import {
    UsageSourceModuleEnum,
    EntityTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
} from '@mark43/rms-api';

import { get } from 'lodash';

import { caseStatusGlobalAttrIdToChildAttrIdsSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import { storeCasesDashboardTab } from '../core/state/ui';
import { casesTabEnum } from '../core/configuration';
import { executeSavedSearchFromQueryParam } from '../../search/core/state/ui';
import { createUsageLog } from '../../admin/usage-logs/state/data';
import {
    allCasesSearch,
    searchTabToPathParam,
    submitAllCasesSearchForm,
    currentElasticQueryCaseStatusGlobalAttrIdSelector,
    searchInitialAllCases,
    pathParamToSearchTab,
} from './state/ui';

const TAB_NAME = casesTabEnum.ALL;

export function onEnter(nextState, replace) {
    this.dispatch(allCasesSearch.actionCreators.setIsInitialSearch(true));
    this.dispatch(storeCasesDashboardTab(TAB_NAME));

    const isTargetProfile = get(nextState, 'location.query.isTargetProfile') === 'true';

    if (isTargetProfile) {
        executeTargetProfilesSearch.call(this, nextState);
    } else {
        executeSavedSearch.call(this, nextState, replace);
    }

    this.dispatch(
        createUsageLog({
            primaryEntityType: EntityTypeEnum.CASE.name,
            action: UsageActionEnum.VIEWED_ALL_CASES_DASHBOARD.name,
            completion: UsageCompletionEnum.SUCCEEDED.name,
            sourceModule: UsageSourceModuleEnum.CASE_MANAGEMENT.name,
        })
    );
}

function executeSavedSearch(nextState, replace) {
    const alreadySelectedTab = currentElasticQueryCaseStatusGlobalAttrIdSelector(this.getState());

    this.dispatch(
        executeSavedSearchFromQueryParam({ searchModule: allCasesSearch, nextState, replace })
    ).then((executed) => {
        if (executed) {
            // when a saved search was just executed at /cases/all?savedSearchId=54321, immediately
            // redirect to the correct tab, and when this `onEnter` gets called again, all these
            // `if` cases will be skipped
            const tabToSelect = currentElasticQueryCaseStatusGlobalAttrIdSelector(this.getState());
            replace(`/cases/all/${searchTabToPathParam[tabToSelect]}`);
        } else if (!nextState.params.allCasesTab) {
            if (alreadySelectedTab) {
                // the previous elasticQuery still exists in state, but the tab isn't in the route
                // yet - this can happen when the user navigates back to the cases dashboard from
                // other routes
                replace(`/cases/all/${searchTabToPathParam[alreadySelectedTab]}`);
            } else {
                // the default, if the user didn't already have a tab selected
                replace('/cases/all/open');
            }
            // don't need to make an initial search if we were just redirected from the `executed` case
            // above
        } else if (
            !get(allCasesSearch.selectors.currentQuerySelector(this.getState()), 'savedSearchId')
        ) {
            this.dispatch(searchInitialAllCases(nextState.params.allCasesTab));
        } else {
            // using browser back button will not trigger search therefore manually trigger scroll
            this.dispatch(allCasesSearch.actionCreators.setTriggerScroll(true));
        }
    });
}

function executeTargetProfilesSearch(nextState) {
    const currentTab = nextState.params.allCasesTab;
    const currentStatusAttrIds = caseStatusGlobalAttrIdToChildAttrIdsSelector(this.getState())[
        pathParamToSearchTab[currentTab]
    ];
    this.dispatch(
        allCasesSearch.form.actionCreators.change({
            currentStatusAttrIds,
            isTargetProfile: true,
        })
    );
    this.dispatch(submitAllCasesSearchForm({ autoSave: false }));
}

import { InlineReportCommentView } from '@mark43/rms-api';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import {
    storeReportNarrative,
    updateReportNarrative,
} from '~/client-common/core/domain/reports/state/data';
import { storeReportInlineComments } from '~/client-common/core/domain/inline-report-comments/state/data';
import { narrativeFormName, refreshNarrativeForm } from '../forms/narrativeForm';
import { RmsAction } from '../../../../../core/typings/redux';
import { logWarning } from '../../../../../core/logging';
import { withTinyEditor } from '../../../../core/editor/utils/withTinyEditor';
import { getEditorIdByReportId } from '../../../../core/editor/utils/tinyEditor';
import { updateNarrativeHtmlWithComments } from '../ui/inlineCommentActions';

/**
 * Resolve the given inline comment.
 *
 * First, make an API request to save the current narrative using markformythree form state.
 *   - This happens before the second API request to resolve the comment because that endpoint also updates the narrative.
 *   - In TinyMCE, the editor state may include new changes from the user which have not yet been synced to form state,
 *       so we use editor state rather than form state.
 * Second, make an API request to resolve the comment.
 * Third, update all states using the API response.
 *   - Update the data state in Nexus.
 *   - Update the form state in markformythree.
 *   - Update the TinyMCE editor state only if the argument `updateTinyMCEContent` is true and that editor is enabled.
 *     - `updateTinyMCEContent` should be false when the resolve action originates from TinyMCE.
 *     - `updateTinyMCEContent` should be true when the resolve action is done outside TinyMCE.
 */
export const resolveInlineComment = (
    reportId: number,
    commentId: number,
    updateTinyMCEContent?: boolean
): RmsAction<Promise<void>> => {
    return (dispatch, getState, { formsRegistry }) => {
        // This usage of the TinyMCE editor uses SYNCHRONOUS_OR_THROW rather than MAYBE_DEFERRED because in this
        // scenario, the editor must already exist in order for the user to have clicked the resolve button on an inline
        // comment. Inline comments render only after the user clicks on highlighted text inside the editor, so this
        // call should never throw an error.
        const editor = withTinyEditor(
            getEditorIdByReportId(reportId, false),
            'SYNCHRONOUS_OR_THROW'
        );
        const narrative = editor.getContent();

        if (narrative === undefined) {
            const error = new Error(
                `Narrative not found when attempting to resolve narrative inline comments.`
            );
            logWarning(error.message, { reportId });
            return Promise.reject(error);
        }

        return (
            dispatch(updateReportNarrative(reportId, narrative))
                // call resource method to make PUT request
                .then(() => getReportResource().resolveInlineComment(commentId))
                .then((response: InlineReportCommentView) => {
                    const { narrativeHtml } = response;
                    const narrativeForm = formsRegistry.get(narrativeFormName, reportId);
                    if (narrativeForm && narrativeHtml) {
                        dispatch(storeReportNarrative(reportId, narrativeHtml));
                        const formModel = dispatch(refreshNarrativeForm(reportId));
                        narrativeForm.set('', formModel);
                        dispatch(storeReportInlineComments(response));

                        if (updateTinyMCEContent && editor) {
                            dispatch(updateNarrativeHtmlWithComments(editor, narrativeHtml));
                        }
                    }
                })
        );
    };
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Icon } from 'arc';
import { convertAttachmentViewModelsForLightboxGallery } from '~/client-common/core/domain/attachments/state/ui';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { openImageGalleryLightbox } from '../../../../legacy-redux/actions/imageGalleryLightboxActions';

const TotalAttachmentsWrapper = styled.span`
    font-size: inherit;
    color: inherit;
`;

const StyledIcon = styled(Icon)`
    height: 1em;
    width: 1em;
`;

const Wrapper = styled.div`
    display: flex;
    gap: var(--arc-space-half);
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.color ?? 'var(--arc-colors-brand-default)'};
    font-size: ${(props) => props.fontSize ?? 'var(--arc-fontSizes-md)'};
`;

function AttachmentsIndicator({ attachmentViewModels }) {
    const dateTimeFormatter = useSelector(currentDepartmentDateFormatterSelector);
    const dispatch = useDispatch();

    if (!attachmentViewModels || attachmentViewModels.length === 0) {
        return null;
    }

    const handleAttachmentsClick = () =>
        dispatch(
            openImageGalleryLightbox(
                convertAttachmentViewModelsForLightboxGallery(
                    attachmentViewModels,
                    dateTimeFormatter
                )
            )
        );

    return (
        <Wrapper onClick={handleAttachmentsClick}>
            <StyledIcon color="var(--arc-colors-brand-default)" icon="Document" />
            <TotalAttachmentsWrapper>{`(${attachmentViewModels.length})`}</TotalAttachmentsWrapper>
        </Wrapper>
    );
}
export default AttachmentsIndicator;

// core deps
import { createSelector } from 'reselect';
import _, { map, mapValues } from 'lodash';

// selectors
import { elasticOrganizationsSelector } from '../data';
import {
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '../../../attributes/state/data';

// view model helpers
import {
    buildViewModel,
    subdivisionAttrIdsMapper,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';

import { formatLocationLinkTypeId } from '../../../../../helpers/linkTypesHelpers';

const buildElasticOrganizationViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
    (formatAttributeById, formatSubdivisionAttrIds) =>
        buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                subdivisionAttrIdsMapper,
                (obj) =>
                    _(obj)
                        .pick('type')
                        .mapValues((type) => formatLocationLinkTypeId(type))
                        .value(),
            ],
            helpers: {
                formatAttributeById,
                formatSubdivisionAttrIds,
            },
        })
);

export const elasticOrganizationViewModelsSelector = createSelector(
    elasticOrganizationsSelector,
    buildElasticOrganizationViewModelSelector,
    (elasticOrganizations, buildElasticOrganizationViewModel) =>
        mapValues(elasticOrganizations, buildElasticOrganizationViewModel)
);

export function createOrganizationsResultsSelector(baseSelector) {
    return createSelector(
        baseSelector,
        buildElasticOrganizationViewModelSelector,
        (organizationResults, buildElasticOrganizationViewModel) =>
            map(organizationResults, buildElasticOrganizationViewModel)
    );
}

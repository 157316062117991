import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { N_ITEMS_FIELDSET } = fieldTypeClientEnum;

const commandLineArgumentAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'commandLineArgument',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'argumentDefinition',
            },
            {
                type: N_ITEMS_FIELDSET,
                key: 'cliArgumentConfigurationLinks',
                fields: buildFlatFormFieldViewModels([
                    {
                        key: 'cliArgument',
                    },
                    {
                        key: 'cliArgumentUsageType',
                    },
                    {
                        key: 'maxAllowed',
                    },
                    {
                        key: 'minRequired',
                    },
                ]),
            },
        ]),
    },
]);

function convertFromFormModel({ commandLineArgument }) {
    return { commandLineArgument };
}

function convertToFormModel({ commandLineArgument }) {
    return {
        commandLineArgument,
    };
}

export default createFormModule({
    formName: formClientEnum.COMMAND_LINE_ARGUMENT,
    fieldViewModels: commandLineArgumentAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import { NamePhoneNumber } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'namePhoneNumbers';
const namePhonesModule = createGroupedModule<NamePhoneNumber>({
    type: NEXUS_STATE_PROP,
    key: 'nameId',
});

// ACTIONS

// SELECTORS
export const namePhonesSelector = namePhonesModule.selectors.entitiesSelector;
export const namePhonesByNameIdSelector = namePhonesModule.selectors.entitiesByKeySelector;

// REDUCER
export default namePhonesModule.reducerConfig;

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { RouteComponentProps } from 'react-router';
import { Box, useToast, VStack } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { InsightsContext } from '../../core/components/InsightsContext';
import { logError } from '../../../../core/logging';
import insightsResource from '../resources/insightsResource';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import errorToMessage from '../../../core/errors/utils/errorToMessage';

const strings = componentStrings.insights.GoodDataDashboard;

type GoodDataDashboardParams = {
    dashboardId?: string;
};

export const GoodDataDashboard: React.FC<
    RouteComponentProps<GoodDataDashboardParams, Record<string, never>>
> = ({ params }) => {
    const goodDataIframeRef = useRef<HTMLIFrameElement>(null);
    const toast = useToast();
    const {
        goodDataEmbedPath,
        goodDataEmbedBaseUrl,
        goodDataEmbedParameters,
        setGoodDataDashboardId,
        setGoodDataIframeLoaded,
        addGoodDataEventCallback,
        removeGoodDataEventCallback,
    } = useContext(InsightsContext);
    const [iframeSrc, setIframeSrc] = useState('');

    useEffect(() => {
        if (params.dashboardId) {
            const decodedDashboardId: string = decodeURIComponent(params.dashboardId);
            setGoodDataDashboardId(decodedDashboardId);
        }
    }, [params.dashboardId, setGoodDataDashboardId]);

    useEffect(() => {
        window.history.pushState(null, '', `/rms/#/insights${goodDataEmbedPath}`);
    }, [goodDataEmbedPath]);

    const handleTokenExpirationSuccess = useCallback((jwt) => {
        const postMessageStructure = {
            gdc: {
                product: 'dashboard',
                event: {
                    name: 'setApiToken',
                    data: {
                        token: jwt.content,
                    },
                },
            },
        };
        const origin = '*';
        if (goodDataIframeRef.current && goodDataIframeRef.current.contentWindow) {
            goodDataIframeRef.current.contentWindow.postMessage(postMessageStructure, origin);
        }
    }, []);

    const handleTokenExpirationError = useCallback(
        (err) => {
            const message = errorToMessage(err, strings.failedToInjectJWTerror);
            toast({ status: 'error', description: message });
            logError(`Insights dashboard failed to inject jwt token. Error: ${err}`);
        } /* toast dependency cause unnecessary re-renders */,
        /* eslint-disable react-hooks/exhaustive-deps */
        []
    );

    const { callResource: handleTokenExpiration } = useResourceDeferred(
        insightsResource.createGoodDataJWT,
        handleTokenExpirationSuccess,
        handleTokenExpirationError
    );

    useEffect(() => {
        addGoodDataEventCallback('listeningForApiToken', handleTokenExpiration);
        addGoodDataEventCallback('apiTokenIsAboutToExpire', handleTokenExpiration);

        return () => {
            removeGoodDataEventCallback('listeningForApiToken', handleTokenExpiration);
            removeGoodDataEventCallback('apiTokenIsAboutToExpire', handleTokenExpiration);
        };
    }, [addGoodDataEventCallback, removeGoodDataEventCallback, handleTokenExpiration]);

    useEffect(() => {
        const goodDataIframe = goodDataIframeRef.current;

        if (goodDataIframe) {
            goodDataIframe.onload = () => {
                setGoodDataIframeLoaded(true);
                handleTokenExpiration();
            };
            goodDataIframe.onerror = () => {
                setGoodDataIframeLoaded(false);
                logError('GoodData Iframe failed to load.');
            };

            return () => {
                goodDataIframe.onload = null;
                goodDataIframe.onerror = null;
            };
        }
        return;
    }, [iframeSrc, handleTokenExpiration, setGoodDataIframeLoaded]);

    useEffect(() => {
        setIframeSrc(`${goodDataEmbedBaseUrl}${goodDataEmbedPath}${goodDataEmbedParameters}`);
    }, [goodDataEmbedBaseUrl, goodDataEmbedPath, goodDataEmbedParameters]);

    if (!goodDataEmbedBaseUrl) {
        return <SimpleLoading />;
    } else {
        return (
            <VStack spacing={0} align="stretch" h="100%" w="100%">
                <Box as="iframe" ref={goodDataIframeRef} src={iframeSrc} flexGrow={1} />
            </VStack>
        );
    }
};

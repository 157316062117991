import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { lifecycleOptions } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { DISPLAY_BEHAVIORAL_CRISIS_REPORT_WEAPON_OR_FORCE_INVOLVED } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';

import { formName } from '../../state/forms/behavioralCrisisForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import {
    MFTAutofillDateButton,
    autofillDateTypeEnum,
} from '../../../../core/components/AutofillDateButton';

const renderReportAttributeMultiSelect = ({
    attributeTypeName,
    attributeIdsPath,
    descriptionPath,
    label,
}) => {
    const labelProp = isUndefinedOrNull(label) ? undefined : { label };
    return (
        <>
            <Row>
                <ArbiterMFTAttributeSelect
                    path={attributeIdsPath}
                    length="lg"
                    attributeType={attributeTypeName}
                    multiple={true}
                    {...labelProp}
                />
            </Row>
            <Row>
                <IndentedFields>
                    <ArbiterMFTText path={descriptionPath} length="lg" />
                </IndentedFields>
            </Row>
        </>
    );
};

const BehavioralCrisisCardForm = withFields([
    DISPLAY_BEHAVIORAL_CRISIS_REPORT_WEAPON_OR_FORCE_INVOLVED,
])(function _BehavioralCrisisCardForm({ fieldDisplayNames }) {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={({ set: formSet }) => {
                return (
                    <CardSection>
                        <Row>
                            <ArbiterMFTDatePicker
                                path="crisisContactStartDateUtc"
                                includeTime={true}
                            />
                            <ArbiterMFTDatePicker
                                path="crisisContactEndDateUtc"
                                includeTime={true}
                            />
                            <MFTAutofillDateButton
                                onClick={(_e, { eventStartUtc, eventEndUtc }) => {
                                    formSet('crisisContactStartDateUtc', eventStartUtc);
                                    formSet('crisisContactEndDateUtc', eventEndUtc);
                                }}
                                autofillDateType={autofillDateTypeEnum.START_END_DATE_TIME}
                                startDatePath="crisisContactStartDateUtc"
                                endDatePath="crisisContactEndDateUtc"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="wasCitOfficerRequested" length="md" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="wasCitOfficerDispatched" length="md" />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="didCitOfficerArrive" length="md" />
                        </Row>
                        {renderReportAttributeMultiSelect({
                            attributeTypeName: AttributeTypeEnum.EXHIBITING_BEHAVIOR.name,
                            attributeIdsPath: 'exhibitingBehaviorAttrIds',
                            descriptionPath: 'exhibitingBehaviorDescription',
                        })}
                        {renderReportAttributeMultiSelect({
                            attributeTypeName: AttributeTypeEnum.WEAPON_INVOLVED.name,
                            attributeIdsPath: 'weaponInvolvedAttrIds',
                            descriptionPath: 'weaponInvolvedDescription',
                            // see RMS-8827
                            // `WEAPON_INVOLVED` `ReportAttribute` is used for both `Field Contact`
                            // reports and `Behavioral Crisis` reports, so we need a way to configure
                            // different labels for the same field.
                            // Really, they should be two separate `AttributeType`s ...
                            label:
                                fieldDisplayNames.DISPLAY_BEHAVIORAL_CRISIS_REPORT_WEAPON_OR_FORCE_INVOLVED,
                        })}
                        {renderReportAttributeMultiSelect({
                            attributeTypeName:
                                AttributeTypeEnum.BEHAVIORAL_CRISIS_TECHNIQUES_USED.name,
                            attributeIdsPath: 'behavioralCrisisTechniquesUsedAttrIds',
                            descriptionPath: 'behavioralCrisisTechniquesUsedDescription',
                        })}
                        {renderReportAttributeMultiSelect({
                            attributeTypeName: AttributeTypeEnum.BEHAVIORAL_CRISIS_DISPOSITION.name,
                            attributeIdsPath: 'behavioralCrisisDispositionAttrIds',
                            descriptionPath: 'behavioralCrisisDispositionDescription',
                        })}
                    </CardSection>
                );
            }}
        />
    );
});

export default BehavioralCrisisCardForm;

import {
    Attachment,
    EntityTypeEnumType,
    FolderUpdateRequest,
    FolderUploadRequest,
    EntityTypeEnum,
    FolderView,
    FolderContentView,
} from '@mark43/rms-api';
import { map, isEmpty, compact, values, chain, orderBy, mapKeys } from 'lodash';
import { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';
import {
    folderByIdSelector,
    foldersSelector,
    Folder,
    NEXUS_STATE_PROP as FOLDERS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/folders/state/data';
import {
    NEXUS_STATE_PROP as FOLDER_CONTENT_VIEWS_NEXUS_PROP,
    folderContentViewsSelector,
} from '~/client-common/core/domain/folder-content-views/state/data';

import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import { removeAttachments } from '~/client-common/core/domain/attachments/state/data';
import folderResource from '../../resources/folderResource';
import folderContentResource from '../../resources/folderContentsResource';
import { RmsAction } from '../../../../../core/typings/redux';

import { expandFolders } from '../ui';
import { removeCaseNotes } from '../../../case-notes/state/data';
import {
    removeSubFolderByIdsFromFolderContentView,
    retrieveFolderContentViewsBySubFolderIdSelector,
    createFolderContents,
    moveFolderContents,
} from './folderContents';

import { updateOrphanContents } from './orphanedContents';

const convertFolderViewToDataNexusShape = (folderView: FolderView): Folder | undefined => {
    if (!folderView.folder) {
        return undefined;
    }
    return {
        ...folderView,
        id: folderView.folder?.id,
    };
};

export function createTopLevelFolder(folderData: FolderUploadRequest): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        return folderResource.createTopLevelFolder(folderData).then((folderView) => {
            const folderId = folderView.folder?.id;

            if (!folderId) {
                return;
            }

            dispatch(
                nexus.withEntityItems(
                    {
                        [FOLDERS_NEXUS_STATE_PROP]: [convertFolderViewToDataNexusShape(folderView)],
                    },
                    { type: 'STORE_FOLDERS' }
                )
            );
        });
    };
}

export function createSubFolder(
    folderData: FolderUploadRequest,
    parentFolderId: number
): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        return folderResource.createSubFolder(folderData, parentFolderId).then((folderView) => {
            const folderPath = folderView?.folder?.path;
            const subFolderEntityTypeId = folderView?.folder?.entityTypeId;
            const subFolderOwnerId = folderView?.folder?.ownerId;
            if (!folderPath) {
                return;
            }

            const path = compact(folderPath.split('/'));
            const topLevelFolderName = path[0];
            const folders = foldersSelector(getState());

            const topLevelFolderView = chain(folders)
                .values()
                .filter(
                    ({ folder }) =>
                        folder?.name === topLevelFolderName &&
                        folder?.entityTypeId === subFolderEntityTypeId &&
                        folder?.ownerId === subFolderOwnerId
                )
                .first()
                .value();

            if (!topLevelFolderView) {
                return;
            }

            const updatedFolderView = {
                ...topLevelFolderView,
                subFolderViews: addNewFolderToFolderHierarchy({
                    currentSubFolderViews: topLevelFolderView.subFolderViews,
                    folderPath: path,
                    newFolderViews: [folderView],
                    isAddNewFolder: true,
                }),
            };

            dispatch(
                nexus.withEntityItems(
                    {
                        [FOLDERS_NEXUS_STATE_PROP]: [updatedFolderView],
                    },
                    { type: 'STORE_FOLDERS' }
                )
            );
        });
    };
}

const getFolderContentViewByFolderId = (
    folderContentViews: FolderContentView[],
    folderId: number
) => {
    const folderContentView = folderContentViews.filter((item) => {
        return item.directSubFolders.filter((item) => item.id === folderId).length === 1;
    })[0];

    // The folderId passed may not be a directSubFolder but instead
    // a top level folder
    if (!folderContentView) {
        return folderContentViews.filter((item) => item.folderId === folderId)[0];
    }

    return folderContentView;
};

type UpdateFolderEntityItemsT = {
    folders: Folder[];
    folderContentViews?: FolderContentView[];
};

export function updateFolder(folderData: FolderUpdateRequest): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        const folderContentViews = values(folderContentViewsSelector(getState()));

        const folderContentView = getFolderContentViewByFolderId(folderContentViews, folderData.id);

        return folderResource.updateFolder(folderData).then((folderView) => {
            const { folder } = folderView;

            if (!folder) {
                return;
            }

            let entitiesToUpdate: UpdateFolderEntityItemsT = {
                [FOLDERS_NEXUS_STATE_PROP]: compact([
                    convertFolderViewToDataNexusShape(folderView),
                ]),
            };

            if (folderContentView) {
                const selectedSubFolder = folderContentView.directSubFolders.filter(
                    (item) => item.id === folderData.id
                )[0];
                const otherSubFolders = folderContentView.directSubFolders.filter(
                    (item) => item.id !== folderData.id
                );

                const breadCrumbToUpdate = folderContentView.folderBreadcrumbs.filter(
                    (item) => item.folderId === folderData.id
                )[0];

                const otherBreadCrumbs = folderContentView.folderBreadcrumbs.filter(
                    (item) => item.folderId !== folderData.id
                );

                const isSelectedFolder = folderData.id === folderContentView.folderId;

                // If it is not a top level folder
                const updatedDirectSubFolders = !isSelectedFolder
                    ? [...otherSubFolders, { ...selectedSubFolder, name: folderData.name }]
                    : folderContentView.directSubFolders;

                // Update the breadcrumb if the user is viewing the selected folder
                const updatedBreadCrumbs = isSelectedFolder
                    ? [...otherBreadCrumbs, { ...breadCrumbToUpdate, folderName: folderData.name }]
                    : folderContentView.folderBreadcrumbs;

                entitiesToUpdate = {
                    ...entitiesToUpdate,
                    [FOLDER_CONTENT_VIEWS_NEXUS_PROP]: [
                        {
                            ...folderContentView,
                            directSubFolders: updatedDirectSubFolders,
                            folderBreadcrumbs: updatedBreadCrumbs,
                        },
                    ],
                };
            }

            return dispatch(
                nexus.withEntityItems(entitiesToUpdate, { type: 'UPDATE_FOLDER_SUCCESS' })
            );
        });
    };
}

export function deleteFolders(
    folderIdsToDelete: number[],
    caseId: number,
    folderEntityType: EntityTypeEnumType
): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        const folderContentViews = retrieveFolderContentViewsBySubFolderIdSelector(getState())(
            folderIdsToDelete
        );

        // If the folder belongs to a folder content view that is already pre-loaded in the
        // front-end, we need to remove the folder from its list of directSubFolders.
        const updatedFolderContentViews = folderContentViews.map((folderContentView) => {
            return {
                ...folderContentView,
                directSubFolders: removeSubFolderByIdsFromFolderContentView(
                    folderContentView,
                    folderIdsToDelete
                ),
            };
        });

        return folderResource
            .deleteFolders({
                folderIdsToDelete,
                ownerId: caseId,
                ownerType: EntityTypeEnum.CASE.name,
                entityType: folderEntityType,
            })
            .then((folderViewWrapper) => {
                const { folderViews } = folderViewWrapper;

                dispatch(
                    nexus.withRemove(
                        [FOLDERS_NEXUS_STATE_PROP],
                        {},
                        nexus.withEntityItems(
                            {
                                [FOLDERS_NEXUS_STATE_PROP]: compact(
                                    map(folderViews, (folderView) => {
                                        return convertFolderViewToDataNexusShape(folderView);
                                    })
                                ),
                                [FOLDER_CONTENT_VIEWS_NEXUS_PROP]: updatedFolderContentViews,
                            },
                            { type: 'DELETE_FOLDER_SUCCESS' }
                        )
                    )
                );
            })
            .then(() => {
                dispatch(updateOrphanContents());
            });
    };
}

export function removeContentsFromFolder(
    folderId: number,
    contentIdsToDelete: number[]
): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        return folderContentResource
            .removeFolderContents({
                folderId,
                contentIds: contentIdsToDelete,
            })
            .then((updatedFolderContentView) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [FOLDER_CONTENT_VIEWS_NEXUS_PROP]: [
                                {
                                    ...updatedFolderContentView,
                                },
                            ],
                        },
                        { type: 'DELETE_FOLDER_CONTENT_VIEW_SUCCESS' }
                    )
                );
            })
            .then(() => {
                dispatch(updateOrphanContents());
            });
    };
}

export function permanentlyDeleteAttachmentsFromFolder(
    folderId: number,
    attachmentsToDelete: Attachment[],
    entityId: number
): RmsAction<Promise<void>> {
    return (dispatch) => {
        return dispatch(
            removeContentsFromFolder(folderId, map(attachmentsToDelete, 'attachmentId'))
        )
            .then(() => {
                return dispatch(
                    removeAttachments(attachmentsToDelete, entityId, EntityTypeEnum.CASE.name)
                );
            })
            .then(() => {
                return dispatch(updateOrphanContents());
            });
        // TODO: CHI-1001 - Refresh the attachments tab dynamically
    };
}

export function permanentlyDeleteCaseNotesFromFolder(
    folderId: number,
    caseNoteIdsToDelete: number[]
): RmsAction<Promise<void>> {
    return (dispatch) => {
        // We need to remove teh case note first because we experience race conditions
        // with the componentDidUnmount logic for the CaseNotesEditor component.
        // May need to revisit this when working on bulk case note delete.
        return dispatch(removeCaseNotes(caseNoteIdsToDelete)).then(() => {
            return dispatch(removeContentsFromFolder(folderId, caseNoteIdsToDelete));
        });
    };
}

export function getFolderHierarchyForFolder(
    ownerId: number,
    entityType: EntityTypeEnumType,
    folderId: number,
    topLevelFolderId: number
): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        return folderResource
            .getFolderHierarchyForFolder(ownerId, entityType, folderId)
            .then(({ folderViews }) => {
                const topLevelFolderView = folderByIdSelector(getState())(topLevelFolderId);
                const folderPath = folderViews[0]?.folder?.path;
                let updatedFolderView: FolderView[];

                if (!topLevelFolderView || !folderPath) {
                    updatedFolderView = orderBy(
                        map(folderViews, (folderView) => ({
                            ...folderView,
                            // TODO: Change folderView.folder to be non-nullable in Java because it must always exist. Remove this type assertion.
                            id: folderView.folder?.id as number,
                        })),
                        [(folderContentView) => folderContentView.folder?.name.toLowerCase()],
                        ['asc']
                    );
                } else {
                    const path = compact(folderPath.split('/'));

                    updatedFolderView = [
                        {
                            ...topLevelFolderView,
                            subFolderViews: addNewFolderToFolderHierarchy({
                                currentSubFolderViews: topLevelFolderView.subFolderViews,
                                folderPath: path,
                                newFolderViews: folderViews,
                                isAddNewFolder: false,
                            }),
                        },
                    ];
                }

                if (isEmpty(updatedFolderView)) {
                    return;
                }

                dispatch(
                    nexus.withEntityItems(
                        {
                            [FOLDERS_NEXUS_STATE_PROP]: updatedFolderView,
                        },
                        { type: 'STORE_FOLDERS' }
                    )
                );

                dispatch(expandFolders(entityType));
            });
    };
}

export function getTopLevelFolders(
    ownerId: number,
    entityType: EntityTypeEnumType
): RmsAction<Promise<ModuleShape<Folder>>> {
    return (dispatch, getState, { nexus }) => {
        return folderResource.getTopLevelFolders(ownerId, entityType).then(({ folderViews }) => {
            const folders: Folder[] = orderBy(
                map(folderViews, (folderView) => ({
                    ...folderView,
                    // TODO: Change folderView.folder to be non-nullable in Java because it must always exist. Remove this type assertion.
                    id: folderView.folder?.id as number,
                })),
                [(folderContentView) => folderContentView.folder?.name.toLowerCase()],
                ['asc']
            );

            dispatch(
                nexus.withEntityItems(
                    {
                        [FOLDERS_NEXUS_STATE_PROP]: folders,
                    },
                    { type: 'STORE_FOLDERS' }
                )
            );

            return mapKeys(folders, 'id');
        });
    };
}

export function getTopLevelFoldersForOwner(
    ownerId: number
): RmsAction<Promise<ModuleShape<Folder>>> {
    return (dispatch, getState, { nexus }) => {
        return folderResource.getTopLevelFoldersForOwner(ownerId).then(({ folderViews }) => {
            const folders: Folder[] = orderBy(
                map(folderViews, (folderView) => ({
                    ...folderView,
                    id: folderView.folder?.id as number,
                })),
                [(folderContentView) => folderContentView.folder?.name.toLowerCase()],
                ['asc']
            );

            dispatch(
                nexus.withEntityItems(
                    {
                        [FOLDERS_NEXUS_STATE_PROP]: folders,
                    },
                    { type: 'STORE_FOLDERS' }
                )
            );

            return mapKeys(folders, 'id');
        });
    };
}

export const getDirectSubFolders = (
    folderId: number,
    topLevelFolderId: number
): RmsAction<Promise<void>> => {
    return (dispatch, getState, { nexus }) => {
        return folderResource.getDirectSubFolders(folderId).then(({ folderViews }) => {
            const topLevelFolderView = folderByIdSelector(getState())(topLevelFolderId);
            const folderPath = folderViews[0]?.folder?.path;

            if (!topLevelFolderView || !folderPath) {
                return;
            }

            const path = compact(folderPath.split('/'));

            const updatedFolderView = {
                ...topLevelFolderView,
                subFolderViews: addNewFolderToFolderHierarchy({
                    currentSubFolderViews: topLevelFolderView.subFolderViews,
                    folderPath: path,
                    newFolderViews: folderViews,
                    isAddNewFolder: false,
                }),
            };

            if (isEmpty(updatedFolderView)) {
                return;
            }

            dispatch(
                nexus.withEntityItems(
                    {
                        [FOLDERS_NEXUS_STATE_PROP]: [updatedFolderView],
                    },
                    { type: 'STORE_FOLDERS' }
                )
            );
        });
    };
};

// Since the getDirectSubFolders api only return direct folderViews
// instead of full folder hierachy, we need to look into the current
// folder hierarchy and add newly returned folderViews to the hierarchy.
// This logic can be remove if the api return full folder hierachy in
// this ticket https://mark43.atlassian.net/browse/CHI-626
const addNewFolderToFolderHierarchy = ({
    currentSubFolderViews,
    folderLevel = 1,
    folderPath,
    newFolderViews,
    isAddNewFolder,
}: {
    currentSubFolderViews: FolderView[];
    folderLevel?: number;
    folderPath: string[];
    newFolderViews: FolderView[];
    isAddNewFolder: boolean;
}): FolderView[] => {
    // if folder level is the last index in folder path
    // this is where we need to add the new folderViews
    if (folderLevel === folderPath.length - 1) {
        if (isAddNewFolder) {
            return sortByNaturalOrder(
                [...currentSubFolderViews, ...newFolderViews],
                [(folderView) => folderView.folder?.name],
                ['asc']
            );
        }
        return newFolderViews;
    }

    const nextFolderViewIndex = currentSubFolderViews.findIndex(
        ({ folder }) => folder?.name === folderPath[folderLevel]
    );

    const nextFolderView = currentSubFolderViews[nextFolderViewIndex];

    currentSubFolderViews[nextFolderViewIndex] = {
        ...nextFolderView,
        subFolderViews: addNewFolderToFolderHierarchy({
            currentSubFolderViews: nextFolderView.subFolderViews,
            folderLevel: folderLevel + 1,
            folderPath,
            newFolderViews,
            isAddNewFolder,
        }),
    };

    return currentSubFolderViews;
};

/*
    Helper method to retrieve the FolderContentView given an folder id.
    This method is used to determine the folderId that a subfolder
    belongs to.
*/

export function moveFoldersIntoFolders(
    destinationFolderId: number,
    folderIdsToMove: number[]
): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        const folderContentViews = retrieveFolderContentViewsBySubFolderIdSelector(getState())(
            folderIdsToMove
        );

        const updatedFolderContentViews = folderContentViews.map((folderContentView) => {
            return {
                ...folderContentView,
                directSubFolders: removeSubFolderByIdsFromFolderContentView(
                    folderContentView,
                    folderIdsToMove
                ),
            };
        });

        return folderResource
            .moveFoldersIntoFolders({
                destFolderId: destinationFolderId,
                folderIdsToMove,
            })
            .then(({ folderViews }) => {
                // Note: The back-end for this response returns the entire Folder View Heirarchy!
                dispatch(
                    nexus.withRemove(
                        FOLDERS_NEXUS_STATE_PROP,
                        {},
                        nexus.withEntityItems(
                            {
                                [FOLDERS_NEXUS_STATE_PROP]: map(folderViews, (folderView) => {
                                    return convertFolderViewToDataNexusShape(folderView);
                                }),

                                [FOLDER_CONTENT_VIEWS_NEXUS_PROP]: updatedFolderContentViews,
                            },
                            { type: 'MOVE_FOLDERS_INTO_FOLDERS_SUCCESS' }
                        )
                    )
                );
            });
    };
}

export function moveAttachmentsIntoFolders({
    contentIds,
    destinationFolderId,
    entityType,
    sourceFolderId,
}: {
    contentIds: number[];
    destinationFolderId: number;
    entityType: EntityTypeEnumType;
    sourceFolderId?: number;
}): RmsAction<Promise<void>> {
    return (dispatch) => {
        if (!sourceFolderId) {
            return dispatch(
                createFolderContents({
                    folderContent: {
                        contentIds,
                        entityTypeId: entityType,
                        folderId: destinationFolderId,
                    },
                    sourceFolderId: undefined,
                })
            );
        }
        return dispatch(
            moveFolderContents({
                contentIds,
                entityType,
                destinationFolderId,
                sourceFolderId,
            })
        );
    };
}

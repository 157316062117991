let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Roles Resource',
        methods: {
            getRolesForDepartment(excludeUserRoles) {
                return req({
                    method: 'GET',
                    url: 'admin/role/department',
                    params: {
                        exclude_user_roles: excludeUserRoles,
                    },
                });
            },
            createRole(role) {
                return req({
                    method: 'POST',
                    url: 'admin/role/',
                    data: role,
                });
            },
        },
    });
}

/**
 * Resources for Roles. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

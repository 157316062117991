import { get } from 'lodash';

import getOffensesResource from '~/client-common/core/domain/offenses/resources/offensesResource';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';

import { convertFromFormModel } from '../../forms/arrest-charges-side-panel/stubOffenseForm';
import { offenseReportDefinitionForCurrentDepartmentSelector } from '../../../../../core/report-definitions/state/ui';
import { storeLinkedReportIds } from '../../../../../../legacy-redux/actions/reportsActions';
import chargesCard from '../chargesCard';
import storeChargeOffenseView from './storeChargeOffenseView';

export const saveStubOffenseSidePanel = ({ form }) => (dispatch, getState) => {
    return form
        .submit()
        .then(() => {
            const state = getState();
            const reportId = chargesCard.selectors.reportIdSelector(state);
            const report = reportByIdSelector(state)(reportId);
            const offenseReportDefinitionForCurrentDepartment = offenseReportDefinitionForCurrentDepartmentSelector(
                state
            );
            const { offense, locationEntityLink } = convertFromFormModel({ form });

            const reportCreationRequest = {
                reportingEventNumber: report.reportingEventNumber,
                reportDefinitionId: get(offenseReportDefinitionForCurrentDepartment, 'id'),
                agencyId: report.agencyId,
            };
            const stubOffenseCreationRequest = {
                reportCreationRequest,
                offense,
                offenseLocationEntityLink: locationEntityLink,
            };
            return getOffensesResource().createStubOffense(stubOffenseCreationRequest);
        })
        .then((chargeOffenseView) => {
            const { offense, report } = chargeOffenseView;
            dispatch(
                // eslint-disable-next-line no-restricted-syntax
                storeChargeOffenseView({
                    chargeOffenseView,
                    action: { type: 'ARREST_CHARGES_SIDE_PANEL_CREATE_STUB_OFFENSE' },
                })
            );
            // legacy redux action ...
            // eslint-disable-next-line no-restricted-syntax
            dispatch(storeLinkedReportIds([report.id]));
            return offense;
        });
};

import React from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';
import classNames from 'classnames';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import { AnalyticsPropertyEnum } from '../../../modules/analytics/constants/analyticsEnum';
import { withAnalytics } from '../../../modules/analytics/components/withAnalytics';
import Icon, { iconTypes } from '../../../modules/core/components/Icon';

interface StyleOverrides {
    float?: 'left' | 'right' | 'none';
    display?: string;
}

const ReactButtonIcon = styled.span`
    position: relative;
    min-width: 20px;
    height: 15px;

    i {
        &::before {
            font-size: 28px;
            line-height: 1;
        }
    }
`;

export const ReactButtonIconLeft = styled(ReactButtonIcon)`
    div {
        display: inline-block;
        margin: -2px 2px 0px 0;
    }

    i {
        &::before {
            margin-top: -2px;
            margin-right: 2px;
            font-size: 18px;
        }
    }
`;

const ReactButton = styled.div<StyleOverrides>`
    text-align: center;
    display: ${(props) => props.display || 'block'};
    cursor: pointer;
    float: ${(props) => props.float || 'left'};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    line-height: 16px;
    border-radius: 4px;
    margin: 12px 10px 12px 0;
    letter-spacing: 0.05em;
    user-select: none;

    &:active,
    &.active {
        background-color: ${(props) => props.theme.colors.brightBlue};
        color: var(--arc-colors-brand-content);
        fill: var(--arc-colors-brand-content);
        border: 1px solid ${(props) => props.theme.colors.navyBlue};
    }

    &.disabled {
        outline: 0;
    }
`;

const ReactPrimaryBaseButton = styled(ReactButton)`
    color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.darkGrey};
    text-transform: uppercase;
    background-color: ${(props) => props.theme.colors.cobaltBlue};

    &:focus {
        box-shadow: 0 0 0 3px ${(props) => props.theme.colors.brightYellow};
        outline: 0;
    }

    &.disabled {
        cursor: default;
        background-color: ${(props) => props.theme.colors.lightGrey};

        &:active {
            color: ${(props) => props.theme.colors.white};
            border: 1px solid ${(props) => props.theme.colors.darkGrey};
        }
    }
`;

const ReactSecondaryBaseButton = styled(ReactButton)`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    color: ${(props) => props.theme.colors.cobaltBlue};
    fill: ${(props) => props.theme.colors.cobaltBlue};
    border: 1px solid var(--arc-colors-border-default);
    text-transform: uppercase;

    &:hover {
        border: 1px solid ${(props) => props.theme.colors.cobaltBlue};
    }

    &.selected {
        background-color: ${(props) => props.theme.colors.brightBlue};
        color: var(--arc-colors-brand-content);
        fill: var(--arc-colors-brand-content);
        border: 1px solid ${(props) => props.theme.colors.navyBlue};
    }

    &:focus {
        box-shadow: 0 0 0 3px ${(props) => props.theme.colors.cobaltBlue};
        outline: 0;
    }

    &.disabled {
        color: ${(props) => props.theme.colors.mediumGrey};
        fill: ${(props) => props.theme.colors.mediumGrey};
        cursor: default;
        border-color: ${(props) => props.theme.colors.lightGrey};

        &:active {
            background-color: transparent;
        }
    }
`;

const ReactIconTextLink = styled.div<StyleOverrides>`
    display: ${(props) => props.display || 'inline-flex'};
    ${(props) => props.float && `float: ${props.float}`};
    color: ${(props) => props.theme.colors.cobaltBlue};
    fill: ${(props) => props.theme.colors.cobaltBlue};
    cursor: pointer;
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-sm);
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};

    .react-icon-left {
        padding-right: 7px;
        margin-top: 1px;
    }

    &:hover {
        text-decoration: underline;
    }

    &.disabled {
        cursor: default;
        color: ${(props) => props.theme.colors.mediumGrey};
        fill: ${(props) => props.theme.colors.mediumGrey};
        text-decoration: none;
    }
`;

// appears at the right of a row of inputs in a form
export const ReactFormRowIconLink = styled(ReactIconTextLink)`
    float: left;
    clear: none;
    margin-top: 19px;
`;

const ReactPrimaryButton = styled(ReactPrimaryBaseButton)`
    padding: 6px 15px 6px;
    min-width: 80px;
`;

const ReactSmallGoButton = styled(ReactPrimaryBaseButton)`
    min-width: 35px;
    margin-left: 0;
    margin-top: 11px;
    padding: 6px 5px;
    border-radius: 0 4px 4px 0;
`;

const ReactSecondaryButton = styled(ReactSecondaryBaseButton)`
    padding: 6px 15px 6px;
    min-width: 80px;
    text-transform: uppercase;
`;

const ReactSecondaryBoldButton = styled(ReactSecondaryBaseButton)`
    border-radius: 3px;
    padding: 7px 15px 5px;
    min-width: 80px;
    text-transform: uppercase;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ReactUtilityButton = styled(ReactSecondaryBaseButton)`
    padding: 6px 10px;
`;

const ReactIconButton = styled(ReactSecondaryBaseButton)`
    box-sizing: border-box;
    height: 30px;
    width: 38px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
`;

export const buttonTypes = {
    PRIMARY: 'mark43-react-primary-button',
    SECONDARY: 'mark43-react-secondary-button',
    // very similar to secondary with higher weight text and slightly
    // different border radius - should reconcile this with
    // a style guide at some point
    SECONDARY_BOLD: 'mark43-react-secondary-bold-button',
    UTILITY: 'mark43-react-utility-button',
    SMALL_GO: 'mark43-react-small-go-button',
    ICON_LINK: 'mark43-react-icon-text-link',
    DELETE_ICON_LINK: 'mark43-react-delete-icon-text-link',
    FORM_ROW_ICON_LINK: 'mark43-react-form-row-icon-link',
    ICON: 'mark43-react-icon-button',
} as const;

interface State {
    hovered: boolean;
}

export type ButtonProps = {
    disabled?: boolean;
    active?: boolean;
    className?: string;
    stopPropagation?: boolean;

    iconRight?: React.ReactNode;
    hoveredIconRight?: React.ReactNode;
    iconLeft?: React.ReactNode | keyof typeof iconTypes;
    hoveredIconLeft?: React.ReactNode;
    hoveredChildren?: React.ReactNode;
    children?: React.ReactNode;

    testDisplay?: string;
    testId?: string;
    id?: string;

    style?: React.CSSProperties;

    onMouseEnter?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseLeave?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    onClick?: (
        e?: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
        value?: any
    ) => void;
    value?: any;
    /* eslint-enable @typescript-eslint/no-explicit-any */
} & StyleOverrides;

const Button = withAnalytics({
    analyticsKeyToAdd: AnalyticsPropertyEnum.BUTTON,
})<ButtonProps, HTMLDivElement>(
    class Button extends React.Component<ButtonProps, State> {
        state: State = {
            hovered: false,
        };

        button: HTMLDivElement | undefined;

        static defaultProps = {
            onClick: noop,
            disabled: false,
        };

        handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (this.props.disabled) {
                return;
            }
            if (event.keyCode === keyCodeEnum.ENTER) {
                event.preventDefault();
                this.handleClick(event);
            }
        };

        render() {
            // this gives class names for css purposes
            const anchorClassNames = classNames({
                active: this.props.active,
                disabled: this.props.disabled,
            });

            const iconRight = (this.props.iconRight || this.props.hoveredIconRight) && (
                <span className={'react-icon-right'}>
                    {this.props.hoveredIconRight && this.state.hovered && !this.props.disabled
                        ? this.props.hoveredIconRight
                        : this.props.iconRight}
                </span>
            );
            let ButtonType: React.ElementType = ReactSecondaryButton;
            let IconType: React.ElementType = ReactButtonIcon;
            const className = this.props.className;
            if (className) {
                if (className.indexOf('mark43-react-primary-button') > -1) {
                    ButtonType = ReactPrimaryButton;
                    IconType = ReactButtonIconLeft;
                } else if (className.indexOf('mark43-react-secondary-button') > -1) {
                    ButtonType = ReactSecondaryButton;
                    IconType = ReactButtonIconLeft;
                } else if (className.indexOf('mark43-react-secondary-bold-button') > -1) {
                    ButtonType = ReactSecondaryBoldButton;
                    IconType = ReactButtonIconLeft;
                } else if (className.indexOf('mark43-react-utility-button') > -1) {
                    ButtonType = ReactUtilityButton;
                    IconType = ReactButtonIconLeft;
                } else if (className.indexOf('mark43-react-small-go-button') > -1) {
                    ButtonType = ReactSmallGoButton;
                } else if (className.indexOf('mark43-react-icon-text-link') > -1) {
                    ButtonType = ReactIconTextLink;
                    IconType = 'span';
                } else if (className.indexOf('mark43-react-form-row-icon-link') > -1) {
                    ButtonType = ReactFormRowIconLink;
                    IconType = 'span';
                } else if (className.indexOf('mark43-react-icon-button') > -1) {
                    ButtonType = ReactIconButton;
                    IconType = 'span';
                }
            }

            let iconLeftComponent = this.props.iconLeft;
            if (typeof this.props.iconLeft === 'string') {
                iconLeftComponent = (
                    <Icon
                        size={12}
                        color={this.props.disabled ? 'mediumGrey' : 'cobaltBlue'}
                        // @ts-expect-error need to narrow the type of iconLeft from string to ValueOf<typeof iconTypes>
                        type={iconTypes[this.props.iconLeft]}
                    />
                );
            }

            const iconLeft = (this.props.iconLeft || this.props.hoveredIconLeft) && (
                <IconType className={'react-icon-left'}>
                    {this.props.hoveredIconLeft && this.state.hovered && !this.props.disabled
                        ? this.props.hoveredIconLeft
                        : iconLeftComponent}
                </IconType>
            );

            // renders the button, given the class name and the on click handler
            // shows the icon if it exists, and props.children (which is basically just the button title)
            return (
                <ButtonType
                    data-test-id={this.props.testId}
                    data-test-display={
                        this.props.testDisplay && this.props.testDisplay.toLowerCase()
                    }
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    className={`${this.props.className} ${anchorClassNames}`}
                    id={this.props.id}
                    onClick={this.handleClick}
                    style={this.props.style}
                    onKeyDown={this.handleKeyPress}
                    tabIndex={this.props.disabled ? -1 : 0}
                    display={this.props.display}
                    float={this.props.float}
                    ref={(button: HTMLDivElement) => (this.button = button)}
                >
                    {iconLeft}
                    <span className="textvalignmiddle">
                        {this.props.hoveredChildren && this.state.hovered
                            ? this.props.hoveredChildren
                            : this.props.children}
                    </span>
                    {iconRight}
                </ButtonType>
            );
        }

        handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            this._setHovered(true);
            if (this.props.onMouseEnter) {
                this.props.onMouseEnter(e);
            }
        };

        handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            this._setHovered(false);
            if (this.props.onMouseLeave) {
                this.props.onMouseLeave(e);
            }
        };

        _setHovered(isHovered: boolean) {
            this.setState({
                hovered: !!isHovered,
            });
        }

        // handles the click event
        // doesn't do anything if it's disabled
        // also handles the keyboard event if it's ENTER
        handleClick = (
            e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
        ) => {
            // if stopPropagation prop is true, then handle the click and
            // stop propagating the action.
            // Events are React `SyntheticEvent`s, so below will work identically
            // across all browsers.
            if (this.props.stopPropagation) {
                e.stopPropagation();
            }

            if (this.props.disabled) {
                return;
            }

            // runs the onclick handler passed to it from the children components
            if (this.props.onClick) {
                this.props.onClick(e, this.props.value);
            }
        };
    }
);

export default Button;

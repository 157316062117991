import React from 'react';
import styled from 'styled-components';
import { Icon as ArcIcon } from 'arc';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Tooltip } from '../../../core/components/tooltip';
import { ExternalLink, ExternalLinkStyles, PopoutIcon } from '../../../core/components/links/Link';
import { SUPPORT_URL } from '../../../../core/constants';

const strings = componentStrings.search.core.SearchCapabilityIcon;
const toolTipURL = `${SUPPORT_URL}article/Using-Boolean-and-Wildcards`;

export const SEARCH_CAPABILITY_TYPE_ENUM = {
    BOOLEAN: {
        featureFlag: 'RMS_BOOLEAN_SEARCH_ENABLED',
        linkText: strings.booleanLinkText,
        linkUrl: toolTipURL,
        name: 'BOOLEAN',
        arcName: 'Boolean',
    },
    WILDCARD: {
        featureFlag: 'RMS_WILDCARD_SEARCH_ENABLED',
        linkText: strings.wildcardLinkText,
        linkUrl: toolTipURL,
        name: 'WILDCARD',
        arcName: 'Wildcard',
    },
} as const;

const Overlay = styled.div`
    width: 130px;
    text-align: center;

    // override Link default styles for Tooltip context
    && ${/* sc-styled */ ExternalLinkStyles} {
        color: ${(props) => props.theme.colors.white};
        text-decoration: underline;
    }

    // when openInNewTab=true, we want to hide Link PopoutIcon
    ${/* sc-styled */ PopoutIcon} {
        display: none;
    }
`;

const SearchCapabilityIcon: React.FC<{ variant: keyof typeof SEARCH_CAPABILITY_TYPE_ENUM }> = (
    props
) => {
    const { variant } = props;
    const capabilityEnum = SEARCH_CAPABILITY_TYPE_ENUM[variant];

    return (
        <FeatureFlagged flag={capabilityEnum.featureFlag}>
            <Tooltip
                content={
                    <Overlay>
                        <ExternalLink openInNewTab={true} to={capabilityEnum.linkUrl}>
                            {capabilityEnum.linkText}
                        </ExternalLink>{' '}
                        {strings.tooltipCommon}
                    </Overlay>
                }
            >
                <ArcIcon icon={capabilityEnum.arcName} size="sm" />
            </Tooltip>
        </FeatureFlagged>
    );
};

export default SearchCapabilityIcon;

export const getCaseAssignedTo = ({ assigneeRole, assignedPersonnelUnit }) =>
    `Assigned to ${assigneeRole || assignedPersonnelUnit}`;

export const getCaseStatus = ({ caseStatus }) => `Status: ${caseStatus}`;

export const getCaseTitle = ({ localId, caseDefinition, caseFieldName = '', title = '' }) => {
    const initialString = `${caseFieldName} #${localId}`;
    const titleSuffix = title ? ` - ${title}` : '';
    return `${initialString}${titleSuffix}: ${caseDefinition}`;
};

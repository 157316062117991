import { filter, last, map, reduce, sortBy, uniq } from 'lodash';
import { isAfter, isBefore } from '~/client-common/core/dates/utils/dateHelpers';

export const sortedReportSupplementHistories = (reportSupplementHistories) =>
    sortBy(reportSupplementHistories, 'historyCreatedDateUtc');

export const reportSupplementHistoryIsAfter = ({
    baseReportSupplementHistory,
    reportSupplementHistoryToCheck,
}) =>
    isAfter(
        baseReportSupplementHistory.historyCreatedDateUtc,
        reportSupplementHistoryToCheck.historyCreatedDateUtc
    );

export const reportSupplementHistoryIsBefore = ({
    baseReportSupplementHistory,
    reportSupplementHistoryToCheck,
}) =>
    isBefore(
        baseReportSupplementHistory.historyCreatedDateUtc,
        reportSupplementHistoryToCheck.historyCreatedDateUtc
    );

export const mostRecentReportSupplementHistoryForReport = ({
    reportSupplementHistories,
    reportId,
}) => last(sortedReportSupplementHistories(filter(reportSupplementHistories, { reportId })));

/**
 * Given a collection of `ReportSupplementHistory`s, find the most recent `ReportSupplementHistory`
 * record for each unique `reportId`.
 */
export const sortedMostRecentReportSupplementHistoriesByReportId = ({
    reportSupplementHistories,
}) => {
    const reportIds = uniq(map(reportSupplementHistories, 'reportId'));
    const mostRecentReportSupplementHistories = reduce(
        reportIds,
        (accumulatedReportSupplementHistories, reportId) => {
            return [
                ...accumulatedReportSupplementHistories,
                mostRecentReportSupplementHistoryForReport({
                    reportSupplementHistories,
                    reportId,
                }),
            ];
        },
        []
    );
    return sortedReportSupplementHistories(mostRecentReportSupplementHistories);
};

import { NibrsExportQuery, NibrsexportResource } from '@mark43/rms-api';
import { req } from '../../../lib/ajax';
import { createResource } from '../../../lib/resources/Resource';
import { COMPLIANCE_DEFAULT_PAGE_SIZE } from '../configuration';

export default createResource({
    name: 'NIBRS Export Resource',
    methods: {
        getExportHistoryForAgencyId(agencyId: number) {
            return req<NibrsexportResource.GetNibrsHistory>({
                method: 'GET',
                url: `nibrs/export/history/${agencyId}/`,
            });
        },
        getLastExportMonthForAgencyId(agencyId: number) {
            return req<NibrsexportResource.GetNibrsLastExportMonthDeprecated>({
                method: 'GET',
                url: `nibrs/export/month/${agencyId}/`,
            });
        },
        getExportErrors(exportId: string) {
            return req<NibrsexportResource.GetNibrsErrorSummary>({
                method: 'POST',
                url: 'nibrs/export/errors',
                params: { uuid: exportId, from: 0, to: COMPLIANCE_DEFAULT_PAGE_SIZE },
            });
        },
        getExportErrorsPaginated(
            exportId: string,
            from: number,
            to: number,
            queryAllReports: boolean
        ) {
            return req<NibrsexportResource.GetNibrsErrorSummary>({
                method: 'POST',
                url: 'nibrs/export/errors',
                params: { uuid: exportId, from, to, queryAllReports },
            });
        },
        checkFinalExportStatus(data: NibrsExportQuery) {
            return req<NibrsexportResource.CheckExistingFinalExports>({
                method: 'POST',
                url: 'nibrs/export/check/final',
                data,
            });
        },
        deleteNibrsExportHistoryFromDate(agencyId: number, date: string) {
            return req<NibrsexportResource.DeleteNibrsFinalExportsFromDate>({
                method: 'DELETE',
                params: { date },
                url: `nibrs/export/from/month/${agencyId}/`,
            });
        },
        getAllComplianceResubmission(agencyId: number) {
            return req<NibrsexportResource.GetAllComplianceResubmission>({
                method: 'GET',
                url: `nibrs/export/resubmissions/${agencyId}/`,
            });
        },
    },
});

import { map, filter, reduce } from 'lodash';
import {
    StopEntityAttribute,
    AttributeView,
    AttributeTypeEnum,
    EntityTypeEnum,
    StopAnonymousSubject,
} from '@mark43/rms-api';
import {
    STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_TYPE_OF_SEARCH_ATTRIBUTE_ID,
    STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_SEARCH_ATTRIBUTE_ID,
    STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_PROPERTY_TYPE_OF_SEARCH_ATTRIBUTE_ID,
    STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID,
    STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_PROPERTY_REASON_FOR_SEARCH_ATTRIBUTE_ID,
    STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_OBJECT_OF_SEARCH_ATTRIBUTE_ID,
    STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID,
} from '../../../enums/universal/fields';
import { ModuleShape } from '../../../utils/createNormalizedModule';

const {
    TYPE_OF_SEARCH,
    PROPERTY_TYPE_OF_SEARCH,
    REASON_FOR_SEARCH,
    PROPERTY_REASON_FOR_SEARCH,
    STOP_TYPE_OF_PROPERTY_SEIZED,
    STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED,
    OBJECT_OF_SEARCH,
} = AttributeTypeEnum;

/**
 * Given a list of stop entity attributes, this function will return a new list with a description mapped on to the
 *   appropriate stop entity attributes (where the linked attribute "is other").
 */
export function assignDescriptionToStopEntityAttributes<
    T extends { attributeId: number } = StopEntityAttribute
>(
    stopEntityAttributes: Partial<T>[],
    allAttributes: ModuleShape<AttributeView>,
    description: string | undefined
): Partial<T>[] {
    return map(stopEntityAttributes, (stopEntityAttribute) => {
        if (
            stopEntityAttribute.attributeId &&
            allAttributes[stopEntityAttribute.attributeId]?.other
        ) {
            return {
                ...stopEntityAttribute,
                description,
            };
        } else {
            return stopEntityAttribute;
        }
    });
}

type SupportedStopEntityAttributes =
    | typeof TYPE_OF_SEARCH
    | typeof PROPERTY_TYPE_OF_SEARCH
    | typeof REASON_FOR_SEARCH
    | typeof PROPERTY_REASON_FOR_SEARCH
    | typeof STOP_TYPE_OF_PROPERTY_SEIZED
    | typeof STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED
    | typeof OBJECT_OF_SEARCH;

type StopEntityAttributeDisplayT = {
    attributeType: SupportedStopEntityAttributes;
    fieldName: string;
    attributes: StopEntityAttribute[];
};

const stopEntityAttributesToFieldsMap = {
    [TYPE_OF_SEARCH.name]: STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_TYPE_OF_SEARCH_ATTRIBUTE_ID,
    [REASON_FOR_SEARCH.name]: STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_SEARCH_ATTRIBUTE_ID,
    [STOP_TYPE_OF_PROPERTY_SEIZED.name]: STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID,
    [PROPERTY_TYPE_OF_SEARCH.name]: STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_PROPERTY_TYPE_OF_SEARCH_ATTRIBUTE_ID,
    [PROPERTY_REASON_FOR_SEARCH.name]: STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_PROPERTY_REASON_FOR_SEARCH_ATTRIBUTE_ID,
    [OBJECT_OF_SEARCH.name]: STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_OBJECT_OF_SEARCH_ATTRIBUTE_ID,
    [STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED.name]: STOP_ENTITY_ATTRIBUTE_ATTRIBUTE_TYPE_STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED_ATTRIBUTE_ID,
} as const;

export function convertStopEntityAttributesForDisplay(
    stopEntityAttributes: StopEntityAttribute[],
    isPerson: boolean
): StopEntityAttributeDisplayT[] {
    const personOrPropertyAttributeType = isPerson ? TYPE_OF_SEARCH : PROPERTY_TYPE_OF_SEARCH;

    const typeOfSearchAttributes = filter(stopEntityAttributes, {
        attributeType: personOrPropertyAttributeType.name,
    }) as StopEntityAttribute[];

    return reduce(
        typeOfSearchAttributes,
        (result, typeOfSearchEntityAttribute) => {
            const {
                attributeId: typeOfSearchAttributeId,
                attributeType,
            } = typeOfSearchEntityAttribute;

            result.push({
                attributeType: personOrPropertyAttributeType,
                fieldName:
                    stopEntityAttributesToFieldsMap[
                        attributeType as keyof typeof stopEntityAttributesToFieldsMap
                    ],
                attributes: [typeOfSearchEntityAttribute],
            });

            // Reason For Search
            const reasonForSearchAttributeType = isPerson
                ? REASON_FOR_SEARCH
                : PROPERTY_REASON_FOR_SEARCH;

            const reasonForSearchStopEntityAttributes = filter(stopEntityAttributes, {
                mapToAttributeId: typeOfSearchAttributeId,
                attributeType: reasonForSearchAttributeType.name,
            });

            if (reasonForSearchStopEntityAttributes.length > 0) {
                result.push({
                    attributeType: reasonForSearchAttributeType,
                    fieldName: stopEntityAttributesToFieldsMap[reasonForSearchAttributeType.name],
                    attributes: reasonForSearchStopEntityAttributes,
                });
            }

            // Object of search (Property Only)
            if (!isPerson) {
                const objectOfSearchStopEntityAttributes = filter(stopEntityAttributes, {
                    mapToAttributeId: typeOfSearchAttributeId,
                    attributeType: OBJECT_OF_SEARCH.name,
                });
                if (objectOfSearchStopEntityAttributes.length > 0) {
                    result.push({
                        attributeType: OBJECT_OF_SEARCH,
                        fieldName: stopEntityAttributesToFieldsMap[OBJECT_OF_SEARCH.name],
                        attributes: objectOfSearchStopEntityAttributes,
                    });
                }
            }

            // Property Searched/Seized
            const propertySearchSeizedAttributeType = isPerson
                ? STOP_TYPE_OF_PROPERTY_SEIZED
                : STOP_PROPERTY_TYPE_OF_PROPERTY_SEIZED;

            const propertySeizedStopEntityAttributes = filter(stopEntityAttributes, {
                mapToAttributeId: typeOfSearchAttributeId,
                attributeType: propertySearchSeizedAttributeType.name,
            });

            if (propertySeizedStopEntityAttributes.length > 0) {
                result.push({
                    attributeType: propertySearchSeizedAttributeType,
                    fieldName:
                        stopEntityAttributesToFieldsMap[propertySearchSeizedAttributeType.name],
                    attributes: propertySeizedStopEntityAttributes,
                });
            }

            return result;
        },
        [] as StopEntityAttributeDisplayT[]
    );
}

export const filterStopEntityAttributes = ({
    stopEntityAttributes,
    stopAnonymousSubject,
}: {
    stopEntityAttributes: StopEntityAttribute[];
    stopAnonymousSubject: StopAnonymousSubject;
}): StopEntityAttribute[] => {
    return filter([...stopEntityAttributes], {
        entityId: stopAnonymousSubject.id,
        entityType: EntityTypeEnum.STOP_ANONYMOUS_SUBJECT.name,
    });
};

import React from 'react';
import styled from 'styled-components';
import { cssVar } from 'arc';
import { isEmpty, map, take } from 'lodash';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { interpolateMessage } from '~/client-common/core/arbiter-utils/templateRunner';

import { CustomLink as _CustomLink } from '../../../modules/core/components/links/Link';
import testIds from '../../../core/testIds';
import { scrollToFieldOrSectionWithinCard } from '../../helpers/scrollToFieldOrSectionWithinCard';
import { InlineBanner, InlineBannerErrorList } from '../../../modules/core/components/InlineBanner';
import { useOverlayStore } from '../../../modules/core/overlays/hooks/useOverlayStore';
import { SCREENS } from '../../../modules/core/locations/state/ui';
import {
    CAUTIONS,
    CONTACT_INFO,
    CORE_DETAILS,
    EVENT_SPECIFIC_INFO,
    MISC_INFO,
} from '../../../modules/core/persons/config/personProfileFormSections';

const strings = componentStrings.core.CardHeaderErrorsSection;
const SHORT_LIST_COUNT = 6;

export interface SidePanelLinkingError {
    formErrors: string[];
    linkDisplay: string;
    message: string;
    overlayId: string;
    personProfileId: number;
}

interface CardHeaderErrorsSectionProps {
    errors: (string | SidePanelLinkingError)[];
    summaryMode?: boolean;
}

const StyledCardHeaderErrorsSection = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    border-left: solid 1px;
    border-right: solid 1px;
    border-color: ${({ theme }) => `${theme.colors.lightGrey}`};
    padding: ${cssVar('arc.space.2')};
    padding-bottom: 0;
`;

const ExpandCollapseButton = styled(_CustomLink)`
    font-weight: unset;
    margin-top: ${cssVar('arc.space.2')};
`;

const CustomErrorLink = styled(_CustomLink)`
    font-weight: unset;
`;

const getKeyForRawPanelError = (rawPanelError: string | SidePanelLinkingError) =>
    typeof rawPanelError === 'string'
        ? rawPanelError
        : `${rawPanelError.personProfileId} ${rawPanelError.message}`;

const ErrorWithSidePanelLink = ({
    personProfileId,
    linkDisplay,
    message,
    formErrors,
    overlayId,
}: SidePanelLinkingError) => {
    const overlayStore = useOverlayStore();
    return (
        <span>
            <CustomErrorLink
                data-test-id={testIds.CARD_ERROR_LINK}
                onClick={() =>
                    overlayStore.open(overlayId, {
                        entityId: personProfileId,
                        screenStack: [
                            {
                                screen: SCREENS.PROFILE_EDIT,
                                screenState: {
                                    errorMessages: formErrors,
                                    selectedId: personProfileId,
                                },
                                formSectionState: {
                                    [CAUTIONS]: true,
                                    [CORE_DETAILS]: true,
                                    [CONTACT_INFO]: true,
                                    [EVENT_SPECIFIC_INFO]: true,
                                    [MISC_INFO]: true,
                                },
                            },
                        ],
                    })
                }
            >
                {linkDisplay}
            </CustomErrorLink>
            {`: ${message}`}
        </span>
    );
};

export const CardHeaderErrorsSection: React.FC<CardHeaderErrorsSectionProps> = ({ errors }) => {
    const [expanded, setExpanded] = React.useState(false);
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    if (isEmpty(errors)) {
        return null;
    }

    function renderErrorLink(rawPanelError: string | SidePanelLinkingError) {
        return typeof rawPanelError === 'string' ? (
            interpolateMessage(
                rawPanelError,
                (fieldName: string, index: number, selector?: string) => (
                    <CustomErrorLink
                        key={index}
                        data-test-id={testIds.CARD_ERROR_LINK}
                        onClick={(e) => {
                            scrollToFieldOrSectionWithinCard({
                                clickedElement: e.currentTarget,
                                ...(selector ? { selector } : { fieldName }),
                            });
                        }}
                    >
                        {formatFieldByName(fieldName) || fieldName}
                    </CustomErrorLink>
                )
            )
        ) : (
            <ErrorWithSidePanelLink {...rawPanelError} />
        );
    }

    return (
        <StyledCardHeaderErrorsSection data-test-id={testIds.CARD_PANEL_ERRORS}>
            <InlineBanner status="error">
                {errors.length === 1 ? (
                    renderErrorLink(errors[0])
                ) : (
                    <InlineBannerErrorList>
                        {map(
                            expanded ? errors : take(errors, SHORT_LIST_COUNT),
                            (rawPanelError) => (
                                <li key={getKeyForRawPanelError(rawPanelError)}>
                                    {renderErrorLink(rawPanelError)}
                                </li>
                            )
                        )}
                    </InlineBannerErrorList>
                )}
                {errors.length > SHORT_LIST_COUNT && (
                    <ExpandCollapseButton
                        onClick={() => setExpanded(!expanded)}
                        data-test-id={testIds.SHOW_MORE_ERRORS_LINK}
                    >
                        {expanded ? strings.showLess : strings.showMore}
                    </ExpandCollapseButton>
                )}
            </InlineBanner>
        </StyledCardHeaderErrorsSection>
    );
};

import React from 'react';
import styled from 'styled-components';
import { filter } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import _DropdownMenu from '../../../../core/components/DropdownMenu';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.core.header.RecordsHeaderHamburgerMenu;

const DropdownMenu = styled(_DropdownMenu)`
    margin: 0;

    .dropdown-menu-button {
        width: 48px;
    }
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
`;

export default function RecordsHeaderHamburgerMenu({ children, dropdownMenuOptionWidth }) {
    if (!filter(children).length) {
        return <div />;
    }
    return (
        <DropdownMenu
            buttonContent={
                <DropdownMenuButtonContainer>
                    <Icon size={16} type={iconTypes.MORE_OPTIONS} />
                    <Icon size={16} type={iconTypes.TRIANGLE_DOWN} />
                </DropdownMenuButtonContainer>
            }
            width={dropdownMenuOptionWidth}
            tooltip={strings.tooltip}
            testId={testIds.RECORD_HAMBURGER_MENU}
        >
            {children}
        </DropdownMenu>
    );
}

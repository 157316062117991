import { DeliveryMethodEnum } from '@mark43/notifications-api';

/* eslint-disable camelcase */

import _, { map } from 'lodash';
import {
    convertBundleToClientModel,
    convertViewToClientModel,
    convertClientModelToBundle,
    convertClientModelToSendNotificationView,
} from '../utils/userNotificationHelpers';

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/notifications/api';
const BASE_PATH = 'notifications';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Notifications Resource',
        methods: {
            /**
             * Get notifications for the current user.
             * @param  {Object}  query
             * @param  {string}  [query.startDateUtc]
             * @param  {boolean} [query.includeRead=true] Every notification is
             *   marked as read when the user opens the notifications popover,
             *   and remains read until it is directly clicked on.
             * @param  {boolean} [query.includeUnread=true]
             * @param  {boolean} [query.includeAcknowledged=true] A notification
             *   is acknowledged when the user clicks on it.
             * @param  {boolean} [query.includeArchived=false] A notification is
             *   archived when the user chooses to archive it in the dashboard.
             * @param  {boolean} [query.includeAlerts=false] Alerts are a subset of
             *   notifications. And are currently displayed differently.
             * @param  {boolean} [query.includeWeb=true] Web App notifications
             * @param  {boolean} [query.includeExpiredAlerts=true] Notifications
             *  from expired alerts if alert notifications are included
             * @param  {number}  [query.from]
             * @param  {number}  [query.size]
             * @param  {boolean} [options.hideLoadingBar=false]
             * @return {Promise<Object>}
             */
            getCurrentUserNotifications(
                {
                    startDateUtc,
                    includeRead = true,
                    includeUnread = true,
                    includeAcknowledged = true,
                    includeArchived = false,
                    includeAlerts = false,
                    includeWeb = true,
                    includeExpiredAlerts = true,
                    from,
                    size,
                },
                { hideLoadingBar = false } = {}
            ) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/user/current`,
                    params: {
                        start_date_utc: startDateUtc,
                        include_read: includeRead,
                        include_unread: includeUnread,
                        include_acknowledged: includeAcknowledged,
                        include_archived: includeArchived,
                        include_alerts: includeAlerts,
                        include_web: includeWeb,
                        include_expired_alerts: includeExpiredAlerts,
                        from,
                        size,
                    },
                    hideLoadingBar,
                }).then(convertViewToClientModel);
            },

            /**
             * Polling endpoint for user notifications.
             *  Returns alerts, notifications count and session timeout.
             * @return {Promise<Object>}
             */
            pollUserNotifications() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/user/poll`,
                    hideLoadingBar: true,
                }).then(convertViewToClientModel);
            },

            /**
             * Create a notification, which must be for the current user. This
             *   resource is for testing purposes only, as real notifications
             *   get generated by the server, not by the client.
             * @param  {Object}  userNotification
             * @param  {boolean} [options.hideLoadingBar=false]
             * @return {Promise<Object>}
             */
            createCurrentUserNotification(userNotification, { hideLoadingBar = false } = {}) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/user/current`,
                    data: convertClientModelToBundle(userNotification),
                    hideLoadingBar,
                }).then(convertBundleToClientModel);
            },

            /**
             * Update the given notification for the current user.
             * @param  {Object}  userNotification
             * @param  {boolean} [options.hideLoadingBar=false]
             * @return {Promise<Object>}
             */
            updateCurrentUserNotification(userNotification, { hideLoadingBar = false } = {}) {
                return req({
                    method: 'PUT',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/user/current/${userNotification.userNotificationLinkId}`,
                    data: convertClientModelToBundle(userNotification),
                    hideLoadingBar,
                }).then(convertBundleToClientModel);
            },

            /**
             * Update the given notifications for the current user. This
             *   response can include `EMAIL` notifications, which must be
             *   filtered out because we deal with only `WEB_APP` notifications
             *   in the browser.
             * @param  {Object}  userNotifications
             * @param  {boolean} [options.hideLoadingBar=false]
             * @return {Promise<Object[]>}
             */
            updateCurrentUserNotifications(userNotifications, { hideLoadingBar = false } = {}) {
                return req({
                    method: 'PUT',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/user/current/bulk`,
                    data: map(userNotifications, convertClientModelToBundle),
                    hideLoadingBar,
                }).then((userNotifications) => {
                    return _(userNotifications)
                        .omit({ deliveryMethod: DeliveryMethodEnum.EMAIL.name })
                        .map(convertBundleToClientModel)
                        .value();
                });
            },

            /**
             * Mark as `afterStatus` all of the current user's notifications with
             *   status in `beforeStatuses` up to `endDateUtc`.
             * @param  {Object}  query
             * @param  {string[]}  [query.beforeStatuses]
             *   Mark only notifications that currently have statuses in this set
             * @param  {string}  [query.afterStatus]
             *   mark notifications with this status
             * @param  {string}  [query.endDateUtc] Limit the notifications to
             *   mark as read by this date. This is useful because we don't want
             *   to mark as read notifications that have been generated for the
             *   user since the last poll and they haven't gotten a chance to
             *   see yet.
             * @param  {boolean} [options.hideLoadingBar=true]
             * @return {Promise<boolean>}
             */
            markUserNotifications(
                { beforeStatuses, afterStatus, endDateUtc },
                { hideLoadingBar = true } = {}
            ) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/user/current/mark`,
                    data: { beforeStatuses, afterStatus, endDateUtc },
                    hideLoadingBar,
                });
            },

            /**
             * Admin resource to create a notification for an arbitrary user.
             *   This resource is for testing purposes only, as real
             *   notifications get generated by the server, not by the client.
             * @param  {Object}   userNotification
             * @param  {boolean}  [options.hideLoadingBar=false]
             * @return {Promise<Object[]>}
             */
            createUserNotification(userNotification, { hideLoadingBar = false } = {}) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/send`,
                    data: convertClientModelToSendNotificationView(userNotification),
                    hideLoadingBar,
                });
            },
        },
    });
}

/**
 * Resources for notifications to the current user. This is temporarily a
 *   function to avoid dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

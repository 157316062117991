import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop, first, orderBy } from 'lodash';

import {
    ComplianceExportTypeEnum,
    NibrsFinalExportStateEnum,
    NibrsFinalExportStateEnumType,
} from '@mark43/rms-api';

import { dateToMonthYear } from '~/client-common/core/dates/utils/dateHelpers';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import getExportsResource from '~/client-common/core/domain/exports/resources/exportsResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { currentUserDepartmentProfileSelector } from '../../core/current-user/state/ui';
import { RmsDispatch } from '../../../core/typings/redux';
import { closeBox, openBox, saveBoxHalt } from '../../../legacy-redux/actions/boxActions';
import { exportDocument } from '../../../legacy-redux/actions/exportsActions';
import { OnlyWithAbility } from '../../core/abilities';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';

import reportPrintingResource from '../../admin/report-printing-templates/resources/reportPrintingResource';
import complianceExportResource from '../resources/complianceExportResource';
import nibrsExportResource from '../resources/nibrsExportResource';
import {
    lastExportedDateSelector,
    nibrsConfigStartDateUtcByAgencyIdSelector,
    uiDisableComplianceExportFormControls,
} from '../state/ui';
import useSubmissionType from '../hooks/useIsExternalSubmission';
import { createPoller } from '../util/complianceExportTypeHelpers';
import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import {
    NIBRS_COMPLIANCE_TYPE,
    exportNibrsDownloadModalContext,
    exportNibrsNotMostRecentMonthModalContext,
    exportNibrsOverwriteModalContext,
} from '../configuration';
import { ComplianceExportType, FetchErrorsSuccessType } from '../types';
import ComplianceDownloadButton from './ComplianceDownloadButton';
import { convertFromFormModel } from './ComplianceExportForm';
import { convertFromResubmissionFormModel } from './ComplianceResubmissionForm';

type ComplianceExportDownloadButtonProps = {
    className?: string;
    complianceExportType: ComplianceExportType;
    disabled?: boolean;
    onFetchErrorsSuccess?: FetchErrorsSuccessType;
    isResubmission?: boolean;
};

const strings = componentStrings.compliance.ComplianceExportDownloadButton;

function useConvertedFormModelGetter(
    segmentGenerationEnabled: boolean,
    currentAgencyId: number | undefined,
    isResubmission?: boolean
) {
    const { getForm } = useFormGetter();

    const getter = useCallback(() => {
        const formName = isResubmission
            ? formClientEnum.COMPLIANCE_RESUBMISSION
            : formClientEnum.COMPLIANCE_EXPORT;
        const convertForm = isResubmission
            ? convertFromResubmissionFormModel
            : convertFromFormModel;
        const form = getForm(formName);
        const formModel = form?.getState().model;

        // form is already submitted and validated
        if (!formModel) {
            return;
        }

        return convertForm(formModel, segmentGenerationEnabled, currentAgencyId);
    }, [currentAgencyId, getForm, isResubmission, segmentGenerationEnabled]);

    return getter;
}

// Exportable months != Month options because we can generate errors for non-exportable months.
// In other words, the export month is more restrictive than generate errors month.
function useSelectedDateIntervalIsExportable() {
    const lastExportedDate = useSelector(lastExportedDateSelector);
    const nibrsConfigStartDateUtcByAgencyId = useSelector(
        nibrsConfigStartDateUtcByAgencyIdSelector
    );
    const currentUserDepartment = useSelector(currentUserDepartmentProfileSelector);

    const { getForm } = useFormGetter();
    const form = getForm(formClientEnum.COMPLIANCE_EXPORT);
    const formModel = form?.getState().model;

    const nibrsConfigStartDateUtc = nibrsConfigStartDateUtcByAgencyId(formModel?.agencyId);

    if (!formModel?.exportDateUtc) {
        return false;
    }
    const { month: formMonth } = dateToMonthYear(formModel?.exportDateUtc);

    // no previous exports and no nibrs start date
    if (!lastExportedDate && !nibrsConfigStartDateUtc) {
        return true;
    }

    // no previous exports. nibrs start date is only valid month
    if (!lastExportedDate && nibrsConfigStartDateUtc) {
        const nibrsConfigMonthYear = dateToMonthYear(
            nibrsConfigStartDateUtc,
            currentUserDepartment?.timeZone
        );
        return formMonth === nibrsConfigMonthYear.month;
    }

    if (lastExportedDate) {
        const { month: lastExportedMonth } = dateToMonthYear(lastExportedDate);

        // handle dec-jan wraparound
        const nextValidMonth = lastExportedMonth < 12 ? lastExportedMonth + 1 : 1;

        return formMonth === lastExportedMonth || formMonth === nextValidMonth;
    }

    return false;
}
const ComplianceExportDownloadButton: React.FC<ComplianceExportDownloadButtonProps> = (props) => {
    const {
        className,
        complianceExportType,
        disabled,
        isResubmission,
        onFetchErrorsSuccess,
    } = props;
    const { callGetLastExportedDateResource, currentAgencyId } = useComplianceExportContext();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const { isExternalSubmission, isNibrs } = useSubmissionType(complianceExportType);
    const isUseOfForce =
        complianceExportType?.name === ComplianceExportTypeEnum.US_USE_OF_FORCE.name;

    const { getForm } = useFormGetter();
    const dispatch = useDispatch<RmsDispatch>();
    const selectedDateIntervalIsExportable = useSelectedDateIntervalIsExportable();
    const convertedFormModelGetter = useConvertedFormModelGetter(
        !!applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED,
        currentAgencyId,
        isResubmission
    );

    const openDownloadModal = useCallback(() => {
        const formName = isResubmission
            ? formClientEnum.COMPLIANCE_RESUBMISSION
            : formClientEnum.COMPLIANCE_EXPORT;
        const form = getForm(formName);

        form?.submit()
            .then(() => dispatch(openBox(exportNibrsDownloadModalContext)))
            // no validation messages, required errors are displayed inline on inputs
            .catch(noop);
    }, [dispatch, getForm, isResubmission]);

    const onGenerateFinalExportSuccessCallback = useCallback(
        (results) => {
            dispatch(uiDisableComplianceExportFormControls(false));
            const mostRecentExport = first(orderBy(results, ['createdDateUtc'], ['desc']));

            const complianceForm = getForm(formClientEnum.COMPLIANCE_EXPORT);
            if (complianceForm) {
                const formModel = complianceForm.get();
                if (formModel && formModel.agencyId) {
                    callGetLastExportedDateResource(formModel.agencyId);
                }
            }

            if (isExternalSubmission) {
                const pollForErrorResults = createPoller(
                    getExportsResource().pollForExportIds,
                    isNibrs
                        ? nibrsExportResource.getExportErrors
                        : complianceExportResource.getComplianceErrorSummary
                );
                return pollForErrorResults([mostRecentExport.id]).then((results) => {
                    if (onFetchErrorsSuccess) {
                        onFetchErrorsSuccess(results);
                    }
                });
            }
            return;
        },
        [
            dispatch,
            getForm,
            isExternalSubmission,
            callGetLastExportedDateResource,
            isNibrs,
            onFetchErrorsSuccess,
        ]
    );

    const generateFinalExport = useCallback(
        (payload) => {
            let username;
            let password;
            const complianceExportAuthenticationForm = getForm(
                formClientEnum.COMPLIANCE_EXPORT_AUTHENTICATION_FORM
            );
            if (complianceExportAuthenticationForm) {
                const {
                    username: formUsername,
                    password: formPassword,
                } = complianceExportAuthenticationForm.get();
                username = formUsername;
                password = formPassword;
            }

            dispatch(uiDisableComplianceExportFormControls(true));
            if (isNibrs) {
                dispatch(
                    exportDocument({
                        requestData: {
                            ...payload,
                            username,
                            password,
                            resubmission: isResubmission,
                        },
                        exportResourceMethod: reportPrintingResource.generateFinalNibrsExport,
                        onSuccess: onGenerateFinalExportSuccessCallback,
                        disableUsageLogs: true,
                    })
                );
            } else {
                dispatch(
                    exportDocument({
                        requestData: {
                            ...payload,
                            complianceExportType: complianceExportType?.name,
                            username,
                            password,
                            resubmission: isResubmission,
                        },
                        exportResourceMethod: reportPrintingResource.generateComplianceExportFinal,
                        onSuccess: onGenerateFinalExportSuccessCallback,
                        disableUsageLogs: true,
                    })
                );
            }
        },
        [
            isNibrs,
            complianceExportType,
            dispatch,
            getForm,
            onGenerateFinalExportSuccessCallback,
            isResubmission,
        ]
    );

    const confirmModalOnSave = useCallback(async () => {
        const payload = convertedFormModelGetter();

        const success = (result: NibrsFinalExportStateEnumType) => {
            dispatch(saveBoxHalt(exportNibrsDownloadModalContext));
            dispatch(closeBox(exportNibrsDownloadModalContext));
            if (result === NibrsFinalExportStateEnum.LEGAL_FIRST_EXPORT.name) {
                generateFinalExport(payload);
            } else if (result === NibrsFinalExportStateEnum.LEGAL_OVERWRITE.name) {
                dispatch(openBox(exportNibrsOverwriteModalContext));
            } else if (
                result === NibrsFinalExportStateEnum.ILLEGAL_NOT_RECENT.name ||
                result === NibrsFinalExportStateEnum.ILLEGAL_NOT_CONSECUTIVE.name
            ) {
                dispatch(openBox(exportNibrsNotMostRecentMonthModalContext));
            }
        };

        const complianceExportAuthenticationForm = getForm(
            formClientEnum.COMPLIANCE_EXPORT_AUTHENTICATION_FORM
        );

        if (complianceExportAuthenticationForm) {
            try {
                const result = await complianceExportAuthenticationForm.submit();

                if (result && result.validationResult && !result.validationResult.success) {
                    dispatch(saveBoxHalt(exportNibrsDownloadModalContext));
                    return;
                }
            } catch {
                dispatch(saveBoxHalt(exportNibrsDownloadModalContext));
                return;
            }
        }

        if (complianceExportType?.name === NIBRS_COMPLIANCE_TYPE.name) {
            return (
                nibrsExportResource
                    // @ts-expect-error formModel props are all optional, does not match resource payload type
                    .checkFinalExportStatus(payload)
                    .then((result) => success(result))
            );
        }

        return (
            complianceExportResource
                // @ts-expect-error formModel props are all optional, does not match resource payload type
                .checkComplianceFinalExportState({
                    ...payload,
                    complianceExportType: complianceExportType.name,
                })
                .then((result) => success(result))
        );
    }, [dispatch, complianceExportType, convertedFormModelGetter, generateFinalExport, getForm]);

    const overwriteModalOnSave = useCallback(() => {
        const payload = convertedFormModelGetter();
        generateFinalExport(payload);
        dispatch(closeBox(exportNibrsOverwriteModalContext));
    }, [convertedFormModelGetter, dispatch, generateFinalExport]);

    const buttonElement = (
        <ComplianceDownloadButton
            className={className}
            disabled={disabled || (!selectedDateIntervalIsExportable && !isResubmission)}
            handleDownloadModalSave={confirmModalOnSave}
            handleOverwriteModalSave={overwriteModalOnSave}
            label={
                isExternalSubmission || isUseOfForce
                    ? strings.labels.exportAndSubmit
                    : strings.labels.exportButton
            }
            openDownloadModal={openDownloadModal}
            selectedDateIntervalIsExportable={selectedDateIntervalIsExportable || isResubmission}
            complianceExportType={complianceExportType?.name}
        />
    );

    if (complianceExportType?.name === NIBRS_COMPLIANCE_TYPE.name) {
        return (
            <OnlyWithAbility has={abilitiesEnum.REPORTING.NIBRS_WORKSPACE}>
                {buttonElement}
            </OnlyWithAbility>
        );
    }

    return buttonElement;
};
export default ComplianceExportDownloadButton;

import React, { useCallback, useState } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { useDispatch } from 'react-redux';
import { ElasticReport, RefContextEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { ReportIdentifier } from '../../../reports/core/utils/buildReportIdentifierView';
import { getErrorMessagesFromErrors } from '../../../reports/core/helpers/validationHelpers';
import { ReasonForRelationContent } from '../../case-summary/components/ReasonForRelationModal';
import { convertFromFormModelToRecordIdentifiers } from '../state/form/reasonForRelationForm';
import { openReasonForRelationSidePanel } from '../state/ui/openReasonForRelationSidePanel';

const SidePanelWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const strings = componentStrings.reports.core.ReasonForRelationSidePanel;

const overlayId = overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL;

const formContext = RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name;

type IndividualReportSelectionSidePanelProps = {
    closePanel: () => void;
    isAtBottomOfStack: () => boolean;
};

export type IndividualReportSelectionSidePanelCustomProperties = {
    reports: ElasticReport[];
    onSave: (reportIdentifiers: ReportIdentifier[]) => Promise<void>;
};

const SidePanelContent = ({
    closePanel,
    isAtBottomOfStack,
}: IndividualReportSelectionSidePanelProps) => {
    const dispatch = useDispatch();
    const overlayStore = useOverlayStore<IndividualReportSelectionSidePanelCustomProperties>();
    const {
        customProperties: { reports, onSave },
    } = overlayStore.getStateForId(overlayId);

    const [errors, setErrors] = useState<string[]>([]);

    const { getForm } = useFormGetter();

    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    const onSaveSidePanel = useCallback(async () => {
        const form = getForm(formContext);
        if (form) {
            try {
                const result = await form.submit();
                const formModel = result.form.getState().model;
                const recordIdentifiers = convertFromFormModelToRecordIdentifiers(formModel);

                if (recordIdentifiers.length === 0) {
                    throw new Error('Missing Report Ids');
                }

                dispatch(
                    openReasonForRelationSidePanel({
                        reportIds: recordIdentifiers.map((report) => report.id),
                        onSave: async () => {
                            closePanel();

                            if (onSave) {
                                await onSave(recordIdentifiers);
                            }
                        },
                    })
                );
            } catch (err) {
                setErrors(getErrorMessagesFromErrors(err));
            }
        }
    }, [closePanel, dispatch, getForm, onSave]);

    return (
        <PortalSidePanel
            id={overlayId}
            title={strings.title(caseDisplayName)}
            closePanel={closePanel}
            savePanel={onSaveSidePanel}
            errorMessages={errors}
            isAtBottomOfStack={isAtBottomOfStack}
        >
            <SidePanelWrapper>
                <ReasonForRelationContent caseId={-1} reports={reports} formLocation={overlayId} />
            </SidePanelWrapper>
        </PortalSidePanel>
    );
};

const IndividualReportSelectionSidePanel = (props: WithRouterProps) => (
    <OverlayBaseHelper<IndividualReportSelectionSidePanelCustomProperties> id={overlayId}>
        {(renderProps) => (
            <SidePanelContent
                closePanel={renderProps.closePanel}
                isAtBottomOfStack={renderProps.overlayBase.isAtBottomOfStack}
                {...props}
            />
        )}
    </OverlayBaseHelper>
);

export default withRouter(IndividualReportSelectionSidePanel);

import React from 'react';
import classNames from 'classnames';

import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import testIds from '../../../core/testIds';

/**
 * Footer fixed to the bottom of the screen, a grey bar containing a submit
 *   button and an optional cancel button. It overflows to the right beyond the
 *   container width.
 * @param  [props.cancelText='Cancel'] Set to `null` to not show a
 *   secondary button.
 */
const FixedFooter: React.FC<{
    onSubmit: () => void;
    onCancel: () => void;
    submitText?: string;
    cancelText?: string;
    submitDisabled?: boolean;
    disabled?: boolean;
    className?: string;
}> = ({
    onSubmit,
    onCancel,
    submitText = 'Ok',
    cancelText = 'Cancel',
    submitDisabled = false,
    disabled = false,
    className,
}) => {
    return (
        <div className={classNames('fixed-footer', className)}>
            <Button
                className={buttonTypes.PRIMARY}
                onClick={onSubmit}
                disabled={disabled || submitDisabled}
                testId={testIds.FIXED_FOOTER_SAVE}
            >
                {submitText}
            </Button>
            {cancelText && (
                <Button className={buttonTypes.SECONDARY} onClick={onCancel} disabled={disabled}>
                    {cancelText}
                </Button>
            )}
        </div>
    );
};

export default FixedFooter;

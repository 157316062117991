import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import _, { first, map, mapValues, keys, compact } from 'lodash';
import { locationsSelector } from '../data';
import sortLocationEntityLinks from '../../../location-entity-links/utils/sortLocationEntityLinks';
import { buildViewModel, getViewModelProperties } from '../../../../../helpers/viewModelHelpers';
import {
    subdivisionFieldKeyNames,
    formatSubdivsionAttributesForLocationEntityLink,
} from '../../../../../helpers/subdivisionHelpers';
import { locationNameFormatsForLocation } from '../../utils/locationHelpers';
import { locationEntityLinksWhereSelector } from '../../../location-entity-links/state/data';
import {
    locationEntityLinksViewModelsWhereSelector,
    locationEntityLinksByLocationIdForReportIdSelector,
} from '../../../location-entity-links/state/ui';
import { unknownLocationIdSelector } from '../../../../constants/state/constants';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { applicationSettingsSelector } from '../../../settings/state/data';

const locationViewModelsSelector = createSelector(
    locationsSelector,
    unknownLocationIdSelector,
    applicationSettingsSelector,
    (locations, unknownLocationId, applicationSettings) =>
        mapValues(
            locations,
            buildViewModel({
                recursive: true,
                mappers: [
                    (location) => ({
                        names: locationNameFormatsForLocation({
                            location,
                            unknownLocationId,
                            isSubPremiseSupportEnabled:
                                applicationSettings.SUBPREMISE_SUPPORT_ENABLED,
                        }),
                    }),
                ],
            })
        )
);

/**
 * Use sortedLocationBundlesForLocationEntityLinksWhereSelector instead
 */
const locationViewModelsByIdSelector = createSelector(
    locationViewModelsSelector,
    (locationViewModels) => (locationId) => locationViewModels[locationId]
);

function createLocationBundlesFromLocationEntityLinks(
    locationEntityLinks,
    { locationViewModels, unknownLocationId }
) {
    return map(locationEntityLinks, (locationEntityLink) => {
        return {
            location: locationViewModels[locationEntityLink.locationId],
            locationEntityLink,
            // These aren't needed now but used to mirror the LocationBundle model
            aliases: [],
            cautions: [],
            statePlaneCoordinates: [],
            // this is needed for the formatter, so it knows when to display: no fixed address
            unknownLocationId,
        };
    });
}

/**
 * This is the preferred selector to use as it gives both the location entity link
 *  and the location
 */
export const sortedLocationBundlesForLocationEntityLinksWhereSelector = createSelector(
    locationEntityLinksWhereSelector,
    locationViewModelsSelector,
    unknownLocationIdSelector,
    (locationEntityLinksWhere, locationViewModels, unknownLocationId) => (predicate) => {
        const sortedLocationEntityLinks = sortLocationEntityLinks({
            locationEntityLinks: locationEntityLinksWhere(predicate),
        });
        return createLocationBundlesFromLocationEntityLinks(sortedLocationEntityLinks, {
            locationViewModels,
            unknownLocationId,
        });
    }
);

/**
 *  Use this selector for creating `locationBundles` from non-nexus stored
 *  `locationEntityLinks`
 */
export const locationBundlesForUnsavedLocationEntityLinksSelector = createSelector(
    locationViewModelsSelector,
    unknownLocationIdSelector,
    (locationViewModels, unknownLocationId) => (locationEntityLinks) =>
        createLocationBundlesFromLocationEntityLinks(locationEntityLinks, {
            locationViewModels,
            unknownLocationId,
        })
);

export const locationViewModelsLinkedToEntitySelector = createSelector(
    locationEntityLinksWhereSelector,
    locationViewModelsSelector,
    (locationEntityLinksWhere, locationViewModels) => (entityType, entityId) => {
        const locationEntityLinks = locationEntityLinksWhere({
            entityType,
            entityId,
        });

        const locationIds = _(locationEntityLinks)
            .sortBy('createdDateUtc') // sort + reverse to get most recent on top
            .reverse()
            .map('locationId')
            .value();

        return map(locationIds, (id) => locationViewModels[id]);
    }
);

/**
 * Recovered Location view model for an item profile id
 * @param {number} itemProfileId
 * return {Object}
 */
export const recoveredLocationViewModelPropertiesByItemProfileIdSelector = createSelector(
    locationEntityLinksViewModelsWhereSelector,
    locationViewModelsByIdSelector,
    (locationEntityLinksViewModelsWhere, locationViewModelsById) => (itemProfileId) => {
        // only one recovered location for any item
        const locationEntityLinkViewModel = first(
            locationEntityLinksViewModelsWhere({
                entityType: EntityTypeEnum.ITEM_PROFILE.name,
                entityId: itemProfileId,
                linkType: LinkTypesEnum.PROPERTY_RECOVERED_LOCATION,
            })
        );

        if (locationEntityLinkViewModel) {
            const locationViewModel = locationViewModelsById(
                locationEntityLinkViewModel.locationId
            );
            const locationEntityLinkViewModelProperties = getViewModelProperties(
                locationEntityLinkViewModel
            );
            const subdivisionSummary = _(locationEntityLinkViewModelProperties)
                .pick(subdivisionFieldKeyNames)
                .values()
                .join(' / ');

            return {
                location: {
                    isVerified: locationViewModel.areCoordinatesVerified,
                    ...getViewModelProperties(locationViewModel),
                },
                locationEntityLink: {
                    description: locationEntityLinkViewModel.description,
                    subdivisionSummary,
                    ...locationEntityLinkViewModelProperties,
                },
            };
        }
    }
);

export const formatSubdivsionAttributesForLocationEntityLinkSelector = createSelector(
    formatAttributeByIdSelector,
    (formatAttributeById) => (locationEntityLink) => {
        return formatSubdivsionAttributesForLocationEntityLink(
            locationEntityLink,
            formatAttributeById
        );
    }
);

export const locationViewModelsWithLinksForReportIdSelector = createSelector(
    locationEntityLinksByLocationIdForReportIdSelector,
    locationViewModelsSelector,
    (locationEntityLinksByLocationIdForReportId, locationViewModels) => (reportId) => {
        const locationEntityLinksByLocationId = locationEntityLinksByLocationIdForReportId(
            reportId
        );
        return compact(
            map(keys(locationEntityLinksByLocationId), (locationId) => {
                const locationViewModel = locationViewModels[locationId];
                return (
                    !!locationViewModel && {
                        links: locationEntityLinksByLocationId[locationId],
                        entity: locationViewModel,
                    }
                );
            })
        );
    }
);

import { FieldTypeEnum } from '@mark43/rms-api';
import {
    ORGANIZATION_PROFILE_INDUSTRY_ATTR_ID,
    ORGANIZATION_PROFILE_INDUSTRY_OTHER,
    ORGANIZATION_PROFILE_NAME,
    ORGANIZATION_PROFILE_ORGANIZATION_TYPE_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

const { STRING, ATTRIBUTE } = FieldTypeEnum;

/**
 * This array describes all mergeable properties of the organization profile model.
 * Additionally the order of these keys defines the order in which
 * fields are going to be rendered on the merge entities dashboard.
 */
const organizationProfileMergeableFields = [
    {
        prop: 'name',
        field: ORGANIZATION_PROFILE_NAME,
        type: STRING,
    },
    {
        prop: 'organizationTypeAttrId',
        field: ORGANIZATION_PROFILE_ORGANIZATION_TYPE_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'industryAttrId',
        field: ORGANIZATION_PROFILE_INDUSTRY_ATTR_ID,
        type: ATTRIBUTE,
    },
    {
        prop: 'industryOther',
        field: ORGANIZATION_PROFILE_INDUSTRY_OTHER,
        type: STRING,
    },
];

export default organizationProfileMergeableFields;

import React from 'react';
import noop from 'lodash/noop';
import { EntityTypeEnum } from '@mark43/rms-api';
import { Box, BoxProps, useToast } from 'arc';
import { Editor as TinyEditor } from '../../core/editor/components/TinyEditor';
import { useAbilitySelector } from '../../core/current-user/hooks/useAbilitySelector';
import { abilitiesEnum } from '../../core/abilities';

import { getEditorIdByBriefingId } from '../utils';
import { BriefingMode, BriefingModes } from '../types';
import testIds from '../../../core/testIds';

interface BriefingEditorProps extends Pick<BoxProps, 'height' | 'sx'> {
    id: number;
    body: string | undefined;
    mode: BriefingMode;
}

const BriefingEditor: React.FC<BriefingEditorProps> = ({ id, body, mode, ...props }) => {
    const toast = useToast();
    const editorId = getEditorIdByBriefingId(id);
    const getViewMode = () => mode === BriefingModes.VIEW;

    const handleEditorError = (message: string) => {
        toast({
            status: 'error',
            description: message,
        });
    };

    const hasAddImageNarrativeAbility = useAbilitySelector(
        abilitiesEnum.REPORTING.ADD_IMAGE_NARRATIVE
    );

    return (
        <Box height="100%" width="100%" {...props}>
            <TinyEditor
                id={editorId}
                value={body}
                entityId={id}
                entityType={EntityTypeEnum.BRIEFING.name}
                mentionsEnabled={true}
                imagesEnabled={hasAddImageNarrativeAbility}
                hideNarrativeGuide={false}
                getSummaryMode={getViewMode}
                setCurrentAutosaveValue={noop}
                setError={handleEditorError}
                onFullScreenToggle={noop}
                testId={testIds.BRIEFING_EDITOR}
            />
        </Box>
    );
};

export default BriefingEditor;

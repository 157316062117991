import React from 'react';
import styled, { withTheme } from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import { MARK_43_FILE_DESCRIPTION } from '~/client-common/core/enums/universal/fields';

import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';
import Row from '../../../core/components/Row';
import Link from '../../../core/components/links/Link';
import { MFTTextArea } from '../../../core/forms/components/TextArea';
import { fileIconTypeForFileCategory, fileTypeShouldBeDownloadOnly } from '../../files/helpers';
import testIds from '../../../../core/testIds';

const strings = componentStrings.reports.core.AttachmentsSidePanel;

const FileLink = styled(Link)`
    color: ${(props) => props.theme.colors.cobaltBlue};
    display: inline-block;
    word-wrap: break-word;
    width: 89%;
`;

const FileBlock = styled.div`
    position: relative;
    box-sizing: border-box;
    padding: 12px 12px 0 12px;
    font-size: var(--arc-fontSizes-sm);
    background-color: ${(props) => props.theme.colors.lightBlue};
    width: 100%;
    margin-bottom: 12px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.brightBlue};
    word-break: break-all;
    display: flex;
    color: ${(props) => props.theme.colors.cobaltBlue};
`;

const LoadingFile = styled(FileBlock)`
    padding: 12px;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
`;

const UploadedFile = styled(FileBlock)`
    background-color: ${(props) =>
        props.uploadFailed ? 'var(--arc-colors-negative-accent)' : props.theme.colors.lightBlue};
    border: 1px solid
        ${(props) =>
            props.uploadFailed
                ? 'var(--arc-colors-negative-default)'
                : 'var(--arc-colors-brand-default)'};
    color: ${(props) =>
        props.uploadFailed ? props.theme.colors.red : props.theme.colors.cobaltBlue};
`;

const AttachmentInfo = styled.div`
    font-style: italic;
    color: ${(props) => props.theme.colors.mediumGrey};
    padding-top: 6px;
    padding-bottom: 13px;
    width: 100%;
`;

const UploadDetails = styled.div`
    font-style: italic;
    color: ${(props) =>
        props.uploadFailed ? props.theme.colors.red : props.theme.colors.mediumGrey};
    width: 89%;
`;

const FileIconWrapper = styled.div`
    display: inline-block;
    margin-right: 7px;
`;

const LoadingBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(../images/loading/loadingBar-Blue-Faster-v2.gif);
    height: 12px;
`;

const FileSummaryWrapper = styled.div`
    width: 89%;
    display: inline-block;
`;

const DeleteAttachmentLink = styled(Link)`
    position: absolute;
    top: 0;
    right: 7px;
    color: ${(props) => props.theme.colors.cobaltBlue};
    user-select: none;
    cursor: pointer;
    font-size: var(--arc-fontSizes-sm);
    font-family: proxima-nova, sans-serif;
    padding: 12px 5px;
`;

const FileRow = ({
    url,
    fileName,
    createdDateUtc,
    createdBy,
    deleteFile,
    isLoading,
    fileCategory,
    uploadFailed,
    errorMessage,
}) => {
    const iconType = fileIconTypeForFileCategory(fileCategory);
    const downloadOnly = fileTypeShouldBeDownloadOnly(fileName);
    const openInNewTab = !downloadOnly;
    return (
        <Row>
            {isLoading ? (
                <LoadingFile>
                    <FileIconWrapper>
                        <Icon className="file-icon" type={iconType} color="cobaltBlue" />
                    </FileIconWrapper>
                    <FileLink
                        href={url}
                        openInNewTab={openInNewTab}
                        download={downloadOnly}
                        disabled={true}
                    >
                        {fileName}
                    </FileLink>
                    <LoadingBar className="loading-bar-small" />
                    <DeleteAttachmentLink onClick={deleteFile}>
                        <Icon
                            type={iconTypes.TRASH_CAN}
                            size={iconSizes.SMALL}
                            color="cobaltBlue"
                        />
                    </DeleteAttachmentLink>
                </LoadingFile>
            ) : (
                <UploadedFile uploadFailed={uploadFailed}>
                    <FileIconWrapper>
                        <Icon className="file-icon" type={iconType} color="cobaltBlue" />
                    </FileIconWrapper>
                    <FileSummaryWrapper>
                        <FileLink
                            href={url}
                            openInNewTab={openInNewTab}
                            download={downloadOnly}
                            disabled={uploadFailed}
                        >
                            {fileName}
                        </FileLink>
                        <AttachmentInfo>
                            {createdDateUtc ? (
                                <div>
                                    <FormattedDate
                                        date={createdDateUtc}
                                        format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                                    />
                                    {` by ${createdBy}`}
                                </div>
                            ) : (
                                <UploadDetails uploadFailed={uploadFailed}>
                                    {uploadFailed ? errorMessage : strings.uploadDetails}
                                </UploadDetails>
                            )}
                        </AttachmentInfo>
                        {!uploadFailed && (
                            <MFTTextArea
                                fieldName={MARK_43_FILE_DESCRIPTION}
                                path="description"
                                label={strings.description}
                                autoResize={true}
                                maxLength={2048}
                                rows={2}
                                width={364}
                            />
                        )}
                    </FileSummaryWrapper>
                    <DeleteAttachmentLink onClick={deleteFile} testId={testIds.TRASH_BUTTON}>
                        <Icon
                            type={iconTypes.TRASH_CAN}
                            size={iconSizes.MEDIUM}
                            color="cobaltBlue"
                        />
                    </DeleteAttachmentLink>
                </UploadedFile>
            )}
        </Row>
    );
};

export default withTheme(FileRow);

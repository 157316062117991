import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import NoDataBlock from '../../../core/components/NoDataBlock';

const strings = componentStrings.entityProfiles.EntityProfileSectionNoData;

const Centered = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const EntityProfileSectionNoData = () => (
    <Centered>
        <NoDataBlock>{strings.noData}</NoDataBlock>
    </Centered>
);

export default EntityProfileSectionNoData;

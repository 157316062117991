import { RetentionPolicyView, OffenseCode } from '@mark43/evidence-api';
import { reduce, isArray } from 'lodash';
import getRetentionPoliciesResource from '../../resources/retentionPoliciesResource';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '../../../offense-codes/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'retentionPolicies';

const retentionPoliciesModule = createNormalizedModule<RetentionPolicyView>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTION TYPES
const SAVE_RETENTION_POLICY_START = 'retention-policies/SAVE_RETENTION_POLICY_START';
const SAVE_RETENTION_POLICY_SUCCESS = 'retention-policies/SAVE_RETENTION_POLICY_SUCCESS';
const SAVE_RETENTION_POLICY_FAILURE = 'retention-policies/SAVE_RETENTION_POLICY_FAILURE';
export const LOAD_RETENTION_POLICIES_START = 'retention-policies/LOAD_RETENTION_POLICIES_START';
export const LOAD_RETENTION_POLICIES_SUCCESS = 'retention-policies/LOAD_RETENTION_POLICIES_SUCCESS';
export const LOAD_RETENTION_POLICIES_FAILURE = 'retention-policies/LOAD_RETENTION_POLICIES_FAILURE';

// ACTIONS
const storeRetentionPolicies = retentionPoliciesModule.actionCreators.storeEntities;

function loadRetentionPoliciesStart() {
    return {
        type: LOAD_RETENTION_POLICIES_START,
    };
}
function loadRetentionPoliciesSuccess() {
    return {
        type: LOAD_RETENTION_POLICIES_SUCCESS,
    };
}
function loadRetentionPoliciesFailure(errorMessage: string) {
    return {
        type: LOAD_RETENTION_POLICIES_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Load all retention policies in the department.
 */
export function loadRetentionPolicies(): ClientCommonAction<Promise<RetentionPolicyView[]>> {
    const retentionPoliciesResource = getRetentionPoliciesResource();

    return function (dispatch, getState, { nexus }) {
        dispatch(loadRetentionPoliciesStart());
        return retentionPoliciesResource
            .getRetentionPolicies()
            .then((retentionPolicies: RetentionPolicyView[]) => {
                const { retentionPoliciesWithoutOffenseCodes, offenseCodes } = reduce(
                    retentionPolicies,
                    (acc, { offenseCodes, ...retentionPolicy }) => {
                        if (isArray(offenseCodes)) {
                            acc.offenseCodes.push(...offenseCodes);
                        }
                        acc.retentionPoliciesWithoutOffenseCodes.push(retentionPolicy);
                        return acc;
                    },
                    {
                        retentionPoliciesWithoutOffenseCodes: [] as Omit<
                            RetentionPolicyView,
                            'offenseCodes'
                        >[],
                        offenseCodes: [] as OffenseCode[],
                    }
                );
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: retentionPoliciesWithoutOffenseCodes,
                            [OFFENSE_CODES_NEXUS_STATE_PROP]: offenseCodes,
                        },
                        loadRetentionPoliciesSuccess()
                    )
                );
                return retentionPolicies;
            })
            .catch((err: Error) => {
                dispatch(loadRetentionPoliciesFailure(err.message));
                throw err;
            });
    };
}

function saveRetentionPolicyStart() {
    return {
        type: SAVE_RETENTION_POLICY_START,
    };
}
function saveRetentionPolicySuccess() {
    return {
        type: SAVE_RETENTION_POLICY_SUCCESS,
    };
}
function saveRetentionPolicyFailure(errorMessage: string) {
    return {
        type: SAVE_RETENTION_POLICY_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Create or update a retention policy.
 * @param  isNew Not used; this is here in case the upsert endpoint
 *   changes to 2 separate create and update endpoints.
 */
export function saveRetentionPolicy(
    isNew: boolean,
    retentionPolicy: RetentionPolicyView
): ClientCommonAction<Promise<RetentionPolicyView>> {
    const retentionPoliciesResource = getRetentionPoliciesResource();

    return function (dispatch) {
        dispatch(saveRetentionPolicyStart());

        return retentionPoliciesResource
            .upsertRetentionPolicy(retentionPolicy)
            .then((retentionPolicy: RetentionPolicyView) => {
                dispatch(storeRetentionPolicies(retentionPolicy));
                dispatch(saveRetentionPolicySuccess());
                return retentionPolicy;
            })
            .catch((err: Error) => {
                dispatch(saveRetentionPolicyFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const retentionPoliciesSelector = retentionPoliciesModule.selectors.entitiesSelector;

// REDUCER
/**
 * RetentionPolicyView models, not RetentionPolicy.
 */
export default retentionPoliciesModule.reducerConfig;

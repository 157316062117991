import React from 'react';
import styled from 'styled-components';
import { first } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { evidenceDashboardSearch } from '../../../../../modules/evidence/dashboard/state/ui';
import Link from '../../../../../modules/core/components/links/Link';
import dateCellFactory from '../elasticReportTable/dateCellFactory';
import testIds from '../../../../../core/testIds';

const AcquiredDateUtc = dateCellFactory('acquiredDateUtc', true);
const UpdatedDateUtcCell = dateCellFactory('eventDateUtc', true);

const CellWrapper = styled.div`
    margin-left: 16px;
`;
const RenLink = styled(Link)`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const _InfoCell = ({ evidenceItem, currentQuery }) => {
    const viewModel = getViewModelProperties(evidenceItem);
    const { sortKey } = currentQuery;
    const { currentChainEvent } = evidenceItem;
    const firstChainEvent = first(currentChainEvent);

    const dateUtcCell =
        sortKey === sortKeyEnum.EVIDENCE_ITEM_CHAIN_EVENT_DATE ? (
            <UpdatedDateUtcCell {...firstChainEvent} />
        ) : (
            <AcquiredDateUtc {...evidenceItem} />
        );

    return (
        <CellWrapper>
            <div>{dateUtcCell}</div>
            <div>
                <RenLink
                    onClick={(event) => event.stopPropagation()}
                    to={`/reports/${evidenceItem.custodialReportId}`}
                >
                    <span
                        className="ren-link-text"
                        data-test-id={testIds.EVIDENCE_DASHBOARD_REN_LINK}
                    >
                        {viewModel.reportingEventNumber}-{viewModel.sequenceNumber}
                    </span>
                </RenLink>
            </div>
        </CellWrapper>
    );
};

const InfoCellBase = connect(
    createStructuredSelector({
        currentQuery: evidenceDashboardSearch.selectors.currentQuerySelector,
    })
)(_InfoCell);

const InfoCell = (evidenceItem) => {
    return <InfoCellBase evidenceItem={evidenceItem} />;
};

export default InfoCell;

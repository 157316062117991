import _, { filter, reject, find, size, map, compact } from 'lodash';
import { createSelector } from 'reselect';

import { AttributeTypeEnum } from '@mark43/rms-api';
import {
    buildOffenseCodeViewModels,
    mapOffenseCodesToOptions,
    offenseCodesWithFlags,
} from '~/client-common/helpers/offenseCodesHelpers';
import {
    StatuteCodeSetFilterEnum,
    offenseCodeIsInStatuteCodeSetFilter,
} from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import {
    currentUserDepartmentIdSelector,
    currentUserDepartmentProfileSelector,
} from '../../modules/core/current-user/state/ui';
import { attributesByTypeForCurrentDepartmentSelector } from '../../modules/core/attributes/state/ui';

const { ACTIVE, EXPIRED } = attributeStatuses;

/**
 * Must be identical to {@link printing/src/csv/selectors/offenseCodesSelectors.js}.
 * Get the offense codes that have at least one of the flags = true
 * Example flags are isIncidentType, isOffenseType, isCrimeCharge, etc
 *
 * This selector is automatically scoped by the current user's department id,
 * resulting in all derived selectors being implicitly scoped by department id as well
 * @param  {String[]} flags     array of flags
 * @return {Object[]}           returns filtered offense codes
 */
export const offenseCodesForFlagsSelector = createSelector(
    offenseCodesSelector,
    currentUserDepartmentIdSelector,
    (offenseCodes, currentUserDepartmentId) => (flags) =>
        filter(
            flags.length > 0 ? offenseCodesWithFlags(offenseCodes, flags) : offenseCodes,
            ({ departmentId, isEnabled }) =>
                // at the moment the offense codes we load on application boot
                // have no department id on them. The BE will be sending
                // department ids eventually but for now we want to make sure
                // that offense codes without department id are counted as internal
                // because codes from external departments will always have
                // the department id set
                (departmentId === currentUserDepartmentId || !departmentId) && isEnabled
        )
);

/**
 * All offense code view models as an array. Each view model contains computed
 *   properties needed for the ui.
 * @type {Object}
 */
const offenseCodeViewModelsSelector = createSelector(
    offenseCodesForFlagsSelector,
    (offenseCodesForFlags) => (flags) => buildOffenseCodeViewModels(offenseCodesForFlags(flags))
);

/**
 * Active offense codes, normalized. Active means not expired or scheduled based
 *   on start/end dates.
 * @type {Object}
 */
export const activeOffenseCodesSelector = createSelector(
    offenseCodeViewModelsSelector,
    (offenseCodeViewModels) => (flags) =>
        _(offenseCodeViewModels(flags)).filter({ status: ACTIVE }).mapKeys('id').value()
);

/**
 * Expired offense codes, normalized. Expired means that the code's expiration
 * date is defined and later than now.
 * @type {Object}
 */
const expiredOffenseCodesSelector = createSelector(
    offenseCodeViewModelsSelector,
    (offenseCodeViewModels) => (flags) =>
        _(offenseCodeViewModels(flags)).filter({ status: EXPIRED }).mapKeys('id').value()
);

/**
 * Obtain offense code option objects using given params.
 * @param {string[]} flags           Offense Code flags, depicting interested
 *                                   offense code types.
 * @param {boolean}  includeExpired  Whether or not to include expired offense
 *                                   codes.
 * @return {Object[]}  Array of offense code view model objects.
 */
export const offenseCodeOptionsSelector = createSelector(
    activeOffenseCodesSelector,
    expiredOffenseCodesSelector,
    offenseCodeViewModelsSelector,
    attributesByTypeForCurrentDepartmentSelector,
    currentUserDepartmentProfileSelector,
    (
        activeOffenseCodes,
        expiredOffenseCodes,
        offenseCodeViewModels,
        attributesByType,
        currentUserDepartmentProfile
    ) => ({
        flags = [],
        includeExpired = false,
        selectedOffenseCodeIds = [],
        statuteCodeSetFilter,
    }) => {
        let offenseCodes = {
            ...activeOffenseCodes(flags),
            ...(includeExpired ? expiredOffenseCodes(flags) : {}),
        };

        if (statuteCodeSetFilter && statuteCodeSetFilter !== StatuteCodeSetFilterEnum.INCIDENT) {
            offenseCodes = filter(offenseCodes, (offenseCode) =>
                offenseCodeIsInStatuteCodeSetFilter(
                    offenseCode,
                    statuteCodeSetFilter,
                    attributesByType(AttributeTypeEnum.OFFENSE_CODE_STATUTE_CODE_SET.name)
                )
            );
        }

        const offenseCodeOptions = mapOffenseCodesToOptions(
            offenseCodes,
            currentUserDepartmentProfile
        );
        const selectedOffenseCodesNotInOffenseCodeOptions = reject(
            selectedOffenseCodeIds,
            (selectedOffenseCodeId) => find(offenseCodeOptions, { value: selectedOffenseCodeId })
        );

        // If there are selected codes that are not yet in
        // the list of options, then we need to add them
        if (size(selectedOffenseCodesNotInOffenseCodeOptions)) {
            // Add these to the top of the array
            offenseCodeOptions.unshift(
                ...mapOffenseCodesToOptions(
                    compact(
                        map(selectedOffenseCodeIds, (selectedOffenseCodeId) =>
                            find(offenseCodeViewModels(flags), { id: selectedOffenseCodeId })
                        )
                    ),
                    currentUserDepartmentProfile
                )
            );
        }

        return offenseCodeOptions;
    }
);

import _, { isArray, includes } from 'lodash';
import { NameNameLink, LinkType, LinkTypesEnum } from '@mark43/rms-api';
import { isAfter } from '../core/dates/utils/dateHelpers';
import { formatLinkType } from './linkTypesHelpers';

// common pattern for matching a computed nameNameLink option ids
const nameNameLinkOptionIdPattern = /^(\d+)(-linkName)(.+)$/;

/**
 * Whether the given link option corresponds to the `linkNameOne` of a
 *   nameNameLink (from the perspective of some person/org).
 */
export function nameNameLinkOptionIsOne(linkOptionId: string): boolean {
    return !!linkOptionId.match(/One$/);
}

/**
 * Whether the given link option corresponds to the `linkNameTwo` of a
 *   nameNameLink (from the perspective of some person/org).
 */
export function nameNameLinkOptionIsTwo(linkOptionId: string): boolean {
    return !!linkOptionId.match(/Two$/);
}

/**
 * Filter the given relationships to those that involve the given persons/orgs.
 *   Any duplicates in the result get removed as well.
 */
export function filterNameNameLinks(
    nameNameLinks: NameNameLink[],
    nameId: number | number[],
    otherNameId: number
): NameNameLink[] {
    return (
        _(nameNameLinks)
            .filter(({ nameFromId, nameToId }) => {
                if (isArray(nameId)) {
                    return includes(nameId, nameFromId) && includes(nameId, nameToId);
                } else if (otherNameId) {
                    return (
                        (nameFromId === nameId && nameToId === otherNameId) ||
                        (nameToId === nameId && nameFromId === otherNameId)
                    );
                } else {
                    return nameFromId === nameId || nameToId === nameId;
                }
            })
            // remove duplicates
            .uniqBy(({ linkTypeId, nameFromId, entityTypeFrom, nameToId, entityTypeTo }) => {
                return `${linkTypeId}-${nameFromId}-${entityTypeFrom}-${nameToId}-${entityTypeTo}`;
            })
            .value()
    );
}

/**
 * Return a formatted string of the provided link type from the perspective
 *   of the entity specified by `nameId` (nameNameLink.nameFromId is used as
 *   a default otherwise)
 * @param  nameId       The id of the `name` from whose perspective you
 *   want to display a formatted link type
 */
export function formatNameNameLink(
    nameNameLink: NameNameLink,
    linkType: LinkType,
    nameId: number
): string {
    let formattedLink =
        !nameId || nameNameLink.nameFromId === nameId
            ? formatLinkType(linkType)
            : formatLinkType(linkType, true);
    if (nameNameLink.description) {
        formattedLink = `${formattedLink} - ${nameNameLink.description}`;
    }
    return formattedLink;
}

/**
 * Array.prototype.sort comparator for NameNameLinks.
 * Compares by dateEffectiveTo in descending order (the latest date will end up at the 0 index).
 * A NameNameLink without a dateEffectiveTo is considered the latest.
 */
export function compareNameNameLinksByEffectiveDates(linkOne: NameNameLink, linkTwo: NameNameLink) {
    // MomentInput type supports null but doesn't support undefined
    const { dateEffectiveFrom: linkOneFrom = null, dateEffectiveTo: linkOneTo = null } = linkOne;
    const { dateEffectiveFrom: linkTwoFrom = null, dateEffectiveTo: linkTwoTo = null } = linkTwo;

    if (linkOneTo === linkTwoTo) {
        return isAfter(linkTwoFrom, linkOneFrom) ? -1 : 1;
    }

    if (!linkOneTo) {
        return -1;
    }

    if (!linkTwoTo) {
        return 1;
    }

    return isAfter(linkTwoTo, linkOneTo) ? -1 : 1;
}

/**
 * Convert `'123-linkNameTwo'` to `123` and `'456'` to `456`.
 */
export function convertNameNameLinkOptionIdToTypeId(linkOptionId: string): number {
    const matches = linkOptionId.match(nameNameLinkOptionIdPattern);
    return _.parseInt(matches ? matches[1] : linkOptionId);
}

/**
 * Test if this link type or a linkOptionId has the optional description field attached
 */
export function linkTypeHasDescriptionField(linkTypeId: number): boolean {
    return _.includes(
        [LinkTypesEnum.OTHERWISE_KNOWN, LinkTypesEnum.TIED_TO, LinkTypesEnum.BUSINESS_BUSINESS],
        linkTypeId
    );
}

import _, { find } from 'lodash';
import {
    createField,
    createNItems,
    createFormConfiguration,
    InferFormDataShape,
} from 'markformythree';
import { ReportCaseStatus } from '@mark43/rms-api';
import {
    REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
    REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
    REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
    REPORT_CASE_STATUS_STATUS_DATE_UTC,
    REPORT_CASE_STATUS_IS_CANCELED,
    REPORT_CASE_STATUS_CANCELED_BY_ID,
} from '~/client-common/core/enums/universal/fields';
import { ModuleShape } from '~/client-common/redux/state';

import { offenseCaseStatusesConfiguration } from '../../../../core/offense-case-statuses/state/form/offenseCaseStatusesForm';
import { externalReportStatusConfiguration } from '../../../../core/external-report-statuses/state/form/externalReportStatusForm';

const reportCaseStatusesFormConfiguration = createNItems({
    fieldName: REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
    fields: {
        reportId: createField<number>({}),
        caseStatusAttrId: createField<number>({
            fieldName: REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
        }),
        isCanceled: createField<boolean>({
            fieldName: REPORT_CASE_STATUS_IS_CANCELED,
        }),
        canceledById: createField<number>({
            fieldName: REPORT_CASE_STATUS_CANCELED_BY_ID,
        }),
        closedByDivisionAttrId: createField<number>({
            fieldName: REPORT_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID,
        }),
        closedByUnitAttrId: createField<number>({
            fieldName: REPORT_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID,
        }),
        statusDateUtc: createField<number>({
            fieldName: REPORT_CASE_STATUS_STATUS_DATE_UTC,
        }),
    },
});

const reportCaseStatusesForm = createFormConfiguration({
    externalReportStatus: externalReportStatusConfiguration,
    reportCaseStatuses: reportCaseStatusesFormConfiguration,
    offenseCaseStatuses: offenseCaseStatusesConfiguration,
});

export type ReportCaseStatusesFormConfiguration = typeof reportCaseStatusesForm;
export type ReportCaseStatusesFormDataShape = InferFormDataShape<ReportCaseStatusesFormConfiguration>;

export function convertToReportCaseStatusesFormModel(
    caseStatuses: ReportCaseStatus[],
    reportIds: number[]
) {
    // build an empty model of case
    const emptyCaseStatuses = _(reportIds)
        // make sure that we do not create empty case statuses for report ids
        // which already have a status in `caseStatuses`
        .filter((reportId) => !find(caseStatuses, { reportId }))
        .mapKeys()
        .mapValues((reportId) => ({ reportId }))
        .value();

    return _.values({
        ...emptyCaseStatuses,
        ...caseStatuses,
    });
}

export function convertFromFormModel(
    dataState: ModuleShape<ReportCaseStatus>,
    caseStatuses: ReportCaseStatus[]
) {
    return _(caseStatuses)
        .reject(({ reportId, caseStatusAttrId }) => {
            // filter out empty new models
            return !_.get(dataState[reportId], 'id') && !caseStatusAttrId;
        })
        .mapKeys('reportId')
        .mapValues((caseStatus) => {
            return {
                ...dataState[caseStatus.reportId],
                ...caseStatus,
            };
        })
        .value();
}

export default reportCaseStatusesForm;

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadRotationListEntityFormFieldViewModels = {
    cadRotationListEntity: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadRotationListEntity',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'rotationServiceId',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'name',
            },
            {
                key: 'startDateUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'expirationDateUtc',
            },
            {
                key: 'rotationServiceLocationId',
            },
        ]),
    },
};

function convertToFormModel(cadRotationListEntity = {}) {
    return {
        cadRotationListEntity,
    };
}

function convertFromFormModel(cadRotationListEntity = {}) {
    return cadRotationListEntity;
}

/**
 * Module of the Entry admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CAD_SERVICE_ROTATION_LISTS_ENTRY_ADMIN,
    fieldViewModels: cadRotationListEntityFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

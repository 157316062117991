import { AttributeTypeEnum } from '@mark43/rms-api';
import { filter, get, map, omit, some, forEach } from 'lodash';

import { createSelector } from 'reselect';
// helpers
import { filterFormData } from '~/client-common/helpers/formHelpers';

import {
    parentAttributeIdByAttributeIdSelector,
    attributeIsOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
// constants
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

export const reasonsForTowIncludeStolenSelector = createSelector(
    parentAttributeIdByAttributeIdSelector,
    (parentAttributeIdByAttributeId) => (reasonsForTow) =>
        some(
            map(reasonsForTow, parentAttributeIdByAttributeId),
            (reason) => reason === globalAttributes.towVehicleReasonForTowGlobal.stolen
        )
);

export const convertFromFormModel = (towVehicleForm, currentReportId, state) => {
    const formModel = towVehicleForm.get();
    const towVehicle = omit(formModel, 'reasonsForTow');

    // handle hidden fields
    let towVehicleRemovedHiddenFields = towVehicle;

    const reasonForTowFormValueIsStolen = reasonsForTowIncludeStolenSelector(state)(
        formModel.reasonsForTow
    );
    let filteredTowVehicle;
    let towVehicleReasonForTowReportAttributes;
    towVehicleForm.transaction(() => {
        // `omit` `towCompanyCalledOther` if `towCompanyCalledAttrId` is not an other attr
        if (
            !attributeIsOtherSelector(state)(towVehicleRemovedHiddenFields.towCompanyCalledAttrId)
        ) {
            towVehicleForm.set('towCompanyCalledOther', '');
            towVehicleRemovedHiddenFields = omit(
                towVehicleRemovedHiddenFields,
                'towCompanyCalledOther'
            );
        }

        // `omit` fields that are only relevant if there is a `stolen` reason for tow
        // As of RMS-6726 -- no way to remove nested, hidden KO View Wrappers
        // without a framework level change in how we handle hidden fields.
        // Issue is that when we hide a field, we do not render the component;
        // that is a problem for KO View Wrappers, because when they are not
        // rendered, we lose their `ref`, giving us no way to nicely proxy a
        // `delete` call to the wrapper view... Thus, the KO Wrapped View entity
        // *AND* it's link data will still exist, which is not great ...
        if (!reasonForTowFormValueIsStolen) {
            const omitFieldPaths = [
                'originalRen',
                'dateOfTheft',
                'wasLocateSent',
                'wereImpoundsChecked',
                'isImpounded',
                'wasOutsideRecovery',
                'outsideRecoveryAgency',
                'outsideRecoveryAgencyRen',
            ];
            towVehicleRemovedHiddenFields = omit(towVehicleRemovedHiddenFields, omitFieldPaths);
            forEach(omitFieldPaths, (omitFieldPath) => {
                towVehicleForm.set(omitFieldPath, undefined);
            });
        } else if (get(towVehicle, 'wasOutsideRecovery') !== true) {
            // tow vehicle was stolen, but it was not an outside recovery
            const omitFieldPaths = ['outsideRecoveryAgency', 'outsideRecoveryAgencyRen'];
            towVehicleRemovedHiddenFields = omit(towVehicleRemovedHiddenFields, omitFieldPaths);
            forEach(omitFieldPaths, (omitFieldPath) => {
                towVehicleForm.set(omitFieldPath, undefined);
            });
        }

        // `omit` `messageLeftWith` if `wasOwnerContactAttempted` is not true
        if (towVehicle.wasOwnerContactAttempted !== true) {
            towVehicleForm.set('messageLeftWith', undefined);
            towVehicleRemovedHiddenFields = omit(towVehicleRemovedHiddenFields, 'messageLeftWith');
        }

        filteredTowVehicle = filterFormData(towVehicleRemovedHiddenFields);

        // Specifically do not `filterFormData` for tow vehicle reason for tow
        // attributes -- the endpoint will replace, so if all attributes are cleared,
        // we correctly want to replace with an empty array.
        towVehicleReasonForTowReportAttributes = map(
            formModel.reasonsForTow,
            (towVehicleReasonForTowAttrId) => {
                return {
                    reportId: currentReportId,
                    attributeType: AttributeTypeEnum.TOW_VEHICLE_REASON_FOR_TOW.name,
                    attributeId: towVehicleReasonForTowAttrId,
                };
            }
        );
    });
    const locationEntityLinks = filter(
        map(
            ['towedFromLocation', 'reportedStolenLocation', 'outsideRecoveryLocation'],
            towVehicleForm.get
        ),
        ({ locationId }) => !!locationId
    );

    return {
        towVehicle: filteredTowVehicle,
        towVehicleReasonForTowReportAttributes,
        locationEntityLinks,
    };
};

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const EVIDENCE_API_URL = '/evidence/api';

export default createResource({
    name: 'Entity Profiles Resource',
    methods: {
        /**
         * Get the item profile history events.
         * @param itemProfileId
         * @returns {Promise}
         */
        getItemProfileHistory: (itemProfileId) =>
            req({
                baseUrl: EVIDENCE_API_URL,
                method: 'GET',
                url: `evidence/item/${itemProfileId}/history`,
            }),
    },
});

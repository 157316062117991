import { RefContextEnum } from '@mark43/rms-api';
import _, { parseInt } from 'lodash';
import { createSelector } from 'reselect';
import {
    narrativeGuidesSelector,
    narrativeGuidesByIdSelector,
    LOAD_NARRATIVE_GUIDES_START,
    LOAD_NARRATIVE_GUIDES_SUCCESS,
    LOAD_NARRATIVE_GUIDES_FAILURE,
    SAVE_NARRATIVE_GUIDE_START,
    SAVE_NARRATIVE_GUIDE_SUCCESS,
    SAVE_NARRATIVE_GUIDE_FAILURE,
    REMOVE_NARRATIVE_GUIDE_START,
    REMOVE_NARRATIVE_GUIDE_SUCCESS,
    REMOVE_NARRATIVE_GUIDE_FAILURE,
    saveNarrativeGuide,
    saveNarrativeGuideFailure,
} from '~/client-common/core/domain/narrative-guides/state/data';
import { convertFromFormModel, convertToFormModel } from '../forms/narrativeGuidesForm';

const SELECT_NARRATIVE_GUIDE_START = 'narrative-guides/SELECT_NARRATIVE_GUIDE_START';
const SELECT_NARRATIVE_GUIDE_SUCCESS = 'narrative-guides/SELECT_NARRATIVE_GUIDE_SUCCESS';
const SELECT_NARRATIVE_GUIDE_FAILURE = 'narrative-guides/SELECT_NARRATIVE_GUIDE_FAILURE';

function selectNarrativeGuideStart() {
    return { type: SELECT_NARRATIVE_GUIDE_START };
}

function selectNarrativeGuideSuccess(id) {
    return { type: SELECT_NARRATIVE_GUIDE_SUCCESS, payload: id };
}

function selectNarrativeGuideFailure(errMsg) {
    return { type: SELECT_NARRATIVE_GUIDE_FAILURE, error: true, payload: errMsg };
}

export const uiSelector = (state) => state.ui.narrativeGuidesAdmin;

export const selectedNarrativeGuideSelector = createSelector(
    uiSelector,
    narrativeGuidesByIdSelector,
    (ui, narrativeGuidesById) => narrativeGuidesById(ui.selectedNarrativeGuideId)
);

export const narrativeGuidesListItemsSelector = createSelector(
    uiSelector,
    narrativeGuidesSelector,
    (ui, narrativeGuides) => {
        return _(narrativeGuides)
            .filter((narrativeGuide) => narrativeGuide.id > -1)
            .map((narrativeGuide) => {
                return {
                    path: `/admin/guides/${narrativeGuide.id}`,
                    title: narrativeGuide.name,
                    key: narrativeGuide.id,
                    selected: narrativeGuide.id === ui.selectedNarrativeGuideId,
                };
            })
            .sortBy('title')
            .value();
    }
);

export function selectNewNarrativeGuide(router) {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_ADMIN_NARRATIVE_GUIDE.name);
        if (form) {
            form.resetModel();
        }
        dispatch(selectNarrativeGuideSuccess(null));
        router.push('/admin/guides/new');
    };
}

export function selectNarrativeGuide(narrativeGuideId) {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(selectNarrativeGuideStart());
        const id = parseInt(narrativeGuideId);
        const selectedNarrativeGuide = narrativeGuidesByIdSelector(getState())(id);
        if (!selectedNarrativeGuide) {
            return dispatch(selectNarrativeGuideFailure('Failed to get guide.'));
        }
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_ADMIN_NARRATIVE_GUIDE.name,
            undefined,
            (form) => {
                form.set('', convertToFormModel(selectedNarrativeGuide));
                form.resetUi();
            }
        );
        dispatch(selectNarrativeGuideSuccess(selectedNarrativeGuide.id));
    };
}

export function submitNarrativeGuide(selectedId, router) {
    return function (dispatch, getState, { formsRegistry }) {
        const form = formsRegistry.get(RefContextEnum.FORM_ADMIN_NARRATIVE_GUIDE.name);
        return form
            .submit()
            .then((result) => {
                const narrativeGuideToSave = convertFromFormModel(
                    result.form.getState().model,
                    selectedId
                );
                return dispatch(saveNarrativeGuide(narrativeGuideToSave)).then((narrativeGuide) => {
                    form.set('', convertToFormModel(narrativeGuide));
                    if (selectedId === undefined) {
                        dispatch(selectNarrativeGuide(narrativeGuide.id));
                        router.push(`/admin/guides/${narrativeGuide.id}`);
                    }
                    return narrativeGuide;
                });
            })
            .catch((err) => {
                if (err.validationResult?.formErrors?.length) {
                    dispatch(saveNarrativeGuideFailure(err.validationResult.formErrors));
                } else {
                    dispatch(saveNarrativeGuideFailure(err.message));
                }
            });
    };
}

const initialUiState = {
    errorMessages: [],
    submittingForm: false,
    selectedNarrativeGuideId: undefined,
    loading: false,
};

function narrativeGuidesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_NARRATIVE_GUIDE_START:
            return {
                ...state,
                submittingForm: false,
                selectedNarrativeGuideId: null,
                errorMessages: [],
            };
        case SELECT_NARRATIVE_GUIDE_SUCCESS:
            return {
                ...state,
                submittingForm: null,
                selectedNarrativeGuideId: parseInt(action.payload),
                errorMessages: [],
            };
        case SELECT_NARRATIVE_GUIDE_FAILURE:
        case LOAD_NARRATIVE_GUIDES_START:
            return {
                ...state,
                errorMessages: [],
                loading: true,
            };
        case LOAD_NARRATIVE_GUIDES_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessages: [],
            };
        case LOAD_NARRATIVE_GUIDES_FAILURE:
            return {
                ...state,
                errorMessages: Array.isArray(action.payload) ? action.payload : [action.payload],
                loading: false,
            };
        case REMOVE_NARRATIVE_GUIDE_START:
            return {
                ...state,
                submittingForm: true,
            };
        case REMOVE_NARRATIVE_GUIDE_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case REMOVE_NARRATIVE_GUIDE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                errorMessages: Array.isArray(action.payload) ? action.payload : [action.payload],
            };
        case SAVE_NARRATIVE_GUIDE_START:
        case SAVE_NARRATIVE_GUIDE_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                errorMessages: [],
            };
        case SAVE_NARRATIVE_GUIDE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                errorMessages: Array.isArray(action.payload) ? action.payload : [action.payload],
            };
        default:
            return state;
    }
}

export default narrativeGuidesAdminUiReducer;

import { createFormConfiguration, createNItems } from 'markformythree';

import * as fields from '~/client-common/core/enums/universal/fields';

export function createOrganizationProfileFormConfiguration() {
    return createFormConfiguration({
        linkType: {
            fieldName: fields.NAME_REPORT_LINK_LINK_TYPE,
        },
        name: {
            fieldName: fields.ORGANIZATION_PROFILE_NAME,
        },
        organizationTypeAttrId: {
            fieldName: fields.ORGANIZATION_PROFILE_ORGANIZATION_TYPE_ATTR_ID,
        },
        industryAttrId: {
            fieldName: fields.ORGANIZATION_PROFILE_INDUSTRY_ATTR_ID,
        },
        industryOther: {
            fieldName: fields.ORGANIZATION_PROFILE_INDUSTRY_OTHER,
        },
        phoneNumbers: createNItems({
            fields: {
                id: {}, // used to update existing phone numbers
                phoneType: {
                    fieldName: fields.NAME_PHONE_NUMBER_PHONE_TYPE,
                },
                phoneTypeOther: {
                    fieldName: fields.DISPLAY_ONLY_NAME_PHONE_NUMBER_PHONE_TYPE_OTHER,
                },
                displayNumber: {
                    fieldName: fields.NAME_PHONE_NUMBER_PHONE_NUMBER,
                },
                isPrimary: {
                    fieldName: fields.NAME_PHONE_NUMBER_IS_PRIMARY,
                },
            },
        }),
        // Only spread the values of the entityLink here
        // there is no need to keep track
        // of any locations for the form
        mailingAddresses: createNItems({
            fields: {
                locationId: {
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_ADDRESS_LOCATION_ID,
                },
                positionAttrId: {
                    fieldName:
                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_ADDRESS_POSITION_ATTR_ID,
                },
                startDateUtc: {
                    fieldName:
                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_ADDRESS_START_DATE_UTC,
                },
            },
        }),
        // Only spread the values of the entityLink here
        // there is no need to keep track
        // of any locations for the form
        physicalAddresses: createNItems({
            fields: {
                locationId: {
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_LOCATION_LOCATION_ID,
                }, // used to update existing physical addresses
                positionAttrId: {
                    fieldName:
                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_LOCATION_POSITION_ATTR_ID,
                },
                startDateUtc: {
                    fieldName:
                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_LOCATION_START_DATE_UTC,
                },
                endDateUtc: {
                    fieldName:
                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_BUSINESS_LOCATION_END_DATE_UTC,
                },
            },
        }),
        emails: createNItems({
            fields: {
                id: {}, // used to update existing emails
                emailType: {
                    fieldName: fields.NAME_EMAIL_EMAIL_TYPE,
                },
                emailTypeOther: {
                    fieldName: fields.DISPLAY_ONLY_NAME_EMAIL_EMAIL_TYPE_OTHER,
                },
                emailAddress: {
                    fieldName: fields.NAME_EMAIL_EMAIL_ADDRESS,
                },
            },
        }),
        identifiers: createNItems({
            fields: {
                id: {}, // used to update existing identifiers
                nameIdentifierTypeAttrId: {
                    fieldName: fields.NAME_IDENTIFIER_NAME_IDENTIFIER_TYPE_ATTR_ID,
                },
                identifier: {
                    fieldName: fields.NAME_IDENTIFIER_IDENTIFIER,
                },
                nameIdentifierTypeOther: {
                    fieldName: fields.NAME_IDENTIFIER_NAME_IDENTIFIER_TYPE_OTHER,
                },
            },
        }),
    });
}

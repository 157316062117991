let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'RMS Saved Search Resource',
        methods: {
            getNonAutoSavedRmsSavedSearchesView(hideLoadingBar) {
                return req({
                    method: 'GET',
                    url: 'saved_searches/view',
                    hideLoadingBar,
                });
            },
            getNonAutoSavedRmsSavedSearchesViewForElasticSearchType(elasticSearchType) {
                return req({
                    method: 'GET',
                    url: `saved_searches/view/${elasticSearchType}`,
                });
            },
            getAutoSavedRmsSavedSearchesViewForElasticSearchType(elasticSearchType) {
                return req({
                    method: 'GET',
                    url: `saved_searches/view/${elasticSearchType}/auto_save`,
                });
            },
            renameRmsSavedSearch(savedSearch, newSavedSearchName) {
                return req({
                    method: 'PUT',
                    url: `saved_searches`,
                    data: savedSearch,
                    params: {
                        name: newSavedSearchName,
                    },
                });
            },
            subscribeRmsSavedSearch(savedSearch, enable) {
                return req({
                    method: 'PUT',
                    url: `saved_searches/subscribe`,
                    params: {
                        enable,
                    },
                    data: savedSearch,
                });
            },
            favoriteRmsSavedSearch(searchId, favorite) {
                return req({
                    method: 'PUT',
                    url: `saved_searches/favorite/${searchId}`,
                    params: {
                        favorite,
                    },
                });
            },
        },
    });
}

/**
 * Resource for RMS Saved Searches. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
/* eslint-disable-next-line import/no-anonymous-default-export */
export default () => resource;

import { ReportDefinition, Report, ReportShortTitle } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { compact, find, get, includes, isArray, map, uniq, chain } from 'lodash';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';

import reportCardEnum from '../../../../enums/universal/reportCardEnum';
import {
    formatReportDefinition,
    reportDefinitionHasCustodialPropertySummaryCard,
    reportDefinitionHasOffenseCard,
    reportDefinitionHasSupplementOffenseCard,
    reportDefinitionHasSupplementIncidentCard,
} from '../../../../../helpers/reportDefinitionsHelpers';
import { reportByIdSelector, reportsWhereSelector } from '../../../reports/state/data';

import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';
import { applicationSettingsSelector } from '../../../settings/state/data';

export const NEXUS_STATE_PROP = 'reportDefinitions';

const reportDefinitionModule = createNormalizedModule<ReportDefinition>({
    type: NEXUS_STATE_PROP,
});

export const reportDefinitionsSelector = reportDefinitionModule.selectors.entitiesSelector;
export const reportDefinitionsWhereSelector =
    reportDefinitionModule.selectors.entitiesWhereSelector;

export const reportDefinitionByIdSelector = createSelector(
    reportDefinitionsSelector,
    (reportDefinitions) => (id: number) => reportDefinitions[id]
);

export const formatReportDefinitionByIdSelector = createSelector(
    reportDefinitionsSelector,
    (reportDefinitions) => (id: number) => {
        const ids = isArray(id) ? id : compact([id]);
        return map(ids, (id) => formatReportDefinition(reportDefinitions[id])).join(', ');
    }
);

export const reportDefinitionHasCardSelector = createSelector(
    reportDefinitionByIdSelector,
    (reportDefinitionById) => (id: number, cardId: number) => {
        const reportDefinition = reportDefinitionById(id) || { cards: [] };
        return includes(map(reportDefinition.cards, 'id'), cardId);
    }
);

/**
 * Selector will specifically only find the Custodial Property Summary report
 *   definition. Selector expects that there is only 1 report definition with a
 *   custodial property summary card.
 */
export const custodialPropertySummaryReportDefinitionSelector = createSelector(
    reportDefinitionsSelector,
    (reportDefinitions) => find(reportDefinitions, reportDefinitionHasCustodialPropertySummaryCard)
);

export const reportDefinitionHasReportCaseStatusSelector = createSelector(
    reportDefinitionsSelector,
    (reportDefinitions) => (id: number) => reportDefinitions[id]?.hasReportCaseStatus
);

export const reportDefinitionHasOffenseCaseStatusSelector = createSelector(
    reportDefinitionsSelector,
    (reportDefinitions) => (id: number) => reportDefinitions[id]?.hasOffenseCaseStatus
);

export const reportDefinitionRestrictViewReportOwnersSelector = createSelector(
    reportDefinitionsSelector,
    (reportDefinitions) => (id?: number) =>
        id ? reportDefinitions[id]?.restrictViewReportOwners : undefined
);

export const reportDefinitionByReportIdSelector = createSelector(
    reportByIdSelector,
    reportDefinitionsSelector,
    (reportById, reportDefinitions) => (reportId: number) =>
        reportDefinitions[get(reportById(reportId) as Report, 'reportDefinitionId')]
);

export const cardTitleByCardId = (
    cardId: number,
    formatFieldByName: ReturnType<typeof formatFieldByNameSelector>
): string | undefined => {
    const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);
    const cardTitleMap: { [id: number]: string } = {
        [reportCardEnum.EVENT_INFO.id]: 'Event Information',
        [reportCardEnum.ARREST.id]: 'Arrest',
        [reportCardEnum.RELATIONSHIPS.id]: 'Relationships',
        [reportCardEnum.NARRATIVE.id]: 'Narrative',
        [reportCardEnum.ATTACHMENTS.id]: 'Attachments',
        [reportCardEnum.APPROVALS.id]: 'Approvals',
        [reportCardEnum.OFFENSE.id]: offenseDisplayName,
        [reportCardEnum.INCIDENT.id]: 'Incident',
        [reportCardEnum.FIELD_CONTACT.id]: 'Field Contact',
        [reportCardEnum.MISSING_PERSONS.id]: 'Missing Persons',
        [reportCardEnum.IMPOUND.id]: 'NCIC Information',
        [reportCardEnum.USE_OF_FORCE.id]: 'Use of Force',
        [reportCardEnum.USE_OF_FORCE_SUBJECT.id]: 'Subject',
        [reportCardEnum.COMMUNITY_INFORMATION.id]: 'Community Information',
        [reportCardEnum.CUSTODIAL.id]: 'Property',
        [reportCardEnum.TOW_VEHICLE.id]: 'Tow Information',
        [reportCardEnum.CHECK_IN_INFORMATION.id]: 'Check In Information',
        [reportCardEnum.RELEASE_INFORMATION.id]: 'Release Information',
        [reportCardEnum.TOW_VEHICLE_IMPOUND.id]: 'Impound',
        [reportCardEnum.TRAFFIC_CRASH.id]: 'Traffic Crash',
        [reportCardEnum.CITATION.id]: 'Citation',
        [reportCardEnum.COURT_CASE.id]: 'Court Case',
        [reportCardEnum.CHARGES.id]: 'Charges',
        [reportCardEnum.SUPPLEMENT_INFO.id]: 'Supplement Information',
        [reportCardEnum.BOOKING.id]: 'Booking',
        [reportCardEnum.INVOLVED_PROFILES.id]: 'Involved Profiles',
        [reportCardEnum.SUPPLEMENT_OFFENSE.id]: offenseDisplayName,
        [reportCardEnum.SUPPLEMENT_INCIDENT.id]: 'Incident',
        [reportCardEnum.BEHAVIORAL_CRISIS.id]: 'Behavioral Crisis',
        [reportCardEnum.PROPERTY.id]: 'Property',
        [reportCardEnum.VEHICLE.id]: 'Vehicle',
        [reportCardEnum.REPORT_STATUS_COMMENTS.id]: 'Report Status & Comments',
        [reportCardEnum.SUMMARY_NARRATIVE.id]: 'Summary Narrative',
        [reportCardEnum.STOP.id]: 'Stop',
        [reportCardEnum.PERSONNEL.id]: 'Personnel',
        [reportCardEnum.LINKED_REPORTS.id]: 'Records',
        [reportCardEnum.STAFF_REMARKS.id]: 'Remarks',
        [reportCardEnum.LEGACY_INFO.id]: 'Migration Information',
        [reportCardEnum.CRASH_LOCATION_INFO.id]: 'Traffic Crash Location',
        [reportCardEnum.CRASH_EVENT_INFO.id]: 'General',
    };
    return cardTitleMap[cardId];
};

export const reportCardTitleByReportIdAndCardIdSelector = createSelector(
    reportDefinitionByReportIdSelector,
    formatFieldByNameSelector,
    (reportDefinitionByReportId, formatFieldByName) => (reportId: number, cardId: number) => {
        const reportDefinition = reportDefinitionByReportId(reportId);
        const configuredTitle = reportDefinition?.cardLinks.find(
            (cardLink) => cardLink.cardId === cardId
        )?.title;

        return (
            configuredTitle ||
            cardTitleByCardId(cardId, formatFieldByName) ||
            reportDefinition?.name
        );
    }
);

export const getReportIdAndReportDefinitionIds = (
    reports: ModuleShape<Report>,
    reportShortTitles: ModuleShape<ReportShortTitle>
): { reportId: number; reportDefinitionId: number }[] => {
    const reportIdAndReportDefinitionIds = chain(reports)
        .map(({ id, reportDefinitionId }) => ({
            reportId: id,
            reportDefinitionId,
        }))
        .value();

    const moreReportIdsAndReportDefinitionsIds = map(
        reportShortTitles,
        ({ reportId, reportDefinitionId }) => ({ reportId, reportDefinitionId })
    );

    return chain(reportIdAndReportDefinitionIds)
        .concat(moreReportIdsAndReportDefinitionsIds)
        .uniqBy('reportId')
        .value();
};

export const isOffenseReportSelector = createSelector(
    reportByIdSelector,
    reportDefinitionByIdSelector,
    (reportById, reportDefinitionById) => (reportId: number) => {
        const report = reportById(reportId);
        if (report) {
            const reportDefinition = reportDefinitionById(report.reportDefinitionId);
            return reportDefinitionHasOffenseCard(reportDefinition);
        } else {
            return false;
        }
    }
);

export const isOffenseModifyingSupplementReportSelector = createSelector(
    reportByIdSelector,
    reportDefinitionByIdSelector,
    (reportById, reportDefinitionById) => (reportId: number) => {
        const report = reportById(reportId);
        if (report) {
            const reportDefinition = reportDefinitionById(report.reportDefinitionId);
            return (
                reportDefinitionHasSupplementOffenseCard(reportDefinition) ||
                reportDefinitionHasSupplementIncidentCard(reportDefinition)
            );
        } else {
            return false;
        }
    }
);

export const formatReportDefinitionByReportIdSelector = createSelector(
    formatReportDefinitionByIdSelector,
    reportDefinitionByReportIdSelector,
    (formatReportDefinitionById, reportDefinitionByReportId) => (reportId: number) => {
        const reportDefinitionId = get(reportDefinitionByReportId(reportId), 'id');
        return formatReportDefinitionById(reportDefinitionId);
    }
);

export const formatCardNameWithRecordNumberByReportIdSelector = createSelector(
    reportByIdSelector,
    applicationSettingsSelector,
    reportCardTitleByReportIdAndCardIdSelector,
    (reportById, applicationSettings, reportCardTitleByReportIdAndCardId) =>
        (reportId: number, reportCardId: number) => {
            const report = reportById(reportId);
            const recordNumber = get(report, 'recordNumber');
            const customDisplayName = reportCardTitleByReportIdAndCardId(reportId, reportCardId);
            return !!applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED
                ? `${customDisplayName} ${recordNumber}`
                : customDisplayName;
        }
);

export const reportDefinitionsForRenSelector = createSelector(
    reportsWhereSelector,
    (reportsWhere) => (reportingEventNumber: string, departmentId?: number) => {
        const reportDefIds = map(
            reportsWhere({ reportingEventNumber, departmentId }),
            'reportDefinitionId'
        );
        return compact(uniq(reportDefIds));
    }
);

export default reportDefinitionModule.reducerConfig;

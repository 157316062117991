import { ComplianceGroupEnum, EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { get, map, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, { createRef } from 'react';

import styled from 'styled-components';
import { legacyEntityDetailsWhereSelector } from '~/client-common/core/domain/legacy-entity-details/state/data';
import { offensesWithoutClientSideStubs } from '~/client-common/core/domain/offenses/utils/offensesHelpers';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { reportDefinitionHasCardSelector } from '~/client-common/core/domain/report-definitions/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import testIds from '../../../../core/testIds';
import {
    canEditOffenseIncidentReportCardStatusSelector,
    currentReportCardUITitleByTypeSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import {
    initCards,
    addNewClientSideOffense,
    addNewClientSideOffenseIncident,
    reorderOffensesForReportId,
    sortedOffensesAndIncidentsForReportIdSelector,
    sortedStubOffenseIncidentsForReportIdSelector,
} from '../state/ui';
import offenseCards from '../state/ui/offenseCards';
import incidentCards from '../state/ui/incidentCards';
import { RMSArbiterProvider } from '../../../core/arbiter';
import { currentUserDepartmentProfileSelector } from '../../../core/current-user/state/ui';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { cardScrollHotKeyConfigForAnchor } from '../state/ui/cards';
import ReactHotKeysWrapper from '../../../core/hotkeys/components/ReactHotKeysWrapper';
import OffenseCard from './offense/OffenseCard';
import IncidentCard from './incident/IncidentCard';
import StubOffenseIncidentCard from './StubOffenseIncidentCard';

const ButtonWrapper = styled.div`
    clear: both;

    & .react-icon-left {
        height: auto;
    }
`;

const AddNewOffenseIncidentButton = styled(Button)`
    margin-top: 30px;
    margin-right: 0;
    width: 100%;
    height: 45px;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .mark43-icon::before {
        margin-top: -2px;
        display: inline-block;
        vertical-align: middle;
        float: none;
    }
`;

const mapStateToProps = createStructuredSelector({
    canEditOffenseIncidentReportCardStatus: canEditOffenseIncidentReportCardStatusSelector,
    sortedOffensesAndIncidentsForReportId: sortedOffensesAndIncidentsForReportIdSelector,
    legacyEntityDetailsWhere: legacyEntityDetailsWhereSelector,
    sortedStubOffenseIncidentsForReportId: sortedStubOffenseIncidentsForReportIdSelector,
    offenseCodes: offenseCodesSelector,
    reportDefinitionHasCard: reportDefinitionHasCardSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
    departmentProfile: currentUserDepartmentProfileSelector,
});

const mapDispatchToProps = {
    addNewClientSideOffense: ({ reportId, offenseCodeId, offenseId }) =>
        addNewClientSideOffense({ reportId, cardModule: offenseCards, offenseCodeId, offenseId }),
    addNewClientSideIncident: ({ reportId, offenseCodeId, offenseId }) =>
        addNewClientSideOffense({
            reportId,
            cardModule: incidentCards,
            isIncident: true,
            offenseCodeId,
            offenseId,
        }),
    addNewClientSideOffenseIncident: ({ reportId, scrollToTop }) =>
        addNewClientSideOffenseIncident({
            reportId,
            scrollToTop,
        }),
    initCards,
    reorderOffensesForReportId,
};

class OffenseIncidentCardsWrapper extends React.Component {
    /**
     * interface ICardRefs {
     *   [index: string] : {
     *     isIncident: boolean;
     *     ref?: ReactInstance;
     *   }
     * }
     */

    constructor(props) {
        super(props);

        const {
            sortedOffensesAndIncidentsForReportId,
            sortedStubOffenseIncidentsForReportId,
            offenseReportId,
            isNewReport,
        } = this.props;
        const { offenses, incidents } = sortedOffensesAndIncidentsForReportId(offenseReportId);
        const sortedStubOffenseIncidents = sortedStubOffenseIncidentsForReportId(offenseReportId);
        const hasPrefilledStubOffenseIncident =
            isEmpty(sortedStubOffenseIncidents) && isEmpty(offenses) && isEmpty(incidents);

        this.state = {
            hasPrefilledStubOffenseIncident,
            showPrefilledStubOffenseInSummaryMode: hasPrefilledStubOffenseIncident && !isNewReport,
            autoFocusCards: !isNewReport,
        };
        this.hotKeysRef = createRef(null);
    }

    cardRefs = {};

    componentDidMount() {
        const { editMode, sortedOffensesAndIncidentsForReportId } = this.props;
        const { offenses, incidents } = sortedOffensesAndIncidentsForReportId(
            this.props.offenseReportId
        );
        const offenseIds = map(offenses, 'id');

        if (this.state.hasPrefilledStubOffenseIncident) {
            this.handlePrefillStubOffenseIncident();
        }

        if (offenseIds.length) {
            this.props.initCards({
                cardModule: offenseCards,
                editMode,
                options: { indexes: offenseIds },
            });
        }

        const incidentIds = map(incidents, 'id');
        if (incidentIds.length) {
            this.props.initCards({
                cardModule: incidentCards,
                editMode,
                options: { indexes: incidentIds },
            });
        }
    }

    handleAddOffenseIncidentClick = () => {
        this.props.addNewClientSideOffenseIncident({
            reportId: this.props.offenseReportId,
            scrollToTop: true,
        });
        if (!this.state.autoFocusCards) {
            this.setState({ autoFocusCards: true });
        }
    };

    handleOffenseIncidentCodeChange = (offenseId) => (offenseCodeId) => {
        const offenseCode = get(this.props.offenseCodes, offenseCodeId);

        if (get(offenseCode, 'isOffenseType')) {
            this.props.addNewClientSideOffense({
                reportId: this.props.offenseReportId,
                offenseCodeId,
                offenseId,
            });
        } else if (get(offenseCode, 'isIncidentType')) {
            this.props.addNewClientSideIncident({
                reportId: this.props.offenseReportId,
                offenseCodeId,
                offenseId,
            });
        }
    };

    handlePrefillStubOffenseIncident = () => {
        this.props.addNewClientSideOffenseIncident({
            reportId: this.props.offenseReportId,
            scrollToTop: false,
        });
    };

    handleStubOffenseIncidentEditToggle = () => {
        this.setState({
            showPrefilledStubOffenseInSummaryMode: !this.state
                .showPrefilledStubOffenseInSummaryMode,
        });
    };

    handleOffenseReorder = ({ fromIndex, toIndex, reportId, offenseId }) => {
        this.props.reorderOffensesForReportId({
            fromIndex,
            toIndex,
            reportId,
            offenseId,
            cardModule: offenseCards,
            isIncident: false,
        });
    };

    handleOffenseIncidentCardRef = (id, isIncident = false) => (ref) => {
        if (!ref) {
            this.cardRefs[id] = undefined;
        } else {
            this.cardRefs[id] = {
                isIncident,
                ref,
            };
        }
    };

    focusHotKeysRef = () => {
        const elementToFocus = get(this.hotKeysRef, 'current');
        if (elementToFocus) {
            elementToFocus.focus();
        }
    };

    render() {
        const {
            editCallback,
            sortedOffensesAndIncidentsForReportId,
            offenseReportId,
            currentReportREN,
            legacyEntityDetailsWhere,
            canEditOffenseIncidentReportCardStatus,
            sortedStubOffenseIncidentsForReportId,
            reportDefinitionHasCard,
            reportDefinitionId,
            currentReportCardUITitleByType,
            departmentProfile,
        } = this.props;
        const {
            offenses: sortedOffenses,
            incidents: sortedIncidents,
        } = sortedOffensesAndIncidentsForReportId(offenseReportId);

        const sortedStubOffenseIncidents = sortedStubOffenseIncidentsForReportId(offenseReportId);

        const canEditReportCard = get(canEditOffenseIncidentReportCardStatus, 'canEditReportCard');

        const anchor = 'offense-incident-add-buttons';

        const offenseCardId = reportDefinitionHasCard(reportDefinitionId, reportCardEnum.OFFENSE.id)
            ? reportCardEnum.OFFENSE.id
            : reportCardEnum.SUPPLEMENT_OFFENSE.id;
        const incidentCardId = reportDefinitionHasCard(
            reportDefinitionId,
            reportCardEnum.INCIDENT.id
        )
            ? reportCardEnum.INCIDENT.id
            : reportCardEnum.SUPPLEMENT_INCIDENT.id;
        const stubOffenseIncidentCardTitle = joinTruthyValues(
            [
                currentReportCardUITitleByType(offenseCardId),
                currentReportCardUITitleByType(incidentCardId),
            ],
            '/'
        );

        const hasNoOffensesOrIncidents =
            isEmpty(sortedOffenses) &&
            isEmpty(sortedIncidents) &&
            isEmpty(sortedStubOffenseIncidents);

        const isUKComplianceGroup =
            departmentProfile.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name;

        const showAddOffenseIncidentButton =
            canEditReportCard && (!isUKComplianceGroup || hasNoOffensesOrIncidents);

        return (
            <div>
                <RMSArbiterProvider context={RefContextEnum.FORM_OFFENSE.name}>
                    {(arbiterInstance) => (
                        <div>
                            {map(sortedOffenses, (offense) => (
                                <OffenseCard
                                    ref={this.handleOffenseIncidentCardRef(offense.id)}
                                    key={offense.id}
                                    index={offense.id}
                                    editCallback={editCallback}
                                    offense={offense}
                                    arbiter={arbiterInstance}
                                    maxOffenseNumber={
                                        offensesWithoutClientSideStubs(sortedOffenses).length
                                    }
                                    onReorder={this.handleOffenseReorder}
                                    legacyEntityDetails={legacyEntityDetailsWhere({
                                        entityType: EntityTypeEnum.OFFENSE.name,
                                        entityId: offense.id,
                                    })}
                                    offenseReportId={offenseReportId}
                                    currentReportREN={currentReportREN}
                                    autoFocus={this.state.autoFocusCards}
                                    cardId={offenseCardId}
                                />
                            ))}
                        </div>
                    )}
                </RMSArbiterProvider>
                <RMSArbiterProvider context={RefContextEnum.FORM_INCIDENT.name}>
                    {(arbiterInstance) => (
                        <div>
                            {map(sortedIncidents, (incident) => (
                                <IncidentCard
                                    ref={this.handleOffenseIncidentCardRef(incident.id, true)}
                                    key={incident.id}
                                    index={incident.id}
                                    editCallback={editCallback}
                                    incident={incident}
                                    arbiter={arbiterInstance}
                                    maxOffenseNumber={
                                        offensesWithoutClientSideStubs(sortedIncidents).length
                                    }
                                    legacyEntityDetails={legacyEntityDetailsWhere({
                                        entityType: EntityTypeEnum.OFFENSE.name,
                                        entityId: incident.id,
                                    })}
                                    offenseReportId={offenseReportId}
                                    currentReportREN={currentReportREN}
                                    autoFocus={this.state.autoFocusCards}
                                    cardId={incidentCardId}
                                />
                            ))}
                        </div>
                    )}
                </RMSArbiterProvider>
                <RMSArbiterProvider context={RefContextEnum.FORM_STUB_OFFENSE_INCIDENT.name}>
                    {(arbiterInstance) =>
                        map(sortedStubOffenseIncidents, (stubOffenseIncident) => (
                            <StubOffenseIncidentCard
                                offenseIncident={stubOffenseIncident}
                                key={stubOffenseIncident.id}
                                index={stubOffenseIncident.id}
                                summaryMode={this.state.showPrefilledStubOffenseInSummaryMode}
                                onChangeOffenseIncidentCode={this.handleOffenseIncidentCodeChange(
                                    stubOffenseIncident.id
                                )}
                                onEdit={this.handleStubOffenseIncidentEditToggle}
                                canEdit={canEditReportCard}
                                autoFocus={this.state.autoFocusCards}
                                arbiter={arbiterInstance}
                                title={stubOffenseIncidentCardTitle}
                            />
                        ))
                    }
                </RMSArbiterProvider>
                {showAddOffenseIncidentButton && (
                    <ReactHotKeysWrapper
                        hotKeysConfig={cardScrollHotKeyConfigForAnchor(anchor)}
                        hotKeysRef={this.hotKeysRef}
                    >
                        <ButtonWrapper data-anchor={anchor} onClick={this.focusHotKeysRef}>
                            <AddNewOffenseIncidentButton
                                className={buttonTypes.SECONDARY}
                                iconLeft={<Icon fontSize="20px" type={iconTypes.ADD} />}
                                onClick={() => editCallback(this.handleAddOffenseIncidentClick)}
                                testId={testIds.ADD_NEW_OFFENSE_INCIDENT_BUTTON}
                            >
                                {stubOffenseIncidentCardTitle}
                            </AddNewOffenseIncidentButton>
                        </ButtonWrapper>
                    </ReactHotKeysWrapper>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    OffenseIncidentCardsWrapper
);

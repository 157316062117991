import React, { forwardRef, ReactNode } from 'react';
import { Button as ArcButton, ButtonProps as ArcButtonProps } from './Button';

type AddNameButtonProps = ArcButtonProps & {
    testId?: string;
    showQuickAdd?: boolean;
    onlyOption?: boolean;
    children?: ReactNode;
};

export const AddNameButton = forwardRef<HTMLButtonElement, AddNameButtonProps>(
    (
        {
            showQuickAdd,
            onlyOption,

            ...props
        },
        ref
    ) => {
        return showQuickAdd || onlyOption ? (
            <ArcButton
                {...props}
                leftIcon="Add"
                variant="ghost"
                ref={ref}
                style={{ paddingLeft: 0 }}
            />
        ) : (
            <ArcButton {...props} variant="outline" ref={ref} />
        );
    }
);

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import { get } from 'lodash';

import {
    consortiumDepartmentLinksAvailableSelector,
    departmentNameFromConsortiumLinksByDepartmentIdSelector,
} from '~/client-common/core/domain/consortium-link-view/state/ui';
import {
    formatFullName,
    formatHeightFull,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { elasticPersonsSelector } from '~/client-common/core/domain/elastic-persons/state/data/';
import testIds from '../../../../core/testIds';

import { useDateTimeFormatter } from '../../current-user/hooks/dateTimeFormats';
import PersonPill from './PersonPill';

const ElasticPersonPill = ({ elasticPerson, ...otherProps }) => {
    const isInConsortium = useSelector(consortiumDepartmentLinksAvailableSelector);
    const departmentNameById = useSelector(departmentNameFromConsortiumLinksByDepartmentIdSelector);

    const { isDead, departmentId } = elasticPerson;

    const dateTimeFormatter = useDateTimeFormatter();

    const dateOfBirth = dateTimeFormatter.formatDate(elasticPerson.dateOfBirth);
    const sex = get(elasticPerson, ['sexAttrDetail', 'displayValue']);
    const race = get(elasticPerson, ['raceAttrDetail', 'displayValue']);
    const height = formatHeightFull(elasticPerson);
    const eyeColor = get(elasticPerson, ['eyeColorAttrDetail', 'displayValue']);
    const hairColor = get(elasticPerson, ['hairColorAttrDetail', 'displayValue']);
    const socialSecurityNumber = elasticPerson.ssn;
    const driversLicenseNumber = elasticPerson.dlNumber;

    const primaryMugshotPath = elasticPerson.primaryMugshotPath;
    const nicknames = elasticPerson.nicknames;
    const fullName = formatFullName(elasticPerson);
    const departmentProfileDisplayName = isInConsortium
        ? departmentNameById(departmentId)
        : undefined;

    return (
        <PersonPill
            dateOfBirth={dateOfBirth}
            sex={sex}
            race={race}
            height={height}
            eyeColor={eyeColor}
            hairColor={hairColor}
            socialSecurityNumber={socialSecurityNumber}
            driversLicenseNumber={driversLicenseNumber}
            isDead={isDead}
            primaryMugshotPath={primaryMugshotPath}
            nicknames={nicknames}
            fullName={fullName}
            person={elasticPerson}
            departmentProfileDisplayName={departmentProfileDisplayName}
            {...otherProps}
            testId={testIds.NAME_SIDE_PANEL_SEARCH_RESULT_PERSON}
        />
    );
};

export default compose(
    connect(
        createStructuredSelector({
            elasticPersons: elasticPersonsSelector,
        })
    ),
    withPropsOnChange(['id', 'elasticPersons'], ({ id, elasticPersons }) => {
        const elasticPerson = elasticPersons[id];
        return {
            elasticPerson: elasticPerson || {},
        };
    })
)(ElasticPersonPill);

import { RefContextEnum } from '@mark43/rms-api';
import React, { useEffect, useRef, useState } from 'react';
import { defer, indexOf, map, size, take, without } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { getNextFocusableElement } from '~/client-common/core/keyboardFocus/helpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';

import { Button as ArcButton } from '../../components/Button';
import { OrganizationQuickAddContext } from '../../context/OrganizationQuickAddContext';
import loadingStateEnum from '../../context/constants/loadingStateEnum';
import { requestRecentPersonsOrOrganizations } from '../../../../legacy-redux/actions/recentEntitiesActions';
import Icon, { iconTypes, iconSizes } from '../../components/Icon';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import QuickAddInstruction from '../../components/QuickAddInstruction';
import { QuickAddGrid } from '../../components/QuickAddGrid';
import { fetchAndBuildOrganizationProfileFormModel } from '../state/ui';
import {
    quickAddOrganization,
    sortedRecentElasticOrgsForContextSelector,
    sortedRecentElasticSocietyOrgsForContextSelector,
} from '../state/data';

import { RMSArbiterProvider } from '../../arbiter';
import testIds from '../../../../core/testIds';
import OrganizationPillSmall from './OrganizationPillSmall';

const strings = componentStrings.core;
const INITIAL_NUM_ORGANIZATIONS_TO_SHOW = 4;

const OrganizationQuickAddContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    padding-right: 15px;
`;

const ExpandButtonContainer = styled.div`
    display: flex;
    width: 100%;
    margin-right: 15px;
    justify-content: center;
`;

const ExpandButton = styled(Button)`
    padding: 5px;
    margin: 5px auto 0;

    & > .react-icon-right {
        margin-left: 10px;
    }
`;

const OrganizationQuickAdd = ({
    className,
    renForRecents,
    contextType,
    contextId,
    ownerType,
    ownerId,
    linkType,
    onAddSuccess,
    showSocieties,
    sortedRecentElasticOrgsForContext,
    sortedRecentElasticSocietyOrgsForContext,
    requestRecentOrganizations,
    quickAddOrganization,
    setCancelFocusRef,
    organizationOverlayId,
    onSocietyProfileClick,
    stubbedLinks,
    societyOffenseRequiresSocietyVictim,
    nibrsCrimeAgainstSociety = false,
    applicationSettings,
}) => {
    const {
        isOrganizationsStale,
        setIsOrganizationsStale,
        organizationsLoadingState,
        setOrganizationsLoadingState,
    } = OrganizationQuickAddContext.useContainer();
    const expandedRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [workingEntityIds, setWorkingEntityIds] = useState([]);

    const { DISPLAY_ONLY_ORGANIZATION_LABEL: organizationLabel } = useFields([
        DISPLAY_ONLY_ORGANIZATION_LABEL,
    ]);

    useEffect(() => {
        if (organizationsLoadingState === loadingStateEnum.DO_LOAD) {
            setOrganizationsLoadingState((prevLoadingState) => {
                if (prevLoadingState === loadingStateEnum.DO_LOAD) {
                    defer(() =>
                        requestRecentOrganizations({
                            renForRecents,
                            ownerType,
                            ownerId,
                            onComplete: () => {
                                setOrganizationsLoadingState(loadingStateEnum.READY);
                                setIsOrganizationsStale(false);
                            },
                        })
                    );
                    return loadingStateEnum.IN_PROGRESS;
                } else {
                    return prevLoadingState;
                }
            });
        }
    }, [
        organizationsLoadingState,
        setOrganizationsLoadingState,
        renForRecents,
        ownerType,
        ownerId,
        requestRecentOrganizations,
        setIsOrganizationsStale,
    ]);

    useEffect(() => {
        if (isOrganizationsStale) {
            setOrganizationsLoadingState((prevLoadingState) =>
                prevLoadingState === loadingStateEnum.READY
                    ? loadingStateEnum.DO_LOAD
                    : prevLoadingState
            );
        }
    }, [isOrganizationsStale, setOrganizationsLoadingState]);

    useEffect(() => {
        if (isExpanded && expandedRef.current) {
            expandedRef.current.focus();
        }
    }, [isExpanded, expandedRef]);

    const societyProfiles = sortedRecentElasticSocietyOrgsForContext({
        ownerType,
        ownerId,
        linkType,
        contextId,
        showSocieties,
        stubbedLinks,
    });

    const organizations = sortedRecentElasticOrgsForContext({
        renForRecents,
        ownerType,
        ownerId,
        linkType,
        contextId,
        showSocieties,
        stubbedLinks,
    });

    societyOffenseRequiresSocietyVictim =
        applicationSettings.RMS_NIBRS_SOCIETY_OFFENSE_REQUIRES_SOCIETY_VICTIM_ENABLED;

    const recentOrganizations = societyOffenseRequiresSocietyVictim
        ? nibrsCrimeAgainstSociety
            ? societyProfiles
            : organizations
        : organizations;

    const recentOrganizationsCount = size(recentOrganizations);

    return (
        <RMSArbiterProvider context={RefContextEnum.FORM_ORGANIZATION_SIDE_PANEL.name}>
            {(arbiterInstance) => (
                <OrganizationQuickAddContainer className={className}>
                    {recentOrganizationsCount > 0 && (
                        <>
                            <QuickAddInstruction
                                renForRecents={renForRecents}
                                type={strings.QuickAddInstruction.organizations}
                                suffix={showSocieties && ' or society profiles'}
                                societyOffenseRequiresSocietyVictim={
                                    societyOffenseRequiresSocietyVictim
                                }
                            />
                            <QuickAddGrid>
                                {map(
                                    take(
                                        recentOrganizations,
                                        isExpanded
                                            ? recentOrganizationsCount
                                            : INITIAL_NUM_ORGANIZATIONS_TO_SHOW
                                    ),
                                    (recentOrganization, index) => {
                                        const onOrganizationClick = (ref) => {
                                            setWorkingEntityIds([
                                                ...workingEntityIds,
                                                recentOrganization.id,
                                            ]);
                                            setCancelFocusRef(ref);
                                            const nextFocusableElement = getNextFocusableElement(
                                                ref.current
                                            );
                                            quickAddOrganization({
                                                entityId: recentOrganization.id,
                                                linkType,
                                                ownerType,
                                                ownerId,
                                                contextType,
                                                contextId,
                                                onAddSuccess,
                                                onComplete: () => {
                                                    setIsOrganizationsStale(true);
                                                    setWorkingEntityIds(
                                                        without(
                                                            workingEntityIds,
                                                            recentOrganization.id
                                                        )
                                                    );
                                                    if (nextFocusableElement) {
                                                        nextFocusableElement.focus();
                                                    }
                                                },
                                                arbiterInstance,
                                                organizationOverlayId,
                                                organizationLabel,
                                            });
                                        };

                                        const handleSocietyProfileClick = (ref) => {
                                            setWorkingEntityIds([
                                                ...workingEntityIds,
                                                recentOrganization.id,
                                            ]);
                                            setCancelFocusRef(ref);
                                            const nextFocusableElement = getNextFocusableElement(
                                                ref.current
                                            );
                                            const onSuccess = () => {
                                                setWorkingEntityIds(
                                                    without(workingEntityIds, recentOrganization.id)
                                                );
                                                if (nextFocusableElement) {
                                                    nextFocusableElement.focus();
                                                }
                                            };
                                            return onSocietyProfileClick(
                                                recentOrganization.id,
                                                onSuccess
                                            );
                                        };

                                        const onClick = recentOrganization.isSocietyProfile
                                            ? handleSocietyProfileClick
                                            : onOrganizationClick;
                                        return (
                                            <OrganizationPillSmall
                                                key={recentOrganization.id}
                                                organization={recentOrganization}
                                                disabled={
                                                    indexOf(
                                                        workingEntityIds,
                                                        recentOrganization.id
                                                    ) >= 0
                                                }
                                                ref={
                                                    index === INITIAL_NUM_ORGANIZATIONS_TO_SHOW
                                                        ? expandedRef
                                                        : null
                                                }
                                                onClick={onClick}
                                                testId={testIds.QUICK_ADD_ORGANIZATION_PILL}
                                            />
                                        );
                                    }
                                )}
                            </QuickAddGrid>
                        </>
                    )}
                    {!isExpanded && recentOrganizationsCount > INITIAL_NUM_ORGANIZATIONS_TO_SHOW && (
                        <ExpandButtonContainer>
                            <FeatureFlagged
                                flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                                fallback={
                                    <ExpandButton
                                        className={buttonTypes.ICON_LINK}
                                        onClick={() => setIsExpanded(true)}
                                        iconRight={
                                            <Icon
                                                color="cobaltBlue"
                                                type={iconTypes.OPEN}
                                                size={iconSizes.MEDIUM}
                                            />
                                        }
                                    >
                                        {strings.OrganizationQuickAdd.more(
                                            recentOrganizationsCount -
                                                INITIAL_NUM_ORGANIZATIONS_TO_SHOW
                                        )}
                                    </ExpandButton>
                                }
                            >
                                <ArcButton
                                    onClick={() => setIsExpanded(true)}
                                    rightIcon="Open"
                                    variant="ghost"
                                >
                                    {strings.OrganizationQuickAdd.more(
                                        recentOrganizationsCount - INITIAL_NUM_ORGANIZATIONS_TO_SHOW
                                    )}
                                </ArcButton>
                            </FeatureFlagged>
                        </ExpandButtonContainer>
                    )}
                </OrganizationQuickAddContainer>
            )}
        </RMSArbiterProvider>
    );
};

const mapDispatchToProps = (dispatch) => ({
    requestRecentOrganizations: ({ renForRecents, ownerType, ownerId, onComplete }) => {
        return dispatch(
            requestRecentPersonsOrOrganizations({
                renForRecents,
                ownerType,
                ownerId,
                isOrg: true,
            })
        ).finally(() => {
            if (onComplete) {
                onComplete();
            }
        });
    },
    quickAddOrganization: ({
        entityId,
        linkType,
        ownerType,
        ownerId,
        contextType,
        contextId,
        onAddSuccess,
        onComplete,
        arbiterInstance,
        organizationOverlayId,
        organizationLabel,
    }) => {
        dispatch(
            fetchAndBuildOrganizationProfileFormModel({
                organizationProfileId: entityId,
                ownerId,
                ownerType,
                linkType,
                onSuccess: (formModel) =>
                    dispatch(
                        quickAddOrganization({
                            formModel,
                            contextType,
                            contextId,
                            ownerType,
                            ownerId,
                            linkType,
                            entityId,
                            onAddSuccess,
                            onComplete,
                            arbiterInstance,
                            organizationOverlayId,
                            organizationLabel,
                        })
                    ),
            })
        );
    },
});

const mapStateToProps = createStructuredSelector({
    sortedRecentElasticOrgsForContext: sortedRecentElasticOrgsForContextSelector,
    sortedRecentElasticSocietyOrgsForContext: sortedRecentElasticSocietyOrgsForContextSelector,
    applicationSettings: applicationSettingsSelector,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationQuickAdd);

import { ConsortiumProfile } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'consortiumProfiles';

const consortiumProfilesModule = createNormalizedModule<ConsortiumProfile>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// SELECTORS
export const consortiumProfilesSelector = consortiumProfilesModule.selectors.entitiesSelector;

// REDUCER
export default consortiumProfilesModule.reducerConfig;

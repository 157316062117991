import { map, sortBy, some, filter } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import testIds from '../../../../../core/testIds';
import RecordSidebarSection from './RecordSidebarSection';
import CourtOrder from './CourtOrder';

const strings = componentStrings.reports.ReportSidebar;

const courtOrdersSectionSelector = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
});

function CourtOrdersSection({ courtOrders, reportId, router, currentUserHasAbility }) {
    const hasSealedCourtOrder = some(courtOrders, { isVacate: false });
    const editPath = hasSealedCourtOrder
        ? `/reports/${reportId}/court-orders`
        : `/reports/${reportId}/charge-vacating`;
    const onCourtOrderEdit = currentUserHasAbility(abilitiesEnum.REPORTING.EXECUTE_COURT_ORDERS)
        ? () => router.push(editPath)
        : null;

    if (hasSealedCourtOrder) {
        courtOrders = filter(courtOrders, { isVacate: false });
    }
    const regularSealings = courtOrders.filter((x) => !x.statuteCodeAttrId);
    const statuteSealings = courtOrders
        .filter((x) => !!x.statuteCodeAttrId)
        .reduce((acc, courtOrder) => {
            const groupedKey = `${courtOrder.statuteCodeAttrId}-${courtOrder.remarks}`;
            if (acc[groupedKey]) {
                acc[groupedKey].allInvolvedPeople = [
                    ...acc[groupedKey].allInvolvedPeople,
                    courtOrder.involvedPersonFullName,
                ];
                acc[groupedKey].allCourtOrderIds = [
                    ...acc[groupedKey].allCourtOrderIds,
                    courtOrder.id,
                ];
            } else {
                acc[groupedKey] = courtOrder;
                acc[groupedKey].allInvolvedPeople = [courtOrder.involvedPersonFullName];
                acc[groupedKey].allCourtOrderIds = [courtOrder.id];
            }
            return acc;
        }, {});

    const consolidatedCourtOrders = [...regularSealings, ...Object.values(statuteSealings)];

    const sortedCourtOrders = sortBy(consolidatedCourtOrders, 'createdDateUtc').reverse();

    return (
        <RecordSidebarSection
            title={strings.CourtOrderSection.title}
            onEdit={onCourtOrderEdit}
            sectionTestId={testIds.COURT_ORDERS_INFORMATION_SECTION}
            editButtonTestId={testIds.COURT_ORDERS_INFORMATION_EDIT}
        >
            {map(sortedCourtOrders, (courtOrder) => {
                return <CourtOrder key={courtOrder.id} courtOrder={courtOrder} />;
            })}
        </RecordSidebarSection>
    );
}

export default compose(withRouter, connect(courtOrdersSectionSelector))(CourtOrdersSection);

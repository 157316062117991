import { StationView } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getStationsResource from '../../resources/stationsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const stationsModule = createNormalizedModule<StationView>({
    type: 'stations',
});

const LOAD_STATIONS_START = 'STATIONS/LOAD_STATIONS_START';
const LOAD_STATIONS_SUCCESS = 'STATIONS/LOAD_STATIONS_SUCCESS';
const LOAD_STATIONS_FAILURE = 'STATIONS/LOAD_STATIONS_FAILURE';

// ACTIONS
export const storeStations = stationsModule.actionCreators.storeEntities;

function loadStationsStart() {
    return {
        type: LOAD_STATIONS_START,
    };
}

function loadStationsSuccess(stations: StationView[]) {
    return {
        type: LOAD_STATIONS_SUCCESS,
        payload: stations,
    };
}

function loadStationsFailure(err: string) {
    return {
        type: LOAD_STATIONS_FAILURE,
        payload: err,
    };
}

export function loadStations(): ClientCommonAction<Promise<void>> {
    const resource = getStationsResource();
    return function (dispatch) {
        dispatch(loadStationsStart());
        return resource
            .getStations()
            .then((stationViews: StationView[]) => {
                dispatch(storeStations(stationViews));
                dispatch(loadStationsSuccess(stationViews));
            })
            .catch((err: Error) => {
                dispatch(loadStationsFailure(err.message));
            });
    };
}

// SELECTORS

export const stationsSelector = stationsModule.selectors.entitiesSelector;
export const stationByIdSelector = stationsModule.selectors.entityByIdSelector;

export default stationsModule.reducerConfig;

import styled from 'styled-components';
import React, { Component } from 'react';
import Composer from 'react-composer';
import { Text, cssVar } from 'arc';
import { SomeFieldsHaveValue, SomeFieldsHaveError } from './SomeFieldsHaveValue';

const LabelExpanderText = styled(Text)`
    font-size: ${cssVar('arc.fontSizes.xs')};
    color: ${cssVar('arc.colors.brand.default')};
    cursor: pointer;
    position: relative;
    text-decoration: none;
    border: none;
    padding: 0;
    background-color: transparent;

    &:hover {
        text-decoration: underline;
    }

    ${(props) => {
        if (props.position === 'bottom') {
            return `top: -5px;`;
        } else if (props.position === 'right') {
            return `top: 20px;`;
        }
    }};
`;

export const LabelExpander = (props) => <LabelExpanderText as="button" type="button" {...props} />;

class TextSectionExpander extends Component {
    static getDerivedStateFromProps(props, state) {
        // If the forceOpen prop is present
        // and truthy, keep the expander open
        //
        // Otherwise, continue using internal state
        if (props.forceOpen) {
            return {
                ...state,
                showContent: true,
            };
        }
        return null;
    }

    state = {
        showContent: this.props.forceOpen,
    };

    showContent = () => {
        if (this.props.onShow) {
            this.props.onShow();
        }
        this.setState({ showContent: this.props.forceOpen || true });
    };

    hideContent = () => {
        if (this.props.onHide) {
            this.props.onHide();
        }
        this.setState({ showContent: this.props.forceOpen || false });
    };

    render() {
        const { children, expanderContent, collapserContent } = this.props;

        return this.state.showContent ? (
            <>
                {children}
                {collapserContent && collapserContent(this.hideContent)}
            </>
        ) : (
            expanderContent && expanderContent(this.showContent)
        );
    }
}

// Note that the `customSubscription` needs the absolute path
// whereas we just need the relative path for the field
export const ExpandableFormLabel = ({
    formPathsToObserve,
    children,
    onHide,
    onShow,
    expanderContent,
    collapserContent,
    forceOpen,
}) => (
    <Composer
        components={[
            <SomeFieldsHaveError key="error" formPathsToObserve={formPathsToObserve} />,
            <SomeFieldsHaveValue key="value" formPathsToObserve={formPathsToObserve} />,
        ]}
    >
        {([someFieldsHaveError, someFieldsHaveValue]) => (
            <TextSectionExpander
                onHide={onHide}
                onShow={onShow}
                forceOpen={forceOpen || someFieldsHaveValue || someFieldsHaveError}
                expanderContent={expanderContent}
                collapserContent={collapserContent}
            >
                {children}
            </TextSectionExpander>
        )}
    </Composer>
);

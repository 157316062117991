import { ReportscardsconfigurationResource } from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';
import type { PartialReportDefinitionOrderedCardViewModel } from '../../../admin/report-types/types';

export default createResource({
    name: 'Report Card Configuration Resource',
    methods: {
        getReportDefinitionOrderedCards(reportDefinitionId: number) {
            return req<ReportscardsconfigurationResource.GetReportDefinitionOrderedCardsForReportDefinition>(
                {
                    method: 'GET',
                    url: `reports/cards/configuration/${reportDefinitionId}/order`,
                }
            );
        },
        replaceReportDefinitionOrderedCards(
            reportDefinitionId: number,
            data: PartialReportDefinitionOrderedCardViewModel
        ) {
            return req<ReportscardsconfigurationResource.ReplaceReportDefinitionOrderedCardsForReportDefinition>(
                {
                    method: 'PUT',
                    url: `reports/cards/configuration/${reportDefinitionId}/order`,
                    // @ts-expect-error Versionable properties are not required
                    data,
                }
            );
        },
    },
});

import { EntityTypeEnum, AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatAttributeWithOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import withFields from '~/client-common/core/fields/components/withFields';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import withCard from '../../utils/withCard';
import involvedProfilesForm, {
    buildInvolvedProfilesCardFormModel,
} from '../../state/forms/involvedProfilesForm';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import involvedProfilesCard from '../../state/ui/involvedProfilesCard';
import testIds from '../../../../../core/testIds';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import Row from '../../../../core/components/Row';
import { RRFAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { RRFText } from '../../../../core/forms/components/Text';
import IndentedFields from '../../../../core/components/IndentedFields';
import { RRFFieldset } from '../../../../core/forms/components/Fieldset';
import { RRFLocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import { registerCard } from '../../utils/cardsRegistry';
import { initializeCardForm } from '../../state/ui/cards';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

const strings = componentStrings.reports.core.InvolvedProfilesCard;

class InvolvedProfilesCard extends React.Component {
    constructor(...args) {
        super(...args);

        this.onEdit = this.onEdit.bind(this);
        this.onSaveProgress = this.onSaveProgress.bind(this);
        this.onSave = this.onSave.bind(this);

        registerCard({
            cardModule: involvedProfilesCard,
            onSave: this.onSave,
        });
    }

    componentDidMount() {
        const formModel = this.props.buildInvolvedProfilesCardFormModel({
            reportId: this.props.currentReportId,
        });
        this.props.changeForm(formModel);
        this.props.initializeCardForm(involvedProfilesForm);
    }

    onEdit() {
        this.props.editCallback(() => this.props.onEdit({ reportId: this.props.currentReportId }));
    }

    onSaveProgress() {
        return this.props.onSaveProgress();
    }

    onSave() {
        return this.props.onSave();
    }

    render() {
        const {
            card = {},
            currentReportId,
            reportById,
            formatAttributeWithOther,
            currentReportCardUITitleByType,
        } = this.props;
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.INVOLVED_PROFILES.id);
        const ren = get(reportById(currentReportId), 'reportingEventNumber');

        const personNameSummaryViewWrapper = (
            <NameSummaryViewWrapper
                renForRecents={ren}
                form={involvedProfilesForm}
                formPath="personSubjectNameReportLinks"
                summaryMode={card.summaryMode}
                addNameButtonText={strings.personAddButtonText}
                reportId={currentReportId}
                contextType={EntityTypeEnum.REPORT.name}
                contextId={currentReportId}
                parentEntityType={EntityTypeEnum.REPORT.name}
                parentId={currentReportId}
                linkType={LinkTypesEnum.INVOLVED_PERSON_IN_REPORT}
                show={{ people: true }}
                renderAdditionalItem={({ summaryMode, nameLink, index }) => {
                    return (
                        <FeatureFlagged
                            flag="RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED"
                            fallback={
                                summaryMode ? (
                                    <SummaryList labelWidth={100} contentWidth={400}>
                                        <SummaryRow
                                            fieldName={
                                                fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_SUBJECT_TYPE_ATTR_ID
                                            }
                                        >
                                            {formatAttributeWithOther({
                                                attributeId: nameLink.subjectTypeAttrId,
                                                other: nameLink.subjectTypeOther,
                                            })}
                                        </SummaryRow>
                                    </SummaryList>
                                ) : (
                                    <RRFFieldset
                                        path={`personSubjectNameReportLinks.${index}`}
                                        highlightOnFocus={false}
                                    >
                                        <Row>
                                            <RRFAttributeSelect
                                                path="subjectTypeAttrId"
                                                attributeType={
                                                    AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE
                                                        .name
                                                }
                                                length="lg"
                                            />
                                        </Row>
                                        <IndentedFields>
                                            <Row>
                                                <RRFText path="subjectTypeOther" length="lg" />
                                            </Row>
                                        </IndentedFields>
                                    </RRFFieldset>
                                )
                            }
                        >
                            <SummaryList labelWidth={100} contentWidth={400}>
                                <SummaryRow
                                    fieldName={
                                        fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_SUBJECT_TYPE_ATTR_ID
                                    }
                                >
                                    {formatAttributeWithOther({
                                        attributeId: nameLink.subjectTypeAttrId,
                                        other: nameLink.subjectTypeOther,
                                    })}
                                </SummaryRow>
                            </SummaryList>
                        </FeatureFlagged>
                    );
                }}
            />
        );

        const organizationLabel = this.props.fieldDisplayNames[
            fields.DISPLAY_ONLY_ORGANIZATION_LABEL
        ];
        const orgNameSummaryViewWrapper = (
            <NameSummaryViewWrapper
                renForRecents={ren}
                form={involvedProfilesForm}
                formPath="orgSubjectNameReportLinks"
                summaryMode={card.summaryMode}
                addNameButtonText={strings.orgAddButtonText(organizationLabel)}
                reportId={currentReportId}
                contextType={EntityTypeEnum.REPORT.name}
                contextId={currentReportId}
                parentEntityType={EntityTypeEnum.REPORT.name}
                parentId={currentReportId}
                linkType={LinkTypesEnum.INVOLVED_ORG_IN_REPORT}
                show={{ organizations: true, societies: true }}
                renderAdditionalItem={({ summaryMode, nameLink, index }) => {
                    return summaryMode ? (
                        <SummaryList labelWidth={100} contentWidth={400}>
                            <SummaryRow
                                fieldName={
                                    fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_SUBJECT_TYPE_ATTR_ID
                                }
                            >
                                {formatAttributeWithOther({
                                    attributeId: nameLink.subjectTypeAttrId,
                                    other: nameLink.subjectTypeOther,
                                })}
                            </SummaryRow>
                        </SummaryList>
                    ) : (
                        <RRFFieldset
                            path={`orgSubjectNameReportLinks.${index}`}
                            highlightOnFocus={false}
                        >
                            <Row>
                                <RRFAttributeSelect
                                    path="subjectTypeAttrId"
                                    attributeType={
                                        AttributeTypeEnum.FIELD_CONTACT_SUBJECT_TYPE.name
                                    }
                                    length="lg"
                                />
                            </Row>
                            <IndentedFields>
                                <Row>
                                    <RRFText path="subjectTypeOther" length="lg" />
                                </Row>
                            </IndentedFields>
                        </RRFFieldset>
                    );
                }}
            />
        );

        const locationSummaryViewWrapper = (
            <RRFLocationSummaryViewWrapperWithFormFields
                summaryMode={card.summaryMode}
                entityType={EntityTypeEnum.REPORT.name}
                entityId={currentReportId}
                linkType={LinkTypesEnum.OTHER_LOCATION_IN_REPORT}
                allowMultiple={true}
                locationPath="involvedProfilesLocations"
                locationDescriptionPath={() => 'description'}
                locationPositionAttrIdPath={() => 'positionAttrId'}
            />
        );

        return (
            <Card
                className={card.anchor}
                anchor={card.anchor}
                title={cardTitle}
                renderContent={() => {
                    return (
                        <ReactReduxForm {...involvedProfilesForm}>
                            <CardSection
                                title={strings.personSectionTitle}
                                testId={testIds.INVOLVED_PROFILES_PERSONS_SECTION}
                            >
                                {personNameSummaryViewWrapper}
                            </CardSection>
                            <CardSection
                                title={strings.orgSectionTitle(organizationLabel)}
                                testId={testIds.INVOLVED_PROFILES_ORGANIZATIONS_SECTION}
                            >
                                {orgNameSummaryViewWrapper}
                            </CardSection>
                            <CardSection
                                title={strings.locationSectionTitle}
                                testId={testIds.INVOLVED_PROFILES_LOCATIONS_SECTION}
                            >
                                {locationSummaryViewWrapper}
                            </CardSection>
                        </ReactReduxForm>
                    );
                }}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
                testId={testIds.INVOLVED_PROFILES_CARD}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatAttributeWithOther: formatAttributeWithOtherSelector,
    reportById: reportByIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    changeForm: involvedProfilesForm.actionCreators.change,
    buildInvolvedProfilesCardFormModel,
    initializeCardForm,
};

export default compose(
    withCard(involvedProfilesCard),
    withFields([fields.DISPLAY_ONLY_ORGANIZATION_LABEL]),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(InvolvedProfilesCard);

import { createSelector } from 'reselect';

import { makeResettable } from '~/client-common/helpers/reducerHelpers';

import dataActionTypes from '../data/actionTypes';
import { base64ToDataUri } from '../../../../evidence/label-printing/utils/labelHelpers';
import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import { actionTypes as uiActionTypes } from './actions';

export * from './actions';

export const evidenceConfigAdminUiSelector = (state) => state.ui.evidenceConfigAdmin;

const labelPreviewBase64Selector = createSelector(
    evidenceConfigAdminUiSelector,
    ({ labelPreviewBase64 }) => labelPreviewBase64
);

export const labelPreviewDataUriSelector = createSelector(
    labelPreviewBase64Selector,
    (labelPreviewBase64) => base64ToDataUri(labelPreviewBase64)
);

export default makeResettable(
    ENTER_NEW_ROUTE,
    function evidenceConfigAdminUiReducer(
        state = {
            loadingConfigErrorMessage: null,
            savingConfigErrorMessage: null,
            savingConfigSuccess: false,
            loadingLabelPreview: false,
            loadingLabelPreviewErrorMessage: null,
            labelPreviewBase64: null,
        },
        action
    ) {
        switch (action.type) {
            case dataActionTypes.LOAD_SETTINGS_FAILURE:
                return {
                    ...state,
                    loadingConfigErrorMessage: action.payload,
                };
            case dataActionTypes.SAVE_SETTINGS_SUCCESS:
                return {
                    ...state,
                    savingConfigErrorMessage: null,
                    savingConfigSuccess: true,
                };
            case dataActionTypes.SAVE_SETTINGS_FAILURE:
                return {
                    ...state,
                    savingConfigErrorMessage: action.payload,
                    savingConfigSuccess: false,
                };
            case uiActionTypes.LOAD_LABEL_PREVIEW_START:
                return {
                    ...state,
                    loadingLabelPreview: true,
                    loadingLabelPreviewErrorMessage: null,
                };
            case uiActionTypes.LOAD_LABEL_PREVIEW_SUCCESS:
                return {
                    ...state,
                    loadingLabelPreview: false,
                    loadingLabelPreviewErrorMessage: null,
                    labelPreviewBase64: action.payload,
                };
            case uiActionTypes.LOAD_LABEL_PREVIEW_FAILURE:
                return {
                    ...state,
                    loadingLabelPreview: false,
                    loadingLabelPreviewErrorMessage: action.payload,
                };
            default:
                return state;
        }
    }
);

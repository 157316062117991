import factory from './actionTypesFactory';

export default factory('global', [
    'WINDOW_RESIZE',
    'SHOW_HIDE_LOADING_BAR',
    'SHOW_HIDE_LOADING_MASK',
    'SET_DEPARTMENT_STATUS_INDICATOR',
    'SET_ADDITIONAL_CONTENT_TOP',
    'SET_GLOBAL_ERROR',
    'TOGGLE_FIELD_NAMES',
    'SET_OVERRIDE_CREATE_REPORT_MODAL_V2',
]);

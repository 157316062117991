import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Briefing } from '~/client-common/core/domain/briefings/state/data';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';

import { useDateTimeFormatter } from '../../core/current-user/hooks/dateTimeFormats';
import { currentUserIdSelector } from '../../core/current-user/state/ui';
import { RECENT_UPDATE_MINUTES } from '../configuration';

const useBriefingLatestUpdate = ({ updatedBy, updatedDateUtc }: Briefing) => {
    const currentUserId = useSelector(currentUserIdSelector);

    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    const dateTimeFormatter = useDateTimeFormatter();

    const updateAuthor = formatMiniUserById(updatedBy);
    const updateTime = dateTimeFormatter.formatTime(updatedDateUtc);

    // useMemo is needed to prevent updates from being considered non-recent just because a re-render happens after some time
    const isRecentlyUpdatedByOtherUser = useMemo(() => {
        const isRecentUpdate =
            moment().diff(moment(updatedDateUtc), 'minutes') < RECENT_UPDATE_MINUTES;
        return isRecentUpdate && currentUserId !== updatedBy;
    }, [currentUserId, updatedBy, updatedDateUtc]);

    return { isRecentlyUpdatedByOtherUser, updateAuthor, updateTime };
};

export default useBriefingLatestUpdate;

import React from 'react';
import { Caution } from '@arc/tag';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';

const strings = componentStrings.evidence.core.HighRiskLabel;

const HighRiskPropertyLabel: React.FC = () => (
    <FeatureFlagged flag="EVIDENCE_AUTO_LOCATION_UPDATE_ENABLED">
        <Caution size="sm" data-test-id={testIds.LABEL} priority={1}>
            {strings.highRisk}
        </Caution>
    </FeatureFlagged>
);

export default HighRiskPropertyLabel;

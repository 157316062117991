import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { reject, map } from 'lodash';
import { useSelector } from 'react-redux';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { prettify } from '~/client-common/helpers/stringHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { LegacySelectOptions, LegacySelectOption } from '../../helpers/selectHelpers';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';

import AttributeSelect from './AttributeSelect';

const { connectRRFInput } = reactReduxFormHelpers;

// Use `filterOptions` to access the options and map over them
const prettifyOption = (option: LegacySelectOption) => ({
    ...option,
    group: prettify(option.group),
});

const ItemCategoryAttributeSelect = ({
    multiple = true,
    includeExpired = true,
    showSelectedGroup = false,
    rejectByCategoryFilter,
    ...props
}: {
    multiple?: boolean;
    includeExpired?: boolean;
    showSelectedGroup?: boolean;
    rejectByCategoryFilter?: typeof globalAttributes['itemType'][keyof typeof globalAttributes['itemType']];
}) => {
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);
    return (
        <AttributeSelect
            {...props}
            multiple={multiple}
            showSelectedGroup={showSelectedGroup}
            grouped={true}
            includeExpired={includeExpired}
            attributeType={AttributeTypeEnum.ITEM_CATEGORY.name}
            filterOptions={(options: LegacySelectOptions<LegacySelectOption>) => {
                const prettifiedOptions = map(options, prettifyOption);
                if (!rejectByCategoryFilter) {
                    return prettifiedOptions;
                }
                // check both parent and grandparent attribute, because some ITEM_CATEGORY attributes can
                // have child ITEM_CATEGORY attributes, see CHD-2850
                return reject(prettifiedOptions, ({ value }) => {
                    const parentAttributeId = parentAttributeIdByAttributeId(value as number);
                    if (parentAttributeId) {
                        const parentParentAttributeId = parentAttributeIdByAttributeId(
                            parentAttributeId
                        );
                        return (
                            parentAttributeId === rejectByCategoryFilter ||
                            parentParentAttributeId === rejectByCategoryFilter
                        );
                    }
                    return parentAttributeId === rejectByCategoryFilter;
                });
            }}
        />
    );
};

// @ts-expect-error client-common to client RND-7529
export const RRFItemCategoryAttributeSelect = connectRRFInput(ItemCategoryAttributeSelect);

import _ from 'lodash';
import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { storeCadServiceRotationProviders } from '~/client-common/core/domain/cad-service-rotation-providers/state/data';
import { replaceLocationEntityLinksForEntityAndLinkTypes } from '~/client-common/core/domain/location-entity-links/state/data';
import { storeCadServiceTypes } from '~/client-common/core/domain/cad-service-rotation-types/state/data';
import { locationByIdSelector } from '~/client-common/core/domain/locations/state/data';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import cadServiceProviderResource from '../../resources/cadServiceProviderResource';
import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';
import { buildLocationEntityLinks } from '../../utils/cadServiceRotationProviderHelpers';

export const LOAD_CAD_SERVICE_PROVIDER_START =
    'cad-service-rotation-providers/LOAD_CAD_SERVICE_PROVIDER_START';
export const LOAD_CAD_SERVICE_PROVIDER_SUCCESS =
    'cad-service-rotation-providers/LOAD_CAD_SERVICE_PROVIDER_SUCCESS';
export const LOAD_CAD_SERVICE_PROVIDER_FAILURE =
    'cad-service-rotation-providers/LOAD_CAD_SERVICE_PROVIDER_FAILURE';
export const SAVE_CAD_SERVICE_PROVIDER_START =
    'cad-service-rotation-providers/SAVE_CAD_SERVICE_PROVIDER_START';
export const SAVE_CAD_SERVICE_PROVIDER_SUCCESS =
    'cad-service-rotation-providers/SAVE_CAD_SERVICE_PROVIDER_SUCCESS';
export const SAVE_CAD_SERVICE_PROVIDER_FAILURE =
    'cad-service-rotation-providers/SAVE_CAD_SERVICE_PROVIDER_FAILURE';
const FETCH_CAD_SERVICE_PROVIDER_HISTORY_START =
    'cad-service-rotation-providers/FETCH_CAD_SERVICE_PROVIDER_HISTORY_START';
export const FETCH_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS =
    'cad-service-rotation-providers/FETCH_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS';
const FETCH_CAD_SERVICE_PROVIDER_HISTORY_FAILURE =
    'cad-service-rotation-providers/FETCH_CAD_SERVICE_PROVIDER_HISTORY_FAILURE';
const FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_START =
    'cad-service-rotation-providers/FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_START';
export const FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS =
    'cad-service-rotation-providers/FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS';
const FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_FAILURE =
    'cad-service-rotation-providers/FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_FAILURE';
export const LOAD_CAD_SERVICE_PROVIDER_TYPE_START =
    'cad-service-rotation-providers/LOAD_CAD_SERVICE_PROVIDER_TYPE_START';
export const LOAD_CAD_SERVICE_PROVIDER_TYPE_SUCCESS =
    'cad-service-rotation-providers/LOAD_CAD_SERVICE_PROVIDER_TYPE_SUCCESS';
export const LOAD_CAD_SERVICE_PROVIDER_TYPE_FAILURE =
    'cad-service-rotation-providers/LOAD_CAD_SERVICE_PROVIDER_TYPE_FAILURE';

function loadCadServiceProvidersStart() {
    return {
        type: LOAD_CAD_SERVICE_PROVIDER_START,
    };
}

function loadCadServiceProvidersSuccess(serviceProviders) {
    return {
        type: LOAD_CAD_SERVICE_PROVIDER_SUCCESS,
        payload: serviceProviders,
    };
}

function loadCadServiceProvidersFailure(errMsg) {
    return {
        type: LOAD_CAD_SERVICE_PROVIDER_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function loadCadServiceProviderTypesStart() {
    return {
        type: LOAD_CAD_SERVICE_PROVIDER_TYPE_START,
    };
}

function loadCadServiceProvidersTypeSuccess(serviceTypes) {
    return {
        type: LOAD_CAD_SERVICE_PROVIDER_TYPE_SUCCESS,
        payload: serviceTypes,
    };
}

function loadCadServiceProvidersTypeFailure(errMsg) {
    return {
        type: LOAD_CAD_SERVICE_PROVIDER_TYPE_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function fetchCadServiceProviderHistoryStart(serviceProviderId) {
    return {
        type: FETCH_CAD_SERVICE_PROVIDER_HISTORY_START,
        payload: serviceProviderId,
    };
}

function fetchCadServiceProviderHistorySuccess(serviceProviderId, histories) {
    return {
        type: FETCH_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS,
        payload: { serviceProviderId, histories },
    };
}

function fetchCadServiceProviderHistoryFailure(serviceProviderId, err) {
    return {
        type: FETCH_CAD_SERVICE_PROVIDER_HISTORY_FAILURE,
        payload: { serviceProviderId, err },
    };
}

function fetchAllCadServiceProvidersHistoryStart() {
    return {
        type: FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_START,
    };
}

function fetchAllCadServiceProvidersHistorySuccess(histories) {
    return {
        type: FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_SUCCESS,
        payload: { histories },
    };
}

function fetchAllCadServiceProvidersHistoryFailure(err) {
    return {
        type: FETCH_ALL_CAD_SERVICE_PROVIDER_HISTORY_FAILURE,
        payload: { err },
    };
}

export function loadCadServiceProviders() {
    return function (dispatch) {
        dispatch(loadCadServiceProvidersStart());

        return cadServiceProviderResource
            .getCadServiceProviders()
            .then((serviceProviders) => {
                dispatch(storeCadServiceRotationProviders(serviceProviders));
                dispatch(loadCadServiceProvidersSuccess(serviceProviders));
            })
            .catch((err) => dispatch(loadCadServiceProvidersFailure(err.message)));
    };
}

export function loadCadServiceTypes() {
    return function (dispatch) {
        dispatch(loadCadServiceProviderTypesStart());

        return cadServiceProviderResource
            .getCadServiceTypes()
            .then((serviceTypes) => {
                dispatch(storeCadServiceTypes(serviceTypes));
                dispatch(loadCadServiceProvidersTypeSuccess(serviceTypes));
            })
            .catch((err) => dispatch(loadCadServiceProvidersTypeFailure(err.message)));
    };
}

export function loadCadServiceProviderHistoryData(serviceProviderId) {
    return function (dispatch) {
        dispatch(fetchCadServiceProviderHistoryStart(serviceProviderId));
        return cadServiceProviderResource
            .getCadServiceProviderHistory(serviceProviderId)
            .then((histories) => {
                dispatch(fetchCadServiceProviderHistorySuccess(serviceProviderId, histories));
            })
            .catch((err) => {
                dispatch(fetchCadServiceProviderHistoryFailure(serviceProviderId, err));
            });
    };
}

export function loadAllCadServiceProvidersHistoryData() {
    return function (dispatch) {
        dispatch(fetchAllCadServiceProvidersHistoryStart());
        return cadServiceProviderResource
            .getAllCadServiceProvidersHistory()
            .then((histories) => {
                dispatch(fetchAllCadServiceProvidersHistorySuccess(histories));
            })
            .catch((err) => {
                dispatch(fetchAllCadServiceProvidersHistoryFailure(err));
            });
    };
}

function saveCadServiceProviderStart() {
    return {
        type: SAVE_CAD_SERVICE_PROVIDER_START,
    };
}

function saveCadServiceProviderSuccess(cadServiceProvider) {
    return {
        type: SAVE_CAD_SERVICE_PROVIDER_SUCCESS,
        payload: cadServiceProvider,
    };
}

function saveCadServiceProviderFailure(errMsg) {
    return {
        type: SAVE_CAD_SERVICE_PROVIDER_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveCadServiceProvider(
    cadServiceProvider,
    paramServiceTypeId,
    availableCapabilities
) {
    return function (dispatch, getState) {
        dispatch(saveCadServiceProviderStart());
        const isNewServiceProvider = !cadServiceProvider.cadServiceProvider.id;
        const method = isNewServiceProvider
            ? 'createCadServiceProvider'
            : 'updateCadServiceProvider';

        const { departmentId } = departmentProfileSelector(getState());
        const {
            ENHANCED_SERVICE_ROTATION: isEnhancedServiceRotationEnabled,
        } = applicationSettingsSelector(getState());
        const proto = Object.assign({}, cadServiceProvider.cadServiceProvider);

        const filteredCapabilities = _.filter(availableCapabilities, (x) => {
            return _.includes(proto.capabilityIds, x.value);
        });
        const capabilities = _.map(filteredCapabilities, (x) => {
            return x.capability;
        });
        proto.capabilities = capabilities;

        if (!isEnhancedServiceRotationEnabled) {
            proto.rotationServiceTypeId = paramServiceTypeId;
        }

        return cadServiceProviderResource[method]({ ...proto, departmentId })
            .then((serviceProvider) => {
                if (!isNewServiceProvider || !serviceProvider.locationId) {
                    dispatch(storeCadServiceRotationProviders([serviceProvider]));
                    return dispatch(saveCadServiceProviderSuccess(serviceProvider));
                }
                // Need to update the LEL with newly created serviceProvider Id
                const location = locationByIdSelector(getState())(serviceProvider.locationId) || {};
                return dispatch(
                    replaceLocationEntityLinksForEntityAndLinkTypes({
                        entityType: EntityTypeEnum.SERVICE_ROTATION_PROVIDER.name,
                        entityId: serviceProvider.id,
                        linkTypes: [LinkTypesEnum.ROTATION_SERVICE_PROVIDER_LOCATION],
                        locationEntityLinks: buildLocationEntityLinks({
                            location,
                            entityId: serviceProvider.id,
                        }),
                    })
                ).then((locationEntityLinks) => {
                    const serviceProviderToStore = {
                        ...serviceProvider,
                        location: {
                            ...serviceProvider.location,
                            entityLinks: locationEntityLinks,
                        },
                    };
                    dispatch(storeCadServiceRotationProviders([serviceProviderToStore]));
                    return dispatch(saveCadServiceProviderSuccess(serviceProviderToStore));
                });
            })
            .catch((err) => dispatch(saveCadServiceProviderFailure(err.message)));
    };
}

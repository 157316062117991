import _ from 'lodash';

export const setKoModelValidation = (field, isValid, errorMessage) => {
    if (field && _.isFunction(field.setError)) {
        field.allowValidation(true);
        if (!isValid) {
            field.setError(errorMessage);
        } else {
            field.clearError();
        }
    }
    return isValid;
};

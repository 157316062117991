import { RefContextEnum } from '@mark43/rms-api';

import { createFieldset, createFormConfiguration, _Form } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

const formConfiguration = createFormConfiguration({
    // UI-only toggle for filtering the dropdown options of offenseCodeId
    statuteCodeSetFilter: {
        fieldName: fields.DISPLAY_ONLY_OFFENSE_CODE_STATUTE_CODE_SET_FILTER,
    },
    offenseIncident: createFieldset({
        fields: {
            id: {},
            reportId: {},
            offenseCodeId: { fieldName: fields.OFFENSE_OFFENSE_CODE_ID },
        },
    }),
});

export const createOffenseIncidentForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;

    return new _Form({
        name: RefContextEnum.FORM_STUB_OFFENSE_INCIDENT.name,
        initialState,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            RefContextEnum.FORM_STUB_OFFENSE_INCIDENT.name,
            formatFieldByName
        ),
        validationEvents: mftArbiterValidationEvents,
        configuration: formConfiguration,
    });
};

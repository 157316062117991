import { identity } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    requiredString,
    requiredInt,
} from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const dispatchAreaAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'name',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'status',
        validators: {
            requiredError: requiredString,
        },
    },
    {
        key: 'agencyTypeGlobalAttrId',
        validators: {
            requiredError: requiredInt,
        },
    },
    {
        key: 'agencyId',
    },
    {
        key: 'radioChannelId',
    },
    {
        key: 'dispatchGroupAttrId',
    },
]);

export default createFormModule({
    formName: formClientEnum.DISPATCH_AREAS_ADMIN,
    fieldViewModels: dispatchAreaAdminFormFieldViewModels,
    convertToFormModel: identity,
    convertFromFormModel: identity,
});

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'ID Generator Resource',
    methods: {
        getIdFormats() {
            return req({
                method: 'GET',
                url: 'id_formats',
            });
        },
        getSequenceKeys() {
            return req({
                method: 'GET',
                url: 'migration/sequences',
            });
        },
        getSequenceByDepartmentSequenceKey(sequenceKey) {
            return req({
                method: 'POST',
                url: 'migration/sequences/key',
                data: sequenceKey,
            });
        },
        upsertIdFormatLink(idFormatLink) {
            return req({
                method: 'POST',
                url: 'id_formats',
                data: [idFormatLink],
            });
        },
        increaseSequence(sequenceKey, increaseBy) {
            return req({
                method: 'PUT',
                url: `migration/sequences?increase_by=${increaseBy}`,
                data: sequenceKey,
            });
        },
    },
});

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { replaceBookingCardBundle } from '~/client-common/core/domain/reports/state/ui';

import createCard from '../../utils/createCard';
import {
    refreshBookingForm,
    buildBookingCardBundle,
    maybePrefillFirstBookingChargeArrestDispositionDate,
} from '../forms/bookingForm';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.BOOKING.name,
    baseSelector: (state) => state.ui.report.bookingCard,
    anchor: 'booking-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit({ index }) {
            return (dispatch) => {
                dispatch(maybePrefillFirstBookingChargeArrestDispositionDate(index));
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        save(form, options = {}) {
            return (dispatch, getState) => {
                form.resetUi();
                const { index } = options;
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, { index });
                const reportId = baseCard.selectors.reportIdSelector(state, { index });

                if (isInSummaryMode) {
                    // repopulate the form with current data to make sure we're validating
                    // against the most recent data
                    dispatch(refreshBookingForm({ reportId, index }));

                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                            options: {
                                index,
                            },
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            options: {
                                index,
                            },
                            getPromisesForSubmission: () => {
                                const bookingCardBundle = dispatch(
                                    buildBookingCardBundle({ reportId, form })
                                );
                                return [
                                    dispatch(
                                        replaceBookingCardBundle({
                                            reportId,
                                            bookingCardBundle,
                                        })
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                dispatch(refreshBookingForm({ reportId, index }));
                            },
                        })
                    );
                }
            };
        },
    },
};

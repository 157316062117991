import React from 'react';
import { pure } from 'recompose';
import { isUnknown } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { BottomPositionedDeceasedLabel } from '../../../../../modules/core/persons/components/DeceasedLabel';

import {
    PersonProfileImage,
    profileImageSizes,
} from '../../../../../modules/core/components/ProfileImage';

function ImageCell({ primaryMugshotPath, className, isDead, firstName, lastName }) {
    return (
        <PersonProfileImage
            className={className}
            isUnknown={isUnknown({ firstName, lastName })}
            url={primaryMugshotPath}
            size={profileImageSizes.IMAGE_CELL}
            noBorder={true}
        >
            {isDead && <BottomPositionedDeceasedLabel />}
        </PersonProfileImage>
    );
}

export default pure(ImageCell);

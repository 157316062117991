import { UnitResource, UnitView } from '@mark43/cad-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'unit';

export default createResource({
    name: 'Cad Unit Resource',
    methods: {
        getCadUnits() {
            return req<UnitResource.GetAllUnits>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}`, // get units rather than unit views, since all we need to show from the list view is call sign and UNIT_TYPE attribute display values
            });
        },
        getDefaultMembers(unitId: string | number) {
            return req<UnitResource.GetDefaultMembersForUnit>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/defaultmembers/${unitId}/`,
            });
        },
        getDefaultUnits(userId: number) {
            return req<UnitResource.GetDefaultMembersByUserIds>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/defaultmembers/users`,
                data: [userId],
            });
        },
        getCadUnit(unitId: string | number) {
            return req<UnitResource.GetCadAdminUnitView>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/admin/${unitId}/`, // UnitView w/default members, their user profiles, unit attributes, and default vehicle
            });
        },
        createCadUnit(cadUnit: UnitView) {
            return req<UnitResource.UpsertUnit>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}`,
                data: cadUnit,
            });
        },
        updateCadUnit(cadUnit: UnitView) {
            return req<UnitResource.UpsertUnit>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}`,
                data: cadUnit,
            });
        },
        getCadUnitHistory(unitId: string | number) {
            return req<UnitResource.GetHistoryById1>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${unitId}/histories`,
            });
        },
        getAllCadUnitsHistory() {
            return req<UnitResource.GetHistoriesForDepartment>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/histories`,
            });
        },

        /**
         * Export all units into a CSV.
         * @return {Promise}
         */
        exportUnits() {
            return req<UnitResource.ExportUnits>({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/export`,
            });
        },

        /**
         * Validate that a file is valid for importing new units.
         * @return {Promise}
         */
        validateUnitImport(fileId: string | number) {
            return req<UnitResource.ValidateUsersCsvFile1>({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/validate/${fileId}/`,
            });
        },
        /**
         * Import units from an uploaded file.
         * @return {Promise}
         */
        importUnits(fileId: string | number) {
            return req<UnitResource.ImportUnitsCsvFile>({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/v2/${fileId}/`,
            });
        },
        importUnitTypeDetails(fileId: string | number) {
            return req<UnitResource.ImportUnitTypeDimensions>({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/unit_type_dimension/import/${fileId}/`,
            });
        },
        exportUnitTypeDetails() {
            return req<UnitResource.ExportUnitTypeDimensions>({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/unit_type_dimension/export/`,
            });
        },
    },
});

import { ChainEventCategoryEnum } from '@mark43/evidence-api';
import componentStrings from '~/client-common/core/strings/componentStrings';

const chainEventTypeCategoryHelpText =
    componentStrings.admin.evidenceChainEventTypes.EvidenceChainEventTypesAdminForm
        .chainEventCategoryHelpText;

export const inheritableChainEventCategories = [
    ChainEventCategoryEnum.CHECKED_IN_MAIN.name,
    ChainEventCategoryEnum.CHECKED_OUT_LAB.name,
    ChainEventCategoryEnum.CHECKED_OUT_PERSON.name,
    ChainEventCategoryEnum.TRANSFERRED.name,
    ChainEventCategoryEnum.RELEASED.name,
    ChainEventCategoryEnum.DISPOSITIONED.name,
    ChainEventCategoryEnum.INFIELD_TRANSFER.name,
];

export const getChainEventCategoryHelpText = (chainEventCategory) => {
    if (!chainEventTypeCategoryHelpText[chainEventCategory]) {
        return '';
    }
    return chainEventTypeCategoryHelpText[chainEventCategory];
};

import { noop, reject } from 'lodash';

import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { objectValuesAreEmpty } from '~/client-common/helpers/objectHelpers';

import createCard from '../../utils/createCard';
import {
    mapOffenseAttributes,
    getCardSavePromises,
    createFormUpdateHandler,
} from '../../utils/offenseIncidentHelpers';
import { submitCard, validateCard } from './cards';
import { refreshIncidentForm } from './incident';

const baseCard = createCard({
    name: reportCardEnum.INCIDENT.name,
    baseSelector: (state) => state.ui.report.incidentCards,
    anchor: 'incident-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit(options = {}) {
            const { index } = options;
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        editAll() {
            return (dispatch) => dispatch(baseCard.actionCreators.transitionAllCardsToEditMode());
        },
        save(form, options) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const cardIndex = { index: options.index };
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, cardIndex);
                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                            options: cardIndex,
                        })
                    );
                } else {
                    let createOrUpsertPromise;
                    let allPromises;
                    let incident;

                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const reduxState = getState();
                                const { model } = form.getState();
                                const { offenseAttributes, links } = model;
                                incident = {
                                    ...model.incident,
                                    offenseOrder: null,
                                };

                                const attributes = mapOffenseAttributes({
                                    attributeIsOther: attributeIsOtherSelector(reduxState),
                                    offense: incident,
                                    offenseAttributes,
                                });
                                ({ createOrUpsertPromise, allPromises } = getCardSavePromises({
                                    dispatch,
                                    getState,
                                })({
                                    offense: incident,
                                    attributes,
                                    links: [
                                        // saving all names here as well
                                        // even though they don't have any custom data associated with them.
                                        // this will be a non-issue once we build out an offense-card-bundle endpoint.
                                        ...links.subjects,
                                        ...links.witnesses,
                                        ...links.otherNames,
                                    ],
                                    // We only support one location for now,
                                    // but building out array support
                                    locationEntityLinks: reject(
                                        [links.location],
                                        objectValuesAreEmpty
                                    ),
                                }));
                                return allPromises;
                            },
                            options: {
                                ...cardIndex,
                                customEventType: options.customEventType,
                                changeToSummaryModeAfterSubmission:
                                    options.changeToSummaryModeAfterSubmission,
                            },
                        })
                    ).finally(() => {
                        // We chain onto the submission promise because all the actions within
                        // the submission need to happen before we can finally update our form state
                        // for newly created offenses. We need to always have this happen as long
                        // as upserting completes because it will replace the original offense
                        return (
                            createOrUpsertPromise
                                .then(
                                    createFormUpdateHandler({
                                        dispatch,
                                        deriveNewFormState: (incident) =>
                                            refreshIncidentForm({
                                                incidentId: incident.id,
                                            }),
                                        form,
                                        cardModule: baseCard,
                                        offense: incident,
                                    })
                                )
                                // we noop here because this error will already have been caught within `submitCard`
                                .catch(noop)
                        );
                    });
                }
            };
        },
    },
};

import { isNil, some } from 'lodash';
import { dateDifferenceInYears } from '~/client-common/core/dates/utils/dateRangeHelpers';

export function isJuvenileFromAgeRange({ ageMin, ageMax, ageOfAdult }) {
    if (ageMin > ageMax) {
        return;
    }

    if (ageMax < ageOfAdult) {
        return true;
    }

    if (ageMin >= ageOfAdult) {
        return false;
    }
}

export function isJuvenileFromPersonProfile(personProfile, ageOfAdult) {
    if (!personProfile || isNil(ageOfAdult)) {
        return;
    }

    const { dateOfBirth, isDobUnknown } = personProfile;
    const ageMin = Number(personProfile.ageMin);
    const ageMax = Number(personProfile.ageMax);

    // form inputs are stored as strings, and empty values are ''
    const anyAgeRangeNil = some([ageMin, ageMax], (val) => isNaN(val) || isNil(val) || val === '');

    if (isDobUnknown && !anyAgeRangeNil) {
        return isJuvenileFromAgeRange({ ageMin, ageMax, ageOfAdult });
    } else if (!isDobUnknown && dateOfBirth) {
        // have to check isDobUnknown because dateOfBirth input is disabled but value persists
        return dateDifferenceInYears(dateOfBirth, new Date()) < ageOfAdult;
    }
}

// Shim for isJuvenile because Person Profiles were not back-filled.
// For user convenience when there is no isJuvenile value when editing master person profile
// or using search-to-add person.
export const shimIsJuvenilePersonProfile = (personProfile, ageOfAdult) => {
    return {
        ...personProfile,
        isJuvenile: isJuvenileFromPersonProfile(personProfile, ageOfAdult),
    };
};

import { get, size } from 'lodash';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { sortReportShortTitleViewModels } from '~/client-common/core/domain/report-short-titles/utils/reportShortTitlesHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import Card from '../../../../legacy-redux/components/core/Card';
import _LinkedCases from '../../../records/core/components/sidebar/LinkedCases';
import _LinkedReportLink from '../../../records/core/components/sidebar/LinkedReportLink';
import CollapsibleList from '../../../core/components/CollapsibleList';
import testIds from '../../../../core/testIds';

import {
    currentReportCardUITitleByTypeSelector,
    currentReportSelector,
    linkedCaseTitleViewModelsSelector,
    linkedReportShortTitleViewModelsSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';

const strings = componentStrings.reports.custodialPropertySummary.LinkedReportsCard;

const LinkedReportLink = styled(_LinkedReportLink)`
    display: flex;
    margin-bottom: 20px;
    padding-left: 10px;
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-md);

    &:last-child {
        margin-bottom: 0;
    }
`;

const LinkedCases = styled(_LinkedCases)`
    display: block;
    margin-bottom: ${(props) => (props.lastChild ? '0px' : '20px')};
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-md);
    padding-left: 0;
`;

const ReportSummary = styled.span`
    display: inline-block;
    vertical-align: middle;
`;

function LinkedReportsCard({
    currentReport = {},
    formatFieldByName,
    linkedCaseTitleViewModels,
    linkedReportShortTitleViewModels,
    className,
}) {
    const currentReportCardUITitleByType = useSelector(currentReportCardUITitleByTypeSelector);
    const sortedLinkedReportShortTitleViewModels = sortReportShortTitleViewModels(
        linkedReportShortTitleViewModels
    );

    const cardTitle = currentReportCardUITitleByType(reportCardEnum.LINKED_REPORTS.id);

    const items = [...linkedCaseTitleViewModels, ...sortedLinkedReportShortTitleViewModels];
    const isLastChild = size(items) === 1;
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    const reportRen = get(currentReport, 'reportingEventNumber');
    return (
        <Card
            title={`${renDisplayName} ${reportRen || ''} ${cardTitle}`}
            className={className}
            testId={testIds.LINKED_REPORTS_SECTION}
        >
            <CollapsibleList
                items={items}
                noItemsText={strings.labels.noRelatedReports}
                renderItem={(item) => {
                    if (item.caseId) {
                        return (
                            <LinkedCases
                                caseTitleViewModels={linkedCaseTitleViewModels}
                                lastChild={isLastChild}
                            />
                        );
                    }

                    const {
                        approvalStatus,
                        isSealed,
                        isPartiallySealed,
                        isVacated,
                        reportId,
                        secondaryApprovalStatus,
                        clientApprovalStatus,
                    } = item;

                    const { shortTitleWithRen } = getViewModelProperties(item);

                    return (
                        <LinkedReportLink
                            key={reportId}
                            reportId={reportId}
                            approvalStatus={approvalStatus}
                            secondaryApprovalStatus={secondaryApprovalStatus}
                            clientApprovalStatus={clientApprovalStatus}
                            reportSealingInfo={{ isSealed, isPartiallySealed, isVacated }}
                            reportSummary={<ReportSummary>{shortTitleWithRen}</ReportSummary>}
                        />
                    );
                }}
            />
        </Card>
    );
}

const mapStateToProps = createStructuredSelector({
    currentReport: currentReportSelector,
    formatFieldByName: formatFieldByNameSelector,
    linkedCaseTitleViewModels: linkedCaseTitleViewModelsSelector,
    linkedReportShortTitleViewModels: linkedReportShortTitleViewModelsSelector,
});

export default connect(mapStateToProps)(LinkedReportsCard);

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/storage_location';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Storage Resource',
        methods: {
            /**
             * Create a storage location.
             * @param  {Object} storageLocationViewModel
             * @return {Promise<Object>}
             */
            createStorageLocation(storageLocation) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: BASE_PATH,
                    data: storageLocation,
                });
            },

            /**
             * Get all the first level storage location children of a facility
             * @param  {number} facilityId
             * @return {Promise<Object>}
             */
            getStorageLocationsInFacility(facilityId) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/facility/${facilityId}/children`,
                });
            },

            /**
             * Get the ids of all the children within a given facility.
             * @param {number} facilityId
             * @param {boolean} includeExpired
             * @param {boolean} includeDeleted
             * @return {Promise<Object>}
             */
            getAllStorageLocationsInFacility(facilityId, includeExpired, includeDeleted) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/facility/${facilityId}/all_children/ids?includeExpired=${includeExpired}&includeDeleted=${includeDeleted}`,
                });
            },

            /**
             * Retrieves all the immediate children of a storage location
             * @param  {number} storageLocationId
             * @return {Promise<Object>}
             */
            getStorageLocationChildren(storageLocationId) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/${storageLocationId}/children`,
                });
            },

            /**
             * Get the ids of all the children within a given storage location.
             * @param {number} storageLocationId
             * @param {boolean} includeExpired
             * @param {boolean} includeDeleted
             * @return {Promise<Object>}
             */
            getAllStorageLocationChildrenIds(storageLocationId, includeExpired, includeDeleted) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/${storageLocationId}/all_children/ids?include_expired=${includeExpired}&include_deleted=${includeDeleted}`,
                });
            },

            /**
             * Get a single storage location.
             * @param  {number} storageLocationId
             * @return {Promise<Object>}
             */
            getStorageLocation(storageLocationId) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/${storageLocationId}`,
                });
            },

            /**
             * Update a storage location
             * @param  {number} storageLocationId
             * @param  {Object} storageLocation
             * @return {Promise<Object>}
             */
            updateStorageLocation(storageLocation) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'PUT',
                    url: `${BASE_PATH}/${storageLocation.id}`,
                    data: storageLocation,
                });
            },

            /**
             * Delete a storage location all children
             * @param {number} storageLocationId
             * @return {Promise<boolean>}
             */
            deleteStorageLocation(storageLocationId) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'DELETE',
                    url: `${BASE_PATH}/${storageLocationId}`,
                });
            },
            validateStorageLocationsBulkImport(bulkImportRequest) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: `${BASE_PATH}/facility/import/validate`,
                    data: bulkImportRequest,
                });
            },
            bulkImportStorageLocations(bulkImportRequest) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: `${BASE_PATH}/facility/import`,
                    data: bulkImportRequest,
                });
            },

            /**
             * Export all storage locations in the given facility into a CSV.
             * @return {Promise}
             */
            exportStorageLocations(facilityId) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/facility/${facilityId}/export`,
                });
            },
            moveStorageLocation(sourceLocationId, destinationLocationId) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: `${BASE_PATH}/move`,
                    data: {
                        sourceLocationId,
                        destinationLocationId,
                    },
                });
            },
        },
    });
}

/**
 * Resources for evidence storage locations. See the tech
 *   doc for details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see  https://docs.google.com/document/d/1v0IgMFaAoRL-lMWrRGYrgqScD79QN2whQ0U0WsMjLpQ/
 */
const resourceGetter = () => resource;
export default resourceGetter;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Warrant Activity Resource',
        methods: {
            createWarrantActivity(warrantActivity) {
                return req({
                    method: 'POST',
                    url: `warrant_activities`,
                    data: warrantActivity,
                });
            },
            updateWarrantActivity(warrantActivity) {
                return req({
                    method: 'PUT',
                    url: `warrant_activities/${warrantActivity.id}`,
                    data: warrantActivity,
                });
            },
            deleteWarrantActivity(id) {
                return req({
                    method: 'DELETE',
                    url: `warrant_activities/${id}`,
                });
            },
        },
    });
}

/**
 * Resources for Warrant Activities. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { map } from 'lodash';
import { InvolvementStatusEnum } from '@mark43/rms-api';
import { prettify } from '~/client-common/helpers/stringHelpers';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

type PersonnelInvolvementStatusSelectProps = {
    label: string;
    path: string;
    onChange: () => void;
};

const PersonnelInvolvementStatusSelect = (props: PersonnelInvolvementStatusSelectProps) => {
    const options = map(InvolvementStatusEnum, (status) => ({
        display: prettify(status.name),
        value: status.name,
    }));
    return <Select {...props} options={options} />;
};

export const RRFPersonnelInvolvementStatusSelect = connectRRFInput(
    // @ts-expect-error client-common to client migration
    PersonnelInvolvementStatusSelect
);

import { get } from 'lodash';

import { NEXUS_STATE_PROP as ELASTIC_PERSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-persons/state/data/';
import { NEXUS_STATE_PROP as ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-organizations/state/data/';
import { NEXUS_STATE_PROP as ELASTIC_VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-vehicles/state/data/';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';

const SEARCH_TO_ADD_SEARCH_START = 'search-to-add/SEARCH_START';
const SEARCH_TO_ADD_SEARCH_SUCCESS = 'search-to-add/SEARCH_SUCCESS';
const SEARCH_TO_ADD_SEARCH_FAILURE = 'search-to-add/SEARCH_FAILURE';

function searchToAddSearchStart(elasticQuery, type) {
    return {
        type: SEARCH_TO_ADD_SEARCH_START,
        payload: { elasticQuery, type },
    };
}

function searchToAddSearchFailure(message) {
    return {
        type: SEARCH_TO_ADD_SEARCH_FAILURE,
        payload: message,
    };
}

function searchToAddSearchSuccess(results) {
    return {
        type: SEARCH_TO_ADD_SEARCH_SUCCESS,
        payload: results,
    };
}

const propertiesForSearchType = {
    person: {
        resourceMethod: 'searchPersons',
        nexusStateProp: ELASTIC_PERSONS_NEXUS_STATE_PROP,
    },
    organization: {
        resourceMethod: 'searchOrganizations',
        nexusStateProp: ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP,
    },
    vehicle: {
        resourceMethod: 'searchVehicles',
        nexusStateProp: ELASTIC_VEHICLES_NEXUS_STATE_PROP,
    },
};

export function searchToAddSearch({ from, size, elasticQuery, type }) {
    const { resourceMethod, nexusStateProp } = propertiesForSearchType[type] || {};
    const noResourceMethodMessage = `No resource method exists for type '${type}'`;
    return (dispatch, getState, dependencies) => {
        if (!resourceMethod) {
            dispatch(searchToAddSearchFailure(noResourceMethodMessage));
            return Promise.reject(new Error(noResourceMethodMessage));
        } else {
            dispatch(searchToAddSearchStart(elasticQuery, type));
            return elasticSearchResource[resourceMethod](elasticQuery, from, size)
                .then((result) => {
                    if (result) {
                        dispatch(
                            dependencies.nexus.withEntityItems(
                                {
                                    [nexusStateProp]: get(result, 'items'),
                                },
                                searchToAddSearchSuccess(result)
                            )
                        );
                    }
                    return result;
                })
                .catch((err) => {
                    dispatch(searchToAddSearchFailure(err.message));
                    throw err;
                });
        }
    };
}

import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Button, IconButton } from 'arc';
import SignaturePad from 'react-signature-pad-wrapper';
import { Mark43File } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { OverlayIdEnumType } from '~/client-common/core/enums/universal/overlayIdEnum';
import { useOverlayStore } from '../../../../../../core/overlays/hooks/useOverlayStore';
import { useFileUploader } from '../../../../../../attachments/files/hooks/useFileUploader';
import { dataUriToBlob } from '../../../../../../core/signature-pad/signaturePadHelpers';
import errorToMessage from '../../../../../../core/errors/utils/errorToMessage';
import { logError } from '../../../../../../../core/logging';
import Modal from '../../../../../../core/overlays/components/Modal';

const ModalContentWrapper = styled.div`
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const SignaturePadWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.lightBlue};
`;

const SignHereTextBlock = styled.div`
    display: flex;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 5px 0;
    justify-content: center;
    font-size: var(--arc-fontSizes-md);
    width: 85%;
    margin: 0 auto;
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

type CustomSignatureModalPropsT = {
    identifier: string;
    overlayId: OverlayIdEnumType;
    onSave: (file: Mark43File) => void;
};

const signatureCaptureModalStrings = componentStrings.dragon.signature.SignatureModal;

export type CustomSignatureModalCustomPropsT = { onSave: (file: Mark43File) => void };

const getSignatureFileName = (identifier: string) => {
    return `signature-${identifier}.png`;
};

export const CustomSignatureModal = ({
    overlayId,
    identifier,
    onSave,
}: CustomSignatureModalPropsT): JSX.Element | null => {
    const signaturePadRef = useRef<SignaturePad>(null);
    const overlayStore = useOverlayStore();
    const onUploadStart = useCallback(() => {
        overlayStore.setLoading(overlayId, true);
    }, [overlayId, overlayStore]);

    const onUploadComplete = useCallback(() => {
        overlayStore.close(overlayId);
    }, [overlayId, overlayStore]);

    const { callResource: saveSignatureImage } = useFileUploader({
        onSave,
        onUploadStart,
        onUploadComplete,
    });

    const handleOnSave = useCallback(async () => {
        if (!signaturePadRef?.current) {
            return;
        }

        try {
            const signaturePadCanvas = signaturePadRef.current;
            if (signaturePadCanvas.isEmpty()) {
                throw new Error(signatureCaptureModalStrings.errors.missingSignature);
            }
            const signatureBlob = dataUriToBlob(signaturePadCanvas.toDataURL('image/png'));

            const formData = new window.FormData();
            formData.append('my-file', signatureBlob, getSignatureFileName(identifier));
            await saveSignatureImage(formData);
        } catch (err) {
            logError(
                errorToMessage(err, signatureCaptureModalStrings.errors.unhandledUploadException)
            );
            throw err;
        }
    }, [saveSignatureImage, identifier]);

    const onClearSignature = useCallback(() => {
        if (signaturePadRef && signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
            signaturePadRef.current.clear();
        }
    }, [signaturePadRef]);

    const onUndoClick = useCallback(() => {
        if (signaturePadRef && signaturePadRef.current && !signaturePadRef.current.isEmpty()) {
            const signaturePadData = signaturePadRef.current.toData();
            signaturePadData.pop();
            signaturePadRef.current.fromData(signaturePadData);
        }
    }, [signaturePadRef]);

    return (
        <Modal
            contentStyle={{ minHeight: 450 }}
            id={overlayId}
            okText={signatureCaptureModalStrings.okText}
            onSave={handleOnSave}
            cancelText={signatureCaptureModalStrings.cancelText}
            title={signatureCaptureModalStrings.title}
            autoClose={false}
            closeOnSave={false}
        >
            <ModalContentWrapper>
                <SignaturePadWrapper>
                    <SignaturePad ref={signaturePadRef} />
                    <SignHereTextBlock>{signatureCaptureModalStrings.disclaimer}</SignHereTextBlock>
                </SignaturePadWrapper>
                <ActionsContainer>
                    <Button leadingVisual="CloseX" variant="ghost" onClick={onClearSignature}>
                        {signatureCaptureModalStrings.clearButtonText}
                    </Button>
                    <IconButton
                        variant="outline"
                        aria-label="Undo"
                        icon="Undo"
                        onClick={onUndoClick}
                    />
                </ActionsContainer>
            </ModalContentWrapper>
        </Modal>
    );
};

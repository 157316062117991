import { FieldNoteEntityLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'fieldNoteEntityLinks';

const fieldNoteEntityLinksModule = createLinkModule<FieldNoteEntityLink>({
    type: NEXUS_STATE_PROP,
    keys: ['fieldNoteId', 'entityType', 'entityId', 'linkType'],
});

export const fieldNoteEntityLinksWhereSelector =
    fieldNoteEntityLinksModule.selectors.linksWhereSelector;

export default fieldNoteEntityLinksModule.reducerConfig;

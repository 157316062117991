import React from 'react';
import { mapLinkTypesToOptions } from '~/client-common/helpers/linkTypesHelpers';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import LinkTypeSelect from './LinkTypeSelect';

const { connectRRFInput } = reactReduxFormHelpers;

const AdvancedSearchLinkTypeSelect = (props) => (
    <LinkTypeSelect {...props} mapLinkTypesToOptions={mapLinkTypesToOptions} />
);

export default AdvancedSearchLinkTypeSelect;

export const RRFAdvancedSearchLinkTypeSelect = connectRRFInput(AdvancedSearchLinkTypeSelect);

import {
    Caution,
    EntityTypeEnum,
    HydratedImage,
    LegacyEntityDetail,
    NameEmail,
    NameMoniker,
    NamePhoneNumber,
    Passport,
    PersonEmergencyContact,
    PersonGangTracking,
    PersonProbation,
    PersonProfile,
    SchoolHistory,
} from '@mark43/rms-api';
import { createSelector } from 'reselect';

import { compact, filter, map, mapValues, reject, sortBy } from 'lodash';
import { personProfilesSelector, personProfilesWhereSelector } from '../data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { attributeAbbrevDisplayByIdSelector } from '../../../attributes/state/ui';
import {
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
    buildViewModel,
    ViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import {
    formatDobAgeRange,
    formatFullName,
    formatHeightFull,
    formatLastNameFirst,
    formatPlaceOfBirth,
    formatRaceEthnicity,
    formatShortName,
    formatWeightFull,
} from '../../utils/personProfilesHelpers';
import { dateOfBirthToAge } from '../../../../dates/utils/dateHelpers';
import { formatAttributeWithOther } from '../../../attributes/utils/attributesHelpers';
import { namePhonesByNameIdSelector } from '../../../name-phones/state/data';
import { nameEmailsByNameIdSelector } from '../../../name-emails/state/data';
import {
    NameIdentifierViewModel,
    nameIdentifierViewModelsByNameIdSelector,
} from '../../../name-identifiers/state/ui';
import { nameMonikersByNameIdSelector } from '../../../name-monikers/state/data';
import { schoolHistoriesByPersonProfileIdSelector } from '../../../school-histories/state/data';
import {
    EmploymentHistoryViewModel,
    employmentHistoryViewModelsByPersonProfileIdSelector,
} from '../../../employment-histories/state/ui';
import {
    IdentifyingMarkViewModel,
    identifyingMarkViewModelsByPersonProfileIdSelector,
} from '../../../identifying-marks/state/ui';
import {
    NameAttributeViewModel,
    nameAttributeViewModelsWhereSelector,
} from '../../../name-attributes/state/ui';
import { mugshotAttachmentsForPersonIdSelector } from '../../../attachments/state/ui';
import { legacyEntityDetailsWhereSelector } from '../../../legacy-entity-details/state/data';
import { personEmergencyContactsByPersonProfileIdSelector } from '../../../person-emergency-contacts/state/data';
import { passportsByPersonProfileIdSelector } from '../../../passports/state/data';
import { personProbationsByPersonProfileIdSelector } from '../../../person-probations/state/data';
import { personGangTrackingForPersonIdSelector } from '../../../person-gang-trackings/state/data';
import { cautionsByEntitySelector } from '../../../cautions/state/data';

type PersonProfileViewModelProperties = {
    age: number | string;
    birthStateAttrId: string;
    buildAttrId: string;
    cautions: Caution[];
    citizenshipAttrId: string;
    dlEndorsementAttrId: string;
    dlState: string;
    dlStateAttrId: string;
    dlStatusAttrId: string;
    dlType: string;
    dlTypeAttrId: string;
    dobAgeRange: string;
    emails: NameEmail[];
    employmentHistories: EmploymentHistoryViewModel[];
    ethnicityAttrId: string;
    eyeColorAttrId: string;
    facialHairTypeAttrId: string;
    fullName: string;
    hairColorAttrId: string;
    hairLengthAttrId: string;
    hairStyleAttrId: string;
    height: string;
    identifiers: NameIdentifierViewModel[];
    identifyingMarks: IdentifyingMarkViewModel[];
    isDead: string;
    isExpunged: string;
    isJuvenile: string;
    isMaster: boolean;
    isNonDisclosureRequest: string;
    isPregnantAttrId: string;
    isResidentOfJurisdictionAttrId: string;
    lastNameFirst: string;
    legacyEntityDetails: LegacyEntityDetail[];
    maritalStatusAttrId: string;
    medicalFacilityAttrId: string;
    medicalTransportationTypeAttrId: string;
    medicalTreatmentAttrId: string;
    militaryHistories: EmploymentHistoryViewModel[];
    monikers: NameMoniker[];
    mostRecentMugshotImage: HydratedImage;
    mugshotImages: HydratedImage[];
    nameAttributes: NameAttributeViewModel[];
    needsInterpreter: string;
    passports: Passport[];
    personEmergencyContacts: PersonEmergencyContact[];
    personGangTracking?: PersonGangTracking;
    personProbations: PersonProbation[];
    phoneNumbers: NamePhoneNumber[];
    placeOfBirth: string;
    priorHistoryOfDomesticViolence: string;
    raceAttrId: string;
    raceEthnicity: string;
    religionAttrId: string;
    schoolHistories: SchoolHistory[];
    sexAttrId: string;
    sexualOrientationAttrId: string;
    shortName: string;
    skinToneAttrId: string;
    verificationOfDeathAttrId: string;
    visionAttrId: string;
    weight: string;
};

export type PersonProfileViewModel = ViewModel<PersonProfile, PersonProfileViewModelProperties>;

function buildPersonProfileViewModel({
    profile,
    ...mappers
}: {
    profile: PersonProfile;
    formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>;
    getAttributeAbbrevDisplayById: ReturnType<typeof attributeAbbrevDisplayByIdSelector>;
    namePhonesByNameId: ReturnType<typeof namePhonesByNameIdSelector>;
    nameEmailsByNameId: ReturnType<typeof nameEmailsByNameIdSelector>;
    nameIdentifierViewModelsByNameId: ReturnType<typeof nameIdentifierViewModelsByNameIdSelector>;
    nameMonikersByNameId: ReturnType<typeof nameMonikersByNameIdSelector>;
    schoolHistoriesByPersonProfileId: ReturnType<typeof schoolHistoriesByPersonProfileIdSelector>;
    employmentHistoryViewModelsByPersonProfileId: ReturnType<
        typeof employmentHistoryViewModelsByPersonProfileIdSelector
    >;
    identifyingMarkViewModelsByPersonProfileId: ReturnType<
        typeof identifyingMarkViewModelsByPersonProfileIdSelector
    >;
    nameAttributeViewModelsWhere: ReturnType<typeof nameAttributeViewModelsWhereSelector>;
    personEmergencyContactsByPersonProfileId: ReturnType<
        typeof personEmergencyContactsByPersonProfileIdSelector
    >;
    passportsByPersonProfileId: ReturnType<typeof passportsByPersonProfileIdSelector>;
    mugshotAttachmentsForPersonId: ReturnType<typeof mugshotAttachmentsForPersonIdSelector>;
    legacyEntityDetailsWhere: ReturnType<typeof legacyEntityDetailsWhereSelector>;
    personGangTrackingForPersonId: ReturnType<typeof personGangTrackingForPersonIdSelector>;
    personProbationsByPersonProfileId: ReturnType<typeof personProbationsByPersonProfileIdSelector>;
    cautionsByEntity: ReturnType<typeof cautionsByEntitySelector>;
}): PersonProfileViewModel {
    const {
        formatAttributeById,
        getAttributeAbbrevDisplayById,
        namePhonesByNameId,
        nameEmailsByNameId,
        nameIdentifierViewModelsByNameId,
        nameMonikersByNameId,
        schoolHistoriesByPersonProfileId,
        employmentHistoryViewModelsByPersonProfileId,
        identifyingMarkViewModelsByPersonProfileId,
        nameAttributeViewModelsWhere,
        personEmergencyContactsByPersonProfileId,
        passportsByPersonProfileId,
        mugshotAttachmentsForPersonId,
        legacyEntityDetailsWhere,
        personGangTrackingForPersonId,
        personProbationsByPersonProfileId,
        cautionsByEntity,
    } = mappers;

    const makeViewModel = buildViewModel<PersonProfile, PersonProfileViewModelProperties>({
        recursive: true,
        mappers: [
            allSingleAttributeValuesMapper,
            boolToDisplayMapper,
            ({ masterPersonId, id }) => ({
                isMaster: masterPersonId === id,
            }),
            ({ title = '', firstName = '', middleName = '', lastName = '', suffix = '' }) => ({
                fullName: formatFullName({
                    title,
                    firstName,
                    middleName,
                    lastName,
                    suffix,
                }),
                shortName: formatShortName({ firstName, lastName }),
                lastNameFirst: formatLastNameFirst({
                    firstName,
                    middleName,
                    lastName,
                    suffix,
                }),
            }),
            ({ raceAttrId, ethnicityAttrId }) => ({
                raceEthnicity: formatRaceEthnicity({
                    raceAttrId: formatAttributeById(raceAttrId),
                    ethnicityAttrId: formatAttributeById(ethnicityAttrId),
                }),
            }),
            ({ height, heightRangeMin, heightRangeMax }) => ({
                height: formatHeightFull({ height, heightRangeMin, heightRangeMax }),
            }),
            ({ weight, weightRangeMin, weightRangeMax }) => ({
                weight: formatWeightFull({ weight, weightRangeMin, weightRangeMax }),
            }),
            ({ dateOfBirth, dateOfBirthRangeStart, dateOfBirthRangeEnd }) => ({
                dobAgeRange: formatDobAgeRange({
                    dateOfBirth,
                    dateOfBirthRangeStart,
                    dateOfBirthRangeEnd,
                }),
                age: dateOfBirth ? dateOfBirthToAge(dateOfBirth) : '',
            }),
            ({ dlTypeAttrId, dlTypeOther, dlStateAttrId }) => ({
                dlType: formatAttributeWithOther(formatAttributeById(dlTypeAttrId), dlTypeOther),
                dlState: joinTruthyValues(
                    [
                        dlStateAttrId && getAttributeAbbrevDisplayById(dlStateAttrId),
                        formatAttributeById(dlStateAttrId),
                    ],
                    ' - '
                ),
            }),
            ({ birthStateAttrId, placeOfBirth }) => ({
                placeOfBirth: formatPlaceOfBirth(
                    placeOfBirth,
                    birthStateAttrId && getAttributeAbbrevDisplayById(birthStateAttrId),
                    formatAttributeById(birthStateAttrId)
                ),
            }),
            ({ id }) => ({
                // TODO should we sort these? and if so, by what?
                phoneNumbers: namePhonesByNameId(id),
                emails: nameEmailsByNameId(id),
                identifiers: nameIdentifierViewModelsByNameId(id),
                monikers: nameMonikersByNameId(id),
                schoolHistories: sortBy(schoolHistoriesByPersonProfileId(id), (school) => [
                    school.schoolName,
                    school.schoolAddress,
                    school.grade,
                    school.status,
                    school.displayNumber,
                    school.id,
                ]),
                employmentHistories: reject(
                    employmentHistoryViewModelsByPersonProfileId(id),
                    'militaryBranchAttrId'
                ),
                militaryHistories: filter(
                    employmentHistoryViewModelsByPersonProfileId(id),
                    'militaryBranchAttrId'
                ),
                identifyingMarks: identifyingMarkViewModelsByPersonProfileId(id),
                nameAttributes: nameAttributeViewModelsWhere({ nameId: id }),
                personEmergencyContacts: personEmergencyContactsByPersonProfileId(id),
                passports: passportsByPersonProfileId(id),
                cautions: cautionsByEntity(EntityTypeEnum.PERSON_PROFILE.name, id),
            }),
            ({ id }) => {
                const mugshotImages = compact(map(mugshotAttachmentsForPersonId(id), 'image'));
                return {
                    mugshotImages,
                    mostRecentMugshotImage: mugshotImages[0],
                };
            },
            ({ id }) => ({
                legacyEntityDetails: legacyEntityDetailsWhere({
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    entityId: id,
                }),
            }),
            ({ id }) => ({
                personGangTracking: personGangTrackingForPersonId(id),
            }),
            ({ id }) => ({
                personProbations: personProbationsByPersonProfileId(id),
            }),
        ],
        helpers: {
            formatAttributeById,
            getAttributeAbbrevDisplayById,
            formatAttributeWithOther,
        },
    });

    return makeViewModel(profile);
}

const personProfileViewModelsSelector = createSelector(
    personProfilesSelector,
    formatAttributeByIdSelector,
    attributeAbbrevDisplayByIdSelector,
    namePhonesByNameIdSelector,
    nameEmailsByNameIdSelector,
    nameIdentifierViewModelsByNameIdSelector,
    nameMonikersByNameIdSelector,
    schoolHistoriesByPersonProfileIdSelector,
    personEmergencyContactsByPersonProfileIdSelector,
    passportsByPersonProfileIdSelector,
    personProbationsByPersonProfileIdSelector,
    employmentHistoryViewModelsByPersonProfileIdSelector,
    identifyingMarkViewModelsByPersonProfileIdSelector,
    nameAttributeViewModelsWhereSelector,
    legacyEntityDetailsWhereSelector,
    personGangTrackingForPersonIdSelector,
    mugshotAttachmentsForPersonIdSelector,
    cautionsByEntitySelector,
    (
        personProfiles,
        formatAttributeById,
        getAttributeAbbrevDisplayById,
        namePhonesByNameId,
        nameEmailsByNameId,
        nameIdentifierViewModelsByNameId,
        nameMonikersByNameId,
        schoolHistoriesByPersonProfileId,
        personEmergencyContactsByPersonProfileId,
        passportsByPersonProfileId,
        personProbationsByPersonProfileId,
        employmentHistoryViewModelsByPersonProfileId,
        identifyingMarkViewModelsByPersonProfileId,
        nameAttributeViewModelsWhere,
        legacyEntityDetailsWhere,
        personGangTrackingForPersonId,
        mugshotAttachmentsForPersonId,
        cautionsByEntity
    ) => {
        return mapValues(personProfiles, (profile) =>
            buildPersonProfileViewModel({
                profile,
                formatAttributeById,
                getAttributeAbbrevDisplayById,
                namePhonesByNameId,
                nameEmailsByNameId,
                nameIdentifierViewModelsByNameId,
                nameMonikersByNameId,
                schoolHistoriesByPersonProfileId,
                employmentHistoryViewModelsByPersonProfileId,
                identifyingMarkViewModelsByPersonProfileId,
                nameAttributeViewModelsWhere,
                personEmergencyContactsByPersonProfileId,
                passportsByPersonProfileId,
                mugshotAttachmentsForPersonId,
                legacyEntityDetailsWhere,
                personGangTrackingForPersonId,
                personProbationsByPersonProfileId,
                cautionsByEntity,
            })
        );
    }
);

export const personProfileViewModelByIdSelector = createSelector(
    personProfileViewModelsSelector,
    (personProfileViewModels) => (id: string | number): PersonProfileViewModel =>
        personProfileViewModels[id]
);

export const personProfileViewModelsInReportSelector = createSelector(
    personProfilesWhereSelector,
    personProfileViewModelByIdSelector,
    (personProfilesWhere, personProfileViewModelById) => (reportId: number) => {
        return map(
            personProfilesWhere({ ownerId: reportId, ownerType: EntityTypeEnum.REPORT.name }),
            (person) => personProfileViewModelById(person.id)
        );
    }
);

import React from 'react';
import { useToast } from 'arc';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { flowRight, isFunction, isEmpty } from 'lodash';

import { EntityTypeEnum } from '@mark43/rms-api';
import { components } from '~/client-common/core/strings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';

import { mapGivenPacketsToPrintables } from '~/client-common/helpers/exportHelpers';
import { toggleExportsState } from '../../../routing/legacyViewNavigationHelpers/reportNavigationStateTogglers';
import { exportSelector } from '../../selectors/exportsSelectors';
import { exportPDF, setRedactionWorkOrderFromExportsFormData } from '../../actions/exportsActions';
import { SimpleLoading } from '../core/Loading';
import { isInvolvedPersonSealedForReportIdSelector } from '../../../modules/record-privacy/sealing/state/ui';
import { currentUserHasAbilitySelector } from '../../../modules/core/current-user/state/ui';
import { emailPrintables } from '../../../modules/core/emails/state/ui';
import { openBox } from '../../actions/boxActions';
import FormExportsOptions from './FormExportsOptions';

// configs
const strings = components.exports.FormExports;

class FormExports extends React.Component {
    computeSelectedPackets() {
        return mapGivenPacketsToPrintables(
            this.props.exportsInfo.selectedPackets,
            this.props.defaultGroupTitle,
            this.props.defaultFilename,
            { folderingEnabled: this.props.applicationSettings.RMS_CASE_FOLDERING_ENABLED }
        );
    }

    downloadHelper(formData, entityMetadata, selectedPackets) {
        if (this.props.trackReleases && this.props.applicationSettings.RELEASE_TRACKING_ENABLED) {
            this.props.openReleaseForm(
                formData,
                selectedPackets,
                this.props.exportResourceMethod,
                entityMetadata
            );
        } else {
            const isAssociatingToEntity = !!entityMetadata;
            if (this.props.useExportRouteToggle && !isAssociatingToEntity) {
                toggleExportsState();
            }

            this.props.exportPDF(
                { excludedAttachmentTypes: this.props.excludedAttachmentTypes, ...formData },
                selectedPackets,
                this.props.exportResourceMethod,
                entityMetadata
            );
            this.props.closeCallback();
        }
    }

    download(formData) {
        const selectedPackets = this.computeSelectedPackets();
        this.downloadHelper(formData, undefined, selectedPackets);
    }

    downloadAndAssociateToEntity(formData) {
        const selectedPackets = this.computeSelectedPackets();

        this.downloadHelper(
            formData,
            {
                entityId: this.props.entityId,
                entityType: this.props.entityType,
                linkType: this.props.internalExportAttachmentLinkType,
                onAssociationComplete: () =>
                    this.props.toast({
                        status: 'default',
                        description: strings.exportAssociationSuccessMessage(
                            selectedPackets.length
                        ),
                    }),
            },
            selectedPackets
        );
    }

    cancel() {
        if (this.props.useExportRouteToggle) {
            toggleExportsState();
        }
        this.props.closeCallback();
    }

    redact() {
        const selectedPackets = this.computeSelectedPackets();
        if (!isEmpty(selectedPackets)) {
            this.props.setRedactionWorkOrderFromExportsFormData(selectedPackets);
            this.props.onRedact();
        }
    }

    email(emailData) {
        const selectedPackets = this.computeSelectedPackets();
        return this.props.emailPackets(selectedPackets, emailData);
    }

    render() {
        const {
            closeCallback,
            exportsInfo,
            includeAttachments,
            excludeAttachmentLinkTypes,
            showIncludeHistoryEvents,
            showOnlyIncludeFieldsWithData,
            showIncludeWarrantActivities,
            showIncludeNameAddendums,
            showCombinedPDF,
            showFieldRedaction,
            entityId,
            entityType,
            submitButtonText,
            showCancel,
            emailsEnabled,
        } = this.props;

        const initialValues = {
            packets: {
                value: [],
            },
            includeConfidentialInformation: false,
            includeFiles: false,
            includeHistoryEvents: false,
            onlyIncludeFieldsWithData: true,
            includeWarrantActivities: false,
            includeNameAddendums: false,
            selectedAttachmentsToInclude: {},
            redactedFields: [],
            redactFields: false,
            mergeAttachments: false,
            combinedPDF:
                entityType === EntityTypeEnum.CASE.name
                    ? !this.props.applicationSettings.RMS_CASE_FOLDERING_ENABLED
                    : undefined,
        };

        const { packetOptions, selectedPackets, loaded, error } = exportsInfo;
        if (loaded) {
            return (
                <FormExportsOptions
                    renderFormExportsOptions={this.props.renderFormExportsOptions}
                    onCancel={showCancel && this.cancel.bind(this)}
                    onSubmit={this.download.bind(this)}
                    onAddExportToEntity={this.downloadAndAssociateToEntity.bind(this)}
                    onRedact={isFunction(this.props.onRedact) && this.redact.bind(this)}
                    onEmail={this.email.bind(this)}
                    initialValues={initialValues}
                    closeCallback={closeCallback}
                    packetOptions={packetOptions}
                    includeAttachments={includeAttachments}
                    excludeAttachmentLinkTypes={excludeAttachmentLinkTypes}
                    showIncludeHistoryEvents={showIncludeHistoryEvents}
                    showOnlyIncludeFieldsWithData={showOnlyIncludeFieldsWithData}
                    showIncludeWarrantActivities={showIncludeWarrantActivities}
                    showIncludeNameAddendums={showIncludeNameAddendums}
                    showCombinedPDF={showCombinedPDF}
                    showFieldRedaction={showFieldRedaction}
                    numOfSelectedPackets={selectedPackets.length}
                    entityId={entityId}
                    entityType={entityType}
                    submitButtonText={submitButtonText}
                    emailsEnabled={emailsEnabled}
                />
            );
        } else if (error) {
            return <h2>{strings.failedToLoadError}</h2>;
        }
        return <SimpleLoading />;
    }
}

const FormExportsFC = (props) => {
    const toast = useToast();
    return <FormExports {...props} toast={toast} />;
};

const mapStateToProps = createStructuredSelector({
    exportsInfo: exportSelector,
    applicationSettings: applicationSettingsSelector,
    isInvolvedPersonSealedForReportId: isInvolvedPersonSealedForReportIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    reports: reportsSelector,
});

function mapDispatchToProps(dispatch) {
    return {
        exportPDF: (data, selectedPackets, exportResourceMethod, entityMetadata) => {
            return dispatch(
                exportPDF(data, selectedPackets, exportResourceMethod, undefined, entityMetadata)
            );
        },
        openReleaseForm: (formData, selectedPackets, exportResourceMethod, entityMetadata) => {
            return dispatch(
                openBox(
                    { name: boxEnum.CREATE_EXPORT_RELEASE_MODAL },
                    {
                        formData,
                        selectedPackets,
                        exportResourceMethod,
                        entityMetadata,
                    }
                )
            );
        },
        setRedactionWorkOrderFromExportsFormData: (selectedPackets) => {
            return dispatch(setRedactionWorkOrderFromExportsFormData(selectedPackets));
        },
        emailPackets: (selectedPackets, emailData) =>
            dispatch(emailPrintables(selectedPackets, emailData)),
    };
}

export default flowRight(withRouter, connect(mapStateToProps, mapDispatchToProps))(FormExportsFC);

import { forEach, keyBy, has } from 'lodash';

import * as caseDetailsModalValidators from '../../modules/cases/core/state/form/caseDetailsFormValidators';
import * as reportCaseStatusesValidators from '../../modules/reports/core/state/forms/reportCaseStatusesFormValidators';
import * as caseReviewFormValidators from '../../modules/cases/case-review/state/form/caseReviewFormValidators';
import * as ucrClassificationFormValidators from '../../modules/reports/ucr-classification/state/forms/ucrClassificationFormValidators';
import * as createChainEventsFormValidators from '../../modules/evidence/item-queue/state/forms/createChainEventsFormValidators';
import * as retentionPolicyAdminFormValidators from '../../modules/admin/evidence-retention-policies/state/forms/retentionPolicyAdminFormValidators';
import * as fieldConfigurationsAdminFormValidators from '../../modules/admin/field-configurations/state/forms/fieldConfigurationsAdminFormValidators';
import * as warrantChargesCardValidators from '../../modules/warrants/warrant/state/cards/warrantChargesCardValidators';
import * as createReleaseFormValidators from '../../modules/core/pdf-exports/release-tracking/state/forms/createReleaseFormValidators';
import * as offenseCardValidators from './rules/offenseCardValidators';
import * as attributesWidgetValidators from './rules/attributesWidgetValidators';
import * as personPanelValidators from './rules/personPanelValidators';
import * as organizationPanelValidators from './rules/organizationPanelValidators';
import * as locationPanelValidators from './rules/locationPanelValidators';
import * as itemsPanelValidators from './rules/itemsPanelValidators';
import * as reportSubmissionValidators from './rules/reportSubmissionValidators';
import * as propertyStatusValidators from './rules/propertyStatusValidators';
import * as createReportValidators from './components/createReportValidators';

export default function addRuleImplementation(validationRules, dispatch) {
    const validators = [
        offenseCardValidators,
        attributesWidgetValidators,
        personPanelValidators,
        organizationPanelValidators,
        locationPanelValidators,
        itemsPanelValidators,
        propertyStatusValidators,
        createReportValidators,
        caseDetailsModalValidators,
        reportCaseStatusesValidators,
        caseReviewFormValidators,
        ucrClassificationFormValidators,
        createChainEventsFormValidators,
        retentionPolicyAdminFormValidators,
        fieldConfigurationsAdminFormValidators,
        warrantChargesCardValidators,
        createReleaseFormValidators,
    ];

    validators.push(reportSubmissionValidators);

    // Check if there are rules being exported with duplicate names.
    const implementations = {};
    forEach(validators, (validator) => {
        forEach(validator, (v, property) => {
            if (!has(implementations, property)) {
                if (needsDispatch(property)) {
                    implementations[property] = validator[property](dispatch);
                } else {
                    implementations[property] = validator[property];
                }
            } else {
                throw new Error('Rule implementation duplicate name found.');
            }
        });
    });

    const impByEnum = keyBy(implementations, 'ruleId');
    forEach(validationRules, (validationRule) => {
        if (validationRule.systemRule) {
            validationRule.validate = impByEnum[validationRule.systemRule];
        }
    });

    return validationRules;
}

const needsDispatch = (property) => {
    return (
        property === 'chainEventSignatureRequiresSignatureEnabled' ||
        property === 'chainEventIDScanRequiresIDScanEnabled'
    );
};

import React from 'react';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import ItemPopover from '../../../modules/reports/core/components/ItemPopover';
import NotificationsPopover from '../../../modules/notifications/popover/components/NotificationsPopover';
import ItemQueuePopover from '../../../modules/evidence/item-queue/components/ItemQueuePopover';
import EntityPermissionsModal from '../security/EntityPermissionsModal';
import RenChangeModal from '../reports/RenChangeModal';
import RecordNumberChangeModal from '../reports/RecordNumberChangeModal';
import LegacyCreateReportModal from '../reports/createReport/LegacyCreateReportModal';
import ReportSubmissionModal from '../../../modules/reports/core/components/report-status-comments/ReportSubmissionModal';
import ReturnReportToDraftModal from '../../../modules/reports/core/components/report-status-comments/ReturnReportToDraftModal';
import ReportChangeOwnerModal from '../../../modules/reports/core/components/header/ReportChangeOwnerModal';
import OffenseIncidentDeletionModal from '../../../modules/reports/core/components/offense/OffenseIncidentDeletionModal';
import ReportRejectionModal from '../../../modules/reports/core/components/report-status-comments/ReportRejectionModal';
import ReportSendArrestToBookingModal from '../../../modules/reports/core/components/header/ReportSendArrestToBookingModal';
import AdvancedSearchLoadingModal from '../../../modules/search/core/components/AdvancedSearchLoadingModal';
import AppLoadFailureModal from '../landing/AppLoadFailureModal';
import CadTicketDoesNotRequireReportModal from '../../../modules/search/cad-tickets/components/CadTicketDoesNotRquireReportModal';
import InactivityModal from '../security/InactivityModal';
import LoadingModal from '../../../modules/core/box/components/LoadingModal';
import ConfirmationModal from '../../../modules/core/box/components/ConfirmationModal';
import ErrorModal from '../../../modules/core/box/components/ErrorModal';
import NarrativeModal from '../../../modules/cases/unassigned-reports/components/NarrativeModal';
import {
    CaseDetailsModal,
    CreateCaseModal,
    DeleteCaseModal,
    EditCaseRenModal,
} from '../../../modules/cases/core/components';
import LabelPrintingModal from '../../../modules/evidence/label-printing/components/LabelPrintingModal';
import DocumentExportingModal from '../../../modules/reports/custodial-property-summary/components/documents/DocumentExportingModal';
import DeleteStorageModal from '../../../modules/admin/evidence-facilities/components/DeleteStorageModal';
import ToggleAbilityModal from '../../../modules/admin/roles/components/ToggleAbilityModal';
import EditRoleNameModal from '../../../modules/admin/roles/components/EditRoleNameModal';
import DeleteRoleConfirmationModal from '../../../modules/admin/roles/components/DeleteRoleConfirmationModal';
import {
    ChangeWarrantNumberModal,
    CreateWarrantModal,
    DeleteWarrantModal,
} from '../../../modules/warrants/warrant/components';
import ItemSplitItemPopover from '../../../modules/evidence/item-split/components/ItemSplitItemPopover';
import CreateReleaseModal from '../../../modules/core/pdf-exports/release-tracking/components/CreateReleaseModal';
import EditReleaseModal from '../../../modules/core/pdf-exports/release-tracking/components/EditReleaseModal';
import MoveStorageModal from '../../../modules/admin/evidence-facilities/components/MoveStorageModal';
import UsersDashboardFilterModal from '../../../modules/admin/users/components/UsersDashboardFilterModal';
import AccountSwitcherModal from '../../../modules/core/account-switcher/components/AccountSwitcherModal';
import ConsortiumAdminRemoveDepartmentModal from '../../../modules/admin/consortium/components/ConsortiumAdminRemoveDepartmentModal';
import ConsortiumAdminAddDepartmentModal from '../../../modules/admin/consortium/components/ConsortiumAdminAddDepartmentModal';
import BlacksmithIntegrationDeleteModal from '../../../modules/support/blacksmith/components/BlacksmithIntegrationDeleteModal';
import BlacksmithIntegrationKickOffConfirmationModal from '../../../modules/support/blacksmith/components/BlacksmithIntegrationKickOffConfirmationModal';
import SubmissionCommentsModal from '../../../modules/reports/core/components/report-status-comments/SubmissionCommentsModal';

import {
    AdminErrorBoundary,
    CaseManagementErrorBoundary,
    EvidenceErrorBoundary,
    NotificationsErrorBoundary,
    ReportsErrorBoundary,
    SearchErrorBoundary,
    WarrantsErrorBoundary,
} from '../../../modules/core/errors/components/ErrorBoundary';
import OffenseCodeChangeReasonModal from '../../../modules/reports/core/components/report-status-comments/OffenseCodeChangeReasonModal';


/**
 * All modals and popovers in the app are rendered in this component. Whether
 *   they become visible depends on state, which is not handled here.
 */
export default function Modals() {
    return (
        <>
            <AdminErrorBoundary>
                <DeleteStorageModal />
                <MoveStorageModal />
                <ToggleAbilityModal />
                <EditRoleNameModal />
                <DeleteRoleConfirmationModal />
                <UsersDashboardFilterModal />
                <ConsortiumAdminRemoveDepartmentModal />
                <ConsortiumAdminAddDepartmentModal />
                <BlacksmithIntegrationDeleteModal />
                <BlacksmithIntegrationKickOffConfirmationModal />
            </AdminErrorBoundary>
            <CaseManagementErrorBoundary>
                <NarrativeModal />
                <CaseDetailsModal />
                <CreateCaseModal />
                <DeleteCaseModal />
                <EditCaseRenModal />
            </CaseManagementErrorBoundary>
            <EvidenceErrorBoundary>
                <FeatureFlagged flag="RMS_ARC_NAVIGATION_ENABLED" fallback={<ItemQueuePopover />} />
                <LabelPrintingModal />
                <ItemSplitItemPopover />
                <DocumentExportingModal />
            </EvidenceErrorBoundary>
            <NotificationsErrorBoundary>
                <NotificationsPopover />
            </NotificationsErrorBoundary>
            <ReportsErrorBoundary>
                <ItemPopover />
                <RenChangeModal />
                <RecordNumberChangeModal />
                <LegacyCreateReportModal
                // TODO: Delete with RMS-8946
                />
                <ReportSubmissionModal />
                <SubmissionCommentsModal />
                <ReturnReportToDraftModal />
                <ReportChangeOwnerModal />
                <OffenseIncidentDeletionModal />
                <OffenseCodeChangeReasonModal />
                <ReportSendArrestToBookingModal />
                <ReportRejectionModal />
                <CreateReleaseModal />
                <EditReleaseModal />
            </ReportsErrorBoundary>
            <SearchErrorBoundary>
                <AdvancedSearchLoadingModal />
                <CadTicketDoesNotRequireReportModal />
            </SearchErrorBoundary>
            <WarrantsErrorBoundary>
                <ChangeWarrantNumberModal />
                <CreateWarrantModal />
                <DeleteWarrantModal />
            </WarrantsErrorBoundary>

            <LoadingModal />
            <ConfirmationModal />
            <ErrorModal />
            <EntityPermissionsModal />
            <AppLoadFailureModal />
            <InactivityModal />
            <AccountSwitcherModal />
        </>
    );
}

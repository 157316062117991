import { EntityTypeEnum } from '@mark43/rms-api';

import { get, map, includes } from 'lodash';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { NEXUS_STATE_PROP as CHARGES_NEXUS_STATE_PROP } from '~/client-common/core/domain/charges/state/data';
import { NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/arrests/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import {
    NEXUS_STATE_PROP as REPORT_NEXUS_STATE_PROP,
    reportsSelector,
} from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as REPORT_DEFINITIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-definitions/state/data';
import { NEXUS_STATE_PROP as COURT_ORDERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/court-orders/state/data';
import {
    NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP,
    attachmentsByEntitySelector,
} from '~/client-common/core/domain/attachments/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';

import { initializeCardForm } from '../../../../reports/core/state/ui/cards';
import sealingResource from '../../../core/resources/sealingResource';
import courtOrderEditForm from '../forms/courtOrderEditForm';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import { chargesForArrestReportIdSelector } from '../../../sealing/state/ui';

const COURT_ORDER_EDIT_RESET_STATE = 'record-privacy/court-orders/RESET_STATE';
const COURT_ORDER_EDIT_FORM_SUBMIT_FAILURE = 'record-privacy/court-orders/FORM_SUBMIT_FAILURE';
const COURT_ORDER_EDIT_FORM_SUBMIT_BEGIN = 'record-privacy/court-orders/FORM_SUBMIT_BEGIN';

const COURT_ORDER_EDIT_LOAD_REPORT_BEGIN = 'record-privacy/court-orders/LOAD_REPORT_BEGIN';
const COURT_ORDER_EDIT_LOAD_REPORT_SUCCESS = 'record-privacy/court-orders/LOAD_REPORT_SUCCESS';
const COURT_ORDER_EDIT_LOAD_REPORT_FAILURE = 'record-privacy/court-orders/LOAD_REPORT_FAILURE';

const resetCourtOrderEditState = () => ({ type: COURT_ORDER_EDIT_RESET_STATE });
const courtOrderEditFormSubmitBegin = () => ({ type: COURT_ORDER_EDIT_FORM_SUBMIT_BEGIN });
const courtOrderEditFormSubmitFailure = (error) => ({
    type: COURT_ORDER_EDIT_FORM_SUBMIT_FAILURE,
    payload: { error },
});

const loadReportBegin = ({ reportId, courtOrderId }) => ({
    type: COURT_ORDER_EDIT_LOAD_REPORT_BEGIN,
    payload: { reportId, courtOrderId },
});
const loadReportSuccess = ({ reportId, courtOrderId }) => ({
    type: COURT_ORDER_EDIT_LOAD_REPORT_SUCCESS,
    payload: { reportId, courtOrderId },
});
const loadReportFailure = (error) => ({
    type: COURT_ORDER_EDIT_LOAD_REPORT_FAILURE,
    payload: { error },
});

export function cleanupReportCourtOrder() {
    return (dispatch, getState, { nexus }) =>
        dispatch(
            // clean up entities from loadSealedReportForCourtOrderEditing
            // keep REPORT_DEFINITIONS_NEXUS_STATE_PROP for select dropdowns
            nexus.withRemoveMultiple(
                {
                    [ATTACHMENTS_NEXUS_STATE_PROP]: {},
                    [COURT_ORDERS_NEXUS_STATE_PROP]: {},
                    [REPORT_NEXUS_STATE_PROP]: {},
                },
                resetCourtOrderEditState()
            )
        );
}

export const loadSealedReportForCourtOrderEditing = (payload) => (
    dispatch,
    getState,
    dependencies
) => {
    const { reportId } = payload;
    dispatch(loadReportBegin(payload));
    return sealingResource()
        .loadSealedReportView(reportId)
        .then((completeReportView) => {
            const action = dependencies.nexus.withEntityItems(
                {
                    [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                        completeReportView.attributes,
                        convertAttributeToAttributeView
                    ),
                    [REPORT_NEXUS_STATE_PROP]: [completeReportView.report],
                    [REPORT_DEFINITIONS_NEXUS_STATE_PROP]: completeReportView.reportDefinitions,
                    [COURT_ORDERS_NEXUS_STATE_PROP]: completeReportView.courtOrders,
                    [ATTACHMENTS_NEXUS_STATE_PROP]: completeReportView.attachments,
                    [CHARGES_NEXUS_STATE_PROP]: completeReportView.charges,
                    [ARRESTS_NEXUS_STATE_PROP]: [completeReportView.arrest],
                    [OFFENSE_CODES_NEXUS_STATE_PROP]: completeReportView.offenseCodes,
                },
                loadReportSuccess(payload)
            );
            dispatch(action);
        })
        .catch((error) => dispatch(loadReportFailure(error.message)));
};

export const submitCourtOrderEditForm = ({ router }) => (dispatch, getState) => {
    dispatch(courtOrderEditFormSubmitBegin());
    return dispatch(
        courtOrderEditForm.actionCreators.submit((formModel) => {
            const state = getState();
            const { chargeIdsToSeal } = formModel;
            const reportId = parseInt(formModel.reportId);
            const departmentId = currentUserDepartmentIdSelector(state);
            const chargesForArrestReportId = chargesForArrestReportIdSelector(state);
            const charges = map(chargesForArrestReportId(reportId), (charge) => ({
                ...charge,
                isSealed: includes(chargeIdsToSeal, charge.id) || charge.isSealed,
            }));
            return sealingResource().updateSealedReport({
                reportId,
                isSealReport: !!formModel.isSealReport,
                isSealNarrative: !!formModel.isSealNarrative,
                userToContactId: formModel.userToContactId,
                courtOrders: [
                    {
                        ...formModel.courtOrder,
                        fileIds: formModel.courtOrderFileIds,
                        reportId,
                        // cast to boolean
                        isJuvenile: !!formModel.courtOrder.isJuvenile,
                        departmentId,
                    },
                ],
                ...{ charges },
            });
        })
    )
        .then((resp) => {
            router.push(`/reports/${get(resp, 'courtOrders[0].reportId')}`);
            return resp;
        })
        .catch((err) => {
            // This is used for validation errors and actual submission server errors.
            // Validation errors won't have a message so this will only reset `isSubmitting`
            // in that case, without setting an actual error message.
            dispatch(courtOrderEditFormSubmitFailure(err.message));
        });
};

export const initializeCourtOrderEditForm = ({ reportId, courtOrder }) => (dispatch, getState) => {
    const state = getState();
    const report = reportsSelector(state)[reportId];
    const arrestIdNumber = report.recordNumber;

    dispatch(
        courtOrderEditForm.actionCreators.change({
            reportId,
            courtOrder,
            isSealNarrative: !!report.isNarrativeSealed,
            isSealReport: !!report.isSealed,
            userToContactId: report.sealedNarrativeContactUserId,
            arrestIdNumber,
            courtOrderFileIds: map(
                attachmentsByEntitySelector(state)(EntityTypeEnum.COURT_ORDER.name, courtOrder.id),
                'file.id'
            ),
        })
    );
    dispatch(initializeCardForm(courtOrderEditForm));
};

const initialState = {
    submissionError: undefined,
    isSubmitting: false,
    courtOrderLoading: {
        loadingError: undefined,
        loaded: false,
        isLoading: false,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case COURT_ORDER_EDIT_FORM_SUBMIT_FAILURE:
            return { ...state, submissionError: action.payload.error, isSubmitting: false };
        case COURT_ORDER_EDIT_FORM_SUBMIT_BEGIN:
            return { ...state, submissionError: undefined, isSubmitting: true };
        case COURT_ORDER_EDIT_LOAD_REPORT_BEGIN:
            return { ...state, courtOrderLoading: { ...state.courtOrderLoading, isLoading: true } };
        case COURT_ORDER_EDIT_LOAD_REPORT_SUCCESS:
            return {
                ...state,
                courtOrderLoading: {
                    ...state.courtOrderLoading,
                    isLoading: false,
                    loaded: true,
                },
            };
        case COURT_ORDER_EDIT_LOAD_REPORT_FAILURE:
            return {
                ...state,
                courtOrderLoading: {
                    ...state.courtOrderLoading,
                    isLoading: false,
                    loadingError: action.payload.error,
                },
            };
        default:
            return state;
    }
};

export const courtOrderEditSubmissionErrorSelector = (state) =>
    state.ui.courtOrderEdit.submissionError;
export const courtOrderEditIsSubmittingSelector = (state) => state.ui.courtOrderEdit.isSubmitting;
export const courtOrderEditcourtOrderLoadingStateSelector = (state) =>
    state.ui.courtOrderEdit.courtOrderLoading;

export default makeResettable(COURT_ORDER_EDIT_RESET_STATE, reducer, initialState);

import { actions } from 'react-redux-form';

import {
    impoundByReportIdSelector,
    updateImpound,
} from '~/client-common/core/domain/impounds/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import createCard from '../../utils/createCard';
import impoundForm from '../forms/impoundForm';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { refreshImpoundForm } from './impound';
import { submitCard, validateCard } from './cards';

const formModelPath = 'formModels.impound';

const baseCard = createCard({
    name: reportCardEnum.IMPOUND.name,
    baseSelector: (state) => state.ui.report.impoundCard,
    anchor: 'impound-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit({ reportId } = {}) {
            return (dispatch) => {
                dispatch(refreshImpoundForm(reportId));
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save() {
            return (dispatch, getState) => {
                dispatch(actions.setInitial(formModelPath));

                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formModule: impoundForm,
                        })
                    );
                } else {
                    const formModel = impoundForm.selectors.formModelSelector(state);
                    const impound = impoundForm.convertFromFormModel(formModel);
                    const rrfPromise = dispatch(updateImpound(impound));

                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formModule: impoundForm,
                            promises: [rrfPromise],
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                // Specifically use `getState()` because state
                                // likely has been mutated by the save.
                                const state = getState();
                                const currentReportId = currentReportIdSelector(state);
                                const impound = impoundByReportIdSelector(state)(currentReportId);
                                const formModel = impoundForm.convertToFormModel(impound);

                                // do not use the form `change` action creator,
                                // because it forces a call to `setInitial`, which
                                // we do not want.  A `setInitial` call will
                                // reset validation errors present on the form.
                                dispatch(actions.change(formModelPath, formModel));
                            },
                        })
                    );
                }
            };
        },
    },
};

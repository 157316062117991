import React from 'react';
import { createStructuredSelector, createSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import styled from 'styled-components';

import { storageLocationViewModelByIdSelector } from '~/client-common/core/domain/storage-locations/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { submitLocationMoveConfirmationModal } from '../state/ui';
import { createModalSelector } from '../../../core/box/state/ui';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { renderOnlyIf } from '../../../../legacy-redux/helpers/reactHelpers';
import LocationUpdatedChainEventPreview from './LocationUpdatedChainEventPreview';

const strings = componentStrings.admin.evidenceFacilities.MoveStorageModal;

const context = { name: boxEnum.MOVE_STORAGE_MODAL };

const ModalMessage = styled.div`
    font-size: var(--arc-fontSizes-md);
`;
const StorageName = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

function MoveStorageModal({ onSave, displayValue, facilityId }) {
    return (
        <Modal context={context} title={strings.title} okText={strings.confirm} onSave={onSave}>
            <ModalMessage>
                <span>{strings.message}</span>
                <StorageName>{displayValue}</StorageName>
            </ModalMessage>
            <LocationUpdatedChainEventPreview facilityId={facilityId} />
        </Modal>
    );
}

const destinationLocationIdSelector = createModalSelector(context, 'destinationLocationId');
const sourceLocationIdSelector = createModalSelector(context, 'sourceLocationId');
const storageLocationViewModelSelector = createSelector(
    storageLocationViewModelByIdSelector,
    sourceLocationIdSelector,
    (storageLocationViewModelById, sourceLocationId) =>
        storageLocationViewModelById(sourceLocationId)
);

const mapStateToProps = createStructuredSelector({
    sourceLocationId: sourceLocationIdSelector,
    destinationLocationId: destinationLocationIdSelector,
    storageLocationViewModel: storageLocationViewModelSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSave: (...args) => dispatch(submitLocationMoveConfirmationModal(...args)),
});

/**
 * Confirmation Modal for Moving Storage Location and all sub-locations and
 *   all the items within them.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    renderOnlyIf(({ storageLocationViewModel }) => !!storageLocationViewModel),
    withHandlers({
        onSave({ onSave, sourceLocationId, destinationLocationId }) {
            return () => onSave(sourceLocationId, destinationLocationId);
        },
    }),
    withPropsOnChange(
        ['storageLocationViewModel'],
        ({ storageLocationViewModel: { displayValue, facilityId } = {} }) => ({
            displayValue,
            facilityId,
        })
    )
)(MoveStorageModal);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { SearchName } from '../../../search/core/components/SearchName';
import Column from '../../../core/components/Column';
import CasesSearchForm from '../../core/components/CasesSearchForm';
import { myCasesSearch, resetMyCasesSearchForm, submitMyCasesSearch } from '../state/ui';
import TargetProfileSearchFieldset from '../../target-profile/components/TargetProfileSearchFieldset';
import MyCasesSearchPersonnelFieldset from './MyCasesSearchPersonnelFieldset';
import MyCasesSearchCaseInformationFieldset from './MyCasesSearchCaseInformationFieldset';

function MyCasesSearchForm({ currentSavedSearch, onSubmit, onReset, query }) {
    return (
        <CasesSearchForm
            {...myCasesSearch.form}
            onSubmit={onSubmit}
            onReset={onReset}
            query={query}
            searchModule={myCasesSearch}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
            <Column width={270}>
                <MyCasesSearchPersonnelFieldset />
            </Column>
            <Column width={620} marginRight={0}>
                <MyCasesSearchCaseInformationFieldset />
            </Column>
            <FeatureFlagged flag="RMS_TARGET_PROFILES_ENABLED">
                <Column width={620} marginRight={0}>
                    <TargetProfileSearchFieldset />
                </Column>
            </FeatureFlagged>
        </CasesSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    currentSavedSearch: myCasesSearch.selectors.currentSavedSearchSelector,
    query: myCasesSearch.selectors.currentQuerySelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(submitMyCasesSearch({ autoSave: true })),
    onReset: () => {
        dispatch(resetMyCasesSearchForm());
        dispatch(myCasesSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(myCasesSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
});

/**
 * Search/filter form on the My Cases page. In the UI, this form appears
 *   when the filter button is clicked.
 */
export default connect(mapStateToProps, mapDispatchToProps)(MyCasesSearchForm);

import _, { map } from 'lodash';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';

export function formatVehicleMake({ vehicleMake, vehicleMakeOther }) {
    if (vehicleMake === vehicleMakeOther) {
        // in this scenario, the Other value was probably backfilled and is not meaningful to repeat
        // e.g. return "Honda" instead of "Honda: Honda"
        return vehicleMake;
    }
    return joinTruthyValues([vehicleMake, vehicleMakeOther], ': ');
}

export function formatVehicleModel({ vehicleModel, vehicleModelOther }) {
    if (vehicleModel === vehicleModelOther) {
        return vehicleModel;
    }
    return joinTruthyValues([vehicleModel, vehicleModelOther], ': ');
}

export function formatVehicleMakeModel({
    vehicleMake,
    vehicleMakeOther,
    vehicleModel,
    vehicleModelOther,
}) {
    const formattedVehicleMake = formatVehicleMake({
        vehicleMake,
        vehicleMakeOther,
    });

    const formattedVehicleModel = formatVehicleModel({
        vehicleModel,
        vehicleModelOther,
    });
    return joinTruthyValues([formattedVehicleMake, formattedVehicleModel], ' ');
}

/**
 * Format a vehicle item for display
 * Refer to https://docs.google.com/spreadsheets/d/1YYA__Xw5PBbpI5cTnAY4XEw7tv2D8HU8F-vu9MzFdmg/edit#gid=0 for row configuration
 * @param {string} description
 * @param {string} itemCategory
 * @param {string} vehicleMakeName
 * @param {string} vehicleMakeAttr
 * @param {boolean} vehicleMakeIsOther
 * @param {string} vehicleMakeOther
 * @param {string} vehicleModelName
 * @param {string} vehicleModelAttr
 * @param {boolean} vehicleModelIsOther
 * @param {string} vehicleModelOther
 * @param {string} yearOfManufacture
 * @returns {string}
 */
export const formatTitleForVehicle = ({
    description,
    itemCategory,
    vehicleMake,
    vehicleMakeIsOther = false,
    vehicleMakeOther,
    vehicleModel,
    vehicleModelIsOther = false,
    vehicleModelOther,
    yearOfManufacture,
}) => {
    // Rows 17, 18, 19
    let details = description;

    if (
        vehicleMake &&
        !vehicleMakeIsOther &&
        !vehicleModel &&
        !vehicleModelOther &&
        !vehicleModelIsOther
    ) {
        // Row 20
        const formattedVehicleMake = formatVehicleMake({
            vehicleMake,
        });

        details = formattedVehicleMake;
    } else if (vehicleMake && !vehicleMakeIsOther && vehicleModelOther) {
        // Row 2, 3
        const formattedVehicleMake = formatVehicleMake({
            vehicleMake,
        });

        details = joinTruthyValues([formattedVehicleMake, vehicleModelOther], ' ');
    } else if (vehicleMake && !vehicleMakeIsOther && vehicleModel && vehicleModelIsOther) {
        // Row 4
        details = formatVehicleMake({
            vehicleMake,
        });
    } else if (vehicleMake && !vehicleMakeIsOther && vehicleModel && !vehicleModelIsOther) {
        // Row 5
        details = formatVehicleMakeModel({
            vehicleMake,
            vehicleMakeOther,
            vehicleModel,
            vehicleModelOther,
        });
    } else if (
        vehicleMakeOther &&
        vehicleModelOther &&
        ((vehicleMake && vehicleMakeIsOther && ((vehicleModel && vehicleModelIsOther) || 1)) || 1)
    ) {
        // Row 10, 11, 12
        details = joinTruthyValues([vehicleMakeOther, vehicleModelOther], ' ');
    } else if (
        vehicleMake &&
        vehicleMakeIsOther &&
        vehicleMakeOther &&
        vehicleModel &&
        !vehicleModelIsOther
    ) {
        // Row 9
        const formattedVehicleModel = formatVehicleModel({
            vehicleModel,
            vehicleModelOther,
        });

        details = joinTruthyValues([vehicleMakeOther, formattedVehicleModel], ' ');
    } else if (
        vehicleMakeOther &&
        ((vehicleMake && vehicleMakeIsOther && vehicleModel && vehicleModelIsOther) || 1)
    ) {
        // Row 6, 7, 8
        details = vehicleMakeOther;
    } else if (
        vehicleModelOther &&
        ((vehicleMake && vehicleMakeIsOther && vehicleModel && vehicleModelIsOther) || 1)
    ) {
        // Row 14, 15, 16
        details = vehicleModelOther;
    } else if (vehicleMake && vehicleMakeIsOther && vehicleModel && !vehicleModelIsOther) {
        // Row 13
        details = formatVehicleModel({
            vehicleModel,
            vehicleModelOther,
        });
    }

    return joinTruthyValues(
        [itemCategory, joinTruthyValues([yearOfManufacture, details], ' ')],
        ' - '
    );
};

/**
 * Vehicle Make Models feature flags helper.
 * @param {string} vehicleMakeAttr
 * @param {boolean} vehicleMakeIsOther
 * @param {string} vehicleMakeName
 * @param {string} vehicleModelAttr
 * @param {boolean} vehicleModelIsOther
 * @param {string} vehicleModelName
 * @returns {Object}
 */
export function vehicleMakeModelDisplayProperties({
    vehicleMakeIsOther,
    vehicleMakeName,
    vehicleModelIsOther,
    vehicleModelName,
}) {
    const vehicleMake = vehicleMakeName;
    const vehicleModel = vehicleModelName;

    vehicleMakeIsOther = !vehicleMakeName;
    vehicleModelIsOther = !vehicleModelName;

    return { vehicleMake, vehicleMakeIsOther, vehicleModel, vehicleModelIsOther };
}

/**
 * Map and remove any cruft from vehicle model details.
 *   Results are for filtering/auto-selecting model related select inputs.
 * @param {Object}   vehicleModelDetails
 * @returns {Object}
 */
export function buildVehicleModelRelated(vehicleModelDetails) {
    const {
        VEHICLE_BODY_STYLE: bodyStyleAttrIds = [],
        ITEM_CATEGORY: itemCategoryAttrIds = [],
    } = _(vehicleModelDetails.attributes)
        .groupBy('type')
        .mapValues((attributes) => map(attributes, 'id'))
        .value();

    return {
        bodyStyleAttrIds,
        itemCategoryAttrIds,
        vehicleMake: vehicleModelDetails.vehicleMake,
    };
}

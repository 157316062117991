import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { compose, pure, withHandlers } from 'recompose';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSONNEL_INVOLVEMENT_TYPE_LABEL } from '~/client-common/core/enums/universal/fields';
import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFOfficerInvolvementSelect } from '../../../core/forms/components/selects/OfficerInvolvementSelect';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';

const FlexRow = styled.div`
    display: flex;
`;

const { withRRFActions } = reactReduxFormHelpers;

const InvolvedOfficerNItem = compose(
    withRRFActions,
    withHandlers({
        onBlur({ formActions: { setValidity }, assistTypeAttrId, officerInvolvement, officerId }) {
            return () =>
                setValidity('officerId', {
                    requiredError: !!officerId || (!assistTypeAttrId && !officerInvolvement),
                });
        },
    })
)(function InvolvedOfficerNItem({ onBlur }) {
    const involvementTypeLabel = useFields(DISPLAY_ONLY_PERSONNEL_INVOLVEMENT_TYPE_LABEL)[
        DISPLAY_ONLY_PERSONNEL_INVOLVEMENT_TYPE_LABEL
    ];
    return (
        <>
            <RRFOfficerInvolvementSelect onBlur={onBlur} label={involvementTypeLabel} />
            <RRFUserSelect multiple={true} path="officerIds" effectiveDate={null} onBlur={onBlur} />
        </>
    );
});

function InvolvedOfficerNItems() {
    return (
        <RRFNItems path="involvedOfficers" addItemOnDirty={true}>
            {({ assistTypeAttrId, officerInvolvement, officerId }, index, deleteButton) => (
                <FlexRow>
                    <InvolvedOfficerNItem
                        assistTypeAttrId={assistTypeAttrId}
                        officerInvolvement={officerInvolvement}
                        officerId={officerId}
                        index={index}
                    />
                    {deleteButton}
                </FlexRow>
            )}
        </RRFNItems>
    );
}

/**
 * Fieldset for personnel (involved users).
 * @param {Object}  props
 */
export default pure(function PersonnelFieldset({ isPersonnelUnitStaticallyHidden }) {
    return (
        <RRFFieldset path="personnel">
            <FlexRow>
                <div
                    data-test-id={testIds.ADVANCED_SEARCH_REPORT_REPORT_OWNER}
                    style={{ width: '100%', marginRight: 'var(--arc-space-4' }}
                >
                    <RRFUserSelect
                        multiple={true}
                        path="currentSubmissionOwnerIds"
                        effectiveDate={null}
                    />
                </div>
                <RRFUserSelect multiple={true} path="authorIds" effectiveDate={null} />
                <RRFUserSelect multiple={true} path="reviewerIds" effectiveDate={null} />
            </FlexRow>
            {isPersonnelUnitStaticallyHidden ? undefined : (
                <Row>
                    <RRFAttributeSelect
                        multiple={true}
                        attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                        includeExpired={true}
                        length="md"
                        path="personnelUnitAttrIds"
                    />
                </Row>
            )}
            <InvolvedOfficerNItems />
        </RRFFieldset>
    );
});

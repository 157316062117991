import { AttributeTypeEnum } from '@mark43/rms-api';

import React from 'react';
import { Observer } from 'markformythree';
import { applyStatuteCodeSetFilterToFlags } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';

import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTOffenseCodeSelect } from '../../../../core/forms/components/selects/OffenseCodeSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import { ArbiterMFTStatuteCodeSetRadio } from '../StatuteCodeSetRadio';

export class FullIncidentForm extends React.PureComponent {
    render() {
        const incidentStatAttrName = AttributeTypeEnum.INCIDENT_STATISTIC.name;

        const { offenseCodeFlags = [] } = this.props;
        return (
            <CardSection>
                <Row>
                    <ArbiterMFTStatuteCodeSetRadio
                        form={this.props.form}
                        offenseCodeIdPath="incident.offenseCodeId"
                    />
                </Row>
                <Row>
                    <Observer
                        subscriptions={{
                            statuteCodeSetFilter: 'statuteCodeSetFilter',
                        }}
                        render={({ statuteCodeSetFilter }) => (
                            <ArbiterMFTOffenseCodeSelect
                                path="incident.offenseCodeId"
                                clearable={false}
                                length="lg"
                                flags={applyStatuteCodeSetFilterToFlags(
                                    statuteCodeSetFilter,
                                    offenseCodeFlags
                                )}
                                statuteCodeSetFilter={statuteCodeSetFilter}
                                autoFocus={this.props.autoFocus}
                            />
                        )}
                    />
                </Row>
                <Row>
                    <ArbiterMFTAttributeCheckboxes
                        path={`offenseAttributes.${incidentStatAttrName}.attributeIds`}
                        attributeType={incidentStatAttrName}
                        columns={2}
                        columnWidth="48%"
                        gutterWidth="4%"
                    />
                </Row>
                <IndentedFields>
                    <Row>
                        <ArbiterMFTText
                            path={`offenseAttributes.${incidentStatAttrName}.description`}
                            length="lg"
                        />
                    </Row>
                </IndentedFields>
            </CardSection>
        );
    }
}

import { createSelector } from 'reselect';
import _, { isArray } from 'lodash';

import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import {
    consortiumDepartmentLinksSelector,
    consortiumDepartmentLinksByDepartmentIdSelector,
} from '../data';
import getDepartmentNameFromConsortiumLinksByDepartmentId from '../../utils/getDepartmentNameFromConsortiumLinksByDepartmentId';

export const consortiumDepartmentLinksAvailableSelector = createSelector(
    consortiumDepartmentLinksSelector,
    (consortiumDepartmentLinks) => !!consortiumDepartmentLinks.length
);

export const consortiumDepartmentLinksOptionsWhereSelector = createSelector(
    consortiumDepartmentLinksSelector,
    (consortiumDepartmentLinks) => (predicate) =>
        _(consortiumDepartmentLinks)
            .filter(predicate)
            .map((link) => ({
                value: link.deptId,
                display: link.deptDisplayName,
            }))
            .sortBy('display')
            .value()
);

export const departmentNameFromConsortiumLinksByDepartmentIdSelector = createSelector(
    consortiumDepartmentLinksByDepartmentIdSelector,
    (consortiumDepartmentLinksByDepartmentId) => {
        const mapper = (id) =>
            getDepartmentNameFromConsortiumLinksByDepartmentId(
                consortiumDepartmentLinksByDepartmentId,
                id
            );

        return (departmentId) =>
            isArray(departmentId)
                ? joinTruthyValues(_(departmentId).map(mapper).sortBy().value())
                : mapper(departmentId);
    }
);

import { CaseProfileLink, CasesResource } from '@mark43/rms-api';
import { createResource } from '../../../../../lib/resources/Resource';
import { req } from '../../../../../lib/ajax';

const BASE_PATH = 'cases';

export default createResource({
    name: 'Case Profile Links Resource',
    methods: {
        updateCaseProfileLinks(caseId: number, caseProfileLinks: CaseProfileLink[]) {
            return req<CasesResource.UpdateCaseProfileLinks>({
                method: 'POST',
                url: `${BASE_PATH}/${caseId}/profiles`,
                data: caseProfileLinks,
            });
        },
    },
});

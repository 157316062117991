import React from 'react';
import {
    InlineBanner as ArcInlineBanner,
    InlineBannerProps as ArcInlineBannerProps,
    cssVar,
} from 'arc';
import styled from 'styled-components';
import testIds from '../../../core/testIds';

type InlineBannerProps = ArcInlineBannerProps & {
    /** When true, renders the children in a unordered list. */
    hasList?: boolean;
    /** Overrides the default testId provided by the "error" or "success" banner status.*/
    testId?: string;
};

const StyledArcInlineBanner = styled(ArcInlineBanner)`
    margin-bottom: ${cssVar('arc.space.3')};
`;

export const InlineBannerErrorList = styled.ul`
    padding-left: ${cssVar('arc.space.6')};
    & li {
        margin-block-end: ${cssVar('arc.space.half')};
    }
`;

function getTestId(statusType: InlineBannerProps['status']) {
    switch (statusType) {
        case 'error':
            return testIds.RED_ERROR_MESSAGE;
            break;
        case 'success':
            return testIds.GREEN_SUCCESS_MESSAGE;
        default:
            return null;
    }
}

// TODO: Move this into /core/components and out of legacy-redux

/** This wraps the Arc InlineBanner but extends it:
 * Adds testId and handles defaults
 * Adds hasList to optionally render a styled bulleted list
 * Accepts children to render as the description
 * Adds default margin bottom
 */
export const InlineBanner: React.FC<InlineBannerProps> = React.forwardRef<
    HTMLDivElement,
    InlineBannerProps
>(({ children, hasList, testId, status, ...rest }, forwardedRef) => (
    <StyledArcInlineBanner
        ref={forwardedRef}
        status={status}
        data-test-id={testId ?? getTestId(status)}
        description={
            hasList ? (
                <InlineBannerErrorList>
                    {React.Children.map(children, (child, index) => (
                        <li key={index}>{child}</li>
                    ))}
                </InlineBannerErrorList>
            ) : (
                children
            )
        }
        {...rest}
    />
));

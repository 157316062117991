import React from 'react';
import { useSelector } from 'react-redux';
import { lifecycleOptions } from 'markformythree';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { summaryNarrativeFormName } from '../../state/forms/summaryNarrativeForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import {
    NarrativeDisplayContainer,
    TinyStyledArbiterMFTNarrativeCardEditor,
} from '../NarrativeStyledComponents';
import { useFetchMentionsConfigurationsOnNarrativeCard } from '../../../../admin/mentions-configuration/state/data';

function TinySummaryNarrativeCardForm({
    id,
    summaryMode,
    isExpandedFullScreen,
    editorRef,
    getSummaryMode,
    onFullScreenToggle,
    setEditor,
    setError,
    reportId,
    isSticky,
    offset,
}) {
    useFetchMentionsConfigurationsOnNarrativeCard(summaryMode);
    const mentionsEnabled = useSelector(applicationSettingsSelector)
        .RMS_MENTIONS_IN_NARRATIVE_ENABLED;

    return (
        <NarrativeDisplayContainer isExpandedFullScreen={isExpandedFullScreen}>
            <ArbiterForm
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={summaryNarrativeFormName}
                index={reportId}
                context={summaryNarrativeFormName}
                render={() => (
                    <TinyStyledArbiterMFTNarrativeCardEditor
                        id={id}
                        path="summaryNarrative"
                        mentionsEnabled={mentionsEnabled}
                        inlineCommentsEnabled={false}
                        isSticky={isSticky}
                        offset={offset}
                        editorRef={editorRef}
                        getSummaryMode={getSummaryMode}
                        onFullScreenToggle={onFullScreenToggle}
                        setEditor={setEditor}
                        setError={setError}
                    />
                )}
            />
        </NarrativeDisplayContainer>
    );
}

export default TinySummaryNarrativeCardForm;

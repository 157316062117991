import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadMessagingAuditAdminFormFieldViewModels = {
    cadMessagingAudit: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadMessagingAudit',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'beginTimeUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endTimeUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'userIds',
            },
            {
                key: 'keywords',
            },
            {
                unitAssignmentIds: 'unitAssignmentIds',
            },
            {
                dispatchAreaAssignmentIds: 'dispatchAreaAssignmentIds',
            },
        ]),
    },
};

function convertToFormModel({ cadMessagingAudit = {} }) {
    return {
        cadMessagingAudit: {
            ...cadMessagingAudit,
        },
    };
}

function convertFromFormModel({ cadMessagingAudit = {} }) {
    return { cadMessagingAudit };
}

/**
 * Module of the cad units admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CAD_MESSAGING_AUDIT_ADMIN,
    fieldViewModels: cadMessagingAuditAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

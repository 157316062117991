import { createSelector } from 'reselect';
import { compact, first, get } from 'lodash';

import { warrantsWhereSelector } from '../data';
import { personProfileByIdSelector } from '../../../person-profiles/state/data';

// Get all `PERSON_PROFILE`s associated with a `WARRANT`
export const personProfilesForWarrantIdSelector = createSelector(
    warrantsWhereSelector,
    personProfileByIdSelector,
    (warrantsWhere, personProfileById) => (warrantId) => {
        const warrant = first(warrantsWhere({ id: warrantId }));
        const nameId = get(warrant, 'subjectPersonProfileId');
        const personProfiles = compact([personProfileById(nameId)]);
        return personProfiles;
    }
);

import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { isEmpty, noop } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { Observer, lifecycleOptions } from 'markformythree';
import { isUndefinedOrNull, isValueDisplayable } from '~/client-common/helpers/logicHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formsRegistry from '../../../../../../core/formsRegistry';

import testIds from '../../../../../../core/testIds';
import ArbiterForm from '../../../../../core/markformythree-arbiter/ArbiterForm';
import { getFormConfiguration } from '../../../state/forms/arrest-charges-side-panel/findWarrantSearchForm';
import {
    FIND_WARRANT_SEARCH_TYPES,
    performFindWarrantSearch,
} from '../../../state/ui/arrest-charges-side-panel/findWarrantSearch';
import Button, { buttonTypes } from '../../../../../../legacy-redux/components/core/Button';
import Row from '../../../../../core/components/Row';
import { MFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTUserSelect } from '../../../../../core/forms/components/selects/UserSelect';

const strings = componentStrings.reports.core.ArrestChargesSidePanel.FindWarrantSearchScreen;

const OrDividerRow = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px 0;
    width: 100%;
`;

const OrDivider = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    display: inline-block;
    width: 40%;
    vertical-align: middle;
`;

const OrText = styled.p`
    display: inline-block;
    text-transform: uppercase;
`;

const formName = RefContextEnum.FORM_ARREST_CHARGES_FIND_WARRANT_SEARCH_SIDE_PANEL.name;
export const unregisterFindWarrantSearchScreenForm = () => formsRegistry.unregister(formName);

export const onPerformSearch = ({ searchType }) => (dispatch) => {
    const form = formsRegistry.get(formName);
    const formModel = form.getState().model;
    return dispatch(
        performFindWarrantSearch({ searchType, findWarrantSearchFormModel: formModel })
    );
};

export const onCancelSidePanel = unregisterFindWarrantSearchScreenForm;

const FindWarrantSearchScreen = ({ screenState, onPerformSearchCallback }) => {
    const { isPerformingAsyncAction } = screenState;
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            configuration={getFormConfiguration()}
            // Specifically no validation for this form - we just want configurable field labels.
            onValidate={noop}
            render={() => {
                return (
                    <>
                        <Row>
                            <ArbiterMFTText path="warrantNumber" length="lg" autoFocus={true} />
                        </Row>
                        <Row>
                            <Observer
                                subscriptions={{ warrantNumber: 'warrantNumber' }}
                                render={({ warrantNumber }) => {
                                    const isDisabled =
                                        isPerformingAsyncAction ||
                                        !isValueDisplayable(warrantNumber);
                                    return (
                                        <Button
                                            testId={
                                                testIds.CONNECT_WARRANT_SIDE_PANEL_SEARCH_WARRANT_NUM_BUTTON
                                            }
                                            className={buttonTypes.PRIMARY}
                                            disabled={isDisabled}
                                            onClick={() =>
                                                onPerformSearchCallback({
                                                    searchType:
                                                        FIND_WARRANT_SEARCH_TYPES.WARRANT_NUMBER,
                                                })
                                            }
                                        >
                                            {strings.searchButtonText}
                                        </Button>
                                    );
                                }}
                            />
                        </Row>
                        <OrDividerRow>
                            <OrDivider />
                            <OrText>{strings.orDivider}</OrText>
                            <OrDivider />
                        </OrDividerRow>
                        <Row>
                            <MFTDatePicker
                                path="startDateUtc"
                                includeTime={true}
                                label={strings.searchStartDate}
                            />
                        </Row>
                        <Row>
                            <MFTDatePicker
                                path="endDateUtc"
                                includeTime={true}
                                label={strings.searchEndDate}
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="warrantTypeAttrIds"
                                attributeType={AttributeTypeEnum.WARRANT_TYPE.name}
                                multiple={true}
                                length="lg"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTUserSelect
                                path="obtainingOfficerUserIds"
                                multiple={true}
                                length="lg"
                                effectiveDate={null}
                            />
                        </Row>
                        <Row>
                            <Observer
                                subscriptions={{
                                    startDateUtc: 'startDateUtc',
                                    endDateUtc: 'endDateUtc',
                                    warrantTypeAttrIds: 'warrantTypeAttrIds',
                                    obtainingOfficerUserIds: 'obtainingOfficerUserIds',
                                }}
                                render={({
                                    startDateUtc,
                                    endDateUtc,
                                    warrantTypeAttrIds,
                                    obtainingOfficerUserIds,
                                }) => {
                                    const isDisabled =
                                        isPerformingAsyncAction ||
                                        (isUndefinedOrNull(startDateUtc) &&
                                            isUndefinedOrNull(endDateUtc) &&
                                            isEmpty(warrantTypeAttrIds) &&
                                            isEmpty(obtainingOfficerUserIds));
                                    return (
                                        <Button
                                            testId={
                                                testIds.CONNECT_WARRANT_SIDE_PANEL_SEARCH_WARRANT_BUTTON
                                            }
                                            className={buttonTypes.PRIMARY}
                                            disabled={isDisabled}
                                            onClick={() =>
                                                onPerformSearchCallback({
                                                    searchType:
                                                        FIND_WARRANT_SEARCH_TYPES.DATE_RANGE_WARRANT_TYPE_OBTAINING_OFFICER,
                                                })
                                            }
                                        >
                                            {strings.searchButtonText}
                                        </Button>
                                    );
                                }}
                            />
                        </Row>
                    </>
                );
            }}
        />
    );
};

export default FindWarrantSearchScreen;

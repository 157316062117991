import React, { useCallback } from 'react';
import { Button } from 'arc';
import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { PortalSidePanel } from '../../../../../legacy-redux/components/core/SidePanel';
import {
    OverlayBaseHelper,
    OverlayRenderProps,
} from '../../../../core/components/OverlayBaseHelper';
import { useEFileContext } from '../../../hooks';
import { eFileResource } from '../../../resources';
import { ImportableItemsContent, useImportableItems } from './content/ImportableItemsContent';

const strings = componentStrings.eFiles.core.sidePanel;

type ImportItemsSidePanelCustomProperties = Record<string, never>;

export const ImportItemsSidePanel = ({ onImport }: { onImport: () => void }) => {
    const { getters } = useEFileContext();
    const eFile = getters.efile.getEFile();
    const eFileId = eFile?.efile.id;

    const { selectedItemProfileIds, setSelectedItemProfileIds } = useImportableItems();

    const onSave = useCallback(
        async ({
            overlayBase,
            closePanel,
        }: OverlayRenderProps<ImportItemsSidePanelCustomProperties>) => {
            if (!eFileId || selectedItemProfileIds.length === 0) {
                return;
            }
            try {
                await eFileResource.importMaterial({
                    efileId: eFileId,
                    importMaterialRequests: map(selectedItemProfileIds, (entityId) => ({
                        entityId,
                        entityType: EntityTypeEnum.ITEM_PROFILE.name,
                    })),
                });
                setSelectedItemProfileIds([]);
                onImport();
            } catch (error) {
                if (error instanceof Error) {
                    overlayBase.setError(error.message);
                    return;
                }
            }
            closePanel();
        },
        [eFileId, selectedItemProfileIds, setSelectedItemProfileIds, onImport]
    );

    if (!eFile || !eFileId) {
        return null;
    }

    return (
        <OverlayBaseHelper<ImportItemsSidePanelCustomProperties>
            id={overlayIdEnum.E_FILE_IMPORT_ITEMS_SIDE_PANEL}
            renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => (
                <Button
                    variant="solid"
                    leadingVisual="Add"
                    onClick={open}
                    isDisabled={!eFile.canEdit}
                    ref={setCloseFocusRefs}
                >
                    {strings.buttonLabel[LinkTypesEnum.ITEM_IN_EFILE]}
                </Button>
            )}
        >
            {(renderProps) => {
                return (
                    <PortalSidePanel
                        saveDisabled={selectedItemProfileIds.length === 0}
                        title={strings.title[LinkTypesEnum.ITEM_IN_EFILE]}
                        closePanel={renderProps.closePanel}
                        savePanel={() => onSave(renderProps)}
                        errorMessages={renderProps.overlayBase.overlayState.errors}
                        isAtBottomOfStack={renderProps.overlayBase.isAtBottomOfStack}
                    >
                        <ImportableItemsContent eFileId={eFileId} />
                    </PortalSidePanel>
                );
            }}
        </OverlayBaseHelper>
    );
};

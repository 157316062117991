import { OffenseInvolvedChild } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getOffensesResource from '../../../offenses/resources/offensesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'offenseInvolvedChildren';

const offenseInvolvedChildrenModule = createLinkModule<OffenseInvolvedChild>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

export const offenseInvolvedChildrenWhereSelector =
    offenseInvolvedChildrenModule.selectors.linksWhereSelector;

export default offenseInvolvedChildrenModule.reducerConfig;

const UPDATE_OFFENSE_INVOLVED_CHILDREN_START =
    'offense_involved_children/UPDATE_OFFENSE_INVOLVED_CHILDREN_START';
const UPDATE_OFFENSE_INVOLVED_CHILDREN_SUCCESS =
    'offense_involved_children/UPDATE_OFFENSE_INVOLVED_CHILDREN_SUCCESS';
const UPDATE_OFFENSE_INVOLVED_CHILDREN_FAILURE =
    'offense_involved_children/UPDATE_OFFENSE_INVOLVED_CHILDREN_FAILURE';

function updateOffenseInvolvedChildrenStart(offenseInvolvedChildren: OffenseInvolvedChild[]) {
    return {
        type: UPDATE_OFFENSE_INVOLVED_CHILDREN_START,
        payload: offenseInvolvedChildren,
    };
}

function updateOffenseInvolvedChildrenSuccess(offenseInvolvedChildren: OffenseInvolvedChild[]) {
    return {
        type: UPDATE_OFFENSE_INVOLVED_CHILDREN_SUCCESS,
        payload: offenseInvolvedChildren,
    };
}

function updateOffenseInvolvedChildrenFailure(message: string) {
    return {
        type: UPDATE_OFFENSE_INVOLVED_CHILDREN_FAILURE,
        payload: message,
    };
}

export function updateOffenseInvolvedChildren({
    offenseId,
    offenseInvolvedChildren,
}: {
    offenseId: number;
    offenseInvolvedChildren: OffenseInvolvedChild[];
}): ClientCommonAction<Promise<OffenseInvolvedChild[]>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(updateOffenseInvolvedChildrenStart(offenseInvolvedChildren));
        // to be moved once everything is cut to the offense/incident card bundle endpoint
        // which won't require us to store all the things using separate endpoints
        return getOffensesResource()
            .updateOffenseInvolvedChildren({ offenseId, offenseInvolvedChildren })
            .then((updatedOffenseInvolvedChildren: OffenseInvolvedChild[]) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: updatedOffenseInvolvedChildren,
                        },
                        nexus.withRemove(
                            NEXUS_STATE_PROP,
                            { offenseId },
                            updateOffenseInvolvedChildrenSuccess(updatedOffenseInvolvedChildren)
                        )
                    )
                );
                return updatedOffenseInvolvedChildren;
            })
            .catch((err: Error) => {
                dispatch(updateOffenseInvolvedChildrenFailure(err.message));
                throw err;
            });
    };
}

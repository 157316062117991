import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { Form, Fieldset as MFTFieldset, lifecycleOptions } from 'markformythree';
import React from 'react';

import { map, findIndex, get, isEmpty } from 'lodash';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { ArbiterMFTYesNoUnknownButtonRadio } from '../../../../core/forms/components/button-radios/YesNoUnknownButtonRadio';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import { ArbiterMFTBooleanButtonRadio } from '../../../../core/forms/components/button-radios/BooleanButtonRadio';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { FormConfigurationRenderer } from '../../../../core/markformythree-arbiter/dynamic-fields/components/FormConfigurationRenderer';
import { CUSTOM_PROPERTIES_KEY_NAME } from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';

class OffenseVictimFields extends React.PureComponent {
    render() {
        const { nameLinkIndex, hideSubstanceAbuseAttr } = this.props;
        return (
            <Row>
                <MFTFieldset
                    path="links"
                    render={() => (
                        <MFTFieldset
                            path="victims"
                            render={() => (
                                <MFTFieldset
                                    path={`[${nameLinkIndex}]`}
                                    render={() => (
                                        <div>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="isVictimOfCriminalNeglect" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="isVictimPregnant" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTYesNoUnknownButtonRadio path="victimPriorPartyDifficultiesAttrId" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTYesNoUnknownButtonRadio path="victimAppraisedOfRemediesAttrId" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="victimReligionAttrId"
                                                    attributeType={AttributeTypeEnum.RELIGION.name}
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                {!hideSubstanceAbuseAttr && (
                                                    <ArbiterMFTAttributeSelect
                                                        path="substanceAbuseAttrId"
                                                        attributeType={
                                                            AttributeTypeEnum.SUBSTANCE_ABUSE.name
                                                        }
                                                        length="lg"
                                                    />
                                                )}
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanSelect
                                                    path="victimAdvisedOfRemedies"
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanSelect
                                                    clearable={false}
                                                    path="isLeoka"
                                                    length="md"
                                                />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="leokaAssignmentTypeAttrId"
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .LEOKA_ASSIGNMENT_CATEGORY.name
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="leokaActivityAttrId"
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .LEOKA_ACTIVITY_CATEGORY.name
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTText
                                                        path="leokaOtherJurisdictionOri"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="leokaOtherJurisdictionOriAttrId"
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .LEOKA_OTHER_JURISDICTION_ORI.name
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                            </IndentedFields>
                                            <Row>
                                                <ArbiterMFTDatePicker path="dateDeclared" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeCheckboxes
                                                    path="informationProvidedAttrIds"
                                                    attributeType={
                                                        AttributeTypeEnum
                                                            .INFORMATION_PROVIDED_TO_VICTIM.name
                                                    }
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="isVictimByAssociation" />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="victimByAssociationTypeAttrId"
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .VICTIM_BY_ASSOCIATION_TYPE.name
                                                        }
                                                        length="md"
                                                    />
                                                    <ArbiterMFTAttributeSelect
                                                        path="victimByAssociationRelationAttrId"
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .VICTIM_BY_ASSOCIATION_RELATION.name
                                                        }
                                                        length="md"
                                                    />
                                                </Row>
                                            </IndentedFields>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio
                                                    path="wasSearchPerformed"
                                                    length="lg"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio path="wasStatementTaken" />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="victimSuspectedDrugTypeAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SUSPECTED_DRUG_TYPE.name
                                                    }
                                                    multiple={true}
                                                    length="md"
                                                />
                                            </Row>
                                            <IndentedFields>
                                                <Row>
                                                    <ArbiterMFTTextArea
                                                        path="statement"
                                                        length="lg"
                                                    />
                                                </Row>
                                            </IndentedFields>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio
                                                    path="isCollegeStudent"
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="schoolCodeAttrId"
                                                    attributeType={
                                                        AttributeTypeEnum.SCHOOL_CODE.name
                                                    }
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio
                                                    path="isOffenseOnCampus"
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio
                                                    path="isVictimTransportation"
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio
                                                    path="isViolationOfOrderOfProtection"
                                                    length="md"
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTBooleanButtonRadio
                                                    path="isPreviousDomesticViolenceVictim"
                                                    length="md"
                                                />
                                            </Row>
                                        </div>
                                    )}
                                />
                            )}
                        />
                    )}
                />
            </Row>
        );
    }
}
export class OffenseVictimAdditionalFields extends React.PureComponent {
    render() {
        const {
            nameLink,
            summaryMode,
            nameAttributesWhere,
            formatAttributeById,
            hideSubstanceAbuseAttr,
            offenseFormIndex,
        } = this.props;

        if (summaryMode) {
            return (
                <SummaryList labelWidth={150} contentWidth={350}>
                    <SummaryRow fieldName={fields.NAME_REPORT_LINK_IS_VICTIM_OF_CRIMINAL_NEGLECT}>
                        {booleanToYesNo(nameLink.isVictimOfCriminalNeglect)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.NAME_REPORT_LINK_IS_VICTIM_PREGNANT}>
                        {booleanToYesNo(nameLink.isVictimPregnant)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_VICTIM_PRIOR_PARTY_DIFFICULTIES_ATTR_ID
                        }
                    >
                        {formatAttributeById(nameLink.victimPriorPartyDifficultiesAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_VICTIM_APPRAISED_OF_REMEDIES_ATTR_ID
                        }
                    >
                        {formatAttributeById(nameLink.victimAppraisedOfRemediesAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_VICTIM_RELIGION_ATTR_ID
                        }
                    >
                        {formatAttributeById(nameLink.victimReligionAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.NAME_REPORT_LINK_SUBSTANCE_ABUSE_ATTR_ID}>
                        {formatAttributeById(nameLink.substanceAbuseAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.NAME_REPORT_LINK_VICTIM_ADVISED_OF_REMEDIES}>
                        {booleanToYesNo(nameLink.victimAdvisedOfRemedies)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_LEOKA}
                    >
                        {booleanToYesNo(nameLink.isLeoka)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LEOKA_ASSIGNMENT_TYPE_ATTR_ID
                        }
                    >
                        {formatAttributeById(nameLink.leokaAssignmentTypeAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LEOKA_ACTIVITY_ATTR_ID
                        }
                    >
                        {formatAttributeById(nameLink.leokaActivityAttrId)}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.NAME_REPORT_LINK_LEOKA_OTHER_JURISDICTION_ORI}>
                        {nameLink.leokaOtherJurisdictionOri}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.NAME_REPORT_LINK_LEOKA_OTHER_JURISDICTION_ORI_ATTR_ID}
                    >
                        {formatAttributeById(nameLink.leokaOtherJurisdictionOriAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_DATE_DECLARED
                        }
                    >
                        {nameLink.dateDeclared}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.DISPLAY_ATTRIBUTE_INFORMATION_PROVIDED_TO_VICTIM}>
                        {map(
                            nameAttributesWhere({
                                nameId: nameLink.nameId,
                                attributeType:
                                    AttributeTypeEnum.INFORMATION_PROVIDED_TO_VICTIM.name,
                            }),
                            (nameAttribute) => formatAttributeById(nameAttribute.attributeId)
                        )
                            .sort()
                            .join(', ')}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.NAME_REPORT_LINK_IS_VICTIM_BY_ASSOCIATION}>
                        {booleanToYesNo(nameLink.isVictimByAssociation)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.NAME_REPORT_LINK_VICTIM_BY_ASSOCIATION_TYPE_ATTR_ID}
                    >
                        {formatAttributeById(nameLink.victimByAssociationTypeAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.NAME_REPORT_LINK_VICTIM_BY_ASSOCIATION_RELATION_ATTR_ID}
                    >
                        {formatAttributeById(nameLink.victimByAssociationRelationAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_WAS_SEARCH_PERFORMED
                        }
                    >
                        {booleanToYesNo(nameLink.wasSearchPerformed)}
                    </SummaryRow>
                    {!isEmpty(nameLink.statement) && (
                        <SummaryRow
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_WAS_STATEMENT_TAKEN
                            }
                        >
                            {booleanToYesNo(true)}
                        </SummaryRow>
                    )}
                    <SummaryRow
                        fieldName={fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_STATEMENT}
                    >
                        {nameLink.statement}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_COLLEGE_STUDENT
                        }
                    >
                        {booleanToYesNo(nameLink.isCollegeStudent)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_SCHOOL_CODE_ATTR_ID
                        }
                    >
                        {formatAttributeById(nameLink.schoolCodeAttrId)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_OFFENSE_ON_CAMPUS
                        }
                    >
                        {booleanToYesNo(nameLink.isOffenseOnCampus)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_DOMESTIC_VIOLENCE_VICTIM}
                    >
                        {booleanToYesNo(nameLink.isDomesticViolenceVictim)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_VICTIM_TRANSPORTATION
                        }
                    >
                        {booleanToYesNo(nameLink.isVictimTransportation)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_VIOLATION_OF_ORDER_OF_PROTECTION
                        }
                    >
                        {booleanToYesNo(nameLink.isViolationOfOrderOfProtection)}
                    </SummaryRow>
                    <SummaryRow
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_PREVIOUS_DOMESTIC_VIOLENCE_VICTIM
                        }
                    >
                        {booleanToYesNo(nameLink.isPreviousDomesticViolenceVictim)}
                    </SummaryRow>

                    <Form
                        name={RefContextEnum.FORM_OFFENSE.name}
                        index={offenseFormIndex}
                        lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                        render={(form) => {
                            const victimData = get(form.getState(), 'model.links.victims');
                            const nameLinkIndex = findIndex(victimData, {
                                nameId: nameLink.nameId,
                            });
                            if (nameLinkIndex <= -1) {
                                return null;
                            }
                            return (
                                <FormConfigurationRenderer
                                    form={form}
                                    absoluteFormPath={`links.victims[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                    mode="SUMMARY"
                                />
                            );
                        }}
                    />
                </SummaryList>
            );
        }

        return (
            <Form
                name={RefContextEnum.FORM_OFFENSE.name}
                index={offenseFormIndex}
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                render={(form) => {
                    const victimData = get(form.getState(), 'model.links.victims');
                    const nameLinkIndex = findIndex(victimData, { nameId: nameLink.nameId });

                    // Only if we found the `nameLinkIndex` in our m43 form,
                    // should we attempt to render the corresponding form elements
                    return (
                        nameLinkIndex > -1 && (
                            <>
                                <OffenseVictimFields
                                    form={form}
                                    hideSubstanceAbuseAttr={hideSubstanceAbuseAttr}
                                    nameLinkIndex={nameLinkIndex}
                                />
                                <FormConfigurationRenderer
                                    form={form}
                                    absoluteFormPath={`links.victims[${nameLinkIndex}].${CUSTOM_PROPERTIES_KEY_NAME}`}
                                    mode="FORM"
                                />
                            </>
                        )
                    );
                }}
            />
        );
    }
}

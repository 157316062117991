import React from 'react';

import strings from '~/client-common/core/strings/componentStrings';

import Row from '../../../core/components/Row';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { NarrativeContainer } from '../../core/components/RecordPrivacyContainers';
import PreviouslySealedNote from '../../core/components/PreviouslySealedNote';

const ReportUnsealingFormFields = ({ report, disableNarrativeSealing }) => (
    <div>
        <Row>
            <RRFCheckbox
                disabled={!report.isNarrativeSealed || disableNarrativeSealing}
                path="isUnsealNarrative"
                label={
                    strings.recordPrivacy.unsealing.ReportUnsealingFormFields.isUnsealNarrative
                        .label
                }
            />
        </Row>
        {!report.isNarrativeSealed ? (
            <NarrativeContainer
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: report.narrative }}
            />
        ) : (
            <PreviouslySealedNote />
        )}
    </div>
);

export default ReportUnsealingFormFields;

import { Store } from 'redux';
import { ElasticPerson } from '@mark43/rms-api';
import { iconPaths } from '@arc/icon';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatShortName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import {
    prepareEnhancedCautions,
    sortCustomAndEnhancedCautions,
} from '../../../../../cautions/helpers';
import { preparePersonCustomCautions } from '../../../../../cautions/helpers/customCautions';
import { getPrioritizedPersonLabelAttributes } from '../../../../../components/tags/helpers';
import { FormatMentionItemMapper, Mention, MentionCautions } from '../types';
import {
    getDefaultAvatarIcon,
    getMentionCautions,
    getMentionEntityLinkUrl,
    getTruthyValues,
} from '../utils';

const prepareMentionedItem = (item: ElasticPerson, store: Store<RootState>): Mention => {
    const formatAttributeById = formatAttributeByIdSelector(store.getState());
    const parentAttributeIdByAttributeId = parentAttributeIdByAttributeIdSelector(store.getState());
    const { RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED } = applicationSettingsSelector(
        store.getState()
    );

    const name = formatShortName({ firstName: item.firstName, lastName: item.lastName });

    const descriptions = getTruthyValues([
        item.dateOfBirth,
        item.sexAttrDetail?.displayValue,
        item.raceAttrDetail?.displayValue,
    ]);

    let cautions: MentionCautions | undefined;

    if (RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED) {
        const customCautions = preparePersonCustomCautions({
            isJuvenile: item.isJuvenile,
            isSuspectedGangMember: item.isSuspectedGangMember,
            isVulnerable: item.isVulnerable,
            dateVulnerableTo: item.dateVulnerableTo,
            hasPotentialActiveWarrant: item.hasPotentialActiveWarrant,
        });
        const { enhancedCautions } = prepareEnhancedCautions({
            cautions: item.cautions,
            formatAttributeById,
            parentAttributeIdByAttributeId,
        });

        const sortedCautions = sortCustomAndEnhancedCautions({
            customCautions,
            enhancedCautions,
        });

        cautions = getMentionCautions(sortedCautions);
    } else {
        const cautionLabels = getPrioritizedPersonLabelAttributes({
            elasticNameAttributes: item.nameAttributes,
            isJuvenile: item.isJuvenile,
            isVulnerable: item.isVulnerable,
            dateVulnerableTo: item.dateVulnerableTo,
            isSuspectedGangMember: item.isSuspectedGangMember,
            hasPotentialActiveWarrant: item.hasPotentialActiveWarrant,
            formatAttributeById,
            parentAttributeIdByAttributeId,
        });

        cautions = getMentionCautions(cautionLabels);
    }

    return {
        id: item.id.toString(),
        name,
        text: name,
        descriptions,
        category: 'PERSON',
        photo: item.primaryMugshotPath,
        defaultIcon: getDefaultAvatarIcon(iconPaths.Person),
        cautions,
        url: getMentionEntityLinkUrl({ itemType: 'PERSON', itemId: item.id }),
    };
};

const formatMapper: FormatMentionItemMapper<ElasticPerson> = {
    resourceMethod: 'searchPersons',
    prepareMentionedItem,
};
export default formatMapper;

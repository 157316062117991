import { createSelector } from 'reselect';
import { reportDefinitionHasStopCard } from '../../../../../helpers/reportDefinitionsHelpers';
import { reportDefinitionByReportIdSelector } from '../../../report-definitions/state/data';

export const isStopReportSelector = createSelector(
    reportDefinitionByReportIdSelector,
    (reportDefinitionByReportId) => (reportId) => {
        const reportDefinition = reportDefinitionByReportId(reportId);
        return reportDefinitionHasStopCard(reportDefinition);
    }
);

import React, { useCallback } from 'react';
import { Text, Icon, Link } from 'arc';
import styled from 'styled-components';
import { first, join } from 'lodash';
import { useSelector } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router';
import { formatCaseDefinitionByIdSelector } from '~/client-common/core/domain/case-definitions/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { CaseApprovalStatusIcon } from '../../../records/core/components/ApprovalStatusIcon';
import {
    getCaseTitle,
    getCaseAssignedTo,
    getCaseStatus,
} from '../../../records/core/helpers/caseStringFormatters';
import { caseDetailsByCaseIdSelector } from '../state/ui';
import { CaseViewType } from '../state/form/reasonForRelationForm';

const Wrapper = styled.div`
    font-family: var(--arc-fonts-body);
    display: flex;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
    background-color: var(--arc-colors-surface-accent);
    padding: 1em;
    border-radius: 5px;
    cursor: pointer;

    & a {
        color: var(--arc-colors-brand-default);
    }
`;

const CaseDescriptionWrapper = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

type CaseItemComponentProps = {
    entityId: number;
    onClick?: (caseView: CaseViewType) => void;
};

const CaseItemComponent: React.FC<CaseItemComponentProps> = ({ entityId, onClick }) => {
    const formatCaseDefinitionById = useSelector(formatCaseDefinitionByIdSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);
    const caseDetailsByCaseId = useSelector(caseDetailsByCaseIdSelector);

    const caseDetails = caseDetailsByCaseId(entityId);

    const title =
        caseDetails?.theCase &&
        getCaseTitle({
            localId: caseDetails.theCase.localId,
            caseDefinition: formatCaseDefinitionById(caseDetails.theCase.caseDefinitionId),
        });

    const approvalStatus = caseDetails.caseApprovalStatus?.status;

    const caseRoleId = first(caseDetails.caseRoleLinks)?.roleId;

    // @ts-expect-error client-common to client RND-7529
    const allRoleFormats = allRoleFormatsByRoleId(caseRoleId);
    const assigneeRole =
        'fullNameWithFirstInitialAndIdNumber' in allRoleFormats &&
        allRoleFormats.fullNameWithFirstInitialAndIdNumber;

    const assignee = getCaseAssignedTo({
        assigneeRole,
        assignedPersonnelUnit: formatAttributeById(
            caseDetails?.theCase?.assignedPersonnelUnitAttrId
        ),
    });

    const caseStatus =
        caseDetails?.caseStatus?.statusAttrId &&
        getCaseStatus({
            caseStatus: formatAttributeById(caseDetails.caseStatus.statusAttrId),
        });

    const secondaryInfo = join([assignee, caseStatus], ' | ');

    const permissionSet = caseDetails.theCase?.permissionSet;

    const onCaseItemClick = useCallback(() => {
        const caseView = {
            approvalStatus,
            assignedPersonnelUnitAttrId: caseDetails?.theCase?.assignedPersonnelUnitAttrId,
            assigneeRoleId: caseRoleId,
            caseId: entityId,
            currentStatusAttrId: caseDetails.caseStatus?.statusAttrId,
            shortTitle: title,
            recentlyViewedCase: false,
        };

        if (onClick) {
            onClick(caseView);
        }
    }, [
        approvalStatus,
        caseDetails.caseStatus?.statusAttrId,
        caseDetails?.theCase?.assignedPersonnelUnitAttrId,
        caseRoleId,
        entityId,
        onClick,
        title,
    ]);

    return (
        <Wrapper onClick={onCaseItemClick}>
            {approvalStatus && (
                <CaseApprovalStatusIcon
                    className="approvalStatusIcon"
                    approvalStatus={approvalStatus}
                    size={20}
                />
            )}
            <CaseDescriptionWrapper>
                {canRead(permissionSet) ? (
                    <Link as={ReactRouterLink} to={`/cases/${entityId}`}>
                        <Text fontWeight="semibold" variant="headingSm" color="inherit">
                            {title} <Icon icon="OpenNewWindow" size="sm" />
                        </Text>
                    </Link>
                ) : (
                    <Text fontWeight="semibold" variant="headingSm" color="inherit">
                        {title}
                    </Text>
                )}
                <Text color="secondary" variant="bodyMd">
                    {secondaryInfo}
                </Text>
            </CaseDescriptionWrapper>
        </Wrapper>
    );
};

export default CaseItemComponent;

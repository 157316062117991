/**
 * Cross browser height grabbing.
 *   http://www.w3schools.com/jsref/prop_win_innerheight.asp
 * @return {Object}
 */
export function getWidthAndHeight() {
    const w =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const h =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    return {
        height: h,
        width: w,
    };
}

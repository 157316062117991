/* eslint-disable camelcase */
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Vehicle Models Resource',
        methods: {
            searchForVehicleModels({
                q,
                vehicleMakeId,
                // TODO: after BE changes, switch to attribute - change all usages
                vehicleTypeCodeId,
                // TODO: after BE changes, switch to attribute - change all usages
                vehicleBodyStyleCodeId,
                yearOfManufacture,
                from,
                size,
            }) {
                return req({
                    method: 'GET',
                    url: 'vehicles/codes/models',
                    params: {
                        q,
                        vehicle_make_id: vehicleMakeId,
                        vehicle_type_code_id: vehicleTypeCodeId,
                        vehicle_body_style_code_id: vehicleBodyStyleCodeId,
                        year_of_manufacture: yearOfManufacture,
                        include_vehicle_model_years: false,
                        include_vehicle_model_code_links: false,
                        include_vehicle_makes: true,
                        from,
                        size,
                    },
                });
            },
            getVehicleModelDetails(modelIds) {
                return req({
                    method: 'POST',
                    url: 'vehicles/codes/models/details',
                    data: modelIds,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import { connect } from 'react-redux';
import { compose } from 'redux';
import React from 'react';
import { isEmpty } from 'lodash';

import { filterReportUcrsOnEventCard } from '~/client-common/core/domain/report-ucrs/utils/reportUcrsHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { reportUcrViewModelsByReportIdSelector } from '~/client-common/core/domain/report-ucrs/state/ui';
import { ucrOffensesWhereSelector } from '~/client-common/core/domain/ucr-offenses/state/data';
import { ucrEventsWhereSelector } from '~/client-common/core/domain/ucr-events/state/data';
import { ucrPropertyWhereSelector } from '~/client-common/core/domain/ucr-property/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { CardSubsection } from '../../../../../legacy-redux/components/core/Card';

const strings = componentStrings.reports.core.EventInfoCard;

const mapStateToProps = (state, ownProps) => {
    const reportId = ownProps.reportId;
    const reportUcrViewModels = reportUcrViewModelsByReportIdSelector(state)(reportId);
    const reportUcrViewModel = filterReportUcrsOnEventCard(reportUcrViewModels);
    const ucrOffenses = ucrOffensesWhereSelector(state)({ reportId });
    const ucrEvents = ucrEventsWhereSelector(state)({ reportId });
    const ucrProperty = ucrPropertyWhereSelector(state)({ reportId });

    return {
        reportUcrViewModel,
        ucrOffenses,
        ucrEvents,
        ucrProperty,
    };
};

const ReportUcrSummary = compose(
    connect(mapStateToProps),
    renderOnlyIf(
        ({ reportUcrViewModel, ucrOffenses, ucrEvents, ucrProperty }) =>
            !!reportUcrViewModel &&
            isEmpty(ucrOffenses) &&
            isEmpty(ucrEvents) &&
            isEmpty(ucrProperty)
    )
)(function _ReportUcrSummary({ reportUcrViewModel }) {
    const viewModelProperties = getViewModelProperties(reportUcrViewModel);

    return (
        <SummaryList labelWidth={160} contentWidth={380}>
            <CardSubsection title={strings.ucrInfoSectionTitle}>
                <SummaryRow fieldName={fields.REPORT_UCR_UCR_SUMMARY_CODE}>
                    {viewModelProperties.ucrCodeSummary}
                </SummaryRow>
            </CardSubsection>
        </SummaryList>
    );
});

export default ReportUcrSummary;

import type { Editor } from 'tinymce';

/**
 * Dynamically update the position of the TinyMCE sidebar which displays an inline comment. Since we are displaying only
 * one comment at a time and not multiple, the top of the sidebar will match the parent element of the selected text,
 * which should be the highlighted text.
 *
 * This function mutates the HTMLElement style directly because the child elements in TinyMCE are not rendered in React.
 */
export default function updateCommentSidebarPosition(editor: Editor): void {
    const container = editor.getContainer();

    const sidebarElement = container.querySelector('.tox-sidebar');
    const headerElement = container.querySelector('.tox-editor-header');
    if (!(sidebarElement instanceof HTMLElement) || !(headerElement instanceof HTMLElement)) {
        return;
    }

    const selectionOffset = editor.selection.getBoundingClientRect().top;
    const top = `${selectionOffset + headerElement.scrollHeight}px`;
    if (sidebarElement.style.top !== top) {
        sidebarElement.style.top = top;
    }
}

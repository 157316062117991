import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Text as ArcText } from 'arc';
import { includes, map, without } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import { RightColumnNote } from '../../core/components/RecordPrivacyNote';
import reportSealingForm from '../state/forms/reportSealingForm';
import courtOrderEditForm from '../../court-orders/state/forms/courtOrderEditForm';
import { chargesForArrestReportIdSelector } from '../state/ui';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import { Button as ArcButton } from '../../../core/components/Button';
import SubSectionTitle from '../../core/components/SubSectionTitle';
import { EntityFlexRow } from '../../core/components/RecordPrivacyRow';
import Tag from '../../core/components/Tag';
import testIds from '../../../../core/testIds';

import type { RootState } from '../../../../legacy-redux/reducers/rootReducer';

const strings = componentStrings.recordPrivacy.sealing.SealingChargesSection;
const stringsEditForm = componentStrings.recordPrivacy.courtOrders.CourtOrderEditForm;

const SummaryListBlock = styled(SummaryList)`
    display: block;
    margin-top: 2px;
`;
const ChargeSummary = styled(ArcText)`
    flex: 0 1 100%;
`;

type SealingChargesSectionProps = {
    reportId: number;
    edit?: boolean;
};

const SealingChargesSection = (props: SealingChargesSectionProps) => {
    const { reportId, edit } = props;
    const dispatch = useDispatch();
    const chargesForArrestReportId = useSelector(chargesForArrestReportIdSelector);
    const formModelByPath = useSelector<RootState, (path: string) => number[]>(
        edit
            ? // @ts-expect-error client-common to client RND-7529
              courtOrderEditForm.selectors.formModelByPathSelector
            : // @ts-expect-error client-common to client RND-7529
              reportSealingForm.selectors.formModelByPathSelector
    );
    const formatOffenseCodeById = useSelector(formatOffenseCodeByIdSelector);

    const chargeIdsToSealFormModel = formModelByPath('chargeIdsToSeal');

    const createSealClickHandler = useCallback(
        (chargeId: number, isSealed: boolean) => () => {
            const chargeIdsToSeal = isSealed
                ? without(chargeIdsToSealFormModel, chargeId)
                : [...(chargeIdsToSealFormModel || []), chargeId];
            dispatch(
                edit
                    ? // @ts-expect-error client-common to client RND-7529
                      courtOrderEditForm.actionCreators.changePath(
                          'chargeIdsToSeal',
                          chargeIdsToSeal
                      )
                    : // @ts-expect-error client-common to client RND-7529
                      reportSealingForm.actionCreators.changePath(
                          'chargeIdsToSeal',
                          chargeIdsToSeal
                      )
            );
        },
        [chargeIdsToSealFormModel, edit, dispatch]
    );

    const arrestCharges = chargesForArrestReportId(reportId);
    const showChargesSection = !!arrestCharges.length;

    if (!showChargesSection) {
        return null;
    }

    return (
        <>
            <SubSectionTitle>{strings.sectionTitle}</SubSectionTitle>
            {edit && <RightColumnNote>{stringsEditForm.charges.note}</RightColumnNote>}
            {map(arrestCharges, (charge) => {
                if (charge?.isSealed) {
                    return null;
                }

                const isSealed = includes(chargeIdsToSealFormModel, charge.id);

                const formattedOffenseCode = formatOffenseCodeById({
                    id: charge.chargeOffenseCodeId,
                });

                return (
                    <EntityFlexRow key={charge.id}>
                        <ChargeSummary variant="headingXs">
                            {isSealed && <Tag>{strings.sealedTag}</Tag>}
                            {formattedOffenseCode}
                            <SummaryListBlock>
                                <SummaryRow fieldName={fields.CHARGE_DISPOSITION_ATTR_ID}>
                                    <ConnectedFormattedAttribute
                                        attributeId={charge.dispositionAttrId}
                                    />
                                </SummaryRow>
                            </SummaryListBlock>
                        </ChargeSummary>
                        <ArcButton
                            isActive={isSealed}
                            onClick={createSealClickHandler(charge.id, isSealed)}
                            variant="outline"
                            testId={testIds.SEAL_CHARGE_BUTTON}
                        >
                            {strings.sealButton}
                        </ArcButton>
                    </EntityFlexRow>
                );
            })}
        </>
    );
};

export default SealingChargesSection;

import {
    DeliveryMethodEnum,
    NotificationStatusEnum,
    EntityTypeEnum,
} from '@mark43/notifications-api';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { userNotificationsSelector } from '~/client-common/core/domain/user-notifications/state/data';
import { attachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';

/**
 * Gathers User Alert Notifcations and Attachments. Returns an array of alerts for display in Banner
 *   or legacy Alerts dashboard. here and not in admin/alerts/state/ui because of dependency loop
 * @type {Object[]}
 */
const userAlertNotificationsSelector = createSelector(
    userNotificationsSelector,
    attachmentViewModelsWhereSelector,
    (userNotifications, attachmentViewModelsWhere) =>
        _(userNotifications)
            .filter({ deliveryMethod: DeliveryMethodEnum.ALERT.name })
            .reject({ notificationStatus: NotificationStatusEnum.ACKNOWLEDGED.name })
            .map((userNotification) => {
                const { dataBlob } = userNotification;
                return {
                    ...dataBlob,
                    attachments: attachmentViewModelsWhere({
                        entityId: userNotification.notificationId,
                        entityType: EntityTypeEnum.NOTIFICATION.name,
                    }),
                    startTimeUtc: dataBlob.startDateUtc,
                    updatedBy: dataBlob.alertCreatorId,
                    userNotification,
                };
            })
            .value()
);

export const formOpenSelector = (state) => state.ui.alerts.formOpen;

export const savingSelector = (state) => state.ui.alerts.saving;

export const alertsListLoadingSelector = (state) => state.ui.alerts.loading;

export const errorMsgSelector = (state) => state.ui.alerts.errorMsg;

const bannersReversedSelector = (state) => state.ui.alerts.bannersReversed;

/**
 * Gets the first alert and adds the necessary metadata for displaying as well,
 * returns falsey if no banners to display.
 */
export const bannerForDisplaySelector = createSelector(
    bannersReversedSelector,
    userAlertNotificationsSelector,
    (bannersReversed, userAlertNotifications) => {
        let sortedBanners = _(userAlertNotifications).sortBy('startTimeUtc').take(2);
        if (bannersReversed) {
            sortedBanners = sortedBanners.reverse();
        }
        let banner = sortedBanners.head();
        const rest = sortedBanners.drop(1);
        if (banner) {
            const nextBannerTypeAttrId = rest.size() > 0 ? rest.head().bulletinTypeAttrId : null;
            banner = {
                bulletin: banner,
                bannerTypeAttrId: banner.bulletinTypeAttrId,
                nBanners: _.size(userAlertNotifications),
                nextBannerTypeAttrId,
            };
        }
        return banner;
    }
);

export const hasBannerSelector = createSelector(bannerForDisplaySelector, (banner) => !!banner);

const BASE_PATH = 'current_viewers';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Current Viewers Resource',
        methods: {
            /**
             * Add/update oneself on currently viewed entity
             * and get back the list of currently active viewers on the entity
             *
             * @param {entityType} the type of entity being left
             * @param {entityId} the id of the entity being left
             * @return {Promise<Set<CurrentViewer>>} The set of viewers currently viewing the entity
             */
            getAndUpdateCurrentViewers(entityType, entityId) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${entityType}/${entityId}`,
                    hideLoadingBar: true,
                });
            },
        },
    });
}

/**
 * Resources for CurrentViewers. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import createFormModule from '../../../../core/forms/lib/createFormModule';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: formClientEnum.SUPPORT_SEARCH_SYNC_TYPE,
    fieldViewModels: buildFlatFormFieldViewModels([
        'rmsEntityType',
        'cadEntityType',
        'evidenceEntityType',
        'startDateTime',
    ]),
    convertFromFormModel({
        rmsEntityType,
        cadEntityType,
        evidenceEntityType,
        reportDefinitionId,
        startDateTime,
    } = {}) {
        const entityType = rmsEntityType || cadEntityType || evidenceEntityType;

        let module;
        if (rmsEntityType) {
            module = 'RMS';
        } else if (cadEntityType) {
            module = 'CAD';
        } else if (evidenceEntityType) {
            module = 'EVIDENCE';
        }

        return { entityType, module, reportDefinitionId, startDateTime };
    },
});

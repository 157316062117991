import { Unit } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const cadUnitsModule = createNormalizedModule<Unit>({
    type: 'cadUnits',
});

// ACTIONS
export const storeCadUnits = cadUnitsModule.actionCreators.storeEntities;

// SELECTORS
export const cadUnitsSelector = cadUnitsModule.selectors.entitiesSelector;

// REDUCER
export default cadUnitsModule.reducerConfig;

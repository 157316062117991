// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/dispositions/retentions';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Retention Policies Resource',
        methods: {
            /**
             * Get all retention policies in the department.
             * @return {Promise<Object[]>}
             */
            getRetentionPolicies() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                });
            },

            /**
             * Create or update a retention policy.
             * @param  {Object} retentionPolicy
             * @return {Promise<Object>}
             */
            upsertRetentionPolicy(retentionPolicy) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                    data: retentionPolicy,
                });
            },

            /**
             * Validate an uploaded file of rention policies
             * @param {BulkImportRequest} BulkImportRequest
             * @returns {*}
             */
            validateRententionPoliciesImport(bulkImportRequest) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: `${BASE_PATH}/import/validate`,
                    data: bulkImportRequest,
                });
            },

            /**
             * Import an uploaded file of retention policies after validation
             * @param  {BulkImportRequest} BulkImportRequest
             * @return {Promise<Object>}
             */
            importRetentionPolicies(bulkImportRequest) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/import`,
                    data: bulkImportRequest,
                });
            },

            /**
             * Export all retention policies into a CSV.
             * Return a Mark43File of the CSV export.
             * @return {Promise}
             */
            exportRetentionPolicies() {
                return req({
                    baseUrl: BASE_URL,
                    method: 'GET',
                    url: `${BASE_PATH}/export`,
                });
            },
        },
    });
}

/**
 * Resource for evidence disposition retention policies. See the tech doc for
 *   details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see
 *   https://docs.google.com/document/d/1-Hy_vUp5N5IzygwlKpt12_uC45AEBO7tXzyV3J2AGp8/
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { VehicleModelYear } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

const vehicleModelYearsModule = createLinkModule<VehicleModelYear>({
    type: 'vehicleModelYears',
    keys: ['vehicleModelId', 'yearOfManufacture'],
});

// ACTIONS

// SELECTORS

// REDUCER
export default vehicleModelYearsModule.reducerConfig;

import type { FormValue } from 'dragon-react';

import { useDragonCurrentFormContext } from '../context/dragon-current-form';
import { useDragonSummaryFormValuesContext } from '../context/dragon-form-values';

/**
 * This hook provides access to the summary form value of the form within the current context.
 */
export function useCurrentFormSummaryValue(): FormValue {
    const { configuredFormId, instanceId } = useDragonCurrentFormContext();
    const formValuesMap = useDragonSummaryFormValuesContext();
    const formValueKey = `${configuredFormId}~${instanceId}`;
    const formValue = formValuesMap[formValueKey];
    if (!formValue) {
        throw new Error(
            `Unexpectedly did not find a value for key "${formValueKey}" in summary form values for form "${configuredFormId}~${instanceId}"`
        );
    }

    return formValue;
}

import React from 'react';
import { size } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

import { REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Tooltip } from '../../../core/components/tooltip';
import Button from '../../../../legacy-redux/components/core/Button';
import Column from '../../../core/components/Column';
import CasesSearchForm from '../../core/components/CasesSearchForm';
import { SearchName } from '../../../search/core/components/SearchName';
import {
    unassignedReportsSearch,
    clearSearchForm,
    submitUnassignedReportsSearchForm,
    activeTabKeySelector,
    tabKeys,
} from '../state/ui';
import unassignedReportsSearchForm from '../state/forms/unassignedReportsSearchForm';
import testIds from '../../../../core/testIds';
import UnassignedReportsSearchPersonnelFieldset from './UnassignedReportsSearchPersonnelFieldset';
import UnassignedReportsSearchReportDetailsFieldset from './UnassignedReportsSearchReportDetailsFieldset';
import UnassignedReportsSearchReportDefinitionFieldset from './UnassignedReportsSearchReportDefinitionFieldset';

const strings = componentStrings.cases.unassignedReports.UnassignedReportsSearchForm;

function UnassignedReportsSearchForm({ onSubmit, onReset, query, currentSavedSearch }) {
    const activeTabKey = useSelector(activeTabKeySelector);
    const formModelByPath = useSelector(
        unassignedReportsSearchForm.selectors.formModelByPathSelector
    );
    const formatFieldByName = useSelector(formatFieldByNameSelector);

    return (
        <CasesSearchForm
            {...unassignedReportsSearch.form}
            searchModule={unassignedReportsSearch}
            onSubmit={onSubmit}
            onReset={onReset}
            query={query}
            renderSearchButton={({ onClick, submitText, className }) => {
                // if we are on the `Will Not Investigate` tab,
                // this needs to be disabled until `caseStatusAttrIds`
                // are popluated
                const disabled =
                    activeTabKey === tabKeys.WILL_NOT_INVESTIGATE &&
                    !size(formModelByPath('reportDetails.caseStatusAttrIds'));

                const button = (
                    <Button
                        testId={testIds.CASE_DASHBOARD_APPLY_FILTER_BUTTON}
                        disabled={disabled}
                        className={className}
                        onClick={onClick}
                    >
                        {submitText}
                    </Button>
                );

                return disabled ? (
                    // The tooltip renders as the child, seems that the Button doesn't appropriately spread the props or something causing the tooltip to not display. Wrapping it in a span solves it for now.
                    <Tooltip
                        side="top"
                        hasButtonOffset
                        content={strings.searchTooltip(
                            formatFieldByName(REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID)
                        )}
                    >
                        <span>{button}</span>
                    </Tooltip>
                ) : (
                    button
                );
            }}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
            <Column width={270}>
                <UnassignedReportsSearchPersonnelFieldset />
            </Column>
            <Column width={620} marginRight={0}>
                <UnassignedReportsSearchReportDetailsFieldset />
                <UnassignedReportsSearchReportDefinitionFieldset />
            </Column>
        </CasesSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    currentSavedSearch: unassignedReportsSearch.selectors.currentSavedSearchSelector,
    query: unassignedReportsSearch.selectors.currentQuerySelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    // specify the default sort because it's not relevance sort
    onSubmit: (applicationSettings) =>
        dispatch(
            submitUnassignedReportsSearchForm(
                {
                    recordsWithoutRenEnabled:
                        applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED,
                },
                {
                    sortKey: sortKeyEnum.REPORT_REPORTING_EVENT_NUMBER,
                    sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                }
            )
        ),
    onReset: () => {
        dispatch(clearSearchForm());
        dispatch(unassignedReportsSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(unassignedReportsSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
});

/**
 * Search/filter form on the Unassigned Reports page. In the UI, this form appears
 *   when the filter button is clicked.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onSubmit: ({ onSubmit, applicationSettings }) => () => {
            onSubmit(applicationSettings);
        },
    })
)(UnassignedReportsSearchForm);

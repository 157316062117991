import { map } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { pure, withHandlers } from 'recompose';
import styled from 'styled-components';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import testIds from '../../../core/testIds';
import DropdownMenu from './DropdownMenu';
import Icon, { iconTypes } from './Icon';

const SearchSizeDropdownMenuOption = withHandlers({
    onClick({ onChange, size }) {
        return () => onChange(size);
    },
})(function SearchSizeDropdownMenuOption({ size, onClick }) {
    return (
        <div
            className="dropdown-menu-option"
            onClick={onClick}
            data-test-id={testIds.DROPDOWN_MENU_OPTION}
        >
            {size} per page
        </div>
    );
});

/**
 *
 * @param {object} props
 * @param {array} [props.sizes] An array of options for the number of items to display on a page
 * @param {function} props.onChange Method to handle when a new option is selected
 * @param {string} [props.className]
 * @returns
 */
function _SearchSizeDropdownMenu({ sizes = [25, 50, 75, 100], onChange, className }) {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isUserWithMfaConfigDashboardEnabled = applicationSettings.RMS_USER_WITH_MFA_CONFIG_DASHBOARD_ENABLED;
    return (
        <DropdownMenu
            buttonContent={<Icon color="cobaltBlue" size={22} type={iconTypes.TRIANGLE_DOWN} />}
            width={50}
            className={className}
            data-test-id={testIds.SEARCH_SIZE_DROPDOWN_MENU}
            disabled={isUserWithMfaConfigDashboardEnabled}
        >
            {map(sizes, (size) => (
                <SearchSizeDropdownMenuOption key={size} size={size} onChange={onChange} />
            ))}
        </DropdownMenu>
    );
}

const SearchSizeDropdownMenu = styled(_SearchSizeDropdownMenu)`
    margin: 0;
    vertical-align: middle;
    font-weight: ${(props) => props.theme.fontWeights.regular};
`;

/**
 * Dropdown menu for changing the number of search results to show per page.
 * @param {number[]} [props.sizes]
 * @param {function} props.onChange
 */
export default pure(SearchSizeDropdownMenu);

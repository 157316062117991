import { FieldNote } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'fieldNotes';

const fieldNotesModule = createNormalizedModule<FieldNote>({
    type: NEXUS_STATE_PROP,
});

export const storeFieldNotes = fieldNotesModule.actionCreators.storeEntities;

export const fieldNotesWhereSelector = fieldNotesModule.selectors.entitiesWhereSelector;
export const fieldNoteByIdSelector = fieldNotesModule.selectors.entityByIdSelector;

export default fieldNotesModule.reducerConfig;

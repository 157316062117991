import { isArray, map } from 'lodash';
import { Charge } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'charges';

const chargesModule = createNormalizedModule<Charge>({
    type: NEXUS_STATE_PROP,
});

export const CHARGES_DISPOSITION_INFO_FIELDS = [
    'dispositionAttrId',
    'dispositionDateUtc',
    'collateralBondReceiptNum',
    'collateralBondAmountPaid',
    'juvenileDispositionDateUtc',
    'juvenileDispositionAttrId',
] as const;

// ACTIONS
const storeCharges = chargesModule.actionCreators.storeEntities;
export const storeChargeDispositionInfoFields = (
    charges: Charge | Charge[]
): ClientCommonAction<void> => {
    return (dispatch, getState) => {
        const state = getState();
        if (isArray(charges)) {
            const updatedCharges = map(charges, (charge) => {
                const oldCharge = chargeByIdSelector(state)(charge.id) as Charge;
                const {
                    dispositionAttrId,
                    dispositionDateUtc,
                    collateralBondReceiptNum,
                    collateralBondAmountPaid,
                    juvenileDispositionDateUtc,
                    juvenileDispositionAttrId,
                } = charge;
                return {
                    ...oldCharge,
                    dispositionAttrId,
                    dispositionDateUtc,
                    collateralBondReceiptNum,
                    collateralBondAmountPaid,
                    juvenileDispositionDateUtc,
                    juvenileDispositionAttrId,
                };
            });
            dispatch(storeCharges(updatedCharges));
        } else {
            const oldCharge = chargeByIdSelector(state)(charges.id) as Charge;
            const {
                dispositionAttrId,
                dispositionDateUtc,
                collateralBondReceiptNum,
                collateralBondAmountPaid,
                juvenileDispositionDateUtc,
                juvenileDispositionAttrId,
            } = charges;
            dispatch(
                storeCharges({
                    ...oldCharge,
                    dispositionAttrId,
                    dispositionDateUtc,
                    collateralBondReceiptNum,
                    collateralBondAmountPaid,
                    juvenileDispositionDateUtc,
                    juvenileDispositionAttrId,
                })
            );
        }
    };
};

// SELECTORS
export const chargesSelector = chargesModule.selectors.entitiesSelector;
export const chargesWhereSelector = chargesModule.selectors.entitiesWhereSelector;
export const chargeByIdSelector = chargesModule.selectors.entityByIdSelector;

// REDUCER
export default chargesModule.reducerConfig;

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import testIds from '../../../../../core/testIds';

import { canEditLabelsForReportIdSelector } from '../../state/ui';
import _RoutingLabels from '../RoutingLabels';
import RoutingLabelsModal, { useOpenRoutingLabelsModal } from '../RoutingLabelsModal';
import RecordSidebarSection from './RecordSidebarSection';

type RoutingLabelsSectionProps = {
    labels: Record<string, unknown>[];
    disableEditing: boolean;
    recordId: number;
};

const strings = componentStrings.reports.ReportSidebar;

const RoutingLabels = styled(_RoutingLabels)`
    padding-top: 10px;
`;

const RoutingLabelsSection: React.FC<RoutingLabelsSectionProps> = (props) => {
    const { labels, disableEditing, recordId } = props;

    const canEditLabelsForReportId = useSelector(canEditLabelsForReportIdSelector);
    const openRoutingLabelsModal = useOpenRoutingLabelsModal({
        overlayId: overlayIdEnum.ROUTING_LABELS_FOR_SIDEBAR_MODAL,
        reportId: recordId,
    });

    function createOnEditHandler() {
        if (disableEditing || !canEditLabelsForReportId(recordId)) {
            // edit button hidden in RecordSidebarSection if onEdit == undefined
            return;
        }

        return openRoutingLabelsModal;
    }

    return (
        <RecordSidebarSection
            onEdit={createOnEditHandler()}
            title={strings.labels}
            editButtonTestId={testIds.REPORT_SIDEBAR_LABEL_EDIT}
            sectionTestId={testIds.REPORT_SIDEBAR_LABEL_SECTION}
        >
            <RoutingLabels labels={labels} hideEmptyMessage={false} />
            <RoutingLabelsModal overlayId={overlayIdEnum.ROUTING_LABELS_FOR_SIDEBAR_MODAL} />
        </RecordSidebarSection>
    );
};

export default RoutingLabelsSection;

import { keyBy } from 'lodash';
import * as React from 'react';
import type { prepareDragonDataStructures } from 'dragon-react';

import { ClientFormView } from '../rms-types';

type DragonDataStructures = ReturnType<typeof prepareDragonDataStructures>;
export type DragonConfigDataContextValue = DragonDataStructures & {
    formRuleMap: Record<string, ClientFormView['formRules'][number]>;
    configuredEntityTypeDescriptors: ClientFormView['configuredEntityTypeDescriptors'];
    reportId: number;
};

const DragonConfigDataContext = React.createContext<DragonConfigDataContextValue | undefined>(
    undefined
);

export const DragonConfigDataContextProvider: React.FC<{
    formView: ClientFormView;
    configData: DragonDataStructures;
    reportId: number;
}> = ({ formView, configData, children, reportId }) => {
    const contextValue = React.useMemo(() => {
        const formRuleMap = keyBy(formView.formRules, (formRule) => formRule.configuredRuleId);
        return {
            ...configData,
            formRuleMap,
            configuredEntityTypeDescriptors: formView.configuredEntityTypeDescriptors,
            reportId,
        };
    }, [configData, formView.configuredEntityTypeDescriptors, formView.formRules, reportId]);

    return (
        <DragonConfigDataContext.Provider value={contextValue}>
            {children}
        </DragonConfigDataContext.Provider>
    );
};

export function useDragonConfigDataContext(): DragonConfigDataContextValue {
    const value = React.useContext(DragonConfigDataContext);
    if (!value) {
        throw new Error(
            'Could not find Dragon config data in context. Please ensure that there is a provider in the hierarchy above'
        );
    }
    return value;
}

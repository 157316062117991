import {
    ShortcutCommandEnumType,
    ShortcutContextEnum,
    ShortcutContextEnumType,
} from '@mark43/rms-api';

const {
    DISPATCHER,
    DISPATCHER_EVENT_WINDOW,
    FIRST_RESPONDER,
    DISPATCHER_EVENT_PANEL,
} = ShortcutContextEnum;

type AugmentedShortcutCommandEnumType = ShortcutCommandEnumType | 'SCHEDULED_EVENTS';

const shortcutCommandEnum: Record<
    AugmentedShortcutCommandEnumType,
    {
        name: AugmentedShortcutCommandEnumType;
        value: number;
        validContexts: ShortcutContextEnumType[];
    }
> = {
    NEW_EVENT: {
        name: 'NEW_EVENT',
        value: 1,
        validContexts: [DISPATCHER.name],
    },
    COMMAND_LINE: {
        name: 'COMMAND_LINE',
        value: 2,
        validContexts: [DISPATCHER.name],
    },
    GO_TO_M43_RMS: {
        name: 'GO_TO_M43_RMS',
        value: 3,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
    CAD_SEARCH: {
        name: 'CAD_SEARCH',
        value: 4,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
    SAVED_SEARCHES: {
        name: 'SAVED_SEARCHES',
        value: 5,
        validContexts: [DISPATCHER.name],
    },
    UNIT_STATUS_SEARCH: {
        name: 'UNIT_STATUS_SEARCH',
        value: 6,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
    UNIT_STATUS_SAVED_SEARCHES: {
        name: 'UNIT_STATUS_SAVED_SEARCHES',
        value: 7,
        validContexts: [DISPATCHER.name],
    },
    EVENTS: {
        name: 'EVENTS',
        value: 8,
        validContexts: [DISPATCHER.name],
    },
    AVL_MAP: {
        name: 'AVL_MAP',
        value: 9,
        validContexts: [DISPATCHER.name],
    },
    REPOSSESSION: {
        name: 'REPOSSESSION',
        value: 10,
        validContexts: [DISPATCHER.name],
    },
    CHECK_FOR_UPDATES: {
        name: 'CHECK_FOR_UPDATES',
        value: 11,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
    LOGOUT: {
        name: 'LOGOUT',
        value: 12,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
    DATA_EXCHANGE_OPEN_PANELS: {
        name: 'DATA_EXCHANGE_OPEN_PANELS',
        value: 35,
        validContexts: [DISPATCHER.name],
    },
    EVENT_SAVE: {
        name: 'EVENT_SAVE',
        value: 13,
        validContexts: [DISPATCHER_EVENT_WINDOW.name, DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_EMERGENCY: {
        name: 'EVENT_EMERGENCY',
        value: 14,
        validContexts: [DISPATCHER_EVENT_WINDOW.name, DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_RELATED_DATA: {
        name: 'EVENT_RELATED_DATA',
        value: 15,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_LINKED_EVENTS: {
        name: 'EVENT_LINKED_EVENTS',
        value: 16,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_EVENT_LOG: {
        name: 'EVENT_EVENT_LOG',
        value: 17,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_CALL: {
        name: 'EVENT_CALL',
        value: 18,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_CLEAR: {
        name: 'EVENT_CLEAR',
        value: 19,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_REMOVE_ALL_DATA: {
        name: 'EVENT_REMOVE_ALL_DATA',
        value: 20,
        validContexts: [DISPATCHER_EVENT_WINDOW.name],
    },
    EVENT_ANI_TO_REPORTING_PERSON: {
        name: 'EVENT_ANI_TO_REPORTING_PERSON',
        value: 21,
        validContexts: [DISPATCHER_EVENT_WINDOW.name, DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_ALI_TO_LOCATION: {
        name: 'EVENT_ALI_TO_LOCATION',
        value: 22,
        validContexts: [DISPATCHER_EVENT_WINDOW.name, DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_IMPORT_ANI_ALI_TO_EVENT: {
        name: 'EVENT_IMPORT_ANI_ALI_TO_EVENT',
        value: 23,
        validContexts: [DISPATCHER_EVENT_WINDOW.name, DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_ASSIGN: {
        name: 'EVENT_ASSIGN',
        value: 24,
        validContexts: [DISPATCHER_EVENT_WINDOW.name, DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_EDIT: {
        name: 'EVENT_EDIT',
        value: 25,
        validContexts: [DISPATCHER_EVENT_WINDOW.name],
    },
    EVENT_PDF: {
        name: 'EVENT_PDF',
        value: 26,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    EVENT_GENERATE_REN: {
        name: 'EVENT_GENERATE_REN',
        value: 27,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    // ! shortcuts with validContexts FIRST_RESPONDER only, are not available on CAD Dispatch
    // ! So, these cases on app/modules/shortcuts/epics.js can be removed?
    SELF_INITIATE: {
        name: 'SELF_INITIATE',
        value: 28,
        validContexts: [FIRST_RESPONDER.name],
    },
    UNASSIGNED_EVENTS: {
        name: 'UNASSIGNED_EVENTS',
        value: 29,
        validContexts: [FIRST_RESPONDER.name],
    },
    ASSIGNED_EVENTS: {
        name: 'ASSIGNED_EVENTS',
        value: 30,
        validContexts: [FIRST_RESPONDER.name],
    },
    FIRST_RESPONDER_MAP: {
        name: 'FIRST_RESPONDER_MAP',
        value: 31,
        validContexts: [FIRST_RESPONDER.name],
    },
    UNITS: {
        name: 'UNITS',
        value: 32,
        validContexts: [FIRST_RESPONDER.name],
    },
    EDIT_UNIT: {
        name: 'EDIT_UNIT',
        value: 33,
        validContexts: [FIRST_RESPONDER.name],
    },
    AT_SCENE: {
        name: 'AT_SCENE',
        value: 34,
        validContexts: [FIRST_RESPONDER.name],
    },
    DATA_EXCHANGE_OPEN_FORM_VEHICLE_BY_PLATE: {
        name: 'DATA_EXCHANGE_OPEN_FORM_VEHICLE_BY_PLATE',
        value: 36,
        validContexts: [FIRST_RESPONDER.name],
    },
    DATA_EXCHANGE_OPEN_FORM_PERSON_BY_LICENSE: {
        name: 'DATA_EXCHANGE_OPEN_FORM_PERSON_BY_LICENSE',
        value: 37,
        validContexts: [FIRST_RESPONDER.name],
    },
    DATA_EXCHANGE_OPEN_FORM_PERSON_BY_NAME: {
        name: 'DATA_EXCHANGE_OPEN_FORM_PERSON_BY_NAME',
        value: 38,
        validContexts: [FIRST_RESPONDER.name],
    },
    DATA_EXCHANGE_OPEN_RESULTS: {
        name: 'DATA_EXCHANGE_OPEN_RESULTS',
        value: 39,
        validContexts: [FIRST_RESPONDER.name],
    },
    COMMAND_LINE_COMPACT_TOGGLE: {
        name: 'COMMAND_LINE_COMPACT_TOGGLE',
        value: 40,
        validContexts: [DISPATCHER.name],
    },
    DATA_EXCHANGE_CLEAR_RESULTS: {
        name: 'DATA_EXCHANGE_CLEAR_RESULTS',
        value: 41,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
    LOGON_UNIT: {
        name: 'LOGON_UNIT',
        value: 42,
        validContexts: [DISPATCHER.name],
    },
    EVENT_CAUTIONS_TAB: {
        name: 'EVENT_CAUTIONS_TAB',
        value: 43,
        validContexts: [DISPATCHER_EVENT_PANEL.name],
    },
    CLEAR_ALL_UPDATES_FOR_EVENT: {
        name: 'CLEAR_ALL_UPDATES_FOR_EVENT',
        value: 44,
        validContexts: [DISPATCHER_EVENT_WINDOW.name, DISPATCHER_EVENT_PANEL.name],
    },
    TOGGLE_MESSAGING_PANEL: {
        name: 'TOGGLE_MESSAGING_PANEL',
        value: 45,
        validContexts: [FIRST_RESPONDER.name],
    },
    CURRENT_ASSIGNMENT: {
        name: 'CURRENT_ASSIGNMENT',
        value: 46,
        validContexts: [FIRST_RESPONDER.name],
    },
    UPDATE_UNIT_LOCATION: {
        name: 'UPDATE_UNIT_LOCATION',
        value: 47,
        validContexts: [FIRST_RESPONDER.name],
    },
    CLOSE_PANEL: {
        name: 'CLOSE_PANEL',
        value: 48,
        validContexts: [DISPATCHER.name],
    },
    SCHEDULED_EVENTS: {
        name: 'SCHEDULED_EVENTS',
        value: 49,
        validContexts: [FIRST_RESPONDER.name],
    },
    NOTIFICATION_CENTER: {
        name: 'NOTIFICATION_CENTER',
        value: 50,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
    DEX_UPDATE_TO_EVENT: {
        name: 'DEX_UPDATE_TO_EVENT',
        value: 51,
        validContexts: [DISPATCHER.name, FIRST_RESPONDER.name],
    },
};

export default shortcutCommandEnum;

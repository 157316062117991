import React from 'react';
import styled from 'styled-components';

import { FormattedDate } from '~/client-common/core/dates/components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { PopoutLink } from '../../../core/components/links/Link';
import Row from '../../../core/components/Row';

const strings = componentStrings.warrants.warrant.WarrantLinkedArrestRow;

const ArrestRow = styled(Row)`
    margin-bottom: 10px;
`;

const DepartmentName = styled.span`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
    font-size: var(--arc-fontSizes-md);
    margin-left: 5px;
`;

const ReportInfo = styled.div`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
    font-size: var(--arc-fontSizes-md);
`;

const WarrantLinkedArrestRow = ({ reportShortTitle, departmentName, reportOwnerName }) => {
    const canRead = getViewModelProperties(reportShortTitle).canRead;
    const reportUrl = canRead ? `/reports/${reportShortTitle.reportId}` : null;
    const reportTitle = reportShortTitle.shortTitle;
    const createdDateUtc = reportShortTitle.createdDateUtc;

    return (
        <ArrestRow>
            <PopoutLink to={reportUrl} disabled={!canRead}>
                {reportTitle}
            </PopoutLink>
            {departmentName && (
                <DepartmentName>{strings.departmentName(departmentName)}</DepartmentName>
            )}
            <ReportInfo>
                {strings.reportInfoOwnedBy(reportOwnerName)}
                {', '}
                {strings.created}{' '}
                {
                    <FormattedDate
                        date={createdDateUtc}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                }
            </ReportInfo>
        </ArrestRow>
    );
};

export default WarrantLinkedArrestRow;

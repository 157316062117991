import imageGalleryActionTypes from './types/imageGalleryActionTypes';

export function nextImage() {
    return {
        type: imageGalleryActionTypes.NEXT_IMAGE,
    };
}

export function previousImage() {
    return {
        type: imageGalleryActionTypes.PREVOUS_IMAGE,
    };
}

export function setCurrentIndex(index) {
    return {
        type: imageGalleryActionTypes.SET_CURRENT_INDEX,
        payload: index,
    };
}

export function setImages(images) {
    return {
        type: imageGalleryActionTypes.SET_IMAGES,
        payload: images,
    };
}

export function setEmailsEnabled(emailsEnabled = false) {
    return {
        type: imageGalleryActionTypes.SET_EMAILS_ENABLED,
        payload: emailsEnabled,
    };
}

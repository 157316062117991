import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';

import { first } from 'lodash';
import { createStructuredSelector } from 'reselect';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatCodeByIdSelector } from '~/client-common/core/domain/codes/state/ui';
import {
    ConnectedFormattedUser,
    FORMATS as USER_FORMATS,
} from '~/client-common/core/users/components';
import { warrantDexSubmissionsWhereSelector } from '~/client-common/core/domain/warrant-dex-submissions/state/data';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../legacy-redux/components/summaries/SummaryRowDate';
import { CardSection } from '../../../../legacy-redux/components/core/Card';
import { getPurposeCodeDisplayValue } from '../config';

export default connect(
    createStructuredSelector({
        formatAttributeWithOther: formatAttributeWithOtherSelector,
        warrantDexSubmissionsWhere: warrantDexSubmissionsWhereSelector,
        formatCodeById: formatCodeByIdSelector,
        formatAttributeById: formatAttributeByIdSelector,
    })
)(function WarrantDexSubmissionSummary({
    warrantDexSubmissionsWhere,
    warrantId,
    formatAttributeWithOther,
    formatCodeById,
    formatAttributeById,
}) {
    const warrantDexSubmission = first(warrantDexSubmissionsWhere({ warrantId }));
    return !warrantDexSubmission ? null : (
        <CardSection>
            <SummaryList labelWidth={200} contentWidth={340}>
                <SummaryRow
                    fieldName={fields.WARRANT_DEX_SUBMISSION_INTERNAL_WARRANT_STATUS_ATTR_ID}
                >
                    {formatAttributeWithOther({
                        attributeId: warrantDexSubmission.internalWarrantStatusAttrId,
                        other: warrantDexSubmission.internalWarrantStatusOther,
                    })}
                </SummaryRow>
                <SummaryRow
                    fieldName={fields.WARRANT_DEX_SUBMISSION_ORIGINATING_AGENCY_CASE_NUMBER}
                >
                    {warrantDexSubmission.originatingAgencyCaseNumber}
                </SummaryRow>
                <SummaryRow fieldName={fields.WARRANT_DEX_SUBMISSION_DEX_WARRANT_TYPE_ATTR_ID}>
                    {formatAttributeWithOther({
                        attributeId: warrantDexSubmission.dexWarrantTypeAttrId,
                        other: warrantDexSubmission.dexWarrantTypeOther,
                    })}
                </SummaryRow>
                <SummaryRow fieldName={fields.WARRANT_DEX_SUBMISSION_NCIC_OFFENSE_CODE_ID}>
                    {formatCodeById(warrantDexSubmission.ncicOffenseCodeId)}
                </SummaryRow>
                <SummaryRow fieldName={fields.WARRANT_DEX_SUBMISSION_NCIC_OFFENSE_CODE_DESCRIPTION}>
                    {warrantDexSubmission.ncicOffenseCodeDescription}
                </SummaryRow>
                <SummaryRow fieldName={fields.WARRANT_DEX_SUBMISSION_ORIGINAL_NCIC_OFFENSE_CODE_ID}>
                    {formatCodeById(warrantDexSubmission.originalNcicOffenseCodeId)}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.WARRANT_DEX_SUBMISSION_ATTRIBUTE_TYPE_WARRANT_FELONY_EXTRADITION_EXTRADITION_LIMITATION_ATTR_ID
                    }
                >
                    {warrantDexSubmission.extraditionLimitationAttrType ===
                        AttributeTypeEnum.WARRANT_FELONY_EXTRADITION.name &&
                        formatAttributeById(warrantDexSubmission.extraditionLimitationAttrId)}
                </SummaryRow>
                <SummaryRow
                    fieldName={
                        fields.WARRANT_DEX_SUBMISSION_ATTRIBUTE_TYPE_WARRANT_MISDEMEANOR_EXTRADITION_EXTRADITION_LIMITATION_ATTR_ID
                    }
                >
                    {warrantDexSubmission.extraditionLimitationAttrType ===
                        AttributeTypeEnum.WARRANT_MISDEMEANOR_EXTRADITION.name &&
                        formatAttributeById(warrantDexSubmission.extraditionLimitationAttrId)}
                </SummaryRow>
                <SummaryRow
                    fieldName={fields.WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID}
                >
                    {formatAttributeWithOther({
                        attributeId: warrantDexSubmission.warrantEntryLevelCodeAttrId,
                        other: warrantDexSubmission.warrantEntryLevelCodeOther,
                    })}
                </SummaryRow>
                <SummaryRow fieldName={fields.WARRANT_DEX_SUBMISSION_ENTERED_BY}>
                    {warrantDexSubmission.enteredBy && (
                        <ConnectedFormattedUser
                            userId={warrantDexSubmission.enteredBy}
                            format={USER_FORMATS.FULL_NAME}
                        />
                    )}
                </SummaryRow>
                <SummaryRowDate
                    fieldName={fields.WARRANT_DEX_SUBMISSION_ENTERED_DATE_UTC}
                    date={warrantDexSubmission.enteredDateUtc}
                    format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                />
                <SummaryRow
                    fieldName={fields.WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER}
                >
                    {warrantDexSubmission.regionalMessageSwitchNumber}
                </SummaryRow>
                <SummaryRow fieldName={fields.WARRANT_DEX_SUBMISSION_NCIC_NUMBER}>
                    {warrantDexSubmission.ncicNumber}
                </SummaryRow>
                <SummaryRow fieldName={fields.WARRANT_DEX_SUBMISSION_PURPOSE_CODE}>
                    {getPurposeCodeDisplayValue(warrantDexSubmission.purposeCode)}
                </SummaryRow>
            </SummaryList>
        </CardSection>
    );
});

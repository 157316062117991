import React, { useRef } from 'react';
import { omit } from 'lodash';

import {
    getPlaceName,
} from '~/client-common/core/domain/locations/utils/locationHelpers';
import { Tile } from '../../components/Tile';
import testIds from '../../../../core/testIds';
import { useSubPremiseFormatters } from '../hooks/useSubPremiseFormatters';

const LocationQuickAddPill = ({ location, onClick, isSelected, isDisabled }) => {
    const ref = useRef(null);

    const handleClick = () => {
        if (onClick) {
            onClick({ location, ref });
        }
    };

    const { locationNameFormatsForLocation } = useSubPremiseFormatters();

    const { full: fullAddress } = locationNameFormatsForLocation({
        location: omit(location, 'placeName'),
    });
    const placeName = getPlaceName(location);

    return (
        <Tile
            onClick={handleClick}
            ref={ref}
            testId={testIds.QUICK_ADD_LOCATION_PILL}
            icon="Location"
            title={placeName && `${placeName}`}
            description={fullAddress}
            isDisabled={isDisabled ?? isSelected ?? undefined}
        />
    );
};

export default LocationQuickAddPill;

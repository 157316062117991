import factory from './actionTypesFactory';

export default factory('rensearch', [
    'GET_INFO_FOR_REPORTING_EVENT_NUMBER_START',
    'GET_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS',
    'GET_INFO_FOR_REPORTING_EVENT_NUMBER_FAILURE',
    'DISABLE_REPORTING_EVENT_NUMBER_SEARCH_FORM',
    'CREATE_REPORT_START',
    'CREATE_REPORT_SUCCESS',
    'CREATE_REPORT_FAILURE',
]);

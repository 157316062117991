// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'admin/roleapproval';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Role Approval Resource',
        methods: {
            loadRoleApprovalForRole(roleId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}?roleId=${roleId}`,
                });
            },
            upsertRoleApproval(roleApproval) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${roleApproval.roleId}`,
                    data: roleApproval,
                });
            },
        },
    });
}

/**
 * Resources for RoleApprovals. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { RefContextEnum } from '@mark43/rms-api';
import { get } from 'lodash';

import { createFormConfiguration, createFieldset } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

export const formName = RefContextEnum.FORM_ARREST_CHARGES_STUB_OFFENSE_SIDE_PANEL.name;

// Keep in sync with the form configuration below.
export const offenseLocationDescriptionPath = 'location.description';
export const offensePositionAttrIdPath = 'location.positionAttrId';

export const getFormConfiguration = () =>
    createFormConfiguration({
        offenseCodeId: { fieldName: fields.OFFENSE_OFFENSE_CODE_ID },
        nibrsOffenseCodeId: { fieldName: fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_ID },
        offenseDateUtc: { fieldName: fields.OFFENSE_OFFENSE_DATE_UTC },
        location: createFieldset({
            fields: {
                linkType: {
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_LINK_TYPE,
                },
                positionAttrId: {
                    fieldName:
                        fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_POSITION_ATTR_ID,
                },
                description: {
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_DESCRIPTION,
                },
            },
        }),
        unsavedLocationEntityLink: {},
    });

export const convertFromFormModel = ({ form }) => {
    const {
        offenseCodeId,
        nibrsOffenseCodeId,
        offenseDateUtc,
        location,
        unsavedLocationEntityLink,
    } = form.get();
    const description = get(location, 'description');
    const filteredFormData = filterFormData({
        offense: {
            offenseCodeId,
            offenseDateUtc,
            isOffenseDateUnknown: isUndefinedOrNull(offenseDateUtc),
            nibrsOffenseCodeId,
        },
        locationEntityLink: {
            ...unsavedLocationEntityLink,
            description,
        },
    });
    return filteredFormData;
};

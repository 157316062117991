import {
    AuthenticationRequest,
    AccountSwitcherRequest,
    AuthenticationResource,
} from '@mark43/rms-api';
import httpHeaderEnum from '~/client-common/core/enums/client/httpHeaderEnum';
import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';
import { getAuthToken } from '../../core/auth';

const headers = {
    [httpHeaderEnum.X_SCREEN_RESOLUTION]: `${window.screen.height}x${window.screen.width}`,
};

export default createResource({
    name: 'Auth Resource',
    methods: {
        logout() {
            return req<AuthenticationResource.DeleteSession>({
                method: 'DELETE',
                url: `auth/sessions/${getAuthToken()}/`,
            });
        },
        dryAuthenticate(data: AuthenticationRequest) {
            return req<AuthenticationResource.DryLogin>({
                url: 'auth/dry_authenticate',
                override401Handler: true,
                headers,
                data,
                method: 'POST',
            });
        },
        extendSession() {
            return req<AuthenticationResource.ExtendSession>({
                method: 'GET',
                url: 'auth/session/extend',
            });
        },
        switchDepartments(data: AccountSwitcherRequest) {
            return req<AuthenticationResource.CreateAccountSwitcherCrossLoginToken>({
                method: 'POST',
                url: 'auth/account_switcher/create_token',
                data,
            });
        },
        createCrossLoginToken(subDomain: string) {
            return req<AuthenticationResource.CreateCrossLoginTokenForTargetDepartment>({
                method: 'POST',
                url: `auth/cross_login/create_token/${subDomain}/`,
            });
        },
    },
});

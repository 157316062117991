import { AttributeTypeEnum } from '@mark43/rms-api';
import _, { flatMap, noop, filter } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import {
    compose,
    lifecycle,
    pure,
    setDisplayName,
    setPropTypes,
    withHandlers,
    withProps,
    withPropsOnChange,
} from 'recompose';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { searchStorageLocations } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { elasticStorageLocationOptionsSelector } from '~/client-common/core/domain/elastic-storage-locations/state/ui';
import { attributesWithParentAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { facilitiesSelector } from '~/client-common/core/domain/facilities/state/data';
import { isExpired } from '~/client-common/core/dates/utils/dateHelpers';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';

import { computeAllowedAttributeTypesToLoad } from '../../../attributes/utils/computeAllowedAttributeTypesToLoad';
import { attributeLoadingStateSelectorFactory } from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import { computeLoadableAttributeTypes } from '../../../attributes/utils/computeLoadableAttributeTypes';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import { FACILITY_OPTION_VALUE_IN_STORAGE_LOCATION_SELECT } from '../../../../admin/evidence-facilities/configuration';
import AsyncSelect from './AsyncSelect';

const StorageLocationSelect = compose(
    setDisplayName('StorageLocationSelect'),
    setPropTypes({
        evidenceFacilityGlobalAttrIdFilter: PropTypes.arrayOf(PropTypes.number),
        facilityIdFilter: PropTypes.number,
        storageLocationTypeFilter: PropTypes.arrayOf(PropTypes.string),
        requiredPermissions: PropTypes.arrayOf(PropTypes.string),
    }),
    pure,
    withProps(() => ({ attributeType: AttributeTypeEnum.EVIDENCE_FACILITY.name })),
    connect(
        createStructuredSelector({
            elasticStorageLocationOptions: elasticStorageLocationOptionsSelector,
            attributesWithParentAttributeId: attributesWithParentAttributeIdSelector,
            attributeLoadingState: attributeLoadingStateSelectorFactory(),
            facilities: facilitiesSelector,
            departmentId: currentUserDepartmentIdSelector,
            applicationSettings: applicationSettingsSelector,
        }),
        (dispatch) => ({
            searchStorageLocations: (...args) =>
                dispatch(
                    searchStorageLocations(elasticSearchResource.searchStorageLocations, ...args)
                ),
            loadAttributesForType: (...args) => dispatch(loadAttributesForType(...args)),
        })
    ),
    lifecycle({
        componentDidMount() {
            const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                this.props.attributeLoadingState
            );
            // for now we load all attributes for the specified types, including expired ones
            if (attributeTypesToLoad.length) {
                this.props
                    .loadAttributesForType({ attributeType: attributeTypesToLoad })
                    .catch(noop);
            }
        },
    }),
    withPropsOnChange(
        ['evidenceFacilityGlobalAttrIdFilter', 'attributesWithParentAttributeId'],
        ({ evidenceFacilityGlobalAttrIdFilter, attributesWithParentAttributeId }) => {
            const now = new Date();
            return {
                facilityTypeAttrIds: flatMap(evidenceFacilityGlobalAttrIdFilter, (globalAttrId) =>
                    _(attributesWithParentAttributeId(globalAttrId))
                        .omitBy(({ end }) => isExpired(end, now))
                        .map('id')
                        .value()
                ),
            };
        }
    ),
    withPropsOnChange(
        [
            'storageLocationTypeFilter',
            'elasticStorageLocationOptions',
            'facilityTypeAttrIds',
            'facilityIdFilter',
            'includeFacilityOption',
            'facilities',
            'departmentId',
        ],
        ({
            storageLocationTypeFilter,
            elasticStorageLocationOptions,
            facilityTypeAttrIds,
            facilityIdFilter,
            includeFacilityOption,
            facilities,
            departmentId,
        }) => {
            const options = filter(
                elasticStorageLocationOptions({
                    facilityTypeAttrIds,
                    facilityId: facilityIdFilter,
                    storageLocationTypes: storageLocationTypeFilter,
                    departmentId,
                }),
                (location) =>
                    moment(location.expiredDateUtc).isAfter(moment()) || !location.expiredDateUtc
            );

            if (includeFacilityOption && !!facilityIdFilter) {
                const { locationName } = facilities[facilityIdFilter];
                const facilityOption = {
                    display: locationName,
                    value: FACILITY_OPTION_VALUE_IN_STORAGE_LOCATION_SELECT,
                };
                options.push(facilityOption);
            }
            return { options: sortByNaturalOrder(options, 'display') };
        }
    ),
    withHandlers({
        asyncAction({
            searchStorageLocations,
            isExpired,
            facilityTypeAttrIds,
            facilityIdFilter,
            storageLocationTypeFilter,
            requiredPermissions,
            applicationSettings,
        }) {
            return (query) => {
                const queryObj = {
                    query,
                    isExpired,
                    facilityTypeAttrIds,
                    facilityIds: !!facilityIdFilter ? [facilityIdFilter] : [],
                    isFacility: false,
                    storageLocationTypes: storageLocationTypeFilter,
                };

                const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;

                if (useEvdPerms && requiredPermissions) {
                    queryObj.storageLocationPermissionsQuery = [
                        {
                            operationTypes: requiredPermissions,
                        },
                    ];
                }

                return searchStorageLocations(
                    queryObj,
                    0,
                    100,
                    undefined,
                    undefined,
                    undefined,
                    true
                );
            };
        },
        onFocus(props) {
            const { attributeLoadingState, onFocus, loadAttributesForType } = props;
            return () => {
                // grab all types which aren't yet loaded so that we only load the required subset
                const attributeTypesToLoad = computeLoadableAttributeTypes(attributeLoadingState);

                // for now we load all attributes for the specified types, including expired ones
                if (attributeTypesToLoad.length) {
                    // the empty `catch` is intentional. Due to having to support legacy KO/BB
                    // we have to return a promise which can potentially be rejected
                    loadAttributesForType({ attributeType: attributeTypesToLoad }).catch(noop);
                }

                if (onFocus) {
                    onFocus();
                }
            };
        },
    })
)(AsyncSelect);

/**
 * Typeahead for searching storage locations. Previously cached storage
 *   locations will display as options immediately. To search for facilities as
 *   well as storage locations, use <FacilityStorageLocationSelect> instead.
 * @param {Object}   props Same props as `Select`.
 * @param {boolean}  [props.isExpired] Optionally filter the search results by
 *   whether they are expired or not.
 * @param {string[]} [props.storageLocationTypeFilter] Optionally filter the
 *   search results by these storage location types.
 * @param {number[]} [props.evidenceFacilityGlobalAttrIdFilter] Optional array
 *   to search only for storage locations within facilities that have the given
 *   evidence facility global attribute (internal or external)
 * @param {number}   [props.facilityIdFilter] Optional facility id to search
 *   only for storage locations within the facility.
 * @param {boolean}  [props.includeFacilityOption] If facilityIdFilter is set,
 *   add the facility as an option.
 * @param {OperationTypeEnum[]} [props.requiredPermissions] Optional -- only storage locations
 *   that current user has at least one of these required permissions on are returned
 */
export default StorageLocationSelect;

import { ElasticProperty } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticProperty';
const elasticPropertyModule = createNormalizedModule<ElasticProperty>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const elasticPropertySelector = elasticPropertyModule.selectors.entitiesSelector;

// REDUCER
export default elasticPropertyModule.reducerConfig;

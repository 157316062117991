import { RefContextEnum, EntityTypeEnum } from '@mark43/rms-api';
import { concat, get } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Form, Observer, lifecycleOptions } from 'markformythree';

import React from 'react';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS,
} from '~/client-common/core/domain/offense-codes/constants';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { applyStatuteCodeSetFilterToFlags } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';
import { reportRenByIdSelector } from '~/client-common/core/domain/reports/state/ui';

import { ArbiterMFTOffenseCodeSelect } from '../../../../core/forms/components/selects/OffenseCodeSelect';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import Row from '../../../../core/components/Row';
import offenseCards from '../../state/ui/offenseCards';
import { actuallyDeleteOffense, addNewClientSideOffense } from '../../state/ui/offense';
import { requestRecentPersonsOrOrganizationsForAllReports } from '../../../../../legacy-redux/actions/recentEntitiesActions';
import { ArbiterMFTStatuteCodeSetRadio } from '../StatuteCodeSetRadio';
import { FullIncidentForm } from './FullIncidentForm';

const offenseCodeFlags = concat(
    [],
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS
);

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    offenseCodes: offenseCodesSelector,
    reportRenById: reportRenByIdSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
    deleteIncident: ({ isOffenseTypeChange }) =>
        dispatch(
            actuallyDeleteOffense({
                offenseId: props.incidentId,
                reportId: props.reportId,
                isIncident: true,
                isOffenseTypeChange,
            })
        ),
    addNewOffense: (offenseCodeId) =>
        dispatch(
            addNewClientSideOffense({
                reportId: props.reportId,
                cardModule: offenseCards,
                isIncident: false,
                offenseCodeId,
            })
        ),
    refetchRecentNames: (reportingEventNumber) => {
        dispatch(
            requestRecentPersonsOrOrganizationsForAllReports({
                renForRecents: reportingEventNumber,
                ownerType: EntityTypeEnum.REPORT.name,
                isOrg: false,
            })
        );
        dispatch(
            requestRecentPersonsOrOrganizationsForAllReports({
                renForRecents: reportingEventNumber,
                ownerType: EntityTypeEnum.REPORT.name,
                isOrg: true,
            })
        );
    },
});

class IncidentCardForm extends React.Component {
    constructor(...args) {
        super(...args);

        if (this.props.isClientSideStub) {
            // it seems like MFT cannot currently handle multiple `onChange` handers
            // so we need to manually set the value in our form before triggering the submit
            // to ensure that the offense code value exists.
            this.handleStubOffenseCodeChange = ({ value, form, path }) => {
                form.set(path, value);
                // ensure that we actually have a value, just clicking in and out of
                // the field should not trigger the outside change handlers
                if (value) {
                    this.props.onStubOffenseCodeChange(value);
                }
            };
        }
    }

    renderStubIncidentForm(form) {
        return (
            <CardSection>
                <Row>
                    <ArbiterMFTStatuteCodeSetRadio
                        form={form}
                        offenseCodeIdPath="incident.offenseCodeId"
                    />
                </Row>
                <Row>
                    <Observer
                        subscriptions={{
                            statuteCodeSetFilter: 'statuteCodeSetFilter',
                        }}
                        render={({ statuteCodeSetFilter }) => (
                            <ArbiterMFTOffenseCodeSelect
                                path="incident.offenseCodeId"
                                clearable={false}
                                length="lg"
                                flags={applyStatuteCodeSetFilterToFlags(
                                    statuteCodeSetFilter,
                                    offenseCodeFlags
                                )}
                                statuteCodeSetFilter={statuteCodeSetFilter}
                                onChange={(value) =>
                                    this.handleStubOffenseCodeChange({
                                        value,
                                        form,
                                        path: 'incident.offenseCodeId',
                                    })
                                }
                                disabled={this.props.isSaving}
                                autoFocus={true}
                            />
                        )}
                    />
                </Row>
            </CardSection>
        );
    }

    renderFullIncidentForm(form) {
        return (
            <FullIncidentForm
                form={form}
                formatFieldByName={this.props.formatFieldByName}
                autoFocus={this.props.autoFocus}
                offenseCodeFlags={offenseCodeFlags}
            />
        );
    }

    renderForm = (form) => {
        return this.props.isClientSideStub
            ? this.renderStubIncidentForm(form)
            : this.renderFullIncidentForm(form);
    };

    componentDidMount() {
        const form = this.props.getForm();
        const offenseCodeId = form.get('incident.offenseCodeId');

        this.offenseCodeIdObserveDisposer = form.observe({
            subscriptions: {
                offenseCodeId: 'incident.offenseCodeId',
            },
            callback: ({ offenseCodeId }) => {
                const newOffenseCode = get(this.props.offenseCodes, offenseCodeId);
                // when the user switches from an incident to an offense
                if (newOffenseCode && newOffenseCode.isOffenseType) {
                    this.props
                        .deleteIncident({ isOffenseTypeChange: true })
                        .then(() => {
                            this.props.addNewOffense(offenseCodeId);
                            this.props.refetchRecentNames(
                                this.props.reportRenById(this.props.reportId)
                            );
                        })
                        .catch(() => this.props.onError());
                }
            },
        });

        if (!!offenseCodeId && this.props.isClientSideStub) {
            this.handleStubOffenseCodeChange({
                value: offenseCodeId,
                form,
                path: 'incident.offenseCodeId',
            });
        }
    }

    componentWillUnmount() {
        this.offenseCodeIdObserveDisposer();
    }

    render() {
        return (
            <Form
                name={RefContextEnum.FORM_INCIDENT.name}
                index={this.props.index}
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                getFormRef={this.props.getFormRef}
                render={this.renderForm}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncidentCardForm);

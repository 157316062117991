import _, { parseInt } from 'lodash';
import { createSelector } from 'reselect';

import { casePrintingTemplatesSelector } from '~/client-common/core/domain/case-printing-templates/state/data';
import casePrintingTemplatesAdminForm from '../forms/casePrintingTemplatesAdminForm';

const SELECT_CASE_PRINTING_TEMPLATE_START =
    'case-printing-templates/SELECT_CASE_PRINTING_TEMPLATE_START';
const SELECT_CASE_PRINTING_TEMPLATE_SUCCESS =
    'case-printing-templates/SELECT_CASE_PRINTING_TEMPLATE_SUCCESS';
const SELECT_CASE_PRINTING_TEMPLATE_FAILURE =
    'case-printing-templates/SELECT_CASE_PRINTING_TEMPLATE_FAILURE';
const OPEN_NEW_CASE_PRINTING_TEMPLATE_FORM =
    'case-printing-templates/OPEN_NEW_CASE_PRINTING_TEMPLATE_FORM';
const CLEAR_CASE_PRINTING_TEMPLATE_ADMIN =
    'case-printing-templates/CLEAR_CASE_PRINTING_TEMPLATE_ADMIN';

export const SAVE_CASE_PRINTING_TEMPLATE_START =
    'case-printing-templates/SAVE_CASE_PRINTING_TEMPLATE_START';
export const SAVE_CASE_PRINTING_TEMPLATE_SUCCESS =
    'case-printing-templates/SAVE_CASE_PRINTING_TEMPLATE_SUCCESS';
export const SAVE_CASE_PRINTING_TEMPLATE_FAILURE =
    'case-printing-templates/SAVE_CASE_PRINTING_TEMPLATE_FAILURE';
export const DELETE_CASE_PRINTING_TEMPLATE_START =
    'case-printing-templates/DELETE_CASE_PRINTING_TEMPLATE_START';
export const DELETE_CASE_PRINTING_TEMPLATE_SUCCESS =
    'case-printing-templates/DELETE_CASE_PRINTING_TEMPLATE_SUCCESS';
export const DELETE_CASE_PRINTING_TEMPLATE_FAILURE =
    'case-printing-templates/DELETE_CASE_PRINTING_TEMPLATE_FAILURE';
export const LOAD_CASE_PRINTING_TEMPLATES_START =
    'case-printing-templates/LOAD_CASE_PRINTING_TEMPLATES_START';
export const LOAD_CASE_PRINTING_TEMPLATES_SUCCESS =
    'case-printing-templates/LOAD_CASE_PRINTING_TEMPLATES_SUCCESS';
export const LOAD_CASE_PRINTING_TEMPLATES_FAILURE =
    'case-printing-templates/LOAD_CASE_PRINTING_TEMPLATES_FAILURE';

function selectCasePrintingTemplateStart() {
    return {
        type: SELECT_CASE_PRINTING_TEMPLATE_START,
    };
}

function selectCasePrintingTemplateSuccess(printingTemplateId) {
    return {
        type: SELECT_CASE_PRINTING_TEMPLATE_SUCCESS,
        payload: printingTemplateId,
    };
}

function selectCasePrintingTemplateFailure(err) {
    return {
        type: SELECT_CASE_PRINTING_TEMPLATE_FAILURE,
        payload: err,
    };
}

export function selectCasePrintingTemplate(printingTemplateId) {
    return (dispatch, getState) => {
        dispatch(selectCasePrintingTemplateStart());
        const state = getState();
        const printingTemplate = casePrintingTemplatesSelector(state)[printingTemplateId];
        if (printingTemplate) {
            dispatch(casePrintingTemplatesAdminForm.actionCreators.change(printingTemplate));
            dispatch(selectCasePrintingTemplateSuccess(printingTemplateId));
        } else {
            dispatch(selectCasePrintingTemplateFailure('Failed to get printing template'));
        }
    };
}

export function openNewCasePrintingTemplateForm() {
    return {
        type: OPEN_NEW_CASE_PRINTING_TEMPLATE_FORM,
    };
}

function clearCasePrintingTemplatesAdminForm() {
    return {
        type: CLEAR_CASE_PRINTING_TEMPLATE_ADMIN,
    };
}

export function clearCasePrintingTemplatesAdmin() {
    return (dispatch) => {
        dispatch(casePrintingTemplatesAdminForm.actionCreators.reset());
        return dispatch(clearCasePrintingTemplatesAdminForm());
    };
}

export const casePrintingTemplatesAdminUiSelector = (state) => state.ui.casePrintingTemplatesAdmin;

// list of template configs
export const casePrintingTemplatesListItemsSelector = createSelector(
    casePrintingTemplatesSelector,
    casePrintingTemplatesAdminUiSelector,
    (casePrintingTemplates, ui) =>
        _(casePrintingTemplates)
            .map((template) => {
                return {
                    path: `/support/printing/cases/${template.id}`,
                    title: template.title,
                    key: template.id,
                    selected: template.id === parseInt(ui.selectedCasePrintingTemplateId),
                };
            })
            .sortBy('title')
            .value()
);

export default function casePrintingTemplatesAdminUiReducer(
    state = {
        loadingSelectedCasePrintingTemplate: false,
        loadingCasePrintingTemplates: false,
        loadingCasePrintingTemplatesErrorMessage: null,
        formErrorMessage: null,
        formLoading: false,
        savingCasePrintingTemplateSuccess: false,
        selectedCasePrintingTemplateId: null,
        newCasePrintingTemplate: false,
    },
    action
) {
    switch (action.type) {
        case LOAD_CASE_PRINTING_TEMPLATES_START:
            return {
                ...state,
                loadingCasePrintingTemplates: true,
                loadingCasePrintingTemplatesErrorMessage: null,
            };
        case LOAD_CASE_PRINTING_TEMPLATES_SUCCESS:
            return {
                ...state,
                loadingCasePrintingTemplatesErrorMessage: null,
                loadingCasePrintingTemplates: false,
            };
        case LOAD_CASE_PRINTING_TEMPLATES_FAILURE:
            return {
                ...state,
                loadingCasePrintingTemplatesErrorMessage: action.payload,
                loadingCasePrintingTemplates: false,
            };
        case SAVE_CASE_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                formErrorMessage: action.payload,
                savingCasePrintingTemplateSuccess: false,
            };
        case SAVE_CASE_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                formErrorMessage: null,
                savingCasePrintingTemplateSuccess: true,
            };
        case SELECT_CASE_PRINTING_TEMPLATE_START:
            return {
                ...state,
                loadingSelectedCasePrintingTemplate: true,
                loadingCasePrintingTemplatesErrorMessage: null,
                formErrorMessage: null,
                savingCasePrintingTemplateSuccess: false,
                selectedCasePrintingTemplateId: null,
            };
        case SELECT_CASE_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                loadingSelectedCasePrintingTemplate: false,
                selectedCasePrintingTemplateId: action.payload,
                newCasePrintingTemplate: false,
            };
        case SELECT_CASE_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                loadingCasePrintingTemplatesErrorMessage: action.payload,
                loadingSelectedCasePrintingTemplate: false,
            };
        case OPEN_NEW_CASE_PRINTING_TEMPLATE_FORM:
            return {
                ...state,
                loadingSelectedCasePrintingTemplate: false,
                loadingCasePrintingTemplatesErrorMessage: null,
                formErrorMessage: null,
                savingCasePrintingTemplateSuccess: false,
                selectedCasePrintingTemplateId: null,
                newCasePrintingTemplate: true,
            };
        case CLEAR_CASE_PRINTING_TEMPLATE_ADMIN:
            return {
                ...state,
                newCasePrintingTemplate: false,
                selectedCasePrintingTemplateId: null,
                loadingSelectedCasePrintingTemplate: false,
            };
        default:
            return state;
    }
}

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { currentUserHasAbilitySelector } from '../../core/current-user/state/ui';

import redirectInsufficientPermissions from '../../core/utils/redirectInsufficientPermissions';

export function onEnter() {
    const state = this.getState();
    if (!currentUserHasAbilitySelector(state)(abilitiesEnum.EVIDENCE.ITEM_QUEUE)) {
        this.dispatch(redirectInsufficientPermissions());
    }
}

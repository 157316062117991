import { CsvExportColumn } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import buildCsvExportColumnsResource from '../../resources/csvExportColumnsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const csvExportColumnsModule = createNormalizedModule<CsvExportColumn>({
    type: 'csvExportColumns',
    key: 'id',
});

const LOAD_CSV_EXPORT_COLUMNS_START = 'csv-export-columns/LOAD_CSV_EXPORT_COLUMNS_START';
const LOAD_CSV_EXPORT_COLUMNS_SUCCESS = 'csv-export-columns/LOAD_CSV_EXPORT_COLUMNS_SUCCESS';
export const LOAD_CSV_EXPORT_COLUMNS_FAILURE = 'csv-export-columns/LOAD_CSV_EXPORT_COLUMNS_FAILURE';

function loadCsvExportColumnsStart() {
    return { type: LOAD_CSV_EXPORT_COLUMNS_START };
}
function loadCsvExportColumnsSuccess() {
    return { type: LOAD_CSV_EXPORT_COLUMNS_SUCCESS };
}
function loadCsvExportColumnsFailure(errorMessage: string) {
    return { type: LOAD_CSV_EXPORT_COLUMNS_FAILURE, payload: errorMessage, error: true };
}

const storeCsvExportColumns = csvExportColumnsModule.actionCreators.storeEntities;
export const removeCsvExportColumnsWhere =
    csvExportColumnsModule.actionCreators.deleteEntitiesWhere;

export function loadCsvExportColumns(
    printingTemplateId: number
): ClientCommonAction<Promise<CsvExportColumn[]>> {
    return (dispatch) => {
        const resource = buildCsvExportColumnsResource();
        dispatch(loadCsvExportColumnsStart());

        return resource
            .getCsvExportColumnsForPrintingTemplate(printingTemplateId)
            .then((csvExportColumns: CsvExportColumn[]) => {
                dispatch(storeCsvExportColumns(csvExportColumns));
                dispatch(loadCsvExportColumnsSuccess());
                return csvExportColumns;
            })
            .catch((err: Error) => {
                dispatch(loadCsvExportColumnsFailure(err.message));
                throw err;
            });
    };
}

export const csvExportColumnsSelector = csvExportColumnsModule.selectors.entitiesSelector;

export default csvExportColumnsModule.reducerConfig;

import {
    CompleteClientReportView,
    Offense,
    ReportSupplementView,
    ReportStatusView,
    Report,
    EventDetailBundle,
    SupplementCardPrefillBundle,
    ReportApprovalStatusView,
    EntityTypeEnum,
    LinkTypesEnum,
    ReportRelatedEntities,
    ReportLegacyMetadata,
    Caution,
} from '@mark43/rms-api';
import { attachmentsWhereSelector } from '~/client-common/core/domain/attachments/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { RmsAction } from '../../core/typings/redux';
import { currentReportCardUITitleByTypeSelector } from '../selectors/reportSelectors';

export function clearAllReportUiData() {
    return {
        type: 'reports_CLEAR_ALL_REPORT_UI_DATA',
    } as const;
}

/**
 * This sets whether or not the user can submit the report.
 */
export function setCanSubmit(canSubmit: boolean) {
    return {
        type: 'reports_SET_CAN_SUBMIT',
        payload: canSubmit,
    } as const;
}

export function setReportIsPackaged(isPackaged: boolean) {
    return {
        type: 'reports_SET_REPORT_IS_PACKAGED',
        payload: isPackaged,
    } as const;
}

export function setReportIsUcrValidated(isUcrValidated: boolean) {
    return {
        type: 'reports_SET_REPORT_IS_UCR_VALIDATED',
        payload: isUcrValidated,
    } as const;
}

/**
 * This sets the report status view
 */
export function setReportStatusView(reportStatusView: ReportStatusView) {
    return {
        type: 'reports_SET_REPORT_STATUS_VIEW',
        payload: reportStatusView,
    } as const;
}

/**
 * Add the provided linked reports to state. This state is de-duped by the
 *   `reportId` field.
 */
export function storeLinkedReportIds(linkedReportIds: number[]) {
    return {
        type: 'reports_STORE_LINKED_REPORT_IDS',
        payload: linkedReportIds,
    } as const;
}

/**
 * Add linked warrant ids to ui state
 */
export function setLinkedWarrantIds(linkedWarrantIds: number[]) {
    return {
        type: 'reports_SET_LINKED_WARRANT_IDS',
        payload: linkedWarrantIds,
    } as const;
}

export function storeReports(reports: Report[]) {
    return {
        type: 'reports_STORE_REPORTS',
        payload: reports,
    } as const;
}

/**
 * A set function for adding a report to Redux state when it is available
 *   without having to make a call for the report.
 */
export function setReport(report: Report) {
    return {
        type: 'reports_SET_REPORT',
        payload: {
            report,
        },
    } as const;
}

export function setReportAgencyId(report: Report) {
    return {
        type: 'reports_SET_REPORT_AGENCY_ID',
        payload: report,
    } as const;
}

export function setReportDescription(report: Report) {
    return {
        type: 'reports_SET_REPORT_DESCRIPTION',
        payload: report,
    } as const;
}

function setCardSidebarTitle({ anchor, title }: { anchor: string; title: string }) {
    return {
        type: 'reports_SET_CARD_SIDEBAR_TITLE',
        payload: {
            anchor,
            title,
        },
    } as const;
}

export function setAttachmentCardTitle(entityId: number): RmsAction<void> {
    return (dispatch, getState) => {
        const state = getState();
        const attachments = attachmentsWhereSelector(state)({
            entityId,
            entityType: EntityTypeEnum.REPORT.name,
            linkType: LinkTypesEnum.REPORT_ATTACHMENT,
        });
        const cardTitle = currentReportCardUITitleByTypeSelector(state)(
            reportCardEnum.ATTACHMENTS.id
        );
        dispatch(
            setCardSidebarTitle({
                anchor: 'attachments-card',
                title: `${cardTitle} (${attachments.length})`,
            })
        );
    };
}

export function fetchReport(reportId: number) {
    return {
        type: 'reports_FETCH_REPORT',
        payload: reportId,
    } as const;
}

export interface ReportBundle extends Omit<CompleteClientReportView, 'reportSupplementView'> {
    linkedOffenses: Offense[];
    reportSupplementView: ReportSupplementView | Record<string, never>;
    reportIsNew: boolean;
    canSubmit: boolean;
    canReview: boolean;
    canStaffReview: boolean;
    canReject: boolean;
    canSecondaryReject: boolean;
    linkedReportIds: number[];
    linkedWarrantIds: number[];
    linkedCaseIds: number[];
    isSnapshot: boolean;
    reportLegacyMetadatas: ReportLegacyMetadata[];
    cautions: Caution[];
}

type ReportRelatedEntitiesBundle = Pick<
    ReportRelatedEntities,
    'linkedCaseIds' | 'linkedReportIds' | 'linkedWarrantIds'
>;

export function fetchReportSuccess(completeClientReportView: ReportBundle) {
    return {
        type: 'reports_FETCH_REPORT_SUCCESS',
        payload: completeClientReportView,
    } as const;
}

export function fetchReportRelatedEntitiesSuccess(
    reportRelatedEntities: ReportRelatedEntitiesBundle
) {
    return {
        type: 'reports_FETCH_REPORT_RELATED_ENTITIES_SUCCESS',
        payload: reportRelatedEntities,
    } as const;
}

export function fetchReportFailure(err: string) {
    return {
        type: 'reports_FETCH_REPORT_FAILURE',
        err,
    } as const;
}

export function setLastCreatedReportId(reportId: number | number[]) {
    return {
        type: 'reports_SET_LAST_CREATED_REPORT_ID',
        payload: reportId,
    } as const;
}

export function setEventInfoCardPrefill(eventInfoCardPrefill: {
    prefillModalSaveSelected: boolean;
    eventDetailBundle: EventDetailBundle;
}) {
    return {
        type: 'reports_SET_EVENT_INFO_CARD_PREFILL',
        payload: eventInfoCardPrefill,
    } as const;
}

export function setSupplementInfoCardPrefill(supplementInfoCardPrefill: {
    prefillModalSaveSelected: boolean;
    supplementCardPrefillBundle: SupplementCardPrefillBundle;
}) {
    return {
        type: 'reports_SET_SUPPLEMENT_INFO_CARD_PREFILL',
        payload: supplementInfoCardPrefill,
    } as const;
}

export function reportSubmissionChange(reportStatusView: ReportApprovalStatusView) {
    return {
        type: 'reports_REPORT_SUBMISSION_CHANGE',
        payload: {
            reportStatusView: reportStatusView.reportStatusView,
        },
    } as const;
}

export type ReportUiActions =
    | ReturnType<typeof storeLinkedReportIds>
    | ReturnType<typeof fetchReport>
    | ReturnType<typeof fetchReportRelatedEntitiesSuccess>
    | ReturnType<typeof fetchReportSuccess>
    | ReturnType<typeof fetchReportFailure>
    | ReturnType<typeof setLinkedWarrantIds>
    | ReturnType<typeof setCardSidebarTitle>
    | ReturnType<typeof setCanSubmit>
    | ReturnType<typeof setReportIsPackaged>
    | ReturnType<typeof setReportIsUcrValidated>
    | ReturnType<typeof setReportStatusView>
    | ReturnType<typeof reportSubmissionChange>
    | ReturnType<typeof clearAllReportUiData>
    | ReturnType<typeof setEventInfoCardPrefill>
    | ReturnType<typeof setSupplementInfoCardPrefill>
    | ReturnType<typeof setReport>
    | ReturnType<typeof setReportAgencyId>
    | ReturnType<typeof setReportDescription>
    | ReturnType<typeof setLastCreatedReportId>
    | ReturnType<typeof storeReports>
    // TODO
    // | ReturnType<typeof loadReportHistoryStart>
    // | ReturnType<typeof loadReportHistorySuccess>
    // | ReturnType<typeof loadReportHistoryFailure>
    | { type: 'reports_LEAVE_REPORT' };

import { MissingPerson } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { first } from 'lodash';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'missingPersons';
const missingPersonsModule = createNormalizedModule<MissingPerson>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
const missingPersonsWhereSelector = missingPersonsModule.selectors.entitiesWhereSelector;

// Only 1 `MissingPerson` per report
export const missingPersonsByReportIdSelector = createSelector(
    missingPersonsWhereSelector,
    (missingPersonsWhere) => (reportId: number) => first(missingPersonsWhere({ reportId }))
);

// REDUCER
export default missingPersonsModule.reducerConfig;

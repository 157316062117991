import { AttributeTypeEnum } from '@mark43/rms-api';

import { difference } from 'lodash';
import { computeLoadableAttributeTypes } from './computeLoadableAttributeTypes';

// Vehicle makes and models and firearm makes are so heavy that we do not want
// to auto-load them when an input mounts. These will only
// be loaded when a user focuses the input
export const computeAllowedAttributeTypesToLoad = (attributeLoadingState) =>
    difference(computeLoadableAttributeTypes(attributeLoadingState), [
        AttributeTypeEnum.VEHICLE_MAKE.name,
        AttributeTypeEnum.VEHICLE_MODEL.name,
        AttributeTypeEnum.FIREARM_MAKE.name,
    ]);

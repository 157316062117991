import { combineReducers } from 'redux';

import notificationsDashboardUiReducer from './dashboard/state/ui';
import notificationSettingsUiReducer from './settings/state/ui';
import notificationsNavigationLinkUiReducer from './navigation-link/state/ui';

export default combineReducers({
    dashboard: notificationsDashboardUiReducer,
    settings: notificationSettingsUiReducer,
    navigationLink: notificationsNavigationLinkUiReducer,
});

import { ReportSubmissionAuthor } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'reportSubmissionAuthors';

const reportSubmissionAuthorsModule = createLinkModule<ReportSubmissionAuthor>({
    type: NEXUS_STATE_PROP,
    keys: ['reportId', 'submissionId', 'authorId'],
});

// REDUCER
export default reportSubmissionAuthorsModule.reducerConfig;

import React, { useState, useEffect } from 'react';
import { map, slice, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { FormattedDate } from '~/client-common/core/dates/components';
import { elasticReportViewModelsSelector } from '~/client-common/core/domain/elastic-reports/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Button } from '../../core/components/Button';

import testIds from '../../../core/testIds';
import ApprovalStatusIcon from '../../records/core/components/ApprovalStatusIcon';
import Link from '../../core/components/links/Link';
import { loadActionRequiredReportIds as _loadActionRequiredReportIds } from '../state/data';
import { ACTION_REQUIRED_REPORTS_INTERVAL, MAX_ACTION_REQUIRED_REPORTS } from '../configuration';
import { NoDataBlock, ShowMoreButton, DashboardCard } from './DashboardSection';

import { DashboardRightCardsLoader } from './DashboardLoaders';
import { DashboardTable, DashboardTr, DashboardTd, DashboardTh } from './DashboardTable';

const columnStyles = {
    ren: { minWidth: '10rem' },
    details: { minWidth: '12rem' },
    date: { minWidth: '10rem' },
    status: { minWidth: '4.5rem', flexGrow: '0', textAlign: 'center' },
};

const strings = componentStrings.personalDashboard.actionRequiredReports;

const HeaderCells = withFields([REPORT_REPORTING_EVENT_NUMBER])(function headerRow({
    fieldDisplayNames,
}) {
    return (
        <DashboardTr>
            <DashboardTh style={columnStyles.ren}>
                {fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER}
            </DashboardTh>
            <DashboardTh style={columnStyles.details}>{strings.reportLongTitle}</DashboardTh>
            <DashboardTh style={columnStyles.date}>{strings.updatedDateUtc}</DashboardTh>
            <DashboardTh style={columnStyles.status}>{strings.approvalStatus}</DashboardTh>
        </DashboardTr>
    );
});

function TableRow({ data }) {
    const elasticReport = get(data, 'elasticReport');
    const recordsWithoutRenEnabled = get(data, 'recordsWithoutRenEnabled');
    const reportTitle = recordsWithoutRenEnabled
        ? elasticReport.shortTitle
        : elasticReport.longTitle;

    return (
        <DashboardTr
            key={elasticReport.id}
            isInteractive
            as={Link}
            to={`/reports/${elasticReport.id}`}
        >
            <DashboardTd style={{ ...columnStyles.ren, color: 'var(--arc-colors-brand-default)' }}>
                {elasticReport.reportingEventNumber}
            </DashboardTd>
            <DashboardTd style={columnStyles.details}>{reportTitle}</DashboardTd>
            <DashboardTd style={columnStyles.date}>
                <FormattedDate
                    date={elasticReport.updatedDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />
            </DashboardTd>
            <DashboardTd style={columnStyles.status}>
                <ApprovalStatusIcon approvalStatus={elasticReport.clientApprovalStatus} />
            </DashboardTd>
        </DashboardTr>
    );
}

const ActionRequiredReportsContent = withRouter(({ router }) => {
    const [reportIds, setReportIds] = useState([]);
    const [reportsToShow, setReportsToShow] = useState(ACTION_REQUIRED_REPORTS_INTERVAL);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const elasticReportViewModels = useSelector(elasticReportViewModelsSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const dispatch = useDispatch();
    const loadActionRequiredReportIds = React.useCallback(
        () => dispatch(_loadActionRequiredReportIds()),
        [dispatch]
    );

    useEffect(() => {
        loadActionRequiredReportIds()
            .then((reportIds) => {
                setReportIds(reportIds);
                setLoading(false);
            })
            .catch(() => {
                setError(true);
                setLoading(false);
            });
    }, [loadActionRequiredReportIds, setReportIds]);

    if (loading && reportIds.length === 0) {
        return <DashboardRightCardsLoader />;
    }

    if (error) {
        return <NoDataBlock>{strings.loadError}</NoDataBlock>;
    }

    const displayedReportIds = slice(reportIds, 0, reportsToShow);
    const recordsWithoutRenEnabled = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
    const data = map(displayedReportIds, (reportId) => {
        const elasticReport = elasticReportViewModels[reportId];
        return {
            elasticReport,
            recordsWithoutRenEnabled,
        };
    });

    if (!loading && data.length === 0) {
        return <NoDataBlock>{strings.noResults}</NoDataBlock>;
    }

    return (
        <>
            <DashboardTable
                as="div"
                data={data}
                renderHeader={() => <HeaderCells />}
                renderRow={(data, index) => <TableRow data={data} key={index} />}
            />

            {(displayedReportIds.length < reportIds.length ||
                reportIds.length >= MAX_ACTION_REQUIRED_REPORTS) && (
                <ShowMoreButton
                    isSticky
                    onClick={() => {
                        if (reportsToShow >= MAX_ACTION_REQUIRED_REPORTS) {
                            router.push('/reports');
                            return;
                        }
                        setReportsToShow(reportsToShow + ACTION_REQUIRED_REPORTS_INTERVAL);
                    }}
                >
                    {strings.showMore}
                </ShowMoreButton>
            )}
        </>
    );
});

const ActionRequiredReports = React.forwardRef(
    ({ dragHandleProps, isDragging, ...props }, forwardedRef) => {
        return (
            <DashboardCard
                ref={forwardedRef}
                {...props}
                dragHandleProps={dragHandleProps}
                isDragging={isDragging}
                data-test-id={testIds.PERSONAL_DASHBOARD_ACTION_REQUIRED_REPORTS_SECTION}
                title={strings.title}
                helpText={strings.helpText}
                actions={
                    <Button variant="ghost" isTextTransformNone asLink to="/reports">
                        {strings.viewAll}
                    </Button>
                }
            >
                <ActionRequiredReportsContent />
            </DashboardCard>
        );
    }
);

/**
 * Action Required Reports section on the Personal Dashboard.
 */
export default ActionRequiredReports;

/**
 * Return data attributes for an HTML element that represents a Dragon field or fieldset. These are useful for testing
 * and for implementing DOM behaviors like scrolling.
 *
 * Use the `testId` property as the data attribute `data-test-id`. It is the configuration id for the child node. The id
 * is not unique; multiple fields or fieldsets have the same id when they are inside the same NItems.
 *
 * Use the `data-path` attribute for the full path, which is a unique identifier for any field or fieldset.
 */

const NON_ASCII_REGEXP = /[^\w\s\']|_/g;

const convertDragonLabelToDragonTestId = (label?: string) => {
    if (!label) {
        return undefined;
    }

    return `DRAGON_${label
        .replace(NON_ASCII_REGEXP, '')
        .trim()
        .replaceAll(' ', '_')
        .toUpperCase()}`;
};

export function getDataAttributes({
    label,
    fullyQualifiedPath,
}: {
    label?: string;
    fullyQualifiedPath?: string;
}) {
    return {
        testId: convertDragonLabelToDragonTestId(label),
        'data-path': fullyQualifiedPath ? `DRAGON${fullyQualifiedPath}` : undefined,
    };
}

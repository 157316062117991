import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LookerRunningQuery } from '@mark43/rms-api';
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Status,
    useToast,
    Center,
} from 'arc';
import moment from 'moment';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Button, ButtonGroup } from '../../../core/components/Button';
import LookerResource from '../../dashboard/resources/lookerResource';
import { logError } from '../../../../core/logging';

const strings = componentStrings.analysis.ViewQueriesDrawer;

const QueryStatus = {
    Pending: 'pending',
    Added: 'added',
    Hold: 'hold',
    Running: 'running',
    Complete: 'complete',
    Error: 'error',
    Failure: 'failure',
    Killed: 'killed',
    WaitingResult: '',
};

const ViewQueriesDrawerRow = React.memo<{ query: LookerRunningQuery }>(
    ({ query }) => {
        const [runtime, setRuntime] = useState(
            moment().diff(moment(query.created_at).local(), 'seconds')
        );
        const toast = useToast();

        useEffect(() => {
            let intervalId: number;
            if (query.status === QueryStatus.Running) {
                intervalId = setInterval(() => {
                    setRuntime((prevRuntime) => prevRuntime + 1);
                }, 1000);
            }
            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }, [query.status]);

        const handleStop = () => {
            LookerResource.killRunningQuery(query.query_task_id)
                .then(() => {
                    toast({
                        status: 'default',
                        description: strings.killingQuery.success(query.query_id),
                    });
                })
                .catch((error) => {
                    toast({
                        status: 'error',
                        description: strings.killingQuery.failure(query.query_id),
                    });
                    logError(`Error stopping looker query with id ${query.id} : `, error);
                });
        };

        function renderStatus(queryStatus: string) {
            switch (queryStatus) {
                case QueryStatus.Pending:
                case QueryStatus.Added:
                    return <Status status="attention">Queued</Status>;
                case QueryStatus.Hold:
                    return <Status status="attention">Hold</Status>;
                case QueryStatus.Running:
                    return <Status status="inProgress">Running</Status>;
                case QueryStatus.Complete:
                case QueryStatus.WaitingResult:
                    return <Status status="positive">Done</Status>;
                case QueryStatus.Error:
                case QueryStatus.Failure:
                    return <Status status="negative">Failed</Status>;
                case QueryStatus.Killed:
                    return <Status status="negative">Killed</Status>;
                default:
                    return <Status status="neutral">{queryStatus}</Status>;
            }
        }

        const formattedStartTime = moment.utc(query.created_at).local().format('HH:mm');

        const formattedRuntime =
            runtime === 0
                ? '0s'
                : moment
                      .utc(moment.duration(runtime, 'seconds').asMilliseconds())
                      .format('m[m] s[s]')
                      .replace(/^0m /, '');

        return (
            <Tr key={query.id}>
                <Td>{formattedStartTime}</Td>
                <Td>{renderStatus(query.status)}</Td>
                <Td style={{ textAlign: 'left' }}>{formattedRuntime}</Td>
                <Td>
                    <ButtonGroup>
                        <Button
                            size="sm"
                            colorVariant="dangerous"
                            variant="solid"
                            isDisabled={query.status !== QueryStatus.Running}
                            onClick={handleStop}
                        >
                            {strings.queryStopButton.title}
                        </Button>
                    </ButtonGroup>
                </Td>
            </Tr>
        );
    },
    (prevProps, nextProps) => {
        return prevProps.query.status === nextProps.query.status;
    }
);

const ViewQueriesDrawer: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
    isOpen,
    onClose,
}) => {
    const [queries, setQueries] = useState<LookerRunningQuery[]>([]);

    const fetchAndUpdateQueries = useCallback(() => {
        LookerResource.getRunningQueries()
            .then((fetchedQueries) => {
                setQueries((existingQueries) => {
                    const updatedExistingQueries = existingQueries.map((existingQuery) => {
                        const correspondingFetchedQuery = fetchedQueries.find(
                            (fq) => fq.id === existingQuery.id
                        );

                        if (
                            correspondingFetchedQuery &&
                            correspondingFetchedQuery.status !== existingQuery.status
                        ) {
                            return { ...existingQuery, status: correspondingFetchedQuery.status };
                        } else if (
                            (!correspondingFetchedQuery &&
                                existingQuery.status === QueryStatus.Running) ||
                            existingQuery.status === QueryStatus.WaitingResult
                        ) {
                            LookerResource.getQueryTask(existingQuery.query_task_id)
                                .then((queryTaskDetails) => {
                                    setQueries((prevQueries) =>
                                        prevQueries.map((pq) =>
                                            pq.id === existingQuery.id
                                                ? {
                                                      ...pq,
                                                      status: queryTaskDetails.status,
                                                      runtime: queryTaskDetails.runtime,
                                                  }
                                                : pq
                                        )
                                    );
                                })
                                .catch((error) => {
                                    logError(
                                        `Failed to fetch details for looker query task ${existingQuery.query_task_id}:`,
                                        error
                                    );
                                    setQueries((prevQueries) =>
                                        prevQueries.map((pq) =>
                                            pq.id === existingQuery.id ? { ...pq, status: '' } : pq
                                        )
                                    );
                                });
                            return { ...existingQuery, status: '' };
                        }
                        return correspondingFetchedQuery || existingQuery;
                    });

                    const existingQueryIds = existingQueries.map((q) => q.id);
                    const newQueriesToAdd = fetchedQueries.filter(
                        (nq) => !existingQueryIds.includes(nq.id)
                    );
                    const allQueries = [...updatedExistingQueries, ...newQueriesToAdd];

                    allQueries.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
                    return allQueries.slice(0, 30);
                });
            })
            .catch((error) => {
                logError('Failed to fetch running looker queries:', error);
            });
    }, []);

    useEffect(() => {
        const interval = setInterval(fetchAndUpdateQueries, 5000);
        return () => clearInterval(interval);
    }, [fetchAndUpdateQueries]);

    const sortedQueries = useMemo(() => {
        return [...queries].sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
    }, [queries]);

    return (
        <Drawer
            placement="right"
            size="md"
            variant="inline"
            isOpen={isOpen}
            onClose={onClose}
            onEsc={onClose}
        >
            <DrawerHeader>{strings.title}</DrawerHeader>
            <DrawerBody>
                <TableContainer>
                    {sortedQueries.length > 0 ? (
                        <Table size="md" variant="accented">
                            <Thead>
                                <Tr>
                                    <Th>{strings.table.columnHeaders.time}</Th>
                                    <Th>{strings.table.columnHeaders.status}</Th>
                                    <Th>{strings.table.columnHeaders.runtime}</Th>
                                    <Th>{strings.table.columnHeaders.actions}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {sortedQueries.map((query) => (
                                    <ViewQueriesDrawerRow key={query.id} query={query} />
                                ))}
                            </Tbody>
                        </Table>
                    ) : (
                        <Center>{strings.table.noResults}</Center>
                    )}
                </TableContainer>
            </DrawerBody>
        </Drawer>
    );
};

export default ViewQueriesDrawer;

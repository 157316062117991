import {
    MasterlocationsshapefilesResource,
    MasterlocationsstreetCenterlineResource,
    Shapefile,
    StreetSegmentCreationView,
} from '@mark43/rms-api';
import { req } from '../../../../../lib/ajax';
import { createResource } from '../../../../../lib/resources/Resource';

export const shapefilesResource = createResource({
    name: 'Shapefiles Resource',
    methods: {
        getShapefiles() {
            return req<MasterlocationsshapefilesResource.ListShapefiles>({
                method: 'GET',
                url: 'master/locations/shapefiles',
            });
        },
        getShapefileProperties(shapefileId: string | number) {
            return req<MasterlocationsshapefilesResource.GetPropertyKeyValueMaps>({
                method: 'GET',
                url: `master/locations/shapefiles/${shapefileId}/properties`,
            });
        },
        getShapefileFeatureProperties() {
            return req<MasterlocationsshapefilesResource.GetShapefileFeaturePropertiesForDept>({
                method: 'GET',
                url: 'master/locations/shapefiles/properties',
            });
        },
        createShapefile(fileId?: number) {
            return req<MasterlocationsshapefilesResource.CreateShapefile>({
                method: 'POST',
                url: `master/locations/shapefiles`,
                params: {
                    file_id: fileId,
                },
            });
        },
        upsertShapefile(shapefile: Shapefile) {
            return req<MasterlocationsshapefilesResource.UpdateShapefile>({
                method: 'PUT',
                url: `master/locations/shapefiles/${shapefile.id}/`,
                data: shapefile,
            });
        },
        createStreetCenterLineShapefile(fileId?: number) {
            return req<MasterlocationsshapefilesResource.CreateStreetSegmentShapefileAndReturnSampleFeatures>(
                {
                    method: 'POST',
                    url: 'master/locations/shapefiles/street_segments',
                    params: {
                        file_id: fileId,
                    },
                }
            );
        },
        createCenterLinesFromShapefile(streetSegmentCreationView: StreetSegmentCreationView) {
            return req<MasterlocationsstreetCenterlineResource.ScheduleStreetSegmentProcessingFromShapefile>(
                {
                    method: 'POST',
                    url: 'master/locations/street_centerline/create_from_shapefile',
                    data: streetSegmentCreationView,
                }
            );
        },
    },
});

const resourceGetter = () => shapefilesResource;
export default resourceGetter;

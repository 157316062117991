import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReportHamburgerMenuView } from '@mark43/rms-api';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import { RmsDispatch } from '../../../../core/typings/redux';
import { AsyncDropdownMenu } from '../../../core/components/AsyncDropdownMenu';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { onClickToOnEnter } from '../../../core/utils/eventHelpers';
import { useOpenRoutingLabelsModal } from '../../../records/core/components/RoutingLabelsModal';
import { canEditLabelsForReportIdSelector } from '../../../records/core/state/ui';
import { hideEmbeddedReport } from '../state/ui';
import { useOpenDeleteReportModal } from './header/DeleteReportModal';

const strings = componentStrings.reports.core.ArrestBlockDropdownMenu;

type ArrestBlockDropdownMenuProps = {
    reportId: number;
    dropdownFocusRef: React.MutableRefObject<HTMLElement | null>;
};

const ArrestBlockDropdownMenu: React.FC<ArrestBlockDropdownMenuProps> = (props) => {
    const dispatch: RmsDispatch = useDispatch();
    const { reportId, dropdownFocusRef } = props;

    const selectedDropdownRef = useRef<HTMLElement>(null);

    const separateReports = React.useCallback(() => {
        // open the arrest report in a new tab
        window.open(`#/reports/${reportId}`, '_blank');
        // hide the arrest block from the current report
        dispatch(hideEmbeddedReport(reportId));
    }, [reportId, dispatch]);
    const canEditLabelsForReportId = useSelector(canEditLabelsForReportIdSelector);

    const fetchOptions = useCallback<() => Promise<ReportHamburgerMenuView>>(
        () => getReportResource().getHamburgerMenuView(reportId),
        [reportId]
    );

    const openRoutingLabelsModal = useOpenRoutingLabelsModal({
        overlayId: overlayIdEnum.ROUTING_LABELS_FOR_EMBEDDED_ARREST_MODAL,
        reportId,
    });
    const openLabelsModal = useCallback(() => {
        openRoutingLabelsModal();
        // 1 ROUTING_LABELS_FOR_EMBEDDED_ARREST_MODAL shared between 1 or more arrest blocks.
        // set focus ref to proper dropdown element after opening modal.
        dropdownFocusRef.current = selectedDropdownRef.current;
    }, [openRoutingLabelsModal, dropdownFocusRef]);

    const openDeleteReportModal = useOpenDeleteReportModal({
        overlayId: overlayIdEnum.DELETE_REPORT_FOR_EMBEDDED_REPORT_MODAL,
        reportId,
    });

    return (
        <AsyncDropdownMenu
            buttonContent={<Icon type={iconTypes.MORE_ACTIONS} color="cobaltBlue" size={16} />}
            fetchOptions={fetchOptions}
            innerRef={selectedDropdownRef}
            mapResultOptions={(options, closeMenu) => {
                function openDeleteModal() {
                    const isPurge = !options.canDeleteUnapproved && options.canDeleteApproved;
                    openDeleteReportModal(isPurge);
                    // 1 DELETE_REPORT_FOR_EMBEDDED_REPORT_MODAL shared between 1 or more arrest blocks.
                    // set focus ref to proper dropdown element after opening modal.
                    dropdownFocusRef.current = selectedDropdownRef.current;
                }

                return (
                    <>
                        <div
                            className="dropdown-menu-option"
                            onClick={separateReports}
                            onKeyDown={onClickToOnEnter(() => {
                                separateReports();
                                closeMenu();
                            })}
                            tabIndex={0}
                            data-test-id={testIds.ARREST_BLOCK_VIEW_REPORT_SEPARATELY}
                        >
                            {strings.dropdownOptions.separateReports}
                        </div>
                        {canEditLabelsForReportId(reportId) && (
                            <div
                                className="dropdown-menu-option"
                                onClick={openLabelsModal}
                                onKeyDown={onClickToOnEnter(() => {
                                    openLabelsModal();
                                    closeMenu();
                                })}
                                tabIndex={0}
                                data-test-id={testIds.ARREST_BLOCK_EDIT_LABELS}
                            >
                                {strings.dropdownOptions.editLabels}
                            </div>
                        )}
                        {options.canDelete && (
                            <div
                                className="dropdown-menu-option"
                                tabIndex={0}
                                onClick={openDeleteModal}
                                onKeyDown={onClickToOnEnter(() => {
                                    openDeleteModal();
                                    closeMenu();
                                })}
                                data-test-id={testIds.ARREST_BLOCK_DELETE_REPORT}
                            >
                                {strings.dropdownOptions.deleteReport}
                            </div>
                        )}
                    </>
                );
            }}
            width={200}
            testId={testIds.ARREST_BLOCK_DROPDOWN_MENU}
        />
    );
};

export default ArrestBlockDropdownMenu;

export type ItemType = 'VEHICLE' | 'FIREARM' | 'ITEM_PROFILE';
type ItemModifiedEvent = {
    type: 'ITEMS_MODIFIED';
    payload: {
        itemType: ItemType;
        itemIds: number[];
    };
};

type ItemRemovedEvent = {
    type: 'ITEM_REMOVED';
    payload: {
        itemType: ItemType;
        itemId: number;
    };
};

type DragonBroadcastEvent = ItemModifiedEvent | ItemRemovedEvent;

const CHANNEL = 'dragon-events';
const broadcaster = new BroadcastChannel(CHANNEL);
const receiver = new BroadcastChannel(CHANNEL);

/**
 * Used to publish events which are relevant for dragon forms. This is not so much about dragon-internal
 * communication as it is about external dragon consumers letting dragon know that an entity of interest
 * has changed. These events can then be used by dragon to update its forms if necessary, e.g. by
 * clearing out values or fetching new options for selects.
 */
export function publish(event: DragonBroadcastEvent): void {
    broadcaster.postMessage(event);
}

/**
 * Subcribes consumers to a stream of dragon broadcast events representing noteworthy
 * changes outside of dragon.
 */
export function subscribe(callback: (action: DragonBroadcastEvent) => void): () => void {
    const listener = (event: MessageEvent<DragonBroadcastEvent>) => callback(event.data);
    receiver.addEventListener('message', listener);
    return () => receiver.removeEventListener('message', listener);
}

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Modal from '../../../../legacy-redux/components/core/Modal';

const strings = componentStrings.search.CadTicketDoesNotRequireReportModal;

const context = {
    name: boxEnum.CAD_TICKET_DOES_NOT_REQUIRE_REPORT_MODAL,
};

export default connect(
    createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
    })
)(function CadTicketDoesNotRequireReportModal({ formatFieldByName }) {
    return (
        <Modal context={context} title={strings.title} cancelText="">
            {strings.text(formatFieldByName(REPORT_REPORTING_EVENT_NUMBER))}
        </Modal>
    );
});

/* eslint-disable camelcase */
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Offense Codes Resource',
        methods: {
            searchForRipaOffenseCodes({ q, size, from }) {
                return req({
                    method: 'GET',
                    url: 'ripa_offense_codes',
                    params: {
                        q,
                        size,
                        from,
                    },
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import _ from 'lodash';
import { createSelector } from 'reselect';
import { WarrantStatus } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getWarrantsResource from '../../../warrants/resources/warrantsResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'warrantStatuses';

const warrantStatusesModule = createNormalizedModule<WarrantStatus>({
    type: 'warrantStatuses',
});

// ACTIONS

// SELECTORS

const warrantStatusesWhereSelector = warrantStatusesModule.selectors.entitiesWhereSelector;

export const mostRecentWarrantStatusByWarrantIdSelector = createSelector(
    warrantStatusesWhereSelector,
    (warrantStatusesWhere) => (warrantId: number) => {
        return _(warrantStatusesWhere({ warrantId })).sortBy('updatedDateUtc').reverse().head();
    }
);

// REDUCER
export default warrantStatusesModule.reducerConfig;

// RESOURCE ACTIONS
const UPDATE_WARRANT_STATUS_START = 'warrant-statuses/UPDATE_WARRANT_STATUS_START';
const UPDATE_WARRANT_STATUS_SUCCESS = 'warrant-statuses/UPDATE_WARRANT_STATUS_SUCCESS';
const UPDATE_WARRANT_STATUS_FAILURE = 'warrant-statuses/UPDATE_WARRANT_STATUS_FAILURE';

function updateWarrantStatusStart() {
    return {
        type: UPDATE_WARRANT_STATUS_START,
    };
}
function updateWarrantStatusSuccess(warrantStatusLocation: WarrantStatus) {
    return {
        type: UPDATE_WARRANT_STATUS_SUCCESS,
        payload: warrantStatusLocation,
    };
}
function updateWarrantStatusFailure(errorMessage: string) {
    return {
        type: UPDATE_WARRANT_STATUS_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Create or update
 */
export function updateWarrantStatus(
    warrantId: number,
    warrantStatus: WarrantStatus
): ClientCommonAction<Promise<WarrantStatus | void>> {
    if (warrantStatus === undefined || !warrantStatus.warrantStatusAttrId) {
        return () => Promise.resolve();
    }

    const warrantsResource = getWarrantsResource();
    warrantStatus = {
        ...warrantStatus,
        warrantId,
    };
    return function (dispatch) {
        dispatch(updateWarrantStatusStart());

        return warrantsResource
            .updateWarrantStatusByWarrantId(warrantId, warrantStatus)
            .then((warrantStatus: WarrantStatus) => {
                dispatch(
                    warrantStatusesModule.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [warrantStatus],
                        },
                        updateWarrantStatusSuccess(warrantStatus)
                    )
                );
                return warrantStatus;
            })
            .catch((err: Error) => {
                dispatch(updateWarrantStatusFailure(err.message));
                throw err;
            });
    };
}

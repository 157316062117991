import { RipaSubjectOffenseCode, StopAnonymousSubject } from '@mark43/rms-api';
import { filter } from 'lodash';

export const filterRipaSubjectOffenseCodes = ({
    ripaSubjectOffenseCodes,
    stopAnonymousSubject,
}: {
    ripaSubjectOffenseCodes: RipaSubjectOffenseCode[];
    stopAnonymousSubject: StopAnonymousSubject;
}): RipaSubjectOffenseCode[] => {
    return filter(ripaSubjectOffenseCodes, {
        anonymousSubjectId: stopAnonymousSubject.id,
    });
};

import React from 'react';
import styled from 'styled-components';
import { Text } from 'arc';
import { TestId } from '../../../core/testIds';

export const Header = styled(Text)`
    border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
`;

const ActionButtonWrapper = styled.span`
    float: right;
`;

const _Subsection: React.FC<{
    title: React.ReactNode;
    className?: string;
    actionButton?: React.ReactNode;
    testId?: TestId;
}> = ({ title, children, className, actionButton, testId }) => {
    return (
        <div className={className} data-test-id={testId}>
            <Header fontWeight="semibold" variant="bodyMd">
                {title}
                {actionButton && <ActionButtonWrapper>{actionButton}</ActionButtonWrapper>}
            </Header>
            {children}
        </div>
    );
};

const Subsection = styled(_Subsection)`
    padding-bottom: 30px;

    &:last-child {
        padding-bottom: 0;
    }
`;

export default Subsection;

import _, { map, isArray, compact } from 'lodash';
import { createSelector } from 'reselect';
import { ripaOffenseCodesSelector } from '../data';
import { formatRipaOffenseCodesDisplayValue } from '../../utils/ripaOffenseCodesHelpers';
import { isUndefinedOrNull } from '../../../../../helpers/logicHelpers';

export const ripaOffenseCodeOptionsSelector = createSelector(
    ripaOffenseCodesSelector,
    (ripaOffenseCodes) => {
        return map(ripaOffenseCodes, (ripaOffenseCode) => {
            const { id } = ripaOffenseCode;
            return {
                display: formatRipaOffenseCodesDisplayValue(ripaOffenseCode),
                value: id,
            };
        });
    }
);

export const formatRipaOffenseCodeByIdSelector = createSelector(
    ripaOffenseCodesSelector,
    (ripaOffenseCodes) => (id: number | number[]) => {
        const idsAsArray = isArray(id) ? compact(id) : compact([id]);
        return _(idsAsArray)
            .map((idInArray) => {
                const ripaOffenseCode = ripaOffenseCodes[idInArray];
                if (isUndefinedOrNull(ripaOffenseCode)) {
                    return undefined;
                }
                return formatRipaOffenseCodesDisplayValue(ripaOffenseCode);
            })
            .sortBy()
            .compact()
            .join(', ');
    }
);

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadBoloAuditAdminFormFieldViewModels = {
    cadBoloAudit: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadBoloAudit',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'beginTimeUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endTimeUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'userIds',
            },
            {
                key: 'keywords',
            },
            {
                unitIds: 'unitIds',
            },
            {
                dispatchAreaIds: 'dispatchAreaIds',
            },
        ]),
    },
};

function convertToFormModel({ cadBoloAudit = {} }) {
    return {
        cadBoloAudit: {
            ...cadBoloAudit,
        },
    };
}

function convertFromFormModel({ cadBoloAudit = {} }) {
    return { cadBoloAudit };
}

/**
 * Module of the cad units admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CAD_BOLO_AUDIT_ADMIN,
    fieldViewModels: cadBoloAuditAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

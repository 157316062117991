import globalAttributes from '../../../legacy-constants/globalAttributes';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';

const { itemType } = globalAttributes;

/**
 * Given an item type attribute id, return the string.
 * Used to generate the object key for an item profile.
 */
export function itemTypeToKey(itemTypeAttrId: number) {
    switch (itemTypeAttrId) {
        case itemType.firearm:
            return 'firearm';
        case itemType.vehicle:
            return 'vehicle';
        case itemType.alcohol:
        case itemType.drugs:
        case itemType.item:
        default:
            return 'item';
    }
}

/**
 * Format a firearm for display
 */
export const formatTitleForFirearm = ({
    description,
    firearmMake,
    itemCategory,
    atfManufacturer,
}: {
    description: string;
    firearmMake: string;
    itemCategory: string;
    atfManufacturer: string;
}) => joinTruthyValues([itemCategory, atfManufacturer || firearmMake || description], ' - ');

/**
 * Format a default item for display
 */
export const formatTitleForDefaultItem = ({
    description,
    itemCategory,
}: {
    description: string;
    itemCategory: string;
}) => joinTruthyValues([itemCategory, description], ' - ');

/**
 * Determine if a given itemProfile is a vehicle (or property)
 */
export const itemProfileIsVehicle = ({ itemTypeAttrId }: { itemTypeAttrId?: number } = {}) => {
    return (
        itemTypeAttrId === globalAttributes.itemType.vehicle ||
        itemTypeAttrId === globalAttributes.itemType.bus
    );
};

export function buildItemProfileLink(
    itemProfile: { masterItemId?: number; itemTypeAttrId?: number } = {}
) {
    const { masterItemId } = itemProfile;
    return `/profiles/${
        itemProfileIsVehicle(itemProfile) ? 'vehicles' : 'property'
    }/${masterItemId}`;
}

import factory from './actionTypesFactory';

export default factory('locationaliasesadmin', [
    'LOAD_ALIASES_START',
    'LOAD_ALIASES_SUCCESS',
    'LOAD_ALIASES_FAILURE',
    'SELECT_ALIAS_START',
    'SELECT_ALIAS_SUCCESS',
    'SELECT_ALIAS_FAILURE',
    'OPEN_LOCATION_SIDE_PANEL',
    'OPEN_NEW_ALIAS_FORM_START',
    'OPEN_NEW_ALIAS_FORM_SUCCESS',
    'OPEN_NEW_ALIAS_FORM_FAILURE',
    'SAVE_ALIAS_START',
    'SAVE_ALIAS_SUCCESS',
    'SAVE_ALIAS_FAILURE',
    'DELETE_ALIAS_START',
    'DELETE_ALIAS_SUCCESS',
    'DELETE_ALIAS_FAILURE',
    'PURGE_ALIASES_START',
    'PURGE_ALIASES_SUCCESS',
    'PURGE_ALIASES_FAILURE',
]);

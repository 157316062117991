import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { createStructuredSelector } from 'reselect';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { elasticAttributeDetailsSelector } from '~/client-common/core/domain/elastic-attribute-details/state/data';
import { createPersonsResultsSelector } from '~/client-common/core/domain/elastic-persons/state/ui';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import createSearchModule from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';

import advancedSearchPersonsForm, {
    convertAdvancedSearchPersonsFormModelToFilterGroups,
    convertAdvancedSearchPersonsElasticQueryToFormModel,
} from '../forms/advancedSearchPersonsForm';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';
import recursivelyConvertElasticLocationQueries from '../../../core/utils/recursivelyConvertElasticLocationQueries';
import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
function convertAdvancedSearchPersonsElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    const elasticQueryTransformed = recursivelyConvertElasticLocationQueries(elasticQuery, {
        legacyQueryProp: 'involvedLocations',
        newQueryProp: 'locations',
    });

    return convertAdvancedSearchPersonsFormModelToFilterGroups(
        convertAdvancedSearchPersonsElasticQueryToFormModel(
            elasticQueryTransformed,
            undefined,
            boundSelectors
        ),
        formatFieldValue,
        boundSelectors
    );
}

/**
 * Redux module for advanced search persons.
 * @type {Object}
 */
export default createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.PERSON.name,
    baseUiSelector: (state) => state.ui.advancedSearch.persons,
    form: advancedSearchPersonsForm,
    elasticQueryToFilterGroups: convertAdvancedSearchPersonsElasticQueryToFilterGroups,
    resourceMethod: elasticSearchResource.searchPersons,
    resultsContainerClassName: 'persons-results-container',
    createResultsViewModelsSelector: createPersonsResultsSelector,
    searchResultToRoutePath: ({ id }) => `/profiles/persons/${id}`,
    selectorToBind: createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
        applicationSettings: applicationSettingsSelector,
        elasticAttributeDetails: elasticAttributeDetailsSelector,
    }),
    transformElasticQueryBeforeSearchSelector: (state) => (elasticQuery) => {
        const transformIds = transformElasticQueryIdsToDisplayValuesSelector(state);
        const queryWithTransformedIds = transformIds(elasticQuery);
        return recursivelyConvertElasticLocationQueries(queryWithTransformedIds, {
            legacyQueryProp: 'locations',
            newQueryProp: 'involvedLocations',
        });
    },
});

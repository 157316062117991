// **
//  * Returns years of experience officer has with rounding logic
//  */
export default function addYOEAndHiredDate(previousExperience: number, hiredDate: string): number {
    const formattedHiredDate = new Date(hiredDate);
    const now = new Date();
    const yearHired = hiredDate ? formattedHiredDate.getFullYear() : now.getFullYear();
    const yearsAtDepartment = now.getFullYear() - yearHired;
    previousExperience = previousExperience ?? 0;
    return previousExperience + yearsAtDepartment;
}

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Department Setup Resource',
    methods: {
        createDepartment(creationRequest) {
            return req({
                method: 'POST',
                url: 'setup/department',
                data: creationRequest,
            });
        },
        getDefaultDepartmentProfiles() {
            return req({
                method: 'GET',
                url: 'setup/department/defaults',
            });
        },
        checkDepartmentSetupProgress(newDeptId) {
            return req({
                method: 'GET',
                url: `setup/department/${newDeptId}/record`,
            });
        },
    },
});

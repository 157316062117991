import { map, filter } from 'lodash';
import { createSelector } from 'reselect';
import { warrantAttributesSelector } from '../data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';

const warrantAttributeViewModelsSelector = createSelector(
    warrantAttributesSelector,
    formatAttributeByIdSelector,
    (warrantAttributes, formatAttributeById) => {
        const viewModel = buildViewModel({
            mappers: [
                ({ attributeId }) => ({
                    attributeId: formatAttributeById(attributeId),
                }),
            ],
        });

        return map(warrantAttributes, viewModel);
    }
);

export const warrantAttributeViewModelsWhereSelector = createSelector(
    warrantAttributeViewModelsSelector,
    (warrantAttributeViewModels) => (predicate) => filter(warrantAttributeViewModels, predicate)
);

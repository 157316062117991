import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { RootCrashDiagramActions } from './reducer';
import { undoAction, redoAction } from './reducer/history/actions';
import { CrashDiagramHistoryState } from './reducer/history';

type CrashDiagramHistoryContext = {
    canPerformUndoAction: boolean;
    canPerformRedoAction: boolean;
    undo: () => void;
    redo: () => void;
};

const CrashDiagramHistoryContext = createContext<CrashDiagramHistoryContext | undefined>(undefined);

export function useCrashDiagramHistory(): CrashDiagramHistoryContext {
    const context = useContext(CrashDiagramHistoryContext);

    if (context === undefined) {
        throw new Error(
            'Crash Diagram History Context must be inside a CrashDiagramProvider with a value'
        );
    }
    return context;
}

type CrashDiagramHistoryProviderProps = {
    state: CrashDiagramHistoryState;
    dispatch: React.Dispatch<RootCrashDiagramActions>;
};

export const CrashDiagramHistoryProvider: React.FC<CrashDiagramHistoryProviderProps> = ({
    state,
    dispatch,
    children,
}) => {
    const undo = useCallback(() => {
        dispatch(undoAction());
    }, [dispatch]);

    const redo = useCallback(() => {
        dispatch(redoAction());
    }, [dispatch]);

    const value = useMemo(() => {
        return {
            undo,
            redo,
            canPerformUndoAction:
                state.userActions.length > 0 &&
                state.currentPosition > 0 &&
                state.currentPosition <= state.userActions.length,
            canPerformRedoAction:
                state.userActions.length > 0 && state.currentPosition < state.userActions.length,
        };
    }, [undo, redo, state.userActions, state.currentPosition]);

    return (
        <CrashDiagramHistoryContext.Provider value={value}>
            {children}
        </CrashDiagramHistoryContext.Provider>
    );
};

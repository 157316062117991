import React from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import { cssVar, Flex } from 'arc';

import { FormattedDate } from '~/client-common/core/dates/components';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import testIds from '../../../../core/testIds';
import CaseRowCheckbox from '../../../cases/core/components/CaseRowCheckbox';
import Icon from '../../../core/components/Icon';
import TruncatedText from '../../../core/components/TruncatedText';
import { CustomLink, PopoutLink } from '../../../core/components/links/Link';
import { BodyMediumText } from '../../../core/components/typography';
import { fileIconTypeForFileCategory } from '../../files/helpers';
import {
    AttachmentCell,
    AttachmentRow,
    NameCellWrapper,
    NameWrapper,
    UploadDateText,
} from './AttachmentFolderRowComponents';
import AttachmentLink from './AttachmentLink';
import { defaultColumns } from './AttachmentFolderRow';
import { AttachmentDownloadButton, AttachmentEmailButton } from './AttachmentActionButtons';

const DisplayNameLink = styled(CustomLink)`
    font-weight: ${(props) => props.theme.fontWeights.regular};
    text-decoration: none;
`;

const DescriptionText = styled(BodyMediumText)`
    color: ${cssVar('arc.colors.text.tertiary')};
    font-size: ${cssVar('arc.fontSizes.xs')};
    padding-top: 5px;
    white-space: pre-line;
`;

const FileTypeText = styled.div`
    text-transform: capitalize;
`;

function _AttachmentRow({
    attachment,
    className,
    onClickForLightBox,
    onDownload,
    onEmail = undefined,
    tableWidths,
    columns = defaultColumns,
    handleRowSelect,
    isRowSelected,
    isGhosting,
    emailsEnabled = false,
    hideDownloadButton = false,
}) {
    const {
        caseId,
        displayName,
        source,
        sourceDescription,
        linkTo,
        createdDateUtc,
        createdBy,
        attachmentId,
        displayType,
        entityType,
        entityId,
    } = attachment;
    const { description } = getAttachmentFile(attachment);
    const iconType = fileIconTypeForFileCategory(displayType);

    return (
        <AttachmentRow
            className={className}
            isRowSelected={isRowSelected}
            isGhosting={isGhosting}
            data-test-id={testIds.CASE_ATTACHMENT_ROW}
        >
            <FeatureFlagged flag="RMS_CASE_FOLDERING_ENABLED">
                {columns.includes('checkbox') && (
                    <AttachmentCell width={tableWidths.checkbox}>
                        <CaseRowCheckbox
                            value={isRowSelected}
                            onChange={() =>
                                handleRowSelect(
                                    attachmentId,
                                    isRowSelected,
                                    false,
                                    true,
                                    EntityTypeEnum.ATTACHMENT.name
                                )
                            }
                            entityType={EntityTypeEnum.ATTACHMENT.name}
                        />
                    </AttachmentCell>
                )}
            </FeatureFlagged>

            {columns.includes('displayName') && (
                <AttachmentCell width={tableWidths.displayName}>
                    <DisplayNameLink
                        onClick={onClickForLightBox}
                        data-test-id={testIds.ATTACHMENT_ROW_FILENAME_LINK}
                    >
                        <NameCellWrapper>
                            <Icon className="file-icon" type={iconType} color="cobaltBlue" />
                            <NameWrapper>{displayName}</NameWrapper>
                        </NameCellWrapper>
                    </DisplayNameLink>
                    <DescriptionText data-test-id={testIds.ATTACHMENT_ROW_DESCRIPTION}>
                        <TruncatedText text={description} />
                    </DescriptionText>
                </AttachmentCell>
            )}
            {columns.includes('type') && (
                <AttachmentCell width={tableWidths.type}>
                    <FileTypeText>{displayType.toLowerCase()}</FileTypeText>
                </AttachmentCell>
            )}
            {columns.includes('createdDateUtc') && (
                <AttachmentCell width={tableWidths.createdDateUtc}>
                    <UploadDateText>
                        <FormattedDate
                            date={createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                        <div>by {createdBy}</div>
                    </UploadDateText>
                </AttachmentCell>
            )}
            {columns.includes('description') && (
                <AttachmentCell width={tableWidths.description}>
                    {source}:{' '}
                    {linkTo ? (
                        <FeatureFlagged
                            flag="RMS_CASE_FOLDERING_ENABLED"
                            fallback={<PopoutLink to={linkTo}>{sourceDescription}</PopoutLink>}
                        >
                            {entityType === EntityTypeEnum.CASE_NOTE.name ? (
                                <AttachmentLink
                                    caseId={caseId}
                                    entityType={entityType}
                                    entityId={entityId}
                                    title={sourceDescription}
                                />
                            ) : (
                                <PopoutLink to={linkTo}>{sourceDescription}</PopoutLink>
                            )}
                        </FeatureFlagged>
                    ) : (
                        sourceDescription
                    )}
                </AttachmentCell>
            )}
            {columns.includes('actions') && (emailsEnabled || !hideDownloadButton) ? (
                <AttachmentCell width={tableWidths.actions}>
                    <Flex gap={1} justifyContent="flex-end" alignItems="center">
                        <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                            {emailsEnabled && (
                                <AttachmentEmailButton attachment={attachment} onEmail={onEmail} />
                            )}
                        </FeatureFlagged>

                        {!hideDownloadButton && (
                            <AttachmentDownloadButton
                                href={attachment.path}
                                onDownload={onDownload}
                            />
                        )}
                    </Flex>
                </AttachmentCell>
            ) : null}
        </AttachmentRow>
    );
}

export default styled(_AttachmentRow)`
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
`;

import { DashboardAreaEnum, DashboardSectionEnum } from '@mark43/rms-api';
import _ from 'lodash';
import formsRegistry from '../../../../core/formsRegistry';

export const FORM_NAME = 'PERSONAL_DASHBOARD_SETTINGS';

export const defaultSettings = {
    cardSections: _.filter(DashboardSectionEnum, {
        area: DashboardAreaEnum.RIGHT_CARDS.name,
    }).map(({ name, index }) => ({ ordinal: `${index + 1}`, section: name, isHidden: false })),
    sidebarSections: _.filter(DashboardSectionEnum, {
        area: DashboardAreaEnum.LEFT_SIDEBAR.name,
    }).map(({ name, index }) => ({ ordinal: `${index + 1}`, section: name, isHidden: false })),
};

function convertToFormModel(settings) {
    const settingsModel = _.isEmpty(settings) ? defaultSettings : settings;

    return _.chain(settingsModel).flatMap().keyBy('section').mapValues('isHidden').value();
}

export function convertFromFormModel(formModel, userSettings) {
    const settings = { cardSections: [], sidebarSections: [] };
    settings.cardSections = _.map(defaultSettings.cardSections, (section) => {
        const dashboardSection = section.section;
        const ordinal = _.find(userSettings.cardSections, { section: dashboardSection }).ordinal;
        return {
            ...section,
            isHidden: formModel[section.section],
            ordinal,
        };
    });
    settings.sidebarSections = _.map(defaultSettings.sidebarSections, (section) => {
        const dashboardSection = section.section;
        const ordinal = _.find(userSettings.sidebarSections, { section: dashboardSection }).ordinal;
        return {
            ...section,
            isHidden: formModel[section.section],
            ordinal,
        };
    });

    return settings;
}

export function fillDashboardSidePanel(settings) {
    formsRegistry.maybeDeferredOperation(FORM_NAME, undefined, (form) => {
        const formModel = convertToFormModel(settings);
        form.set('', formModel);
    });
}

import { CountryCodeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { every, forEach, map, some, take } from 'lodash';
import React from 'react';
import { compose, pure, withHandlers } from 'recompose';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
import styled, { withTheme } from 'styled-components';
import { Circle, Marker, Polygon } from 'react-google-maps';
import { HStack } from 'arc';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { combinedSubdivisionsLabelSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { convertToEsriPolygon } from '~/client-common/core/maps/helpers/avlHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    LOCATION_VIEW_LATITUDE,
    LOCATION_VIEW_LONGITUDE,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { useSubPremiseFieldLabels } from '~/client-common/core/fields/hooks/useFields';
import { currentUserDepartmentCountryCodeSelector } from '~/client-common/core/domain/current-user/state/ui';

import Row from '../../../core/components/Row';
import { CollapsibleFieldset, RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNFieldsets } from '../../../core/forms/components/NFieldsets';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFCountrySelect } from '../../../core/forms/components/selects/CountrySelect';
import { RRFAdvancedSearchLinkTypeSelect } from '../../../core/forms/components/selects/AdvancedSearchLinkTypeSelect';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { getPolygonCenter } from '../../../core/maps/utils/mapHelpers';
import GoogleMapWrapper from '../../../core/maps/components/GoogleMapWrapper';
import EsriSimpleMapWrapper from '../../../core/maps/components/EsriSimpleMapWrapper';
import NoMapContainer from '../../../core/maps/components/NoMapContainer';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { locationsFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';
import testIds from '../../../../core/testIds';
import SearchCapabilityIcon, { SEARCH_CAPABILITY_TYPE_ENUM } from './SearchCapabilityIcon';
import { SubdivisionsSearch } from './SubdivisionsSearch';

const { withRRFActions, withRRFFieldValues } = reactReduxFormHelpers;
const strings = componentStrings.search.fieldsets.LocationsFieldset;

const IconWrapper = styled.span`
    margin-left: 10px;
`;

/**
 * First row of the location fieldset with street address inputs.
 * @param {boolean}      [props.fuzzyMatchingEnabled]
 * @param {ReactElement} [props.expandCollapseButton]
 */
export function LocationFieldsetRow1({
    fuzzyMatchingEnabled,
    expandCollapseButton,
    includeSearchIcon = true,
}) {
    const isSubPremiseV2Enabled = useSelector(applicationSettingsSelector)
        .SUBPREMISE_SUPPORT_ENABLED;

    const { subPremiseNameLabel, subPremiseValueLabel } = useSubPremiseFieldLabels()

    return (
        <>
            <Row>
                <div data-test-id={testIds.ADVANCED_SEARCH_LOCATION_STREET_ADDRESS}>
                    <RRFText
                        width={248}
                        className={classNames({
                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                        })}
                        extraLabelContent={
                            includeSearchIcon ? (
                                <IconWrapper>
                                    <SearchCapabilityIcon
                                        variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name}
                                    />
                                </IconWrapper>
                            ) : (
                                <></>
                            )
                        }
                        labelClassName=""
                        path="streetAddress"
                    />
                </div>
                <div>
                    {!isSubPremiseV2Enabled && (
                        <RRFText
                            data-test-id={testIds.ADVANCED_SEARCH_LOCATION_SUB_PREMISE}
                            extraLabelContent={
                                includeSearchIcon ? (
                                    <IconWrapper>
                                        <SearchCapabilityIcon
                                            variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name}
                                        />
                                    </IconWrapper>
                                ) : (
                                    <></>
                                )
                            }
                            labelClassName=""
                            path="subPremise"
                            width={248}
                        />
                    )}
                    {expandCollapseButton}
                </div>
            </Row>

            {isSubPremiseV2Enabled && (
                <Row>
                    <RRFNItems
                        path={'subPremiseDtos'}
                        addItemOnEmpty={true}
                        addText={'Add Sub-Premise'}
                        maxItems={5}
                    >
                        {(_, index, deleteButton) => {
                            return (
                                <HStack>
                                    <RRFText
                                        className={classNames({
                                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                                        })}
                                        extraLabelContent={
                                            includeSearchIcon ? (
                                                <IconWrapper>
                                                    <SearchCapabilityIcon
                                                        variant={
                                                            SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD
                                                                .name
                                                        }
                                                    />
                                                </IconWrapper>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        labelClassName=""
                                        width={200}
                                        label={subPremiseNameLabel}
                                        path={'subPremiseName'}
                                    />
                                    <RRFText
                                        className={classNames({
                                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                                        })}
                                        extraLabelContent={
                                            includeSearchIcon ? (
                                                <IconWrapper>
                                                    <SearchCapabilityIcon
                                                        variant={
                                                            SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD
                                                                .name
                                                        }
                                                    />
                                                </IconWrapper>
                                            ) : (
                                                <></>
                                            )
                                        }
                                        labelClassName=""
                                        width={200}
                                        label={subPremiseValueLabel}
                                        path={'subPremiseValue'}
                                    />
                                    {index > 0 ? deleteButton : null}
                                </HStack>
                            );
                        }}
                    </RRFNItems>
                </Row>
            )}
        </>
    );
}

/**
 * Second row of the location fieldset with city/state/zip/country inputs. The
 *   city/state/zip inputs are spaced closer to each other than normal.
 */
export function LocationFieldsetRow2() {
    const currentUserDepartmentCountryCode = useSelector(currentUserDepartmentCountryCodeSelector);
    const isGBDepartment = CountryCodeEnum.GB.name === currentUserDepartmentCountryCode;
    return (
        <Row>
            {isGBDepartment ? (
                <>
                    <RRFText width={158} style={{ marginRight: 10 }} path="neighborhood" />
                    <RRFText width={158} style={{ marginRight: 10 }} path="locality" />
                    <RRFText
                        width={158}
                        style={{ marginRight: 10 }}
                        path="administrativeAreaLevel2"
                    />
                </>
            ) : (
                <>
                    <RRFText width={158} style={{ marginRight: 10 }} path="locality" />
                    <RRFText
                        width={158}
                        style={{ marginRight: 10 }}
                        path="administrativeAreaLevel1"
                    />
                </>
            )}
            <RRFText width={96} path="postalCode" />
            <RRFCountrySelect width={258} path="country" />
        </Row>
    );
}

/**
 * Third row of the location fieldset with intersection street inputs.
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includeCrossStreets]
 */
export function LocationFieldsetRow3({ fuzzyMatchingEnabled, includeCrossStreets }) {
    if (!includeCrossStreets) {
        return <div />;
    }
    return (
        <Row>
            <RRFText
                width={345}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                path="crossStreet1"
            />
            <RRFText
                width={345}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                path="crossStreet2"
            />
        </Row>
    );
}

const LocationFieldsetRow4 = compose(
    connect(
        createStructuredSelector({
            combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        })
    ),
    withRRFActions
)(function LocationFieldsetRow4({
    combinedSubdivisionsLabel,
    includeSubdivisionAttrIds,
    includeType,
    includeCategoryAttrIds,
}) {
    if (!includeSubdivisionAttrIds && !includeType && !includeCategoryAttrIds) {
        return <div />;
    }
    return (
        <Row>
            <FeatureFlagged
                flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                fallback={
                    includeSubdivisionAttrIds && (
                        <RRFAttributeSelect
                            attributeType={[
                                'SUBDIVISION_DEPTH_1',
                                'SUBDIVISION_DEPTH_2',
                                'SUBDIVISION_DEPTH_3',
                                'SUBDIVISION_DEPTH_4',
                                'SUBDIVISION_DEPTH_5',
                            ]}
                            multiple={true}
                            includeExpired={true}
                            label={combinedSubdivisionsLabel}
                            width={178}
                            path="subdivisionAttrIds"
                        />
                    )
                }
            />
            {includeType && (
                <RRFAdvancedSearchLinkTypeSelect
                    entityTypePairs={[
                        [EntityTypeEnum.LOCATION.name, EntityTypeEnum.REPORT.name],
                        [EntityTypeEnum.LOCATION.name, EntityTypeEnum.OFFENSE.name],
                        [EntityTypeEnum.LOCATION.name, EntityTypeEnum.ARREST.name],
                        [EntityTypeEnum.LOCATION.name, EntityTypeEnum.FIELD_CONTACT.name],
                        [EntityTypeEnum.LOCATION.name, EntityTypeEnum.ITEM_PROFILE.name],
                    ]}
                    width={248}
                    path="type"
                />
            )}
            <FeatureFlagged flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED">
                {includeSubdivisionAttrIds && (
                    <Row>
                        <SubdivisionsSearch width={179} />
                    </Row>
                )}
            </FeatureFlagged>
            {includeCategoryAttrIds && (
                <RRFAttributeSelect
                    attributeType="LOCATION_CATEGORY"
                    multiple={true}
                    includeExpired={true}
                    width={248}
                    path="categoryAttrIds"
                />
            )}
        </Row>
    );
});

const GeoSearchFieldset = styled(RRFFieldset)`
    min-height: 300px;
`;

const GeoSearchWrapper = styled.div`
    height: 100%;
    position: relative;
`;

const mapStyles = { top: '35px', right: 0 };

const RadiusSearchFieldset = compose(
    connect(
        createStructuredSelector({
            applicationSettings: applicationSettingsSelector,
        })
    ),
    withRRFFieldValues({
        lat: 'geoRadiusQuery.lat',
        lon: 'geoRadiusQuery.lon',
        radiusInMeters: 'geoRadiusQuery.radiusInMeters',
    }),
    withRRFActions,
    withHandlers({
        onBlur({ formActions: { setValidity }, lat, lon, radiusInMeters }) {
            return () => {
                // user must include all 3 values to search by radius
                const empty = !lat && !lon && !radiusInMeters;
                setValidity('geoRadiusQuery.lat', { requiredError: empty || !!lat });
                setValidity('geoRadiusQuery.lon', { requiredError: empty || !!lon });
                setValidity('geoRadiusQuery.radiusInMeters', {
                    requiredError: empty || !!radiusInMeters,
                });
            };
        },
    }),
    withTheme
)(function RadiusSearchFieldset({
    lat,
    lon,
    radiusInMeters,
    onBlur,
    applicationSettings,
    theme,
    className = 'light',
}) {
    const radius = parseFloat(radiusInMeters);
    const center = { lat: parseFloat(lat), lng: parseFloat(lon) }; // google api uses `lng` and elasticsearch uses `lon`
    const hasCenter = every(center);

    let staticMap = <NoMapContainer styles={mapStyles} />;
    if (hasCenter && radius) {
        if (applicationSettings.ESRI_MAPS_ENABLED) {
            const polygons = [convertToEsriPolygon(center, null, 0, 'CIRCLE', radius)];
            staticMap = (
                <EsriSimpleMapWrapper
                    styles={mapStyles}
                    defaultCenter={center}
                    selectedLocation={{ latitude: center.lat, longitude: center.lng }}
                    polygons={polygons}
                    enableMove
                    enableZoom
                />
            );
        } else {
            const red = theme.colors.red;

            staticMap = (
                <GoogleMapWrapper center={center} styles={mapStyles}>
                    <Marker position={center} />
                    <Circle
                        center={center}
                        radius={radius}
                        options={{ fillColor: red, strokeColor: red, strokeWeight: 1 }}
                    />
                </GoogleMapWrapper>
            );
        }
    }

    return (
        <GeoSearchWrapper>
            <GeoSearchFieldset
                className={className}
                path="geoRadiusQuery"
                title={strings.radiusSearchTitle}
            >
                <Row>
                    <RRFText width={200} path="lat" onBlur={onBlur} />
                    <RRFText width={200} path="lon" onBlur={onBlur} />
                </Row>
                <Row>
                    <RRFText width={200} path="radiusInMeters" onBlur={onBlur} />
                </Row>
            </GeoSearchFieldset>
            {staticMap}
        </GeoSearchWrapper>
    );
});

const PolygonSearchFieldset = compose(
    connect(
        createStructuredSelector({
            applicationSettings: applicationSettingsSelector,
        })
    ),
    withRRFFieldValues({
        geoPolygonQuery: 'geoPolygonQuery',
    }),
    withRRFActions,
    withHandlers({
        onBlur({ formActions: { setValidity }, geoPolygonQuery }) {
            return () => {
                const firstThreeCoordinates = take(geoPolygonQuery, 3);
                const isPartiallyFilled = some(firstThreeCoordinates, ({ lat, lon }) => lat || lon);
                // if any of the first 3 coordinates are filled, user must fill out the rest of the 3 coordinates
                forEach(firstThreeCoordinates, ({ lat, lon }, i) => {
                    setValidity(`geoPolygonQuery.${i}.lat`, {
                        requiredError: !isPartiallyFilled || !!lat,
                    });
                    setValidity(`geoPolygonQuery.${i}.lon`, {
                        requiredError: !isPartiallyFilled || !!lon,
                    });
                });
            };
        },
    }),
    withFields([LOCATION_VIEW_LATITUDE, LOCATION_VIEW_LONGITUDE]),
    withTheme
)(function PolygonSearchFieldset({
    geoPolygonQuery,
    onBlur,
    applicationSettings,
    theme,
    className = 'light',
    fieldDisplayNames,
}) {
    // google maps api uses lng and elasticsearch uses lon
    const geoPolygonQueryFloats = map(geoPolygonQuery, ({ lat, lon }) => ({
        lat: parseFloat(lat),
        lng: parseFloat(lon),
    }));

    // first 3 sets of coordinates must be filled before we show the map
    const allValuesFilled = every(take(geoPolygonQueryFloats, 3), ({ lat, lng }) => !!lat && !!lng);

    let staticMap = <NoMapContainer styles={mapStyles} />;

    if (allValuesFilled) {
        const polygonCenter = getPolygonCenter(geoPolygonQueryFloats);

        if (applicationSettings.ESRI_MAPS_ENABLED) {
            const polygons = [
                convertToEsriPolygon(polygonCenter, geoPolygonQueryFloats, 0, 'POLYGON'),
            ];
            staticMap = (
                <EsriSimpleMapWrapper
                    styles={mapStyles}
                    defaultCenter={polygonCenter}
                    selectedLocation={{ latitude: polygonCenter.lat, longitude: polygonCenter.lng }}
                    polygons={polygons}
                    enableMove
                    enableZoom
                />
            );
        } else {
            const red = theme.colors.red;

            staticMap = (
                <GoogleMapWrapper center={polygonCenter} styles={mapStyles}>
                    <Polygon
                        paths={geoPolygonQueryFloats}
                        options={{ fillColor: red, strokeColor: red, strokeWeight: 1 }}
                    />
                </GoogleMapWrapper>
            );
        }
    }

    return (
        <GeoSearchWrapper>
            <GeoSearchFieldset className={className} title={strings.polygonSearchTitle}>
                <RRFNItems path="geoPolygonQuery" addItemOnDirty={true} minimumNumberOfItems={3}>
                    {(field, index) => (
                        <div>
                            <RRFText
                                label={locationsFieldsetViewModel.fields.geoPolygonQuery.fields.lat.label(
                                    fieldDisplayNames.LOCATION_VIEW_LATITUDE,
                                    index
                                )}
                                width={200}
                                path="lat"
                                onBlur={onBlur}
                            />
                            <RRFText
                                label={locationsFieldsetViewModel.fields.geoPolygonQuery.fields.lon.label(
                                    fieldDisplayNames.LOCATION_VIEW_LONGITUDE,
                                    index
                                )}
                                width={200}
                                path="lon"
                                onBlur={onBlur}
                            />
                        </div>
                    )}
                </RRFNItems>
            </GeoSearchFieldset>
            {staticMap}
        </GeoSearchWrapper>
    );
});

/**
 * Fieldset for a single location. Some of the fields are optional for use in
 *   different forms.
 * @param {Object}  props
 * @param {Object}  [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includeCrossStreets=false]
 * @param {boolean} [props.includeSubdivisionAttrIds=false]
 * @param {boolean} [props.includeType=false]
 * @param {boolean} [props.includeCategoryAttrIds=false]
 */
const LocationFieldset = pure(function LocationFieldset({
    fuzzyMatchingEnabled,
    includeCrossStreets = false,
    includeSubdivisionAttrIds = false,
    includeType = false,
    includeCategoryAttrIds = false,
    ...otherProps
}) {
    return (
        <RRFFieldset path="location" {...otherProps}>
            <LocationFieldsetRow1 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <LocationFieldsetRow2 />
            <LocationFieldsetRow3
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                includeCrossStreets={includeCrossStreets}
            />
            <LocationFieldsetRow4
                includeSubdivisionAttrIds={includeSubdivisionAttrIds}
                includeType={includeType}
                includeCategoryAttrIds={includeCategoryAttrIds}
            />
        </RRFFieldset>
    );
});

/**
 * Collapsible fieldset for a single location.
 * @param {Object} props
 */
function CollapsibleLocationFieldset({
    fuzzyMatchingEnabled,
    includeCrossStreets,
    includeSubdivisionAttrIds,
    includeType,
    includeCategoryAttrIds,
    includeGeoQuery,
    ...otherProps
}) {
    return (
        <CollapsibleFieldset
            expandedChildren={
                <div>
                    {includeGeoQuery && <RadiusSearchFieldset />}
                    {includeGeoQuery && <PolygonSearchFieldset />}
                </div>
            }
            {...otherProps}
        >
            {(expandCollapseButton, collapsed) => (
                // We don't keep these collapsed rows in `expandedChildren` because
                // we want the focus highlighting of this fieldset to be separate
                // from the highlighting of the radius search fieldset.
                <RRFFieldset>
                    <LocationFieldsetRow1
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        expandCollapseButton={expandCollapseButton}
                    />
                    {!collapsed && <LocationFieldsetRow2 />}
                    {!collapsed && (
                        <LocationFieldsetRow3
                            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                            includeCrossStreets={includeCrossStreets}
                        />
                    )}
                    {!collapsed && (
                        <LocationFieldsetRow4
                            includeSubdivisionAttrIds={includeSubdivisionAttrIds}
                            includeType={includeType}
                            includeCategoryAttrIds={includeCategoryAttrIds}
                        />
                    )}
                </RRFFieldset>
            )}
        </CollapsibleFieldset>
    );
}

/**
 * N location fieldsets. Some of the fields are optional for use in different
 *   forms.
 * @param {Object}  props
 * @param {Object}  [props.collapsible=true] Whether each location fieldset is
 *   collapsible.
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {boolean} [props.includeCrossStreets=false]
 * @param {boolean} [props.includeSubdivisionAttrIds=false]
 * @param {boolean} [props.includeType=false]
 * @param {boolean} [props.includeCategoryAttrIds=false]
 * @param {boolean} [props.includeGeoQuery=false]
 */
export default pure(function LocationsFieldset({
    collapsible = true,
    fuzzyMatchingEnabled,
    includeCrossStreets = false,
    includeSubdivisionAttrIds = false,
    includeType = false,
    includeCategoryAttrIds = false,
    includeGeoQuery = false,
    ...otherProps
}) {
    return (
        <RRFNFieldsets path="locations" automaticallyIncludeDeleteButton={false} {...otherProps}>
            {(fieldsetProps) =>
                collapsible ? (
                    <CollapsibleLocationFieldset
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        includeCrossStreets={includeCrossStreets}
                        includeSubdivisionAttrIds={includeSubdivisionAttrIds}
                        includeType={includeType}
                        includeCategoryAttrIds={includeCategoryAttrIds}
                        includeGeoQuery={includeGeoQuery}
                        {...fieldsetProps}
                    />
                ) : (
                    // empty path because this is locations[n], not locations[n].location
                    <LocationFieldset
                        path=""
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        includeCrossStreets={includeCrossStreets}
                        includeSubdivisionAttrIds={includeSubdivisionAttrIds}
                        includeType={includeType}
                        includeCategoryAttrIds={includeCategoryAttrIds}
                        {...fieldsetProps}
                    />
                )
            }
        </RRFNFieldsets>
    );
});

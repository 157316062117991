import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import noop from 'lodash/noop';
import { AttributeTypeEnumType } from '@mark43/rms-api';
import { loadAttributesForType } from '../../../modules/core/attributes/state/ui/loadAttributesForType';
import { RmsDispatch } from '../../../core/typings/redux';

const loadedAttributes: AttributeTypeEnumType[] = [];
export const usePreloadAttributes = (attributeTypes: AttributeTypeEnumType[]) => {
    const dispatch = useDispatch<RmsDispatch>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const notLoadedAttributes = attributeTypes.filter(
            (item) => !loadedAttributes.includes(item)
        );
        if (!isLoading && notLoadedAttributes.length) {
            setIsLoading(true);
            dispatch(
                loadAttributesForType({
                    attributeType: notLoadedAttributes,
                })
            )
                .then(() => {
                    loadedAttributes.push(...notLoadedAttributes);
                })
                .catch(noop)
                .finally(() => setIsLoading(false));
        }
    }, [attributeTypes, isLoading, dispatch]);
    return { isLoading };
};

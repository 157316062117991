import React, { useCallback, useState, useEffect } from 'react';
import { InlineBanner } from 'arc';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';
import {
    EFileNameSearchRequest,
    LinkTypesEnum,
    SearchResultEFileNameSearchView,
} from '@mark43/rms-api';
import styled from 'styled-components';
import { augmentActionWithNexusHydratedOrganizations } from '~/client-common/core/domain/organization-profiles/state/data';
import { augmentActionAndStoreRmsHydratedPersonProfiles } from '~/client-common/core/domain/person-profiles/state/data';
import { useResource } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { SkeletonTable } from '../../../../core/components/Skeleton';
import Pagination from '../../../../../legacy-redux/components/core/Pagination';
import TableResultsSummary from '../../../../../legacy-redux/components/core/tables/TableResultsSummary';
import { groupDefendants } from '../../../helpers';
import { useEFileContext, usePagination } from '../../../hooks';
import { INITIAL_DEFENDANTS_SEARCH_QUERY, INVOLVED_PROFILES_PAGE_SIZE } from '../../../constants';
import { ColumnT, EFileSearchRequest } from '../../../types';
import RMSTableComposite from '../../../../core/components/RMSTableComposite';
import testIds from '../../../../../core/testIds';

import { EFileInvolvedProfilesGridRows, EFileInvolvedProfilesHeaderRow } from '../table';

const strings = componentStrings.eFiles.defendants.grid;

const StyledEFileDefendantsGrid = styled.div`
    display: flex;
    margin-top: 1em;
    flex-direction: column;
`;

const PaginationSummaryComponentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.5em;
`;

const PaginationComponentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
`;

const useColumns = (): ColumnT[] => {
    const offenseDisplayName = useOffenseFieldName();

    return [
        {
            key: 'nameData',
            width: 235,
            title: strings.columns.name,
            sortable: false,
            filterable: false,
        },
        {
            key: 'identifiers',
            width: 150,
            title: strings.columns.identifiers,
            sortable: false,
            filterable: false,
        },
        {
            key: 'locationData',
            width: 150,
            title: strings.columns.locationData,
            sortable: false,
            filterable: false,
        },
        {
            key: 'offenses',
            width: 130,
            title: strings.columns.offenses(offenseDisplayName),
            sortable: false,
            filterable: false,
        },
        {
            key: 'actions',
            width: 45,
            title: '',
            sortable: false,
            filterable: false,
        },
    ];
};

export const EFileDefendantsGrid = () => {
    const dispatch = useDispatch();
    const {
        actions,
        getters: {
            efile: { getEFileViewModel },
            search: { getEFileDefendants },
        },
        handlers: { getInvolvedProfiles, onFetchError },
    } = useEFileContext();

    useEffect(() => {
        return function cleanup() {
            actions.grid.resetEFileGrid();
        };
    }, [actions.grid]);

    const eFileViewModel = getEFileViewModel();
    const eFileId = eFileViewModel?.id;

    const columns = useColumns();

    const [total, setTotal] = useState<number>(0);

    const getProfiles = useCallback(
        () => getInvolvedProfiles(INITIAL_DEFENDANTS_SEARCH_QUERY, eFileId),
        [eFileId, getInvolvedProfiles]
    );

    const getInvolvedProfilesAndResetGrid = useCallback(
        (searchQuery: EFileSearchRequest<EFileNameSearchRequest>, efileId?: number) => {
            actions.grid.resetEFileGrid();
            return getInvolvedProfiles(searchQuery, efileId);
        },
        [actions.grid, getInvolvedProfiles]
    );

    const onGetDefendantsSuccess = useCallback(
        (defendantsSearchResult?: SearchResultEFileNameSearchView) => {
            if (defendantsSearchResult) {
                // store the search result in the context
                actions.search.setEFileDefendants(defendantsSearchResult);
                setTotal(defendantsSearchResult.totalCount);

                // store the fetched profiles in redux
                const { personProfiles, organizationProfiles } = groupDefendants(
                    defendantsSearchResult.items
                );
                dispatch(
                    augmentActionAndStoreRmsHydratedPersonProfiles(
                        { type: 'STORE_E_FILE_DEFENDANTS_PERSON_PROFILES' },
                        map(personProfiles, 'personProfile')
                    )
                );
                dispatch(
                    augmentActionWithNexusHydratedOrganizations(
                        { type: 'STORE_E_FILE_DEFENDANTS_ORGANIZATION_PROFILES' },
                        map(organizationProfiles, 'organizationProfile')
                    )
                );
            }
        },
        [actions.search, dispatch]
    );

    const { isLoading, errorMessage } = useResource(
        getProfiles,
        onGetDefendantsSuccess,
        onFetchError
    );
    const { loading: onPaginationLoad, page, from, to, onPaginationClick } = usePagination<
        EFileNameSearchRequest,
        SearchResultEFileNameSearchView
    >({
        eFileId,
        total,
        initialSearchQuery: INITIAL_DEFENDANTS_SEARCH_QUERY,
        pageSize: INVOLVED_PROFILES_PAGE_SIZE,
        resourceCall: getInvolvedProfilesAndResetGrid,
        onSuccess: onGetDefendantsSuccess,
        onError: onFetchError,
    });

    if (errorMessage) {
        return <InlineBanner description={errorMessage} status="error" title={strings.error} />;
    }

    if (isLoading || onPaginationLoad.isLoading) {
        return <SkeletonTable />;
    }

    const eFileDefendantsViewModels = getEFileDefendants();

    return (
        <StyledEFileDefendantsGrid>
            <RMSTableComposite
                columns={columns}
                items={eFileDefendantsViewModels}
                noDataText={strings.noDataText}
                includeFilter={false}
                testId={testIds.TABLE}
                renderHeaderRow={({ columns }: { columns: ColumnT[] }) => {
                    return (
                        <EFileInvolvedProfilesHeaderRow
                            columns={columns}
                            canEdit={!!eFileViewModel?.canEdit}
                        />
                    );
                }}
                // @ts-expect-error RMSTableComposite is not typed
                renderBodyRow={({ item, index }) => {
                    return (
                        <EFileInvolvedProfilesGridRows
                            key={`${item.eFileLinkId}`}
                            item={item}
                            index={index}
                            linkType={LinkTypesEnum.DEFENDANT_IN_EFILE}
                        />
                    );
                }}
                renderLayout={({ tableComponent }: { tableComponent: JSX.Element }) => {
                    return (
                        <>
                            <PaginationSummaryComponentWrapper>
                                <TableResultsSummary
                                    from={from}
                                    to={to}
                                    totalResults={total}
                                    className={null}
                                />
                            </PaginationSummaryComponentWrapper>
                            {tableComponent}
                            {total > 0 && (
                                <PaginationComponentWrapper>
                                    <Pagination
                                        currentPage={page}
                                        itemsPerPage={INVOLVED_PROFILES_PAGE_SIZE}
                                        itemCount={total}
                                        onClick={onPaginationClick}
                                        maxEdgeItems={1}
                                        className={null}
                                        gapText={null}
                                    />
                                </PaginationComponentWrapper>
                            )}
                        </>
                    );
                }}
            />
        </StyledEFileDefendantsGrid>
    );
};

import React from 'react';
import { map } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

export default function ReportReviewersCell(elasticReport) {
    const reviewers = getViewModelProperties(elasticReport).reviewerIds;
    return (
        <div>
            {reviewers ? map(reviewers, ({ id, display }) => <div key={id}>{display}</div>) : ''}
        </div>
    );
}

import React, { useReducer } from 'react';

import { crashDiagramRootReducer, rootInitialState } from './reducer';

import { CrashDiagramProvider } from './CrashDiagramContext';
import { CrashDiagramHistoryProvider } from './CrashDiagramHistoryContext';
import { AssetCacheProvider } from './AssetCacheContext';

export const CrashDiagramContextProviders: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(crashDiagramRootReducer, rootInitialState);

    return (
        <AssetCacheProvider>
            <CrashDiagramProvider state={state.diagram} dispatch={dispatch}>
                <CrashDiagramHistoryProvider state={state.history} dispatch={dispatch}>
                    {children}
                </CrashDiagramHistoryProvider>
            </CrashDiagramProvider>
        </AssetCacheProvider>
    );
};

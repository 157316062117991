import _, { chain, filter, find, get, map, reject, sortBy } from 'lodash';
import { createField, createFieldset, createFormConfiguration, createNItems } from 'markformythree';
import { ruleTypes } from 'arbiter-mark43';
import { AttributeTypeEnum, CoreModelEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';

import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { objectValuesAreEmpty } from '~/client-common/helpers/objectHelpers';
import { convertOffenseCodeToStatuteCodeSetFilter } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';
import { getEarliestDate } from '~/client-common/core/dates/utils/dateHelpers';

import { convertNameAttributesDataStateToFormState } from '../../../../../legacy-redux/helpers/nameAttributesHelpers';
import { mapOffenseAttributes } from '../../utils/offenseIncidentHelpers';
import { formDataToArbiterDataMFT } from '../../../../core/arbiter';
import { buildFormConfigurationForCoreModel } from '../../../../core/markformythree-arbiter/dynamic-fields/helpers/buildFormConfigurationForCoreModel';
import {
    CUSTOM_PROPERTIES_KEY_NAME,
    TNIBRS_PROPERTY_NAME,
} from '../../../../core/markformythree-arbiter/dynamic-fields/constants/constants';

export const offenseFormConfiguration = (params) =>
    createFormConfiguration({
        reportCreatedDateUtc: createField({ fieldName: fields.REPORT_CREATED_DATE_UTC }),
        propertyStatusDateUtc: createField({ fieldName: fields.PROPERTY_STATUS_STATUS_DATE_UTC }),
        // UI-only toggle for filtering the dropdown options of offenseCodeId
        statuteCodeSetFilter: createField({
            fieldName: fields.DISPLAY_ONLY_OFFENSE_CODE_STATUTE_CODE_SET_FILTER,
        }),
        offense: createFieldset({
            fields: {
                ...buildFormConfigurationForCoreModel({
                    keyName: CUSTOM_PROPERTIES_KEY_NAME,
                    context: RefContextEnum.FORM_OFFENSE.name,
                    coreModelName: CoreModelEnum.OFFENSE.name,
                    additionalData: params.additionalData,
                    __orderedPropertyNames: [
                        TNIBRS_PROPERTY_NAME.GANG_NAME_1,
                        TNIBRS_PROPERTY_NAME.TYPE_OF_GANG_1_ATTR_ID,
                        TNIBRS_PROPERTY_NAME.GANG_NAME_2,
                        TNIBRS_PROPERTY_NAME.TYPE_OF_GANG_2_ATTR_ID,
                        TNIBRS_PROPERTY_NAME.IS_USE_OF_FORCE,
                        TNIBRS_PROPERTY_NAME.USE_OF_FORCE_NUMBER,
                        TNIBRS_PROPERTY_NAME.IS_HOME_INVASION,
                        TNIBRS_PROPERTY_NAME.IS_IDENTITY_THEFT,
                        TNIBRS_PROPERTY_NAME.IS_CYBERCRIME,
                        TNIBRS_PROPERTY_NAME.IS_DRUG_RELATED,
                        TNIBRS_PROPERTY_NAME.DRUG_TYPE_ATTR_ID,
                        TNIBRS_PROPERTY_NAME.DRUG_PRECURSORS_ATTR_ID,
                        TNIBRS_PROPERTY_NAME.ORIGIN_OF_DRUG_ATTR_ID,
                    ],
                }),
                childrenInvolved: createField({ fieldName: fields.OFFENSE_CHILDREN_INVOLVED }),
                childrenPresent: createField({ fieldName: fields.OFFENSE_CHILDREN_PRESENT }),
                offenseInvolvedChildren: createNItems({
                    fieldName: fields.DISPLAY_ONLY_OFFENSE_INVOLVED_CHILDREN_N_ITEMS_WRAPPER,
                    fields: {
                        childName: createField({
                            fieldName: fields.OFFENSE_INVOLVED_CHILD_CHILD_NAME,
                        }),
                        childAge: createField({
                            fieldName: fields.OFFENSE_INVOLVED_CHILD_CHILD_AGE,
                        }),
                    },
                }),
                id: createField({}),
                reportId: createField({}),
                offenseOrder: createField({}),
                offenseCodeId: createField({ fieldName: fields.OFFENSE_OFFENSE_CODE_ID }),
                // these 2 fields must be kept in sync any time 1 of them changes
                // nibrsOffenseCodeId is the value selected in the select dropdown and used in some BE rules
                // nibrsCodeCode is also used for logic in various FE checks and BE rules
                nibrsOffenseCodeId: createField({
                    fieldName: fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_ID,
                }),
                nibrsCodeCode: createField({
                    fieldName: fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE,
                }),
                offenseCodeCode: createField({
                    fieldName: fields.DISPLAY_ONLY_OFFENSE_OFFENSE_CODE_CODE,
                }),
                isOffenseDateUnknown: createField({
                    fieldName: fields.OFFENSE_IS_OFFENSE_DATE_UNKNOWN,
                }),
                offenseDateUtc: createField({ fieldName: fields.OFFENSE_OFFENSE_DATE_UTC }),
                offenseEndDateUtc: createField({ fieldName: fields.OFFENSE_OFFENSE_END_DATE_UTC }),

                crimeReportedOffenseDateUtc: createField({
                    fieldName: fields.CRIME_REPORTED_OFFENSE_DATE_UTC,
                }),
                crimeReportedOffenseEndDateUtc: createField({
                    fieldName: fields.CRIME_REPORTED_OFFENSE_END_DATE_UTC,
                }),

                includesCargoTheft: createField({
                    fieldName: fields.OFFENSE_INCLUDES_CARGO_THEFT,
                }),
                wasCompleted: createField({ fieldName: fields.OFFENSE_WAS_COMPLETED }),
                isSuspectedHateCrime: createField({
                    fieldName: fields.OFFENSE_IS_SUSPECTED_HATE_CRIME,
                }),
                isDomesticViolence: createField({ fieldName: fields.OFFENSE_IS_DOMESTIC_VIOLENCE }),
                isAntiReproductiveRightsCrime: createField({
                    fieldName: fields.OFFENSE_IS_ANTI_REPRODUCTIVE_RIGHTS_CRIME,
                }),
                hasProtectionOrder: createField({ fieldName: fields.OFFENSE_HAS_PROTECTION_ORDER }),
                protectionOrderStatusAttrId: createField({
                    fieldName: fields.OFFENSE_PROTECTION_ORDER_STATUS_ATTR_ID,
                }),
                protectionOrderStatusOther: createField({
                    fieldName: fields.OFFENSE_PROTECTION_ORDER_STATUS_OTHER,
                }),
                protectionOrderTypeAttrId: createField({
                    fieldName: fields.OFFENSE_PROTECTION_ORDER_TYPE_ATTR_ID,
                }),
                protectionOrderTypeOther: createField({
                    fieldName: fields.OFFENSE_PROTECTION_ORDER_TYPE_OTHER,
                }),
                protectionOrderIssuingCourt: createField({
                    fieldName: fields.OFFENSE_PROTECTION_ORDER_ISSUING_COURT,
                }),
                protectionOrderDocketNumber: createField({
                    fieldName: fields.OFFENSE_PROTECTION_ORDER_DOCKET_NUMBER,
                }),
                itemInvolvementType: createField({
                    fieldName: fields.OFFENSE_ITEM_INVOLVEMENT_TYPE,
                }),
                burgWasMethodOfEntryForced: createField({
                    fieldName: fields.OFFENSE_BURG_WAS_METHOD_OF_ENTRY_FORCED,
                }),
                entryPointAttrId: createField({
                    fieldName: fields.OFFENSE_ENTRY_POINT_ATTR_ID,
                }),
                burgNumberOfPremisesEntered: createField({
                    fieldName: fields.OFFENSE_BURG_NUMBER_OF_PREMISES_ENTERED,
                }),
                arsonBuildingInhabited: createField({
                    fieldName: fields.OFFENSE_ARSON_BUILDING_INHABITED,
                }),
                /* `justifiableHomicideAttrId does not have an `other` */
                justifiableHomicideAttrId: createField({
                    fieldName: fields.OFFENSE_JUSTIFIABLE_HOMICIDE_ATTR_ID,
                }),
                wasCriminalKilledByOfficer: createField({
                    fieldName: fields.OFFENSE_WAS_CRIMINAL_KILLED_BY_OFFICER,
                }),
                /* `negligentManslaughterAttrId does not have an `other` */
                negligentManslaughterAttrId: createField({
                    fieldName: fields.OFFENSE_NEGLIGENT_MANSLAUGHTER_ATTR_ID,
                }),
                previousComplaintsAttrId: createField({
                    fieldName: fields.OFFENSE_PREVIOUS_COMPLAINTS_ATTR_ID,
                }),
                previousComplaintsOtherDescription: createField({
                    fieldName: fields.OFFENSE_PREVIOUS_COMPLAINTS_OTHER_DESCRIPTION,
                }),
                priorCourtOrdersAttrId: createField({
                    fieldName: fields.OFFENSE_PRIOR_COURT_ORDERS_ATTR_ID,
                }),
                priorCourtOrdersOtherDescription: createField({
                    fieldName: fields.OFFENSE_PRIOR_COURT_ORDERS_OTHER_DESCRIPTION,
                }),
                reasonForNoArrestAttrId: createField({
                    fieldName: fields.OFFENSE_REASON_FOR_NO_ARREST_ATTR_ID,
                }),
                reasonForNoArrestOtherDescription: createField({
                    fieldName: fields.OFFENSE_REASON_FOR_NO_ARREST_OTHER_DESCRIPTION,
                }),
                suspectedInternetCyberInvolvementAttrId: createField({
                    fieldName: fields.OFFENSE_SUSPECTED_INTERNET_CYBER_INVOLVEMENT_ATTR_ID,
                }),
                suspectedComputerUse: createField({
                    fieldName: fields.OFFENSE_SUSPECTED_COMPUTER_USE,
                }),
                suspectedAlcoholUse: createField({
                    fieldName: fields.OFFENSE_SUSPECTED_ALCOHOL_USE,
                }),
                suspectedMarijuanaUse: createField({
                    fieldName: fields.OFFENSE_SUSPECTED_MARIJUANA_USE,
                }),
                suspectedDrugUse: createField({ fieldName: fields.OFFENSE_SUSPECTED_DRUG_USE }),
                suspectedGamingActivity: createField({
                    fieldName: fields.OFFENSE_SUSPECTED_GAMING_ACTIVITY,
                }),
                suspectedDrugTypeAttrId: createField({
                    fieldName: fields.OFFENSE_SUSPECTED_DRUG_TYPE_ATTR_ID,
                }),
                hateCrimeActAttrId: createField({
                    fieldName: fields.OFFENSE_HATE_CRIME_ACT_ATTR_ID,
                }),
                hateCrimeActOther: createField({ fieldName: fields.OFFENSE_HATE_CRIME_ACT_OTHER }),
                evidenceAvailableAttrId: createField({
                    fieldName: fields.OFFENSE_EVIDENCE_AVAILABLE_ATTR_ID,
                }),
                objectsSymbolsPresentAttrId: createField({
                    fieldName: fields.OFFENSE_OBJECTS_SYMBOLS_PRESENT_ATTR_ID,
                }),
                numberOfWeaponsInvolved: createField({
                    fieldName: fields.OFFENSE_NUMBER_OF_WEAPONS_INVOLVED,
                }),
                policeActionAttrId: createField({
                    fieldName: fields.OFFENSE_POLICE_ACTION_ATTR_ID,
                }),
                schoolWeaponInvolved: createField({
                    fieldName: fields.OFFENSE_SCHOOL_WEAPON_INVOLVED,
                }),
                wasCarjackingInvolved: createField({
                    fieldName: fields.OFFENSE_WAS_CARJACKING_INVOLVED,
                }),
                isSuspectedChildSexualAbuse: createField({
                    fieldName: fields.OFFENSE_IS_SUSPECTED_CHILD_SEXUAL_ABUSE,
                }),
                isSuspectedChildSexualExploitation: createField({
                    fieldName: fields.OFFENSE_IS_SUSPECTED_CHILD_SEXUAL_EXPLOITATION,
                }),
                isSuspectedCorrosiveBasedOffense: createField({
                    fieldName: fields.OFFENSE_IS_SUSPECTED_CORROSIVE_BASED_OFFENSE,
                }),
                isHonourBasedOffence: createField({
                    fieldName: fields.OFFENSE_IS_HONOUR_BASED_OFFENCE,
                }),
                isSuspectedOffenseAgainstWomenOrGirls: createField({
                    fieldName: fields.OFFENSE_IS_SUSPECTED_OFFENSE_AGAINST_WOMEN_OR_GIRLS,
                }),
                isSuspectedModernSlavery: createField({
                    fieldName: fields.OFFENSE_IS_SUSPECTED_MODERN_SLAVERY,
                }),
                wasVehicleBumpedRobbed: createField({
                    fieldName: fields.OFFENSE_WAS_VEHICLE_BUMPED_ROBBED,
                }),
                wasWitnessPresent: createField({
                    fieldName: fields.OFFENSE_WAS_WITNESS_PRESENT,
                }),
                wasShootingInvolved: createField({
                    fieldName: fields.OFFENSE_WAS_SHOOTING_INVOLVED,
                }),
                hasOtherOrganizedGroup: createField({
                    fieldName: fields.OFFENSE_HAS_OTHER_ORGANIZED_GROUP,
                }),
                hasStreetGang: createField({
                    fieldName: fields.OFFENSE_HAS_STREET_GANG,
                }),
                biasDescriptionAttrId: createField({
                    fieldName: fields.OFFENSE_BIAS_DESCRIPTION_ATTR_ID,
                }),
                biasDescriptionOther: createField({
                    fieldName: fields.OFFENSE_BIAS_DESCRIPTION_OTHER,
                }),
                vehicleEntryPointAttrId: createField({
                    fieldName: fields.OFFENSE_VEHICLE_ENTRY_POINT_ATTR_ID,
                }),
                directionOfEntry1AttrId: createField({
                    fieldName: fields.OFFENSE_DIRECTION_OF_ENTRY_1_ATTR_ID,
                }),
                directionOfEntry2AttrId: createField({
                    fieldName: fields.OFFENSE_DIRECTION_OF_ENTRY_2_ATTR_ID,
                }),
                secondaryLocationCategoryAttrId: createField({
                    fieldName: fields.OFFENSE_SECONDARY_LOCATION_CATEGORY_ATTR_ID,
                }),
                nfibNumber: createField({
                    fieldName: fields.OFFENSE_NFIB_NUMBER,
                }),
            },
        }),
        // meta data used in validations, not directly related to offense card model data
        links: createFieldset({
            fields: {
                location: createFieldset({
                    // fill these fields when adding a location/ prefill from added location
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_LINK_TYPE,
                    fields: {
                        typeAttrId: createField({
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_TYPE_ATTR_ID,
                        }),
                        linkType: createField({
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_LINK_TYPE,
                        }),
                        positionAttrId: createField({
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_POSITION_ATTR_ID,
                        }),
                        description: createField({
                            fieldName:
                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_OFFENSE_LOCATION_DESCRIPTION,
                        }),
                    },
                }),
                victims: createNItems({
                    fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LINK_TYPE,
                    fields: {
                        ...buildFormConfigurationForCoreModel({
                            keyName: CUSTOM_PROPERTIES_KEY_NAME,
                            context: RefContextEnum.FORM_OFFENSE.name,
                            coreModelName: CoreModelEnum.NAME_REPORT_LINK.name,
                            genericFieldType: 'LINK_TYPE',
                            genericFieldTypeMappedId: LinkTypesEnum.VICTIM_IN_OFFENSE,
                            additionalData: params.additionalData,
                        }),
                        nameId: createField({
                            fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_NAME_ID,
                        }),
                        contextId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_CONTEXT_ID,
                        }),
                        entityType: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_ENTITY_TYPE,
                        }),
                        isLeoka: createField({
                            fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_LEOKA,
                        }),
                        leokaAssignmentTypeAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LEOKA_ASSIGNMENT_TYPE_ATTR_ID,
                        }),
                        leokaActivityAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LEOKA_ACTIVITY_ATTR_ID,
                        }),
                        leokaOtherJurisdictionOri: createField({
                            fieldName: fields.NAME_REPORT_LINK_LEOKA_OTHER_JURISDICTION_ORI,
                        }),
                        leokaOtherJurisdictionOriAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_LEOKA_OTHER_JURISDICTION_ORI_ATTR_ID,
                        }),
                        linkType: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_LINK_TYPE,
                        }),
                        // this data does not actually live on the link. It has been put
                        // here for convenience. If we add more information, then it will make
                        // sense to move the above fields into their own fieldset within this nitems wrapper
                        informationProvidedAttrIds: createField({
                            fieldName: fields.DISPLAY_ATTRIBUTE_INFORMATION_PROVIDED_TO_VICTIM,
                        }),
                        substanceAbuseAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_SUBSTANCE_ABUSE_ATTR_ID,
                        }),
                        victimAdvisedOfRemedies: createField({
                            fieldName: fields.NAME_REPORT_LINK_VICTIM_ADVISED_OF_REMEDIES,
                        }),
                        isVictimByAssociation: createField({
                            fieldName: fields.NAME_REPORT_LINK_IS_VICTIM_BY_ASSOCIATION,
                        }),
                        victimByAssociationTypeAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_VICTIM_BY_ASSOCIATION_TYPE_ATTR_ID,
                        }),
                        victimByAssociationRelationAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_VICTIM_BY_ASSOCIATION_RELATION_ATTR_ID,
                        }),
                        wasStatementTaken: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_WAS_STATEMENT_TAKEN,
                        }),
                        statement: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_STATEMENT,
                        }),
                        wasSearchPerformed: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_WAS_SEARCH_PERFORMED,
                        }),
                        dateDeclared: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_DATE_DECLARED,
                        }),
                        victimPriorPartyDifficultiesAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_VICTIM_PRIOR_PARTY_DIFFICULTIES_ATTR_ID,
                        }),
                        victimAppraisedOfRemediesAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_VICTIM_APPRAISED_OF_REMEDIES_ATTR_ID,
                        }),
                        victimReligionAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_VICTIM_RELIGION_ATTR_ID,
                        }),
                        isVictimPregnant: createField({
                            fieldName: fields.NAME_REPORT_LINK_IS_VICTIM_PREGNANT,
                        }),
                        isVictimOfCriminalNeglect: createField({
                            fieldName: fields.NAME_REPORT_LINK_IS_VICTIM_OF_CRIMINAL_NEGLECT,
                        }),
                        victimSuspectedDrugTypeAttrId: createField({
                            fieldName:
                                fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_VICTIM_SUSPECTED_DRUG_TYPE_ATTR_ID,
                        }),
                        isCollegeStudent: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_COLLEGE_STUDENT,
                        }),
                        schoolCodeAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_SCHOOL_CODE_ATTR_ID,
                        }),
                        isOffenseOnCampus: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_OFFENSE_ON_CAMPUS,
                        }),
                        isDomesticViolenceVictim: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_DOMESTIC_VIOLENCE_VICTIM,
                        }),
                        isVictimTransportation: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_VICTIM_TRANSPORTATION,
                        }),
                        isViolationOfOrderOfProtection: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_VIOLATION_OF_ORDER_OF_PROTECTION,
                        }),
                        isPreviousDomesticViolenceVictim: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_PREVIOUS_DOMESTIC_VIOLENCE_VICTIM,
                        }),
                    },
                }),
                suspects: createNItems({
                    fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_LINK_TYPE,
                    fields: {
                        ...buildFormConfigurationForCoreModel({
                            keyName: CUSTOM_PROPERTIES_KEY_NAME,
                            context: RefContextEnum.FORM_OFFENSE.name,
                            coreModelName: CoreModelEnum.NAME_REPORT_LINK.name,
                            genericFieldType: 'LINK_TYPE',
                            genericFieldTypeMappedId: LinkTypesEnum.SUSPECT_IN_OFFENSE,
                            additionalData: params.additionalData,
                        }),
                        howAggressorWasIdentifiedAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_HOW_AGGRESSOR_WAS_IDENTIFIED_ATTR_ID,
                        }),
                        howAggressorWasIdentifiedOtherDescription: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_HOW_AGGRESSOR_WAS_IDENTIFIED_OTHER_DESCRIPTION,
                        }),
                        substanceAbuseAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_SUBSTANCE_ABUSE_ATTR_ID,
                        }),
                        nameId: createField({
                            fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_NAME_ID,
                        }),
                        linkType: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_LINK_TYPE,
                        }),
                        contextId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_CONTEXT_ID,
                        }),
                        wasStatementTaken: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_WAS_STATEMENT_TAKEN,
                        }),
                        statement: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_STATEMENT,
                        }),
                        wasSearchPerformed: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_WAS_SEARCH_PERFORMED,
                        }),
                        offenderReligionAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_OFFENDER_RELIGION_ATTR_ID,
                        }),
                        clearedBySuicide: createField({
                            fieldName: fields.NAME_REPORT_LINK_CLEARED_BY_SUICIDE,
                        }),
                        offenderLivedWithVictimAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_OFFENDER_LIVED_WITH_VICTIM_ATTR_ID,
                        }),
                        restrainingOrderEverIssued: createField({
                            fieldName: fields.NAME_REPORT_LINK_RESTRAINING_ORDER_EVER_ISSUED,
                        }),
                        restrainingOrderIssued: createField({
                            fieldName: fields.NAME_REPORT_LINK_RESTRAINING_ORDER_ISSUED,
                        }),
                        restrainingOrderViolated: createField({
                            fieldName: fields.NAME_REPORT_LINK_RESTRAINING_ORDER_VIOLATED,
                        }),
                        suspectStatusAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_SUSPECT_STATUS_ATTR_ID,
                        }),
                        suspectSuspectedDrugTypeAttrId: createField({
                            fieldName:
                                fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_SUSPECT_SUSPECTED_DRUG_TYPE_ATTR_ID,
                        }),
                    },
                }),
                offenders: createNItems({
                    fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_LINK_TYPE,
                    fields: {
                        ...buildFormConfigurationForCoreModel({
                            keyName: CUSTOM_PROPERTIES_KEY_NAME,
                            context: RefContextEnum.FORM_OFFENSE.name,
                            coreModelName: CoreModelEnum.NAME_REPORT_LINK.name,
                            genericFieldType: 'LINK_TYPE',
                            genericFieldTypeMappedId: LinkTypesEnum.OFFENDER_IN_OFFENSE,
                            additionalData: params.additionalData,
                        }),
                        nameId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_NAME_ID,
                        }),
                        linkType: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_LINK_TYPE,
                        }),
                        wasStatementTaken: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_WAS_STATEMENT_TAKEN,
                        }),
                        statement: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_STATEMENT,
                        }),
                        wasSearchPerformed: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OFFENDER_IN_OFFENSE_WAS_SEARCH_PERFORMED,
                        }),
                        offenderLivedWithVictimAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_OFFENDER_LIVED_WITH_VICTIM_ATTR_ID,
                        }),
                        substanceAbuseAttrId: createField({
                            fieldName: fields.NAME_REPORT_LINK_SUBSTANCE_ABUSE_ATTR_ID,
                        }),
                        suspectStatusAttrId: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUSPECT_IN_OFFENSE_SUSPECT_STATUS_ATTR_ID,
                        }),
                        suspectSuspectedDrugTypeAttrId: createField({
                            fieldName:
                                fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_SUSPECT_SUSPECTED_DRUG_TYPE_ATTR_ID,
                        }),
                    },
                }),
                // empty `NItems` so we have a place to put witnesses and other names,
                // even though we are not going to modify them in the form.
                // this allows us to easily access them when we submit the form
                witnesses: createNItems({
                    fields: {
                        ...buildFormConfigurationForCoreModel({
                            keyName: CUSTOM_PROPERTIES_KEY_NAME,
                            context: RefContextEnum.FORM_OFFENSE.name,
                            coreModelName: CoreModelEnum.NAME_REPORT_LINK.name,
                            genericFieldType: 'LINK_TYPE',
                            genericFieldTypeMappedId: LinkTypesEnum.WITNESS_IN_OFFENSE,
                            additionalData: params.additionalData,
                        }),
                        wasStatementTaken: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_WAS_STATEMENT_TAKEN,
                        }),
                        statement: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_STATEMENT,
                        }),
                        wasSearchPerformed: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_WITNESS_IN_OFFENSE_WAS_SEARCH_PERFORMED,
                        }),
                    },
                }),
                otherNames: createNItems({
                    fields: {
                        ...buildFormConfigurationForCoreModel({
                            keyName: CUSTOM_PROPERTIES_KEY_NAME,
                            context: RefContextEnum.FORM_OFFENSE.name,
                            coreModelName: CoreModelEnum.NAME_REPORT_LINK.name,
                            genericFieldType: 'LINK_TYPE',
                            genericFieldTypeMappedId: LinkTypesEnum.OTHER_NAME_IN_OFFENSE,
                            additionalData: params.additionalData,
                        }),
                        wasStatementTaken: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_OFFENSE_WAS_STATEMENT_TAKEN,
                        }),
                        statement: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_OFFENSE_STATEMENT,
                        }),
                        wasSearchPerformed: createField({
                            fieldName:
                                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_OFFENSE_WAS_SEARCH_PERFORMED,
                        }),
                    },
                }),
                subCrimeIds: createField({
                    fieldName: fields.OFFENSE_SUB_CRIME_LINK_OFFENSE_CODE_ID,
                }),
            },
        }),
        offenseAttributes: createFieldset({
            fields: [
                [
                    AttributeTypeEnum.BIAS_MOTIVATION.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_BIAS_MOTIVATION_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_BIAS_MOTIVATION_DESCRIPTION,
                    fields.DISPLAY_ONLY_BIAS_MOTIVATION_WRAPPER,
                ],
                [
                    AttributeTypeEnum.AGGRAVATED_ASSAULT_CIRCUMSTANCE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_AGGRAVATED_ASSAULT_CIRCUMSTANCE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_AGGRAVATED_ASSAULT_CIRCUMSTANCE_DESCRIPTION,
                    fields.DISPLAY_ONLY_AGGRAVATED_ASSAULT_CIRCUMSTANCE_WRAPPER,
                ],
                [
                    AttributeTypeEnum.MEANS_OF_APPROACH.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_MEANS_OF_APPROACH_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_MEANS_OF_APPROACH_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_OR_FORCE_INVOLVED_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_OR_FORCE_INVOLVED_DESCRIPTION,
                    fields.DISPLAY_ONLY_WEAPON_OR_FORCE_INVOLVED_WRAPPER,
                ],
                [
                    AttributeTypeEnum.IMPLEMENT_WEAPON_INVOLVED.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_IMPLEMENT_WEAPON_INVOLVED_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_IMPLEMENT_WEAPON_INVOLVED_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.HOMICIDE_CIRCUMSTANCE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_HOMICIDE_CIRCUMSTANCE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_HOMICIDE_CIRCUMSTANCE_DESCRIPTION,
                    fields.DISPLAY_ONLY_HOMICIDE_CIRCUMSTANCE_WRAPPER,
                ],
                [
                    AttributeTypeEnum.CRIMINAL_ACTIVITY_CATEGORY.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_CRIMINAL_ACTIVITY_CATEGORY_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_CRIMINAL_ACTIVITY_CATEGORY_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.ANIMAL_CRUELTY_CATEGORY.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ANIMAL_CRUELTY_CATEGORY_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ANIMAL_CRUELTY_CATEGORY_DESCRIPTION,
                    fields.DISPLAY_ONLY_ANIMAL_CRUELTY_CATEGORY_WRAPPER,
                ],
                [
                    AttributeTypeEnum.GANG_INFORMATION.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_GANG_INFORMATION_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_GANG_INFORMATION_DESCRIPTION,
                    fields.DISPLAY_ONLY_GANG_INFORMATION_WRAPPER,
                ],
                [
                    AttributeTypeEnum.MODUS_OPERANDI.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_MODUS_OPERANDI_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.OFFENDER_CONTRIBUTING_FACTORS.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENDER_CONTRIBUTING_FACTORS_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENDER_CONTRIBUTING_FACTORS_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.ROUTE_OF_APPROACH.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ROUTE_OF_APPROACH_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ROUTE_OF_APPROACH_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.SECURITY_SYSTEM.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SECURITY_SYSTEM_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SECURITY_SYSTEM_DESCRIPTION,
                    fields.DISPLAY_ONLY_SECURITY_SYSTEM_WRAPPER,
                ],
                [
                    AttributeTypeEnum.OFFENSE_STATISTIC.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENSE_STATISTIC_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENSE_STATISTIC_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.ABUSE_TYPE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ABUSE_TYPE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ABUSE_TYPE_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.ACTION_AT_SCENE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ACTION_AT_SCENE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ACTION_AT_SCENE_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.POLICE_ACTION.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_POLICE_ACTION_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_POLICE_ACTION_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.LARCENY_TYPE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_LARCENY_TYPE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_LARCENY_TYPE_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.INTERNET_INVOLVEMENT_TYPE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_INTERNET_INVOLVEMENT_TYPE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_INTERNET_INVOLVEMENT_TYPE_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.SUSPECTED_METAL_THEFT.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SUSPECTED_METAL_THEFT_ATTRIBUTE_ID,
                ],
                [
                    AttributeTypeEnum.METHOD_OF_ENTRY_EXIT.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_METHOD_OF_ENTRY_EXIT_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_METHOD_OF_ENTRY_EXIT_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.ADDITIONAL_OTHER_INFORMATION.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ADDITIONAL_OTHER_INFORMATION_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_ADDITIONAL_OTHER_INFORMATION_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.VEHICLE_MO.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_VEHICLE_MO_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_VEHICLE_MO_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.HONOR_BASED_ABUSE_TYPE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_HONOR_BASED_ABUSE_TYPE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_HONOR_BASED_ABUSE_TYPE_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.POINT_OF_ENTRY_EXIT.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_POINT_OF_ENTRY_EXIT_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_POINT_OF_ENTRY_EXIT_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.DISTRACTION_FRAUD_ACTIVITY.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_DISTRACTION_FRAUD_ACTIVITY_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_DISTRACTION_FRAUD_ACTIVITY_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.SAFEGUARDING_MO.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SAFEGUARDING_MO_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_SAFEGUARDING_MO_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.UK_OFFENSE_CODE_CATEGORY.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_UK_OFFENSE_CODE_CATEGORY_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_UK_OFFENSE_CODE_CATEGORY_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.DRUG_INVOLVEMENT.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_DRUG_INVOLVEMENT_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_DRUG_INVOLVEMENT_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.OFFENSE_SCENE_OF_CRIME_LOCATION.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENSE_SCENE_OF_CRIME_LOCATION_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OFFENSE_SCENE_OF_CRIME_LOCATION_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.EXTENDED_OFFENSE_SUSPECTED_ALCOHOL_USE.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_EXTENDED_OFFENSE_SUSPECTED_ALCOHOL_USE_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_EXTENDED_OFFENSE_SUSPECTED_ALCOHOL_USE_DESCRIPTION,
                ],
                [
                    AttributeTypeEnum.OTHER_MODUS_OPERANDI.name,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OTHER_MODUS_OPERANDI_ATTRIBUTE_ID,
                    fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_OTHER_MODUS_OPERANDI_DESCRIPTION,
                ],
            ].reduce(
                (acc, [attributeType, idsFieldName, descriptionFieldName, wrapperFieldName]) => {
                    const fieldsetConfig = {
                        fields: {
                            attributeIds: createField({
                                fieldName: idsFieldName,
                            }),
                            description: createField({
                                fieldName: descriptionFieldName,
                            }),
                        },
                    };
                    if (wrapperFieldName) {
                        fieldsetConfig.fieldName = wrapperFieldName;
                    }
                    acc[attributeType] = createFieldset(fieldsetConfig);
                    return acc;
                },
                {}
            ),
        }),
    });

/**
 * wasStatementTaken is a transient property on the BE calculated from the value of statement.
 * We need to properly clear it here before sending to the API.
 */
const cleanStatementDataForLinks = (nameReportLinks) => {
    return map(nameReportLinks, (nrl) => {
        if (!nrl.wasStatementTaken) {
            nrl.statement = undefined;
        }

        return nrl;
    });
};

export const convertFromFormModel = (formModel, additionalData = {}) => {
    const { nibrsOffenseCodes, nibrsOffenseCodeForOffenseCodeId, attributeIsOther } =
        additionalData;
    const { offense, offenseAttributes, links } = formModel;
    const { offenseInvolvedChildren } = offense;

    // Try to default a nibrs code before proceeding with save / validation
    const nibrsCode =
        nibrsOffenseCodes[offense.nibrsOffenseCodeId] ||
        nibrsOffenseCodeForOffenseCodeId(offense.offenseCodeId);
    const nibrsCodeCode = get(nibrsCode, 'code');
    const offenseWithNibrsCode = {
        ...offense,
        nibrsCode,
        nibrsOffenseCodeId: get(nibrsCode, 'id'),
        nibrsCodeCode,
    };

    const attributes = mapOffenseAttributes({
        attributeIsOther,
        offense: offenseWithNibrsCode,
        offenseAttributes,
    });

    const subCrimeLinks =
        links.subCrimeIds?.map((offenseCodeId) => ({
            offenseId: offense.id,
            offenseCodeId,
        })) || [];

    return {
        offense: offenseWithNibrsCode,
        attributes,
        links: [
            ...cleanStatementDataForLinks(links.victims),
            ...cleanStatementDataForLinks(links.suspects),
            ...cleanStatementDataForLinks(links.offenders),
            // saving witnesses and other names here as well
            // even though they don't have any custom data associated with them.
            // this will be a non-issue once we build out an offense-card-bundle endpoint.
            // If this is not necessary at all we can remove these lines and purge
            // both witnesses and other names from MFT form state
            ...cleanStatementDataForLinks(links.witnesses),
            ...cleanStatementDataForLinks(links.otherNames),
        ],
        // We only support one location for now,
        // but building out array support
        locationEntityLinks: reject([links.location], objectValuesAreEmpty),
        subCrimeLinks,
        offenseInvolvedChildren,
    };
};

export const convertToFormModel = ({
    offense,
    offenseAttributes,
    offenseCode,
    offenseSubCrimeLinks,
    nameReportLinks,
    nameAttributes,
    locationLinks,
    report,
    offenseInvolvedChildren,
    statuteCodeSetAttributes,
    departmentSubDomain,
    fieldConfigurationContextByContextAndFieldName,
    propertyStatuses,
}) => {
    const informationProvidedAttributes = filter(nameAttributes, {
        attributeType: AttributeTypeEnum.INFORMATION_PROVIDED_TO_VICTIM.name,
    });
    const suspectedDrugTypeAttributes = filter(nameAttributes, {
        attributeType: AttributeTypeEnum.SUSPECTED_DRUG_TYPE.name,
    });
    const offenseAttributesByType = chain(offenseAttributes)
        .groupBy('attributeType')
        .mapValues((attributesByType) => {
            // UK_OFFENSE_CODE_CATEGORY is a single pick, if it is cast into an array, rules
            // will not behave as expected.
            return attributesByType[0]?.attributeType ===
                AttributeTypeEnum.UK_OFFENSE_CODE_CATEGORY.name
                ? {
                      attributeIds: attributesByType[0].attributeId,
                  }
                : {
                      attributeIds: map(attributesByType, 'attributeId'),
                      description: getDescriptionForAttributeLinks(attributesByType),
                  };
        })
        .value();
    const sortedNameReportLinks = sortBy(nameReportLinks, 'linkTypeSequenceNumber');
    const victims = _(sortedNameReportLinks)
        .filter((nameReportLink) => nameReportLink.linkType === LinkTypesEnum.VICTIM_IN_OFFENSE)
        .map((nameReportLink) => ({
            isLeoka: undefined,
            leokaAssignmentTypeAttrId: undefined,
            leokaActivityAttrId: undefined,
            leokaOtherJurisdictionOri: undefined,
            leokaOtherJurisdictionOriAttrId: undefined,
            ...nameReportLink,
            informationProvidedAttrIds: convertNameAttributesDataStateToFormState(
                informationProvidedAttributes,
                nameReportLink.nameId,
                true
            ),
            suspectedDrugTypeAttrIds: [],
        }))
        .value();
    const suspects = _(sortedNameReportLinks)
        .filter((nameReportLink) => nameReportLink.linkType === LinkTypesEnum.SUSPECT_IN_OFFENSE)
        .map((nameReportLink) => ({
            ...nameReportLink,
            suspectSuspectedDrugTypeAttrId: convertNameAttributesDataStateToFormState(
                suspectedDrugTypeAttributes,
                nameReportLink.nameId,
                true
            ),
        }))
        .value();
    const offenders = _(sortedNameReportLinks)
        .filter((nameReportLink) => nameReportLink.linkType === LinkTypesEnum.OFFENDER_IN_OFFENSE)
        .map((nameReportLink) => ({
            ...nameReportLink,
            suspectedDrugTypeAttrIds: convertNameAttributesDataStateToFormState(
                suspectedDrugTypeAttributes,
                nameReportLink.nameId,
                true
            ),
        }))
        .value();
    const witnesses = filter(
        sortedNameReportLinks,
        (nameReportLink) => nameReportLink.linkType === LinkTypesEnum.WITNESS_IN_OFFENSE
    );
    const otherNames = filter(
        sortedNameReportLinks,
        (nameReportLink) => nameReportLink.linkType === LinkTypesEnum.OTHER_NAME_IN_OFFENSE
    );
    const location = find(
        locationLinks,
        (locationLink) => locationLink.linkType === LinkTypesEnum.OFFENSE_LOCATION
    );
    const sortedChildren = sortBy(offenseInvolvedChildren, ['childName']);

    const statuteCodeSetFilterFieldContext = fieldConfigurationContextByContextAndFieldName(
        RefContextEnum.FORM_OFFENSE.name,
        fields.DISPLAY_ONLY_OFFENSE_CODE_STATUTE_CODE_SET_FILTER
    );
    const statuteCodeSetFilter = convertOffenseCodeToStatuteCodeSetFilter(
        offenseCode,
        statuteCodeSetAttributes,
        departmentSubDomain,
        !!get(statuteCodeSetFilterFieldContext, 'isStaticallyHidden')
    );
    const subCrimeIds = offenseSubCrimeLinks.map(({ offenseCodeId }) => offenseCodeId);

    const statusDateUtc = propertyStatuses
        ? getEarliestDate(
              propertyStatuses
                  .map((ps) => ps.statusDateUtc)
                  .filter((date) => date !== undefined && date !== null)
          )
        : undefined;

    return {
        reportCreatedDateUtc: report.createdDateUtc,
        propertyStatusDateUtc: statusDateUtc,
        statuteCodeSetFilter,
        offense: {
            ...offense,
            offenseInvolvedChildren: sortedChildren,
            isOffenseDateUnknown: isUndefinedOrNull(offense.isOffenseDateUnknown)
                ? false
                : offense.isOffenseDateUnknown,
            offenseCodeCode: offenseCode?.code,
        },
        offenseAttributes: offenseAttributesByType,
        links: {
            victims,
            suspects,
            offenders,
            location,
            witnesses,
            otherNames,
            subCrimeIds,
        },
    };
};

/**
 * Run Conditional Rules on Offense Card form. Return conditional arguments if any of the rules
 *   succeed.
 * @param {Object} arbiter arbiter instance
 * @param {Object} form    markformythree form
 * @param {Object} [dryRun=false] trigger dry run if form isn't mounted yet
 * @return {Object}
 */
export function runOffenseCardConditionalRules(arbiter, form, dryRun = false) {
    const context = form.name;
    const formModel = form.getState().model;
    const arbiterData = formDataToArbiterDataMFT(formModel, form.getConfiguration());

    // arbiter will not respect ruleTypes config, if we don't dryRun first,
    // because rules cache isn't built.
    // by default forms will dryRun on mount, but if form is not yet mounted force dry run
    if (dryRun) {
        arbiter.runRules(context, arbiterData, { dryRun });
    }
    const results = arbiter.runRules(context, arbiterData, { ruleTypes: [ruleTypes.CONDITIONAL] });

    // in offense card, all conditional rules are mutually exclusive, so we only need to find one
    const result = find(
        results,
        ({ success, ruleView }) =>
            // ignore any non-CONDITIONAL rules, namely FIELD_CONSTRAINT as of Arbiter 1.8.0
            !!success && get(ruleView, 'rule.ruleType') === ruleTypes.CONDITIONAL
    );
    // check result has conditional argument data, not just boolean success.
    if (!result || result.success === true) {
        return {};
    }

    return result.success;
}

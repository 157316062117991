import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { currentWarrantIdSelector } from '../state/ui';
import Page from '../../../core/components/Page';

function WarrantContainer({ children }) {
    return <Page>{children}</Page>;
}

const mapStateToProps = createStructuredSelector({
    currentWarrantId: currentWarrantIdSelector,
});

export default connect(mapStateToProps)(WarrantContainer);

import { map } from 'lodash';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Case Resource',
    methods: {
        getCaseDefaults(caseDefaultsRequest) {
            return req({
                method: 'POST',
                url: 'cases/defaults',
                data: caseDefaultsRequest,
            });
        },
        createCase(caseCreationRequest) {
            return req({
                method: 'POST',
                url: 'cases',
                data: caseCreationRequest,
            });
        },
        /**
         * POST /batch_operations is an endpoint that makes requests to another endpoint, which is specified in `operation`.
         *
         * Each request uses `targets` and `args` to pass parameters into the method of the operation.
         *
         * Here, the method is createCaseBatchItem in CaseResource.java, which has 2 parameters.
         *
         * The first parameter is a REN string, which corresponds to each string in the reportingEventNumbers array.
         * - Confusingly, `targets` is a JSON object which contains a property that is also called `targets`, which is
         *   an array of JSON values. Here, each JSON value happens to be a string.
         * - The number of values in this array determines the number of requests.
         *
         * The second parameter is a CaseCreationBatchItemRequest, which is the only element of the `args` array.
         * - Unlike `targets`, which is looped over for each request, `args` is the same for every operation. The back
         *   end does not support using different values of `args` for different requests. Use `targets` for that
         *   purpose. See BatchOperationItemCreatedSubscriber.
         * - If the method createCaseBatchItem accepted N arguments instead, then `args` would have to an array of size N-1.
         * - Here, the caseModel object is always passed in as the second parameter, CaseCreationBatchItemRequest, for
         *   every request.
         */
        bulkCreateCases(reportingEventNumbers, caseModel) {
            return req({
                method: 'POST',
                url: 'batch_operations',
                data: {
                    operation: 'POST /cases/{ren}/batch/item',
                    targets: JSON.stringify({
                        targets: reportingEventNumbers,
                    }),
                    args: JSON.stringify([caseModel]),
                },
            });
        },
        /**
         * See bulkCreateCases above for an explanation of batch operations.
         *
         * Here, the method is createCaseBatchItemForMultiRENAndRWR in CaseResource.java, which has only 1 parameter, a
         * CaseCreationBatchItemRequest.
         * - The number of values in the given array determines the number of requests.
         * - The CaseCreationBatchItemRequest object is different for each request.
         *
         * This is a sample request payload.
         * {
         *   "operation": "POST /cases/batch/item/multi_ren_rwr",
         *   "targets": "{\"targets\":[\"{\\\"theCase\\\":{\\\"assignedDateUtc\\\":\\\"2023-05-24T15:53:44.165Z\\\",\\\"assignedPersonnelUnitAttrId\\\":5537631190,\\\"caseDefinitionId\\\":5537794401,\\\"externalReportStatus\\\":{}},\\\"caseStatus\\\":{\\\"statusAttrId\\\":5537636238},\\\"assistingInvestigatorRoleIds\\\":[],\\\"supervisorRoleIds\\\":[],\\\"caseReportLinkCreationFromCaseRequest\\\":{\\\"linkedReportREN\\\":\\\"5119233811\\\"}}\",\"{\\\"theCase\\\":{\\\"assignedDateUtc\\\":\\\"2023-05-24T15:53:44.165Z\\\",\\\"assignedPersonnelUnitAttrId\\\":5537631190,\\\"caseDefinitionId\\\":5537794401,\\\"externalReportStatus\\\":{}},\\\"caseStatus\\\":{\\\"statusAttrId\\\":5537636238},\\\"assistingInvestigatorRoleIds\\\":[],\\\"supervisorRoleIds\\\":[],\\\"caseReportLinkCreationFromCaseRequest\\\":{\\\"linkedRecordWithoutRENId\\\":41470259733}}\",\"{\\\"theCase\\\":{\\\"assignedDateUtc\\\":\\\"2023-05-24T15:53:44.165Z\\\",\\\"assignedPersonnelUnitAttrId\\\":5537631190,\\\"caseDefinitionId\\\":5537794401,\\\"externalReportStatus\\\":{}},\\\"caseStatus\\\":{\\\"statusAttrId\\\":5537636238},\\\"assistingInvestigatorRoleIds\\\":[],\\\"supervisorRoleIds\\\":[],\\\"caseReportLinkCreationFromCaseRequest\\\":{\\\"linkedReportREN\\\":\\\"21-47438483\\\"}}\",\"{\\\"theCase\\\":{\\\"assignedDateUtc\\\":\\\"2023-05-24T15:53:44.165Z\\\",\\\"assignedPersonnelUnitAttrId\\\":5537631190,\\\"caseDefinitionId\\\":5537794401,\\\"externalReportStatus\\\":{}},\\\"caseStatus\\\":{\\\"statusAttrId\\\":5537636238},\\\"assistingInvestigatorRoleIds\\\":[],\\\"supervisorRoleIds\\\":[],\\\"caseReportLinkCreationFromCaseRequest\\\":{\\\"linkedRecordWithoutRENId\\\":41470259923}}\"]}",
         *   "args": "[]"
         * }
         *
         * Since there is only 1 parameter, we provide only `targets`.
         * - The `args` array is empty because it'd only be used for a second parameter, a third parameter, and so on.
         * - Confusingly, `targets` is a JSON object which contains a property that is also called `targets`, which is
         *   an array of JSON values. Unlike bulkCreateCases, each JSON value here is a CaseCreationBatchItemRequest
         *   object, hence the nested call to JSON.stringify().
         * - Each \" is from the first JSON.stringify() call. It is escaped to " when `targets` is parsed as a string.
         * - Each \\\" is from the second JSON.stringify() call. It is escaped to \" when `targets` is first parsed as a
         *   string, and then is escaped to " when each element in the array is parsed as JSON.
         */
        bulkCreateCasesForAnyReport(caseCreationBatchItemRequests) {
            return req({
                method: 'POST',
                url: 'batch_operations',
                data: {
                    operation: 'POST /cases/batch/item/multi_ren_rwr',
                    targets: JSON.stringify({
                        targets: map(caseCreationBatchItemRequests, (object) =>
                            JSON.stringify(object)
                        ),
                    }),
                    args: JSON.stringify([]),
                },
            });
        },
        updateCase(caseId, caseModel) {
            return req({
                method: 'PUT',
                url: `cases/${caseId}`,
                data: caseModel,
            });
        },
        batchUpdateCases(caseIds, caseModel) {
            return req({
                method: 'POST',
                url: 'batch_operations',
                data: {
                    operation: 'PUT /cases/{id}/batch/item',
                    targets: JSON.stringify({
                        targets: caseIds,
                    }),
                    args: JSON.stringify([caseModel]),
                },
            });
        },
        isCaseDeletable(id) {
            return req({
                method: 'GET',
                url: `cases/${id}/is_deletable`,
            });
        },
        deleteCase(id) {
            return req({
                method: 'DELETE',
                url: `cases/${id}`,
            });
        },
        getCompleteCaseView(caseId, onlyOrphanContent) {
            return req({
                method: 'GET',
                url: `cases/${caseId}/full?only_orphan_content=${onlyOrphanContent}`,
            });
        },
        getCasesForDepartment() {
            return req({
                method: 'GET',
                url: 'cases',
            });
        },
        getCasesForReport(reportId) {
            return req({
                method: 'GET',
                url: `report/${reportId}/cases`,
            });
        },
        getCaseDetails(caseId) {
            return req({
                method: 'GET',
                url: `cases/${caseId}/details`,
            });
        },
        getBulkCaseDetails(caseIds) {
            return req({
                method: 'POST',
                url: `cases/bulk/details`,
                data: caseIds,
            });
        },
        setRoleLinks(caseId, links, sendEmails = false) {
            return req({
                method: 'PUT',
                url: `cases/${caseId}/links/roles`,
                data: links,
                params: { sendEmails },
            });
        },
        // case notes
        addNoteToCase(caseNote, folderId) {
            const params = folderId ? { folder_id: folderId } : {};
            return req({
                method: 'POST',
                url: `cases/${caseNote.caseId}/notes`,
                data: caseNote,
                params: { ...params },
            });
        },
        updateCaseNote(caseNote) {
            return req({
                method: 'PUT',
                url: `cases/${caseNote.caseId}/notes/${caseNote.id}`,
                data: caseNote,
            });
        },
        deleteCaseNotes(noteIds) {
            return req({
                method: 'POST',
                url: `cases/notes/delete/bulk`,
                data: noteIds,
            });
        },
        getCaseNotesForCase(caseId) {
            return req({
                method: 'GET',
                url: `cases/${caseId}/notes`,
            });
        },
        // case status
        updateCaseStatus(caseId, caseStatus) {
            return req({
                method: 'PUT',
                url: `cases/${caseId}/status`,
                data: caseStatus,
            });
        },
        // case review
        addReviewToCase(caseReviewRequest) {
            const { caseId } = caseReviewRequest.caseReview;
            return req({
                method: 'POST',
                url: `cases/${caseId}/reviews`,
                data: caseReviewRequest,
            });
        },
        // case approval status
        updateCaseApprovalStatus(caseApprovalStatus) {
            const { caseId } = caseApprovalStatus;
            return req({
                method: 'PUT',
                url: `cases/${caseId}/approval_status`,
                data: caseApprovalStatus,
            });
        },
        // case history
        getCaseHistory(caseId) {
            return req({
                method: 'GET',
                url: `cases/${caseId}/history`,
            });
        },
        // case tasks
        createTaskForCase(caseId, task) {
            return req({
                method: 'POST',
                url: `cases/${caseId}/tasks`,
                data: task,
            });
        },
        updateTaskForCase(caseId, taskId, task) {
            return req({
                method: 'PUT',
                url: `cases/${caseId}/tasks/${taskId}`,
                data: task,
            });
        },
        deleteTaskForCase(caseId, taskId) {
            return req({
                method: 'DELETE',
                url: `cases/${caseId}/tasks/${taskId}`,
            });
        },
        updateCaseBundle(caseId, caseUpdateRequest) {
            return req({
                method: 'PUT',
                url: `cases/${caseId}/bundle`,
                data: caseUpdateRequest,
            });
        },
        applyCasePermissionsToAllReportsInCase(caseId) {
            return req({
                method: 'PUT',
                url: `cases/${caseId}/permissions/reports`,
            });
        },
        getAcceptableReportsForCase(caseId) {
            return req({
                method: 'GET',
                url: `cases/acceptable/${caseId}`,
            });
        },
    },
});

import React from 'react';
import styled from 'styled-components';

// components
import Card from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';

const PaddedDiv = styled(SummaryList)`
    padding: 0 10px 0 10px;
    width: 100%;
`;

const StyledCard = styled(Card)`
    background-color: ${(props) => props.theme.colors.lightBlue};

    &&& .mark43-react-card-content {
        border-color: ${(props) => props.theme.colors.mediumBlue};
    }
`;

const ReportInfoMessage = ({ children }) => (
    <StyledCard hideHeader={true} hideFooter={true}>
        <PaddedDiv>{children}</PaddedDiv>
    </StyledCard>
);

export default ReportInfoMessage;

import React from 'react';

/**
 * This is the second of two identical loading screens with the Mark43 logo.
 *
 * The first loading screen is in index_template.html (which is served at index.html) and appears as soon as the user
 * opens the RMS, before the React component tree is mounted. This second loading screen appears after that, during the
 * authentication requests. There is no transition between these two screens, and it is very possible that the second
 * screen doesn't appear at all.
 *
 * These comment aren't written in index_template.html only because that source is published publicly.
 */
export default function LoadingMask() {
    const doc = document.documentElement;

    /**
     * index_template.html has viewport width=970 because most pages are not responsive.
     * After every RMS page is responsive, it will change to width=device-width.
     * The few pages that are responsive use the useViewport() hook.
     *
     * In order to correctly center the logo in all devices, we compute the actual width and height of the screen.
     * There is no pure css solution due to the viewport width being 970px.
     * When the screen width is less than 970px, say 480px, there is no css value that evaluates to 480px.
     *     100% and 100vh are still 970x. 480px is window.outerWidth and 970px is window.innerWidth.
     * Conversely, on an iPad, when the screen width is 820px, window.outerWidth is 1200px and window.innerWidth is 820px.
     * We take the minimum to handle both scenarios.
     *
     * The same goes for height. Sometimes the document's clientHeight is 1px less than either window height.
     */
    const width = Math.min(window.outerWidth, window.innerWidth, doc.clientWidth);
    const height = Math.min(window.outerHeight, window.innerHeight, doc.clientHeight);

    /**
     * .loading-mask is the black background that takes up the entire 970px+ wide viewport, which may be larger than the screen
     *     if this element didn't exist, the user would see a white background at the edges when they scroll
     * #loading-viewport takes up the entire size of the screen, inside which the logo is centered
     * .loading-wrapper is the div with the logo
     */
    return (
        <div className="loading-mask">
            <div
                id="loading-viewport"
                style={{
                    width,
                    height,
                }}
            >
                <div className="loading-wrapper" />
            </div>
        </div>
    );
}

// core deps
import { createSelector } from 'reselect';
import _, { map, mapValues, omit } from 'lodash';

// selectors
import { elasticVehiclesSelector } from '../data';
import {
    attributeIsOtherSelector,
    formatAttributeByIdSelector,
    formatSubdivisionAttrIdsSelector,
} from '../../../attributes/state/data';
import { formatVehicleMakeByIdSelector } from '../../../vehicle-makes/state/ui';
import { formatVehicleModelByIdSelector } from '../../../vehicle-models/state/ui';

// view model helpers
import {
    buildViewModel,
    subdivisionAttrIdsMapper,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatLocationLinkTypeId } from '../../../../../helpers/linkTypesHelpers';
import {
    formatTitleForVehicle,
    formatVehicleMakeModel,
    vehicleMakeModelDisplayProperties,
} from '../../../vehicles/utils/vehicleHelpers';
import { formatFieldByNameSelector } from '../../../../fields/state/config';

const buildElasticVehicleViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatVehicleMakeByIdSelector,
    formatVehicleModelByIdSelector,
    formatSubdivisionAttrIdsSelector,
    formatFieldByNameSelector,
    (
        formatAttributeById,
        formatVehicleMakeById,
        formatVehicleModelById,
        formatSubdivisionAttrIds,
        formatFieldByName
    ) =>
        buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                subdivisionAttrIdsMapper,
                (obj) =>
                    _(obj)
                        .pick('type')
                        .mapValues((type) => formatLocationLinkTypeId(type, formatFieldByName))
                        .value(),
                ({ vehicleMakeId, vehicleMakeOther, vehicleModelId, vehicleModelOther }) => {
                    const vehicleMakeModelDisplayProps = vehicleMakeModelDisplayProperties({
                        vehicleMakeName: formatVehicleMakeById(vehicleMakeId),
                        vehicleModelName: formatVehicleModelById(vehicleModelId),
                    });

                    return {
                        vehicleMakeModel: formatVehicleMakeModel({
                            vehicleMakeOther,
                            vehicleModelOther,
                            ...vehicleMakeModelDisplayProps,
                        }),
                    };
                },
            ],
            helpers: {
                formatAttributeById,
                formatSubdivisionAttrIds,
            },
        })
);

export const elasticVehicleViewModelsSelector = createSelector(
    elasticVehiclesSelector,
    buildElasticVehicleViewModelSelector,
    (elasticVehicles, buildElasticVehicleViewModel) =>
        mapValues(elasticVehicles, buildElasticVehicleViewModel)
);

export function createVehiclesResultsSelector(baseSelector) {
    return createSelector(
        baseSelector,
        buildElasticVehicleViewModelSelector,
        (vehicleResults, buildElasticVehicleViewModel) =>
            map(vehicleResults, buildElasticVehicleViewModel)
    );
}

/**
 * @param  {Object} [elasticVehicle] ElasticVehicle.
 * @param  {string|string[]} [options.omitProps]
 * @return {string}
 */
export const formatTitleForElasticVehicleSelector = createSelector(
    formatAttributeByIdSelector,
    attributeIsOtherSelector,
    (formatAttributeById, attributeIsOther) => (elasticVehicle = {}, { omitProps } = {}) => {
        const { vehicle = {} } = elasticVehicle;
        const vehicleMakeModelDisplayProps = vehicleMakeModelDisplayProperties({
            vehicleMakeIsOther: attributeIsOther(vehicle.vehicleMakeAttrId),
            vehicleMakeName: vehicle.vehicleMakeName,
            vehicleModelIsOther: attributeIsOther(vehicle.vehicleModelAttrId),
            vehicleModelName: vehicle.vehicleModelName,
        });
        const vehicleProps = {
            description: vehicle.description,
            itemCategory: formatAttributeById(vehicle.itemCategoryAttrId),
            vehicleMakeOther: vehicle.vehicleMakeOther,
            vehicleModelOther: vehicle.vehicleModelOther,
            yearOfManufacture: vehicle.yearOfManufacture,
            ...vehicleMakeModelDisplayProps,
        };

        return formatTitleForVehicle(omit(vehicleProps, omitProps));
    }
);

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TaskView } from '@mark43/rms-api';
import { refreshTasksDashboard } from '../../../../tasks/dashboard/state/ui';
import TaskSidePanel from '../../../../tasks/core/components/TaskSidePanel';
import { TasksTableContext } from '../../../../tasks/core/components/TasksTable/TasksTableContext';
import { useEFileContext } from '../../../hooks';
import { EFileTasksHeader } from './EFileTasksHeader';
import { EFileTasksTable } from './EFileTasksTable';

export const EFileTasksPage = () => {
    const dispatch = useDispatch();
    const { getters } = useEFileContext();
    const eFile = getters.efile.getEFile();
    const eFileId = eFile?.efile.id;
    const [modalTask, setModalTask] = useState<TaskView>();

    const refreshTasks = useCallback(() => {
        dispatch(refreshTasksDashboard());
    }, [dispatch]);

    const tasksTableContextValue = useMemo(
        () => ({
            refreshTasks,
            setModalTask,
            modalTask,
        }),
        [modalTask, refreshTasks]
    );

    if (!eFileId) {
        return null;
    }

    return (
        <div>
            <TasksTableContext.Provider value={tasksTableContextValue}>
                <EFileTasksHeader />
                <EFileTasksTable eFileId={eFileId} />
            </TasksTableContext.Provider>
            <TaskSidePanel onSave={refreshTasks} />
        </div>
    );
};

import { loadInventoryItems } from '~/client-common/core/domain/inventory-items/state/data/';
import { closeLoadingModal, openLoadingModal } from '../../../legacy-redux/actions/boxActions';
import redirectToErrorPage from '../../core/utils/redirectToErrorPage';

export function onEnter({ params: { id: inventoryId } }) {
    this.dispatch(openLoadingModal());
    this.dispatch(loadInventoryItems(inventoryId))
        .then(() => this.dispatch(closeLoadingModal()))
        .catch((err) => {
            this.dispatch(redirectToErrorPage({ errorCode: err.code }));
            throw err;
        });
}

import React, { useCallback } from 'react';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';

import Modal from '../../../core/overlays/components/Modal';
import casePhotoLineupResource from '../../core/resources/casePhotoLineupResource';
import { useLineupContext } from '../state/ui';

const overlayId = overlayIdEnum.DELETE_LINEUP_MODAL;
const strings = componentStrings.cases.casePhotoLineups.CasePhotoLineups.DeleteLineupModal;

type DeleteLineupConfirmationModalProps = {
    lineupFieldName: string;
    onSuccess: (lineupId: number) => void;
};

const DeleteLineupConfirmationModal: React.FC<DeleteLineupConfirmationModalProps> = ({
    lineupFieldName,
    onSuccess,
}) => {
    const { selectedLineup } = useLineupContext();
    const { title, lineupId } = selectedLineup;

    const deleteLineup = useCallback(
        () => casePhotoLineupResource.deletePhotoLineupForCase(lineupId),
        [lineupId]
    );

    const onResourceSuccess = useCallback(() => onSuccess(lineupId), [onSuccess, lineupId]);
    const { callResource: onSave } = useResourceDeferred(deleteLineup, onResourceSuccess);

    return (
        <Modal
            id={overlayId}
            title={strings.deletePhotoLineup(lineupFieldName)}
            okText={strings.deleteText}
            cancelText={strings.cancelText}
            onSave={onSave}
        >
            {strings.deletePhotoLineupConfirmation(lineupFieldName, title)}
        </Modal>
    );
};

export default DeleteLineupConfirmationModal;

import React, { useState } from 'react';
import { Input } from '@arc/input';
import { Text } from '@arc/typography';
import { Flex } from '@arc/layout';
import { MenuListGroup, MenuListItem } from '@arc/menu';
import { NumberInput } from '@arc/number-input';
import { Popover, PopoverContent, PopoverTrigger } from '@arc/popover';
import { FormControl, IconButton, IconNameT, useDisclosure } from 'arc';
import styled from 'styled-components';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { arrangeMenuListItems, flipMenuListItems } from '../config';
import { useCrashDiagram } from '../context/CrashDiagramContext';
import { MenuListItemsProps, Widget, WidgetAction, widgetTypes } from '../types';
import { useScreenBreakpoint } from '../../../../../../core/utils/useScreenBreakpoint';
import { CrashDiagramActionButton } from './CrashDiagramActionButton';

const strings = componentStrings.dragon.crashDiagram.CrashDiagramModal;

type TextWidgetMenuOptionsProps = {
    widget: Widget;
};

type PopoverMenuProps = {
    items: MenuListItemsProps[];
    onClick: (action: WidgetAction) => void;
};

type SelectedWidgetPopoverButtonProps = {
    items: MenuListItemsProps[];
    leadingVisual: IconNameT;
    displayName: string;
    disabled?: boolean;
    onClick: (action: WidgetAction) => void;
};

type SelectedWidgetGhostButtonProps = {
    leadingVisual: IconNameT;
    displayName: string;
    onKeyDown: (numberOfDuplicates: number) => void;
};

type OpacityInputProps = {
    onKeyDown: (opacity: number) => void;
};

const StyledInput = styled(Input)`
    padding: 0 var(--arc-space-1);
`;

const StyledNumberInput = styled(NumberInput)`
    width: 80px;
    margin: 0 var(--arc-space-1);
`;

const TextWidgetMenuOptions: React.FC<TextWidgetMenuOptionsProps> = ({ widget }) => {
    const { updateValue } = useCrashDiagram();
    const { isMediumScreen } = useScreenBreakpoint();

    const onSubmitChange = () => {
        updateValue({
            widgetId: widget.id,
            value: widget.value,
            commit: true,
        });
    };

    return (
        <StyledInput
            size={isMediumScreen ? 'md' : 'sm'}
            value={widget.value}
            onBlur={onSubmitChange}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.keyCode !== keyCodeEnum.ENTER) {
                    return;
                }
                onSubmitChange();
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateValue({
                    widgetId: widget.id,
                    value: e.target.value,
                    commit: false,
                });
            }}
        />
    );
};

const PopoverMenu: React.FC<PopoverMenuProps> = ({ items, onClick }) => {
    return (
        <MenuListGroup>
            {items.map(({ action, leadingVisual, displayName }) => (
                <MenuListItem
                    key={action}
                    onClick={() => onClick(action)}
                    leadingVisual={leadingVisual}
                >
                    {displayName}
                </MenuListItem>
            ))}
        </MenuListGroup>
    );
};

const SelectedWidgetPopoverButton: React.FC<SelectedWidgetPopoverButtonProps> = ({
    items,
    leadingVisual,
    displayName,
    disabled = false,
    onClick,
}) => {
    return (
        <Flex
            alignItems="center"
            position="relative"
            justifyContent="end"
            paddingRight="var(--arc-space-1)"
        >
            <Popover>
                <PopoverTrigger asChild>
                    <CrashDiagramActionButton
                        leadingVisual={leadingVisual}
                        trailingVisual="Open"
                        disabled={disabled}
                        label={displayName}
                        isHeaderButton={true}
                    />
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverMenu items={items} onClick={onClick} />
                </PopoverContent>
            </Popover>
        </Flex>
    );
};

const SelectedWidgetGhostButton: React.FC<SelectedWidgetGhostButtonProps> = ({
    leadingVisual,
    displayName,
    onKeyDown,
}) => {
    const [value, setValue] = useState<number>();
    const { isOpen, onToggle, onClose } = useDisclosure();

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === keyCodeEnum.ENTER) {
            if (value) {
                onKeyDown(value);
                setValue(value);
            } else {
                onClose();
            }
        }
    };

    return (
        <Flex alignItems="center" paddingRight="var(--arc-space-1)">
            <Popover isOpen={isOpen}>
                <PopoverTrigger asChild>
                    <CrashDiagramActionButton
                        leadingVisual={leadingVisual}
                        trailingVisual="Open"
                        label={displayName}
                        isHeaderButton={true}
                        onClick={onToggle}
                    />
                </PopoverTrigger>
                <PopoverContent>
                    <FormControl label={strings.numberOfDuplicates}>
                        <NumberInput
                            size="sm"
                            hasStepper
                            value={value}
                            onChange={(valueOrEvent) => {
                                // TODO: When this arc bug gets fixed, valueOrEvent will always be a number; remove type guard and cast
                                if (typeof valueOrEvent !== 'string') {
                                    return;
                                }
                                const numberValue = Number(valueOrEvent);
                                if (!isNaN(numberValue)) {
                                    setValue(numberValue);
                                }
                            }}
                            onKeyDown={handleKeyDown}
                            min={0}
                        />
                    </FormControl>
                </PopoverContent>
            </Popover>
        </Flex>
    );
};
const MIN_OPACITY = 1;
const MAX_OPACITY = 100;

const OpacityInput: React.FC<OpacityInputProps> = ({ onKeyDown }) => {
    const { selectedWidget } = useCrashDiagram();

    const { isMobile, isMediumScreen, isLargeScreen } = useScreenBreakpoint();
    const [value, setValue] = useState(
        selectedWidget && selectedWidget.opacity ? selectedWidget.opacity * 100 : undefined
    );

    if (!selectedWidget) {
        return null;
    }

    const handleChange = (valueOrEvent: string | React.ChangeEvent<HTMLInputElement>) => {
        // TODO: When this arc bug gets fixed, valueOrEvent will always be a number; remove type guard and cast
        if (typeof valueOrEvent === 'string') {
            const newValue = Number(valueOrEvent);
            setValue(newValue > MAX_OPACITY ? MAX_OPACITY : newValue);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === keyCodeEnum.ENTER) {
            const newValue = !value ? MIN_OPACITY : value;
            onKeyDown(newValue);
            setValue(newValue);
        }
    };

    return (
        <Flex alignItems="center">
            {isLargeScreen && (
                <Text variant="bodyMd" fontWeight="bold">
                    {strings.opacity}
                </Text>
            )}
            <StyledNumberInput
                isMobile={isMobile}
                size={isMediumScreen ? 'md' : 'sm'}
                hasStepper
                value={value === 0 ? '' : value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                max={MAX_OPACITY}
                min={MIN_OPACITY}
            />
        </Flex>
    );
};

export const SelectedWidgetMenu = () => {
    const { isMobile } = useScreenBreakpoint();
    const {
        selectedWidget,
        widgets,
        deleteWidget,
        duplicateWidget,
        applyWidgetAction,
        addGhostWidgets,
        setWidgetOpacity,
    } = useCrashDiagram();

    if (!selectedWidget) {
        return null;
    }

    const isSelectedWidgetTextWidgetType = selectedWidget.type === widgetTypes.TEXT;

    const onDelete = () => {
        deleteWidget({
            widgetId: selectedWidget.id,
        });
    };
    const onDuplicate = () => {
        duplicateWidget({
            widgetId: selectedWidget.id,
        });
    };
    const onApplyWidgetAction = (action: WidgetAction) => {
        applyWidgetAction({
            widgetId: selectedWidget.id,
            action,
        });
    };
    const onGhostDuplicate = (numberOfDuplicates: number) => {
        addGhostWidgets({
            widgetId: selectedWidget.id,
            numberOfDuplicates,
        });
    };
    const onUpdateOpacity = (opacity: number) => {
        setWidgetOpacity({
            widgetId: selectedWidget.id,
            opacity,
        });
    };

    return (
        <Flex>
            {isSelectedWidgetTextWidgetType && <TextWidgetMenuOptions widget={selectedWidget} />}
            <Flex justifyContent="space-between">
                {!isSelectedWidgetTextWidgetType && <OpacityInput onKeyDown={onUpdateOpacity} />}
                <SelectedWidgetPopoverButton
                    items={arrangeMenuListItems}
                    leadingVisual="Arrange"
                    displayName={strings.arrangeButtonText}
                    onClick={onApplyWidgetAction}
                    disabled={widgets.length === 1}
                />
                {!isSelectedWidgetTextWidgetType && (
                    <SelectedWidgetPopoverButton
                        items={flipMenuListItems}
                        leadingVisual="FlipHorizontal"
                        displayName={strings.flipButtonText}
                        onClick={onApplyWidgetAction}
                    />
                )}
                <Flex alignItems="center" paddingRight="var(--arc-space-1)">
                    <CrashDiagramActionButton
                        leadingVisual="Duplicate"
                        label={strings.duplicateButtonText}
                        isHeaderButton={true}
                        onClick={onDuplicate}
                    />
                </Flex>
                {!isSelectedWidgetTextWidgetType && (
                    <SelectedWidgetGhostButton
                        leadingVisual="StrokeNone"
                        displayName={strings.ghostButtonText}
                        onKeyDown={onGhostDuplicate}
                    />
                )}
                <Flex alignItems="center" paddingRight="var(--arc-space-1)">
                    <IconButton
                        icon="TrashCan"
                        onClick={onDelete}
                        aria-label="Delete Button"
                        size={isMobile ? 'sm' : 'md'}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

import { EntityTypeEnum } from '@mark43/rms-api';
import { get, filter, map, includes, find, parseInt } from 'lodash';

import { createSelector } from 'reselect';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import sortCharges from '~/client-common/core/domain/charges/utils/sortCharges';

import { NEXUS_STATE_PROP as COURT_ORDERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/court-orders/state/data';
import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/person-profiles/state/data';
import {
    NEXUS_STATE_PROP as CHARGES_NEXUS_STATE_PROP,
    chargesWhereSelector,
} from '~/client-common/core/domain/charges/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import {
    NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP,
    attachmentsByEntitySelector,
} from '~/client-common/core/domain/attachments/state/data';
import {
    NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP,
    arrestForReportIdSelector,
} from '~/client-common/core/domain/arrests/state/data';
import {
    NEXUS_STATE_PROP as REPORT_NEXUS_STATE_PROP,
    reportsSelector,
} from '~/client-common/core/domain/reports/state/data';
import { reportDefinitionsSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { reportDefinitionHasArrestCard } from '~/client-common/helpers/reportDefinitionsHelpers';
import getExpungementResource from '~/client-common/core/domain/charges/resources/expungementResource';

import { updateUploads } from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';
import { initializeCardForm } from '../../../../reports/core/state/ui/cards';
import chargeVacatingForm from '../forms/chargeVacatingForm';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

const REPORT_CHARGE_VACATING_RESET_STATE = 'record-privacy/charge-vacating/RESET_STATE';
const REPORT_CHARGE_VACATING_FORM_SUBMIT_FAILURE =
    'record-privacy/charge-vacating/FORM_SUBMIT_FAILURE';
const REPORT_CHARGE_VACATING_FORM_SUBMIT_BEGIN = 'record-privacy/charge-vacating/FORM_SUBMIT_BEGIN';

const REPORT_CHARGE_VACATING_LOAD_REPORT_BEGIN = 'record-privacy/charge-vacating/LOAD_REPORT_BEGIN';
const REPORT_CHARGE_VACATING_LOAD_REPORT_SUCCESS =
    'record-privacy/charge-vacating/LOAD_REPORT_SUCCESS';
const REPORT_CHARGE_VACATING_LOAD_REPORT_FAILURE =
    'record-privacy/charge-vacating/LOAD_REPORT_FAILURE';

const resetChargeVacatingState = () => ({ type: REPORT_CHARGE_VACATING_RESET_STATE });
const chargeVacatingFormSubmitBegin = () => ({
    type: REPORT_CHARGE_VACATING_FORM_SUBMIT_BEGIN,
});
const chargeVacatingFormSubmitFailure = (error) => ({
    type: REPORT_CHARGE_VACATING_FORM_SUBMIT_FAILURE,
    payload: { error },
});

const loadReportBegin = (reportId) => ({
    type: REPORT_CHARGE_VACATING_LOAD_REPORT_BEGIN,
    payload: { reportId },
});
const loadReportSuccess = (reportId) => ({
    type: REPORT_CHARGE_VACATING_LOAD_REPORT_SUCCESS,
    payload: { reportId },
});
const loadReportFailure = (error) => ({
    type: REPORT_CHARGE_VACATING_LOAD_REPORT_FAILURE,
    payload: { error },
});

export function cleanupVactingInfoReport() {
    return (dispatch, getState, { nexus }) =>
        dispatch(
            // clean up entities from loadVacatingInfo
            nexus.withRemoveMultiple(
                {
                    [ARRESTS_NEXUS_STATE_PROP]: {},
                    [ATTACHMENTS_NEXUS_STATE_PROP]: {},
                    [CHARGES_NEXUS_STATE_PROP]: {},
                    [COURT_ORDERS_NEXUS_STATE_PROP]: {},
                    [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: {},
                },
                resetChargeVacatingState()
            )
        );
}

export const loadVacatingInfo = (reportId) => (dispatch, getState, dependencies) => {
    dispatch(loadReportBegin(reportId));
    return getExpungementResource()
        .loadVacateInfoForReportId(reportId)
        .then((vacateInfo) => {
            const action = dependencies.nexus.withEntityItems(
                {
                    [REPORT_NEXUS_STATE_PROP]: vacateInfo.reports,
                    [CHARGES_NEXUS_STATE_PROP]: vacateInfo.charges,
                    [COURT_ORDERS_NEXUS_STATE_PROP]: vacateInfo.courtOrders,
                    [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: vacateInfo.reportShortTitles,
                    [ARRESTS_NEXUS_STATE_PROP]: vacateInfo.arrests,
                    [ATTACHMENTS_NEXUS_STATE_PROP]: vacateInfo.courtOrderAttachments,
                    [PERSON_PROFILES_NEXUS_STATE_PROP]: vacateInfo.personProfiles,
                },
                loadReportSuccess(reportId)
            );
            dispatch(action);

            return vacateInfo;
        })
        .then((vacateInfo) => dispatch(initializeChargeVacatingForm({ vacateInfo, reportId })))
        .catch((error) => dispatch(loadReportFailure(error.message)));
};

export const submitChargeVacatingForm = ({ router }) => (dispatch, getState) => {
    dispatch(chargeVacatingFormSubmitBegin());
    return dispatch(
        chargeVacatingForm.actionCreators.submit((formModel) => {
            const state = getState();
            const { chargeIdsToVacate } = formModel;
            const arrest = arrestForReportIdSelector(state)(formModel.reportId);
            const charges = chargesWhereSelector(state)({ arrestId: arrest.id });
            const updatedCharges = map(charges, (charge) => ({
                ...charge,
                isVacated: includes(chargeIdsToVacate, charge.id),
            }));
            const departmentId = currentUserDepartmentIdSelector(state);
            const { courtCodeAttrId } = formModel.courtOrder;
            return getExpungementResource().vacateCharges({
                courtOrders: [
                    {
                        ...formModel.courtOrder,
                        fileIds: formModel.courtOrderFileIds,
                        reportId: formModel.reportId,
                        departmentId,
                        courtCodeAttrId,
                    },
                ],
                charges: updatedCharges,
            });
        })
    )
        .then((resp) => {
            dispatch(updateUploads([]));
            router.push(`/reports/${get(resp, 'courtOrders[0].reportId')}`);
            return resp;
        })
        .catch((err) => {
            // This is used for validation errors and actual submission server errors.
            // Validation errors won't have a message so this will only reset `isSubmitting`
            // in that case, without setting an actual error message.
            dispatch(chargeVacatingFormSubmitFailure(err.message));
        });
};

const initializeChargeVacatingForm = ({ vacateInfo, reportId }) => (dispatch, getState) => {
    const state = getState();
    const report = reportsSelector(state)[reportId];

    const courtOrder = find(vacateInfo.courtOrders, ({ isVacate }) => isVacate);
    const { personProfiles, arrests } = vacateInfo;
    const arrestIdNumber = report.recordNumber;
    const defendantId = get(arrests, '0.defendantId');
    const defendantProfile = find(personProfiles, ({ id }) => id === defendantId);
    const stateIdNumber = get(defendantProfile, 'stateIdNumber');
    const formCourtOrder = { ...courtOrder, stateIdNumber };

    dispatch(
        chargeVacatingForm.actionCreators.change({
            reportId: parseInt(reportId),
            courtOrder: formCourtOrder,
            courtOrderId: get(courtOrder, 'id'),
            chargeIdsToVacate: map(
                filter(vacateInfo.charges, ({ isVacated }) => isVacated),
                'id'
            ),
            courtOrderFileIds: courtOrder
                ? map(
                      attachmentsByEntitySelector(getState())(
                          EntityTypeEnum.COURT_ORDER.name,
                          courtOrder.id
                      ),
                      'file.id'
                  )
                : [],
            arrestIdNumber,
        })
    );
    dispatch(initializeCardForm(chargeVacatingForm));
};

const initialState = {
    submissionError: undefined,
    isSubmitting: false,
    chargesLoading: {
        loadingError: undefined,
        reportLoaded: false,
        isLoading: false,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case REPORT_CHARGE_VACATING_FORM_SUBMIT_FAILURE:
            return { ...state, submissionError: action.payload.error, isSubmitting: false };
        case REPORT_CHARGE_VACATING_FORM_SUBMIT_BEGIN:
            return { ...state, submissionError: undefined, isSubmitting: true };
        case REPORT_CHARGE_VACATING_LOAD_REPORT_BEGIN:
            return { ...state, chargesLoading: { ...state.chargesLoading, isLoading: true } };
        case REPORT_CHARGE_VACATING_LOAD_REPORT_SUCCESS:
            return {
                ...state,
                chargesLoading: { ...state.chargesLoading, isLoading: false, reportLoaded: true },
            };
        case REPORT_CHARGE_VACATING_LOAD_REPORT_FAILURE:
            return {
                ...state,
                chargesLoading: {
                    ...state.chargesLoading,
                    isLoading: false,
                    loadingError: action.payload.error,
                },
            };
        default:
            return state;
    }
};

export const chargeVacatingSubmissionErrorSelector = (state) =>
    state.ui.chargeVacating.submissionError;
export const chargeVacatingIsSubmittingSelector = (state) => state.ui.chargeVacating.isSubmitting;
export const chargeVacatingChargesLoadingStateSelector = (state) =>
    state.ui.chargeVacating.chargesLoading;

// this selector lives here because arrests in client-common already depend on charges and
// we would create a circular dependency
export const chargesForReportIdSelector = createSelector(
    arrestForReportIdSelector,
    chargesWhereSelector,
    (arrestForReportId, chargesWhere) => (reportId) => {
        const arrest = arrestForReportId(reportId);
        if (!arrest) {
            return [];
        }

        return sortCharges({ charges: chargesWhere({ arrestId: arrest.id }) });
    }
);
export default makeResettable(REPORT_CHARGE_VACATING_RESET_STATE, reducer, initialState);

export const doesVacatedChargeExistForReportIdSelector = createSelector(
    reportsSelector,
    reportDefinitionsSelector,
    arrestForReportIdSelector,
    chargesWhereSelector,
    (reports, reportDefinitions, arrestForReportId, chargesWhere) => (reportId) => {
        const parsedReportId = parseInt(reportId);
        const report = reports[parsedReportId];
        if (!report) {
            return false;
        }

        const reportDefinition = reportDefinitions[report.reportDefinitionId];
        if (reportDefinitionHasArrestCard(reportDefinition)) {
            const arrest = arrestForReportId(report.id);
            if (!arrest) {
                return false;
            }

            const charges = chargesWhere({ arrestId: arrest.id });
            return !!filter(charges, (charge) => charge.isVacated).length;
        }
        return false;
    }
);

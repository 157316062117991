import React from 'react';
import _ from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
    EntityProfileSubdetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

const strings = componentStrings.entityProfiles.person.EmploymentDetails;

function mapEmploymentHistoryRows(employmentHistories) {
    return _.map(employmentHistories, (history) => {
        const { dateRange } = getViewModelProperties(history);

        return (
            <SummaryRow key={history.id} label={strings.labels.employer}>
                <div>{history.employerName}</div>
                <EntityProfileSubdetailSummaryList>
                    <SummaryRow label={strings.labels.address}>
                        {history.employerAddress}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.occupation}>{history.occupation}</SummaryRow>
                    <SummaryRow label={strings.labels.phoneNumber}>
                        {history.displayNumber}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.dateEmployed}>{dateRange}</SummaryRow>
                </EntityProfileSubdetailSummaryList>
            </SummaryRow>
        );
    });
}

export default function EmploymentDetails({ employmentHistories }) {
    const partitionedEmploymentHistories = _.partition(
        employmentHistories,
        (history, index) => index % 2
    );
    const leftEmploymentHistoryRows = mapEmploymentHistoryRows(partitionedEmploymentHistories[1]);
    const rightEmploymentHistoryRows = mapEmploymentHistoryRows(partitionedEmploymentHistories[0]);

    return (
        <EntityProfileSection title={strings.title}>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    {leftEmploymentHistoryRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    {rightEmploymentHistoryRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

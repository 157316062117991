import departmentProfileAdminActionTypes from '../actions/types/departmentProfileAdminActionTypes';
import authActionTypes from '../actions/types/authActionTypes';

const {
    LOAD_DEPARTMENT_PROFILE_SUCCESS,
    SAVE_DEPARTMENT_PROFILE_SUCCESS,
} = departmentProfileAdminActionTypes;

const { BOOTSTRAP_SUCCESS } = authActionTypes;

const initialDataState = {};

// TODO should use client-common/core/domain/department-profiles/state/data
export function departmentProfileDataReducer(state = initialDataState, action) {
    switch (action.type) {
        case BOOTSTRAP_SUCCESS:
            return action.payload.userData.departmentProfile;
        case LOAD_DEPARTMENT_PROFILE_SUCCESS:
            return action.payload;
        case SAVE_DEPARTMENT_PROFILE_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

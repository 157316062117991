import { AttributeTypeEnum } from '@mark43/rms-api';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    compose,
    defaultProps,
    setDisplayName,
    setPropTypes,
    withProps,
    lifecycle,
    withHandlers,
} from 'recompose';
import keyMirror from 'keymirror';

import { map, noop } from 'lodash';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';
import { PERSON_GANG_TRACKING_GANG_NAME_ATTR_ID } from '~/client-common/core/enums/universal/fields';

import {
    attributeLoadingStateSelectorFactory,
    attributeOptionsByTypeForCurrentDepartmentSelector,
} from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import { computeAllowedAttributeTypesToLoad } from '../../../attributes/utils/computeAllowedAttributeTypesToLoad';
import { computeLoadableAttributeTypes } from '../../../attributes/utils/computeLoadableAttributeTypes';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import MultiFieldSelect from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.select.GangNameSelect;

/**
 * Dropdown containing all gang names and all
 * gang subgroup names. The two types are modeled across two separate form fields.
 * @param {Object}  props
 * @param {Object}  props.fields
 * @param {boolean} [props.includeExpired=false]
 */
const GangNameSelect = compose(
    setDisplayName('GangNameSelect'),
    setPropTypes({
        includeExpired: PropTypes.bool,
    }),
    withProps(() => ({
        attributeType: [AttributeTypeEnum.GANG_NAME.name, AttributeTypeEnum.GANG_SUBGROUP.name],
    })),
    withFields([PERSON_GANG_TRACKING_GANG_NAME_ATTR_ID]),
    connect(
        () => {
            const attributeLoadingStateSelector = attributeLoadingStateSelectorFactory();
            return createStructuredSelector({
                attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
                formatAttributeById: formatAttributeByIdSelector,
                attributeLoadingState: attributeLoadingStateSelector,
            });
        },
        {
            loadAttributesForType,
        }
    ),
    withHandlers({
        onFocus({ attributeLoadingState, onFocus, loadAttributesForType }) {
            return () => {
                // see AttributeSelect.js
                const attributeTypesToLoad = computeLoadableAttributeTypes(attributeLoadingState);

                if (attributeTypesToLoad.length) {
                    loadAttributesForType({ attributeType: attributeTypesToLoad }).catch(noop);
                }

                if (onFocus) {
                    onFocus();
                }
            };
        },
    }),
    lifecycle({
        componentDidMount() {
            // see AttributeSelect.js
            const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                this.props.attributeLoadingState
            );
            if (attributeTypesToLoad.length) {
                this.props
                    .loadAttributesForType({ attributeType: attributeTypesToLoad })
                    .catch(noop);
            }
        },
    })
)(function GangNameSelect({
    fields: { gangNameAttrIds, gangSubgroupAttrIds, isExpired },
    includeExpired = false,
    attributeOptionsByTypeForCurrentDepartment,
    formatAttributeById,
    fieldDisplayNames,
    ...otherProps
}) {
    const gangNameOptions = map(
        attributeOptionsByTypeForCurrentDepartment({
            type: AttributeTypeEnum.GANG_NAME.name,
            includeExpired,
        }),
        ({ value }) => {
            return {
                value,
                display: formatAttributeById(value, false, true),
            };
        }
    );

    const gangSubgroupOptions = map(
        attributeOptionsByTypeForCurrentDepartment({
            type: AttributeTypeEnum.GANG_SUBGROUP.name,
            includeExpired,
        }),
        ({ value }) => {
            return {
                value,
                display: formatAttributeById(value, false, true),
            };
        }
    );

    return (
        <MultiFieldSelect
            label={fieldDisplayNames.PERSON_GANG_TRACKING_GANG_NAME_ATTR_ID}
            sortOptions={true}
            {...otherProps}
            fields={{
                isExpired: {
                    options: [
                        {
                            value: false,
                            display: strings.anyGang,
                            none: true,
                        },
                    ],
                    ...isExpired,
                },
                gangNameAttrIds: {
                    options: gangNameOptions,
                    ...gangNameAttrIds,
                },
                gangSubgroupAttrIds: {
                    options: gangSubgroupOptions,
                    ...gangSubgroupAttrIds,
                },
            }}
        />
    );
});

export const RRFGangNameSelect = compose(
    setDisplayName('RRFGangNameSelect'),
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            gangNameAttrIds: null,
            gangSubgroupAttrIds: null,
            isExpired: null,
        }),
    }),
    connectRRFMultiFieldInput
)(GangNameSelect);

import { light } from 'arc';
import { hexToRgb } from '../utils/colorHelpers';

const colors = light.colors;
const text = colors.text;
const surface = colors.surface;
const brand = colors.brand;
const negative = colors.negative;
const positive = colors.positive;
const raw = colors.raw;

const baseColors = {
    black: raw.black,
    darkGrey: text.primary,
    mediumGrey: text.secondary,
    mediumLightGrey: text.tertiary,
    lightGrey: raw.gray[300],
    extraLightGrey: surface.foreground,
    white: surface.foreground,
    red: negative.default,
    darkGreen: positive.default,
    lightGreen: positive.accent,
    darkGreen2: raw.cyan[500], // Used only in Label and TaskStatusLabel
    lightGreen2: raw.cyan[50], // Used only in Label and TaskStatusLabel
    brightYellow: raw.yellow[200],
    lightYellow: raw.yellow[50],
    navyBlue: raw.blue[800],
    cobaltBlue: brand.default,
    brightBlue: brand.default,
    mediumBlue: brand.accent,
    lightBlue: surface.accent,
};

const brightBlueRgbValues = hexToRgb(baseColors.brightBlue);

export default {
    colors: {
        ...baseColors,

        brightBlueAlpha: `rgba(${brightBlueRgbValues.r},${brightBlueRgbValues.g},${brightBlueRgbValues.b}, .25)`,

        // Deprecated, remove these two when RMS_ARC_NAVIGATION_ENABLED is torn down
        navbarText: baseColors.extraLightGrey,
        navbarBorder: baseColors.darkGrey,

        text: text.primary,
        placeholder: text.placeholder,
        shadow: baseColors.darkGrey,
        fieldBg: baseColors.white,
        disabled: baseColors.lightGrey,

        highContrastGreen: baseColors.lightGreen,
        lowContrastGreen: baseColors.darkGreen,
        highContrastGrey: text.secondary,
        lowContrastGrey: text.tertiary,
    },
    fontWeights: {
        thin: 100,
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    fontFamilies: {
        facit: 'jaf-facitweb',
        proximaNova: 'proxima-nova',
    },
    widths: {
        container: 970,
        navSidebar: 166,
        label: 85,
        sectionHeader: {
            card: 574,
            sidePanel: 428,
        },
        caseContent: 804,
    },
    heights: {
        header: 60,
        subheader: 70,
        actionBar: 62,
        // TODO: Refactor the header to not rely on fixed heights.
        alertBanner: 66,
    },
    fontSizes: {
        subheader: '24px',
    },
};

import { RetentionPolicyTypeEnum } from '@mark43/evidence-api';
import _, { first } from 'lodash';

import { createSelector } from 'reselect';
import {
    LOAD_RETENTION_POLICIES_START,
    LOAD_RETENTION_POLICIES_SUCCESS,
    LOAD_RETENTION_POLICIES_FAILURE,
    saveRetentionPolicy,
    retentionPoliciesSelector,
} from '~/client-common/core/domain/retention-policies/state/data';

import retentionPolicyAdminForm from '../forms/retentionPolicyAdminForm';

const SELECT_RETENTION_POLICY_START = 'evidence-retention-policies/SELECT_RETENTION_POLICY_START';
const SELECT_RETENTION_POLICY_SUCCESS =
    'evidence-retention-policies/SELECT_RETENTION_POLICY_SUCCESS';
const SELECT_RETENTION_POLICY_FAILURE =
    'evidence-retention-policies/SELECT_RETENTION_POLICY_FAILURE';
const OPEN_NEW_RETENTION_POLICY_FORM = 'evidence-retention-policies/OPEN_NEW_RETENTION_POLICY_FORM';
const SUBMIT_RETENTION_POLICY_ADMIN_FORM_START =
    'evidence-retention-policies/SUBMIT_RETENTION_POLICY_ADMIN_FORM_START';
const SUBMIT_RETENTION_POLICY_ADMIN_FORM_SUCCESS =
    'evidence-retention-policies/SUBMIT_RETENTION_POLICY_ADMIN_FORM_SUCCESS';
const SUBMIT_RETENTION_POLICY_ADMIN_FORM_FAILURE =
    'evidence-retention-policies/SUBMIT_RETENTION_POLICY_ADMIN_FORM_FAILURE';

function selectRetentionPolicyStart(id) {
    return {
        type: SELECT_RETENTION_POLICY_START,
        payload: id,
    };
}
function selectRetentionPolicySuccess(id) {
    return {
        type: SELECT_RETENTION_POLICY_SUCCESS,
        payload: id,
    };
}
function selectRetentionPolicyFailure(errorMessage) {
    return {
        type: SELECT_RETENTION_POLICY_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Select a retention policy on the admin page.
 * @param {number} [id]
 */
export function selectRetentionPolicy(id) {
    return function (dispatch, getState) {
        dispatch(selectRetentionPolicyStart(id));
        const state = getState();
        const retentionPolicy = retentionPoliciesSelector(state)[id];
        if (!!retentionPolicy) {
            dispatch(retentionPolicyAdminForm.actionCreators.change(retentionPolicy));
            dispatch(selectRetentionPolicySuccess(id));
        } else {
            dispatch(retentionPolicyAdminForm.actionCreators.reset());
            dispatch(selectRetentionPolicyFailure('Retention policy not found'));
        }
    };
}

function openNewRetentionPolicyFormRaw() {
    return {
        type: OPEN_NEW_RETENTION_POLICY_FORM,
    };
}

/**
 * Open the form for a new retention policy.
 */
export function openNewRetentionPolicyForm() {
    return function (dispatch) {
        dispatch(retentionPolicyAdminForm.actionCreators.reset());
        dispatch(openNewRetentionPolicyFormRaw());
    };
}

function submitRetentionPolicyAdminFormStart() {
    return {
        type: SUBMIT_RETENTION_POLICY_ADMIN_FORM_START,
    };
}
function submitRetentionPolicyAdminFormSuccess() {
    return {
        type: SUBMIT_RETENTION_POLICY_ADMIN_FORM_SUCCESS,
    };
}
function submitRetentionPolicyAdminFormFailure(errorMessage) {
    return {
        type: SUBMIT_RETENTION_POLICY_ADMIN_FORM_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Submit the retention policy admin form. The policy model can be new or
 *   existing.
 * @return {Promise<Object|boolean>} RetentionPolicy model.
 */
export function submitRetentionPolicyAdminForm() {
    return function (dispatch, getState) {
        dispatch(submitRetentionPolicyAdminFormStart());
        return dispatch(
            retentionPolicyAdminForm.actionCreators.submit((formModel) => {
                const state = getState();
                const id = selectedRetentionPolicyIdSelector(state);
                const retentionPolicy = retentionPolicyAdminForm.convertFromFormModel(
                    formModel,
                    id
                );

                return dispatch(saveRetentionPolicy(!id, retentionPolicy))
                    .then((retentionPolicy) => {
                        dispatch(submitRetentionPolicyAdminFormSuccess());
                        return retentionPolicy;
                    })
                    .catch((err) => {
                        dispatch(submitRetentionPolicyAdminFormFailure(err.message));
                    });
            })
        ).catch((panelErrors) => {
            dispatch(submitRetentionPolicyAdminFormFailure(first(panelErrors)));
            return false;
        });
    };
}

const retentionPoliciesAdminUiSelector = (state) => state.ui.evidenceRetentionPoliciesAdmin;

/**
 * The id of the selected retention policy on the admin page. `null` when none
 *   is selected.
 * @type {number|null}
 */
export const selectedRetentionPolicyIdSelector = createSelector(
    retentionPoliciesAdminUiSelector,
    ({ selectedId }) => selectedId
);

/**
 * Whether retention policies are being loaded for the admin page.
 * @type {boolean}
 */
export const loadingRetentionPoliciesSelector = createSelector(
    retentionPoliciesAdminUiSelector,
    ({ loading }) => loading
);

/**
 * Whether a retention policy is being created or updated on the admin page.
 * @type {boolean}
 */
export const savingRetentionPolicySelector = createSelector(
    retentionPoliciesAdminUiSelector,
    ({ saving }) => saving
);

/**
 * Error message on the retention policy list at the left on the admin page.
 * @type {string|null}
 */
export const retentionPolicyAdminListErrorMessageSelector = createSelector(
    retentionPoliciesAdminUiSelector,
    ({ listErrorMessage }) => listErrorMessage
);

/**
 * Error message on the retention policy form at the right on the admin page.
 * @type {string|null}
 */
export const retentionPolicyAdminFormErrorMessageSelector = createSelector(
    retentionPoliciesAdminUiSelector,
    ({ formErrorMessage }) => formErrorMessage
);

/**
 * All retention policies in the shape of <AdminList> item objects.
 * @type {Object[]}
 */
export const retentionPolicyAdminListItemsSelector = createSelector(
    retentionPoliciesSelector,
    selectedRetentionPolicyIdSelector,
    (retentionPolicies, selectedId) =>
        _(retentionPolicies)
            .filter({ retentionPolicyType: RetentionPolicyTypeEnum.CONFIGURED.name })
            .map(({ retentionPolicyId, description, code }) => ({
                path: `/admin/evidence/retention-policies/${retentionPolicyId}`,
                key: retentionPolicyId,
                title: description,
                subtitle: `Code: ${code}`,
                selected: retentionPolicyId === selectedId,
            }))
            .sortBy('title')
            .value()
);

export default function evidenceRetentionPoliciesAdminUiReducer(
    state = {
        selectedId: null,
        loading: false,
        saving: false,
        listErrorMessage: null,
        formErrorMessage: null,
    },
    action
) {
    switch (action.type) {
        case LOAD_RETENTION_POLICIES_START:
            return {
                ...state,
                loading: true,
                listErrorMessage: null,
            };
        case LOAD_RETENTION_POLICIES_SUCCESS:
            return {
                ...state,
                loading: false,
                listErrorMessage: null,
            };
        case LOAD_RETENTION_POLICIES_FAILURE:
            return {
                ...state,
                loading: false,
                listErrorMessage: action.payload,
            };
        case SELECT_RETENTION_POLICY_START:
            return {
                ...state,
                selectedId: null,
                formErrorMessage: null,
            };
        case SELECT_RETENTION_POLICY_SUCCESS:
            return {
                ...state,
                selectedId: action.payload,
                formErrorMessage: null,
            };
        case SELECT_RETENTION_POLICY_FAILURE:
            return {
                ...state,
                selectedId: null,
                formErrorMessage: action.payload,
            };
        case OPEN_NEW_RETENTION_POLICY_FORM: {
            return {
                ...state,
                selectedId: null,
                formErrorMessage: null,
            };
        }
        case SUBMIT_RETENTION_POLICY_ADMIN_FORM_START:
            return {
                ...state,
                saving: true,
                formErrorMessage: null,
            };
        case SUBMIT_RETENTION_POLICY_ADMIN_FORM_SUCCESS:
            return {
                ...state,
                saving: false,
                formErrorMessage: null,
            };
        case SUBMIT_RETENTION_POLICY_ADMIN_FORM_FAILURE:
            return {
                ...state,
                saving: false,
                formErrorMessage: action.payload,
            };
        default:
            return state;
    }
}

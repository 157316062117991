import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { horizVertCenter } from '../../styles/mixins';

import NoDataBlock from '../../components/NoDataBlock';
import MapContainer from './MapContainer';

const strings = componentStrings.core.maps.NoMapContainer;

const NoMapBlock = styled(NoDataBlock)`
    width: 200px;
    ${horizVertCenter};
`;

export default function NoMapContainer({ className, styles }) {
    return (
        <MapContainer className={className} style={styles}>
            <NoMapBlock>{strings.noMapText}</NoMapBlock>
        </MapContainer>
    );
}

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/notifications/api';
const BASE_PATH = 'notifications/preferences';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Notification Preferences Resource',
        methods: {
            /**
             * Get user notification opt outs
             * @return {Promise<Object[]>}
             */
            getCurrentUserOptOuts() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/current`,
                });
            },

            /**
             * Get user notification opt outs
             * @param {number} userId
             * @return {Promise<Object[]>}
             */
            getUserOptOuts(userId) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${userId}`,
                });
            },

            /**
             * Save user notification opt outs
             * array of optOuts will overwrite server-side opt outs
             * @param {Object[]} optOuts
             * @return {Promise<Object[]>}
             */
            saveCurrentUserOptOuts(optOuts) {
                return req({
                    method: 'PUT',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/current`,
                    data: optOuts,
                });
            },

            /**
             * Save user notification opt outs
             * array of optOuts will overwrite server-side opt outs
             * @param {number} userId
             * @param {Object[]} optOuts
             * @return {Promise<Object[]>}
             */
            saveUserOptOuts(userId, optOuts) {
                return req({
                    method: 'PUT',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${userId}`,
                    data: optOuts,
                });
            },

            /**
             * Get all notification types for the department
             * @return {Promise<String[]>}
             */
            getNotificationTypes() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/types`,
                });
            },

            /**
             * Save single opt out for current user.
             * @param {Object} optOut
             * @return {Promise<String[]>}
             */
            saveCurrentUserOptOut(optOut) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/current/opt_out`,
                    data: optOut,
                });
            },
        },
    });
}

/**
 * Resources for notifications preferences.
 *   This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

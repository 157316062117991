import { chain, max, size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { isExpired } from '~/client-common/core/dates/utils/dateHelpers';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { convertAttachmentViewModelsForLightboxGallery } from '~/client-common/core/domain/attachments/state/ui';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import { BannerIcon as _BannerIcon } from '../../../../legacy-redux/components/alerts/Banner';
import _Pagination from '../../../../legacy-redux/components/core/Pagination';
import _TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import { openImageGalleryLightbox } from '../../../../legacy-redux/actions/imageGalleryLightboxActions';
import Icon, { iconTypes } from '../../../core/components/Icon';
import NoDataBlock from '../../../core/components/NoDataBlock';
import { scrollToElement } from '../../../../legacy-redux/helpers/navigationHelpers';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import NotificationAlertCreator from '../../core/components/NotificationAlertCreator';
import {
    notificationsAlerts,
    getNotificationsAlertsAndAttachments,
    getNotificationsAlertsAndAttachmentsSelector,
} from '../state/ui';
import testIds from '../../../../core/testIds';

const NOTIFICATION_CONTAINER_CLASS = 'mark43-notifications-table';
const SCROLL_CONTAINER_CLASS = 'mark43-scrollable-under-subheader';

const { selectors } = notificationsAlerts;
const strings = componentStrings.notifications.dashboard.NotificationsAlerts;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const NoResults = styled.div`
    margin-top: 40px;
    text-align: center;
`;

const Pagination = styled(_Pagination)`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 62px;
`;

const SummaryRow = styled(Row)`
    background-color: ${(props) => props.theme.colors.white};

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }

    &:last-child {
        border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    }
`;

const BannerIcon = styled(_BannerIcon)`
    height: 62px;
    width: 62px;
    flex-shrink: 0;
`;

const Content = styled(Row)`
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    flex: 1 1 auto;
`;

const Description = styled(Column)`
    flex: 1 1 auto;
    margin: ${(props) => (props.hasDate ? '10px' : '5px 10px 0 10px')};
`;

const Title = styled.span`
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    margin-right: 5px;
`;

const Text = styled.span`
    font-size: var(--arc-fontSizes-md);
`;

const DateAndUser = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
`;

const Attachments = styled(({ attachments, className, onClick }) => {
    const attachmentSize = size(attachments);
    return !attachmentSize ? null : (
        <Column className={className}>
            <Row onClick={onClick} style={{ cursor: 'pointer' }}>
                <Icon color="darkGrey" type={iconTypes.DOCUMENT} />
                <span style={{ marginLeft: '5px' }}>{strings.attachmentsN(attachmentSize)}</span>
            </Row>
        </Column>
    );
})`
    font-size: var(--arc-fontSizes-md);
    flex: 0 0 auto;
    justify-content: flex-start;
    padding: 10px;
`;

const NotificationAlert = ({
    alertCreatorFullNameWithBadge,
    alertCreatorId,
    alertCreatorUserProfile,
    attachments,
    className,
    handleAttachmentsClick,
    iconType,
    title,
    text,
    startTimeUtc,
    endTimeUtc,
}) => (
    <SummaryRow className={className} data-test-id={testIds.NOTIFICATIONS_ALERT_CONTENT}>
        <BannerIcon bannerTypeAttrId={iconType} />
        <Content>
            <Description hasDate={!!startTimeUtc}>
                <Row>
                    {title && <Title>{title}</Title>}
                    <Text>{text}</Text>
                </Row>
                <DateAndUser>
                    {isExpired(endTimeUtc) ? strings.expired : strings.active}{' '}
                    <FormattedDate
                        format={FormattedDate.FORMATS.TABLE_DATE_TIME}
                        date={startTimeUtc}
                    />
                    {' - '}
                    <FormattedDate
                        format={FormattedDate.FORMATS.TABLE_DATE_TIME}
                        date={endTimeUtc}
                    />{' '}
                    by{' '}
                    <NotificationAlertCreator
                        alertCreatorFullNameWithBadge={alertCreatorFullNameWithBadge}
                        alertCreatorId={alertCreatorId}
                        alertCreatorUserProfile={alertCreatorUserProfile}
                    />
                </DateAndUser>
            </Description>
            <Attachments attachments={attachments} onClick={handleAttachmentsClick} />
        </Content>
    </SummaryRow>
);

const AlignRight = styled(Row)`
    justify-content: flex-end;
    margin-bottom: 5px;
`;

const TableResultsSummary = styled(_TableResultsSummary)`
    margin-top: 7px;
    margin-right: 10px;
`;

const _NotificationsDashboardAlerts = styled(({ results, className }) => (
    <div className={className}>{results}</div>
))`
    background-color: ${(props) => props.theme.colors.white};
    min-height: 100%;
    padding: 20px;
`;

const mapStateToProps = createStructuredSelector({
    query: selectors.currentQuerySelector,
    results: getNotificationsAlertsAndAttachmentsSelector,
    totalCount: selectors.totalCountSelector,
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
});

const mapDispatchToProps = (dispatch) => ({
    getNotificationsAlertsAndAttachments: (query) =>
        dispatch(getNotificationsAlertsAndAttachments(query)),
    handleAttachmentsClick: (alertAttachments, dateTimeFormatter) =>
        dispatch(
            openImageGalleryLightbox(
                convertAttachmentViewModelsForLightboxGallery(alertAttachments, dateTimeFormatter)
            )
        ),
});

const NotificationsDashboardAlerts = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        handleSizeChange({ getNotificationsAlertsAndAttachments }) {
            return (size) => getNotificationsAlertsAndAttachments({ from: 0, size });
        },
        handlePaginationClick({ getNotificationsAlertsAndAttachments, query: { size } }) {
            scrollToElement({
                selector: `.${NOTIFICATION_CONTAINER_CLASS}`,
                wrapperSelector: `.${SCROLL_CONTAINER_CLASS}`,
            });
            return (lastPage, nextPage) => {
                getNotificationsAlertsAndAttachments({
                    from: nextPage * size - size,
                    size,
                });
            };
        },
    }),
    mapProps(
        ({
            className,
            handleAttachmentsClick,
            handlePaginationClick,
            handleSizeChange,
            query: { from, size },
            results,
            totalCount,
            dateTimeFormatter,
        }) => ({
            className,
            results: totalCount ? (
                chain(results)
                    .map(
                        (
                            {
                                alertCreatorFullNameWithBadge,
                                alertCreatorId,
                                alertCreatorUserProfile,
                                attachments,
                                bulletinTypeAttrId,
                                startDateUtc,
                                alertNotificationExpiredDateUtc,
                                text,
                                title,
                            },
                            index
                        ) => {
                            return (
                                <NotificationAlert
                                    key={`notification_alert_${index + 1}`}
                                    attachments={attachments}
                                    handleAttachmentsClick={() =>
                                        handleAttachmentsClick(attachments, dateTimeFormatter)
                                    }
                                    iconType={bulletinTypeAttrId}
                                    startTimeUtc={startDateUtc}
                                    endTimeUtc={alertNotificationExpiredDateUtc}
                                    title={title}
                                    text={text}
                                    alertCreatorFullNameWithBadge={alertCreatorFullNameWithBadge}
                                    alertCreatorId={alertCreatorId}
                                    alertCreatorUserProfile={alertCreatorUserProfile}
                                />
                            );
                        }
                    )
                    .thru((content) => (
                        <div>
                            <AlignRight className={NOTIFICATION_CONTAINER_CLASS}>
                                <TableResultsSummary
                                    from={from}
                                    to={from + results.length}
                                    totalResults={totalCount}
                                    caption=""
                                />
                                <SearchSizeDropdownMenu onChange={handleSizeChange} />
                            </AlignRight>
                            {content}
                            {totalCount > size && (
                                <Pagination
                                    currentPage={max([1, fromSizeToPage(from + 1, size)])}
                                    itemCount={totalCount}
                                    itemsPerPage={size}
                                    onClick={handlePaginationClick}
                                />
                            )}
                        </div>
                    ))
                    .value()
            ) : (
                <NoResults>
                    <NoDataBlock>{strings.noAlerts}</NoDataBlock>
                </NoResults>
            ),
        })
    )
)(_NotificationsDashboardAlerts);

export default NotificationsDashboardAlerts;

import { EvidenceDocumentTypeEnum, FormEnum } from '@mark43/evidence-api';
import * as fields from '~/client-common/core/enums/universal/fields';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'printingTemplateId',
        fieldName: fields.EVIDENCE_PRINTING_TEMPLATE_PRINTING_TEMPLATE_ID,
    },
    {
        key: 'name',
        fieldName: fields.EVIDENCE_PRINTING_TEMPLATE_NAME,
    },
]);

function convertFromFormModel(formModel, id, files) {
    return {
        template: {
            ...formModel,
            // there is only 1 value in this enum, and the user does not need to see it KRA-1793
            evidenceDocumentType: EvidenceDocumentTypeEnum.FIELD_RECEIPT.name,
            id,
        },
        files,
    };
}

const evidencePrintingTemplateAdminForm = createFormModule({
    formName: FormEnum.EVIDENCE_PRINTING_TEMPLATE_ADMIN.name,
    fieldViewModels,
    convertFromFormModel,
});

/**
 * Module of the evidence printing template form, for creating/editing.
 * @type {Object}
 */
export default evidencePrintingTemplateAdminForm;

import { map, mapValues, compact } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';

const LOAD_EMBEDDED_REPORT_START = 'reports/LOAD_EMBEDDED_REPORT_START';
const LOAD_EMBEDDED_REPORT_SUCCESS = 'reports/LOAD_EMBEDDED_REPORT_SUCCESS';
const LOAD_EMBEDDED_REPORT_FAILURE = 'reports/LOAD_EMBEDDED_REPORT_FAILURE';
const HIDE_EMBEDDED_REPORT = 'reports/HIDE_EMBEDDED_REPORT';
const HIDE_ALL_EMBEDDED_REPORTS = 'reports/HIDE_ALL_EMBEDDED_REPORTS';
const DELETE_EMBEDDED_REPORT = 'reports/DELETE_EMBEDDED_REPORT';

export function loadEmbeddedReportStart(reportId: number) {
    return {
        type: LOAD_EMBEDDED_REPORT_START,
        payload: reportId,
    } as const;
}

export function loadEmbeddedReportSuccess(reportId: number) {
    return {
        type: LOAD_EMBEDDED_REPORT_SUCCESS,
        payload: reportId,
    } as const;
}

export function loadEmbeddedReportFailure(reportId: number, errorMessage: string) {
    return {
        type: LOAD_EMBEDDED_REPORT_FAILURE,
        payload: {
            reportId,
            errorMessage,
        },
    } as const;
}

export function hideEmbeddedReport(reportId: number) {
    return {
        type: HIDE_EMBEDDED_REPORT,
        payload: reportId,
    } as const;
}

export function hideAllEmbeddedReports() {
    return {
        type: HIDE_ALL_EMBEDDED_REPORTS,
    } as const;
}

export function deleteEmbeddedReport(reportId: number) {
    return { type: DELETE_EMBEDDED_REPORT, payload: reportId } as const;
}

type EmbeddedReportsUi = {
    [reportId: string]:
        | { loading: true; loadedAndVisible: false }
        | { loading: false; loadedAndVisible: true }
        | { loading: false; loadedAndVisible: false; errorMessage?: string };
};

export function embeddedReportsUiReducer(
    state: EmbeddedReportsUi = {},
    action:
        | ReturnType<typeof loadEmbeddedReportStart>
        | ReturnType<typeof loadEmbeddedReportSuccess>
        | ReturnType<typeof loadEmbeddedReportFailure>
        | ReturnType<typeof hideEmbeddedReport>
        | ReturnType<typeof hideAllEmbeddedReports>
        | ReturnType<typeof deleteEmbeddedReport>
): EmbeddedReportsUi {
    switch (action.type) {
        case LOAD_EMBEDDED_REPORT_START:
            return {
                ...state,
                [action.payload]: {
                    loading: true,
                    loadedAndVisible: false,
                    errorMessage: undefined,
                },
            };
        case LOAD_EMBEDDED_REPORT_SUCCESS:
            return {
                ...state,
                [action.payload]: {
                    loading: false,
                    loadedAndVisible: true,
                    errorMessage: undefined,
                },
            };
        case LOAD_EMBEDDED_REPORT_FAILURE:
            return {
                ...state,
                [action.payload.reportId]: {
                    loading: false,
                    loadedAndVisible: false,
                    errorMessage: action.payload.errorMessage,
                },
            };
        case HIDE_EMBEDDED_REPORT:
            return {
                ...state,
                [action.payload]: {
                    loading: false,
                    loadedAndVisible: false,
                },
            };
        case HIDE_ALL_EMBEDDED_REPORTS:
            return mapValues(state, () => ({
                loading: false,
                loadedAndVisible: false,
            }));
        case DELETE_EMBEDDED_REPORT: {
            return {
                ...state,
                [action.payload]: undefined,
            };
        }
        default:
            return state;
    }
}

export const embeddedReportsUiSelector = (state: RootState) => state.ui.report.embeddedReports;

export const visibleEmbeddedReportIdsSelector = createSelector(
    embeddedReportsUiSelector,
    (embeddedReportsUi) => {
        return compact(
            map(embeddedReportsUi, (ui, reportId) => {
                if (ui?.loadedAndVisible) {
                    return parseInt(reportId);
                }
                return null;
            })
        );
    }
);

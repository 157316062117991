import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { compact, find, map, sortBy } from 'lodash';

import { TableRow as _TableRow } from 'components-mark43';
import { ComplianceReportErrors, NibrsReportSegmentsDescription } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import testIds from '../../../core/testIds';
import _TableResultsSummary from '../../../legacy-redux/components/core/tables/TableResultsSummary';
import _Pagination from '../../../legacy-redux/components/core/Pagination';
import { scrollToElement } from '../../../legacy-redux/helpers/navigationHelpers';
import RMSTableComposite from '../../core/components/RMSTableComposite';
import Switch from '../../core/forms/components/Switch';

import { COMPLIANCE_DEFAULT_PAGE_SIZE, NIBRS_COMPLIANCE_TYPE } from '../configuration';
import { ErrorSummary, NibrsErrorRow } from '../types';
import nibrsExportResource from '../resources/nibrsExportResource';
import TableHeader, { TableHeaderWrapper } from './table/TableHeader';
import _TableCell, { TableCellContent } from './table/TableCell';
import ComplianceReportTitleCell from './table/cells/ComplianceReportTitleCell';
import ComplianceExportErrorsCell from './table/cells/ComplianceExportErrorsCell';
import TableWrapper from './table/TableWrapper';

const COLUMN_REPORT_WIDTH = 242;
const COLUMN_ERROR_WIDTH = 708;
const RESULT_CONTAINER_CLASS = 'compliance-export-errors-table';
const SCROLL_CONTAINER_CLASS = 'mark43-scrollable-under-subheader';

const table = componentStrings.compliance.ComplianceExportErrorsTable.table;

type RenderBodyRowT<T> = {
    index: number;
    item: T;
};
type ComplianceExportErrorsProps = {
    errorSummary?: ErrorSummary;
};

const ErrorsOnlySwitch = styled.div`
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    width: 160px;
    text-transform: uppercase;
    font: ${(props) =>
        `${props.theme.fontWeights.semiBold} 13px ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => props.theme.colors.mediumGrey};
`;
const TableRow = styled(_TableRow)`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    padding: 10px 0;
`;
const TableCell = styled(_TableCell)`
    display: inline-block;
    align-items: unset;
`;
const TableResultsSummary = styled(_TableResultsSummary)`
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
`;
const Pagination = styled(_Pagination)`
    vertical-align: middle;
`;
const PaginationWrapper = styled.div`
    text-align: right;
    padding: 10px 20px 30px;
`;

const renderHeaderRow = ({
    errorsOnly,
    errorsOnlySwitchChangeHandler,
}: {
    errorsOnly: boolean;
    errorsOnlySwitchChangeHandler: () => void;
}) => {
    return (
        <TableHeaderWrapper>
            <TableHeader width={COLUMN_REPORT_WIDTH}>{table.labels.report}</TableHeader>
            <TableHeader width={COLUMN_ERROR_WIDTH - 180 - 10}>{table.labels.errors}</TableHeader>
            <FeatureFlagged flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED">
                <ErrorsOnlySwitch>
                    {table.showErrorsOnly}
                    <Switch
                        isChecked={errorsOnly}
                        onChange={errorsOnlySwitchChangeHandler}
                        size={'sm'}
                    />
                </ErrorsOnlySwitch>
            </FeatureFlagged>
        </TableHeaderWrapper>
    );
};

const renderBodyRow: React.FC<RenderBodyRowT<NibrsErrorRow>> = ({ item }) => {
    const { errorSegment, reportShortTitle } = item;

    return (
        <TableRow key={reportShortTitle?.reportId}>
            <TableCell width={COLUMN_REPORT_WIDTH}>
                <ComplianceReportTitleCell reportShortTitle={reportShortTitle} />
            </TableCell>
            <TableCell width={COLUMN_ERROR_WIDTH}>
                <TableCellContent>
                    <ComplianceExportErrorsCell errorSegment={errorSegment} />
                </TableCellContent>
            </TableCell>
        </TableRow>
    );
};

function convertToNibrsErrorSegmentDescriptions(
    errorSegments?: ComplianceReportErrors[]
): NibrsReportSegmentsDescription[] {
    return map(errorSegments, (segment) => {
        const errors = map(segment.errorDescriptions, (errorDescription) => ({
            nibrsErrorCode: errorDescription.errorCode,
            nibrsErrorMessage: errorDescription.errorMessage,
            // typescript: optional -> required
            rmsSuggestionMessage: errorDescription.rmsSuggestionMessage || '',
        }));

        return {
            errors,
            // typescript required
            isIncident: false,
            reportId: segment.reportId,
        };
    });
}

function usePagination(errorSummary?: ErrorSummary) {
    const [page, setPage] = useState(1);
    const [errorsOnly, setErrorsOnly] = useState(true);
    const [shortTitles, setShortTitles] = useState(
        errorSummary?.errorSummary.reportShortTitles || []
    );

    const errorsOnlySwitchChangeHandler = () => {
        setPage(1);
        setErrorsOnly((previous) => !previous);
    };

    useEffect(() => {
        const zeroBasedPage = page - 1;
        const fromOffset = zeroBasedPage * COMPLIANCE_DEFAULT_PAGE_SIZE;
        const toOffset = fromOffset + COMPLIANCE_DEFAULT_PAGE_SIZE;
        if (errorSummary?.errorSummary.exportUUID) {
            nibrsExportResource
                .getExportErrorsPaginated(
                    errorSummary?.errorSummary.exportUUID,
                    fromOffset,
                    toOffset,
                    !errorsOnly
                )
                .then((result) => {
                    setShortTitles(result.reportShortTitles);
                });
        }
    }, [page, errorsOnly, errorSummary?.errorSummary.exportUUID]);

    // normalize error summary keys
    const errorSegments =
        errorSummary && errorSummary?.complianceType === NIBRS_COMPLIANCE_TYPE.name
            ? errorSummary?.errorSummary.errorSegments
            : convertToNibrsErrorSegmentDescriptions(errorSummary?.errorSummary.reportErrors);
    const zeroBasedPage = page - 1;
    const sliceOffset = zeroBasedPage * COMPLIANCE_DEFAULT_PAGE_SIZE;
    const allReportShortTitles = shortTitles || errorSummary?.errorSummary.reportShortTitles || [];

    const reportShortTitles = sortBy(allReportShortTitles, 'reportId');

    const nibrsErrorRows = compact(
        map(reportShortTitles, (reportShortTitle) => {
            const reportId = reportShortTitle.reportId;
            const errorSegment = find(errorSegments, { reportId });
            if (errorsOnly && !errorSegment) {
                return undefined;
            }
            return {
                reportShortTitle,
                errorSegment,
            };
        })
    );

    const summaryFrom = sliceOffset;
    const summaryTo = sliceOffset + reportShortTitles.length;
    const total = errorsOnly
        ? errorSummary?.errorSummary?.totalIncidentErrorNum || 0
        : errorSummary?.errorSummary?.totalIncidentNum || 0;

    const handlePaginationClick = useCallback(
        (currentPage, nextPage) => {
            if (nextPage * COMPLIANCE_DEFAULT_PAGE_SIZE > total) {
                setPage(Math.ceil(total / COMPLIANCE_DEFAULT_PAGE_SIZE));
            } else if (nextPage <= 1) {
                setPage(1);
            } else {
                setPage(nextPage);
            }

            // Handling this directly when clicking the pagination since
            // this pagination is client-only and does not need to wait
            // for data from the server
            scrollToElement({
                selector: `.${RESULT_CONTAINER_CLASS}`,
                wrapperSelector: `.${SCROLL_CONTAINER_CLASS}`,
            });
        },
        [setPage, total]
    );

    return {
        handlePaginationClick,
        nibrsErrorRows,
        page,
        summaryFrom,
        summaryTo,
        total,
        errorsOnly,
        setErrorsOnly,
        errorsOnlySwitchChangeHandler,
    };
}

const ComplianceExportErrors: React.FC<ComplianceExportErrorsProps> = (props) => {
    const { errorSummary } = props;

    const {
        handlePaginationClick,
        nibrsErrorRows,
        page,
        summaryFrom,
        summaryTo,
        total,
        errorsOnly,
        errorsOnlySwitchChangeHandler,
    } = usePagination(errorSummary);

    return (
        <>
            <TableWrapper>
                <RMSTableComposite
                    className={RESULT_CONTAINER_CLASS}
                    columns={[]}
                    items={nibrsErrorRows}
                    includeFilter={false}
                    noDataText={table.noResults}
                    renderHeaderRow={() =>
                        renderHeaderRow({ errorsOnly, errorsOnlySwitchChangeHandler })
                    }
                    renderBodyRow={renderBodyRow}
                    testId={testIds.COMPLIANCE_DASHBOARD_EXPORT_ERRORS_TABLE}
                />
            </TableWrapper>
            {!!total && (
                <PaginationWrapper>
                    <TableResultsSummary
                        from={summaryFrom}
                        to={summaryTo}
                        totalResults={total}
                        className={null}
                    />
                    <Pagination
                        currentPage={page}
                        itemsPerPage={COMPLIANCE_DEFAULT_PAGE_SIZE}
                        itemCount={total}
                        onClick={handlePaginationClick}
                        maxEdgeItems={1}
                        className={null}
                        gapText={'...'}
                    />
                </PaginationWrapper>
            )}
        </>
    );
};
export default ComplianceExportErrors;

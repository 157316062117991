import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { parseInt, find } from 'lodash';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import { courtOrdersByReportIdSelector } from '~/client-common/core/domain/court-orders/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';

import withCourtOrdersForReport from '../../core/components/withCourtOrdersForReport';
import { updateUploads } from '../../../attachments/core/state/ui/inlineAttachmentsUploader';
import Select from '../../../core/forms/components/selects/Select';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import courtOrderEditForm from '../state/forms/courtOrderEditForm';
import {
    submitCourtOrderEditForm,
    cleanupReportCourtOrder,
    courtOrderEditcourtOrderLoadingStateSelector,
    courtOrderEditIsSubmittingSelector,
    initializeCourtOrderEditForm,
} from '../state/ui';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { ColumnFullBottomBorder } from '../../core/components/RecordPrivacyColumn';
import CourtOrderEditForm from './CourtOrderEditForm';
import CourtOrderEditActionBar from './CourtOrderEditActionBar';

class CourtOrderEditDashboard extends React.Component {
    state = {
        selectedCourtOrder: undefined,
    };

    componentWillUnmount() {
        this.props.resetState();
    }

    handleCourOrderSelectChange = (value) => {
        const parsedCourtOrderId = parseInt(value);
        this.setState({ selectedCourtOrder: parsedCourtOrderId });
        const { reportId } = this.props.routeParams;
        const { courtOrdersForReport } = this.props;
        this.props.initializeCourtOrderEditForm({
            reportId,
            courtOrder: find(courtOrdersForReport, ({ id }) => id === parsedCourtOrderId),
        });
    };

    render() {
        const {
            routeParams,
            onFormSubmit,
            courtOrderEditState,
            courtOrderEditIsSubmitting,
            reports,
            courtOrderOptions,
        } = this.props;
        const { reportId } = routeParams;
        const backUrl = `/reports/${reportId}`;
        const { isLoading, loadingError, loaded } = courtOrderEditState;

        return (
            <Page>
                <Subheader
                    title={componentStrings.recordPrivacy.courtOrders.CourtOrderEditDashboard.title}
                    backButtonTo={backUrl}
                />
                <ScrollableUnderSubheader style={{ display: 'flex' }} forceNoClassName={true}>
                    {isLoading && <SimpleLoading />}
                    {loadingError && <InlineBanner status="error">{loadingError}</InlineBanner>}
                    {loaded && !this.state.selectedCourtOrder && (
                        <ColumnFullBottomBorder>
                            <Select
                                options={courtOrderOptions}
                                onChange={this.handleCourOrderSelectChange}
                                value={this.state.selectedCourtOrder}
                                label={
                                    componentStrings.recordPrivacy.courtOrders.CourtOrderSelect
                                        .label
                                }
                                maxDropdownItems={Infinity}
                            />
                        </ColumnFullBottomBorder>
                    )}
                    {loaded && this.state.selectedCourtOrder && (
                        <CourtOrderEditForm
                            report={reports[reportId]}
                            courtOrderId={this.state.selectedCourtOrder}
                        />
                    )}
                </ScrollableUnderSubheader>
                {loaded && (
                    <CourtOrderEditActionBar
                        saveButtonDisabled={courtOrderEditIsSubmitting}
                        onSaveClick={onFormSubmit}
                        backUrl={backUrl}
                    />
                )}
            </Page>
        );
    }
}

export default compose(
    connect(
        createStructuredSelector({
            formIsValid: courtOrderEditForm.selectors.formIsValidSelector,
            formModelByPath: courtOrderEditForm.selectors.formModelByPathSelector,
            courtOrderEditState: courtOrderEditcourtOrderLoadingStateSelector,
            courtOrderEditIsSubmitting: courtOrderEditIsSubmittingSelector,
            courtOrdersByReportId: courtOrdersByReportIdSelector,
            reports: reportsSelector,
            formatFieldByName: formatFieldByNameSelector,
            formatAttributeById: formatAttributeByIdSelector,
        }),
        (dispatch, props) => ({
            onFormSubmit: () => dispatch(submitCourtOrderEditForm({ router: props.router })),
            resetState: () => {
                dispatch(courtOrderEditForm.actionCreators.reset());
                dispatch(cleanupReportCourtOrder());
                // reset attachment uploader
                dispatch(updateUploads([]));
            },
            initializeCourtOrderEditForm: (...args) =>
                dispatch(initializeCourtOrderEditForm(...args)),
        })
    ),
    withCourtOrdersForReport
)(CourtOrderEditDashboard);

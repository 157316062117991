import { first, map } from 'lodash';
import { createSelector } from 'reselect';

// helpers
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    boolToDisplayMapper,
} from '../../../../../helpers/viewModelHelpers';

// selectors
import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '../../../attributes/state/data';
import { towVehiclesWhereSelector } from '../data';

const buildTowVehicleViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
    (formatAttributeById, formatAttributeWithOther) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                boolToDisplayMapper,
                ({ towCompanyCalledAttrId, towCompanyCalledOther }) => {
                    return {
                        towCompanyCalledDisplay: formatAttributeWithOther({
                            attributeId: towCompanyCalledAttrId,
                            other: towCompanyCalledOther,
                        }),
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });

        return viewModel;
    }
);

const towVehicleViewModelsWhereSelector = createSelector(
    towVehiclesWhereSelector,
    buildTowVehicleViewModelSelector,
    (towVehiclesWhere, buildTowVehicleViewModel) => (predicate) =>
        map(towVehiclesWhere(predicate), buildTowVehicleViewModel)
);

export const towVehicleViewModelByReportIdSelector = createSelector(
    towVehicleViewModelsWhereSelector,
    (towVehicleViewModelsWhere) => (reportId) => first(towVehicleViewModelsWhere({ reportId }))
);

import _ from 'lodash';

export const subdivisionSortingOrder = [
    'SUBDIVISION_DEPTH_1',
    'SUBDIVISION_DEPTH_2',
    'SUBDIVISION_DEPTH_3',
    'SUBDIVISION_DEPTH_4',
    'SUBDIVISION_DEPTH_5',
    'BUREAU',
    'DIVISION',
    'BRANCH',
    'PERSONNEL_UNIT',
];

export function getAttributeTypeSortingOrder(attributeType) {
    return _.indexOf(subdivisionSortingOrder, attributeType);
}

import React from 'react';
import styled from 'styled-components';
import { Text, cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

const strings = componentStrings.eFiles.materials;

const Header = styled('div')`
    padding-bottom: ${cssVar('arc.space.2')};
`;

export const EFileMaterialsPage: React.FC = ({ children }) => {
    return (
        <>
            <Header>
                <Text variant="headingMd">{strings.header.title}</Text>
            </Header>
            {children}
        </>
    );
};

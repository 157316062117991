import { EntityTypeEnumType, HtmlEditorResource } from '@mark43/rms-api';
import { createResource } from '../../../../../lib/resources/Resource';
import { req } from '../../../../../lib/ajax';

const BASE_PATH = 'html_editor';

export default createResource({
    name: 'HtmlEditor Resource',
    methods: {
        uploadImage(entityId: number, entityType: EntityTypeEnumType, formData: FormData) {
            return req<HtmlEditorResource.Upload2>({
                method: 'POST',
                params: {
                    entity_id: entityId,
                },
                data: formData,
                url: `${BASE_PATH}/type/${entityType}/image`,
                hideLoadingBar: true,
            });
        },
    },
});

import React, { useCallback, useState } from 'react';
import { JmsApiBookingSearchRequest, JmsApiUser, JmsLocation, JmsBooking } from '@mark43/rms-api';
import { WithRouterProps, withRouter } from 'react-router';
import { pageSizeToFrom } from '~/client-common/helpers/searchHelpers';
import { searchSizes } from '~/client-common/configs/advancedSearchConfig';
import { BookingCellProps } from '../../../entity-profiles/persons/components/EntityProfilePersonJmsBookings';
import { bookingsResource } from '../resources';
import { logError } from '../../../../core/logging';
import SearchCard from './SearchCard';
import { SearchBookingsForm } from './SearchBookingsForm';
import { AdvancedSearchBookingResults } from './AdvancedSearchBookingResults';

const DEFAULT_OPTIONS_LIMIT = 100;
const DEFAULT_PAGE = 1;
const AdvancedSearchBookingsComponent: React.FC<WithRouterProps> = ({ router }) => {
    const [bookings, setBookings] = useState<JmsBooking[] | undefined>();
    const [totalCount, setTotalCount] = useState<number>(0);
    const [isBookingsLoading, setIsBookingsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [lastRequestedData, setLastRequestedData] = useState<Partial<JmsApiBookingSearchRequest>>(
        {}
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState<searchSizes>(25);
    const [locationsLoading, setLocationsLoading] = useState(false);
    const [officersLoading, setOfficersLoading] = useState(false);
    const [locations, setLocations] = useState<JmsLocation[]>([]);
    const [officers, setOfficers] = useState<JmsApiUser[]>([]);

    const searchLocations = useCallback(async (query?: string) => {
        setLocationsLoading(true);
        return bookingsResource
            .getBookingFacilities(query, DEFAULT_OPTIONS_LIMIT)
            .then((res) => {
                setLocations(res.items);
            })
            .catch(logError)
            .finally(() => {
                setLocationsLoading(false);
            });
    }, []);
    const searchUsers = useCallback(async (query?: string) => {
        setOfficersLoading(true);
        return bookingsResource
            .getJmsUsers(query, DEFAULT_OPTIONS_LIMIT)
            .then((res) => {
                setOfficers(res.items);
            })
            .catch(logError)
            .finally(() => {
                setOfficersLoading(false);
            });
    }, []);
    const searchBookings = async (
        data: Partial<JmsApiBookingSearchRequest>,
        from?: number,
        size?: searchSizes
    ) => {
        if (from === undefined) {
            from = pageSizeToFrom(1, pageSize);
            setCurrentPage(DEFAULT_PAGE);
        }
        if (!size) {
            size = pageSize;
        }
        setIsBookingsLoading(true);
        return bookingsResource
            .searchBookings({ ...data, offset: from, limit: size })
            .then((res) => {
                setIsBookingsLoading(false);
                setBookings(res.items);
                setTotalCount(res.totalCount);
                setLastRequestedData(data);
            })
            .catch((error) => {
                setErrorMessage(error.message);
            });
    };
    const handlePagination = (currentPage: number, nextPage: number) => {
        const from = pageSizeToFrom(nextPage, pageSize);
        setCurrentPage(nextPage);
        searchBookings(lastRequestedData, from, pageSize);
    };

    const handleRowClick = ({ id }: BookingCellProps) => {
        router.push(`bookings/${id}`);
    };

    const handlePageSizeChange = (newSize: searchSizes) => {
        if (pageSize === newSize) {
            return;
        }
        setPageSize(newSize);
        setCurrentPage(DEFAULT_PAGE);
        const from = pageSizeToFrom(DEFAULT_PAGE, pageSize);
        searchBookings(lastRequestedData, from, newSize);
    };

    return (
        <>
            <SearchCard key={0}>
                <SearchBookingsForm
                    searchLocations={searchLocations}
                    searchUsers={searchUsers}
                    officersOptions={officers.map((item) => {
                        return {
                            value: item.id,
                            label: item.fullName,
                        };
                    })}
                    locationsOptions={locations.map((item) => {
                        return {
                            value: item.id,
                            label: item.displayName,
                        };
                    })}
                    locationsLoading={locationsLoading}
                    officersLoading={officersLoading}
                    submitHandler={searchBookings}
                />
            </SearchCard>
            <div className="bookings-results-container" key={1}>
                <SearchCard>
                    {bookings && (
                        <AdvancedSearchBookingResults
                            bookings={bookings}
                            errorMessage={errorMessage}
                            totalCount={totalCount}
                            from={pageSizeToFrom(currentPage, pageSize)}
                            pageSize={pageSize}
                            handlePagination={handlePagination}
                            loading={isBookingsLoading}
                            handleRowClick={handleRowClick}
                            handlePageSizeChange={handlePageSizeChange}
                            currentPage={currentPage}
                        />
                    )}
                </SearchCard>
            </div>
        </>
    );
};

export const AdvancedSearchBookings = withRouter(AdvancedSearchBookingsComponent);

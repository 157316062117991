import React from 'react';
import _ from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

const strings = componentStrings.entityProfiles.person.DemeanorDetails;

const mapAttribute = (attr) => <div key={attr}>{attr}</div>;

export default function DemeanorDetails({ nameAttributes }) {
    const behaviorCharacteristicAttributes =
        !!nameAttributes.BEHAVIORAL_CHARACTERISTIC &&
        _.map(nameAttributes.BEHAVIORAL_CHARACTERISTIC, mapAttribute);
    const moodAttributes = !!nameAttributes.MOOD && _.map(nameAttributes.MOOD, mapAttribute);
    const modusOperandiAttributes =
        !!nameAttributes.MODUS_OPERANDI && _.map(nameAttributes.MODUS_OPERANDI, mapAttribute);
    const skillsAttributes =
        !!nameAttributes.PERSON_SKILL && _.map(nameAttributes.PERSON_SKILL, mapAttribute);

    return (
        <EntityProfileSection title={strings.title}>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    <SummaryRow label={strings.labels.behavioralChar}>
                        {behaviorCharacteristicAttributes}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.mood}>{moodAttributes}</SummaryRow>
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    <SummaryRow label={strings.labels.modusOperandi}>
                        {modusOperandiAttributes}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.skills}>{skillsAttributes}</SummaryRow>
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

import actionTypes from '../actions/types/alertsActionTypes';

const initialUIState = {
    saving: false,
    formOpen: false,
    loading: false,
    errorMsg: null,
    bannersReversed: false,
};

export function alertsUIReducer(state = initialUIState, action) {
    switch (action.type) {
        case actionTypes.UPLOAD_ATTACHMENT_START:
            return {
                ...state,
                saving: true,
            };
        case actionTypes.MFTUPLOAD_ATTACHMENT_SUCCESS:
        case actionTypes.MFTUPLOAD_INFECTED_FILES_FOUND:
            return {
                ...state,
                saving: false,
            };
        case actionTypes.OPEN_ALERT_FORM:
            return {
                ...state,
                formOpen: true,
                errorMsg: null,
            };
        case actionTypes.CLOSE_ALERT_FORM:
            return {
                ...state,
                formOpen: false,
            };
        case actionTypes.API_FAILURE:
            return {
                ...state,
                errorMsg: action.payload,
                saving: false,
                loading: false,
            };
        case actionTypes.REVERSE_BANNERS:
            return {
                ...state,
                bannersReversed: !state.bannersReversed,
            };
        default:
            return state;
    }
}

import { size, filter } from 'lodash';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

export function getCountWithValue(value, { targetValue } = {}) {
    if (isUndefinedOrNull(value)) {
        return 0;
    } else {
        // lets us count empty fields
        if (targetValue === '') {
            targetValue = undefined;
        }
        return size(filter(value, { castedValue: targetValue }));
    }
}

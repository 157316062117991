import { Printable } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'attachmentFolderPrintables';

/**
 * This module does not store all Printables, it only stores Printables which represent folders containing attachments.
 * eg. attachment folders or notes folders containing notes that have attachments
 * evidence-printables is a similar module.
 */
const attachmentFolderPrintablesModule = createLinkModule<Printable>({
    type: NEXUS_STATE_PROP,
    keys: ['entityId'], // as of August 2022, the linked entity is always a case
});

export const attachmentFolderPrintablesSelector =
    attachmentFolderPrintablesModule.selectors.linksSelector;

export default attachmentFolderPrintablesModule.reducerConfig;

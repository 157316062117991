import { createSelector } from 'reselect';
import _ from 'lodash';
import Promise from 'bluebird';

import {
    DELETE_EVIDENCE_PRINTING_TEMPLATE_START,
    DELETE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS,
    DELETE_EVIDENCE_PRINTING_TEMPLATE_FAILURE,
    evidencePrintingTemplatesSelector,
    saveEvidencePrintingTemplate,
    loadEvidencePrintingTemplates as loadEvidencePrintingTemplatesRaw,
} from '~/client-common/core/domain/evidence-printing-templates/state/data';
import { NEXUS_STATE_PROP as PRINTING_TEMPLATES_NEXUS_STATE_PROP } from '~/client-common/core/domain/printing-templates/state/data';
import { templateFileLinksWhereSelector } from '~/client-common/core/domain/template-file-links/state/data';
import { fileByIdSelector } from '~/client-common/core/domain/files/state/data';

import evidencePrintingTemplateAdminForm from '../forms/evidencePrintingTemplateAdminForm';
import printingTemplatesResource from '../../../printing-templates/resources/printingTemplatesResource';
import { UPLOAD_FILE_START, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE, REMOVE_FILE } from './upload';

export * from './upload';

const OPEN_NEW_EVIDENCE_PRINTING_TEMPLATE_FORM =
    'evidence-printing templates/OPEN_NEW_EVIDENCE_PRINTING_TEMPLATE_FORM';
const CLOSE_EVIDENCE_PRINTING_TEMPLATE_FORM =
    'evidence-printing templates/CLOSE_EVIDENCE_PRINTING_TEMPLATE_FORM';
const SELECT_EVIDENCE_PRINTING_TEMPLATE_SUCCESS =
    'evidence-printing templates/SELECT_EVIDENCE_PRINTING_TEMPLATE_SUCCESS';
const SELECT_EVIDENCE_PRINTING_TEMPLATE_FAILURE =
    'evidence-printing templates/SELECT_EVIDENCE_PRINTING_TEMPLATE_FAILURE';
const SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_START =
    'evidence-printing templates/SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_START';
const SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_SUCCESS =
    'evidence-printing templates/SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_SUCCESS';
const SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_FAILURE =
    'evidence-printing templates/SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_FAILURE';
const LOAD_EVIDENCE_PRINTING_TEMPLATES_START =
    'evidence-printing templates/LOAD_EVIDENCE_PRINTING_TEMPLATES_START';
const LOAD_EVIDENCE_PRINTING_TEMPLATES_SUCCESS =
    'evidence-printing templates/LOAD_EVIDENCE_PRINTING_TEMPLATES_SUCCESS';
const LOAD_EVIDENCE_PRINTING_TEMPLATES_FAILURE =
    'evidence-printing templates/LOAD_EVIDENCE_PRINTING_TEMPLATES_FAILURE';

function loadEvidencePrintingTemplatesStart() {
    return { type: LOAD_EVIDENCE_PRINTING_TEMPLATES_START };
}
function loadEvidencePrintingTemplatesSuccess() {
    return { type: LOAD_EVIDENCE_PRINTING_TEMPLATES_SUCCESS };
}
function loadEvidencePrintingTemplatesFailure(errorMessage) {
    return {
        type: LOAD_EVIDENCE_PRINTING_TEMPLATES_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

function openNewEvidencePrintingTemplateFormRaw() {
    return { type: OPEN_NEW_EVIDENCE_PRINTING_TEMPLATE_FORM };
}

export function closeEvidencePrintingTemplateForm() {
    return { type: CLOSE_EVIDENCE_PRINTING_TEMPLATE_FORM };
}

function selectEvidencePrintingTemplateSuccess(selectedId, stagedFiles) {
    return {
        type: SELECT_EVIDENCE_PRINTING_TEMPLATE_SUCCESS,
        payload: { selectedId, stagedFiles },
    };
}
function selectEvidencePrintingTemplateFailure(errorMessage) {
    return {
        type: SELECT_EVIDENCE_PRINTING_TEMPLATE_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

function submitEvidencePrintingTemplateFormStart() {
    return { type: SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_START };
}
function submitEvidencePrintingTemplateFormSuccess() {
    return { type: SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_SUCCESS };
}
function submitEvidencePrintingTemplateFormFailure(errorMessage) {
    return {
        type: SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Load evidence printing templates and printing templates
 */
export function loadEvidencePrintingTemplates() {
    return (dispatch, getState, dependencies) => {
        dispatch(loadEvidencePrintingTemplatesStart());

        return Promise.all([
            dispatch(loadEvidencePrintingTemplatesRaw()),
            printingTemplatesResource.getStrippedPrintingTemplates(),
        ])
            .spread((evidencePrintingTemplates, printingTemplates) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [PRINTING_TEMPLATES_NEXUS_STATE_PROP]: printingTemplates,
                        },
                        loadEvidencePrintingTemplatesSuccess()
                    )
                );
            })
            .catch((err) => dispatch(loadEvidencePrintingTemplatesFailure(err.message)));
    };
}

/**
 * Select an evidence printing template on the admin page.
 * @param {number} id
 */
export function selectEvidencePrintingTemplate(id) {
    return (dispatch, getState) => {
        const evidencePrintingTemplate = evidencePrintingTemplatesSelector(getState())[id];
        const files = evidencePrintingTemplateFiles(getState())(id);
        dispatch(evidencePrintingTemplateAdminForm.actionCreators.reset());

        if (!!evidencePrintingTemplate) {
            dispatch(
                evidencePrintingTemplateAdminForm.actionCreators.change(evidencePrintingTemplate)
            );
            dispatch(selectEvidencePrintingTemplateSuccess(id, files));
        } else {
            dispatch(selectEvidencePrintingTemplateFailure('Evidence Document Not Found'));
        }
    };
}

/**
 * Open the form for a new evidence printing template.
 */
export function openNewEvidencePrintingTemplateForm() {
    return (dispatch) => {
        dispatch(evidencePrintingTemplateAdminForm.actionCreators.reset());
        dispatch(openNewEvidencePrintingTemplateFormRaw());
    };
}

/**
 * Submit the evidence printing template admin form.
 *   The evidence printing template can be new or existing.
 * @return {Promise<Object|boolean>} evidence printing template model
 */
export function submitEvidencePrintingTemplateForm() {
    return (dispatch, getState) => {
        dispatch(submitEvidencePrintingTemplateFormStart());

        return dispatch(
            evidencePrintingTemplateAdminForm.actionCreators.submit((formModel) => {
                const files = stagedFilesSelector(getState());
                const id = selectedEvidencePrintingTemplateIdSelector(getState());
                const template = evidencePrintingTemplateAdminForm.convertFromFormModel(
                    formModel,
                    id,
                    files
                );

                return dispatch(saveEvidencePrintingTemplate(template))
                    .then((evidencePrintingTemplate) => {
                        dispatch(submitEvidencePrintingTemplateFormSuccess());
                        return evidencePrintingTemplate;
                    })
                    .catch((err) =>
                        dispatch(submitEvidencePrintingTemplateFormFailure(err.message))
                    );
            })
        ).catch((panelErrors) =>
            dispatch(submitEvidencePrintingTemplateFormFailure(_.head(panelErrors)))
        );
    };
}

const evidencPrintingTemplatesUiSelector = (state) => state.ui.evidencePrintingTemplatesAdmin;

/**
 * The id of the selected evidence printing template on the admin page.
 *   `null` when none is selected.
 * @type {number|null}
 */
export const selectedEvidencePrintingTemplateIdSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ selectedId }) => selectedId
);

/**
 * Error message on the evidence printing template form at the right on the admin page.
 * @type {string|null}
 */
export const evidencePrintingTemplateFormErrorMessageSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ formErrorMessage }) => formErrorMessage
);

/**
 * Whether an evidence printing template is being created/updated/deleted on the
 *   admin page.
 * @type {boolean}
 */
export const savingEvidencePrintingTemplateSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ saving }) => saving
);

/**
 * Whether evidence printing templates are being loaded for the admin page.
 * @type {boolean}
 */
export const loadingEvidencePrintingTemplatesSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ loading }) => loading
);

/**
 * Error message on the evidence printing templates list at the left on the admin page.
 * @type {string|null}
 */
export const evidencePrintingTemplatesListErrorMessageSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ listErrorMessage }) => listErrorMessage
);

/**
 * Whether the evidence printing templates have been previously successfully loaded or not.
 *   When you select a printing template or create a new one, the root onEnter is called.
 *   This is used to prevent mulitple API calls in the same session.
 * @type {boolean}
 */
export const evidencePrintingTemplatesPreviouslyLoadedSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ previouslyLoaded }) => previouslyLoaded
);

/**
 * Whether the evidence printing template form is open or closed.
 * @type {boolean}
 */
export const evidencePrintingTemplateFormIsOpenSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ formIsOpen }) => formIsOpen
);

/**
 * Whether the open form is a new or exiting evidence printing template.
 * @type {boolean}
 */
export const evidencePrintingTemplateFormIsNewSelector = createSelector(
    evidencePrintingTemplateFormIsOpenSelector,
    selectedEvidencePrintingTemplateIdSelector,
    (evidencePrintingTemplateFormIsOpen, selectedEvidencePrintingTemplateId) =>
        evidencePrintingTemplateFormIsOpen && !selectedEvidencePrintingTemplateId
);

/**
 * The data model for the selected evidence printing template.
 * @type {object|undefined}
 */
export const selectedEvidencePrintingTemplateSelector = createSelector(
    selectedEvidencePrintingTemplateIdSelector,
    evidencePrintingTemplatesSelector,
    (selectedEvidencePrintingTemplateId, evidencePrintingTemplates) =>
        evidencePrintingTemplates[selectedEvidencePrintingTemplateId]
);

/**
 * Files for evidence printing template. File objects are saved in ui state
 *   and passed into convertFromFormModel.
 * @type {object[]}
 */
export const stagedFilesSelector = createSelector(
    evidencPrintingTemplatesUiSelector,
    ({ stagedFiles }) => stagedFiles
);

/**
 * For a given evidencePrintingTemplateId, find in redux state all files
 *   via templateFileLinks.
 * @param {number} evidencePrintingTemplateId
 * @type {object[]}
 */
const evidencePrintingTemplateFiles = createSelector(
    templateFileLinksWhereSelector,
    fileByIdSelector,
    (templateFileLinksWhere, fileById) => (evidencePrintingTemplateId) => {
        const links = templateFileLinksWhere({ evidencePrintingTemplateId });

        return _.map(links, ({ fileId }) => fileById(fileId));
    }
);

/**
 * All evidence printing templates in the shape of <AdminList> item objects.
 * @type {Object[]}
 */
export const evidencePrintingTemplatesAdminListSelector = createSelector(
    evidencePrintingTemplatesSelector,
    selectedEvidencePrintingTemplateIdSelector,
    (evidencePrintingTemplates, selectedId) =>
        _(evidencePrintingTemplates)
            .map(({ id, name }) => ({
                path: `/admin/evidence/documents/${id}`,
                key: id,
                title: name,
                selected: id === selectedId,
            }))
            .sortBy('title')
            .value()
);

export default function evidencePrintingTemplatesAdminUiReducer(
    state = {
        selectedId: null,
        loading: false,
        previouslyLoaded: false,
        saving: false,
        formIsOpen: false,
        listErrorMessage: null,
        formErrorMessage: null,
        stagedFiles: [],
    },
    action
) {
    switch (action.type) {
        case OPEN_NEW_EVIDENCE_PRINTING_TEMPLATE_FORM:
            return {
                ...state,
                selectedId: null,
                formIsOpen: true,
                formErrorMessage: null,
                stagedFiles: [],
            };
        case SELECT_EVIDENCE_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                selectedId: action.payload.selectedId,
                formIsOpen: true,
                formErrorMessage: null,
                stagedFiles: action.payload.stagedFiles,
            };
        case SELECT_EVIDENCE_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                selectedId: null,
                formIsOpen: false,
                formErrorMessage: action.payload,
            };
        case DELETE_EVIDENCE_PRINTING_TEMPLATE_START:
            return {
                ...state,
                saving: true,
                formErrorMessage: null,
            };
        case DELETE_EVIDENCE_PRINTING_TEMPLATE_SUCCESS:
            return {
                ...state,
                saving: false,
                formIsOpen: false,
                formErrorMessage: null,
            };
        case DELETE_EVIDENCE_PRINTING_TEMPLATE_FAILURE:
            return {
                ...state,
                saving: false,
                formErrorMessage: action.payload,
            };
        case SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_START:
            return {
                ...state,
                saving: true,
                formErrorMessage: null,
            };
        case SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_SUCCESS:
            return {
                ...state,
                saving: false,
                formErrorMessage: null,
            };
        case SUBMIT_EVIDENCE_PRINTING_TEMPLATE_FORM_FAILURE:
            return {
                ...state,
                saving: false,
                formErrorMessage: action.payload,
            };
        case CLOSE_EVIDENCE_PRINTING_TEMPLATE_FORM:
            return {
                ...state,
                selectedId: null,
                formIsOpen: false,
                formErrorMessage: null,
                stagedFiles: [],
            };
        case LOAD_EVIDENCE_PRINTING_TEMPLATES_START:
            return {
                ...state,
                loading: true,
                previouslyLoaded: false,
                listErrorMessage: null,
            };
        case LOAD_EVIDENCE_PRINTING_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                previouslyLoaded: true,
                listErrorMessage: null,
            };
        case LOAD_EVIDENCE_PRINTING_TEMPLATES_FAILURE:
            return {
                ...state,
                loading: false,
                previouslyLoaded: false,
                listErrorMessage: action.payload,
            };
        case UPLOAD_FILE_START:
            return {
                ...state,
                saving: true,
                formErrorMessage: null,
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                saving: false,
                stagedFiles: [...state.stagedFiles, action.payload],
            };
        case UPLOAD_FILE_FAILURE:
            return {
                ...state,
                saving: false,
                formErrorMessage: action.payload,
            };
        case REMOVE_FILE:
            return {
                ...state,
                stagedFiles: _.reject(state.stagedFiles, {
                    id: action.payload,
                }),
            };
        default:
            return state;
    }
}

import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import _, { chain, first, map, some, every, includes, keyBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import classNames from 'classnames';

import styled from 'styled-components';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { inPoliceCustodyChainEventTypeSelector } from '~/client-common/core/domain/chain-event-types/state/data';
import { chainEventViewModelsForChainOfCustodyIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { chainOfCustodiesSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { itemEvidenceStateByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import { dispositionEventsByMasterItemIdsSelector } from '~/client-common/core/domain/disposition-events/state/data';
import { userRolesSelector } from '~/client-common/core/domain/user-roles/state/data';
import { evidenceLocationPermissionsWhereSelector } from '~/client-common/core/domain/evidence-location-permissions/state/data';
import { itemTypeSpecificViewModelByIdSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';

import { fillResetRetentionForm } from '../state/forms/resetRetentionPolicyForm';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import _Button, {
    ReactButtonIconLeft,
    buttonTypes,
} from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import _DropdownMenu from '../../../core/components/DropdownMenu';
import { Tooltip } from '../../../core/components/tooltip';
import { BlueActionBar } from '../../../core/components/ActionBar';
import { openLabelPrintingModal } from '../../../evidence/label-printing/state/ui';
import { hasEvidenceLocationPermission } from '../../../evidence/core/utils/evidenceItemsHelpers';

import {
    selectedMasterItemIdsSelector,
    selectedItemProfileIdsSelector,
    requestDispositions,
    openDocumentExportingModal,
} from '../state/ui';
import { invalidResetRetentionPolicyDispositionStatuses } from '../state/config';
import { openStaffRemarkSidePanel } from '../../../evidence/core/state/ui';
import { addToItemQueue } from '../../../evidence/item-queue/state/ui';
import { openItemSplitPopoverForm } from '../../../evidence/item-split/state/ui';
import { currentReportIdSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import testIds from '../../../../core/testIds';
import withDispositionActionSidePanel, { ACTION_TYPES } from './withDispositionActionSidePanel';

const { ITEM_PROFILE } = EntityTypeEnum;
const strings = componentStrings.reports.custodialPropertySummary.CustodialPropertyActionBar;

const Button = styled(_Button)`
    align-items: center;
    display: flex;
    flex-direction: row;

    ${ReactButtonIconLeft} {
        margin-top: 1px;
    }
`;

function ActionButton({ disabled, disabledTooltipText, ...props }) {
    const button = <Button className={buttonTypes.SECONDARY} disabled={disabled} {...props} />;
    return disabled && disabledTooltipText ? (
        <Tooltip hasButtonOffset side="top" content={disabledTooltipText}>
            {/* Tooltip renders as it's child, the current RMS button doesn't work with that. This
            wrapper keeps the tooltip displaying and aligned with the button. This can be removed
            when these buttons are updated. */}
            <div style={{ float: 'left' }}>{button}</div>
        </Tooltip>
    ) : (
        button
    );
}

const DropdownMenu = styled(_DropdownMenu)`
    .dropdown-menu-button {
        margin-top: 12px;
        ${(props) =>
            props.marginLeft ? `margin-left: ${props.marginLeft}px;` : ''} background-color: ${(
            props
        ) => props.theme.colors.extraLightGrey};
    }
`;

const DropdownMenuButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
`;

function DropdownMenuOption({
    onClick,
    disabled,
    disabledTooltipText,
    children,
    setRef,
    optionTestId,
}) {
    const option = (
        <div
            className={classNames('dropdown-menu-option', { disabled })}
            onClick={disabled ? undefined : onClick}
            data-test-id={optionTestId}
        >
            {children}
        </div>
    );

    return disabled && disabledTooltipText ? (
        <Tooltip side="left" content={disabledTooltipText}>
            <div tabIndex={0} ref={setRef}>
                {option}
            </div>
        </Tooltip>
    ) : (
        option
    );
}

const DispositionDropdownMenuOption = withDispositionActionSidePanel(({ children, ...props }) => (
    <DropdownMenuOption {...props}>{children}</DropdownMenuOption>
));

function CustodialPropertyActionBar({
    masterItemIds,
    someItemIsInPoliceCustody,
    onAddToItemQueueClick,
    canPrintLabels,
    onAddStaffRemarks,
    onPrintLabelsClick,
    onDocumentExportingClick,
    canItemSplit,
    itemSplitTooltip,
    onSplitItemClick,
    canManualRequestDisposition,
    isPrimaryReviewer,
    isSecondaryReviewer,
    canUpdateDisposition,
    canPrimaryReview,
    canSecondaryReviewForRelease,
    canSecondaryReviewForDisposition,
    onResetRetentionButtonClick,
    disableResetRetentionPolicy,
    evidenceLocationPermissionsWhere,
    userRoles,
    latestChainEventByMasterItemId,
    applicationSettings,
}) {
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];
    const userRolesById = keyBy(userRoles, (role) => role.roleId);
    const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;
    const evidenceLocationPermsSet = _(masterItemIds)
        .map((id) => {
            // only return a permission set if the item is currently in Facility or Storage location
            const isItemInEvidenceLocation =
                latestChainEventByMasterItemId[id].facilityId !== undefined ||
                latestChainEventByMasterItemId[id].storageLocationId !== undefined;
            if (isItemInEvidenceLocation) {
                return evidenceLocationPermissionsWhere({ masterItemId: id });
            }
            return;
        })
        .compact()
        .value();

    const hasNoLocationPermission =
        useEvdPerms &&
        !hasEvidenceLocationPermission(
            evidenceLocationPermsSet,
            userRolesById,
            OperationTypeEnum.MANAGE.name
        );

    const requestDispositionTooltip = someItemIsInPoliceCustody
        ? strings.disabledRequestDispositionInPoliceCustodyTooltip(custodyLabel)
        : strings.disabledRequestDispositionTooltip;

    return (
        <BlueActionBar visible={masterItemIds.length > 0}>
            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.ITEM_QUEUE}>
                <ActionButton
                    iconLeft={iconTypes.PROPERTY}
                    onClick={onAddToItemQueueClick}
                    disabled={someItemIsInPoliceCustody || hasNoLocationPermission}
                    disabledTooltipText={
                        someItemIsInPoliceCustody
                            ? strings.disabledAddToQueueTooltip(custodyLabel)
                            : strings.noManagePermissionTooltip
                    }
                    testId={testIds.CUSTODIAL_PROPERTY_ADD_TO_QUEUE}
                >
                    {strings.addToQueue}
                </ActionButton>
            </OnlyWithAbility>

            <ActionButton
                iconLeft={iconTypes.LABEL}
                onClick={onPrintLabelsClick}
                disabled={!canPrintLabels}
                disabledTooltipText={strings.disabledPrintLabelsTooltip(custodyLabel)}
                testId={testIds.CPS_ACTION_BAR_PRINT_LABELS}
            >
                {strings.printLabels}
            </ActionButton>

            <ActionButton
                iconLeft={iconTypes.EXPORT}
                onClick={onDocumentExportingClick}
                testId={testIds.CPS_ACTION_BAR_EXPORT}
            >
                {strings.export}
            </ActionButton>

            {canUpdateDisposition && (
                <DropdownMenu
                    buttonContent={
                        <DropdownMenuButtonContainer>
                            {strings.updateDisposition}
                            <Icon color="cobaltBlue" type={iconTypes.TRIANGLE_DOWN} />
                        </DropdownMenuButtonContainer>
                    }
                    dropup={true}
                    width={190}
                    testId={testIds.CPS_ACTION_BAR_UPDATE_DISPOSITION_MENU}
                >
                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.REQUEST_DISPOSITION}>
                        <DispositionDropdownMenuOption
                            actionType={ACTION_TYPES.REQUEST_DISPOSITION}
                            disabled={!canManualRequestDisposition || hasNoLocationPermission}
                            disabledTooltipText={
                                !canManualRequestDisposition
                                    ? requestDispositionTooltip
                                    : strings.noRequestDisposition
                            }
                            masterItemIds={masterItemIds}
                            optionTestId={testIds.CPS_ACTION_BAR_REQUEST_DISPOSITION}
                        >
                            {strings.requestDisposition}
                        </DispositionDropdownMenuOption>
                    </OnlyWithAbility>

                    {isPrimaryReviewer && (
                        <>
                            <DispositionDropdownMenuOption
                                actionType={ACTION_TYPES.PRIMARY_APPROVE_RELEASE}
                                disabled={!canPrimaryReview || hasNoLocationPermission}
                                disabledTooltipText={
                                    !canPrimaryReview
                                        ? strings.disabledPrimaryDispositionReviewTooltip
                                        : strings.noApproveRelease
                                }
                                masterItemIds={masterItemIds}
                                optionTestId={testIds.CPS_ACTION_BAR_PRIMARY_APPROVE_RELEASE}
                            >
                                {strings.primaryApproveRelease}
                            </DispositionDropdownMenuOption>

                            <DispositionDropdownMenuOption
                                actionType={ACTION_TYPES.PRIMARY_APPROVE_DISPOSITION}
                                disabled={!canPrimaryReview || hasNoLocationPermission}
                                disabledTooltipText={
                                    !canPrimaryReview
                                        ? strings.disabledPrimaryDispositionReviewTooltip
                                        : strings.noApproveRequestDisposition
                                }
                                masterItemIds={masterItemIds}
                                optionTestId={testIds.CPS_ACTION_BAR_PRIMARY_APPROVE_DISPOSITION}
                            >
                                {strings.primaryApproveDisposition}
                            </DispositionDropdownMenuOption>

                            <DispositionDropdownMenuOption
                                actionType={ACTION_TYPES.PRIMARY_REJECT_HOLD}
                                disabled={!canPrimaryReview || hasNoLocationPermission}
                                disabledTooltipText={
                                    !canPrimaryReview
                                        ? strings.disabledPrimaryDispositionReviewTooltip
                                        : strings.noHoldEvidenceItem
                                }
                                masterItemIds={masterItemIds}
                                optionTestId={testIds.CPS_ACTION_BAR_HOLD}
                            >
                                {strings.hold}
                            </DispositionDropdownMenuOption>
                        </>
                    )}

                    {isSecondaryReviewer && (
                        <>
                            <DispositionDropdownMenuOption
                                actionType={
                                    canSecondaryReviewForRelease
                                        ? ACTION_TYPES.SECONDARY_APPROVE_RELEASE
                                        : ACTION_TYPES.SECONDARY_APPROVE_DISPOSITION
                                }
                                disabled={
                                    (!canSecondaryReviewForRelease &&
                                        !canSecondaryReviewForDisposition) ||
                                    hasNoLocationPermission
                                }
                                disabledTooltipText={
                                    !canSecondaryReviewForRelease &&
                                    !canSecondaryReviewForDisposition
                                        ? strings.disabledSecondaryDispositionReviewTooltip
                                        : strings.noApproveRequestDisposition
                                }
                                masterItemIds={masterItemIds}
                                optionTestId={
                                    canSecondaryReviewForRelease
                                        ? testIds.CPS_ACTION_BAR_SECONDARY_APPROVE_RELEASE
                                        : testIds.CPS_ACTION_BAR_SECONDARY_APPROVE_DISPOSITION
                                }
                            >
                                {strings.secondaryApprove}
                            </DispositionDropdownMenuOption>

                            <DispositionDropdownMenuOption
                                actionType={ACTION_TYPES.SECONDARY_REJECT}
                                disabled={
                                    (!canSecondaryReviewForRelease &&
                                        !canSecondaryReviewForDisposition) ||
                                    hasNoLocationPermission
                                }
                                disabledTooltipText={
                                    !canSecondaryReviewForRelease &&
                                    !canSecondaryReviewForDisposition
                                        ? strings.disabledSecondaryDispositionReviewTooltip
                                        : strings.noRejectRequestDisposition
                                }
                                masterItemIds={masterItemIds}
                                optionTestId={testIds.CPS_ACTION_BAR_SECONDARY_REJECT}
                            >
                                {strings.secondaryReject}
                            </DispositionDropdownMenuOption>
                        </>
                    )}
                </DropdownMenu>
            )}

            <OnlyWithAbility
                hasOneOf={[
                    abilitiesEnum.EVIDENCE.EDIT_STAFF_REMARKS,
                    abilitiesEnum.EVIDENCE.CREATE_CHAIN_EVENTS,
                ]}
            >
                <DropdownMenu
                    buttonContent={
                        <DropdownMenuButtonContainer>
                            {strings.more}
                            <Icon color="cobaltBlue" type={iconTypes.TRIANGLE_DOWN} />
                        </DropdownMenuButtonContainer>
                    }
                    dropup={true}
                    width={190}
                    marginLeft={10}
                    testId={testIds.CPS_ACTION_BAR_MORE_MENU}
                >
                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.EDIT_STAFF_REMARKS}>
                        <DropdownMenuOption
                            onClick={onAddStaffRemarks}
                            optionTestId={testIds.CPS_ACTION_BAR_ADD_REMARKS}
                        >
                            {strings.addStaffRemarks}
                        </DropdownMenuOption>
                    </OnlyWithAbility>

                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.CREATE_CHAIN_EVENTS}>
                        <DropdownMenuOption
                            onClick={onSplitItemClick}
                            disabled={!canItemSplit}
                            disabledTooltipText={itemSplitTooltip}
                            optionTestId={testIds.CPS_ACTION_BAR_SPLIT_ITEM}
                        >
                            {strings.splitItem}
                        </DropdownMenuOption>
                    </OnlyWithAbility>
                    <OnlyWithAbility has={abilitiesEnum.EVIDENCE.RESET_RETENTION_POLICY}>
                        <DispositionDropdownMenuOption
                            actionType={ACTION_TYPES.RESET_RETENTION_POLICY}
                            disabled={
                                disableResetRetentionPolicy ||
                                (useEvdPerms && hasNoLocationPermission)
                            }
                            disabledTooltipText={
                                disableResetRetentionPolicy
                                    ? strings.disabledResetRetentionPolicyTooltip
                                    : strings.noResetRetentionPolicy
                            }
                            masterItemIds={masterItemIds}
                            onClick={onResetRetentionButtonClick}
                            optionTestId={testIds.CPS_ACTION_BAR_RESET_RETENTION_POLICY}
                        >
                            {strings.resetRetentionPolicy}
                        </DispositionDropdownMenuOption>
                    </OnlyWithAbility>
                </DropdownMenu>
            </OnlyWithAbility>
        </BlueActionBar>
    );
}

const mapStateToProps = createStructuredSelector({
    masterItemIds: selectedMasterItemIdsSelector,
    selectedItemProfileIds: selectedItemProfileIdsSelector,
    chainOfCustodies: chainOfCustodiesSelector,
    currentReportId: currentReportIdSelector,
    itemEvidenceStateByChainOfCustodyId: itemEvidenceStateByChainOfCustodyIdSelector,
    chainEventViewModelsForChainOfCustodyId: chainEventViewModelsForChainOfCustodyIdSelector,
    inPoliceCustodyChainEventType: inPoliceCustodyChainEventTypeSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    dispositionEventsByMasterItemIds: dispositionEventsByMasterItemIdsSelector,
    evidenceLocationPermissionsWhere: evidenceLocationPermissionsWhereSelector,
    userRoles: userRolesSelector,
    itemProfileViewModelById: itemTypeSpecificViewModelByIdSelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = {
    openLabelPrintingModal,
    openDocumentExportingModal,
    addToItemQueue,
    requestDispositions,
    openItemSplitPopoverForm,
    openStaffRemarkSidePanel,
    fillResetRetentionForm,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        [
            'masterItemIds',
            'chainOfCustodies',
            'currentReportId',
            'itemEvidenceStateByChainOfCustodyId',
            'chainEventViewModelsForChainOfCustodyId',
            'inPoliceCustodyChainEventType',
            'currentUserHasAbility',
            'dispositionEventsByMasterItemIds',
        ],
        ({
            masterItemIds,
            chainOfCustodies,
            currentReportId,
            itemEvidenceStateByChainOfCustodyId,
            chainEventViewModelsForChainOfCustodyId,
            inPoliceCustodyChainEventType,
            currentUserHasAbility,
            dispositionEventsByMasterItemIds,
        }) => {
            const selectedDispositionEvents = dispositionEventsByMasterItemIds(masterItemIds);

            const chainofCustodyIds = map(masterItemIds, (masterItemId) => {
                return chain(chainOfCustodies)
                    .find({ masterItemId, reportId: currentReportId })
                    .get('id')
                    .value();
            });

            const latestChainEventViewModels = map(chainofCustodyIds, (chainofCustodyId) =>
                first(chainEventViewModelsForChainOfCustodyId(chainofCustodyId))
            );
            const someItemIsInPoliceCustody = !!inPoliceCustodyChainEventType
                ? some(latestChainEventViewModels, {
                      eventTypeId: inPoliceCustodyChainEventType.id,
                  })
                : false;

            const itemEvidenceStates = map(chainofCustodyIds, itemEvidenceStateByChainOfCustodyId);
            // these two 'is reviewer' properties must be identical on every itemEvidenceState and
            // we can check either `some` or `every`, see EvidenceUserActionsData.java
            const isPrimaryReviewer = some(itemEvidenceStates, 'isPrimaryReviewer');
            const isSecondaryReviewer = some(itemEvidenceStates, 'isSecondaryReviewer');

            const itemSplitOneItem = masterItemIds.length === 1;
            const itemSplitValidEvidenceState = chain(chainofCustodyIds)
                .first()
                .thru(itemEvidenceStateByChainOfCustodyId)
                .get('canSplit')
                .value();
            // too many tooltip should display if both too many and invalid
            const itemSplitTooltip = itemSplitOneItem
                ? strings.disabledItemSplitInvalidTooltip
                : strings.disabledItemSplitTooManyTooltip;

            const latestChainEventByMasterItemId = keyBy(
                latestChainEventViewModels,
                (chainEvent) => chainOfCustodies[chainEvent.chainOfCustodyId].masterItemId
            );

            return {
                someItemIsInPoliceCustody,
                canPrintLabels: !someItemIsInPoliceCustody,
                canItemSplit: itemSplitOneItem && itemSplitValidEvidenceState,
                itemSplitTooltip,
                canManualRequestDisposition: every(
                    itemEvidenceStates,
                    'canManualRequestDisposition'
                ),
                isPrimaryReviewer,
                isSecondaryReviewer,
                canUpdateDisposition:
                    currentUserHasAbility(abilitiesEnum.EVIDENCE.REQUEST_DISPOSITION) ||
                    isPrimaryReviewer ||
                    isSecondaryReviewer,
                canPrimaryReview: every(itemEvidenceStates, 'canPrimaryReview'),
                canSecondaryReviewForRelease: every(
                    itemEvidenceStates,
                    'canSecondaryReviewForRelease'
                ),
                canSecondaryReviewForDisposition: every(
                    itemEvidenceStates,
                    'canSecondaryReviewForDisposition'
                ),
                disableResetRetentionPolicy: some(selectedDispositionEvents, (dispositionEvent) => {
                    const { dispositionState = {} } = dispositionEvent;

                    return includes(
                        invalidResetRetentionPolicyDispositionStatuses,
                        dispositionState.dispositionStatus
                    );
                }),
                latestChainEventByMasterItemId,
            };
        }
    ),
    withHandlers({
        onPrintLabelsClick({ openLabelPrintingModal, masterItemIds }) {
            return () => openLabelPrintingModal({ masterItemIds });
        },
        onDocumentExportingClick({
            openDocumentExportingModal,
            masterItemIds,
            selectedItemProfileIds,
        }) {
            return () =>
                openDocumentExportingModal({
                    masterItemIds,
                    itemProfileIds: selectedItemProfileIds,
                });
        },
        onAddStaffRemarks({ openStaffRemarkSidePanel, selectedItemProfileIds }) {
            return () => openStaffRemarkSidePanel(ITEM_PROFILE.name, selectedItemProfileIds);
        },
        onAddToItemQueueClick({ addToItemQueue, masterItemIds }) {
            return () => addToItemQueue(masterItemIds);
        },
        onSplitItemClick({ openItemSplitPopoverForm, selectedItemProfileIds }) {
            // split contexted item profile
            return () => openItemSplitPopoverForm(first(selectedItemProfileIds));
        },
        onResetRetentionButtonClick({ masterItemIds, fillResetRetentionForm }) {
            return () => {
                fillResetRetentionForm(masterItemIds);
            };
        },
    })
)(CustodialPropertyActionBar);

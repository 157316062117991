let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'DOJ Schools Resource',
        methods: {
            searchForDojSchools({ q, from, size }) {
                return req({
                    method: 'GET',
                    baseUrl: '/rms/api',
                    url: 'doj_schools',
                    params: {
                        q,
                        from,
                        size,
                    },
                });
            },
            exportDOJSchools() {
                return req({
                    method: 'GET',
                    baseUrl: '/rms/api',
                    url: 'doj_schools/export',
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

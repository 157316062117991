import React from 'react';
import { first, map } from 'lodash';
import styled from 'styled-components';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    formatFullName,
    isUnknown,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ExpandedPersonGangTrackingDisplay } from '~/client-common/core/domain/person-gang-trackings/components/GangTrackingDisplay';
import { FormattedDate } from '~/client-common/core/dates/components';

import Pill from '../../../core/components/Pill';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';
import { PersonStubIcon } from '../../../core/components/ProfileImage';
import { PersonLabels as _PersonLabels } from '../../../core/components/tags/PersonLabels';
import testIds from '../../../../core/testIds';
import { PersonEnhancedCautions } from '../../../core/cautions/components/PersonEnhancedCautions';

const strings = componentStrings.reports.gangTracking.GangTrackingSidePanel;

const NoResultsContainer = styled.div`
    text-align: center;
`;

const PersonLabels = styled(_PersonLabels)`
    margin-top: 6px;
`;

const NoResults = styled.div`
    border-color: ${(props) => props.theme.colors.mediumLightGrey};
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    padding: 6px 16px;
    text-align: center;
    margin-top: 22px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    display: inline-block;
    justify-content: space-between;
`;

const FlexContainer = styled.div`
    display: flex;
`;

const OpenIcon = styled(Icon)`
    align-self: center;
`;
const StyledPersonStubIcon = styled(PersonStubIcon)`
    margin-right: 8px;
`;
const CriteriaContainer = styled.div`
    font-style: italic;
`;

const GangTrackingPersons = ({ onClick, personProfilesWithCriteria }) => {
    if (personProfilesWithCriteria.length === 0) {
        return (
            <NoResultsContainer>
                <NoResults>{strings.noResults}</NoResults>
            </NoResultsContainer>
        );
    }
    return (
        <div>
            {map(
                personProfilesWithCriteria,
                ({ personProfile, gangCriteria, personGangTracking }) => {
                    const { id, title, firstName, middleName, lastName, suffix, masterPersonId } =
                        personProfile;
                    return (
                        <Pill
                            testId={testIds.GANG_TRACKING_SIDE_PANEL_ENTITY}
                            marginBottom="10px"
                            key={id}
                            onClick={() =>
                                onClick({
                                    personProfile,
                                    gangCriteria,
                                    personGangTracking,
                                })
                            }
                        >
                            <FlexContainer>
                                <StyledPersonStubIcon isUnknown={isUnknown(personProfile)} />
                                <div>
                                    <div>
                                        {formatFullName({
                                            title,
                                            firstName,
                                            middleName,
                                            lastName,
                                            suffix,
                                            masterPersonId,
                                        })}
                                    </div>
                                    <CriteriaContainer>
                                        {gangCriteria.length} Criteria added by{' '}
                                        <ConnectedFormattedUser
                                            userId={first(gangCriteria).createdBy}
                                            format={FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
                                        />{' '}
                                        on{' '}
                                        <FormattedDate
                                            date={first(gangCriteria).createdDateUtc}
                                            format={FormattedDate.FORMATS.SUMMARY_DATE}
                                        />
                                    </CriteriaContainer>
                                    {personGangTracking && (
                                        <div>
                                            <FeatureFlagged
                                                flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
                                                fallback={
                                                    <PersonLabels
                                                        isSuspectedGangMember={true}
                                                        size="sm"
                                                    />
                                                }
                                            >
                                                <PersonEnhancedCautions
                                                    isSuspectedGangMember
                                                    size="sm"
                                                />
                                            </FeatureFlagged>
                                            <ExpandedPersonGangTrackingDisplay
                                                personGangTracking={personGangTracking}
                                            />
                                        </div>
                                    )}
                                </div>
                            </FlexContainer>
                            <OpenIcon
                                type={iconTypes.OPEN_RIGHT}
                                color="cobaltBlue"
                                size={iconSizes.LARGE}
                            />
                        </Pill>
                    );
                }
            )}
        </div>
    );
};

export default GangTrackingPersons;

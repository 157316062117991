import { SystemRuleEnum } from '@mark43/rms-api';
import _ from 'lodash';
import addRuleId from '../helpers/addRuleId';
import { setKoModelValidation } from '../helpers/koValidationHelper';

function validateAttributeWidgetOther(errorMsg) {
    return (widget) => {
        let allValid = true;
        if (widget) {
            _.forEach(widget.otherHidden, (isHidden, parentAttrId) => {
                if (!isHidden()) {
                    const otherModel = widget.otherModels[parentAttrId];
                    const isValid = !!otherModel.other();
                    setKoModelValidation(otherModel.other, isValid, errorMsg);
                    if (!isValid) {
                        allValid = false;
                    }
                }
            });
        }
        return allValid;
    };
}
export const offenseAttributesOtherRequired = addRuleId(
    SystemRuleEnum.OFFENSE_ATTRIBUTES_OTHER_REQUIRED.name,
    (data, errorMsg) => {
        const attributeWidgets = [
            data.hateBias,
            data.aggravatedAssault,
            data.homicideFactors,
            data.weaponForceInvolved,
            data.gangInfo,
            data.animalCruelty,
            data.criminalActivities,
            data.modusOperandi,
        ];
        return _.every(attributeWidgets, validateAttributeWidgetOther(errorMsg));
    }
);

import React from 'react';
import { useSelector } from 'react-redux';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { CustomLink } from '../../../../core/components/links/Link';
import { onClickToOnEnter } from '../../../../core/utils/eventHelpers';
import RoutingLabelsModal, {
    useOpenRoutingLabelsModal,
} from '../../../../records/core/components/RoutingLabelsModal';
import { canEditLabelsForReportIdSelector } from '../../../../records/core/state/ui';

const strings = componentStrings.reports.core.ReportStatusCommentsCard;

const EditRecordLabels: React.FC<{ reportId: number }> = (props) => {
    const { reportId } = props;
    const canEditLabelsForReportId = useSelector(canEditLabelsForReportIdSelector);
    const openRoutingLabelsModal = useOpenRoutingLabelsModal({
        overlayId: overlayIdEnum.ROUTING_LABELS_FOR_REPORT_STATUS_COMMENTS_CARD_MODAL,
        reportId,
    });

    if (!canEditLabelsForReportId(reportId)) {
        return null;
    }

    return (
        <RoutingLabelsModal
            overlayId={overlayIdEnum.ROUTING_LABELS_FOR_REPORT_STATUS_COMMENTS_CARD_MODAL}
            buttonElement={
                <p>
                    <CustomLink
                        onClick={openRoutingLabelsModal}
                        tabIndex={0}
                        onKeyDown={onClickToOnEnter(openRoutingLabelsModal)}
                    >
                        {strings.core.addLabelsLink}
                    </CustomLink>
                    {strings.core.addLabelsMessage}
                </p>
            }
        />
    );
};

export default EditRecordLabels;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Disposition Events Resource',
        methods: {
            /**
             * Request disposition for multiple items.
             * @return {Promise<Object[]>} Array of objects containing
             *   `dispositionEvent` and `itemEvidenceState`.
             */
            requestDispositions(dispositionEvents) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: 'evidence/dispositions/request/bulk',
                    data: dispositionEvents,
                });
            },

            /**
             * Review disposition for a single item.
             * @return {Promise<Object>} `dispositionEvent` and
             *   `itemEvidenceState`.
             */
            reviewDisposition(dispositionEvent) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: 'evidence/dispositions/review',
                    data: dispositionEvent,
                });
            },

            reviewDispositions(dispositionEvents) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: 'evidence/dispositions/review/bulk',
                    data: dispositionEvents,
                });
            },

            /**
             * Manually create disposition events. Use this resource method for
             *   Mark43 internal testing only; a real user won't have the
             *   ability to use this as part of any disposition workflow.
             * @return {Promise<Object[]>} Array of `dispositionEvent`s.
             */
            createDispositionEvents(dispositionEvents) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: 'evidence/dispositions/events/bulk',
                    data: dispositionEvents,
                });
            },
            resetDispositions(dispositionEvents) {
                return req({
                    baseUrl: BASE_URL,
                    method: 'POST',
                    url: 'evidence/dispositions/reset/bulk',
                    data: dispositionEvents,
                });
            },
        },
    });
}

/**
 * Resources for disposition events.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedRouter, withRouter } from 'react-router';
import { map, isEmpty } from 'lodash';
import { FolderView, EntityTypeEnumType } from '@mark43/rms-api';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Folder, foldersByOwnerIdSelector } from '~/client-common/core/domain/folders/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { getDirectSubFolders } from '../state/data/folders';
import { getCaseFolderPath } from '../utils/getCaseFolderPath';
import { Tooltip } from '../../../core/components/tooltip';
import testIds from '../../../../core/testIds';

import { createCaseSidebarFolderDroppableId } from '../utils/dragAndDropHelpers';
import { SearchFilterContext, DraggingIdContext, SelectedRowsContext } from '../contexts';
import { folderTreeExpansionSelector, toggleFolderExpansion, collapseFolders } from '../state/ui';
import CaseSidebarLink from './CaseSidebarLink';
import FolderNameWrapper from './FolderNameWrapper';
import KebabMenu from './KebabMenu';
import FolderOverlayButton from './FolderOverlayButton';
import SidebarControlsWrap from './SidebarControlsWrap';

const SubFolderFolderNameWrapper = styled(FolderNameWrapper)`
    height: 60px;
`;

const NameWrap = styled.div`
    display: flex;
    overflow: hidden;
    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

type FoldersTreeProps = {
    folderView: Folder | FolderView;
    topLevelFolderId: number;
    ownerId: number;
    entityType: EntityTypeEnumType;
    router: InjectedRouter;
    canEditCaseFolders: boolean;
    parentFolderId?: number;
};

const PATH_DELIMITER = '/';

const DroppablePlaceHolderWrapper = styled.div`
    display: none;
`;

const FoldersTree: React.FC<FoldersTreeProps> = ({
    folderView,
    topLevelFolderId,
    ownerId,
    entityType,
    router,
    canEditCaseFolders,
    parentFolderId,
}) => {
    const [selectedFolderId, setSelectedFolderId] = useState<number>();
    const { setSelectedRows } = useContext(SelectedRowsContext);
    const { draggingId } = useContext(DraggingIdContext);
    const foldersExpandedMap = useSelector(folderTreeExpansionSelector);
    const dispatch = useDispatch();
    const { folder, subFolderViews } = folderView;

    const { setFilter, setCurrentPage } = useContext(SearchFilterContext);

    useEffect(() => {
        if (subFolderViews.length === 0) {
            dispatch(collapseFolders(entityType, true, [folder?.id]));
        }
    }, [dispatch, entityType, folder, subFolderViews]);

    const handleClick = useCallback(
        (folderId, topLevelFolderId) => {
            dispatch(getDirectSubFolders(folderId, topLevelFolderId));
            dispatch(toggleFolderExpansion(entityType, folderId));
        },
        [dispatch, entityType]
    );

    const handleCaseLinkOnClick = (folderId: number) => {
        if (folderId !== selectedFolderId) {
            setSelectedFolderId(folderId);
            if (setSelectedRows) {
                setSelectedRows([]);
            }
        }
    };

    if (!folder) {
        return null;
    }

    const { name, path, id: folderId } = folder;

    const folderDepth = path.split(PATH_DELIMITER).length;
    const folderPath = getCaseFolderPath({ caseId: ownerId, entityType, folderId });

    return (
        <>
            <Droppable droppableId={createCaseSidebarFolderDroppableId(folderId, name)}>
                {(provided, dropSnapshot) => {
                    return (
                        <SubFolderFolderNameWrapper
                            isPathActive={router.isActive(folderPath)}
                            folderDepth={folderDepth}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={dropSnapshot.isDraggingOver}
                            draggingId={draggingId}
                            data-test-id={testIds.FOLDER_TREE_WRAPPER}
                            data-test-display={name}
                        >
                            <div
                                onClick={() => handleClick(folderId, topLevelFolderId)}
                                data-test-id={testIds.FOLDER_TREE_EXPAND}
                            >
                                <Icon
                                    color="cobaltBlue"
                                    size={12}
                                    type={
                                        foldersExpandedMap[entityType].folders[folderId]
                                            ? iconTypes.OPEN
                                            : iconTypes.OPEN_RIGHT
                                    }
                                />
                            </div>
                            <CaseSidebarLink
                                to={folderPath}
                                disabled={router.isActive(folderPath)}
                                onClick={() => {
                                    if (setFilter) {
                                        setFilter('');
                                    }
                                    if (setCurrentPage) {
                                        setCurrentPage(1);
                                    }
                                    handleCaseLinkOnClick(folderId);
                                }}
                                testId={testIds.FOLDER_TREE_LINK}
                            >
                                <Icon color="cobaltBlue" size={18} type={iconTypes.CASE} />
                                <NameWrap>
                                    <Tooltip
                                        content={<span>{name}</span>}
                                        hasButtonOffset
                                        side="top"
                                    >
                                        <span>{name}</span>
                                    </Tooltip>
                                </NameWrap>
                            </CaseSidebarLink>
                            {!draggingId && canEditCaseFolders && (
                                <SidebarControlsWrap>
                                    <KebabMenu
                                        sidebarLook={true}
                                        folderId={folder.id}
                                        entityTypeId={folder.entityTypeId}
                                        canRename={folder.isEditable}
                                        canDelete={folder.isEditable}
                                        parentFolderId={parentFolderId}
                                    />
                                    <FolderOverlayButton
                                        overlayId={overlayIdEnum.CREATE_FOLDER_MODAL}
                                        folderId={folder.id}
                                        entityTypeId={folder.entityTypeId}
                                        testId={testIds.FOLDER_TREE_CREATE}
                                    >
                                        <Icon color="cobaltBlue" size={16} type={iconTypes.ADD} />
                                    </FolderOverlayButton>
                                </SidebarControlsWrap>
                            )}
                            <DroppablePlaceHolderWrapper>
                                {provided.placeholder}
                            </DroppablePlaceHolderWrapper>
                        </SubFolderFolderNameWrapper>
                    );
                }}
            </Droppable>
            {foldersExpandedMap[entityType].folders[folderId] &&
                !isEmpty(subFolderViews) &&
                map(subFolderViews, (folderView, index) => (
                    <FoldersTree
                        ownerId={ownerId}
                        key={index}
                        folderView={folderView}
                        topLevelFolderId={topLevelFolderId}
                        entityType={entityType}
                        router={router}
                        canEditCaseFolders={canEditCaseFolders}
                        parentFolderId={folderId}
                    />
                ))}
        </>
    );
};

type FolderTreeContainerProps = {
    ownerId: number;
    entityType: EntityTypeEnumType;
    router: InjectedRouter;
    canEditCaseFolders: boolean;
    folderTreeExpansion: Record<number | string, boolean>;
};

const FolderTreeContainer: React.FC<FolderTreeContainerProps> = ({
    ownerId,
    entityType,
    router,
    canEditCaseFolders,
}) => {
    const foldersByOwnerId = useSelector(foldersByOwnerIdSelector);
    const folders = foldersByOwnerId(ownerId, entityType) || [];
    const sortedFolders = sortByNaturalOrder(
        folders,
        [(folderView) => folderView.folder?.name],
        ['asc']
    );

    return (
        <>
            {map(sortedFolders, (folder, index) => (
                <FoldersTree
                    ownerId={ownerId}
                    key={index}
                    folderView={folder}
                    topLevelFolderId={folder.id}
                    entityType={entityType}
                    router={router}
                    canEditCaseFolders={canEditCaseFolders}
                />
            ))}
        </>
    );
};

export default withRouter(FolderTreeContainer);

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import renSearchActionTypes from '../actions/types/renSearchModalActionTypes';
import boxActionTypes from '../actions/types/boxActionTypes';

const {
    GET_INFO_FOR_REPORTING_EVENT_NUMBER_START,
    GET_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS,
    GET_INFO_FOR_REPORTING_EVENT_NUMBER_FAILURE,
    DISABLE_REPORTING_EVENT_NUMBER_SEARCH_FORM,
} = renSearchActionTypes;

const { OPEN_BOX } = boxActionTypes;

const initialUiState = {
    reportId: null,
    acceptableReports: [],
    reportShortTitles: [],
    enteredReportingEventNumber: null,
    enteredCadAgencyEventNumber: null,
    hasFinishedSearch: false,
    reportingEventNumberSearchFormDisabled: false,
    isLoading: false,
};

export function renSearchModalUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case OPEN_BOX:
            if (
                action.meta.name === boxEnum.CREATE_REPORT_MODAL ||
                action.meta.name === boxEnum.CREATE_WARRANT_MODAL
            ) {
                return initialUiState;
            }
            return state;
        case GET_INFO_FOR_REPORTING_EVENT_NUMBER_START:
            return {
                ...state,
                hasFinishedSearch: false,
                enteredReportingEventNumber: action.payload,
                isLoading: true,
            };
        case GET_INFO_FOR_REPORTING_EVENT_NUMBER_SUCCESS:
            return {
                ...state,
                hasFinishedSearch: true,
                isLoading: false,
                enteredReportingEventNumber: action.payload.reportingEventNumber,
                enteredCadAgencyEventNumber: action.payload.cadAgencyEventNumber,
                ...action.payload, // includes acceptable + existing
            };
        case GET_INFO_FOR_REPORTING_EVENT_NUMBER_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case DISABLE_REPORTING_EVENT_NUMBER_SEARCH_FORM:
            return {
                ...state,
                isLoading: false,
                reportingEventNumberSearchFormDisabled: true,
            };
        default:
            return state;
    }
}

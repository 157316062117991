import { filter, identity, map, reject, size } from 'lodash';

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredInt } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const itemPopoverFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'itemTypeAttrId',
        validators: {
            requiredError: requiredInt,
        },
    },
    {
        key: 'policeCustodyPropertyStatusAttrIds',
        type: fieldTypeClientEnum.ATTRIBUTE,
    },
    {
        key: 'nonPoliceCustodyPropertyStatusAttrIds',
        type: fieldTypeClientEnum.ATTRIBUTE,
    },
]);

function convertToFormModel(
    itemProfile = {},
    propertyStatuses = [],
    {
        visibleItemTypeAttrIds = [],
        propertyStatusAttrIdIsInPoliceCustody = identity,
        requirePoliceCustodyPropertyStatus,
    }
) {
    const propertyStatusAttrIds = map(propertyStatuses, 'propertyStatusAttrId');

    return {
        itemTypeAttrId:
            itemProfile.itemTypeAttrId ||
            // if only one item type is visible in the form, automatically
            // select it
            (visibleItemTypeAttrIds.length === 1 ? visibleItemTypeAttrIds[0] : undefined),
        policeCustodyPropertyStatusAttrIds: filter(
            propertyStatusAttrIds,
            propertyStatusAttrIdIsInPoliceCustody
        ),
        nonPoliceCustodyPropertyStatusAttrIds: reject(
            propertyStatusAttrIds,
            propertyStatusAttrIdIsInPoliceCustody
        ),
        requirePoliceCustodyPropertyStatus,
    };
}

const itemPopoverForm = createFormModule({
    formName: formClientEnum.ITEM_POPOVER,
    fieldViewModels: itemPopoverFormFieldViewModels,
    formValidators: {
        propertyStatus: ({
            policeCustodyPropertyStatusAttrIds,
            nonPoliceCustodyPropertyStatusAttrIds,
            requirePoliceCustodyPropertyStatus,
        }) => {
            const policeCustodySelected = size(policeCustodyPropertyStatusAttrIds) > 0;
            const otherSelected = size(nonPoliceCustodyPropertyStatusAttrIds) > 0;

            if (requirePoliceCustodyPropertyStatus) {
                return policeCustodySelected;
            } else {
                return policeCustodySelected || otherSelected;
            }
        },
    },
    convertToFormModel,
});

/**
 * Module of the item popover form.
 * @type {Object}
 */
export default itemPopoverForm;

import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    TOW_VEHICLE_RELEASE_RELEASE_DATE_UTC,
    TOW_VEHICLE_RELEASE_RELEASE_TYPE_ATTR_ID,
    TOW_VEHICLE_RELEASE_RELEASE_BY,
    TOW_VEHICLE_RELEASE_ADDITIONAL_NOTES,
} from '~/client-common/core/enums/universal/fields';
import { towVehicleReleaseViewModelByReportIdSelector } from '~/client-common/core/domain/tow-vehicle-releases/state/ui';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import testIds from '../../../../../core/testIds';

const mapStateToProps = createStructuredSelector({
    towVehicleReleaseViewModelByReportId: towVehicleReleaseViewModelByReportIdSelector,
});

const TowVehicleReleaseCardSummary = connect(mapStateToProps)(
    function _TowVehicleReleaseCardSummary({ reportId, towVehicleReleaseViewModelByReportId }) {
        const towVehicleReleaseViewModel = towVehicleReleaseViewModelByReportId(reportId) || {};
        const { releaseTypeDisplay, releaseBy } = getViewModelProperties(
            towVehicleReleaseViewModel
        );

        return (
            <SummaryList labelWidth={160} contentWidth={380}>
                <CardSection testId={testIds.CARD_SUMMARY}>
                    <SummaryRowDate
                        fieldName={TOW_VEHICLE_RELEASE_RELEASE_DATE_UTC}
                        date={towVehicleReleaseViewModel.releaseDateUtc}
                    />
                    <SummaryRow fieldName={TOW_VEHICLE_RELEASE_RELEASE_TYPE_ATTR_ID}>
                        {releaseTypeDisplay}
                    </SummaryRow>
                    <SummaryRow fieldName={TOW_VEHICLE_RELEASE_RELEASE_BY}>
                        {get(getViewModelProperties(releaseBy).display, 'fullName')}
                    </SummaryRow>
                    <SummaryRow fieldName={TOW_VEHICLE_RELEASE_ADDITIONAL_NOTES}>
                        {towVehicleReleaseViewModel.additionalNotes}
                    </SummaryRow>
                </CardSection>
            </SummaryList>
        );
    }
);

export default TowVehicleReleaseCardSummary;

import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import ResultsTableColumn, {
    ResultsSortableTableColumn,
} from '../../search-results/components/ResultsTableColumn';

import { ITEM_ID_COLUMN_KEY, ITEM_STATUS_COLUMN_KEY } from '../state/ui';

const strings = componentStrings.evidence.inventories.SearchResultsTableHeader.columns;

const SearchResultsTableHeader = ({ handleSortKeyClick, querySortType }) => (
    <TableColumns>
        <ResultsSortableTableColumn
            columnKey="itemId"
            display={strings.itemId.label}
            onClick={handleSortKeyClick}
            sortKey={ITEM_ID_COLUMN_KEY}
            sortOptions={[sortTypeEnum.ALPHABETICAL_A_TO_Z, sortTypeEnum.ALPHABETICAL_Z_TO_A]}
            sortType={querySortType}
            width={120}
        />

        <ResultsSortableTableColumn
            columnKey="status"
            display={strings.status.label}
            onClick={handleSortKeyClick}
            sortKey={ITEM_STATUS_COLUMN_KEY}
            sortOptions={[sortTypeEnum.ALPHABETICAL_A_TO_Z, sortTypeEnum.ALPHABETICAL_Z_TO_A]}
            sortType={querySortType}
            width={120}
        />

        <ResultsTableColumn
            columnKey="itemIdentifier"
            display={strings.itemIdentifier.label}
            width={337}
        />

        <ResultsTableColumn
            columnKey="storageLocation"
            display={strings.storageLocation.label}
            width={337}
        />
    </TableColumns>
);

SearchResultsTableHeader.propTypes = {
    formatFieldByName: PropTypes.func.isRequired,
    handleSortTypeClick: PropTypes.func.isRequired,
    querySortKey: PropTypes.string.isRequired,
    querySortType: PropTypes.string.isRequired,
};

SearchResultsTableHeader.defaultProps = {
    formatFieldByName: noop,
    handleSortKeyClick: noop,
    querySortType: '',
};

export default SearchResultsTableHeader;

import { AttributeTypeEnum, RefContextEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { concat, filter, findIndex, get, map } from 'lodash';

import { createFormConfiguration, createNItems, createFieldset, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';

import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { hydratedBehavioralCrisisByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/behavioralCrises';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import sortNameReportLinks from '~/client-common/core/domain/name-report-links/utils/sortNameReportLinks';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_BEHAVIORAL_CRISIS.name;
export const BEHAVIORAL_CRISIS_SUBJECT_PATH = 'behavioralCrisisSubject';
export const BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH =
    'otherNamesInBehavioralCrisis';
export const BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID_PATH =
    'subjectTypeAttrId';
export const BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_OTHER_PATH =
    'subjectTypeOther';
export const BEHAVIORAL_CRISIS_LOCATION_PATH = 'behavioralCrisisLocation';
const BEHAVIORAL_CRISIS_LOCATION_DESCRIPTION_PATH = 'description';
const BEHAVIORAL_CRISIS_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const BEHAVIORAL_CRISIS_LOCATION_DESCRIPTION_FULL_PATH = `${BEHAVIORAL_CRISIS_LOCATION_PATH}.${BEHAVIORAL_CRISIS_LOCATION_DESCRIPTION_PATH}`;
export const BEHAVIORAL_CRISIS_LOCATION_POSITION_ATTR_ID_FULL_PATH = `${BEHAVIORAL_CRISIS_LOCATION_PATH}.${BEHAVIORAL_CRISIS_LOCATION_POSITION_ATTR_ID_PATH}`;

export const getBehavioralCrisisForm = () => formsRegistry.get(formName);

export const createBehavioralCrisisForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            crisisContactStartDateUtc: {
                fieldName: fields.BEHAVIORAL_CRISIS_CRISIS_CONTACT_START_DATE_UTC,
            },
            crisisContactEndDateUtc: {
                fieldName: fields.BEHAVIORAL_CRISIS_CRISIS_CONTACT_END_DATE_UTC,
            },
            wasCitOfficerRequested: {
                fieldName: fields.BEHAVIORAL_CRISIS_WAS_CIT_OFFICER_REQUESTED,
            },
            wasCitOfficerDispatched: {
                fieldName: fields.BEHAVIORAL_CRISIS_WAS_CIT_OFFICER_DISPATCHED,
            },
            didCitOfficerArrive: {
                fieldName: fields.BEHAVIORAL_CRISIS_DID_CIT_OFFICER_ARRIVE,
            },
            exhibitingBehaviorAttrIds: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EXHIBITING_BEHAVIOR_ATTRIBUTE_ID,
            },
            exhibitingBehaviorDescription: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EXHIBITING_BEHAVIOR_DESCRIPTION,
            },
            weaponInvolvedAttrIds: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_INVOLVED_ATTRIBUTE_ID,
            },
            weaponInvolvedDescription: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_INVOLVED_DESCRIPTION,
            },
            behavioralCrisisTechniquesUsedAttrIds: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CRISIS_TECHNIQUES_USED_ATTRIBUTE_ID,
            },
            behavioralCrisisTechniquesUsedDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CRISIS_TECHNIQUES_USED_DESCRIPTION,
            },
            behavioralCrisisDispositionAttrIds: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CRISIS_DISPOSITION_ATTRIBUTE_ID,
            },
            behavioralCrisisDispositionDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CRISIS_DISPOSITION_DESCRIPTION,
            },
            [BEHAVIORAL_CRISIS_SUBJECT_PATH]: {
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_BEHAVIORAL_CRISIS_LINK_TYPE,
            },
            [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH]: createNItems({
                fields: {
                    nameId: {},
                    [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID,
                    },
                    [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_OTHER_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_OTHER,
                    },
                },
            }),
            [BEHAVIORAL_CRISIS_LOCATION_PATH]: createFieldset({
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_BEHAVIORAL_CRISIS_LOCATION_ID,
                    },
                    [BEHAVIORAL_CRISIS_LOCATION_POSITION_ATTR_ID_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_BEHAVIORAL_CRISIS_POSITION_ATTR_ID,
                    },
                    [BEHAVIORAL_CRISIS_LOCATION_DESCRIPTION_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_BEHAVIORAL_CRISIS_DESCRIPTION,
                    },
                },
            }),
        }),
    });
};

const buildReportAttributeFormModelObject = ({ reportAttributes, attributeType }) => {
    const filteredReportAttributes = filter(reportAttributes, { attributeType });
    const attributeIds = map(filteredReportAttributes, 'attributeId');
    const description = getDescriptionForAttributeLinks(filteredReportAttributes);
    return {
        attributeIds,
        description,
    };
};

export const buildBehavioralCrisisCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const hydratedBehavioralCrisis = hydratedBehavioralCrisisByReportIdSelector(state)(reportId);
    const {
        behavioralCrisis: {
            id: behavioralCrisisId,
            crisisContactStartDateUtc,
            crisisContactEndDateUtc,
            wasCitOfficerRequested,
            wasCitOfficerDispatched,
            didCitOfficerArrive,
        },
        reportAttributes,
        behavioralCrisisSubject: behavioralCrisisSubjectNameReportLink,
        otherNamesInBehavioralCrisis,
        behavioralCrisisLocation,
    } = hydratedBehavioralCrisis;

    const {
        attributeIds: exhibitingBehaviorAttrIds,
        description: exhibitingBehaviorDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.EXHIBITING_BEHAVIOR.name,
    });
    const {
        attributeIds: weaponInvolvedAttrIds,
        description: weaponInvolvedDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.WEAPON_INVOLVED.name,
    });
    const {
        attributeIds: behavioralCrisisTechniquesUsedAttrIds,
        description: behavioralCrisisTechniquesUsedDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.BEHAVIORAL_CRISIS_TECHNIQUES_USED.name,
    });
    const {
        attributeIds: behavioralCrisisDispositionAttrIds,
        description: behavioralCrisisDispositionDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.BEHAVIORAL_CRISIS_DISPOSITION.name,
    });

    const behavioralCrisisSubjectLinkType = get(behavioralCrisisSubjectNameReportLink, 'linkType');
    const sortedOtherNamesInBehavioralCrisis = sortNameReportLinks({
        nameReportLinks: otherNamesInBehavioralCrisis,
    });

    return {
        id: behavioralCrisisId,
        crisisContactStartDateUtc,
        crisisContactEndDateUtc,
        wasCitOfficerRequested,
        wasCitOfficerDispatched,
        didCitOfficerArrive,
        exhibitingBehaviorAttrIds,
        exhibitingBehaviorDescription,
        weaponInvolvedAttrIds,
        weaponInvolvedDescription,
        behavioralCrisisTechniquesUsedAttrIds,
        behavioralCrisisTechniquesUsedDescription,
        behavioralCrisisDispositionAttrIds,
        behavioralCrisisDispositionDescription,
        [BEHAVIORAL_CRISIS_SUBJECT_PATH]: behavioralCrisisSubjectLinkType,
        [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH]: sortedOtherNamesInBehavioralCrisis,
        [BEHAVIORAL_CRISIS_LOCATION_PATH]: behavioralCrisisLocation,
    };
};

const convertReportAttributeFormModelToDataModel = ({
    attributes,
    reportId,
    attributeTypeName,
    attributeIds,
    description,
}) => {
    return assignDescriptionToReportAttributes(
        map(attributeIds, (attributeId) => {
            return {
                reportId,
                attributeType: attributeTypeName,
                attributeId,
            };
        }),
        attributes,
        description
    );
};

export const buildBehavioralCrisisCardBundle = ({ reportId, form }) => (dispatch, getState) => {
    const state = getState();
    const attributes = attributesSelector(state);
    const nameReportLinksWhere = nameReportLinksWhereSelector(state);
    const { model } = form.getState();
    const {
        id: behavioralCrisisId,
        crisisContactStartDateUtc,
        crisisContactEndDateUtc,
        wasCitOfficerRequested,
        wasCitOfficerDispatched,
        didCitOfficerArrive,
        exhibitingBehaviorAttrIds,
        exhibitingBehaviorDescription,
        weaponInvolvedAttrIds,
        weaponInvolvedDescription,
        behavioralCrisisTechniquesUsedAttrIds,
        behavioralCrisisTechniquesUsedDescription,
        behavioralCrisisDispositionAttrIds,
        behavioralCrisisDispositionDescription,
        [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH]: otherNamesInBehavioralCrisis,
        [BEHAVIORAL_CRISIS_LOCATION_PATH]: behavioralCrisisLocation,
    } = model;

    const behavioralCrisisSubject = nameReportLinksWhere({
        reportId,
        contextType: EntityTypeEnum.REPORT.name,
        contextId: reportId,
        linkType: LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS,
    });

    const behavioralCrisisForBundle = {
        id: behavioralCrisisId,
        reportId,
        crisisContactStartDateUtc,
        crisisContactEndDateUtc,
        wasCitOfficerRequested,
        wasCitOfficerDispatched,
        didCitOfficerArrive,
    };

    const exhibitingBehaviorReportAttributes = convertReportAttributeFormModelToDataModel({
        attributes,
        reportId,
        attributeTypeName: AttributeTypeEnum.EXHIBITING_BEHAVIOR.name,
        attributeIds: exhibitingBehaviorAttrIds,
        description: exhibitingBehaviorDescription,
    });
    const weaponInvolvedReportAttributes = convertReportAttributeFormModelToDataModel({
        attributes,
        reportId,
        attributeTypeName: AttributeTypeEnum.WEAPON_INVOLVED.name,
        attributeIds: weaponInvolvedAttrIds,
        description: weaponInvolvedDescription,
    });
    const behavioralCrisisTechniquesUsedReportAttributes = convertReportAttributeFormModelToDataModel(
        {
            attributes,
            reportId,
            attributeTypeName: AttributeTypeEnum.BEHAVIORAL_CRISIS_TECHNIQUES_USED.name,
            attributeIds: behavioralCrisisTechniquesUsedAttrIds,
            description: behavioralCrisisTechniquesUsedDescription,
        }
    );
    const behavioralCrisisDispositionReportAttributes = convertReportAttributeFormModelToDataModel({
        attributes,
        reportId,
        attributeTypeName: AttributeTypeEnum.BEHAVIORAL_CRISIS_DISPOSITION.name,
        attributeIds: behavioralCrisisDispositionAttrIds,
        description: behavioralCrisisDispositionDescription,
    });

    const reportAttributesForBundle = concat(
        [],
        exhibitingBehaviorReportAttributes,
        weaponInvolvedReportAttributes,
        behavioralCrisisTechniquesUsedReportAttributes,
        behavioralCrisisDispositionReportAttributes
    );

    // Only preserve the `LocationEntityLink` if the form has a valid
    // `LocationEntityLink`; use `locationId` to validate.
    // This is because both initializing and adding-then-removing a
    // location will cause the form model state to correctly reset with
    // `undefined` values for `locationId` & `description`, but the BE
    // is expecting a `NULL` `locationEntityLink` for processing,
    // not an "empty" one.
    const filteredBehavioralCrisisLocation = isUndefinedOrNull(
        get(behavioralCrisisLocation, 'locationId')
    )
        ? undefined
        : behavioralCrisisLocation;

    return {
        behavioralCrisis: behavioralCrisisForBundle,
        reportAttributes: reportAttributesForBundle,
        nameReportLinks: [...otherNamesInBehavioralCrisis, ...behavioralCrisisSubject],
        locationEntityLink: filteredBehavioralCrisisLocation,
    };
};

export const refreshBehavioralCrisisForm = ({ reportId }) => (dispatch) => {
    const form = getBehavioralCrisisForm();
    const formModel = dispatch(buildBehavioralCrisisCardFormModel({ reportId }));
    form.set('', formModel);
};

export const findIndexOfOtherNameInBehavioralCrisis = ({ nameId }) => {
    const form = getBehavioralCrisisForm();
    const {
        model: {
            [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH]: otherNamesInBehavioralCrisis,
        },
    } = form.getState();
    return findIndex(otherNamesInBehavioralCrisis, { nameId });
};

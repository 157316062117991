import { chain } from 'lodash';
import { createSelector } from 'reselect';
import { isActive } from '../../../../dates/utils/dateHelpers';
import { userAssignmentsSelector, userAssignmentsWhereSelector } from '../data';

export const mostRecentActiveUnitForUserSelector = createSelector(
    userAssignmentsSelector,
    (userAssignments) => (userId) => {
        return chain(userAssignments)
            .filter(({ userProfileId, startDateUtc, endDateUtc, personnelUnitAttrId }) => {
                return (
                    userProfileId === userId &&
                    isActive(startDateUtc, endDateUtc) &&
                    personnelUnitAttrId
                );
            })
            .sortBy('rmsEventId')
            .last()
            .value();
    }
);

export const mostRecentUserAssignmentForUserSelector = createSelector(
    userAssignmentsWhereSelector,
    (userAssignmentsWhere) => (userProfileId) => {
        const userAssignments = userAssignmentsWhere({ userProfileId });
        return chain(userAssignments)
            .filter(
                ({ rankAttrId, startDateUtc, endDateUtc }) =>
                    rankAttrId && isActive(startDateUtc, endDateUtc)
            )
            .sortBy('rmsEventId')
            .last()
            .value();
    }
);

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { ROLE_NAME } from '~/client-common/core/enums/universal/fields';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { DEFAULT_SESSION_LENGTH_MINUTES } from './addNewRoleForm';

const editRoleFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'name',
        fieldName: ROLE_NAME,
    },
    {
        key: 'sessionTimeoutLength',
        fields: buildFlatFormFieldViewModels([{ key: 'hours' }, { key: 'mins' }]),
    },
]);

function convertFromFormModel(applicationSettings, formData) {
    let totalMins;
    if (formData.sessionTimeoutLength) {
        const parsedHours = parseInt(
            formDataIsEmpty(formData.sessionTimeoutLength.hours)
                ? 0
                : formData.sessionTimeoutLength.hours
        );
        const parsedMins = parseInt(
            formDataIsEmpty(formData.sessionTimeoutLength.mins)
                ? 0
                : formData.sessionTimeoutLength.mins
        );

        if (!isNaN(parsedHours) && !isNaN(parsedMins)) {
            totalMins = parsedHours * 60 + parsedMins;
        } else {
            totalMins = 0;
        }
    } else {
        // 0 is set as the default to ensure the value isn't overridden unless explicitly indicated by the user.
        totalMins = 0;
    }

    const d = {
        ...formData,
        sessionLengthMinutes: totalMins,
    };

    delete d.sessionTimeoutLength;

    return {
        ...d,
    };
}

function convertToFormModel(role) {
    const parsedHours = parseInt(
        (formDataIsEmpty(role.sessionLengthMinutes)
            ? DEFAULT_SESSION_LENGTH_MINUTES
            : role.sessionLengthMinutes) / 60
    );
    const parsedMins = parseInt(
        (formDataIsEmpty(role.sessionLengthMinutes)
            ? DEFAULT_SESSION_LENGTH_MINUTES
            : role.sessionLengthMinutes) % 60
    );
    return {
        ...role,
        sessionTimeoutLength: {
            hours: parsedHours,
            mins: parsedMins,
        },
    };
}

export default createFormModule({
    formName: formClientEnum.EDIT_ROLE_NAME,
    fieldViewModels: editRoleFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import { last, keys, reduce, set } from 'lodash';
import { formEvents } from 'markformythree';
import { flattenKeys } from '~/client-common/helpers/logicHelpers';

// MFT returns an object of errors, where the keys are the error type
// When we have a required error, the error will be listed
// under the 'Required' key. E.g. {
//  do: {
//      name: {
//          errors: {
//              Required: 'Error message Here'
//          }
//      }
//  }
// }
// This function will just take the error type and return
// the corresponding key in the error object
// TODO: Currently, this is only handling required errors
//
// Should also make sure that all the normal model keys are set with no error
// Very hacky implementation, but it gets the job done
// It depends on initial state being set, as this is how we are able to
// set /remove error states for the rest of the form
const typeToError = (type) =>
    ({
        required: 'Required',
    }[type] || type);

// ON the fly convert yup's 'path' convention from
// permissions[2].tests to permissions.2.tests
const convertPath = (path) => last(keys(flattenKeys(set({}, path, true))));

export function convertYupSuccessShapeToMFTSuccessShape(yupValidationResult, $form) {
    return {
        do: reduce(
            keys(flattenKeys(yupValidationResult)),
            (acc, fieldName) => {
                acc[fieldName] = {
                    errors: {},
                    valid: true,
                };
                return acc;
            },
            {}
        ),
        formErrors: [],
        success: true,
        $form,
    };
}

export function convertYupErrorShapeToMFTErrorShape(yupValidationResult, $form, eventType) {
    const { value, inner } = yupValidationResult;

    return {
        do: reduce(
            inner,
            (acc, validationError) => {
                const { path, message, type } = validationError;
                acc[convertPath(path)] = {
                    errors: {
                        [typeToError(type)]: message,
                    },
                    valid: false,
                    ...(eventType === formEvents.FORM_SUBMIT ? { touched: true } : undefined),
                };
                return acc;
            },
            convertYupSuccessShapeToMFTSuccessShape(value).do
        ),
        // TODO: This is not correct - the form errors are different messages
        // than those that appear underneath the field
        formErrors: yupValidationResult.errors,
        success: false,
        $form,
    };
}

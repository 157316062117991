import { EmploymentHistory } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'employmentHistories';

const employmentHistoriesModule = createGroupedModule<EmploymentHistory>({
    type: NEXUS_STATE_PROP,
    key: 'personProfileId',
});

// SELECTORS
export const employmentHistoriesSelector = employmentHistoriesModule.selectors.entitiesSelector;

// REDUCER
export default employmentHistoriesModule.reducerConfig;

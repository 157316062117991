import React from 'react';
import { useDispatch } from 'react-redux';
import { AnalyticsPropertyEnumType } from '../constants/analyticsEnum';
import { AnalyticsContext } from '../../core/context/AnalyticsContext';
import { trackAnalyticsStart } from '../state/ui';

export const useAnalytics = () => {
    const analyticsContext = React.useContext(AnalyticsContext);
    const dispatch = useDispatch();

    const track = React.useCallback(
        (
            analyticsPropertiesToMerge: {
                [analyticsKey in AnalyticsPropertyEnumType]?: unknown;
            }
        ) => {
            dispatch(trackAnalyticsStart({ ...analyticsContext, ...analyticsPropertiesToMerge }));
        },
        [analyticsContext, dispatch]
    );

    const filteredTrack = React.useCallback(
        (
            analyticsPropertiesToMerge: {
                [analyticsKey in AnalyticsPropertyEnumType]?: unknown;
            },
            testIdToFilterBy?: string
        ) => {
            dispatch(
                trackAnalyticsStart(
                    { ...analyticsContext, ...analyticsPropertiesToMerge },
                    testIdToFilterBy
                )
            );
        },
        [analyticsContext, dispatch]
    );

    return {
        /*
         * Use this when you want all interactions to fire
         * a tracking call
         */
        track,
        /**
         * Use this when adding tracking calls to a generic comonent,
         * such as a `Button`, but you want to have control
         * over which interactions actually fire a tracking call
         *
         * Only if an id is in this list, will it fire a tracking call:
         * client/src/scripts/core/analyticsMiddleware.js:enabledTrackingIds
         */
        filteredTrack,
    };
};

import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { prettify } from '../../../helpers/stringHelpers';
import { Field, formatFieldByNameSelector, formatFieldNames } from '../state/config';
import {
    DISPLAY_ONLY_CASE,
    DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE,
    DISPLAY_ONLY_OFFENSE,
    DISPLAY_ONLY_SUB_PREMISE_NAME_LABEL,
    DISPLAY_ONLY_SUB_PREMISE_VALUE_LABEL,
    DISPLAY_ONLY_TARGET_PROFILE,
} from '../../enums/universal/fields';

export default function useFields<F extends Field>(fieldNames: F[] | F): Record<Field, string> {
    const fields = Array.isArray(fieldNames) ? fieldNames : [fieldNames];
    const formatter = useSelector(formatFieldByNameSelector);
    return formatFieldNames(formatter, fields);
}

export const useFieldName = (fieldName: Field) => {
    const formatter = useSelector(formatFieldByNameSelector);
    const singular = prettify(formatter(fieldName));

    return {
        singular,
        plural: pluralize(singular),
    };
};

export function useEvidenceModuleName() {
    return useFields(DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE)[DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE];
}

export function useCaseFieldName() {
    const singularCaseFieldName = prettify(useFields(DISPLAY_ONLY_CASE)[DISPLAY_ONLY_CASE]);
    return {
        pluralCaseFieldName: pluralize(singularCaseFieldName),
        singularCaseFieldName,
    };
}

export function useOffenseFieldName() {
    return prettify(useFields(DISPLAY_ONLY_OFFENSE)[DISPLAY_ONLY_OFFENSE]);
}

export function useTargetProfileFieldName() {
    const singularTargetProfileFieldName = prettify(
        useFields(DISPLAY_ONLY_TARGET_PROFILE)[DISPLAY_ONLY_TARGET_PROFILE]
    );
    return {
        pluralTargetProfileFieldName: pluralize(singularTargetProfileFieldName),
        singularTargetProfileFieldName,
    };
}

export function useSubPremiseFieldLabels() {
    const {
        [DISPLAY_ONLY_SUB_PREMISE_NAME_LABEL]: subPremiseNameLabel,
        [DISPLAY_ONLY_SUB_PREMISE_VALUE_LABEL]: subPremiseValueLabel,
    } = useFields([DISPLAY_ONLY_SUB_PREMISE_NAME_LABEL, DISPLAY_ONLY_SUB_PREMISE_VALUE_LABEL]);
    return {
        subPremiseNameLabel: subPremiseNameLabel || 'Sub-Premise Name',
        subPremiseValueLabel: subPremiseValueLabel || 'Sub-Premise Value',
    };
}

import _, { get } from 'lodash';
import rangeFieldKeyEnum from '../core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '../core/enums/client/rangeFieldTypeEnum';

import { formatHeight } from '../core/domain/person-profiles/utils/personProfilesHelpers';
import {
    formatWithinLastPeriod,
    formatToDatePeriod,
    formatCustomRange,
} from '../core/dates/utils/dateRangeHelpers';
import {
    AGE_RANGE_SLIDER_MIN,
    AGE_RANGE_SLIDER_MAX,
    HEIGHT_RANGE_SLIDER_MIN,
    HEIGHT_RANGE_SLIDER_MAX,
    WEIGHT_RANGE_SLIDER_MIN,
    WEIGHT_RANGE_SLIDER_MAX,
    VEHICLE_YEAR_RANGE_SLIDER_MIN,
    VEHICLE_YEAR_RANGE_SLIDER_MAX,
} from '../configs/formsConfig';
import { joinTruthyValues } from './stringHelpers';

/**
 * Format the display string for a vehicle year range.
 * @param  {number} [options.rangeStart]
 * @param  {number} [options.rangeEnd]
 * @param  {number} [options.min]
 * @param  {number} [options.max]
 * @return {string}
 */
export function formatVehicleYearRange({
    rangeStart = VEHICLE_YEAR_RANGE_SLIDER_MIN,
    rangeEnd = VEHICLE_YEAR_RANGE_SLIDER_MAX,
    min = VEHICLE_YEAR_RANGE_SLIDER_MIN,
    max = VEHICLE_YEAR_RANGE_SLIDER_MAX,
}) {
    const prefix = rangeStart === min ? '<' : '';
    const suffix = rangeEnd === max ? '+' : '';
    return `${prefix}${rangeStart}-${rangeEnd}${suffix}`;
}

/**
 * Format the display string for an age range.
 * @param  {number} [options.rangeStart]
 * @param  {number} [options.rangeEnd]
 * @param  {number} [options.max]
 * @return {string}
 */
export function formatAgeRange({
    rangeStart = AGE_RANGE_SLIDER_MIN,
    rangeEnd = AGE_RANGE_SLIDER_MAX,
    max = AGE_RANGE_SLIDER_MAX,
}) {
    return `${rangeStart}-${rangeEnd}${rangeEnd === max ? '+' : ''} yrs`;
}

/**
 * Format the display string for a height range.
 * @param  {number} [options.rangeStart]
 * @param  {number} [options.rangeEnd]
 * @param  {number} [options.min]
 * @param  {number} [options.max]
 * @return {string}
 */
export function formatHeightRange({
    rangeStart = HEIGHT_RANGE_SLIDER_MIN,
    rangeEnd = HEIGHT_RANGE_SLIDER_MAX,
    min = HEIGHT_RANGE_SLIDER_MIN,
    max = HEIGHT_RANGE_SLIDER_MAX,
}) {
    const prefix = rangeStart === min ? '<' : '';
    const suffix = rangeEnd === max ? '+' : '';
    return `${prefix}${formatHeight(rangeStart)}-${formatHeight(rangeEnd)}${suffix}`;
}

/**
 * Format the display string for a weight range.
 * @param  {number} [options.rangeStart]
 * @param  {number} [options.rangeEnd]
 * @param  {number} [options.min]
 * @param  {number} [options.max]
 * @return {string}
 */
export function formatWeightRange({
    rangeStart = WEIGHT_RANGE_SLIDER_MIN,
    rangeEnd = WEIGHT_RANGE_SLIDER_MAX,
    min = WEIGHT_RANGE_SLIDER_MIN,
    max = WEIGHT_RANGE_SLIDER_MAX,
}) {
    const prefix = rangeStart === min ? '<' : '';
    const suffix = rangeEnd === max ? '+' : '';
    return `${prefix}${rangeStart}-${rangeEnd}${suffix} lbs`;
}

export function formatSimpleRange({ rangeStart = 0, rangeEnd = 1 }) {
    return joinTruthyValues([rangeStart, rangeEnd], ' - ');
}

/**
 * Format the display string for a group of range fields.
 * @param  {Object} fields
 * @param  {Object} fieldViewModels
 * @param  {DateTimeFormatter} dateTimeFormatter
 * @return {string}
 */
export function formatRangeFieldValue(fields, fieldViewModels, dateTimeFormatter) {
    switch (fieldViewModels[0].rangeKey) {
        case rangeFieldKeyEnum.AGE_RANGE:
            return formatAgeRange({
                rangeStart: get(fields, 'ageRangeStart'),
                rangeEnd: get(fields, 'ageRangeEnd'),
            });
        case rangeFieldKeyEnum.HEIGHT_RANGE:
            return formatHeightRange({
                rangeStart: get(fields, 'heightRangeMin'),
                rangeEnd: get(fields, 'heightRangeMax'),
            });
        case rangeFieldKeyEnum.WEIGHT_RANGE:
            return formatWeightRange({
                rangeStart: get(fields, 'weightRangeMin'),
                rangeEnd: get(fields, 'weightRangeMax'),
            });
        case rangeFieldKeyEnum.VEHICLE_YEAR_RANGE:
            return formatVehicleYearRange({
                rangeStart: get(fields, 'minYearOfManufacture'),
                rangeEnd: get(fields, 'maxYearOfManufacture'),
            });
        case rangeFieldKeyEnum.TIME_RANGE:
            return `${get(fields, 'startTime')} - ${get(fields, 'endTime')}`;
        case rangeFieldKeyEnum.DATE_TIME_RANGE:
            // zipObject has gone through breaking changes between lodash 3.10.1 and 4.17.2
            // The desired operator in 4.17.2 is fromPairs, which doesn't exist in 3.10.1
            // When available, use fromPairs, otherwise fall back to zipObject
            const zipObjectHelper = _.fromPairs || _.zipObject;
            const fieldKeyByRangeTypeArrays = _(fieldViewModels)
                .filter((fieldViewModel) => {
                    return _(fields).keys().includes(fieldViewModel.key);
                })
                .map((fieldViewModel) => {
                    return [fieldViewModel.rangeType, fieldViewModel.key];
                })
                .value();

            const fieldKeyByRangeType = zipObjectHelper(fieldKeyByRangeTypeArrays);

            if (fieldKeyByRangeType[rangeFieldTypeEnum.WITHIN_LAST_PERIOD]) {
                return formatWithinLastPeriod(
                    get(fields, fieldKeyByRangeType[rangeFieldTypeEnum.WITHIN_LAST_PERIOD])
                );
            } else if (fieldKeyByRangeType[rangeFieldTypeEnum.TO_DATE_PERIOD]) {
                return formatToDatePeriod(
                    get(fields, fieldKeyByRangeType[rangeFieldTypeEnum.TO_DATE_PERIOD])
                );
            } else if (
                fieldKeyByRangeType[rangeFieldTypeEnum.RANGE_START] ||
                fieldKeyByRangeType[rangeFieldTypeEnum.RANGE_END]
            ) {
                return formatCustomRange(
                    get(fields, fieldKeyByRangeType[rangeFieldTypeEnum.RANGE_START]),
                    get(fields, fieldKeyByRangeType[rangeFieldTypeEnum.RANGE_END]),
                    true, // include time; can make this configurable if needed
                    dateTimeFormatter
                );
            }
            return '';
        default:
            return joinTruthyValues(fields, '-');
    }
}

import { LookerResource } from '@mark43/rms-api';
import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'Looker Resource',
    methods: {
        getLookerDashboards() {
            return req<LookerResource.GetUrlsForLookerEmbeddables>({
                method: 'GET',
                url: `looker/urls`,
            });
        },
        getLookerDashboardById(dashboardId: string) {
            return req<LookerResource.GetDashboardEmbedUrlForUser>({
                method: 'GET',
                url: `looker/urls/${dashboardId}/`,
            });
        },
        createNewDashboard(folderType: string) {
            return req<LookerResource.CreateLookerDashboard>({
                method: 'POST',
                url: `looker/dashboards`,
                data: folderType,
            });
        },
        getQueryTask(queryTaskId: string) {
            return req<LookerResource.GetQueryTask>({
                method: 'GET',
                url: `looker/query_tasks/${queryTaskId}/`,
                hideLoadingBar: true,
            });
        },
        getRunningQueries() {
            return req<LookerResource.GetRunningQueries>({
                method: 'GET',
                url: `looker/running-queries`,
                hideLoadingBar: true,
            });
        },
        killRunningQueriesForDashboard(dashboardId: string) {
            return req<LookerResource.KillRunningQueriesForDashboard>({
                method: 'DELETE',
                url: `looker/running-queries`,
                params: {
                    dashboard_id: dashboardId,
                },
            });
        },
        killRunningQuery(queryTaskId: string) {
            return req<LookerResource.KillRunningQuery>({
                method: 'DELETE',
                url: `looker/running-queries/${queryTaskId}/`,
            });
        },
    },
});

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { EntityTypeEnum, UsageActionEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import testIds from '../../../../core/testIds';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import { canCurrentUserEditCurrentCaseSelector, currentCaseIdSelector } from '../../core/state/ui';
import { EFileCreationModal, useEFileDisplayName, useEFileDetailsModal } from '../../../e-files';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import { useDispatchUsageLog } from '../../core/hooks';
import { CaseEFilesTable } from './CaseEFilesTable';

const strings = componentStrings.reports.core.ReportHeaderHamburgerMenu;

const HeaderAboveTable = styled.div`
    margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
    margin-right: -10px;
`;

const NewButton = styled(Button)`
    min-width: '185px';
    float: right;
    margin-right: 0;
    margin-top: 4px;
`;

const SummaryLine = styled.div`
    display: flex;
    justify-content: end;
    height: 40px;
`;

function CaseEFiles() {
    const currentCaseId = useSelector(currentCaseIdSelector);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const canEditCurrentCase = useSelector(canCurrentUserEditCurrentCaseSelector);
    const canEditEFiles = currentUserHasAbility(abilitiesEnum.EFILES.EDIT);
    const canCreateEFiles = canEditCurrentCase && canEditEFiles;

    const { eFileDisplayName } = useEFileDisplayName();
    const { openCreateEFile } = useEFileDetailsModal();

    useDispatchUsageLog(EntityTypeEnum.CASE.name, UsageActionEnum.VIEWED_CASE_E_FILES.name);

    return (
        <div data-test-id={testIds.CASE_E_FILES}>
            <HeaderAboveTable>
                <SummaryLine>
                    <ButtonWrapper>
                        <NewButton
                            iconLeft={<Icon fontSize="20px" type={iconTypes.ADD} />}
                            className={buttonTypes.PRIMARY}
                            disabled={!canCreateEFiles}
                            onClick={() => {
                                openCreateEFile({
                                    entityId: currentCaseId,
                                    entityType: EntityTypeEnum.CASE.name,
                                });
                            }}
                            testId={testIds.CASE_CREATE_E_FILE}
                        >
                            {strings.createNewEFile(eFileDisplayName)}
                        </NewButton>
                    </ButtonWrapper>
                </SummaryLine>
            </HeaderAboveTable>
            <CaseEFilesTable caseId={currentCaseId} />
            <EFileCreationModal />
        </div>
    );
}

export default styled(CaseEFiles)`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
`;

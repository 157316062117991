import { ProductModuleEnum } from '@mark43/rms-api';
import React, { useState } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { Text, cssVar } from 'arc';
import { createFormConfiguration, Form, lifecycleOptions } from 'markformythree';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';
import useFields, { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import {
    DISPLAY_ONLY_CAD_LABEL,
    DISPLAY_ONLY_ITEMS_PENDING_DISPOSITION_REVIEW_LABEL,
} from '~/client-common/core/enums/universal/fields';
import testIds from '../../../core/testIds';
import formsRegistry from '../../../core/formsRegistry';
import { OverlayBaseHelper } from '../../core/components/OverlayBaseHelper';
import { PortalSidePanel } from '../../../legacy-redux/components/core/SidePanel';
import { abilitiesEnum, OnlyWithAbility } from '../../core/abilities';
import Row from '../../core/components/Row';
import { MFTRadio } from '../../core/forms/components/Radio';
import {
    SubsectionTitleText,
    SecondarySectionHeader as _SecondarySectionHeader,
} from '../../core/components/typography';
import personalDashboardResource from '../resources/personalDashboardResource';
import { convertFromFormModel, FORM_NAME } from '../state/forms/personalDashboardSettingsForm';

const SidePanelContainer = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;

const StyledText = styled(Text)`
    font-size: ${cssVar('arc.fontSizes.sm')};
    display: inline-block;
`;
const StyledRadio = styled(MFTRadio)`
    float: right;
`;

const SecondarySectionHeader = styled(_SecondarySectionHeader)`
    padding-top: 25px;
`;

const strings = componentStrings.personalDashboard;
const PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL = overlayIdEnum.PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL;

const isHiddenOptions = [
    {
        value: false,
        label: strings.sidePanel.show,
    },
    {
        value: true,
        label: strings.sidePanel.hide,
    },
];

const DashboardSettingsForm = connect(
    createStructuredSelector({
        applicationSettings: applicationSettingsSelector,
    })
)(function _DashboardSettingsForm({
    saveSettingsSuccess,
    initialErrorMessage,
    overlayBase,
    savePanel,
    closePanel,
    userLayoutSettings,
}) {
    const [errorMessages, setErrorMessages] = useState(
        initialErrorMessage ? [initialErrorMessage] : []
    );

    const onSubmit = () => {
        const form = formsRegistry.get(FORM_NAME);
        const formModel = form.getState().model;
        const settings = convertFromFormModel(formModel, userLayoutSettings);
        personalDashboardResource
            .upsertSettings(settings)
            .then((updatedSettings) => {
                saveSettingsSuccess(updatedSettings);
                savePanel();
            })
            .catch((error) => {
                setErrorMessages([error.message]);
            });
    };

    const cadDisplayName = useFields(DISPLAY_ONLY_CAD_LABEL)[DISPLAY_ONLY_CAD_LABEL];
    const { pluralCaseFieldName: casesDisplayName } = useCaseFieldName();
    const itemsPendingDispositionReviewDisplayName = useFields(
        DISPLAY_ONLY_ITEMS_PENDING_DISPOSITION_REVIEW_LABEL
    )[DISPLAY_ONLY_ITEMS_PENDING_DISPOSITION_REVIEW_LABEL];

    return (
        <PortalSidePanel
            id={PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL}
            noPadding={true}
            title={strings.settings}
            closePanel={closePanel}
            savePanel={onSubmit}
            isLoading={overlayBase.overlayState.isLoading}
            errorMessages={errorMessages}
            userHasAttemptedSave={!!errorMessages.length}
            testId={testIds.PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL}
            isAtBottomOfStack={() => {
                return overlayBase.overlayStore.isOverlayAtBottomOfStack(
                    PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL
                );
            }}
        >
            <SidePanelContainer>
                <Form
                    name={FORM_NAME}
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    configuration={createFormConfiguration({
                        RECENTLY_VIEWED: {},
                        EXTERNAL_LINKS: {},
                        RECENT_SAVED_SEARCHES: {},
                        RECENT_ALERTS: {},
                        ACTION_REQUIRED_REPORTS: {},
                        MY_CAD_EVENTS: {},
                        RECENT_ARRESTS: {},
                        MY_TASKS: {},
                        ITEMS_PENDING_REVIEW: {},
                        LEXIS_NEXIS_PORTAL: {},
                    })}
                    render={() => (
                        <>
                            <SecondarySectionHeader>
                                {strings.sidePanel.sidebar}
                            </SecondarySectionHeader>
                            <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_GENERAL}>
                                <Row>
                                    <StyledText>{strings.RecentlyViewed.title}</StyledText>
                                    <StyledRadio
                                        name="isRecentlyViewedHidden"
                                        path="RECENTLY_VIEWED"
                                        options={isHiddenOptions}
                                    />
                                </Row>
                            </OnlyWithAbility>
                            <FeatureFlagged flag="RMS_PERSONAL_DASHBOARD_V2_EXTERNAL_LINKS">
                                <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_GENERAL}>
                                    <Row>
                                        <StyledText>{strings.ExternalLinks.title}</StyledText>
                                        <StyledRadio
                                            name="isExternalLinksHidden"
                                            path="EXTERNAL_LINKS"
                                            options={isHiddenOptions}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </FeatureFlagged>
                            <OnlyWithAbility has={abilitiesEnum.CORE.SEARCH_GENERAL}>
                                <Row>
                                    <StyledText>{strings.RecentSavedSearches.title}</StyledText>
                                    <StyledRadio
                                        name="isRecentlySavedSearchesHidden"
                                        path="RECENT_SAVED_SEARCHES"
                                        options={isHiddenOptions}
                                    />
                                </Row>
                            </OnlyWithAbility>
                            <OnlyWithAbility has={abilitiesEnum.NOTIFICATIONS.CORE}>
                                <Row>
                                    <StyledText>{strings.RecentAlerts.title}</StyledText>
                                    <StyledRadio
                                        name="isRecentAlertsHidden"
                                        path="RECENT_ALERTS"
                                        options={isHiddenOptions}
                                    />
                                </Row>
                            </OnlyWithAbility>
                            <SecondarySectionHeader>
                                <SubsectionTitleText>{strings.sidePanel.cards}</SubsectionTitleText>
                            </SecondarySectionHeader>
                            <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_GENERAL}>
                                <Row>
                                    <StyledText>{strings.actionRequiredReports.title}</StyledText>
                                    <StyledRadio
                                        name="isActionRequiredReportsHidden"
                                        path="ACTION_REQUIRED_REPORTS"
                                        options={isHiddenOptions}
                                    />
                                </Row>
                            </OnlyWithAbility>
                            <OnlyWithAbility has={abilitiesEnum.CORE.SEARCH_GENERAL}>
                                <Row>
                                    <StyledText>
                                        {strings.MyCadEvents.title(cadDisplayName)}
                                    </StyledText>
                                    <StyledRadio
                                        name="isMyCadEvents"
                                        path="MY_CAD_EVENTS"
                                        options={isHiddenOptions}
                                    />
                                </Row>
                            </OnlyWithAbility>
                            <FeatureFlagged flag="RMS_TASK_AND_REQUEST_TRACKING_ENABLED">
                                <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_NON_CASE_TASKS}>
                                    <Row>
                                        <StyledText>{strings.MyTasks.title}</StyledText>
                                        <StyledRadio
                                            name="myTasks"
                                            path="MY_TASKS"
                                            options={isHiddenOptions}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </FeatureFlagged>
                            <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
                                <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_GENERAL}>
                                    <Row>
                                        <StyledText>
                                            {itemsPendingDispositionReviewDisplayName}
                                        </StyledText>
                                        <StyledRadio
                                            name="isItemsPendingReviewHidden"
                                            path="ITEMS_PENDING_REVIEW"
                                            options={isHiddenOptions}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </ProductModuled>
                            <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_GENERAL}>
                                <Row>
                                    <StyledText>{strings.recentArrests.title}</StyledText>
                                    <StyledRadio
                                        name="isRecentArrestsHidden"
                                        path="RECENT_ARRESTS"
                                        options={isHiddenOptions}
                                    />
                                </Row>
                            </OnlyWithAbility>
                            <OnlyWithAbility has={abilitiesEnum.CORE.SEARCH_GENERAL}>
                                <Row>
                                    <StyledText>
                                        {strings.MyCases.title(casesDisplayName)}
                                    </StyledText>
                                    <StyledRadio
                                        name="isMyCasesHidden"
                                        path="MY_CASES"
                                        options={isHiddenOptions}
                                    />
                                </Row>
                            </OnlyWithAbility>
                            <FeatureFlagged flag="RMS_WARRANT_CARDS_ENABLED">
                                <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_GENERAL}>
                                    <Row>
                                        <StyledText>{strings.RecentWarrants.title}</StyledText>
                                        <StyledRadio
                                            name="isRecentWarrantsHidden"
                                            path="RECENT_WARRANTS"
                                            options={isHiddenOptions}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </FeatureFlagged>
                            <FeatureFlagged flag="RMS_WARRANT_CARDS_ENABLED">
                                <OnlyWithAbility has={abilitiesEnum.CORE.SEARCH_GENERAL}>
                                    <Row>
                                        <StyledText>{strings.MyWarrants.title}</StyledText>
                                        <StyledRadio
                                            name="isMyWarrantsHidden"
                                            path="MY_WARRANTS"
                                            options={isHiddenOptions}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </FeatureFlagged>
                            <ProductModuled productModule={ProductModuleEnum.INSIGHTS.name}>
                                <OnlyWithAbility has={abilitiesEnum.ANALYSIS.GENERAL}>
                                    <Row>
                                        <StyledText>{strings.Insights.title}</StyledText>
                                        <StyledRadio
                                            name="isInsightsHidden"
                                            path="INSIGHTS"
                                            options={isHiddenOptions}
                                        />
                                    </Row>
                                </OnlyWithAbility>
                            </ProductModuled>
                        </>
                    )}
                />
            </SidePanelContainer>
        </PortalSidePanel>
    );
});

export const DashboardSettingsSidePanel = ({ renderButton, ...props }) => {
    return (
        <OverlayBaseHelper id={PERSONAL_DASHBOARD_SETTINGS_SIDEPANEL} renderButton={renderButton}>
            {(renderProps) => <DashboardSettingsForm {...props} {...renderProps} />}
        </OverlayBaseHelper>
    );
};

import React from 'react';
import { compose } from 'recompose';
import { ElasticReport } from '@mark43/rms-api';

import { getViewModelProperties, ViewModel } from '~/client-common/helpers/viewModelHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { canManage } from '~/client-common/core/domain/entity-permissions/state/ui';

import { RestrictVisibilityWithDash } from '../../../../../modules/core/components/RestrictVisibilityWithDash';

type ElasticReportViewModel = ViewModel<ElasticReport, { currentSubmissionOwnerId?: string }>;

function ReportOwnerCell(elasticReportViewModel: ElasticReportViewModel) {
    const viewModel = getViewModelProperties(elasticReportViewModel);
    const { restrictViewReportOwners } = elasticReportViewModel.reportDefinition;

    return (
        <div>
            <FeatureFlagged
                flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                fallback={<div>{viewModel.currentSubmissionOwnerId}</div>}
            >
                <RestrictVisibilityWithDash
                    restricted={
                        restrictViewReportOwners && !canManage(elasticReportViewModel.permissionSet)
                    }
                >
                    {viewModel.currentSubmissionOwnerId}
                </RestrictVisibilityWithDash>
            </FeatureFlagged>
        </div>
    );
}

export default compose<ElasticReportViewModel, { columnKey: 'reportOwner' }>()(ReportOwnerCell);

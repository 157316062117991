import { FieldContact } from '@mark43/rms-api';
import { head } from 'lodash';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'fieldContacts';

const fieldContactsModule = createNormalizedModule<FieldContact>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
const fieldContactsWhereSelector = fieldContactsModule.selectors.entitiesWhereSelector;
export const fieldContactByReportIdSelector = createSelector(
    fieldContactsWhereSelector,
    (fieldContactsWhere) => (reportId: number) => head(fieldContactsWhere({ reportId }))
);

// REDUCER
export default fieldContactsModule.reducerConfig;

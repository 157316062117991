import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { omit } from 'lodash';
import { combineReducers } from 'redux';

import { createStructuredSelector } from 'reselect';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';

import { createWarrantsResultsSelector } from '~/client-common/core/domain/elastic-warrants/state/ui';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import { getAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { scrollToTop } from '../../../../core/components/ScrollableUnderSubheader';
import warrantsDashboardSearchForm, {
    convertWarrantsDashboardSearchElasticQueryToFormModel,
    convertWarrantsDashboardSearchFormModelToFilterGroups,
} from '../forms/warrantsDashboardSearchForm';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import searchResource from '../../../../../legacy-redux/resources/searchResource';

const warrantsDashboardSearchConstants = {
    FROM: 0,
    SIZE: 25,
};

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
function convertWarrantsDashboardSearchElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    // removing the query so it doesn't appear as an applied filter
    const filteredQuery = omit(elasticQuery, 'quickSearchQuery');
    return convertWarrantsDashboardSearchFormModelToFilterGroups(
        convertWarrantsDashboardSearchElasticQueryToFormModel(filteredQuery),
        formatFieldValue,
        boundSelectors
    );
}

/**
 * Submit the warrants dashboard search form.
 */
export function submitWarrantsDashboardSearchForm(showLoadingModal = true) {
    return function (dispatch) {
        dispatch(
            warrantsDashboardSearch.form.actionCreators.submit((formData) => {
                // make the search
                dispatch(
                    warrantsDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        {
                            cacheBust: true,
                            scroll: false,
                            showLoadingModal,
                        }
                    )
                );
            })
        );
    };
}

/**
 * Close the filter modal and remove all currently applied filters
 * Also do another search with no filters used.
 */
export function clearAllFilters() {
    return function (dispatch) {
        dispatch(warrantsDashboardSearch.form.actionCreators.reset());
        dispatch(
            warrantsDashboardSearch.form.actionCreators.submit((formData) => {
                // make the search
                dispatch(
                    warrantsDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                );
            })
        );
    };
}

export const warrantsDashboardSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.WARRANT.name,
    baseUiSelector: (state) => state.ui.warrants.dashboard.search,
    form: warrantsDashboardSearchForm,
    searchResultToRoutePath: ({ id }) => `/warrants/${id}`,
    elasticQueryToFilterGroups: convertWarrantsDashboardSearchElasticQueryToFilterGroups,
    defaultTableState: {
        from: warrantsDashboardSearchConstants.FROM,
        size: warrantsDashboardSearchConstants.SIZE,
        sortKey: sortKeyEnum.WARRANT_DATE_ISSUED_UTC,
        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
        activeColumnKeys: {
            date: 'warrantIssuedDateUtc',
            warrant: 'warrantInformation',
            subject: null,
        },
    },
    resourceMethod: elasticSearchResource.searchWarrants,
    resultsContainerClassName: 'warrants-dashboard-search-results',
    createResultsViewModelsSelector: createWarrantsResultsSelector,
    selectorToBind: createStructuredSelector({
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
        attributeById: getAttributeByIdSelector,
    }),
    exportResourceMethod: searchResource.exportWarrants,
    loadSearchExportPrintablesResourceMethod: searchResource.getWarrantSearchExportPrintables,
    scrollToElement: scrollToTop,
});

export function performInitialWarrantsDashboardSearch() {
    return function (dispatch, getState) {
        // NOTE: this ignores the success or failure of the search calls
        // this is partly because it's possible a search has already been performed,
        // and partly because so much of the logic is buried in createSearchModule
        dispatch(warrantsDashboardSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
            (result) => {
                if (!result) {
                    // execute first time visit search
                    const formModel = warrantsDashboardSearchForm.selectors.formModelSelector(
                        getState()
                    );
                    return dispatch(
                        warrantsDashboardSearch.actionCreators.search(
                            {
                                formData: formModel,
                            },
                            // do not auto-save the default search so that
                            // a faulty request cannot cause your latest saved-search
                            // to be overwritten by the default search
                            { cacheBust: true, scroll: false, autoSave: false }
                        )
                    );
                }
            }
        );
    };
}

export default combineReducers({
    search: warrantsDashboardSearch.reducers.uiReducer,
});

import styled from 'styled-components';

const LocationWrapper = styled.span`
    font: ${(props) =>
        `${props.theme.fontWeights.regular} var(--arc-fontSizes-md) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => props.theme.colors.lightGrey};
    text-transform: uppercase;
`;

export default LocationWrapper;

import styled from 'styled-components';

export const InfoMessage = styled.div`
    position: relative;
    box-sizing: border-box;
    padding: 10px 10px;
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    border-radius: 4px;
    display: flex;
    background-color: ${(props) => props.theme.colors.lightBlue};
    border: 1px solid ${(props) => props.theme.colors.cobaltBlue};
`;

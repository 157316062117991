import { ApplicationSettingView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const applicationSettingsModule = createNormalizedModule<ApplicationSettingView>({
    type: 'applicationSettings',
});

// ACTIONS
export const storeApplicationSettings = applicationSettingsModule.actionCreators.storeEntities;

// SELECTORS
export const applicationSettingsSelector = applicationSettingsModule.selectors.entitiesSelector;

export default applicationSettingsModule.reducerConfig;

import { TrafficCrashPerson } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashPersons';
const trafficCrashPersonModule = createNormalizedModule<TrafficCrashPerson>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const trafficCrashPersonsSelector = trafficCrashPersonModule.selectors.entitiesSelector;

// REDUCER
export default trafficCrashPersonModule.reducerConfig;

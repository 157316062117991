import { RoleTypeEnum, AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { createFormConfiguration, lifecycleOptions } from 'markformythree';
import { uniq } from 'lodash';
import { currentUserUserRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { OverlayBase } from '../../../../core/overlayManager';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import CheckboxedField, {
    createCheckboxPath,
} from '../../../core/forms/components/checkboxes/CheckboxedField';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { submitBulkManageCasesSidePanel } from '../state/data';
import { ArbiterMFTCaseDefinitionSelect } from '../../../core/forms/components/selects/CaseDefinitionSelect';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import testIds from '../../../../core/testIds';

const strings = componentStrings.cases.core.BulkManageCasesSidePanel;

const assigneePath = 'assigneeRoleId';
const assignedUnitPath = 'assignedPersonnelUnitAttrId';
const supervisorPath = 'supervisorRoleIds';
const assistingInvestigatorPath = 'assistingInvestigatorRoleIds';
const caseDefinitionPath = 'caseDefinitionId';
const dueDatePath = 'dueDateUtc';

const formConfiguration = createFormConfiguration({
    [assigneePath]: {
        fieldName: fields.DISPLAY_CASE_ASSIGNEE,
    },
    [createCheckboxPath(assigneePath)]: {
        fieldName: fields.DISPLAY_ONLY_IS_DISPLAY_CASE_ASSIGNEE_REQUIRED,
    },
    [assignedUnitPath]: {
        fieldName: fields.CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
    },
    [createCheckboxPath(assignedUnitPath)]: {
        fieldName: fields.DISPLAY_ONLY_IS_CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID_REQUIRED,
    },
    [supervisorPath]: {
        fieldName: fields.DISPLAY_CASE_SUPERVISORS,
    },
    [createCheckboxPath(supervisorPath)]: {
        fieldName: fields.DISPLAY_ONLY_IS_DISPLAY_CASE_SUPERVISORS_REQUIRED,
    },
    [assistingInvestigatorPath]: {
        fieldName: fields.DISPLAY_CASE_ASSISTING_INVESTIGATORS,
    },
    [createCheckboxPath(assistingInvestigatorPath)]: {
        fieldName: fields.DISPLAY_ONLY_IS_DISPLAY_CASE_ASSISTING_INVESTIGATORS_REQUIRED,
    },
    [caseDefinitionPath]: {
        fieldName: fields.CASE_CASE_DEFINITION_ID,
    },
    [createCheckboxPath(caseDefinitionPath)]: {
        fieldName: fields.DISPLAY_ONLY_IS_CASE_CASE_DEFINITION_ID_REQUIRED,
    },
    [dueDatePath]: {
        fieldName: fields.CASE_DUE_DATE_UTC,
    },
    [createCheckboxPath(dueDatePath)]: {
        fieldName: fields.DISPLAY_ONLY_IS_CASE_DUE_DATE_UTC_REQUIRED,
    },
});

const FormDescription = styled.div`
    margin-bottom: 20px;
    font-size: var(--arc-fontSizes-sm);
`;

const BoldedText = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.bold};
`;

const AddMeButton = styled(Button)`
    margin-top: 19px;
`;

const BulkManageCasesSidePanel = ({ caseIds, renderButton, submit, currentUserUserRoleId }) => {
    const { pluralCaseFieldName: casesDisplayName } = useCaseFieldName();
    return (
        <ArbiterForm
            name={RefContextEnum.FORM_BULK_MANAGE_CASES_SIDE_PANEL.name}
            context={RefContextEnum.FORM_BULK_MANAGE_CASES_SIDE_PANEL.name}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            configuration={formConfiguration}
            initialState={{
                [supervisorPath]: [],
                [assistingInvestigatorPath]: [],
                [createCheckboxPath(assigneePath)]: false,
                [createCheckboxPath(assignedUnitPath)]: false,
                [createCheckboxPath(supervisorPath)]: false,
                [createCheckboxPath(assistingInvestigatorPath)]: false,
                [createCheckboxPath(caseDefinitionPath)]: false,
                [createCheckboxPath(dueDatePath)]: false,
            }}
            render={(form) => (
                <OverlayBase id={overlayIdEnum.BULK_MANAGE_CASES_SIDEPANEL}>
                    {({
                        overlayState: {
                            isOpen,
                            errors,
                            customProperties: { isSaving },
                        },
                        open,
                        close,
                        ...otherProps
                    }) => (
                        <>
                            {renderButton(open)}
                            {isOpen && (
                                <PortalSidePanel
                                    id={overlayIdEnum.BULK_MANAGE_CASES_SIDEPANEL}
                                    title={strings.title(casesDisplayName)}
                                    saveText={strings.saveText}
                                    savePanel={submit(caseIds)}
                                    closePanel={() => {
                                        form.resetModel();
                                        form.resetUi();
                                        close();
                                    }}
                                    errorMessages={errors}
                                    userHasAttemptedSave={isSaving || !!errors.length}
                                    {...otherProps}
                                    testId={testIds.BULK_MANAGE_CASES_SIDEPANEL}
                                >
                                    <FormDescription>
                                        {strings.preBoldDescription}
                                        <BoldedText>
                                            {strings.boldDescription(
                                                caseIds.length,
                                                casesDisplayName
                                            )}
                                        </BoldedText>
                                        {strings.postBoldDescription(casesDisplayName)}
                                    </FormDescription>
                                    <CheckboxedField
                                        checkboxPath={createCheckboxPath(caseDefinitionPath)}
                                    >
                                        <ArbiterMFTCaseDefinitionSelect
                                            clearable={false}
                                            path={caseDefinitionPath}
                                            width={240}
                                        />
                                    </CheckboxedField>
                                    <CheckboxedField checkboxPath={createCheckboxPath(dueDatePath)}>
                                        <ArbiterMFTDatePicker path={dueDatePath} width={240} />
                                    </CheckboxedField>
                                    <CheckboxedField
                                        checkboxPath={createCheckboxPath(assigneePath)}
                                    >
                                        <ArbiterMFTRoleSelect
                                            roleTypes={[RoleTypeEnum.USER.name]}
                                            path={assigneePath}
                                            width={240}
                                        />
                                        <AddMeButton
                                            className={buttonTypes.SECONDARY}
                                            onClick={() =>
                                                form.set(assigneePath, currentUserUserRoleId)
                                            }
                                            testId={testIds.ASSIGN_CASE_TO_ME_BUTTON}
                                        >
                                            {strings.addMe}
                                        </AddMeButton>
                                    </CheckboxedField>
                                    <CheckboxedField
                                        checkboxPath={createCheckboxPath(assistingInvestigatorPath)}
                                    >
                                        <ArbiterMFTRoleSelect
                                            roleTypes={[RoleTypeEnum.USER.name]}
                                            path={assistingInvestigatorPath}
                                            multiple={true}
                                            width={240}
                                        />
                                        <AddMeButton
                                            className={buttonTypes.SECONDARY}
                                            onClick={() =>
                                                form.set(
                                                    assistingInvestigatorPath,
                                                    uniq([
                                                        ...form.get(assistingInvestigatorPath),
                                                        currentUserUserRoleId,
                                                    ])
                                                )
                                            }
                                        >
                                            {strings.addMe}
                                        </AddMeButton>
                                    </CheckboxedField>
                                    <CheckboxedField
                                        checkboxPath={createCheckboxPath(assignedUnitPath)}
                                    >
                                        <ArbiterMFTAttributeSelect
                                            attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                                            path={assignedUnitPath}
                                            width={240}
                                        />
                                    </CheckboxedField>
                                    <CheckboxedField
                                        checkboxPath={createCheckboxPath(supervisorPath)}
                                    >
                                        <ArbiterMFTRoleSelect
                                            roleTypes={[RoleTypeEnum.USER.name]}
                                            path={supervisorPath}
                                            multiple={true}
                                            width={240}
                                        />
                                        <AddMeButton
                                            className={buttonTypes.SECONDARY}
                                            onClick={() =>
                                                form.set(
                                                    supervisorPath,
                                                    uniq([
                                                        ...form.get(supervisorPath),
                                                        currentUserUserRoleId,
                                                    ])
                                                )
                                            }
                                        >
                                            {strings.addMe}
                                        </AddMeButton>
                                    </CheckboxedField>
                                </PortalSidePanel>
                            )}
                        </>
                    )}
                </OverlayBase>
            )}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    currentUserUserRoleId: currentUserUserRoleIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    submit: (caseIds) => () => dispatch(submitBulkManageCasesSidePanel(caseIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkManageCasesSidePanel);

import { RefContextEnum, AttributeTypeEnum } from '@mark43/rms-api';
import React, { useMemo } from 'react';
import { Button } from 'arc';
import styled from 'styled-components';
import { Observer } from 'markformythree';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { MFTNItems } from '../../../core/forms/components/NItems';
import {
    CaseViewType,
    getReasonForRelationForm,
    ReasonForRelationFormConfiguration,
    ReasonForRelationFormNItemDataShape,
} from '../state/form/reasonForRelationForm';
import Row from '../../../core/components/Row';
import IndentedFields from '../../../core/components/IndentedFields';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import testIds from '../../../../core/testIds';
import { N_ITEM_FIELD_FULL_WIDTH } from '../../../core/names/config/formWidths';
import CaseSearchLink from './CaseSearchLink';
import ReasonForRelationReportNItem from './ReasonForRelationReportNItem';

const strings = componentStrings.cases.core.ReasonForRelationForm;

export type ReasonForRelationFormLocationType =
    | typeof overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL
    | typeof overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL
    | typeof overlayIdEnum.REASON_FOR_RELATION_MODAL
    | typeof overlayIdEnum.REASON_FOR_RELATION_SIDE_PANEL
    | typeof overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL;

const HIDE_REASON_FOR_RELATION_FORM_LOCATIONS: ReasonForRelationFormLocationType[] = [
    overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL,
    overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL,
];

const SHOW_REMOVE_ITEM_BUTTON_FOR_LOCATIONS: ReasonForRelationFormLocationType[] = [
    overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL,
    overlayIdEnum.REASON_FOR_RELATION_MODAL,
    overlayIdEnum.REASON_FOR_RELATION_SIDE_PANEL,
];

const SHOW_REPORT_DETAILS_FORM_LOCATIONS: ReasonForRelationFormLocationType[] = [
    overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL,
    overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL,
];

type RelatedRecordFormItemProps = {
    index: number;
    item: ReasonForRelationFormNItemDataShape;
    casesToShow?: CaseViewType[];
    reportShortTitleWrapper?: ReportShortTitleWrapper;
    formLocation: ReasonForRelationFormLocationType;
    onChangeReportIds: (reportIds: number[]) => void;
};

type ReasonForRelationFormProps = {
    context:
        | typeof RefContextEnum.FORM_REASON_FOR_RELATION_SIDE_PANEL.name
        | typeof RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name;
    formLocation: ReasonForRelationFormLocationType;
    onRemoveItem?: (item: ReasonForRelationFormNItemDataShape) => void;
    onChangeReportIds?: (item: ReasonForRelationFormNItemDataShape, reportIds: number[]) => void;
    casesToShow?: CaseViewType[];
    reportShortTitleWrapper?: ReportShortTitleWrapper;
    hideReasonForRelation?: boolean;
};

const RelatedRecordFormItemWrapper = styled.div`
    background-color: var(--arc-colors-surface-accent);
    padding: 1em 1em 0 1em;
    margin-bottom: 1em;
`;

const CaseSearchLinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5em;
`;

const RelatedRecordForm: React.FC<{ hideReasonForRelation: boolean }> = ({
    children,
    hideReasonForRelation,
}) => {
    return (
        <RelatedRecordFormItemWrapper>
            <CaseSearchLinkWrapper>{children}</CaseSearchLinkWrapper>
            {!hideReasonForRelation && (
                <>
                    <Row>
                        <ArbiterMFTAttributeSelect
                            path="reasonForRelationAttrId"
                            attributeType={AttributeTypeEnum.REASON_FOR_RELATION.name}
                            width={N_ITEM_FIELD_FULL_WIDTH}
                        />
                    </Row>
                    <IndentedFields>
                        <Row>
                            <ArbiterMFTText
                                path="reasonForRelationOther"
                                width={N_ITEM_FIELD_FULL_WIDTH}
                            />
                        </Row>
                    </IndentedFields>
                </>
            )}
        </RelatedRecordFormItemWrapper>
    );
};

const RelatedRecordFormItem: React.FC<RelatedRecordFormItemProps> = ({
    index,
    formLocation,
    item,
    casesToShow,
    reportShortTitleWrapper,
    onChangeReportIds,
}) => {
    let formRecordItem = null;

    switch (formLocation) {
        case overlayIdEnum.REASON_FOR_RELATION_SIDE_PANEL: {
            formRecordItem =
                casesToShow && casesToShow.length > 0 ? (
                    <CaseSearchLink item={casesToShow[index]} />
                ) : null;
            break;
        }
        case overlayIdEnum.REASON_FOR_RELATION_MODAL:
        case overlayIdEnum.CREATE_CASE_FROM_REPORT_MODAL:
        case overlayIdEnum.CREATE_CASE_FROM_MULTI_REPORTS_MODAL:
        case overlayIdEnum.INDIVIDUAL_REPORT_SELECTION_SIDE_PANEL: {
            if (reportShortTitleWrapper) {
                const { caseId, reportingEventNumber, recordNumber } = item;
                const {
                    reportShortTitlesByREN,
                    reportShortTitlesByRecordWithoutRENRecordNumber,
                } = reportShortTitleWrapper;
                const isRen = !!reportingEventNumber;
                const reportShortTitles = isRen
                    ? reportShortTitlesByREN[reportingEventNumber]
                    : reportShortTitlesByRecordWithoutRENRecordNumber[recordNumber || ''];
                if (reportShortTitles && reportShortTitles.length >= 1) {
                    const allReportIds = reportShortTitles.map(({ reportId }) => reportId);
                    const isReportDetailsShown = SHOW_REPORT_DETAILS_FORM_LOCATIONS.includes(
                        formLocation
                    );
                    formRecordItem = (
                        <Observer<{ reportIds: number[] }, ReasonForRelationFormConfiguration>
                            subscriptions={{
                                reportIds: `reportCaseLinks[${index}].reportIds`,
                            }}
                            render={({ reportIds: selectedReportIds = [] }) => {
                                return (
                                    <ReasonForRelationReportNItem
                                        caseId={caseId ?? -1}
                                        isRen={isRen}
                                        showReportDetails={isReportDetailsShown}
                                        selectedReportIds={selectedReportIds}
                                        displayReportIds={allReportIds}
                                        onChange={onChangeReportIds}
                                    />
                                );
                            }}
                        />
                    );
                }
            }
            break;
        }
        default:
            formRecordItem = <>{strings.entityError}</>;
            break;
    }

    return formRecordItem;
};

const ReasonForRelationForm: React.FC<ReasonForRelationFormProps> = ({
    context,
    formLocation,
    onRemoveItem,
    casesToShow,
    reportShortTitleWrapper,
    onChangeReportIds,
}) => {
    const hideReasonForRelation = HIDE_REASON_FOR_RELATION_FORM_LOCATIONS.includes(formLocation);
    const reasonForRelationForm = useMemo(() => getReasonForRelationForm(hideReasonForRelation), [
        hideReasonForRelation,
    ]);
    const isRemoveItemButtonVisible = SHOW_REMOVE_ITEM_BUTTON_FOR_LOCATIONS.includes(formLocation);

    return (
        <ArbiterForm
            context={context}
            name={context}
            {...reasonForRelationForm}
            render={(form) => (
                <MFTNItems<ReasonForRelationFormNItemDataShape>
                    path={'reportCaseLinks'}
                    addItemOnEmpty={false}
                    childFieldKeys={['reasonForRelationAttrId', 'reasonForRelationOther']}
                    render={({ item, index }) => {
                        const handleReportIdsChange = (reportIds: number[]) => {
                            form.set(`reportCaseLinks[${index}].reportIds`, reportIds);

                            if (onChangeReportIds) {
                                onChangeReportIds(item, reportIds);
                            }
                        };

                        return (
                            <RelatedRecordFormItem
                                key={index}
                                index={index}
                                item={item}
                                casesToShow={casesToShow}
                                reportShortTitleWrapper={reportShortTitleWrapper}
                                formLocation={formLocation}
                                onChangeReportIds={handleReportIdsChange}
                            />
                        );
                    }}
                    renderAddButton={undefined}
                    renderRemoveButton={
                        isRemoveItemButtonVisible
                            ? ({ removeItem, item }) => {
                                  return (
                                      <Button
                                          key={`${item.caseId}`}
                                          size="sm"
                                          style={{ minWidth: '60px' }}
                                          onClick={() => {
                                              removeItem();
                                              if (onRemoveItem) {
                                                  onRemoveItem(item);
                                              }
                                          }}
                                      >
                                          {strings.removeText}
                                      </Button>
                                  );
                              }
                            : undefined
                    }
                    renderRowContainer={({ itemElement, removeButtonElement, index }) => (
                        <RelatedRecordForm
                            key={index}
                            data-test-id={testIds.REPORT_LINK_WRAPPER}
                            children={[itemElement, removeButtonElement]}
                            hideReasonForRelation={hideReasonForRelation}
                        />
                    )}
                />
            )}
        />
    );
};

export default ReasonForRelationForm;

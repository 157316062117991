import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { replaceSummaryNarrativeCardBundle } from '~/client-common/core/domain/reports/state/ui/summaryNarrative';
import { summaryNarrativeByReportIdSelector } from '~/client-common/core/domain/summary-narratives/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import errorToMessage from '../../../../core/errors/utils/errorToMessage';
import { buildSummaryNarrativeFormModel } from '../forms/summaryNarrativeForm';
import createCard from '../../utils/createCard';
import {
    getEditorIdByReportId,
    onEditNarrativeCards,
    sanitizeTinyMCEValue,
} from '../../../../core/editor/utils/tinyEditor';
import { withTinyEditor } from '../../../../core/editor/utils/withTinyEditor';
import { TINYMCE_DEFAULT_INIT_TIMEOUT } from '../../../../core/editor/constants';
import { submitCard, validateCard } from './cards';

const strings = componentStrings.core.Editor.errors;

const baseCard = createCard({
    name: reportCardEnum.SUMMARY_NARRATIVE.name,
    baseSelector: (state) => state.ui.report.summaryNarrativeCard,
    anchor: 'summary-narrative-card',
});

const SUMMARY_NARRATIVE_FORM_MODEL_PATH = 'summaryNarrative';

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit(options = {}) {
            return (dispatch, getState) => {
                const { index } = options;

                onEditNarrativeCards(index, true, getState());
                dispatch(baseCard.actionCreators.transitionToEditMode({ index }));
            };
        },
        editAll(options = {}) {
            return (dispatch, getState) => {
                const { reportId } = options;

                onEditNarrativeCards(reportId, true, getState());
                dispatch(baseCard.actionCreators.transitionAllCardsToEditMode());
            };
        },
        save(form, options) {
            return (dispatch, getState) => {
                const state = getState();
                const { onSaveSuccessCallback, index } = options;
                const reportId = baseCard.selectors.reportIdSelector(state, {
                    index,
                });

                const editorId = getEditorIdByReportId(reportId, true);
                const applicationSettings = applicationSettingsSelector(state);
                const initTimeout =
                    applicationSettings.RMS_TINYMCE_INIT_TIMEOUT || TINYMCE_DEFAULT_INIT_TIMEOUT;

                return new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        reject(strings.saveTimeout);
                    }, initTimeout);

                    withTinyEditor(editorId, 'MAYBE_DEFERRED', (editor) => {
                        try {
                            form.set(
                                SUMMARY_NARRATIVE_FORM_MODEL_PATH,
                                sanitizeTinyMCEValue(editor.getContent())
                            );

                            form.resetUi();

                            const isInSummaryMode = baseCard.selectors.summaryModeSelector(state, {
                                index,
                            });

                            if (isInSummaryMode) {
                                dispatch(
                                    validateCard({
                                        card: baseCard,
                                        formComponent: form,
                                        options: {
                                            index,
                                        },
                                    })
                                )
                                    .then(() => {
                                        clearTimeout(timeout);
                                        resolve();
                                    })
                                    .catch((err) => {
                                        clearTimeout(timeout);
                                        reject(errorToMessage(err));
                                    });
                            } else {
                                dispatch(
                                    submitCard({
                                        card: baseCard,
                                        options: {
                                            index,
                                        },
                                        formComponent: form,
                                        getPromisesForSubmission: () => {
                                            const { model } = form.getState();
                                            const summaryNarrative =
                                                summaryNarrativeByReportIdSelector(state)(
                                                    reportId
                                                ) || {};
                                            return [
                                                dispatch(
                                                    replaceSummaryNarrativeCardBundle({
                                                        reportId,
                                                        summaryNarrativeCardBundle: {
                                                            summaryNarrative: {
                                                                ...summaryNarrative,
                                                                ...model,
                                                                reportId,
                                                            },
                                                        },
                                                    })
                                                ),
                                            ];
                                        },
                                        onSavingSuccess: () => {
                                            // Always re-sync `formModel` state.
                                            const formModel = dispatch(
                                                buildSummaryNarrativeFormModel(reportId)
                                            );
                                            form.set('', formModel);
                                            onSaveSuccessCallback();
                                        },
                                    })
                                )
                                    .then(() => {
                                        clearTimeout(timeout);
                                        resolve();
                                    })
                                    .catch((err) => {
                                        clearTimeout(timeout);
                                        reject(errorToMessage(err));
                                    });
                            }
                        } catch (err) {
                            clearTimeout(timeout);
                            reject(errorToMessage(err));
                        }
                    });
                });
            };
        },
    },
};

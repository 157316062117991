import React, { FC } from 'react';
import { find, map } from 'lodash';
import { AttributeTypeEnum, EFileTitle } from '@mark43/rms-api';
import sortRelatedRecordsEnum from '~/client-common/core/enums/client/reportSidebarSortRelatedRecordsEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { FormattedDate } from '~/client-common/core/dates/components';
import { formatName } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { sortEFileTitles } from '../../../../e-files/helpers';
import testIds from '../../../../../core/testIds';
import { iconTypes } from '../../../../core/components/Icon';

import {
    Link,
    Icon,
    Subheader,
    LinkedRecordContainer,
    LinkedRecordBodyContainer,
    DisplayDate,
} from './LinkedRecordComponents';

const strings = componentStrings.warrants.warrant.WarrantLinkedArrestRow;

type LinkedEFilesProps = {
    eFileTitles: EFileTitle[];
    activeValue?: keyof typeof sortRelatedRecordsEnum;
    sortType?: keyof typeof sortTypeEnum;
    className?: string;
};

const LinkedEFiles: FC<LinkedEFilesProps> = ({ eFileTitles, activeValue, sortType, className }) => {
    if (!eFileTitles.length) {
        return null;
    }

    const sortedEFileTitles = sortEFileTitles({ eFileTitles, activeValue, sortType });

    return (
        <div data-test-id={testIds.LINKED_E_FILES}>
            {map(sortedEFileTitles, (eFileTitle) => (
                <LinkedEFile eFileTitle={eFileTitle} className={className} />
            ))}
        </div>
    );
};

type LinkedEFileProps = {
    eFileTitle: EFileTitle;
    className?: string;
};

const LinkedEFile: FC<LinkedEFileProps> = ({ eFileTitle, className }) => {
    const {
        efileId,
        createdDateUtc,
        profiles: { owner },
    } = eFileTitle;

    const formattedOwnerName = owner
        ? formatName({ first: owner.firstName, last: owner.lastName }, true)
        : null;

    return (
        <LinkedRecordContainer
            key={efileId}
            className={className}
            data-test-id={testIds.LINKED_E_FILES_DETAILS}
        >
            <Icon type={iconTypes.CASE} />
            <LinkedRecordBodyContainer>
                <Link to={`/e-file/${efileId}/defendants`}>
                    <div data-test-id={testIds.LINKED_E_FILES_LINK}>
                        {getEFileTitleDisplayName(eFileTitle)}
                    </div>
                </Link>
                <Subheader>
                    {formattedOwnerName && (
                        <span>{strings.reportInfoOwnedBy(formattedOwnerName)}</span>
                    )}
                    {formattedOwnerName && createdDateUtc && (
                        <>
                            <span>&nbsp;on&nbsp;</span>
                            <DisplayDate>
                                <FormattedDate
                                    date={createdDateUtc}
                                    format={FormattedDate.FORMATS.SUMMARY_DATE}
                                />
                            </DisplayDate>
                        </>
                    )}
                </Subheader>
            </LinkedRecordBodyContainer>
        </LinkedRecordContainer>
    );
};

function getEFileTitleDisplayName({ attributes, displayName, localId, title }: EFileTitle) {
    const status = find(attributes, { type: AttributeTypeEnum.E_FILE_STATUS.name });

    return joinTruthyValues([
        `${displayName}: #${localId}`,
        title,
        status ? `Status: ${status.displayValue}` : null,
    ]);
}

export default LinkedEFiles;

import React from 'react';
import invariant from 'invariant';
import { get } from 'lodash';
import { AttributeView } from '@mark43/rms-api';

import { getChildTypes } from '../../../../helpers/reactHelpers';
import { formatAttributeValue } from '../utils/attributesHelpers';
import { useItemTypeNames } from '../../../fields/hooks/useItemTypeNames';

const NODE_ENV = process.env.NODE_ENV;

export type FormattedAttributeProps = {
    attribute?: AttributeView;
    format?: keyof typeof FORMATS;
    className?: string;
    children?: (
        formattedAttribute: string | undefined,
        attribute: AttributeView
    ) => React.ReactElement;
};

const FORMATS = {
    ITEM_TYPE: 'ITEM_TYPE',
    VALUE: 'VALUE',
} as const;

type FormattedAttributeExport = React.FC<FormattedAttributeProps> & {
    FORMATS: typeof FORMATS;
};

// TODO: format with parent attributes
/**
 * Use this component to format an attribute
 * @param  [options.children]     (Optional) Function that takes in the result of the formatted string
 */
const FormattedAttribute: FormattedAttributeExport = ({
    attribute,
    className,
    format = 'VALUE',
    children,
}) => {
    const childIsFunction = typeof children === 'function';
    const itemTypeNames = useItemTypeNames();

    if (!attribute) {
        return null;
    }

    if (NODE_ENV !== 'production') {
        const types = children ? getChildTypes(children).join(', ') : '';
        invariant(
            children === undefined || childIsFunction,
            `FormattedAttribute only accepts a function as child, but you have nested "${types}".`
        );
        invariant(
            !(format === 'ITEM_TYPE' && attribute.type !== 'ITEM_TYPE'),
            'FormattedAttribute: format ITEM_TYPE only accepts attributes with type ITEM_TYPE.'
        );
    }

    const formattedAttribute: string | undefined =
        format === FORMATS.ITEM_TYPE
            ? get(itemTypeNames, attribute.id)
            : formatAttributeValue(attribute);

    if (typeof children === 'function') {
        return children(formattedAttribute, attribute);
    }

    return <span className={className}>{formattedAttribute}</span>;
};

FormattedAttribute.FORMATS = FORMATS;

export default FormattedAttribute;

export default {
    sections: [
        {
            shortcuts: [
                {
                    name: 'Open this menu',
                    keys: ['CTRL', '?'],
                },
            ],
        },
        {
            title: 'Advance Search Actions',
            shortcuts: [
                {
                    name: 'Search Form',
                    keys: ['CTRL', 'Enter'],
                },
            ],
        },
        {
            title: 'Report Actions',
            shortcuts: [
                {
                    name: 'Print report',
                    keys: ['CTRL', 'P'],
                },
                {
                    name: 'Put all cards in Edit mode',
                    keys: ['CTRL', 'E'],
                },
                {
                    name: 'Open Add/Edit labels modal',
                    keys: ['CTRL', 'L'],
                },
                {
                    name: 'Open NIBRS admin side panel',
                    keys: ['CTRL', 'I'],
                },
                {
                    name: 'Validate report',
                    keys: ['CTRL', 'SHIFT', 'V'],
                },
            ],
        },
        {
            title: 'Forms',
            shortcuts: [
                {
                    name: 'Copy text',
                    keys: ['CTRL', 'C'],
                },
                {
                    name: 'Paste text',
                    keys: ['CTRL', 'V'],
                },
                {
                    name: 'Search text on page',
                    keys: ['CTRL', 'F'],
                },
                {
                    name: 'Select all text',
                    keys: ['CTRL', 'A'],
                },
                {
                    name: 'Enter current date and time',
                    keys: ['T'],
                },
                {
                    name: "Enter yesterday's date",
                    keys: ['Y'],
                },
                {
                    name: "Enter CAD event start/end date and time",
                    keys: ['E'],
                },
            ],
        },
        {
            title: 'Navigation',
            shortcuts: [
                {
                    name: 'Navigate to next field',
                    keys: ['TAB'],
                },
                {
                    name: 'Navigate to previous field',
                    keys: ['SHIFT', 'TAB'],
                },
                {
                    name: 'Navigate to previous card',
                    keys: ['SHIFT', 'UP'],
                },
                {
                    name: 'Navigate to next card',
                    keys: ['SHIFT', 'DOWN'],
                },
                {
                    name: 'Save and close sidepanel or card',
                    keys: ['CTRL', 'S'],
                },
                {
                    name: 'Cancel and close sidepanel or modal',
                    keys: ['ESC'],
                },
                {
                    name: 'Zoom in',
                    keys: ['CTRL', '+'],
                },
                {
                    name: 'Zoom out',
                    keys: ['CTRL', '-'],
                },
            ],
        },
    ],
};

import { CommandStatusHistory } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const commandStatusHistoryModule = createNormalizedModule<CommandStatusHistory>({
    type: 'commandStatusHistories',
});

// SELECTORS

export const commandStatusHistoriesWhereSelector =
    commandStatusHistoryModule.selectors.entitiesWhereSelector;

// REDUCER
export default commandStatusHistoryModule.reducerConfig;

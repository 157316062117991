import React from 'react';
import { FormElementButtonRadioProps, ArbiterMFTFormElementButtonRadio } from './FormElementButtonRadio';

type BooleanButtonRadioProps = {
    path: string;
    onChange?: FormElementButtonRadioProps['onChange'];
};

const booleanOptions = [
    { value: true, display: 'Yes' },
    { value: false, display: 'No' },
];

export const ArbiterMFTBooleanButtonRadio: React.FC<BooleanButtonRadioProps> = (props) => (
    <ArbiterMFTFormElementButtonRadio options={booleanOptions} {...props} />
);

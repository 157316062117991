import { LocationExternalLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'locationExternalLinks';
const LOCATION_EXTERNAL_LINK_MODEL_KEYS = ['entityId', 'entityType', 'linkType', 'locationId'];

const locationExternalLinksModule = createLinkModule<LocationExternalLink>({
    type: NEXUS_STATE_PROP,
    keys: LOCATION_EXTERNAL_LINK_MODEL_KEYS,
});

// ACTION TYPES

export const locationExternalLinksWhereSelector =
    locationExternalLinksModule.selectors.linksWhereSelector;

// REDUCER
export default locationExternalLinksModule.reducerConfig;

import { FullOffenseCode, OffenseCode } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { filter, flatten, get, map, reduce } from 'lodash';

import { NEXUS_STATE_PROP as OFFENSE_CODES_NIBRS_CODE_LINKS_PROP } from '../../../offense-code-nibrs-code-links/state/data';
import { NEXUS_STATE_PROP as UK_OFFENSE_CODE_EXTENSIONS_PROP } from '../../../uk-offense-code-extensions/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_HOC_CATEGORY_LINKS_PROP } from '../../../offense-code-hoc-category-links/state/data';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getOffenseCodesResource from '../../resources/offenseCodesResource';
import { nibrsOffenseCodesSelector } from '../../../nibrs-offense-codes/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'offenseCodes';

const offenseCodeModule = createNormalizedModule<OffenseCode>({
    type: NEXUS_STATE_PROP,
});

export const storeOffenseCodes = offenseCodeModule.actionCreators.storeEntities;

export const offenseCodesSelector = offenseCodeModule.selectors.entitiesSelector;
export const offenseCodeByIdSelector = offenseCodeModule.selectors.entityByIdSelector;

export const nibrsOffenseCodeForOffenseCodeIdSelector = createSelector(
    offenseCodesSelector,
    nibrsOffenseCodesSelector,
    (offenseCodes, nibrsOffenseCodes) => (offenseCodeId: number) => {
        const nibrsOffenseCodeId = get(offenseCodes[offenseCodeId], 'nibrsOffenseCodeId');
        if (!nibrsOffenseCodeId) {
            return undefined;
        }
        return nibrsOffenseCodes[nibrsOffenseCodeId];
    }
);

const SEARCH_FOR_OFFENSE_CODES_SUCCESS = 'SEARCH_FOR_OFFENSE_CODES_SUCCESS';

function searchForOffenseCodesSuccess(offenseCodes: OffenseCode[]) {
    return { type: SEARCH_FOR_OFFENSE_CODES_SUCCESS, payload: offenseCodes };
}

export function searchForOffenseCodes({
    q,
    includeExpired,
    includeDisabled,
    from,
    size,
    flags = [],
    statuteCodeSetAttrIds,
    onSuccess,
    onError,
}: {
    q?: string;
    includeExpired?: boolean;
    includeDisabled?: boolean;
    from?: number;
    size?: number;
    flags?: string[];
    statuteCodeSetAttrIds?: number[];
    onSuccess?: (offenseCodes: OffenseCode[]) => void;
    onError?: (err: Error) => void;
}): ClientCommonAction<Promise<void>> {
    const offenseCodesResource = getOffenseCodesResource();

    return (dispatch, getState, { nexus }) => {
        return offenseCodesResource
            .searchForFullOffenseCodes({
                q,
                includeExpired,
                includeDisabled,
                ...reduce<string, { [key: string]: boolean }>(
                    flags,
                    (acc, flag) => {
                        const transformedKey = flag.replace(/^is/, 'includeIs');
                        acc[transformedKey] = true;
                        return acc;
                    },
                    {}
                ),
                statuteCodeSetAttrIds,
                from,
                size,
            })
            .then((fullOffenseCodes?: FullOffenseCode[]) => {
                // TODO: Remove this check after https://mark43.atlassian.net/browse/RND-7099 is complete
                if (!fullOffenseCodes) {
                    return;
                }
                const offenseCodes = fullOffenseCodes.map((foc) => foc.offenseCode);
                const offenseCodeNibrsCodeLinks = fullOffenseCodes.map(
                    (foc) => foc.offenseCodeNibrsCodeLinks
                );
                const offenseCodeHocCategoryLinks = fullOffenseCodes.map(
                    (foc) => foc.offenseCodeHocCategoryLinks
                );
                const ukOffenseCodeExtensions = map(
                    filter(fullOffenseCodes, 'ukOffenseCodesData'),
                    'ukOffenseCodesData'
                );

                if (onSuccess) {
                    onSuccess(offenseCodes);
                }

                return dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: offenseCodes,
                            [OFFENSE_CODES_NIBRS_CODE_LINKS_PROP]: flatten(
                                offenseCodeNibrsCodeLinks
                            ),
                            [OFFENSE_CODES_HOC_CATEGORY_LINKS_PROP]: flatten(
                                offenseCodeHocCategoryLinks
                            ),
                            [UK_OFFENSE_CODE_EXTENSIONS_PROP]: ukOffenseCodeExtensions,
                        },
                        searchForOffenseCodesSuccess(offenseCodes)
                    )
                );
            })
            .catch(onError);
    };
}

export default offenseCodeModule.reducerConfig;

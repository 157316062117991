import React from 'react';
import { lifecycleOptions } from 'markformythree';
import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import * as fields from '~/client-common/core/enums/universal/fields';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import Row from '../../../../core/components/Row';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTAttributeButtonRadio } from '../../../../core/forms/components/button-radios/AttributeButtonRadio';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import IndentedFields from '../../../../core/components/IndentedFields';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { UseOfForceExtendedBooleanSelect } from '../../../../core/forms/components/selects/UseOfForceExtendedBooleanSelect';
import UseOfForceSubjectDeEscalationNItemsForm from './UseOfForceSubjectDeEscalationNItemsForm';

type UseOfForceSubjectCardFormProps = {
    nameSummaryViewWrapper: React.ReactNode;
    index: number;
};

const UseOfForceSubjectCardForm: React.FC<UseOfForceSubjectCardFormProps> = (props) => {
    const { nameSummaryViewWrapper, index } = props;
    const numShotsFiredAndHit = (
        <>
            <Row>
                <ArbiterMFTText path="numShotsFired" length="md" />
            </Row>
            <Row>
                <ArbiterMFTText path="numShotsHit" length="md" />
            </Row>
        </>
    );

    const officerInjuryInfo = (
        <>
            <Row>
                <ArbiterMFTAttributeSelect
                    path="officerInjurySeverityAttrId"
                    length="md"
                    attributeType={AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_SEVERITY.name}
                />
                <IndentedFields>
                    <ArbiterMFTText path="officerInjurySeverityDescription" length="md" />
                </IndentedFields>
            </Row>

            <Row>
                <ArbiterMFTAttributeSelect
                    path="officerInjuryTypeAttrIds"
                    length="md"
                    attributeType={AttributeTypeEnum.USE_OF_FORCE_OFFICER_INJURY_TYPE.name}
                    multiple={true}
                />
                <IndentedFields>
                    <ArbiterMFTText path="officerInjuryTypeDescription" length="md" />
                </IndentedFields>
            </Row>
            <Row>
                <IndentedFields>
                    <ArbiterMFTBooleanSelect
                        path="officerDeceasedAsResultOfUseOfForce"
                        length="md"
                    />
                </IndentedFields>
            </Row>
        </>
    );

    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name}
            context={RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name}
            index={index}
            render={() => (
                <>
                    <CardSection>
                        <FeatureFlagged
                            flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED"
                            fallback={
                                <>
                                    {/*
                                        Remove everything in this <FeatureFlagged /> when the
                                        RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED FF is enabled everywhere
                                    */}
                                    <Row>
                                        <ArbiterMFTBooleanSelect
                                            path="arrested"
                                            length="md"
                                            autoFocus={true}
                                        />
                                    </Row>
                                    <Row>
                                        <IndentedFields>
                                            <ArbiterMFTText path="charges" length="md" />
                                        </IndentedFields>
                                    </Row>
                                </>
                            }
                        />
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="firearmDischargeIntentional"
                                length="md"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="firearmDischargeAccidental"
                                length="md"
                            />
                        </Row>
                        <FeatureFlagged
                            flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED"
                            fallback={numShotsFiredAndHit}
                        >
                            <Row>
                                <UseOfForceExtendedBooleanSelect
                                    path="shotsFiredAttrId"
                                    length="md"
                                />
                            </Row>
                            <IndentedFields>{numShotsFiredAndHit}</IndentedFields>
                        </FeatureFlagged>
                    </CardSection>
                    <CardSection
                        fieldName={
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_USE_OF_FORCE_LINK_TYPE
                        }
                    >
                        {nameSummaryViewWrapper}
                        <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path="threatDirectedAtAttrId"
                                    length="md"
                                    attributeType={
                                        AttributeTypeEnum.USE_OF_FORCE_SUBJECT_THREAT_DIRECTED_AT
                                            .name
                                    }
                                />
                            </Row>
                            <Row>
                                <UseOfForceExtendedBooleanSelect
                                    path="subjectImpairmentAttrId"
                                    length="md"
                                />
                            </Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="impairmentTypeAttrIds"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum.USE_OF_FORCE_SUBJECT_IMPAIRMENTS.name
                                        }
                                        multiple={true}
                                    />
                                </Row>
                            </IndentedFields>
                        </FeatureFlagged>
                        <Row>
                            <ArbiterMFTBooleanSelect path="deEscalationAttempted" length="md" />
                        </Row>
                        <FeatureFlagged flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED">
                            <IndentedFields>
                                <UseOfForceSubjectDeEscalationNItemsForm
                                    formIndex={index}
                                    path="useOfForceSubjectDeEscalations"
                                />
                            </IndentedFields>
                        </FeatureFlagged>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="subjectPerceivedArmedWithAttrId"
                                length="md"
                                attributeType={
                                    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_PERCEIVED_ARMED_WITH.name
                                }
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="subjectConfirmedArmedWithAttrId"
                                length="md"
                                attributeType={
                                    AttributeTypeEnum.USE_OF_FORCE_SUBJECT_CONFIRMED_ARMED_WITH.name
                                }
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="officerAttemptedToDisarmSubject"
                                length="md"
                            />
                        </Row>
                        <FeatureFlagged
                            flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED"
                            fallback={
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        multiple={true}
                                        path="subjectActionsAttrIds"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum.USE_OF_FORCE_SUBJECT_ACTIONS.name
                                        }
                                    />
                                </Row>
                            }
                        >
                            <Row>
                                <UseOfForceExtendedBooleanSelect
                                    path="resistedAttrId"
                                    length="md"
                                />
                            </Row>
                            <Row>
                                <IndentedFields>
                                    <ArbiterMFTAttributeSelect
                                        multiple={true}
                                        path="subjectActionsAttrIds"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum.USE_OF_FORCE_SUBJECT_ACTIONS.name
                                        }
                                    />
                                </IndentedFields>
                            </Row>
                        </FeatureFlagged>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="subjectActionsDescription" length="md" />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="subjectBehaviorWasErratic" length="md" />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTAttributeSelect
                                    path="subjectDetailsAttrIds"
                                    length="md"
                                    attributeType={
                                        AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DETAILS.name
                                    }
                                    multiple={true}
                                />
                                <IndentedFields>
                                    <ArbiterMFTText path="subjectDetailsDescription" length="md" />
                                </IndentedFields>
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="officerUsedForceOnSubject" length="md" />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="officerForceTowardsSubjectAttrIds"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum
                                                .USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT.name
                                        }
                                        multiple={true}
                                    />
                                    <IndentedFields>
                                        <ArbiterMFTText
                                            path="officerForceTowardsSubjectDescription"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </Row>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="officerForceTowardsSubjectLocationAttrIds"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum
                                                .USE_OF_FORCE_OFFICER_FORCE_TOWARDS_SUBJECT_LOCATION
                                                .name
                                        }
                                        multiple={true}
                                    />
                                    <IndentedFields>
                                        <ArbiterMFTText
                                            path="officerForceTowardsSubjectLocationDescription"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </Row>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="subjectInjurySeverityAttrId"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_SEVERITY
                                                .name
                                        }
                                    />
                                    <IndentedFields>
                                        <ArbiterMFTText
                                            path="subjectInjurySeverityDescription"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </Row>
                                <IndentedFields>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="subjectInjuryTypeAttrIds"
                                            length="md"
                                            attributeType={
                                                AttributeTypeEnum.USE_OF_FORCE_SUBJECT_INJURY_TYPE
                                                    .name
                                            }
                                            multiple={true}
                                        />
                                        <IndentedFields>
                                            <ArbiterMFTText
                                                path="subjectInjuryTypeDescription"
                                                length="md"
                                            />
                                        </IndentedFields>
                                    </Row>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            path="medicalAidReceivedAttrId"
                                            length="md"
                                            attributeType={
                                                AttributeTypeEnum.USE_OF_FORCE_MEDICAL_AID_RECEIVED
                                                    .name
                                            }
                                        />
                                        <IndentedFields>
                                            <ArbiterMFTText
                                                path="medicalAidReceivedDescription"
                                                length="md"
                                            />
                                        </IndentedFields>
                                    </Row>
                                    <IndentedFields>
                                        <ArbiterMFTBooleanSelect
                                            path="subjectDeceasedAsResultOfUseOfForce"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </IndentedFields>
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="subjectUsedForceOnOfficer" length="md" />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="subjectForceTowardsOfficerAttrIds"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum
                                                .USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER.name
                                        }
                                        multiple={true}
                                    />
                                    <IndentedFields>
                                        <ArbiterMFTText
                                            path="subjectForceTowardsOfficerDescription"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </Row>
                                <Row>
                                    <ArbiterMFTAttributeSelect
                                        path="subjectForceTowardsOfficerLocationAttrIds"
                                        length="md"
                                        attributeType={
                                            AttributeTypeEnum
                                                .USE_OF_FORCE_SUBJECT_FORCE_TOWARDS_OFFICER_LOCATION
                                                .name
                                        }
                                        multiple={true}
                                    />
                                    <IndentedFields>
                                        <ArbiterMFTText
                                            path="subjectForceTowardsOfficerLocationDescription"
                                            length="md"
                                        />
                                    </IndentedFields>
                                </Row>
                            </IndentedFields>
                        </Row>
                        <FeatureFlagged
                            flag="RMS_USE_OF_FORCE_ENHANCEMENTS_ENABLED"
                            fallback={officerInjuryInfo}
                        >
                            <Row>
                                <UseOfForceExtendedBooleanSelect
                                    path="officerInjuredAttrId"
                                    length="md"
                                />
                            </Row>
                            <Row>
                                <IndentedFields>
                                    <ArbiterMFTText
                                        path="officerAssaultHomicideIncidentNumber"
                                        length="md"
                                    />
                                </IndentedFields>
                            </Row>
                            <Row>
                                <ArbiterMFTAttributeButtonRadio
                                    path="nibrsIncidentNumberPuAttrId"
                                    attributeType={
                                        AttributeTypeEnum.USE_OF_FORCE_PENDING_UNKNOWN.name
                                    }
                                />
                            </Row>
                            <IndentedFields>{officerInjuryInfo}</IndentedFields>
                            <Row>
                                <ArbiterMFTAttributeSelect
                                    path="subjectDispositionAttrId"
                                    length="md"
                                    attributeType={
                                        AttributeTypeEnum.USE_OF_FORCE_SUBJECT_DISPOSITION.name
                                    }
                                />
                            </Row>
                            <Row>
                                <IndentedFields>
                                    <ArbiterMFTText path="charges" length="md" />
                                </IndentedFields>
                            </Row>
                        </FeatureFlagged>
                        <Row>
                            <UseOfForceExtendedBooleanSelect path="chargedAttrId" length="md" />
                        </Row>
                    </CardSection>
                </>
            )}
        />
    );
};

export default UseOfForceSubjectCardForm;

export function getRotationAreaForListRequest({
    noRotationAreaOptions,
    formRotationArea,
    currentRotationArea,
    rotationListAreasNames,
}: {
    noRotationAreaOptions: boolean;
    formRotationArea: string | undefined;
    currentRotationArea: string | undefined;
    rotationListAreasNames: string[];
}) {
    if (noRotationAreaOptions) {
        return formRotationArea;
    }

    if (rotationListAreasNames.includes(currentRotationArea ?? '')) {
        return currentRotationArea;
    }

    return rotationListAreasNames[0];
}

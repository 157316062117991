import { EntityTypeEnum, HistoryEventTypeEnum, RmsHistoryEvent } from '@mark43/rms-api';
import { compact, flatten, map } from 'lodash';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import { DateTimeFormatter } from '~/client-common/core/dates/utils/dateHelpers';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    TASK_ASSIGNEE_ROLE_ID,
    TASK_DESCRIPTION,
    TASK_PRIORITY_ATTR_ID,
    TASK_STATUS_ATTR_ID,
    TASK_TITLE,
    TASK_TYPE_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

const historyStrings = componentStrings.tasks.core.TaskSidePanel.HistorySection;

export const formatTaskHistories = (
    taskHistories: RmsHistoryEvent[],
    formatMiniUserById: ReturnType<typeof formatMiniUserByIdSelector>,
    formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>,
    allRoleFormatsByRoleId: ReturnType<typeof allRoleFormatsByRoleIdSelector>,
    fieldFormatter: ReturnType<typeof formatFieldByNameSelector>,
    dateTimeFormatter: DateTimeFormatter
) => {
    const fieldDisplayNames = {
        TASK_TITLE: fieldFormatter(TASK_TITLE),
        TASK_TYPE_ATTR_ID: fieldFormatter(TASK_TYPE_ATTR_ID),
        TASK_PRIORITY_ATTR_ID: fieldFormatter(TASK_PRIORITY_ATTR_ID),
        TASK_STATUS_ATTR_ID: fieldFormatter(TASK_STATUS_ATTR_ID),
        TASK_ASSIGNEE_ROLE_ID: fieldFormatter(TASK_ASSIGNEE_ROLE_ID),
        TASK_DESCRIPTION: fieldFormatter(TASK_DESCRIPTION),
    };
    const formattedHistoryEvents = compact(
        flatten(
            map(taskHistories, (taskHistory) => {
                const changedBy = formatMiniUserById(taskHistory.changedBy);
                const formattedCreatedDateUtc = `on ${dateTimeFormatter.formatSummaryDate(
                    taskHistory.timestampUtc
                )}`;
                const historyEventType = taskHistory.historyEventType;
                if (
                    historyEventType === HistoryEventTypeEnum.ENTITY_CREATION.name &&
                    taskHistory.primaryType === EntityTypeEnum.TASK.name
                ) {
                    return historyStrings.taskCreateChange(changedBy, formattedCreatedDateUtc);
                } else if (
                    historyEventType === HistoryEventTypeEnum.LINK_CREATION.name ||
                    historyEventType === HistoryEventTypeEnum.LINK_DELETE.name
                ) {
                    return formatLinkChange(
                        taskHistory,
                        changedBy,
                        formattedCreatedDateUtc,
                        historyEventType === HistoryEventTypeEnum.LINK_DELETE.name
                    );
                } else if (taskHistory.primaryType === EntityTypeEnum.FILE.name) {
                    return historyStrings.attachmentEditChange(
                        changedBy,
                        taskHistory.primaryName,
                        formattedCreatedDateUtc
                    );
                } else if (historyEventType === HistoryEventTypeEnum.FIELDS_CHANGED.name) {
                    return formatFieldChange(
                        taskHistory,
                        changedBy,
                        formattedCreatedDateUtc,
                        formatAttributeById,
                        allRoleFormatsByRoleId,
                        fieldDisplayNames,
                        dateTimeFormatter
                    );
                }
                return undefined;
            })
        )
    );
    return formattedHistoryEvents;
};

const formatLinkChange = (
    taskHistory: RmsHistoryEvent,
    changedBy: string,
    formattedCreatedDateUtc: string,
    isLinkDeleted: boolean
) => {
    let formattedHistoryEvent: string;
    const linkString = isLinkDeleted ? 'unlinked' : 'linked';
    if (taskHistory.primaryType === EntityTypeEnum.ITEM_PROFILE.name) {
        formattedHistoryEvent = historyStrings.linkChange(
            changedBy,
            linkString,
            taskHistory.primaryName,
            'item',
            formattedCreatedDateUtc
        );
    } else if (taskHistory.primaryType === EntityTypeEnum.PERSON_PROFILE.name) {
        formattedHistoryEvent = historyStrings.linkChange(
            changedBy,
            linkString,
            taskHistory.primaryName,
            'person',
            formattedCreatedDateUtc
        );
    } else {
        formattedHistoryEvent = historyStrings.linkChange(
            changedBy,
            linkString,
            taskHistory.secondaryName,
            'attachment',
            formattedCreatedDateUtc
        );
    }
    return formattedHistoryEvent;
};

const formatFieldChange = (
    taskHistory: RmsHistoryEvent,
    changedBy: string,
    formattedCreatedDateUtc: string,
    formatAttributeById: ReturnType<typeof formatAttributeByIdSelector>,
    allRoleFormatsByRoleId: ReturnType<typeof allRoleFormatsByRoleIdSelector>,
    fieldDisplayNames: Record<string, string>,
    dateTimeFormatter: DateTimeFormatter
) => {
    const formattedHistoryEvents = map(taskHistory.changeSet, (change) => {
        let newFieldValue = change.newValue;
        const fieldName = change.fieldName;

        if (
            fieldName === fieldDisplayNames.TASK_ASSIGNEE_ROLE_ID &&
            typeof newFieldValue === 'string'
        ) {
            newFieldValue = allRoleFormatsByRoleId(parseInt(newFieldValue)).default;
        } else if (fieldName === historyStrings.dueDate && typeof newFieldValue === 'string') {
            newFieldValue = `[${dateTimeFormatter.formatDateTime(newFieldValue)}]`;
        } else if (
            (fieldName === fieldDisplayNames.TASK_PRIORITY_ATTR_ID ||
                fieldName === fieldDisplayNames.TASK_TYPE_ATTR_ID ||
                fieldName === fieldDisplayNames.TASK_STATUS_ATTR_ID) &&
            typeof newFieldValue === 'number'
        ) {
            newFieldValue = `[${formatAttributeById(newFieldValue)}]`;
        } else if (fieldName === fieldDisplayNames.TASK_TITLE) {
            newFieldValue = `[${newFieldValue}]`;
        } else if (fieldName === fieldDisplayNames.TASK_DESCRIPTION) {
            return historyStrings.fieldChangeWithoutValue(
                changedBy,
                change.fieldName,
                formattedCreatedDateUtc
            );
        }

        return historyStrings.fieldChange(
            changedBy,
            change.fieldName,
            newFieldValue ?? '[]',
            formattedCreatedDateUtc
        );
    });
    return formattedHistoryEvents;
};

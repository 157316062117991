import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import { copyItemProfileData } from '../state/ui';
import testIds from '../../../../core/testIds';

const strings = componentStrings.evidence.itemSplit.CopyDataButton;

const Button = styled(_Button)`
    margin: 0;

    && .react-icon-right {
        float: right;
    }
`;

function CopyDataButton({ onCopyData }) {
    return (
        <Button
            className={buttonTypes.SECONDARY}
            onClick={onCopyData}
            iconRight={<Icon type={iconTypes.PULLDATA_RIGHTARROW} />}
            testId={testIds.COPY_DATA_BUTTON}
        >
            {strings.label}
        </Button>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onCopyData: () => dispatch(copyItemProfileData(true)),
});

/**
 * Copy Data Button for the Item Split Side Panel
 */
export default connect(null, mapDispatchToProps)(CopyDataButton);

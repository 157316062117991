import React from 'react';
import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { compose } from 'recompose';
import pluralize from 'pluralize';
import {
    cloneDeep,
    flatMap,
    map,
    flowRight,
    isUndefined,
    groupBy,
    orderBy,
    findIndex,
} from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    CAD_TICKET_CAD_AGENCY_EVENT_NUMBER,
    DISPLAY_ONLY_CASE,
    DISPLAY_ONLY_CAD_LABEL,
    DISPLAY_ONLY_ORGANIZATION_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import withFields from '~/client-common/core/fields/components/withFields';
import testIds from '../../../../core/testIds';
import escapeRegexCharacters from '../helpers/escapeRegexCharacters';
import { InputLoading } from '../../../../legacy-redux/components/core/Loading';
import { ResultsGrouping } from '../../../../legacy-redux/components/core/tables/ResultsGrouping';
import { QUICK_SEARCH_QUERY_SIZE } from '../config';
import QuickSearchResultsSectionHeader from './QuickSearchResultsSectionHeader';
import QuickSearchResultSectionItemWrapper from './QuickSearchResultSectionItemWrapper';
import QuickSearchResultsSectionHeaderCount from './QuickSearchResultsSectionHeaderCount';
import SectionBottomButton from './layout/SectionBottomButton';

const { quickSearch: quickSearchStrings } = componentStrings;

class QuickSearchResultsSection extends React.Component {
    constructor(...args) {
        super(...args);
        this.triggerLoadMore = this.triggerLoadMore.bind(this);
        this.createHandleResultClick = this.createHandleResultClick.bind(this);
    }

    // using `shouldComponentUpdate` directly because
    // this is already a class and wrapping it again with
    // `recompose`'s `onlyUpdateForKeys` would only add
    // to the rendering time.
    shouldComponentUpdate(nextProps) {
        // we do not check for `type`, because it is always
        // directly tied to the results
        return (
            this.props.results !== nextProps.results ||
            this.props.isSectionLoading !== nextProps.isSectionLoading
        );
    }

    triggerLoadMore() {
        this.props.triggerQuickSearchLoadMoreForType({
            type: this.props.type,
            query: this.props.query,
            excludeExternalAgencyResults: this.props.excludeExternalAgencyResults,
        });
    }

    createHandleResultClick(item, index) {
        return (event) => {
            this.props.onResultClick({ event, index, item, itemType: this.props.type });
        };
    }

    createNewResultItemsFromRens(items) {
        items = flatMap(items, (item) =>
            item.rens?.length === 0
                ? item
                : map(item.rens, (ren) => {
                      const newItem = cloneDeep(item);
                      newItem['ren'] = ren; // 'ren' is what is displayed
                      newItem['reportingEventNumber'] = ren; // 'reportingEventNumber' is what is sent in API request
                      return newItem;
                  })
        );
        return items;
    }

    render() {
        const {
            results,
            type,
            isSectionLoading,
            applicationSettings,
            formatFieldByName,
            fieldDisplayNames,
        } = this.props;

        const getEntityType = (type) => {
            const label = quickSearchStrings.core.elasticSearchTypeHeader[type];
            if (type === ElasticSearchTypeEnum.CASE.name) {
                return label(pluralize(fieldDisplayNames.DISPLAY_ONLY_CASE));
            }
            if (type === ElasticSearchTypeEnum.CAD_TICKET.name) {
                return label(fieldDisplayNames.DISPLAY_ONLY_CAD_LABEL);
            }
            if (type === ElasticSearchTypeEnum.ORGANIZATION.name) {
                return label(pluralize(fieldDisplayNames.DISPLAY_ONLY_ORGANIZATION_LABEL));
            }
            if (type === EntityTypeEnum.BOOKING.name) {
                return pluralize(quickSearchStrings.core.elasticSearchTypeHeader.BOOKING);
            }

            return label;
        };
        const entityType = getEntityType(type);
        const { query, totalCount } = results;
        let { items } = results;
        const { from, size } = query;
        const amountLeft = totalCount - (from + size);
        const amountToLoad =
            amountLeft > QUICK_SEARCH_QUERY_SIZE ? QUICK_SEARCH_QUERY_SIZE : amountLeft;
        const canLoadMore = amountLeft > 0;
        const escapedQuery = escapeRegexCharacters(this.props.query);

        const isCadTicket = type === ElasticSearchTypeEnum.CAD_TICKET.name;
        let groupingField;
        let groupingFieldName;
        if (isCadTicket && applicationSettings?.CAD_MULTI_REN_GENERATION_ENABLED) {
            items = this.createNewResultItemsFromRens(items);
            groupingField = 'agencyEventNumber';
            groupingFieldName = formatFieldByName(CAD_TICKET_CAD_AGENCY_EVENT_NUMBER);
        }

        let groupedItems = { nonGroupedData: items };
        if (!isUndefined(groupingField)) {
            groupedItems = groupBy(items, groupingField);
            // Have to sort here since groupBy will reorder the items.
            groupedItems = orderBy(groupedItems, (groupedItem) => {
                return findIndex(items, [groupingField, groupedItem[0][groupingField]]);
            });
        }

        const maxIndex = items.length - 1;
        const isOnly = maxIndex === 0;
        let currentIndex = 0;
        return (
            <div data-test-id={testIds.QUICKSEARCH_RESULT_SECTION}>
                <QuickSearchResultsSectionHeader>
                    {`${entityType} `}
                    <QuickSearchResultsSectionHeaderCount>
                        ({totalCount})
                    </QuickSearchResultsSectionHeaderCount>
                </QuickSearchResultsSectionHeader>
                {/*
                    This could be further optimized, by moving the mapping into
                    a separate component and preventing that component from
                    updating if nothing relevant changed
                */}
                {map(groupedItems, (item, key) => {
                    return (
                        <>
                            <ResultsGrouping
                                showDivider={!isUndefined(groupingField)}
                                showCollapseButton={true}
                                groupingDisplay={`${groupingFieldName} ${
                                    item[0][groupingField] ?? ''
                                }`}
                                key={`${type}~${key}`}
                            >
                                {map(item, (elem) => {
                                    const index = currentIndex++;
                                    const isLast = index === maxIndex;
                                    return (
                                        <>
                                            <QuickSearchResultSectionItemWrapper
                                                key={
                                                    isCadTicket
                                                        ? `${type}~${elem.id}~${elem.ren}`
                                                        : `${type}~${elem.id}`
                                                }
                                                isOnly={isOnly}
                                                isLast={isLast}
                                                itemType={type}
                                                item={elem}
                                                onClick={this.createHandleResultClick(elem, index)}
                                                query={escapedQuery}
                                                type={type}
                                            />
                                        </>
                                    );
                                })}
                            </ResultsGrouping>
                        </>
                    );
                })}
                {canLoadMore && (
                    <SectionBottomButton
                        type="button"
                        onClick={this.triggerLoadMore}
                        disabled={isSectionLoading}
                        data-test-id={testIds.QUICK_SEARCH_LOAD_MORE}
                        data-test-type={type}
                    >
                        {quickSearchStrings.QuickSearchResultsSection.loadMore(
                            amountToLoad,
                            entityType
                        )}
                        {isSectionLoading && <InputLoading />}
                    </SectionBottomButton>
                )}
            </div>
        );
    }
}

export default compose(
    flowRight(
        connect(
            createStructuredSelector({
                applicationSettings: applicationSettingsSelector,
                formatFieldByName: formatFieldByNameSelector,
            })
        ),
        renderOnlyIf((props) => props.results && props.results.items.length)
    ),
    withFields([DISPLAY_ONLY_CASE, DISPLAY_ONLY_CAD_LABEL, DISPLAY_ONLY_ORGANIZATION_LABEL])
)(QuickSearchResultsSection);

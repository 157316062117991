import { createSelector } from 'reselect';
import { AssistingOfficer } from '@mark43/rms-api';

import createLinkModule from '../../../../utils/createLinkModule';
import getAssistingOfficersResource from '../../resources/assistingOfficersResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'assistingOfficers';

const assistingOfficersModule = createLinkModule<AssistingOfficer>({
    type: NEXUS_STATE_PROP,
    keys: ['officerId', 'reportId', 'assistTypeAttrId'],
});

// ACTIONS

const replaceAssistingOfficersWhere = assistingOfficersModule.actionCreators.replaceLinksWhere;

const SET_ASSISTING_OFFICERS_START = 'assisting-officers/SET_ASSISTING_OFFICERS_START';
const SET_ASSISTING_OFFICERS_SUCCESS = 'assisting-officers/SET_ASSISTING_OFFICERS_SUCCESS';
const SET_ASSISTING_OFFICERS_FAILURE = 'assisting-officers/SET_ASSISTING_OFFICERS_FAILURE';

function setAssistingOfficersStart(assistingOfficers: AssistingOfficer[]) {
    return {
        type: SET_ASSISTING_OFFICERS_START,
        payload: assistingOfficers,
    };
}

function setAssistingOfficersSuccess(assistingOfficers: AssistingOfficer[]) {
    return {
        type: SET_ASSISTING_OFFICERS_SUCCESS,
        payload: assistingOfficers,
    };
}

function setAssistingOfficersFailure(message: string) {
    return {
        type: SET_ASSISTING_OFFICERS_FAILURE,
        payload: message,
    };
}

export function setAssistingOfficers(
    reportId: number,
    assistingOfficers: AssistingOfficer[]
): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        const resource = getAssistingOfficersResource();
        dispatch(setAssistingOfficersStart(assistingOfficers));
        return resource
            .setAssistingOfficers(reportId, assistingOfficers)
            .then((assistingOfficers: AssistingOfficer[]) => {
                dispatch(setAssistingOfficersSuccess(assistingOfficers));
                dispatch(replaceAssistingOfficersWhere({ reportId }, assistingOfficers));
            })
            .catch((err: Error) => {
                dispatch(setAssistingOfficersFailure(err.message));
                throw err;
            });
    };
}

// SELECTORS
export const assistingOfficersSelector = assistingOfficersModule.selectors.linksSelector;
export const assistingOfficersWhereSelector = assistingOfficersModule.selectors.linksWhereSelector;
export const assistingOfficersForReportIdSelector = createSelector(
    assistingOfficersWhereSelector,
    (assistingOfficersWhere) => (reportId: number) => assistingOfficersWhere({ reportId })
);

// REDUCER
export default assistingOfficersModule.reducerConfig;

import { createSelector } from 'reselect';
import { Shapefile } from '@mark43/rms-api';
import { shapefileByIdSelector } from '~/client-common/core/domain/shapefiles/state/data';

export const shapefilePropertyOptionsByIdSelector = createSelector(
    shapefileByIdSelector,
    (shapefileById: (key: string | number | undefined) => Shapefile | undefined) => {
        return (shapefileId: string | number) => {
            const shapefile = shapefileById(shapefileId);

            if (!shapefile) {
                return [];
            }

            const allShapefilePropertyNames = shapefile.properties.flatMap(
                (shapefilePropertyEntry) => Object.keys(shapefilePropertyEntry)
            );

            return Array.from(new Set<string>(allShapefilePropertyNames)).map((propertyName) => ({
                display: propertyName,
                value: propertyName,
            }));
        };
    }
);

import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import HelpText from '../../../legacy-redux/components/core/HelpText';
import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import { NibrsErrorCounts } from '../types';

const labels = componentStrings.compliance.ComplianceErrorsCounts.labels;

const Column = styled.div`
    flex: 0 1 auto;
    white-space: nowrap;
    width: min-content;
    padding: 0 18px 0 16px;
    vertical-align: top;
    height: 40px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    border-left: ${(props) => `1px solid ${props.theme.colors.lightGrey}`};
    font-size: var(--arc-fontSizes-md);

    &:first-child {
        border-left: 0;
        padding-left: 0;
    }
`;

const LabelWrapper = styled.div`
    ${(props: { condensed?: boolean; width?: string }) =>
        props.condensed ? '' : `width: ${props.width || '165px'};`}
    display: flex;
    vertical-align: top;
    margin-right: 10px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const CountItemWrapper = styled.div`
    display: flex;
    margin-bottom: 2px;
`;

const ErrorCountsWrapper = styled.div`
    margin-bottom: 16px;
    width: 70%;
    display: flex;
    vertical-align: top;
`;

const CountItem: React.FC<{
    condensed?: boolean;
    label: string;
    labelWidth?: string;
    labelSuffix?: string;
}> = (props) => (
    <CountItemWrapper>
        <LabelWrapper condensed={props.condensed} width={props.labelWidth}>
            {props.label}
            {props.labelSuffix || ': '}
        </LabelWrapper>
        {props.children}
    </CountItemWrapper>
);

const ErrorRateWrapper = styled.div`
    text-align: center;
`;

const HelpTextWrapper = styled.div`
    display: inline-block;
    margin-top: -2px;
    vertical-align: top;
`;

const TestIdWrapper = styled.div`
    display: flex;
`;

const ComplianceErrorsCounts = (props: { data?: NibrsErrorCounts; testId?: string }) => {
    const { isNibrs } = useComplianceExportContext();
    const { data, testId } = props;

    const isHidden = !isNibrs;

    if (isHidden) {
        return <ErrorCountsWrapper />;
    }

    return (
        <TestIdWrapper data-test-id={testId}>
            <Column>
                <CountItem label={labels.totalIncidents}>{data?.totalIncidentNum}</CountItem>
                <CountItem label={labels.withErrors}>{data?.totalIncidentErrorNum}</CountItem>
            </Column>
            <Column>
                <CountItem label={labels.totalArrests}>{data?.totalArrestNum}</CountItem>
                <CountItem label={labels.withErrors}>{data?.totalArrestErrorNum}</CountItem>
            </Column>
            <Column>
                <CountItem condensed={true} label={labels.totalErrorRate} labelSuffix=" ">
                    <HelpTextWrapper>
                        <HelpText content={labels.errorRateHelpText} />
                    </HelpTextWrapper>
                </CountItem>
                {!!data?.errorRate && (
                    <ErrorRateWrapper>{labels.errorRate(data?.errorRate)}</ErrorRateWrapper>
                )}
            </Column>
        </TestIdWrapper>
    );
};

export default ComplianceErrorsCounts;

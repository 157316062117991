let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Files Resource',
        methods: {
            getFile(fileId) {
                return req({
                    method: 'GET',
                    url: `files/${fileId}`,
                });
            },
            getBatchFiles(fileIds) {
                return req({
                    method: 'GET',
                    url: `files/batch`,
                    params: {
                        file_id: fileIds,
                    },
                });
            },
            updateFiles(files) {
                return req({
                    method: 'PUT',
                    url: `files`,
                    data: files,
                });
            },
            uploadBegin(fileNames) {
                return req({
                    method: 'POST',
                    url: `files/upload/begin`,
                    data: {
                        fileNames,
                    },
                });
            },
            uploadComplete(fileServerFiles) {
                return req({
                    method: 'POST',
                    url: `files/upload/complete`,
                    data: fileServerFiles,
                });
            },
            uploadResponse(fileIds) {
                return req({
                    method: 'POST',
                    url: 'files/upload/response',
                    data: fileIds,
                });
            },
            deleteFile(fileId) {
                return req({
                    method: 'DELETE',
                    url: `files/${fileId}`,
                });
            },
            bulkDeleteFiles(fileIds) {
                return req({
                    method: 'POST',
                    url: `files/delete/bulk`,
                    data: fileIds,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

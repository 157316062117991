import { LinkTypesEnum } from '@mark43/rms-api';
import _ from 'lodash';
import { normalize } from '~/client-common/helpers/dataHelpers';
import authActionTypes from '../actions/types/authActionTypes';

export function linkTypesDataReducer(state = [], action) {
    switch (action.type) {
        case authActionTypes.BOOTSTRAP_SUCCESS:
            // filter out ALLEGED_MEMBER_OF_GANG and CONFIRMED_MEMBER_OF_GANG
            // see RMS-2261
            return _.omit(normalize(action.payload.userData.allLinkTypes), [
                LinkTypesEnum.ALLEGED_MEMBER_OF_GANG,
                LinkTypesEnum.CONFIRMED_MEMBER_OF_GANG,
            ]);
        default:
            return state;
    }
}

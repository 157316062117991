import { createSelector } from 'reselect';

import { dispositionEventsSelector } from '../data';
import { formatDispositionEventType } from '../../utils/dispositionEventsHelpers';
import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
} from '../../../../../helpers/viewModelHelpers';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';

/*
 * Disposition event view model.
 * @param  {id}
 * @return {Object}
 */
export const dispositionEventViewModelByIdSelector = createSelector(
    dispositionEventsSelector,
    allMiniUserFormatsByIdSelector,
    (dispositionEvents, allMiniUserFormatsById) => (id) => {
        const dispositionEvent = dispositionEvents[id];
        const buildDispositionEventViewModel = buildViewModel({
            mappers: [
                buildAllMiniUserFormatsMapper(),
                ({ dispositionEventType }) => ({
                    dispositionEventType: formatDispositionEventType(dispositionEventType),
                }),
            ],
            helpers: {
                allMiniUserFormatsById,
            },
        });
        return buildDispositionEventViewModel(dispositionEvent);
    }
);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { first, map } from 'lodash';
import { propertyTitleForItemProfileSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { buildItemProfileLink } from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { iconTypes } from '../../../../core/components/Icon';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import EntitySummaryHeader from './EntitySummaryHeader';

const UnconnectedPropertyTitleSummary = ({ propertyViewModel, propertyTitleForItemProfile }) => {
    const { propertyStatuses } = getViewModelProperties(propertyViewModel);
    const { propertyStatusAttrId, reasonForPoliceCustodyAttrId } = getViewModelProperties(
        first(propertyStatuses)
    );
    const summaryFieldConfig = [
        {
            fieldName: fields.PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID,
            value: propertyStatusAttrId,
        },
        {
            fieldName: fields.PROPERTY_STATUS_REASON_FOR_POLICE_CUSTODY_ATTR_ID,
            value: reasonForPoliceCustodyAttrId,
        },
    ];
    return (
        <EntitySummaryHeader
            iconType={iconTypes.PROPERTY}
            name={propertyTitleForItemProfile(propertyViewModel)}
            linkTo={buildItemProfileLink(propertyViewModel)}
        >
            <SummaryList>
                {map(summaryFieldConfig, ({ fieldName, value }) => {
                    return (
                        <SummaryRow key={fieldName} fieldName={fieldName}>
                            {value}
                        </SummaryRow>
                    );
                })}
            </SummaryList>
        </EntitySummaryHeader>
    );
};

export const PropertyTitleSummary = connect(
    createStructuredSelector({
        propertyTitleForItemProfile: propertyTitleForItemProfileSelector,
    })
)(UnconnectedPropertyTitleSummary);

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';

import { createStructuredSelector } from 'reselect';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { globalSequenceNumberLabelSelector } from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_ATTACHMENT_FILE_NAME_LABEL,
    DISPLAY_ONLY_ATTACHMENT_DESCRIPTION_LABEL,
    DISPLAY_ONLY_ATTACHMENT_FILE_CONTENT_KEYWORD_SEARCH_LABEL,
    DISPLAY_ONLY_ATTACHMENT_UPLOADED_DATE_LABEL,
    DISPLAY_ONLY_ATTACHMENT_UPLOADED_TO_LABEL,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';

import testIds from '../../../../core/testIds';
import advancedSearchAttachments from '../state/ui';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import Row from '../../../core/components/Row';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import SearchCapabilityIcon, {
    SEARCH_CAPABILITY_TYPE_ENUM,
} from '../../core/components/SearchCapabilityIcon';

const strings = componentStrings.search.AdvancedSearchAttachmentsForm;

const entityTypeOptions = [
    {
        display: 'Person',
        value: EntityTypeEnum.PERSON_PROFILE.name,
    },
    {
        display: 'Report',
        value: EntityTypeEnum.REPORT.name,
    },
    {
        display: 'Item',
        value: EntityTypeEnum.ITEM_PROFILE.name,
    },
    {
        display: 'Case',
        value: EntityTypeEnum.CASE.name,
    },
];

const IconWrapper = styled.span`
    margin-left: 10px;
`;

function AdvancedSearchAttachmentsForm({
    formIsEmpty,
    globalSequenceNumberLabel,
    onSubmit,
    onReset,
    fieldDisplayNames,
}) {
    return (
        <AdvancedSearchForm
            {...advancedSearchAttachments.form}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
        >
            <FormSection>
                <RRFFieldset path="fileDetails">
                    <Row>
                        <RRFText
                            label={fieldDisplayNames[DISPLAY_ONLY_ATTACHMENT_FILE_NAME_LABEL]}
                            placeholder={strings.fields.fileName.placeholder}
                            path="fileName"
                            width={165}
                        />
                    </Row>
                    <Row>
                        <RRFText
                            label={globalSequenceNumberLabel}
                            path="reportSequenceNumber"
                            width={225}
                        />
                    </Row>
                    <Row testId={testIds.ATTACHMENT_ROW_DESCRIPTION}>
                        <RRFText
                            extraLabelContent={
                                <IconWrapper>
                                    <SearchCapabilityIcon
                                        variant={SEARCH_CAPABILITY_TYPE_ENUM.BOOLEAN.name}
                                    />
                                </IconWrapper>
                            }
                            label={fieldDisplayNames[DISPLAY_ONLY_ATTACHMENT_DESCRIPTION_LABEL]}
                            labelClassName=""
                            helpText={strings.fields.description.helpText}
                            path="description"
                            width={330}
                        />
                    </Row>
                    <Row testId={testIds.ATTACHMENT_ROW_KEYWORD_SEARCH}>
                        <RRFText
                            extraLabelContent={
                                <IconWrapper>
                                    <SearchCapabilityIcon
                                        variant={SEARCH_CAPABILITY_TYPE_ENUM.BOOLEAN.name}
                                    />
                                </IconWrapper>
                            }
                            label={
                                fieldDisplayNames[
                                    DISPLAY_ONLY_ATTACHMENT_FILE_CONTENT_KEYWORD_SEARCH_LABEL
                                ]
                            }
                            labelClassName=""
                            helpText={strings.fields.fileContents.helpText}
                            placeholder={strings.fields.fileContents.placeholder}
                            path="fileTextContentsKeywords"
                            width={330}
                        />
                    </Row>
                    <Row>
                        <RRFDateRangePicker
                            label={fieldDisplayNames[DISPLAY_ONLY_ATTACHMENT_UPLOADED_DATE_LABEL]}
                            width={330}
                            includeTime={true}
                            withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                            toDatePeriodOptions={['P1M', 'P1Y']}
                        />
                    </Row>
                    <Row>
                        <RRFSelect
                            path="linkedEntityTypes"
                            label={fieldDisplayNames[DISPLAY_ONLY_ATTACHMENT_UPLOADED_TO_LABEL]}
                            options={entityTypeOptions}
                            width={330}
                            multiple={true}
                        />
                    </Row>
                </RRFFieldset>
            </FormSection>
        </AdvancedSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    formIsEmpty: advancedSearchAttachments.form.selectors.formIsEmptySelector,
    globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () =>
        dispatch(
            advancedSearchAttachments.form.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchAttachments.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                )
            )
        ),
    onReset: () => {
        dispatch(advancedSearchAttachments.form.actionCreators.reset());
        dispatch(advancedSearchAttachments.actionCreators.resetState());
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFields([
        DISPLAY_ONLY_ATTACHMENT_FILE_NAME_LABEL,
        DISPLAY_ONLY_ATTACHMENT_DESCRIPTION_LABEL,
        DISPLAY_ONLY_ATTACHMENT_FILE_CONTENT_KEYWORD_SEARCH_LABEL,
        DISPLAY_ONLY_ATTACHMENT_UPLOADED_DATE_LABEL,
        DISPLAY_ONLY_ATTACHMENT_UPLOADED_TO_LABEL,
    ])
)(AdvancedSearchAttachmentsForm);

import React from 'react';
import styled from 'styled-components';
import {
    ComplianceExportTypeEnum,
    ComplianceExportHistoryView,
    NibrsHistoryView,
} from '@mark43/rms-api';
import { FormattedUser } from '~/client-common/core/users/components';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../core/testIds';
import RMSTableComposite from '../../core/components/RMSTableComposite';
import TableHeader, { TableHeaderWrapper } from './table/TableHeader';
import TableRow from './table/TableRow';
import TableCell, { TableCellContent } from './table/TableCell';
import ComplianceFormattedDateCell from './table/cells/ComplianceFormattedDateCell';
import ComplianceDownloadLinkedCell from './table/cells/ComplianceDownloadLinkedCell';
import ErrorRateCell from './table/cells/ErrorRateCell';

// Table Components

const COLUMN_DATE_WIDTH = 120;
const COLUMN_DOWNLOADED_BY_WIDTH = 220;
const COLUMN_COMPLIANCE_EXPORT_FILE_WIDTH = 560;
const COLUMN_NIBRS_ERROR_RATE_WIDTH = 130;
const COLUMN_NIBRS_EXPORT_FILE_WIDTH = 470;

type ComplianceExportHistoryTableProps = {
    data: ComplianceExportHistoryView[];
};

type NibrsExportHistoryTableProps = {
    data: NibrsHistoryView[];
};

const strings = componentStrings.compliance.ComplianceExportHistoryTable;

const renderHeaderRow = () => {
    return (
        <TableHeaderWrapper>
            <TableHeader width={COLUMN_DATE_WIDTH}>{strings.labels.dateRange}</TableHeader>
            <TableHeader width={COLUMN_DOWNLOADED_BY_WIDTH}>
                {strings.labels.downloadedBy}
            </TableHeader>
            <TableHeader width={COLUMN_COMPLIANCE_EXPORT_FILE_WIDTH}>
                {strings.labels.complianceExportFile}
            </TableHeader>
        </TableHeaderWrapper>
    );
};

const renderNibrsHeaderRow = () => {
    return (
        <TableHeaderWrapper>
            <TableHeader width={COLUMN_DATE_WIDTH}>{strings.labels.dateRange}</TableHeader>
            <TableHeader width={COLUMN_DOWNLOADED_BY_WIDTH}>
                {strings.labels.downloadedBy}
            </TableHeader>
            <TableHeader width={COLUMN_NIBRS_ERROR_RATE_WIDTH}>
                {strings.labels.errorRate}
            </TableHeader>
            <TableHeader width={COLUMN_NIBRS_EXPORT_FILE_WIDTH}>
                {strings.labels.nibrsExport}
            </TableHeader>
        </TableHeaderWrapper>
    );
};

type RenderBodyRowT<T> = {
    index: number;
    item: T;
};

const DownloadedByContent = styled.span`
    font-style: italic;
`;

const renderComplianceExportHistoryBodyRow: React.FC<
    RenderBodyRowT<ComplianceExportHistoryView>
> = ({ index, item }) => {
    const {
        complianceExportType,
        exportDay,
        exportMonth,
        exportYear,
        exportDateRangeStart,
        exportDateRangeEnd,
        exportedBy,
        exportedDateUtc,
        complianceExportFile,
        isOverwritten,
    } = item;

    const exportIntervalType = ComplianceExportTypeEnum[complianceExportType].exportIntervalType;

    return (
        <TableRow key={index}>
            <TableCell width={COLUMN_DATE_WIDTH}>
                <TableCellContent>
                    <ComplianceFormattedDateCell
                        exportIntervalType={exportIntervalType}
                        day={exportDay}
                        month={exportMonth}
                        year={exportYear}
                        startDate={exportDateRangeStart}
                        endDate={exportDateRangeEnd}
                    />
                </TableCellContent>
            </TableCell>
            <TableCell width={COLUMN_DOWNLOADED_BY_WIDTH}>
                <TableCellContent>
                    <DownloadedByContent>
                        <span>
                            <FormattedUser
                                user={exportedBy}
                                format={FormattedUser.FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
                            />
                            {' on '}
                            <FormattedDate
                                date={exportedDateUtc}
                                format={FormattedDate.FORMATS.FORM_DATE}
                            />
                        </span>
                    </DownloadedByContent>
                </TableCellContent>
            </TableCell>
            <TableCell width={COLUMN_COMPLIANCE_EXPORT_FILE_WIDTH}>
                <TableCellContent>
                    <ComplianceDownloadLinkedCell
                        file={complianceExportFile}
                        isOverwritten={isOverwritten}
                    />
                </TableCellContent>
            </TableCell>
        </TableRow>
    );
};

const TableWrapper = styled.div`
    padding: 0 0 20px 0;
    margin: 0 10px;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const ErrorRateTableCellContent = styled(TableCellContent)`
    margin-left: 2px;
`;

const renderNibrsExportHistoryBodyRow: React.FC<RenderBodyRowT<NibrsHistoryView>> = ({
    index,
    item,
}) => {
    const {
        exportDay,
        exportMonth,
        exportYear,
        exportedBy,
        exportedDateUtc,
        nibrsExportFile,
        isOverwritten,
        isDeleted,
        errorRate,
        exportIntervalType,
    } = item;

    return (
        <TableRow key={index}>
            <TableCell width={COLUMN_DATE_WIDTH}>
                <TableCellContent>
                    <ComplianceFormattedDateCell
                        exportIntervalType={exportIntervalType}
                        day={exportDay}
                        month={exportMonth}
                        year={exportYear}
                    />
                </TableCellContent>
            </TableCell>
            <TableCell width={COLUMN_DOWNLOADED_BY_WIDTH}>
                <TableCellContent>
                    <DownloadedByContent>
                        <span>
                            <FormattedUser
                                user={exportedBy}
                                format={FormattedUser.FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
                            />
                            {' on '}
                            <FormattedDate
                                date={exportedDateUtc}
                                format={FormattedDate.FORMATS.FORM_DATE}
                            />
                        </span>
                    </DownloadedByContent>
                </TableCellContent>
            </TableCell>
            <TableHeader width={COLUMN_NIBRS_ERROR_RATE_WIDTH}>
                <ErrorRateTableCellContent>
                    <ErrorRateCell errorRate={errorRate} isOverwritten={isOverwritten} />
                </ErrorRateTableCellContent>
            </TableHeader>
            <TableCell width={COLUMN_NIBRS_EXPORT_FILE_WIDTH}>
                <TableCellContent>
                    <ComplianceDownloadLinkedCell
                        file={nibrsExportFile}
                        isOverwritten={isOverwritten}
                        isDeleted={isDeleted}
                    />
                </TableCellContent>
            </TableCell>
        </TableRow>
    );
};

export const NibrsExportHistoryTable: React.FC<NibrsExportHistoryTableProps> = ({ data }) => {
    return (
        <TableWrapper>
            <RMSTableComposite
                className="nibrs-export-history-table"
                columns={[]}
                items={data}
                includeFilter={false}
                includePagination={false}
                noDataText={strings.noResults}
                paginationOptions={{ pageSize: 9999 }}
                renderHeaderRow={renderNibrsHeaderRow}
                renderBodyRow={renderNibrsExportHistoryBodyRow}
                testId={testIds.COMPLIANCE_DASHBOARD_HISTORY_TABLE}
            />
        </TableWrapper>
    );
};

const ComplianceExportHistoryTable: React.FC<ComplianceExportHistoryTableProps> = ({ data }) => {
    return (
        <TableWrapper>
            <RMSTableComposite
                className="compliance-export-history-table"
                columns={[]}
                items={data}
                includeFilter={false}
                includePagination={false}
                noDataText={strings.noResults}
                paginationOptions={{ pageSize: 9999 }}
                renderHeaderRow={renderHeaderRow}
                renderBodyRow={renderComplianceExportHistoryBodyRow}
                testId={testIds.COMPLIANCE_DASHBOARD_HISTORY_TABLE}
            />
        </TableWrapper>
    );
};

export default ComplianceExportHistoryTable;

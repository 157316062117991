import {
    TasksResource,
    Attachment,
    TaskView,
    TaskComment,
    SqlSearchQueryTaskSqlQuery,
    EntityTypeEnumType,
    SavedSearch,
    ElasticSearchTypeEnum,
    TaskEntityLinkView,
} from '@mark43/rms-api';
import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/rms/api';
const DEFAULT_PARAMS = {
    from: 0,
    size: 20,
};

export default createResource({
    name: 'Tasks Resource',
    methods: {
        /**
         * Delete task
         */
        deleteTask: (taskId: number) =>
            req<TasksResource.DeleteTasks>({
                method: 'DELETE',
                baseUrl: BASE_URL,
                url: `tasks/${taskId}/`,
            }),

        /**
         * Create/update a a task with attachments
         */
        upsertTask: (
            task: Partial<TaskView>,
            taskEntityLinks: Partial<TaskEntityLinkView>[],
            attachments: Partial<Attachment>[],
            taskComments: Partial<TaskComment>[] = []
        ) =>
            req<TasksResource.UpsertTask>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: 'tasks',
                // @ts-expect-error Partials not accepted in generated resource type
                data: { attachments, taskEntityLinks, task, taskComments },
            }),
        createTaskComment: (taskId: number, data: Partial<TaskComment>) =>
            req<TasksResource.CreateTaskComment>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `tasks/${taskId}/comment`,
                // @ts-expect-error Partials not accepted in generated resource type
                data,
            }),
        removeTaskComment: (commentId: number) =>
            req<TasksResource.DeleteTaskComment>({
                method: 'DELETE',
                baseUrl: BASE_URL,
                url: `tasks/comment/${commentId}/`,
            }),

        /**
         * Search for tasks
         */
        search: ({ query, size, from, sorts }: SqlSearchQueryTaskSqlQuery) =>
            req<TasksResource.SearchTasks>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `tasks/search`,
                data: {
                    query,
                    size: size || DEFAULT_PARAMS.size,
                    from: from || DEFAULT_PARAMS.from,
                    sorts,
                },
                params: { auto_save_type: ElasticSearchTypeEnum.TASK.name },
            }),

        /**
         * Get tasks with the given entity as their owner. Use this endpoint when the entity type is allowed have only 1
         * entity linked to each task, such as CASE.
         */
        getTasksForEntity: (entityType: EntityTypeEnumType, entityId: number) =>
            req<TasksResource.GetTaskForEntityType>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `tasks/${entityType}/${entityId}/`,
            }),
        /**
         * Get tasks which are linked to the given entity through TaskEntityLinks. Use this endpoint when the entity
         * type is allowed to have arbitrarily many entities linked to each task, and when there is no special business
         * logic tied to ownerType/ownerId.
         */
        getTasksForLinkedEntity: (entityId: number, entityType: EntityTypeEnumType) =>
            req<TasksResource.GetTasksForLinkedEntity>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `tasks/entity_links/${entityType}/${entityId}/`,
            }),

        /**
         * Get tasks assigned to me or to my role
         */
        getMyTasks: (
            { from = DEFAULT_PARAMS.from, size = DEFAULT_PARAMS.size } = {},
            { hideLoadingBar = false }
        ) =>
            req<TasksResource.GetMyTasks>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `tasks/mine`,
                params: { from, size },
                hideLoadingBar,
            }),

        executeSavedSearch: (
            savedSearch: SavedSearch,
            { from = DEFAULT_PARAMS.from, size = DEFAULT_PARAMS.size } = {}
        ) =>
            req<TasksResource.ExecuteSavedSearch>({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `tasks/search/saved/execute`,
                params: { from, size },
                data: savedSearch,
            }),

        getTaskHistory: (taskId: number) =>
            req<TasksResource.GetTaskHistory>({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `tasks/${taskId}/history/`,
            }),
    },
});

import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import keyMirror from 'keymirror';
import { chain, compact, concat, first, get, filter, map } from 'lodash';

import { createSelector } from 'reselect';
import getPersonProfileResource from '~/client-common/core/domain/person-profiles/resources/personProfileResource';
import {
    formatAttributeByIdSelector,
    attributeIsOtherSelector,
    activeAttributesByTypeSelector,
} from '~/client-common/core/domain/attributes/state/data';

import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { personEmergencyContactsSelector } from '~/client-common/core/domain/person-emergency-contacts/state/data';
import { passportsSelector } from '~/client-common/core/domain/passports/state/data';
import { personProbationsSelector } from '~/client-common/core/domain/person-probations/state/data';
import { employmentHistoriesSelector } from '~/client-common/core/domain/employment-histories/state/data';
import { identifyingMarksSelector } from '~/client-common/core/domain/identifying-marks/state/data';
import { nameIdentifiersSelector } from '~/client-common/core/domain/name-identifiers/state/data';
import { nameEmailsSelector } from '~/client-common/core/domain/name-emails/state/data';
import { nameMonikersSelector } from '~/client-common/core/domain/name-monikers/state/data';
import { namePhonesSelector } from '~/client-common/core/domain/name-phones/state/data';
import { schoolHistoriesSelector } from '~/client-common/core/domain/school-histories/state/data';
import { mugshotAttachmentsForPersonIdSelector } from '~/client-common/core/domain/attachments/state/ui/';
import {
    augmentActionAndStoreRmsHydratedPersonProfiles,
    SAVE_PERSON_PROFILE_SUCCESS,
    personProfilesSelector,
} from '~/client-common/core/domain/person-profiles/state/data/';
import { unknownLocationIdSelector } from '~/client-common/core/constants/state/constants';
import { personInjuriesByPersonProfileIdSelector } from '~/client-common/core/domain/person-injuries/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { elasticPersonsSelector } from '~/client-common/core/domain/elastic-persons/state/data/';
import {
    addNameReportLink,
    nameReportLinksWhereSelector,
} from '~/client-common/core/domain/name-report-links/state/data';
import { UNKNOWN } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { offensesByReportIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { isOffenseModifyingSupplementReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import {
    nameNameLinksWhereSelector,
    NEXUS_STATE_PROP as NAME_NAME_LINK_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/name-name-links/state/data';
import { cautionsByEntitySelector } from '~/client-common/core/domain/cautions/state/data';

import { logWarning } from '../../../../../core/logging';

import {
    handleNameProfileFormError,
    augmentHydratedNameWithAttachmentsAndReportLinks,
    createOnAddSuccessNameReportLink,
    shouldUseStubLink,
} from '../../../names/util/nameSaveFormHelpers';
import { uploadedFilesSelector } from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';
import {
    currentReportSelector,
    currentReportIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { createOverlayCustomProperties } from '../../../utils/createOverlayCustomProperties';
import {
    CAUTIONS,
    CORE_DETAILS,
    EVENT_SPECIFIC_INFO,
    MISC_INFO,
    CONTACT_INFO,
} from '../../config/personProfileFormSections';
import { createPersonProfileFormConfiguration } from '../forms/createPersonProfileFormConfiguration';
import { quickAddName } from '../../../names/state/data';
import relationshipsCard from '../../../../reports/core/state/ui/relationshipsCard';
import {
    REN_IDENTIFIER,
    recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
} from '../../../recent-entities/state/ui';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';
import { mapQuickAddEntitiesToSameOwnerId } from '../../../../reports/core/helpers/quickAddHelpers';
import involvedProfilesForm from '../../../../reports/core/state/forms/involvedProfilesForm';
import { initializeCardForm } from '../../../../reports/core/state/ui/cards';
import { getFormContextForLinkType } from '../../utils/getFormContextForLinkType';
import { convertFromFormModel } from '../forms/personProfileForm';

const quickAddStrings = componentStrings.core.PersonQuickAdd;

export const SCREENS = keyMirror({
    // Go to the search to add screen
    SEARCH_FORM: null,
    // Go to the search results screen
    SEARCH_RESULTS: null,
    // Go to the edit profile screen for an existing profile
    PROFILE_EDIT: null,
    // Go to the edit profile for adding a new profile
    PROFILE_ADD_NEW: null,
    // Go to the edit profile for adding an unknown profile
    PROFILE_ADD_UNKNOWN: null,
    // Go to the edit profile for identifying an unknown to an existing profile
    PROFILE_IDENTIFY_EXISTING: null,
    // Go to the edit profile for identifying an unknown as a new profile
    PROFILE_IDENTIFY_NEW: null,
    // Identification search form
    IDENTIFY_UNKNOWN_SEARCH: null,
    // Identification search form results
    IDENTIFY_UNKNOWN_SEARCH_RESULTS: null,
    // Select Person Involvement Type Screen
    SELECT_PERSON_INVOLVEMENT_TYPE: null,
    // Search form for cad profiles
    CAD_PROFILE_SEARCH: null,
    // Search results for cad profiles
    CAD_PROFILE_SEARCH_RESULTS: null,
    // DEx search panel
    DEX_SIDE_PANEL: null,
});

export const CREATE_UNKNOWN_PERSON_SUCCESS = 'person-profiles/CREATE_UNKNOWN_PERSON_SUCCESS';

function createNoInfoKnownPersonSuccess(unknownPerson) {
    return {
        type: CREATE_UNKNOWN_PERSON_SUCCESS,
        payload: unknownPerson,
    };
}

function personSidePanelSaveSuccess(hydratedPerson) {
    return {
        type: SAVE_PERSON_PROFILE_SUCCESS,
        payload: hydratedPerson,
    };
}

function createFullHydratedPerson({
    dispatch,
    formModel,
    getState,
    contextId,
    contextType,
    linkType,
    entityId,
    ownerId,
    ownerType,
    isIdentifying,
    isEditingMasterProfile,
    cadProfileId,
}) {
    const state = getState();
    const lookupEntityId = entityId || formModel.masterPersonId;
    if (cadProfileId) {
        formModel.id = undefined;
    }
    const hydratedPerson = convertFromFormModel(
        formModel,
        {
            personInjuries: personInjuriesByPersonProfileIdSelector(state)(lookupEntityId),
            monikers: nameMonikersSelector(state)[lookupEntityId],
            identifiers: nameIdentifiersSelector(state)[lookupEntityId],
            phoneNumbers: namePhonesSelector(state)[lookupEntityId],
            emails: nameEmailsSelector(state)[lookupEntityId],
            identifyingMarks: identifyingMarksSelector(state)[lookupEntityId],
            personEmergencyContacts: personEmergencyContactsSelector(state)[lookupEntityId],
            passports: passportsSelector(state)[lookupEntityId],
            personProbations: personProbationsSelector(state)[lookupEntityId],
            employmentHistories: employmentHistoriesSelector(state)[lookupEntityId],
            schoolHistories: schoolHistoriesSelector(state)[lookupEntityId],
            locations: locationsSelector(state),
            locationEntityLinks: locationEntityLinksWhereSelector(state)({
                entityId: lookupEntityId,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            }),
            unknownLocationId: unknownLocationIdSelector(state),
            applicationSettings: applicationSettingsSelector(state),
            dateOfCalculation: eventDateByReportIdSelector(state)({ ownerId }),
            cautions: cautionsByEntitySelector(state)(
                EntityTypeEnum.PERSON_PROFILE.name,
                lookupEntityId
            ),
        },
        {
            isEditingMasterProfile,
            isIdentifying,
            entityId,
            lookupEntityId,
            dispatch,
            getState,
            ownerId,
            ownerType,
            formatAttributeById: formatAttributeByIdSelector(state),
            attributeIsOther: attributeIsOtherSelector(state),
        }
    );

    // we need to send down an empty array if there are no locations present
    // because the backend is going to replace location links
    if (!hydratedPerson.locations) {
        hydratedPerson.locations = [];
    }

    const mugshotLookupId = lookupEntityId ?? cadProfileId;
    const attachments = compact(
        concat(
            uploadedFilesSelector(state),
            first(mugshotAttachmentsForPersonIdSelector(state)(mugshotLookupId))
        )
    );

    // attachments are uploaded using the `InlineAttachmentsUploader`
    // which manages its own state. Because of this the attachments do not
    // live in MFT directly and have to be augmented here.
    const fullHydratedPerson = augmentHydratedNameWithAttachmentsAndReportLinks({
        hydratedName: hydratedPerson,
        attachments,
        entityType: EntityTypeEnum.PERSON_PROFILE.name,
        entityId,
        linkType,
        ownerId,
        contextId,
        contextType,
        isEditingMasterProfile,
    });

    // Defendants are not handled with name report links, but are stored on the arrest itself.
    // This is something we need to change to make our codebase and patterns more uniform.
    if (shouldUseStubLink({ contextType, contextId })) {
        fullHydratedPerson.reportLinks = [];
    }

    return fullHydratedPerson;
}

function saveHydratedPerson({
    formModel,
    getState,
    dispatch,
    contextId,
    contextType,
    linkType,
    entityId,
    ownerId,
    ownerType,
    idToIdentify,
    isEditingMasterProfile,
    cadProfileId,
}) {
    const fullHydratedPerson = createFullHydratedPerson({
        formModel,
        getState,
        dispatch,
        contextId,
        contextType,
        linkType,
        entityId,
        ownerId,
        ownerType,
        isIdentifying: !!idToIdentify,
        isEditingMasterProfile,
        cadProfileId,
    });

    return Promise.resolve(
        // If we are identifying, we need to hit a different endpoint
        !!idToIdentify
            ? getPersonProfileResource().identifyHydratedPerson(idToIdentify, fullHydratedPerson)
            : // Wrap the hydratedPerson in an array so that
              // we keep the returned data structures consistent (identify
              // endpoint returns an array of hydratedPeople)
              getPersonProfileResource()
                  .upsertHydratedPerson({
                      hydratedPerson: fullHydratedPerson,
                      removeLocationsIfEmpty: true,
                      removePersonGangTrackingIfEmpty: !isEditingMasterProfile,
                  })
                  .then((hydratedPerson) => {
                      if (cadProfileId) {
                          return getPersonProfileResource()
                              .linkPersonPerson(
                                  cadProfileId,
                                  hydratedPerson.personProfile.id,
                                  LinkTypesEnum.RMS_PERSON_CREATED_FROM_CAD_PERSON
                              )
                              .then((nameNameLink) => {
                                  hydratedPerson.nameNameLinks = [nameNameLink];
                                  return [hydratedPerson];
                              });
                      } else {
                          return [hydratedPerson];
                      }
                  })
    );
}

const setSelectedSubjectTypeInInvolvedProfilesFormModel = (nameReportLink) => {
    return (dispatch, getState) => {
        const formModel = involvedProfilesForm.selectors.formModelSelector(getState());
        const { personSubjectNameReportLinks = [] } = formModel;
        const { nameId, subjectTypeAttrId } = nameReportLink;

        const alteredPersonSubjectNameReportLinks = map(personSubjectNameReportLinks, (link) => {
            if (link.nameId === nameId) {
                return {
                    ...link,
                    subjectTypeAttrId,
                };
            }
            return link;
        });
        dispatch(
            involvedProfilesForm.actionCreators.change({
                ...formModel,
                personSubjectNameReportLinks: alteredPersonSubjectNameReportLinks,
            })
        );
        dispatch(initializeCardForm(involvedProfilesForm));
    };
};

export function savePersonProfileForm({
    idToIdentify,
    overlayId,
    resetPanelErrorMessages,
    appendPanelErrorMessageIfNotExists,
    isEditingMasterProfile,
    isEditingExistingPerson,
    updateFormSectionState,
    linkType,
    formConfigurationLinkType,
    selectedSubjectTypeAttrId,
    onAddSuccess,
    contextId,
    contextType,
    ownerId,
    ownerType,
    entityId,
    savePanel,
    cadProfileId,
    refreshRecentPersons,
}) {
    return (dispatch, getState, dependencies) => {
        const applicationSettings = applicationSettingsSelector(getState());
        resetPanelErrorMessages();
        const formContext = getFormContextForLinkType(formConfigurationLinkType);
        const form = dependencies.formsRegistry.get(formContext);
        return form
            .submit()
            .then(({ form }) => {
                dependencies.overlayStore.setCustomProperties(
                    overlayId,
                    createOverlayCustomProperties({ isSaving: true })
                );

                const { model } = form.getState();

                return saveHydratedPerson({
                    formModel: model,
                    getState,
                    dispatch,
                    contextId,
                    contextType,
                    linkType,
                    entityId,
                    ownerId,
                    ownerType,
                    idToIdentify,
                    isEditingMasterProfile: !!isEditingMasterProfile,
                    cadProfileId,
                });
            })
            .then((hydratedPeople) => {
                // The first person in the array is the newly created context profile,
                // or updated context profile
                // The second person is the updated unknown stub (updated just to have a master id)
                // A second person will only exist if we hit the identify endpoint
                const hydratedPersonForCurrentReport = first(hydratedPeople);

                dispatch(
                    augmentActionAndStoreRmsHydratedPersonProfiles(
                        personSidePanelSaveSuccess(hydratedPeople),
                        hydratedPeople
                    )
                );

                const nameReportLink = createOnAddSuccessNameReportLink({
                    contextId,
                    contextType,
                    linkType,
                    ownerId,
                    hydratedName: hydratedPersonForCurrentReport,
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                });

                if (
                    selectedSubjectTypeAttrId &&
                    applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED &&
                    linkType === LinkTypesEnum.INVOLVED_PERSON_IN_REPORT
                ) {
                    const nameReportLinkWithSubjectTypeAttrId = {
                        ...nameReportLink,
                        subjectTypeAttrId: selectedSubjectTypeAttrId,
                    };
                    dispatch(addNameReportLink(nameReportLinkWithSubjectTypeAttrId));

                    dispatch(
                        setSelectedSubjectTypeInInvolvedProfilesFormModel(
                            nameReportLinkWithSubjectTypeAttrId
                        )
                    );
                } else {
                    // this callback is only passed in for adding, but not editing.
                    if (onAddSuccess) {
                        onAddSuccess(nameReportLink);
                        if (cadProfileId) {
                            dispatch(
                                dependencies.nexus.withEntityItems(
                                    {
                                        [NAME_NAME_LINK_NEXUS_STATE_PROP]:
                                            hydratedPersonForCurrentReport.nameNameLinks,
                                    },
                                    { type: 'ADD_CAD_RMS_NAME_NAME_LINK' }
                                )
                            );
                        }
                    }
                }

                if (refreshRecentPersons) {
                    refreshRecentPersons();
                }

                // If we are adding a new person
                // then put the involved name card
                // into edit mode
                if (!isEditingExistingPerson) {
                    dispatch(relationshipsCard.actionCreators.edit());
                }
                savePanel(hydratedPersonForCurrentReport.id);
            })
            .catch((error) => {
                // not moving this into `handleNameProfileFormError`
                // as it only applies to saving the person profile form
                updateFormSectionState({
                    [CAUTIONS]: true,
                    [CORE_DETAILS]: true,
                    [CONTACT_INFO]: true,
                    [EVENT_SPECIFIC_INFO]: true,
                    [MISC_INFO]: true,
                });
                handleNameProfileFormError({
                    error,
                    appendPanelErrorMessageIfNotExists,
                    overlayStore: dependencies.overlayStore,
                    overlayId,
                });
            });
    };
}

export function quickAddPerson({
    formModel,
    contextType,
    contextId,
    ownerType,
    ownerId,
    linkType,
    entityId,
    onAddSuccess,
    onComplete,
    arbiterInstance,
    formContext,
    personOverlayId,
}) {
    return (dispatch, getState, { overlayStore }) => {
        const formConfiguration = createPersonProfileFormConfiguration();
        const applicationSettings = applicationSettingsSelector(getState());

        const showInvolvementSidePanel =
            applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED &&
            linkType === LinkTypesEnum.INVOLVED_PERSON_IN_REPORT;

        const personSidePanelScreen = showInvolvementSidePanel
            ? SCREENS.SELECT_PERSON_INVOLVEMENT_TYPE
            : SCREENS.PROFILE_EDIT;

        const onSubmitSuccess = () =>
            saveHydratedPerson({
                formModel,
                getState,
                dispatch,
                contextId,
                contextType,
                linkType,
                entityId,
                ownerId,
                ownerType,
                isEditingMasterProfile: false,
            }).then((hydratedPeople) => {
                // The first person in the array is the newly created context profile,
                // or updated context profile
                // The second person is the updated unknown stub (updated just to have a master id)
                // A second person will only exist if we hit the identify endpoint
                const hydratedPersonForCurrentReport = first(hydratedPeople);
                const hydratedPersonContextId = hydratedPersonForCurrentReport
                    ? hydratedPersonForCurrentReport.id
                    : undefined;

                dispatch(
                    augmentActionAndStoreRmsHydratedPersonProfiles(
                        personSidePanelSaveSuccess(hydratedPeople),
                        hydratedPeople
                    )
                );

                // this callback is only passed in for adding, but not editing.
                if (onAddSuccess) {
                    onAddSuccess(
                        createOnAddSuccessNameReportLink({
                            contextId,
                            contextType,
                            linkType,
                            ownerId,
                            hydratedName: hydratedPersonForCurrentReport,
                            entityType: EntityTypeEnum.PERSON_PROFILE.name,
                        })
                    );
                }

                if (hydratedPersonContextId && showInvolvementSidePanel) {
                    overlayStore.open(personOverlayId, {
                        screenStack: [
                            {
                                screen: personSidePanelScreen,
                                screenState: {
                                    selectedId: hydratedPersonContextId,
                                },
                            },
                        ],
                    });
                }
            });

        const getScreenStackWithErrorMessages = (errorMessages) => {
            return [
                {
                    screen: personSidePanelScreen,
                    screenState: {
                        errorMessages,
                        selectedId: entityId,
                    },
                    formSectionState: {
                        [CAUTIONS]: true,
                        [CORE_DETAILS]: true,
                        [CONTACT_INFO]: true,
                        [EVENT_SPECIFIC_INFO]: true,
                        [MISC_INFO]: true,
                    },
                },
            ];
        };

        return dispatch(
            quickAddName({
                formModel,
                entityId,
                onComplete,
                arbiterInstance,
                nameOverlayId: personOverlayId,
                formContext,
                formConfiguration,
                onSubmitSuccess,
                genericSaveError: quickAddStrings.genericSaveError,
                getScreenStackWithErrorMessages,
            })
        );
    };
}

const getUnknownAttributesForQuickAddUnknownPerson = ({ getState }) => {
    const state = getState();
    const activeAttributesByType = activeAttributesByTypeSelector(state);
    const currentReport = currentReportSelector(state);
    const currentUserDepartmentId = currentUserDepartmentIdSelector(state);
    const departmentId = currentReport
        ? get(currentReport, 'departmentId')
        : currentUserDepartmentId;

    const filterForUnknownInDepartment = (attribute) =>
        attribute.deptId === departmentId && attribute.val.toUpperCase() === UNKNOWN.toUpperCase();

    const ethnicityAttrId = first(
        activeAttributesByType(AttributeTypeEnum.ETHNICITY.name, filterForUnknownInDepartment)
    )?.id;
    const raceAttrId = first(
        activeAttributesByType(AttributeTypeEnum.RACE.name, filterForUnknownInDepartment)
    )?.id;
    const sexAttrId = first(
        activeAttributesByType(AttributeTypeEnum.SEX.name, filterForUnknownInDepartment)
    )?.id;

    if (!ethnicityAttrId || !raceAttrId || !sexAttrId) {
        logWarning(
            `Missing active 'Unknown' Attribute for prefilling unknown person: ` +
                `ethnicityAttrId: ${ethnicityAttrId}, ` +
                `raceAttrId: ${raceAttrId}, ` +
                `sexAttrId: ${sexAttrId}`,
            {
                ethnicityAttrId,
                raceAttrId,
                sexAttrId,
            }
        );
    }

    return { ethnicityAttrId, raceAttrId, sexAttrId };
};

export function quickAddUnknownPerson({
    contextId,
    contextType,
    linkType,
    entityId,
    ownerId,
    ownerType,
    onAddSuccess,
    onComplete,
}) {
    return (dispatch, getState) => {
        const {
            ethnicityAttrId,
            raceAttrId,
            sexAttrId,
        } = getUnknownAttributesForQuickAddUnknownPerson({ getState });

        const personProfileResource = getPersonProfileResource();
        return personProfileResource
            .upsertHydratedPerson({
                hydratedPerson: augmentHydratedNameWithAttachmentsAndReportLinks({
                    hydratedName: {
                        personProfiles: [
                            {
                                id: entityId,
                                isDobUnknown: true,
                                ownerId,
                                ownerType,
                                ethnicityAttrId,
                                raceAttrId,
                                sexAttrId,
                            },
                        ],
                    },
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    entityId,
                    linkType,
                    ownerId,
                    contextId,
                    contextType,
                }),
                removeLocationsIfEmpty: true,
                removePersonGangTrackingIfEmpty: true,
            })
            .then((unknownPerson) => {
                dispatch(
                    augmentActionAndStoreRmsHydratedPersonProfiles(
                        createNoInfoKnownPersonSuccess([unknownPerson]),
                        [unknownPerson]
                    )
                );

                if (onAddSuccess) {
                    onAddSuccess(
                        createOnAddSuccessNameReportLink({
                            contextId,
                            contextType,
                            linkType,
                            ownerId,
                            hydratedName: unknownPerson,
                            entityType: EntityTypeEnum.PERSON_PROFILE.name,
                        })
                    );
                }
            })
            .finally(() => {
                if (onComplete) {
                    onComplete();
                }
            });
    };
}

export function quickAddUnknownPersonForPole({
    formModel,
    contextId,
    contextType,
    linkType,
    entityId,
    ownerId,
    ownerType,
    onAddSuccess,
    onComplete,
    arbiterInstance,
    formContext,
    personOverlayId,
}) {
    return (dispatch, getState) => {
        const {
            ethnicityAttrId,
            raceAttrId,
            sexAttrId,
        } = getUnknownAttributesForQuickAddUnknownPerson({ getState });

        const personProfileResource = getPersonProfileResource();
        return personProfileResource
            .upsertHydratedPerson({
                hydratedPerson: augmentHydratedNameWithAttachmentsAndReportLinks({
                    hydratedName: {
                        personProfiles: [
                            {
                                ethnicityAttrId,
                                id: entityId,
                                isDobUnknown: true,
                                ownerId,
                                ownerType,
                                raceAttrId,
                                sexAttrId,
                            },
                        ],
                    },
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    entityId,
                    linkType,
                    ownerId,
                    contextId,
                    contextType,
                }),
                removeLocationsIfEmpty: true,
                removePersonGangTrackingIfEmpty: true,
            })
            .then((unknownPerson) => {
                dispatch(
                    augmentActionAndStoreRmsHydratedPersonProfiles(
                        createNoInfoKnownPersonSuccess([unknownPerson]),
                        [unknownPerson]
                    )
                );
                const formConfiguration = createPersonProfileFormConfiguration();

                const onSubmitSuccess = () => {
                    const personProfileResource = getPersonProfileResource();
                    return personProfileResource
                        .upsertHydratedPerson({
                            hydratedPerson: augmentHydratedNameWithAttachmentsAndReportLinks({
                                hydratedName: {
                                    personProfiles: [
                                        {
                                            id: entityId,
                                            ownerId,
                                            ownerType,
                                        },
                                    ],
                                },
                                entityType: EntityTypeEnum.PERSON_PROFILE.name,
                                entityId,
                                linkType,
                                ownerId,
                                contextId,
                                contextType,
                            }),
                            removeLocationsIfEmpty: true,
                        })
                        .then((unknownPerson) => {
                            dispatch(
                                augmentActionAndStoreRmsHydratedPersonProfiles(
                                    createNoInfoKnownPersonSuccess([unknownPerson]),
                                    [unknownPerson]
                                )
                            );
                            if (onAddSuccess) {
                                onAddSuccess(
                                    createOnAddSuccessNameReportLink({
                                        contextId,
                                        contextType,
                                        linkType,
                                        ownerId,
                                        hydratedName: unknownPerson,
                                        entityType: EntityTypeEnum.PERSON_PROFILE.name,
                                    })
                                );
                            }
                        });
                };

                const getScreenStackWithErrorMessages = (errorMessages) => {
                    return [
                        {
                            screen: SCREENS.PROFILE_EDIT,
                            screenState: {
                                errorMessages,
                                selectedId: entityId,
                            },
                            formSectionState: {
                                [CAUTIONS]: true,
                                [CORE_DETAILS]: true,
                                [CONTACT_INFO]: true,
                                [EVENT_SPECIFIC_INFO]: true,
                                [MISC_INFO]: true,
                            },
                        },
                    ];
                };

                return dispatch(
                    quickAddName({
                        formModel,
                        entityId,
                        onComplete,
                        arbiterInstance,
                        nameOverlayId: personOverlayId,
                        formContext,
                        formConfiguration,
                        onSubmitSuccess,
                        genericSaveError: quickAddStrings.genericSaveError,
                        getScreenStackWithErrorMessages,
                    })
                );
            });
    };
}

export const sortedRecentElasticPersonsForContextSelector = createSelector(
    recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
    elasticPersonsSelector,
    personProfilesSelector,
    nameReportLinksWhereSelector,
    currentReportIdSelector,
    isOffenseModifyingSupplementReportSelector,
    (
        recentEntitiesForOwnerTypeOwnerIdAndEntityType,
        elasticPersons,
        personProfiles,
        nameReportLinksWhere,
        currentReportId,
        isOffenseModifyingSupplementReport
    ) => ({ renForRecents, ownerType, ownerId, linkType, contextId, stubbedLinks }) => {
        const { recentEntityIdsByOwner = {} } =
            recentEntitiesForOwnerTypeOwnerIdAndEntityType({
                ownerType: renForRecents ? REN_IDENTIFIER : ownerType,
                ownerId: renForRecents || ownerId,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            }) || {};

        const recentEntityIds = isOffenseModifyingSupplementReport(currentReportId)
            ? recentEntityIdsByOwner[currentReportId] || []
            : recentEntityIdsByOwner[ownerId] || [];

        const existingNameReportLinks = nameReportLinksWhere({
            linkType,
            reportId: ownerId,
            entityType: EntityTypeEnum.PERSON_PROFILE.name,
            contextId,
        });

        const personIdsAlreadyLinked = chain(existingNameReportLinks)
            .concat(stubbedLinks)
            .flatMap((nrl) => {
                const personId = nrl.nameId;
                const person = personProfiles[personId] || elasticPersons[personId];
                const masterPersonId = get(person, 'masterPersonId');
                return [personId, masterPersonId];
            })
            .compact()
            .uniq()
            .value();

        const persons = chain(recentEntityIds)
            .uniq()
            .difference(personIdsAlreadyLinked)
            .map((recentPersonId) => elasticPersons[recentPersonId])
            .compact()
            .value();

        /*
            This change was introduced as part of the Dynamic Reports feature.
            Before this feature, the `persons` array always had objects for
            the same report since we only ever had one report on the page.

            Now that we are starting to store multiple reports on the page
            for the Dynamic Reports feature, we need to modify the person's
            object's owner id to match the ownerId of the report.

            This change also only affect Person's Quick Add as of 04/30/2021
        */
        const personsMappedToSameOwnerId = mapQuickAddEntitiesToSameOwnerId(
            persons,
            ownerId,
            'masterPersonId'
        );

        return chain(personsMappedToSameOwnerId)
            .sortBy([
                (elasticPerson) => get(elasticPerson, 'firstName', UNKNOWN).toLowerCase(),
                (elasticPerson) => get(elasticPerson, 'lastName', UNKNOWN).toLowerCase(),
            ])
            .value();
    }
);

const eventDateByReportIdSelector = createSelector(
    offensesByReportIdSelector,
    arrestForReportIdSelector,
    eventDetailByReportIdSelector,
    (offensesByReportId, arrestForReportId, eventDetailByReportId) => ({ ownerId }) => {
        let dateOfCalculation;
        const offenses = offensesByReportId(ownerId);
        const eventDetails = eventDetailByReportId(ownerId);
        if (!!offenses?.[0]) {
            dateOfCalculation = offenses[0].offenseDateUtc ?? eventDetails.eventStartUtc;
        }
        const currentArrest = arrestForReportId(ownerId);
        if (!!currentArrest) {
            dateOfCalculation = currentArrest.arrestDateUtc;
        }
        return dateOfCalculation;
    }
);

export const cadPersonProfilesSelector = createSelector(
    personProfilesSelector,
    nameNameLinksWhereSelector,
    (personProfiles, nameNameLinksWhere) => {
        const usedCadProfilesIds = nameNameLinksWhere({
            linkTypeId: LinkTypesEnum.RMS_PERSON_CREATED_FROM_CAD_PERSON,
        }).map((x) => x.nameToId);
        return filter(
            personProfiles,
            (profile) =>
                profile.ownerType === EntityTypeEnum.CAD_AGENCY_EVENT.name &&
                !usedCadProfilesIds.includes(profile.id)
        );
    }
);

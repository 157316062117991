import { ItemReportLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'itemReportLinks';

const itemReportLinksModule = createLinkModule<ItemReportLink>({
    type: NEXUS_STATE_PROP,
    keys: ['reportId'],
});

// SELECTORS
export const itemReportLinksSelector = itemReportLinksModule.selectors.linksSelector;

// REDUCER
export default itemReportLinksModule.reducerConfig;

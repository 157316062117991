import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { rolesForUserSelector } from '~/client-common/core/domain/roles/state/data';
import {
    DISPLAY_CASE_ASSIGNEE,
    DISPLAY_CASE_ASSISTING_INVESTIGATORS,
} from '~/client-common/core/enums/universal/fields';

import { currentUserIdSelector } from '../../../../../core/current-user/state/ui';

type InvolvementCellProps = {
    assigneeRoleId: number;
};

export const InvolvementCell: FC<InvolvementCellProps> = ({ assigneeRoleId }) => {
    const fields = useFields([DISPLAY_CASE_ASSIGNEE, DISPLAY_CASE_ASSISTING_INVESTIGATORS]);

    const currentUserId = useSelector(currentUserIdSelector) || -1;
    const rolesForUser = useSelector(rolesForUserSelector);

    const currentUserRoles = rolesForUser(currentUserId);
    const isCurrentUserAssignee = currentUserRoles[assigneeRoleId] !== undefined;

    const involvementString = isCurrentUserAssignee
        ? fields.DISPLAY_CASE_ASSIGNEE
        : fields.DISPLAY_CASE_ASSISTING_INVESTIGATORS;

    return <>{involvementString}</>;
};

import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { personGangTrackingForPersonIdSelector } from '~/client-common/core/domain/person-gang-trackings/state/data';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import formsRegistry from '../../../../core/formsRegistry';
import { createModalSelector } from '../../../core/box/state/ui';

import { handleSubmit, closeGangTrackingSidePanel } from '../state/ui/gangTrackingSidePanel';
import GangTrackingPersons from './GangTrackingPersons';
import GangTrackingSelectedPerson from './GangTrackingSelectedPerson';

const strings = componentStrings.reports.gangTracking.GangTrackingSidePanel;

const context = {
    name: boxEnum.GANG_TRACKING_SIDE_PANEL,
};

const CustomSidePanel = styled(SidePanel)`
    font-size: var(--arc-fontSizes-sm);
`;

class GangTrackingSidePanel extends React.Component {
    state = {
        selectedPerson: null,
        saveDisabled: true,
    };

    handleSubmit = (masterPersonId) => {
        this.props.handleSubmit(masterPersonId).then(() => {
            this.setState({
                selectedPerson: null,
            });
        });
    };

    handleClose = () => {
        // this needs to be expanded to include other ways the side panel can be closed
        this.setState({
            selectedPerson: null,
        });
        this.props.handleClose();
    };

    handleCancel = () => {
        this.setState({
            selectedPerson: null,
            saveDisabled: true,
        });
    };

    handlePersonClick = (personProfile) => {
        const formValue = personProfile.personGangTracking
            ? personProfile.personGangTracking.gangSubgroupAttrId ||
              personProfile.personGangTracking.gangNameAttrId
            : null;
        formsRegistry.maybeDeferredOperation(
            RefContextEnum.FORM_GANG_UNIT_SIDE_PANEL.name,
            0,
            (form) => {
                form.set('gangNameAttrId', formValue);
            }
        );
        this.setState({
            selectedPerson: personProfile,
        });
    };

    setSaveDisabled = (saveDisabled) => {
        this.setState({
            saveDisabled,
        });
    };

    render() {
        const { boxPayload, personGangTrackingForPersonId } = this.props;
        const { personProfilesWithCriteria, reportId } = boxPayload;
        const personProfilesWithCriteriaAndTracking = map(
            personProfilesWithCriteria,
            (personProfile) => {
                return {
                    ...personProfile,
                    personGangTracking: personGangTrackingForPersonId(
                        personProfile.personProfile.masterPersonId
                    ),
                };
            }
        );
        let personName;
        if (this.state.selectedPerson) {
            personName = formatFullName(this.state.selectedPerson.personProfile);
        }
        return (
            <CustomSidePanel
                title={this.state.selectedPerson ? strings.personTitle(personName) : strings.title}
                cancelText={this.state.selectedPerson ? strings.cancel : strings.close}
                saveText={this.state.selectedPerson ? strings.save : ''}
                onSave={() =>
                    this.handleSubmit(this.state.selectedPerson.personProfile.masterPersonId)
                }
                onCancel={this.state.selectedPerson ? this.handleCancel : this.handleClose}
                context={context}
                saveDisabled={this.state.saveDisabled}
            >
                {this.state.selectedPerson ? (
                    <GangTrackingSelectedPerson
                        handleCancel={this.handleCancel}
                        personProfileWithCriteria={this.state.selectedPerson}
                        setSaveDisabled={this.setSaveDisabled}
                        reportId={reportId}
                    />
                ) : (
                    <GangTrackingPersons
                        onClick={this.handlePersonClick}
                        personProfilesWithCriteria={personProfilesWithCriteriaAndTracking}
                    />
                )}
            </CustomSidePanel>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    reportById: reportByIdSelector,
    boxPayload: createModalSelector(context),
    personGangTrackingForPersonId: personGangTrackingForPersonIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    handleSubmit: (masterPersonId) => dispatch(handleSubmit(masterPersonId)),
    handleClose: () => dispatch(closeGangTrackingSidePanel()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GangTrackingSidePanel);

import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Department Profile Admin Resource',
    methods: {
        getDepartmentProfile(deptId) {
            return req({
                method: 'GET',
                url: `admin/department/${deptId}`,
            });
        },
        upsertDepartmentProfile(deptProfile) {
            return req({
                method: 'PUT',
                url: 'admin/department',
                data: deptProfile,
            });
        },
        editDepartmentStatus(deptProfile) {
            return req({
                method: 'POST',
                url: 'admin/department/status',
                data: deptProfile,
            });
        },
        allowSearchResyncs(deptId, allowSearchResyncs) {
            return req({
                method: 'POST',
                url: `admin/security/departments/${deptId}/set_allow_search_resyncs`,
                data: { data: allowSearchResyncs },
            });
        },
        startReset(type, service, resetOptions) {
            return req({
                method: 'POST',
                url: `admin/department/reset/start/${type}/${service}`,
                data: resetOptions,
            });
        },
        saveLandingBackground(deptId, fileId) {
            return req({
                method: 'POST',
                url: `admin/department/${deptId}/background/${fileId}`,
            });
        },
        deleteLandingBackground(deptId) {
            return req({
                method: 'DELETE',
                url: `admin/department/${deptId}/background`,
            });
        },
        updateDepartmentSubdomain(subdomain) {
            return req({
                method: 'POST',
                url: `admin/department/${subdomain}`,
                data: subdomain,
            });
        },
    },
});

import { ElasticVehicle } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticVehicles';

const elasticVehiclesModule = createNormalizedModule<ElasticVehicle>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS
export const elasticVehiclesSelector = elasticVehiclesModule.selectors.entitiesSelector;

export default elasticVehiclesModule.reducerConfig;

import React from 'react';

import { formatCode } from '~/client-common/core/domain/codes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import { CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';

const strings = componentStrings.reports.core.EventInfoCard;

const UcrOffenseSummary = ({ ucrOffense, codesByCodeId, formatUcrSummaryOffenseCodeByCode }) => {
    const displayFieldNames = useFields(fields.UCR_OFFENSE_UCR_CODE);
    return (
        <CardSubsection
            title={strings.ucrClassificationOffenseSectionTitle(
                displayFieldNames.UCR_OFFENSE_UCR_CODE,
                ucrOffense.sequenceNumber
            )}
        >
            <SummaryRow fieldName={fields.UCR_OFFENSE_UCR_CODE}>
                {formatUcrSummaryOffenseCodeByCode(ucrOffense.ucrSummaryCodeCode)}
            </SummaryRow>
            <SummaryRowDate
                fieldName={fields.UCR_OFFENSE_OFFENSE_STATUS_DATE_UTC}
                date={ucrOffense.offenseStatusDateUtc}
                format={SummaryRowDate.FORMATS.SUMMARY_DATE}
            />
            <SummaryRow fieldName={fields.UCR_OFFENSE_OFFENSE_STATUS_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.offenseStatusCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_OFFENSE_SCORE}>
                {ucrOffense.offenseScore}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_ALL_SUSPECTS_ARE_JUVENILE}>
                {booleanToYesNo(ucrOffense.allSuspectsAreJuvenile)}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_IS_SUSPECTED_HATE_CRIME}>
                {booleanToYesNo(ucrOffense.isSuspectedHateCrime)}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_IS_DOMESTIC_VIOLENCE}>
                {booleanToYesNo(ucrOffense.isDomesticViolence)}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_RELATIONSHIP_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.relationshipCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_HOMICIDE_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.homicideCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_HOMICIDE_WEAPON_OR_FORCE_INVOLVED_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.homicideWeaponOrForceInvolvedCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_NEGLIGENT_MANSLAUGHTER_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.negligentManslaughterCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_ARSON_LOCATION_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.arsonLocationCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_ROBBERY_LOCATION_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.robberyLocationCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_BURGLARY_RESIDENCE_TYPE_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.burglaryResidenceTypeCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_BURGLARY_TIME_OF_DAY_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.burglaryTimeOfDayCodeId))}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_ARSON_BUILDING_INHABITED}>
                {booleanToYesNo(ucrOffense.arsonBuildingInhabited)}
            </SummaryRow>
            <SummaryRow fieldName={fields.UCR_OFFENSE_LARCENY_THEFT_CATEGORY_CODE_ID}>
                {formatCode(codesByCodeId(ucrOffense.larcenyTheftCategoryCodeId))}
            </SummaryRow>
        </CardSubsection>
    );
};

export default UcrOffenseSummary;

import { ApprovalStatusEnum, ReportStatusView } from '@mark43/rms-api';

import { get, noop } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { pollImportEvent } from '~/client-common/core/domain/import-events/state/data';
import { custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import { loadReportActiveStatus, submitReport } from '../data';
import {
    openBox,
    saveBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import {
    setCanSubmit,
    setReportStatusView,
} from '../../../../../legacy-redux/actions/reportsActions';
import {
    currentReportIdSelector,
    currentReportSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { loadEvidenceDataForCurrentReport } from '../../../custodial-property-summary/state/ui';
import errors from '../../../../../lib/errors';
import attachmentsCard from '../../../../warrants/warrant/state/cards/attachmentsCard';
import { RmsAction } from '../../../../../core/typings/redux';
import { actuallyRedraftReport } from './submissions';
import custodialPropertyCard from './custodialPropertyCard';

const reportSubmissionModalContext = {
    name: boxEnum.REPORT_SUBMISSION_MODAL,
};

const returnReportToDraftModalContext = {
    name: boxEnum.RETURN_REPORT_TO_DRAFT_MODAL,
};

/**
 * Load the current report's active status. Update ui state.
 */
export function loadCurrentReportActiveStatus(
    providedReportId?: number
): RmsAction<Promise<ReportStatusView>> {
    return function (dispatch, getState) {
        const state = getState();
        const reportId = providedReportId || currentReportIdSelector(state);
        if (typeof reportId !== 'number') {
            return Promise.reject(
                new errors.Mark43Error('No reportId provided and no currentReportId')
            );
        }
        return dispatch(loadReportActiveStatus(reportId)).then(
            (reportStatusView: ReportStatusView) => {
                dispatch(setCanSubmit(reportStatusView.canSubmit));
                dispatch(setReportStatusView(reportStatusView));
                return reportStatusView;
            }
        );
    };
}

/**
 * Open the report submission modal.
 * Custodial Propery Summary Report will attempt to save its cards
 * before opening the submission modal.
 */
export function openReportSubmissionModal(): RmsAction<void> {
    return function (dispatch, getState) {
        const state = getState();
        const custodialId = get(
            custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector(state),
            'id'
        );
        const currentReport = currentReportSelector(state);

        if (currentReport && currentReport.reportDefinitionId === custodialId) {
            return dispatch(custodialPropertyCard.actionCreators.save())
                .then(() => dispatch(attachmentsCard.actionCreators.save()))
                .then(() => dispatch(openBox(reportSubmissionModalContext)))
                .catch(noop);
        } else {
            return dispatch(openBox(reportSubmissionModalContext));
        }
    };
}

/**
 * Attempt to submit the current report. If the report is not in draft, do not
 *   submit.
 */
export function submitReportSubmissionModal(): RmsAction<Promise<ReportStatusView>> {
    return function (dispatch, getState) {
        dispatch(saveBoxStart(reportSubmissionModalContext));
        return (
            dispatch(loadCurrentReportActiveStatus())
                .then((reportStatusView: ReportStatusView) => {
                    if (reportStatusView.report.approvalStatus === ApprovalStatusEnum.DRAFT.name) {
                        // this is an array to pass the response data along the
                        // promise chain, because the submit endpoint only returns a
                        // success boolean
                        return [
                            reportStatusView,
                            dispatch(submitReport(reportStatusView.report.id)),
                        ];
                    } else {
                        throw new errors.Mark43Error(
                            'The report has already been submitted, please reload.'
                        );
                    }
                })
                // sync data and ui state from the server since the approval status
                // has changed
                .spread<[ReportStatusView, ReportStatusView]>(
                    (reportStatusView: ReportStatusView) => [
                        reportStatusView,
                        dispatch(loadCurrentReportActiveStatus()),
                    ]
                )
                // handle submission success, close the modal
                .spread<ReportStatusView>((reportStatusView: ReportStatusView) => {
                    dispatch(saveBoxSuccess(reportSubmissionModalContext));
                    return reportStatusView;
                })
                // handle submission failure, keep the modal open
                .catch((err: Error) => {
                    dispatch(saveBoxFailure(reportSubmissionModalContext, err.message));
                })
                // special case for evidence: after submitting a custodial property
                // summary report, poll the Import Event
                .then((reportStatusView) => {
                    const state = getState();
                    const custodialId = get(
                        custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector(state),
                        'id'
                    );
                    if (reportStatusView.report.reportDefinitionId === custodialId) {
                        const reportId = reportStatusView.report.id;
                        return (
                            // @ts-expect-error client-common transport to client
                            dispatch(pollImportEvent(reportId))
                                // @ts-expect-error client-common transport to client
                                .then(() => {
                                    // reload all evidence data, including
                                    // assistingOfficers since the list of "involved
                                    // officers" may have changed
                                    dispatch(loadEvidenceDataForCurrentReport());
                                    return reportStatusView;
                                })
                                // swallow error here as it gets displayed in the
                                // custodial property card
                                .catch(noop)
                        );
                    } else {
                        return reportStatusView;
                    }
                })
        );
    };
}

const reportRejectionModalContext = { name: boxEnum.REPORT_REJECTION_MODAL };

export function openReportRejectionModal({
    callback,
}: {
    callback: (reasonForRejection: string) => void;
}): RmsAction<void> {
    return (dispatch) => {
        dispatch(openBox(reportRejectionModalContext, { callback }));
    };
}

/**
 * Open the modal that lets the user return the current report to draft.
 */
export function openReturnReportToDraftModal({
    callback,
}: {
    callback: () => void;
}): RmsAction<void> {
    return (dispatch) => {
        dispatch(openBox(returnReportToDraftModalContext, { callback }));
    };
}

/**
 * Attempt to return the current report to draft. Update all relevant states.
 */
export function submitReturnReportToDraftModal(): RmsAction<void> {
    return (dispatch) => {
        dispatch(saveBoxStart(returnReportToDraftModalContext));
        return dispatch(
            actuallyRedraftReport(() => dispatch(saveBoxSuccess(returnReportToDraftModalContext)))
        );
    };
}

import { isArray } from 'lodash';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import boxActionTypes from './types/boxActionTypes';

const {
    OPEN_BOX,
    CLOSE_BOX,
    SAVE_BOX_START,
    SAVE_BOX_HALT,
    SAVE_BOX_SUCCESS,
    SAVE_BOX_FAILURE,
    STORE_BOX_ERROR_MESSAGES,
    LOAD_BOX_START,
    STORE_PAYLOAD,
} = boxActionTypes;

export function openBox(context, payload) {
    return {
        type: OPEN_BOX,
        meta: context,
        payload,
    };
}

/**
 * Store payload to a specific box state.
 * Can use `createModalSelector` from client/src/scripts/modules/core/box/state/ui/index.js
 * to get the data out.
 * @param  {Object} context Box Context
 * @param  {Object} payload Data to store
 */
export function storePayloadToBox(context, payload) {
    return {
        type: STORE_PAYLOAD,
        meta: context,
        payload,
    };
}

export function closeBox(context) {
    return {
        type: CLOSE_BOX,
        meta: context,
    };
}

/**
 * Add loading gif to a specfic box.
 * This action shouldn't cause any other effects besides a loading gif.
 * You can stop the loading gif by calling `saveBoxHalt`
 * If you want the loading gif + submit/cancel buttons disabled, use `saveBoxStart`
 * @param  {[type]} context [description]
 * @return {[type]}         [description]
 */
export function loadBoxStart(context) {
    return {
        type: LOAD_BOX_START,
        meta: context,
    };
}

export function saveBoxStart(context) {
    return {
        type: SAVE_BOX_START,
        meta: context,
    };
}

export function saveBoxHalt(context) {
    return {
        type: SAVE_BOX_HALT,
        meta: context,
    };
}

export function saveBoxSuccess(context) {
    return {
        type: SAVE_BOX_SUCCESS,
        meta: context,
    };
}

/**
 * Put a specific box into its error state and show error messages (a default
 *   error message is set if not provided).
 * @param  {Object}          context
 * @param  {string|string[]} [errorMessages]
 */
export function saveBoxFailure(context, errorMessages = [componentStrings.validation.serverError]) {
    return {
        type: SAVE_BOX_FAILURE,
        payload: isArray(errorMessages) ? errorMessages : [errorMessages],
        meta: context,
    };
}

/**
 * Store error messages for a specific box.
 * @param  {Object}          context
 * @param  {string|string[]} errorMessages
 * @return {Object}
 */
export function storeBoxErrorMessages(context, errorMessages) {
    return {
        type: STORE_BOX_ERROR_MESSAGES,
        payload: isArray(errorMessages) ? errorMessages : [errorMessages],
        meta: context,
    };
}

/**
 * Open the loading modal.
 */
export function openLoadingModal() {
    return function (dispatch) {
        return dispatch(
            openBox({
                name: boxEnum.LOADING_MODAL,
            })
        );
    };
}

/**
 * Close the loading modal.
 */
export function closeLoadingModal() {
    return function (dispatch) {
        return dispatch(
            closeBox({
                name: boxEnum.LOADING_MODAL,
            })
        );
    };
}

/**
 * Open the confirmation modal with the given title, message, and saveCallback.
 *  @param {object}    options
 *  @param {string}    options.title   Title of Modal
 *  @param {string}    options.message Message to be displayed in Modal
 *  @param {Function}  options.saveCallback callback to run on confirmation
 *  @param {Function} [options.cancelCallback] callback to run on cancel, defaults to closing modal
 *  @param {boolean}  [options.messageContainsNewLine] options.messageContainsNewLine default false, set true if message contains new line characters
 *  @param {string}   [options.okText]  used for confirmation button if provided, defaults to 'Confirm''
 *  @param {string}   [options.cancelText]  used for cancel button if provided, defaults to 'Cancel''
 */
export function openConfirmationModal({
    title,
    message,
    saveCallback,
    cancelCallback,
    messageContainsNewLine,
    okText,
    cancelText,
}) {
    return openBox(
        {
            name: boxEnum.CONFIRMATION_MODAL,
        },
        { title, message, saveCallback, cancelCallback, messageContainsNewLine, okText, cancelText }
    );
}

/**
 * Close the confirmation modal.
 */
export function closeConfirmationModal(cancelCallback) {
    return function (dispatch) {
        if (cancelCallback) {
            return dispatch(
                closeBox(
                    {
                        name: boxEnum.CONFIRMATION_MODAL,
                    },
                    { cancelCallback }
                )
            );
        } else {
            return dispatch(
                closeBox({
                    name: boxEnum.CONFIRMATION_MODAL,
                })
            );
        }
    };
}

/**
 * Open the error modal with the given error text.
 * @param   {Object} props
 * @param   {string} props.title
 * @param   {string[]} [props.paragraphs]
 * @param   {string[]} [props.list]
 */
export function openErrorModal({ title, paragraphs = [], list = [] }) {
    return function (dispatch) {
        return dispatch(
            openBox(
                {
                    name: boxEnum.ERROR_MODAL,
                },
                { title, paragraphs, list }
            )
        );
    };
}

import { EntityTypeEnum } from '@mark43/rms-api';
import _ from 'lodash';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Flex, Popover, PopoverContent, PopoverTrigger, PopoverClose, Text as ArcText } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Button } from '../../../../../../core/components/Button';
import StaffRemarks from '../../../../../core/components/StaffRemarks';
import type { ManageEvidenceDashboardResults } from '../../../../types';

const strings = componentStrings.core.tables.elasticEvidenceTable.StaffRemarksCell;

type EvidenceStaffRemarksCellProps = Pick<
    ManageEvidenceDashboardResults,
    'staffRemarks' | 'reportingEventNumber'
>;
const StyledPopoverContent = styled(PopoverContent)`
    max-height: 40vh;
    overflow-y: auto;
`;

export const EvidenceStaffRemarksCell = ({
    staffRemarks,
    reportingEventNumber,
}: EvidenceStaffRemarksCellProps) => {
    const reportLevelStaffRemarks = _(staffRemarks)
        .filter({ entityType: EntityTypeEnum.REPORT.name })
        .sortBy('remarkDateUtc')
        .reverse()
        .value();
    const itemLevelStaffRemarks = _(staffRemarks)
        .filter({ entityType: EntityTypeEnum.ITEM_PROFILE.name })
        .sortBy('remarkDateUtc')
        .reverse()
        .value();

    const [isOpen, setIsOpen] = useState(false);
    const closePopover = () => {
        setIsOpen(false);
    };
    const popoverButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <Popover isOpen={isOpen}>
            <PopoverTrigger asChild>
                <Button
                    size="sm"
                    variant="ghost"
                    leadingVisual="Messaging"
                    onClickCapture={(e) => {
                        e.stopPropagation();
                        setIsOpen((prev) => !prev);
                    }}
                    ref={popoverButtonRef}
                >
                    {staffRemarks.length}
                </Button>
            </PopoverTrigger>
            <StyledPopoverContent
                hideWhenDetached
                align="end"
                onEscapeKeyDown={closePopover}
                onInteractOutside={(e) => {
                    const target = e.target instanceof Node ? e.target : null;
                    /** If it's the popover button, do nothing and let the button action toggle the popover.
                     * Otherwise it closes it and then the button action immediately re-opens the popover.
                     */
                    if (
                        popoverButtonRef.current &&
                        (target === popoverButtonRef.current ||
                            popoverButtonRef.current.contains(target))
                    ) {
                        return;
                    }
                    closePopover();
                }}
            >
                <PopoverClose
                    onClickCapture={(e) => {
                        e.stopPropagation();
                        setIsOpen(false);
                    }}
                />
                <Flex flexDirection="column" gap={4}>
                    {reportLevelStaffRemarks.length > 0 && (
                        <>
                            <ArcText variant="headingSm">{reportingEventNumber}</ArcText>
                            <StaffRemarks staffRemarks={reportLevelStaffRemarks} />
                        </>
                    )}
                    {itemLevelStaffRemarks.length > 0 && (
                        <>
                            <ArcText variant="headingSm">{strings.itemLevelStaffRemarks}</ArcText>
                            <StaffRemarks staffRemarks={itemLevelStaffRemarks} />
                        </>
                    )}
                </Flex>
            </StyledPopoverContent>
        </Popover>
    );
};

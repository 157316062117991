import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import strings from '~/client-common/core/strings/componentStrings';

import { reportUnsealingSubmissionErrorSelector } from '../state/ui';
import reportUnsealingForm from '../state/forms/reportUnsealingForm';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { ColumnLeft, ColumnRight, ColumnFull } from '../../core/components/RecordPrivacyColumn';
import { FlexContainer, InnerFlexContainer } from '../../core/components/RecordPrivacyContainers';
import { RightColumnNote, RightColumnNoteTopMargin } from '../../core/components/RecordPrivacyNote';
import { Form } from '../../core/components/Form';
import SubSectionTitle from '../../core/components/SubSectionTitle';
import CourtOrderSummary from './CourtOrderSummary';
import ReportUnsealingFormFields from './ReportUnsealingFormFields';

const ReportUnsealingForm = ({ report, submissionError, courtOrder, forceNarrativeUnseal }) => (
    <Form {...reportUnsealingForm}>
        <FlexContainer>
            {submissionError && (
                <ColumnFull flexGrow={0}>
                    <InlineBanner status="error">{submissionError}</InlineBanner>
                </ColumnFull>
            )}
            <InnerFlexContainer>
                <ColumnLeft>
                    {courtOrder && <CourtOrderSummary courtOrder={courtOrder} />}
                </ColumnLeft>
                <ColumnRight>
                    <SubSectionTitle>
                        {strings.recordPrivacy.shared.sections.involvedPersons.title}
                    </SubSectionTitle>
                    <RightColumnNote>
                        {
                            strings.recordPrivacy.unsealing.ReportUnsealingForm.sections
                                .involvedPersons.note
                        }
                    </RightColumnNote>
                    <SubSectionTitle>
                        {strings.recordPrivacy.shared.sections.charges.title}
                    </SubSectionTitle>
                    <RightColumnNote>
                        {strings.recordPrivacy.unsealing.ReportUnsealingForm.sections.charges.note}
                    </RightColumnNote>
                    <SubSectionTitle>
                        {strings.recordPrivacy.shared.sections.narrative.title}
                    </SubSectionTitle>
                    <RightColumnNote>
                        {
                            strings.recordPrivacy.unsealing.ReportUnsealingForm.sections.narrative
                                .note
                        }
                    </RightColumnNote>
                    <ReportUnsealingFormFields
                        report={report}
                        disableNarrativeSealing={forceNarrativeUnseal}
                    />
                    <RightColumnNoteTopMargin>
                        {
                            strings.recordPrivacy.unsealing.ReportUnsealingForm
                                .unsealReportContentsNote
                        }
                    </RightColumnNoteTopMargin>
                </ColumnRight>
            </InnerFlexContainer>
        </FlexContainer>
    </Form>
);

const mapStateToProps = createStructuredSelector({
    submissionError: reportUnsealingSubmissionErrorSelector,
});

export default connect(mapStateToProps)(ReportUnsealingForm);

import styled from 'styled-components';
import { responsiveStack } from '../../../core/styles/mixins';

export const FlexContainer = styled.div`
    display: flex;
    min-height: 100%;
    flex-direction: column;
`;

export const InnerFlexContainer = styled.div`
    ${responsiveStack()};
    min-height: 100%;
    height: auto;
    flex: 1 1 100%;
`;

export const NarrativeContainer = styled.div`
    font-size: var(--arc-fontSizes-sm);
    overflow: auto;
    height: 200px;
    background: ${(props) =>
        props.disabled ? props.theme.colors.extraLightGrey : props.theme.colors.white};
    color: ${(props) => (props.disabled ? props.theme.colors.mediumGrey : props.theme.colors.text)};
    padding: 10px;
    border: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: inset 0 6px 0 0 ${(props) => props.theme.colors.lightGrey};
`;

export const PreviouslySealedNoteContainer = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.bold};
    font-size: var(--arc-fontSizes-sm);
    margin-top: 7px;
`;

import { ElasticSearchTypeEnum, ProductModuleEnum } from '@mark43/rms-api';
import { createStructuredSelector } from 'reselect';
import { isNil } from 'lodash';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import createSearchModule from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import advancedSearchCadTicketsForm, {
    convertAdvancedSearchCadTicketsFormModelToFilterGroups,
    convertAdvancedSearchCadTicketsElasticQueryToFormModel,
} from '../forms/advancedSearchCadTicketsForm';
import { openBox } from '../../../../../legacy-redux/actions/boxActions';
import { openCreateReportModalWithReportingEventNumber } from '../../../../../legacy-redux/actions/renSearchModalActions';
import createCadTicketsResultsSelector from '../../../core/utils/createCadTicketsResultsSelector';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';
import recursivelyConvertElasticLocationQueries from '../../../core/utils/recursivelyConvertElasticLocationQueries';
import { openCreateReportModalFromCadEvent } from '../../../../reports/core/state/ui/createReportModal';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
function convertAdvancedSearchCadTicketsElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    const elasticQueryTransformed = recursivelyConvertElasticLocationQueries(elasticQuery, {
        legacyQueryProp: 'involvedLocations',
        newQueryProp: 'locations',
    });

    return convertAdvancedSearchCadTicketsFormModelToFilterGroups(
        convertAdvancedSearchCadTicketsElasticQueryToFormModel(
            elasticQueryTransformed,
            null,
            boundSelectors
        ),
        formatFieldValue,
        boundSelectors
    );
}

const advancedSearchCadTickets = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.CAD_TICKET.name,
    baseUiSelector: (state) => state.ui.advancedSearch.cadTickets,
    form: advancedSearchCadTicketsForm,
    elasticQueryToFilterGroups: convertAdvancedSearchCadTicketsElasticQueryToFilterGroups,
    resourceMethod: elasticSearchResource.searchCadTickets,
    resultsContainerClassName: 'cad-tickets-results-container',
    createResultsViewModelsSelector: createCadTicketsResultsSelector,
    selectorToBind: createStructuredSelector({
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
        applicationSettings: applicationSettingsSelector,
    }),
    transformElasticQueryBeforeSearchSelector: (state) => (elasticQuery) => {
        const transformIds = transformElasticQueryIdsToDisplayValuesSelector(state);
        const queryWithTransformedIds = transformIds(elasticQuery);
        return recursivelyConvertElasticLocationQueries(queryWithTransformedIds, {
            legacyQueryProp: 'locations',
            newQueryProp: 'involvedLocations',
        });
    },
});

/**
 * Attempt to open a CAD ticket search result (on click). If the CAD ticket
 *   requires a report, open the REN modal with the REN search form filled in
 *   and disabled, allowing the user to open or create a report; otherwise, open
 *   a modal to alert the user that the CAD ticket does not require a report.
 * @param  {Object} elasticCadTicket
 */
advancedSearchCadTickets.actionCreators.openSearchResult = function (
    { departmentId, requiresReport, reportingEventNumber, agencyEventNumber },
    rowIndex,
    scrollPosition
) {
    return (dispatch, getState) => {
        if (rowIndex) {
            dispatch(advancedSearchCadTickets.actionCreators.highlightRows([rowIndex]));
        }
        const rmsEnabled = isProductModuleActiveSelector(getState())(
            ProductModuleEnum.REPORTS.name
        );
        if (!rmsEnabled) {
            return;
        }

        const currentUserDepartmentId = currentUserDepartmentIdSelector(getState());
        if (currentUserDepartmentId !== departmentId) {
            return;
        }

        if (!isNil(scrollPosition)) {
            dispatch(advancedSearchCadTickets.actionCreators.setScrollPosition(scrollPosition));
        }

        const recordsWithoutARenFlag = applicationSettingsSelector(getState())
            .RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;

        if (recordsWithoutARenFlag) {
            dispatch(
                openCreateReportModalFromCadEvent({
                    reportingEventNumber,
                    cadAgencyEventNumber: agencyEventNumber,
                })
            );
        } else {
            if (requiresReport) {
                // report is required: open REN modal
                dispatch(
                    openCreateReportModalWithReportingEventNumber({
                        reportingEventNumber,
                    })
                );
            } else {
                dispatch(
                    openBox({
                        name: boxEnum.CAD_TICKET_DOES_NOT_REQUIRE_REPORT_MODAL,
                    })
                );
            }
        }
    };
};

/**
 * Redux module for advanced search CAD tickets. Unique to this search module is
 *   an action creator for opening CAD ticket search results.
 * @type {Object}
 */
export default advancedSearchCadTickets;

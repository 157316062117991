import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const strings = componentStrings.search.AdvancedSearchVehiclesResults.IdentifiersCell.labels;

export default function IdentifiersCell(elasticVehicle) {
    const { vinNumber, tag, registrationYear } = elasticVehicle;

    const { registrationStateAttrId } = getViewModelProperties(elasticVehicle);

    const vinNumberInfo = vinNumber && (
        <div>
            <div className="info-label">{strings.vin}</div>
            <div className="info-text">{vinNumber}</div>
        </div>
    );

    return (
        <div className="elastic-vehicle-identifiers">
            {vinNumberInfo}
            <div className="elastic-vehicle-other-identifiers">
                {tag && (
                    <div>
                        <div className="info-label">{strings.tag}</div>
                        <div className="info-text">{tag}</div>
                    </div>
                )}
                {registrationStateAttrId && (
                    <div>
                        <div className="info-label">{strings.registrationState}</div>
                        <div className="info-text">{registrationStateAttrId}</div>
                    </div>
                )}
                {registrationYear && (
                    <div>
                        <div className="info-label">{strings.registrationYear}</div>
                        <div className="info-text">{registrationYear}</div>
                    </div>
                )}
            </div>
        </div>
    );
}

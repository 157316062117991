import React from 'react';
import { useSelector } from 'react-redux';
import { FormControlProps } from 'arc';
import { AttributeTypeEnum } from '@mark43/rms-api';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import { getSubdivisionShownMap } from '~/client-common/core/domain/locations/utils/subdivisionHelpers';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import Row from '../../../core/components/Row';
import { ValueType } from '../../../core/forms/components/Text';

interface SubdivisionsSearchProps {
    width?: number;
    length?: FormControlProps['length'];
    onChange?: (value: ValueType) => void;
}

export const SubdivisionsSearch: React.FC<SubdivisionsSearchProps> = ({
    width,
    length,
    onChange,
}) => {
    const fieldConfigurationContextByContextAndFieldName = useSelector(
        fieldConfigurationContextByContextAndFieldNameSelector
    );

    const subdivisionShownMap = getSubdivisionShownMap(
        fieldConfigurationContextByContextAndFieldName
    );

    return (
        <FeatureFlagged flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED">
            <Row>
                {subdivisionShownMap[fields.LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID] && (
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_1.name}
                        multiple={true}
                        includeExpired={true}
                        width={width}
                        length={length}
                        path="subdivision1AttrIds"
                        onChange={onChange}
                    />
                )}
                {subdivisionShownMap[fields.LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID] && (
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_2.name}
                        multiple={true}
                        includeExpired={true}
                        width={width}
                        length={length}
                        path="subdivision2AttrIds"
                        onChange={onChange}
                    />
                )}
                {subdivisionShownMap[fields.LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID] && (
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_3.name}
                        multiple={true}
                        includeExpired={true}
                        width={width}
                        length={length}
                        path="subdivision3AttrIds"
                        onChange={onChange}
                    />
                )}
            </Row>
            <Row>
                {subdivisionShownMap[fields.LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID] && (
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_4.name}
                        multiple={true}
                        includeExpired={true}
                        width={width}
                        length={length}
                        path="subdivision4AttrIds"
                        onChange={onChange}
                    />
                )}
                {subdivisionShownMap[fields.LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID] && (
                    <RRFAttributeSelect
                        attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_5.name}
                        multiple={true}
                        includeExpired={true}
                        width={width}
                        length={length}
                        path="subdivision5AttrIds"
                        onChange={onChange}
                    />
                )}
            </Row>
        </FeatureFlagged>
    );
};

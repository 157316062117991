import { SearchableConfiguredPropertyOptionView } from '@mark43/rms-api';
import type { SelectOption } from '../../../../core/forms/helpers/selectHelpers';
import { createConfigurdEntityPropertyOptionValue } from './optionValue';

export function convertSearchableConfiguredPropertyOptionViewToOption(
    option: SearchableConfiguredPropertyOptionView
): SelectOption {
    return {
        label: option.display,
        value: createConfigurdEntityPropertyOptionValue(option),
    };
}

const BASE_URL = '/cad/api';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'CAD Rolodex Resource',
        methods: {
            /**
             * @param {number | string} fileId
             * @return {Promise}
             */
            importRolodexProfiles(fileId) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `rolodex/import/${fileId}`,
                });
            },
            /**
             * @return {Promise}
             */
            exportRolodexProfiles() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: 'rolodex/export',
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import pluralize from 'pluralize';

import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';

import testIds from '../../../../core/testIds';
import {
    currentCaseLinkedProfileViewModelsSelector,
    formatPersonInvolvementSelector,
    formatOrganizationInvolvementSelector,
} from '../state/ui';
import CaseLinkedProfilesBlock from './CaseLinkedProfilesBlock';
import CaseLinkedPerson from './CaseLinkedPerson';
import CaseLinkedOrganization from './CaseLinkedOrganization';
import CaseLinkedLocation from './CaseLinkedLocation';
import CaseLinkedVehicle from './CaseLinkedVehicle';
import CaseLinkedProperty from './CaseLinkedProperty';
import CaseLinkedProfilesSidePanel from './CaseLinkedProfilesSidePanel';
import CaseLinkedProfilesErrors from './CaseLinkedProfilesErrors';
import CaseLinkedProfilesHeaderButtons from './CaseLinkedProfilesHeaderButtons';

const strings = componentStrings.cases.linkedProfiles;

function CaseLinkedProfiles({
    linkedProfiles: {
        relatedPersons = [],
        relatedOrganizations = [],
        relatedLocations = [],
        relatedVehicles = [],
        relatedProperties = [],
    },
    formatPersonInvolvement,
    formatOrganizationInvolvement,
}) {
    const { DISPLAY_ONLY_ORGANIZATION_LABEL: organizationLabel } = useFields([
        DISPLAY_ONLY_ORGANIZATION_LABEL,
    ]);

    const caseLinkedPersons = relatedPersons.filter(Boolean).map((relatedPerson) => (
            <CaseLinkedPerson
                key={relatedPerson.id}
                person={relatedPerson}
                formattedInvolvement={formatPersonInvolvement(relatedPerson.id)}
            />
        ));
    const caseLinkedOrganizations = relatedOrganizations.filter(Boolean).map((relatedOrganization) => (
            <CaseLinkedOrganization
                key={relatedOrganization.id}
                organization={relatedOrganization}
                formattedInvolvement={formatOrganizationInvolvement(relatedOrganization.id)}
            />
        ));
    const caseLinkedLocations = relatedLocations.filter(Boolean).map((relatedLocation) => (
        <CaseLinkedLocation key={relatedLocation.id} elasticLocation={relatedLocation} />
    ));
    const caseLinkedVehicles = relatedVehicles.filter(Boolean).map((relatedVehicle) => (
        <CaseLinkedVehicle key={relatedVehicle.id} vehicle={relatedVehicle} />
    ));
    const caseLinkedProperty = relatedProperties.filter(Boolean).map((relatedProperty) => (
        <CaseLinkedProperty key={relatedProperty.id} property={relatedProperty} />
    ));
    return (
        <div className="case-linked-profiles" data-test-id={testIds.CASE_LINKED_PROFILES}>
            <CaseLinkedProfilesErrors />
            <CaseLinkedProfilesSidePanel renderButton={() => <CaseLinkedProfilesHeaderButtons />} />
            <CaseLinkedProfilesBlock title={strings.persons.title}>
                {caseLinkedPersons}
            </CaseLinkedProfilesBlock>
            <CaseLinkedProfilesBlock title={pluralize(organizationLabel)}>
                {caseLinkedOrganizations}
            </CaseLinkedProfilesBlock>
            <CaseLinkedProfilesBlock title={strings.locations.title}>
                {caseLinkedLocations}
            </CaseLinkedProfilesBlock>
            <CaseLinkedProfilesBlock title={strings.vehicles.title}>
                {caseLinkedVehicles}
            </CaseLinkedProfilesBlock>
            <CaseLinkedProfilesBlock title={strings.property.title}>
                {caseLinkedProperty}
            </CaseLinkedProfilesBlock>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    linkedProfiles: currentCaseLinkedProfileViewModelsSelector,
    formatPersonInvolvement: formatPersonInvolvementSelector,
    formatOrganizationInvolvement: formatOrganizationInvolvementSelector,
});

export default connect(mapStateToProps)(CaseLinkedProfiles);

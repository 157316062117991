import React from 'react';
import { map, compact, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withHandlers } from 'recompose';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import advancedSearchExportForm from '../state/forms/advancedSearchExportForm';
import advancedSearchReports from '../../reports/state/ui';
import GrayBar from '../../../../legacy-redux/components/styling/GrayBar';
import { SectionHeader } from '../../../../legacy-redux/components/exports/FormExportsOptionHeaders';
import testIds from '../../../../core/testIds';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';

const strings = componentStrings.search.AdvancedSearchExport;

const AdvancedSearchExport = function AdvancedSearchExport({
    reportsPrintables,
    onSubmit,
    onCancel,
}) {
    return (
        <ScrollableUnderSubheader>
            <div className="advanced-search-export" data-test-id={testIds.ADVANCED_SEARCH_EXPORT}>
                <GrayBar extraTopMargin={true} />
                <SectionHeader>{strings.includeForExport}</SectionHeader>
                <ReactReduxForm {...advancedSearchExportForm}>
                    {map(sortBy(reportsPrintables, 'title'), (printable) => (
                        <RRFCheckbox
                            key={printable.printingTemplateId}
                            label={printable.title}
                            path={printable.printingTemplateId.toString()}
                        />
                    ))}
                    <GrayBar />
                    <Button
                        className={buttonTypes.PRIMARY}
                        onClick={onSubmit}
                        testId={testIds.EXPORTS_DOWNLOAD_BUTTON}
                    >
                        {strings.download}
                    </Button>
                    <Button className={buttonTypes.SECONDARY} onClick={onCancel}>
                        {strings.cancel}
                    </Button>
                </ReactReduxForm>
            </div>
        </ScrollableUnderSubheader>
    );
};

const mapStateToProps = createStructuredSelector({
    // reports
    reportsPrintables: advancedSearchReports.selectors.printablesSelector,
    reportsSelectedRows: advancedSearchReports.selectors.selectedRowsSelector,
    reportsAllResultsSelected: advancedSearchReports.selectors.allResultsSelectedSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    onSubmit: (selectedRows, allResultsSelected) => {
        dispatch(
            advancedSearchExportForm.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchReports.actionCreators.exportResults(
                        selectedRows,
                        allResultsSelected,
                        compact(map(formData, (value, id) => (value ? parseInt(id) : null)))
                    )
                ).tap(() => router.push('/search/reports'))
            )
        );
    },
    onCancel: () => router.push('/search/reports'),
});

const mapHandlers = withHandlers({
    onSubmit: ({ onSubmit, reportsSelectedRows, reportsAllResultsSelected }) => () =>
        onSubmit(reportsSelectedRows, reportsAllResultsSelected),
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    mapHandlers
)(AdvancedSearchExport);

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/inventories';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Inventory with Items Resource',
        methods: {
            /**
             * Load inventory items
             * @param {number} id
             * @return {Promise<Object>} `barcodes`, `facilities`, `firearms`, `inventory`, `inventoryItems`,
             *                           `itemProfiles`, `itemReportingEventLinks`, `reportingEvents`,
             *                           `storageLocations`, and `vehicles`.
             */
            loadInventoryItems(id) {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/${id}/items`,
                });
            },
        },
    });
}

/**
 * Resource for inventories and audits. See the tech doc for details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see
 *   https://docs.google.com/document/d/12dH-fM0RvSCm5td9oHpe3J9V6SzIm42qJKIWW1rvyzM/
 */
const resourceGetter = () => resource;
export default resourceGetter;

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { replaceFieldContactCardBundle } from '~/client-common/core/domain/reports/state/ui';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';

import { buildFieldContactCardBundle, refreshFieldContactForm } from '../forms/fieldContactForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.FIELD_CONTACT.name,
    baseSelector: (state) => state.ui.report.fieldContactCard,
    anchor: 'field-contact-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);
                const report = currentReportSelector(state);
                const reportId = report.id;

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const fieldContactCardBundle = dispatch(
                                    buildFieldContactCardBundle({ reportId, form })
                                );

                                return [
                                    dispatch(
                                        replaceFieldContactCardBundle({
                                            reportId,
                                            fieldContactCardBundle,
                                        })
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                dispatch(refreshFieldContactForm({ reportId }));
                            },
                        })
                    );
                }
            };
        },
    },
};

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { find, shuffle, map } from 'lodash';
import { Flex, Banner, Box, ButtonGroup, Divider, cssVar } from 'arc';
import styled from 'styled-components';
import {
    PhotoLineupView,
    FileUploadResponse,
    PhotoLineupSlotView,
    HydratedImage,
    Mark43File,
    FileCategoryEnum,
} from '@mark43/rms-api';
import { RouteComponentProps } from 'react-router';
import { useResource, useResourceDeferred } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { Button as _Button } from '../../../core/components/Button';
import { openLoadingModal, closeLoadingModal } from '../../../../legacy-redux/actions/boxActions';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import casePhotoLineupResource from '../../core/resources/casePhotoLineupResource';
import Link from '../../../core/components/links/Link';
import testIds from '../../../../core/testIds';
import Upload from '../../../core/forms/components/Upload';
import { UploadErrorType } from '../../../core/forms/helpers/uploadHelpers';
import { DisplayedPhoto } from '../utils/parseElasticPhotoLineups';
import { LineupComposeColumn } from './LineupPhotoRow';
import ManualUploadLineupModal from './ManualUploadLineupModal';
import SelectionAreaContainer from './SelectionAreaContainer';
import ExceedManualErrorModal from './ExceedManualErrorModal';
import MaximumWarningModal from './MaximumWarningModal';

const strings = componentStrings.cases.casePhotoLineups.ComposeLineup;
const MAX_SLOTS = 12;

const ComposePage = styled(Flex)`
    position: relative;
    background-color: ${cssVar('arc.colors.surface.background')};
    height: 100%;
`;

const ReferenceAreaContainer = styled(Flex)`
    height: calc(100% - 65px); // subtract the sticky footer height
    overflow-y: scroll;
    overflow-x: hidden;
    border-right: 1px solid ${cssVar('arc.colors.border.default')};
`;

const ReferenceArea = styled(Box)`
    padding-top: 20px;
    width: 262px; // needs to account for scrollbar
    margin-left: 16px;
`;

const StyledDivider = styled(Divider)`
    width: 245px;
`;

const PaddedButton = styled(_Button)`
    margin: 20px 0;
    text-transform: none;
`;

const Button = styled(_Button)`
    text-transform: none;
`;

const SaveArea = styled(Flex)`
    position: fixed;
    bottom: 0;
    min-width: 277px;
    margin-left: 0;
    background-color: ${cssVar('arc.colors.surface.background')};
    border-top: 1px solid ${cssVar('arc.colors.border.default')};
    border-right: 1px solid ${cssVar('arc.colors.border.default')};
`;

type Params = {
    caseId: string;
    photoLineupId: string;
};

const ComposeLineup: React.FC<RouteComponentProps<Params, Record<string, unknown>>> = ({
    params,
    router,
}) => {
    const dispatch = useDispatch();
    const [uploadFiles, setUploadFiles] = useState<FileUploadResponse[]>([]);
    const [lineup, setLineup] = useState<PhotoLineupView | undefined>();
    const [uploadError, setUploadError] = useState<string>('');

    const overlayStore = useOverlayStore();
    const openManualModal = useCallback(
        () => overlayStore.open(overlayIdEnum.MANUAL_LINEUP_MODAL),
        [overlayStore]
    );
    const openErrorExceedModal = useCallback(
        () => overlayStore.open(overlayIdEnum.ERROR_MANUAL_LINEUP_MODAL),
        [overlayStore]
    );
    const openMaximumLineupModal = useCallback(
        () => overlayStore.open(overlayIdEnum.MAXIMUM_LINEUP_MODAL),
        [overlayStore]
    );

    const caseId = parseInt(params.caseId);
    const photoLineupId = parseInt(params.photoLineupId);

    const loadLineup = useCallback(
        () => casePhotoLineupResource.getPhotoLineupForCase(caseId, photoLineupId),
        [caseId, photoLineupId]
    );

    const onResourceSuccess = useCallback(
        (photoLineupView: PhotoLineupView) => {
            setLineup(photoLineupView);
        },
        [setLineup]
    );

    const saveLineup = React.useCallback(
        (photoLineupView: PhotoLineupView | undefined): Promise<PhotoLineupView> => {
            if (!photoLineupView) {
                return Promise.reject(new Error('Cannot find Lineup'));
            }

            const { photoLineupSlotViews } = photoLineupView;

            // build save request
            const slots = map(photoLineupSlotViews, (slot, index) => {
                return {
                    ...slot,
                    orderNumber: index + 1,
                };
            });
            const lineupReq: PhotoLineupView = {
                ...photoLineupView,
                photoLineupSlotViews: slots,
            };

            // submit
            return casePhotoLineupResource.upsertPhotoLineupForCase(lineupReq);
        },
        []
    );
    const onSaveSuccess = React.useCallback(
        (photoLineup: PhotoLineupView): void => {
            // if successful go back to lineups tab
            // error is handled in the loading.errorMessage
            if (photoLineup) {
                router.push(`/cases/${caseId}/lineups`);
            }
        },
        [caseId, router]
    );

    const {
        loading: { isLoading: saveLoading, errorMessage: saveError },
        callResource: onSave,
    } = useResourceDeferred(saveLineup, onSaveSuccess);
    const { isLoading, errorMessage } = useResource(loadLineup, onResourceSuccess);

    const onUploadSuccess = (response: FileUploadResponse[]) => {
        // Continue to add on each file until all files have been uploaded
        setUploadFiles((prevState) => [...prevState, ...response]);
    };

    const onUploadError = (error: string, type: string) => {
        dispatch(closeLoadingModal());
        // check the type of upload to display correct error
        if (type === UploadErrorType.MAXIMUM_EXCEEDED) {
            openErrorExceedModal();
        } else {
            setUploadError(error);
        }
    };

    const onUploadBatchesSuccess = () => {
        // open the manual upload form once all files have been uploaded successfully
        dispatch(closeLoadingModal());
        openManualModal();
    };

    const shuffleSlots = () => {
        setLineup((prevState) => {
            if (!prevState) {
                return prevState;
            }

            return {
                ...prevState,
                photoLineupSlotViews: shuffle(prevState.photoLineupSlotViews),
            };
        });
    };

    const addSelectedPhoto = useCallback(
        (selectedPhoto: DisplayedPhoto) => {
            if ((lineup?.photoLineupSlotViews.length || 0) < MAX_SLOTS) {
                setLineup((prevState) => {
                    if (!prevState) {
                        return prevState;
                    }
                    const slotToAdd: PhotoLineupSlotView = {
                        id: selectedPhoto.id,
                        imageId: selectedPhoto.id,
                        masterPersonId: selectedPhoto.masterPersonId,
                        orderNumber: -1,
                        processedImageId: -1,
                    };

                    // Since images coming from the selection area are not fully hydrated, we have to create a skeleton
                    // Mark43File and HydratedImage so it can be shown in the reference area with the current logic.
                    const skeletonFile: Mark43File = {
                        createdBy: -1,
                        createdDateUtc: '',
                        departmentId: -1,
                        fileAppServerPath: '',
                        fileCategory: FileCategoryEnum.IMAGE.name,
                        fileResourcePath: '',
                        fileServerCommonPath: '',
                        fileServerId: '',
                        fileWebServerPath: selectedPhoto.path,
                        id: -1,
                        originalFileName: '',
                        permissionSet: [],
                        rmsEventId: -1,
                        updatedBy: -1,
                        updatedDateUtc: '',
                        mergeFailedFileNames: [],
                    };
                    const skeletonImage: HydratedImage = {
                        createdBy: -1,
                        createdDateUtc: '',
                        departmentId: -1,
                        id: selectedPhoto.id,
                        imageSizes: [],
                        originalFileId: -1,
                        rmsEventId: -1,
                        updatedBy: -1,
                        updatedDateUtc: '',
                        originalFile: skeletonFile,
                    };
                    return {
                        ...prevState,
                        photoLineupSlotViews: [...prevState.photoLineupSlotViews, slotToAdd],
                        images: [...prevState.images, skeletonImage],
                    };
                });
            } else {
                openMaximumLineupModal();
            }
        },
        [lineup?.photoLineupSlotViews.length, openMaximumLineupModal]
    );

    const removeSelectedPhoto = useCallback((selectedPhotoId: number) => {
        setLineup((prevState) => {
            if (!prevState) {
                return prevState;
            }
            const suspectMasterPersonId = prevState.photoLineup.personOfInterestMasterId;
            const suspectImage = find(prevState.photoLineupSlotViews, (slot) => {
                return slot.masterPersonId === suspectMasterPersonId;
            });
            if (selectedPhotoId === suspectImage?.imageId) {
                return prevState;
            }
            const filteredSlots = prevState.photoLineupSlotViews.filter((slot) => {
                return slot.imageId !== selectedPhotoId;
            });
            const filteredImages = prevState.images.filter((image) => {
                return image.id !== selectedPhotoId;
            });
            return {
                ...prevState,
                photoLineupSlotViews: filteredSlots,
                images: filteredImages,
            };
        });
    }, []);

    const showError = errorMessage || saveError || uploadError;
    const getErrorMessage = () => {
        if (errorMessage) {
            return errorMessage;
        } else if (saveError) {
            return saveError;
        } else if (uploadError) {
            return uploadError;
        } else {
            return '';
        }
    };

    if (isLoading || !lineup) {
        return null;
    }
    return (
        <>
            {showError && (
                <Banner status="error" description={getErrorMessage()} title={strings.errorText} />
            )}
            <MaximumWarningModal maxNum={MAX_SLOTS} />
            <ExceedManualErrorModal />
            <ManualUploadLineupModal files={uploadFiles} setLineup={setLineup} />
            <ComposePage data-test-id={testIds.PHOTO_LINEUP_COMPOSE_PAGE}>
                <ReferenceAreaContainer>
                    <ReferenceArea>
                        <LineupComposeColumn lineupView={lineup} onRemove={removeSelectedPhoto} />
                        <Upload
                            maxFiles={MAX_SLOTS - lineup.photoLineupSlotViews.length}
                            multiple={true}
                            onAllBatchesComplete={onUploadBatchesSuccess}
                            onStart={() => {
                                dispatch(openLoadingModal());
                                setUploadFiles([]);
                            }}
                            onSuccess={onUploadSuccess}
                            onError={onUploadError}
                            accept="image/jpg, image/jpeg, image/png"
                        >
                            <PaddedButton
                                leftIcon="Upload"
                                testId={testIds.PHOTO_LINEUP_COMPOSE_UPLOAD_IMAGES_BUTTON}
                            >
                                {strings.uploadImages}
                            </PaddedButton>
                        </Upload>
                        <StyledDivider />
                        <PaddedButton
                            onClick={shuffleSlots}
                            testId={testIds.PHOTO_LINEUP_COMPOSE_SHUFFLE_ORDER}
                            style={{ marginBottom: '50px', width: '110px' }}
                        >
                            {strings.shuffleOrder}
                        </PaddedButton>
                    </ReferenceArea>
                </ReferenceAreaContainer>
                <SaveArea justify="end" padding={4}>
                    <ButtonGroup>
                        <Link to={`/cases/${caseId}/lineups`}>
                            <Button testId={testIds.PHOTO_LINEUP_COMPOSE_CANCEL}>
                                {strings.cancelText}
                            </Button>
                        </Link>
                        <Button
                            variant="solid"
                            isLoading={saveLoading}
                            loadingText={strings.savingText}
                            onClick={() => onSave(lineup)}
                            testId={testIds.PHOTO_LINEUP_COMPOSE_SAVE}
                        >
                            {strings.saveText}
                        </Button>
                    </ButtonGroup>
                </SaveArea>
                <Box width={698}>
                    {lineup?.searchFilters ? (
                        <SelectionAreaContainer
                            addSelectedPhoto={addSelectedPhoto}
                            removeSelectedPhoto={removeSelectedPhoto}
                            initialSearchFilters={lineup?.searchFilters}
                            lineup={lineup}
                        />
                    ) : undefined}
                </Box>
            </ComposePage>
        </>
    );
};

export default ComposeLineup;

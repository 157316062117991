import { get } from 'lodash';
import componentStrings from '../core/strings/componentStrings';

const { options } = componentStrings.forms.select.OperationTypeSelect;

/**
 *  Converts an entity permission value from its Database value to values that are consistent with
 *  with the Options menu for OperationTypeSelect (front-end)
 */

export const formatEntityPermission = (entityPermission: string): string => {
    return get(options, entityPermission) || entityPermission;
};

import { Action } from 'redux';
import { createSelector } from 'reselect';
import _, { compact, filter, flatMap, get, map, size } from 'lodash';
import {
    CodeTypeCategoryEnum,
    EntityTypeEnum,
    EntityTypeEnumType,
    ItemProfile,
    RelatedItemsView,
    RmsHydratedItem,
    HydrationOptionsEnum,
} from '@mark43/rms-api';
import {
    NEXUS_STATE_PROP as CAUTIONS_NEXUS_STATE_PROP,
    cautionsByEntitySelector,
} from '~/client-common/core/domain/cautions/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '../../../attributes/state/data';
import {
    firearmsSelector,
    NEXUS_STATE_PROP as FIREARMS_NEXUS_STATE_PROP,
} from '../../../firearms/state/data';

import {
    itemAttributesWhereSelector,
    NEXUS_STATE_PROP as ITEM_ATTRIBUTES_NEXUS_STATE_PROP,
} from '../../../item-attributes/state/data';
import {
    itemIdentifiersByItemIdSelector,
    NEXUS_STATE_PROP as ITEM_IDENTIFIERS_NEXUS_STATE_PROP,
} from '../../../item-identifiers/state/data';
import { NEXUS_STATE_PROP as ITEM_REPORT_LINKS_NEXUS_STATE_PROP } from '../../../item-report-links/state/data';
import {
    locationsSelector,
    NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP,
} from '../../../locations/state/data';
import {
    locationEntityLinksWhereSelector,
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
} from '../../../location-entity-links/state/data';
import {
    nameItemLinksByItemProfileIdSelector,
    NEXUS_STATE_PROP as NAME_ITEM_LINKS_NEXUS_STATE_PROP,
} from '../../../name-item-links/state/data';
import {
    NEXUS_STATE_PROP as PROPERTY_STATUSES_NEXUS_STATE_PROP,
    propertyStatusesByItemProfileIdSelector,
} from '../../../property-statuses/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '../../../report-short-titles/state/data';
import {
    NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP,
    vehiclesSelector,
} from '../../../vehicles/state/data';
import { NEXUS_STATE_PROP as PERSON_PROFILES_NEXUS_STATE_PROP } from '../../../person-profiles/state/data';
import { NEXUS_STATE_PROP as ORGANIZATION_PROFILES_NEXUS_STATE_PROP } from '../../../organization-profiles/state/data';
import { convertAttributeToAttributeView } from '../../../attributes/utils/attributesHelpers';
import { convertLocationBundlesToLocationViews } from '../../../locations/utils/locationHelpers';
import { NEXUS_STATE_PROP as VEHICLE_MAKES_NEXUS_STATE_PROP } from '../../../vehicle-makes/state/data';
import { NEXUS_STATE_PROP as VEHICLE_MODELS_NEXUS_STATE_PROP } from '../../../vehicle-models/state/data';
import { NEXUS_STATE_PROP as FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP } from '../../../etrace-atf/state/data';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getItemProfileResource from '../../resources/itemProfileResource';
import { nibrsCodeForAttributeIdCodeTypeCategoryAndDepartmentSelector } from '../../../attribute-codes/state/ui';
import { ClientCommonAction, DependenciesArg } from '../../../../../redux/types';
import {
    getReportingEventNumberSelector,
    NEXUS_STATE_PROP as ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP,
} from '../../../item-reporting-event-links/state/data';

export const NEXUS_STATE_PROP = 'itemProfiles';
const itemProfileModule = createNormalizedModule<ItemProfile>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
const storeItemProfiles = itemProfileModule.actionCreators.storeEntities;

const STORE_HYDRATED_ITEM = 'item-profiles/STORE_HYDRATED_ITEM';
const STORE_HYDRATED_ITEMS = 'item-profiles/STORE_HYDRATED_ITEMS';
const LOAD_ITEM_PROFILES_FOR_MASTERS_START = 'item-profiles/LOAD_ITEM_PROFILES_FOR_MASTERS_START';
const LOAD_ITEM_PROFILES_FOR_MASTERS_SUCCESS =
    'item-profiles/LOAD_ITEM_PROFILES_FOR_MASTERS_SUCCESS';
const LOAD_ITEM_PROFILES_FOR_MASTERS_FAILURE =
    'item-profiles/LOAD_ITEM_PROFILES_FOR_MASTERS_FAILURE';
const SAVE_HYDRATED_ITEM_START = 'item-profiles/SAVE_HYDRATED_ITEM_START';
export const SAVE_HYDRATED_ITEM_SUCCESS = 'item-profiles/SAVE_HYDRATED_ITEM_SUCCESS';
const SAVE_HYDRATED_ITEM_FAILURE = 'item-profiles/SAVE_HYDRATED_ITEM_FAILURE';
const LOAD_HYDRATED_ITEM_START = 'item-profiles/LOAD_HYDRATED_ITEM_START';
const LOAD_HYDRATED_ITEM_SUCCESS = 'item-profiles/LOAD_HYDRATED_ITEM_SUCCESS';
const LOAD_HYDRATED_ITEM_FAILURE = 'item-profiles/LOAD_HYDRATED_ITEM_FAILURE';
const LOAD_HYDRATED_ITEMS_START = 'item-profiles/LOAD_HYDRATED_ITEMS_START';
const LOAD_HYDRATED_ITEMS_SUCCESS = 'item-profiles/LOAD_HYDRATED_ITEMS_SUCCESS';
const LOAD_HYDRATED_ITEMS_FAILURE = 'item-profiles/LOAD_HYDRATED_ITEMS_FAILURE';

/**
 * Remove practically all RMS data linked to an item from Nexus data state. Not removing locations,
 *   personProfiles, and organizationProfiles because too much effort to write predicate and should
 *   have no effect downstream.
 */
export function withRemoveHydratedItem(
    action: Action<string>,
    itemProfileId: number,
    dependencies: DependenciesArg,
    masterItemId: number | null = null
) {
    return dependencies.nexus.withRemoveMultiple(
        {
            [FIREARMS_NEXUS_STATE_PROP]: { id: itemProfileId },
            [NEXUS_STATE_PROP]: { id: itemProfileId },
            [ITEM_IDENTIFIERS_NEXUS_STATE_PROP]: { itemId: itemProfileId },
            [CAUTIONS_NEXUS_STATE_PROP]: { entityId: itemProfileId },
            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: { entityId: itemProfileId },
            [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: { itemProfileId },
            [PROPERTY_STATUSES_NEXUS_STATE_PROP]: { itemProfileId },
            [VEHICLES_NEXUS_STATE_PROP]: { id: itemProfileId },
            [ITEM_ATTRIBUTES_NEXUS_STATE_PROP]: { itemProfileId },
            ...(masterItemId !== null
                ? {
                      [ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP]: {
                          masterItemProfileId: masterItemId,
                      },
                  }
                : {}),
        },
        action
    );
}

/**
 * Store all the models in RmsHydratedItem.
 */
function storeHydratedItem(hydratedItem: RmsHydratedItem): ClientCommonAction<void> {
    return function (dispatch, getState, dependencies) {
        const itemProfileId = get(hydratedItem, 'item.id');
        const state = getState();
        const masterItemId = hydratedItem.item.masterItemId;
        const reportId = hydratedItem.ownerId;
        const reportingEventNumber =
            masterItemId !== undefined
                ? getReportingEventNumberSelector(state)(reportId)
                : undefined;
        const removeHydratedItem = withRemoveHydratedItem(
            { type: STORE_HYDRATED_ITEM },
            itemProfileId,
            dependencies,
            masterItemId
        );

        const masterItemReportingEventLink = hydratedItem.masterItemReportingEventLinks.find(
            (link) => link.reportingEventNumber === reportingEventNumber
        );

        return dispatch(
            itemProfileModule.withEntityItems(
                {
                    [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                        hydratedItem.attributes,
                        convertAttributeToAttributeView
                    ),
                    [ITEM_ATTRIBUTES_NEXUS_STATE_PROP]: hydratedItem.attributeLinks,
                    [FIREARMS_NEXUS_STATE_PROP]: [hydratedItem.firearm],
                    [NEXUS_STATE_PROP]: [hydratedItem.item],
                    [ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP]: [masterItemReportingEventLink],
                    [ITEM_IDENTIFIERS_NEXUS_STATE_PROP]: hydratedItem.itemIdentifiers,
                    [CAUTIONS_NEXUS_STATE_PROP]: hydratedItem.cautions,
                    [LOCATIONS_NEXUS_STATE_PROP]: convertLocationBundlesToLocationViews(
                        hydratedItem.locations
                    ),
                    [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: flatMap(
                        hydratedItem.locations,
                        'entityLinks'
                    ),
                    [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: hydratedItem.nameItemLinks,
                    [PROPERTY_STATUSES_NEXUS_STATE_PROP]: hydratedItem.propertyStatuses,
                    [VEHICLES_NEXUS_STATE_PROP]: [hydratedItem.vehicle],
                    [PERSON_PROFILES_NEXUS_STATE_PROP]: flatMap(
                        hydratedItem.people,
                        'personProfiles'
                    ),
                    [ORGANIZATION_PROFILES_NEXUS_STATE_PROP]: flatMap(
                        hydratedItem.organizations,
                        'organizationProfiles'
                    ),
                    [VEHICLE_MAKES_NEXUS_STATE_PROP]: hydratedItem.vehicleMakes,
                    [VEHICLE_MODELS_NEXUS_STATE_PROP]: hydratedItem.vehicleModels,
                    [FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP]: hydratedItem.atfManufacturers,
                },
                removeHydratedItem
            )
        );
    };
}

export const storeHydratedItems = (hydratedItems: RmsHydratedItem[]): ClientCommonAction<void> => (
    dispatch,
    getState
) =>
    dispatch(
        itemProfileModule.withEntityItems(
            {
                [ATTRIBUTES_NEXUS_STATE_PROP]: _(hydratedItems)
                    .flatMap('attributes')
                    .compact()
                    .map(convertAttributeToAttributeView)
                    .value(),
                [ITEM_ATTRIBUTES_NEXUS_STATE_PROP]: compact(
                    flatMap(hydratedItems, 'attributeLinks')
                ),
                [FIREARMS_NEXUS_STATE_PROP]: compact(flatMap(hydratedItems, 'firearms')),
                [NEXUS_STATE_PROP]: compact(flatMap(hydratedItems, 'items')),
                [ITEM_REPORTING_EVENT_LINKS_NEXUS_STATE_PROP]: compact(
                    hydratedItems.flatMap((hydratedItem) => {
                        const state = getState();

                        const masterItemId = hydratedItem.item.masterItemId;
                        const reportId = hydratedItem.ownerId;
                        const reportingEventNumber =
                            masterItemId !== undefined
                                ? getReportingEventNumberSelector(state)(reportId)
                                : undefined;

                        return hydratedItem.masterItemReportingEventLinks.find(
                            (link) => link.reportingEventNumber === reportingEventNumber
                        );
                    })
                ),
                [ITEM_IDENTIFIERS_NEXUS_STATE_PROP]: compact(
                    flatMap(hydratedItems, 'itemIdentifiers')
                ),
                [CAUTIONS_NEXUS_STATE_PROP]: compact(flatMap(hydratedItems, 'cautions')),
                [LOCATIONS_NEXUS_STATE_PROP]: convertLocationBundlesToLocationViews(
                    compact(flatMap(hydratedItems, 'locations'))
                ),
                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: compact(
                    flatMap(flatMap(hydratedItems, 'locations'), 'entityLinks')
                ),
                [NAME_ITEM_LINKS_NEXUS_STATE_PROP]: compact(
                    flatMap(hydratedItems, 'nameItemLinks')
                ),
                [PROPERTY_STATUSES_NEXUS_STATE_PROP]: compact(
                    flatMap(hydratedItems, 'propertyStatuses')
                ),
                [VEHICLES_NEXUS_STATE_PROP]: compact(flatMap(hydratedItems, 'vehicles')),
                [PERSON_PROFILES_NEXUS_STATE_PROP]: _(hydratedItems)
                    .flatMap('people')
                    .flatMap('personProfiles')
                    .compact()
                    .value(),
                [ORGANIZATION_PROFILES_NEXUS_STATE_PROP]: _(hydratedItems)
                    .flatMap('organizations')
                    .flatMap('organizationProfiles')
                    .compact()
                    .value(),
                [VEHICLE_MAKES_NEXUS_STATE_PROP]: compact(flatMap(hydratedItems, 'vehicleMakes')),
                [VEHICLE_MODELS_NEXUS_STATE_PROP]: compact(flatMap(hydratedItems, 'vehicleModels')),
                [FIREARM_ATF_MANUFACTURERS_NEXUS_STATE_PROP]: compact(
                    flatMap(hydratedItems, 'atfManufacturers')
                ),
            },
            { type: STORE_HYDRATED_ITEMS }
        )
    );

export const LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_START =
    'item-profiles/LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_START';
export const LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_SUCCESS =
    'item-profiles/LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_SUCCESS';
export const LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_FAILURE =
    'item-profiles/LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_FAILURE';

export const LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_START =
    'item-profiles/LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_START';
export const LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_SUCCESS =
    'item-profiles/LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_SUCCESS';
export const LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_FAILURE =
    'item-profiles/LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_FAILURE';

const DELETE_HYDRATED_ITEM_START = 'item-profiles/DELETE_HYDRATED_ITEM_START';
export const DELETE_HYDRATED_ITEM_SUCCESS = 'item-profiles/DELETE_HYDRATED_ITEM_SUCCESS';
const DELETE_HYDRATED_ITEM_FAILURE = 'item-profiles/DELETE_HYDRATED_ITEM_FAILURE';

const SAVE_ITEM_PROFILE_IDS_TO_OWNER_START = 'item-profiles/SAVE_ITEM_PROFILE_IDS_TO_OWNER_START';

const SAVE_ITEM_PROFILE_IDS_TO_OWNER_SUCCESS =
    'item-profiles/SAVE_ITEM_PROFILE_IDS_TO_OWNER_SUCCESS';

const SAVE_ITEM_PROFILE_IDS_TO_OWNER_FAILURE =
    'item-profiles/SAVE_ITEM_PROFILE_IDS_TO_OWNER_FAILURE';

function loadAllItemProfilesForMasterItemIdsStart() {
    return {
        type: LOAD_ITEM_PROFILES_FOR_MASTERS_START,
    };
}

function loadAllItemProfilesForMasterItemIdsSuccess() {
    return {
        type: LOAD_ITEM_PROFILES_FOR_MASTERS_SUCCESS,
    };
}

function loadAllItemProfilesForMasterItemIdsFailure(errorMessage: string) {
    return {
        type: LOAD_ITEM_PROFILES_FOR_MASTERS_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

const loadAllItemProfilesPropertiesForReportingEventNumberStart = ({
    ownerId,
    ownerType,
    reportingEventNumber,
}: {
    ownerId: number;
    ownerType: EntityTypeEnumType;
    reportingEventNumber: string;
}) => ({
    type: LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_START,
    payload: { ownerId, ownerType, reportingEventNumber },
});

const loadAllItemProfilesPropertiesForReportingEventNumberSuccess = () => ({
    type: LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_SUCCESS,
});

const loadAllItemProfilesPropertiesForReportingEventNumberFailure = (errorMessage: string) => ({
    type: LOAD_ITEM_PROFILES_PROPERTIES_FOR_REPORTING_EVENT_NUMBER_FAILURE,
    error: true,
    payload: errorMessage,
});

const loadAllItemProfilesVehiclesForReportingEventNumberStart = ({
    ownerId,
    ownerType,
    reportingEventNumber,
}: {
    ownerId: number;
    ownerType: EntityTypeEnumType;
    reportingEventNumber: string;
}) => ({
    type: LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_START,
    payload: { ownerId, ownerType, reportingEventNumber },
});

const loadAllItemProfilesVehiclesForReportingEventNumberSuccess = () => ({
    type: LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_SUCCESS,
});

const loadAllItemProfilesVehiclesForReportingEventNumberFailure = (errorMessage: string) => ({
    type: LOAD_ITEM_PROFILES_VEHICLES_FOR_REPORTING_EVENT_NUMBER_FAILURE,
    error: true,
    payload: errorMessage,
});

const saveItemProfileIdsToOwnerStart = () => ({
    type: SAVE_ITEM_PROFILE_IDS_TO_OWNER_START,
});

const saveItemProfileIdsToOwnerSuccess = () => ({
    type: SAVE_ITEM_PROFILE_IDS_TO_OWNER_SUCCESS,
});

const saveItemProfileIdsToOwnerFailure = (errorMessage: string) => ({
    type: SAVE_ITEM_PROFILE_IDS_TO_OWNER_FAILURE,
    error: true,
    payload: errorMessage,
});

/**
 * Load all contexted item profiles with the given master item ids. The item profiles can be
 *   contexted to different reports in the same REN or across different RENs.
 */
export function loadAllItemProfilesForMasterItemIds(
    masterItemIds: number[]
): ClientCommonAction<Promise<ItemProfile[]>> {
    const itemProfileResource = getItemProfileResource();

    return function (dispatch) {
        dispatch(loadAllItemProfilesForMasterItemIdsStart());
        return itemProfileResource
            .getAllItemProfilesForMasterItemIds(masterItemIds)
            .then((itemProfiles: ItemProfile[]) => {
                dispatch(loadAllItemProfilesForMasterItemIdsSuccess());
                dispatch(storeItemProfiles(itemProfiles));
                return itemProfiles;
            })
            .catch((err: Error) => {
                dispatch(loadAllItemProfilesForMasterItemIdsFailure(err.message));
                throw err;
            });
    };
}

const deleteHydratedItemStart = () => ({ type: DELETE_HYDRATED_ITEM_START });
const deleteHydratedItemSuccess = () => ({ type: DELETE_HYDRATED_ITEM_SUCCESS });
const deleteHydratedItemFailure = (errorMessage: string) => ({
    type: DELETE_HYDRATED_ITEM_FAILURE,
    error: true,
    payload: errorMessage,
});

/**
 * Delete an RmsHydratedItem
 */
export const deleteHydratedItem = (itemProfileId: number): ClientCommonAction<Promise<boolean>> => {
    const itemProfileResource = getItemProfileResource();

    return (dispatch, getState, dependencies) => {
        const itemProfile = itemProfileByIdSelector(getState())(itemProfileId);
        const masterItemId = get(itemProfile, 'masterItemId');
        dispatch(deleteHydratedItemStart());
        return itemProfileResource
            .deleteHydratedItem(itemProfileId)
            .then((success: boolean) => {
                if (!success) {
                    throw new Error('Failed to delete item');
                }
                dispatch(
                    withRemoveHydratedItem(
                        deleteHydratedItemSuccess(),
                        itemProfileId,
                        dependencies,
                        masterItemId
                    )
                );
                return success;
            })
            .catch((err: Error) => {
                dispatch(deleteHydratedItemFailure(err.message));
                throw err;
            });
    };
};

function saveHydratedItemStart() {
    return {
        type: SAVE_HYDRATED_ITEM_START,
    };
}

function saveHydratedItemSuccess() {
    return {
        type: SAVE_HYDRATED_ITEM_SUCCESS,
    };
}

function saveHydratedItemFailure(errorMessage: string) {
    return {
        type: SAVE_HYDRATED_ITEM_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Create or update an RmsHydratedItem.
 */
export function saveHydratedItem(
    hydratedItem: RmsHydratedItem
): ClientCommonAction<Promise<RmsHydratedItem>> {
    const itemProfileResource = getItemProfileResource();

    return function (dispatch) {
        dispatch(saveHydratedItemStart());
        return itemProfileResource
            .upsertHydratedItem(hydratedItem)
            .then((hydratedItem: RmsHydratedItem) => {
                dispatch(storeHydratedItem(hydratedItem));
                dispatch(saveHydratedItemSuccess());
                return hydratedItem;
            })
            .catch((err: Error) => {
                dispatch(saveHydratedItemFailure(err.message));
                throw err;
            });
    };
}

function loadHydratedItemStart() {
    return { type: LOAD_HYDRATED_ITEM_START };
}

function loadHydratedItemSuccess() {
    return { type: LOAD_HYDRATED_ITEM_SUCCESS };
}

function loadHydratedItemFailure() {
    return {
        type: LOAD_HYDRATED_ITEM_FAILURE,
        error: true,
    };
}

export function loadHydratedItem(
    itemProfileId: number
): ClientCommonAction<Promise<RmsHydratedItem>> {
    const itemProfileResource = getItemProfileResource();

    return (dispatch) => {
        dispatch(loadHydratedItemStart());
        return itemProfileResource
            .getHydratedItem(itemProfileId)
            .then((hydratedItem: RmsHydratedItem) => {
                dispatch(storeHydratedItem(hydratedItem));
                dispatch(loadHydratedItemSuccess());
                return hydratedItem;
            })
            .catch((err: Error) => {
                dispatch(loadHydratedItemFailure());
                throw err;
            });
    };
}

function loadHydratedItemsStart() {
    return { type: LOAD_HYDRATED_ITEMS_START };
}

function loadHydratedItemsSuccess() {
    return { type: LOAD_HYDRATED_ITEMS_SUCCESS };
}

function loadHydratedItemsFailure() {
    return {
        type: LOAD_HYDRATED_ITEMS_FAILURE,
        error: true,
    };
}

export function loadHydratedItems(
    itemProfileIds: number[]
): ClientCommonAction<Promise<RmsHydratedItem[]>> {
    const itemProfileResource = getItemProfileResource();

    return (dispatch) => {
        dispatch(loadHydratedItemsStart());
        return itemProfileResource
            .getHydratedItems(itemProfileIds, [HydrationOptionsEnum.INCLUDE_ATTRIBUTES.name])
            .then((hydratedItems: RmsHydratedItem[]) => {
                dispatch(storeHydratedItems(hydratedItems));
                dispatch(loadHydratedItemsSuccess());
                return hydratedItems;
            })
            .catch((err: Error) => {
                dispatch(loadHydratedItemsFailure());
                throw err;
            });
    };
}

export const loadAllItemProfilesPropertiesForReportingEventNumber = ({
    ownerId,
    ownerType,
    reportingEventNumber,
}: {
    ownerId: number;
    ownerType: EntityTypeEnumType;
    reportingEventNumber: string;
}): ClientCommonAction<Promise<RelatedItemsView>> => (dispatch, getState, dependencies) => {
    const resource = getItemProfileResource();
    dispatch(
        loadAllItemProfilesPropertiesForReportingEventNumberStart({
            ownerId,
            ownerType,
            reportingEventNumber,
        })
    );

    return resource
        .getAllItemProfilesPropertiesForReportingEventNumber({
            ownerId,
            ownerType,
            reportingEventNumber,
        })
        .then(({ hydratedItems, itemReportLinks, reportShortTitles }: RelatedItemsView) => {
            dispatch(storeHydratedItems(hydratedItems));
            dispatch(
                dependencies.nexus.withEntityItems(
                    {
                        [ITEM_REPORT_LINKS_NEXUS_STATE_PROP]: itemReportLinks,
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                    },
                    loadAllItemProfilesPropertiesForReportingEventNumberSuccess()
                )
            );
            return hydratedItems;
        })
        .catch((err: Error) => {
            dispatch(loadAllItemProfilesPropertiesForReportingEventNumberFailure(err.message));
            throw err;
        });
};

export const loadAllItemProfilesVehiclesForReportingEventNumber = ({
    ownerId,
    ownerType,
    reportingEventNumber,
}: {
    ownerId: number;
    ownerType: EntityTypeEnumType;
    reportingEventNumber: string;
}): ClientCommonAction<Promise<RelatedItemsView>> => (dispatch, getState, dependencies) => {
    const resource = getItemProfileResource();
    dispatch(
        loadAllItemProfilesVehiclesForReportingEventNumberStart({
            ownerId,
            ownerType,
            reportingEventNumber,
        })
    );

    return resource
        .getAllItemProfilesVehiclesForReportingEventNumber({
            ownerId,
            ownerType,
            reportingEventNumber,
        })
        .then(({ hydratedItems, itemReportLinks, reportShortTitles }: RelatedItemsView) => {
            dispatch(storeHydratedItems(hydratedItems));
            dispatch(
                dependencies.nexus.withEntityItems(
                    {
                        [ITEM_REPORT_LINKS_NEXUS_STATE_PROP]: itemReportLinks,
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                    },
                    loadAllItemProfilesVehiclesForReportingEventNumberSuccess()
                )
            );
            return hydratedItems;
        })
        .catch((err: Error) => {
            dispatch(loadAllItemProfilesVehiclesForReportingEventNumberFailure(err.message));
            throw err;
        });
};

export const saveItemProfileIdsToOwner = ({
    itemProfileIds,
    ownerId,
    ownerType,
}: {
    itemProfileIds: number[];
    ownerId: number;
    ownerType: EntityTypeEnumType;
}): ClientCommonAction<Promise<RmsHydratedItem[]>> => (dispatch) => {
    const resource = getItemProfileResource();
    dispatch(saveItemProfileIdsToOwnerStart());

    return resource
        .saveHydratedItemProfilesIdToOwner({
            itemProfileIds,
            ownerId,
            ownerType,
        })
        .then((hydratedItems: RmsHydratedItem[]) => {
            dispatch(storeHydratedItems(hydratedItems));
            dispatch(saveItemProfileIdsToOwnerSuccess());

            return hydratedItems;
        })
        .catch((err: Error) => {
            dispatch(saveItemProfileIdsToOwnerFailure(err.message));
            throw err;
        });
};

// SELECTORS
export const itemProfilesSelector = itemProfileModule.selectors.entitiesSelector;
export const itemProfileByIdSelector = itemProfileModule.selectors.entityByIdSelector;
export const itemProfilesWhereSelector = itemProfileModule.selectors.entitiesWhereSelector;

/**
 * Retrieve a set of Item profiles based on given input set of item profile ids.
 */
export const itemProfilesByIdsSelector = createSelector(
    itemProfileByIdSelector,
    propertyStatusesByItemProfileIdSelector,
    (itemProfileById, propertyStatusesByItemProfileId) => (itemProfileIds: number[]) =>
        itemProfileIds.reduce(
            (results, id) =>
                size(propertyStatusesByItemProfileId(id))
                    ? results.concat(itemProfileById(id) as ItemProfile)
                    : results,
            [] as ItemProfile[]
        )
);

/**
 * Item profiles that belong to given report and have at least 1 property
 *   status. It is important to note that item profiles without property
 *   statuses are not shown in the report at all.
 */
export const itemProfilesInReportSelector = createSelector(
    itemProfilesSelector,
    propertyStatusesByItemProfileIdSelector,
    (itemProfiles, propertyStatusesByItemProfileId) => (reportId: number) =>
        filter(
            itemProfiles,
            ({ id, ownerId, ownerType }) =>
                ownerId === reportId &&
                ownerType === EntityTypeEnum.REPORT.name &&
                propertyStatusesByItemProfileId(id).length > 0
        )
);

export const filterItemCategoryByNibrsCodeSelector = createSelector(
    nibrsCodeForAttributeIdCodeTypeCategoryAndDepartmentSelector,
    (nibrsCodeForAttributeIdCodeTypeCategoryAndDepartment) => (
        itemProfiles: ItemProfile[],
        nibrsCode: string,
        departmentId: number
    ) =>
        filter(itemProfiles, ({ itemCategoryAttrId }) => {
            if (!itemCategoryAttrId) {
                return false;
            }
            return (
                nibrsCodeForAttributeIdCodeTypeCategoryAndDepartment(
                    itemCategoryAttrId,
                    CodeTypeCategoryEnum.NIBRS_PROPERTY_CATEGORY.name,
                    departmentId
                ) === nibrsCode
            );
        })
);

/**
 * Return an object that contains the important entities and links in the RmsHydratedItem bundle.
 *   This doesn't match the server model exactly, as it doesn't contain `id`, `persons`, etc.
 */
export const hydratedItemByIdSelector = createSelector(
    firearmsSelector,
    itemProfilesSelector,
    itemIdentifiersByItemIdSelector,
    locationsSelector,
    locationEntityLinksWhereSelector,
    nameItemLinksByItemProfileIdSelector,
    propertyStatusesByItemProfileIdSelector,
    vehiclesSelector,
    itemAttributesWhereSelector,
    cautionsByEntitySelector,
    (
        firearms,
        itemProfiles,
        itemIdentifiersByItemId,
        locations,
        locationEntityLinksWhere,
        nameItemLinksByItemProfileId,
        propertyStatusesByItemProfileId,
        vehicles,
        itemAttributesWhere,
        cautionsByEntity
    ) => {
        return (itemProfileId: number) => ({
            firearms: firearms[itemProfileId] ? [firearms[itemProfileId]] : undefined,
            items: itemProfiles[itemProfileId] ? [itemProfiles[itemProfileId]] : undefined,

            itemIdentifiers: itemIdentifiersByItemId(itemProfileId),
            locations: _(
                locationEntityLinksWhere({
                    entityType: EntityTypeEnum.ITEM_PROFILE.name,
                    entityId: itemProfileId,
                })
            )
                .groupBy('locationId')
                .map((entityLinks) => ({ ...locations[entityLinks[0].locationId], entityLinks }))
                .value(),

            nameItemLinks: nameItemLinksByItemProfileId(itemProfileId),
            propertyStatuses: propertyStatusesByItemProfileId(itemProfileId),
            vehicles: vehicles[itemProfileId] ? [vehicles[itemProfileId]] : undefined,
            attributeLinks: itemAttributesWhere({ itemProfileId }),
            cautions: cautionsByEntity(EntityTypeEnum.VEHICLE.name, itemProfileId),
        });
    }
);

// REDUCER
export default itemProfileModule.reducerConfig;

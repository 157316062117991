import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';

import {
    Form,
    createField,
    createFieldset,
    createFormConfiguration,
    lifecycleOptions,
    InferFormDataShape,
    formEvents,
} from 'markformythree';
import { ExportIntervalTypeEnum, ExportIntervalTypeEnumType, RegionalGroupEnum } from '@mark43/rms-api';

import { dateToDMYProps } from '~/client-common/core/dates/utils/dateHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import testIds from '../../../core/testIds';
import Row from '../../core/components/Row';
import { currentUserDepartmentIdSelector, currentUserDepartmentProfileSelector  } from '../../core/current-user/state/ui';
import { MFTCheckbox } from '../../core/forms/components/checkboxes/Checkbox';
import { MFTAgencyProfileWithOriSelect } from '../../core/forms/components/selects/AgencyProfileSelect';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../core/validation/yupMftValidation';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';

import { lastExportedDateSelector } from '../state/ui';
import { ComplianceExportType } from '../types';
import { useComplianceExportContext } from '../contexts/ComplianceExportContext';
import ComplianceDateSelect from './ComplianceDateSelect';

type ComplianceExportFormProps = {
    complianceExportType: ComplianceExportType;
    buttonElement: React.ReactNode;
    disabled: boolean;
    forceUpdate?: () => void;
    generateExportErrorsHandler: () => void;
    updateFormNotMatchingErrorsState: () => void;
};

const strings = componentStrings.compliance.ComplianceExportForm;
const labels = strings.labels;
const validationStrings = componentStrings.validation;

export const complianceExportFormConfiguration = createFormConfiguration({
    agencyId: createField<number>({}),
    dateRange: createFieldset({
        fields: {
            startDateUtc: createField<string>({}),
            endDateUtc: createField<string>({}),
        },
    }),
    exportDateUtc: createField<string>({}),
    exportZeroReportSegment: createField<boolean>({}),
    year: createField<number>({}),
});

export type FormComplianceExportFormConfiguration = typeof complianceExportFormConfiguration;
type ComplianceExportFormModel = InferFormDataShape<FormComplianceExportFormConfiguration>;

const ExportZeroReportSegmentCheckbox = styled(MFTCheckbox)`
    margin-left: 530px;
`;

const AgencyMonthGenerateRow = styled.div`
    display: flex;
    align-items: center;
`;

const validationSchema = yup.object().shape({
    agencyId: yup.number().required(validationStrings.requiredError),
    dateRange: yup.object({
        startDateUtc: yup.string(),
        endDateUtc: yup.string(),
    }),
    exportDateUtc: yup
        .string()
        .when('$dateType', (dateType: ExportIntervalTypeEnumType, schema: yup.DateSchema) =>
            dateType === ExportIntervalTypeEnum.MONTHLY.name || ExportIntervalTypeEnum.TN_WEEKLY.name
                ? schema.required(validationStrings.requiredError)
                : schema
        ),
    exportZeroReportSegment: yup.boolean(),
    year: yup
        .number()
        .when('$dateType', (dateType: ExportIntervalTypeEnumType, schema: yup.DateSchema) =>
            dateType === ExportIntervalTypeEnum.YEARLY.name
                ? schema.required(validationStrings.requiredError)
                : schema
        ),
});

export function convertFromFormModel(
    formModel: ComplianceExportFormModel,
    segmentGenerationEnabled: boolean,
    currentAgencyId: number | undefined
) {
    const { exportDateUtc, ...restOfFormModel } = formModel;
    return {
        ...restOfFormModel,
        agencyId: segmentGenerationEnabled ? currentAgencyId : formModel.agencyId,
        ...(exportDateUtc ? dateToDMYProps(exportDateUtc) : undefined),
        // NibrsExportQuery type requires totalUpdatedReportSize
        // but the client payload value is not used, instead the server sets it based on query
        totalUpdatedReportSize: 0,
    };
}

const ComplianceExportForm: React.FC<ComplianceExportFormProps> = (props) => {
    const {
        buttonElement,
        complianceExportType,
        forceUpdate,
        generateExportErrorsHandler,
        updateFormNotMatchingErrorsState,
    } = props;
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);
    const lastExportedDate = useSelector(lastExportedDateSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const {
        currentAgencyId,
        isNibrs,
        callGetLastExportedDateResource,
        getLastExportedDateLoadingState,
    } = useComplianceExportContext();

    const currentNibrsRegionalGroup = useSelector(currentUserDepartmentProfileSelector)?.nibrsRegionalGroup ?? RegionalGroupEnum.FEDERAL_NIBRS.name;
    const currentNibrsExportInterval = RegionalGroupEnum[currentNibrsRegionalGroup].nibrsExportIntervalType;
    const currentExportIntervalType = isNibrs ? currentNibrsExportInterval : complianceExportType?.exportIntervalType;

    const agencyProfileSelectPredicate = { departmentId: currentUserDepartmentId };

    const disabled = props.disabled || getLastExportedDateLoadingState.isLoading;

    const handleAgencyChange = useCallback(
        (agencyId) => {
            updateFormNotMatchingErrorsState();
            callGetLastExportedDateResource(agencyId);
        },
        [callGetLastExportedDateResource, updateFormNotMatchingErrorsState]
    );

    const handleValidation = useCallback(
        ({ formState, eventType }) => {
            const $form = formState.ui.$form;
            const validationContext = {
                dateType: complianceExportType?.exportIntervalType,
            };
            return validationSchema
                .validate(formState.model, { abortEarly: false, context: validationContext })
                .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
                .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
        },
        [complianceExportType?.exportIntervalType]
    );

    const handleComplianceDateSelectChange = useCallback(() => {
        updateFormNotMatchingErrorsState();

        if (forceUpdate) {
            forceUpdate();
        }
        if (applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED) {
            generateExportErrorsHandler();
        }
    }, [
        applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED,
        forceUpdate,
        generateExportErrorsHandler,
        updateFormNotMatchingErrorsState,
    ]);

    const { getForm } = useFormGetter();
    const form = getForm(formClientEnum.COMPLIANCE_EXPORT);

    const offenseDisplayName = useOffenseFieldName();

    useEffect(() => {
        if (!form || !applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED) {
            return;
        }
        const lastAgencyId = form.get('agencyId');
        if (lastAgencyId !== currentAgencyId) {
            form.transaction(() => {
                form.set('agencyId', currentAgencyId);
                form.set('dateRange', undefined);
                form.set('exportDateUtc', undefined);
                form.set('year', undefined);
            });
        }
    }, [
        applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED,
        callGetLastExportedDateResource,
        currentAgencyId,
        form,
        updateFormNotMatchingErrorsState,
    ]);

    return (
        <Form
            configuration={complianceExportFormConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={formClientEnum.COMPLIANCE_EXPORT}
            onValidate={handleValidation}
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_BLUR },
                { eventType: formEvents.INPUT_CHANGE },
            ]}
            initialState={{
                agencyId: currentAgencyId,
            }}
            render={() => (
                <>
                    <AgencyMonthGenerateRow>
                        {
                            // renders unless FF is on and is NIBRS
                            !(
                                applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED &&
                                isNibrs
                            ) && (
                                <div
                                    data-test-id={
                                        testIds.COMPLIANCE_DASHBOARD_AGENCY_PROFILE_SELECT
                                    }
                                >
                                    <MFTAgencyProfileWithOriSelect
                                        clearable={false}
                                        disabled={disabled}
                                        label={labels.agencySelect}
                                        onChange={handleAgencyChange}
                                        path="agencyId"
                                        predicate={agencyProfileSelectPredicate}
                                        width={390}
                                    />
                                </div>
                            )
                        }
                        <div data-test-id={testIds.COMPLIANCE_DASHBOARD_DATE_SELECT}>
                            <ComplianceDateSelect
                                agencyId={currentAgencyId}
                                disabled={disabled}
                                exportIntervalType={currentExportIntervalType}
                                lastExportedDate={lastExportedDate}
                                onChange={handleComplianceDateSelectChange}
                            />
                        </div>
                        {!(
                            applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED &&
                            isNibrs
                        ) && buttonElement}
                    </AgencyMonthGenerateRow>
                    <FeatureFlagged
                        flag="RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED"
                        fallback={
                            <Row>
                                {isNibrs && (
                                    <FeatureFlagged flag="NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX_ENABLED">
                                        <ExportZeroReportSegmentCheckbox
                                            disabled={disabled}
                                            testId={
                                                testIds.COMPLIANCE_DASHBOARD_NIBRS_EXPORT_ZERO_REPORT_SEGMENT_CHECKBOX
                                            }
                                            helpText={labels.exportZeroReportSegmentHelpText(
                                                offenseDisplayName
                                            )}
                                            label={labels.exportZeroReportSegmentCheckbox}
                                            onChange={updateFormNotMatchingErrorsState}
                                            path="exportZeroReportSegment"
                                        />
                                    </FeatureFlagged>
                                )}
                            </Row>
                        }
                    />
                </>
            )}
        />
    );
};

export default ComplianceExportForm;

import { NotificationCategoryEnum } from '@mark43/notifications-api';
import { ProductModuleEnum } from '@mark43/rms-api';
import { prettify } from '../../../../helpers/stringHelpers';
import { DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE } from '../../../enums/universal/fields';

/**
 * How many milliseconds to wait between each polling request for the latest
 *   notifications for the current user.
 * @type {number}
 */
export const POLL_FOR_USER_NOTIFICATIONS_INTERVAL = 20000;

/**
 * The limit of how many of the most recent notifications to receive in each
 *   polling request. 20 will give us enough notifications to display in Popover,
 *   and also include any alerts. NUMBER_OF_NOTIFICATIONS_FOR_POPOVER is currently set to 10.
 * @type {number}
 */
export const POLL_FOR_USER_NOTIFICATIONS_SIZE = 20;

/**
 * Each element of this array represents a notification category. The display strings
 *   are used in the notification settings feature, and the categories
 *   appear there in this order. The `featureFlag` property is required if the
 *   category corresponds to a feature flag.
 *
 * Do not import this object directly in places where you need to display
 *   notification categories, instead use the selector
 *   `notificationCategoryConfigsSelector` which transforms this object
 *   specifically for the current department.
 * @type {Object[]}
 */

export const notificationCategoryConfigs = [
    {
        notificationCategory: NotificationCategoryEnum.REPORTS.name,
        productModule: ProductModuleEnum.REPORTS.name,
        display: 'Reports Settings',
    },
    {
        notificationCategory: NotificationCategoryEnum.CASES.name,
        productModule: ProductModuleEnum.CASES.name,
        display: 'Cases Settings',
    },
    {
        notificationCategory: NotificationCategoryEnum.EVIDENCE.name,
        productModule: ProductModuleEnum.EVIDENCE.name,
        display: (evidenceModuleName) => `${prettify(evidenceModuleName)} Settings`,
        fieldName: DISPLAY_ONLY_NAME_OF_EVIDENCE_MODULE,
    },
    {
        notificationCategory: NotificationCategoryEnum.TASKS.name,
        productModule: undefined,
        display: 'Tasks Settings',
    },
    {
        notificationCategory: NotificationCategoryEnum.SAVED_SEARCHES.name,
        productModule: undefined,
        display: 'Saved Search Settings',
    },
    {
        notificationCategory: NotificationCategoryEnum.SUBSCRIPTIONS.name,
        productModule: undefined,
        display: 'Subscriptions Settings',
    },
    {
        notificationCategory: NotificationCategoryEnum.CAD.name,
        productModule: ProductModuleEnum.CAD.name,
        display: 'CAD Settings',
    },
];

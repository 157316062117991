export const LOAD_PRODUCT_MODULES_START = 'support-product-modules/LOAD_PRODUCT_MODULES_START';
export const LOAD_PRODUCT_MODULES_SUCCESS = 'support-product-modules/LOAD_PRODUCT_MODULES_SUCCESS';
export const LOAD_PRODUCT_MODULES_FAILURE = 'support-product-modules/LOAD_PRODUCT_MODULES_FAILURE';

export const SAVE_PRODUCT_MODULES_START = 'support-product-modules/SAVE_PRODUCT_MODULES_START';
export const SAVE_PRODUCT_MODULES_SUCCESS = 'support-product-modules/SAVE_PRODUCT_MODULES_SUCCESS';
export const SAVE_PRODUCT_MODULES_FAILURE = 'support-product-modules/SAVE_PRODUCT_MODULES_FAILURE';

const initialState = {
    loading: false,
    error: null,
    productModuleBlob: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SAVE_PRODUCT_MODULES_START:
        case LOAD_PRODUCT_MODULES_START:
            return {
                ...state,
                loading: true,
            };
        case SAVE_PRODUCT_MODULES_SUCCESS:
        case LOAD_PRODUCT_MODULES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                productModuleBlob: action.payload,
            };
        case SAVE_PRODUCT_MODULES_FAILURE:
        case LOAD_PRODUCT_MODULES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.productModules;

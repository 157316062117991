import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pure, lifecycle, compose, withPropsOnChange } from 'recompose';

import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { agencyProfilesWhereSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CAD_LABEL } from '~/client-common/core/enums/universal/fields';

import advancedSearchCadTickets from '../state/ui';
import {
    currentUserDepartmentIdSelector,
    currentUserDepartmentAgencyIdSelector,
} from '../../../core/current-user/state/ui';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import LocationsFieldset from '../../core/components/LocationsFieldset';
import { SearchName } from '../../core/components/SearchName';
import CadTicketFieldset from './CadTicketFieldset';

const strings = componentStrings.search.AdvancedSearchCadTicketsForm;

const CadTicketSection = pure(function CadTicketSection(props) {
    return (
        <FormSection>
            <CadTicketFieldset highlightOnFocus={false} {...props} />
        </FormSection>
    );
});

const LocationsSection = pure(function LocationsSection(props) {
    const cadDisplayName = useFields(DISPLAY_ONLY_CAD_LABEL)[DISPLAY_ONLY_CAD_LABEL];
    return (
        <FormSection title={strings.sectionTitles.locations(cadDisplayName)}>
            <LocationsFieldset collapsible={false} includeCrossStreets={true} {...props} />
        </FormSection>
    );
});

function AdvancedSearchCadTicketsForm({
    fuzzyMatchingEnabled,
    hasSingleAgencyProfile,
    formIsEmpty,
    onSubmit,
    onReset,
    currentSavedSearch,
}) {
    return (
        <AdvancedSearchForm
            {...advancedSearchCadTickets.form}
            includeFuzzyMatchingCheckbox={true}
            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={true} />
            <CadTicketSection
                hideAgencySelect={hasSingleAgencyProfile}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            />
            <hr />
            <LocationsSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
        </AdvancedSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    fuzzyMatchingEnabled: advancedSearchCadTickets.form.selectors.buildFormModelSelectorByPath(
        'fuzzyMatchingEnabled'
    ),
    formIsEmpty: advancedSearchCadTickets.form.selectors.formIsEmptySelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    currentUserDepartmentAgencyId: currentUserDepartmentAgencyIdSelector,
    agencyProfilesWhere: agencyProfilesWhereSelector,
    currentSavedSearch: advancedSearchCadTickets.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () =>
        dispatch(
            advancedSearchCadTickets.form.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchCadTickets.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                )
            )
        ),
    onReset: () => {
        dispatch(advancedSearchCadTickets.form.actionCreators.reset());
        dispatch(advancedSearchCadTickets.actionCreators.resetState());
        dispatch(advancedSearchCadTickets.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(advancedSearchCadTickets.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
    initializeFormFields: (props) => {
        const data = props.consortiumDepartmentLinksAvailable
            ? { departmentIds: [props.currentUserDepartmentId] }
            : {};
        dispatch(advancedSearchCadTickets.form.actionCreators.change(data));
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['agencyProfilesWhere'],
        ({ currentUserDepartmentId, agencyProfilesWhere }) => ({
            hasSingleAgencyProfile:
                agencyProfilesWhere({
                    departmentId: currentUserDepartmentId,
                }).length === 1,
        })
    ),
    lifecycle({
        UNSAFE_componentWillMount() {
            // if the form is empty, this means that we didn't execute a saved search.
            // In this case we have to prefill the agency or department select once
            // on component mount so that user have a sensible default value
            if (this.props.formIsEmpty) {
                this.props.initializeFormFields(this.props);
            }
        },
    })
)(AdvancedSearchCadTicketsForm);

import React from 'react';
import styled from 'styled-components';
import { Text } from 'arc';
import { timeAgoShort } from '~/client-common/core/dates/utils/dateHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { isUnknown } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { hexColorWithOpacity } from '../../core/utils/colorHelpers';
import Icon, { iconTypes } from '../../core/components/Icon';
import { EntityItem } from './EntityItem';

const OverlayContainer = styled.div`
    background-color: ${(props) => hexColorWithOpacity(props.theme.colors.extraLightGrey, 0.45)};
    color: ${(props) => props.theme.colors.mediumGrey};
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-end;
`;

const TimeAgoContainer = styled.div`
    background-color: ${(props) => hexColorWithOpacity(props.theme.colors.extraLightGrey, 0.7)};
    width: 100%;
    display: flex;
    align-items: center;
    font-size: var(--arc-fontSizes-xs);
    font-style: italic;
`;
const InfoContainer = styled.div`
    flex: 1;
`;

const CameraIcon = styled(Icon)`
    margin-right: 8px;
    margin-left: 2px;
`;

const RecentArrest = ({ recentArrest, arrestNumberDisplayName }) => {
    const {
        arrestDateUtc,
        defendantId,
        fullAddress,
        masterMugshotDateUtc,
        mugshotImagePath,
        mugshotIsFromMasterProfile,
        recordNumber,
        permissionSet,
        reportId,
    } = recentArrest;

    const hasOldMugshot = !!mugshotImagePath && mugshotIsFromMasterProfile;
    const timeSince = timeAgoShort(masterMugshotDateUtc);
    const isRestricted = !canRead(permissionSet);
    const link = isUnknown(recentArrest) ? undefined : `/profiles/persons/${defendantId}`;

    return (
        <EntityItem
            entity={recentArrest}
            date={arrestDateUtc}
            entityRoute={link}
            itemRoute={`/reports/${reportId}`}
            itemLinkText={`${arrestNumberDisplayName} ${recordNumber}`}
            isRestricted={isRestricted}
            mugShotImage={mugshotImagePath}
            mugShotOverlay={
                hasOldMugshot && (
                    <OverlayContainer>
                        <TimeAgoContainer>
                            <CameraIcon type={iconTypes.CAMERA} color="mediumGrey" size={11} />
                            {timeSince}
                        </TimeAgoContainer>
                    </OverlayContainer>
                )
            }
        >
            <InfoContainer>
                <Text>{fullAddress}</Text>
            </InfoContainer>
        </EntityItem>
    );
};

/**
 * An individual arrest in the Recent Arrests section.
 */
export default RecentArrest;

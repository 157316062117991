import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const labelPrintingFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'labelPrinterId',
        validators: {
            requiredError: requiredString,
        },
    },
]);

const labelPrintingForm = createFormModule({
    formName: formClientEnum.LABEL_PRINTING,
    fieldViewModels: labelPrintingFormFieldViewModels,
});

/**
 * Module of the label printing form, for selecting a printer to print to.
 * @type {Object}
 */
export default labelPrintingForm;

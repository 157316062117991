import { RefContextEnum } from '@mark43/rms-api';

import { filterFormData } from '~/client-common/helpers/formHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';

import createReportFormModule from '../../../../core/forms/lib/createReportFormModule';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { DATE_TIME, RESOLVER } = fieldTypeClientEnum;

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'id',
    },
    {
        key: 'reportId',
    },
    {
        type: RESOLVER,
        key: 'involvedVehicle',
        fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_TOW_VEHICLE_REASON_FOR_TOW_ATTRIBUTE_ID,
        resolver: (state, { formsRegistry }) => () => {
            const tvForm = formsRegistry.get(RefContextEnum.FORM_TOW_VEHICLE.name);
            const towVehicleReasonForTowAttrIds = tvForm.get('reasonsForTow');
            return towVehicleReasonForTowAttrIds;
        },
    },
    {
        type: DATE_TIME,
        key: 'nicNumberOriginalDateUtc',
        fieldName: fields.IMPOUND_NIC_NUMBER_ORIGINAL_DATE_UTC,
    },
    {
        key: 'nicNumberOriginal',
        fieldName: fields.IMPOUND_NIC_NUMBER_ORIGINAL,
    },
    {
        key: 'nicNumberOriginalIsLocal',
        fieldName: fields.IMPOUND_NIC_NUMBER_ORIGINAL_IS_LOCAL,
    },
    {
        type: DATE_TIME,
        key: 'nicNumberCancellationDateUtc',
        fieldName: fields.IMPOUND_NIC_NUMBER_CANCELLATION_DATE_UTC,
    },
    {
        key: 'nicNumberCancellation',
        fieldName: fields.IMPOUND_NIC_NUMBER_CANCELLATION,
    },
    {
        key: 'nicNumberCancellationIsLocal',
        fieldName: fields.IMPOUND_NIC_NUMBER_CANCELLATION_IS_LOCAL,
    },
    {
        key: 'keysInVehicle',
        fieldName: fields.IMPOUND_KEYS_IN_VEHICLE,
    },
    {
        key: 'vehicleLocked',
        fieldName: fields.IMPOUND_VEHICLE_LOCKED,
    },
]);

const convertToFormModel = (towVehicleImpound) => {
    return towVehicleImpound;
};

const convertFromFormModel = (formModel, currentReportId) => {
    const impound = {
        reportId: currentReportId,
        ...formModel,
    };
    const filteredImpound = filterFormData(impound);
    return filteredImpound;
};

export default createReportFormModule({
    context: RefContextEnum.FORM_TOW_VEHICLE_IMPOUND.name,
    fieldViewModels,
    convertToFormModel,
    convertFromFormModel,
});

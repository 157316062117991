import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'type',
    },
    {
        key: 'service',
    },
    {
        key: 'retainMark43SupportUsers',
    },
]);

export default createFormModule({
    context: formClientEnum.DEPARTMENT_RESET,
    fieldViewModels,
});

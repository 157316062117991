import { createSelector } from 'reselect';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';

import { attachmentViews } from '../../../../attachments/core/components/AttachmentViewToggleButton';

const SET_ATTACHMENT_LIST_SORT = 'case-attachment/SET_ATTACHMENT_LIST_SORT';
const SET_ATTACHMENT_VIEW = 'case-attachment/SET_ATTACHMENT_VIEW';
const RESET_CURRENT_CASE_ATTACHMENTS_UI = 'case-attachments/RESET_CURRENT_CASE_ATTACHMENTS_UI';

export const attachmentListSortTypes = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

export function setAttachmentSort(sortKey) {
    return (dispatch, getState) => {
        const currentTableUi = caseAttachmentsTableUiSelector(getState());
        dispatch({
            type: SET_ATTACHMENT_LIST_SORT,
            payload: {
                sortKey,
                sortType:
                    currentTableUi.sortKey === sortKey &&
                    currentTableUi.sortType === attachmentListSortTypes.ASCENDING
                        ? attachmentListSortTypes.DESCENDING
                        : attachmentListSortTypes.ASCENDING,
            },
        });
    };
}

export function toggleAttachmentView() {
    return (dispatch, getState) => {
        const currentTableUi = caseAttachmentsTableUiSelector(getState());
        dispatch({
            type: SET_ATTACHMENT_VIEW,
            payload: {
                view:
                    currentTableUi.view === attachmentViews.LIST
                        ? attachmentViews.GRID
                        : attachmentViews.LIST,
            },
        });
    };
}

export function resetCurrentCaseAttachmentsUi() {
    return {
        type: RESET_CURRENT_CASE_ATTACHMENTS_UI,
    };
}

const currentCaseAttachmentsUiSelector = (state) => state.ui.cases.attachments;

export const caseAttachmentsTableUiSelector = createSelector(
    currentCaseAttachmentsUiSelector,
    (currentCaseAttachmentsUi) => currentCaseAttachmentsUi
);

export default makeResettable(
    RESET_CURRENT_CASE_ATTACHMENTS_UI,
    function currentCaseAttachmentsReducer(
        state = {
            sortKey: 'displayName',
            sortType: attachmentListSortTypes.ASCENDING,
            view: attachmentViews.LIST,
        },
        action
    ) {
        switch (action.type) {
            case SET_ATTACHMENT_LIST_SORT:
                return {
                    ...state,
                    sortKey: action.payload.sortKey,
                    sortType: action.payload.sortType,
                };
            case SET_ATTACHMENT_VIEW:
                return {
                    ...state,
                    view: action.payload.view,
                };
            default:
                return state;
        }
    }
);

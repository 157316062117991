import { EntityPermission } from '@mark43/rms-api';

import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'evidenceLocationPermissions';

export interface EvidenceLocationPermissionsModule extends EntityPermission {
    itemId: number;
    masterItemId: number;
}

const evidenceLocationPermissionsModule = createLinkModule<EvidenceLocationPermissionsModule>({
    type: NEXUS_STATE_PROP,
    keys: ['entityId', 'entityType', 'roleId', 'itemId'],
});

export const evidenceLocationPermissionsWhereSelector =
    evidenceLocationPermissionsModule.selectors.linksWhereSelector;

export default evidenceLocationPermissionsModule.reducerConfig;

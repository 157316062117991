import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const cadServiceRotationAuditAdminFormFieldViewModels = {
    cadServiceRotationAudit: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'cadServiceRotationAudit',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'beginTimeUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'endTimeUtc',
                validators: {
                    requiredError: requiredString,
                },
            },
            {
                key: 'rotationServiceTypeId',
            },
            {
                key: 'rotationServiceId',
            },
        ]),
    },
};

function convertToFormModel({ cadServiceRotationAudit = {} }) {
    return {
        cadServiceRotationAudit: {
            ...cadServiceRotationAudit,
        },
    };
}

function convertFromFormModel({ cadServiceRotationAudit = {} }) {
    return { cadServiceRotationAudit };
}

/**
 * Module of the cad units admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CAD_SERVICE_ROTATION_AUDIT_ADMIN,
    fieldViewModels: cadServiceRotationAuditAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tabs, Tab, TabList } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import testIds from '../../../../core/testIds';
import { routeNameSelector } from '../../../../routing/routerModule';
import routesConfig from '../../../../routing/routesConfig';
import { BaseLinkWithAnalytics } from '../../../core/components/links/Link';

const { menu } = componentStrings.evidence.core.EvidenceHeader;
type EvidenceMenuItem = keyof typeof menu;

const pathParamToRouteParam: Record<EvidenceMenuItem, { name: string; testId?: string }> = {
    dashboard: { name: routesConfig.EVIDENCE_DASHBOARD.name },
    inventories: {
        name: routesConfig.EVIDENCE_INVENTORIES_DASHBOARD.name,
        testId: testIds.INVENTORIES_AND_AUDITS_TAB,
    },
};

interface EvidenceDashboardSubHeaderTabProps {
    routeName: string;
}
function EvidenceDashboardSubHeaderTab({ routeName }: EvidenceDashboardSubHeaderTabProps) {
    const evidenceModuleName = useEvidenceModuleName();

    return (
        <Tabs value={routeName} isManual>
            <TabList style={{ border: 'none' }}>
                {map(pathParamToRouteParam, (param, path: EvidenceMenuItem) => {
                    const { name, testId } = param;
                    return (
                        <Tab
                            tabIndex={0}
                            value={name}
                            as={BaseLinkWithAnalytics}
                            testId={testId}
                            key={path}
                            to={`/evidence/${path}`}
                        >
                            {path === 'dashboard' ? menu.dashboard(evidenceModuleName) : menu[path]}
                        </Tab>
                    );
                })}
            </TabList>
        </Tabs>
    );
}

const mapStateToProps = createStructuredSelector({
    routeName: routeNameSelector,
});

export default connect(mapStateToProps)(EvidenceDashboardSubHeaderTab);

import React from 'react';
import { omit } from 'lodash';
import { Form, MFTFormConfiguration } from 'markformythree';
import { useSelector } from 'react-redux';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { RMSArbiterProvider } from '../arbiter';
import mftArbiterValidationEvents from './mftArbiterValidationEvents';
import createArbiterMFTValidationHandler from './createArbiterMFTValidationHandler';

export type ArbiterInstance = {
    runRules: (
        context: string,
        arbiterData: Record<string, unknown>,
        runConfiguration: { ruleTypes: string[] }
    ) => unknown[];
    dataGetters: Record<string, unknown>;
};

type WrapperProps<T extends MFTFormConfiguration> = Form<T>['props'] & {
    context: string;
};

type Props<T extends MFTFormConfiguration> = WrapperProps<T> & {
    arbiterInstance: ArbiterInstance;
};

function ArbiterForm<T extends MFTFormConfiguration>(
    props: React.PropsWithChildren<Props<T>>
): JSX.Element {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const onValidate: Form['props']['onValidate'] = createArbiterMFTValidationHandler(
        props.arbiterInstance,
        props.context,
        formatFieldByName
    );
    const otherProps = omit(props, ['arbiterInstance']);

    return (
        <Form<T>
            onValidate={onValidate}
            validationEvents={mftArbiterValidationEvents}
            {...otherProps}
        />
    );
}

function ArbiterFormWrapper<T extends MFTFormConfiguration = MFTFormConfiguration>(
    props: React.PropsWithChildren<WrapperProps<T>>
): JSX.Element {
    return (
        <RMSArbiterProvider context={props.context}>
            {(arbiterInstance: ArbiterInstance) => (
                <ArbiterForm<T> arbiterInstance={arbiterInstance} {...props} />
            )}
        </RMSArbiterProvider>
    );
}

export default ArbiterFormWrapper;

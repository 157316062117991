import { ReportExternalLink } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'reportExternalLinks';

const reportExternalLinksModule = createNormalizedModule<ReportExternalLink>({
    type: NEXUS_STATE_PROP,
});

export const reportExternalLinksWhereSelector =
    reportExternalLinksModule.selectors.entitiesWhereSelector;

export default reportExternalLinksModule.reducerConfig;

const countries = [
    {
        value: 'AF',
        display: 'AFGHANISTAN',
    },
    {
        value: 'AL',
        display: 'ALBANIA',
    },
    {
        value: 'DZ',
        display: 'ALGERIA',
    },
    {
        value: 'AS',
        display: 'AMERICAN SAMOA',
    },
    {
        value: 'AD',
        display: 'ANDORRA',
    },
    {
        value: 'AO',
        display: 'ANGOLA',
    },
    {
        value: 'AI',
        display: 'ANGUILLA',
    },
    {
        value: 'AQ',
        display: 'ANTARCTICA',
    },
    {
        value: 'AG',
        display: 'ANTIGUA AND BARBUDA',
    },
    {
        value: 'AR',
        display: 'ARGENTINA',
    },
    {
        value: 'AM',
        display: 'ARMENIA',
    },
    {
        value: 'AW',
        display: 'ARUBA',
    },
    {
        value: 'AU',
        display: 'AUSTRALIA',
    },
    {
        value: 'AT',
        display: 'AUSTRIA',
    },
    {
        value: 'AZ',
        display: 'AZERBAIJAN',
    },
    {
        value: 'BS',
        display: 'BAHAMAS',
    },
    {
        value: 'BH',
        display: 'BAHRAIN',
    },
    {
        value: 'BD',
        display: 'BANGLADESH',
    },
    {
        value: 'BB',
        display: 'BARBADOS',
    },
    {
        value: 'BY',
        display: 'BELARUS',
    },
    {
        value: 'BE',
        display: 'BELGIUM',
    },
    {
        value: 'BZ',
        display: 'BELIZE',
    },
    {
        value: 'BJ',
        display: 'BENIN',
    },
    {
        value: 'BM',
        display: 'BERMUDA',
    },
    {
        value: 'BT',
        display: 'BHUTAN',
    },
    {
        value: 'BO',
        display: 'BOLIVIA, PLURINATIONAL STATE OF',
    },
    {
        value: 'BQ',
        display: 'BONAIRE, SINT EUSTATIUS AND SABA',
    },
    {
        value: 'BA',
        display: 'BOSNIA AND HERZEGOVINA',
    },
    {
        value: 'BW',
        display: 'BOTSWANA',
    },
    {
        value: 'BV',
        display: 'BOUVET ISLAND',
    },
    {
        value: 'BR',
        display: 'BRAZIL',
    },
    {
        value: 'IO',
        display: 'BRITISH INDIAN OCEAN TERRITORY',
    },
    {
        value: 'BN',
        display: 'BRUNEI DARUSSALAM',
    },
    {
        value: 'BG',
        display: 'BULGARIA',
    },
    {
        value: 'BF',
        display: 'BURKINA FASO',
    },
    {
        value: 'BI',
        display: 'BURUNDI',
    },
    {
        value: 'KH',
        display: 'CAMBODIA',
    },
    {
        value: 'CM',
        display: 'CAMEROON',
    },
    {
        value: 'CA',
        display: 'CANADA',
    },
    {
        value: 'CV',
        display: 'CAPE VERDE',
    },
    {
        value: 'KY',
        display: 'CAYMAN ISLANDS',
    },
    {
        value: 'CF',
        display: 'CENTRAL AFRICAN REPUBLIC',
    },
    {
        value: 'TD',
        display: 'CHAD',
    },
    {
        value: 'CL',
        display: 'CHILE',
    },
    {
        value: 'CN',
        display: 'CHINA',
    },
    {
        value: 'CX',
        display: 'CHRISTMAS ISLAND',
    },
    {
        value: 'CC',
        display: 'COCOS (KEELING) ISLANDS',
    },
    {
        value: 'CO',
        display: 'COLOMBIA',
    },
    {
        value: 'KM',
        display: 'COMOROS',
    },
    {
        value: 'CG',
        display: 'CONGO',
    },
    {
        value: 'CD',
        display: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
    },
    {
        value: 'CK',
        display: 'COOK ISLANDS',
    },
    {
        value: 'CR',
        display: 'COSTA RICA',
    },
    {
        value: 'HR',
        display: 'CROATIA',
    },
    {
        value: 'CU',
        display: 'CUBA',
    },
    {
        value: 'CW',
        display: 'CURAÇAO',
    },
    {
        value: 'CY',
        display: 'CYPRUS',
    },
    {
        value: 'CZ',
        display: 'CZECH REPUBLIC',
    },
    {
        value: 'CI',
        display: "CÔTE D'IVOIRE",
    },
    {
        value: 'DK',
        display: 'DENMARK',
    },
    {
        value: 'DJ',
        display: 'DJIBOUTI',
    },
    {
        value: 'DM',
        display: 'DOMINICA',
    },
    {
        value: 'DO',
        display: 'DOMINICAN REPUBLIC',
    },
    {
        value: 'EC',
        display: 'ECUADOR',
    },
    {
        value: 'EG',
        display: 'EGYPT',
    },
    {
        value: 'SV',
        display: 'EL SALVADOR',
    },
    {
        value: 'GQ',
        display: 'EQUATORIAL GUINEA',
    },
    {
        value: 'ER',
        display: 'ERITREA',
    },
    {
        value: 'EE',
        display: 'ESTONIA',
    },
    {
        value: 'ET',
        display: 'ETHIOPIA',
    },
    {
        value: 'FK',
        display: 'FALKLAND ISLANDS (MALVINAS)',
    },
    {
        value: 'FO',
        display: 'FAROE ISLANDS',
    },
    {
        value: 'FJ',
        display: 'FIJI',
    },
    {
        value: 'FI',
        display: 'FINLAND',
    },
    {
        value: 'FR',
        display: 'FRANCE',
    },
    {
        value: 'GF',
        display: 'FRENCH GUIANA',
    },
    {
        value: 'PF',
        display: 'FRENCH POLYNESIA',
    },
    {
        value: 'TF',
        display: 'FRENCH SOUTHERN TERRITORIES',
    },
    {
        value: 'GA',
        display: 'GABON',
    },
    {
        value: 'GM',
        display: 'GAMBIA',
    },
    {
        value: 'GE',
        display: 'GEORGIA',
    },
    {
        value: 'DE',
        display: 'GERMANY',
    },
    {
        value: 'GH',
        display: 'GHANA',
    },
    {
        value: 'GI',
        display: 'GIBRALTAR',
    },
    {
        value: 'GR',
        display: 'GREECE',
    },
    {
        value: 'GL',
        display: 'GREENLAND',
    },
    {
        value: 'GD',
        display: 'GRENADA',
    },
    {
        value: 'GP',
        display: 'GUADELOUPE',
    },
    {
        value: 'GU',
        display: 'GUAM',
    },
    {
        value: 'GT',
        display: 'GUATEMALA',
    },
    {
        value: 'GG',
        display: 'GUERNSEY',
    },
    {
        value: 'GN',
        display: 'GUINEA',
    },
    {
        value: 'GW',
        display: 'GUINEA-BISSAU',
    },
    {
        value: 'GY',
        display: 'GUYANA',
    },
    {
        value: 'HT',
        display: 'HAITI',
    },
    {
        value: 'HM',
        display: 'HEARD ISLAND AND MCDONALD ISLANDS',
    },
    {
        value: 'VA',
        display: 'HOLY SEE (VATICAN CITY STATE)',
    },
    {
        value: 'HN',
        display: 'HONDURAS',
    },
    {
        value: 'HK',
        display: 'HONG KONG',
    },
    {
        value: 'HU',
        display: 'HUNGARY',
    },
    {
        value: 'IS',
        display: 'ICELAND',
    },
    {
        value: 'IN',
        display: 'INDIA',
    },
    {
        value: 'ID',
        display: 'INDONESIA',
    },
    {
        value: 'IR',
        display: 'IRAN, ISLAMIC REPUBLIC OF',
    },
    {
        value: 'IQ',
        display: 'IRAQ',
    },
    {
        value: 'IE',
        display: 'IRELAND',
    },
    {
        value: 'IM',
        display: 'ISLE OF MAN',
    },
    {
        value: 'IL',
        display: 'ISRAEL',
    },
    {
        value: 'IT',
        display: 'ITALY',
    },
    {
        value: 'JM',
        display: 'JAMAICA',
    },
    {
        value: 'JP',
        display: 'JAPAN',
    },
    {
        value: 'JE',
        display: 'JERSEY',
    },
    {
        value: 'JO',
        display: 'JORDAN',
    },
    {
        value: 'KZ',
        display: 'KAZAKHSTAN',
    },
    {
        value: 'KE',
        display: 'KENYA',
    },
    {
        value: 'KI',
        display: 'KIRIBATI',
    },
    {
        value: 'KP',
        display: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    },
    {
        value: 'KR',
        display: 'KOREA, REPUBLIC OF',
    },
    {
        value: 'KW',
        display: 'KUWAIT',
    },
    {
        value: 'KG',
        display: 'KYRGYZSTAN',
    },
    {
        value: 'LA',
        display: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    },
    {
        value: 'LV',
        display: 'LATVIA',
    },
    {
        value: 'LB',
        display: 'LEBANON',
    },
    {
        value: 'LS',
        display: 'LESOTHO',
    },
    {
        value: 'LR',
        display: 'LIBERIA',
    },
    {
        value: 'LY',
        display: 'LIBYA',
    },
    {
        value: 'LI',
        display: 'LIECHTENSTEIN',
    },
    {
        value: 'LT',
        display: 'LITHUANIA',
    },
    {
        value: 'LU',
        display: 'LUXEMBOURG',
    },
    {
        value: 'MO',
        display: 'MACAO',
    },
    {
        value: 'MK',
        display: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
    },
    {
        value: 'MG',
        display: 'MADAGASCAR',
    },
    {
        value: 'MW',
        display: 'MALAWI',
    },
    {
        value: 'MY',
        display: 'MALAYSIA',
    },
    {
        value: 'MV',
        display: 'MALDIVES',
    },
    {
        value: 'ML',
        display: 'MALI',
    },
    {
        value: 'MT',
        display: 'MALTA',
    },
    {
        value: 'MH',
        display: 'MARSHALL ISLANDS',
    },
    {
        value: 'MQ',
        display: 'MARTINIQUE',
    },
    {
        value: 'MR',
        display: 'MAURITANIA',
    },
    {
        value: 'MU',
        display: 'MAURITIUS',
    },
    {
        value: 'YT',
        display: 'MAYOTTE',
    },
    {
        value: 'MX',
        display: 'MEXICO',
    },
    {
        value: 'FM',
        display: 'MICRONESIA, FEDERATED STATES OF',
    },
    {
        value: 'MD',
        display: 'MOLDOVA, REPUBLIC OF',
    },
    {
        value: 'MC',
        display: 'MONACO',
    },
    {
        value: 'MN',
        display: 'MONGOLIA',
    },
    {
        value: 'ME',
        display: 'MONTENEGRO',
    },
    {
        value: 'MS',
        display: 'MONTSERRAT',
    },
    {
        value: 'MA',
        display: 'MOROCCO',
    },
    {
        value: 'MZ',
        display: 'MOZAMBIQUE',
    },
    {
        value: 'MM',
        display: 'MYANMAR',
    },
    {
        value: 'NA',
        display: 'NAMIBIA',
    },
    {
        value: 'NR',
        display: 'NAURU',
    },
    {
        value: 'NP',
        display: 'NEPAL',
    },
    {
        value: 'NL',
        display: 'NETHERLANDS',
    },
    {
        value: 'NC',
        display: 'NEW CALEDONIA',
    },
    {
        value: 'NZ',
        display: 'NEW ZEALAND',
    },
    {
        value: 'NI',
        display: 'NICARAGUA',
    },
    {
        value: 'NE',
        display: 'NIGER',
    },
    {
        value: 'NG',
        display: 'NIGERIA',
    },
    {
        value: 'NU',
        display: 'NIUE',
    },
    {
        value: 'NF',
        display: 'NORFOLK ISLAND',
    },
    {
        value: 'MP',
        display: 'NORTHERN MARIANA ISLANDS',
    },
    {
        value: 'NO',
        display: 'NORWAY',
    },
    {
        value: 'OM',
        display: 'OMAN',
    },
    {
        value: 'PK',
        display: 'PAKISTAN',
    },
    {
        value: 'PW',
        display: 'PALAU',
    },
    {
        value: 'PS',
        display: 'PALESTINE, STATE OF',
    },
    {
        value: 'PA',
        display: 'PANAMA',
    },
    {
        value: 'PG',
        display: 'PAPUA NEW GUINEA',
    },
    {
        value: 'PY',
        display: 'PARAGUAY',
    },
    {
        value: 'PE',
        display: 'PERU',
    },
    {
        value: 'PH',
        display: 'PHILIPPINES',
    },
    {
        value: 'PN',
        display: 'PITCAIRN',
    },
    {
        value: 'PL',
        display: 'POLAND',
    },
    {
        value: 'PT',
        display: 'PORTUGAL',
    },
    {
        value: 'PR',
        display: 'PUERTO RICO',
    },
    {
        value: 'QA',
        display: 'QATAR',
    },
    {
        value: 'RO',
        display: 'ROMANIA',
    },
    {
        value: 'RU',
        display: 'RUSSIAN FEDERATION',
    },
    {
        value: 'RW',
        display: 'RWANDA',
    },
    {
        value: 'RE',
        display: 'RÉUNION',
    },
    {
        value: 'BL',
        display: 'SAINT BARTHÉLEMY',
    },
    {
        value: 'SH',
        display: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    },
    {
        value: 'KN',
        display: 'SAINT KITTS AND NEVIS',
    },
    {
        value: 'LC',
        display: 'SAINT LUCIA',
    },
    {
        value: 'MF',
        display: 'SAINT MARTIN (FRENCH PART)',
    },
    {
        value: 'PM',
        display: 'SAINT PIERRE AND MIQUELON',
    },
    {
        value: 'VC',
        display: 'SAINT VINCENT AND THE GRENADINES',
    },
    {
        value: 'WS',
        display: 'SAMOA',
    },
    {
        value: 'SM',
        display: 'SAN MARINO',
    },
    {
        value: 'ST',
        display: 'SAO TOME AND PRINCIPE',
    },
    {
        value: 'SA',
        display: 'SAUDI ARABIA',
    },
    {
        value: 'SN',
        display: 'SENEGAL',
    },
    {
        value: 'RS',
        display: 'SERBIA',
    },
    {
        value: 'SC',
        display: 'SEYCHELLES',
    },
    {
        value: 'SL',
        display: 'SIERRA LEONE',
    },
    {
        value: 'SG',
        display: 'SINGAPORE',
    },
    {
        value: 'SX',
        display: 'SINT MAARTEN (DUTCH PART)',
    },
    {
        value: 'SK',
        display: 'SLOVAKIA',
    },
    {
        value: 'SI',
        display: 'SLOVENIA',
    },
    {
        value: 'SB',
        display: 'SOLOMON ISLANDS',
    },
    {
        value: 'SO',
        display: 'SOMALIA',
    },
    {
        value: 'ZA',
        display: 'SOUTH AFRICA',
    },
    {
        value: 'GS',
        display: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    },
    {
        value: 'SS',
        display: 'SOUTH SUDAN',
    },
    {
        value: 'ES',
        display: 'SPAIN',
    },
    {
        value: 'LK',
        display: 'SRI LANKA',
    },
    {
        value: 'SD',
        display: 'SUDAN',
    },
    {
        value: 'SR',
        display: 'SURINAME',
    },
    {
        value: 'SJ',
        display: 'SVALBARD AND JAN MAYEN',
    },
    {
        value: 'SZ',
        display: 'SWAZILAND',
    },
    {
        value: 'SE',
        display: 'SWEDEN',
    },
    {
        value: 'CH',
        display: 'SWITZERLAND',
    },
    {
        value: 'SY',
        display: 'SYRIAN ARAB REPUBLIC',
    },
    {
        value: 'TW',
        display: 'TAIWAN, PROVINCE OF CHINA',
    },
    {
        value: 'TJ',
        display: 'TAJIKISTAN',
    },
    {
        value: 'TZ',
        display: 'TANZANIA, UNITED REPUBLIC OF',
    },
    {
        value: 'TH',
        display: 'THAILAND',
    },
    {
        value: 'TL',
        display: 'TIMOR-LESTE',
    },
    {
        value: 'TG',
        display: 'TOGO',
    },
    {
        value: 'TK',
        display: 'TOKELAU',
    },
    {
        value: 'TO',
        display: 'TONGA',
    },
    {
        value: 'TT',
        display: 'TRINIDAD AND TOBAGO',
    },
    {
        value: 'TN',
        display: 'TUNISIA',
    },
    {
        value: 'TR',
        display: 'TURKEY',
    },
    {
        value: 'TM',
        display: 'TURKMENISTAN',
    },
    {
        value: 'TC',
        display: 'TURKS AND CAICOS ISLANDS',
    },
    {
        value: 'TV',
        display: 'TUVALU',
    },
    {
        value: 'UG',
        display: 'UGANDA',
    },
    {
        value: 'UA',
        display: 'UKRAINE',
    },
    {
        value: 'AE',
        display: 'UNITED ARAB EMIRATES',
    },
    {
        value: 'GB',
        display: 'UNITED KINGDOM',
    },
    {
        value: 'US',
        display: 'UNITED STATES',
    },
    {
        value: 'UM',
        display: 'UNITED STATES MINOR OUTLYING ISLANDS',
    },
    {
        value: 'UY',
        display: 'URUGUAY',
    },
    {
        value: 'UZ',
        display: 'UZBEKISTAN',
    },
    {
        value: 'VU',
        display: 'VANUATU',
    },
    {
        value: 'VE',
        display: 'VENEZUELA, BOLIVARIAN REPUBLIC OF',
    },
    {
        value: 'VN',
        display: 'VIET NAM',
    },
    {
        value: 'VG',
        display: 'VIRGIN ISLANDS, BRITISH',
    },
    {
        value: 'VI',
        display: 'VIRGIN ISLANDS, U.S.',
    },
    {
        value: 'WF',
        display: 'WALLIS AND FUTUNA',
    },
    {
        value: 'EH',
        display: 'WESTERN SAHARA',
    },
    {
        value: 'YE',
        display: 'YEMEN',
    },
    {
        value: 'ZM',
        display: 'ZAMBIA',
    },
    {
        value: 'ZW',
        display: 'ZIMBABWE',
    },
    {
        value: 'AX',
        display: 'ÅLAND ISLANDS',
    },
];

export default countries;

import _, { some, max } from 'lodash';
import { createSelector } from 'reselect';

export const boxUiSelector = (state) => state.ui.box;

export const sidePanelIsOpenSelector = createSelector(boxUiSelector, (ui) =>
    some(ui, ({ isOpen }, name) => isOpen && name.match(/_SIDE_PANEL$/))
);

export const sidePanelZIndexOverrideSelector = createSelector(boxUiSelector, (ui) => {
    const openOverrides = _(ui)
        .map(({ isOpen, zIndexOverride }) => isOpen && zIndexOverride)
        .compact()
        .value();
    return openOverrides.length > 0 ? max(openOverrides) : null;
});

import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';

import loadingStateEnum from './constants/loadingStateEnum';

const LocationQuickAdd = () => {
    const [isLocationStale, setIsLocationStale] = useState(true);
    const [locationsLoadingState, setLocationsLoadingState] = useState(loadingStateEnum.READY);

    const resetState = useCallback(() => {
        setIsLocationStale(true);
        setLocationsLoadingState(loadingStateEnum.READY);
    }, [setIsLocationStale, setLocationsLoadingState]);

    return {
        isLocationStale,
        setIsLocationStale,
        locationsLoadingState,
        setLocationsLoadingState,
        resetState,
    };
};

export const LocationQuickAddContext = createContainer(LocationQuickAdd);

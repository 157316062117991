import { formatSvgPath } from '../../helpers';

import ApproachTurn from '../../../../../../../../../images/crash-diagram/arrows/approach-turn.svg';
import Broadside from '../../../../../../../../../images/crash-diagram/arrows/broadside.svg';
import HeadOnSideSwipe from '../../../../../../../../../images/crash-diagram/arrows/head-on-sideswipe.svg';
import HeadOn from '../../../../../../../../../images/crash-diagram/arrows/head-on.svg';
import NoPassingLine from '../../../../../../../../../images/crash-diagram/arrows/no-passing-line.svg';
import OutOfControlSpinout from '../../../../../../../../../images/crash-diagram/arrows/out-of-control-spinout.svg';
import OvertakingSideSwipe from '../../../../../../../../../images/crash-diagram/arrows/overtaking-sideswipe.svg';
import OvertakingTurn from '../../../../../../../../../images/crash-diagram/arrows/overtaking-turn.svg';
import Overturned from '../../../../../../../../../images/crash-diagram/arrows/overturned.svg';
import PedestrianAnimalArrow from '../../../../../../../../../images/crash-diagram/arrows/pedestrian-animal-arrow.svg';
import RearEnd from '../../../../../../../../../images/crash-diagram/arrows/rear-end.svg';
import TrainArrow from '../../../../../../../../../images/crash-diagram/arrows/train-arrow.svg';
import VehicleArrow from '../../../../../../../../../images/crash-diagram/arrows/vehicle-arrow.svg';
import VehicleBacking from '../../../../../../../../../images/crash-diagram/arrows/vehicle-backing.svg';
import Collision from '../../../../../../../../../images/crash-diagram/arrows/collision.svg';
import VehicleCurvedArrow from '../../../../../../../../../images/crash-diagram/arrows/vehicle-curved-arrow.svg';

export const arrows = [
    ApproachTurn,
    Broadside,
    HeadOnSideSwipe,
    HeadOn,
    NoPassingLine,
    OutOfControlSpinout,
    OvertakingSideSwipe,
    OvertakingTurn,
    Overturned,
    PedestrianAnimalArrow,
    RearEnd,
    TrainArrow,
    VehicleArrow,
    VehicleBacking,
    Collision,
    VehicleCurvedArrow,
].map((svgPath) => formatSvgPath(svgPath));

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import createCard from '../../utils/createCard';

const towVehicleCheckInCard = createCard({
    name: reportCardEnum.CHECK_IN_INFORMATION.name,
    baseSelector: (state) => state.ui.report.towVehicleCheckInCard,
    anchor: 'tow-vehicle-check-in-card',
});

export default towVehicleCheckInCard;

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { actions } from 'react-redux-form';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { addNameReportLink } from '~/client-common/core/domain/name-report-links/state/data';

import { replaceLocationEntityLinksForEntityAndLinkTypes } from '~/client-common/core/domain/location-entity-links/state/data';
import createCard from '../../utils/createCard';
import involvedProfilesForm, {
    buildInvolvedProfilesCardFormModel,
} from '../forms/involvedProfilesForm';
import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { submitCard, validateCard, initializeCardForm } from './cards';

const formModelPath = 'formModels.formInvolvedProfiles';

const baseCard = createCard({
    name: reportCardEnum.INVOLVED_PROFILES.name,
    baseSelector: (state) => state.ui.report.involvedProfilesCard,
    anchor: 'involved-profiles-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit({ reportId }) {
            return (dispatch) => {
                const formModel = dispatch(buildInvolvedProfilesCardFormModel({ reportId }));
                dispatch(involvedProfilesForm.actionCreators.change(formModel));
                dispatch(initializeCardForm(involvedProfilesForm));
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save() {
            return (dispatch, getState) => {
                dispatch(actions.setInitial(formModelPath));

                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formModule: involvedProfilesForm,
                        })
                    );
                } else {
                    dispatch(involvedProfilesForm.actionCreators.clearHiddenFields());
                    const applicationSettings = applicationSettingsSelector(state);

                    const currentReportId = currentReportIdSelector(state);
                    const formModel = involvedProfilesForm.selectors.formModelSelector(state);
                    const formData = involvedProfilesForm.convertFromFormModel(formModel);
                    const otherLocationInReportLELs = formData.locationEntityLinks.filter(
                        (lel) => lel.linkType === LinkTypesEnum.OTHER_LOCATION_IN_REPORT
                    );

                    let nameReportLinks = formData.nameReportLinks;
                    // When this FF is enabled, Person Side Panel is the place where we select and save Subject Type for person-report links
                    // For organisation-report links, Subject Type is still being selected in the card form, so it needs to be saved
                    if (applicationSettings.RMS_PERSON_SIDE_PANEL_INVOLVEMENT_TYPE_ENABLED) {
                        nameReportLinks = formData.nameReportLinks.filter((nameReportLink) =>
                            nameReportLink?.entityType
                                ? nameReportLink.entityType !== EntityTypeEnum.PERSON_PROFILE.name
                                : false
                        );
                    }

                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formModule: involvedProfilesForm,
                            promises: [
                                ...nameReportLinks.map((nameReportLink) =>
                                    dispatch(addNameReportLink(nameReportLink))
                                ),
                                dispatch(
                                    replaceLocationEntityLinksForEntityAndLinkTypes({
                                        entityType: EntityTypeEnum.REPORT.name,
                                        entityId: currentReportId,
                                        linkTypes: [LinkTypesEnum.OTHER_LOCATION_IN_REPORT],
                                        locationEntityLinks: otherLocationInReportLELs,
                                    })
                                ),
                            ],
                        })
                    );
                }
            };
        },
    },
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { get, slice } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import testIds from '../../../../core/testIds';
import {
    uploadedFilesSelector,
    existingUploadingFilesSelector,
    attachmentsShownCountSelector,
    saveAttachmentsSidePanelForm,
    cleanupAttachmentsSidePanel,
    currentAttachmentsByFolderIdAndEntityTypeSelector,
} from '../state/ui/attachmentsSidePanel';
import DeleteAttachmentConfirmationModal from './DeleteAttachmentConfirmationModal';
import AttachmentsSidePanelImageUploadForm from './AttachmentsSidePanelImageUploadForm';
import AttachmentsSidePanelUploadForm from './AttachmentsSidePanelUploadForm';

const strings = componentStrings.reports.core.AttachmentsSidePanel;

function Content(props) {
    return get(props, 'entityLinkDetails.imageLinkType') ? (
        <AttachmentsSidePanelImageUploadForm {...props} />
    ) : (
        <AttachmentsSidePanelUploadForm {...props} />
    );
}

const AttachmentsSidePanel = ({
    renderButton,
    cleanupAttachmentsSidePanel,
    saveAttachmentsSidePanelForm,
    uploadedFiles,
    attachmentsShownCount,
    existingUploadingFiles,
    overlayId,
    getInitialCustomPropertyState,
    currentFolderId,
    entityType,
    currentAttachmentsByFolderIdAndEntityType,
}) => {
    return (
        <OverlayBaseHelper
            id={overlayId}
            renderButton={renderButton}
            getInitialCustomPropertyState={getInitialCustomPropertyState}
        >
            {(overlayBaseRenderProps) => {
                const { overlayBase, closePanel } = overlayBaseRenderProps;
                const { customProperties: entityLinkDetails, isLoading } = overlayBase.overlayState;

                const attachments = currentAttachmentsByFolderIdAndEntityType({
                    folderId: currentFolderId,
                    entityType,
                    entityLinkDetails,
                });

                const shownAttachments = slice(attachments, 0, attachmentsShownCount);

                const hasAttachments = uploadedFiles.length > 0 || attachments.length > 0;

                function handleClosePanel() {
                    cleanupAttachmentsSidePanel();
                    closePanel();
                }

                function saveAttachmentsSidePanel() {
                    saveAttachmentsSidePanelForm(uploadedFiles, entityLinkDetails, currentFolderId)
                        .then((cleanup) => {
                            if (cleanup) {
                                cleanupAttachmentsSidePanel();
                            }
                            closePanel();
                        })
                        .catch((error) => overlayBase.setError(error.message));
                }

                return (
                    <PortalSidePanel
                        id={overlayId}
                        errorMessages={overlayBase.overlayState.errors}
                        userHasAttemptedSave={!!overlayBase.overlayState.errors.length}
                        testId={testIds.ATTACHMENTS_SIDE_PANEL}
                        title={strings.title(
                            existingUploadingFiles.length +
                                uploadedFiles.length +
                                shownAttachments.length
                        )}
                        saveText={strings.save}
                        savePanel={saveAttachmentsSidePanel}
                        saveDisabled={isLoading}
                        cancelText={strings.cancel}
                        closePanel={handleClosePanel}
                        loading={isLoading}
                        isAtBottomOfStack={() => {
                            return overlayBase.overlayStore.isOverlayAtBottomOfStack(overlayId);
                        }}
                    >
                        <Content
                            attachments={attachments}
                            entityLinkDetails={entityLinkDetails}
                            hasAttachments={hasAttachments}
                            overlayBase={overlayBase}
                            entityType={entityType}
                        />
                        <DeleteAttachmentConfirmationModal />
                    </PortalSidePanel>
                );
            }}
        </OverlayBaseHelper>
    );
};

AttachmentsSidePanel.propTypes = {
    overlayId: PropTypes.string.isRequired,
};

export default compose(
    connect(
        createStructuredSelector({
            attachmentsShownCount: attachmentsShownCountSelector,
            existingUploadingFiles: existingUploadingFilesSelector,
            uploadedFiles: uploadedFilesSelector,
            currentAttachmentsByFolderIdAndEntityType: currentAttachmentsByFolderIdAndEntityTypeSelector,
        }),
        { cleanupAttachmentsSidePanel, saveAttachmentsSidePanelForm }
    )
)(AttachmentsSidePanel);

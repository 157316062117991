import { first, isArray, map, uniq, without, find, chain, intersection, size } from 'lodash';
import { createSelector } from 'reselect';
import {
    loadReportLevelStaffRemarksForItemProfiles,
    staffRemarksByMasterItemIdSelector,
} from '~/client-common/core/domain/staff-remarks/state/data';
import { loadAllItemProfilesForMasterItemIds } from '~/client-common/core/domain/item-profiles/state/data';
import {
    itemProfileViewModelsSelector,
    itemTypeSpecificViewModelByIdSelector,
} from '~/client-common/core/domain/item-profiles/state/ui';
import { createChainEvents } from '~/client-common/core/domain/chain-events/state/data';
import { chainEventViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { chainEventTypesByCategorySelector } from '~/client-common/core/domain/chain-event-types/state/data';
import { latestChainOfCustodiesForMasterItemIdsSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import getEvidenceBasketResource from '~/client-common/core/domain/evidence/resources/evidenceBasketResource';
import { itemEvidenceStateByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import {
    closeBox,
    openLoadingModal,
    closeLoadingModal,
} from '../../../../../legacy-redux/actions/boxActions';
import { clearUploads } from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';

import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import { openConfirmationBar } from '../../../../core/confirmation-bar/state/ui';
import createChainEventsForm from '../forms/createChainEventsForm';
import {
    addItemReportLevelStaffRemarksToLoad,
    LOAD_ITEM_QUEUE_START,
    LOAD_ITEM_QUEUE_FAILURE,
    LOAD_ITEM_QUEUE_SUCCESS,
    loadEvidenceItems,
    ADD_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD,
} from '../../../core/state/data';

import { ITEM_QUEUE_AUTO_OPEN_CLOSE_DELAY } from '../../configuration';
import { openItemQueuePopover, itemQueuePopoverIsOpenSelector, popoverContext } from './popover';

export * from './popover';

const strings = componentStrings.evidence.itemQueue;

const ADD_TO_ITEM_QUEUE_START = 'item-queue/ADD_TO_ITEM_QUEUE_START';
const ADD_TO_ITEM_QUEUE_SUCCESS = 'item-queue/ADD_TO_ITEM_QUEUE_SUCCESS';
const ADD_TO_ITEM_QUEUE_FAILURE = 'item-queue/ADD_TO_ITEM_QUEUE_FAILURE';
const REMOVE_FROM_ITEM_QUEUE_START = 'item-queue/REMOVE_FROM_ITEM_QUEUE_START';
const REMOVE_FROM_ITEM_QUEUE_SUCCESS = 'item-queue/REMOVE_FROM_ITEM_QUEUE_SUCCESS';
const REMOVE_FROM_ITEM_QUEUE_FAILURE = 'item-queue/REMOVE_FROM_ITEM_QUEUE_FAILURE';
const CLEAR_ITEM_QUEUE_START = 'item-queue/CLEAR_ITEM_QUEUE_START';
const CLEAR_ITEM_QUEUE_SUCCESS = 'item-queue/CLEAR_ITEM_QUEUE_SUCCESS';
const CLEAR_ITEM_QUEUE_FAILURE = 'item-queue/CLEAR_ITEM_QUEUE_FAILURE';
const SUBMIT_CREATE_CHAIN_EVENTS_FORM_START = 'item-queue/SUBMIT_CREATE_CHAIN_EVENTS_FORM_START';
const SUBMIT_CREATE_CHAIN_EVENTS_FORM_SUCCESS =
    'item-queue/SUBMIT_CREATE_CHAIN_EVENTS_FORM_SUCCESS';
const SUBMIT_CREATE_CHAIN_EVENTS_FORM_FAILURE =
    'item-queue/SUBMIT_CREATE_CHAIN_EVENTS_FORM_FAILURE';
const LOAD_EVIDENCE_ITEMS_START = 'item-queue/LOAD_EVIDENCE_ITEMS_START';
const LOAD_EVIDENCE_ITEMS_SUCCESS = 'item-queue/LOAD_EVIDENCE_ITEMS_SUCCESS';
const DISABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE = 'item-queue/DISABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE';
const ENABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE = 'item-queue/ENABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE';
const ITEM_QUEUE_POPOVER_TIMEOUT = 'item-queue/ITEM_QUEUE_POPOVER_TIMEOUT';
const CLEAR_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD =
    'item-queue/CLEAR_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD';

function addToItemQueueStart() {
    return {
        type: ADD_TO_ITEM_QUEUE_START,
    };
}

function addToItemQueueSuccess(masterItemIds) {
    return {
        type: ADD_TO_ITEM_QUEUE_SUCCESS,
        payload: masterItemIds,
    };
}

function addToItemQueueFailure(errorMessage) {
    return {
        type: ADD_TO_ITEM_QUEUE_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

function removeFromItemQueueStart(masterItemId) {
    return {
        type: REMOVE_FROM_ITEM_QUEUE_START,
        payload: masterItemId,
    };
}

function removeFromItemQueueSuccess(masterItemId) {
    return {
        type: REMOVE_FROM_ITEM_QUEUE_SUCCESS,
        payload: masterItemId,
    };
}

function removeFromItemQueueFailure(masterItemId, errorMessage) {
    return {
        type: REMOVE_FROM_ITEM_QUEUE_FAILURE,
        error: true,
        payload: {
            masterItemId,
            errorMessage,
        },
    };
}

function clearItemQueueStart() {
    return {
        type: CLEAR_ITEM_QUEUE_START,
    };
}

function clearItemQueueSuccess() {
    return {
        type: CLEAR_ITEM_QUEUE_SUCCESS,
    };
}

function clearItemQueueFailure(errorMessage) {
    return {
        type: CLEAR_ITEM_QUEUE_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Add the given master item id(s) to the item queue.
 * @param {number|number[]} masterItemIds
 */
export function addToItemQueue(masterItemIds) {
    return (dispatch) => {
        dispatch(addToItemQueueStart());

        masterItemIds = isArray(masterItemIds) ? masterItemIds : [masterItemIds];

        if (!masterItemIds.length) {
            const message = 'Failed to add items to basket';
            dispatch(addToItemQueueFailure(message));
            dispatch(openItemQueuePopover({ loadQueue: false }));
        } else {
            return getEvidenceBasketResource()
                .addItemsToBasket(masterItemIds)
                .then(() => {
                    dispatch(addToItemQueueSuccess(masterItemIds));
                    dispatch(autoOpenItemQueuePopover());
                })
                .catch((error) => {
                    dispatch(addToItemQueueFailure(error.message));
                    dispatch(openItemQueuePopover({ loadQueue: false }));
                });
        }
    };
}

/**
 * Remove the given master item id from the item queue.
 * @param {number} masterItemId
 */
export function removeFromItemQueue(masterItemId) {
    return (dispatch) => {
        dispatch(removeFromItemQueueStart(masterItemId));

        return getEvidenceBasketResource()
            .removeItemFromBasket(masterItemId)
            .then(() => dispatch(removeFromItemQueueSuccess(masterItemId)))
            .catch((error) =>
                dispatch(
                    removeFromItemQueueFailure({
                        masterItemId,
                        errorMessage: error.message,
                    })
                )
            );
    };
}

/**
 * Remove all the items from the item queue.
 */
function clearItemQueue() {
    return (dispatch) => {
        dispatch(clearItemQueueStart());

        return getEvidenceBasketResource()
            .clearBasket()
            .then(() => dispatch(clearItemQueueSuccess()))
            .catch((error) => dispatch(clearItemQueueFailure(error.message)));
    };
}

/**
 * Reset the Chain of Events form.
 */
export function resetChainEventsForm() {
    return (dispatch) => {
        dispatch(createChainEventsForm.actionCreators.reset());
        dispatch(clearUploads());
    };
}

/**
 * Remove all items from Item Queue and reset create chain event form
 */
export function resetItemQueue() {
    return (dispatch) => {
        dispatch(resetChainEventsForm());
        return dispatch(clearItemQueue());
    };
}

function submitCreateChainEventsFormStart() {
    return {
        type: SUBMIT_CREATE_CHAIN_EVENTS_FORM_START,
    };
}
function submitCreateChainEventsFormSuccess() {
    return {
        type: SUBMIT_CREATE_CHAIN_EVENTS_FORM_SUCCESS,
    };
}
function submitCreateChainEventsFormFailure(errorMessage) {
    return {
        type: SUBMIT_CREATE_CHAIN_EVENTS_FORM_FAILURE,
        error: true,
        payload: errorMessage,
    };
}

/**
 * Submit the Create Chain Events form. On success, display a confirmation bar
 *   and go back to the previous route. This has START/SUCCESS/FAILURE actions
 *   because it is not inside a "box" and needs to handle its own loading and
 *   error states.
 */
export function submitCreateChainEventsForm(router) {
    return function (dispatch, getState) {
        dispatch(submitCreateChainEventsFormStart());
        return dispatch(
            createChainEventsForm.actionCreators.submit((formModel) => {
                const state = getState();
                const itemQueueCount = itemQueueCountSelector(state);
                const chainOfCustodyIds = map(
                    latestChainOfCustodiesForMasterItemIdsSelector(state)(
                        itemQueueMasterItemIdsSelector(state)
                    ),
                    'id'
                );
                const chainEventsWithAttachments = createChainEventsForm.convertFromFormModel(
                    formModel,
                    chainOfCustodyIds
                );

                return dispatch(createChainEvents(chainEventsWithAttachments))
                    .then(() => {
                        // reset the item queue and close the form immediately after
                        // creating chain events because the corresponding
                        // ItemEvidenceStates are now updated, which would cause
                        // error messages like "no valid status updates" to show up
                        // in the form for the 1+ second before the `goBack` call
                        // below happens
                        dispatch(resetItemQueue());
                        dispatch(openLoadingModal());
                    })
                    .delay(1000) // wait for search sync KRA-1002
                    .then(() => {
                        dispatch(submitCreateChainEventsFormSuccess());
                        router.goBack();
                        dispatch(closeLoadingModal());
                        dispatch(
                            openConfirmationBar({
                                message: strings.CreateChainEventsForm.successConfirmation(
                                    itemQueueCount
                                ),
                            })
                        );
                    })
                    .catch((err) => {
                        dispatch(submitCreateChainEventsFormFailure(err.message));
                        dispatch(closeLoadingModal());
                    });
            })
        ).catch((panelErrors) => {
            dispatch(submitCreateChainEventsFormFailure(first(panelErrors)));
        });
    };
}

function loadEvidenceItemsStart() {
    return {
        type: LOAD_EVIDENCE_ITEMS_START,
    };
}

function loadEvidenceItemsSuccess() {
    return {
        type: LOAD_EVIDENCE_ITEMS_SUCCESS,
    };
}

export function disableItemQueuePopoverTimedClose() {
    return {
        type: DISABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE,
    };
}

function enableItemQueuePopoverTimedClose() {
    return {
        type: ENABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE,
    };
}

/**
 * Before closing item queue popover from timer, check it's open and eligible
 *  for close
 */
function itemQueuePopoverTimedClose() {
    return (dispatch, getState) => {
        const state = getState();

        if (itemQueuePopoverIsOpenSelector(state) && itemQueuePopoverTimedCloseSelector(state)) {
            dispatch(closeBox(popoverContext));
        }
    };
}

function autoOpenItemQueuePopover() {
    return (dispatch) => {
        dispatch(openItemQueuePopover());
        const itemQueueTimeout = setTimeout(
            () => dispatch(itemQueuePopoverTimedClose()),
            ITEM_QUEUE_AUTO_OPEN_CLOSE_DELAY
        );
        dispatch(itemQueuePopoverTimeout(itemQueueTimeout));
    };
}

/**
 * Add items to queue from Evidence Dashboard. Load server data for all these
 *   items, even ones that are already in redux state, because it's possible for
 *   some data such as storage locations to be missing or outdated.
 *
 * On success, auto open the item queue popover and set timer for auto close.
 */
export function addToItemQueueWithEvidenceItems(masterItemIds) {
    return (dispatch) => {
        // reset popover timed close
        dispatch(enableItemQueuePopoverTimedClose());
        dispatch(itemQueuePopoverTimeout(null));

        dispatch(loadEvidenceItemsStart());

        dispatch(loadEvidenceItems(masterItemIds))
            .then((items) => map(items, 'item.masterItemId'))
            .then((masterItemIds) => {
                dispatch(addItemReportLevelStaffRemarksToLoad(masterItemIds));
                dispatch(addToItemQueue(masterItemIds));
                dispatch(loadEvidenceItemsSuccess());
            });
    };
}

function itemQueuePopoverTimeout(timeout) {
    return {
        type: ITEM_QUEUE_POPOVER_TIMEOUT,
        payload: timeout,
    };
}

function clearItemReportLevelStaffRemarksToLoad() {
    return {
        type: CLEAR_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD,
    };
}

/**
 * Loads report level staff remarks for items which have been newly added
 *   to the item queue, either on page load or through the Evidence Dashboard.
 */
export function loadItemReportLevelStaffRemarks() {
    return (dispatch, getState) => {
        const masterItemIds = itemReportLevelStaffRemarksToLoadSelector(getState());
        dispatch(loadAllItemProfilesForMasterItemIds(masterItemIds)).then((itemProfiles) => {
            dispatch(loadReportLevelStaffRemarksForItemProfiles(itemProfiles));
            dispatch(clearItemReportLevelStaffRemarksToLoad());
        });
    };
}

/**
 * Custom onClose callback to reset auto close timer.
 */
export function closeItemQueuePopover() {
    return (dispatch, getState) => {
        const itemQueueTimeout = itemQueuePopoverTimeoutSelector(getState());
        if (itemQueueTimeout) {
            clearTimeout(itemQueueTimeout);
        }

        dispatch(closeBox(popoverContext));
    };
}

const itemQueueUiSelector = (state) => state.ui.evidence.itemQueue;

/**
 * The ids of the master items in the item queue.
 * @returns {number[]}
 */
export const itemQueueMasterItemIdsSelector = createSelector(
    itemQueueUiSelector,
    ({ masterItemIds }) => masterItemIds
);

/**
 * Whether the item queue is loading, awaiting API response.
 * @returns {number[]}
 */
export const loadingItemQueueSelector = createSelector(
    itemQueueUiSelector,
    ({ loadingItemQueue }) => loadingItemQueue
);

/**
 * Error message when the item queue is loaded.
 * @returns {number[]}
 */
export const itemQueueErrorMessageSelector = createSelector(
    itemQueueUiSelector,
    ({ loadItemQueueErrorMessage }) => loadItemQueueErrorMessage
);

/**
 * The ids of the master items in the item queue that are loading.
 * @returns {number[]}
 */
export const loadingItemIdsSelector = createSelector(
    itemQueueUiSelector,
    ({ loadingItemIds }) => loadingItemIds
);

/**
 * Whether the Create Chain Events form is being submitted, awaiting API
 *   response.
 * @type {boolean}
 */
export const createChainEventsPanelIsSubmittingSelector = createSelector(
    itemQueueUiSelector,
    ({ createChainEventsPanelIsSubmitting }) => createChainEventsPanelIsSubmitting
);

/**
 * Error message when the Create Chain Events form is submitted.
 * @type {string}
 */
export const createChainEventsPanelErrorMessageSelector = createSelector(
    itemQueueUiSelector,
    ({ createChainEventsPanelErrorMessage }) => createChainEventsPanelErrorMessage
);

/**
 * The number of items in the item queue.
 * @return {number}
 */
export const itemQueueCountSelector = createSelector(
    itemQueueMasterItemIdsSelector,
    (masterItemIds) => masterItemIds.length
);

/**
 * Loading state for evidence hydrated items API call, when items are added to
 *  item queue basket.
 * @returns {boolean}
 */
export const loadingEvidenceItemsSelector = createSelector(
    itemQueueUiSelector,
    ({ loadingEvidenceItems }) => loadingEvidenceItems
);

/**
 * Whether to close item queue popover after timer expires.
 * @return {boolean}
 */
export const itemQueuePopoverTimedCloseSelector = createSelector(
    itemQueueUiSelector,
    ({ timedClose }) => timedClose
);

/**
 * The timeout reference for item queue popover delayed close.
 *  Used to clearTimeout if popover is closed before timer finishes.
 * @type {boolean}
 */
const itemQueuePopoverTimeoutSelector = createSelector(
    itemQueueUiSelector,
    ({ itemQueueTimeout }) => itemQueueTimeout
);

/**
 * Master item view model in a special shape for use only in the item queue,
 *   which includes the item queue table and the item queue popover. The object
 *   contains various view models and special properties.
 * @param  {number} masterItemId
 */
export const itemQueueViewModelByMasterItemIdSelector = createSelector(
    itemProfileViewModelsSelector,
    itemTypeSpecificViewModelByIdSelector,
    chainEventViewModelsForMasterItemIdSelector,
    (itemProfileViewModels, itemTypeSpecificViewModelById, chainEventViewModelsForMasterItemId) => (
        masterItemId
    ) => {
        const viewModel =
            itemTypeSpecificViewModelById(masterItemId) ||
            // if no master item view model, find contexted item
            find(itemProfileViewModels, { masterItemId });

        if (!viewModel) {
            return;
        }

        const latestChainEvent = first(chainEventViewModelsForMasterItemId(masterItemId)) || {};

        return {
            ...viewModel,
            latestChainEvent,
            // for ren sequence number
            reportId: getViewModelProperties(latestChainEvent).reportId,
        };
    }
);

/**
 * Master item view models in a special shape for use only in the item queue,
 *   which includes the item queue table and the item queue popover. Each object
 *   contains various view models and special properties.
 * @type {Object[]}
 */
export const itemQueueViewModelsSelector = createSelector(
    itemQueueMasterItemIdsSelector,
    itemQueueViewModelByMasterItemIdSelector,
    (masterItemIds, viewModelByMasterItemId) =>
        chain(masterItemIds).map(viewModelByMasterItemId).compact().value()
);

/**
 * Find the total number of items in the item queue that have staff remarks associated with them.
 * @type {number}
 */
export const itemQueueItemsWithStaffRemarksCountSelector = createSelector(
    itemQueueMasterItemIdsSelector,
    staffRemarksByMasterItemIdSelector,
    (masterItemIds, staffRemarksByMasterItemId) =>
        chain(masterItemIds)
            .reduce(
                (count, masterItemId) =>
                    count + (size(staffRemarksByMasterItemId(masterItemId)) > 0 ? 1 : 0),
                0
            )
            .value()
);

/**
 * The shared valid subsequent chain event type ids of the items currently in
 *   the item queue.
 * @type {number[]}
 */
export const subsequentChainEventTypeIdsSelector = createSelector(
    itemQueueViewModelsSelector,
    itemEvidenceStateByChainOfCustodyIdSelector,
    chainEventTypesByCategorySelector,
    (itemQueueViewModels, itemEvidenceStateByChainOfCustodyId, chainEventTypesByCategory) => {
        const validNextEventsForItemQueueItems = map(
            itemQueueViewModels,
            ({ latestChainEvent: { chainOfCustodyId } }) =>
                chain(itemEvidenceStateByChainOfCustodyId(chainOfCustodyId))
                    .get('validNextCategories')
                    .map(chainEventTypesByCategory)
                    .flatten()
                    .map('id')
                    .value()
        );

        return intersection(...validNextEventsForItemQueueItems);
    }
);

/**
 * The items whose report level staff remarks have not yet been loaded.
 * @type {Object[]}
 */
export const itemReportLevelStaffRemarksToLoadSelector = createSelector(
    itemQueueUiSelector,
    ({ itemReportLevelStaffRemarksToLoad }) => itemReportLevelStaffRemarksToLoad
);

export default function itemQueueUiReducer(
    state = {
        masterItemIds: [],
        loadingItemQueue: false,
        loadItemQueueErrorMessage: null,
        loadingItemIds: [],
        createChainEventsPanelIsSubmitting: false,
        createChainEventsPanelErrorMessage: null,
        loadingEvidenceItems: false,
        timedClose: true,
        itemQueueTimeout: null,
        itemReportLevelStaffRemarksToLoad: [],
    },
    action
) {
    switch (action.type) {
        case LOAD_ITEM_QUEUE_START:
            return {
                ...state,
                loadingItemQueue: true,
                loadItemQueueErrorMessage: null,
            };
        case LOAD_ITEM_QUEUE_SUCCESS:
            return {
                ...state,
                loadingItemQueue: false,
                loadItemQueueErrorMessage: null,
                masterItemIds: action.payload,
            };
        case LOAD_ITEM_QUEUE_FAILURE:
            return {
                ...state,
                loadingItemQueue: false,
                loadItemQueueErrorMessage: action.payload,
            };
        case ADD_TO_ITEM_QUEUE_START:
            return {
                ...state,
                loadingItemQueue: true,
                loadItemQueueErrorMessage: null,
            };
        case ADD_TO_ITEM_QUEUE_SUCCESS:
            return {
                ...state,
                masterItemIds: uniq([...action.payload, ...state.masterItemIds]),
                loadingItemQueue: false,
                loadItemQueueErrorMessage: null,
            };
        case ADD_TO_ITEM_QUEUE_FAILURE:
            return {
                ...state,
                loadingItemQueue: false,
                loadItemQueueErrorMessage: action.payload,
            };
        case REMOVE_FROM_ITEM_QUEUE_START:
            return {
                ...state,
                loadingItemIds: uniq([action.payload, ...state.loadingItemIds]),
                loadItemQueueErrorMessage: null,
            };
        case REMOVE_FROM_ITEM_QUEUE_SUCCESS:
            return {
                ...state,
                masterItemIds: without(state.masterItemIds, action.payload),
                loadingItemIds: without(state.loadingItemIds, action.payload),
                loadItemQueueErrorMessage: null,
            };
        case REMOVE_FROM_ITEM_QUEUE_FAILURE:
            return {
                ...state,
                loadingItemIds: without(state.loadingItemIds, action.payload.masterItemId),
                loadItemQueueErrorMessage: action.payload.errorMessage,
            };
        case CLEAR_ITEM_QUEUE_START:
            return {
                ...state,
                loadingItemQueue: true,
                loadItemQueueErrorMessage: null,
            };
        case CLEAR_ITEM_QUEUE_SUCCESS:
            return {
                ...state,
                masterItemIds: [],
                loadingItemQueue: false,
                loadItemQueueErrorMessage: null,
            };
        case CLEAR_ITEM_QUEUE_FAILURE:
            return {
                ...state,
                loadingItemQueue: false,
                loadItemQueueErrorMessage: action.payload,
            };
        case ENTER_NEW_ROUTE:
            return {
                ...state,
                createChainEventsPanelErrorMessage: null,
            };
        case SUBMIT_CREATE_CHAIN_EVENTS_FORM_START:
            return {
                ...state,
                createChainEventsPanelIsSubmitting: true,
                createChainEventsPanelErrorMessage: null,
            };
        case SUBMIT_CREATE_CHAIN_EVENTS_FORM_SUCCESS:
            return {
                ...state,
                createChainEventsPanelIsSubmitting: false,
                createChainEventsPanelErrorMessage: null,
            };
        case SUBMIT_CREATE_CHAIN_EVENTS_FORM_FAILURE:
            return {
                ...state,
                createChainEventsPanelIsSubmitting: false,
                createChainEventsPanelErrorMessage: action.payload,
            };
        case DISABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE:
            return {
                ...state,
                timedClose: false,
            };
        case ENABLE_ITEM_QUEUE_POPOVER_TIMED_CLOSE:
            return {
                ...state,
                timedClose: true,
            };
        case LOAD_EVIDENCE_ITEMS_START:
            return {
                ...state,
                loadingEvidenceItems: true,
            };
        case LOAD_EVIDENCE_ITEMS_SUCCESS:
            return {
                ...state,
                loadingEvidenceItems: false,
            };
        case ITEM_QUEUE_POPOVER_TIMEOUT:
            return {
                ...state,
                itemQueueTimeout: action.payload,
            };
        case ADD_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD:
            return {
                ...state,
                itemReportLevelStaffRemarksToLoad: uniq([
                    ...action.payload,
                    ...state.itemReportLevelStaffRemarksToLoad,
                ]),
            };
        case CLEAR_ITEM_REPORT_LEVEL_STAFF_REMARKS_TO_LOAD:
            return {
                ...state,
                itemReportLevelStaffRemarksToLoad: [],
            };
        default:
            return state;
    }
}

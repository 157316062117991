import React from 'react';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

const UseOfForceOfficerDemographics: React.FC<{ children: React.ReactNode }> = (props) => {
    const { children } = props;

    return <FeatureFlagged flag="RMS_USE_OF_FORCE_HIDE_OFFICER_DEMOGRAPHICS" fallback={children} />;
};

export default UseOfForceOfficerDemographics;

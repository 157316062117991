import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';

import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { WARRANT_WARRANT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';

const mapStateToProps = createStructuredSelector({
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
});

export default connect(mapStateToProps)(function WarrantNumberSearchText({
    fieldConfigurationContextByContextAndFieldName,
    context,
    onSearch,
    width = 250,
    ...otherProps
}) {
    const fieldConfigContext = fieldConfigurationContextByContextAndFieldName(
        context,
        WARRANT_WARRANT_NUMBER
    );
    return (
        <RRFAsyncText
            placeholder={get(fieldConfigContext, 'placeholderText')}
            width={width}
            asyncAction={onSearch}
            {...otherProps}
        />
    );
});

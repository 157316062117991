import React from 'react';

import ErrorPage from './ErrorPage';

export default function ErrorManager({ error, children }) {
    if (error && error.errorCode) {
        return <ErrorPage error={error} />;
    } else {
        return <>{children}</>;
    }
}

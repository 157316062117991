import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, defaultProps, setDisplayName, setPropTypes, withHandlers } from 'recompose';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { valuesToArray } from '~/client-common/helpers/logicHelpers';

import { searchForOffenseCodes } from '~/client-common/core/domain/offense-codes/state/data';
import { convertStatuteCodeSetFilterToAttrIds } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';

import { attributesByTypeForCurrentDepartmentSelector } from '../../../attributes/state/ui';
import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { offenseCodeOptionsSelector } from '../../../../../legacy-redux/selectors/offenseCodesSelectors';
import AsyncSelect from './AsyncSelect';

const { connectRRFInput } = reactReduxFormHelpers;

const OffenseCodeSelect = compose(
    setDisplayName('OffenseCodeSelect'),
    defaultProps({
        flags: [],
        includeExpired: false,
    }),
    setPropTypes({
        offenseCodeOptions: PropTypes.func,
        flags: PropTypes.arrayOf(PropTypes.string),
        includeExpired: PropTypes.bool,
        statuteCodeSetFilter: PropTypes.string,
    }),
    connect(
        createStructuredSelector({
            offenseCodeOptions: offenseCodeOptionsSelector,
            attributesByType: attributesByTypeForCurrentDepartmentSelector,
        }),
        {
            searchForOffenseCodes,
        }
    ),
    withHandlers({
        searchForOffenseCodes: ({
            searchForOffenseCodes,
            includeExpired,
            flags,
            statuteCodeSetFilter,
            attributesByType,
        }) => {
            return (q) => {
                return searchForOffenseCodes({
                    q,
                    includeExpired,
                    size: 100,
                    from: 0,
                    flags,
                    statuteCodeSetAttrIds: statuteCodeSetFilter
                        ? convertStatuteCodeSetFilterToAttrIds(
                              statuteCodeSetFilter,
                              attributesByType(AttributeTypeEnum.OFFENSE_CODE_STATUTE_CODE_SET.name)
                          )
                        : undefined,
                });
            };
        },
    })
)(function OffenseCodeSelect({
    searchForOffenseCodes,
    offenseCodeOptions,
    flags = [],
    includeExpired = false,
    value,
    statuteCodeSetFilter,
    ...otherProps
}) {
    return (
        <AsyncSelect
            asyncAction={searchForOffenseCodes}
            sortOnRelevance={true}
            options={offenseCodeOptions({
                flags,
                includeExpired,
                selectedOffenseCodeIds: valuesToArray(value || []),
                statuteCodeSetFilter,
            })}
            value={value}
            {...otherProps}
        />
    );
});

/**
 * Select dropdown containing all desired offense codes.
 * @param {string[]} flags           Offense Code flags, depicting interested
 *                                   offense code types.
 * @param {boolean}  includeExpired  Whether or not to include expired offense
 *                                   codes.
 * @param {string}   statuteCodeSetFilter Special filter on the offense codes'
 *                                        statute code set attributes.
 * @param {Object} props Same props as `Select`.
 */
export default OffenseCodeSelect;

export const RRFOffenseCodeSelect = connectRRFInput(OffenseCodeSelect);
export const ArbiterMFTOffenseCodeSelect = arbiterMFTInput(OffenseCodeSelect);

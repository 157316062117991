import { FormEnum } from '@mark43/rms-api';
import {
    filter,
    map,
    get,
    isFunction,
    isEmpty,
    compact,
    sortBy,
    forEach,
    reduce,
    find,
    reject,
} from 'lodash';

import $ from 'jquery';
import { useOfForceSubjectsWhereSelector } from '~/client-common/core/domain/use-of-force-subjects/state/data';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { organizationProfilesSelector } from '~/client-common/core/domain/organization-profiles/state/data';
import { isOffenseModifyingSupplementReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import getReportsResource from '~/client-common/core/domain/reports/resources/reportResource';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { offenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { interpolateErrorMessage } from '~/client-common/core/arbiter-utils/templateRunner';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { cards } from '../../utils/cardsRegistry';
import { scrollToDataAnchor } from '../../../../../legacy-redux/helpers/navigationHelpers';
import { validateSubmission } from '../../../../../legacy-redux/validation/validationRunner';
import {
    currentReportIdSelector,
    isQuickCrashReportSelector,
    renReportSupplementHistoriesSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    setReportIsPackaged,
    setReportIsUcrValidated,
} from '../../../../../legacy-redux/actions/reportsActions';
import { sortedOffensesAndIncidentsForReportIdSelector, refreshOffenseForm } from './offense';
import offenseCards from './offenseCards';

const strings = componentStrings.reports.core.ReportStatusCommentsCard.submissions;

function generateLegacyDataForOffenseCards() {
    return (dispatch, getState) => {
        const state = getState();
        const reportId = currentReportIdSelector(state);
        const offensesAndIncidents = sortedOffensesAndIncidentsForReportIdSelector(state)(reportId);
        const isOmsReport = isOffenseModifyingSupplementReportSelector(state)(reportId);
        if (isOmsReport) {
            const reportSupplements = renReportSupplementHistoriesSelector(state);
            if (reportSupplements) {
                reportSupplements.map((report) => {
                    const offensesAndIncidentsInSupplementReport = sortedOffensesAndIncidentsForReportIdSelector(
                        state
                    )(report.supplementingReportId);
                    offensesAndIncidents.offenses = [
                        ...offensesAndIncidents.offenses,
                        ...offensesAndIncidentsInSupplementReport.offenses,
                    ];
                    offensesAndIncidents.incidents = [
                        ...offensesAndIncidents.incidents,
                        ...offensesAndIncidentsInSupplementReport.incidents,
                    ];
                });
            }
        }
        const { offenses, incidents } = offensesAndIncidents;
        const personProfiles = personProfilesSelector(state);
        const organizationProfiles = organizationProfilesSelector(state);

        function filterForOrganizations(links) {
            return filter(links, (link) => !!organizationProfiles[link.nameId]);
        }

        function filterForPersons(links) {
            return filter(links, (link) => !!personProfiles[link.nameId]);
        }

        function createLinks(links, entityLookup) {
            return map(links, (link) => {
                const entity = entityLookup[link.nameId];
                return {
                    entity,
                    linkData: link,
                };
            });
        }

        function createOrganizationLinks(links) {
            return createLinks(filterForOrganizations(links), organizationProfiles);
        }

        function createPersonLinks(links) {
            return createLinks(filterForPersons(links), personProfiles);
        }

        /**
         * Report submission validators operate on removed `offensePanelView`s, which is
         * why we have to mimic the shape of those, so we can seamlessly integrate
         * our new data into the legacy system
         */
        function createFakePanels(offenses, isIncident) {
            return map(offenses, (offense) => {
                const formState = dispatch(refreshOffenseForm({ offenseId: offense.id }));

                const orgVictimLinks = createOrganizationLinks(formState.links.victims);
                const orgSuspectLinks = createOrganizationLinks(formState.links.suspects);
                const personVictimLinks = createPersonLinks(formState.links.victims);
                const personSuspectLinks = createPersonLinks(formState.links.suspects);

                const offenseCode = offenseCodeByIdSelector(state)(offense.offenseCodeId);

                return {
                    offense: {
                        ...offense,
                        offenseCode,
                    },
                    nameSummaryViews: {
                        VICTIM: {
                            personLinks: personVictimLinks,
                            organizationLinks: orgVictimLinks,
                        },
                        SUSPECT: {
                            personLinks: personSuspectLinks,
                            organizationLinks: orgSuspectLinks,
                        },
                    },
                    isIncident,
                };
            });
        }

        return createFakePanels(offenses, false).concat(createFakePanels(incidents, true));
    };
}

function generateLegacyDataForUseOfForceSubjectCards() {
    return (dispatch, getState) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        const useOfForceSubjects = useOfForceSubjectsWhereSelector(state)({
            reportId: currentReportId,
        });

        return map(useOfForceSubjects, (useOfForceSubject) => {
            const officerUsedForceOnSubject = get(useOfForceSubject, 'officerUsedForceOnSubject');
            const subjectUsedForceOnOfficer = get(useOfForceSubject, 'subjectUsedForceOnOfficer');
            return {
                officerUsedForceOnSubject,
                subjectUsedForceOnOfficer,
            };
        });
    };
}

function getDataForLegacyValidation() {
    return (dispatch) => {
        const useOfForceSubjectCards = dispatch(generateLegacyDataForUseOfForceSubjectCards());
        const offenseCards = dispatch(generateLegacyDataForOffenseCards());
        return {
            useOfForceSubjectCards,
            offenseCards,
        };
    };
}

function getPresubmissionValidationErrors() {
    return (dispatch) => {
        const legacyValidationData = dispatch(getDataForLegacyValidation());
        return validateSubmission({ form: FormEnum.REPORT_SUBMISSION.name }, legacyValidationData);
    };
}

function getSecondaryApprovalValidationErrors() {
    return (dispatch) => {
        const legacyValidationData = dispatch(getDataForLegacyValidation());
        return validateSubmission({ form: FormEnum.SECONDARY_APPROVAL.name }, legacyValidationData);
    };
}

function getOffensePanelErrorMessages() {
    return (dispatch, getState) => {
        // this is only used when staff review validates an offense report after
        // changing NIBRS mappings and only offenses can fail validation. Only
        // including errors from offense panels for now.
        const state = getState();
        const reportId = currentReportIdSelector(state);
        const offenses = get(
            sortedOffensesAndIncidentsForReportIdSelector(state)(reportId),
            'offenses'
        );
        const offenseIds = map(offenses, 'id');
        const formatOffenseCodeById = formatOffenseCodeByIdSelector(state);
        const formatFieldByName = formatFieldByNameSelector(state);
        return reduce(
            offenseIds,
            (acc, offenseId, index) => {
                const uninterpolatedErrors = offenseCards.selectors.errorMessagesSelector(state, {
                    index: offenseId,
                });
                if (!uninterpolatedErrors.length) {
                    return acc;
                }
                const errors = map(uninterpolatedErrors, (err) =>
                    interpolateErrorMessage(err, formatFieldByName)
                );
                const formattedOffenseCode = formatOffenseCodeById({
                    id: offenses[index].offenseCodeId,
                    includeCode: false,
                });
                const currentValue = acc[formattedOffenseCode] || [];
                acc[formattedOffenseCode] = currentValue.concat(errors);
                return acc;
            },
            {}
        );
    };
}

export function validateStaffReview() {
    return (dispatch, getState, { overlayStore }) => {
        const saveOptions = {
            transitionToEditModeOnFailure: false,
            scrollOnFailure: true,
        };

        const secondaryApprovalValidationErrors = dispatch(getSecondaryApprovalValidationErrors());
        if (isEmpty(secondaryApprovalValidationErrors)) {
            return dispatch(saveAllReportCards(saveOptions))
                .then(() => {
                    dispatch(setReportIsUcrValidated(true));
                    scrollToDataAnchor('report-status-comments-card', {
                        wrapperSelector: '.mark43-scrollable-under-subheader',
                    });
                })
                .catch(() => {
                    // Legacy -- special case specifically for Offense Card validation
                    // errors (?)
                    // get error messages that validating left stored on offense panels
                    const errorsByOffense = dispatch(getOffensePanelErrorMessages());
                    // check for offense panel level errors
                    if (!isEmpty(errorsByOffense)) {
                        overlayStore.open(overlayIdEnum.SUBMISSION_ERROR_MODAL, {
                            errors: errorsByOffense,
                            title: strings.errors.secondaryValidation.offenseValidationFailureTitle,
                        });
                    }
                    dispatch(setReportIsUcrValidated(false));
                });
        } else {
            overlayStore.open(overlayIdEnum.SUBMISSION_ERROR_MODAL, {
                errors: secondaryApprovalValidationErrors,
            });
            dispatch(setReportIsUcrValidated(false));
        }
    };
}

// Save all report cards (or validate cards are in summary mode)
// If validation fails for a card in summary mode, it should be put in to edit mode and we should scroll to the top-most card that fails submission
// If success, put the report in to a “packaged” state, which will enable the user to submit
// If fail, show submission errors
export function packageReport() {
    return (dispatch, getState, { overlayStore }) => {
        const saveOptions = { transitionToEditModeOnFailure: true, scrollOnFailure: true };
        const state = getState();
        const currentReportId = currentReportIdSelector(state);

        const onSaveComplete = (success) => {
            if (!success) {
                return;
            }

            // it is important that this check is done _after_ all cards are saved
            // because we need to ensure that we validate against the updated redux state
            // else we will not catch all errors
            const presubmissionErrors = dispatch(getPresubmissionValidationErrors());
            if (!isEmpty(presubmissionErrors)) {
                overlayStore.open(overlayIdEnum.SUBMISSION_ERROR_MODAL, {
                    errors: presubmissionErrors,
                });
                return;
            }

            return getReportsResource()
                .getActiveStatus(currentReportId)
                .then((reportStatusView) => {
                    if (reportStatusView.canCompleteSubmission) {
                        dispatch(setReportIsPackaged(true));
                        scrollToDataAnchor('report-status-comments-card', {
                            wrapperSelector: '.mark43-scrollable-under-subheader',
                        });
                    } else {
                        overlayStore.open(overlayIdEnum.SUBMISSION_ERROR_MODAL, {
                            errors: reportStatusView.submitErrors,
                            title: strings.errors.submissions.title,
                        });
                    }
                })
                .catch((err) => {
                    overlayStore.open(overlayIdEnum.SUBMISSION_ERROR_MODAL, {
                        errors: [err.message],
                        title: strings.errors.package.title,
                    });
                });
        };

        return dispatch(saveAllReportCards(saveOptions))
            .then(() => onSaveComplete(true))
            .catch(() => onSaveComplete(false));
    };
}

function saveReportCard(reportCard) {
    if (isFunction(reportCard.onSave)) {
        return reportCard.onSave().catch((errors) => {
            return {
                ...reportCard,
                errors,
            };
        });
    }
    return Promise.resolve();
}

function saveReportCards(cards) {
    return map(cards, (card) => saveReportCard(card));
}

function getSaveAllReportCardsPromise(cards) {
    const supplementInfoCard = find(cards, { name: reportCardEnum.SUPPLEMENT_INFO.name });
    const narrativeCard = find(cards, { name: reportCardEnum.NARRATIVE.name });

    if (supplementInfoCard && narrativeCard) {
        // when both these cards exist, save one before the other because both endpoints write to the same db table,
        // resulting in deadlocks
        const results = [];
        return Promise.all(saveReportCards(reject(cards, { name: narrativeCard.name })))
            .then((result) => results.push(...result))
            .then(() => saveReportCard(narrativeCard))
            .then((result) => results.push(result))
            .then(() => results);
    } else {
        return Promise.all(saveReportCards(cards));
    }
}

function saveAllReportCards(options) {
    return (dispatch, getState) => {
        return getSaveAllReportCardsPromise(cards).then((results) => {
            const state = getState();
            const isQuickCrashReport = isQuickCrashReportSelector(state);
            const cardsWithErrors = filter(compact(results), (result) => !isEmpty(result.errors));

            /**
             * Validation rules for QuickCrash reports are governed by QuickCrash
             * so we don't want to hard-prevent submission if there's a mismatch in
             * rules configurations between the RMS tenant and QuickCrash. There may
             * be fields in the RMS that are required that aren't collected in QuickCrash.
             * We'll still report the errors to the user for visibility but ultimately the
             * submission won't be prevented
             */
            if (!isQuickCrashReport && cardsWithErrors.length > 0) {
                const orderedCardsWithErrors = compact(
                    sortBy(cardsWithErrors, (card) => {
                        const element = $(`[data-anchor='${get(card, 'anchor')}']`);
                        if (!element) {
                            return;
                        }
                        return element.offset().top;
                    })
                );

                if (options.transitionToEditModeOnFailure) {
                    forEach(cardsWithErrors, (card) => {
                        if (isFunction(card.transitionToEditMode)) {
                            dispatch(card.transitionToEditMode());
                        }
                    });
                }

                if (options.scrollOnFailure) {
                    const scrollToTopCardWithErrors = get(orderedCardsWithErrors[0], 'scrollToTop');
                    if (isFunction(scrollToTopCardWithErrors)) {
                        scrollToTopCardWithErrors();
                    }
                }
                throw new Error('Save all report cards failed');
            }
            return results;
        });
    };
}

import factory from '../../../../../legacy-redux/actions/types/actionTypesFactory';

export default factory('printing', [
    'SAVE_PRINTING_TEMPLATE_START',
    'SAVE_PRINTING_TEMPLATE_SUCCESS',
    'SAVE_PRINTING_TEMPLATE_FAILURE',
    'DELETE_PRINTING_TEMPLATE_START',
    'DELETE_PRINTING_TEMPLATE_SUCCESS',
    'DELETE_PRINTING_TEMPLATE_FAILURE',
    'LOAD_PRINTING_TEMPLATES_START',
    'LOAD_PRINTING_TEMPLATES_SUCCESS',
    'LOAD_PRINTING_TEMPLATES_FAILURE',
]);

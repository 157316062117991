import React from 'react';
import { BriefingDuplicateRequest, BriefingSimpleView, BriefingStateEnum } from '@mark43/rms-api';
import { ConfirmModal, Menu, MenuContent, MenuItem, MenuTrigger, useToast } from 'arc';
import { withRouter } from 'react-router';
import { WithRouterProps } from 'react-router/lib/withRouter';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import { IconButton } from '../../../core/components/IconButton';
import errorToMessage from '../../../core/errors/utils/errorToMessage';

import briefingResource from '../../resources/briefingResource';
import useBriefingDeletionConfirmationModal from '../../hooks/useBriefingDeletionConfirmationModal';
import useBriefingPermissions from '../../hooks/useBriefingPermissions';
import { BRIEFINGS_ROUTES } from '../../configuration/routes';
import { getDuplicateBriefingTitle } from '../../utils';

const tableStrings = componentStrings.briefings.table;
const { actions } = tableStrings;
const coreStrings = componentStrings.briefings.core;

type BriefingActionsMenuProps = WithRouterProps & {
    briefing: BriefingSimpleView;
    className?: string;
};

const BriefingActionsMenu = ({ briefing, router }: BriefingActionsMenuProps) => {
    const toast = useToast();

    const { canCreate, canDelete, canEdit } = useBriefingPermissions(briefing);
    const { openDeletionModal, deletionModalProps } = useBriefingDeletionConfirmationModal(
        briefing.id,
        router,
        false
    );

    const handleEdit = () => {
        router.push(BRIEFINGS_ROUTES.item.url(briefing.id, 'edit'));
    };

    const handleDuplicate = async () => {
        try {
            const duplicatePayload: BriefingDuplicateRequest = {
                title: getDuplicateBriefingTitle(briefing.title),
            };
            const { id } = await briefingResource.duplicateBriefing(briefing.id, duplicatePayload);
            router.push(BRIEFINGS_ROUTES.item.url(id));
        } catch (e) {
            toast({
                status: 'error',
                description: errorToMessage(e, coreStrings.duplicateFailToast),
            });
        }
    };

    const menuItems = [
        {
            label: actions.duplicate,
            enabled: canCreate && briefing.state !== BriefingStateEnum.DRAFT.name,
            testId: testIds.BRIEFING_DUPLICATE_OPTION,
            handler: handleDuplicate,
        },
        {
            label: actions.edit,
            enabled: canEdit,
            testId: testIds.BRIEFING_EDIT_OPTION,
            handler: handleEdit,
        },
        {
            label: actions.delete,
            enabled: canDelete,
            testId: testIds.BRIEFING_DELETE_OPTION,
            handler: openDeletionModal,
        },
    ];
    const enabledMenuItems = menuItems.filter(({ enabled }) => enabled);
    if (!enabledMenuItems.length) {
        return null;
    }

    return (
        <>
            <Menu>
                <MenuTrigger asChild>
                    <IconButton
                        aria-label={actions.moreActions}
                        icon="MoreActions"
                        variant="ghost"
                        testId={testIds.BRIEFING_OPTIONS_MENU_BUTTON}
                    />
                </MenuTrigger>
                <MenuContent data-test-id={testIds.BRIEFING_OPTIONS_MENU} align="end">
                    {enabledMenuItems.map(({ label, handler, testId }) => (
                        <MenuItem key={label} data-test-id={testId} onClick={handler}>
                            {label}
                        </MenuItem>
                    ))}
                </MenuContent>
            </Menu>
            {canDelete && <ConfirmModal {...deletionModalProps} />}
        </>
    );
};

export default withRouter(BriefingActionsMenu);

import { DispositionEventTypeEnum, DispositionStatusEnum } from '@mark43/evidence-api';
import { chain } from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { dispositionReviewerSelector } from '~/client-common/core/domain/evidence-department-config/state/ui/';
import { FormattedDate } from '~/client-common/core/dates/components';
import { timeAgo } from '~/client-common/core/dates/utils/dateHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { custodialReportBundleSelector } from '../../state/ui';

const { PRIMARY_RELEASE_APPROVED, PRIMARY_DISPOSITION_APPROVED } = DispositionEventTypeEnum;
const { PENDING_PRIMARY_REVIEW } = DispositionStatusEnum;

const strings =
    componentStrings.reports.custodialPropertySummary.disposition.DispositionEventDetails;

const _DispositionEventDetails = ({
    className,
    eventDateUtc,
    dispositionReviewer,
    isPrimaryReview,
    isPendingReview,
    custodialReportBundle,
}) => {
    return (
        <div className={className}>
            <FormattedDate date={eventDateUtc} format={FormattedDate.FORMATS.SUMMARY_DATE}>
                {(date) =>
                    isPrimaryReview
                        ? strings.requestMade(timeAgo(eventDateUtc), date)
                        : strings.initialApprovalGranted(timeAgo(eventDateUtc), date)
                }
            </FormattedDate>
            {isPendingReview ? (
                <>
                    <br />{' '}
                    {dispositionReviewer(
                        isPrimaryReview,
                        custodialReportBundle.assignedSupervisorUserIds,
                        custodialReportBundle.assignedInvestigatorUserId,
                        true
                    )}
                </>
            ) : null}
        </div>
    );
};

const DispositionEventDetails = styled(_DispositionEventDetails)`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-style: italic;
`;

const mapStateToProps = createStructuredSelector({
    dispositionReviewer: dispositionReviewerSelector,
    custodialReportBundle: custodialReportBundleSelector,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['dispositionEvents', 'dispositionStatus'],
        ({ dispositionEvents, dispositionStatus }) => {
            if (dispositionStatus === PENDING_PRIMARY_REVIEW.name) {
                return chain(dispositionEvents).head().pick('eventDateUtc').value();
            }

            // find the latest disposition event that was a primary approval
            // since it needs to be displayed in some cases
            return chain(dispositionEvents)
                .find(({ dispositionEventType }) => {
                    return (
                        dispositionEventType === PRIMARY_RELEASE_APPROVED.name ||
                        dispositionEventType === PRIMARY_DISPOSITION_APPROVED.name
                    );
                })
                .pick('eventDateUtc')
                .value();
        }
    )
)(DispositionEventDetails);

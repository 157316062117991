import { DragonRmsIntegrationsResource } from '@mark43/rms-api';
import { createResource } from '../../../../../lib/resources/Resource';
import { req } from '../../../../../lib/ajax';

export default createResource({
    name: 'Dragon RMS Integration Resource',
    methods: {
        getDisplayOptionsForProtectedConfiguredEntityKeyNames(
            data: DragonRmsIntegrationsResource.GetDisplayOptionsForProtectedConfiguredEntityKeyNames['data']
        ) {
            return req<DragonRmsIntegrationsResource.GetDisplayOptionsForProtectedConfiguredEntityKeyNames>(
                {
                    method: 'POST',
                    url: 'dragon_integration/configurations/protected/instances/search/get',
                    data,
                }
            );
        },

        getReportSearchableConfiguredPropertyOptionViewsForReportDefinitionRequest(
            data: DragonRmsIntegrationsResource.GetReportSearchableConfiguredPropertyOptionViewsForReportDefinitionRequest['data']
        ) {
            return req<DragonRmsIntegrationsResource.GetReportSearchableConfiguredPropertyOptionViewsForReportDefinitionRequest>(
                {
                    method: 'POST',
                    url: 'dragon_integration/configurations/properties/search/reports',
                    data,
                }
            );
        },

        getSavedSearchContext(data: DragonRmsIntegrationsResource.GetSavedSearchContext['data']) {
            return req<DragonRmsIntegrationsResource.GetSavedSearchContext>({
                method: 'POST',
                url: 'dragon_integration/configurations/instances/saved_search/get',
                data,
            });
        },
    },
});

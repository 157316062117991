import * as fields from '~/client-common/core/enums/universal/fields';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'printableHash',
        fieldName: fields.EVIDENCE_PRINTING_TEMPLATE_EVIDENCE_DOCUMENT_TYPE,
    },
    'csvExportColumnIds',
]);

const evidenceDashboardExportForm = createFormModule({
    formName: formClientEnum.EVIDENCE_DASHBOARD_EXPORT,
    fieldViewModels,
});

/**
 * Module of the search form on the evidence dashboard export. It supports both CSV and PDF
 *   export.
 * @type {Object}
 */
export default evidenceDashboardExportForm;

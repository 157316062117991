import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
    lifecycleOptions,
    Form,
    formEvents,
    createFormConfiguration,
    createField,
} from 'markformythree';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import getWarrantsResource from '~/client-common/core/domain/warrants/resources/warrantsResource';

import clipsResource from '../../../../legacy-redux/resources/clipsResource';
import Modal from '../../../core/overlays/components/Modal';
import { MFTText } from '../../../core/forms/components/Text';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../core/validation/yupMftValidation';
import { openBox, closeBox } from '../../../../legacy-redux/actions/boxActions';
import { openConfirmationBar } from '../../../core/confirmation-bar/state/ui';
import { currentWarrantSelector } from '../state/ui';
import testIds from '../../../../core/testIds';
import { logWarning } from '../../../../core/logging';
import { getMachineNameFromMetadata } from '../../../dex/utils';
import WarrantClipsSendingModal from './WarrantClipsSendingModal';

const strings = componentStrings.warrants.warrant.WarrantSendToClipsModal;
const validationStrings =
    componentStrings.warrants.warrant.WarrantSendToClipsModal.validationStrings;

const ModalText = styled.p`
    font-weight: 600;
    font-size: var(--arc-fontSizes-md);
`;

const FieldWrapper = styled.div`
    display: flex;
`;

const RenSuffixText = styled.span`
    margin-top: 26px;
    color: ${(props) => props.theme.colors.lightGrey};
`;

const formConfiguration = createFormConfiguration({
    entryName: createField<string>({}),
});

const validationSchema = yup.object().shape({
    entryName: yup.string().required(validationStrings.entryNameRequired),
});

function openSendingModal() {
    return function (dispatch: (action: unknown) => void) {
        return dispatch(
            openBox({
                name: boxEnum.WARRANT_CLIPS_SENDING_MODAL,
            })
        );
    };
}

function closeSendingModal() {
    return function (dispatch: (action: unknown) => void) {
        return dispatch(
            closeBox({
                name: boxEnum.WARRANT_CLIPS_SENDING_MODAL,
            })
        );
    };
}

const WarrantSendToClipsModal: React.FC = () => {
    const currWarrant = useSelector(currentWarrantSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const port = applicationSettings.CAD_DESKTOP_INTEGRATION_PORT;
    const ren = currWarrant?.reportingEventNumber || '';
    const lastFourOfRen = ren.slice(-4);
    const fieldDisplayName = useFields(fields.REPORT_REPORTING_EVENT_NUMBER);
    const renSuffix = `${fieldDisplayName.REPORT_REPORTING_EVENT_NUMBER} ${lastFourOfRen}`;
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const warrantType = formatAttributeById(currWarrant.warrantTypeAttrId);
    const title = strings.title(currWarrant.warrantNumber, warrantType);
    const { getForm } = useFormGetter();
    const dispatch = useDispatch();
    const onValidate = useCallback(({ formState, eventType }) => {
        const $form = formState.ui.$form;
        return validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
            .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
    }, []);

    const onSuccess = useCallback(
        () =>
            dispatch(
                openConfirmationBar({
                    message: strings.sendSuccessMessage,
                    isSuccess: true,
                    width: 536,
                })
            ),
        [dispatch]
    );

    const { loading, callResource } = useResourceDeferred(
        clipsResource.submitClipsWarrant,
        onSuccess
    );

    const onError = useCallback(
        () =>
            dispatch(
                openConfirmationBar({
                    message: loading.errorMessage || strings.sendErrorMessage,
                    isError: true,
                    width: 536,
                })
            ),
        [dispatch, loading.errorMessage]
    );

    useEffect(() => {
        // seems we can't access the error state from the callResource()
        // promise result so using this as a workaround
        if (loading.errorMessage) {
            onError();
        }
    }, [loading.errorMessage, onError]);

    const onSendHandler = useCallback(() => {
        const form = getForm(formClientEnum.CLIPS_WARRANT_FORM_NAME);

        return form?.submit().then(() => {
            const model = form?.get();
            dispatch(openSendingModal());

            return (
                getWarrantsResource()
                    .getDesktopMetadata(port)
                    // we may not have a machineName if we're in mock mode
                    // let the submitClipsReport call below handle that
                    .catch((error: Error) => {
                        logWarning('getDesktopMetadata failed', { error, port });
                    })
                    .then((data: undefined | { machineName: undefined | string }) => {
                        try {
                            const deviceName = getMachineNameFromMetadata({ data, port });
                            return callResource({
                                deviceName,
                                entryName: `${model?.entryName} ${lastFourOfRen}`,
                                fullWarrantId: currWarrant.id,
                            });
                        } catch {
                            return callResource({
                                // error is logged inside getMachineNameFromMetadata
                                // make call without a deviceName in case of mock mode
                                entryName: `${model?.entryName} ${lastFourOfRen}`,
                                fullWarrantId: currWarrant.id,
                            });
                        }
                    })
                    .finally(() => dispatch(closeSendingModal()))
            );
        });
    }, [getForm, dispatch, port, callResource, lastFourOfRen, currWarrant.id]);

    return (
        <>
            <WarrantClipsSendingModal />
            <Modal
                id={overlayIdEnum.WARRANT_SEND_TO_CLIPS_MODAL}
                title={title}
                shouldCloseOnOverlayClick={true}
                okText={strings.sendButtonText}
                onSave={onSendHandler}
            >
                <ModalText>{strings.message}</ModalText>

                <Form
                    lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                    name={formClientEnum.CLIPS_WARRANT_FORM_NAME}
                    validationEvents={[
                        { eventType: formEvents.FORM_SUBMIT },
                        { eventType: formEvents.INPUT_BLUR },
                        { eventType: formEvents.INPUT_CHANGE },
                    ]}
                    onValidate={onValidate}
                    configuration={formConfiguration}
                    initialState={{ entryName: strings.warrantType(warrantType) }}
                    render={() => {
                        return (
                            <FieldWrapper>
                                <MFTText label={strings.entryName} path="entryName" />
                                <RenSuffixText data-test-id={testIds.CLIPS_REN_SUFFIX}>
                                    {renSuffix}
                                </RenSuffixText>
                            </FieldWrapper>
                        );
                    }}
                />
            </Modal>
        </>
    );
};

export default WarrantSendToClipsModal;

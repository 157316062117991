import React from 'react';
import styled from 'styled-components';
import { isFunction } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../core/testIds';
import _Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import Icon, { iconTypes, iconSizes } from './Icon';

const strings = componentStrings.core.ActionIcons;

const Button = styled(_Button)`
    padding-right: 6px;
    padding-left: 6px;
`;

const ButtonRightDivider = styled(Button)`
    border-right: solid ${(props) => props.theme.colors.lightGrey}
        ${(props) => (props.hideDividers ? '0px' : '1px')};
`;

// TODO: fix css - tweaking top positioning
// is not ideal for vertically centering text
const IconLabel = styled.span`
    position: relative;
    margin-left: 4px;
    top: -3px;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

/**
 * Generic Action Icons to use across the app
 * @param {function} [props.onEdit]
 * @param {function} [props.onDuplicate]
 * @param {function} [props.onRemove]
 * @param {boolean}  [props.hideDuplicate=false]
 * @param {boolean}  [props.hideRemove=false]
 * @param {boolean}  [props.hideIconLabels=false]
 * @param {string}   [props.className]           className for resulting span.
 * @param {string}   [props.hideDividers]
 */
export default function ActionIcons({
    onEdit,
    setEditRef,
    onDuplicate,
    onRemove,
    hideDuplicate = false,
    hideRemove = false,
    hideIconLabels = false,
    className,
    hideDividers = false,
    iconSize = iconSizes.MEDIUM,
    render,
}) {
    const editButton = (
        <ButtonRightDivider
            className={buttonTypes.ICON_LINK}
            onClick={onEdit}
            ref={setEditRef}
            testId={testIds.EDIT_BUTTON}
            hideDividers={hideDividers}
        >
            <Icon type={iconTypes.EDIT} color="cobaltBlue" size={iconSize} />
            {!hideIconLabels && <IconLabel>{strings.edit}</IconLabel>}
        </ButtonRightDivider>
    );

    const duplicateButton = (
        <ButtonRightDivider
            className={buttonTypes.ICON_LINK}
            onClick={onDuplicate}
            testId={testIds.DUPLICATE_BUTTON}
            hideDividers={hideDividers}
        >
            <Icon type={iconTypes.DUPLICATE} color="cobaltBlue" size={iconSize} />
            {!hideIconLabels && <IconLabel>{strings.duplicate}</IconLabel>}
        </ButtonRightDivider>
    );

    const deleteButton = (
        <Button className={buttonTypes.ICON_LINK} onClick={onRemove} testId={testIds.TRASH_BUTTON}>
            <Icon type={iconTypes.TRASH_CAN} color="cobaltBlue" size={iconSize} />
            {!hideIconLabels && <IconLabel>{strings.remove}</IconLabel>}
        </Button>
    );

    if (isFunction(render)) {
        return (
            <span className={className}>
                {render({ editButton, duplicateButton, deleteButton })}
            </span>
        );
    }

    return (
        <span className={className}>
            {editButton}
            {!hideDuplicate && duplicateButton}
            {!hideRemove && deleteButton}
        </span>
    );
}

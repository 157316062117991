// TODO cache stacks for non-default usages (use hash of arguments as key in
// a kv store)
import Promise from 'bluebird';
import { some, isFunction as isFn, wrap, reduce } from 'lodash';
import logger from '../log';

const buildLogBefore = (resourceName, methodName, logLevel) => {
    return (...args) => {
        logger(logLevel, '%s#%s called with arguments: %j', resourceName, methodName, args);
        return args;
    };
};

const buildLogAfter = (resourceName, methodName, logLevel) => {
    return (data) => {
        logger(logLevel, '%s#%s completed with data %j', resourceName, methodName, data);
        return data;
    };
};

const buildRetryClause = (predicates) => {
    return (ctx, err) => {
        // If there are no retryAttemptsRemaining just pass the error along
        if (!('retryAttemptsRemaining' in ctx) || ctx.retryAttemptsRemaining < 1) {
            throw err;
        }
        return some(predicates, (predicate) => {
            // if the predicate is a function and not an error, run the error
            // through the predicate and return the result
            if (!(err instanceof Error) && isFn(predicate)) {
                return predicate(err);
            }
            // otherwise just determine if the predicate is an instance of
            // an error type that we're looking for
            return err instanceof predicate;
        });
    };
};

const assemblePromise = (stack, args, retryAttempts = 0) => {
    const promise = Promise.bind({
        // keep track of the promise stack used
        stack,
        // keep track of the original arguments we were given so that they
        // can be reapplied in the event of a retry
        originalArgs: args,
        // keep a counter of retryAttemptsRemaining such that we can decrease
        // it each time we attempt a retry and ultimately know when to bail
        retryAttemptsRemaining: retryAttempts,
    }).then(() => [...args]);

    return reduce(
        stack,
        // if the sequential process has indicated a non-default promise
        // method, use that, otherwise use 'then'
        (acc, fn) => acc[fn.__promiseMethod__ || 'then'](fn).cancellable(),
        promise
    );
};

export default class ResourceMethod {
    constructor(resourceName, methodName, method, retry, retryOn, retryAttempts, log, logLevel) {
        this.resourceName = resourceName;
        this.methodName = methodName;
        this.method = method;
        this.retry = retry;
        this.retryOn = retryOn;
        this.retryAttempts = retryAttempts;
        this.log = log;
        this.logLevel = logLevel;
        this.method.__promiseMethod__ = 'spread';
        this.defaultStack = this._buildDefaultStack();
    }

    _buildDefaultStack() {
        const stack = [];
        if (this.log) {
            const beforeLogMethod = buildLogBefore(
                this.resourceName,
                this.methodName,
                this.logLevel
            );
            beforeLogMethod.__promiseMethod__ = 'spread';
            stack.push(beforeLogMethod);
        }
        stack.push(this.method);
        if (this.log) {
            stack.push(buildLogAfter(this.resourceName, this.methodName, this.logLevel));
        }
        // Avoid cases where there's no need to add retry logic
        if (this.retry && this.retryOn.length && this.retryAttempts > 0) {
            // deliberately avoid ES6 lambda for this callback - doing so will
            // result in 'this' referring to the ResourceMethod instance which
            // is undesirable because of our reliance on Promise.bind to
            // maintain state of the context
            const func = wrap(buildRetryClause(this.retryOn), function (fn, err) {
                let result;
                // If an error is thrown to us just pass it along. Otherwise
                // decide whether or not to do a 'retry' based on the result
                // of our error passing our predicates
                try {
                    result = fn(this, err);
                } catch (error) {
                    throw err;
                }
                // if the error matched one of our predicates
                if (result) {
                    return assemblePromise(
                        this.stack,
                        this.originalArgs,
                        --this.retryAttemptsRemaining
                    );
                }
            });
            func.__promiseMethod__ = 'catch';
            stack.push(func);
        }
        return stack;
    }

    runDefault(...args) {
        return assemblePromise(this.defaultStack, args, this.retryAttempts);
    }
}

import styled, { css } from 'styled-components';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import SortableTableColumn from '../../../../legacy-redux/components/core/tables/SortableTableColumn';

const tableColumnCss = css`
    .cobalt-table-column-title {
        line-height: normal !important;
        padding: 10px 5px !important;
    }
`;

export const ResultsSortableTableColumn = styled(SortableTableColumn)`
    ${tableColumnCss};
    color: ${(props) => props.theme.colors.cobaltBlue};
`;

export default styled(TableColumn)`
    ${tableColumnCss};
`;

import React from 'react';

import Pagination from '../../../../legacy-redux/components/core/Pagination';

/**
 * Sticky footer under cases dashboard results. Contains pagination.
 */
export default function CasesResultsFooter({
    totalResults,
    searchSize,
    currentPage,
    handlePaginationClick,
}) {
    return (
        <div className="cases-results-footer">
            <Pagination
                currentPage={currentPage}
                itemsPerPage={searchSize}
                itemCount={totalResults}
                onClick={handlePaginationClick}
                maxEdgeItems={1}
            />
        </div>
    );
}

import { UseOfForce } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'useOfForces';
const useOfForcesModule = createNormalizedModule<UseOfForce>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

export const useOfForcesWhereSelector = useOfForcesModule.selectors.entitiesWhereSelector;

// REDUCER
export default useOfForcesModule.reducerConfig;

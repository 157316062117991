import getReportShortTitlesResource from '~/client-common/core/domain/report-short-titles/resources/reportShortTitlesResource';
import { storeReportShortTitles } from '~/client-common/core/domain/report-short-titles/state/data';

export function loadWarrantRelatedEntities(reportIds) {
    return (dispatch) => {
        const resource = getReportShortTitlesResource();
        return resource
            .getReportShortTitles(reportIds)
            .then((reportShortTitles) => dispatch(storeReportShortTitles(reportShortTitles)))
            .catch((err) => {
                throw err;
            });
    };
}

import { get, isEmpty, size } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Observer, lifecycleOptions, formEvents } from 'markformythree';
import Promise from 'bluebird';

import { AttributeTypeEnum, RegionalGroupEnum } from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { isUndefinedOrNull, isValueDisplayable } from '~/client-common/helpers/logicHelpers';
import {
    formatWarrantShortTitle,
    formatObtainingOfficerUser,
} from '~/client-common/helpers/warrantHelpers';
import { formatNibrsCode } from '~/client-common/helpers/offenseCodesHelpers';
import _componentStrings from '~/client-common/core/strings/componentStrings';
import { FormattedDate } from '~/client-common/core/dates/components';
import { CHARGE_TYPE_OFFENSE_CODE_FLAGS } from '~/client-common/core/domain/offense-codes/constants';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import {
    formatAttributeByIdSelector,
    formatAttributeWithOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { offenseByIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { warrantByIdSelector } from '~/client-common/core/domain/warrants/state/data';
import { isWarrantStatusOfWarrantStatusGlobalIdSelector } from '~/client-common/core/domain/warrant-statuses/state/ui';
import { detentionForReportIdAndChargeIdSelector } from '~/client-common/core/domain/charges/state/ui';
import { reportRenByIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { formatOffenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/ui';
import useFields, { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_WARRANT_LABEL,
    WARRANT_CHARGE_CHARGE_NAME,
} from '~/client-common/core/enums/universal/fields';

import formsRegistry from '../../../../../../core/formsRegistry';
import { saveNChargesSidePanel } from '../../../state/ui/arrest-charges-side-panel/nCharges';
import {
    formName,
    convertFromFormModel,
    convertToFormModel,
    getFormConfiguration,
    newChargeFormModel,
    resequenceOffenseOrders,
    swapOffenseOrders,
    moveChargeFormModelToTopCharge,
    setOffenseOnCharge,
    setLegacyOffenseOnCharge,
    setWarrantOnCharge,
    findIndexForChargeOffenseOrder,
} from '../../../state/forms/arrest-charges-side-panel/nChargesForm';
import {
    currentUserDepartmentProfileSelector,
    currentUserDepartmentSupportsNibrsSelector,
} from '../../../../../core/current-user/state/ui';
import useCanCreateStubOffense from '../../../hooks/useCanCreateStubOffense';

import testIds from '../../../../../../core/testIds';
import ArbiterForm from '../../../../../core/markformythree-arbiter/ArbiterForm';
import AddEntityLink from '../../../../../core/components/AddEntityLink';
import Button, { buttonTypes } from '../../../../../../legacy-redux/components/core/Button';
import SummaryList from '../../../../../../legacy-redux/components/summaries/SummaryList';
import Icon, { iconSizes, iconTypes } from '../../../../../core/components/Icon';
import Row from '../../../../../core/components/Row';
import SummaryRow from '../../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { SubsectionTitleText } from '../../../../../core/components/typography';
import { MFTNItems, RemoveButton } from '../../../../../core/forms/components/NItems';
import { ArbiterMFTOffenseCodeSelect } from '../../../../../core/forms/components/selects/OffenseCodeSelect';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { renderAddButton } from '../../../../../core/names/components/nameFormNItemsRenderers';
import { DashedDivider } from '../../../../../../legacy-redux/components/core/Divider';
import { SimpleLoading } from '../../../../../../legacy-redux/components/core/Loading';
import { VisibilityObserver } from '../../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { Tooltip } from '../../../../../core/components/tooltip';
import FocusWrapper from '../../../../../core/components/FocusWrapper';
import { ArbiterMFTAttributeSelect } from '../../../../../core/forms/components/selects/AttributeSelect';
import OffenseQuickAddRadioButtons from './components/OffenseQuickAddRadioButtons';
import WarrantQuickAddRadioButtons from './components/WarrantQuickAddRadioButtons';

const componentStrings = _componentStrings.reports.core.ArrestChargesSidePanel.NChargesScreen;
const SUMMARY_TEXT_LEFT_PADDING = 10;

const ChargeOrderingContainer = styled.div`
    float: right;
    width: 55%;
`;

const ChargeOrderingButton = styled(Button)`
    border: 0;
    background: transparent;
`;

const RightRemoveButton = styled(RemoveButton)`
    float: right;
`;

const RightDiv = styled.div`
    float: right;
`;

const RightButton = styled(Button)`
    margin-left: 10px;
`;

const ChargeOffenseCodeSelect = styled(ArbiterMFTOffenseCodeSelect)`
    margin-right: 12px;
    width: 320px;
`;

const ChargeSummarySubsectionTitleText = styled(SubsectionTitleText)`
    padding-left: ${SUMMARY_TEXT_LEFT_PADDING}px;
`;

const ChargeSummaryList = styled(SummaryList)`
    padding-left: ${iconSizes.MEDIUM + SUMMARY_TEXT_LEFT_PADDING}px;
`;

const CloseShowingWarrantLinkButtons = styled(RightButton)`
    margin-top: 15px;
`;

const PaddedBottomDiv = styled.div`
    padding-bottom: 10px;
`;

export const unregisterNChargesScreenForm = () => formsRegistry.unregister(formName);

export const onSaveSidePanel =
    ({ arrestId, formIndex }) =>
    (dispatch) => {
        const form = formsRegistry.get(formName);
        const { formErrors, success } = form.validate({ eventType: formEvents.FORM_SUBMIT });

        if (isEmpty(formErrors) && success) {
            const charges = dispatch(convertFromFormModel({ form, arrestId }));
            return dispatch(saveNChargesSidePanel({ form, formIndex, arrestId, charges })).then(
                () => unregisterNChargesScreenForm()
            );
        }
        return Promise.reject(formErrors);
    };

export const onCancelSidePanel = unregisterNChargesScreenForm;

const ChargeOffense = compose(
    connect(
        createStructuredSelector({
            offenseById: offenseByIdSelector,
            formatOffenseCodeById: formatOffenseCodeByIdSelector,
            formatAttributeById: formatAttributeByIdSelector,
            reportRenById: reportRenByIdSelector,
            isNibrsDepartment: currentUserDepartmentSupportsNibrsSelector,
        }),
        { setOffenseOnCharge }
    ),
    withFields([fields.REPORT_REPORTING_EVENT_NUMBER, fields.CHARGE_OFFENSE_ID])
)(function _ChargeOffense({
    offenseById,
    formatOffenseCodeById,
    formatAttributeById,
    reportRenById,
    isNibrsDepartment,
    setOffenseOnCharge,
    fieldDisplayNames,
    nChargesFormModelCharges,
    arrestId,
    offenseId,
    legacyCharge,
    legacyEventNumber,
    legacyChargeSourceAttrId,
    offenseOrder,
    canCreateStubOffense,
    onAddStubOffense,
    onEditLegacyOffense,
    onAddLegacyOffense,
    onPriorOffenseSearch,
}) {
    const renDisplayLabel = fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER;
    const offenseDisplayName = useOffenseFieldName();
    const offense = offenseById(offenseId);
    const isLinkedToOffense = !isUndefinedOrNull(offense);
    const isLinkedToLegacyOffense =
        !isLinkedToOffense &&
        !(
            isUndefinedOrNull(legacyCharge) ||
            isUndefinedOrNull(legacyEventNumber) ||
            isUndefinedOrNull(legacyChargeSourceAttrId)
        );
    const [isShowingLinkButtons, setIsShowingLinkButtons] = useState(false);

    const offenseSummary = ({ offenseOrder, offense }) => {
        const { offenseCodeId, reportId, offenseDateUtc, offenseEndDateUtc } = offense;
        const nibrsCode = offense.nibrsCode ?? offense.offenseCode?.nibrsCode;

        const formattedOffenseCode = formatOffenseCodeById({
            id: offenseCodeId,
            includeCode: true,
        });
        const reportRen = reportRenById(reportId);
        const formattedNibrsCode = formatNibrsCode(nibrsCode);

        const isNibrsVisible = isNibrsDepartment && formattedNibrsCode;

        return (
            <PaddedBottomDiv>
                <Row data-test-id={testIds.CHARGES_SIDE_PANEL_OFFENSE_SUMMARY}>
                    <Icon type={iconTypes.CHARGE} size={iconSizes.MEDIUM} color="darkGrey" />
                    <ChargeSummarySubsectionTitleText>
                        {formattedOffenseCode}
                    </ChargeSummarySubsectionTitleText>
                    <RightDiv>
                        <RightButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.TRASH_CAN} size={iconSizes.MEDIUM} />}
                            onClick={() =>
                                setOffenseOnCharge({
                                    chargeOffenseOrder: offenseOrder,
                                    offenseId: undefined,
                                })
                            }
                        />
                    </RightDiv>
                </Row>
                <Row>
                    <ChargeSummaryList labelWidth={150} contentWidth={150}>
                        {isValueDisplayable(reportRen) && (
                            <SummaryRow
                                label={componentStrings.offenseRenTitle(
                                    offenseDisplayName,
                                    renDisplayLabel
                                )}
                            >
                                {reportRen}
                            </SummaryRow>
                        )}
                        <SummaryRowDate
                            fieldName={fields.OFFENSE_OFFENSE_DATE_UTC}
                            date={offenseDateUtc}
                        />
                        <SummaryRowDate
                            fieldName={fields.OFFENSE_OFFENSE_END_DATE_UTC}
                            date={offenseEndDateUtc}
                        />
                        {isNibrsVisible && (
                            <SummaryRow fieldName={fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_ID}>
                                {formattedNibrsCode}
                            </SummaryRow>
                        )}
                    </ChargeSummaryList>
                </Row>
            </PaddedBottomDiv>
        );
    };

    const legacyOffenseSummary = ({
        offenseOrder,
        legacyCharge,
        legacyEventNumber,
        legacyChargeSourceAttrId,
    }) => {
        const formattedLegacyChargeSource = formatAttributeById(legacyChargeSourceAttrId);
        return (
            <PaddedBottomDiv>
                <Row data-test-id={testIds.CHARGES_SIDE_PANEL_LEGACY_CHARGE_OFFENSE_SUMMARY}>
                    <Icon type={iconTypes.CHARGE} size={iconSizes.MEDIUM} color="darkGrey" />
                    <ChargeSummarySubsectionTitleText>
                        {legacyCharge}
                    </ChargeSummarySubsectionTitleText>
                    <RightDiv>
                        <RightButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.EDIT} size={iconSizes.MEDIUM} />}
                            onClick={() => onEditLegacyOffense({ offenseOrder })}
                        />
                        <RightButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.TRASH_CAN} size={iconSizes.MEDIUM} />}
                            onClick={() =>
                                setLegacyOffenseOnCharge({
                                    chargeOffenseOrder: offenseOrder,
                                    legacyCharge: undefined,
                                    legacyEventNumber: undefined,
                                    legacyChargeSourceAttrId: undefined,
                                })
                            }
                        />
                    </RightDiv>
                </Row>
                <Row>
                    <ChargeSummaryList labelWidth={150} contentWidth={150}>
                        <SummaryRow fieldName={fields.CHARGE_LEGACY_EVENT_NUMBER}>
                            {legacyEventNumber}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.CHARGE_LEGACY_CHARGE_SOURCE_ATTR_ID}>
                            {formattedLegacyChargeSource}
                        </SummaryRow>
                    </ChargeSummaryList>
                </Row>
            </PaddedBottomDiv>
        );
    };

    const chargeIndex = findIndexForChargeOffenseOrder({ chargeOffenseOrder: offenseOrder });
    const chargeButtonPath = `charges[${chargeIndex}].newOffenseButton`;
    return (
        <>
            {isLinkedToOffense && offenseSummary({ offenseOrder, offense })}
            {isLinkedToLegacyOffense &&
                legacyOffenseSummary({
                    offenseOrder,
                    legacyCharge,
                    legacyEventNumber,
                    legacyChargeSourceAttrId,
                })}
            {!isLinkedToOffense && !isLinkedToLegacyOffense && (
                <Row>
                    <OffenseQuickAddRadioButtons
                        arrestId={arrestId}
                        nChargesFormModelCharges={nChargesFormModelCharges}
                        renDisplayLabel={renDisplayLabel}
                        offenseOrder={offenseOrder}
                    />
                </Row>
            )}
            {!isLinkedToOffense && !isLinkedToLegacyOffense && isShowingLinkButtons && (
                <>
                    <Button
                        className={buttonTypes.SECONDARY}
                        testId={testIds.CHARGES_SIDE_PANEL_ADD_PRIOR_OFFENSE}
                        onClick={() => onPriorOffenseSearch({ offenseOrder })}
                    >
                        {componentStrings.priorOffense(offenseDisplayName)}
                    </Button>
                    <VisibilityObserver
                        path={chargeButtonPath}
                        formName={formName}
                        render={({ hidden }) => {
                            if (hidden) {
                                return null;
                            }
                            const button = (
                                <Button
                                    className={buttonTypes.SECONDARY}
                                    testId={testIds.CHARGES_SIDE_PANEL_ADD_NEW_OFFENSE}
                                    onClick={() => onAddStubOffense({ offenseOrder })}
                                    disabled={!canCreateStubOffense}
                                >
                                    {componentStrings.newOffense(offenseDisplayName)}
                                </Button>
                            );
                            if (canCreateStubOffense) {
                                return button;
                            } else {
                                return (
                                    <Tooltip
                                        side="top"
                                        hasButtonOffset
                                        content={componentStrings.newOffenseDisabledTooltip(
                                            offenseDisplayName.toLowerCase()
                                        )}
                                        collisionBoundary={document.querySelector(
                                            '.mark43-react-side-panel'
                                        )}
                                    >
                                        <div>{button}</div>
                                    </Tooltip>
                                );
                            }
                        }}
                    />
                    <Button
                        className={buttonTypes.SECONDARY}
                        testId={testIds.CHARGES_SIDE_PANEL_ADD_LEGACY_OFFENSE}
                        onClick={() => onAddLegacyOffense({ offenseOrder })}
                    >
                        {componentStrings.legacyOffense(offenseDisplayName)}
                    </Button>
                    <RightDiv>
                        <RightButton
                            className={buttonTypes.ICON_LINK}
                            onClick={() => setIsShowingLinkButtons(false)}
                            iconLeft={iconTypes.CLOSE_X}
                        >
                            {componentStrings.cancel}
                        </RightButton>
                    </RightDiv>
                </>
            )}
            {!isLinkedToOffense && !isLinkedToLegacyOffense && !isShowingLinkButtons && (
                <>
                    <AddEntityLink
                        className={buttonTypes.ICON_LINK}
                        onClick={() => setIsShowingLinkButtons(true)}
                        testId={testIds.CHARGES_SIDE_PANEL_ADD_OFFENSE}
                    >
                        {fieldDisplayNames.CHARGE_OFFENSE_ID}
                    </AddEntityLink>
                </>
            )}
        </>
    );
});

const ChargeWarrant = connect(
    createStructuredSelector({
        formatAttributeById: formatAttributeByIdSelector,
        formatAttributeWithOther: formatAttributeWithOtherSelector,
        formatMiniUserById: formatMiniUserByIdSelector,
        warrantById: warrantByIdSelector,
        isWarrantStatusOfWarrantStatusGlobalId: isWarrantStatusOfWarrantStatusGlobalIdSelector,
        offenseCodes: offenseCodesSelector,
        departmentProfile: currentUserDepartmentProfileSelector,
    })
)(function _ChargeWarrant({
    formatAttributeById,
    formatAttributeWithOther,
    formatMiniUserById,
    warrantById,
    isWarrantStatusOfWarrantStatusGlobalId,
    offenseCodes,
    departmentProfile,
    nChargesFormModelCharges,
    arrestId,
    warrantId,
    offenseCodeId,
    offenseOrder,
    onAddStubWarrant,
    onEditStubWarrant,
    onFindWarrantSearch,
}) {
    const warrant = warrantById(warrantId);
    const isLinkedToWarrant = !isUndefinedOrNull(warrant);
    const [isShowingLinkButtons, setIsShowingLinkButtons] = useState(false);
    const isOtherJurisdictionPrefill =
        departmentProfile.nibrsRegionalGroup === RegionalGroupEnum.LOUISIANA_NIBRS.name
            ? get(offenseCodes, [offenseCodeId, 'isWarrantChargeExternal'])
            : undefined;
    const warrantLabel = useFields(DISPLAY_ONLY_WARRANT_LABEL)[DISPLAY_ONLY_WARRANT_LABEL];

    const warrantSummary = (warrantForSummaryDisplay) => {
        const {
            id,
            warrantNumber,
            warrantTypeAttrId,
            reportingEventNumber,
            description,
            obtainingOfficerUserId,
            obtainingOfficerOther,
            warrantIssuedDateUtc,
            issuingJudge,
            bailAmount,
            issuingAgencyOri,
            issuingAgencyName,
            issuingAgencyNameAttrId,
            issuingAgencyNameOther,
        } = warrantForSummaryDisplay;
        const isStubWarrant = isWarrantStatusOfWarrantStatusGlobalId({
            warrantId: id,
            warrantStatusGlobalId: globalAttributes.warrantStatusGlobal.stub,
        });
        const warrantType = formatAttributeById(warrantTypeAttrId);
        const formattedObtainingOfficer = formatMiniUserById(obtainingOfficerUserId);
        const title = formatWarrantShortTitle({ warrantNumber, warrantType });
        const obtainingOfficerDisplay = formatObtainingOfficerUser({
            formattedObtainingOfficer,
            obtainingOfficerOther,
        });
        const formattedIssuingAgencyAttrId = formatAttributeWithOther({
            attributeId: issuingAgencyNameAttrId,
            other: issuingAgencyNameOther,
        });

        return (
            <>
                <Row data-test-id={testIds.CHARGES_SIDE_PANEL_WARRANT_SUMMARY}>
                    <Icon type={iconTypes.CHARGE} size={iconSizes.MEDIUM} color="darkGrey" />
                    <ChargeSummarySubsectionTitleText>{title}</ChargeSummarySubsectionTitleText>
                    <RightDiv>
                        {isStubWarrant && (
                            <RightButton
                                className={buttonTypes.ICON_LINK}
                                iconLeft={<Icon type={iconTypes.EDIT} size={iconSizes.MEDIUM} />}
                                onClick={() =>
                                    onEditStubWarrant({ offenseOrder, isOtherJurisdictionPrefill })
                                }
                            />
                        )}
                        <RightButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.TRASH_CAN} size={iconSizes.MEDIUM} />}
                            onClick={() =>
                                setWarrantOnCharge({
                                    chargeOffenseOrder: offenseOrder,
                                    warrantId: undefined,
                                })
                            }
                        />
                    </RightDiv>
                </Row>
                <Row>
                    <ChargeSummaryList labelWidth={150} contentWidth={150}>
                        <SummaryRow fieldName={fields.WARRANT_REPORTING_EVENT_NUMBER}>
                            {reportingEventNumber}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_DESCRIPTION}>
                            {description}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_OBTAINING_OFFICER_USER_ID}>
                            {obtainingOfficerDisplay}
                        </SummaryRow>
                        <SummaryRowDate
                            fieldName={fields.WARRANT_WARRANT_ISSUED_DATE_UTC}
                            date={warrantIssuedDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE}
                        />
                        <SummaryRow fieldName={fields.WARRANT_ISSUING_JUDGE}>
                            {issuingJudge}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_BAIL_AMOUNT}>{bailAmount}</SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_ORI}>
                            {issuingAgencyOri}
                        </SummaryRow>
                        {/*
                            `issuingAgencyName` free text input is used for Stub Warrants, whereas
                            `issuingAgencyNameAttrId` is used for non-stub Warrants.
                         */}
                        <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_NAME}>
                            {issuingAgencyName}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_ISSUING_AGENCY_NAME_ATTR_ID}>
                            {formattedIssuingAgencyAttrId}
                        </SummaryRow>
                    </ChargeSummaryList>
                </Row>
            </>
        );
    };

    return (
        <>
            {isLinkedToWarrant && warrantSummary(warrant)}
            {!isLinkedToWarrant && (
                <Row>
                    <WarrantQuickAddRadioButtons
                        arrestId={arrestId}
                        nChargesFormModelCharges={nChargesFormModelCharges}
                        offenseOrder={offenseOrder}
                    />
                </Row>
            )}
            {!isLinkedToWarrant && isShowingLinkButtons && (
                <>
                    <Button
                        className={buttonTypes.SECONDARY}
                        testId={testIds.CHARGES_SIDE_PANEL_FIND_WARRANT}
                        onClick={() => onFindWarrantSearch({ offenseOrder })}
                    >
                        {componentStrings.findWarrant}
                    </Button>
                    <Button
                        className={buttonTypes.SECONDARY}
                        testId={testIds.CHARGES_SIDE_PANEL_ADD_NEW_WARRANT}
                        onClick={() =>
                            onAddStubWarrant({ offenseOrder, isOtherJurisdictionPrefill })
                        }
                    >
                        {componentStrings.addNewWarrant}
                    </Button>
                    <CloseShowingWarrantLinkButtons
                        className={buttonTypes.ICON_LINK}
                        onClick={() => setIsShowingLinkButtons(false)}
                        iconLeft={iconTypes.CLOSE_X}
                    >
                        {componentStrings.cancel}
                    </CloseShowingWarrantLinkButtons>
                </>
            )}
            {!isLinkedToWarrant && !isShowingLinkButtons && (
                <>
                    <AddEntityLink
                        className={buttonTypes.ICON_LINK}
                        onClick={() => setIsShowingLinkButtons(true)}
                        testId={testIds.CHARGES_SIDE_PANEL_ADD_WARRANT}
                    >
                        {warrantLabel}
                    </AddEntityLink>
                </>
            )}
        </>
    );
});

const NChargesScreen = connect(
    createStructuredSelector({
        detentionForReportIdAndChargeId: detentionForReportIdAndChargeIdSelector,
    }),
    { convertToFormModel }
)(function _NChargesScreen({
    convertToFormModel,
    screenState: { isInitialLoad, initialHydratedCharges },
    reportId,
    arrestId,
    detentionForReportIdAndChargeId,
    onOrderChangeCallback,
    onAddStubOffenseCallback,
    onEditLegacyOffenseCallback,
    onAddLegacyOffenseCallback,
    onPriorOffenseSearchCallback,
    onAddStubWarrantCallback,
    onEditStubWarrantCallback,
    onFindWarrantSearchCallback,
}) {
    const canCreateStubOffense = useCanCreateStubOffense();
    const chargesLabel = useFields(WARRANT_CHARGE_CHARGE_NAME)[WARRANT_CHARGE_CHARGE_NAME];
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            configuration={getFormConfiguration()}
            initialState={convertToFormModel({
                hydratedCharges: initialHydratedCharges,
            })}
            render={(form) => {
                return isInitialLoad ? (
                    <SimpleLoading />
                ) : (
                    <FocusWrapper>
                        <MFTNItems
                            path="charges"
                            addText={chargesLabel}
                            rowTestId={testIds.CHARGES_SIDE_PANEL_CHARGE_WIDGET}
                            useRowSpacing={true}
                            render={({ index, items, removeItem }) => {
                                const chargeFormModel = items[index];
                                const { offenseOrder } = chargeFormModel;
                                const detention = detentionForReportIdAndChargeId(
                                    reportId,
                                    chargeFormModel.id
                                );
                                const canDeleteCharge = !detention;
                                return (
                                    <>
                                        <Row>
                                            <SubsectionTitleText>
                                                {componentStrings.chargeTitle(offenseOrder)}
                                            </SubsectionTitleText>
                                            <ChargeOrderingContainer>
                                                {size(items) > 1 && (
                                                    <>
                                                        <ChargeOrderingButton
                                                            className={buttonTypes.ICON_LINK}
                                                            disabled={offenseOrder === 1}
                                                            iconLeft={
                                                                <Icon
                                                                    type={iconTypes.MOVE_UP}
                                                                    size={iconSizes.LARGE}
                                                                />
                                                            }
                                                            onClick={() => {
                                                                swapOffenseOrders({
                                                                    form,
                                                                    offenseOrder1: offenseOrder,
                                                                    offenseOrder2: offenseOrder - 1,
                                                                });
                                                                onOrderChangeCallback();
                                                            }}
                                                        />
                                                        <ChargeOrderingButton
                                                            className={buttonTypes.ICON_LINK}
                                                            disabled={offenseOrder === size(items)}
                                                            iconLeft={
                                                                <Icon
                                                                    type={iconTypes.MOVE_DOWN}
                                                                    size={iconSizes.LARGE}
                                                                />
                                                            }
                                                            onClick={() => {
                                                                swapOffenseOrders({
                                                                    form,
                                                                    offenseOrder1: offenseOrder,
                                                                    offenseOrder2: offenseOrder + 1,
                                                                });
                                                                onOrderChangeCallback();
                                                            }}
                                                        />
                                                        <ChargeOrderingButton
                                                            className={buttonTypes.ICON_LINK}
                                                            disabled={offenseOrder === 1}
                                                            iconLeft={
                                                                <Icon
                                                                    type={iconTypes.MOVE_TO_TOP}
                                                                    size={iconSizes.LARGE}
                                                                />
                                                            }
                                                            onClick={() => {
                                                                moveChargeFormModelToTopCharge({
                                                                    form,
                                                                    offenseOrder,
                                                                });
                                                                onOrderChangeCallback();
                                                            }}
                                                        />
                                                    </>
                                                )}
                                                {canDeleteCharge && (
                                                    <RightRemoveButton
                                                        onClick={() => {
                                                            removeItem();
                                                            resequenceOffenseOrders({ form });
                                                        }}
                                                        icon={
                                                            <Icon
                                                                size={iconSizes.MEDIUM}
                                                                type={iconTypes.TRASH_CAN}
                                                            />
                                                        }
                                                    />
                                                )}
                                            </ChargeOrderingContainer>
                                        </Row>
                                        <Row>
                                            <ChargeOffenseCodeSelect
                                                path="chargeOffenseCodeId"
                                                flags={CHARGE_TYPE_OFFENSE_CODE_FLAGS}
                                            />
                                            <ArbiterMFTText path="chargeCount" length="sm" />
                                        </Row>
                                        <Row>
                                            <Observer
                                                subscriptions={{
                                                    offenseId: `charges[${index}].offenseId`,
                                                    legacyCharge: `charges[${index}].legacyCharge`,
                                                    legacyEventNumber: `charges[${index}].legacyEventNumber`,
                                                    legacyChargeSourceAttrId: `charges[${index}].legacyChargeSourceAttrId`,
                                                }}
                                                render={({
                                                    offenseId,
                                                    legacyCharge,
                                                    legacyEventNumber,
                                                    legacyChargeSourceAttrId,
                                                }) => {
                                                    return (
                                                        <ChargeOffense
                                                            nChargesFormModelCharges={form.get(
                                                                'charges'
                                                            )}
                                                            arrestId={arrestId}
                                                            offenseId={offenseId}
                                                            legacyCharge={legacyCharge}
                                                            legacyEventNumber={legacyEventNumber}
                                                            legacyChargeSourceAttrId={
                                                                legacyChargeSourceAttrId
                                                            }
                                                            offenseOrder={offenseOrder}
                                                            canCreateStubOffense={
                                                                canCreateStubOffense
                                                            }
                                                            onAddStubOffense={
                                                                onAddStubOffenseCallback
                                                            }
                                                            onEditLegacyOffense={
                                                                onEditLegacyOffenseCallback
                                                            }
                                                            onAddLegacyOffense={
                                                                onAddLegacyOffenseCallback
                                                            }
                                                            onPriorOffenseSearch={
                                                                onPriorOffenseSearchCallback
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Row>
                                        <Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="chargeStatusAttrId"
                                                    length="md"
                                                    attributeType={
                                                        AttributeTypeEnum.CHARGE_STATUS.name
                                                    }
                                                />
                                            </Row>
                                            <Row>
                                                <ArbiterMFTAttributeSelect
                                                    path="duiCauseAttrId"
                                                    length="md"
                                                    attributeType={
                                                        AttributeTypeEnum.SUBSTANCE_ABUSE.name
                                                    }
                                                />
                                            </Row>
                                        </Row>
                                        <Row>
                                            <Observer
                                                subscriptions={{
                                                    warrantId: `charges[${index}].warrantId`,
                                                    offenseCodeId: `charges[${index}].chargeOffenseCodeId`,
                                                }}
                                                render={({ warrantId, offenseCodeId }) => {
                                                    return (
                                                        <ChargeWarrant
                                                            nChargesFormModelCharges={form.get(
                                                                'charges'
                                                            )}
                                                            arrestId={arrestId}
                                                            warrantId={warrantId}
                                                            offenseCodeId={offenseCodeId}
                                                            offenseOrder={offenseOrder}
                                                            onAddStubWarrant={
                                                                onAddStubWarrantCallback
                                                            }
                                                            onEditStubWarrant={
                                                                onEditStubWarrantCallback
                                                            }
                                                            onFindWarrantSearch={
                                                                onFindWarrantSearchCallback
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Row>
                                        <DashedDivider />
                                    </>
                                );
                            }}
                            renderAddButton={({ addItem, addText, items }) => {
                                const formModel = newChargeFormModel({
                                    chargeFormModels: items,
                                });
                                return renderAddButton({
                                    addItem: () => addItem(formModel),
                                    addText,
                                    testId: testIds.CHARGES_SIDE_PANEL_ADD_CHARGE,
                                });
                            }}
                            renderRemoveButton={undefined}
                        />
                    </FocusWrapper>
                );
            }}
        />
    );
});

export default NChargesScreen;

import { find } from 'lodash';
import { ProductModuleEnum } from '@mark43/rms-api';
import React, { useRef } from 'react';
import { InjectedRouter } from 'react-router';
import { Location } from 'history';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
    Caution,
    Menu,
    MenuItem,
    MenuContent,
    MenuTrigger,
    Nav,
    NavBrand,
    NavItems,
    NavItem,
    NavBottomDrawer,
    NavUser,
    useAugmentedArcTheme,
    isDarkMode,
    ThemeProvider,
    NavAvatar,
    useNavCollapse,
    useNavOpen,
    useBreakpointValue,
    mediaQueries,
} from 'arc';
import { getFullName } from '~/client-common/helpers/userHelpers';
import {
    useEvidenceModuleName,
    useCaseFieldName,
} from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { currentThemeNameSelector } from '../../styles/state';
import { Ability, OnlyWithAbility, abilitiesEnum } from '../../abilities';

import { openKeyboardShortcutsSidePanel as _openKeyboardShortcutsSidePanel } from '../../keyboard-shortcuts/state/ui';
import { pathStartsWith } from '../../../../legacy-redux/helpers/urlHelpers';
import testIds from '../../../../core/testIds';

import { logout as _logout } from '../../../../legacy-redux/actions/authActions';
import { useRouteTitle, ROUTE_KEYS } from '../../../../routing/routesConfig';

import {
    departmentStatusIndicatorSelector,
    shouldShowFieldNamesSelector,
} from '../../../../legacy-redux/selectors/globalSelectors';
import { departmentProfileSelector } from '../../../../legacy-redux/selectors/departmentProfileSelectors';
import {
    userProfileUrlSelector,
    userProfileSelector,
} from '../../../../legacy-redux/selectors/userSelectors';
import { navigationPermissionsSelector } from '../../../../legacy-redux/selectors/navigationSelectors';
import { getFederatedSearchNotificationsCount } from '../../../federated-search/state/ui';
import UpdatedAccountSwitcherLink from '../../account-switcher/components/UpdatedAccountSwitcherLink';
import NotificationsNavigationLink from '../../../cobalt/navigation/navigation-links/components/NotificationsNavItem';
import ItemQueueNavItem from '../../../cobalt/navigation/navigation-links/components/ItemQueueNavItem';
import {
    toggleShowFieldNames,
    toggleFieldDisplayNames,
} from '../../../../legacy-redux/actions/globalActions';
import { currentReportSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { reportModulesSelector } from '../../../reports/core/state/data/reportModules';
import { isReportModuleIcon } from '../../../admin/report-modules/config/reportModuleIcons';
import { KeyboardShortcutsSidePanel } from '../../keyboard-shortcuts/components/KeyboardShortcutsSidePanel';
import { BaseLinkWithAnalytics } from '../links/Link';
import { QuickSearchNavItem } from '../../../cobalt/navigation/navigation-links/components/QuickSearchNavItem';
import zIndexes from '../../styles/zIndexes';
import { SUPPORT_URL } from '../../../../core/constants';

const titles = componentStrings.core.navigation;

const StyledNav = styled(Nav)<{ $isDarkMode: boolean }>`
    z-index: ${zIndexes.leftNavigation};

    /** Remove the white box-shadow from the nav in light mode.
    * Add inset in dark mode to create consistent border and separate from header. */
    ${(props) => {
        return props.$isDarkMode === false
            ? `& > * {box-shadow: none;`
            : `& > * {box-shadow: inset -1px 0 0 0 var(--arc-navigation-border);};`;
    }}
`;

const StyledMenuContent = styled(MenuContent)`
    overflow-y: auto;
    max-height: 80vh;
    max-width: calc(100vw - 2rem);

    @media (min-width: ${mediaQueries.md}) {
        max-width: 13rem;
        max-height: 90vh;
    }
`;

const DepartmentStatusIndicator = styled(Caution)`
    justify-content: center;
    display: flex;
    flex: 1;
    margin: 4px;
`;

const ReportNavItem: React.FC<{ pathname: string }> = ({ pathname }) => {
    return (
        <NavItem
            key="reports"
            as={BaseLinkWithAnalytics}
            to="/reports"
            dismissNavOnInteraction
            icon="Report"
            label={titles.reports}
            isActive={pathStartsWith(pathname, ['/reports', '/react-reports'])}
            testId={testIds.MODULE_REPORTS}
        />
    );
};

const ReportModuleNavItems: React.FC<{ pathname: string }> = ({ pathname }) => {
    const reportModules = useSelector(reportModulesSelector);
    const currentReport = useSelector(currentReportSelector);

    if (!reportModules || reportModules.length === 0) {
        return <ReportNavItem pathname={pathname} />;
    }
    const currentReportDefinitionId = currentReport?.reportDefinitionId;
    const activeReportModuleId = currentReportDefinitionId
        ? find(
              reportModules,
              ({ isDefault, reportDefinitionIds }) =>
                  !isDefault && reportDefinitionIds.includes(currentReportDefinitionId)
          )?.id
        : undefined;

    return (
        <>
            {reportModules.map(({ isDefault, id, url, icon, name }) =>
                isDefault ? (
                    <ReportNavItem
                        key={id}
                        // if another Report Module is active, make the default Report Module inactive
                        pathname={activeReportModuleId ? '' : pathname}
                    />
                ) : (
                    <NavItem
                        key={id}
                        as={BaseLinkWithAnalytics}
                        to={`/modules/${url}`}
                        dismissNavOnInteraction
                        icon={isReportModuleIcon(icon) ? icon : 'Report'}
                        label={name}
                        isActive={pathname === `/modules/${url}` || id === activeReportModuleId}
                        testId={testIds.MODULE_REPORT_MODULE}
                    />
                )
            )}
        </>
    );
};

type LeftNavigationProps = {
    location: Location;
    router: InjectedRouter;
    className: string;
};

function LeftNavigation({ location, className }: LeftNavigationProps) {
    const userFullName = getFullName(useSelector(userProfileSelector));
    const userProfileUrl = useSelector(userProfileUrlSelector);
    const navigationPermissions = useSelector(navigationPermissionsSelector);
    const departmentStatusIndicator = useSelector(departmentStatusIndicatorSelector);
    const departmentProfile = useSelector(departmentProfileSelector);
    const shouldShowFieldNames = useSelector(shouldShowFieldNamesSelector);
    const federatedSearchNotificationsCount = useSelector(getFederatedSearchNotificationsCount);

    const [, setNavCollapse] = useNavCollapse();
    const [, setNavOpen] = useNavOpen();

    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    const isDesktop = useBreakpointValue({
        base: false,
        xl: true,
    });

    const closeNav = React.useCallback(() => {
        setNavOpen(false);
        if (!isDesktop) {
            setNavCollapse(true);
        }
    }, [isDesktop, setNavCollapse, setNavOpen]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => closeNav(), [location]);

    const dispatch = useDispatch();
    const logout = () => dispatch(_logout());
    const openKeyboardShortcutsSidePanel = () => dispatch(_openKeyboardShortcutsSidePanel());
    const toggleFieldNames = () => {
        dispatch(toggleShowFieldNames());
        dispatch(toggleFieldDisplayNames());
    };

    const dashboardLink = (
        <NavItem
            as={BaseLinkWithAnalytics}
            to="/"
            dismissNavOnInteraction
            icon="Dashboard"
            label={titles.dashboard}
            isActive={location.pathname === '/'}
            testId={testIds.MODULE_DASHBOARD}
        />
    );

    const reportLinks = navigationPermissions.hasReportingGeneral && (
        <FeatureFlagged
            flag="RMS_REPORT_MODULES_ENABLED"
            fallback={<ReportNavItem pathname={location.pathname} />}
        >
            <ReportModuleNavItems pathname={location.pathname} />
        </FeatureFlagged>
    );

    const { pluralCaseFieldName: caseModuleName } = useCaseFieldName();
    const casesLink = navigationPermissions.hasCasesGeneral && (
        <ProductModuled productModule={ProductModuleEnum.CASES.name}>
            <NavItem
                as={BaseLinkWithAnalytics}
                to="/cases"
                dismissNavOnInteraction
                label={titles.cases(caseModuleName)}
                icon="Case"
                isActive={pathStartsWith(location.pathname, '/cases')}
                testId={testIds.MODULE_CASES}
            />
        </ProductModuled>
    );

    const warrants = (
        <ProductModuled productModule={ProductModuleEnum.WARRANTS.name}>
            <OnlyWithAbility has={abilitiesEnum.WARRANTS.VIEW_GENERAL}>
                <NavItem
                    as={BaseLinkWithAnalytics}
                    to="/warrants"
                    dismissNavOnInteraction
                    label={titles.warrants}
                    icon="Warrant"
                    isActive={pathStartsWith(location.pathname, '/warrants')}
                    testId={testIds.MODULE_WARRANTS}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );

    const evidenceModuleName = useEvidenceModuleName();
    const evidence = (
        <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.SEARCH_ITEMS}>
                <NavItem
                    as={BaseLinkWithAnalytics}
                    to="/evidence/dashboard"
                    dismissNavOnInteraction
                    label={titles.evidence(evidenceModuleName)}
                    icon="Evidence"
                    isActive={pathStartsWith(location.pathname, [
                        '/evidence/dashboard',
                        '/evidence/inventories',
                        '/profiles/property/',
                    ])}
                    testId={testIds.MODULE_EVIDENCE}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );

    const analysis = navigationPermissions.hasAnalysisGeneral && (
        <ProductModuled productModule={ProductModuleEnum.ANALYSIS.name}>
            <NavItem
                as={BaseLinkWithAnalytics}
                to="/analytics"
                dismissNavOnInteraction
                label={titles.analysis}
                icon="Analysis"
                isActive={pathStartsWith(location.pathname, '/analytics')}
                testId={testIds.MODULE_ANALYSIS}
            />
        </ProductModuled>
    );

    const insights = (
        <ProductModuled productModule={ProductModuleEnum.INSIGHTS.name}>
            <OnlyWithAbility has={abilitiesEnum.ANALYSIS.GENERAL}>
                <NavItem
                    as={BaseLinkWithAnalytics}
                    to="/insights"
                    dismissNavOnInteraction
                    label={titles.insights}
                    icon="AnalyticsLook"
                    isActive={pathStartsWith(location.pathname, '/insights')}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );

    const mobile = (
        <FeatureFlagged flag="RMS_MOBILE_ENABLED">
            <OnlyWithAbility has={abilitiesEnum.REPORTING.FIELD_NOTES}>
                <NavItem
                    as={BaseLinkWithAnalytics}
                    to="/mobile/collections"
                    dismissNavOnInteraction
                    label={titles.mobile}
                    icon="Note"
                    isActive={pathStartsWith(location.pathname, '/mobile')}
                    testId={testIds.MODULE_MOBILE}
                />
            </OnlyWithAbility>
        </FeatureFlagged>
    );

    const federatedSearch = (
        <ProductModuled productModule={ProductModuleEnum.RMS_DEX.name}>
            <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES}>
                <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS}>
                    <NavItem
                        as={BaseLinkWithAnalytics}
                        count={federatedSearchNotificationsCount}
                        to="/federated-search"
                        label={titles.dex}
                        icon="Dex"
                        isActive={pathStartsWith(location.pathname, '/federated-search')}
                        testId={testIds.FEDERATED_SEARCH}
                    />
                </OnlyWithAbility>
            </OnlyWithAbility>
        </ProductModuled>
    );

    const tasks = (
        <FeatureFlagged flag="RMS_TASK_AND_REQUEST_TRACKING_ENABLED">
            <OnlyWithAbility has={abilitiesEnum.CORE.VIEW_NON_CASE_TASKS}>
                <NavItem
                    as={BaseLinkWithAnalytics}
                    to="/tasks"
                    dismissNavOnInteraction
                    label={titles.tasks}
                    icon="Task"
                    isActive={pathStartsWith(location.pathname, '/tasks')}
                    testId={testIds.MODULE_TASKS}
                />
            </OnlyWithAbility>
        </FeatureFlagged>
    );

    const notifications = (
        <ProductModuled productModule={ProductModuleEnum.NOTIFICATIONS.name}>
            <OnlyWithAbility has={abilitiesEnum.NOTIFICATIONS.CORE}>
                <NotificationsNavigationLink />
            </OnlyWithAbility>
        </ProductModuled>
    );

    const itemQueue = (
        <ProductModuled productModule={ProductModuleEnum.EVIDENCE.name}>
            <OnlyWithAbility has={abilitiesEnum.EVIDENCE.ITEM_QUEUE}>
                <ItemQueueNavItem
                    onPath={pathStartsWith(location.pathname, '/evidence/item-queue')}
                />
            </OnlyWithAbility>
        </ProductModuled>
    );

    // Menu Items
    const dropdownRef = useRef(null);

    const AccountSettings = () => {
        const routeTitle = useRouteTitle(ROUTE_KEYS.MY_ACCOUNT);
        return navigationPermissions.hasReadUsers ? (
            <MenuItem
                as={BaseLinkWithAnalytics}
                to={userProfileUrl}
                data-test-id={testIds.INITIALS_DROPDOWN_ACCOUNT_SETTINGS}
            >
                {routeTitle}
            </MenuItem>
        ) : null;
    };

    const AdminSettings = () => {
        const routeTitle = useRouteTitle(ROUTE_KEYS.ADMIN_SETTINGS);
        return navigationPermissions.hasAnyAdminAbility ? (
            <MenuItem
                className="dropdown-menu-option"
                as={BaseLinkWithAnalytics}
                to="/admin"
                data-test-id={testIds.INITIALS_DROPDOWN_ADMIN_SETTINGS}
            >
                {routeTitle}
            </MenuItem>
        ) : null;
    };

    const ComplianceDashboard = () => {
        const canNibrs =
            navigationPermissions.hasNibrs && checkIfDepartmentIsNibrs(departmentProfile);
        const canCompliance =
            navigationPermissions.hasComplianceDashboard && departmentProfile.complianceGroup;
        const routeTitle = useRouteTitle(ROUTE_KEYS.COMPLIANCE_DASHBOARD);
        return canNibrs || canCompliance ? (
            <MenuItem
                className="dropdown-menu-option"
                as={BaseLinkWithAnalytics}
                to="/compliance/dashboard"
                data-test-id={testIds.INITIALS_DROPDOWN_COMPLIANCE_DASHBOARD}
            >
                {routeTitle}
            </MenuItem>
        ) : null;
    };

    const Bulletins = () => {
        const routeTitle = useRouteTitle(ROUTE_KEYS.ADMIN_ALERTS);
        return navigationPermissions.hasManageBulletinsPermissions ? (
            <MenuItem
                className="dropdown-menu-option"
                as={BaseLinkWithAnalytics}
                to="/admin/alerts"
                data-test-id={testIds.INITIALS_DROPDOWN_MANAGE_ALERTS}
            >
                {routeTitle}
            </MenuItem>
        ) : null;
    };

    const ToggleLabels = () => {
        return (
            <FeatureFlagged flag="RMS_TOGGLE_FIELD_LABELS_ENABLED">
                <OnlyWithAbility
                    hasOneOf={[
                        abilitiesEnum.ADMIN.VIEW_VALIDATION,
                        abilitiesEnum.ADMIN.EDIT_GLOBAL_ATTRIBUTE_CONFIGURATION,
                    ]}
                >
                    <MenuItem onSelect={toggleFieldNames}>
                        {shouldShowFieldNames ? 'Hide' : 'Show'}{' '}
                        <Ability ability={abilitiesEnum.ADMIN.VIEW_VALIDATION}>
                            {(userHasAbility: boolean) => (
                                <>{userHasAbility ? 'Field Names' : 'Attribute Types'}</>
                            )}
                        </Ability>
                    </MenuItem>
                </OnlyWithAbility>
            </FeatureFlagged>
        );
    };

    const userMenu = (
        <Menu>
            <MenuTrigger ref={dropdownRef} asChild>
                <NavAvatar name={userFullName} data-test-id={testIds.INITIALS_DROPDOWN_BUTTON} />
            </MenuTrigger>
            <StyledMenuContent side={isMobile ? 'top' : 'right'} align="end">
                <AccountSettings />
                <AdminSettings />
                <ComplianceDashboard />
                <Bulletins />
                <ToggleLabels />
                <MenuItem
                    onSelect={() => {
                        openKeyboardShortcutsSidePanel();
                        closeNav();
                    }}
                >
                    {componentStrings.keyboardShortcutsSidePanel.title}
                </MenuItem>
                <FeatureFlagged flag="ACCOUNT_SWITCHER_ENABLED">
                    <UpdatedAccountSwitcherLink onClick={closeNav} />
                </FeatureFlagged>
                <MenuItem
                    as="a"
                    href={SUPPORT_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    onSelect={closeNav}
                >
                    {componentStrings.core.adminMenu.support}
                </MenuItem>
                <MenuItem onSelect={logout} data-test-id={testIds.INITIALS_DROPDOWN_LOGOUT}>
                    {componentStrings.core.adminMenu.logout}
                </MenuItem>
            </StyledMenuContent>
        </Menu>
    );

    const currentThemeName: 'light' | 'dark' = useSelector(currentThemeNameSelector);
    const darkMode = isDarkMode(currentThemeName);

    return (
        <>
            <KeyboardShortcutsSidePanel />
            {/* @ts-expect-error Typescript issue with colors */}
            <ThemeProvider theme={useAugmentedArcTheme('dark')}>
                <StyledNav className={className} id="main-navigation" $isDarkMode={darkMode}>
                    <NavBrand />
                    <NavItems>
                        <QuickSearchNavItem
                            isActive={pathStartsWith(location.pathname, ['/persons', '/search'])}
                        />
                        {dashboardLink}
                        {reportLinks}
                        {casesLink}
                        {evidence}
                        {warrants}
                        {analysis}
                        {insights}
                        {mobile}
                        {tasks}
                        {federatedSearch}
                    </NavItems>
                    <NavBottomDrawer>
                        <NavItems hideLabelsWhenCollapsed>
                            {notifications}
                            {itemQueue}
                        </NavItems>
                        <NavUser>{userMenu}</NavUser>
                        {departmentStatusIndicator && (
                            <DepartmentStatusIndicator priority={1}>
                                {departmentStatusIndicator}
                            </DepartmentStatusIndicator>
                        )}
                    </NavBottomDrawer>
                </StyledNav>
            </ThemeProvider>
        </>
    );
}

export default LeftNavigation;

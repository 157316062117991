import { get } from 'lodash';
import { createSelector } from 'reselect';

import {
    computeReportReportLinkTitle,
    computeOtherReportId,
} from '../../utils';
import { reportByIdSelector } from '../../../reports/state/data';
import { elasticReportByIdSelector } from '../../../elastic-reports/state/data';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '../../../consortium-link-view/state/ui';
import { reportShortTitleViewModelByReportIdSelector } from '../../../report-short-titles/state/ui';
import { formatFieldByNameSelector } from '../../../../fields/state/config';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    REPORT_RECORD_NUMBER,
} from '../../../../enums/universal/fields';

export const formatReportReportLinkTitleSelector = createSelector(
    reportByIdSelector,
    departmentNameFromConsortiumLinksByDepartmentIdSelector,
    reportShortTitleViewModelByReportIdSelector,
    elasticReportByIdSelector,
    formatFieldByNameSelector,
    (
            reportById,
            departmentNameFromConsortiumLinksByDepartmentId,
            reportShortTitleViewModelByReportId,
            elasticReportById,
            formatFieldByName
        ) =>
        ({ currentReportId, reportLink }) => {
            const otherDepartmentId =
                reportLink.toReportId === currentReportId
                    ? reportLink.departmentId
                    : reportLink.toDepartmentId;
            const currentReportDepartmentId = get(
                reportById(currentReportId),
                'departmentId'
            );
            const isCrossAgency =
                currentReportDepartmentId !== otherDepartmentId;
            const departmentName =
                reportLink.departmentId !== reportLink.toDepartmentId &&
                isCrossAgency
                    ? departmentNameFromConsortiumLinksByDepartmentId(
                          otherDepartmentId
                      )
                    : '';

            let title = computeReportReportLinkTitle({
                reportId: computeOtherReportId({
                    currentReportId,
                    fromReportId: reportLink.fromReportId,
                    toReportId: reportLink.toReportId,
                }),
                reportShortTitleViewModelByReportId,
                elasticReportById,
            });
            title = departmentName ? `${title} (${departmentName})` : title;

            let associatedRecordShortTitle;

            if (currentReportId === reportLink.fromReportId) {
                associatedRecordShortTitle =
                    reportShortTitleViewModelByReportId(reportLink.toReportId);
            } else {
                associatedRecordShortTitle =
                    reportShortTitleViewModelByReportId(currentReportId);
            }

            const renDisplayName = formatFieldByName(
                REPORT_REPORTING_EVENT_NUMBER
            );
            const recordIdDisplayName = formatFieldByName(REPORT_RECORD_NUMBER);

            const displayName = associatedRecordShortTitle?.reportingEventNumber
                ? renDisplayName
                : recordIdDisplayName;

            return `${displayName} ${title}`;
        }
);

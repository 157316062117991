import { Attachment } from '@mark43/rms-api';
import { filter as lodashFilter, includes } from 'lodash';
import React, { createContext, useState } from 'react';
import { Folder } from '~/client-common/core/domain/folders/state/data';

type SearchResultsT = {
    folders: Folder[];
    attachments: Attachment[];
};

type SearchFilterContextProps = {
    filter?: string;
    setFilter?: React.Dispatch<React.SetStateAction<string>>;
    currentPage?: number;
    setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
    searchResult?: SearchResultsT;
    setSearchResult?: React.Dispatch<React.SetStateAction<SearchResultsT>>;
    removeItemsFromSearchResults?: ({
        folderIds,
        attachmentIds,
    }: {
        folderIds?: number[];
        attachmentIds?: number[];
    }) => void;
};

export const SearchFilterContext = createContext<SearchFilterContextProps>({});

export const SearchFilterContextProvider: React.FC = ({ children }) => {
    const [filter, setFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [searchResult, setSearchResult] = useState<SearchResultsT>({
        folders: [],
        attachments: [],
    });

    const removeItemsFromSearchResults = ({
        folderIds = [],
        attachmentIds = [],
    }: {
        folderIds?: number[];
        attachmentIds?: number[];
    }) => {
        const adjustedFolders =
            searchResult.folders.length > 0
                ? lodashFilter(searchResult.folders, (folder) => {
                      return !includes(folderIds, folder.id);
                  })
                : [];

        const adjustedAttachments =
            searchResult.attachments.length > 0
                ? lodashFilter(searchResult.attachments, (attachment) => {
                      return !includes(attachmentIds, attachment.attachmentId);
                  })
                : [];

        const newSearchResult = {
            folders: adjustedFolders,
            attachments: adjustedAttachments,
        };
        setSearchResult(newSearchResult);

        if (filter) {
            setFilter('');
        }
    };

    return (
        <SearchFilterContext.Provider
            value={{
                filter,
                setFilter,
                currentPage,
                setCurrentPage,
                searchResult,
                setSearchResult,
                removeItemsFromSearchResults,
            }}
        >
            {children}
        </SearchFilterContext.Provider>
    );
};

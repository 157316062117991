import { assign, concat, mapValues } from 'lodash';

/** This only transfers the structure, will not filter out anything,
 * if you want to filter out for example undefined or {}, filter them before transfer.
 *
 * If type of U is an array, it will be flattened by _.concat,
 * this is desired because the attribute value of our api response might be either
 * a single value or an array of values.
 *
 * Check the unit test for more expected behaviors.
 */
export function mergeArrayOfObjects<T extends Record<string, unknown>>(arr: T[]): T {
    return arr.reduce<T>(
        (result, obj) =>
            assign(
                result,
                mapValues(obj, (v, k) => concat(result[k] || [], v))
            ),
        // @ts-expect-error Argument of type '{}' is not assignable to parameter of type 'T'.
        // '{}' is assignable to the constraint of type 'T', but 'T' could be instantiated with a different subtype of constraint 'Record<string, unknown>'.
        {}
    );
}

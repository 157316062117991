import { formatSvgPath } from '../../helpers';

import None from '../../../../../../../../../images/crash-diagram/street-background/none.svg';
import CrossRoad from '../../../../../../../../../images/crash-diagram/street-background/cross-road.svg';
import MisalignedIntersectionOne from '../../../../../../../../../images/crash-diagram/street-background/misaligned-intersection-1.svg';
import MisalignedIntersectionTwo from '../../../../../../../../../images/crash-diagram/street-background/misaligned-intersection-2.svg';
import OffRamp from '../../../../../../../../../images/crash-diagram/street-background/off-ramp.svg';
import OnRamp from '../../../../../../../../../images/crash-diagram/street-background/on-ramp.svg';
import OneWayStreet from '../../../../../../../../../images/crash-diagram/street-background/one-way-street.svg';
import Overpass from '../../../../../../../../../images/crash-diagram/street-background/overpass.svg';
import RoundAbout from '../../../../../../../../../images/crash-diagram/street-background/round-about.svg';
import TIntersectionLeft from '../../../../../../../../../images/crash-diagram/street-background/t-intersection-left.svg';
import TIntersectionRight from '../../../../../../../../../images/crash-diagram/street-background/t-intersection-right.svg';
import TwoWayStreet from '../../../../../../../../../images/crash-diagram/street-background/two-way-street.svg';
import Underpass from '../../../../../../../../../images/crash-diagram/street-background/underpass.svg';
import YIntersection from '../../../../../../../../../images/crash-diagram/street-background/y-intersection.svg';

// None should always be the first one in this array
export const streetBackgrounds = [
    None,
    CrossRoad,
    MisalignedIntersectionOne,
    MisalignedIntersectionTwo,
    OffRamp,
    OnRamp,
    OneWayStreet,
    Overpass,
    RoundAbout,
    TIntersectionLeft,
    TIntersectionRight,
    TwoWayStreet,
    Underpass,
    YIntersection,
].map((svgPath) => formatSvgPath(svgPath));

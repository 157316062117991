enum SerializerErrorCodes {
    INVALID_FORMAT = 'INVALID_FORMAT',
}

export const serializerErrorMessages: Record<SerializerErrorCodes, string> = {
    [SerializerErrorCodes.INVALID_FORMAT]: 'Invalid svg format provided',
};

class BaseSerializerError extends Error {
    code: SerializerErrorCodes;
    constructor(message: string, code: SerializerErrorCodes) {
        super(message);
        this.code = code;
    }
}
export class InvalidSvgFormatError extends BaseSerializerError {
    constructor() {
        super(serializerErrorMessages.INVALID_FORMAT, SerializerErrorCodes.INVALID_FORMAT);
    }
}

import {
    InferFormDataShape,
    createField,
    createFormConfiguration,
    createNItems,
    lifecycleOptions,
} from 'markformythree';
import {
    RefContextEnumType,
    AttributeTypeEnum,
    CaseReportLinkCreationFromCaseRequest,
    CaseReportLinkCreationFromCaseRequestWrapper,
    CaseReportLinkCreationFromReportRequest,
    CaseReportLinkCreationFromReportRequestWrapper,
    ApprovalStatusForCaseEnumType,
    CaseDefaultsRequest,
    CaseReportLinkAttributeView,
} from '@mark43/rms-api';
import { compact, filter, flatten, isNumber, map, reduce, uniq } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import formsRegistry from '../../../../../core/formsRegistry';
import { ReportIdentifier } from '../../../../reports/core/utils/buildReportIdentifierView';

export interface CaseViewType {
    approvalStatus?: ApprovalStatusForCaseEnumType;
    assignedPersonnelUnitAttrId?: number;
    assigneeRoleId?: number;
    caseId: number;
    currentStatusAttrId?: number;
    shortTitle?: string;
    recentlyViewedCase: boolean;
}

const getReasonForRelationConfig = (hideReasonForRelation: boolean) => {
    const reasonForRelationFields = {
        reasonForRelationAttrId: createField<number>({
            fieldName:
                fields.CASE_REPORT_LINK_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_RELATION_ATTRIBUTE_ID,
        }),
        reasonForRelationOther: createField<string>({
            fieldName:
                fields.CASE_REPORT_LINK_ATTRIBUTE_ATTRIBUTE_TYPE_REASON_FOR_RELATION_OTHER_ATTRIBUTE_DESCRIPTION,
        }),
    };

    const reportCaseLinksConfig = createNItems({
        fields: {
            ...(hideReasonForRelation ? {} : reasonForRelationFields),
            reportingEventNumber: createField<string>({
                fieldName: fields.REPORT_REPORTING_EVENT_NUMBER,
            }),
            recordNumber: createField<string>({}),
            reportIds: createField<number[]>({}),
            caseId: createField<number>({}),
        },
    });

    return createFormConfiguration({
        reportCaseLinks: reportCaseLinksConfig,
    });
};

export type ReasonForRelationFormConfiguration = ReturnType<typeof getReasonForRelationConfig>;
export type ReasonForRelationFormNItemDataShape = InferFormDataShape<
    ReasonForRelationFormConfiguration['reportCaseLinks']
>[0];

type ReasonForRelationToCaseFormDataShape = InferFormDataShape<ReasonForRelationFormConfiguration>;

export function convertToFormModel({
    reportIds,
    reportingEventNumber,
    caseView,
}: {
    reportIds: number[];
    reportingEventNumber: string;
    caseView: CaseViewType;
}) {
    return {
        reportingEventNumber,
        reportIds,
        caseId: caseView.caseId,
    };
}

export function convertReportShortTitleWrapperToFormModel(
    reportShortTitleWrapper: ReportShortTitleWrapper,
    selectedReportIds?: number[],
    caseId?: number
) {
    // one NItem per REN
    const nItemsForRens = map(
        reportShortTitleWrapper.reportShortTitlesByREN,
        (reportShortTitles, reportingEventNumber) => {
            const renReportIds = map(reportShortTitles, 'reportId');
            const reportIds = selectedReportIds
                ? renReportIds.filter((reportId) => selectedReportIds.includes(reportId))
                : renReportIds;
            return {
                reportingEventNumber,
                caseId,
                reportIds,
            };
        }
    );

    // one NItem per record number
    // while the individual RWRs within each `reportShortTitles` array are not actually linked to each other, it's still
    // possible for different RWRs to have the same record number, and we group them together in one NItem
    const nItemsForRecordNumbers = map(
        reportShortTitleWrapper.reportShortTitlesByRecordWithoutRENRecordNumber,
        (reportShortTitles, recordNumber) => {
            const renReportIds = map(reportShortTitles, 'reportId');
            const reportIds = selectedReportIds
                ? renReportIds.filter((reportId) => selectedReportIds.includes(reportId))
                : renReportIds;
            return {
                recordNumber,
                caseId,
                reportIds,
            };
        }
    );

    return {
        reportCaseLinks: [...nItemsForRens, ...nItemsForRecordNumbers],
    };
}

export function convertFromFormModelToCaseRequest(
    formModel: ReasonForRelationToCaseFormDataShape,
    caseId: number,
    individualReportSelectionEnabled: boolean
): CaseReportLinkCreationFromCaseRequestWrapper {
    const caseReportLinkCreationFromCaseRequests = reduce<
        ReasonForRelationFormNItemDataShape,
        CaseReportLinkCreationFromCaseRequest[]
    >(
        formModel.reportCaseLinks,
        (requests, nItem) => {
            const isRen = !!nItem.reportingEventNumber && !individualReportSelectionEnabled;
            let reasonForRelationAttributeView: CaseReportLinkAttributeView | undefined;
            if (!!nItem.reasonForRelationAttrId) {
                reasonForRelationAttributeView = {
                    attributeId: nItem.reasonForRelationAttrId,
                    attributeType: AttributeTypeEnum.REASON_FOR_RELATION.name,
                    otherAttributeDescription: nItem.reasonForRelationOther,
                };
            }

            if (isRen) {
                return [
                    ...requests,
                    {
                        linkedReportREN: nItem.reportingEventNumber,
                        reasonForRelation: reasonForRelationAttributeView,
                    },
                ];
            } else {
                return [
                    ...requests,
                    ...map(nItem.reportIds, (reportId) => ({
                        linkedRecordWithoutRENId: reportId,
                        reasonForRelation: reasonForRelationAttributeView,
                    })),
                ];
            }
        },
        []
    );

    return {
        caseId,
        caseReportLinkCreationFromCaseRequests,
    };
}

export function convertFromFormModelToRecordIdentifiers(
    formModel: ReasonForRelationToCaseFormDataShape
): ReportIdentifier[] {
    const { reportCaseLinks = [] } = formModel;

    return reportCaseLinks.reduce<ReportIdentifier[]>((acc, item) => {
        if (Array.isArray(item.reportIds)) {
            return [
                ...acc,
                ...item.reportIds.map((id) => {
                    return {
                        id,
                        recordNumber: item.recordNumber,
                        reportingEventNumber: item.reportingEventNumber,
                    };
                }),
            ];
        }
        return acc;
    }, []);
}

export function convertFromFormModelToReportRequest(
    formModel: ReasonForRelationToCaseFormDataShape
): CaseReportLinkCreationFromReportRequestWrapper {
    const { reportCaseLinks } = formModel;

    const caseReportLinkCreationFromReportRequests: CaseReportLinkCreationFromReportRequest[] = map(
        reportCaseLinks,
        (reportCaseLink) => {
            if (!!reportCaseLink.reasonForRelationAttrId) {
                return {
                    reasonForRelation: {
                        attributeId: reportCaseLink.reasonForRelationAttrId,
                        attributeType: AttributeTypeEnum.REASON_FOR_RELATION.name,
                        otherAttributeDescription: reportCaseLink.reasonForRelationOther,
                    },
                    caseId: reportCaseLink.caseId || -1,
                };
            }

            return {
                caseId: reportCaseLink.caseId || -1,
            };
        }
    );

    const reportIds = uniq(filter(flatten(map(reportCaseLinks, 'reportIds')), isNumber));

    return {
        reportIds,
        caseReportLinkCreationFromReportRequests,
    };
}

export function convertFromFormModelToCaseDefaultsRequest(
    formModel: ReasonForRelationToCaseFormDataShape,
    caseDefinitionId: number
): CaseDefaultsRequest {
    const { reportCaseLinks } = formModel;
    const reportIds = uniq(compact(flatten(map(reportCaseLinks, 'reportIds'))));

    return {
        caseDefinitionId,
        reportIds,
    };
}

export function resetReasonForRelationForm(formContext: RefContextEnumType) {
    const form = formsRegistry.get(formContext);

    if (form) {
        form.resetUi();
        form.resetModel();
    }
}

export const getReasonForRelationForm = (hideReasonForRelation: boolean) => ({
    configuration: getReasonForRelationConfig(hideReasonForRelation),
    lifecycle: lifecycleOptions.REGISTER_AND_RETAIN,
});

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styled, { DefaultTheme } from 'styled-components';

import { EntityTypeEnum, SyncedEntityView } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';

import supportSearchSyncResource from '../../../../../modules/support/search-sync/resources/supportSearchSyncResource';
import { currentUserIsSupportUserSelector } from '../../../../../modules/core/current-user/state/ui';
import _Button from '../../Button';
import { SimpleLoading as _SimpleLoading } from '../../Loading';

const SyncStatusEnum = {
    DEFAULT: 'DEFAULT',
    SYNCED: 'SYNCED',
    NOT_SYNCED: 'NOT_SYNCED',
} as const;
type SyncStatusEnum = keyof typeof SyncStatusEnum;

const strings =
    componentStrings.reports.dashboard.ReportsDashboardSearchResults.columns.routingLabels;

const getBgColor = ({ syncStatus, theme }: { syncStatus: SyncStatusEnum; theme: DefaultTheme }) => {
    const { colors } = theme;
    const { darkGreen, lightGrey, red } = colors;
    switch (syncStatus) {
        case SyncStatusEnum.SYNCED:
            return darkGreen;
        case SyncStatusEnum.NOT_SYNCED:
            return red;
        default:
            return lightGrey;
    }
};

const StyledIndicator = styled.span<{ syncStatus: SyncStatusEnum }>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => getBgColor(props)};
    margin-left: 8px;
    margin-bottom: -1px;
    display: inline-block;
`;

const Button = styled(_Button)`
    padding: 6px;
    margin: 0px 0px 6px 0px;
    align-self: flex-end;
`;

const SimpleLoading = styled(_SimpleLoading)`
    width: 12px;
    height: 12px;
    display: inline-block;
    margin: 0 4px -1px;

    .loading-whitebg {
        width: 12px;
        padding-right: 8px;
        height: 12px;
        margin: 0 0 0 5px;
    }
`;

const useHandleLoadSyncStatus = (onSuccess: (results: SyncedEntityView[]) => void) => {
    const resource = (reportId: number) =>
        supportSearchSyncResource.getAreEntitiesSynced('RMS', EntityTypeEnum.REPORT.name, [
            reportId,
        ]);
    return useResourceDeferred(resource, onSuccess);
};

export default function SyncStatusButton({ reportId }: { reportId: number }) {
    const [syncStatus, setSyncStatus] = useState<SyncStatusEnum>(SyncStatusEnum.DEFAULT);
    const currentUserIsSupport = useSelector(currentUserIsSupportUserSelector);

    const { loading, callResource } = useHandleLoadSyncStatus((results: SyncedEntityView[]) => {
        if (!isEmpty(results)) {
            setSyncStatus(
                results[0].isSynchronized ? SyncStatusEnum.SYNCED : SyncStatusEnum.NOT_SYNCED
            );
        }
    });

    const rightIcon = loading.isLoading ? (
        <SimpleLoading float="none" />
    ) : (
        <StyledIndicator syncStatus={syncStatus} />
    );

    return currentUserIsSupport ? (
        <Button
            onClick={() => callResource(reportId)}
            iconRight={rightIcon}
            stopPropagation={true}
            disabled={loading.isLoading}
        >
            {strings.status}
        </Button>
    ) : null;
}

import { map, reduce, size, omit, compact, flatten } from 'lodash';
import Promise from 'bluebird';
import { EntityTypeEnum } from '@mark43/rms-api';

import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { NEXUS_STATE_PROP as LOCATION_EXTERNAL_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-external-links/state/data';
import { NEXUS_STATE_PROP as ELASTIC_PERSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-persons/state/data/';
import { NEXUS_STATE_PROP as ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-organizations/state/data/';
import { currentWarrantIdSelector } from '../../modules/warrants/warrant/state/ui';
import { embeddedReportShortTitlesSelector } from '../../modules/reports/core/state/ui/arrestBlock';
import { currentReportIdSelector, currentReportRENSelector } from '../selectors/reportSelectors';
import resource from '../resources/recentEntitiesResource';
import { Mark43Error } from '../../lib/errors';
import recentEntitiesActionTypes from './types/recentEntitiesActionTypes';

const clearRecentEntities = ({ recentEntityType, ownerType, ownerId, renForRecents }) => ({
    type: recentEntitiesActionTypes.CLEAR_RECENT_ENTITIES,
    payload: {
        renForRecents,
        recentEntityType,
        ownerType,
        ownerId,
    },
});

const clearRecentEntitiesActionCreatorFactory = (recentEntityType) => ({
    renForRecents,
    ownerType,
    ownerId,
}) =>
    clearRecentEntities({
        renForRecents,
        recentEntityType,
        ownerType,
        ownerId,
    });

export const clearRecentLocations = clearRecentEntitiesActionCreatorFactory(
    EntityTypeEnum.LOCATION.name
);

function requestRecentEntitiesStart({ renForRecents, ownerType, ownerId, recentEntityType }) {
    return {
        type: recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_START,
        payload: { renForRecents, ownerType, ownerId, recentEntityType },
    };
}

function requestRecentEntitiesSuccess({
    renForRecents,
    recentEntityType,
    recentEntities,
    ownerType,
    ownerId,
}) {
    return {
        type: recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_SUCCESS,
        payload: {
            renForRecents,
            recentEntityType,
            recentEntities,
            ownerType,
            ownerId,
        },
    };
}

function requestRecentEntitiesFailure({
    error,
    renForRecents,
    ownerType,
    ownerId,
    recentEntityType,
}) {
    return {
        type: recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_FAILURE,
        error: true,
        payload: { error, renForRecents, ownerType, ownerId, recentEntityType },
    };
}

/**
 * Request recent entities of the given type (such as LOCATION) for the given
 *   entity (report).
 * @param  {number} renForRecents
 * @param  {string} ownerType
 * @param  {number} ownerId
 * @param  {string} recentEntityType
 * @return {function}
 */
function requestRecentEntities(renForRecents, ownerType, ownerId, recentEntityType) {
    return function (dispatch, getState, dependencies) {
        dispatch(
            requestRecentEntitiesStart({ renForRecents, ownerType, ownerId, recentEntityType })
        );

        let method;
        let nexusProperty;
        let buildNexusObject = (nexusProperty, recentEntities) => ({
            [nexusProperty]: recentEntities,
        });

        switch (recentEntityType) {
            case EntityTypeEnum.PERSON_PROFILE.name:
                method = 'getRecentPersons';
                nexusProperty = ELASTIC_PERSONS_NEXUS_STATE_PROP;
                break;
            case EntityTypeEnum.ORGANIZATION_PROFILE.name:
                // The `getRecentOrganizationsDeprecated` returns real organizations,
                // but the new endpoint returns `elasticOrganizations`
                method = 'getRecentOrganizations';
                nexusProperty = ELASTIC_ORGANIZATIONS_NEXUS_STATE_PROP;
                break;
            case EntityTypeEnum.LOCATION.name:
                method = 'getRecentLocations';
                nexusProperty = LOCATIONS_NEXUS_STATE_PROP;
                buildNexusObject = (nexusProperty, recentEntities) => {
                    // Note: This is not great, but we are augmenting
                    // the location that gets returned by the
                    // recent locations endpoint with some additional data
                    //
                    // When we store these into `dataNexus`, we are stripping
                    // the additional data (namely the `locationExternalLinks`)
                    // so that we can still store a normalized location, and
                    // its normalized `locationExternalLinks` separately
                    //
                    // iterate through all the locations and pull off the
                    // external links
                    return reduce(
                        recentEntities,
                        (acc, recentEntity) => {
                            // Track all the locations, but remove the `locationExternalLinks` from them
                            acc[nexusProperty].push(omit(recentEntity, 'locationExternalLinks'));
                            // If there were `locationExternalLinks`,
                            // store them in a different nexus key
                            if (size(recentEntity.locationExternalLinks) >= 1) {
                                acc[LOCATION_EXTERNAL_LINKS_NEXUS_STATE_PROP].push(
                                    ...recentEntity.locationExternalLinks
                                );
                            }
                            return acc;
                        },
                        {
                            [nexusProperty]: [],
                            [LOCATION_EXTERNAL_LINKS_NEXUS_STATE_PROP]: [],
                        }
                    );
                };
                break;
            default:
                return Promise.resolve(
                    dispatch(
                        requestRecentEntitiesFailure({
                            error: new Mark43Error('Invalid entity type'),
                            renForRecents,
                            ownerType,
                            ownerId,
                            recentEntityType,
                        })
                    )
                );
        }

        return resource[method](renForRecents, ownerType, ownerId)
            .then(({ attributes, recentEntities }) => {
                return dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                attributes,
                                convertAttributeToAttributeView
                            ),
                            ...(nexusProperty
                                ? buildNexusObject(nexusProperty, recentEntities)
                                : {}),
                        },
                        requestRecentEntitiesSuccess({
                            renForRecents,
                            recentEntityType,
                            recentEntities,
                            ownerType,
                            ownerId,
                        })
                    )
                );
            })
            .catch((error) =>
                dispatch(
                    requestRecentEntitiesFailure({
                        error,
                        renForRecents,
                        ownerType,
                        ownerId,
                        recentEntityType,
                    })
                )
            );
    };
}

/**
 * Private action creator factory for an action creator that makes a server
 *   request for recent entities of the given type.
 * @param  {string} recentEntityType String from `entityTypeEnum`.
 * @return {function}
 */
const requestRecentEntitiesActionCreatorFactory = (recentEntityType) => (
    renForRecents,
    ownerType,
    ownerId
) => (dispatch) =>
    dispatch(requestRecentEntities(renForRecents, ownerType, ownerId, recentEntityType)).then(
        ({ payload }) => payload.recentEntities
    );

/**
 * @param  {number} renForRecents
 * @param  {string} ownerType
 * @param  {number} onwerId
 * @return {Promise<Object[]>} Dispatched action promise resolves with person
 *   profile objects, not an action.
 */
const requestRecentPersons = requestRecentEntitiesActionCreatorFactory(
    EntityTypeEnum.PERSON_PROFILE.name
);

/**
 * @param  {number} renForRecents
 * @param  {string} ownerType
 * @param  {number} onwerId
 * @return {Promise<Object[]>} Dispatched action promise resolves with
 *   organization profile objects, not an action.
 */
const requestRecentOrganizations = requestRecentEntitiesActionCreatorFactory(
    EntityTypeEnum.ORGANIZATION_PROFILE.name
);

export const requestRecentPersonsOrOrganizations = ({
    renForRecents,
    ownerType,
    ownerId,
    isOrg,
}) => (dispatch) => {
    // There are only two cases in which we execute a recent person/organization search
    // 1) If on a report
    // 2) If on a warrant
    //
    // Thus, we should only construct configs for these two variations
    //
    // NOTE: While it is an option, we actually don't pass through
    // `renForRecents` in the legacy `connectNameView`, so we can
    // easily use the selector here. The only time
    // we do pass it down is for warrants (but we could replace this
    // with the selector as well)

    if (ownerType === EntityTypeEnum.REPORT.name) {
        return dispatch(
            requestRecentPersonsOrOrganizationsForAllReports({
                renForRecents,
                ownerType,
                isOrg,
            })
        );
    } else if (ownerType === EntityTypeEnum.WARRANT.name && ownerId) {
        return dispatch(
            requestRecentPersonsOrOrganizationsForWarrants({
                renForRecents,
                ownerType,
                ownerId,
                isOrg,
            })
        );
    }
    return Promise.resolve();
};

const requestRecentPersonsOrOrganizationsForWarrants = ({
    renForRecents,
    ownerType,
    ownerId,
    isOrg,
}) => (dispatch, getState) => {
    const state = getState();
    const currentWarrantId = currentWarrantIdSelector(state);

    if (currentWarrantId && ownerType && ownerId) {
        return dispatch(
            (isOrg ? requestRecentOrganizations : requestRecentPersons)(
                renForRecents,
                ownerType,
                ownerId
            )
        );
    }
    return Promise.resolve();
};

export const requestRecentPersonsOrOrganizationsForAllReports = ({
    renForRecents,
    ownerType,
    isOrg,
}) => (dispatch, getState) => {
    const state = getState();
    const currentReportId = currentReportIdSelector(state);
    const embeddedReports = embeddedReportShortTitlesSelector(state);
    const embeddedReportIds = map(embeddedReports, 'reportId');
    const reportIdsOnPage = compact([currentReportId, ...embeddedReportIds]);

    if (reportIdsOnPage.length > 0 && ownerType) {
        const dispatchFunction = isOrg ? requestRecentOrganizations : requestRecentPersons;
        return Promise.all(
            map(reportIdsOnPage, (reportId) =>
                dispatch(dispatchFunction(renForRecents, ownerType, reportId))
            )
        ).then((recentOrganizationOrPersonForRen) => flatten(recentOrganizationOrPersonForRen));
    }

    return Promise.resolve();
};

/**
 * @param  {number} renForRecents
 * @param  {string} ownerType
 * @param  {number} onwerId
 * @return {Promise<Object[]>} Dispatched action promise resolves with location
 *   objects, not an action.
 */
const requestRecentLocations = requestRecentEntitiesActionCreatorFactory(
    EntityTypeEnum.LOCATION.name
);

export const requestRecentLocationsForReports = (renForRecents, ownerType) => (
    dispatch,
    getState
) => {
    const state = getState();
    const currentReportId = currentReportIdSelector(state);
    const embeddedReports = embeddedReportShortTitlesSelector(state);
    const embeddedReportIds = map(embeddedReports, 'reportId');

    const reportIdsOnPage = [currentReportId, ...embeddedReportIds];

    if (reportIdsOnPage.length > 0) {
        return Promise.all(
            map(reportIdsOnPage, (reportId) =>
                dispatch(requestRecentLocations(renForRecents, ownerType, reportId))
            )
        ).then((recentLocationsForRen) => flatten(recentLocationsForRen));
    }
    return Promise.resolve();
};

export const requestRecentLocationsForEntity = (entityIds, ownerType) => (dispatch, getState) => {
    const state = getState();
    const renForRecents = currentReportRENSelector(state);

    if (entityIds.length > 0) {
        return Promise.all(
            map(entityIds, (entityId) =>
                dispatch(requestRecentLocations(renForRecents, ownerType, entityId))
            )
        ).then((recentLocationsForRen) => flatten(recentLocationsForRen));
    }
    return Promise.resolve();
};

export const clearAllRecentEntities = () => ({
    type: recentEntitiesActionTypes.CLEAR_ALL_RECENT_ENTITIES,
});

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers } from 'recompose';
import styled from 'styled-components';
import { mapValues, size } from 'lodash';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import DoubleSidePanel from '../../../core/box/components/DoubleSidePanel';
import { DashedDivider } from '../../../../legacy-redux/components/core/Divider';
import {
    saveBoxHalt,
    closeBox,
    saveBoxFailure,
    storeBoxErrorMessages,
} from '../../../../legacy-redux/actions/boxActions';

import evidenceItemSplitForm, {
    childItemTypeAttrIdSelector,
    parentItemProfileIdSelector,
    parentItemTypeAttrIdSelector,
} from '../state/forms/evidenceItemSplitForm';
import ItemProfileFieldset from '../../../reports/core/components/item-profile/ItemProfileFieldset';
import ItemIdentifierNItems from '../../../reports/core/components/item-profile/ItemIdentifierNItems';
import OwnerClaimantNItems from '../../../reports/core/components/item-profile/OwnerClaimantNItems';
import itemProfileFieldViewModels from '../../../reports/core/state/forms/itemProfileFieldViewModels';
import {
    propertyProfileByItemIdSelector,
    openConfirmation,
    resetUi,
    validateItemProfiles,
    validatePropertyStatuses,
} from '../state/ui';
import CopyDataButton from './CopyDataButton';
import ItemSplitPropertyStatusFieldset from './ItemSplitPropertyStatusFieldset';

const strings = componentStrings.evidence.itemSplit.ItemSplitSidePanel;
const context = { name: boxEnum.ITEM_SPLIT_SIDE_PANEL };
const widths = {
    full: 410,
    half: 200,
    mini: 70,
    third: 130,
};

const disabledConfig = mapValues(itemProfileFieldViewModels, () => true);
disabledConfig.description = false;

const ClaimantsWrapper = styled.div`
    margin-top: 10px;
`;
const FlexWrapper = styled.div`
    flex: 100%;
`;

function LeftItemSplit({
    showItemProfile,
    parentItemTypeAttrId,
    childItemTypeAttrId,
    parentItemProfileId,
}) {
    return showItemProfile ? (
        <RRFFieldset path="parentItemProfile" highlightOnFocus={false}>
            <ItemProfileFieldset
                itemTypeAttrId={parentItemTypeAttrId}
                disabledConfig={disabledConfig}
            />
        </RRFFieldset>
    ) : (
        <RRFFieldset path="parentPropertyStatus" highlightOnFocus={false}>
            <ItemSplitPropertyStatusFieldset
                isParent={true}
                parentItemProfileId={parentItemProfileId}
                parentItemTypeAttrId={parentItemTypeAttrId}
                childItemTypeAttrId={childItemTypeAttrId}
                widths={widths}
            />
        </RRFFieldset>
    );
}

function RightItemSplit({
    showItemProfile,
    parentItemTypeAttrId,
    childItemTypeAttrId,
    parentItemProfileId,
}) {
    return showItemProfile ? (
        <div>
            <RRFFieldset path="childItemProfile" highlightOnFocus={false}>
                <ItemProfileFieldset itemTypeAttrId={childItemTypeAttrId} widths={widths} />
                <DashedDivider />
                <ItemIdentifierNItems addItemOnEmpty={false} includeExpired={false} />
                <DashedDivider />
                <OwnerClaimantNItems isClaimants={false} widths={widths} />
                <ClaimantsWrapper>
                    <OwnerClaimantNItems isClaimants={true} widths={widths} />
                </ClaimantsWrapper>
            </RRFFieldset>
        </div>
    ) : (
        <RRFFieldset path="childPropertyStatus" highlightOnFocus={false}>
            <ItemSplitPropertyStatusFieldset
                isParent={false}
                parentItemProfileId={parentItemProfileId}
                parentItemTypeAttrId={parentItemTypeAttrId}
                childItemTypeAttrId={childItemTypeAttrId}
                widths={widths}
            />
        </RRFFieldset>
    );
}

function ItemSplitSidePanel({
    showItemProfile,
    onBack,
    onNext,
    onSave,
    onCancel,
    parentItemTypeAttrId,
    childItemTypeAttrId,
    parentItemProfileId,
    propertyProfileByItemId,
    childItemDescription,
}) {
    const parentItemProfile = propertyProfileByItemId(parentItemProfileId);

    return (
        <DoubleSidePanel
            context={context}
            hideBackButton={showItemProfile}
            onBack={onBack}
            onSave={showItemProfile ? onNext : onSave}
            onCancel={onCancel}
            title={strings.title}
            saveText={showItemProfile ? strings.labels.next : strings.labels.save}
            leftHeaderLabel="Existing Item"
            rightHeaderLabel="New Item"
            leftHeaderSecondary={parentItemProfile.description}
            rightHeaderSecondary={showItemProfile ? <CopyDataButton /> : childItemDescription}
            leftContent={
                <LeftItemSplit
                    showItemProfile={showItemProfile}
                    parentItemTypeAttrId={parentItemTypeAttrId}
                    childItemTypeAttrId={childItemTypeAttrId}
                    parentItemProfileId={parentItemProfileId}
                />
            }
            rightContent={
                <RightItemSplit
                    showItemProfile={showItemProfile}
                    parentItemTypeAttrId={parentItemTypeAttrId}
                    childItemTypeAttrId={childItemTypeAttrId}
                    parentItemProfileId={parentItemProfileId}
                />
            }
            renderChildrenWrapper={(children) => (
                <FlexWrapper>
                    <ReactReduxForm {...evidenceItemSplitForm}>{children}</ReactReduxForm>
                </FlexWrapper>
            )}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    parentItemTypeAttrId: parentItemTypeAttrIdSelector,
    childItemTypeAttrId: childItemTypeAttrIdSelector,
    parentItemProfileId: parentItemProfileIdSelector,
    propertyProfileByItemId: propertyProfileByItemIdSelector,
    childItemDescription: evidenceItemSplitForm.selectors.buildFormModelSelectorByPath(
        'childItemProfile.description'
    ),
});

const mapDispatchToProps = (dispatch) => ({
    saveBoxHalt: () => dispatch(saveBoxHalt(context)),
    closeBox: () => dispatch(closeBox(context)),
    resetForm: () => dispatch(evidenceItemSplitForm.actionCreators.reset()),
    saveBoxFailure: (...args) => dispatch(saveBoxFailure(...args)),
    storeBoxErrorMessages: (...args) => dispatch(storeBoxErrorMessages(...args)),
    openConfirmation: () => dispatch(openConfirmation()),
    resetUi: () => dispatch(resetUi()),
    validateItemProfiles: () => dispatch(validateItemProfiles()),
    validatePropertyStatuses: () => dispatch(validatePropertyStatuses()),
});

/**
 * Item Split Double Side Panel.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withState('showItemProfile', 'setShowItemProfile', true),
    withHandlers({
        onBack: ({ setShowItemProfile, storeBoxErrorMessages }) => () => {
            storeBoxErrorMessages(context, []);
            setShowItemProfile(true);
        },
        onNext: ({
            setShowItemProfile,
            saveBoxFailure,
            saveBoxHalt,
            storeBoxErrorMessages,
            validateItemProfiles,
        }) => () => {
            const errors = validateItemProfiles();
            // SidePanel savePanel dispatches BOTH save action and onSave prop
            // turn off loading ui
            saveBoxHalt();
            if (size(errors) > 0) {
                saveBoxFailure(context, errors);
            } else {
                storeBoxErrorMessages(context, []);
                setShowItemProfile(false);
            }
        },
        onSave: ({
            closeBox,
            openConfirmation,
            saveBoxFailure,
            saveBoxHalt,
            validatePropertyStatuses,
        }) => () => {
            const errors = validatePropertyStatuses();
            // SidePanel savePanel dispatches BOTH save action and onSave prop
            // turn off loading ui
            saveBoxHalt();
            if (size(errors) > 0) {
                saveBoxFailure(context, errors);
            } else {
                closeBox();
                openConfirmation();
            }
        },
        onCancel: ({ closeBox, setShowItemProfile, resetForm, resetUi }) => () => {
            // manually close box because onCancel won't
            closeBox();
            // reset to item profile view
            setShowItemProfile(true);
            resetForm();
            resetUi();
        },
    })
)(ItemSplitSidePanel);

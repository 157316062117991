import { EntityTypeEnum } from '@mark43/rms-api';
import _, { mapValues, reduce, groupBy, reject, join } from 'lodash';
import { createSelector } from 'reselect';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { locationEntityLinksSelector, locationEntityLinksWhereSelector } from '../data';
import { arrestForReportIdSelector } from '../../../arrests/state/data';
import { offensesByReportIdSelector } from '../../../offenses/state/data';
import { nameReportLinksWhereSelector } from '../../../name-report-links/state/data';
import { citationByReportIdSelector } from '../../../citations/state/data';
import { fieldContactByReportIdSelector } from '../../../field-contacts/state/data';
import { behavioralCrisisByReportIdSelector } from '../../../behavioral-crises/state/data';
import { towVehicleByReportIdSelector } from '../../../tow-vehicles/state/data';
import { itemProfilesInReportSelector } from '../../../item-profiles/state/data';
import { useOfForcesWhereSelector } from '../../../use-of-forces/state/data';
import { unknownLocationIdSelector } from '../../../../constants/state/constants';

const locationEntityLinkViewModelsSelector = createSelector(
    locationEntityLinksSelector,
    formatAttributeByIdSelector,
    (locationEntityLinks, formatAttributeById) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [allSingleAttributeValuesMapper],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(locationEntityLinks, viewModel);
    }
);

export const locationEntityLinksViewModelsWhereSelector = createSelector(
    locationEntityLinkViewModelsSelector,
    (locationEntityLinkViewModel) => (predicate) =>
        // we tend to grab the first element, so let's put the most important on top (most recent)
        _(locationEntityLinkViewModel).filter(predicate).sortBy('createdDateUtc').reverse().value()
);

const constructLocationKey = (entityType, id) => join([entityType, id], '-');
export const locationEntityLinksByLocationIdForReportIdSelector = createSelector(
    arrestForReportIdSelector,
    offensesByReportIdSelector,
    locationEntityLinksWhereSelector,
    nameReportLinksWhereSelector,
    citationByReportIdSelector,
    fieldContactByReportIdSelector,
    behavioralCrisisByReportIdSelector,
    towVehicleByReportIdSelector,
    itemProfilesInReportSelector,
    useOfForcesWhereSelector,
    unknownLocationIdSelector,
    (
        arrestForReportId,
        offensesByReportId,
        locationEntityLinksWhere,
        nameReportLinksWhere,
        citationByReportId,
        fieldContactByReportId,
        behavioralCrisisByReportId,
        towVehicleByReportId,
        itemProfilesInReport,
        useOfForcesWhere,
        unknownLocationId
    ) => (reportId, { ignoreNoFixed = true } = {}) => {
        const arrest = arrestForReportId(reportId);
        const offenses = offensesByReportId(reportId);
        const fieldContact = fieldContactByReportId(reportId);
        const behavioralCrisis = behavioralCrisisByReportId(reportId);
        const towVehicle = towVehicleByReportId(reportId);
        const itemProfiles = itemProfilesInReport(reportId);
        const useOfForces = useOfForcesWhere({ reportId });
        const citation = citationByReportId(reportId);

        const nameReportLinks = nameReportLinksWhere({ reportId });

        const parentLocationKeys = {
            // locations related to arrest data
            ...(arrest
                ? { [constructLocationKey(EntityTypeEnum.ARREST.name, arrest.id)]: true }
                : {}),
            // locations related to offenses
            ...reduce(
                offenses,
                (acc, { id }) => {
                    acc[constructLocationKey(EntityTypeEnum.OFFENSE.name, id)] = true;
                    return acc;
                },
                {}
            ),
            // locations related to report
            [constructLocationKey(EntityTypeEnum.REPORT.name, reportId)]: true,
            // locations related to people
            // locations related to organizations
            ...reduce(
                nameReportLinks,
                (acc, { entityType, nameId }) => {
                    acc[constructLocationKey(entityType, nameId)] = true;
                    return acc;
                },
                {}
            ),
            ...(fieldContact
                ? {
                      [constructLocationKey(
                          EntityTypeEnum.FIELD_CONTACT.name,
                          fieldContact.id
                      )]: true,
                  }
                : {}),
            ...(behavioralCrisis
                ? {
                      [constructLocationKey(
                          EntityTypeEnum.BEHAVIORAL_CRISIS.name,
                          behavioralCrisis.id
                      )]: true,
                  }
                : {}),
            ...(towVehicle
                ? {
                      [constructLocationKey(
                          EntityTypeEnum.RMS_TOW_VEHICLE.name,
                          towVehicle.id
                      )]: true,
                  }
                : {}),
            ...(citation
                ? {
                      [constructLocationKey(EntityTypeEnum.CITATION.name, citation.id)]: true,
                  }
                : {}),
            ...reduce(
                itemProfiles,
                (acc, { id }) => {
                    acc[constructLocationKey(EntityTypeEnum.ITEM_PROFILE.name, id)] = true;
                    return acc;
                },
                {}
            ),
            ...reduce(
                useOfForces,
                (acc, { id }) => {
                    acc[constructLocationKey(EntityTypeEnum.USE_OF_FORCE.name, id)] = true;
                    return acc;
                },
                {}
            ),
        };

        const locationEntityLinks = locationEntityLinksWhere(
            ({ entityId, entityType }) => parentLocationKeys[`${entityType}-${entityId}`]
        );

        return groupBy(
            ignoreNoFixed
                ? reject(locationEntityLinks, { locationId: unknownLocationId })
                : locationEntityLinks,
            'locationId'
        );
    }
);

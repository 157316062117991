import { UserProfileView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'userProfiles';
// TODO PRINTING-REFACTOR de-dupe module code in RMS
const userProfileModule = createNormalizedModule<UserProfileView>({
    type: NEXUS_STATE_PROP,
    key: 'userId',
});

// ACTIONS
export const storeUserProfiles = userProfileModule.actionCreators.storeEntities;

// SELECTORS
export const userProfilesByIdSelector = userProfileModule.selectors.entityByIdSelector;

// REDUCER
export default userProfileModule.reducerConfig;

import { CodeTypeCategoryEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { filter, map, includes } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { compose, withState, withHandlers } from 'recompose';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import { codesSelector } from '~/client-common/core/domain/codes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFCodeSelect } from '../../../core/forms/components/selects/CodeSelect';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import { RRFText } from '../../../core/forms/components/Text';
import { Button, ButtonGroup } from '../../../core/components/Button';
import FormGroup from '../../../core/forms/components/FormGroup';
import { DashedDivider as _DashedDivider } from '../../../../legacy-redux/components/core/Divider';

import { prefillUcrProperty } from '../state/ui';
import {
    currentReportPropertyStatusOptionsSelector,
    currentUcrSourceSelector,
} from '../state/ui/selectors';
import testIds from '../../../../core/testIds';
import GreyHeaderRRFFieldset from './GreyHeaderRRFFieldset';

const strings = componentStrings.reports.UcrClassificationSidePanel.UcrPropertyFieldset;

const DashedDivider = styled(_DashedDivider)`
    margin-top: 5px;
    margin-bottom: 0;
`;

const DeleteButtonDiv = styled.div`
    & > .mark43-react-form-row-icon-link {
        margin-top: 0;
        margin-left: 15px;
    }
`;

const mapStateToProps = createStructuredSelector({
    currentReportPropertyStatusOptions: currentReportPropertyStatusOptionsSelector,
    currentUcrSource: currentUcrSourceSelector,
    codes: codesSelector,
});

function mapDispatchToProps(dispatch) {
    return {
        onSelectItem: (ucrPropertyIndex, propertyStatusId) =>
            dispatch(prefillUcrProperty(ucrPropertyIndex, propertyStatusId)),
    };
}

const ignoreCodes = ['N'];

export default compose(
    withState('fromReport', 'setFromReport', null),
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        setIsFromReport: ({ setFromReport }) => () => setFromReport(true),
        setNotFromReport: ({ setFromReport }) => () => setFromReport(false),
        prefillHandler: ({ ucrPropertyIndex, onSelectItem }) => (propertyStatusId) => {
            // clearing the dropdown fires an `onChange` event, causing
            // us to fire off a prefill request for a null id, which we don't
            // want.
            if (propertyStatusId) {
                onSelectItem(ucrPropertyIndex, propertyStatusId);
            }
        },
    })
)(function UcrPropertyFieldset({
    ucrPropertyIndex,
    deleteButton,
    formModelByPath,
    fromReport,
    setIsFromReport,
    setNotFromReport,
    prefillHandler,
    currentReportPropertyStatusOptions,
    currentUcrSource,
    codes,
}) {
    const currentPropertyStatusId = formModelByPath(
        `ucrProperty[${ucrPropertyIndex}].propertyStatusId`
    );
    const selectedPropertyIds = map(formModelByPath('ucrProperty') || [], 'propertyStatusId');
    // filter out the already selected property status ids unless the one selected is in this component
    // Note: this could become a performance issue when there are lots of options and in that case, this should be refactored to prevent constant filtering
    const filteredPropertyStatusOptions = filter(
        currentReportPropertyStatusOptions,
        ({ value }) => value === currentPropertyStatusId || !includes(selectedPropertyIds, value)
    );
    const sequenceNumber = ucrPropertyIndex + 1;
    const showItemSelect = fromReport || currentPropertyStatusId;
    const propertyCategoryCode =
        codes[formModelByPath(`ucrProperty[${ucrPropertyIndex}].propertyCategoryCodeId`)];
    const propertyStatusCode =
        codes[formModelByPath(`ucrProperty[${ucrPropertyIndex}].propertyStatusCodeId`)];
    // these codes are hard coded because they come from the UCR spec and should therefore not change
    const showVehicleField = propertyCategoryCode && propertyCategoryCode.code === '24';
    const showRecoveredFields = propertyStatusCode && propertyStatusCode.code === 'R';

    const newButtons = (
        <ButtonGroup>
            <Button
                isTextTransformNone
                testId={testIds.ITEM_FROM_CURRENT_REPORT_BUTTON}
                onClick={setIsFromReport}
                disabled={filteredPropertyStatusOptions.length === 0}
            >
                {strings.fromReport}
            </Button>
            <Button isTextTransformNone testId={testIds.NEW_ITEM_BUTTON} onClick={setNotFromReport}>
                {strings.newItem}
            </Button>
        </ButtonGroup>
    );

    const itemFields = (
        <>
            <RRFText
                path="additionalNotes"
                helpTextCollisionBoundary={document.querySelector('.mark43-react-side-panel')}
                helpText={strings.itemNotesHelpText}
            />
            <RRFCodeSelect
                path="propertyCategoryCodeId"
                codeSource={currentUcrSource}
                codeTypeCategory={CodeTypeCategoryEnum.UCR_PROPERTY_CATEGORY.name}
                length="md"
            />
            <RRFCodeSelect
                path="propertyStatusCodeId"
                codeSource={currentUcrSource}
                codeTypeCategory={CodeTypeCategoryEnum.UCR_PROPERTY_STATUS.name}
                ignoreCodes={ignoreCodes}
                length="md"
            />
            <RRFText path="nearestDollarValue" length="sm" />
            {showVehicleField && showRecoveredFields && (
                <RRFCodeSelect
                    path="vehicleRecoveryTypeCodeId"
                    codeSource={currentUcrSource}
                    codeTypeCategory={CodeTypeCategoryEnum.UCR_VEHICLE_RECOVERY_TYPE.name}
                    length="md"
                />
            )}
            {showRecoveredFields && (
                <RRFDatePicker
                    // vehicleRecoveryDateUtc is used for all property recovery as of 05-02-17
                    path="vehicleRecoveryDateUtc"
                />
            )}
        </>
    );

    const mainForm = (
        <FormGroup>
            {showItemSelect && (
                <RRFSelect
                    path="propertyStatusId"
                    label={strings.propertyStatusIdLabel}
                    options={filteredPropertyStatusOptions}
                    onChange={prefillHandler}
                />
            )}
            {itemFields}
            <DashedDivider />
        </FormGroup>
    );

    return (
        <GreyHeaderRRFFieldset title={strings.fieldsetHeader(sequenceNumber)}>
            <DeleteButtonDiv>{deleteButton}</DeleteButtonDiv>
            {formDataIsEmpty(formModelByPath(`ucrProperty[${ucrPropertyIndex}]`)) &&
            fromReport === null
                ? newButtons
                : mainForm}
        </GreyHeaderRRFFieldset>
    );
});

type FetchSVGState = {
    isLoading: boolean;
    svg?: string;
    svgDataUrl?: string;
    error?: string;
};

type FetchSVGAction =
    | {
          type: 'LOADING_START';
      }
    | {
          type: 'LOADING_SUCCESS';
          payload: {
              svg: string;
              svgDataUrl: string;
          };
      }
    | {
          type: 'LOADING_FAILURE';
          payload: {
              error: string;
          };
      }
    | {
          type: 'SET_SVG_DATA_URL';
          payload: {
              svgDataUrl: string;
          };
      };

export function fetchSVGFromUrlReducer(
    state: FetchSVGState,
    action: FetchSVGAction
): FetchSVGState {
    switch (action.type) {
        case 'LOADING_START':
            return {
                isLoading: true,
                svg: undefined,
                svgDataUrl: undefined,
                error: undefined,
            };
        case 'LOADING_SUCCESS':
            return {
                isLoading: false,
                svg: action.payload.svg,
                svgDataUrl: action.payload.svgDataUrl,
                error: undefined,
            };
        case 'LOADING_FAILURE':
            return {
                isLoading: false,
                svg: undefined,
                svgDataUrl: undefined,
                error: action.payload.error,
            };
        case 'SET_SVG_DATA_URL':
            return {
                ...state,
                svgDataUrl: action.payload.svgDataUrl,
                svg: undefined,
            };
        default:
            return state;
    }
}

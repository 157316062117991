import { find } from 'lodash';
import { Arrest, ArrestView } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getResource from '../../resources/arrestResource';
import getBookingResource from '../../resources/bookingResource';
import { storeChargeDispositionInfoFields } from '../../../charges/state/data';
import { storeWarrants } from '../../../warrants/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'arrests';

const arrestsModule = createNormalizedModule<Arrest>({
    type: NEXUS_STATE_PROP,
});

const UPDATE_ARREST_BOOKING_START = 'arrests/UPDATE_ARREST_BOOKING_START';
const UPDATE_ARREST_BOOKING_SUCCESS = 'arrests/UPDATE_ARREST_BOOKING_SUCCESS';
const UPDATE_ARREST_BOOKING_FAILURE = 'arrests/UPDATE_ARREST_BOOKING_FAILURE';
const UPDATE_ARREST_NIBRS_CODE_START = 'arrests/UPDATE_ARREST_NIBRS_CODE_START';
const UPDATE_ARREST_NIBRS_CODE_SUCCESS = 'arrests/UPDATE_ARREST_NIBRS_CODE_SUCCESS';
const UPDATE_ARREST_NIBRS_CODE_FAILURE = 'arrests/UPDATE_ARREST_NIBRS_CODE_FAILURE';
export const UPDATE_ARREST_DEFENDANT_ID = 'arrests/UPDATE_ARREST_DEFENDANT_ID';
const SEND_ARREST_TO_BOOKING_START = 'arrests/SEND_ARREST_TO_BOOKING_START';
const SEND_ARREST_TO_BOOKING_SUCCESS = 'arrests/SEND_ARREST_TO_BOOKING_SUCCESS';
const SEND_ARREST_TO_BOOKING_FAILURE = 'arrests/SEND_ARREST_TO_BOOKING_FAILURE';

function sendArrestToBookingStart() {
    return {
        type: SEND_ARREST_TO_BOOKING_START,
    };
}

function sendArrestToBookingSuccess(reportId: number) {
    return {
        type: SEND_ARREST_TO_BOOKING_SUCCESS,
        payload: reportId,
    };
}

function sendArrestToBookingFailure(message: string) {
    return {
        type: SEND_ARREST_TO_BOOKING_FAILURE,
        payload: message,
    };
}

export function sendArrestReportToBooking(reportId: number): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        const resource = getResource();
        dispatch(sendArrestToBookingStart());
        return resource
            .sendArrestToBooking(reportId)
            .then(() => {
                return dispatch(sendArrestToBookingSuccess(reportId));
            })
            .catch((err: Error) => {
                dispatch(sendArrestToBookingFailure(err.message));
                throw err;
            });
    };
}

function updateArrestBookingStart(arrestView: ArrestView) {
    return {
        type: UPDATE_ARREST_BOOKING_START,
        payload: arrestView,
    };
}

function updateArrestBookingSuccess(arrestView: ArrestView) {
    return {
        type: UPDATE_ARREST_BOOKING_SUCCESS,
        payload: arrestView,
    };
}

function updateArrestBookingFailure(errorMessage: string) {
    return {
        type: UPDATE_ARREST_BOOKING_FAILURE,
        payload: errorMessage,
    };
}

export function updateArrestBookingInfo(
    arrestView: ArrestView
): ClientCommonAction<Promise<ArrestView>> {
    return (dispatch, getState) => {
        const resource = getBookingResource();
        dispatch(updateArrestBookingStart(arrestView));
        return resource
            .updateArrestBookingInfo(arrestView)
            .then((updatedArrestView: ArrestView) => {
                const { arrest, charges, warrants } = updatedArrestView;
                const state = getState();
                const oldArrest = arrestsSelector(state)[arrest.id] as Arrest;

                const {
                    releasedByOfficerId,
                    lockupNumber,
                    afisNumber,
                    lockupLocation,
                    lockupDateUtc,
                    juvenileTriedAsAdult,
                    juvenileTriedAsAdultNotes,
                } = arrest as Arrest;

                dispatch(
                    storeArrests({
                        ...oldArrest,
                        releasedByOfficerId,
                        lockupNumber,
                        afisNumber,
                        lockupLocation,
                        lockupDateUtc,
                        juvenileTriedAsAdult,
                        juvenileTriedAsAdultNotes,
                    })
                );
                dispatch(storeChargeDispositionInfoFields(charges));
                dispatch(storeWarrants(warrants));
                dispatch(updateArrestBookingSuccess(arrestView));
                return arrestView;
            })
            .catch((err: Error) => {
                dispatch(updateArrestBookingFailure(err.message));
                throw err;
            });
    };
}

function updateArrestNibrsCodeStart(arrest: Arrest) {
    return {
        type: UPDATE_ARREST_NIBRS_CODE_START,
        payload: arrest,
    };
}

function updateArrestNibrsCodeSuccess(arrest: Arrest) {
    return {
        type: UPDATE_ARREST_NIBRS_CODE_SUCCESS,
        payload: arrest,
    };
}

function updateArrestNibrsCodeFailure(errorMessage: string) {
    return {
        type: UPDATE_ARREST_NIBRS_CODE_FAILURE,
        payload: errorMessage,
    };
}

export function updateArrestNibrsCode(arrest: Arrest): ClientCommonAction<Promise<Arrest>> {
    return (dispatch, getState) => {
        const resource = getResource();
        dispatch(updateArrestNibrsCodeStart(arrest));
        return resource
            .updateArrestNibrsCode(arrest)
            .then((updatedArrest: Arrest) => {
                const state = getState();
                const oldArrest = arrestsSelector(state)[updatedArrest.id] as Arrest;
                const { nibrsCode } = updatedArrest;

                const finalUpdatedArrest = {
                    ...oldArrest,
                    nibrsCode,
                };

                dispatch(storeArrests(finalUpdatedArrest));
                dispatch(updateArrestNibrsCodeSuccess(finalUpdatedArrest));
                return finalUpdatedArrest;
            })
            .catch((err: Error) => {
                dispatch(updateArrestNibrsCodeFailure(err.message));
            });
    };
}

// ACTIONS
const storeArrests = arrestsModule.actionCreators.storeEntities;

// SELECTORS
export const arrestsSelector = arrestsModule.selectors.entitiesSelector;
export const arrestByIdSelector = arrestsModule.selectors.entityByIdSelector;
export const arrestsWhereSelector = arrestsModule.selectors.entitiesWhereSelector;
export const arrestForReportIdSelector = createSelector(
    arrestsSelector,
    (arrests) => (reportId: number) => find(arrests, { reportId })
);

// REDUCER
export default arrestsModule.reducerConfig;

import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { EntityTypeEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

import Page from '../../core/components/Page';
import Subheader from '../../core/components/Subheader';
import ScrollableUnderSubheader from '../../core/components/ScrollableUnderSubheader';
import { clipsCurrentReportDataSelector } from '../state/ui';
import ClipsFooter from './ClipsFooter';
import ClipsReportPage from './ClipsReportPage';

// fallback back button paths
const entityBackPath = {
    [EntityTypeEnum.REPORT.name]: '/reports',
    [EntityTypeEnum.WARRANT.name]: '/warrants',
};

const strings = componentStrings.clips.ClipsDashboardContainer;

const ClipsDashboardContainer: React.FC<RouteComponentProps<never, never>> = ({
    params: { entityId: entityIdInt, entityType },
    router,
}) => {
    const entityId = parseInt(entityIdInt);
    const getClipsCurrentReportEntity = useSelector(clipsCurrentReportDataSelector);
    const reportEntities = getClipsCurrentReportEntity(entityId);

    return (
        <Page>
            <Subheader
                backButtonTo={`${entityBackPath[entityType]}/${entityId}`}
                onBackButtonClick={router.goBack}
                title={joinTruthyValues([strings.defaultTitle, reportEntities.title], ' ')}
            />
            <ScrollableUnderSubheader>
                <ClipsReportPage entityId={entityId} reportEntities={reportEntities} />
            </ScrollableUnderSubheader>
            <ClipsFooter
                navigateTo={`${entityBackPath[entityType]}/${entityId}`}
                entities={reportEntities}
            />
        </Page>
    );
};

export default withRouter(ClipsDashboardContainer);

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { withProps } from 'recompose';

import { reduce, values, parseInt, sum, mapValues } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';
import Page from '../../core/components/Page';
import Subheader from '../../core/components/Subheader';
import UnderSubheader from '../../core/components/UnderSubheader';
import { LEFT_VALUE, RIGHT_VALUE } from '../configuration/constants';
import personProfileMergeableFields from '../configuration/personProfileMergeableFields';
import organizationProfileMergeableFields from '../configuration/organizationProfileMergeableFields';
import vehicleProfileMergeableFields from '../configuration/vehicleProfileMergeableFields';
import { InlineBanner } from '../../core/components/InlineBanner';
import {
    isLoadingSelector,
    errorSelector,
    resetMergeEntitiesState,
    profilesLoadedSelector,
    initiateEntityMerge,
    entityTypeToMergeSelector,
    entityIdsToMergeSelector,
    profilesToMergeSelector,
} from '../state/ui';
import FlexContainer from './core/FlexContainer';
import Column from './core/Column';
import ProfileHeaderRow from './ProfileHeaderRow';
import SelectAllHeaderRow from './SelectAllHeaderRow';
import PersonProfilePropertyList from './PersonProfilePropertyList';
import OrganizationProfilePropertyList from './OrganizationProfilePropertyList';
import vehicleProfilePropertyList from './vehicleProfilePropertyList';
import MergeActionBar from './MergeActionBar';

const { PERSON_PROFILE, ORGANIZATION_PROFILE, VEHICLE } = EntityTypeEnum;

const FlexUnderSubheader = styled(UnderSubheader)`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

class MergeEntitiesDashboard extends React.Component {
    constructor(...args) {
        super(...args);

        let fieldConfig;
        switch (this.props.entityType) {
            case PERSON_PROFILE.name:
                fieldConfig = personProfileMergeableFields;
                break;
            case ORGANIZATION_PROFILE.name:
                fieldConfig = organizationProfileMergeableFields;
                break;
            case VEHICLE.name:
                fieldConfig = vehicleProfileMergeableFields;
                break;
            default:
                throw new Error(
                    'Unsupported merging type, not of type Persons, Organizations, or Vehicles.'
                );
        }
        // default all values to those of the model on the left hand side
        this.state = {
            selectedValues: reduce(
                fieldConfig,
                (acc, fieldConfig) => {
                    acc[fieldConfig.prop] = LEFT_VALUE;
                    return acc;
                },
                {}
            ),
        };
    }

    componentWillUnmount() {
        this.props.resetMergeEntitiesState();
    }

    setAllSelectedValues(newValue) {
        this.setState({
            selectedValues: mapValues(this.state.selectedValues, () => newValue),
        });
    }

    selectAllLeftValues = () => this.setAllSelectedValues(LEFT_VALUE);

    selectAllRightValues = () => this.setAllSelectedValues(RIGHT_VALUE);

    handleValueChange = (prop, value) =>
        this.setState({
            selectedValues: {
                ...this.state.selectedValues,
                [prop]: parseInt(value),
            },
        });

    handleMergeClick = () => {
        const { selectedValues } = this.state;
        this.props.initiateEntityMerge({ selectedValues });
    };

    render() {
        const { isLoading, error, profilesToMerge, entityType, applicationSettings } = this.props;
        const allValues = values(this.state.selectedValues);
        const sumOfSelectedValues = sum(allValues);
        const allLeftSelected = sumOfSelectedValues === 0;
        const allRightSelected = sumOfSelectedValues === allValues.length;
        let PropertyListComponent = [];
        let backUrl = '';
        switch (entityType) {
            case PERSON_PROFILE.name:
                PropertyListComponent = PersonProfilePropertyList;
                backUrl = '/search/persons';
                break;
            case ORGANIZATION_PROFILE.name:
                PropertyListComponent = OrganizationProfilePropertyList;
                backUrl = '/search/organizations';
                break;
            case VEHICLE.name:
                PropertyListComponent = vehicleProfilePropertyList;
                backUrl = '/search/vehicles';
                break;
            default:
                throw new Error(
                    'Unsupported merging type, not of type Persons, Organizations, or Vehicles - line 132'
                );
        }

        return (
            <Page>
                <Subheader
                    title={componentStrings.mergeEntities.MergeEntitiesDashboard.title}
                    backButtonTo={backUrl}
                />
                {isLoading && (
                    <FlexUnderSubheader forceNoClassName={true}>
                        <SimpleLoading />
                    </FlexUnderSubheader>
                )}
                {!isLoading && error && (
                    <FlexUnderSubheader forceNoClassName={true}>
                        <FlexContainer
                            flexGrow={0}
                            flexBasis="auto"
                            direction="row"
                            align="center"
                            justify="center"
                        >
                            <Column width="50%">
                                <InlineBanner status="error">{error.message}</InlineBanner>
                            </Column>
                        </FlexContainer>
                    </FlexUnderSubheader>
                )}
                {!isLoading && !error && profilesToMerge && !!profilesToMerge.length && (
                    <FlexUnderSubheader forceNoClassName={true}>
                        <ProfileHeaderRow models={profilesToMerge} entityType={entityType} />
                        <SelectAllHeaderRow
                            onLeftCheckboxChange={this.selectAllLeftValues}
                            onRightCheckboxChange={this.selectAllRightValues}
                            allLeftSelected={allLeftSelected}
                            allRightSelected={allRightSelected}
                        />
                        <PropertyListComponent
                            selectedValues={this.state.selectedValues}
                            onValueChange={this.handleValueChange}
                            models={profilesToMerge}
                            applicationSettings={applicationSettings}
                        />
                    </FlexUnderSubheader>
                )}
                <MergeActionBar onMergeClick={this.handleMergeClick} backUrl={backUrl} />
            </Page>
        );
    }
}

export default connect(
    createStructuredSelector({
        profilesToMerge: profilesToMergeSelector,
        isLoading: isLoadingSelector,
        profilesLoaded: profilesLoadedSelector,
        entityTypeToMerge: entityTypeToMergeSelector,
        entityIdsToMerge: entityIdsToMergeSelector,
        error: errorSelector,
        applicationSettings: applicationSettingsSelector,
    }),
    { resetMergeEntitiesState, initiateEntityMerge }
)(
    withProps(({ location }) => {
        // using query params here because we need to know whether or not
        // we have a person merge as soon as the component is mounted.
        // this information gets stored in state as well so it is accessible
        // in modals and action creators where we do not want to rely on
        // url state. In this case those values would be set too late and
        // would lead to inconsistent behavior when determining the merge type.
        const { entityType } = location.query;
        return { entityType };
    })(MergeEntitiesDashboard)
);

import { CodeTypeSourceEnum } from '@mark43/rms-api';
import { get, find } from 'lodash';

import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '../../../attributes/state/data';
import { attributeCodesWhereSelector } from '../../../attribute-codes/state/data';
import { codesByCodeIdSelector } from '../../../codes/state/data';
import getVehicleModelsResource from '../../resources/vehicleModelsResource';

export const NEXUS_STATE_PROP = 'vehicleModels';
const vehicleModelsModule = createNormalizedModule({ type: NEXUS_STATE_PROP });

// ACTIONS
export const storeVehicleModels = vehicleModelsModule.actionCreators.storeEntities;

// SELECTORS
export const vehicleModelsSelector = vehicleModelsModule.selectors.entitiesSelector;

const vehicleModelByIdSelector = vehicleModelsModule.selectors.entityByIdSelector;

export const vehicleModelNameByModelIdSelector = createSelector(
    vehicleModelByIdSelector,
    (vehicleModelById) => (id) => vehicleModelById(id)?.modelName
);

// REDUCER
export default vehicleModelsModule.reducerConfig;

const LOAD_VEHICLE_MODEL_DETAILS_SUCCESS = 'vehicle-models/LOAD_VEHICLE_MODEL_DETAILS_SUCCESS';

function loadVehicleModelDetailsSuccess(result) {
    return { type: LOAD_VEHICLE_MODEL_DETAILS_SUCCESS, payload: result };
}

export function searchForVehicleModels({
    q,
    vehicleMakeId,
    vehicleTypeCodeId,
    vehicleBodyStyleCodeId,
    yearOfManufacture,
    from,
    size,
}) {
    const vehicleModelsResource = getVehicleModelsResource();

    return () =>
        vehicleModelsResource.searchForVehicleModels({
            q,
            vehicleMakeId,
            vehicleTypeCodeId,
            vehicleBodyStyleCodeId,
            yearOfManufacture,
            from,
            size,
        });
}

export function loadVehicleModelDetails(modelIds) {
    const vehicleModelsResource = getVehicleModelsResource();

    return (dispatch, getState, dependencies) =>
        vehicleModelsResource.getVehicleModelDetails(modelIds).then((modelDetails) => {
            dispatch(
                dependencies.nexus.withEntityItems(
                    { [ATTRIBUTES_NEXUS_STATE_PROP]: modelDetails.attributes },
                    loadVehicleModelDetailsSuccess(modelDetails)
                )
            );

            return modelDetails;
        });
}

/**
 * Vehicle Make/Model search endpoints accept vehicleTypeCodeId as filter criteria.
 *   For any selected itemCategoryAttrId, find VEHICLE code. Returns undefined if none found.
 *   Any attribute can have only one code for each type.
 * @param  {number} itemCategoryAttrId
 * @return {number|undefined}
 */
export const vehicleTypeCodeIdForItemCategoryAttrIdSelector = createSelector(
    attributeCodesWhereSelector,
    codesByCodeIdSelector,
    (attributeCodesWhere, codesByCodeId) => (itemCategoryAttrId) => {
        const attributeCodes = attributeCodesWhere({ attributeId: itemCategoryAttrId });
        const vehicleTypeCode = find(
            attributeCodes,
            ({ codeId }) =>
                get(codesByCodeId(codeId), 'codeType.source') === CodeTypeSourceEnum.VEHICLE.name
        );

        return get(vehicleTypeCode, 'codeId');
    }
);

import { LinkTypesEnum, LinkTypesEnumType } from '@mark43/rms-api';
import { keyBy, map, mapValues } from 'lodash';
import { Field } from '../core/fields/state/config';
import * as fields from '../core/enums/universal/fields';

export type ItemLocationType = {
    type: LinkTypesEnumType;
    field: Field;
};

export const vehicleLocationsTypes: ItemLocationType[] = [
    {
        type: LinkTypesEnum.VEHICLE_ABANDONED_LOCATION,
        field: fields.DISPLAY_ONLY_VEHICLE_ABANDONED_LOCATION,
    },
    {
        type: LinkTypesEnum.VEHICLE_BURNED_LOCATION,
        field: fields.DISPLAY_ONLY_VEHICLE_BURNED_LOCATION,
    },
    {
        type: LinkTypesEnum.VEHICLE_REGISTERED_LOCATION,
        field: fields.DISPLAY_ONLY_VEHICLE_REGISTRATION_LOCATION,
    },
    { type: LinkTypesEnum.VEHICLE_SEEN_LOCATION, field: fields.DISPLAY_ONLY_VEHICLE_SEEN_LOCATION },
    {
        type: LinkTypesEnum.VEHICLE_STOLEN_FROM_LOCATION,
        field: fields.DISPLAY_ONLY_VEHICLE_STOLEN_FROM_LOCATION,
    },
];

export const allItemLocationTypes = map(
    vehicleLocationsTypes,
    (itemLocationType) => itemLocationType.type
);

export const fieldByItemLocationType = mapValues(keyBy(vehicleLocationsTypes, 'type'), 'field');

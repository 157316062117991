// Bulk import this in order to properly annotate the types in this file
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Resources, { LocationSourceEnum } from '@mark43/rms-api';
import React from 'react';
import { map } from 'lodash';
import { Text, cssVar, Icon as ArcIcon } from 'arc';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { locationNameFormatsForSearch } from '~/client-common/core/domain/locations/utils/locationHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import Pill from '../../components/Pill';
import _Icon, { iconTypes, iconSizes } from '../../components/Icon';

const StyledPill = styled(Pill)`
    ${(props) =>
        props.mode === 'condensed'
            ? `
            padding: 4px 10px;
            border-width: 0px;
            &:hover, &:focus {
                background-color: ${cssVar('arc.colors.surface.accent')};
            }
    `
            : undefined}
`;

const LocationInfo = styled.div`
    flex: 1;
    padding-left: 10px;
`;

const PlaceName = styled(Text)`
    display: flex;
    font-size: ${cssVar('arc.fontSizes.sm')};
    font-weight: ${cssVar('arc.fontWeights.semibold')};
    margin-right: 6px;
`;

const PlaceNameWrapper = styled(Text)`
    display: flex;
    font-size: ${cssVar('arc.fontSizes.sm')};
    font-weight: ${cssVar('arc.fontWeights.semibold')};
    align-items: baseline;
`;

const Row = styled(Text)`
    display: flex;
    font-size: ${cssVar('arc.fontSizes.sm')};
    line-height: ${cssVar('arc.lineHeights.short')};
`;

const VerifiedLocationWrapper = styled(Text)`
    display: flex;
    padding-top: ${(props) => (props.mode === 'condensed' ? '0px' : '10px;')}
    text-transform: uppercase;
    line-height: 12px;
    color: ${(props) => props.theme.colors.darkGreen};
    font-size: ${cssVar('arc.fontSizes.sm')};
`;

const VerifiedLocationIcon = styled(_Icon)`
    padding-right: 5px;
`;

const StyledSubtitle = styled(Text)`
    margin-top: 12px;
`;

/**
 * Render a location
 *
 * @typedef Props
 * @prop {Resources.ConsolidatedLocationView} location
 * @prop {string} [className]
 * @prop {function} [onClick]
 * @prop {'condensed'} [mode]
 * @prop {string} [testId]
 *
 * @param {Props} props
 */
const LocationPill = ({ location, className, subtitle, disabled, onClick, testId, mode }) => {
    const handleClick = () => onClick?.(location);

    const isSubPremiseSupportEnabled = useSelector(applicationSettingsSelector)
        .SUBPREMISE_SUPPORT_ENABLED;

    const { sourceText, lines, placeName } = locationNameFormatsForSearch({
        elasticLocation: location,
        isSubPremiseSupportEnabled,
    });

    let verifiedIconType = iconTypes.VERIFIED_LOCATION;
    if (
        location.source === LocationSourceEnum.GOOGLE.name ||
        location.source === LocationSourceEnum.ESRI.name ||
        location.source === LocationSourceEnum.POSTCODER.name
    ) {
        verifiedIconType = iconTypes.GOOGLE_VERIFIED_LOCATION;
    }

    return (
        <StyledPill
            disabled={disabled}
            className={className}
            onClick={handleClick}
            hoverable={true}
            showChevron={mode !== 'condensed'}
            testId={testId}
            mode={mode}
        >
            <ArcIcon
                style={{ marginTop: '2px' }}
                color={mode === 'condensed' ? 'brand.default' : 'icon.default'}
                icon="Location"
                size={'sm'}
            />
            <LocationInfo>
                <FeatureFlagged
                    flag="SUBPREMISE_SUPPORT_ENABLED"
                    fallback={
                        <>
                            {placeName && <PlaceName as="span">{placeName}</PlaceName>}
                            {map(lines, (line) => (
                                <Row key={line}>{line}</Row>
                            ))}
                            {!!sourceText && (
                                <VerifiedLocationWrapper>
                                    <VerifiedLocationIcon
                                        color="darkGreen"
                                        type={verifiedIconType}
                                        size={iconSizes.SMALL}
                                    />
                                    {sourceText}
                                </VerifiedLocationWrapper>
                            )}
                        </>
                    }
                >
                    <PlaceNameWrapper>
                        {placeName && <PlaceName as="span">{placeName}</PlaceName>}
                        {!!sourceText && (
                            <VerifiedLocationWrapper>
                                <VerifiedLocationIcon
                                    color="darkGreen"
                                    type={verifiedIconType}
                                    size={iconSizes.SMALL}
                                />
                                {sourceText}
                            </VerifiedLocationWrapper>
                        )}
                    </PlaceNameWrapper>
                    {map(lines, (line) => (
                        <Row key={line}>{line}</Row>
                    ))}
                </FeatureFlagged>
                {subtitle && (
                    <StyledSubtitle variant="bodyMd" color="secondary" isItalic>
                        {subtitle}
                    </StyledSubtitle>
                )}
            </LocationInfo>
        </StyledPill>
    );
};

export default LocationPill;

import getAdminConsortiumResource from '../../resources/AdminConsortiumResource';
import { storeConsortiumDepartmentLinks } from '../../../consortium-link-view/state/data';
import { storeRoles } from '../../../roles/state/data';

const LOAD_CONSORTIUM_DATA_START = 'full-consortium-view/LOAD_CONSORTIUM_DATA_START';
const LOAD_CONSORTIUM_DATA_SUCCESS = 'full-consortium-view/LOAD_CONSORTIUM_DATA_SUCCESS';
const LOAD_CONSORTIUM_DATA_FAILURE = 'full-consortium-view/LOAD_CONSORTIUM_DATA_FAILURE';

function loadConsortiumDataStart() {
    return {
        type: LOAD_CONSORTIUM_DATA_START,
    };
}

function loadConsortiumDataSuccess() {
    return {
        type: LOAD_CONSORTIUM_DATA_SUCCESS,
    };
}

function loadConsortiumDataFailure(errMsg) {
    return {
        type: LOAD_CONSORTIUM_DATA_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function loadConsortiumData() {
    return function (dispatch) {
        dispatch(loadConsortiumDataStart());

        return getAdminConsortiumResource()
            .getConsortiumDetails()
            .then((data) => {
                dispatch(storeConsortiumDepartmentLinks(data.consortiumLinks));
                dispatch(storeRoles(data.externalRoles));
                dispatch(loadConsortiumDataSuccess());
            })
            .catch((err) => {
                dispatch(loadConsortiumDataFailure(err.message));
                throw err;
            });
    };
}

// shim for object.create
if (typeof Object.create !== 'function') {
    Object.create = function (o) {
        function F() {}
        F.prototype = o;
        return new F();
    };
}

const CODES = {
    NETWORK_ERROR: 0,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    REQUEST_TIMEOUT: 408,
    CONFLICT_ERROR: 409,
    GONE: 410,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500,
    UNKNOWN_ERROR: 999,
};

function UnauthorizedError(message) {
    this.name = 'UnauthorizedError';
    this.code = CODES.UNAUTHORIZED;
    this.message = message || 'Unauthorized Access.';
}
UnauthorizedError.prototype = Object.create(Error.prototype);
UnauthorizedError.prototype.constructor = UnauthorizedError;

function InsufficientPermissionsError(message) {
    this.name = 'ForbiddenError';
    this.code = CODES.FORBIDDEN;
    this.message = message || 'Forbidden.';
}
InsufficientPermissionsError.prototype = Object.create(Error.prototype);
InsufficientPermissionsError.prototype.constructor = InsufficientPermissionsError;

function NotFoundError(message) {
    this.name = 'NotFoundError';
    this.code = CODES.NOT_FOUND;
    this.message = message || 'Not Found.';
}
NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.constructor = NotFoundError;

function GoneError(message, response) {
    this.name = 'GoneError';
    this.code = CODES.GONE;
    this.message = message || 'Gone.';
    this.response = response || message;
}
GoneError.prototype = Object.create(Error.prototype);
GoneError.prototype.constructor = GoneError;

function InternalServerError(message) {
    this.name = 'InternalServerError';
    this.code = CODES.INTERNAL_SERVER_ERROR;
    this.message = message || 'Internal Server Error.';
}
InternalServerError.prototype = Object.create(Error.prototype);
InternalServerError.prototype.constructor = InternalServerError;

function UnprocessableEntityError(message) {
    this.name = 'UnprocessableEntityError';
    this.code = CODES.UNPROCESSABLE_ENTITY;
    this.message = message || 'Unprocessable Entity.';
}
UnprocessableEntityError.prototype = Object.create(Error.prototype);
UnprocessableEntityError.prototype.constructor = UnprocessableEntityError;

function NetworkError(message) {
    this.name = 'NetworkError';
    this.code = CODES.NETWORK_ERROR;
    this.message = message || 'Network Error.';
}
NetworkError.prototype = Object.create(Error.prototype);
NetworkError.prototype.constructor = NetworkError;

function UnknownError(message) {
    this.name = 'UnknownError';
    this.code = CODES.UNKNOWN_ERROR;
    this.message = message || 'Unknown Error.';
}
UnknownError.prototype = Object.create(Error.prototype);
UnknownError.prototype.constructor = UnknownError;

function RequestTimeoutError(message) {
    this.name = 'RequestTimeoutError';
    this.code = CODES.REQUEST_TIMEOUT;
    this.message = message || 'Request Timeout Error.';
}
RequestTimeoutError.prototype = Object.create(Error.prototype);
RequestTimeoutError.prototype.constructor = RequestTimeoutError;

function ConflictError(message) {
    this.name = 'ConflictError';
    this.code = CODES.CONFLICT_ERROR;
    this.message = message || 'Conflict Error.';
}
ConflictError.prototype = Object.create(Error.prototype);
ConflictError.prototype.constructor = ConflictError;

function Mark43Error(message) {
    this.name = 'Mark43Error';
    this.code = 4343;
    this.message = message || 'Application Error';
}
Mark43Error.prototype = Object.create(Error.prototype);
Mark43Error.prototype.constructor = Mark43Error;

function FeatureFlagDisabledError(message) {
    this.name = 'FeatureFlagDisabledError';
    this.code = null; // not an http error, this is a client side check
    this.message = message || 'Feature Flag is disabled.';
}
FeatureFlagDisabledError.prototype = Object.create(Error.prototype);
FeatureFlagDisabledError.prototype.constructor = FeatureFlagDisabledError;

// eslint-disable-next-line import/no-commonjs
module.exports = exports = {
    UnauthorizedError,
    InsufficientPermissionsError,
    NotFoundError,
    GoneError,
    UnprocessableEntityError,
    InternalServerError,
    NetworkError,
    ConflictError,
    RequestTimeoutError,
    UnknownError,
    Mark43Error,
    FeatureFlagDisabledError,
    ERROR_CODES: CODES,
};

import React from 'react';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import { submitAssignUserRoleSidePanel, closeAssignUserRoleSidePanel } from '../state/ui';
import AssignUserRoleForm from './AssignUserRoleForm';

const strings = componentStrings.admin.roles.RoleUsersActions.AssignUserRoleSidePanel;
const context = { name: boxEnum.ADMIN_ASSIGN_USERROLE_SIDE_PANEL };

function AssignUserRoleSidePanel({ saveSidePanel, closeSidePanel }) {
    return (
        <SidePanel
            context={context}
            title={strings.title}
            onSave={saveSidePanel}
            onCancel={closeSidePanel}
            width={475}
        >
            <AssignUserRoleForm />
        </SidePanel>
    );
}

export default connect(null, {
    saveSidePanel: submitAssignUserRoleSidePanel,
    cancelSidePanel: closeAssignUserRoleSidePanel,
})(AssignUserRoleSidePanel);

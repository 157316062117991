import * as Sentry from '@sentry/browser';
// Redux
import { compose, createStore, applyMiddleware } from 'redux';

// Reducer
import { Analytics, adapters } from 'analytics-mark43';
import {
    withEntityItems,
    withRemove,
    withRemoveMultiple,
} from '~/client-common/core/utils/nexusHelpers';
import rootReducer from '../legacy-redux/reducers/rootReducer';

// Middlewares
import { req } from '../lib/ajax';
import { publish } from '../modules/dragon/events/broadcast';
import { getMiddlewares } from './middlewares';

import { overlayStore } from './overlayManager';
import formsRegistry from './formsRegistry';

const analytics = new Analytics({ requestAgent: req, adapter: adapters.reqAdapter });

// If the redux devtools extension is installed, use a method provided by it. Otherwise,
// use the regular `compose` provided by redux
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(
            ...getMiddlewares({
                dependencies: {
                    overlayStore,
                    formsRegistry,
                    nexus: { withEntityItems, withRemove, withRemoveMultiple },
                    sentry: Sentry,
                    analytics,
                    dragonEvents: {
                        publish,
                    },
                },
            })
        )
    )
);

export default store;

import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import testIds from '../../../../core/testIds';
import { Button } from '../../../core/components/Button';
import { openCaseProfilesSidePanel } from '../state/ui/caseLinkedProfilesSidePanel';

const strings = componentStrings.cases.linkedProfiles.actions;

const ButtonContainer = styled.div`
    display: flex;
    padding-bottom: 10px;
    justify-content: flex-end;
`;

const CaseLinkedProfilesHeaderButtons: FC = () => {
    const dispatch = useDispatch();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isTargetProfileEnabled = !!applicationSettings.RMS_TARGET_PROFILES_ENABLED;
    const isCaseEntityLinksEnabled = !!applicationSettings.RMS_CASE_ENTITY_LINKS_ENABLED;

    if (!isTargetProfileEnabled && !isCaseEntityLinksEnabled) {
        return null;
    }

    const leftIcon = isCaseEntityLinksEnabled ? 'Add' : 'Edit';
    const buttonText = isCaseEntityLinksEnabled ? strings.addEditProfile : strings.editProfile;

    return (
        <ButtonContainer>
            <Button
                leftIcon={leftIcon}
                variant="solid"
                isTextTransformNone
                testId={testIds.CASE_ADD_LINKED_PROFILE}
                onClick={() => {
                    dispatch(openCaseProfilesSidePanel());
                }}
            >
                {buttonText}
            </Button>
        </ButtonContainer>
    );
};

export default CaseLinkedProfilesHeaderButtons;

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import createFormModule from '../../../../core/forms/lib/createFormModule';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

export default createFormModule({
    formName: formClientEnum.SUPPORT_SEARCH_SYNC_MULTI,
    fieldViewModels: buildFlatFormFieldViewModels([
        'rmsEntityTypes',
        'cadEntityTypes',
        'evidenceEntityTypes',
        'departments',
        'startDateTime',
    ]),
    convertFromFormModel({
        rmsEntityTypes,
        cadEntityTypes,
        evidenceEntityTypes,
        departments,
        startDateTime,
    } = {}) {
        return {
            entityTypesByModule: {
                RMS: rmsEntityTypes,
                CAD: cadEntityTypes,
                EVIDENCE: evidenceEntityTypes,
            },
            departments,
            startDateTime,
        };
    },
});

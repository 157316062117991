import { ElasticWarrant } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'elasticWarrants';
const elasticWarrantsModule = createNormalizedModule<ElasticWarrant>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const elasticWarrantsSelector = elasticWarrantsModule.selectors.entitiesSelector;
export const elasticWarrantByIdSelector = elasticWarrantsModule.selectors.entityByIdSelector;
export const elasticWarrantsWhereSelector = elasticWarrantsModule.selectors.entitiesWhereSelector;

// REDUCER
export default elasticWarrantsModule.reducerConfig;

import React from 'react';
import styled from 'styled-components';
import { cssVar } from 'arc';
import HelpText from '../../../../legacy-redux/components/core/HelpText';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../Icon';
import { SubsectionTitleText } from './Typography';

const SecondarySectionHeaderWrapper = styled.div`
    border-bottom: 1px solid ${cssVar('arc.colors.border.default')};
    margin-bottom: ${cssVar('arc.space.4')};
    line-height: 1.5;
`;

/**  Precomposed header that uses SubsectionTitleText and provides a border. Options for HelpText and Edit button. */
export const SecondarySectionHeader: React.FC<{
    className?: string;
    helpText?: string;
    onEditClick?: () => void;
}> = React.forwardRef(({ className, helpText, children, onEditClick, ...rest }, ref) => {
    return (
        <SecondarySectionHeaderWrapper>
            <SubsectionTitleText lineHeight="base" className={className} {...rest}>
                {children}
                {helpText && <HelpText content={helpText} />}
                {onEditClick && (
                    <Button
                        onClick={onEditClick}
                        float="right"
                        className={buttonTypes.ICON_LINK}
                        iconLeft={iconTypes.EDIT}
                        ref={ref}
                    >
                        Edit
                    </Button>
                )}
            </SubsectionTitleText>
        </SecondarySectionHeaderWrapper>
    );
});

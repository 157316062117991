import {
    DeliveryMethodEnum,
    EntityTypeEnum,
    NotificationStatusEnum,
} from '@mark43/notifications-api';
import { get, map, omit, random } from 'lodash';

import notificationTypeEnum from '../../../enums/universal/notificationTypeEnum';
import { savedSearchRouteMap } from '../../../../configs/savedSearchConfig';

const { EMAIL, WEB_APP } = DeliveryMethodEnum;

/**
 * View model mapper for a UserNotification data model.
 * @param  {Object} userNotification
 * @return {Object}
 */
export function userNotificationMapper(userNotification) {
    const { notificationType, dataBlob } = userNotification;
    const { elasticSearchType, savedSearchId } = dataBlob;

    const notificationCategory = get(notificationTypeEnum[notificationType], 'category');

    let routePath;

    switch (notificationType) {
        case notificationTypeEnum.EVIDENCE_ITEM_OVERDUE_USER.name:
        case notificationTypeEnum.EVIDENCE_ITEM_OVERDUE_CLERK.name:
        case notificationTypeEnum.EVIDENCE_REVIEW_ITEM_FOR_DISPOSITION.name:
        case notificationTypeEnum.EVIDENCE_ITEM_APPROVED_FOR_DISPOSITION.name:
        case notificationTypeEnum.EVIDENCE_ITEM_APPROVED_FOR_RELEASE.name:
            routePath = `/profiles/property/${dataBlob.masterItemId}`;
            break;
        case notificationTypeEnum.TEST.name:
            routePath = '/notifications';
            break;
        case notificationTypeEnum.REPORT_RETURNED_TO_DRAFT_AFTER_SECONDARY_APPROVAL.name:
        case notificationTypeEnum.REPORT_APPROVED.name:
        case notificationTypeEnum.REPORT_REJECTED.name:
        case notificationTypeEnum.REPORT_REJECTED_BY_SECONDARY.name:
        case notificationTypeEnum.REPORT_APPROVAL_REQUESTED.name:
        case notificationTypeEnum.REPORT_IN_CASE_RETURNED_TO_DRAFT.name:
        case notificationTypeEnum.REPORT_IN_CASE_APPROVED.name:
        case notificationTypeEnum.REPORT_IN_CASE_REJECTED.name:
        case notificationTypeEnum.REPORT_ADDED_TO_CASE.name:
        case notificationTypeEnum.REPORT_REMOVED_FROM_CASE.name:
        case notificationTypeEnum.REPORT_REN_UPDATED.name:
        case notificationTypeEnum.REPORT_COMMENT_CREATED.name:
        case notificationTypeEnum.REPORT_PERMISSION_MODIFIED.name:
        case notificationTypeEnum.REPORT_AUTHOR_ADDED.name:
        case notificationTypeEnum.REPORT_OWNER_MODIFIED.name:
            routePath = `/reports/${dataBlob.reportId}`;
            break;
        case notificationTypeEnum.ASSOCIATED_RECORD_ADDED_TO_REPORT.name:
        case notificationTypeEnum.SUGGESTED_ASSOCIATED_RECORD_ADDED_TO_REPORT.name:
            routePath = `/reports/${dataBlob.associatedToReportId}`;
            break;
        case notificationTypeEnum.USER_CASE_ASSIGNMENT.name:
        case notificationTypeEnum.USER_CASE_UNASSIGNMENT.name:
        case notificationTypeEnum.USER_CASE_ASSIGNMENT_UPDATED_SUPERVISOR.name:
        case notificationTypeEnum.UNIT_CASE_ASSIGNMENT.name:
        case notificationTypeEnum.CASE_NOTE_ADDED.name:
        case notificationTypeEnum.CASE_NOTE_DELETED.name:
        case notificationTypeEnum.CASE_STATUS_CHANGED.name:
        case notificationTypeEnum.CASE_REVIEW_REQUESTED.name:
        case notificationTypeEnum.CASE_REVIEWED.name:
        case notificationTypeEnum.CASE_APPROVED.name:
        case notificationTypeEnum.CASE_REJECTED.name:
            routePath = `/cases/${dataBlob.caseId}/summary`;
            break;
        case notificationTypeEnum.USER_TASK_ASSIGNMENT.name:
        case notificationTypeEnum.TASK_STATUS_CHANGED.name:
            switch (dataBlob.ownerType) {
                case EntityTypeEnum.CASE.name:
                    routePath = `/cases/${dataBlob.ownerId}/task-list`;
                    break;
                case EntityTypeEnum.REPORT.name:
                default:
                    routePath = '/tasks';
                    break;
            }
            break;
        case notificationTypeEnum.SUBSCRIPTION_HIT.name:
            if (savedSearchRouteMap[elasticSearchType]) {
                routePath = `${savedSearchRouteMap[elasticSearchType]}?savedSearchId=${savedSearchId}&showNew=true`;
            }
            break;
        case notificationTypeEnum.SHARED_SAVED_SEARCH.name:
            if (savedSearchRouteMap[elasticSearchType]) {
                routePath = `${savedSearchRouteMap[elasticSearchType]}?savedSearchId=${savedSearchId}`;
            }
            break;
        case notificationTypeEnum.SHARED_SAVED_SEARCH_UPDATED.name:
            if (savedSearchRouteMap[elasticSearchType]) {
                routePath = `${savedSearchRouteMap[elasticSearchType]}?savedSearchId=${savedSearchId}`;
            }
            break;
        case notificationTypeEnum.SHARED_SAVED_SEARCH_DELETED.name:
            if (savedSearchRouteMap[elasticSearchType]) {
                routePath = `${savedSearchRouteMap[elasticSearchType]}`;
            }
            break;
        case notificationTypeEnum.TEST_CAD_HIGH.name:
        case notificationTypeEnum.TEST_CAD_MEDIUM.name:
        case notificationTypeEnum.BOLO.name:
        case notificationTypeEnum.OFFICER_EMERGENCY.name:
        case notificationTypeEnum.INCOMING_ASSIGNMENT.name:
        case notificationTypeEnum.HIGH_PRIORITY_NARRATIVE.name:
        case notificationTypeEnum.RFA_URGENT_MESSAGE.name:
        case notificationTypeEnum.RFA_TIMED_OUT.name:
        case notificationTypeEnum.RFA_FAILURE.name:
        case notificationTypeEnum.REPORT_DELETED.name:
            break;
        default:
            return {};
    }

    return {
        notificationCategory,
        routePath,
    };
}

/**
 * Generate a mock user notification for testing purposes only.
 * @param  {UserNotificationBundle} userNotification
 * @return {UserNotificationBundle}
 */
export function generateMockUserNotification(userNotification) {
    return {
        notificationStatus: NotificationStatusEnum.UNREAD.name,
        eventId: `123e4567-e89b-12d3-a456-4266${random(12345678, 23456789)}`,
        deliveryMethod: WEB_APP.name,
        dataBlob: { foo: 'this is a test!' },
        departmentId: 2,
        ...userNotification,
    };
}

/**
 * Convert a user notification to the shape we use on the client from the shape
 *   that the server returns, which is UserNotificationBundle.java.
 * @param  {Object} userNotification
 * @return {Object}
 */
export function convertBundleToClientModel(userNotificationBundle) {
    return {
        ...omit(userNotificationBundle, 'notificationJson'),
        dataBlob: JSON.parse(userNotificationBundle.notificationJson),
    };
}

/**
 * Convert multiple user notifications to the shape we use on the client from
 *   the array that the server returns, which is UserNotificationView.java.
 * @param  {Object} userNotification
 * @return {Object}
 */
export function convertViewToClientModel(userNotificationView) {
    return {
        ...userNotificationView,
        notifications: map(userNotificationView.notifications, convertBundleToClientModel),
    };
}

/**
 * Convert a user notification from the shape we use on the client to the shape
 *   that the server expects, which is UserNotificationBundle.java.
 * @param  {Object} userNotification
 * @return {Object}
 */
export function convertClientModelToBundle(userNotification) {
    return {
        ...omit(userNotification, 'dataBlob'),
        notificationJson: JSON.stringify(userNotification.dataBlob),
    };
}

/**
 * Convert a user notification from the shape we use on the client to the shape
 *   that the server expects for a notification created for another user (not
 *   the current user), which is SendNotificationView.java.
 * @param  {Object} userNotification
 * @return {Object}
 */
export function convertClientModelToSendNotificationView(userNotification) {
    return {
        notification: convertClientModelToBundle(userNotification),
        userDeliveryMethods: {
            [userNotification.userId]: [EMAIL.name, WEB_APP.name],
        },
    };
}

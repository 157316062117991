import {
    UsageSourceModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    EntityTypeEnum,
    RefContextEnum,
} from '@mark43/rms-api';

import { parseInt, get, map } from 'lodash';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { loadFullWarrant } from '~/client-common/core/domain/warrants/state/data';
import {
    startPollingForCurrentViewers,
    stopPollingForCurrentViewers,
} from '~/client-common/core/domain/current-viewers/state/polling';
import { executeSavedSearchFromQueryParam } from '../search/core/state/ui';

import { createUsageLog } from '../admin/usage-logs/state/data';

import formsRegistry from '../../core/formsRegistry';

// error
import redirectToErrorPage from '../core/utils/redirectToErrorPage';

// current-viewers
import { openLoadingModal, closeLoadingModal } from '../../legacy-redux/actions/boxActions';
import redirectInsufficientPermissions from '../core/utils/redirectInsufficientPermissions';
import { currentUserHasAbilitySelector } from '../core/current-user/state/ui';
import warrantDetailsCard from './warrant/state/cards/warrantDetailsCard';
import { loadWarrantRelatedEntities } from './warrant/state/data';
import {
    setCurrentWarrantId,
    setInitialWarrantCardsState,
    setIsNewWarrant,
    setLinkedReportIds,
} from './warrant/state/ui';
import {
    warrantsDashboardSearch,
    performInitialWarrantsDashboardSearch,
} from './dashboard/state/ui';
import { loadWarrantExports } from './warrant/state/ui/warrantExports';
import { loadWarrantHistory } from './warrant/state/data/warrantHistories';

export function warrantExportsOnEnter({ params }) {
    const warrantId = params.warrantId;
    this.dispatch(loadWarrantExports(parseInt(warrantId)));
}

export function warrantHistoryOnEnter({ params }) {
    this.dispatch(loadWarrantHistory(params.warrantId));
}

export function warrantsOnEnter() {
    const state = this.getState();
    if (!currentUserHasAbilitySelector(state)(abilitiesEnum.WARRANTS.VIEW_GENERAL)) {
        this.dispatch(
            createUsageLog({
                primaryEntityType: EntityTypeEnum.WARRANT.name,
                action: UsageActionEnum.WARRANTS_DASHBOARD_VIEWED.name,
                completion: UsageCompletionEnum.INSUFFICIENT_PERMISSIONS.name,
                sourceModule: UsageSourceModuleEnum.WARRANTS.name,
            })
        );
        this.dispatch(redirectInsufficientPermissions());
    }
}

export function warrantsDashboardOnEnter(nextState, replace) {
    this.dispatch(
        createUsageLog({
            primaryEntityType: EntityTypeEnum.WARRANT.name,
            primaryEntityTitle: 'Warrants Dashboard',
            action: UsageActionEnum.WARRANTS_DASHBOARD_VIEWED.name,
            completion: UsageCompletionEnum.SUCCEEDED.name,
            sourceModule: UsageSourceModuleEnum.WARRANTS.name,
        })
    );

    this.dispatch(
        executeSavedSearchFromQueryParam({
            searchModule: warrantsDashboardSearch,
            nextState,
            replace,
        })
    ).then((executed) => {
        if (!executed) {
            this.dispatch(performInitialWarrantsDashboardSearch());
        }
    });
}

export function warrantOnEnter({ params }) {
    const warrantId = parseInt(params.warrantId);
    const usageLog = {
        primaryEntityType: EntityTypeEnum.WARRANT.name,
        action: UsageActionEnum.WARRANT_RECORD_VIEWED.name,
        primaryEntityId: warrantId,
        sourceModule: UsageSourceModuleEnum.WARRANTS.name,
    };

    this.dispatch(setCurrentWarrantId(warrantId));
    this.dispatch(openLoadingModal());
    this.dispatch(loadFullWarrant(warrantId))
        .then((fullWarrant) => {
            this.dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityDepartmentId: get(fullWarrant, 'warrants[0].departmentId'),
                    primaryEntityTitle: get(fullWarrant, 'warrants[0].warrantNumber'),
                })
            );
            this.dispatch(setInitialWarrantCardsState());
            warrantDetailsCard.scrollToTop(); // scroll to top of first card
            this.dispatch(startPollingForCurrentViewers(EntityTypeEnum.WARRANT.name, warrantId));
            this.dispatch(closeLoadingModal());
            const reportIds = map(fullWarrant.chargeLinkedReports, 'id');
            this.dispatch(setLinkedReportIds(reportIds));
            this.dispatch(loadWarrantRelatedEntities(reportIds));
        })
        .catch((err) => {
            this.dispatch(createUsageLog(usageLog, { errorCode: err.code }));

            this.dispatch(redirectToErrorPage({ errorCode: err.code }));
            throw err;
        });
}

export function warrantOnLeave({ params } = {}) {
    if (params && params.warrantId) {
        this.dispatch(stopPollingForCurrentViewers(EntityTypeEnum.WARRANT.name, params.warrantId));
    }
    this.dispatch(setIsNewWarrant(false));
    this.dispatch(setCurrentWarrantId(null));
    formsRegistry.unregister(RefContextEnum.FORM_WARRANT_DEX_SUBMISSION.name);
}

import { DeepPartial } from 'utility-types';
import { compact, omit } from 'lodash';
import { buildLocationSubPremises } from '@mark43/gis-locations';
import { LocationSourceEnum, LocationView } from '@mark43/rms-api';
import { LocationSubPremises } from '~/client-common/core/domain/locations/utils/subPremiseHelpers';
import {
    LocationSidePanelFormDataShape,
    SubPremisesFormDataShape,
} from '../state/forms/locationSidePanelForm';

export const convertToSubPremiseDataModel = (subPremises: SubPremisesFormDataShape | undefined) => {
    if (!subPremises) {
        return {};
    }
    return buildLocationSubPremises(subPremises);
};

export const convertToSubPremiseFormModel = ({
    subPremise1Name,
    subPremise1Value,
    subPremise2Name,
    subPremise2Value,
    subPremise3Name,
    subPremise3Value,
    subPremise4Name,
    subPremise4Value,
    subPremise5Name,
    subPremise5Value,
}: LocationSubPremises) => {
    return compact([
        (subPremise1Name || subPremise1Value) && {
            subPremiseName: subPremise1Name,
            subPremiseValue: subPremise1Value,
        },
        (subPremise2Name || subPremise2Value) && {
            subPremiseName: subPremise2Name,
            subPremiseValue: subPremise2Value,
        },
        (subPremise3Name || subPremise3Value) && {
            subPremiseName: subPremise3Name,
            subPremiseValue: subPremise3Value,
        },
        (subPremise4Name || subPremise4Value) && {
            subPremiseName: subPremise4Name,
            subPremiseValue: subPremise4Value,
        },
        (subPremise5Name || subPremise5Value) && {
            subPremiseName: subPremise5Name,
            subPremiseValue: subPremise5Value,
        },
    ]);
};

export function convertToLocationDataModel(
    formModel: LocationSidePanelFormDataShape
): DeepPartial<LocationView> {
    const { entityLink, ...locationWithoutEntityLink } = formModel;
    const convertedSubPremises = convertToSubPremiseDataModel(
        locationWithoutEntityLink?.subPremises
    );
    const entityLinksToUpsert = entityLink?.linkType
        ? [
              {
                  ...entityLink,
                  ...convertedSubPremises,
              },
          ]
        : [];
    const locationToUpsert = {
        ...omit(locationWithoutEntityLink, 'subPremises'),
        ...convertedSubPremises,
        entityLinks: entityLinksToUpsert,
        // if the current location don't have the resolverSource
        // and don't have a valid valid lat/long we need to set
        // it to FORCED_LOCATION for the BE to save or else we will lose that context
        resolverSource: !!locationWithoutEntityLink.resolverSource
            ? locationWithoutEntityLink.resolverSource
            : !locationWithoutEntityLink.longitude && !locationWithoutEntityLink.latitude
            ? LocationSourceEnum.FORCED_LOCATION.name
            : undefined,
    };

    return locationToUpsert;
}

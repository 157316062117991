import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RefContextEnum } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import DeleteEvidenceItemForm from '../../core/components/items/DeleteEvidenceItemForm';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import Modal from '../../../core/overlays/components/Modal';
import { deleteEvidenceItem } from '../../../evidence/core/state/data/evidenceItems';
import { selectedItemProfileIdsSelector, selectItemProfiles } from '../state/ui';

type DeleteEvidenceItemModalCustomProps = {
    itemProfileId: number;
    masterItemId: number;
    reportId: number;
};

const strings = componentStrings.reports.custodialPropertySummary.DeleteEvidenceItemModal;

const DeleteEvidenceItemModal: React.FC = () => {
    const dispatch = useDispatch();
    const { getForm } = useFormGetter();
    const selectedItemProfileIds = useSelector(selectedItemProfileIdsSelector);
    const handleAfterClose = useCallback(
        () => getForm(RefContextEnum.FORM_DELETE_EVIDENCE_ITEM.name)?.resetModel(),
        [getForm]
    );
    const handleSave = useCallback(
        (props: DeleteEvidenceItemModalCustomProps) => {
            const { masterItemId, itemProfileId, reportId } = props;
            const form = getForm(RefContextEnum.FORM_DELETE_EVIDENCE_ITEM.name);
            return form
                ?.submit()
                .then(() => {
                    // validated in submit action
                    const reasonForDeletion = form.getState().model
                        .itemRequestReasonForDeletion as string;
                    return dispatch(
                        deleteEvidenceItem(itemProfileId, {
                            masterItemId,
                            reportId,
                            reasonForDeletion,
                        })
                    );
                })
                .then(() => {
                    // can't have an item selected which has been deleted
                    const updatedSelectedItemProfileIds = selectedItemProfileIds.filter(
                        (selectedItemProfileId) => selectedItemProfileId !== itemProfileId
                    );
                    dispatch(selectItemProfiles(updatedSelectedItemProfileIds));
                });
        },
        [dispatch, getForm, selectedItemProfileIds]
    );

    const evidenceModuleName = useEvidenceModuleName();
    return (
        <Modal<DeleteEvidenceItemModalCustomProps>
            id={overlayIdEnum.CUSTODIAL_PROPERTY_CARD_OVERLAY}
            okText={strings.confirmButton}
            onAfterClose={handleAfterClose}
            onSave={handleSave}
            title={strings.title(evidenceModuleName)}
        >
            <DeleteEvidenceItemForm />
        </Modal>
    );
};

export default DeleteEvidenceItemModal;

import { reduce, forEach } from 'lodash';

const computePropSourceMap = ({ values, fieldConfig }) =>
    reduce(
        fieldConfig,
        (acc, config) => {
            const { prop, relatedFields } = config;
            const selectedValue = values[prop];
            if (selectedValue === undefined) {
                return acc;
            }
            acc[prop] = selectedValue;
            if (relatedFields) {
                forEach(relatedFields, (relatedField) => {
                    acc[relatedField.prop] = selectedValue;
                });
            }

            return acc;
        },
        {}
    );

export default computePropSourceMap;

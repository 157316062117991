import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';

import loadingStateEnum from './constants/loadingStateEnum';

const PersonQuickAdd = () => {
    const [isPersonStale, setIsPersonStale] = useState(true);
    const [personLoadingState, setPersonLoadingState] = useState(loadingStateEnum.READY);

    const resetState = useCallback(() => {
        setIsPersonStale(true);
        setPersonLoadingState(loadingStateEnum.READY);
    }, [setIsPersonStale, setPersonLoadingState]);

    return {
        isPersonStale,
        setIsPersonStale,
        personLoadingState,
        setPersonLoadingState,
        resetState,
    };
};

export const PersonQuickAddContext = createContainer(PersonQuickAdd);

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'admin/permission';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Ability Role Links Resource',
        methods: {
            loadAbilityRoleLinksForRole(roleId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/ability_role_links/role/${roleId}`,
                });
            },
            upsertAbilityRoleLinks(abilityRoleLinks) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/ability_role_links?remove_existing=true`,
                    data: abilityRoleLinks,
                });
            },
            deleteAbilityRoleLink(abilityRoleLink) {
                return req({
                    method: 'DELETE',
                    url: `${BASE_PATH}/role/${abilityRoleLink.roleId}/ability/${abilityRoleLink.abilityId}`,
                });
            },
            loadAbilityRoleLinksForRoleBatch(roleIds) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/ability_role_links/batch`,
                    data: roleIds,
                });
            },
            exportAbilityRoleLinks() {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/ability_role_links/export`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;

/**
 * Resources for AbilityRoleLinks. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

import { UcrProperty } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'ucrProperty';

const ucrPropertyModule = createNormalizedModule<UcrProperty>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS

export const ucrPropertyWhereSelector = ucrPropertyModule.selectors.entitiesWhereSelector;

// REDUCER
export default ucrPropertyModule.reducerConfig;

import { isEmpty } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { isUndefinedOrNull, isValueDisplayable } from '~/client-common/helpers/logicHelpers';
import { formatNibrsCode } from '~/client-common/helpers/offenseCodesHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { ARREST_NIBRS_CODE } from '~/client-common/core/enums/universal/fields';

import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';

import { computeDefaultNibrsOffenseCode } from '../../state/forms/chargesForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import PaddedNoDataBlock from '../../../../core/components/PaddedNoDataBlock';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import ChargesSummaryView from './ChargesSummaryView';

const strings = componentStrings.reports.core.ChargesCard;

const NibrsSummaryList = styled(SummaryList)`
    padding-bottom: 20px;
`;

const mapStateToProps = createStructuredSelector({
    chargesWhere: chargesWhereSelector,
});

const mapDispatchToProps = { computeDefaultNibrsOffenseCode };

const ChargesCardSummary = connect(
    mapStateToProps,
    mapDispatchToProps
)(function _ChargesCardSummary({
    arrest,
    showNibrsCode,
    chargesWhere,
    computeDefaultNibrsOffenseCode,
    index,
}) {
    const { id: arrestId, nibrsCode: arrestNibrsOffenseCode } = arrest;
    const chargesForArrest = chargesWhere({ arrestId });

    // The Arrest NIBRS Offense Code is used for the NIBRS export.  We have fallback
    // logic on the BE that will compute and use a default NIBRS Offense Code if
    // the arrest's NIBRS Offense Code value is `NULL`.  We mirror the defaulting logic
    // for display purposes.
    const nibrsOffenseCodeDisplay = isUndefinedOrNull(arrestNibrsOffenseCode)
        ? formatNibrsCode(computeDefaultNibrsOffenseCode({ arrestId }))
        : formatNibrsCode(arrestNibrsOffenseCode);

    return (
        <CardSection>
            {showNibrsCode && isValueDisplayable(nibrsOffenseCodeDisplay) && (
                <NibrsSummaryList labelWidth={100} contentWidth={400}>
                    <SummaryRow fieldName={ARREST_NIBRS_CODE}>{nibrsOffenseCodeDisplay}</SummaryRow>
                </NibrsSummaryList>
            )}
            {isEmpty(chargesForArrest) ? (
                <PaddedNoDataBlock>{strings.noData}</PaddedNoDataBlock>
            ) : (
                <ChargesSummaryView arrestId={arrestId} chargesFormIndex={index} />
            )}
        </CardSection>
    );
});

export default ChargesCardSummary;

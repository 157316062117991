import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, first } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { createStructuredSelector } from 'reselect';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { useOfForceSubjectsWhereSelector } from '~/client-common/core/domain/use-of-force-subjects/state/data';
import { formatUseOfForceSubjectTitleByUseOfForceSubjectIdSelector } from '~/client-common/core/domain/use-of-force-subjects/state/ui';

import testIds from '../../../../../core/testIds';
import {
    createUseOfForceSubjectForm,
    refreshUseOfForceSubjectForm,
} from '../../state/forms/useOfForceSubjectForm';
import { registerForm } from '../../state/ui';
import useOfForceSubjectCard from '../../state/ui/useOfForceSubjectCard';
import Card from '../../../../../legacy-redux/components/core/Card';
import withCard from '../../utils/withCard';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import formsRegistry from '../../../../../core/formsRegistry';
import OverlayButton from '../../../../core/overlays/components/OverlayButton';
import { registerCard } from '../../utils/cardsRegistry';
import UseOfForceSubjectCardForm from './UseOfForceSubjectCardForm';
import UseOfForceSubjectCardSummary from './UseOfForceSubjectCardSummary';
import DeleteUseOfForceSubjectModal from './DeleteUseOfForceSubjectModal';

const strings = componentStrings.reports.core.UseOfForceSubjectCard;

class UseOfForceSubjectCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(...args) {
        super(...args);
        const {
            dataUseOfForceSubjectsWhere,
            useOfForceSubjectId,
            nameReportLinksWhere,
            index,
        } = this.props;
        const useOfForceSubject = first(dataUseOfForceSubjectsWhere({ id: useOfForceSubjectId }));
        const reportId = get(useOfForceSubject, 'reportId');
        const contextId = get(useOfForceSubject, 'contextId');
        const subjectNameReportLink = first(
            nameReportLinksWhere({
                reportId,
                contextId,
                contextType: EntityTypeEnum.USE_OF_FORCE_SUBJECT.name,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            })
        );
        const subjectPersonProfileId = get(subjectNameReportLink, 'nameId');
        const initialState = this.props.refreshUseOfForceSubjectForm({
            useOfForceSubject,
            subjectPersonProfileId,
        });
        const form = createUseOfForceSubjectForm({
            formatFieldByName: this.props.formatFieldByName,
            arbiter: this.props.arbiter,
            initialState,
        });
        registerForm({ form, index });
        this.unregisterCard = registerCard({
            cardModule: useOfForceSubjectCard,
            onSave: this.onSave,
            index,
        });
    }

    componentWillUnmount() {
        const form = formsRegistry.get(
            RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name,
            this.props.index
        );
        if (form) {
            formsRegistry.unregister(form, this.props.index);
        }

        this.unregisterCard();
    }

    onEdit = () => this.props.editCallback(() => this.props.onEdit({ index: this.props.index }));

    getForm = () =>
        formsRegistry.get(RefContextEnum.FORM_USE_OF_FORCE_SUBJECT.name, this.props.index);

    onSaveProgress = ({ customEventType } = {}) =>
        this.props.onSaveProgress(this.getForm(), {
            index: this.props.index,
            customEventType,
            changeToSummaryModeAfterSubmission: true,
        });

    onSubjectAdd = (nameReportLink) => {
        this.getForm().set('subject', get(nameReportLink, 'nameId'));
    };

    onSubjectRemove = () => {
        this.getForm().set('subject', null);
    };

    onSave = () => {
        return this.props.onSave(this.getForm(), {
            index: this.props.index,
        });
    };

    getUseOfForceSubjectOverlayId = () =>
        `${overlayIdEnum.DELETE_USE_OF_FORCE_SUBJECT_MODAL}.${this.props.useOfForceSubjectId}`;

    render() {
        const {
            card = {},
            dataUseOfForceSubjectsWhere,
            useOfForceSubjectId,
            nameReportLinksWhere,
            formatUseOfForceSubjectTitleByUseOfForceSubjectId,
            currentReportREN,
        } = this.props;

        const useOfForceSubject = first(dataUseOfForceSubjectsWhere({ id: useOfForceSubjectId }));

        if (!useOfForceSubject) {
            return <div />;
        }

        const reportId = get(useOfForceSubject, 'reportId');
        const title = formatUseOfForceSubjectTitleByUseOfForceSubjectId(
            useOfForceSubjectId,
            reportId
        );
        const contextId = get(useOfForceSubject, 'contextId');

        const subjectNameReportLink = first(
            nameReportLinksWhere({
                reportId,
                contextId,
                contextType: EntityTypeEnum.USE_OF_FORCE_SUBJECT.name,
                entityType: EntityTypeEnum.PERSON_PROFILE.name,
            })
        );
        const subjectPersonProfileId = get(subjectNameReportLink, 'nameId');

        const nameSummaryViewWrapper = (
            <NameSummaryViewWrapper
                renForRecents={currentReportREN}
                summaryMode={card.summaryMode}
                addNameButtonText={strings.subject}
                reportId={reportId}
                contextType={EntityTypeEnum.USE_OF_FORCE_SUBJECT.name}
                contextId={contextId}
                parentEntityType={EntityTypeEnum.REPORT.name}
                parentId={reportId}
                linkType={LinkTypesEnum.SUBJECT_IN_USE_OF_FORCE}
                show={{ people: true }}
                limitToOne={true}
                onAddSuccess={this.onSubjectAdd}
                onRemoveSuccess={this.onSubjectRemove}
                personOverlayIdPrefix={`${overlayIdEnum.PERSON_OVERLAY_USE_OF_FORCE_CARD}.${useOfForceSubject.id}`}
                organizationOverlayIdPrefix={`${overlayIdEnum.ORGANIZATION_OVERLAY_USE_OF_FORCE_CARD}.${useOfForceSubject.id}`}
            />
        );
        return (
            <>
                <OverlayButton id={this.getUseOfForceSubjectOverlayId()}>
                    {(open) => (
                        <Card
                            testId={testIds.USE_OF_FORCE_SUBJECT_CARD}
                            title={title}
                            renderContent={(summaryMode) => {
                                return (
                                    <div>
                                        {summaryMode ? (
                                            <UseOfForceSubjectCardSummary
                                                subjectPersonProfileId={subjectPersonProfileId}
                                                useOfForceSubject={useOfForceSubject}
                                                nameSummaryViewWrapper={nameSummaryViewWrapper}
                                            />
                                        ) : (
                                            <UseOfForceSubjectCardForm
                                                index={useOfForceSubject.id}
                                                nameSummaryViewWrapper={nameSummaryViewWrapper}
                                                currentReportREN={currentReportREN}
                                            />
                                        )}
                                    </div>
                                );
                            }}
                            onEdit={this.onEdit}
                            errors={card.errorMessages}
                            summaryMode={card.summaryMode}
                            canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                            canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                            saving={card.saving}
                            onSave={this.onSaveProgress}
                            onRemove={open}
                            anchor={card.anchorForIndex(useOfForceSubject.id)}
                        />
                    )}
                </OverlayButton>
                <DeleteUseOfForceSubjectModal
                    overlayId={this.getUseOfForceSubjectOverlayId()}
                    reportId={reportId}
                    useOfForceSubject={useOfForceSubject}
                />
            </>
        );
    }
}

export default compose(
    withCard(useOfForceSubjectCard),
    connect(
        createStructuredSelector({
            formatFieldByName: formatFieldByNameSelector,
            nameReportLinksWhere: nameReportLinksWhereSelector,
            dataUseOfForceSubjectsWhere: useOfForceSubjectsWhereSelector,
            formatUseOfForceSubjectTitleByUseOfForceSubjectId: formatUseOfForceSubjectTitleByUseOfForceSubjectIdSelector,
        }),
        { refreshUseOfForceSubjectForm },
        null,
        { forwardRef: true }
    )
)(UseOfForceSubjectCard);

import React, { useState, useEffect, useCallback } from 'react';
import { InjectedRouter, withRouter } from 'react-router';
import { Radio, RadioGroup, Text, Select, SelectChangeEventHandler, SelectChangeEvent } from 'arc';
import { map } from 'lodash';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import casePhotoLineupResource from '../../core/resources/casePhotoLineupResource';
import Modal from '../../../core/overlays/components/Modal';
import { useLineupContext } from '../state/ui';
import { getLineupName } from '../utils/helpers';
import { usePhotoLineupFieldName } from '../hooks/usePhotoLineupFieldName';
import { getFormOptions, getMugshots, POI_VALUE } from '../state/data/createLineupModal';
import testIds from '../../../../core/testIds';
import { usePhotoLineupPersonProfiles } from '../hooks/usePhotoLineupPersonProfiles';
import { MugshotPhotoOptions } from './LineupPhotoRow';

const overlayId = overlayIdEnum.CREATE_LINEUP_MODAL;
const strings = componentStrings.cases.casePhotoLineups.CasePhotoLineups.CreateLineupModal;

interface CreateLineupModalProps {
    caseId: number;
    router: InjectedRouter;
}

const CreateLineupModal: React.FC<CreateLineupModalProps> = ({
    caseId,
    router,
}: CreateLineupModalProps) => {
    const photoLineupFieldName = usePhotoLineupFieldName();

    const {
        relatedPersons,
        mugshotAttachmentsForPersonId,
        offenseSuspects,
        otherProfiles,
    } = usePhotoLineupPersonProfiles();

    const [selectedSuspectId, setSelectedSuspectId] = useState<number>(0);
    const [selectedPersonOfInterestId, setSelectedPersonOfInterestId] = useState<number>(POI_VALUE);
    const [selectedImageId, setSelectedImageId] = useState<number>(0);
    const { setSelectedLineup } = useLineupContext();

    // Form options
    const profileId =
        selectedSuspectId !== POI_VALUE ? selectedSuspectId : selectedPersonOfInterestId;
    const selectNonSuspectOptions = getFormOptions(otherProfiles);
    const radioSuspectOptions = getFormOptions(offenseSuspects);

    // set selectedSuspectId when suspectProfiles populates with actual data
    useEffect(() => {
        if ((selectedSuspectId === 0 || !selectedSuspectId) && radioSuspectOptions.length > 0) {
            setSelectedSuspectId(radioSuspectOptions[0].value);
        }
    }, [radioSuspectOptions, selectedSuspectId]);

    // set selectedImageId when sortedMugshotAttachments populates with actual data
    useEffect(() => {
        if ((selectedImageId === 0 || !selectedImageId) && radioSuspectOptions.length > 0) {
            setSelectedImageId(
                getMugshots(radioSuspectOptions[0].value, mugshotAttachmentsForPersonId)[0]
                    ?.attachmentId
            );
        }
    }, [mugshotAttachmentsForPersonId, radioSuspectOptions, selectedImageId]);

    const onSaveModal = useCallback(() => {
        const personProfileId =
            selectedSuspectId === POI_VALUE ? selectedPersonOfInterestId : selectedSuspectId;
        const creationResult = casePhotoLineupResource.createPhotoLineupForCase({
            caseId,
            imageId: selectedImageId,
            personOfInterestMasterId: personProfileId,
        });
        creationResult.then((result) => {
            setSelectedLineup({
                title: result.title,
                // @ts-expect-error client-common to client RND-7529
                name: getLineupName(result.photoLineup.personOfInterestMasterId, relatedPersons),
                lineupId: result.photoLineup.id,
                canExport: result.canExport,
                isExported: result.isExported,
            });
            router.push(`/cases/${caseId}/lineups/${result.photoLineup.id}/compose`);
        });
    }, [
        caseId,
        selectedPersonOfInterestId,
        selectedSuspectId,
        selectedImageId,
        router,
        setSelectedLineup,
        relatedPersons,
    ]);

    // form & modal handlers
    const onCloseModal = () => {
        setSelectedSuspectId(0);
        setSelectedPersonOfInterestId(POI_VALUE);
        setSelectedImageId(0);
    };

    const onSuspectRadioChange = (masterPersonId: string) => {
        const suspect = parseInt(masterPersonId);
        setSelectedSuspectId(suspect);

        if (suspect === POI_VALUE) {
            // set the imageId to be the first person in the select dropdown
            const imgs = getMugshots(
                selectNonSuspectOptions[0].value,
                mugshotAttachmentsForPersonId
            );
            setSelectedPersonOfInterestId(selectNonSuspectOptions[0].value);
            setSelectedImageId(imgs[0].attachmentId);
        } else {
            setSelectedPersonOfInterestId(POI_VALUE);
            setSelectedImageId(getMugshots(suspect, mugshotAttachmentsForPersonId)[0].attachmentId);
        }
    };

    const onOthersSelectChange: SelectChangeEventHandler<
        { label: string; value: number },
        false
    > = (e: SelectChangeEvent<{ label: string; value: number }, false>) => {
        const { currentTarget } = e;
        const poi = currentTarget.value ? currentTarget.value : POI_VALUE;
        const imgs = getMugshots(poi, mugshotAttachmentsForPersonId);

        setSelectedPersonOfInterestId(poi);
        setSelectedImageId(imgs.length > 0 ? imgs[0].attachmentId : POI_VALUE);
    };

    const otherProfileSelect = (showSelect: boolean) => {
        if (selectNonSuspectOptions.length <= 0) {
            return null;
        }

        return (
            showSelect && (
                <Select
                    data-test-id={testIds.PHOTO_LINEUP_CREATE_MODAL_POI_SELECT}
                    style={{ width: '250px', zIndex: 10000 }}
                    options={selectNonSuspectOptions}
                    value={selectedPersonOfInterestId}
                    onChange={onOthersSelectChange}
                />
            )
        );
    };

    return (
        <Modal
            id={overlayId}
            title={strings.title(photoLineupFieldName)}
            okText={strings.okText}
            cancelText={strings.cancelText}
            onSave={onSaveModal}
            onCancel={onCloseModal}
            saveDisabled={
                selectedSuspectId === POI_VALUE && selectedPersonOfInterestId === POI_VALUE
            }
        >
            <Text variant="bodyMd">{strings.message(photoLineupFieldName)}</Text>
            <RadioGroup
                direction="column"
                defaultValue={selectedSuspectId}
                value={selectedSuspectId}
                onChange={onSuspectRadioChange}
            >
                {map(
                    selectNonSuspectOptions.length > 0
                        ? [
                              ...radioSuspectOptions,
                              { label: strings.personOfInterest, value: POI_VALUE },
                          ]
                        : radioSuspectOptions,
                    (suspect) => (
                        <React.Fragment key={suspect.value}>
                            <Radio
                                value={suspect.value}
                                data-test-id={testIds.PHOTO_LINEUP_CREATE_MODAL_SUSPECT}
                            >
                                {suspect.label}
                            </Radio>
                            {otherProfileSelect(
                                selectedSuspectId === POI_VALUE && suspect.value === POI_VALUE
                            )}
                            <MugshotPhotoOptions
                                isShown={selectedSuspectId === suspect.value}
                                photos={getMugshots(profileId, mugshotAttachmentsForPersonId)}
                                selectedImageId={selectedImageId}
                                setSelectedImageId={setSelectedImageId}
                            />
                        </React.Fragment>
                    )
                )}
            </RadioGroup>
        </Modal>
    );
};

export default withRouter(CreateLineupModal);

import React from 'react';
import {
    MFTFormConfigurationDictionary,
    MFT_CONFIGURATION_TYPE,
    _Form,
    MFTFormConfiguration,
    Fieldset,
} from 'markformythree';
import { InlineBanner } from 'arc';
import ErrorBoundary from '../../../errors/components/ErrorBoundary';
import { logError } from '../../../../../core/logging';
import { FormConfigurationFormFieldRenderer } from './FormConfigurationFormFieldRenderer';
import { FormConfigurationSummaryFieldRenderer } from './FormConfigurationSummaryFieldRenderer';

/**
 * Wrapper around `getConfigurationForPath` that will not error out
 * when an invalid path is provided
 */
const safeGetConfigurationForPath = <T extends MFTFormConfiguration>(
    form: _Form<T>,
    absoluteFormPath: string
) => {
    try {
        // @ts-expect-error The types here are wrong -- we need to fix MFT types
        // https://mark43.atlassian.net/browse/RND-13885
        const subsetOfFormConfiguration = (form.getConfigurationForPath as (
            path: string
        ) => MFTFormConfigurationDictionary[string] | MFTFormConfiguration)(absoluteFormPath);
        return subsetOfFormConfiguration;
    } catch (e) {
        logError('Could not get configuration for path', {
            extras: {
                path: absoluteFormPath,
            },
        });
        return undefined;
    }
};

/**
 * Takes a string input and converts it to an mft compliant array
 *
 * input: `victims[4].customProperties.property`
 * output: ['victims', '[4]', 'customProperties', 'property']
 */
function parseStringPathToArray(stringPath: string): string[] {
    const regex = /\w+|\[\d+\]/g;

    const matches = stringPath.match(regex);

    if (matches) {
        return matches;
    }

    return [];
}

/**
 * Renders a fieldset for each level of the form path
 * E.g. victims[4].customProperties.property
 * will be:
 *
 *  <Fieldset path="victims">
 *      <Fieldset path="[4]"
 *          <Fieldset path="customProperties"
 *              <Fieldset path="property"
 *                  etc
 *
 * Note in `SUMMARY` mode, we don't render the fieldsets
 *
 */
function RecursiveFieldsetRenderer<T extends MFTFormConfiguration>(props: {
    form: _Form<T>;
    mode: 'SUMMARY' | 'FORM';
    previousPathAsArray: string[];
    previousAbsolutePath: string;
    __fieldsToInclude?: string[];
}) {
    const currentPath = props.previousPathAsArray[0];
    if (typeof currentPath === 'undefined') {
        return null;
    }

    const absolutePath =
        props.previousAbsolutePath !== ''
            ? props.previousAbsolutePath +
              (currentPath.startsWith('[') ? currentPath : `.${currentPath}`)
            : currentPath;

    const subsetOfFormConfiguration = safeGetConfigurationForPath(props.form, absolutePath);

    if (!subsetOfFormConfiguration) {
        return null;
    }

    // TODO: When we support NItems here, we'll want to distinguish the dynamically generated
    // config from the config created via the `pathAsArray`
    // https:// mark43.atlassian.net/browse/RND-13886
    if (
        subsetOfFormConfiguration[MFT_CONFIGURATION_TYPE] === 'FORM_CONFIGURATION' ||
        subsetOfFormConfiguration[MFT_CONFIGURATION_TYPE] === 'FIELDSET' ||
        // @ts-expect-error The types here are wrong -- we need to fix MFT types
        // https://mark43.atlassian.net/browse/RND-13885
        subsetOfFormConfiguration[MFT_CONFIGURATION_TYPE] === 'N_ITEMS'
    ) {
        const pathAsArray = props.previousPathAsArray.slice(1);

        const fieldsetChildren =
            pathAsArray.length > 0 ? (
                <RecursiveFieldsetRenderer
                    form={props.form}
                    previousPathAsArray={pathAsArray}
                    previousAbsolutePath={absolutePath}
                    mode={props.mode}
                    __fieldsToInclude={props.__fieldsToInclude}
                />
            ) : (
                // if we've reached the end of our initial `absoluteFormPath`,
                // begin dynamically constructing the form based on the configuration
                <>
                    {Object.keys(
                        subsetOfFormConfiguration[MFT_CONFIGURATION_TYPE] === 'FORM_CONFIGURATION'
                            ? subsetOfFormConfiguration
                            : subsetOfFormConfiguration.fields
                    ).map((nestedPath) => {
                        return (
                            <RecursiveFieldsetRenderer
                                key={nestedPath}
                                form={props.form}
                                // TODO: Refactor to support nesting NItems within here
                                previousPathAsArray={pathAsArray.concat(nestedPath)}
                                mode={props.mode}
                                previousAbsolutePath={absolutePath}
                                __fieldsToInclude={props.__fieldsToInclude}
                            />
                        );
                    })}
                </>
            );

        // If in summary mode, there's no need to
        // render the fieldsets since
        // all we really care about is the final absolute path
        // We also don't render a fieldset if `currentPath` is undefined, because
        // that implies there is no fieldset -- we likely just have fields we are rendering
        if (props.mode === 'SUMMARY' || currentPath === '') {
            return fieldsetChildren;
        }

        return (
            <Fieldset
                path={currentPath}
                render={() => {
                    return fieldsetChildren;
                }}
            />
        );
    } else if (subsetOfFormConfiguration[MFT_CONFIGURATION_TYPE] === 'FIELD') {
        if (props.__fieldsToInclude?.length && !props.__fieldsToInclude.includes(currentPath)) {
            return null;
        }

        if (props.mode === 'SUMMARY') {
            return (
                <FormConfigurationSummaryFieldRenderer
                    fieldConfiguration={subsetOfFormConfiguration}
                    absoluteFormPath={absolutePath}
                    form={props.form}
                />
            );
        } else {
            return (
                <FormConfigurationFormFieldRenderer
                    mftFieldConfiguration={subsetOfFormConfiguration}
                    path={currentPath}
                />
            );
        }
    } else {
        return null;
    }
}

/**
 * Given a form configuration and an absolute form path,
 * dynamcially render the form from the given path.
 */
export const FormConfigurationRenderer = <T extends MFTFormConfiguration>(props: {
    /**
     * The form configuration
     */
    form: _Form<T>;
    /**
     * The path of the form configuration to render dynamically
     * If not specified, the entire form configuration will be rendered
     */
    absoluteFormPath?: string;
    mode: 'SUMMARY' | 'FORM';
    /**
     * TEMPORARY: Specifically for TNIBRS, we need the ability to render a subset of
     * configured fields in various parts of the application
     *
     * This props is a temporary workaround to support that in the immediate term
     */
    __fieldsToInclude?: string[];
}) => {
    // If no `absoluteFormPath` was provided, then we assume we are rendering
    // the form from the root level
    // In this scenario, our form path array will contain a single empty string entry
    const absoluteFormPathAsArray = props.absoluteFormPath
        ? parseStringPathToArray(props.absoluteFormPath)
        : [''];
    return (
        <ErrorBoundary
            fallback={() => (
                <InlineBanner status="attention" description="Failed to load configurable fields" />
            )}
        >
            <RecursiveFieldsetRenderer
                form={props.form}
                previousPathAsArray={absoluteFormPathAsArray}
                previousAbsolutePath=""
                mode={props.mode}
                __fieldsToInclude={props.__fieldsToInclude}
            />
        </ErrorBoundary>
    );
};

import React from 'react';
import { BriefingSimpleView } from '@mark43/rms-api';
import { Box } from '@arc/layout';
import { DataTable, DataTableProps, ReactTable } from '@arc/data-table';

import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import NoDataBlock from '../../../core/components/NoDataBlock';

import {
    BriefingCreatedByCell,
    BriefingLocationsCell,
    BriefingPostedDateCell,
    BriefingStatusCell,
    BriefingTitleCell,
} from './cells';
import BriefingActionsMenu from './BriefingActionsMenu';

const strings = componentStrings.briefings.table;
const { columnHeaders } = strings;

const columnHelper = ReactTable.createColumnHelper<BriefingSimpleView>();
const columnDefinitions = [
    columnHelper.accessor('postedDateUtc', {
        id: 'postedDateUtc',
        header: columnHeaders.postedDateUtc,
        cell: ({ getValue }) => <BriefingPostedDateCell postedDateUtc={getValue()} />,
        enableSorting: true,
        sortingFn: 'datetime',
        minSize: 90,
        maxSize: 110,
        meta: {
            isResizable: false,
        },
    }),
    columnHelper.accessor('title', {
        id: 'title',
        header: columnHeaders.title,
        cell: ({ getValue, row }) => <BriefingTitleCell id={row.original.id} title={getValue()} />,
        enableSorting: false,
    }),
    columnHelper.accessor('locations', {
        id: 'locations',
        header: columnHeaders.locations,
        cell: ({ getValue }) => <BriefingLocationsCell locations={getValue()} />,
        enableSorting: false,
        minSize: 80,
        size: 80,
    }),
    columnHelper.accessor('createdBy', {
        id: 'createdBy',
        header: columnHeaders.createdBy,
        cell: ({ getValue }) => <BriefingCreatedByCell createdBy={getValue()} />,
        enableSorting: false,
        minSize: 80,
    }),
    columnHelper.accessor('state', {
        id: 'state',
        header: columnHeaders.state,
        cell: ({ getValue }) => <BriefingStatusCell state={getValue()} />,
        enableSorting: false,
        minSize: 60,
        maxSize: 100,
    }),
    columnHelper.display({
        id: 'actions',
        header: undefined,
        cell: ({ row }) => <BriefingActionsMenu briefing={row.original} />,
        size: 35,
        maxSize: 35,
        enableSorting: false,
        meta: {
            isResizable: false,
            columnName: columnHeaders.actions,
        },
    }),
];

type BriefingsTableProps = Omit<DataTableProps<BriefingSimpleView>, 'columns' | 'data'> & {
    results?: BriefingSimpleView[];
    errorMessage: string;
};
export const BriefingsTable = ({
    results,
    errorMessage,
    ...dataTableProps
}: BriefingsTableProps) => {
    const message = results?.length === 0 ? strings.noResults : errorMessage || '';

    return (
        <>
            {/* TODO: change it to return NoDataBlock separately after implementation of https://mark43.atlassian.net/browse/RND-7481*/}
            {/* in the current implementation of the DataTable component it fires the onColumnSortChange on component's mount*/}
            {/* which cost us extra request and some bugs related to it*/}
            {message ? (
                <Box textAlign="center">
                    <NoDataBlock>{message}</NoDataBlock>
                </Box>
            ) : null}
            <Box display={message ? 'none' : 'block'} data-test-id={testIds.BRIEFING_TABLE}>
                <DataTable
                    data={results ?? []}
                    columns={columnDefinitions}
                    hasColumnOrdering={false}
                    tableProps={{ variant: 'accented', size: 'lg' }}
                    {...dataTableProps}
                />
            </Box>
        </>
    );
};

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { BodyMediumText } from '../../../core/components/typography';

import testIds from '../../../../core/testIds';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import { PortalSidePanel } from '../../../../legacy-redux/components/core/SidePanel';
import { redactableFieldOptionsSelector } from '../state/ui';
import { FieldSection } from './FieldSection';

const strings = componentStrings.core.ExportRedactionSidePanel;

const ScreenContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
`;

const ExportRedactionSidePanelBase = compose(
    connect((state) => ({
        redactableFieldOptions: redactableFieldOptionsSelector(state),
    }))
)(
    class ExportRedactionSidePanelBase extends React.PureComponent {
        state = {
            /**
             * Redactable field options
             */
            fieldOptions: this.props.redactableFieldOptions,
            /**
             * Fields selected for redaction
             */
            redactedFields: this.props.initialRedactedFields || [],
        };

        /**
         * Adds or removes a field from the selected field set of an entity
         *
         * @param {object} data The field name which was toggled
         * @param {boolean} isSelected Whether or not the field should be redacted
         */
        handleFieldToggle = ({ option }, isSelected) => {
            let fieldsToRedact = (this.state.redactedFields || []).slice();

            if (isSelected) {
                fieldsToRedact.push(option.value);
            } else {
                fieldsToRedact = fieldsToRedact.filter((id) => id !== option.value);
            }

            this.setState({
                redactedFields: fieldsToRedact,
            });
        };

        handleSavePanel = () => {
            this.props.onPanelSave(this.state.redactedFields);
            this.props.savePanel();
        };

        render() {
            return (
                <PortalSidePanel
                    noPadding={true}
                    savePanel={this.handleSavePanel}
                    closePanel={this.props.closePanel}
                    title={strings.title}
                    testId={testIds.EXPORTS_REDACTION_SIDE_PANEL}
                    isAtBottomOfStack={this.props.overlayBase.isAtBottomOfStack}
                    onRest={this.handlePanelOnRest}
                >
                    <ScreenContainer>
                        <BodyMediumText>{strings.redactionInfo}</BodyMediumText>
                        <FieldSection
                            options={this.state.fieldOptions}
                            selectedOptions={this.state.redactedFields}
                            onFieldToggle={this.handleFieldToggle}
                        />
                    </ScreenContainer>
                </PortalSidePanel>
            );
        }
    }
);

export const ExportRedactionSidePanel = ({ id, renderButton, ...props }) => (
    <OverlayBaseHelper id={id} renderButton={renderButton}>
        {(renderProps) => (
            <ExportRedactionSidePanelBase overlayId={id} {...props} {...renderProps} />
        )}
    </OverlayBaseHelper>
);

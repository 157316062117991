import { pick } from 'lodash';
import { EntityTypeEnum, LinkTypesEnum, LocationView, LocationEntityLink } from '@mark43/rms-api';
import { RotationTypeSuspensionBundle } from '@mark43/cad-api';

import routesConfig from '../../../../routing/routesConfig';

export function buildLocationEntityLinks({
    location,
    entityId,
}: {
    location: LocationView;
    entityId: number;
}): Partial<LocationEntityLink>[] {
    return [
        {
            ...pick(location, [
                'subPremise1Name',
                'subPremise1Value',
                'subPremise2Name',
                'subPremise2Value',
                'subPremise3Name',
                'subPremise3Value',
                'subPremise4Name',
                'subPremise4Value',
                'subPremise5Name',
                'subPremise5Value',
                'placeName',
                'streetNumber',
                'country',
                'postalCode',
                'locality',
                'neighborhood',
                'latitude',
                'longitude',
                'rangeName',
                'crossStreet1',
                'crossStreet1Alias',
                'crossStreet2',
                'crossStreet2Alias',
                'resolverSource',
            ]),
            entityType: EntityTypeEnum.SERVICE_ROTATION_PROVIDER.name,
            entityId,
            linkType: LinkTypesEnum.ROTATION_SERVICE_PROVIDER_LOCATION,
            locationId: location.id,
        },
    ];
}

export function checkIfEndDateIsAfterStartOne(
    startDateInIsoFormat: string,
    isIndefinitely: boolean,
    endDateInIsoFormat?: string
) {
    if (isIndefinitely || !endDateInIsoFormat) {
        return true;
    }

    return new Date(endDateInIsoFormat) > new Date(startDateInIsoFormat);
}

export function getSuspensionTypesIds(suspension: RotationTypeSuspensionBundle) {
    return (
        suspension?.suspendedRotationTypes.map(
            (suspendedRotationType) => suspendedRotationType.id
        ) ?? []
    );
}

export const nonRedirectRoutes = [
    routesConfig.ADMIN_CAD_SERVICE_PROVIDERS.name,
    routesConfig.ADMIN_SETTINGS.name,
];

export const newProviderFormRoutes = [
    routesConfig.ADMIN_CAD_SERVICE_PROVIDERS_NEW.name,
    routesConfig.ENHANCED_ADMIN_CAD_SERVICE_PROVIDERS_NEW.name,
];

export const allProviderFormsRoutes = [
    ...newProviderFormRoutes,
    routesConfig.ADMIN_CAD_SERVICE_PROVIDERS_SERVICE_PROVIDER.name,
    routesConfig.ENHANCED_ADMIN_CAD_SERVICE_PROVIDERS_SERVICE_PROVIDER.name,
];

export function getSuspensionServiceTypesNames(suspension: RotationTypeSuspensionBundle) {
    return suspension.suspendedRotationTypes
        .map((suspendedRotationType) => suspendedRotationType.name)
        .join(', ');
}

export function getSortedSuspensions(suspensions: RotationTypeSuspensionBundle[]) {
    return [...suspensions].sort((currentSuspension, nextSuspension) => {
        const currentSuspensionStartDate = new Date(currentSuspension.suspensionStartDateUtc);
        const nextSuspensionStartDate = new Date(nextSuspension.suspensionStartDateUtc);

        if (currentSuspensionStartDate > nextSuspensionStartDate) {
            return -1;
        }

        if (nextSuspensionStartDate > currentSuspensionStartDate) {
            return 1;
        }

        return 0;
    });
}

export function checkIfSuspensionIsActive(suspension: RotationTypeSuspensionBundle) {
    const { suspensionStartDateUtc, suspensionEndDateUtc } = suspension;
    const today = new Date();
    const hasSuspensionStarted = today >= new Date(suspensionStartDateUtc);
    const isSuspensionOver =
        (suspensionEndDateUtc && today > new Date(suspensionEndDateUtc)) ?? false;

    return hasSuspensionStarted && !isSuspensionOver;
}

export function checkIfProviderIsSuspended(
    providerSuspensions: RotationTypeSuspensionBundle[] = []
) {
    for (const suspension of providerSuspensions) {
        if (checkIfSuspensionIsActive(suspension)) {
            return true;
        }
    }
    return false;
}

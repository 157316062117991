import {
    createField,
    createFormConfiguration,
    InferFormDataShape,
    lifecycleOptions,
} from 'markformythree';
import { BriefingCreationRequest, RefContextEnum } from '@mark43/rms-api';
import { Briefing } from '~/client-common/core/domain/briefings/state/data';
import {
    BRIEFING_EXPIRY_DATE_UTC,
    BRIEFING_ROLE_ROUTING_LINK_ROLE_ID,
    BRIEFING_SUBDIVISION_ROUTING_LINK_SUBDIVISION_ID,
    BRIEFING_TITLE,
    BRIEFING_PERSONNEL_UNIT_ROUTING_LINK_PERSONNEL_UNIT_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

const briefingRoutingFormConfig = createFormConfiguration({
    title: createField<string>({ fieldName: BRIEFING_TITLE }),
    roleIds: createField<number[]>({ fieldName: BRIEFING_ROLE_ROUTING_LINK_ROLE_ID }),
    subdivisionIds: createField<number[]>({
        fieldName: BRIEFING_SUBDIVISION_ROUTING_LINK_SUBDIVISION_ID,
    }),
    unitIds: createField<number[]>({
        fieldName: BRIEFING_PERSONNEL_UNIT_ROUTING_LINK_PERSONNEL_UNIT_ATTR_ID,
    }),
    expiryDate: createField<string>({ fieldName: BRIEFING_EXPIRY_DATE_UTC }),
});

export const briefingRoutingForm = {
    name: RefContextEnum.FORM_BRIEFING_ROUTING_MODAL.name,
    context: RefContextEnum.FORM_BRIEFING_ROUTING_MODAL.name,
    configuration: briefingRoutingFormConfig,
    lifecycle: lifecycleOptions.REGISTER_AND_UNREGISTER,
};

export type BriefingRoutingFormConfiguration = typeof briefingRoutingFormConfig;
type BriefingRoutingFormDataShape = InferFormDataShape<BriefingRoutingFormConfiguration>;

export const convertFromFormModel = (
    formModel: BriefingRoutingFormDataShape
): Partial<BriefingCreationRequest> => {
    // BE requirement to send empty arrays for empty routing fields
    return {
        title: formModel.title,
        roleRoutingIds: formModel.roleIds ?? [],
        unitRoutingIds: formModel.unitIds ?? [],
        subdivisionRoutingIds: formModel.subdivisionIds ?? [],
        expiryDateUtc: formModel.expiryDate,
    };
};

export const convertToFormModel = (briefing: Briefing): BriefingRoutingFormDataShape => ({
    title: briefing.title,
    roleIds: briefing.roleRoutingIds,
    subdivisionIds: briefing.subdivisionRoutingIds,
    unitIds: briefing.unitRoutingIds,
    expiryDate: briefing.expiryDateUtc,
});

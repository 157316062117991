import { every, isBoolean } from 'lodash';
import { PersonProfile } from '@mark43/rms-api';

type FieldConfig = {
    prop: keyof PersonProfile;
    relatedFields?: Omit<FieldConfig, 'relatedFields'>[];
};

/**
 * Compare properties on two profiles based.
 *  Returns undefined if one and only one profile has undefined value.
 *  Returns true if values match and false if values do not match.
 */
export function isPropertyRowMatch(
    modelA: PersonProfile,
    modelB: PersonProfile,
    fieldConfig: FieldConfig
) {
    if (!fieldConfig.relatedFields) {
        // rows where both values are undefined are filtered out upstream
        if (
            typeof modelA[fieldConfig.prop] === 'undefined' ||
            typeof modelB[fieldConfig.prop] === 'undefined'
        ) {
            return;
        }

        return modelA[fieldConfig.prop] === modelB[fieldConfig.prop];
    }

    const everyModelARelatedFieldIsEmpty = every(
        fieldConfig.relatedFields,
        (relatedField) => typeof modelA[relatedField.prop] === 'undefined'
    );
    const everyModelBRelatedFieldIsEmpty = every(
        fieldConfig.relatedFields,
        (relatedField) => typeof modelB[relatedField.prop] === 'undefined'
    );

    if (everyModelARelatedFieldIsEmpty || everyModelBRelatedFieldIsEmpty) {
        return;
    }

    return every(
        fieldConfig.relatedFields,
        (relatedField) => modelA[relatedField.prop] === modelB[relatedField.prop]
    );
}

/**
 * Compute colors for the Property Rows.
 *   String color names are key names for theme.js colors.
 */
export function computePropertyRowColors(even: boolean | undefined, isMatch: boolean | undefined) {
    // even is used for Advanced Search Persons - Merge Profiles
    if (isBoolean(even)) {
        return {
            columnBackgroundColor: even ? 'var(--arc-colors-surface-accent)' : 'white',
            hoverBackgroundColor: 'brightBlueAlpha',
            selectedTextBackgroundColor: 'brightBlueAlpha',
        };
    }

    // isMatch is used for Admin - Deduplicate Persons
    // undefined when one profile has a value and the other profile has no value.
    if (typeof isMatch === 'undefined') {
        return {
            columnBackgroundColor: 'lightYellow',
            // 8 char Hex format, where last two chars are alpha value.
            hoverBackgroundColor: '#FFF5D288',
        };
    }

    if (isMatch === false) {
        return {
            columnBackgroundColor: 'var(--arc-colors-positive-default)',
            hoverBackgroundColor: '#FFE8E988',
        };
    }

    return {
        columnBackgroundColor: 'white',
        hoverBackgroundColor: 'extraLightGrey',
    };
}

import { chain, first } from 'lodash';
import { ReportStatusError } from '@mark43/rms-api';

/**
 * @typedef CanEditReportCard
 * @prop {boolean} canEditReportCard
 * @prop {string} [errorMessage]
 */

/**
 * Takes two sets of `ReportStatusError` objects and computes the top, most important error message
 * of both sets to be used for user-facing messaging.  The most important error message has the
 * lowest `order`, as determined by the BE `ReportStatusView` logic.
 * `ReportStatusError` objects have shape:
 * `{ order: <boolean>, errorMessage: <string> }`
 *
 * Returns a convenience object with shape:
 * `{ canEditReportCard: <boolean>, errorMessage: <string> }`
 */
export const computeCanEditReportCard = ({
    canEdit = false,
    editErrors = [],
    canMasterEdit = false,
    masterEditErrors = [],
    userHasMasterEditAbility = false,
}: {
    canEdit: boolean;
    editErrors: ReportStatusError[];
    canMasterEdit: boolean;
    masterEditErrors: ReportStatusError[];
    userHasMasterEditAbility: boolean;
}) => {
    const sortedEditErrors = chain(editErrors).sortBy('order').map('errorMessage').value();
    const sortedMasterEditErrors = chain(masterEditErrors)
        .sortBy('order')
        .map('errorMessage')
        .value();

    // Note: We use the `can*` booleans as the source of truth; not the presense of error messages.
    // We are inconsistent; to short-cut things in some places (Report Snapshots and Sealed Reports),
    // on the BE, we simply return an empty `ReportStatusView` object to the FE, which defaults all of
    // the boolean `can*` properties on `ReportStatusView` to `false`, but also defaults all of the
    // `can*Errors` objects to be empty.  Therefore, we end up in a state where the `can*` booleans
    // are correctly `false`, but there are no accompanying error messages.
    if (!canEdit) {
        return {
            canEditReportCard: false,
            errorMessage: first(sortedEditErrors),
        };
    } else if (userHasMasterEditAbility && !canMasterEdit) {
        return {
            canEditReportCard: false,
            errorMessage: first(sortedMasterEditErrors),
        };
    }

    return {
        canEditReportCard: true,
        errorMessage: undefined,
    };
};

import _ from 'lodash';

export const rulesSelector = (state) => state.config.validation.rules;

const rulesForContextSelector = (state, context) => {
    const rules = _.values(rulesSelector(state));
    return _.filter(rules, (rule) => {
        // compare rule in memory to context we are validating
        // first check that any property not in context is null in rule
        const propertiesInRuleNotInContext = _.pickBy(rule.context, (value, key) => {
            return !_.has(context, key);
        });
        const allFiltersNotInContextAreEmptyInRule = _(propertiesInRuleNotInContext)
            .values()
            .every((contextFilter) => !contextFilter);
        // any property in context either matches or is null in rule
        const propertiesInRuleAndInContext = _.pickBy(rule.context, (value, key) => {
            return _.has(context, key);
        });
        const allFiltersPresentInContextMatchOrAreEmptyInRule = _(propertiesInRuleAndInContext)
            .mapValues((value, key) => {
                return !value || context[key] === value;
            })
            .values()
            .every();
        return (
            allFiltersPresentInContextMatchOrAreEmptyInRule && allFiltersNotInContextAreEmptyInRule
        );
    });
};

export const getDynamicRulesForContext = (state, context) => {
    return _.filter(rulesForContextSelector(state, context), (rule) => !rule.systemRule);
};

export const getSystemRulesForContext = (state, context) => {
    return _.filter(rulesForContextSelector(state, context), (rule) => !!rule.systemRule);
};

export const getDynamicRulesForContexts = (state, contexts) => {
    return _(contexts)
        .map((context) => getDynamicRulesForContext(state, context))
        .flatten()
        .value();
};

export const getSystemRulesForContexts = (state, contexts) => {
    return _(contexts)
        .map((context) => getSystemRulesForContext(state, context))
        .flatten()
        .value();
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withProps } from 'recompose';
import { map, slice, keyBy } from 'lodash';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { MFTNItems } from '../../../core/forms/components/NItems';
import {
    deleteUploadingFile,
    uploadedFilesSelector,
    uploadingFilesSelector,
    attachmentsShownCountSelector,
    deleteUpload,
} from '../state/ui/attachmentsSidePanel';
import { openDeleteAttachmentConfirmationModal } from '../state/ui/deleteAttachmentConfirmationModal';
import FileRow from './FileRow';

const AttachmentsTable = ({ uploadingFiles, onClickDeleteUploadingFile, fileUploads }) => {
    return (
        <>
            {map(uploadingFiles, (upload) => {
                if (!upload.file.isDeleted) {
                    return (
                        <FileRow
                            key={upload.file.id}
                            url={upload.file.fileWebServerPath}
                            fileName={upload.file.originalFileName}
                            deleteFile={onClickDeleteUploadingFile(upload.file.id)}
                            isLoading={upload.file.isLoading}
                            uploadFailed={upload.file.uploadFailed}
                            errorMessage={upload.file.errorMessage}
                        />
                    );
                }
            })}
            <MFTNItems
                path="fileDescriptions"
                disabled={true}
                addItemOnEmpty={false}
                hideAddButtonOnEmptyItem={true}
                childFieldKeys={['id', 'description']}
                render={(file) => {
                    const matchFile = fileUploads[file.item.id];
                    return (
                        matchFile && (
                            <FileRow
                                key={matchFile.id}
                                url={matchFile.fileWebServerPath}
                                fileName={matchFile.originalFileName}
                                {...matchFile}
                            />
                        )
                    );
                }}
            />
        </>
    );
};

/*
    This component renders uploaded attachments in light blue boxes.
    Each box gives the user the ability to delete the attachment and add a description
    to the attachments.
*/

export default compose(
    connect(
        createStructuredSelector({
            uploadingFiles: uploadingFilesSelector,
            uploadedFiles: uploadedFilesSelector,
            attachmentsShownCount: attachmentsShownCountSelector,
        }),
        {
            deleteUploadingFile,
            deleteUpload,
            openDeleteAttachmentConfirmationModal,
        }
    ),
    withHandlers({
        onClickDeleteUploadingFile: ({ uploadingFiles, deleteUploadingFile, overlayBase }) => (
            uploadingFileId
        ) => () => {
            const hasFailedUploads = deleteUploadingFile(uploadingFiles, uploadingFileId);

            if (!hasFailedUploads) {
                overlayBase.setError();
            }
        },
        onClickDeleteUpload: ({ uploadedFiles, deleteUpload }) => (uploadId) => () =>
            deleteUpload(uploadedFiles, uploadId),
        onClickDeleteAttachment: ({ openDeleteAttachmentConfirmationModal, entityType }) => (
            attachmentId
        ) => () => {
            return openDeleteAttachmentConfirmationModal(attachmentId, entityType);
        },
    }),
    withProps(
        ({
            attachments,
            uploadedFiles,
            attachmentsShownCount,
            onClickDeleteAttachment,
            onClickDeleteUpload,
        }) => {
            const shownAttachments = slice(attachments, 0, attachmentsShownCount);

            const previousFileUploads = map(shownAttachments, (attachment) => {
                return {
                    ...getAttachmentFile(attachment),
                    createdDateUtc: attachment.createdDateUtc,
                    createdBy: attachment.createdBy,
                    deleteFile: onClickDeleteAttachment(attachment.id),
                };
            });
            const newFileUploads = map(uploadedFiles, (upload) => {
                return {
                    ...upload.file,
                    createdDateUtc: undefined,
                    deleteFile: onClickDeleteUpload(upload.file.id),
                };
            });

            const fileUploads = keyBy([...newFileUploads, ...previousFileUploads], 'id');

            return { fileUploads };
        }
    )
)(AttachmentsTable);

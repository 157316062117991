import { StorageLocationTypeEnum } from '@mark43/evidence-api';

import _, { get } from 'lodash';
import { findStorageLocationChildren } from '~/client-common/core/domain/storage-locations/utils/storageLocationHelpers';
import { MAX_SUB_LOCATION_DEPTH } from '~/client-common/core/domain/storage-locations/constants';
import { isExpired } from '~/client-common/core/dates/utils/dateHelpers';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import { FACILITY_OPTION_VALUE_IN_STORAGE_LOCATION_SELECT } from '../configuration';

const { ACTIVE, EXPIRED } = attributeStatuses;

/**
 * `<AdminList>` items for the facilities column in the Facilities Admin UI.
 * @param  {Object}  facilityViewModels
 * @param  {number}  selectedFacilityId
 * @param  {boolean} showExpired
 * @return {Object[]}
 */
export function generateFacilitiesAdminListItems(
    facilityViewModels,
    selectedFacilityId,
    showExpired
) {
    const now = new Date();

    return _(facilityViewModels)
        .map((facilityViewModel) => ({
            key: facilityViewModel.id,
            title: facilityViewModel.locationName,
            subtitle: facilityViewModel.description,
            status: isExpired(facilityViewModel.expiredDateUtc, now) ? EXPIRED : ACTIVE,
            selected: facilityViewModel.id === selectedFacilityId,
        }))
        .filter(showExpired ? undefined : ({ status }) => status !== EXPIRED)
        .sortBy('title')
        .value();
}

/**
 * Each element in the returned array contains storage location `title` and
 *   `items` for an `<AdminList>` in the Facilities Admin UI. Note this does not
 *   include a column for facilities.
 * @param  {Object}   storageLocationViewModels
 * @param  {Object[]} viewModelsInSelectedPath
 * @param  {boolean}  showExpired
 * @return {Object[]}
 */
export function generateStorageAdminListColumns(
    storageLocationViewModels,
    viewModelsInSelectedPath,
    showExpired
) {
    const now = new Date();
    // the facility is always the first entry in the array
    const facilityId = _.get(viewModelsInSelectedPath[0], 'id');
    // iterate over the each id in the path for a whole depth column
    return _(viewModelsInSelectedPath)
        .map((viewModelInSelectedPath, index) => {
            // the depth of the child locations we are looking for (as opposed to the
            // depth of `viewModelInSelectedPath`, which is the parent)
            const depth = index + 1;

            // do not show the column to the right of the max depth
            if (depth > MAX_SUB_LOCATION_DEPTH) {
                return;
            }

            // create a list of ids of locations to the left that aren't a facility
            const parentStorageLocationIds = _(viewModelsInSelectedPath)
                .tail()
                .map('id')
                .take(depth - 1)
                .value();

            const title =
                depth === 1
                    ? viewModelInSelectedPath.locationName // facility
                    : viewModelInSelectedPath.displayValue; // storage location

            // iterate over each location inside a depth column
            const items = _(
                findStorageLocationChildren(
                    storageLocationViewModels,
                    facilityId,
                    depth,
                    depth > 1 ? viewModelInSelectedPath.id : undefined // parent
                )
            )
                .map((parentLocationViewModel) => ({
                    key: parentLocationViewModel.id,
                    title: parentLocationViewModel.displayValue,
                    status: isExpired(parentLocationViewModel.expiredDateUtc, now)
                        ? EXPIRED
                        : ACTIVE,
                    selected:
                        parentLocationViewModel.id === get(viewModelsInSelectedPath[depth], 'id'),
                    isTemporary:
                        parentLocationViewModel.storageLocationType ===
                        StorageLocationTypeEnum.TEMPORARY.name,
                    isHighValue: parentLocationViewModel.isHighValue,
                    isInHighValueContainer: parentLocationViewModel.isInHighValueContainer,
                    barcodeValue: parentLocationViewModel.barcodeValue,
                    // custom properties
                    description: parentLocationViewModel.description,
                }))
                .filter(showExpired ? undefined : ({ status }) => status !== EXPIRED)
                .value();

            return { facilityId, parentStorageLocationIds, title, items };
        })
        .compact()
        .value();
}

/**
 * Check if parent location selected in the form does not match the actual
 * parent location id.
 * @param  {number|undefined} actualParentStorageLocationId
 * @param  {number|undefined} formParentStorageLocationId
 * @return {boolean}
 */
export function actualParentStorageSelected(
    actualParentStorageLocationId,
    formParentStorageLocationId
) {
    const actualParentStorageSelected =
        !!actualParentStorageLocationId &&
        formParentStorageLocationId === actualParentStorageLocationId;
    const actualParentFacilitySelected =
        !actualParentStorageLocationId &&
        formParentStorageLocationId === FACILITY_OPTION_VALUE_IN_STORAGE_LOCATION_SELECT;

    return actualParentStorageSelected || actualParentFacilitySelected;
}

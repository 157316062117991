import { TrafficCrashEntityDetail } from '@mark43/rms-api';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'trafficCrashEntityDetails';
const trafficCrashEntityDetailModule = createNormalizedModule<TrafficCrashEntityDetail>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS

export const trafficCrashEntityDetailsWhereSelector =
    trafficCrashEntityDetailModule.selectors.entitiesWhereSelector;

// REDUCER
export default trafficCrashEntityDetailModule.reducerConfig;

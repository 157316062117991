/** NOTE: Helpers in this file are client concerns, which is why they are not in client-common/helpers/nameNameLInksHelpers.js */

import _, { map, first, filter } from 'lodash';
import { NameNameLink, EntityTypeEnumType } from '@mark43/rms-api';

import {
    filterNameNameLinks,
    nameNameLinkOptionIsOne,
    nameNameLinkOptionIsTwo,
    convertNameNameLinkOptionIdToTypeId,
    linkTypeHasDescriptionField,
} from '~/client-common/helpers/nameNameLinksHelpers';
import { LinkTypeViewModel } from '~/client-common/helpers/linkTypesHelpers';
import { dateTimeFormats, formatISODate } from '~/client-common/core/dates/utils/dateHelpers';

type NameNameLinkViewModel = NameNameLink & {
    otherNameId: number;
    otherNameEntityType: string;
    linkOptionId: string;
};

type NameNameLinkTypeViewModels = Record<number, LinkTypeViewModel>;

/**
 * Parse the given relationship from the perspective of the given person/org.
 * @return The `nameNameLink` with additional computed properties.
 */
export function buildNameNameLinkViewModel(
    nameNameLink: NameNameLink,
    nameId: number,
    nameNameLinkTypeViewModel: LinkTypeViewModel
): NameNameLinkViewModel {
    const isTwo = nameNameLink.nameToId === nameId;
    const linkNameOneOrTwo = `linkName${isTwo ? 'Two' : 'One'}`;
    const linkOptionIdSuffix = nameNameLinkTypeViewModel.symmetric ? '' : `-${linkNameOneOrTwo}`;

    return {
        ...nameNameLink,
        otherNameId: nameNameLink[isTwo ? 'nameFromId' : 'nameToId'],
        otherNameEntityType: isTwo ? nameNameLink.entityTypeFrom : nameNameLink.entityTypeTo,
        linkOptionId: `${nameNameLink.linkTypeId}${linkOptionIdSuffix}`,
    };
}

/**
 * Parse the given relationships from the perspective of the given person/org.
 * @param  nameNameLinkTypeViewModels Needed for relationship
 *   parsing.
 * @return The `nameNameLinks` with additional computed properties.
 */
function buildNameNameLinkViewModels(
    nameNameLinks: NameNameLink[],
    nameId: number,
    nameNameLinkTypeViewModels: NameNameLinkTypeViewModels
): NameNameLinkViewModel[] {
    return _.map(nameNameLinks, (nameNameLink) => {
        return buildNameNameLinkViewModel(
            nameNameLink,
            nameId,
            nameNameLinkTypeViewModels[nameNameLink.linkTypeId]
        );
    });
}

/**
 * Compute the `linkOptionId` strings representing the relationships from the
 *   first person/org to the second person/org.
 * @param  nameNameLinks Can contain more links than just the ones
 *   between the two persons/orgs.
 * @param  nameNameLinkTypeViewModels Needed for relationship
 *   parsing.
 */
export function buildLinkOptionIds(
    nameNameLinks: NameNameLink[],
    nameId: number,
    otherNameId: number,
    nameNameLinkTypeViewModels: NameNameLinkTypeViewModels
): string[] {
    const filteredLinks = filterNameNameLinks(nameNameLinks, nameId, otherNameId);
    const viewModels = buildNameNameLinkViewModels(
        filteredLinks,
        nameId,
        nameNameLinkTypeViewModels
    );
    return _.map(viewModels, 'linkOptionId');
}

/**
 * Convert the given data model of relationships into a form model as applied to
 *   the given person/org (singular!). The `nameNameLinks` array may include
 *   relationships that do not involve the given person/org, as those will get
 *   filtered out. The resulting form state may include fields with no
 *   relationships.
 * @param  names Only the properties `nameId` and `nameEntityType`
 *   are required for this method. The order matters, so sort these names before
 *   passing them in.
 * @param  nameNameLinkTypeViewModels Needed for relationship
 *   parsing.
 */
export function convertNameNameLinksDataStateToFormState(
    nameNameLinks: NameNameLink[],
    names: { nameId: number; nameEntityType: string }[],
    nameId: number,
    nameNameLinkTypeViewModels: NameNameLinkTypeViewModels,
    isRelationshipsPrefillEnabled: boolean
): {
    otherNameId: number;
    otherNameEntityType: string;
    linkOptionIds: string[];
    description: string;
}[] {
    return _(names)
        .reject({ nameId })
        .map(({ nameId: otherNameId, nameEntityType: otherNameEntityType }) => {
            const filteredLinks = filterNameNameLinks(nameNameLinks, nameId, otherNameId);

            const linkOptionIds = buildLinkOptionIds(
                nameNameLinks,
                nameId,
                otherNameId,
                nameNameLinkTypeViewModels
            );

            const links = {
                otherNameId,
                otherNameEntityType,
                linkOptionIds: buildLinkOptionIds(
                    nameNameLinks,
                    nameId,
                    otherNameId,
                    nameNameLinkTypeViewModels
                ),
                description: getNameNameLinkDescription(nameNameLinks, nameId, otherNameId),
                relationshipLinkTypes: isRelationshipsPrefillEnabled
                    ? map(linkOptionIds, (displayLinkOptionId) => {
                          const linkOptionId = parseInt(displayLinkOptionId, 10);
                          const nameNameLink = first(
                              filter(filteredLinks, (link) => link.linkTypeId === linkOptionId)
                          );

                          return {
                              description: nameNameLink?.description,
                              effectiveTo: nameNameLink?.dateEffectiveTo,
                              effectiveFrom: nameNameLink?.dateEffectiveFrom
                                  ? nameNameLink?.dateEffectiveFrom
                                  : formatISODate(new Date(), dateTimeFormats.isoDate),
                              linkOptionIds: linkOptionId,
                              displayLinkOptionIds: displayLinkOptionId,
                          };
                      })
                    : [],
            };

            return links;
        })
        .value();
}

/**
 * Get the description for the given link that's calculated from all the links
 */
function getNameNameLinkDescription(
    nameNameLinks: NameNameLink[],
    nameId: number,
    otherNameId: number
): string {
    return _.chain(nameNameLinks)
        .find(
            ({ nameFromId, nameToId, description }) =>
                ((nameFromId === nameId && nameToId === otherNameId) ||
                    (nameFromId === otherNameId && nameToId === nameId)) &&
                !!description
        )
        .get('description')
        .value();
}

interface NameNameLinkDataState {
    linkTypeId: number;
    nameFromId: number;
    entityTypeFrom: string;
    nameToId: number;
    entityTypeTo: string;
    description: string;
    dateEffectiveFrom?: string;
    dateEffectiveTo?: string;
}

/**
 * Build the data model for a nameNameLink with the given computed properties.
 *   The returned object is always the same in terms of from-to properties when
 *   the two names are switched, even if the link type is symmetric. This makes
 *   it easy to merge arrays of links across names.
 */
export function buildNameNameLinkDataState(
    linkOptionId: string,
    nameId: number,
    nameEntityType: EntityTypeEnumType,
    otherNameId: number,
    otherNameEntityType: string,
    description: string,
    // Make `dateEffectiveFrom` and `dateEffectiveTo` required properties
    // during the tear down of `RMS_RELATIONSHIP_PREFILL_ENABLED`.
    dateEffectiveFrom?: string,
    dateEffectiveTo?: string
): NameNameLinkDataState {
    const linkTypeId = convertNameNameLinkOptionIdToTypeId(linkOptionId);
    const linkDescription = linkTypeHasDescriptionField(linkTypeId) ? description : '';
    const isOne = nameNameLinkOptionIsOne(linkOptionId);
    const isTwo = nameNameLinkOptionIsTwo(linkOptionId);
    const symmetric = !isOne && !isTwo;

    // if the link type is symmetric, always order the ids ascending so
    // identical links result for both names
    const isFrom = isOne || (symmetric && nameId < otherNameId);

    if (isFrom) {
        return {
            linkTypeId,
            nameFromId: nameId,
            entityTypeFrom: nameEntityType,
            nameToId: otherNameId,
            entityTypeTo: otherNameEntityType,
            description: linkDescription,
            dateEffectiveFrom,
            dateEffectiveTo,
        };
    } else {
        return {
            linkTypeId,
            nameFromId: otherNameId,
            entityTypeFrom: otherNameEntityType,
            nameToId: nameId,
            entityTypeTo: nameEntityType,
            description: linkDescription,
            dateEffectiveFrom,
            dateEffectiveTo,
        };
    }
}

import _, { keys, omitBy } from 'lodash';

const MAX_RECENT_USERS = 10;

/**
 * Given a storageType, check that it is supported and is working.
 * @param  {String}   storageType  localStorage or sessionStorage
 * @return {Boolean}  the storageType is working or not.
 */
function storageAvailable(storageType) {
    try {
        const storage = window[storageType];
        const testString = '__storage_test__';

        storage.setItem(testString, testString);
        storage.removeItem(testString);
        return true;
    } catch (e) {
        return false;
    }
}

/**
 * Set a key/value pair in local storage.
 * Values in local storage must be strings; use JSON to convert and parse.
 * @param  {String}   key    name of key
 * @param  {Object}   value  data to be saved
 */
export function setItem(key, value) {
    if (storageAvailable('localStorage')) {
        localStorage.setItem(key, JSON.stringify(value));
    }
}

/**
 * Retrieve from local storage the value at specified key.
 * Values in local storage must be strings; use JSON to convert and parse.
 * @param  {String}   key  name of key
 * @return {Object}   the value in local storage at the given key
 */
export function getItem(key) {
    if (storageAvailable('localStorage')) {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return;
        }
    }
}

/**
 * Keep a limited number of users in local storage.
 * Function to remove least recent users.
 * @param  {Object}  key  local storage data
 * @return {Object}  local storage data less older users
 */
export function removeOlderUsers(localStorage) {
    if (keys(localStorage).length > MAX_RECENT_USERS) {
        const omitBeforeDate = _(localStorage)
            .map('lastActionTimeStamp')
            .sortBy() // earliest to latest
            .takeRight(MAX_RECENT_USERS)
            .head();

        return omitBy(
            localStorage,
            ({ lastActionTimeStamp }) => lastActionTimeStamp < omitBeforeDate
        );
    } else {
        return localStorage;
    }
}

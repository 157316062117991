import { map } from 'lodash';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button as _Button, useToast } from 'arc';
import {
    SqlSearchQueryInventorySqlQuery,
    EntityTypeEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    UsageSourceModuleEnum,
} from '@mark43/evidence-api';
import { useSelector, useDispatch } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { createUsageLog } from '../../../admin/usage-logs/state/data';
import inventoryExportResource from '../resources/inventoriesExportResource';
import { inventoriesDashboardSearch } from '../state/ui';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';

const ActionBarContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 62px;
    background-color: ${({ theme }) => theme.colors.brightBlue};
    color: ${({ theme }) => theme.colors.white};
    font-size: var(--arc-fontSizes-md);
`;

const ActionBarContent = styled.div`
    width: 970px;
    margin: 10px auto;

    .select-action {
        text-decoration: underline;
        cursor: pointer;
    }
`;

const Button = styled(_Button)`
    margin-right: 10px;
    width: 80px;
`;

const TextWrapper = styled.span`
    text-decoration: underline;
`;

const strings = componentStrings.evidence.inventories.actionBar;

const InventoriesDashboardSearchResultFooter: React.FC = () => {
    const toast = useToast();
    const dispatch = useDispatch();
    const selectedRows: number[] = useSelector(
        // @ts-expect-error client-common to client RND-7529
        inventoriesDashboardSearch.selectors.selectedRowsSelector
    );
    // @ts-expect-error client-common to client RND-7529
    const totalCount: number = useSelector(inventoriesDashboardSearch.selectors.totalCountSelector);
    const allResultsSelected = useSelector(
        // @ts-expect-error client-common to client RND-7529
        inventoriesDashboardSearch.selectors.allResultsSelectedSelector
    );
    const query: SqlSearchQueryInventorySqlQuery = useSelector(
        // @ts-expect-error client-common to client RND-7529
        inventoriesDashboardSearch.selectors.currentQuerySelector
    );
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    let statusText: string;
    if (allResultsSelected) {
        statusText = strings.allSelected(totalCount);
    } else if (selectedRows.length === query.size) {
        statusText = strings.allOnPageSelected(selectedRows.length);
    } else {
        statusText = strings.someOnPageSelected(selectedRows.length);
    }

    const onExportClick = useCallback(() => {
        inventoryExportResource
            .getInventoryPrintables()
            .then((printables) => {
                if (printables?.length > 0) {
                    dispatch(
                        // @ts-expect-error client-common to client RND-7529
                        inventoriesDashboardSearch.actionCreators.exportResults(
                            selectedRows,
                            !!allResultsSelected,
                            map(printables, 'printingTemplateId')
                        )
                    );
                    dispatch(
                        createUsageLog({
                            primaryEntityType: EntityTypeEnum.EVIDENCE_INVENTORY.name,
                            primaryEntityTitle: `Export ${selectedRows.length} Inventories`,
                            primaryEntityDepartmentId: currentUserDepartmentId,
                            sourceModule: UsageSourceModuleEnum.EVIDENCE.name,
                            action: UsageActionEnum.EXPORTED_INVENTORIES.name,
                            completion: UsageCompletionEnum.SUCCEEDED.name,
                        })
                    );
                } else {
                    dispatch(
                        createUsageLog({
                            primaryEntityType: EntityTypeEnum.EVIDENCE_INVENTORY.name,
                            primaryEntityTitle: `Export ${selectedRows.length} Inventories`,
                            primaryEntityDepartmentId: currentUserDepartmentId,
                            sourceModule: UsageSourceModuleEnum.EVIDENCE.name,
                            action: UsageActionEnum.EXPORTED_INVENTORIES.name,
                            completion: UsageCompletionEnum.SERVER_ERROR.name,
                        })
                    );
                    throw new Error('Failed to export inventory, please reload and try again');
                }
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    description: err.message,
                });
            });
    }, [dispatch, selectedRows, allResultsSelected, currentUserDepartmentId, toast]);

    const onClearSelection = useCallback(() => {
        // @ts-expect-error client-common to client RND-7529
        dispatch(inventoriesDashboardSearch.actionCreators.selectRows([]));
    }, [dispatch]);

    const onSelectAll = useCallback(() => {
        // @ts-expect-error client-common to client RND-7529
        dispatch(inventoriesDashboardSearch.actionCreators.selectAllResults());
    }, [dispatch]);

    if (selectedRows.length === 0) {
        return <></>;
    }

    return (
        <ActionBarContainer>
            <ActionBarContent>
                <Button colorVariant="white" variant="solid" onClick={onExportClick}>
                    {strings.export}
                </Button>
                {`${statusText} `}
                {selectedRows.length === totalCount ? (
                    <TextWrapper onClick={onClearSelection}>{strings.clearSelection}</TextWrapper>
                ) : (
                    <TextWrapper onClick={onSelectAll}>{strings.selectAll(totalCount)}</TextWrapper>
                )}
            </ActionBarContent>
        </ActionBarContainer>
    );
};

export default InventoriesDashboardSearchResultFooter;

import { EntityTypeEnum, OperationTypeEnum } from '@mark43/rms-api';
import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose, withHandlers, withPropsOnChange } from 'recompose';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import OnlyWithEntityPermission from '~/client-common/core/domain/entity-permissions/components/OnlyWithEntityPermission';
import {
    WARRANT_WARRANT_NUMBER,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import { warrantViewModelByIdSelector } from '~/client-common/core/domain/warrants/state/ui';
import { mostRecentWarrantStatusViewModelByWarrantIdSelector } from '~/client-common/core/domain/warrant-statuses/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import RecordsHeader from '../../../records/core/components/header/RecordsHeader';
import RecordsHeaderPermissionsButton from '../../../records/core/components/header/RecordsHeaderPermissionsButton';
import RecordsHeaderExportsButton from '../../../records/core/components/header/RecordsHeaderExportsButton';
import RecordsHeaderHamburgerMenu from '../../../records/core/components/header/RecordsHeaderHamburgerMenu';
import RecordsHeaderCurrentViewersToggle from '../../../records/core/components/header/RecordsHeaderCurrentViewersToggle';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import GenericPermissionsModal from '../../../core/permissions/components/GenericPermissionsModal';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import routesConfig from '../../../../routing/routesConfig';
import TaskSidePanel from '../../../tasks/core/components/TaskSidePanel';
import { openTaskSidePanelForEntity } from '../../../tasks/core/state/ui';
import {
    openChangeWarrantNumberModalForWarrant,
    openDeleteWarrantModalForWarrant,
} from '../state/ui';
import Subheader from '../../../core/components/Subheader';
import { ButtonGroup } from '../../../core/components/Button';

import WarrantSendToClipsModal from './WarrantSendToClipsModal';
import WarrantHeaderHistoryButton from './WarrantHeaderHistoryButton';

const strings = componentStrings.warrants.warrant.WarrantHeader;

const WarrantHamburgerOption = styled.div`
    width: 200px !important;
`;

const Container = styled.span`
    display: inline-flex;
`;

function useOpenWarrantSendToClipsModal() {
    const overlayStore = useOverlayStore();
    const open = useCallback(() => overlayStore.open(overlayIdEnum.WARRANT_SEND_TO_CLIPS_MODAL), [
        overlayStore,
    ]);

    return open;
}

/**
 * Header that appears on a warrant page. Don't confuse this with `WarrantsHeader`,
 *   which is the header for warrants dashboard pages.
 */
function WarrantHeader({
    warrantViewModel,
    warrantStatusViewModel,
    onClickChangeWarrantNumber,
    onClickDeleteWarrant,
    warrantId,
    fieldDisplayNames,
    currentUserDepartmentId,
}) {
    const { warrantShortTitle } = getViewModelProperties(warrantViewModel);
    const { warrantStatusAttrId } = getViewModelProperties(warrantStatusViewModel);
    const { id, permissionSet, departmentId } = warrantViewModel || {};

    const dispatch = useDispatch();
    const onClickCreateTask = React.useCallback(() => {
        dispatch(
            openTaskSidePanelForEntity([
                {
                    entityId: warrantId,
                    entityType: EntityTypeEnum.WARRANT.name,
                    entityTitle: warrantShortTitle,
                },
            ])
        );
    }, [dispatch, warrantId, warrantShortTitle]);

    const subtitleParts = [{ name: strings.status, value: warrantStatusAttrId }];

    const isCrossAgency = departmentId !== currentUserDepartmentId;

    const overlayId = overlayIdEnum.WARRANT_PERMISSIONS_OVERLAY;

    const openWarrantSendToClipsModal = useOpenWarrantSendToClipsModal();
    return (
        <Subheader
            title={warrantShortTitle}
            content={<RecordsHeader subtitleParts={subtitleParts} departmentId={departmentId} />}
        >
            <ButtonGroup spacing="var(--arc-space-1)">
                {!isCrossAgency && (
                    <OnlyWithAbility has={abilitiesEnum.WARRANTS.WARRANT_EXPORTS}>
                        <RecordsHeaderExportsButton
                            exportRouteName={routesConfig.WARRANT_EXPORTS.name}
                            path={`/warrants/${warrantId}`}
                        />
                    </OnlyWithAbility>
                )}
                {!isCrossAgency && (
                    <Container>
                        <GenericPermissionsModal
                            buttonElement={<RecordsHeaderPermissionsButton overlayId={overlayId} />}
                            entityId={id}
                            entityType={EntityTypeEnum.WARRANT.name}
                            overlayId={overlayId}
                            permissionSet={permissionSet}
                            title={strings.permissionsModalTitle(warrantShortTitle)}
                        />
                    </Container>
                )}
                <FeatureFlagged flag="RMS_WARRANT_HISTORY_ENABLED">
                    <OnlyWithAbility has={abilitiesEnum.WARRANTS.VIEW_GENERAL}>
                        <WarrantHeaderHistoryButton warrantId={warrantId} />
                    </OnlyWithAbility>
                </FeatureFlagged>
                <RecordsHeaderCurrentViewersToggle
                    entityId={warrantId}
                    entityType={EntityTypeEnum.WARRANT.name}
                />
                {!isCrossAgency && (
                    <OnlyWithEntityPermission
                        permissionSet={permissionSet}
                        has={OperationTypeEnum.WRITE.name}
                    >
                        <OnlyWithAbility has={abilitiesEnum.WARRANTS.EDIT_GENERAL}>
                            <RecordsHeaderHamburgerMenu>
                                <WarrantHamburgerOption
                                    className="dropdown-menu-option"
                                    onClick={onClickChangeWarrantNumber}
                                >
                                    {strings.changeWarrantNumber(
                                        fieldDisplayNames.WARRANT_WARRANT_NUMBER
                                    )}
                                </WarrantHamburgerOption>
                                <OnlyWithAbility has={abilitiesEnum.WARRANTS.DELETE_GENERAL}>
                                    <WarrantHamburgerOption
                                        className="dropdown-menu-option"
                                        onClick={onClickDeleteWarrant}
                                    >
                                        {strings.deleteWarrant}
                                    </WarrantHamburgerOption>
                                </OnlyWithAbility>
                                <FeatureFlagged flag="RMS_TASK_ENTITY_LINKS_ENABLED">
                                    <OnlyWithAbility has={abilitiesEnum.CORE.EDIT_TASKS}>
                                        <WarrantHamburgerOption
                                            className="dropdown-menu-option"
                                            onClick={onClickCreateTask}
                                        >
                                            {strings.createTask}
                                        </WarrantHamburgerOption>
                                    </OnlyWithAbility>
                                </FeatureFlagged>
                                <FeatureFlagged flag="RMS_CLIPS_DEX_INTEGRATION_ENABLED">
                                    <OnlyWithAbility has={abilitiesEnum.DATA_EXCHANGE.CLIPS_SUBMIT}>
                                        <WarrantHamburgerOption
                                            className="dropdown-menu-option"
                                            onClick={openWarrantSendToClipsModal}
                                        >
                                            {strings.sendToClips}
                                        </WarrantHamburgerOption>
                                        <WarrantSendToClipsModal />
                                    </OnlyWithAbility>
                                </FeatureFlagged>
                            </RecordsHeaderHamburgerMenu>
                            <FeatureFlagged flag="RMS_TASK_ENTITY_LINKS_ENABLED">
                                <TaskSidePanel />
                            </FeatureFlagged>
                        </OnlyWithAbility>
                    </OnlyWithEntityPermission>
                )}
            </ButtonGroup>
        </Subheader>
    );
}

const mapDispatchToProps = (dispatch) => ({
    openChangeWarrantNumberModal: (warrant) =>
        dispatch(openChangeWarrantNumberModalForWarrant(warrant)),
    openDeleteWarrantModal: (warrant) => dispatch(openDeleteWarrantModalForWarrant(warrant.id)),
});

const mapStateToProps = createStructuredSelector({
    warrantViewModelById: warrantViewModelByIdSelector,
    mostRecentWarrantStatusViewModelByWarrantId: mostRecentWarrantStatusViewModelByWarrantIdSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    applicationSettings: applicationSettingsSelector,
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['warrantViewModelById', 'mostRecentWarrantStatusViewModelByWarrantId', 'warrantId'],
        ({ warrantViewModelById, mostRecentWarrantStatusViewModelByWarrantId, warrantId }) => ({
            warrantViewModel: warrantViewModelById(warrantId),
            warrantStatusViewModel: mostRecentWarrantStatusViewModelByWarrantId(warrantId),
        })
    ),
    withFields([WARRANT_WARRANT_NUMBER, REPORT_REPORTING_EVENT_NUMBER]),
    withHandlers({
        onClickChangeWarrantNumber({ openChangeWarrantNumberModal, warrantViewModel }) {
            return () => openChangeWarrantNumberModal(warrantViewModel);
        },
        onClickDeleteWarrant({ openDeleteWarrantModal, warrantViewModel }) {
            return () => openDeleteWarrantModal(warrantViewModel);
        },
    })
)(WarrantHeader);

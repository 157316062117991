import { createSelector } from 'reselect';
import { map, sortBy, parseInt, get, noop } from 'lodash';

import { consortiumDepartmentLinksWhereSelector } from '~/client-common/core/domain/consortium-link-view/state/data';
import { departmentProfileByIdSelector } from '~/client-common/core/domain/department-profiles/state/data';

import {
    currentUserConsortiumIdSelector,
    currentUserDepartmentProfileSelector,
} from '../../../../core/current-user/state/ui';
import {
    grantPermissions,
    GRANT_PERMISSIONS_START,
    GRANT_PERMISSIONS_SUCCESS,
    GRANT_PERMISSIONS_FAILURE,
    GET_PERMISSION_STATUSES_START,
    GET_PERMISSION_STATUSES_SUCCESS,
    GET_PERMISSION_STATUSES_FAILURE,
} from '../data';
import {
    BULK_ENTITY_PERMISSIONS_ADMIN_FORM,
    clearHiddenStartDateUtc,
} from '../forms/bulkEntityPermissionsAdminForm';

const SELECT_BULK_ENTITY_PERMISSIONS_START =
    'bulk-entity-permissions/SELECT_BULK_ENTITY_PERMISSIONS_START';
const SELECT_BULK_ENTITY_PERMISSIONS_SUCCESS =
    'bulk-entity-permissions/SELECT_BULK_ENTITY_PERMISSIONS_SUCCESS';
const SELECT_BULK_ENTITY_PERMISSIONS_FAILURE =
    'bulk-entity-permissions/SELECT_BULK_ENTITY_PERMISSIONS_FAILURE';

function selectBulkEntityPermissionsStart() {
    return { type: SELECT_BULK_ENTITY_PERMISSIONS_START };
}

function selectBulkEntityPermissionsSuccess(id) {
    return { type: SELECT_BULK_ENTITY_PERMISSIONS_SUCCESS, payload: id };
}

function selectBulkEntityPermissionsFailure(errMsg) {
    return { type: SELECT_BULK_ENTITY_PERMISSIONS_FAILURE, error: true, payload: errMsg };
}

export const uiSelector = (state) => state.ui.bulkEntityPermissionsAdmin;

const departmentInfoListSelector = createSelector(
    currentUserDepartmentProfileSelector,
    currentUserConsortiumIdSelector,
    consortiumDepartmentLinksWhereSelector,
    (currentUserDepartmentProfile, currentUserConsortiumId, consortiumDepartmentLinksWhere) =>
        currentUserConsortiumId
            ? sortBy(
                  consortiumDepartmentLinksWhere({ consortiumId: currentUserConsortiumId }),
                  (consortiumDepartmentLink) =>
                      consortiumDepartmentLink.deptId !== currentUserDepartmentProfile.departmentId
              )
            : [
                  {
                      deptId: currentUserDepartmentProfile.departmentId,
                      deptDisplayName: currentUserDepartmentProfile.displayName,
                  },
              ]
);

export const departmentListItemsSelector = createSelector(
    uiSelector,
    departmentInfoListSelector,
    (ui, departmentInfoList) =>
        map(departmentInfoList, (deptInfo) => ({
            path: `/admin/bulk-update-permissions/department/${deptInfo.deptId}`,
            title: deptInfo.deptDisplayName,
            key: deptInfo.deptId,
            selected: deptInfo.deptId === ui.selectedDepartmentId,
        }))
);

export function selectBulkEntityPermissions(deptId) {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(selectBulkEntityPermissionsStart());

        const state = getState();
        const id = parseInt(deptId);
        const selectedDeptProfile = departmentProfileByIdSelector(state)(id);
        const currentUserDeptProfile = currentUserDepartmentProfileSelector(state);
        const isExternalDepartment =
            get(currentUserDeptProfile, 'departmentId') !==
            get(selectedDeptProfile, 'departmentId');
        if (selectedDeptProfile) {
            formsRegistry.maybeDeferredOperation(
                BULK_ENTITY_PERMISSIONS_ADMIN_FORM,
                undefined,
                (form) => {
                    form.resetModel();
                    form.set(
                        'batchEntityPermissionRequests',
                        map(
                            get(form.getState().model, 'batchEntityPermissionRequests'),
                            (request) => {
                                return {
                                    ...request,
                                    isExternalDepartment,
                                };
                            }
                        )
                    );
                }
            );
            dispatch(selectBulkEntityPermissionsSuccess(id));
        } else {
            dispatch(selectBulkEntityPermissionsFailure('Failed to get department profile.'));
        }
    };
}

export function submitBulkEntityPermissionsAdminForm({ currentDeptId, selectedDeptId }) {
    return (dispatch, getState, { formsRegistry }) =>
        formsRegistry
            .get(BULK_ENTITY_PERMISSIONS_ADMIN_FORM)
            .submit()
            .then((result) => {
                const batchEntityPermissionRequests = get(
                    result.form.getState().model,
                    'batchEntityPermissionRequests'
                );
                dispatch(
                    grantPermissions({
                        entityDepartmentId: currentDeptId,
                        permissionDepartmentId: selectedDeptId,
                        batchEntityPermissionRequests: clearHiddenStartDateUtc(
                            batchEntityPermissionRequests
                        ),
                    })
                );
            })
            .catch(noop);
}

const initialUiState = {
    formError: '',
    selectedDepartmentId: null,
    grantRequestStatusError: '',
    grantPermissionStatuses: [],
};

export default function bulkEntityPermissionsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_BULK_ENTITY_PERMISSIONS_START:
            return {
                ...state,
                selectedDepartmentId: null,
                formError: '',
            };
        case SELECT_BULK_ENTITY_PERMISSIONS_SUCCESS:
            return {
                ...state,
                selectedDepartmentId: parseInt(action.payload),
                formError: '',
            };
        case SELECT_BULK_ENTITY_PERMISSIONS_FAILURE:
            return {
                ...state,
                selectedDepartmentId: null,
                formError: action.payload,
            };
        case GRANT_PERMISSIONS_START:
            return {
                ...state,
                formError: '',
            };
        case GRANT_PERMISSIONS_SUCCESS:
            return {
                ...state,
                formError: '',
            };
        case GRANT_PERMISSIONS_FAILURE:
            return {
                ...state,
                formError: action.payload,
            };
        case GET_PERMISSION_STATUSES_START:
            return {
                ...state,
                grantRequestStatusError: '',
                grantPermissionStatuses: [],
            };
        case GET_PERMISSION_STATUSES_SUCCESS:
            return {
                ...state,
                grantRequestStatusError: '',
                grantPermissionStatuses: action.payload,
            };
        case GET_PERMISSION_STATUSES_FAILURE:
            return {
                ...state,
                grantRequestStatusError: action.payload,
                grantPermissionStatuses: [],
            };
        default:
            return state;
    }
}

import { map } from 'lodash';
import { createSelector } from 'reselect';

import {
    allSingleAttributeValuesMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { formatOffenseCodeByIdSelector } from '../../../offense-codes/state/ui';
import { citationChargesWhereSelector } from '../data';

const buildCitationChargeViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    formatOffenseCodeByIdSelector,
    (formatAttributeById, formatOffenseCodeById) => {
        const viewModel = buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                ({ offenseCodeId }) => ({
                    offenseCodeDisplayName: formatOffenseCodeById({
                        id: offenseCodeId,
                        includeCode: false,
                    }),
                }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return viewModel;
    }
);

const citationChargeViewModelsWhereSelector = createSelector(
    citationChargesWhereSelector,
    buildCitationChargeViewModelSelector,
    (citationChargesWhere, buildCitationChargeViewModel) => (predicate) =>
        map(citationChargesWhere(predicate), buildCitationChargeViewModel)
);

export const citationChargeViewModelsByCitationIdSelector = createSelector(
    citationChargeViewModelsWhereSelector,
    (citationChargeViewModelsWhere) => (citationId) => citationChargeViewModelsWhere({ citationId })
);

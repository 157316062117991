import { AnalyticsPropertyEnumType } from '../../constants/analyticsEnum';

export const TRACK_ANALYTICS_START = 'analytics/TRACK_ANALYTICS_START';

export function trackAnalyticsStart(
    analyticsProperties: {
        [analyticsKey in AnalyticsPropertyEnumType]?: unknown;
    },
    testIdToFilterBy?: string
) {
    return {
        type: TRACK_ANALYTICS_START,
        payload: analyticsProperties,
        meta: { testIdToFilterBy },
    };
}

import { EFileTitle, EntityTypeEnumType } from '@mark43/rms-api';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResource } from '~/client-common/core/hooks/useResource';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { currentUserHasAbilitySelector } from '../../core/current-user/state/ui';
import { eFileResource } from '../resources/eFileResource';

export function useEFilesRelatedToEntity({
    entityId,
    entityType,
}: {
    entityId: number;
    entityType: EntityTypeEnumType;
}) {
    const [eFileTitles, setEFileTitles] = useState([] as EFileTitle[]);
    const isEFileFeatureEnabled = useSelector(applicationSettingsSelector).RMS_E_FILING_ENABLED;
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const canViewEFiles = currentUserHasAbility(abilitiesEnum.EFILES.VIEW);

    const shouldFetch = canViewEFiles && isEFileFeatureEnabled;

    const fetchRelatedEFiles = useCallback(
        () =>
            entityId && shouldFetch
                ? eFileResource.getLinkedEFiles({
                      entityId,
                      entityType,
                  })
                : new Promise((resolve) => resolve([])),
        [entityId, entityType, shouldFetch]
    );

    const onFetchLinkedEFilesSuccess = useCallback((eFileTitles) => {
        setEFileTitles(eFileTitles);
    }, []);

    const loading = useResource(fetchRelatedEFiles, onFetchLinkedEFilesSuccess);

    return { eFileTitles, loading };
}

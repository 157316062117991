import { isFinite } from 'lodash';

// we are converting to a precision of 5 decimal points
// given the information available on https://en.wikipedia.org/wiki/Decimal_degrees
// 5 decimal points give us an accuracy of ~ 1m, which should be absolutely fine to determine
// if a position has changed
const DECIMAL_FACTOR = 1000000;
const TOLERANCE_THRESHOLD = 15;

function toComparableInteger(num) {
    return Math.floor(parseFloat(num) * DECIMAL_FACTOR);
}

export function areLatLngsEqual(location1, location2) {
    if (!location1 || !location2) {
        return !location1 && !location2;
    }
    const lat1 = location1.lat || location1.latitude;
    const lng1 = location1.lng || location1.longitude;
    const lat2 = location2.lat || location2.latitude;
    const lng2 = location2.lng || location2.longitude;
    return (
        Math.abs(toComparableInteger(lat1) - toComparableInteger(lat2)) <= TOLERANCE_THRESHOLD &&
        Math.abs(toComparableInteger(lng1) - toComparableInteger(lng2)) <= TOLERANCE_THRESHOLD
    );
}

/**
 * covnert ployline data to PolylineT
 *
 * @param {{lat: number, lng: number}[]} polyline, start and end point [{lat, lng}, {lat, lng}]
 * @param {'UNIT'|'USER'|''} type
 * @param {number} id
 * @returns {PolylineT}
 */
export function convertToEsriPolyline(polyline, type = '', id = 0) {
    return {
        path: [
            { lat: polyline?.[0]?.lat || 0, lng: polyline?.[0]?.lng || 0 },
            { lat: polyline?.[1]?.lat || 0, lng: polyline?.[1]?.lng || 0 },
        ],
        id,
        type,
    };
}

/**
 * convert vertices data to PolygonT
 *
 * @param {{lat: number, lng: number}} center
 * @param {{lat: number, lng: number}[]} vertices
 * @param {number} id
 * @param {'POLYGON'|'CIRCLE'} type
 * @param {number} radius
 * @param {'METERS'|'MILES'} radisUnit
 * @returns {PolygonT}
 */
export function convertToEsriPolygon(
    center,
    vertices,
    id = 0,
    type = 'POLYGON',
    radius = 1000,
    radisUnit = 'METERS'
) {
    if (type === 'POLYGON') {
        return {
            rings: (vertices || [])
                .filter((each) => isFinite(each?.lat) && isFinite(each?.lng))
                .map((each) => [each?.lng, each?.lat]),
            id,
            type,
        };
    } else if (type === 'CIRCLE') {
        return {
            center: [center.lng, center.lat],
            id,
            type,
            radius,
            radisUnit,
        };
    }
    return {};
}

/**
 * Converts a string to a hex color. For use with the geoJSON layer for marking regions
 * with different colors.
 * @param {string} str
 * @returns
 */
export const stringToColor = (str, alpha = 1) => {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + (hash << 6) + (hash << 16) - hash;
    }

    const rgba = [];

    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xfff;
        const hexValue = (value * 0xfffff * 1000000).toString(16);
        rgba.push(parseInt(`00${hexValue}`.substring(hexValue.length), 16));
    }

    rgba.push(alpha);

    return rgba;
};

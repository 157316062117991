import { DutyStatusEnum } from '@mark43/rms-api';

import React from 'react';
import { map } from 'lodash';
import { prettify } from '~/client-common/helpers/stringHelpers';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const dutyStatusOptions = map(DutyStatusEnum, (dutyStatus) => ({
    display: prettify(dutyStatus.name),
    value: dutyStatus.name,
}));

function DutyStatusSelect(props) {
    return <Select options={dutyStatusOptions} {...props} />;
}

export const RRFDutyStatusSelect = connectRRFInput(DutyStatusSelect);
export default DutyStatusSelect;

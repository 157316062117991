import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { isExpired } from '~/client-common/core/dates/utils/dateHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { facilitiesSelector } from '~/client-common/core/domain/facilities/state/data';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import stringsConfig from '~/client-common/core/strings';

import facilityAdminForm from '../state/forms/facilityAdminForm';
import {
    loadingFacilityLocationSelector,
    newFacilityFormIsOpenSelector,
    selectedFacilityIdSelector,
    closeFacilityForm,
    submitFacilityForm,
} from '../state/ui';
import AdminListStatus from '../../core/components/AdminDateStatus';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import Row from '../../../core/components/Row';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFCountrySelect } from '../../../core/forms/components/selects/CountrySelect';

const { ACTIVE, EXPIRED } = attributeStatuses;
const strings = stringsConfig.components.admin.evidenceFacilities.FacilityAdminForm;

const context = {
    name: boxEnum.FACILITY_ADMIN_SIDE_PANEL,
};

function FacilityAdminForm({
    loading,
    newFacilityFormIsOpen,
    selectedFacility,
    onSubmit,
    closeFacilityForm,
}) {
    return (
        <SidePanel
            title={newFacilityFormIsOpen ? strings.titles.add : strings.titles.edit}
            onSave={onSubmit}
            onCancel={closeFacilityForm}
            context={context}
            width={593}
        >
            {loading ? (
                <SimpleLoading />
            ) : (
                <ReactReduxForm {...facilityAdminForm}>
                    <Row>
                        <RRFText
                            label={strings.labels.locationName}
                            width={345}
                            path="locationName"
                        />
                    </Row>
                    <Row>
                        <RRFAttributeSelect
                            attributeType="EVIDENCE_FACILITY"
                            width={345}
                            path="locationTypeAttrId"
                        />
                    </Row>
                    <Row>
                        <RRFText
                            label={strings.labels.streetAddress}
                            width={345}
                            path="streetAddress"
                        />
                        <RRFText label={strings.labels.subPremise} width={96} path="subPremise" />
                    </Row>
                    <Row>
                        <RRFText label={strings.labels.locality} width={158} path="locality" />
                        <RRFText label={strings.labels.adminArea1} width={76} path="adminArea1" />
                        <RRFText label={strings.labels.postalCode} width={96} path="postalCode" />
                        <RRFCountrySelect width={270} path="country" />
                    </Row>
                    <Row>
                        <RRFText
                            label={strings.labels.phoneNumber}
                            width={270}
                            path="phoneNumber"
                        />
                    </Row>
                    <Row>
                        <RRFTextArea
                            label={strings.labels.description}
                            width={550}
                            rows={4}
                            path="description"
                        />
                    </Row>
                    <Row>
                        <RRFDatePicker
                            label={strings.labels.expiredDateUtc}
                            includeTime={true}
                            path="expiredDateUtc"
                        />
                    </Row>
                    {selectedFacility && (
                        <AdminListStatus
                            status={isExpired(selectedFacility.expiredDateUtc) ? EXPIRED : ACTIVE}
                            dateTime={true}
                        />
                    )}
                </ReactReduxForm>
            )}
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    loading: loadingFacilityLocationSelector,
    newFacilityFormIsOpen: newFacilityFormIsOpenSelector,
    selectedFacilityId: selectedFacilityIdSelector,
    facilities: facilitiesSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: () => dispatch(submitFacilityForm()),
    closeFacilityForm: () => dispatch(closeFacilityForm()),
});

/**
 * Side panel form for creating/editing a facility.
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    renderOnlyIf(({ loading, newFacilityFormIsOpen, selectedFacilityId }) => {
        return loading || newFacilityFormIsOpen || !!selectedFacilityId;
    }),
    withPropsOnChange(
        ['selectedFacilityId', 'facilities'],
        ({ facilities, selectedFacilityId }) => ({
            selectedFacility: facilities[selectedFacilityId],
        })
    )
)(FacilityAdminForm);

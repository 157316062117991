import React from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';

import {
    DISPLAY_ONLY_DROP_OFF_LOCATION,
    DISPLAY_ONLY_OFFICER_POSSESSION,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { arbiterMFTInput } from '../../../arbiter';
import Select from './Select';

const CustodyTypes = {
    EVIDENCE_STORAGE_LOCATION: EntityTypeEnum.EVIDENCE_STORAGE_LOCATION,
    USER: EntityTypeEnum.USER,
};

type CustodyType = typeof CustodyTypes[keyof typeof CustodyTypes]['name'];

export const custodyTypeIsUser = (custodyType: CustodyType | undefined) =>
    custodyType === CustodyTypes.USER.name;

function CustodyTypeSelect(props: Record<string, unknown>) {
    const fieldNames = useFields([DISPLAY_ONLY_OFFICER_POSSESSION, DISPLAY_ONLY_DROP_OFF_LOCATION]);
    const custodyTypeOptions = [
        {
            value: CustodyTypes.EVIDENCE_STORAGE_LOCATION.name,
            display: fieldNames[DISPLAY_ONLY_DROP_OFF_LOCATION],
        },
        {
            value: CustodyTypes.USER.name,
            display: fieldNames[DISPLAY_ONLY_OFFICER_POSSESSION],
        },
    ];

    return <Select options={custodyTypeOptions} {...props} />;
}

CustodyTypeSelect.displayName = 'CustodyTypeSelect';

export const ArbiterMFTCustodyTypeSelect = arbiterMFTInput(CustodyTypeSelect);

import React from 'react';
import styled from 'styled-components';
import { Mark43File } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { FileDownloadLink } from '../../../../../legacy-redux/components/core/Files';

const strings = componentStrings.compliance.ComplianceExportHistoryTable.labels;

const OverwrittenWrapper = styled.div`
    text-transform: uppercase;
`;

type ComplianceDownloadLinkedCellPropsT = {
    file?: Mark43File;
    isOverwritten: boolean;
    isDeleted?: boolean;
};

const ComplianceDownloadLinkedCell: React.FC<ComplianceDownloadLinkedCellPropsT> = ({
    file,
    isOverwritten,
    isDeleted,
}) => {
    if (isDeleted) {
        return <OverwrittenWrapper>{strings.deleted}</OverwrittenWrapper>;
    }
    if (isOverwritten) {
        return <OverwrittenWrapper>{strings.overwritten}</OverwrittenWrapper>;
    }

    if (!file) {
        return null;
    }

    return (
        <FileDownloadLink
            displayName={file.originalFileName}
            path={file.fileWebServerPath}
            // choosing a high number here because the component
            // does not currently allow percentage values like "100%"
            width="600"
        />
    );
};

export default ComplianceDownloadLinkedCell;

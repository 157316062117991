import { LinkTypesEnumType, LocationSourceEnumType, CountryCodeEnumType } from '@mark43/rms-api';
import {
    createFieldset,
    createFormConfiguration,
    createNItems,
    createField,
    InferFormDataShape,
} from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

export function createLocationSidePanelFormConfiguration() {
    return createFormConfiguration({
        id: createField<number>({
            fieldName: fields.LOCATION_VIEW_ID,
        }),
        streetAddress: createField<string>({
            fieldName: fields.LOCATION_VIEW_STREET_ADDRESS,
        }),
        crossStreet1: createField<string>({
            fieldName: fields.LOCATION_VIEW_CROSS_STREET_1,
        }),
        crossStreet2: createField<string>({
            fieldName: fields.LOCATION_VIEW_CROSS_STREET_2,
        }),
        // city
        locality: createField<string>({
            fieldName: fields.LOCATION_VIEW_LOCALITY,
        }),
        // state
        adminArea1: createField<string>({
            fieldName: fields.LOCATION_VIEW_ADMIN_AREA_1,
        }),
        adminArea2: createField<string>({
            fieldName: fields.LOCATION_VIEW_ADMIN_AREA_2,
        }),
        neighborhood: createField<string>({
            fieldName: fields.LOCATION_VIEW_NEIGHBORHOOD,
        }),
        postalCode: createField<string>({
            fieldName: fields.LOCATION_VIEW_POSTAL_CODE,
        }),
        country: createField<CountryCodeEnumType>({
            fieldName: fields.LOCATION_VIEW_COUNTRY,
        }),
        latitude: createField<number>({
            fieldName: fields.LOCATION_VIEW_LATITUDE,
        }),
        longitude: createField<number>({
            fieldName: fields.LOCATION_VIEW_LONGITUDE,
        }),
        resolverSource: createField<LocationSourceEnumType>({}),
        subPremises: createNItems({
            fieldName: fields.DISPLAY_ONLY_LOCATION_SUB_PREMISE_N_ITEMS_WRAPPER,
            fields: {
                subPremiseName: createField<string>({
                    fieldName: fields.DISPLAY_ONLY_LOCATION_ENTITY_LINK_SUB_PREMISE_NAME,
                }),
                subPremiseValue: createField<string>({
                    fieldName: fields.DISPLAY_ONLY_LOCATION_ENTITY_LINK_SUB_PREMISE_VALUE,
                }),
            },
        }),
        entityLink: createFieldset({
            fields: {
                placeName: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_PLACE_NAME,
                }),
                // apt, unit
                subPremise: createField<string>({
                    fieldName: fields.LOCATION_ENTITY_LINK_SUB_PREMISE,
                }),
                // district
                subdivision1AttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_1_ATTR_ID,
                }),
                // sector
                subdivision2AttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_2_ATTR_ID,
                }),
                // grid
                subdivision3AttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_3_ATTR_ID,
                }),
                subdivision4AttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_4_ATTR_ID,
                }),
                subdivision5AttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_SUBDIVISION_5_ATTR_ID,
                }),
                // location category
                typeAttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_TYPE_ATTR_ID,
                }),
                // private/public
                propertyTypeAttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_PROPERTY_TYPE_ATTR_ID,
                }),
                entityId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_ENTITY_ID,
                }),
                linkType: createField<LinkTypesEnumType>({
                    fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE,
                }),
                agencyId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_AGENCY_ID,
                }),
                countyCodeAttrId: createField<number>({
                    fieldName: fields.LOCATION_ENTITY_LINK_COUNTY_CODE_ATTRIBUTE_ID,
                }),
            },
        }),
    });
}

type LocationSidePanelFormConfiguration = ReturnType<
    typeof createLocationSidePanelFormConfiguration
>;

export type LocationSidePanelFormDataShape = InferFormDataShape<LocationSidePanelFormConfiguration>;
export type SubPremisesFormDataShape = InferFormDataShape<
    LocationSidePanelFormConfiguration['subPremises']
>;

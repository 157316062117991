import {
    diff_match_patch as DiffMatchPatch,
    DIFF_INSERT,
    DIFF_DELETE,
    Diff,
} from 'diff-match-patch';

const dmp = new DiffMatchPatch();

// converted as per https://github.com/GerHobbelt/google-diff-match-patch/blob/master/doc/wiki/LineOrWordDiffs.md#word-mode
const diffLinesToWords = (text1: string, text2: string) => {
    const wordArray: string[] = []; // e.g. wordArray[4] == 'Hello '
    const wordHash: Record<string, number> = {}; // e.g. wordHash['Hello '] == 4

    // '\x00' is a valid character, but various debuggers don't like it.
    // So we'll insert a junk entry to avoid generating a null character.
    wordArray[0] = '';

    /**
     * Split a text into an array of strings.  Reduce the texts to a string of
     * hashes where each Unicode character represents one word.
     * Modifies wordArray and wordHash through being a closure.
     * @param  text String to encode.
     * @return  Encoded string.
     * @private
     */
    const diffWordsToCharsMunge = (text: string): string => {
        let chars = '';
        // Walk the text, pulling out a substring for each word.
        // text.split(' ') would would temporarily double our memory footprint.
        // Modifying text would create many large strings to garbage collect.
        let wordStart = 0;
        let wordEnd = -1;
        // Keeping our own length variable is faster than looking it up.
        let wordArrayLength = wordArray.length;
        while (wordEnd < text.length - 1) {
            wordEnd = text.indexOf(' ', wordStart);
            if (wordEnd === -1) {
                wordEnd = text.length - 1;
            }
            const word = text.substring(wordStart, wordEnd + 1);
            wordStart = wordEnd + 1;

            if (
                wordHash.hasOwnProperty
                    ? wordHash.hasOwnProperty(word)
                    : wordHash[word] !== undefined
            ) {
                chars += String.fromCharCode(wordHash[word]);
            } else {
                chars += String.fromCharCode(wordArrayLength);
                wordHash[word] = wordArrayLength;
                wordArray[wordArrayLength++] = word;
            }
        }
        return chars;
    };

    const chars1 = diffWordsToCharsMunge(text1);
    const chars2 = diffWordsToCharsMunge(text2);
    return { chars1, chars2, wordArray };
};

type ConvertedDiff = {
    added: boolean;
    removed: boolean;
    value: string;
};

const convertDiffFormat = ([changeType, value]: Diff): ConvertedDiff => {
    const added = changeType === DIFF_INSERT;
    const removed = changeType === DIFF_DELETE;
    return {
        added,
        removed,
        value,
    };
};

const diffWords = (oldValue: string, newValue: string) => {
    const { chars1, chars2, wordArray } = diffLinesToWords(oldValue, newValue);
    const diffs = dmp.diff_main(chars1, chars2, false);
    dmp.diff_charsToLines_(diffs, wordArray);
    dmp.diff_cleanupSemantic(diffs);
    return diffs.map(convertDiffFormat);
};

export default diffWords;

import { FullCallForService } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const callForServicesModule = createNormalizedModule<FullCallForService>({
    type: 'callForServices',
});

// ACTIONS
export const storeCallForServices = callForServicesModule.actionCreators.storeEntities;

// SELECTORS
export const callForServicesSelector = callForServicesModule.selectors.entitiesSelector;
export const callForServiceByIdSelector = callForServicesModule.selectors.entityByIdSelector;

// REDUCER
export default callForServicesModule.reducerConfig;

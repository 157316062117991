import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@arc/checkbox';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import { DisplayedPhoto } from '../utils/parseElasticPhotoLineups';
import { Tooltip } from '../../../core/components/tooltip';
import { LineupPhotoWidth, SlotPhoto } from './LineupPhotoRow';

const strings = componentStrings.cases.casePhotoLineups.ComposeLineup;

/**
 * The checkbox that appears at the top right.
 */
const CornerCheckbox = styled(Checkbox)`
    position: absolute;
    z-index: 1;
    top: 6px;
    right: 6px;
`;

/**
 * This is the only element which is clickable in SelectionAreaPhoto, to simplify the handling of all clicks to one
 * element.
 *
 * Without this element, the checkbox would become clickable, but the Checkbox component has a change handler without a
 * click handler, and we don't directly control the `isChecked` value of the Checkbox here.
 */
const ClickableArea = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const SelectionAreaPhotoContainer = styled.div<{ isSelected: boolean }>`
    position: relative;
    width: 114px;
    height: 114px;

    ${(props) =>
        props.isSelected
            ? `& ${/* sc-selector */ SlotPhoto} {
                   border: var(--arc-borders-sm) var(--arc-colors-selected-default);
                   outline: var(--arc-borders-sm) var(--arc-colors-selected-default);
               }`
            : `${/* sc-selector */ CornerCheckbox} {
                   display: none;
               }
               &:hover ${/* sc-selector */ CornerCheckbox} {
                   display: block;

                   .chakra-checkbox__control {
                       background-color: var(--arc-colors-surface-background);
                   }
               }`}
`;

interface SelectionAreaPhotoProps extends Omit<DisplayedPhoto, 'masterPersonId'> {
    isSelected: boolean;
    onClick: (id: number) => void;
    disablePhotoSlot: boolean;
    isPoiImage: boolean;
}

const DisableSlot = styled(SlotPhoto)`
    filter: brightness(50%);
    opacity: 0.4;
`;

/**
 * A box containing a photo.
 * When the photo is selected, it is displayed with an outline (border) and a checked checkbox.
 * When the photo is not selected, hovering over it shows an unchecked checkbox.
 */
const SelectionAreaPhoto = ({
    id,
    path,
    isSelected,
    onClick,
    disablePhotoSlot,
    isPoiImage,
}: SelectionAreaPhotoProps): JSX.Element => {
    return (
        <>
            {disablePhotoSlot ? (
                <Tooltip
                    side="top"
                    align="start"
                    content={strings.sameMasterIdError}
                    testId={testIds.PHOTO_TOOLTIP_CONTAINER}
                >
                    <SelectionAreaPhotoContainer
                        isSelected={isSelected}
                        data-test-id={testIds.PHOTO_LINEUP_SELECTION_AREA_PHOTO}
                    >
                        <DisableSlot src={path} $photoWidth={LineupPhotoWidth.COMPOSE_PAGE} />
                    </SelectionAreaPhotoContainer>
                </Tooltip>
            ) : (
                <SelectionAreaPhotoContainer
                    isSelected={isSelected}
                    data-test-id={testIds.PHOTO_LINEUP_SELECTION_AREA_PHOTO}
                >
                    <SlotPhoto src={path} $photoWidth={LineupPhotoWidth.COMPOSE_PAGE} />
                    <CornerCheckbox
                        isChecked={isSelected}
                        isDisabled={isPoiImage}
                        data-test-id={testIds.PHOTO_LINEUP_SELECTION_AREA_PHOTO_CHECKBOX}
                    />
                    <ClickableArea
                        data-test-id={testIds.PHOTO_LINEUP_SELECTION_AREA_PHOTO_CLICK_AREA}
                        onClick={() => {
                            onClick(id);
                        }}
                    />
                </SelectionAreaPhotoContainer>
            )}
        </>
    );
};

export default SelectionAreaPhoto;

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

const BASE = 'dashboard/external_links';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'External Links Resource',
        methods: {
            /**
             * Get External Links.
             * @return {Promise<Object[]>}
             */
            getExternalLinks({ hideLoadingBar = false } = {}) {
                return req({
                    method: 'GET',
                    url: BASE,
                    hideLoadingBar,
                });
            },
            /**
             * Upsert single External Link.
             * @param {Object} externalLink
             * @return {Promise<Object>}
             */
            upsertExternalLink(externalLink) {
                return req({
                    method: 'POST',
                    url: BASE,
                    data: externalLink,
                });
            },
            /**
             * Delete Single External Link.
             * @param {number} externalLinkId
             * @return {Promise<Object>}
             */
            deleteExternalLink(externalLinkId) {
                return req({
                    method: 'DELETE',
                    url: `${BASE}/${externalLinkId}`,
                });
            },
        },
    });
}

/**
 * Resources for external links. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

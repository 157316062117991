import _ from 'lodash';
import { createSelector } from 'reselect';
import { profileReportTypesSelector } from '../data';

export const profileReportTypesModelsSelector = createSelector(
    profileReportTypesSelector,
    (reportTypes) => {
        return _.map(reportTypes, function mapTypes(reportType) {
            return {
                value: reportType.id,
                display: reportType.name,
            };
        });
    }
);

import { AttributeTypeEnum } from '@mark43/rms-api';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Fields, Observer } from 'markformythree';
import keyMirror from 'keymirror';
import styled from 'styled-components';
import { Flex } from 'arc';

import { mapValues, find, get, first, reduce } from 'lodash';
import { dateDifferenceInYears } from '~/client-common/core/dates/utils/dateRangeHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { responsiveStack } from '../../styles/mixins';

import { VisibilityObserver } from '../../forms/markformythree-arbiter/mftArbiterObservers';
import { currentUserDepartmentProfileSelector } from '../../current-user/state/ui';
import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker } from '../../forms/components/DatePicker';
import { ArbiterMFTRadio } from '../../forms/components/Radio';
import { ArbiterMFTText } from '../../forms/components/Text';
import { ArbiterMFTCheckbox } from '../../forms/components/checkboxes/Checkbox';
import FormGroup from '../../forms/components/FormGroup';
import { TWO_CHAR_TEXT_INPUT_WIDTH } from '../../names/config/formWidths';
import { SomeFieldsHaveTruthyValue } from '../../components/SomeFieldsHaveValue';
import { isJuvenileFromPersonProfile } from '../utils/personProfileHelpers';
import FormElementMultiSelectButtonRadio from '../../forms/components/button-radios/FormElementMultiSelectButtonRadio';
import { ArbiterMFTMultiTextInput } from './MultiTextInput';

const InputWithCheckboxWrapper = styled.div`
    ${responsiveStack('sm')}
`;

const CenteredCheckbox = styled(ArbiterMFTCheckbox)`
    margin-top: 28px;
`;
const strings = componentStrings.core.PersonProfileForm.PersonProfileFormGeneralPersonRelatedFields;

const placeholders = componentStrings.core.genericPlaceholders;

const isDeadOptions = [
    {
        value: false,
        label: strings.living,
    },
    {
        value: true,
        label: strings.deceased,
    },
];

const BIRTH_STATUS_VALUES = keyMirror({
    KNOWN: null,
    UNKNOWN: null,
    UNBORN: null,
});

const birthTypeOptions = [
    { value: BIRTH_STATUS_VALUES.KNOWN, label: strings.known },
    { value: BIRTH_STATUS_VALUES.UNKNOWN, label: strings.unknown },
    { value: BIRTH_STATUS_VALUES.UNBORN, label: strings.unborn },
];

const BirthStatus = ({ isNewPerson, isDobUnknown, isUnborn, form }) => {
    const defaultValue = isUnborn
        ? find(birthTypeOptions, (opt) => opt.value === BIRTH_STATUS_VALUES.UNBORN)
        : isDobUnknown
        ? find(birthTypeOptions, (opt) => opt.value === BIRTH_STATUS_VALUES.UNKNOWN)
        : find(birthTypeOptions, (opt) => opt.value === BIRTH_STATUS_VALUES.KNOWN);

    const [selectedOption, setSelectedOption] = useState([defaultValue]);

    const onBirthStatusChange = useCallback(
        (clickedOption) => {
            const [{ value }] = clickedOption;

            setSelectedOption(clickedOption);
            form.transaction(() => {
                if (value === BIRTH_STATUS_VALUES.KNOWN) {
                    form.set('isDobUnknown', false);
                    form.set('isUnborn', false);
                    if (isNewPerson) {
                        form.set('firstNameUnknown', false);
                    }
                } else if (value === BIRTH_STATUS_VALUES.UNKNOWN) {
                    form.set('isDobUnknown', true);
                    form.set('isUnborn', false);
                    if (isNewPerson) {
                        form.set('firstNameUnknown', false);
                    }
                } else if (value === BIRTH_STATUS_VALUES.UNBORN) {
                    form.set('isUnborn', true);
                    form.set('isDobUnknown', false);
                    if (isNewPerson) {
                        form.set('firstNameUnknown', true);
                    }
                }
            });
        },
        [form, isNewPerson]
    );

    return (
        <FormElementMultiSelectButtonRadio
            multiple={false}
            options={birthTypeOptions}
            selectedOptions={selectedOption}
            onChange={onBirthStatusChange}
            label={strings.dateOfBirth}
        />
    );
};

const updateIsJuvenileFromSubscriptions = ({ subscriptions, form, ageOfAdult }) => {
    const { isJuvenileDirty, ...restOfSubscriptions } = subscriptions;

    if (!ageOfAdult) {
        return;
    }

    const subscriptionsModels = mapValues(restOfSubscriptions, ([model]) => model);
    const isJuvenile = isJuvenileFromPersonProfile(subscriptionsModels, ageOfAdult);

    form.set('isJuvenile', isJuvenile);
    if (!first(isJuvenileDirty)) {
        form.do({ isJuvenile: { dirty: true } });
    }
};

const IsJuvenileSelect = connect(
    createStructuredSelector({
        currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
    })
)(function IsJuvenileSelect({ subscriptions, currentUserDepartmentProfile, form }) {
    const { ageOfAdult } = currentUserDepartmentProfile;

    const formUIState = form.getState().ui;

    // we also need to calculate the age based of ageMin and ageMax
    reduce(
        ['ageMin', 'ageMax'],
        (result, field) => {
            result[field] = [form.get(field), get(formUIState, `${field}.dirty`)];
            return result;
        },
        subscriptions
    );

    useEffect(() => {
        updateIsJuvenileFromSubscriptions({ subscriptions, form, ageOfAdult });
    }, [ageOfAdult, form, subscriptions]);

    return <ArbiterMFTBooleanSelect path="isJuvenile" length="md" />;
});

const AgeRangeMFTMultiTextInput = connect(
    createStructuredSelector({
        currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
    })
)(function AgeRangeMFTMultiTextInput({ subscriptions, currentUserDepartmentProfile, form }) {
    const { ageOfAdult } = currentUserDepartmentProfile;

    const onBlur = useCallback(() => {
        updateIsJuvenileFromSubscriptions({ ageOfAdult, form, subscriptions });
    }, [ageOfAdult, form, subscriptions]);

    const ageRangePathConfig = [
        {
            path: 'ageMin',
            suffix: '—',
            width: TWO_CHAR_TEXT_INPUT_WIDTH,
            placeholder: placeholders.age,
            onBlur,
        },
        {
            path: 'ageMax',
            width: TWO_CHAR_TEXT_INPUT_WIDTH,
            placeholder: placeholders.age,
            onBlur,
        },
    ];

    return (
        <ArbiterMFTMultiTextInput
            justifyContent="flex-start"
            pathConfig={ageRangePathConfig}
            style={{ maxWidth: '7rem' }}
            fieldName={form.getConfigurationForPath('ageRangeLabel').fieldName}
        />
    );
});

function IsNonDisclosureSelect({ form, subscriptions }) {
    useEffect(() => {
        const {
            isJuvenile: [model, isDirty],
        } = subscriptions;

        if (isDirty && model) {
            form.set('isNonDisclosureRequest', true);
            form.do({ isNonDisclosureRequest: { dirty: true } });
        }
    }, [subscriptions, form]);

    return <ArbiterMFTBooleanSelect path="isNonDisclosureRequest" length="md" />;
}

const Age = ({ dateOfBirth, form }) => {
    useEffect(() => form.set('age', dateDifferenceInYears(dateOfBirth, new Date())), [
        dateOfBirth,
        form,
    ]);
    return <ArbiterMFTText path="age" disabled={true} length="sm" />;
};

export function PersonProfileFormGeneralPersonRelatedFields({ form }) {
    return (
        <FormGroup>
            <ArbiterMFTRadio
                label={undefined}
                name="isDead"
                path="isDead"
                options={isDeadOptions}
            />
            <ArbiterMFTDatePicker includeTime={true} path="dateOfDeathUtc" />
            <ArbiterMFTAttributeSelect
                path="verificationOfDeathAttrId"
                attributeType={AttributeTypeEnum.VERIFICATION_OF_DEATH.name}
            />
            <FeatureFlagged flag="RMS_UNBORN_PERSON_CAPTURE_ENABLED">
                <Observer
                    subscriptions={{
                        id: 'id',
                        isDobUnknown: 'isDobUnknown',
                        isUnborn: 'isUnborn',
                    }}
                    render={({ id, isUnborn, isDobUnknown }) => {
                        return (
                            <BirthStatus
                                isNewPerson={!id}
                                isUnborn={isUnborn}
                                isDobUnknown={isDobUnknown}
                                form={form}
                            />
                        );
                    }}
                />
                <Observer
                    subscriptions={{
                        isExpectedDateOfBirthUnknown: 'isExpectedDateOfBirthUnknown',
                    }}
                    render={({ isExpectedDateOfBirthUnknown }) => (
                        <ArbiterMFTDatePicker
                            path="expectedDateOfBirth"
                            variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                            label={strings.expectedDueDate}
                            disabled={isExpectedDateOfBirthUnknown}
                        />
                    )}
                />
                <ArbiterMFTCheckbox
                    path="isExpectedDateOfBirthUnknown"
                    label={strings.unknown}
                    onChange={useCallback(
                        (value) => value && form.set('expectedDateOfBirth', undefined),
                        [form]
                    )}
                />
            </FeatureFlagged>
            <InputWithCheckboxWrapper>
                <Flex>
                    <SomeFieldsHaveTruthyValue formPathsToObserve={['isDobUnknown']}>
                        {(someFieldsHaveTruthyValue) => (
                            <FeatureFlagged
                                flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                                fallback={
                                    <ArbiterMFTDatePicker
                                        path="dateOfBirth"
                                        disabled={someFieldsHaveTruthyValue}
                                        variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                                        {...(someFieldsHaveTruthyValue
                                            ? {
                                                  value: undefined,
                                              }
                                            : undefined)}
                                    />
                                }
                            >
                                <ArbiterMFTDatePicker
                                    path="dateOfBirth"
                                    disabled={someFieldsHaveTruthyValue}
                                    variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
                                    hasCalendar={false}
                                    {...(someFieldsHaveTruthyValue
                                        ? {
                                              value: undefined,
                                          }
                                        : undefined)}
                                />
                            </FeatureFlagged>
                        )}
                    </SomeFieldsHaveTruthyValue>
                    <FeatureFlagged
                        flag="RMS_UNBORN_PERSON_CAPTURE_ENABLED"
                        fallback={<CenteredCheckbox path={'isDobUnknown'} />}
                    />
                </Flex>
                <Observer
                    subscriptions={{
                        dateOfBirth: 'dateOfBirth',
                    }}
                    render={({ dateOfBirth }) => {
                        return <Age dateOfBirth={dateOfBirth} form={form} />;
                    }}
                />
                <Observer
                    subscriptions={{
                        isDobUnknown: ['isDobUnknown', [Fields.MODEL, Fields.DIRTY]],
                        ageMin: ['ageMin', [Fields.MODEL, Fields.DIRTY]],
                        ageMax: ['ageMax', [Fields.MODEL, Fields.DIRTY]],
                        isJuvenileDirty: ['isJuvenile', [Fields.DIRTY]],
                    }}
                    render={(subscriptions) =>
                        !first(get(subscriptions, 'isDobUnknown')) ? null : (
                            <AgeRangeMFTMultiTextInput subscriptions={subscriptions} form={form} />
                        )
                    }
                />
            </InputWithCheckboxWrapper>
            <ArbiterMFTAttributeSelect
                path="infantAge"
                attributeType={AttributeTypeEnum.INFANT_AGE.name}
                length="sm"
            />
            <FormRow>
                <Observer
                    subscriptions={{
                        dateOfBirth: ['dateOfBirth', [Fields.MODEL, Fields.DIRTY]],
                        isDobUnknown: ['isDobUnknown', [Fields.MODEL, Fields.DIRTY]],
                        isJuvenileDirty: ['isJuvenile', [Fields.DIRTY]],
                    }}
                    render={(subscriptions) => {
                        return (
                            <IsJuvenileSelect
                                subscriptions={subscriptions}
                                form={form}
                                length="md"
                            />
                        );
                    }}
                />
                <FeatureFlagged
                    flag="RMS_LUNA_RELEASE_2020_2_ENABLED"
                    fallback={
                        <ArbiterMFTAttributeSelect
                            path="isPregnantAttrId"
                            attributeType={AttributeTypeEnum.YES_NO_UNKNOWN.name}
                            length="md"
                        />
                    }
                >
                    <VisibilityObserver
                        path="isNonDisclosureRequest"
                        formName={form.name}
                        render={({ hidden }) =>
                            !hidden && (
                                <Observer
                                    subscriptions={{
                                        isJuvenile: ['isJuvenile', [Fields.MODEL, Fields.DIRTY]],
                                    }}
                                    render={(subscriptions) => (
                                        <IsNonDisclosureSelect
                                            subscriptions={subscriptions}
                                            form={form}
                                        />
                                    )}
                                />
                            )
                        }
                    />
                </FeatureFlagged>
            </FormRow>
            <ArbiterMFTBooleanSelect path="isVulnerable" length="md" />
            <Observer
                subscriptions={{
                    isVulnerable: 'isVulnerable',
                }}
                render={({ isVulnerable }) => {
                    return !isVulnerable ? null : (
                        <>
                            <ArbiterMFTDatePicker path="dateVulnerableFrom" />
                            <ArbiterMFTDatePicker path="dateVulnerableTo" />
                        </>
                    );
                }}
            />
            <ArbiterMFTBooleanSelect path="priorHistoryOfDomesticViolence" length="md" />
            <FeatureFlagged flag="RMS_LUNA_RELEASE_2020_2_ENABLED">
                <ArbiterMFTAttributeSelect
                    path="isPregnantAttrId"
                    attributeType={AttributeTypeEnum.YES_NO_UNKNOWN.name}
                    length="md"
                />
            </FeatureFlagged>
            <ArbiterMFTAttributeSelect
                path="sexAttrId"
                attributeType={AttributeTypeEnum.SEX.name}
                length="md"
            />
            <ArbiterMFTAttributeSelect
                path="isResidentOfJurisdictionAttrId"
                attributeType={AttributeTypeEnum.RESIDENT_OF_JURISDICTION.name}
                length="md"
            />
            <ArbiterMFTAttributeSelect
                path="raceAttrId"
                attributeType={AttributeTypeEnum.RACE.name}
                length="md"
            />
            <ArbiterMFTAttributeSelect
                path="ethnicityAttrId"
                attributeType={AttributeTypeEnum.ETHNICITY.name}
                length="md"
            />
        </FormGroup>
    );
}

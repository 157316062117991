import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';

import { sortBy, filter } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data/';

import createReportFormModule from '../../../../core/forms/lib/createReportFormModule';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { N_ITEMS_FIELDSET } = fieldTypeClientEnum;

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        type: N_ITEMS_FIELDSET,
        key: 'personSubjectNameReportLinks',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'nameId',
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_INVOLVED_PERSON_IN_REPORT_ID,
            },
            {
                key: 'subjectTypeAttrId',
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_INVOLVED_PERSON_IN_REPORT_SUBJECT_TYPE_ATTR_ID,
            },
            {
                key: 'subjectTypeOther',
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_INVOLVED_PERSON_IN_REPORT_SUBJECT_TYPE_OTHER,
            },
        ]),
    },
    {
        type: N_ITEMS_FIELDSET,
        key: 'orgSubjectNameReportLinks',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'nameId',
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_INVOLVED_ORG_IN_REPORT_ID,
            },
            {
                key: 'subjectTypeAttrId',
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_INVOLVED_ORG_IN_REPORT_SUBJECT_TYPE_ATTR_ID,
            },
            {
                key: 'subjectTypeOther',
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_INVOLVED_ORG_IN_REPORT_SUBJECT_TYPE_OTHER,
            },
        ]),
    },
    {
        type: N_ITEMS_FIELDSET,
        key: 'involvedProfilesLocations',
        fields: buildFlatFormFieldViewModels([
            {
                key: 'locationId',
                fieldName:
                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_OTHER_LOCATION_IN_REPORT_LOCATION_ID,
            },
            {
                key: 'positionAttrId',
                fieldName:
                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_OTHER_LOCATION_IN_REPORT_POSITION_ATTR_ID,
            },
            {
                key: 'description',
                fieldName:
                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_OTHER_LOCATION_IN_REPORT_DESCRIPTION,
            },
        ]),
    },
]);

const convertToFormModel = ({
    personSubjectNameReportLinks,
    orgSubjectNameReportLinks,
    involvedProfilesLocations,
} = {}) => {
    return {
        personSubjectNameReportLinks: sortBy(
            personSubjectNameReportLinks,
            'linkTypeSequenceNumber'
        ),
        orgSubjectNameReportLinks: sortBy(orgSubjectNameReportLinks, 'linkTypeSequenceNumber'),
        involvedProfilesLocations,
    };
};

const convertFromFormModel = (formModel = {}) => {
    return {
        nameReportLinks: [
            ...(formModel.personSubjectNameReportLinks || []),
            ...(formModel.orgSubjectNameReportLinks || []),
        ],
        // Only grab the valid `locationEntityLinks`
        locationEntityLinks: filter(
            formModel.involvedProfilesLocations,
            ({ locationId } = {}) => !!locationId
        ),
    };
};

export default createReportFormModule({
    context: RefContextEnum.FORM_INVOLVED_PROFILES.name,
    fieldViewModels,
    convertToFormModel,
    convertFromFormModel,
});

export const buildInvolvedProfilesCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const nameReportLinksWhere = nameReportLinksWhereSelector(state);

    const personSubjectNameReportLinks = nameReportLinksWhere({
        reportId,
        contextType: EntityTypeEnum.REPORT.name,
        contextId: reportId,
        entityType: EntityTypeEnum.PERSON_PROFILE.name,
        linkType: LinkTypesEnum.INVOLVED_PERSON_IN_REPORT,
    });
    const orgSubjectNameReportLinks = nameReportLinksWhere({
        reportId,
        contextType: EntityTypeEnum.REPORT.name,
        contextId: reportId,
        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
        linkType: LinkTypesEnum.INVOLVED_ORG_IN_REPORT,
    });
    const involvedProfilesLocations = locationEntityLinksWhereSelector(state)({
        entityId: reportId,
        entityType: EntityTypeEnum.REPORT.name,
        linkType: LinkTypesEnum.OTHER_LOCATION_IN_REPORT,
    });

    return {
        personSubjectNameReportLinks,
        orgSubjectNameReportLinks,
        involvedProfilesLocations,
    };
};

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Name Attributes Resource',
        methods: {
            /**
             * The endpoint used in this method lives in `PersonResource.java`.
             * No `NameAttributeResource` exists, but since this endpoint deals
             * exclusively with name attributes, it has been added here
             */
            updateNameAttributesForType({ attributes, attributeType, entityId }) {
                return req({
                    method: 'PUT',
                    url: `person/profile/${entityId}/attributes/${attributeType}`,
                    data: attributes,
                });
            },
        },
    });
}

/**
 * Resources for NameAttributes. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

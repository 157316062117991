import { get, map } from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import getWarrantsResource from '~/client-common/core/domain/warrants/resources/warrantsResource';
import { NEXUS_STATE_PROP as WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrants/state/data';
import { NEXUS_STATE_PROP as WARRANT_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-statuses/state/data';
import { NEXUS_STATE_PROP as WARRANT_DEX_SUBMISSIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-dex-submissions/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import {
    saveBoxSuccess,
    saveBoxFailure,
    saveBoxStart,
    closeBox,
} from '../../../../../legacy-redux/actions/boxActions';

import createWarrantStubPanelForm from '../forms/createWarrantStubPanelForm';
import {
    connectWarrantsContexts,
    connectWarrantsUiSelector,
    addToWarrantResults,
    clearWarrantResults,
} from './connectWarrantsSidePanel';

const context = { name: boxEnum.CREATE_WARRANT_SIDE_PANEL };

export function closeCreateWarrantStubPanel() {
    return (dispatch) => {
        dispatch(closeBox(context));
        dispatch(createWarrantStubPanelForm.actionCreators.reset());
        dispatch(clearWarrantResults());
    };
}

export function saveWarrantFromWarrantStubSidePanel() {
    return (dispatch, getState) => {
        dispatch(saveBoxStart);
        const state = getState();
        const uiState = connectWarrantsUiSelector(state);
        const { metadata } = uiState;
        const { arrestId, chargeOffenseOrder } = metadata;

        dispatch(
            createWarrantStubPanelForm.actionCreators.submit((formModel) => {
                // intentionally leaving out arrestId - we just want to create the warrant
                // here and only associate the arrest when we save the add/edit charges panel
                const warrant = createWarrantStubPanelForm.convertFromFormModel(formModel);
                return warrant;
            })
        )
            .then((warrant) => {
                const warrantId = warrant.id;
                const warrantRen = get(warrant, 'reportingEventNumber');

                // if the id of warrant is defined already - update the warrant, otherwise its new
                if (warrantId) {
                    return dispatch(
                        updateStubWarrantFromWarrantStubSidePanel({
                            warrant,
                            warrantRen,
                            arrestId,
                            chargeOffenseOrder,
                        })
                    );
                } else {
                    return dispatch(
                        createStubWarrantFromWarrantStubSidePanel({
                            warrant,
                            warrantRen,
                            arrestId,
                            chargeOffenseOrder,
                        })
                    );
                }
            })
            .catch((err) => dispatch(saveBoxFailure(context, err.panelErrors)));
    };
}

function updateStubWarrantFromWarrantStubSidePanel({
    warrant,
    warrantRen,
    arrestId,
    chargeOffenseOrder,
}) {
    return (dispatch, getState, dependencies) => {
        return getWarrantsResource()
            .updateWarrant(warrant, warrantRen)
            .then((updatedWarrant) => {
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [WARRANTS_NEXUS_STATE_PROP]: [updatedWarrant],
                        },
                        addToWarrantResults({
                            [connectWarrantsContexts.ARREST]: {
                                [arrestId]: {
                                    [chargeOffenseOrder]: updatedWarrant,
                                },
                            },
                        })
                    )
                );
                dispatch(saveBoxSuccess(context));
                return dispatch(closeCreateWarrantStubPanel());
            })
            .catch(() => dispatch(saveBoxFailure(context, 'Failed to update stub warrant.')));
    };
}

function createStubWarrantFromWarrantStubSidePanel({
    warrant,
    warrantRen,
    arrestId,
    chargeOffenseOrder,
}) {
    return (dispatch, getState, dependencies) => {
        return getWarrantsResource()
            .createStubWarrant(warrant, warrantRen)
            .then((chargeWarrantView) => {
                const createdWarrant = chargeWarrantView.warrant;
                const createdWarrantStatus = chargeWarrantView.warrantStatus;
                const createdWarrantDexSubmission = chargeWarrantView.warrantDexSubmission;
                const attributes = chargeWarrantView.attributes;

                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [WARRANTS_NEXUS_STATE_PROP]: [createdWarrant],
                            [WARRANT_STATUSES_NEXUS_STATE_PROP]: [createdWarrantStatus],
                            [WARRANT_DEX_SUBMISSIONS_NEXUS_STATE_PROP]: [
                                createdWarrantDexSubmission,
                            ],
                            [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                attributes,
                                convertAttributeToAttributeView
                            ),
                        },
                        addToWarrantResults({
                            [connectWarrantsContexts.ARREST]: {
                                [arrestId]: {
                                    [chargeOffenseOrder]: createdWarrant,
                                },
                            },
                        })
                    )
                );
                dispatch(saveBoxSuccess(context));
                return dispatch(closeCreateWarrantStubPanel());
            })
            .catch(() => dispatch(saveBoxFailure(context, 'Failed to create stub warrant.')));
    };
}

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';

import { groupBy, map, pick, reduce, orderBy } from 'lodash';

import sortLocationEntityLinks from '~/client-common/core/domain/location-entity-links/utils/sortLocationEntityLinks';
import { organizationProfilesSelector } from '~/client-common/core/domain/organization-profiles/state/data';
import { nameIdentifiersSelector } from '~/client-common/core/domain/name-identifiers/state/data';
import { namePhonesSelector } from '~/client-common/core/domain/name-phones/state/data';
import { nameEmailsSelector } from '~/client-common/core/domain/name-emails/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { attachmentsWhereSelector } from '~/client-common/core/domain/attachments/state/data';

import { filterFormData } from '~/client-common/helpers/formHelpers';
import { ORGANIZATION_SEARCH_TO_ADD_FORM } from '../../../../search/search-to-add/state/forms/organizationSearchToAddForm';
import {
    processLinkData,
    convertFormLocationEntityLinksToLocationBundles,
    convertPhonesToFormModel,
    convertEmailsToFormModel,
    convertPhonesFromFormModel,
    convertEmailsFromFormModel,
} from '../../../names/util/nameFormHelpers';
import { nameIdentifierFields, addressFields } from '../../../names/util/nameFieldHelpers';

function convertToFormModel({
    organizationProfile,
    emails,
    identifiers,
    phoneNumbers,
    locationEntityLinks,
    attachments,
    linkType,
}) {
    const locationEntityLinksByType = groupBy(locationEntityLinks, 'linkType');
    const pickLocationAddressFields = (location) => pick(location, addressFields);
    const massagedPhoneNumbers = convertPhonesToFormModel(phoneNumbers);
    const massagedEmails = convertEmailsToFormModel(emails);

    const physicalAddressesSorted = orderBy(map(
        locationEntityLinksByType[LinkTypesEnum.BUSINESS_LOCATION],
        pickLocationAddressFields
    ), 'endDateUtc', 'desc');

    return {
        ...organizationProfile,
        linkType,
        emails: massagedEmails,
        phoneNumbers: massagedPhoneNumbers,
        identifiers: map(identifiers, (identifier) => pick(identifier, nameIdentifierFields)),
        mailingAddresses: map(
            locationEntityLinksByType[LinkTypesEnum.BUSINESS_ADDRESS],
            pickLocationAddressFields
        ),
        physicalAddresses: physicalAddressesSorted,
        attachments,
    };
}

export const refreshOrganizationProfileForm = ({ organizationProfileId, linkType }) => (
    dispatch,
    getState
) => {
    const state = getState();
    return convertToFormModel({
        organizationProfile: organizationProfilesSelector(state)[organizationProfileId],
        emails: nameEmailsSelector(state)[organizationProfileId],
        identifiers: nameIdentifiersSelector(state)[organizationProfileId],
        phoneNumbers: namePhonesSelector(state)[organizationProfileId],
        locations: locationsSelector(state),
        locationEntityLinks: sortLocationEntityLinks({
            locationEntityLinks: locationEntityLinksWhereSelector(state)({
                entityId: organizationProfileId,
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            }),
        }),
        attachments: attachmentsWhereSelector(state)({
            entityId: organizationProfileId,
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
        }),
        linkType,
    });
};

export function convertFromFormModel(
    formModel,
    stateData,
    { lookupEntityId, ownerId, ownerType, isEditingMasterProfile }
) {
    const {
        phoneNumbers,
        emails,
        identifiers,
        mailingAddresses,
        physicalAddresses,
        ...organizationProfile
    } = formModel;

    // we need to set owner type and id on the organization profile model
    // because that's what the backend expects
    const updatedOrganizationProfile = {
        ...organizationProfile,
        ownerId,
        ownerType,
    };
    // we remove the id when processing a master profile rather than
    // a contexted one because the backend needs to create a new contexted version
    // on save.
    if (
        updatedOrganizationProfile.masterOrganizationId === updatedOrganizationProfile.id &&
        !isEditingMasterProfile
    ) {
        updatedOrganizationProfile.id = 0;
    }

    if (isEditingMasterProfile) {
        updatedOrganizationProfile.ownerType = EntityTypeEnum.ORGANIZATION_PROFILE.name;
        updatedOrganizationProfile.ownerId = updatedOrganizationProfile.id;
    }

    const massagedPhoneNumbers = convertPhonesFromFormModel(phoneNumbers);
    const massagedEmails = convertEmailsFromFormModel(emails);

    const data = {
        organizationProfiles: [updatedOrganizationProfile],
        emails: processLinkData(
            massagedEmails,
            stateData.emails,
            EntityTypeEnum.ORGANIZATION_PROFILE.name
        ),
        phones: processLinkData(
            massagedPhoneNumbers,
            stateData.phoneNumbers,
            EntityTypeEnum.ORGANIZATION_PROFILE.name
        ),
        identifiers: processLinkData(
            identifiers,
            stateData.identifiers,
            EntityTypeEnum.ORGANIZATION_PROFILE.name
        ),
        locations: reduce(
            [
                {
                    formLocationEntityLinks: mailingAddresses,
                    linkType: LinkTypesEnum.BUSINESS_ADDRESS,
                },
                {
                    formLocationEntityLinks: physicalAddresses,
                    linkType: LinkTypesEnum.BUSINESS_LOCATION,
                },
            ],
            (acc, { formLocationEntityLinks, linkType }) =>
                acc.concat(
                    convertFormLocationEntityLinksToLocationBundles({
                        formLocationEntityLinks,
                        stateData: {
                            locations: stateData.locations,
                            locationEntityLinks: stateData.locationEntityLinks,
                            unknownLocationId: stateData.unknownLocationId,
                        },
                        additionalData: {
                            entityId: lookupEntityId,
                            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                            linkType,
                        },
                    })
                ),
            []
        ),
    };

    return filterFormData(data);
}

export function createOrganizationProfileFormInitialAddState({ linkType }) {
    return (dispatch, getState, dependencies) => {
        const searchToAddForm = dependencies.formsRegistry.get(ORGANIZATION_SEARCH_TO_ADD_FORM);

        return {
            name: searchToAddForm.get('quickSearchQuery'),
            organizationTypeAttrId: searchToAddForm.get('organizationTypeAttrIds'),
            linkType,
        };
    };
}

import React, { FC, useEffect, useState } from 'react';
import { Button, Text, cssVar } from 'arc';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { InlineBanner, InlineBannerErrorList } from '../../../core/components/InlineBanner';
import ErrorMessage from '../../../core/overlays/components/SidePanelErrorMessage';
import { openCaseProfilesSidePanel } from '../state/ui/caseLinkedProfilesSidePanel';
import { validateCaseProfileLinks } from '../state/ui/caseLinkedProfilesErrors';
import { caseLinkedProfilesFormViewModelSelector } from '../state/ui';

const strings = componentStrings.cases.linkedProfiles;

const Title = styled(Text)`
    margin-bottom: ${cssVar('arc.space.2')};
`;

const CaseLinkedProfilesErrors: FC = () => {
    const dispatch = useDispatch();
    const formModel = useSelector(caseLinkedProfilesFormViewModelSelector);
    const [errors, setErrors] = useState<string[]>([]);

    const openSidePanel = () => {
        dispatch(openCaseProfilesSidePanel());
    };

    useEffect(() => {
        dispatch(validateCaseProfileLinks(formModel, setErrors));
    }, [dispatch, formModel, setErrors]);

    if (!errors.length) {
        return null;
    }

    return (
        <InlineBanner status="error">
            <Title variant="headingMd">{strings.banner.title}</Title>
            <InlineBannerErrorList>
                {errors.map((error) => (
                    <li key={error}>
                        <ErrorMessage errorMessage={error} />
                    </li>
                ))}
            </InlineBannerErrorList>
            <Button
                variant="ghost"
                onClick={openSidePanel}
                children={strings.actions.addInformation}
            />
        </InlineBanner>
    );
};

export default CaseLinkedProfilesErrors;

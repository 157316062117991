// eslint-disable-next-line no-redeclare
/* global MARK43_ENV */
import environmentEnum from '../../../enums/client/environmentEnum';

/**
 * How many milliseconds to wait between each polling request for current-viewers
 * on this entity.
 *
 * In prod, this value should not be lower than 30s due to excessive load, see https://mark43.atlassian.net/browse/RMS-13664
 * If you need to work on this feature locally, remove the env check.
 * If you need to demo this locally, change the value down to 5000 or lower.
 */
export const POLL_FOR_CURRENT_VIEWERS_INTERVAL =
    MARK43_ENV === environmentEnum.DEVELOPER ? 600000 : 180000;
export const ICON_FLASH_DURATION = 3000;

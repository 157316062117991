import { InventoryTypeEnum } from '@mark43/rms-api';
import PropTypes from 'prop-types';
import React from 'react';

import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';

const { INVENTORY } = InventoryTypeEnum;
const Wrapper = styled.div`
    margin-bottom: 30px;

    .row:last-child {
        margin: 0;
    }
`;

const Row = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

const HeaderRow = styled(Row)`
    border-bottom: 2px solid ${(props) => props.theme.colors.extraLightGrey};
    margin-bottom: 20px;
    padding-bottom: 10px;
`;

const Column = styled.div`
    flex: 0 0 auto;
    font-size: var(--arc-fontSizes-sm);
    padding-right: 12px;
    width: 125px;
`;

const Header1Column = styled(Column)`
    color: ${(props) => props.theme.colors.darkGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    margin: 0;
    text-transform: uppercase;
`;

const Header2Column = styled(Column)`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.medium};
    margin: 0;
    text-transform: uppercase;
`;

const FlexColumn = styled(Column)`
    flex: auto;
`;

const Details = ({ className, type, viewModel }) => {
    const showUnexpectedItemsCount = type === INVENTORY.name;
    const labels = componentStrings.evidence.inventory.InventoryDetails[type].labels;
    const { completedDateUtc } = viewModel;
    const {
        missingItemsCount,
        notes,
        ownerUserId,
        scannedItemsCount,
        storageLocationFullDisplayPath,
        unexpectedItemsCount,
    } = getViewModelProperties(viewModel);

    return (
        <Wrapper className={className}>
            <HeaderRow>
                <Header1Column>{labels.results}</Header1Column>

                <Row className="row">
                    <Header2Column>{labels.scannedItemsCount}:</Header2Column>
                    <Column>{scannedItemsCount}</Column>
                </Row>

                <Row className="row">
                    <Header2Column>{labels.missingItemsCount}:</Header2Column>
                    <Column>{missingItemsCount}</Column>
                </Row>

                {showUnexpectedItemsCount && (
                    <Row className="row">
                        <Header2Column>{labels.unexpectedItemsCount}:</Header2Column>
                        <Column>{unexpectedItemsCount}</Column>
                    </Row>
                )}
            </HeaderRow>

            <Row className="row">
                <Header1Column>{labels.details}</Header1Column>
                <Row className="row">
                    <Header2Column>{labels.completionDate}:</Header2Column>
                    <FlexColumn>
                        {completedDateUtc ? (
                            <FormattedDate
                                date={completedDateUtc}
                                format={FormattedDate.FORMATS.FORM_DATE}
                            />
                        ) : (
                            '-'
                        )}
                    </FlexColumn>
                </Row>
            </Row>

            <Row className="row">
                <Header1Column />
                <Header2Column>{labels.responsiblePersonnel}:</Header2Column>
                <FlexColumn>{(ownerUserId && ownerUserId.fullName) || '-'}</FlexColumn>
            </Row>

            <Row className="row">
                <Header1Column />
                <Header2Column>{labels.storageLocation}:</Header2Column>
                <FlexColumn>{storageLocationFullDisplayPath || '-'}</FlexColumn>
            </Row>

            {notes && (
                <Row className="row">
                    <Header1Column />
                    <Header2Column>{labels.notes}:</Header2Column>
                    <FlexColumn>{notes}</FlexColumn>
                </Row>
            )}
        </Wrapper>
    );
};

Details.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    viewModel: PropTypes.object.isRequired,
};

Details.defaultProps = {
    className: '',
    type: INVENTORY.name,
};

export default Details;

import { chain, find, get, isArray, isEmpty, map, noop, omit, some, uniq, values } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedRouter, withRouter } from 'react-router';
import styled from 'styled-components';

import {
    Attribute,
    AttributeTypeEnum,
    AttributeTypeEnumType,
    FieldNameEnumType,
} from '@mark43/rms-api';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { AttributeViewModel } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { ValueOf } from '~/client-common/types';

import { arbiterMFTInput } from '../../../arbiter';
import { abilitiesEnum, OnlyWithAbility } from '../../../abilities';
import {
    attributeLoadingStateByAttributeTypeSelector,
    attributeOptionsByTypeForCurrentDepartmentSelector,
} from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import { SimpleLoading } from '../../../../../legacy-redux/components/core/Loading';
import Link from '../../../components/links/Link';
import { shouldShowFieldNamesSelector } from '../../../../../legacy-redux/selectors/globalSelectors';
import { computeAllowedAttributeTypesToLoad } from '../../../attributes/utils/computeAllowedAttributeTypesToLoad';
import { logWarning } from '../../../../../core/logging';
import { AttributeLoadingState } from '../../../attributes/utils/types';
import FormElementMultiSelectButtonRadio from './FormElementMultiSelectButtonRadio';
import { ButtonRadioOption } from './types';

const RequiredAsterisk = styled.span`
    color: ${(props) => props.theme.colors.red};
`;

type AttributeMultiSelectButtonRadioProps = {
    attributeType: AttributeTypeEnumType;
    error: string;
    fieldName: FieldNameEnumType;
    helpText?: string;
    includeAbbr?: boolean;
    includeExpired?: boolean;
    isRequired?: boolean;
    label: string;
    multiple: boolean;
    onAttributeLoadSuccess: (attributes: Attribute[]) => void;
    onChange: (value: (string | number | boolean)[]) => void;
    router: InjectedRouter;
    sortByIteratees?: (keyof AttributeViewModel)[];
    value: (string | number | boolean)[];
};

const sortByRmsEventIdIteratee: (keyof AttributeViewModel)[] = ['rmsEventId'];
const ASTERISK = '*';

const createLink = (attributeType: AttributeTypeEnumType, showLinks: boolean) => {
    const displayName = get(AttributeTypeEnum[attributeType], 'displayName');
    return showLinks ? (
        <Link to={`/admin/attributes/${attributeType}`} openInNewTab={true}>
            {displayName}
        </Link>
    ) : (
        <span>{displayName}</span>
    );
};

const BaseAttributeMultiSelectButtonRadio: (
    props: AttributeMultiSelectButtonRadioProps
) => JSX.Element = ({
    attributeType,
    error,
    fieldName,
    helpText,
    includeAbbr = false,
    includeExpired = false,
    isRequired = false,
    label,
    multiple,
    onAttributeLoadSuccess,
    onChange,
    router,
    sortByIteratees = sortByRmsEventIdIteratee,
    value,
    ...otherProps
}) => {
    const attributeLoadingStateByAttributeType = useSelector(
        attributeLoadingStateByAttributeTypeSelector
    );
    const attributeOptionsByTypeForCurrentDepartment = useSelector(
        attributeOptionsByTypeForCurrentDepartmentSelector
    );
    const attributeLoadingState = attributeLoadingStateByAttributeType(attributeType);
    const attributeLoadingValues = values(attributeLoadingState);
    const isLoading = some(attributeLoadingValues, { loadingState: true });
    const shouldShowFieldNames = useSelector(shouldShowFieldNamesSelector);
    const dispatch = useDispatch();
    const attributeTypesToLoad = useMemo(
        () => computeAllowedAttributeTypesToLoad(attributeLoadingState),
        [attributeLoadingState]
    );
    const options = attributeOptionsByTypeForCurrentDepartment({
        type: attributeType,
        includeExpired,
        additionalIds: [],
        includeAbbr,
        sortByIteratees,
    });
    const initialSelectedOptions = isEmpty(value)
        ? []
        : chain(value)
              .map((val) => {
                  const currentOption = find(options, (option) => option.value === val);
                  if (!currentOption) {
                      return false;
                  }
                  const currentOptionAsButtonRadioOption =
                      // if 'display' is a key in currentOption it's a legacy option
                      // let's replace the 'display' key with a 'label' key
                      'display' in currentOption
                          ? { ...omit(currentOption, 'display'), label: currentOption.display }
                          : currentOption;
                  return currentOptionAsButtonRadioOption;
              })
              .compact()
              .value();
    const [selectedOptions, setSelectedOptions] = useState<ButtonRadioOption[]>(
        initialSelectedOptions
    );
    const departmentIds = map(options, 'departmentId');
    const uniqueDepartmentIds = uniq(departmentIds);
    if (uniqueDepartmentIds.length > 1) {
        logWarning('Attributes from multiple departments found in attribute select', {
            attributeType,
            departmentIds: uniqueDepartmentIds,
            attributeIds: map(options, 'value'),
        });
    }

    useEffect(() => {
        if (!isEmpty(attributeTypesToLoad)) {
            dispatch(loadAttributesForType({ attributeType: attributeTypesToLoad }))
                // @ts-expect-error RmsAction<Promise<Attribute[]>> is not thenable
                .then((attributes: Attribute[]) => {
                    if (!!onAttributeLoadSuccess) {
                        onAttributeLoadSuccess(attributes);
                    }
                })
                .catch(noop);
        }
    }, [attributeLoadingState, attributeTypesToLoad, dispatch, onAttributeLoadSuccess, options]);

    const onSelectionChange = (newSelectedOptions: ButtonRadioOption[]) => {
        setSelectedOptions(newSelectedOptions);
        const newAttributeIds = map(newSelectedOptions, ({ value }) => value);
        onChange(newAttributeIds);
    };

    const hasAsyncError =
        !isLoading &&
        some(
            attributeLoadingValues,
            (loadingState: ValueOf<AttributeLoadingState>) => loadingState.error
        );

    const rawLabel = label || '';
    const showLinks = !!router;
    const requiredAsterisk = isRequired ? ASTERISK : '';
    const labelWithAsteriskIfRequired = (
        <span>
            {rawLabel}
            <RequiredAsterisk>{requiredAsterisk}</RequiredAsterisk>
        </span>
    );
    const formLabel = shouldShowFieldNames ? (
        <div>
            {labelWithAsteriskIfRequired}
            <FeatureFlagged flag="RMS_TOGGLE_FIELD_LABELS_ENABLED">
                <OnlyWithAbility has={abilitiesEnum.ADMIN.EDIT_GLOBAL_ATTRIBUTE_CONFIGURATION}>
                    <div>
                        {componentStrings.core.ButtonRadio.attributeType}
                        {isArray(attributeType)
                            ? map(attributeType, (attribute) => (
                                  <div key={attribute}>{createLink(attribute, showLinks)} </div>
                              ))
                            : createLink(attributeType, showLinks)}
                    </div>
                </OnlyWithAbility>
            </FeatureFlagged>
        </div>
    ) : (
        <div>{labelWithAsteriskIfRequired}</div>
    );

    return (
        <>
            {isLoading ? (
                <SimpleLoading />
            ) : (
                <FormElementMultiSelectButtonRadio
                    error={
                        error ||
                        (hasAsyncError
                            ? componentStrings.core.attributeComponents.attributeTypeLoadingError
                            : undefined)
                    }
                    fieldName={fieldName}
                    forceShowError={hasAsyncError}
                    helpText={helpText}
                    isRequired={isRequired}
                    label={formLabel}
                    multiple={multiple}
                    onChange={onSelectionChange}
                    options={options}
                    selectedOptions={selectedOptions}
                    {...otherProps}
                />
            )}
        </>
    );
};

const AttributeMultiSelectButtonRadio = withRouter(BaseAttributeMultiSelectButtonRadio);

export const ArbiterMFTAttributeMultiSelectButtonRadio = arbiterMFTInput(
    AttributeMultiSelectButtonRadio
);

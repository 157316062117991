import keyMirror from 'keymirror';
import { createSelector } from 'reselect';

import { get, compact, map, keyBy, size, uniq, omit, find, forEach } from 'lodash';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import {
    fireSubdivisionAttrTypes,
    policeSubdivisionAttrTypes,
    emsSubdivisionAttrTypes,
} from '../../configuration';

export const agencyTypeEnum = keyMirror({
    FIRE: null,
    POLICE: null,
    EMS: null,
});

// CONSTANTS

export const DISPATCH_AREA_OOJ_DESCRIPTION_PATH = 'outOfJurisdictionDescription';
export const DISPATCH_AREA_OOJ_NAME_PATH = 'outOfJurisdictionName';
export const DISPATCH_AREA_PROPERTY_NAME_PATH = 'propertyName';
export const FIRE_DISPATCH_AREA_PATH = 'fireDispatchArea';
export const EMS_DISPATCH_AREA_PATH = 'emsDispatchArea';
const FIRE_SUBDIVISIONS_PATH = 'fire';
export const POLICE_DISPATCH_AREA_PATH = 'policeDispatchArea';
const DISPATCH_AREA_SHAPEFILE_ID_PATH = 'shapefileId';
export const POLICE_DISPATCH_AREA_SHAPEFILE_ID_PATH = `${POLICE_DISPATCH_AREA_PATH}.${DISPATCH_AREA_SHAPEFILE_ID_PATH}`;
export const FIRE_DISPATCH_AREA_SHAPEFILE_ID_PATH = `${FIRE_DISPATCH_AREA_PATH}.${DISPATCH_AREA_SHAPEFILE_ID_PATH}`;
export const EMS_DISPATCH_AREA_SHAPEFILE_ID_PATH = `${EMS_DISPATCH_AREA_PATH}.${DISPATCH_AREA_SHAPEFILE_ID_PATH}`;
const POLICE_SUBDIVISIONS_PATH = 'police';
const EMS_SUBDIVISIONS_PATH = 'ems';
export const SHAPEFILE_FEATURE_PROPERTY_NAME_PATH = 'shapefileFeaturePropertyName';

export const baseWizardUiSelector = (state) => state.ui.subdivisionsV2Admin.wizard;

const subdivisionsAdminFormSelector = createSelector(
    baseWizardUiSelector,
    (wizard) => wizard.subdivisionsAdminForm
);

export const isFireDispatchAreaAddedSelector = createSelector(
    subdivisionsAdminFormSelector,
    (subdivisionsAdminForm) => subdivisionsAdminForm.isFireDispatchAreaAdded
);

export const isPoliceDispatchAreaAddedSelector = createSelector(
    subdivisionsAdminFormSelector,
    (subdivisionsAdminForm) => subdivisionsAdminForm.isPoliceDispatchAreaAdded
);

export const isEmsDispatchAreaAddedSelector = createSelector(
    subdivisionsAdminFormSelector,
    (subdivisionsAdminForm) => subdivisionsAdminForm.isEmsDispatchAreaAdded
);

const subdivisionFields = buildFlatFormFieldViewModels([
    'id',
    'subdivisionName',
    SHAPEFILE_FEATURE_PROPERTY_NAME_PATH,
    'shapefileId',
    'outOfJurisdictionDisplayValue',
    'outOfJurisdictionDisplayAbbreviation',
]);

function clearShapefileFeatures(subdivisions) {
    return map(subdivisions, (subdivision) =>
        omit(subdivision, SHAPEFILE_FEATURE_PROPERTY_NAME_PATH)
    );
}

const formModule = createFormModule({
    formName: formClientEnum.SUBDIVISIONS_ADMIN,
    convertToFormModel: (
        subdivisions,
        attributesByType,
        oojDispatchAreaByAgencyTypeGlobalAttrId,
        fireDispatchAreaShapefileFeatureProperty,
        policeDispatchAreaShapefileFeatureProperty,
        emsDispatchAreaShapefileFeatureProperty
    ) => {
        const indexedByAttrType = keyBy(subdivisions, 'depthAttributeType');

        const getSubdivisionWithOOJ = (attrType) => {
            const subdivision = indexedByAttrType[attrType];
            if (!subdivision) {
                return null;
            }
            const oojAttr = find(attributesByType(attrType), ({ isNone, none }) => isNone || none);
            if (!oojAttr) {
                return subdivision;
            }
            return {
                ...subdivision,
                outOfJurisdictionDisplayValue: oojAttr.val,
                outOfJurisdictionDisplayAbbreviation: oojAttr.abbr,
            };
        };

        const mappings = {
            police: compact(map(policeSubdivisionAttrTypes, getSubdivisionWithOOJ)),
            fire: compact(map(fireSubdivisionAttrTypes, getSubdivisionWithOOJ)),
            ems: compact(map(emsSubdivisionAttrTypes, getSubdivisionWithOOJ)),
        };

        const policeShapefileIds = uniq(map(mappings.police, 'shapefileId'));
        const policeShapefileId = size(policeShapefileIds) === 1 ? policeShapefileIds[0] : null;
        const policeDispatchAreaShapefileId = get(
            policeDispatchAreaShapefileFeatureProperty,
            'shapefileId',
            policeShapefileId
        );

        const fireShapefileIds = uniq(map(mappings.fire, 'shapefileId'));
        const fireShapefileId = size(fireShapefileIds) === 1 ? fireShapefileIds[0] : null;
        const fireDispatchAreaShapefileId = get(
            fireDispatchAreaShapefileFeatureProperty,
            'shapefileId',
            fireShapefileId
        );

        const emsShapefileIds = uniq(map(mappings.ems, 'shapefileId'));
        const emsShapefileId = size(emsShapefileIds) === 1 ? emsShapefileIds[0] : null;
        const emsDispatchAreaShapefileId = get(
            emsDispatchAreaShapefileFeatureProperty,
            'shapefileId',
            emsShapefileId
        );
        /**
         * Because a CAD dispatch area depends on both a shapefile feature
         * property in RMS and a corresponding dispatch area in CAD, we only try
         * to look up a CAD dispatch area if we find a shapefile feature
         * property first.
         */
        const hasFireDispatchArea = !!get(fireDispatchAreaShapefileFeatureProperty, 'propertyName');

        const hasPoliceDispatchArea = !!get(
            policeDispatchAreaShapefileFeatureProperty,
            'propertyName'
        );

        const hasEmsDispatchArea = !!get(emsDispatchAreaShapefileFeatureProperty, 'propertyName');

        const fireOojDispatchArea = hasFireDispatchArea
            ? oojDispatchAreaByAgencyTypeGlobalAttrId(globalAttributes.agencyTypeGlobal.fire)
            : {};
        const policeOojDispatchArea = hasPoliceDispatchArea
            ? oojDispatchAreaByAgencyTypeGlobalAttrId(globalAttributes.agencyTypeGlobal.police)
            : {};
        const emsOojDispatchArea = hasEmsDispatchArea
            ? oojDispatchAreaByAgencyTypeGlobalAttrId(globalAttributes.agencyTypeGlobal.medical)
            : {};

        return {
            fire: fireShapefileId ? mappings.fire : clearShapefileFeatures(mappings.fire),
            police: policeShapefileId ? mappings.police : clearShapefileFeatures(mappings.police),
            ems: emsShapefileId ? mappings.ems : clearShapefileFeatures(mappings.ems),
            fireShapefileId,
            policeShapefileId,
            emsShapefileId,
            [FIRE_DISPATCH_AREA_PATH]: {
                [DISPATCH_AREA_SHAPEFILE_ID_PATH]: fireDispatchAreaShapefileId,
                [DISPATCH_AREA_OOJ_DESCRIPTION_PATH]: get(fireOojDispatchArea, 'description'),
                [DISPATCH_AREA_OOJ_NAME_PATH]: get(fireOojDispatchArea, 'name'),
                [DISPATCH_AREA_PROPERTY_NAME_PATH]: get(
                    fireDispatchAreaShapefileFeatureProperty,
                    'propertyName'
                ),
                fireOojDispatchAreaObj: fireOojDispatchArea,
            },
            [POLICE_DISPATCH_AREA_PATH]: {
                [DISPATCH_AREA_SHAPEFILE_ID_PATH]: policeDispatchAreaShapefileId,
                [DISPATCH_AREA_OOJ_DESCRIPTION_PATH]: get(policeOojDispatchArea, 'description'),
                [DISPATCH_AREA_OOJ_NAME_PATH]: get(policeOojDispatchArea, 'name'),
                [DISPATCH_AREA_PROPERTY_NAME_PATH]: get(
                    policeDispatchAreaShapefileFeatureProperty,
                    'propertyName'
                ),
                policeOojDispatchAreaObj: policeOojDispatchArea,
            },
            [EMS_DISPATCH_AREA_PATH]: {
                [DISPATCH_AREA_SHAPEFILE_ID_PATH]: emsDispatchAreaShapefileId,
                [DISPATCH_AREA_OOJ_DESCRIPTION_PATH]: get(emsOojDispatchArea, 'description'),
                [DISPATCH_AREA_OOJ_NAME_PATH]: get(emsOojDispatchArea, 'name'),
                [DISPATCH_AREA_PROPERTY_NAME_PATH]: get(
                    emsDispatchAreaShapefileFeatureProperty,
                    'propertyName'
                ),
                emsOojDispatchAreaObj: emsOojDispatchArea,
            },
        };
    },
    convertFromFormModel: (model) => {
        const fireShapefileId = get(model, 'fireShapefileId');
        const policeShapefileId = get(model, 'policeShapefileId');
        const emsShapefileId = get(model, 'emsShapefileId');
        const fire = map(get(model, FIRE_SUBDIVISIONS_PATH), (subdivision) => ({
            ...subdivision,
            shapefileId: fireShapefileId,
        }));
        const police = map(get(model, POLICE_SUBDIVISIONS_PATH), (subdivision) => ({
            ...subdivision,
            shapefileId: policeShapefileId,
        }));
        const ems = map(get(model, EMS_SUBDIVISIONS_PATH), (subdivision) => ({
            ...subdivision,
            shapefileId: emsShapefileId,
        }));
        return {
            fireShapefileId: size(fire) ? fireShapefileId : null,
            policeShapefileId: size(police) ? policeShapefileId : null,
            emsShapefileId: size(ems) ? emsShapefileId : null,
            fire,
            police,
            ems,
            fireDispatchArea: get(model, FIRE_DISPATCH_AREA_PATH),
            policeDispatchArea: get(model, POLICE_DISPATCH_AREA_PATH),
            emsDispatchArea: get(model, EMS_DISPATCH_AREA_PATH),
        };
    },
    fieldViewModels: buildFlatFormFieldViewModels([
        'policeShapefileId',
        'fireShapefileId',
        'emsShapefileId',
        {
            type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
            key: POLICE_SUBDIVISIONS_PATH,
            fields: subdivisionFields,
        },
        {
            type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
            key: FIRE_SUBDIVISIONS_PATH,
            fields: subdivisionFields,
        },
        {
            type: fieldTypeClientEnum.N_ITEMS_FIELDSET,
            key: EMS_SUBDIVISIONS_PATH,
            fields: subdivisionFields,
        },
        {
            key: FIRE_DISPATCH_AREA_PATH,
            type: fieldTypeClientEnum.FIELDSET,
            fields: buildFlatFormFieldViewModels([
                {
                    key: DISPATCH_AREA_PROPERTY_NAME_PATH,
                },
                {
                    key: DISPATCH_AREA_OOJ_NAME_PATH,
                },
                {
                    key: DISPATCH_AREA_OOJ_DESCRIPTION_PATH,
                },
            ]),
        },
        {
            key: POLICE_DISPATCH_AREA_PATH,
            type: fieldTypeClientEnum.FIELDSET,
            fields: buildFlatFormFieldViewModels([
                {
                    key: DISPATCH_AREA_PROPERTY_NAME_PATH,
                },
                {
                    key: DISPATCH_AREA_OOJ_NAME_PATH,
                },
                {
                    key: DISPATCH_AREA_OOJ_DESCRIPTION_PATH,
                },
            ]),
        },
        {
            key: EMS_DISPATCH_AREA_PATH,
            type: fieldTypeClientEnum.FIELDSET,
            fields: buildFlatFormFieldViewModels([
                {
                    key: DISPATCH_AREA_PROPERTY_NAME_PATH,
                },
                {
                    key: DISPATCH_AREA_OOJ_NAME_PATH,
                },
                {
                    key: DISPATCH_AREA_OOJ_DESCRIPTION_PATH,
                },
            ]),
        },
    ]),
});

export default formModule;

// HELPERS

/**
 * Get the dispatch area path.
 * @param {string} agencyType Police, fire or ems.
 * @returns {string} The path.
 */
export function getDispatchAreaPath(agencyType) {
    if (agencyType === agencyTypeEnum.FIRE) {
        return FIRE_DISPATCH_AREA_PATH;
    } else if (agencyType === agencyTypeEnum.EMS) {
        return EMS_DISPATCH_AREA_PATH;
    }

    return POLICE_DISPATCH_AREA_PATH;
}

/**
 * Get the subdivisions path.
 * @param {string} agencyType Police, fire or ems.
 * @returns {string} The path.
 */
export function getSubdivisionsPath(agencyType) {
    if (agencyType === agencyTypeEnum.FIRE) {
        return FIRE_SUBDIVISIONS_PATH;
    } else if (agencyType === agencyTypeEnum.EMS) {
        return EMS_SUBDIVISIONS_PATH;
    }

    return POLICE_SUBDIVISIONS_PATH;
}

export function clearShapefileFeaturesAction(agencyType, dispatchAreaPath = false) {
    return (dispatch, getState) => {
        if (dispatchAreaPath) {
            const dispatchAreaModel = formModule.selectors.formModelByPathSelector(getState())(
                dispatchAreaPath
            );

            const newDispatchAreaModel = omit(dispatchAreaModel, DISPATCH_AREA_PROPERTY_NAME_PATH);
            dispatch(formModule.actionCreators.changePath(dispatchAreaPath, newDispatchAreaModel));
        } else {
            const dispatchAreaPath = getDispatchAreaPath(agencyType);
            const subdivisionsPath = getSubdivisionsPath(agencyType);
            const dispatchModel = formModule.selectors.formModelByPathSelector(getState())(
                dispatchAreaPath
            );

            const subDivisionsModel = formModule.selectors.formModelByPathSelector(getState())(
                subdivisionsPath
            );

            const newDispatchModel = omit(dispatchModel, DISPATCH_AREA_PROPERTY_NAME_PATH);
            const newSubdivisionsModel = clearShapefileFeatures(subDivisionsModel);

            dispatch(formModule.actionCreators.changePath(dispatchAreaPath, newDispatchModel));
            dispatch(formModule.actionCreators.changePath(subdivisionsPath, newSubdivisionsModel));
        }
    };
}

export function clearDispatchArea(agencyType) {
    return (dispatch) => {
        const dispatchAreaPath = getDispatchAreaPath(agencyType);
        dispatch(formModule.actionCreators.changePath(dispatchAreaPath, {}));
    };
}

/**
 * The validation framework doesn't support client-side validation of NItems
 * so we just manually validate them here.
 */
export function validate() {
    const setValidity = formModule.actionCreators.setValidity;
    return (dispatch, getState) => {
        dispatch(formModule.actionCreators.validate());
        const state = getState();
        const formModel = formModule.selectors.formModelSelector(state);
        const isFireDispatchAreaAdded = isFireDispatchAreaAddedSelector(state);
        const isPoliceDispatchAreaAdded = isPoliceDispatchAreaAddedSelector(state);
        const isEmsDispatchAreaAdded = isEmsDispatchAreaAddedSelector(state);
        let isValid = true;
        const validateType = (agencyType, shapefileIdPath, isDispatchAreaAdded) => {
            const dispatchAreaPath = getDispatchAreaPath(agencyType);
            const subdivisionsPath = getSubdivisionsPath(agencyType);
            const dispatchAreaShapefileFeaturePropertyNamePath = `${dispatchAreaPath}.${DISPATCH_AREA_PROPERTY_NAME_PATH}`;
            const dispatchAreaOojNamePath = `${dispatchAreaPath}.${DISPATCH_AREA_OOJ_NAME_PATH}`;
            const dispatchAreaOojDescriptionPath = `${dispatchAreaPath}.${DISPATCH_AREA_OOJ_DESCRIPTION_PATH}`;
            const subdivisions = get(formModel, subdivisionsPath);
            const shapefileId = get(formModel, shapefileIdPath);
            const dispatchAreaShapefileFeaturePropertyName = get(
                formModel,
                dispatchAreaShapefileFeaturePropertyNamePath
            );
            const dispatchAreaOojName = get(formModel, dispatchAreaOojNamePath);
            const dispatchAreaOojDescription = get(formModel, dispatchAreaOojDescriptionPath);

            const shapefileIdValid = !size(subdivisions) || shapefileId;
            if (!shapefileIdValid) {
                isValid = false;
            }

            dispatch(
                setValidity(shapefileIdPath, {
                    requiredError: !!shapefileIdValid,
                })
            );

            forEach(subdivisions, (subdivision, index) => {
                const validateHasField = (field) => {
                    const fieldValid = !!get(subdivision, field);
                    if (!fieldValid) {
                        isValid = false;
                    }
                    dispatch(
                        setValidity(`${subdivisionsPath}.${index}.${field}`, {
                            requiredError: fieldValid,
                        })
                    );
                };

                validateHasField('subdivisionName');
                validateHasField('shapefileFeaturePropertyName');
                validateHasField('outOfJurisdictionDisplayValue');
                validateHasField('outOfJurisdictionDisplayAbbreviation');
            });

            // validate dispatch area
            const isDispatchAreaShapefileFeaturePropertyNameValid =
                !isDispatchAreaAdded || !!dispatchAreaShapefileFeaturePropertyName;
            const isDispatchAreaOojNameValid = !dispatchAreaOojDescription || !!dispatchAreaOojName;

            if (!isDispatchAreaShapefileFeaturePropertyNameValid || !isDispatchAreaOojNameValid) {
                isValid = false;
            }

            dispatch(
                setValidity(dispatchAreaShapefileFeaturePropertyNamePath, {
                    requiredError: isDispatchAreaShapefileFeaturePropertyNameValid,
                })
            );

            dispatch(
                setValidity(dispatchAreaOojNamePath, {
                    requiredError: isDispatchAreaOojNameValid,
                })
            );
        };
        validateType(agencyTypeEnum.FIRE, 'fireShapefileId', isFireDispatchAreaAdded);
        validateType(agencyTypeEnum.POLICE, 'policeShapefileId', isPoliceDispatchAreaAdded);
        validateType(agencyTypeEnum.EMS, 'emsShapefileId', isEmsDispatchAreaAdded);
        return isValid;
    };
}

import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

export default function CaseTypeCell(elasticCase) {
    const { caseType, primaryOffenseCode } = getViewModelProperties(elasticCase);
    return (
        <>
            <div>{caseType}</div>
            <div>{primaryOffenseCode}</div>
        </>
    );
}

import { RefContextEnum } from '@mark43/rms-api';

import { createFormConfiguration } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

export const formName = RefContextEnum.FORM_CREATE_WARRANT_STUB_SIDE_PANEL.name;

export const getFormConfiguration = () =>
    createFormConfiguration({
        warrantTypeAttrId: { fieldName: fields.WARRANT_WARRANT_TYPE_ATTR_ID },
        warrantNumber: { fieldName: fields.WARRANT_WARRANT_NUMBER },
        reportingEventNumber: { fieldName: fields.WARRANT_REPORTING_EVENT_NUMBER },
        warrantIssuedDateUtc: { fieldName: fields.WARRANT_WARRANT_ISSUED_DATE_UTC },
        description: { fieldName: fields.WARRANT_DESCRIPTION },
        issuingJudge: { fieldName: fields.WARRANT_ISSUING_JUDGE },
        bailAmount: { fieldName: fields.WARRANT_BAIL_AMOUNT },
        obtainingOfficerUserId: { fieldName: fields.WARRANT_OBTAINING_OFFICER_USER_ID },
        obtainingOfficerOther: { fieldName: fields.WARRANT_OBTAINING_OFFICER_OTHER },
        isOtherJurisdiction: { fieldName: fields.WARRANT_IS_OTHER_JURISDICTION },
        issuingAgencyName: { fieldName: fields.WARRANT_ISSUING_AGENCY_NAME },
        issuingAgencyOri: { fieldName: fields.WARRANT_ISSUING_AGENCY_ORI },
    });

import { forEach } from 'lodash';
import ResourceMethod from './ResourceMethod';

// default configuration for all resources
const RETRY = true;
const RETRY_ON: unknown[] = [];
const RETRY_ATTEMPTS = 1;
const LOG = true;
const LOG_LEVEL = 'DEBUG';

// The resource class is used for legacy/client-common
// The `createResource` is preferred within client because it preserves type information
export default class Resource {
    private name: string;
    private retry: boolean;
    private retryOn: unknown[];
    private retryAttempts: number;
    private log: boolean;
    private logLevel: string;
    private methods: ResourceMethods;

    constructor({
        name = '',
        config: {
            retry = RETRY,
            retryOn = RETRY_ON,
            retryAttempts = RETRY_ATTEMPTS,
            log = LOG,
            logLevel = LOG_LEVEL,
        } = {},
        methods = {},
    }) {
        this.name = name;
        this.retry = retry;
        this.retryOn = retryOn;
        this.retryAttempts = retryAttempts;
        this.log = log;
        this.logLevel = logLevel;
        this.methods = methods;
        this._buildMethods();
    }

    _buildMethods() {
        forEach(this.methods, (method, methodName) => {
            const sMethod = new ResourceMethod(
                this.name,
                methodName,
                method,
                this.retry,
                this.retryOn,
                this.retryAttempts,
                this.log,
                this.logLevel
            );
            // @ts-expect-error Type these arbitrary methods with an index signature on this class
            // Take care to not collide with the keys of the existing private properties
            this[methodName] = (...args) => sMethod.runDefault(...args);
        });
    }
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type SingleResourceMethod = (...args: any[]) => Promise<unknown>;

type ResourceMethods = {
    [index: string]: SingleResourceMethod;
};

type ResourceConfig<T extends ResourceMethods> = {
    name: string;
    methods: T;
};

export function createResource<T extends ResourceMethods>({ methods }: ResourceConfig<T>): T {
    return methods;
}

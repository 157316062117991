import {
    difference,
    isArray,
    isEmpty,
    isNull,
    isUndefined,
    isObject,
    merge,
    reduce,
    trim,
} from 'lodash';
import invariant from 'invariant';

/**
 * findAdditionsAndSubtractions
 * Given two arrays, returns a two dimensional array consisting of
 * 1. An array of items that were present in the new array, but not the original
 * 2. An array of items that were present in the original array, but not the new array
 * @param   oldArray
 * @param   newArray
 */
export const findAdditionsAndSubtractions = <T>(oldArray: T[], newArray: T[]) => {
    invariant(
        isArray(oldArray) && isArray(newArray),
        'findAdditionsAndSubtractions expects 2 valid arrays'
    );
    return [difference(newArray, oldArray), difference(oldArray, newArray)];
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const isUndefinedOrNull = (value: any): value is null | undefined =>
    isUndefined(value) || isNull(value);

export const isBlank = (str: string) => isEmpty(trim(str));

/**
 * Whether to display content to the user. The following types of data will not be displayed:
 *  - false
 *  - undefined
 *  - null
 *  - []
 *  - ''
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const isValueDisplayable = (value: any) =>
    !(
        value === false ||
        isUndefinedOrNull(value) ||
        (isArray(value) && isEmpty(value)) ||
        value === ''
    );

/**
 * Given values, return them as an array.
 * Returns
 *   - `undefined` or `null` if the values are `undefined` or `null`
 *     accordingly
 *   - An array of `values`, if `values` is not an Array
 *   - no-op, if `values` is already an Array
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const valuesToArray = (values: any) => {
    if (!isUndefinedOrNull(values) && !isArray(values)) {
        return [values];
    }
    return values;
};

/**
 * Converts any depth object into a flat object
 * where the keys are nested key
 * paths and values are values at that path
 *
 * Copied and modified from here: https://github.com/lodash/lodash/issues/2240
 *
 * Simple / most common use-case (you only need to supply object): flattenKeys({
 *  a: {
 *      b: {
 *          c: 'test'
 *      },
 *      d: {
 *          e: 'test2'
 *      }
 *  }
 * }) // {'a.b.c': 'test', 'a.d.e': 'test2'}
 *
 * @param   obj         The object to flatten
 * @param   delimiter   The char used to delimit path depth
 * @param   path        Array of strings used to compute final property path
 */
export const flattenKeys = (
    obj: Record<string, unknown> | unknown,
    delimiter = '.',
    path: string[] = []
): Record<string, unknown> =>
    !isObject(obj)
        ? { [path.join(delimiter)]: obj }
        : reduce(
              // need to assert type here to get type checks in CAD to pass
              obj as Record<string, unknown>,
              (acc, next, key) => merge(acc, flattenKeys(next, delimiter, [...path, key])),
              {}
          );

/**
 * getTruthyValuesByKey
 * Given an array of items and an item key, returns truthy values of items
 * @param   arr
 * @param   key
 */
export const getTruthyValuesByKey = <T extends object, K extends keyof T>(
    arr: T[],
    key: K
): Required<T>[K][] => {
    return arr.reduce<Required<T>[K][]>((acc, item) => {
        return item[key] ? [...acc, item[key]] : acc;
    }, []);
};

import { TrafficCrashRoadwayEntityLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'trafficCrashRoadwayEntityLinks';

const trafficCrashRoadwayEntityLinksModule = createLinkModule<TrafficCrashRoadwayEntityLink>({
    type: NEXUS_STATE_PROP,
    keys: ['trafficCrashRoadwayId', 'entityType', 'entityId', 'linkType'],
});

// ACTIONS

// SELECTORS

export const trafficCrashRoadwayEntityLinksWhereSelector =
    trafficCrashRoadwayEntityLinksModule.selectors.linksWhereSelector;

// REDUCER
export default trafficCrashRoadwayEntityLinksModule.reducerConfig;

import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import { RmsDispatch } from '../../../core/typings/redux';
import { InlineBanner } from '../../core/components/InlineBanner';
import { currentUserHasAbilitySelector } from '../../core/current-user/state/ui';
import redirectInsufficientPermissions from '../../core/utils/redirectInsufficientPermissions';
import {
    complianceExportTypesLoadingErrorMessageSelector,
    getComplianceExportTypes,
    resetComplianceUiState,
} from '../state/ui';

const strings = componentStrings.compliance.ComplianceWrapper;

const StyledInlineBanner = styled(InlineBanner)`
    margin-top: 30px;
`;

const ComplianceWrapper: React.FC = ({ children }) => {
    const dispatch = useDispatch<RmsDispatch>();
    const isMounted = useRef(false);
    const errorMessage = useSelector(complianceExportTypesLoadingErrorMessageSelector);
    const hasAbility = useSelector(currentUserHasAbilitySelector);

    useEffect(() => {
        if (
            !hasAbility(abilitiesEnum.REPORTING.COMPLIANCE_EXPORT_DASHBOARD) &&
            !hasAbility(abilitiesEnum.REPORTING.NIBRS_WORKSPACE)
        ) {
            dispatch(redirectInsufficientPermissions());
        }
    }, [dispatch, hasAbility]);

    useEffect(() => {
        if (!isMounted.current) {
            dispatch(getComplianceExportTypes());
        }

        return () => {
            isMounted.current = true;
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetComplianceUiState());
        };
    }, [dispatch]);

    return errorMessage ? (
        <StyledInlineBanner status="error">
            {strings.loadError}
            <br />
            <br />
            {errorMessage}
        </StyledInlineBanner>
    ) : (
        <>{children}</>
    );
};

export default ComplianceWrapper;

import { UnitState } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const cadUnitStatusesModule = createNormalizedModule<UnitState>({
    type: 'cadUnitStatuses',
});

export default cadUnitStatusesModule.reducerConfig;

export const storeCadUnitStatuses = cadUnitStatusesModule.actionCreators.storeEntities;
export const cadUnitStatusesSelector = cadUnitStatusesModule.selectors.entitiesSelector;
export const cadUnitStatusByIdSelector = cadUnitStatusesModule.selectors.entityByIdSelector;

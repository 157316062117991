import { get } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';

import { impoundByReportIdSelector } from '~/client-common/core/domain/impounds/state/data';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import withCard from '../../utils/withCard';
import Card from '../../../../../legacy-redux/components/core/Card';
import towVehicleImpoundCard from '../../state/ui/towVehicleImpoundCard';
import { refreshTowVehicleImpoundForm } from '../../state/ui';
import { registerCard } from '../../utils/cardsRegistry';
import testIds from '../../../../../core/testIds';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import TowVehicleImpoundCardForm from './TowVehicleImpoundCardForm';
import TowVehicleImpoundCardSummary from './TowVehicleImpoundCardSummary';

const mapStateToProps = createStructuredSelector({
    impoundByReportId: impoundByReportIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

class TowVehicleImpoundCard extends React.Component {
    constructor(...args) {
        super(...args);

        this.onEdit = this.onEdit.bind(this);
        this.onSaveProgress = this.onSaveProgress.bind(this);
        this.onSave = this.onSave.bind(this);

        registerCard({
            cardModule: towVehicleImpoundCard,
            onSave: this.onSave,
        });
    }

    componentDidMount() {
        this.props.refreshTowVehicleImpoundForm();
    }

    onEdit() {
        this.props.editCallback(() => this.props.onEdit());
    }

    onSaveProgress() {
        return this.props.onSaveProgress();
    }

    onSave() {
        return this.props.onSave();
    }

    render() {
        const {
            card = {},
            currentReportId,
            impoundByReportId,
            currentReportCardUITitleByType,
        } = this.props;

        const impound = impoundByReportId(currentReportId);
        const impoundId = impound.id;

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.TOW_VEHICLE_IMPOUND.id);

        const summaryModeContent = card.summaryMode ? (
            <TowVehicleImpoundCardSummary impoundId={impoundId} />
        ) : undefined;

        const editModeContent = !card.summaryMode ? <TowVehicleImpoundCardForm /> : undefined;

        return (
            <Card
                className={card.anchor}
                anchor={card.anchor}
                title={cardTitle}
                testId={testIds.TOW_VEHICLE_IMPOUND_CARD}
                renderContent={(summaryMode) => {
                    if (summaryMode) {
                        return summaryModeContent;
                    } else {
                        return editModeContent;
                    }
                }}
                onEdit={this.onEdit}
                errors={card.errorMessages}
                summaryMode={card.summaryMode}
                canEdit={get(card.canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(card.canEditReportCardStatus, 'errorMessage')}
                saving={card.saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

const mapDispatchToProps = {
    refreshTowVehicleImpoundForm,
};

export default compose(
    withCard(towVehicleImpoundCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TowVehicleImpoundCard);

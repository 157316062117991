import type { DepartmentProfile, UserProfileView } from '@mark43/rms-api';
import environmentEnum from '~/client-common/core/enums/client/environmentEnum';
import { getFullName } from '~/client-common/helpers/userHelpers';

/**
 * In Pendo, the Account ID is for the group that the user belongs to.
 * https://support.pendo.io/hc/en-us/articles/360045829772-Planning-your-Pendo-installation
 *
 * We use the Account ID to identify the tenant (subdomain or department). This prefix helps make the Account ID unique
 * per environment. Production tenants do not have a prefix because they're considered the default.
 */
function getEnvironmentPrefix(): string {
    switch (MARK43_ENV) {
        case environmentEnum.QA:
            return 'qa-';
        case environmentEnum.STAGING:
            return 'staging-';
        default:
            return '';
    }
}

/**
 * Initialize Pendo.
 * https://support.pendo.io/hc/en-us/articles/360046272771-Developer-s-guide-to-installing-Pendo
 * "Call this function after users are authenticated in your app and your visitor and account id values are available"
 */
export default function initPendo({
    userProfile,
    departmentProfile,
}: {
    userProfile: UserProfileView;
    departmentProfile: DepartmentProfile;
}): void {
    if (window.pendo) {
        window.pendo.initialize({
            visitor: {
                id: `${userProfile.primaryEmail}-${userProfile.userId.toString()}-${
                    departmentProfile.department.subDomain
                }`,
                email: userProfile.primaryEmail,
                full_name: getFullName(userProfile),
                role: userProfile.primaryUserGroup || null,
            },
            account: {
                id: `${getEnvironmentPrefix()}${departmentProfile.department.subDomain}-${
                    departmentProfile.departmentId
                }`,
            },
        });
    }
}

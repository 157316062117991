import React, { FC } from 'react';
import { Icon } from '@arc/icon';
import { Text, Box, cssVar } from 'arc';
import { JmsBooking } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';

const strings = componentStrings.quickSearch.booking;

type AssociatedRecordsSearchBookingItemProps = {
    item: JmsBooking;
};

const AssociatedRecordsSearchBookingItem: FC<AssociatedRecordsSearchBookingItemProps> = ({
    item,
}) => {
    return (
        <Box
            display="flex"
            alignItems="flex-start"
            gap={cssVar('arc.space.3')}
            padding={`${cssVar('arc.space.4')} ${cssVar('arc.space.2')}`}
        >
            <Icon icon="Booking" size="md" color="icon.default" />
            <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                gap={cssVar('arc.space.1')}
                flexGrow={1}
            >
                <Text variant="headingSm">{strings.bookingTitle(item.bookingNumber)}</Text>
                <Text variant="headingXs" fontWeight="normal" color="tertiary">
                    <FormattedDate format="shortDate" date={item.beginDate} />
                </Text>
            </Box>
        </Box>
    );
};

export default AssociatedRecordsSearchBookingItem;

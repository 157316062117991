import { size, reduce, filter, get } from 'lodash';
import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Fieldset as MFTFieldset, lifecycleOptions } from 'markformythree';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import styled from 'styled-components';
import { SimpleGrid } from 'arc';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { agencyProfileOptionsWhereSelector } from '~/client-common/core/domain/agency-profiles/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import getLocationsResource from '~/client-common/core/domain/locations/resources/locationsResource';

import ArbiterForm from '../../markformythree-arbiter/ArbiterForm';
import { ArbiterMFTAttributeSelect as ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTAgencyProfileSelect as ArbiterMFTAgencyProfileSelect } from '../../forms/components/selects/AgencyProfileSelect';
import { createLocationSidePanelFormConfiguration } from '../state/forms/locationSidePanelForm';
import { SecondarySectionHeader } from '../../components/typography';
import { currentUserDepartmentIdSelector } from '../../current-user/state/ui';
import LocationInfoSection from './LocationInfoSection';

const strings = componentStrings.core.LocationSidePanel;

const CustomRow = styled(SimpleGrid)`
    grid-gap: 0 var(--arc-space-3);
    width: 100%;
`;

const HeaderWrapper = styled.div`
    margin-top: 10px;
`;

// Need to add padding to the pick list so that it doesn't
// get cutoff by the sidepanel footer.
// This is a quick hack/fix for now, but we should fix
// this for the core component, not here
const ClassificationWrapper = styled.div`
    padding-bottom: 150px;
`;

class LocationSidePanelForm extends Component {
    constructor(...args) {
        super(...args);
        this.formConfiguration = createLocationSidePanelFormConfiguration();

        this.state = {
            isAddressMode: true,
            isEditMode: false,
            subdivisionAttributeIdsByAgency: {},
        };
    }
    componentDidMount() {
        const { applicationSettings } = this.props;
        const multiagencySubdivisionsEnabled =
            applicationSettings.MULTI_AGENCY_SUBDIVISIONS_ENABLED;

        if (multiagencySubdivisionsEnabled) {
            this.getAgencySubdivisionAttributes();
        }
    }
    getAgencySubdivisionAttributes = () => {
        const onSuccess = (responseSubvisions) => {
            // This object looks like that {[agencyId]: {[attributeId]: true}}
            // This is used in subdivision filter to have a quick look-up by agencyId
            const subdivisionAttributeIdsByAgency = reduce(
                responseSubvisions,
                (result, agencySubdivisionLink) => {
                    if (get(result, agencySubdivisionLink.agencyId)) {
                        result[agencySubdivisionLink.agencyId][
                            agencySubdivisionLink.attributeId
                        ] = true;
                    } else {
                        result[agencySubdivisionLink.agencyId] = {
                            [agencySubdivisionLink.attributeId]: true,
                        };
                    }
                    return result;
                },
                {}
            );

            this.setState({ subdivisionAttributeIdsByAgency });
        };

        const locationsResource = getLocationsResource();
        locationsResource.getAgencySubdivisionAttributes().then(onSuccess);
    };

    switchToAddressMode = () => this.setState({ isAddressMode: true });

    switchToIntersectionMode = () => this.setState({ isAddressMode: false });

    switchToEditMode = () => this.setState({ isEditMode: true });

    isLocationEntityLink = () => {
        const { context } = this.props;
        return context === RefContextEnum.FORM_LOCATION_ENTITY_LINK_SIDE_PANEL.name;
    };

    onReclassifyLocation = ({ form, agencyId }) => {
        const { applicationSettings } = this.props;
        const multiagencySubdivisionsEnabled =
            applicationSettings.MULTI_AGENCY_SUBDIVISIONS_ENABLED;

        if (multiagencySubdivisionsEnabled) {
            const id = this.state.isEditMode ? undefined : form.get('id');
            const latitude = form.get('latitude');
            const longitude = form.get('longitude');
            const locality = form.get('locality');
            const postalCode = form.get('postalCode');
            const fullAddress = this.state.isAddressMode ? form.get('streetAddress') : undefined;

            const onError = (error) => {
                this.props.setErrors([error.message]);

                form.set('entityLink.subdivision1AttrId', undefined);
                form.set('entityLink.subdivision2AttrId', undefined);
                form.set('entityLink.subdivision3AttrId', undefined);
                form.set('entityLink.subdivision4AttrId', undefined);
                form.set('entityLink.subdivision5AttrId', undefined);
            };

            const onSuccess = (entityLink) => {
                const {
                    subdivision1AttrId,
                    subdivision2AttrId,
                    subdivision3AttrId,
                    subdivision4AttrId,
                    subdivision5AttrId,
                    subPremise1Name,
                    subPremise1Value,
                    subPremise2Name,
                    subPremise2Value,
                    subPremise3Name,
                    subPremise3Value,
                    subPremise4Name,
                    subPremise4Value,
                    subPremise5Name,
                    subPremise5Value,
                } = entityLink;

                form.set('entityLink.subdivision1AttrId', subdivision1AttrId);
                form.set('entityLink.subdivision2AttrId', subdivision2AttrId);
                form.set('entityLink.subdivision3AttrId', subdivision3AttrId);
                form.set('entityLink.subdivision4AttrId', subdivision4AttrId);
                form.set('entityLink.subdivision5AttrId', subdivision5AttrId);

                form.set('entityLink.subPremise1Name', subPremise1Name);
                form.set('entityLink.subPremise1Value', subPremise1Value);
                form.set('entityLink.subPremise2Name', subPremise2Name);
                form.set('entityLink.subPremise2Value', subPremise2Value);
                form.set('entityLink.subPremise3Name', subPremise3Name);
                form.set('entityLink.subPremise3Value', subPremise3Value);
                form.set('entityLink.subPremise4Name', subPremise4Name);
                form.set('entityLink.subPremise4Value', subPremise4Value);
                form.set('entityLink.subPremise5Name', subPremise5Name);
                form.set('entityLink.subPremise5Value', subPremise5Value);

                // Clear errors if exists from previous reclassification
                this.props.setErrors(undefined);
            };

            const locationsResource = getLocationsResource();
            locationsResource
                .multiagencyClassify(
                    {
                        id,
                        latitude,
                        longitude,
                        fullAddress,
                        locality,
                        postalCode,
                    },
                    agencyId
                )
                .then(onSuccess)
                .catch(onError);
        }
    };

    renderClassifications = (form) => {
        const agencyProfileOptionsPredicate = { departmentId: this.props.currentUserDepartmentId };
        const isAgencyProfileShown =
            size(this.props.agencyProfileOptionsWhere(agencyProfileOptionsPredicate)) > 1;

        const onAgencyChange = (agencyId) => this.onReclassifyLocation({ agencyId, form });

        const { applicationSettings } = this.props;
        const multiagencySubdivisionsEnabled =
            applicationSettings.MULTI_AGENCY_SUBDIVISIONS_ENABLED;

        const filterSubdivisionAttribute = !multiagencySubdivisionsEnabled
            ? undefined
            : (options) => {
                  const { subdivisionAttributeIdsByAgency } = this.state;
                  const agencyId = form.get('entityLink.agencyId');
                  const subdivisionAttributeMap = get(subdivisionAttributeIdsByAgency, agencyId);

                  return filter(options, (option) => {
                      const attributeId = get(option, 'value');
                      return !!get(subdivisionAttributeMap, attributeId);
                  });
              };

        return (
            this.isLocationEntityLink() && (
                <ClassificationWrapper>
                    <HeaderWrapper>
                        <SecondarySectionHeader>{strings.classifications}</SecondarySectionHeader>
                    </HeaderWrapper>
                    <MFTFieldset path="entityLink">
                        <FeatureFlagged flag="MULTI_AGENCY_SUBDIVISIONS_ENABLED">
                            {isAgencyProfileShown && (
                                <ArbiterMFTAgencyProfileSelect
                                    path="agencyId"
                                    clearable={false}
                                    predicate={agencyProfileOptionsPredicate}
                                    onChange={onAgencyChange}
                                />
                            )}
                        </FeatureFlagged>
                        <CustomRow columns={{ base: '2', md: '3' }}>
                            <ArbiterMFTAttributeSelect
                                path="subdivision1AttrId"
                                attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_1.name}
                                filterOptions={filterSubdivisionAttribute}
                            />
                            <ArbiterMFTAttributeSelect
                                path="subdivision2AttrId"
                                attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_2.name}
                                filterOptions={filterSubdivisionAttribute}
                            />
                            <ArbiterMFTAttributeSelect
                                path="subdivision3AttrId"
                                attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_3.name}
                                filterOptions={filterSubdivisionAttribute}
                            />
                            <ArbiterMFTAttributeSelect
                                path="subdivision4AttrId"
                                attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_4.name}
                                filterOptions={filterSubdivisionAttribute}
                            />
                            <ArbiterMFTAttributeSelect
                                path="subdivision5AttrId"
                                attributeType={AttributeTypeEnum.SUBDIVISION_DEPTH_5.name}
                                filterOptions={filterSubdivisionAttribute}
                            />
                            <ArbiterMFTAttributeSelect
                                path="countyCodeAttrId"
                                attributeType={AttributeTypeEnum.COUNTY_CODE.name}
                            />
                        </CustomRow>
                        <ArbiterMFTAttributeSelect
                            path="typeAttrId"
                            attributeType={AttributeTypeEnum.LOCATION_CATEGORY.name}
                            length="lg"
                        />
                        <ArbiterMFTAttributeSelect
                            path="propertyTypeAttrId"
                            attributeType={AttributeTypeEnum.LOCATION_PROPERTY_TYPE.name}
                            length="md"
                        />
                    </MFTFieldset>
                </ClassificationWrapper>
            )
        );
    };

    render() {
        const { context, location, initialFormState } = this.props;
        const { isAddressMode, isEditMode } = this.state;

        return (
            <ArbiterForm
                name={context}
                context={context}
                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                render={(form) => (
                    <div>
                        <LocationInfoSection
                            isAddressMode={isAddressMode}
                            isEditMode={isEditMode}
                            isLocationEntityLink={this.isLocationEntityLink()}
                            location={location}
                            switchToAddressMode={this.switchToAddressMode}
                            switchToEditMode={this.switchToEditMode}
                            switchToIntersectionMode={this.switchToIntersectionMode}
                            form={form}
                        />
                        {this.renderClassifications(form)}
                    </div>
                )}
                configuration={this.formConfiguration}
                initialState={initialFormState}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    agencyProfileOptionsWhere: agencyProfileOptionsWhereSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    applicationSettings: applicationSettingsSelector,
});

export default compose(connect(mapStateToProps))(LocationSidePanelForm);

import { map, get } from 'lodash';
import { createSelector } from 'reselect';

import recentEntitiesActionTypes from '../../../../../legacy-redux/actions/types/recentEntitiesActionTypes';

export const REN_IDENTIFIER = 'REN';
export const REPORT_IDENTIFIER = 'REPORT';

const initialState = {
    loading: false,
    recentEntityIds: [],
    error: null,
    recentEntityIdsByOwner: {},
};
const entityDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message,
            };
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                // Make this obsolete by replacing all usages of recentEntityIds with
                // recentEntityIdsByOwner for more flexibility with embedded reports
                // Suggestion: Do this as part of the RMS_DYNAMIC_REPORT_ENABLED tear down.
                // Note: I am adding the FF key to this comment so when the tear down happens
                //       this comment is read by that another engineer.
                recentEntityIds: map(action.payload.recentEntities, 'id'),
                recentEntityIdsByOwner: {
                    ...state.recentEntityIdsByOwner,
                    [action.payload.ownerId]: map(action.payload.recentEntities, 'id'),
                },
                error: null,
            };

        case recentEntitiesActionTypes.CLEAR_RECENT_ENTITIES:
            return {
                ...state,
                loading: false,
                recentEntityIds: [], // TODO: Make this obsolete
                recentEntityIdsByOwner: {},
                error: null,
            };

        default:
            return state;
    }
};

const entityTypeReducer = (state = {}, action) => {
    switch (action.type) {
        case recentEntitiesActionTypes.CLEAR_RECENT_ENTITIES:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_FAILURE:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_START:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_SUCCESS:
            return {
                ...state,
                [action.payload.recentEntityType]: entityDataReducer(
                    state[action.payload.recentEntityType],
                    action
                ),
            };

        default:
            return state;
    }
};

const ownerIdReducer = (state = {}, action) => {
    switch (action.type) {
        case recentEntitiesActionTypes.CLEAR_RECENT_ENTITIES:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_FAILURE:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_START:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_SUCCESS:
            const ownerId = action.payload.renForRecents
                ? action.payload.renForRecents
                : action.payload.ownerId;
            return {
                ...state,
                [ownerId]: entityTypeReducer(state[ownerId], action),
            };

        default:
            return state;
    }
};

const uiReducer = (state = {}, action) => {
    switch (action.type) {
        case recentEntitiesActionTypes.CLEAR_RECENT_ENTITIES:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_FAILURE:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_START:
        case recentEntitiesActionTypes.REQUEST_RECENT_ENTITIES_SUCCESS:
            const ownerType = action.payload.renForRecents
                ? REN_IDENTIFIER
                : action.payload.ownerType || REPORT_IDENTIFIER;
            return {
                ...state,
                [ownerType]: ownerIdReducer(state[ownerType], action),
            };
        case recentEntitiesActionTypes.CLEAR_ALL_RECENT_ENTITIES:
            return {};
        default:
            return state;
    }
};

const recentEntitiesSelector = createSelector(
    (state) => state.ui.recentEntities,
    (recentEntities) => recentEntities
);

export const recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector = createSelector(
    recentEntitiesSelector,
    (recentEntities) => ({ ownerType, ownerId, entityType }) =>
        get(recentEntities, [ownerType, ownerId, entityType]) || {}
);

export default uiReducer;

import { AttributeTypeEnumType, AttributesResource } from '@mark43/rms-api';
import { isArray } from 'lodash';

import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'Attributes Resource',
    methods: {
        loadAttributesForType({
            attributeType,
            hideLoadingBar,
            includeIsNone = true,
            includeExpired,
            includeParents,
            includeDisabled,
        }: {
            attributeType: AttributeTypeEnumType | AttributeTypeEnumType[];
            hideLoadingBar?: boolean;
            includeIsNone?: boolean;
            includeExpired?: boolean;
            includeParents?: boolean;
            includeDisabled?: boolean;
        }) {
            return req<AttributesResource.GetAttributesForTypes>({
                method: 'POST',
                hideLoadingBar,
                url: 'attributes/types',
                data: isArray(attributeType) ? attributeType : [attributeType],
                params: {
                    include_is_none: includeIsNone,
                    include_out_of_date: includeExpired,
                    include_parents: includeParents,
                    include_disabled: includeDisabled,
                },
            });
        },
        getAttributesOfParentAttr({
            parentId,
            attributeType,
            hideLoadingBar = true,
        }: {
            parentId: number;
            attributeType: AttributeTypeEnumType;
            hideLoadingBar?: boolean;
        }) {
            return req<AttributesResource.GetDirectChildAttributesByType>({
                method: 'GET',
                hideLoadingBar,
                url: `attributes/${parentId}/children`,
                params: {
                    attributeType,
                },
            });
        },
    },
});

import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { find, values, size, map, filter, every, isEmpty, mapValues } from 'lodash';
import { Text as ArcText, cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';
import { propertyTitleForItemProfileSelector } from '~/client-common/core/domain/item-profiles/state/ui';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import testIds from '../../../../core/testIds';
import { entitySidebarDataForReportIdSelector } from '../state/ui';
import Text from '../../../core/forms/components/Text';
import { CollapsibleSectionWithStickyHeader } from '../../../core/components/CollapsibleSectionWithStickyHeader';
import { onClickToOnEnter } from '../../../core/utils/eventHelpers';
import { involvedProfilesSidebarConfig } from '../helpers/involvedProfilesSidebarConfig';
import {
    setIsOpenByKey,
    setIsOpenForAll,
    collapsibleStateReducer,
    initialCollapsibleState,
} from '../state/ui/involvedProfilesSidebar';
import { NoResults } from '../../../core/components/NoResults';
import { withAnalytics } from '../../../analytics/components/withAnalytics';
import { entitySidebarConfigEnum } from '../constants/entitySidebarConfigEnum';

const strings = componentStrings.reports.core.InvolvedProfilesSidebar;

const Filter = styled(Text)`
    float: none;
    margin: 0;

    .mark43-form-element {
        float: none;
    }
`;

const Label = styled(ArcText)`
    font-size: ${cssVar('arc.fontSizes.sm')};
    font-weight: ${cssVar('arc.fontWeights.semibold')};
    color: ${(props) => props.theme.colors[props.color || 'darkGrey']};
    ${(props) => props.onClick && 'cursor: pointer;'}
    margin-left: ${(props) => `${props.marginLeft || 0}px`};
    margin-right: ${(props) => `${props.marginRight || 0}px`};
`;

const Controls = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

const CollapsibleSections = styled.div`
    margin-top: 20px;
`;

const CollapsibleContent = styled.div`
    margin: 0 6px 0 15px;
    overflow: hidden;
`;

// Grouping these into the "button" category
// even though they don't actually use the button component
const LabelWithAnalytics = withAnalytics({
    analyticsKeyToAdd: AnalyticsPropertyEnum.BUTTON,
})(Label);

const UnconnectedInvolvedProfilesSidebar = ({
    entitySidebarDataForReportId,
    propertyTitleForItemProfile,
    reportId,
    className,
}) => {
    const [collapsibleState, dispatch] = React.useReducer(
        collapsibleStateReducer,
        initialCollapsibleState
    );

    const [query, setQuery] = React.useState('');
    const entitySidebarData = entitySidebarDataForReportId(reportId);

    // If there is no query, then don't filter
    const filteredEntitySidebarData = !query
        ? entitySidebarData
        : mapValues(entitySidebarData, (data, key) =>
              filter(data, (item) => {
                  const config = find(involvedProfilesSidebarConfig, { key }) || {};
                  const { shouldShowItemFactory } = config;
                  if (shouldShowItemFactory) {
                      return shouldShowItemFactory(query, { propertyTitleForItemProfile })(item);
                  }
                  return true;
              })
          );

    const allSectionsAreEmpty = every(values(filteredEntitySidebarData), isEmpty);

    const handleClickOpenAll = () => dispatch(setIsOpenForAll(true));
    const handleClickCloseAll = () => dispatch(setIsOpenForAll(false));
    const { DISPLAY_ONLY_ORGANIZATION_LABEL: organizationLabel } = useFields([
        DISPLAY_ONLY_ORGANIZATION_LABEL,
    ]);
    return (
        <div className={className}>
            <Filter
                value={query}
                onChange={(value) => setQuery(value)}
                placeholder={strings.filterPlaceholder}
            />
            <Controls>
                <LabelWithAnalytics
                    color="cobaltBlue"
                    data-test-id={testIds.ENTITY_SIDEBAR_EXPAND_ALL_BUTTON}
                    tabIndex={0}
                    variant="columnLabels"
                    onClick={handleClickOpenAll}
                    onKeyDown={onClickToOnEnter(handleClickOpenAll)}
                >
                    {strings.expandAll}
                </LabelWithAnalytics>
                <Label variant="columnLabels" marginLeft={6} marginRight={6}>
                    /
                </Label>
                <LabelWithAnalytics
                    color="cobaltBlue"
                    data-test-id={testIds.ENTITY_SIDEBAR_COLLAPSE_ALL_BUTTON}
                    tabIndex={0}
                    variant="columnLabels"
                    onClick={handleClickCloseAll}
                    onKeyDown={onClickToOnEnter(handleClickCloseAll)}
                >
                    {strings.collapseAll}
                </LabelWithAnalytics>
            </Controls>
            <CollapsibleSections>
                {allSectionsAreEmpty ? (
                    <NoResults children={!!query ? strings.noFilterResults : strings.noResults} />
                ) : (
                    map(involvedProfilesSidebarConfig, ({ title, key, Component, testId }) => {
                        const filteredResults = filteredEntitySidebarData[key];
                        return (
                            !!size(filteredResults) && (
                                <CollapsibleSectionWithStickyHeader
                                    testId={testId}
                                    key={key}
                                    title={
                                        key === entitySidebarConfigEnum.ORGANIZATIONS
                                            ? title(organizationLabel)
                                            : title
                                    }
                                    // If we are searching, then everything should be open
                                    isOpen={collapsibleState[key]}
                                    toggleOpen={(isOpen) => dispatch(setIsOpenByKey(key, isOpen))}
                                    children={map(
                                        filteredResults,
                                        ({ entity, links }) =>
                                            Component && (
                                                <CollapsibleContent key={entity.id}>
                                                    <Component entity={entity} links={links} />
                                                </CollapsibleContent>
                                            )
                                    )}
                                />
                            )
                        );
                    })
                )}
            </CollapsibleSections>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    entitySidebarDataForReportId: entitySidebarDataForReportIdSelector,
    propertyTitleForItemProfile: propertyTitleForItemProfileSelector,
});

export const InvolvedProfilesSidebar = connect(mapStateToProps)(UnconnectedInvolvedProfilesSidebar);

import { UsageSourceApplicationEnum } from '@mark43/rms-api';
/* globals COMMIT_HASH SEGMENT_WRITE_KEY */
import { includes, get } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { departmentProfilesSelector } from '~/client-common/core/domain/department-profiles/state/data';
import { AnalyticsPropertyEnum } from '../modules/analytics/constants/analyticsEnum';
import { TRACK_ANALYTICS_START } from '../modules/analytics/state/ui';
import actionTypes from '../legacy-redux/actions/types/authActionTypes';
import { userProfileSelector } from '../legacy-redux/selectors/userSelectors';
import testIds from './testIds';

const enabledTrackingIds = {
    // Creating a report
    [testIds.NEW_REPORT_BUTTON]: true,
    [testIds.RMS_AUTOGENERATE_REN_BUTTON]: true,
    [testIds.NEW_REPORT_OPTION]: true,

    // Adding person to report
    [testIds.NAME_SUMMARY_VIEW_ADD_NO_INFO_KNOWN]: true,
    [testIds.NAME_SUMMARY_VIEW_ADD_PERSON]: true,
    [testIds.NAME_SIDE_PANEL_SEARCH_BUTTON]: true,
    [testIds.NAME_SUMMARY_VIEW_REMOVE_NAME]: true,
    [testIds.NAME_SUMMARY_VIEW_EDIT_NAME]: true,

    // Capture side panel interactions
    [testIds.SIDE_PANEL_SAVE]: true,
    [testIds.SIDE_PANEL_CANCEL]: true,

    // Submitting a comment
    [testIds.REPORT_STATUS_COMMENTS_CARD_COMMENT_BUTTON]: true,

    // Time between edit + save
    [testIds.CARD_EDIT]: true,
    [testIds.CARD_SAVE]: true,

    // Capture modal interactions
    [testIds.MODAL_OK]: true,
    [testIds.MODAL_CANCEL]: true,

    // Capture action buttons
    [testIds.EDIT_BUTTON]: true,
    [testIds.DUPLICATE_BUTTON]: true,
    [testIds.TRASH_BUTTON]: true,

    // Capture mechanisms for navigating from RMS to CAD
    [testIds.INITIALS_DROPDOWN_OPEN_CAD]: true,
    [testIds.LINK_TO_CAD_EVENT]: true,

    // Collapsible sections to track
    [testIds.ENTITY_SIDEBAR_PEOPLE_COLLAPSIBLE_SECTION]: true,
    [testIds.ENTITY_SIDEBAR_ORGANIZATIONS_COLLAPSIBLE_SECTION]: true,
    [testIds.ENTITY_SIDEBAR_LOCATIONS_COLLAPSIBLE_SECTION]: true,
    [testIds.ENTITY_SIDEBAR_VEHICLES_COLLAPSIBLE_SECTION]: true,
    [testIds.ENTITY_SIDEBAR_PROPERTY_COLLAPSIBLE_SECTION]: true,

    // Expand/collapse all in entity sidebar
    [testIds.ENTITY_SIDEBAR_EXPAND_ALL_BUTTON]: true,
    [testIds.ENTITY_SIDEBAR_COLLAPSE_ALL_BUTTON]: true,

    // Tab components
    [testIds.ENTITY_SIDEBAR_TABS]: true,

    // Export redaction button
    [testIds.EXPORT_REDACTION_BUTTON]: true,

    // Quick export link via report export menu
    [testIds.REPORT_HEADER_EXPORTS_MENU_EXPORT_LINK]: true,
    [testIds.REPORT_HEADER_EXPORTS_MENU_ADVANCED_OPTIONS_LINK]: true,

    // Capture clicks on Card Header Error Links
    [testIds.CARD_ERROR_LINK]: true,

    // Report creation and submission workflows
    // Approval status: Draft
    [testIds.REPORT_STATUS_COMMENTS_CARD_VALIDATE_REPORT_BUTTON]: true,
    [testIds.REPORT_STATUS_COMMENTS_CARD_SUBMIT_REPORT_BUTTON]: true,
    // Approval status: Pending Supervisor Review
    [testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD_APPROVE_REPORT_BUTTON]: true,
    [testIds.REPORT_STATUS_COMMENTS_SUBMITTED_CARD_REJECT_REPORT_BUTTON]: true,
    // Approval status: Pending Secondary Review
    [testIds.REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_APPROVE_BUTTON]: true,
    [testIds.REPORT_STATUS_COMMENTS_PENDING_SECONDARY_REVIEW_CARD_REJECT_BUTTON]: true,
    [testIds.VALIDATE_REPORT_BUTTON]: true,
    // Dynamic Report phase 1
    [testIds.ADD_ARREST_REPORT_BUTTON]: true,

    // Evidence
    [testIds.CPS_ACTION_BAR_PRINT_LABELS]: true,
    [testIds.EVIDENCE_DASHBOARD_ACTION_BAR_PRINT_LABELS]: true,
    [testIds.EVIDENCE_FACILITIES_ADMIN_PRINT_ALL_LABELS]: true,
    [testIds.EVIDENCE_FACILITIES_ADMIN_PRINT_LABEL]: true,
    [testIds.EVIDENCE_ITEM_MENU_PRINT_LABEL]: true,

    // Photo Lineup
    [testIds.PHOTO_LINEUP_CREATE_LINEUP_BUTTON]: true,
    [testIds.PHOTO_LINEUP_FILTER_FORM_APPLY_FILTERS]: true,
    [testIds.PHOTO_LINEUP_FILTER_FORM_TOGGLE]: true,
    [testIds.PHOTO_LINEUP_FILTER_FORM_CLEAR_FILTERS]: true,
    [testIds.PHOTO_LINEUP_DETAIL_LINK]: true,
    [testIds.PHOTO_LINEUP_COMPOSE_CANCEL]: true,
    [testIds.PHOTO_LINEUP_COMPOSE_SAVE]: true,
    [testIds.PHOTO_LINEUP_COMPOSE_UPLOAD_IMAGES_BUTTON]: true,
    [testIds.PHOTO_LINEUP_COMPOSE_SHUFFLE_ORDER]: true,
    [testIds.PHOTO_LINEUP_EXPORT_DOWNLOAD_BUTTON]: true,
};

// Construct the event name based on what was done
function createEventNameFromAnalyticsData(analyticsData) {
    let eventString = '';

    const button = analyticsData[AnalyticsPropertyEnum.BUTTON];
    const keyboardShortcut = analyticsData[AnalyticsPropertyEnum.KEYBOARD_SHORTCUT];
    const link = analyticsData[AnalyticsPropertyEnum.LINK];
    const mentionChosenFrom = analyticsData[AnalyticsPropertyEnum.MENTION_CHOSEN_FROM];
    const photoLineupFilterSection =
        analyticsData[AnalyticsPropertyEnum.PHOTO_LINEUP_FILTER_SECTION];
    const collapsibleSection = analyticsData[AnalyticsPropertyEnum.COLLAPSIBLE_SECTION];
    const reportDefinitionChosen = analyticsData[AnalyticsPropertyEnum.REPORT_DEFINITION];
    const tabs = analyticsData[AnalyticsPropertyEnum.TABS];

    if (keyboardShortcut) {
        eventString += `Used ${keyboardShortcut} keyboard shortcut`;
    } else if (mentionChosenFrom) {
        eventString += 'Used mentions categories';
    } else if (reportDefinitionChosen) {
        eventString += `Used ${reportDefinitionChosen} report type`;
    } else if (photoLineupFilterSection) {
        eventString += `Used ${photoLineupFilterSection} section`;
    }
    // We could further map each `button` or `card` to a more human readable
    // form, but leaving alone for now
    else if (button) {
        eventString += `Used ${button} button`;
    } else if (link) {
        eventString += `Used ${link} link`;
    } else if (collapsibleSection) {
        eventString += `Used ${collapsibleSection} collapsible section`;
    } else if (tabs) {
        eventString += `Used ${tabs} tabs`;
    }

    return eventString;
}

// Handles initializing and making tracking calls
export const analyticsMiddleware =
    ({ analytics, sentry }) =>
    (store) =>
    (next) =>
    (action) => {
        const analyticsEnabled = applicationSettingsSelector(store.getState()).ANALYTICS_ENABLED;

        // Skip to the next middleware if mixpanel should
        // not be enabled
        if (!analyticsEnabled) {
            return next(action);
        }

        // If bootstrap succeeded, we need to initialize our analytics app
        if (action.type === actionTypes.BOOTSTRAP_SUCCESS) {
            const result = next(action);
            const state = store.getState();
            const userProfile = userProfileSelector(state);
            const userId = get(userProfile, 'userId');
            const primaryUserGroup = get(userProfile, 'primaryUserGroup');
            const departmentId = get(userProfile, 'departmentId');
            const departmentProfile = departmentProfilesSelector(state)[departmentId];
            const subDomain = get(departmentProfile, 'department.subDomain');
            const fullDomain = get(departmentProfile, 'department.fullDomain');

            analytics
                .initialize({
                    authToken: btoa(SEGMENT_WRITE_KEY),
                    departmentProfile: {
                        departmentId,
                        subDomain,
                    },
                    userAdminUrl: `https://${fullDomain}/#/admin/users/${userId}`,
                    userId,
                    primaryUserGroup,
                    mark43Environment: MARK43_ENV,
                    application: UsageSourceApplicationEnum.RMS.name,
                    applicationVersion: COMMIT_HASH,
                    platform: 'WEB',
                })
                .catch((e) => sentry.captureException(e));
            return result;
        }
        // If we are dealing with `analytics` actions,
        // we can just swallow them actions (by not calling `next`)
        // because we aren't actually doing anything with
        // them in the store
        else if (includes([TRACK_ANALYTICS_START], action.type)) {
            const testIdToFilterBy = get(action, 'meta.testIdToFilterBy');
            // If this action was dispatched from a generic tracking component
            // (e.g. `Button`, `Link`, etc) then check if the `testId` supplied
            // for that property is supported
            //
            // If its not supported, then don't fire a tracking event
            if (testIdToFilterBy && !enabledTrackingIds[testIdToFilterBy]) {
                return;
            }
            const eventName = createEventNameFromAnalyticsData(action.payload);
            if (eventName) {
                analytics.track(eventName, action.payload).catch((e) => sentry.captureException(e));
            }
        } else {
            return next(action);
        }
    };

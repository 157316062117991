import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'arc';
import { LinkTypesEnum } from '@mark43/rms-api';
import { map, isEmpty } from 'lodash';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { augmentActionAndStoreRmsHydratedPersonProfiles } from '~/client-common/core/domain/person-profiles/state/data';
import { augmentActionWithNexusHydratedOrganizations } from '~/client-common/core/domain/organization-profiles/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { PortalSidePanel } from '../../../../../legacy-redux/components/core/SidePanel';
import {
    OverlayBaseHelper,
    OverlayRenderProps,
} from '../../../../core/components/OverlayBaseHelper';
import { EFileLinkTypesT } from '../../../types';
import { useEFileContext } from '../../../hooks';
import { groupDefendants } from '../../../helpers';
import { eFileResource } from '../../../resources';
import testIds from '../../../../../core/testIds';
import { ImportableInvolvedProfilesContent } from './content/ImportableInvolvedProfilesContent';

const strings = componentStrings.eFiles.core.sidePanel;

type ImportInvolvedProfilesSidePanelCustomProperties = Record<string, never>;

export const ImportInvolvedProfilesSidePanel = ({ linkType }: { linkType: EFileLinkTypesT }) => {
    const {
        actions,
        getters,
        handlers: { getInvolvedProfiles, onFetchError },
    } = useEFileContext();
    const eFile = getters.efile.getEFile();

    const dispatch = useDispatch();

    const handleSave = async ({
        overlayBase,
        closePanel,
    }: OverlayRenderProps<ImportInvolvedProfilesSidePanelCustomProperties>) => {
        const eFileId = getters.efile.getEFileViewModel()?.id;
        if (!eFileId) {
            return;
        }

        const selectedNameEntities = getters.sidePanel.getEFileSelectedInvolvedProfilesToImport();
        const importProfileRequestWrapper = {
            efileId: eFileId,
            importProfileRequests: map(selectedNameEntities, (selectedNameEntity) => {
                return {
                    entityMasterId: selectedNameEntity.entityId,
                    entityType: selectedNameEntity.entityType,
                    linkType,
                };
            }),
        };
        try {
            await eFileResource.importInvolvedProfiles(importProfileRequestWrapper);

            const searchQuery = {
                query: { linkTypes: [linkType] },
                sorts: [],
            };
            try {
                const searchResult = await getInvolvedProfiles(searchQuery, eFileId);
                if (searchResult) {
                    actions.search.setEFileDefendants(searchResult);
                    const { personProfiles, organizationProfiles } = groupDefendants(
                        searchResult.items
                    );

                    dispatch(
                        augmentActionAndStoreRmsHydratedPersonProfiles(
                            { type: 'STORE_E_FILE_DEFENDANTS_PERSON_PROFILES' },
                            map(personProfiles, 'personProfile')
                        )
                    );
                    dispatch(
                        augmentActionWithNexusHydratedOrganizations(
                            {
                                type: 'STORE_E_FILE_DEFENDANTS_ORGANIZATION_PROFILES',
                            },
                            map(organizationProfiles, 'organizationProfile')
                        )
                    );
                }
            } catch {
                onFetchError();
                return;
            }
        } catch (error) {
            if (error instanceof Error) {
                overlayBase.setError(error.message);
                return;
            }
        }
        closePanel();
        return;
    };

    const { callResource } = useResourceDeferred(handleSave);

    const saveDisabled = React.useMemo(() => {
        return isEmpty(getters.sidePanel.getEFileSelectedInvolvedProfilesToImport());
    }, [getters.sidePanel]);

    if (!eFile) {
        return null;
    }

    return (
        <OverlayBaseHelper<ImportInvolvedProfilesSidePanelCustomProperties>
            id={overlayIdEnum.E_FILE_IMPORT_INVOLVED_PROFILES_SIDE_PANEL}
            renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => (
                <Button
                    variant="solid"
                    leadingVisual="Add"
                    onClick={open}
                    isDisabled={!eFile.canEdit}
                    ref={setCloseFocusRefs}
                    data-test-id={testIds.DEFENDANT_ADD_BUTTON}
                >
                    {strings.buttonLabel[LinkTypesEnum.DEFENDANT_IN_EFILE]}
                </Button>
            )}
        >
            {(renderProps) => {
                return (
                    <PortalSidePanel
                        saveDisabled={saveDisabled}
                        title={strings.title[linkType]}
                        closePanel={renderProps.closePanel}
                        savePanel={() => callResource(renderProps)}
                        errorMessages={renderProps.overlayBase.overlayState.errors}
                        isAtBottomOfStack={renderProps.overlayBase.isAtBottomOfStack}
                    >
                        <ImportInvolvedProfilesSidePanelContent
                            eFileId={eFile.efile.id}
                            linkType={linkType}
                        />
                    </PortalSidePanel>
                );
            }}
        </OverlayBaseHelper>
    );
};

const ImportInvolvedProfilesSidePanelContent = ({
    eFileId,
    linkType,
}: {
    eFileId: number;
    linkType: EFileLinkTypesT;
}) => {
    const { actions } = useEFileContext();

    useEffect(() => {
        return function cleanup() {
            actions.sidePanel.resetEFileSidePanel();
        };
    }, [actions.sidePanel]);

    return <ImportableInvolvedProfilesContent eFileId={eFileId} linkType={linkType} />;
};

import { DragonCurrentFormValue, ParentFormPath } from '../rms-types';
import { uiConfigurationIdToNumber } from './ui-configuration-id-to-number';

/**
 * Given a list of `DragonCurrentFormValue` returns a depth-indexed object of `ConfiguredFormInstanceView`s.
 * `nestingPath` is expected to include the current form as well, and this function will, by default, slice off the last element
 * to return only the parent path. If the whole path should be returned `options.onlyParentPath` must be set to `false`.
 */
export function getConfiguredFormPath(
    nestingPath: DragonCurrentFormValue[],
    options: { onlyParentPath?: boolean } = { onlyParentPath: true }
): ParentFormPath {
    return (options.onlyParentPath ? nestingPath.slice(0, -1) : nestingPath).reduce<ParentFormPath>(
        (acc, { configuredFormId, instanceId, referencingUiConfigurationId }, index) => {
            acc[index] = {
                configuredFormId: Number(configuredFormId),
                instanceId: Number(instanceId),
                parentReferencingUiConfigurationId: uiConfigurationIdToNumber(
                    referencingUiConfigurationId
                ),
            };
            return acc;
        },
        {}
    );
}

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import styled, { withTheme } from 'styled-components';

import { map } from 'lodash';

import { cssVar } from 'arc';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { attachmentViewModelsByEntitySelector } from '~/client-common/core/domain/attachments/state/ui';
import {
    currentCaseNoteLocationSelector,
    caseNoteLocationByNoteIdSelector,
} from '../../../../../modules/cases/core/state/ui';
import { useSubPremiseFormatters } from '../../../../../modules/core/locations/hooks/useSubPremiseFormatters';
import Icon, { iconTypes, iconSizes } from '../../../../../modules/core/components/Icon';
import { FileDownloadLink } from '../../Files';
import { getFilePath } from '../../../../helpers/fileHelper';
import { BodyMediumText } from '../../../../../modules/core/components/typography';

const Content = styled.div`
    margin-bottom: 20px;
`;

const Location = styled.div`
    display: flex;
    margin-bottom: 15px;
`;

const LocationInfo = styled(BodyMediumText)`
    flex: 1;
    padding-left: 10px;
    color: ${cssVar('arc.colors.text.tertiary')};
`;

const StyledFileDownloadLink = styled(FileDownloadLink)`
    a {
        width: calc(100% - 24px);
    }
`;

function NoteContentCell({
    id,
    content,
    caseNoteLocationByNoteId,
    attachmentViewModelsByEntity,
    theme,
}) {
    const location = caseNoteLocationByNoteId(id);
    const attachments = attachmentViewModelsByEntity(EntityTypeEnum.CASE_NOTE.name, id);
    const { locationNameFormatsForLocation } = useSubPremiseFormatters();

    return (
        <>
            {content && <Content dangerouslySetInnerHTML={{ __html: content }} />}
            {location && (
                <Location>
                    <Icon
                        color={theme.colors.mediumGrey}
                        type={iconTypes.LOCATION}
                        size={iconSizes.MEDIUM}
                    />
                    <LocationInfo>{locationNameFormatsForLocation({ location }).full}</LocationInfo>
                </Location>
            )}
            {map(attachments, (attachment) => {
                const attachmentViewModel = getViewModelProperties(attachment);
                const file = attachmentViewModel.originalFile;
                return (
                    <StyledFileDownloadLink
                        displayName={file.originalFileName}
                        path={getFilePath(file)}
                        key={file.id}
                    />
                );
            })}
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    currentCaseNoteLocation: currentCaseNoteLocationSelector,
    caseNoteLocationByNoteId: caseNoteLocationByNoteIdSelector,
    attachmentViewModelsByEntity: attachmentViewModelsByEntitySelector,
});

export default compose(connect(mapStateToProps), withTheme)(NoteContentCell);

const redirectMap = {
    '/': '/rms/login/#/',
};

const getRedirectPathname = ({ pathname }) => {
    const redirectKey = Object.keys(redirectMap).find((key) => pathname.startsWith(key));

    if (!redirectKey) {
        throw new Error('Specified redirect not defined in redirection map');
    }

    const pathOrFunc = redirectMap[redirectKey];
    return typeof pathOrFunc === 'function' ? pathOrFunc(pathname) : pathOrFunc;
};

const handleAuthRedirect = ({ location }) => {
    const { pathname, search = '' } = location;
    const redirectPath = getRedirectPathname({ pathname });
    const { origin } = window.location;
    window.location = `${origin}${redirectPath}${search}`;
    return true;
};

export default handleAuthRedirect;

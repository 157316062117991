import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import type {
    PartialReportDefinitionCard,
    PartialReportDefinitionOrderedCardViewModel,
} from '../types';
import { isInformationCard } from './informationCards';

/**
 * Same order as defaultRMSCoreCardOrder in ReportDefinitionOrderedCardService.java.
 */
export const defaultCardsForNewReportDefinition: PartialReportDefinitionCard[] = [
    {
        cardId: reportCardEnum.EVENT_INFO.id,
        isRequired: false,
        isHidden: false,
        title: 'Event Information',
    },
    {
        cardId: reportCardEnum.CRASH_EVENT_INFO.id,
        isRequired: false,
        isHidden: true,
        title: 'Crash Event Information',
    },
    {
        cardId: reportCardEnum.CRASH_LOCATION_INFO.id,
        isRequired: false,
        isHidden: true,
        title: 'Traffic Crash Location',
    },
    {
        cardId: reportCardEnum.SUPPLEMENT_INFO.id,
        isRequired: false,
        isHidden: true,
        title: 'Supplement Information',
    },
    {
        cardId: reportCardEnum.LEGACY_INFO.id,
        isRequired: false,
        isHidden: true,
        title: 'Migration Information',
    },
    {
        cardId: reportCardEnum.INVOLVED_PROFILES.id,
        isRequired: false,
        isHidden: false,
        title: 'Involved Profiles',
    },
    {
        cardId: reportCardEnum.PROPERTY.id,
        isRequired: false,
        isHidden: false,
        title: 'Property',
    },
    {
        cardId: reportCardEnum.VEHICLE.id,
        isRequired: false,
        isHidden: false,
        title: 'Vehicle',
    },
    {
        cardId: reportCardEnum.STOP.id,
        isRequired: false,
        isHidden: true,
        title: 'Stop',
    },
    {
        cardId: reportCardEnum.COURT_CASE.id,
        isRequired: false,
        isHidden: false,
        title: 'Court Case',
    },
    {
        cardId: reportCardEnum.RELATIONSHIPS.id,
        isRequired: false,
        isHidden: false,
        title: 'Relationships',
    },
    {
        cardId: reportCardEnum.SUMMARY_NARRATIVE.id,
        isRequired: false,
        isHidden: false,
        title: 'Summary Narrative',
    },
    {
        cardId: reportCardEnum.NARRATIVE.id,
        isRequired: false,
        isHidden: false,
        title: 'Narrative',
    },
    {
        cardId: reportCardEnum.ATTACHMENTS.id,
        isRequired: false,
        isHidden: false,
        title: 'Attachments',
    },
    {
        cardId: reportCardEnum.REPORT_STATUS_COMMENTS.id,
        isRequired: true,
        isHidden: false,
        title: 'Report Status & Comments',
    },
    {
        cardId: reportCardEnum.APPROVALS.id,
        isRequired: true,
        isHidden: false,
        title: 'Approvals',
    },
];

export const defaultOrderedCardViewModelForNewReportDefinition: PartialReportDefinitionOrderedCardViewModel =
    {
        isDefaultOrder: false,
        orderedCardLinks: defaultCardsForNewReportDefinition
            .filter(
                (link) =>
                    !link.isHidden &&
                    !isInformationCard(link.cardId) &&
                    link.cardId !== reportCardEnum.APPROVALS.id
            )
            .map((link, index) => ({
                cardId: link.cardId,
                ordinal: index + 1,
            })),
        reportDefinitionId: -1,
    };

import { AttributeTypeCodeTypeLink, AttributeTypeEnumType } from '@mark43/rms-api';
import { filter } from 'lodash';
import { createSelector } from 'reselect';
import createLinkModule from '../../../../utils/createLinkModule';

import getAttributeTypeCodesTypeLinksResource from '../../resources/attributeTypeCodesTypeLinksResource';
import { ClientCommonAction } from '../../../../../redux/types';

const SAVE_ATTRIBUTE_CODES_TYPE_LINKS_START =
    'admin-attribute-code-type-links/SAVE_ATTRIBUTE_CODE_TYPE_LINKS_START';
const SAVE_ATTRIBUTE_CODES_TYPE_LINKS_SUCCESS =
    'admin-attribute-code-type-links/SAVE_ATTRIBUTE_CODE_TYPE_LINKS_SUCCESS';
const SAVE_ATTRIBUTE_CODES_TYPE_LINKS_FAILURE =
    'admin-attribute-code-type-links/SAVE_ATTRIBUTE_CODE_TYPE_LINKS_FAILURE';

const attributeTypeCodesTypeLinksModule = createLinkModule<AttributeTypeCodeTypeLink>({
    type: 'attributeTypeCodesTypeLinks',
    keys: ['attributeType', 'codeTypeId', 'codeTypeSource'],
});

// ACTIONS
export const storeAttributeTypeCodesTypeLinks =
    attributeTypeCodesTypeLinksModule.actionCreators.storeLinks;
const replaceAttributeTypeCodesTypeLinksWhere =
    attributeTypeCodesTypeLinksModule.actionCreators.replaceLinksWhere;

// SELECTORS
export const attributeTypeCodesTypeLinksSelector =
    attributeTypeCodesTypeLinksModule.selectors.linksSelector;
export const attributeTypeCodesTypeLinksByAttributeTypeSelector = createSelector(
    attributeTypeCodesTypeLinksSelector,
    (attributeTypeCodesTypeLinks) => (attributeType: AttributeTypeEnumType) =>
        filter(attributeTypeCodesTypeLinks, { attributeType })
);

function saveAttributeTypeCodesTypeLinksStart() {
    return {
        type: SAVE_ATTRIBUTE_CODES_TYPE_LINKS_START,
    };
}

function saveAttributeTypeCodesTypeLinksSuccess(
    attributeCodeTypeLinks: AttributeTypeCodeTypeLink[]
) {
    return {
        type: SAVE_ATTRIBUTE_CODES_TYPE_LINKS_SUCCESS,
        payload: attributeCodeTypeLinks,
    };
}

function saveAttributeTypeCodesTypeLinksFailure(errMsg: string) {
    return {
        type: SAVE_ATTRIBUTE_CODES_TYPE_LINKS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveAttributeTypeCodesTypeLinks(
    attributeType: AttributeTypeEnumType,
    attributeCodeTypeLinks: AttributeTypeCodeTypeLink[]
): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        const resource = getAttributeTypeCodesTypeLinksResource();
        dispatch(saveAttributeTypeCodesTypeLinksStart());

        return resource
            .setAttributeCodeTypeLinksByAttributeType(attributeType, attributeCodeTypeLinks)
            .then((savedLinks: AttributeTypeCodeTypeLink[]) => {
                dispatch(replaceAttributeTypeCodesTypeLinksWhere({ attributeType }, savedLinks));
                dispatch(saveAttributeTypeCodesTypeLinksSuccess(savedLinks));
            })
            .catch((err: Error) => {
                dispatch(saveAttributeTypeCodesTypeLinksFailure(err.message));
                throw err;
            });
    };
}

export default attributeTypeCodesTypeLinksModule.reducerConfig;

import React, { FC, useContext } from 'react';
import { TaskView } from '@mark43/rms-api';
import TaskStatusCell from '../../TaskStatusCell';
import { TasksTableContext } from '../TasksTableContext';
import { useTaskStatusProps } from '../../../hooks/useTaskStatusProps';

export const TaskStatusMenu: FC<{ task: TaskView }> = ({ task }) => {
    const { refreshTasks, setModalTask } = useContext(TasksTableContext);
    const { options, taskEntityLinks } = useTaskStatusProps(task);

    return (
        <TaskStatusCell
            task={task}
            taskEntityLinks={taskEntityLinks}
            options={options}
            onMenuOpen={setModalTask}
            onUpdate={refreshTasks}
            style={{ width: 'auto' }}
        />
    );
};

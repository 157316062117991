import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { size } from 'lodash';
import { ReportCaseStatus, RefContextEnum } from '@mark43/rms-api';
import { lifecycleOptions, Observer, InferFormDataShape, Fields } from 'markformythree';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';
import { formatLinkedReportDisplayTitle } from '~/client-common/core/domain/reports/utils/reportsHelpers';
import { statusIsClosedSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import { reportShortTitleByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/data';
import stringsConfig from '~/client-common/core/strings';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { caseStatusOptionsSelector } from '../../../../cases/core/state/ui'

import { OnlyWithAbility } from '../../../../core/abilities';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTBooleanButtonRadio } from '../../../../core/forms/components/button-radios/BooleanButtonRadio';
import { ArbiterMFTUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { MFTNItems } from '../../../../core/forms/components/NItems';
import Fieldset from '../../../../core/forms/components/Fieldset';
import FormRow from '../../../../core/forms/components/FormRow';
import { useFormGetter } from '../../../../core/forms/hooks/useFormGetter';
import SidePanel, { SidePanelSection } from '../../../../../legacy-redux/components/core/SidePanel';
import reportCaseStatusesForm, {
    ReportCaseStatusesFormConfiguration,
} from '../../state/forms/reportCaseStatusesForm';
import {
    submitReportCaseStatuses,
    closeReportCaseStatusesSidePanel,
} from '../../state/ui/caseStatuses';
import { ExternalReportStatusFieldset } from '../../../../core/external-report-statuses/components/ExternalReportStatusFieldset';
import { OffenseCaseStatusFieldset } from '../../../../core/offense-case-statuses/components/OffenseCaseStatusFieldset';

type ReportCaseStatusFieldsetProps = {
    context: typeof RefContextEnum.FORM_REPORT_CASE_STATUSES_SIDE_PANEL.name;
};

const strings = stringsConfig.components.reports.ReportCaseStatusesForm;

const sidePanelContext = { name: boxEnum.CASE_STATUSES_SIDE_PANEL };
const formContext = RefContextEnum.FORM_REPORT_CASE_STATUSES_SIDE_PANEL.name;

function ReportCaseStatusFieldset(props: ReportCaseStatusFieldsetProps) {
    const reportShortTitleByReportId = useSelector(reportShortTitleByReportIdSelector);
    const statusIsClosed = useSelector(statusIsClosedSelector);
    const caseStatusFilter = useSelector(caseStatusOptionsSelector);

    const { getForm } = useFormGetter();
    const form = getForm(props.context);

    return (
        <MFTNItems<ReportCaseStatus>
            renderAddButton={undefined}
            renderRemoveButton={undefined}
            path="reportCaseStatuses"
            render={({ item, index }) => {
                const reportShortTitle = reportShortTitleByReportId(item.reportId);
                const title = reportShortTitle && formatLinkedReportDisplayTitle(reportShortTitle);

                const isCanceled = form?.get()?.reportCaseStatuses?.[index]?.isCanceled ?? false;

                return (
                    <Fieldset>
                        <div className="report-title">{title}</div>
                        <ArbiterMFTAttributeSelect
                            path="caseStatusAttrId"
                            attributeType="CASE_STATUS"
                            length="lg"
                            filterOptions={caseStatusFilter}
                            onChange={(caseStatusAttrId: number) => {
                                if (statusIsClosed(caseStatusAttrId)) {
                                    form?.set(
                                        `reportCaseStatuses[${index}].statusDateUtc`,
                                        nowUtc()
                                    );
                                }
                            }}
                            disabled={isCanceled}
                        />
                        <OnlyWithAbility has={abilitiesEnum.REPORTING.CANCEL_CASE_STATUS}>
                            <FormRow>
                                <ArbiterMFTBooleanButtonRadio
                                    path="isCanceled"
                                    onChange={(value: boolean) => {
                                        if (value === true) {
                                            form?.set(
                                                `reportCaseStatuses[${index}].caseStatusAttrId`,
                                                undefined
                                            );
                                        }
                                    }}
                                />
                                <ArbiterMFTUserSelect
                                    path="canceledById"
                                    multiple={false}
                                    length="lg"
                                    effectiveDate={null}
                                />
                            </FormRow>
                        </OnlyWithAbility>
                        <FormRow>
                            <ArbiterMFTAttributeSelect
                                path="closedByDivisionAttrId"
                                attributeType="DIVISION"
                                length="md"
                            />

                            <ArbiterMFTAttributeSelect
                                path="closedByUnitAttrId"
                                attributeType="PERSONNEL_UNIT"
                                length="md"
                            />
                        </FormRow>
                        <ArbiterMFTDatePicker path="statusDateUtc" includeTime={true} />
                    </Fieldset>
                );
            }}
        />
    );
}

const ReportCaseStatusesSidePanel = () => {
    const dispatch = useDispatch();
    const onSave = () => dispatch(submitReportCaseStatuses());
    const onCancel = () => dispatch(closeReportCaseStatusesSidePanel());
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const externalReportStatusTitle = formatFieldByName(
        fields.EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID
    );
    const internalReportStatusTitle = formatFieldByName(
        fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID
    );
    const offenseStatusTitle = formatFieldByName(fields.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID);

    return (
        <SidePanel
            title={strings.title}
            onSave={onSave}
            onCancel={onCancel}
            context={sidePanelContext}
            className="reporting-event-case-statuses-side-panel"
        >
            <ArbiterForm
                name={formContext}
                context={formContext}
                lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                configuration={reportCaseStatusesForm}
                render={() => {
                    return (
                        <>
                            <FeatureFlagged flag="RMS_CLEARANCE_IMPROVEMENTS_ENABLED">
                                <OnlyWithAbility
                                    has={abilitiesEnum.REPORTING.VIEW_EXTERNAL_REPORT_STATUS}
                                >
                                    <Observer<
                                        {
                                            externalReportStatus: InferFormDataShape<ReportCaseStatusesFormConfiguration>['externalReportStatus'];
                                        },
                                        ReportCaseStatusesFormConfiguration
                                    >
                                        subscriptions={{
                                            externalReportStatus: [
                                                'externalReportStatus',
                                                Fields.DEEP_MODEL,
                                            ],
                                        }}
                                        render={({ externalReportStatus }) =>
                                            !!externalReportStatus?.reportingEventId && (
                                                <SidePanelSection title={externalReportStatusTitle}>
                                                    <ExternalReportStatusFieldset
                                                        context={formContext}
                                                    />
                                                </SidePanelSection>
                                            )
                                        }
                                    />
                                </OnlyWithAbility>
                            </FeatureFlagged>
                            <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_CASE_STATUS}>
                                <Observer<
                                    {
                                        reportCaseStatuses: InferFormDataShape<ReportCaseStatusesFormConfiguration>['reportCaseStatuses'];
                                    },
                                    ReportCaseStatusesFormConfiguration
                                >
                                    subscriptions={{ reportCaseStatuses: 'reportCaseStatuses' }}
                                    render={({ reportCaseStatuses }) =>
                                        !!size(reportCaseStatuses) && (
                                            <SidePanelSection title={internalReportStatusTitle}>
                                                <ReportCaseStatusFieldset context={formContext} />
                                            </SidePanelSection>
                                        )
                                    }
                                />
                                <Observer<
                                    {
                                        offenseCaseStatuses: InferFormDataShape<ReportCaseStatusesFormConfiguration>['offenseCaseStatuses'];
                                    },
                                    ReportCaseStatusesFormConfiguration
                                >
                                    subscriptions={{ offenseCaseStatuses: 'offenseCaseStatuses' }}
                                    render={({ offenseCaseStatuses }) =>
                                        !!size(offenseCaseStatuses) && (
                                            <SidePanelSection title={offenseStatusTitle}>
                                                <OffenseCaseStatusFieldset context={formContext} />
                                            </SidePanelSection>
                                        )
                                    }
                                />
                            </OnlyWithAbility>
                        </>
                    );
                }}
            />
        </SidePanel>
    );
};

export default ReportCaseStatusesSidePanel;

import { AttributeTypeEnum } from '@mark43/rms-api';

import { map, pick, filter, get } from 'lodash';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { replaceMissingPersonsCardBundle } from '~/client-common/core/domain/reports/state/ui';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { refreshMissingPersonsForm } from '../forms/missingPersonsForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.MISSING_PERSONS.name,
    baseSelector: (state) => state.ui.report.missingPersonsCard,
    anchor: 'missing-persons-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);
                const report = currentReportSelector(state);
                const reportId = report.id;

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const { model } = form.getState();
                                const {
                                    missingPersonAdditionalInfo,
                                    missingPersonAdditionalInfoDescription,
                                    lastKnownLocation,
                                    locationWhereLocated,
                                } = model;
                                const attributes = attributesSelector(state);

                                const missingPerson = pick(model, [
                                    'id',
                                    'reportId',
                                    'lastContactDateUtc',
                                    'missingPersonCriticalityAttrId',
                                    'missingPersonCriticalityOther',
                                    'missingPersonTypeAttrId',
                                    'missingPersonTypeOther',
                                    'closureStatusAttrId',
                                    'closureStatusDateUtc',
                                ]);

                                const missingPersonAdditionalInfoReportAttributes = assignDescriptionToReportAttributes(
                                    map(
                                        missingPersonAdditionalInfo,
                                        (missingPersonAdditionalInfoAttrId) => {
                                            return {
                                                reportId,
                                                attributeType:
                                                    AttributeTypeEnum.MISSING_PERSON_ADDITIONAL_INFO
                                                        .name,
                                                attributeId: missingPersonAdditionalInfoAttrId,
                                            };
                                        }
                                    ),
                                    attributes,
                                    missingPersonAdditionalInfoDescription
                                );

                                const missingPersonsCardBundle = {
                                    missingPerson,
                                    reportAttributes: missingPersonAdditionalInfoReportAttributes,
                                    // Only keep the link if it has a location id
                                    locationEntityLinks: filter(
                                        [lastKnownLocation, locationWhereLocated],
                                        (location) => get(location, 'locationId')
                                    ),
                                };

                                return [
                                    dispatch(
                                        replaceMissingPersonsCardBundle({
                                            reportId,
                                            missingPersonsCardBundle,
                                        })
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                const formModel = dispatch(refreshMissingPersonsForm({ reportId }));
                                form.set('', formModel);
                            },
                        })
                    );
                }
            };
        },
    },
};

import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';

import Fieldset from '../../../core/forms/components/Fieldset';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFNItems } from '../../../core/forms/components/NItems';

import { identifyingMarksFieldsetViewModel } from '../../../../legacy-redux/configs/fieldsetsConfig';

const { title } = identifyingMarksFieldsetViewModel;

/**
 * This fieldset for scars/marks/tattoos has a default title and a default
 *   className.
 * @param {Object}  props
 * @param {string}  [props.path='identifyingMarks'] Form model state path.
 * @param {boolean} [props.fuzzyMatchingEnabled]
 * @param {string}  [props.className='light']
 */
export default pure(function IdentifyingMarksFieldset({
    path = 'identifyingMarks',
    fuzzyMatchingEnabled,
    className = 'light',
    ...otherProps
}) {
    return (
        <Fieldset title={title} className={className} {...otherProps}>
            <RRFNItems path={path} addItemOnDirty={true}>
                {() => [
                    <RRFText
                        key={0}
                        width={280}
                        className={classNames({
                            'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                        })}
                        path="description"
                    />,
                    <RRFAttributeSelect
                        key={1}
                        attributeType="BODY_PART"
                        includeExpired={true}
                        width={183}
                        path="bodyPartAttrId"
                    />,
                    <RRFAttributeSelect
                        key={2}
                        attributeType="IDENTIFYING_MARK_TYPE"
                        includeExpired={true}
                        width={183}
                        path="identifyingMarkTypeAttrId"
                    />,
                ]}
            </RRFNItems>
        </Fieldset>
    );
});

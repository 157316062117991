import React from 'react';
import { connect } from 'react-redux';
import { Form, lifecycleOptions } from 'markformythree';
import { HStack } from 'arc';
import styled from 'styled-components';
import { ElasticVehicle, SearchResultElasticVehicle } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { MFTText as _MFTText } from '../../../../core/forms/components/Text';
import { vehicleSearchToAddSearch } from '../../../../search/search-to-add/state/ui';
import {
    VEHICLE_SEARCH_TO_ADD_FORM,
    createVehicleSearchToAddFormConfiguration,
} from '../../../../search/search-to-add/state/forms/vehicleSearchToAddForm';

const strings = componentStrings.reports.EntityPrefillPopover;

const MFTText = styled(_MFTText)`
    width: 350px;
    margin: 0;
`;

export type SearchResults = {
    vehicles: ElasticVehicle[];
    totalCount: number;
};

type VehicleSearchFormProps = {
    vehicleSearchToAddSearch: unknown;
    setQuickSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    setSearchResults: React.Dispatch<React.SetStateAction<SearchResults | undefined>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const VehicleSearchForm: React.FC<VehicleSearchFormProps> = ({
    vehicleSearchToAddSearch,
    setSearchResults,
    setQuickSearchQuery,
    setIsLoading,
}) => {
    const executeVehicleSearch = () => {
        setIsLoading(true);
        // @ts-expect-error vehicleSearchToAddSearch not typed
        return vehicleSearchToAddSearch({
            onSuccess: (results: SearchResultElasticVehicle) => {
                // @ts-expect-error results.query.elasticQuery not typed
                if (results.query.elasticQuery.quickSearchQuery) {
                    // @ts-expect-error results.query.elasticQuery not typed
                    setQuickSearchQuery(results.query.elasticQuery.quickSearchQuery);
                    setSearchResults({
                        vehicles: results.items,
                        totalCount: results.totalCount,
                    });
                } else {
                    setSearchResults(undefined);
                }
                setIsLoading(false);
            },
            onError: () => {
                setIsLoading(false);
            },
        });
    };

    return (
        <Form
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={VEHICLE_SEARCH_TO_ADD_FORM}
            configuration={createVehicleSearchToAddFormConfiguration()}
            render={() => {
                return (
                    <HStack marginBottom={2} marginTop={2}>
                        <MFTText
                            path="quickSearchQuery"
                            placeholder={strings.vehicePlaceholder}
                            leftIcon="Search"
                            style={{ marginTop: 0 }}
                            onPressEnter={executeVehicleSearch}
                        />
                    </HStack>
                );
            }}
        />
    );
};

export default connect(null, { vehicleSearchToAddSearch })(VehicleSearchForm);

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Reporting Event Resource',
        methods: {
            /**
             * Check to see which report definitions we can still make for the given REN.
             */
            getEventInfoForReportingEventNumber(reportingEventNumber, options = {}) {
                const { ignoreFormatValidation } = options;
                return req({
                    method: 'GET',
                    url: `events/${reportingEventNumber}`,
                    params: {
                        ignore_format_validation: ignoreFormatValidation,
                    },
                });
            },
            getReportingEventInfoByReportingEventNumber(reportingEventNumber, options = {}) {
                const { reportDefinitionId, ignoreFormatValidation } = options;
                return req({
                    method: 'GET',
                    url: `events/reporting_event_number/${reportingEventNumber}`,
                    params: {
                        report_definition_id: reportDefinitionId,
                        ignore_format_validation: ignoreFormatValidation,
                    },
                });
            },
            getReportingEventInfoByCadAgencyEventNumber(cadAgencyEventNumber, options = {}) {
                const { reportDefinitionId, ignoreFormatValidation } = options;
                return req({
                    method: 'GET',
                    url: `events/cad_agency_event_number/${cadAgencyEventNumber}`,
                    params: {
                        report_definition_id: reportDefinitionId,
                        ignore_format_validation: ignoreFormatValidation,
                    },
                });
            },
            getMultiRenReportingEventInfoByCadAgencyEventNumber(
                cadAgencyEventNumber,
                reportingEventNumber,
                options = {}
            ) {
                const { reportDefinitionId, ignoreFormatValidation } = options;
                return req({
                    method: 'GET',
                    url: `events/cad_agency_event_number/multi_ren/${cadAgencyEventNumber}/${reportingEventNumber}`,
                    params: {
                        report_definition_id: reportDefinitionId,
                        ignore_format_validation: ignoreFormatValidation,
                    },
                });
            },
            getAcceptableReportsForRen(reportingEventNumber) {
                return req({
                    method: 'GET',
                    url: `events/acceptable/${reportingEventNumber}`,
                });
            },
        },
    });
}

/**
 * Resources for `ReportingEvent`s. This is temporarily a function to avoid
 *   dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

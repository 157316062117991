import httpHeaderEnum from '~/client-common/core/enums/client/httpHeaderEnum';

import { getAuthToken } from '../../../../core/auth';
import { clientStringifyQuery } from '../../../../legacy-redux/helpers/urlHelpers';

export const redirectToQuickCrashUrl = ({
    quickCrashUrl,
    openInNewWindow,
}: {
    quickCrashUrl: string;
    openInNewWindow: boolean;
}) => {
    const sessionToken = getAuthToken();
    const origin = window.location.origin;
    const params = {
        [httpHeaderEnum.X_SESSION_TOKEN]: sessionToken,
        redirect: quickCrashUrl,
    };
    const queryString = clientStringifyQuery(params, { snakeCaseKeys: false });
    const url = `${origin}/rms/api/traffic_crash/redirect${queryString}`;

    if (openInNewWindow) {
        window.open(url, '_blank');
    } else {
        window.location.assign(url);
    }
};

export const createReportInQuickCrash = ({
    reportingEventNumber,
    recordNumber,
    reportId,
}: {
    reportingEventNumber?: string;
    recordNumber?: string;
    reportId?: number;
}) => {
    const searchParams = new URLSearchParams();
    if (!!reportingEventNumber) {
        searchParams.append('reportingEventNumber', reportingEventNumber);
    }
    if (!!recordNumber) {
        searchParams.append('crashIdentifier', recordNumber);
    }
    if (!!reportId) {
        searchParams.append('m43ReportId', String(reportId));
    }

    const quickCrashUrl = `/crash/create?${searchParams}`;
    redirectToQuickCrashUrl({ quickCrashUrl, openInNewWindow: false });
};

const BASE_PATH = 'facility/link';
const BASE_URL = '/evidence/api';
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Item Facility Link Resource',
        methods: {
            loadItemFacilityLinks(itemProfileIds) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/item/batch`,
                    baseUrl: BASE_URL,
                    data: itemProfileIds,
                });
            },
            replaceItemFacilityLinks(itemFacilityLinks) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/item/replace/bulk`,
                    baseUrl: BASE_URL,
                    data: itemFacilityLinks,
                });
            },
            upsertItemFacilityLinks(itemFacilityLinks) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/item/bulk`,
                    baseUrl: BASE_URL,
                    data: itemFacilityLinks,
                });
            },
            deleteItemFacilityLink(itemFacilityLinkId) {
                return req({
                    method: 'DELETE',
                    url: `${BASE_PATH}/${itemFacilityLinkId}`,
                    baseUrl: BASE_URL,
                });
            },
        },
    });
}

/**
 * Resources for Item Facility Links. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { createSelector } from 'reselect';
import _, { get, isArray } from 'lodash';
import { Ability, AbilityRoleLink, UserRole } from '@mark43/rms-api';

import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getAbilitiesResource from '../../resources/abilitiesResource';
import { userRolesSelector } from '../../../user-roles/state/data';
import { abilityRoleLinksSelector } from '../../../ability-role-links/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

const abilitiesModule = createNormalizedModule<Ability>({
    type: 'abilities',
});

const LOAD_ABILITIES_START = 'abilities/LOAD_ABILITIES_START';
const LOAD_ABILITIES_SUCCESS = 'abilities/LOAD_ABILITIES_SUCCESS';
const LOAD_ABILITIES_FAILURE = 'abilities/LOAD_ABILITIES_FAILURE';

function loadAbilitiesStart() {
    return {
        type: LOAD_ABILITIES_START,
    };
}

function loadAbilitiesSuccess(abilities: Ability[]) {
    return {
        type: LOAD_ABILITIES_SUCCESS,
        payload: abilities,
    };
}

function loadAbilitiesFailure(message: string) {
    return {
        type: LOAD_ABILITIES_FAILURE,
        payload: message,
    };
}

export function loadAbilities(): ClientCommonAction<Promise<Ability[]>> {
    return (dispatch) => {
        dispatch(loadAbilitiesStart());
        const resource = getAbilitiesResource();
        return resource
            .loadAbilities()
            .then((abilities: Ability[]) => {
                dispatch(loadAbilitiesSuccess(abilities));
                dispatch(storeAbilities(abilities));
                return abilities;
            })
            .catch((err: Error) => {
                dispatch(loadAbilitiesFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS
const storeAbilities = abilitiesModule.actionCreators.storeEntities;

// SELECTORS

// does user x have ability y
export const userHasAbilitySelector = createSelector(
    userRolesSelector,
    abilityRoleLinksSelector,
    (userRoles: UserRole[], abilityRoles: AbilityRoleLink[]) => (
        userId?: number,
        abilityId?: number
    ) => {
        // find the one abilityRole that matches the abilityId
        const abilityRole = _.find(abilityRoles, { abilityId });
        if (!abilityRole) {
            return false;
        }
        // find all the user roles that match the userId and the ability roleId
        return _.some(userRoles, {
            userId,
            roleId: abilityRole.roleId,
        });
    }
);

export const abilitiesSelector = abilitiesModule.selectors.entitiesSelector;
export const abilitiesWhereSelector = abilitiesModule.selectors.entitiesWhereSelector;

export const formatAbilityByIdSelector = createSelector(
    abilitiesSelector,
    (abilities) => (ids: number | number[]) =>
        isArray(ids)
            ? joinTruthyValues(
                  _(ids)
                      .map((id) => get(abilities[id], 'name'))
                      .sortBy()
                      .value()
              )
            : get(abilities[ids], 'name')
);

// REDUCER
export default abilitiesModule.reducerConfig;

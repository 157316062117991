import { map } from 'lodash';
import { CurrentViewer, EntityTypeEnumType } from '@mark43/rms-api';

import createLinkModule from '../../../../utils/createLinkModule';
import getCurrentViewersResource from '../../resources/currentViewersResource';
import { ClientCommonAction } from '../../../../../redux/types';

const currentViewersModule = createLinkModule<CurrentViewer>({
    type: 'currentViewers',
    keys: ['userId', 'entityType', 'entityId'],
});

// SELECTORS
export const currentViewersSelector = currentViewersModule.selectors.linksSelector;

// REDUCER
export default currentViewersModule.reducerConfig;

// RESOURCE ACTIONS
const LOAD_CURRENT_VIEWERS_SUCCESS = 'current-viewers/LOAD_CURRENT_VIEWERS_SUCCESS';
const LOAD_CURRENT_VIEWERS_FAILURE = 'current-viewers/LOAD_CURRENT_VIEWERS_FAILURE';

function loadCurrentViewersSuccess() {
    return {
        type: LOAD_CURRENT_VIEWERS_SUCCESS,
    };
}
function loadCurrentViewersFailure(errorMessage: string) {
    return {
        type: LOAD_CURRENT_VIEWERS_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Get and update the current viewers list
 */
export function getCurrentViewersForEntity(
    entityType: EntityTypeEnumType,
    entityId: number
): ClientCommonAction<Promise<CurrentViewer[]>> {
    // no guarantees are made as to param validity here. These are checked in /state/polling.
    const currentViewersResource = getCurrentViewersResource();

    return function (dispatch) {
        return (
            currentViewersResource
                .getAndUpdateCurrentViewers(entityType, entityId)
                .then((currentViewers: CurrentViewer[]) => {
                    const miniUsers = map(currentViewers, 'miniUser');
                    dispatch(
                        currentViewersModule.withEntityItems(
                            { currentViewers, miniUsers },
                            // replace entirely: there might be deletions and additions and
                            // data needn't be kept beyond page's lifetime.
                            currentViewersModule.withRemove(
                                'currentViewers',
                                {
                                    entityType,
                                    entityId,
                                },
                                loadCurrentViewersSuccess()
                            )
                        )
                    );

                    return currentViewers;
                })
                // fail silently -- error not used
                .catch((err: Error) => {
                    dispatch(loadCurrentViewersFailure(err.message));
                })
        );
    };
}

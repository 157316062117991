import { Image, MiniUserView } from '@mark43/rms-api';
import { mapValues } from 'lodash';
import { createSelector } from 'reselect';
import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';

import { imagesSelector } from '../data';

type ImageViewModelProperties = {
    createdBy: MiniUserView;
    updatedBy: MiniUserView;
};

export const imageViewModelsSelector = createSelector(
    imagesSelector,
    allMiniUserFormatsByIdSelector,
    formatAttributeByIdSelector,
    (images, allMiniUserFormatsById, formatAttributeById) => {
        const viewModel = buildViewModel<Image, ImageViewModelProperties>({
            recursive: true,
            mappers: [allSingleAttributeValuesMapper, buildAllMiniUserFormatsMapper()],
            helpers: {
                formatAttributeById,
                allMiniUserFormatsById,
            },
        });
        return mapValues(images, (image) => viewModel(image));
    }
);

import { MetaInstanceView } from '@mark43/rms-api';
import { DragonInlineFormReferenceState } from '../rms-types';

type GetInstanceIdOptions = { mode: 'CORE' | 'DRAGON' | 'EITHER' };

function getInstanceItFromMeta(
    meta: MetaInstanceView,
    options: GetInstanceIdOptions
): number | undefined {
    return options.mode === 'CORE'
        ? meta.coreModelInstanceId
        : options.mode === 'DRAGON'
        ? meta.dragonInstanceId
        : meta.coreModelInstanceId ?? meta.dragonInstanceId;
}

function isMetaInstanceView(value: unknown): value is MetaInstanceView {
    return typeof value === 'object' && !!value && 'configuredEntityPropertyValues' in value;
}

function assertMetaInstanceView(value: unknown): asserts value is MetaInstanceView {
    if (!isMetaInstanceView(value)) {
        throw new Error(
            'Unexpectedly did not find `MetaInstanceView` on `__meta` property for inline form reference state'
        );
    }
}

export function getMetaInstanceView(state: DragonInlineFormReferenceState): MetaInstanceView {
    const metaInstanceView =
        '__meta' in state ? (state.__meta as MetaInstanceView) : Object.values(state)[0]?.__meta;
    assertMetaInstanceView(metaInstanceView);
    return metaInstanceView;
}

function getInstanceId(
    state: DragonInlineFormReferenceState,
    options: GetInstanceIdOptions
): number {
    const meta = getMetaInstanceView(state);
    const id = Number(getInstanceItFromMeta(meta, options));
    if (!id || isNaN(id)) {
        throw new Error(
            `Unexpectedly did not find valid ${
                options.mode === 'CORE'
                    ? 'core model'
                    : options.mode === 'DRAGON'
                    ? 'dragon'
                    : 'core model or dragon'
            } instance id in provided inline form reference state`
        );
    }
    return id;
}

export function getCoreModelInstanceId(state: DragonInlineFormReferenceState): number {
    return getInstanceId(state, { mode: 'CORE' });
}

export function getCoreModelOrDragonInstanceId(state: DragonInlineFormReferenceState): number {
    return getInstanceId(state, { mode: 'EITHER' });
}

import React, { useCallback, useContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { EntityTypeEnum } from '@mark43/rms-api';
import { parseInt } from 'lodash';
import { withRouter } from 'react-router';

import { isPlural, plural } from 'pluralize';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import testIds from '../../../../core/testIds';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import {
    currentCaseNotesByFolderIdSelector,
    currentCaseReportReportLinksSelector,
    currentCaseReportExternalLinksSelector,
    canCurrentUserEditCurrentCaseSelector,
    folderTreeExpansionSelector,
    toggleFolderExpansion,
    currentCaseAttachmentsSelector,
} from '../state/ui';
import { currentCaseLinkedProfilesCountSelector } from '../../linked-profiles/state/ui';
import { currentCaseTaskViewModelsSelector } from '../../task-list/state/ui';
import { routeNameSelector } from '../../../../routing/routerModule';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { outstandingCaseTasksCountSelector } from '../../../tasks/cases/state/ui';
import { getTopLevelFolders } from '../state/data/folders';
import { updateOrphanContents } from '../state/data/orphanedContents';
import { getCaseFolderPath } from '../utils/getCaseFolderPath';
import { SearchFilterContext } from '../contexts';
import { usePhotoLineupFieldName } from '../../case-photo-lineups/hooks/usePhotoLineupFieldName';

import { useLineupContext } from '../../case-photo-lineups/state/ui';
import { tasksDashboardSearch } from '../../../tasks/dashboard/state/ui';
import { useEFileDisplayName, useEFilesRelatedToEntity } from '../../../e-files/hooks';
import CreateFolderModal from './CreateFolderModal';
import DeleteCaseFoldersAttachmentsAndCaseNotesModal from './DeleteCaseFoldersAttachmentsAndCaseNotesModal';
import UpdateFolderModal from './UpdateFolderModal';
import CaseSidebarLink, { LegacyCaseSidebarLink } from './CaseSidebarLink';
import FolderNameWrapper from './FolderNameWrapper';
import FolderTreeContainer from './FolderTreeContainer';
import FolderOverlayButton from './FolderOverlayButton';
import SidebarControlsWrap from './SidebarControlsWrap';

const strings = componentStrings.cases.core.CaseSidebar;
const TOP_LEVEL_FOLDER_DEPTH = 1;

/**
 * The case sidebar appears at the left side of a case page.
 */
function CaseSidebar({
    caseId,
    linkedProfilesCount,
    currentCaseNotesByFolderId,
    reportReportLinks,
    reportExternalLinks,
    totalOutstandingCaseTasks,
    caseSidebarRef,
    folderTreeExpansion,
    taskEnhancementsNumberOfResults,
    router,
}) {
    const showAttachmentFolders = folderTreeExpansion[EntityTypeEnum.ATTACHMENT.name].expanded;
    const showNotesFolders = folderTreeExpansion[EntityTypeEnum.CASE_NOTE.name].expanded;
    const canCurrentUserEditCurrentCase = useSelector(canCurrentUserEditCurrentCaseSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const { pluralEFileDisplayName: eFilesDisplayName } = useEFileDisplayName();

    const lineupFieldName = usePhotoLineupFieldName();
    const pluralLineupName = isPlural(lineupFieldName) ? lineupFieldName : plural(lineupFieldName);

    const allAttachments = useSelector(currentCaseAttachmentsSelector);
    const caseNotes = currentCaseNotesByFolderId();

    const { setFilter, setCurrentPage } = useContext(SearchFilterContext);
    const { numPhotoLineups } = useLineupContext();

    const dispatch = useDispatch();

    const handleClick = useCallback(
        (caseId, entityType) => {
            if (!showAttachmentFolders || !showNotesFolders) {
                dispatch(getTopLevelFolders(caseId, entityType));
            }

            dispatch(toggleFolderExpansion(entityType));
        },
        [showAttachmentFolders, showNotesFolders, dispatch]
    );

    const attachmentPath = getCaseFolderPath({
        caseId,
        entityType: EntityTypeEnum.ATTACHMENT.name,
    });
    const notesPath = getCaseFolderPath({ caseId, entityType: EntityTypeEnum.CASE_NOTE.name });

    const loadOrphanContents = useCallback(() => {
        if (applicationSettings.RMS_CASE_FOLDERING_ENABLED) {
            dispatch(updateOrphanContents());
        }
    }, [applicationSettings, dispatch]);

    const modifiedNumberOfCaseTasks = applicationSettings.RMS_TASK_ENHANCEMENTS_ENABLED
        ? taskEnhancementsNumberOfResults
        : totalOutstandingCaseTasks;

    const { eFileTitles } = useEFilesRelatedToEntity({
        entityId: caseId,
        entityType: EntityTypeEnum.CASE.name,
    });

    return (
        <div ref={caseSidebarRef} className="case-sidebar" data-test-id={testIds.CASE_SIDEBAR}>
            <FeatureFlagged
                flag="RMS_CASE_FOLDERING_ENABLED"
                fallback={
                    <LegacyCaseSidebarLink
                        to={`/cases/${caseId}/summary`}
                        testId={testIds.CASE_SIDEBAR_SUMMARY}
                    >
                        {strings.summary(caseDisplayName)}
                    </LegacyCaseSidebarLink>
                }
            >
                <FolderNameWrapper
                    folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                    isPathActive={router.isActive(`/cases/${caseId}/summary`, true)}
                >
                    <CaseSidebarLink
                        to={`/cases/${caseId}/summary`}
                        testId={testIds.caseDisplayName}
                    >
                        {strings.summary(caseDisplayName)}
                    </CaseSidebarLink>
                </FolderNameWrapper>
            </FeatureFlagged>
            <FeatureFlagged
                flag="RMS_CASE_FOLDERING_ENABLED"
                fallback={
                    <LegacyCaseSidebarLink
                        to={attachmentPath}
                        testId={testIds.CASE_SIDEBAR_ATTACHMENTS}
                    >
                        {strings.attachments(allAttachments.length)}
                    </LegacyCaseSidebarLink>
                }
            >
                <>
                    <FolderNameWrapper
                        folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                        isPathActive={router.isActive(attachmentPath, true)}
                    >
                        <div
                            onClick={() => handleClick(caseId, EntityTypeEnum.ATTACHMENT.name)}
                            data-test-id={testIds.CASE_SIDEBAR_ATTACHMENTS_EXPAND}
                        >
                            <Icon
                                color="cobaltBlue"
                                size={12}
                                type={showAttachmentFolders ? iconTypes.OPEN : iconTypes.OPEN_RIGHT}
                            />
                        </div>
                        <CaseSidebarLink
                            to={attachmentPath}
                            activeClassName={''}
                            onClick={() => {
                                if (setFilter) {
                                    setFilter('');
                                }
                                if (setCurrentPage) {
                                    setCurrentPage(1);
                                }

                                if (!router.isActive(attachmentPath, true)) {
                                    loadOrphanContents();
                                }
                            }}
                            testId={testIds.CASE_SIDEBAR_ATTACHMENTS}
                        >
                            {strings.attachments(allAttachments.length)}
                        </CaseSidebarLink>
                        <SidebarControlsWrap>
                            {canCurrentUserEditCurrentCase && (
                                <FolderOverlayButton
                                    overlayId={overlayIdEnum.CREATE_FOLDER_MODAL}
                                    folderId={NaN}
                                    entityTypeId={EntityTypeEnum.ATTACHMENT.name}
                                    testId={testIds.CASE_SIDEBAR_ATTACHMENTS_CREATE_FOLDER}
                                >
                                    <Icon color="cobaltBlue" size={16} type={iconTypes.ADD} />
                                </FolderOverlayButton>
                            )}
                        </SidebarControlsWrap>
                    </FolderNameWrapper>
                    {showAttachmentFolders && (
                        <FolderTreeContainer
                            ownerId={parseInt(caseId)}
                            entityType={EntityTypeEnum.ATTACHMENT.name}
                            canEditCaseFolders={canCurrentUserEditCurrentCase}
                        />
                    )}
                </>
            </FeatureFlagged>
            <FeatureFlagged
                flag="RMS_CASE_FOLDERING_ENABLED"
                fallback={
                    <LegacyCaseSidebarLink
                        to={`/cases/${caseId}/linked-profiles`}
                        testId={testIds.CASE_SIDEBAR_PROFILES}
                    >
                        {`${strings.linkedProfiles} (${linkedProfilesCount})`}
                    </LegacyCaseSidebarLink>
                }
            >
                <FolderNameWrapper
                    folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                    isPathActive={router.isActive(`/cases/${caseId}/linked-profiles`, true)}
                >
                    <CaseSidebarLink
                        to={`/cases/${caseId}/linked-profiles`}
                        testId={testIds.CASE_SIDEBAR_PROFILES}
                    >
                        {`${strings.linkedProfiles} (${linkedProfilesCount})`}
                    </CaseSidebarLink>
                </FolderNameWrapper>
            </FeatureFlagged>

            <OnlyWithAbility has={abilitiesEnum.CASES.VIEW_NOTES}>
                <FeatureFlagged
                    flag="RMS_CASE_FOLDERING_ENABLED"
                    fallback={
                        <LegacyCaseSidebarLink to={notesPath} testId={testIds.CASE_SIDEBAR_NOTES}>
                            {strings.notes(caseNotes.length)}
                        </LegacyCaseSidebarLink>
                    }
                >
                    <>
                        <FolderNameWrapper
                            folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                            isPathActive={router.isActive(notesPath) && !router.params.folderId}
                        >
                            <div
                                onClick={() => handleClick(caseId, EntityTypeEnum.CASE_NOTE.name)}
                                data-test-id={testIds.CASE_SIDEBAR_NOTES_EXPAND}
                            >
                                <Icon
                                    color="cobaltBlue"
                                    size={12}
                                    type={showNotesFolders ? iconTypes.OPEN : iconTypes.OPEN_RIGHT}
                                />
                            </div>
                            <CaseSidebarLink
                                to={notesPath}
                                activeClassName={''}
                                onClick={() => {
                                    if (!router.isActive(notesPath, true)) {
                                        loadOrphanContents();
                                    }
                                }}
                                testId={testIds.CASE_SIDEBAR_NOTES}
                            >
                                {strings.notes(caseNotes.length)}
                            </CaseSidebarLink>
                            <SidebarControlsWrap>
                                {canCurrentUserEditCurrentCase && (
                                    <FolderOverlayButton
                                        overlayId={overlayIdEnum.CREATE_FOLDER_MODAL}
                                        folderId={NaN}
                                        entityTypeId={EntityTypeEnum.CASE_NOTE.name}
                                        testId={testIds.CASE_SIDEBAR_NOTES_CREATE_FOLDER}
                                    >
                                        <Icon color="cobaltBlue" size={16} type={iconTypes.ADD} />
                                    </FolderOverlayButton>
                                )}
                            </SidebarControlsWrap>
                        </FolderNameWrapper>
                        {showNotesFolders && (
                            <FolderTreeContainer
                                ownerId={parseInt(caseId)}
                                entityType={EntityTypeEnum.CASE_NOTE.name}
                                canEditCaseFolders={canCurrentUserEditCurrentCase}
                            />
                        )}
                    </>
                    <CreateFolderModal />
                    <DeleteCaseFoldersAttachmentsAndCaseNotesModal />
                    <UpdateFolderModal />
                </FeatureFlagged>
            </OnlyWithAbility>

            <OnlyWithAbility has={abilitiesEnum.CASES.VIEW_CASE_TASKS}>
                <FeatureFlagged
                    flag="RMS_CASE_FOLDERING_ENABLED"
                    fallback={
                        <LegacyCaseSidebarLink
                            to={`/cases/${caseId}/task-list`}
                            testId={testIds.CASE_SIDEBAR_TASKS}
                        >
                            {`${strings.taskList} (${modifiedNumberOfCaseTasks})`}
                        </LegacyCaseSidebarLink>
                    }
                >
                    <FolderNameWrapper
                        folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                        isPathActive={router.isActive(`/cases/${caseId}/task-list`, true)}
                    >
                        <CaseSidebarLink
                            to={`/cases/${caseId}/task-list`}
                            testId={testIds.CASE_SIDEBAR_TASKS}
                        >
                            {`${strings.taskList} (${modifiedNumberOfCaseTasks})`}
                        </CaseSidebarLink>
                    </FolderNameWrapper>
                </FeatureFlagged>
            </OnlyWithAbility>
            <FeatureFlagged
                flag="RMS_CASE_FOLDERING_ENABLED"
                fallback={
                    <LegacyCaseSidebarLink
                        to={`/cases/${caseId}/associated-records`}
                        testId={testIds.CASE_SIDEBAR_ASSOCIATED_RECORDS}
                    >
                        {`Associated Records (${
                            reportExternalLinks.length + reportReportLinks.length
                        })`}
                    </LegacyCaseSidebarLink>
                }
            >
                <FolderNameWrapper
                    folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                    isPathActive={router.isActive(`/cases/${caseId}/associated-records`, true)}
                >
                    <CaseSidebarLink
                        to={`/cases/${caseId}/associated-records`}
                        testId={testIds.CASE_SIDEBAR_ASSOCIATED_RECORDS}
                    >
                        {`Associated Records (${
                            reportExternalLinks.length + reportReportLinks.length
                        })`}
                    </CaseSidebarLink>
                </FolderNameWrapper>
            </FeatureFlagged>

            <FeatureFlagged flag="RMS_E_FILING_ENABLED">
                <OnlyWithAbility has={abilitiesEnum.EFILES.VIEW}>
                    <FeatureFlagged
                        flag="RMS_CASE_FOLDERING_ENABLED"
                        fallback={
                            <LegacyCaseSidebarLink
                                to={`/cases/${caseId}/e-files`}
                                testId={testIds.CASE_SIDEBAR_E_FILES}
                            >
                                {strings.eFiles(eFilesDisplayName, eFileTitles.length)}
                            </LegacyCaseSidebarLink>
                        }
                    >
                        <FolderNameWrapper
                            folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                            isPathActive={router.isActive(`/cases/${caseId}/e-files`, true)}
                        >
                            <CaseSidebarLink
                                to={`/cases/${caseId}/e-files`}
                                testId={testIds.CASE_SIDEBAR_E_FILES}
                            >
                                {strings.eFiles(eFilesDisplayName, eFileTitles.length)}
                            </CaseSidebarLink>
                        </FolderNameWrapper>
                    </FeatureFlagged>
                </OnlyWithAbility>
            </FeatureFlagged>

            <FeatureFlagged flag="RMS_PHOTO_LINEUPS_ENABLED">
                <FeatureFlagged
                    flag="RMS_CASE_FOLDERING_ENABLED"
                    fallback={
                        <LegacyCaseSidebarLink
                            to={`/cases/${caseId}/lineups`}
                            testId={testIds.CASE_SIDEBAR_PHOTO_LINEUP}
                        >
                            {strings.photoLineup(pluralLineupName, numPhotoLineups)}
                        </LegacyCaseSidebarLink>
                    }
                >
                    <FolderNameWrapper
                        folderDepth={TOP_LEVEL_FOLDER_DEPTH}
                        isPathActive={router.isActive(`/cases/${caseId}/lineups`, true)}
                    >
                        <CaseSidebarLink
                            to={`/cases/${caseId}/lineups`}
                            testId={testIds.CASE_SIDEBAR_PHOTO_LINEUP}
                        >
                            {strings.photoLineup(pluralLineupName, numPhotoLineups)}
                        </CaseSidebarLink>
                    </FolderNameWrapper>
                </FeatureFlagged>
            </FeatureFlagged>
        </div>
    );
}

export default compose(
    withRouter,
    connect(
        createStructuredSelector({
            currentCaseNotesByFolderId: currentCaseNotesByFolderIdSelector,
            linkedProfilesCount: currentCaseLinkedProfilesCountSelector,
            caseTasks: currentCaseTaskViewModelsSelector,
            reportReportLinks: currentCaseReportReportLinksSelector,
            reportExternalLinks: currentCaseReportExternalLinksSelector,
            outstandingCaseTasks: outstandingCaseTasksCountSelector,
            applicationSettings: applicationSettingsSelector,
            folderTreeExpansion: folderTreeExpansionSelector,
            taskEnhancementsNumberOfResults: tasksDashboardSearch.selectors.totalCountSelector,

            // routerName is connected so that this component updates when route changes,
            // and as a result the correct Link is displayed as active - this is a
            // necessary hack for now but the underlying bug will be fixed when we
            // upgrade react router
            routerName: routeNameSelector,
        })
    ),
    withPropsOnChange(
        ['applicationSettings', 'outstandingCaseTasks', 'caseTasks'],
        ({ applicationSettings, outstandingCaseTasks, caseTasks }) => {
            const isTaskAndRequestTrackingEnabled =
                applicationSettings.RMS_TASK_AND_REQUEST_TRACKING_ENABLED;

            return {
                totalOutstandingCaseTasks: isTaskAndRequestTrackingEnabled
                    ? outstandingCaseTasks.length
                    : caseTasks.filter((task) => getViewModelProperties(task).isPending).length,
            };
        }
    )
)(CaseSidebar);

import { RuleActionedField } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'ruleActionedFields';

const ruleActionedFieldsModule = createLinkModule<RuleActionedField>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// SELECTORS
export const ruleActionedFieldsSelector = ruleActionedFieldsModule.selectors.linksSelector;
export const ruleActionedFieldsWhereSelector =
    ruleActionedFieldsModule.selectors.linksWhereSelector;

// REDUCER
export default ruleActionedFieldsModule.reducerConfig;

import styled from 'styled-components';
import Button from '../../../../legacy-redux/components/core/Button';

// need both max-width and min-width because
// for some reason just max-width doesn't word-wrap correctly
export const AttachmentCell = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 5px 10px;
    max-width: ${({ width }: { width: string }) => width};
    min-width: ${({ width }: { width: string }) => width};
`;

export const UploadDateText = styled.span`
    font-style: italic;
    color: ${(props) => props.theme.colors.lowContrastGrey};
    font-size: var(--arc-fontSizes-sm);
`;

export const DownloadButton = styled(Button)`
    .textvalignmiddle {
        display: none;
    }
`;

interface AttachmentRowProps {
    isRowSelected: boolean;
    isGhosting?: boolean;
    isDraggingOver?: boolean;
}

export const AttachmentRow = styled.div<AttachmentRowProps>`
    font-size: var(--arc-fontSizes-sm);
    background: ${({ isRowSelected, isGhosting, isDraggingOver, theme }) => {
        if (isGhosting) {
            return theme.colors.extraLightGrey;
        }
        if (isRowSelected) {
            return theme.colors.lightBlue;
        }
        if (isDraggingOver) {
            return theme.colors.lightBlue;
        }

        return theme.colors.white;
    }};

    &,
    * {
        box-sizing: border-box;
        word-break: break-word;
    }
`;

export const NameCellWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const NameWrapper = styled.div`
    margin-left: 10px;
`;

import React, { useState } from 'react';
import { findIndex, get, isArray, isFunction, map } from 'lodash';

import { ElasticCobaltSortTypeEnumType } from '@mark43/rms-api';

import PopoutMenu from '../../../../core/components/PopoutMenu';
import RelatedRecordsSortHeader from './RelatedRecordsSortHeader';

type SortOption = { display: string; sortType: ElasticCobaltSortTypeEnumType };

type SortOptionProps = {
    display: string;
    value: string;
    columnKey: string;
    sortOptions: SortOption[];
};

type SortOptionsProps = {
    // The text which will appear in the sort header.
    display: string;
    // Which one (if any) of the options offered by this sort header is active.
    activeValue: string;
    // The active sort type of results the parent header component represents.
    activeSortType: ElasticCobaltSortTypeEnumType;
    // Callback called whenever an option representing a member of props.children is clicked.
    onOptionClick: ({ value, sortOptions }: { value: string; sortOptions: SortOption[] }) => void;
    // Callback called whenever a child PopoutSortOption is clicked.
    onSortTypeClick: (sortType: ElasticCobaltSortTypeEnumType, props?: SortOptionProps) => void;
    // An additional complimentary CSS class that RelatedRecordsSortHeader can inherit
    className?: string;
};

/**
 * `children` is an array of sort instances, each of which will be represented as an option in a dropdown that opens if
 * the sort header is clicked.
 */
const SortOptions: React.FC<SortOptionsProps> = ({
    children,
    display,
    activeValue,
    activeSortType,
    onOptionClick,
    onSortTypeClick,
    className,
}) => {
    const options = (isArray(children)
        ? React.Children.toArray(children)
        : [children]) as React.ReactElement<SortOptionProps>[];
    const activeOptionIndex = findIndex(options, ({ props: { value } }) => activeValue === value);
    const activeOption = options[activeOptionIndex];
    const sortDisplay = isFunction(display)
        ? display(get(activeOption, 'props'), options)
        : display;
    const [isOpen, setIsOpen] = useState(false);
    return (
        <RelatedRecordsSortHeader
            className={className}
            sortType={activeSortType}
            display={sortDisplay}
            onClick={() => setIsOpen((oldVal) => !oldVal)}
        >
            {isOpen ? (
                // @ts-expect-error TODO is to type the PopoutMenu component
                <PopoutMenu
                    onClickAway={() => setIsOpen(false)}
                    left="unset"
                    right="5px"
                    marginBottom="15px"
                    options={options}
                    onOptionClick={onOptionClick}
                    sortOptions={
                        activeOption && activeOption.props.sortOptions
                            ? map(activeOption.props.sortOptions, ({ display, sortType }) => ({
                                  display,
                                  sortType,
                                  isActive:
                                      activeSortType === sortType &&
                                      activeValue === activeOption.props.value,
                                  handleClick: () =>
                                      onSortTypeClick(
                                          sortType,
                                          activeOption ? activeOption.props : undefined
                                      ),
                              }))
                            : undefined
                    }
                    activeOptionIndex={activeOptionIndex}
                />
            ) : undefined}
        </RelatedRecordsSortHeader>
    );
};

// this component is a noop
export const SortOption: React.FC<SortOptionProps> = () => null;

export default SortOptions;

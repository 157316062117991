import React from 'react';

import ConnectedFormattedUser from '~/client-common/core/users/components/ConnectedFormattedUser';
import FormattedUser from '~/client-common/core/users/components/FormattedUser';

export default function NotificationAlertCreator({
    alertCreatorFullNameWithBadge,
    alertCreatorId,
    alertCreatorUserProfile,
}) {
    if (alertCreatorFullNameWithBadge) {
        return alertCreatorFullNameWithBadge;
    }

    // legacy alerts - user profile
    if (alertCreatorUserProfile) {
        return (
            <FormattedUser
                format={FormattedUser.FORMATS.FULL_NAME}
                user={alertCreatorUserProfile}
            />
        );
    }

    // legacy alerts - user id with mini-users from bootstrap
    return (
        <ConnectedFormattedUser format={FormattedUser.FORMATS.FULL_NAME} userId={alertCreatorId} />
    );
}

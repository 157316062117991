import { CaseDefaultTaskView } from '@mark43/rms-api';
import { filter } from 'lodash';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'caseDefaultTasks';

const caseDefaultTaskModule = createNormalizedModule<CaseDefaultTaskView>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeCaseDefaultTasks = caseDefaultTaskModule.actionCreators.storeEntities;
export const replaceCaseDefaultTasksWhere =
    caseDefaultTaskModule.actionCreators.replaceEntitiesWhere;

// SELECTORS
export const caseDefaultTasksByOwnerIdSelector = createSelector(
    caseDefaultTaskModule.selectors.entitiesSelector,
    (caseDefaultTasks) => (ownerId: number) => filter(caseDefaultTasks, { ownerId })
);

// REDUCER
export default caseDefaultTaskModule.reducerConfig;

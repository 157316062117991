import PropTypes from 'prop-types';
import React from 'react';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import ResultsTableColumn, {
    ResultsSortableTableColumn,
} from '../../search-results/components/ResultsTableColumn';

const strings = componentStrings.evidence.inventories.SearchResultsTableHeader.columns;

const SearchResultsTableHeader = ({ handleSortKeyClick, querySortType }) => (
    <TableColumns>
        <ResultsSortableTableColumn
            columnKey="completedDateUtc"
            display={<span>Completion Date</span>}
            onClick={handleSortKeyClick}
            sortKey={sqlSortKeyEnum.INVENTORY_COMPLETED_DATE_UTC}
            sortOptions={[
                sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
            ]}
            sortType={querySortType}
            width={160}
        />

        <ResultsTableColumn
            columnKey="storageLocationName"
            display={strings.storageLocation.label}
            width={210}
        />

        <ResultsTableColumn
            columnKey="ownerUserId"
            display={strings.responsiblePersonnel.label}
            width={210}
        />

        <ResultsTableColumn
            columnKey="inventoryType"
            display={strings.inventoryType.label}
            width={130}
        />

        <ResultsSortableTableColumn
            columnKey="missingItemsCount"
            display={<span>Missing Items</span>}
            onClick={handleSortKeyClick}
            sortKey={sqlSortKeyEnum.INVENTORY_MISSING_ITEMS_COUNT}
            sortOptions={[
                sortTypeEnum.NUMBER_HIGHEST_TO_LOWEST,
                sortTypeEnum.NUMBER_LOWEST_TO_HIGHEST,
            ]}
            sortType={querySortType}
            width={130}
        />
    </TableColumns>
);

SearchResultsTableHeader.propTypes = {
    formatFieldByName: PropTypes.func.isRequired,
    handleSortTypeClick: PropTypes.func.isRequired,
    querySortKey: PropTypes.string.isRequired,
    querySortType: PropTypes.string.isRequired,
};

SearchResultsTableHeader.defaultProps = {
    formatFieldByName: () => {},
    handleSortKeyClick: () => {},
    querySortType: '',
};

export default SearchResultsTableHeader;

import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import pluralize from 'pluralize';

import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ConnectedFormattedAttribute from '~/client-common/core/domain/attributes/components/ConnectedFormattedAttribute';
import { FormattedDate } from '~/client-common/core/dates/components';
import {
    warrantChargesWhereSelector,
    deleteWarrantCharge,
} from '~/client-common/core/domain/warrant-charges/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { WARRANT_CHARGE_CHARGE_NAME } from '~/client-common/core/enums/universal/fields';
import withCard from '../../../reports/core/utils/withCard';
import warrantChargesCard from '../state/cards/warrantChargesCard';
import testIds from '../../../../core/testIds';
import Card, { CardSection } from '../../../../legacy-redux/components/core/Card';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { openWarrantChargesSidePanel } from '../state/ui';
import RMSTableComposite from '../../../core/components/RMSTableComposite';
import NoDataBlock from '../../../core/components/NoDataBlock';
import ActionIcons from '../../../core/components/ActionIcons';
import { iconSizes } from '../../../core/components/Icon';

const strings = componentStrings.warrants.warrant.WarrantChargesCard;
const NUM_CHARGES_PER_PAGE = 15;

const CenteredCardSection = styled(CardSection)`
    text-align: center;
    padding: 0 10px;
`;

const ActionIconsWrapper = styled.div`
    padding: 5px 0;
`;

const ChargeCardSection = styled(CardSection)`
    padding: 18px 12px;
`;

const AddChargesButton = styled(Button)`
    margin-top: -5px;
`;

const Top = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Footer = styled.div`
    float: right;
    margin: 10px 0;
`;

function WarrantChargesCard(props) {
    const {
        entityId,
        card,
        canEdit,
        onEdit,
        onSaveProgress,
        openWarrantChargesSidePanel,
        warrantChargesWhere,
        deleteWarrantCharge,
    } = props;
    const warrantCharges = warrantChargesWhere({ warrantId: entityId });
    const chargeLabel = useFields(WARRANT_CHARGE_CHARGE_NAME)[WARRANT_CHARGE_CHARGE_NAME];
    return (
        <>
            <Card
                testId={testIds.WARRANT_CHARGES_CARD}
                className={card.anchor}
                anchor={card.anchor}
                title={pluralize(chargeLabel)}
                errors={card.errorMessages}
                onEdit={onEdit}
                summaryMode={card.summaryMode}
                canEdit={canEdit}
                saving={card.saving}
                onSave={onSaveProgress}
                renderContent={(summaryMode) => {
                    const columns = [
                        {
                            key: 'chargeCount',
                            width: '17%',
                            fieldName: fields.WARRANT_CHARGE_CHARGE_COUNT,
                        },
                        {
                            key: 'chargeName',
                            width: '33%',
                            fieldName: fields.WARRANT_CHARGE_CHARGE_NAME,
                        },
                        {
                            key: 'offenseClassificationAttrId',
                            width: '22%',
                            fieldName: fields.WARRANT_CHARGE_OFFENSE_CLASSIFICATION_ATTR_ID,
                            renderCellContent: ({ item }) => {
                                return (
                                    <ConnectedFormattedAttribute
                                        attributeId={item.offenseClassificationAttrId}
                                    />
                                );
                            },
                        },
                        {
                            key: 'offenseDateUtc',
                            width: '18%',
                            title: 'Date',
                            renderCellContent: ({ item }) => {
                                return (
                                    <FormattedDate
                                        date={item.offenseDateUtc}
                                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                                    />
                                );
                            },
                        },
                        {
                            key: 'icons',
                            width: '10%',
                            renderCellContent: ({ item }) => {
                                return summaryMode ? null : (
                                    <ActionIconsWrapper>
                                        <ActionIcons
                                            hideIconLabels={true}
                                            hideDuplicate={true}
                                            iconSize={iconSizes.SMALL}
                                            onEdit={() =>
                                                openWarrantChargesSidePanel({
                                                    warrantCharges: [item],
                                                    createNew: false,
                                                })
                                            }
                                            onRemove={() => deleteWarrantCharge(item.id)}
                                        />
                                    </ActionIconsWrapper>
                                );
                            },
                            filterable: false,
                            sortable: false,
                        },
                    ];

                    return (
                        <ChargeCardSection>
                            {warrantCharges.length > 0 ? (
                                <RMSTableComposite
                                    items={warrantCharges}
                                    columns={columns}
                                    paginationOptions={{ pageSize: NUM_CHARGES_PER_PAGE }}
                                    includeFilter={false}
                                    renderLayout={({
                                        tableComponent,
                                        paginationComponent,
                                        paginationSummaryComponent,
                                    }) => {
                                        return (
                                            <>
                                                <Top>
                                                    <div>
                                                        {!summaryMode && (
                                                            <AddChargesButton
                                                                className={buttonTypes.SECONDARY}
                                                                iconLeft={
                                                                    <Icon type={iconTypes.ADD} />
                                                                }
                                                                onClick={() =>
                                                                    openWarrantChargesSidePanel({
                                                                        warrantCharges: [],
                                                                        createNew: true,
                                                                    })
                                                                }
                                                            >
                                                                {pluralize(chargeLabel)}
                                                            </AddChargesButton>
                                                        )}
                                                    </div>
                                                    {paginationSummaryComponent}
                                                </Top>
                                                {tableComponent}
                                                {warrantCharges.length > NUM_CHARGES_PER_PAGE && (
                                                    <Footer>{paginationComponent}</Footer>
                                                )}
                                            </>
                                        );
                                    }}
                                />
                            ) : (
                                <>
                                    {!summaryMode ? (
                                        <AddChargesButton
                                            className={buttonTypes.SECONDARY}
                                            iconLeft={<Icon type={iconTypes.ADD} />}
                                            onClick={() =>
                                                openWarrantChargesSidePanel({
                                                    warrantCharges: [],
                                                    createNew: true,
                                                })
                                            }
                                        >
                                            {pluralize(chargeLabel)}
                                        </AddChargesButton>
                                    ) : (
                                        <CenteredCardSection>
                                            <NoDataBlock>
                                                {strings.noCharges(chargeLabel)}
                                            </NoDataBlock>
                                        </CenteredCardSection>
                                    )}
                                </>
                            )}
                        </ChargeCardSection>
                    );
                }}
            />
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    warrantChargesWhere: warrantChargesWhereSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
    onSave() {
        dispatch(warrantChargesCard.actionCreators.save());
    },
    onEdit() {
        dispatch(warrantChargesCard.actionCreators.transitionToEditMode());
    },
    openWarrantChargesSidePanel({ warrantCharges, createNew }) {
        dispatch(
            openWarrantChargesSidePanel({ warrantCharges, warrantId: props.entityId, createNew })
        );
    },
    deleteWarrantCharge(warrantChargeId) {
        dispatch(deleteWarrantCharge(warrantChargeId));
    },
});

export default compose(
    withCard(warrantChargesCard),
    connect(mapStateToProps, mapDispatchToProps)
)(WarrantChargesCard);

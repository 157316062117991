import React from 'react';
import { connect } from 'react-redux';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { submitDeleteRoleModal } from '../state/ui';
import ModalBodyText from './ModalBodyText';

const strings = componentStrings.admin.roles.DeleteRole;

const context = {
    name: boxEnum.DELETE_ROLE_CONFIRMATION_MODAL,
};

function DeleteRoleConfirmationModal({ handleSave }) {
    return (
        <Modal context={context} title={strings.title} onSave={handleSave}>
            <ModalBodyText>{strings.body}</ModalBodyText>
        </Modal>
    );
}

export default connect(null, { handleSave: submitDeleteRoleModal })(DeleteRoleConfirmationModal);

import { EntityTypeEnum } from '@mark43/rms-api';
import _, { size } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withState } from 'recompose';
import React from 'react';

import styled from 'styled-components';
import { latestCadTicket } from '~/client-common/core/domain/cad-tickets/utils/cadTicketsHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import { cadTicketsViewModelWhereSelector } from '~/client-common/core/domain/cad-tickets/state/ui';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { reportDefinitionByIdSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { idFormatConfigurationByIdAndEntityTypeSelector } from '~/client-common/core/domain/id-format-configurations/state/data';
import { Button } from '../../../../core/components/Button';
import { CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import LinkToCadEvent from './LinkToCadEvent';

const strings = componentStrings.reports.core.EventInfoCard;

const SemiBoldedText = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const CadTicketCommentWrapper = styled.div`
    padding-top: ${(props) => (props.index > 0 ? '10px' : '0px')};
    overflow-wrap: break-word;
`;

const ShowMoreLessButton = styled(Button)`
    padding: 8px 5px;
    margin: 0 185px;
`;

export const CadTicketSummaryRaw = compose(
    renderOnlyIf(({ cadTicketViewModel }) => !!cadTicketViewModel),
    withState('allCadTicketCommentsVisible', 'showAllCadTicketComments', false)
)(function _CadTicketSummary({
    cadTicketViewModel,
    allCadTicketCommentsVisible,
    showAllCadTicketComments,
}) {
    const {
        eventDate,
        secondaryEventTypeAttrId,
        eventTypeAttrId,
        responderUnitsCommaSeparated,
        responderMembersCommaSeparated,
    } = getViewModelProperties(cadTicketViewModel);
    const { cadAgencyEventId } = cadTicketViewModel;

    return (
        <SummaryList labelWidth={160} contentWidth={380}>
            <SummaryRow fieldName={fields.CAD_TICKET_CAD_AGENCY_EVENT_NUMBER}>
                <LinkToCadEvent
                    cadAgencyEventNumber={cadTicketViewModel.cadAgencyEventNumber}
                    cadAgencyEventId={cadAgencyEventId}
                />
            </SummaryRow>
            <SummaryRow fieldName={fields.DISPLAY_CAD_TICKET_EVENT_START_END}>
                {eventDate}
            </SummaryRow>
            <SummaryRow fieldName={fields.CAD_TICKET_EVENT_TYPE}>{eventTypeAttrId}</SummaryRow>
            <SummaryRow fieldName={fields.CAD_TICKET_SECONDARY_EVENT_TYPE}>
                {secondaryEventTypeAttrId}
            </SummaryRow>
            <SummaryRow fieldName={fields.CAD_TICKET_CALLER_PHONE_NUMBER}>
                {cadTicketViewModel.callerPhoneNumber}
            </SummaryRow>
            <SummaryRow fieldName={fields.DISPLAY_AGENCY_EVENT_PRIMARY_UNITS}>
                {responderUnitsCommaSeparated}
            </SummaryRow>
            <SummaryRow fieldName={fields.DISPLAY_AGENCY_EVENT_PRIMARY_OFFICERS}>
                {responderMembersCommaSeparated}
            </SummaryRow>
            <SummaryRow fieldName={fields.CAD_TICKET_FULL_ADDRESS}>
                {cadTicketViewModel.fullAddress}
            </SummaryRow>
            {size(cadTicketViewModel.comments) > 0 && (
                <div>
                    {allCadTicketCommentsVisible ? (
                        <div>
                            <SummaryRow fieldName={fields.DISPLAY_CAD_TICKET_COMMENTS}>
                                {_(cadTicketViewModel.comments)
                                    .sortBy('authoredDateUtc')
                                    .map((cadTicketComment, index) => (
                                        <CadTicketCommentWrapper key={index} index={index}>
                                            <div>
                                                <SemiBoldedText>
                                                    {cadTicketComment.authorName}
                                                </SemiBoldedText>{' '}
                                                <FormattedDate
                                                    date={cadTicketComment.authoredDateUtc}
                                                    format={FormattedDate.FORMATS.FORM_TIME_SEC}
                                                />
                                            </div>
                                            <div>{cadTicketComment.comment}</div>
                                        </CadTicketCommentWrapper>
                                    ))
                                    .value()}
                            </SummaryRow>
                            <ShowMoreLessButton
                                variant="ghost"
                                size="sm"
                                leftIcon="Close"
                                onClick={() => showAllCadTicketComments(false)}
                            >
                                {strings.cadCommentsViewLess}
                            </ShowMoreLessButton>
                        </div>
                    ) : (
                        <ShowMoreLessButton
                            variant="ghost"
                            size="sm"
                            leftIcon="Open"
                            onClick={() => showAllCadTicketComments(true)}
                        >
                            {strings.cadCommentsViewMore}
                        </ShowMoreLessButton>
                    )}
                </div>
            )}
        </SummaryList>
    );
});

const mapStateToProps = (state, ownProps) => {
    const reportId = ownProps.reportId;
    const report = reportByIdSelector(state)(reportId);
    if (!report) {
        return {
            cadTicketViewModel: undefined,
        };
    }
    const reportDefinition = reportDefinitionByIdSelector(state)(report.reportDefinitionId);
    const idFormatConfiguration = idFormatConfigurationByIdAndEntityTypeSelector(state)(
        reportDefinition.idFormatConfigurationId,
        EntityTypeEnum.CAD_AGENCY_EVENT.name
    );
    const cadAgencyEventNumber = idFormatConfiguration ? report.recordNumber : null;
    const cadTicketViewModels = cadTicketsViewModelWhereSelector(state)(
        report.reportingEventNumber,
        cadAgencyEventNumber
    );
    const cadTicketViewModel = latestCadTicket(cadTicketViewModels);

    return {
        cadTicketViewModel,
    };
};

export default compose(
    connect(mapStateToProps),
    renderOnlyIf(({ cadTicketViewModel }) => !!cadTicketViewModel)
)(function CadTicketSummary({ cadTicketViewModel }) {
    return (
        <CardSubsection title={strings.cadEventInfoSectionTitle}>
            <CadTicketSummaryRaw cadTicketViewModel={cadTicketViewModel} />
        </CardSubsection>
    );
});

import { RefContextEnumType } from '@mark43/rms-api';
import type { MFTFormConfiguration } from 'markformythree';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import formsRegistry from '../../../../core/formsRegistry';

type FormContextName = RefContextEnumType | keyof typeof formClientEnum;

/**
 * Form getter. Do _not_ use this directly, please use `useFormGetter` in React component,
 *   or use formsRegistry in redux middlewares dependencies.
 */
function getForm<
    FormConfiguration extends MFTFormConfiguration = MFTFormConfiguration,
    ContextName extends string = FormContextName
>(contextName: ContextName, index?: number | string) {
    const form = formsRegistry.get<FormConfiguration, ContextName>(contextName, index);

    return form;
}

/**
 * Helper to get forms from the `markformythree` registry
 */
export function useFormGetter() {
    return { getForm };
}

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Warrant Activity Status Links Resource',
        methods: {
            getWarrantActivityStatusLinks() {
                return req({
                    method: 'GET',
                    url: 'warrants/activity_status_links/bundle',
                });
            },
            upsertWarrantActivityStatusLink(warrantActivityStatusLink) {
                return req({
                    method: 'POST',
                    url: 'warrants/activity_status_links',
                    data: warrantActivityStatusLink,
                });
            },
            deleteWarrantActivityStatusLink(activityTypeAttrId) {
                return req({
                    method: 'DELETE',
                    url: `warrants/activity_status_links/${activityTypeAttrId}`,
                });
            },
        },
    });
}

/**
 * Resources for Warrant Activity Status Links. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import invariant from 'invariant';

import Button, { ButtonProps } from '../../../../legacy-redux/components/core/Button';
import { OverlayStoreContext } from '../../../../core/overlayManager';
import { logWarning } from '../../../../core/logging';

type OverlayButtonProps = {
    overlayCustomProperties?: Record<string, unknown>;
    id: string;
} & ButtonProps;

/**
 * Button used to trigger overlay open. Same props as Button component.
 */
const OverlayButton: React.FC<OverlayButtonProps> = (props) => {
    const { id, onClick, overlayCustomProperties, ...buttonProps } = props;

    return (
        // 1. can't use React.useContext because overlay-manager does not export actual
        // React context, instead it is plain object { Provider, Consumer }.
        // 2. using OverlayStoreContext instead of OverlayBase because we can only have
        // one unique OverlayBase per id. This is sufficient for our needs because
        // we only need 'open' api and that is not dependent on any internal state from OverlayBase.
        <OverlayStoreContext.Consumer>
            {(context) => {
                const store = context?.store;
                invariant(
                    store,
                    'Cannot find overlay store. Ensure <OverlayButton/> is rendered under <OverlayStoreContext.Provider/>'
                );

                const openOverlay = () => store.open(id, overlayCustomProperties);

                // escape hatch to manually trigger open or replace Button component.
                if (typeof buttonProps.children === 'function') {
                    return buttonProps.children(openOverlay);
                }

                if (onClick) {
                    logWarning(
                        'Found onClick prop in OverlayButton. Recommend using children render function to handle onClick and overlay open'
                    );
                }

                return <Button {...buttonProps} onClick={openOverlay} />;
            }}
        </OverlayStoreContext.Consumer>
    );
};

export { buttonTypes } from '../../../../legacy-redux/components/core/Button';

export default OverlayButton;

import { SortingState, Updater } from '@tanstack/react-table';
import { DataTable, DataTableBulkAction, ReactTable, DataTablePaginationSummary } from 'arc';
import keyMirror from 'keymirror';
import { keyBy } from 'lodash';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { currentUserHasAbilitySelector } from '../../../../../../core/current-user/state/ui';
import createDataTableSortColumns from '../../../../../../search/core/utils/createDataTableSortColumns';
import { evidenceDashboardSearch } from '../../../../state/ui';

import { ElasticResultsDataTableRow } from '../../../../../../core/components/ElasticResultsDataTableRow';
// Manage Evidence Cell Components
import type { ManageEvidenceDashboardResults, ManageEvidenceViewModel } from '../../../../types';
import EvidenceDashboardDispositionMenu from '../../../EvidenceDashboardDispositionMenu';
import { EvidenceCautionCell } from '../cells/EvidenceCautionCell';
import { EvidenceDescriptionCell } from '../cells/EvidenceDescriptionCell';
import { EvidenceLocationCell } from '../cells/EvidenceLocationCell';
import { EvidenceRenCell } from '../cells/EvidenceRenCell';
import { EvidenceStaffRemarksCell } from '../cells/EvidenceStaffRemarksCell';
import { AddToBasketAction } from './AddToBasketBulkAction';
import { ExportBulkAction } from './ExportBulkAction';
import { NewTaskBulkAction } from './NewTaskBulkAction';
import { PrintLabelsBulkAction } from './PrintLabelsBulkAction';

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;

const DEFAULT_PAGE_SIZE = 25;
const APPROXIMATE_RESULTS_THRESHOLD = 5000;

const columnHelper = ReactTable.createColumnHelper<
    ManageEvidenceViewModel & { title?: string; currentDispositionStatus?: string }
>();

const columnKeys = keyMirror({
    title: null,
    acquiredDateUtc: null,
    location: null,
    reportingEventNumber: null,
    lastSyncedDateUtc: null,
    currentDispositionStatus: null,
    isHighValue: null,
    staffRemarks: null,
});

const evidenceColumns = [
    columnHelper.accessor('title', {
        id: columnKeys.title,
        header: 'Item',
        // Not available in the sortKeyEnum or ElasticCobaltSortKey.java
        enableSorting: false,
        maxSize: 300,
        minSize: 250,

        cell: ({ row }) => {
            return <EvidenceDescriptionCell evidenceItem={row.original} />;
        },
    }),

    columnHelper.accessor('acquiredDateUtc', {
        id: columnKeys.acquiredDateUtc,
        header: 'Acquired',
        enableSorting: true,
        maxSize: 140,
        cell: ({ getValue }) => {
            return (
                <FormattedDate date={getValue()} format={FormattedDate.FORMATS.FORM_DATE_TIME} />
            );
        },
    }),
    columnHelper.display({
        id: columnKeys.location,
        header: 'Location',
        // Not available in the sortKeyEnum or ElasticCobaltSortKey.java
        enableSorting: false,
        enableResizing: false,
        cell: ({ row }) => {
            return <EvidenceLocationCell evidenceItem={row.original} />;
        },
    }),
    columnHelper.accessor('reportingEventNumber', {
        id: columnKeys.reportingEventNumber,
        header: 'Report',
        // Not available in the sortKeyEnum or ElasticCobaltSortKey.java
        enableSorting: false,
        cell: ({ row }) => {
            const { custodialReportId, reportingEventNumber, sequenceNumber } = row.original;
            return (
                <EvidenceRenCell {...{ custodialReportId, reportingEventNumber, sequenceNumber }} />
            );
        },
    }),
    columnHelper.accessor('lastSyncedDateUtc', {
        id: columnKeys.lastSyncedDateUtc,
        header: 'Updated',
        enableSorting: true,
        maxSize: 140,
        cell: ({ getValue }) => {
            return (
                <FormattedDate date={getValue()} format={FormattedDate.FORMATS.FORM_DATE_TIME} />
            );
        },
    }),
    columnHelper.accessor('currentDispositionStatus', {
        id: columnKeys.currentDispositionStatus,
        header: 'Disposition Status',
        // Not available in the sortKeyEnum or ElasticCobaltSortKey.java
        enableSorting: false,
        maxSize: 240,
        cell: ({ row }) => {
            const { currentDispositionStatus } = getViewModelProperties(row.original);
            return currentDispositionStatus;
        },
    }),
    columnHelper.accessor('isHighValue', {
        id: columnKeys.isHighValue,
        header: 'Caution',
        maxSize: 100,
        // Not available in the sortKeyEnum or ElasticCobaltSortKey.java
        enableSorting: false,
        cell: ({ row }) => {
            const { masterItemId, isHighValue, isInHighValueContainer } = row.original;
            return (
                <EvidenceCautionCell {...{ masterItemId, isHighValue, isInHighValueContainer }} />
            );
        },
    }),
    columnHelper.accessor('staffRemarks', {
        id: columnKeys.staffRemarks,
        header: 'Remarks',
        maxSize: 64,
        // Not available in the sortKeyEnum or ElasticCobaltSortKey.java
        enableSorting: false,
        cell: ({ row }) => {
            const { staffRemarks, reportingEventNumber } = row.original;
            return row.original.staffRemarks.length > 0 ? (
                <EvidenceStaffRemarksCell {...{ staffRemarks, reportingEventNumber }} />
            ) : null;
        },
    }),
];

const sorts = [
    {
        id: columnKeys.acquiredDateUtc,
        sortKey: sortKeyEnum.EVIDENCE_ITEM_DATE_ACQUIRED,
        sortDirections: {
            asc: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
            desc: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
        },
    },
    {
        id: columnKeys.lastSyncedDateUtc,
        sortKey: sortKeyEnum.EVIDENCE_ITEM_CHAIN_EVENT_DATE,
        sortDirections: {
            asc: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
            desc: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
        },
    },
];

const columnSorts = createDataTableSortColumns<ManageEvidenceViewModel>()({
    columns: keyBy(sorts, 'id'),
});

const StyledEvidenceDashboardDispositionMenu = styled(EvidenceDashboardDispositionMenu)`
    margin-right: 0;
`;

const MANAGE_DASHBOARD_BULK_ACTIONS: DataTableBulkAction<ManageEvidenceDashboardResults>[] = [
    {
        type: 'custom',
        render: () => <AddToBasketAction />,
    },
    {
        type: 'custom',
        render: () => <PrintLabelsBulkAction />,
    },
    {
        type: 'custom',
        render: () => <NewTaskBulkAction />,
    },
    {
        type: 'custom',
        render: (selectedResults) => {
            if (typeof selectedResults === 'string') {
                return null;
            }
            return (
                <StyledEvidenceDashboardDispositionMenu
                    masterItemIds={selectedResults.map(
                        (selectedResult) => selectedResult.masterItemId
                    )}
                />
            );
        },
    },
    {
        type: 'custom',
        render: () => <ExportBulkAction />,
    },
];

const BaseManageEvidenceDashboardDataTable: React.FC<RouteComponentProps<never, never>> = ({
    router,
}) => {
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const staffRemarksAbility = currentUserHasAbility(abilitiesEnum.EVIDENCE.VIEW_STAFF_REMARKS);
    const showHighRiskLabelColumn = useSelector(
        applicationSettingsSelector
    ).EVIDENCE_AUTO_LOCATION_UPDATE_ENABLED;

    const results: ManageEvidenceViewModel[] = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.currentResultsViewModelsSelector
    );
    // @ts-expect-error Need to Type the createSearchModule abstraction
    const totalCount = useSelector(evidenceDashboardSearch.selectors.totalCountSelector);
    // @ts-expect-error Need to Type the createSearchModule abstraction
    const { from: queryFrom, size: querySize } = useSelector(
        // @ts-expect-error Need to Type the createSearchModule abstraction
        evidenceDashboardSearch.selectors.currentQuerySelector
    );

    const dispatch = useDispatch();

    const pageSize = querySize ?? DEFAULT_PAGE_SIZE;
    const currentPageNumber = fromSizeToPage(queryFrom + 1, pageSize);

    const onPageSizeChange = useCallback(
        (event) => {
            const newPageSize = event.currentTarget.value;
            /** Calculate which page contains the first result from the current visible page. */
            const newPageNumber = Math.ceil(queryFrom / newPageSize);
            dispatch(
                // @ts-expect-error Need to Type the createSearchModule abstraction
                evidenceDashboardSearch.actionCreators.search({
                    from: newPageNumber * newPageSize - newPageSize,
                    size: newPageSize,
                })
            );
        },
        [queryFrom, dispatch]
    );

    const onRowClick = useCallback(
        ({ evidenceItem, rowIndex }) =>
            dispatch(
                // @ts-expect-error Need to Type the createSearchModule abstraction
                evidenceDashboardSearch.actionCreators.openSearchResult(
                    evidenceItem,
                    rowIndex,
                    router
                )
            ),
        [dispatch, router]
    );

    // Currently set up for just SINGLE column sorting.
    const handleColumnSortChange = useCallback(
        (updaterOrValue: Updater<SortingState>) => {
            if (!Array.isArray(updaterOrValue) || !updaterOrValue.length) {
                dispatch(
                    // @ts-expect-error Need to Type the createSearchModule abstraction
                    evidenceDashboardSearch.actionCreators.search({
                        sortKey: sortKeyEnum.ELASTICSEARCH_SCORE,
                        sortType: sortTypeEnum.RELEVANCE_MOST_TO_LEAST,
                    })
                );
            } else {
                const sortOption = updaterOrValue[0];
                const columnSort = columnSorts.getSqlSort(sortOption);
                dispatch(
                    // @ts-expect-error Need to Type the createSearchModule abstraction
                    evidenceDashboardSearch.actionCreators.search({
                        ...columnSort,
                    })
                );
            }
        },
        [dispatch]
    );

    const onNavigateToPage = useCallback(
        (pageNumber) => {
            dispatch(
                // @ts-expect-error Need to Type the createSearchModule abstraction
                evidenceDashboardSearch.actionCreators.search(
                    {
                        from: pageNumber * pageSize - pageSize,
                        size: pageSize,
                    },
                    { cacheBust: true }
                )
            );
        },
        [dispatch, pageSize]
    );

    const onPageIndexChange = useCallback(
        (e) => {
            const pageNumber = Number(e.target.value) + 1;
            onNavigateToPage(pageNumber);
        },
        [onNavigateToPage]
    );

    const filteredColumnDefs = evidenceColumns.filter((column) => {
        if (!staffRemarksAbility && column.id === 'staffRemarks') {
            return;
        }

        if (!showHighRiskLabelColumn && column.id === 'isHighValue') {
            return;
        }
        return column;
    });

    const handleRowSelectionChange = (rows: ManageEvidenceDashboardResults[] | 'ALL_SELECTED') => {
        if (rows === 'ALL_SELECTED') {
            // @ts-expect-error Need to Type the createSearchModule abstraction
            dispatch(evidenceDashboardSearch.actionCreators.selectAllResults());
        } else {
            dispatch(
                // @ts-expect-error Need to Type the createSearchModule abstraction
                evidenceDashboardSearch.actionCreators.selectRows(
                    rows.map((row) => {
                        const { id } = row;
                        return results.findIndex((item) => item.id === id);
                    })
                )
            );
        }
    };

    return (
        <DataTable<ManageEvidenceViewModel>
            data={results}
            columns={filteredColumnDefs}
            hasColumnOrdering
            hasColumnVisibility
            hasColumnResizing
            hasColumnSorting
            hasRowSelection
            hasStickyHeaders
            onColumnSortChange={handleColumnSortChange}
            tableProps={{ variant: 'striped' }}
            renderRow={({ row, renderedCells }) => {
                return (
                    <ElasticResultsDataTableRow<ManageEvidenceDashboardResults>
                        row={row.original}
                        onClick={() => {
                            onRowClick({
                                evidenceItem: row.original,
                                rowIndex: row.index,
                            });
                        }}
                    >
                        {renderedCells}
                    </ElasticResultsDataTableRow>
                );
            }}
            onRowSelectionChange={handleRowSelectionChange}
            bulkActions={MANAGE_DASHBOARD_BULK_ACTIONS}
            paginationProps={{
                pageIndex: currentPageNumber - 1,
                pageSize,
                totalSize: Number(totalCount),
                pageSizeOptions: [25, 50, 75, 100],
                onNextPage: () => onNavigateToPage(currentPageNumber + 1),
                onPrevPage: () => onNavigateToPage(currentPageNumber - 1),
                onPageIndexChange,
                onPageSizeChange,
                renderPaginationSummary:
                    Number(totalCount) >= APPROXIMATE_RESULTS_THRESHOLD
                        ? ({ from, to, total }) => {
                              return (
                                  <DataTablePaginationSummary
                                      from={from}
                                      to={to}
                                      total={
                                          total ? strings.paginationTotalSummary(total) : undefined
                                      }
                                  />
                              );
                          }
                        : undefined,
            }}
        />
    );
};

const ManageEvidenceDashboardDataTable = withRouter(BaseManageEvidenceDashboardDataTable);

export { ManageEvidenceDashboardDataTable };

import React, { useRef } from 'react';
import classNames from 'classnames';
import { Motion, spring, MotionProps } from 'react-motion';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { ButtonProps as ArcButtonProps } from 'arc';
import { useSelector } from 'react-redux';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import withFocusTrap, {
    WithFocusTrapProps,
} from '~/client-common/core/keyboardFocus/withFocusTrap';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';

import testIds from '../../../../core/testIds';
import { AnalyticsContextProviderWithAdditionalData } from '../../context/AnalyticsContext';
import { ToolTipContainerContext } from '../../context/ToolTipContainerContext';
import ReactHotKeysWrapper from '../../hotkeys/components/ReactHotKeysWrapper';
import { defaultContentStyles, defaultOverlayStyles } from '../configuration';
import { createHotKeysConfig } from '../utils/hotKeyHelpers';
import { InteractionType } from '../../utils/buttonHelpers';
import { LoadingBar } from '../../../../legacy-redux/components/core/LegacyLoadingBar';
import SidePanelHeader from './SidePanelHeader';
import SidePanelFooter from './SidePanelFooter';

type AllowedReactModalProps = Pick<
    ReactModalProps,
    'isOpen' | 'onRequestClose' | 'shouldCloseOnOverlayClick' | 'shouldReturnFocusAfterClose'
>;
export interface SidePanelBaseProps extends AllowedReactModalProps, WithFocusTrapProps {
    cancelButtonVariant?: ArcButtonProps['variant'];
    cancelDisabled?: boolean;
    cancelText?: string; // use null to hide button
    className?: string;
    closePanel?: (e?: InteractionType) => void;
    dimmedOverlay?: boolean;
    errorMessages?: string[];
    hideFooter?: boolean;
    isLegacy?: boolean;
    loading?: boolean;
    noPadding?: boolean;
    onRest?: MotionProps['onRest'];
    renderFooter?: (baseFooter: React.ReactNode) => React.ReactNode;
    renderCancelButton?: (baseCancelButton: React.ReactNode) => React.ReactNode;
    renderLoadingBar?: (baseLoadingBar: React.ReactNode) => React.ReactNode;
    saveButtonVariant?: ArcButtonProps['variant'];
    saveDisabled?: boolean;
    savePanel?: (e?: InteractionType) => void;
    saveText?: string | null; // use null to hide button
    saving?: boolean;
    scrollOffset?: number;
    testId?: string;
    title: string;
    actionButtonVariant?: ArcButtonProps['variant'];
    action?: (e?: InteractionType) => void;
    actionText?: string;
    useDefaultStyle?: boolean;
    width?: number;
}

const closedRightPosition = -470;
const closedRightPositionAnimationEnd = -490;
const openRightPosition = 0;

// Do not style react-modal content element directly. Because Motion component slide action
// is better when applied to children element.
const sidePanelBaseStyles = {
    content: defaultContentStyles,
    overlay: defaultOverlayStyles,
};

// Mutable id which is used to create a unique class for every side panel base open
// This way we can identify the right side panel for our tooltips when multiple
// panels are open at the same time
let id = 0;

const ConditionalReactModal: React.FC<{ isLegacy: boolean } & ReactModalProps> = ({
    children,
    isLegacy,
    ...props
}) => {
    if (isLegacy) {
        return <>{children}</>;
    }

    return <ReactModal {...props}>{children}</ReactModal>;
};

/**
 * Renders the base UI for every side panel
 */
const SidePanelBase: React.FC<SidePanelBaseProps> = ({
    cancelButtonVariant = 'outline',
    cancelDisabled = false,
    cancelText = 'Cancel',
    children,
    className,
    closePanel,
    dimmedOverlay,
    errorMessages = [],
    hideFooter = false,
    isLegacy = false,
    isOpen = false,
    loading,
    noPadding = false,
    onRest,
    onRequestClose,
    renderFooter,
    renderCancelButton,
    renderLoadingBar,
    saveButtonVariant = 'solid',
    saveDisabled = false,
    savePanel,
    saveText = 'Save',
    saving,
    scrollOffset,
    shouldCloseOnOverlayClick = false,
    shouldReturnFocusAfterClose = true,
    testId,
    title,
    actionButtonVariant = 'ghost',
    action,
    actionText,
    useDefaultStyle = true,
    width,
}) => {
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;

    const savingGifClass = classNames('react-saving-gif', {
        'loading-whitebg loading-small': saving || loading,
    });
    const containerClass = classNames(
        'mark43-react-side-panel',
        {
            'no-padding': noPadding,
            'hide-footer': hideFooter,
            'no-header': isMainNavEnabled,
        },
        className
    );
    // We want a consistent id for the lifecycle of our component.
    // While we are still incrementing `id` on every render, the `current`
    // value will be the value we passed in initially, guarantueeing
    // that we don't accidentally modify the class for the tooltips.
    //
    // We could also use `useMemo` here but it provides less strong guarantees
    // about the lifetime of a value, e.g. it might recompute at some point
    // if react decides to evict the cached value.
    // See https://reactjs.org/docs/hooks-reference.html#usememo for more information.
    const tooltipClass = useRef(`react-side-panel-content-${++id}`).current;

    const defaultContentElement = (
        <div className="react-side-panel-content-wrapper" data-test-id={testIds.SIDE_PANEL_CONTENT}>
            <div className={`react-side-panel-content ${tooltipClass}`}>
                {renderLoadingBar ? renderLoadingBar({ LoadingBar, loading, saving }) : undefined}
                {children}
            </div>
        </div>
    );
    const footerElement = (
        <SidePanelFooter
            cancelButtonVariant={cancelButtonVariant}
            cancelDisabled={cancelDisabled}
            cancelText={cancelText}
            closePanel={closePanel}
            renderCancelButton={renderCancelButton}
            saveButtonVariant={saveButtonVariant}
            savePanel={savePanel}
            saveDisabled={saveDisabled}
            saveText={saveText}
            saving={saving}
            savingGifClass={savingGifClass}
            actionButtonVariant={actionButtonVariant}
            action={action}
            actionText={actionText}
        />
    );

    // Motion works better when _not_ acting on ReactModal content container. This is why we
    // ReactModal styles to undefined and use plain div container for Motion to act on.
    return (
        <AnalyticsContextProviderWithAdditionalData
            analyticsKeyToAdd={AnalyticsPropertyEnum.SIDE_PANEL}
            analyticsValueToAdd={testId}
        >
            <ReactHotKeysWrapper
                hotKeysConfig={createHotKeysConfig({ onClose: closePanel, onSave: savePanel })}
            >
                <ToolTipContainerContext.Provider value={`.${tooltipClass}`}>
                    <ConditionalReactModal
                        ariaHideApp={false}
                        isLegacy={isLegacy}
                        isOpen={isOpen}
                        onRequestClose={onRequestClose}
                        overlayClassName={classNames('mark43-modal-overlay', {
                            dimmed: !!dimmedOverlay,
                            // This can be removed when the RMS_ARC_NAVIGATION_ENABLED feature flag is torn down
                            'no-header': isMainNavEnabled,
                        })}
                        // Removing this default functionality from `ReactModal`
                        // and handling with `ReactHotKeysWrapper` so
                        // that we can easily track via mixpanel
                        shouldCloseOnEsc={false}
                        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                        shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
                        style={sidePanelBaseStyles}
                    >
                        <Motion
                            defaultStyle={{ right: closedRightPosition }}
                            style={{ right: spring(openRightPosition, { damping: 20 }) }}
                            onRest={onRest}
                        >
                            {({ right }) => {
                                if (right <= closedRightPositionAnimationEnd) {
                                    // return type JSX.Element does not except null or false
                                    return <></>;
                                }

                                return (
                                    <div
                                        className={containerClass}
                                        data-test-id={testId}
                                        style={{ right, width }}
                                        onSubmit={(e) => {
                                            // Form submission events of forms mounted within the side panel must now propgate outside of the side panel.
                                            // Their propagation causes parent forms to be submitted when hitting "enter" inside a form element of a nested form.
                                            // This is very unexpected behavior, thus we stop propagation of submission events here.
                                            e.stopPropagation();
                                        }}
                                    >
                                        <SidePanelHeader
                                            errorMessages={errorMessages}
                                            scrollOffset={scrollOffset}
                                            title={title}
                                        />
                                        {useDefaultStyle ? defaultContentElement : children}
                                        {!hideFooter &&
                                            (renderFooter
                                                ? renderFooter(footerElement)
                                                : footerElement)}
                                    </div>
                                );
                            }}
                        </Motion>
                    </ConditionalReactModal>
                </ToolTipContainerContext.Provider>
            </ReactHotKeysWrapper>
        </AnalyticsContextProviderWithAdditionalData>
    );
};

export default withFocusTrap(SidePanelBase);

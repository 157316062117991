import React, { useEffect, useMemo } from 'react';
import { FilterList, FilterMenuRenderProps, FilterT, Flex, VisibleFilter } from 'arc';
import {
    AttributeTypeEnum,
    BriefingStateEnum,
    DateRangeQuery,
    RefContextEnum,
} from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import {
    BRIEFING_EXPIRY_DATE_UTC,
    BRIEFING_PERSONNEL_UNIT_ROUTING_LINK_PERSONNEL_UNIT_ATTR_ID,
    BRIEFING_ROLE_ROUTING_LINK_ROLE_ID,
    BRIEFING_SUBDIVISION_ROUTING_LINK_SUBDIVISION_ID,
    BRIEFING_TITLE,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nonInternalStaticallyHiddenFieldConfigurationsInContextSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';

import testIds from '../../../../core/testIds';
import { DateRangeFilter } from '../../../core/filters/components/DateRangeFilter';
import { UserSelectFilter } from '../../../core/filters/components/UserSelectFilter';
import { UserRoleFilter } from '../../../core/filters/components/UserRoleFilter';
import { InputFilter } from '../../../core/filters/components/InputFilter';
import { CreateFilterHelper, enumToFilterOptions } from '../../../core/filters/utils';
import { AttributeSelectFilter } from '../../../core/filters/components/AttributeSelectFilter';
import { useFilters } from '../../../core/filters/hooks/useFilters';
import { BRIEFING_QUERY_MAPPER, initialFilters } from '../../state/forms/briefingsDashboardFilters';
import {
    ALWAYS_VISIBLE_FILTERS_LIMIT,
    SUPPORTED_ROLES_TYPES,
    SUPPORTED_SUBDIVISION_TYPES,
} from '../../configuration';

const strings = componentStrings.briefings.filters;

const filterIdByField: Map<string, string> = new Map([
    [BRIEFING_EXPIRY_DATE_UTC, BRIEFING_QUERY_MAPPER.expiryDateRangeQuery],
    [BRIEFING_SUBDIVISION_ROUTING_LINK_SUBDIVISION_ID, BRIEFING_QUERY_MAPPER.subdivisionIds],
    [BRIEFING_ROLE_ROUTING_LINK_ROLE_ID, BRIEFING_QUERY_MAPPER.roleIds],
    [
        BRIEFING_PERSONNEL_UNIT_ROUTING_LINK_PERSONNEL_UNIT_ATTR_ID,
        BRIEFING_QUERY_MAPPER.personnelUnitIds,
    ],
    [BRIEFING_TITLE, BRIEFING_QUERY_MAPPER.title],
]);
type BriefingFiltersConfigReturn = {
    filterList: FilterT<BriefingFilterOption>[];
    initialAppliedFilters: VisibleFilter<BriefingFilterOption>[];
};
const useBriefingFiltersConfig = (): BriefingFiltersConfigReturn => {
    const fields = useFields([
        BRIEFING_EXPIRY_DATE_UTC,
        BRIEFING_ROLE_ROUTING_LINK_ROLE_ID,
        BRIEFING_SUBDIVISION_ROUTING_LINK_SUBDIVISION_ID,
        BRIEFING_TITLE,
        BRIEFING_PERSONNEL_UNIT_ROUTING_LINK_PERSONNEL_UNIT_ATTR_ID,
    ]);

    const hiddenBriefingRoutingFields = useSelector(
        nonInternalStaticallyHiddenFieldConfigurationsInContextSelector
    )(RefContextEnum.FORM_BRIEFING_ROUTING_MODAL.name);
    const hiddenBriefingRoutingFieldDisplayNames = hiddenBriefingRoutingFields.map(
        (fieldConfiguration) => filterIdByField.get(fieldConfiguration.fieldName)
    );

    return useMemo(() => {
        const filterCreator = new CreateFilterHelper();
        const filterList = filterCreator
            .add({
                id: BRIEFING_QUERY_MAPPER.briefingStateIds,
                name: strings.fields.state,
                options: enumToFilterOptions(BriefingStateEnum),
            })
            .add({
                id: BRIEFING_QUERY_MAPPER.subdivisionIds,
                name: fields.BRIEFING_SUBDIVISION_ROUTING_LINK_SUBDIVISION_ID,
                render: (renderProps) => (
                    <AttributeSelectFilter
                        {...renderProps}
                        attributeType={SUPPORTED_SUBDIVISION_TYPES}
                    />
                ),
            })
            .add({
                id: BRIEFING_QUERY_MAPPER.roleIds,
                name: fields.BRIEFING_ROLE_ROUTING_LINK_ROLE_ID,
                render: (renderProps: FilterMenuRenderProps) => (
                    <UserRoleFilter {...renderProps} roleTypes={SUPPORTED_ROLES_TYPES} />
                ),
            })
            .add({
                id: BRIEFING_QUERY_MAPPER.personnelUnitIds,
                name: fields.BRIEFING_PERSONNEL_UNIT_ROUTING_LINK_PERSONNEL_UNIT_ATTR_ID,
                render: (renderProps: FilterMenuRenderProps) => (
                    <AttributeSelectFilter
                        {...renderProps}
                        attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                    />
                ),
            })
            .add({
                id: BRIEFING_QUERY_MAPPER.title,
                name: fields.BRIEFING_TITLE,
                render: (renderProps: FilterMenuRenderProps) => {
                    return (
                        <InputFilter
                            {...renderProps}
                            filterId={BRIEFING_QUERY_MAPPER.title}
                            placeholder={strings.fields.inputPlaceholder}
                        />
                    );
                },
                dynamicValues: true,
            })
            .add({
                id: BRIEFING_QUERY_MAPPER.id,
                name: strings.fields.urn,
                render: (renderProps: FilterMenuRenderProps) => {
                    return (
                        <InputFilter
                            {...renderProps}
                            filterId={BRIEFING_QUERY_MAPPER.id}
                            placeholder={strings.fields.inputPlaceholder}
                        />
                    );
                },
                dynamicValues: true,
            })
            .add({
                id: BRIEFING_QUERY_MAPPER.creatorUserIds,
                name: strings.fields.createdBy,
                render: (renderProps: FilterMenuRenderProps) => {
                    return <UserSelectFilter {...renderProps} />;
                },
            })
            .add<DateRangeQuery>({
                id: BRIEFING_QUERY_MAPPER.postedDateRangeQuery,
                name: strings.fields.postedDateUtc,
                render: (renderProps) => (
                    <DateRangeFilter
                        {...renderProps}
                        withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
                        toDatePeriodOptions={['P1M', 'P1Y']}
                    />
                ),
                dynamicValues: true,
            })
            .add<DateRangeQuery>({
                id: BRIEFING_QUERY_MAPPER.expiryDateRangeQuery,
                name: fields.BRIEFING_EXPIRY_DATE_UTC,
                render: (renderProps: FilterMenuRenderProps<DateRangeQuery>) => (
                    <DateRangeFilter
                        {...renderProps}
                        withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
                        toDatePeriodOptions={['P1M', 'P1Y']}
                    />
                ),
                dynamicValues: true,
            })
            .getFilters();

        const activeFilters = filterList
            .filter(
                (filterData) =>
                    !hiddenBriefingRoutingFieldDisplayNames.includes(filterData.id.toString())
            )
            .map((filterData, index) => ({
                ...filterData,
                isAlwaysVisible: index < ALWAYS_VISIBLE_FILTERS_LIMIT,
            }));

        return {
            filterList: activeFilters,
            initialAppliedFilters: [
                {
                    id: filterList[0].id,
                    appliedOptions: (filterList[0].options ?? []).filter((option) =>
                        initialFilters.briefingStateIds.includes(Number(option.id))
                    ),
                },
            ],
        };
    }, [
        fields.BRIEFING_EXPIRY_DATE_UTC,
        fields.BRIEFING_PERSONNEL_UNIT_ROUTING_LINK_PERSONNEL_UNIT_ATTR_ID,
        fields.BRIEFING_ROLE_ROUTING_LINK_ROLE_ID,
        fields.BRIEFING_SUBDIVISION_ROUTING_LINK_SUBDIVISION_ID,
        fields.BRIEFING_TITLE,
        hiddenBriefingRoutingFieldDisplayNames,
    ]);
};

export type BriefingFilterOption = string | DateRangeQuery;
type BriefingFiltersProps = {
    visibleFilters?: VisibleFilter<BriefingFilterOption>[];
    shouldApplyFilters?: boolean;
    onFiltersChange?: (
        activeFilters: VisibleFilter<BriefingFilterOption>[],
        reset?: boolean
    ) => void;
};
export function BriefingsFilters({
    visibleFilters,
    shouldApplyFilters = false,
    onFiltersChange,
}: BriefingFiltersProps) {
    const { filterList, initialAppliedFilters } = useBriefingFiltersConfig();

    const { appliedFilters, applyFilters, handleFiltersChange } = useFilters<BriefingFilterOption>({
        filterIds: filterList.map((filter) => filter.id),
        initialAppliedFilters,
        onFiltersChange,
    });

    useEffect(() => {
        if (visibleFilters && shouldApplyFilters) {
            applyFilters(visibleFilters, false);
        }
    }, [applyFilters, shouldApplyFilters, visibleFilters]);

    return (
        <Flex gap={2} flexWrap="wrap" data-test-id={testIds.BRIEFING_FILTERS}>
            <FilterList<BriefingFilterOption>
                filters={filterList}
                appliedFilters={appliedFilters}
                onFiltersChange={handleFiltersChange}
            />
        </Flex>
    );
}

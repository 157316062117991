import { FormEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import warrantCardEnum from '~/client-common/core/enums/client/warrantCardEnum';
import createCard from '../../../../reports/core/utils/createCard';
import { runCardValidation } from '../../../../core/validation';

const cardName = warrantCardEnum.WARRANT_CHARGES;

const baseCard = createCard({
    name: cardName,
    baseSelector: (state) => state.ui.warrants.warrant.warrantChargesCard,
    anchor: 'warrant-charges-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        save() {
            return (dispatch) => {
                const validationContext = { form: FormEnum.WARRANT_CHARGES.name };
                const panelErrors = dispatch(runCardValidation(validationContext));
                return new Promise((resolve, reject) => {
                    if (panelErrors.length > 0) {
                        dispatch(baseCard.actionCreators.setErrorMessages(panelErrors));
                        reject(panelErrors);
                    } else {
                        dispatch(baseCard.actionCreators.savingSuccess());
                        // clear error messages
                        dispatch(baseCard.actionCreators.resetState());
                        resolve();
                    }
                });
            };
        },
    },
};

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { SearchErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import { BaseScrollableUnderSubheader } from '../../../core/components/ScrollableUnderSubheader';
import Page from '../../../core/components/Page';
import { PageContent } from '../../../core/components/PageContent';
import Subheader from '../../../core/components/Subheader';

import AdvancedSearch from './AdvancedSearch';

const pageTitle = componentStrings.core.navigation.search;

const StyledPageContent = styled(PageContent)`
    background-color: var(--arc-colors-surface-canvas);
    border: none;
    padding-top: var(--arc-space-2);
    width: unset; // To be removed when this page is made responsive
    max-width: unset; // To be removed when this page is made responsive

    @media (min-width: ${mediaQueries.md}) {
        padding-top: var(--arc-space-8);
    }
`;
const StyledBaseScrollableUnderSubheader = styled(BaseScrollableUnderSubheader)`
    justify-content: unset; // To be removed when this page is made responsive
`;

function AdvancedSearchContainer({
    // general
    children,
}) {
    /**
     * This class exists to make advanced search routing work well with the router
     * If a search type is specified in the route (eg: /search/reports) then
     * the router will pass an appropriate AdvancedSearch component in, otherwise
     * we render a default one
     */
    return (
        <Page>
            <Subheader title={pageTitle} />
            <StyledBaseScrollableUnderSubheader>
                <StyledPageContent>
                    <SearchErrorBoundary>{children || <AdvancedSearch />}</SearchErrorBoundary>
                </StyledPageContent>
            </StyledBaseScrollableUnderSubheader>
        </Page>
    );
}

const mapDispatchToProps = () => ({});

export default compose(withRouter, connect(undefined, mapDispatchToProps))(AdvancedSearchContainer);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { formatAttributeByIdSelector } from '../../attributes/state/data';
import { formatFieldByNameSelector } from '../../../fields/state/config';
import * as fields from '../../../enums/universal/fields';

const mapStateToProps = createStructuredSelector({
    formatAttributeById: formatAttributeByIdSelector,
    formatFieldByName: formatFieldByNameSelector,
});

/**
 * Use this component to format the marijuana garden type and quantity for a given property status.
 * @param  {Object} props.propertyStatus
 */
export const FormattedPropertyStatusMarijuanaTypeQuantity = connect(mapStateToProps)(
    ({ propertyStatus, className, formatAttributeById, formatFieldByName }) => {
        if (!propertyStatus) {
            return null;
        }

        const { marijuanaTypeAttrId, marijuanaQuantity } = propertyStatus;

        const formattedMarijuanaTypeQuantity =
            marijuanaTypeAttrId && marijuanaQuantity
                ? `${formatFieldByName(
                      fields.PROPERTY_STATUS_MARIJUANA_TYPE_ATTR_ID
                  )} - ${formatAttributeById(marijuanaTypeAttrId)} - ${marijuanaQuantity}`
                : '';

        return formattedMarijuanaTypeQuantity ? (
            <div className={className}>{formattedMarijuanaTypeQuantity}</div>
        ) : null;
    }
);

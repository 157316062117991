import React from 'react';
import ReactPlayer from 'react-player';

// by default, do not show a "download" button in the media
export default function MediaPlayer({
    config = { file: { attributes: { controlsList: 'nodownload' } } },
    controls = true,
    url,
}) {
    return <ReactPlayer config={config} controls={controls} url={url} />;
}

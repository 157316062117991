import { compact, concat, filter, get, includes, isEmpty, map, parseInt, orderBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { isUndefinedOrNull, isValueDisplayable } from '~/client-common/helpers/logicHelpers';
import { formatWarrantShortTitle } from '~/client-common/helpers/warrantHelpers';

import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { arrestByIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { warrantByIdSelector } from '~/client-common/core/domain/warrants/state/data';
import { arrestRelatedWarrantsSelector } from '~/client-common/core/domain/charges/state/ui';
import { reportRenByIdSelector } from '~/client-common/core/domain/reports/state/ui';

import { setWarrantOnCharge } from '../../../../state/forms/arrest-charges-side-panel/nChargesForm';
import { RadioWithPaddedOptions } from './RadioWithPaddedOptions';

const WarrantQuickAddRadioButtons = connect(
    createStructuredSelector({
        arrestById: arrestByIdSelector,
        reportRenById: reportRenByIdSelector,
        arrestRelatedWarrants: arrestRelatedWarrantsSelector,
        warrantById: warrantByIdSelector,
        formatAttributeById: formatAttributeByIdSelector,
    })
)(function _WarrantQuickAddRadioButtons({
    arrestById,
    reportRenById,
    arrestRelatedWarrants,
    warrantById,
    formatAttributeById,
    arrestId,
    nChargesFormModelCharges,
    offenseOrder,
}) {
    const arrest = arrestById(arrestId);
    const arrestReportRen = reportRenById(arrest.reportId);
    // Grab all warrants that are related to the arrest
    const relatedWarrants = arrestRelatedWarrants({ arrestId });
    const relatedWarrantIds = map(relatedWarrants, 'id');
    // Grab warrants for all of the `Charge`s on our form, that are not also linked warrants.
    // This will give us a unique set of possible `Warrant`s to quick add.
    const formModelChargeWarrantIds = filter(
        compact(map(nChargesFormModelCharges, 'warrantId')),
        (formModelChargeWarrantId) => !includes(relatedWarrantIds, formModelChargeWarrantId)
    );
    const formModelChargeWarrants = map(formModelChargeWarrantIds, warrantById);
    const warrantsForQuickAdd = concat(relatedWarrants, formModelChargeWarrants);

    // - Sort by REN first
    //    - For REN sort, warrants matching the arrest report REN come first, then
    //      sort by REN ascending, with `NULL` RENs last.
    // - Sort by warrant number second
    const sortedWarrantsForQuickAdd = orderBy(
        warrantsForQuickAdd,
        [
            // `NULL` RENs last
            ({ reportingEventNumber }) => {
                return isUndefinedOrNull(reportingEventNumber) ? 0 : reportingEventNumber;
            },
            // RENs ascending, where matching arrest report RENs come first
            ({ reportingEventNumber }) => {
                return arrestReportRen === reportingEventNumber ? 0 : reportingEventNumber;
            },
            // warrant number ascending
            'warrantNumber',
        ],
        ['desc', 'asc', 'asc']
    );

    const sortedOptions = map(sortedWarrantsForQuickAdd, (warrantForQuickAdd) => {
        const { id, warrantNumber, warrantTypeAttrId, reportingEventNumber } = warrantForQuickAdd;
        const warrantType = formatAttributeById(warrantTypeAttrId);
        const formattedWarrantShortTitle = formatWarrantShortTitle({
            warrantNumber,
            warrantType,
        });
        const label = isValueDisplayable(reportingEventNumber)
            ? `${formattedWarrantShortTitle} (${reportingEventNumber})`
            : `${formattedWarrantShortTitle}`;
        return {
            value: id,
            label,
        };
    });

    return isEmpty(sortedOptions) ? null : (
        <RadioWithPaddedOptions
            newlines={true}
            orientation="column"
            options={sortedOptions}
            onChange={(selectedOption) => {
                const warrantId = get(selectedOption, 'target.value');
                if (!isUndefinedOrNull(warrantId)) {
                    const parsedWarrantId = parseInt(warrantId);
                    return setWarrantOnCharge({
                        chargeOffenseOrder: offenseOrder,
                        warrantId: parsedWarrantId,
                    });
                }
            }}
        />
    );
});

export default WarrantQuickAddRadioButtons;

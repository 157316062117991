let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Export Presets Resource',
        methods: {
            getAllExportPresets() {
                return req({
                    method: 'GET',
                    url: 'exports/presets',
                });
            },
            upsertExportPreset(exportPreset) {
                return req({
                    method: 'POST',
                    url: 'exports/presets',
                    data: exportPreset,
                });
            },
            deleteExportPresets(exportPresetIds) {
                return req({
                    method: 'DELETE',
                    url: 'exports/presets',
                    params: {
                        ids: exportPresetIds,
                    },
                });
            },
        },
    });
}

/**
 * Resources for Export Presets. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { iconTypes } from '../../../../core/components/Icon';
import _NavigationLink from '../../core/components/NavigationLink';

import {
    openItemQueuePopover,
    itemQueuePopoverIsOpenSelector,
    itemQueueCountSelector,
} from '../../../../evidence/item-queue/state/ui';
import testIds from '../../../../../core/testIds';

// overwrite default nav-box styles
const NavigationLink = styled(_NavigationLink)`
    &&.nav-box {
        float: right;
        border-left: none;
        height: 60px;
        width: 20px;
        margin-right: 17px;
    }
`;

function ItemQueueNavigationLink({
    openItemQueuePopover,
    itemQueuePopoverIsOpen,
    itemQueueCount,
    onPath,
}) {
    return (
        <NavigationLink
            iconType={iconTypes.PROPERTY}
            onClick={openItemQueuePopover}
            count={itemQueueCount}
            selected={itemQueuePopoverIsOpen || onPath}
            testId={testIds.EVIDENCE_ITEM_BASKET}
        />
    );
}

const mapStateToProps = createStructuredSelector({
    itemQueuePopoverIsOpen: itemQueuePopoverIsOpenSelector,
    itemQueueCount: itemQueueCountSelector,
});
const mapDispatchToProps = (dispatch) => ({
    openItemQueuePopover: () => dispatch(openItemQueuePopover()),
});

/**
 * Navigation link to open the Item Queue Popover. Overlay the Number Bubble
 *   with number of items in Queue.
 * @param {Boolean} onPath true if the current page is the item queue.
 */
export default connect(mapStateToProps, mapDispatchToProps)(ItemQueueNavigationLink);

import styled from 'styled-components';
import { withProps } from 'recompose';
import { cssVar } from 'arc';
import testIds from '../../../../core/testIds';
import getThemeColor, { ThemeColorsT } from '../../utils/getThemeColor';

const Label = styled('span')<{
    backgroundColor?: ThemeColorsT;
    borderColor?: ThemeColorsT;
    textColor?: ThemeColorsT;
    'data-test-id': 'LABEL';
}>`
    font-size: ${cssVar('arc.fontSizes.xs')};
    line-height: 15px;
    padding: 0 4px;
    margin: 0 10px;
    border-radius: 3px;
    display: inline-block;
    border: 1px solid
        ${({ theme, borderColor = 'darkGreen2' }) => getThemeColor(theme, borderColor)};
    background-color: ${({ theme, backgroundColor = 'lightGreen2' }) =>
        getThemeColor(theme, backgroundColor)};
    ${({ theme, textColor }) => textColor && `color: ${getThemeColor(theme, textColor)};`}
`;
export default withProps({ 'data-test-id': testIds.LABEL })(Label);

import { RefContextEnum } from '@mark43/rms-api';
import { CASE_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const editCaseRenFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'existingCaseRen',
    },
    {
        key: 'newCaseRen',
        fieldName: CASE_REPORTING_EVENT_NUMBER,
    },
]);

export default createFormModule({
    context: RefContextEnum.FORM_CASE_CHANGE_REN.name,
    fieldViewModels: editCaseRenFormFieldViewModels,
});

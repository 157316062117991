import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map, keys, sortBy } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { sortedProfileLocationsViewModelsSelector } from '../state/ui';
import NoDataBlock from '../../../core/components/NoDataBlock';
import EntityProfileSection from './EntityProfileSection';
import EntityProfileLocationSummary from './EntityProfileLocationSummary';

const strings = componentStrings.entityProfiles.EntityProfileLocations;

function EntityProfileLocations({ profileLocations }) {
    const alphabetizedLocationKeys = sortBy(keys(profileLocations));
    const contents = alphabetizedLocationKeys.length ? (
        map(alphabetizedLocationKeys, (locationLinkType) => (
            <EntityProfileSection key={locationLinkType} title={locationLinkType}>
                {map(profileLocations[locationLinkType], (location, index) =>
                    location ? (
                        <EntityProfileLocationSummary
                            key={location.id}
                            elasticLocation={location}
                        />
                    ) : (
                        <div key={index} />
                    )
                )}
            </EntityProfileSection>
        ))
    ) : (
        <div className="no-data-container">
            <NoDataBlock>{strings.noLocations}</NoDataBlock>
        </div>
    );
    return <div>{contents}</div>;
}

const mapStateToProps = createStructuredSelector({
    profileLocations: sortedProfileLocationsViewModelsSelector,
});

export default connect(mapStateToProps)(EntityProfileLocations);

import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import {
    ElasticCase,
    ElasticCaseRelatedOrganization,
    ElasticCaseRelatedPerson,
    EntityTypeEnum,
} from '@mark43/rms-api';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatInvolvementSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { getEntityDataFromRouteParams, ProfileRouteParams } from '../../helpers/routeHelpers';

export const EntityProfileInvolvementCell = withRouter<
    { elasticCase: ElasticCase } & WithRouterProps<ProfileRouteParams>
>(({ elasticCase, params }) => {
    const attributes = useSelector(attributesSelector);
    const formatInvolvement = useSelector(formatInvolvementSelector);
    const { entityId, entityType } = getEntityDataFromRouteParams(params);

    const isPersonProfile = entityType === EntityTypeEnum.PERSON_PROFILE.name;
    const relatedEntities: (ElasticCaseRelatedOrganization | ElasticCaseRelatedPerson)[] =
        isPersonProfile ? elasticCase.relatedPersons : elasticCase.relatedOrganizations;

    const { linkTypes, subjectTypeAttrIds } = relatedEntities.reduce<{
        linkTypes: number[];
        subjectTypeAttrIds: number[];
    }>(
        (acc, item) => {
            const itemId = 'personId' in item ? item.personId : item.organizationId;

            if (itemId !== entityId) {
                return acc;
            }

            const { involvement, subjectTypeAttrId } = item;

            return {
                linkTypes:
                    typeof involvement === 'number'
                        ? acc.linkTypes.concat(involvement)
                        : acc.linkTypes,
                subjectTypeAttrIds:
                    typeof subjectTypeAttrId === 'number'
                        ? acc.subjectTypeAttrIds.concat(subjectTypeAttrId)
                        : acc.subjectTypeAttrIds,
            };
        },
        {
            linkTypes: [],
            subjectTypeAttrIds: [],
        }
    );

    return <>{formatInvolvement(linkTypes, subjectTypeAttrIds, attributes)}</>;
});

import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { InlineBanner, Select, SelectChangeEvent, SkeletonText, Text } from 'arc';
import { compact, flatten, includes, isEmpty, map, uniqBy, values } from 'lodash';
import { Report, ReportDefinition } from '@mark43/rms-api';
import { InjectedRouter, withRouter } from 'react-router';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';

import { useResource } from '~/client-common/core/hooks/useResource';
import type { ReportShortTitleWrapper } from '~/client-common/core/domain/report-short-titles/types';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { openCreateReportModalFromLinkedReport } from '../../../reports/core/state/ui/createReportModal';
import { linkNewReport } from '../../../records/core/state/ui/reportLinking';
import { OverlayBaseHelper } from '../../../core/components/OverlayBaseHelper';
import Modal from '../../../core/overlays/components/Modal';
import { RmsDispatch } from '../../../../core/typings/redux';
import buildReportIdentifierView from '../../../reports/core/utils/buildReportIdentifierView';
import reportsResource from '../../../reports/core/resources/reportsResource';
import LinkedReportsModalContent from './LinkedReportsFromRENModalContent';

const overlayId = overlayIdEnum.CREATE_NEW_REPORT_FROM_MULTI_REN_CASE_MODAL;
const strings = componentStrings.cases.core.CreateNewReportFromMultiRenModal;

const RenSelectWrapper = styled.div`
    padding: 15px 0;
    width: 250px;
`;

type OptionType = {
    isDisabled: boolean;
    label: string;
    value: string;
};

type RenReportType = Pick<Report, 'id' | 'recordNumber' | 'reportingEventNumber'>;

type ModalContentPropsType = {
    reportDefinitionName: string;
    rensForReportDefinitionLink: string[];
    selectedRen: string;
    onSelectChange: (e: SelectChangeEvent<OptionType, false>) => void;
};

const ModalContent = ({
    reportDefinitionName,
    rensForReportDefinitionLink,
    selectedRen,
    onSelectChange,
}: ModalContentPropsType) => {
    const dispatch = useDispatch<RmsDispatch>();
    const caseReports: ReturnType<typeof reportsSelector> = useSelector(reportsSelector);
    const fieldDisplayNames = useFields([REPORT_REPORTING_EVENT_NUMBER]);
    const renDisplayName = fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER;

    const renReports: RenReportType[] = useMemo(
        () =>
            compact(
                map(caseReports, ({ id, reportingEventNumber, recordNumber }) => {
                    if (!isEmpty(reportingEventNumber)) {
                        return {
                            id,
                            reportingEventNumber,
                            recordNumber,
                        };
                    }
                    return undefined;
                })
            ),
        [caseReports]
    );

    const loadReportShortTitles = useCallback(() => {
        const reportIdentifierView = buildReportIdentifierView(renReports);
        return reportsResource.findReportTitlesByReportIdentifier(reportIdentifierView);
    }, [renReports]);

    const onSuccess = useCallback(
        (shortTitleWrapper: ReportShortTitleWrapper) => {
            // store the report short titles in redux
            dispatch(
                withEntityItems(
                    {
                        // Don't need to store records because there will never be records returned since
                        // linking a new report to a case can only be done on a REN
                        [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: [
                            ...flatten(values(shortTitleWrapper.reportShortTitlesByREN)),
                        ],
                    },
                    { type: 'STORE_REPORT_SHORT_TITLES' }
                )
            );
        },
        [dispatch]
    );

    const options: OptionType[] = compact(
        map(uniqBy(renReports, 'reportingEventNumber'), ({ reportingEventNumber }) => {
            if (reportingEventNumber) {
                return {
                    isDisabled: !includes(rensForReportDefinitionLink, reportingEventNumber),
                    label: reportingEventNumber,
                    value: reportingEventNumber,
                };
            }
            return undefined;
        })
    );

    const { isLoading, errorMessage } = useResource(loadReportShortTitles, onSuccess);
    if (errorMessage) {
        return <InlineBanner status="error" description={errorMessage} />;
    }

    if (isLoading) {
        return <SkeletonText noOfLines={3} />;
    }

    return (
        <>
            <Text variant="bodyMd">
                {strings.description(renDisplayName, reportDefinitionName)}
            </Text>
            <RenSelectWrapper>
                <Text variant="bodyMd" fontWeight="semibold">
                    {renDisplayName}
                </Text>
                <Select
                    style={{ zIndex: 10000 }}
                    menuPlacement="bottom"
                    options={options}
                    value={selectedRen}
                    onChange={onSelectChange}
                />
            </RenSelectWrapper>
            <LinkedReportsModalContent selectedRen={selectedRen} />
        </>
    );
};

interface CreateNewReportFromMultiRenModalContentProps {
    reportDefinition: ReportDefinition;
    rensForReportDefinitionLink: string[];
}

const CreateNewReportFromMultiRenCaseModal: React.FC<{
    router: InjectedRouter;
}> = ({ router }) => {
    const [selectedRen, setSelectedRen] = useState<string>('');
    const onSelectChange = (e: SelectChangeEvent<OptionType, false>) => {
        setSelectedRen(e.currentTarget.value);
    };
    const applicationSettings = useSelector(applicationSettingsSelector);
    const multiRenModalRef = useRef(null);
    const dispatch = useDispatch();

    const onCreateReport = useCallback(
        (reportDefinition) => {
            if (applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED) {
                dispatch(
                    openCreateReportModalFromLinkedReport({
                        reportDefinition,
                        reportingEventNumber: selectedRen,
                        router,
                        onCloseRef: multiRenModalRef,
                    })
                );
            } else {
                dispatch(linkNewReport(selectedRen, reportDefinition.id)).then((report) => {
                    // @ts-expect-error linkNewReport is not typed, returns a report typed any
                    router.push(`/reports/${report.id}`);
                });
            }
        },
        [router, selectedRen, applicationSettings, dispatch]
    );

    return (
        <OverlayBaseHelper<CreateNewReportFromMultiRenModalContentProps> id={overlayId}>
            {(renderProps) => {
                const {
                    reportDefinition,
                    rensForReportDefinitionLink,
                } = renderProps.overlayBase.overlayState.customProperties;

                return (
                    <Modal
                        id={overlayId}
                        title={strings.title(reportDefinition?.name)}
                        okText={strings.okText}
                        saveDisabled={isEmpty(selectedRen)}
                        onSave={() => onCreateReport(reportDefinition)}
                    >
                        <ModalContent
                            reportDefinitionName={reportDefinition?.name ?? ''}
                            rensForReportDefinitionLink={rensForReportDefinitionLink}
                            selectedRen={selectedRen}
                            onSelectChange={onSelectChange}
                        />
                    </Modal>
                );
            }}
        </OverlayBaseHelper>
    );
};

export default withRouter(CreateNewReportFromMultiRenCaseModal);

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { focusNextElement } from '~/client-common/core/keyboardFocus/helpers';
import { noFocusOutline } from '../styles/mixins';

const NoOutlineFocusableDiv = styled.div`
    ${noFocusOutline}
`;

/**
 * Wrapper component to handle focusing the first
 * child element on mount
 */
const FocusWrapper = ({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) => {
    const wrapperRef = useRef(null);
    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            focusNextElement(wrapperRef.current);
        }
    }, []);

    return (
        <NoOutlineFocusableDiv tabIndex={-1} ref={wrapperRef} className={className}>
            {children}
        </NoOutlineFocusableDiv>
    );
};

export default FocusWrapper;

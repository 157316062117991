import React from 'react';
import styled from 'styled-components';
import Link from '../../../core/components/links/Link';

const CommonCaseSidebarLinkWrapper = styled.span`
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    && .case-sidebar-link {
        display: flex;
        align-items: inherit;
        width: 100%;
        padding-right: 10px;

        & span {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:focus {
            box-shadow: none;
            border: none;
        }
    }
`;
const LegacyCaseSidebarLinkWrapper = styled(CommonCaseSidebarLinkWrapper)`
    &:hover,
    &:hover .case-sidebar-link {
        color: ${({ theme }) => theme.colors.cobaltBlue};
        background-color: var(--arc-colors-interactive-hover);
    }
`;

const CaseSidebarLinkWrapper = styled(CommonCaseSidebarLinkWrapper)`
    height: 60px;

    &:hover,
    &:hover .case-sidebar-link {
        color: ${({ theme }) => theme.colors.cobaltBlue};
    }
`;

export function LegacyCaseSidebarLink(props) {
    return (
        <LegacyCaseSidebarLinkWrapper>
            <Link activeClassName="active" className="case-sidebar-link" {...props} />
        </LegacyCaseSidebarLinkWrapper>
    );
}

export default function CaseSidebarLink(props) {
    return (
        <CaseSidebarLinkWrapper>
            <Link activeClassName="active" className="case-sidebar-link" {...props} />
        </CaseSidebarLinkWrapper>
    );
}

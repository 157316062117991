import React, { FC } from 'react';
import { ReportShortTitle } from '@mark43/rms-api';
import { Flex } from 'arc';
import styled from 'styled-components';
import { PopoutLink } from '../../../../core/components/links/Link';
import Checkbox from '../../../../core/forms/components/checkboxes/Checkbox';
import LinkedReport from './LinkedReport';

const CheckboxStyled = styled(Checkbox)`
    margin-top: 5px;
    margin-right: 0px;
`;

const PopoutLinkStyled = styled(PopoutLink)`
    margin: 5px 0px;
`;

type LinkedReportWrapperProps = {
    reportShortTitle: ReportShortTitle;
    showReportDetails: boolean;
};

const LinkedReportWrapper: FC<LinkedReportWrapperProps> = ({
    children,
    reportShortTitle,
    showReportDetails,
}) => {
    const { reportId, shortTitle } = reportShortTitle;

    const reportLink = showReportDetails ? (
        <LinkedReport reportShortTitleViewModel={reportShortTitle} />
    ) : (
        <PopoutLinkStyled to={`/reports/${reportId}`}>{shortTitle}</PopoutLinkStyled>
    );

    return (
        <Flex>
            {children}
            {reportLink}
        </Flex>
    );
};

type LinkedReportItemProps = LinkedReportWrapperProps & Partial<LinkedReportItemSelectableProps>;

export type LinkedReportItemSelectableProps = {
    selectable: boolean;
    selectedReportIds: number[];
    onChange: (reportIds: number[]) => void;
};

export const LinkedReportItem: FC<LinkedReportItemProps> = ({
    selectable,
    selectedReportIds = [],
    onChange = () => {},
    ...props
}) => {
    const { reportId } = props.reportShortTitle;

    const handleChange = () => {
        const nextReportIds = selectedReportIds.includes(reportId)
            ? selectedReportIds.filter((id) => id !== reportId)
            : [...selectedReportIds, reportId];

        onChange(nextReportIds);
    };

    return (
        <LinkedReportWrapper {...props}>
            {selectable && (
                <CheckboxStyled
                    value={selectedReportIds.includes(reportId)}
                    onChange={handleChange}
                />
            )}
        </LinkedReportWrapper>
    );
};

export default LinkedReportItem;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, renderNothing, branch, renderComponent } from 'recompose';
import { applicationSettingsSelector } from '../state/data';

const createFeatureFlaggedHOC = (featureFlagsSelector) =>
    function (featureFlag, FallbackComponent) {
        return compose(
            connect(
                createStructuredSelector({
                    featureFlags: featureFlagsSelector,
                })
            ),
            branch(
                ({ featureFlags }) => featureFlags[featureFlag],
                (Component) => Component,
                FallbackComponent ? renderComponent(FallbackComponent) : renderNothing
            )
        );
    };

export default createFeatureFlaggedHOC(applicationSettingsSelector);

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { map } from 'lodash';

import { Text } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import Page from '../../core/components/Page';
import UnderSubheader from '../../core/components/UnderSubheader';
import HelpText from '../../../legacy-redux/components/core/HelpText';
import testIds from '../../../core/testIds';
import { OnlyWithAbility } from '../../core/abilities';
import Link from '../../core/components/links/Link';
import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import {
    complianceExportTypeDisplaySelector,
    complianceExportTypesLoadedSelector,
    supportedComplianceTypesSelector,
} from '../state/ui';
import { getComplianceExportTypeEnum } from '../util/complianceExportTypeHelpers';

type ComplianceDashboardProps = RouteComponentProps<never, Record<string, unknown>>;

const strings = componentStrings.compliance.ComplianceDashboard;

const Title = styled(Text)`
    margin: 20px 0;
`;

const ComplianceDashboard: React.FC<ComplianceDashboardProps> = (props) => {
    const { location, router } = props;

    const complianceExportTypeDisplay = useSelector(complianceExportTypeDisplaySelector);
    const complianceExportTypesLoaded = useSelector(complianceExportTypesLoadedSelector);
    const supportedComplianceTypes = useSelector(supportedComplianceTypesSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);

    useEffect(() => {
        if (!complianceExportTypesLoaded) {
            return;
        }

        if (supportedComplianceTypes.length === 1) {
            const complianceType = getComplianceExportTypeEnum(supportedComplianceTypes[0]);
            if (
                applicationSettings.RMS_NIBRS_DISABLE_NIBRS_DASHBOARD_ENABLED &&
                complianceType.name === NIBRS_COMPLIANCE_TYPE.name
            ) {
                return;
            }
            router.push(`compliance/${complianceType.name}/export`);
        }
    }, [
        applicationSettings.RMS_NIBRS_DISABLE_NIBRS_DASHBOARD_ENABLED,
        complianceExportTypesLoaded,
        router,
        supportedComplianceTypes,
    ]);

    return (
        <Page>
            <UnderSubheader data-test-id={testIds.COMPLIANCE_DASHBOARD_EXPORT_OPTIONS}>
                <Title variant="headingSm">{strings.title}</Title>
                {map(supportedComplianceTypes, (supportedComplianceType) => {
                    const complianceType = getComplianceExportTypeEnum(supportedComplianceType);
                    const { name } = complianceType;
                    const isNibrs = name === NIBRS_COMPLIANCE_TYPE.name;
                    const shouldDisableLink =
                        applicationSettings.RMS_NIBRS_DISABLE_NIBRS_DASHBOARD_ENABLED && isNibrs;
                    const url = location.pathname.replace('dashboard', name);
                    const ability = isNibrs
                        ? abilitiesEnum.REPORTING.NIBRS_WORKSPACE
                        : abilitiesEnum.REPORTING.COMPLIANCE_EXPORT_DASHBOARD;

                    return (
                        <OnlyWithAbility has={ability} key={name}>
                            <div>
                                <Link to={url} disabled={shouldDisableLink}>
                                    {complianceExportTypeDisplay(supportedComplianceType)}
                                    {shouldDisableLink && (
                                        <HelpText content={strings.nibrsDisabled} />
                                    )}
                                </Link>
                            </div>
                        </OnlyWithAbility>
                    );
                })}
            </UnderSubheader>
        </Page>
    );
};

export default ComplianceDashboard;

import {
    deletePrintingTemplatesWhere,
    storePrintingTemplates,
} from '~/client-common/core/domain/printing-templates/state/data';
import resource from '../../resources/printingTemplatesResource';
import { selectPrintingTemplate } from '../ui/actions';
import actionTypes from './actionTypes';

function savePrintingTemplateStart() {
    return {
        type: actionTypes.SAVE_PRINTING_TEMPLATE_START,
    };
}

function savePrintingTemplateSuccess(templates) {
    return {
        type: actionTypes.SAVE_PRINTING_TEMPLATE_SUCCESS,
        payload: templates,
    };
}

function savePrintingTemplateFailure(errorMessage) {
    return {
        type: actionTypes.SAVE_PRINTING_TEMPLATE_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function deletePrintingTemplateStart() {
    return {
        type: actionTypes.DELETE_PRINTING_TEMPLATE_START,
    };
}

function deletePrintingTemplateSuccess() {
    return {
        type: actionTypes.DELETE_PRINTING_TEMPLATE_SUCCESS,
    };
}

function deletePrintingTemplateFailure(errorMessage) {
    return {
        type: actionTypes.DELETE_PRINTING_TEMPLATE_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

function loadPrintingTemplatesStart() {
    return {
        type: actionTypes.LOAD_PRINTING_TEMPLATES_START,
    };
}

function loadPrintingTemplatesSuccess(settings) {
    return {
        type: actionTypes.LOAD_PRINTING_TEMPLATES_SUCCESS,
        payload: settings,
    };
}

function loadPrintingTemplatesFailure(errorMessage) {
    return {
        type: actionTypes.LOAD_PRINTING_TEMPLATES_FAILURE,
        payload: errorMessage,
        error: true,
    };
}

export function savePrintingTemplate(printingTemplate, fileid) {
    return function (dispatch) {
        dispatch(savePrintingTemplateStart());
        const method = printingTemplate.id
            ? resource.updatePrintingTemplate
            : resource.createPrintingTemplate;

        return method(printingTemplate, fileid)
            .then((newTemplate) => {
                dispatch(storePrintingTemplates([newTemplate]));
                dispatch(selectPrintingTemplate(newTemplate.id));
                return dispatch(savePrintingTemplateSuccess(newTemplate));
            })
            .catch((err) => dispatch(savePrintingTemplateFailure(err.message)));
    };
}

export function deletePrintingTemplate(templateId) {
    templateId = parseInt(templateId);
    return function (dispatch) {
        dispatch(deletePrintingTemplateStart());

        return resource
            .deletePrintingTemplate(templateId)
            .then(() => {
                dispatch(deletePrintingTemplatesWhere({ id: templateId }));
                return dispatch(deletePrintingTemplateSuccess(templateId));
            })
            .catch((err) => dispatch(deletePrintingTemplateFailure(err.message)));
    };
}

export function loadPrintingTemplates() {
    return function (dispatch) {
        dispatch(loadPrintingTemplatesStart());

        return resource
            .getStrippedPrintingTemplates()
            .then((templates) => {
                dispatch(storePrintingTemplates(templates));
                return dispatch(loadPrintingTemplatesSuccess(templates));
            })
            .catch((err) => dispatch(loadPrintingTemplatesFailure(err.message)));
    };
}

import React, { useContext, useEffect, useRef, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { first, get } from 'lodash';
import { LookerEmbeddableView } from '@mark43/rms-api';
import { useToast } from 'arc';
import errorCodeEnum from '~/client-common/core/enums/client/errorCodeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { selectedLookerDashboardUrlSelector, selectLookerDashboard } from '../state/ui';
import redirectToErrorPage from '../../../core/utils/redirectToErrorPage';
import lookerResource from '../resources/lookerResource';
import { RmsDispatch } from '../../../../core/typings/redux';
import { AnalysisContext, LookerEventData } from '../../core/components/AnalysisContext';
import { logError } from '../../../../core/logging';
import { clearLookerDashboards, loadLookerDashboards } from '../state/data';
import lookerDashboardForm from '../state/forms/lookerDashboardForm';

const strings = componentStrings.analysis.dashboard;

type LookerDashboardParams = {
    dashboardId?: string;
};

export const LookerDashboard: React.FC<
    RouteComponentProps<LookerDashboardParams, Record<string, never>>
> = ({ params, location }) => {
    const [dashboardId, setDashboardId] = useState<string | null>(
        params.dashboardId ? decodeURIComponent(params.dashboardId) : null
    );
    const dispatch = useDispatch<RmsDispatch>();
    const { setLookerIframeLoaded, addLookerEventCallback, removeLookerEventCallback } =
        useContext(AnalysisContext) || {};
    const applicationSettings = useSelector(applicationSettingsSelector);
    const selectedLookerDashboardUrl = useSelector(selectedLookerDashboardUrlSelector);
    const lookerIframeRef = useRef<HTMLIFrameElement>(null);
    const toast = useToast();

    const killRunningQueriesForDashboard = (dashboardId: string) => {
        lookerResource.killRunningQueriesForDashboard(dashboardId).catch((error) => {
            logError('Failed to kill running looker queries:', error);
        });
    };

    const loadDefaultDashboard = useCallback(() => {
        lookerResource
            .getLookerDashboards()
            .then((dashboards) => {
                const embedUrl = get(first(dashboards), 'url');
                if (!embedUrl) {
                    throw new Error();
                }
                if (lookerIframeRef.current) {
                    lookerIframeRef.current.src = embedUrl;
                    setDashboardId(null);
                }
            })
            .catch(() => {
                dispatch(redirectToErrorPage({ errorCode: errorCodeEnum.NOT_FOUND }));
            });
    }, [dispatch]);

    const loadDashboardById = useCallback(
        (nextDashboardId: string) => {
            lookerResource
                .getLookerDashboardById(nextDashboardId)
                .then((dashboard) => {
                    const embedUrl = get(dashboard, 'url');
                    if (!embedUrl) {
                        throw new Error();
                    }
                    if (lookerIframeRef.current) {
                        lookerIframeRef.current.src = embedUrl;
                        setDashboardId(nextDashboardId);
                    }
                })
                .catch(() => {
                    toast({
                        status: 'error',
                        description: strings.failedToLoadWithIdError(nextDashboardId),
                    });
                    loadDefaultDashboard();
                });
        } /* toast dependency cause unnecessary re-renders */,
        /* eslint-disable react-hooks/exhaustive-deps */ [dispatch, loadDefaultDashboard]
    );

    const handleDashboardLoaded = useCallback(
        (eventData: LookerEventData) => {
            const newDashboardId = eventData.dashboard?.id;
            if (newDashboardId && dashboardId !== newDashboardId) {
                if (
                    dashboardId &&
                    applicationSettings.RMS_LOOKER_KILL_QUERIES_ON_PAGE_TRANSITION_ENABLED
                ) {
                    killRunningQueriesForDashboard(dashboardId);
                }

                if (applicationSettings.RMS_LOOKER_SHARE_DASHBOARD_URL_ENABLED) {
                    const basePath = location.pathname.substring(
                        0,
                        location.pathname.indexOf('/dashboard') + 10
                    );
                    window.history.pushState(
                        null,
                        '',
                        `/rms/#${basePath}/${encodeURIComponent(newDashboardId)}`
                    );
                    setDashboardId(newDashboardId);
                }
            }
        },
        [dashboardId]
    );

    const handlePageChanged = useCallback(
        (eventData: LookerEventData) => {
            const pageType = eventData.page?.type;
            if (pageType !== 'dashboard') {
                if (
                    dashboardId &&
                    applicationSettings.RMS_LOOKER_KILL_QUERIES_ON_PAGE_TRANSITION_ENABLED
                ) {
                    killRunningQueriesForDashboard(dashboardId);
                    setDashboardId(null);
                } else if (
                    dashboardId &&
                    applicationSettings.RMS_LOOKER_SHARE_DASHBOARD_URL_ENABLED
                ) {
                    setDashboardId(null);
                }

                if (applicationSettings.RMS_LOOKER_SHARE_DASHBOARD_URL_ENABLED) {
                    const basePath = location.pathname.substring(
                        0,
                        location.pathname.indexOf('/dashboard') + 10
                    );
                    window.history.pushState(null, '', `/rms/#${basePath}`);
                }
            }
        },
        [dashboardId]
    );

    useEffect(() => {
        if (!applicationSettings.RMS_LOOKER_IFRAME_LISTENER_ENABLED) {
            dispatch(clearLookerDashboards());
            dispatch(loadLookerDashboards())
                .then((dashboards: LookerEmbeddableView[]) => {
                    const firstDashboard = first(dashboards as LookerEmbeddableView[]);
                    if (firstDashboard) {
                        const firstDashboardId = firstDashboard['lookerId'];
                        dispatch(selectLookerDashboard(firstDashboardId));
                        dispatch(
                            // @ts-expect-error RRF createForm is not typed
                            lookerDashboardForm.actionCreators.change({
                                lookerDashboardId: firstDashboardId,
                            })
                        );
                    } else {
                        throw new Error(strings.failedToLoadError);
                    }
                })
                .catch(() => {
                    dispatch(redirectToErrorPage({ errorCode: errorCodeEnum.NOT_FOUND }));
                });
            return;
        }

        if (params.dashboardId && applicationSettings.RMS_LOOKER_SHARE_DASHBOARD_URL_ENABLED) {
            loadDashboardById(decodeURIComponent(params.dashboardId));
        } else {
            loadDefaultDashboard();
        }
    }, [applicationSettings, dispatch, loadDashboardById, loadDefaultDashboard]);

    useEffect(() => {
        const lookerIframe = lookerIframeRef.current;

        if (lookerIframe && setLookerIframeLoaded) {
            lookerIframe.onload = () => {
                setLookerIframeLoaded(true);
            };
            lookerIframe.onerror = () => {
                setLookerIframeLoaded(false);
                logError('Looker Iframe failed to load.');
            };

            return () => {
                lookerIframe.onload = null;
                lookerIframe.onerror = null;
            };
        }
        return;
    }, [setLookerIframeLoaded]);

    useEffect(() => {
        if (
            applicationSettings.RMS_LOOKER_IFRAME_LISTENER_ENABLED &&
            addLookerEventCallback &&
            removeLookerEventCallback
        ) {
            addLookerEventCallback('dashboard:loaded', handleDashboardLoaded);
            addLookerEventCallback('page:changed', handlePageChanged);

            return () => {
                removeLookerEventCallback('dashboard:loaded', handleDashboardLoaded);
                removeLookerEventCallback('page:changed', handlePageChanged);
            };
        }
        return;
    }, [
        applicationSettings.RMS_LOOKER_IFRAME_LISTENER_ENABLED,
        addLookerEventCallback,
        removeLookerEventCallback,
        handleDashboardLoaded,
        handlePageChanged,
    ]);

    return (
        <div className="analysis-dashboard">
            {applicationSettings.RMS_LOOKER_IFRAME_LISTENER_ENABLED ? (
                <iframe ref={lookerIframeRef} className="analysis-dashboard-frame" />
            ) : (
                <iframe
                    key={selectedLookerDashboardUrl}
                    ref={lookerIframeRef}
                    src={selectedLookerDashboardUrl}
                    className="analysis-dashboard-frame"
                />
            )}
        </div>
    );
};

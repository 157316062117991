import { EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { _Form, createFormConfiguration, createNItems } from 'markformythree';
import { reduce, chain, compact, find, flatMap, map, uniqBy } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import {
    buildNameNameLinkDataState,
    convertNameNameLinksDataStateToFormState,
} from '../../../../../legacy-redux/helpers/nameNameLinksHelpers';
import { relationshipsDataSelector } from '../ui/relationships';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

import { nameNameLinkTypeViewModelsSelector } from '../../../../../legacy-redux/selectors/linkTypesSelectors';
import formsRegistry from '../../../../../core/formsRegistry';

const relationshipsFormName = RefContextEnum.FORM_RELATIONSHIPS.name;

export const createRelationshipsForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName, isRelationshipsPrefillEnabled } = options;

    const relationshipsBaseFormConfig = {
        linkOptionIds: {
            fieldName: fields.NAME_NAME_LINK_LINK_TYPE_ID,
        },
        description: {
            fieldName: fields.NAME_NAME_LINK_DESCRIPTION,
        },
    };

    const relationshipsNItemsFormConfig = isRelationshipsPrefillEnabled
        ? {
              relationshipLinkTypes: createNItems({
                  fieldName: fields.RELATIONSHIP_LINK_TYPES_N_ITEMS_WRAPPER,
                  fields: {
                      displayLinkOptionIds: {},
                      effectiveFrom: {
                          fieldName: fields.NAME_NAME_LINK_DATE_EFFECTIVE_FROM,
                      },
                      effectiveTo: {
                          fieldName: fields.NAME_NAME_LINK_DATE_EFFECTIVE_TO,
                      },
                      ...relationshipsBaseFormConfig,
                  },
              }),
          }
        : relationshipsBaseFormConfig;

    const relationshipsFormConfig = {
        name: relationshipsFormName,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            relationshipsFormName,
            formatFieldByName
        ),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            // Hacky way to force the UI to rerender
            forceRender: {},
            relationships: createNItems({
                fields: {
                    nameId: {},
                    otherNameId: {},
                    nameEntityType: {},
                    otherNameEntityType: {},
                    displayLinkOptionIds: {},
                    ...relationshipsNItemsFormConfig,
                },
            }),
        }),
    };
    return new _Form(relationshipsFormConfig);
};

export const buildRelationshipsFormModel = () => (dispatch, getState) => {
    const state = getState();
    const nameNameLinkTypeViewModels = nameNameLinkTypeViewModelsSelector(state);
    const relationshipsData = relationshipsDataSelector(state);
    const applicationSettings = applicationSettingsSelector(state);
    return {
        ...convertToFormModel({
            relationshipsData,
            nameNameLinkTypeViewModels,
            isRelationshipsPrefillEnabled: applicationSettings.RMS_RELATIONSHIP_PREFILL_ENABLED,
        }),
        // This is needed because the UI can update
        // before the form is updated.  When this happens,
        // the UI will update w/o the most updated form data.
        //
        // In `RelationshipsCardForm`, we observe this
        // `forceRender` field so that we can force a rerender
        // whenever the form is manually refreshed
        // via this method, `buildRelationshipsFormModel`.
        //
        // This way, the UI can display the most updated form
        // information.
        forceRender: Math.random(),
    };
};

// TODO: Convert `relationshipsData` to the form model for the Relationships card
export function convertToFormModel({
    relationshipsData,
    nameNameLinkTypeViewModels,
    isRelationshipsPrefillEnabled,
}) {
    const { personProfiles, organizationProfiles, nameNameLinks } = relationshipsData;

    const personsInRelationships = map(personProfiles, (profile) => {
        return { nameId: profile.id, nameEntityType: EntityTypeEnum.PERSON_PROFILE.name };
    });

    const organizationsInRelationships = map(organizationProfiles, (profile) => {
        return { nameId: profile.id, nameEntityType: EntityTypeEnum.ORGANIZATION_PROFILE.name };
    });

    const profilesInRelationships = [...personsInRelationships, ...organizationsInRelationships];

    // relationships get added last because each relationship state depends on
    // all names
    const relationships = flatMap(profilesInRelationships, (profile) =>
        map(
            convertNameNameLinksDataStateToFormState(
                nameNameLinks,
                map(profilesInRelationships, ({ nameId, nameEntityType }) => ({
                    nameId,
                    nameEntityType,
                })),
                profile.nameId,
                nameNameLinkTypeViewModels,
                isRelationshipsPrefillEnabled
            ),
            (nameNameLinkData) => ({ ...nameNameLinkData, ...profile })
        )
    );

    // At this point, we have both the forward and reverse relationships
    // for all profiles. E.g. we have
    // {
    //      otherNameId: 2,
    //      otherNameEntityType: 'B',
    //      linkOptionIds: ['10-linkNameOne', '11', '12-linkNameTwo'],
    //      description: undefined,
    //      nameId: 1,
    //      nameEntityType: 'A'
    // },
    //
    // and we also have
    // {
    //      otherNameId: 1,
    //      otherNameEntityType: 'A',
    //      linkOptionIds: ['10-linkNameTwo', '11', '12-linkNameOne'],
    //      description: undefined,
    //      nameId: 2,
    //      nameEntityType: 'B'
    // },
    //
    // Since we don't want the duplicates, we assign a 'primaryKey'
    // and `reverseKey` to each relationship, and then filter
    // out relationships that have a key that we've already seen
    const keysToIgnore = {};
    const filteredAndTransformedRelationships = compact(
        map(relationships, (relationship) => {
            const {
                nameId,
                nameEntityType,
                otherNameId,
                otherNameEntityType,
                linkOptionIds,
            } = relationship;
            const primaryKey = `${nameId}.${nameEntityType}.${otherNameId}.${otherNameEntityType}`;
            const reverseKey = `${otherNameId}.${otherNameEntityType}.${nameId}.${nameEntityType}`;
            if (keysToIgnore[primaryKey]) {
                return undefined;
            } else {
                keysToIgnore[reverseKey] = true;
                return {
                    ...relationship,
                    displayLinkOptionIds: linkOptionIds,
                    linkOptionIds: map(linkOptionIds, (linkOptionId) => parseInt(linkOptionId, 10)),
                };
            }
        })
    );

    return { relationships: filteredAndTransformedRelationships };
}

const mergeRelationshipsFormModelWithExistingRelationshipsFormState = (
    relationshipsFormModel,
    existingFormState
) => {
    // Go through the new `relationshipsFormModel`
    // and compare it against the `existingFormState`
    //
    // If any data from `existingFormState` already exists
    // then use it instead of the new data
    return {
        ...relationshipsFormModel,
        relationships: map(relationshipsFormModel.relationships, (relationship) => {
            const { nameId, nameEntityType, otherNameId, otherNameEntityType } = relationship;
            const existingFormStateForRelationship = find(existingFormState.relationships, {
                nameId,
                nameEntityType,
                otherNameId,
                otherNameEntityType,
            });
            return existingFormStateForRelationship || relationship;
        }),
    };
};

export const refreshRelationshipsForm = () => (dispatch) => {
    formsRegistry.maybeDeferredOperation(relationshipsFormName, 0, (form) => {
        const relationshipsFormModel = dispatch(buildRelationshipsFormModel());
        const mergedRelationshipsFormModel = mergeRelationshipsFormModelWithExistingRelationshipsFormState(
            relationshipsFormModel,
            form.getState().model
        );
        form.set('', mergedRelationshipsFormModel);
    });
};

export const convertFromFormModel = (
    formModel,
    { personProfiles, organizationProfiles } = {},
    isRelationshipsPrefillEnabled
) => {
    const nameNameLinks = isRelationshipsPrefillEnabled
        ? reduce(
              formModel.relationships,
              (
                  acc,
                  {
                      nameEntityType,
                      nameId,
                      otherNameEntityType,
                      otherNameId,
                      relationshipLinkTypes = [],
                  }
              ) => {
                  const result = chain(relationshipLinkTypes)
                      // Note because we add an empty NItem, we need to filter for when
                      // the displayLinkOptionIds hsa a value
                      .filter((item) => !!item.displayLinkOptionIds)
                      .map(({ displayLinkOptionIds, description, effectiveFrom, effectiveTo }) => {
                          return {
                              ...buildNameNameLinkDataState(
                                  displayLinkOptionIds,
                                  nameId,
                                  nameEntityType,
                                  otherNameId,
                                  otherNameEntityType,
                                  description,
                                  effectiveFrom,
                                  effectiveTo
                              ),
                          };
                      })
                      .uniqBy(({ linkTypeId, nameFromId, nameToId }) => {
                          // arbitrary string that's unique for the arguments
                          return `${linkTypeId}-${nameFromId}-${nameToId}`;
                      })
                      .value();

                  return [...acc, ...result];
              },
              []
          )
        : uniqBy(
              flatMap(
                  formModel.relationships,
                  ({
                      nameId,
                      otherNameId,
                      nameEntityType,
                      otherNameEntityType,
                      displayLinkOptionIds,
                      description,
                  }) => {
                      return map(displayLinkOptionIds, (linkOptionId) => {
                          return buildNameNameLinkDataState(
                              linkOptionId,
                              nameId,
                              nameEntityType,
                              otherNameId,
                              otherNameEntityType,
                              description
                          );
                      });
                  }
              ),
              ({ linkTypeId, nameFromId, nameToId }) => {
                  // arbitrary string that's unique for the arguments
                  return `${linkTypeId}-${nameFromId}-${nameToId}`;
              }
          );

    return {
        personProfiles,
        organizationProfiles,
        nameNameLinks,
    };
};

export const prefillRelationshipForm = (
    prefillData,
    relationshipLinkTypesPath,
    relationshipIndex
) => (dispatch, getState, { formsRegistry }) => {
    const form = formsRegistry.get(relationshipsFormName);
    const relationships = form.get('relationships');

    const relationshipLinkTypes = form.get(relationshipLinkTypesPath);
    const updatedRelationshipLinkTypes = [...relationshipLinkTypes];
    const relationshipLinkTypesIndexToUpdate = relationshipLinkTypes.findIndex(
        ({ effectiveTo, displayLinkOptionIds, description }) =>
            !effectiveTo && !displayLinkOptionIds && !description
    );
    if (relationshipLinkTypesIndexToUpdate === -1) {
        updatedRelationshipLinkTypes.push(prefillData);
    } else {
        updatedRelationshipLinkTypes[relationshipLinkTypesIndexToUpdate] = prefillData;
    }

    relationships[relationshipIndex] = {
        ...relationships[relationshipIndex],
        relationshipLinkTypes: updatedRelationshipLinkTypes,
    };

    form.set('', {
        forceRender: Math.random(),
        relationships,
    });
};

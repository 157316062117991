import React, { useContext } from 'react';
import {
    scrollableNodeNameEnum,
    ScrollableNodesContext,
} from '../../../modules/core/context/ScrollableNodesContext';

/**
 * This component is mounted only once, when the app is loaded.
 */
export default function AppContent({ children }) {
    const nodeRefs = useContext(ScrollableNodesContext);

    return (
        <div className="mark43-content" ref={nodeRefs[scrollableNodeNameEnum.APP_CONTENT]}>
            <div className="page-bucket mark43-container">{children}</div>
        </div>
    );
}

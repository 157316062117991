import { uniq } from 'lodash';
import type { ReportIdentifierView } from '@mark43/rms-api';

import { isRecordWithRen } from './isRecordWithoutRen';

export interface ReportIdentifier {
    id: number;
    recordNumber?: string;
    reportingEventNumber?: string;
}

export default function buildReportIdentifierView(
    reports: ReportIdentifier[]
): ReportIdentifierView {
    const reportIdentifierView = reports.reduce<ReportIdentifierView>(
        (view, report) => {
            const { reportRENs, recordWithoutRENReportIds } = view;
            const { id, reportingEventNumber } = report;
            if (isRecordWithRen(report)) {
                return {
                    reportRENs: uniq([
                        ...reportRENs,
                        ...(reportingEventNumber ? [reportingEventNumber] : []),
                    ]),
                    recordWithoutRENReportIds,
                };
            } else {
                return {
                    reportRENs,
                    recordWithoutRENReportIds: uniq([...recordWithoutRENReportIds, id]),
                };
            }
        },
        {
            reportRENs: [],
            recordWithoutRENReportIds: [],
        }
    );
    return reportIdentifierView;
}

import { useSelector } from 'react-redux';
import {
    currentUserDepartmentCountryCodeSelector,
    currentDepartmentDateFormatsSelector,
    currentDepartmentDateFormatterSelector,
} from '~/client-common/core/domain/current-user/state/ui';
import {
    DateTimeFormats,
    DateTimeFormatter,
    getLocationSpecificAcceptedDateTimeFormats,
} from '~/client-common/core/dates/utils/dateHelpers';

export function useAcceptedDateFormats(): string[] {
    const countryCode = useSelector(currentUserDepartmentCountryCodeSelector);
    return getLocationSpecificAcceptedDateTimeFormats(countryCode);
}

export function useDateTimeFormats(): DateTimeFormats {
    const dateFormats = useSelector(currentDepartmentDateFormatsSelector);
    return dateFormats;
}

export function useDateTimeFormatter(): DateTimeFormatter {
    const dateFormatter = useSelector(currentDepartmentDateFormatterSelector);
    return dateFormatter;
}

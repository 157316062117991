// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
let resource;

const BASE_URL = '/evidence/api';
const BASE_PATH = 'evidence/print';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Printing Printables Resource',
        methods: {
            /**
             * Get printables for evidence.
             * @param  {number[]} [itemIds] Can be master or contexted.
             * @param  {number}   [reportId]
             * @param  {string[]} [printingDataTypes]
             * @return {Promise<Object[]>}
             */
            getEvidencePrintables({ itemIds, reportId, printingDataTypes }) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/printables/batch`,
                    data: {
                        itemIds,
                        reportId,
                        printingDataTypes,
                    },
                });
            },
        },
    });
}

/**
 * Resources for locations. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

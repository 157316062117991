import React from 'react';
import towVehicleImpoundForm from '../../state/forms/towVehicleImpoundForm';
import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import Row from '../../../../core/components/Row';
import { RRFDatePicker } from '../../../../core/forms/components/DatePicker';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { RRFText } from '../../../../core/forms/components/Text';
import { RRFBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { RRFCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import IndentedFields from '../../../../core/components/IndentedFields';
import { dateTimePickerTotalWidth } from '../../../../../legacy-redux/configs/formsConfig';

export default function TowVehicleImpoundCardForm() {
    return (
        <ReactReduxForm {...towVehicleImpoundForm}>
            <CardSection>
                <Row>
                    <RRFDatePicker
                        path="nicNumberOriginalDateUtc"
                        width={dateTimePickerTotalWidth}
                        includeTime={true}
                    />
                    <RRFText path="nicNumberOriginal" length="md" />
                </Row>
                <IndentedFields>
                    <Row>
                        <RRFBooleanSelect path="nicNumberOriginalIsLocal" length="md" />
                    </Row>
                </IndentedFields>
                <Row>
                    <RRFDatePicker
                        path="nicNumberCancellationDateUtc"
                        width={dateTimePickerTotalWidth}
                        includeTime={true}
                    />
                    <RRFText path="nicNumberCancellation" length="md" />
                </Row>
                <IndentedFields>
                    <Row>
                        <RRFBooleanSelect path="nicNumberCancellationIsLocal" length="md" />
                    </Row>
                </IndentedFields>
                <Row>
                    <RRFCheckbox path="keysInVehicle" length="md" />
                </Row>
                <Row>
                    <RRFCheckbox path="vehicleLocked" length="md" />
                </Row>
            </CardSection>
        </ReactReduxForm>
    );
}

import Promise from 'bluebird';
import {
    Attribute,
    AttributeCode,
    AttributeTypeEnumType,
    CodeTypeSourceEnumType,
    LinkTypesEnum,
    AttributesResource,
    AttributeTypeResponse,
} from '@mark43/rms-api';
import { GetCodesAndMappingsForAttributeType } from '@mark43/rms-api/dist/resources/AttributesResource';
import httpHeaderEnum from '~/client-common/core/enums/client/httpHeaderEnum';
import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Attributes Admin Resource',
    methods: {
        getAllAttributes() {
            return req({
                method: 'GET',
                url: 'attributes',
                params: {
                    include_is_none: true,
                    include_out_of_date: true,
                    include_not_started: true,
                    include_disabled: true,
                },
            });
        },
        /* eslint-disable-next-line @typescript-eslint/no-inferrable-types */
        getAllCodes(override401Handler: boolean = false) {
            return req({
                method: 'GET',
                url: 'attributes/department?offense_nav=true',
                override401Handler,
                headers: {
                    [httpHeaderEnum.X_ENABLE_ETAG_CACHE]: true,
                },
            });
        },
        getAttributeTypes() {
            return req({
                method: 'GET',
                url: 'attributes/filtered',
            });
        },

        /**
         * Get code, links, and mappings by attribute type.
         * @param   attributeType
         * @return {Promise<AttributeTypeResponse>} Object with 3 properties: `codes`, `links`,
         *   `mappings`.
         */
        getAttributeTypeMappings(
            attributeType: AttributeTypeEnumType
        ): Promise<AttributeTypeResponse> {
            // string
            return req<GetCodesAndMappingsForAttributeType>({
                method: 'GET',
                url: 'attributes/codes/links',
                params: { attributeType },
            });
        },
        getAttributeTypeHistory(attributeType) {
            return req({
                method: 'GET',
                url: `attributes/histories/${attributeType}`,
            });
        },
        createAttribute(attribute: Attribute, linkType?: keyof typeof LinkTypesEnum) {
            return req({
                method: 'POST',
                url: linkType ? `attributes?link_type=${linkType}` : 'attributes',
                data: attribute,
            });
        },
        updateAttribute(attribute: Attribute, linkType?: keyof typeof LinkTypesEnum) {
            return req({
                method: 'PUT',
                url: linkType
                    ? `attributes/${attribute.id}?link_type=${linkType}`
                    : `attributes/${attribute.id}`,
                data: attribute,
            });
        },

        /**
         * Update the code links for the given attribute. Despite the url being
         *   `/codes` and not `/codes/links`, we are updating the links.
         * @return The updated code links.
         */
        updateAttributeCodeLinks(attribute: Attribute, codeLinks: AttributeCode[]) {
            if (codeLinks.length === 0) {
                return Promise.resolve(codeLinks);
            }

            return req({
                method: 'PUT',
                url: `attributes/${attribute.id}/codes`,
                data: codeLinks,
            });
        },

        /**
         * Export all attributes into a CSV.
         */
        exportAttributes() {
            return req({
                method: 'GET',
                url: 'attributes/export',
            });
        },

        /**
         * Validate that a file is valid for importing new attributes.
         */
        validateAttributeImport(fileId: number) {
            return req({
                method: 'POST',
                url: `attributes/import/validate/${fileId}`,
            });
        },

        /**
         * Import attributes from an uploaded file.
         */
        importAttributes(fileId: number) {
            return req({
                method: 'POST',
                url: `attributes/import/${fileId}`,
            });
        },

        getCodeTypes() {
            return req({
                method: 'GET',
                url: 'attributes/code_types',
            });
        },

        getCodesByCodeTypeId(codeTypeId: number) {
            return req<AttributesResource.GetCodes>({
                method: 'GET',
                url: 'attributes/codes',
                params: {
                    code_type_id: codeTypeId,
                },
            });
        },

        replaceCodes(codeTypeSource: CodeTypeSourceEnumType, codes) {
            return req<AttributesResource.ReplaceCodesForSource>({
                method: 'PUT',
                url: 'attributes/codes/replace',
                params: {
                    source: codeTypeSource,
                },
                data: codes,
            });
        },
    },
});

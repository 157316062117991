import React, { createContext, useState } from 'react';
import { KebabSources, KebabMenuActions } from '../components/KebabMenu';

type KebabSourcesContextProps = {
    source?: KebabSources;
    setSource?: React.Dispatch<React.SetStateAction<KebabSources | undefined>>;
    initiatedAction?: KebabMenuActions;
    setInitiatedAction?: React.Dispatch<React.SetStateAction<KebabMenuActions | undefined>>;
};

export const KebabMenuContext = createContext<KebabSourcesContextProps>({});

export const KebabMenuContextProvider: React.FC = ({ children }) => {
    const [kebabMenuSource, setKebabMenuSource] = useState<KebabSources>();
    const [kebabMenuInitiatedAction, setKebabMenuInitiatedAction] = useState<KebabMenuActions>();

    return (
        <KebabMenuContext.Provider
            value={{
                source: kebabMenuSource,
                setSource: setKebabMenuSource,
                initiatedAction: kebabMenuInitiatedAction,
                setInitiatedAction: setKebabMenuInitiatedAction,
            }}
        >
            {children}
        </KebabMenuContext.Provider>
    );
};

import { omitBy, get, find, forEach, first, size, isArray, isUndefined, has } from 'lodash';
import { ElasticReportQuery, ElasticReportDefinitionQuery } from '@mark43/rms-api';

function getOffenseCaseStatusAttrIdsFromOffenseAndIncidentsReportDefinition(
    reportDefinition: ElasticReportDefinitionQuery | undefined
) {
    const offenseAndIncident = first(reportDefinition?.offensesAndIncidents);

    const offenseCaseStatusAttrId = get(offenseAndIncident, 'offenseCaseStatusAttrId');
    const offenseCaseStatusAttrIds = get(offenseAndIncident, 'offenseCaseStatusAttrIds');

    if (!!size(offenseCaseStatusAttrIds)) {
        return offenseCaseStatusAttrIds;
    }

    return isArray(offenseCaseStatusAttrId) ? offenseCaseStatusAttrId : [offenseCaseStatusAttrId];
}

export function parseOffenseCaseStatusesFromReportDefinitions(elasticQuery: ElasticReportQuery) {
    const offenseCaseStatusReportDefinition = find(
        elasticQuery?.reportDefinitions,
        (reportDefinition) => {
            return !!size(
                getOffenseCaseStatusAttrIdsFromOffenseAndIncidentsReportDefinition(reportDefinition)
            );
        }
    );
    return getOffenseCaseStatusAttrIdsFromOffenseAndIncidentsReportDefinition(
        offenseCaseStatusReportDefinition
    );
}

export function parseOffenseCaseStatusFromReportDefinitions(elasticQuery: ElasticReportQuery) {
    return first(parseOffenseCaseStatusesFromReportDefinitions(elasticQuery));
}

export function parseHasOffenseCaseStatusFromReportDefinitions(elasticQuery: ElasticReportQuery) {
    const hasOffenseCaseStatusReportDefinition = find(
        elasticQuery?.reportDefinitions,
        (reportDefinition) =>
            has(first(reportDefinition?.offensesAndIncidents), 'hasOffenseCaseStatus')
    );
    return get(
        first(hasOffenseCaseStatusReportDefinition?.offensesAndIncidents),
        'hasOffenseCaseStatus'
    );
}

export function parseAdditionalFieldsFromReportDefinitions(elasticQuery: ElasticReportQuery) {
    let additionalFields = {};
    const reportDefinitions = elasticQuery?.reportDefinitions;

    forEach(reportDefinitions, (reportDefinition) => {
        const hasArrest = get(reportDefinition, 'hasLinkedArrests');
        if (hasArrest) {
            additionalFields = {
                ...additionalFields,
                hasArrest,
            };
        }

        const offensesAndIncidents = reportDefinition?.offensesAndIncidents;
        forEach(offensesAndIncidents, (offenseAndIncident) => {
            const isDomesticViolence = get(offenseAndIncident, 'isDomesticViolence');
            const isSuspectedHateCrime = get(offenseAndIncident, 'isSuspectedHateCrime');
            const offenseCodeIds = get(offenseAndIncident, 'offenseCodeIds');
            const offenseClassificationAttrIds = get(
                offenseAndIncident,
                'offenseClassificationAttrIds'
            );
            additionalFields = {
                ...additionalFields,
                isDomesticViolence,
                isSuspectedHateCrime,
                offenseCodeIds,
                offenseClassificationAttrIds,
            };
        });
    });
    return omitBy(additionalFields, isUndefined);
}

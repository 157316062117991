import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { concat, filter, findIndex, get, isEmpty, map, omit } from 'lodash';

import { createFormConfiguration, createNItems, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import entityTypeObjectEnum from '~/client-common/core/enums/universal/entityTypeObjectEnum';

import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { hydratedCommunityInfoByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/communityInfos';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import buildLocationEntityLinkKey from '~/client-common/core/domain/location-entity-links/utils/buildLocationEntityLinkKey';
import sortLocationEntityLinks from '~/client-common/core/domain/location-entity-links/utils/sortLocationEntityLinks';
import sortNameReportLinks from '~/client-common/core/domain/name-report-links/utils/sortNameReportLinks';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_COMMUNITY_INFO.name;
export const COMMUNITY_INFO_SUBJECTS_PATH = 'communityInfoSubjects';
export const COMMUNITY_INFO_SUBJECTS_ENTITY_TYPE_ID_PATH = 'entityTypeId';
export const COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_ATTR_ID_PATH = 'subjectTypeAttrId';
export const COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_OTHER_PATH = 'subjectTypeOther';
export const COMMUNITY_INFO_SUBJECTS_WAS_FRISKED_PATH = 'wasFrisked';
export const COMMUNITY_INFO_ORGANIZATIONS_PATH = 'communityInfoOrganizations';
export const COMMUNITY_INFO_LOCATIONS_PATH = 'communityInfoLocations';
export const COMMUNITY_INFO_LOCATION_DESCRIPTION_PATH = 'description';
export const COMMUNITY_INFO_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const getCommunityInfoForm = () => formsRegistry.get(formName);

export const createCommunityInfoForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            communityInformationObtainedFromAttrId: {
                fieldName: fields.FIELD_CONTACT_COMMUNITY_INFORMATION_OBTAINED_FROM_ATTR_ID,
            },
            communityInformationObtainedFromOther: {
                fieldName: fields.FIELD_CONTACT_COMMUNITY_INFORMATION_OBTAINED_FROM_OTHER,
            },
            communityInformationReasonForReportAttrIds: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_REASON_FOR_REPORT_ATTRIBUTE_ID,
            },
            communityInformationReasonForReportDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_REASON_FOR_REPORT_DESCRIPTION,
            },
            communityInformationDispositionAttrIds: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_DISPOSITION_ATTRIBUTE_ID,
            },
            communityInformationDispositionDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_DISPOSITION_DESCRIPTION,
            },
            communityInformationStatisticsAttrIds: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_STATISTICS_ATTRIBUTE_ID,
            },
            communityInformationStatisticsDescription: {
                fieldName:
                    fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_COMMUNITY_INFORMATION_STATISTICS_DESCRIPTION,
            },
            [COMMUNITY_INFO_SUBJECTS_PATH]: createNItems({
                fields: {
                    nameId: {},
                    // fields used for validation
                    id: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_ID,
                    },
                    linkType: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_LINK_TYPE,
                    },
                    // custom field used for hide/show logic, namely, that we do not display `wasFrisked`
                    // for Organizations, but we do display the input for Persons.
                    [COMMUNITY_INFO_SUBJECTS_ENTITY_TYPE_ID_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_ENTITY_TYPE,
                    },
                    // fields in UI
                    [COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_ATTR_ID_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_SUBJECT_TYPE_ATTR_ID,
                    },
                    [COMMUNITY_INFO_SUBJECTS_SUBJECT_TYPE_OTHER_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_SUBJECT_TYPE_OTHER,
                    },
                    [COMMUNITY_INFO_SUBJECTS_WAS_FRISKED_PATH]: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_COMMUNITY_INFORMATION_WAS_FRISKED,
                    },
                },
            }),
            [COMMUNITY_INFO_ORGANIZATIONS_PATH]: createNItems({
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_ORGANIZATION_IN_FIELD_CONTACT_LINK_TYPE,
                fields: {
                    nameId: {},
                    linkType: {
                        fieldName:
                            fields.NAME_REPORT_LINK_LINK_TYPE_ORGANIZATION_IN_FIELD_CONTACT_LINK_TYPE,
                    },
                    // Organizations do not allow for `Subject Type`s.
                },
            }),
            [COMMUNITY_INFO_LOCATIONS_PATH]: createNItems({
                fieldName:
                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_LOCATION_ID,
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_LOCATION_ID,
                    },
                    [COMMUNITY_INFO_LOCATION_POSITION_ATTR_ID_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_POSITION_ATTR_ID,
                    },
                    [COMMUNITY_INFO_LOCATION_DESCRIPTION_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_FIELD_CONTACT_DESCRIPTION,
                    },
                },
            }),
        }),
    });
};

const buildReportAttributeFormModelObject = ({ reportAttributes, attributeType }) => {
    const filteredReportAttributes = filter(reportAttributes, { attributeType });
    const attributeIds = map(filteredReportAttributes, 'attributeId');
    const description = getDescriptionForAttributeLinks(filteredReportAttributes);
    return {
        attributeIds,
        description,
    };
};

const buildDefaultCommunityInfoSubjectFormModel = () => {
    return {
        nameId: undefined,
        id: undefined,
        linkType: undefined,
    };
};

const filterDefaultCommunityInfoSubjects = ({ communityInfoSubjects }) => {
    return filter(communityInfoSubjects, (communityInfoSubject) => {
        const { nameId, id, linkType } = communityInfoSubject;
        const isDefaultCommunityInfoSubject =
            isUndefinedOrNull(nameId) && isUndefinedOrNull(id) && isUndefinedOrNull(linkType);
        return !isDefaultCommunityInfoSubject;
    });
};

export const removeDefaultCommunityInfoSubjectsFromFormModel = () => {
    const form = getCommunityInfoForm();
    const communityInfoSubjects = form.get(COMMUNITY_INFO_SUBJECTS_PATH);
    const communityInfoSubjectsToKeep = filterDefaultCommunityInfoSubjects({
        communityInfoSubjects,
    });
    const sortedCommunityInfoSubjectsToKeep = sortNameReportLinks({
        nameReportLinks: communityInfoSubjectsToKeep,
    });
    form.set(COMMUNITY_INFO_SUBJECTS_PATH, sortedCommunityInfoSubjectsToKeep);
};

export const maybeAddDefaultCommunityInfoSubjectToFormModel = () => {
    const form = getCommunityInfoForm();
    const existingCommunityInfoSubjects = form.get(COMMUNITY_INFO_SUBJECTS_PATH);
    if (isEmpty(existingCommunityInfoSubjects)) {
        form.set(COMMUNITY_INFO_SUBJECTS_PATH, [buildDefaultCommunityInfoSubjectFormModel()]);
    }
};

const buildDefaultCommunityInfoLocationFormModel = () => {
    return { locationId: undefined };
};

const filterDefaultCommunityInfoLocations = ({ communityInfoLocations }) => {
    return filter(communityInfoLocations, (communityInfoLocation) => {
        const { locationId } = communityInfoLocation;
        const isDefaultCommunityInfoLocation = isUndefinedOrNull(locationId);
        return !isDefaultCommunityInfoLocation;
    });
};

export const removeDefaultCommunityInfoLocationsFromFormModel = () => {
    const form = getCommunityInfoForm();
    const communityInfoLocations = form.get(COMMUNITY_INFO_LOCATIONS_PATH);
    const communityInfoLocationsToKeep = filterDefaultCommunityInfoLocations({
        communityInfoLocations,
    });
    const sortedCommunityInfoLocationsToKeep = sortLocationEntityLinks({
        locationEntityLinks: communityInfoLocationsToKeep,
    });
    form.set(COMMUNITY_INFO_LOCATIONS_PATH, sortedCommunityInfoLocationsToKeep);
};

export const maybeAddDefaultCommunityInfoLocationToFormModel = () => {
    const form = getCommunityInfoForm();
    const existingCommunityInfoLocations = form.get(COMMUNITY_INFO_LOCATIONS_PATH);
    if (isEmpty(existingCommunityInfoLocations)) {
        form.set(COMMUNITY_INFO_LOCATIONS_PATH, [buildDefaultCommunityInfoLocationFormModel()]);
    }
};

export const buildCommunityInfoCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const hydratedFieldContact = hydratedCommunityInfoByReportIdSelector(state)(reportId);
    const {
        fieldContact: {
            id: fieldContactId,
            communityInformationObtainedFromAttrId,
            communityInformationObtainedFromOther,
        },
        reportAttributes,
        communityInfoSubjects,
        communityInfoOrganizations,
        communityInfoLocations,
    } = hydratedFieldContact;

    const {
        attributeIds: communityInformationReasonForReportAttrIds,
        description: communityInformationReasonForReportDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.COMMUNITY_INFORMATION_REASON_FOR_REPORT.name,
    });
    const {
        attributeIds: communityInformationDispositionAttrIds,
        description: communityInformationDispositionDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.COMMUNITY_INFORMATION_DISPOSITION.name,
    });
    const {
        attributeIds: communityInformationStatisticsAttrIds,
        description: communityInformationStatisticsDescription,
    } = buildReportAttributeFormModelObject({
        reportAttributes,
        attributeType: AttributeTypeEnum.COMMUNITY_INFORMATION_STATISTICS.name,
    });

    const initialCommunityInfoSubjects = isEmpty(communityInfoSubjects)
        ? [buildDefaultCommunityInfoSubjectFormModel()]
        : communityInfoSubjects;
    const communityInfoSubjectsWithEntityTypeIds = map(
        initialCommunityInfoSubjects,
        (initialCommunityInfoSubject) => {
            const { entityType } = initialCommunityInfoSubject;
            const entityTypeId = get(entityTypeObjectEnum, `${entityType}.value`);
            return {
                ...initialCommunityInfoSubject,
                [COMMUNITY_INFO_SUBJECTS_ENTITY_TYPE_ID_PATH]: entityTypeId,
            };
        }
    );

    const initialCommunityInfoLocations = isEmpty(communityInfoLocations)
        ? [buildDefaultCommunityInfoLocationFormModel()]
        : communityInfoLocations;

    return {
        id: fieldContactId,
        communityInformationObtainedFromAttrId,
        communityInformationObtainedFromOther,
        communityInformationReasonForReportAttrIds,
        communityInformationReasonForReportDescription,
        communityInformationDispositionAttrIds,
        communityInformationDispositionDescription,
        communityInformationStatisticsAttrIds,
        communityInformationStatisticsDescription,
        [COMMUNITY_INFO_SUBJECTS_PATH]: communityInfoSubjectsWithEntityTypeIds,
        [COMMUNITY_INFO_ORGANIZATIONS_PATH]: communityInfoOrganizations,
        [COMMUNITY_INFO_LOCATIONS_PATH]: initialCommunityInfoLocations,
    };
};

const convertReportAttributeFormModelToDataModel = ({
    attributes,
    reportId,
    attributeTypeName,
    attributeIds,
    description,
}) => {
    return assignDescriptionToReportAttributes(
        map(attributeIds, (attributeId) => {
            return {
                reportId,
                attributeType: attributeTypeName,
                attributeId,
            };
        }),
        attributes,
        description
    );
};

export const buildCommunityInfoCardBundle = ({ reportId, form }) => (dispatch, getState) => {
    const state = getState();
    const attributes = attributesSelector(state);
    const { model } = form.getState();
    const {
        id: fieldContactId,
        communityInformationObtainedFromAttrId,
        communityInformationObtainedFromOther,
        communityInformationReasonForReportAttrIds,
        communityInformationReasonForReportDescription,
        communityInformationDispositionAttrIds,
        communityInformationDispositionDescription,
        communityInformationStatisticsAttrIds,
        communityInformationStatisticsDescription,
        [COMMUNITY_INFO_SUBJECTS_PATH]: communityInfoSubjects,
        [COMMUNITY_INFO_LOCATIONS_PATH]: communityInfoLocations,
    } = model;

    const fieldContactForBundle = {
        id: fieldContactId,
        reportId,
        communityInformationObtainedFromAttrId,
        communityInformationObtainedFromOther,
    };

    const communityInformationReasonForReportReportAttributes = convertReportAttributeFormModelToDataModel(
        {
            attributes,
            reportId,
            attributeTypeName: AttributeTypeEnum.COMMUNITY_INFORMATION_REASON_FOR_REPORT.name,
            attributeIds: communityInformationReasonForReportAttrIds,
            description: communityInformationReasonForReportDescription,
        }
    );
    const communityInformationDispositionReportAttributes = convertReportAttributeFormModelToDataModel(
        {
            attributes,
            reportId,
            attributeTypeName: AttributeTypeEnum.COMMUNITY_INFORMATION_DISPOSITION.name,
            attributeIds: communityInformationDispositionAttrIds,
            description: communityInformationDispositionDescription,
        }
    );
    const communityInformationStatisticsReportAttributes = convertReportAttributeFormModelToDataModel(
        {
            attributes,
            reportId,
            attributeTypeName: AttributeTypeEnum.COMMUNITY_INFORMATION_STATISTICS.name,
            attributeIds: communityInformationStatisticsAttrIds,
            description: communityInformationStatisticsDescription,
        }
    );
    const reportAttributesForBundle = concat(
        [],
        communityInformationReasonForReportReportAttributes,
        communityInformationDispositionReportAttributes,
        communityInformationStatisticsReportAttributes
    );

    const communityInfoSubjectsWithoutDefaultSubjects = filterDefaultCommunityInfoSubjects({
        communityInfoSubjects,
    });
    const nameReportLinksForBundle = map(
        communityInfoSubjectsWithoutDefaultSubjects,
        (communityInfoSubjectsWithoutDefaultSubject) =>
            omit(
                communityInfoSubjectsWithoutDefaultSubject,
                COMMUNITY_INFO_SUBJECTS_ENTITY_TYPE_ID_PATH
            )
    );

    const communityInfoLocationsWithoutDefaultLocations = filterDefaultCommunityInfoLocations({
        communityInfoLocations,
    });

    return {
        fieldContact: fieldContactForBundle,
        reportAttributes: reportAttributesForBundle,
        nameReportLinks: nameReportLinksForBundle,
        locationEntityLinks: communityInfoLocationsWithoutDefaultLocations,
    };
};

export const refreshCommunityInfoForm = ({ reportId }) => (dispatch) => {
    const form = getCommunityInfoForm();
    const formModel = dispatch(buildCommunityInfoCardFormModel({ reportId }));
    form.set('', formModel);
};

export const findIndexOfNItemsNameLink = ({ path, nameId }) => {
    const form = getCommunityInfoForm();
    const {
        model: { [path]: nameLinks },
    } = form.getState();
    return findIndex(nameLinks, { nameId });
};

export const findIndexOfNItemsLocationEntityLink = ({ path, locationEntityLinkKey }) => {
    const form = getCommunityInfoForm();
    const {
        model: { [path]: locationEntityLinks },
    } = form.getState();
    const formLocationEntityLinkKeys = map(locationEntityLinks, buildLocationEntityLinkKey);
    return findIndex(
        formLocationEntityLinkKeys,
        (formLocationEntityLinkKey) => formLocationEntityLinkKey === locationEntityLinkKey
    );
};

import { RefContextEnum } from '@mark43/rms-api';
import { createFormConfiguration, _Form } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import { summaryNarrativeByReportIdSelector } from '~/client-common/core/domain/summary-narratives/state/data';
import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const summaryNarrativeFormName = RefContextEnum.FORM_SUMMARY_NARRATIVE.name;
export const getSummaryNarrativeForm = (reportId) =>
    formsRegistry.get(summaryNarrativeFormName, reportId);

export const createSummaryNarrativeForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: summaryNarrativeFormName,
        onValidate: createArbiterMFTValidationHandler(
            arbiter,
            summaryNarrativeFormName,
            formatFieldByName
        ),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            summaryNarrative: {
                fieldName: fields.SUMMARY_NARRATIVE_SUMMARY_NARRATIVE,
            },
        }),
    });
};

export const buildSummaryNarrativeFormModel = (currentReportId) => (dispatch, getState) => {
    const state = getState();
    const summaryNarrative = summaryNarrativeByReportIdSelector(state)(currentReportId) || {};

    return {
        summaryNarrative: summaryNarrative.summaryNarrative || '',
    };
};

import { isEqual } from 'lodash';
import { SqlSort, TaskView } from '@mark43/rms-api';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataTablePagination } from '../../../search/core/hooks/useDataTablePagination';
import { tasksDashboardSearch } from '../../dashboard/state/ui';
import { useTasksSorts } from './useTasksSorts';

// @ts-expect-error tasksDashboardSearch is not typed yet
const { actionCreators, selectors } = tasksDashboardSearch;
const { resetState, search, setIsInitialSearch } = actionCreators;
const {
    currentResultsSelector,
    currentQuerySelector,
    tableLoadingSelector,
    totalCountSelector,
    initialSearchSelector,
} = selectors;

export const useTasksSearch = <T extends SqlSort>(initialQuery: T) => {
    const dispatch = useDispatch();
    const [query, setQuery] = useState<T>(initialQuery);

    const updateQuery = useCallback((params) => {
        setQuery((prevQuery) => {
            const nextQuery = { ...prevQuery, ...(params || {}) };
            return isEqual(nextQuery, prevQuery) ? prevQuery : nextQuery;
        });
    }, []);

    const { sortKey, sortType } = query;
    const { sort, onColumnSortChange } = useTasksSorts({ sortKey, sortType }, updateQuery);

    const { from, size }: { from: number; size: number } = useSelector(currentQuerySelector);
    const totalSize: number = useSelector(totalCountSelector);
    const pagination = useDataTablePagination(totalSize, { from, size }, updateQuery);

    const results: TaskView[] = useSelector(currentResultsSelector);
    const loading: boolean = useSelector(tableLoadingSelector);
    const initialLoading: boolean = useSelector(initialSearchSelector);

    useEffect(() => {
        dispatch(setIsInitialSearch(true));
        return () => {
            dispatch(resetState());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(search(query));
    }, [dispatch, query]);

    return { results, loading, initialLoading, pagination, sort, onColumnSortChange };
};

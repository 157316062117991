import React from 'react';
import { IconButton } from '../../../../../core/components/IconButton';
import testIds from '../../../../../../core/testIds';

export const SummaryExpandCollapseButton = ({ collapsed, setCollapsed, ...props }) => (
    <IconButton
        size="sm"
        icon={collapsed ? 'Open' : 'Close'}
        variant="ghost"
        onClick={() => setCollapsed(!collapsed)}
        testId={testIds.GENERIC_EXPAND_COLLAPSE_BUTTON}
        {...props}
    />
);

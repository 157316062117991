import React from 'react';
import { DateRange as ArcDateRange, DateValue, RangeValue } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { dateIsValid } from '~/client-common/core/dates/utils/dateHelpers';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import ModalBase, { ModalBaseProps } from '../../overlays/components/ModalBase';
import DatePicker from './DatePicker';

const strings = componentStrings.forms.DateRangePicker;

const modalStyle = { overflow: 'initial' };
export const CustomDateRangeModal = ({
    isOpen,
    includeTime,
    onSave,
    startDateUtcValueInitialValue,
    endDateUtcValueInitialValue,
    onCancel,
    onRequestClose,
    granularity,
}: {
    isOpen: boolean;
    includeTime: boolean;
    onSave: ({
        startDateUtcValue,
        endDateUtcValue,
    }: {
        startDateUtcValue?: string;
        endDateUtcValue?: string;
    }) => void;
    startDateUtcValueInitialValue: string;
    endDateUtcValueInitialValue: string;
    onCancel: ModalBaseProps['onCancel'];
    onRequestClose: ModalBaseProps['onRequestClose'];
    granularity: 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
}) => {
    const [startDateUtcValue, setStartDateUtcValue] = React.useState<string>();
    const [endDateUtcValue, setEndDateUtcValue] = React.useState<string>();
    const [dateRange, setDateRange] = React.useState<RangeValue<DateValue | undefined>>({
        start: undefined,
        end: undefined,
    });
    const [isInvalidDate, setIsInvalidDate] = React.useState<boolean>(false);

    // Whenever the modal is opened, initialize the inputs
    // to the controlled value (and then use component state afterwards)
    React.useEffect(() => {
        if (isOpen) {
            setStartDateUtcValue(startDateUtcValueInitialValue);
            setEndDateUtcValue(endDateUtcValueInitialValue);
        }
    }, [isOpen, startDateUtcValueInitialValue, endDateUtcValueInitialValue]);

    const isValidRange = (range: RangeValue<DateValue | undefined>) => {
        const startDateString = range?.start?.toString() || '';
        const endDateString = range?.end?.toString() || '';
        if (dateIsValid(startDateString) && dateIsValid(endDateString)) {
            setIsInvalidDate(false);
            return { startDateString, endDateString };
        } else {
            setIsInvalidDate(true);
            return { startDateString, endDateString };
        }
    };

    return (
        <FeatureFlagged
            flag="RMS_ARC_DATEPICKER_ENABLED"
            fallback={
                <ModalBase
                    isOpen={isOpen}
                    onRequestClose={onRequestClose}
                    saveModal={() => onSave({ startDateUtcValue, endDateUtcValue })}
                    title={strings.customRange}
                    contentStyle={modalStyle}
                    onCancel={onCancel}
                    shouldCloseOnOverlayClick={true}
                >
                    <DatePicker
                        label={strings.labels.startDateUtc}
                        includeTime={includeTime}
                        value={startDateUtcValue}
                        onChange={setStartDateUtcValue}
                    />
                    <DatePicker
                        label={strings.labels.endDateUtc}
                        includeTime={includeTime}
                        value={endDateUtcValue}
                        onChange={setEndDateUtcValue}
                    />
                </ModalBase>
            }
        >
            <ModalBase
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                saveModal={() => {
                    const validDates = isValidRange(dateRange);
                    return onSave({
                        startDateUtcValue: validDates.startDateString,
                        endDateUtcValue: validDates.endDateString,
                    });
                }}
                saveDisabled={isInvalidDate}
                title={strings.customRange}
                contentStyle={modalStyle}
                onCancel={onCancel}
                shouldCloseOnOverlayClick={true}
            >
                <ArcDateRange
                    // @ts-expect-error date can be undefined
                    value={dateRange}
                    startFormControlProps={{
                        label: `${strings.labels.startDateUtc}`,
                        errorMessage: `${strings.invalidDateRange}`,
                    }}
                    endFormControlProps={{
                        label: `${strings.labels.endDateUtc}`,
                    }}
                    granularity={granularity || 'minute'}
                    onChange={(dates) => {
                        setDateRange(dates);
                        isValidRange(dates);
                    }}
                    isInvalid={isInvalidDate}
                    popoverContentProps={{ style: { zIndex: 2500 } }}
                    hasCalendar
                />
            </ModalBase>
        </FeatureFlagged>
    );
};

import _, { includes } from 'lodash';

import {
    CompleteClientReportView,
    LinkTypesEnum,
    NameReportLink,
    Offense,
    PersonProfile,
} from '@mark43/rms-api';
import { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';

/**
 * Returns whether the defendant in the given arrest report is one of the suspects in the givven offense report.
 */
export default function defendantIsSuspect({
    arrestCompleteClientReportView,
    offenseReportId,
    offenses,
    nameReportLinks,
    personProfiles,
}: {
    arrestCompleteClientReportView: CompleteClientReportView;
    offenseReportId: number;
    offenses: ModuleShape<Offense>;
    nameReportLinks: NameReportLink[];
    personProfiles: ModuleShape<PersonProfile>;
}): boolean {
    const defendantMasterPersonId = _.chain(arrestCompleteClientReportView.personProfiles)
        .find({ id: arrestCompleteClientReportView.arrest?.defendantId })
        .get('masterPersonId')
        .value();

    if (!defendantMasterPersonId) {
        return false;
    }

    const offenseIds = _(offenses).filter({ reportId: offenseReportId }).map('id').value();
    const suspectIds = _(nameReportLinks)
        .filter(
            ({ linkType, reportId, contextId }) =>
                (linkType === LinkTypesEnum.SUSPECT_IN_OFFENSE ||
                    linkType === LinkTypesEnum.OFFENDER_IN_OFFENSE) &&
                reportId === offenseReportId &&
                includes(offenseIds, contextId)
        )
        .map('nameId')
        .value();
    const suspectMasterPersonIds = _(personProfiles)
        .filter(({ id }) => includes(suspectIds, id))
        .map('masterPersonId')
        .value();
    return includes(suspectMasterPersonIds, defendantMasterPersonId);
}

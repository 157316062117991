let resource;

const BASE = 'narrative_guides';

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Narrative Guides Resource',
        methods: {
            /**
             * Get Narrative Guides.
             * @return {Promise<Object[]>}
             */
            getNarrativeGuides() {
                return req({
                    method: 'GET',
                    url: BASE,
                });
            },
            /**
             * Upsert single Narrative Guide.
             * @param {Object} narrativeGuide
             * @return {Promise<Object>}
             */
            upsertNarrativeGuide(narrativeGuide) {
                return req({
                    method: 'POST',
                    url: BASE,
                    data: narrativeGuide,
                });
            },
            /**
             * Delete Single Narrative Guide.
             * @param {number} narrativeGuideId
             * @return {Promise<Object>}
             */
            deleteNarrativeGuide(narrativeGuideId) {
                return req({
                    method: 'DELETE',
                    url: `${BASE}/${narrativeGuideId}`,
                });
            },
        },
    });
}

/**
 * Resources for narrative guides. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { map } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Icon, { iconTypes } from '../../components/Icon';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import testIds from '../../../../core/testIds';

const FilterBody = styled.div`
    column-count: 1;
    text-align: left;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.darkGrey};
    line-height: 15px;
    padding-left: 10px;
    width: 100%;
`;

const FilterItem = styled.div`
    display: inline-block;
    width: 100%;
`;

const ClearFilterButton = styled(Button)`
    position: relative;
    left: 5px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    float: none;
    min-width: 0;
    padding: 0;
    border: none;
    margin-top: 0;

    &:hover {
        border: none;
    }
`;

const FilterText = styled.span`
    word-break: break-word;
`;

function RedactionFilters({ filters, onClearFilter }) {
    return (
        <FilterBody data-test-id={testIds.REDACTION_FILTERS}>
            {map(filters, (filter) => {
                return (
                    <div key={filter.key}>
                        <FilterItem data-test-id={testIds.REDACTION_FILTER_ITEM}>
                            <FilterText>{filter.textToRedactDisplay}</FilterText>
                            <ClearFilterButton
                                testId={testIds.CLEAR_BUTTON}
                                type={buttonTypes.ICON_LINK}
                                iconLeft={
                                    <Icon type={iconTypes.CLOSE_X} size={8} color="darkGrey" />
                                }
                                onClick={() => onClearFilter(filter.key)}
                            />
                        </FilterItem>
                    </div>
                );
            })}
        </FilterBody>
    );
}

export default RedactionFilters;

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import createCard from '../../utils/createCard';

const towVehicleReleaseCard = createCard({
    name: reportCardEnum.RELEASE_INFORMATION.name,
    baseSelector: (state) => state.ui.report.towVehicleReleaseCard,
    anchor: 'tow-vehicle-release-card',
});

export default towVehicleReleaseCard;

import { first, compact, isString, startCase, map, split } from 'lodash';

/**
 * Trim the given string to the given length with ellipses. The ellipses count
 *   towards the length. Spaces and words are not considered.
 */
export function trimToLength(str: string, len: number): string {
    if (isString(str) && str.length >= len) {
        return `${str.substring(0, len - 3)}...`;
    }
    return str;
}

/**
 * Format the given string for displaying to the user. For example,
 *   'TASK_STATUS' becomes 'Task Status'. One problem is words that
 *   should be all caps like 'DUI' become 'Dui'.
 */
export function prettify(str = ''): string {
    return startCase(str.toLowerCase());
}

/**
 * String for displaying last modified info.
 */
export function lastModified(when: string, who: string): string {
    if (!when && !who) {
        return '';
    } else {
        return joinTruthyValues(['Last Modified', when, who && `by ${who}`], ' ');
    }
}

/**
 * String for displaying last modified and synced info.
 */
export function lastModifiedAndSynced(when: string, who: string, synced: string): string {
    if (!when && !who && !synced) {
        return '';
    } else {
        return joinTruthyValues(
            ['Last Modified', when, who && `by ${who}`, synced && `synced ${synced}`],
            ' '
        );
    }
}

/**
 * Convert an array of values into a string of those values joined on a
 *   specified string.
 *
 * @param [values] An array of strings which will have falsey
 *   values filtered out before being joined.
 * @param [joinWith=', '] A string on which to join provided values
 * @return A string of all values in `values` which are truthy,
 *   joined with `joinWith`.
 */
export function joinTruthyValues(
    values: (string | number | boolean | null | undefined)[] = [],
    joinWith = ', '
): string {
    return compact(values).join(joinWith);
}

/**
 * Convert a boolean value into string 'Yes' or 'No'
 */
export function booleanToYesNo(value?: boolean) {
    if (value === true) {
        return 'Yes';
    } else if (value === false) {
        return 'No';
    }
    return undefined;
}

/**
 * Get the initial based on name, ie, A. for Alfred
 */
export function getNameInitial(name?: string, period = false) {
    return name ? `${first(name)}${period ? '.' : ''}`.toUpperCase() : '';
}

/**
 * Splits the provided value into an array of strings, removing falsy values.
 * @param value The string to split
 * @param separator The separator
 * @param shouldTrim Trims the space at the beginning and end of each string when true
 */
export function safeSplit(value: string, separator: string, shouldTrim = true): string[] {
    const values: string[] = compact(split(value, separator));
    if (shouldTrim) {
        return map(values, (str) => str.trim());
    }
    return values;
}

/**
 * convert SCREAMING_SNAKE_CASE to camelCase
 */
export const convertScreamingSnakeToCamelCase = (value: string) =>
    value.toLowerCase().replace(/_./g, (match) => match[1].toUpperCase());

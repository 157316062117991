import { combineReducers } from 'redux';
import { take, some } from 'lodash';
import applicationSettingsReducer from '~/client-common/core/domain/settings/state/data';
import productModulesReducer from '~/client-common/core/domain/product-modules/state/data';

const REDUX_FORM_ACTION_TYPE_REGEX = /redux-form.*/;

function createBasicTakeReducer(takeCount = 100, defaultState = []) {
    return (state = defaultState, action) => take([action.type, ...state], takeCount);
}

function ignorePatterns(patterns, baseReducer) {
    return (state = [], action) => {
        const ignore = some(patterns, (regex) => regex.test(action.type));
        if (ignore) {
            return state;
        } else {
            return baseReducer(state, action);
        }
    };
}

// exclude these action types from the `filteredActionTypes` that get logged in
// a Sentry error; this list is similar to middlewares.js
const filteredActionTypeHistoryReducer = ignorePatterns(
    [
        /@@router.*/,
        /global_SHOW_HIDE_LOADING_BAR/,
        /core\/STORE_userNotificationsS/,
        /user-notifications\/POLL_FOR_USER_NOTIFICATIONS_SUCCESS/,
        /cobalt-notifications\/SET_PREVIOUS_POLL_DATE/,
        /current-viewers\/LOAD_CURRENT_VIEWERS_SUCCESS/,
        REDUX_FORM_ACTION_TYPE_REGEX,
    ],
    createBasicTakeReducer()
);

const rawActionTypeHistoryReducer = createBasicTakeReducer();

function locationHistoryReducer(state = [], action) {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            return take([action.payload.pathname, ...state], 100);
        default:
            return state;
    }
}

function formContextHistoryReducer(state = {}, action) {
    if (REDUX_FORM_ACTION_TYPE_REGEX.test(action.type)) {
        const previous = state[action.form] ? state[action.form] : [];
        return {
            ...state,
            [action.form]: take([action.type, ...previous], 100),
        };
    } else {
        return state;
    }
}

export default combineReducers({
    filteredActionTypes: filteredActionTypeHistoryReducer,
    rawActionTypes: rawActionTypeHistoryReducer,
    locations: locationHistoryReducer,
    form: formContextHistoryReducer,
    settings: applicationSettingsReducer,
    productModules: productModulesReducer,
});

import { AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    compose,
    pure,
    setDisplayName,
    withState,
    withProps,
    withHandlers,
    withPropsOnChange,
    lifecycle,
} from 'recompose';

import _, { values, some, noop, map, keyBy, isEmpty, groupBy } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    facilityOptionsSelector,
    facilityViewModelsSelector,
    gatherFacilityDataForFacilityOptions,
} from '~/client-common/core/domain/facilities/state/ui';
import strings from '~/client-common/core/strings/componentStrings';
import {
    storeEntityPermissions,
    entityPermissionsSelector,
} from '~/client-common/core/domain/entity-permissions/state/data';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { userRolesSelector } from '~/client-common/core/domain/user-roles/state/data';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { computeAllowedAttributeTypesToLoad } from '../../../attributes/utils/computeAllowedAttributeTypesToLoad';
import { attributeLoadingStateSelectorFactory } from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import { computeLoadableAttributeTypes } from '../../../attributes/utils/computeLoadableAttributeTypes';
import entityPermissionsResource from '../../../permissions/resources/entityPermissionsResource';
import { hasEvidenceLocationPermission } from '../../../../evidence/core/utils/evidenceItemsHelpers';

import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const FacilitySelect = compose(
    setDisplayName('FacilitySelect'),
    pure,
    withState('facilityIds', 'setFacilityIds', ({ value }) => (value ? [].concat(value) : [])),
    withProps(() => ({ attributeType: AttributeTypeEnum.EVIDENCE_FACILITY.name })),
    connect(
        () => {
            const attributeLoadingStateSelector = attributeLoadingStateSelectorFactory();
            return createStructuredSelector({
                facilityOptions: facilityOptionsSelector,
                attributeLoadingState: attributeLoadingStateSelector,
                facilityViewModels: facilityViewModelsSelector,
                parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
                userRoles: userRolesSelector,
                entityPermissions: entityPermissionsSelector,
                applicationSettings: applicationSettingsSelector,
            });
        },
        {
            loadAttributesForType,
            loadEntityPermissions: (perm) => (dispatch) => {
                dispatch(storeEntityPermissions(perm));
            },
        }
    ),
    lifecycle({
        componentDidUpdate(prevProps) {
            const {
                facilityViewModels,
                parentAttributeIdByAttributeId,
                facilityIds,
                evidenceFacilityGlobalAttrIdFilter,
                applicationSettings,
            } = this.props;
            if (
                applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED &&
                (prevProps.facilityViewModels !== facilityViewModels ||
                    prevProps.facilityIds !== facilityIds)
            ) {
                const facilities = gatherFacilityDataForFacilityOptions({
                    facilityViewModels,
                    parentAttributeIdByAttributeId,
                    facilityIds,
                    evidenceFacilityGlobalAttrIdFilter,
                });
                const filterFacilityIds = map(facilities, (facility) => facility.id);
                if (!isEmpty(filterFacilityIds)) {
                    entityPermissionsResource
                        .getPermissions(
                            EntityTypeEnum.EVIDENCE_FACILITY.name,
                            filterFacilityIds,
                            true
                        )
                        .then((perms) => {
                            this.props.loadEntityPermissions(perms);
                        });
                }
            }
        },
        componentDidMount() {
            const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                this.props.attributeLoadingState
            );
            // for now we load all attributes for the specified types, including expired ones
            if (attributeTypesToLoad.length) {
                this.props
                    .loadAttributesForType({ attributeType: attributeTypesToLoad })
                    .catch(noop);
            }
        },
    }),
    withHandlers({
        onChange({ setFacilityIds, onChange }) {
            return (facilityId) => {
                setFacilityIds((facilityIds) =>
                    _(facilityIds).concat(facilityId).filter().uniq().value()
                );
                onChange(facilityId);
            };
        },
        onInputFocus(props) {
            const { attributeLoadingState, onFocus, loadAttributesForType } = props;
            return () => {
                // grab all types which aren't yet loaded so that we only load the required subset
                const attributeTypesToLoad = computeLoadableAttributeTypes(attributeLoadingState);

                // for now we load all attributes for the specified types, including expired ones
                if (attributeTypesToLoad.length) {
                    // the empty `catch` is intentional. Due to having to support legacy KO/BB
                    // we have to return a promise which can potentially be rejected
                    loadAttributesForType({ attributeType: attributeTypesToLoad }).catch(noop);
                }

                if (onFocus) {
                    onFocus();
                }
            };
        },
    }),
    withPropsOnChange(
        [
            'facilityOptions',
            'evidenceFacilityGlobalAttrIdFilter',
            'facilityIds',
            'attributeLoadingState',
            'onInputFocus',
            'fieldUi',
            'ui',
            'requiredPermissions',
            'entityPermissions',
            'applicationSettings',
            'userRoles',
        ],
        ({
            facilityOptions,
            evidenceFacilityGlobalAttrIdFilter,
            facilityIds,
            attributeLoadingState,
            onInputFocus,
            fieldUi,
            ui,
            requiredPermissions,
            entityPermissions,
            applicationSettings,
            userRoles,
        }) => {
            const attributeLoadingValues = values(attributeLoadingState);
            const isLoading = some(attributeLoadingValues, (loadingState) => loadingState.loading);
            // need to check both fields to stay compatible between RRF and MFT
            const uiState = fieldUi || ui;
            // if a field has't been focussed at all, it doesn't seem
            // to have a ui state in RRF. so we have to account for that here
            const isFocussed = !!uiState && uiState.focus;
            const hasAsyncError =
                isFocussed &&
                !isLoading &&
                some(attributeLoadingValues, (loadingState) => loadingState.error);
            let userHasNoPermissionsToAllLocationsError = false;

            let options = facilityOptions(
                evidenceFacilityGlobalAttrIdFilter,
                facilityIds,
                requiredPermissions
            );

            const userRolesById = keyBy(userRoles, (role) => role.roleId);
            const entityPermissionsById = groupBy(
                entityPermissions,
                (permission) => permission.entityId
            );

            const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;
            if (
                useEvdPerms &&
                requiredPermissions &&
                !isEmpty(options) &&
                !isEmpty(entityPermissions)
            ) {
                options = _(options)
                    .map((option) => {
                        const permissions = entityPermissionsById[option.value];
                        if (
                            hasEvidenceLocationPermission(
                                [permissions],
                                userRolesById,
                                requiredPermissions
                            )
                        ) {
                            return option;
                        }
                        return null;
                    })
                    .compact()
                    .value();

                if (isEmpty(options)) {
                    userHasNoPermissionsToAllLocationsError = true;
                }
            }

            return {
                loading: isFocussed && isLoading,
                options,
                error: hasAsyncError
                    ? strings.evidence.core.FacilitySelect.attributeTypeLoadingError
                    : userHasNoPermissionsToAllLocationsError
                    ? strings.evidence.core.FacilitySelect.userHasNoPermissionsToAllLocationsError
                    : undefined,
                onFocus: onInputFocus,
                forceShowError: hasAsyncError,
            };
        }
    )
)(Select);

/**
 * Select dropdown containing all facilities.
 * @param {Object} props Same props as `Select`.
 * @param {Boolean} [props.evidenceFacilityGlobalAttrIdFilter] Optional array to filter facilities
 *   by evidence facility global attribute (internal or external)
 */
export default FacilitySelect;

export const RRFFacilitySelect = connectRRFInput(FacilitySelect);

import { LocationSourceEnum } from '@mark43/rms-api';
import { size, assignWith, first, get, map, pick, compact, join } from 'lodash';
import componentStrings from '../../../strings/componentStrings';

import countryEnum from '../../../enums/client/countryEnum';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';
import { roundStreetAddressToNearestBlock } from './locationAddressHelpers';
import { formatSubPremise } from './subPremiseHelpers';

/**
 * Convert the given LocationBundle model (which contains a Location model) into
 *   a LocationView model. This helper is needed because we store LocationView
 *   models in data state, yet some API endpoints respond with Location models
 *   instead. See the server files for the shapes of these data models. The
 *   string conversion logic here matches the logic on the server.
 *   Do not add any more fields to this! It's supposed to match the server model
 * @param  {Object} locationBundle
 * @return {Object}
 */
function convertLocationBundleToLocationView(data = {}) {
    const { location = {}, entityLinks } = data;
    return {
        ...pick(location, [
            'streetNumber',
            'streetName',
            'country',
            'postalCode',
            'locality',
            'neighborhood',
            'latitude',
            'longitude',
            'rangeName',
            'crossStreet1',
            'crossStreet1Alias',
            'crossStreet2',
            'crossStreet2Alias',
            'id',
        ]),
        type: location.locationType,
        adminArea1: location.administrativeAreaLevel1,
        placeName: get(entityLinks, '0.placeName'),
        streetAddress: joinTruthyValues(
            [location.streetNumber, location.streetNameAlias || location.streetName],
            ' '
        ),
        areCoordinatesVerified: !!location.latitude && !!location.longitude,
    };
}

/**
 * Plural version of `convertLocationBundleToLocationView()`.
 * @param  {LocationBundle[]} locationBundles
 * @return {LocationBundle[]}
 */
export function convertLocationBundlesToLocationViews(locationBundles) {
    return map(locationBundles, convertLocationBundleToLocationView);
}

function getCrossStreetsDisplay(crossStreet1, crossStreet2) {
    const crossStreets = compact([crossStreet1, crossStreet2]);
    return crossStreets.length === 1 ? `at ${crossStreets.join()}` : crossStreets.join(' & ');
}

function getStreetDisplayFromLocation(location, showRange) {
    const { type, streetAddress, rangeName } = location;
    return type === 'RANGE' || showRange
        ? join(compact([rangeName, streetAddress]), ' ')
        : streetAddress;
}

function getSubPremiseAndPlaceNameFromLocationBundle({
    location,
    locationEntityLink,
    isSubPremiseSupportEnabled,
}) {
    const hasLocationEntityLink = !!locationEntityLink?.entityId;
    const deprecatedSubPremise = locationEntityLink.subPremise || location.subPremise;
    const subPremise =
        (isSubPremiseSupportEnabled
            ? // prefer using locationEntityLink since not all location shapes have sub premises
              hasLocationEntityLink
                ? formatSubPremise(locationEntityLink)
                : formatSubPremise(location)
            : undefined) || deprecatedSubPremise;

    const placeName = hasLocationEntityLink ? locationEntityLink.placeName : location.placeName;
    return {
        subPremise,
        placeName,
    };
}

function buildLocationLines({
    location,
    showCountry,
    locationEntityLink = {},
    hidePlaceName,
    // This flag was added for the organization pill
    // since elastic locations don't have a `type`
    showRange,
    isSubPremiseSupportEnabled = false,
}) {
    const {
        streetAddress,
        locality,
        administrativeAreaLevel1,
        postalCode,
        crossStreet1,
        crossStreet1Alias,
        crossStreet2,
        crossStreet2Alias,
        country,
        adminArea1,
        type,
        rangeName,
    } = location;
    const streetDisplay = getStreetDisplayFromLocation(
        { type, rangeName, streetAddress },
        showRange
    );

    const { subPremise, placeName } = getSubPremiseAndPlaceNameFromLocationBundle({
        location,
        locationEntityLink,
        isSubPremiseSupportEnabled,
    });

    let intersectionDisplay = null;

    if (!(type === 'INTERSECTION' && streetDisplay)) {
        const tempDisplay = getCrossStreetsDisplay(
            crossStreet1Alias || crossStreet1,
            crossStreet2Alias || crossStreet2
        );

        if (tempDisplay !== streetDisplay) {
            intersectionDisplay = tempDisplay;
        }
    }

    if (isSubPremiseSupportEnabled) {
        return compact([
            !hidePlaceName && placeName,
            compact([
                compact([streetDisplay, locality, administrativeAreaLevel1 || adminArea1]).join(
                    ', '
                ),
                postalCode,
            ]).join(' '),
            subPremise,
            intersectionDisplay,
            showCountry && get(countryEnum, country),
        ]);
    }

    return compact([
        !hidePlaceName && placeName,
        streetDisplay,
        subPremise,
        intersectionDisplay,
        compact([
            compact([locality, administrativeAreaLevel1 || adminArea1]).join(', '),
            postalCode,
        ]).join(' '),
        showCountry && get(countryEnum, country),
    ]);
}
const fullLocationBuilder = ({
    location,
    unknownLocationId,
    showCountry,
    locationEntityLink,
    anonymize = false,
    hidePlaceName = false,
    isSubPremiseSupportEnabled = false,
}) => {
    // if the address is already a range, leave it alone
    anonymize = location.type === 'RANGE' ? false : anonymize;

    const prefix = location.type === 'RANGE' ? `${location.rangeName} ` : '';
    // when using certain view models or convertLocationBundleToLocationView fields will be on location
    // instead of locationEntityLink

    const { subPremise, placeName } = getSubPremiseAndPlaceNameFromLocationBundle({
        location,
        locationEntityLink,
        isSubPremiseSupportEnabled,
    });

    const streetNumber = anonymize
        ? roundStreetAddressToNearestBlock(location.streetNumber)
        : location.streetNumber;
    const streetAddress = anonymize
        ? roundStreetAddressToNearestBlock(location.streetAddress)
        : location.streetAddress;
    const streetDisplay = `${prefix}${
        location.streetNameAlias
            ? joinTruthyValues([streetNumber, location.streetNameAlias], ' ')
            : streetAddress || ''
    }`;
    const crossStreetsDisplay = getCrossStreetsDisplay(
        location.crossStreet1Alias || location.crossStreet1,
        location.crossStreet2Alias || location.crossStreet2
    );

    return location.id === unknownLocationId
        ? componentStrings.summaries.LocationSummary.noFixed
        : joinTruthyValues(
              [
                  joinTruthyValues([
                      anonymize || hidePlaceName ? undefined : placeName,
                      streetDisplay,
                      anonymize ||
                      crossStreetsDisplay === streetDisplay ||
                      (streetDisplay && location.type === 'INTERSECTION')
                          ? undefined
                          : crossStreetsDisplay,
                      anonymize ? undefined : subPremise,
                      location.locality,
                      location.adminArea1,
                  ]),
                  location.postalCode,
                  showCountry && location.country,
              ],
              ' '
          );
};

// this is for the full address in one line
export function formatAddressForLocationBundle({
    locationBundle,
    anonymize = false,
    isSubPremiseSupportEnabled = false,
} = {}) {
    if (!locationBundle) {
        return null;
    }
    const { locationEntityLink, location, unknownLocationId } = locationBundle;
    return fullLocationBuilder({
        location,
        anonymize,
        unknownLocationId,
        locationEntityLink: locationEntityLink || {},
        showCountry: false,
        isSubPremiseSupportEnabled,
    });
}

export function formatAddressLinesForLocationBundle({
    locationBundle,
    hidePlaceName,
    showCountry,
    isSubPremiseSupportEnabled = false,
}) {
    if (!locationBundle) {
        return null;
    }
    const { locationEntityLink, location } = locationBundle;
    return buildLocationLines({
        location,
        locationEntityLink,
        hidePlaceName,
        showCountry,
        isSubPremiseSupportEnabled,
    });
}

export function locationNameFormatsForLocation({
    location,
    unknownLocationId,
    showCountry,
    hidePlaceName = false,
    isSubPremiseSupportEnabled = false,
}) {
    const verified = location.areCoordinatesVerified ? '(VERIFIED)' : '';
    const locationEntityLink = first(get(location, 'entityLinks')) || {};
    const full = fullLocationBuilder({
        location,
        showCountry,
        locationEntityLink,
        unknownLocationId,
        hidePlaceName,
        isSubPremiseSupportEnabled,
    });
    const fullVerified = joinTruthyValues([full, verified], ' ');
    return {
        full,
        fullVerified,
        lines: buildLocationLines({ location, isSubPremiseSupportEnabled, locationEntityLink }),
    };
}

function getSourceText(location) {
    const isLocationFromPostcoder = location.source === LocationSourceEnum.POSTCODER.name;
    if (isLocationFromPostcoder) {
        return LocationSourceEnum.POSTCODER.name;
    } else if (location.areCoordinatesVerified) {
        const isLocationFromGoogle = location.source === LocationSourceEnum.GOOGLE.name;
        const isLocationFromESRI = location.source === LocationSourceEnum.ESRI.name;
        const isLocationFromMAR = location.source === LocationSourceEnum.DC_MAR.name;
        const isLocationFromESRICustom = location.source === LocationSourceEnum.ESRI_CUSTOM.name;
        let locationText = LocationSourceEnum.MARK43.name;
        if (isLocationFromGoogle) {
            locationText = LocationSourceEnum.GOOGLE.name;
        } else if (isLocationFromESRICustom) {
            locationText = 'DEPARTMENT';
        } else if (isLocationFromESRI) {
            locationText = LocationSourceEnum.ESRI.name;
        } else if (isLocationFromMAR) {
            locationText = 'DC MAR';
        }
        return locationText;
    } else {
        return '';
    }
}

export function locationNameFormatsForSearch({
    elasticLocation,
    showRange,
    isSubPremiseSupportEnabled,
}) {
    return {
        sourceText: getSourceText(elasticLocation),
        lines: buildLocationLines({
            hidePlaceName: true,
            location: elasticLocation,
            showRange,
            isSubPremiseSupportEnabled,
        }),
        placeName: elasticLocation.placeName,
    };
}

export function getPlaceName(location) {
    return get(location, 'placeName') || get(location, 'entityLinks[0].placeName');
}

export function locationHasSubdivision(location) {
    const locationEntityLink = location.entityLinks && location.entityLinks[0];
    return !!(
        locationEntityLink &&
        (locationEntityLink.subdivision1AttrId ||
            locationEntityLink.subdivision2AttrId ||
            locationEntityLink.subdivision3AttrId ||
            locationEntityLink.subdivision4AttrId ||
            locationEntityLink.subdivision5AttrId)
    );
}

export function formatLatLong(location) {
    if (!location.latitude || !location.longitude) {
        return '';
    }
    return `${location.latitude}, ${location.longitude}`;
}

/**
 * If the given `locationEntityLink` has any of the listed
 * keys defined, then merge them back into the `locationView`
 *
 * If the `locationEntityLink` has the keys, but they are
 * `undefined`, then do not merge them, because
 * this will override existing data
 */
export function mergeLocationEntityLinkIntoLocationView(locationEntityLink, locationView) {
    const hasLocationEntityLinks = size(locationView, 'entityLinks');

    // If the `locationView` does not have any entity links,
    // just return the locationView.  We do not want
    // to merge any entityLink into it
    if (!hasLocationEntityLinks) {
        return locationView;
    }

    const keysToCopyFromLocationEntityLinkIntoLocationView = [
        'subdivision1AttrId',
        'subdivision2AttrId',
        'subdivision3AttrId',
        'subdivision4AttrId',
        'subdivision5AttrId',
        'subPremise',
        'agencyId',
    ];

    const createMergedEntityLink = (originalEntityLink) =>
        assignWith(
            {},
            originalEntityLink,
            // If any of these are undefined,
            // then don't override them from the
            // original location's entityLink
            pick(locationEntityLink, keysToCopyFromLocationEntityLinkIntoLocationView),
            (a, b) => (b === undefined ? a : b)
        );

    return {
        ...locationView,
        // For each existing `entityLink`, merge in the provided `locationEntityLink`
        // TODO: There's a bug here - On the FE we shouldn't really be relying on `locationView.entityLinks`,
        // which is an array of an empty entity link object. If the `entityLinks` is an empty array, then this
        // will not return any entity links at all, despite `locationEntityLink` containing the classification
        // information. It's unclear if there's a BE issue here. At least we should get rid of the reliance to
        // `locationView.entityLinks` having an empty entity link.
        entityLinks:
            !!locationView.entityLinks && locationView.entityLinks.length > 0
                ? map(locationView.entityLinks, createMergedEntityLink)
                : !!locationEntityLink
                ? [createMergedEntityLink(locationEntityLink)]
                : [],
    };
}

/**
 * With multi-agency subdivision feature flag we want to default the agency info based of
 * If the feature flag is off, we don't need the agency
 * If the feature flag is on, when copying from existing location, take the location's agency
 * Or use the provided default agency
 * Otherwise default it to user's agency
 */
export function getAgencyIdForLocationEntityLink({
    multiagencySubdivisionsEnabled,
    defaultAgencyId,
    location,
    currentUserDepartmentAgencyId,
}) {
    return !multiagencySubdivisionsEnabled
        ? undefined
        : get(location?.entityLinks, '[0].agencyId') ||
              defaultAgencyId ||
              currentUserDepartmentAgencyId;
}

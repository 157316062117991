import React, { Component } from 'react';
import { map, get, size, join, isFunction } from 'lodash';
import styled from 'styled-components';
import { Text } from 'arc';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { isUnknown } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';

import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';
import { showIdentifyUnknownForPersonSelector } from '../state/ui';

import Pill from '../../components/Pill';
import { PersonProfileImage, profileImageSizes } from '../../components/ProfileImage';
import { TwoColumnSummary } from '../../../records/core/components/summaries/TwoColumnSummary';
import { BottomPositionedDeceasedLabel } from './DeceasedLabel';

const strings = componentStrings.core.PersonPill;

const PersonDescription = styled.div`
    margin-left: 20px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const IdentifyButton = styled(Text)`
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.cobaltBlue};
    text-align: center;
    border: 1px solid ${(props) => props.theme.colors.mediumBlue};
    border-top: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    height: 32px;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    display: flex;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    justify-content: center;
    cursor: pointer;

    &:active {
        background-color: ${(props) => props.theme.colors.brightBlue};
    }

    &:hover {
        border-top: 1px solid;
        border-color: ${(props) => props.theme.colors.darkGrey};
        position: relative;
    }
`;

const leftColumnFields = [
    {
        label: 'DOB',
        path: 'dateOfBirth',
    },
    {
        label: 'Sex',
        path: 'sex',
    },
    {
        label: 'Race',
        path: 'race',
    },
    {
        label: 'Height',
        path: 'height',
    },
    {
        path: 'departmentProfileDisplayName',
        fieldName: fields.REPORT_DEPARTMENT_ID,
    },
];

const rightColumnFields = [
    {
        label: 'Eyes',
        path: 'eyeColor',
    },
    {
        label: 'Hair',
        path: 'hairColor',
    },
    {
        label: 'SSN',
        path: 'socialSecurityNumber',
        fieldName: fields.PERSON_PROFILE_SSN,
    },
    {
        label: 'MNI',
        path: 'id',
    },
    {
        label: 'DLN',
        path: 'driversLicenseNumber',
    },
];

class PersonPill extends Component {
    handleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick(this.props.id);
        }
    };

    handleIdentifyClick = () => {
        const { onIdentifyClick } = this.props;
        if (onIdentifyClick) {
            onIdentifyClick(this.props.id);
        }
    };

    renderFields = () => {
        const leftColumnConfig = map(leftColumnFields, ({ label, path, fieldName }) => ({
            label: fieldName ? this.props.formatFieldByName(fieldName) : label,
            value: get(this.props, path),
        }));

        const rightColumnConfig = map(rightColumnFields, ({ label, path, fieldName }) => ({
            label: fieldName ? this.props.formatFieldByName(fieldName) : label,
            value: get(this.props, path),
        }));

        return (
            <TwoColumnSummary
                leftColumnConfig={leftColumnConfig}
                rightColumnConfig={rightColumnConfig}
            />
        );
    };

    render() {
        const {
            onIdentifyClick,
            className,
            testId,
            isDead,
            primaryMugshotPath,
            nicknames,
            fullName,
            subtitle,
            showIdentifyUnknownForPerson,
            person,
        } = this.props;

        const showIdentify = isFunction(onIdentifyClick) && showIdentifyUnknownForPerson(person);

        const isPersonUnknown = isUnknown(person);
        const permissionSet = get(person, 'permissionSet');
        const isDisabled = !canRead(permissionSet);

        return (
            <div className={className} data-test-id={testId}>
                <Pill
                    disabled={isDisabled}
                    onClick={isDisabled ? undefined : this.handleClick}
                    hoverable={true}
                    kind={showIdentify ? 'flatBottom' : undefined}
                    showChevron={true}
                >
                    <PersonProfileImage
                        size={profileImageSizes.PERSON_PILL}
                        isUnknown={isPersonUnknown}
                        url={primaryMugshotPath}
                    >
                        {isDead && <BottomPositionedDeceasedLabel />}
                    </PersonProfileImage>
                    <PersonDescription>
                        <div>
                            <Text variant="headingXs">{fullName}</Text>
                            {!!size(nicknames) && (
                                <Text fontSize="sm" isItalic color="tertiary">
                                    {strings.nicknamesPrefix} {join(nicknames, ', ')}
                                </Text>
                            )}
                            {!!subtitle && (
                                <Text as="span" fontSize="sm">
                                    {subtitle}
                                </Text>
                            )}
                        </div>
                        {this.renderFields()}
                    </PersonDescription>
                </Pill>
                {showIdentify && (
                    <IdentifyButton onClick={this.handleIdentifyClick}>
                        {strings.identify}
                    </IdentifyButton>
                )}
            </div>
        );
    }
}

PersonPill.propTypes = {
    dateOfBirth: PropTypes.string,
    sex: PropTypes.string,
    race: PropTypes.string,
    height: PropTypes.string,
    eyeColor: PropTypes.string,
    hairColor: PropTypes.string,
    socialSecurityNumber: PropTypes.string,
    driversLicenseNumber: PropTypes.string,
    onIdentifyClick: PropTypes.func,
    className: PropTypes.string,
    testId: PropTypes.string,
    isDead: PropTypes.bool,
    primaryMugshotPath: PropTypes.string,
    nicknames: PropTypes.arrayOf(PropTypes.string),
    fullName: PropTypes.string,
    subtitle: PropTypes.string,
    showIdentifyUnknownForPerson: PropTypes.func,
    formatFieldByName: PropTypes.func,
};

export default connect(
    createStructuredSelector({
        showIdentifyUnknownForPerson: showIdentifyUnknownForPersonSelector,
        formatFieldByName: formatFieldByNameSelector,
    })
)(PersonPill);

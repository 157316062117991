import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector, Selector } from 'reselect';
import { map, partialRight } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { withRouter, WithRouterProps } from 'react-router';
import { ReportStatusView } from '@mark43/rms-api';
import reportApprovalLevelClientEnum from '~/client-common/core/enums/client/reportApprovalLevelClientEnum';
import withFields from '~/client-common/core/fields/components/withFields';
import {
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import approvalStatusClientEnum from '~/client-common/core/enums/client/approvalStatusClientEnum';
import {
    reportDefinitionByReportIdSelector,
    reportDefinitionRestrictViewReportOwnersSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import { Field } from '~/client-common/core/fields/state/config';
import { canManage } from '~/client-common/core/domain/entity-permissions/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import {
    canSubmitReportSelector,
    reportIsPackagedSelector,
    reportOwnerIdSelector,
    isQuickCrashReportSelector,
    currentReportApprovalLevelSelector,
    currentReportSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import { Button as ArcButton } from '../../../../core/components/Button';
import { CustomLink } from '../../../../core/components/links/Link';
import { AnalyticsPropertyEnum } from '../../../../analytics/constants/analyticsEnum';
import { AnalyticsContextProviderWithAdditionalData } from '../../../../core/context/AnalyticsContext';
import testIds from '../../../../../core/testIds';
import { onClickToOnEnter } from '../../../../core/utils/eventHelpers';
import { openReportChangeOwnerModal, openReportSubmissionModal } from '../../state/ui';
import custodialPropertyCard from '../../state/ui/custodialPropertyCard';
import { packageReport } from '../../state/ui/submissionValidations';
import { hideAllEmbeddedReports } from '../../state/ui/embeddedReports';
import { embeddedReportShortTitlesSelector } from '../../state/ui/arrestBlock';
import { Tooltip, ConditionalTooltip } from '../../../../core/components/tooltip';
import { currentReportSealingSelector } from '../../../../record-privacy/sealing/state/ui';
import { RmsDispatch } from '../../../../../core/typings/redux';
import CardWithApprovalStatusIcon from './CardWithApprovalStatusIcon';
import EditRecordLabels from './EditRecordLabels';
import SubmissionDraftCard from './SubmissionDraftCard';
import SubmissionErrorModal from './SubmissionErrorModal';

const strings = componentStrings.reports.core.ReportStatusCommentsCard;

interface NoValidationDraftCardOuterPropsT {
    className?: string;
    disabled?: boolean;
}
interface NoValidationDraftCardInnerPropsT extends NoValidationDraftCardOuterPropsT {
    fieldDisplayNames: Record<Field, string>;
    openReportSubmissionModal: () => void;
    saving?: boolean;
}

/**
 * Approval status card in the "draft" state. This is a plain white card with
 *   submit functionality.
 */
const NoValidationDraftCard = compose<
    NoValidationDraftCardInnerPropsT,
    NoValidationDraftCardOuterPropsT
>(
    withFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT]),
    connect(
        createStructuredSelector({
            saving: custodialPropertyCard.selectors.savingSelector as Selector<
                unknown,
                () => boolean
            >,
        }),
        {
            openReportSubmissionModal,
        }
    )
)(({ openReportSubmissionModal, disabled, saving, className, fieldDisplayNames }) => (
    <CardWithApprovalStatusIcon
        approvalStatus={approvalStatusClientEnum.DRAFT}
        title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT}
        className={className}
        latestHistoryText={partialRight(
            strings.draft.returnToDraftEnhancements,
            fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT
        )}
        testId={testIds.REPORT_STATUS_COMMENTS_CUSTODIAL_SUBMISSION_DRAFT_CARD}
    >
        <ArcButton
            isTextTransformNone
            variant="solid"
            onClick={openReportSubmissionModal}
            disabled={saving || disabled}
            testId={testIds.REPORT_STATUS_COMMENTS_CARD_SUBMIT_REPORT_BUTTON}
        >
            {strings.core.submit}
        </ArcButton>
    </CardWithApprovalStatusIcon>
));

/**
 * In a dynamic report (a streamlined offense + arrest report),
 * the first button in PackageReportCard validates all reports together, and
 * the second button (this component) hides all embedded (arrest) reports before validating only the original (offense) report.
 * Subsequently, the SubmissionDraftCard's one submit button works for both these scenarios.
 *
 * This button does not appear in non-dynamic reports.
 */
const ValidateOnlyOriginalReportButton: React.FC<{
    disabled?: boolean;
    reportId: number;
    packageReport: () => void;
    embeddedReportCount: number;
}> = ({ disabled, reportId, embeddedReportCount }) => {
    const dispatch = useDispatch();
    const onClick = React.useCallback(() => {
        dispatch(hideAllEmbeddedReports());
        // Without setTimeout, the cardsRegistry state (which is not part of Redux) doesn't update and thus
        // packageReport will still try to save the cards in the now-hidden embedded reports. The resulting
        // bug is the window does not scroll up to the first invalid card. Waiting for the next tick gives
        // cardsRegistry time to update.
        setTimeout(() => {
            dispatch(packageReport());
        }, 0);
    }, [dispatch]);

    const reportDefinitionByReportId = useSelector(reportDefinitionByReportIdSelector);
    const currentReportDefinition = reportDefinitionByReportId(reportId);
    if (!reportId || !currentReportDefinition) {
        return null;
    }

    return (
        <Tooltip
            side="top"
            content={strings.core.validateOnlyOriginalReportTooltip(embeddedReportCount)}
        >
            <ArcButton
                isTextTransformNone
                variant="outline"
                disabled={disabled}
                onClick={onClick}
                testId={testIds.REPORT_STATUS_COMMENTS_CARD_VALIDATE_ONLY_ORIGINAL_REPORT_BUTTON}
                style={{ marginTop: '10px', marginLeft: '10px' }}
            >
                {strings.core.validateOnlyOriginalReport(currentReportDefinition.name)}
            </ArcButton>
        </Tooltip>
    );
};

interface PackageReportCardOuterPropsT {
    className?: string;
    disabled?: boolean;
    packageReport: () => void;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    reportStatusView: ReportStatusView;
}
interface PackageReportCardInnerPropsT extends PackageReportCardOuterPropsT {
    fieldDisplayNames: Record<Field, string>;
    router: WithRouterProps;
}

const PackageReportCard = compose<PackageReportCardInnerPropsT, PackageReportCardOuterPropsT>(
    withRouter,
    withFields([DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT])
)(
    ({
        router,
        className,
        packageReport,
        disabled,
        reportSealingInfo,
        reportStatusView,
        fieldDisplayNames,
    }) => {
        React.useEffect(() => {
            // when a query param is provided, validate all cards
            if (router.location.query.validate === 'true') {
                packageReport();
            }
        }, [router.location.query.validate, packageReport]);

        const embeddedReportCount = useSelector(embeddedReportShortTitlesSelector).length;
        const isQuickCrashReport = useSelector(isQuickCrashReportSelector);

        return (
            <CardWithApprovalStatusIcon
                approvalStatus={approvalStatusClientEnum.DRAFT}
                reportSealingInfo={reportSealingInfo}
                title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT}
                className={className}
                latestHistoryText={partialRight(
                    strings.draft.returnToDraftEnhancements,
                    fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT
                )}
                testId={testIds.REPORT_STATUS_COMMENTS_PACKAGE_REPORT_CARD}
            >
                {!disabled && <EditRecordLabels reportId={reportStatusView.report.id} />}
                <AnalyticsContextProviderWithAdditionalData
                    analyticsKeyToAdd={AnalyticsPropertyEnum.REPORT_COUNT}
                    analyticsValueToAdd={embeddedReportCount + 1}
                >
                    <ConditionalTooltip
                        side="top"
                        condition={isQuickCrashReport}
                        content={strings.draft.quickCrashHelpText}
                    >
                        <ArcButton
                            isTextTransformNone
                            variant="solid"
                            disabled={disabled}
                            onClick={packageReport}
                            testId={testIds.REPORT_STATUS_COMMENTS_CARD_VALIDATE_REPORT_BUTTON}
                            style={{ marginTop: '10px' }}
                        >
                            {embeddedReportCount > 0
                                ? strings.core.bulkValidate(embeddedReportCount + 1)
                                : strings.core.validate}
                        </ArcButton>
                    </ConditionalTooltip>
                    {embeddedReportCount > 0 ? (
                        <ValidateOnlyOriginalReportButton
                            disabled={disabled}
                            reportId={reportStatusView.report.id}
                            packageReport={packageReport}
                            embeddedReportCount={embeddedReportCount}
                        />
                    ) : undefined}
                </AnalyticsContextProviderWithAdditionalData>
                <SubmissionErrorModal />
            </CardWithApprovalStatusIcon>
        );
    }
);

interface NoSubmitDraftCardOuterPropsT {
    className?: string;
    disabled: boolean;
    reportStatusView: ReportStatusView;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
}
interface NoSubmitDraftCardInnerPropsT extends NoSubmitDraftCardOuterPropsT {
    fieldDisplayNames: Record<Field, string>;
    formatMiniUserById: ReturnType<typeof formatMiniUserByIdSelector>;
    reportOwnerId: number;
    openReportChangeOwnerModal: () => void;
    currentReport: ReturnType<typeof currentReportSelector>;
    reportDefinitionRestrictViewReportOwners: ReturnType<
        typeof reportDefinitionRestrictViewReportOwnersSelector
    >;
}

/**
 * The draft card if there is a different owner and cannot be submitted
 */
const NoSubmitDraftCard = compose<NoSubmitDraftCardInnerPropsT, NoSubmitDraftCardOuterPropsT>(
    withFields([
        DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
        DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
    ]),
    connect(
        createStructuredSelector({
            reportOwnerId: reportOwnerIdSelector,
            formatMiniUserById: formatMiniUserByIdSelector,
            currentReport: currentReportSelector,
            reportDefinitionRestrictViewReportOwners: reportDefinitionRestrictViewReportOwnersSelector,
        }),
        (dispatch: RmsDispatch) => ({
            openReportChangeOwnerModal: (callback?: (() => void) | false) =>
                dispatch(openReportChangeOwnerModal({ callback })),
        })
    ),
    withHandlers({
        openReportChangeOwnerModal: ({
            openReportChangeOwnerModal,
        }: {
            openReportChangeOwnerModal: (callback?: (() => void) | false) => void;
        }) => {
            return () => openReportChangeOwnerModal(false);
        },
    })
)(
    ({
        disabled,
        className,
        reportStatusView,
        openReportChangeOwnerModal,
        formatMiniUserById,
        reportOwnerId,
        currentReport,
        reportDefinitionRestrictViewReportOwners,
        reportSealingInfo,
        fieldDisplayNames,
    }) => {
        if (!currentReport) {
            return null;
        }
        const latestHistoryText: (user: string, date: string | null) => string = partialRight(
            strings.draft.returnToDraftEnhancements,
            fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT
        );
        const restrictViewReportOwners = reportDefinitionRestrictViewReportOwners(
            currentReport.reportDefinitionId
        );
        return (
            <CardWithApprovalStatusIcon
                approvalStatus={approvalStatusClientEnum.DRAFT}
                reportSealingInfo={reportSealingInfo}
                title={fieldDisplayNames.DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT}
                className={className}
                latestHistoryText={latestHistoryText}
                testId={testIds.REPORT_STATUS_COMMENTS_NO_SUBMIT_DRAFT_CARD}
            >
                {reportStatusView.submitErrors && reportStatusView.submitErrors.length > 0 && (
                    <p>
                        <FeatureFlagged
                            flag="RMS_HIDABLE_REPORT_OWNERS_ENABLED"
                            fallback={strings.draft.draftError(
                                formatMiniUserById(reportOwnerId),
                                fieldDisplayNames.DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL.toLowerCase()
                            )}
                        >
                            {strings.draft.draftError(
                                restrictViewReportOwners && !canManage(currentReport.permissionSet)
                                    ? '—'
                                    : formatMiniUserById(reportOwnerId),
                                fieldDisplayNames.DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL.toLowerCase()
                            )}
                        </FeatureFlagged>

                        {map(reportStatusView.submitErrors, (error, i) => {
                            return <span key={i}>{error}</span>;
                        })}
                    </p>
                )}
                {reportStatusView.canReassign && !disabled && (
                    <p>
                        <CustomLink
                            onClick={() => openReportChangeOwnerModal()}
                            tabIndex={0}
                            onKeyDown={onClickToOnEnter(openReportChangeOwnerModal)}
                        >
                            {strings.core.reassignLink}
                        </CustomLink>
                        {strings.core.reassignMessage(
                            fieldDisplayNames.DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL.toLowerCase()
                        )}
                    </p>
                )}
            </CardWithApprovalStatusIcon>
        );
    }
);

interface DraftCardPropsType {
    isCustodialReport: boolean;
    canSubmit: boolean;
    reportIsPackaged: boolean;
    disabled: boolean;
    className?: string;
    reportStatusView: ReportStatusView;
    reportSealingInfo: ReturnType<typeof currentReportSealingSelector>;
    packageReport: () => void;
    reportApprovalLevel: ReturnType<typeof currentReportApprovalLevelSelector>;
}

const DraftCard = ({
    isCustodialReport,
    canSubmit,
    reportIsPackaged,
    disabled,
    className,
    reportStatusView,
    reportSealingInfo,
    packageReport,
    reportApprovalLevel,
}: DraftCardPropsType) => {
    if (!canSubmit) {
        // Viewing someone else's Draft card.
        return (
            <NoSubmitDraftCard
                disabled={disabled}
                reportStatusView={reportStatusView}
                className={className}
                reportSealingInfo={reportSealingInfo}
            />
        );
    } else if (
        reportApprovalLevel === reportApprovalLevelClientEnum.NONE &&
        (reportIsPackaged || isCustodialReport)
    ) {
        // The report does not need any levels of approval.
        // This case is to get rid of the `isCustodialReport` logic.
        return <NoValidationDraftCard disabled={disabled} className={className} />;
    } else if (!reportIsPackaged) {
        // When the Draft is ready to be validated.
        return (
            <PackageReportCard
                packageReport={packageReport}
                className={className}
                disabled={disabled}
                reportSealingInfo={reportSealingInfo}
                reportStatusView={reportStatusView}
            />
        );
    } else {
        // The Draft Card has been validated and is ready for submission.
        return (
            <SubmissionDraftCard
                className={className}
                disabled={disabled}
                reportSealingInfo={reportSealingInfo}
            />
        );
    }
};

const mapStateToProps = createStructuredSelector({
    canSubmit: canSubmitReportSelector,
    reportIsPackaged: reportIsPackagedSelector,
});

const mapDispatchToProps = {
    packageReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(DraftCard);

import { AttributeTypeEnum } from '@mark43/rms-api';
import { get, noop, map, omit } from 'lodash';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import {
    getAttributeByIdSelector,
    attributesWithParentAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { taskStatusOptionsForDisplay } from '~/client-common/helpers/taskStatusHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import componentStrings from '~/client-common/core/strings/componentStrings';
import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { ALL_TO_DATE } from '~/client-common/core/dates/utils/dateRangeHelpers';
import useFields from '~/client-common/core/fields/hooks/useFields';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import FormSection from '../../../core/forms/components/FormSection';
import { MFTSelect } from '../../../core/forms/components/selects/Select';
import { MFTText } from '../../../core/forms/components/Text';
import { MFTDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { SearchName } from '../../../search/core/components/SearchName';

import { tasksDashboardSearch, resetFilterForm, filterTaskDashboard } from '../state/ui';
import Row from '../../../core/components/Row';
import { MFTBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { MFTUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { MFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { MFTRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import testIds from '../../../../core/testIds';
import CreateSavedSearchModal from '../../../search/saved-search/components/CreateSavedSearchModal';
import { DeleteSavedSearchConfirmationModal } from '../../../search/saved-search/components/SavedSearchConfirmationModals';
import useOnSaveSearchToggleHandler from '../../../search/core/hooks/useOnSaveSearchToggleHandler';
import { SavedSearchDropdownButton } from '../../../search/saved-search/components/SavedSearchDropdownButton';
import { TaskDashboardSearchResultsParents } from '../../core/config';

import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { convertFromFormModel } from '../state/forms/tasksDashboardSearchForm';
import { queryParamDefaults } from '../configuration';

const strings = componentStrings.tasks.dashboard.TaskDashboardSearchForm;

const TasksSearchFormContainer = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const TasksFormSection = styled(FormSection)`
    && {
        padding-top: 22px;
        padding-bottom: 22px;
    }
`;

const ApplyFiltersButton = styled(Button)`
    width: 138px;
`;

const ClearFiltersButton = styled(Button)`
    color: ${(props) => props.theme.colors.highContrastGrey};
    fill: ${(props) => props.theme.colors.highContrastGrey};
`;

const BottomRow = styled.div`
    display: flex;
    align-items: center;
    column-gap: 15px;
`;

const SaveSearchToggle = styled(Button)`
    align-items: center;
`;

const dateTypeOptions = [
    { display: strings.dateOptions.createdDate, value: dateTypeEnum.CREATED },
    { display: strings.dateOptions.updatedDate, value: dateTypeEnum.MODIFIED },
    { display: strings.dateOptions.dueDate, value: dateTypeEnum.DUE },
];
const dateTypeOptionsWithCompleted = [
    ...dateTypeOptions,
    { display: strings.dateOptions.statusDate, value: dateTypeEnum.COMPLETED },
];

const toDatePeriodOptionsWithoutAllToDate = ['P1M', 'P1Y'];
const toDatePeriodOptionsWithAllToDate = ['P1M', 'P1Y', ALL_TO_DATE];

const TasksDashboardSearchForm = ({
    getAttributeById,
    getAttributesWithParentAttributeId,
    onReset,
    currentSavedSearch,
    onSubmit,
    query,
}) => {
    const isSavedSearch = query.savedSearchId && !query.isAutoSave;

    const { getForm } = useFormGetter();

    const form = getForm(formClientEnum.TASKS_DASHBOARD_SEARCH_FORM);

    const onSaveSearchToggle = useOnSaveSearchToggleHandler(tasksDashboardSearch);
    const fieldDisplayNames = useFields([
        fields.TASK_TITLE,
        fields.TASK_TYPE_ATTR_ID,
        fields.TASK_PRIORITY_ATTR_ID,
        fields.TASK_STATUS_ATTR_ID,
        fields.TASK_ASSIGNEE_ROLE_ID,
        fields.DISPLAY_ONLY_TASK_CREATED_BY,
        fields.DISPLAY_ONLY_TASK_SOURCE_NUMBER,
    ]);

    const [, setDateRange] = useState();
    const dateType = form.get('dateType');
    const [toDatePeriodOptions, setToDatePeriodOptions] = useState(
        dateType === dateTypeEnum.DUE
            ? toDatePeriodOptionsWithAllToDate
            : toDatePeriodOptionsWithoutAllToDate
    );
    const [isTaskStatusEnabled, setIsTaskStatusEnabled] = useState(
        dateType !== dateTypeEnum.COMPLETED
    );

    const onSaveSearch = useCallback(
        ({ name, isShared, entityPermissions }) => {
            const formModel = form?.getState().model;

            const savedSearchQuery = omit(convertFromFormModel(formModel), 'query');

            const currentQuery = {
                query: savedSearchQuery,
                from: queryParamDefaults.FROM,
                size: queryParamDefaults.SIZE,
            };

            return onSaveSearchToggle({
                query: currentQuery,
                name,
                isShared,
                entityPermissions,
            });
        },
        [onSaveSearchToggle, form]
    );

    const onSelectDateType = useCallback(
        (dateType) => {
            setToDatePeriodOptions(
                dateType === dateTypeEnum.DUE
                    ? toDatePeriodOptionsWithAllToDate
                    : toDatePeriodOptionsWithoutAllToDate
            );

            if (dateType === dateTypeEnum.DUE) {
                form.set('daysUntilTasksDue', undefined);
                form.set('isOverdue', undefined);
            }

            if (
                dateType !== dateTypeEnum.DUE &&
                form.get('dateRange.toDatePeriod') === ALL_TO_DATE
            ) {
                form.set('dateRange', {});
            }

            if (dateType === dateTypeEnum.COMPLETED) {
                const completedAttributes = map(
                    getAttributesWithParentAttributeId(globalAttributes.taskStatusGlobal.completed),
                    'id'
                );
                form.set('statusAttrIdsUntransformed', completedAttributes);
            }
            setIsTaskStatusEnabled(dateType !== dateTypeEnum.COMPLETED);
        },
        [form, getAttributesWithParentAttributeId]
    );

    const handleDueDateFilterChange = (path) => {
        const pathToClear = path === 'isOverdue' ? 'daysUntilTasksDue' : 'isOverdue';
        form.set(pathToClear, undefined);

        const isDueDateType = form.get('dateType') === dateTypeEnum.DUE;
        if (isDueDateType) {
            form.set('dateType', undefined);
            form.set('dateRange', undefined);
            setDateRange();
        }
    };

    const dateRangeFields = {
        withinLastPeriod: {
            onChange: noop,
        },
        toDatePeriod: {
            onChange: noop,
        },
        startDateUtc: {
            onChange: noop,
        },
        endDateUtc: {
            onChange: noop,
        },
    };

    const taskStatusOptions = [
        {
            display: '',
            value: null,
        },
        {
            display: get(getAttributeById(globalAttributes.taskStatusGlobal.pending), 'val'),
            value: globalAttributes.taskStatusGlobal.pending,
        },
        {
            display: get(getAttributeById(globalAttributes.taskStatusGlobal.completed), 'val'),
            value: globalAttributes.taskStatusGlobal.completed,
        },
        {
            display: get(getAttributeById(globalAttributes.taskStatusGlobal.inProgress), 'val'),
            value: globalAttributes.taskStatusGlobal.inProgress,
        },
        {
            display: get(getAttributeById(globalAttributes.taskStatusGlobal.notApplicable), 'val'),
            value: globalAttributes.taskStatusGlobal.notApplicable,
        },
    ];

    const FIELD_WIDTH = 222;

    return (
        <TasksSearchFormContainer>
            <TasksFormSection>
                <Row>
                    <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
                </Row>
                <Row>
                    <FeatureFlagged
                        flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                        fallback={
                            <>
                                <MFTSelect
                                    clearable={false}
                                    label={strings.labels.dateType}
                                    options={dateTypeOptions}
                                    path="dateType"
                                    width={FIELD_WIDTH}
                                    testId={testIds.TASKS_DASHBOARD_FILTER_DATE_TYPE}
                                />
                                <MFTDateRangePicker
                                    fields={dateRangeFields}
                                    includeTime={true}
                                    label={strings.labels.dateTimeRange}
                                    onChange={setDateRange}
                                    path="dateRange"
                                    width={FIELD_WIDTH}
                                    withinLastPeriodOptions={[
                                        'PT12H',
                                        'PT24H',
                                        'P7D',
                                        'P14D',
                                        'P28D',
                                    ]}
                                    toDatePeriodOptions={['P1M', 'P1Y']}
                                    testId={testIds.TASKS_DASHBOARD_FILTER_DATE_RANGE}
                                />
                            </>
                        }
                    >
                        <MFTSelect
                            clearable={false}
                            label={strings.labels.dateType}
                            onChange={onSelectDateType}
                            options={dateTypeOptionsWithCompleted}
                            path="dateType"
                            width={FIELD_WIDTH}
                            testId={testIds.TASKS_DASHBOARD_FILTER_DATE_TYPE}
                        />
                        <MFTDateRangePicker
                            fields={dateRangeFields}
                            includeTime={true}
                            label={strings.labels.dateTimeRange}
                            onChange={setDateRange}
                            path="dateRange"
                            width={FIELD_WIDTH}
                            withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
                            toDatePeriodOptions={toDatePeriodOptions}
                            testId={testIds.TASKS_DASHBOARD_FILTER_DATE_RANGE}
                        />
                    </FeatureFlagged>

                    <MFTText
                        helpText={strings.tooltips.daysUntilTaskDue}
                        label={strings.labels.daysUntilTaskDue}
                        path="daysUntilTasksDue"
                        textInputOnly={false}
                        width={FIELD_WIDTH}
                        onChange={() => handleDueDateFilterChange('daysUntilTasksDue')}
                    />
                </Row>

                <Row>
                    <MFTText
                        label={fieldDisplayNames.TASK_TITLE}
                        fieldName={fields.TASK_TITLE}
                        path="title"
                        textInputOnly={false}
                        width={FIELD_WIDTH}
                    />
                    <FeatureFlagged
                        flag="RMS_TASK_ENHANCEMENTS_ENABLED"
                        fallback={
                            <MFTAttributeSelect
                                attributeType={AttributeTypeEnum.TASK_STATUS.name}
                                filterOptions={taskStatusOptionsForDisplay}
                                label={fieldDisplayNames.TASK_STATUS_ATTR_ID}
                                options={taskStatusOptions}
                                multiple={true}
                                path="statusAttrIdsUntransformed"
                                width={FIELD_WIDTH}
                            />
                        }
                    >
                        <MFTAttributeSelect
                            attributeType={AttributeTypeEnum.TASK_TYPE.name}
                            label={fieldDisplayNames.TASK_TYPE_ATTR_ID}
                            multiple={true}
                            path="typeAttrIdsUntransformed"
                            width={FIELD_WIDTH}
                        />
                        <MFTAttributeSelect
                            attributeType={AttributeTypeEnum.TASK_PRIORITY.name}
                            label={fieldDisplayNames.TASK_PRIORITY_ATTR_ID}
                            multiple={true}
                            path="priorityAttrIdsUntransformed"
                            width={FIELD_WIDTH}
                        />
                        <MFTAttributeSelect
                            attributeType={AttributeTypeEnum.TASK_STATUS.name}
                            filterOptions={taskStatusOptionsForDisplay}
                            label={fieldDisplayNames.TASK_STATUS_ATTR_ID}
                            options={taskStatusOptions}
                            multiple={true}
                            path="statusAttrIdsUntransformed"
                            width={FIELD_WIDTH}
                            disabled={!isTaskStatusEnabled}
                        />
                    </FeatureFlagged>
                    <MFTBooleanSelect
                        helpText={strings.tooltips.isOverdue}
                        label={strings.labels.isOverdue}
                        path="isOverdue"
                        width={FIELD_WIDTH}
                        testId={testIds.TASKS_DASHBOARD_FILTER_IS_OVERDUE}
                        onChange={() => handleDueDateFilterChange('isOverdue')}
                    />
                    <div data-test-id={testIds.TASKS_DASHBOARD_FILTER_CREATOR}>
                        <MFTUserSelect
                            label={fieldDisplayNames.DISPLAY_ONLY_TASK_CREATED_BY}
                            multiple={true}
                            path="creatorUserIds"
                            width={FIELD_WIDTH}
                        />
                    </div>
                    <div data-test-id={testIds.TASKS_DASHBOARD_FILTER_ASSIGNEE}>
                        <MFTRoleSelect
                            label={fieldDisplayNames.TASK_ASSIGNEE_ROLE_ID}
                            multiple={true}
                            path="assigneeRoleIds"
                            width={FIELD_WIDTH}
                        />
                    </div>
                    <FeatureFlagged flag="RMS_TASK_ENHANCEMENTS_ENABLED">
                        <MFTText
                            label={fieldDisplayNames.DISPLAY_ONLY_TASK_SOURCE_NUMBER}
                            fieldName={fields.DISPLAY_ONLY_TASK_SOURCE_NUMBER}
                            path="sourceNumbers"
                            textInputOnly={false}
                            width={FIELD_WIDTH}
                        />
                    </FeatureFlagged>
                </Row>

                <div>
                    <BottomRow>
                        <ApplyFiltersButton
                            className={buttonTypes.PRIMARY}
                            onClick={onSubmit}
                            testId={testIds.TASKS_DASHBOARD_APPLY_FILTER_BUTTON}
                        >
                            {strings.apply}
                        </ApplyFiltersButton>
                        <SavedSearchDropdownButton searchModule={tasksDashboardSearch} />
                        <FeatureFlagged
                            flag="RMS_SAVED_SEARCH_SHARING_ENABLED"
                            fallback={
                                <SaveSearchToggle
                                    className={buttonTypes.ICON_LINK}
                                    iconLeft={
                                        <Icon
                                            type={
                                                isSavedSearch
                                                    ? iconTypes.SAVED_SEARCH_ACTIVE
                                                    : iconTypes.SAVED_SEARCH_INACTIVE
                                            }
                                        />
                                    }
                                    hoveredIconLeft={
                                        <Icon
                                            type={
                                                isSavedSearch
                                                    ? iconTypes.SAVED_SEARCH_INACTIVE
                                                    : iconTypes.SAVED_SEARCH_ACTIVE
                                            }
                                        />
                                    }
                                    onClick={onSaveSearchToggle}
                                >
                                    {isSavedSearch ? strings.unsaveSearch : strings.saveSearch}
                                </SaveSearchToggle>
                            }
                        />
                        <ClearFiltersButton
                            className={buttonTypes.ICON_LINK}
                            iconLeft={<Icon type={iconTypes.CLOSE_X} size={12} />}
                            onClick={onReset}
                        >
                            {strings.clear}
                        </ClearFiltersButton>
                        <CreateSavedSearchModal onSave={onSaveSearch} />
                        <DeleteSavedSearchConfirmationModal searchModule={tasksDashboardSearch} />
                    </BottomRow>
                </div>
            </TasksFormSection>
        </TasksSearchFormContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    getAttributeById: getAttributeByIdSelector,
    getAttributesWithParentAttributeId: attributesWithParentAttributeIdSelector,
    query: tasksDashboardSearch.selectors.currentQuerySelector,
    currentSavedSearch: tasksDashboardSearch.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onReset() {
        dispatch(resetFilterForm());
        dispatch(tasksDashboardSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(tasksDashboardSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
        dispatch(filterTaskDashboard(TaskDashboardSearchResultsParents.TASK_DASHBOARD));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(TasksDashboardSearchForm);

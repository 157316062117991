import React from 'react';
import { pure } from 'recompose';
import classNames from 'classnames';
import styled from 'styled-components';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_MODIFIED_DATE_TIME_RANGE_LABEL,
    DISPLAY_ONLY_STATUS_DATE_TIME_RANGE_LABEL,
} from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { RRFItemTypeAttributeSelect } from '../../../core/forms/components/selects/ItemTypeAttributeSelect';
import { RRFItemCategoryAttributeSelect } from '../../../core/forms/components/selects/ItemCategoryAttributeSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';

import Row from '../../../core/components/Row';
import { RRFFieldset, CollapsibleFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNFieldsets } from '../../../core/forms/components/NFieldsets';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFAftManufacturerSelect } from '../../../reports/core/components/items/fieldsets/AtfManufacturerSelect';
import { RRFConsortiumDepartmentSelect } from '../../../core/forms/components/selects/ConsortiumDepartmentSelect';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';

import testIds from '../../../../core/testIds';
import ItemIdentifiersFieldset from './ItemIdentifiersFieldset';
import SearchCapabilityIcon, { SEARCH_CAPABILITY_TYPE_ENUM } from './SearchCapabilityIcon';

const VEHICLE = globalAttributes.itemType.vehicle;

const IconWrapper = styled.span`
    margin-left: 10px;
`;

function PropertyCategoryRow() {
    const updatedDateRangeLabel = useFields(DISPLAY_ONLY_MODIFIED_DATE_TIME_RANGE_LABEL)[
        DISPLAY_ONLY_MODIFIED_DATE_TIME_RANGE_LABEL
    ];
    return (
        <Row>
            <RRFItemTypeAttributeSelect
                path="itemTypeAttrIds"
                width={257}
                rejectByCategoryFilter={VEHICLE}
            />
            <RRFItemCategoryAttributeSelect
                path="itemCategoryAttrIds"
                width={257}
                rejectByCategoryFilter={VEHICLE}
            />
            <RRFDateRangePicker
                label={updatedDateRangeLabel}
                width={257}
                includeTime={true}
                withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                toDatePeriodOptions={['P1M', 'P1Y']}
                paths={{
                    withinLastPeriod: 'updatedDateRangeQuery.withinLastPeriod',
                    toDatePeriod: 'updatedDateRangeQuery.toDatePeriod',
                    startDateUtc: 'updatedDateRangeQuery.startDateUtc',
                    endDateUtc: 'updatedDateRangeQuery.endDateUtc',
                }}
            />
        </Row>
    );
}

function PropertyStatusRow() {
    const statusDateRangeLabel = useFields(DISPLAY_ONLY_STATUS_DATE_TIME_RANGE_LABEL)[
        DISPLAY_ONLY_STATUS_DATE_TIME_RANGE_LABEL
    ];
    return (
        <Row>
            <RRFAttributeSelect
                attributeType="PROPERTY_LOSS"
                multiple={true}
                grouped={true}
                showSelectedGroup={false}
                includeExpired={true}
                width={257}
                path="propertyStatusAttrIds"
            />
            <RRFDateRangePicker
                label={statusDateRangeLabel}
                width={257}
                includeTime={true}
                withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                toDatePeriodOptions={['P1M', 'P1Y']}
                paths={{
                    withinLastPeriod: 'statusDateRangeQuery.withinLastPeriod',
                    toDatePeriod: 'statusDateRangeQuery.toDatePeriod',
                    startDateUtc: 'statusDateRangeQuery.startDateUtc',
                    endDateUtc: 'statusDateRangeQuery.endDateUtc',
                }}
            />
        </Row>
    );
}

function PropertyFieldsetRow1({ expandCollapseButton }) {
    return (
        <Row>
            <RRFItemTypeAttributeSelect
                path="itemTypeAttrIds"
                width={257}
                rejectByCategoryFilter={VEHICLE}
            />
            <RRFItemCategoryAttributeSelect
                path="itemCategoryAttrIds"
                width={257}
                rejectByCategoryFilter={VEHICLE}
            />
            <RRFAttributeSelect
                attributeType="PROPERTY_LOSS"
                multiple={true}
                grouped={true}
                showSelectedGroup={false}
                includeExpired={true}
                width={257}
                path="propertyStatusAttrIds"
            />
            {expandCollapseButton}
        </Row>
    );
}

function PropertyFieldsetRow2({ fuzzyMatchingEnabled }) {
    return (
        <Row>
            <div data-test-id={testIds.PROPERTY_DESCRIPTION}>
                <RRFText
                    width={257}
                    className={classNames({
                        'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                    })}
                    path="description"
                />
            </div>
            <RRFText
                width={257}
                className={classNames({
                    'fuzzy-matching-enabled': fuzzyMatchingEnabled,
                })}
                extraLabelContent={
                    <IconWrapper>
                        <SearchCapabilityIcon variant={SEARCH_CAPABILITY_TYPE_ENUM.WILDCARD.name} />
                    </IconWrapper>
                }
                labelClassName=""
                path="serialNumber"
            />
            <RRFBooleanSelect width={158} path="hasSerialNumber" />
        </Row>
    );
}

function PropertyFieldsetRow3({ includeDepartmentIds }) {
    return (
        <Row>
            <RRFAttributeSelect
                attributeType="FIREARM_MAKE"
                multiple={true}
                includeExpired={true}
                width={451}
                path="firearmMakeAttrIds"
            />
            <FeatureFlagged flag="RMS_ETRACE_ATF_INTEGRATION_ENABLED">
                <RRFAftManufacturerSelect multiple={true} path="atfManufacturerIds" width={451} />
            </FeatureFlagged>
            <RRFAttributeSelect
                attributeType="ITEM_COLOR"
                multiple={true}
                includeExpired={true}
                width={183}
                path="primaryColorAttrIds"
            />
            {includeDepartmentIds && (
                <RRFConsortiumDepartmentSelect path="departmentIds" multiple={true} width={183} />
            )}
        </Row>
    );
}

/**
 * Fieldset for a single property.
 * @param {Object}  props
 * @param {boolean} props.fuzzyMatchingEnabled
 * @param {boolean} [includeDepartmentIds=false]
 */
export const PropertyFieldset = pure(function PropertyFieldset({
    fuzzyMatchingEnabled,
    includeDepartmentIds = false,
    ...otherProps
}) {
    return (
        <RRFFieldset path="property" {...otherProps}>
            <PropertyCategoryRow />
            <PropertyStatusRow />
            <PropertyFieldsetRow2 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <PropertyFieldsetRow3 includeDepartmentIds={includeDepartmentIds} />
            <ItemIdentifiersFieldset
                highlightOnFocus={false}
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                showHeader={false}
            />
        </RRFFieldset>
    );
});

/**
 * Collapsible fieldset for a single property.
 * @param {Object}  props
 * @param {boolean} props.fuzzyMatchingEnabled
 */
function CollapsiblePropertyFieldset({ fuzzyMatchingEnabled, ...otherProps }) {
    return (
        <CollapsibleFieldset
            expandedChildren={
                <div>
                    <PropertyFieldsetRow2 fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
                    <PropertyFieldsetRow3 includeDepartmentIds={false} />
                    <ItemIdentifiersFieldset
                        highlightOnFocus={false}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        showHeader={false}
                    />
                </div>
            }
            {...otherProps}
        >
            {(expandCollapseButton) => (
                <PropertyFieldsetRow1 expandCollapseButton={expandCollapseButton} />
            )}
        </CollapsibleFieldset>
    );
}

/**
 * N property (firearm, etc.) fieldsets, each of which is collapsible.
 * @param {Object}  props
 * @param {boolean} fuzzyMatchingEnabled
 */
export default pure(function PropertiesFieldset({ fuzzyMatchingEnabled, ...otherProps }) {
    return (
        <RRFNFieldsets automaticallyIncludeDeleteButton={false} {...otherProps}>
            {(fieldsetProps) => (
                <CollapsiblePropertyFieldset
                    fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                    {...fieldsetProps}
                />
            )}
        </RRFNFieldsets>
    );
});

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import arrestUcrClassificationForm from '../state/forms/arrestUcrClassificationForm';
import { RRFSingleUcrCodeSelect } from '../../../core/forms/components/selects/UcrCodeSelect';

const strings = componentStrings.reports.ArrestUcrClassificationSidePanel;

const OffenseUcrWrapper = styled.div`
    font-size: var(--arc-fontSizes-sm);
    padding-bottom: 10px;
`;

function ArrestUcrClassificationForm({ formModelByPath }) {
    const offenseUcrName = formModelByPath('offenseUcrName');
    const offenseDisplayName = useOffenseFieldName();
    return (
        <ReactReduxForm {...arrestUcrClassificationForm}>
            {offenseUcrName && (
                <OffenseUcrWrapper>
                    {strings.ArrestUcrClassificationForm.ucrLabel(
                        offenseUcrName,
                        offenseDisplayName
                    )}
                </OffenseUcrWrapper>
            )}
            <RRFSingleUcrCodeSelect
                path="arrestUcr"
                label={strings.ArrestUcrClassificationForm.arrestUcrClassification}
            />
        </ReactReduxForm>
    );
}

const mapStateToProps = createStructuredSelector({
    formModelByPath: arrestUcrClassificationForm.selectors.formModelByPathSelector,
});

export default connect(mapStateToProps)(ArrestUcrClassificationForm);

import { DeepPartial } from 'utility-types';
import { keys, merge, pick } from 'lodash';
import {
    createField,
    createFormConfiguration,
    createNItems,
    formEvents,
    InferFormDataShape,
    lifecycleOptions,
    _Form,
} from 'markformythree';
import {
    ExportPresetView,
    ExportReportVersionEnum,
    ExportReportVersionEnumType,
    ExportOptionTypeEnum,
    RefContextEnum,
} from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    convertExportPresetReportTypesToFormModel,
    convertExportPresetReportTypesFromFormModel,
    exportPresetReportTypeFormConfiguration,
} from '../../report-types/forms/exportPresetReportTypesForm';
import { ReportTypePacket } from '../../report-types/state/data';
import {
    exportOptionFields,
    convertExportPresetOptionsFromFormModel,
    convertExportPresetOptionsToFormModel,
} from './exportPresetOptionsForm';

export const exportPresetReportTypesPath = 'exportPresetReportTypes';
export const isDisplayedInExportDropdownPath = 'isDisplayedInExportDropdown';
export const isDisplayedOnExportPagePath = 'isDisplayedOnExportPage';
export const releasedToAttrIdPath = 'releasedToAttrId';
export const remarksPath = 'remarks';
export const titlePath = 'title';
export const exportReportVersionPath = 'exportReportVersion';

const exportPresetFields = {
    id: createField<number>({}),
    departmentId: createField<number>({}),
    [titlePath]: createField<string>({
        fieldName: fields.EXPORT_PRESET_TITLE,
    }),
    [isDisplayedInExportDropdownPath]: createField<boolean>({
        fieldName: fields.EXPORT_PRESET_IS_DISPLAYED_IN_EXPORT_DROPDOWN,
    }),
    [isDisplayedOnExportPagePath]: createField<boolean>({
        fieldName: fields.EXPORT_PRESET_IS_DISPLAYED_ON_EXPORT_PAGE,
    }),
    [releasedToAttrIdPath]: createField<number>({
        fieldName: fields.EXPORT_PRESET_RELEASED_TO_ATTR_ID,
    }),
    [remarksPath]: createField<string>({
        fieldName: fields.EXPORT_PRESET_REMARKS,
    }),
    isDefaultExportPreset: createField<boolean>({}),
    [exportPresetReportTypesPath]: createNItems({
        fields: {
            ...exportPresetReportTypeFormConfiguration,
        },
    }),
    reportPrintingTemplates: createNItems({
        fields: {},
    }),
    [exportReportVersionPath]: createField<ExportReportVersionEnumType>({
        fieldName: fields.EXPORT_PRESET_EXPORT_REPORT_VERSION,
    }),
};

const exportPresetFormConfiguration = createFormConfiguration(
    merge(exportPresetFields, exportOptionFields)
);
export type ExportPresetFormConfiguration = typeof exportPresetFormConfiguration;
export type ExportPresetForm = _Form<ExportPresetFormConfiguration>;
export type ExportPresetFormDataShape = InferFormDataShape<ExportPresetFormConfiguration>;

export function convertToFormModel(
    exportPreset: ExportPresetView,
    defaultReportTypePackets: ReportTypePacket[]
) {
    const formModel = pick(exportPreset, keys(exportPresetFormConfiguration)) as ExportPresetView;
    const exportPresetOptions = convertExportPresetOptionsToFormModel(
        exportPreset.exportPresetOptions
    );
    const exportPresetReportTypes = convertExportPresetReportTypesToFormModel(
        formModel.id,
        formModel.exportPresetReportTypes,
        defaultReportTypePackets
    );
    return {
        ...formModel,
        ...exportPresetOptions,
        exportPresetReportTypes,
    };
}

export function convertFromFormModel(
    formModel: ExportPresetFormDataShape,
    selectedId?: number
): DeepPartial<ExportPresetView> {
    const exportPresetOptions = convertExportPresetOptionsFromFormModel({
        ...formModel,
        id: selectedId,
    });
    const exportPresetReportTypes = convertExportPresetReportTypesFromFormModel(
        selectedId,
        !!formModel.isDefaultExportPreset,
        formModel.exportPresetReportTypes || []
    );
    return {
        ...formModel,
        exportPresetOptions,
        exportPresetReportTypes,
    };
}

const defaultChecked = {
    [isDisplayedOnExportPagePath]: true,
    [ExportOptionTypeEnum.ONLY_INCLUDE_FIELDS_WITH_DATA.name]: true,
    [exportReportVersionPath]: ExportReportVersionEnum.CURRENT.name,
};

const exportPresetAdminForm = {
    name: RefContextEnum.FORM_ADMIN_EXPORT_PRESET.name,
    configuration: exportPresetFormConfiguration,
    lifecycle: lifecycleOptions.REGISTER_AND_UNREGISTER,
    validationEvents: [{ eventType: formEvents.FORM_SUBMIT }],
    initialState: defaultChecked,
};

export default exportPresetAdminForm;

import React, { useRef } from 'react';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
    CompleteClientReportView,
    EntityTypeEnum,
    LinkTypesEnum,
    ReportShortTitle,
} from '@mark43/rms-api';
import styled from 'styled-components';
import { Skeleton, SkeletonText, Stack, cssVar } from 'arc';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { reportDefinitionHasCardSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { formatReportTitleForReportIdSelector } from '~/client-common/core/domain/reports/state/ui';
import { offensesSelector } from '~/client-common/core/domain/offenses/state/data';
import { nameReportLinksSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../core/overlays/components/Modal';
import Row from '../../../core/components/Row';
import _OverlayButton, { buttonTypes } from '../../../core/overlays/components/OverlayButton';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { RmsDispatch } from '../../../../core/typings/redux';
import {
    canEditReportCardStatusSelector,
    isReportNewSelector,
} from '../../../../legacy-redux/selectors/reportSelectors';
import reportsResource from '../resources/reportsResource';
import { editMediator } from '../state/ui/submissions';
import {
    loadEmbeddedReportStart,
    loadEmbeddedReportSuccess,
    loadEmbeddedReportFailure,
    hideEmbeddedReport,
    storeEmbeddedArrestReport,
    embeddedReportShortTitlesSelector,
    initialEmbeddedArrestReportCardsUiState,
    embeddedReportsUiSelector,
} from '../state/ui';
import defendantIsSuspect from '../utils/defendantIsSuspect';
import testIds from '../../../../core/testIds';
import { CardComponent } from '../../../../legacy-redux/components/core/Card';
import AttachmentsCard from '../../../core/components/cards/AttachmentsCard';
import RoutingLabelsModal from '../../../records/core/components/RoutingLabelsModal';
import { dragonDataSelector } from '../../../dragon/dragonRedux';
import { RMSDragonForm } from '../../../dragon/components/rms/rms-dragon-form';
import DeleteReportModal from './header/DeleteReportModal';
import ArrestCard from './arrest/ArrestCard';
import VehicleCard from './items/VehicleCard';
import ChargesCard from './charges/ChargesCard';
import BookingCard from './booking/BookingCard';
import CourtCaseCard from './court-case/CourtCaseCard';
import NarrativeCard from './narrative/NarrativeCard';
import SummaryNarrativeCardWrapper from './summary-narrative/SummaryNarrativeCard';
import ArrestBlockDropdownMenu from './ArrestBlockDropdownMenu';

const strings = componentStrings.reports.core.ArrestBlock;

export const ARREST_BLOCK_ANCHOR = 'arrest-block';

const ArrestBlockCardsLoader = () => {
    return (
        <Stack
            spacing={cssVar('arc.space.4')}
            background={cssVar('arc.colors.surface.foreground')}
            margin={`0 ${cssVar('arc.space.1')} ${cssVar('arc.space.4')} ${cssVar('arc.space.1')}`}
            padding={cssVar('arc.space.3')}
            borderRadius={cssVar('arc.radii.md')}
            border={`1px solid ${cssVar('arc.colors.control.border.default')}`}
            borderTop={`solid 2rem ${cssVar('arc.colors.control.border.default')}`}
        >
            <Skeleton />
            <SkeletonText noOfLines={3} />
        </Stack>
    );
};

const Container = styled.div`
    margin: 20px -5px 0 -5px;
`;

const Header = styled.div`
    height: 60px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px 4px 0 0;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const Title = styled.div`
    font-size: var(--arc-fontSizes-xl);
    font-weight: ${(props) => props.theme.fontWeights.bold};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Body = styled.div`
    background-color: var(--arc-colors-surface-background);
    border-top: none;
    border-right: 1px solid ${({ theme }) => theme.colors.lightGrey};
    border-left: 1px solid ${({ theme }) => theme.colors.lightGrey};
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    padding-top: 16px;

    & ${/* sc-selector */ CardComponent} {
        margin: 0 auto 16px;
    }
`;

const OverlayButton = styled(_OverlayButton)`
    float: right;
    margin: 0 4px 16px;
`;

type EmbeddedReportsUi = ReturnType<typeof embeddedReportsUiSelector>;

function useDefendantIsSuspect() {
    const offenses = useSelector(offensesSelector);
    const nameReportLinks = useSelector(nameReportLinksSelector);
    const personProfiles = useSelector(personProfilesSelector);

    return React.useCallback(
        (arrestCompleteClientReportView: CompleteClientReportView, offenseReportId: number) =>
            defendantIsSuspect({
                arrestCompleteClientReportView,
                offenseReportId,
                offenses,
                nameReportLinks,
                personProfiles,
            }),
        [offenses, nameReportLinks, personProfiles]
    );
}

function useEmbeddedArrestReport(
    reportId: number,
    offenseReportId: number
): EmbeddedReportsUi[keyof EmbeddedReportsUi] {
    const dispatch: RmsDispatch = useDispatch();

    const ui = useSelector(embeddedReportsUiSelector)[reportId];
    const defendantIsSuspect = useDefendantIsSuspect();

    React.useEffect(() => {
        if (!ui) {
            // load the report data when it hasn't been loaded yet
            dispatch(loadEmbeddedReportStart(reportId));
            reportsResource
                .getFullReport(reportId)
                .then((completeClientReportView) => {
                    if (defendantIsSuspect(completeClientReportView, offenseReportId)) {
                        dispatch(storeEmbeddedArrestReport(completeClientReportView));
                        dispatch(
                            initialEmbeddedArrestReportCardsUiState(reportId, { isNew: false })
                        );
                        dispatch(loadEmbeddedReportSuccess(reportId));
                    } else {
                        dispatch(hideEmbeddedReport(reportId));
                    }
                })
                .catch((err) => {
                    dispatch(loadEmbeddedReportFailure(reportId, err.message));
                });
        }
    }, [reportId, ui, defendantIsSuspect, offenseReportId, dispatch]);

    return ui || { loading: true, loadedAndVisible: false };
}

const SeparateEmbeddedReportModal: React.FC<{
    reportId: number;
    buttonElement: React.ReactNode;
}> = ({ reportId, buttonElement }) => {
    const dispatch: RmsDispatch = useDispatch();
    const separateReports = React.useCallback(() => {
        // open the arrest report in a new tab
        window.open(`#/reports/${reportId}?validate=true`, '_blank');
        // hide the arrest block from the current report
        dispatch(hideEmbeddedReport(reportId));
    }, [reportId, dispatch]);

    return (
        <Modal
            id={`${overlayIdEnum.SEPARATE_EMBEDDED_REPORT_MODAL}.${reportId}`}
            title={strings.separateAndSubmit}
            okText={strings.separateAndSubmit}
            onSave={separateReports}
            buttonElement={buttonElement}
        >
            {strings.separateAndSubmitMessage}
        </Modal>
    );
};

const ArrestBlockAttachmentsCard: React.FC<{
    reportId: number;
    editCallback: (callback: () => void) => void;
    index: number;
}> = ({ reportId, editCallback, index }) => {
    const canEditReportCardStatus = useSelector(canEditReportCardStatusSelector);
    const formatReportTitleForReportId = useSelector(formatReportTitleForReportIdSelector);

    return (
        <AttachmentsCard
            entityTitle={formatReportTitleForReportId(reportId, {
                includeRenDisplayName: false,
            })}
            entityType={EntityTypeEnum.REPORT.name}
            entityId={reportId}
            index={index}
            editCallback={editCallback}
            linkType={LinkTypesEnum.REPORT_ATTACHMENT}
            canEdit={canEditReportCardStatus ? canEditReportCardStatus.canEditReportCard : false}
            canEditErrorMessage={
                canEditReportCardStatus ? canEditReportCardStatus.errorMessage : undefined
            }
        />
    );
};

function useOnEditCard() {
    const dispatch: RmsDispatch = useDispatch();
    const onEditCard = React.useCallback(
        (callback: () => void) => dispatch(editMediator(callback)),
        [dispatch]
    );
    return {
        onEditCard,
    };
}

type ArrestBlockCardsPropsT = {
    arrestReportId: number;
    reportDefinitionId: number;
    isLoading?: boolean;
};

const ArrestBlockCards: React.FC<ArrestBlockCardsPropsT> = ({
    arrestReportId,
    reportDefinitionId,
    isLoading,
}) => {
    const { onEditCard } = useOnEditCard();
    const reportDefinitionHasCard = useSelector(reportDefinitionHasCardSelector);
    const report = useSelector(reportByIdSelector)(arrestReportId);
    const isNewReport = useSelector(isReportNewSelector)(arrestReportId);

    const dragonData = useSelector(dragonDataSelector)[arrestReportId];
    return (
        <>
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.ARREST.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <ArrestCard
                        currentReportId={arrestReportId}
                        editCallback={onEditCard}
                        index={arrestReportId}
                    />
                ))}
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.VEHICLE.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <VehicleCard
                        editCallback={onEditCard}
                        currentReportId={arrestReportId}
                        index={arrestReportId}
                        reportingEventNumber={report?.reportingEventNumber}
                        ownerType={EntityTypeEnum.REPORT.name}
                        ownerId={arrestReportId}
                    />
                ))}
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.CHARGES.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <ChargesCard
                        reportId={arrestReportId}
                        editCallback={onEditCard}
                        index={arrestReportId}
                    />
                ))}
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.BOOKING.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <BookingCard
                        currentReportId={arrestReportId}
                        editCallback={onEditCard}
                        index={arrestReportId}
                    />
                ))}
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.COURT_CASE.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <CourtCaseCard
                        currentReportId={arrestReportId}
                        editCallback={onEditCard}
                        index={arrestReportId}
                    />
                ))}
            {dragonData && (
                <RMSDragonForm
                    formData={dragonData.form}
                    summaryData={dragonData.summary}
                    isNewReport={isNewReport}
                    reportId={arrestReportId}
                />
            )}
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.SUMMARY_NARRATIVE.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <SummaryNarrativeCardWrapper
                        editCallback={onEditCard}
                        currentReportId={arrestReportId}
                        index={arrestReportId}
                        isEmbedded={true}
                    />
                ))}
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.NARRATIVE.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <NarrativeCard
                        editCallback={onEditCard}
                        currentReportId={arrestReportId}
                        index={arrestReportId}
                        isEmbedded={true}
                    />
                ))}
            {reportDefinitionHasCard(reportDefinitionId, reportCardEnum.ATTACHMENTS.id) &&
                (isLoading ? (
                    <ArrestBlockCardsLoader />
                ) : (
                    <ArrestBlockAttachmentsCard
                        reportId={arrestReportId}
                        editCallback={onEditCard}
                        index={arrestReportId}
                    />
                ))}
            <SeparateEmbeddedReportModal
                reportId={arrestReportId}
                buttonElement={
                    <Row>
                        <OverlayButton
                            id={`${overlayIdEnum.SEPARATE_EMBEDDED_REPORT_MODAL}.${arrestReportId}`}
                            testId={testIds.ARREST_BLOCK_SUBMIT_REPORT_SEPARATELY}
                            className={buttonTypes.SECONDARY}
                            float="right"
                        >
                            {strings.submitReportSeparately}
                        </OverlayButton>
                    </Row>
                }
            />
        </>
    );
};

const ArrestBlock: React.FC<{
    dropdownFocusRef: React.MutableRefObject<HTMLElement | null>;
    reportShortTitle: ReportShortTitle;
    offenseReportId: number;
}> = ({ dropdownFocusRef, reportShortTitle, offenseReportId }) => {
    const { reportId } = reportShortTitle;
    const ui = useEmbeddedArrestReport(reportId, offenseReportId);

    let body: React.ReactNode;
    if (ui.loading) {
        body = (
            <ArrestBlockCards
                arrestReportId={reportShortTitle.reportId}
                reportDefinitionId={reportShortTitle.reportDefinitionId}
                isLoading={true}
            />
        );
    } else if (ui.loadedAndVisible) {
        body = (
            <ArrestBlockCards
                arrestReportId={reportId}
                reportDefinitionId={reportShortTitle.reportDefinitionId}
            />
        );
    } else if (ui.errorMessage) {
        body = <InlineBanner status="error">{ui.errorMessage}</InlineBanner>;
    } else {
        return null;
    }

    return (
        <Container data-test-id={testIds.ARREST_BLOCK} data-anchor={ARREST_BLOCK_ANCHOR}>
            <Header>
                <Title>{reportShortTitle.shortTitle}</Title>
                {ui.loadedAndVisible && (
                    <ArrestBlockDropdownMenu
                        reportId={reportId}
                        dropdownFocusRef={dropdownFocusRef}
                    />
                )}
            </Header>
            <Body>{body}</Body>
        </Container>
    );
};

const ArrestBlocks: React.FC<{ offenseReportId: number }> = ({ offenseReportId }) => {
    const embeddedReportShortTitles = useSelector(embeddedReportShortTitlesSelector);
    const dropdownFocusRef = useRef<HTMLElement | null>(null);

    if (embeddedReportShortTitles.length === 0) {
        return null;
    }

    return (
        <>
            {map(embeddedReportShortTitles, (reportShortTitle) => {
                return (
                    <ArrestBlock
                        key={reportShortTitle.reportId}
                        dropdownFocusRef={dropdownFocusRef}
                        reportShortTitle={reportShortTitle}
                        offenseReportId={offenseReportId}
                    />
                );
            })}
            <DeleteReportModal
                closeFocusRef={dropdownFocusRef}
                overlayId={overlayIdEnum.DELETE_REPORT_FOR_EMBEDDED_REPORT_MODAL}
            />
            <RoutingLabelsModal
                closeFocusRef={dropdownFocusRef}
                overlayId={overlayIdEnum.ROUTING_LABELS_FOR_EMBEDDED_ARREST_MODAL}
            />
        </>
    );
};

export default ArrestBlocks;

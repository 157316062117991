import { useSelector } from 'react-redux';
import { TaskView } from '@mark43/rms-api';
import { filter } from 'lodash';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { taskEntityLinksSelector } from '~/client-common/core/domain/task-entity-links/state/data';
import { convertToFormModel } from '../forms/taskForm';
import { useTaskStatusOptions } from './useTaskStatusOptions';

export const useTaskStatusProps = (task?: TaskView) => {
    const options = useTaskStatusOptions();
    const allTaskEntityLinks = useSelector(taskEntityLinksSelector);
    const { RMS_TASK_ENTITY_LINKS_ENABLED } = useSelector(applicationSettingsSelector);

    if (!task) {
        return { options, taskEntityLinks: [] };
    }

    // @ts-expect-error TaskEntityLinkView type is not aligned with Partial<TaskEntityLinkView>
    const { taskEntityLinks } = convertToFormModel(
        task,
        !!RMS_TASK_ENTITY_LINKS_ENABLED,
        filter(allTaskEntityLinks, { taskId: task.id })
    );

    return { options, taskEntityLinks };
};

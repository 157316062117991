import { get, map } from 'lodash';
import getChargesResource from '~/client-common/core/domain/charges/resources/chargesResource';
import { NEXUS_STATE_PROP as CHARGES_NEXUS_STATE_PROP } from '~/client-common/core/domain/charges/state/data';
import { arrestByIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { arrestRelatedWarrantsSelector } from '~/client-common/core/domain/charges/state/ui';
import { refreshBookingForm } from '../../forms/bookingForm';
import {
    prefillChargesCardDependentArrestNibrsCodeField,
    refreshChargesCardDependentChargesData,
} from '../../forms/chargesForm';

import { setLinkedWarrantIds } from '../../../../../../legacy-redux/actions/reportsActions';
import { storeChargeOffenseViews } from './storeChargeOffenseView';
import { storeChargeWarrantViews } from './storeChargeWarrantView';
import { convertChargeWarrantViewsToFormModelList } from './convertChargeWarrants';

export const getChargeEntityQuickAddView = ({ arrestId }) => (dispatch) => {
    return getChargesResource()
        .getChargeEntityQuickAddViewForArrestId({ arrestId })
        .then((chargeEntityQuickAddView) => {
            const { chargeOffenseViews, chargeWarrantViews } = chargeEntityQuickAddView;
            dispatch(
                // eslint-disable-next-line no-restricted-syntax
                storeChargeOffenseViews({
                    chargeOffenseViews,
                    action: {
                        type: 'ARREST_CHARGES_SIDE_PANEL_INITIAL_LOAD_STORE_CHARGE_OFFENSE_VIEWS',
                    },
                })
            );
            dispatch(
                // eslint-disable-next-line no-restricted-syntax
                storeChargeWarrantViews({
                    chargeWarrantViews: convertChargeWarrantViewsToFormModelList(
                        chargeWarrantViews
                    ),
                    action: {
                        type: 'ARREST_CHARGES_SIDE_PANEL_INITIAL_LOAD_STORE_CHARGE_WARRANT_VIEWS',
                    },
                })
            );
        });
};

const updateLinkedWarrantsInSidebar = ({ arrestId }) => (dispatch, getState) => {
    const state = getState();
    const arrestRelatedWarrants = arrestRelatedWarrantsSelector(state)({ arrestId });
    const arrestLinkedWarrantIds = map(arrestRelatedWarrants, 'id');
    dispatch(setLinkedWarrantIds(arrestLinkedWarrantIds));
};

export const saveNChargesSidePanel = ({ form, formIndex, arrestId, charges }) => (
    dispatch,
    getState,
    { nexus: { withRemove, withEntityItems } }
) => {
    return form
        .submit()
        .then(() => {
            return getChargesResource().replaceChargesForArrestId({ arrestId, charges });
        })
        .then((replacedCharges) => {
            const state = getState();
            const arrestById = arrestByIdSelector(state);
            const arrestReportId = get(arrestById(arrestId), 'reportId');

            dispatch(
                withRemove(
                    CHARGES_NEXUS_STATE_PROP,
                    { arrestId },
                    withEntityItems(
                        { [CHARGES_NEXUS_STATE_PROP]: replacedCharges },
                        { type: 'ARREST_CHARGES_SIDE_PANEL_REPLACE_CHARGES' }
                    )
                )
            );
            dispatch(updateLinkedWarrantsInSidebar({ arrestId }));
            dispatch(refreshBookingForm({ reportId: arrestReportId, index: formIndex }));
            dispatch(
                prefillChargesCardDependentArrestNibrsCodeField({
                    arrestId,
                    formIndex,
                })
            );
            dispatch(refreshChargesCardDependentChargesData({ arrestId, formIndex }));
        });
};

import { ApprovalStatusEnum } from '@mark43/rms-api';
import _, { includes, uniq } from 'lodash';
import approvalStatusClientEnum from '../core/enums/client/approvalStatusClientEnum';
import componentStrings from '../core/strings/componentStrings';
import {
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
} from '../core/enums/universal/fields';

const approvalStatusOptionStrings = componentStrings.forms.select.ApprovalStatusSelect.options;

const {
    APPROVED,
    APPROVED2,
    APPROVED3,
    APPROVED4,
    COMPLETED,
    DRAFT,
    REJECTED,
    SUBMITTED,
} = ApprovalStatusEnum;

export const clientApprovalStatusToFieldNames = {
    [approvalStatusClientEnum.DRAFT]: DISPLAY_ONLY_REPORT_APPROVAL_STATUS_DRAFT,
    [approvalStatusClientEnum.REJECTED]: DISPLAY_ONLY_REPORT_APPROVAL_STATUS_REJECTED,
    [approvalStatusClientEnum.PENDING_SUPERVISOR_REVIEW]: DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SUPERVISOR_REVIEW,
    [approvalStatusClientEnum.PENDING_SECONDARY_REVIEW]: DISPLAY_ONLY_REPORT_APPROVAL_STATUS_PENDING_SECONDARY_REVIEW,
    [approvalStatusClientEnum.COMPLETED]: DISPLAY_ONLY_REPORT_APPROVAL_STATUS_COMPLETED,
};

// additional values to also select when APPROVED is selected
const additionalApprovedValues = [APPROVED2.name, APPROVED3.name, APPROVED4.name, COMPLETED.name];

export const unapprovedApprovalStatuses = [DRAFT.name, REJECTED.name, SUBMITTED.name];

export function formatClientApprovalStatuses(fieldValues) {
    return _(approvalStatusOptionStrings).pick(fieldValues).values().join(', ');
}

export function convertPrimaryApprovalStatusClientEnumValuesToFieldValues(clientEnumValues) {
    if (includes(clientEnumValues, APPROVED.name)) {
        return uniq(clientEnumValues.concat(additionalApprovedValues));
    }
    return clientEnumValues;
}

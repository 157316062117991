import * as React from 'react';
import {
    LocationEntityLinkUiOptions,
    NameReportLinkUiOptions,
    NitemsUiOptions,
} from 'dragon-react';
import { get } from 'lodash';
import { DragonConfiguredPropertyAliasContextProvider } from '../../context/dragon-configured-property-alias';
import { DragonInlineFormReferenceState, RMSDragonConfigurationExtensions } from '../../rms-types';
import { resolveAliasValues } from '../../utils/resolve-alias-values';
import { useDragonInlineFormConfigurationContext } from '../../context/dragon-inline-form-configuration';
import { assertNotNullOrUndefined } from '../../../core/utils/assert-not-null-or-undefined';
import { useMFTFormContext } from '../../context/mft-form';
import { DragonFormNestingPathContextProvider } from '../../context/dragon-current-form';
import {
    getCoreModelOrDragonInstanceId,
    getMetaInstanceView,
} from '../../utils/dragon-instance-ids';

export function InlineFormReferenceWrapper({
    options,
    children,
}: React.PropsWithChildren<{
    options:
        | NitemsUiOptions<RMSDragonConfigurationExtensions>
        | NameReportLinkUiOptions<RMSDragonConfigurationExtensions>
        | LocationEntityLinkUiOptions<RMSDragonConfigurationExtensions>;
}>): JSX.Element | null {
    const indexedInlineForms = useDragonInlineFormConfigurationContext();
    const currentForm = useMFTFormContext();
    const formConfiguration = indexedInlineForms[options.configuration.formConfigurationId];
    assertNotNullOrUndefined(
        formConfiguration,
        `Unexpectedly did not find configuration for inline form with id "${options.configuration.formConfigurationId}"`
    );
    const { configuredFormPropertyAliasViews } = formConfiguration;
    const formValue = get(
        currentForm.getState().model,
        options.fullyQualifiedPath
    ) as DragonInlineFormReferenceState;
    const meta = getMetaInstanceView(formValue);
    const { configuredEntityPropertyValues } = meta;

    return (
        <DragonFormNestingPathContextProvider
            instanceId={getCoreModelOrDragonInstanceId({ __meta: meta })}
            configuredFormId={formConfiguration.id}
            referencingUiConfigurationId={options.configuration.id}
        >
            <DragonConfiguredPropertyAliasContextProvider
                value={resolveAliasValues({
                    configuredEntityPropertyValues,
                    configuredFormPropertyAliasViews,
                })}
            >
                {children}
            </DragonConfiguredPropertyAliasContextProvider>
        </DragonFormNestingPathContextProvider>
    );
}

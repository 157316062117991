import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';

import Button from './Button';

/**
 * A button that toggles between an active state and an inactive state when
 *   clicked.
 * @param {Object}       props                Other props get spread into
 *   `<Button>`.
 * @param {boolean}      props.active
 * @param {ReactElement} [props.activeIcon]   The icon to show when the button
 *   is inactive. If not provided, `inactiveIcon` is always shown.
 * @param {ReactElement} [props.inactiveIcon] The icon to show when the button
 *   is inactive.
 */
export default compose(
    setPropTypes({
        active: PropTypes.bool.isRequired,
        activeIcon: PropTypes.element,
        inactiveIcon: PropTypes.element,
    }),
    withHandlers({
        onClick({ onClick, active }) {
            return () => onClick(!active);
        },
    })
)(function ToggleButton({ active, activeIcon, inactiveIcon, ...otherProps }) {
    return (
        <Button
            {...otherProps}
            active={active}
            iconLeft={active && activeIcon ? activeIcon : inactiveIcon}
        />
    );
});

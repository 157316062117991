import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    DISPLAY_ONLY_CASE_SEARCH_HAS_LINKED_ARREST_LABEL,
    DISPLAY_ONLY_CASE_SEARCH_REPORT_APPROVAL_STATUS_LABEL,
    DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
    OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
    OFFENSE_IS_DOMESTIC_VIOLENCE,
    OFFENSE_IS_SUSPECTED_HATE_CRIME,
    OFFENSE_OFFENSE_CODE_ID,
    REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
    REPORT_REPORTING_EVENT_NUMBER,
    SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { subdivisionAttrFieldsForViewModels } from '../../../../search/core/state/forms/subdivisionFields';

const {
    CLIENT_APPROVAL_STATUS,
    OFFENSE_CODE,
    ATTRIBUTE,
    FIELDSET,
    LABEL,
    RANGE,
} = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;

const strings =
    componentStrings.cases.unassignedReports.UnassignedReportsSearchReportDetailsFieldset;

/**
 * Fieldset view model.
 */
export default {
    type: FIELDSET,
    key: 'reportDetails',
    title: strings.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'reportingEventNumber',
            fieldName: REPORT_REPORTING_EVENT_NUMBER,
        },
        { key: 'subdivisionAttrIds', type: ATTRIBUTE },
        ...subdivisionAttrFieldsForViewModels,
        {
            key: 'withinLastPeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: WITHIN_LAST_PERIOD,
        },
        {
            key: 'toDatePeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: TO_DATE_PERIOD,
        },
        {
            key: 'startDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_START,
        },
        {
            key: 'endDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_END,
        },
        {
            key: 'clientApprovalStatuses',
            type: CLIENT_APPROVAL_STATUS,
            fieldName: DISPLAY_ONLY_CASE_SEARCH_REPORT_APPROVAL_STATUS_LABEL,
        },
        {
            key: 'hasRoutingLabel',
            type: LABEL,
        },
        {
            key: 'routingLabelAttrIds',
            type: ATTRIBUTE,
        },
        {
            key: 'caseStatusAttrIds',
            type: ATTRIBUTE,
            fieldName: REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
        },
        {
            key: 'offenseCaseStatusAttrId',
            type: ATTRIBUTE,
            fieldName: OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
        },
        {
            key: 'hasOffenseCaseStatus',
            fieldName: OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
        },
        // The following two field is not represented in the form ui, and instead
        // is controlled by the 'New' and 'Will Not Investigate' Tabs.
        { key: 'hasCaseStatus' },
        { key: 'excludedCaseStatusAttrIds', type: ATTRIBUTE },
        {
            key: 'createdDateRangeQuery',
            type: FIELDSET,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'withinLastPeriod',
                    type: RANGE,
                    rangeKey: DATE_TIME_RANGE,
                    rangeType: WITHIN_LAST_PERIOD,
                },
                {
                    key: 'toDatePeriod',
                    type: RANGE,
                    rangeKey: DATE_TIME_RANGE,
                    rangeType: TO_DATE_PERIOD,
                },
                {
                    key: 'startDateUtc',
                    type: RANGE,
                    rangeKey: DATE_TIME_RANGE,
                    rangeType: RANGE_START,
                },
                {
                    key: 'endDateUtc',
                    type: RANGE,
                    rangeKey: DATE_TIME_RANGE,
                    rangeType: RANGE_END,
                },
            ]),
        },
        {
            key: 'hasArrest',
            fieldName: DISPLAY_ONLY_CASE_SEARCH_HAS_LINKED_ARREST_LABEL,
        },
        {
            key: 'offenseCodeIds',
            type: OFFENSE_CODE,
            fieldName: OFFENSE_OFFENSE_CODE_ID,
        },
        {
            key: 'offenseClassificationAttrIds',
            type: ATTRIBUTE,
            fieldName: DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
        },
        {
            key: 'isSuspectedHateCrime',
            fieldName: OFFENSE_IS_SUSPECTED_HATE_CRIME,
        },
        {
            key: 'isDomesticViolence',
            fieldName: OFFENSE_IS_DOMESTIC_VIOLENCE,
        },
        {
            key: 'securityClassificationAttrId',
            type: ATTRIBUTE,
            fieldName: SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
        },
    ]),
};

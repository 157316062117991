import React from 'react';
import styled from 'styled-components';
import { ArbiterMFTText } from '../../../../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../../../../core/forms/components/DatePicker';
import { RemoveButton } from '../../../../../core/forms/components/NItems';

const StyledDiv = styled.div`
    display: flex;
    align-items: center;
`;

const CourtCasesNItems = ({ items, index, removeItem }) => (
    <StyledDiv>
        <ArbiterMFTDatePicker path="courtDateUtc" includeTime={true} />
        <ArbiterMFTText path="courtRoomNumber" width="45%" />
        {(index > 0 || items.length > 1) && <RemoveButton onClick={removeItem} />}
    </StyledDiv>
);

export default CourtCasesNItems;

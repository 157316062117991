import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityTypeEnum, NameCaseLink } from '@mark43/rms-api';
import styled from 'styled-components';
import { cssVar } from 'arc';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import testIds from '../../../../core/testIds';
import { AddNameButton } from '../../../core/components/AddNameButton';
import { PersonSidePanel } from '../../../core/persons/components/PersonSidePanel';
import { OrganizationSidePanel } from '../../../core/organizations/components/OrganizationSidePanel';
import { OverlayRenderProps } from '../../../core/components/OverlayBaseHelper';
import { openItemSidePanel } from '../../../reports/core/state/ui';
import { subscribe as dragonEventSubscribe } from '../../../dragon/events/broadcast';
import { currentCaseIdSelector } from '../../core/state/ui';
import { addNameCaseLink, addVehicleCaseLink, removeVehicleCaseLink } from '../state/ui/caseLinkedProfilesSidePanel';
import { useCaseLinkedProfileLabels } from '../hooks';

const personOverlayId = overlayIdEnum.PERSON_OVERLAY_NAME_SUMMARY_VIEW_WRAPPER;
const organizationOverlayId = overlayIdEnum.ORGANIZATION_OVERLAY_NAME_SUMMARY_VIEW_WRAPPER;

const Header = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    box-shadow: 0 3px 3px -3px ${(props) => props.theme.colors.darkGrey};
`;

const HeaderRow = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    padding: 3px 26px;
    font-size: ${cssVar('arc.fontSizes.sm')};
`;

const CaseLinkedProfilesSidePanelHeader = () => {
    const dispatch = useDispatch();
    const { personLabel, organizationLabel, vehicleLabel } = useCaseLinkedProfileLabels();
    const currentCaseId: number = useSelector(currentCaseIdSelector);

    const panelProps = {
        contextId: currentCaseId,
        contextType: EntityTypeEnum.CASE.name,
        ownerId: currentCaseId,
        ownerType: EntityTypeEnum.CASE.name,
        onAddSuccess: (link: NameCaseLink) => {
            const key =
                link.entityType === EntityTypeEnum.PERSON_PROFILE.name
                    ? 'personNameCaseLinks'
                    : 'orgNameCaseLinks';
            dispatch(addNameCaseLink(key, link));
        },
    };

    useEffect(() => {
        return dragonEventSubscribe(({ type, payload }) => {
            if (payload.itemType === EntityTypeEnum.VEHICLE.name) {
                if (type === 'ITEMS_MODIFIED') {
                    payload.itemIds.forEach((itemId) => {
                        dispatch(
                            addVehicleCaseLink({
                                itemId,
                            })
                        );
                    });
                } else if (type === 'ITEM_REMOVED') {
                    dispatch(
                        removeVehicleCaseLink({
                            itemId: payload.itemId,
                        })
                    );
                }
            }
        });
    }, [currentCaseId, dispatch]);

    const addVehicleSidePanel = () => {
        dispatch(
            // @ts-expect-error `openItemSidePanel` is not typed yet
            openItemSidePanel({
                itemTypeAttrId: globalAttributes.itemType.vehicle,
                isVehicle: true,
                ownerId: currentCaseId,
                ownerType: EntityTypeEnum.CASE.name,
                operation: itemSidePanelOperationEnum.CREATE,
                isFormHidden: true,
            })
        );
    };

    return (
        <Header>
            <HeaderRow>
                {/* @ts-expect-error `PersonSidePanel` is not typed yet */}
                <PersonSidePanel
                    key={personOverlayId}
                    overlayId={personOverlayId}
                    renderButton={({ overlayBase: { open } }: OverlayRenderProps<object>) => (
                        <AddNameButton
                            testId={testIds.NAME_SUMMARY_VIEW_ADD_PERSON}
                            onClick={open}
                            onlyOption={true}
                        >
                            {personLabel}
                        </AddNameButton>
                    )}
                    {...panelProps}
                />
                {/* @ts-expect-error `OrganizationSidePanel` is not typed yet */}
                <OrganizationSidePanel
                    key={organizationOverlayId}
                    overlayId={organizationOverlayId}
                    renderButton={({ overlayBase: { open } }: OverlayRenderProps<object>) => (
                        <AddNameButton
                            testId={testIds.NAME_SUMMARY_VIEW_ADD_ORGANIZATION}
                            onClick={open}
                            onlyOption={true}
                        >
                            {organizationLabel}
                        </AddNameButton>
                    )}
                    {...panelProps}
                />
                <AddNameButton
                    testId={testIds.NAME_SUMMARY_VIEW_ADD_VEHICLE}
                    onlyOption={true}
                    onClick={addVehicleSidePanel}
                >
                    {vehicleLabel}
                </AddNameButton>
            </HeaderRow>
        </Header>
    );
};

export default CaseLinkedProfilesSidePanelHeader;

import React from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import testIds from '../../../../core/testIds';

import { caseNoteLocationByNoteIdSelector } from '../../core/state/ui';

const Container = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
    width: 100%;
    padding: 0 12px;
`;

const RemoveButton = styled(Button)`
    margin-left: 12px;
`;

const CaseNoteLocation = ({ caseNoteId, caseNoteLocationByNoteId, onDelete }) => {
    const location = caseNoteLocationByNoteId(caseNoteId);
    return (
        <Container data-test-id={testIds.CASE_NOTE_LOCATION_CONTAINER}>
            {location && (
                <>
                    <Icon
                        color="mediumLightGrey"
                        size={iconSizes.SMALL}
                        type={iconTypes.LOCATION}
                    />
                    {getViewModelProperties(location).names.full}
                    {onDelete && (
                        <RemoveButton
                            testId={testIds.TRASH_BUTTON}
                            onClick={onDelete}
                            iconLeft={
                                <Icon
                                    size={iconSizes.SMALL}
                                    color="cobaltBlue"
                                    type={iconTypes.TRASH_CAN}
                                />
                            }
                            className={buttonTypes.ICON_LINK}
                        />
                    )}
                </>
            )}
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    caseNoteLocationByNoteId: caseNoteLocationByNoteIdSelector,
});

export default connect(mapStateToProps)(CaseNoteLocation);

import { CrashLocationCardBundle } from '@mark43/rms-api';
import {
    CrashLocationCardBundleForUpsert,
    NEXUS_STATE_PROP,
    updateCrashLocationFailure,
    updateCrashLocationStart,
    updateCrashLocationSuccess,
} from '~/client-common/core/domain/crash-locations/state/data';

import { RmsAction } from '../../../../../core/typings/redux';
import crashLocationResource from '../../resources/crashLocationResource';

export const updateCrashLocation =
    (
        crashLocationCardBundle: CrashLocationCardBundleForUpsert,
        reportId: number
    ): RmsAction<Promise<CrashLocationCardBundle>> =>
    (dispatch, getState, { nexus: { withEntityItems } }) => {
        dispatch(updateCrashLocationStart(crashLocationCardBundle));
        return crashLocationResource
            .upsertCrashLocationCard(crashLocationCardBundle, reportId)
            .then((updatedCardBundle) => {
                dispatch(
                    withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [updatedCardBundle.crashLocation],
                        },
                        { type: 'STORE_CRASH_EVENT_LOCATION' }
                    )
                );
                dispatch(updateCrashLocationSuccess(updatedCardBundle));
                return updatedCardBundle;
            })
            .catch((err: Error) => {
                dispatch(updateCrashLocationFailure(err.message));
                throw err;
            });
    };

import styled from 'styled-components';

export default styled.div`
    display: inline-block;
    font-family: 'proxima-nova', sans-serif;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
    text-transform: uppercase;
    cursor: pointer;

    i::before {
        font-size: 14px;
    }
`;

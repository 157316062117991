import {
    exportsPageLoad,
    loadExportPacketOptions,
} from '../../../../../legacy-redux/actions/exportsActions';

export function loadCaseExports(caseId) {
    return (dispatch) => {
        dispatch(exportsPageLoad());
        dispatch(loadExportPacketOptions('CASE', caseId));
    };
}

import * as React from 'react';
import { simpleControl } from 'markformythree';

import invariant from 'invariant';
import {
    DragonReportCoreEntityDataLoader,
    useDragonReportCoreEntityDataLoader,
} from '../../../context/dragon-report-core-entity-data-loaders';
import { subscribe as dragonEventSubscribe } from '../../../events/broadcast';
import type { ReportOptionCoreEntityType } from '../../../rms-types';
import {
    DragonOption,
    useDragonSelectBase,
    UseDragonSelectBaseOptions,
    UseDragonSelectBaseResult,
} from './use-dragon-select-base';

import { dragonSelectStateReducer } from './dragon-select-state-reducer';

type UseDragonReportCoreEntitySelectOptions = {
    uiConfigurationId: string;
    value?: Record<string, unknown>;
    optionsDataLoader: DragonReportCoreEntityDataLoader;
} & Omit<UseDragonSelectBaseOptions, 'optionData' | 'isMultiple' | 'value'>;

type UseDragonEntitySelectResult = UseDragonSelectBaseResult & {
    refetchOptions: () => void;
    onBlur: UseDragonSelectBaseOptions['onBlur'];
    isLoading: boolean;
    error?: string;
};

function useDragonReportCoreEntitySelect({
    uiConfigurationId,
    onChange,
    onBlur = () => {},
    displayPropertyId,
    sortPropertyId,
    valuePropertyId,
    value,
    optionsDataLoader,
}: UseDragonReportCoreEntitySelectOptions): Omit<UseDragonEntitySelectResult, 'value'> & {
    value?: number;
} {
    const [state, dispatch] = React.useReducer(dragonSelectStateReducer, {
        isLoading: false,
        error: undefined,
        data: undefined,
    });

    const ignoreRef = React.useRef(false);
    const refetchOptions = React.useCallback(() => {
        dispatch({ type: 'LOADING_START' });
        optionsDataLoader
            .clear(uiConfigurationId)
            .load(uiConfigurationId)
            .then((result) => {
                if (ignoreRef.current) {
                    return;
                }
                dispatch({ type: 'LOADING_SUCCESS', payload: result });
            })
            .catch((err) => {
                if (ignoreRef.current) {
                    return;
                }
                // instantly clear out the result in case of a failure so that fetching will be re-attempted when this select is re-opened
                optionsDataLoader.clear(uiConfigurationId);
                dispatch({ type: 'LOADING_FAILURE', payload: err });
            });
    }, [optionsDataLoader, uiConfigurationId]);

    React.useEffect(() => {
        refetchOptions();
        return () => {
            ignoreRef.current = true;
        };
    }, [refetchOptions]);

    const optionData = state.data;
    const dragonSelectBaseResult = useDragonSelectBase({
        optionData,
        displayPropertyId,
        sortPropertyId,
        valuePropertyId,
        onChange,
        // core entity selects right now are not allowed to contain multiple values
        isMultiple: false,
        value,
    });

    const { value: mappedValue } = dragonSelectBaseResult;
    invariant(
        !mappedValue || !Array.isArray(mappedValue),
        'Unexpectedly found array value for dragon core entity select value'
    );

    return {
        ...dragonSelectBaseResult,
        value: mappedValue,
        refetchOptions,
        onBlur,
        error: state.error?.message,
        isLoading: state.isLoading,
    };
}

type RenderOptions = Omit<UseDragonEntitySelectResult, 'refetchOptions' | 'value'> & {
    value?: number;
};

type DragonReportCoreEntitySelect = {
    uiConfigurationId: string;
    displayPropertyId: number;
    sortPropertyId?: number;
    valuePropertyId: number;
    entityType: ReportOptionCoreEntityType;
    children: (options: RenderOptions) => React.ReactElement;
};

function DragonReportCoreEntitySelectBase({
    uiConfigurationId,
    displayPropertyId,
    sortPropertyId,
    valuePropertyId,
    value: mftValue,
    onChange: mftOnChange,
    children,
    error: mftFieldError,
    entityType,
}: DragonReportCoreEntitySelect & {
    value?: DragonOption;
    onChange: (val: unknown) => void;
    error?: string;
}) {
    const optionsDataLoader = useDragonReportCoreEntityDataLoader(entityType);
    const useDragonEntitySelectResult = useDragonReportCoreEntitySelect({
        valuePropertyId,
        displayPropertyId,
        sortPropertyId,
        uiConfigurationId,
        onChange: mftOnChange,
        value: mftValue,
        optionsDataLoader,
    });
    const valueRef = React.useRef(useDragonEntitySelectResult.value);
    valueRef.current = useDragonEntitySelectResult.value;
    const { refetchOptions, ...rest } = useDragonEntitySelectResult;
    React.useEffect(() => {
        return dragonEventSubscribe((event) => {
            if (event.payload.itemType === entityType) {
                if (event.type === 'ITEMS_MODIFIED' || event.type === 'ITEM_REMOVED') {
                    refetchOptions();
                }
                if (event.type === 'ITEM_REMOVED' && event.payload.itemId === valueRef.current) {
                    mftOnChange(undefined);
                }
            }
        });
    }, [refetchOptions, mftOnChange, entityType]);

    return children({
        ...rest,
        error: useDragonEntitySelectResult.error || mftFieldError || '',
    });
}

export const DragonReportCoreEntitySelect: React.ComponentType<
    Omit<DragonReportCoreEntitySelect, 'optionsDataLoader'> & { path: string }
> = simpleControl(DragonReportCoreEntitySelectBase);

import _, { concat, filter, find, flatMap, get, map, omit, first } from 'lodash';

import {
    Arrest,
    ArrestAttribute,
    LocationView,
    NameReportLink,
    RefContextEnum,
    Warrant,
    AttributeTypeEnum,
    EntityTypeEnum,
    LinkTypesEnum,
    AttributeView,
} from '@mark43/rms-api';
import {
    _Form,
    InferFormDataShape,
    createField,
    createFormConfiguration,
    createFieldset,
    createNItems,
} from 'markformythree';

import { FormatFieldByName } from '~/client-common/core/fields/state/config';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { filterFormData } from '~/client-common/helpers/formHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import {
    hasLinkedVehiclesSelector,
    hydratedArrestByReportIdSelector,
} from '~/client-common/core/domain/reports/state/ui/arrests';
import { eventDetailByReportIdSelector } from '~/client-common/core/domain/event-details/state/data';
import { ModuleShape } from '~/client-common/redux/state';

import { currentReportIdSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import formsRegistry from '../../../../../core/formsRegistry';
import { currentUserProfileSelector } from '../../../../core/current-user/state/ui';
import { RmsAction } from '../../../../../core/typings/redux';

const ARREST_FORM_ARREST_FIELDSET_KEY = 'arrest';
const ARREST_ID_FIELD_KEY = 'id';
const ARREST_REPORT_ID_FIELD_KEY = 'reportId';
const ARREST_FORM_ARREST_DATE_UTC_FIELD_KEY = 'arrestDateUtc';
const ARREST_FORM_DEFENDANT_ID_FIELD_KEY = 'defendantId';
export const ARREST_FORM_ARREST_ID_PATH = `${ARREST_FORM_ARREST_FIELDSET_KEY}.${ARREST_ID_FIELD_KEY}`;
export const ARREST_FORM_REPORT_ID_PATH = `${ARREST_FORM_ARREST_FIELDSET_KEY}.${ARREST_REPORT_ID_FIELD_KEY}`;
export const ARREST_FORM_ARREST_DATE_UTC_PATH = `${ARREST_FORM_ARREST_FIELDSET_KEY}.${ARREST_FORM_ARREST_DATE_UTC_FIELD_KEY}`;
export const ARREST_FORM_DEFENDANT_ID_PATH = `${ARREST_FORM_ARREST_FIELDSET_KEY}.${ARREST_FORM_DEFENDANT_ID_FIELD_KEY}`;

export const formName = RefContextEnum.FORM_ARREST.name;

export function getArrestForm(index: number) {
    return formsRegistry.get(formName, index);
}

export function buildArrestCardFormModel({
    reportId,
}: {
    reportId: number;
}): RmsAction<ReturnType<typeof convertToFormModel>> {
    return (dispatch, getState) => {
        const state = getState();
        let hydratedArrest = hydratedArrestByReportIdSelector(state)(reportId);

        if (!hydratedArrest.arrest.arrestingAgencyAttrId) {
            const defaultArrestingAgencyAttrId = currentUserProfileSelector(state)
                ?.defaultArrestingAgencyAttrId;

            hydratedArrest = {
                ...hydratedArrest,
                arrest: {
                    ...hydratedArrest.arrest,
                    arrestingAgencyAttrId: defaultArrestingAgencyAttrId,
                },
            };
        }

        // the report id from global Redux state may be different from this card's report id in the Dynamic Report
        // workflow
        const currentReportId = currentReportIdSelector(state);
        const eventDetailByReportId = eventDetailByReportIdSelector(state);
        const eventStartUtc = currentReportId
            ? get(eventDetailByReportId(currentReportId), 'eventStartUtc')
            : undefined;
        // @ts-expect-error client-common to client RND-7529
        return convertToFormModel({ ...hydratedArrest, eventStartUtc });
    };
}

export function refreshArrestCardForm(reportId: number, index?: number): RmsAction<void> {
    return (dispatch) => {
        const formModel = dispatch(buildArrestCardFormModel({ reportId }));
        formsRegistry.maybeDeferredOperation(formName, index, (form) => {
            form.set('', formModel);
        });
    };
}

export function refreshArrestCardFormVehicles(reportId: number, index?: number): RmsAction<void> {
    return (dispatch, getState) => {
        const hasLinkedVehicles = hasLinkedVehiclesSelector(getState())(reportId);
        formsRegistry.maybeDeferredOperation(formName, index, (form) => {
            form.set('arrest.hasLinkedVehicles', hasLinkedVehicles);
        });
    };
}

export const formConfiguration = createFormConfiguration({
    [ARREST_FORM_ARREST_FIELDSET_KEY]: createFieldset({
        fields: {
            [ARREST_ID_FIELD_KEY]: createField<number>({}),
            [ARREST_REPORT_ID_FIELD_KEY]: createField<number>({}),
            localId: createField<string>({}),
            [ARREST_FORM_DEFENDANT_ID_FIELD_KEY]: createField<number>({
                fieldName: fields.ARREST_DEFENDANT_ID,
            }),
            walesNcicCheckNumbers: createField<string>({
                fieldName: fields.ARREST_WALES_NCIC_CHECK_NUMBERS,
            }),
            arrestTypeAttrId: createField<string>({ fieldName: fields.ARREST_ARREST_TYPE_ATTR_ID }),
            [ARREST_FORM_ARREST_DATE_UTC_FIELD_KEY]: createField<string>({
                fieldName: fields.ARREST_ARREST_DATE_UTC,
            }),
            arrestingOfficerId: createField<number>({
                fieldName: fields.ARREST_ARRESTING_OFFICER_ID,
            }),
            arrestingAgencyAttrId: createField<number>({
                fieldName: fields.ARREST_ARRESTING_AGENCY_ATTR_ID,
            }),
            advisedRights: createField<boolean>({ fieldName: fields.ARREST_ADVISED_RIGHTS }),
            advisedRightsOfficerId: createField<number>({
                fieldName: fields.ARREST_ADVISED_RIGHTS_OFFICER_ID,
            }),
            advisedRightsResponseAttrId: createField<number>({
                fieldName: fields.ARREST_ADVISED_RIGHTS_RESPONSE_ATTR_ID,
            }),
            advisedRightsResponseOther: createField<string>({
                fieldName: fields.ARREST_ADVISED_RIGHTS_RESPONSE_OTHER,
            }),
            ticketNumbers: createField<string>({ fieldName: fields.ARREST_TICKET_NUMBERS }),
            arrestCodefendant: createField<Partial<NameReportLink>>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_CO_DEFENDANT_LINK_TYPE,
            }),
            hasLinkedVehicles: createField<boolean>({
                fieldName: fields.DISPLAY_ONLY_ARREST_HAS_LINKED_VEHICLES,
            }),
            arrestComplainant: createField<Partial<NameReportLink[]>>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_COMPLAINANT_IN_REPORT_LINK_TYPE,
            }),
            isArresteeSuspectedUsing: createField<boolean>({
                fieldName: fields.ARREST_IS_ARRESTEE_SUSPECTED_USING,
            }),
            wasArresteeFingerprinted: createField<boolean>({
                fieldName: fields.ARREST_WAS_ARRESTEE_FINGERPRINTED,
            }),
            incidentTransactionNumber: createField<string>({
                fieldName: fields.ARREST_INCIDENT_TRANSACTION_NUMBER,
            }),
            warrantSignedByAttrId: createField<number>({
                fieldName: fields.ARREST_WARRANT_SIGNED_BY_ATTR_ID,
            }),
        },
    }),
    arrestAttributes: createFieldset({
        fields: {
            ARREST_TACTICS: createFieldset({
                fields: {
                    attributeIds: createField<number[]>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_TACTICS_ATTRIBUTE_ID,
                    }),
                    description: createField<string>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_TACTICS_DESCRIPTION,
                    }),
                },
            }),
            ARRESTEE_WAS_ARMED_WITH: createFieldset({
                fields: {
                    attributeIds: createField<number[]>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARRESTEE_WAS_ARMED_WITH_ATTRIBUTE_ID,
                    }),
                    description: createField<string>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARRESTEE_WAS_ARMED_WITH_DESCRIPTION,
                    }),
                },
            }),
            ARREST_ARRESTEE_SUSPECTED_USING: createFieldset({
                fields: {
                    attributeIds: createField<number[]>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_ARRESTEE_SUSPECTED_USING_ATTRIBUTE_ID,
                    }),
                    description: createField<string>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_ARRESTEE_SUSPECTED_USING_DESCRIPTION,
                    }),
                },
            }),
            ARREST_STATISTICS: createFieldset({
                fields: {
                    attributeIds: createField<number[]>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_STATISTICS_ATTRIBUTE_ID,
                    }),
                    description: createField<string>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_STATISTICS_DESCRIPTION,
                    }),
                },
            }),
            WARRANT_CHECK_TYPES: createNItems({
                fields: {
                    attributeId: createField<number>({}),
                    description: createField<string>({}),
                },
            }),
            ARREST_STATUS: createFieldset({
                fields: {
                    attributeId: createField<number>({
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARREST_STATUS_ATTRIBUTE_ID,
                    }),
                },
            }),
        },
    }),
    warrants: createNItems({
        fields: {
            id: createField<number>({}),
            warrantNumber: createField<string>({
                fieldName: fields.WARRANT_WARRANT_NUMBER,
            }),
            description: createField<string>({
                fieldName: fields.WARRANT_DESCRIPTION,
            }),
            isOtherJurisdiction: createField<string>({
                fieldName: fields.WARRANT_IS_OTHER_JURISDICTION,
            }),
        },
    }),
    eventStartUtc: createField<string>({ fieldName: fields.EVENT_DETAIL_EVENT_START_UTC }),
    arrestLocation: createFieldset({
        fields: {
            locationId: createField<number>({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_LOCATION_ID,
            }),
            positionAttrId: createField<number>({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_POSITION_ATTR_ID,
            }),
            description: createField<string>({
                fieldName: fields.LOCATION_ENTITY_LINK_LINK_TYPE_ARREST_LOCATION_DESCRIPTION,
            }),
        },
    }),
    defendantReportLink: createFieldset({
        fields: {
            statement: createField<string>({
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_DEFENDANT_STATEMENT,
            }),
        },
    }),
});

export type ArrestMFTForm = _Form<typeof formConfiguration>;
type ArrestMFTFormDataShape = InferFormDataShape<typeof formConfiguration>;

export function createArrestForm({
    initialState,
    arbiter,
    formatFieldByName,
}: {
    initialState: ArrestMFTFormDataShape;
    arbiter: unknown;
    formatFieldByName: FormatFieldByName;
}) {
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: formConfiguration,
    });
}

const convertToFormModel = ({
    arrest,
    arrestAttributes,
    warrants,
    arrestLocation,
    nameReportLinks,
    eventStartUtc,
}: {
    arrest: Arrest;
    arrestAttributes: ArrestAttribute[];
    warrants: Warrant[];
    arrestLocation: LocationView;
    nameReportLinks: NameReportLink[];
    eventStartUtc?: string;
}) => {
    // fields that only have 1 "description" field per attribute group
    const arrestAttributesOneDescription = _(arrestAttributes)
        .omitBy(
            (attribute) =>
                attribute.attributeType === AttributeTypeEnum.WARRANT_CHECK_TYPES.name ||
                attribute.attributeType === AttributeTypeEnum.ARREST_STATUS.name
        )
        .groupBy('attributeType')
        .mapValues((attributesByType) => {
            return {
                attributeIds: map(attributesByType, 'attributeId'),
                description: getDescriptionForAttributeLinks(attributesByType),
            };
        })
        .value();

    // fields that have a "description" for each attribute
    const arrestAttributesMultipleDescription = _(arrestAttributes)
        .filter(
            (attribute) => attribute.attributeType === AttributeTypeEnum.WARRANT_CHECK_TYPES.name
        )
        .groupBy('attributeType')
        .value();

    const arrestSingleAttribute = _(arrestAttributes)
        .filter((attribute) => attribute.attributeType === AttributeTypeEnum.ARREST_STATUS.name)
        .groupBy('attributeType')
        .mapValues((attributesByType) => {
            return {
                attributeId: get(first(attributesByType), 'attributeId'),
            };
        })
        .value();

    const defendantReportLink =
        find(
            nameReportLinks,
            (nrl) =>
                nrl.nameId === arrest.defendantId &&
                nrl.contextId === arrest.reportId &&
                nrl.linkType === LinkTypesEnum.DEFENDANT
        ) || {};

    return {
        arrest,
        arrestAttributes: {
            ...arrestAttributesMultipleDescription,
            ...arrestAttributesOneDescription,
            ...arrestSingleAttribute,
        },
        warrants,
        arrestLocation,
        defendantReportLink,
        eventStartUtc,
    };
};

export const convertFromFormModel = (
    formModel: ArrestMFTFormDataShape,
    attributes: ModuleShape<AttributeView>,
    arrestNameReportLinks: NameReportLink[] = []
) => {
    // fields that only have 1 "description" field per attribute group
    const arrestAttributesOneDescription = flatMap(
        omit(formModel.arrestAttributes, ['WARRANT_CHECK_TYPES', 'ARREST_STATUS']),
        (attribute, attributeType) => {
            if (!attribute) {
                return;
            }

            return assignDescriptionToReportAttributes(
                map(attribute.attributeIds, (attributeId) => {
                    return {
                        arrestId: formModel.arrest?.id,
                        attributeType,
                        attributeId,
                    };
                }),
                attributes,
                attribute.description || ''
            );
        }
    );

    // We only want to get fields that have the "description" field filled because those are the only ones that the user entered
    const arrestAttributesMultipleDescription = filter(
        get(formModel.arrestAttributes, 'WARRANT_CHECK_TYPES'),
        (attribute) => !!attribute.description
    );

    const arrestStatusAttrId = get(formModel.arrestAttributes, 'ARREST_STATUS.attributeId');

    if (!!arrestStatusAttrId) {
        arrestAttributesOneDescription.push({
            arrestId: formModel.arrest?.id,
            attributeType: AttributeTypeEnum.ARREST_STATUS.name,
            attributeId: arrestStatusAttrId,
        });
    }

    // @ts-expect-error client-common to client RND-7529
    const arrest = filterFormData(formModel.arrest);
    // @ts-expect-error client-common to client RND-7529
    const warrants = map(formModel.warrants, (warrant) => ({ ...warrant, arrestId: arrest.id }));
    const arrestLocationEntityLink = isUndefinedOrNull(get(formModel.arrestLocation, 'locationId'))
        ? undefined
        : formModel.arrestLocation;

    const defaultDefendantReportLink = {
        // @ts-expect-error client-common to client RND-7529
        nameId: arrest.defendantId,
        // @ts-expect-error client-common to client RND-7529
        reportId: arrest.reportId,
        // @ts-expect-error client-common to client RND-7529
        contextId: arrest.reportId,
        entityType: EntityTypeEnum.PERSON_PROFILE.name,
        contextType: EntityTypeEnum.REPORT.name,
        linkType: LinkTypesEnum.DEFENDANT,
    } as const;

    const nrlsWithoutDefendant = filter(
        arrestNameReportLinks,
        (nrl) => nrl.linkType !== LinkTypesEnum.DEFENDANT
    );

    let defendantReportLink =
        find(
            arrestNameReportLinks,
            (nrl) =>
                // @ts-expect-error client-common to client RND-7529
                nrl.nameId === arrest.defendantId &&
                // @ts-expect-error client-common to client RND-7529
                nrl.contextId === arrest.reportId &&
                nrl.linkType === LinkTypesEnum.DEFENDANT
        ) || defaultDefendantReportLink;

    defendantReportLink = {
        ...defendantReportLink,
        statement: formModel.defendantReportLink?.statement,
    };
    const nameReportLinks = filter(
        concat(nrlsWithoutDefendant, defendantReportLink),
        (nrl) => !!nrl.nameId
    );
    // Specifically do not `filterFormData` for arrest attributes -- the endpoint
    // will replace, so if all arrest attributes are cleared, we correctly
    // want to replace with an empty array.
    return {
        arrest,
        arrestAttributes: [
            ...arrestAttributesOneDescription,
            ...arrestAttributesMultipleDescription,
        ],
        warrants,
        arrestLocationEntityLink,
        nameReportLinks,
    };
};

import { EntityTypeEnum, PrintingDataTypeEnum, PrintingTemplateTypeEnum } from '@mark43/rms-api';
import _, { first, tail, toLower, find, without } from 'lodash';

export const isCoverSheet = (stringToCheck) => {
    return toLower(stringToCheck).startsWith('cover sheet');
};

export const printingDataTypeToEntityType = {
    [PrintingDataTypeEnum.REPORT.name]: EntityTypeEnum.REPORT.name,
    [PrintingDataTypeEnum.CASE.name]: EntityTypeEnum.CASE.name,
    [PrintingDataTypeEnum.PHOTO_LINEUP.name]: EntityTypeEnum.PHOTO_LINEUP.name,
    [PrintingDataTypeEnum.CASE_NOTE.name]: EntityTypeEnum.CASE_NOTE.name,
    [PrintingDataTypeEnum.FOLDER.name]: EntityTypeEnum.FOLDER.name,
    [PrintingDataTypeEnum.PARENT_FOLDER.name]: EntityTypeEnum.FOLDER.name,
    [PrintingDataTypeEnum.CHAIN_OF_CUSTODY.name]: EntityTypeEnum.CHAIN_OF_CUSTODY.name,
    [PrintingDataTypeEnum.WARRANT.name]: EntityTypeEnum.WARRANT.name,
    [PrintingDataTypeEnum.PERSON_PROFILE.name]: EntityTypeEnum.PERSON_PROFILE.name,
    [PrintingDataTypeEnum.AGENCY_EVENT.name]: EntityTypeEnum.CAD_AGENCY_EVENT.name,
    [PrintingDataTypeEnum.TASK.name]: EntityTypeEnum.TASK.name,
    [PrintingDataTypeEnum.TASK_TYPE.name]: EntityTypeEnum.TASK.name,
    [PrintingDataTypeEnum.REPORT_IDENTIFIER.name]: EntityTypeEnum.REPORT.name,
};

/**
 * Some properties such as evidenceItemProfileIds must become an array (even if there is only 1 id),
 *   and other properties such as evidencePrintingTemplateId must become a single value.
 * @param  {Object[]} exportOptions
 * @return {Object[]}
 */
export function convertExportOptionsToExportConfigs(exportOptions) {
    return _(exportOptions)
        .groupBy('exportOptionName')
        .mapValues((options, key) => {
            switch (key) {
                case 'evidencePrintingTemplateId':
                    return _.get(options[0], 'exportOptionValue');
                case 'evidenceItemProfileIds':
                default:
                    return _.map(options, 'exportOptionValue');
            }
        })
        .value();
}

/**
 * Return the given printables (which are called rawPacket elsewhere in code, not PacketOption) as a 1-element array of
 * Printables, where 1 Printable is the parent and the other Printables are its children.
 *
 * If one of the packets is a case, choose that to be the parent.
 * Else if one of the packets is a javascript template, choose that to be the parent.
 *   The Foreman currently won't render javascript templates if a javascript template is not the parent.
 * Otherwise, choose the first packet.
 *
 * If the "default" strings are provided, replace the parent printable's strings with them. This happens even with the
 * empty string.
 *
 * For example, on a case Printable which comes from the server,
 *   `groupTitle` is hardcoded as "Case Jacket".
 *   Both `title` and `fileName` are hardcoded with the prefix "Case #" (e.g. "Case # 123").
 *
 *   When exporting a case and case notes, which are in multiple packets as siblings, so this function replaces
 *     `groupTitle` and `fileName` of the parent case printable with the "default" strings from CaseExports.
 *
 *   When exporting a case and linked reports, the report printables are children of the case printable, so this
 *     function does not replace `groupTitle` and `fileName`.
 */
export const mapGivenPacketsToPrintables = (
    packets,
    defaultGroupTitle,
    defaultFilename,
    { folderingEnabled = false } = {}
) => {
    if (packets.length > 1) {
        let parentPrintable = first(packets);
        let childPrintables = tail(packets);

        const casePrintable = find(
            packets,
            (packet) => packet.dataType === PrintingDataTypeEnum.CASE.name
        );
        if (casePrintable) {
            parentPrintable = casePrintable;
            childPrintables = without(packets, casePrintable);
        } else if (parentPrintable.templateType !== PrintingTemplateTypeEnum.JAVASCRIPT.name) {
            const javascriptPrintable = find(
                packets,
                (packet) => packet.templateType === PrintingTemplateTypeEnum.JAVASCRIPT.name
            );
            if (javascriptPrintable) {
                parentPrintable = javascriptPrintable;
                childPrintables = without(packets, javascriptPrintable);
            }
        }

        packets = [
            {
                ...parentPrintable,
                ...getCustomProperties(parentPrintable, defaultGroupTitle, defaultFilename, {
                    folderingEnabled,
                }),
                childPrintables: [...parentPrintable.childPrintables, ...childPrintables],
            },
        ];
    }
    return packets;
};

/**
 * Before converting a parent Printable to a parent WorkOrder, this helper is used to determine which properties to
 * update in the WorkOrder. This helper is meant to be called on the parent Printable only, not the child Printables.
 *
 * Returning an empty object means no properties will be updated.
 */
function getCustomProperties(
    printable,
    defaultGroupTitle,
    defaultFilename,
    { folderingEnabled = false } = {}
) {
    if (folderingEnabled && printable.dataType === PrintingDataTypeEnum.CASE_NOTE.name) {
        // Special case: When the parent Printable is a case note, we know for certain that the original fileName must
        // be preserved because a case note has no parent/child relationship with other exportable entities. We move the groupTitle into the title in order to prevent the groupTitle from also be joined with the fileName.
        return {};
    }

    const properties = {};
    if (defaultGroupTitle || defaultGroupTitle === '') {
        properties.groupTitle = defaultGroupTitle;
    }
    if (defaultFilename || defaultFilename === '') {
        properties.fileName = defaultFilename;
    }
    return properties;
}

import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { IOverlayBaseRenderProps } from 'overlay-manager/lib/OverlayBase';
import {
    BriefingCreationRequest,
    BriefingDuplicateRequest,
    BriefingUpdateRequest,
    BriefingView,
    RefContextEnum,
} from '@mark43/rms-api';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { withBriefing } from '~/client-common/core/domain/briefings/state/data';

import Modal from '../../core/overlays/components/Modal';
import { OverlayBaseHelper } from '../../core/components/OverlayBaseHelper';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';

import { BriefingRoutingModalType } from '../types';
import { convertFromFormModel } from '../state/forms/routingOptionsForm';
import briefingsResource from '../resources/briefingResource';
import BriefingRoutingForm from './BriefingRoutingForm';

interface BriefingRoutingModalProps {
    modalType: BriefingRoutingModalType;
    briefingId?: number;
}
interface BriefingRoutingModalRenderProps {
    overlayBase: IOverlayBaseRenderProps<BriefingRoutingModalProps>;
}

const strings = componentStrings.briefings.modal;

const typeToLabelsMap = {
    [BriefingRoutingModalType.CREATE]: strings.create,
    [BriefingRoutingModalType.DUPLICATE]: strings.duplicate,
    [BriefingRoutingModalType.EDIT]: strings.edit,
};

const FormWrapper = styled.div`
    padding: var(--arc-space-3, 12px);
`;

const BriefingRoutingModal: React.FC = () => {
    return (
        <OverlayBaseHelper id={overlayIdEnum.BRIEFING_ROUTING_MODAL}>
            {(renderProps: BriefingRoutingModalRenderProps) => {
                const {
                    modalType,
                    briefingId,
                } = renderProps.overlayBase.overlayState.customProperties;
                return (
                    <BriefingRoutingModalContent modalType={modalType} briefingId={briefingId} />
                );
            }}
        </OverlayBaseHelper>
    );
};

const BriefingRoutingModalContent = withRouter<BriefingRoutingModalProps>(
    ({ modalType, briefingId, router }) => {
        const dispatch = useDispatch();
        const { getForm } = useFormGetter();
        const labels = typeToLabelsMap[modalType];

        const handleSave = () => {
            const form = getForm(RefContextEnum.FORM_BRIEFING_ROUTING_MODAL.name);
            if (!form) {
                return;
            }
            return form
                .submit()
                .then((formData) => {
                    const payload = convertFromFormModel(formData.form.getState().model);
                    if (modalType === BriefingRoutingModalType.EDIT && briefingId) {
                        return briefingsResource.updateBriefing(
                            briefingId,
                            payload as BriefingUpdateRequest
                        );
                    }
                    if (modalType === BriefingRoutingModalType.DUPLICATE && briefingId) {
                        return briefingsResource.duplicateBriefing(
                            briefingId,
                            payload as BriefingDuplicateRequest
                        );
                    }
                    return briefingsResource.createBriefing(payload as BriefingCreationRequest);
                })
                .then((response: BriefingView) => {
                    if (modalType === BriefingRoutingModalType.EDIT) {
                        dispatch(withBriefing(response));
                        return;
                    }
                    const { id } = response;
                    router.push(`/briefings/${id}`);
                    form.resetModel();
                });
        };

        return (
            <Modal
                id={overlayIdEnum.BRIEFING_ROUTING_MODAL}
                title={labels.title}
                okText={labels.ok}
                cancelText={strings.cancel}
                onSave={handleSave}
            >
                <FormWrapper>
                    <BriefingRoutingForm />
                </FormWrapper>
            </Modal>
        );
    }
);

export default BriefingRoutingModal;

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    formatAttributeByIdSelector,
    formatLinkAttributesSelector,
} from '~/client-common/core/domain/attributes/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import { hydratedReportLegacyMetadataByReportIdSelector } from '~/client-common/core/domain/reports/state/ui/reportLegacyMetadatas';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    REPORT_LEGACY_METADATA_EVENT_DATE_UTC,
    REPORT_AGENCY_ID,
    REPORT_LEGACY_METADATA_PRIMARY_REPORTER_ID,
    REPORT_LEGACY_METADATA_LEGACY_SYSTEM_ATTRIBUTE_ID,
    REPORT_LEGACY_METADATA_LEGACY_REPORT_TYPE_ATTRIBUTE_ID,
    REPORT_LEGACY_METADATA_LEGACY_REPORT_SUB_TYPE_ATTRIBUTE_ID,
    REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_LEGACY_CLASSIFICATION_CODE_ATTRIBUTE_ID,
    REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_LEGACY_MIGRATION_STATISTIC_ATTRIBUTE_ID,
} from '~/client-common/core/enums/universal/fields';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import testIds from '../../../../../core/testIds';

const ReportLegacyMetadataCardSummary: React.FC<{ reportId: number }> = ({ reportId }) => {
    const hydratedReportLegacyMetadataByReportId = useSelector(
        hydratedReportLegacyMetadataByReportIdSelector
    );
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const formatLinkAttributes = useSelector(formatLinkAttributesSelector);
    const {
        reportAttributes,
        agencyProfile,
        reportLegacyMetadatas,
    } = hydratedReportLegacyMetadataByReportId(reportId);

    const fieldDisplayNames = useFields([
        REPORT_LEGACY_METADATA_EVENT_DATE_UTC,
        REPORT_AGENCY_ID,
        REPORT_LEGACY_METADATA_PRIMARY_REPORTER_ID,
        REPORT_LEGACY_METADATA_LEGACY_SYSTEM_ATTRIBUTE_ID,
        REPORT_LEGACY_METADATA_LEGACY_REPORT_TYPE_ATTRIBUTE_ID,
        REPORT_LEGACY_METADATA_LEGACY_REPORT_SUB_TYPE_ATTRIBUTE_ID,
        REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_LEGACY_CLASSIFICATION_CODE_ATTRIBUTE_ID,
        REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_LEGACY_MIGRATION_STATISTIC_ATTRIBUTE_ID,
    ]);

    return (
        <>
            <SummaryList labelWidth={220} contentWidth={320}>
                <CardSection testId={testIds.CARD_SUMMARY}>
                    <FeatureFlagged flag="RMS_LEGACY_MIGRATION_INFO_CARD_ENABLED">
                        <SummaryRowDate
                            fieldName={REPORT_LEGACY_METADATA_EVENT_DATE_UTC}
                            date={reportLegacyMetadatas?.legacyEventDateUtc}
                        />
                        <SummaryRow label={fieldDisplayNames.REPORT_AGENCY_ID}>
                            {agencyProfile?.agencyName}
                        </SummaryRow>
                        <SummaryRow
                            label={fieldDisplayNames.REPORT_LEGACY_METADATA_PRIMARY_REPORTER_ID}
                        >
                            {reportLegacyMetadatas?.primaryReporterId && (
                                <ConnectedFormattedUser
                                    userId={reportLegacyMetadatas.primaryReporterId}
                                    format={FORMATS.FULL_NAME}
                                />
                            )}
                        </SummaryRow>
                        <SummaryRow
                            label={
                                fieldDisplayNames.REPORT_LEGACY_METADATA_LEGACY_SYSTEM_ATTRIBUTE_ID
                            }
                        >
                            {formatAttributeById(reportLegacyMetadatas?.legacySystemAttrId)}
                        </SummaryRow>
                        <SummaryRow
                            label={
                                fieldDisplayNames.REPORT_LEGACY_METADATA_LEGACY_REPORT_TYPE_ATTRIBUTE_ID
                            }
                        >
                            {formatAttributeById(reportLegacyMetadatas?.legacyReportTypeAttrId)}
                        </SummaryRow>
                        <SummaryRow
                            label={
                                fieldDisplayNames.REPORT_LEGACY_METADATA_LEGACY_REPORT_SUB_TYPE_ATTRIBUTE_ID
                            }
                        >
                            {formatAttributeById(reportLegacyMetadatas?.legacyReportSubTypeAttrId)}
                        </SummaryRow>
                        <SummaryRow
                            label={
                                fieldDisplayNames.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_LEGACY_CLASSIFICATION_CODE_ATTRIBUTE_ID
                            }
                        >
                            {formatLinkAttributes(
                                reportAttributes,
                                AttributeTypeEnum.LEGACY_CLASSIFICATION_CODE.name
                            )}
                        </SummaryRow>
                        <SummaryRow
                            label={
                                fieldDisplayNames.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_LEGACY_MIGRATION_STATISTIC_ATTRIBUTE_ID
                            }
                        >
                            {formatLinkAttributes(
                                reportAttributes,
                                AttributeTypeEnum.LEGACY_MIGRATION_STATISTIC.name
                            )}
                        </SummaryRow>
                    </FeatureFlagged>
                </CardSection>
            </SummaryList>
        </>
    );
};

export default ReportLegacyMetadataCardSummary;

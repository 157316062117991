import styled from 'styled-components';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';

export const Form = styled(ReactReduxForm)`
    flex-basis: 100%;
    overflow: auto;
`;

export const StyledRRFCheckbox = styled(RRFCheckbox)`
    margin: 0;
    margin-top: 4px;
`;

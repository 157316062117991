import _, { map, reduce, find, omit } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { prettify } from '~/client-common/helpers/stringHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    reportModuleSearch,
    submitReportModuleSearch,
    clearReportModuleFilters,
} from '../state/ui/reportModuleSearch';
import Icon, { iconTypes } from '../../../core/components/Icon';
import Button from '../../../../legacy-redux/components/core/Button';
import Row from '../../../core/components/Row';
import testIds from '../../../../core/testIds';

const dateTimeRange = componentStrings.reports.dashboard.DateTimeFieldset.labels.dateTimeRange;

const FilterBody = styled.div`
    text-align: left;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.darkGrey};
    background-color: ${(props) => props.theme.colors.white};
    line-height: 15px;
    display: flex;
    gap: var(--arc-space-1);
    flex-wrap: wrap;
    padding-top: var(--arc-space-4);
`;

const FilterItem = styled.div`
    padding: var(--arc-space-1);
    display: inline-block;
`;

const FilterTitle = styled.span`
    color: ${(props) => props.theme.colors.mediumGrey};
    font-weight: ${(props) => props.theme.fontWeights.medium};
`;

const ClearFilterButton = styled(Button)`
    position: relative;
    left: 5px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    float: none;
    min-width: 0;
    padding: 0;
    border: none;
    margin: 0;
    margin-right: var(--arc-space-2);

    &:hover {
        border: none;
    }
`;

function ReportModuleSearchFilters({ filters, onClearFilter }) {
    // filters prop is array of objects with filter groups from different fieldsets (dateTime, personnel, reportDetails)
    // this reduce compresses filters from the different fieldsets into one filterGroups array
    const filterGroups = reduce(
        filters,
        (merged, { fields, key }) => [
            ...merged,
            ...map(fields, (field) => {
                return {
                    ...field,
                    path: key,
                };
            }),
        ],
        []
    );

    // date type is non-nullable, so we don't want to give the user the option to clear it but still want to display it as
    // part of the date range filter label
    const dateTypeGroup = find(filterGroups, (filterGroup) => filterGroup.key === 'dateType');
    const filterGroupsWithoutDateType = _(filterGroups)
        .reject((filterGroup) => filterGroup.key === 'dateType')
        .map((filterGroup) => {
            return {
                ...omit(filterGroup, 'label'),
                label:
                    dateTypeGroup && filterGroup.label === dateTimeRange
                        ? `${prettify(dateTypeGroup.display)} ${filterGroup.label}`
                        : filterGroup.label,
            };
        })
        .value();

    return (
        <Row>
            <FilterBody>
                {map(filterGroupsWithoutDateType, (filterGroup) => {
                    return (
                        <div key={filterGroup.key}>
                            <FilterItem>
                                <FilterTitle>{filterGroup.label}:</FilterTitle>
                                <span>
                                    &nbsp;
                                    {filterGroup.display}
                                </span>
                                <ClearFilterButton
                                    onClick={() => onClearFilter(filterGroup.path, filterGroup.key)}
                                    testId={testIds.REPORTS_DASHBOARD_CLEAR_FILTER_BUTTON}
                                >
                                    <Icon type={iconTypes.CLOSE_X} size={8} color="darkGrey" />
                                </ClearFilterButton>
                            </FilterItem>
                        </div>
                    );
                })}
            </FilterBody>
        </Row>
    );
}

const mapStateToProps = createStructuredSelector({
    filters: reportModuleSearch.selectors.filtersSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onClearFilter: (path, filterKey) => {
        dispatch(clearReportModuleFilters(path, filterKey));
        dispatch(submitReportModuleSearch());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportModuleSearchFilters);

import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import OverlayButton, { buttonTypes } from '../../../core/overlays/components/OverlayButton';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';

const strings = componentStrings.analysis;

const NewDashboardButton = styled(OverlayButton)`
    margin: 0;
`;

const AddDashboardText = styled.div`
    padding-top: 1px;
    padding-left: 2px;
    display: inline-block;
`;

const AddIcon = styled(Icon)`
    display: inline-block;
    padding-top: 3px;
    padding-right: 3px;
    vertical-align: top;
`;

const CreateAnalysisDashboardButton: React.FC<{ id: string }> = ({ id }) => (
    <NewDashboardButton
        className={buttonTypes.PRIMARY}
        iconLeft={<AddIcon size={iconSizes.MEDIUM} color="white" type={iconTypes.ADD} />}
        id={id}
    >
        <AddDashboardText>{strings.subheader.newDashboard}</AddDashboardText>
    </NewDashboardButton>
);

export default CreateAnalysisDashboardButton;

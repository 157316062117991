import { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import Page from '../../../core/components/Page';
import Subheader from '../../../core/components/Subheader';
import { BaseScrollableUnderSubheader } from '../../../core/components/ScrollableUnderSubheader';
import { Button } from '../../../core/components/Button';
import Link from '../../../core/components/links/Link';
import { NotificationsErrorBoundary } from '../../../core/errors/components/ErrorBoundary';

import routesConfig from '../../../../routing/routesConfig';
import { routeNameSelector } from '../../../../routing/routerModule';
import { openNotificationSettingsForm } from '../../settings/state/ui';

const strings = componentStrings.notifications.dashboard.NotificationsDashboard;

const pathParamToRouteName = {
    inbox: routesConfig.NOTIFICATIONS_DASHBOARD_INBOX.name,
    archive: routesConfig.NOTIFICATIONS_DASHBOARD_ARCHIVE.name,
    alerts: routesConfig.NOTIFICATIONS_DASHBOARD_ALERTS.name,
};

const LayoutWrapper = styled.div`
    flex-direction: row;
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 970px;
    overflow-y: auto;
    justify-content: center;
`;

const Column = styled.div`
    position: relative;
    width: 810px;
    min-height: 1px;
`;

const Sidebar = styled.div`
    padding-top: 20px;
    width: 160px;
    position: sticky;
    top: 0;
    align-self: flex-start;
`;

const SidebarLink = styled(Link)`
    display: block;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    height: 40px;
    line-height: 40px;
    padding-left: 18px;
    outline: none;

    && {
        background-color: ${(props) =>
            props.active ? props.theme.colors.cobaltBlue : 'transparent'};
        color: ${(props) =>
            props.active ? props.theme.colors.white : props.theme.colors.cobaltBlue};
    }

    &:hover {
        color: ${(props) => props.theme.colors.white};
        background-color: ${(props) => props.theme.colors.cobaltBlue};
    }
`;

function NotificationsDashboard({ openNotificationsSettings, routeName, children }) {
    return (
        <NotificationsErrorBoundary>
            <Page>
                <Subheader title={strings.title}>
                    <Button
                        isTextTransformNone
                        leadingVisual="Settings"
                        onClick={openNotificationsSettings}
                    >
                        {strings.settings}
                    </Button>
                </Subheader>
                <BaseScrollableUnderSubheader>
                    <LayoutWrapper>
                        <Sidebar>
                            {map(pathParamToRouteName, (name, pathParam) => (
                                <SidebarLink
                                    active={routeName === name}
                                    key={pathParam}
                                    to={`/notifications/${pathParam}`}
                                >
                                    {strings.menu[pathParam]}
                                </SidebarLink>
                            ))}
                        </Sidebar>
                        <Column>{children}</Column>
                    </LayoutWrapper>
                </BaseScrollableUnderSubheader>
            </Page>
        </NotificationsErrorBoundary>
    );
}

const mapStateToProps = createStructuredSelector({
    routeName: routeNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openNotificationsSettings: () => dispatch(openNotificationSettingsForm()),
});

/**
 * Route component for the notifications dashboard.
 */
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsDashboard);

import _ from 'lodash';
import { Printable, PrintingDataTypeEnum } from '@mark43/rms-api';

const { CHAIN_OF_CUSTODY } = PrintingDataTypeEnum;

/**
 * Build dropdown options that correspond to evidence document printables. For each option, we
 *   need to record both the `printingTemplateId` and `title` because the id itself is not unique
 *   among the printables. Both are needed to identify exactly what PDF the user wants to export.
 *
 * If there are multiple Chain of Custody printables, they get combined into a single option for
 *   all of them to be exported together. There is no situation where we let the user to choose
 *   which CoC to print.
 * @param   evidenceDocumentPrintables Printable models that come from the server.
 * @return
 */
export function buildEvidenceDocumentPrintableOptions(evidenceDocumentPrintables: Printable[]) {
    const chainOfCustodyPrintable = _.find(evidenceDocumentPrintables, {
        dataType: CHAIN_OF_CUSTODY.name,
    });
    const chainOfCustodyOptions = chainOfCustodyPrintable
        ? [
              {
                  value: `${chainOfCustodyPrintable.printingTemplateId}~${chainOfCustodyPrintable.title}`,
                  // the `title` on this printable looks like `<REN> ChainOfCustody` which doesn't
                  // look nice next to the other configurable printable options, so we hard code
                  // this string
                  display: 'Chains of Custody',
              },
          ]
        : [];

    const otherOptions = _(evidenceDocumentPrintables)
        .reject({ dataType: CHAIN_OF_CUSTODY.name })
        .map(({ printingTemplateId, title }) => ({
            value: `${printingTemplateId}~${title}`,
            display: title,
        }))
        .value();

    return _.sortBy([...chainOfCustodyOptions, ...otherOptions], 'display');
}

/**
 * Convert a value from `buildEvidenceDocumentPrintableOptions()` back into the
 *   original properties on the printable that it comes from.
 */
export function parseEvidenceDocumentPrintableHash(printableHash = '') {
    const [printingTemplateId, title] = printableHash.split('~');
    return {
        printingTemplateId: _.parseInt(printingTemplateId),
        title,
    };
}

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { chain } from 'lodash';
import { Icon as ArcIcon, Text, cssVar } from 'arc';
import { CaseDefinition } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import testIds from '../../../../core/testIds';
import { activeCaseDefinitionViewModelsForCurrentDepartmentSelector } from '../state/ui';
import FilterableList from '../../../core/components/FilterableList';
import { Tile } from '../../../core/components/Tile';

const strings = componentStrings.cases.core.CaseTypesSection;

const CaseTypesSubsectionHeader = styled(Text)`
    border-bottom: 1px solid ${cssVar('arc.colors.border.default')};
    color: ${cssVar('arc.colors.text.tertiary')};
    text-transform: uppercase;
`;

const CaseTypesWrapper = styled.div`
    float: left;
    margin-top: 20px;
    width: 100%;
`;

type CaseTypesSectionPropsT = {
    onClick: (caseDefinitionId: number) => void;
};

function CaseTypesSection({ onClick }: CaseTypesSectionPropsT) {
    const activeCaseDefinitionViewModels = useSelector(
        activeCaseDefinitionViewModelsForCurrentDepartmentSelector
    );

    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    const caseDefinitionOptions = chain(activeCaseDefinitionViewModels)
        .sortBy('name')
        .map((definition) => ({
            ...definition,
            display: definition.name,
        }))
        .value();

    const caseDefinitionsOptionsMapper = (definition: CaseDefinition) => (
        <Tile
            key={definition.id}
            title={definition.name}
            media={<ArcIcon color="brand.default" icon="Add" size="md" />}
            onClick={() => onClick(definition.id)}
            testId={testIds.CREATE_CASE_MODAL_ADD_NEW_CASE_BUTTON}
        />
    );

    return (
        <div data-test-id={testIds.CREATE_CASE_MODAL_CASE_TYPE_SECTION}>
            <CaseTypesSubsectionHeader variant="headingXs" fontWeight="semibold">
                {strings.caseTypeSection(caseFieldName)}
            </CaseTypesSubsectionHeader>
            <CaseTypesWrapper>
                <FilterableList
                    options={caseDefinitionOptions}
                    optionsMapper={caseDefinitionsOptionsMapper}
                    placeholderText={strings.filterPlaceholder(caseFieldName)}
                    testId={testIds.CASE_TYPE_FILTER}
                />
            </CaseTypesWrapper>
        </div>
    );
}

export default CaseTypesSection;

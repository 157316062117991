import React from 'react';
import { Flex } from 'arc';
import { useBriefingsDashboardResults } from '../../hooks/useBriefingsDashboardResults';
import { useBriefingsPagination } from '../../hooks/useBriefingsPagination';
import { useBriefingsSortsAndFilters } from '../../hooks/useBriefingsSortsAndFilters';
import { BriefingsTable } from './BriefingsTable';
import { BriefingsFilters } from './BriefingsFilters';

export const BriefingsDashboardResults = () => {
    const {
        results,
        errorMessage,
        isLoading,
        query: { isAutoSave = false },
    } = useBriefingsDashboardResults();
    const paginationProps = useBriefingsPagination();
    const { visibleFilters, onFiltersChange, sort, ...sortProps } = useBriefingsSortsAndFilters();

    return (
        <Flex mt={4} flexDirection="column" gap={4}>
            <BriefingsFilters
                shouldApplyFilters={!isLoading && isAutoSave}
                visibleFilters={visibleFilters}
                onFiltersChange={onFiltersChange}
            />
            <BriefingsTable
                results={results}
                paginationProps={paginationProps}
                config={{ sort }}
                errorMessage={errorMessage.actionText}
                {...sortProps}
            />
        </Flex>
    );
};

import { EntityTypeEnum, AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import Promise from 'bluebird';
import { head, map, omit, pick, values } from 'lodash';
import { ruleTypes } from 'arbiter-mark43';
import {
    warrantsSelector,
    saveWarrant,
    getWarrantTitlesForWarrantNumber,
    deleteWarrant,
} from '~/client-common/core/domain/warrants/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { mostRecentWarrantStatusByWarrantIdSelector } from '~/client-common/core/domain/warrant-statuses/state/data';
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { warrantAttributesWhereSelector } from '~/client-common/core/domain/warrant-attributes/state/data';
import { NEXUS_STATE_PROP as MINI_USERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/mini-users/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import getWarrantResource from '~/client-common/core/domain/warrants/resources/warrantsResource';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { attributesByTypeForCurrentDepartmentSelector } from '../../../../core/attributes/state/ui';
import { getInfoForReportingEventNumber } from '../../../../../legacy-redux/actions/renSearchModalActions';

import warrantDetailsForm from '../forms/warrantDetailsForm';
import warrantDetailsCard from '../cards/warrantDetailsCard';
import warrantChargesCard from '../cards/warrantChargesCard';
import warrantDexSubmissionCard from '../cards/warrantDexSubmissionCard';
import attachmentsCard from '../cards/attachmentsCard';
import createWarrantForm from '../forms/createWarrantForm';
import changeWarrantNumberForm from '../forms/changeWarrantNumberForm';

import {
    openBox,
    saveBoxStart,
    saveBoxSuccess,
    saveBoxHalt,
    saveBoxFailure,
    openErrorModal,
    closeBox,
    loadBoxStart,
    storePayloadToBox,
} from '../../../../../legacy-redux/actions/boxActions';

const strings = componentStrings.warrants.warrant.DeleteWarrantModal;

export {
    WARRANT_ACTIVITY_BOX_CONTEXT,
    openWarrantActivitySidePanel,
    submitWarrantActivityForm,
} from './warrantActivity';
export {
    WARRANT_CHARGES_BOX_CONTEXT,
    openWarrantChargesSidePanel,
    submitWarrantChargesForm,
} from './warrantCharges';

const SET_CURRENT_WARRANT_ID = 'warrants/SET_CURRENT_WARRANT_ID';
const SET_LINKED_REPORT_IDS = 'warrants/SET_LINKED_REPORT_IDS';
const SET_IS_NEW_WARRANT = 'warrants/SET_IS_NEW_WARRANT';

// selectors
const warrantGeneralUiSelector = (state) => state.ui.warrants.warrant.general;
export const currentWarrantIdSelector = createSelector(
    warrantGeneralUiSelector,
    ({ currentWarrantId }) => currentWarrantId
);
export const currentWarrantSelector = createSelector(
    currentWarrantIdSelector,
    warrantsSelector,
    (currentWarrantId, warrants) => warrants[currentWarrantId]
);

const isNewWarrantSelector = createSelector(
    warrantGeneralUiSelector,
    ({ isNewWarrant }) => isNewWarrant
);

const warrantLinkedReportIdsSelector = createSelector(
    warrantGeneralUiSelector,
    ({ linkedReportIds }) => linkedReportIds
);

export const warrantLinkedReportShortTitleViewModelsSelector = createSelector(
    warrantLinkedReportIdsSelector,
    reportShortTitleViewModelsSelector,
    (linkedReportIds, reportShortTitleViewModels) =>
        pick(reportShortTitleViewModels, linkedReportIds)
);

// actions
export function setCurrentWarrantId(id) {
    return {
        type: SET_CURRENT_WARRANT_ID,
        payload: id,
    };
}

export function setLinkedReportIds(linkedReportIds) {
    return {
        type: SET_LINKED_REPORT_IDS,
        payload: linkedReportIds,
    };
}

export function openCreateWarrantModal() {
    return (dispatch, getState) => {
        dispatch(createWarrantForm.actionCreators.reset()); // make sure there's a clean form
        const warrantTypes = values(
            attributesByTypeForCurrentDepartmentSelector(getState())(
                AttributeTypeEnum.WARRANT_TYPE.name
            )
        );
        // if there's only 1 warrant type attribute, prefill the warrant type field in warrant creation (RMS-5406)
        if (warrantTypes.length === 1) {
            dispatch(
                createWarrantForm.actionCreators.change({ warrantTypeAttrId: warrantTypes[0].id })
            );
        }
        dispatch(openBox({ name: boxEnum.CREATE_WARRANT_MODAL }));
    };
}

export function openChangeWarrantNumberModalForWarrant(warrant) {
    return (dispatch) => {
        dispatch(changeWarrantNumberForm.actionCreators.change(warrant));
        dispatch(openBox({ name: boxEnum.CHANGE_WARRANT_NUMBER_MODAL }));
    };
}

export function openDeleteWarrantModalForWarrant(warrantId) {
    return (dispatch, getState, dependencies) => {
        const context = { name: boxEnum.DELETE_WARRANT_MODAL };
        dispatch(loadBoxStart(context));
        getWarrantResource()
            .isWarrantDeletable(warrantId)
            .then((warrantDeletionResponse) => {
                const {
                    miniUsers,
                    linkedArrestReportShortTitles,
                    canDeleteWarrant,
                } = warrantDeletionResponse;
                const linkedArrestReportIds = map(linkedArrestReportShortTitles, 'reportId');
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: linkedArrestReportShortTitles,
                            [MINI_USERS_NEXUS_STATE_PROP]: miniUsers,
                        },
                        openBox(context, { canDeleteWarrant, linkedArrestReportIds })
                    )
                );
            })
            .catch((err) => {
                dispatch(openErrorModal({ title: strings.title, paragraphs: [err.message] }));
                throw err;
            });
    };
}

export function setIsNewWarrant(isNewWarrant) {
    return {
        type: SET_IS_NEW_WARRANT,
        payload: isNewWarrant,
    };
}

export function createNewWarrantWithAutoGenWarrantNumber({ router }) {
    return (dispatch) => {
        return dispatch(saveWarrant({})).then((warrant) => {
            dispatch(setIsNewWarrant(true));
            router.push(`/warrants/${warrant.id}`);
        });
    };
}

export function submitCreateWarrantModal({ router }) {
    return (dispatch) => {
        const context = {
            name: boxEnum.CREATE_WARRANT_MODAL,
        };
        return dispatch(
            createWarrantForm.actionCreators.submit((formModel) => {
                const warrantNumberIsAvailablePromise = dispatch(
                    getWarrantTitlesForWarrantNumber(formModel.warrantNumber)
                ).then((warrantTitles) => {
                    const warrantNumberIsAvailable = warrantTitles && warrantTitles.length === 0;
                    return warrantNumberIsAvailable;
                });
                return Promise.all([warrantNumberIsAvailablePromise, formModel]);
            })
        )
            .then(([warrantNumberIsAvailable, formModel]) => {
                if (!warrantNumberIsAvailable) {
                    throw new Error('Warrant Number must be unique.');
                }
                const newWarrant = omit(formModel, 'reportingEventNumber');
                const warrantRen = formModel.reportingEventNumber;
                const idFormatConfigurationId = formModel.idFormatConfigurationId;
                return dispatch(saveWarrant(newWarrant, warrantRen, idFormatConfigurationId));
            })
            .then((warrant) => {
                dispatch(saveBoxSuccess(context));
                dispatch(closeBox(context));
                // reset the values now that we don't need them
                dispatch(createWarrantForm.actionCreators.reset());
                dispatch(setIsNewWarrant(true));
                router.push(`/warrants/${warrant.id}`);
            })
            .catch((err) => dispatch(saveBoxFailure(context, err.message)));
    };
}

export function submitWarrantNumberSearch({ boxContext = {}, form, fieldPath = '' }) {
    return (dispatch, getState) => {
        if (form) {
            const state = getState();

            const warrantNumberIsValid = form.selectors.formPathIsValidSelector(state)(fieldPath);

            if (warrantNumberIsValid) {
                const warrantNumber = form.selectors.formModelByPathSelector(state)(fieldPath);
                // mimic saving to show loading gif and disable save
                dispatch(saveBoxStart(boxContext));
                return dispatch(getWarrantTitlesForWarrantNumber(warrantNumber.trim()))
                    .then((warrantTitles) => {
                        dispatch(saveBoxHalt(boxContext));
                        const isAvailable = warrantTitles && warrantTitles.length === 0;
                        dispatch(
                            form.actionCreators.setValidity(fieldPath, {
                                duplicateWarrantNumberError: isAvailable,
                            })
                        );
                        dispatch(
                            storePayloadToBox(boxContext, {
                                existingWarrantIds: map(warrantTitles, 'warrantId'),
                            })
                        );
                    })
                    .catch((err) => {
                        dispatch(saveBoxFailure(boxContext, err.message));
                    });
            } else {
                dispatch(storePayloadToBox(boxContext, { existingWarrantIds: [] }));
            }
        }
    };
}

export function submitChangeWarrantNumberModal() {
    return (dispatch, getState) => {
        const context = {
            name: boxEnum.CHANGE_WARRANT_NUMBER_MODAL,
        };
        return dispatch(
            changeWarrantNumberForm.actionCreators.submit((formModel) => {
                const { newWarrantNumber } = formModel;
                // check that warrant number is unique
                return dispatch(getWarrantTitlesForWarrantNumber(newWarrantNumber))
                    .then((warrantTitles) => {
                        const isAvailable = warrantTitles && warrantTitles.length === 0;
                        dispatch(
                            changeWarrantNumberForm.actionCreators.setValidity('newWarrantNumber', {
                                duplicateWarrantNumberError: isAvailable,
                            })
                        );

                        if (isAvailable) {
                            const state = getState();
                            const warrantId = currentWarrantIdSelector(state);
                            const existingWarrantData = warrantsSelector(state)[warrantId];
                            const warrantRen = existingWarrantData.reportingEventNumber;
                            const updatedWarrant = {
                                ...omit(existingWarrantData, 'reportingEventNumber'),
                                warrantNumber: newWarrantNumber,
                            };
                            dispatch(saveWarrant(updatedWarrant, warrantRen)).then(() => {
                                dispatch(saveBoxSuccess(context));
                                dispatch(closeBox(context));
                                // reset the values now that we don't need them
                                dispatch(changeWarrantNumberForm.actionCreators.reset());
                            });
                        } else {
                            dispatch(saveBoxHalt(context));
                        }
                    })
                    .catch((err) => {
                        dispatch(saveBoxFailure(context, err.message));
                    });
            })
        ).catch((err) => {
            dispatch(saveBoxFailure(context, err));
        });
    };
}

export function submitDeleteWarrantModal({ router }) {
    return (dispatch, getState) => {
        const context = {
            name: boxEnum.DELETE_WARRANT_MODAL,
        };
        const state = getState();
        const warrantId = currentWarrantIdSelector(state);

        dispatch(deleteWarrant(warrantId))
            .then(() => {
                dispatch(saveBoxSuccess(context));
                dispatch(closeBox(context));
                router.push('/warrants');
            })
            .catch((err) => {
                dispatch(saveBoxFailure(context, err.message));
            });
    };
}

export function submitRenSearchForCreateWarrantModal() {
    return (dispatch, getState) => {
        const state = getState();
        const fieldName = 'reportingEventNumber';
        const isValid = createWarrantForm.selectors.formPathIsValidSelector(state)(fieldName);
        if (isValid) {
            const warrantRen = createWarrantForm.selectors.formModelByPathSelector(getState())(
                fieldName
            );
            const modalContext = { name: boxEnum.CREATE_WARRANT_MODAL };
            return dispatch(
                getInfoForReportingEventNumber(warrantRen, modalContext)
            ).then(({ reportingEventNumber }) =>
                dispatch(
                    createWarrantForm.actionCreators.changePath(fieldName, reportingEventNumber)
                )
            );
        }
    };
}

/**
 * Populate the Warrant Details form from current data state
 * @return {[type]} [description]
 */
export function populateWarrantDetailsForm() {
    return (dispatch, getState) => {
        const state = getState();
        const currentWarrantId = currentWarrantIdSelector(state);
        const warrantModel = warrantsSelector(state)[currentWarrantId] || {};
        const warrantStatus =
            mostRecentWarrantStatusByWarrantIdSelector(state)(currentWarrantId) || {};
        const warrantStatisticAttributes = warrantAttributesWhereSelector(state)({
            warrantId: currentWarrantId,
            attributeType: 'WARRANT_STATISTIC',
        });
        const warrantLocation = head(
            locationEntityLinksWhereSelector(state)({
                entityType: EntityTypeEnum.WARRANT.name,
                entityId: currentWarrantId,
                linkType: LinkTypesEnum.LOCATION_OF_WARRANT,
            })
        );

        dispatch(
            warrantDetailsForm.actionCreators.change(
                warrantModel,
                warrantStatus,
                warrantStatisticAttributes,
                warrantLocation
            )
        );
        dispatch(warrantDetailsForm.actionCreators.validate({ ruleTypes: [ruleTypes.HIDE] }));
    };
}

export function setInitialWarrantCardsState() {
    return (dispatch, getState) => {
        const state = getState();
        if (isNewWarrantSelector(state)) {
            // when we go to a new warrant, we want all the cards to be in edit mode
            dispatch(
                warrantDetailsCard.actionCreators.transitionToEditMode(populateWarrantDetailsForm)
            );
            dispatch(warrantChargesCard.actionCreators.transitionToEditMode());
            dispatch(warrantDexSubmissionCard.actionCreators.transitionToEditMode());
            dispatch(attachmentsCard.actionCreators.transitionToEditMode());
        } else {
            dispatch(warrantDetailsCard.actionCreators.resetState());
            dispatch(attachmentsCard.actionCreators.resetState());
            dispatch(warrantChargesCard.actionCreators.resetState());
            dispatch(warrantDexSubmissionCard.actionCreators.resetState());
        }
    };
}

function warrantGeneralUiReducer(
    state = {
        currentWarrantId: null,
        isNewWarrant: false,
        linkedReportIds: [],
    },
    action
) {
    switch (action.type) {
        case SET_CURRENT_WARRANT_ID:
            return {
                ...state,
                currentWarrantId: action.payload,
            };
        case SET_IS_NEW_WARRANT:
            return {
                ...state,
                isNewWarrant: action.payload,
            };
        case SET_LINKED_REPORT_IDS:
            return {
                ...state,
                linkedReportIds: action.payload,
            };
        default:
            return state;
    }
}

export default combineReducers({
    general: warrantGeneralUiReducer,
    warrantDetailsCard: warrantDetailsCard.reducer,
    warrantChargesCard: warrantChargesCard.reducer,
    attachmentsCard: attachmentsCard.reducer,
    warrantDexSubmissionCard: warrantDexSubmissionCard.reducer,
});

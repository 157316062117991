import { createSelector } from 'reselect';
import _, { first, map, filter, sortBy } from 'lodash';
import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
    allSingleAttributeValuesMapper,
    getViewModelProperties,
} from '../../../../../helpers/viewModelHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import {
    attributesSelector,
    formatAttributeByIdSelector,
    formatAttributeAbbrevByIdSelector,
} from '../../../attributes/state/data';
import { assistingOfficersSelector } from '../data';

export function formatAssistingOfficers(assistingOfficerViewModels, { showAbbrev = true } = {}) {
    return _(assistingOfficerViewModels)
        .groupBy('officerId')
        .mapValues((assistingOfficerList) => {
            const officerDisplay = getViewModelProperties(first(assistingOfficerList)).officerId
                .fullName;
            const mappedAssistTypes = map(assistingOfficerList, (assistingOfficer) => {
                const viewModelProperties = getViewModelProperties(assistingOfficer);
                if (viewModelProperties.assistTypeIsOther) {
                    return showAbbrev
                        ? viewModelProperties.assistTypeOtherAbbrevDisplay
                        : viewModelProperties.assistTypeOtherDisplay;
                } else {
                    return showAbbrev
                        ? viewModelProperties.assistTypeAbbrev
                        : viewModelProperties.assistTypeAttrId;
                }
            });
            const assistTypes = sortBy(mappedAssistTypes).join(', ');
            return `${officerDisplay} (${assistTypes})`;
        })
        .values()
        .sort()
        .join(', ');
}

const assistingOfficerViewModelsSelector = createSelector(
    allMiniUserFormatsByIdSelector,
    attributesSelector,
    formatAttributeByIdSelector,
    formatAttributeAbbrevByIdSelector,
    assistingOfficersSelector,
    (
        allMiniUserFormatsById,
        attributes,
        formatAttributeById,
        formatAttributeAbbrevById,
        assistingOfficers
    ) => {
        const viewModel = buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                buildAllMiniUserFormatsMapper(),
                ({ assistTypeAttrId, description }) => ({
                    assistTypeIsOther: attributes[assistTypeAttrId].other,
                    assistTypeAbbrev: formatAttributeAbbrevById(assistTypeAttrId),
                    assistTypeOtherAbbrevDisplay: joinTruthyValues(
                        [formatAttributeAbbrevById(assistTypeAttrId), description],
                        ' - '
                    ),
                    assistTypeOtherDisplay: joinTruthyValues(
                        [formatAttributeById(assistTypeAttrId), description],
                        ' - '
                    ),
                }),
            ],
            helpers: {
                formatAttributeById,
                allMiniUserFormatsById,
            },
        });
        return map(assistingOfficers, viewModel);
    }
);

export const assistingOfficerViewModelsWhereSelector = createSelector(
    assistingOfficerViewModelsSelector,
    (assistingOfficerViewModels) => (predicate) => filter(assistingOfficerViewModels, predicate)
);

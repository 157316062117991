import { LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { get, some } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import { FormattedDate } from '~/client-common/core/dates/components';

import { useSubPremiseFormatters } from '../../../../core/locations/hooks/useSubPremiseFormatters';
import ApprovalStatusIcon from '../../../../records/core/components/ApprovalStatusIcon';
import QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import Highlighter from '../QuickSearchHighlighter';
import WrappedFormattedDate from '../WrappedFormattedDate';
import LocationWrapper from '../layout/LocationWrapper';
import ExternalDepartmentName from '../ExternalDepartmentName';

const Wrapper = styled.div`
    padding: 10px 10px 10px 42px;
    position: relative;
`;

const StyledApprovalStatusIcon = styled(ApprovalStatusIcon)`
    position: absolute;
    left: 10px;
    top: 10px;
`;

const QuickSearchResultSectionReportItem = ({
    query,
    item,
    externalDepartmentName,
    theme,
    formatFieldByName,
    testId,
    testType,
}) => {
    const location = item.involvedElasticLocations.find(
        ({ type }) => type === LinkTypesEnum.LOCATION_OF_EVENT
    );
    const searchWords = [query];
    const renAndTitle = joinTruthyValues([item.ren, item.shortTitle], ' ');
    const reportType = !item.shortTitle
        ? get(item, 'reportDefinition.reportDefinitionName')
        : undefined;
    const { isSealed, isPartiallySealed, clientApprovalStatus } = item;
    const isVacated = some(get(item, 'reportDefinition.charges'), 'isVacated');
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <StyledApprovalStatusIcon
                approvalStatus={clientApprovalStatus}
                isSealed={isSealed}
                isPartiallySealed={isPartiallySealed}
                isVacated={isVacated}
            />

            <QuickSearchResultSectionItemTitle>
                <span>{item.ren && formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)}</span>
                <Highlighter searchWords={searchWords} textToHighlight={renAndTitle} />{' '}
                <div>{reportType}</div>
                <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
            </QuickSearchResultSectionItemTitle>
            <WrappedFormattedDate
                date={item.eventStartUtc}
                format={FormattedDate.FORMATS.SHORT_DATE}
            />
            {location && (
                <LocationWrapper>
                    <Highlighter
                        searchWords={searchWords}
                        textToHighlight={buildElasticLocationLines(location).join(', ')}
                    />
                </LocationWrapper>
            )}
        </Wrapper>
    );
};

export default compose(
    connect((state) => ({
        formatFieldByName: formatFieldByNameSelector(state),
    })),
    withTheme
)(QuickSearchResultSectionReportItem);

import { map } from 'lodash';
import { LinkTypesEnum, EntityTypeEnum, AttributeTypeEnum } from '@mark43/rms-api';
import {
    locationEntityLinksWhereSelector,
    replaceLocationEntityLinksForEntityAndLinkTypes,
} from '~/client-common/core/domain/location-entity-links/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    assistingOfficersWhereSelector,
    setAssistingOfficers,
} from '~/client-common/core/domain/assisting-officers/state/data';
import {
    attributesSelector,
    attributeIsOtherSelector,
} from '~/client-common/core/domain/attributes/state/data';
import {
    eventDetailByReportIdSelector,
    updateEventDetail,
} from '~/client-common/core/domain/event-details/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { paramedicsByReportIdSelector } from '~/client-common/core/domain/paramedics/state/data';
import {
    reportAttributesWhereSelector,
    updateReportAttributes,
} from '~/client-common/core/domain/report-attributes/state/data';
import {
    reportNotificationsWhereSelector,
    setReportNotifications,
} from '~/client-common/core/domain/report-notifications/state/data';
import {
    updateReportAgency,
    EVENT_CARD_LOCATION_LINK_TYPES,
} from '~/client-common/core/domain/reports/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { isStopReportSelector } from '~/client-common/core/domain/stops/state/ui';
import { setReportAgencyId as legacyReduxSetReportAgencyId } from '../../../../../legacy-redux/actions/reportsActions';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { convertFromFormModel, convertToFormModel } from '../forms/eventInfoForm';
import createCard from '../../utils/createCard';
import { updateEventDatesOnStopForm } from '../forms/stopForm';
import { submitCard, validateCard } from './cards';
import { refreshEventInfoForm, saveEventInfoForm } from './eventInfo';

const baseCard = createCard({
    name: reportCardEnum.EVENT_INFO.name,
    baseSelector: (state) => state.ui.report.eventInfoCard,
    anchor: 'event-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(refreshEventInfoForm());
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);
                const isDynamicReportsEnabled = applicationSettingsSelector(state).RMS_DYNAMIC_REPORT_ENABLED;

                /**
                 * The normal flow is:
                 *   In summary mode, validate the form without submitting it and without saving data to the back end.
                 *   In edit mode, validate and submit the form, then save data to the back end.
                 *
                 * But when the Dynamic Reports application setting is enabled and arrest reports are embedded in this
                 * report, their Event Info cards are not displayed. When this Event Info card is saved, it also saves
                 * data to the back end for all those other arrest reports with the `saveEventInfoForm` call below. In
                 * summary mode, validating the form is insufficient because there's no guarantee that the other reports
                 * have their Event Info synced with this one, hence the `!isDynamicReportsEnabled` condition.
                 */
                if (isInSummaryMode && !isDynamicReportsEnabled) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    const formModel = form.getState().model;
                    const currentReport = currentReportSelector(state);
                    const currentReportId = currentReport.id;
                    const attributes = attributesSelector(state);
                    const attributeIsOther = attributeIsOtherSelector(state);

                    const objectModel = convertFromFormModel(
                        formModel,
                        currentReport,
                        attributes,
                        attributeIsOther
                    );

                    // Needed, because `report` objects live in 2 branches of `state`; the `reports`
                    // branch and the `reportsTODO` branch.  The `reports` branch has actions + reducer
                    // that lives in `client/` whereas `reportTODO` lives in `client-common`.
                    const updateReportAgencyPromise = dispatch(
                        updateReportAgency(currentReportId, objectModel.agencyId)
                    ).then((updatedReport) => {
                        dispatch(legacyReduxSetReportAgencyId(updatedReport));
                    });

                    const rrfPromises = isDynamicReportsEnabled
                        ? [dispatch(saveEventInfoForm())]
                        : [
                              dispatch(updateEventDetail(objectModel.eventDetail)),
                              dispatch(
                                  replaceLocationEntityLinksForEntityAndLinkTypes({
                                      entityType: EntityTypeEnum.REPORT.name,
                                      entityId: currentReportId,
                                      linkTypes: EVENT_CARD_LOCATION_LINK_TYPES,
                                      locationEntityLinks: objectModel.locationEntityLinks,
                                  })
                              ),
                              dispatch(
                                  updateReportAttributes(
                                      currentReportId,
                                      objectModel.weatherReportAttributes,
                                      AttributeTypeEnum.WEATHER.name
                                  )
                              ),
                              dispatch(
                                  updateReportAttributes(
                                      currentReportId,
                                      objectModel.eventStatisticsReportAttributes,
                                      AttributeTypeEnum.EVENT_STATISTICS.name
                                  )
                              ),
                              updateReportAgencyPromise,
                              dispatch(
                                  setReportNotifications(
                                      currentReportId,
                                      objectModel.reportNotifications
                                  )
                              ),
                              dispatch(
                                  setAssistingOfficers(
                                      currentReportId,
                                      objectModel.assistingPersonnel
                                  )
                              ),
                          ];

                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            promises: rrfPromises,
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                // Specifically use `getState()` because state
                                // likely has been mutated by the save.
                                const state = getState();
                                const report = currentReportSelector(state);
                                const reportId = report.id;

                                const eventDetail = eventDetailByReportIdSelector(state)(reportId);
                                const weatherReportAttributes = reportAttributesWhereSelector(
                                    state
                                )({
                                    reportId,
                                    attributeType: AttributeTypeEnum.WEATHER.name,
                                });
                                const weatherAttrIds = map(weatherReportAttributes, 'attributeId');
                                const weatherDescription = getDescriptionForAttributeLinks(
                                    weatherReportAttributes
                                );

                                const eventStatisticsReportAttributes = reportAttributesWhereSelector(
                                    state
                                )({
                                    reportId,
                                    attributeType: AttributeTypeEnum.EVENT_STATISTICS.name,
                                });
                                const eventStatisticsAttrIds = map(
                                    eventStatisticsReportAttributes,
                                    'attributeId'
                                );
                                const eventStatisticsDescription = getDescriptionForAttributeLinks(
                                    eventStatisticsReportAttributes
                                );

                                const reportNotifications = reportNotificationsWhereSelector(
                                    state
                                )({ reportId });
                                const assistingPersonnel = assistingOfficersWhereSelector(state)({
                                    reportId,
                                });
                                const paramedics = paramedicsByReportIdSelector(state)(reportId);
                                const locationEntityLinks = locationEntityLinksWhereSelector(state)(
                                    {
                                        entityId: reportId,
                                        entityType: EntityTypeEnum.REPORT.name,
                                        linkType: LinkTypesEnum.LOCATION_OF_EVENT,
                                    }
                                );

                                const reportingParties = map(
                                    nameReportLinksWhereSelector(state)({
                                        reportId: currentReport.id,
                                        linkType: LinkTypesEnum.REPORTING_PARTY_IN_REPORT,
                                        contextType: EntityTypeEnum.REPORT.name,
                                        contextId: currentReport.id,
                                    }),
                                    'nameId'
                                );

                                const formModel = convertToFormModel(
                                    eventDetail,
                                    weatherAttrIds,
                                    weatherDescription,
                                    eventStatisticsAttrIds,
                                    eventStatisticsDescription,
                                    report,
                                    reportNotifications,
                                    assistingPersonnel,
                                    paramedics,
                                    locationEntityLinks,
                                    reportingParties
                                );

                                const isStopReport = isStopReportSelector(getState())(reportId);
                                if (isStopReport) {
                                    dispatch(
                                        updateEventDatesOnStopForm(
                                            formModel.eventStartUtc,
                                            formModel.eventEndUtc
                                        )
                                    );
                                }

                                form.set('', formModel);
                            },
                        })
                    );
                }
            };
        },
    },
};

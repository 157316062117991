import React, { useEffect, useState } from 'react';
import loadImage from 'blueimp-load-image';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { get } from 'lodash';

import { isUndefinedOrNull } from '../../../helpers/logicHelpers';

const backgroundImg = `
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const StyledImage = styled.img`
    ${(props) => (props.isBackgroundImage ? backgroundImg : '')}
    ${(props) => (!isUndefinedOrNull(props.zIndex) ? `z-index: ${props.zIndex};` : '')}
`;

/**
 * A wrapper class around <img> that will use
 * the image's EXIF data to properly orient the image in the browser.
 * See https://www.impulseadventure.com/photo/exif-orientation.html
 * for more details about possible EXIF orientation values.
 * This is necessary because images that clients upload may
 * rely on EXIF orientation data for proper display
 */
const ImgWithExifOrientation = ({ src, zIndex, isBackgroundImage, ...otherProps }) => {
    const [imageSrc, setImageSrc] = useState(null);
    useEffect(() => {
        if (!!src) {
            try {
                loadImage(
                    src,
                    (img) => {
                        let imgUrl;
                        if (img instanceof HTMLImageElement) {
                            imgUrl = get(img, 'src');
                        } else if (img instanceof HTMLCanvasElement) {
                            imgUrl = img.toDataURL();
                        }

                        if (imgUrl) {
                            setImageSrc(imgUrl);
                        } else {
                            setImageSrc(src);
                        }
                    },
                    {
                        orientation: true,
                    }
                );
            } catch (e) {
                setImageSrc(src);
                Sentry.captureException(e);
            }
        }
    }, [src]);
    return (
        <StyledImage
            src={imageSrc}
            zIndex={isBackgroundImage ? (!isUndefinedOrNull(zIndex) ? zIndex : -1) : zIndex}
            isBackgroundImage={isBackgroundImage}
            {...otherProps}
        />
    );
};

export default ImgWithExifOrientation;

import { FormEnum } from '@mark43/rms-api';
import _ from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import { components } from '~/client-common/core/strings';
import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';
import { validateReduxForm } from '../validationRunner';

const strings = components.reports.RecordNumberChangeForm;

function makeErrorObject(errorMessage) {
    return { _error: errorMessage };
}

export function recordNumberChangeValidator(
    { newRecordNumber, currentRecordNumber },
    { recordSequenceType, formatFieldByName }
) {
    const recordNumberDisplayName =
        recordSequenceType === recordSequenceTypeEnum.CAD_EVENT
            ? formatFieldByName(fields.CAD_TICKET_CAD_AGENCY_EVENT_NUMBER)
            : formatFieldByName(fields.REPORT_RECORD_NUMBER);

    if (newRecordNumber === '') {
        return makeErrorObject(strings.requiredError(recordNumberDisplayName));
    }

    if (newRecordNumber === currentRecordNumber) {
        return makeErrorObject(strings.sameRecordNumberError(recordNumberDisplayName));
    }

    const formContext = FormEnum.CREATE_REPORT.name;
    const formFieldNames = {
        newRecordNumber: fields.REPORT_RECORD_NUMBER,
        currentRecordNumber: null,
    };

    const reduxFormError = _.get(
        validateReduxForm({ form: formContext }, formFieldNames)({ newRecordNumber }),
        'newRecordNumber'
    );
    if (reduxFormError) {
        return makeErrorObject(reduxFormError);
    }

    return {};
}

import { Printable, RmsWorkOrder, SendEmailRequest, UserSendEmailRequest } from '@mark43/rms-api';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';

import { exportsFormDataSelector } from '../../../../../legacy-redux/selectors/exportsSelectors';
import { getPrintRequestObject } from '../../../../../legacy-redux/actions/exportsActions';
import { RmsAction } from '../../../../../core/typings/redux';
import { partitionAttachmentAndFolderIdsSelector } from '../../../../exports/core/state/data/partitionAttachmentAndFolderIds';

import { emailWorkOrder } from '../../helpers';

export const emailPrintables = (
    printables: Printable[],
    emailData: SendEmailRequest
): RmsAction<Promise<UserSendEmailRequest>> => {
    return (dispatch, getState) => {
        const state = getState();
        const exportsFormData = exportsFormDataSelector(state);
        const workOrder = (getPrintRequestObject(exportsFormData, printables, {
            isExportPreview: false,
            applicationSettings: applicationSettingsSelector(state),
            partitionAttachmentAndFolderIds: partitionAttachmentAndFolderIdsSelector(state),
            formatFieldByName: formatFieldByNameSelector(state),
        }) as unknown) as RmsWorkOrder[];

        return emailWorkOrder(workOrder, emailData);
    };
};

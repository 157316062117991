import React, { useState } from 'react';
import styled from 'styled-components';

import Text from '../../../core/forms/components/Text';
import Icon, { iconTypes, iconSizes } from '../../../core/components/Icon';

const InputContainer = styled.div`
    margin-top: 20px;
    position: relative;
    display: flex;

    & .mark43-form-field {
        flex-grow: 1;
        margin-right: 0;
    }

    & input {
        padding-left: 26px;
    }
`;
const SearchInputIcon = styled(Icon)`
    position: absolute;
    left: 4px;
    padding: 3px;
    top: 12px;
`;

export const FilterInput = React.memo(function FilterInput({ onChange, placeholder, autoFocus }) {
    const [value, setValue] = useState('');
    return (
        <InputContainer>
            <Text
                onChange={(newValue) => {
                    setValue(newValue);
                    onChange(newValue);
                }}
                value={value}
                placeholder={placeholder}
                autoFocus={autoFocus}
            />
            <SearchInputIcon type={iconTypes.SEARCH} size={iconSizes.SMALL} color="cobaltBlue" />
        </InputContainer>
    );
});

import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { lifecycleOptions } from 'markformythree';
import { Arrest, JuvenileArrestReportView } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import getArrestResource from '~/client-common/core/domain/arrests/resources/arrestResource';
import { useResource } from '~/client-common/core/hooks/useResource';

import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';

import { BodyMediumText } from '../../../../../core/components/typography';
import { currentReportIdSelector } from '../../../../../../legacy-redux/selectors/reportSelectors';
import ArbiterForm from '../../../../../core/markformythree-arbiter/ArbiterForm';
import SidePanel from '../../../../../../legacy-redux/components/core/SidePanel';
import {
    handleSubmit,
    BOOKING_SIDE_PANEL_CONTEXT,
    closeBookingSidePanel,
} from '../../../state/ui/booking';

import SubSectionTitle from '../../../../../record-privacy/core/components/SubSectionTitle';
import bookingSidePanelFormConfiguration, {
    formName as formContextName,
} from '../../../state/forms/bookingSidePanelForm';
import { refreshBookingForm } from '../../../state/forms/bookingForm';
import { RmsDispatch } from '../../../../../../core/typings/redux';
import BookingSidePanelForm from './BookingSidePanelForm';

const stringsBookingSidePanel = componentStrings.reports.core.BookingSidePanel;

const BookingSidePanelContent: React.FC<{ arrest: Arrest }> = ({ arrest }) => {
    const fieldDisplayNames = useFields(fields.ARREST_JUVENILE_TRIED_AS_ADULT);
    const [juvenileArrestReportView, setJuvenileArrestReportView] = useState<
        JuvenileArrestReportView | undefined
    >();

    const getPriorJuvenileArrestReport = useCallback(() => {
        return getArrestResource().getPriorJuvenileArrestReport(arrest.id);
    }, [arrest]);

    const onSuccess = useCallback((data) => {
        setJuvenileArrestReportView(data);
    }, []);
    useResource(getPriorJuvenileArrestReport, onSuccess);

    const hasJuvenileArrest = !!juvenileArrestReportView?.arrest;

    return (
        <div>
            <ArbiterForm
                name={formContextName}
                context={formContextName}
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                configuration={bookingSidePanelFormConfiguration}
                render={() => <BookingSidePanelForm priorJuvenileArrest={hasJuvenileArrest} />}
            />
            {hasJuvenileArrest && (
                <>
                    <SubSectionTitle>
                        {fieldDisplayNames.ARREST_JUVENILE_TRIED_AS_ADULT}
                    </SubSectionTitle>
                    <div>
                        <BodyMediumText>
                            Juvenile tried as adult in prior arrest:{' '}
                            {juvenileArrestReportView?.report?.recordNumber}
                        </BodyMediumText>
                    </div>
                    <div>
                        <BodyMediumText>
                            "{juvenileArrestReportView?.arrest?.juvenileTriedAsAdultNotes}"
                        </BodyMediumText>
                    </div>
                </>
            )}
        </div>
    );
};

const BookingSidePanel: React.FC = () => {
    const chargesWhere = useSelector(chargesWhereSelector);
    const arrestForReportId = useSelector(arrestForReportIdSelector);
    const currentReportId = useSelector(currentReportIdSelector);
    const dispatch = useDispatch<RmsDispatch>();

    if (!currentReportId) {
        return null;
    }

    const arrest = arrestForReportId(currentReportId);
    const charges = arrest ? chargesWhere({ arrestId: arrest.id }) : [];
    const showForm = charges.length > 0;

    return (
        <SidePanel
            title={stringsBookingSidePanel.title}
            context={BOOKING_SIDE_PANEL_CONTEXT}
            cancelText={showForm ? undefined : stringsBookingSidePanel.noChargesCloseButton}
            saveText={showForm ? undefined : ''}
            onSave={() =>
                dispatch(handleSubmit()).then(() => {
                    dispatch(refreshBookingForm({ reportId: currentReportId, index: undefined }));
                })
            }
            onCancel={() => dispatch(closeBookingSidePanel())}
        >
            {showForm && arrest ? (
                <>
                    <BookingSidePanelContent arrest={arrest} />
                </>
            ) : (
                <span>{stringsBookingSidePanel.noChargesMessage}</span>
            )}
        </SidePanel>
    );
};

export default BookingSidePanel;

// TODO PRINTING-REFACTOR evaluate the "DI" pattern used here
let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Attribute Code Resource',
        methods: {
            getCodesForSourceAndCategory({ codeTypeSource, codeTypeCategories }) {
                return req({
                    method: 'GET',
                    url: 'attributes/codes/source',
                    params: {
                        code_type_source: codeTypeSource,
                        code_type_categories: codeTypeCategories,
                    },
                });
            },
            cloneAttributeCodes({ sourceFrom, sourceTo }) {
                return req({
                    method: 'PUT',
                    url: 'attributes/codes/clone',
                    params: { source_from: sourceFrom, source_to: sourceTo },
                });
            },
        },
    });
}

const resourceGetter = () => resource;
/**
 * Resources for Attributes. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
export default resourceGetter;

import { EntityTypeEnum } from '@mark43/rms-api';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'arc';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    difference,
    find,
    get,
    groupBy,
    identity,
    isEmpty,
    map,
    reduce,
    reject,
    size,
    union,
} from 'lodash';
import { withRouter } from 'react-router';
import { compose, withHandlers } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';
import { isValueDisplayable } from '~/client-common/helpers/logicHelpers';
import {
    attachmentsSelector,
    loadAttachmentsByEntityIdsAndTypes,
} from '~/client-common/core/domain/attachments/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import OverlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import testIds from '../../../../core/testIds';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import {
    exportPageReleaseTrackingConfigSelector,
    redactionWorkOrderSelector,
} from '../../../../legacy-redux/selectors/exportsSelectors';
import {
    exportPDFFromWorkOrder,
    setPositionRight,
} from '../../../../legacy-redux/actions/exportsActions';
import { InlineBanner } from '../../components/InlineBanner';
import { generateExportHtmlPreview, getMostRecentUserExportHtmlPreview } from '../state/data';
// TODO: fix usage of VirtualizedList
// import { VirtualizedList } from '../../components/VirtualizedList';
import { ActionBarBase } from '../../components/ActionBar';
import { BaseScrollableUnderSubheader } from '../../components/ScrollableUnderSubheader';
import { ConditionalTooltip } from '../../components/tooltip';

import { responsiveStack } from '../../styles/mixins';
import { SecondarySectionHeader } from '../../components/typography';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { openBox } from '../../../../legacy-redux/actions/boxActions';
import Icon, { iconSizes, iconTypes } from '../../components/Icon';
import { currentReportSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { redactableFieldOptionsSelector } from '../../../exports/core/state/ui';
import CheckboxTree from '../../forms/components/checkboxes/CheckboxTree';
import { useScreenBreakpoint } from '../../utils/useScreenBreakpoint';
import { useOverlayStore } from '../../overlays/hooks/useOverlayStore';
import {
    getRedactedWorkOrder,
    redactTextFromHtmlContents,
    removeDepartmentLogo,
    toggleFieldRedaction,
} from '../utils/redactionHelpers';
import {
    createCompositeAttachmentsConfig,
    getEntityTypeToIdsMapByAttachmentsConfig,
    calculateAttachmentsSize,
} from '../utils/attachmentHelpers';
import Text from '../../forms/components/Text';
import Pill from '../../components/Pill';
import { MAX_EMAIL_ATTACHMENTS_SIZE } from '../../emails/configuration';
import { emailWorkOrder } from '../../emails/helpers';
import SendEmailModal from '../../emails/components/SendEmailModal';

import RedactionFilters from './RedactionFilters';

const strings = componentStrings.core.ExportRedactionPage;
const emailStrings = componentStrings.exports.emails;

const REDACTION_PAGE_MY_EXPORTS_RIGHT_PX = 230;
const BASE_MY_EXPORTS_RIGHT_PX = 150;

const StyledBaseScrollableUnderHeader = styled(BaseScrollableUnderSubheader)`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
`;
const PageContainer = styled.div`
    ${responsiveStack()}
    flex-direction: column-reverse;
    position: relative;
    padding: 40px var(--arc-space-6) 100px;
    height: fit-content;
    width: 100%;
`;

const PreviewColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 3;
    background-color: ${(props) => props.theme.colors.white};
    height: 100vh;

    @media (min-width: ${mediaQueries.md}) {
        margin-right: 45px;
        max-width: 67%;
    }
`;

const PreviewFooter = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    margin-bottom: 102px;
    width: 100%;
    min-height: 100px;
`;

const ErrorMessage = styled(InlineBanner)`
    text-align: center;
    margin-left: 100px;
    margin-right: 75px;
`;

const PreviewHtmlContainer = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    background-color: ${(props) => props.theme.colors.white};
`;

const BackIcon = styled(Icon)`
    display: inline-block;
    padding: 4px 6px 4px 8px;
    vertical-align: -3px;
`;

const renderPreviewFooter = () => <PreviewFooter />;

const ActionBarContent = styled.div`
    height: 100%;
    box-sizing: border-box;
    margin-left: 80px;
    padding: 4px 20px;
`;

const ExportButton = styled(Button)`
    float: right;

    @media (min-width: ${mediaQueries.md}) {
        margin-right: 75px;
    }
`;

const EmailButton = styled(Button)`
    float: right;
`;

const RedactionColumn = styled.div`
    flex: 1 1 0%;
`;

const FillablePdfMessagePill = styled(Pill)`
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : props.theme.colors.lightYellow};
    border-color: ${(props) =>
        props.borderColor ? props.borderColor : props.theme.colors.brightYellow};
    font-size: var(--arc-fontSizes-sm);
    margin-bottom: 25px;
`;

const FillablePdfWarning = styled(ErrorMessage)`
    background-color: ${(props) => props.theme.colors.lightYellow};
    border-color: ${(props) => props.theme.colors.brightYellow};
    color: ${(props) => props.theme.colors.black};
`;

const mapStateToProps = createStructuredSelector({
    redactionWorkOrder: redactionWorkOrderSelector,
    currentReport: currentReportSelector,
    exportPageReleaseTrackingConfig: exportPageReleaseTrackingConfigSelector,
    applicationSettings: applicationSettingsSelector,
    redactableFieldOptions: redactableFieldOptionsSelector,
    attachments: attachmentsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openReleaseForm: (workOrder, onSuccess) => {
        return dispatch(
            openBox(
                { name: boxEnum.CREATE_EXPORT_RELEASE_MODAL },
                {
                    workOrder,
                    onSuccess,
                }
            )
        );
    },
    exportPDFFromWorkOrder: (workOrder, onSuccess) => {
        return dispatch(exportPDFFromWorkOrder(workOrder, onSuccess));
    },
    setPositionRight: (positionRight) => dispatch(setPositionRight(positionRight)),
    loadAttachmentsByEntityIdsAndTypes: (entityTypeToIdsMap) => {
        if (!isEmpty(entityTypeToIdsMap)) {
            dispatch(loadAttachmentsByEntityIdsAndTypes(entityTypeToIdsMap));
        }
    },
});

const ExportRedactionPage = ({
    redactionWorkOrder,
    navigateToExports,
    onExport,
    redactableFieldOptions,
    setPositionRight,
    loadAttachmentsByEntityIdsAndTypes,
    attachments,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [originalPreviewHtmlContents, setOriginalPreviewHtmlContents] = useState([]);
    const [workOrder, setWorkOrder] = useState(null);
    const [redactedFields, setRedactedFields] = useState([]);
    const [textToRedact, setTextToRedact] = useState(null);
    const [textRedactions, setTextRedactions] = useState([]);
    const [redactedPreviewHtmlContents, setRedactedPreviewHtmlContents] = useState([]);
    const [containsFillablePdf, setContainsFillablePdf] = useState(false);

    const textToRedactLength = size(textToRedact);
    const isTextToRedactValid = textToRedactLength === 0 || textToRedactLength > 2;
    const { isMobile } = useScreenBreakpoint();
    const overlayStore = useOverlayStore();

    const attachmentsByEntityId = groupBy(attachments, 'entityId');
    const { attachmentsConfig, entitiesMap } = useMemo(() => {
        const attachmentsConfig = createCompositeAttachmentsConfig(workOrder);
        const entitiesMap = getEntityTypeToIdsMapByAttachmentsConfig(attachmentsConfig);
        return { attachmentsConfig, entitiesMap };
    }, [workOrder]);
    const attachmentsSize = calculateAttachmentsSize(attachmentsConfig, attachmentsByEntityId);

    const isEmailButtonVisible = !isEmpty(redactedPreviewHtmlContents);
    const isEmailButtonDisabled = attachmentsSize > MAX_EMAIL_ATTACHMENTS_SIZE;

    const loadExportHtmlPreviewSuccess = (exportHtmlPreview) => {
        const previewHtmlContents = get(exportHtmlPreview, 'htmlPaper.content');
        const workOrder = get(exportHtmlPreview, 'workOrder');
        /**
         * When generating our preview, we pass in the isExportPreview: true
         * exportConfig -- however once we get the workOrder back from the backend
         * we'll set it to false for once we eventually send this WorkOrder
         * to the backend for actual printing
         */
        const updatedWorkOrder = {
            ...workOrder,
            ec: {
                ...workOrder.ec,
                isExportPreview: false,
            },
        };
        setOriginalPreviewHtmlContents(previewHtmlContents);
        setRedactedPreviewHtmlContents(previewHtmlContents);
        setWorkOrder(updatedWorkOrder);
        setContainsFillablePdf(get(exportHtmlPreview, 'containsFillablePdf'));
        setIsLoading(false);
    };

    const loadExportHtmlPreviewFailure = () => {
        setIsLoading(false);
        setErrorMsg(strings.errorLoadingPreview);
    };

    useEffect(() => {
        setPositionRight(`${REDACTION_PAGE_MY_EXPORTS_RIGHT_PX}px`);
        if (!!redactionWorkOrder) {
            setIsLoading(true);
            generateExportHtmlPreview(redactionWorkOrder)
                .then(loadExportHtmlPreviewSuccess)
                .catch(loadExportHtmlPreviewFailure);
        } else {
            getMostRecentUserExportHtmlPreview()
                .then(loadExportHtmlPreviewSuccess)
                .catch(loadExportHtmlPreviewFailure);
        }
        return () => setPositionRight(`${BASE_MY_EXPORTS_RIGHT_PX}px`);
    }, [redactionWorkOrder, setPositionRight]);

    useEffect(() => {
        loadAttachmentsByEntityIdsAndTypes(entitiesMap);
    }, [loadAttachmentsByEntityIdsAndTypes, entitiesMap]);

    const redactionCheckboxOptions = map(redactableFieldOptions, (fieldOption) => ({
        ...fieldOption,
        parentValue: strings.selectAll,
    }));

    const onChangeRedactedFields = (newRedactedFields) => {
        const changedFields = union(
            difference(redactedFields, newRedactedFields),
            difference(newRedactedFields, redactedFields)
        );
        setRedactedFields(newRedactedFields);
        const redactedHtmlContents = toggleAllFieldRedactions(
            redactedPreviewHtmlContents,
            changedFields
        );
        setRedactedPreviewHtmlContents(redactedHtmlContents);
    };

    const runRedactions = (text, currRedactions) => {
        const { redaction, redactedHtmlContents } = redactTextFromHtmlContents({
            htmlContents: originalPreviewHtmlContents,
            textToRedact: text,
            currentTextRedactions: currRedactions,
        });
        if (!!redaction) {
            currRedactions = [...currRedactions, redaction];
        }

        setTextRedactions(() => currRedactions);

        const redactedWithToggledHtmlContents = toggleAllFieldRedactions(
            redactedHtmlContents,
            redactedFields
        );
        setRedactedPreviewHtmlContents(redactedWithToggledHtmlContents);
    };

    const toggleAllFieldRedactions = (contents, fields) => {
        if (fields.length === 0) {
            return contents;
        }
        return map(contents, (content) => {
            return reduce(
                fields,
                (acc, field) => {
                    return toggleFieldRedaction({
                        fieldName: field,
                        html: acc,
                    });
                },
                content
            );
        });
    };

    const onRedactText = () => {
        if (isValueDisplayable(textToRedact) && isTextToRedactValid) {
            runRedactions(textToRedact, textRedactions);
            setTextToRedact(null);
        }
    };

    const onClearFilter = (key) => {
        const targetRedaction = find(textRedactions, { key });
        if (targetRedaction) {
            const updatedRedactions = reject(textRedactions, targetRedaction);
            runRedactions(null, updatedRedactions);
        }
    };

    const onEmail = (emailData) => {
        const redactedWorkOrder = [
            getRedactedWorkOrder({
                workOrder,
                redactedFields,
                originalPreviewHtmlContents,
                textRedactions,
            }),
        ];
        return emailWorkOrder(redactedWorkOrder, emailData);
    };

    const handleEmail = () => {
        overlayStore.open(OverlayIdEnum.SEND_EMAIL_MODAL, { onSave: onEmail });
    };

    const additionalTrackingParams = {
        [AnalyticsPropertyEnum.REDACTION_SEARCH_TAGS]: size(textRedactions),
        [AnalyticsPropertyEnum.REDACTION_FIELDS]: redactedFields,
    };

    // Tooltip doesn't work for legacy Buttons without intermediate element. Known issue.
    const emailButton = (
        <ConditionalTooltip
            condition={isEmailButtonDisabled}
            content={emailStrings.attachmentsSizeTooltip}
            align="end"
            hasButtonOffset
            side="top"
        >
            <div>
                <EmailButton
                    className={buttonTypes.SECONDARY}
                    disabled={isEmailButtonDisabled}
                    testId={testIds.EMAIL_BUTTON}
                    onClick={handleEmail}
                >
                    {emailStrings.email}
                </EmailButton>
            </div>
        </ConditionalTooltip>
    );

    return (
        <>
            <StyledBaseScrollableUnderHeader>
                {isLoading ? (
                    <SimpleLoading />
                ) : (
                    <PageContainer>
                        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
                        {!isEmpty(redactedPreviewHtmlContents) ? (
                            <>
                                <PreviewColumn>
                                    {map(
                                        redactedPreviewHtmlContents,
                                        (redactedPreviewHtmlContent) => (
                                            <PreviewHtmlContainer
                                                // eslint-disable-next-line react/no-danger
                                                dangerouslySetInnerHTML={{
                                                    __html: removeDepartmentLogo(
                                                        redactedPreviewHtmlContent
                                                    ),
                                                }}
                                                data-test-id={testIds.HTML_PREVIEW_CONTAINER}
                                            />
                                        )
                                    )}
                                    {renderPreviewFooter()}
                                </PreviewColumn>
                                <RedactionColumn data-test-id={testIds.REDACTION_COLUMN}>
                                    {containsFillablePdf && (
                                        <div data-test-id={testIds.FILLABLE_PDF_MESSAGE_PILL}>
                                            <FillablePdfMessagePill>
                                                {strings.fillablePdfMessage}
                                            </FillablePdfMessagePill>
                                        </div>
                                    )}
                                    <SecondarySectionHeader>
                                        {strings.redactText}
                                    </SecondarySectionHeader>
                                    <Text
                                        width={'80%'}
                                        goButton={true}
                                        goButtonText={strings.redact}
                                        onClick={onRedactText}
                                        onPressEnter={onRedactText}
                                        onChange={setTextToRedact}
                                        value={textToRedact}
                                        autoFocus={true}
                                        placeholder={strings.enterTextToRedact}
                                        error={
                                            !isTextToRedactValid &&
                                            strings.minimumLengthErrorMessage
                                        }
                                        forceShowError={true}
                                    />
                                    <RedactionFilters
                                        filters={textRedactions}
                                        onClearFilter={onClearFilter}
                                    />
                                    <SecondarySectionHeader>
                                        {strings.redactFields}
                                    </SecondarySectionHeader>
                                    <CheckboxTree
                                        value={redactedFields}
                                        options={redactionCheckboxOptions}
                                        onChange={onChangeRedactedFields}
                                        // Options are already sorted
                                        sortOptions={identity}
                                    />
                                </RedactionColumn>
                            </>
                        ) : (
                            <FillablePdfWarning>{strings.fillablePdfMessage}</FillablePdfWarning>
                        )}
                    </PageContainer>
                )}
            </StyledBaseScrollableUnderHeader>
            <ActionBarBase visible={true}>
                <ActionBarContent>
                    {!isMobile && (
                        <Button
                            className={buttonTypes.SECONDARY}
                            iconLeft={
                                <BackIcon
                                    size={iconSizes.MEDIUM}
                                    color="cobaltBlue"
                                    type={iconTypes.OPEN_LEFT}
                                />
                            }
                            onClick={navigateToExports}
                            testId={testIds.BACK_BUTTON}
                        >
                            {strings.backToExportPage}
                        </Button>
                    )}
                    {!isEmpty(redactedPreviewHtmlContents) && (
                        <ExportButton
                            additionalTrackingParams={additionalTrackingParams}
                            testId={testIds.EXPORT_REDACTION_BUTTON}
                            className={buttonTypes.PRIMARY}
                            onClick={onExport({
                                workOrder,
                                redactedFields,
                                originalPreviewHtmlContents,
                                textRedactions,
                            })}
                        >
                            {strings.export}
                        </ExportButton>
                    )}
                    <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                        {isEmailButtonVisible && emailButton}
                    </FeatureFlagged>
                </ActionBarContent>
            </ActionBarBase>
            <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                <SendEmailModal />
            </FeatureFlagged>
        </>
    );
};

const navigateToExports = ({ router, currentReport }) => () => {
    const reportId = get(currentReport, 'id');
    if (!!reportId) {
        router.push(`/reports/${reportId}/exports`);
    }
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        navigateToExports,
        onExport: ({
            exportPageReleaseTrackingConfig,
            exportPDFFromWorkOrder,
            openReleaseForm,
            applicationSettings,
            currentReport,
            router,
        }) => ({
            workOrder,
            redactedFields,
            originalPreviewHtmlContents,
            textRedactions,
        }) => () => {
            const workOrderWithAllRedactions = getRedactedWorkOrder({
                workOrder,
                redactedFields,
                originalPreviewHtmlContents,
                textRedactions,
            });

            const reportId = get(currentReport, 'id');
            const isCpsReport = get(
                exportPageReleaseTrackingConfig(EntityTypeEnum.REPORT.name, reportId),
                'isCpsReport'
            );

            const onSuccess = navigateToExports({ router, currentReport });

            if (!isCpsReport && applicationSettings.RELEASE_TRACKING_ENABLED) {
                openReleaseForm(workOrderWithAllRedactions, onSuccess);
            } else {
                exportPDFFromWorkOrder(workOrderWithAllRedactions, onSuccess);
            }
        },
    })
)(ExportRedactionPage);

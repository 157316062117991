import { createSelector } from 'reselect';
import { getLocationSpecificCurrencyFormatter } from '~/client-common/core/dates/utils/currencyHelpers';

import { departmentProfileByIdSelector } from '../../../department-profiles/state/data';
import {
    getLocationSpecificDateTimeFormats,
    getLocationSpecificDateTimeFormatter,
} from '../../../../dates/utils/dateHelpers';

export interface CurrentUserState {
    userIsLoggedIn: boolean;
    userIsBootstrapping: boolean;
    currentUserId?: number;
    currentUserDepartmentId?: number;
    currentUserDepartmentAgencyId?: number;
}

// @ts-expect-error typing this with `state: RootState` produces the following error
// Type alias 'RootState' circularly references itself.
export const currentUserUiSelector = (state): CurrentUserState => state.ui.currentUser;

export const currentUserDepartmentCountryCodeSelector = createSelector(
    currentUserUiSelector,
    departmentProfileByIdSelector,
    (currentUser, departmentProfileById) => {
        const currentUserDepartmentId = currentUser?.currentUserDepartmentId;
        const currentUserDepartmentProfile = departmentProfileById(currentUserDepartmentId);
        return currentUserDepartmentProfile?.locationBias.countryCode;
    }
);

export const currentUserDepartmentProfileSelector = createSelector(
    currentUserUiSelector,
    departmentProfileByIdSelector,
    (currentUser, departmentProfileById) =>
        departmentProfileById(currentUser?.currentUserDepartmentId)
);

export const currentDepartmentDateFormatsSelector = createSelector(
    currentUserDepartmentCountryCodeSelector,
    (countryCode) => getLocationSpecificDateTimeFormats(countryCode)
);

export const currentDepartmentDateFormatterSelector = createSelector(
    currentUserDepartmentCountryCodeSelector,
    (countryCode) => getLocationSpecificDateTimeFormatter(countryCode)
);

export const currentDepartmentCurrencyFormatterSelector = createSelector(
    currentUserDepartmentCountryCodeSelector,
    (countryCode) => getLocationSpecificCurrencyFormatter(countryCode)
);

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classNames from 'classnames';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import testIds from '../../../core/testIds';
import HelpText from '../core/HelpText';
import ExportsFile from './ExportsFile';

class FormExportsPackets extends React.Component {
    render() {
        const files = _.map(this.props.files, (file) => <ExportsFile {...file} key={file.id} />);
        const filesReadyToDownload = _.filter(
            this.props.files,
            (download) => download.file && !download.failedPrint
        );
        const strings = componentStrings.exports.MyExports;

        const isBulkExportCaseEnabled = this.props.RMS_BULK_EXPORT_CASE_ENABLED;
        const isWithinBulkExportCaseLimit =
            this.props.files.length <= this.props.RMS_BULK_EXPORT_CASE_LIMIT;

        return (
            <div>
                <div className="exports-list" data-test-id={testIds.EXPORTS_EXPORTS_LIST}>
                    {files}
                </div>
                <div className="exports-list-footer">
                    <div
                        onClick={this.props.clearAll}
                        className="exports-list-clearall"
                        data-test-id={testIds.EXPORTS_EXPORTS_LIST_CLEAR_ALL_BUTTON}
                    >
                        {strings.clearAll}
                    </div>
                    {isBulkExportCaseEnabled && (
                        <div
                            className={classNames('exports-list-downloadall', {
                                disabled:
                                    !isWithinBulkExportCaseLimit || !filesReadyToDownload.length,
                            })}
                        >
                            <span
                                onClick={() =>
                                    isWithinBulkExportCaseLimit ? this.props.downloadAll() : null
                                }
                            >
                                {strings.downloadAll}
                            </span>
                            <HelpText
                                content={strings.downloadAllTooltip(
                                    this.props.RMS_BULK_EXPORT_CASE_LIMIT
                                )}
                                collisionBoundary={document.querySelector('.exports-list-footer')}
                                className="tooltip"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default connect(applicationSettingsSelector)(FormExportsPackets);

import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import theme from '../../../modules/core/styles/theme';

/**
 * @deprecated
 * Please use client/src/scripts/modules/core/components/Icon.js instead
 */
export const iconTypes = {
    // in alphabetical order!
    ADD: 'icon-add', // plus symbol
    ANALYSIS: 'icon-analysis', // line graph
    ARROWDOWN: 'icon-arrowdown',
    ARROWUP: 'icon-arrowup',
    ARROWRIGHT: 'icon-arrowright',
    ARROWLEFT: 'icon-arrowleft',
    BACK: 'icon-back', // left chevron
    BARCODE: 'icon-barcode', // evidence barcode
    BINOCULARS: 'icon-binoculars', // binoculars
    BOLD: 'icon-bold', // B
    CAD: 'icon-cad', // piece of paper that says CAD
    CAMERA: 'icon-camera', // photo camera
    CALENDAR: 'icon-calendar',
    CARDVIEW: 'icon-cardview',
    CASEREVIEW: 'icon-casereview', // pencil
    CASEREVIEWP: 'icon-casereviewp', // pencil with P
    CASEVIEW1: 'icon-caseview1', // columns/horizontal
    CASEVIEW2: 'icon-caseview2', // rows/vertical
    CASES: 'icon-cases', // folder
    CHARGE: 'icon-charge', // book
    CHECK: 'icon-check', // check mark
    COLLAPSE: 'icon-collapse', // horizontal line
    COLLAPSEARROW: 'icon-collapsearrow',
    COLLAPSEWINDOW: 'icon-collapsewindow', // arrows pointing diagonally in
    CURRENTLYEDITING: 'icon-currentlyediting',
    DEATH: 'icon-death', // skull
    DELETE: 'icon-closex', // X
    DUPLICATE: 'icon-duplicate', // filled in square over non-filled in square
    EDIT: 'icon-edit', // pen
    EMAIL: 'icon-email', // envelope
    EXPAND: 'icon-expand', // chevron down
    EXPANDWINDOW: 'icon-expandwindow', // arrows pointing diagonally out
    EXPORT: 'icon-export', // file with down(load) arrow
    FILE: 'icon-file', // piece of paper
    FILE_AUDIO: 'icon-file-audio', // music note
    FILE_VIDEO: 'icon-file-video', // video camera
    FILTER: 'icon-filter', // funnel
    FIREARM: 'icon-firearm', // gun
    GHOST: 'icon-ghost',
    GOOGLEVERIFIEDLOCATION: 'icon-googleverifiedlocation',
    HANDCUFFS: 'icon-handcuffs',
    HISTORYVIEW: 'icon-historyview', // clock in counterclockwise arrowed circle
    HOME: 'icon-home', // house
    INJURY: 'icon-injury', // cross
    ITEM: 'icon-item', // document box
    LABEL: 'icon-label', // store tag
    LINK: 'icon-link', // chain
    LINK2: 'icon-link2', // exact duplicate of link?
    LOCK: 'icon-lock', // lock with check mark inside it
    LOCATION: 'icon-location', // map marker
    MAPMARKER: 'icon-mapmarker', // smaller map marker over circle
    MARK43: 'icon-mark43-logo',
    MOON: 'icon-moon',
    MOREOPTIONS: 'icon-moreoptions', // dot dot dot
    MOVEDOWN: 'icon-movedown',
    MOVETOP: 'icon-movetop',
    MOVEUP: 'icon-moveup',
    NEXT: 'icon-next', // right chevron
    NOTEPAD: 'icon-notepad',
    NOTIFICATIONS: 'icon-notifications', // email
    ORGANIZATION: 'icon-organization', // building
    OPTIONS: 'icon-options', // hamburger
    PDF: 'icon-pdf', // piece of paper that says PDF
    PERSON: 'icon-person',
    PHONEANALYSIS: 'icon-phoneanalysis', // phone inside marker
    POPOUT: 'icon-popout', // open link in new tab
    PRINT: 'icon-print',
    PULLDATA: 'icon-pulldata', // arrow pointing to form thingy
    PULLDATA_RIGHTARROW: 'icon-pulldata_rightarrow', // arrow point to form, to the right
    RELATIONSHIP: 'icon-relationship', // two people
    REPORT: 'icon-reports', // document; piece of paper with lines
    REVIEW: 'icon-review', // spectacles
    REVIEWP: 'icon-reviewp', // spectacles with P
    SAVEDSEARCH: 'icon-savedsearch', // magnifying glass with a star
    SEARCH: 'icon-search', // magnifying glass
    SNA: 'icon-SNA', // 3 circles connected in a triangle
    SOCIALMEDIA: 'icon-socialmedia',
    SOCIETY: 'icon-society', // government building icon
    STAR: 'icon-star_fill', // filled in star
    STAR_OUTLINE: 'icon-star_stroke', // outline of star
    SUN: 'icon-sun',
    SYNC: 'icon-Sync', // arrow going in a circle
    TEXT: 'icon-text', // T
    TOOLTIP_ACTIVE: 'icon-tooltip_active',
    TOOLTIP_INACTIVE: 'icon-tooltip_inactive',
    TRAFFICINTERACTIONS: 'icon-trafficinteractions', // up arrow
    TRAFFICOBJECTS: 'icon-trafficobjects', // car
    TRAFFICSIGNS: 'icon-trafficsigns', // stop sign
    TRASH: 'icon-trash', // garbage can
    TRIANGLEDOWN: 'icon-triangledown', // filled in triangle
    TRIANGLERIGHT: 'icon-triangleright', // filled in triangle
    TRIANGLEUP: 'icon-triangleup', // filled in triangle
    VEHICLE: 'icon-vehicle', // van/bus
    VERIFIEDLOCATION: 'icon-verifiedlocation',
    WARRANT: 'icon-warrant', // paper warrant representation
    ZOOM: 'icon-zoom', // magnifying glass with +
} as const;

type ValueOf<T> = T[keyof T];

const Container = styled.i<{ fontSize?: string; color?: keyof typeof theme.colors }>`
    &&::before {
        ${(props) => (props.fontSize ? `font-size: ${props.fontSize};` : '')};
        ${(props) => (props.color ? `color: ${props.theme.colors[props.color]}` : '')};
    }
`;

/**
 * @deprecated
 * Please use client/src/scripts/modules/core/components/Icon.js instead
 */
const Icon: React.FC<{
    testId?: string;
    className?: string;
    width?: string | number;
    style?: React.CSSProperties;
    type: ValueOf<typeof iconTypes>;
    fontSize?: string;
    onClick?: () => void;
}> = (props) => {
    return (
        <Container
            {...props}
            data-test-id={props.testId}
            className={classNames(props.type, props.className, 'mark43-icon', {
                'fixed-width-icon': !!props.width,
            })}
            style={{
                width: props.width,
                ...props.style,
            }}
        />
    );
};

export default Icon;

import { combineReducers } from 'redux';
import inlineAttachmentsUploaderUiReducer from './inlineAttachmentsUploader';
import overlayManagerAttachmentsSidePanelUiReducer from './attachmentsSidePanel';
import fileScanningUiReducer from './securityScanning';

export default combineReducers({
    attachmentsSidePanel: overlayManagerAttachmentsSidePanelUiReducer,
    inlineAttachmentsUploader: inlineAttachmentsUploaderUiReducer,
    fileScanning: fileScanningUiReducer,
});

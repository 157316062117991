import { ElasticSearchTypeEnum } from '@mark43/rms-api';

import _, { get, map } from 'lodash';
import getRmsSavedSearchResource from '../../../search/core/resources/rmsSavedSearchResource';
import { NUM_RECENT_SAVED_SEARCHES } from '../../configuration';
import storeRmsSavedSearchesView from '../../../search/core/utils/storeRmsSavedSearchesView';
import { convertSavedSearchQueryShapesToLegacyQueryShape } from '../../../search/core/utils/createSearchModule';

// all search modules that support saved search
import { usersDashboardSearch } from '../../../admin/users/state/ui';
import { allCasesSearch } from '../../../cases/all-cases/state/ui';
import { myCasesSearch } from '../../../cases/my-cases/state/ui';
import { unassignedReportsSearch } from '../../../cases/unassigned-reports/state/ui';
import { reportsDashboardSearch } from '../../../reports/dashboard/state/ui';
import { evidenceDashboardSearch } from '../../../evidence/dashboard/state/ui';
import advancedSearchCadTickets from '../../../search/cad-tickets/state/ui';
import advancedSearchOrganizations from '../../../search/organizations/state/ui';
import advancedSearchPersons from '../../../search/persons/state/ui';
import advancedSearchProperty from '../../../search/property/state/ui';
import advancedSearchReports from '../../../search/reports/state/ui';
import advancedSearchVehicles from '../../../search/vehicles/state/ui';
import { warrantsDashboardSearch } from '../../../warrants/dashboard/state/ui';

/**
 * Configuration objects for each elastic search type included in the Recent Saved Searches feature.
 *
 * Note that not all elastic search types currently support saved search on front end.
 * @type {Object}
 */
export const savedSearchTypeMap = {
    [ElasticSearchTypeEnum.CAD_TICKET.name]: {
        module: advancedSearchCadTickets,
    },
    [ElasticSearchTypeEnum.CASE.name]: {
        module: allCasesSearch,
    },
    [ElasticSearchTypeEnum.CASE_ASSIGNED_TO_ME.name]: {
        module: myCasesSearch,
    },
    [ElasticSearchTypeEnum.EVIDENCE_ITEM.name]: {
        module: evidenceDashboardSearch,
    },
    [ElasticSearchTypeEnum.ORGANIZATION.name]: {
        module: advancedSearchOrganizations,
    },
    [ElasticSearchTypeEnum.PERSON.name]: {
        module: advancedSearchPersons,
    },
    [ElasticSearchTypeEnum.PROPERTY.name]: {
        module: advancedSearchProperty,
    },
    [ElasticSearchTypeEnum.REPORT.name]: {
        module: advancedSearchReports,
    },
    [ElasticSearchTypeEnum.REPORT_NOT_ASSIGNED_TO_CASE.name]: {
        module: unassignedReportsSearch,
    },
    [ElasticSearchTypeEnum.REPORTS_DASHBOARD.name]: {
        module: reportsDashboardSearch,
    },
    [ElasticSearchTypeEnum.USER.name]: {
        module: usersDashboardSearch,
    },
    [ElasticSearchTypeEnum.VEHICLE.name]: {
        module: advancedSearchVehicles,
    },
    [ElasticSearchTypeEnum.WARRANT.name]: {
        module: warrantsDashboardSearch,
    },
};

function loadAllSavedSearches({ hideLoadingBar = false } = {}) {
    return function (dispatch) {
        return getRmsSavedSearchResource()
            .getNonAutoSavedRmsSavedSearchesView(hideLoadingBar)
            .then((rmsSavedSearchesView) => {
                const { savedSearches } = rmsSavedSearchesView;
                // eslint-disable-next-line no-restricted-syntax
                dispatch(storeRmsSavedSearchesView({ rmsSavedSearchesView }));
                return savedSearches;
            });
    };
}

/**
 * Load saved searches that the user most recently executed, and store them in ui state.
 *
 * Only the elastic search types specified in `savedSearchTypeMap` will be included.
 * @return {Promise<number[]>}
 */
export function loadRecentSavedSearches({ hideLoadingBar = false } = {}) {
    return function (dispatch) {
        return dispatch(loadAllSavedSearches({ hideLoadingBar })).then((savedSearches) => {
            // filter down the saved searches to only the ones we will show
            const recentSavedSearches = _(savedSearches)
                .filter(({ searchType }) => searchType in savedSearchTypeMap)
                .sortBy('lastExecutedDateUtc')
                .reverse()
                .take(NUM_RECENT_SAVED_SEARCHES)
                .value();

            _(recentSavedSearches)
                .groupBy('searchType')
                .forEach((savedSearches, searchType) => {
                    const searchModule = get(savedSearchTypeMap[searchType], 'module');
                    convertSavedSearchQueryShapesToLegacyQueryShape(savedSearches);
                    // store these saved searches in ui state in order to display them properly
                    // and to execute them when clicked
                    dispatch(searchModule.actionCreators.loadSavedSearchesSuccess(savedSearches));
                });

            return map(recentSavedSearches, 'id');
        });
    };
}

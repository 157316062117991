import React from 'react';
import { map } from 'lodash';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

export default function PrimaryUnitCell(elasticCadTicket) {
    const { involvedUnitCallSigns, involvedOfficerIds } = getViewModelProperties(elasticCadTicket);

    return (
        <div className="elastic-cad-ticket-primary-unit">
            <div>{involvedUnitCallSigns || 'No Units'}</div>
            {involvedOfficerIds.length ? (
                map(involvedOfficerIds, (officer, index) => <div key={index}>{officer}</div>)
            ) : (
                <div>No Officers</div>
            )}
        </div>
    );
}

import { ProductModuleEnum, ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import { invert, map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import pluralize from 'pluralize';

import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    DISPLAY_ONLY_CAD_LABEL,
    DISPLAY_ONLY_ORGANIZATION_LABEL,
} from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import AdvancedSearchReportsForm from '../../reports/components/AdvancedSearchReportsForm';
import AdvancedSearchReportsResults from '../../reports/components/AdvancedSearchReportsResults';
import AdvancedSearchCadTicketsForm from '../../cad-tickets/components/AdvancedSearchCadTicketsForm';
import AdvancedSearchCadTicketsResults from '../../cad-tickets/components/AdvancedSearchCadTicketsResults';
import AdvancedSearchPersonsForm from '../../persons/components/AdvancedSearchPersonsForm';
import AdvancedSearchPersonsResults from '../../persons/components/AdvancedSearchPersonsResults';
import AdvancedSearchOrganizationsForm from '../../organizations/components/AdvancedSearchOrganizationsForm';
import AdvancedSearchOrganizationsResults from '../../organizations/components/AdvancedSearchOrganizationsResults';
import AdvancedSearchVehiclesForm from '../../vehicles/components/AdvancedSearchVehiclesForm';
import AdvancedSearchVehiclesResults from '../../vehicles/components/AdvancedSearchVehiclesResults';
import AdvancedSearchPropertyForm from '../../property/components/AdvancedSearchPropertyForm';
import AdvancedSearchPropertyResults from '../../property/components/AdvancedSearchPropertyResults';
import AdvancedSearchAttachmentsForm from '../../attachments/components/AdvancedSearchAttachmentsForm';
import AdvancedSearchAttachmentsResults from '../../attachments/components/AdvancedSearchAttachmentsResults';
import AdvancedSearchCourtOrdersForm from '../../court-orders/components/AdvancedSearchCourtOrdersForm';
import AdvancedSearchCourtOrdersResults from '../../court-orders/components/AdvancedSearchCourtOrdersResults';

import { abilitiesEnum } from '../../../core/abilities';
import advancedSearchPersons from '../../persons/state/ui';
import advancedSearchReports from '../../reports/state/ui';
import advancedSearchCadTickets from '../../cad-tickets/state/ui';
import advancedSearchOrganizations from '../../organizations/state/ui';
import advancedSearchVehicles from '../../vehicles/state/ui';
import advancedSearchProperty from '../../property/state/ui';
import advancedSearchAttachments from '../../attachments/state/ui';
import advancedSearchCourtOrders from '../../court-orders/state/ui';

import { downloadSelectFile } from '../../../attachments/files/helpers';
import {
    currentUserHasAbilitySelector,
    currentUserDepartmentIdSelector,
} from '../../../core/current-user/state/ui';
import { clientStringifyQuery } from '../../../../legacy-redux/helpers/urlHelpers';
import { scrollToTop } from '../../../../legacy-redux/helpers/navigationHelpers';

import Tabs, { Tab } from '../../../../legacy-redux/components/core/Tabs';

import pathParamToSearchTab from '../utils/pathParamToSearchTab';
import AdvancedSearchErrorBar from './AdvancedSearchErrorBar';

import AdvancedSearchOpenSavedSearchesBar from './AdvancedSearchOpenSavedSearchesBar';
import SearchMergeEntityBar from './SearchMergeEntityBar';
import SearchExportBar from './SearchExportBar';
import SearchCard from './SearchCard';
import { AdvancedSearchBookings } from './AdvancedSearchBookings';

const { PERSON_PROFILE, ORGANIZATION_PROFILE, VEHICLE } = EntityTypeEnum;
const { titles } = componentStrings.search.AdvancedSearch;

const searchTabToPath = invert(pathParamToSearchTab);

function AdvancedSearch({
    // general
    handleErrorActionTextClick,
    navigateToTab,
    params: { searchType } = {},
    children,

    // persons
    hasPersonsResults,
    personsSearchErrorMessage = '',
    personsSavedSearches,
    personsSelectedRows,
    personsResults,

    // cad tickets
    hasCadTicketsResults,
    cadTicketsSearchErrorMessage = '',
    cadSavedSearches,

    // reports
    reportsSavedSearches,
    hasReportsResults,
    reportsSearchErrorMessage = '',
    reportsSelectedRows,
    reportsAllResultsSelected,
    reportsTotalCount,
    reportsCurrentResults,
    reportsExportDisabled,
    handleReportsClickExport,
    handleReportsSelectAllRows,
    handleReportsClearAllSelectedRows,

    // organizations
    hasOrganizationsResults,
    organizationsSearchErrorMessage = '',
    organizationsSavedSearches,
    organizationsSelectedRows,
    organizationsResults,

    // vehicles
    hasVehiclesResults,
    vehiclesSearchErrorMessage = '',
    vehiclesSavedSearches,
    vehiclesSelectedRows,
    vehiclesResults,

    // property
    hasPropertyResults,
    propertySearchErrorMessage = '',
    propertySavedSearches,
    propertySelectedRows,
    propertyTotalCount,
    propertyCurrentResults,
    propertyAllResultsSelected,
    propertyExportDisabled,
    handlePropertyClickExport,
    handlePropertySelectAllRows,
    handlePropertyClearAllSelectedRows,

    // attachments
    hasAttachmentsResults,
    attachmentsSearchErrorMessage = '',
    attachmentsSelectedRows,
    attachmentsAllResultsSelected,
    attachmentsCurrentResults,
    attachmentsExportDisabled,
    handleAttachmentsClickExport,
    handleAttachmentsSelectAllRows,
    handleAttachmentsClearAllSelectedRows,

    courtOrdersSearchErrorMessage = '',
    hasCourtOrdersResults,
    courtOrdersSelectedRows,
    courtOrdersAllResultsSelected,
    courtOrdersCurrentResults,
    courtOrdersTotalCount,
    courtOrdersExportDisabled,
    handleCourtOrdersClickExport,
    handleCourtOrdersSelectAllRows,
    handleCourtOrdersClearAllSelectedRows,

    // product modules
    isProductModuleActive,
    currentUserHasAbility,
    currentUserDepartmentId,
    handleMergeEntitiesClick,
    consortiumDepartmentLinksAvailable,
    fieldDisplayNames,

    applicationSettings,
}) {
    const searchTab = pathParamToSearchTab[searchType || ''];
    /*
     * If we intend to render a component specified beneath advanced search
     * in the router (eg: /search/reports/export) then we should render that
     * and not the search page
     */

    if (children) {
        return <>{children}</>;
    }

    const isBookingEnabled =
        applicationSettings.RMS_JMS_BOOKINGS_ENABLED === true &&
        isProductModuleActive(ProductModuleEnum.JMS.name) &&
        currentUserHasAbility(abilitiesEnum.CORE.VIEW_GENERAL);

    return (
        <div className="advanced-search-container">
            <Tabs
                selectedTab={searchTab}
                onSelectTab={navigateToTab}
                className="mark43-nav-tabs advanced-search-tabs"
                lazyRenderTabs={true}
            >
                {isProductModuleActive(ProductModuleEnum.REPORTS.name) &&
                    currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_GENERAL) && (
                        <Tab title={titles.reports} key={ElasticSearchTypeEnum.REPORT.name}>
                            <SearchCard key={0}>
                                <AdvancedSearchOpenSavedSearchesBar
                                    key={1}
                                    searchModule={advancedSearchReports}
                                />
                                <AdvancedSearchReportsForm key={2} />
                            </SearchCard>
                            <div
                                key={3}
                                className={advancedSearchReports.resultsContainerClassName}
                            >
                                {(hasReportsResults || reportsSearchErrorMessage.readOnlyText) && (
                                    <SearchCard
                                        key={4}
                                        footer={
                                            reportsSelectedRows.length > 0 ? (
                                                <SearchExportBar
                                                    key={5}
                                                    allResultsSelected={reportsAllResultsSelected}
                                                    selectedResultCount={reportsSelectedRows.length}
                                                    pageTotalResultCount={
                                                        reportsCurrentResults.length
                                                    }
                                                    totalResultCount={reportsTotalCount}
                                                    onClickExport={() =>
                                                        handleReportsClickExport(
                                                            reportsSelectedRows,
                                                            reportsAllResultsSelected
                                                        )
                                                    }
                                                    onClickSelectAll={handleReportsSelectAllRows}
                                                    onClickClearSelection={
                                                        handleReportsClearAllSelectedRows
                                                    }
                                                    exportDisabled={reportsExportDisabled}
                                                />
                                            ) : undefined
                                        }
                                    >
                                        {reportsSearchErrorMessage.readOnlyText && (
                                            <AdvancedSearchErrorBar
                                                key={6}
                                                onActionTextClick={handleErrorActionTextClick}
                                                message={reportsSearchErrorMessage}
                                            />
                                        )}
                                        <AdvancedSearchReportsResults
                                            key={7}
                                            savedSearches={reportsSavedSearches}
                                        />
                                    </SearchCard>
                                )}
                            </div>
                        </Tab>
                    )}
                <Tab
                    title={fieldDisplayNames.DISPLAY_ONLY_CAD_LABEL}
                    key={ElasticSearchTypeEnum.CAD_TICKET.name}
                >
                    <SearchCard key={0}>
                        <AdvancedSearchOpenSavedSearchesBar
                            key={1}
                            searchModule={advancedSearchCadTickets}
                        />
                        <AdvancedSearchCadTicketsForm key={2} />
                    </SearchCard>
                    <div key={3} className={advancedSearchCadTickets.resultsContainerClassName}>
                        {(hasCadTicketsResults || cadTicketsSearchErrorMessage.readOnlyText) && (
                            <SearchCard key={4}>
                                {cadTicketsSearchErrorMessage.readOnlyText && (
                                    <AdvancedSearchErrorBar
                                        key={5}
                                        onActionTextClick={handleErrorActionTextClick}
                                        message={cadTicketsSearchErrorMessage}
                                    />
                                )}
                                <AdvancedSearchCadTicketsResults
                                    key={6}
                                    savedSearches={cadSavedSearches}
                                />
                            </SearchCard>
                        )}
                    </div>
                </Tab>
                {isBookingEnabled && (
                    <Tab title={titles.bookings} key={EntityTypeEnum.BOOKING.name}>
                        <AdvancedSearchBookings />
                    </Tab>
                )}
                {currentUserHasAbility(abilitiesEnum.CORE.VIEW_GENERAL) && (
                    <Tab title={titles.persons} key={ElasticSearchTypeEnum.PERSON.name}>
                        <SearchCard key={0}>
                            <AdvancedSearchOpenSavedSearchesBar
                                key={1}
                                searchModule={advancedSearchPersons}
                            />
                            <AdvancedSearchPersonsForm key={2} />
                        </SearchCard>
                        <div key={3} className={advancedSearchPersons.resultsContainerClassName}>
                            {(hasPersonsResults || personsSearchErrorMessage.readOnlyText) && (
                                <SearchCard
                                    key={4}
                                    footer={
                                        currentUserHasAbility(abilitiesEnum.ADMIN.MERGE_PROFILES) &&
                                        personsSelectedRows.length > 0 ? (
                                            <SearchMergeEntityBar
                                                selectedResultCount={personsSelectedRows.length}
                                                selectedRowViewModels={map(
                                                    personsSelectedRows,
                                                    (i) => personsResults[i]
                                                )}
                                                currentUserDepartmentId={currentUserDepartmentId}
                                                consortiumDepartmentLinksAvailable={
                                                    consortiumDepartmentLinksAvailable
                                                }
                                                onMergeClick={() =>
                                                    handleMergeEntitiesClick(
                                                        PERSON_PROFILE.name,
                                                        map(
                                                            personsSelectedRows,
                                                            (i) => personsResults[i].id
                                                        )
                                                    )
                                                }
                                            />
                                        ) : undefined
                                    }
                                >
                                    {personsSearchErrorMessage.readOnlyText && (
                                        <AdvancedSearchErrorBar
                                            key={5}
                                            onActionTextClick={handleErrorActionTextClick}
                                            message={personsSearchErrorMessage}
                                        />
                                    )}
                                    <AdvancedSearchPersonsResults
                                        selectableRows={true}
                                        key={6}
                                        savedSearches={personsSavedSearches}
                                    />
                                </SearchCard>
                            )}
                        </div>
                    </Tab>
                )}
                {currentUserHasAbility(abilitiesEnum.CORE.VIEW_GENERAL) && (
                    <Tab
                        title={pluralize(fieldDisplayNames.DISPLAY_ONLY_ORGANIZATION_LABEL)}
                        key={ElasticSearchTypeEnum.ORGANIZATION.name}
                    >
                        <SearchCard key={0}>
                            <AdvancedSearchOpenSavedSearchesBar
                                key={1}
                                searchModule={advancedSearchOrganizations}
                            />
                            <AdvancedSearchOrganizationsForm key={2} />
                        </SearchCard>
                        <div
                            key={3}
                            className={advancedSearchOrganizations.resultsContainerClassName}
                        >
                            {(hasOrganizationsResults ||
                                organizationsSearchErrorMessage.readOnlyText) && (
                                <SearchCard
                                    key={4}
                                    footer={
                                        currentUserHasAbility(abilitiesEnum.ADMIN.MERGE_PROFILES) &&
                                        organizationsSelectedRows.length > 0 ? (
                                            <SearchMergeEntityBar
                                                selectedResultCount={
                                                    organizationsSelectedRows.length
                                                }
                                                selectedRowViewModels={map(
                                                    organizationsSelectedRows,
                                                    (i) => organizationsResults[i]
                                                )}
                                                currentUserDepartmentId={currentUserDepartmentId}
                                                onMergeClick={() =>
                                                    handleMergeEntitiesClick(
                                                        ORGANIZATION_PROFILE.name,
                                                        map(
                                                            organizationsSelectedRows,
                                                            (i) => organizationsResults[i].id
                                                        )
                                                    )
                                                }
                                            />
                                        ) : undefined
                                    }
                                >
                                    {organizationsSearchErrorMessage.readOnlyText && (
                                        <AdvancedSearchErrorBar
                                            key={5}
                                            onActionTextClick={handleErrorActionTextClick}
                                            message={organizationsSearchErrorMessage}
                                        />
                                    )}
                                    <AdvancedSearchOrganizationsResults
                                        selectableRows={true}
                                        key={6}
                                        savedSearches={organizationsSavedSearches}
                                    />
                                </SearchCard>
                            )}
                        </div>
                    </Tab>
                )}
                {isProductModuleActive(ProductModuleEnum.REPORTS.name) && (
                    <Tab title={titles.vehicles} key={ElasticSearchTypeEnum.VEHICLE.name}>
                        <SearchCard key={0}>
                            <AdvancedSearchOpenSavedSearchesBar
                                key={1}
                                searchModule={advancedSearchVehicles}
                            />
                            <AdvancedSearchVehiclesForm key={2} />
                        </SearchCard>
                        <FeatureFlagged
                            flag="RMS_VEHICLE_MERGING_ENABLED"
                            fallback={
                                <div
                                    key={3}
                                    className={advancedSearchVehicles.resultsContainerClassName}
                                >
                                    {(hasVehiclesResults ||
                                        vehiclesSearchErrorMessage.readOnlyText) && (
                                        <SearchCard key={4}>
                                            {vehiclesSearchErrorMessage.readOnlyText && (
                                                <AdvancedSearchErrorBar
                                                    key={5}
                                                    onActionTextClick={handleErrorActionTextClick}
                                                    message={vehiclesSearchErrorMessage}
                                                />
                                            )}
                                            <AdvancedSearchVehiclesResults
                                                key={6}
                                                savedSearches={vehiclesSavedSearches}
                                            />
                                        </SearchCard>
                                    )}
                                </div>
                            }
                        >
                            <div
                                key={3}
                                className={advancedSearchVehicles.resultsContainerClassName}
                            >
                                {(hasVehiclesResults ||
                                    vehiclesSearchErrorMessage.readOnlyText) && (
                                    <SearchCard
                                        key={4}
                                        footer={
                                            currentUserHasAbility(
                                                abilitiesEnum.ADMIN.MERGE_PROFILES
                                            ) && vehiclesSelectedRows.length > 0 ? (
                                                <SearchMergeEntityBar
                                                    selectedResultCount={
                                                        vehiclesSelectedRows.length
                                                    }
                                                    selectedRowViewModels={map(
                                                        vehiclesSelectedRows,
                                                        (i) => vehiclesResults[i]
                                                    )}
                                                    currentUserDepartmentId={
                                                        currentUserDepartmentId
                                                    }
                                                    onMergeClick={() =>
                                                        handleMergeEntitiesClick(
                                                            VEHICLE.name,
                                                            map(
                                                                vehiclesSelectedRows,
                                                                (i) => vehiclesResults[i].id
                                                            )
                                                        )
                                                    }
                                                />
                                            ) : undefined
                                        }
                                    >
                                        {vehiclesSearchErrorMessage.readOnlyText && (
                                            <AdvancedSearchErrorBar
                                                key={5}
                                                onActionTextClick={handleErrorActionTextClick}
                                                message={vehiclesSearchErrorMessage}
                                            />
                                        )}
                                        <AdvancedSearchVehiclesResults
                                            selectableRows={true}
                                            key={6}
                                            savedSearches={vehiclesSavedSearches}
                                        />
                                    </SearchCard>
                                )}
                            </div>
                        </FeatureFlagged>
                    </Tab>
                )}
                {isProductModuleActive(ProductModuleEnum.REPORTS.name) && (
                    <Tab title={titles.property} key={ElasticSearchTypeEnum.PROPERTY.name}>
                        <SearchCard key={0}>
                            <AdvancedSearchOpenSavedSearchesBar
                                key={1}
                                searchModule={advancedSearchProperty}
                            />
                            <AdvancedSearchPropertyForm key={2} />
                        </SearchCard>
                        <div key={3} className={advancedSearchProperty.resultsContainerClassName}>
                            {(hasPropertyResults || propertySearchErrorMessage.readOnlyText) && (
                                <SearchCard
                                    key={4}
                                    footer={
                                        propertySelectedRows.length > 0 ? (
                                            <SearchExportBar
                                                key={5}
                                                allResultsSelected={propertyAllResultsSelected}
                                                selectedResultCount={propertySelectedRows.length}
                                                pageTotalResultCount={propertyCurrentResults.length}
                                                totalResultCount={propertyTotalCount}
                                                onClickExport={() =>
                                                    handlePropertyClickExport(
                                                        propertySelectedRows,
                                                        propertyAllResultsSelected
                                                    )
                                                }
                                                onClickSelectAll={handlePropertySelectAllRows}
                                                onClickClearSelection={
                                                    handlePropertyClearAllSelectedRows
                                                }
                                                exportDisabled={propertyExportDisabled}
                                            />
                                        ) : undefined
                                    }
                                >
                                    {propertySearchErrorMessage.readOnlyText && (
                                        <AdvancedSearchErrorBar
                                            key={5}
                                            onActionTextClick={handleErrorActionTextClick}
                                            message={propertySearchErrorMessage}
                                        />
                                    )}
                                    <AdvancedSearchPropertyResults
                                        key={6}
                                        savedSearches={propertySavedSearches}
                                    />
                                </SearchCard>
                            )}
                        </div>
                    </Tab>
                )}
                {isProductModuleActive(ProductModuleEnum.REPORTS.name) && (
                    <Tab title={titles.attachments} key={ElasticSearchTypeEnum.ATTACHMENTS.name}>
                        <SearchCard key={0}>
                            <AdvancedSearchAttachmentsForm key={2} />
                        </SearchCard>
                        <div
                            key={3}
                            className={advancedSearchAttachments.resultsContainerClassName}
                        >
                            {(hasAttachmentsResults ||
                                attachmentsSearchErrorMessage.readOnlyText) && (
                                <SearchCard
                                    key={4}
                                    footer={
                                        attachmentsSelectedRows.length > 0 ? (
                                            <SearchExportBar
                                                allowSelectAll={false}
                                                key={5}
                                                allResultsSelected={attachmentsAllResultsSelected}
                                                selectedResultCount={attachmentsSelectedRows.length}
                                                pageTotalResultCount={
                                                    attachmentsCurrentResults.length
                                                }
                                                totalResultCount={reportsTotalCount}
                                                onClickExport={() =>
                                                    handleAttachmentsClickExport(
                                                        attachmentsSelectedRows,
                                                        attachmentsCurrentResults
                                                    )
                                                }
                                                onClickSelectAll={handleAttachmentsSelectAllRows}
                                                onClickClearSelection={
                                                    handleAttachmentsClearAllSelectedRows
                                                }
                                                exportDisabled={attachmentsExportDisabled}
                                            />
                                        ) : undefined
                                    }
                                >
                                    {attachmentsSearchErrorMessage.readOnlyText && (
                                        <AdvancedSearchErrorBar
                                            key={5}
                                            onActionTextClick={handleErrorActionTextClick}
                                            message={attachmentsSearchErrorMessage}
                                        />
                                    )}
                                    <AdvancedSearchAttachmentsResults key={6} />
                                </SearchCard>
                            )}
                        </div>
                    </Tab>
                )}
                {currentUserHasAbility(abilitiesEnum.REPORTING.VIEW_COURT_ORDERS) && (
                    <Tab title={titles.courtOrders} key={ElasticSearchTypeEnum.COURT_ORDERS.name}>
                        <SearchCard key={0}>
                            <AdvancedSearchCourtOrdersForm key={2} />
                        </SearchCard>
                        <div
                            key={3}
                            className={advancedSearchCourtOrders.resultsContainerClassName}
                        >
                            {(hasCourtOrdersResults ||
                                courtOrdersSearchErrorMessage.readOnlyText) && (
                                <SearchCard
                                    key={4}
                                    footer={
                                        courtOrdersSelectedRows.length > 0 ? (
                                            <SearchExportBar
                                                allowSelectAll={false}
                                                key={5}
                                                allResultsSelected={courtOrdersAllResultsSelected}
                                                selectedResultCount={courtOrdersSelectedRows.length}
                                                pageTotalResultCount={
                                                    courtOrdersCurrentResults.length
                                                }
                                                totalResultCount={courtOrdersTotalCount}
                                                onClickExport={() =>
                                                    handleCourtOrdersClickExport(
                                                        courtOrdersSelectedRows,
                                                        courtOrdersAllResultsSelected
                                                    )
                                                }
                                                onClickSelectAll={handleCourtOrdersSelectAllRows}
                                                onClickClearSelection={
                                                    handleCourtOrdersClearAllSelectedRows
                                                }
                                                exportDisabled={courtOrdersExportDisabled}
                                            />
                                        ) : undefined
                                    }
                                >
                                    {courtOrdersSearchErrorMessage.readOnlyText && (
                                        <AdvancedSearchErrorBar
                                            key={5}
                                            onActionTextClick={handleErrorActionTextClick}
                                            message={courtOrdersSearchErrorMessage}
                                        />
                                    )}
                                    <AdvancedSearchCourtOrdersResults key={6} />
                                </SearchCard>
                            )}
                        </div>
                    </Tab>
                )}
            </Tabs>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    // persons
    personsSearchErrorMessage: advancedSearchPersons.selectors.errorMessageSelector,
    hasPersonsResults: advancedSearchPersons.selectors.hasResultsSelector,
    personsSavedSearches: advancedSearchPersons.selectors.savedSearchesViewModelsSelector,
    personsSelectedRows: advancedSearchPersons.selectors.selectedRowsSelector,
    personsResults: advancedSearchPersons.selectors.currentResultsViewModelsSelector,

    // reports

    reportsSavedSearches: advancedSearchReports.selectors.savedSearchesViewModelsSelector,
    reportsSearchErrorMessage: advancedSearchReports.selectors.errorMessageSelector,
    hasReportsResults: advancedSearchReports.selectors.hasResultsSelector,
    reportsSelectedRows: advancedSearchReports.selectors.selectedRowsSelector,
    reportsTotalCount: advancedSearchReports.selectors.totalCountSelector,
    reportsCurrentResults: advancedSearchReports.selectors.currentResultsSelector,
    reportsAllResultsSelected: advancedSearchReports.selectors.allResultsSelectedSelector,
    reportsExportDisabled: advancedSearchReports.selectors.exportDisabledSelector,

    // cad tickets
    cadTicketsSearchErrorMessage: advancedSearchCadTickets.selectors.errorMessageSelector,
    hasCadTicketsResults: advancedSearchCadTickets.selectors.hasResultsSelector,
    cadSavedSearches: advancedSearchCadTickets.selectors.savedSearchesViewModelsSelector,

    // organizations
    organizationsSearchErrorMessage: advancedSearchOrganizations.selectors.errorMessageSelector,
    hasOrganizationsResults: advancedSearchOrganizations.selectors.hasResultsSelector,
    organizationsSavedSearches:
        advancedSearchOrganizations.selectors.savedSearchesViewModelsSelector,
    organizationsSelectedRows: advancedSearchOrganizations.selectors.selectedRowsSelector,
    organizationsResults: advancedSearchOrganizations.selectors.currentResultsViewModelsSelector,

    // vehicles
    vehiclesSearchErrorMessage: advancedSearchVehicles.selectors.errorMessageSelector,
    hasVehiclesResults: advancedSearchVehicles.selectors.hasResultsSelector,
    vehiclesSavedSearches: advancedSearchVehicles.selectors.savedSearchesViewModelsSelector,
    vehiclesSelectedRows: advancedSearchVehicles.selectors.selectedRowsSelector,
    vehiclesResults: advancedSearchVehicles.selectors.currentResultsViewModelsSelector,

    // property
    propertySearchErrorMessage: advancedSearchProperty.selectors.errorMessageSelector,
    hasPropertyResults: advancedSearchProperty.selectors.hasResultsSelector,
    propertySavedSearches: advancedSearchProperty.selectors.savedSearchesViewModelsSelector,
    propertySelectedRows: advancedSearchProperty.selectors.selectedRowsSelector,
    propertyTotalCount: advancedSearchProperty.selectors.totalCountSelector,
    propertyCurrentResults: advancedSearchProperty.selectors.currentResultsSelector,
    propertyAllResultsSelected: advancedSearchProperty.selectors.allResultsSelectedSelector,
    propertyExportDisabled: advancedSearchProperty.selectors.exportDisabledSelector,

    // attachments
    attachmentsSearchErrorMessage: advancedSearchAttachments.selectors.errorMessageSelector,
    hasAttachmentsResults: advancedSearchAttachments.selectors.hasResultsSelector,
    attachmentsSelectedRows: advancedSearchAttachments.selectors.selectedRowsSelector,
    attachmentsAllResultsSelected: advancedSearchAttachments.selectors.allResultsSelectedSelector,
    attachmentsCurrentResults: advancedSearchAttachments.selectors.currentResultsSelector,
    attachmentsExportDisabled: advancedSearchAttachments.selectors.exportDisabledSelector,

    // courtOrders
    courtOrdersSearchErrorMessage: advancedSearchCourtOrders.selectors.errorMessageSelector,
    hasCourtOrdersResults: advancedSearchCourtOrders.selectors.hasResultsSelector,
    courtOrdersSavedSearches: advancedSearchCourtOrders.selectors.savedSearchesViewModelsSelector,
    courtOrdersSelectedRows: advancedSearchCourtOrders.selectors.selectedRowsSelector,
    courtOrdersCurrentResults: advancedSearchCourtOrders.selectors.currentResultsSelector,
    courtOrdersAllResultsSelected: advancedSearchCourtOrders.selectors.allResultsSelectedSelector,
    courtOrdersTotalCount: advancedSearchCourtOrders.selectors.totalCountSelector,
    courtOrdersExportDisabled: advancedSearchCourtOrders.selectors.exportDisabledSelector,

    // other
    isProductModuleActive: isProductModuleActiveSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    // general
    navigateToTab(tabKey) {
        router.push(`/search${searchTabToPath[tabKey] ? '/' : ''}${searchTabToPath[tabKey]}`);
    },
    handleErrorActionTextClick() {
        scrollToTop();
    },

    // reports
    handleReportsClickExport() {
        dispatch(advancedSearchReports.actionCreators.loadSearchExportPrintables()).then(() =>
            router.push('/search/reports/exports')
        );
    },
    handleReportsSelectAllRows() {
        dispatch(advancedSearchReports.actionCreators.selectAllResults());
    },
    handleReportsClearAllSelectedRows() {
        dispatch(advancedSearchReports.actionCreators.selectRows([]));
    },

    // attachments
    handleAttachmentsClickExport(selectedIndex, allResults) {
        const selectedResult = allResults[selectedIndex];
        downloadSelectFile(selectedResult.file.fileWebServerPath, selectedResult.fileName);
    },
    handleAttachmentsSelectAllRows() {
        dispatch(advancedSearchAttachments.actionCreators.selectAllResults());
    },
    handleAttachmentsClearAllSelectedRows() {
        dispatch(advancedSearchAttachments.actionCreators.selectRows([]));
    },

    // property
    handlePropertyClickExport(propertySelectedRows, propertyAllResultsSelected) {
        dispatch(
            advancedSearchProperty.actionCreators.exportResults(
                propertySelectedRows,
                propertyAllResultsSelected
            )
        );
    },
    handlePropertySelectAllRows() {
        dispatch(advancedSearchProperty.actionCreators.selectAllResults());
    },
    handlePropertyClearAllSelectedRows() {
        dispatch(advancedSearchProperty.actionCreators.selectRows([]));
    },

    handleMergeEntitiesClick(entityType, entityIds) {
        const query = {
            entityType,
            entityIds,
        };
        router.push(`/merge-entities${clientStringifyQuery(query, { snakeCaseKeys: false })}`);
    },

    // court order
    handleCourtOrdersClickExport(courtOrderSelectedRows, courtOrderAllResultsSelected) {
        dispatch(
            advancedSearchCourtOrders.actionCreators.exportResults(
                courtOrderSelectedRows,
                courtOrderAllResultsSelected
            )
        );
    },
    handleCourtOrdersSelectAllRows() {
        dispatch(advancedSearchCourtOrders.actionCreators.selectAllResults());
    },
    handleCourtOrdersClearAllSelectedRows() {
        dispatch(advancedSearchCourtOrders.actionCreators.selectRows([]));
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withFields([DISPLAY_ONLY_CAD_LABEL, DISPLAY_ONLY_ORGANIZATION_LABEL])
)(AdvancedSearch);

import { get, includes } from 'lodash';

import type { Role, RoleTypeEnumType } from '@mark43/rms-api';
import { RoleTypeEnum } from '@mark43/rms-api';

/**
 * Given a roleById selector, returns a function that expects an item
 * in the context of the Master Entity / Warrant permission admin pages that
 * returns if the role that is not a department
 */
export const isRoleTypeNotDepartment = (roleById: (id: number) => Role) => (item: Role) => {
    const roleId = get(item, 'roleId');
    return get(roleById(roleId), 'roleType') !== 'DEPARTMENT';
};

/**
 * Each tenant has exactly 1 super user role and 1 integration role.
 *
 * For the users in these roles who have API tokens that are used in integrations, they must remain active (not be
 * disabled) and remain in their role for those integrations to continue working.
 */
export function roleTypeIsIntegrationOrSuperUser(roleType: RoleTypeEnumType): boolean {
    return includes([RoleTypeEnum.INTEGRATION.name, RoleTypeEnum.SUPER_USER.name], roleType);
}

import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import classNames from 'classnames';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { boxUiSelector } from '../../selectors/boxSelectors';
import Icon, { iconTypes } from '../../../modules/core/components/Icon';
import { closeLightbox } from '../../actions/lightboxActions';
import { renderOnlyIf } from '../../helpers/reactHelpers';
import testIds from '../../../core/testIds';
import Button, { buttonTypes } from './Button';

// compute the `style` prop for the react-modal component
function computeStyle(props) {
    return {
        overlay: {
            ...Lightbox.defaultProps.overlayStyle,
            ...props.overlayStyle,
        },
        content: {
            ...Lightbox.defaultProps.modalStyle,
        },
    };
}

const CancelButton = styled(Button)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

/**
 * Use this component to add an overlay with a cancel button in the corner
 * @param {Object} context context for the lightbox
 * @param {Object} props.children Component to render in the lightbox
 */
function Lightbox(props) {
    const { isOpen } = props.ui[props.context.name];

    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;

    return (
        <ReactModal
            className="mark43-lightbox"
            overlayClassName={classNames('mark43-modal-overlay', {
                // This can be removed when the RMS_ARC_NAVIGATION_ENABLED feature flag is torn down
                'no-header': isMainNavEnabled,
            })}
            onRequestClose={props.closeLightbox}
            isOpen={isOpen}
            {...props}
            style={computeStyle(props)}
        >
            <div className="lightbox-cancel">
                <CancelButton
                    className={buttonTypes.ICON_LINK}
                    onClick={props.closeLightbox}
                    iconLeft={<Icon type={iconTypes.CLOSE_X} color="mediumGrey" size={40} />}
                    testId={testIds.LIGHTBOX_CLOSE_BUTTON}
                />
            </div>
            {props.children}
        </ReactModal>
    );
}

Lightbox.propTypes = {
    overlayStyle: PropTypes.object,
    modalStyle: PropTypes.object,
};

Lightbox.defaultProps = {
    overlayStyle: {
        // null values to override react-modal defaults
        position: 'fixed',
        zIndex: 'var(--arc-zIndices-modal)',
        top: 0,
        left: 0,
        right: null,
        bottom: null,
        backgroundColor: 'rgba(26,28,36,0.95)',
    },
    modalStyle: {
        position: null,
        right: null, // `top` and `left` are always calculated, hence omitted
        bottom: null,
        border: null,
        background: null,
        overflow: null,
        WebkitOverflowScrolling: null,
        borderRadius: null,
        outline: 0,
        padding: null,
    },
};

const mapDispatchToProps = (dispatch, props) => ({
    closeLightbox: () => {
        if (props.onClose) {
            props.onClose();
        } else {
            // if the prop isn't provided, just close the panel
            dispatch(closeLightbox(props.context));
        }
    },
});

const mapStateToProps = createStructuredSelector({
    ui: boxUiSelector,
});

export default flowRight(
    connect(mapStateToProps, mapDispatchToProps),
    renderOnlyIf(({ context, ui }) => ui[context.name].isOpen)
)(Lightbox);

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { buildFlatFormFieldViewModels } from '~/client-common/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';

import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'offenseCodeChangeReason',
        validators: {
            requiredError: requiredString,
        },
    },
]);

// @ts-expect-error client-common to client RND-7529
const offenseCodeChangeReasonForm = createFormModule({
    formName: formClientEnum.OFFENSE_CODE_CHANGE_REASON,
    fieldViewModels,
});

/**
 * Module of the report rejection text field form
 *
 */
export default offenseCodeChangeReasonForm;

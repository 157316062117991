import { first, map } from 'lodash';
import { createSelector } from 'reselect';
import { towVehicleCheckInsWhereSelector } from '../data';

// helpers
import { buildViewModel, boolToDisplayMapper } from '../../../../../helpers/viewModelHelpers';

const buildTowVehicleCheckInViewModel = (towVehicleCheckIn) => {
    const viewModel = buildViewModel({
        recursive: true,
        mappers: [boolToDisplayMapper],
    });

    return viewModel(towVehicleCheckIn);
};

const towVehicleCheckInViewModelsWhereSelector = createSelector(
    towVehicleCheckInsWhereSelector,
    (towVehicleCheckInsWhere) => (predicate) =>
        map(towVehicleCheckInsWhere(predicate), buildTowVehicleCheckInViewModel)
);

export const towVehicleCheckInViewModelByReportIdSelector = createSelector(
    towVehicleCheckInViewModelsWhereSelector,
    (towVehicleCheckInViewModelsWhere) => (reportId) =>
        first(towVehicleCheckInViewModelsWhere({ reportId }))
);

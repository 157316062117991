import React from 'react';
import { compact, map } from 'lodash';
import { Motion, spring } from 'react-motion';
import { profileHeaderSpringParameters } from '../../configuration';
import { withContentScrollHandler } from '../../../../../legacy-redux/helpers/reactHelpers';
import testIds from '../../../../../core/testIds';

const HEADER_COLLAPSE_SCROLL_TOP = 45;

function EntityProfileHeader({ children, scrollValue }) {
    const collapsed = scrollValue - HEADER_COLLAPSE_SCROLL_TOP > 0;
    const mappedChildren = map(compact(React.Children.toArray(children)), (child) =>
        React.cloneElement(child, { collapsed })
    );
    return (
        <Motion style={{ padding: spring(collapsed ? 11 : 22, profileHeaderSpringParameters) }}>
            {({ padding }) => (
                <div
                    style={{
                        paddingTop: padding,
                        paddingBottom: padding,
                        borderBottom: collapsed ? '1px solid #f1f1f1' : 0,
                    }}
                    className="entity-profile-header"
                    data-test-id={testIds.MASTER_PROFILE_HEADER}
                >
                    {mappedChildren}
                </div>
            )}
        </Motion>
    );
}

export default withContentScrollHandler(EntityProfileHeader);

import { EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import _, { compact, forEach, get, isEmpty, map, pickBy, reject, uniq, first } from 'lodash';
import { createSelector } from 'reselect';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data/';
import {
    statusIsClosedSelector,
    caseStatusGlobalAttrIdToChildAttrIdsSelector,
} from '~/client-common/core/domain/case-statuses/state/ui';

import {
    replaceCasesWhere,
    NEXUS_STATE_PROP as CASES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/cases/state/data';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { replaceCaseRoleLinksWhere } from '~/client-common/core/domain/case-role-links/state/data';
import { NEXUS_STATE_PROP as CASE_ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-attributes/state/data';
import { NEXUS_STATE_PROP as CASE_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-statuses/state/data';
import { NEXUS_STATE_PROP as REPORT_CASE_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-case-statuses/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { NEXUS_STATE_PROP as entityPermissionsNexusStateProp } from '~/client-common/core/domain/entity-permissions/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { externalReportStatusForReportingEventIdSelector } from '~/client-common/core/domain/external-report-statuses/state/data';
import { offenseCaseStatusesSelector } from '~/client-common/core/domain/offense-case-statuses/state/data';
import { reportShortTitlesSelector } from '~/client-common/core/domain/report-short-titles/state/data';
import { saveExternalReportStatus } from '../../../../core/external-report-statuses/state/data/externalReportStatuses';
import { convertFromExternalReportStatusFormModel } from '../../../../core/external-report-statuses/state/form/externalReportStatusForm';
import { saveOffenseCaseStatuses } from '../../../../core/offense-case-statuses/state/data/offenseCaseStatuses';
import { convertFromOffenseCaseStatusesFormModel } from '../../../../core/offense-case-statuses/state/form/offenseCaseStatusesForm';
import { scrollToElement } from '../../../../../legacy-redux/helpers/navigationHelpers';
import { scrollToTop } from '../../../../core/components/ScrollableUnderSubheader';
import { createCheckboxPath } from '../../../../core/forms/components/checkboxes/CheckboxedField';
import { loadPermissions, getFormNameForEntityType } from '../../../../core/permissions/state/ui';
import {
    filterRecordWithoutRenIds,
    filterRecordWithRen,
} from '../../../../reports/core/utils/isRecordWithoutRen';
import { casesBatchPolling } from '../../../batch-operations/state/ui';
import {
    removeUnassignedReportsSearchResult,
    submitWillNotInvestigateCommentForm,
} from '../../../unassigned-reports/state/ui';
import { augmentAndUpdatePermissions } from '../../../../../legacy-redux/actions/entityPermissionsActions';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { myCasesSearch } from '../../../my-cases/state/ui';
import { allCasesSearch, resetAllCasesSearch } from '../../../all-cases/state/ui';
import {
    saveBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
    closeBox,
} from '../../../../../legacy-redux/actions/boxActions';
import entityPermissionsResource from '../../../../../legacy-redux/resources/entityPermissionsResource';
import buildCaseCreationBatchItemRequests from '../../utils/buildCaseCreationBatchItemRequests';
import caseResource from '../../resources/caseResource';
import caseDetailsForm from '../form/caseDetailsForm';
import {
    convertCreateManageCaseFromFormModel,
    getCaseFormFields,
} from '../form/createManageCaseForm';
import {
    assigneeCaseRoleLinkSelector,
    closeCreateManageCaseModal,
    currentCaseEntityPermissionsSelector,
    currentCaseIdSelector,
    currentCaseRoleLinksSelector,
    currentCaseUiSelector,
    defaultEntityPermissionsSelector,
    isNewCaseSelector,
    storeCaseRelatedModels,
} from '../ui';
import { resetReasonForRelationForm } from '../form/reasonForRelationForm';
import caseReportLinksResource from '../../resources/caseReportLinksResource';
import { resetReasonForRemovalForm } from '../form/reasonForRemovalForm';
import { caseOnEnter } from '../../..';

export * from './caseHistory';

const MODAL_FORM = 'form';
const MODAL_CONTENT_CLASS = 'modal-content';
const MODAL_SCROLL_OFFSET = 250;

function legacyCreateCase(caseCreationRequest, router) {
    return (dispatch) => {
        const caseDetailsBoxContext = { name: boxEnum.CASE_DETAILS_MODAL };
        dispatch(saveBoxStart(caseDetailsBoxContext));
        return caseResource
            .createCase(caseCreationRequest)
            .then((caseView) => {
                dispatch(storeCaseRelatedModels(caseView));
                forEach(map(caseView.reports, 'id'), (reportId) =>
                    dispatch(removeUnassignedReportsSearchResult(reportId))
                );
                dispatch(resetAllCasesSearch());
                dispatch(closeBox(caseDetailsBoxContext));
                const caseId = caseView.cases[0].id;
                router.push(`/cases/${caseId}`);
            })
            .catch((err) => {
                dispatch(saveBoxFailure(caseDetailsBoxContext, err.message));
            });
    };
}

function createCase(caseCreationRequest, router) {
    return (dispatch, getStore, { overlayStore }) => {
        overlayStore.setLoading(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, true);
        return caseResource
            .createCase(caseCreationRequest)
            .then((caseView) => {
                dispatch(storeCaseRelatedModels(caseView));
                forEach(map(caseView.reports, 'id'), (reportId) =>
                    dispatch(removeUnassignedReportsSearchResult(reportId))
                );
                dispatch(resetAllCasesSearch());
                dispatch(closeCreateManageCaseModal());
                const caseId = caseView.cases[0].id;
                router.push(`/cases/${caseId}`);
            })
            .catch((err) => {
                overlayStore.setError(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, err.message);
                overlayStore.setCustomProperties(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, {
                    isSaving: false,
                });
            });
    };
}

function moveToNewCase(caseMoveRequest, router) {
    return (dispatch, getStore, { overlayStore }) => {
        overlayStore.setLoading(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, true);
        return caseReportLinksResource
            .removeFromCurrentCaseAndCreateNewCase(caseMoveRequest)
            .then((newCaseId) => {
                dispatch(closeCreateManageCaseModal());
                router.push(`/cases/${newCaseId}`);
                resetReasonForRemovalForm();
            })
            .catch((err) => {
                overlayStore.setError(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, err.message);
                overlayStore.setCustomProperties(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, {
                    isSaving: false,
                });
            });
    };
}

export function removeFromCase(caseRemovalRequest) {
    return (dispatch, getStore, { overlayStore }) => {
        const { caseId, requestPromise } = getRemoveFromCaseRequestPromise(caseRemovalRequest);
        overlayStore.setLoading(overlayIdEnum.REASON_FOR_REMOVAL_MODAL, true);
        return requestPromise
            .then(() => {
                overlayStore.close(overlayIdEnum.REASON_FOR_REMOVAL_MODAL);
                resetReasonForRemovalForm();
            })
            .catch((err) => {
                overlayStore.setError(overlayIdEnum.REASON_FOR_REMOVAL_MODAL, err.message);
                overlayStore.setCustomProperties(overlayIdEnum.REASON_FOR_REMOVAL_MODAL, {
                    isSaving: false,
                });
            })
            .finally(() => {
                dispatch((dispatch, getState) => {
                    caseOnEnter.call(
                        { dispatch, getState },
                        {
                            params: {
                                caseId,
                            },
                        }
                    );
                });
            });
    };
}

const getRemoveFromCaseRequestPromise = (caseRemovalRequest) => {
    const caseRemovalRequests = [caseRemovalRequest].flat();
    const caseId = caseRemovalRequests[0].caseId;
    const promises = caseRemovalRequests.map((req) => caseReportLinksResource.removeFromCase(req));
    const requestPromise = promises.length === 1 ? promises[0] : Promise.all(promises);

    return {
        caseId,
        requestPromise,
    };
};

export function removeFromCaseAndMarkWillNotInvestigate(caseRemovalRequest, reportIds) {
    return (dispatch, getStore, { overlayStore }) => {
        const { caseId, requestPromise } = getRemoveFromCaseRequestPromise(caseRemovalRequest);
        return requestPromise
            .then(() => {
                return dispatch(submitWillNotInvestigateCommentForm(reportIds));
            })
            .catch((err) => {
                overlayStore.setError(overlayIdEnum.WILL_NOT_INVESTIGATE_MODAL, err.message);
            })
            .finally(() => {
                dispatch((dispatch, getState) => {
                    caseOnEnter.call(
                        { dispatch, getState },
                        {
                            params: {
                                caseId,
                            },
                        }
                    );
                });
            });
    };
}

export function loadCases() {
    return function (dispatch, getState, { nexus }) {
        return caseResource.getCasesForDepartment().then((cases) =>
            dispatch(
                nexus.withEntityItems(
                    {
                        [CASES_NEXUS_STATE_PROP]: cases,
                    },
                    { type: 'LOAD_CASES_SUCCESS', payload: cases }
                )
            )
        );
    };
}

function legacySaveCaseDetails(caseDetails, options = { isNewCase: false }, router) {
    return (dispatch, getState, dependencies) => {
        if (options.isNewCase) {
            return dispatch(
                legacyCreateCase(convertCaseDetailsToCaseCreationRequest(caseDetails), router)
            );
        }

        const { c, caseRoleLinks, entityPermissions, caseStatus, reportCaseStatuses } = caseDetails;

        const caseDetailsBoxContext = { name: boxEnum.CASE_DETAILS_MODAL };
        const caseId = c.id;

        const updateCase = caseResource
            .updateCase(caseId, c)
            .then((updatedCase) => dispatch(replaceCasesWhere({ id: caseId }, updatedCase)));
        const SEND_EMAILS = true;
        const setRoleLinks = caseResource
            .setRoleLinks(caseId, caseRoleLinks, SEND_EMAILS)
            .then((caseRoleLinks) =>
                dispatch(replaceCaseRoleLinksWhere({ caseId }, caseRoleLinks))
            );
        const updateEntityPermissions = entityPermissionsResource
            .updatePermissions(EntityTypeEnum.CASE.name, caseId, entityPermissions)
            .then((entityPermissions) => dispatch(augmentAndUpdatePermissions(entityPermissions)));

        const state = getState();
        const userHasEditCaseStatusAbility = currentUserHasAbilitySelector(state)(
            abilitiesEnum.CASES.EDIT_STATUSES
        );
        let updateCaseStatus;
        if (userHasEditCaseStatusAbility && caseStatus) {
            updateCaseStatus = caseResource
                .updateCaseStatus(caseId, caseStatus)
                .then((caseStatus) => {
                    dispatch(
                        allCasesSearch.actionCreators.patchResultsWhere(
                            { currentStatusAttrId: caseStatus.statusAttrId },
                            { id: caseId }
                        )
                    );
                    dispatch(
                        myCasesSearch.actionCreators.patchResultsWhere(
                            { currentStatusAttrId: caseStatus.statusAttrId },
                            { id: caseId }
                        )
                    );
                    return dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [CASE_STATUSES_NEXUS_STATE_PROP]: [caseStatus],
                            },
                            { type: 'UPDATE_CASE_STATUS' }
                        )
                    );
                });
        }

        const userHasEditReportCaseStatusAbility = currentUserHasAbilitySelector(state)(
            abilitiesEnum.REPORTING.EDIT_CASE_STATUS
        );
        let updateReportCaseStatuses;
        if (userHasEditReportCaseStatusAbility && reportCaseStatuses.length > 0) {
            updateReportCaseStatuses = getReportResource()
                .saveCaseStatuses(reportCaseStatuses)
                .then((reportCaseStatuses) =>
                    dispatch(
                        dependencies.nexus.withEntityItems(
                            {
                                [REPORT_CASE_STATUSES_NEXUS_STATE_PROP]: reportCaseStatuses,
                            },
                            { type: 'UPDATE_REPORT_CASE_STATUS' }
                        )
                    )
                );
        }
        const resources = compact([
            updateCase,
            setRoleLinks,
            updateEntityPermissions,
            updateCaseStatus,
            updateReportCaseStatuses,
        ]);
        return Promise.all(resources)
            .then(() => {
                dispatch(saveBoxSuccess(caseDetailsBoxContext));
                dispatch(closeBox(caseDetailsBoxContext));
            })
            .catch((err) => {
                dispatch(saveBoxFailure(caseDetailsBoxContext, err.message));
            });
    };
}

function saveCaseDetails(caseDetails, options = { isNewCase: false, removalRequest: {} }, router) {
    return (dispatch, getState, { nexus, overlayStore }) => {
        if (options.isNewCase && isEmpty(options.removalRequest)) {
            return dispatch(
                createCase(convertCaseDetailsToCaseCreationRequest(caseDetails), router)
            );
        }
        if (!isEmpty(options.removalRequest)) {
            return dispatch(
                moveToNewCase(
                    {
                        caseCreationRequest: convertCaseDetailsToCaseCreationRequest(caseDetails),
                        caseReportLinkRemovalRequest: options.removalRequest,
                    },
                    router
                )
            );
        }
        const {
            c,
            caseRoleLinks,
            entityPermissions,
            caseStatus,
            reportCaseStatuses,
            caseAttributes,
            nameCaseLinks,
            itemCaseLinks,
        } = caseDetails;

        const caseId = c.id;
        const SEND_EMAILS = true;

        const caseUpdateRequest = {
            theCase: c,
            caseStatus,
            caseRoleLinks,
            entityPermissions,
            reportCaseStatuses,
            caseAttributes,
            nameCaseLinks,
            itemCaseLinks,
            sendEmails: SEND_EMAILS,
        };
        return caseResource
            .updateCaseBundle(caseId, caseUpdateRequest)
            .then((caseUpdateResponse) => {
                dispatch(replaceCasesWhere({ id: caseId }, caseUpdateResponse.theCase));
                dispatch(replaceCaseRoleLinksWhere({ caseId }, caseUpdateResponse.caseRoleLinks));
                dispatch(augmentAndUpdatePermissions(entityPermissions));
                const updatedCaseAttributes = caseUpdateResponse.caseAttributes;
                const updatedCaseStatus = caseUpdateResponse.caseStatus;
                const updatedReportCaseStatuses = caseUpdateResponse.reportCaseStatuses;
                dispatch(
                    allCasesSearch.actionCreators.patchResultsWhere(
                        { currentStatusAttrId: updatedCaseStatus.statusAttrId },
                        { id: caseId }
                    )
                );
                dispatch(
                    myCasesSearch.actionCreators.patchResultsWhere(
                        { currentStatusAttrId: updatedCaseStatus.statusAttrId },
                        { id: caseId }
                    )
                );
                dispatch(
                    nexus.withEntityItems(
                        {
                            [CASE_STATUSES_NEXUS_STATE_PROP]: [updatedCaseStatus],
                            [REPORT_CASE_STATUSES_NEXUS_STATE_PROP]: updatedReportCaseStatuses,
                        },
                        { type: 'UPDATE_CASE_STATUS' }
                    )
                );
                dispatch(
                    nexus.withEntityItems(
                        {
                            [CASE_ATTRIBUTES_NEXUS_STATE_PROP]: updatedCaseAttributes,
                        },
                        nexus.withRemove(
                            CASE_ATTRIBUTES_NEXUS_STATE_PROP,
                            {},
                            { type: 'UPDATE_CASE_ATTRIBUTES' }
                        )
                    )
                );
                resetReasonForRelationForm(RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name);
                dispatch(closeCreateManageCaseModal());
            })
            .catch((err) => {
                overlayStore.setError(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, err.message);
                overlayStore.setCustomProperties(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, {
                    isSaving: false,
                });
            });
    };
}

export function submitBulkManageCasesSidePanel(caseIds) {
    return (dispatch, getState, { overlayStore, formsRegistry }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_BULK_MANAGE_CASES_SIDE_PANEL.name);
        return form
            .submit()
            .then((result) => {
                const formData = result.form.get();
                const requiredData = pickBy(
                    formData,
                    (val, key) => !!formData[createCheckboxPath(key)]
                );
                if (isEmpty(requiredData)) {
                    // Saving should act the same as cancel in this case
                    return false;
                }
                overlayStore.setCustomProperties(overlayIdEnum.BULK_MANAGE_CASES_SIDEPANEL, {
                    isSaving: true,
                });
                return caseResource.batchUpdateCases(caseIds, requiredData);
            })
            .then((success) => {
                form.resetModel();
                form.resetUi();
                overlayStore.close(overlayIdEnum.BULK_MANAGE_CASES_SIDEPANEL);
                if (success) {
                    dispatch(casesBatchPolling());
                }
                scrollToTop();
            })
            .catch((e) => {
                const errorMessage = e.validationResult ? e.validationResult.formErrors : e.message;
                overlayStore.setError(overlayIdEnum.BULK_MANAGE_CASES_SIDEPANEL, errorMessage);
                overlayStore.setCustomProperties(overlayIdEnum.BULK_MANAGE_CASES_SIDEPANEL, {
                    isSaving: false,
                });
            });
    };
}

export function submitCreateManageCaseForm({
    router,
    removalRequest,
    nameCaseLinks,
    itemCaseLinks,
}) {
    return (dispatch, getState, { formsRegistry, overlayStore }) => {
        const state = getState();
        const attributes = attributesSelector(state);
        const RMS_CLEARANCE_IMPROVEMENTS_ENABLED = applicationSettingsSelector(state)
            .RMS_CLEARANCE_IMPROVEMENTS_ENABLED;
        const assigneeCaseRoleLink = assigneeCaseRoleLinkSelector(state);
        const isNewCase = isNewCaseSelector(state);
        const reportIdsForCaseCreation = reportIdsForCaseCreationSelector(state);
        const reportShortTitles = reportShortTitlesSelector(state);
        const currentUserHasAbility = currentUserHasAbilitySelector(state);
        const entityPermissions = isNewCase
            ? defaultEntityPermissionsSelector(state)
            : currentCaseEntityPermissionsSelector(state);
        const caseRoleLinkRoleIds = map(currentCaseRoleLinksSelector(state), 'roleId');
        const userHasEditExternalReportStatusAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.EDIT_EXTERNAL_REPORT_STATUS
        );
        const userHasEditReportCaseStatusAbility = currentUserHasAbility(
            abilitiesEnum.REPORTING.EDIT_CASE_STATUS
        );
        const applicationSettings = applicationSettingsSelector(state);

        const caseEnhancementsEnabled = !!applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE;
        const individualReportSelectionEnabled = !!applicationSettings.RMS_INDIVIDUAL_REPORT_SELECTION_ENABLED;

        // before we update with the new form data, we only want the entity permission of other personnel,
        // ie, entity permissions of people who aren't assignees or supervisors, because assignees and supervisors may have changed
        const otherPersonnelEntityPermissions = reject(entityPermissions, (entityPermission) =>
            _.includes(caseRoleLinkRoleIds, entityPermission.roleId)
        );

        const form = formsRegistry.get(RefContextEnum.FORM_CREATE_MANAGE_CASE.name);
        const reasonForRelationForm = formsRegistry.get(
            RefContextEnum.FORM_REASON_FOR_RELATION_MODAL.name
        );

        return form
            .submit()
            .then((result) => {
                const formData = result.form.getState().model;

                // FF check for caseEnhancementsEnabled isn't done because the form will always be undefined if the FF isn't on
                const reasonForRelationFormModel = reasonForRelationForm?.getState().model;

                const caseStatusIsClosed = statusIsClosedSelector(state)(
                    get(formData, 'caseStatus.statusAttrId')
                );
                const reportingEventId = get(formData, 'externalReportStatus.reportingEventId');
                const externalReportStatus = externalReportStatusForReportingEventIdSelector(state)(
                    reportingEventId
                );
                const offenseCaseStatuses = offenseCaseStatusesSelector(state);

                const reportStatusesAreClosed = _(get(formData, 'reportCaseStatuses'))
                    .compact()
                    .mapKeys(({ id }) => id)
                    .mapValues(({ caseStatusAttrId }) =>
                        statusIsClosedSelector(state)(caseStatusAttrId)
                    )
                    .value();

                const groupedStatuses = caseStatusGlobalAttrIdToChildAttrIdsSelector(state);
                const defaultCanceledCaseStatusAttribute = first(
                    groupedStatuses[globalAttributes.caseStatus.canceled]
                );
                /**
                 * When case enhancements is enabled the record without ren (RWR) report Ids and RENs are required
                 * to make a report case link for Case Creation.
                 */
                const recordWithoutRenReportIds = caseEnhancementsEnabled
                    ? filterRecordWithoutRenIds(reportIdsForCaseCreation, reportShortTitles)
                    : [];

                const reportingEventNumbers = caseEnhancementsEnabled
                    ? uniq(
                          compact([
                              get(formData, 'reportingEventNumber'),
                              ...filterRecordWithRen(reportIdsForCaseCreation, reportShortTitles),
                          ])
                      )
                    : [];

                const caseDataForSubmission = {
                    ...convertCreateManageCaseFromFormModel(formData, {
                        reasonForRelationFormModel,
                        recordWithoutRenReportIds,
                        reportingEventNumbers,
                        assigneeCaseRoleLinkId: get(assigneeCaseRoleLink, 'id'),
                        assigneeRoleId: get(assigneeCaseRoleLink, 'roleId'),
                        caseStatusIsClosed,
                        defaultCanceledCaseStatusAttribute,
                        reportStatusesAreClosed,
                        otherPersonnelEntityPermissions,
                        attributes,
                        individualReportSelectionEnabled,
                    }),
                    reportIds: isNewCase ? reportIdsForCaseCreation : undefined,
                    nameCaseLinks,
                    itemCaseLinks,
                };

                // If there is no `reportingEventId`, it means that we are creating
                // a case for a new REN, and we cannot set the external status
                // for this scenario
                const shouldSaveExternalReportStatus =
                    RMS_CLEARANCE_IMPROVEMENTS_ENABLED &&
                    userHasEditExternalReportStatusAbility &&
                    reportingEventId;

                const shouldSaveOffenseCaseStatuses =
                    userHasEditReportCaseStatusAbility && reportingEventId;

                const formDataOffenseCaseStatuses = map(
                    formData.offenseCaseStatuses,
                    (caseStatus) => {
                        if (!caseStatus.caseStatusAttrId) {
                            return {
                                ...caseStatus,
                                closedByDivisionAttrId: null,
                                closedByUnitAttrId: null,
                                statusDateUtc: null,
                            };
                        }
                        return caseStatus;
                    }
                );

                return Promise.all([
                    dispatch(
                        saveCaseDetails(
                            caseDataForSubmission,
                            { isNewCase, removalRequest },
                            router
                        )
                    ),
                    ...(shouldSaveExternalReportStatus
                        ? [
                              dispatch(
                                  saveExternalReportStatus(
                                      convertFromExternalReportStatusFormModel(
                                          formData.externalReportStatus,
                                          externalReportStatus
                                      )
                                  )
                              ),
                          ]
                        : []),
                    ...(shouldSaveOffenseCaseStatuses
                        ? [
                              dispatch(
                                  saveOffenseCaseStatuses(
                                      reportingEventId,
                                      convertFromOffenseCaseStatusesFormModel(
                                          formDataOffenseCaseStatuses,
                                          offenseCaseStatuses
                                      )
                                  )
                              ),
                          ]
                        : []),
                ]);
            })
            .catch((e) => {
                handleCreateManageCaseFormError(e, overlayStore);
            });
    };
}

/**
 * Submit case details from the CaseDetailsModal. This may either be to create a new case
 * or to save an existing one.
 * @method submitCaseDetails
 * @return {[type]}          [description]
 */
export function submitCaseDetails(router) {
    return (dispatch, getState, { formsRegistry, overlayStore }) => {
        const casePermissionsOverlayState = overlayStore.getStateForId(
            overlayIdEnum.CASE_PERMISSIONS_OVERLAY
        );
        const state = getState();
        const assigneeCaseRoleLink = assigneeCaseRoleLinkSelector(state);
        const isNewCase = isNewCaseSelector(state);
        const currentCaseId = currentCaseIdSelector(state);
        const reportIdsForCaseCreation = reportIdsForCaseCreationSelector(state);
        const entityPermissions = isNewCase
            ? defaultEntityPermissionsSelector(state)
            : currentCaseEntityPermissionsSelector(state);
        const caseRoleLinkRoleIds = map(currentCaseRoleLinksSelector(state), 'roleId');

        // before we update with the new form data, we only want the entity permission of other personnel,
        // ie, entity permissions of people who aren't assignees or supervisors, because assignees and supervisors may have changed
        const otherPersonnelEntityPermissions = reject(entityPermissions, (entityPermission) =>
            _.includes(caseRoleLinkRoleIds, entityPermission.roleId)
        );

        return dispatch(
            caseDetailsForm.actionCreators.submit((formData) => {
                const caseStatusIsClosed = statusIsClosedSelector(state)(
                    get(formData, 'caseStatus.statusAttrId')
                );
                const reportStatusesAreClosed = _(get(formData, 'caseStatus.reportCaseStatuses'))
                    .compact()
                    .mapKeys(({ id }) => id)
                    .mapValues(({ caseStatusAttrId }) =>
                        statusIsClosedSelector(state)(caseStatusAttrId)
                    )
                    .value();

                const caseDataForSubmission = {
                    ...caseDetailsForm.convertFromFormModel(formData, {
                        assigneeCaseRoleLinkId: get(assigneeCaseRoleLink, 'id'),
                        assigneeRoleId: get(assigneeCaseRoleLink, 'roleId'),
                        caseStatusIsClosed,
                        reportStatusesAreClosed,
                        otherPersonnelEntityPermissions,
                    }),
                    reportIds: isNewCase ? reportIdsForCaseCreation : undefined,
                };

                return dispatch(
                    legacySaveCaseDetails(caseDataForSubmission, { isNewCase }, router)
                ).then(() => {
                    // this ensures that the case permissions modal is "refreshed" in a case where
                    // it is open "under" the case details modal
                    // this is necessary because case supervisors may be edited on the case details modal
                    // causing state of the case permissions modal to be out of date
                    const casePermissionsForm = formsRegistry.get(
                        getFormNameForEntityType(EntityTypeEnum.CASE.name)
                    );

                    if (
                        casePermissionsForm &&
                        casePermissionsOverlayState &&
                        casePermissionsOverlayState.isOpen
                    ) {
                        dispatch(
                            loadPermissions(
                                overlayIdEnum.CASE_PERMISSIONS_OVERLAY,
                                EntityTypeEnum.CASE.name,
                                currentCaseId
                            )
                        );
                    }
                });
            })
        ).catch((errors) => {
            scrollToElement({
                selector: `${MODAL_FORM}`,
                wrapperSelector: `.${MODAL_CONTENT_CLASS}`,
                offset: MODAL_SCROLL_OFFSET,
            });
            dispatch(saveBoxFailure({ name: boxEnum.CASE_DETAILS_MODAL }, errors));
        });
    };
}

export function submitCreateManageCaseFormForBulk({
    reportingEventNumbers,
    recordWithoutRenReportIds,
    router,
}) {
    return (dispatch, getState, { formsRegistry, overlayStore }) => {
        const form = formsRegistry.get(RefContextEnum.FORM_CREATE_MANAGE_CASE.name);
        return form
            .submit()
            .then((result) => {
                const formData = result.form.getState().model;
                const theCase = {
                    ...getCaseFormFields(formData),
                    assignedDateUtc: nowUtc(),
                };
                const caseStatus = formData.caseStatus;
                const assigneeRoleId = formData.assignee;
                const assistingInvestigatorRoleIds = uniq(formData.assistingInvestigators);
                const supervisorRoleIds = uniq(formData.supervisors);
                overlayStore.setCustomProperties(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, {
                    isSaving: true,
                });

                const caseData = {
                    theCase,
                    caseStatus,
                    assigneeRoleId,
                    assistingInvestigatorRoleIds,
                    supervisorRoleIds,
                };

                const state = getState();
                const applicationSettings = applicationSettingsSelector(state);
                if (applicationSettings.RMS_INVESTIGATION_ENHANCEMENTS_PHASE_ONE) {
                    const caseCreationBatchItemRequests = buildCaseCreationBatchItemRequests({
                        caseCreationBatchItemRequest: caseData,
                        reportingEventNumbers,
                        recordWithoutRenReportIds,
                    });
                    return caseResource.bulkCreateCasesForAnyReport(caseCreationBatchItemRequests);
                } else {
                    return caseResource.bulkCreateCases(reportingEventNumbers, caseData);
                }
            })
            .then(() => {
                dispatch(casesBatchPolling());
                dispatch(closeCreateManageCaseModal());
                router.push('/cases/all');
            })
            .catch((e) => {
                handleCreateManageCaseFormError(e, overlayStore);
            });
    };
}

function handleCreateManageCaseFormError(error, overlayStore) {
    const { validationResult } = error;
    const errorMessages = get(validationResult, 'formErrors') || [error.message];
    overlayStore.setError(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, errorMessages);
    overlayStore.setCustomProperties(overlayIdEnum.CREATE_MANAGE_CASE_MODAL, {
        isSaving: false,
    });
    scrollToElement(`${MODAL_FORM}`, undefined, `.${MODAL_CONTENT_CLASS}`, MODAL_SCROLL_OFFSET);
}

function convertCaseDetailsToCaseCreationRequest({
    c: entity,
    caseRoleLinks,
    caseStatus,
    entityPermissions,
    reportCaseStatuses,
    reportIds,
    caseReportLinkCreationFromCaseRequests,
    caseAttributes,
    nameCaseLinks,
    itemCaseLinks,
}) {
    const caseDefaultRoleLinks = map(caseRoleLinks, (caseRoleLink) => ({
        ...caseRoleLink,
        operationType: caseRoleLink.entityPermission,
    }));
    return {
        entity,
        caseDefaultRoleLinks,
        caseStatus,
        entityPermissions,
        reportCaseStatuses,
        reportIds,
        caseReportLinkCreationFromCaseRequests,
        caseAttributes,
        nameCaseLinks,
        itemCaseLinks,
    };
}

// this is used only for constructing a case creation request
const reportIdsForCaseCreationSelector = createSelector(
    currentCaseUiSelector,
    (casesUi) => casesUi.reportIdsForCaseCreation
);

export function submitApplyCasePermissionsToAllReportsInCase({ caseId, caseDisplayName }) {
    return (dispatch, _getState, { nexus, overlayStore }) => {
        const errorMessage = componentStrings.cases.core.ApplyCasePermissionsToReportsModal.errorMessage(
            caseDisplayName
        );
        return caseResource
            .applyCasePermissionsToAllReportsInCase(caseId)
            .then((entityPermissions) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [entityPermissionsNexusStateProp]: entityPermissions,
                        },
                        { type: 'STORE_CASE_REPORT_PERMISSIONS' }
                    )
                );
                overlayStore.close(overlayIdEnum.APPLY_CASE_PERMISSIONS_TO_REPORTS_OVERLAY);
            })
            .catch(() => {
                overlayStore.setCustomProperties(
                    overlayIdEnum.APPLY_CASE_PERMISSIONS_TO_REPORTS_OVERLAY,
                    {
                        isSaving: false,
                    }
                );
                overlayStore.setError(
                    overlayIdEnum.APPLY_CASE_PERMISSIONS_TO_REPORTS_OVERLAY,
                    errorMessage
                );
            });
    };
}

import React, { Fragment, useState } from 'react';
import { TaskComment } from '@mark43/rms-api';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { noop, sortBy, map } from 'lodash';
import { Stack, Icon } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { timeAgo } from '~/client-common/core/dates/utils/dateHelpers';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';
import Row from '../../../core/components/Row';
import {
    currentUserHasAbilitySelector,
    currentUserIdSelector,
} from '../../../core/current-user/state/ui';
import { SidePanelSection } from '../../../../legacy-redux/components/core/SidePanel';
import TextArea from '../../../core/forms/components/TextArea';
import { Button as ArcButton } from '../../../core/components/Button';
import { PendingComment } from '../state/ui';
import { abilitiesEnum } from '../../../core/abilities';
import testIds from '../../../../core/testIds';

const strings = componentStrings.tasks.core.TaskSidePanel;

const StyledStack = styled(Stack)`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;

const CommentButton = styled(ArcButton)`
    margin-bottom: 20px;
`;

const HistoryTextItalic = styled.span`
    font-style: italic;
    color: ${(props) => props.theme.colors.mediumGrey};
`;

const HistoryText = styled.span`
    vertical-align: middle;
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.darkGrey};
    margin-bottom: 10px;
`;

export const HistoryName = styled.span`
    font-size: var(--arc-fontSizes-sm);
    font-weight: ${(props) => props.theme.fontWeights.regular};
    color: ${(props) => props.theme.colors.mediumGrey};
`;

const HistoryItem = styled.div<{ canDelete: boolean }>`
    display: flex;
    .delete-comment-icon {
        display: none;
    }
    ${({ canDelete }) =>
        canDelete &&
        `
        &:hover {
            .delete-comment-icon {
                display: unset;
            }
        }`}
`;

const StyledIcon = styled(Icon)`
    margin-left: 10px;
    cursor: pointer;
`;

export const StyledRow = styled(Row)`
    margin-top: 0 !important;
`;

const formatComment = (comment: string) => {
    const regex = /(\r\n|\r|\n)/gi;

    return comment
        .split(regex)
        .map((line, index) => <Fragment key={index}>{line.match(regex) ? <br /> : line}</Fragment>);
};

const TaskFormComment = ({
    onAddComment,
    comments = [],
    deleteComment = noop,
    disabled,
}: {
    onAddComment: (comment: Pick<TaskComment, 'comment' | 'createdBy' | 'createdDateUtc'>) => void;
    comments: (PendingComment | TaskComment)[];
    deleteComment: (comment: PendingComment | TaskComment) => void;
    disabled?: boolean;
}) => {
    const createdBy = useSelector(currentUserIdSelector) || 0;
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const dateTimeFormatter = useDateTimeFormatter();
    const [commentText, setCommentText] = useState<string | undefined>('');
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    return (
        <SidePanelSection title={strings.taskUpdates.toUpperCase()}>
            {!disabled && (
                <Row data-test-id={testIds.TASK_COMMENT}>
                    <TextArea
                        width={420}
                        rows={3}
                        value={commentText}
                        onChange={(text) => setCommentText(text?.toString())}
                    />
                </Row>
            )}
            {!disabled && (
                <CommentButton
                    testId={testIds.ADD_UPDATE_BUTTON}
                    disabled={!commentText}
                    onClick={() => {
                        if (commentText === undefined) {
                            return;
                        }
                        onAddComment({
                            comment: commentText,
                            createdBy,
                            createdDateUtc: new Date().toISOString(),
                        });
                        setCommentText('');
                    }}
                    variant="solid"
                >
                    {strings.addUpdate}
                </CommentButton>
            )}
            {map(sortBy(comments, ['createdDateUtc', 'id']).reverse(), (item, i) => {
                const isUsersComment = item.createdBy === createdBy;
                const canDeleteTaskUpdate =
                    isUsersComment || currentUserHasAbility(abilitiesEnum.CORE.DELETE_TASK_UPDATES);
                const createdByString = `${formatMiniUserById(item.createdBy, {
                    firstNameAsInitial: true,
                })} ${isUsersComment ? '(you)' : ''}`;

                const formattedCreatedDateUtc = `${timeAgo(
                    item.createdDateUtc
                )} ${dateTimeFormatter.formatSummaryDate(item.createdDateUtc)}`;

                return (
                    <HistoryItem
                        key={i}
                        canDelete={canDeleteTaskUpdate}
                        data-test-id={testIds.TASK_COMMENT_HISTORY}
                    >
                        <Icon icon="Note" color="textDefault" size="md" />
                        <StyledStack>
                            <StyledRow>
                                <HistoryName>
                                    {createdByString}
                                    <HistoryTextItalic>
                                        {' '}
                                        {formattedCreatedDateUtc}
                                    </HistoryTextItalic>
                                </HistoryName>
                            </StyledRow>
                            <StyledRow className="HistoryText">
                                <HistoryText>{formatComment(item.comment)}</HistoryText>
                            </StyledRow>
                        </StyledStack>
                        {!disabled && (
                            <StyledIcon
                                className="delete-comment-icon"
                                icon="TrashCan"
                                onClick={() => deleteComment(item)}
                                data-test-id={testIds.TRASH_BUTTON}
                            />
                        )}
                    </HistoryItem>
                );
            })}
        </SidePanelSection>
    );
};

export default TaskFormComment;

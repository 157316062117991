import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';

/**
 * In Cobalt, a popover is a type of modal that is styled with a grey background
 *   (on the popover itself) and has no dimmed overlay around it. Its intended
 *   use is as a bridge between a form and a side panel, for the user to fill in
 *   entity links before creating/editing an entity in the side panel.
 *
 * As a specific example, when adding a new item to a report, the item popover
 *   is first opened for the user to select an item type (which is a field on
 *   the item model, not an entity link) and property statuses (which are entity
 *   links). Submitting the item popover opens the item side panel for the user
 *   to enter fields for the item itself. The item side panel displays different
 *   fields depending on the selected item type, hence the popover fields.
 *
 * A benefit of this bridge is that side panels will always look the same for a
 *   given type of entity, and not need dynamic context-specific fields that may
 *   need to be shown/hidden depending on other fields in the side panel.
 */
export default function Popover(props) {
    return (
        <Modal
            okText={componentStrings.core.Popover.okText}
            shouldCloseOnOverlayClick={true}
            modalClassName="popover"
            dimmedOverlay={false}
            {...props}
        />
    );
}

import { ReportscardsconfigurationResource } from '@mark43/rms-api';
import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

export default createResource({
    name: 'Report Cards Configuration Resource',
    methods: {
        getOrderedCardsByReportDefinitionId(reportDefinitionId: number) {
            return req<ReportscardsconfigurationResource.GetReportDefinitionOrderedCardsForReportDefinition>(
                {
                    method: 'GET',
                    url: `reports/cards/configuration/${reportDefinitionId}/order`,
                }
            );
        },
    },
});

import { UnitStateTransitionPermissionEnum } from '@mark43/cad-api';

import _ from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { N_ITEMS_FIELDSET, FIELDSET } = fieldTypeClientEnum;

const transitionsList = {
    type: N_ITEMS_FIELDSET,
    key: 'transitions',
    fields: buildFlatFormFieldViewModels([
        'firstResponderCanTransition',
        'dispatcherCanTransition',
        'systemCanTransition',
        'id',
        'fromUnitStateId',
        'toUnitStateId',
    ]),
};

const fieldViewModels = {
    eventRelatedTransitions: {
        type: FIELDSET,
        key: 'eventRelatedTransitions',
        fields: transitionsList,
    },
    nonEventRelatedTransitions: {
        type: FIELDSET,
        key: 'nonEventRelatedTransitions',
        fields: transitionsList,
    },
};

function getList(transitions) {
    return {
        transitions: _.map(transitions, (transition) => ({
            ...transition,
            firstResponderCanTransition: _.includes(
                transition.transitionPermissionSet,
                UnitStateTransitionPermissionEnum.FIRST_RESPONDER.name
            ),
            dispatcherCanTransition: _.includes(
                transition.transitionPermissionSet,
                UnitStateTransitionPermissionEnum.DISPATCHER.name
            ),
            systemCanTransition: _.includes(
                transition.transitionPermissionSet,
                UnitStateTransitionPermissionEnum.SYSTEM.name
            ),
        })),
    };
}

function convertToFormModel(transitions) {
    return {
        eventRelatedTransitions: getList(transitions.eventRelatedTransitions),
        nonEventRelatedTransitions: getList(transitions.nonEventRelatedTransitions),
    };
}

/**
 * @param  {Object} options
 * @return {Object}
 */
function transitionIterator({
    firstResponderCanTransition,
    dispatcherCanTransition,
    systemCanTransition,
    ...other
}) {
    return {
        ...other,
        transitionPermissionSet: _.compact([
            firstResponderCanTransition && UnitStateTransitionPermissionEnum.FIRST_RESPONDER.name,
            dispatcherCanTransition && UnitStateTransitionPermissionEnum.DISPATCHER.name,
            systemCanTransition && UnitStateTransitionPermissionEnum.SYSTEM.name,
        ]),
    };
}

function convertFromFormModel(formModel) {
    return {
        eventRelatedTransitions: _.map(
            formModel.eventRelatedTransitions.transitions,
            transitionIterator
        ),
        nonEventRelatedTransitions: _.map(
            formModel.nonEventRelatedTransitions.transitions,
            transitionIterator
        ),
    };
}

/**
 * Module of the location cautions admin form.
 * @type {Object}
 */
export default createFormModule({
    formName: formClientEnum.CAD_UNIT_STATUS_TRANSITIONS,
    fieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

import React from 'react';

import ImageGalleryLightbox from './ImageGalleryLightbox';

export default function Lightboxes() {
    return (
        <div>
            <ImageGalleryLightbox />
        </div>
    );
}

import React from 'react';
import styled from 'styled-components';
import { useToast } from 'arc';
import { IOverlayBaseRenderProps } from 'overlay-manager/lib/OverlayBase';
import { EmailRequestStatusEnum } from '@mark43/rms-api';

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import Modal from '../../overlays/components/Modal';
import { OverlayBaseHelper } from '../../components/OverlayBaseHelper';
import { useFormGetter } from '../../forms/hooks/useFormGetter';

import type { ResolvedEmailRequestStatus, SendEmailFunction } from '../types';
import { pollForEmail } from '../helpers/emailPolling';
import { convertFromFormModel, SendEmailFormData } from '../state/forms/sendEmailForm';
import SendEmailForm from './SendEmailForm';

interface SendEmailModalProps {
    onSave: SendEmailFunction;
    prefillState?: SendEmailFormData;
}

interface SendEmailModalRenderProps {
    overlayBase: IOverlayBaseRenderProps<SendEmailModalProps>;
}

const strings = componentStrings.exports.emails;

const FormWrapper = styled.div`
    padding: var(--arc-space-3, 12px);
`;

const SendEmailModal: React.FC = () => {
    return (
        <OverlayBaseHelper id={overlayIdEnum.SEND_EMAIL_MODAL}>
            {(renderProps: SendEmailModalRenderProps) => {
                const { onSave, prefillState } =
                    renderProps.overlayBase.overlayState.customProperties;
                return <SendEmailModalContent onSave={onSave} prefillState={prefillState} />;
            }}
        </OverlayBaseHelper>
    );
};

const SendEmailModalContent: React.FC<SendEmailModalProps> = ({ onSave, prefillState }) => {
    const toast = useToast();
    const { getForm } = useFormGetter();

    const handleCompletion = (status: ResolvedEmailRequestStatus) => {
        if (status === EmailRequestStatusEnum.ERROR.name) {
            toast({
                status: 'error',
                position: 'bottom-right',
                description: strings.failedToast,
            });
        } else {
            toast({
                status: 'default',
                position: 'bottom-right',
                description: strings.successfulToast,
            });
        }
    };

    const handleSave = () => {
        const form = getForm(formClientEnum.SEND_EMAIL_FORM);
        if (!form) {
            return;
        }

        return form
            .submit()
            .then(({ form }) => {
                const formData = form.get();
                return onSave(convertFromFormModel(formData));
            })
            .then(({ id, status }) => {
                if (status !== EmailRequestStatusEnum.PENDING.name) {
                    handleCompletion(status);
                } else {
                    pollForEmail(id, handleCompletion);
                }
            });
    };

    return (
        <Modal
            id={overlayIdEnum.SEND_EMAIL_MODAL}
            title={strings.sendEmail}
            okText={strings.sendEmail}
            cancelText={strings.cancel}
            saveDisabled={!onSave}
            onSave={handleSave}
            overlayStyle={{
                // React.CSSProperties expects a number and gives a type error for var(--arc-zIndices-modal)
                zIndex: 4000,
            }}
        >
            <FormWrapper>
                <SendEmailForm prefillState={prefillState} />
            </FormWrapper>
        </Modal>
    );
};

export default SendEmailModal;

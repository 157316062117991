import {
    FillablePdfTemplateView,
    PrintingTemplateTypeEnum,
    PrintreportResource,
} from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_PATH = 'print/report';

export default createResource({
    name: 'Report Printing Resource - Fillable PDFs',
    methods: {
        getDepartmentFillablePdfTemplates() {
            return req<PrintreportResource.GetDepartmentFillablePdfTemplates>({
                method: 'GET',
                url: `${BASE_PATH}/pdf/templates`,
                params: {
                    printing_template_types: [PrintingTemplateTypeEnum.FILLABLE_PDF.name],
                },
            });
        },
        getDepartmentPdfNextFillableTemplates() {
            return req<PrintreportResource.GetDepartmentFillablePdfTemplates>({
                method: 'GET',
                url: `${BASE_PATH}/pdf/templates`,
                params: {
                    include_disabled: true,
                    printing_template_types: [
                        PrintingTemplateTypeEnum.PDF_NEXT.name,
                        PrintingTemplateTypeEnum.PDF_NEXT_LEGACY_DATA.name,
                        PrintingTemplateTypeEnum.PDF_NEXT_REPORT_REN.name,
                    ],
                },
            });
        },
        upsertFillablePdfTemplate(template: FillablePdfTemplateView) {
            return req<PrintreportResource.UpsertFillablePdfTemplate>({
                method: 'POST',
                url: `${BASE_PATH}/pdf/templates`,
                data: template,
            });
        },
        deleteFillablePdfTemplate(templateId: string | number) {
            return req<PrintreportResource.DeleteFillablePdfTemplate>({
                method: 'DELETE',
                url: `${BASE_PATH}/pdf/templates/${templateId}/`,
            });
        },
        testHandlebarsTemplate(reportId: number, handlebars: string) {
            return req<PrintreportResource.TestFillablePdfTemplate>({
                method: 'POST',
                url: `${BASE_PATH}/pdf/test/templates`,
                data: { reportId, templates: handlebars },
            });
        },
        getExternalReport(reportId: string | number) {
            return req<PrintreportResource.GetExternalReport>({
                method: 'GET',
                url: `${BASE_PATH}/pdf/fields/${reportId}/`,
            });
        },
    },
});

import { CustodialResponsibleOfficer } from '@mark43/evidence-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import buildCustodialResponsibleOfficerResource from '../../resources/custodialResponsibleOfficerResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'custodialResponsibleOfficers';

const custodialResponsibleOfficersModule = createNormalizedModule<CustodialResponsibleOfficer>({
    type: NEXUS_STATE_PROP,
});

const UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_START =
    'custodial-responsible-officers/UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_START';
const UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_SUCCESS =
    'custodial-responsible-officers/UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_SUCCESS';
const UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_FAILURE =
    'custodial-responsible-officers/UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_FAILURE';

function updateCustodialResponsibleOfficerStart(
    custodialResponsibleOfficer: CustodialResponsibleOfficer
) {
    return {
        type: UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_START,
        payload: custodialResponsibleOfficer,
    };
}

function updateCustodialResponsibleOfficerSuccess(
    custodialResponsibleOfficer: CustodialResponsibleOfficer
) {
    return {
        type: UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_SUCCESS,
        payload: custodialResponsibleOfficer,
    };
}

function updateCustodialResponsibleOfficerFailure(message: string) {
    return {
        type: UPDATE_CUSTODIAL_RESPONSIBLE_OFFICER_FAILURE,
        payload: message,
    };
}

export function updateCustodialResponsibleOfficer(
    custodialResponsibleOfficer: CustodialResponsibleOfficer
): ClientCommonAction<Promise<CustodialResponsibleOfficer>> {
    return (dispatch) => {
        const resource = buildCustodialResponsibleOfficerResource();
        dispatch(updateCustodialResponsibleOfficerStart(custodialResponsibleOfficer));
        return resource
            .updateCustodialResponsibleOfficer(custodialResponsibleOfficer)
            .then((custodialResponsibleOfficer: CustodialResponsibleOfficer) => {
                dispatch(storeCustodialResponsibleOfficers(custodialResponsibleOfficer));
                dispatch(updateCustodialResponsibleOfficerSuccess(custodialResponsibleOfficer));
                return custodialResponsibleOfficer;
            })
            .catch((err: Error) => {
                dispatch(updateCustodialResponsibleOfficerFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS
const storeCustodialResponsibleOfficers =
    custodialResponsibleOfficersModule.actionCreators.storeEntities;

// SELECTORS
export const custodialResponsibleOfficersSelector =
    custodialResponsibleOfficersModule.selectors.entitiesSelector;
export const custodialResponsibleOfficersWhereSelector =
    custodialResponsibleOfficersModule.selectors.entitiesWhereSelector;

// REDUCER
export default custodialResponsibleOfficersModule.reducerConfig;

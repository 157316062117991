import { roleByIdSelector } from '~/client-common/core/domain/roles/state/data';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

import warrantPermissionsAdminForm from '../forms/warrantPermissionsAdminForm';

import {
    UPDATE_WARRANT_PERMISSIONS_START,
    UPDATE_WARRANT_PERMISSIONS_SUCCESS,
    UPDATE_WARRANT_PERMISSIONS_FAILURE,
} from '../data';

const initialUiState = {
    submittingForm: false,
    formError: null,
};

export const setInitialFormState = (permissions) => (dispatch, getState) => {
    const state = getState();
    const currentDepartmentId = currentUserDepartmentIdSelector(state);
    const roleById = roleByIdSelector(state);
    dispatch(
        warrantPermissionsAdminForm.actionCreators.change({
            permissions,
            currentDepartmentId,
            roleById,
        })
    );
};

export default function warrantConfigurationAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case UPDATE_WARRANT_PERMISSIONS_START:
            return {
                ...state,
                submittingForm: true,
                formError: null,
            };
        case UPDATE_WARRANT_PERMISSIONS_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                formError: null,
            };
        case UPDATE_WARRANT_PERMISSIONS_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.warrantConfigurationAdmin;

import { reduce, chain } from 'lodash';
import globalAttributes from '../core/legacy-constants/globalAttributes';

const {
    taskStatusGlobal: { pending, inProgress, completed, notApplicable },
} = globalAttributes;

export const TaskStatusGroupEnum = {
    COMPLETED: 'Completed',
    IN_PROGRESS: 'In Progress',
    NOT_APPLICABLE: 'Not Applicable',
    TO_DO: 'To Do',
};

const taskStatusAttributesDisplayOrder = [pending, inProgress, completed, notApplicable];

const optionsForDisplay = (attributeOptions, order) => {
    return reduce(
        order,
        (result, parentAttributeId) => {
            const filteredAttributeOptions = chain(attributeOptions)
                .filter({
                    parentId: parentAttributeId,
                })
                .orderBy('display')
                .value();

            if (filteredAttributeOptions.length > 0) {
                return [...result, ...filteredAttributeOptions];
            }
            return result;
        },
        []
    );
};

export const taskStatusOptionsForDisplay = (attributeOptions) =>
    optionsForDisplay(attributeOptions, taskStatusAttributesDisplayOrder);

import { flow, partialRight, map } from 'lodash';
import { createSelector } from 'reselect';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { formatOffenseCode } from '~/client-common/helpers/offenseCodesHelpers';
import { reportDefinitionsForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import recursivelyConvertAttributeIdsToDisplayValueObjects from '../../utils/recursivelyConvertAttributeIdsToDisplayValueObjects';
import recursivelyConvertOffenseCodeIdsToDisplayValueObjects from '../../utils/recursivelyConvertOffenseCodeIdsToDisplayValueObjects';
import recursivelyConvertReportDefinitionIdsToDisplayValueObjects from '../../utils/recursivelyConvertReportDefinitionIdsToDisplayValueObjects';

export const transformElasticQueryIdsToDisplayValuesSelector = createSelector(
    attributesSelector,
    offenseCodesSelector,
    reportDefinitionsForCurrentDepartmentSelector,
    (attributes, offenseCodes, reportDefinitionsForCurrentDepartment) => {
        const attributeValueGetter = (id) => attributes[id].val;
        const convertAttributeIds = partialRight(
            recursivelyConvertAttributeIdsToDisplayValueObjects,
            (value) => {
                // `*AttrDetail` case
                // we know that if our shape has an `ids` prop, it is an array of ids
                if (value.ids) {
                    return map(value.ids, attributeValueGetter);
                }
                return attributeValueGetter(value);
            }
        );
        const convertOffenseCodeIds = partialRight(
            recursivelyConvertOffenseCodeIdsToDisplayValueObjects,
            (id) => formatOffenseCode(offenseCodes[id])
        );
        const convertReportDefinitionIds = partialRight(
            recursivelyConvertReportDefinitionIdsToDisplayValueObjects,
            (id) => reportDefinitionsForCurrentDepartment[id].name
        );

        const conversionPipeline = flow(
            convertAttributeIds,
            convertOffenseCodeIds,
            convertReportDefinitionIds
        );

        return (elasticQuery) => conversionPipeline(elasticQuery);
    }
);

export * from './savedSearches';

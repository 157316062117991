// TO remove
import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import { compact } from 'lodash';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import { loadLocationsLinkedToEntity } from '~/client-common/core/domain/locations/state/data';
import {
    NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP,
    saveAttachmentsForEntities,
} from '~/client-common/core/domain/attachments/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { NEXUS_STATE_PROP as DEPARTMENT_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/department-profiles/state/data';
import { currentUserHasAbilitySelector } from '../../modules/core/current-user/state/ui';
import { userDepartmentIdSelector } from '../selectors/userSelectors';
import { departmentProfileSelector } from '../selectors/departmentProfileSelectors';
import Resource from '../resources/adminDepartmentResource';
import {
    closeBox,
    openErrorModal,
    saveBoxFailure,
    saveBoxStart,
    saveBoxSuccess,
} from './boxActions';
import departmentProfileAdminActionTypes from './types/departmentProfileAdminActionTypes';

const {
    DEPARTMENT_PROFILE_ADMIN_PAGELOAD,
    LOAD_DEPARTMENT_PROFILE_SUCCESS,
    LOAD_DEPARTMENT_PROFILE_FAILURE,
    SAVE_DEPARTMENT_PROFILE_SUCCESS,
    SAVE_DEPARTMENT_PROFILE_FAILURE,
    RESET_DEPARTMENT_FORM,
    LOAD_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS,
    SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS,
    SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_FAILURE,
} = departmentProfileAdminActionTypes;

const context = {
    name: boxEnum.DEPARTMENT_PROFILE_SIDE_PANEL,
};

function rawPageLoad() {
    return {
        type: DEPARTMENT_PROFILE_ADMIN_PAGELOAD,
    };
}

function loadDepartmentProfileSuccess(deptProfile) {
    return {
        type: LOAD_DEPARTMENT_PROFILE_SUCCESS,
        payload: deptProfile,
    };
}

function loadDepartmentProfileFailure() {
    return {
        type: LOAD_DEPARTMENT_PROFILE_FAILURE,
    };
}

function saveDepartmentProfileSuccess(deptProfile) {
    return {
        type: SAVE_DEPARTMENT_PROFILE_SUCCESS,
        payload: deptProfile,
    };
}

function saveDepartmentProfileFailure() {
    return {
        type: SAVE_DEPARTMENT_PROFILE_FAILURE,
    };
}

function saveDepartmentProfileAttachmentsFailure() {
    return {
        type: SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_FAILURE,
    };
}

function resetDepartmentForm(departmentProfile) {
    return {
        type: RESET_DEPARTMENT_FORM,
        payload: departmentProfile,
    };
}

export function cancelDepartmentPanel() {
    return (dispatch, getState) => {
        const departmentProfile = departmentProfileSelector(getState());
        dispatch(closeBox(context));
        dispatch(resetDepartmentForm(departmentProfile));
    };
}

function loadDepartmentProfileAttachmentsSuccess(attachments) {
    return {
        type: LOAD_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS,
        payload: attachments,
    };
}

function saveDepartmentProfileAttachmentsSuccess(attachments) {
    return {
        type: SAVE_DEPARTMENT_PROFILE_ATTACHMENTS_SUCCESS,
        payload: attachments,
    };
}

function loadDeptProfileAttachments(deptId) {
    const attachmentsResource = getAttachmentsResource();
    return attachmentsResource.loadAttachmentsByEntityId(EntityTypeEnum.DEPARTMENT.name, deptId);
}

export function pageLoad() {
    return (dispatch, getState, dependencies) => {
        dispatch(rawPageLoad());
        const departmentId = userDepartmentIdSelector(getState());
        Promise.all([
            Resource.getDepartmentProfile(departmentId),
            loadDeptProfileAttachments(departmentId),
            dispatch(loadLocationsLinkedToEntity(EntityTypeEnum.DEPARTMENT.name, departmentId)),
        ])
            .spread((deptProfile, attachments) => {
                dispatch(loadDepartmentProfileSuccess(deptProfile));
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [ATTACHMENTS_NEXUS_STATE_PROP]: attachments,
                        },
                        loadDepartmentProfileAttachmentsSuccess(attachments)
                    )
                );
            })
            .catch(() => {
                dispatch(loadDepartmentProfileFailure());
            });
    };
}

function saveDepartmentLogo(deptProfile) {
    return (dispatch) => {
        // This awkward double field is required to know on a redux form if we have removed the
        // existing department logo, or just left it untouched.
        if (!deptProfile.departmentProfileLogoId && !deptProfile.removedDepartmentProfileLogoId) {
            return;
        }
        const newDepartmentProfilePhotoAttachment = deptProfile.departmentProfileLogoId
            ? {
                  entityId: deptProfile.departmentId,
                  attachmentId: deptProfile.departmentProfileLogoId,
                  attachmentType: EntityTypeEnum.FILE.name,
                  entityType: EntityTypeEnum.DEPARTMENT.name,
                  linkType: LinkTypesEnum.DEPARTMENT_PROFILE_LOGO,
              }
            : undefined;

        const attachment = compact([newDepartmentProfilePhotoAttachment]);
        // save Attachments and replace existing attachments (last arg)
        return dispatch(
            saveAttachmentsForEntities({
                entityType: EntityTypeEnum.DEPARTMENT.name,
                entityIds: [deptProfile.departmentId],
                attachments: attachment,
                removeOthers: true,
                linkTypes: [LinkTypesEnum.DEPARTMENT_PROFILE_LOGO],
            })
        )
            .then((attachment) => {
                dispatch(saveDepartmentProfileAttachmentsSuccess(attachment));
            })
            .catch(() => {
                dispatch(saveDepartmentProfileAttachmentsFailure());
            });
    };
}

function saveDepartmentProfile(deptProfileData) {
    const {
        timeZone,
        displayName,
        city,
        complianceGroup,
        nibrsRegionalGroup,
        nibrsSubmissionEndpoint,
        departmentStatus,
        phoneNumber,
        website,
        searchResyncsEnabled,
        dexRegion,
        ageOfAdult,
        personsSearchResultsDefault,
        externalCustomerId,
        ...locationBias
    } = deptProfileData;
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const { departmentId, localType } = departmentProfileSelector(state);
        const canEditDeptStatus = currentUserHasAbilitySelector(state)(
            abilitiesEnum.ADMIN.EDIT_DEPARTMENT_STATUS
        );
        const canEditSearchResync = currentUserHasAbilitySelector(state)(
            abilitiesEnum.SUPPORT.DEPARTMENT_SETUP
        );
        const department = {
            departmentStatus,
            searchResyncsEnabled,
        };
        const deptProfile = {
            departmentId,
            localType,
            city,
            timeZone,
            displayName,
            complianceGroup,
            nibrsRegionalGroup,
            nibrsSubmissionEndpoint,
            locationBias,
            department,
            phoneNumber,
            website,
            dexRegion,
            ageOfAdult,
            personsSearchResultsDefault,
            externalCustomerId,
        };
        return Resource.upsertDepartmentProfile(deptProfile)
            .then((newProfile) =>
                Promise.all([
                    newProfile,
                    canEditDeptStatus ? Resource.editDepartmentStatus(deptProfile) : null,
                    canEditSearchResync
                        ? Resource.allowSearchResyncs(
                              deptProfile.departmentId,
                              !!searchResyncsEnabled
                          )
                        : null,
                ])
            )
            .then(([newProfile, profileWithNewDepartment]) => {
                const departmentProfileResult = {
                    ...newProfile,
                    department: {
                        ...newProfile.department,
                        departmentStatus:
                            profileWithNewDepartment && profileWithNewDepartment.department
                                ? profileWithNewDepartment.department.departmentStatus
                                : newProfile.department.departmentStatus,
                        allowSearchResyncs:
                            profileWithNewDepartment && profileWithNewDepartment.department
                                ? profileWithNewDepartment.department.allowSearchResyncs
                                : newProfile.department.allowSearchResyncs,
                    },
                };
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [DEPARTMENT_PROFILES_NEXUS_STATE_PROP]: [departmentProfileResult],
                        },
                        saveDepartmentProfileSuccess(departmentProfileResult)
                    )
                );
            })
            .catch((err) => {
                dispatch(saveDepartmentProfileFailure());
                throw err;
            });
    };
}

export function saveDepartmentPanel(deptProfile) {
    return (dispatch) => {
        dispatch(saveBoxStart(context));
        return Promise.all([
            dispatch(saveDepartmentProfile(deptProfile)),
            dispatch(saveDepartmentLogo(deptProfile)),
        ])
            .then(() => {
                dispatch(saveBoxSuccess(context));
                dispatch(closeBox(context));
            })
            .catch((err) => {
                dispatch(saveBoxFailure(context, err.message));
                if (err.code === 4343) {
                    dispatch(
                        openErrorModal({
                            title: `Invalid Department Profile data`,
                            paragraphs: err.message.split(/\r?\n/),
                        })
                    );
                }
            });
    };
}

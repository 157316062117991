import { Detention, ReportingdetentionsResource } from '@mark43/rms-api';
import { createResource } from '../../../../../lib/resources/Resource';
import { req } from '../../../../../lib/ajax';

export default createResource({
    name: 'Detentions Resource',
    methods: {
        replaceDetentionsForReport({
            detentions,
            reportId,
        }: {
            detentions: Detention[];
            reportId: number;
        }) {
            return req<ReportingdetentionsResource.ReplaceDetentionsForReport>({
                data: detentions,
                method: 'PUT',
                url: `reporting/detentions/reports/${reportId}/`,
            });
        },
    },
});

import React from 'react';
import styled from 'styled-components';
import testIds from '../../../core/testIds';

const ClipsErrorWrapper = styled.div`
    margin: 0 30px 12px 7px;
`;

const ClipsErrorSection = styled.div`
    display: flex;
    background-color: var(--arc-colors-negative-accent);
    color: ${({ theme }) => theme.colors.red};
    border: 1px solid var(--arc-colors-negative-default);
    border-radius: 4px;
    padding: 10px 28px;
    width: 100%;
`;

const ClipsErrorContainer = styled.ul`
    color: ${({ theme }) => theme.colors.red};
    font-size: var(--arc-fontSizes-sm);
    padding-left: 0;
    margin: 0;

    & li {
        list-style: inside;
        padding: 2px 0;
        margin: 10px 5px;
    }
`;

const ClipsErrorMessage: React.FC = (props) => {
    return (
        <ClipsErrorWrapper>
            <ClipsErrorSection>
                <ClipsErrorContainer data-test-id={testIds.CLIPS_ERROR_MESSAGE}>
                    <li>{props.children}</li>
                </ClipsErrorContainer>
            </ClipsErrorSection>
        </ClipsErrorWrapper>
    );
};

export default ClipsErrorMessage;

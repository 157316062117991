import React, { useCallback } from 'react';
import { RefContextEnum } from '@mark43/rms-api';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { storeAttributes } from '~/client-common/core/domain/attributes/state/data';
import Modal from '../../../core/overlays/components/Modal';
import { useEFileContext, useEFileDisplayName } from '../../hooks';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import {
    DetailsEFileFormDataShapeT,
    convertFromFormModelToEFileUpsert,
} from '../state/forms/eFileDetailsForm';
import { eFileResource } from '../../resources/eFileResource';
import EFileDetailsForm from './EFileDetailsForm';

const overlayId = overlayIdEnum.UPSERT_E_FILE_MODAL;
const strings = componentStrings.eFiles.core.eFileDetailsModal;

export const EFileUpdateModal = () => {
    const dispatch = useDispatch();
    const { eFileDisplayName } = useEFileDisplayName();
    const { getForm } = useFormGetter();
    const {
        actions,
        getters: { efile },
    } = useEFileContext();

    const eFileToUpdate = efile.getEFile();
    const eFileViewModel = efile.getEFileViewModel();
    const localId = eFileViewModel?.localId ? `#${eFileViewModel.localId}` : '';

    const onSave = useCallback(async () => {
        const form = getForm(RefContextEnum.FORM_E_FILE_DETAILS_MODAL.name);
        if (!form) {
            return new Error('Failed to find form');
        }

        const result = await form.submit();
        if (result && result.validationResult) {
            if (result.validationResult.success) {
                const formModel: DetailsEFileFormDataShapeT = result.form.getState().model;
                if (eFileToUpdate) {
                    const eFileUpsertRequest = convertFromFormModelToEFileUpsert(
                        formModel,
                        eFileToUpdate
                    );
                    if (eFileUpsertRequest) {
                        try {
                            const efileView = await eFileResource.updateEFileDetails(
                                eFileUpsertRequest
                            );

                            dispatch(
                                // eslint-disable-next-line no-restricted-syntax
                                storeAttributes(
                                    map(efileView.attributes, convertAttributeToAttributeView)
                                )
                            );
                            actions.efile.setEfile(efileView);

                            return [];
                        } catch (err) {
                            throw err;
                        }
                    } else {
                        return new Error(
                            'Failed to save E-File. The form fields are either missing or incorrect.'
                        );
                    }
                }

                return new Error('Cannot find e-file');
            }
            return result.validationResult.formErrors;
        }
        return [];
    }, [actions.efile, dispatch, eFileToUpdate, getForm]);

    return (
        <Modal
            id={overlayId}
            title={strings.manageEFile(eFileDisplayName, localId)}
            okText={strings.saveText}
            autoClose={false}
            onSave={onSave}
        >
            <EFileDetailsForm eFile={eFileToUpdate} />
        </Modal>
    );
};

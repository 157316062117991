import React, { useState, useCallback } from 'react';
import { Flex, Banner, ButtonGroup, Divider, cssVar, RadioGroup, Radio, Text, VStack } from 'arc';
import { find, includes } from 'lodash';
import styled from 'styled-components';
import { PhotoLineupView, PrintingDataTypeEnum } from '@mark43/rms-api';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useResource } from '~/client-common/core/hooks/useResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { AnalyticsContextProviderWithAdditionalData } from '../../../core/context/AnalyticsContext';
import { Button as _Button } from '../../../core/components/Button';
import {
    exportPDF,
    loadExportPacketOptions,
} from '../../../../legacy-redux/actions/exportsActions';

import { exportSelector } from '../../../../legacy-redux/selectors/exportsSelectors';
import { openBox } from '../../../../legacy-redux/actions/boxActions';
import casePhotoLineupResource from '../../core/resources/casePhotoLineupResource';
import Link from '../../../core/components/links/Link';
import testIds from '../../../../core/testIds';

import { useAbilitySelector } from '../../../core/current-user/hooks/useAbilitySelector';
import { usePhotoLineupFieldName } from '../hooks/usePhotoLineupFieldName';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import { LineupDetailsRow } from './LineupPhotoRow';

const strings = componentStrings.cases.casePhotoLineups.ExportLineup;
let noPacketError = '';
const RAW_PACKET = 'rawPacket';

const ExportPage = styled(Flex)`
    position: relative;
    background-color: ${cssVar('arc.colors.surface.background')};
    height: 100%;
`;

const LineupContainer = styled.div`
    margin: 24px 0;
`;

const ExportTypeContainer = styled.div`
    align-self: start;
    width: 100%;
`;

const Button = styled(_Button)`
    text-transform: none;
`;

const Footer = styled(Flex)`
    position: fixed;
    bottom: 0;
    margin-left: 0;
    width: 970px;
    background-color: ${cssVar('arc.colors.surface.accent')};
    border-top: 1px solid ${cssVar('arc.colors.text.secondary')};
`;

const LineupExportType = {
    SEQUENTIAL: 'Sequential',
    SIMULTANEOUS: 'Simultaneous',
} as const;

type Params = {
    caseId: string;
    photoLineupId: string;
};

type LineupExportOption = typeof LineupExportType.SEQUENTIAL | typeof LineupExportType.SIMULTANEOUS;

const ExportLineup: React.FC<RouteComponentProps<Params, Record<string, unknown>>> = ({
    params,
}) => {
    const dispatch = useDispatch();
    const [lineup, setLineup] = useState<PhotoLineupView>();
    const [exportType, setExportType] = useState<LineupExportOption>(LineupExportType.SEQUENTIAL);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const exportInfo = useSelector(exportSelector);
    const lineupFieldName = usePhotoLineupFieldName();

    const viewExportReleaseTracking = useAbilitySelector(
        abilitiesEnum.CORE.VIEW_EXPORT_RELEASE_TRACKING
    );

    const caseId = parseInt(params.caseId);
    const photoLineupId = parseInt(params.photoLineupId);

    const loadExportPage = useCallback(() => {
        // Get Export templates
        const loadExportPackets = dispatch(
            loadExportPacketOptions(PrintingDataTypeEnum.PHOTO_LINEUP.name, {
                caseId,
                photoLineupId,
            })
        );

        return Promise.all<unknown | PhotoLineupView>([
            casePhotoLineupResource.getPhotoLineupForCase(caseId, photoLineupId),
            loadExportPackets,
        ]);
    }, [caseId, dispatch, photoLineupId]);

    const onExportPageSuccess = useCallback((loadedInitVals: (PhotoLineupView | unknown)[]) => {
        // `as` is okay to use here because with promise.all(), the order of the promises will ALWAYS be the SAME
        // this means that the order will ALWAYS be [PhotoLineupView, unknown] unless changed
        const photoLineupView: PhotoLineupView = loadedInitVals[0] as PhotoLineupView;
        setLineup(photoLineupView);
    }, []);

    const getPacketsFromExportType = useCallback(
        (exportType: LineupExportOption) => {
            const packets = exportInfo.packetOptions.map((packet) => {
                if (RAW_PACKET in packet) {
                    return packet[RAW_PACKET];
                } else {
                    return packet;
                }
            });
            let selectedPacket: typeof packets[0];
            switch (exportType) {
                case LineupExportType.SIMULTANEOUS:
                    selectedPacket = find(packets, (packet) =>
                        includes(packet.groupTitle, LineupExportType.SIMULTANEOUS)
                    );
                    break;
                default:
                    selectedPacket = find(packets, (packet) =>
                        includes(packet.groupTitle, LineupExportType.SEQUENTIAL)
                    );
            }
            return [selectedPacket];
        },
        [exportInfo.packetOptions]
    );

    const onExport = useCallback(() => {
        const printables = getPacketsFromExportType(exportType);

        if (includes(printables, undefined)) {
            noPacketError = strings.noPrintables;
            return;
        }

        const exportData = {
            formData: {
                combinedPDF: false,
                packets: printables,
            },
            selectedPackets: printables,
        };

        if (applicationSettings.RELEASE_TRACKING_ENABLED && viewExportReleaseTracking) {
            // open the release form and kick off print job when successful
            dispatch(openBox({ name: boxEnum.CREATE_EXPORT_RELEASE_MODAL }, exportData));
        } else {
            // kick off printing job if no release needed
            dispatch(exportPDF(exportData.formData, exportData.selectedPackets));
        }
    }, [
        applicationSettings.RELEASE_TRACKING_ENABLED,
        dispatch,
        exportType,
        getPacketsFromExportType,
        viewExportReleaseTracking,
    ]);

    const { isLoading, errorMessage } = useResource(loadExportPage, onExportPageSuccess);

    const showError = errorMessage || noPacketError;
    const getErrorMessage = () => {
        if (errorMessage) {
            return errorMessage;
        } else if (noPacketError) {
            return noPacketError;
        } else {
            return '';
        }
    };

    if (showError) {
        return <Banner status="error" description={getErrorMessage()} title={strings.errorText} />;
    } else if (isLoading || !lineup) {
        return null;
    }

    return (
        <ExportPage justify="center">
            <VStack>
                <LineupContainer>
                    <LineupDetailsRow lineupView={lineup} />
                </LineupContainer>
                {applicationSettings.RMS_SIMULTANEOUS_LINEUPS_ENABLED && (
                    <ExportTypeContainer>
                        <Text variant="headingMd">
                            {strings.lineupPresentation(lineupFieldName).toUpperCase()}
                        </Text>
                        <Divider />
                        <RadioGroup
                            value={exportType}
                            onChange={(presentationType: LineupExportOption) => {
                                setExportType(presentationType);
                            }}
                            style={{ marginTop: '20px' }}
                        >
                            <Radio
                                value={LineupExportType.SEQUENTIAL}
                                data-test-id={testIds.PHOTO_LINEUP_EXPORT_TYPE}
                            >
                                {LineupExportType.SEQUENTIAL}
                            </Radio>
                            <Radio
                                value={LineupExportType.SIMULTANEOUS}
                                data-test-id={testIds.PHOTO_LINEUP_EXPORT_TYPE}
                            >
                                {LineupExportType.SIMULTANEOUS}
                            </Radio>
                        </RadioGroup>
                    </ExportTypeContainer>
                )}
            </VStack>
            <Footer justify="start" padding={4}>
                <ButtonGroup>
                    <AnalyticsContextProviderWithAdditionalData
                        analyticsKeyToAdd={AnalyticsPropertyEnum.EXPORT_TYPE}
                        analyticsValueToAdd={
                            exportType === LineupExportType.SIMULTANEOUS
                                ? LineupExportType.SIMULTANEOUS
                                : LineupExportType.SEQUENTIAL
                        }
                    >
                        <Button
                            variant="solid"
                            onClick={onExport}
                            testId={testIds.PHOTO_LINEUP_EXPORT_DOWNLOAD_BUTTON}
                        >
                            {strings.downloadText}
                        </Button>
                    </AnalyticsContextProviderWithAdditionalData>
                    <Link to={`/cases/${caseId}/lineups`}>
                        <Button>{strings.cancelText}</Button>
                    </Link>
                </ButtonGroup>
            </Footer>
        </ExportPage>
    );
};

export default ExportLineup;

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { chain, map, first, filter, keys } from 'lodash';

import { OffenseCodeWordingTemplate, FullOffenseCodeWording } from '@mark43/rms-api';
import { useResource } from '~/client-common/core/hooks/useResource';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import getChargesResource from '~/client-common/core/domain/charges/resources/chargesResource';
import {
    initialChargesFormModelMadLibs,
    getChargesForm,
    upsertMadLibsChargeConfig,
    getChargeMadLibsConfiguration,
    MADLIBS_FORM_MODEL_KEY,
} from '../../../state/forms/chargesForm';
import {
    convertOffenseWordingFieldValuesArrayToMap,
    validateMadLibsParameters,
} from '../../../utils/madLibsConfig';
import MadLibs, { ParamMapT } from './MadLibs';

type PropsT = {
    summaryMode: boolean;
    arrestId: number;
    chargeId: number;
    chargesFormIndex: number;
    offenseCodeId?: number;
};

export const ChargeWordTemplates: React.FC<PropsT> = ({
    summaryMode,
    chargeId,
    arrestId,
    chargesFormIndex,
    offenseCodeId,
}) => {
    const dispatch = useDispatch();
    const [selectedOffenseWordTemplate, setSelectedOffenseWordTemplate] = useState<
        OffenseCodeWordingTemplate | undefined
    >(undefined);
    const [
        selectedOffenseWordTemplateParameterMap,
        setSelectedOffenseWordTemplateParameterMap,
    ] = useState<ParamMapT>({});

    const [offenseCodeWordingTemplates, setOffenseCodeWordingTemplates] = useState<
        OffenseCodeWordingTemplate[]
    >([]);

    const offenseCodeIdRef = useRef(offenseCodeId);

    const fetchChargeOffenseWordingBundle = useCallback(() => {
        offenseCodeIdRef.current = offenseCodeId;
        return getChargesResource().getOffenseCodeWordingTemplateBundleByChargeIds([chargeId]);
    }, [chargeId, offenseCodeId]);

    useEffect(() => {
        return function cleanup() {
            const form = getChargesForm(chargesFormIndex);
            if (!isUndefinedOrNull(form)) {
                const madlibsConfig = form.get(MADLIBS_FORM_MODEL_KEY);
                // @ts-expect-error chargesForm should be typed
                const index = madlibsConfig.findIndex((item) => item.chargeId === chargeId);
                if (index >= 0) {
                    form.remove(MADLIBS_FORM_MODEL_KEY, index);
                }
            }
        };
    }, [chargeId, chargesFormIndex]);



    const onChargedWordingBundleSuccess = useCallback(
        ({
            offenseCodeWordingTemplates,
            fullOffenseCodeWordings,
        }: {
            offenseCodeWordingTemplates: OffenseCodeWordingTemplate[];
            fullOffenseCodeWordings: FullOffenseCodeWording[];
        }) => {
            const currentChargeFullOffenseCodeWording = first(fullOffenseCodeWordings);
            let selectedChargeOffenseWordingTemplate;
            if (currentChargeFullOffenseCodeWording) {
                selectedChargeOffenseWordingTemplate = chain(offenseCodeWordingTemplates)
                    .filter(
                        (item) =>
                            item.id ===
                            currentChargeFullOffenseCodeWording.offenseCodeWording
                                .offenseCodeWordingTemplateId
                    )
                    .first()
                    .value();
            }
            if (!selectedChargeOffenseWordingTemplate) {
                selectedChargeOffenseWordingTemplate = chain(offenseCodeWordingTemplates)
                    .sortBy('createdDateUtc')
                    .last()
                    .value();
            }
            const madLibsParameterMap = currentChargeFullOffenseCodeWording
                ? convertOffenseWordingFieldValuesArrayToMap(
                      currentChargeFullOffenseCodeWording.offenseCodeWordingFieldValues
                  )
                : {};
            setSelectedOffenseWordTemplateParameterMap(madLibsParameterMap);
            if (selectedChargeOffenseWordingTemplate) {
                setSelectedOffenseWordTemplate(selectedChargeOffenseWordingTemplate);
                dispatch(
                    initialChargesFormModelMadLibs({
                        chargesFormIndex,
                        arrestId,
                        chargeId,
                        offenseWordingId: currentChargeFullOffenseCodeWording
                            ? currentChargeFullOffenseCodeWording.offenseCodeWording.id
                            : undefined,
                        offenseWordingTemplateFieldValues: currentChargeFullOffenseCodeWording
                            ? currentChargeFullOffenseCodeWording.offenseCodeWordingFieldValues
                            : [],
                        selectedOffenseWordTemplate: selectedChargeOffenseWordingTemplate,
                    })
                );
            }
            setOffenseCodeWordingTemplates(offenseCodeWordingTemplates);
        },
        [arrestId, chargeId, chargesFormIndex, dispatch]
    );

    useResource(fetchChargeOffenseWordingBundle, onChargedWordingBundleSuccess);

    const updateOffenseWordingFieldValuesOnChargesFormModel = useCallback(
        ({
            chargeId,
            parameterMap,
            templateString,
        }: {
            chargeId: number;
            parameterMap: ParamMapT;
            templateString: string;
        }) => {
            const form = getChargesForm(chargesFormIndex);
            if (!isUndefinedOrNull(form)) {
                const selectedChargeMadLibs = getChargeMadLibsConfiguration({
                    chargesForm: form,
                    chargeId,
                });

                if (selectedChargeMadLibs) {
                    const { fieldValues } = selectedChargeMadLibs;
                    const updatedTemplateFieldValues = map(keys(parameterMap), (key) => {
                        const existingFieldValue = first(
                            filter(fieldValues, (fieldValue) => fieldValue.fieldKey === key)
                        );

                        if (existingFieldValue) {
                            return {
                                ...existingFieldValue,
                                fieldValue: parameterMap[key],
                            };
                        }
                        return {
                            fieldKey: key,
                            fieldValue: parameterMap[key],
                        };
                    });

                    const isMadLibsValid = validateMadLibsParameters(
                        templateString,
                        updatedTemplateFieldValues
                    );

                    upsertMadLibsChargeConfig({
                        chargesForm: form,
                        chargeId,
                        configuration: {
                            ...selectedChargeMadLibs,
                            chargeId,
                            fieldValues: updatedTemplateFieldValues,
                            isValid: isMadLibsValid,
                        },
                    });
                }
            }
        },
        [chargesFormIndex]
    );

    const onParameterMapChange = useCallback(
        (parameterMap: ParamMapT) => {
            if (selectedOffenseWordTemplate) {
                updateOffenseWordingFieldValuesOnChargesFormModel({
                    chargeId,
                    parameterMap,
                    templateString: selectedOffenseWordTemplate.wording,
                });
            }
        },
        [updateOffenseWordingFieldValuesOnChargesFormModel, chargeId, selectedOffenseWordTemplate]
    );

    if (offenseCodeWordingTemplates.length === 0) {
        return null;
    }

    if (!selectedOffenseWordTemplate) {
        return null;
    }

    return (
        <MadLibs
            templateString={selectedOffenseWordTemplate.wording}
            initialParameters={selectedOffenseWordTemplateParameterMap}
            onParameterMapChange={onParameterMapChange}
            isReadOnly={summaryMode}
        />
    );
};

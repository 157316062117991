import { DraggableStateSnapshot, DraggableProvidedDraggableProps } from 'react-beautiful-dnd';

export enum DraggableTypes {
    ATTACHMENT = 'ATTACHMENT',
    FOLDER = 'FOLDER',
    CASE_NOTE = 'CASE_NOTE',
}

export const DROPPABLE_ID_SEPARATOR = '-';

export const createAttachmentDroppableId = (attachmentId: number) => {
    return `attachment${DROPPABLE_ID_SEPARATOR}${attachmentId}`;
};

export const createFolderDroppableId = (folderId: number, folderName: string) => {
    return `folder${DROPPABLE_ID_SEPARATOR}${folderId}${DROPPABLE_ID_SEPARATOR}${folderName}`;
};

export const createCaseSidebarFolderDroppableId = (folderId: number, folderName: string) => {
    return `caseSideBarFolder${DROPPABLE_ID_SEPARATOR}${folderId}${DROPPABLE_ID_SEPARATOR}${folderName}`;
};

export const createCaseNoteDroppableId = (caseNoteId: number) => {
    return `caseNote${DROPPABLE_ID_SEPARATOR}${caseNoteId}`;
};

// Same reason mentioned in DroppablePlaceHolderWrapper, we
// need to disabled animation translate of draggable items
// except the item isDragging to avoid reordering behaviour
export const getDragStyle = (
    draggableProps: DraggableProvidedDraggableProps,
    snapshot: DraggableStateSnapshot
) => {
    if (!snapshot.isDragging) {
        return {};
    }
    if (!snapshot.isDropAnimating) {
        return draggableProps.style;
    }
    return {
        ...draggableProps.style,
        transition: `0.001s`,
    };
};

import { CaseNote } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'caseNotes';
const caseNotesModule = createNormalizedModule<CaseNote>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeCaseNotes = caseNotesModule.actionCreators.storeEntities;

// SELECTORS
export const caseNotesSelector = caseNotesModule.selectors.entitiesSelector;
export const caseNotesWhereSelector = caseNotesModule.selectors.entitiesWhereSelector;

// REDUCER
export default caseNotesModule.reducerConfig;

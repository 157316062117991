import { FieldTypeEnum } from '@mark43/rms-api';

import { identity } from 'lodash';
import {
    PERSON_PROFILE_HEIGHT,
    PERSON_PROFILE_HEIGHT_RANGE_MAX,
    PERSON_PROFILE_HEIGHT_RANGE_MIN,
} from '~/client-common/core/enums/universal/fields';
import { formatHeight } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { NO_VALUE_SENTINEL } from '../configuration/constants';
import valueIsEmpty from './valueIsEmpty';

const { STRING, DATE, ATTRIBUTE, BOOLEAN, INTEGER } = FieldTypeEnum;

const valueFormatters = {
    [ATTRIBUTE.name]: (value, dependencies, format) =>
        format
            ? dependencies.formatAttributeById(value, false, true)
            : dependencies.formatAttributeById(value),
    [STRING.name]: identity,
    [DATE.name]: (value, dependencies, format) =>
        format ? dependencies.dateTimeFormatter.formatSummaryDate(value) : identity(value),
    [INTEGER.name]: identity,
    [BOOLEAN.name]: booleanToYesNo,
    [PERSON_PROFILE_HEIGHT]: formatHeight,
    [PERSON_PROFILE_HEIGHT_RANGE_MAX]: formatHeight,
    [PERSON_PROFILE_HEIGHT_RANGE_MIN]: formatHeight,
};

const getDisplayValueForValue = (model, fieldConfig, dependencies) => {
    const { prop, type, field, deserialize, format } = fieldConfig;
    const value = deserialize ? deserialize(model[prop]) : model[prop];

    if (valueIsEmpty(value, type)) {
        return NO_VALUE_SENTINEL;
    }

    const formatter = valueFormatters[field] || valueFormatters[type.name];
    return formatter(value, dependencies, format);
};

export default getDisplayValueForValue;

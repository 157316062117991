import { some } from 'lodash';
import { ConfiguredRuleEffectTypeEnum, ConfiguredRuleOperatorEnum } from '@mark43/rms-api';
import type { DragonConfigDataContextValue } from '../../../context/dragon-config-data';

const RUN_EFFECT = ConfiguredRuleOperatorEnum.RUN_EFFECT.name;
const IS_EMPTY = ConfiguredRuleOperatorEnum.IS_EMPTY.name;
const FORM_VALUE_OF = ConfiguredRuleOperatorEnum.FORM_VALUE_OF.name;
const ELEMENT_ERROR_MESSAGE = ConfiguredRuleEffectTypeEnum.ELEMENT_ERROR_MESSAGE.name;

/**
 * The first argument is the `UiConfiguration.id` for a field. Return whether there exists a rule which only requires
 * that field.
 *
 * This function only identifies 'simple' rules which consist of exactly 3 conditions. More complicated rules which
 * also require the field are not detected.
 */
export function simpleRequiredRuleExistsForField(
    fieldConfigurationId: string,
    configData: DragonConfigDataContextValue
): boolean {
    return some(configData.formRuleMap, (formRuleView) => {
        const effects = formRuleView.uiConfigurationAccessors.RULE_EFFECT;
        const conditions = formRuleView.uiConfigurationAccessors.RULE_CONDITION;

        if (effects?.length !== 1 || conditions?.length !== 1) {
            return false;
        }

        const {
            operator,
            childConfiguredRuleConditions: children,
        } = formRuleView.rootConfiguredRuleCondition;

        return (
            effects[0].uiConfigurationId === fieldConfigurationId &&
            effects[0].configuredRuleEffectType === ELEMENT_ERROR_MESSAGE &&
            conditions[0].uiConfigurationId === fieldConfigurationId &&
            operator === RUN_EFFECT &&
            children.length === 1 &&
            children[0].operator === IS_EMPTY &&
            children[0].childConfiguredRuleConditions.length === 1 &&
            children[0].childConfiguredRuleConditions[0].operator === FORM_VALUE_OF &&
            children[0].childConfiguredRuleConditions[0].uiConfigurationId === fieldConfigurationId
        );
    });
}

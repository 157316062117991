import React, { useCallback, createContext, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EntityTypeEnum, EntityTypeEnumType } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import formsRegistry from '../../../../core/formsRegistry';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';
import Modal from '../../../core/overlays/components/Modal';
import { currentCaseSelector } from '../state/ui';
import { createTopLevelFolder, createSubFolder } from '../state/data/folders';

import { getFolderContents } from '../state/data/folderContents';
import { CurrentFolderIdContext } from '../contexts';
import CreateFolderForm from './CreateFolderForm';

const overlayId = overlayIdEnum.CREATE_FOLDER_MODAL;
const strings = componentStrings.cases.core.CreateFolderModal;

type EntityTypeIdContextProps = {
    entityTypeId?: EntityTypeEnumType;
    setEntityTypeId?: React.Dispatch<React.SetStateAction<EntityTypeEnumType>>;
};

export const EntityTypeIdContext = createContext<EntityTypeIdContextProps>({});

const CreateFolderModal: React.FC = () => {
    const overlayStore = useOverlayStore();
    const currentCase = useSelector(currentCaseSelector);
    const dispatch = useDispatch();
    const { currentFolderId } = useContext(CurrentFolderIdContext);
    const { entityTypeId } = useContext(EntityTypeIdContext);

    const handleCancel = useCallback(() => {
        return overlayStore.close(overlayId);
    }, [overlayStore]);

    const handleSave = useCallback(async () => {
        const form = formsRegistry.get(formClientEnum.FORM_CREATE_FOLDER);
        const folderName = form?.getState().model.name;

        if (!folderName) {
            throw new Error('Folder name cannot be empty');
        }

        if (!entityTypeId) {
            return;
        }

        const folderData = {
            name: folderName.toString(),
            ownerId: currentCase.id,
            ownerTypeId: EntityTypeEnum.CASE.name,
            entityTypeId,
        };

        if (currentFolderId && currentCase) {
            await dispatch(createSubFolder(folderData, currentFolderId));
            dispatch(getFolderContents(currentFolderId, entityTypeId));
        } else {
            await dispatch(createTopLevelFolder(folderData));
        }
    }, [currentCase, dispatch, currentFolderId, entityTypeId]);

    return (
        <Modal
            id={overlayId}
            title={strings.title}
            okText={strings.okText}
            cancelText={strings.cancelText}
            onSave={handleSave}
            onCancel={handleCancel}
        >
            <CreateFolderForm />
        </Modal>
    );
};

export default CreateFolderModal;

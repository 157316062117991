import { reduce } from 'lodash';
import { Action } from 'redux';
import { createWithEntityItems } from 'nexus-mark43';
import { createWithRemove } from 'probes-mark43';

export const withEntityItems = createWithEntityItems({ key: 'items' });
export const withRemove = createWithRemove({ key: 'config' });

/**
 * Remove entities of multiple entity types at the same time.
 */
export function withRemoveMultiple(obj: { [key: string]: unknown }, action: Action) {
    return reduce(obj, (acc, value, key) => withRemove(key, value, acc), action);
}

const BASE_URL = '/cad/api';
const BASE_PATH = 'stations';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Cad Stations Resource',
        methods: {
            /**
             * Get all stations
             *
             * @return {Promise<StationView[]>}
             */
            getStations() {
                return req({
                    method: 'GET',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                });
            },
            /**
             * Upsert stations
             *
             * @return {Promise<StationView[]>}
             */
            upsertStation(stations) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: BASE_PATH,
                    data: stations,
                });
            },
        },
    });
}

/**
 * Resources for Stations. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

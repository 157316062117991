import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { BriefingView } from '@mark43/rms-api';

import { useResource } from '~/client-common/core/hooks/useResource';
import {
    briefingsByIdSelector,
    withBriefing,
} from '~/client-common/core/domain/briefings/state/data';

import Page from '../../core/components/Page';
import redirectToErrorPage from '../../core/utils/redirectToErrorPage';
import { SimpleLoading } from '../../../legacy-redux/components/core/Loading';

import briefingsResource from '../resources/briefingResource';
import BriefingComponent from './Briefing';

interface BriefingPageParams {
    briefingId: string;
}

type BriefingPageProps = RouteComponentProps<BriefingPageParams, BriefingPageParams>;

const BriefingPage: React.FC<BriefingPageProps> = ({
    params: { briefingId },
    location: { query },
}) => {
    const dispatch = useDispatch();

    const briefing = useSelector(briefingsByIdSelector)(briefingId);

    // Initial value should be persistent to prevent TinyEditor re-mounts on briefing save/autosave
    const initialBody = useRef<string>();

    const getBriefing = useCallback(() => briefingsResource.getBriefing(+briefingId), [briefingId]);
    const onGetBriefingSuccess = useCallback(
        (response: BriefingView) => {
            initialBody.current = response.body ?? '';
            dispatch(withBriefing(response));
        },
        [dispatch]
    );
    const onGetBriefingError = useCallback(() => dispatch(redirectToErrorPage()), [dispatch]);

    const { isLoading } = useResource(getBriefing, onGetBriefingSuccess, onGetBriefingError);

    const hasEditQueryParam = 'edit' in query;

    // initialBody check is needed to skip the unnecessary render that happens when you re-visit the page:
    // loading hasn't started yet but outdated briefing is already present in the store
    if (isLoading || !briefing || initialBody.current === undefined) {
        return <SimpleLoading />;
    }

    return (
        <Page>
            <BriefingComponent
                briefing={briefing}
                initialBody={initialBody.current}
                hasEditQueryParam={hasEditQueryParam}
            />
        </Page>
    );
};

export default BriefingPage;

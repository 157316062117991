import { createSelector } from 'reselect';
import { UcrSummaryCodeEnum } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'ucrSummaryOffenseCodes';

type UcrSummaryCode = typeof UcrSummaryCodeEnum[keyof typeof UcrSummaryCodeEnum];

const ucrSummaryOffenseCodesModule = createNormalizedModule<UcrSummaryCode>({
    type: NEXUS_STATE_PROP,
    key: 'code',
});

// actions

// selectors
export const ucrSummaryOffenseCodesSelector =
    ucrSummaryOffenseCodesModule.selectors.entitiesSelector;

/**
 * Get a complete UCR Summary Offense Code by its code.
 */
export const ucrSummaryOffenseCodeByCodeSelector = createSelector(
    ucrSummaryOffenseCodesSelector,
    (ucrSummaryOffenseCodes) => (code: string) => ucrSummaryOffenseCodes[code]
);

// reducer
export default ucrSummaryOffenseCodesModule.reducerConfig;

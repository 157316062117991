import React from 'react';
import { map } from 'lodash';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';

export default function LegacyEntityDetailSummaryRow({ legacyEntityDetails = [] }) {
    if (!legacyEntityDetails.length) {
        return null;
    }
    const detailRows = map(legacyEntityDetails, (detail, i) => {
        const { detailKey, detailValue } = detail;
        return (
            <SummaryRow key={i} label={detailKey}>
                {detailValue}
            </SummaryRow>
        );
    });
    return (
        <SummaryRow label="Legacy Data">
            <SummaryList labelWidth={120} contentWidth={258}>
                {detailRows}
            </SummaryList>
        </SummaryRow>
    );
}

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy, partition } from 'lodash';
import { EntityPermissionView, EntityTypeEnumType, RoleTypeEnum } from '@mark43/rms-api';

import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';

import { RmsDispatch } from '../../../../core/typings/redux';
import { currentUserDepartmentIdSelector } from '../../current-user/state/ui';
import { useFormGetter } from '../../forms/hooks/useFormGetter';
import { useOverlayResourceOnOpen } from '../../overlays/hooks/useOverlayResource';
import entityPermissionsResource from '../resources/entityPermissionsResource';
import { getFormNameForEntityType, rankOperationType, stashPermissions } from '../state/ui';

type PermissionFormName = ReturnType<typeof getFormNameForEntityType>;
interface UseLoadPermissionsForOverlayProps {
    entityId: number;
    entityType: EntityTypeEnumType;
    overlayId: string;
    overrideRoleRestrictions?: boolean;
}

export function useSetPermissionsForm() {
    const { getForm } = useFormGetter();

    const consortiumDepartmentLinksAvailable = useSelector(
        consortiumDepartmentLinksAvailableSelector
    );
    const currentUserDepartmentId = useSelector(currentUserDepartmentIdSelector);

    const setPermissionsForm = useCallback(
        (formName: PermissionFormName, permissions: EntityPermissionView[]) => {
            const form = getForm(formName);

            const sortedPermissions = orderBy(
                permissions,
                ({ operationType }) => rankOperationType(operationType),
                ['desc']
            );
            const [userSettings, roleSettings] = partition(
                sortedPermissions,
                ({ role }) => role.roleType === RoleTypeEnum.USER.name
            );
            const [internalRoleSettings, externalRoleSettings] = partition(
                roleSettings,
                ({ role }) => role.departmentId === currentUserDepartmentId
            );

            form?.transaction(() => {
                form.set('roleSettings', internalRoleSettings);
                form.set('userSettings', userSettings);
                if (consortiumDepartmentLinksAvailable) {
                    form.set('externalAgencySettings', externalRoleSettings);
                }
            });
        },
        [consortiumDepartmentLinksAvailable, currentUserDepartmentId, getForm]
    );

    return setPermissionsForm;
}

/**
 * Load permissions for Modal and initialize form.
 */
export function useLoadPermissionsForOverlay(props: UseLoadPermissionsForOverlayProps) {
    const { entityId, entityType, overlayId, overrideRoleRestrictions } = props;

    const formName = getFormNameForEntityType(entityType);

    const dispatch = useDispatch<RmsDispatch>();
    const resource = useCallback(() => {
        return entityPermissionsResource.getPermissions(
            entityType,
            entityId,
            overrideRoleRestrictions
        );
    }, [entityId, entityType, overrideRoleRestrictions]);

    const setPermissionsForm = useSetPermissionsForm();
    const onSuccess = useCallback(
        (permissions: EntityPermissionView[]) => {
            dispatch(stashPermissions(permissions));
            setPermissionsForm(formName, permissions);
        },
        [dispatch, formName, setPermissionsForm]
    );

    useOverlayResourceOnOpen<EntityPermissionView[]>({ id: overlayId, onSuccess, resource });
}

import { AbilityRoleLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getAbilityRoleLinksResource from '../../resources/abilityRoleLinksResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'abilityRoleLinks';

// TODO-CLIENT-COMMON-REFACTOR RMS should start moving to this file
// TODO PRINTING-REFACTOR TODO-PRINTING-REFACTOR
const abilityRoleLinksModule = createLinkModule<AbilityRoleLink>({
    type: NEXUS_STATE_PROP,
    keys: ['abilityId', 'roleId'],
});

const LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_START =
    'ability-role-links/LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_START';
const LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_SUCCESS =
    'ability-role-links/LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_SUCCESS';
const LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_FAILURE =
    'ability-role-links/LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_FAILURE';
const LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_START =
    'ability-role-links/LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_START';
const LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_SUCCESS =
    'ability-role-links/LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_SUCCESS';
const LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_FAILURE =
    'ability-role-links/LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_FAILURE';

const UPSERT_ABILITY_ROLE_LINKS_START = 'ability-role-links/UPSERT_ABILITY_ROLE_LINKS_START';
const UPSERT_ABILITY_ROLE_LINKS_SUCCESS = 'ability-role-links/UPSERT_ABILITY_ROLE_LINKS_SUCCESS';
const UPSERT_ABILITY_ROLE_LINKS_FAILURE = 'ability-role-links/UPSERT_ABILITY_ROLE_LINKS_FAILURE';

function loadAbilityRoleLinksForRoleStart(roleId: number) {
    return {
        type: LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_START,
        payload: roleId,
    };
}

function loadAbilityRoleLinksForRoleSuccess(abilityRoleLinks: AbilityRoleLink[]) {
    return {
        type: LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_SUCCESS,
        payload: abilityRoleLinks,
    };
}

function loadAbilityRoleLinksForRoleFailure(message: string) {
    return {
        type: LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_FAILURE,
        payload: message,
    };
}

function loadAbilityRoleLinksForRoleBatchStart(roleIds: number[]) {
    return {
        type: LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_START,
        payload: roleIds,
    };
}
function loadAbilityRoleLinksForRoleBatchSuccess(abilityRoleLinksForBatch: AbilityRoleLink[]) {
    return {
        type: LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_SUCCESS,
        payload: abilityRoleLinksForBatch,
    };
}
function loadAbilityRoleLinksForRoleBatchFailure(message: string) {
    return {
        type: LOAD_ABILITY_ROLE_LINKS_FOR_ROLE_BATCH_FAILURE,
        payload: message,
    };
}

function upsertAbilityRoleLinksStart(abilityRoleLinks: AbilityRoleLink[]) {
    return {
        type: UPSERT_ABILITY_ROLE_LINKS_START,
        payload: abilityRoleLinks,
    };
}

function upsertAbilityRoleLinksSuccess(abilityRoleLinks: AbilityRoleLink[]) {
    return {
        type: UPSERT_ABILITY_ROLE_LINKS_SUCCESS,
        payload: abilityRoleLinks,
    };
}

function upsertAbilityRoleLinksFailure(message: string) {
    return {
        type: UPSERT_ABILITY_ROLE_LINKS_FAILURE,
        payload: message,
    };
}

export function loadAbilityRoleLinksForRole(
    roleId: number
): ClientCommonAction<Promise<AbilityRoleLink[]>> {
    return (dispatch) => {
        dispatch(loadAbilityRoleLinksForRoleStart(roleId));
        const resource = getAbilityRoleLinksResource();
        return resource
            .loadAbilityRoleLinksForRole(roleId)
            .then((abilityRoleLinks: AbilityRoleLink[]) => {
                dispatch(loadAbilityRoleLinksForRoleSuccess(abilityRoleLinks));
                dispatch(storeAbilityRoleLinks(abilityRoleLinks));
                return abilityRoleLinks;
            })
            .catch((err: Error) => {
                dispatch(loadAbilityRoleLinksForRoleFailure(err.message));
                throw err;
            });
    };
}

export function loadAbilityRoleLinksForRoleBatch(
    roleIds: number[]
): ClientCommonAction<Promise<AbilityRoleLink[]>> {
    return (dispatch) => {
        dispatch(loadAbilityRoleLinksForRoleBatchStart(roleIds));
        const resource = getAbilityRoleLinksResource();
        return resource
            .loadAbilityRoleLinksForRoleBatch(roleIds)
            .then((abilityRoleLinksForBatch: AbilityRoleLink[]) => {
                dispatch(loadAbilityRoleLinksForRoleBatchSuccess(abilityRoleLinksForBatch));
                dispatch(storeAbilityRoleLinks(abilityRoleLinksForBatch));
                return abilityRoleLinksForBatch;
            })
            .catch((err: Error) => {
                dispatch(loadAbilityRoleLinksForRoleBatchFailure(err.message));
                throw err;
            });
    };
}

export function upsertAbilityRoleLinks(
    abilityRoleLinks: AbilityRoleLink[]
): ClientCommonAction<Promise<AbilityRoleLink[]>> {
    return (dispatch) => {
        dispatch(upsertAbilityRoleLinksStart(abilityRoleLinks));
        const resource = getAbilityRoleLinksResource();
        return resource
            .upsertAbilityRoleLinks(abilityRoleLinks)
            .then((abilityRoleLinks: AbilityRoleLink[]) => {
                dispatch(upsertAbilityRoleLinksSuccess(abilityRoleLinks));
                dispatch(storeAbilityRoleLinks(abilityRoleLinks));
                return abilityRoleLinks;
            })
            .catch((err: Error) => {
                dispatch(upsertAbilityRoleLinksFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS
const storeAbilityRoleLinks = abilityRoleLinksModule.actionCreators.storeLinks;

// SELECTORS
export const abilityRoleLinksSelector = abilityRoleLinksModule.selectors.linksSelector;
export const abilityRoleLinksWhereSelector = abilityRoleLinksModule.selectors.linksWhereSelector;

// REDUCER
export default abilityRoleLinksModule.reducerConfig;

import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get } from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Observer, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import { NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatAttributeWithOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { behavioralCrisisByReportIdSelector } from '~/client-common/core/domain/behavioral-crises/state/data';
import { formatNameReportLinkTypeIdSelector } from '~/client-common/core/domain/name-report-links/state/ui';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import { createNItemsAdder, createNItemsRemover } from '../../utils/nItemsHelpers';
import withCard from '../../utils/withCard';
import {
    formName,
    BEHAVIORAL_CRISIS_SUBJECT_PATH,
    BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH,
    BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID_PATH,
    BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_OTHER_PATH,
    BEHAVIORAL_CRISIS_LOCATION_PATH,
    BEHAVIORAL_CRISIS_LOCATION_DESCRIPTION_FULL_PATH,
    BEHAVIORAL_CRISIS_LOCATION_POSITION_ATTR_ID_FULL_PATH,
    getBehavioralCrisisForm,
    createBehavioralCrisisForm,
    buildBehavioralCrisisCardFormModel,
    findIndexOfOtherNameInBehavioralCrisis,
} from '../../state/forms/behavioralCrisisForm';
import { registerForm } from '../../state/ui';
import behavioralCrisisCard from '../../state/ui/behavioralCrisisCard';
import testIds from '../../../../../core/testIds';
import { registerCard } from '../../utils/cardsRegistry';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { LocationSummaryViewWrapperWithFormFields } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapperWithFormFields';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import BehavioralCrisisCardSummary from './BehavioralCrisisCardSummary';
import BehavioralCrisisCardForm from './BehavioralCrisisCardForm';

class BehavioralCrisisCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildBehavioralCrisisCardFormModel,
            currentReportId: reportId,
        } = this.props;

        const form = createBehavioralCrisisForm({
            initialState: buildBehavioralCrisisCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        registerCard({
            cardModule: behavioralCrisisCard,
            onSave: this.onSave,
        });
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    handleBehavioralCrisisSubjectAdd = (nameReportLink) => {
        const form = getBehavioralCrisisForm();
        form.set(BEHAVIORAL_CRISIS_SUBJECT_PATH, get(nameReportLink, 'linkType'));
    };

    handleBehavioralCrisisSubjectRemove = () => {
        const form = getBehavioralCrisisForm();
        form.set(BEHAVIORAL_CRISIS_SUBJECT_PATH, undefined);
    };

    handleOtherNameInBehavioralCrisisAdd = (nameReportLink) => {
        return createNItemsAdder({
            getForm: getBehavioralCrisisForm,
            path: BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH,
            defaults: {
                [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID_PATH]: undefined,
                [BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_OTHER_PATH]: undefined,
            },
        })(nameReportLink);
    };

    handleOtherNameInBehavioralCrisisRemove = (nameReportLink) => {
        const { nameId } = nameReportLink;
        return createNItemsRemover({
            getForm: getBehavioralCrisisForm,
            path: BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH,
        })({ nameId });
    };

    handleBehavioralCrisisLocationAdd = (location, locationEntityLinkModel) => {
        const form = getBehavioralCrisisForm();
        form.set(BEHAVIORAL_CRISIS_LOCATION_PATH, locationEntityLinkModel);
    };

    handleBehavioralCrisisLocationRemove = () => {
        const form = getBehavioralCrisisForm();
        form.set(BEHAVIORAL_CRISIS_LOCATION_PATH, undefined);
    };

    onEdit = () => {
        this.props.editCallback(() => this.props.onEdit());
    };

    onSaveProgress = () => {
        const form = getBehavioralCrisisForm();
        return this.props.onSaveProgress(form, {});
    };

    onSave = () => {
        const form = getBehavioralCrisisForm();
        return this.props.onSave(form);
    };

    render() {
        const {
            card = {},
            currentReportId: reportId,
            behavioralCrisisByReportId,
            formatNameReportLinkTypeId,
            formatAttributeWithOther,
            reportById,
            currentReportCardUITitleByType,
        } = this.props;
        const {
            anchor,
            summaryMode: cardSummaryMode,
            errorMessages,
            canEditReportCardStatus,
            saving,
        } = card;

        const customCardTitle = currentReportCardUITitleByType(reportCardEnum.BEHAVIORAL_CRISIS.id);
        const behavioralCrisis = behavioralCrisisByReportId(reportId);
        const behavioralCrisisId = behavioralCrisis.id;
        const renForRecents = get(reportById(reportId), 'reportingEventNumber');
        const behavioralCrisisSubjectTitle = formatNameReportLinkTypeId(
            LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS
        );
        const otherNameInBehavioralCrisisTitle = formatNameReportLinkTypeId(
            LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS
        );

        return (
            <Card
                testId={testIds.BEHAVIORAL_CRISIS_CARD}
                className={anchor}
                anchor={anchor}
                title={customCardTitle}
                renderContent={(summaryMode) => (
                    <>
                        {summaryMode ? (
                            <BehavioralCrisisCardSummary reportId={reportId} />
                        ) : (
                            <BehavioralCrisisCardForm />
                        )}
                        <CardSection
                            testId={testIds.SUBJECT_IN_BEHAVIORAL_CRISIS_SECTION}
                            fieldName={
                                fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_BEHAVIORAL_CRISIS_LINK_TYPE
                            }
                        >
                            <NameSummaryViewWrapper
                                renForRecents={renForRecents}
                                summaryMode={summaryMode}
                                addNameButtonText={behavioralCrisisSubjectTitle}
                                reportId={reportId}
                                contextType={EntityTypeEnum.REPORT.name}
                                contextId={reportId}
                                parentEntityType={EntityTypeEnum.REPORT.name}
                                parentId={reportId}
                                linkType={LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS}
                                show={{ people: true }}
                                limitToOne={true}
                                onAddSuccess={this.handleBehavioralCrisisSubjectAdd}
                                onRemoveSuccess={this.handleBehavioralCrisisSubjectRemove}
                            />
                        </CardSection>
                        <ArbiterForm
                            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                            name={formName}
                            context={formName}
                            render={() => {
                                return (
                                    <>
                                        <CardSection
                                            testId={testIds.OTHER_NAME_IN_BEHAVIORAL_CRISIS_SECTION}
                                            fieldName={
                                                fields.NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_BEHAVIORAL_CRISIS_LINK_TYPE
                                            }
                                        >
                                            <Observer
                                                subscriptions={{
                                                    otherNamesInBehavioralCrisis: BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH,
                                                }}
                                                formName={formName}
                                                render={() => {
                                                    return (
                                                        <NameSummaryViewWrapper
                                                            renForRecents={renForRecents}
                                                            summaryMode={summaryMode}
                                                            addNameButtonText={
                                                                otherNameInBehavioralCrisisTitle
                                                            }
                                                            reportId={reportId}
                                                            contextType={EntityTypeEnum.REPORT.name}
                                                            contextId={reportId}
                                                            parentEntityType={
                                                                EntityTypeEnum.REPORT.name
                                                            }
                                                            parentId={reportId}
                                                            linkType={
                                                                LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS
                                                            }
                                                            show={{ people: true }}
                                                            onAddSuccess={
                                                                this
                                                                    .handleOtherNameInBehavioralCrisisAdd
                                                            }
                                                            onRemoveSuccess={
                                                                this
                                                                    .handleOtherNameInBehavioralCrisisRemove
                                                            }
                                                            renderAdditionalItem={({
                                                                nameLink,
                                                            }) => {
                                                                const {
                                                                    nameId,
                                                                    subjectTypeAttrId,
                                                                    subjectTypeOther,
                                                                } = nameLink;
                                                                const index = findIndexOfOtherNameInBehavioralCrisis(
                                                                    { nameId }
                                                                );

                                                                // Only render additional fields if the name currently exists on the form.
                                                                if (index < 0) {
                                                                    return null;
                                                                }

                                                                const completeSubjectTypeAttrIdPath = `${BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH}[${index}].${BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID_PATH}`;
                                                                const completeSubjectTypeOtherPath = `${BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_PATH}[${index}].${BEHAVIORAL_CRISIS_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_OTHER_PATH}`;

                                                                return summaryMode ? (
                                                                    <SummaryList
                                                                        labelWidth={100}
                                                                        contentWidth={400}
                                                                    >
                                                                        <SummaryRow
                                                                            fieldName={
                                                                                NAME_REPORT_LINK_LINK_TYPE_OTHER_NAME_IN_BEHAVIORAL_CRISIS_SUBJECT_TYPE_ATTR_ID
                                                                            }
                                                                        >
                                                                            {formatAttributeWithOther(
                                                                                {
                                                                                    attributeId: subjectTypeAttrId,
                                                                                    other: subjectTypeOther,
                                                                                }
                                                                            )}
                                                                        </SummaryRow>
                                                                    </SummaryList>
                                                                ) : (
                                                                    <>
                                                                        <Row>
                                                                            <ArbiterMFTAttributeSelect
                                                                                path={
                                                                                    completeSubjectTypeAttrIdPath
                                                                                }
                                                                                length="lg"
                                                                                attributeType={
                                                                                    AttributeTypeEnum
                                                                                        .FIELD_CONTACT_SUBJECT_TYPE
                                                                                        .name
                                                                                }
                                                                            />
                                                                        </Row>
                                                                        <Row>
                                                                            <IndentedFields>
                                                                                <ArbiterMFTText
                                                                                    path={
                                                                                        completeSubjectTypeOtherPath
                                                                                    }
                                                                                    length="lg"
                                                                                />
                                                                            </IndentedFields>
                                                                        </Row>
                                                                    </>
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </CardSection>
                                        <CardSection
                                            testId={testIds.BEHAVIORAL_CRISIS_LOCATION_SECTION}
                                            fieldName={
                                                fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_BEHAVIORAL_CRISIS_LOCATION_ID
                                            }
                                        >
                                            <LocationSummaryViewWrapperWithFormFields
                                                summaryMode={summaryMode}
                                                onLocationAdd={
                                                    this.handleBehavioralCrisisLocationAdd
                                                }
                                                onLocationRemove={
                                                    this.handleBehavioralCrisisLocationRemove
                                                }
                                                entityType={EntityTypeEnum.BEHAVIORAL_CRISIS.name}
                                                entityId={behavioralCrisisId}
                                                linkType={
                                                    LinkTypesEnum.LOCATION_OF_BEHAVIORAL_CRISIS
                                                }
                                                locationDescriptionPath={() =>
                                                    BEHAVIORAL_CRISIS_LOCATION_DESCRIPTION_FULL_PATH
                                                }
                                                locationPositionAttrIdPath={() =>
                                                    BEHAVIORAL_CRISIS_LOCATION_POSITION_ATTR_ID_FULL_PATH
                                                }
                                            />
                                        </CardSection>
                                    </>
                                );
                            }}
                        />
                    </>
                )}
                onEdit={this.onEdit}
                errors={errorMessages}
                summaryMode={cardSummaryMode}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                saving={saving}
                onSave={this.onSaveProgress}
            />
        );
    }
}

class BehavioralCrisisCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => (
                    <BehavioralCrisisCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    behavioralCrisisByReportId: behavioralCrisisByReportIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    formatNameReportLinkTypeId: formatNameReportLinkTypeIdSelector,
    formatAttributeWithOther: formatAttributeWithOtherSelector,
    reportById: reportByIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    buildBehavioralCrisisCardFormModel,
};

export default compose(
    withCard(behavioralCrisisCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(BehavioralCrisisCardWrapper);

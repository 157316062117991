import React from 'react';
import { createField, createFormConfiguration, Form, lifecycleOptions } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { MFTText } from '../../../core/forms/components/Text';
import Row from '../../../core/components/Row';

const strings = componentStrings.cases.core.UpdateFolderForm;

const updateFolderFormConfiguration = createFormConfiguration({
    name: createField<string>({}),
});

const UpdateFolderForm: React.FC = () => {
    return (
        <Form
            lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
            name={formClientEnum.FORM_UPDATE_FOLDER}
            configuration={updateFolderFormConfiguration}
            render={() => (
                <Row>
                    <MFTText maxLength={64} label={strings.label} path="name" />
                </Row>
            )}
        />
    );
};

export default UpdateFolderForm;

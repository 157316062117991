import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { caseReviewViewModelsForCaseIdSelector } from '~/client-common/core/domain/case-reviews/state/data';
import { openBox } from '../../../../../legacy-redux/actions/boxActions';
import caseReviewForm from '../form/caseReviewForm';
import { currentCaseIdSelector } from '../../../core/state/ui';

const SET_IS_REJECTION = 'case-reviews/SET_IS_REJECTION';

export function setIsRejection(isRejection) {
    return {
        type: SET_IS_REJECTION,
        payload: isRejection,
    };
}

export function openCaseReviewsSidePanel() {
    return (dispatch) => {
        dispatch(caseReviewForm.actionCreators.reset());
        dispatch(openBox({ name: boxEnum.CASE_REVIEW_SIDE_PANEL }));
    };
}

export default function currentCaseReviewUiReducer(
    state = {
        isRejection: false,
    },
    action
) {
    switch (action.type) {
        case SET_IS_REJECTION:
            return {
                ...state,
                isRejection: action.payload,
            };
        default:
            return state;
    }
}

const caseReviewUiSelector = (state) => state.ui.cases.caseReview;

export const caseReviewInRejectionStateSelector = createSelector(
    caseReviewUiSelector,
    ({ isRejection }) => isRejection
);

export const caseReviewsForCurrentCaseViewModelSelector = createSelector(
    caseReviewViewModelsForCaseIdSelector,
    currentCaseIdSelector,
    (caseReviewViewModelsForCaseId, currentCaseId) => {
        return sortBy(caseReviewViewModelsForCaseId(currentCaseId), 'createdByUtc');
    }
);

import { get } from 'lodash';

import { evidenceDepartmentConfigSelector } from '~/client-common/core/domain/evidence-department-config/state/data';

/**
 * Get the value of a setting in this tenant's EvidenceDepartmentConfig. Which setting to get is
 *   determined by the RuleConditionArg.
 */
export function getEvidenceDepartmentConfig(getState) {
    return function (value, { setting } = {}) {
        const state = getState();
        return get(evidenceDepartmentConfigSelector(state), setting);
    };
}

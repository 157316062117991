import { get } from 'lodash';
import { createField, createFormConfiguration, lifecycleOptions } from 'markformythree';
import React from 'react';
import { useSelector } from 'react-redux';
import { RefContextEnum } from '@mark43/rms-api';
import {
    DELETE_ITEMS_REQUEST_REASON_FOR_DELETION,
    DISPLAY_ONLY_CUSTODY_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields, { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import { initialState } from '../../state/forms/deleteEvidenceItemForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';

const strings = componentStrings.reports.custodialPropertySummary.DeleteEvidenceItemForm;

const formConfiguration = createFormConfiguration({
    itemRequestReasonForDeletion: createField<string>({
        fieldName: DELETE_ITEMS_REQUEST_REASON_FOR_DELETION,
    }),
});

export type FormDeleteEvidenceItemFormConfiguration = typeof formConfiguration;

const renderForm = () => <ArbiterMFTTextArea path="itemRequestReasonForDeletion" rows={6} />;

const DeleteEvidenceItemForm: React.FC = () => {
    const reportDefinition = useSelector(
        custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector
    );
    const evidenceModuleName = useEvidenceModuleName();
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];
    return (
        <div>
            <p>
                {strings.confirmDeleteEvidenceItem({
                    reportDefinitionName: get(reportDefinition, 'name'),
                    evidenceModuleName,
                    custody: custodyLabel,
                })}
            </p>
            <ArbiterForm
                context={RefContextEnum.FORM_DELETE_EVIDENCE_ITEM.name}
                configuration={formConfiguration}
                initialState={initialState}
                name={RefContextEnum.FORM_DELETE_EVIDENCE_ITEM.name}
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                render={renderForm}
            />
        </div>
    );
};

export default DeleteEvidenceItemForm;

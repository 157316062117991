import {
    OrganizationProfile,
    HydratedOrganization,
    EntityTypeEnumType,
    RmsHydratedOrganization,
    EntityTypeEnum,
} from '@mark43/rms-api';
import { Action } from 'redux';
import { concat, flatMap, forEach, reduce } from 'lodash';
import { withRemoveMultiple, withEntityItems } from '../../../../utils/nexusHelpers';

import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '../../../locations/state/data';
import { NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP } from '../../../attachments/state/data';
import { NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP } from '../../../name-report-links/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import { NEXUS_STATE_PROP as NAME_IDENTIFIERS_NEXUS_STATE_PROP } from '../../../name-identifiers/state/data';
import { NEXUS_STATE_PROP as NAME_EMAILS_NEXUS_STATE_PROP } from '../../../name-emails/state/data';
import { NEXUS_STATE_PROP as NAME_PHONES_NEXUS_STATE_PROP } from '../../../name-phones/state/data';
import { convertLocationBundlesToLocationViews } from '../../../locations/utils/locationHelpers';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getOrganizationProfileResource from '../../resources/organizationProfileResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'organizationProfiles';

const organizationProfilesModule = createNormalizedModule<OrganizationProfile>({
    type: NEXUS_STATE_PROP,
});

const LOAD_ORGANIZATION_PROFILE_START = 'organization-profiles/LOAD_ORGANIZATION_PROFILE_START';
const LOAD_ORGANIZATION_PROFILE_SUCCESS = 'organization-profiles/LOAD_ORGANIZATION_PROFILE_SUCCESS';
const LOAD_ORGANIZATION_PROFILE_FAILURE = 'organization-profiles/LOAD_ORGANIZATION_PROFILE_FAILURE';

export const SAVE_ORGANIZATION_PROFILE_SUCCESS =
    'organization-profiles/SAVE_ORGANIZATION_PROFILE_SUCCESS';

function loadOrganizationProfileStart(organizationProfileId: number) {
    return {
        type: LOAD_ORGANIZATION_PROFILE_START,
        payload: organizationProfileId,
    };
}

function loadOrganizationProfileSuccess(organizationProfile: RmsHydratedOrganization) {
    return {
        type: LOAD_ORGANIZATION_PROFILE_SUCCESS,
        payload: organizationProfile,
    };
}

function loadOrganizationProfileFailure(errorMessage: string) {
    return {
        type: LOAD_ORGANIZATION_PROFILE_FAILURE,
        payload: errorMessage,
    };
}

export function augmentActionWithNexusHydratedOrganization(
    hydratedOrganization: HydratedOrganization,
    action: Action
) {
    const entityItems = {
        [ATTACHMENTS_NEXUS_STATE_PROP]: hydratedOrganization.attachments,
        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: flatMap(
            hydratedOrganization.locations,
            'entityLinks'
        ),
        [LOCATIONS_NEXUS_STATE_PROP]: convertLocationBundlesToLocationViews(
            hydratedOrganization.locations
        ),
        [NAME_EMAILS_NEXUS_STATE_PROP]: hydratedOrganization.emails,
        [NAME_IDENTIFIERS_NEXUS_STATE_PROP]: hydratedOrganization.identifiers,
        [NAME_PHONES_NEXUS_STATE_PROP]: hydratedOrganization.phones,
        [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: hydratedOrganization.reportLinks,
        [NEXUS_STATE_PROP]: hydratedOrganization.organizationProfiles,
    };

    // we remove all non-normalized values for the given profile id,
    // so we don't end up with duplicate values.
    // we can do this because the backend sends back values for all entities
    // related to the organization profile for the given report
    const nameId = { nameId: hydratedOrganization.id };
    const entityTypeAndId = {
        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
        entityId: hydratedOrganization.id,
    };
    const itemsToRemove = {
        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: entityTypeAndId,
        [NAME_EMAILS_NEXUS_STATE_PROP]: nameId,
        [NAME_IDENTIFIERS_NEXUS_STATE_PROP]: nameId,
        [NAME_PHONES_NEXUS_STATE_PROP]: nameId,
        [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameId,
    };
    return withRemoveMultiple(itemsToRemove, withEntityItems(entityItems, action));
}

export function augmentActionWithNexusHydratedOrganizations(
    actionToAugment: Action,
    rmsHydratedOrganizationProfiles: HydratedOrganization[]
): ClientCommonAction<RmsHydratedOrganization[]> {
    return (dispatch, getState, dependencies) => {
        const nexusConfigForHydratedOrganizations = reduce<
            HydratedOrganization,
            {
                withEntityItems: Record<string, unknown[]>;
                withRemove: Record<string, unknown[]>;
            }
        >(
            rmsHydratedOrganizationProfiles,
            (acc, result) => {
                const locationEntityLinks = flatMap(result.locations, 'entityLinks');
                const hydratedOrganizationId = result.id;
                forEach(
                    {
                        [ATTACHMENTS_NEXUS_STATE_PROP]: result.attachments,
                        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                        [LOCATIONS_NEXUS_STATE_PROP]: convertLocationBundlesToLocationViews(
                            result.locations
                        ),
                        [NAME_EMAILS_NEXUS_STATE_PROP]: result.emails,
                        [NAME_IDENTIFIERS_NEXUS_STATE_PROP]: result.identifiers,
                        [NAME_PHONES_NEXUS_STATE_PROP]: result.phones,
                        [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: result.reportLinks,
                        [NEXUS_STATE_PROP]: result.organizationProfiles,
                    },
                    (items, entityType) => {
                        if (!acc.withEntityItems[entityType]) {
                            acc.withEntityItems[entityType] = [];
                        }
                        acc.withEntityItems[entityType] = concat(
                            acc.withEntityItems[entityType],
                            items
                        );
                    }
                );

                // we remove all non-normalized values for the given profile id,
                // so we don't end up with duplicate values.
                // we can do this because the backend sends back values for all entities
                // related to the person profile for the given report
                const nameId = { nameId: hydratedOrganizationId };
                const entityTypeAndId = {
                    entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                    entityId: hydratedOrganizationId,
                };
                forEach(
                    {
                        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: entityTypeAndId,
                        [NAME_EMAILS_NEXUS_STATE_PROP]: nameId,
                        [NAME_IDENTIFIERS_NEXUS_STATE_PROP]: nameId,
                        [NAME_PHONES_NEXUS_STATE_PROP]: nameId,
                        [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameId,
                    },
                    (predicate, entityType) => {
                        if (!acc.withRemove[entityType]) {
                            acc.withRemove[entityType] = [];
                        }
                        acc.withRemove[entityType] = concat(acc.withRemove[entityType], predicate);
                    }
                );
                return acc;
            },
            { withEntityItems: {}, withRemove: {} }
        );

        dispatch(
            dependencies.nexus.withEntityItems(
                nexusConfigForHydratedOrganizations.withEntityItems,
                reduce(
                    nexusConfigForHydratedOrganizations.withRemove,
                    (acc, predicate, entityType) =>
                        dependencies.nexus.withRemove(entityType, predicate, acc),
                    actionToAugment
                )
            )
        );

        return rmsHydratedOrganizationProfiles;
    };
}

export function getContextedOrMasterOrganizationProfile({
    masterOrContextedProfileId,
    ownerType,
    ownerId,
}: {
    masterOrContextedProfileId: number;
    ownerType: EntityTypeEnumType;
    ownerId: number;
}): ClientCommonAction<Promise<RmsHydratedOrganization>> {
    return (dispatch) => {
        const organizationProfileResource = getOrganizationProfileResource();
        dispatch(loadOrganizationProfileStart(masterOrContextedProfileId));
        return organizationProfileResource
            .getContextedOrMasterOrganizationProfile({
                masterOrContextedProfileId,
                ownerType,
                ownerId,
            })
            .then((hydratedOrganization: RmsHydratedOrganization) => {
                dispatch(
                    augmentActionWithNexusHydratedOrganization(
                        hydratedOrganization,
                        loadOrganizationProfileSuccess(hydratedOrganization)
                    )
                );
                return hydratedOrganization;
            })
            .catch((err: Error) => {
                dispatch(loadOrganizationProfileFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS
export const storeOrganizationProfiles = organizationProfilesModule.actionCreators.storeEntities;

// SELECTORS
export const organizationProfilesSelector = organizationProfilesModule.selectors.entitiesSelector;
export const organizationProfileByIdSelector =
    organizationProfilesModule.selectors.entityByIdSelector;
export const organizationProfilesWhereSelector =
    organizationProfilesModule.selectors.entitiesWhereSelector;

// REDUCER
export default organizationProfilesModule.reducerConfig;

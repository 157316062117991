// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'reports';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report Attributes Resource',
        methods: {
            updateReportAttributes(reportId, reportAttributes, attributeType) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${reportId}/attributes`,
                    data: reportAttributes,
                    params: attributeType
                        ? {
                              type: attributeType,
                          }
                        : undefined,
                });
            },
        },
    });
}

/**
 * Resources for ReportAttributes. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

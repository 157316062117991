import { compact, filter, flatten, includes, map, pick, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import {
    ClientApprovalStatusEnum,
    CompleteClientReportView,
    EntityTypeEnum,
} from '@mark43/rms-api';

import { NEXUS_STATE_PROP as REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offense-codes/state/data';
import {
    NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP,
    arrestsSelector,
} from '~/client-common/core/domain/arrests/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as FIELD_CONTACTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/field-contacts/state/data';
import { NEXUS_STATE_PROP as IMPOUNDS_NEXUS_STATE_PROP } from '~/client-common/core/domain/impounds/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/location-entity-links/state/data';
import { NEXUS_STATE_PROP as LOCATIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/locations/state/data';
import { NEXUS_STATE_PROP as MINI_USERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/mini-users/state/data';
import { NEXUS_STATE_PROP as MISSING_PERSONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/missing-persons/state/data';
import { NEXUS_STATE_PROP as NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP } from '~/client-common/core/domain/narrative-autosave/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { NEXUS_STATE_PROP as USE_OF_FORCES_NEXUS_STATE_PROP } from '~/client-common/core/domain/use-of-forces/state/data';
import { NEXUS_STATE_PROP as USER_PROFILES_NEXUS_STATE_PROP } from '~/client-common/core/domain/user-profiles/state/data';
import { NEXUS_STATE_PROP as REPORT_INLINE_COMMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/inline-report-comments/state/data';
import { NEXUS_STATE_PROP as VEHICLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/vehicles/state/data';
import { NEXUS_STATE_PROP as ITEM_PROFILE_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-profiles/state/data';
import { NEXUS_STATE_PROP as PROPERTY_STATUS_NEXUS_STATE_PROP } from '~/client-common/core/domain/property-statuses/state/data';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { userProfileToMiniUser } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import {
    NEXUS_STATE_PROP as REPORT_DEFINITIONS_NEXUS_STATE_PROP,
    isOffenseReportSelector,
} from '~/client-common/core/domain/report-definitions/state/data';
import { augmentOffenseCodeWithOffenseCodeView } from '~/client-common/helpers/offenseCodesHelpers';

import { RmsAction } from '../../../../../core/typings/redux';
import reportsResource from '../../resources/reportsResource';
import reportsCreateResource from '../../resources/reportsCreateResource';
import { arrestReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import {
    requestRecentPersonsOrOrganizations,
    requestRecentLocationsForReports,
} from '../../../../../legacy-redux/actions/recentEntitiesActions';
import {
    storeLinkedReportIds,
    setLastCreatedReportId,
} from '../../../../../legacy-redux/actions/reportsActions';
import { storeEmbeddedDragonDataAction } from '../../../../dragon/dragonRedux';

import {
    currentReportSelector,
    currentReportIdSelector,
    approvalStatusSelector,
    reportSubmissionAuthorsSelector,
    linkedReportShortTitlesSelector,
    lastCreatedReportIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    currentUserIdSelector,
    currentUserDepartmentAgencyIdSelector,
} from '../../../../core/current-user/state/ui';
import { logError } from '../../../../../core/logging';

import { dataNexusEntityKeys } from '../data/reportRouting';

import attachmentsCard from '../../../../warrants/warrant/state/cards/attachmentsCard';
import arrestCard from './arrestCard';
import bookingCard from './bookingCard';
import courtCaseCard from './courtCaseCard';
import summaryNarrativeCard from './summaryNarrativeCard';
import narrativeCard from './narrativeCard';
import {
    deleteEmbeddedReport,
    loadEmbeddedReportStart,
    loadEmbeddedReportSuccess,
    loadEmbeddedReportFailure,
    embeddedReportsUiSelector,
} from './embeddedReports';
import chargesCard from './chargesCard';
import vehicleCard from './vehicleCard';

export function createEmbeddedArrestReport(
    defendantId: number
): RmsAction<Promise<CompleteClientReportView>> {
    return function (dispatch, getState) {
        const state = getState();
        const currentReport = currentReportSelector(state);
        const agencyId = currentUserDepartmentAgencyIdSelector(state);
        const arrestReportDefinition = arrestReportDefinitionForCurrentDepartmentSelector(state);
        const lastCreatedReportId = lastCreatedReportIdSelector(state);

        if (agencyId && arrestReportDefinition && currentReport) {
            return reportsCreateResource
                .createArrestReportFromOffenseReport({
                    sourceDefendantId: defendantId,
                    sourceReportId: currentReport.id,
                    targetReportDefinitionId: arrestReportDefinition.id,
                })
                .then((completeClientReportView) => {
                    const arrestReportId = completeClientReportView.report.id;
                    dispatch(loadEmbeddedReportStart(arrestReportId));

                    // instead of replacing lastCreatedReportId with the newly created arrestReportId,
                    // we should store the newly created arrestReportId along with lastCreatedReportId
                    // because these reports are all created in the current session.
                    const updatedLatestCreatedReportIds = compact(
                        flatten([lastCreatedReportId, arrestReportId])
                    );
                    dispatch(setLastCreatedReportId(updatedLatestCreatedReportIds));

                    return reportsResource
                        .getReportRelatedEntities(currentReport.id)
                        .then((relatedEntities) => {
                            dispatch(
                                storeEmbeddedArrestReport({
                                    ...completeClientReportView,
                                    reportShortTitles: relatedEntities.reportShortTitles,
                                })
                            );
                            dispatch(storeLinkedReportIds(relatedEntities.linkedReportIds));
                            dispatch(
                                initialEmbeddedArrestReportCardsUiState(arrestReportId, {
                                    isNew: true,
                                })
                            );
                            dispatch(loadEmbeddedReportSuccess(arrestReportId));
                            if (completeClientReportView.report.reportingEventNumber) {
                                dispatch(
                                    refreshRecentEntitiesForReportingEventNumberAllReports(
                                        completeClientReportView.report.reportingEventNumber
                                    )
                                );
                            }

                            return completeClientReportView;
                        })
                        .catch((err) => {
                            dispatch(loadEmbeddedReportFailure(arrestReportId, err.mnessage));
                        });
                });
        } else {
            logError('Not enough data in Redux state to create embedded arrest report', {
                extras: { agencyId, arrestReportDefinition, currentReport },
            });
            return Promise.reject(
                new Error('Cannot create arrest report, please reload and try again')
            );
        }
    };
}

/**
 * This method is similar to handleFetchReportSuccess() from reportRouting, except it does less. All we want to do here
 *   is store a subset of entities and links from the CompleteClientReportView into Redux state. Unlike
 *   handleFetchReportSuccess(), we do not dispatch fetchReportSuccess() because that updates the Redux store with the
 *   current report state.
 *
 * This also ignores the following properties on CompleteClientReportView. 'Embedder report' refers to the report that
 *   is embedding the arrest report.
 *
 *   eventDetail - to sync the same eventDetail data between the embedder report and the arrest report, we store only
 *     the embedder report's eventDetail
 *   linkedOffenses - should already be stored from the embedder offense report
 *   offenses - ignore to avoid conflict with the embedder offense report
 *   offenseInvolvedChildren - ignore to avoid conflict with the embedder offense report
 *   outsideArrestReports - embedded arrest block is made to work with Mark43 arrest reports only
 *   reportCaseStatus - embedded arrest block does not allow managing its report case status, ignore to avoid conflict
 *     with embedder offense report's report case status side panel
 *   reportUcr - same reason as reportCaseStatus
 *
 * The following properties on CompleteClientReportView do not get populated for the arrest report, but are still
 *   useful to include even when they do nothing now, as they will be useful for future functionality or for
 *   consolidating this action with handleFetchReportSuccess().
 *
 *   fieldContact
 *   impound
 *   missingPerson
 *   useOfForce
 *
 * We do not load ReportRelatedEntities for the embedded arrest report.
 *
 * TODO:
 *   reportSupplementView
 *   permission checks on reportStatusView
 */
export function storeEmbeddedArrestReport(
    completeClientReportView: CompleteClientReportView
): RmsAction<void> {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const applicationSettings = applicationSettingsSelector(state);
        dispatch(storeEmbeddedDragonDataAction(completeClientReportView));
        dispatch(
            dependencies.nexus.withEntityItems(
                {
                    // From Complete Client Report View
                    // First, naively pick some properties
                    ...pick(completeClientReportView, dataNexusEntityKeys),
                    // Then multi agency configurables
                    [USER_PROFILES_NEXUS_STATE_PROP]: map(
                        completeClientReportView.userProfiles,
                        userProfileToMiniUser
                    ),
                    [MINI_USERS_NEXUS_STATE_PROP]: map(
                        completeClientReportView.userProfiles,
                        userProfileToMiniUser
                    ),
                    [OFFENSE_CODES_NEXUS_STATE_PROP]: map(
                        completeClientReportView.offenseCodes,
                        augmentOffenseCodeWithOffenseCodeView
                    ),
                    // Then other values that need to be mapped in some way
                    [REPORTS_NEXUS_STATE_PROP]: compact([completeClientReportView.report]),
                    [NARRATIVE_AUTOSAVE_NEXUS_STATE_PROP]: compact([
                        completeClientReportView.narrativeAutosave,
                    ]),
                    [ARRESTS_NEXUS_STATE_PROP]: completeClientReportView.arrests,
                    [LOCATIONS_NEXUS_STATE_PROP]: completeClientReportView.locationViews,
                    [IMPOUNDS_NEXUS_STATE_PROP]: compact([completeClientReportView.impound]),
                    [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: flatten(
                        map(completeClientReportView.locationViews, 'entityLinks')
                    ),
                    [FIELD_CONTACTS_NEXUS_STATE_PROP]: compact([
                        completeClientReportView.fieldContact,
                    ]),
                    [MISSING_PERSONS_NEXUS_STATE_PROP]: compact([
                        completeClientReportView.missingPerson,
                    ]),
                    [USE_OF_FORCES_NEXUS_STATE_PROP]: compact([
                        completeClientReportView.useOfForce,
                    ]),
                    [REPORT_DEFINITIONS_NEXUS_STATE_PROP]: compact([
                        completeClientReportView.reportDefinition,
                        ...completeClientReportView.reportDefinitions,
                    ]),
                    // WITHOUT Report Related Entities
                    [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]:
                        completeClientReportView.reportShortTitles,
                    [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                        completeClientReportView.attributes,
                        convertAttributeToAttributeView
                    ),
                    [REPORT_INLINE_COMMENTS_NEXUS_STATE_PROP]: applicationSettings.RMS_INLINE_NARRATIVE_COMMENTS_ENABLED
                        ? completeClientReportView.inlineReportComments
                        : undefined,
                    [VEHICLES_NEXUS_STATE_PROP]: compact(completeClientReportView.vehicles),
                    [ITEM_PROFILE_NEXUS_STATE_PROP]: compact(completeClientReportView.items),
                    [PROPERTY_STATUS_NEXUS_STATE_PROP]: compact(
                        completeClientReportView.propertyStatuses
                    ),
                },
                { type: 'STORE_EMBEDDED_ARREST_REPORT' }
            )
        );
    };
}

export function refreshRecentEntitiesForReportingEventNumberAllReports(
    reportingEventNumber: string
): RmsAction<void> {
    return (dispatch) => {
        // PERSON_PROFILE
        dispatch(
            requestRecentPersonsOrOrganizations({
                renForRecents: reportingEventNumber,
                ownerType: EntityTypeEnum.REPORT.name,
                ownerId: undefined,
                isOrg: false,
            })
        );

        // ORGANIZATION_PROFILE
        dispatch(
            requestRecentPersonsOrOrganizations({
                renForRecents: reportingEventNumber,
                ownerType: EntityTypeEnum.REPORT.name,
                ownerId: undefined,
                isOrg: true,
            })
        );

        // LOCATIONS
        dispatch(
            requestRecentLocationsForReports(reportingEventNumber, EntityTypeEnum.REPORT.name)
        );
    };
}

export function initialEmbeddedArrestReportCardsUiState(
    reportId: number,
    { isNew }: { isNew: boolean }
): RmsAction<void> {
    return (dispatch) => {
        // Multiple Instances
        const options = {
            index: reportId,
            summaryMode: !isNew,
            reportId,
        };

        // Arrest Card
        dispatch(arrestCard.actionCreators.createNewCard(options));

        // Charges Card
        dispatch(chargesCard.actionCreators.createNewCard(options));

        // Booking Card
        dispatch(bookingCard.actionCreators.createNewCard(options));

        // Course Case Card
        dispatch(courtCaseCard.actionCreators.createNewCard(options));
        // Summary Narrative Card
        dispatch(summaryNarrativeCard.actionCreators.createNewCard(options));

        // Narrative Card
        dispatch(narrativeCard.actionCreators.createNewCard(options));

        // Attachments Card
        dispatch(attachmentsCard.actionCreators.createNewCard(options));

        // Vehicle Card
        dispatch(vehicleCard.actionCreators.createNewCard(options));
    };
}

export function removeEmbeddedReport(reportId: number): RmsAction<void> {
    return (dispatch, getState, dependencies) => {
        return dispatch(
            dependencies.nexus.withRemoveMultiple(
                // not a comprehensive list of related entities, but sufficient to update UI.
                {
                    [ARRESTS_NEXUS_STATE_PROP]: { reportId },
                    [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: { reportId },
                    [REPORTS_NEXUS_STATE_PROP]: { id: reportId },
                },
                deleteEmbeddedReport(reportId)
            )
        );
    };
}

/**
 * All arrest reports linked to the current report.
 */
export const linkedArrestReportShortTitlesSelector = createSelector(
    linkedReportShortTitlesSelector,
    arrestReportDefinitionForCurrentDepartmentSelector,
    (linkedReportShortTitles, arrestReportDefinition) =>
        arrestReportDefinition
            ? sortBy(
                  filter(linkedReportShortTitles, {
                      reportDefinitionId: arrestReportDefinition.id,
                  }),
                  'reportId'
              )
            : []
);

export const linkedArrestsSelector = createSelector(
    linkedArrestReportShortTitlesSelector,
    arrestsSelector,
    (linkedArrestReportShortTitles, arrests) => {
        const linkedArrestReportShortTitlesReportIds = map(
            linkedArrestReportShortTitles,
            'reportId'
        );
        return filter(arrests, (arrest) => {
            const { reportId } = arrest;
            return includes(linkedArrestReportShortTitlesReportIds, reportId);
        });
    }
);

/**
 * Whether this report meets the conditions for potentially displaying embedded reports inside it. This logic does not
 *   consider linked arrest reports, and is meant to be composed with other selectors that do consider linked arrest
 *   reports, because there are 2 separate stateful things to discover:
 *
 * 1. Which arrest reports are embedded inside this offense report?
 * 2. May each offense suspect have a new arrest report be created and embedded inside this offense report?
 */
export const currentReportSupportsEmbeddingSelector = createSelector(
    applicationSettingsSelector,
    currentReportIdSelector,
    isOffenseReportSelector,
    approvalStatusSelector,
    reportSubmissionAuthorsSelector,
    currentUserIdSelector,
    (
        applicationSettings,
        currentReportId,
        isOffenseReport,
        approvalStatus,
        reportSubmissionAuthors,
        currentUserId
    ) =>
        applicationSettings.RMS_DYNAMIC_REPORT_ENABLED &&
        currentReportId &&
        isOffenseReport(currentReportId) &&
        approvalStatus === ClientApprovalStatusEnum.DRAFT.name &&
        includes(map(reportSubmissionAuthors, 'officerId'), currentUserId)
);

/**
 * The ids of reports that are currently embedded into the current report.
 */
export const embeddedReportShortTitlesSelector = createSelector(
    currentReportSupportsEmbeddingSelector,
    linkedArrestReportShortTitlesSelector,
    embeddedReportsUiSelector,
    (currentReportSupportsEmbedding, linkedArrestReportShortTitles, embeddedReportsUi) =>
        currentReportSupportsEmbedding
            ? filter(linkedArrestReportShortTitles, (reportShortTitle) => {
                  const ui = embeddedReportsUi[reportShortTitle.reportId];
                  const hidden =
                      ui?.loading === false && ui?.loadedAndVisible === false && !ui?.errorMessage;
                  return (
                      reportShortTitle.clientApprovalStatus ===
                          ClientApprovalStatusEnum.DRAFT.name && !hidden
                  );
              })
            : []
);

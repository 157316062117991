import { storeCadCallTakerStations } from '~/client-common/core/domain/cad-call-taker-stations/state/data';
import cadCallTakerStationResource from '../../resources/cadCallTakerStationResource';
import {
    createImportActionCreator,
    createValidateFileActionCreator,
    createExportActionCreator,
} from '../../../core/utils/importExportHelpers';

export const LOAD_CAD_CALL_TAKER_STATIONS_START =
    'cad-call-taker-stations/LOAD_CAD_CALL_TAKER_STATIONS_START';
export const LOAD_CAD_CALL_TAKER_STATIONS_SUCCESS =
    'cad-call-taker-stations/LOAD_CAD_CALL_TAKER_STATIONS_SUCCESS';
export const LOAD_CAD_CALL_TAKER_STATIONS_FAILURE =
    'cad-call-taker-stations/LOAD_CAD_CALL_TAKER_STATIONS_FAILURE';
export const SAVE_CAD_CALL_TAKER_STATION_START =
    'cad-call-taker-stations/SAVE_CAD_CALL_TAKER_STATION_START';
export const SAVE_CAD_CALL_TAKER_STATION_SUCCESS =
    'cad-call-taker-stations/SAVE_CAD_CALL_TAKER_STATION_SUCCESS';
export const SAVE_CAD_CALL_TAKER_STATION_FAILURE =
    'cad-call-taker-stations/SAVE_CAD_CALL_TAKER_STATION_FAILURE';
const FETCH_CAD_CALL_TAKER_STATION_HISTORY_START =
    'cad-call-taker-stations/FETCH_CAD_CALL_TAKER_STATION_HISTORY_START';
export const FETCH_CAD_CALL_TAKER_STATION_HISTORY_SUCCESS =
    'cad-call-taker-stations/FETCH_CAD_CALL_TAKER_STATION_HISTORY_SUCCESS';
const FETCH_CAD_CALL_TAKER_STATION_HISTORY_FAILURE =
    'cad-call-taker-stations/FETCH_CAD_CALL_TAKER_STATION_HISTORY_FAILURE';
const FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_START =
    'cad-call-taker-stations/FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_START';
export const FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_SUCCESS =
    'cad-call-taker-stations/FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_SUCCESS';
const FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_FAILURE =
    'cad-call-taker-stations/FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_FAILURE';

function loadCadCallTakerStationsStart() {
    return {
        type: LOAD_CAD_CALL_TAKER_STATIONS_START,
    };
}

function loadCadCallTakerStationsFailure(errMsg) {
    return {
        type: LOAD_CAD_CALL_TAKER_STATIONS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

function loadCadCallTakerStationsSuccess() {
    return {
        type: LOAD_CAD_CALL_TAKER_STATIONS_SUCCESS,
    };
}

function fetchCadCallTakerStationHistoryStart(callTakerStationId) {
    return {
        type: FETCH_CAD_CALL_TAKER_STATION_HISTORY_START,
        payload: callTakerStationId,
    };
}

function fetchCadCallTakerStationHistorySuccess(callTakerStationId, histories) {
    return {
        type: FETCH_CAD_CALL_TAKER_STATION_HISTORY_SUCCESS,
        payload: { callTakerStationId, histories },
    };
}

function fetchCadCallTakerStationHistoryFailure(callTakerStationId, err) {
    return {
        type: FETCH_CAD_CALL_TAKER_STATION_HISTORY_FAILURE,
        payload: { callTakerStationId, err },
    };
}

function fetchAllCadCallTakerStationsHistoryStart() {
    return {
        type: FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_START,
    };
}

function fetchAllCadCallTakerStationsHistorySuccess(histories) {
    return {
        type: FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_SUCCESS,
        payload: { histories },
    };
}

function fetchAllCadCallTakerStationsHistoryFailure(err) {
    return {
        type: FETCH_ALL_CAD_CALL_TAKER_STATIONS_HISTORY_FAILURE,
        payload: { err },
    };
}

export function loadCadCallTakerStations() {
    return function (dispatch) {
        dispatch(loadCadCallTakerStationsStart());
        return cadCallTakerStationResource
            .getCadCallTakerStations()
            .then((callTakerStations) => {
                dispatch(storeCadCallTakerStations(callTakerStations));
                dispatch(loadCadCallTakerStationsSuccess());
            })
            .catch((err) => {
                dispatch(loadCadCallTakerStationsFailure(err.message));
            });
    };
}

export function loadCadCallTakerStationHistoryData(callTakerStationId) {
    return function (dispatch) {
        dispatch(fetchCadCallTakerStationHistoryStart(callTakerStationId));
        return cadCallTakerStationResource
            .getCadCallTakerStationHistory(callTakerStationId)
            .then((histories) => {
                dispatch(fetchCadCallTakerStationHistorySuccess(callTakerStationId, histories));
            })
            .catch((err) => {
                dispatch(fetchCadCallTakerStationHistoryFailure(callTakerStationId, err));
            });
    };
}

export function loadAllCadCallTakerStationsHistoryData() {
    return function (dispatch) {
        dispatch(fetchAllCadCallTakerStationsHistoryStart());
        return cadCallTakerStationResource
            .getAllCadCallTakerStationsHistory()
            .then((histories) => {
                dispatch(fetchAllCadCallTakerStationsHistorySuccess(histories));
            })
            .catch((err) => {
                dispatch(fetchAllCadCallTakerStationsHistoryFailure(err));
            });
    };
}

function saveCadCallTakerStationStart() {
    return {
        type: SAVE_CAD_CALL_TAKER_STATION_START,
    };
}

function saveCadCallTakerStationSuccess(cadCallTakerStation) {
    return {
        type: SAVE_CAD_CALL_TAKER_STATION_SUCCESS,
        payload: cadCallTakerStation,
    };
}

function saveCadCallTakerStationFailure(errMsg) {
    return {
        type: SAVE_CAD_CALL_TAKER_STATION_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveCadCallTakerStation({ cadCallTakerStation }) {
    return function (dispatch) {
        dispatch(saveCadCallTakerStationStart());
        return cadCallTakerStationResource
            .updateCadCallTakerStation(cadCallTakerStation)
            .then((callTakerStation) => {
                dispatch(storeCadCallTakerStations([callTakerStation]));
                return dispatch(saveCadCallTakerStationSuccess(callTakerStation));
            })
            .catch((err) => dispatch(saveCadCallTakerStationFailure(err.message)));
    };
}

/**
 * Import attributes from an uploaded file.
 * @param  {number} fileId
 * @return {Promise}
 */
const importCallTakerStations = createImportActionCreator(
    cadCallTakerStationResource.importCallTakerStations
);

/**
 * Validate that a file is valid for importing new attributes.
 * @param  {number} fileId
 * @return {Promise}
 */
export const validateCallTakerStationImport = createValidateFileActionCreator(
    cadCallTakerStationResource.validateCallTakerStationImport,
    importCallTakerStations
);

/**
 * Export all attributes into a CSV file.
 * @return {Promise}
 */
export const exportCallTakerStations = createExportActionCreator(
    cadCallTakerStationResource.exportCallTakerStations
);

import { PrimarySubdivisionDto } from '@mark43/rms-api';

import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';
import getSubdivisionsResource from '../../../subdivisions/resources/subdivisionsResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const PRIMARY_SUBDIVISIONS_NEXUS_STATE_PROP = 'primarySubdivisions';

const primarySubdivisionsModule = createNormalizedModule<PrimarySubdivisionDto>({
    type: PRIMARY_SUBDIVISIONS_NEXUS_STATE_PROP,
});

const MARK_SUBDIVISION_AS_PRIMARY_START = 'subdivisions/MARK_SUBDIVISION_AS_PRIMARY_START';

const MARK_SUBDIVISION_AS_PRIMARY_SUCCESS = 'subdivisions/MARK_SUBDIVISION_AS_PRIMARY_SUCCESS';

export const MARK_SUBDIVISION_AS_PRIMARY_FAILURE =
    'subdivisions/MARK_SUBDIVISION_AS_PRIMARY_FAILURE';

const UNMARK_SUBDIVISION_AS_PRIMARY_START = 'subdivisions/UNMARK_SUBDIVISION_AS_PRIMARY_START';
const UNMARK_SUBDIVISION_AS_PRIMARY_SUCCESS = 'subdivisions/UNMARK_SUBDIVISION_AS_PRIMARY_SUCCESS';
const UNMARK_SUBDIVISION_AS_PRIMARY_FAILURE = 'subdivisions/UNMARK_SUBDIVISION_AS_PRIMARY_FAILURE';

function markSubdivisionAsPrimaryStart() {
    return {
        type: MARK_SUBDIVISION_AS_PRIMARY_START,
    };
}

function markSubdivisionAsPrimarySuccess() {
    return {
        type: MARK_SUBDIVISION_AS_PRIMARY_SUCCESS,
    };
}

function markSubdivisionAsPrimaryFailure(err: { message?: string } = {}) {
    return {
        type: MARK_SUBDIVISION_AS_PRIMARY_FAILURE,
        payload: err.message,
    };
}

function unmarkSubdivisionAsPrimaryStart() {
    return {
        type: UNMARK_SUBDIVISION_AS_PRIMARY_START,
    };
}

function unmarkSubdivisionAsPrimarySuccess() {
    return {
        type: UNMARK_SUBDIVISION_AS_PRIMARY_SUCCESS,
    };
}

function unmarkSubdivisionAsPrimaryFailure(err: { message?: string } = {}) {
    return {
        type: UNMARK_SUBDIVISION_AS_PRIMARY_FAILURE,
        payload: err.message,
    };
}

export default primarySubdivisionsModule.reducerConfig;

export const primarySubdivisionsSelector = primarySubdivisionsModule.selectors.entitiesSelector;

export function markSubdivisionAsPrimary(
    subdivisionId: number,
    callBack?: (args: ModuleShape<PrimarySubdivisionDto>) => void
): ClientCommonAction<Promise<void>> {
    const resource = getSubdivisionsResource();
    return (dispatch, getState, { nexus }) => {
        dispatch(markSubdivisionAsPrimaryStart());
        return resource
            .promoteToPrimary(subdivisionId)
            .then((result: PrimarySubdivisionDto) => {
                // Removes old primary subdivision of that agency type
                dispatch(
                    nexus.withRemove(
                        PRIMARY_SUBDIVISIONS_NEXUS_STATE_PROP,
                        {
                            agencyTypeGlobalAttrId: result.agencyTypeGlobalAttrId,
                        },
                        nexus.withEntityItems(
                            { [PRIMARY_SUBDIVISIONS_NEXUS_STATE_PROP]: [result] },
                            markSubdivisionAsPrimarySuccess()
                        )
                    )
                );

                if (callBack) {
                    callBack(primarySubdivisionsSelector(getState()));
                }
            })
            .catch((err: Error) => dispatch(markSubdivisionAsPrimaryFailure(err)));
    };
}

export function unmarkSubdivisionAsPrimary(
    subdivisionId: number,
    callBack?: (args: ModuleShape<PrimarySubdivisionDto>) => void
): ClientCommonAction<Promise<void>> {
    const resource = getSubdivisionsResource();
    return (dispatch, getState, { nexus }) => {
        dispatch(unmarkSubdivisionAsPrimaryStart());
        return resource
            .demoteFromPrimary(subdivisionId)
            .then((result: boolean) => {
                if (result) {
                    dispatch(
                        nexus.withRemove(
                            PRIMARY_SUBDIVISIONS_NEXUS_STATE_PROP,
                            { subdivisionId },
                            unmarkSubdivisionAsPrimarySuccess()
                        )
                    );
                    if (callBack) {
                        callBack(primarySubdivisionsSelector(getState()));
                    }
                } else {
                    throw new Error('Failed to unselect primary subdivision');
                }
            })
            .catch((err: Error) => dispatch(unmarkSubdivisionAsPrimaryFailure(err)));
    };
}

import {
    DELETE_LOGIN_BACKGROUND_START,
    DELETE_LOGIN_BACKGROUND_SUCCESS,
    DELETE_LOGIN_BACKGROUND_FAILURE,
    SAVE_LOGIN_BACKGROUND_START,
    SAVE_LOGIN_BACKGROUND_SUCCESS,
    SAVE_LOGIN_BACKGROUND_FAILURE,
    UPLOAD_LOGIN_BACKGROUND_START,
    UPLOAD_LOGIN_BACKGROUND_SUCCESS,
    UPLOAD_LOGIN_BACKGROUND_FAILURE,
} from '../data';

const initialUiState = {
    loading: false,
    error: null,
    stagedFile: null,
    cacheBuster: `${new Date().getTime()}`,
};

export default function landingBackgroundAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case DELETE_LOGIN_BACKGROUND_START:
        case SAVE_LOGIN_BACKGROUND_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPLOAD_LOGIN_BACKGROUND_START:
            return {
                ...state,
                loading: true,
                error: null,
                stagedFile: null,
            };
        case DELETE_LOGIN_BACKGROUND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                stagedFile: null,
                cacheBuster: `${new Date().getTime()}`,
            };
        case SAVE_LOGIN_BACKGROUND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                stagedFile: null,
                cacheBuster: `${new Date().getTime()}`,
            };
        case UPLOAD_LOGIN_BACKGROUND_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                stagedFile: action.payload,
            };
        case DELETE_LOGIN_BACKGROUND_FAILURE:
        case SAVE_LOGIN_BACKGROUND_FAILURE:
        case UPLOAD_LOGIN_BACKGROUND_FAILURE:
            return {
                ...state,
                loadingList: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.landingBackgroundAdmin;

import { TaskEntityLinkView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'taskEntityLinks';

const taskEntityLinksModule = createNormalizedModule<TaskEntityLinkView>({
    type: NEXUS_STATE_PROP,
});

export const taskEntityLinksSelector = taskEntityLinksModule.selectors.entitiesSelector;

export default taskEntityLinksModule.reducerConfig;

import classNames from 'classnames';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import { ResultsHeader } from '../../../cases/core/components/ResultsHeader';

const ResultsTable = ({
    TableBody: TableBodyContent,
    TableFooter: TableFooterContent,
    TableHeader: TableHeaderContent,
    rowComponent,
    className,
    columnSpan,
    formatFieldByName,
    handlePaginationClick,
    handleRowClick,
    handleSelectRows,
    handleSizeChange,
    handleSortKeyClick,
    handleSortTypeClick,
    queryFrom,
    queryResults,
    querySize,
    querySortKey,
    querySortType,
    selectableRows,
    selectedRows,
    showTableHeader,
    totalResults,
    tableCheckboxMargin,
    SearchForm,
}) => {
    /* eslint-disable */

    // TODO do to how legacy Table Header works, we cannot render TableHeaderContent as a React component via <TableHeaderContent ... />
    const tableHeaderContent = TableHeaderContent({
        formatFieldByName,
        handleSortKeyClick,
        handleSortTypeClick,
        querySortKey,
        querySortType,
    });

    // TODO do to how legacy TableBodyContent works, we cannot render TableBodyContent as a React component via <TableBodyContent ... />
    const tableBodyContent = TableBodyContent();

    // TODO do to how legacy TableBodyContent works, we cannot render TableFooterContent as a React component via <TableFooterContent ... />
    const tableFooterContent = totalResults
        ? TableFooterContent({
              handlePaginationClick,
              queryFrom,
              querySize,
              totalResults,
          })
        : null;

    const tableHeaderColSpan = columnSpan || get(tableHeaderContent, 'props.children.length') || 1;
    /* eslint-enable */

    return (
        <div className={classNames(className, 'dashboard-search-results')}>
            {showTableHeader && (
                <ResultsHeader
                    from={queryFrom}
                    to={queryFrom + queryResults.length}
                    SearchForm={SearchForm}
                    onSizeChange={handleSizeChange}
                    totalResults={totalResults}
                    isSearchSavable={false}
                    className={classNames(className)}
                    inlineWithTabs={false}
                    searchOpenByDefault={false}
                />
            )}
            <Table
                data={queryResults}
                onRowClick={handleRowClick}
                onSelectRow={handleSelectRows}
                selectableRows={selectableRows}
                selectedRows={selectedRows}
                sortType={querySortType}
                sortKey={querySortKey}
                rowComponent={rowComponent}
                tableCheckboxMargin={tableCheckboxMargin}
            >
                <TableHeader>{tableHeaderContent}</TableHeader>
                {tableBodyContent}
            </Table>

            {tableFooterContent}
        </div>
    );
};

ResultsTable.propTypes = {
    TableBody: PropTypes.func,
    TableFooter: PropTypes.func,
    TableHeader: PropTypes.func,
    className: PropTypes.string,
    columnSpan: PropTypes.number,
    formatFieldByName: PropTypes.func,
    handlePaginationClick: PropTypes.func,
    handleRowClick: PropTypes.func,
    handleSelectRows: PropTypes.func,
    handleSizeChange: PropTypes.func,
    queryFrom: PropTypes.number,
    queryResults: PropTypes.array,
    querySize: PropTypes.number,
    querySortKey: PropTypes.string,
    querySortType: PropTypes.string,
    showTableHeader: PropTypes.bool,
    selectedRows: PropTypes.arrayOf(PropTypes.number),
    totalResults: PropTypes.number,
    SearchForm: PropTypes.func,
};

ResultsTable.defaultProps = {
    TableBody: noop,
    TableFooter: noop,
    TableHeader: noop,
    className: '',
    columnSpan: 0,
    formatFieldByName: noop,
    handlePaginationClick: noop,
    handleRowClick: noop,
    handleSelectRows: noop,
    handleSizeChange: noop,
    queryFrom: 0,
    queryResults: [],
    querySize: 0,
    querySortKey: '',
    querySortType: '',
    showTableHeader: true,
    selectedRows: [],
    totalResults: 0,
    SearchForm: undefined,
};

export default ResultsTable;

import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import _ from 'lodash';

import { withContentScrollHandler } from '../../helpers/reactHelpers';

// this private function helps determine which of the "spied on" components
// is being viewed based on the provided window scroll position
function calculateActiveAnchor(constantOffset, anchors) {
    const el = _(anchors)
        .map((anchor) => $(`[data-anchor='${anchor}']`))
        .reduce((selectedElement, nextElement) => {
            if (!nextElement.offset() || !selectedElement.offset()) {
                return selectedElement;
            }
            return nextElement.offset().top > selectedElement.offset().top &&
                nextElement.offset().top < constantOffset
                ? nextElement
                : selectedElement;
        });

    // default to first anchor, which is useful when the scroll spy component
    // renders before the anchors
    return el && el.attr('data-anchor') ? el.attr('data-anchor') : anchors[0];
}
function ScrollSpy({ className, anchors, constantOffset, render }) {
    const activeAnchor = calculateActiveAnchor(constantOffset, anchors);

    return <ul className={className}>{render(activeAnchor)}</ul>;
}

function LegacyScrollSpy({ children, className, anchors, constantOffset }) {
    const activeAnchor = calculateActiveAnchor(constantOffset, anchors);
    const mappedChildren = _.map(children, (child) =>
        React.cloneElement(child, { isScrollSpyActive: activeAnchor === child.props.anchor })
    );

    return <ul className={className}>{mappedChildren}</ul>;
}

ScrollSpy.propTypes = {
    anchors: PropTypes.arrayOf(PropTypes.string),
    constantOffset: PropTypes.number,
};

ScrollSpy.defaultProps = {
    constantOffset: 0,
};

export const LegacyReportSidebarScrollSpy = withContentScrollHandler(LegacyScrollSpy);
export default withContentScrollHandler(ScrollSpy);

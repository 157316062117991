import { IdentifyingMark } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'identifyingMarks';

const identifyingMarksModule = createGroupedModule<IdentifyingMark>({
    type: NEXUS_STATE_PROP,
    key: 'personProfileId',
});

// SELECTORS
export const identifyingMarksSelector = identifyingMarksModule.selectors.entitiesSelector;
export const identifyingMarksByPersonProfileIdSelector =
    identifyingMarksModule.selectors.entitiesByKeySelector;

// REDUCER
export default identifyingMarksModule.reducerConfig;

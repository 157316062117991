import factory from './actionTypesFactory';

export default factory('customreportsadmin', [
    'PAGE_LOAD_START',
    'PAGE_LOAD_SUCCESS',
    'PAGE_LOAD_FAILURE',
    'LOAD_DEFAULT_ROLE_LINKS',
    'LOAD_ROLE_PERMISSIONS_SUCCESS',
    'LOAD_ROLE_PERMISSIONS_START',
    'LOAD_ROLE_PERMISSIONS_FAILURE',
    'SAVE_ROLE_PERMISSIONS_SUCCESS',
    'SAVE_ROLE_PERMISSIONS_START',
    'SAVE_ROLE_PERMISSIONS_FAILURE',
    'SELECT_REPORT_DEFINITION',
    'SAVE_REPORT_DEFINITION_SUCCESS',
    'SAVE_REPORT_DEFINITION_FAILURE',
    'SAVE_DOWNLOADABLE_START',
    'SAVE_REPORT_DEFINITION_AND_DOWNLOADABLE_SUCCESS',
    'SAVE_DOWNLOADABLES_SUCCESS',
    'SAVE_DOWNLOADABLES_FAILURE',
    'ADD_DOWNLOADABLE_SUCCESS',
    'ADD_DOWNLOADABLE_FAILURE',
    'REMOVE_DOWNLOADABLE',
    'STORE_HYDRATED_REPORT_DEFINTION',
    'SELECT_NEW_REPORT_DEFINITION',
    'STORE_REPORT_DEFINITION_CARD_LINKS',
    'SAVE_REPORT_DEFINITION_CARD_LINKS_FAILURE',
    'SELECT_REPORT_DEFINITION_SUCESS',
]);

import React from 'react';
import { map, difference, sortBy, get } from 'lodash';
import styled from 'styled-components';

import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum, RefContextEnum } from '@mark43/rms-api';

import strings from '~/client-common/core/strings/componentStrings';
import Row from '../../../core/components/Row';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import { RRFCourtCodeAttributeSelect } from '../../../core/forms/components/selects/CourtCodeSelect';
import { FIELD_WIDTH_50, FIELD_WIDTH_100 } from '../configuration';
import { RRFInlineAttachmentsUploader } from '../../../attachments/core/components/InlineAttachmentsUploader';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import Select from '../../../core/forms/components/selects/Select';
import { StyledRRFCheckbox as _StyledRRFCheckbox } from './Form';

const StyledRRFCheckbox = styled(_StyledRRFCheckbox)`
    margin-bottom: 13px;
`;

class CourtOrderFormFields extends React.Component {
    handleFileUpload = (files) => {
        const courtOrderFileIds = this.props.formModelByPath('courtOrderFileIds') || [];
        const fileIds = map(files, 'file.id');
        const updatedCourtOrderFileIds = [...courtOrderFileIds, ...fileIds];
        this.props.changePath('courtOrderFileIds', sortBy(updatedCourtOrderFileIds));
    };

    handleFileDelete = (fileId) => {
        const courtOrderFileIds = this.props.formModelByPath('courtOrderFileIds');
        const updatedCourtOrderFileIds = difference(courtOrderFileIds, [fileId]);
        this.props.changePath('courtOrderFileIds', updatedCourtOrderFileIds);
    };

    handleAttachmentDelete = (attachment) => {
        const courtOrderFileIds = this.props.formModelByPath('courtOrderFileIds');
        const updatedCourtOrderFileIds = difference(courtOrderFileIds, [
            get(attachment, 'file.id'),
        ]);
        this.props.changePath('courtOrderFileIds', updatedCourtOrderFileIds);
    };

    render() {
        const {
            showPersonRelatedFields = true,
            currentContext,
            showArrestIdField,
            showStateIdField,
            onSealingTypeChange = () => {},
            isStatuteSealing = false,
            isEditing = false,
        } = this.props;

        return (
            <div>
                {currentContext !== RefContextEnum.FORM_CHARGE_VACATING.name && (
                    <Row>
                        <Select
                            path="sealingType"
                            options={strings.recordPrivacy.courtOrders.CourtOrderTypeSelect.options}
                            onChange={onSealingTypeChange.bind(this)}
                            isRequired={true}
                            value={isStatuteSealing}
                            label={strings.recordPrivacy.courtOrders.CourtOrderTypeSelect.label}
                            disabled={isEditing}
                        />
                    </Row>
                )}
                {isStatuteSealing ? (
                    <>
                        <Row>
                            <RRFAttributeSelect
                                attributeType={AttributeTypeEnum.SEALING_STATUTE_CODE.name}
                                path="courtOrder.statuteCodeAttrId"
                                label={
                                    strings.recordPrivacy.courtOrders.CourtOrderEditForm
                                        .courtOrderAttrId
                                }
                                shouldShowFieldNames={true}
                                width={FIELD_WIDTH_100}
                            />
                        </Row>
                    </>
                ) : (
                    <>
                        <Row>
                            <RRFText path="courtOrder.courtCaseNumber" width={FIELD_WIDTH_100} />
                        </Row>
                        <Row>
                            <RRFText path="courtOrder.courtCaseStatute" width={FIELD_WIDTH_100} />
                        </Row>
                        <Row>
                            <RRFCourtCodeAttributeSelect
                                attributeType={AttributeTypeEnum.COURT_CODE.name}
                                path="courtOrder.courtCodeAttrId"
                                width={FIELD_WIDTH_100}
                                currentContext={currentContext}
                            />
                        </Row>
                        {showPersonRelatedFields && (
                            <>
                                <Row>
                                    <RRFText
                                        path="courtOrder.involvedPersonFullName"
                                        width={FIELD_WIDTH_100}
                                        disabled={this.props.disableInvolvedPersonFullName}
                                    />
                                </Row>
                                <StyledRRFCheckbox
                                    path="courtOrder.isJuvenile"
                                    disabled={this.props.disableIsJuvenile}
                                />
                            </>
                        )}
                        <Row>
                            <RRFDatePicker
                                path="courtOrder.courtCaseDateUtc"
                                dateWidth="100%"
                                width={FIELD_WIDTH_50}
                            />
                            <RRFDatePicker
                                path="courtOrder.dateToPurgeUtc"
                                dateWidth="100%"
                                width={FIELD_WIDTH_50}
                            />
                        </Row>
                        <Row>
                            {showArrestIdField && (
                                <RRFText disabled path="arrestIdNumber" width={FIELD_WIDTH_100} />
                            )}
                            {showStateIdField && (
                                <RRFText
                                    disabled
                                    path="courtOrder.stateIdNumber"
                                    width={FIELD_WIDTH_100}
                                />
                            )}
                        </Row>
                    </>
                )}
                <Row>
                    <RRFTextArea path="courtOrder.remarks" width={FIELD_WIDTH_100} />
                </Row>
                <Row>
                    <RRFInlineAttachmentsUploader
                        path={'courtOrderFileIds'}
                        entityId={this.props.courtOrderId}
                        entityType={EntityTypeEnum.COURT_ORDER.name}
                        linkType={LinkTypesEnum.COURT_ORDER_ATTACHMENT}
                        onFileUploadFinish={this.handleFileUpload}
                        onDeleteUpload={this.handleFileDelete}
                        onAttachmentDelete={this.handleAttachmentDelete}
                    />
                </Row>
            </div>
        );
    }
}

export default CourtOrderFormFields;

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import {
    INVENTORY_INVENTORY_TYPE,
    INVENTORY_OWNER_USER_ID,
    INVENTORY_STORAGE_LOCATION_ID,
} from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { RANGE, STORAGE_LOCATION, USER } = fieldTypeClientEnum;

const { DATE_TIME_RANGE } = rangeFieldKeyEnum;

const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'departmentIds',
        type: fieldTypeClientEnum.DEPARTMENT,
    },
    {
        fieldName: INVENTORY_INVENTORY_TYPE,
        key: 'inventoryType',
    },
    {
        key: 'completedStartDateUtc',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_START,
    },
    {
        key: 'completedEndDateUtc',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_END,
    },
    {
        key: 'completedToDatePeriod',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: TO_DATE_PERIOD,
    },
    {
        key: 'completedWithinLastPeriod',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: WITHIN_LAST_PERIOD,
    },
    {
        fieldName: INVENTORY_OWNER_USER_ID,
        key: 'ownerUserIds',
        type: USER,
    },
    {
        fieldName: INVENTORY_STORAGE_LOCATION_ID,
        key: 'storageLocationIds',
        type: STORAGE_LOCATION,
    },
]);

export default fieldViewModels;

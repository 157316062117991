import React from 'react';
import classNames from 'classnames';

/**
 * Section header in a popover.
 * @param {Object} props All props go into a `div`.
 */
export default function PopoverSectionHeader({ className, ...otherProps }) {
    return <div className={classNames('popover-section-header', className)} {...otherProps} />;
}

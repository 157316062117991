import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

import { ComplianceExportTypeEnumType } from '@mark43/rms-api';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { useFormGetter } from '../../core/forms/hooks/useFormGetter';

import { convertFromFormModel } from '../components/ComplianceExportForm';
import { NIBRS_COMPLIANCE_TYPE } from '../configuration';
import { ComplianceExportType } from '../types';

function useCreateGenerateExportErrors(
    callErrorsResource: (
        payload: ReturnType<typeof convertFromFormModel> & {
            complianceExportType?: ComplianceExportTypeEnumType;
        }
    ) => Promise<void>,
    currentAgencyId: number | undefined,
    complianceExportType?: ComplianceExportType,
    setIsFormMatchingErrorsGenerated?: React.Dispatch<React.SetStateAction<boolean>>
) {
    const { getForm } = useFormGetter();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const generateExportErrorsHandler = useCallback(() => {
        const form = getForm(formClientEnum.COMPLIANCE_EXPORT);

        form?.submit()
            .then(({ form }) => {
                const formModel = form.getState().model;
                const payload = convertFromFormModel(
                    formModel,
                    !!applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED,
                    currentAgencyId
                );
                if (complianceExportType?.name === NIBRS_COMPLIANCE_TYPE.name) {
                    callErrorsResource(payload);
                } else {
                    callErrorsResource({
                        ...payload,
                        complianceExportType: complianceExportType?.name,
                    });
                }

                if (setIsFormMatchingErrorsGenerated) {
                    setIsFormMatchingErrorsGenerated(true);
                }
            })
            // no validation messages, required errors are displayed inline on inputs
            .catch(noop);
    }, [
        applicationSettings.RMS_NIBRS_EXPORT_USE_GENERATED_SEGMENTS_ENABLED,
        callErrorsResource,
        complianceExportType?.name,
        currentAgencyId,
        getForm,
        setIsFormMatchingErrorsGenerated,
    ]);

    return generateExportErrorsHandler;
}

export default useCreateGenerateExportErrors;

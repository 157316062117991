import React from 'react';
import styled from 'styled-components';
import { Virtuoso } from 'react-virtuoso';

// Copied from here:
// https://github.com/petyosi/react-virtuoso/blob/d2b18403ca52e9b916e88d69bf59b3c006faf657/src/VirtuosoScroller.tsx#L3-L9
//
// Moving these stlyes into a styled component for easier overriding in our code base!
// Most importantly, I removed the default `height: 40rem` - we should always
// specify our height, unless we are using `flexbox` (which is probably most cases)
const StyledDefaultScrollContainer = styled.div`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    outline: none;
`;

// Also copied this from here:
// https://github.com/petyosi/react-virtuoso/blob/d2b18403ca52e9b916e88d69bf59b3c006faf657/src/VirtuosoScroller.tsx#L18-L44
//
// Specifically copied this over so that I could remove the `style` prop and use `className` instead (for easier overriding)
const DefaultScrollContainer = ({ className, reportScrollTop, scrollTo, children }) => {
    const elRef = React.useRef(null);

    const onScroll = React.useCallback(
        (e) => {
            reportScrollTop(e.target.scrollTop);
        },
        [reportScrollTop]
    );

    const ref = React.useCallback(
        (theRef) => {
            if (theRef) {
                theRef.addEventListener('scroll', onScroll, { passive: true });
                elRef.current = theRef;
            } else {
                elRef.current.removeEventListener('scroll', onScroll);
            }
        },
        [onScroll]
    );

    scrollTo((scrollTop) => {
        const goTo = { top: scrollTop };
        elRef.current.scrollTo(goTo);
    });

    return (
        <StyledDefaultScrollContainer ref={ref} tabIndex={0} className={className}>
            {children}
        </StyledDefaultScrollContainer>
    );
};

export const VirtualizedList = ({ items, children, overscan = 0, footer, className }) => {
    const generateItem = (index) => children(items[index]);

    const options = {
        totalCount: items.length,
        overscan,
        item: generateItem,
        footer,
    };

    return <Virtuoso className={className} ScrollContainer={DefaultScrollContainer} {...options} />;
};

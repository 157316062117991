import { EntityTypeEnum } from '@mark43/rms-api';
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { locationByIdSelector } from '~/client-common/core/domain/locations/state/data';
import RecentEntities from '../../recent-entities/components/RecentEntities';
import testIds from '../../../../core/testIds';
import _LocationPill from './LocationPill';

const LocationPill = styled(_LocationPill)`
    margin-top: 10px;
`;

const mapStateToProps = createStructuredSelector({
    locationById: locationByIdSelector,
});
export const InvolvedLocations = connect(mapStateToProps)(
    class InvolvedLocations extends Component {
        renderRecentEntity = ({ recentEntityId }) => {
            const location = this.props.locationById(recentEntityId);
            // We need to check if `location` is present
            // because there are some timing issues that
            // can occur that cause `recentEntities` and `dataNexus`
            // to become out of sync.
            //
            // When this happens, the `recentEntityId` will not
            // exist in `dataNexus`, and will cause this code to error.
            //
            // See TW-1088 for additional details on how this can occur.
            return (
                !!location && (
                    <LocationPill
                        key={recentEntityId}
                        location={location}
                        onClick={this.props.onClick}
                        testId={testIds.LOCATION_SIDE_PANEL_LOCATION_RESULT}
                    />
                )
            );
        };

        render() {
            return (
                <RecentEntities
                    renForRecents={this.props.renForRecents}
                    entityType={EntityTypeEnum.LOCATION.name}
                    linkType={this.props.linkType}
                    ownerId={this.props.ownerId}
                    renderRecentEntity={this.renderRecentEntity}
                />
            );
        }
    }
);

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Promise from 'bluebird';

import { CommandStatusEnum, NibrsGeneratedSegmentErrorsView } from '@mark43/rms-api';

import { useResourceDeferred } from '~/client-common/core/hooks/useResource';

import generatedSegmentsResource from '../resources/generatedSegmentsResource';
import { currentReportIdSelector } from '../../../../legacy-redux/selectors/reportSelectors';

import { NibrsErrorState } from '../constants';
import {
    NIBRS_SEGMENT_MAX_POLLING_ATTEMPTS,
    NIBRS_SEGMENT_POLL_INTERVAL_MS,
} from '../configuration';

type Poller = (
    reportId: number,
    setErrorsView: (result: NibrsGeneratedSegmentErrorsView) => void,
    pollCount?: number
) => Promise<void>;

const pollForErrorResults: Poller = (reportId, setErrorsView, pollCount = 1) => {
    return Promise.delay(NIBRS_SEGMENT_POLL_INTERVAL_MS)
        .then(() => generatedSegmentsResource.getNibrsErrors(reportId))
        .then((result) => {
            if (pollCount > NIBRS_SEGMENT_MAX_POLLING_ATTEMPTS) {
                setErrorsView({
                    currentStatus: CommandStatusEnum.FAILED.name,
                    hasGeneratedSegments: false,
                    reportId,
                    hasFatalErrors: false,
                    errorDescriptions: [],
                    notGeneratedReasons: [],
                    serializedSegments: '',
                    updatedDateUtc: '',
                });
                return;
            }
            if (result?.currentStatus === CommandStatusEnum.RUNNING.name) {
                const newPollCount = pollCount + 1;
                return pollForErrorResults(reportId, setErrorsView, newPollCount);
            } else {
                setErrorsView(result);
                return;
            }
        })
        .catch(() => {
            setErrorsView({
                currentStatus: CommandStatusEnum.FAILED.name,
                hasGeneratedSegments: false,
                reportId,
                hasFatalErrors: false,
                errorDescriptions: [],
                notGeneratedReasons: [],
                serializedSegments: '',
                updatedDateUtc: '',
            });
            return;
        });
};

export const useGenerateNibrsSegment = (
    setErrorsView: (value: NibrsGeneratedSegmentErrorsView) => void
) => {
    // TODO save / validate / package report before doing this
    const currentReportId = useSelector(currentReportIdSelector);
    const resource = useCallback((reportId: number) => {
        return generatedSegmentsResource.generateNibrsSegment(reportId);
    }, []);

    const onSuccess = useCallback(() => {
        if (!currentReportId) {
            return;
        }
        pollForErrorResults(currentReportId, setErrorsView);
    }, [currentReportId, setErrorsView]);

    return useResourceDeferred(resource, onSuccess);
};

export const useNibrsErrors = () => {
    const [nibrsErrorState, setNibrsErrorState] = useState<NibrsErrorState>(
        NibrsErrorState.NotGenerated
    );
    const currentReportId = useSelector(currentReportIdSelector);
    const [errorsView, setErrorsView] = useState<NibrsGeneratedSegmentErrorsView | undefined>(
        undefined
    );

    useEffect(() => {
        if (errorsView?.currentStatus === CommandStatusEnum.RUNNING.name) {
            setNibrsErrorState(NibrsErrorState.Running);
        } else if (!!errorsView?.notGeneratedReasons.length) {
            setNibrsErrorState(NibrsErrorState.NotReportable);
        } else if (errorsView?.errorDescriptions?.length && errorsView?.hasFatalErrors) {
            setNibrsErrorState(NibrsErrorState.HasFatalErrors);
        } else if (errorsView?.errorDescriptions?.length) {
            setNibrsErrorState(NibrsErrorState.HasNibrsErrors);
        } else if (errorsView?.currentStatus === CommandStatusEnum.FAILED.name) {
            setNibrsErrorState(NibrsErrorState.Failed);
        } else if (errorsView?.hasGeneratedSegments) {
            setNibrsErrorState(NibrsErrorState.NoErrors);
        } else {
            setNibrsErrorState(NibrsErrorState.NotGenerated);
        }
    }, [errorsView, setNibrsErrorState, currentReportId]);

    const onSuccess = useCallback(
        (result) => {
            setErrorsView(result);
            // if segment generation is running, start polling
            if (result.currentStatus === CommandStatusEnum.RUNNING.name && currentReportId) {
                pollForErrorResults(currentReportId, setErrorsView);
            }
            return;
        },
        [currentReportId]
    );

    const {
        callResource: callGetNibrsErrorsResource,
        loading: { errorMessage: getNibrsErrorsErrorMessage, isLoading: getNibrsErrorsIsLoading },
    } = useResourceDeferred(generatedSegmentsResource.getNibrsErrors, onSuccess);

    const disableRunErrorCheck = useMemo(
        () => errorsView?.currentStatus === CommandStatusEnum.RUNNING.name,
        [errorsView]
    );
    const disableViewNibrsDetails = useMemo(
        () =>
            !(
                nibrsErrorState === NibrsErrorState.HasFatalErrors ||
                nibrsErrorState === NibrsErrorState.HasNibrsErrors
            ),
        [nibrsErrorState]
    );
    return {
        callGetNibrsErrorsResource,
        getNibrsErrorsErrorMessage,
        nibrsErrors: errorsView?.hasFatalErrors ? [] : errorsView?.errorDescriptions,
        fatalErrors: errorsView?.hasFatalErrors ? errorsView?.errorDescriptions : [],
        getNibrsErrorsIsLoading,
        nibrsErrorState,
        errorsView,
        setErrorsView,
        disableRunErrorCheck,
        disableViewNibrsDetails,
    };
};

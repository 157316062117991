import { OperationTypeEnum } from '@mark43/rms-api';

import _ from 'lodash';
import entityPermissionsActionTypes from '../actions/types/entityPermissionsActionTypes';
import {
    buildEntityPermissionViewModels,
    convertEntityPermissionsDataStateToFormState,
} from '../helpers/entityPermissionsHelpers';
import { isExternalDepartment } from '../helpers/permissionHelpers';

const initialDataState = {
    entityType: null,
    entityId: null,
    userOperationTypes: [],
    permissions: {}, // arrays of permissions, keyed by entityId
    externalDepartmentPermissions: {}, // arrays of permissions, keyed by entityId
};

export function entityPermissionsDataReducer(state = initialDataState, action) {
    switch (action.type) {
        case entityPermissionsActionTypes.OPEN_PERMISSIONS_MODAL:
            return {
                ...state,
                ...action.payload, // entityType, entityId, userOperationTypes
            };
        case entityPermissionsActionTypes.LOAD_PERMISSIONS_SUCCESS:
        case entityPermissionsActionTypes.UPDATE_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    [state.entityId]: _.reject(action.payload, isExternalDepartment),
                },
                externalDepartmentPermissions: {
                    ...state.externalDepartmentPermissions,
                    [state.entityId]: _.filter(action.payload, isExternalDepartment),
                },
            };
        default:
            return state;
    }
}

const initialUiState = {
    loading: false,
    editable: false, // whether the current user can edit the form
    permissions: {}, // normalized permissions for the current entity only
    externalDepartmentPermissions: {}, // normalized permissions for the current entity only
    handleSubmit: _.noop,
};

export function entityPermissionsUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case entityPermissionsActionTypes.OPEN_PERMISSIONS_MODAL:
            return {
                ...state,
                loading: true,
                editable: _.includes(
                    action.payload.userOperationTypes,
                    OperationTypeEnum.MANAGE.name
                ),
            };
        case entityPermissionsActionTypes.LOAD_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: buildEntityPermissionViewModels(
                    _.reject(action.payload, isExternalDepartment)
                ),
                externalDepartmentPermissions: buildEntityPermissionViewModels(
                    _.filter(action.payload, isExternalDepartment)
                ),
            };
        case entityPermissionsActionTypes.STORE_HANDLE_SUBMIT:
            return {
                ...state,
                handleSubmit: action.payload,
            };
        default:
            return state;
    }
}

export function entityPermissionsFormReducer(state = {}, action) {
    switch (action.type) {
        case entityPermissionsActionTypes.LOAD_PERMISSIONS_SUCCESS:
            return convertEntityPermissionsDataStateToFormState(action.payload);
        default:
            return state;
    }
}

import { isEmpty, map, values } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { countriesSelector } from '~/client-common/core/domain/countries/state/data';
import { loadCountries, countriesUiSelector } from '~/client-common/core/domain/countries/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { arbiterMFTInput } from '../../../arbiter';
import { sortedNameOnlyCountriesSelector } from '../../../current-user/state/ui';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const { label, loadingFailedError } = componentStrings.forms.select.CountrySelect;

/**
 * Select dropdown for countries.
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 */

const CountrySelect = ({ namesOnly, value, ...otherProps }) => {
    const [countryOptions, setCountryOptions] = useState([]);
    const countries = useSelector(countriesSelector);
    const countriesUi = useSelector(countriesUiSelector);
    const sortedNameOnlyCountries = useSelector(sortedNameOnlyCountriesSelector);
    const dispatch = useDispatch();
    const hasAsyncError = !countriesUi?.loading && countriesUi?.error;

    const onCountryFocus = useCallback(
        (onFocus) => {
            if (isEmpty(countries) && !countriesUi?.loading) {
                dispatch(loadCountries());
            }
            if (onFocus) {
                onFocus();
            }
        },
        [countries, countriesUi, dispatch]
    );

    useEffect(() => {
        if (value && isEmpty(countries) && !countriesUi?.loading) {
            dispatch(loadCountries());
        }

        if (namesOnly) {
            setCountryOptions(sortedNameOnlyCountries);
        } else {
            const regularOptions = values(countries);

            if (regularOptions.length) {
                setCountryOptions(regularOptions);
            } else {
                const valueAsArray = value ? [].concat(value) : [];
                const valueOptions = map(valueAsArray, (v) => ({ display: v, value: v }));
                setCountryOptions(valueOptions);
            }
        }
    }, [countries, countriesUi, sortedNameOnlyCountries, value, namesOnly, dispatch]);

    return (
        <Select
            label={label}
            options={countryOptions}
            loading={countriesUi?.loading}
            value={value}
            {...otherProps}
            error={hasAsyncError ? loadingFailedError : undefined}
            onFocus={() => {
                onCountryFocus();
            }}
            forceShowError={hasAsyncError}
        />
    );
};

export default CountrySelect;
export const RRFCountrySelect = connectRRFInput(CountrySelect);
export const ArbiterMFTCountrySelect = arbiterMFTInput(CountrySelect);

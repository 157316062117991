import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { EntityTypeEnum } from '@mark43/rms-api';

import { FormattedDate } from '~/client-common/core/dates/components';
import { getMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { FolderRowView } from '../../../cases/case-attachments/types';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Link from '../../../core/components/links/Link';
import { tableWidths } from '../../../cases/case-attachments/configuration';
import { getCaseFolderPath } from '../../../cases/core/utils/getCaseFolderPath';
import { HandleRowSelectType, SearchFilterContext } from '../../../cases/core/contexts';
import CaseRowCheckbox from '../../../cases/core/components/CaseRowCheckbox';
import testIds from '../../../../core/testIds';
import {
    AttachmentCell,
    UploadDateText,
    DownloadButton,
    AttachmentRow,
    NameCellWrapper,
    NameWrapper,
} from './AttachmentFolderRowComponents';

const strings = componentStrings.cases.caseAttachments.AttachmentTableColumns;

type ColumnNameType =
    | 'checkbox'
    | 'displayName'
    | 'type'
    | 'createdDateUtc'
    | 'description'
    | 'actions'
    | 'remove';

export const defaultColumns: ColumnNameType[] = [
    'checkbox',
    'displayName',
    'type',
    'createdDateUtc',
    'description',
    'actions',
    'remove',
];

function _AttachmentFolderRow({
    columns = defaultColumns,
    folder,
    handleRowSelect,
    isRowSelected,
    isGhosting,
    isDraggingOver,
}: {
    columns?: ColumnNameType[];
    folder: FolderRowView;
    handleRowSelect?: HandleRowSelectType;
    isRowSelected: boolean;
    isGhosting: boolean;
    isDraggingOver: boolean;
}) {
    const getMiniUserById = useSelector(getMiniUserByIdSelector);
    const { setFilter, setCurrentPage } = useContext(SearchFilterContext);
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    if (!folder) {
        return null;
    }
    const { name, createdDateUtc, updatedBy, ownerTypeId, ownerId, id } = folder;

    const user = getMiniUserById(updatedBy);
    const folderPath = getCaseFolderPath({
        caseId: ownerId,
        entityType: EntityTypeEnum.ATTACHMENT.name,
        folderId: id,
    });

    return (
        <AttachmentRow
            isRowSelected={isRowSelected}
            isGhosting={isGhosting}
            isDraggingOver={isDraggingOver}
            data-test-id={testIds.CASE_ATTACHMENT_ROW}
        >
            {columns.includes('checkbox') && (
                <AttachmentCell width={tableWidths.checkbox}>
                    <CaseRowCheckbox
                        value={isRowSelected}
                        onChange={() => {
                            if (handleRowSelect) {
                                handleRowSelect(
                                    id,
                                    isRowSelected,
                                    true,
                                    folder.isEditable,
                                    ownerTypeId
                                );
                            }
                        }}
                        entityType={EntityTypeEnum.ATTACHMENT.name}
                    />
                </AttachmentCell>
            )}
            {columns.includes('displayName') && (
                <AttachmentCell width={tableWidths.displayName}>
                    <NameCellWrapper>
                        <Icon color="cobaltBlue" size={18} type={iconTypes.CASE} />
                        <NameWrapper data-test-id={testIds.CASE_ATTACHMENT_ROW_LINK}>
                            <Link
                                to={folderPath}
                                onClick={() => {
                                    if (setFilter) {
                                        setFilter('');
                                    }
                                    if (setCurrentPage) {
                                        setCurrentPage(1);
                                    }
                                }}
                            >
                                {name}
                            </Link>
                        </NameWrapper>
                    </NameCellWrapper>
                </AttachmentCell>
            )}
            {columns.includes('type') && (
                <AttachmentCell width={tableWidths.type}>{strings.folderType}</AttachmentCell>
            )}
            {columns.includes('createdDateUtc') && (
                <AttachmentCell width={tableWidths.createdDateUtc}>
                    <UploadDateText>
                        <FormattedDate
                            date={createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />
                        {user && <div>by {user.firstInitialAndLastName}</div>}
                    </UploadDateText>
                </AttachmentCell>
            )}
            {columns.includes('description') && (
                <AttachmentCell width={tableWidths.description}>{caseDisplayName}</AttachmentCell>
            )}
            {columns.includes('actions') && (
                <FeatureFlagged
                    flag="RMS_CASE_FOLDERING_ENABLED"
                    fallback={
                        <AttachmentCell width={tableWidths.actions}>
                            <DownloadButton
                                className={buttonTypes.ICON_LINK}
                                iconLeft={
                                    <Icon type={iconTypes.EXPORT} size={20} color="cobaltBlue" />
                                }
                            />
                        </AttachmentCell>
                    }
                />
            )}
        </AttachmentRow>
    );
}

export default styled(_AttachmentFolderRow)`
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
`;

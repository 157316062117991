import { RefContextEnum } from '@mark43/rms-api';
import { every, find, get, map, pick, size } from 'lodash';

import { createFormConfiguration, createNItems, createFieldset, _Form } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';

import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import sortCharges from '~/client-common/core/domain/charges/utils/sortCharges';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';
import { getDateFromReportCards } from '../ui/core';
import {
    defendantPersonProfileAgeForCurrentArrestByReportIdSelector,
    defendantPersonProfileAgeMinForCurrentArrestByReportIdSelector,
    defendantPersonProfileIsJuvenileForCurrentArrestByReportIdSelector,
} from '../ui/booking';
import {
    getArrestForm,
    ARREST_FORM_ARREST_DATE_UTC_PATH,
    buildArrestCardFormModel,
} from './arrestForm';

export const formName = RefContextEnum.FORM_BOOKING.name;
export const getBookingForm = (index) => formsRegistry.get(formName, index);

export const createBookingForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            charges: createNItems({
                fields: {
                    id: {},
                    dispositionAttrId: {
                        fieldName: fields.CHARGE_DISPOSITION_ATTR_ID,
                    },
                    dispositionDateUtc: {
                        fieldName: fields.CHARGE_DISPOSITION_DATE_UTC,
                    },
                    juvenileDispositionAttrId: {
                        fieldName: fields.CHARGE_JUVENILE_DISPOSITION_ATTR_ID,
                    },
                    juvenileDispositionDateUtc: {
                        fieldName: fields.CHARGE_JUVENILE_DISPOSITION_DATE_UTC,
                    },
                    collateralBondReceiptNum: {
                        fieldName: fields.CHARGE_COLLATERAL_BOND_RECEIPT_NUM,
                    },
                    collateralBondAmountPaid: {
                        fieldName: fields.CHARGE_COLLATERAL_BOND_AMOUNT_PAID,
                    },
                },
            }),
            arrest: createFieldset({
                fields: {
                    id: {},
                    lockupNumber: {
                        fieldName: fields.ARREST_LOCKUP_NUMBER,
                    },
                    afisNumber: {
                        fieldName: fields.ARREST_AFIS_NUMBER,
                    },
                    lockupLocation: {
                        fieldName: fields.ARREST_LOCKUP_LOCATION,
                    },
                    lockupDateUtc: {
                        fieldName: fields.ARREST_LOCKUP_DATE_UTC,
                    },
                    // The following fields don't show up in the booking card UI.
                    // These fields are needed for hide/show and validation.
                    arrestDateUtc: {
                        fieldName: fields.ARREST_ARREST_DATE_UTC,
                    },
                },
            }),
            defendantProfile: createFieldset({
                fields: {
                    // The following fields don't show up in the booking card UI.
                    // These fields are needed for hide/show and validation.
                    personProfileIsJuvenile: {
                        fieldName: fields.PERSON_PROFILE_IS_JUVENILE,
                    },
                    personProfileAge: {
                        fieldName: fields.DISPLAY_ONLY_PERSON_PROFILE_AGE,
                    },
                    personProfileAgeMin: {
                        fieldName: fields.PERSON_PROFILE_AGE_MIN,
                    },
                },
            }),
        }),
    });
};

export const buildBookingCardData = ({ reportId, arrestForReportId, chargesWhere }) => {
    const arrest = arrestForReportId(reportId);
    const charges = sortCharges({ charges: chargesWhere({ arrestId: arrest.id }) });
    return { arrest, charges };
};

export const buildBookingCardFormModel = ({ reportId, formIndex }) => (dispatch, getState) => {
    const state = getState();
    const arrestForReportId = arrestForReportIdSelector(state);
    const chargesWhere = chargesWhereSelector(state);
    const { arrest, charges } = buildBookingCardData({
        reportId,
        arrestForReportId,
        chargesWhere,
    });

    const arrestForm = getArrestForm(formIndex);
    const arrestDateUtc = arrestForm
        ? get(arrestForm.get(), ARREST_FORM_ARREST_DATE_UTC_PATH)
        : get(buildArrestCardFormModel({ reportId }), 'arrest.arrestDateUtc');

    const convertedArrest = {
        ...pick(arrest, ['id', 'lockupNumber', 'afisNumber', 'lockupLocation', 'lockupDateUtc']),
        arrestDateUtc,
    };
    const convertedCharges = map(charges, (charge) =>
        pick(charge, [
            'id',
            'dispositionAttrId',
            'dispositionDateUtc',
            'juvenileDispositionAttrId',
            'juvenileDispositionDateUtc',
            'collateralBondReceiptNum',
            'collateralBondAmountPaid',
        ])
    );

    const personProfileAgeForReportByReportId = defendantPersonProfileAgeForCurrentArrestByReportIdSelector(
        state
    );
    const personProfileAgeMinForReportByReportId = defendantPersonProfileAgeMinForCurrentArrestByReportIdSelector(
        state
    );
    const personProfileIsJuvenileForReportByReportId = defendantPersonProfileIsJuvenileForCurrentArrestByReportIdSelector(
        state
    );
    const defendantProfile = {
        personProfileAge: personProfileAgeForReportByReportId(reportId),
        personProfileIsJuvenile: personProfileIsJuvenileForReportByReportId(reportId),
        personProfileAgeMin: personProfileAgeMinForReportByReportId(reportId),
    };

    return {
        arrest: convertedArrest,
        charges: convertedCharges,
        defendantProfile,
    };
};

export const buildBookingCardBundle = ({ reportId, form }) => (dispatch, getState) => {
    const state = getState();
    const arrestForReportId = arrestForReportIdSelector(state);
    const chargesWhere = chargesWhereSelector(state);
    const { charges: rawCharges } = buildBookingCardData({
        reportId,
        arrestForReportId,
        chargesWhere,
    });
    const { model } = form.getState();
    const {
        arrest: { id: arrestId, lockupNumber, afisNumber, lockupLocation, lockupDateUtc },
        charges: formCharges,
    } = model;

    const chargesForUpdate = map(formCharges, (formCharge) => {
        const rawCharge = find(rawCharges, { id: formCharge.id }) || {};
        return {
            ...pick(formCharge, [
                'id',
                'dispositionAttrId',
                'dispositionDateUtc',
                'juvenileDispositionAttrId',
                'juvenileDispositionDateUtc',
                'collateralBondReceiptNum',
                'collateralBondAmountPaid',
            ]),
            arrestId: rawCharge.arrestId,
        };
    });

    return {
        arrestId,
        lockupNumber,
        afisNumber,
        lockupLocation,
        lockupDateUtc,
        charges: chargesForUpdate,
    };
};

export const refreshBookingForm = ({ reportId, index }) => (dispatch) => {
    const form = getBookingForm(index);
    if (!isUndefinedOrNull(form)) {
        const formModel = dispatch(buildBookingCardFormModel({ reportId, formIndex: index }));
        form.set('', formModel);
    }
};

export const refreshBookingCardDependentArrestDateUtcField = ({ arrestDateUtc, formIndex }) => {
    const form = getBookingForm(formIndex);
    if (!isUndefinedOrNull(form)) {
        form.set('arrest.arrestDateUtc', arrestDateUtc || undefined);
    }
};

export const prefillChargeFormData = ({ form, prefillFromChargeId }) => {
    const allChargeFormModels = form.get('charges');
    const chargeFormModelToPrefillFrom = find(allChargeFormModels, { id: prefillFromChargeId });
    if (!isUndefinedOrNull(chargeFormModelToPrefillFrom)) {
        const {
            dispositionAttrId,
            dispositionDateUtc,
            juvenileDispositionAttrId,
            juvenileDispositionDateUtc,
            collateralBondReceiptNum,
            collateralBondAmountPaid,
        } = chargeFormModelToPrefillFrom;

        form.transaction(() => {
            map(allChargeFormModels, (chargeFormModel, index) => {
                const { id: chargeId } = chargeFormModel;
                if (chargeId !== prefillFromChargeId) {
                    form.set(`charges[${index}].dispositionAttrId`, dispositionAttrId);
                    form.set(`charges[${index}].dispositionDateUtc`, dispositionDateUtc);
                    form.set(
                        `charges[${index}].juvenileDispositionAttrId`,
                        juvenileDispositionAttrId
                    );
                    form.set(
                        `charges[${index}].juvenileDispositionDateUtc`,
                        juvenileDispositionDateUtc
                    );
                    form.set(
                        `charges[${index}].collateralBondReceiptNum`,
                        collateralBondReceiptNum
                    );
                    form.set(
                        `charges[${index}].collateralBondAmountPaid`,
                        collateralBondAmountPaid
                    );
                }
            });
        });
    }
};

/**
 * Logic: Prefill the first Booking Charge Arrest Disposition Date to be
 * the Report's Event Start Date, only if all Booking Charges contain no
 * relevant Charge data and the Arrest does not have any lockup data.
 * Essentially, we only prefill if the Booking Form is completely empty.
 */
export const maybePrefillFirstBookingChargeArrestDispositionDate = (index) => (dispatch) => {
    const form = getBookingForm(index);
    if (!isUndefinedOrNull(form)) {
        const allChargeFormModels = form.get('charges');
        const arrestFormModel = form.get('arrest');
        const eventStartDate = dispatch(getDateFromReportCards());

        const areAllFieldsOnModelEmpty = ({ model, fields = [] }) =>
            every(fields, (field) => isUndefinedOrNull(get(model, field)));
        const isArrestLockupDataEmpty = areAllFieldsOnModelEmpty({
            model: arrestFormModel,
            fields: ['lockupNumber', 'afisNumber', 'lockupLocation', 'lockupDateUtc'],
        });
        const areAllBookingChargesEmpty = every(allChargeFormModels, (chargeFormModel) => {
            return areAllFieldsOnModelEmpty({
                model: chargeFormModel,
                fields: [
                    'dispositionAttrId',
                    'dispositionDateUtc',
                    'juvenileDispositionAttrId',
                    'juvenileDispositionDateUtc',
                    'collateralBondReceiptNum',
                    'collateralBondAmountPaid',
                ],
            });
        });

        const shouldPrefill =
            size(allChargeFormModels) > 0 &&
            !isUndefinedOrNull(eventStartDate) &&
            isArrestLockupDataEmpty &&
            areAllBookingChargesEmpty;

        if (shouldPrefill) {
            form.set('charges[0].dispositionDateUtc', eventStartDate);
        }
    }
};

import React from 'react';

interface MaterialItemsContextT {
    selectedMaterialIds: number[];
    setSelectedMaterialIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const EFileMaterialItemsContext = React.createContext<MaterialItemsContextT | undefined>(undefined);

export const EFileMaterialItemsProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [selectedMaterialIds, setSelectedMaterialIds] = React.useState<number[]>([]);
    return (
        <EFileMaterialItemsContext.Provider
            value={{
                selectedMaterialIds,
                setSelectedMaterialIds,
            }}
        >
            {children}
        </EFileMaterialItemsContext.Provider>
    );
};

/**
 * The state of material items currently selected by the user.
 */
export function useMaterialItems(): MaterialItemsContextT {
    const context = React.useContext(EFileMaterialItemsContext);
    if (context === undefined) {
        throw new Error('Must be inside EFileMaterialItemsProvider');
    }
    return context;
}

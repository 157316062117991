import { isFunction, noop } from 'lodash';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';

interface CreateHotKeysConfigProps<E = Element> {
    onClose?: (event: React.KeyboardEvent<E> | React.MouseEvent<E>) => void;
    onSave?: (event?: React.KeyboardEvent<E> | React.MouseEvent<E>) => void;
}
type HotKeysConfig = Partial<
    Record<keyof typeof hotkeysActionEnum, { handler: (event?: React.KeyboardEvent) => void }>
>;

export function createHotKeysConfig<E = Element>({
    onClose,
    onSave,
}: CreateHotKeysConfigProps<E>): HotKeysConfig {
    const {
        SAVE_AND_CLOSE,
        OPEN_LABELS_MODAL,
        CANCEL_AND_CLOSE,
        TRANSITION_ALL_CARDS_TO_EDIT_MODE,
        VALIDATE_REPORT,
        PRINT_REPORT,
        SEARCH_FORM,
    } = hotkeysActionEnum;

    return {
        [CANCEL_AND_CLOSE.name]: {
            handler: (e: React.KeyboardEvent<E>) => {
                if (isFunction(onClose)) {
                    onClose(e);
                }
            },
        },
        [SAVE_AND_CLOSE.name]: {
            handler: () => {
                if (isFunction(onSave)) {
                    onSave();
                }
            },
        },
        // Prevent all remaining actions when a modal is open
        [OPEN_LABELS_MODAL.name]: { handler: noop },
        [TRANSITION_ALL_CARDS_TO_EDIT_MODE.name]: { handler: noop },
        [VALIDATE_REPORT.name]: { handler: noop },
        [PRINT_REPORT.name]: { handler: noop },
        [SEARCH_FORM.name]: { handler: noop },
    };
}

import factory from './actionTypesFactory';

export default factory('armada', [
    'GET_CURRENT_COMMANDS_SUCCESS',
    'GET_CURRENT_COMMANDS_FAILURE',
    'GET_RUNNING_COMMANDS_SUCCESS',
    'GET_RUNNING_COMMANDS_FAILURE',
    'GET_PENDING_COMMANDS_SUCCESS',
    'GET_PENDING_COMMANDS_FAILURE',
    'GET_FAILED_COMMANDS_SUCCESS',
    'GET_FAILED_COMMANDS_FAILURE',
    'GET_SCHEDULES_SUCCESS',
    'GET_SCHEDULES_FAILURE',
    'CREATE_SCHEDULE',
    'CREATE_SCHEDULE_SUCCESS',
    'CREATE_SCHEDULE_FAILURE',
    'UPDATE_SCHEDULE',
    'UPDATE_SCHEDULE_SUCCESS',
    'UPDATE_SCHEDULE_FAILURE',
    'DELETE_SCHEDULE_FAILURE',
    'GET_SCHEDULE_HISTORY_SUCCESS',
    'GET_SCHEDULE_HISTORY_FAILURE',
    'GET_COMMAND_HISTORY_SUCCESS',
    'GET_COMMAND_HISTORY_FAILURE',
    'RETRY_COMMAND',
    'RETRY_COMMAND_SUCCESS',
    'RETRY_COMMAND_FAILURE',
    'CLEAR_COMMAND_SUCCESS',
    'CLEAR_COMMAND_FAILURE',
    'GET_SELECTED_FAILED_COMMAND',
    'GET_SELECTED_FAILED_COMMAND_FORM',
    'SET_SELECTED_SCHEDULE',
    'SET_SELECTED_SCHEDULE_FORM',
    'SET_SCHEDULE_ACTION_CREATE',
]);

import {
    AttributeTypeEnum,
    EntityTypeEnum,
    LinkTypesEnum,
    LocationEntityLink,
    PersonEmergencyContact,
    SchoolHistory,
    ComplianceGroupEnum,
} from '@mark43/rms-api';
import React from 'react';
import {
    chain,
    compact,
    filter,
    get,
    includes,
    isUndefined,
    join,
    map,
    size,
    some,
    isEmpty,
} from 'lodash';
import { useSelector } from 'react-redux';
// @ts-expect-error no .d.ts file for react-composer
import Composer from 'react-composer';
import styled from 'styled-components';

import { ButtonGroup, cssVar } from 'arc';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import GangTrackingDisplay from '~/client-common/core/domain/person-gang-trackings/components/GangTrackingDisplay';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { booleanToYesNo, joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import {
    formatAttendingPhysicians,
    formatIdentifyingMark,
    isUnknownAndIdentified,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { formatPersonInjuriesSelector } from '~/client-common/core/domain/person-injuries/state/ui';
import { personInjuriesByPersonProfileIdSelector } from '~/client-common/core/domain/person-injuries/state/data';
import { FormattedDate } from '~/client-common/core/dates/components';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    PersonProfileViewModel,
    personProfileViewModelByIdSelector,
} from '~/client-common/core/domain/person-profiles/state/ui';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { identifyingMarksDisplayAndImagesByNameIdSelector } from '~/client-common/core/domain/identifying-marks/state/ui';
import { sortedLocationBundlesForLocationEntityLinksWhereSelector } from '~/client-common/core/domain/locations/state/ui';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { EmploymentHistoryViewModel } from '~/client-common/core/domain/employment-histories/state/ui';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { otherPersonLocationTypes } from '~/client-common/helpers/linkTypesHelpers';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import Thumbnail from '../../../../attachments/files/components/Thumbnail';
import { FileGrid } from '../../../../../legacy-redux/components/core/Files';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import {
    convertEmailAddressesToPersonComponentArray,
    convertPhoneNumbersToPersonComponentArray,
} from '../../../../entity-profiles/core/helpers/contactHelpers';
import { convertPassportsToComponentArray } from '../../../../entity-profiles/core/helpers/identifierHelpers';
import { renderOtherAddressLocationNItemsSummary } from '../../../../core/persons/components/nameFormNItemsRenderers';
import WithComplianceGroup from '../../../../core/components/WithComplianceGroup';
import { SummaryExpandCollapseButton } from './buttons/SummaryExpandCollapseButton';
import PersonProfileTitleSummary from './PersonProfileTitleSummary';
import LocationSummary from './LocationSummary';
import LegacyEntityDetailSummaryRow from './LegacyEntityDetailSummaryRow';
import EntitySummary from './EntitySummary';

const strings = componentStrings.summaries.PersonProfileSummary;

const SUMMARY_LIST_LABEL_WIDTH = 125;
const SUMMARY_LIST_CONTENT_WIDTH = 385;
const SUB_SUMMARY_LIST_LABEL_WIDTH = 130;
const SUB_SUMMARY_LIST_CONTENT_WIDTH = 250;
const GANG_SUMMARY_LIST_LABEL_WIDTH = 190;
const GANG_SUMMARY_LIST_CONTENT_WIDTH = 320;

const BottomPaddedDiv = styled.div`
    padding-bottom: 10px;
`;

const NewSection = styled.div<{ $marginTop?: string }>`
    border-top: 1px dashed ${cssVar('arc.colors.border.default')};
    padding: 10px 0;
    margin-top: ${({ $marginTop }) => $marginTop || 0};
`;

const FirstSection = styled.div`
    padding-bottom: 10px;
`;

function EmploymentHistorySummary({
    employmentHistoryViewModel,
}: {
    employmentHistoryViewModel: EmploymentHistoryViewModel;
}) {
    if (!employmentHistoryViewModel || isEmpty(employmentHistoryViewModel)) {
        return null;
    }
    const { dateRange } = getViewModelProperties(employmentHistoryViewModel);
    const { employerName, employerAddress, displayNumber, occupation } = employmentHistoryViewModel;
    return (
        <BottomPaddedDiv>
            <div>
                {employerName} {dateRange ? `(${dateRange})` : ''}
            </div>
            <SummaryList
                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
            >
                <SummaryRow fieldName={fields.EMPLOYMENT_HISTORY_EMPLOYER_ADDRESS}>
                    {employerAddress}
                </SummaryRow>
                <SummaryRow fieldName={fields.EMPLOYMENT_HISTORY_OCCUPATION}>
                    {occupation}
                </SummaryRow>
                <SummaryRow fieldName={fields.EMPLOYMENT_HISTORY_PHONE_NUMBER}>
                    {displayNumber}
                </SummaryRow>
            </SummaryList>
        </BottomPaddedDiv>
    );
}

function DateOfBirthSummary({
    dateOfBirth,
    ageMax,
    ageMin,
    isDobUnknown,
}: {
    dateOfBirth?: string;
    ageMax?: number;
    ageMin?: number;
    isDobUnknown?: boolean;
}) {
    if (dateOfBirth) {
        return (
            <SummaryRowDate
                fieldName={fields.PERSON_PROFILE_DATE_OF_BIRTH}
                date={dateOfBirth}
                format={SummaryRowDate.FORMATS.SUMMARY_DATE}
            />
        );
    } else if (isDobUnknown && !ageMin && !ageMax) {
        return (
            <SummaryRow fieldName={fields.PERSON_PROFILE_DATE_OF_BIRTH}>
                {strings.unknownDate}
            </SummaryRow>
        );
    } else {
        return null;
    }
}

export function ExpectedDateOfBirthSummary({
    expectedDateOfBirth,
    isExpectedDateOfBirthUnknown,
}: {
    expectedDateOfBirth?: string;
    isExpectedDateOfBirthUnknown?: boolean;
}) {
    if (isExpectedDateOfBirthUnknown) {
        return (
            <SummaryRow fieldName={fields.PERSON_PROFILE_EXPECTED_DATE_OF_BIRTH}>
                {strings.unknownDate}
            </SummaryRow>
        );
    } else if (!!expectedDateOfBirth) {
        return (
            <SummaryRowDate
                fieldName={fields.PERSON_PROFILE_EXPECTED_DATE_OF_BIRTH}
                date={expectedDateOfBirth}
                format={SummaryRowDate.FORMATS.SUMMARY_DATE}
            />
        );
    }

    return null;
}

function EmergencyContactSummary({
    emergencyContact,
}: {
    emergencyContact: PersonEmergencyContact;
}) {
    const {
        emergencyContactAddress,
        emergencyContactLinkType,
        emergencyContactName,
        emergencyContactPhoneNumber,
        isGuardian,
        dateEffectiveFrom,
        dateEffectiveTo,
    } = emergencyContact;
    return (
        <BottomPaddedDiv>
            <SummaryList
                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
            >
                <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_NAME}>
                    {emergencyContactName}
                </SummaryRow>
                <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_LINK_TYPE}>
                    {emergencyContactLinkType}
                </SummaryRow>
                <SummaryRow
                    fieldName={fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_PHONE_NUMBER}
                >
                    {emergencyContactPhoneNumber}
                </SummaryRow>
                <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_EMERGENCY_CONTACT_ADDRESS}>
                    {emergencyContactAddress}
                </SummaryRow>
                <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_IS_GUARDIAN}>
                    {booleanToYesNo(isGuardian)}
                </SummaryRow>
                <FeatureFlagged flag="RMS_POLE_DATA_CAPTURE_ENABLED">
                    <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_DATE_EFFECTIVE_FROM}>
                        {dateEffectiveFrom}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.PERSON_EMERGENCY_CONTACT_DATE_EFFECTIVE_TO}>
                        {dateEffectiveTo}
                    </SummaryRow>
                </FeatureFlagged>
            </SummaryList>
        </BottomPaddedDiv>
    );
}

function SchoolHistorySummary({ schoolHistory }: { schoolHistory: SchoolHistory }) {
    const { schoolName, schoolAddress, grade, status, displayNumber } = schoolHistory;
    return (
        <BottomPaddedDiv>
            <SummaryList
                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
            >
                <SummaryRow fieldName={fields.SCHOOL_HISTORY_SCHOOL_NAME}>{schoolName}</SummaryRow>
                <SummaryRow fieldName={fields.SCHOOL_HISTORY_SCHOOL_ADDRESS}>
                    {schoolAddress}
                </SummaryRow>
                <SummaryRow fieldName={fields.SCHOOL_HISTORY_GRADE}>{grade}</SummaryRow>
                <SummaryRow fieldName={fields.SCHOOL_HISTORY_STATUS}>{status}</SummaryRow>
                <SummaryRow fieldName={fields.SCHOOL_HISTORY_PHONE_NUMBER}>
                    {displayNumber}
                </SummaryRow>
            </SummaryList>
        </BottomPaddedDiv>
    );
}

function MedicalSectionSummary({
    personProfileViewModel,
    nameAttributeFormatsByType,
}: {
    personProfileViewModel: PersonProfileViewModel;
    nameAttributeFormatsByType: Record<string, string>;
}) {
    const viewModelProperties = getViewModelProperties(personProfileViewModel);
    const personInjuriesByPersonProfileId = useSelector(personInjuriesByPersonProfileIdSelector);
    const formatPersonInjuries: ReturnType<typeof formatPersonInjuriesSelector> = useSelector(
        formatPersonInjuriesSelector
    );

    const formattedAttendingPhysicians = formatAttendingPhysicians(
        // @ts-expect-error client-common to client RND-7529
        personProfileViewModel.attendingPhysicians
    );

    const formattedPersonInjuries = join(
        formatPersonInjuries(
            personInjuriesByPersonProfileId(personProfileViewModel.id),
            // @ts-expect-error client-common to client RND-7529
            personProfileViewModel.id
        ),
        '\n'
    );

    const formattedMedicalStatisticsAttributes =
        nameAttributeFormatsByType[AttributeTypeEnum.MEDICAL_STATISTICS.name];

    const summaryRowPropsConfig = [
        {
            label: AttributeTypeEnum.MEDICAL_STATISTICS.displayName,
            children: formattedMedicalStatisticsAttributes,
        },
        {
            label: strings.injuries,
            children: formattedPersonInjuries,
        },
        {
            fieldName: fields.PERSON_PROFILE_MEDICAL_TREATMENT_ATTR_ID,
            children: viewModelProperties.medicalTreatmentAttrId,
        },
        {
            fieldName: fields.PERSON_PROFILE_MEDICAL_TRANSPORTATION_TYPE_ATTR_ID,
            children: viewModelProperties.medicalTransportationTypeAttrId,
        },
        {
            fieldName: fields.PERSON_PROFILE_AMBULANCE_NUMBER,
            children: personProfileViewModel.ambulanceNumber,
        },
        {
            fieldName: fields.PERSON_PROFILE_TAKEN_TO_HOSPITAL_BY,
            children: personProfileViewModel.takenToHospitalBy,
        },
        {
            fieldName: fields.PERSON_PROFILE_MEDICAL_FACILITY_ATTR_ID,
            children: viewModelProperties.medicalFacilityAttrId,
        },
        {
            fieldName: fields.PERSON_PROFILE_HOSPITAL_TREATED_AT,
            children: personProfileViewModel.hospitalTreatedAt,
        },
        {
            label: strings.physicians,
            children: formattedAttendingPhysicians,
        },
    ];

    return (
        <>
            {some(map(summaryRowPropsConfig, 'children')) && (
                <NewSection $marginTop="10px">
                    <SummaryList
                        labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                        contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
                    >
                        {map(summaryRowPropsConfig, (summaryRowProps, idx) => (
                            <SummaryRow key={idx} {...summaryRowProps} />
                        ))}
                    </SummaryList>
                </NewSection>
            )}
        </>
    );
}

type PersonProfileSummaryProps = {
    personProfileId: number;
    linkTypeLetterNumber?: string;
    showExpandCollapseButton?: boolean;
    showMugshotThumbnail?: boolean;
    showCautionsAsSnapshot?: boolean;
    additionalItem?: ({ collapsed }: { collapsed: boolean }) => React.ReactElement;
    renderActionButtons?: ({
        isUnknownAndIdentified,
    }: {
        isUnknownAndIdentified: boolean;
    }) => React.ReactNode;
};

export const PersonProfileSummary = ({
    personProfileId,
    linkTypeLetterNumber,
    renderActionButtons,
    additionalItem,
    showExpandCollapseButton = true,
    showMugshotThumbnail = false,
    showCautionsAsSnapshot = false,
}: PersonProfileSummaryProps) => {
    const personProfileViewModelById = useSelector(personProfileViewModelByIdSelector);
    const sortedAugmentedAttachmentViewModelsWhere = useSelector(
        sortedAugmentedAttachmentViewModelsWhereSelector
    );
    const identifyingMarksDisplayAndImagesByNameId: ReturnType<
        typeof identifyingMarksDisplayAndImagesByNameIdSelector
    > = useSelector(identifyingMarksDisplayAndImagesByNameIdSelector);
    const sortedLocationBundlesForLocationEntityLinksWhere: ReturnType<
        typeof sortedLocationBundlesForLocationEntityLinksWhereSelector
    > = useSelector(sortedLocationBundlesForLocationEntityLinksWhereSelector);
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);

    const applicationSettings = useSelector(applicationSettingsSelector);
    const fieldDisplayNames = useFields([
        fields.PERSON_PROFILE_RACE_ATTR_ID,
        fields.PERSON_PROFILE_ETHNICITY_ATTR_ID,
        fields.PERSON_PROFILE_PLACE_OF_BIRTH,
        fields.PERSON_PROFILE_DATE_OF_BIRTH,
    ]);

    const personProfileViewModel = personProfileViewModelById(personProfileId);
    if (!personProfileViewModel) {
        return null;
    }

    const viewModelProperties = getViewModelProperties<PersonProfileViewModel>(
        personProfileViewModel
    );
    const isPoleEnabled = !!applicationSettings['RMS_POLE_DATA_CAPTURE_ENABLED'];

    const attachmentViewModels = sortedAugmentedAttachmentViewModelsWhere((attachmentViewModel) => {
        const linkTypes = [
            LinkTypesEnum.PERSON_PROFILE_ATTACHMENT,
            LinkTypesEnum.DRIVER_LICENSE_FRONT_PHOTO,
            LinkTypesEnum.DRIVER_LICENSE_BACK_PHOTO,
        ];
        return (
            attachmentViewModel.entityId === personProfileId &&
            attachmentViewModel.entityType === EntityTypeEnum.PERSON_PROFILE.name &&
            includes(linkTypes, attachmentViewModel.linkType)
        );
    });

    const clothingAttributes = filter(
        viewModelProperties.nameAttributes,
        (nameAttribute) => nameAttribute.attributeType === AttributeTypeEnum.CLOTHING_TYPE.name
    );

    const gangCriteriaAttributes = filter(
        viewModelProperties.nameAttributes,
        (nameAttribute) => nameAttribute.attributeType === AttributeTypeEnum.GANG_CRITERIA.name
    );

    const homeLocations = sortedLocationBundlesForLocationEntityLinksWhere({
        entityType: EntityTypeEnum.PERSON_PROFILE.name,
        entityId: personProfileId,
        linkType: LinkTypesEnum.LIVES_AT,
    });

    const identifyingMarksImages = chain(identifyingMarksDisplayAndImagesByNameId(personProfileId))
        .map('image')
        .compact()
        .value();

    const nameAttributeFormatsByType = chain(viewModelProperties.nameAttributes)
        .groupBy('attributeType')
        .mapValues((nameAttributes) => {
            return joinTruthyValues(
                map(nameAttributes, (attribute) =>
                    get(getViewModelProperties(attribute), 'displayFormats.descriptionWithParent')
                )
            );
        })
        .value();

    const otherDateOfBirths = compact(map(viewModelProperties.monikers, 'dateOfBirthOther'));

    const workLocations = sortedLocationBundlesForLocationEntityLinksWhere({
        entityType: EntityTypeEnum.PERSON_PROFILE.name,
        entityId: personProfileId,
        linkType: LinkTypesEnum.WORKS_AT,
    });

    const otherLocations = sortedLocationBundlesForLocationEntityLinksWhere(
        (locationEntityLink: LocationEntityLink) => {
            return (
                locationEntityLink.entityType === EntityTypeEnum.PERSON_PROFILE.name &&
                locationEntityLink.entityId === personProfileId &&
                includes(otherPersonLocationTypes, locationEntityLink.linkType)
            );
        }
    );

    return (
        <EntitySummary
            additionalItem={additionalItem}
            collapsible={true}
            isSealed={personProfileViewModel.isExpunged}
            renderTitle={({ collapsed }) => {
                return (
                    <PersonProfileTitleSummary
                        imageUrl={
                            showMugshotThumbnail
                                ? viewModelProperties.mostRecentMugshotImage?.thumbnailSmallFile
                                      ?.fileWebServerPath
                                : ''
                        }
                        collapsed={collapsed}
                        personProfileViewModel={personProfileViewModel}
                        linkTypeLetterNumber={linkTypeLetterNumber}
                        showCautionsAsSnapshot={showCautionsAsSnapshot}
                    />
                );
            }}
            renderActionButtons={({ setCollapsed, collapsed }) => {
                if (personProfileViewModel.isExpunged) {
                    return null;
                }

                return (
                    <ButtonGroup>
                        {showExpandCollapseButton && (
                            <SummaryExpandCollapseButton
                                collapsed={collapsed}
                                setCollapsed={setCollapsed}
                            />
                        )}
                        {!isUndefined(renderActionButtons) &&
                            renderActionButtons({
                                isUnknownAndIdentified:
                                    // @ts-expect-error client-common to client RND-7529
                                    isUnknownAndIdentified(personProfileViewModel),
                            })}
                    </ButtonGroup>
                );
            }}
        >
            <SummaryList
                labelWidth={SUMMARY_LIST_LABEL_WIDTH}
                contentWidth={SUMMARY_LIST_CONTENT_WIDTH}
            >
                {(personProfileViewModel.dateOfBirth ||
                    (personProfileViewModel.isDobUnknown &&
                        !personProfileViewModel.ageMin &&
                        !personProfileViewModel.ageMax) ||
                    nameAttributeFormatsByType.INFANT_AGE ||
                    viewModelProperties.placeOfBirth ||
                    size(otherDateOfBirths) ||
                    personProfileViewModel.dateOfEmancipation ||
                    viewModelProperties.citizenshipAttrId ||
                    viewModelProperties.isResidentOfJurisdictionAttrId ||
                    viewModelProperties.dobAgeRange ||
                    viewModelProperties.isPregnantAttrId ||
                    personProfileViewModel.dateOfDeathUtc ||
                    viewModelProperties.sexAttrId ||
                    viewModelProperties.raceEthnicity ||
                    viewModelProperties.maritalStatusAttrId ||
                    viewModelProperties.sexualOrientationAttrId ||
                    personProfileViewModel.sexualOrientationOther ||
                    viewModelProperties.verificationOfDeathAttrId ||
                    viewModelProperties.religionAttrId ||
                    personProfileViewModel.religionOther ||
                    personProfileViewModel.expectedDateOfBirth ||
                    personProfileViewModel.isExpectedDateOfBirthUnknown ||
                    viewModelProperties.personProbations?.length ||
                    size(homeLocations) > 0 ||
                    size(workLocations) > 0 ||
                    size(otherLocations) > 0 ||
                    size(viewModelProperties.phoneNumbers) > 0 ||
                    size(viewModelProperties.emails) > 0 ||
                    size(viewModelProperties.passports) > 0 ||
                    (viewModelProperties.personEmergencyContacts &&
                        viewModelProperties.personEmergencyContacts.length)) && (
                    <FirstSection>
                        <DateOfBirthSummary
                            isDobUnknown={personProfileViewModel.isDobUnknown}
                            dateOfBirth={personProfileViewModel.dateOfBirth}
                            ageMin={personProfileViewModel.ageMin}
                            ageMax={personProfileViewModel.ageMax}
                        />
                        <ExpectedDateOfBirthSummary
                            expectedDateOfBirth={personProfileViewModel.expectedDateOfBirth}
                            isExpectedDateOfBirthUnknown={
                                personProfileViewModel.isExpectedDateOfBirthUnknown
                            }
                        />
                        <SummaryRow
                            fieldName={
                                fields.DISPLAY_PERSON_PROFILE_DATE_OF_BIRTH_ESTIMATED_AGE_RANGE
                            }
                        >
                            {!personProfileViewModel.dateOfBirth
                                ? viewModelProperties.dobAgeRange
                                : null}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_INFANT_AGE_ATTRIBUTE_ID}
                        >
                            {nameAttributeFormatsByType.INFANT_AGE}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_IS_JUVENILE}>
                            {viewModelProperties.isJuvenile}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_IS_PREGNANT_ATTR_ID}>
                            {viewModelProperties.isPregnantAttrId}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.PERSON_PROFILE_PRIOR_HISTORY_OF_DOMESTIC_VIOLENCE}
                        >
                            {booleanToYesNo(personProfileViewModel.priorHistoryOfDomesticViolence)}
                        </SummaryRow>
                        <Composer
                            components={map(otherDateOfBirths, (otherDateOfBirth, idx) => (
                                <FormattedDate
                                    key={idx}
                                    date={otherDateOfBirth}
                                    format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                                />
                            ))}
                        >
                            {(formattedDates: string[]) => (
                                <SummaryRow fieldName={fields.NAME_MONIKER_DATE_OF_BIRTH_OTHER}>
                                    {join(formattedDates, '; ')}
                                </SummaryRow>
                            )}
                        </Composer>
                        <SummaryRow fieldName={fields.PASSPORT_N_ITEMS_WRAPPER}>
                            {convertPassportsToComponentArray(viewModelProperties.passports)}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_PLACE_OF_BIRTH}>
                            {viewModelProperties.placeOfBirth}
                        </SummaryRow>
                        <SummaryRowDate
                            fieldName={fields.PERSON_PROFILE_DATE_OF_EMANCIPATION}
                            date={personProfileViewModel.dateOfEmancipation}
                            format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                        />
                        <SummaryRow fieldName={fields.PERSON_PROFILE_CITIZENSHIP_ATTR_ID}>
                            {viewModelProperties.citizenshipAttrId}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={fields.PERSON_PROFILE_IS_RESIDENT_OF_JURISDICTION_ATTR_ID}
                        >
                            {viewModelProperties.isResidentOfJurisdictionAttrId}
                        </SummaryRow>
                        <SummaryRowDate
                            fieldName={fields.PERSON_PROFILE_DATE_OF_DEATH_UTC}
                            date={personProfileViewModel.dateOfDeathUtc}
                            format={SummaryRowDate.FORMATS.FORM_DATE_TIME}
                        />
                        <SummaryRow fieldName={fields.PERSON_PROFILE_VERIFICATION_OF_DEATH_ATTR_ID}>
                            {viewModelProperties.verificationOfDeathAttrId}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_SEX_ATTR_ID}>
                            {viewModelProperties.sexAttrId}
                        </SummaryRow>
                        {viewModelProperties.personProbations?.flatMap((item) => (
                            <SummaryList
                                key={item.id}
                                labelWidth={SUMMARY_LIST_LABEL_WIDTH}
                                contentWidth={SUMMARY_LIST_CONTENT_WIDTH}
                            >
                                <SummaryRow
                                    fieldName={fields.PERSON_PROBATION_PROBATION_TYPE_ATTR_ID}
                                >
                                    {formatAttributeById(item.probationTypeAttrId)}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROBATION_PROBATION_OFFICER}>
                                    {item.probationOfficer}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROBATION_MISC_DESCRIPTION}>
                                    {item.miscDescription}
                                </SummaryRow>
                            </SummaryList>
                        ))}
                        <SummaryRow
                            label={`${fieldDisplayNames.PERSON_PROFILE_RACE_ATTR_ID} / ${fieldDisplayNames.PERSON_PROFILE_ETHNICITY_ATTR_ID}`}
                        >
                            {viewModelProperties.raceEthnicity}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_MARITAL_STATUS_ATTR_ID}>
                            {viewModelProperties.maritalStatusAttrId}
                        </SummaryRow>
                        <SummaryRow
                            label={strings.religion}
                            fieldname={fields.PERSON_PROFILE_RELIGION_ATTR_ID}
                        >
                            {viewModelProperties.religionAttrId}
                        </SummaryRow>
                        <SummaryRow
                            label={strings.religionOther}
                            fieldname={fields.PERSON_PROFILE_RELIGION_OTHER}
                        >
                            {personProfileViewModel.religionOther}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_SEXUAL_ORIENTATION_ATTR_ID}>
                            {viewModelProperties.sexualOrientationAttrId}
                        </SummaryRow>
                        <SummaryRow fieldname={fields.PERSON_PROFILE_SEXUAL_ORIENTATION_OTHER}>
                            {personProfileViewModel.sexualOrientationOther}
                        </SummaryRow>
                        <SummaryRow label={strings.homeAddresses}>
                            {map(homeLocations, (homeLocation) => {
                                return (
                                    <LocationSummary
                                        key={homeLocation.location.id}
                                        locationBundle={homeLocation}
                                    />
                                );
                            })}
                        </SummaryRow>
                        <SummaryRow label={strings.workAddresses}>
                            {map(workLocations, (workLocation) => {
                                return (
                                    <LocationSummary
                                        key={workLocation.location.id}
                                        locationBundle={workLocation}
                                    />
                                );
                            })}
                        </SummaryRow>
                        <WithComplianceGroup
                            complianceGroup={ComplianceGroupEnum.UNITED_KINGDOM.name}
                        >
                            {renderOtherAddressLocationNItemsSummary(
                                { locationBundles: otherLocations },
                                formatFieldByName
                            )}
                        </WithComplianceGroup>
                        <SummaryRow
                            label={strings.phones}
                            hidden={!viewModelProperties?.phoneNumbers?.length}
                        >
                            <SummaryList
                                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
                            >
                                {convertPhoneNumbersToPersonComponentArray(
                                    viewModelProperties.phoneNumbers,
                                    isPoleEnabled
                                )}
                            </SummaryList>
                        </SummaryRow>
                        <SummaryRow
                            label={strings.emails}
                            hidden={!viewModelProperties?.emails?.length}
                        >
                            <SummaryList
                                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
                            >
                                {convertEmailAddressesToPersonComponentArray(
                                    viewModelProperties.emails,
                                    isPoleEnabled
                                )}
                            </SummaryList>
                        </SummaryRow>
                        {viewModelProperties.personEmergencyContacts &&
                            viewModelProperties.personEmergencyContacts.length && (
                                <SummaryRow label={strings.emergency}>
                                    {map(viewModelProperties.personEmergencyContacts, (contact) => (
                                        <EmergencyContactSummary
                                            key={contact.id}
                                            emergencyContact={contact}
                                        />
                                    ))}
                                </SummaryRow>
                            )}
                    </FirstSection>
                )}
                {viewModelProperties.personGangTracking && (
                    <NewSection>
                        <SummaryList
                            labelWidth={GANG_SUMMARY_LIST_LABEL_WIDTH}
                            contentWidth={GANG_SUMMARY_LIST_CONTENT_WIDTH}
                        >
                            <SummaryRow fieldName={fields.PERSON_GANG_TRACKING_GANG_NAME_ATTR_ID}>
                                <GangTrackingDisplay
                                    personGangTracking={viewModelProperties.personGangTracking}
                                />
                            </SummaryRow>
                            <SummaryRow
                                fieldName={
                                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_GANG_CRITERIA_ATTRIBUTE_ID
                                }
                            >
                                {' '}
                            </SummaryRow>

                            {map(gangCriteriaAttributes, (nameAttributeViewModel) => {
                                const { displayFormats } = getViewModelProperties(
                                    nameAttributeViewModel
                                );
                                return (
                                    <SummaryRow
                                        key={nameAttributeViewModel.attributeId}
                                        label={displayFormats.attributeWithoutDescription}
                                    >
                                        {nameAttributeViewModel.description}
                                    </SummaryRow>
                                );
                            })}
                        </SummaryList>
                    </NewSection>
                )}
                {(personProfileViewModel.ssn ||
                    personProfileViewModel.dlNumber ||
                    viewModelProperties.dlState ||
                    viewModelProperties.dlStatusAttrId ||
                    viewModelProperties.dlType ||
                    viewModelProperties.dlEndorsementAttrId ||
                    personProfileViewModel.dlCountry ||
                    personProfileViewModel.fbiUcn ||
                    personProfileViewModel.stateIdNumber ||
                    personProfileViewModel.pncId ||
                    personProfileViewModel.croId ||
                    personProfileViewModel.nationalIdNumber ||
                    (viewModelProperties.identifiers &&
                        viewModelProperties.identifiers.length > 0)) && (
                    <NewSection>
                        <SummaryRow label={strings.id}>
                            <SummaryList
                                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
                            >
                                <SummaryRow fieldName={fields.PERSON_PROFILE_SSN}>
                                    {personProfileViewModel.ssn}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_NATIONAL_ID_NUMBER}>
                                    {personProfileViewModel.nationalIdNumber}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_NUMBER}>
                                    {personProfileViewModel.dlNumber}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_STATE_ATTR_ID}>
                                    {viewModelProperties.dlState}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_STATUS_ATTR_ID}>
                                    {viewModelProperties.dlStatusAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_TYPE_ATTR_ID}>
                                    {viewModelProperties.dlType}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={fields.PERSON_PROFILE_DL_ENDORSEMENT_ATTR_ID}
                                >
                                    {viewModelProperties.dlEndorsementAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_DL_COUNTRY}>
                                    {personProfileViewModel.dlCountry}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_FBI_UCN}>
                                    {personProfileViewModel.fbiUcn}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_CRO_ID}>
                                    {personProfileViewModel.croId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_STATE_ID_NUMBER}>
                                    {personProfileViewModel.stateIdNumber}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_PNC_ID}>
                                    {personProfileViewModel.pncId}
                                </SummaryRow>
                                {map(viewModelProperties.identifiers, (nameIdentifierViewModel) => (
                                    <SummaryRow
                                        key={nameIdentifierViewModel.id}
                                        label={
                                            getViewModelProperties(nameIdentifierViewModel)
                                                .nameIdentifierTypeAttrId
                                        }
                                    >
                                        {nameIdentifierViewModel.identifier}
                                    </SummaryRow>
                                ))}
                            </SummaryList>
                        </SummaryRow>
                    </NewSection>
                )}
                {(viewModelProperties.height ||
                    viewModelProperties.weight ||
                    viewModelProperties.eyeColorAttrId ||
                    viewModelProperties.hairColorAttrId ||
                    viewModelProperties.hairLengthAttrId ||
                    viewModelProperties.hairStyleAttrId ||
                    viewModelProperties.facialHairTypeAttrId ||
                    viewModelProperties.visionAttrId ||
                    viewModelProperties.buildAttrId ||
                    viewModelProperties.skinToneAttrId) && (
                    <NewSection>
                        <SummaryRow label={strings.appearance}>
                            <SummaryList
                                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
                            >
                                <SummaryRow fieldName={fields.PERSON_PROFILE_HEIGHT}>
                                    {viewModelProperties.height}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_WEIGHT}>
                                    {viewModelProperties.weight}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_EYE_COLOR_ATTR_ID}>
                                    {viewModelProperties.eyeColorAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.DISPLAY_PERSON_PROFILE_HAIR_INFO}>
                                    {joinTruthyValues(
                                        [
                                            viewModelProperties.hairColorAttrId,
                                            viewModelProperties.hairLengthAttrId,
                                            viewModelProperties.hairStyleAttrId,
                                        ],
                                        ' / '
                                    )}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={fields.PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID}
                                >
                                    {viewModelProperties.facialHairTypeAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_VISION_ATTR_ID}>
                                    {viewModelProperties.visionAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_BUILD_ATTR_ID}>
                                    {viewModelProperties.buildAttrId}
                                </SummaryRow>
                                <SummaryRow fieldName={fields.PERSON_PROFILE_SKIN_TONE_ATTR_ID}>
                                    {viewModelProperties.skinToneAttrId}
                                </SummaryRow>
                            </SummaryList>
                        </SummaryRow>
                    </NewSection>
                )}
                {clothingAttributes.length > 0 && (
                    <NewSection>
                        <SummaryRow label={strings.clothing}>
                            <SummaryList
                                labelWidth={SUB_SUMMARY_LIST_LABEL_WIDTH}
                                contentWidth={SUB_SUMMARY_LIST_CONTENT_WIDTH}
                            >
                                {map(clothingAttributes, (nameAttributeViewModel) => {
                                    const { displayFormats } = getViewModelProperties(
                                        nameAttributeViewModel
                                    );
                                    return (
                                        <SummaryRow
                                            key={nameAttributeViewModel.attributeId}
                                            label={displayFormats.attributeWithoutDescription}
                                        >
                                            {nameAttributeViewModel.description}
                                        </SummaryRow>
                                    );
                                })}
                            </SummaryList>
                        </SummaryRow>
                    </NewSection>
                )}
                {viewModelProperties.identifyingMarks &&
                    viewModelProperties.identifyingMarks.length > 0 && (
                        <SummaryRow label={strings.identifyingMarks}>
                            {map(
                                viewModelProperties.identifyingMarks,
                                (identifyingMarkViewModel) => {
                                    return (
                                        <div key={identifyingMarkViewModel.id}>
                                            {formatIdentifyingMark(identifyingMarkViewModel)}
                                        </div>
                                    );
                                }
                            )}
                            {map(identifyingMarksImages, (image, index) => {
                                return (
                                    <Thumbnail
                                        key={index}
                                        image={image}
                                        index={index}
                                        images={identifyingMarksImages}
                                    />
                                );
                            })}
                        </SummaryRow>
                    )}
                {((viewModelProperties.militaryHistories &&
                    viewModelProperties.militaryHistories.length > 0) ||
                    (viewModelProperties.employmentHistories &&
                        viewModelProperties.employmentHistories.length > 0)) && (
                    <NewSection>
                        <SummaryRow label={strings.military}>
                            {map(
                                viewModelProperties.militaryHistories,
                                (militaryHistoryViewModel) => {
                                    const { dateRange } = getViewModelProperties(
                                        militaryHistoryViewModel
                                    );
                                    const militaryBranch = get(
                                        militaryHistoryViewModel,
                                        'employerName'
                                    );
                                    return (
                                        <div key={militaryHistoryViewModel.id}>
                                            {militaryBranch} {dateRange ? `(${dateRange})` : ''}
                                        </div>
                                    );
                                }
                            )}
                        </SummaryRow>
                        <SummaryRow label={strings.employment}>
                            {map(
                                viewModelProperties.employmentHistories,
                                (employmentHistoryViewModel) => (
                                    <EmploymentHistorySummary
                                        key={employmentHistoryViewModel.id}
                                        employmentHistoryViewModel={employmentHistoryViewModel}
                                    />
                                )
                            )}
                        </SummaryRow>
                    </NewSection>
                )}
                {((viewModelProperties.schoolHistories &&
                    viewModelProperties.schoolHistories.length > 0) ||
                    nameAttributeFormatsByType.MOOD ||
                    nameAttributeFormatsByType.PHYSICAL_CHARACTERISTIC ||
                    nameAttributeFormatsByType.BEHAVIORAL_CHARACTERISTIC ||
                    nameAttributeFormatsByType.MODUS_OPERANDI ||
                    nameAttributeFormatsByType.PERSON_SKILL ||
                    viewModelProperties.needsInterpreter ||
                    viewModelProperties.isNonDisclosureRequest ||
                    nameAttributeFormatsByType.LANGUAGE ||
                    nameAttributeFormatsByType.SUBJECT_DATA_DISABILITY ||
                    nameAttributeFormatsByType.VICTIM_DISABILITY_TYPE ||
                    personProfileViewModel.needsInterpreter ||
                    personProfileViewModel.details ||
                    attachmentViewModels.length > 0) && (
                    <NewSection>
                        <SummaryRow label={strings.school}>
                            {map(viewModelProperties.schoolHistories, (schoolHistory) => (
                                <SchoolHistorySummary
                                    key={schoolHistory.id}
                                    schoolHistory={schoolHistory}
                                />
                            ))}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.DISPLAY_ATTRIBUTE_MOOD}>
                            {nameAttributeFormatsByType.MOOD}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.DISPLAY_ATTRIBUTE_PHYSICAL_CHARACTERISTIC}>
                            {nameAttributeFormatsByType.PHYSICAL_CHARACTERISTIC}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.DISPLAY_ATTRIBUTE_BEHAVIORAL_CHARACTERISTIC}>
                            {nameAttributeFormatsByType.BEHAVIORAL_CHARACTERISTIC}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.DISPLAY_ATTRIBUTE_MODUS_OPERANDI}>
                            {nameAttributeFormatsByType.MODUS_OPERANDI}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.DISPLAY_ATTRIBUTE_PERSON_SKILL}>
                            {nameAttributeFormatsByType.PERSON_SKILL}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_NEEDS_INTERPRETER}>
                            {viewModelProperties.needsInterpreter}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_IS_NON_DISCLOSURE_REQUEST}>
                            {viewModelProperties.isNonDisclosureRequest}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.DISPLAY_ATTRIBUTE_LANGUAGE}>
                            {nameAttributeFormatsByType.LANGUAGE}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_VICTIM_DISABILITY_TYPE_ATTRIBUTE_ID
                            }
                        >
                            {nameAttributeFormatsByType.VICTIM_DISABILITY_TYPE}
                        </SummaryRow>
                        <SummaryRow
                            fieldName={
                                fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_SUBJECT_DATA_DISABILITY_ATTRIBUTE_ID
                            }
                        >
                            {nameAttributeFormatsByType.SUBJECT_DATA_DISABILITY}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.PERSON_PROFILE_DETAILS}>
                            {personProfileViewModel.details}
                        </SummaryRow>
                        <SummaryRow label={strings.attachments}>
                            {attachmentViewModels.length > 0 && (
                                <FileGrid fileLinks={attachmentViewModels} numOfColumns={1} />
                            )}
                        </SummaryRow>
                    </NewSection>
                )}
                <MedicalSectionSummary
                    personProfileViewModel={personProfileViewModel}
                    nameAttributeFormatsByType={nameAttributeFormatsByType}
                />
                <LegacyEntityDetailSummaryRow
                    legacyEntityDetails={viewModelProperties.legacyEntityDetails}
                />
            </SummaryList>
        </EntitySummary>
    );
};

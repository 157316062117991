import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Form, createFormConfiguration, createNItems, lifecycleOptions } from 'markformythree';
import { compose, withHandlers } from 'recompose';
import { min } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    addUploadingFiles,
    saveUploadedFiles,
    uploadFileFailure,
    attachmentsShownCountSelector,
    viewMoreAttachments,
    clearDirtyFilesFromSidePanel,
} from '../state/ui/attachmentsSidePanel';
import { ATTACHMENT_SIDEPANEL_FORM_NAME, VIEW_MORE_INCREMENT } from '../configuration';
import Row from '../../../core/components/Row';
import Upload from '../../../core/forms/components/Upload';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { buildFilesToSave, buildUploadingFiles } from '../utils/uploadFilesHelpers';
import AttachmentsTable from './AttachmentsTable';

const strings = componentStrings.reports.core.AttachmentsSidePanel;

const FileUploadWrapper = styled.div`
    margin-bottom: 20px;
    position: relative;
`;

const ViewMoreButton = styled(Button)`
    && {
        background: none;
        border: none;
        padding: 10px 15px;
        margin: 0;
        font: ${(props) =>
            `${props.theme.fontWeights.semiBold} 13px ${props.theme.fontFamilies.proximaNova}`};
        width: 100%;
        text-align: left;
    }
`;

const formConfiguration = createFormConfiguration({
    fileDescriptions: createNItems({
        fields: {
            id: {},
            description: {},
        },
    }),
});

const initialState = {
    fileDescriptions: [],
};

const AttachmentsSidePanelUploadForm = ({
    onUploadFilesSuccess,
    onUploadFilesStart,
    onUploadFailure,
    onAllBatchesComplete,
    attachments,
    attachmentsShownCount,
    entityLinkDetails,
    hasAttachments,
    onClickViewMoreAttachments,
    overlayBase,
    autoOpenDisabled,
    entityType,
}) => {
    const viewMoreCount = min([attachments.length - attachmentsShownCount, VIEW_MORE_INCREMENT]);
    const dispatch = useDispatch();
    const [uploadRef, setUploadRef] = useState();
    // prevent auto open if: 1) there are any initial uploads/attachments OR
    // 2) imageLinkType. because we open AttachmentsSidePanelImageUploadForm, which has option
    // for profile picture in addition to this attachments form.
    const initialPreventAutoOpen =
        hasAttachments || entityLinkDetails.imageLinkType || autoOpenDisabled;
    const [preventAutoOpen, setPreventAutoOpen] = useState(initialPreventAutoOpen);

    useEffect(() => {
        if (preventAutoOpen || !uploadRef || !uploadRef.open) {
            return;
        }

        uploadRef.open();
        setPreventAutoOpen(true);
    }, [preventAutoOpen, setPreventAutoOpen, uploadRef]);

    const removeDirtyFilesFromSidePanel = useCallback(
        (dirtyUploadingFiles) => {
            dispatch(clearDirtyFilesFromSidePanel(dirtyUploadingFiles));
        },
        [dispatch]
    );

    return (
        <Form
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={ATTACHMENT_SIDEPANEL_FORM_NAME}
            configuration={formConfiguration}
            initialState={initialState}
            render={() => {
                return (
                    <div>
                        <Row>
                            <FileUploadWrapper>
                                <Upload
                                    multiple={true}
                                    onSuccess={onUploadFilesSuccess}
                                    onStart={onUploadFilesStart}
                                    onError={onUploadFailure}
                                    onFileInfected={(dirtyFiles, uploadingDirtyFiles) => {
                                        removeDirtyFilesFromSidePanel(uploadingDirtyFiles);
                                    }}
                                    onAllBatchesComplete={onAllBatchesComplete}
                                    setUploadRef={setUploadRef}
                                >
                                    <Button className={buttonTypes.PRIMARY}>
                                        {strings.addAttachmentButtonText}
                                    </Button>
                                </Upload>
                            </FileUploadWrapper>
                        </Row>
                        <AttachmentsTable
                            attachments={attachments}
                            overlayBase={overlayBase}
                            entityType={entityType}
                        />
                        {viewMoreCount > 0 && (
                            <Row>
                                <ViewMoreButton
                                    className={buttonTypes.SECONDARY}
                                    onClick={onClickViewMoreAttachments}
                                >
                                    {strings.viewMoreText(viewMoreCount)}
                                </ViewMoreButton>
                            </Row>
                        )}
                    </div>
                );
            }}
        />
    );
};

export default compose(
    connect(
        createStructuredSelector({
            attachmentsShownCount: attachmentsShownCountSelector,
        }),
        {
            addUploadingFiles,
            saveUploadedFiles,
            uploadFileFailure,
            viewMoreAttachments,
        }
    ),
    withHandlers({
        onClickViewMoreAttachments: ({
            attachmentsShownCount,
            attachments,
            viewMoreAttachments,
        }) => () => viewMoreAttachments(attachmentsShownCount, attachments),
        onUploadFailure: ({ overlayBase, uploadFileFailure }) => (error, errorType, files) => {
            const errorMessage = uploadFileFailure(error, files, overlayBase);

            if (errorMessage) {
                overlayBase.setError(errorMessage);
            }

            overlayBase.setLoading(false);
        },
        onUploadFilesStart: ({ addUploadingFiles, entityLinkDetails, overlayBase }) => (
            uploads
        ) => {
            overlayBase.setLoading(true);
            const uploadingFiles = buildUploadingFiles(uploads, entityLinkDetails);
            addUploadingFiles(uploadingFiles);
        },
        onUploadFilesSuccess: ({ entityLinkDetails, saveUploadedFiles, overlayBase }) => (
            uploadedFiles
        ) => {
            const files = buildFilesToSave(uploadedFiles, entityLinkDetails);
            const hasFailedUploads = saveUploadedFiles(files);

            if (!hasFailedUploads) {
                overlayBase.setError();
                overlayBase.setLoading(true); // resetting because setError automatically sets isLoading to false
            }
        },
        onAllBatchesComplete: ({ overlayBase }) => () => {
            overlayBase.setLoading(false);
        },
    })
)(AttachmentsSidePanelUploadForm);

import isArray from 'lodash/isArray';
import map from 'lodash/fp/map';
import join from 'lodash/fp/join';
import flow from 'lodash/fp/flow';

import { DispositionStatusEnum, DispositionStatusEnumType } from '@mark43/rms-api';

/**
 * For a given disposition status or arry of statuses, return a display string.
 */

export function formatDispositionStatus(
    dispositionStatus: DispositionStatusEnumType
): string[] | string {
    if (isArray(dispositionStatus)) {
        return flow(
            map((status: DispositionStatusEnumType) => formatDispositionStatusByStatus(status)),
            join(', ')
        )(dispositionStatus);
    }
    return formatDispositionStatusByStatus(dispositionStatus);
}

function formatDispositionStatusByStatus(dispositionStatus: DispositionStatusEnumType): string {
    switch (dispositionStatus) {
        case DispositionStatusEnum.APPROVED_FOR_RELEASE.name:
            return 'Approved for Release to Owner';
        case DispositionStatusEnum.APPROVED_FOR_DISPOSITION.name:
            return 'Approved for Disposition';
        case DispositionStatusEnum.WAITING_RETENTION_PERIOD.name:
            return 'Active Retention Period';
        case DispositionStatusEnum.PENDING_PRIMARY_REVIEW.name:
            return 'Pending Disposition Review';
        case DispositionStatusEnum.PENDING_SECONDARY_REVIEW_FOR_RELEASE.name:
            return 'Pending Secondary Approval for Release';
        case DispositionStatusEnum.PENDING_SECONDARY_REVIEW_FOR_DISPOSITION.name:
            return 'Pending Secondary Approval for Disposition';
        case DispositionStatusEnum.DISPOSITION_COMPLETED.name:
            return 'Disposition Completed';
        case DispositionStatusEnum.INELIGIBLE_FOR_DISPOSITION.name:
            return 'Ineligible for Disposition';
        default:
            return '';
    }
}

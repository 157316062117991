import { EntityTypeEnum, UsageActionEnum } from '@mark43/rms-api';

export const entityTypeParamToEnum = {
    organizations: EntityTypeEnum.ORGANIZATION_PROFILE.name,
    persons: EntityTypeEnum.PERSON_PROFILE.name,
    property: EntityTypeEnum.ITEM_PROFILE.name,
    vehicles: EntityTypeEnum.ITEM_PROFILE.name,
} as const;

export const entityTypeSectionToUsageActionEnum = {
    organizations: UsageActionEnum.VIEWED_ORGANIZATION_PROFILE.name,
    vehicles: UsageActionEnum.VIEWED_VEHICLE_PROFILE.name,
    property: UsageActionEnum.VIEWED_PROPERTY_PROFILE.name,
    persons: UsageActionEnum.VIEWED_PERSON_PROFILE.name,
} as const;

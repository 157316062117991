import { RoleTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { some, uniq } from 'lodash';
import pluralize from 'pluralize';

import styled from 'styled-components';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { statusIsClosedSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import { elasticReportViewModelsSelector } from '~/client-common/core/domain/elastic-reports/state/ui';
import { reportShortTitleViewModelsSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { currentUserUserRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    DISPLAY_CASE_ASSIGNEE,
    EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID,
    DISPLAY_ONLY_CASE_SUPERVISOR_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import withFields from '~/client-common/core/fields/components/withFields';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import testIds from '../../../../core/testIds';

import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import caseDetailsForm from '../state/form/caseDetailsForm';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Row from '../../../core/components/Row';
import { RRFFieldset, RRFCollapsibleFieldset } from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFCaseDefinitionSelect } from '../../../core/forms/components/selects/CaseDefinitionSelect';
import { RRFRoleSelect } from '../../../core/forms/components/selects/RoleSelect';
import {
    modalHalfFormWidth,
    modalSmallFormWidth,
    dateTimePickerTotalWidth,
} from '../../../../legacy-redux/configs/formsConfig';
import { currentUserCasePermissionsSelector } from '../state/ui';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';

const { withRRFFieldValues, withRRFActions } = reactReduxFormHelpers;
const strings = componentStrings.cases.core.CaseDetailsForm;

const AssignToMeButton = styled(Button)`
    margin-top: 19px;
`;

const AddMeAsSupervisorButton = styled(Button)`
    margin-top: 0;
`;

const CaseInformationFieldset = compose(
    withRRFFieldValues({
        localId: 'caseInformation.localId',
    }),
    connect(
        createStructuredSelector({
            currentUserUserRoleId: currentUserUserRoleIdSelector,
            userCasePermissions: currentUserCasePermissionsSelector,
            formModelByPath: caseDetailsForm.selectors.formModelByPathSelector,
        })
    ),
    withRRFActions,
    withHandlers({
        onAssignToMe({ formActions: { change }, currentUserUserRoleId }) {
            return () => change('caseInformation.assignee', currentUserUserRoleId);
        },
        onAssignAssistingToMe({ formActions: { change }, currentUserUserRoleId, formModelByPath }) {
            return () => {
                const path = 'caseInformation.assistingInvestigators';
                const investigators = formModelByPath(path);
                change(path, uniq([...investigators, currentUserUserRoleId]));
            };
        },
    }),
    withFields([DISPLAY_CASE_ASSIGNEE])
)(function CaseInformationFieldset({
    onAssignToMe,
    onAssignAssistingToMe,
    userCasePermissions,
    fieldDisplayNames,
}) {
    const { canEventuallyApprove } = userCasePermissions;
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    return (
        <RRFFieldset
            path="caseInformation"
            title={strings.titles.caseInformation(caseDisplayName)}
            highlightOnFocus={false}
        >
            <Row>
                <RRFCaseDefinitionSelect
                    clearable={false}
                    path="caseDefinitionId"
                    width={modalHalfFormWidth}
                />
                <RRFDatePicker
                    disabled={!canEventuallyApprove}
                    label={strings.labels.dueDate}
                    path="dueDateUtc"
                    width={modalHalfFormWidth}
                />
            </Row>
            <Row>
                <RRFText
                    label={strings.labels.title(caseDisplayName)}
                    width={modalHalfFormWidth}
                    path="title"
                />
            </Row>
            <Row>
                <RRFAttributeSelect
                    attributeType="PERSONNEL_UNIT"
                    path="assignedPersonnelUnitAttrId"
                    width={modalHalfFormWidth}
                    label={strings.labels.assignedUnit}
                />
                <RRFRoleSelect
                    roleTypes={[RoleTypeEnum.USER.name]}
                    path="assignee"
                    width={modalHalfFormWidth}
                    label={fieldDisplayNames.DISPLAY_CASE_ASSIGNEE}
                />
                <AssignToMeButton
                    className={buttonTypes.SECONDARY}
                    onClick={onAssignToMe}
                    testId={testIds.ASSIGN_CASE_TO_ME_BUTTON}
                >
                    {strings.buttonText.assignToMe}
                </AssignToMeButton>
            </Row>
            <Row>
                <RRFRoleSelect
                    roleTypes={[RoleTypeEnum.USER.name]}
                    path="assistingInvestigators"
                    width={modalHalfFormWidth}
                    label={strings.labels.assistingInvestigators}
                    multiple={true}
                />
                <AssignToMeButton className={buttonTypes.SECONDARY} onClick={onAssignAssistingToMe}>
                    {strings.buttonText.addMe}
                </AssignToMeButton>
            </Row>
        </RRFFieldset>
    );
});

const SupervisorsFieldset = compose(
    withRRFFieldValues({
        supervisors: 'supervisors',
    }),
    connect(
        createStructuredSelector({
            currentUserUserRoleId: currentUserUserRoleIdSelector,
        })
    ),
    withRRFActions,
    withHandlers({
        onAddMeAsSupervisor({ formActions: { change }, currentUserUserRoleId, supervisors }) {
            return () => {
                if (some(supervisors, { roleId: currentUserUserRoleId })) {
                    return;
                }
                // we need to account for the initial placeholder row when there are no supervisors,
                // so replace the whole array rather than appending to it.
                const newSupervisors =
                    !supervisors[0].roleId && supervisors.length === 1
                        ? [{ roleId: currentUserUserRoleId }]
                        : [...supervisors, { roleId: currentUserUserRoleId }];
                change('supervisors', newSupervisors);
            };
        },
    }),
    withFields([DISPLAY_ONLY_CASE_SUPERVISOR_LABEL])
)(function SupervisorsFieldset({ onAddMeAsSupervisor, fieldDisplayNames }) {
    return (
        <RRFFieldset
            title={pluralize(fieldDisplayNames.DISPLAY_ONLY_CASE_SUPERVISOR_LABEL)}
            highlightOnFocus={false}
            path="supervisors"
        >
            <AddMeAsSupervisorButton
                className={buttonTypes.SECONDARY}
                onClick={onAddMeAsSupervisor}
            >
                {strings.buttonText.addMeAsSupervisor(
                    fieldDisplayNames.DISPLAY_ONLY_CASE_SUPERVISOR_LABEL
                )}
            </AddMeAsSupervisorButton>
            <RRFNItems
                addItemOnEmpty={true}
                addText={fieldDisplayNames.DISPLAY_ONLY_CASE_SUPERVISOR_LABEL}
            >
                {() => (
                    <RRFRoleSelect
                        roleTypes={[RoleTypeEnum.USER.name]}
                        path="roleId"
                        width={modalHalfFormWidth}
                        label={strings.labels.userRole}
                    />
                )}
            </RRFNItems>
        </RRFFieldset>
    );
});

const mapDispatchToProps = (dispatch) => ({
    setReportCaseStatusDefaultStatusDate: (i) => {
        dispatch(
            caseDetailsForm.actionCreators.changePath(
                `caseStatus.reportCaseStatuses[${i}].statusDateUtc`,
                nowUtc()
            )
        );
    },
    setCaseStatusDefaultDate: () => {
        dispatch(caseDetailsForm.actionCreators.changePath(`caseStatus.statusDateUtc`, nowUtc()));
    },
});

// only have case status field. If case status is set to closed, the
// other fields will appear.
const CaseStatusFieldset = compose(
    withRRFFieldValues({
        statusAttrId: 'caseStatus.statusAttrId',
        reportCaseStatuses: 'caseStatus.reportCaseStatuses',
    }),
    connect(
        createStructuredSelector({
            statusIsClosed: statusIsClosedSelector,
            formatFieldByName: formatFieldByNameSelector,
            reportShortTitleViewModels: reportShortTitleViewModelsSelector,
            elasticReportsViewModels: elasticReportViewModelsSelector,
            currentUserHasAbility: currentUserHasAbilitySelector,
            formModelByPath: caseDetailsForm.selectors.formModelByPathSelector,
        }),
        mapDispatchToProps
    )
)(function CaseStatusFieldset({
    reportCaseStatuses = [],
    statusAttrId,
    statusIsClosed,
    formatFieldByName,
    reportShortTitleViewModels,
    elasticReportsViewModels,
    currentUserHasAbility,
    setReportCaseStatusDefaultStatusDate,
    setCaseStatusDefaultDate,
    formModelByPath,
}) {
    const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);
    const caseStatusTitle = formatFieldByName(
        EXTERNAL_REPORT_STATUS_EXTERNAL_REPORT_STATUS_ATTR_ID
    );
    return (
        <RRFCollapsibleFieldset
            path="caseStatus"
            collapsed={false}
            title={caseStatusTitle}
            highlightOnFocus={false}
            expandedChildren={
                reportCaseStatuses.length > 0 && (
                    <RRFFieldset
                        className="case-details-form-report-statuses"
                        highlightOnFocus={false}
                    >
                        <RRFNItems
                            addItemOnEmpty={false}
                            automaticallyIncludeDeleteButton={false}
                            path="reportCaseStatuses"
                        >
                            {(rowData, i) => {
                                // during case creation, we have ReportShortTitles
                                const reportShortTitleViewModel =
                                    rowData && reportShortTitleViewModels[rowData.reportId];
                                const { shortTitleWithRen } = getViewModelProperties(
                                    reportShortTitleViewModel
                                );

                                // for existing cases, we have ElasticReports
                                const elasticReportsViewModel =
                                    rowData && elasticReportsViewModels[rowData.reportId];
                                const { renWithShortTitle } = getViewModelProperties(
                                    elasticReportsViewModel
                                );

                                // use whichever title we have
                                const reportCaseStatusTitle = `${renDisplayName} ${
                                    shortTitleWithRen || renWithShortTitle
                                }`;
                                const userHasEditReportCaseStatusAbility = currentUserHasAbility(
                                    abilitiesEnum.REPORTING.EDIT_CASE_STATUS
                                );

                                return (
                                    <div>
                                        <div className="report-status-header">
                                            {reportCaseStatusTitle}
                                        </div>
                                        <RRFAttributeSelect
                                            attributeType="CASE_STATUS"
                                            disabled={!userHasEditReportCaseStatusAbility}
                                            path="caseStatusAttrId"
                                            width={modalSmallFormWidth}
                                            label={strings.labels.status}
                                            onChange={(caseStatusAttrId) => {
                                                if (
                                                    !rowData.statusDateUtc &&
                                                    statusIsClosed(caseStatusAttrId)
                                                ) {
                                                    setReportCaseStatusDefaultStatusDate(i);
                                                }
                                            }}
                                        />
                                        {rowData && statusIsClosed(rowData.caseStatusAttrId) && (
                                            <div>
                                                <RRFAttributeSelect
                                                    attributeType="DIVISION"
                                                    disabled={!userHasEditReportCaseStatusAbility}
                                                    label={strings.labels.closedByDivision}
                                                    width={modalSmallFormWidth}
                                                    path="closedByDivisionAttrId"
                                                />
                                                <RRFAttributeSelect
                                                    attributeType="PERSONNEL_UNIT"
                                                    disabled={!userHasEditReportCaseStatusAbility}
                                                    label={strings.labels.closedByUnit}
                                                    width={modalSmallFormWidth}
                                                    path="closedByUnitAttrId"
                                                />
                                                <RRFDatePicker
                                                    disabled={!userHasEditReportCaseStatusAbility}
                                                    label={strings.labels.statusDate}
                                                    includeTime={true}
                                                    width={modalHalfFormWidth}
                                                    path="statusDateUtc"
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        </RRFNItems>
                    </RRFFieldset>
                )
            }
        >
            {(expandCollapseButton) => {
                const userHasEditCaseStatusAbility = currentUserHasAbility(
                    abilitiesEnum.CASES.EDIT_STATUSES
                );
                return (
                    <Row>
                        <RRFAttributeSelect
                            attributeType="CASE_STATUS"
                            disabled={!userHasEditCaseStatusAbility}
                            width={modalHalfFormWidth}
                            path="statusAttrId"
                            label={caseStatusTitle}
                            onChange={(caseStatusAttrId) => {
                                if (
                                    !formModelByPath('caseStatus.statusDateUtc') &&
                                    statusIsClosed(caseStatusAttrId)
                                ) {
                                    setCaseStatusDefaultDate();
                                }
                            }}
                        />
                        {statusIsClosed(statusAttrId) && (
                            <div>
                                <RRFAttributeSelect
                                    attributeType="DIVISION"
                                    disabled={!userHasEditCaseStatusAbility}
                                    width={modalSmallFormWidth}
                                    label={strings.labels.closedByDivision}
                                    path="closedByDivisionAttrId"
                                />
                                <RRFAttributeSelect
                                    attributeType="PERSONNEL_UNIT"
                                    disabled={!userHasEditCaseStatusAbility}
                                    width={modalSmallFormWidth}
                                    label={strings.labels.closedByUnit}
                                    path="closedByUnitAttrId"
                                />
                                <RRFDatePicker
                                    disabled={!userHasEditCaseStatusAbility}
                                    width={dateTimePickerTotalWidth}
                                    label={strings.labels.statusDate}
                                    includeTime={true}
                                    path="statusDateUtc"
                                />
                            </div>
                        )}
                        {reportCaseStatuses.length > 0 && expandCollapseButton}
                    </Row>
                );
            }}
        </RRFCollapsibleFieldset>
    );
});

export default function CaseDetailsForm() {
    return (
        <ReactReduxForm {...caseDetailsForm}>
            <CaseInformationFieldset />
            <SupervisorsFieldset />
            <CaseStatusFieldset />
        </ReactReduxForm>
    );
}

import React from 'react';
import { BriefingSimpleView } from '@mark43/rms-api';
import { Text } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Link from '../../../../core/components/links/Link';
import { BRIEFINGS_ROUTES } from '../../../configuration/routes';
import testIds from '../../../../../core/testIds';
import { BriefingCellWrapper } from './BriefingCellWrapper';

const strings = componentStrings.briefings.table;

type Props = Pick<BriefingSimpleView, 'title' | 'id'>;
export const BriefingTitleCell = ({ title, id }: Props) => {
    return (
        <BriefingCellWrapper>
            <Link
                to={BRIEFINGS_ROUTES.item.url(id)}
                fontWeight={600}
                hoverOnUnderline
                testId={testIds.BRIEFING_TABLE_TITLE_LINK}
            >
                {title}
            </Link>
            <Text variant="bodyMd" color="primary">
                {strings.urn}: {id}
            </Text>
        </BriefingCellWrapper>
    );
};

import { map, find } from 'lodash';
import {
    Subdivision,
    SubdivisionSetupResult,
    SubdivisionSetupView,
    SubdivisionView,
} from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getSubdivisionsResource from '../../resources/subdivisionsResource';
import { storeAttributes } from '../../../attributes/state/data';
import { convertAttributeToAttributeView } from '../../../attributes/utils/attributesHelpers';
import {
    PRIMARY_SUBDIVISIONS_NEXUS_STATE_PROP,
    primarySubdivisionsSelector,
} from '../../../primary-subdivisions/state/data';
import globalAttributes from '../../../../legacy-constants/globalAttributes';
import { ClientCommonAction } from '../../../../../redux/types';

const SUBDIVISIONS_NEXUS_STATE_PROP = 'subdivisions';

const subdivisionsModule = createNormalizedModule<Subdivision>({
    type: SUBDIVISIONS_NEXUS_STATE_PROP,
});

export const LOAD_SUBDIVISIONS_START = 'subdivisions/LOAD_SUBDIVISIONS_START';
const LOAD_SUBDIVISIONS_SUCCESS = 'subdivisions/LOAD_SUBDIVISIONS_SUCCESS';
export const LOAD_SUBDIVISIONS_FAILURE = 'subdivisions/LOAD_SUBDIVISIONS_FAILURE';

const SETUP_SUBDIVISIONS_START = 'subdivisions/SETUP_SUBDIVISIONS_START';
const SETUP_SUBDIVISIONS_SUCCESS = 'subdivisions/SETUP_SUBDIVISIONS_SUCCESS';
export const SETUP_SUBDIVISIONS_FAILURE = 'subdivisions/SETUP_SUBDIVISIONS_FAILURE';

function loadSubdivisionsStart() {
    return {
        type: LOAD_SUBDIVISIONS_START,
    };
}

function loadSubdivisionsSuccess() {
    return {
        type: LOAD_SUBDIVISIONS_SUCCESS,
    };
}

function loadSubdivisionsFailure(err: { message?: string } = {}) {
    return {
        type: LOAD_SUBDIVISIONS_FAILURE,
        payload: err.message,
    };
}

function setupSubdivisionsStart() {
    return {
        type: SETUP_SUBDIVISIONS_START,
    };
}

function setupSubdivisionsSuccess() {
    return {
        type: SETUP_SUBDIVISIONS_SUCCESS,
    };
}

function setupSubdivisionsFailure(err: { message?: string } = {}) {
    return {
        type: SETUP_SUBDIVISIONS_FAILURE,
        payload: err.message,
    };
}

export const subdivisionsSelector = subdivisionsModule.selectors.entitiesSelector;

export default subdivisionsModule.reducerConfig;

const storeSubdivisions = subdivisionsModule.actionCreators.storeEntities;

export function loadSubdivisions(): ClientCommonAction<Promise<Subdivision[]>> {
    const resource = getSubdivisionsResource();
    return (dispatch, getState, { nexus }) => {
        dispatch(loadSubdivisionsStart());
        return resource
            .getSubdivisionsView()
            .then((subdivisions: SubdivisionView) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [PRIMARY_SUBDIVISIONS_NEXUS_STATE_PROP]:
                                subdivisions.primarySubdivisions,
                            [SUBDIVISIONS_NEXUS_STATE_PROP]: subdivisions.subdivisions,
                        },
                        loadSubdivisionsSuccess()
                    )
                );
                return subdivisions.subdivisions;
            })
            .catch((err: Error) => dispatch(loadSubdivisionsFailure(err)));
    };
}

export function setupSubdivisions(
    subdivisionsSetupView: SubdivisionSetupView
): ClientCommonAction<Promise<SubdivisionSetupResult>> {
    const resource = getSubdivisionsResource();
    return (dispatch, getState, { nexus: { withRemove } }) => {
        dispatch(setupSubdivisionsStart());
        return resource
            .setupSubdivisions(subdivisionsSetupView)
            .then((result: SubdivisionSetupResult) => {
                dispatch(
                    storeAttributes(
                        map(
                            [...result.attributes, ...result.expiredAttributes],
                            convertAttributeToAttributeView
                        )
                    )
                );
                dispatch(
                    withRemove(
                        {
                            [SUBDIVISIONS_NEXUS_STATE_PROP]: map(
                                result.deletedSubdivisions,
                                (subdivision) => {
                                    return {
                                        id: subdivision.id,
                                    };
                                }
                            ),
                        },
                        { type: 'DELETE_MULTIPLE_SUBDIVISIONS_SUCESS' }
                    )
                );
                dispatch(storeSubdivisions(result.subdivisions));

                dispatch(setupSubdivisionsSuccess());
                return result;
            })
            .catch((err: Error) => dispatch(setupSubdivisionsFailure(err)));
    };
}

const agencySubdivisionSelector = createSelector(
    primarySubdivisionsSelector,
    subdivisionsSelector,
    (primarySubdivisions, subdivisions) => (agencyTypeGlobalAttrId: number) => {
        const primarySubId = find(primarySubdivisions, { agencyTypeGlobalAttrId })?.subdivisionId;

        return primarySubId ? subdivisions?.[primarySubId] : undefined;
    }
);

export const beatSubdivisionSelector = createSelector(
    agencySubdivisionSelector,
    (getAgencySubdivision) => getAgencySubdivision(globalAttributes.agencyTypeGlobal.police)
);

import React from 'react';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import InfoCell from '../../../../legacy-redux/components/core/tables/elasticEvidenceTable/InfoCell';
import DescriptionCell from '../../../../legacy-redux/components/core/tables/elasticEvidenceTable/DescriptionCell';
import LocationCell from '../../../../legacy-redux/components/core/tables/elasticEvidenceTable/LocationCell';

const EvidenceDashboardSearchResultsTableBody = () => (
    <TableBody>
        <InfoCell columnKey="reportingEventNumber" />
        <DescriptionCell columnKey="identifiers" />
        <LocationCell columnKey="location" />
    </TableBody>
);

export default EvidenceDashboardSearchResultsTableBody;

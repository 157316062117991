import { RefContextEnum } from '@mark43/rms-api';

import { omit } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import { otherUserId } from '~/client-common/helpers/userHelpers';
import { filterFormData } from '~/client-common/helpers/formHelpers';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { createFormModule } from '../../../../core/forms';

const { ATTRIBUTE, DATE } = fieldTypeClientEnum;

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        type: ATTRIBUTE,
        key: 'warrantTypeAttrId',
        fieldName: fields.WARRANT_WARRANT_TYPE_ATTR_ID,
    },
    {
        key: 'warrantNumber',
        fieldName: fields.WARRANT_WARRANT_NUMBER,
    },
    {
        key: 'reportingEventNumber',
        fieldName: fields.WARRANT_REPORTING_EVENT_NUMBER,
    },
    {
        type: DATE,
        key: 'warrantIssuedDateUtc',
        fieldName: fields.WARRANT_WARRANT_ISSUED_DATE_UTC,
    },
    {
        key: 'description',
        fieldName: fields.WARRANT_DESCRIPTION,
    },
    {
        key: 'issuingJudge',
        fieldName: fields.WARRANT_ISSUING_JUDGE,
    },
    {
        key: 'bailAmount',
        fieldName: fields.WARRANT_BAIL_AMOUNT,
    },
    {
        key: 'obtainingOfficerUserId',
        fieldName: fields.WARRANT_OBTAINING_OFFICER_USER_ID,
    },
    {
        key: 'obtainingOfficerOther',
        fieldName: fields.WARRANT_OBTAINING_OFFICER_OTHER,
    },
    {
        key: 'isOtherJurisdiction',
        fieldName: fields.WARRANT_IS_OTHER_JURISDICTION,
    },
    {
        key: 'issuingAgencyName',
        fieldName: fields.WARRANT_ISSUING_AGENCY_NAME,
    },
    {
        key: 'issuingAgencyOri',
        fieldName: fields.WARRANT_ISSUING_AGENCY_ORI,
    },
]);
const convertToFormModel = (warrantModel) => {
    const { obtainingOfficerUserId, obtainingOfficerOther } = warrantModel;
    return {
        ...warrantModel,
        // if obtainingOfficerOther exists with no obtainingOfficerUserId, then set to "isOther" id
        obtainingOfficerUserId:
            !obtainingOfficerUserId && obtainingOfficerOther ? otherUserId : obtainingOfficerUserId,
    };
};
const convertFromFormModel = (formModel) => {
    const { obtainingOfficerUserId } = formModel;
    // handle hidden fields
    let warrantRemovedHiddenFields = formModel;
    if (obtainingOfficerUserId !== otherUserId) {
        warrantRemovedHiddenFields = omit(warrantRemovedHiddenFields, 'obtainingOfficerOther');
    }
    if (obtainingOfficerUserId === otherUserId) {
        warrantRemovedHiddenFields = omit(warrantRemovedHiddenFields, 'obtainingOfficerUserId');
    }
    const filteredOutWarrant = filterFormData(warrantRemovedHiddenFields);
    return filteredOutWarrant;
};

export default createFormModule({
    context: RefContextEnum.FORM_CREATE_WARRANT_STUB_SIDE_PANEL.name,
    fieldViewModels,
    convertToFormModel,
    convertFromFormModel,
});

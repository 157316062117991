import { UsageSourceApplicationEnum, UsageCompletionEnum } from '@mark43/rms-api';
import {
    setCurrentParentUsageLog,
    currentParentUsageLogSelector,
} from '~/client-common/core/domain/usage-logs/state/ui';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import getUsageLogsResource from '~/client-common/core/domain/usage-logs/resources/usageLogsResource';

/**
 * Construct and submit a case usage log and submit the usage log creation request
 * @param  {Object} usageLog  UsageLog fields
 * @param  {[type]} options.isParent Is this the parent usage log?
 */
export function createUsageLog(
    {
        primaryEntityType,
        action,
        completion = UsageCompletionEnum.SUCCEEDED.name,
        primaryEntityId,
        primaryEntityTitle = '',
        sourceModule,
        parentUsageLogDateUtc,
        parentPrimaryEntityId,
        parentAction,
        parentPrimaryEntityType,
        primaryEntityDepartmentId,
    },
    { isParent = false, errorCode = null } = {}
) {
    return (dispatch, getState) => {
        const state = getState();
        if (errorCode === 401) {
            completion = UsageCompletionEnum.UNAUTHORIZED.name;
        } else if (errorCode === 403) {
            completion = UsageCompletionEnum.INSUFFICIENT_PERMISSIONS.name;
        }
        const usageLogCreation = {
            sourceApplication: UsageSourceApplicationEnum.RMS.name,
            usageLogDateUtc: nowUtc(),
            primaryEntityType,
            primaryEntityId,
            primaryEntityTitle,
            action,
            completion,
            sourceModule,
            parentUsageLogDateUtc,
            parentPrimaryEntityId,
            parentAction,
            parentPrimaryEntityType,
            primaryEntityDepartmentId,
        };
        let currentUsageLogParentContext = {};
        if (isParent) {
            dispatch(
                setCurrentParentUsageLog({
                    parentUsageLogDateUtc: usageLogCreation.usageLogDateUtc,
                    parentPrimaryEntityId: usageLogCreation.primaryEntityId,
                    parentAction: usageLogCreation.action,
                    parentPrimaryEntityType: usageLogCreation.primaryEntityType,
                })
            );
        } else {
            currentUsageLogParentContext = currentParentUsageLogSelector(state);
        }

        return getUsageLogsResource().createUsageLog({
            ...usageLogCreation,
            ...currentUsageLogParentContext,
        });
    };
}

import _, { filter, memoize } from 'lodash';
import { createSelector } from 'reselect';

import { miniUsersSelector } from '../../../mini-users/state/data';
import { formatName, formatIdNumber } from '../../../mini-users/utils/miniUsersHelpers';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';
import { currentViewersSelector } from '../data';

export const BEGIN_POLLING_FOR_CURRENT_VIEWERS =
    'current-viewers/BEGIN_POLLING_FOR_CURRENT_VIEWERS';
export const END_POLLING_FOR_CURRENT_VIEWERS = 'current-viewers/END_POLLING_FOR_CURRENT_VIEWERS';

/**
 * All current viewers on a given page,
 * augmented with MiniUser data
 */
const currentViewersViewModelsSelector = createSelector(
    currentViewersSelector,
    miniUsersSelector,
    (currentViewers, miniUsers) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                ({ userId }) => ({
                    name: formatName(miniUsers[userId], true),
                    id: formatIdNumber(miniUsers[userId], true),
                }),
            ],
            helpers: {
                formatName,
                formatIdNumber,
            },
        });

        return _.mapValues(currentViewers, viewModel);
    }
);

// Polling state-keeping

export const currentViewersPollingSelector = (state) => state.ui.currentViewers;

export default function currentViewersPollingReducer(
    state = {
        polling: false,
    },
    action
) {
    switch (action.type) {
        case BEGIN_POLLING_FOR_CURRENT_VIEWERS:
            return {
                ...state,
                polling: true,
            };
        case END_POLLING_FOR_CURRENT_VIEWERS:
            return {
                ...state,
                polling: false,
            };
        default:
            return state;
    }
}

export const sortedCurrentViewersViewModelsForEntityIdAndTypeWithoutUserIdSelector = createSelector(
    currentViewersViewModelsSelector,
    (currentViewersViewModels) =>
        memoize(
            ({ entityType, entityId, userId }) =>
                filter(
                    currentViewersViewModels,
                    (item) =>
                        item.entityId === entityId &&
                        item.entityType === entityType &&
                        item.userId !== userId
                ),
            ({ entityType, entityId, userId }) => `${entityType}~${entityId}~${userId}`
        )
);

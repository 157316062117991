import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import Link from '../../../core/components/links/Link';

const strings = componentStrings.entityProfiles.EntityProfileContextInfoBar;

export default function EntityProfileContextInfoBar({
    masterProfileId,
    currentRouteParams: { entityType },
    ownerContext: { ownerType, ownerId },
    ownerTitle,
    ownerDepartmentName,
}) {
    const ownerLink = computeOwnerLink(ownerType, ownerId, ownerTitle, ownerDepartmentName);
    const title = ownerLink === '' ? strings.contextedProfileGeneric : strings.contextedProfile;
    const masterLink = masterProfileId && (
        <Link
            to={`profiles/${entityType}/${masterProfileId}`}
            className="entity-profile-context-info-bar-master-link"
        >
            {strings.linkToMaster}
        </Link>
    );
    return (
        <div className="entity-profile-context-info-bar">
            <div className="entity-profile-context-info-bar-title">{title}</div>
            {ownerLink}
            {masterLink}
        </div>
    );
}

function computeOwnerLink(ownerType, ownerId, ownerTitle, ownerDepartmentName) {
    let ownerLink = '';
    if (ownerTitle) {
        let ownerUrl;
        switch (ownerType) {
            case EntityTypeEnum.REPORT.name:
                ownerUrl = `reports/${ownerId}`;
                break;
            case EntityTypeEnum.CASE.name:
                ownerUrl = `cases/${ownerId}/summary`;
                break;
            case EntityTypeEnum.WARRANT.name:
                ownerUrl = `warrants/${ownerId}`;
                break;
            default:
                ownerUrl = '';
        }
        ownerLink = (
            <Link to={ownerUrl} className="entity-profile-context-info-bar-owner-link">
                {ownerTitle}
                {ownerDepartmentName ? ` (${ownerDepartmentName})` : ''}
            </Link>
        );
    }
    return ownerLink;
}

import { RefContextEnum, RefContextEnumType } from '@mark43/rms-api';

export const CUSTOM_PROPERTIES_KEY_NAME = 'customProperties';

/**
 * We need to maintain this list because we already have some `DragonManagedProperties`
 * that are defined on the core models that will also exist within `customProperties`.
 *
 * For now, we will continue handling these flat on the core model
 */
export const propertiesToIgnore: Partial<Record<RefContextEnumType, string[]>> = {
    [RefContextEnum.FORM_OFFENSE.name]: [
        'wasCarjackingInvolved',
        'wasVehicleBumpedRobbed',
        'wasWitnessPresent',
        'wasShootingInvolved',
        'isSuspectedOffenseAgainstWomenOrGirls',
        'isSuspectedModernSlavery',
        'hasStreetGang',
        'hasOtherOrganizedGroup',
        'biasDescriptionAttrId',
        'biasDescriptionOther',
        'vehicleEntryPointAttrId',
        'directionOfEntry1AttrId',
        'directionOfEntry2AttrId',
        'isSuspectedCorrosiveBasedOffense',
        'isHonourBasedOffence',
        'isSuspectedChildSexualAbuse',
        'isSuspectedChildSexualExploitation',
        'offenseURN',
        'suspectedInternetCyberInvolvementAttrId',
    ],
};

/**
 * If a field's length is longer than this, then the FE will render
 * a textarea instead
 */
export const STRING_TEXT_INPUT_MAX_LENGTH = 256;

export const TNIBRS_PROPERTY_NAME = {
    GANG_NAME_1: 'gangName1',
    TYPE_OF_GANG_1_ATTR_ID: 'typeOfGang1AttrId',
    GANG_NAME_2: 'gangName2',
    TYPE_OF_GANG_2_ATTR_ID: 'typeOfGang2AttrId',
    IS_USE_OF_FORCE: 'isUseOfForce',
    USE_OF_FORCE_NUMBER: 'useOfForceNumber',
    IS_DRUG_RELATED: 'isDrugRelated',
    DRUG_TYPE_ATTR_ID: 'drugTypeAttrId',
    DRUG_PRECURSORS_ATTR_ID: 'drugPrecursorsAttrId',
    ORIGIN_OF_DRUG_ATTR_ID: 'originOfDrugAttrId',
    IS_HOME_INVASION: 'isHomeInvasion',
    IS_IDENTITY_THEFT: 'isIdentityTheft',
    IS_CYBERCRIME: 'isCybercrime',
};

import React, { FC } from 'react';
import { includes } from 'lodash';
import { OperationTypeEnum } from '@mark43/rms-api';
import styled from 'styled-components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import _TaskStatusLabel from './TaskStatusLabel';
import TaskStatusPopOverMenu, { TaskStatusPopOverMenuProps } from './TaskStatusPopOverMenu';

type TaskStatusCellProps = Omit<TaskStatusPopOverMenuProps, 'buttonDisabled'>;

const TaskStatusLabel = styled(_TaskStatusLabel)`
    flex: 1 1 auto;
`;

const TaskStatusCell: FC<TaskStatusCellProps> = ({
    task,
    taskEntityLinks,
    onUpdate,
    onMenuOpen,
    options,
    style,
}) => {
    return (
        <FeatureFlagged
            flag="RMS_TASK_ENHANCEMENTS_ENABLED"
            fallback={<TaskStatusLabel statusAttrId={task.statusAttrId} />}
        >
            <TaskStatusPopOverMenu
                task={task}
                taskEntityLinks={taskEntityLinks}
                options={options}
                onUpdate={onUpdate}
                onMenuOpen={onMenuOpen}
                buttonDisabled={!includes(task.permissionSet, OperationTypeEnum.WRITE.name)}
                style={style}
            />
        </FeatureFlagged>
    );
};

export default TaskStatusCell;

import * as Sentry from '@sentry/browser';
import React from 'react';
import { useDispatch } from 'react-redux';

import { UsageSourceModuleEnum, UsageSourceModuleEnumType } from '@mark43/rms-api';
import environmentEnum from '~/client-common/core/enums/client/environmentEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import redirectToErrorPage from '../../utils/redirectToErrorPage';
import type { ErrorConfig } from '../types';
import errorToMessage from '../utils/errorToMessage';

const strings = componentStrings.core.errors.ErrorBoundary;

interface ErrorBoundaryProps {
    /**
     * This value is sent to Sentry as the `module` tag.
     */
    moduleTag?: UsageSourceModuleEnumType;
    fallback?: (errorMessage: string) => JSX.Element;
    children: React.ReactNode;
}

type ErrorBoundaryInnerProps = ErrorBoundaryProps & {
    onError: (errorConfig: ErrorConfig) => void;
};

interface ErrorBoundaryState {
    errorMessage?: string;
}

// this is a class component because React does not support componentDidCatch in function components
// https://react.dev/reference/react/Component#componentdidcatch
class ErrorBoundaryBase extends React.Component<ErrorBoundaryInnerProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryInnerProps) {
        super(props);
        this.state = { errorMessage: undefined };
    }

    static getDerivedStateFromError(error: unknown) {
        return { errorMessage: errorToMessage(error, strings.fallbackError) };
    }

    componentDidCatch(error: unknown, info: unknown) {
        let errorId;
        Sentry.withScope((scope) => {
            scope.setExtra('info', info);
            scope.setTag('module', this.props.moduleTag || UsageSourceModuleEnum.RMS_GENERAL.name);

            const eventId = Sentry.captureException(error);
            errorId = eventId;
        });

        if (
            error &&
            (MARK43_ENV === environmentEnum.DEVELOPER ||
                MARK43_ENV === environmentEnum.QA ||
                MARK43_ENV === environmentEnum.DEV43)
        ) {
            /* eslint-disable no-console */
            console.error(
                'The following error was logged in a developer environment by the error boundary:\n\n',
                error
            );
        }

        if (!this.props.fallback) {
            this.props.onError({ errorId });
        }
    }

    render() {
        if (this.state.errorMessage) {
            return this.props.fallback ? this.props.fallback(this.state.errorMessage) : null;
        }
        return this.props.children;
    }
}

/**
 * If the child component throws an uncaught exception, then (a) display a `fallback` component if it is provided,
 * otherwise (b) redirect to the error page without displaying the actual error message. This is meant to handle
 * unexpected errors. You should always catch expected errors and handle them without relying on this ErrorBoundary.
 *
 * (a) It is always preferable to display a human readable error message to the user. The default fallback message of
 *     "An error has occurred" (`strings.fallbackError`) is unhelpful, please remember to include a message when
 *     throwing an error.
 *
 * (b) Redirecting to the error page should only be done as a last resort for unexpected or unrecoverable exceptions.
 */
export function ErrorBoundary(props: ErrorBoundaryProps): JSX.Element {
    const dispatch = useDispatch();
    const onError = React.useCallback(
        (errorConfig: ErrorConfig) => {
            dispatch(redirectToErrorPage(errorConfig));
        },
        [dispatch]
    );
    return <ErrorBoundaryBase {...props} onError={onError} />;
}

export default ErrorBoundary;

function createErrorBoundary(moduleTag: UsageSourceModuleEnumType) {
    return function (props: Omit<ErrorBoundaryProps, 'moduleTag'>) {
        return <ErrorBoundary {...props} moduleTag={moduleTag} />;
    };
}

/*
 * Module Error Boundaries.
 * Used to catch errors and log them with respective module name.
 * Place them as high up (or deep down) in the react component tree as necessary.
 */
export const AdminErrorBoundary = createErrorBoundary(UsageSourceModuleEnum.ADMIN.name);
export const AnalyticsErrorBoundary = createErrorBoundary(UsageSourceModuleEnum.ANALYTICS.name);
export const CaseManagementErrorBoundary = createErrorBoundary(
    UsageSourceModuleEnum.CASE_MANAGEMENT.name
);
export const EntityProfilesErrorBoundary = createErrorBoundary(
    UsageSourceModuleEnum.ENTITY_PROFILES.name
);
export const EvidenceErrorBoundary = createErrorBoundary(UsageSourceModuleEnum.EVIDENCE.name);
export const NotificationsErrorBoundary = createErrorBoundary(
    UsageSourceModuleEnum.NOTIFICATIONS.name
);
export const ReportsErrorBoundary = createErrorBoundary(UsageSourceModuleEnum.REPORTS.name);
export const RmsMobileErrorBoundary = createErrorBoundary(UsageSourceModuleEnum.RMS_MOBILE.name);
export const SearchErrorBoundary = createErrorBoundary(UsageSourceModuleEnum.SEARCH.name);
export const WarrantsErrorBoundary = createErrorBoundary(UsageSourceModuleEnum.WARRANTS.name);

import _ from 'lodash';
import { ElasticStorageLocation, StorageLocationView } from '@mark43/evidence-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { convertStorageLocationToElasticSelector } from '../../../storage-locations/state/data';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'elasticStorageLocations';

// Some of the elasticStorageLocations in state are convereted StorageLocationViews which match
// the following definition
export type ClientElasticStorageLocation = Omit<
    ElasticStorageLocation,
    | 'facilityTypeAttrDetail'
    | 'innerHits'
    | 'innerHitFields'
    | 'matchedQueries'
    | 'lastSyncedDateUtc'
    | 'score'
    | 'storageLocationPermissions'
>;

const elasticStorageLocationsModule = createNormalizedModule<ClientElasticStorageLocation>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
const storeElasticStorageLocations = elasticStorageLocationsModule.actionCreators.storeEntities;

/**
 * Search for storage locations and completely replace the
 *   elasticStorageLocations data state with the search results.
 * @param  {function} resourceMethod TODO tech debt
 * @param  {Object}   elasticQuery Search model containing all fields to search
 *   for.
 * @param  {number}   [from]
 * @param  {number}   [size]
 * @param  {string}   [sortKey]
 * @param  {string}   [sortType]
 */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function searchStorageLocations<S extends any[]>(
    resourceMethod: (...args: S) => Promise<{ items: ElasticStorageLocation[] }>,
    ...args: S
): ClientCommonAction<Promise<ElasticStorageLocation[] | undefined>> {
    return function (dispatch) {
        return resourceMethod(...args).then((result) => {
            if (result) {
                dispatch(storeElasticStorageLocations(result.items));
                return result.items;
            }
            // The result may be empty when the XHR request is cancelled in withAsyncHandler. This happens when
            // successive requests are made very quickly as the user types. When we used to log this as an error
            // to Sentry, it occurred in prod 0-300 times per day. It is cleaner to not throw this as an error
            // since the subsequent XHR request is what matters.
            return undefined;
        });
    };
}

/**
 * Given `storageLocations`, store them in state as `elasticStorageLocations`. This is needed to
 *   populate the storage location typeahead in the property status form, since that typeahead deals
 *   only with the `ElasticStorageLocation` model.
 *
 * This action must be dispatched after `storageLocations` are already stored in state, otherwise
 *   the conversion won't work. We don't directly dispatch `storeStorageLocations` as well here
 *   because callers can dispatch `withEntityItems` on the original bundle that includes the
 *   `storageLocations`.
 *
 * Instead of this action creator, we could write a helper that does this conversion statelessly,
 *   but it would have to assume that the provided `storageLocations` are always complete in terms
 *   of parents (if a location A > B > C is provided, then locations A and B must also be provided),
 *   which may not always be true in the future.
 */
export function convertStorageLocationsToElasticAndStore(
    storageLocations: StorageLocationView[]
): ClientCommonAction<void> {
    return function (dispatch, getState) {
        const state = getState();
        const convertStorageLocationToElastic = convertStorageLocationToElasticSelector(state);
        const elasticStorageLocations = _.map(storageLocations, ({ id }) =>
            convertStorageLocationToElastic(id)
        );
        dispatch(storeElasticStorageLocations(elasticStorageLocations));
    };
}

// SELECTORS
export const elasticStorageLocationsSelector =
    elasticStorageLocationsModule.selectors.entitiesSelector;

// REDUCER
export default elasticStorageLocationsModule.reducerConfig;

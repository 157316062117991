import React from 'react';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

const InventoryItemIdCell = (inventoryItem) => {
    const { itemId } = getViewModelProperties(inventoryItem);

    return <div>{itemId}</div>;
};

export default InventoryItemIdCell;

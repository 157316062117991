import React from 'react';
import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import createElement from '../../../core/utils/recompose.createElement';

import ImageCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/ImageCell';
import { PersonProfilePropertyCell } from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/PersonProfilePropertyCell';
import EntityProfileLinkIndicator from './EntityProfileLinkIndicator';

const EntityProfilePropertySummaryStyled = styled.div`
    border-bottom: 2px solid ${(props) => props.theme.colors.extraLightGrey};
    margin-bottom: var(--arc-space-3);
    padding-bottom: var(--arc-space-3);

    .elastic-property-profile-photo {
        border-radius: 10px;

        i::before {
            font-size: 80px;
            line-height: 80px;
        }
    }
`;

export default function EntityProfilePropertySummary({ hasReadAccess, elasticProperty }) {
    // use of `createElement` is due to the fact that all `Symbol` properties
    // of an object are lost when it's "copied" (via spread syntax). This is an intended
    // effect of spread syntax / for in
    return (
        <EntityProfilePropertySummaryStyled>
            <div className="entity-profile-summary-sibling">
                {createElement(ImageCell, { ...elasticProperty })}
            </div>
            <div className="entity-profile-summary-sibling">
                <PersonProfilePropertyCell
                    hasReadAccess={hasReadAccess}
                    elasticProperty={elasticProperty}
                />
                <EntityProfileLinkIndicator
                    linkTypes={getViewModelProperties(elasticProperty).linkTypes}
                />
            </div>
        </EntityProfilePropertySummaryStyled>
    );
}

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Flex, Spacer, cssVar, mediaQueries, useToast } from 'arc';
import { Detention } from '@mark43/rms-api';
import { MFTValidationFailureResult, _Form } from 'markformythree';

import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { NEXUS_STATE_PROP as DETENTIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/detentions/state/data';
import validationStrings from '~/client-common/core/strings/validationStrings';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import { ActionBarBase } from '../../../core/components/ActionBar';
import { Button } from '../../../core/components/Button';
import errorToMessage from '../../../core/errors/utils/errorToMessage';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { RmsDispatch } from '../../../../core/typings/redux';
import testIds from '../../../../core/testIds';

import detentionsResource from '../state/resources/detentionsResource';
import { DetentionDispositionFormConfiguration, convertFromFormModel } from '../state/forms';

import { DetentionDispositionForm } from './DetentionDispositionForm';

export type DetentionDispositionParams = { reportId: string };

const ActionBarContent = styled.div<{ isMainNavEnabled: boolean }>`
    box-sizing: border-box;
    width: 100%;
    padding: 4px 20px;

    padding-left: ${(props) => (props.isMainNavEnabled === true ? '7rem' : '')};

    @media (min-width: ${mediaQueries.xl}) {
        padding-left: ${(props) => (props.isMainNavEnabled === true ? '15rem' : '')};
    }
`;

export const DetentionDispositionDashboard: React.FC<
    RouteComponentProps<DetentionDispositionParams, Record<string, unknown>>
> = (props) => {
    const { params, router } = props;
    const reportId = parseInt(params.reportId);
    const toast = useToast();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isMainNavEnabled = applicationSettings.RMS_ARC_NAVIGATION_ENABLED;
    if (isNaN(reportId)) {
        throw new Error(`reportId ${reportId} is not a number`);
    }

    const dispatch: RmsDispatch = useDispatch();
    const { getForm } = useFormGetter();

    const onReplaceDetentionsForReportSuccess = useCallback(
        (newDetentions: Detention[]) => {
            dispatch((dispatch, getState, dependencies) => {
                dispatch(
                    dependencies.nexus.withRemove(
                        DETENTIONS_NEXUS_STATE_PROP,
                        { reportId },
                        dependencies.nexus.withEntityItems(
                            {
                                [DETENTIONS_NEXUS_STATE_PROP]: newDetentions,
                            },
                            { type: 'UPDATE_DETENTIONS_FOR_REPORT' }
                        )
                    )
                );
            });
            router.push(`/reports/${reportId}`);
        },
        [dispatch, reportId, router]
    );

    const onReplaceDetentionForReportError = useCallback(
        (error?: Error) => {
            toast({
                status: 'error',
                description: errorToMessage(error, validationStrings.panel.failedToSaveGeneric),
            });
        },
        [toast]
    );

    const { callResource: callReplaceDetentionsForReport, loading: replaceDetentionsLoadingState } =
        useResourceDeferred(
            detentionsResource.replaceDetentionsForReport,
            onReplaceDetentionsForReportSuccess,
            onReplaceDetentionForReportError
        );

    const handleUpdate = async () => {
        const form = getForm('FORM_DETENTION_DISPOSITION');
        form
            ?.submit()
            .then(({ form }) => {
                const detentions = convertFromFormModel({
                    formModel: form?.getState().model,
                });
                if (detentions) {
                    callReplaceDetentionsForReport({
                        detentions: detentions as Detention[],
                        reportId,
                    });
                } else {
                    throw new Error('no detentions');
                }
            })
            .catch(
                (
                    err:
                        | Error
                        | {
                              form: _Form<DetentionDispositionFormConfiguration>;
                              validationResult: MFTValidationFailureResult;
                          }
                ) => {
                    // if validation failed, the ui is taking care of that.
                    // if it's a real error, show it in a toast
                    if (err instanceof Error) {
                        toast({
                            status: 'error',
                            description: errorToMessage(
                                err,
                                validationStrings.panel.failedToSaveGeneric
                            ),
                        });
                    }
                }
            );
    };
    return (
        <ScrollableUnderSubheader>
            <DetentionDispositionForm reportId={reportId} />
            <ActionBarBase visible position="fixed" style={{ left: '0' }}>
                <ActionBarContent isMainNavEnabled={!!isMainNavEnabled}>
                    <Flex
                        alignItems="center"
                        height="100%"
                        gap={cssVar('arc.sizes.4')}
                        justifyContent="flex-start"
                    >
                        <Spacer maxWidth="1" />
                        <Button
                            variant="solid"
                            onClick={handleUpdate}
                            isLoading={replaceDetentionsLoadingState?.isLoading}
                            testId={testIds.DETENTION_UPDATE_BUTTON}
                        >
                            Update
                        </Button>
                        <Button
                            onClick={() => router.push(`/reports/${reportId}`)}
                            testId={testIds.DETENTION_CANCEL_BUTTON}
                        >
                            Cancel
                        </Button>
                    </Flex>
                </ActionBarContent>
            </ActionBarBase>
        </ScrollableUnderSubheader>
    );
};

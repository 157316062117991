import React, { FC } from 'react';
import { Box, cssVar } from 'arc';

import CreateProfileLinkedCaseButton from './cases/CreateProfileLinkedCaseButton';

import EntityProfileCasesTable from './EntityProfileCasesTable';

const EntityProfileCases: FC = () => {
    return (
        <>
            <Box display="flex" justifyContent="flex-end" padding={cssVar('arc.space.4')}>
                <CreateProfileLinkedCaseButton />
            </Box>
            <EntityProfileCasesTable />
        </>
    );
};

export default EntityProfileCases;

import React from 'react';
import { first, get, orderBy } from 'lodash';
import { connect } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Upload from '../../forms/components/Upload';
import { ImageContainer, UploadError } from '../../forms/components/ImageUpload';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../components/Icon';
import { clearErroredUploads } from '../../../attachments/core/state/ui/inlineAttachmentsUploader';

const strings = componentStrings.core.InlineAttachmentsEntityProfileUploader;

const InlineAttachmentsEntityProfileUploaderUnconnected = ({
    attachments,
    uploadingFiles,
    uploadedFiles,
    onClickDeleteUpload,
    onClickDeleteAttachment,
    onUploadSuccess,
    onUploadStart,
    onUploadFailure,
    clearErroredUploads,
    entityId,
    linkType,
}) => {
    // Just take the most recent one for the mugshot uploader
    const uploadedFile = first(orderBy(uploadedFiles, 'file.createdDateUtc', 'desc'));
    const attachment = first(orderBy(attachments, 'image.createdDateUtc', 'desc'));
    const uploadingFile = first(uploadingFiles);

    // Errors should only occurs when we are uploading an attachment
    const error = !uploadedFile && uploadingFile && uploadingFile.file.errorMessage;
    let onRemove;
    let preview;

    // if the uploadedFile exists, we want to prioritize it
    if (attachment && !uploadedFile) {
        onRemove = onClickDeleteAttachment(get(attachment, 'id'));
        preview = get(attachment, 'thumbnailPath');
    } else if (uploadedFile) {
        onRemove = onClickDeleteUpload(get(uploadedFile, 'file.id'));
        preview = get(uploadedFile, 'file.fileWebServerPath') || get(uploadedFile, 'preview');
        // If there was an error uploading, give the option to remove
    }

    const isEmpty = (!attachment && !uploadedFile && !uploadingFile) || error;
    const isLoading = uploadingFile && !uploadedFile && !error;

    return (
        <div>
            <Upload
                multiple={true}
                onSuccess={onUploadSuccess}
                onStart={(files) => {
                    // Always clear `uploadingFiles` to ensure never
                    // have zombie uploads
                    clearErroredUploads(uploadingFiles, { entityId, linkType });
                    onUploadStart(files);
                }}
                onError={onUploadFailure}
            >
                <ImageContainer
                    width="90px"
                    height="90px"
                    empty={isEmpty}
                    imageUrl={preview}
                    loading={isLoading}
                />
            </Upload>
            {onRemove && (
                <Button className={buttonTypes.ICON_LINK} onClick={onRemove}>
                    <Icon type={iconTypes.TRASH_CAN} color="cobaltBlue" />
                    {strings.remove}
                </Button>
            )}
            {!!error && <UploadError>{error}</UploadError>}
        </div>
    );
};

export const InlineAttachmentsEntityProfileUploader = connect(undefined, { clearErroredUploads })(
    InlineAttachmentsEntityProfileUploaderUnconnected
);

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import itemSidePanelOperationEnum from '~/client-common/core/enums/client/itemSidePanelOperationEnum';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { deleteHydratedItem } from '~/client-common/core/domain/item-profiles/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { isStorageLocationEditableSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { iconTypes } from '../../../../core/components/Icon';
import { Tooltip } from '../../../../core/components/tooltip';
import { propertyStatusForItemToUseInEvidenceSelector } from '../../state/ui';
import custodialPropertyCard from '../../../core/state/ui/custodialPropertyCard';
import { openItemSidePanel } from '../../../core/state/ui/itemSidePanel';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import OverlayButton from '../../../../core/overlays/components/OverlayButton';
import testIds from '../../../../../core/testIds';

const strings =
    componentStrings.reports.custodialPropertySummary.custodialPropertyCardItem
        .CustodialPropertyCardItemActions;

const InlineBlock = styled.div`
    display: inline-block;
`;

const ButtonContainer = styled.div`
    display: inline-block;
    padding: 0 8px 0 12px;
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey};
    vertical-align: top;

    &:last-child {
        border-right: 0;
    }
`;

function EditButton({ canEdit, onEdit, isDispositioned }) {
    const evidenceModuleName = useEvidenceModuleName();

    const button = (
        <Button
            className={buttonTypes.ICON_LINK}
            iconLeft={iconTypes.EDIT}
            onClick={onEdit}
            disabled={!canEdit}
            testId={testIds.CUSTODIAL_PROPERTY_CARD_ITEM_EDIT_BUTTON}
        />
    );

    if (canEdit) {
        return button;
    } else {
        return (
            <Tooltip
                side="top"
                content={
                    isDispositioned
                        ? strings.disabledEditDispositionedTooltip
                        : strings.disabledEditTooltip(evidenceModuleName)
                }
            >
                <InlineBlock>{button}</InlineBlock>
            </Tooltip>
        );
    }
}

function DeleteButton({ canDelete, buttonElement }) {
    const evidenceModuleName = useEvidenceModuleName();

    if (canDelete) {
        return buttonElement;
    } else {
        return (
            <Tooltip side="top" content={strings.disabledDeleteTooltip(evidenceModuleName)}>
                <InlineBlock>{buttonElement}</InlineBlock>
            </Tooltip>
        );
    }
}

function CustodialPropertyCardItemActions({
    canEdit,
    canDelete,
    currentUserHasAbility,
    isDispositioned,
    onEdit,
    onDeleteNonEvidenceItem,
    hasCoC,
    itemProfileId,
    masterItemId,
    reportId,
}) {
    const isEvidenceDelete =
        currentUserHasAbility(abilitiesEnum.EVIDENCE.DELETE_EVIDENCE_ITEMS) && hasCoC;
    const buttonElement = isEvidenceDelete ? (
        <OverlayButton
            className={buttonTypes.ICON_LINK}
            disabled={!canDelete}
            iconLeft={iconTypes.TRASH_CAN}
            id={overlayIdEnum.CUSTODIAL_PROPERTY_CARD_OVERLAY}
            overlayCustomProperties={{ itemProfileId, masterItemId, reportId }}
        />
    ) : (
        <Button
            className={buttonTypes.ICON_LINK}
            disabled={!canDelete}
            iconLeft={iconTypes.TRASH_CAN}
            onClick={onDeleteNonEvidenceItem}
        />
    );

    return (
        <InlineBlock>
            <ButtonContainer>
                <DeleteButton canDelete={canDelete} buttonElement={buttonElement} />
            </ButtonContainer>
            <ButtonContainer>
                <EditButton canEdit={canEdit} onEdit={onEdit} isDispositioned={isDispositioned} />
            </ButtonContainer>
        </InlineBlock>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
    isStorageLocationEditable: isStorageLocationEditableSelector,
    propertyStatusForItemToUseInEvidence: propertyStatusForItemToUseInEvidenceSelector,
    reportById: reportByIdSelector,
});

const mapDispatchToProps = (dispatch) => ({
    openItemSidePanel: ({
        itemProfileId,
        isStorageLocationIdDisabled,
        isVehicle,
        ownerId,
        reportingEventNumber,
        isInPoliceCustody,
    }) => {
        dispatch(
            openItemSidePanel({
                itemProfileId,
                isInPoliceCustodyDisabled: isInPoliceCustody,
                isStorageLocationIdDisabled,
                isVehicle,
                operation: itemSidePanelOperationEnum.EDIT,
                ownerId,
                ownerType: EntityTypeEnum.REPORT.name,
                reportingEventNumber,
            })
        );
    },
    deleteHydratedItem: (itemProfileId) => dispatch(deleteHydratedItem(itemProfileId)),
    setErrorMessages: (errorMessages) =>
        dispatch(custodialPropertyCard.actionCreators.setErrorMessages(errorMessages)),
});

/**
 * Action buttons for an individual item profile in the custodial property card.
 * @param  {number}   itemProfileId
 * @param  {function} canEdit
 * @param  {function} canDelete
 */
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onEdit({
            isStorageLocationEditable,
            isVehicle,
            itemProfileId,
            masterItemId,
            reportId: ownerId,
            reportById,
            openItemSidePanel,
            propertyStatusForItemToUseInEvidence,
        }) {
            const reportingEventNumber = get(reportById(ownerId), 'reportingEventNumber');
            const { isInPoliceCustody } = propertyStatusForItemToUseInEvidence(itemProfileId);
            const isStorageLocationIdDisabled = !isStorageLocationEditable(masterItemId);

            return () =>
                openItemSidePanel({
                    itemProfileId,
                    isStorageLocationIdDisabled,
                    isVehicle,
                    ownerId,
                    reportingEventNumber,
                    isInPoliceCustody,
                });
        },
        onDeleteNonEvidenceItem({ deleteHydratedItem, itemProfileId, setErrorMessages }) {
            return () => {
                setErrorMessages([]);

                // delete only RMS data
                return deleteHydratedItem(itemProfileId).catch(() =>
                    setErrorMessages(['Failed to delete item, please try again'])
                );
            };
        },
    })
)(CustodialPropertyCardItemActions);

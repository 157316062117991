import { CrashDiagramHistoryUserInteraction } from '../../../types';

const INITIAL_HISTORY_POSITION = -1;

export type CrashDiagramHistoryState = {
    userActions: CrashDiagramHistoryUserInteraction[];
    currentPosition: number;
};

export const initialState: CrashDiagramHistoryState = {
    userActions: [],
    currentPosition: INITIAL_HISTORY_POSITION,
};

import React from 'react';
import styled from 'styled-components';
import { size } from 'lodash';

import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';

const CaseLinkDescription = styled.div`
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.darkGrey};
    line-height: 16px;
`;

const CaseLinkDescriptionWrapper = styled.div`
    margin-top: 10px;
    width: 350px;
`;

export default function CaseLinkIndicator({ formattedInvolvement }) {
    return size(formattedInvolvement) > 0 ? (
        <CaseLinkDescriptionWrapper>
            <CaseLinkDescription>
                <Icon type={iconTypes.LINK} />
                {formattedInvolvement}
            </CaseLinkDescription>
        </CaseLinkDescriptionWrapper>
    ) : null;
}

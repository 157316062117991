import _, { find, some } from 'lodash';
import Promise from 'bluebird';
import { Report, ReportDefinition, ReportStatusView } from '@mark43/rms-api';
import { itemProfilesInReportSelector } from '~/client-common/core/domain/item-profiles/state/data';
import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import { loadReportShortTitles } from '~/client-common/core/domain/report-short-titles/state/data';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';

import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import { setReport } from '../../../../../legacy-redux/actions/reportsActions';
import {
    linkedReportShortTitlesSelector,
    reportsSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_DELAY_FACTOR,
    POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_DELAY_MS,
    POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_INITIAL_DELAY_MS,
    POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_MAX_ATTEMPTS,
} from '../../configuration';
import { RmsAction, RmsDispatch } from '../../../../../core/typings/redux';
import { retry } from '../../../../core/utils/promiseHelpers';
import { Mark43Error } from '../../../../../lib/errors';
import { loadReportsInReportingEvent } from './reportingEvent';

const LOAD_REPORT_ACTIVE_STATUS_START = 'reports-submission/LOAD_REPORT_ACTIVE_STATUS_START';
const LOAD_REPORT_ACTIVE_STATUS_SUCCESS = 'reports-submission/LOAD_REPORT_ACTIVE_STATUS_SUCCESS';
const LOAD_REPORT_ACTIVE_STATUS_FAILURE = 'reports-submission/LOAD_REPORT_ACTIVE_STATUS_FAILURE';
const SUBMIT_REPORT_START = 'reports-submission/SUBMIT_REPORT_START';
const SUBMIT_REPORT_SUCCESS = 'reports-submission/SUBMIT_REPORT_SUCCESS';
const SUBMIT_REPORT_FAILURE = 'reports-submission/SUBMIT_REPORT_FAILURE';
const POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_START =
    'reports-submission/POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_START';
const POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_SUCCESS =
    'reports-submission/POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_SUCCESS';
const POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_FAILURE =
    'reports-submission/POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_FAILURE';

function loadReportActiveStatusStart() {
    return {
        type: LOAD_REPORT_ACTIVE_STATUS_START,
    };
}
function loadReportActiveStatusSuccess() {
    return {
        type: LOAD_REPORT_ACTIVE_STATUS_SUCCESS,
    };
}
function loadReportActiveStatusFailure(errorMessage: string) {
    return {
        type: LOAD_REPORT_ACTIVE_STATUS_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Load the most up to date status of the report. Update data state.
 */
export function loadReportActiveStatus(reportId: number) {
    return function (dispatch: RmsDispatch) {
        dispatch(loadReportActiveStatusStart());
        return getReportResource()
            .getActiveStatus(reportId)
            .then((reportStatusView: ReportStatusView) => {
                dispatch(setReport(reportStatusView.report));
                dispatch(loadReportActiveStatusSuccess());
                return reportStatusView;
            })
            .catch((err: Error) => {
                dispatch(loadReportActiveStatusFailure(err.message));
                throw err;
            });
    };
}

function submitReportStart() {
    return {
        type: SUBMIT_REPORT_START,
    };
}
function submitReportSuccess() {
    return {
        type: SUBMIT_REPORT_SUCCESS,
    };
}
function submitReportFailure(errorMessage: string) {
    return {
        type: SUBMIT_REPORT_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Submit the report.
 * @param {number} reportId
 */
export function submitReport(reportId: number) {
    return function (dispatch: RmsDispatch) {
        dispatch(submitReportStart());
        return getReportResource()
            .submitReport(reportId)
            .then(() => {
                dispatch(submitReportSuccess());
                return true;
            })
            .catch((err: Error) => {
                dispatch(submitReportFailure(err.message));
                throw err;
            });
    };
}

function pollForCustodialPropertySummaryReportStart() {
    return {
        type: POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_START,
    };
}
function pollForCustodialPropertySummaryReportSuccess() {
    return {
        type: POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_SUCCESS,
    };
}
function pollForCustodialPropertySummaryReportFailure(errorMessage: string) {
    return {
        type: POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Poll for a newly linked Custodial Property Summary report within the
 *   reporting event. This action creator decides whether the polling should
 *   actually be attempted. On success, the new report will appear in the report
 *   sidebar. There is no loading state or error handling, this all happens in
 *   the background.
 * @param  {number} reportId
 * @return {RmsAction<Promise<boolean|Report>>} Report model on polling success, thrown
 *   error on polling failure, `false` when polling is not needed.
 */
export function pollForCustodialPropertySummaryReport(
    reportId: number
): RmsAction<Promise<Report | boolean>> {
    return (dispatch: RmsDispatch, getState: () => RootState) => {
        const state = getState();
        const custodialReportDefinition:
            | ReportDefinition
            | undefined = custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector(
            state
        );

        // if there is already a linked Custodial report, don't poll
        const linkedReportShortTitles = linkedReportShortTitlesSelector(state);
        const hasCustodialReport = some(linkedReportShortTitles, {
            reportDefinitionId: custodialReportDefinition?.id,
        });
        if (hasCustodialReport) {
            return Promise.resolve(false);
        }

        // if the report has no police custody property statuses, don't poll
        const itemProfiles = itemProfilesInReportSelector(state)(reportId);
        const propertyStatusesByItemProfileId = propertyStatusesByItemProfileIdSelector(state);
        const hasPoliceCustodyPropertyStatus = _(itemProfiles)
            .flatMap(({ id }) => propertyStatusesByItemProfileId(id))
            .some('isInPoliceCustody');
        if (!hasPoliceCustodyPropertyStatus) {
            return Promise.resolve(false);
        }

        dispatch(pollForCustodialPropertySummaryReportStart());
        const { eventId } = reportsSelector(state)[reportId];

        return Promise.delay(POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_INITIAL_DELAY_MS)
            .then(() =>
                retry<Report>(
                    () => {
                        return dispatch(loadReportsInReportingEvent(eventId)).then(
                            (reports: Report[]) => {
                                const custodialReport = find(reports, {
                                    reportDefinitionId: custodialReportDefinition?.id,
                                });
                                if (!custodialReport) {
                                    throw new Error('Did not find custodial report');
                                }

                                return custodialReport;
                            }
                        );
                    },
                    {
                        retries: POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_MAX_ATTEMPTS,
                        delayMs: POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_DELAY_MS,
                        delayFactor: POLL_FOR_CUSTODIAL_PROPERTY_SUMMARY_REPORT_DELAY_FACTOR,
                    }
                ).catch(() => {
                    throw new Mark43Error('Polling for custodial failed after too many attempts.');
                })
            )
            .then((custodialReport) => {
                return dispatch(loadReportShortTitles([custodialReport.id])).then(() => {
                    dispatch(pollForCustodialPropertySummaryReportSuccess());
                    return custodialReport;
                });
            })
            .catch((err) => {
                dispatch(pollForCustodialPropertySummaryReportFailure(err.message));
            });
    };
}

import { NEXUS_STATE_PROP } from '~/client-common/core/domain/fillable-pdf-templates/state/data';
import fillablePdfTemplatesResource from '../../resources/fillablePdfTemplatesResource';

export const LOAD_FILLABLE_PDF_TEMPLATES_START =
    'fillable-pdf-templates/LOAD_FILLABLE_PDF_TEMPLATES_START';
export const LOAD_FILLABLE_PDF_TEMPLATES_SUCCESS =
    'fillable-pdf-templates/LOAD_FILLABLE_PDF_TEMPLATES_SUCCESS';
export const LOAD_FILLABLE_PDF_TEMPLATES_FAILURE =
    'fillable-pdf-templates/LOAD_FILLABLE_PDF_TEMPLATES_FAILURE';

export const SAVE_FILLABLE_PDF_TEMPLATE_START =
    'fillable-pdf-templates/SAVE_FILLABLE_PDF_TEMPLATE_START';
export const SAVE_FILLABLE_PDF_TEMPLATE_SUCCESS =
    'fillable-pdf-templates/SAVE_FILLABLE_PDF_TEMPLATE_SUCCESS';
export const SAVE_FILLABLE_PDF_TEMPLATE_FAILURE =
    'fillable-pdf-templates/SAVE_FILLABLE_PDF_TEMPLATE_FAILURE';

export const CLEAR_ERRORS = 'fillable-pdf-templates/CLEAR_ERRORS';

export function clearErrors() {
    return { type: CLEAR_ERRORS };
}

function loadFillablePdfTemplatesStart() {
    return {
        type: LOAD_FILLABLE_PDF_TEMPLATES_START,
    };
}

function loadFillablePdfTemplatesSuccess() {
    return {
        type: LOAD_FILLABLE_PDF_TEMPLATES_SUCCESS,
    };
}

function loadFillablePdfTemplatesFailure(errorMessage) {
    return {
        type: LOAD_FILLABLE_PDF_TEMPLATES_FAILURE,
        payload: {
            errorMessage,
        },
    };
}

function saveFillablePdfTemplateStart() {
    return {
        type: SAVE_FILLABLE_PDF_TEMPLATE_START,
    };
}

function saveFillablePdfTemplateSuccess() {
    return {
        type: SAVE_FILLABLE_PDF_TEMPLATE_SUCCESS,
    };
}

function saveFillablePdfTemplateFailure(errorMessage) {
    return {
        type: SAVE_FILLABLE_PDF_TEMPLATE_FAILURE,
        payload: {
            errorMessage,
        },
    };
}

export function loadFillableTemplateUploaders() {
    return function (dispatch, getState, { nexus }) {
        dispatch(loadFillablePdfTemplatesStart());
        return fillablePdfTemplatesResource
            .getDepartmentFillablePdfTemplates()
            .then((fillablePdfTemplateViews) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: fillablePdfTemplateViews,
                        },
                        loadFillablePdfTemplatesSuccess()
                    )
                );
            })
            .catch((error) => {
                dispatch(loadFillablePdfTemplatesFailure(error.message));
            });
    };
}

export function upsertFillablePdfTemplate(template) {
    return function (dispatch, getState, { nexus }) {
        dispatch(saveFillablePdfTemplateStart());
        return fillablePdfTemplatesResource
            .upsertFillablePdfTemplate(template)
            .then((fillablePdfTemplateView) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [fillablePdfTemplateView],
                        },
                        saveFillablePdfTemplateSuccess()
                    )
                );
                return fillablePdfTemplateView;
            })
            .catch((error) => {
                dispatch(saveFillablePdfTemplateFailure(error.message));
            });
    };
}

export function deleteFillablePdfTemplate(templateId) {
    return (dispatch, getState, { nexus }) => {
        return fillablePdfTemplatesResource
            .deleteFillablePdfTemplate(templateId)
            .then((result) => {
                if (result) {
                    dispatch(
                        nexus.withRemove(
                            NEXUS_STATE_PROP,
                            { id: templateId },
                            saveFillablePdfTemplateSuccess()
                        )
                    );
                }
            })
            .catch((error) => {
                dispatch(saveFillablePdfTemplateFailure(error.message));
                return error;
            });
    };
}

import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { pick } from 'lodash';
import { warrantTitleViewModelsSelector } from '~/client-common/core/domain/warrant-titles/state/ui';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import createWarrantForm from '../state/forms/createWarrantForm';
import RenSearchText from '../../../core/ren-search/components/RenSearchText';
import { createModalSelector } from '../../../core/box/state/ui';
import { loadIdFormatConfigurations } from '../../../support/id-generator/state/data';
import { submitRenSearchForCreateWarrantModal, submitWarrantNumberSearch } from '../state/ui';
import WarrantRenSearchResults from './WarrantRenSearchResults';
import WarrantNumberSearchText from './WarrantNumberSearchText';
import ExistingWarrantTitles from './ExistingWarrantTitles';
import WarrantNumberConfigurationSelect from './WarrantNumberConfigurationSelect';

const modalContext = { name: boxEnum.CREATE_WARRANT_MODAL };

const mapStateToProps = createStructuredSelector({
    existingWarrantIds: createModalSelector(modalContext, 'existingWarrantIds'),
    warrantTitleViewModels: warrantTitleViewModelsSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch) => ({
    submitRenSearch: () => dispatch(submitRenSearchForCreateWarrantModal()),
    submitWarrantNumberSearch: () =>
        dispatch(
            submitWarrantNumberSearch({
                boxContext: modalContext,
                form: createWarrantForm,
                fieldPath: 'warrantNumber',
            })
        ),
    loadIdFormatConfigurations: () => dispatch(loadIdFormatConfigurations()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(function CreateWarrantForm({
    submitRenSearch,
    submitWarrantNumberSearch,
    loadIdFormatConfigurations,
    existingWarrantIds,
    warrantTitleViewModels,
}) {
    React.useEffect(() => {
        loadIdFormatConfigurations();
    }, [loadIdFormatConfigurations]);
    const existingWarrantTitleViewModels = pick(warrantTitleViewModels, existingWarrantIds);
    return (
        <ReactReduxForm {...createWarrantForm}>
            <Row>
                <RRFAttributeSelect
                    path="warrantTypeAttrId"
                    width={250}
                    attributeType="WARRANT_TYPE"
                />
            </Row>
            <Row>
                <WarrantNumberSearchText
                    onSearch={submitWarrantNumberSearch}
                    context={RefContextEnum.FORM_WARRANT_CREATE_WARRANT.name}
                    path="warrantNumber"
                />
                <WarrantNumberConfigurationSelect path="idFormatConfigurationId" width={250} />
            </Row>
            <Row>
                <ExistingWarrantTitles warrantTitleViewModels={existingWarrantTitleViewModels} />
            </Row>
            <RenSearchText
                typeToSearch={true}
                path="reportingEventNumber"
                submitSearch={submitRenSearch}
                goButton={false}
                width={206}
            />
            <Row>
                <WarrantRenSearchResults />
            </Row>
        </ReactReduxForm>
    );
});

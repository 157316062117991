import { EntityTypeEnum } from '@mark43/rms-api';
import _, { map, keyBy, chain } from 'lodash';
import { createSelector } from 'reselect';
import { DISPLAY_ONLY_CASE } from '../../../../enums/universal/fields';
import { caseNotesWhereSelector } from '../../../case-notes/state/data';
import { tasksWhereSelector } from '../../../tasks/state/data';
import {
    sortedAugmentedAttachmentViewModelsWhereSelector,
    attachmentToFileGridSelector,
} from '../../../attachments/state/ui';
import {
    reportIdsForCaseIdSelector,
    warrantIdsForCaseIdSelector,
} from '../../../case-links/state/data';
import { elasticReportByIdSelector } from '../../../elastic-reports/state/data';
import { elasticWarrantByIdSelector } from '../../../elastic-warrants/state/data';
import { userHasAbilitySelector } from '../../../abilities/state/data';
import { canRead } from '../../../entity-permissions/state/ui';

import { formatWarrantTitle } from '../../../../../helpers/warrantHelpers';
import abilitiesEnum from '../../../../../enums/universal/abilitiesEnum';
import { caseByIdSelector } from '../../../cases/state/data';
import {
    translateToCaseAttachment,
    translateToCaseNoteAttachment,
    translateToCaseTaskAttachment,
    translateToCaseReportAttachment,
    translateToCaseWarrantAttachment,
} from '../../../attachments/utils/attachmentsHelper';
import { applicationSettingsSelector } from '../../../settings/state/data';
import { formatFieldByNameSelector } from '../../../../fields/state/config';

const caseAttachmentsByCaseIdSelector = createSelector(
    sortedAugmentedAttachmentViewModelsWhereSelector,
    caseByIdSelector,
    formatFieldByNameSelector,
    (sortedAugmentedAttachmentViewModelsWhere, caseByIdSelector, formatFieldByName) => (caseId) => {
        const currentCase = caseByIdSelector(caseId);
        const attachments = sortedAugmentedAttachmentViewModelsWhere({
            entityType: EntityTypeEnum.CASE.name,
            entityId: caseId,
        });

        const caseFieldName = formatFieldByName(DISPLAY_ONLY_CASE);

        return _(attachments)
            .map((attachment) =>
                translateToCaseAttachment(attachment, currentCase.title, caseFieldName)
            )
            .flatten()
            .value();
    }
);

const caseNoteAttachmentsByCaseIdSelector = createSelector(
    caseNotesWhereSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    formatFieldByNameSelector,
    (caseNotesWhere, sortedAugmentedAttachmentViewModelsWhere, formatFieldByName) => (caseId) => {
        const caseNotes = caseNotesWhere({ caseId });
        const caseFieldName = formatFieldByName(DISPLAY_ONLY_CASE);
        return _(caseNotes)
            .map((caseNote) =>
                map(
                    sortedAugmentedAttachmentViewModelsWhere({
                        entityType: EntityTypeEnum.CASE_NOTE.name,
                        entityId: caseNote.id,
                    }),
                    (attachmentViewModel) =>
                        translateToCaseNoteAttachment(
                            attachmentViewModel,
                            caseId,
                            caseNote,
                            caseFieldName
                        )
                )
            )
            .flatten()
            .value();
    }
);

export const caseTaskAttachmentsByCaseIdSelector = createSelector(
    tasksWhereSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    formatFieldByNameSelector,
    (tasksWhere, sortedAugmentedAttachmentViewModelsWhere, formatFieldByName) => (caseId) => {
        const tasks = tasksWhere({ caseId });
        const caseFieldName = formatFieldByName(DISPLAY_ONLY_CASE);
        return _(tasks)
            .map((task) =>
                map(
                    sortedAugmentedAttachmentViewModelsWhere({
                        entityType: EntityTypeEnum.TASK.name,
                        entityId: task.id,
                    }),
                    (attachmentViewModel) =>
                        translateToCaseTaskAttachment(
                            attachmentViewModel,
                            caseId,
                            task,
                            caseFieldName
                        )
                )
            )
            .flatten()
            .value();
    }
);

const warrantAttachmentsByCaseIdSelector = createSelector(
    warrantIdsForCaseIdSelector,
    elasticWarrantByIdSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    (warrantIdsForCaseId, elasticWarrantById, sortedAugmentedAttachmentViewModelsWhere) => (
        caseId
    ) => {
        const warrantIds = warrantIdsForCaseId(caseId);
        return _(warrantIds)
            .map((warrantId) => {
                const elasticWarrant = elasticWarrantById(warrantId);
                if (!elasticWarrant || !canRead(elasticWarrant.permissionSet)) {
                    return null;
                }

                // Ideally we'd use WarrantTitle.warrantTitle here, but we don't have those on
                // case page load, and the UI looks cleaner with just the warrant number.
                const warrantTitle = formatWarrantTitle({
                    warrantNumber: elasticWarrant.warrantNumber,
                    warrantType: '', // see elasticWarrant.warrantTypeAttrDetail.displayValue
                    subjectName: '', // see elasticWarrant.subject
                });

                const warrantAttachmentViewModels = sortedAugmentedAttachmentViewModelsWhere({
                    entityType: EntityTypeEnum.WARRANT.name,
                    entityId: warrantId,
                });

                return map(warrantAttachmentViewModels, (attachmentViewModel) =>
                    translateToCaseWarrantAttachment(
                        attachmentViewModel,
                        caseId,
                        warrantId,
                        warrantTitle
                    )
                );
            })
            .compact()
            .flatten()
            .value();
    }
);

const reportAttachmentsByCaseIdSelector = createSelector(
    reportIdsForCaseIdSelector,
    elasticReportByIdSelector,
    sortedAugmentedAttachmentViewModelsWhereSelector,
    (reportIdsForCaseId, elasticReportById, sortedAugmentedAttachmentViewModelsWhere) => (
        caseId
    ) => {
        const reportIds = reportIdsForCaseId(caseId);
        return _(reportIds)
            .map((reportId) => {
                const elasticReport = elasticReportById(reportId);
                if (!elasticReport || !canRead(elasticReport.permissionSet)) {
                    return null;
                }

                const reportAttachmentViewModels = sortedAugmentedAttachmentViewModelsWhere({
                    entityType: EntityTypeEnum.REPORT.name,
                    entityId: reportId,
                });
                return map(reportAttachmentViewModels, (attachmentViewModel) =>
                    translateToCaseReportAttachment(attachmentViewModel, caseId, elasticReport)
                );
            })
            .compact()
            .flatten()
            .value();
    }
);

export const caseAttachmentsByCaseIdAndUserIdSelector = createSelector(
    caseAttachmentsByCaseIdSelector,
    caseNoteAttachmentsByCaseIdSelector,
    caseTaskAttachmentsByCaseIdSelector,
    reportAttachmentsByCaseIdSelector,
    warrantAttachmentsByCaseIdSelector,
    userHasAbilitySelector,
    (
        caseAttachmentByCaseId,
        caseNoteAttachmentsByCaseId,
        caseTaskAttachmentsByCaseId,
        reportAttachmentsByCaseId,
        warrantAttachmentsByCaseId,
        userHasAbility
    ) => (caseId, userId) => {
        const caseAttachments = userHasAbility(userId, abilitiesEnum.CASES.VIEW_GENERAL)
            ? caseAttachmentByCaseId(caseId)
            : [];
        const noteAttachments = userHasAbility(userId, abilitiesEnum.CASES.VIEW_NOTES)
            ? caseNoteAttachmentsByCaseId(caseId)
            : [];

        const taskAttachments = userHasAbility(userId, abilitiesEnum.CASES.VIEW_CASE_TASKS)
            ? caseTaskAttachmentsByCaseId(caseId)
            : [];

        const reportAttachments = reportAttachmentsByCaseId(caseId);
        const warrantAttachments = warrantAttachmentsByCaseId(caseId);

        return [
            ...caseAttachments,
            ...noteAttachments,
            ...taskAttachments,
            ...reportAttachments,
            ...warrantAttachments,
        ];
    }
);

export const convertAttachmentsToCaseAttachmentsRowViewModelsSelector = createSelector(
    userHasAbilitySelector,
    caseByIdSelector,
    attachmentToFileGridSelector,
    caseNotesWhereSelector,
    tasksWhereSelector,
    reportIdsForCaseIdSelector,
    warrantIdsForCaseIdSelector,
    elasticReportByIdSelector,
    elasticWarrantByIdSelector,
    formatFieldByNameSelector,
    applicationSettingsSelector,

    (
        userHasAbility,
        caseById,
        attachmentToFileGrid,
        caseNotesWhere,
        tasksWhere,
        reportIdsForCaseId,
        warrantIdsForCaseId,
        elasticReportById,
        elasticWarrantById,
        formatFieldByName
    ) => (caseId, userId, currentAttachments) => {
        if (!currentAttachments) {
            return undefined;
        }

        const currentCase = caseById(caseId);
        const caseNotes = caseNotesWhere({ caseId });
        const caseTasks = tasksWhere({ caseId });
        const caseReportIds = reportIdsForCaseId(caseId);
        const caseWarrantIds = warrantIdsForCaseId(caseId);

        const currentAttachmentsKeyById = keyBy(currentAttachments, 'id');
        const attachmentFileGrids = attachmentToFileGrid(currentAttachmentsKeyById);

        const caseFieldName = formatFieldByName(DISPLAY_ONLY_CASE);

        const caseAttachments = userHasAbility(userId, abilitiesEnum.CASES.VIEW_GENERAL)
            ? chain(attachmentFileGrids)
                  .filter((attachment) => attachment.entityType === EntityTypeEnum.CASE.name)
                  .map((attachment) =>
                      translateToCaseAttachment(attachment, currentCase?.title, caseFieldName)
                  )
                  .value()
            : [];

        const noteAttachments = userHasAbility(userId, abilitiesEnum.CASES.VIEW_NOTES)
            ? chain(attachmentFileGrids)
                  .filter((attachment) => attachment.entityType === EntityTypeEnum.CASE_NOTE.name)
                  .map((attachment) => {
                      const caseNote = _(caseNotes).find((note) => note.id === attachment.entityId);

                      return translateToCaseNoteAttachment(
                          attachment,
                          caseId,
                          caseNote,
                          caseFieldName
                      );
                  })
                  .value()
            : [];

        const taskAttachments = userHasAbility(userId, abilitiesEnum.CASES.VIEW_CASE_TASKS)
            ? chain(attachmentFileGrids)
                  .filter((attachment) => attachment.entityType === EntityTypeEnum.TASK.name)
                  .map((attachment) => {
                      const caseTask = _(caseTasks).find((task) => task.id === attachment.entityId);

                      return translateToCaseTaskAttachment(
                          attachment,
                          caseId,
                          caseTask,
                          caseFieldName
                      );
                  })
                  .value()
            : [];

        const reportAttachments = chain(attachmentFileGrids)
            .filter((attachment) => attachment.entityType === EntityTypeEnum.REPORT.name)
            .map((attachment) => {
                const caseReportId = _(caseReportIds).find((id) => id === attachment.entityId);

                const elasticReport = elasticReportById(caseReportId);

                return translateToCaseReportAttachment(attachment, caseId, elasticReport);
            })
            .value();

        const warrantAttachments = chain(attachmentFileGrids)
            .filter((attachment) => attachment.entityType === EntityTypeEnum.WARRANT.name)
            .map((attachment) => {
                const caseWarrantId = _(caseWarrantIds).find((id) => id === attachment.entityId);

                const elasticWarrant = elasticWarrantById(caseWarrantId);
                if (!elasticWarrant || !canRead(elasticWarrant.permissionSet)) {
                    return null;
                }

                const warrantTitle = formatWarrantTitle({
                    warrantNumber: elasticWarrant.warrantNumber,
                    warrantType: '', // see elasticWarrant.warrantTypeAttrDetail.displayValue
                    subjectName: '', // see elasticWarrant.subject
                });

                return translateToCaseWarrantAttachment(
                    attachment,
                    caseId,
                    caseWarrantId,
                    warrantTitle
                );
            })
            .value();

        return [
            ...caseAttachments,
            ...noteAttachments,
            ...taskAttachments,
            ...reportAttachments,
            ...warrantAttachments,
        ];
    }
);

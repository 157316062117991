import React from 'react';
import { rateLimitTypeahead } from '~/client-common/helpers/searchHelpers';

/**
 *  This is a uncontrolled Text component that debounces the onChange prop from firing
 *  It CANNOT be passed a value, instead it stores the value locally so it can update
 *  independently of the onChange.
 *  It takes all the same props as Text, additionally a TextComponent that can be a
 *  styled-component wrapper aroudn text
 *  @param: {TextComponent} [ React component to use for rendering]
 *  @param: {onChange} [Form onChange function]
 */
export default class DebouncedText extends React.Component {
    constructor(props) {
        super(props);
        this.debounceOnChange = rateLimitTypeahead(
            this.debounceOnChange.bind(this),
            this.props.throttle
        );
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: '',
        };
    }

    debounceOnChange() {
        this.props.onChange(this.state.value);
    }

    onChange(value) {
        this.setState(
            {
                value,
            },
            () => {
                this.debounceOnChange();
            }
        );
    }

    render() {
        const { TextComponent, ...otherProps } = this.props;
        return <TextComponent {...otherProps} value={this.state.value} onChange={this.onChange} />;
    }
}

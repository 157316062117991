import {
    EntityTypeEnum,
    ChainEventCategoryEnum,
    FileTypeEnum,
    LinkTypesEnum,
} from '@mark43/rms-api';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withState, setPropTypes } from 'recompose';
import { chain, map, filter, head, last, compact } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_CUSTODY_LABEL,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import { formatRenSequenceNumberSelector } from '~/client-common/core/domain/item-reporting-event-links/state/ui';
import { FormattedDate } from '~/client-common/core/dates/components';
import { chainOfCustodyViewModelsForMasterItemIdSelector } from '~/client-common/core/domain/chain-of-custodies/state/ui';

import { itemEvidenceStateByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import Icon, { iconTypes as legacyIconTypes } from '../../../../legacy-redux/components/core/Icon';
import Link from '../../../core/components/links/Link';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import InlineFileRow from '../../../attachments/core/components/InlineFileRow';
import _ChainOfCustodyPdfLink from './ChainOfCustodyPdfLink';
import DeleteChainEventModal from './DeleteChainEventModal';

const strings = componentStrings.evidence.chainOfCustody.ChainOfCustody;

const ChainOfCustodyWrapper = styled.div`
    margin-bottom: 30px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Content = styled.div`
    flex: 0 0 408px;
    max-width: 408px;
`;

const ContextMenu = styled.div`
    display: inline-block;
    flex: 0 0 12px;
    margin: 0 8px 0 12px;
`;

export const ChainEventWrapper = styled.div`
    border-left: 2px solid ${(props) => props.theme.colors.mediumBlue};
    padding-left: 20px;
    position: relative;
    padding-bottom: 30px;
    margin-bottom: 0;
`;

export const ChainEventItemHeader = styled.div`
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--arc-fontSizes-md);
`;

export const ChainEventItemSubheader = styled.div`
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 4px;
    font-size: var(--arc-fontSizes-sm);
`;

const ChainEventBody = styled.div`
    border-left: 1px dashed ${(props) => props.theme.colors.mediumBlue};
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 5px 0;
    font-size: var(--arc-fontSizes-sm);
    position: relative;
`;

const ChainEventBodyDetail = styled.div`
    margin-bottom: 2px;
    margin-top: 0;
`;

const ChainEventBodyDetailDescription = styled(ChainEventBodyDetail)`
    white-space: pre-wrap;
`;

const ChainEventLink = styled.div`
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-md);
    cursor: pointer;
`;

export const CornerDot = styled.div`
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.cobaltBlue};
    height: 8px;
    width: 8px;
    position: absolute;
    top: 5px;
    left: -5px;
    outline: 2px solid ${(props) => props.theme.colors.white};
`;

export const Bold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const ExpandCoCToggle = styled.div`
    border-bottom: 2px dashed ${(props) => props.theme.colors.cobaltBlue};
    height: 13px;
    width: 100%;
    text-align: center;
`;

const ExpandCoCToggleText = styled.div`
    width: 130px;
    cursor: pointer;
    display: inline-block;
    background-color: ${(props) => props.theme.colors.white};
    text-align: center;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-md);
    padding: 0 16px;
`;

const Image = styled.img`
    width: 80%;
`;

const ChainOfCustodyPdfLink = styled(_ChainOfCustodyPdfLink)`
    background-color: ${(props) => props.theme.colors.extraLightGrey};
`;

const ChainEventBodyImage = function ({ path, title, fileType, fileName }) {
    return (
        <div>
            <ChainEventBodyDetail>{title}</ChainEventBodyDetail>
            {fileType === FileTypeEnum.PDF.name ? (
                <ChainOfCustodyPdfLink filePath={path} fileName={fileName} width={300} />
            ) : (
                <Image src={path} />
            )}
        </div>
    );
};

const ChainEvent = compose(
    withState('expand', 'setExpand', false),
    withHandlers({
        toggleExpand: (props) => () => {
            props.setExpand(!props.expand);
        },
    }),
    connect(
        createStructuredSelector({
            userHasAbility: currentUserHasAbilitySelector,
            formatFieldByName: formatFieldByNameSelector,
        })
    ),
    setPropTypes({
        chainEvent: PropTypes.object.isRequired,
    })
)(({ chainEvent, canDelete, expand, toggleExpand, userHasAbility, formatFieldByName }) => {
    const chainProps = getViewModelProperties(chainEvent);
    const name = chainProps.eventUserId.fullNameWithFirstInitial;
    let header;
    let body;
    const { attachments, eventType, eventTypeDisplay, facility, storageLocation } = chainProps;
    const chainEventAttachments = filter(attachments, {
        linkType: LinkTypesEnum.CHAIN_EVENT_ATTACHMENT,
    });
    const showStorageLocation = userHasAbility(abilitiesEnum.EVIDENCE.CREATE_CHAIN_EVENTS);
    const custodyLabel = formatFieldByName(DISPLAY_ONLY_CUSTODY_LABEL);
    switch (eventType.chainEventCategory) {
        case ChainEventCategoryEnum.IN_POLICE_CUSTODY.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> of <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            break;
        case ChainEventCategoryEnum.CHECKED_IN_TEMP.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> to <Bold>{facility}</Bold> by{' '}
                    <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            if (storageLocation && showStorageLocation) {
                body = (
                    <ChainEventBodyDetail>{storageLocation.fullDisplayPath}</ChainEventBodyDetail>
                );
            } else {
                body = (
                    <ChainEventBodyDetail>
                        {strings.dropOffStorageLocation}: <Bold>{facility}</Bold>
                    </ChainEventBodyDetail>
                );
            }
            break;
        case ChainEventCategoryEnum.CHECKED_IN_MAIN.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> to <Bold>{facility}</Bold> by{' '}
                    <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            if (storageLocation && showStorageLocation) {
                body = (
                    <ChainEventBodyDetail>{storageLocation.fullDisplayPath}</ChainEventBodyDetail>
                );
            }
            break;
        case ChainEventCategoryEnum.CHECKED_OUT_LAB.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> to <Bold>{facility}</Bold> by{' '}
                    <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            if (storageLocation && showStorageLocation) {
                body = (
                    <ChainEventBodyDetail>{storageLocation.fullDisplayPath}</ChainEventBodyDetail>
                );
            } else {
                body = (
                    <ChainEventBodyDetail>
                        {strings.checkedOutLabFacility}: <Bold>{facility}</Bold>
                    </ChainEventBodyDetail>
                );
            }
            break;
        case ChainEventCategoryEnum.CHECKED_OUT_PERSON.name:
        case ChainEventCategoryEnum.INFIELD_TRANSFER.name:
            // the person is either `receivedByEntityId` which is a Cobalt user,
            // or `receivedByName` which is a string entered by a clerk
            const receivedName =
                chainEvent.receivedByEntityType === EntityTypeEnum.USER.name
                    ? chainProps.receivedByEntityId.fullNameWithFirstInitial
                    : chainEvent.receivedByName;
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> to <Bold>{receivedName}</Bold> by{' '}
                    <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            break;
        case ChainEventCategoryEnum.TRANSFERRED.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> to <Bold>{chainEvent.receivedByName}</Bold> by{' '}
                    <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            body = (
                <ChainEventBodyDetail>
                    {strings.transferredAgency}: <Bold>{chainEvent.receivedByName}</Bold>
                </ChainEventBodyDetail>
            );
            break;
        case ChainEventCategoryEnum.LOCATION_UPDATED.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> within <Bold>{facility}</Bold> by{' '}
                    <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            if (storageLocation && showStorageLocation) {
                body = (
                    <ChainEventBodyDetail>{storageLocation.fullDisplayPath}</ChainEventBodyDetail>
                );
            }
            break;
        case ChainEventCategoryEnum.DISPOSITIONED.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> by <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            body = (
                <ChainEventBodyDetail>
                    <Bold>{eventTypeDisplay}</Bold>
                </ChainEventBodyDetail>
            );
            break;
        case ChainEventCategoryEnum.RELEASED.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> by <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            body = (
                <ChainEventBodyDetail>
                    {strings.releasedPersonProfile(custodyLabel)}:{' '}
                    <Bold>{chainEvent.receivedByName}</Bold>
                    <Bold>{chainEvent.receivedByName}</Bold>
                </ChainEventBodyDetail>
            );
            break;
        case ChainEventCategoryEnum.MIGRATED.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> by <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );

            body = (
                <>
                    {storageLocation && showStorageLocation && (
                        <ChainEventBodyDetail>
                            {strings.location}: {storageLocation.fullDisplayPath}
                        </ChainEventBodyDetail>
                    )}
                    {chainEvent && chainEvent.receivedByName && (
                        <ChainEventBodyDetail>
                            {strings.receivedBy}: {chainEvent.receivedByName}
                        </ChainEventBodyDetail>
                    )}
                </>
            );
            const {
                props: { children },
            } = body;

            // This is needed to ensure that we don't render the "Show Details" link when
            // the "body" does not have any actual children
            if (compact(children).length === 0) {
                body = null;
            }
            break;
        case ChainEventCategoryEnum.LOCATION_UPDATED_AUTOMATICALLY.name:
            header = (
                <ChainEventItemHeader>
                    <Bold>{eventTypeDisplay}</Bold> within <Bold>{facility}</Bold> by{' '}
                    <Bold>{name}</Bold>
                </ChainEventItemHeader>
            );
            if (storageLocation && showStorageLocation) {
                body = (
                    <ChainEventBodyDetail>{storageLocation.fullDisplayPath}</ChainEventBodyDetail>
                );
            }
            break;
        default:
            header = null;
            body = null;
    }
    const hasBody = body || chainEvent.description || attachments.length > 0;
    return (
        <ChainEventWrapper>
            <Row>
                <Content>
                    <CornerDot />
                    {header}
                    <ChainEventItemSubheader>
                        <FormattedDate
                            date={chainEvent.eventDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE_TIME}
                        />
                    </ChainEventItemSubheader>
                    {hasBody && expand ? (
                        <ChainEventBody>
                            {body}
                            {chain(attachments)
                                .reject({ linkType: LinkTypesEnum.CHAIN_EVENT_ATTACHMENT })
                                .sortBy('linkType')
                                .map((attachment) => {
                                    const { path, originalFileName } = getViewModelProperties(
                                        attachment
                                    );
                                    const { linkType } = attachment;

                                    let title = '';
                                    if (linkType === LinkTypesEnum.CHAIN_EVENT_SIGNATURE) {
                                        title = strings.signature;
                                    } else if (linkType === LinkTypesEnum.CHAIN_EVENT_ID_SCAN) {
                                        title = strings.identification;
                                    }
                                    return (
                                        <ChainEventBodyImage
                                            key={attachment.id}
                                            path={path}
                                            title={title}
                                            fileType={attachment.file?.fileType}
                                            fileName={originalFileName}
                                        />
                                    );
                                })
                                .value()}
                            {chainEventAttachments.length > 0 && (
                                <ChainEventBodyDetail>{strings.attachments}</ChainEventBodyDetail>
                            )}
                            {map(chainEventAttachments, (attachment) => (
                                <InlineFileRow
                                    key={attachment.file.id}
                                    url={attachment.file.fileWebServerPath}
                                    fileName={attachment.file.originalFileName}
                                    hideDetails={true}
                                    isLoading={attachment.file.isLoading}
                                    fileCategory={attachment.file.fileCategory}
                                    disabled={true}
                                />
                            ))}
                            {chainEvent.description && (
                                <ChainEventBodyDetailDescription>
                                    {chainEvent.description}
                                </ChainEventBodyDetailDescription>
                            )}
                        </ChainEventBody>
                    ) : (
                        <div />
                    )}
                    {hasBody ? (
                        <ChainEventLink onClick={toggleExpand}>
                            {expand ? strings.hideDetails : strings.showDetails}
                        </ChainEventLink>
                    ) : null}
                </Content>
                <ContextMenu>
                    {canDelete && <DeleteChainEventModal chainEventId={chainEvent.id} />}
                </ContextMenu>
            </Row>
        </ChainEventWrapper>
    );
});

// An individual chain of custody

function ChainOfCustody({
    canDeleteCoC = false,
    chainOfCustody,
    formatFieldByName,
    formatRenSequenceNumber,
}) {
    const { chainEvents, reportingEventNumber, custodialReportId, masterItemId } = chainOfCustody;
    // This number is retrieved from the /reports request meaning it might be loaded when the CoCs are created
    if (!reportingEventNumber) {
        return <div />;
    }
    const newestDate = head(chainEvents).eventDateUtc;
    const oldestDate = last(chainEvents).eventDateUtc;

    return (
        <ChainOfCustodyWrapper>
            <Link onClick={(event) => event.stopPropagation()} to={`reports/${custodialReportId}`}>
                <ChainEventLink>
                    {formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)}{' '}
                    {formatRenSequenceNumber(masterItemId, custodialReportId)}
                </ChainEventLink>
            </Link>
            <ChainEventItemSubheader>
                <FormattedDate date={oldestDate} format={FormattedDate.FORMATS.FORM_DATE} />
                {' - '}
                <FormattedDate date={newestDate} format={FormattedDate.FORMATS.FORM_DATE} />
            </ChainEventItemSubheader>
            <br />
            {chainEvents.map((chainEvent, index) => {
                const canDelete = canDeleteCoC && index === 0;
                return (
                    <ChainEvent key={chainEvent.id} canDelete={canDelete} chainEvent={chainEvent} />
                );
            })}
        </ChainOfCustodyWrapper>
    );
}

// All of the chain of custodies on the page
function ChainOfCustodies({
    chainOfCustodyViewModelsForMasterItemId,
    currentUserHasAbility,
    itemEvidenceStateByChainOfCustodyId,
    masterItemId,
    expand,
    toggleExpand,
    formatFieldByName,
    formatRenSequenceNumber,
}) {
    const chainOfCustodies = chainOfCustodyViewModelsForMasterItemId(masterItemId);
    if (!chainOfCustodies || chainOfCustodies.length === 0) {
        return <div />;
    }

    const hasMasterEditCoC = currentUserHasAbility(
        abilitiesEnum.EVIDENCE.MASTER_EDIT_CHAIN_OF_CUSTODY
    );

    const canDeleteCoC = (chainOfCustody) =>
        hasMasterEditCoC &&
        chain(chainOfCustody)
            .get('chainOfCustodyId')
            .thru(itemEvidenceStateByChainOfCustodyId)
            .get('canUserDeleteLastChainEvent')
            .value();

    return (
        <div>
            {chain([])
                .concat(expand ? head(chainOfCustodies) : chainOfCustodies)
                .map((chainOfCustody) => (
                    <ChainOfCustody
                        formatFieldByName={formatFieldByName}
                        formatRenSequenceNumber={formatRenSequenceNumber}
                        key={chainOfCustody.chainOfCustodyId}
                        canDeleteCoC={canDeleteCoC(chainOfCustody)}
                        chainOfCustody={chainOfCustody}
                    />
                ))
                .value()}

            {chainOfCustodies.length > 1 && (
                <ExpandCoCToggle>
                    <ExpandCoCToggleText onClick={toggleExpand}>
                        {expand ? (
                            <span>
                                <Icon type={legacyIconTypes.EXPAND} /> {strings.viewMore}
                            </span>
                        ) : (
                            <span>
                                <Icon type={legacyIconTypes.COLLAPSE} /> {strings.viewLess}
                            </span>
                        )}
                    </ExpandCoCToggleText>
                </ExpandCoCToggle>
            )}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    chainOfCustodyViewModelsForMasterItemId: chainOfCustodyViewModelsForMasterItemIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    formatFieldByName: formatFieldByNameSelector,
    formatRenSequenceNumber: formatRenSequenceNumberSelector,
    itemEvidenceStateByChainOfCustodyId: itemEvidenceStateByChainOfCustodyIdSelector,
});

/**
 * The Chain of Custody timeline component
 * A listing of all the chain events that have occurred to a piece of evidence
 * @param  {chainOfCustodyViewModels} A list of chain of custody view models each containing chain events
 */
export default compose(
    connect(mapStateToProps),
    withState('expand', 'setExpand', true),
    withHandlers({
        toggleExpand: (props) => () => {
            props.setExpand(!props.expand);
        },
    })
)(ChainOfCustodies);

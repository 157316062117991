import React, { useState, useCallback } from 'react';
import { change } from 'redux-form-mark43';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { FileCategoryEnumType, FileCategoryEnum, UserProfileView } from '@mark43/rms-api';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';
import { AttachmentViewModel } from '~/client-common/core/domain/attachments/state/ui';

import SignaturePad from '../../../../modules/evidence/signature-pad/components/SignaturePad';
import Icon, { iconTypes } from '../../../../modules/core/components/Icon';
import Button, { buttonTypes } from '../../core/Button';

interface UserProfileAdminSignatureUploadProps {
    profile: UserProfileView & { userSignature: AttachmentViewModel };
    width: number;
}

const SignatureImageContainer = styled.div`
    position: relative;
    display: flex;
    z-index: 0;
    align-items: flex-start;
    justify-content: left;
    flex-direction: column;
`;

const SignatureWithBorder = styled(ImgWithExifOrientation)`
    border: 1px solid ${(props) => props.theme.colors.brightBlue};
    max-height: 100px;
    max-width: 100%;
`;

const UserProfileAdminSignatureUpload: React.FC<UserProfileAdminSignatureUploadProps> = ({
    profile,
    width,
}) => {
    const dispatch = useDispatch();
    const [userSignatureId, setUserSignatureId] = useState<number | undefined>(undefined);

    const handleOnSuccess = useCallback(
        (fileCategory: FileCategoryEnumType) => {
            if (fileCategory !== FileCategoryEnum.IMAGE.name) {
                throw Error();
            } else {
                dispatch(change('userProfileAdmin', 'userSignature', userSignatureId));
            }
        },
        [dispatch, userSignatureId]
    );

    const removeUserSignature = useCallback(() => {
        setUserSignatureId(undefined);
        dispatch(change('userProfileAdmin', 'removedUserSignature', true));
        dispatch(change('userProfileAdmin', 'userSignature', null));
    }, [dispatch]);

    const imageUrl: string = getViewModelProperties(profile.userSignature).originalFile
        ?.fileWebServerPath;
    /*  hasUserSignature tracks whether the user already a signature saved. When the user uploads a signature, this state doesn't become true until after the side panel is saved and reopened. */
    const [hasUserSignature, setHasUserSignature] = useState(!!profile.userSignature);

    return (
        <>
            {hasUserSignature ? (
                <SignatureImageContainer>
                    <SignatureWithBorder src={imageUrl} isBackgroundImage={false} />
                    <Button
                        className={buttonTypes.SECONDARY}
                        onClick={() => {
                            removeUserSignature();
                            setHasUserSignature(false);
                        }}
                    >
                        <Icon type={iconTypes.TRASH_CAN} color="cobaltBlue" />
                        Remove
                    </Button>
                </SignatureImageContainer>
            ) : (
                <SignaturePad
                    onChange={setUserSignatureId}
                    onSuccess={handleOnSuccess}
                    width={width}
                    onRemove={removeUserSignature}
                    hasSizeLimits={true}
                />
            )}
        </>
    );
};

export default UserProfileAdminSignatureUpload;

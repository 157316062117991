import { AttributeTypeEnum, ElasticNameAttribute, NameAttribute } from '@mark43/rms-api';
import styled from 'styled-components';
import { Caution, CautionGroup, CautionProps } from '@arc/tag';
import React from 'react';
import { useSelector } from 'react-redux';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';

import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';

import _Label from '../typography/Label';
import { getPrioritizedPersonLabelAttributes, labelColorProps } from './helpers';

// Deprecate this style component when RMS_VEHICLE_CAUTIONS_ENABLED is torn down
const Label = styled(_Label)`
    margin: 0 3px 3px 0;
    color: ${({ theme }) => theme.colors.darkGrey};
    ${({ large }: { large: boolean }) =>
        large
            ? `
                font-size: var(--arc-fontSizes-md);
                line-height: 19px;

                .dark & {
                    font-size: var(--arc-fontSizes-md);
                    line-height: 19px;
                }`
            : ''}
`;

// Deprecate this style component when RMS_VEHICLE_CAUTIONS_ENABLED is torn down
const PersonLabel: React.FC<{ priorityAttrId?: number; large?: boolean }> = ({
    priorityAttrId,
    large = false,
    children,
}) => {
    return (
        // @ts-expect-error Label doesn't allow raw strings, but since this will be deprecated, ignoring.
        <Label {...labelColorProps(priorityAttrId)} large={large}>
            {children}
        </Label>
    );
};

type PersonLabelsPropsT = {
    nameAttributes?: NameAttribute[];
    elasticNameAttributes?: ElasticNameAttribute[];
    isJuvenile?: boolean;
    isVulnerable?: boolean;
    dateVulnerableTo?: string;
    hasPotentialActiveWarrant?: boolean;
    isSuspectedGangMember?: boolean;
    priority1Only?: boolean;
    size: CautionProps['size'];
    className?: string;
    isActiveTarget?: boolean;
};

/**
 * Display two types of person labels:
 *
 * 1. attribute of type PERSON_LABEL_ATTRIBUTES
 *
 * 2. special labels which are not represented by attributes and have hardcoded priorities
 *
 * Sort these by priority (PERSON_LABEL_PRIORITY) and then by display value alphabetically.
 *
 * Although all props are optional, they should be passed in wherever the data is available. Either
 *   nameAttributes or elasticNameAttributes should be provided depending on whether the feature
 *   uses search.
 */
export const PersonLabels: React.FC<PersonLabelsPropsT> = ({
    nameAttributes,
    elasticNameAttributes,
    isJuvenile,
    isVulnerable,
    dateVulnerableTo,
    hasPotentialActiveWarrant,
    isSuspectedGangMember,
    isActiveTarget,
    className,
    priority1Only,
    size,
}) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);

    // 1. For non-search features, in RmsHydratedPerson the gathered attributes are
    //    PERSON_LABEL_ATTRIBUTES and don't include their parent attributes of type
    //    PERSON_LABEL_CATEGORY.
    // 2. For search features, in ElasticPerson the PERSON_LABEL_ATTRIBUTES do not have
    //    parent ids since they are AttrDetails.
    // We need PERSON_LABEL_CATEGORY in order to determine each label's priority. Until
    // both models include this info, this component has to preload both attribute types.
    usePreloadAttributes([
        AttributeTypeEnum.PERSON_LABEL_CATEGORY.name,
        AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
    ]);

    const shouldRender =
        (nameAttributes && nameAttributes.length > 0) ||
        (elasticNameAttributes && elasticNameAttributes.length > 0) ||
        isJuvenile ||
        isVulnerable ||
        hasPotentialActiveWarrant ||
        isSuspectedGangMember ||
        isActiveTarget;

    if (!shouldRender) {
        return null;
    }

    return (
        <CautionGroup wrap="wrap" className={className}>
            {getPrioritizedPersonLabelAttributes({
                nameAttributes,
                elasticNameAttributes,
                isJuvenile,
                isVulnerable,
                dateVulnerableTo,
                hasPotentialActiveWarrant,
                isSuspectedGangMember,
                isActiveTarget,
                priority1Only,
                formatAttributeById,
                parentAttributeIdByAttributeId,
            }).map(({ priorityAttrId, priority, formattedCategoryAttribute }) => {
                return formattedCategoryAttribute ? (
                    <FeatureFlagged
                        key={formattedCategoryAttribute}
                        flag="RMS_VEHICLE_CAUTIONS_ENABLED"
                        fallback={
                            <PersonLabel
                                key={formattedCategoryAttribute}
                                priorityAttrId={priorityAttrId}
                                large={size === 'md'}
                            >
                                {formattedCategoryAttribute}
                            </PersonLabel>
                        }
                    >
                        <Caution size={size} priority={priority}>
                            {formattedCategoryAttribute}
                        </Caution>
                    </FeatureFlagged>
                ) : undefined;
            })}
        </CautionGroup>
    );
};

import React from 'react';
import classNames from 'classnames';

import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';

const strings = componentStrings.search.TableResultsSummary;

/**
 *
 * @param {object} props
 * @param {number} props.from The starting value in the sequence of results at which the current page starts
 * @param {number} props.to The ending value in the sequence of results at which the current page ends.
 * @param {number} props.totalResults The total number of results from the search
 * @param {string} [props.caption] Information given to the user about the number of results
 * @param {string | null} props.className An additional complimentary CSS class that TableResultsSummary can inherit
 */
export default function TableResultsSummary({
    from,
    to,
    totalResults,
    caption = `${strings.showingResults} `,
    className,
}) {
    // in a special case where there are zero total results, we don't want
    // to display "showing results 1-0 of 0"
    // 1 indexed (for the UI)
    from = totalResults === 0 ? 0 : from + 1;

    // cap `from` and `to` at `totalResults`
    from = from > totalResults ? totalResults : from;
    to = to > totalResults ? totalResults : to;

    return (
        <div className={classNames('table-results-summary', className)}>
            <span>{caption}</span>
            <span
                className="table-results-summary-range"
                data-test-id={testIds.TABLE_RESULTS_SUMMARY_RANGE}
            >
                {`${from}-${to}`}{' '}
            </span>
            <span>{`${strings.of} `}</span>
            <span className="table-results-summary-total">{totalResults}</span>
        </div>
    );
}

import { MiniUserView } from '@mark43/rms-api';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
    formatFullName,
    formatNameShortHand,
    allMiniUserFormats,
} from '../../utils/miniUsersHelpers';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'miniUsers';
const miniUserModule = createNormalizedModule<MiniUserView>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeMiniUsers = miniUserModule.actionCreators.storeEntities;

// SELECTORS
export const miniUsersSelector = miniUserModule.selectors.entitiesSelector;

export const getMiniUserByIdSelector = createSelector(
    miniUsersSelector,
    (miniUsers) => (id: number) => miniUsers[id]
);

export const miniUsersByUserRoleIdSelector = createSelector(miniUsersSelector, (miniUsers) =>
    _(miniUsers).values().keyBy('roleId').value()
);

export const formatMiniUserByIdSelector = createSelector(
    miniUsersSelector,
    (miniUsers) => (
        id: number,
        {
            firstNameAsInitial,
            useCadIdIfNoBadge,
        }: { firstNameAsInitial?: boolean; useCadIdIfNoBadge?: boolean } = {}
    ) => formatFullName(miniUsers[id], firstNameAsInitial, useCadIdIfNoBadge)
);

export const formatMiniUserShortHandByIdSelector = createSelector(
    miniUsersSelector,
    (miniUsers) => (id: number) => formatNameShortHand(miniUsers[id])
);

export const allMiniUserFormatsByIdSelector = createSelector(
    miniUsersSelector,
    (miniUsers) => (id: number) => allMiniUserFormats(miniUsers[id])
);

// REDUCER
export default miniUserModule.reducerConfig;

import { EvidenceHydratedItem } from '@mark43/evidence-api';
import { RmsAction } from '../../../../../core/typings/redux';
import evidenceHydratedItemResource from '../../resources/evidenceHydratedItemResource';
import { storeEvidenceHydratedItems } from '../../utils/evidenceItemsHelpers';

export function setHighValue(isHighValue: boolean, masterItemId: number): RmsAction<Promise<void>> {
    return (dispatch) => {
        return evidenceHydratedItemResource
            .setHighValue(isHighValue, masterItemId)
            .then((evidenceItemProfile: EvidenceHydratedItem) => {
                dispatch(storeEvidenceHydratedItems([evidenceItemProfile]));
            });
    };
}

import React from 'react';
import classNames from 'classnames';

import Icon, { iconTypes } from '../../../modules/core/components/Icon';
import testIds from '../../../core/testIds';
import Button, { buttonTypes } from './Button';

/**
 * Toggle button to expand/collapse a section or a fieldset in a search form. A
 *   section with multiple fieldsets may have multiple toggle buttons. This
 *   component can be renamed if the button gets used in other features of the
 *   app.
 * @param {Object}   props              Other props get spread into `<Button>`.
 * @param {boolean}  props.collapsed    Current expand/collapse state.
 * @param {function} props.setCollapsed Function for updating the state.
 */
export default function ExpandCollapseButton(props) {
    const { className, collapsed, setCollapsed, ...otherProps } = props;

    return (
        <Button
            float="right"
            className={classNames(buttonTypes.ICON_LINK, 'expand-collapse-button', className)}
            iconLeft={
                <Icon
                    type={collapsed ? iconTypes.OPEN : iconTypes.CLOSE}
                    color="cobaltBlue"
                    size={16}
                />
            }
            onClick={() => setCollapsed(!collapsed)}
            testId={testIds.GENERIC_EXPAND_COLLAPSE_BUTTON}
            {...otherProps}
        />
    );
}

import { useReducer } from 'react';
import { VisibleFilter } from 'arc';

const actionTypes = {
    APPLY_FILTERS: 'APPLY_FILTERS',
    SAVE_FILTERS: 'SET_FILTERS',
} as const;

export const filtersActionCreators = {
    saveFilters: <TFilterOption = string>(
        returnedFilters: VisibleFilter<TFilterOption>[],
        shouldFetchNextRequest = true
    ) => ({
        type: actionTypes.SAVE_FILTERS,
        payload: { appliedFilters: returnedFilters, shouldFetchNextRequest },
    }),
    applyFilters: <TFilterOption = string>(
        appliedFilters: VisibleFilter<TFilterOption>[],
        shouldFetchNextRequest = false
    ) => ({
        type: actionTypes.APPLY_FILTERS,
        payload: {
            appliedFilters,
            shouldFetchNextRequest,
        },
    }),
} as const;

type FiltersState<TFilterOption = string> = {
    appliedFilters: VisibleFilter<TFilterOption>[];
    shouldFetchNextRequest: boolean;
    isDirty: boolean;
};

type FiltersActionTypePayload<TFilterOption = string> = {
    appliedFilters: VisibleFilter<TFilterOption>[];
    shouldFetchNextRequest: boolean;
};

// TODO refactor it to use instantiation expressions to retain all generics
// ticket for this refactoring - https://mark43.atlassian.net/browse/RND-16189
type ActionTypes<TFilterOption = string> =
    | {
          type: typeof actionTypes.APPLY_FILTERS;
          payload: FiltersActionTypePayload<TFilterOption>;
      }
    | {
          type: typeof actionTypes.SAVE_FILTERS;
          payload: FiltersActionTypePayload<TFilterOption>;
      };

export const useFiltersState = <TFilterOption = string>({
    initialAppliedFilters,
}: {
    initialAppliedFilters: VisibleFilter<TFilterOption>[];
}) => {
    const initialState = {
        appliedFilters: initialAppliedFilters,
        shouldFetchNextRequest: false,
        isDirty: false,
    };

    function reducer(
        state: FiltersState<TFilterOption>,
        action: ActionTypes<TFilterOption>
    ): FiltersState<TFilterOption> {
        switch (action.type) {
            case actionTypes.SAVE_FILTERS: {
                const { appliedFilters, shouldFetchNextRequest } = action.payload;
                return {
                    ...state,
                    appliedFilters,
                    shouldFetchNextRequest,
                    isDirty: false,
                };
            }
            case actionTypes.APPLY_FILTERS: {
                const { appliedFilters, shouldFetchNextRequest } = action.payload;
                return {
                    ...state,
                    appliedFilters,
                    shouldFetchNextRequest,
                    isDirty: true,
                };
            }
            default:
                return state;
        }
    }

    return useReducer(reducer, initialState);
};

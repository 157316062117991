import errorCodeEnum from '~/client-common/core/enums/client/errorCodeEnum';
import redirectToErrorPage from './redirectToErrorPage';

/**
 * Redirect the user to the page that tells them they have insufficient
 *   permissions. Use this function in a React Router `onEnter` hook.
 */
export default function redirectInsufficientPermissions() {
    return (dispatch) => dispatch(redirectToErrorPage({ errorCode: errorCodeEnum.FORBIDDEN }));
}

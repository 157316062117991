import { map } from 'lodash';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as FACILITIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/facilities/state/data';
import { NEXUS_STATE_PROP as CHAIN_EVENT_TYPES_NEXUS_STATE_PROP } from '~/client-common/core/domain/chain-event-types/state/data';
import { NEXUS_STATE_PROP as LABEL_PRINTERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/label-printers/state/data';
import { setEvidenceDepartmentConfig } from '~/client-common/core/domain/evidence-department-config/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import getEvidenceBootstrapResource from '~/client-common/core/domain/evidence/resources/evidenceBootstrapResource';

import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { loadItemQueue, addItemReportLevelStaffRemarksToLoad } from './evidenceItemQueue';

/**
 * Load all data needed by the evidence app and store it in data state.
 */
export function evidenceBootstrap() {
    return function (dispatch, getState, dependencies) {
        const state = getState();

        if (currentUserHasAbilitySelector(state)(abilitiesEnum.EVIDENCE.ITEM_QUEUE)) {
            dispatch(loadItemQueue()).then((masterItemIds) => {
                // it is important to check whether `masterItemIds` has a truthy value
                // because `loadItemQueue` already catches any errors and returns `undefined`
                // in case an error is caught.
                if (masterItemIds) {
                    dispatch(addItemReportLevelStaffRemarksToLoad(masterItemIds));
                }
            });
        }

        return getEvidenceBootstrapResource()
            .bootstrap()
            .then(
                ({
                    attributes,
                    chainEventTypes,
                    facilities,
                    labelPrinters,
                    evidenceDepartmentConfig,
                }) => {
                    const entities = {
                        [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                            attributes,
                            convertAttributeToAttributeView
                        ),
                        [CHAIN_EVENT_TYPES_NEXUS_STATE_PROP]: chainEventTypes,
                        [FACILITIES_NEXUS_STATE_PROP]: facilities,
                    };

                    // label printers that come from the server only matter when
                    // this feature flag is on
                    if (applicationSettingsSelector(state).EVIDENCE_LABELS_V3_ENABLED) {
                        entities[LABEL_PRINTERS_NEXUS_STATE_PROP] = labelPrinters;
                    }
                    dispatch(
                        dependencies.nexus.withEntityItems(entities, {
                            type: 'EVIDENCE_BOOTSTRAP_ENTITIES',
                        })
                    );
                    dispatch(setEvidenceDepartmentConfig(evidenceDepartmentConfig));
                }
            );
    };
}

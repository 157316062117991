import { FormEnum } from '@mark43/rms-api';

import _, { map, isUndefined } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import {
    parsePeriod,
    convertDaysToDayPeriod,
    convertAmountAndUnitToPeriod,
} from '~/client-common/core/dates/utils/dateRangeHelpers';

import { createFormModule } from '../../../../core/forms';
import {
    buildFlatFormFieldViewModels,
    buildFormModel,
    filterFormData,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'code',
        fieldName: fields.RETENTION_POLICY_CODE,
    },
    {
        key: 'description',
        fieldName: fields.RETENTION_POLICY_DESCRIPTION,
    },
    {
        key: 'periodAmount',
        fieldName: fields.RETENTION_POLICY_PERIOD,
    },
    {
        key: 'isInfinite',
        fieldName: fields.RETENTION_POLICY_IS_INFINITE,
    },
    {
        key: 'terminalDispositionApprovalLevel',
        fieldName: fields.RETENTION_POLICY_TERMINAL_DISPOSITION_APPROVAL_LEVEL,
        validators: {
            // this server field config isn't required as of 2017-05-03
            requiredError: requiredString,
        },
    },
    {
        key: 'isDefault',
        fieldName: fields.RETENTION_POLICY_IS_DEFAULT,
    },
    {
        key: 'offenseCodeIds',
        fieldName: fields.OFFENSE_CODE_RETENTION_POLICY_LINK_OFFENSE_CODE_ID,
        type: fieldTypeClientEnum.OFFENSE_CODE,
    },
    {
        key: 'custodyReasonAttrIds',
        fieldName: fields.CUSTODY_REASON_RETENTION_POLICY_LINK_CUSTODY_REASON_ATTR_ID,
        type: fieldTypeClientEnum.ATTRIBUTE,
    },
    'isRetentionPolicyLimited',
    'periodUnit',
]);

/**
 * Convert the given retention policy data model to form model.
 * @param  {Object} [retentionPolicy]
 * @return {Object}
 */
export function convertRetentionPolicyToFormModel(retentionPolicy = {}) {
    const { unit, amount } = parsePeriod(retentionPolicy.period);

    return buildFormModel(
        {
            ...retentionPolicy,
            periodUnit: !retentionPolicy.isInfinite ? unit : undefined,
            periodAmount:
                !retentionPolicy.isInfinite && !isUndefined(amount) ? amount.toString() : undefined,
            isRetentionPolicyLimited:
                !retentionPolicy.isInfinite && !!retentionPolicy.period ? true : undefined,
        },
        fieldViewModels
    );
}

/**
 * Convert the given retention policy form model to data model, to be submitted
 *   to the server.
 * @param  {Object} [formModel]
 * @param  {number} [retentionPolicyId]
 * @return {Object}
 */
export function convertRetentionPolicyFormModelToDataState(formModel = {}, retentionPolicyId) {
    return filterFormData({
        ..._(formModel)
            .pick(map(fieldViewModels, 'key'))
            .omit(['periodUnit', 'periodAmount', 'isRetentionPolicyLimited'])
            .value(),
        // fill in 999 years for an indefinite period because the period field
        // is not nullable as of 2017-05-03
        period: formModel.isInfinite
            ? 'P999Y'
            : !!formModel.periodUnit
            ? convertAmountAndUnitToPeriod(formModel.periodAmount, formModel.periodUnit)
            : convertDaysToDayPeriod(formModel.periodAmount),
        retentionPolicyId,
    });
}

const retentionPolicyAdminForm = createFormModule({
    formName: FormEnum.EVIDENCE_RETENTION_POLICY_ADMIN.name,
    fieldViewModels,
    convertToFormModel: convertRetentionPolicyToFormModel,
    convertFromFormModel: convertRetentionPolicyFormModelToDataState,
});

/**
 * Module of the retention policy admin form, for creating/editing a single
 *   retention policy and all its links at the same time.
 * @type {Object}
 */
export default retentionPolicyAdminForm;

import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';

import {
    CASE_IS_TARGET_PROFILE,
    CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
    CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_PRIORITY_ATTRIBUTE_ID,
} from '~/client-common/core/enums/universal/fields';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { FIELDSET, RANGE } = fieldTypeClientEnum;
const { DATE_TIME_RANGE } = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;

const targetProfileFields = [
    {
        key: 'isTargetProfile',
        fieldName: CASE_IS_TARGET_PROFILE,
    },
    {
        key: 'targetProfileCategoryAttrIds',
        fieldName: CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
    },
    {
        key: 'targetProfilePriorityAttrIds',
        fieldName: CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_PRIORITY_ATTRIBUTE_ID,
    },
];

const dateTimeRangeFields = [
    {
        key: 'withinLastPeriod',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: WITHIN_LAST_PERIOD,
    },
    {
        key: 'toDatePeriod',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: TO_DATE_PERIOD,
    },
    {
        key: 'startDateUtc',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_START,
    },
    {
        key: 'endDateUtc',
        type: RANGE,
        rangeKey: DATE_TIME_RANGE,
        rangeType: RANGE_END,
    },
];

/**
 * Fieldset view model.
 */
export default {
    type: FIELDSET,
    key: 'targetProfile',
    fields: buildFlatFormFieldViewModels([
        ...targetProfileFields,
        ...dateTimeRangeFields,
    ]),
};

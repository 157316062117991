import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFNFieldsets } from '../../../core/forms/components/NFieldsets';

import notificationSettingsForm from '../state/forms/notificationSettingsForm';
import {
    submitNotificationSettingsForm,
    closeNotificationSettingsSidePanel,
    notificationSettingsLoadingErrorMessageSelector,
    notificationSettingsIsLoadingSelector,
} from '../state/ui';
import NotificationCategorySettingsFieldset from './NotificationCategorySettingsFieldset';

const context = { name: boxEnum.NOTIFICATION_SETTINGS_SIDE_PANEL };
const strings = componentStrings.notifications.settings.NotificationSettingsSidePanel;

function NotificationSettingsSidePanel({ submitForm, closeForm, loadingErrorMessage, isLoading }) {
    const form = (
        <ReactReduxForm {...notificationSettingsForm}>
            <RRFNFieldsets path="categories" automaticallyIncludeDeleteButton={false}>
                {(fieldsetProps) => <NotificationCategorySettingsFieldset {...fieldsetProps} />}
            </RRFNFieldsets>
        </ReactReduxForm>
    );

    return (
        <SidePanel
            title={strings.title}
            context={context}
            width={475}
            onSave={submitForm}
            onCancel={closeForm}
            disableSave={!!loadingErrorMessage || isLoading}
        >
            {!!loadingErrorMessage ? (
                <InlineBanner status="error">{loadingErrorMessage}</InlineBanner>
            ) : isLoading ? (
                <SimpleLoading />
            ) : (
                form
            )}
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    loadingErrorMessage: notificationSettingsLoadingErrorMessageSelector,
    isLoading: notificationSettingsIsLoadingSelector,
});

const mapDispatchToProps = (dispatch) => ({
    submitForm: () => dispatch(submitNotificationSettingsForm()),
    closeForm: () => dispatch(closeNotificationSettingsSidePanel()),
});

/**
 * Side panel form for notifications settings.
 */
export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsSidePanel);

import { TaskComment } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'taskComments';

const taskCommentsModule = createNormalizedModule<TaskComment>({
    type: NEXUS_STATE_PROP,
});

export const taskCommentsSelector = taskCommentsModule.selectors.entitiesSelector;

export default taskCommentsModule.reducerConfig;

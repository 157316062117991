import React from 'react';
import styled from 'styled-components';
import { Text, cssVar } from 'arc';
import { InlineCommentStatusEnum } from '@mark43/rms-api';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import _Label from '../../../../core/components/typography/Label';
import { InlineReportComment } from '../../utils/inlineReportComments';

const Flex = styled.div`
    display: flex;
`;

const Content = styled(Flex)`
    flex-direction: column;
    max-width: 95%;
    min-width: 95%;
`;

const HighlightedTarget = styled(Text)`
    margin-left: 5px;
    display: inline-block;
    max-width: 100%;
    font-size: ${cssVar('arc.fontSizes.xs')};
`;

const HighlightedTargetWrapper = styled.div`
    border-left: 3px solid ${({ theme }) => theme.colors.brightYellow};
    margin-bottom: 10px;
    overflow-wrap: break-word;
`;

const CommentText = styled(Text)`
    max-width: 100%;
    overflow-wrap: break-word;
    font-size: ${cssVar('arc.fontSizes.xs')};
    display: inline-block;
    color: ${cssVar('arc.colors.text.secondary')};
`;

const Label = styled(_Label)`
    max-height: 18px;
`;

const Wrapper = styled(Flex)`
    margin: 10px 0;
`;

const NoteIcon = styled(Icon)`
    margin-right: 8px;
`;

const InlineCommentsCreatedTime = styled.span`
    font-style: italic;
    color: ${(props) => props.theme.colors.mediumGrey};
`;

const CommentedByWrapper = styled(Flex)`
    justify-content: space-between;
    margin-bottom: 5px;
    height: 25px;
`;

const ReportInlineComment = ({
    inlineComment,
    formattedCreatedDateUtc,
    formattedCreatedByUser,
}: {
    inlineComment: InlineReportComment;
    formattedCreatedDateUtc: string;
    formattedCreatedByUser: string;
    reportId: number;
}) => {
    const { highlightTarget, comment, status } = inlineComment;
    const isResolved = status === InlineCommentStatusEnum.RESOLVED.name;
    return (
        <Wrapper>
            <NoteIcon type={iconTypes.NOTE} />
            <Content>
                <CommentedByWrapper>
                    <div>
                        {formattedCreatedByUser}{' '}
                        <InlineCommentsCreatedTime>
                            {formattedCreatedDateUtc}
                        </InlineCommentsCreatedTime>
                    </div>
                </CommentedByWrapper>
                <HighlightedTargetWrapper>
                    <HighlightedTarget as="span" color="secondary">
                        {highlightTarget}
                    </HighlightedTarget>
                </HighlightedTargetWrapper>
                <div>
                    <CommentText>{comment}</CommentText>
                    {isResolved && (
                        <Label color="darkGreen" borderColor="darkGreen">
                            {status.toLowerCase()}
                        </Label>
                    )}
                </div>
            </Content>
        </Wrapper>
    );
};

export default ReportInlineComment;

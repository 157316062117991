import { CourtCase } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getCourtCasesResource from '../../resources/courtCasesResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'courtCases';

const courtCasesModule = createNormalizedModule<CourtCase>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

const UPDATE_COURT_CASES_START = 'court-cases/COURT_CASES';
const UPDATE_COURT_CASES_SUCCESS = 'court-cases/COURT_CASES';
const UPDATE_COURT_CASES_FAILURE = 'court-cases/COURT_CASES';

function updateCourtCasesStart() {
    return { type: UPDATE_COURT_CASES_START };
}

function updateCourtCasesSuccess() {
    return { type: UPDATE_COURT_CASES_SUCCESS };
}

function updateCourtCasesFailure(errorMessage: string) {
    return { type: UPDATE_COURT_CASES_FAILURE, payload: errorMessage };
}

const replaceCourtCasesWhere = courtCasesModule.actionCreators.replaceEntitiesWhere;

export function updateCourtCases(
    reportId: number,
    courtCases: CourtCase[]
): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        const resource = getCourtCasesResource();
        dispatch(updateCourtCasesStart());
        return resource
            .setCourtCasesForReport(reportId, courtCases)
            .then((updatedCourtCases: CourtCase[]) => {
                dispatch(replaceCourtCasesWhere({ reportId }, updatedCourtCases));
                dispatch(updateCourtCasesSuccess());
            })
            .catch((err: Error) => {
                dispatch(updateCourtCasesFailure(err.message));
                throw err;
            });
    };
}

export const courtCasesWhereSelector = courtCasesModule.selectors.entitiesWhereSelector;

export const courtCasesByReportIdSelector = createSelector(
    courtCasesWhereSelector,
    (courtCasesWhere) => (reportId: number) => courtCasesWhere({ reportId })
);

export default courtCasesModule.reducerConfig;

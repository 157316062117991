import React from 'react';
import { renderOnlyIf } from '../../../../legacy-redux/helpers/reactHelpers';

import CaseSection from '../../core/components/CaseSection';

function CaseLinkedProfilesBlock({ title, children = [] }) {
    return (
        <CaseSection className="case-linked-profiles-block" title={title}>
            {children}
        </CaseSection>
    );
}

export default renderOnlyIf(({ children = [] }) => children.length)(CaseLinkedProfilesBlock);

import {
    RefContextEnum,
    RefContextEnumType,
    EntityTypeEnumType,
    EntityTypeEnum,
} from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { isArrestReportSelector } from '~/client-common/core/domain/arrests/state/ui';
import { useFormGetter } from '../../../core/forms/hooks/useFormGetter';
import { currentReportIdSelector } from '../../../../legacy-redux/selectors/reportSelectors';

type contextToMapT = {
    [key in EntityTypeEnumType]?: { formContext: RefContextEnumType; datePath: string };
};

const useReferenceDate = (contextType: EntityTypeEnumType, contextId: number) => {
    const { getForm } = useFormGetter();
    const defaultEventInfoForm = getForm(RefContextEnum.FORM_EVENT_INFO.name);
    const defaultDatePath = 'eventStartUtc';
    const reportId = useSelector(currentReportIdSelector);
    const isArrestReport = useSelector(isArrestReportSelector);

    const contextTypeToFormObject: contextToMapT = {
        [EntityTypeEnum.OFFENSE.name]: {
            formContext: RefContextEnum.FORM_OFFENSE.name,
            datePath: 'offense.offenseDateUtc',
        },
        [EntityTypeEnum.CITATION.name]: {
            formContext: RefContextEnum.FORM_CITATION.name,
            datePath: 'issuedDateUtc',
        },
        [EntityTypeEnum.ARREST.name]: {
            // note that Arrest reports actually have the REPORT contextType
            formContext: RefContextEnum.FORM_ARREST.name,
            datePath: 'arrest.arrestDateUtc',
        },
        [EntityTypeEnum.REPORT.name]: {
            formContext: RefContextEnum.FORM_EVENT_INFO.name,
            datePath: 'eventStartUtc',
        },
    };

    const formObj = isArrestReport(reportId)
        ? contextTypeToFormObject[EntityTypeEnum.ARREST.name]
        : contextTypeToFormObject[contextType];
    const form =
        contextType === EntityTypeEnum.OFFENSE.name
            ? getForm(formObj?.formContext || '', contextId)
            : getForm(formObj?.formContext || '') || defaultEventInfoForm;

    // if date is undefined in specific form, default to using event start date
    const defaultDate = defaultEventInfoForm?.get(defaultDatePath);
    const datePath = formObj?.datePath;
    return !!datePath ? form?.get(datePath) ?? defaultDate : defaultDate;
};

export default useReferenceDate;

import { keyBy } from 'lodash';
import { FieldDetail } from '@mark43/rms-api';
import { createSelector } from 'reselect';

// helpers
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'fieldDetails';

const fieldDetailsModule = createNormalizedModule<FieldDetail>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

// SELECTORS
export const fieldDetailsSelector = fieldDetailsModule.selectors.entitiesSelector;
export const fieldDetailByIdSelector = fieldDetailsModule.selectors.entityByIdSelector;

/**
 * Indexes `FieldDetail`s by `fieldName`.
 */
export const fieldDetailsByFieldNameSelector = createSelector(
    fieldDetailsSelector,
    (fieldDetails) => keyBy(fieldDetails, 'fieldName')
);

// REDUCER
export default fieldDetailsModule.reducerConfig;

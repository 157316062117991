import { CodeTypeSourceEnum, CodeTypeCategoryEnum } from '@mark43/rms-api';
import React from 'react';

import {
    showDomesticViolenceOnUcrOffense,
    showRelationshipCodeId,
    showHomicideFields,
    showArsonLocation,
    showNegligentManslaughter,
} from '../utils/ucrClassificationHelper';

import Row from '../../../core/components/Row';
import { RRFCodeSelect } from '../../../core/forms/components/selects/CodeSelect';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';

export const FloridaUcrOffenseFieldset = ({ ucrRuleBundle }) => {
    return (
        <>
            {showDomesticViolenceOnUcrOffense(ucrRuleBundle) && (
                <Row>
                    <RRFBooleanSelect path="isDomesticViolence" length="md" />
                </Row>
            )}
            {showRelationshipCodeId(ucrRuleBundle) && (
                <Row>
                    <RRFCodeSelect
                        codeSource={CodeTypeSourceEnum.FL_UCR.name}
                        codeTypeCategory={CodeTypeCategoryEnum.UCR_RELATIONSHIP.name}
                        path="relationshipCodeId"
                        length="lg"
                    />
                </Row>
            )}
            {showHomicideFields(ucrRuleBundle) && (
                <>
                    <Row>
                        <RRFCodeSelect
                            codeSource={CodeTypeSourceEnum.FL_UCR.name}
                            codeTypeCategory={CodeTypeCategoryEnum.UCR_HOMICIDE_CIRCUMSTANCE.name}
                            path="homicideCodeId"
                            length="lg"
                        />
                    </Row>
                    <Row>
                        <RRFCodeSelect
                            codeSource={CodeTypeSourceEnum.FL_UCR.name}
                            codeTypeCategory={
                                CodeTypeCategoryEnum.UCR_HOMICIDE_WEAPON_OR_FORCE.name
                            }
                            path="homicideWeaponOrForceInvolvedCodeId"
                            length="lg"
                        />
                    </Row>
                </>
            )}
            {showNegligentManslaughter(ucrRuleBundle) && (
                <Row>
                    <RRFCodeSelect
                        codeSource={CodeTypeSourceEnum.FL_UCR.name}
                        codeTypeCategory={
                            CodeTypeCategoryEnum.UCR_NEGLIGENT_MANSLAUGHTER_CIRCUMSTANCE.name
                        }
                        path="negligentManslaughterCodeId"
                        length="lg"
                    />
                </Row>
            )}
            {showArsonLocation(ucrRuleBundle) && (
                <Row>
                    <RRFCodeSelect
                        codeSource={CodeTypeSourceEnum.FL_UCR.name}
                        codeTypeCategory={CodeTypeCategoryEnum.UCR_ARSON_LOCATION_TYPE.name}
                        path="arsonLocationCodeId"
                        length="lg"
                    />
                </Row>
            )}
        </>
    );
};

import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import errorCodeEnum from '~/client-common/core/enums/client/errorCodeEnum';
import { logError } from '../../../../core/logging';
import insightsResource from '../../dashboard/resources/insightsResource';
import { RmsDispatch } from '../../../../core/typings/redux';
import redirectToErrorPage from '../../../core/utils/redirectToErrorPage';

interface State {
    goodDataIframeLoaded: boolean;
    goodDataEventSubscriptions: { [eventType: string]: ((eventData: GoodDataEventData) => void)[] };
    goodDataEmbedPath: string;
}

interface Action {
    type:
        | 'SET_GOODDATA_IFRAME_LOADED'
        | 'ADD_GOODDATA_EVENT_CALLBACK'
        | 'REMOVE_GOODDATA_EVENT_CALLBACK'
        | 'SET_GOODDATA_DASHBOARD_ID'
        | 'SET_GOODDATA_NEW_DASHBOARD_PATH';
    loaded?: boolean;
    eventName?: string;
    callback?: (eventData: GoodDataEventData) => void;
    dashboardId?: string;
}

interface GoodDataEventData {
    gdc?: {
        event: {
            name: string;
        };
    };
}

const GOODDATA_EMBED_PARAMETERS = '?apiTokenAuthentication=true&showNavigation=false';

export const InsightsContext = createContext<{
    goodDataEmbedPath: string;
    goodDataEmbedBaseUrl: string;
    goodDataEmbedParameters: string;
    setGoodDataIframeLoaded: (loaded: boolean) => void;
    addGoodDataEventCallback: (
        eventType: string,
        callback: (eventData: GoodDataEventData) => void
    ) => void;
    removeGoodDataEventCallback: (
        eventType: string,
        callback: (eventData: GoodDataEventData) => void
    ) => void;
    setGoodDataDashboardId: (dashboardId: string) => void;
    setGoodDataNewDashboardPath: () => void;
}>({
    goodDataEmbedPath: '/dashboard',
    goodDataEmbedBaseUrl: '',
    goodDataEmbedParameters: GOODDATA_EMBED_PARAMETERS,
    setGoodDataIframeLoaded: () => {},
    addGoodDataEventCallback: () => {},
    removeGoodDataEventCallback: () => {},
    setGoodDataDashboardId: () => {},
    setGoodDataNewDashboardPath: () => {},
});

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_GOODDATA_IFRAME_LOADED':
            return { ...state, goodDataIframeLoaded: action.loaded ?? false };
        case 'ADD_GOODDATA_EVENT_CALLBACK':
            if (!action.eventName || !action.callback) {
                return state;
            }
            return {
                ...state,
                goodDataEventSubscriptions: {
                    ...state.goodDataEventSubscriptions,
                    [action.eventName]: [
                        ...(state.goodDataEventSubscriptions[action.eventName] || []),
                        action.callback,
                    ],
                },
            };
        case 'REMOVE_GOODDATA_EVENT_CALLBACK':
            if (!action.eventName || !action.callback) {
                return state;
            }
            return {
                ...state,
                goodDataEventSubscriptions: {
                    ...state.goodDataEventSubscriptions,
                    [action.eventName]: state.goodDataEventSubscriptions[action.eventName].filter(
                        (cb) => cb !== action.callback
                    ),
                },
            };
        case 'SET_GOODDATA_DASHBOARD_ID':
            return {
                ...state,
                goodDataEmbedPath: action.dashboardId
                    ? `/dashboard/${action.dashboardId}`
                    : '/dashboard',
            };
        case 'SET_GOODDATA_NEW_DASHBOARD_PATH':
            return {
                ...state,
                goodDataEmbedPath: '/new-dashboard',
            };
        default:
            return state;
    }
}

const setGoodDataIframeLoaded = (loaded: boolean): Action => ({
    type: 'SET_GOODDATA_IFRAME_LOADED',
    loaded,
});

const addGoodDataEventCallback = (
    eventName: string,
    callback: (eventData: GoodDataEventData) => void
): Action => ({
    type: 'ADD_GOODDATA_EVENT_CALLBACK',
    eventName,
    callback,
});

const removeGoodDataEventCallback = (
    eventName: string,
    callback: (eventData: GoodDataEventData) => void
): Action => ({
    type: 'REMOVE_GOODDATA_EVENT_CALLBACK',
    eventName,
    callback,
});

const setGoodDataDashboardId = (dashboardId: string): Action => ({
    type: 'SET_GOODDATA_DASHBOARD_ID',
    dashboardId,
});

const setGoodDataNewDashboardPath = (): Action => ({
    type: 'SET_GOODDATA_NEW_DASHBOARD_PATH',
});

function useActions(dispatch: React.Dispatch<Action>) {
    const handleSetGoodDataIframeLoaded = useCallback(
        (loaded: boolean) => {
            dispatch(setGoodDataIframeLoaded(loaded));
        },
        [dispatch]
    );

    const handleAddGoodDataEventCallback = useCallback(
        (eventType: string, callback: (eventData: GoodDataEventData) => void) => {
            dispatch(addGoodDataEventCallback(eventType, callback));
        },
        [dispatch]
    );

    const handleRemoveGoodDataEventCallback = useCallback(
        (eventType: string, callback: (eventData: GoodDataEventData) => void) => {
            dispatch(removeGoodDataEventCallback(eventType, callback));
        },
        [dispatch]
    );

    const handleSetGoodDataDashboardId = useCallback(
        (dashboardId: string) => {
            dispatch(setGoodDataDashboardId(dashboardId));
        },
        [dispatch]
    );

    const handleSetGoodDataNewDashboardPath = useCallback(() => {
        dispatch(setGoodDataNewDashboardPath());
    }, [dispatch]);

    return {
        setGoodDataIframeLoaded: handleSetGoodDataIframeLoaded,
        addGoodDataEventCallback: handleAddGoodDataEventCallback,
        removeGoodDataEventCallback: handleRemoveGoodDataEventCallback,
        setGoodDataDashboardId: handleSetGoodDataDashboardId,
        setGoodDataNewDashboardPath: handleSetGoodDataNewDashboardPath,
    };
}

export const InsightsContextProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, {
        goodDataEmbedPath: '/dashboard',
        goodDataIframeLoaded: false,
        goodDataEventSubscriptions: {},
    });
    const actions = useActions(dispatch);
    const rmsDispatch = useDispatch<RmsDispatch>();

    const [goodDataEmbedBaseUrl, setGoodDataEmbedBaseUrl] = useState<string>('');

    useEffect(() => {
        insightsResource
            .getBaseEmbedUrl()
            .then((embedUrl) => {
                setGoodDataEmbedBaseUrl(embedUrl);
            })
            .catch((error) => {
                logError('Gooddata embed base url failed to load', error);
                rmsDispatch(redirectToErrorPage({ errorCode: errorCodeEnum.NOT_FOUND }));
            });
    }, [rmsDispatch]);

    useEffect(() => {
        if (!state.goodDataIframeLoaded) {
            return;
        }

        const handleMessage = (event: MessageEvent) => {
            const goodDataIframe = document.getElementsByClassName(
                'insights-dashboard-frame'
            )[0] as HTMLIFrameElement;

            if (goodDataIframe?.contentWindow && event.source === goodDataIframe.contentWindow) {
                let goodDataEventData: GoodDataEventData;
                try {
                    goodDataEventData = JSON.parse(event.data);
                } catch (error) {
                    logError(`Failed to parse gooddata event data: ${error}`);
                    return;
                }
                if (goodDataEventData.gdc) {
                    state.goodDataEventSubscriptions[goodDataEventData.gdc.event.name]?.forEach(
                        (callback) => callback(goodDataEventData)
                    );
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [state.goodDataIframeLoaded, state.goodDataEventSubscriptions, actions]);

    const contextValue = useMemo(
        () => ({
            goodDataEmbedBaseUrl,
            goodDataEmbedPath: state.goodDataEmbedPath,
            goodDataEmbedParameters: GOODDATA_EMBED_PARAMETERS,
            ...actions,
        }),
        [state.goodDataEmbedPath, goodDataEmbedBaseUrl, actions]
    );

    return <InsightsContext.Provider value={contextValue}>{children}</InsightsContext.Provider>;
};

import React, { useMemo } from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';

import { OmitFromUnion, VehicleCustomCautionsData } from '../configuration';
import { prepareVehicleCustomCautions } from '../helpers/customCautions';
import { EnhancedCautions, EnhancedCautionsProps } from './EnhancedCautions';

type VehicleEnhancedCautionsProps = OmitFromUnion<
    EnhancedCautionsProps,
    'customCautions' | 'entityType'
> &
    VehicleCustomCautionsData;

export const VehicleEnhancedCautions: React.FC<VehicleEnhancedCautionsProps> = ({
    isActiveTarget,
    isStolen,
    ...enhancedCautionsProps
}) => {
    const customCautions = useMemo(
        () =>
            prepareVehicleCustomCautions({
                isActiveTarget,
                isStolen,
            }),
        [isActiveTarget, isStolen]
    );

    return (
        <EnhancedCautions
            {...enhancedCautionsProps}
            entityType={EntityTypeEnum.VEHICLE.name}
            customCautions={customCautions}
        />
    );
};

import { some } from 'lodash';
import React from 'react';
import ApprovalStatusIcon from '../../../../../modules/records/core/components/ApprovalStatusIcon';

export default function StatCell(elasticReport) {
    const { isPartiallySealed, reportDefinition, clientApprovalStatus } = elasticReport;
    const { arrest, charges } = reportDefinition;

    const isVacated = arrest && charges ? some(charges, 'isVacated') : false;

    // user with only `Can Find` will not be able to see approval status,
    // so fallback to just showing nothing in cell.
    return clientApprovalStatus ? (
        <ApprovalStatusIcon
            approvalStatus={clientApprovalStatus}
            isPartiallySealed={isPartiallySealed}
            isVacated={isVacated}
        />
    ) : (
        <div />
    );
}

import React from 'react';
import styled from 'styled-components';
import { Text, cssVar } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';

const strings = componentStrings.persons.DeceasedLabel;

const LabelText = styled(Text)`
    font-size: ${cssVar('arc.fontSizes.xs')};
    line-height: ${cssVar('arc.lineHeights.shorter')};
    color: inherit;
`;
const LargeLabelText = styled(LabelText)`
    font-size: ${cssVar('arc.fontSizes.sm')};
`;
// Generic deceased label
const DeceasedLabelWrapper = styled.div`
    padding: 2px 0;
    background-color: ${cssVar('arc.colors.raw.blackAlpha.700')};
    text-transform: uppercase;
    color: ${cssVar('arc.colors.raw.white')};
    text-align: center;
`;

const BottomPositionedDeceasedLabelWrapper = styled(DeceasedLabelWrapper)`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
`;

export const BottomPositionedDeceasedLabel = ({ isLarge }) => {
    const Font = isLarge ? LargeLabelText : LabelText;

    return (
        <BottomPositionedDeceasedLabelWrapper>
            <Font>{strings.deceased}</Font>
        </BottomPositionedDeceasedLabelWrapper>
    );
};

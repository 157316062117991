import _ from 'lodash';
import pickBy from 'lodash.pickby';
import { createSelector } from 'reselect';
import moment from 'moment';

import { cadServiceTypesSelector } from '~/client-common/core/domain/cad-service-rotation-types/state/data';

import {
    getAttributeByIdSelector,
    formatAttributeByIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import {
    processHistoryEvent,
    processChangeSet,
} from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import {
    shapefileByIdSelector,
    loadShapefilesProperties,
} from '~/client-common/core/domain/shapefiles/state/data';
import { currentDepartmentDateFormatsSelector } from '~/client-common/core/domain/current-user/state/ui';
import { cadRotationListSelector } from '~/client-common/core/domain/cad-service-rotation-lists/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import cadRotationListsAdminForm, { validate } from '../forms/cadRotationListsAdminForm';
import cadRotationListEntryForm from '../forms/cadRotationListEntryForm';
import {
    saveCadRotationList,
    saveCadRotationListEntry,
    SAVE_CAD_ROTATION_LISTS_SUCCESS,
    SAVE_CAD_ROTATION_LIST_ENTRY_SUCCESS,
    LOAD_CAD_ROTATION_LISTS_START,
    LOAD_CAD_SERVICE_TYPES_START,
    LOAD_CAD_ROTATION_LISTS_SUCCESS,
    LOAD_CAD_SERVICE_TYPES_SUCCESS,
    LOAD_CAD_ROTATION_LISTS_FAILURE,
    LOAD_CAD_ROTATION_LIST_HISTORY_SUCCESS,
    LOAD_CAD_ROTATION_LIST_HISTORY_START,
    LOAD_CAD_SERVICE_PROVIDERSS_SUCCESS,
    SET_INITIAL_STATE,
    SAVE_CAD_ROTATION_LISTS_FAILURE,
} from '../data';
import { formatUserByIdSelector } from '../../../../../legacy-redux/selectors/userSelectors';
import { routeNameSelector } from '../../../../../routing/routerModule';
import { closeConfirmationModal } from '../../../../../legacy-redux/actions/boxActions';

const NEW_ROTATION_LIST = 'NEW ROTATION-LIST';

const SELECT_SERVICE_TYPE = 'cad-service-rotation-lists/SELECT_SERVICE_TYPE';
const SELECT_ROTATION_LIST = 'cad-service-rotation-lists/SELECT_ROTATION_LIST';
const SELECT_ROTATION_LIST_COMPLETE = 'cad-service-rotation-lists/SELECT_ROTATION_LIST_COMPLETE';
const DELETE_ROTATION_LIST_ENTRY_START =
    'cad-service-rotation-lists/DELETE_ROTATION_LIST_ENTRY_START';
const DELETE_ROTATION_LIST_ENTRY_SUCCESS =
    'cad-service-rotation-lists/DELETE_ROTATION_LIST_ENTRY_SUCCESS';
const EDIT_ROTATION_LIST_ENTRY_START = 'cad-service-rotation-lists/EDIT_ROTATION_LIST_ENTRY_START';
const EDIT_ROTATION_LIST_ENTRY_SUCCESS =
    'cad-service-rotation-lists/EDIT_ROTATION_LIST_ENTRY_SUCCESS';
const CANCEL_EDIT_ROTATION_LIST_ENTRY_SUCCESS =
    'cad-service-rotation-lists/CANCEL_EDIT_ROTATION_LIST_ENTRY_SUCCESS';
const ADD_NEW_ROTATION_LIST_ENTRY_SUCCESS =
    'cad-service-rotation-lists/ADD_NEW_ROTATION_LIST_ENTRY_SUCCESS';
const ADD_NEW_LOCATION = 'cad-service-rotation-lists/ADD_NEW_LOCATION';
const CREATE_NEW_ROTATION_LIST_START = 'cad-service-rotation-lists/CREATE_NEW_ROTATION_LIST_START';
const CLEAR_CAD_ROTATION_LIST_HISTORY =
    'cad-service-rotation-lists/CLEAR_CAD_ROTATION_LIST_HISTORY';
const SET_ROTATION_LIST_ENTRY_POSITION_SUCCESS =
    'cad-service-rotation-lists/SET_ROATION_LIST_ENTRY_POSITION_SUCCESS';

export function clearCadRotationListHistory() {
    return {
        type: CLEAR_CAD_ROTATION_LIST_HISTORY,
    };
}

function createNewCadRotationListStartRaw() {
    return {
        type: CREATE_NEW_ROTATION_LIST_START,
    };
}

export function createNewCadRotationListStart(router) {
    return (dispatch, getState) => {
        const { selectedCadServiceType } = uiSelector(getState());
        dispatch(cadRotationListsAdminForm.actionCreators.reset());
        dispatch(cadRotationListEntryForm.actionCreators.reset());
        dispatch(createNewCadRotationListStartRaw());
        if (router) {
            const path = `/admin/cad/cad-service-rotation-lists/${selectedCadServiceType.id}/cad-rotation-list/${NEW_ROTATION_LIST}`;
            router.push(path);
        }
    };
}

export function selectServiceType(id) {
    return (dispatch, getState) => {
        dispatch(selectServiceTypeStart(id));
        const state = getState();
        const { selectedCadServiceType } = uiSelector(state);

        if (selectedCadServiceType) {
            return dispatch(loadShapefilesProperties(selectedCadServiceType.shapefileId));
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const { selectedCadServiceType } = uiSelector(state);

                if (selectedCadServiceType) {
                    window.clearInterval(intervalId);

                    return dispatch(loadShapefilesProperties(selectedCadServiceType.shapefileId));
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    return dispatch();
                    // selectCadRotationsListTypeFailure('Failed to get cad service type.')
                }
            }, 1000);
        }
    };
}

function selectServiceTypeStart(cadServiceTypeId) {
    return {
        type: SELECT_SERVICE_TYPE,
        payload: cadServiceTypeId,
    };
}

export function selectRotationList(cadRotationListId) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(selectRotationListStart(cadRotationListId));
        const { selectedCadRotationList } = uiSelector(state);

        if (
            selectedCadRotationList &&
            selectedCadRotationList.id === cadRotationListId &&
            selectedCadRotationList !== NEW_ROTATION_LIST
        ) {
            dispatch(cadRotationListsAdminForm.actionCreators.change(selectedCadRotationList));
            dispatch(selectRotationListComplete());
        } else {
            // TODO: remove this crappy interval when migrating the router, this is
            // a workaround for onEnter/onLeave hooks not respecting parent/child relationships
            let attempts = 0;

            const intervalId = window.setInterval(() => {
                const state = getState();
                const { selectedCadRotationList } = uiSelector(state);

                if (
                    selectedCadRotationList &&
                    selectedCadRotationList.id === cadRotationListId &&
                    selectedCadRotationList !== NEW_ROTATION_LIST
                ) {
                    window.clearInterval(intervalId);

                    dispatch(selectRotationListComplete());
                    dispatch(
                        cadRotationListsAdminForm.actionCreators.change(selectedCadRotationList)
                    );
                }

                attempts++;
                if (attempts > 300) {
                    window.clearInterval(intervalId);

                    // return dispatch(selectCadServiceTypeFailure('Failed to get cad service type.'));
                }
            }, 1000);
        }
    };
}

function selectRotationListStart(cadRotationListId) {
    return {
        type: SELECT_ROTATION_LIST,
        payload: cadRotationListId,
    };
}

function selectRotationListComplete() {
    return {
        type: SELECT_ROTATION_LIST_COMPLETE,
    };
}

export function addNewLocation(location) {
    const locationId = location.id;
    return (dispatch) => {
        dispatch(
            cadRotationListEntryForm.actionCreators.changePath(
                'cadRotationListEntity.rotationServiceLocationId',
                locationId
            )
        );
        dispatch(addNewLocationSuccess(location));
    };
}

function addNewLocationSuccess(location) {
    return {
        type: ADD_NEW_LOCATION,
        payload: location,
    };
}

export function editCadServiceRotationEntry(entryId) {
    return (dispatch, getState) => {
        dispatch(editCadServiceRotationEntryStart());
        const state = getState();
        const ui = uiSelector(state);
        const { selectedCadRotationList } = ui;

        const cadRotationListEntry = selectedCadRotationList.entries.find((item) => {
            return item.id === entryId;
        });

        dispatch(
            cadRotationListEntryForm.actionCreators.change({
                id: cadRotationListEntry.id,
                rotationServiceId: cadRotationListEntry.rotationServiceId,
                rotationListId: cadRotationListEntry.rotationListId,
                startDateUtc: cadRotationListEntry.startDateUtc,
                position: cadRotationListEntry.position,
                defaultPosition: cadRotationListEntry.defaultPosition,
                locationId: cadRotationListEntry.locationId,
                expirationDateUtc: cadRotationListEntry.expirationDateUtc,
            })
        );

        if (cadRotationListEntry) {
            dispatch(editCadServiceRotationEntrySuccess(entryId));
        }
    };
}

export function deleteCadServiceRotationListEntry(entryId) {
    return (dispatch, getState) => {
        dispatch(deleteCadServiceRotationListEntryStart());
        const state = getState();
        const ui = uiSelector(state);
        const { selectedCadRotationList } = ui;

        const entryIndex = selectedCadRotationList.entries.findIndex((item) => {
            return item.id === entryId;
        });

        if (entryIndex !== -1) {
            dispatch(
                cadRotationListsAdminForm.actionCreators.remove(
                    'cadRotationList.entries',
                    entryIndex
                )
            );

            dispatch(deleteCadServiceRotationListEntrySuccess(entryId));
        }
    };
}

export function setCadServiceRotationListEntryPosition(entryId, newIndex) {
    return (dispatch, getState) => {
        const state = getState();
        const ui = uiSelector(state);
        const { selectedCadRotationList } = ui;

        const entry = selectedCadRotationList.entries.find((item) => {
            return item.id === entryId;
        });

        if (!entry) {
            return;
        }

        let indexToUse = newIndex;

        if (newIndex < 0) {
            indexToUse = selectedCadRotationList.entries.length - 1;
        }

        if (newIndex >= selectedCadRotationList.entries.length) {
            indexToUse = 0;
        }

        const filteredEntries = selectedCadRotationList.entries.filter((e) => e.id !== entryId);
        const firstHalf = filteredEntries.slice(0, indexToUse);
        const secondHalf = filteredEntries.slice(indexToUse);
        const newEntryList = [...firstHalf, entry, ...secondHalf];
        const newModel = { ...selectedCadRotationList, entries: newEntryList };

        dispatch(cadRotationListsAdminForm.actionCreators.change(newModel));
        dispatch(setCadServiceRotationListEntryPositionSuccess(newEntryList));
    };
}

function setCadServiceRotationListEntryPositionSuccess(newEntryList) {
    return {
        type: SET_ROTATION_LIST_ENTRY_POSITION_SUCCESS,
        payload: newEntryList,
    };
}

function deleteCadServiceRotationListEntryStart() {
    return {
        type: DELETE_ROTATION_LIST_ENTRY_START,
    };
}

function deleteCadServiceRotationListEntrySuccess(entryId) {
    return {
        type: DELETE_ROTATION_LIST_ENTRY_SUCCESS,
        payload: entryId,
    };
}

function editCadServiceRotationEntryStart() {
    return {
        type: EDIT_ROTATION_LIST_ENTRY_START,
    };
}

function editCadServiceRotationEntrySuccess(entryId) {
    return {
        type: EDIT_ROTATION_LIST_ENTRY_SUCCESS,
        payload: entryId,
    };
}

export function cancelEditRotationList() {
    return (dispatch, getState) => {
        const ui = uiSelector(getState());
        const selectedCadRotationListId = ui.selectedCadRotationListId;
        const originalList = cadRotationListSelector(getState())[selectedCadRotationListId];
        dispatch(cadRotationListsAdminForm.actionCreators.change(originalList));
        dispatch(cancelEditEntrySuccess(originalList));
    };
}

function cancelEditEntrySuccess(newRotationList) {
    return {
        type: CANCEL_EDIT_ROTATION_LIST_ENTRY_SUCCESS,
        payload: newRotationList,
    };
}

export function addNewCadRotationListEntry(cadRotationListCategoryAttrId) {
    return (dispatch, getState) => {
        const ui = uiSelector(getState());
        const selectedCadRotationListId = ui.selectedCadRotationListId;
        const entries = ui.selectedCadRotationList.entries;
        const max = (entries ? entries.length : 0) + 1;
        const maxPosition =
            Math.max(
                ...entries.map((o) => {
                    return o.position;
                }),
                max
            ) + 1;
        const now = moment().toISOString();

        dispatch(
            cadRotationListEntryForm.actionCreators.change({
                rotationListId: selectedCadRotationListId,
                startDateUtc: now,
                position: maxPosition,
                defaultPosition: max,
            })
        );
        dispatch(addNewCadRotationListEntrySuccess(cadRotationListCategoryAttrId));
    };
}

function addNewCadRotationListEntrySuccess(cadRotationListCategoryAttrId) {
    return {
        type: ADD_NEW_ROTATION_LIST_ENTRY_SUCCESS,
        payload: cadRotationListCategoryAttrId,
    };
}

const initialUiState = {
    selectedCadServiceTypeId: null,
    cadServiceTypes: null,
    cadServiceProviders: null,
    selectedCadRotationListId: null,
    cadRotationLists: null,
    selectedCadServiceType: null,
    selectedCadRotationList: null,
    editingCadRotationListEntryId: null,
    editingCadRotationListEntry: null,
    addingRotationListEntry: null,
    loadingList: null,
    newLocationId: null,
    creatingNewRotationList: false,
    history: null,
};

export default function cadRotationListsAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SELECT_SERVICE_TYPE:
            return {
                ...state,
                selectedCadServiceTypeId: action.payload,
                selectedCadServiceType: state.cadServiceTypes.find(
                    (item) => item.id === action.payload
                ),
                selectedCadRotationList: null,
                selectedCadRotationListId: null,
                creatingNewRotationList: false,
                history: null,
            };
        case SELECT_ROTATION_LIST:
            const output = {
                ...state,
                selectedCadRotationList: state.cadRotationLists.find(
                    (item) => item.id === action.payload
                ),
                selectedCadRotationListId: action.payload,
                creatingNewRotationList: action.payload === NEW_ROTATION_LIST,
            };
            return output;
        case SELECT_ROTATION_LIST_COMPLETE:
            return {
                ...state,
            };
        case EDIT_ROTATION_LIST_ENTRY_SUCCESS:
            return {
                ...state,
                locationId: undefined,
                selectedLocation: undefined,
                editingCadRotationListEntryId: action.payload,
                editingCadRotationListEntry: state.selectedCadRotationList.entries.find(
                    (item) => item.id === action.payload
                ),
                addingRotationListEntry: null,
            };
        case DELETE_ROTATION_LIST_ENTRY_SUCCESS:
            return {
                ...state,
                cadRotationLists: state.cadRotationLists.map((list) => ({
                    ...list,
                    entries: list.entries.filter((entry) => entry.id !== action.payload),
                })),
                selectedCadRotationList: {
                    ...state.selectedCadRotationList,
                    entries: state.selectedCadRotationList.entries.filter(
                        (entry) => entry.id !== action.payload
                    ),
                },
            };
        case ADD_NEW_ROTATION_LIST_ENTRY_SUCCESS:
            return {
                ...state,
                locationId: undefined,
                selectedLocation: undefined,
                editingCadRotationListEntryId: null,
                editingCadRotationListEntry: null,
                addingRotationListEntry: action.payload,
            };
        case SAVE_CAD_ROTATION_LISTS_SUCCESS:
            return {
                ...state,
                submittingForm: false,
                formError: undefined,
                editingCadRotationListEntryId: null,
                editingCadRotationListEntry: null,
                addingRotationListEntry: null,
                selectedCadRotationListId: action.payload.id,
                selectedCadRotationList: action.payload,
                creatingNewRotationList: false,
                cadRotationLists: [
                    ...state.cadRotationLists.filter((item) => item.id !== action.payload.id),
                    action.payload,
                ],
            };
        case SAVE_CAD_ROTATION_LIST_ENTRY_SUCCESS:
            return {
                ...state,
                editingCadRotationListEntryId: null,
                editingCadRotationListEntry: null,
                addingRotationListEntry: null,
                selectedCadRotationList: action.payload.rotationList,
                cadRotationLists: [
                    ...state.cadRotationLists.filter(
                        (item) => item.id !== action.payload.rotationList.id
                    ),
                    action.payload.rotationList,
                ],
                cadServiceProviders: [
                    ...state.cadServiceProviders.filter(
                        (item) => item.id !== action.payload.rotationListEntry.id
                    ),
                    action.payload.rotationListEntry,
                ],
            };
        case CANCEL_EDIT_ROTATION_LIST_ENTRY_SUCCESS:
            return {
                ...state,
                editingCadRotationListEntryId: null,
                editingCadRotationListEntry: null,
                addingRotationListEntry: null,
                cadRotationLists: state.cadRotationLists.map((rl) => {
                    if (rl.id === action.payload.id) {
                        return action.payload;
                    }
                    return rl;
                }),
                selectedCadRotationList: action.payload,
            };
        case SET_ROTATION_LIST_ENTRY_POSITION_SUCCESS:
            return {
                ...state,
                cadRotationLists: state.cadRotationLists.map((list) => ({
                    ...list,
                    entries:
                        list.id === state.selectedCadRotationList ? action.payload : list.entries,
                })),
                selectedCadRotationList: {
                    ...state.selectedCadRotationList,
                    entries: action.payload,
                },
            };
        case LOAD_CAD_ROTATION_LISTS_START:
        case LOAD_CAD_SERVICE_TYPES_START:
            return {
                ...state,
                loadingList: true,
            };
        case LOAD_CAD_ROTATION_LISTS_FAILURE:
            return {
                ...state,
                loadingList: false,
            };
        case LOAD_CAD_SERVICE_PROVIDERSS_SUCCESS:
            return {
                ...state,
                loadingList: false,
                cadServiceProviders: action.payload,
            };
        case LOAD_CAD_SERVICE_TYPES_SUCCESS:
            return {
                ...state,
                loadingList: false,
                cadServiceTypes: action.payload,
            };
        case LOAD_CAD_ROTATION_LISTS_SUCCESS:
            return {
                ...state,
                loadingList: false,
                cadRotationLists: action.payload,
            };
        case ADD_NEW_LOCATION:
            return {
                ...state,
                locationId: action.payload.id,
                selectedLocation: action.payload,
            };
        case CREATE_NEW_ROTATION_LIST_START:
            return {
                ...state,
                submittingForm: false,
                formError: undefined,
                creatingNewRotationList: true,
                selectedCadRotationList: NEW_ROTATION_LIST,
                selectedCadRotationListId: NEW_ROTATION_LIST,
            };
        case LOAD_CAD_ROTATION_LIST_HISTORY_SUCCESS:
            return {
                ...state,
                history: action.payload,
            };
        case CLEAR_CAD_ROTATION_LIST_HISTORY:
        case LOAD_CAD_ROTATION_LIST_HISTORY_START:
            return {
                ...state,
                history: null,
            };
        case SAVE_CAD_ROTATION_LISTS_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SET_INITIAL_STATE:
            return {
                ...initialUiState,
            };

        default:
            return state;
    }
}

const uiSelectorBase = (state) => state.ui.cadRotationListsAdmin;

const uiHistorySelector = createSelector(
    uiSelectorBase,
    formatAttributeByIdSelector,
    formatUserByIdSelector,
    getAttributeByIdSelector,
    currentDepartmentDateFormatsSelector,
    ({ history: histories }, formatAttributeById, userById, getAttributeById, dateTimeFormats) => {
        if (!histories) {
            return null;
        }

        const displayDate = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDate);
            }
            return '';
        };

        const displayDateTime = (date) => {
            if (date) {
                return moment(date).format(dateTimeFormats.summaryDateTime);
            }
            return '';
        };

        const args = {
            formatAttributeById,
            userById,
            displayDate,
            displayDateTime,
            getAttributeById,
        };

        return _.map(_.sortBy(histories, 'timestampUtc'), (history) => {
            const processed = processHistoryEvent(history, args);
            const newObj = {
                ...processed,
                user: userById(processed.changedBy),
                changes: _.map(processed.changeSet, (changeSet) =>
                    processChangeSet(changeSet, args, processed)
                ),
            };

            return newObj;
        }).reverse();
    }
);

export const uiSelector = createSelector(uiSelectorBase, uiHistorySelector, (ui, history) => {
    return {
        ...ui,
        history,
    };
});

const cadServiceTypeViewModelByIdSelector = createSelector(
    cadServiceTypesSelector,
    (cadServiceTypes) => (id) => {
        return cadServiceTypes[id];
    }
);

export const serviceTypesListItemsSelector = createSelector(
    cadServiceTypesSelector,
    uiSelector,
    cadServiceTypeViewModelByIdSelector,
    routeNameSelector,
    (cadServiceTypes, ui) => {
        return _(cadServiceTypes)
            .map((serviceType) => {
                return serviceType
                    ? {
                          path: `/admin/cad/cad-service-rotation-lists/${serviceType.id}`,
                          title: `${serviceType.code.toUpperCase()}-${serviceType.name.toUpperCase()}`,
                          key: serviceType.id,
                          selected: serviceType.id === ui.selectedCadServiceTypeId,
                      }
                    : null;
            })
            .sortBy('title')
            .value();
    }
);

export const rotationListsListItemsSelector = createSelector(uiSelector, (ui) => {
    return ui.selectedCadServiceTypeId
        ? _(
              pickBy(ui.cadRotationLists, (value) => {
                  return value.rotationServiceTypeId === ui.selectedCadServiceTypeId;
              })
          )
              .map((rotationList) => {
                  return rotationList
                      ? {
                            path: `admin/cad/cad-service-rotation-lists/${ui.selectedCadServiceTypeId}/cad-rotation-list/${rotationList.id}`,
                            title: rotationList.name.toUpperCase(),
                            key: rotationList.id,
                            selected: rotationList.id === ui.selectedCadRotationListId,
                        }
                      : null;
              })
              .sortBy('title')
              .value()
        : [];
});

export function submitCadRotationList({
    router,
    isEnhancedServiceRotationEnabled,
    selectedCadRotationList,
    noRotationAreaOptions,
}) {
    return (dispatch, getState) => {
        if (dispatch(validate(uiSelector, isEnhancedServiceRotationEnabled))) {
            return;
        } else {
            dispatch(
                cadRotationListsAdminForm.actionCreators.submit(
                    ({ cadRotationList: formModel }) => {
                        const { selectedCadServiceType } = uiSelector(getState());
                        const cadRotationList = {
                            ...cadRotationListsAdminForm.convertFromFormModel({
                                formModel,
                                isEnhancedServiceRotationEnabled,
                                selectedCadRotationList,
                                noRotationAreaOptions,
                            }),
                            rotationServiceTypeId: selectedCadServiceType.id,
                            departmentId: selectedCadServiceType.departmentId,
                        };
                        dispatch(
                            saveCadRotationList(cadRotationList, isEnhancedServiceRotationEnabled)
                        ).tap((action) => {
                            dispatch(
                                cadRotationListsAdminForm.actionCreators.change(action.payload)
                            );
                            const serviceRotationList = action.payload;
                            const path = `/admin/cad/cad-service-rotation-lists/${serviceRotationList.rotationServiceTypeId}/cad-rotation-list/${serviceRotationList.id}`;
                            router.push(path);
                        });
                    }
                )
            ).then(() => {
                dispatch(closeConfirmationModal());
            });
        }
    };
}

export function submitCadRotationEntry() {
    return (dispatch, getState) => {
        if (dispatch(validate(uiSelector))) {
            return;
        } else {
            dispatch(
                cadRotationListEntryForm.actionCreators.submit((formModel) => {
                    const { selectedCadRotationList, editingCadRotationListEntryId } = uiSelector(
                        getState()
                    );
                    // Get the model for the entry from cadRotationListsAdminEntryForm
                    const newRotationListEntryModel = {
                        ...cadRotationListEntryForm.convertFromFormModel(
                            formModel.cadRotationListEntity
                        ),
                        rotationListId: selectedCadRotationList.id,
                        departmentId: selectedCadRotationList.departmentId,
                        id: !!editingCadRotationListEntryId ? editingCadRotationListEntryId : 0,
                    };

                    dispatch(
                        saveCadRotationListEntry(newRotationListEntryModel, selectedCadRotationList)
                    );

                    const { selectedCadRotationList: updatedSelectedCadRotationList } = uiSelector(
                        getState()
                    );

                    dispatch(
                        cadRotationListsAdminForm.actionCreators.change(
                            updatedSelectedCadRotationList
                        )
                    );
                })
            ).then(() => {
                dispatch(closeConfirmationModal());
            });
        }
    };
}

export function selectOnEnter(serviceTypeId, cadRotationListId) {
    return (dispatch) => {
        if (serviceTypeId) {
            dispatch(selectServiceType(_.parseInt(serviceTypeId)));
        }
        if (cadRotationListId && cadRotationListId !== NEW_ROTATION_LIST) {
            dispatch(selectRotationList(_.parseInt(cadRotationListId)));
        }
        if (cadRotationListId && cadRotationListId === NEW_ROTATION_LIST) {
            dispatch(createNewCadRotationListStart());
        }
    };
}

export const rotationAreaOptionsSelector = createSelector(
    uiSelector,
    shapefileByIdSelector,
    (ui, shapefileById) => {
        const serviceType = ui.selectedCadServiceType;
        const shapefileId = serviceType ? serviceType.shapefileId : undefined;
        if (shapefileId) {
            const shapefile = shapefileById(shapefileId);
            if (shapefile) {
                const shapefilePropertyValueOptions = _.uniq(
                    _.map(shapefile.properties, serviceType.propertyName)
                );

                const output = _.map(shapefilePropertyValueOptions, (propertyValue) => {
                    return {
                        display: propertyValue,
                        value: String(propertyValue),
                    };
                });

                return output;
            }
        }

        return [];
    }
);

export const cadServiceProviderOptionsSelector = createSelector(
    uiSelector,
    applicationSettingsSelector,
    (ui, { ENHANCED_SERVICE_ROTATION: isEnhancedServiceRotationEnabled }) =>
        _.chain(ui.cadServiceProviders)
            .filter((item) => {
                if (!isEnhancedServiceRotationEnabled) {
                    return item.rotationServiceTypeId === ui.selectedCadServiceTypeId;
                }

                const rotationServiceTypesIds =
                    (item.rotationServiceTypes &&
                        item.rotationServiceTypes.map((serviceType) => serviceType.id)) ||
                    [];

                return rotationServiceTypesIds.includes(ui.selectedCadServiceTypeId);
            })
            .sortBy('name')
            .map((provider) => {
                return {
                    display: provider.name,
                    value: provider.id,
                    provider,
                };
            })
            .value()
);

export const cadServiceProviderByIdSelector = createSelector(
    uiSelector,
    (ui) => (id, entry, entries) => {
        const output = ui.cadServiceProviders
            ? ui.cadServiceProviders.find((item) => item.id === id)
            : null;

        return output && ui.cadServiceProviders
            ? {
                  ...output,
                  isMax:
                      entries.reduce(
                          (max, p) => (p.defaultPosition > max ? p.defaultPosition : max),
                          entry.defaultPosition
                      ) === entry.defaultPosition,
                  isMin:
                      entries.reduce(
                          (min, p) => (p.defaultPosition < min ? p.defaultPosition : min),
                          entry.defaultPosition
                      ) === entry.defaultPosition,
              }
            : output;
    }
);

export const cadServiceRotationEntryByIdSelector = createSelector(uiSelector, (ui) => {
    return ui.selectedCadRotationList && ui.selectedCadRotationList.entries
        ? ui.selectedCadRotationList.entries.find((item) => {
              return item.id === ui.editingCadRotationListEntryId;
          })
        : [];
});

import { SummaryNarrative } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { first } from 'lodash';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'summaryNarratives';
const summaryNarrativeModule = createNormalizedModule<SummaryNarrative>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

const summaryNarrativeWhereSelector = summaryNarrativeModule.selectors.entitiesWhereSelector;

export const summaryNarrativeByReportIdSelector = createSelector(
    summaryNarrativeWhereSelector,
    (summaryNarrativeWhere) => (reportId: number) => first(summaryNarrativeWhere({ reportId }))
);

// REDUCER
export default summaryNarrativeModule.reducerConfig;

import { createSelector } from 'reselect';
import { groupBy } from 'lodash';
import { ConsortiumLinkView } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'consortiumDepartmentLinks';

const consortiumDepartmentLinksModule = createLinkModule<ConsortiumLinkView>({
    type: NEXUS_STATE_PROP,
    keys: ['consortiumId', 'deptId'],
});

// ACTIONS
export const storeConsortiumDepartmentLinks =
    consortiumDepartmentLinksModule.actionCreators.storeLinks;

// SELECTORS
export const consortiumDepartmentLinksSelector =
    consortiumDepartmentLinksModule.selectors.linksSelector;
export const consortiumDepartmentLinksWhereSelector =
    consortiumDepartmentLinksModule.selectors.linksWhereSelector;

export const consortiumDepartmentLinksByDepartmentIdSelector = createSelector(
    consortiumDepartmentLinksSelector,
    (consortiumDepartmentLinks) => groupBy(consortiumDepartmentLinks, 'deptId')
);

// REDUCER
export default consortiumDepartmentLinksModule.reducerConfig;

const reportCardEnum = {
    EVENT_INFO: {
        name: 'EVENT_INFO',
        id: 1,
    },
    ARREST: {
        name: 'ARREST',
        id: 2,
    },
    RELATIONSHIPS: {
        name: 'RELATIONSHIPS',
        id: 3,
    },
    NARRATIVE: {
        name: 'NARRATIVE',
        id: 4,
    },
    ATTACHMENTS: {
        name: 'ATTACHMENTS',
        id: 5,
    },
    APPROVALS: {
        name: 'APPROVALS',
        id: 6,
    },
    OFFENSE: {
        name: 'OFFENSE',
        id: 7,
    },
    INCIDENT: {
        name: 'INCIDENT',
        id: 8,
    },
    FIELD_CONTACT: {
        name: 'FIELD_CONTACT',
        id: 9,
    },
    MISSING_PERSONS: {
        name: 'MISSING_PERSONS',
        id: 10,
    },
    IMPOUND: {
        name: 'IMPOUND',
        id: 11,
    },
    USE_OF_FORCE: {
        name: 'USE_OF_FORCE',
        id: 12,
    },
    USE_OF_FORCE_SUBJECT: {
        name: 'USE_OF_FORCE_SUBJECT',
        id: 13,
    },
    COMMUNITY_INFORMATION: {
        name: 'COMMUNITY_INFORMATION',
        id: 14,
    },
    CUSTODIAL: {
        name: 'CUSTODIAL',
        id: 16,
    },
    TOW_VEHICLE: {
        name: 'TOW_VEHICLE',
        id: 17,
    },
    CHECK_IN_INFORMATION: {
        name: 'CHECK_IN_INFORMATION',
        id: 18,
    },
    RELEASE_INFORMATION: {
        name: 'RELEASE_INFORMATION',
        id: 19,
    },
    TOW_VEHICLE_IMPOUND: {
        name: 'TOW_VEHICLE_IMPOUND',
        id: 20,
    },
    TRAFFIC_CRASH: {
        name: 'TRAFFIC_CRASH',
        id: 21,
    },
    CITATION: {
        name: 'CITATION',
        id: 22,
    },
    COURT_CASE: {
        name: 'COURT_CASE',
        id: 23,
    },
    CHARGES: {
        name: 'CHARGES',
        id: 24,
    },
    SUPPLEMENT_INFO: {
        name: 'SUPPLEMENT_INFO',
        id: 25,
    },
    BOOKING: {
        name: 'BOOKING',
        id: 26,
    },
    INVOLVED_PROFILES: {
        name: 'INVOLVED_PROFILES',
        id: 27,
    },
    SUPPLEMENT_OFFENSE: {
        name: 'SUPPLEMENT_OFFENSE',
        id: 28,
    },
    SUPPLEMENT_INCIDENT: {
        name: 'SUPPLEMENT_INCIDENT',
        id: 29,
    },
    BEHAVIORAL_CRISIS: {
        name: 'BEHAVIORAL_CRISIS',
        id: 30,
    },
    PROPERTY: {
        name: 'PROPERTY',
        id: 31,
    },
    VEHICLE: {
        name: 'VEHICLE',
        id: 32,
    },
    REPORT_STATUS_COMMENTS: {
        name: 'REPORT_STATUS_COMMENTS',
        id: 33,
    },
    SUMMARY_NARRATIVE: {
        name: 'SUMMARY_NARRATIVE',
        id: 34,
    },
    STOP: {
        name: 'STOP',
        id: 35,
    },
    PERSONNEL: {
        name: 'PERSONNEL',
        id: 36,
    },
    LINKED_REPORTS: {
        name: 'LINKED_REPORTS',
        id: 37,
    },
    STAFF_REMARKS: {
        name: 'STAFF_REMARKS',
        id: 38,
    },
    LEGACY_INFO: {
        name: 'LEGACY_INFO',
        id: 39,
    },
    CRASH_EVENT_INFO: {
        name: 'CRASH_EVENT_INFO',
        id: 40,
    },
    CRASH_LOCATION_INFO: {
        name: 'CRASH_LOCATION_INFO',
        id: 41,
    },
} as const;

/**
 * "Global" (default) report cards must match BE enum:
 * {@link
 *  cobalt-rms/reporting/reporting-common/src/main/java/mark43/reporting/model/reports/definitions/ReportCard.java}
 */
export default reportCardEnum;

export type ReportCard = (typeof reportCardEnum)[keyof typeof reportCardEnum];
export type ReportCardId = ReportCard['id'];

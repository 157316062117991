import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const CAD_BASE_URL = '/cad/api';

export default createResource({
    name: 'Call For Service Admin Resource',
    methods: {
        getCallForServicesForDept(deptId) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'GET',
                url: `cfs/full/department/${deptId}`,
            });
        },
        upsertCallForService(data) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'POST',
                url: 'cfs/full',
                data,
            });
        },
        fetchHistoryForAllCallForServices() {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'GET',
                url: 'cfs/histories',
            });
        },
        fetchHistoryForCallForService(id) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'GET',
                url: `cfs/${id}/histories`,
            });
        },

        /**
         * Export all CFSs into a CSV.
         * @return {Promise}
         */
        exportAttributes() {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'GET',
                url: 'cfs/export',
            });
        },

        /**
         * Validate that a file is valid for importing new CFSs.
         * @return {Promise}
         */
        validateAttributeImport(fileId) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'POST',
                url: `cfs/import/validate/${fileId}`,
            });
        },

        /**
         * Import CFSs from an uploaded file.
         * @return {Promise}
         */
        importAttributes(fileId) {
            return req({
                baseUrl: CAD_BASE_URL,
                method: 'POST',
                url: `cfs/import/${fileId}`,
            });
        },
    },
});

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { currentUserHasAbilitySelector } from '../../core/current-user/state/ui';

import redirectInsufficientPermissions from '../../core/utils/redirectInsufficientPermissions';
import { executeSavedSearchFromQueryParam } from '../../search/core/state/ui';
import evidenceDashboardSearchForm from './state/forms/evidenceDashboardSearchForm';
import { evidenceDashboardSearch } from './state/ui';

export function onEnter(nextState, replace) {
    if (currentUserHasAbilitySelector(this.getState())(abilitiesEnum.EVIDENCE.SEARCH_ITEMS)) {
        this.dispatch(
            executeSavedSearchFromQueryParam({
                searchModule: evidenceDashboardSearch,
                nextState,
                replace,
            })
        ).then((executed) => {
            if (!executed) {
                this.dispatch(
                    evidenceDashboardSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()
                ).then((result) => {
                    if (!result) {
                        // execute first time visit search
                        const formModel = evidenceDashboardSearchForm.selectors.formModelSelector(
                            this.getState()
                        );
                        return this.dispatch(
                            evidenceDashboardSearch.actionCreators.search(
                                {
                                    formData: formModel,
                                },
                                // do not auto-save the default search so that
                                // a faulty request cannot cause your latest saved-search
                                // to be overwritten by the default search
                                { cacheBust: true, scroll: false, autoSave: false }
                            )
                        );
                    }
                });
            }
        });
    } else {
        this.dispatch(redirectInsufficientPermissions());
    }
}

import React from 'react';
import styled from 'styled-components';

type NItemsItemSectionProps = {
    className?: string;
    deleteButton: React.ReactNode;
    nestingLevel?: number;
};

const SectionWrapper = styled.div<{ nestingLevel: number }>`
    display: flex;
    padding: 16px;
    background-color: ${(props) =>
        props.nestingLevel % 2 === 0
            ? props.theme.colors.extraLightGrey
            : 'var(--arc-colors-surface-accent)'};
    border-radius: 5px;
`;

const Content = styled.div`
    flex: 1;
`;

const DeleteButtonWrapper = styled.div`
    float: none;
    align-self: flex-start;
`;

/**
 * Wrapper for individual item in N-Items; When we want to visually group together multiple rows
 *   as one single item with single delete button.
 */
const NItemsItemSection: React.FC<NItemsItemSectionProps> = (props) => {
    const { children, className, deleteButton, nestingLevel = 0 } = props;
    return (
        <SectionWrapper className={className} nestingLevel={nestingLevel}>
            <Content>{children}</Content>
            <DeleteButtonWrapper>{deleteButton}</DeleteButtonWrapper>
        </SectionWrapper>
    );
};

export default NItemsItemSection;

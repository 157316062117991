import React from 'react';
import { sortBy, size, keyBy, chain, compact, join, concat, find, map, filter } from 'lodash';
import { ExportPresetView } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL } from '~/client-common/core/enums/universal/fields';

import { PacketOption } from '../../../exports/core/utils/exportHelpers';
import { sortOptionsByExportPresetReportTypes } from '../../../exports/core/utils/sortOptionsByExportPresetReportTypes';
import {
    unflatten,
    setCorrectChecks,
} from '../../../core/forms/components/checkboxes/CheckboxTree';
import { ReportExportsCheckboxTree, Tree } from './ReportExportsCheckboxTree';
import { ExpandCollapseState } from './ReportFormExportsOptions';

const strings = componentStrings.reports.core.SortedReportExportsCheckboxTree;

export function buildNodesFromOptions(options: PacketOption[], entityId: number, ownerDisplayValue: string) {
    const packetsByValue = keyBy(options, 'value');
    const packetsByParentValue = keyBy(options, 'parentValue');
    const dummyParents = map(
        chain(options)
            .map('parentValue')
            .compact()
            .uniq()
            .reject((val) => !!packetsByValue[val])
            .map((parentValue) => packetsByParentValue[parentValue])
            .keyBy('value')
            .value(),
        ({
            parentValue = '',
            clientApprovalStatus,
            ownerName,
            createdDate,
            createdDateUtc,
            entityId: optionEntityId,
            privacyDisplay,
            isAssociatedRecord,
        }) => ({
            privacyDisplay,
            display: parentValue,
            value: parentValue,
            createdDateUtc,
            // `noteDisplay` and `indicatorText` should only appear on dummy root nodes
            indicatorText: optionEntityId === entityId ? 'Current Report' : undefined,
            noteDisplay:
                ownerName && createdDate
                    ? join(
                          compact([strings.owner(ownerName, ownerDisplayValue), strings.createdDate(createdDate)]),
                          ', '
                      )
                    : undefined,
            clientApprovalStatus,
            isAssociatedRecord,
        })
    );
    const nodes = compact(concat(options, dummyParents));
    return nodes;
}

function createdSortedTreeFromNodes(nodes: PacketOption[]) {
    // Root nodes should be sorted by `createdDateUtc`.
    // but nested nodes should be sorted by their display
    const rootNodes = sortBy(
        unflatten((option: PacketOption) => option.display, nodes),
        ['createdDateUtc']
    );

    const associatedNodes = filter(rootNodes, (node) => node.isAssociatedRecord);
    const nonAssociatedNodes = filter(rootNodes, (node) => !node.isAssociatedRecord);

    return concat(nonAssociatedNodes, associatedNodes);
}

export const SortedReportExportsCheckboxTree: React.FC<{
    value: string[];
    onChange: (
        value?: string,
        newValue?: boolean,
        allNodes?: PacketOption[]
    ) => (sortOrder: Tree[]) => void;
    options: PacketOption[];
    entityId: number;
    exportPreset?: ExportPresetView;
    exportPresetSort: boolean;
    handleSelectAll: (sortOrder: Tree[]) => void;
    handleDeselectAll: () => void;
    expandCollapseState: ExpandCollapseState;
    setExpandCollapseState: React.Dispatch<React.SetStateAction<ExpandCollapseState>>;
}> = ({
    value,
    onChange,
    options,
    entityId,
    handleSelectAll,
    handleDeselectAll,
    expandCollapseState,
    exportPreset,
    exportPresetSort,
    setExpandCollapseState,
}) => {
    const ownerDisplayValue = useFields(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)[DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL];
    const nodes = React.useMemo(() => {
        // sort here for UI update and sort in ReportFormExportsOptions for form model update
        const sortedOptions =
            exportPresetSort && exportPreset
                ? sortOptionsByExportPresetReportTypes(options, exportPreset)
                : options;

        return buildNodesFromOptions(sortedOptions, entityId, ownerDisplayValue);
    }, [entityId, exportPreset, exportPresetSort, options, ownerDisplayValue]);

    const [sortOrder, setSortOrder] = React.useState<Tree[]>(
        size(nodes) ? createdSortedTreeFromNodes(nodes) : []
    );

    React.useEffect(() => {
        if (!exportPresetSort || !exportPreset) {
            return;
        }

        // nodes are pre-sorted by sortOptionsByExportPresetReportTypes
        // because easier to sort flat options rather than nodes or nested tree
        const tree = unflatten(undefined, nodes);

        setSortOrder(tree);
    }, [nodes, exportPreset, exportPresetSort]);

    const nodesWithChangeHandler = React.useMemo(() => {
        return map(nodes, (node) => {
            return {
                ...node,
                changeHandler: (val: string, newValue: boolean, allNodes: PacketOption[]) => {
                    return onChange(val, newValue, allNodes)(sortOrder);
                },
            };
        });
    }, [nodes, sortOrder, onChange]);

    const tree = React.useMemo(() => createdSortedTreeFromNodes(nodesWithChangeHandler), [
        nodesWithChangeHandler,
    ]);

    // reorder the tree to match the sort order
    const sortedTree = map(sortOrder, ({ value }) => {
        return find(tree, { value });
    });

    // Modifies the tree and it's nodes in place
    setCorrectChecks(sortedTree, value);

    return (
        <ReportExportsCheckboxTree
            expandCollapseState={expandCollapseState}
            setExpandCollapseState={setExpandCollapseState}
            onDragEnd={(sortOrder: Tree[]) => {
                setSortOrder(sortOrder);
                // Update the values with the new sort order
                // If no args are passed to `onChange`, it just
                // returns the original values, which is what we want
                onChange()(sortOrder);
            }}
            tree={sortedTree}
            nodes={nodesWithChangeHandler}
            onSelectAll={() => handleSelectAll(sortedTree)}
            onDeselectAll={handleDeselectAll}
            options={options}
            value={value}
        />
    );
};

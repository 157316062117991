/**
 * Flags on an offense code that depict whether the code is an offense, incident,
 * arrest charge, or traffic charge.
 * @type {string[]}
 */
export const OFFENSE_TYPE_OFFENSE_CODE_FLAGS = ['isOffenseType'] as const;
export const INCIDENT_TYPE_OFFENSE_CODE_FLAGS = ['isIncidentType'] as const;
export const CHARGE_TYPE_OFFENSE_CODE_FLAGS = [
    'isWarrantChargeLocal',
    'isWarrantChargeExternal',
    'isCrimeCharge',
    'isDuiCharge',
    'isNonCustodialCharge',
    'isTrafficCharge',
] as const;
export const TRAFFIC_TYPE_OFFENSE_CODE_FLAGS = ['isTrafficCharge'] as const;

import nameAttributesActionTypes from '../actions/types/nameAttributesActionTypes';

export function nameAttributesDataReducer(state = [], action) {
    switch (action.type) {
        case nameAttributesActionTypes.SET_NAME_ATTRIBUTES:
            return action.payload;
        default:
            return state;
    }
}

import { SystemRuleEnum } from '@mark43/rms-api';
import _ from 'lodash';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import addRuleId from '../helpers/addRuleId';
import { setKoModelValidation } from '../helpers/koValidationHelper';
import { checkPhoneLength } from './phoneValidationHelpers';

export const orgVictimAddressRequired = addRuleId(
    SystemRuleEnum.ORG_VICTIMS_ADDRESS_REQUIRED.name,
    (data) => {
        return data.hasBusinessAddress || data.hasBusinessLocation;
    }
);

export const orgIndustryOtherRequired = addRuleId(
    SystemRuleEnum.ORG_INDUSTRY_OTHER_REQUIRED.name,
    (data, msg, state) => {
        const isValid = attributeIsOtherSelector(state)(data.org.industryAttrId())
            ? !!data.org.industryOther()
            : true;
        setKoModelValidation(data.org.industryOther, isValid, msg);
        return isValid;
    }
);

export const orgPhoneSpecialMaxLength = addRuleId(
    SystemRuleEnum.ORG_PHONE_SPECIAL_MAX_LENGTH.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.phones, (phone) => {
            const isValid = checkPhoneLength(phone.displayNumber());
            setKoModelValidation(phone.displayNumber, isValid, msg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

export const orgPhoneTypeRequiredIfNumberFilled = addRuleId(
    SystemRuleEnum.ORG_PHONE_TYPE_REQUIRED_IF_NUMBER_FILLED.name,
    (data, msg) => {
        let allAreValid = true;
        _.forEach(data.phones, (phone) => {
            const isValid = phone.displayNumber() ? !!phone.phoneType() : true;
            setKoModelValidation(phone.phoneType, isValid, msg);
            if (!isValid) {
                allAreValid = false;
            }
        });
        return allAreValid;
    }
);

import { compose, withPropsOnChange } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import React from 'react';

import {
    formatFullName,
    isUnknown,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import sortCharges from '~/client-common/core/domain/charges/utils/sortCharges';
import { arrestForReportIdSelector } from '~/client-common/core/domain/arrests/state/data';
import { chargesWhereSelector } from '~/client-common/core/domain/charges/state/data';
import { personProfileByIdSelector } from '~/client-common/core/domain/person-profiles/state/data';
import {
    defendantMugshotPathByReportIdSelector,
    findArrestRecordNumberSelector,
} from '~/client-common/core/domain/arrests/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';

import Row from '../../../../../core/components/Row';
import { currentReportIdSelector } from '../../../../../../legacy-redux/selectors/reportSelectors';

import { PersonProfileImage, profileImageSizes } from '../../../../../core/components/ProfileImage';

const strings = componentStrings.reports.core.BookingSidePanel;

const DefendantProfileWrapper = styled(Row)`
    display: flex;
`;

const DefendantInfo = styled.div`
    flex: 1;
`;

const DefendantMainText = styled.div`
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const DefendantSubText = styled.div`
    font-size: var(--arc-fontSizes-sm);
`;

const DefendantProfile = ({ recordNumber, profile, charges, mugshotPath }) => (
    <DefendantProfileWrapper>
        <PersonProfileImage
            isUnknown={isUnknown(profile)}
            src={mugshotPath}
            size={profileImageSizes.IMAGE_CELL}
        />
        <DefendantInfo>
            <DefendantMainText>{formatFullName(profile)}</DefendantMainText>
            <DefendantSubText>{strings.arrestText(recordNumber)}</DefendantSubText>
            <DefendantSubText>{strings.chargesText(charges.length)}</DefendantSubText>
        </DefendantInfo>
    </DefendantProfileWrapper>
);

const mapStateToProps = createStructuredSelector({
    arrestForReportId: arrestForReportIdSelector,
    currentReportId: currentReportIdSelector,
    findArrestRecordNumber: findArrestRecordNumberSelector,
    personProfileById: personProfileByIdSelector,
    chargesWhere: chargesWhereSelector,
    defendantMugshotPathByReportId: defendantMugshotPathByReportIdSelector,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            'arrestForReportId',
            'currentReportId',
            'findArrestRecordNumber',
            'personProfileById',
            'chargesWhere',
            'defendantMugshotPathByReportId',
        ],
        ({
            arrestForReportId,
            currentReportId,
            findArrestRecordNumber,
            personProfileById,
            chargesWhere,
            defendantMugshotPathByReportId,
        }) => {
            const arrest = arrestForReportId(currentReportId) || {};
            const { defendantId, id: arrestId } = arrest;
            const profile = personProfileById(defendantId) || {};
            const charges = sortCharges({ charges: chargesWhere({ arrestId }) });
            const mugshotPath = defendantMugshotPathByReportId(currentReportId) || '';
            const recordNumber = findArrestRecordNumber({ arrest, reportId: currentReportId });
            return {
                recordNumber,
                profile,
                charges,
                mugshotPath,
            };
        }
    )
)(DefendantProfile);

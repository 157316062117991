/**
 * The values of this enum are query params that may be added to routes
 * that configure application behavior.
 */
export default {
    // Hides the top or left navigation and used for adjust the embedded report page.
    CHROMELESS: 'chromeless',
    // Turns on night mode
    FORCE_NIGHTMODE: 'force_nightmode',
};

import { Store } from 'redux';
import { ElasticWarrant } from '@mark43/rms-api';
import { iconPaths } from '@arc/icon';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { formatWarrantTitle } from '~/client-common/helpers/warrantHelpers';
import { WarrantStatusGlobalAttrIdType } from '~/client-common/core/legacy-constants/types';
import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import {
    convertWarrantApprovalStatusGlobalAttrIdToIconType,
    convertWarrantIconTypeToThemeColor,
} from '../../../../../../records/core/components/ApprovalStatusIcon';
import { iconTypes } from '../../../../../components/Icon';
import { currentThemeSelector } from '../../../../../styles/state';
import { FormatMentionItemMapper, Mention } from '../types';
import { getDefaultIcon, getMentionEntityLinkUrl } from '../utils';

const ICON_TYPE_TO_ICON_MAPPER = {
    [iconTypes.PENDING]: iconPaths.Pending,
    [iconTypes.WARRANT_ACTIVE]: iconPaths.WarrantActive,
    [iconTypes.WARRANT_INACTIVE]: iconPaths.WarrantInactive,
    [iconTypes.STUB]: iconPaths.Stub,
    [iconTypes.WARRANT_NO_STATUS]: iconPaths.WarrantNoStatus,
};

const isWarrantStatusGlobalAttrIdType = (item?: number): item is WarrantStatusGlobalAttrIdType => {
    return !!item;
};

const getApprovalStatusIcon = ({
    warrantStatusGlobalAttrId,
    theme,
}: {
    warrantStatusGlobalAttrId?: number;
    theme: ReturnType<typeof currentThemeSelector>;
}) => {
    if (!isWarrantStatusGlobalAttrIdType(warrantStatusGlobalAttrId)) {
        return getDefaultIcon(
            ICON_TYPE_TO_ICON_MAPPER[iconTypes.WARRANT_NO_STATUS],
            theme.colors.brightYellow
        );
    }

    const warrantIconType = convertWarrantApprovalStatusGlobalAttrIdToIconType(
        warrantStatusGlobalAttrId
    );
    const iconColor = convertWarrantIconTypeToThemeColor(warrantIconType);

    return getDefaultIcon(
        ICON_TYPE_TO_ICON_MAPPER[warrantIconType],
        iconColor ? theme.colors[iconColor] : theme.colors.brightYellow
    );
};

const prepareMentionedItem = (item: ElasticWarrant, store: Store<RootState>): Mention => {
    const currentTheme = currentThemeSelector(store.getState());
    const formatAttributeById = formatAttributeByIdSelector(store.getState());
    const { warrantNumber, warrantTypeAttrId, subject, warrantStatusGlobalAttrId } = item;
    const warrantType = formatAttributeById(warrantTypeAttrId);
    const subjectName = subject && formatFullName(subject);
    const name = formatWarrantTitle({ warrantNumber, warrantType, subjectName });

    const approvalStatusIcon = getApprovalStatusIcon({
        warrantStatusGlobalAttrId,
        theme: currentTheme,
    });

    return {
        id: item.id.toString(),
        name,
        text: name,
        category: 'WARRANTS',
        defaultIcon: approvalStatusIcon,
        url: getMentionEntityLinkUrl({ itemType: 'WARRANTS', itemId: item.id }),
    };
};

const formatMapper: FormatMentionItemMapper<ElasticWarrant> = {
    resourceMethod: 'searchWarrants',
    prepareMentionedItem,
};

export default formatMapper;

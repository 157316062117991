import recursivelyConvertIdsToDisplayValueObjects from './recursivelyConvertIdsToDisplayValueObjects';
import { isNotPrefixedWithHasRegex, joinRegex } from './regexPatterns';

// field is not prefixed by `has` and is suffixed with AttrId(s)
const ATTRIBUTE_KEY_REGEX = joinRegex([isNotPrefixedWithHasRegex, /AttrIds?$/]);
const ATTRIBUTE_DETAIL_KEY_REGEX = joinRegex([isNotPrefixedWithHasRegex, /AttrDetails?$/]);
const ID_KEY_REGEX = /Ids?$/;

/**
 * Recursively traverses an object, looking for keys with `AttrId(s)` or `AttrDetail(s)`
 * as suffix and does NOT contains `has` as prefix, transforms their values into display value
 * objects with a shape of `{ displayValues : [<string>], ids: [number] }` and stores the
 * result on a new key, replacing the `Id(s)` suffix with `Detail`. The original keys will
 * be removed. In case of `AttrDetail(s)` the original key is kept, because it is already correct.
 * For params see return value of `recursivelyConvertIdsToDisplayValueObjects`
 */
export default recursivelyConvertIdsToDisplayValueObjects({
    sourceKeyRegex: [ATTRIBUTE_KEY_REGEX, ATTRIBUTE_DETAIL_KEY_REGEX],
    targetKeyRegex: ID_KEY_REGEX,
    getTargetKey: (key, targetKeyRegex, index) => {
        // `*AttrId` case
        if (index === 0) {
            return key.replace(targetKeyRegex, 'Detail');
            // `*AttrDetail` case, no need to convert the key
        } else if (index === 1) {
            return key;
        } else {
            throw new Error('Invalid index found');
        }
    },
});

import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Icon as ArcIcon } from 'arc';

import { reportShortTitleViewModelsWhereSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import stringsConfig from '~/client-common/core/strings';

import testIds from '../../../../core/testIds';
import { Tile } from '../../../../modules/core/components/Tile';
import FilterableList from '../../../../modules/core/components/FilterableList';
import LinkedReportsSection from '../LinkedReportsSection';
import LinkedCadReports from '../LinkedCadReports';
import { currentUserDepartmentIdSelector } from '../../../../modules/core/current-user/state/ui';

const strings = stringsConfig.components.reports.CreateReportModalResults;

const CreateReportModalSubsectionHeader = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.mediumLightGrey};
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-weight: ${(props) => props.theme.fontWeights.regular};
    text-transform: uppercase;
`;

const NewReportOptions = styled.div`
    margin-top: 20px;
`;

function ReportDefinitionsSection({ children }) {
    return (
        <div style={{ marginTop: 15 }}>
            <CreateReportModalSubsectionHeader>
                {strings.reportDefinitionsTitle}
            </CreateReportModalSubsectionHeader>
            {children}
        </div>
    );
}

function reportDefinitionsOptionsMapper(createReport) {
    return (reportDef) => {
        return (
            <Tile
                key={reportDef.id}
                onClick={createReport.bind(this, reportDef.id)}
                testId={testIds.NEW_REPORT_OPTION}
                media={<ArcIcon color="brand.default" icon="Add" size="md" />}
                title={
                    <>
                        {reportDef.name} {strings.report}
                    </>
                }
            />
        );
    };
}

function CreateReportModalResults({
    createReport,
    renDisplayName,
    ui,
    reportShortTitleViewModelsWhere,
    currentUserDepartmentId,
}) {
    const reportShortTitleViewModels = reportShortTitleViewModelsWhere({
        reportingEventNumber: ui.enteredReportingEventNumber,
        departmentId: currentUserDepartmentId,
    });
    const linkedReportsSection = ui.hasFinishedSearch ? (
        <LinkedReportsSection
            title={strings.relatedTitle(renDisplayName, ui.enteredReportingEventNumber)}
            reportShortTitleViewModels={reportShortTitleViewModels}
        />
    ) : null;

    const linkedCadReports = ui.hasFinishedSearch ? (
        <LinkedCadReports
            title={strings.cadRelatedTitle(renDisplayName, ui.enteredReportingEventNumber)}
            reportingEventNumber={ui.enteredReportingEventNumber}
            cadAgencyEventNumber={ui.enteredCadAgencyEventNumber}
        />
    ) : null;

    const reportDefinitionsFilterableListOptions = _(ui.acceptableReports)
        .sortBy('name')
        .map((definition) => {
            return {
                ...definition,
                display: definition.name,
            };
        })
        .value();

    const reportDefinitions = ui.hasFinishedSearch ? (
        <ReportDefinitionsSection>
            <NewReportOptions>
                <FilterableList
                    options={reportDefinitionsFilterableListOptions}
                    optionsMapper={reportDefinitionsOptionsMapper(createReport)}
                    placeholderText={strings.filterPlaceholder}
                    testId={testIds.NEW_REPORT_OPTIONS_LIST}
                />
            </NewReportOptions>
        </ReportDefinitionsSection>
    ) : null;
    return (
        <div>
            <div className="clearfix">
                {linkedCadReports}
                {linkedReportsSection}
            </div>
            {reportDefinitions}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    reportShortTitleViewModelsWhere: reportShortTitleViewModelsWhereSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

export default connect(mapStateToProps)(CreateReportModalResults);

import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { mediaQueries } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { myExportsSelector } from '../../selectors/exportsSelectors';
import {
    collapseMyExports,
    expandMyExports,
    clearExports,
    removeFilesFromMyExports,
} from '../../actions/exportsActions';
import ExportsList from './ExportsList';

const MyExportsWrapper = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 114;
    margin-right: var(--arc-space-4);
    right: 0;

    &.expanded {
        width: 350px;
        max-width: calc(100vw - (var(--arc-space-4) * 2));
    }

    &.collapsed {
        width: 200px;
    }

    @media (min-width: ${mediaQueries.md}) {
        right: 10rem;
    }
`;

const MyExportsHeader = styled.div`
    float: left;
    width: 100%;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.colors.brightBlue};
    color: ${(props) => props.theme.colors.white};
    font-size: var(--arc-fontSizes-sm);
    border: 1px solid ${(props) => props.theme.colors.cobaltBlue};
    border-bottom: 2px solid ${(props) => props.theme.colors.cobaltBlue};
    border-radius: 4px 4px 0 0;
    cursor: pointer;
`;

const MyExportsTitle = styled.div`
    float: left;
    padding: 6px 0 6px 12px;
`;

const MyExportsShowHide = styled.div`
    float: right;
    padding: 6px 12px;
    font-family: proxima-nova, sans-serif;

    i::before {
        font-size: var(--arc-fontSizes-md);
        margin: -2px 2px 0 0;
    }
`;

function mapDispatchToProps(dispatch) {
    return {
        collapseMyExports: () => dispatch(collapseMyExports()),
        expandMyExports: () => dispatch(expandMyExports()),
        clearExports: (ids) => dispatch(clearExports(ids)),
        removeFilesFromMyExports: (ids) => dispatch(removeFilesFromMyExports(ids)),
    };
}

class MyExports extends React.Component {
    headerClick() {
        if (this.props.expanded) {
            this.props.collapseMyExports();
        } else {
            this.props.expandMyExports();
        }
    }

    clearAll() {
        // if the work order id exists, we want to make a server call to clear the export
        // if not, we just want to locally clear it
        const workOrderIdsToClear = _(this.props.exports).map('id').compact().value();

        if (workOrderIdsToClear.length > 0) {
            this.props.clearExports(workOrderIdsToClear);
        }

        const localFileIdsToClear = _(this.props.exports)
            .map((myExport) => (myExport.file ? myExport.file.id : null))
            .compact()
            .value();

        if (localFileIdsToClear.length > 0) {
            this.props.removeFilesFromMyExports(localFileIdsToClear);
        }
    }

    downloadAll() {
        const temporaryDownloadLink = document.createElement('a');
        temporaryDownloadLink.style.display = 'none';
        document.body.appendChild(temporaryDownloadLink);

        const domainUrl = window.location.hostname;

        // loop thru export tray files and programmatically download each one
        for (let n = 0; n < this.props.exports.length; n++) {
            const download = this.props.exports[n];
            const isReadyToDownload = download.file && !download.failedPrint;

            if (isReadyToDownload) {
                const downloadUrl = `https://${domainUrl}${download.file.fileWebServerPath}`;
                temporaryDownloadLink.setAttribute('href', downloadUrl);
                temporaryDownloadLink.setAttribute('download', download.file.originalFileName);

                temporaryDownloadLink.click();
            }
        }
        document.body.removeChild(temporaryDownloadLink);
    }

    render() {
        // render nothing if there are no exports to show
        if (!this.props.exports || !this.props.exports.length) {
            return null;
        }
        const title = `${componentStrings.exports.MyExports.title} (${
            this.props.exports ? this.props.exports.length : 0
        })`;
        const showHide = this.props.expanded ? 'HIDE' : 'SHOW';
        const icon = this.props.expanded ? 'icon-collapse' : 'icon-expand';

        const header = (
            <MyExportsHeader onClick={this.headerClick.bind(this)}>
                <MyExportsTitle>{title}</MyExportsTitle>
                <MyExportsShowHide>
                    {showHide}
                    <i className={icon} />
                </MyExportsShowHide>
            </MyExportsHeader>
        );

        const wrapperClasses = classNames('my-exports-wrapper', {
            expanded: this.props.expanded,
            collapsed: !this.props.expanded,
        });

        let contents = '';

        if (this.props.expanded) {
            contents = (
                <ExportsList
                    clearAll={this.clearAll.bind(this)}
                    files={this.props.exports}
                    downloadAll={this.downloadAll.bind(this)}
                />
            );
        }

        return (
            <MyExportsWrapper className={wrapperClasses} right={this.props.positionRight}>
                {header}
                {contents}
            </MyExportsWrapper>
        );
    }
}

export default connect(myExportsSelector, mapDispatchToProps)(MyExports);

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_PATH = 'admin/agency';

export default createResource({
    name: 'Agency Profile Resource',
    methods: {
        getDepartmentAgencyProfiles(departmentIds) {
            return req({
                method: 'POST',
                url: `${BASE_PATH}/department/batch`,
                data: departmentIds,
            });
        },
        createAgencyProfile(agencyProfile) {
            return req({
                method: 'POST',
                url: BASE_PATH,
                data: agencyProfile,
            });
        },
        getAgencies() {
            return req({
                method: 'GET',
                url: BASE_PATH,
            });
        },
        updateAgencyProfile(agencyProfile) {
            return req({
                method: 'PUT',
                url: `${BASE_PATH}/${agencyProfile.agencyId}`,
                data: agencyProfile,
            });
        },
        getAgencyProfile(agencyId) {
            return req({
                method: 'GET',
                url: `${BASE_PATH}/${agencyId}`,
            });
        },
    },
});

import {
    DISPLAY_ONLY_ORGANIZATION_LABEL,
    DISPLAY_ONLY_PERSON_LABEL,
} from "~/client-common/core/enums/universal/fields";
import useFields from "~/client-common/core/fields/hooks/useFields";
import { useItemTypeNames } from "~/client-common/core/fields/hooks/useItemTypeNames";
import globalAttributes from "~/client-common/core/legacy-constants/globalAttributes";

export const useCaseLinkedProfileLabels = () => {
    const {
        [DISPLAY_ONLY_ORGANIZATION_LABEL]: organizationLabel,
        [DISPLAY_ONLY_PERSON_LABEL]: personLabel,
    } = useFields([
        DISPLAY_ONLY_ORGANIZATION_LABEL,
        DISPLAY_ONLY_PERSON_LABEL,
    ]);

    const itemTypeNames = useItemTypeNames();
    const vehicleLabel = itemTypeNames[globalAttributes.itemType.vehicle];

    return {
        organizationLabel,
        personLabel,
        vehicleLabel,
    };
};

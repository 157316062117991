import { DispatchArea } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getDispatchAreasResource from '../../resources/dispatchAreasResource';
import { ClientCommonAction } from '../../../../../redux/types';

const dispatchAreasModule = createNormalizedModule<DispatchArea>({
    type: 'dispatchAreas',
});

const LOAD_DISPATCH_AREAS_START = 'dispatch_areas/LOAD_DISPATCH_AREAS_START';
const LOAD_DISPATCH_AREAS_SUCCESS = 'dispatch_areas/LOAD_DISPATCH_AREAS_SUCCESS';
const LOAD_DISPATCH_AREAS_FAILURE = 'dispatch_areas/LOAD_DISPATCH_AREAS_FAILURE';

// ACTIONS
export const storeDispatchAreas = dispatchAreasModule.actionCreators.storeEntities;

function loadDispatchAreasStart() {
    return {
        type: LOAD_DISPATCH_AREAS_START,
    };
}

function loadDispatchAreasSuccess(dispatchAreas: DispatchArea[]) {
    return {
        type: LOAD_DISPATCH_AREAS_SUCCESS,
        payload: dispatchAreas,
    };
}

function loadDispatchAreasFailure(err: Error) {
    return {
        type: LOAD_DISPATCH_AREAS_FAILURE,
        payload: err,
    };
}

export function loadDispatchAreas(): ClientCommonAction<Promise<void>> {
    const resource = getDispatchAreasResource();
    return function (dispatch) {
        dispatch(loadDispatchAreasStart());
        return resource
            .getDispatchAreas()
            .then((dispatchAreas: DispatchArea[]) => {
                dispatch(storeDispatchAreas(dispatchAreas));
                dispatch(loadDispatchAreasSuccess(dispatchAreas));
            })
            .catch((err: Error) => {
                dispatch(loadDispatchAreasFailure(err));
            });
    };
}

// SELECTORS

export const dispatchAreasSelector = dispatchAreasModule.selectors.entitiesSelector;
export const dispatchAreaByIdSelector = dispatchAreasModule.selectors.entityByIdSelector;

export default dispatchAreasModule.reducerConfig;

import { filter, map, uniq } from 'lodash';

import type { PacketOption } from './exportHelpers';
import isCasePacket from './isCasePacket';

/**
 * Find all the case ids within the given packets. The packets are not nested.
 */
export default function getCaseIdsInPackets(packetOptions: PacketOption[]): number[] {
    return uniq(
        map(
            filter(packetOptions, (packetOption) => isCasePacket(packetOption)),
            'rawPacket.entityId'
        )
    );
}

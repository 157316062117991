import { filter, includes, reject } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';

import PopoverSectionHeader from '../../../core/box/components/PopoverSectionHeader';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFButtonRadio } from '../../../core/forms/components/ButtonRadio';
import { RRFAttributeCheckboxes } from '../../../core/forms/components/checkboxes/AttributeCheckboxes';
import Button from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';

import itemPopoverForm from '../state/forms/itemPopoverForm';
import { createModalSelector } from '../../../core/box/state/ui';

const { inPoliceCustody } = globalAttributes.propertyStatusGlobal;
const strings = componentStrings.reports.core.ItemPopoverForm;

function ItemPopoverForm({
    itemTypeOptions,
    policeCustodyPropertyStatusFilterOptions,
    nonPoliceCustodyPropertyStatusFilterOptions,
    includePoliceCustodyStatuses,
    includeItemStatuses,
    multiplePoliceCustodyPropertyStatuses,
    propertyStatusesDisabled,
}) {
    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];
    return (
        <ReactReduxForm {...itemPopoverForm}>
            <PopoverSectionHeader>{strings.sectionTitles.itemType}</PopoverSectionHeader>
            <RRFButtonRadio path="itemTypeAttrId" options={itemTypeOptions}>
                {(buttonProps) => (
                    <Button {...buttonProps} iconLeft={<Icon type={iconTypes.ADD} />} />
                )}
            </RRFButtonRadio>

            {includePoliceCustodyStatuses && (
                <>
                    <PopoverSectionHeader>
                        {strings.sectionTitles.policeCustodyPropertyStatuses(custodyLabel)}
                    </PopoverSectionHeader>
                    <RRFAttributeCheckboxes
                        path="policeCustodyPropertyStatusAttrIds"
                        attributeType="PROPERTY_LOSS"
                        filterOptions={policeCustodyPropertyStatusFilterOptions}
                        columns={2}
                        columnWidth="48%"
                        gutterWidth="4%"
                        multiple={multiplePoliceCustodyPropertyStatuses}
                        disabled={propertyStatusesDisabled}
                    />
                </>
            )}

            {includeItemStatuses && (
                <>
                    <PopoverSectionHeader>
                        {strings.sectionTitles.nonPoliceCustodyPropertyStatuses}
                    </PopoverSectionHeader>
                    <RRFAttributeCheckboxes
                        path="nonPoliceCustodyPropertyStatusAttrIds"
                        attributeType="PROPERTY_LOSS"
                        filterOptions={nonPoliceCustodyPropertyStatusFilterOptions}
                        columns={2}
                        columnWidth="48%"
                        gutterWidth="4%"
                        disabled={propertyStatusesDisabled}
                    />
                </>
            )}
        </ReactReduxForm>
    );
}

const mapStateToProps = (_, { popoverContext }) => {
    const visibleItemTypeAttrIdsSelector = createModalSelector(
        popoverContext,
        'visibleItemTypeAttrIds'
    );
    const hiddenItemTypeAttrIdsSelector = createModalSelector(
        popoverContext,
        'hiddenItemTypeAttrIds'
    );
    const includePoliceCustodyStatusesSelector = createModalSelector(
        popoverContext,
        'includePoliceCustodyStatuses'
    );
    const includeItemStatusesSelector = createModalSelector(popoverContext, 'includeItemStatuses');
    const multiplePoliceCustodyPropertyStatusesSelector = createModalSelector(
        popoverContext,
        'multiplePoliceCustodyPropertyStatuses'
    );
    const propertyStatusesDisabledSelector = createModalSelector(
        popoverContext,
        'propertyStatusesDisabled'
    );
    return (state) => ({
        parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector(state),
        visibleItemTypeAttrIds: visibleItemTypeAttrIdsSelector(state),
        hiddenItemTypeAttrIds: hiddenItemTypeAttrIdsSelector(state),
        includePoliceCustodyStatuses: includePoliceCustodyStatusesSelector(state),
        includeItemStatuses: includeItemStatusesSelector(state),
        multiplePoliceCustodyPropertyStatuses: multiplePoliceCustodyPropertyStatusesSelector(state),
        propertyStatusesDisabled: propertyStatusesDisabledSelector(state),
    });
};

/**
 * Form that appears in the item popover for selecting an item's type and
 *   property status(es).
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['visibleItemTypeAttrIds', 'hiddenItemTypeAttrIds'],
        ({ visibleItemTypeAttrIds, hiddenItemTypeAttrIds }) => {
            let itemTypeOptions = strings.itemTypeOptions;
            if (visibleItemTypeAttrIds.length > 0) {
                itemTypeOptions = filter(itemTypeOptions, ({ value }) => {
                    return includes(visibleItemTypeAttrIds, value);
                });
            }
            if (hiddenItemTypeAttrIds.length > 0) {
                itemTypeOptions = reject(itemTypeOptions, ({ value }) => {
                    return includes(hiddenItemTypeAttrIds, value);
                });
            }
            return { itemTypeOptions };
        }
    ),
    withHandlers({
        policeCustodyPropertyStatusFilterOptions({ parentAttributeIdByAttributeId }) {
            return (options) =>
                filter(options, ({ value }) => {
                    return parentAttributeIdByAttributeId(value) === inPoliceCustody;
                });
        },
        nonPoliceCustodyPropertyStatusFilterOptions({ parentAttributeIdByAttributeId }) {
            return (options) =>
                filter(options, ({ value }) => {
                    return parentAttributeIdByAttributeId(value) !== inPoliceCustody;
                });
        },
    })
)(ItemPopoverForm);

import React from 'react';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { isOverflowHorizontal } from 'arc';

import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import Link from '../../../core/components/links/Link';
import ElasticCell from '../../../../legacy-redux/components/core/tables/ElasticCell';

import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import { ConditionalTooltip } from '../../../core/components/tooltip';

const AgencyNameContainer = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const StyledCaseTitle = styled.div`
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
`;

function CaseTitle({ elasticCase, ...props }) {
    const { isLegacy, title } = elasticCase;
    const localId = getViewModelProperties(elasticCase).localId || elasticCase.localId;

    return (
        <StyledCaseTitle className="case-title" {...props}>
            {isLegacy ? <span className="legacy-search-result">{`${localId}*`}</span> : localId}
            {title ? ` - ${title}` : ''}
        </StyledCaseTitle>
    );
}

function CaseNumberNameCell({
    elasticCase,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
    handleCaseLinkClick = (e) => e.stopPropagation(),
}) {
    const { departmentId } = elasticCase;

    const isExternalAgency = departmentId !== currentUserDepartmentId;
    const departmentDisplayTag = isExternalAgency
        ? ` (${departmentNameFromConsortiumLinksByDepartmentId(departmentId)})`
        : undefined;

    const [isTruncated, setIsTruncated] = React.useState(false);

    return (
        <ElasticCell row={elasticCase}>
            {(isClickable) => (
                <div>
                    <ConditionalTooltip
                        condition={isTruncated}
                        side="bottom"
                        content={<CaseTitle elasticCase={elasticCase} />}
                    >
                        <div>
                            <Link
                                disabled={!isClickable}
                                hoverOnUnderline={true}
                                disabledColor="darkGrey"
                                onClick={handleCaseLinkClick}
                                to={`/cases/${elasticCase.id}/summary`}
                            >
                                <CaseTitle
                                    onMouseEnter={(e) => {
                                        setIsTruncated(isOverflowHorizontal(e.currentTarget));
                                    }}
                                    elasticCase={elasticCase}
                                />
                            </Link>
                        </div>
                    </ConditionalTooltip>
                    {departmentDisplayTag && (
                        <AgencyNameContainer>{departmentDisplayTag}</AgencyNameContainer>
                    )}
                </div>
            )}
        </ElasticCell>
    );
}

export default compose(
    mapProps(({ handleCaseLinkClick, ...elasticCase }) => ({
        elasticCase,
        handleCaseLinkClick,
    })),
    connect(
        createStructuredSelector({
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(CaseNumberNameCell);

import { createSelector } from 'reselect';
import { get } from 'lodash';
import Promise from 'bluebird';

import { currentUserIdSelector } from '../current-user/state/ui';
import { setItem, getItem, removeOlderUsers } from './utils/localStorageHelpers';

const PERSISTENT_DATA = 'PERSISTENT_DATA';

// Selectors
const localStorageSelector = (state) => state.localStorage;

const formModelForCurrentUserSelector = createSelector(
    localStorageSelector,
    currentUserIdSelector,
    (localStorage, currentUserId) => (formModule) =>
        get(localStorage, `${currentUserId}.${formModule.formName}`)
);

// Actions
const SAVE_PERSISTENT_DATA = 'local-storage/SAVE_PERSISTENT_DATA';

function savePersistentDataToLocalStorage(data) {
    // before writing to local storage, remove older data
    setItem(PERSISTENT_DATA, removeOlderUsers(data));

    return {
        type: SAVE_PERSISTENT_DATA,
        payload: data,
    };
}

/**
 * load form data from local storage proxy into form module.
 * @param  {Object}    formModule The form module to update
 * @return {function}  return function returns form model
 */
export function loadFormDataFromLocalStorageIntoFormModule(formModule) {
    return (dispatch, getState) => {
        const formData = formModelForCurrentUserSelector(getState())(formModule);

        if (formData) {
            // update last action time stamp
            dispatch(touchLocalStorageCurrentUser());

            dispatch(formModule.actionCreators.change(formData));
        }
        return formData;
    };
}

/**
 * Update last action time stamp for current user.
 */
function touchLocalStorageCurrentUser() {
    return (dispatch, getState) => {
        const currentUserId = currentUserIdSelector(getState());
        const currentLocalStorage = localStorageSelector(getState());

        if (currentLocalStorage[currentUserId]) {
            const localStorage = {
                ...currentLocalStorage,
                [currentUserId]: {
                    ...currentLocalStorage[currentUserId],
                    lastActionTimeStamp: Date.now(),
                },
            };
            dispatch(savePersistentDataToLocalStorage(localStorage));
        }
    };
}

/**
 * Retrieve the current local storage proxy form data. Update/add the form data
 * from the form module. Uses current user as keys for the local storage object.
 * And timestamps help manage local storage limits.
 * @param  {Object}   formModule The form module to save
 * @return {function}
 */
export function saveFormDataToLocalStorage(formModule) {
    return (dispatch, getState) => {
        const currentUserId = currentUserIdSelector(getState());
        const formModel = formModule.selectors.formModelSelector(getState());
        // convert FROM form model because using actionCreators.change on
        // loading converts TO form model
        const formData = formModule.convertFromFormModel(formModel);
        const currentLocalStorage = localStorageSelector(getState());
        const localStorage = {
            ...currentLocalStorage,
            [currentUserId]: {
                ...currentLocalStorage[currentUserId],
                [formModule.formName]: formData,
                lastActionTimeStamp: Date.now(),
            },
        };

        dispatch(savePersistentDataToLocalStorage(localStorage));
    };
}

/**
 * Do NOT use this method with any sensitive data
 * It will persist after logout
 * Save arbitrary data into a user's persistent local storage
 * @param  {string}   key  The key for the data
 * @param  {Object}   data The data to store
 */
export function saveArbitraryDataToLocalStorage(key, data) {
    return (dispatch, getState) => {
        const currentUserId = currentUserIdSelector(getState());
        const currentLocalStorage = localStorageSelector(getState());
        const localStorage = {
            ...localStorageSelector(getState()),
            [currentUserId]: {
                ...currentLocalStorage[currentUserId],
                [key]: data,
                lastActionTimeStamp: Date.now(),
            },
        };

        dispatch(savePersistentDataToLocalStorage(localStorage));
    };
}

/**
 * Load arbitrary data from a key in local storage.
 * @param  {string}   key The key for the data
 * @return {Promise}
 */
export function loadArbitraryDataFromLocalStorage(key) {
    return (dispatch, getState) => {
        const currentUserId = currentUserIdSelector(getState());
        const currentLocalStorage = localStorageSelector(getState());
        return Promise.resolve(get(currentLocalStorage, `${currentUserId}.${key}`));
    };
}

// Reducers
export default function localStorageReducer(state = getItem(PERSISTENT_DATA) || {}, action) {
    switch (action.type) {
        case SAVE_PERSISTENT_DATA:
            return action.payload;
        default:
            return state;
    }
}

import React from 'react';
import styled from 'styled-components';
import { isBoolean, map } from 'lodash';

import {
    COLUMN_WIDTH_12,
    COLUMN_WIDTH_8,
    LEFT_VALUE,
    RIGHT_VALUE,
} from '../configuration/constants';
import getDisplayValueForValue from '../utils/getDisplayValueForValue';
import { computePropertyRowColors, isPropertyRowMatch } from '../utils/computePropertyRowColors';
import { useDateTimeFormatter } from '../../core/current-user/hooks/dateTimeFormats';
import MergeEntitiesText from './core/MergeEntitiesText';
import Column from './core/Column';
import FlexContainer from './core/FlexContainer';
import GenericGroupedCell from './cells/GenericGroupedCell';

const TextLabel = styled(MergeEntitiesText)`
    cursor: pointer;
    display: flex;
`;

const InputWithMargin = styled.input`
    && {
        margin-right: 10px;
    }
`;

class PropertyRowComponent extends React.Component {
    shouldComponentUpdate(nextProps) {
        return (
            this.props.models !== nextProps.models ||
            this.props.selectedValue !== nextProps.selectedValue
        );
    }

    handleChange = (e) => this.props.onValueChange(this.props.fieldConfig.prop, e.target.value);

    render() {
        const {
            last,
            even,
            fieldDisplayNames,
            fieldConfig,
            models,
            formatAttributeById,
            selectedValue,
            dateTimeFormatter,
        } = this.props;

        const { prop } = fieldConfig;
        const [leftModel, rightModel] = models;
        const dependencies = {
            formatAttributeById,
            dateTimeFormatter,
        };
        const { field } = fieldConfig;
        const modelValueTuples = [
            [leftModel, LEFT_VALUE],
            [rightModel, RIGHT_VALUE],
        ];

        const isMatch = isPropertyRowMatch(leftModel, rightModel, fieldConfig);
        const colors = computePropertyRowColors(even, isMatch);

        return (
            // the last item row has to grow in case we do not have enough data
            // to fill the whole screen. This is required so that the left bar
            // properly fills the whole content area from top to bottom
            <FlexContainer flexGrow={last ? 1 : 0} flexBasis="auto" flexShrink={0}>
                <Column
                    backgroundColor="var(--arc-colors-surface-accent)"
                    width={COLUMN_WIDTH_8}
                    borderRight="1px solid"
                    borderColor="var(--arc-colors-border-default)"
                    borderBottom="1px solid"
                >
                    <MergeEntitiesText textAlign="right">
                        {fieldDisplayNames[field]}
                    </MergeEntitiesText>
                </Column>
                {map(modelValueTuples, ([model, value]) => {
                    const isSelected = selectedValue === value;

                    return (
                        <Column
                            key={model.id}
                            backgroundColor={colors.columnBackgroundColor}
                            width={COLUMN_WIDTH_12}
                            borderRight="1px solid"
                            borderBottom={isBoolean(even) ? undefined : '1px solid'}
                            borderColor="var(--arc-colors-border-default)"
                            hoverBackgroundColor={colors.hoverBackgroundColor}
                            hover={true}
                            padding="0"
                        >
                            {/*
                                Padding has to be moved from `Column` to `TextLabel` because we need
                                to be able to show a 25% color overlay over the Column background,
                                in both normal and hover mode
                            */}
                            <TextLabel
                                as="label"
                                padding="15px 20px"
                                backgroundColor={
                                    isSelected ? colors.selectedTextBackgroundColor : undefined
                                }
                            >
                                <InputWithMargin
                                    type="radio"
                                    name={prop}
                                    checked={isSelected}
                                    value={value}
                                    onChange={this.handleChange}
                                />
                                {fieldConfig.relatedFields ? (
                                    <GenericGroupedCell
                                        model={model}
                                        dependencies={dependencies}
                                        fieldConfig={fieldConfig.relatedFields}
                                        fieldDisplayNames={fieldDisplayNames}
                                    />
                                ) : (
                                    getDisplayValueForValue(model, fieldConfig, dependencies)
                                )}
                            </TextLabel>
                        </Column>
                    );
                })}
            </FlexContainer>
        );
    }
}

export default function PropertyRow(props) {
    const dateTimeFormatter = useDateTimeFormatter();
    return <PropertyRowComponent {...props} dateTimeFormatter={dateTimeFormatter} />;
}

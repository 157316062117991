import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import moment from 'moment';

import { agencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/data';

import { MFTSelect } from '../../core/forms/components/selects/Select';
import { MAX_OPTION_MONTHS_INITIAL } from '../configuration';

type ComplianceMonthYearSelectProps = {
    agencyId?: number;
    clearable: boolean;
    disabled: boolean;
    label: string;
    lastExportedDate?: string;
    firstExportedDate?: string;
    ignoreMaxMonthsOptions?: boolean;
    onChange: () => void;
    path: string;
    width: number;
};

function buildNibrsMonthYearOptions(agencyNibrsStartDateUtc?: string, lastExportMonth?: string) {
    const start = moment(agencyNibrsStartDateUtc);
    const date = moment(lastExportMonth);
    let displayMonths = MAX_OPTION_MONTHS_INITIAL;
    // set day of month to 1
    date.date(1);
    // if the agency has exported NIBRS before, show the last exported month and
    // all months between then and now. We show them in reverse
    // order so we add one month to the last exported month in order
    // for the map below to work properly
    if (lastExportMonth) {
        displayMonths = 1 + moment().diff(date, 'months');
        date.add(displayMonths - 1, 'month');
    }

    // if there is a nibrs start date, we will start from this month
    if (agencyNibrsStartDateUtc && !lastExportMonth) {
        displayMonths = 1 + moment().diff(start, 'months');
    }

    // if we have no last export month this means that the agency
    // has never exported any nibrs exports. In this case we display
    // the last 24 months as options
    const options = map(Array(displayMonths), () => {
        const option = {
            display: date.format('MM/YYYY'),
            value: date.format('YYYY-MM-DD'),
        };
        date.subtract(1, 'month');
        return option;
    });

    return options;
}

function buildNibrsFromToMonthYearOptions(
    fistExportMonth: string,
    lastExportMonth: string,
    ignoreMaxMonthsOptions?: boolean
) {
    const start = moment(fistExportMonth);
    const date = moment(lastExportMonth);

    start.date(1);
    date.date(1);

    // Display dates from end date to start date or up to max number of months options
    const displayMonths = !ignoreMaxMonthsOptions
        ? Math.min(1 + date.diff(start, 'months'), MAX_OPTION_MONTHS_INITIAL)
        : 1 + date.diff(start, 'months');

    const options = map(Array(displayMonths), () => {
        const option = {
            display: date.format('MM/YYYY'),
            value: date.format('YYYY-MM-DD'),
        };
        date.subtract(1, 'month');
        return option;
    });

    return options;
}

const ComplianceMonthYearSelect: React.FC<ComplianceMonthYearSelectProps> = (props) => {
    const { agencyId, firstExportedDate, lastExportedDate, ignoreMaxMonthsOptions } = props;

    const agencyProfileById = useSelector(agencyProfileByIdSelector);
    const agencyProfile = agencyProfileById(agencyId);

    const options = useMemo(() => {
        const nibrsStartDateUtc = agencyProfile?.nibrsStartDateUtc;

        if (!!firstExportedDate && lastExportedDate) {
            return buildNibrsFromToMonthYearOptions(
                firstExportedDate,
                lastExportedDate,
                ignoreMaxMonthsOptions
            );
        }

        return buildNibrsMonthYearOptions(nibrsStartDateUtc, lastExportedDate);
    }, [agencyProfile, firstExportedDate, lastExportedDate, ignoreMaxMonthsOptions]);

    return <MFTSelect {...props} options={options} />;
};

export default ComplianceMonthYearSelect;

import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import ConnectedFormattedUser from '~/client-common/core/users/components/ConnectedFormattedUser';
import FormattedUser from '~/client-common/core/users/components/FormattedUser';
import strings from '~/client-common/core/strings/componentStrings';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';

import { FileGrid as _FileGrid } from '../../../../legacy-redux/components/core/Files';
import _SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import { FlexRow, IndentedRow as _IndentedRow } from '../../core/components/RecordPrivacyRow';
import _SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';

const SummaryList = styled(_SummaryList)`
    display: block;
`;

const SummaryRow = styled(_SummaryRow)`
    && {
        display: block;
    }
`;

const FileGrid = styled(_FileGrid)`
    margin-left: 10px;
    margin-right: 10px;
`;

const FieldLabel = styled.div`
    flex: 1 1 auto;
    font-size: var(--arc-fontSizes-sm);
    line-height: 15px;
    color: ${(props) => props.theme.colors.lowContrastGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
`;

const renderFormattedDate = (dateString) =>
    dateString ? (
        <FormattedDate date={dateString} format={FormattedDate.FORMATS.FORM_DATE} />
    ) : null;

const courtOrderFieldTuples = [
    {
        prop: 'dateToPurgeUtc',
        fieldName: fields.COURT_ORDER_DATE_TO_PURGE_UTC,
        renderValue: renderFormattedDate,
    },
    {
        prop: 'courtCaseStatute',
        fieldName: fields.COURT_ORDER_COURT_CASE_STATUTE,
    },
    {
        prop: 'courtCaseDateUtc',
        fieldName: fields.COURT_ORDER_COURT_CASE_DATE_UTC,
        renderValue: renderFormattedDate,
    },
    {
        prop: 'involvedPersonFullName',
        fieldName: fields.COURT_ORDER_INVOLVED_PERSON_FULL_NAME,
    },
    {
        prop: 'isJuvenile',
        fieldName: fields.COURT_ORDER_IS_JUVENILE,
        renderValue: booleanToYesNo,
    },
    {
        prop: 'createdBy',
        label: strings.recordPrivacy.unsealing.CourtOrderSummary.sealedBy,
        renderValue: (userId) => (
            <ConnectedFormattedUser
                userId={userId}
                format={FormattedUser.FORMATS.FULL_NAME_WITH_FIRST_INITIAL_AND_ID_NUMBER}
            />
        ),
    },
    {
        prop: 'createdDateUtc',
        label: strings.recordPrivacy.unsealing.CourtOrderSummary.dateOfSeal,
        renderValue: renderFormattedDate,
    },
    {
        prop: 'remarks',
        fieldName: fields.COURT_ORDER_REMARKS,
    },
];

const SummaryRowWrapper = styled(FlexRow)`
    font-size: var(--arc-fontSizes-sm);
    align-items: flex-start;
`;

const BoldSummmaryRowWrapper = styled(SummaryRowWrapper)`
    font-weight: ${(props) => props.theme.fontWeights.bold};
    margin-bottom: 10px;
`;

const IndentedRow = styled(_IndentedRow)`
    margin-left: 10px;
`;

const CourtOrderSummary = ({ courtOrder, sortedAugmentedAttachmentViewModelsWhere }) => {
    const attachmentViewModels = sortedAugmentedAttachmentViewModelsWhere({
        entityId: courtOrder.id,
        entityType: EntityTypeEnum.COURT_ORDER.name,
        linkType: LinkTypesEnum.COURT_ORDER_ATTACHMENT,
    });

    return (
        <div>
            <SummaryRow
                fieldName={fields.COURT_ORDER_COURT_CASE_NUMBER}
                render={({ label }) => (
                    <BoldSummmaryRowWrapper>
                        {label} {courtOrder.courtCaseNumber}
                    </BoldSummmaryRowWrapper>
                )}
            />
            <IndentedRow>
                <SummaryList labelWidth={130} contentWidth="calc(100% - 140px)">
                    {map(courtOrderFieldTuples, ({ prop, fieldName, label, renderValue }) => (
                        <SummaryRow key={prop} fieldName={fieldName} label={label}>
                            {renderValue ? renderValue(courtOrder[prop]) : courtOrder[prop]}
                        </SummaryRow>
                    ))}
                    {!!attachmentViewModels.length && (
                        <div className="summary-row">
                            <FieldLabel>
                                {strings.recordPrivacy.unsealing.CourtOrderSummary.attachments}:
                            </FieldLabel>
                            <FileGrid fileLinks={attachmentViewModels} numOfColumns={1} />
                        </div>
                    )}
                </SummaryList>
            </IndentedRow>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    sortedAugmentedAttachmentViewModelsWhere: sortedAugmentedAttachmentViewModelsWhereSelector,
});

export default connect(mapStateToProps)(CourtOrderSummary);

import { MeasurementSystemEnum } from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import departmentSetupResource from '../../resources/departmentSetupResource';
import { openErrorModal } from '../../../../../legacy-redux/actions/boxActions';
import departmentSetupForm from '../forms/departmentSetupForm';

export const PAGE_LOAD_START = 'department-setup/PAGE_LOAD_START';
export const PAGE_LOAD_SUCCESS = 'department-setup/PAGE_LOAD_SUCCESS';
const PAGE_LOAD_FAILURE = 'department-setup/PAGE_LOAD_FAILURE';

const CREATE_DEPARTMENT_START = 'department-setup/CREATE_DEPARTMENT_START';
export const CREATE_DEPARTMENT_SUCCESS = 'department-setup/CREATE_DEPARTMENT_SUCCESS';
const CREATE_DEPARTMENT_FAILURE = 'department-setup/CREATE_DEPARTMENT_FAILURE';

const CHECK_PROGRESS_START = 'department-setup/CHECK_PROGRESS_START';
export const CHECK_PROGRESS_SUCCESS = 'department-setup/CHECK_PROGRESS_SUCCESS';
const CHECK_PROGRESS_FAILURE = 'department-setup/CHECK_PROGRESS_FAILURE';

function createDepartmentStart() {
    return {
        type: CREATE_DEPARTMENT_START,
    };
}

function createDepartmentSuccess(data) {
    return {
        type: CREATE_DEPARTMENT_SUCCESS,
        payload: data,
    };
}

function createDepartmentFailure(errMsg) {
    return {
        type: CREATE_DEPARTMENT_FAILURE,
        payload: errMsg,
    };
}

export function createDepartment(departmentCreationRequest) {
    return (dispatch) => {
        dispatch(createDepartmentStart());
        return departmentSetupResource
            .createDepartment(departmentCreationRequest)
            .then((result) => {
                dispatch(createDepartmentSuccess(result));
            })
            .catch((err) => {
                dispatch(createDepartmentFailure(err.message));
                dispatch(openErrorModal({ paragraphs: err.message.split(/\r?\n/) }));
            });
    };
}

function checkProgressStart() {
    return {
        type: CHECK_PROGRESS_START,
    };
}

function checkProgressSuccess(data) {
    return {
        type: CHECK_PROGRESS_SUCCESS,
        payload: data,
    };
}

function checkProgressFailure(errMsg) {
    return {
        type: CHECK_PROGRESS_FAILURE,
        payload: errMsg,
    };
}

export function checkProgress(newDeptId) {
    return (dispatch) => {
        dispatch(checkProgressStart());
        return departmentSetupResource
            .checkDepartmentSetupProgress(newDeptId)
            .then((data) => {
                dispatch(checkProgressSuccess(data));
            })
            .catch((err) => {
                dispatch(checkProgressFailure(err.message));
                dispatch(openErrorModal({ paragraphs: [err.message] }));
            });
    };
}

function pageLoadStart() {
    return {
        type: PAGE_LOAD_START,
    };
}

function pageLoadSuccess(departmentProfiles) {
    return {
        type: PAGE_LOAD_SUCCESS,
        payload: departmentProfiles,
    };
}

function pageLoadFailure(errMsg) {
    return {
        type: PAGE_LOAD_FAILURE,
        payload: errMsg,
    };
}

const IMPERIAL = MeasurementSystemEnum.IMPERIAL.name;

export function pageLoad() {
    return (dispatch, getState) => {
        const isRegionalPreferencesSectionEnabled = applicationSettingsSelector(getState())
            .RMS_I18N_ENABLED;

        dispatch(pageLoadStart());

        // start from a fresh form state every time
        dispatch(departmentSetupForm.actionCreators.reset());

        // These fields need to be set whether the i18n ff is on or off.
        // If it is on the user will manually have to set them,
        // if it is off they will default to imperial.
        const regionalPreferences = {};
        if (!isRegionalPreferencesSectionEnabled) {
            regionalPreferences.regionalPreferences = {
                heightMeasurementSystem: IMPERIAL,
                weightMeasurementSystem: IMPERIAL,
                distanceMeasurementSystem: IMPERIAL,
            };
        }
        // set default
        dispatch(
            departmentSetupForm.actionCreators.change({
                locationBias: {
                    countryCode: 'US',
                },
                ...regionalPreferences,
            })
        );

        return departmentSetupResource
            .getDefaultDepartmentProfiles()
            .then((departmentProfiles) => {
                dispatch(pageLoadSuccess(departmentProfiles));
            })
            .catch((err) => {
                dispatch(pageLoadFailure(err.message));
            });
    };
}

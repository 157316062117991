import moment from 'moment-timezone';
import { includes, isArray, map, partialRight, padStart } from 'lodash';
import { CountryCodeEnum } from '@mark43/rms-api';

import { isUndefinedOrNull } from '../../../helpers/logicHelpers';
import dateTypeEnum from '../../enums/client/dateTypeEnum';
import { attributeStatuses } from '../../domain/attributes/configuration';

// more like date-un-helpers

export const dateTypeOptions = [
    { display: 'Assigned', value: dateTypeEnum.ASSIGNED },
    { display: 'Modified', value: dateTypeEnum.MODIFIED },
    { display: 'Created', value: dateTypeEnum.CREATED },
    { display: 'Due', value: dateTypeEnum.DUE },
];

type JSDateInput = string | Date;
// since MomentInput allows void, we distinguish our own required and optional types
type NonVoid<T> = T extends void ? never : T;
type MomentInput = NonVoid<moment.MomentInput>;
type OptionalMomentInput = moment.MomentInput;

/**
 * @param  {*}      date   Any type that can be parsed by Moment.
 * Note: format cannot be optional here, due to issues with partialRight, below
 */
export function formatISODate(date: OptionalMomentInput, format: string) {
    if (!date) {
        return '';
    }
    return moment(date, moment.ISO_8601).format(format);
}

export function formatISODateWithTimezone(
    date: OptionalMomentInput,
    timezone: string,
    format: string
) {
    if (!date || !timezone) {
        return '';
    }
    return moment(date, moment.ISO_8601).tz(timezone).format(format);
}

/**
 * Zeros out the seconds and milliseconds of an ISO date.  Undefined/null dates return as such.
 * @param    Moment date string to trim
 * @return   ISO date string with seconds and milliseconds zeroed out.
 */
export function trimToMinutes(date: MomentInput) {
    if (isUndefinedOrNull(date)) {
        return date;
    }
    return moment(date).seconds(0).milliseconds(0).toISOString();
}

/**
 * Determines whether or not the provided date is in the future.
 * @param
 * @return
 */
export function isDateInFuture(date: MomentInput) {
    if (!date) {
        return false;
    }
    return moment().diff(date) < 0;
}

/**
 * Returns a date with a given amount of time added to the current date
 * Example: dateInFuture(5, 'y')
 * @param  the amount of time to be added
 * @param  the unit of time the amount is in
 */
export function dateInFuture(
    amount: Parameters<moment.Moment['add']>[0],
    unit: Parameters<moment.Moment['add']>[1]
) {
    return moment().add(amount, unit);
}

/**
 * Determines if the `dateToCheck` is after the `date`.
 * @param date
 * @param dateToCheck
 */
export function isAfter(date: MomentInput, dateToCheck: MomentInput) {
    return moment(dateToCheck).isAfter(moment(date));
}

/**
 * Determines if the `dateToCheck` is before the `date`.
 * @param  date
 * @param  dateToCheck
 */
export function isBefore(date: MomentInput, dateToCheck: MomentInput) {
    return moment(dateToCheck).isBefore(moment(date));
}

/**
 * Determines whether or not the provided date is between start and end.
 * @param       date    Moment date string to check
 * @param       start   Moment date string representing the start date
 * @param       end     Moment date string representing the end date
 * @return
 */
export const isDateBetween = (date: MomentInput, start: MomentInput, end: MomentInput) => {
    const dateMoment = moment(date);
    return dateMoment.isAfter(moment(start)) && dateMoment.isBefore(moment(end));
};

/**
 * Current time in ISO string format.
 */
export function nowUtc() {
    return moment().toISOString();
}

export type DateTimeFormats = typeof dateTimeFormats;

export type DateTimeFormatKeys = keyof DateTimeFormats;

/**
 * Function provides department location date formats
 * @param countryCode department country code
 * @return object with date formats
 */
export const getLocationSpecificDateTimeFormats = (countryCode: string | undefined) =>
    countryCode === CountryCodeEnum.GB.name ? dateTimeEuropeFormats : dateTimeFormats;

/**
 * Date time formats used throughout the app.
 * Ideally after changes this should not be exported and taken from {@link getLocationSpecificDateTimeFormats}
 */
export const dateTimeFormats = {
    summaryDateTimeSec: 'D MMM YYYY, HH:mm:ss',
    summaryDateTime: 'MMM D, YYYY HH:mm',
    summaryMonthDayYearTimeSec: 'MMM DD, YYYY HH:mm:ss',
    summaryDate: 'MMM D, YYYY',
    summaryDateWithoutYear: 'MMM D',
    shortDate: 'MM/DD/YY',
    monthYear: 'MM/YYYY',
    formDate: 'MM/DD/YYYY',
    formTime: 'HH:mm',
    formTimeSec: 'HH:mm:ss',
    formDateTime: 'MM/DD/YYYY HH:mm',
    formDateTimeSec: 'MM/DD/YYYY HH:mm:ss',
    tableDateTime: 'MM/DD/YY HH:mm',
    isoSecond: 'YYYY-MM-DDThh:mm:ssTZD',
    isoDateTime: 'YYYY-MM-DDThh:mm:ss',
    isoDate: 'YYYY-MM-DD',
    hour: 'HH00',
    longMonthYear: 'MMMM YYYY',
    dateTimeInSentence: 'MMM D, YYYY [at] HH:mm',
    dayOfWeek: 'dddd',
};

/**
 * Europe Date time formats used throughout the app.
 * exported for tests purposes
 */
export const dateTimeEuropeFormats: DateTimeFormats = {
    summaryDateTimeSec: 'D MMM YYYY, HH:mm:ss',
    summaryDateTime: 'D MMM, YYYY HH:mm',
    summaryMonthDayYearTimeSec: 'DD MMM, YYYY HH:mm:ss',
    summaryDate: 'D MMM, YYYY',
    summaryDateWithoutYear: 'D MMM',
    shortDate: 'DD/MM/YY',
    monthYear: 'MM/YYYY',
    formDate: 'DD/MM/YYYY',
    formTime: 'HH:mm',
    formTimeSec: 'HH:mm:ss',
    formDateTime: 'DD/MM/YYYY HH:mm',
    formDateTimeSec: 'DD/MM/YYYY HH:mm:ss',
    tableDateTime: 'DD/MM/YY HH:mm',
    isoSecond: 'YYYY-MM-DDThh:mm:ssTZD',
    isoDateTime: 'YYYY-MM-DDThh:mm:ss',
    isoDate: 'YYYY-MM-DD',
    hour: 'HH00',
    longMonthYear: 'MMMM YYYY',
    dateTimeInSentence: 'D MMM, YYYY [at] HH:mm',
    dayOfWeek: 'dddd',
};

/**
 * Function provides department location accepted date formats manually entered
 * @param countryCode department country code
 * @return list of accepted formats
 */
export const getLocationSpecificAcceptedDateTimeFormats = (countryCode: string | undefined) =>
    countryCode === CountryCodeEnum.GB.name ? acceptedDateEuropeFormats : acceptedDateFormats;

/**
 * Accepted input Date formats thath user can input.
 * Ideally after changes this should not be exported and taken from {@link getLocationSpecificAcceptedDateTimeFormats}
 */
const acceptedDateFormats: string[] = [
    dateTimeFormats.formDate,
    'MM/DD/YY',
    'MM-DD-YYYY',
    'MM-DD-YY',
    'MM.DD.YYYY',
    'MM.DD.YY',
    'MMDDYYYY',
    'MMDDYY',
];

/**
 * Accepted europe input Date formats thath user can input.
 */
const acceptedDateEuropeFormats: string[] = [
    dateTimeEuropeFormats.formDate,
    'DD/MM/YY',
    'DD-MM-YYYY',
    'DD-MM-YY',
    'DD.MM.YYYY',
    'DD.MM.YY',
    'DDMMYYYY',
    'DDMMYY',
];

export type DateTimeFormatter = {
    formatDate(date: OptionalMomentInput): string;
    formatDateTime(date: OptionalMomentInput): string;
    formatDateTimeSec(date: OptionalMomentInput): string;
    formatShortDate(date: OptionalMomentInput): string;
    formatLocalDate(date: OptionalMomentInput): string;
    formatSummaryDate(date: OptionalMomentInput): string;
    formatSummaryDateTime(date: OptionalMomentInput): string;
    formatDateTimeInSentence(date: OptionalMomentInput): string;
    formatSummaryDateWithoutYear(date: OptionalMomentInput): string;
    formatSummaryMonthDayYearTimeSec(date: OptionalMomentInput): string;
    formatTableDateTime(date: OptionalMomentInput): string;
    formatTime(date: OptionalMomentInput): string;
};

/**
 * Function provides department location date formatter besed on {@link getLocationSpecificDateTimeFormats}
 * @param countryCode department country code
 * @return object with format functions
 */
export const getLocationSpecificDateTimeFormatter = (countryCode: string | undefined) => {
    const locationSpecificDateTimeFormats = getLocationSpecificDateTimeFormats(countryCode);
    return {
        formatDate: partialRight(formatISODate, locationSpecificDateTimeFormats.formDate),
        formatDateTime: partialRight(formatISODate, locationSpecificDateTimeFormats.formDateTime),
        formatDateTimeSec: partialRight(
            formatISODate,
            locationSpecificDateTimeFormats.formDateTimeSec
        ),
        formatShortDate: partialRight(formatISODate, locationSpecificDateTimeFormats.shortDate),
        formatLocalDate: partialRight(formatISODate, locationSpecificDateTimeFormats.formDate),
        formatSummaryDate: partialRight(formatISODate, locationSpecificDateTimeFormats.summaryDate),
        formatSummaryDateTime: partialRight(
            formatISODate,
            locationSpecificDateTimeFormats.summaryDateTime
        ),
        formatDateTimeInSentence: partialRight(
            formatISODate,
            locationSpecificDateTimeFormats.dateTimeInSentence
        ),
        formatSummaryDateWithoutYear: partialRight(
            formatISODate,
            locationSpecificDateTimeFormats.summaryDateWithoutYear
        ),
        formatSummaryMonthDayYearTimeSec: partialRight(
            formatISODate,
            locationSpecificDateTimeFormats.summaryMonthDayYearTimeSec
        ),
        formatTableDateTime: partialRight(
            formatISODate,
            locationSpecificDateTimeFormats.tableDateTime
        ),
        formatTime: partialRight(formatISODate, locationSpecificDateTimeFormats.formTime),
    };
};

export const dateTimeDefaultFormatter = getLocationSpecificDateTimeFormatter(
    CountryCodeEnum.US.name
);

/**
 * The year after the current year.
 */
export function getNextYear() {
    return parseInt(moment().format('YYYY'), 10) + 1;
}

const formatTime = partialRight(formatISODate, dateTimeFormats.formTime);

export const formatSummaryDateTime = partialRight(formatISODate, dateTimeFormats.summaryDateTime);

/**
 * Active if start date is before now, end date is null or later than now
 * @param   startDateUtc  start date
 * @param   endDateUtc    end date
 * @return            is active or not
 */
export function isActive(startDateUtc: JSDateInput, endDateUtc: JSDateInput) {
    const now = new Date();
    const startDate = new Date(startDateUtc);
    return startDate < now && (!endDateUtc || new Date(endDateUtc) > now);
}

export function dateOfBirthToAge(dob: MomentInput, basisDate: MomentInput = nowUtc()) {
    return moment(basisDate).diff(moment(dob), 'years');
}

/**
 * Similar to getAdminListStatusFromStartEnd in dateHelpers but has been streamlined
 * and simplified due to performance reasons
 * It ignores the SCHEDULED stats
 * @param  end
 * @param  [now] Use this argument when computing a lot of statuses
 *   in order to reuse the same `Date` object.
 */
export function isExpired(end?: JSDateInput, now = new Date()) {
    if (!end) {
        return false;
    }
    const endDate = new Date(end);
    if (endDate < now) {
        return true;
    }
    return false;
}

/**
 * Compute the status (active, scheduled, or expired) for an admin list item
 *   based on the given dates. This function uses native `Date` objects rather
 *   than Moment objects for better performance, except in IE8 which has trouble
 *   parsing date formats.
 * @param  start
 * @param  [end]
 * @param   [optNow] Use this argument when computing a lot of statuses
 *   in order to reuse the same `Date` object.
 */
export function getAdminListStatusFromStartEnd(
    start: JSDateInput,
    end?: JSDateInput,
    optNow?: Date
) {
    const now = optNow || new Date();
    const startDate = new Date(start);

    if (startDate > now) {
        return attributeStatuses.SCHEDULED;
    } else if (end) {
        const endDate = new Date(end);
        if (endDate < now) {
            return attributeStatuses.EXPIRED;
        }
    }
    return attributeStatuses.ACTIVE;
}

/**
 * Compute the time passed since date param.
 * @param   date
 * @return  time passed since date param
 */
export function timeAgo(date: MomentInput) {
    return moment(date).fromNow();
}

export function timeAgoShort(date: MomentInput) {
    return timeAgo(date)
        .replace('years', 'yr.')
        .replace('months', 'mo.')
        .replace('minutes', 'min.')
        .replace('seconds', 'sec.');
}

/**
 * Adds the provided duration to the provided date.
 * @param   date
 * @param   duration
 * @return  ISO formatted date string
 */
export function addDuration(date: MomentInput, duration: Parameters<typeof moment.duration>[0]) {
    const dateObject = moment(date, moment.ISO_8601);
    const durationObject = moment.duration(duration);
    return dateObject.add(durationObject).toISOString();
}

/**
 * Converts the difference between two dates in to duration in months
 * @param   date1
 * @param   date2
 * @return  Duration in months
 */
export function dateDiffToDurationMonths(date1: MomentInput, date2: MomentInput) {
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return Math.floor(moment.duration(moment1.diff(moment2)).asMonths());
}

export function dateTimeRangeFormatter(
    startDateUtc?: OptionalMomentInput,
    endDateUtc?: OptionalMomentInput,
    dateTimeFormatter: DateTimeFormatter = dateTimeDefaultFormatter,
    { includeTime = true } = {}
) {
    if (!startDateUtc) {
        return;
    }

    const formatFunction = includeTime
        ? dateTimeFormatter.formatSummaryDateTime
        : dateTimeFormatter.formatSummaryDate;
    const start = moment(startDateUtc);
    const firstPart = formatFunction(startDateUtc);

    if (!!endDateUtc) {
        const end = moment(endDateUtc);
        const isSameDay = start.dayOfYear() === end.dayOfYear() && start.year() === end.year();
        let secondPart: string | null = null;

        if (isSameDay) {
            secondPart = includeTime ? `${formatTime(end)}` : '';
        } else {
            secondPart = formatFunction(endDateUtc);
        }
        return `${firstPart}${secondPart ? ' - ' : ''}${secondPart}`;
    }
    return `${firstPart}`;
}

/**
 * Max date in ISO string format, within an array of dates.
 * @param   {string|object} dates   Array of dates as strings or moments.
 * @return                  ISO string representation of the maximum moment.
 */
export function maxDate(dates: moment.Moment | MomentInput | (MomentInput | moment.Moment)[]) {
    if (!isArray(dates)) {
        return moment.isMoment(dates) ? dates.toISOString() : moment(dates).toISOString();
    } else {
        const dateMoments = map(dates, (date) => (moment.isMoment(date) ? date : moment(date)));
        return moment.max(dateMoments).toISOString();
    }
}

const STR_LENGTH_WITH_COLON = 5;
const STR_LENGTH_WITHOUT_COLON = STR_LENGTH_WITH_COLON - 1;
const TIME_PADDING_CHAR = '0';
const TIME_SEPARATOR = ':';
export function normalizeTimeString(timeString: string) {
    if (!timeString) {
        return timeString;
    }

    if (includes(timeString, TIME_SEPARATOR)) {
        return padStart(timeString, STR_LENGTH_WITH_COLON, TIME_PADDING_CHAR);
    }

    const padded = padStart(timeString, STR_LENGTH_WITHOUT_COLON, TIME_PADDING_CHAR);
    return `${padded.substr(0, 2)}${TIME_SEPARATOR}${padded.substr(2)}`;
}

export const START_OF_DAY_TIME = '00:00';
export const END_OF_DAY_TIME = '23:59';

// if the time string is of the from: "10:00" it cannot be passed above as it's not ISO-compatible
// instead use this
export function formatTimeOnly(_timeString: string) {
    const timeString = normalizeTimeString(_timeString);
    if (!_timeString) {
        return '';
    }
    const timeMoment = moment(timeString, dateTimeFormats.formTime);
    return timeMoment.format(dateTimeFormats.formTime);
}

export function dateToMonthYear(dateString: string, timeZone?: string) {
    const date = moment(dateString);
    if (timeZone) {
        date.tz(timeZone);
    }

    // `moment().month` is 0 indexed, therefore + 1
    return { month: date.month() + 1, year: date.year() };
}

export function dateToDMYProps(dateString: string, timeZone?: string) {
    const date = moment(dateString)
    if (timeZone) {
        date.tz(timeZone);
    }

    return { day: date.date(), month: date.month() + 1, year: date.year() };
}

export function formatDayMonthYear(day: number, month: number, year: number, format: string) {
    return moment()
        .set('date', day)
        .month(month - 1)
        .year(year)
        .format(format);
}

export function monthYearNumbersToLongMonthYear(month: number, year: number) {
    return moment()
        .month(month - 1)
        .year(year)
        .format(dateTimeFormats.longMonthYear);
}

export function applyTimeZoneToIsoDate(isoDate: string, timeZone: string) {
    if (timeZone) {
        return moment(isoDate).tz(timeZone).add(24, 'hours').startOf('month').toISOString();
    }
    return isoDate;
}

export function isoTimeGetFirstMomentOfMonthRegardlessTimezone(isoDate: string) {
    return moment(isoDate).add(24, 'hours').startOf('month').toISOString();
}

// if the year starts with a 0, it is clearly invalid and so we should not set that value
// this serves as a global validation that is not configurable
export function dateIsValid(date: string) {
    return moment(date).year() >= 1000;
}

export function isToday(date: string) {
    const momentDate = moment(date);
    return momentDate.isSame(moment(), 'days');
}

export function isYesterday(date: string) {
    const momentDate = moment(date);
    return momentDate.isSame(moment().subtract(1, 'days'), 'days');
}

export function isTomorrow(date: string) {
    const momentDate = moment(date);
    return momentDate.isSame(moment().add(1, 'days'), 'days');
}

export function isCurrentWeek(date: string) {
    const momentDate = moment(date);
    return momentDate.isSame(moment(), 'weeks');
}

export function convertIsoDurationFormatToFutureDate(duration: string) {
    const months = Number(moment.duration(duration).asMonths());
    return moment().add(months, 'months');
}

export function calculateDateDifferenceFromNow(
    date: string,
    unit: moment.unitOfTime.Diff,
    precise = true
) {
    return moment(date).diff(moment(), unit, precise);
}

export function getIsoDateFromNDaysAgo(days: number) {
    return moment().subtract(days, 'days').toISOString();
}

export function getIsoDateFromNHoursAgo(hours: number) {
    return moment().subtract(hours, 'hours').toISOString();
}

export function getIsoDateFromNMonthsAgo(months: number) {
    return moment().subtract(months, 'months').toISOString();
}

export function getEarliestDate(dates: string[]) {
    if (dates.length === 0) {
        return;
    }

    return dates.reduce((prev, curr) => {
        if (!prev || (curr && isAfter(prev, curr))) {
            return curr;
        }
        return prev;
    });
}

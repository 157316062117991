import { createSelector } from 'reselect';
import { EntityTypeEnumType, NarrativeGuide } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getNarrativeGuidesResource from '../../resources/narrativeGuidesResource';
import { ClientCommonAction } from '../../../../../redux/types';

const NEXUS_STATE_PROP = 'narrativeGuides';
const narrativeGuidesModule = createNormalizedModule<NarrativeGuide>({
    type: NEXUS_STATE_PROP,
});

export const LOAD_NARRATIVE_GUIDES_START = 'narrative-guides/LOAD_NARRATIVE_GUIDES_START';
export const LOAD_NARRATIVE_GUIDES_SUCCESS = 'narrative-guides/LOAD_NARRATIVE_GUIDES_SUCCESS';
export const LOAD_NARRATIVE_GUIDES_FAILURE = 'narrative-guides/LOAD_NARRATIVE_GUIDES_FAILURE';
export const SAVE_NARRATIVE_GUIDE_START = 'narrative-guides/SAVE_NARRATIVE_GUIDE_START';
export const SAVE_NARRATIVE_GUIDE_SUCCESS = 'narrative-guides/SAVE_NARRATIVE_GUIDE_SUCCESS';
export const SAVE_NARRATIVE_GUIDE_FAILURE = 'narrative-guides/SAVE_NARRATIVE_GUIDE_FAILURE';
export const REMOVE_NARRATIVE_GUIDE_START = 'narrative-guides/REMOVE_NARRATIVE_GUIDE_START';
export const REMOVE_NARRATIVE_GUIDE_SUCCESS = 'narrative-guides/REMOVE_NARRATIVE_GUIDE_SUCCESS';
export const REMOVE_NARRATIVE_GUIDE_FAILURE = 'narrative-guides/REMOVE_NARRATIVE_GUIDE_FAILURE';

// ACTIONS

const deleteNarrativeGuides = narrativeGuidesModule.actionCreators.deleteEntity;

function loadNarrativeGuidesStart() {
    return {
        type: LOAD_NARRATIVE_GUIDES_START,
    } as const;
}

function loadNarrativeGuidesSuccess(narrativeGuides: NarrativeGuide[]) {
    return {
        type: LOAD_NARRATIVE_GUIDES_SUCCESS,
        payload: narrativeGuides,
    } as const;
}

export function loadNarrativeGuidesFailure(errMsg: string) {
    return { type: LOAD_NARRATIVE_GUIDES_FAILURE, payload: errMsg } as const;
}

function saveNarrativeGuideStart() {
    return { type: SAVE_NARRATIVE_GUIDE_START } as const;
}

function saveNarrativeGuideSuccess() {
    return { type: SAVE_NARRATIVE_GUIDE_SUCCESS } as const;
}

export function saveNarrativeGuideFailure(errMsg: string) {
    return { type: SAVE_NARRATIVE_GUIDE_FAILURE, error: true, payload: errMsg } as const;
}

function removeNarrativeGuideStart() {
    return { type: REMOVE_NARRATIVE_GUIDE_START } as const;
}

function removeNarrativeGuideSuccess() {
    return { type: REMOVE_NARRATIVE_GUIDE_SUCCESS } as const;
}

function removeNarrativeGuideFailure(errMsg: string) {
    return { type: REMOVE_NARRATIVE_GUIDE_FAILURE, error: true, payload: errMsg } as const;
}

export function loadNarrativeGuidesWithoutCatch(): ClientCommonAction<Promise<void>> {
    const narrativeGuidesResource = getNarrativeGuidesResource();
    return function (dispatch, getState, { nexus }) {
        dispatch(loadNarrativeGuidesStart());
        return narrativeGuidesResource
            .getNarrativeGuides()
            .then((narrativeGuides: NarrativeGuide[]) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: narrativeGuides,
                        },
                        loadNarrativeGuidesSuccess(narrativeGuides)
                    )
                );
            });
    };
}

export function loadNarrativeGuides(): ClientCommonAction<void> {
    return function (dispatch) {
        return dispatch(loadNarrativeGuidesWithoutCatch()).catch((err: Error) =>
            dispatch(loadNarrativeGuidesFailure(err.message))
        );
    };
}

export function saveNarrativeGuide(
    narrativeGuide: NarrativeGuide
): ClientCommonAction<Promise<NarrativeGuide>> {
    return function (dispatch, getState, { nexus }) {
        dispatch(saveNarrativeGuideStart());
        const resource = getNarrativeGuidesResource();
        return resource
            .upsertNarrativeGuide(narrativeGuide)
            .then((narrativeGuide: NarrativeGuide) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: [narrativeGuide],
                        },
                        saveNarrativeGuideSuccess()
                    )
                );
                return narrativeGuide;
            });
    };
}

export function removeNarrativeGuide(narrativeGuideId: string): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        dispatch(removeNarrativeGuideStart());
        const resource = getNarrativeGuidesResource();
        const id = parseInt(narrativeGuideId);
        return resource
            .deleteNarrativeGuide(id)
            .then((isSuccessfulDelete: boolean) => {
                if (isSuccessfulDelete) {
                    dispatch(deleteNarrativeGuides(id));
                    dispatch(removeNarrativeGuideSuccess());
                } else {
                    dispatch(removeNarrativeGuideFailure('Failed to delete Narrative Guide'));
                }
            })
            .catch((err: Error) => dispatch(removeNarrativeGuideFailure(err.message)));
    };
}

// SELECTORS
export const narrativeGuidesSelector = narrativeGuidesModule.selectors.entitiesSelector;
export const narrativeGuidesByIdSelector = narrativeGuidesModule.selectors.entityByIdSelector;

export const narrativeGuidesByEntityTypeSelecor = createSelector(
    narrativeGuidesSelector,
    (narrativeGuides) => (entityType: EntityTypeEnumType) =>
        Object.values(narrativeGuides).filter(({ availableIn }) => availableIn === entityType)
);

// REDUCER
export default narrativeGuidesModule.reducerConfig;

import Promise from 'bluebird';

// this bootstrapPromise is used to defer actions which require 'bootstrap data'
// (such as a user profile or attributes data) from taking place until after
// bootstrap data is available
let isBootstrapped = null; // eslint-disable-line
let bootstrapPromiseResolver = null;
let bootstrapPromiseResolved = false;
function getBootstrapResolver() {
    if (bootstrapPromiseResolved === false && bootstrapPromiseResolver) {
        return bootstrapPromiseResolver;
    } else {
        bootstrapPromiseResolved = false;
        isBootstrapped = new Promise((res) => {
            bootstrapPromiseResolver = res;
        }).then(() => {
            bootstrapPromiseResolved = true;
        });
        return bootstrapPromiseResolver;
    }
}
// run once to initialize the value
getBootstrapResolver();

export { isBootstrapped, getBootstrapResolver };

import { Store } from 'redux';
import { ElasticProperty } from '@mark43/rms-api';
import { iconPaths } from '@arc/icon';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { sortPropertyStatuses } from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import { formatTitleForPropertyRecord } from '../../../../../../../legacy-redux/helpers/propertyRecordHelpers';
import { FormatMentionItemMapper, Mention } from '../types';
import { getDefaultAvatarIcon, getMentionEntityLinkUrl } from '../utils';

const strings = componentStrings.core.Editor.plugins.mentionsForBriefing;

const getExtraInfo = (isInPoliceCustody?: boolean) => [
    `${strings.inPoliceCustody}: ${isInPoliceCustody ? strings.yes : strings.no}`,
];

const prepareMentionedItem = (item: ElasticProperty, store: Store<RootState>): Mention => {
    const formatAttributeById = formatAttributeByIdSelector(store.getState());
    const isFirearm = item.itemTypeAttrId === globalAttributes.itemType.firearm;

    const name = formatTitleForPropertyRecord(
        {
            itemCategoryAttrId: formatAttributeById(item.itemCategoryAttrId),
            description: item.description,
            firearmMakeAttrId: formatAttributeById(item.firearmMakeAttrId),
            atfManufacturer: item.property.atfManufacturerName,
        },
        false
    );

    const sortedProperties = sortPropertyStatuses(item.propertyStatuses).reverse();
    const isInPoliceCustody = sortedProperties.length
        ? sortedProperties[0].isInPoliceCustody
        : false;

    return {
        id: item.id.toString(),
        name,
        text: name,
        descriptions: item.description ? [item.description] : undefined,
        extraInfo: getExtraInfo(isInPoliceCustody),
        category: 'PROPERTY',
        photo: item.primaryProfilePhotoPath,
        defaultIcon: getDefaultAvatarIcon(isFirearm ? iconPaths.Firearm : iconPaths.Property),
        url: getMentionEntityLinkUrl({ itemType: 'PROPERTY', itemId: item.id }),
    };
};

const formatMapper: FormatMentionItemMapper<ElasticProperty> = {
    resourceMethod: 'searchProperty',
    prepareMentionedItem,
};

export default formatMapper;

import styled from 'styled-components';
import { Note } from '../../../core/components/Note';

export { Note } from '../../../core/components/Note';

export const RightColumnNote = styled(Note)`
    margin-bottom: 16px;
`;

export const RightColumnNoteTopMargin = styled(Note)`
    margin-top: 16px;
`;

// the exports in this script are in alphabetical order
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import rangeFieldKeyEnum from '~/client-common/core/enums/client/rangeFieldKeyEnum';
import rangeFieldTypeEnum from '~/client-common/core/enums/client/rangeFieldTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../helpers/formHelpers';
import { mustBeInt } from '../validation/helpers/reactReduxFormFieldValidators';
import { subdivisionAttrFieldsForViewModels } from '../../modules/search/core/state/forms/subdivisionFields';

const {
    ATTRIBUTE,
    CASE_STATUS,
    CLIENT_APPROVAL_STATUS,
    CUSTOM_REPORT_CLASSIFICATION,
    DATE,
    DATE_PRESET,
    AGENCY,
    DEPARTMENT,
    FIELDSET,
    GANG_NAME,
    LABEL,
    LINK_TYPE,
    N_FIELDSETS,
    N_ITEMS_FIELDSET,
    NIBRS_CODE,
    NIBRS_CODE_ID,
    OFFENSE_CASE_STATUS,
    OFFENSE_CODE,
    OFFICER_INVOLVEMENT,
    RANGE,
    REPORT_DEFINITION,
    UCR_CODE,
    USER,
    VEHICLE_MAKE,
    VEHICLE_MODEL,
    ATF_MANUFACTURER,
} = fieldTypeClientEnum;
const {
    AGE_RANGE,
    DATE_TIME_RANGE,
    HEIGHT_RANGE,
    TIME_RANGE,
    VEHICLE_YEAR_RANGE,
    WEIGHT_RANGE,
    YEARS_OF_SERVICE_RANGE,
} = rangeFieldKeyEnum;
const { RANGE_START, RANGE_END, WITHIN_LAST_PERIOD, TO_DATE_PERIOD } = rangeFieldTypeEnum;
const strings = componentStrings.search.fieldsets;

/**
 * View model for the `CadTicketFieldset` component.
 * @type {Object}
 */
export const cadTicketFieldsetViewModel = {
    type: FIELDSET,
    key: 'cadTicket',
    title: strings.CadTicketFieldset.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'withinLastPeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: WITHIN_LAST_PERIOD,
        },
        {
            key: 'toDatePeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: TO_DATE_PERIOD,
        },
        {
            key: 'startDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_START,
            label: strings.CadTicketFieldset.labels.dateTimeRange,
        },
        {
            key: 'endDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_END,
        },
        {
            key: 'agencyEventNumbers',
            type: N_ITEMS_FIELDSET,
            filterLabel: strings.CadTicketFieldset.filterLabels.agencyEventNumber,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'agencyEventNumber',
                    label: strings.CadTicketFieldset.labels.agencyEventNumber,
                },
            ]),
        },
        // When removing CAD_MULTI_REN_GENERATION_ENABLED FF, delete reportingEventNumbers
        {
            key: 'reportingEventNumbers',
            type: N_ITEMS_FIELDSET,
            fieldNameForFilterLabel: fields.REPORT_REPORTING_EVENT_NUMBER,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'reportingEventNumber',
                    fieldName: fields.REPORT_REPORTING_EVENT_NUMBER,
                },
            ]),
        },
        {
            key: 'rens',
            type: N_ITEMS_FIELDSET,
            fieldNameForFilterLabel: fields.REPORT_REPORTING_EVENT_NUMBER,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'ren',
                    fieldName: fields.REPORT_REPORTING_EVENT_NUMBER,
                },
            ]),
        },
        {
            key: 'agencyIds',
            fieldName: fields.REPORT_AGENCY_ID,
            fieldNameForFilterLabel: fields.REPORT_AGENCY_ID,
            type: AGENCY,
        },
        {
            key: 'departmentIds',
            fieldName: fields.REPORT_DEPARTMENT_ID,
            fieldNameForFilterLabel: fields.REPORT_DEPARTMENT_ID,
            type: DEPARTMENT,
        },
        { key: 'subdivisionAttrIds', type: ATTRIBUTE },
        ...subdivisionAttrFieldsForViewModels,
        {
            key: 'callTypeAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.CAD_TICKET_EVENT_TYPE,
        },
        {
            key: 'callEventTypeAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.CAD_TICKET_SECONDARY_EVENT_TYPE,
        },
        {
            key: 'callerPhoneNumber',
            fieldName: fields.CAD_TICKET_CALLER_PHONE_NUMBER,
        },
        {
            key: 'hasReport',
            fieldName: fields.DISPLAY_ONLY_HAS_REPORT_LABEL,
        },
        {
            key: 'requiresReport',
            fieldName: fields.DISPLAY_ONLY_REQUIRES_REPORT_LABEL,
        },
        {
            key: 'primaryUnitCallSigns',
            fieldName: fields.DISPLAY_ONLY_PRIMARY_UNITS_IDS_LABEL,
        },
        {
            key: 'involvedUnitCallSigns',
            fieldName: fields.DISPLAY_ONLY_INVOLVED_UNITS_IDS_LABEL,
        },
        {
            key: 'primaryOfficerIds',
            fieldName: fields.DISPLAY_ONLY_PRIMARY_OFFICERS_IDS_LABEL,
            type: USER,
        },
        {
            key: 'involvedOfficerIds',
            type: USER,
            fieldName: fields.DISPLAY_ONLY_PERSONNEL_INVOLVED_OFFICERS_LABEL,
        },
    ]),
};

/**
 * View model for the `ClothingFieldset` component.
 * @type {Object}
 */
export const clothingFieldsetViewModel = {
    type: N_ITEMS_FIELDSET,
    key: 'clothing',
    title: strings.ClothingFieldset.title,
    filterLabel: strings.ClothingFieldset.filterLabel,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'clothingTypeAttrId',
            type: ATTRIBUTE,
            fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_CLOTHING_TYPE_ATTRIBUTE_ID,
        },
        {
            key: 'description',
            fieldName: fields.DISPLAY_ONLY_PERSON_CLOTHING_DESCRIPTION_LABEL,
        },
    ]),
};

/**
 * View model for the `IdentifiersFieldset` component.
 * @type {Object}
 */
export const identifiersFieldsetViewModel = {
    type: FIELDSET,
    key: 'identifiers',
    title: strings.IdentifiersFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'fbiUcn',
                fieldName: fields.PERSON_PROFILE_FBI_UCN,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_FBI_UCN,
            },
            {
                key: 'stateIdNumber',
                fieldName: fields.PERSON_PROFILE_STATE_ID_NUMBER,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_STATE_ID_NUMBER,
            },
            {
                key: 'ssn',
                fieldName: fields.PERSON_PROFILE_SSN,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_SSN,
            },
            {
                key: 'religionAttrId',
                fieldName: fields.PERSON_PROFILE_RELIGION_ATTR_ID,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_RELIGION_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                key: 'sexualOrientationAttrId',
                fieldName: fields.PERSON_PROFILE_SEXUAL_ORIENTATION_ATTR_ID,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_SEXUAL_ORIENTATION_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                key: 'croId',
                fieldName: fields.PERSON_PROFILE_CRO_ID,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_CRO_ID,
            },
            {
                key: 'pncId',
                fieldName: fields.PERSON_PROFILE_PNC_ID,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_PNC_ID,
            },
            {
                key: 'nationalIdNumber',
                fieldName: fields.PERSON_PROFILE_NATIONAL_ID_NUMBER,
                fieldNameForFilterLabel: fields.PERSON_PROFILE_NATIONAL_ID_NUMBER,
            },
            {
                key: 'dlTypeAttrId',
                type: ATTRIBUTE,
                fieldName: fields.USER_PROFILE_DRIVERS_LICENSE_TYPE_ATTR_ID,
            },
            {
                key: 'dlNumber',
                fieldName: fields.USER_PROFILE_DRIVERS_LICENSE,
            },
            {
                key: 'phoneNumber',
                fieldName: fields.NAME_PHONE_NUMBER_PHONE_NUMBER,
            },
            {
                key: 'masterPersonId',
                fieldName: fields.PERSON_PROFILE_ID,
            },
        ]),
        nameIdentifiers: {
            type: N_ITEMS_FIELDSET,
            key: 'nameIdentifiers',
            filterLabel: strings.IdentifiersFieldset.NameIdentifiersNItems.filterLabel,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'nameIdentifierTypeAttrId',
                    type: ATTRIBUTE,
                    fieldName: fields.NAME_IDENTIFIER_NAME_IDENTIFIER_TYPE_ATTR_ID,
                },
                {
                    key: 'idValue',
                    fieldName: fields.DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL,
                },
            ]),
        },
        ids: {
            type: N_ITEMS_FIELDSET,
            key: 'ids',
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'mniValue',
                    fieldName: fields.PERSON_PROFILE_ID,
                },
            ]),
        },
        passports: {
            type: N_ITEMS_FIELDSET,
            key: 'passports',
            filterLabel: strings.IdentifiersFieldset.Passports.filterLabel,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'number',
                    fieldName: fields.PASSPORT_NUMBER,
                    fieldNameForFilterLabel: fields.PASSPORT_NUMBER,
                },
            ]),
        },
    },
};

/**
 * View model for the `ItemIdentifiersFieldset` component.
 * @type {Object}
 */
export const itemIdentifiersFieldsetViewModel = {
    type: FIELDSET,
    key: 'itemIdentifiers',
    title: strings.IdentifiersFieldset.title,
    fields: {
        itemIdentifiers: {
            type: N_ITEMS_FIELDSET,
            key: 'itemIdentifiers',
            filterLabel: strings.IdentifiersFieldset.NameIdentifiersNItems.filterLabel,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'itemIdentifierTypeAttrId',
                    type: ATTRIBUTE,
                    fieldName: fields.ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_ATTR_ID,
                },
                {
                    key: 'identifier',
                    fieldName: fields.DISPLAY_ONLY_ITEM_IDENTIFIER_LABEL,
                },
            ]),
        },
    },
};

/**
 * View model for the `IdentifyingMarksFieldset` component.
 * @type {Object}
 */
export const identifyingMarksFieldsetViewModel = {
    type: N_ITEMS_FIELDSET,
    key: 'identifyingMarks',
    title: strings.IdentifyingMarksFieldset.title,
    filterLabel: strings.IdentifyingMarksFieldset.filterLabel,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'identifyingMarkTypeAttrId',
            type: ATTRIBUTE,
            fieldName: fields.IDENTIFYING_MARK_IDENTIFYING_MARK_TYPE_ATTR_ID,
        },
        {
            key: 'bodyPartAttrId',
            type: ATTRIBUTE,
            fieldName: fields.IDENTIFYING_MARK_BODY_PART_ATTR_ID,
        },
        {
            key: 'description',
            fieldName: fields.DISPLAY_ONLY_PERSON_IDENTIFYING_MARK_DESCRIPTION_LABEL,
        },
    ]),
};

/**
 * View model for the `LocationFieldset` component.
 * @type {Object}
 */
const locationFieldsetViewModel = {
    type: FIELDSET,
    key: 'location',
    title: strings.LocationFieldset.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'streetAddress',
            fieldName: fields.LOCATION_VIEW_STREET_ADDRESS,
        },
        {
            key: 'subPremise',
            fieldName: fields.LOCATION_ENTITY_LINK_SUB_PREMISE,
        },
        {
            key: 'locality',
            fieldName: fields.LOCATION_VIEW_LOCALITY,
        },
        {
            key: 'administrativeAreaLevel1',
            fieldName: fields.LOCATION_VIEW_ADMIN_AREA_1,
        },
        {
            key: 'administrativeAreaLevel2',
            fieldName: fields.LOCATION_VIEW_ADMIN_AREA_2,
        },
        {
            key: 'neighborhood',
            fieldName: fields.LOCATION_VIEW_NEIGHBORHOOD,
        },
        {
            key: 'postalCode',
            fieldName: fields.LOCATION_VIEW_POSTAL_CODE,
        },
        {
            key: 'country',
            fieldName: fields.LOCATION_VIEW_COUNTRY,
        },
        {
            key: 'crossStreet1',
            fieldName: fields.LOCATION_VIEW_CROSS_STREET_1,
        },
        {
            key: 'crossStreet2',
            fieldName: fields.LOCATION_VIEW_CROSS_STREET_2,
        },
        { key: 'subdivisionAttrIds', type: ATTRIBUTE },
        ...subdivisionAttrFieldsForViewModels,
        {
            key: 'type',
            type: LINK_TYPE,
            fieldName: fields.DISPLAY_ONLY_LOCATION_INVOLVEMENT_LABEL,
        },
        {
            key: 'categoryAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.LOCATION_ENTITY_LINK_TYPE_ATTR_ID,
        },
    ]),
};

/**
 * View model for the `LocationsFieldset` component.
 * @type {Object}
 */
export const locationsFieldsetViewModel = {
    fields: {
        ...locationFieldsetViewModel.fields,
        geoRadiusQuery: {
            type: FIELDSET,
            key: 'geoRadiusQuery',
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'lat',
                    fieldName: fields.LOCATION_BIAS_CENTER_LAT,
                },
                {
                    key: 'lon',
                    fieldName: fields.LOCATION_BIAS_CENTER_LNG,
                },
                {
                    key: 'radiusInMeters',
                    fieldName: fields.LOCATION_BIAS_RADIUS_METERS,
                },
            ]),
        },
        geoPolygonQuery: {
            type: N_ITEMS_FIELDSET,
            key: 'geoPolygonQuery',
            filterLabel: strings.LocationsFieldset.labels.geoPolygonQuery.filterLabel,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'lat',
                    label: strings.LocationsFieldset.labels.geoPolygonQuery.lat,
                },
                {
                    key: 'lon',
                    label: strings.LocationsFieldset.labels.geoPolygonQuery.lon,
                },
            ]),
        },
    },
    type: N_FIELDSETS,
    key: 'locations',
    title: strings.LocationsFieldset.title,
};

/**
 * View model for the `OrganizationFieldset` component.
 * @type {Object}
 */
export const organizationFieldsetViewModel = {
    type: FIELDSET,
    key: 'organization',
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'name',
                fieldName: fields.ORGANIZATION_PROFILE_NAME,
                label: strings.OrganizationFieldset.labels.name,
            },
            { key: 'phoneNumber', label: strings.OrganizationFieldset.labels.phoneNumber },
            {
                key: 'involvement',
                type: LINK_TYPE,
                label: strings.OrganizationFieldset.labels.involvement,
            },
            {
                key: 'organizationTypeAttrIds',
                type: ATTRIBUTE,
                label: strings.OrganizationFieldset.labels.typeAttrIds,
            },
            {
                key: 'industryAttrIds',
                type: ATTRIBUTE,
                label: strings.OrganizationFieldset.labels.industryAttrIds,
            },
        ]),
        locations: {
            ...locationsFieldsetViewModel,
            type: N_ITEMS_FIELDSET,
            // singular string, not function, because only 1 location can be
            // entered
            filterLabel: strings.LocationFieldset.title,
        },
    },
};

/**
 * View model for the `OrganizationsFieldset` component.
 * @type {Object}
 */
export const organizationsFieldsetViewModel = {
    ...organizationFieldsetViewModel,
    type: N_FIELDSETS,
    key: 'organizations',
};

/**
 * View model for the `PersonDetailsFieldset` component.
 * @type {Object}
 */
export const personDetailsFieldsetViewModel = {
    type: FIELDSET,
    key: 'personDetails',
    title: strings.PersonDetailsFieldset.title,
    fields: buildFlatFormFieldViewModels([
        { key: 'firstName', fieldName: fields.PERSON_PROFILE_FIRST_NAME },
        { key: 'lastName', fieldName: fields.PERSON_PROFILE_LAST_NAME },
        {
            key: 'sexAttrId',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_SEX_ATTR_ID,
        },
        {
            key: 'isDead',
            fieldName: fields.PERSON_PROFILE_IS_DEAD,
        },
        {
            key: 'isAllegedGangMember',
            fieldName: fields.PERSON_PROFILE_IS_ALLEGED_GANG_MEMBER,
        },
        {
            key: 'hasPotentialActiveWarrant',
            fieldName: fields.DISPLAY_ONLY_PERSON_HAS_POTENTIAL_ACTIVE_WARRANT_LABEL,
        },
        {
            key: 'nickname',
            fieldName: fields.NAME_MONIKER_MONIKER,
            fieldNameForFilterLabel: fields.NAME_MONIKER_MONIKER,
        },
        {
            key: 'dateOfBirth',
            type: DATE,
            fieldName: fields.DISPLAY_ONLY_PERSON_DOB_LABEL,
        },
        {
            key: 'dateOfBirthOther',
            type: DATE,
            fieldName: fields.NAME_MONIKER_DATE_OF_BIRTH_OTHER,
            fieldNameForFilterLabel: fields.NAME_MONIKER_DATE_OF_BIRTH_OTHER,
        },
        { key: 'ageRangeStart', type: RANGE, rangeKey: AGE_RANGE, rangeType: RANGE_START },
        { key: 'ageRangeEnd', type: RANGE, rangeKey: AGE_RANGE, rangeType: RANGE_END },
        {
            key: 'involvement',
            type: LINK_TYPE,
            fieldName: fields.DISPLAY_ONLY_PERSON_INVOLVEMENT_LABEL,
        },
        {
            key: 'wasFrisked',
            fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_WAS_FRISKED,
            fieldNameForFilterLabel:
                fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_FIELD_CONTACT_WAS_FRISKED,
        },
    ]),
};

/**
 * View model for the `PersonInjuriesFieldset` component.
 * @type {Object}
 */
export const personInjuriesFieldsetViewModel = {
    type: N_ITEMS_FIELDSET,
    key: 'injuries',
    title: strings.PersonInjuriesFieldset.title,
    filterLabel: strings.PersonInjuriesFieldset.filterLabel,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'injuryCategoryAttrId',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_INJURY_INJURY_TYPE_ATTR_ID,
        },
        {
            key: 'bodyPartAttrId',
            type: ATTRIBUTE,
            fieldName: fields.IDENTIFYING_MARK_BODY_PART_ATTR_ID,
        },
        {
            key: 'description',
            fieldName: fields.DISPLAY_ONLY_PERSON_INJURIES_DESCRIPTION_LABEL,
        },
    ]),
};

/**
 * View model for the `PersonnelFieldset` component.
 * @type {Object}
 */
export const personnelFieldsetViewModel = {
    type: FIELDSET,
    key: 'personnel',
    title: strings.PersonnelFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'currentSubmissionOwnerIds',
                type: USER,
                fieldName: fields.DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL,
            },
            {
                key: 'personnelUnitAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
            },
            {
                key: 'authorIds',
                type: USER,
                fieldName: fields.DISPLAY_ONLY_PERSONNEL_AUTHORS_LABEL,
            },
            {
                key: 'reviewerIds',
                type: USER,
                fieldName: fields.DISPLAY_ONLY_PERSONNEL_REVIEWERS_LABEL,
            },
        ]),
        involvedOfficers: {
            type: N_ITEMS_FIELDSET,
            key: 'involvedOfficers',
            filterLabel:
                componentStrings.search.AdvancedSearchReportsForm.filterLabels.involvedOfficers,
            fields: buildFlatFormFieldViewModels([
                {
                    key: 'assistTypeAttrId',
                    type: ATTRIBUTE,
                    fieldName: fields.DISPLAY_ONLY_PERSONNEL_INVOLVEMENT_TYPE_LABEL,
                },
                {
                    key: 'officerInvolvement',
                    type: OFFICER_INVOLVEMENT,
                },
                {
                    key: 'officerIds',
                    type: USER,
                    fieldName: fields.DISPLAY_ONLY_PERSONNEL_INVOLVED_OFFICERS_LABEL,
                },
            ]),
        },
    },
};

/**
 * View model for the `PhysicalAttributesFieldset` component.
 * @type {Object}
 */
export const physicalAttributesFieldsetViewModel = {
    type: FIELDSET,
    key: 'physicalAttributes',
    title: strings.PhysicalAttributesFieldset.title,
    fields: buildFlatFormFieldViewModels([
        {
            key: 'raceAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_RACE_ATTR_ID,
            fieldNameForFilterLabel: fields.PERSON_PROFILE_RACE_ATTR_ID,
        },
        {
            key: 'ethnicityAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_ETHNICITY_ATTR_ID,
        },
        {
            key: 'buildAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_BUILD_ATTR_ID,
        },
        {
            key: 'hairColorAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_HAIR_COLOR_ATTR_ID,
        },
        {
            key: 'hairStyleAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_HAIR_STYLE_ATTR_ID,
        },
        {
            key: 'facialHairTypeAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_FACIAL_HAIR_TYPE_ATTR_ID,
        },
        {
            key: 'eyeColorAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_EYE_COLOR_ATTR_ID,
        },
        {
            key: 'visionAttrIds',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_VISION_ATTR_ID,
        },
        {
            key: 'physicalCharacteristicAttrIds',
            type: ATTRIBUTE,
            grouped: true,
            fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_PHYSICAL_CHARACTERISTIC_ATTRIBUTE_ID,
        },
        {
            key: 'weightRangeMin',
            type: RANGE,
            rangeKey: WEIGHT_RANGE,
            rangeType: RANGE_START,
        },
        {
            key: 'weightRangeMax',
            type: RANGE,
            rangeKey: WEIGHT_RANGE,
            rangeType: RANGE_END,
        },
        {
            key: 'heightRangeMin',
            type: RANGE,
            rangeKey: HEIGHT_RANGE,
            rangeType: RANGE_START,
        },
        {
            key: 'heightRangeMax',
            type: RANGE,
            rangeKey: HEIGHT_RANGE,
            rangeType: RANGE_END,
        },
        {
            key: 'isPregnantAttrId',
            type: ATTRIBUTE,
            fieldName: fields.PERSON_PROFILE_IS_PREGNANT_ATTR_ID,
            fieldNameForFilterLabel: fields.PERSON_PROFILE_IS_PREGNANT_ATTR_ID,
        },
        {
            key: 'isUnborn',
            fieldName: fields.PERSON_PROFILE_IS_UNBORN,
            fieldNameForFilterLabel: fields.PERSON_PROFILE_IS_UNBORN,
        },
    ]),
};

const buildDateRangeFields = (label) =>
    buildFlatFormFieldViewModels([
        {
            key: 'withinLastPeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: WITHIN_LAST_PERIOD,
            label,
        },
        {
            key: 'toDatePeriod',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: TO_DATE_PERIOD,
            label,
        },
        {
            key: 'startDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_START,
            label,
        },
        {
            key: 'endDateUtc',
            type: RANGE,
            rangeKey: DATE_TIME_RANGE,
            rangeType: RANGE_END,
            label,
        },
    ]);

/**
 * View model for the `PropertyFieldset` component.
 * @type {Object}
 */
export const propertyFieldsetViewModel = {
    type: FIELDSET,
    key: 'property',
    title: strings.PropertyFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'itemCategoryAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.DISPLAY_ONLY_PROPERTY_CATEGORY_LABEL,
            },
            {
                key: 'propertyStatusAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID,
            },
            {
                key: 'description',
                fieldName: fields.DISPLAY_ONLY_PROPERTY_DESCRIPTION_LABEL,
            },
            {
                key: 'serialNumber',
                fieldName: fields.ITEM_PROFILE_SERIAL_NUMBER,
            },
            {
                key: 'hasSerialNumber',
                fieldName: fields.DISPLAY_ONLY_PROPERTY_HAS_SERIAL_NUMBER_LABEL,
            },
            {
                key: 'primaryColorAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_ITEM_PRIMARY_COLOR_ATTR_ID,
            },
            {
                key: 'firearmMakeAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.FIREARM_FIREARM_MAKE_ATTR_ID,
            },
            {
                key: 'atfManufacturerIds',
                type: ATF_MANUFACTURER,
                fieldName: fields.FIREARM_ATF_MANUFACTURER_ID,
            },
            {
                key: 'itemTypeAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.DISPLAY_ONLY_PROPERTY_TYPE_LABEL,
            },
            {
                key: 'departmentIds',
                fieldName: fields.REPORT_DEPARTMENT_ID,
                fieldNameForFilterLabel: fields.REPORT_DEPARTMENT_ID,
                type: DEPARTMENT,
            },
        ]),
        updatedDateRangeQuery: {
            type: FIELDSET,
            key: 'updatedDateRangeQuery',
            fields: buildDateRangeFields(strings.PropertiesFieldset.labels.modifiedDateRange),
        },
        statusDateRangeQuery: {
            type: FIELDSET,
            key: 'statusDateRangeQuery',
            fields: buildDateRangeFields(strings.PropertiesFieldset.labels.statusDateRange),
        },
        itemIdentifiers: itemIdentifiersFieldsetViewModel,
    },
};

/**
 * View model for the `PropertiesFieldset` component.
 * @type {Object}
 */
export const propertiesFieldsetViewModel = {
    ...propertyFieldsetViewModel,
    type: N_FIELDSETS,
    key: 'properties',
    title: strings.PropertiesFieldset.title,
};

/**
 * View model for the `ReportDetailsFieldset` component. It has a deep structure
 *   that is meant for the advanced search reports form.
 * @type {Object}
 */
export const reportDetailsFieldsetViewModel = {
    type: FIELDSET,
    key: 'reportDetails',
    title: strings.ReportDetailsFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'dateType',
                type: DATE_PRESET,
                fieldName: fields.DISPLAY_ONLY_DATE_TYPE_LABEL,
            },
            {
                key: 'withinLastPeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: WITHIN_LAST_PERIOD,
            },
            {
                key: 'toDatePeriod',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: TO_DATE_PERIOD,
            },
            {
                key: 'startDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_START,
            },
            {
                key: 'endDateUtc',
                type: RANGE,
                rangeKey: DATE_TIME_RANGE,
                rangeType: RANGE_END,
            },
            {
                key: 'startTime',
                type: RANGE,
                rangeKey: TIME_RANGE,
                rangeType: RANGE_START,
            },
            {
                key: 'endTime',
                type: RANGE,
                rangeKey: TIME_RANGE,
                rangeType: RANGE_END,
            },
            {
                key: 'reportingEventNumber',
                fieldNameForFilterLabel: fields.REPORT_REPORTING_EVENT_NUMBER,
                label: '', // label is directly passed in to ReportDetailsFieldset from fieldConfigs (don't use fieldStrings.js)
            },
            {
                key: 'globalSequenceNumber',
            },
            {
                key: 'subdivisionAttrIds',
                type: ATTRIBUTE,
            },
            ...subdivisionAttrFieldsForViewModels,
            {
                key: 'clientApprovalStatuses',
                type: CLIENT_APPROVAL_STATUS,
                fieldName: fields.REPORT_STATUS_HISTORY_APPROVAL_STATUS,
                fieldNameForFilterLabel: fields.REPORT_STATUS_HISTORY_APPROVAL_STATUS,
            },
            {
                key: 'routingLabelAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.DISPLAY_ONLY_REPORT_DETAILS_LABELS,
            },
            {
                key: 'hasRoutingLabel',
                type: LABEL,
            },
            {
                key: 'eventStatisticAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_EVENT_STATISTICS_ATTRIBUTE_ID,
            },
            {
                key: 'hasCustomReportClassificationAttrId',
                type: CUSTOM_REPORT_CLASSIFICATION,
                fieldName:
                    fields.DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
            },
            {
                key: 'customReportClassificationAttrIds',
                type: CUSTOM_REPORT_CLASSIFICATION,
                fieldName:
                    fields.DISPLAY_ONLY_REPORT_DETAILS_INCIDENT_OR_OFFENSE_CLASSIFICATION_LABEL,
            },
            { key: 'hasUcrCode', type: UCR_CODE },
            {
                key: 'ucrCodes',
                type: UCR_CODE,
                fieldName: fields.REPORT_UCR_UCR_SUMMARY_CODE,
            },
            {
                key: 'ucrGroup',
                fieldName: fields.DISPLAY_ONLY_REPORT_DETAILS_UCR_GROUP_LABEL,
            },
            {
                key: 'agencyIds',
                fieldName: fields.REPORT_AGENCY_ID,
                fieldNameForFilterLabel: fields.REPORT_AGENCY_ID,
                type: AGENCY,
            },
            {
                key: 'departmentIds',
                fieldName: fields.REPORT_DEPARTMENT_ID,
                fieldNameForFilterLabel: fields.REPORT_DEPARTMENT_ID,
                type: DEPARTMENT,
            },
            { key: 'hasCaseStatus', type: CASE_STATUS },
            {
                key: 'caseStatusAttrId',
                type: CASE_STATUS,
                fieldName: fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
                fieldNameForFilterLabel: fields.REPORT_CASE_STATUS_CASE_STATUS_ATTR_ID,
            },
            {
                key: 'securityClassificationAttrId',
                type: ATTRIBUTE,
                fieldName: fields.SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
                fieldNameForFilterLabel:
                    fields.SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID,
            },
            { key: 'hasOffenseCaseStatus', type: OFFENSE_CASE_STATUS },
            {
                key: 'offenseCaseStatusAttrId',
                type: OFFENSE_CASE_STATUS,
                fieldName: fields.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
                fieldNameForFilterLabel: fields.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID,
            },
            {
                key: 'narrative',
                fieldName: fields.REPORT_NARRATIVE,
            },
            {
                key: 'isSchoolRelated',
                fieldName: fields.EVENT_DETAIL_INVOLVES_SCHOOL_INCIDENT,
                fieldNameForFilterLabel: fields.EVENT_DETAIL_INVOLVES_SCHOOL_INCIDENT,
            },
            {
                key: 'isArrestOrCustodialRelatedDeath',
                fieldName: fields.EVENT_DETAIL_ARREST_OR_CUSTODIAL_RELATED_DEATH,
                fieldNameForFilterLabel: fields.EVENT_DETAIL_ARREST_OR_CUSTODIAL_RELATED_DEATH,
            },
        ]),
        reportDefinitions: {
            type: N_ITEMS_FIELDSET,
            key: 'reportDefinitions',
            filterLabel:
                componentStrings.search.AdvancedSearchReportsForm.filterLabels.reportDefinitions,
            fields: {
                ...buildFlatFormFieldViewModels([
                    {
                        key: 'reportDefinitionId',
                        type: REPORT_DEFINITION,
                        fieldName: fields.REPORT_REPORT_DEFINITION_ID,
                    },
                    {
                        key: 'fieldContactTypeAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.FIELD_CONTACT_CONTACT_TYPE_ATTR_ID,
                        fieldNameForFilterLabel: fields.FIELD_CONTACT_CONTACT_TYPE_ATTR_ID,
                    },
                    {
                        key: 'fieldContactDispositionAttrIds',
                        type: ATTRIBUTE,
                        fieldName:
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_ATTRIBUTE_ID,
                        fieldNameForFilterLabel:
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_FIELD_CONTACT_DISPOSITION_ATTRIBUTE_ID,
                    },
                    {
                        key: 'offenseNibrsGroups',
                        fieldName: fields.DISPLAY_ONLY_UCR_CODES_LABEL,
                    },
                    // deprecated field
                    {
                        key: 'offenseNibrsCodes',
                        type: NIBRS_CODE,
                        fieldName: fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE,
                    },
                    {
                        key: 'offenseNibrsCodeIds',
                        type: NIBRS_CODE_ID,
                        fieldName: fields.DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE,
                    },
                    {
                        key: 'offenseUcrGroups',
                        label: strings.OffenseFieldSet.labels.offenseUcrGroups,
                    },
                    {
                        key: 'offenseHasUcrCode',
                        type: UCR_CODE,
                        label: strings.OffenseFieldSet.labels.offenseHasUcrCode,
                    },
                    {
                        key: 'offenseUcrCodes',
                        type: UCR_CODE,
                        fieldName: fields.DISPLAY_ONLY_UCR_CODE_LABEL,
                    },
                    {
                        key: 'offenseIncidentOffenseCodeIds',
                        type: OFFENSE_CODE,
                        fieldName: fields.DISPLAY_ONLY_OFFENSE_INCIDENT_CODE_LABEL,
                    },
                    {
                        key: 'offenseWeaponOrForceInvolvedAttrIds',
                        type: ATTRIBUTE,
                        fieldName:
                            fields.OFFENSE_ATTRIBUTE_ATTRIBUTE_TYPE_WEAPON_OR_FORCE_INVOLVED_ATTRIBUTE_ID,
                        fieldNameForFilterLabel: fields.DISPLAY_ATTRIBUTE_WEAPON_OR_FORCE_INVOLVED,
                    },
                    {
                        key: 'offenseModusOperandiAttrIds',
                        type: ATTRIBUTE,
                        grouped: true,
                        fieldName: fields.DISPLAY_ATTRIBUTE_MODUS_OPERANDI,
                        fieldNameForFilterLabel: fields.DISPLAY_ATTRIBUTE_MODUS_OPERANDI,
                    },
                    {
                        key: 'offenseIsDomesticViolence',
                        fieldName: fields.OFFENSE_IS_DOMESTIC_VIOLENCE,
                        fieldNameForFilterLabel: fields.OFFENSE_IS_DOMESTIC_VIOLENCE,
                    },
                    {
                        key: 'offenseIsSuspectedHateCrime',
                        fieldName: fields.OFFENSE_IS_SUSPECTED_HATE_CRIME,
                        fieldNameForFilterLabel: fields.OFFENSE_IS_SUSPECTED_HATE_CRIME,
                    },
                    {
                        key: 'offenseIsLeoka',
                        fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_LEOKA,
                        fieldNameForFilterLabel:
                            fields.NAME_REPORT_LINK_LINK_TYPE_VICTIM_IN_OFFENSE_IS_LEOKA,
                    },
                    {
                        key: 'offenseNfibNumber',
                        fieldName: fields.OFFENSE_NFIB_NUMBER,
                        fieldNameForFilterLabel: fields.OFFENSE_NFIB_NUMBER,
                    },
                    {
                        key: 'arrestOffenseCodeIds',
                        type: OFFENSE_CODE,
                        fieldName: fields.OFFENSE_OFFENSE_CODE_ID,
                    },
                    {
                        key: 'arrestLocalId',
                        fieldName: fields.ARREST_LOCAL_ID,
                        fieldNameForFilterLabel: fields.ARREST_LOCAL_ID,
                    },
                    {
                        key: 'arrestTypeAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.OFFENSE_CODE_ARREST_TYPE_ATTR_ID,
                    },
                    {
                        key: 'arresteeArmedWithAttrIds',
                        type: ATTRIBUTE,
                        fieldName:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARRESTEE_WAS_ARMED_WITH_ATTRIBUTE_ID,
                        fieldNameForFilterLabel:
                            fields.ARREST_ATTRIBUTE_ATTRIBUTE_TYPE_ARRESTEE_WAS_ARMED_WITH_ATTRIBUTE_ID,
                    },
                    {
                        key: 'hasWarrants',
                        fieldName: fields.DISPLAY_ONLY_HAS_WARRANTS_LABEL,
                    },
                    {
                        key: 'isDetention',
                        fieldName: fields.ARREST_IS_DETENTION,
                    },
                    // use of force fields
                    {
                        key: 'useOfForceReasonAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.DISPLAY_ONLY_TYPE_OF_INCIDENT_LABEL,
                    },
                    {
                        key: 'useOfForceStatisticsAttrIds',
                        type: ATTRIBUTE,
                        fieldName:
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_USE_OF_FORCE_STATISTICS_ATTRIBUTE_ID,
                    },
                    {
                        key: 'userProfileIds',
                        type: USER,
                        fieldName: fields.DISPLAY_ONLY_OFFICER_LABEL,
                    },
                    {
                        key: 'officerSexAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.USE_OF_FORCE_OFFICER_SEX_ATTR_ID,
                    },
                    {
                        key: 'officerRaceAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.USE_OF_FORCE_OFFICER_RACE_ATTR_ID,
                    },
                    {
                        key: 'officerYearsOfServiceMin',
                        type: RANGE,
                        rangeKey: YEARS_OF_SERVICE_RANGE,
                        rangeType: RANGE_START,
                    },
                    {
                        key: 'officerYearsOfServiceMax',
                        type: RANGE,
                        rangeKey: YEARS_OF_SERVICE_RANGE,
                        rangeType: RANGE_END,
                    },
                    {
                        key: 'dateOfBirth',
                        type: DATE,
                        fieldName: fields.DISPLAY_ONLY_OFFICER_DOB_LABEL,
                    },
                    {
                        key: 'ageRangeStart',
                        type: RANGE,
                        rangeKey: AGE_RANGE,
                        rangeType: RANGE_START,
                    },
                    { key: 'ageRangeEnd', type: RANGE, rangeKey: AGE_RANGE, rangeType: RANGE_END },
                    {
                        key: 'useOfForceSubjectDetailsAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.DISPLAY_ONLY_SUBJECT_DETAILS_LABEL,
                    },
                    {
                        key: 'useOfForceSubjectActionsAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.DISPLAY_ONLY_SUBJECT_ACTIONS_LABEL,
                    },
                    {
                        key: 'useOfForceOfficerForceTowardsSubjectAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.DISPLAY_ONLY_OFFICER_USE_OF_FORCE_SUBJECT_LABEL,
                    },
                    // tow vehicle
                    {
                        key: 'reasonForTowAttrIds',
                        type: ATTRIBUTE,
                        fieldName:
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_TOW_VEHICLE_REASON_FOR_TOW_ATTRIBUTE_ID,
                        fieldNameForFilterLabel:
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_TOW_VEHICLE_REASON_FOR_TOW_ATTRIBUTE_ID,
                    },
                    // Community info fields
                    {
                        key: 'communityInformationDispositionAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.DISPLAY_ONLY_COMMUNITY_INFORMATION_DISPOSITION_LABEL,
                    },
                    // Supplement Info fields
                    {
                        key: 'supplementTypeAttrIds',
                        type: ATTRIBUTE,
                        fieldName:
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_SUPPLEMENT_TYPE_ATTRIBUTE_ID,
                    },
                    // missing persons
                    {
                        key: 'missingPersonTypeAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.MISSING_PERSON_MISSING_PERSON_TYPE_ATTR_ID,
                        fieldNameForFilterLabel: fields.MISSING_PERSON_MISSING_PERSON_TYPE_ATTR_ID,
                    },
                    {
                        key: 'missingPersonStatusAttrIds',
                        type: ATTRIBUTE,
                        fieldName: fields.MISSING_PERSON_CLOSURE_STATUS_ATTR_ID,
                        fieldNameForFilterLabel: fields.MISSING_PERSON_CLOSURE_STATUS_ATTR_ID,
                    },
                    // dragon search fields
                    {
                        key: 'keywords',
                        label:
                            componentStrings.search.AdvancedSearchReportsForm.dragon.keywordField,
                    },
                ]),
                reportConfiguredEntityProperties: {
                    key: 'reportConfiguredEntityProperties',
                    type: N_ITEMS_FIELDSET,
                    fields: buildFlatFormFieldViewModels([
                        {
                            key: 'configuredEntityPropertyDescriptor',
                        },
                        {
                            key: 'configuredEntityPropertyInputValue',
                        },
                    ]),
                },
            },
        },
    },
};

const buildCautionsFieldsetViewModel = (cautionAttrIdsFieldName) => ({
    type: FIELDSET,
    key: 'cautions',
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'cautionAttrIds',
                type: ATTRIBUTE,
                fieldName: cautionAttrIdsFieldName,
            },
            {
                key: 'includeInactive',
                fieldName: fields.DISPLAY_ONLY_INCLUDE_INACTIVE_LABEL,
            },
        ]),
        activeWithinRange: {
            type: FIELDSET,
            key: 'activeWithinRange',
            fields: buildDateRangeFields(strings.CautionsFieldset.activeWithinRange),
        },
    },
});

export const vehicleCautionsFieldsetViewModel = buildCautionsFieldsetViewModel(
    fields.CAUTION_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_CAUTION_ATTR_ID
);
export const personCautionsFieldsetViewModel = buildCautionsFieldsetViewModel(
    fields.CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_CAUTION_ATTR_ID
);

/**
 * View model for the `BehaviorsFieldset` component.
 * @type {Object}
 */
export const behaviorsFieldsetViewModel = {
    type: FIELDSET,
    key: 'behaviors',
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'behavioralCharacteristicAttrIds',
                type: ATTRIBUTE,
                grouped: true,
                fieldName:
                    fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_BEHAVIORAL_CHARACTERISTIC_ATTRIBUTE_ID,
            },
            { key: 'modusOperandiAttrIds', type: ATTRIBUTE, grouped: true },
            {
                key: 'moodAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.NAME_ATTRIBUTE_ATTRIBUTE_TYPE_MOOD_ATTRIBUTE_ID,
            },
            {
                key: 'personLabelAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.DISPLAY_ONLY_PERSON_LABEL,
            },
        ]),
        personGangTrackings: {
            type: N_ITEMS_FIELDSET,
            key: 'personGangTrackings',
            title: 'Person Gang Trackings',
            filterLabel: strings.PersonGangTrackingsFieldset.filterLabel,
            fields: buildFlatFormFieldViewModels([
                { key: 'isExpired', type: GANG_NAME },
                { key: 'gangNameAttrIds', type: ATTRIBUTE },
                { key: 'gangSubgroupAttrIds', type: ATTRIBUTE },
            ]),
        },
        cautions: personCautionsFieldsetViewModel,
    },
};

/**
 * View model for the `VehicleFieldset` component.
 * @type {Object}
 */
export const vehicleFieldsetViewModel = {
    type: FIELDSET,
    key: 'vehicle',
    title: strings.VehicleFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            { key: 'vehicleMakeAttrIds', type: ATTRIBUTE },
            {
                key: 'vehicleMakeIds',
                type: VEHICLE_MAKE,
                fieldName: fields.VEHICLE_VEHICLE_MAKE_ID,
            },
            { key: 'vehicleModelAttrIds', type: ATTRIBUTE, grouped: true },
            {
                key: 'vehicleModelIds',
                type: VEHICLE_MODEL,
                fieldName: fields.VEHICLE_VEHICLE_MODEL_ID,
            },
            {
                key: 'vehicleMakeOther',
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_ITEM_MAKE,
            },
            {
                key: 'vehicleModelOther',
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_ITEM_MODEL,
            },
            { key: 'tag', fieldName: fields.VEHICLE_TAG },
            {
                key: 'registrationStateAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.DISPLAY_ONLY_VEHICLE_REGISTRATION_STATE_LABEL,
            },
            { key: 'vinNumber', fieldName: fields.VEHICLE_VIN_NUMBER },
            {
                key: 'yearOfManufacture',
                validators: {
                    mustBeIntError: mustBeInt,
                },
                fieldName: fields.VEHICLE_YEAR_OF_MANUFACTURE,
            },
            {
                key: 'minYearOfManufacture',
                type: RANGE,
                rangeKey: VEHICLE_YEAR_RANGE,
                rangeType: RANGE_START,
            },
            {
                key: 'maxYearOfManufacture',
                type: RANGE,
                rangeKey: VEHICLE_YEAR_RANGE,
                rangeType: RANGE_END,
            },
            {
                key: 'description',
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_DESCRIPTION,
            },
            {
                key: 'itemCategoryAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.DISPLAY_ONLY_VEHICLE_TYPE_LABEL,
            },
            {
                key: 'bodyStyleAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.VEHICLE_BODY_STYLE_ATTR_ID,
            },
            {
                key: 'primaryColorAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.ITEM_PROFILE_ITEM_TYPE_VEHICLE_PRIMARY_COLOR_ATTR_ID,
            },
            {
                key: 'propertyStatusAttrIds',
                type: ATTRIBUTE,
                fieldName: fields.PROPERTY_STATUS_PROPERTY_STATUS_ATTR_ID,
            },
            {
                key: 'vehicleLabelAttrIds',
                type: ATTRIBUTE,
                label: strings.VehicleFieldset.labels.vehicleCautionAttrIds,
                fieldName:
                    fields.ITEM_ATTRIBUTE_ATTRIBUTE_TYPE_VEHICLE_LABEL_ATTRIBUTES_ATTRIBUTE_ID,
            },
        ]),
        itemIdentifiers: itemIdentifiersFieldsetViewModel,
        cautions: vehicleCautionsFieldsetViewModel,
    },
};

/**
 * View model for the `VehiclesFieldset` component.
 * @type {Object}
 */
export const vehiclesFieldsetViewModel = {
    ...vehicleFieldsetViewModel,
    type: N_FIELDSETS,
    key: 'vehicles',
    title: strings.VehiclesFieldset.title,
};

/**
 * View model for the `PersonsFieldset` component, which contains other
 *   fieldsets.
 * @type {Object}
 */
export const personsFieldsetViewModel = {
    type: N_FIELDSETS,
    key: 'persons',
    title: strings.PersonsFieldset.title,
    fields: {
        personDetails: personDetailsFieldsetViewModel,
        physicalAttributes: physicalAttributesFieldsetViewModel,
        injuries: personInjuriesFieldsetViewModel,
        identifyingMarks: identifyingMarksFieldsetViewModel,
        clothing: clothingFieldsetViewModel,
        behaviors: behaviorsFieldsetViewModel,
        identifiers: identifiersFieldsetViewModel,
    },
};

/**
 * Fields that appear in the report definition fieldset when offense/incident is
 *   selected.
 * @type {string[]}
 */
export const reportDefinitionOffenseFieldList = [
    'offenseNibrsGroups',
    'offenseNibrsCodes',
    'offenseNibrsCodeIds',
    'offenseUcrGroups',
    'offenseHasUcrCode',
    'offenseUcrCodes',
    'offenseIncidentOffenseCodeIds',
    'offenseWeaponOrForceInvolvedAttrIds',
    'offenseModusOperandiAttrIds',
    'offenseIsDomesticViolence',
    'offenseIsSuspectedHateCrime',
    'offenseIsLeoka',
    'offenseCaseStatusAttrId',
    'offenseNfibNumber',
    'hasOffenseCaseStatus',
];

/**
 * Fields that appear in the report definition fieldset when arrest is selected.
 * These are then used to populate the arrest query.
 * @type {string[]}
 */
export const reportDefinitionArrestFieldList = [
    'arrestTypeAttrIds',
    'arrestLocalId',
    'isDetention',
];

/**
 * Fields that appear in the report definition fieldset when arrest is selected.
 * These are then used to populate the charges query.
 * @type {string[]}
 */
export const reportDefinitionChargeFieldList = ['arrestOffenseCodeIds'];

/**
 * Fields specific to Use of Force
 * that appear in the report definition fieldset when Use of Force is selected.
 * @type {string[]}
 */
export const reportDefinitionUseOfForceFieldList = [
    'useOfForceReasonAttrIds',
    'useOfForceStatisticsAttrIds',
    'userProfileIds',
    'officerSexAttrIds',
    'officerRaceAttrIds',
    'officerYearsOfServiceMin',
    'officerYearsOfServiceMax',
    'dateOfBirth',
    'ageRangeStart',
    'ageRangeEnd',
];

/**
 * Fields specific to Use of Force Subject
 * that appear in the report definition fieldset when Use of Force is selected.
 * @type {string[]}
 */
export const reportDefinitionUseOfForceSubjectFieldList = [
    'useOfForceSubjectDetailsAttrIds',
    'useOfForceSubjectActionsAttrIds',
    'useOfForceOfficerForceTowardsSubjectAttrIds',
];

/**
 * Fields that appear in the report definition fieldset when Tow Vehicle is selected.
 * @type {string[]}
 */
export const reportDefinitionTowVehicleFieldList = ['reasonForTowAttrIds'];

/**
 * Fields that appear in the report definition fieldset when Supplement is selected.
 * @type {string[]}
 */
export const reportDefinitionSupplementFieldList = ['supplementTypeAttrIds'];

/**
 * Fields that appear in the report definition fieldset when Missing Persons is selected.
 * @type {string[]}
 */
export const reportDefinitionMissingPersonsFieldList = [
    'missingPersonTypeAttrIds',
    'missingPersonStatusAttrIds',
];

/**
 * View model for the `RecordPrivacyFieldset` component.
 */
export const courtOrdersFieldsetViewModel = {
    type: FIELDSET,
    key: 'courtOrders',
    title: strings.CourtOrdersFieldset.title,
    fields: {
        ...buildFlatFormFieldViewModels([
            {
                key: 'courtCaseNumber',
                fieldName: fields.COURT_ORDER_COURT_CASE_NUMBER,
            },
            {
                key: 'involvedPersonFullName',
                fieldName: fields.COURT_ORDER_INVOLVED_PERSON_FULL_NAME,
            },
            {
                key: 'courtCaseDateRangeQuery',
                type: FIELDSET,
                fields: buildDateRangeFields(strings.CourtOrdersFieldset.labels.courtCaseDateRange),
            },
            {
                key: 'courtCodeAttrId',
                fieldName: fields.COURT_ORDER_COURT_CODE_ATTR_ID,
                type: ATTRIBUTE,
            },
            {
                key: 'arrestNumber',
                fieldName: fields.DISPLAY_ONLY_ARREST_ID_NUMBER,
            },
            {
                key: 'stateIdNumber',
                fieldName: fields.COURT_ORDER_STATE_ID_NUMBER,
            },
            {
                key: 'modifiedDateRangeQuery',
                type: FIELDSET,
                fields: buildDateRangeFields(strings.CourtOrdersFieldset.labels.modifiedDateRange),
            },
        ]),
    },
};

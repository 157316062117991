import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';

import { omit } from 'lodash';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import Row from '../../../core/components/Row';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';

import connectWarrantsSidePanelForm from '../state/forms/connectWarrantsSidePanelForm';

const strings = componentStrings.warrants.connectWarrants;

const OrContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px 0;
`;

const OrBar = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
    display: inline-block;
    width: 40%;
`;

const OrOrb = styled.div`
    display: inline-block;
    font-size: var(--arc-fontSizes-lg);
`;

export default function ConnectWarrantsSearchForm({
    onClickQuickSearch,
    onClickDetailSearch,
    formModel,
}) {
    return (
        <ReactReduxForm {...connectWarrantsSidePanelForm}>
            <Row>
                <RRFText path="warrantNumber" label={strings.labels.warrantNumber} />
            </Row>
            <Row>
                <Button
                    onClick={onClickQuickSearch}
                    className={buttonTypes.PRIMARY}
                    disabled={!formModel.warrantNumber}
                    testId={testIds.CONNECT_WARRANT_SIDE_PANEL_SEARCH_WARRANT_NUM_BUTTON}
                >
                    {strings.search}
                </Button>
            </Row>
            <OrContainer>
                <OrBar />
                <OrOrb>OR</OrOrb>
                <OrBar />
            </OrContainer>
            <Row>
                <RRFDatePicker
                    label={strings.labels.startDateUtc}
                    includeTime={true}
                    path={'warrantIssuedStartDateUtc'}
                />
            </Row>
            <Row>
                <RRFDatePicker
                    label={strings.labels.endDateUtc}
                    includeTime={true}
                    path={'warrantIssuedEndDateUtc'}
                />
            </Row>
            <Row>
                <RRFAttributeSelect
                    label={strings.labels.warrantTypeAttrIds}
                    path={'warrantTypeAttrIds'}
                    multiple={true}
                    attributeType={AttributeTypeEnum.WARRANT_TYPE.name}
                    width={240}
                />
            </Row>
            <Row>
                <RRFUserSelect
                    label={strings.labels.obtainingOfficerIds}
                    multiple={true}
                    path={'obtainingOfficerIds'}
                    width={360}
                />
            </Row>
            <Row>
                <Button
                    onClick={onClickDetailSearch}
                    className={buttonTypes.PRIMARY}
                    disabled={formDataIsEmpty(omit(formModel, 'warrantNumber'))}
                    testId={testIds.CONNECT_WARRANT_SIDE_PANEL_SEARCH_WARRANT_BUTTON}
                >
                    {strings.search}
                </Button>
            </Row>
        </ReactReduxForm>
    );
}

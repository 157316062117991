import { map } from 'lodash';
import { DepartmentStatusEnumType } from '@mark43/rms-api';
import {
    storeFieldConfigurations,
    fieldConfigurationsSelector,
    ClientFieldConfiguration,
} from '~/client-common/core/domain/field-configurations/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import type { ErrorConfig } from '../../modules/core/errors/types';
import { currentUserHasAbilitySelector } from '../../modules/core/current-user/state/ui';
import { shouldShowFieldNamesSelector } from '../selectors/globalSelectors';
import { RmsAction } from '../../core/typings/redux';
import actionTypes from './types/globalActionTypes';

export function windowResize(hw: { height: number; width: number }) {
    return {
        type: actionTypes.WINDOW_RESIZE,
        payload: hw,
    };
}

export function showLoadingBar(loadingBarVisible: boolean) {
    return {
        type: actionTypes.SHOW_HIDE_LOADING_BAR,
        payload: loadingBarVisible,
    };
}

export function showLoadingMask(loadingMaskVisible: boolean) {
    return {
        type: actionTypes.SHOW_HIDE_LOADING_MASK,
        payload: loadingMaskVisible,
    };
}

export function setDepartmentStatusIndicator(departmentStatus: DepartmentStatusEnumType) {
    return {
        type: actionTypes.SET_DEPARTMENT_STATUS_INDICATOR,
        payload: departmentStatus,
    };
}

export function setAdditionalContentTop(top: number) {
    return {
        type: actionTypes.SET_ADDITIONAL_CONTENT_TOP,
        payload: top,
    };
}

export function setGlobalError(
    errorConfig: ErrorConfig = {
        errorCode: undefined,
        errorId: undefined,
    }
) {
    return {
        type: actionTypes.SET_GLOBAL_ERROR,
        payload: errorConfig,
    };
}

export function toggleShowFieldNames() {
    return {
        type: actionTypes.TOGGLE_FIELD_NAMES,
    };
}

export function setOverrideCreateReportModalV2(override: boolean) {
    return {
        type: actionTypes.SET_OVERRIDE_CREATE_REPORT_MODAL_V2,
        payload: override,
    };
}

/**
 * Toggle every FieldConfiguration.displayName between its original value and its fieldName.
 */
export function toggleFieldDisplayNames(): RmsAction<Record<string, unknown> | void> {
    return (dispatch, getState) => {
        const state = getState();
        if (!currentUserHasAbilitySelector(state)(abilitiesEnum.ADMIN.VIEW_VALIDATION)) {
            return;
        }

        const allFieldConfigs = fieldConfigurationsSelector(state);
        const shouldShowFieldNames = shouldShowFieldNamesSelector(state);
        const newFieldConfigs = map(
            allFieldConfigs,
            (fieldConfig): ClientFieldConfiguration => {
                const { originalDisplayName, displayName, fieldName } = fieldConfig;

                return {
                    ...fieldConfig,
                    originalDisplayName: displayName,
                    displayName: shouldShowFieldNames
                        ? // insert zero-width spaces &#8203; for long field names to wrap
                          fieldName.replace(/_/g, `_${String.fromCharCode(8203)}`)
                        : originalDisplayName,
                };
            }
        );
        return dispatch(storeFieldConfigurations(newFieldConfigs));
    };
}

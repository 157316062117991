import React from 'react';
import _, { map } from 'lodash';
import classNames from 'classnames';
import testIds from '../../../../core/testIds';

export default function SearchFilters({ searchFilters }) {
    const filterGroups = _(searchFilters)
        .values()
        .map(({ title, fields }, index) => (
            <SearchFilterGroup
                key={`${index}~${title}`}
                title={title}
                fields={fields}
                isRowLast={(index + 1) % 3 === 0}
            />
        ))
        .chunk(3)
        .map((filterGroup, index) => (
            <div key={index} className="filter-group-row clearfix">
                {filterGroup}
            </div>
        ))
        .value();

    return (
        <div className="search-filters">
            {filterGroups.length ? (
                <div className="filters-section clearfix">{filterGroups}</div>
            ) : undefined}
        </div>
    );
}

function SearchFilterGroup({ title, fields, isRowLast }) {
    const filters = map(fields, (field, index) => <SearchFilterField {...field} key={index} />);
    return (
        <div
            className={classNames('filter-group', { 'row-last': isRowLast })}
            data-test-id={testIds.ADVANCED_SEARCH_FILTERS_SECTION}
        >
            <div className="filter-group-title clearfix">
                <div className="title-text">{title}</div>
            </div>
            <div className="filter-group-filters clearfix">{filters}</div>
        </div>
    );
}

function SearchFilterField({ label, display }) {
    return (
        <div className="filter clearfix">
            <div className="filter-text">
                <span className="label">{`${label}: `}</span>
                <span className="display">{display}</span>
            </div>
        </div>
    );
}

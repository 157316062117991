import { createSelector } from 'reselect';
import groupBy from 'lodash/groupBy';
import {
    EntityTypeEnumType,
    AttributeTypeEnum,
    LinkTypesEnum,
    EntityTypeEnum,
    TasksView,
    TaskEntityLinkView,
    TaskComment,
    Attachment,
    FileUploadResponse,
} from '@mark43/rms-api';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import {
    NEXUS_STATE_PROP as TASK_VIEWS_NEXUS_STATE_PROP,
    fetchTasksSuccess,
} from '~/client-common/core/domain/taskViews/state/data';
import { NEXUS_STATE_PROP as TASK_ENTITY_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/task-entity-links/state/data';
import { NEXUS_STATE_PROP as TASK_COMMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/task-comments/state/data';
import { convertFileUploadResponsesToAttachmentLinks } from '~/client-common/core/domain/attachments/state/ui';
import {
    NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP,
    attributesByTypeSelector,
    activeAttributesByTypeSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import {
    NEXUS_STATE_PROP as ATTACHMENTS_NEXUS_STATE_PROP,
    attachmentsWhereSelector,
} from '~/client-common/core/domain/attachments/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { uploadedFilesSelector } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import { UNPERSISTED_ENTITY_ID } from '../../../attachments/core/configuration';
import taskResource from '../resources/taskResource';
import { RmsAction } from '../../../../core/typings/redux';

export function fetchAndStoreEntityTasks(
    entityId: number,
    entityType: EntityTypeEnumType
): RmsAction<Promise<TasksView>> {
    return function (dispatch) {
        return taskResource.getTasksForEntity(entityType, entityId).then((taskView) => {
            dispatch(withTasksView(taskView));

            return taskView;
        });
    };
}

export function withTasksView(tasksView: Partial<TasksView>): RmsAction<void> {
    return function (dispatch, getState, { nexus }) {
        const { tasks, taskEntityLinks, attributes, attachments, taskComments } = tasksView;
        const attributeViews = attributes?.map(convertAttributeToAttributeView) ?? [];
        const taskIds = tasks?.map((item) => item.id) ?? [];
        dispatch(
            nexus.withEntityItems(
                {
                    [TASK_VIEWS_NEXUS_STATE_PROP]: tasks,
                    [TASK_COMMENTS_NEXUS_STATE_PROP]: taskComments,
                    [TASK_ENTITY_LINKS_NEXUS_STATE_PROP]: taskEntityLinks,
                    [ATTRIBUTES_NEXUS_STATE_PROP]: attributeViews,
                    [ATTACHMENTS_NEXUS_STATE_PROP]: attachments,
                },
                nexus.withRemove(
                    TASK_ENTITY_LINKS_NEXUS_STATE_PROP,
                    (taskEntityLink: TaskEntityLinkView) => taskIds.includes(taskEntityLink.taskId),
                    nexus.withRemove(
                        TASK_COMMENTS_NEXUS_STATE_PROP,
                        (taskComment: TaskComment) => taskIds.includes(taskComment.taskId),
                        fetchTasksSuccess()
                    )
                )
            )
        );
    };
}

export function withComment(newComment: TaskComment): RmsAction<void> {
    return function (dispatch, getState, { nexus }) {
        dispatch(
            nexus.withEntityItems(
                {
                    [TASK_COMMENTS_NEXUS_STATE_PROP]: [newComment],
                },
                fetchTasksSuccess()
            )
        );
    };
}

export const pendingTaskStatusAttributeIdSelector = createSelector(
    attributesByTypeSelector,
    (attributesByType) => {
        const attributes = attributesByType(AttributeTypeEnum.TASK_STATUS.name, {
            parentAttributeId: globalAttributes.taskStatusGlobal.pending,
        });
        return attributes?.[0]?.id;
    }
);

export const inProgressTaskStatusAttributeIdSelector = createSelector(
    attributesByTypeSelector,
    (attributesByType) => {
        const attributes = attributesByType(AttributeTypeEnum.TASK_STATUS.name, {
            parentAttributeId: globalAttributes.taskStatusGlobal.inProgress,
        });
        return attributes?.[0]?.id;
    }
);

export const completedTaskStatusAttributeIdSelector = createSelector(
    attributesByTypeSelector,
    (attributesByType) => {
        const attributes = attributesByType(AttributeTypeEnum.TASK_STATUS.name, {
            parentAttributeId: globalAttributes.taskStatusGlobal.completed,
        });
        return attributes?.[0]?.id;
    }
);

export const unpersistedTaskAttachmentsSelector = createSelector(
    uploadedFilesSelector,
    (
        uploadedFiles: (FileUploadResponse & {
            config: Pick<Attachment, 'entityType' | 'linkType'> & { entityId: number | string };
        })[]
    ) => {
        const unpersistedEntityUploads = uploadedFiles.filter((uploadedFile) => {
            const {
                config: { entityId, entityType, linkType },
            } = uploadedFile;

            return (
                entityId === UNPERSISTED_ENTITY_ID &&
                entityType === EntityTypeEnum.TASK.name &&
                linkType === LinkTypesEnum.TASK_ATTACHMENT
            );
        });
        return convertFileUploadResponsesToAttachmentLinks(unpersistedEntityUploads, {
            entityType: EntityTypeEnum.TASK.name,
            linkType: LinkTypesEnum.TASK_ATTACHMENT,
        });
    }
);

const taskAttachmentsSelector = createSelector(attachmentsWhereSelector, (attachmentsWhere) => {
    return attachmentsWhere({
        entityType: EntityTypeEnum.TASK.name,
        linkType: LinkTypesEnum.TASK_ATTACHMENT,
    });
});

export const taskAttachmentsMapSelector = createSelector(
    taskAttachmentsSelector,
    (taskAttachments) => groupBy(taskAttachments, 'entityId')
);

export const defaultTaskPriorityAttributeIdSelector = createSelector(
    activeAttributesByTypeSelector,
    applicationSettingsSelector,
    (activeAttributesByType, applicationSettings) => {
        if (applicationSettings.RMS_TASK_ENHANCEMENTS_ENABLED) {
            const attributes = activeAttributesByType(AttributeTypeEnum.TASK_PRIORITY.name, {
                isDefault: true,
            });
            return attributes?.[0]?.id;
        } else {
            return undefined;
        }
    }
);

export const todoTaskStatusAttributeIdSelector = createSelector(
    activeAttributesByTypeSelector,
    (activeAttributesByType) => {
        const pendingAttributes = activeAttributesByType(AttributeTypeEnum.TASK_STATUS.name, {
            parentAttributeId: globalAttributes.taskStatusGlobal.pending,
            isDefault: true,
        });
        const todoDefaultTaskStatusAttributeId = pendingAttributes?.[0]?.id;
        if (!todoDefaultTaskStatusAttributeId) {
            const attributes = activeAttributesByType(AttributeTypeEnum.TASK_STATUS.name, {
                parentAttributeId: globalAttributes.taskStatusGlobal.pending,
            });
            return attributes?.[0]?.id;
        }
        return todoDefaultTaskStatusAttributeId;
    }
);

export const defaultTaskTypeAttributeIdSelector = createSelector(
    activeAttributesByTypeSelector,
    applicationSettingsSelector,
    (activeAttributesByType, applicationSettings) => {
        if (applicationSettings.RMS_TASK_ENHANCEMENTS_ENABLED) {
            const attributes = activeAttributesByType(AttributeTypeEnum.TASK_TYPE.name, {
                isDefault: true,
            });
            return attributes?.[0]?.id;
        } else {
            return undefined;
        }
    }
);

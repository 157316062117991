import React from 'react';
import { flatMap, map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withFields from '~/client-common/core/fields/components/withFields';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import organizationProfileMergeableFields from '../configuration/organizationProfileMergeableFields';

import GenericPropertyList from './GenericPropertyList';

const OrganizationProfilePropertyList = (props) => (
    <GenericPropertyList {...props} propertyConfiguration={organizationProfileMergeableFields} />
);

const OrganizationProfilePropertyListWithFields = withFields(() =>
    flatMap(organizationProfileMergeableFields, ({ field, relatedFields }) => {
        const fields = [field];
        if (relatedFields) {
            fields.push(...map(relatedFields, 'field'));
        }

        return fields;
    })
)(
    connect(
        createStructuredSelector({
            formatAttributeById: formatAttributeByIdSelector,
        })
    )(OrganizationProfilePropertyList)
);

export default OrganizationProfilePropertyListWithFields;

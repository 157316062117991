import { find } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { reportModulesSelector } from '../../core/state/data/reportModules';

import { ReportModuleDashboardContainer } from './ReportModuleDashboardContainer';

interface Params {
    moduleUrl: string;
}

/**
 * Route component for a Report Module at /modules/:moduleUrl, for example /modules/citations.
 */
export const ReportModule: React.FC<RouteComponentProps<Params, Params>> = (props) => {
    const reportModules = useSelector(reportModulesSelector);
    const url = props.params.moduleUrl;
    const reportModule = find(reportModules, { url });
    if (!reportModule) {
        // it is possible for this component to render before bootstrap is done
        // it will re-render after reportModules are stored in Redux state
        return null;
    }

    return (
        <ReportModuleDashboardContainer
            title={reportModule.name}
            reportDefinitionIds={reportModule.reportDefinitionIds}
        />
    );
};

import { RoleTypeEnum } from '@mark43/rms-api';
import pluralize from 'pluralize';

import React from 'react';
import {
    DISPLAY_CASE_ASSIGNEE,
    DISPLAY_CASE_SUPERVISORS,
    DISPLAY_ONLY_CASE_SEARCH_INCLUDE_ASSISTING_INVESTIGATORS_LABEL,
    CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';

import useFields from '~/client-common/core/fields/hooks/useFields';
import Row from '../../../core/components/Row';
import { RRFCheckbox } from '../../../core/forms/components/checkboxes/Checkbox';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFCaseAssigneeSelect } from '../../../core/forms/components/selects/CaseAssigneeSelect';
import { RRFCaseSupervisorSelect } from '../../../core/forms/components/selects/CaseSupervisorSelect';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';

import fieldsetViewModel from '../state/forms/allCasesSearchPersonnelFieldset';

/**
 * Fieldset for personnel (involved users) in the All Cases search form.
 */
const AllCasesSearchPersonnelFieldset: React.FC = () => {
    const fieldDisplayNames = useFields([
        DISPLAY_CASE_ASSIGNEE,
        DISPLAY_CASE_SUPERVISORS,
        DISPLAY_ONLY_CASE_SEARCH_INCLUDE_ASSISTING_INVESTIGATORS_LABEL,
        CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID,
    ]);

    return (
        <RRFFieldset path="personnel" title={fieldsetViewModel.title}>
            <Row>
                <RRFCaseAssigneeSelect
                    multiple={true}
                    width={260}
                    label={fieldDisplayNames.DISPLAY_CASE_ASSIGNEE}
                    paths={{
                        hasAssignee: 'hasAssignee',
                        assigneeRoleIds: 'assigneeRoleIds',
                    }}
                    roleTypes={[RoleTypeEnum.USER.name]}
                    effectiveDate={null}
                />
            </Row>
            <RRFCheckbox path="includeAssistingInvestigators" />
            <Row>
                <RRFCaseSupervisorSelect
                    multiple={true}
                    width={260}
                    label={fieldDisplayNames.DISPLAY_CASE_SUPERVISORS}
                    paths={{
                        hasSupervisor: 'hasSupervisor',
                        supervisorRoleIds: 'supervisorRoleIds',
                    }}
                    roleTypes={RoleTypeEnum.USER.name}
                    effectiveDate={null}
                />
            </Row>
            <Row>
                <RRFAttributeSelect
                    multiple={true}
                    attributeType="PERSONNEL_UNIT"
                    path="assignedPersonnelUnitAttrIds"
                    width={260}
                    label={pluralize(fieldDisplayNames.CASE_ASSIGNED_PERSONNEL_UNIT_ATTR_ID)}
                    includeExpired={true}
                />
            </Row>
        </RRFFieldset>
    );
};
export default AllCasesSearchPersonnelFieldset;

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';

import { compose } from 'recompose';
import { storeOrganizationProfiles } from '~/client-common/core/domain/organization-profiles/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { openErrorModal } from '../../../legacy-redux/actions/boxActions';
import { LocationQuickAddContext } from '../context/LocationQuickAddContext';
import { withQuickAddCallback } from './withQuickAddCallback';
import { NameSummaryView } from './NameSummaryView';

const NameSummaryViewWrapperCase = connect(
    createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
    }),
    (dispatch) => ({
        storeOrganizationProfiles: (organizationProfiles) => {
            dispatch(storeOrganizationProfiles(organizationProfiles));
        },
        openErrorModal: (paragraphs) => dispatch(openErrorModal({ paragraphs })),
    })
)(NameSummaryView);

const withLocationQuickAddContext = (Component) => {
    return function WithLocationQuickAddContext(props) {
        const { setIsLocationStale } = LocationQuickAddContext.useContainer();
        return <Component setIsLocationStale={setIsLocationStale} {...props} />;
    };
};

export default compose(withQuickAddCallback, withLocationQuickAddContext)(NameSummaryViewWrapperCase);

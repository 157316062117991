import { compact, every, filter, get, has, map } from 'lodash';
import { UserProfile, ElasticUser, DutyStatusEnum } from '@mark43/rms-api';

import { isDateBetween, nowUtc } from '../core/dates/utils/dateHelpers';
import { joinTruthyValues } from './stringHelpers';

export const otherUserId = -1;

export const formatStrings = {
    getUserInitials(userProfile: UserProfile) {
        return userProfile
            ? map(compact([userProfile.firstName, userProfile.lastName]), (name) =>
                  name.charAt(0).toUpperCase()
              ).join('')
            : '';
    },
};

type UserProfileOverrideT = UserProfile & { isDisabled?: boolean };
/**
 * Must be identical to {@link client/src/scripts/helpers/userHelpers.js}.
 */
export function getFullName(
    userProfile: UserProfileOverrideT,
    includeBadgeNumber = false,
    includeDeactivatedStatus = false
) {
    return userProfile
        ? joinTruthyValues(
              [
                  userProfile.firstName,
                  userProfile.middleName,
                  userProfile.lastName,
                  userProfile.suffix,
                  includeBadgeNumber && userProfile.badgeNumber
                      ? `#${userProfile.badgeNumber}`
                      : undefined,
                  includeDeactivatedStatus && 'isDisabled' in userProfile && userProfile.isDisabled
                      ? `(deactivated)`
                      : undefined,
              ],
              ' '
          )
        : '';
}

export function getProfileUrlForUser(userProfile: UserProfile) {
    return userProfile ? `/admin/users/${userProfile.userId}` : '';
}

/**
 * Helper function to determine if a `userSearchResult` user object is currently
 * active.  Finds all duty status histories for this user that are active for
 * `effectiveDate` (ideally, only one...) -- if any of these duty status histories
 * are inactive (`dutyStatusEnum.isActive = false`), then the user is considered
 * inactive.
 */
export function isUserActive(userSearchResult?: ElasticUser, effectiveDate = nowUtc()) {
    if (!userSearchResult) {
        return false;
    }
    const effectiveDutyStatusHistories = filter(
        userSearchResult.dutyStatusHistories,
        (dutyStatusHistory) =>
            isDateBetween(
                effectiveDate,
                dutyStatusHistory.dateEffectiveStart,
                dutyStatusHistory.dateEffectiveEnd
            )
    );
    return (
        effectiveDutyStatusHistories.length > 0 &&
        every(effectiveDutyStatusHistories, (dutyStatusHistory) =>
            get(DutyStatusEnum[dutyStatusHistory.dutyStatus], 'isActive')
        )
    );
}

/**
 * Map a user id to an option.
 */
export function mapUserToOption(
    userId: number,
    userSearchResults: ElasticUser[] = [],
    formatUserById = (userId: number) => userId
) {
    // RMS-5515 -- For `userId`s that are not contained in
    // `userSearchResults` state, we hardcode these users as active.
    // status is a user's isDisabled key ie. is the user active or not
    // EXPIRED and ACTIVE are used since the ASYNC SELECT sorts by EXPIRED by default
    return {
        display: formatUserById(userId),
        status: get(userSearchResults, userId)?.isDisabled ? 'EXPIRED' : 'ACTIVE',
        noteDisplay:
            !has(userSearchResults, userId) || isUserActive(get(userSearchResults, userId))
                ? ''
                : '(inactive)',
        value: userId,
    };
}

/**
 * Map the hardcoded `OTHER` user to an option.
 */
export function mapOtherUserToOption() {
    // RMS-5515 -- The "OTHER" user is always hardcoded as active.
    return {
        display: 'OTHER',
        status: 'ACTIVE',
        noteDisplay: '',
        value: otherUserId,
    };
}

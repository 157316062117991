import _, { get, map, omit, pick, isEmpty, some, flatMap } from 'lodash';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';
import { AttributeTypeEnum, PersonsSearchResultsDefaultEnum } from '@mark43/rms-api';

import { elasticAttributeDetailsSelector } from '~/client-common/core/domain/elastic-attribute-details/state/data';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL,
    DISPLAY_ONLY_JUVENILE_LABEL,
} from '~/client-common/core/enums/universal/fields';
import {
    includeLocationSubdivisions,
    getSubdivisionsFromLocation,
} from '~/client-common/core/domain/locations/utils/subdivisionHelpers';

import { createFormModule } from '../../../../core/forms';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import {
    behaviorsFieldsetViewModel,
    identifiersFieldsetViewModel,
    identifyingMarksFieldsetViewModel,
    locationsFieldsetViewModel,
    personCautionsFieldsetViewModel,
    personDetailsFieldsetViewModel,
    physicalAttributesFieldsetViewModel,
} from '../../../../../legacy-redux/configs/fieldsetsConfig';
import { currentUserDepartmentProfileSelector } from '../../../../core/current-user/state/ui';

const strings = componentStrings.search.AdvancedSearchPersonsForm;
const personsFiltersStrings = componentStrings.search.AdvancedSearchFormFooter.labels;

const advancedSearchPersonsFormFieldViewModels = {
    ...buildFlatFormFieldViewModels([
        // fields in the form that are not nested
        'fuzzyMatchingEnabled',
    ]),
    juvenilePersonsFilters: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'juvenilePersonsFilters',
        fields: buildFlatFormFieldViewModels([
            { key: 'showAdults', label: personsFiltersStrings.showAdults },
            { key: 'showJuveniles', label: personsFiltersStrings.showJuveniles },
        ]),
    },
    personDetails: personDetailsFieldsetViewModel,
    physicalCharacteristics: {
        type: fieldTypeClientEnum.FIELDSET,
        key: 'physicalCharacteristics',
        title: physicalAttributesFieldsetViewModel.title,
        fields: {
            physicalAttributes: physicalAttributesFieldsetViewModel,
            identifyingMarks: identifyingMarksFieldsetViewModel,
        },
    },
    behaviors: behaviorsFieldsetViewModel,
    identifiers: identifiersFieldsetViewModel,
    locations: {
        ...locationsFieldsetViewModel,
        title: strings.fieldsetTitles.locations,
    },
};

export function personsSearchResultsDefaultToElasticQuery(personsSearchResultsDefault) {
    switch (personsSearchResultsDefault) {
        case PersonsSearchResultsDefaultEnum.DEFAULT_JUVENILE_ONLY.name:
            return { isJuvenile: true };
        case PersonsSearchResultsDefaultEnum.DEFAULT_ADULT_ONLY.name:
            return { isJuvenile: false };
        case PersonsSearchResultsDefaultEnum.DEFAULT_JUVENILE_AND_ADULT.name:
        case PersonsSearchResultsDefaultEnum.SEARCH_OPTION_NOT_DISPLAYED.name:
        default:
            return {};
    }
}

export function isJuvenileElasticQueryToFilterCheckboxes(queryValue) {
    switch (queryValue) {
        case true:
            return { showAdults: false, showJuveniles: true };
        case false:
            return { showAdults: true, showJuveniles: false };
        default:
            return { showAdults: true, showJuveniles: true };
    }
}

export function formModelToIsJuvenileElasticQuery(formModel) {
    const { showAdults, showJuveniles } = get(formModel, 'juvenilePersonsFilters', {});

    if (showJuveniles === true && showAdults === false) {
        return { isJuvenile: true };
    }

    if (showJuveniles === false && showAdults === true) {
        return { isJuvenile: false };
    }

    return {};
}

/**
 * Convert the given search query model to form model state. While the query is
 *   flat, the form model state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertAdvancedSearchPersonsElasticQueryToFormModel(
    elasticQuery,
    state,
    boundSelectors
) {
    const personGangTrackings = convertGangTrackingToFormModel(elasticQuery);
    const personsSearchResultsDefault = state
        ? get(currentUserDepartmentProfileSelector(state), 'personsSearchResultsDefault')
        : get(boundSelectors, 'currentUserDepartmentProfile.personsSearchResultsDefault');
    const hideFilterCheckboxes =
        personsSearchResultsDefault ===
        PersonsSearchResultsDefaultEnum.SEARCH_OPTION_NOT_DISPLAYED.name;
    const juvenilePersonsFilters = hideFilterCheckboxes
        ? {}
        : isJuvenileElasticQueryToFilterCheckboxes(get(elasticQuery, 'isJuvenile'));

    const elasticAttributeDetails = state
        ? elasticAttributeDetailsSelector(state)
        : get(boundSelectors, 'elasticAttributeDetails', {});

    return buildFormModel(
        {
            fuzzyMatchingEnabled: get(elasticQuery, 'fuzzyMatchingEnabled'),
            personDetails: pick(elasticQuery, map(personDetailsFieldsetViewModel.fields, 'key')),
            physicalCharacteristics: {
                physicalAttributes: pick(
                    elasticQuery,
                    map(physicalAttributesFieldsetViewModel.fields, 'key')
                ),
                identifyingMarks: get(elasticQuery, 'identifyingMarks'),
            },
            behaviors: {
                ...(personGangTrackings !== undefined ? { personGangTrackings } : {}),
                ...convertElasticPersonQueryToBehaviorsFormModel(
                    elasticQuery,
                    elasticAttributeDetails
                ),
                cautions: pick(
                    get(elasticQuery, 'cautions[0]'),
                    map(personCautionsFieldsetViewModel.fields, 'key')
                ),
            },
            identifiers: pick(elasticQuery, map(identifiersFieldsetViewModel.fields, 'key')),
            locations: get(elasticQuery, 'locations'),
            juvenilePersonsFilters,
        },
        advancedSearchPersonsFormFieldViewModels
    );
}

const attributeTypeToFormPath = {
    [AttributeTypeEnum.BEHAVIORAL_CHARACTERISTIC.name]: 'behavioralCharacteristicAttrIds',
    [AttributeTypeEnum.CAUTION.name]: 'personLabelAttrIds',
    [AttributeTypeEnum.MODUS_OPERANDI.name]: 'modusOperandiAttrIds',
    [AttributeTypeEnum.MOOD.name]: 'moodAttrIds',
};

const legacyAttributeKeyNames = [
    'behavioralCharacteristicAttrIds',
    'modusOperandiAttrIds',
    'moodAttrIds',
];

export function convertElasticPersonQueryToBehaviorsFormModel(
    personQuery,
    elasticAttributeDetails
) {
    // backwards compatability for saved searches with queries using old attribute keys
    const behaviorsFormModelFromAttributeKeys = pick(personQuery, legacyAttributeKeyNames);

    const nameAttrIds = get(personQuery, 'nameAttributes[0].nameAttrIds');

    const behaviorsFormModelFromNameAttributes = _(nameAttrIds)
        .map((nameAttrId) => elasticAttributeDetails[nameAttrId])
        .groupBy('type')
        .mapValues((attributeDetails) => map(attributeDetails, 'id'))
        .mapKeys((attributeDetails, attributeType) => attributeTypeToFormPath[attributeType])
        .value();

    const cautionsQuery = get(personQuery, 'cautions[0]');

    const cautions = cautionsQuery
        ? {
              cautions: pick(cautionsQuery, map(personCautionsFieldsetViewModel.fields, 'key')),
          }
        : {};

    if (isEmpty(behaviorsFormModelFromAttributeKeys)) {
        return { ...behaviorsFormModelFromNameAttributes, ...cautions };
    }

    return {
        ...behaviorsFormModelFromNameAttributes,
        ...behaviorsFormModelFromAttributeKeys,
        ...cautions,
    };
}

export function convertFormModelToNameAttributes(formModel) {
    const behaviorsFormModel = get(formModel, 'behaviors', {});
    const nameAttrIds = flatMap(omit(behaviorsFormModel, 'personGangTrackings', 'cautions'));

    if (isEmpty(nameAttrIds)) {
        return;
    } else {
        return { nameAttributes: [{ nameAttrIds }] };
    }
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertAdvancedSearchPersonsFormModelToElasticQuery(formModel = {}) {
    return filterFormData({
        fuzzyMatchingEnabled: formModel.fuzzyMatchingEnabled,
        ...formModelToIsJuvenileElasticQuery(formModel),
        ...formModel.personDetails,
        ...get(formModel.physicalCharacteristics, 'physicalAttributes'),
        identifyingMarks: get(formModel.physicalCharacteristics, 'identifyingMarks'),
        ...addExpirationsToGangTracking(formModel),
        ...formModel.identifiers,
        ids: formModel.identifiers
            ? map(formModel.identifiers.ids, (mniEntry) => mniEntry.mniValue)
            : [],
        locations: [
            ...(formModel.locations || []),
            ...(includeLocationSubdivisions(formModel.locations)
                ? [getSubdivisionsFromLocation(formModel.locations)]
                : []),
        ],
        ...convertFormModelToNameAttributes(formModel),
        ...convertFormModelToPersonCautionsElasticQuery(formModel),
    });
}

export function convertFormModelToPersonCautionsElasticQuery({ behaviors = {} } = {}) {
    if ('cautions' in behaviors && behaviors.cautions) {
        return { cautions: [behaviors.cautions] };
    }
}

export function addExpirationsToGangTracking(formModel) {
    const personGangTrackings = filterFormData(get(formModel, 'behaviors.personGangTrackings'));

    if (!isEmpty(personGangTrackings)) {
        const newPersonGangTrackings = map(personGangTrackings, (personGangTracking) => {
            return {
                ...personGangTracking,
                isExpired: false,
            };
        });
        return { personGangTrackings: newPersonGangTrackings };
    }
}

function convertGangTrackingToFormModel(elasticQuery) {
    const personGangTrackings = get(elasticQuery, 'personGangTrackings');
    if (personGangTrackings) {
        const hasGangTrackingExpiredVal = some(personGangTrackings, (personGangTracking) => {
            return !personGangTracking.gangNameAttrIds && !personGangTracking.gangSubgroupAttrIds;
        });

        if (hasGangTrackingExpiredVal) {
            return map(personGangTrackings, (personGangTracking) => {
                return {
                    ...personGangTracking,
                    isExpired: [false.toString()],
                };
            });
        }
    }
    return personGangTrackings;
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
export function convertAdvancedSearchPersonsFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const combinedSubdivisionsLabel = boundSelectors.combinedSubdivisionsLabel;
    const formatFieldByName = boundSelectors.formatFieldByName;
    const juvenileDisplayName = pluralize(formatFieldByName(DISPLAY_ONLY_JUVENILE_LABEL));

    const fieldViewModels = {
        ...advancedSearchPersonsFormFieldViewModels,
        juvenilePersonsFilters: {
            ...advancedSearchPersonsFormFieldViewModels.juvenilePersonsFilters,
            fields: {
                ...advancedSearchPersonsFormFieldViewModels.juvenilePersonsFilters.fields,
                showJuveniles: {
                    ...advancedSearchPersonsFormFieldViewModels.juvenilePersonsFilters.fields
                        .showJuveniles,
                    label: personsFiltersStrings.showJuveniles(juvenileDisplayName),
                },
            },
        },
        locations: {
            ...advancedSearchPersonsFormFieldViewModels.locations,
            fields: {
                ...advancedSearchPersonsFormFieldViewModels.locations.fields,
                subdivisionAttrIds: {
                    ...advancedSearchPersonsFormFieldViewModels.locations.fields.subdivisionAttrIds,
                    label: combinedSubdivisionsLabel,
                },
                subdivision1AttrIds: {
                    ...advancedSearchPersonsFormFieldViewModels.locations.fields
                        .subdivision1AttrIds,
                    label: formatFieldByName(
                        advancedSearchPersonsFormFieldViewModels.locations.fields
                            .subdivision1AttrIds.fieldName
                    ),
                },
                subdivision2AttrIds: {
                    ...advancedSearchPersonsFormFieldViewModels.locations.fields
                        .subdivision2AttrIds,
                    label: formatFieldByName(
                        advancedSearchPersonsFormFieldViewModels.locations.fields
                            .subdivision2AttrIds.fieldName
                    ),
                },
                subdivision3AttrIds: {
                    ...advancedSearchPersonsFormFieldViewModels.locations.fields
                        .subdivision3AttrIds,
                    label: formatFieldByName(
                        advancedSearchPersonsFormFieldViewModels.locations.fields
                            .subdivision3AttrIds.fieldName
                    ),
                },
                subdivision4AttrIds: {
                    ...advancedSearchPersonsFormFieldViewModels.locations.fields
                        .subdivision4AttrIds,
                    label: formatFieldByName(
                        advancedSearchPersonsFormFieldViewModels.locations.fields
                            .subdivision4AttrIds.fieldName
                    ),
                },
                subdivision5AttrIds: {
                    ...advancedSearchPersonsFormFieldViewModels.locations.fields
                        .subdivision5AttrIds,
                    label: formatFieldByName(
                        advancedSearchPersonsFormFieldViewModels.locations.fields
                            .subdivision5AttrIds.fieldName
                    ),
                },
            },
        },
        behaviors: {
            ...advancedSearchPersonsFormFieldViewModels.behaviors,
            fields: {
                ...advancedSearchPersonsFormFieldViewModels.behaviors.fields,
                ...convertPersonCautionsFormModelToFilterGroups(formatFieldByName),
            },
        },
    };

    const newFormModel = {
        ...formModel,
        behaviors: {
            ...formModel.behaviors,
            personGangTrackings: replacePersonGangTrackingLabels(formModel),
        },
    };

    return convertFormModelToFilterGroups(
        newFormModel,
        fieldViewModels,
        formatFieldValue,
        boundSelectors.formatFieldByName
    );
}

export function convertPersonCautionsFormModelToFilterGroups(formatFieldByName) {
    const activeWithinRangeLabel = formatFieldByName(DISPLAY_ONLY_ACTIVE_WITHIN_RANGE_LABEL);
    return {
        cautions: {
            ...personCautionsFieldsetViewModel,
            fields: {
                ...personCautionsFieldsetViewModel.fields,
                activeWithinRange: {
                    ...personCautionsFieldsetViewModel.fields.activeWithinRange,
                    fields: {
                        ...Object.keys(
                            personCautionsFieldsetViewModel.fields.activeWithinRange.fields
                        ).reduce(
                            (fields, key) => ({
                                ...fields,
                                [key]: {
                                    ...fields[key],
                                    label: activeWithinRangeLabel,
                                },
                            }),
                            personCautionsFieldsetViewModel.fields.activeWithinRange.fields
                        ),
                    },
                },
            },
        },
    };
}

function replacePersonGangTrackingLabels(formModel) {
    const personGangTrackings = get(formModel, 'behaviors.personGangTrackings');
    if (personGangTrackings) {
        return map(personGangTrackings, (personGangTracking) => {
            if (personGangTracking.gangNameAttrIds || personGangTracking.gangSubgroupAttrIds) {
                return {
                    ...personGangTracking,
                    isExpired: null,
                };
            }
            return personGangTracking;
        });
    }
    return personGangTrackings;
}

const advancedSearchPersonsForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_PERSONS,
    fieldViewModels: advancedSearchPersonsFormFieldViewModels,
    convertToFormModel: convertAdvancedSearchPersonsElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchPersonsFormModelToElasticQuery,
});

advancedSearchPersonsForm.selectors.formIsEmptySelector = createSelector(
    advancedSearchPersonsForm.selectors.formModelSelector,
    (formModel) =>
        formDataIsEmpty(omit(formModel, ['fuzzyMatchingEnabled', 'juvenilePersonsFilters']))
);

/**
 * Module of the advanced search persons form.
 * @type {Object}
 */
export default advancedSearchPersonsForm;

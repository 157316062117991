import { EvidencePrintingTemplateFileLink } from '@mark43/evidence-api';
import createLinkModule from '../../../../utils/createLinkModule';

const templateFileLinksModule = createLinkModule<EvidencePrintingTemplateFileLink>({
    type: 'templateFileLinks',
    keys: ['evidencePrintingTemplateId', 'fileId'],
});

// ACTIONS
export const storeTemplateFileLinks = templateFileLinksModule.actionCreators.storeLinks;
export const replaceTemplateFileLinks = templateFileLinksModule.actionCreators.replaceLinksWhere;

// SELECTORS

export const templateFileLinksWhereSelector = templateFileLinksModule.selectors.linksWhereSelector;

// REDUCER
export default templateFileLinksModule.reducerConfig;

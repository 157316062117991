import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { lifecycleOptions } from 'markformythree';

import { formName } from '../../state/forms/fieldContactForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';

const FieldContactCardForm = () => {
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={() => {
                return (
                    <CardSection>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="contactTypeAttrId"
                                length="lg"
                                attributeType={AttributeTypeEnum.FIELD_CONTACT_TYPE.name}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="contactTypeOther" length="lg" />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker path="seizureStartDateUtc" includeTime={true} />
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker path="seizureEndDateUtc" includeTime={true} />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect
                                path="wasStopScreenedBySupervisor"
                                length="lg"
                            />
                        </Row>
                        <Row>
                            <ArbiterMFTTextArea path="reasonableSuspicionNarrative" rows={4} />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="wasSubjectSearched" length="lg" />
                        </Row>
                        <Row>
                            <ArbiterMFTTextArea path="armedAndDangerousNarrative" rows={4} />
                        </Row>
                        <Row>
                            <ArbiterMFTBooleanSelect path="didOfficerFindWeapon" length="lg" />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="weaponInvolvedAttrIds"
                                length="lg"
                                attributeType={AttributeTypeEnum.WEAPON_INVOLVED.name}
                                multiple={true}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="weaponInvolvedDescription" length="lg" />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTTextArea path="policeExperienceNarrative" rows={4} />
                        </Row>
                        <Row>
                            <ArbiterMFTTextArea path="contactDetailsNarrative" rows={4} />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="reasonForStopAttrId"
                                attributeType={AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name}
                                length="lg"
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="reasonForStopOther" length="lg" />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="fieldContactReasonForStopAttrIds"
                                length="lg"
                                attributeType={AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name}
                                multiple={true}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText
                                    path="fieldContactReasonForStopDescription"
                                    length="lg"
                                />
                            </IndentedFields>
                        </Row>
                        <Row>
                            <ArbiterMFTText path="movingViolationNumber" length="lg" />
                        </Row>
                        <Row>
                            <ArbiterMFTAttributeCheckboxes
                                path="fieldContactDispositionAttrIds"
                                attributeType={AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name}
                                columns={2}
                                columnWidth="48%"
                                gutterWidth="4%"
                            />
                            <Row>
                                <IndentedFields>
                                    <ArbiterMFTText
                                        path="fieldContactDispositionDescription"
                                        length="lg"
                                    />
                                </IndentedFields>
                            </Row>
                        </Row>
                    </CardSection>
                );
            }}
        />
    );
};

export default FieldContactCardForm;

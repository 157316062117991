import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import styled from 'styled-components';

import {
    deleteStaffRemark,
    staffRemarksByEntitySelector,
} from '~/client-common/core/domain/staff-remarks/state/data';
import { FormattedDate } from '~/client-common/core/dates/components';
import {
    ConnectedFormattedUser,
    FORMATS as USER_FORMATS,
} from '~/client-common/core/users/components';

import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import testIds from '../../../../core/testIds';

const IconContainer = styled.div`
    width: 20px;
`;

const ContentContainer = styled.div`
    flex: 1;
`;

const TrashContainer = styled.div`
    width: 20px;
`;

const SemiBold = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const _StaffRemark = compose(
    connect(null, { deleteStaffRemark }),
    withHandlers({
        onDelete({ deleteStaffRemark, staffRemark }) {
            return () => {
                deleteStaffRemark(staffRemark.id);
            };
        },
    })
)(function _StaffRemark({ staffRemark, deletable, onDelete, className }) {
    return (
        <div className={className} data-test-id={testIds.STAFF_REMARK_CONTAINER}>
            <IconContainer>
                <Icon size={12} type={iconTypes.NOTE} />
            </IconContainer>
            <ContentContainer>
                <SemiBold>
                    <FormattedDate
                        date={staffRemark.remarkDateUtc}
                        format={FormattedDate.FORMATS.FORM_DATE_TIME}
                    />
                </SemiBold>
                {' by '}
                <SemiBold>
                    <ConnectedFormattedUser
                        userId={staffRemark.remarkUserId}
                        format={USER_FORMATS.FULL_NAME_WITH_FIRST_INITIAL}
                    />
                </SemiBold>
                <div>{staffRemark.remark}</div>
            </ContentContainer>
            <TrashContainer>
                {deletable && (
                    <Button
                        className={buttonTypes.ICON_LINK}
                        iconLeft={iconTypes.TRASH_CAN}
                        onClick={onDelete}
                        testId={testIds.TRASH_BUTTON}
                    />
                )}
            </TrashContainer>
        </div>
    );
});

export const StaffRemark = styled(_StaffRemark)`
    display: flex;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    .mark43-icon::before {
        margin-top: -3px;
    }
`;

function _StaffRemarks({ staffRemarks, deletable, className }) {
    return (
        <div className={className}>
            {_.map(staffRemarks, (staffRemark) => (
                <StaffRemark key={staffRemark.id} staffRemark={staffRemark} deletable={deletable} />
            ))}
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    staffRemarksByEntity: staffRemarksByEntitySelector,
});

const StaffRemarks = compose(
    connect(mapStateToProps),
    withPropsOnChange(
        ['staffRemarks', 'staffRemarksByEntity', 'entityType', 'entityId'],
        ({ staffRemarks, staffRemarksByEntity, entityType, entityId }) => ({
            staffRemarks: staffRemarks || staffRemarksByEntity(entityType, entityId),
        })
    )
)(_StaffRemarks);

/**
 * Display a list of staff remarks. Provide either the StaffRemark models or an entity type and id.
 * @param {Object[]} [props.staffRemarks]
 * @param {string}   [props.entityType]
 * @param {number}   [props.entityId]
 * @param {function} [props.deletable=false]
 */
export default styled(StaffRemarks)`
    font-size: var(--arc-fontSizes-sm);

    &,
    * {
        box-sizing: border-box;
    }
`;

import Promise from 'bluebird';
import { createSelector } from 'reselect';
import { get, parseInt } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import { entityTypeParamToEnum } from '../../../../../helpers/entityProfileHelpers';

import { routerSelector } from '../../../../../../routing/routerModule';

import { POLL_FOR_CURRENT_VIEWERS_INTERVAL } from '../../configuration';
import {
    BEGIN_POLLING_FOR_CURRENT_VIEWERS,
    currentViewersPollingSelector,
    END_POLLING_FOR_CURRENT_VIEWERS,
} from '../ui';
import { getCurrentViewersForEntity } from '../data';

const POLL_FOR_CURRENT_VIEWERS_FAILURE = 'current-viewers/POLL_FOR_CURRENT_VIEWERS_FAILURE';

// Bookends to start and stop polling

function beginPollingForCurrentViewers(entityType, entityId) {
    return {
        type: BEGIN_POLLING_FOR_CURRENT_VIEWERS,
        payload: { entityType, entityId },
    };
}

// Single-round poll status

function pollForCurrentViewersFailure(errorMessage) {
    return {
        type: POLL_FOR_CURRENT_VIEWERS_FAILURE,
        payload: errorMessage,
    };
}

// Helpers

function entityTypeToParamKey(entityType) {
    switch (entityType) {
        case EntityTypeEnum.REPORT.name:
            return 'reportId';
        case EntityTypeEnum.CASE.name:
            return 'caseId';
        case EntityTypeEnum.WARRANT.name:
            return 'warrantId';
        case EntityTypeEnum.PERSON_PROFILE.name:
        case EntityTypeEnum.ORGANIZATION_PROFILE.name:
        case EntityTypeEnum.ITEM_PROFILE.name:
            return 'entityId';
        default:
            return;
    }
}

const shouldPollSelector = createSelector(
    currentViewersPollingSelector,
    routerSelector,
    (currentViewersPollingUi, routing) => (entityType, entityId) => {
        const routeParam = get(routing, ['params', entityTypeToParamKey(entityType)]);
        return currentViewersPollingUi.polling && parseInt(routeParam) === entityId;
    }
);

function parseArguments(entityType, entityId) {
    if (!entityId || isNaN(entityId)) {
        return false;
    }
    return {
        entityType: entityTypeParamToEnum[entityType] || entityType,
        entityId: parseInt(entityId),
    };
}

/**
 *   Start polling for current-viewers until `stopPollingForCurrentViewers()` is
 *   dispatched. No-op if already polling.
 */
export function startPollingForCurrentViewers(entityType, entityId) {
    return function (dispatch, getState) {
        const state = getState();
        const parsed = parseArguments(entityType, entityId);
        if (parsed && !shouldPollSelector(state)(parsed.entityType, parsed.entityId)) {
            dispatch(beginPollingForCurrentViewers(parsed.entityType, parsed.entityId));
            dispatch(pollForCurrentViewers(parsed.entityType, parsed.entityId));
        }
    };
}

/**
 *   Stop polling for current-viewers.
 */
export function stopPollingForCurrentViewers(entityType, entityId) {
    return {
        type: END_POLLING_FOR_CURRENT_VIEWERS,
        payload: { entityType, entityId },
    };
}

// Actual polling implementation

/**
 * Recursive polling call
 */
function nextPoll(entityType, entityId) {
    return function (dispatch, getState) {
        return Promise.delay(POLL_FOR_CURRENT_VIEWERS_INTERVAL).then(() => {
            // continue polling only if the user is still in entity:
            // if stopPollingForCurrentViewers() has not been dispatched.
            if (shouldPollSelector(getState())(entityType, entityId)) {
                dispatch(pollForCurrentViewers(entityType, entityId));
            }
            return;
        });
    };
}

/**
 * Poll for current-viewers on the page.
 * @return {Promise}
 */
function pollForCurrentViewers(entityType, entityId) {
    return function (dispatch) {
        return (
            dispatch(getCurrentViewersForEntity(entityType, entityId))
                // silent failure
                .catch((err) => {
                    dispatch(pollForCurrentViewersFailure(err.message));
                })
                .finally(() => {
                    return dispatch(nextPoll(entityType, entityId));
                })
        );
    };
}

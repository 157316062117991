import { last, uniqBy, some, map, omitBy } from 'lodash';
import React from 'react';
import { useVirtual } from 'react-virtual';
import { DeepPartial } from 'utility-types';

import { Banner } from '@arc/banner';
import { Divider } from '@arc/divider';
import { Box, Flex, HStack, Spacer, VStack } from '@arc/layout';
import {
    ElasticPersonQuery,
    SearchQueryElasticPersonQuery,
    SearchResultElasticPhotoLineup,
    PhotoLineupView,
} from '@mark43/rms-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { useLoadingState, loadingActionCreators } from '~/client-common/core/hooks/useLoadingState';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formDataIsEmpty } from '~/client-common/helpers/formHelpers';
import { useAnalytics } from '../../../analytics/hooks/useAnalytics';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';

import formsRegistry from '../../../../core/formsRegistry';
import casePhotoLineupResource from '../../core/resources/casePhotoLineupResource';
import { IconButton } from '../../../core/components/IconButton';
import testIds from '../../../../core/testIds';
import parseElasticPhotoLineups, { DisplayedPhoto } from '../utils/parseElasticPhotoLineups';
import useGetElasticPersonQueryForPhotoLineup from '../state/ui/useGetElasticPersonQueryForPhotoLineup';
import PhotoLineupFilterForm from './PhotoLineupFilterForm';
import SelectionAreaPhoto from './SelectionAreaPhoto';

/**
 * Since arbitrarily many instances of SelectionAreaPhoto are rendered in the grid, we memoize the component for it to
 * only re-render when props change. This means that when the user clicks a SelectionAreaPhoto to select or remove it
 * from the lineup, only that one SelectionAreaPhoto will re-render instead of all SelectionAreaPhotos.
 */
const SelectionAreaPhotoMemo = React.memo(SelectionAreaPhoto);

const strings = componentStrings.cases.casePhotoLineups.SelectionAreaContainer;

/**
 * The height in pixels of each row in the photo grid.
 */
const ROW_HEIGHT = 128;
/**
 * Number of photos per row in the grid.
 */
const PHOTOS_PER_ROW = 5;
/**
 * Number of photo search results to get in each API request that gets made as the user scrolls down.
 * Each request gives a "page" of results. The number of results is 0 up to this number.
 */
const PHOTOS_PER_REQUEST = 50;
/**
 * The height in pixels of the content above the infinite scrolling element, excluding the filter form.
 */
const OFFSET_HEIGHT_WITHOUT_FORM = 64;
/**
 * The height in pixels of the content above the infinite scrolling element, including the filter form.
 */
const OFFSET_HEIGHT_WITH_FORM = 494;

const estimateSize = () => ROW_HEIGHT;

/**
 * The SelectionAreaContainer contains
 * 1. the filter form (where the user searches for photos of people who are similar to the suspect) and
 * 2. the selection area (where the user selects photos for the current lineup)
 */
const SelectionAreaContainer = ({
    initialSearchFilters,
    addSelectedPhoto,
    removeSelectedPhoto,
    lineup,
}: {
    initialSearchFilters?: ElasticPersonQuery;
    addSelectedPhoto: (photo: DisplayedPhoto) => void;
    removeSelectedPhoto: (id: number) => void;
    lineup: PhotoLineupView;
}): JSX.Element => {
    const [formIsOpen, setFormIsOpen] = React.useState(false);
    const [photos, setPhotos] = React.useState<DisplayedPhoto[]>([]);
    // The `from` value of the last search. This keeps track of the current "page" number.
    const [from, setFrom] = React.useState(0);
    const [noMoreResults, setNoMoreResults] = React.useState(false);
    // Infinite scrolling: Since it's possible for multiple search requests to be running at the same time, this keeps
    // track of the global loading state.
    const [scrollLoading, loadingDispatch] = useLoadingState();
    const { filteredTrack } = useAnalytics();
    const getElasticPersonQuery = useGetElasticPersonQueryForPhotoLineup(initialSearchFilters);
    const photoLineupSlotViews = lineup.photoLineupSlotViews;
    const selectedPhotoIds = map(photoLineupSlotViews, 'imageId');
    const poiMasterId = lineup.photoLineup.personOfInterestMasterId;

    // Search: Build resource method
    const searchPhotos = React.useCallback(
        (
            searchQuery: DeepPartial<SearchQueryElasticPersonQuery>
        ): Promise<SearchResultElasticPhotoLineup> => {
            const from = searchQuery.from || 0;
            const size = searchQuery.size || PHOTOS_PER_REQUEST;
            setFrom(from);
            if (from === 0) {
                setNoMoreResults(false);
            }
            return casePhotoLineupResource.searchPhotos({
                ...searchQuery,
                from,
                size,
            });
        },
        []
    );
    const onSuccess = React.useCallback(
        (results: SearchResultElasticPhotoLineup): void => {
            // Debounce API requests to once per second
            setTimeout(() => {
                loadingDispatch(loadingActionCreators.loadingSuccess());
            }, 1000);

            if (results.items.length === 0) {
                // Infinite scrolling: Stop trying to load more results as the user scrolls down
                setNoMoreResults(true);
            }

            if (results.query.from === 0) {
                // First "page" of results
                setPhotos(parseElasticPhotoLineups(results.items, poiMasterId));
            } else {
                // Remove any duplicate results
                setPhotos((photos) => {
                    return uniqBy(
                        [...photos, ...parseElasticPhotoLineups(results.items, poiMasterId)],
                        'path'
                    );
                });
            }
        },
        [loadingDispatch, poiMasterId]
    );
    /**
     * Using `useResourceDeferred` instead of `useResource` to support both:
     * 1. When initial search filters are provided, in the `useEffect` below, and
     * 2. When the user submits the form by clicking a button.
     */
    const { loading: resourceLoading, callResource } = useResourceDeferred(searchPhotos, onSuccess);

    // Search: Make the initial API request for the first "page" of results
    React.useEffect(() => {
        if (initialSearchFilters) {
            callResource({
                elasticQuery: initialSearchFilters,
                from: 0,
                size: PHOTOS_PER_REQUEST,
            });
        }
    }, [initialSearchFilters, callResource]);

    // Filter form: Handle submission
    const onFormSubmit = React.useCallback(() => {
        const query = getElasticPersonQuery();
        const submittedFields = Object.keys(omitBy(query, (field) => formDataIsEmpty(field)));
        filteredTrack(
            {
                [AnalyticsPropertyEnum.PHOTO_LINEUP_FILTER_SECTION]:
                    testIds.PHOTO_LINEUP_FILTER_FORM,
                [AnalyticsPropertyEnum.PHOTO_LINEUP_FILTER_QUERY]: submittedFields,
            },
            testIds.PHOTO_LINEUP_FILTER_FORM_APPLY_FILTERS
        );

        callResource({
            elasticQuery: query,
            from: 0,
        });
    }, [callResource, filteredTrack, getElasticPersonQuery]);

    // Filter form: Unregister the form when this component unmounts
    React.useEffect(() => {
        return () => {
            /**
             * The form is not unregistered when the form component PhotoLineupFilterForm unmounts,[1] because the form
             * state has to persist while the user show/hides the form.[2] Instead, we unregister the form when this
             * component SelectionAreaContainer unmounts, because it only unmounts when the user stops editing the
             * current photo lineup.
             *
             * [1] This is accomplished by REGISTER_AND_RETAIN in PhotoLineupFilterForm.
             * [2] The `formIsOpen` state is controlled here, in SelectionAreaContainer.
             */
            formsRegistry.unregister(formClientEnum.PHOTO_LINEUP_FILTER_FORM);
        };
    }, []);

    // Selection area: Handle selection and removal of a single photo, when the user clicks in it
    const onSelect = React.useCallback(
        (id: number) => {
            const selectedPhoto = photos.find((photo) => photo.id === id);
            if (selectedPhoto) {
                addSelectedPhoto(selectedPhoto);
            }
        },
        [addSelectedPhoto, photos]
    );

    // Infinite scrolling: This ref is used by react-virtual on the parent DOM element, which has a static height. This
    // DOM element is the parent of the scrolling element, which has a dynamic height.
    const virtualParentRef = React.useRef<HTMLDivElement | null>(null);

    // Infinite scrolling: The number of rows of photos in the grid. There are more rows than "pages".
    const rowCount = Math.ceil(photos.length / PHOTOS_PER_ROW);

    const paddingStart = formIsOpen ? OFFSET_HEIGHT_WITH_FORM : OFFSET_HEIGHT_WITHOUT_FORM;

    // Infinite scrolling: See https://react-virtual-v2.tanstack.com/examples/infinite-scroll
    const virtualizer = useVirtual({
        parentRef: virtualParentRef,
        // When there are still results to load, show an empty row at the bottom of the grid
        size: noMoreResults ? rowCount : rowCount + 1,
        // This callback must be memoized
        estimateSize,
        overscan: 1,
        // The amount of px at the top of the scrolling element.
        // An enhancement is to dynamically compute these heights using element.offsetHeight from a ref.
        paddingStart,
    });

    // Infinite scrolling: Determine whether to trigger an API request for the next "page" of results
    React.useEffect(() => {
        const lastItem = last(virtualizer.virtualItems);
        if (!lastItem) {
            return;
        }

        if (
            lastItem.index >= rowCount &&
            !noMoreResults &&
            !scrollLoading.isLoading &&
            getElasticPersonQuery
        ) {
            loadingDispatch(loadingActionCreators.loadingStart());
            callResource({
                elasticQuery: getElasticPersonQuery(),
                from: from + PHOTOS_PER_REQUEST,
                size: PHOTOS_PER_REQUEST,
            });
        }
    }, [
        callResource,
        from,
        getElasticPersonQuery,
        loadingDispatch,
        noMoreResults,
        rowCount,
        scrollLoading.isLoading,
        virtualizer.virtualItems,
    ]);

    return (
        // Infinite scrolling: Parent element
        <Box ref={virtualParentRef} width="100%" height="100%" overflowX="hidden" overflowY="auto">
            {/* Infinite scrolling: Scrolling element */}
            <VStack position="relative" width="100%" height={virtualizer.totalSize} spacing="4">
                <Flex width="100%" paddingLeft="4" paddingRight="4" paddingTop="4">
                    <Spacer />
                    <HStack>
                        <IconButton
                            icon="Filter"
                            variant={formIsOpen ? 'solid' : 'outline'}
                            onClick={() => {
                                setFormIsOpen((isOpen) => !isOpen);
                            }}
                            aria-label="Filter"
                            testId={testIds.PHOTO_LINEUP_FILTER_FORM_TOGGLE}
                        />
                    </HStack>
                </Flex>

                {formIsOpen ? (
                    <>
                        <Box paddingLeft="4">
                            <PhotoLineupFilterForm
                                initialSearchFilters={initialSearchFilters}
                                onSubmit={onFormSubmit}
                            />
                        </Box>
                        <Divider />
                    </>
                ) : undefined}

                {/* All elements above these virtual rows are accounted for by the height of `paddingStart` */}
                {virtualizer.virtualItems.map((virtualRow) => {
                    const photosInRow = photos.slice(
                        virtualRow.index * PHOTOS_PER_ROW,
                        (virtualRow.index + 1) * PHOTOS_PER_ROW
                    );

                    // Check if the person exists in reference area and selection area
                    const isDuplicatePerson = (masterPersonId: number) => {
                        const sameMasterId = some(photoLineupSlotViews, { masterPersonId });
                        return sameMasterId;
                    };

                    // Check if it is the same photo  by imageId
                    const isDuplicatePhoto = (imageId: number) => {
                        const refImageIds: number[] = [];
                        const slotViews = lineup.photoLineupSlotViews;
                        slotViews.forEach((slot) => {
                            if (slot.imageId) {
                                refImageIds.push(slot.imageId);
                            }
                        });

                        // Get back list of imageId and check with the selection area id
                        const duplicatePicture = refImageIds.includes(imageId);
                        return duplicatePicture;
                    };

                    // Render each row in the grid
                    return (
                        <HStack
                            key={virtualRow.index}
                            ref={virtualRow.measureRef}
                            position="absolute"
                            top={0}
                            // Each row is vertically positioned at a static number of pixels from the top of the
                            // scrolling element; this number does not change as the user scrolls through other rows.
                            transform={`translateY(${virtualRow.start}px)`}
                            left="6"
                            height={ROW_HEIGHT}
                            spacing="4"
                            alignItems="start"
                        >
                            {photosInRow.map((photo) => {
                                const isSelected = selectedPhotoIds.includes(photo.id);

                                // Render each column in the row
                                return (
                                    <SelectionAreaPhotoMemo
                                        key={photo.id}
                                        id={photo.id}
                                        path={photo.path}
                                        isSelected={isSelected}
                                        onClick={isSelected ? removeSelectedPhoto : onSelect}
                                        disablePhotoSlot={
                                            isDuplicatePerson(photo.masterPersonId) &&
                                            !isDuplicatePhoto(photo.id)
                                        }
                                        isPoiImage={
                                            photo.masterPersonId ===
                                            lineup.photoLineup.personOfInterestMasterId
                                        }
                                    />
                                );
                            })}
                        </HStack>
                    );
                })}

                {resourceLoading.errorMessage && (
                    <Box
                        position="absolute"
                        top={0}
                        // Since all the rows in the grid above are absolutely positioned, we position this error box
                        // to be where the bottom row would be. The bottom row is empty due to the `rowCount + 1`
                        // calculation.
                        transform={`translateY(${virtualizer.totalSize - ROW_HEIGHT}px)`}
                        width="100%"
                        paddingLeft="6"
                        paddingRight="6"
                    >
                        <Banner
                            status="error"
                            description={resourceLoading.errorMessage}
                            title={strings.errorTitle}
                        />
                    </Box>
                )}
            </VStack>
        </Box>
    );
};

export default SelectionAreaContainer;

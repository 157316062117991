import { RmsHistoryEvent } from '@mark43/rms-api';
import { storeHistoryEventsWithEntityId } from '~/client-common/core/domain/history-events/state/data';
import getWarrantResource from '~/client-common/core/domain/warrants/resources/warrantsResource';
import { RmsAction } from '../../../../../core/typings/redux';

const LOAD_WARRANT_HISTORY_START = 'warrant-history/LOAD_WARRANT_HISTORY_START';
const LOAD_WARRANT_HISTORY_SUCCESS = 'warrant-history/LOAD_WARRANT_HISTORY_SUCCESS';
const LOAD_WARRANT_HISTORY_FAILURE = 'warrant-history/LOAD_WARRANT_HISTORY_FAILURE';

function loadWarrantHistoryStart() {
    return {
        type: LOAD_WARRANT_HISTORY_START,
    };
}

function loadWarrantHistorySuccess() {
    return {
        type: LOAD_WARRANT_HISTORY_SUCCESS,
    };
}

function loadWarrantHistoryFailure(errorMessage: string) {
    return {
        type: LOAD_WARRANT_HISTORY_FAILURE,
        payload: errorMessage,
    };
}

export function loadWarrantHistory(warrantId: number): RmsAction<Promise<void>> {
    return function (dispatch) {
        dispatch(loadWarrantHistoryStart());
        const warrantResource = getWarrantResource();
        return warrantResource
            .getWarrantHistory(warrantId)
            .then((historyEvents: RmsHistoryEvent[]) => {
                dispatch(storeHistoryEventsWithEntityId(historyEvents, warrantId));
                dispatch(loadWarrantHistorySuccess());
            })
            .catch((err: { message: string }) => {
                dispatch(loadWarrantHistoryFailure(err.message));
                throw err;
            });
    };
}

import { createResource } from '../../lib/resources/Resource';
import { req } from '../../lib/ajax';

export default createResource({
    name: 'Shapefiles Admin Resource',
    methods: {
        getShapefiles() {
            return req({
                method: 'GET',
                url: 'master/locations/shapefiles',
            });
        },
        createShapefile(fileId) {
            return req({
                method: 'POST',
                url: `master/locations/shapefiles?file_id=${fileId}`,
            });
        },
        updateShapefile(shapefile) {
            return req({
                method: 'PUT',
                url: `master/locations/shapefiles/${shapefile.id}`,
                data: shapefile,
            });
        },
        deleteShapefile(shapefile) {
            return req({
                method: 'DELETE',
                url: `master/locations/shapefiles/${shapefile.id}`,
            });
        },
        getGeojsonFile(shapefile) {
            return req({
                method: 'GET',
                url: `files/${shapefile.geojsonFileId}`,
            });
        },
    },
});

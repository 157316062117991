import { NameNameLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'nameNameLinks';
const nameNameLinksModule = createLinkModule<NameNameLink>({
    type: NEXUS_STATE_PROP,
    keys: ['nameFromId', 'nameToId', 'linkTypeId'],
});

// SELECTORS
export const nameNameLinksWhereSelector = nameNameLinksModule.selectors.linksWhereSelector;

// REDUCER
export default nameNameLinksModule.reducerConfig;

import { RefContextEnum } from '@mark43/rms-api';
import { chain, get, last, some, sortBy } from 'lodash';

import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import * as fields from '../../../../enums/universal/fields';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '../../../field-configuration-contexts/state/data';
import { rulesSelector } from '../../../rules/state/data';
import { ruleConfigurationContextsWhereSelector } from '../../../rule-configuration-contexts/state/data';

export const NEXUS_STATE_PROP = 'stops';

const stopsModule = createNormalizedModule({
    type: NEXUS_STATE_PROP,
});

// SELECTORS

const stopsWhereSelector = stopsModule.selectors.entitiesWhereSelector;

// if there are multiple stops, get the most recently created stop
export const stopByReportIdSelector = createSelector(
    stopsWhereSelector,
    (stopsWhere) => (reportId) => last(sortBy(stopsWhere({ reportId }), 'createdDateUtc'))
);

export const atlQRuleIsActiveSelector = createSelector(
    fieldConfigurationContextByContextAndFieldNameSelector,
    rulesSelector,
    ruleConfigurationContextsWhereSelector,
    (fieldConfigurationContextByContextAndFieldName, rules, ruleConfigurationContextsWhere) => {
        const atlQFieldsAreVisible = some(
            [
                fieldConfigurationContextByContextAndFieldName(
                    RefContextEnum.FORM_STOP.name,
                    fields.STOP_ATL_Q_1
                ),
                fieldConfigurationContextByContextAndFieldName(
                    RefContextEnum.FORM_STOP.name,
                    fields.STOP_ATL_Q_2
                ),
            ],
            { isStaticallyHidden: false }
        );
        const atlQRuleIsEnabled = chain(rules)
            .find({ name: 'HIDE_ATLANTA_STOP_AND_THINK_FIELDS' })
            .get('id')
            .thru((ruleId) =>
                ruleId
                    ? get(
                          ruleConfigurationContextsWhere({
                              ruleId,
                          }),
                          '[0].isDisabled'
                      ) === false
                    : false
            )
            .value();
        return atlQFieldsAreVisible && atlQRuleIsEnabled;
    }
);

// REDUCER
export default stopsModule.reducerConfig;

import * as React from 'react';
import type { MetaInstanceView } from '@mark43/rms-api';
import type { FormValue } from 'dragon-react';

type DragonFormValueMap = Partial<Record<string, FormValue & { __meta?: MetaInstanceView }>>;
type DragonValueDataContextValue = DragonFormValueMap;

const DragonFormValuesContext = React.createContext<DragonValueDataContextValue | undefined>(
    undefined
);

const DragonSummaryFormValuesContext = React.createContext<DragonValueDataContextValue | undefined>(
    undefined
);

/**
 * Context used to store all initial form values for a given dragon form tree.
 */
export function DragonFormValuesContextProvider({
    value,
    children,
}: React.PropsWithChildren<{
    value: DragonFormValueMap;
}>): JSX.Element {
    return (
        <DragonFormValuesContext.Provider value={value}>
            {children}
        </DragonFormValuesContext.Provider>
    );
}

/**
 * Grants access to all indexed, static form values for all dragon forms in the current context
 */
export function useDragonFormValuesContext(): DragonValueDataContextValue {
    const value = React.useContext(DragonFormValuesContext);
    if (!value) {
        throw new Error(
            'Could not find Dragon form values in context. Please ensure that an instance of `DragonFormValuesContextProvider` has been rendered in the component hierarchy above.'
        );
    }

    return value;
}

/**
 * Context used to store all summary form values for a given dragon form tree.
 */
export function DragonSummaryFormValuesContextProvider({
    value,
    children,
}: React.PropsWithChildren<{
    value: DragonFormValueMap;
}>): JSX.Element {
    return (
        <DragonSummaryFormValuesContext.Provider value={value}>
            {children}
        </DragonSummaryFormValuesContext.Provider>
    );
}

/**
 * Grants access to all indexed, static summary form values for all dragon forms in the current context
 */
export function useDragonSummaryFormValuesContext(): DragonValueDataContextValue {
    const value = React.useContext(DragonSummaryFormValuesContext);
    if (!value) {
        throw new Error(
            'Could not find Dragon form values in context. Please ensure that an instance of `DragonSummaryFormValuesContextProvider` has been rendered in the component hierarchy above.'
        );
    }

    return value;
}

import { first, get } from 'lodash';
import { createSelector } from 'reselect';

import { lookerDashboardsWhereSelector } from '~/client-common/core/domain/looker-dashboards/state/data';

const SELECT_LOOKER_DASHBOARD = 'analysis/SELECT_LOOKER_DASHBOARD';

export function selectLookerDashboard(dashboardId) {
    return {
        type: SELECT_LOOKER_DASHBOARD,
        payload: dashboardId,
    };
}

const uiSelector = (state) => state.ui.analysisDashboard;

const selectedLookerDashboardIdSelector = createSelector(
    uiSelector,
    ({ selectedLookerDashboardId }) => selectedLookerDashboardId
);

export const selectedLookerDashboardUrlSelector = createSelector(
    lookerDashboardsWhereSelector,
    selectedLookerDashboardIdSelector,
    (lookerDashboardsWhere, selectedLookerDashboardId) => {
        return get(first(lookerDashboardsWhere({ lookerId: selectedLookerDashboardId })), 'url');
    }
);

export default function AnalysisDashboardUiReducer(
    state = {
        selectedLookerDashboardId: null,
    },
    action
) {
    switch (action.type) {
        case SELECT_LOOKER_DASHBOARD:
            return {
                ...state,
                selectedLookerDashboardId: action.payload,
            };
        default:
            return state;
    }
}

import { FilterT } from 'arc';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CreateFilterHelper<TFilterList extends any[] = [], TFilterOptions = string> {
    filterList: TFilterList;

    constructor() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.filterList = [] as any;
    }

    add<TFilterOption = string>(
        filter: FilterT<TFilterOption>
    ): CreateFilterHelper<
        [...TFilterList, FilterT<TFilterOption>],
        TFilterOptions | TFilterOption
    > {
        this.filterList.push(filter);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this as any;
    }

    /*
        it transforms the real type like FilterT<string> | FilterT<DateRangeQuery>[] ... and so on
        to a type that FilterList component expect like FilterT<string | DateRangeQuery>
     */
    getFilters(): FilterT<TFilterOptions>[] {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return this.filterList as any;
    }
}

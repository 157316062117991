import React from 'react';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { CaseManagementErrorBoundary } from '../../../core/errors/components/ErrorBoundary';
import CasesDashboardContainer from './CasesDashboardContainer';
import CaseContainer from './CaseContainer';

const CaseWrapper = ({ children, ...props }) => {
    return (
        <div style={{ width: '100%', display: 'flex', height: '100%' }} {...props}>
            {children}
        </div>
    );
};
/**
 * Determine whether or not we're displaying content from cases dashboard
 *   or a single case, we do this by inspecting which component React
 *   router has sent to us.
 * @param {Object} props
 */
export default renderOnlyIf(({ children }) => children)(function CasesContainer({
    children,
    ...otherProps
}) {
    const container = React.createElement(
        children.type === CaseContainer ? CaseWrapper : CasesDashboardContainer,
        otherProps,
        children
    );

    return <CaseManagementErrorBoundary>{container}</CaseManagementErrorBoundary>;
});

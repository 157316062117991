import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';

export function assertNotNullOrUndefined<T>(
    value: T,
    message: string
): asserts value is NonNullable<T> {
    if (isUndefinedOrNull(value)) {
        throw new Error(message);
    }
}

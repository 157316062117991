import { get, some } from 'lodash';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { offenseVictimProfileGetter } from './offenseVictimProfileGetter';

const STRING = 'string';
const BOOLEAN = 'boolean';
const NUMBER = 'number';
const NULL = 'null';

const strictComparison = (a, b) => a === b;
export const getOffenseHasVictim = (getState) => {
    const getOffenseVictimProfiles = offenseVictimProfileGetter(getState);
    return (offenseId, { entityType, path, value, valueType } = {}) => {
        let castValue;
        switch (valueType) {
            case STRING:
                castValue = value;
                break;
            case NUMBER:
                castValue = Number(value);
                break;
            case BOOLEAN:
                castValue = value.trim().toLowerCase() === 'true';
                break;
            case NULL:
                castValue = null;
                break;
            default:
                throw new Error(`Invalid value type specified for rule ${valueType}`);
        }
        const victimProfiles = getOffenseVictimProfiles(offenseId, [entityType]);
        const comparator = castValue === null ? isUndefinedOrNull : strictComparison;
        return some(victimProfiles, (profile) => comparator(get(profile, path), castValue));
    };
};

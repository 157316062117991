import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { sortedProfilePropertyViewModelsSelector } from '../state/ui';
import NoDataBlock from '../../../core/components/NoDataBlock';
import ElasticModelWrapper from '../../../../legacy-redux/components/core/tables/ElasticModelWrapper';
import EntityProfileSection from './EntityProfileSection';
import EntityProfilePropertySummary from './EntityProfilePropertySummary';

const strings = componentStrings.entityProfiles.EntityProfileProperty;

function EntityProfileProperty({ profileProperty }) {
    const contents = profileProperty.length ? (
        <EntityProfileSection title={strings.propertyTitle}>
            {map(profileProperty, (property) => (
                <ElasticModelWrapper key={property.id} entity={property}>
                    {({ hasReadAccess, entity }) => (
                        <EntityProfilePropertySummary
                            hasReadAccess={hasReadAccess}
                            elasticProperty={entity}
                        />
                    )}
                </ElasticModelWrapper>
            ))}
        </EntityProfileSection>
    ) : (
        <div className="no-data-container">
            <NoDataBlock>{strings.noPropertyText}</NoDataBlock>
        </div>
    );
    return <div>{contents}</div>;
}

const mapStateToProps = createStructuredSelector({
    profileProperty: sortedProfilePropertyViewModelsSelector,
});

export default connect(mapStateToProps)(EntityProfileProperty);

import React from 'react';
import styled from 'styled-components';
import { Status, cssVar } from 'arc';
import { BriefingStateEnumType } from '@mark43/rms-api';

import testIds from '../../../../core/testIds';
import { SubheaderTitle } from '../../../core/components/Subheader';

import { BRIEFING_STATE_STATUS_MAP } from '../../configuration';

interface BriefingTitleProps {
    title: string;
    briefingState: BriefingStateEnumType;
}

const BriefingTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${cssVar('arc.space.3')};
    min-width: 0;
`;

const BriefingTitle: React.FC<BriefingTitleProps> = ({ title, briefingState }) => {
    return (
        <BriefingTitleWrapper>
            <SubheaderTitle title={title} testId={testIds.BRIEFING_TITLE} />
            <Status
                style={{ flexShrink: 0 }}
                status={BRIEFING_STATE_STATUS_MAP[briefingState]}
                data-test-id={testIds.BRIEFING_STATUS_INDICATOR}
            >
                {briefingState}
            </Status>
        </BriefingTitleWrapper>
    );
};

export default BriefingTitle;

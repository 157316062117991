import React from 'react';
import { useSelector } from 'react-redux';
import { Stack } from 'arc';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { sortedChargesWithDetentionsForReportIdSelector } from '~/client-common/core/domain/charges/state/ui';
import { ConnectedFormattedUser, FORMATS } from '~/client-common/core/users/components';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import * as fields from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../../core/testIds';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import ChargeTitle from '../../../../reports/core/components/booking/ChargeTitle';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import RecordSidebarSection from './RecordSidebarSection';

const strings = componentStrings.reports.ReportSidebar;

export const DetentionSection = (props: { reportId: number }) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const sortedChargesWithDetentions = useSelector(sortedChargesWithDetentionsForReportIdSelector)(
        props.reportId
    );
    const formatFieldByName = useSelector(formatFieldByNameSelector);

    return (
        <RecordSidebarSection
            collapsible={true}
            title={strings.DetentionSection.title}
            sectionTestId={testIds.REPORT_SIDEBAR_DETENTIONS_SECTION}
        >
            <Stack spacing={0} gap={4}>
                {sortedChargesWithDetentions.map(({ charge, detention }) => {
                    return (
                        <Stack spacing={0} gap={2} key={charge.id}>
                            <ChargeTitle charge={charge} boldSummaryText={true} />
                            <SummaryList labelWidth={'40%'} contentWidth={'60%'}>
                                <SummaryRow
                                    label={formatFieldByName(
                                        fields.DETENTION_NON_FILING_TYPE_ATTR_ID
                                    )}
                                >
                                    {formatAttributeById(detention.nonFilingTypeAttrId)}
                                </SummaryRow>
                                <SummaryRow
                                    label={formatFieldByName(
                                        fields.DETENTION_NON_FILING_REASON_CODE_ATTR_ID
                                    )}
                                >
                                    {formatAttributeById(detention.nonFilingReasonCodeAttrId)}
                                </SummaryRow>
                                <SummaryRow
                                    label={formatFieldByName(
                                        fields.DETENTION_ORIGINAL_COURT_CASE_NUMBER
                                    )}
                                >
                                    {detention.originalCourtCaseNumber}
                                </SummaryRow>
                                <SummaryRow
                                    label={formatFieldByName(
                                        fields.DETENTION_ORIGINAL_COURT_DISTRICT_NUMBER
                                    )}
                                >
                                    {detention.originalCourtDistrictNumber}
                                </SummaryRow>
                                <SummaryRowDate
                                    label={formatFieldByName(fields.DETENTION_COURT_ORDER_DATE_UTC)}
                                    date={detention.courtOrderDateUtc}
                                    format={FormattedDate.FORMATS.SUMMARY_DATE}
                                />
                                <SummaryRow
                                    label={formatFieldByName(fields.DETENTION_COURT_ORDER_NUMBER)}
                                >
                                    {detention.courtOrderNumber}
                                </SummaryRow>
                                <SummaryRow label={strings.DetentionSection.updatedBy}>
                                    <ConnectedFormattedUser
                                        userId={detention.createdBy}
                                        format={FORMATS.FULL_NAME}
                                    />
                                </SummaryRow>
                                <SummaryRowDate
                                    label={strings.DetentionSection.updatedDate}
                                    date={detention.updatedDateUtc}
                                />
                            </SummaryList>
                        </Stack>
                    );
                })}
            </Stack>
        </RecordSidebarSection>
    );
};

import { get } from 'lodash';

import {
    REMOVE_ID_FORMAT_CONFIGURATION_START,
    REMOVE_ID_FORMAT_CONFIGURATION_SUCCESS,
    REMOVE_ID_FORMAT_CONFIGURATION_FAILURE,
    removeIdFormatConfigurationStart,
    removeIdFormatConfigurationSuccess,
    removeIdFormatConfigurationFailure,
} from '~/client-common/core/domain/id-format-configurations/state/data';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import {
    SAVE_ID_FORMAT_START,
    SAVE_ID_FORMAT_SUCCESS,
    SAVE_ID_FORMAT_FAILURE,
    LOAD_ID_FORMATS_START,
    LOAD_ID_FORMATS_FAILURE,
    LOAD_ID_FORMATS_SUCCESS,
    loadIdFormatsStart,
    loadIdFormatsSuccess,
    loadIdFormatsFailure,
    saveIdFormatStart,
    saveIdFormatSuccess,
    saveIdFormatFailure,
    saveSequenceIncreaseStart,
    saveSequenceIncreaseSuccess,
    saveSequenceIncreaseFailure,
} from '../../../../support/id-generator/state/data';

type UiState = {
    loadingList: boolean;
    loadingListError?: string;
    saving: boolean;
    savingError?: string;
    removing: boolean;
    removingError?: string;
};

const initialUiState = {
    loadingList: false,
    loadingListError: undefined,
    saving: false,
    savingError: undefined,
    removing: false,
    removingError: undefined,
};

type WarrantIdGeneratorActionTypes =
    | ReturnType<typeof loadIdFormatsStart>
    | ReturnType<typeof loadIdFormatsSuccess>
    | ReturnType<typeof loadIdFormatsFailure>
    | ReturnType<typeof saveIdFormatStart>
    | ReturnType<typeof saveIdFormatSuccess>
    | ReturnType<typeof saveIdFormatFailure>
    | ReturnType<typeof saveSequenceIncreaseStart>
    | ReturnType<typeof saveSequenceIncreaseSuccess>
    | ReturnType<typeof saveSequenceIncreaseFailure>
    | ReturnType<typeof removeIdFormatConfigurationStart>
    | ReturnType<typeof removeIdFormatConfigurationSuccess>
    | ReturnType<typeof removeIdFormatConfigurationFailure>;

export default makeResettable(
    ENTER_NEW_ROUTE,
    function warrantNumberConfigurationAdminUiReducer(
        state: UiState = initialUiState,
        action: WarrantIdGeneratorActionTypes
    ): UiState {
        switch (action.type) {
            case LOAD_ID_FORMATS_FAILURE:
                return {
                    ...state,
                    loadingList: false,
                    loadingListError: action.payload,
                };
            case LOAD_ID_FORMATS_START:
                return {
                    ...state,
                    loadingList: true,
                    loadingListError: undefined,
                };
            case LOAD_ID_FORMATS_SUCCESS:
                return {
                    ...state,
                    loadingList: false,
                    loadingListError: undefined,
                };
            case SAVE_ID_FORMAT_START:
                return {
                    ...state,
                    saving: true,
                    savingError: undefined,
                };
            case SAVE_ID_FORMAT_SUCCESS:
                return {
                    ...state,
                    saving: false,
                    savingError: undefined,
                };
            case SAVE_ID_FORMAT_FAILURE:
                return {
                    ...state,
                    saving: false,
                    savingError: action.payload,
                };
            case REMOVE_ID_FORMAT_CONFIGURATION_START:
                return {
                    ...state,
                    removing: true,
                    removingError: undefined,
                };
            case REMOVE_ID_FORMAT_CONFIGURATION_FAILURE:
                return {
                    ...state,
                    removing: false,
                    removingError: action.payload,
                };
            case REMOVE_ID_FORMAT_CONFIGURATION_SUCCESS:
                return {
                    ...state,
                    removing: false,
                    removingError: undefined,
                };

            default:
                return state;
        }
    }
);

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const uiSelector = (state: any): UiState => get(state, 'ui.warrantNumberConfigurationAdmin');

import { AttributeTypeEnum } from '@mark43/rms-api';

import { get, map, omit } from 'lodash';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { replaceUseOfForceCardBundle } from '~/client-common/core/domain/reports/state/ui';
import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import { nibrsOffenseCodesSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { refreshUseOfForceForm, convertFromFormModel } from '../forms/useOfForceForm';
import createCard from '../../utils/createCard';
import { submitCard, validateCard } from './cards';

const baseCard = createCard({
    name: reportCardEnum.USE_OF_FORCE.name,
    baseSelector: (state) => state.ui.report.useOfForceCard,
    anchor: 'use-of-force-card',
});

export default {
    ...baseCard,
    actionCreators: {
        ...baseCard.actionCreators,
        edit() {
            return (dispatch) => {
                dispatch(baseCard.actionCreators.transitionToEditMode());
            };
        },
        save(form) {
            return (dispatch, getState) => {
                form.resetUi();
                const state = getState();
                const report = currentReportSelector(state);
                const reportId = report.id;
                const isInSummaryMode = baseCard.selectors.summaryModeSelector(state);

                if (isInSummaryMode) {
                    return dispatch(
                        validateCard({
                            card: baseCard,
                            formComponent: form,
                        })
                    );
                } else {
                    return dispatch(
                        submitCard({
                            card: baseCard,
                            formComponent: form,
                            getPromisesForSubmission: () => {
                                const { model } = form.getState();
                                const attributes = attributesSelector(state);

                                const convertedFormModel = convertFromFormModel(model, {
                                    nibrsOffenseCodes: nibrsOffenseCodesSelector(state),
                                });

                                const reportAttributes = assignDescriptionToReportAttributes(
                                    map(model.useOfForceStatistics, (useOfForceStatistic) => {
                                        return {
                                            reportId,
                                            attributeType:
                                                AttributeTypeEnum.USE_OF_FORCE_STATISTICS.name,
                                            attributeId: useOfForceStatistic,
                                        };
                                    }),
                                    attributes,
                                    model.useOfForceStatisticsDescription
                                );
                                const otherInvolvedAgencies = map(
                                    model.otherInvolvedAgencies,
                                    (otherInvolvedAgency) => {
                                        return {
                                            reportId,
                                            ...otherInvolvedAgency,
                                        };
                                    }
                                );
                                const useOfForce = omit(convertedFormModel, [
                                    'useOfForceStatistics',
                                    'useOfForceStatisticsDescription',
                                    'useOfForceLocation',
                                ]);
                                const locationEntityLink = isUndefinedOrNull(
                                    get(model.useOfForceLocation, 'locationId')
                                )
                                    ? undefined
                                    : model.useOfForceLocation;
                                const useOfForceCardBundle = {
                                    useOfForce,
                                    reportAttributes,
                                    locationEntityLink,
                                    otherInvolvedAgencies,
                                };

                                return [
                                    dispatch(
                                        replaceUseOfForceCardBundle({
                                            reportId,
                                            useOfForceCardBundle,
                                        })
                                    ),
                                ];
                            },
                            onSavingSuccess: () => {
                                // Always re-sync `formModel` state.
                                const formModel = dispatch(refreshUseOfForceForm({ reportId }));
                                form.set('', formModel);
                            },
                        })
                    );
                }
            };
        },
    },
};

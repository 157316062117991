import React from 'react';
import styled from 'styled-components';
import _ItemInfo from '../../../../../modules/evidence/core/components/ItemInfo';
import { ITEM_INFO_ICON_WIDTH } from '../../../../../modules/evidence/core/configuration';

const CellWrapper = styled.div`
    margin-left: 15px;
    display: flex;
    flex-direction: column;
`;
const ItemInfo = styled(_ItemInfo)`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    display: flex;
`;
const BarcodeValue = styled.div`
    margin-left: ${ITEM_INFO_ICON_WIDTH}px;
`;

export default function DescriptionCell(evidenceItem) {
    return (
        <CellWrapper>
            <ItemInfo
                viewModel={evidenceItem}
                disableLink={true}
                hideRenSequence={true}
                showHighRiskLabel={true}
            />
            <BarcodeValue>{evidenceItem.barcodeValue}</BarcodeValue>
        </CellWrapper>
    );
}

import { createSelector } from 'reselect';
import { chain } from 'lodash';
import { ComplianceExportTypeEnum, ComplianceExportTypeEnumType } from '@mark43/rms-api';

import { agencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { checkIfDepartmentIsNibrs } from '~/client-common/helpers/departmentProfilesHelper';
import { prettify } from '~/client-common/helpers/stringHelpers';

import { RootState } from '../../../../legacy-redux/reducers/rootReducer';
import {
    currentUserDepartmentProfileSelector,
    currentUserHasAbilitySelector,
} from '../../../core/current-user/state/ui';
import complianceExportResource from '../../resources/complianceExportResource';
import { NIBRS_COMPLIANCE_TYPE } from '../../configuration';
import { SupportedComplianceExportType } from '../../types';
import { RmsAction } from '../../../../core/typings/redux';

type ComplianceUiState = {
    lastExportedDate?: string;
    loadingErrorMessage?: string;
    complianceExportTypesLoaded: boolean;
    supportedComplianceTypes: SupportedComplianceExportType[];
    disableComplianceExportFormControls: boolean;
};

const GET_COMPLIANCE_EXPORT_TYPES_SUCCESS = 'GET_COMPLIANCE_EXPORT_TYPES_SUCCESS';
const GET_COMPLIANCE_EXPORT_TYPES_FAILURE = 'GET_COMPLIANCE_EXPORT_TYPES_FAILURE';
const STORE_LAST_EXPORTED_DATE = 'STORE_LAST_EXPORTED_DATE';
const STORE_NIBRS_COMPLIANCE_TYPE = 'STORE_NIBRS_COMPLIANCE_TYPE';
const RESET_COMPLIANCE_UI_STATE = 'RESET_COMPLIANCE_UI_STATE';
const DISABLE_COMPLIANCE_EXPORT_FORM_CONTROLS = 'DISABLE_COMPLIANCE_EXPORT_FORM_CONTROLS';

function uiStoreNibrsComplianceType() {
    return { type: STORE_NIBRS_COMPLIANCE_TYPE, payload: NIBRS_COMPLIANCE_TYPE.name } as const;
}

export function uiStoreLastExportedDate(lastExporteDate?: string) {
    return { type: STORE_LAST_EXPORTED_DATE, payload: lastExporteDate } as const;
}

export function uiDisableComplianceExportFormControls(
    disableComplianceExportFormControls: boolean
) {
    return {
        type: DISABLE_COMPLIANCE_EXPORT_FORM_CONTROLS,
        payload: disableComplianceExportFormControls,
    } as const;
}

function getComplianceTypeSuccess(complianceTypes?: SupportedComplianceExportType[]) {
    return { type: GET_COMPLIANCE_EXPORT_TYPES_SUCCESS, payload: complianceTypes } as const;
}

function getComplianceTypeFailure(errorMessage: string) {
    return { type: GET_COMPLIANCE_EXPORT_TYPES_FAILURE, payload: errorMessage } as const;
}

export function resetComplianceUiState() {
    return { type: RESET_COMPLIANCE_UI_STATE } as const;
}

export function getComplianceExportTypes(): RmsAction<Promise<ComplianceExportTypeEnumType[]>> {
    return (dispatch, getState) => {
        const state = getState();
        const departmentProfile = currentUserDepartmentProfileSelector(state);
        const hasAbility = currentUserHasAbilitySelector(state);

        // if this department is configured for NIBRS, then add it here because we don't
        // model NIBRS as a ComplianceExportType
        if (departmentProfile && checkIfDepartmentIsNibrs(departmentProfile)) {
            dispatch(uiStoreNibrsComplianceType());
        }

        // `getComplianceExportTypes` requires this ability. We can skip resource call because
        // NIBRS users will still have access to NIBRS.
        if (!hasAbility(abilitiesEnum.REPORTING.COMPLIANCE_EXPORT_DASHBOARD)) {
            dispatch(getComplianceTypeSuccess());

            return Promise.resolve([]);
        }

        return complianceExportResource
            .getComplianceExportTypes()
            .then((complianceExportTypes) => {
                dispatch(getComplianceTypeSuccess(complianceExportTypes));

                return complianceExportTypes;
            })
            .catch((err) => {
                dispatch(getComplianceTypeFailure(err.message));
            });
    };
}

const uiSelector = (state: RootState) => state.ui.compliance;

export const complianceExportTypesLoadedSelector = createSelector(
    uiSelector,
    (ui) => ui.complianceExportTypesLoaded
);

export const complianceExportTypesLoadingErrorMessageSelector = createSelector(
    uiSelector,
    (ui) => ui.loadingErrorMessage
);

export const supportedComplianceTypesSelector = createSelector(
    uiSelector,
    (ui) => ui.supportedComplianceTypes
);

export const disableComplianceExportFormControlsSelector = createSelector(
    uiSelector,
    (ui) => ui.disableComplianceExportFormControls
);

export const nibrsConfigStartDateUtcByAgencyIdSelector = createSelector(
    agencyProfileByIdSelector,
    (agencyProfileById) => (agencyId?: string | number) => {
        const agencyProfile = agencyProfileById(agencyId);
        return agencyProfile?.nibrsStartDateUtc;
    }
);

export const lastExportedDateSelector = createSelector(uiSelector, (ui) => ui.lastExportedDate);

export const complianceExportTypeDisplaySelector = createSelector(
    currentUserDepartmentProfileSelector,
    (currentUserDepartmentProfile) => (complianceExportType: SupportedComplianceExportType) => {
        switch (complianceExportType) {
            case NIBRS_COMPLIANCE_TYPE.name:
                return chain(currentUserDepartmentProfile?.nibrsRegionalGroup)
                    .lowerCase()
                    .startCase()
                    .replace('Nibrs', 'NIBRS')
                    .value();
            case ComplianceExportTypeEnum.CALIFORNIA_RIPA.name:
                return chain(complianceExportType)
                    .lowerCase()
                    .startCase()
                    .replace('Ripa', 'RIPA')
                    .value();
            case ComplianceExportTypeEnum.US_USE_OF_FORCE.name:
                return chain(complianceExportType)
                    .lowerCase()
                    .startCase()
                    .replace('Us', 'US')
                    .value();
            default:
                return prettify(ComplianceExportTypeEnum[complianceExportType]?.name);
        }
    }
);

const initialState: ComplianceUiState = {
    lastExportedDate: undefined,
    loadingErrorMessage: undefined,
    complianceExportTypesLoaded: false,
    supportedComplianceTypes: [],
    disableComplianceExportFormControls: false,
};

type ComplianceAction =
    | ReturnType<typeof uiStoreNibrsComplianceType>
    | ReturnType<typeof uiStoreLastExportedDate>
    | ReturnType<typeof uiDisableComplianceExportFormControls>
    | ReturnType<typeof getComplianceTypeSuccess>
    | ReturnType<typeof getComplianceTypeFailure>
    | ReturnType<typeof resetComplianceUiState>;

function complianceUiReducer(state = initialState, action: ComplianceAction): ComplianceUiState {
    switch (action.type) {
        case GET_COMPLIANCE_EXPORT_TYPES_SUCCESS:
            return {
                ...state,
                supportedComplianceTypes: [
                    ...state.supportedComplianceTypes,
                    ...(action.payload || []),
                ],
                complianceExportTypesLoaded: true,
                loadingErrorMessage: undefined,
            };
        case GET_COMPLIANCE_EXPORT_TYPES_FAILURE:
            return {
                ...state,
                complianceExportTypesLoaded: false,
                loadingErrorMessage: action.payload,
            };
        case STORE_NIBRS_COMPLIANCE_TYPE:
            return {
                ...state,
                supportedComplianceTypes: [...state.supportedComplianceTypes, action.payload],
            };
        case STORE_LAST_EXPORTED_DATE:
            return {
                ...state,
                lastExportedDate: action.payload,
            };
        case DISABLE_COMPLIANCE_EXPORT_FORM_CONTROLS:
            return {
                ...state,
                disableComplianceExportFormControls: action.payload,
            };
        case RESET_COMPLIANCE_UI_STATE:
            return initialState;

        default:
            return state;
    }
}

export default complianceUiReducer;

import styled from 'styled-components';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';

const GreyHeaderRRFFieldset = styled(RRFFieldset)`
    & > .mark43-fieldset-header {
        color: ${(props) => props.theme.colors.mediumLightGrey};
        border-bottom: none;
        padding-bottom: none;
        float: left;
        margin-top: 5px;
    }
`;

export default GreyHeaderRRFFieldset;

export type SearchState = {
    searchTerm: string;
    filteredAssets: string[];
    isLoading: boolean;
};

type SearchAction =
    | { type: 'SEARCH_START'; payload: { searchTerm: string } }
    | { type: 'SEARCH_SUCCESS'; payload: { filteredAssets: string[] } };

export const searchReducer = (state: SearchState, action: SearchAction): SearchState => {
    switch (action.type) {
        case 'SEARCH_START':
            return {
                ...state,
                searchTerm: action.payload.searchTerm,
                isLoading: true,
            };
        case 'SEARCH_SUCCESS':
            return {
                ...state,
                filteredAssets: action.payload.filteredAssets,
                isLoading: false,
            };
        default:
            return state;
    }
};

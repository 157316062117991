import { ReportReportLink } from '@mark43/rms-api';
import { find } from 'lodash';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'reportReportLinks';

const reportReportLinksModule = createNormalizedModule<ReportReportLink>({
    type: NEXUS_STATE_PROP,
});

export const storeReportReportLinks = reportReportLinksModule.actionCreators.storeEntities;

export const reportReportLinksWhereSelector =
    reportReportLinksModule.selectors.entitiesWhereSelector;

// This selector checks for intra-department links and for inter-department links
// those are two-way, which means they can appear in either direction
// this does not return suggested associations for a report
// aka, cross-department links pointing at a report but not reciprocated
export const reportReportLinksByReportIdSelector = createSelector(
    reportReportLinksWhereSelector,
    (reportReportLinksWhere) => (reportId: number) => {
        return reportReportLinksWhere(
            ({ departmentId, toDepartmentId, fromReportId, toReportId }) => {
                return (
                    fromReportId === reportId ||
                    (departmentId === toDepartmentId && toReportId === reportId)
                );
            }
        );
    }
);

// this selector is O(n^2) so watch out
export const suggestedReportReportLinksByReportIdSelector = createSelector(
    reportReportLinksWhereSelector,
    (reportReportLinksWhere) => (reportId: number) => {
        const allReportReportLinks = reportReportLinksWhere(
            ({ fromReportId, toReportId }) => fromReportId === reportId || toReportId === reportId
        );
        return reportReportLinksWhere(
            ({ departmentId, toDepartmentId, toReportId, fromReportId }) => {
                const isCrossDepartmentLink =
                    departmentId !== toDepartmentId && toReportId === reportId;
                if (!isCrossDepartmentLink) {
                    return false;
                }
                // return only links that don't have a corresponding link pointing the other direction
                return !find(
                    allReportReportLinks,
                    ({ fromReportId: otherFromReportId, toReportId: otherToReportId }) =>
                        otherFromReportId === toReportId && otherToReportId === fromReportId
                );
            }
        );
    }
);

export default reportReportLinksModule.reducerConfig;

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { map, mapValues, orderBy } from 'lodash';
import { createSelector } from 'reselect';
import { sortedLocationBundlesForLocationEntityLinksWhereSelector } from '../../../locations/state/ui';
import { organizationProfilesSelector } from '../data';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { namePhonesByNameIdSelector } from '../../../name-phones/state/data';
import { nameEmailsByNameIdSelector } from '../../../name-emails/state/data';
import { nameIdentifierViewModelsByNameIdSelector } from '../../../name-identifiers/state/ui';
import { attachmentsWhereSelector } from '../../../attachments/state/data';
import { legacyEntityDetailsWhereSelector } from '../../../legacy-entity-details/state/data';
import {
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { formatAttributeWithOther } from '../../../attributes/utils/attributesHelpers';

const organizationProfileViewModelsSelector = createSelector(
    organizationProfilesSelector,
    formatAttributeByIdSelector,
    namePhonesByNameIdSelector,
    nameEmailsByNameIdSelector,
    nameIdentifierViewModelsByNameIdSelector,
    legacyEntityDetailsWhereSelector,
    attachmentsWhereSelector,
    (
        orgProfiles,
        formatAttributeById,
        namePhonesByNameId,
        nameEmailsByNameId,
        nameIdentifierViewModelsByNameId,
        legacyEntityDetailsWhere,
        attachmentsWhere
    ) => {
        const viewModelBuilder = buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                ({ id }) => ({
                    phoneNumbers: namePhonesByNameId(id),
                    emails: nameEmailsByNameId(id),
                    identifiers: nameIdentifierViewModelsByNameId(id),
                }),
                ({ organizationTypeAttrId, industryAttrId }) => ({
                    names: {
                        full: joinTruthyValues(
                            [
                                formatAttributeById(organizationTypeAttrId),
                                formatAttributeById(industryAttrId),
                            ],
                            ': '
                        ),
                    },
                }),
                ({ industryAttrId, industryOther }) => ({
                    industry: formatAttributeWithOther(
                        formatAttributeById(industryAttrId),
                        industryOther
                    ),
                }),
                ({ id }) => ({
                    legacyEntityDetails: legacyEntityDetailsWhere({
                        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                        entityId: id,
                    }),
                }),
                ({ id }) => {
                    const images = orderBy(
                        attachmentsWhere({
                            attachmentType: EntityTypeEnum.IMAGE.name,
                            linkType: LinkTypesEnum.ORGANIZATION_PROFILE_PHOTO,
                            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                            entityId: id,
                        }),
                        'createdDateUtc',
                        'desc'
                    );

                    return {
                        primaryProfilePhoto: map(images, 'image')[0],
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return mapValues(orgProfiles, viewModelBuilder);
    }
);

export const organizationProfileViewModelByIdSelector = createSelector(
    organizationProfileViewModelsSelector,
    (organizationProfileViewModels) => (id) => organizationProfileViewModels[id]
);

export const organizationLocationsByProfileIdSelector = createSelector(
    sortedLocationBundlesForLocationEntityLinksWhereSelector,
    (sortedLocationBundlesForLocationEntityLinksWhere) => (organizationProfileId) =>
        sortedLocationBundlesForLocationEntityLinksWhere({
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            entityId: organizationProfileId,
            linkType: LinkTypesEnum.BUSINESS_LOCATION,
        })
);

export const organizationAddressesByProfileIdSelector = createSelector(
    sortedLocationBundlesForLocationEntityLinksWhereSelector,
    (sortedLocationBundlesForLocationEntityLinksWhere) => (organizationProfileId) =>
        sortedLocationBundlesForLocationEntityLinksWhere({
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            entityId: organizationProfileId,
            linkType: LinkTypesEnum.BUSINESS_ADDRESS,
        })
);

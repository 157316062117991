import _ from 'lodash';
import { createSelector } from 'reselect';

import { labelPrintersSelector } from '../data';

/**
 * All label printers formatted as dropdown options and sorted by display
 *   string.
 * @return {Object[]}
 */
export const labelPrinterOptionsSelector = createSelector(labelPrintersSelector, (labelPrinters) =>
    _(labelPrinters)
        .map(({ id, name }) => ({
            value: id,
            display: name,
        }))
        .sortBy(({ display }) => display.toLowerCase())
        .value()
);

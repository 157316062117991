import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedRouter, withRouter } from 'react-router';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../../../../core/testIds';
import { Button } from '../../../../../../core/components/Button';
import { getExportOptions } from '../../../../state/ui';

const strings = componentStrings.evidence.dashboard.EvidenceDashboardSearchResults;

const ExportBulkActionBase = ({ router }: { router: InjectedRouter }) => {
    const dispatch = useDispatch();

    const onExportClick = useCallback(() => {
        dispatch(getExportOptions());
        router.push('evidence/dashboard/exports');
    }, [dispatch, router]);

    return (
        <Button
            isTextTransformNone
            leadingVisual="Export"
            onClick={() => onExportClick()}
            testId={testIds.EVIDENCE_DASHBOARD_ACTION_BAR_EXPORT}
        >
            {strings.actionButtons.export}
        </Button>
    );
};

export const ExportBulkAction = withRouter(ExportBulkActionBase);

import { EntityTypeEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { Briefing } from '~/client-common/core/domain/briefings/state/data';

import { GenericPermissionsModalProps } from '../../core/permissions/components/GenericPermissionsModal';
import { useOverlayStore } from '../../core/overlays/hooks/useOverlayStore';

const strings = componentStrings.briefings.core;

const briefingPermissionsModalId = overlayIdEnum.BRIEFING_PERMISSIONS_MODAL;

// Should be used in combination with GenericPermissionsModal
// Provides all the necessary props
const useBriefingPermissionsModal = (briefing: Briefing) => {
    const overlayStore = useOverlayStore();

    const permissionsModalProps: GenericPermissionsModalProps = {
        entityId: briefing.id,
        entityType: EntityTypeEnum.BRIEFING.name,
        overlayId: briefingPermissionsModalId,
        permissionSet: briefing.permissionSet,
        title: strings.permissionsModalTitle(briefing.title),
    };

    const openPermissionsModal = () => overlayStore.open(briefingPermissionsModalId);

    return { openPermissionsModal, permissionsModalProps };
};

export default useBriefingPermissionsModal;

import {
    createFormConfiguration,
    lifecycleOptions,
    createField,
    createNItems,
    InferFormDataShape,
} from 'markformythree';
import { concat, filter, find, map, pick, uniqWith, includes } from 'lodash';
import {
    EntityTypeEnumType,
    TaskEntityLinkView,
    TaskView,
    RefContextEnum,
    EntityTypeEnum,
    ClientApprovalStatusEnumType,
    ApprovalStatusForCaseEnumType,
} from '@mark43/rms-api';
import * as fields from '~/client-common/core/enums/universal/fields';
import type { ArrayElement } from '~/client-common/types';
import { allowedTaskLinkEntityTypes } from '../config';

const configuration = createFormConfiguration({
    priorityAttrId: createField<number>({ fieldName: fields.TASK_PRIORITY_ATTR_ID }),
    statusAttrId: createField<number>({ fieldName: fields.TASK_STATUS_ATTR_ID }),
    assigneeRoleId: createField<number>({ fieldName: fields.TASK_ASSIGNEE_ROLE_ID }),
    dueDateUtc: createField<string>({ fieldName: fields.TASK_DUE_DATE_UTC }),
    description: createField<string>({ fieldName: fields.TASK_DESCRIPTION }),
    /* Start Hidden Fields */
    id: createField<number>({}),
    typeAttrId: createField<number>({ fieldName: fields.TASK_TYPE_ATTR_ID }),
    title: createField<string>({ fieldName: fields.TASK_TITLE }),
    // ownerId and ownerType are to track task entity owner data
    ownerId: createField<number>({}),
    ownerType: createField<EntityTypeEnumType>({}),
    taskEntityLinks: createNItems({
        fields: {
            entityId: createField<number>({}),
            entityType: createField<EntityTypeEnumType>({}),
            // all the fields below are used to display the linked entity in the UI, not to save to the database
            entityTitle: createField<string>({}),
            itemTypeAttrId: createField<number>({}),
            clientApprovalStatus: createField<ClientApprovalStatusEnumType>({}),
            // this value comes from TaskView rather than TaskEntityLinkView, which is why it is not in
            // taskEntityLinkFormPaths
            approvalStatusForCase: createField<ApprovalStatusForCaseEnumType>({}),
        },
    }),
    /* End Hidden Fields */
});

export type FormTaskConfiguration = typeof configuration;
type FormTask = InferFormDataShape<FormTaskConfiguration>;
export type TaskEntityLinkFormDataShape = ArrayElement<FormTask['taskEntityLinks']>;

const taskEntityLinkFormPaths = [
    'entityId',
    'entityType',
    'entityTitle',
    'itemTypeAttrId',
    'clientApprovalStatus',
];
const taskModelFormPaths = [
    'assigneeRoleId',
    'description',
    'dueDateUtc',
    'id',
    'ownerId',
    'ownerType',
    'priorityAttrId',
    'statusAttrId',
    'typeAttrId',
    'title',
];

export function convertToFormModel(
    task: Partial<TaskView>,
    taskEntityLinksEnabled?: boolean,
    taskEntityLinks?: Partial<TaskEntityLinkView>[]
) {
    const taskFormModel = pick(task, taskModelFormPaths);
    if (taskEntityLinksEnabled) {
        const formTaskEntityLinks = map(taskEntityLinks, (taskEntityLink) =>
            pick(taskEntityLink, taskEntityLinkFormPaths)
        );

        const ownerTaskEntityLink = find(taskEntityLinks, {
            entityId: task.ownerId,
            entityType: task.ownerType,
        });

        if (
            task.ownerType &&
            task.ownerType !== EntityTypeEnum.TASK.name &&
            task.ownerId &&
            !ownerTaskEntityLink
        ) {
            return {
                ...pick(task, taskModelFormPaths),
                taskEntityLinks: concat(
                    {
                        entityId: task.ownerId,
                        entityType: task.ownerType,
                        entityTitle: task.ownerTitle,
                    },
                    formTaskEntityLinks
                ),
            };
        }

        return {
            ...taskFormModel,
            taskEntityLinks: formTaskEntityLinks,
        };
    }
    return taskFormModel;
}

function confirmOwnerEntityLink(
    task: Partial<TaskView>,
    taskEntityLinks: Partial<TaskEntityLinkView>[]
) {
    switch (task.ownerType) {
        case EntityTypeEnum.ITEM_PROFILE.name:
        case EntityTypeEnum.PERSON_PROFILE.name:
        case EntityTypeEnum.REPORT.name: {
            const ownerEntityLinkPredicate = { entityId: task.ownerId, entityType: task.ownerType };

            if (find(taskEntityLinks, ownerEntityLinkPredicate)) {
                return { task, taskEntityLinks };
            }

            return {
                task,
                taskEntityLinks: concat(taskEntityLinks || [], ownerEntityLinkPredicate),
            };
        }
        case EntityTypeEnum.TASK.name:
        case EntityTypeEnum.CASE.name:
        case EntityTypeEnum.WARRANT.name:
        default:
            return { task, taskEntityLinks };
    }
}

export function convertFromFormModel(formModel: FormTask) {
    const { taskEntityLinks, ...task } = formModel;

    // dont expect duplicate links to be generated in app. uniq just in case.
    const uniqTaskEntityLinks = uniqWith(
        taskEntityLinks,
        (link1, link2) => link1.entityId === link2.entityId && link1.entityType === link2.entityType
    );
    // filter out links with disallowed entityTypes
    // these exist in form state since we display them to the user in the same way as normal taskEntityLinks
    const filteredTaskEntityLinks = filter(
        uniqTaskEntityLinks,
        (taskEntityLink) => !!includes(allowedTaskLinkEntityTypes, taskEntityLink.entityType)
    );

    // tasks without id can trigger BE error. replace empty ids with 0
    const taskEntityLinksWithTaskId = map(filteredTaskEntityLinks, (taskEntityLink) => ({
        ...taskEntityLink,
        taskId: task.id || 0,
    }));
    return confirmOwnerEntityLink(task, taskEntityLinksWithTaskId);
}

const initialState = {
    id: undefined,
    title: undefined,
    typeAttrId: undefined,
    priorityAttrId: undefined,
    statusAttrId: undefined,
    assigneeRoleId: undefined,
    dueDateUtc: undefined,
    description: undefined,
    ownerId: undefined,
    ownerType: undefined,
    taskEntityLinks: undefined,
} as const;

const taskForm = {
    name: RefContextEnum.FORM_TASK.name,
    configuration,
    lifecycle: lifecycleOptions.REGISTER_AND_RETAIN,
    initialState,
};

export default taskForm;

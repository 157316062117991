import { AttributeTypeEnum } from '@mark43/rms-api';

import { createSelector } from 'reselect';

// helpers
import { filterOffenseAttributes } from '../../../offense-attributes/utils/offenseAttributesHelpers';
// selectors
import { offenseByIdSelector } from '../../../offenses/state/data';
import { offenseAttributesSelector } from '../../../offense-attributes/state/data';
import { offenseInvolvedChildrenWhereSelector } from '../../../offense-involved-children/state/data';
import { offenseCaseStatusByOffenseIdSelector } from '../../../offense-case-statuses/state/data';

import { offenseCodeByIdSelector } from '../../../offense-codes/state/data';
import { offenseSubCrimeLinksByOffenseIdSelector } from '../../../offense-sub-crime-links/state/data';

/**
 * `AttributeType`s that are valid for the `OffenseAttribute`s in an Offense Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
export const OFFENSE_CARD_OFFENSE_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.ACTION_AT_SCENE.name,
    AttributeTypeEnum.BIAS_MOTIVATION.name,
    AttributeTypeEnum.AGGRAVATED_ASSAULT_CIRCUMSTANCE.name,
    AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name,
    AttributeTypeEnum.HOMICIDE_CIRCUMSTANCE.name,
    AttributeTypeEnum.CRIMINAL_ACTIVITY_CATEGORY.name,
    AttributeTypeEnum.ANIMAL_CRUELTY_CATEGORY.name,
    AttributeTypeEnum.GANG_INFORMATION.name,
    AttributeTypeEnum.MODUS_OPERANDI.name,
    AttributeTypeEnum.OFFENDER_CONTRIBUTING_FACTORS.name,
    AttributeTypeEnum.ROUTE_OF_APPROACH.name,
    AttributeTypeEnum.TYPE_OF_SEARCH_CONDUCTED_BY_OFFENDER.name,
    AttributeTypeEnum.IMPLEMENT_WEAPON_INVOLVED.name,
    AttributeTypeEnum.SECURITY_SYSTEM.name,
    AttributeTypeEnum.OFFENSE_STATISTIC.name,
    AttributeTypeEnum.ABUSE_TYPE.name,
    AttributeTypeEnum.POLICE_ACTION.name,
    AttributeTypeEnum.REASON_FOR_NO_ARREST.name,
    AttributeTypeEnum.PREVIOUS_COMPLAINTS.name,
    AttributeTypeEnum.PRIOR_COURT_ORDERS.name,
    AttributeTypeEnum.SUBSTANCE_ABUSE.name,
    AttributeTypeEnum.LARCENY_TYPE.name,
    AttributeTypeEnum.HONOR_BASED_ABUSE_TYPE.name,
    AttributeTypeEnum.INTERNET_INVOLVEMENT_TYPE.name,
    AttributeTypeEnum.SUSPECTED_METAL_THEFT.name,
    AttributeTypeEnum.MEANS_OF_APPROACH.name,
    AttributeTypeEnum.METHOD_OF_ENTRY_EXIT.name,
    AttributeTypeEnum.ADDITIONAL_OTHER_INFORMATION.name,
    AttributeTypeEnum.VEHICLE_MO.name,
    AttributeTypeEnum.POINT_OF_ENTRY_EXIT.name,
    AttributeTypeEnum.DISTRACTION_FRAUD_ACTIVITY.name,
    AttributeTypeEnum.SAFEGUARDING_MO.name,
    AttributeTypeEnum.UK_OFFENSE_CODE_CATEGORY.name,
    AttributeTypeEnum.OFFENSE_SCENE_OF_CRIME_LOCATION.name,
    AttributeTypeEnum.DRUG_INVOLVEMENT.name,
    AttributeTypeEnum.EXTENDED_OFFENSE_SUSPECTED_ALCOHOL_USE.name,
    AttributeTypeEnum.OTHER_MODUS_OPERANDI.name,
];

export const hydratedOffenseByIdSelector = createSelector(
    offenseByIdSelector,
    offenseAttributesSelector,
    offenseInvolvedChildrenWhereSelector,
    offenseCaseStatusByOffenseIdSelector,
    offenseCodeByIdSelector,
    offenseSubCrimeLinksByOffenseIdSelector,
    (
        offenseById,
        offenseAttributes,
        offenseInvolvedChildrenWhere,
        offenseCaseStatusByOffenseId,
        offenseCodeById,
        offenseSubCrimeLinksByOffenseId
    ) => (offenseId) => {
        const offense = offenseById(offenseId);
        const filteredOffenseAttributes = filterOffenseAttributes({
            offenseAttributes,
            offenseId,
            attributeTypes: OFFENSE_CARD_OFFENSE_ATTRIBUTE_TYPES,
        });
        const offenseInvolvedChildren = offenseInvolvedChildrenWhere({ offenseId });
        const offenseCaseStatus = offenseCaseStatusByOffenseId[offenseId];
        const offenseCode = offenseCodeById(offense.offenseCodeId);
        const offenseSubCrimeLinks = offenseSubCrimeLinksByOffenseId(offenseId);
        return {
            offense,
            offenseCode,
            offenseAttributes: filteredOffenseAttributes,
            offenseInvolvedChildren,
            offenseCaseStatus,
            offenseSubCrimeLinks,
        };
    }
);

import _ from 'lodash';

import shapefilesActionTypes from '../actions/types/shapefilesAdminActionTypes';

const initialDataState = {
    shapefiles: {},
};

export function shapefilesAdminDataReducer(state = initialDataState, action) {
    let shapefile;

    switch (action.type) {
        case shapefilesActionTypes.PAGE_LOAD_SUCCESS:
            return {
                ...state,
                shapefiles: action.payload,
            };
        case shapefilesActionTypes.CREATE_SHAPEFILE_SUCCESS:
        case shapefilesActionTypes.SAVE_SHAPEFILE_SUCCESS:
            shapefile = action.payload;

            return {
                ...state,
                shapefiles: {
                    ...state.shapefiles,
                    [shapefile.id]: shapefile,
                },
            };
        case shapefilesActionTypes.DELETE_SHAPEFILE_SUCCESS:
            shapefile = action.payload;

            return {
                ...state,
                shapefiles: _.omit(state.shapefiles, shapefile.id),
            };
        default:
            return state;
    }
}

const initialUiState = {
    shapefiles: {}, // computed properties not in the data state
    selectedShapefile: null,
    loadingList: false,
    listError: null,
    loadingShapefile: false,
    submittingForm: false,
    formError: null,
};

export function shapefilesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case shapefilesActionTypes.LOAD_SHAPEFILES_START:
            return {
                ...state,
                loadingList: true,
                listError: null,
            };
        case shapefilesActionTypes.LOAD_SHAPEFILES_SUCCESS:
            const shapefiles = action.payload;

            return {
                ...state,
                shapefiles,
                loadingList: false,
            };
        case shapefilesActionTypes.LOAD_SHAPEFILES_FAILURE:
            return {
                ...state,
                loadingList: false,
                listError: action.payload.message,
            };
        case shapefilesActionTypes.SELECT_SHAPEFILE_START:
            return {
                ...state,
                loadingShapefile: true,
                formError: null,
            };
        case shapefilesActionTypes.SELECT_SHAPEFILE_SUCCESS:
            return {
                ...state,
                selectedShapefile: action.payload,
                loadingShapefile: false,
            };
        case shapefilesActionTypes.SELECT_SHAPEFILE_FAILURE:
            return {
                ...state,
                loadingShapefile: false,
                selectedShapefile: null,
                formError: action.payload.message,
            };
        case shapefilesActionTypes.SAVE_SHAPEFILE_START:
        case shapefilesActionTypes.DELETE_SHAPEFILE_START:
            return {
                ...state,
                submittingForm: true,
                formError: null,
            };
        case shapefilesActionTypes.CREATE_SHAPEFILE_START:
            return {
                ...state,
                selectedShapefile: null,
                submittingForm: true,
                formError: null,
            };
        case shapefilesActionTypes.SAVE_SHAPEFILE_SUCCESS:
        case shapefilesActionTypes.CREATE_SHAPEFILE_SUCCESS:
            const shapefile = action.payload;

            return {
                ...state,
                shapefiles: {
                    ...state.shapefiles,
                    [shapefile.id]: {
                        ...shapefile,
                    },
                },
                submittingForm: false,
            };
        case shapefilesActionTypes.DELETE_SHAPEFILE_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case shapefilesActionTypes.SAVE_SHAPEFILE_FAILURE:
        case shapefilesActionTypes.DELETE_SHAPEFILE_FAILURE:
        case shapefilesActionTypes.CREATE_SHAPEFILE_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload.message,
            };
        case shapefilesActionTypes.CANCEL_SHAPEFILE_ACTIONS:
            return {
                ...state,
                selectedShapefile: null,
                loadingShapefile: false,
            };
        default:
            return state;
    }
}

import { createSelector } from 'reselect';

import { RefContextEnum } from '@mark43/rms-api';

import * as fields from '~/client-common/core/enums/universal/fields';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';

/**
 * To format an offense title, each department is configured to display either its offense code or its URN.
 * Return true if and only if it's configured to display the URN rather than the offense code.
 */
export const offenseURNEnabledSelector = createSelector(
    fieldConfigurationContextByContextAndFieldNameSelector,
    (fieldConfigurationContextByContextAndFieldName) => {
        const fieldConfigurationContext = fieldConfigurationContextByContextAndFieldName(
            RefContextEnum.FORM_OFFENSE.name,
            fields.DISPLAY_ONLY_OFFENSE_URN_TITLE
        );
        return !!fieldConfigurationContext && !fieldConfigurationContext.isStaticallyHidden;
    }
);

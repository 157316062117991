import { get } from 'lodash';
import { connect } from 'react-redux';
import React from 'react';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { impoundViewModelsSelector } from '~/client-common/core/domain/impounds/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.ImpoundCard;

const mapStateToProps = (state, ownProps) => {
    const impoundId = ownProps.impoundId;
    const impoundViewModel = get(impoundViewModelsSelector(state), impoundId);
    return {
        impoundViewModel,
    };
};

function ImpoundCardSummary({ impoundViewModel }) {
    const {
        nicNumberOriginal,
        ocaNumberOriginal,
        originatingAgencyOriginal,
        nicNumberCancellation,
        ocaNumberCancellation,
        originatingAgencyCancellation,
    } = impoundViewModel;
    const impoundViewModelProperties = getViewModelProperties(impoundViewModel);
    const vehicleLocked = get(impoundViewModelProperties, 'vehicleLocked');
    const keysInVehicle = get(impoundViewModelProperties, 'keysInVehicle');

    return (
        <div>
            <SummaryList labelWidth={180} contentWidth={360}>
                {(vehicleLocked || keysInVehicle) && (
                    <CardSection
                        title={strings.vehicleSectionTitle}
                        testId={testIds.IMPOUND_CARD_VEHICLE_SECTION}
                    >
                        <SummaryRow fieldName={fields.IMPOUND_VEHICLE_LOCKED}>
                            {vehicleLocked}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.IMPOUND_KEYS_IN_VEHICLE}>
                            {keysInVehicle}
                        </SummaryRow>
                    </CardSection>
                )}
                <CardSection
                    title={strings.ncicSectionTitle}
                    testId={testIds.IMPOUND_CARD_NCIC_INFORMATION_SECTION}
                >
                    <SummaryRow fieldName={fields.IMPOUND_NIC_NUMBER_ORIGINAL}>
                        {nicNumberOriginal}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_OCA_NUMBER_ORIGINAL}>
                        {ocaNumberOriginal}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_ORIGINATING_AGENCY_ORIGINAL}>
                        {originatingAgencyOriginal}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_NIC_NUMBER_CANCELLATION}>
                        {nicNumberCancellation}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_OCA_NUMBER_CANCELLATION}>
                        {ocaNumberCancellation}
                    </SummaryRow>
                    <SummaryRow fieldName={fields.IMPOUND_ORIGINATING_AGENCY_CANCELLATION}>
                        {originatingAgencyCancellation}
                    </SummaryRow>
                </CardSection>
            </SummaryList>
        </div>
    );
}

export default connect(mapStateToProps)(ImpoundCardSummary);

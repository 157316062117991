import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { map, get } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { dateSortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { CustomLink as _CustomLink } from '../../../core/components/links/Link';
import InlineFileRow from '../../../attachments/core/components/InlineFileRow';
import testIds from '../../../../core/testIds';

const Container = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
    width: 100%;
    flex-shrink: 1;
    display: flex;
    overflow: hidden;
    justify-content: stretch;
    flex-basis: 40%;

    &:empty {
        flex-basis: 0%;
    }
`;

const AttachmentFooter = styled.div`
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const AttachmentFooterTitle = styled.div`
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.mediumLightGrey};
    padding: 12px 0 6px 12px;
`;

const AttachmentFooterList = styled.div`
    width: 100%;
    padding-left: 12px;
    overflow-y: auto;
`;

const CustomLink = styled(_CustomLink)`
    position: absolute;
    font-size: var(--arc-fontSizes-sm);
    top: 6px;
    right: 12px;
`;

const strings = componentStrings.cases.caseSummary.CaseNotes;

const CaseNoteAttachments = ({
    caseNoteId,
    openAttachmentsSidePanel,
    dateSortedAugmentedAttachmentViewModelsWhere,
}) => {
    const attachments = dateSortedAugmentedAttachmentViewModelsWhere({
        entityType: EntityTypeEnum.CASE_NOTE.name,
        entityId: caseNoteId,
    });
    return (
        <Container data-test-id={testIds.CASE_NOTE_ATTACHMENT_FOOTER}>
            {attachments.length > 0 && (
                <AttachmentFooter>
                    <AttachmentFooterTitle>{strings.attachments}</AttachmentFooterTitle>
                    <AttachmentFooterList>
                        {map(attachments, (attachment) => {
                            const originalFile = getAttachmentFile(attachment);
                            const attachmentName = get(originalFile, 'originalFileName');
                            const fileCategory = get(originalFile, 'fileCategory');
                            const url = get(originalFile, 'fileWebServerPath');

                            return (
                                <InlineFileRow
                                    key={attachment.id}
                                    fileName={attachmentName}
                                    createdDateUtc={attachment.createdDateUtc}
                                    createdBy={attachment.createdBy}
                                    fileCategory={fileCategory}
                                    hideDetails={true}
                                    url={url}
                                    disabled={true}
                                />
                            );
                        })}
                    </AttachmentFooterList>
                    {openAttachmentsSidePanel && (
                        <CustomLink onClick={openAttachmentsSidePanel}>
                            {componentStrings.seeAll}
                        </CustomLink>
                    )}
                </AttachmentFooter>
            )}
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    dateSortedAugmentedAttachmentViewModelsWhere: dateSortedAugmentedAttachmentViewModelsWhereSelector,
});

export default connect(mapStateToProps)(CaseNoteAttachments);

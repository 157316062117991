import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { flowRight } from 'lodash';

import { InjectedRouter, withRouter } from 'react-router';
import { Box, useToast, VStack } from 'arc';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import {
    InsightsContext,
    InsightsContextProvider,
} from '../../insights/core/components/InsightsContext';
import insightsResource from '../../insights/dashboard/resources/insightsResource';
import { logError } from '../../../core/logging';
import errorToMessage from '../../core/errors/utils/errorToMessage';
import { DashboardCard, DashboardCardProps, NoDataBlock } from './DashboardSection';

const strings = componentStrings.personalDashboard.Insights;

const _Insights = ({}: PropsWithChildren<{
    router: InjectedRouter;
    hideLoadingBar: boolean;
}>): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState('');
    const toast = useToast();
    const goodDataIframeRef = useRef<HTMLIFrameElement>(null);
    const {
        goodDataEmbedPath,
        goodDataEmbedBaseUrl,
        goodDataEmbedParameters,
        setGoodDataDashboardId,
        setGoodDataIframeLoaded,
        addGoodDataEventCallback,
        removeGoodDataEventCallback,
    } = useContext(InsightsContext);
    const applicationSettings = useSelector(applicationSettingsSelector);

    const [iframeSrc, setIframeSrc] = useState<string>(
        `${goodDataEmbedBaseUrl}${goodDataEmbedPath}${goodDataEmbedParameters}&hideControl=[filterBar,topBar,widgetsCatalogue,deleteButton,saveAsButton]`
    );

    useEffect(() => {
        setIframeSrc(
            `${goodDataEmbedBaseUrl}${goodDataEmbedPath}${goodDataEmbedParameters}&hideControl=[filterBar,topBar,widgetsCatalogue,deleteButton,saveAsButton]`
        );
    }, [goodDataEmbedBaseUrl, goodDataEmbedPath, goodDataEmbedParameters]);

    const handleTokenExpirationSuccess = useCallback((jwt) => {
        const postMessageStructure = {
            gdc: {
                product: 'dashboard',
                event: {
                    name: 'setApiToken',
                    data: {
                        token: jwt.content,
                    },
                },
            },
        };
        const origin = '*';
        if (goodDataIframeRef.current && goodDataIframeRef.current.contentWindow) {
            goodDataIframeRef.current.contentWindow.postMessage(postMessageStructure, origin);
        }
    }, []);

    const handleTokenExpirationError = useCallback(
        (err) => {
            const message = errorToMessage(err, strings.loadError);
            toast({ status: 'error', description: message });
            setErrorMessage(strings.loadError);
            logError(`Insights dashboard section failed to load. Error: ${err}`);
        } /* toast dependency cause unnecessary re-renders */,
        /* eslint-disable react-hooks/exhaustive-deps */
        []
    );

    const { callResource: handleTokenExpiration } = useResourceDeferred(
        insightsResource.createGoodDataJWT,
        handleTokenExpirationSuccess,
        handleTokenExpirationError
    );

    useEffect(() => {
        addGoodDataEventCallback('listeningForApiToken', handleTokenExpiration);
        addGoodDataEventCallback('apiTokenIsAboutToExpire', handleTokenExpiration);
        if (applicationSettings.RMS_INSIGHTS_DASHBOARD_SECTION_ID) {
            setGoodDataDashboardId(`${applicationSettings.RMS_INSIGHTS_DASHBOARD_SECTION_ID}`);
        }

        return () => {
            removeGoodDataEventCallback('listeningForApiToken', handleTokenExpiration);
            removeGoodDataEventCallback('apiTokenIsAboutToExpire', handleTokenExpiration);
        };
    }, [
        addGoodDataEventCallback,
        removeGoodDataEventCallback,
        handleTokenExpiration,
        applicationSettings.RMS_INSIGHTS_DASHBOARD_SECTION_ID,
        setGoodDataDashboardId,
    ]);

    useEffect(() => {
        const goodDataIframe = goodDataIframeRef.current;

        if (goodDataIframe) {
            goodDataIframe.onload = () => {
                setGoodDataIframeLoaded(true);
                handleTokenExpiration();
            };
            goodDataIframe.onerror = () => {
                setGoodDataIframeLoaded(false);
                logError('GoodData Iframe failed to load.');
            };

            return () => {
                goodDataIframe.onload = null;
                goodDataIframe.onerror = null;
            };
        }
        return;
    }, [handleTokenExpiration, setGoodDataIframeLoaded]);

    useEffect(() => {
        if (applicationSettings.RMS_INSIGHTS_DASHBOARD_SECTION_ID) {
            setGoodDataDashboardId(`${applicationSettings.RMS_INSIGHTS_DASHBOARD_SECTION_ID}`);
        }
    }, [applicationSettings.RMS_INSIGHTS_DASHBOARD_SECTION_ID, setGoodDataDashboardId]);

    if (errorMessage) {
        return <NoDataBlock>{errorMessage}</NoDataBlock>;
    } else {
        return (
            <VStack spacing={0} align="stretch" h="235px" w="100%">
                {
                    <Box
                        as="iframe"
                        ref={goodDataIframeRef}
                        src={iframeSrc}
                        flexGrow={1}
                        w="117.5%"
                        h="200px"
                        transform="scale(0.85)"
                        transformOrigin="0 0"
                        marginBottom="-42px"
                    />
                }
            </VStack>
        );
    }
};

const InsightsContent = flowRight(withRouter)(_Insights);

const Insights = ({
    dragHandleProps,
    isDragging,
    ...props
}: PropsWithChildren<DashboardCardProps>): JSX.Element => {
    return (
        <InsightsContextProvider>
            <DashboardCard
                dragHandleProps={dragHandleProps}
                isDragging={isDragging}
                {...props}
                title={strings.title}
                padding="0px"
            >
                <InsightsContent hideLoadingBar={true} />
            </DashboardCard>
        </InsightsContextProvider>
    );
};

export default Insights;

import { Case } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'cases';
const caseModule = createNormalizedModule<Case>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS

export const replaceCasesWhere = caseModule.actionCreators.replaceEntitiesWhere;
export const removeCase = caseModule.actionCreators.deleteEntity;

// SELECTORS
export const casesSelector = caseModule.selectors.entitiesSelector;
export const caseByIdSelector = caseModule.selectors.entityByIdSelector;
export const casesWhereSelector = caseModule.selectors.entitiesWhereSelector;

// REDUCER
export default caseModule.reducerConfig;

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';

import { filter } from 'lodash';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatShortName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { PersonLabels as _PersonLabels } from '../../../../core/components/tags/PersonLabels';
import { PersonEnhancedCautions as _PersonEnhancedCautions } from '../../../../core/cautions/components/PersonEnhancedCautions';
import ImageCell from '../../../../../legacy-redux/components/core/tables/elasticPersonTable/ImageCell';
import { useDateTimeFormatter } from '../../../../core/current-user/hooks/dateTimeFormats';

const MugshotContainer = styled.div`
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
`;

const MasterPersonFullName = styled.div`
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.lightGrey};
`;

const Nicknames = styled.div`
    font-style: italic;
    color: ${(props) => props.theme.colors.medium};
`;

const BiographicalInfoContainer = styled.div`
    display: inline-block;
    width: calc(100% - 90px);
    vertical-align: top;
`;

const DOBLabel = styled.span`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

const PersonLabels = styled(_PersonLabels)`
    margin-top: 6px;
`;

const PersonEnhancedCautions = styled(_PersonEnhancedCautions)`
    margin-top: 6px;
`;

export default function SubjectCell(elasticWarrant) {
    const dateTimeFormatter = useDateTimeFormatter();
    const viewModelProps = getViewModelProperties(elasticWarrant);
    const subject = elasticWarrant.subject;
    const subjectViewModel = viewModelProps.subject;
    const { nicknames } = subjectViewModel;
    const name = formatShortName(subject);
    return subject ? (
        <div>
            <MugshotContainer>
                <ImageCell {...subject} />
            </MugshotContainer>
            <BiographicalInfoContainer>
                <div>{name}</div>
                {subject.masterPersonFullName && (
                    <MasterPersonFullName>{subject.masterPersonFullName}</MasterPersonFullName>
                )}
                {nicknames && <Nicknames>aka: {nicknames}</Nicknames>}
                {(subject.age >= 0 || (subject.ageMin >= 0 && subject.ageMax >= 0)) && (
                    <div>
                        <DOBLabel>Age: </DOBLabel>
                        {subject.age >= 0 ? subject.age : `${subject.ageMin} - ${subject.ageMax}`}
                    </div>
                )}
                {subject.dateOfBirth && (
                    <div>
                        <DOBLabel>DOB: </DOBLabel>
                        {dateTimeFormatter.formatShortDate(subject.dateOfBirth)}
                    </div>
                )}

                <FeatureFlagged
                    flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <PersonLabels
                            elasticNameAttributes={filter(subjectViewModel.nameAttributes, {
                                nameAttrDetail: {
                                    type: AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name,
                                },
                            })}
                            isJuvenile={subjectViewModel.isJuvenile}
                            isVulnerable={subjectViewModel.isVulnerable}
                            dateVulnerableTo={subjectViewModel.dateVulnerableTo}
                            hasPotentialActiveWarrant={subjectViewModel.hasPotentialActiveWarrant}
                            isSuspectedGangMember={subjectViewModel.isAllegedGangMember}
                            size="md"
                        />
                    }
                >
                    <PersonEnhancedCautions
                        cautions={subject.cautions}
                        isJuvenile={subjectViewModel.isJuvenile}
                        isVulnerable={subjectViewModel.isVulnerable}
                        dateVulnerableTo={subjectViewModel.dateVulnerableTo}
                        hasPotentialActiveWarrant={subjectViewModel.hasPotentialActiveWarrant}
                        isSuspectedGangMember={subjectViewModel.isAllegedGangMember}
                    />
                </FeatureFlagged>
            </BiographicalInfoContainer>
        </div>
    ) : undefined;
}

import React from 'react';
import testIds from '../../../../core/testIds';

const SearchActionBar = ({ children }) => (
    <div className="search-action-bar" data-test-id={testIds.ADVANCED_SEARCH_DASHBOARD_ACTION_BAR}>
        {children}
    </div>
);

export default SearchActionBar;

import { PreferenceLink } from '@mark43/cad-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const preferenceLinksModule = createNormalizedModule<PreferenceLink>({
    type: 'preferenceLinks',
});

// ACTIONS
export const storePreferenceLinks = preferenceLinksModule.actionCreators.storeEntities;

// SELECTORS
export const preferenceLinksSelector = preferenceLinksModule.selectors.entitiesSelector;
export const preferenceLinkByIdSelector = preferenceLinksModule.selectors.entityByIdSelector;

export default preferenceLinksModule.reducerConfig;

import React from 'react';
import { Motion, spring } from 'react-motion';
import { profileHeaderSpringParameters } from '../../configuration';

export default function HeaderCautions({ children, collapsed }) {
    return (
        <Motion
            style={{
                marginTop: spring(collapsed ? 4 : 10, profileHeaderSpringParameters),
            }}
        >
            {({ marginTop }) => (
                <div
                    className="cautions"
                    style={{
                        marginTop,
                    }}
                >
                    {children}
                </div>
            )}
        </Motion>
    );
}

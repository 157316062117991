import { CommandStatusEnum } from '@mark43/partnerships-api';
import { last } from 'lodash';

import Promise from 'bluebird';
import { POLL_IMPORT_EVENT_INTERVALS, POLL_IMPORT_EVENT_MAX_ATTEMPTS } from '../../configuration';
import getImportEventResource from '../../resources/importEventResource';
import { storeHydratedItems } from '../../../item-profiles/state/data';

const RUN_IMPORT_EVENT_START = 'import-events/RUN_IMPORT_EVENT_START';
const RUN_IMPORT_EVENT_SUCCESS = 'import-events/RUN_IMPORT_EVENT_SUCCESS';
const RUN_IMPORT_EVENT_FAILURE = 'import-events/RUN_IMPORT_EVENT_FAILURE';
export const POLL_IMPORT_EVENT_START = 'import-events/POLL_IMPORT_EVENT_START';
const POLL_IMPORT_EVENT_SUCCESS = 'import-events/POLL_IMPORT_EVENT_SUCCESS';
export const POLL_IMPORT_EVENT_FAILURE = 'import-events/POLL_IMPORT_EVENT_FAILURE';

function runImportEventStart({ sourceReportId, targetReportId, itemProfileIds }) {
    return {
        type: RUN_IMPORT_EVENT_START,
        payload: { sourceReportId, targetReportId, itemProfileIds },
    };
}
function runImportEventSuccess() {
    return {
        type: RUN_IMPORT_EVENT_SUCCESS,
    };
}
function runImportEventFailure(errorMessage) {
    return {
        type: RUN_IMPORT_EVENT_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Trigger the import event to run for the given itemProfileIds from the source report to
 *   the target report.
 * @param  {number}   options.sourceReportId
 * @param  {number}   options.targetReportId
 * @param  {number[]} options.itemProfileIds
 * @return {Promise<Object[]>} RmsHydratedItem
 */
export function runImportEvent(options) {
    const importEventResource = getImportEventResource();

    return function (dispatch) {
        dispatch(runImportEventStart(options));
        return importEventResource
            .runImportEvent(options)
            .then((hydratedItems) => {
                dispatch(storeHydratedItems(hydratedItems));
                dispatch(runImportEventSuccess());
                return hydratedItems;
            })
            .catch((err) => {
                dispatch(runImportEventFailure(err.message));
                throw err;
            });
    };
}

function pollImportEventStart(reportId, pollCount) {
    return {
        type: POLL_IMPORT_EVENT_START,
        payload: { reportId, pollCount },
    };
}
function pollImportEventSuccess() {
    return {
        type: POLL_IMPORT_EVENT_SUCCESS,
    };
}
function pollImportEventFailure(errorMessage) {
    return {
        type: POLL_IMPORT_EVENT_FAILURE,
        payload: errorMessage,
    };
}

/**
 * Poll for the status of the current Import Event for the given report id until
 *   the status is success or failure.
 * @param  {number} reportId
 * @param  {number} [pollCount=1] The number of times this current poll is, used
 *   for determining when to stop.
 * @return {Promise<undefined>}
 */
export function pollImportEvent(reportId, pollCount = 1) {
    const importEventResource = getImportEventResource();

    // how long to wait before making this poll request (not after, since the
    // Import Event can't happen immediately)
    const ms = POLL_IMPORT_EVENT_INTERVALS[pollCount - 1] || last(POLL_IMPORT_EVENT_INTERVALS);

    return function (dispatch) {
        dispatch(pollImportEventStart(reportId, pollCount));

        return Promise.delay(ms)
            .then(() => importEventResource.getImportEventStatus(reportId))
            .then((commandStatus) => {
                switch (commandStatus) {
                    case CommandStatusEnum.QUEUED.name:
                    case CommandStatusEnum.STARTING.name:
                    case CommandStatusEnum.RUNNING.name:
                    case CommandStatusEnum.STOPPING.name:
                        if (pollCount >= POLL_IMPORT_EVENT_MAX_ATTEMPTS) {
                            // stop polling after too many attempts
                            throw new Error(
                                'The creation of new chains of custody timed out, please reload this page to try again.'
                            );
                        } else {
                            // continue polling
                            return dispatch(pollImportEvent(reportId, pollCount + 1));
                        }
                    case CommandStatusEnum.SUCCEEDED.name:
                        // stop polling on success
                        dispatch(pollImportEventSuccess());
                        return;
                    case CommandStatusEnum.TIMEDOUT.name:
                    case CommandStatusEnum.FAILED.name:
                    case CommandStatusEnum.CLEARED.name:
                        // stop polling on failure
                        throw new Error(
                            'Failed to create new chains of custody, please reload this page to try again.'
                        );
                    default:
                        // this line should not be reachable
                        throw new Error('Failed to create new chains of custody.');
                }
            })
            .catch((err) => {
                dispatch(pollImportEventFailure(err.message));
                throw err;
            });
    };
}

import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { towVehicleByReportIdSelector } from '~/client-common/core/domain/tow-vehicles/state/data';
import * as fields from '~/client-common/core/enums/universal/fields';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { RMSArbiterProvider } from '../../../../core/arbiter';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    formName,
    createTowVehicleCheckInForm,
    buildTowVehicleCheckInCardFormModel,
} from '../../state/forms/towVehicleCheckInForm';
import { registerForm } from '../../state/ui';
import withCard from '../../utils/withCard';
import Card, { CardSection } from '../../../../../legacy-redux/components/core/Card';
import testIds from '../../../../../core/testIds';
import towVehicleCheckInCard from '../../state/ui/towVehicleCheckInCard';
import { LocationSummaryViewWrapper } from '../../../../records/core/components/summaries/locations/LocationSummaryViewWrapper';
import { currentReportCardUITitleByTypeSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import TowVehicleCheckInCardSummary from './TowVehicleCheckInCardSummary';

class TowVehicleCheckInCard extends React.Component {
    static contextTypes = {
        forms: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const {
            arbiter,
            formatFieldByName,
            buildTowVehicleCheckInCardFormModel,
            reportId,
        } = this.props;

        const form = createTowVehicleCheckInForm({
            initialState: buildTowVehicleCheckInCardFormModel({ reportId }),
            arbiter,
            formatFieldByName,
        });

        registerForm({ form });
        // Purposely not registering this card within the `cardsRegistry`, because this Report Card
        // uniquely does not have an edit mode or save functionality.
    }

    componentWillUnmount() {
        formsRegistry.unregister(formName);
    }

    render() {
        const {
            card = {},
            reportId,
            towVehicleByReportId,
            currentReportCardUITitleByType,
        } = this.props;
        const { anchor } = card;
        const { id: towVehicleId } = towVehicleByReportId(reportId);
        const cardTitle = currentReportCardUITitleByType(reportCardEnum.CHECK_IN_INFORMATION.id);

        return (
            <Card
                className={anchor}
                anchor={anchor}
                title={cardTitle}
                testId={testIds.CHECK_IN_INFORMATION_CARD}
                renderContent={() => {
                    return (
                        <>
                            <TowVehicleCheckInCardSummary reportId={reportId} />
                            <CardSection
                                testId={testIds.CHECK_IN_INFORMATION_CARD_STORAGE_LOCATION_SECTION}
                                fieldName={
                                    fields.LOCATION_ENTITY_LINK_LINK_TYPE_TOW_VEHICLE_STORAGE_LOCATION_LOCATION_ID
                                }
                            >
                                <LocationSummaryViewWrapper
                                    summaryMode={true}
                                    entityType={EntityTypeEnum.RMS_TOW_VEHICLE.name}
                                    entityId={towVehicleId}
                                    linkType={LinkTypesEnum.TOW_VEHICLE_STORAGE_LOCATION}
                                    // Need to manually add a suffix here, because the side panel
                                    // will also have a `LocationSummaryViewWrapper` component that will
                                    // open a side panel -- we don't want _this_ `LocationSummaryViewWrapper`
                                    // to also open a sidepanel as well.
                                    overlayIdSuffix="CARD"
                                />
                            </CardSection>
                        </>
                    );
                }}
                // No `edit` pencil for the card.  All card editing is done via the `Tow Vehicle Check In` side panel.
                // No `save` for the card either.  All validation is done in the `Tow Vehicle Check In` side panel.  We
                // explicitly do not run any validation for the card, even if the Side Panel is unfortunately left in
                // an invalid state.  This is because two separate user groups interact with the report -- officers will
                // fill out the report, while later, a different user may actually _check in_ the vehicle.  This may happen
                // well after report approval -- therefore, we usually will not be able to validate the `Tow Vehicle Check In`
                // card upon report submission.
                onEdit={undefined}
                canEdit={undefined}
                canEditErrorMessage={undefined}
                errors={[]}
                summaryMode={true}
                saving={false}
                onSave={undefined}
            />
        );
    }
}

class TowVehicleCheckInCardWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setRef = (element) => {
            if (element) {
                this.ref = element;
            }
        };
    }

    render() {
        return (
            <RMSArbiterProvider context={formName}>
                {(arbiter) => (
                    <TowVehicleCheckInCard ref={this.setRef} {...this.props} arbiter={arbiter} />
                )}
            </RMSArbiterProvider>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    towVehicleByReportId: towVehicleByReportIdSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = {
    buildTowVehicleCheckInCardFormModel,
};

export default compose(
    withCard(towVehicleCheckInCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
)(TowVehicleCheckInCardWrapper);

import { ItemIdentifier } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'itemIdentifiers';

const itemIdentifiersModule = createGroupedModule<ItemIdentifier>({
    type: NEXUS_STATE_PROP,
    key: 'itemId',
});

// ACTIONS

// SELECTORS

export const itemIdentifiersByItemIdSelector =
    itemIdentifiersModule.selectors.entitiesByKeySelector;

// REDUCER
export default itemIdentifiersModule.reducerConfig;

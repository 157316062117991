import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import AttachmentsSidePanel from '../../../attachments/core/components/AttachmentsSidePanel';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import Header from '../../core/components/header/EntityProfileHeader';
import HeaderMasterName from '../../core/components/header/EntityProfileHeaderMasterName';
import HeaderMenu from '../../core/components/header/EntityProfileHeaderMenu';
import HeaderSecondaryInfoSection from '../../core/components/header/EntityProfileHeaderSecondaryInfoSection';
import ExternalDepartmentNameSecondaryHeaderInfo from '../../core/components/header/ExternalDepartmentSecondaryHeaderInfo';
import { currentEntityProfileIdSelector, propertyHeaderDataSelector } from '../../core/state/ui';
import HighRiskPropertyLabel from './HighRiskPropertyLabel';

function EntityProfilePropertyHeader({
    propertyHeaderData,
    ownerDepartmentName,
    entityId,
    initializeAttachmentsSidePanelForm,
    itemEvidenceStateByMasterItemId,
}) {
    const { title } = propertyHeaderData;
    const itemEvidenceState = itemEvidenceStateByMasterItemId(entityId);
    const attachmentEntityLinkDetails = {
        entityId,
        entityType: EntityTypeEnum.ITEM_PROFILE.name,
        imageLinkType: LinkTypesEnum.ITEM_PROFILE_PHOTO,
        attachmentLinkType: LinkTypesEnum.ITEM_PROFILE_ATTACHMENT,
    };

    const isHighValue = itemEvidenceState?.isHighValue || itemEvidenceState?.isInHighValueContainer;

    return (
        <Header>
            <HeaderMenu
                editProfileSidePanel={(renderEditButton) => (
                    <AttachmentsSidePanel
                        getInitialCustomPropertyState={() => attachmentEntityLinkDetails}
                        overlayId={overlayIdEnum.ENTITY_PROFILE_HEADER_ATTACHMENTS_SIDE_PANEL}
                        renderButton={({ overlayBase: { open }, setCloseFocusRefs }) =>
                            renderEditButton({
                                open: () => {
                                    initializeAttachmentsSidePanelForm(attachmentEntityLinkDetails);
                                    open();
                                },
                                setCloseFocusRefs,
                            })
                        }
                    />
                )}
                entityName={title}
            />
            <HeaderMasterName>{title}</HeaderMasterName>
            {(ownerDepartmentName || isHighValue) && (
                <HeaderSecondaryInfoSection>
                    {ownerDepartmentName && (
                        <ExternalDepartmentNameSecondaryHeaderInfo
                            ownerDepartmentName={ownerDepartmentName}
                        />
                    )}
                    {isHighValue && <HighRiskPropertyLabel />}
                </HeaderSecondaryInfoSection>
            )}
        </Header>
    );
}

const mapStateToProps = createStructuredSelector({
    propertyHeaderData: propertyHeaderDataSelector,
    entityId: currentEntityProfileIdSelector,
    itemEvidenceStateByMasterItemId: itemEvidenceStateByMasterItemIdSelector,
});

export default connect(mapStateToProps, { initializeAttachmentsSidePanelForm })(
    EntityProfilePropertyHeader
);

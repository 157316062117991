import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';
import { requiredInt } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const responsibleOfficerForm = createFormModule({
    formName: formClientEnum.RESPONSIBLE_OFFICER,
    fieldViewModels: buildFlatFormFieldViewModels([
        {
            key: 'officerId',
            validators: {
                requiredError: requiredInt,
            },
        },
    ]),
    convertFromFormModel: (reportId, custodialResponsibleOfficerId, formModel) => ({
        reportId,
        id: custodialResponsibleOfficerId,
        ...formModel,
    }),
});

/**
 * Module of the responsible officer form, for editing the
 * responsible officer of a custodial report.
 * @type {Object}
 */
export default responsibleOfficerForm;

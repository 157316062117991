import { RefContextEnum, RenConfigurationEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Icon as ArcIcon } from 'arc';
import { createStructuredSelector } from 'reselect';
import { chain } from 'lodash';
import { compose } from 'recompose';
import { withRegistry, lifecycleOptions } from 'markformythree';
import { withRouter } from 'react-router';

import componentStrings from '~/client-common/core/strings';
import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { PortalModal } from '../../../../legacy-redux/components/core/Modal';
import FilterableList from '../../../core/components/FilterableList';
import testIds from '../../../../core/testIds';
import Icon, { iconTypes } from '../../../core/components/Icon';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { Tile } from '../../../core/components/Tile';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import {
    clearReportDefinitionFromCreateReportModal,
    submitCreateReportModal,
    currentDepartmentReportShortTitleViewModelsWhereSelector,
    submitFormSearch,
    chooseReportDefinition,
    onReportingEventNumberChange,
    onRecordNumberChange,
    onCadAgencyEventNumberChange,
    createReportModalFormConfiguration,
} from '../state/ui/createReportModal';
import LinkedCadReports from '../../../../legacy-redux/components/reports/LinkedCadReports';
import LinkedReportsSection from '../../../../legacy-redux/components/reports/LinkedReportsSection';
import { ArbiterField } from '../../../core/arbiter';
import { OverlayBase } from '../../../../core/overlayManager';
import { AnalyticsPropertyEnum } from '../../../analytics/constants/analyticsEnum';
import { AnalyticsContextProviderWithAdditionalData } from '../../../core/context/AnalyticsContext';

const strings = componentStrings.components.reports.CreateReportModal;

const NewReportOptions = styled.div`
    width: 100%;
`;

const SearchButton = styled(Button)`
    margin-top: 19px;
`;

const GenerateRenButton = styled(Button)`
    margin-top: 19px;
`;

const reportDefinitionsOptionsMapper = (chooseReportDefinition) => (definition) => (
    <AnalyticsContextProviderWithAdditionalData
        analyticsKeyToAdd={AnalyticsPropertyEnum.REPORT_DEFINITION}
        analyticsValueToAdd={definition.name}
    >
        <Tile
            media={<ArcIcon color="brand.default" icon="Add" size="md" />}
            key={definition.id}
            title={definition.name}
            testId={testIds.NEW_REPORT_OPTION}
            onClick={() => {
                chooseReportDefinition(definition);
            }}
        />
    </AnalyticsContextProviderWithAdditionalData>
);

const ReportDefinitionList = ({
    reportDefinitions,
    chooseReportDefinition,
    onCloseRef,
    ...otherProps
}) => {
    const reportDefinitionsFilterableListOptions = chain(reportDefinitions)
        .filter((definition) => definition.isEnabled && definition.isActive)
        .sortBy('name')
        .map((definition) => {
            return {
                ...definition,
                display: definition.name,
            };
        })
        .value();
    return (
        <PortalModal
            {...otherProps}
            id={overlayIdEnum.CREATE_REPORT_MODAL}
            shouldCloseOnOverlayClick={true}
            onCloseRef={onCloseRef}
        >
            <NewReportOptions>
                <FilterableList
                    options={reportDefinitionsFilterableListOptions}
                    optionsMapper={reportDefinitionsOptionsMapper(chooseReportDefinition)}
                    placeholderText={strings.filterPlaceholder}
                    testId={testIds.NEW_REPORT_OPTIONS_LIST}
                />
            </NewReportOptions>
        </PortalModal>
    );
};

const BackButton = styled(Button)`
    margin-bottom: 10px;
`;

class _ReportSearchForm extends React.Component {
    componentWillUnmount() {
        const form = this.props.registry.get(RefContextEnum.FORM_REPORT_CREATE_REPORT.name);
        if (form) {
            this.props.registry.unregister(form);
        }
    }
    render() {
        const {
            submitFormSearch,
            currentDepartmentReportShortTitleViewModelsWhere,
            clearReportDefinitionFromCreateReportModal,
            submitCreateReportModal,
            submitAutoCreateReportModal,
            reportDefinition: {
                id,
                name: reportDefinitionName,
                renConfiguration,
                recordSequenceType,
            },
            overlayState,
            overlayStore,
            onCloseRef,
            reportingEventNumberChange,
            recordNumberChange,
            cadAgencyEventNumberChange,
            ...otherProps
        } = this.props;

        const {
            isLoadingSearch,
            hasFinishedSearch,
            recordFieldIsBlank,
            renFieldIsBlank,
            cadNotSearchedAndTouched,
            formIsBlankOrError,
            lastSearchedReportingEventNumber,
            lastSearchedCadAgencyEventNumber,
            showBackButton = true,
            disableRen = false,
            createdFromCadEvent,
        } = overlayState.customProperties;
        const saveDisabled =
            isUndefinedOrNull(reportDefinitionName) ||
            recordFieldIsBlank ||
            renFieldIsBlank ||
            cadNotSearchedAndTouched ||
            formIsBlankOrError;
        return (
            <PortalModal
                {...otherProps}
                overlayState={overlayState}
                overlayStore={overlayStore}
                id={overlayIdEnum.CREATE_REPORT_MODAL}
                title={`${strings.title}: ${reportDefinitionName}`}
                shouldCloseOnOverlayClick={true}
                saveDisabled={saveDisabled}
                onSave={submitCreateReportModal(
                    id,
                    recordSequenceType === recordSequenceTypeEnum.CAD_EVENT
                )}
                onCloseRef={onCloseRef}
            >
                {showBackButton && (
                    <BackButton
                        className={buttonTypes.ICON_LINK}
                        iconLeft={<Icon type={iconTypes.OPEN_LEFT} />}
                        onClick={clearReportDefinitionFromCreateReportModal}
                    >
                        Back
                    </BackButton>
                )}
                <ArbiterForm
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    name={RefContextEnum.FORM_REPORT_CREATE_REPORT.name}
                    context={RefContextEnum.FORM_REPORT_CREATE_REPORT.name}
                    configuration={createReportModalFormConfiguration}
                    render={() => (
                        <ArbiterField fieldName={REPORT_REPORTING_EVENT_NUMBER}>
                            {({ fieldConfiguration }) => {
                                const label =
                                    renConfiguration === RenConfigurationEnum.OPTIONAL.name
                                        ? `${fieldConfiguration.displayName} (${strings.optionalLabel})`
                                        : fieldConfiguration.displayName;
                                const showSearchButton =
                                    (recordSequenceType === recordSequenceTypeEnum.CAD_EVENT &&
                                        renConfiguration === RenConfigurationEnum.NONE.name) ||
                                    renConfiguration !== RenConfigurationEnum.NONE.name;
                                return (
                                    <>
                                        {(recordSequenceType === recordSequenceTypeEnum.FREE_TEXT ||
                                            recordSequenceType ===
                                                recordSequenceTypeEnum.FORMATTED_FREE_TEXT) && (
                                            <ArbiterMFTText
                                                path="recordNumber"
                                                label={`${reportDefinitionName} #`}
                                                length="md"
                                                onChange={recordNumberChange}
                                            />
                                        )}
                                        {recordSequenceType ===
                                            recordSequenceTypeEnum.CAD_EVENT && (
                                            <ArbiterMFTText
                                                path="cadAgencyEventNumber"
                                                disabled={createdFromCadEvent}
                                                length="md"
                                                loading={isLoadingSearch}
                                                onPressEnter={submitFormSearch}
                                                onChange={cadAgencyEventNumberChange}
                                                forceShowError={true}
                                            />
                                        )}
                                        {renConfiguration !== RenConfigurationEnum.NONE.name && (
                                            <ArbiterMFTText
                                                path="reportingEventNumber"
                                                label={label}
                                                disabled={disableRen}
                                                length="md"
                                                loading={isLoadingSearch}
                                                onPressEnter={submitFormSearch}
                                                onChange={(ren) =>
                                                    reportingEventNumberChange(
                                                        renConfiguration,
                                                        ren
                                                    )
                                                }
                                                forceShowError={true}
                                            />
                                        )}
                                        {showSearchButton && (
                                            <SearchButton
                                                className={buttonTypes.PRIMARY}
                                                onClick={submitFormSearch}
                                                disabled={disableRen}
                                                testId={testIds.EVENT_SEARCH_BUTTON}
                                            >
                                                {strings.goButtonText}
                                            </SearchButton>
                                        )}
                                        <FeatureFlagged flag="RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED">
                                            <FeatureFlagged flag="RMS_AUTO_GENERATED_REN_ENABLED">
                                                <GenerateRenButton
                                                    className={buttonTypes.PRIMARY}
                                                    onClick={submitAutoCreateReportModal(
                                                        id,
                                                        recordSequenceType ===
                                                            recordSequenceTypeEnum.CAD_EVENT
                                                    )}
                                                    disabled={disableRen}
                                                    testId={testIds.RMS_AUTOGENERATE_REN_BUTTON}
                                                >
                                                    {strings.autogenerateRenText(
                                                        fieldConfiguration.displayName
                                                    )}
                                                </GenerateRenButton>
                                            </FeatureFlagged>
                                        </FeatureFlagged>
                                        {hasFinishedSearch && (
                                            <>
                                                {(lastSearchedReportingEventNumber ||
                                                    lastSearchedCadAgencyEventNumber) && (
                                                    <LinkedCadReports
                                                        title={strings.cadRelatedTitle}
                                                        reportingEventNumber={
                                                            lastSearchedReportingEventNumber
                                                        }
                                                        cadAgencyEventNumber={
                                                            lastSearchedCadAgencyEventNumber
                                                        }
                                                        noCadTicketsLabel={strings.noCadEvents}
                                                    />
                                                )}
                                                {lastSearchedReportingEventNumber && (
                                                    <LinkedReportsSection
                                                        title={strings.relatedTitle(
                                                            fieldConfiguration.displayName,
                                                            lastSearchedReportingEventNumber
                                                        )}
                                                        reportShortTitleViewModels={currentDepartmentReportShortTitleViewModelsWhere(
                                                            lastSearchedReportingEventNumber
                                                        )}
                                                        noReportsMessage={strings.noReportsUnderRen(
                                                            fieldConfiguration.displayName
                                                        )}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </>
                                );
                            }}
                        </ArbiterField>
                    )}
                />
            </PortalModal>
        );
    }
}
const ReportSearchForm = withRegistry()(_ReportSearchForm);

const CreateReportModal = ({
    currentDepartmentReportShortTitleViewModelsWhere,
    chooseReportDefinition,
    clearReportDefinitionFromCreateReportModal,
    submitFormSearch,
    submitCreateReportModal,
    submitAutoCreateReportModal,
    buttonComponent,
    reportingEventNumberChange,
    recordNumberChange,
    cadAgencyEventNumberChange,
}) => (
    <OverlayBase id={overlayIdEnum.CREATE_REPORT_MODAL} testId={testIds.CREATE_REPORT_MODAL}>
        {({ overlayState, close, ...otherProps }) => {
            const {
                reportDefinition,
                reportDefinitions,
                onCloseRef,
            } = overlayState.customProperties;
            return (
                <div>
                    {buttonComponent}
                    {isUndefinedOrNull(reportDefinition) ? (
                        <ReportDefinitionList
                            reportDefinitions={reportDefinitions}
                            chooseReportDefinition={chooseReportDefinition}
                            okText={null}
                            title={strings.title}
                            onClose={close}
                            overlayState={overlayState}
                            onCloseRef={onCloseRef}
                            {...otherProps}
                        />
                    ) : (
                        <ReportSearchForm
                            submitFormSearch={submitFormSearch}
                            currentDepartmentReportShortTitleViewModelsWhere={
                                currentDepartmentReportShortTitleViewModelsWhere
                            }
                            okText={strings.createReportButton}
                            reportDefinition={reportDefinition}
                            clearReportDefinitionFromCreateReportModal={
                                clearReportDefinitionFromCreateReportModal
                            }
                            onClose={close}
                            submitCreateReportModal={submitCreateReportModal}
                            submitAutoCreateReportModal={submitAutoCreateReportModal}
                            overlayState={overlayState}
                            onCloseRef={onCloseRef}
                            reportingEventNumberChange={reportingEventNumberChange}
                            recordNumberChange={recordNumberChange}
                            cadAgencyEventNumberChange={cadAgencyEventNumberChange}
                            {...otherProps}
                        />
                    )}
                </div>
            );
        }}
    </OverlayBase>
);

const mapStateToProps = createStructuredSelector({
    currentDepartmentReportShortTitleViewModelsWhere: currentDepartmentReportShortTitleViewModelsWhereSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
    chooseReportDefinition: (reportDefinition) =>
        dispatch(
            chooseReportDefinition({
                reportDefinition,
                router: props.router,
            })
        ),
    clearReportDefinitionFromCreateReportModal: () =>
        dispatch(clearReportDefinitionFromCreateReportModal()),
    submitFormSearch: () => dispatch(submitFormSearch()),
    submitCreateReportModal: (reportDefinitionId, useCadAsRecordNumber) => () =>
        dispatch(
            submitCreateReportModal({
                reportDefinitionId,
                useCadAsRecordNumber,
                router: props.router,
                isRenAutogenerated: false,
            })
        ),
    submitAutoCreateReportModal: (reportDefinitionId, useCadAsRecordNumber) => () =>
        dispatch(
            submitCreateReportModal({
                reportDefinitionId,
                useCadAsRecordNumber,
                router: props.router,
                isRenAutogenerated: true,
            })
        ),
    reportingEventNumberChange: (renConfiguration, ren) =>
        dispatch(onReportingEventNumberChange({ renConfiguration, ren })),
    recordNumberChange: () => dispatch(onRecordNumberChange()),
    cadAgencyEventNumberChange: () => dispatch(onCadAgencyEventNumberChange()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CreateReportModal);

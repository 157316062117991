/*
 * This file was converted from typescript in order to be migrated to client-common on 08/22/2019
 * Method signature left as comments to help make converting back easier
 */

const FOCUSABLE_SELECTOR =
    'a, input, select, textarea, button, *[tabindex], *[contenteditable="true"]';
const FOCUS_INSIDE_SELECTOR = '*[data-focus-inside="true"]';

// https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js

// const isVisible = (el: Element) => {
const isVisible = (el) => {
    if (!(el instanceof HTMLElement)) {
        return false;
    }
    return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
};

// const checkForDisabledFieldset = (el: HTMLElement): Boolean => {
const checkForDisabledFieldset = (el) => {
    let parent = el.parentNode;
    while (parent && !(parent instanceof HTMLFieldSetElement)) {
        parent = parent.parentNode;
    }
    if (parent) {
        return !parent.disabled;
    }
    return true;
};

// const isFocusable = (el: HTMLElement) => {
const isFocusable = (el) => {
    if (el.tabIndex !== undefined && el.tabIndex < 0) {
        return false;
    }
    if (el instanceof HTMLAnchorElement) {
        return (el.href || el.tabIndex !== undefined) && isVisible(el);
    }
    if (
        el instanceof HTMLInputElement ||
        el instanceof HTMLSelectElement ||
        el instanceof HTMLTextAreaElement ||
        el instanceof HTMLButtonElement
    ) {
        return el.disabled === false && checkForDisabledFieldset(el) && isVisible(el);
    }
    return el.tabIndex !== undefined && isVisible(el);
};

// const getPotentiallyFocusableElements = (container: HTMLElement | null): Array<HTMLElement> => {
const getPotentiallyFocusableElements = (container) => {
    if (container === null) {
        return [];
    }
    const htmlElements = Array.from(container.querySelectorAll(FOCUSABLE_SELECTOR));
    return htmlElements;
};

// export const getFocusableElements = (container: HTMLElement | null): Array<HTMLElement> => {
export const getFocusableElements = (container) => {
    return getPotentiallyFocusableElements(container).filter(isFocusable);
};

// export const getElementToFocusInside = (container: HTMLElement | null): HTMLElement | null => {
export const getElementToFocusInside = (container) => {
    if (container === null) {
        return null;
    }
    const htmlElements = Array.from(container.querySelectorAll(FOCUS_INSIDE_SELECTOR));
    return htmlElements[0] || null;
};

const isSkippingElements = (element, skippingFocusableElementInputIds) => {
    return (
        element &&
        skippingFocusableElementInputIds?.length &&
        skippingFocusableElementInputIds.includes(element?.dataset.inputid)
    );
};

export const getNextFocusableElement = (
    el = document.activeElement,
    skippingFocusableElementInputIds = []
) => {
    if (!(el instanceof HTMLElement) || el === document.body) {
        return null;
    }

    const potentialElements = getPotentiallyFocusableElements(document.body);
    const index = potentialElements.indexOf(el);
    if (index === -1) {
        throw new Error('Tried to focusNextElement from a non-potentially-focusable element');
    }

    let i = index + 1;
    for (i; i < potentialElements.length; i++) {
        if (
            isFocusable(potentialElements[i]) &&
            !isSkippingElements(potentialElements[i], skippingFocusableElementInputIds)
        ) {
            return potentialElements[i];
        }
    }
    for (i = 0; i < potentialElements.length; i++) {
        if (
            isFocusable(potentialElements[i]) &&
            !isSkippingElements(potentialElements[i], skippingFocusableElementInputIds)
        ) {
            return potentialElements[i];
        }
    }
};

// export const focusNextElement = (el: HTMLElement = document.activeElement as HTMLElement): void => {
export const focusNextElement = (
    el = document.activeElement,
    skippingFocusableElementInputIds = []
) => {
    const nextFocusableElement = getNextFocusableElement(el, skippingFocusableElementInputIds);
    if (!nextFocusableElement) {
        return;
    }
    nextFocusableElement.focus();
};

export const getPreviousFocusableElement = (el = document.activeElement) => {
    if (!(el instanceof HTMLElement)) {
        return;
    }
    const potentialElements = getPotentiallyFocusableElements(document.body);
    const index = potentialElements.indexOf(el);
    if (index === -1) {
        throw new Error('Tried to focusPreviousElement from a non-potentially-focusable element');
    }

    let i = index - 1;
    for (i; i >= 0; i--) {
        if (isFocusable(potentialElements[i])) {
            return potentialElements[i];
        }
    }
    for (i = potentialElements.length - 1; i > index; i--) {
        if (isFocusable(potentialElements[i])) {
            return potentialElements[i];
        }
    }
};

export const blurActiveElement = () => {
    if (document.activeElement) {
        document.activeElement.blur();
    }
};

import React from 'react';

import { Observer, Fields } from 'markformythree';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_PERSON_PROFILE_EMERGENCY_CONTACT_INFORMATION_LABEL } from '~/client-common/core/enums/universal/fields';
import testIds from '../../../../core/testIds';

import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTCheckbox } from '../../forms/components/checkboxes/Checkbox';
import { ArbiterMFTNItems } from '../../forms/components/NItems';
import { ArbiterMFTPhoneNumberInput } from '../../forms/components/PhoneNumberInput';
import { ArbiterMFTText } from '../../forms/components/Text';
import { renderAddButton } from '../../names/components/nameFormNItemsRenderers';
import { emergencyContactFields } from '../../names/util/nameFieldHelpers';
import { ArbiterMFTDatePicker } from '../../forms/components/DatePicker';
import { DefaultDateEffectiveFromToCurrent } from '../../names/util/nameFormHelpers';
import { FormSection } from './FormSection';
import { NItemsRow } from './NItemsRow';

const placeholders = componentStrings.core.genericPlaceholders;

function renderNItem({ index, form, removeItem }) {
    const path = `emergencyContacts[${index}]`;

    return (
        <FormSection removeItem={removeItem}>
            <FormRow>
                <ArbiterMFTText path="emergencyContactName" length="md" />
                <ArbiterMFTText path="emergencyContactLinkType" length="md" />
            </FormRow>
            <ArbiterMFTCheckbox path="isGuardian" />
            <ArbiterMFTText path="emergencyContactAddress" />
            <FeatureFlagged
                flag="RMS_DIALING_CODE_DISPLAYED_ENABLED"
                fallback={
                    <ArbiterMFTText
                        placeholder={placeholders.phoneNumbers}
                        path="emergencyContactPhoneNumber"
                        length="md"
                    />
                }
            >
                <ArbiterMFTPhoneNumberInput
                    path="emergencyContactPhoneNumber"
                    form={form}
                    length="md"
                />
            </FeatureFlagged>
            <Observer
                subscriptions={{
                    id: ['id', Fields.MODEL],
                    dateNonPrefillConditions: [`${path}.dateEffectiveFrom`, [Fields.DIRTY]],
                }}
                render={({ id, dateNonPrefillConditions }) => {
                    return (
                        <DefaultDateEffectiveFromToCurrent
                            entityId={id}
                            form={form}
                            NItemPath={path}
                            dateNonPrefillConditions={dateNonPrefillConditions}
                        />
                    );
                }}
            />
            <ArbiterMFTDatePicker
                path="dateEffectiveTo"
                variant={ArbiterMFTDatePicker.variants.LOCAL_DATE}
            />
        </FormSection>
    );
}

export function PersonProfileFormEmergencyContactFields({ form }) {
    const labels = useFields([DISPLAY_ONLY_PERSON_PROFILE_EMERGENCY_CONTACT_INFORMATION_LABEL]);
    return (
        <ArbiterMFTNItems
            path="emergencyContacts"
            addItemOnEmpty={false}
            addText={labels[DISPLAY_ONLY_PERSON_PROFILE_EMERGENCY_CONTACT_INFORMATION_LABEL]}
            hideAddButtonOnEmptyItem={true}
            childFieldKeys={emergencyContactFields}
            renderRowContainer={({ itemElement, index, item }) => (
                <NItemsRow key={index} index={index} item={item} children={itemElement} />
            )}
            render={({ index, removeItem }) => renderNItem({ index, form, removeItem })}
            renderRemoveButton={undefined}
            renderAddButton={(props) =>
                renderAddButton({
                    ...props,
                    testId: testIds.PERSON_PROFILE_ADD_EMERGENCY_CONTACT,
                })
            }
            formName={form.name}
        />
    );
}

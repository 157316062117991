import React, { useCallback } from 'react';
import { DataTable, DataTableProps } from '@arc/data-table';
import { Tr } from '@arc/table';
import { Box } from '@arc/layout';
import { cssVar } from 'arc';
import styled, { css } from 'styled-components';

import testIds from '../../../core/testIds';
import NoDataBlock from './NoDataBlock';
import { SkeletonTable } from './Skeleton';

const TableWrapper = styled.div`
    & th {
        font-size: ${cssVar('arc.fontSizes.sm')};
        position: sticky;
        top: 0;
        z-index: 10;
    }

    /* fix spacing for the container of sort arrow icon */
    & th > div > div > div {
        display: flex;
        align-items: center;
        gap: ${cssVar('arc.space.1')};
    }

    /* fix sizes for the sort arrow icon itself */
    & thead svg {
        width: 1.125rem;
        height: 1.125rem;
    }

    & > div {
        width: 740px;
    }

    & .arc-table_container ~ div {
        padding: ${cssVar('arc.space.4')} ${cssVar('arc.space.6')} 0;
        border-top: ${cssVar('arc.space.px')} solid ${cssVar('arc.colors.border.default')};
    }
`;

export const DataTableRow = styled(Tr)`
    & td {
        border: none;
    }

    ${(props) =>
        !props.hideBorder &&
        css`
            & {
                border-bottom: 1px solid ${cssVar('arc.colors.border.default')};
            }
        `}
`;

type DataTableWrapperProps<T extends unknown> = DataTableProps<T> & {
    noDataText: string;
    initialLoading: boolean;
    loading: boolean;
    rowTestId?: keyof typeof testIds;
    wrapperClassName?: string;
};

export const DataTableWrapper = <T extends unknown>({
    noDataText,
    initialLoading,
    loading,
    rowTestId,
    wrapperClassName,
    data = [],
    ...other
}: DataTableWrapperProps<T>) => {
    const renderRow = useCallback(
        ({ row, renderedCells }) => (
            <DataTableRow key={row.id} data-test-id={rowTestId}>
                {renderedCells}
            </DataTableRow>
        ),
        [rowTestId]
    );

    if (initialLoading) {
        return <SkeletonTable />;
    } else if (!loading && !data.length) {
        return (
            <Box textAlign="center">
                <NoDataBlock>{noDataText}</NoDataBlock>
            </Box>
        );
    } else {
        return (
            <TableWrapper className={wrapperClassName}>
                <DataTable data={data} renderRow={renderRow} {...other} />
            </TableWrapper>
        );
    }
};

import _, {
    some,
    pick,
    filter,
    get,
    groupBy,
    memoize,
    map,
    includes,
    isArray,
    omit,
    ObjectIterateeCustom,
} from 'lodash';
import { createSelector } from 'reselect';
import {
    AttributeView,
    ReportAttribute,
    ArrestAttribute,
    AttributeTypeEnumType,
    WarrantAttribute,
    ItemAttribute,
    NameAttribute,
    OffenseAttribute,
    StopEntityAttribute,
} from '@mark43/rms-api';
import { isUndefinedOrNull } from '../../../../../helpers/logicHelpers';

// configs
import { attributeStatuses } from '../../configuration';

// helpers
import {
    AttributeViewModel,
    buildAttributeViewModels,
    formatAttribute,
    formatAttributeAbbrev,
    formatAttributeAbbrevAndValue,
    globalAttributeIds,
    formatAttributeWithOther,
    formatAttributeValue,
} from '../../utils/attributesHelpers';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import createNormalizedModule, { ModuleShape } from '../../../../utils/createNormalizedModule';
import { elasticAttributeDetailsSelector } from '../../../elastic-attribute-details/state/data';

const { ACTIVE, EXPIRED } = attributeStatuses;

export const NEXUS_STATE_PROP = 'attributes';

const attributeModule = createNormalizedModule<AttributeView>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeAttributes = attributeModule.actionCreators.storeEntities;

// SELECTORS
export const attributesSelector = attributeModule.selectors.entitiesSelector;

export const getAttributeByIdSelector = createSelector(
    attributesSelector,
    (attributes) => (id: number | string) => attributes[id]
);

export const getAttributesByIdsSelector = createSelector(
    attributesSelector,
    (attributes) => (ids: number | number[]) =>
        filter(attributes, (attribute) =>
            !isArray(ids) ? includes([ids], attribute.id) : includes(ids, attribute.id)
        )
);

export const attributeIsOtherSelector = createSelector(
    getAttributeByIdSelector,
    (attributesById) => (id: number) => get(attributesById(id), 'other')
);

/**
 * All attributes of the given type(s), normalized.
 * @param   [type] If not provided, all attributes are
 *   included.
 * @param  [predicate] A predicate to further filter attributes by
 */
export const attributesByTypeSelector = createSelector(attributesSelector, (attributes) => {
    const attributesByType = groupBy(attributes, 'type');
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    return (type: AttributeTypeEnumType | AttributeTypeEnumType[], predicate?: any) => {
        const attributesForType = !isArray(type)
            ? attributesByType[type]
            : type.reduce<AttributeView[]>((acc, t) => acc.concat(attributesByType[t] || []), []);
        return predicate ? filter(attributesForType, predicate) : attributesForType;
    };
});

export const parentAttributeIdByAttributeIdSelector = createSelector(
    attributesSelector,
    (attributes) => memoize((attributeId: number) => _.get(attributes[attributeId], 'parentId'))
);

/**
 * Retrieve all attributes whose parent attribute has the provided parentAttributeId
 */
export const attributesWithParentAttributeIdSelector = createSelector(
    attributesSelector,
    (attributes) => memoize((parentId: number) => filter(attributes, { parentId }))
);

const nonGlobalAttributesSelector = createSelector(attributesSelector, (attributes) =>
    omit(attributes, globalAttributeIds)
);

/**
 * Format a display string for the given attribute(s). If multiple attributes
 *   are given, they are sorted alphabetically.
 *
 * @param  [id]
 * @param          [sort=false] Whether to sort the display strings
 *   alphabetically (when there are multiple attributes).
 * @param          [includeParent=false] Whether to prefix each
 *   attribute name with its parent attribute name.
 */
export const formatAttributeByIdSelector = createSelector(
    attributesSelector,
    elasticAttributeDetailsSelector,
    parentAttributeIdByAttributeIdSelector,
    (attributes, elasticAttributeDetails, parentAttributeIdByAttributeId) => (
        id: number | number[] | undefined,
        sort = true,
        includeParent = false
    ) => {
        const ids = _.isArray(id) ? id : _.compact([id]);
        return (
            _(ids)
                .map((id) => {
                    const useElasticAttributeDetail = isUndefinedOrNull(attributes[id]);
                    const attribute = useElasticAttributeDetail
                        ? elasticAttributeDetails[id]
                        : attributes[id];
                    return formatAttribute(
                        attribute,
                        includeParent && !useElasticAttributeDetail
                            ? attributes[parentAttributeIdByAttributeId(id) as number]
                            : undefined
                    );
                })
                // optional sorting
                .thru((strings) => (sort ? _.sortBy(strings) : strings))
                .join(', ')
        );
    }
);

export const formatAttributeWithOtherSelector = createSelector(
    formatAttributeByIdSelector,
    (formatAttributeById) => (params?: {
        attributeId?: number;
        other?: string;
        joinWith?: string;
    }) => {
        if (!params) {
            return '';
        }
        return formatAttributeWithOther(
            formatAttributeById(params.attributeId),
            params.other,
            params.joinWith
        );
    }
);

/**
 * @return Display Abbrev string.
 */
export const formatAttributeAbbrevByIdSelector = createSelector(
    attributesSelector,
    (attributes) => (id: number) => formatAttributeAbbrev(attributes[id])
);

export const formatAttributeValueByIdSelector = createSelector(
    attributesSelector,
    (attributes) => (id: number) => formatAttributeValue(attributes[id])
);

export const formatAttributeAbbrevAndValueByIdSelector = createSelector(
    attributesSelector,
    (attributes) => (id: number) => (id ? formatAttributeAbbrevAndValue(attributes[id]) : '')
);

/**
 * Format a display string for the given subdivision attributes, ordered by
 *   depth.
 *
 * @param   [join=true] Whether to join all the subdivisions into a
 *   single display string.
 * @return  Array or string depending on `join`.
 */
export const formatSubdivisionAttrIdsSelector = createSelector(
    attributesSelector,
    elasticAttributeDetailsSelector,
    (attributes, elasticAttributeDetails) => (
        subdivisionAttrIds: number[],
        join = true,
        joinWith?: string
    ) => {
        const subdivisionAttributes = {
            ...pick(elasticAttributeDetails, subdivisionAttrIds),
            ...pick(attributes, subdivisionAttrIds),
        };
        const displays = [
            formatAttribute(
                _.find(subdivisionAttributes, {
                    type: 'SUBDIVISION_DEPTH_1',
                })
            ),
            formatAttribute(
                _.find(subdivisionAttributes, {
                    type: 'SUBDIVISION_DEPTH_2',
                })
            ),
            formatAttribute(
                _.find(subdivisionAttributes, {
                    type: 'SUBDIVISION_DEPTH_3',
                })
            ),
            formatAttribute(
                _.find(subdivisionAttributes, {
                    type: 'SUBDIVISION_DEPTH_4',
                })
            ),
            formatAttribute(
                _.find(subdivisionAttributes, {
                    type: 'SUBDIVISION_DEPTH_5',
                })
            ),
        ];

        return join ? joinTruthyValues(displays, joinWith) : displays;
    }
);

/**
 * All attribute view models of the given type. Each view model contains
 *   computed properties needed for the ui.
 * @param   [type] If not provided, all attributes are included.
 */
export const attributeViewModelsByTypeSelector = createSelector(
    attributesByTypeSelector,
    (attributesByType) =>
        memoize(
            (type: AttributeTypeEnumType) => buildAttributeViewModels(attributesByType(type)),
            (type: AttributeTypeEnumType) => (!_.isArray(type) ? type : _.map(type, 'id').join('-'))
        )
);

/**
 * Whether there are any active attributes of the given type. Active means not
 *   expired or scheduled based on attribute dates.
 */
export const attributeTypeHasActiveAttributesSelector = createSelector(
    attributeViewModelsByTypeSelector,
    (viewModelsByType) => (
        type: AttributeTypeEnumType,
        predicate: ObjectIterateeCustom<AttributeViewModel[], boolean>
    ) =>
        some(predicate ? filter(viewModelsByType(type), predicate) : viewModelsByType(type), {
            status: ACTIVE,
        })
);

/**
 * Active attributes of the given type, normalized. Active means not expired or
 *   scheduled based on attribute dates.
 * @param   [type]
 * @param  [predicate] A predicate to further filter attributes by
 * @return
 */
export const activeAttributesByTypeSelector = createSelector(
    attributeViewModelsByTypeSelector,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (viewModelsByType) => (type: AttributeTypeEnumType, predicate?: any) => {
        let wrapper = _(viewModelsByType(type)).filter({ status: ACTIVE });

        if (predicate) {
            wrapper = wrapper.filter(predicate);
        }

        return wrapper.value();
    }
);

/**
 * Expired attributes of the given type, normalized.
 */
export const expiredAttributesByTypeSelector = createSelector(
    attributeViewModelsByTypeSelector,
    (viewModelsByType) => (type: AttributeTypeEnumType) =>
        filter(
            viewModelsByType(type),
            (attribute) => attribute.status === EXPIRED && attribute.enabled
        )
);

/**
 * Given an attribute object from xAttributes, ie nameAttributes,
 * returns object in shape of
 * {
 *     ATTRIBUTE_TYPE: [myDisplayValues]
 * }
 *
 * where myDisplayValues are strings
 *
 * @param   attributesObject  Attributes to be formatted
 */
export const attributesDisplayValuesByTypeForAttributesSelector = createSelector(
    formatAttributeByIdSelector,
    (formatAttributeById) => (attributesObject: Partial<LinkAttribute>[]) =>
        _(attributesObject)
            .groupBy('attributeType')
            .mapValues((attributes) => {
                return map(attributes, (attr) => {
                    const parentAttributeCombined = formatAttributeById(
                        attr.attributeId,
                        /* sort */ false,
                        /* include parent */ true
                    );
                    return joinTruthyValues([parentAttributeCombined, attr.description], ' - ');
                });
            })
            .value()
);

type LinkAttribute =
    | ArrestAttribute
    | ReportAttribute
    | WarrantAttribute
    | ItemAttribute
    | NameAttribute
    | OffenseAttribute
    | StopEntityAttribute;

/**
 * Given an attribute object from xAttributes, ie nameAttributes,
 * with parentAttribute: returns array of objects in shape of
 * {
 *      parentAttribute,
 *      description
 * }
 * (relevant for CLOTHING)
 * @param       Attributes to be formatted                See description for shape of returned objects
 */
export const linkAttributeDisplayObjectsSelector = createSelector(
    formatAttributeByIdSelector,
    (formatAttributeById) => (attributes: LinkAttribute[]) =>
        map(attributes, (attr) => {
            const parentAttributeCombined = formatAttributeById(
                attr.attributeId,
                /* sort */ false,
                /* include parent */ true
            );
            return {
                parentAttribute: parentAttributeCombined,
                description: attr.description,
            };
        })
);

export const formatLinkAttributesSelector = createSelector(
    formatAttributeWithOtherSelector,
    (formatAttributeWithOther) => (
        linkAttributes: LinkAttribute[],
        attributeType: AttributeTypeEnumType
    ) => {
        return _(linkAttributes)
            .filter((attribute) =>
                attributeType ? attribute.attributeType === attributeType : true
            )
            .map((linkAttribute) =>
                formatAttributeWithOther({
                    attributeId: linkAttribute.attributeId,
                    other: linkAttribute.description,
                    joinWith: ' - ',
                })
            )
            .sortBy()
            .join(', ');
    }
);

const attributeTypesForAttributes = (attrs: ModuleShape<AttributeView>) =>
    _(attrs).map('type').uniq().value();

/**
 * Using all non-global attributes in a department, returns a distinct array of
 * attribute types.
 * @return  Attribute type names
 */
export const nonGlobalAttributeTypesSelector = createSelector(
    nonGlobalAttributesSelector,
    attributeTypesForAttributes
);

// REDUCER
export default attributeModule.reducerConfig;

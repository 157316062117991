import React from 'react';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { last, sortBy, get, filter, omit } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { SidePanelSection } from '../../../../legacy-redux/components/core/SidePanel';
import {
    addUploadingFiles,
    saveUploadedFiles,
    uploadFileFailure,
    uploadedFilesSelector,
    uploadingFilesSelector,
    existingUploadingFilesSelector,
    uploadedImagesSelector,
    uploadingImagesSelector,
} from '../state/ui/attachmentsSidePanel';
import Row from '../../../core/components/Row';
import ImageUpload from '../../../core/forms/components/ImageUpload';
import { buildFilesToSave, buildUploadingFiles } from '../utils/uploadFilesHelpers';
import AttachmentsSidePanelUploadForm from './AttachmentsSidePanelUploadForm';

const strings = componentStrings.reports.core.AttachmentsSidePanel;

const AttachmentsSidePanelImageUploadForm = compose(
    connect(
        (state, { entityLinkDetails: { imageLinkType } }) => {
            return {
                uploadingFiles: uploadingFilesSelector(state),
                uploadedFiles: uploadedFilesSelector(state),
                existingUploadingFiles: existingUploadingFilesSelector(state),
                uploadedImages: uploadedImagesSelector(state)(imageLinkType),
                uploadingImages: uploadingImagesSelector(state)(imageLinkType),
            };
        },
        { addUploadingFiles, saveUploadedFiles, uploadFileFailure }
    ),
    withHandlers({
        onUploadFailure: ({ overlayBase, uploadFileFailure }) => (error, files) => {
            const errorMessage = uploadFileFailure(error, files, overlayBase);

            if (errorMessage) {
                overlayBase.setError(errorMessage);
            }

            overlayBase.setLoading(false);
        },
        onUploadImagesStart: ({ addUploadingFiles, entityLinkDetails, overlayBase }) => (
            uploads
        ) => {
            overlayBase.setLoading(true);
            const uploadingFiles = buildUploadingFiles(uploads, entityLinkDetails);
            addUploadingFiles(uploadingFiles);
        },
        onUploadImagesSuccess: ({ entityLinkDetails, saveUploadedFiles, overlayBase }) => (
            uploadedFiles
        ) => {
            const files = buildFilesToSave(uploadedFiles, entityLinkDetails);
            const hasFailedUploads = saveUploadedFiles(files);

            if (!hasFailedUploads) {
                overlayBase.setError();
                overlayBase.setLoading(true); // resetting because setError automatically sets isLoading to false
            }
        },
        onAllBatchesComplete: ({ overlayBase }) => () => {
            overlayBase.setLoading(false);
        },
    })
)(
    ({
        onUploadImagesSuccess,
        onUploadImagesStart,
        onUploadFailure,
        onAllBatchesComplete,
        uploadingImages,
        uploadedImages,
        attachments,
        entityLinkDetails,
        overlayBase,
        entityType,
    }) => {
        const attachmentImages = filter(attachments, ['attachmentType', 'IMAGE']);

        const currentProfileImage =
            last(uploadingImages) ||
            last(sortBy(uploadedImages, 'file.id')) ||
            last(sortBy(attachmentImages, 'attachmentId'));
        const currentProfileImageFile = getAttachmentFile(currentProfileImage);
        const currentProfileImageUrl = get(currentProfileImageFile, 'fileWebServerPath');
        const attachmentLinkDetails = omit(entityLinkDetails, ['imageLinkType']);

        return (
            <>
                <SidePanelSection title={strings.profilePhoto}>
                    <Row>
                        <ImageUpload
                            multiple={true}
                            onSuccess={onUploadImagesSuccess}
                            onStart={onUploadImagesStart}
                            onError={onUploadFailure}
                            onAllBatchesComplete={onAllBatchesComplete}
                            height="120px"
                            width="120px"
                            imageUrl={currentProfileImageUrl}
                        />
                    </Row>
                </SidePanelSection>
                <SidePanelSection title={strings.additionalAttachments}>
                    <AttachmentsSidePanelUploadForm
                        attachments={attachments}
                        entityLinkDetails={attachmentLinkDetails}
                        overlayBase={overlayBase}
                        autoOpenDisabled={true}
                        entityType={entityType}
                    />
                </SidePanelSection>
            </>
        );
    }
);

export default AttachmentsSidePanelImageUploadForm;

import React from 'react';
import { reduxForm } from 'redux-form-mark43';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { trim } from 'lodash';

import stringsConfig from '~/client-common/core/strings';
import {
    REPORT_RECORD_NUMBER,
    CAD_TICKET_CAD_AGENCY_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import recordSequenceTypeEnum from '~/client-common/core/enums/client/recordSequenceTypeEnum';

import Row from '../../../modules/core/components/Row';
import FormRow from '../../../modules/core/forms/components/FormRow';
import Text from '../../../modules/core/forms/components/Text';
import { currentUserDepartmentIdSelector } from '../../../modules/core/current-user/state/ui';
import {
    validateRecordNumberUpdate,
    updateRecordNumber,
    storeHandleSubmit,
    disableRecordNumberUpdate,
} from '../../actions/recordNumberChangeActions';
import { saveBoxHalt } from '../../actions/boxActions';
import {
    recordNumberChangeUiSelector,
    newRecordNumberFieldSelector,
} from '../../selectors/recordNumberChangeSelectors';
import { currentReportSelector } from '../../selectors/reportSelectors';
import { recordNumberChangeValidator } from '../../validation/components/recordNumberChangeValidators';
import { recordNumberChangeFormFieldList } from '../../configs/recordNumberChangeConfig';
import asyncValidatorFromSyncValidator from '../../validation/helpers/asyncValidatorFromSyncValidator';

const strings = stringsConfig.components.reports.RecordNumberChangeForm;
const labels = strings.labels;

const context = {
    name: boxEnum.RECORD_NUMBER_CHANGE_MODAL,
};

class RecordNumberChangeForm extends React.Component {
    componentDidMount() {
        this.props.storeHandleSubmit(this.props.handleSubmit.bind(this));
    }

    render() {
        const { ui, fields, recordSequenceType } = this.props;

        const recordNumberDisplayName = this.props.formatFieldByName(REPORT_RECORD_NUMBER);
        const cadEventNumberDisplayName = this.props.formatFieldByName(
            CAD_TICKET_CAD_AGENCY_EVENT_NUMBER
        );

        const isCadEventRecordSequenceType =
            recordSequenceType === recordSequenceTypeEnum.CAD_EVENT;

        return (
            <div>
                <FormRow>
                    <Text
                        length="md"
                        label={
                            isCadEventRecordSequenceType
                                ? labels.currentCadEventNumber(cadEventNumberDisplayName)
                                : labels.currentRecordNumber(recordNumberDisplayName)
                        }
                        disabled={true}
                        {...fields.currentRecordNumber}
                    />
                    {isCadEventRecordSequenceType && (
                        <Text
                            length="md"
                            label={labels.newCadEventNumber(cadEventNumberDisplayName)}
                            goButton={true}
                            loading={ui.loading}
                            goButtonText={labels.validateButton}
                            onClick={this.props.onValidate}
                            onPressEnter={this.props.onValidate}
                            alterValue={(value) => trim(value)}
                            {...fields.newRecordNumber}
                        />
                    )}
                    {!isCadEventRecordSequenceType && (
                        <Text
                            length="md"
                            label={labels.newRecordNumber(recordNumberDisplayName)}
                            alterValue={(value) => trim(value)}
                            {...fields.newRecordNumber}
                        />
                    )}
                </FormRow>
                <Row>
                    <h5>{ui.message}</h5>
                </Row>
            </div>
        );
    }
}

const reduxFormConfig = {
    form: 'recordNumberChange',
    fields: recordNumberChangeFormFieldList,
};

const selectors = createStructuredSelector({
    ui: recordNumberChangeUiSelector,
    newRecordNumberField: newRecordNumberFieldSelector,
    currentReport: currentReportSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
});

const mapDispatchToProps = (dispatch, props) => ({
    storeHandleSubmit(handleSubmit) {
        dispatch(storeHandleSubmit(handleSubmit));
    },
    onValidate() {
        return asyncValidatorFromSyncValidator(
            recordNumberChangeValidator(
                {
                    newRecordNumber: props.newRecordNumberField.value,
                    currentRecordNumber: props.currentReport.recordNumber,
                },
                props
            )
        )
            .then(() => {
                const reportDefinition = props.reportDefinition;
                const newRecordNumberField = props.newRecordNumberField;
                if (newRecordNumberField) {
                    dispatch(
                        validateRecordNumberUpdate(reportDefinition, newRecordNumberField.value)
                    );
                }
            })
            .catch((err) => {
                dispatch(disableRecordNumberUpdate(err._error));
            });
    },
    onSubmit(data) {
        if (props.recordSequenceType === recordSequenceTypeEnum.CAD_EVENT) {
            if (props.newRecordNumberField.value !== props.ui.validRecordNumber) {
                dispatch(
                    disableRecordNumberUpdate(
                        strings.changedSinceValidationError(
                            props.formatFieldByName(CAD_TICKET_CAD_AGENCY_EVENT_NUMBER)
                        )
                    )
                );
                dispatch(saveBoxHalt(context));
            }
        }
        return asyncValidatorFromSyncValidator(
            recordNumberChangeValidator(
                {
                    newRecordNumber: data.newRecordNumber,
                    currentRecordNumber: data.currentRecordNumber,
                },
                props
            )
        )
            .then(() => {
                return dispatch(
                    updateRecordNumber(props.currentReport.id, props.newRecordNumberField.value)
                );
            })
            .catch((err) => {
                dispatch(disableRecordNumberUpdate(err._error));
                dispatch(saveBoxHalt(context));
            });
    },
});

RecordNumberChangeForm = reduxForm(
    reduxFormConfig,
    selectors,
    mapDispatchToProps
)(RecordNumberChangeForm);

export default connect(selectors, mapDispatchToProps)(RecordNumberChangeForm);

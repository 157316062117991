import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { reject, map } from 'lodash';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { LegacySelectOption, LegacySelectOptions } from '../../helpers/selectHelpers';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import AttributeSelect from './AttributeSelect';

const { connectRRFInput } = reactReduxFormHelpers;

// Use `filterOptions` to access the options and map over them
const prettifyOption = (option: LegacySelectOption) => ({
    ...option,
    display: `${prettify(option.display)}`,
});

const ItemTypeAttributeSelect = ({
    multiple = true,
    includeExpired = true,
    rejectByCategoryFilter,
    ...props
}: {
    multiple?: boolean;
    includeExpired?: boolean;
    rejectByCategoryFilter?: typeof globalAttributes['itemType'][keyof typeof globalAttributes['itemType']];
}) => {
    return (
        <AttributeSelect
            {...props}
            multiple={multiple}
            includeExpired={includeExpired}
            attributeType={AttributeTypeEnum.ITEM_TYPE.name}
            filterOptions={(options: LegacySelectOptions<LegacySelectOption>) => {
                const prettifiedOptions = map(options, prettifyOption);
                if (rejectByCategoryFilter) {
                    return reject(prettifiedOptions, {
                        value: rejectByCategoryFilter,
                    });
                }
                return prettifiedOptions;
            }}
        />
    );
};

// @ts-expect-error client-common to client RND-7529
export const RRFItemTypeAttributeSelect = connectRRFInput(ItemTypeAttributeSelect);

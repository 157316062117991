import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';

import { useSelector } from 'react-redux';
import { trafficCrashPersonViewModelsByPersonIdSelector } from '~/client-common/core/domain/traffic-crash-persons/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';

import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import { TrafficCrashEntityDetailSummarySection } from './TrafficCrashEntityDetailSummarySection';

const strings = componentStrings.trafficCrashPersonSummary;

const TrafficCrashPersonSummary = ({ personId }: { personId: number }) => {
    const offenseDisplayName = useOffenseFieldName();
    const trafficCrashPersonViewModelsByPersonId = useSelector(
        trafficCrashPersonViewModelsByPersonIdSelector
    );

    const viewModel = trafficCrashPersonViewModelsByPersonId(personId);

    if (!viewModel) {
        return null;
    }

    const viewModelProperties = getViewModelProperties(viewModel);

    return (
        <SummaryList labelWidth={250} contentWidth={250}>
            <SummaryRow label={strings.age}>{viewModel.age}</SummaryRow>
            <SummaryRow label={strings.personTypeAttrId}>
                {viewModelProperties.personTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.incidentResponderAttrId}>
                {viewModelProperties.incidentResponderAttrId}
            </SummaryRow>
            <SummaryRow label={strings.vehicleUnitNumber}>{viewModel.vehicleUnitNumber}</SummaryRow>
            <SummaryRow label={strings.driverActions}>
                {viewModelProperties.driverActions}
            </SummaryRow>
            <SummaryRow label={strings.seatingPositionAttrId}>
                {viewModelProperties.seatingPositionAttrId}
            </SummaryRow>
            <SummaryRow label={strings.restraintsAndHelmetsAttrId}>
                {viewModelProperties.restraintsAndHelmetsAttrId}
            </SummaryRow>
            <SummaryRow label={strings.restraintSystemsImproperUseAttrId}>
                {viewModelProperties.restraintSystemsImproperUseAttrId}
            </SummaryRow>
            <SummaryRow label={strings.ejectionAttrId}>
                {viewModelProperties.ejectionAttrId}
            </SummaryRow>
            <SummaryRow label={strings.airbagDeployed}>
                {viewModelProperties.airbagDeployed}
            </SummaryRow>
            <SummaryRow label={strings.driverLicenseJurisdictionAttrId}>
                {viewModelProperties.driverLicenseJurisdictionAttrId}
            </SummaryRow>
            <SummaryRow label={strings.driverLicenseJurisdictionName}>
                {viewModel.driverLicenseJurisdictionName}
            </SummaryRow>
            <SummaryRow label={strings.driverLicenseClassAttrId}>
                {viewModelProperties.driverLicenseClassAttrId}
            </SummaryRow>
            <SummaryRow label={strings.driverLicenseCdlAttrId}>
                {viewModelProperties.driverLicenseCdlAttrId}
            </SummaryRow>
            <SummaryRow label={strings.driverLicenseRestrictions}>
                {viewModelProperties.driverLicenseRestrictions}
            </SummaryRow>
            <FormattedDate
                date={viewModel.dateOfLicenseExpiration}
                format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
            >
                {(formattedDate) => (
                    <SummaryRow label={strings.dateOfLicenseExpiration}>{formattedDate}</SummaryRow>
                )}
            </FormattedDate>
            <SummaryRow label={strings.cmvLicenseStatusAttrId}>
                {viewModelProperties.cmvLicenseStatusAttrId}
            </SummaryRow>
            <SummaryRow label={strings.cmvLicenseComplianceAttrId}>
                {viewModelProperties.cmvLicenseComplianceAttrId}
            </SummaryRow>
            <SummaryRow label={strings.speedingRelatedAttrId}>
                {viewModelProperties.speedingRelatedAttrId}
            </SummaryRow>
            <SummaryRow label={strings.offenseCodes(offenseDisplayName)}>
                {viewModelProperties.offenseCodes}
            </SummaryRow>
            <SummaryRow label={strings.alcoholInterlockPresentAttrId}>
                {viewModelProperties.alcoholInterlockPresentAttrId}
            </SummaryRow>
            <SummaryRow label={strings.distractedByAttrId}>
                {viewModelProperties.distractedByAttrId}
            </SummaryRow>
            <SummaryRow label={strings.distractedBySourceAttrId}>
                {viewModelProperties.distractedBySourceAttrId}
            </SummaryRow>
            <SummaryRow label={strings.conditions}>{viewModelProperties.conditions}</SummaryRow>
            <SummaryRow label={strings.lawEnforcementSuspectsAlcoholUseAttrId}>
                {viewModelProperties.lawEnforcementSuspectsAlcoholUseAttrId}
            </SummaryRow>
            <SummaryRow label={strings.alcoholTestStatusAttrId}>
                {viewModelProperties.alcoholTestStatusAttrId}
            </SummaryRow>
            <SummaryRow label={strings.alcoholTestTypeAttrId}>
                {viewModelProperties.alcoholTestTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.alcoholTestResultAttrId}>
                {viewModelProperties?.alcoholTestResultAttrId}
            </SummaryRow>
            <SummaryRow label={strings.lawEnforcementSuspectsDrugUseAttrId}>
                {viewModelProperties.lawEnforcementSuspectsDrugUseAttrId}
            </SummaryRow>
            <SummaryRow label={strings.drugTestStatusAttrId}>
                {viewModelProperties.drugTestStatusAttrId}
            </SummaryRow>
            <SummaryRow label={strings.drugTestTypeAttrId}>
                {viewModelProperties.drugTestTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.drugTestResult}>
                {viewModelProperties.drugTestResult}
            </SummaryRow>
            <SummaryRow label={strings.medicalTransportSourceAttrId}>
                {viewModelProperties.medicalTransportSourceAttrId}
            </SummaryRow>
            <SummaryRow label={strings.medicalTransportSourceAgencyId}>
                {viewModel.medicalTransportSourceAgencyId}
            </SummaryRow>
            <SummaryRow label={strings.medicalTransportEmsResponseRunNumber}>
                {viewModel.medicalTransportEmsResponseRunNumber}
            </SummaryRow>
            <SummaryRow label={strings.medicalTransportFacility}>
                {viewModel.medicalTransportFacility}
            </SummaryRow>
            <SummaryRow label={strings.injuryDiagnosis}>{viewModel.injuryDiagnosis}</SummaryRow>
            <SummaryRow label={strings.injurySeverityAttrId}>
                {viewModelProperties.injurySeverityAttrId}
            </SummaryRow>
            <SummaryRow label={strings.attemptedAvoidanceManeuverAttrId}>
                {viewModelProperties.attemptedAvoidanceManeuverAttrId}
            </SummaryRow>
            <SummaryRow label={strings.fatalAlcoholTestTypeAttrId}>
                {viewModelProperties.fatalAlcoholTestTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.fatalAlcoholTestResults}>
                {viewModel.fatalAlcoholTestResults}
            </SummaryRow>
            <SummaryRow label={strings.fatalDrugTestTypeAttrId}>
                {viewModelProperties.fatalDrugTestTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.fatalDrugTestResults}>
                {viewModel.fatalDrugTestResults}
            </SummaryRow>
            <SummaryRow label={strings.unitNumOfMotorVehicleStrikingNonMotorist}>
                {viewModel.unitNumOfMotorVehicleStrikingNonMotorist}
            </SummaryRow>
            <SummaryRow label={strings.nonMotoristActionPriorToCrashAttrId}>
                {viewModelProperties.nonMotoristActionPriorToCrashAttrId}
            </SummaryRow>
            <SummaryRow label={strings.nonMotoristOriginOrDestinationAttrId}>
                {viewModelProperties.nonMotoristOriginOrDestinationAttrId}
            </SummaryRow>
            <SummaryRow label={strings.nonMotoristContributingActionsAttrId}>
                {viewModelProperties.nonMotoristContributingActionsAttrId}
            </SummaryRow>
            <SummaryRow label={strings.nonMotoristLocationAtTimeOfCrashAttrId}>
                {viewModelProperties.nonMotoristLocationAtTimeOfCrashAttrId}
            </SummaryRow>
            <SummaryRow label={strings.nonMotoristSafetyEquipment}>
                {viewModelProperties.nonMotoristSafetyEquipment}
            </SummaryRow>
            <SummaryRow label={strings.initialContactPointOnNonMotoristAttrId}>
                {viewModelProperties.initialContactPointOnNonMotoristAttrId}
            </SummaryRow>
            <TrafficCrashEntityDetailSummarySection
                entityId={personId}
                entityType={EntityTypeEnum.PERSON_PROFILE.name}
            />
        </SummaryList>
    );
};

export default TrafficCrashPersonSummary;

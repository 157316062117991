// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_URL = '/evidence/api';
const BASE_PATH = 'chain_of_custody';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Evidence Chain Event Resource',
        methods: {
            /**
             * Create chain events.
             * @param  {Object[]} events
             * @return {Promise<Object>} `chainOfCustodies`, `chainEvents`
             *                              and `itemEvidenceState`.
             */
            createEvents(events) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/events/state/bulk`,
                    data: events,
                });
            },

            /**
             * Delete Custody Event by Id
             * @param  {number} eventId
             * @param  {String}reasonForDeletion
             * @return {Promise<Object[]>}
             */
            deleteChainEventById(eventId, reasonForDeletion) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/events/${eventId}/delete`,
                    data: { reasonForDeletion },
                });
            },

            /**
             * Get the most recent chains of custody and chain events for the
             *   given item ids, which may be master item ids or contexted item
             *   profile ids.
             * @param  {number[]} masterItemIds
             * @return {Promise<Object>} `chainOfCustodies`, `chainEvents`
             *                              and `itemEvidenceState`.
             */
            getCurrentEventsForItems(masterItemIds) {
                return req({
                    method: 'POST',
                    baseUrl: BASE_URL,
                    url: `${BASE_PATH}/properties/current/batch`,
                    data: masterItemIds,
                });
            },
        },
    });
}

/**
 * Resource for chain of custody events, not types. See the tech doc for
 *   details.
 *
 * This is temporarily a function to avoid dependency problems between
 *   /client-common/ and /client/.
 * @type {function}
 * @see
 *   https://docs.google.com/document/d/1ICdtpQkqkFAsgiTFbw8tsfaXP1r-oV2H5DE789HGGMk/
 */
const resourceGetter = () => resource;
export default resourceGetter;

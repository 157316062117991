import { headerHeight, subheaderHeight } from './globalConfig';

/**
 * The height between the top of a given report card element and the top of the
 *   document to offset the card by for the card to be considered appearing at
 *   the top of the page.
 * @type {number}
 */
export const SCROLL_OFFSET = headerHeight + subheaderHeight + 24; // top margin of each card

/**
 * The height between the top of a given report card element and the top of the
 *   document to offset the card by for the scroll spy to consider the card
 *   active. There is a constant number between this and `SCROLL_OFFSET that is
 *   arbitrary and can be adjusted for better UX. The bigger this number, the
 *   earlier the card is considered active, as in you don't have to scroll as
 *   far down.
 * @type {number}
 */
export const SCROLL_SPY_OFFSET = SCROLL_OFFSET + 100;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import Row from '../../../core/components/Row';
import caseReviewForm from '../state/form/caseReviewForm';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { RRFUserSelect } from '../../../core/forms/components/selects/UserSelect';
import { RRFTextArea } from '../../../core/forms/components/TextArea';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { DashedDivider } from '../../../../legacy-redux/components/core/Divider';

import { submitCaseReviewForm } from '../state/data';
import { setIsRejection } from '../state/ui';
import { currentUserCasePermissionsSelector } from '../../core/state/ui';
import testIds from '../../../../core/testIds';

const { withRRFFieldValues } = reactReduxFormHelpers;
const strings = componentStrings.cases.caseReviews.CaseReviewForm;

const _CommentButton = withRRFFieldValues({
    body: 'body',
})(function _CommentButton({ body, onSubmit, className }) {
    // disable submit button unless some text is in the body
    return (
        <Button
            className={`${buttonTypes.PRIMARY} ${className}`}
            onClick={onSubmit}
            disabled={!body}
            testId={testIds.CASE_REVIEW_COMMENT_BUTTON}
        >
            {strings.comment}
        </Button>
    );
});

const CommentButton = styled(_CommentButton)`
    ${(props) =>
        props.canApprove
            ? `
        float: right;
        margin-right: -2px;
    `
            : `
        float: left;
    `};
`;

function CaseReviewForm({
    onApprove,
    onRequestApproval,
    onReject,
    onSubmitComment,
    userCasePermissions,
}) {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    const { canApprove, hasManage } = userCasePermissions;
    const submitButtons = (
        <Row>
            {canApprove && (
                <div>
                    <Button
                        className={buttonTypes.PRIMARY}
                        onClick={onApprove}
                        testId={testIds.CASE_REVIEW_APPROVE_BUTTON}
                    >
                        {strings.approve}
                    </Button>
                    <Button
                        className={buttonTypes.SECONDARY}
                        onClick={onReject}
                        testId={testIds.CASE_REVIEW_REJECT_BUTTON}
                    >
                        {strings.reject}
                    </Button>
                </div>
            )}
            <CommentButton
                className={buttonTypes.PRIMARY}
                onSubmit={onSubmitComment}
                canApprove={canApprove}
            >
                {strings.comment}
            </CommentButton>
        </Row>
    );
    // if you can see the form but you can't approve, you can request approval
    const requestForApprovalButton = !canApprove && (
        <Row className="case-review-request-approval">
            <Button
                className={buttonTypes.PRIMARY}
                onClick={onRequestApproval}
                testId={testIds.CASE_REQUEST_APPROVAL_BUTTON}
            >
                {strings.requestApproval}
            </Button>
        </Row>
    );

    return hasManage ? (
        <div className="case-review-form">
            <ReactReduxForm {...caseReviewForm}>
                <RRFUserSelect
                    path="userIdsToNotify"
                    label={strings.labels.userToNotify}
                    width="100%"
                    multiple={true}
                />
                {requestForApprovalButton}
                <DashedDivider />
                <RRFTextArea
                    path="body"
                    label={strings.labels.body(canApprove, caseDisplayName)}
                    width="100%"
                    rows={6}
                />
                {submitButtons}
            </ReactReduxForm>
        </div>
    ) : (
        <div />
    );
}

const mapStateToProps = createStructuredSelector({
    userCasePermissions: currentUserCasePermissionsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmitComment: () => {
        dispatch(setIsRejection(false));
        dispatch(submitCaseReviewForm());
    },
    onReject: () => {
        // need to know if it's rejection for validation rules
        dispatch(setIsRejection(true));
        dispatch(
            submitCaseReviewForm({
                isRejection: true,
                isApproval: false,
                isRequestForApproval: false,
            })
        );
    },
    onApprove: () => {
        dispatch(setIsRejection(false));
        dispatch(
            submitCaseReviewForm({
                isApproval: true,
                isRejection: false,
                isRequestForApproval: false,
            })
        );
    },
    onRequestApproval: () =>
        dispatch(
            submitCaseReviewForm({
                isApproval: false,
                isRejection: false,
                isRequestForApproval: true,
            })
        ),
    setIsRejection: (isRejection) => dispatch(setIsRejection(isRejection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseReviewForm);

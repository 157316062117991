import { chunk, includes, map, uniq, without } from 'lodash';
import React from 'react';
import { withHandlers } from 'recompose';
import { useBreakpointValue } from 'arc';
import { useScreenBreakpoint } from '../../../utils/useScreenBreakpoint';
import Row from '../../../components/Row';
import Column from '../../../components/Column';
import Checkbox from './Checkbox';

const computeCheckboxesValue = (newCheckboxValue, multiple, option, allCheckedValues = []) => {
    if (newCheckboxValue === true) {
        return multiple ? uniq([...allCheckedValues, option.value]) : [option.value];
    } else {
        return without(allCheckedValues, option.value);
    }
};

const ColumnCheckbox = withHandlers({
    onCheckboxChange({ onCheckboxesChange, multiple, option, allCheckedValues }) {
        return (newValue) => {
            const checkboxesValue = computeCheckboxesValue(
                newValue,
                multiple,
                option,
                allCheckedValues
            );
            onCheckboxesChange(checkboxesValue);
        };
    },
    onCheckboxBlur({ onCheckboxesBlur, multiple, option, allCheckedValues }) {
        return (newValue) => {
            const checkboxesValue = computeCheckboxesValue(
                newValue,
                multiple,
                option,
                allCheckedValues
            );
            onCheckboxesBlur(checkboxesValue);
        };
    },
})(function ColumnCheckbox({
    width,
    option,
    allCheckedValues,
    onCheckboxChange,
    onFocus,
    onCheckboxBlur,
    error,
    fieldName,
    disabled, // are all checkboxes disabled ?
}) {
    return (
        <Checkbox
            width={width}
            label={option.display}
            attrIsOther={option.other}
            noteDisplay={option.noteDisplay}
            value={includes(allCheckedValues, option.value)}
            disabled={!!disabled || !!option.disabled}
            onChange={onCheckboxChange}
            onFocus={onFocus}
            onBlur={onCheckboxBlur}
            error={error}
            fieldName={fieldName}
        />
    );
});

/**
 * Checkboxes that appear in columns. The checkboxes appear in the provided
 *   order from left to right then top to bottom.
 * @param {number}   props.columns
 * @param {number}   props.columnWidth
 * @param {number}   [props.gutterWidth=20] Spacing between columns.
 * @param {Object[]} props.options
 * @param {boolean}  props.options.disabled  disables an individual option.
 * @param {Array}    props.value
 * @param {function} props.onChange
 * @param {boolean}  [props.multiple=true]     allow multiple boxes to be checked
 * @param {boolean}  [props.disabled] disables all checkboxes
 */
function CheckboxColumns({
    columns,
    columnWidth,
    multiple = true,
    gutterWidth = 20,
    options,
    value,
    onChange,
    onFocus,
    onBlur,
    fieldName,
    disabled,
}) {
    const rows = chunk(options, columns);

    const computeColumnWidth = useBreakpointValue({
        base: '100%',
        sm: columnWidth,
    });
    const { isMobileSm } = useScreenBreakpoint();

    return (
        <div className="checkbox-columns">
            {map(rows, (options, rowIndex) => (
                <Row key={rowIndex}>
                    {map(options, (option, columnIndex) => (
                        <Column
                            key={columnIndex}
                            width={computeColumnWidth}
                            marginRight={
                                isMobileSm ? 0 : columnIndex < columns - 1 ? gutterWidth : 0
                            }
                        >
                            <ColumnCheckbox
                                width="100%"
                                option={option}
                                allCheckedValues={value}
                                multiple={multiple}
                                onCheckboxesChange={onChange}
                                onFocus={onFocus}
                                onCheckboxesBlur={onBlur}
                                fieldName={fieldName}
                                disabled={disabled}
                            />
                        </Column>
                    ))}
                </Row>
            ))}
        </div>
    );
}

export default CheckboxColumns;

import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { renderOnlyIf } from '../../../legacy-redux/helpers/reactHelpers';
import zIndexes from '../styles/zIndexes';

const ActionBarContainer = styled.div`
    box-sizing: border-box;
    position: ${(props) => props.position || 'sticky'};
    z-index: ${zIndexes.actionBar};
    bottom: 0;
    width: 100%;
    border-top: 1px solid ${(props) => props.theme.colors.darkBlue};
    height: ${(props) => props.theme.heights.actionBar}px;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    color: ${(props) => props.theme.colors.darkGrey};
    font-size: var(--arc-fontSizes-md);
    box-shadow: 0 1px 12px ${(props) => props.theme.colors.mediumLightGrey};
`;

const BlueActionBarContainer = styled(ActionBarContainer)`
    border-top: 0;
    background-color: ${(props) => props.theme.colors.brightBlue};
    color: ${(props) => props.theme.colors.white};
`;

const ActionBarContent = styled.div`
    height: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 4px 20px;
    width: ${(props) => props.theme.widths.container}px;
    display: flex;
`;

/**
 * Action bar fixed to the bottom of the screen.
 */
export const ActionBarBase = renderOnlyIf(({ visible }) => visible)(function ActionBarBase({
    children,
    position,
    style,
}) {
    return (
        <ActionBarContainer style={style} position={position}>
            {children}
        </ActionBarContainer>
    );
});

export default function ActionBar({ children, position, visible, className }) {
    return (
        <ActionBarBase position={position} visible={visible}>
            <ActionBarContent className={classNames('clearFix', className)}>
                {children}
            </ActionBarContent>
        </ActionBarBase>
    );
}

/**
 * Blue action bar fixed to the bottom of the screen.
 */
export const BlueActionBar = renderOnlyIf(({ visible }) => visible)(function BlueActionBar({
    className,
    children,
}) {
    return (
        <BlueActionBarContainer>
            <ActionBarContent className={classNames('clearfix', className)}>
                {children}
            </ActionBarContent>
        </BlueActionBarContainer>
    );
});

import { DeliveryMethodEnum } from '@mark43/notifications-api';

import { reduce, each, map } from 'lodash';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';

import { createFormModule } from '../../../../core/forms';
import {
    notificationSettingByIndex,
    settingOptedOut,
} from '../../utils/notificationSettingsHelpers';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { N_FIELDSETS, N_ITEMS } = fieldTypeClientEnum;

const fieldViewModels = {
    type: N_FIELDSETS,
    key: 'categories',
    fields: {
        type: N_ITEMS,
        key: 'types',
        fields: buildFlatFormFieldViewModels([
            DeliveryMethodEnum.WEB_APP.name,
            DeliveryMethodEnum.EMAIL.name,
        ]),
    },
};

export function convertToFormModel(optOuts, config) {
    return {
        categories: map(config, ({ notificationTypes }) => ({
            types: map(notificationTypes, ({ name, webDelivery, emailDelivery }) => ({
                WEB_APP:
                    webDelivery && !settingOptedOut(name, optOuts, DeliveryMethodEnum.WEB_APP.name),
                EMAIL:
                    emailDelivery && !settingOptedOut(name, optOuts, DeliveryMethodEnum.EMAIL.name),
            })),
        })),
    };
}

export function convertFromFormModel({ categories }, currentUserId, config) {
    return reduce(
        categories,
        (result, { types }, categoryIndex) => {
            each(types, (deliveryMethods, settingIndex) => {
                each(deliveryMethods, (checkboxValue, deliveryMethod) => {
                    if (!checkboxValue) {
                        const notificationType = notificationSettingByIndex(
                            categoryIndex,
                            settingIndex,
                            config
                        ).name;

                        result.push({
                            notificationType,
                            deliveryMethod,
                            userId: currentUserId,
                        });
                    }
                });
            });
            return result;
        },
        []
    );
}

const notificationSettingsForm = createFormModule({
    formName: formClientEnum.NOTIFICATION_SETTINGS_FORM,
    fieldViewModels,
    convertToFormModel,
    convertFromFormModel,
});

export default notificationSettingsForm;

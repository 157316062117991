import React from 'react';
import { compose } from 'redux';

import createReactReduxFormHelpers from '~/client-common/helpers/createReactReduxFormHelpers';
import featureFlagged from '~/client-common/core/domain/settings/utils/featureFlagged';

import { runSingleFieldValidationRRF } from '../../modules/core/validation';
import { useStaticFieldRequired } from '../../modules/core/forms/hooks/useStaticFieldRequired';

function rrfStaticRequired(Component) {
    function StaticRequired(props) {
        const { context, disabled, fieldConfiguration, value } = props;
        const isStaticRequired = useStaticFieldRequired({
            context,
            disabled,
            value,
            fieldConfigurationId: fieldConfiguration?.id,
        });

        return <Component {...props} isRequired={isStaticRequired} />;
    }

    return featureFlagged(
        'RMS_REPORT_WRITING_SHOW_REQUIRED_FIELDS_ENABLED',
        Component
    )(StaticRequired);
}

const reactReduxFormHelpers = createReactReduxFormHelpers({
    baseFormPath: 'formModels',
    validationRunnerAction: runSingleFieldValidationRRF,
});

const connectRRFInput = compose(reactReduxFormHelpers.connectRRFInput, rrfStaticRequired);

export default {
    ...reactReduxFormHelpers,
    connectRRFInput,
};

import _, { map, omit, uniq, first } from 'lodash';

import { storeOffenses } from '~/client-common/core/domain/offenses/state/data';
import { codesSelector } from '~/client-common/core/domain/codes/state/data';
import { ucrSummaryOffenseCodeByCodeSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/data';
import {
    reportUcrsByReportIdSelector,
    storeReportUcrs,
} from '~/client-common/core/domain/report-ucrs/state/data';
import getOffensesResource from '~/client-common/core/domain/offenses/resources/offensesResource';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_OFFENSE } from '~/client-common/core/enums/universal/fields';

import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import {
    currentReportEventIdSelector,
    currentReportIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import {
    closeBox,
    openBox,
    saveBoxSuccess,
    saveBoxFailure,
    loadBoxStart,
    saveBoxHalt,
} from '../../../../../legacy-redux/actions/boxActions';
import ucrClassificationForm from '../forms/ucrClassificationForm';
import arrestUcrClassificationForm from '../forms/arrestUcrClassificationForm';
import getResource from '../../resources/ucrClassificationResource';
import { offenseUcrForReportIdSelector, currentUcrSourceSelector } from './selectors';

const strings = componentStrings.reports.UcrClassificationSidePanel;
const context = { name: boxEnum.UCR_CLASSIFICATION_SIDE_PANEL };
const arrestContext = { name: boxEnum.ARREST_UCR_CLASSIFICATION_SIDE_PANEL };

export function openArrestUcrClassificationSidePanel() {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(openBox(arrestContext));

        const currentReportId = currentReportIdSelector(state);
        const offenseUcr = offenseUcrForReportIdSelector(state)(currentReportId);
        const arrestUcr = first(reportUcrsByReportIdSelector(state)(currentReportId));

        dispatch(
            arrestUcrClassificationForm.actionCreators.change({
                offenseUcr,
                arrestUcr,
            })
        );
    };
}

export function saveArrestUcrClassificationSidePanel() {
    return (dispatch, getState) => {
        const state = getState();
        const dispatchSaveArrestUcrClassificationSidePanelFailure = (err) =>
            dispatch(saveBoxFailure(arrestContext, err.message));
        dispatch(
            arrestUcrClassificationForm.actionCreators.submit((formModel) => {
                const currentReportId = currentReportIdSelector(state);
                const currentDepartmentId = currentUserDepartmentIdSelector(state);
                const payload = {
                    ucrCodeCode: formModel.arrestUcr,
                    reportId: currentReportId,
                    departmentId: currentDepartmentId,
                };
                return getResource()
                    .replaceArrestUcrProperty(payload)
                    .then((data) => {
                        dispatch(storeReportUcrs(data));
                        dispatch(saveBoxSuccess(arrestContext));
                        dispatch(closeArrestUcrClassificationSidePanel());
                    })
                    .catch(dispatchSaveArrestUcrClassificationSidePanelFailure);
            })
        ).catch(dispatchSaveArrestUcrClassificationSidePanelFailure);
    };
}

export function closeArrestUcrClassificationSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(arrestContext));
        dispatch(arrestUcrClassificationForm.actionCreators.reset());
    };
}

export function openUcrClassificationSidePanel() {
    return (dispatch, getState) => {
        dispatch(openBox(context));
        dispatch(loadBoxStart(context));

        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        const currentReportEventId = currentReportEventIdSelector(state);

        const offensesResource = getOffensesResource();
        const resource = getResource();
        return Promise.all([
            resource.getUcrBundle(currentReportId),
            offensesResource.getOffensesForEventId(currentReportEventId),
        ])
            .then(
                ([
                    {
                        ucrEvent,
                        ucrEventAttributes,
                        ucrOffenses,
                        ucrProperties,
                        ucrOffenseOffenseLinks,
                    },
                    offensesForEventId,
                ]) => {
                    dispatch(storeOffenses(offensesForEventId));
                    dispatch(
                        ucrClassificationForm.actionCreators.change({
                            ucrEvent,
                            ucrOffenses,
                            ucrProperty: ucrProperties,
                            ucrOffenseCharges: ucrOffenseOffenseLinks,
                            ucrEventAttributes,
                        })
                    );
                    dispatch(saveBoxHalt(context));
                }
            )
            .catch(() => dispatch(saveBoxFailure(context, [strings.loadUcrClassificationsError])));
    };
}

export function prefillUcrClassification() {
    return (dispatch, getState) => {
        dispatch(loadBoxStart(context));
        const state = getState();
        const eventId = currentReportEventIdSelector(state);
        return getResource()
            .prefillUcrClassification(eventId)
            .then(({ ucrEvent, ucrOffenses, ucrProperties }) => {
                dispatch(
                    ucrClassificationForm.actionCreators.change({
                        ucrEvent,
                        ucrOffenses,
                        ucrProperty: ucrProperties,
                    })
                );
                dispatch(saveBoxHalt(context));
            })
            .catch(() => dispatch(saveBoxFailure(context, [strings.loadUcrClassificationsError])));
    };
}

export function prefillUcrOffense(index, offense) {
    return (dispatch, getState) => {
        dispatch(loadBoxStart(context));
        const state = getState();
        const offenseDisplayName = formatFieldByNameSelector(state)(DISPLAY_ONLY_OFFENSE);
        const ucrOffenseFormState = ucrClassificationForm.selectors.formModelByPathSelector(state)(
            `ucrOffenses[${index}]`
        );
        return getResource()
            .prefillUcrOffenses([offense.id])
            .then(([ucrOffense]) => {
                dispatch(
                    ucrClassificationForm.actionCreators.changePath(
                        `ucrOffenses[${index}]`,
                        {
                            ...ucrOffenseFormState,
                            ...omit(ucrOffense, 'id'),
                        },
                        true /* shouldResetFormUi */
                    )
                );
                dispatch(saveBoxHalt(context));
            })
            .catch(() =>
                dispatch(saveBoxFailure(context, [strings.loadUcrOffenseError(offenseDisplayName)]))
            );
    };
}

export function prefillUcrProperty(index, propertyStatusId) {
    return (dispatch, getState) => {
        dispatch(loadBoxStart(context));
        const state = getState();
        const ucrPropertyFormState = ucrClassificationForm.selectors.formModelByPathSelector(state)(
            `ucrProperty[${index}]`
        );
        return getResource()
            .prefillUcrProperty([propertyStatusId])
            .then(([ucrProperty]) => {
                dispatch(
                    ucrClassificationForm.actionCreators.changePath(
                        `ucrProperty[${index}]`,
                        {
                            ...ucrPropertyFormState,
                            ...omit(ucrProperty, 'id'),
                        },
                        true /* shouldResetFormUi */
                    )
                );
                dispatch(saveBoxHalt(context));
            })
            .catch(() => dispatch(saveBoxFailure(context, [strings.loadUcrPropertyError])));
    };
}

export function saveUcrClassification() {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        const currentUcrSource = currentUcrSourceSelector(state);
        const ucrCodesByCode = ucrSummaryOffenseCodeByCodeSelector(state);
        const codes = codesSelector(state);

        return dispatch(
            ucrClassificationForm.actionCreators.submit((formModel) => {
                const {
                    ucrEvent,
                    ucrOffenses,
                    ucrProperty,
                    ucrOffenseCharges,
                    ucrEventAttributes,
                } = ucrClassificationForm.convertFromFormModel(
                    formModel,
                    currentUcrSource,
                    ucrCodesByCode,
                    codes
                );
                const resource = getResource();
                return Promise.all([
                    resource.replaceUcrBundle(currentReportId, {
                        ucrEvent,
                        ucrEventAttributes,
                        ucrOffenses,
                        ucrProperties: ucrProperty,
                    }),
                ])
                    .then(([{ ucrEvent, ucrEventAttributes, ucrOffenses, ucrProperties }]) => {
                        const action = { type: 'UCR_SAVE' };
                        const removeEvents = dependencies.nexus.withRemove('ucrEvents', {}, action);
                        const removeOffenses = dependencies.nexus.withRemove(
                            'ucrOffenses',
                            {},
                            removeEvents
                        );
                        const removeProperty = dependencies.nexus.withRemove(
                            'ucrProperty',
                            {},
                            removeOffenses
                        );
                        dispatch(
                            dependencies.nexus.withEntityItems(
                                {
                                    ucrEvents: [ucrEvent],
                                    ucrOffenses,
                                    ucrProperty,
                                },
                                removeProperty
                            )
                        );

                        const ucrOffensesBySequenceNumber = _(ucrOffenses)
                            .groupBy('sequenceNumber')
                            .mapValues(
                                (ucrOffensesForSequenceNumber) => ucrOffensesForSequenceNumber[0]
                            )
                            .value();

                        // reportId is pretty meaningless here, we really want REN-based data.
                        // Use ucrEvent.reportId so that all UCR data is attached to the same report.
                        const replaceCharges = resource.replaceUcrOffenseCharges(
                            ucrEvent.reportId,
                            map(ucrOffenseCharges, (ucrOffenseCharge) => ({
                                ...ucrOffenseCharge,
                                reportId: ucrEvent.reportId,
                                ucrOffenseId:
                                    ucrOffensesBySequenceNumber[
                                        ucrOffenseCharge.offenseSequenceNumber
                                    ].id,
                            }))
                        );
                        return Promise.all([
                            ucrEvent,
                            ucrOffenses,
                            ucrProperties,
                            replaceCharges,
                            ucrEventAttributes,
                        ]);
                    })
                    .then(
                        ([
                            ucrEvent,
                            ucrOffenses,
                            ucrProperties,
                            ucrOffenseCharges,
                            ucrEventAttributes,
                        ]) => {
                            dispatch(
                                ucrClassificationForm.actionCreators.change({
                                    ucrEvent,
                                    ucrOffenses,
                                    ucrProperty: ucrProperties,
                                    ucrOffenseCharges,
                                    ucrEventAttributes,
                                })
                            );
                            dispatch(saveBoxSuccess(context));
                        }
                    );
            })
        ).catch((errors) => {
            dispatch(saveBoxFailure(context, uniq(errors)));
        });
    };
}

import {
    ComplianceErrorSummary,
    ComplianceExportTypeEnum,
    ComplianceExportTypeEnumType,
    NibrsErrorSummary,
    UserExport,
} from '@mark43/rms-api';
import Promise from 'bluebird';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { NIBRS_COMPLIANCE_TYPE, COMPLIANCE_POLL_INTERVAL_MS } from '../configuration';
import { ComplianceExportType, SupportedComplianceExportType } from '../types';

const strings = componentStrings.compliance.ComplianceExport;

function isComplianceExportType(type: string): type is ComplianceExportTypeEnumType {
    return type in ComplianceExportTypeEnum;
}

/**
 * NIBRS_COMPLIANCE_TYPE is not in ComplianceExportTypeEnum. This gives us a convenient API to get
 *   compliance type.
 */
export function getComplianceExportTypeEnum(
    complianceExportType: SupportedComplianceExportType
): ComplianceExportType;
// Overload when complianceExportType is from params string
export function getComplianceExportTypeEnum(
    complianceExportType: string
): ComplianceExportType | undefined;

export function getComplianceExportTypeEnum(complianceExportType: string) {
    if (complianceExportType === NIBRS_COMPLIANCE_TYPE.name) {
        return NIBRS_COMPLIANCE_TYPE;
    }

    if (isComplianceExportType(complianceExportType)) {
        return ComplianceExportTypeEnum[complianceExportType];
    }
    return undefined;
}

type PollForExportIds = (exportIds: string[]) => Promise<UserExport[]>;
type GetExportErrors = (exportId: string) => Promise<ComplianceErrorSummary | NibrsErrorSummary>;
type Poller = (exportIds: string[]) => Promise<ComplianceErrorSummary | NibrsErrorSummary>;

export function createPoller(
    pollForExportIds: PollForExportIds,
    getExportErrors: GetExportErrors
): Poller {
    let shouldRetry = false;
    const pollForErrorResults: Poller = (exportIds) => {
        return Promise.delay(COMPLIANCE_POLL_INTERVAL_MS)
            .then(() => pollForExportIds(exportIds))
            .then((result) => {
                const [pendingExport] = result;
                if (!pendingExport || pendingExport.failedPrint) {
                    // we want to retry only if `pendingExport` is 'undefined'
                    // we do not want to retry if `pendingExport.failedPrint` is 'true'
                    shouldRetry = !pendingExport;

                    throw new Error(strings.serverError);
                }
                if (!pendingExport.file) {
                    return pollForErrorResults(exportIds);
                } else {
                    return getExportErrors(exportIds[0]);
                }
            })
            .catch((e) => (shouldRetry ? pollForErrorResults(exportIds) : Promise.reject(e)));
    };
    return pollForErrorResults;
}

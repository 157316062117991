/**
 * Special select component to handle new 'No Assignee' option for user
 * See RMS-7869
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, defaultProps, setPropTypes, setDisplayName } from 'recompose';
import { createStructuredSelector } from 'reselect';
import keyMirror from 'keymirror';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { roleOptionsByTypesSelector } from '~/client-common/core/domain/roles/state/data';
import { DISPLAY_CASE_ASSIGNEE } from '~/client-common/core/enums/universal/fields';
import withFields from '~/client-common/core/fields/components/withFields';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import RoleSelect from './RoleSelect';
import { multiFieldSelectFactory } from './MultiFieldSelect';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.cases;

/*
 * We need RoleSelect to support multiple paths (hasAssignee and assigneeRoleIds) despite it being async
 * This is to support 'No Assignee' option (See RMS-7869)
 * We get around this by wrapping RoleSelect with multiFieldSelectFactory HOC
 */

const MultiFieldRoleSelect = multiFieldSelectFactory(RoleSelect);

const CaseAssigneeSelect = compose(
    connect(
        createStructuredSelector({
            roleOptionsByTypes: roleOptionsByTypesSelector,
        })
    ),
    withFields([DISPLAY_CASE_ASSIGNEE])
)(function CaseAssigneeSelect({
    fields: { hasAssignee, assigneeRoleIds },
    roleOptionsByTypes,
    roleTypes,
    fieldDisplayNames,
    ...otherProps
}) {
    return (
        <MultiFieldRoleSelect
            {...otherProps}
            fields={{
                hasAssignee: {
                    options: [
                        {
                            value: false,
                            display: strings.allCases.AllCasesSearchPersonnelFieldset.labels.noAssignee(
                                fieldDisplayNames.DISPLAY_CASE_ASSIGNEE
                            ),
                            none: true,
                        },
                    ],
                    ...hasAssignee,
                },
                assigneeRoleIds: {
                    options: roleOptionsByTypes(roleTypes),
                    ...assigneeRoleIds,
                },
            }}
        />
    );
});

export const RRFCaseAssigneeSelect = compose(
    setDisplayName('RRFCaseAssigneeSelect'),
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            hasAssignee: null,
            assigneeRoleIds: null,
        }),
    }),
    connectRRFMultiFieldInput
)(CaseAssigneeSelect);

import { NameEmail } from '@mark43/rms-api';

import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'nameEmails';
const nameEmailsModule = createGroupedModule<NameEmail>({
    type: NEXUS_STATE_PROP,
    key: 'nameId',
});

// SELECTORS
export const nameEmailsSelector = nameEmailsModule.selectors.entitiesSelector;
export const nameEmailsByNameIdSelector = nameEmailsModule.selectors.entitiesByKeySelector;

// REDUCER
export default nameEmailsModule.reducerConfig;

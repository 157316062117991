import React from 'react';
import styled from 'styled-components';
import { last, map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import Icon, { iconTypes, iconSizes } from '../../components/Icon';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { pollingFileSecurityScannerRemoveFiles } from '../../../attachments/core/state/ui/securityScanning';
import Upload from './Upload';

const ImageUploadContainer = styled.div`
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border: 1px solid ${(props) => props.theme.colors.brightBlue};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
`;

const UploadContainer = styled(Upload)`
    display: inline-block;
`;

const EditIcon = styled.div`
    display: none;
    position: absolute;
    padding: 4px;
    top: 0;
    left: 0;
    background-color: var(--arc-colors-background-overlayShim);

    ${/* sc-selector */ ImageUploadContainer}:hover & {
        ${(props) => props.editable && `display: block;`};
    }
`;

const EmptyIcon = styled(Icon)`
    margin-left: 30px;
    margin-right: 30px;
`;

export const UploadError = styled.div`
    color: ${(props) => props.theme.colors.red};
    font-style: italic;
`;

const LoadingContainer = styled.div`
    background-color: var(--arc-colors-surface-accent);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileId: 0,
            imageUrl: props.imageUrl,
            loading: false,
            images: props.images || [],
            uploadSuccessful: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.imageUrl !== nextProps.imageUrl) {
            this.setState({ imageUrl: nextProps.imageUrl });
        }
        // Don't reset the image if there was an error
        // This is not used when file scanning is on becuase it resets the images on the person side panel
        if (
            this.props.images !== nextProps.images &&
            !this.state.error &&
            !this.props.applicationSettings.RMS_FEDRAMP_FILE_UPLOAD_STATUSES_ENABLED
        ) {
            this.setState({ images: nextProps.images });
        }
    }

    onUploadStart = (rawFiles) => {
        if (this.state.loading) {
            return;
        }
        this.setState({
            error: null,
            loading: true,
            imageUrl: last(rawFiles).preview,
            images: rawFiles,
            uploadSuccessful: false,
        });
        if (this.props.onStart) {
            this.props.onStart(this.props.multiple ? rawFiles : last(rawFiles));
        }
    };

    onUploading = (fileUploadIntermediates) => {
        this.setState({
            fileId: last(fileUploadIntermediates).file.id,
        });
    };

    onUploadSuccess = (files) => {
        this.setState({
            loading: false,
            file: last(files).file.id,
            imageUrl: last(files).file.fileWebServerPath,
            // Map the file back to the format of a dataNexus image
            images: map(files, ({ image, file }) => {
                const attachment = image || file; // Rm when BE is done
                return {
                    ...attachment,
                    originalFile: file,
                };
            }),
            uploadSuccessful: true,
        });
        if (this.props.onSuccess) {
            const attachments = this.props.multiple ? files : last(files);
            this.props.onSuccess(attachments);
        }
    };

    onUploadError = (errorMessage, erroredFiles) => {
        this.setState({
            loading: false,
            error: errorMessage,
            imageUrl: null,
            images: erroredFiles,
            uploadSuccessful: false,
        });
        if (this.props.onError) {
            this.props.onError(errorMessage, erroredFiles);
        }
    };

    onAllBatchesComplete = () => {
        if (this.props.onAllBatchesComplete) {
            this.props.onAllBatchesComplete();
        }
    };

    clearState = () => {
        this.setState({
            uploadSuccessful: false,
            imageUrl: null,
            loading: false,
            error: null,
            images: [],
            fileId: 0,
        });
    };

    onRemove = () => {
        this.clearState();
        this.props.onRemove();
        if (this.props.applicationSettings.RMS_FEDRAMP_FILE_UPLOAD_STATUSES_ENABLED) {
            this.props.onHandleRemove(this.state.fileId);
        }
    };

    onRemoveThumbnail = () => {
        // this is used to clear ImageUpload state when a user
        // adds a photo, but doesn't save it and then clicks on
        // a new profile (e.g., agency profiles)
        this.clearState();
    };

    render() {
        const imageUploadApi = {
            onUploadStart: this.onUploadStart,
            onUploading: this.onUploading,
            onUploadSuccess: this.onUploadSuccess,
            onUploadError: this.onUploadError,
            onAllBatchesComplete: this.onAllBatchesComplete,
            onFileInfected: this.props.onRemove ? this.onRemove : undefined,
            onRemove: this.props.onRemove ? this.onRemove : undefined,
            imageUrl: this.state.imageUrl,
            loading: this.state.loading,
            error: this.state.error,
            images: this.state.images,
            uploadSuccessful: this.state.uploadSuccessful,
        };

        const childrenProps = {
            imageUploadApi,
            className: this.props.className,
            width: this.props.width,
            height: this.props.height,
            multiple: this.props.multiple,
        };

        return this.props.children ? (
            this.props.children(childrenProps)
        ) : (
            <DefaultImageUploadComponent {...childrenProps} />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onHandleRemove: (id) => dispatch(pollingFileSecurityScannerRemoveFiles([id])),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);

export const ImageContainer = ({ imageUrl, height, width, empty, loading }) => {
    return (
        <ImageUploadContainer height={height} width={width}>
            {!empty && (
                <ImgWithExifOrientation src={imageUrl} zIndex={0} isBackgroundImage={true} />
            )}
            <EditIcon editable={!empty}>
                <Icon
                    color="var(--arc-colors-raw-whiteAlpha-900)"
                    type={iconTypes.EDIT}
                    size={iconSizes.LARGE}
                />
            </EditIcon>
            {empty && (
                <EmptyIcon color="brightBlue" type={iconTypes.CAMERA} size={iconSizes.XLARGE} />
            )}
            {loading && (
                <LoadingContainer>
                    <div className="loading-whitebg" />
                </LoadingContainer>
            )}
        </ImageUploadContainer>
    );
};

function DefaultImageUploadComponent({
    imageUploadApi: {
        onUploadStart,
        onUploading,
        onUploadSuccess,
        onUploadError,
        onAllBatchesComplete,
        onRemove,
        imageUrl,
        loading,
        error,
    },
    className,
    width,
    height,
    multiple,
}) {
    const empty = !imageUrl;
    return (
        <Container className={className}>
            <UploadContainer
                multiple={multiple || false}
                onStart={onUploadStart}
                onUploading={onUploading}
                onSuccess={onUploadSuccess}
                onError={onUploadError}
                onFileInfected={onRemove}
                onAllBatchesComplete={onAllBatchesComplete}
            >
                <ImageContainer
                    imageUrl={imageUrl}
                    height={height}
                    width={width}
                    empty={empty}
                    loading={loading}
                />
            </UploadContainer>
            {onRemove && !empty && !loading && (
                <Button className={buttonTypes.ICON_LINK} onClick={onRemove}>
                    <Icon type={iconTypes.TRASH_CAN} color="cobaltBlue" />
                    Remove
                </Button>
            )}
            {error && <UploadError>{error}</UploadError>}
        </Container>
    );
}

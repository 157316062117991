import React from 'react';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { buttonTypes } from '../../../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../../core/components/Icon';
import testIds from '../../../../../../core/testIds';
import { Button as ArcButton } from '../../../../../core/components/Button';
import { VehicleSuggestionPopover } from '../../entity-prefill/VehicleSuggestionPopover';
import { AddButton } from './Button';
import { NavigationRow } from './Row';

const Navigation = ({ isVehicle, limitToOne, onAddClickHandler, summaryMode }) => {
    const {
        addEditPropertyButton,
        addVehicleButton,
        addEditVehicleButton,
    } = componentStrings.reports.core.ItemCard;

    return (
        !summaryMode && (
            <NavigationRow>
                <FeatureFlagged
                    flag="RMS_CAD_DATA_ENTITY_PREFILL_ENABLED"
                    fallback={
                        <FeatureFlagged
                            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                            fallback={
                                <AddButton
                                    className={buttonTypes.SECONDARY}
                                    onClick={onAddClickHandler}
                                    testId={testIds.ITEM_CARD_MANAGE_ITEMS_BUTTON}
                                >
                                    <Icon type={iconTypes.ADD} />
                                    <div style={{ marginLeft: '10px' }}>
                                        {isVehicle
                                            ? addEditVehicleButton(!limitToOne)
                                            : addEditPropertyButton}
                                    </div>
                                </AddButton>
                            }
                        >
                            <ArcButton
                                variant="outline"
                                leftIcon="Add"
                                onClick={onAddClickHandler}
                                testId={testIds.ITEM_CARD_MANAGE_ITEMS_BUTTON}
                            >
                                {isVehicle
                                    ? addEditVehicleButton(!limitToOne)
                                    : addEditPropertyButton}
                            </ArcButton>
                        </FeatureFlagged>
                    }
                >
                    {isVehicle ? (
                        <VehicleSuggestionPopover buttonLabel={addVehicleButton} />
                    ) : (
                        <FeatureFlagged
                            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                            fallback={
                                <AddButton
                                    className={buttonTypes.SECONDARY}
                                    onClick={onAddClickHandler}
                                    testId={testIds.ITEM_CARD_MANAGE_ITEMS_BUTTON}
                                >
                                    <Icon type={iconTypes.ADD} />
                                    <div style={{ marginLeft: '10px' }}>
                                        {addEditPropertyButton}
                                    </div>
                                </AddButton>
                            }
                        >
                            <ArcButton
                                variant="outline"
                                leftIcon="Add"
                                onClick={onAddClickHandler}
                                testId={testIds.ITEM_CARD_MANAGE_ITEMS_BUTTON}
                            >
                                {addEditPropertyButton}
                            </ArcButton>
                        </FeatureFlagged>
                    )}
                </FeatureFlagged>
            </NavigationRow>
        )
    );
};

export default Navigation;

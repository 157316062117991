import { createSelector } from 'reselect';

import { Attachment } from '@mark43/rms-api';
import { attachmentFolderPrintablesSelector } from '~/client-common/core/domain/attachment-folder-printables/state/data';
import { foldersSelector } from '~/client-common/core/domain/folders/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import {
    ExportableFolder,
    groupAttachmentsByEntityId,
    groupAttachmentsByEntityIdWithFolders,
} from '../../utils/groupAttachmentsByEntityId';
import type { PacketOption } from '../../utils/exportHelpers';

/**
 * Use this selector instead of the base helper function `groupAttachmentsByEntityId` when the export feature involves
 * attachment folders.
 */
export const groupAttachmentsByEntityIdSelector = createSelector(
    attachmentFolderPrintablesSelector,
    foldersSelector,
    applicationSettingsSelector,
    (attachmentFolderPrintables, folders, applicationSettings) => ({
        entities,
        attachments,
    }: {
        entities: PacketOption[];
        attachments: Attachment[];
    }): Record<number, (Attachment | ExportableFolder)[]> =>
        applicationSettings.RMS_CASE_FOLDERING_ENABLED
            ? groupAttachmentsByEntityIdWithFolders({
                  entities,
                  attachments,
                  attachmentFolderPrintables,
                  folders,
              })
            : groupAttachmentsByEntityId({
                  entities,
                  attachments,
              })
);

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { latestChainOfCustodiesForMasterItemIdsSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { itemEvidenceStateByMasterItemIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import { elasticReportByIdSelector } from '~/client-common/core/domain/elastic-reports/state/data';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';

import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Link from '../../../core/components/links/Link';
import NoticeBar from '../../../core/components/NoticeBar';

const strings = componentStrings.evidence.disposition.DispositionNoticeBar;

// The standard button has a few too many strong opinions on layout
const Button = styled(_Button)`
    display: inline-block;
    margin: 0;
    float: none;
    text-transform: uppercase;
`;

const DispositionNoticeBarText = styled.div`
    width: 75%;
    display: inline-block;
`;

const DispositionNoticeBarButtonContainer = styled.div`
    width: 25%;
    display: inline-block;
    text-align: right;
`;

function DispositionActionBar({
    latestChainOfCustodiesForMasterItemIds,
    masterItemId,
    elasticReportById,
    formatFieldByName,
}) {
    const chainOfCustodies = latestChainOfCustodiesForMasterItemIds([masterItemId]);
    const custodialReportId = _.get(chainOfCustodies, '[0].reportId');
    const reportingEventNumber = _.get(
        elasticReportById(custodialReportId),
        'reportingEventNumber'
    );
    return (
        <NoticeBar>
            <DispositionNoticeBarText>
                {strings.readyForApproval}{' '}
                {`${formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)} ${reportingEventNumber}`}
            </DispositionNoticeBarText>
            <DispositionNoticeBarButtonContainer>
                <Link
                    onClick={(event) => event.stopPropagation()}
                    to={`reports/${custodialReportId}`}
                >
                    <Button className={buttonTypes.PRIMARY}>{strings.reviewReport}</Button>
                </Link>
            </DispositionNoticeBarButtonContainer>
        </NoticeBar>
    );
}

const mapStateToProps = createStructuredSelector({
    latestChainOfCustodiesForMasterItemIds: latestChainOfCustodiesForMasterItemIdsSelector,
    elasticReportById: elasticReportByIdSelector,
    itemEvidenceStateByMasterItemId: itemEvidenceStateByMasterItemIdSelector,
    formatFieldByName: formatFieldByNameSelector,
});

export default compose(
    connect(mapStateToProps),
    renderOnlyIf(({ itemEvidenceStateByMasterItemId, masterItemId }) => {
        const actions = itemEvidenceStateByMasterItemId(masterItemId);
        if (!actions) {
            return false;
        }
        return (
            actions.canPrimaryReview ||
            actions.canSecondaryReviewForDisposition ||
            actions.canSecondaryReviewForDisposition
        );
    })
)(DispositionActionBar);

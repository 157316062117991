import {
    DispositionApprovalLevelEnum,
    DispositionEventTypeEnum,
    DispositionStatusEnum,
    OperationTypeEnum,
} from '@mark43/evidence-api';
import { chain, get, noop, keyBy, first } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import { dispositionEventsByChainOfCustodyIdSelector } from '~/client-common/core/domain/disposition-events/state/data';
import { itemEvidenceStateByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { userRolesSelector } from '~/client-common/core/domain/user-roles/state/data';
import { evidenceLocationPermissionsWhereSelector } from '~/client-common/core/domain/evidence-location-permissions/state/data';
import { chainEventViewModelsForChainOfCustodyIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { chainOfCustodiesSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import PendingCard from '../../../core/components/report-status-comments/PendingCard';
import { Tooltip } from '../../../../core/components/tooltip';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import { reviewDisposition } from '../../state/ui';
import testIds from '../../../../../core/testIds';
import withDispositionActionSidePanel, { ACTION_TYPES } from '../withDispositionActionSidePanel';
import { hasEvidenceLocationPermission } from '../../../../evidence/core/utils/evidenceItemsHelpers';
import DispositionEventDetails from './DispositionEventDetails';

const strings = componentStrings.reports.custodialPropertySummary.disposition.DispositionReviewCard;

const {
    DISPOSITION_APPROVED,
    PRIMARY_DISPOSITION_APPROVED,
    PRIMARY_RELEASE_APPROVED,
    RELEASE_APPROVED,
} = DispositionEventTypeEnum;

const {
    PENDING_PRIMARY_REVIEW,
    PENDING_SECONDARY_REVIEW_FOR_RELEASE,
    PENDING_SECONDARY_REVIEW_FOR_DISPOSITION,
} = DispositionStatusEnum;

const WideButton = styled(Button)`
    width: 150px;
`;

const HoldButton = styled(Button)`
    width: 100px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const LocationPermissionToolTip = ({ children, disabled }) => {
    return disabled ? (
        <Tooltip side="right" content={strings.tooltips.evidenceLocationPermissions}>
            <span>{children}</span>
        </Tooltip>
    ) : (
        children
    );
};

const DispositionButton = withDispositionActionSidePanel(({ children, setRef, ...props }) => (
    <LocationPermissionToolTip disabled={props.disabled}>
        <Button className={buttonTypes.SECONDARY} ref={setRef} {...props}>
            {children}
        </Button>
    </LocationPermissionToolTip>
));

const DispositionWideButton = withDispositionActionSidePanel(({ children, setRef, ...props }) => (
    <LocationPermissionToolTip disabled={props.disabled}>
        <WideButton className={buttonTypes.SECONDARY} ref={setRef} {...props}>
            {children}
        </WideButton>
    </LocationPermissionToolTip>
));

const DispositionHoldButton = withDispositionActionSidePanel(({ children, setRef, ...props }) => (
    <LocationPermissionToolTip disabled={props.disabled}>
        <HoldButton className={buttonTypes.SECONDARY} ref={setRef} {...props}>
            {children}
        </HoldButton>
    </LocationPermissionToolTip>
));

/**
 * Base component exported for playground testing.
 */
export function _DispositionReviewCard({
    dispositionEvents,
    itemEvidenceState: {
        canPrimaryReview,
        canSecondaryReviewForRelease,
        canSecondaryReviewForDisposition,
        isPrimaryReviewer,
        dispositionStatus,
    } = {},
    masterItemId,
    hasRequestDispositionAbility,
    className,
    applicationSettings,
    userRoles,
    latestChainEventViewModel,
    evidenceLocationPermissionsWhere,
}) {
    const isItemInEvidenceLocation =
        latestChainEventViewModel.facilityId !== undefined ||
        latestChainEventViewModel.storageLocationId !== undefined;
    const evidenceLocationPermissions = isItemInEvidenceLocation
        ? evidenceLocationPermissionsWhere({ masterItemId })
        : [];
    const userRolesById = keyBy(userRoles, (role) => role.roleId);
    const useEvdPerms = applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED;

    const hasNoLocationPermission =
        useEvdPerms &&
        isItemInEvidenceLocation &&
        !hasEvidenceLocationPermission(
            [evidenceLocationPermissions],
            userRolesById,
            OperationTypeEnum.MANAGE.name
        );
    // the itemEvidenceState is computed by the server and fully determine
    // whether each action can be taken on the item right now by the current
    // user; the `if` cases below that have other conditions beyond
    // itemEvidenceState are special summary views
    if (canPrimaryReview) {
        return (
            <PendingCard title={strings.titles.waitingOnPrimaryReview} className={className}>
                <DispositionEventDetails
                    dispositionEvents={dispositionEvents}
                    dispositionStatus={dispositionStatus}
                    isPrimaryReview={true}
                />
                <Row>
                    <DispositionButton
                        actionType={ACTION_TYPES.PRIMARY_APPROVE_RELEASE}
                        masterItemIds={[masterItemId]}
                        disabled={hasNoLocationPermission}
                        testId={testIds.DISPOSITION_REVIEW_CARD_PRIMARY_APPROVE_RELEASE}
                    >
                        {strings.actions.primaryApproveRelease}
                    </DispositionButton>
                    <DispositionWideButton
                        actionType={ACTION_TYPES.PRIMARY_APPROVE_DISPOSITION}
                        masterItemIds={[masterItemId]}
                        disabled={hasNoLocationPermission}
                        testId={testIds.DISPOSITION_REVIEW_CARD_PRIMARY_APPROVE_DISPOSITION}
                    >
                        {strings.actions.primaryApproveDisposition}
                    </DispositionWideButton>
                    <DispositionHoldButton
                        actionType={ACTION_TYPES.PRIMARY_REJECT_HOLD}
                        masterItemIds={[masterItemId]}
                        disabled={hasNoLocationPermission}
                        testId={testIds.DISPOSITION_REVIEW_CARD_HOLD}
                    >
                        {strings.actions.primaryRejectToHold}
                    </DispositionHoldButton>
                </Row>
            </PendingCard>
        );
    } else if (
        dispositionStatus === PENDING_SECONDARY_REVIEW_FOR_RELEASE.name &&
        !canSecondaryReviewForRelease &&
        (isPrimaryReviewer || hasRequestDispositionAbility)
    ) {
        return (
            <PendingCard
                title={strings.titles.pendingSecondaryReviewForRelease}
                className={className}
            >
                <DispositionEventDetails
                    dispositionEvents={dispositionEvents}
                    dispositionStatus={dispositionStatus}
                    isPendingReview={true}
                />
            </PendingCard>
        );
    } else if (
        dispositionStatus === PENDING_SECONDARY_REVIEW_FOR_DISPOSITION.name &&
        !canSecondaryReviewForDisposition &&
        (isPrimaryReviewer || hasRequestDispositionAbility)
    ) {
        return (
            <PendingCard
                title={strings.titles.pendingSecondaryReviewForDisposition}
                className={className}
            >
                <DispositionEventDetails
                    dispositionEvents={dispositionEvents}
                    dispositionStatus={dispositionStatus}
                    isPendingReview={true}
                />
            </PendingCard>
        );
    } else if (canSecondaryReviewForRelease) {
        return (
            <PendingCard
                title={strings.titles.waitingOnSecondaryReviewForRelease}
                className={className}
            >
                <DispositionEventDetails
                    dispositionStatus={dispositionStatus}
                    dispositionEvents={dispositionEvents}
                />
                <Row>
                    <DispositionWideButton
                        actionType={ACTION_TYPES.SECONDARY_APPROVE_RELEASE}
                        masterItemIds={[masterItemId]}
                        testId={testIds.DISPOSITION_REVIEW_CARD_SECONDARY_APPROVE_RELEASE}
                    >
                        {strings.actions.secondaryApproveRelease}
                    </DispositionWideButton>
                    <DispositionWideButton
                        actionType={ACTION_TYPES.SECONDARY_REJECT}
                        masterItemIds={[masterItemId]}
                        testId={testIds.DISPOSITION_REVIEW_CARD_SECONDARY_REJECT}
                    >
                        {strings.actions.secondaryReject}
                    </DispositionWideButton>
                </Row>
            </PendingCard>
        );
    } else if (canSecondaryReviewForDisposition) {
        return (
            <PendingCard
                title={strings.titles.waitingOnSecondaryReviewForDisposition}
                className={className}
            >
                <DispositionEventDetails
                    dispositionStatus={dispositionStatus}
                    dispositionEvents={dispositionEvents}
                />
                <Row>
                    <DispositionWideButton
                        actionType={ACTION_TYPES.SECONDARY_APPROVE_DISPOSITION}
                        id={masterItemId}
                        masterItemIds={[masterItemId]}
                        disabled={hasNoLocationPermission}
                        testId={testIds.DISPOSITION_REVIEW_CARD_SECONDARY_APPROVE_DISPOSITION}
                    >
                        {strings.actions.secondaryApproveDisposition}
                    </DispositionWideButton>
                    <DispositionWideButton
                        actionType={ACTION_TYPES.SECONDARY_REJECT}
                        id={masterItemId}
                        masterItemIds={[masterItemId]}
                        disabled={hasNoLocationPermission}
                        testId={testIds.DISPOSITION_REVIEW_CARD_SECONDARY_REJECT}
                    >
                        {strings.actions.secondaryReject}
                    </DispositionWideButton>
                </Row>
            </PendingCard>
        );
    } else if (dispositionStatus === PENDING_PRIMARY_REVIEW.name && hasRequestDispositionAbility) {
        return (
            <PendingCard title={strings.titles.pendingPrimaryReview} className={className}>
                <DispositionEventDetails
                    dispositionEvents={dispositionEvents}
                    dispositionStatus={dispositionStatus}
                    isPendingReview={true}
                    isPrimaryReview={true}
                />
            </PendingCard>
        );
    } else {
        return <div />;
    }
}

const mapStateToProps = createStructuredSelector({
    itemEvidenceStateByChainOfCustodyId: itemEvidenceStateByChainOfCustodyIdSelector,
    dispositionEventsByChainOfCustodyId: dispositionEventsByChainOfCustodyIdSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    applicationSettings: applicationSettingsSelector,
    evidenceLocationPermissionsWhere: evidenceLocationPermissionsWhereSelector,
    userRoles: userRolesSelector,
    chainEventViewModelsForChainOfCustodyId: chainEventViewModelsForChainOfCustodyIdSelector,
    chainOfCustodies: chainOfCustodiesSelector,
});

const mapDispatchToProps = (dispatch) => ({
    reviewDisposition: (...args) => dispatch(reviewDisposition(...args)),
});

const handlers = {
    primaryApproveRelease({
        reviewDisposition,
        masterItemId,
        itemEvidenceState: { dispositionApprovalLevel },
    }) {
        let dispositionEventType;
        switch (dispositionApprovalLevel) {
            case DispositionApprovalLevelEnum.PRIMARY.name:
                dispositionEventType = RELEASE_APPROVED.name;
                break;
            case DispositionApprovalLevelEnum.SECONDARY.name:
                dispositionEventType = PRIMARY_RELEASE_APPROVED.name;
                break;
            default:
                return noop;
        }
        return () => {
            reviewDisposition(masterItemId, { dispositionEventType });
        };
    },
    primaryApproveDisposition({
        reviewDisposition,
        masterItemId,
        itemEvidenceState: { dispositionApprovalLevel },
    }) {
        let dispositionEventType;
        switch (dispositionApprovalLevel) {
            case DispositionApprovalLevelEnum.PRIMARY.name:
                dispositionEventType = DISPOSITION_APPROVED.name;
                break;
            case DispositionApprovalLevelEnum.SECONDARY.name:
                dispositionEventType = PRIMARY_DISPOSITION_APPROVED.name;
                break;
            default:
                return noop;
        }
        return () => {
            reviewDisposition(masterItemId, { dispositionEventType });
        };
    },
    secondaryApproveRelease({ reviewDisposition, masterItemId }) {
        return () => {
            reviewDisposition(masterItemId, {
                dispositionEventType: RELEASE_APPROVED.name,
            });
        };
    },
    secondaryApproveDisposition({ reviewDisposition, masterItemId }) {
        return () => {
            reviewDisposition(masterItemId, {
                dispositionEventType: DISPOSITION_APPROVED.name,
            });
        };
    },
};

/**
 * Card for the current user to review the release/disposition of a single item
 *   as either the primary or secondary reviewer. Nothing appears if the user
 *   cannot currently perform a disposition review action on the item.
 * @param {number} [props.chainOfCustodyId]
 */
export default compose(
    renderOnlyIf(({ chainOfCustodyId }) => !!chainOfCustodyId),
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(['currentUserHasAbility'], ({ currentUserHasAbility }) => ({
        hasRequestDispositionAbility: currentUserHasAbility(
            abilitiesEnum.EVIDENCE.REQUEST_DISPOSITION
        ),
    })),
    withPropsOnChange(
        [
            'chainOfCustodyId',
            'itemEvidenceStateByChainOfCustodyId',
            'dispositionEventsByChainOfCustodyId',
            'chainEventViewModelsForChainOfCustodyId',
        ],
        ({
            chainOfCustodyId,
            itemEvidenceStateByChainOfCustodyId,
            dispositionEventsByChainOfCustodyId,
            chainEventViewModelsForChainOfCustodyId,
        }) => {
            const dispositionEvents = dispositionEventsByChainOfCustodyId(chainOfCustodyId);
            const itemEvidenceState = itemEvidenceStateByChainOfCustodyId(chainOfCustodyId);
            const latestChainEventViewModel = first(
                chainEventViewModelsForChainOfCustodyId(chainOfCustodyId)
            );

            return {
                dispositionEvents,
                itemEvidenceState,
                masterItemId: get(itemEvidenceState, 'masterItemId'),
                // find the latest disposition event that was a primary approval
                // since it needs to be displayed in some cases
                primaryApprovedDispositionEventId: chain(dispositionEvents)
                    .find(({ dispositionEventType }) => {
                        return (
                            dispositionEventType === PRIMARY_RELEASE_APPROVED.name ||
                            dispositionEventType === PRIMARY_DISPOSITION_APPROVED.name
                        );
                    })
                    .get('id')
                    .value(),
                latestChainEventViewModel,
            };
        }
    ),
    withHandlers(handlers)
)(_DispositionReviewCard);

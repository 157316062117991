import React from 'react';
import { useSelector } from 'react-redux';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Tooltip } from '../../../core/components/tooltip';
import { usePhotoLineupFieldName } from '../hooks/usePhotoLineupFieldName';

const strings = componentStrings.cases.casePhotoLineups.ExportDisableTooltip;

type LineupDisableExportTooltipProps = {
    children: React.ReactNode;
};

const LineupDisableExportTooltip: React.FC<LineupDisableExportTooltipProps> = ({ children }) => {
    const lineupFieldName = usePhotoLineupFieldName();
    const minSlot = useSelector(applicationSettingsSelector).RMS_MIN_PHOTO_LINEUP_SLOTS;
    const minSlotTooltip: number = minSlot && typeof minSlot !== 'boolean' ? +minSlot : 0;

    return (
        <Tooltip
            side="bottom"
            content={strings.exportDisableToolTip(minSlotTooltip, lineupFieldName)}
        >
            {children}
        </Tooltip>
    );
};

export default LineupDisableExportTooltip;

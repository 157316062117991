// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'property_status';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Property Status Resource',
        methods: {
            /**
             * Update a property status
             * @param  {number} id
             * @param  {Object} propertyStatus
             * @return {Promise<Object>}
             */
            updatePropertyStatus(id, propertyStatus) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${id}`,
                    data: propertyStatus,
                });
            },
            /**
             * Upsert property statuses
             * @param  {Object[]} propertyStatuses
             * @return {Promise<Object[]>}
             */
            upsertPropertyStatuses(propertyStatuses) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/bulk`,
                    data: propertyStatuses,
                });
            },
            /**
             * Delete property statuses by ids
             * @param  {number[]} propertyStatusesIds
             * @return {Promise}
             */
            deletePropertyStatuses(propertyStatusIds) {
                return req({
                    method: 'POST',
                    url: `${BASE_PATH}/delete/bulk`,
                    data: propertyStatusIds,
                });
            },
        },
    });
}

/**
 * Resources for property statuses. This is temporarily a function to avoid
 *   dependency problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { withState } from 'recompose';
import { searchSizes } from '~/client-common/configs/advancedSearchConfig';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getSavedSearchById } from '~/client-common/helpers/advancedSearchHelpers';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import Pagination from '../../../../legacy-redux/components/core/Pagination';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import { SavedSearchDropdownButton } from '../../saved-search/components/SavedSearchDropdownButton';
import CreateSavedSearchModal from '../../saved-search/components/CreateSavedSearchModal';
import { DeleteSavedSearchConfirmationModal } from '../../saved-search/components/SavedSearchConfirmationModals';
import useSaveAsNewSearch from '../hooks/useSaveAsNewSearch';
import testIds from '../../../../core/testIds';
import SearchFilters from './SearchFilters';
import AdvancedSearchSaveSearchButton from './AdvancedSearchSaveSearchButton';

const strings = componentStrings.search.AdvancedSearchResultsTableHeader;

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    float: left;
    margin-right: 10px;
    padding-top: 7px;
`;

const SearchButtons = styled.div`
    display: inline-flex;
    float: left;
    align-items: center;
`;

function ShowFiltersToggle({ onClick: handleClick, filtersOpen }) {
    return (
        <div
            className="show-filters-toggle"
            onClick={handleClick}
            data-test-id={testIds.ADVANCED_SEARCH_SHOW_FILTER_TOGGLE}
        >
            <span className="show-filters-text">
                {filtersOpen ? strings.hideFilters : strings.showFilters}
            </span>
            <Icon type={filtersOpen ? iconTypes.COLLAPSE : iconTypes.EXPAND} />
        </div>
    );
}

function AdvancedSearchResultsTableHeader({
    from,
    to,
    totalResults,
    filtersOpen,
    setFiltersOpen,
    searchFilters,
    searchSize,
    currentPage,
    onSizeChange,
    onPaginationClick: handlePaginationClick,
    // Default the search to not be savable.
    isSearchSavable = false,
    onSaveSearchToggleClick,
    savedSearchId,
    savedSearchErrorMessage,
    savedSearches,
    onSaveSearch,
    searchModule,
}) {
    const savedSearch = getSavedSearchById(savedSearches, savedSearchId);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const savedSearchSharingEnabled = applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED;
    const saveAsNewSearchHandler = useSaveAsNewSearch(searchModule);

    return (
        <th colSpan={4} className="advanced-search-table-header">
            <div className="header-top">
                <div className="size-select-container">
                    <TableResultsSummaryContainer
                        from={from}
                        to={to}
                        totalResults={totalResults}
                        caption=""
                    />
                    <SearchSizeDropdownMenu sizes={searchSizes} onChange={onSizeChange} />
                </div>
                <SearchButtons>
                    {isSearchSavable && (
                        <>
                            <SavedSearchDropdownButton searchModule={searchModule} />
                            {!savedSearchSharingEnabled && (
                                <AdvancedSearchSaveSearchButton
                                    onSaveSearchToggleClick={onSaveSearchToggleClick}
                                    savedSearch={savedSearch}
                                    savedSearchErrorMessage={savedSearchErrorMessage}
                                />
                            )}
                            <CreateSavedSearchModal
                                onSave={
                                    savedSearchSharingEnabled
                                        ? saveAsNewSearchHandler
                                        : onSaveSearch
                                }
                            />
                            <DeleteSavedSearchConfirmationModal searchModule={searchModule} />
                        </>
                    )}
                    <ShowFiltersToggle
                        onClick={() => setFiltersOpen((oldVal) => !oldVal)}
                        filtersOpen={filtersOpen}
                    />
                </SearchButtons>
                <div className="advanced-search-table-header-pagination">
                    {totalResults > 0 ? (
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={searchSize}
                            itemCount={totalResults}
                            onClick={handlePaginationClick}
                            maxEdgeItems={1}
                        />
                    ) : undefined}
                </div>
            </div>
            {filtersOpen && (
                <div>
                    <SearchFilters searchFilters={searchFilters} />
                </div>
            )}
        </th>
    );
}

export default withState('filtersOpen', 'setFiltersOpen', false)(AdvancedSearchResultsTableHeader);

import { compact, flatMap, map } from 'lodash';

import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { NEXUS_STATE_PROP as WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrants/state/data';
import { NEXUS_STATE_PROP as WARRANT_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-statuses/state/data';
import { NEXUS_STATE_PROP as WARRANT_DEX_SUBMISSIONS_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-dex-submissions/state/data';
import { NEXUS_STATE_PROP as MINI_USERS_NEXUS_STATE_PROP } from '~/client-common/core/domain/mini-users/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { userProfileToMiniUser } from '~/client-common/core/domain/mini-users/utils/miniUsersHelpers';

const storeChargeWarrantView = ({ chargeWarrantView, action }) => (dispatch) => {
    // eslint-disable-next-line no-restricted-syntax
    dispatch(storeChargeWarrantViews({ chargeWarrantViews: [chargeWarrantView], action }));
};

export default storeChargeWarrantView;

export const storeChargeWarrantViews = ({ chargeWarrantViews = [], action = { type: '' } }) => (
    dispatch,
    getState,
    { nexus: { withEntityItems } }
) => {
    const warrants = flatMap(chargeWarrantViews, 'warrant');
    const warrantStatuses = compact(flatMap(chargeWarrantViews, 'warrantStatus'));
    const warrantDexSubmissions = compact(flatMap(chargeWarrantViews, 'warrantDexSubmission'));
    const attributes = flatMap(chargeWarrantViews, 'attributes');
    const userProfileViews = flatMap(chargeWarrantViews, 'userProfileViews');

    const attributeViewsToStore = map(attributes, convertAttributeToAttributeView);
    const miniUsersToStore = map(userProfileViews, userProfileToMiniUser);

    dispatch(
        withEntityItems(
            {
                [WARRANTS_NEXUS_STATE_PROP]: warrants,
                [WARRANT_STATUSES_NEXUS_STATE_PROP]: warrantStatuses,
                [WARRANT_DEX_SUBMISSIONS_NEXUS_STATE_PROP]: warrantDexSubmissions,
                [ATTRIBUTES_NEXUS_STATE_PROP]: attributeViewsToStore,
                [MINI_USERS_NEXUS_STATE_PROP]: miniUsersToStore,
            },
            action
        )
    );
};

import { EntityTypeEnum, AttributeTypeEnum } from '@mark43/rms-api';
import { compact, flatMap, map, omit } from 'lodash';

import { createOffense, upsertOffense } from '~/client-common/core/domain/offenses/state/data';
import { addNameReportLink } from '~/client-common/core/domain/name-report-links/state/data';
import { setOffenseAttributes } from '~/client-common/core/domain/offense-attributes/state/data';
import { updateOffenseInvolvedChildren } from '~/client-common/core/domain/offense-involved-children/state/data';
import { isClientSideOffenseStub } from '~/client-common/core/domain/offenses/utils/offensesHelpers';
import {
    updateNameAttributesForType,
    nameAttributesWhereSelector,
} from '~/client-common/core/domain/name-attributes/state/data';
import { replaceLocationLinksForEntity } from '~/client-common/core/domain/location-entity-links/state/data';
import { isBefore, isDateBetween } from '~/client-common/core/dates/utils/dateHelpers';
import { replaceOffenseSubCrimeLinks } from '~/client-common/core/domain/offense-sub-crime-links/state/data';

import { convertNameAttributesFormDataToDataState } from '../../../../legacy-redux/helpers/nameAttributesHelpers';
import { validateLinkedPersonForms } from '../helpers/validateLinkedPersonForms';

/**
 * Maps offense attributes from mft form state for a given offense into the shape the api expects
 *
 * @param object attributeIsOther attributeIsOtherSelector
 * @param object offense The offense/incident
 * @param object offenseAttributes Offense attributes from MFT form state
 *
 * @return array Mapped offense attributes
 */
export const mapOffenseAttributes = ({ attributeIsOther, offense, offenseAttributes }) => {
    const offenseId = offense.id;
    return flatMap(offenseAttributes, ({ attributeIds, description } = {}, key) => {
        let attributeIdsArray;
        if (!Array.isArray(attributeIds)) {
            attributeIdsArray = compact([attributeIds]);
        } else {
            attributeIdsArray = attributeIds;
        }
        return map(attributeIdsArray, (attributeId) => {
            const mapped = {
                attributeId,
                offenseId,
                attributeType: key,
            };
            if (attributeIsOther(attributeId)) {
                mapped.description = description;
            }
            return mapped;
        });
    });
};

/**
 * Takes in `dispatch` and returns a new function that dispatches all actions required to
 * save an offense/incident card and returns all collected promises.
 * The `createOrUpsertPromise` is handled separately because it has
 * to be chained on to and relying on its array position is brittle
 *
 * @param object options
 * @param object options.offense The offense model to save (an incident is also an offense)
 * @param array  options.attributes The offense attributes to save
 * @param array  options.links An array of name report links to be saved
 *
 * @return object
 */
export const getCardSavePromises = ({ additionalFormsToValidate, dispatch, getState }) => ({
    offense,
    attributes,
    links,
    locationEntityLinks,
    offenseInvolvedChildren = [],
    subCrimeLinks = [],
}) => {
    const createOrUpsertPromise = dispatch(
        isClientSideOffenseStub(offense) ? createOffense(offense) : upsertOffense(offense)
    );
    const nameAttributesWhere = nameAttributesWhereSelector(getState());
    const additionalFormsValidationErrors = additionalFormsToValidate?.length
        ? dispatch(
              validateLinkedPersonForms({
                  additionalFormsToValidate,
                  offenseFormIndex: offense.id,
              })
          )
        : [];

    return {
        createOrUpsertPromise,
        allPromises: compact([
            createOrUpsertPromise,
            ...(offense.id > 0
                ? [
                      dispatch(setOffenseAttributes({ offenseId: offense.id, attributes })),
                      dispatch(
                          replaceLocationLinksForEntity(
                              EntityTypeEnum.OFFENSE.name,
                              offense.id,
                              locationEntityLinks
                          )
                      ),
                      dispatch(
                          updateOffenseInvolvedChildren({
                              offenseId: offense.id,
                              offenseInvolvedChildren,
                          })
                      ),
                      dispatch(replaceOffenseSubCrimeLinks({ offenseId: offense.id, subCrimeLinks })),
                  ]
                : []),
            ...flatMap(links, (nameReportLink) => {
                const promises = [];
                const informationProvidedAttributes = nameAttributesWhere({
                    nameId: nameReportLink.nameId,
                    attributeType: AttributeTypeEnum.INFORMATION_PROVIDED_TO_VICTIM.name,
                });

                if (
                    // attributes have been added
                    (!nameReportLink.informationProvidedAttrIds &&
                        informationProvidedAttributes.length) ||
                    // attributes have been modified
                    (nameReportLink.informationProvidedAttrIds &&
                        nameReportLink.informationProvidedAttrIds.length !==
                            informationProvidedAttributes.length)
                ) {
                    promises.push(
                        dispatch(
                            updateNameAttributesForType({
                                attributes: convertNameAttributesFormDataToDataState(
                                    nameReportLink.informationProvidedAttrIds || [],
                                    nameReportLink.nameId,
                                    nameReportLink.entityType,
                                    AttributeTypeEnum.INFORMATION_PROVIDED_TO_VICTIM.name
                                ),
                                entityId: nameReportLink.nameId,
                                attributeType:
                                    AttributeTypeEnum.INFORMATION_PROVIDED_TO_VICTIM.name,
                            })
                        )
                    );
                }

                promises.push(
                    dispatch(addNameReportLink(omit(nameReportLink, 'informationProvidedAttrIds')))
                );
                return promises;
            }),
            additionalFormsValidationErrors.length
                ? Promise.reject(additionalFormsValidationErrors)
                : Promise.resolve([]),
        ]),
    };
};

/**
 * Returns a generic handler used to refresh MFT form state on offense/incidents cards
 * after the cards have been saved.
 *
 * @param object    options
 * @param function  options.dispatch Redux dispatch function
 * @param function  options.deriveNewFormState An action creator that returns updated form state for a given offense
 * @param object    options.cardModule The cardmodule this offense belongs to (offense or incident)
 * @param object    options.offense The offense/incident which was saved - used for detecting whether this was an update or create action
 *
 * @return function
 */
export const createFormUpdateHandler = ({
    dispatch,
    deriveNewFormState,
    form,
    cardModule,
    offense,
}) => {
    return (createdOrUpsertedOffense) => {
        // sync our form state so that we have the most recent data after saving
        const updatedFormModel = dispatch(deriveNewFormState(createdOrUpsertedOffense));
        form.set('', updatedFormModel);

        // Offenses created on the client will have a client-only id.
        // We need to copy their card state over to the new offense id because
        // after updating our store with the upserted offense, that id will
        // be used when interacting with the card instead
        if (offense.id !== createdOrUpsertedOffense.id) {
            dispatch(
                cardModule.actionCreators.cloneCardState({
                    sourceIndex: offense.id,
                    targetIndex: createdOrUpsertedOffense.id,
                })
            );
            // since the index changed we have to manually trigger the scroll action
            cardModule.scrollToTop({
                index: createdOrUpsertedOffense.id,
            });
        }
    };
};

export const checkIfOffenseDateIsWithinOffenseCodeDateRange = ({
    startDateUtc,
    endDateUtc,
    offenseDateUtc,
}) => {
    if (endDateUtc) {
        return isDateBetween(offenseDateUtc, startDateUtc, endDateUtc);
    }
    return isBefore(offenseDateUtc, startDateUtc);
};

import React, { createContext, useContext } from 'react';
import { ComplianceExportType, LoadingState } from '../types';

interface ComplianceExportContextProps {
    complianceExportType: ComplianceExportType | undefined;
    currentAgencyId: number | undefined;
    setCurrentAgencyId: React.Dispatch<React.SetStateAction<number | undefined>>;
    isNibrs: boolean;
    callGetLastExportedDateResource: (agencyId: number) => Promise<void>;
    getLastExportedDateLoadingState: LoadingState;
}

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
function createComplianceExportContext<A extends ComplianceExportContextProps | null>() {
    const ComplianceExportContext = createContext<A | undefined>(undefined);
    function useComplianceExportContext() {
        const context = useContext(ComplianceExportContext);
        if (context === undefined) {
            throw new Error('useComplianceExportContext must be inside a Provider with a value');
        }
        return context;
    }
    return {
        useComplianceExportContext,
        ComplianceExportContextProvider: ComplianceExportContext.Provider,
    } as const;
}

export const {
    useComplianceExportContext,
    ComplianceExportContextProvider,
} = createComplianceExportContext<ComplianceExportContextProps>();

import React from 'react';
import { cssVar } from 'arc';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { EntityProfileActiveTargetItem } from './EntityProfileActiveTargetItem';
import { useActiveTargetCases } from './ActiveTargetCasesContext';

const borderCss = `${cssVar('arc.space.px')} solid ${cssVar('arc.colors.negative.default')}`;
const radiiBaseCss = cssVar('arc.radii.base');

const Header = styled.div`
    padding: ${cssVar('arc.space.3')};
    background: ${cssVar('arc.colors.negative.accent')};
    border: ${borderCss};
    border-radius: ${radiiBaseCss} ${radiiBaseCss} 0 0;
`;

const HeaderText = styled.h3`
    font-size: ${cssVar('arc.fontSizes.sm')};
    line-height: ${cssVar('arc.lineHeights.normal')};
    font-weight: ${cssVar('arc.fontWeights.bold')};
`;

const ActiveTargetItemContainer = styled.div`
    padding: ${cssVar('arc.space.3')};
    border-left: ${borderCss};
    border-right: ${borderCss};
    border-bottom: ${borderCss};
    border-radius: 0 0 ${radiiBaseCss} ${radiiBaseCss};
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

const strings = componentStrings.entityProfiles.EntityProfileActiveTarget;

export const EntityProfileActiveTarget = () => {
    const { activeTargetCasesViewModels, isActiveTarget } = useActiveTargetCases();

    return (
        isActiveTarget && (
            <div>
                <Header>
                    <HeaderText>{strings.header}</HeaderText>
                </Header>
                <ActiveTargetItemContainer>
                    {activeTargetCasesViewModels.map((caseItem) => (
                        <EntityProfileActiveTargetItem key={caseItem.id} caseItem={caseItem} />
                    ))}
                </ActiveTargetItemContainer>
            </div>
        )
    );
};

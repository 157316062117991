import { createSelector } from 'reselect';
import { mapValues, get } from 'lodash';
import settingsConfig from '../../core/configuration';
import { formatISODate, dateTimeFormats } from '../../../../dates/utils/dateHelpers';

type Settings = { [key: string]: string };

// ACTION TYPES
const STORE_SETTINGS = 'settings/STORE_SETTINGS';

// ACTIONS
export function storeSettings(settingsByName: Settings) {
    return {
        type: STORE_SETTINGS,
        payload: settingsByName,
    };
}

// SELECTORS
// Make sure to use get here because our states are not the same shape.
const settingsSelector = (state: unknown) =>
    get(state, 'data.settings', {}) as Settings | Record<string, unknown>;

export const applicationSettingsSelector = createSelector(settingsSelector, (settings) => {
    return mapValues(settingsConfig, ({ name, type }) => {
        const rawValue = settings[name] as string;
        switch (type) {
            case 'BOOLEAN':
                return rawValue && rawValue.trim().toLowerCase() === 'true';
            case 'NUMBER':
                return parseFloat(rawValue);
            case 'STRING':
                return rawValue;
            case 'DATE':
                return formatISODate(rawValue, dateTimeFormats.isoDate);
            default:
                return null;
        }
    });
});

// REDUCER
export default function settingsReducer(state = {}, action: ReturnType<typeof storeSettings>) {
    switch (action.type) {
        case STORE_SETTINGS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

import React from 'react';
import { simpleControl } from 'markformythree';

import ImageUpload from '../../forms/components/ImageUpload';

class EntityProfileFormImageUploader extends React.Component {
    handleStart = () => {
        if (this.props.onStart) {
            this.props.onStart();
        }
    };

    handleSuccess = (file) => {
        this.props.onChange(file);
        if (this.props.onSuccess) {
            this.props.onSuccess();
        }
    };

    handleFailure = () => {
        if (this.props.onError) {
            this.props.onError();
        }
    };

    handleRemove = () => this.props.onChange(undefined);

    render() {
        return (
            <ImageUpload
                {...this.props}
                onSuccess={this.handleSuccess}
                onStart={this.handleStart}
                onError={this.handleFailure}
                onRemove={this.handleRemove}
            />
        );
    }
}

export const MFTEntityProfileFormImageUploader = simpleControl(EntityProfileFormImageUploader);

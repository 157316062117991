import { CaseProfileLink, CaseProfileLinkResponse } from '@mark43/rms-api';
import { ClientCommonAction } from '../../../../../redux/types';
import { NEXUS_STATE_PROP as ITEM_CASE_LINKS_NEXUS_STATE_PROP } from '../../../item-case-links/state/data';
import { NEXUS_STATE_PROP as NAME_CASE_LINKS_NEXUS_STATE_PROP } from '../../../name-case-links/state/data';
import caseProfileLinksResource from '../../resources/caseProfileLinksResource';

const UPDATE_ITEM_CASE_PROFILES_SUCCESS = 'item-case-links/UPDATE_ITEM_CASE_PROFILES_SUCCESS';

export function updateCaseProfileLinks(
    caseId: number,
    caseProfileLinks: CaseProfileLink[],
    postUpdateAction?: () => void
): ClientCommonAction<Promise<CaseProfileLinkResponse>> {
    return (dispatch, getState, { nexus }) => {
        return caseProfileLinksResource
            .updateCaseProfileLinks(caseId, caseProfileLinks)
            .then((res) => {
                if (postUpdateAction) {
                    postUpdateAction();
                }
                return res;
            })
            .then((response: CaseProfileLinkResponse) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [NAME_CASE_LINKS_NEXUS_STATE_PROP]: response.nameCaseLinks,
                            [ITEM_CASE_LINKS_NEXUS_STATE_PROP]: response.itemCaseLinks,
                        },
                        // replace entirely by `caseId`
                        nexus.withRemoveMultiple(
                            {
                                [NAME_CASE_LINKS_NEXUS_STATE_PROP]: { caseId },
                                [ITEM_CASE_LINKS_NEXUS_STATE_PROP]: { caseId },
                            },
                            { type: UPDATE_ITEM_CASE_PROFILES_SUCCESS }
                        )
                    )
                );
                return response;
            });
    };
}

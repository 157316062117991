import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/evidence/api';

/**
 * Resources for the EvidenceHydratedItem model, which is a bundle that is not
 *   stored in data state directly, but rather is separated out into different
 *   models in data state.
 */
export default createResource({
    name: 'Evidence Hydrated Item Resource',
    methods: {
        /**
         * Load a hydrated item with all the evidence data related
         * to that item
         */
        getHydratedItem(itemId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `evidence/item/hydrated/${itemId}`,
            });
        },

        /**
         * Load hydrated items with all the evidence data related to them
         */
        getHydratedItems(itemIds, options = {}) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: 'evidence/item/hydrated/batch',
                data: itemIds,
                ...options,
            });
        },

        /**
         * Load hydrated items with the evidence data related to them within the
         *   context of a non-Custodial Property Summary report only. This
         *   "lite" version of the endpoint returns less models to improve
         *   performance.
         */
        getHydratedItemsLite(itemIds) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: 'evidence/item/hydrated/lite/batch',
                data: itemIds,
            });
        },

        /**
         * Delete an item profile along with all its linked RMS and Evidence data.
         * @return {Promise<boolean>}
         */
        deleteHydratedItem(itemProfileId, reasonForDeletion = 'No reason provided') {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `evidence/item/hydrated/${itemProfileId}/delete`,
                data: { itemProfileIds: [itemProfileId], reasonForDeletion },
            });
        },
        /**
         * changes evidence item's high risk status
         * @param {*} isHighValue boolean
         * @param {*} masterItemId number
         * @returns {Promise<EvidenceHydratedItem>}
         */
        setHighValue(isHighValue, masterItemId) {
            return req({
                method: 'PUT',
                baseUrl: BASE_URL,
                url: `evidence/item/high-value/${masterItemId}`,
                params: {
                    is_high_value: isHighValue,
                },
            });
        },
    },
});

import { filter, mapValues } from 'lodash';
import { CaseReview } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';

import {
    buildViewModel,
    buildAllMiniUserFormatsMapper,
} from '../../../../../helpers/viewModelHelpers';

export const NEXUS_STATE_PROP = 'caseReviews';
const caseReviewsModule = createNormalizedModule<CaseReview>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const storeCaseReviews = caseReviewsModule.actionCreators.storeEntities;

// SELECTORS
const caseReviewsSelector = caseReviewsModule.selectors.entitiesSelector;

const caseReviewViewModelsSelector = createSelector(
    caseReviewsSelector,
    // TODO PRINTING-REFACTOR
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: any) => state.ui.currentUser.currentUserId,
    allMiniUserFormatsByIdSelector,
    (caseReviews, currentUserId, allMiniUserFormatsById) => {
        const viewModel = buildViewModel<CaseReview>({
            recursive: true,
            mappers: [
                buildAllMiniUserFormatsMapper(),
                ({ createdBy }) => ({
                    isCurrentUsersComment: createdBy === currentUserId,
                }),
            ],
            helpers: {
                allMiniUserFormatsById,
            },
        });
        // legacy to-do
        return (mapValues(caseReviews, viewModel) as unknown) as CaseReview[];
    }
);

export const caseReviewViewModelsForCaseIdSelector = createSelector(
    caseReviewViewModelsSelector,
    (caseReviewViewModels) => (caseId: number) => filter(caseReviewViewModels, { caseId })
);

// REDUCER
export default caseReviewsModule.reducerConfig;

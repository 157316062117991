import applicationSettings from '~/client-common/core/domain/settings/core/configuration';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_PATH = 'admin/application_settings';

export default createResource({
    name: 'Application Settings Resource',
    methods: {
        getApplicationSettings() {
            return req({
                method: 'GET',
                url: `${BASE_PATH}/department`,
                params: {
                    only_boolean_flags: false,
                },
            });
        },
        upsertApplicationSetting(upsertRequest: {
            name: typeof applicationSettings[keyof typeof applicationSettings]['name'];
            value: string | boolean;
        }) {
            return req({
                method: 'POST',
                url: `${BASE_PATH}/value`,
                data: upsertRequest,
            });
        },
    },
});

import { RoleTypeEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import _, {
    map,
    filter,
    compact,
    sortBy,
    keyBy,
    forEach,
    reject,
    identity,
    some,
    includes,
} from 'lodash';

import { createSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';

import {
    createRole,
    roleByIdSelector,
    LOAD_DEPARTMENT_ROLES_START,
    LOAD_DEPARTMENT_ROLES_SUCCESS,
    LOAD_DEPARTMENT_ROLES_FAILURE,
    CREATE_ROLE_START,
    CREATE_ROLE_SUCCESS,
    CREATE_ROLE_FAILURE,
    rolesForUserSelector,
} from '~/client-common/core/domain/roles/state/data';
import {
    storeUserRoles,
    loadUserRolesForRole,
    deleteUserFromRole,
    updateUserRole,
    userRolesWhereSelector,
} from '~/client-common/core/domain/user-roles/state/data';
import {
    abilitiesSelector,
    loadAbilities,
    abilitiesWhereSelector,
} from '~/client-common/core/domain/abilities/state/data';
import { abilityViewModelsSelector } from '~/client-common/core/domain/abilities/state/ui';
import {
    loadAbilityRoleLinksForRole,
    upsertAbilityRoleLinks,
    abilityRoleLinksWhereSelector,
} from '~/client-common/core/domain/ability-role-links/state/data';

import {
    loadRoleApprovalForRole,
    updateRoleApproval,
} from '~/client-common/core/domain/role-approvals/state/data';

import { userRoleViewModelsWhereSelector } from '~/client-common/core/domain/user-roles/state/ui';

import { roleApprovalViewModelsSelector } from '~/client-common/core/domain/role-approvals/state/ui';

import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { miniUserViewModelsSelector } from '~/client-common/core/domain/mini-users/state/ui';

import { abilityRoleLinkViewModelsWhereSelector } from '~/client-common/core/domain/ability-role-links/state/ui';

import { modulesSelector } from '~/client-common/core/domain/modules/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import {
    currentUserDepartmentIdSelector,
    currentUserIdSelector,
    currentUserHasAbilitySelector,
} from '../../../../core/current-user/state/ui';

import {
    updateRoleName,
    deleteRoleByRoleId,
} from '../../../../../legacy-redux/actions/rolesActions';

import { userProfileSelector } from '../../../../../legacy-redux/selectors/userSelectors';

import { USERS_PER_PAGE } from '../../configuration';

import rolesResource from '../../../../../legacy-redux/resources/rolesResource';
import {
    openBox,
    closeBox,
    saveBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';

import { sortCoreAbilities } from '../../utils/abilityFilterHelpers';
import { getDependedAbilities, getDependentAbilities } from '../../utils/abilityDependencyHelpers';

import editRoleFilterUsersForm from '../forms/editRoleFilterUsersForm';
import editRoleUsersManageStatusForm from '../forms/editRoleUsersManageStatusForm';
import editRoleFilterAbilitiesForm from '../forms/editRoleFilterAbilitiesForm';
import editRoleAbilitiesModulesForm from '../forms/editRoleAbilitiesModulesForm';
import editRoleAbilitiesEnabledForm from '../forms/editRoleAbilitiesEnabledForm';
import editRoleApprovalsForm from '../forms/editRoleApprovalsForm';
import editRoleNameForm from '../forms/editRoleNameForm';
import assignUserRoleForm from '../forms/assignUserRoleForm';

import addNewRoleForm from '../forms/addNewRoleForm';
import { logWarning } from '../../../../../core/logging';

// ACTION TYPES

const SET_CURRENT_EDITING_ROLE_ID = 'roles/SET_CURRENT_EDITING_ROLE_ID';
const SET_STICKY_CHILDREN_CONTAINER_HEIGHT = 'roles/SET_STICKY_CHILDREN_CONTAINER_HEIGHT';
const SET_CURRENT_USERS_PAGE_NUMBER = 'roles/SET_CURRENT_USERS_PAGE_NUMBER';
const SET_ACTION_BAR_HEIGHT = 'roles/SET_ACTION_BAR_HEIGHT';

const LOAD_DATA_START = 'roles/LOAD_DATA_START';
const LOAD_DATA_SUCCESS = 'roles/LOAD_DATA_SUCCES';
const LOAD_DATA_FAILURE = 'roles/LOAD_DATA_FAILURE';

const SAVE_ABILITIES_START = 'roles/SAVE_ABILITIES_START';
const SAVE_ABILITIES_SUCCESS = 'roles/SAVE_ABILITIES_SUCCESS';
const SAVE_ABILITIES_FAILURE = 'roles/SAVE_ABILITIES_FAILURE';

const SAVE_APPROVALS_START = 'roles/SAVE_APPROVALS_START';
const SAVE_APPROVALS_SUCCESS = 'roles/SAVE_APPROVALS_SUCCESS';
const SAVE_APPROVALS_FAILURE = 'roles/SAVE_APPROVALS_FAILURE';

const REMOVE_USER_FROM_ROLE_START = 'roles/REMOVE_USER_FROM_ROLE_START';
const REMOVE_USER_FROM_ROLE_SUCCESS = 'roles/REMOVE_USER_FROM_ROLE_SUCCESS';
const REMOVE_USER_FROM_ROLE_FAILURE = 'roles/REMOVE_USER_FROM_ROLE_FAILURE';

const SET_USER_CAN_MANAGE_START = 'roles/SET_USER_CAN_MANAGE_START';
const SET_USER_CAN_MANAGE_SUCCESS = 'roles/SET_USER_CAN_MANAGE_SUCCESS';
const SET_USER_CAN_MANAGE_FAILURE = 'roles/SET_USER_CAN_MANAGE_FAILURE';

// ACTION CREATORS

export function setCurrentEditingRoleId(roleId) {
    return {
        type: SET_CURRENT_EDITING_ROLE_ID,
        payload: roleId,
    };
}

export function setStickyChildrenContainerHeight(height) {
    return {
        type: SET_STICKY_CHILDREN_CONTAINER_HEIGHT,
        payload: height,
    };
}

export function setCurrentUsersPageNumber(pageNumber) {
    return {
        type: SET_CURRENT_USERS_PAGE_NUMBER,
        payload: pageNumber,
    };
}

export function setActionBarHeight(height) {
    return {
        type: SET_ACTION_BAR_HEIGHT,
        payload: height,
    };
}

function loadDataStart() {
    return {
        type: LOAD_DATA_START,
    };
}

function loadDataSuccess() {
    return {
        type: LOAD_DATA_SUCCESS,
    };
}

function loadDataFailure(message) {
    return {
        type: LOAD_DATA_FAILURE,
        payload: message,
    };
}

function saveAbilitiesStart(abilityRoleLinks) {
    return {
        type: SAVE_ABILITIES_START,
        payload: abilityRoleLinks,
    };
}

function saveAbilitiesSuccess(abilityRoleLinks) {
    return {
        type: SAVE_ABILITIES_SUCCESS,
        payload: abilityRoleLinks,
    };
}

function saveAbilitiesFailure(message) {
    return {
        type: SAVE_ABILITIES_FAILURE,
        payload: message,
    };
}

function saveApprovalsStart(roleApprovals) {
    return {
        type: SAVE_APPROVALS_START,
        payload: roleApprovals,
    };
}

function saveApprovalsSuccess(roleApprovals) {
    return {
        type: SAVE_APPROVALS_SUCCESS,
        payload: roleApprovals,
    };
}

function saveApprovalsFailure(message) {
    return {
        type: SAVE_APPROVALS_FAILURE,
        payload: message,
    };
}

function removeUserFromRoleStart() {
    return {
        type: REMOVE_USER_FROM_ROLE_START,
    };
}

function removeUserFromRoleSuccess(userRole) {
    return {
        type: REMOVE_USER_FROM_ROLE_SUCCESS,
        payload: userRole,
    };
}

function setUserCanManageStart(userRole, canManage) {
    return {
        type: SET_USER_CAN_MANAGE_START,
        payload: {
            userRole,
            canManage,
        },
    };
}

function setUserCanManageSuccess(userRole) {
    return {
        type: SET_USER_CAN_MANAGE_SUCCESS,
        payload: userRole,
    };
}

function setUserCanManageFailure(message) {
    return {
        type: SET_USER_CAN_MANAGE_FAILURE,
        payload: message,
    };
}

function removeUserFromRoleFailure(message) {
    return {
        type: REMOVE_USER_FROM_ROLE_FAILURE,
        payload: message,
    };
}

const EDIT_ROLE_NAME_MODAL_BOX_CONTEXT = { name: boxEnum.EDIT_ROLE_NAME_MODAL };
export function showEditRoleNameModal(currentRole) {
    return (dispatch) => {
        dispatch(editRoleNameForm.actionCreators.change(currentRole));
        dispatch(openBox(EDIT_ROLE_NAME_MODAL_BOX_CONTEXT));
    };
}

export function submitEditRoleNameModal() {
    return (dispatch, getState) => {
        dispatch(saveBoxStart(EDIT_ROLE_NAME_MODAL_BOX_CONTEXT));
        const state = getState();
        const applicationSettings = applicationSettingsSelector(state);
        return dispatch(
            editRoleNameForm.actionCreators.submit((formModel) => {
                const updatedRole = editRoleNameForm.convertFromFormModel(
                    applicationSettings,
                    formModel
                );
                return dispatch(updateRoleName(updatedRole));
            })
        )
            .then(() => dispatch(saveBoxSuccess(EDIT_ROLE_NAME_MODAL_BOX_CONTEXT)))
            .catch((err) =>
                dispatch(saveBoxFailure(EDIT_ROLE_NAME_MODAL_BOX_CONTEXT, err.message))
            );
    };
}

const DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT = { name: boxEnum.DELETE_ROLE_CONFIRMATION_MODAL };

export function openDeleteRoleModal(roleId) {
    return (dispatch) => {
        dispatch(setCurrentEditingRoleId(roleId));
        dispatch(openBox(DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT));
    };
}

export function submitDeleteRoleModal() {
    return (dispatch, getState) => {
        const state = getState();
        const currentRoleId = currentEditingRoleIdSelector(state);
        dispatch(saveBoxStart(DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT));
        return dispatch(deleteRoleByRoleId(currentRoleId))
            .then(() => dispatch(saveBoxSuccess(DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT)))
            .catch((err) =>
                dispatch(saveBoxFailure(DELETE_ROLE_CONFIRMATION_MODAL_CONTEXT, err.message))
            );
    };
}

const ADD_ROLE_SIDEBAR_CONTEXT = { name: boxEnum.ADMIN_NEW_ROLE_SIDE_PANEL };

export function openAddNewRoleSidePanel() {
    return (dispatch) => {
        dispatch(addNewRoleForm.actionCreators.reset());
        dispatch(openBox(ADD_ROLE_SIDEBAR_CONTEXT));
    };
}

export function submitAddNewRoleForm(router) {
    return (dispatch, getState) => {
        const dispatchSaveRoleSidebarFailure = (err) =>
            dispatch(saveBoxFailure(ADD_ROLE_SIDEBAR_CONTEXT, err.message));
        const state = getState();
        const applicationSettings = applicationSettingsSelector(state);
        dispatch(
            addNewRoleForm.actionCreators.submit((formModel) => {
                const departmentId = currentUserDepartmentIdSelector(state);
                const roleType = RoleTypeEnum.NORMAL.name;

                const roleData = addNewRoleForm.convertFromFormModel(
                    applicationSettings,
                    formModel,
                    {
                        departmentId,
                        roleType,
                    }
                );
                dispatch(createRole(roleData))
                    .then((role) => {
                        dispatch(saveBoxSuccess(ADD_ROLE_SIDEBAR_CONTEXT));
                        dispatch(closeAddNewRoleSidePanel());
                        router.push(`/admin/roles/${role.id}`);
                    })
                    .catch(dispatchSaveRoleSidebarFailure);
            })
        ).catch(dispatchSaveRoleSidebarFailure);
    };
}

export function closeAddNewRoleSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(ADD_ROLE_SIDEBAR_CONTEXT));
        dispatch(addNewRoleForm.actionCreators.reset());
    };
}

const ASSIGN_USERROLE_SIDEPANEL_BOX_CONTENT = { name: boxEnum.ADMIN_ASSIGN_USERROLE_SIDE_PANEL };
export function openAssignUserRoleSidePanel() {
    return (dispatch) => {
        dispatch(openBox(ASSIGN_USERROLE_SIDEPANEL_BOX_CONTENT));
        dispatch(assignUserRoleForm.actionCreators.reset());
    };
}

export function submitAssignUserRoleSidePanel() {
    return (dispatch, getState) => {
        const dispatchSaveRoleSidePanelFailure = (err) => {
            dispatch(saveBoxFailure(ASSIGN_USERROLE_SIDEPANEL_BOX_CONTENT, err.message));
        };
        const state = getState();
        const currentRoleId = currentEditingRoleIdSelector(state);
        const updatedById = userProfileSelector(state).userId;
        const currentDepartmentId = currentUserDepartmentIdSelector(state);
        const currentUserHasAbility = currentUserHasAbilitySelector(state);
        const hasRoleAdmin = currentUserHasAbility(
            abilitiesEnum.ADMIN.DELETE_GLOBAL_ROLE_ENROLLMENT
        );
        dispatch(
            assignUserRoleForm.actionCreators.submit((formModel) => {
                const userRoles = assignUserRoleForm.convertFromFormModel(formModel, {
                    isUserAdmin: false,
                    roleId: currentRoleId,
                    updatedBy: updatedById,
                    updatedDateUtc: Date.now(),
                    departmentId: currentDepartmentId,
                });
                const addingUserRoles = hasRoleAdmin
                    ? rolesResource.addUserRoles(userRoles)
                    : rolesResource.addUserRolesAdmin(userRoles, currentRoleId);
                addingUserRoles
                    .then((data) => {
                        dispatch(storeUserRoles(data));
                        dispatch(saveBoxSuccess(ASSIGN_USERROLE_SIDEPANEL_BOX_CONTENT));
                        dispatch(closeAssignUserRoleSidePanel());
                    })
                    .catch(dispatchSaveRoleSidePanelFailure);
            })
        ).catch(dispatchSaveRoleSidePanelFailure);
    };
}

export function closeAssignUserRoleSidePanel() {
    return (dispatch) => {
        dispatch(closeBox(ASSIGN_USERROLE_SIDEPANEL_BOX_CONTENT));
        dispatch(assignUserRoleForm.actionCreators.reset());
    };
}

/**
 * Commits a user's decision to update the status of multiple abilities
 *   based on the users decision to proceed from `ToggleAbilityModal`.
 * @param  {number}  abilityIds The IDs of abilities which we'll change
 *   the `isEnabled` value of.
 * @param  {boolean} newVal     The new value (true or false) for the
 *   `isEnabled` property of the given abilities
 * @return {undefined}
 */
export function commitAbilityChange(abilityIds, newVal) {
    return (dispatch) => {
        // regardless of which modal was open, we're going to close both
        dispatch(closeBox({ name: boxEnum.TOGGLE_ABILITY_MODAL }));
        forEach(abilityIds, (abilityId) =>
            dispatch(
                editRoleAbilitiesEnabledForm.actionCreators.changePath(
                    `abilities[${abilityId}].isEnabled`,
                    newVal
                )
            )
        );
    };
}

/**
 * Ensure that depended / dependent abilities have their values changed appropriately.
 *   If there are depended / dependent abilities, the user is presented a modal to confirm
 *   that they are okay with changing the enabled/disabled values of dependent / depended
 *   abilities as well. If there are no depended / dependent abilities, the value change
 *   that the user requested is performed right away.
 * @param {number}  abilityId The id of the ability the user is trying to change.
 * @param {boolean} newVal    The value (enabled or disabled) that the user is trying
 *   to change the ability to.
 * @return {[type]}             [description]
 */
export function confirmAbilityChange(abilityId, newVal) {
    return (dispatch, getState) => {
        const allAbilities = abilityViewModelsSelector(getState());
        const ability = allAbilities[abilityId];
        const abilityIsEnabledPath = `abilities[${abilityId}].isEnabled`;
        const abilitiesEnabledFormState = editRoleAbilitiesEnabledForm.selectors.formModelByPathSelector(
            getState()
        )('abilities');
        // we decide whether or not we're looking up dependent or depended abilities
        // based on whether the user is disabling or enabling an ability
        const abilityGatherer = newVal ? getDependedAbilities : getDependentAbilities;
        // the same idea applies for deciding whether to filter for or reject
        // enabled abilities
        const prune = newVal ? reject : filter;

        const abilities = abilityGatherer(ability, allAbilities);
        const prunedAbilities = prune(
            abilities,
            (ability) => abilitiesEnabledFormState[ability.id].isEnabled
        );
        if (prunedAbilities.length) {
            dispatch(
                openBox(
                    { name: boxEnum.TOGGLE_ABILITY_MODAL },
                    { abilityId, abilities: prunedAbilities, newVal }
                )
            );
        } else {
            dispatch(
                editRoleAbilitiesEnabledForm.actionCreators.changePath(abilityIsEnabledPath, newVal)
            );
        }
    };
}

/**
 * Load all data required for the admin roles page to operate.
 * @method loadData
 * @param  {[type]} roleId
 * @return {[type]} [description]
 */
export function loadData(roleId) {
    return (dispatch, getState) => {
        dispatch(loadDataStart());
        return Promise.all([
            dispatch(loadUserRolesForRole(roleId)),
            dispatch(loadAbilityRoleLinksForRole(roleId)),
            dispatch(loadAbilities()),
            dispatch(loadRoleApprovalForRole(roleId)),
        ])
            .spread((userRoles, abilityRoleLinks, abilities, roleApproval) => {
                dispatch(editRoleUsersManageStatusForm.actionCreators.change(userRoles));
                dispatch(
                    editRoleAbilitiesEnabledForm.actionCreators.change(abilities, abilityRoleLinks)
                );
                dispatch(
                    editRoleAbilitiesModulesForm.actionCreators.change(
                        modulesSelector(getState()),
                        // we get abilities via this selector because when they come from
                        // state they are keyed which makes them easier to consume downstream
                        abilitiesSelector(getState()),
                        abilityRoleLinks
                    )
                );
                dispatch(editRoleApprovalsForm.actionCreators.change(roleApproval));
                dispatch(loadDataSuccess());
            })
            .catch((err) => {
                dispatch(loadDataFailure(err.message));
                throw err;
            });
    };
}

/**
 * Remove a user from a role, handled downstream by deleting the provided userRole.
 * @return {undefined}
 */
export function removeUserFromRole(userRole) {
    return (dispatch) => {
        dispatch(removeUserFromRoleStart());
        dispatch(deleteUserFromRole(userRole))
            .then(() => dispatch(removeUserFromRoleSuccess()))
            .catch((err) => dispatch(removeUserFromRoleFailure(err.message)));
    };
}

/**
 * Given a users intention to give or take manage permissions from a user for a given role,
 *   make a remote call to persist this intention. Regardless of the success or failure of
 *   this action, the form state for `editRoleUsersManageStatusForm` will be re-synced with
 *   our local understanding (based on `userRoles` kept in `state.data`) of which users have
 *   manage permissions.
 * @return {undefined}
 */
export function setUserCanManage(userRole, canManage) {
    return (dispatch, getState) => {
        dispatch(setUserCanManageStart());
        dispatch(updateUserRole({ ...userRole, isUserAdmin: canManage }))
            .then((userRole) => {
                dispatch(setUserCanManageSuccess(userRole));
            })
            .catch((err) => {
                dispatch(setUserCanManageFailure(err.message));
            })
            .finally(() => {
                const userRoles = userRolesWhereSelector(getState())({ roleId: userRole.roleId });
                dispatch(editRoleUsersManageStatusForm.actionCreators.change(userRoles));
            });
    };
}

/**
 * Save all abilities for the given role.
 * @return {undefined}
 */
export function saveAbilities() {
    return (dispatch, getState) => {
        const state = getState();
        const currentRoleId = currentEditingRoleIdSelector(state);
        const formState = editRoleAbilitiesEnabledForm.selectors.formModelByPathSelector(state)(
            'abilities'
        );
        const existingAbilityRoleLinks = abilityRoleLinksWhereSelector(state)({
            roleId: currentRoleId,
        });
        const indexedAbilityRoleLinks = keyBy(existingAbilityRoleLinks, 'abilityId');
        const newAbilityRoleLinks = _(formState)
            .filter('isEnabled')
            .map((formModel) => {
                if (indexedAbilityRoleLinks[formModel.id]) {
                    // an ability role link already exists for this ability,
                    // so send the existing model back up
                    return indexedAbilityRoleLinks[formModel.id];
                } else {
                    // an ability role link does not yet exist for this ability,
                    // so we must create a new one
                    return {
                        abilityId: formModel.id,
                        roleId: currentRoleId,
                    };
                }
            })
            .value();

        dispatch(saveAbilitiesStart(newAbilityRoleLinks));
        return dispatch(upsertAbilityRoleLinks(newAbilityRoleLinks))
            .then((abilityRoleLinks) => dispatch(saveAbilitiesSuccess(abilityRoleLinks)))
            .catch((err) => dispatch(saveAbilitiesFailure(err.message)));
    };
}

/**
 * Save RoleApproval for the current role
 * @return {undefined}
 */
export function saveApprovals() {
    return (dispatch, getState) => {
        const state = getState();
        const currentRoleApproval = currentRoleApprovalSelector(state);
        dispatch(
            editRoleApprovalsForm.actionCreators.submit((formModel) => {
                const updatedRoleApproval = {
                    ...currentRoleApproval,
                    ...formModel,
                    roleId: currentEditingRoleIdSelector(state),
                };
                dispatch(saveApprovalsStart(updatedRoleApproval));
                return dispatch(updateRoleApproval(updatedRoleApproval))
                    .then((roleApproval) => {
                        dispatch(saveApprovalsSuccess(roleApproval));
                        editRoleApprovalsForm.actionCreators.change(roleApproval);
                    })
                    .catch((err) => dispatch(saveApprovalsFailure(err.message)));
                // we catch with the identity function because an uncaught error
                // will bubble to the console but we have no submit behaviours we want
                // to implement if the form is invalid - invalid states are handled
                // automatically directly on fields
            })
        ).catch(identity);
    };
}

/**
 * Clears the filter text input which determines which abilities will be shown
 *   to the user
 * @return {undefined}
 */
export function clearAbilitiesFilter() {
    return (dispatch) =>
        dispatch(editRoleFilterAbilitiesForm.actionCreators.change({ filter: '' }));
}

// SELECTORS

/**
 * The base admin roles ui selector
 */
const rolesAdminUiSelector = (state) => state.ui.rolesAdmin;

/**
 * The ID (Role.id) of the role currently being edited.
 * @return {number}
 */
export const currentEditingRoleIdSelector = createSelector(
    rolesAdminUiSelector,
    ({ currentEditingRoleId }) => currentEditingRoleId
);

/**
 * The full `Role` model for the role that is currently being edited.
 * @return {object}
 */
export const currentEditingRoleSelector = createSelector(
    currentEditingRoleIdSelector,
    roleByIdSelector,
    (currentEditingRoleId, roleById) => (currentEditingRoleId ? roleById(currentEditingRoleId) : {})
);

/**
 * The height (in pixels) of the sticky content immediately below tabs under both the users
 *   and abilities tabs. This value is provided by <Measure/>
 * @return {number}
 */
export const stickyChildrenContainerHeightSelector = createSelector(
    rolesAdminUiSelector,
    ({ stickyChildrenContainerHeight }) => stickyChildrenContainerHeight
);

/**
 * AbilitieRoleLinks pertaining to the Role currently being viewed
 * @type {array}
 */
const currentRoleAbilityRoleLinksSelector = createSelector(
    abilityRoleLinkViewModelsWhereSelector,
    currentEditingRoleIdSelector,
    (abilityRoleLinkViewModelsWhere, currentEditingRoleId) =>
        abilityRoleLinkViewModelsWhere({
            roleId: currentEditingRoleId,
        })
);

/**
 * Returns the most recently updated AbilityRoleLink
 * @type {object}
 */
export const lastUpdatedAbilityRoleLinkSelector = createSelector(
    currentRoleAbilityRoleLinksSelector,
    (abilityRoleLinks) => _(abilityRoleLinks).sortBy('updatedDateUtc').last()
);

/**
 * Returns an array of objects each of which represents a module. Each of these
 *   objects also includes the abilities for the given module.
 * @type {[type]}
 */
export const moduleViewModelsSelector = createSelector(
    modulesSelector,
    abilitiesWhereSelector,
    (modules, abilitiesWhere) =>
        map(modules, (module) => {
            const abilities = abilitiesWhere({ moduleId: module.id });
            return {
                module,
                abilities:
                    module.id !== 4 // CORE
                        ? abilities
                        : sortCoreAbilities(abilities),
            };
        })
);

/**
 * Based on the current role being viewed and state available in the application
 *   this selector determines which users are relevant to the current role
 *   and returns an array of view models (which contain the mini user and user role
 *   for each user).
 * @return {array}
 */
const currentRoleUsersSelector = createSelector(
    currentEditingRoleIdSelector,
    userRoleViewModelsWhereSelector,
    miniUserViewModelsSelector,
    (currentEditingRoleId, userRoleViewModelsWhere, miniUserViewModels) =>
        _(userRoleViewModelsWhere({ roleId: currentEditingRoleId }))
            .map((userRoleViewModel) => {
                const miniUser = miniUserViewModels[userRoleViewModel.userId];
                if (!miniUser) {
                    logWarning('User has a UserRole but no miniUser', {
                        userId: userRoleViewModel.userId,
                    });
                    return false;
                }
                return {
                    userRole: userRoleViewModel,
                    miniUser: miniUserViewModels[userRoleViewModel.userId],
                };
            })
            .compact()
            .value()
);

/**
 * Filters the list of visible users based on the current user's input
 *   to the filter text field. Uses a very basic text match strategy.
 * @type {array}
 */
export const filteredCurrentRoleUsersSelector = createSelector(
    currentRoleUsersSelector,
    editRoleFilterUsersForm.selectors.formModelByPathSelector,
    (currentRoleUsers, formModelByPath) => {
        const filterValue = (formModelByPath('filter') || '').toLowerCase();
        if (filterValue.length < 2) {
            return currentRoleUsers;
        } else {
            return filter(currentRoleUsers, ({ miniUser }) => {
                if (!filterValue) {
                    return true;
                }
                const userSummaryString = compact([miniUser.first, miniUser.last, miniUser.badge])
                    .join(' ')
                    .toLowerCase();
                return includes(userSummaryString, filterValue);
            });
        }
    }
);

/**
 * Returns a boolean depending on the user's ability to create new users for current role. If the user 'canManage' or is a role Admin, return true. Otherwise, return false.
 */

export const canEditUserSelector = createSelector(
    rolesForUserSelector,
    currentUserIdSelector,
    currentEditingRoleIdSelector,
    roleByIdSelector,
    currentUserHasAbilitySelector,
    (rolesForUser, currentUserId, currentEditingRoleId, roleById, currentUserHasAbility) => {
        const userRolesForCurrentUser = rolesForUser(currentUserId);
        const currentEditingRole = roleById(currentEditingRoleId);
        const rolesUserCanEdit = filter(
            userRolesForCurrentUser,
            (roleLink) => roleLink.isUserAdmin
        );
        const canEditThisSpecificRole = some(
            rolesUserCanEdit,
            (link) => link.roleId === currentEditingRoleId
        );
        const canDeleteGlobalRoleEnrollment = currentUserHasAbility(
            abilitiesEnum.ADMIN.DELETE_GLOBAL_ROLE_ENROLLMENT
        );
        const hasRoleAdmin = canDeleteGlobalRoleEnrollment;
        const hasUserAdmin = canDeleteGlobalRoleEnrollment || canEditThisSpecificRole;
        const noEditUser = [RoleTypeEnum.DEPARTMENT.name, RoleTypeEnum.CREATOR.name];
        return hasRoleAdmin || (hasUserAdmin && !includes(noEditUser, currentEditingRole.roleType));
    }
);

/**
 * The value of the abilities filter input
 * @return {string}
 */
export const abilitiesFilterSelector = createSelector(
    editRoleFilterAbilitiesForm.selectors.formModelByPathSelector,
    (formModelByPath) => formModelByPath('filter') || ''
);

/**
 * The value of the enabled only checkbox
 * @return {string}
 */
export const abilitiesEnabledOnlySelector = createSelector(
    editRoleFilterAbilitiesForm.selectors.formModelByPathSelector,
    (formModelByPath) => formModelByPath('enabledOnly') || false
);

/**
 * Current users tab page number
 * @return {number}
 */
export const currentUsersPageSelector = createSelector(
    rolesAdminUiSelector,
    ({ currentUsersPageNumber }) => currentUsersPageNumber
);

/**
 * Sorts users for the users tab based on their last name, and refines the list
 *   of visible users to include only those that should appear given the user's
 *   selected page.
 * @return {array}
 */
export const usersInViewSelector = createSelector(
    filteredCurrentRoleUsersSelector,
    currentUsersPageSelector,
    (filteredCurrentRoleUsers, currentUsersPage) =>
        sortBy(filteredCurrentRoleUsers, 'miniUser.last').slice(
            currentUsersPage * USERS_PER_PAGE - USERS_PER_PAGE,
            currentUsersPage * USERS_PER_PAGE
        )
);

/**
 * The current RoleApproval
 * @type {object}
 */
export const currentRoleApprovalSelector = createSelector(
    currentEditingRoleIdSelector,
    roleApprovalViewModelsSelector,
    (currentEditingRoleId, roleApprovals) => roleApprovals[currentEditingRoleId]
);

/**
 * The height of the "action bar" (the fixed/sticky content under tabs but
 *   above a results table). Contains a filter text box and possibly other
 *   ui elements as well.
 * @type {[type]}
 */
export const actionBarHeightSelector = createSelector(
    rolesAdminUiSelector,
    ({ actionBarHeight }) => actionBarHeight
);

/**
 * Indicates whether or not data for the page is currently being loaded
 * @type {Boolean}
 */
export const isLoadingSelector = createSelector(rolesAdminUiSelector, ({ isLoading }) => isLoading);

/**
 * Reducer for Admin Roles UI State.
 */
export default function rolesAdminUiReducer(
    state = {
        currentEditingRoleId: 0,
        stickyChildrenContainerHeight: 0,
        currentUsersPageNumber: 1,
        actionBarHeight: 0,
        isLoading: true,
        departmentId: null,
        roleType: null,
    },
    action
) {
    switch (action.type) {
        case LOAD_DATA_START:
            return {
                ...state,
                isLoading: true,
            };
        case LOAD_DATA_SUCCESS:
        case LOAD_DATA_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case LOAD_DEPARTMENT_ROLES_START:
            return {
                ...state,
                isLoading: true,
                currentEditingRoleId: null,
            };
        case LOAD_DEPARTMENT_ROLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case LOAD_DEPARTMENT_ROLES_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case SET_CURRENT_USERS_PAGE_NUMBER:
            return {
                ...state,
                currentUsersPageNumber: action.payload,
            };
        case SET_ACTION_BAR_HEIGHT:
            return {
                ...state,
                actionBarHeight: action.payload,
            };
        case SET_CURRENT_EDITING_ROLE_ID:
            return {
                ...state,
                currentEditingRoleId: action.payload,
            };
        case SET_STICKY_CHILDREN_CONTAINER_HEIGHT:
            return {
                ...state,
                stickyChildrenContainerHeight: action.payload,
            };
        case CREATE_ROLE_START:
            return {
                ...state,
                isLoading: true,
            };
        case CREATE_ROLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case CREATE_ROLE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import stringsConfig from '~/client-common/core/strings';
import Card, { CardSection, CardMiniSectionHeader } from '../core/Card';
import { FileGrid } from '../core/Files';
import { getFilePath } from '../../helpers/fileHelper';
import testIds from '../../../core/testIds';
import { currentReportCardUITitleByTypeSelector } from '../../selectors/reportSelectors';

const strings = stringsConfig.components.reports.DownloadablesCard;

export default function DownloadablesCard({ cardDetails }) {
    const downloads = _(cardDetails.dynamicProperties.downloadables)
        .map((downloadable) => ({
            id: downloadable.id,
            displayName: downloadable.displayName || '',
            path: getFilePath(downloadable.file),
        }))
        .sortBy(({ displayName }) => displayName.toLowerCase())
        .value();
    const currentReportCardUITitleByType = useSelector(currentReportCardUITitleByTypeSelector);
    const cardTitle = currentReportCardUITitleByType(cardDetails.id);
    const cardElement =
        downloads.length > 0 ? (
            <Card
                title={cardTitle}
                anchor="downloadables-card"
                summaryMode={true}
                testId={testIds.DOWNLOADABLES_CARD}
            >
                <CardSection>
                    <CardMiniSectionHeader text={strings.downloads} />
                    <FileGrid fileLinks={downloads} />
                </CardSection>
            </Card>
        ) : null;
    return <div>{cardElement}</div>;
}

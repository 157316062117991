import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import _Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import RecordsHeaderButton from '../../../records/core/components/header/RecordsHeaderButton';
import testIds from '../../../../core/testIds';

const strings = componentStrings.core.header.CaseHeaderReviewCaseButton;

const Button = styled(_Button)`
    margin: 0;
`;

function RecordsHeaderBookingButton({ className, onClick }) {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();
    return (
        <RecordsHeaderButton
            overlay={strings.tooltip(caseDisplayName)}
            testId={testIds.CASE_REVIEW_BUTTON}
        >
            <Button className={classNames(buttonTypes.SECONDARY, className)} onClick={onClick}>
                {strings.label}
            </Button>
        </RecordsHeaderButton>
    );
}

export default RecordsHeaderBookingButton;

import { SchoolHistory } from '@mark43/rms-api';
import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP = 'schoolHistories';
const schoolHistoriesModule = createGroupedModule<SchoolHistory>({
    type: NEXUS_STATE_PROP,
    key: 'personProfileId',
});

// SELECTORS
export const schoolHistoriesSelector = schoolHistoriesModule.selectors.entitiesSelector;
export const schoolHistoriesByPersonProfileIdSelector =
    schoolHistoriesModule.selectors.entitiesByKeySelector;

// REDUCER
export default schoolHistoriesModule.reducerConfig;

import { map, values } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tabs, Tab, TabList } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { casesTabEnum } from '../configuration';
import { BaseLinkWithAnalytics } from '../../../core/components/links/Link';
import { routerLocationSelector } from '../../../../routing/routerModule';
import testIds from '../../../../core/testIds';

const strings = componentStrings.cases.core.CasesHeader;

// the route paths under /cases/ to link to in the menu
const menuLinkPaths = values(casesTabEnum);

function CasesHeader({ location: { pathname } }) {
    const { pluralCaseFieldName: casesFieldName } = useCaseFieldName();

    // Get the second item in the array which is the portion after cases and before any other `/`
    const currentPath = pathname.match(/\/cases\/([^\/]+)/)?.[1];

    return (
        <Tabs value={currentPath} isManual>
            <TabList data-test-id={testIds.CASES_HEADER_MENU} style={{ border: 'none' }}>
                {map(menuLinkPaths, (path) => {
                    const fullPath = `/cases/${path}`;
                    return (
                        <Tab
                            tabIndex={0}
                            value={path}
                            as={BaseLinkWithAnalytics}
                            key={path}
                            to={fullPath}
                        >
                            {typeof strings[path] === 'function'
                                ? strings[path](casesFieldName)
                                : strings[path]}
                        </Tab>
                    );
                })}
            </TabList>
        </Tabs>
    );
}

const mapStateToProps = createStructuredSelector({
    location: routerLocationSelector,
});

/**
 * Header that appears on cases dashboard pages such as My Cases and Unassigned
 *   Cases. Don't confuse this with `CaseHeader`, which is the header for an
 *   individual case page.
 */
export default connect(mapStateToProps)(CasesHeader);

import { createField, createFormConfiguration, InferFormDataShape } from 'markformythree';
import { JmsApiBookingSearchRequest } from '@mark43/rms-api';
import {
    getIsoDateFromNDaysAgo,
    getIsoDateFromNHoursAgo,
    getIsoDateFromNMonthsAgo,
    nowUtc,
} from '~/client-common/core/dates/utils/dateHelpers';

type BookingTime = {
    withinLastPeriod?: string;
    toDatePeriod?: string;
    startDateUtc?: string;
    endDateUtc?: string;
};
export const searchBookingsFormConfiguration = createFormConfiguration({
    eventNumber: createField<string>({}),
    bookingNumber: createField<string>({}),
    bookingTime: createField<BookingTime>({}),
    bookingFacility: createField<number>({}),
    detaineeName: createField<string>({}),
    detaineeLastName: createField<string>({}),
    bookingOfficer: createField<number>({}),
    arrestingOfficer: createField<number>({}),
});

export type SearchBookingsFormConfiguration = typeof searchBookingsFormConfiguration;
type SearchBookingFormData = InferFormDataShape<SearchBookingsFormConfiguration>;

export const convertFromFormModel = (
    data: SearchBookingFormData
): Partial<JmsApiBookingSearchRequest> => {
    let bookingBeginDateTimeStart;
    let bookingBeginDateTimeEnd;
    const period = data.bookingTime?.withinLastPeriod || data.bookingTime?.toDatePeriod;
    if (period) {
        bookingBeginDateTimeEnd = nowUtc();
        switch (period) {
            case 'PT12H':
                bookingBeginDateTimeStart = getIsoDateFromNHoursAgo(12);
                break;
            case 'PT24H':
                bookingBeginDateTimeStart = getIsoDateFromNHoursAgo(24);
                break;
            case 'PT48H':
                bookingBeginDateTimeStart = getIsoDateFromNHoursAgo(48);
                break;
            case 'P7D':
                bookingBeginDateTimeStart = getIsoDateFromNDaysAgo(7);
                break;
            case 'P28D':
                bookingBeginDateTimeStart = getIsoDateFromNDaysAgo(28);
                break;
            case 'P1M':
                bookingBeginDateTimeStart = getIsoDateFromNMonthsAgo(1);
                break;
            case 'P1Y':
                bookingBeginDateTimeStart = getIsoDateFromNMonthsAgo(12);
                break;
            default:
                bookingBeginDateTimeEnd = undefined;
        }
    }
    return {
        ren: data.eventNumber,
        bookingOfficerId: data.bookingOfficer,
        arrestingOfficerId: data.arrestingOfficer,
        bookingFacilityId: data.bookingFacility,
        bookingNumber: data.bookingNumber,
        detaineeLastName: data.detaineeLastName,
        detaineeFirstName: data.detaineeName,
        bookingBeginDateTimeStart,
        bookingBeginDateTimeEnd,
    };
};

import React from 'react';
import { get, map } from 'lodash';
import styled from 'styled-components';
import { withHandlers } from 'recompose';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import { FormattedDate } from '~/client-common/core/dates/components';
import { formatWarrantTitle } from '~/client-common/helpers/warrantHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../../core/testIds';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';

const strings = componentStrings.warrants.connectWarrants;
const genericWarrantString = componentStrings.warrants.generic;
const WarrantBox = styled.div`
    background: ${(props) => props.theme.colors.extraLightGrey};
    padding: 15px 5px 15px 15px;
    margin: 6px 0;
    border: 1px solid ${(props) => props.theme.colors.mediumBlue};
    border-radius: 3px;
    font-size: var(--arc-fontSizes-md);
    cursor: pointer;

    &:hover {
        border: 1px solid ${(props) => props.theme.colors.cobaltBlue};
    }

    i {
        font-size: var(--arc-fontSizes-lg);
    }
`;

const WarrantTitle = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const WarrantObtainingInfo = styled.div`
    color: ${(props) => props.theme.colors.lightGrey};
`;

const WarrantCharges = styled.div`
    color: ${(props) => props.theme.colors.lightGrey};
    margin-top: 8px;
`;

const WarrantInfo = styled.div`
    width: 85%;
    display: inline-block;
`;

const WarrantArrow = styled(Icon)`
    float: right;
    color: ${(props) => props.theme.colors.cobaltBlue};
`;

const BackButton = styled(Button)`
    margin-bottom: 10px;
`;

const MoreResultsButton = styled(Button)`
    padding: 15px;
`;

const Warrant = withHandlers({
    onClickWarrantBox({ elasticWarrantViewModel, onClickWarrant }) {
        return () => {
            onClickWarrant(elasticWarrantViewModel.id);
        };
    },
})(function Warrant({ elasticWarrantViewModel, onClickWarrantBox, isExternal }) {
    const viewModelProps = getViewModelProperties(elasticWarrantViewModel);
    const subjectName = get(viewModelProps.subject, 'fullName');
    const warrantType = viewModelProps.warrantTypeAttrId;
    const { warrantNumber } = elasticWarrantViewModel;
    const title = formatWarrantTitle({ warrantNumber, warrantType, subjectName });
    return (
        <WarrantBox
            onClick={onClickWarrantBox}
            data-test-id={testIds.CONNECT_WARRANT_SIDE_PANEL_WARRANT_SEARCH_RESULT}
        >
            {/* excluding issuingAgencyOri, issuingAgencyName for now - see RMS-6240 */}
            <WarrantInfo>
                <WarrantTitle>{title}</WarrantTitle>
                {!isExternal && elasticWarrantViewModel.obtainingOfficerId && (
                    <FormattedDate
                        date={elasticWarrantViewModel.warrantIssuedDateUtc}
                        format={FormattedDate.FORMATS.FORM_DATE}
                    >
                        {(formattedDate) => (
                            <WarrantObtainingInfo>
                                {`${genericWarrantString.obtainedBy} ${viewModelProps.obtainingOfficerUserDisplay}, ${formattedDate}`}
                            </WarrantObtainingInfo>
                        )}
                    </FormattedDate>
                )}
                {viewModelProps.charges.length > 0 && (
                    <WarrantCharges>
                        {map(viewModelProps.charges, ({ id, offenseCode }) => {
                            return (
                                <div key={id}>
                                    <Icon type={iconTypes.CHARGE} />
                                    {offenseCode}
                                </div>
                            );
                        })}
                    </WarrantCharges>
                )}
            </WarrantInfo>
            <WarrantArrow type={iconTypes.NEXT} />
        </WarrantBox>
    );
});

export default function ConnectWarrantsResults({
    elasticWarrants,
    continuingSearch,
    onClickMoreResults,
    onClickBack,
    onClickWarrant,
    totalCount,
    warrantSearchPosition,
    currentUserDepartmentId,
}) {
    const showMoreResults = !continuingSearch && totalCount && warrantSearchPosition <= totalCount;
    const moreResults = showMoreResults ? (
        <MoreResultsButton onClick={onClickMoreResults} className={buttonTypes.SECONDARY}>
            {strings.moreResults}
        </MoreResultsButton>
    ) : undefined;
    return (
        <div>
            <BackButton
                iconLeft={iconTypes.OPEN_LEFT}
                className={buttonTypes.ICON_LINK}
                onClick={onClickBack}
            >
                {strings.back}
            </BackButton>
            {totalCount === 0 ? (
                <div>{strings.noResultsFound}</div>
            ) : (
                <div>
                    {map(elasticWarrants, (ew) => (
                        <Warrant
                            key={ew.id}
                            onClickWarrant={onClickWarrant}
                            elasticWarrantViewModel={ew}
                            isExternal={currentUserDepartmentId !== ew.departmentId}
                        />
                    ))}
                </div>
            )}
            {moreResults}
        </div>
    );
}

import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../../legacy-redux/components/core/Icon';
import testIds from '../../../../core/testIds';

const strings = componentStrings.reports.UcrClassificationSidePanel.PrefillButton;

const StyledPrefillButton = styled(Button)`
    border: 1px solid ${(props) => props.theme.colors.mediumBlue};
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    color: ${(props) => props.theme.colors.cobaltBlue};
    padding: 5px 7px;
    border-radius: 3px;
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    font-size: var(--arc-fontSizes-sm);

    &.disabled {
        border: 1px solid ${(props) => props.theme.colors.lightGrey};
        background: ${(props) => props.theme.colors.extraLightGrey};
        color: ${(props) => props.theme.colors.lightGrey};
    }
`;

export default function PrefillButton({ className, ...props }) {
    return (
        <StyledPrefillButton
            testId={testIds.PREFILL_BUTTON}
            className={classNames(buttonTypes.SECONDARY, className)}
            iconLeft={<Icon type={iconTypes.PULLDATA} />}
            children={strings.loadEventData}
            {...props}
        />
    );
}

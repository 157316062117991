import { EntityTypeEnum } from '@mark43/rms-api';
import { filter, map, size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose } from 'recompose';
import { withRouter } from 'react-router';

import { Text, HStack, Stack, Flex } from 'arc';
import { createStructuredSelector } from 'reselect';
import getUserNotificationsResource from '~/client-common/core/domain/user-notifications/resources/userNotificationsResource';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';
import componentStrings from '~/client-common/core/strings/componentStrings';
import getAttachmentsResource from '~/client-common/core/domain/attachments/resources/attachmentsResource';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { convertAttachmentsToAttachmentViewModel } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import Link from '../../core/components/links/Link';
import AttachmentsIndicator from '../../attachments/core/components/AttachmentsIndicator';
import { BannerIcon } from '../../../legacy-redux/components/alerts/Banner';
import { NUM_RECENT_ALERTS } from '../configuration';
import { DashboardLeftSidebarLoader } from './DashboardLoaders';
import { NoDataBlock, DashboardSideBarCard } from './DashboardSection';

const strings = componentStrings.personalDashboard.RecentAlerts;

function loadRecentAlertNotifications() {
    const resource = getUserNotificationsResource();
    const query = {
        includeWeb: false,
        includeAlerts: true,
        includeExpiredAlerts: false,
        from: 0,
        size: NUM_RECENT_ALERTS,
    };
    return resource.getCurrentUserNotifications(query, { hideLoadingBar: true });
}

const StyledBannerIcon = styled(BannerIcon)`
    height: 2rem;
    width: 2rem;
    flex-shrink: 0;
    border-radius: var(--arc-radii-base);
`;

function AlertNotification({ notification, attachments }) {
    const dataBlob = notification.dataBlob || {};
    return (
        <HStack align="start">
            <StyledBannerIcon bannerTypeAttrId={dataBlob.bulletinTypeAttrId} iconSize="md" />
            <Stack spacing="0" width="100%">
                <Flex justifyContent="space-between">
                    <Flex
                        flexWrap="wrap"
                        gridGap="var(--arc-space-2) var(--arc-space-1)"
                        align="center"
                    >
                        {dataBlob.title && <Text fontWeight="medium">{dataBlob.title}</Text>}
                    </Flex>
                </Flex>
                {dataBlob.text && (
                    <Text
                        lineHeight="var(--arc-lineHeights-shorter)"
                        noOfLines={2}
                        fontSize="md"
                        color="secondary"
                    >
                        {dataBlob.text}
                    </Text>
                )}
                <Flex
                    flexWrap="wrap"
                    gridGap="var(--arc-space-2) var(--arc-space-1)"
                    align="center"
                >
                    <Text fontSize="var(--arc-fontSizes-sm)" color="tertiary" lineHeight={1.75}>
                        <FormattedDate
                            format={FormattedDate.FORMATS.TABLE_DATE_TIME}
                            date={dataBlob.startDateUtc}
                        />
                    </Text>
                    {attachments && (
                        <AttachmentsIndicator
                            fontSize="var(--arc-fontSizes-md)"
                            attachmentViewModels={attachments}
                        />
                    )}
                </Flex>
            </Stack>
        </HStack>
    );
}

class RecentAlerts extends React.Component {
    state = {
        notifications: [],
        attachmentViewModels: [],
        error: false,
        loading: true,
    };

    componentDidMount() {
        loadRecentAlertNotifications()
            .then(({ notifications }) => {
                if (!size(notifications)) {
                    this.setState({
                        error: false,
                        loading: false,
                    });
                    return;
                }
                const notificationIds = map(notifications, 'notificationId');
                return getAttachmentsResource()
                    .loadAttachmentsByEntityId(EntityTypeEnum.NOTIFICATION.name, notificationIds, {
                        hideLoadingBar: true,
                    })
                    .then((attachments) =>
                        convertAttachmentsToAttachmentViewModel(
                            attachments,
                            this.props.formatMiniUserById
                        )
                    )
                    .then((attachmentViewModels) => {
                        this.setState({
                            notifications,
                            attachmentViewModels,
                            error: false,
                            loading: false,
                        });
                    });
            })
            .catch(() => {
                this.setState({
                    error: true,
                    loading: false,
                });
            });
    }

    render() {
        let content;
        if (this.state.loading) {
            content = <DashboardLeftSidebarLoader />;
        } else if (this.state.error) {
            content = <NoDataBlock>{strings.loadError}</NoDataBlock>;
        } else if (this.state.notifications.length === 0) {
            content = <NoDataBlock>{strings.noResults}</NoDataBlock>;
        } else {
            content = map(this.state.notifications, (notification) => (
                <AlertNotification
                    key={notification.userNotificationLinkId}
                    notification={notification}
                    attachments={filter(this.state.attachmentViewModels, {
                        entityId: notification.notificationId,
                    })}
                />
            ));
        }

        return (
            <DashboardSideBarCard
                dragHandleProps={this.props.dragHandleProps}
                isDragging={this.props.isDragging}
                title={strings.title}
                actions={<Link to="/notifications/alerts">{strings.viewAll}</Link>}
            >
                <Stack spacing="var(--arc-space-2)">{content}</Stack>
            </DashboardSideBarCard>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    formatMiniUserById: formatMiniUserByIdSelector,
});

/**
 * Recent Alerts section on the Personal Dashboard.
 */
export default compose(withRouter, connect(mapStateToProps))(RecentAlerts);

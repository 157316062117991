import moment from 'moment';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { isBefore } from '~/client-common/core/dates/utils/dateHelpers';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

// We are returning true if the stop date is before the cutover date to
// hide the new RIPA Fields added in RIPA V2
export const getIsBeforeCutoverDate = (getState: () => RootState) => (value: Date): boolean => {
    //    the value is the stop start date
    if (!value) {
        return true;
    }

    if (!(value instanceof Date)) {
        return true;
    }

    const stopStartDate = moment(value);

    const stopStartDateUtc = stopStartDate.toISOString();

    // Invalid date was entered. We can't assume that this RFG should pass.
    if (!stopStartDateUtc) {
        return false;
    }

    // Grab the cutoverDate from applicationSettings
    const state = getState();
    const cutoverDateStr = applicationSettingsSelector(state).RMS_RIPA_2024_CUTOVER_DATE;
    let isBeforeCutoverDate;
    if (typeof cutoverDateStr === 'string') {
        //    checks if stop start date is before cutover date
        isBeforeCutoverDate = isBefore(cutoverDateStr, stopStartDate);
    } else {
        //    no cutover date
        return true;
    }

    return isBeforeCutoverDate;
};

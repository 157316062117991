import { createSelector } from 'reselect';

import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getVehicleMakesResource from '../../resources/vehicleMakesResource';

export const NEXUS_STATE_PROP = 'vehicleMakes';
const vehicleMakesModule = createNormalizedModule({ type: NEXUS_STATE_PROP });

// ACTIONS
export const storeVehicleMakes = vehicleMakesModule.actionCreators.storeEntities;

// SELECTORS
export const vehicleMakesSelector = vehicleMakesModule.selectors.entitiesSelector;

const vehicleMakeByIdSelector = vehicleMakesModule.selectors.entityByIdSelector;

export const vehicleMakeNameByMakeIdSelector = createSelector(
    vehicleMakeByIdSelector,
    (vehicleMakeById) => (id) => vehicleMakeById(id)?.makeName
);

// REDUCER
export default vehicleMakesModule.reducerConfig;

export function searchForVehicleMakes({
    q,
    vehicleTypeCodeId,
    vehicleBodyStyleCodeId,
    yearOfManufacture,
    from,
    size,
}) {
    const vehicleMakesResource = getVehicleMakesResource();

    return () =>
        vehicleMakesResource.searchForVehicleMakes({
            q,
            vehicleTypeCodeId,
            vehicleBodyStyleCodeId,
            yearOfManufacture,
            from,
            size,
        });
}

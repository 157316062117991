import { EntityTypeEnum, EntityTypeEnumType, Folder } from '@mark43/rms-api';
import { createContext } from 'react';

export type SelectedRowType = {
    rowId: number;
    isFolder: boolean;
    isEditable: boolean;
    entityType: typeof EntityTypeEnum.ATTACHMENT.name | typeof EntityTypeEnum.CASE_NOTE.name;
};

interface Element {
    id: number;
}

interface SelectAllClickProps {
    elements: Element[];
    idKey: string;
    folders: Folder[];
    entityType: EntityTypeEnumType;
}

export type HandleRowSelectType = (
    rowId: number,
    isSelected: boolean,
    isFolder: boolean,
    isEditable: boolean,
    entityType: EntityTypeEnumType
) => void;

type SelectedRowsContextProps = {
    selectedRows: SelectedRowType[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<SelectedRowType | []>>;
    areAllElementsSelected?: boolean;
    handleRowSelect?: HandleRowSelectType;
    isRowSelected?: (id: number | undefined) => boolean;
    selectAllClick?: (props: SelectAllClickProps) => void;
};

export const SelectedRowsContext = createContext<SelectedRowsContextProps>({ selectedRows: [] });

import React from 'react';
import styled from 'styled-components';

import { EntityTypeEnum } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import { FormattedDate } from '~/client-common/core/dates/components';
import { BodyMediumText } from '../../../core/components/typography';
import { ExternalLink } from '../../../core/components/links/Link';
import TruncatedText from '../../../core/components/TruncatedText';
import Icon, { iconTypes } from '../../../core/components/Icon';
import Checkbox from '../../../core/forms/components/checkboxes/Checkbox';
import testIds from '../../../../core/testIds';
import { formatFileSize } from '../../../attachments/files/helpers';
import { getCaseFolderPath } from '../../../cases/core/utils/getCaseFolderPath';

const strings = componentStrings.core.ExportAttachmentsSidePanel.AttachmentRow;

const AttachmentRowContainer = styled.div`
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.extraLightGrey};
`;

const AttachmentRowInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ModifiedCheckbox = styled(Checkbox)`
    margin-right: 5px;
`;

const FileDescription = styled(BodyMediumText)`
    padding-top: 5px;
    white-space: pre-line;
`;

function AttachmentFolderRow({ folderView: { folder }, formatMiniUserById }) {
    return (
        <AttachmentRowInfoContainer>
            <BodyMediumText>
                <ExternalLink
                    openInNewTab={true}
                    to={`/#${getCaseFolderPath({
                        entityType: EntityTypeEnum.ATTACHMENT.name,
                        caseId: folder.ownerId,
                        folderId: folder.id,
                    })}`}
                >
                    <Icon color="cobaltBlue" size={18} type={iconTypes.CASE} /> {folder.name}
                </ExternalLink>
            </BodyMediumText>
            <BodyMediumText isItalic color="tertiary">
                <FormattedDate
                    date={folder.updatedDateUtc}
                    format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                />{' '}
                {strings.uploadedBy(formatMiniUserById(folder.updatedBy))}
            </BodyMediumText>
        </AttachmentRowInfoContainer>
    );
}

export const AttachmentRow = React.memo(function AttachmentRow({
    attachment,
    onAttachmentToggle,
    isSelected,
    formatMiniUserById,
}) {
    const file = getAttachmentFile(attachment);
    return (
        <AttachmentRowContainer>
            <ModifiedCheckbox
                testId={testIds.EXPORTS_ATTACHMENTS_SIDE_PANEL_CHECKBOX}
                value={isSelected}
                onChange={() => onAttachmentToggle(attachment, !isSelected)}
            />
            {attachment.folder ? (
                <AttachmentFolderRow
                    folderView={attachment}
                    formatMiniUserById={formatMiniUserById}
                />
            ) : (
                <AttachmentRowInfoContainer>
                    <BodyMediumText>
                        <ExternalLink openInNewTab={true} to={file.fileWebServerPath}>
                            {file.originalFileName}
                        </ExternalLink>
                    </BodyMediumText>
                    <BodyMediumText isItalic color="tertiary">
                        <FormattedDate
                            date={attachment.createdDateUtc}
                            format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                        />{' '}
                        {strings.uploadedBy(formatMiniUserById(attachment.createdBy))}
                        {file.contentLength > 0 ? ` (${formatFileSize(file.contentLength)})` : ''}
                    </BodyMediumText>
                    <FileDescription color="tertiary">
                        <TruncatedText text={file.description} />
                    </FileDescription>
                </AttachmentRowInfoContainer>
            )}
        </AttachmentRowContainer>
    );
});

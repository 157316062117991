import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import { Button as ArcButton } from './Button';
import { iconTypes } from './Icon';

const StyledAddLink = styled(Button)`
    padding: 10px 0 18px;
    font-weight: normal;
`;

// TODO: Once we globally enable ARC_RELEASE_CYCLE_ONE_COMPONENTS,
// we should delete this wrapper and just use the `ArcButton` directly
export default function AddEntityLink({ children, onClick, className, testId, setRef }) {
    return (
        <FeatureFlagged
            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
            fallback={
                <StyledAddLink
                    className={classNames(buttonTypes.ICON_LINK, className)}
                    iconLeft={iconTypes.ADD}
                    onClick={onClick}
                    testId={testId}
                    ref={setRef}
                >
                    {children}
                </StyledAddLink>
            }
        >
            <ArcButton
                leftIcon="Add"
                variant="ghost"
                onClick={onClick}
                testId={testId}
                ref={setRef}
            >
                {children}
            </ArcButton>
        </FeatureFlagged>
    );
}

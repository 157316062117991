import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { max } from 'lodash';

import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { NEXUS_STATE_PROP as CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP } from '~/client-common/core/domain/chain-of-custodies/state/data';

import advancedSearchVehicles from '../state/ui';
import StaleQueryNotificationBar from '../../core/components/StaleQueryNotificationBar';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import ImageCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/ImageCell';
import VehicleCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/VehicleCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/IdentifiersCell';
import OwnerCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/OwnerCell';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import { savedSearchIsStale } from '../../core/utils/savedSearchIsStale';
import { useDashboardScroll } from '../../core/hooks/useDashboardScroll';
import useOnSaveSearchToggleHandler from '../../core/hooks/useOnSaveSearchToggleHandler';
import chainOfCustodyCheckResource from '../../../evidence/chain-of-custody/resources/chainOfCustodyCheckResource';

const strings = componentStrings.search.AdvancedSearchVehiclesResults;

// 107 is the height of the table header
// todo refactor to genericize
const ClippableSearchTable = clippable({ bottomBuffer: 107 })(Table);

function AdvancedSearchVehiclesResults({
    // Current Query and Search Results
    results = [],
    query: { savedSearchId, from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,

    // Table UI State
    tableLoading,
    searchFilters,
    selectedRows,

    // Event Handlers
    handleRowClick,
    handleSortByOptionClick,
    handleSortBySortTypeClick,
    handlePaginationClick,
    handleSizeChange,
    handleRowSelect,

    // Saved Search
    savedSearchErrorMessage,
    savedSearches,
    selectableRows,
    router,
}) {
    const dispatch = useDispatch();
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: advancedSearchVehicles,
        isScrollToElement: true,
    });
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(advancedSearchVehicles);
    const idsToCheckChainOfEntities = [];
    results.forEach((item) => idsToCheckChainOfEntities.push(item.id));
    try {
        chainOfCustodyCheckResource
            .getChainOfCustody(idsToCheckChainOfEntities)
            .then((chainOfCustodies) => {
                const entitiesToStore = {
                    [CHAIN_OF_CUSTODIES_NEXUS_STATE_PROP]: chainOfCustodies,
                };
                dispatch(withEntityItems(entitiesToStore, { type: 'GET_CHAIN_OF_CUSTODIES' }));
            });
    } catch (err) {
        throw new Error('Failed to check chain of custodies');
    }

    return (
        <div>
            {savedSearchIsStale(savedSearches, savedSearchId) && <StaleQueryNotificationBar />}
            <ClippableSearchTable
                data={results}
                onRowClick={handleRowClick(getCurrentScrollPosition)}
                sortKey={currentSortKey}
                sortType={currentSortType}
                noRowsText={strings.noResults}
                selectableRows={selectableRows}
                onSelectRow={handleRowSelect}
                selectedRows={selectedRows}
                disableBody={tableLoading}
                containerClassName="search-results-table"
                rowComponent={ElasticResultRow}
                tableCheckboxMargin="10px"
            >
                <TableHeader>
                    <AdvancedSearchResultsTableHeader
                        from={from}
                        to={from + results.length}
                        searchSize={size}
                        onSizeChange={handleSizeChange}
                        totalResults={totalResults}
                        currentPage={currentPage}
                        searchFilters={searchFilters}
                        onPaginationClick={(lastPage, nextPage) =>
                            handlePaginationClick(nextPage, size)
                        }
                        isSearchSavable={true}
                        onSaveSearchToggleClick={onSaveSearchToggle}
                        savedSearchId={savedSearchId}
                        savedSearchErrorMessage={savedSearchErrorMessage}
                        savedSearches={savedSearches}
                        onSaveSearch={onSaveSearchToggle}
                        searchModule={advancedSearchVehicles}
                    />
                    <TableColumns>
                        <OptionsTableColumn
                            display={strings.sortBy.label}
                            columnKey="profilePhoto"
                            activeValue={currentSortKey}
                            activeSortType={currentSortType}
                            onOptionClick={handleSortByOptionClick}
                            onSortTypeClick={handleSortBySortTypeClick}
                            width={130}
                            popoutWidth={160}
                        >
                            <OptionsTableColumnOption
                                display={strings.relevance.label}
                                value={sortKeyEnum.RELEVANCE}
                            />
                            <OptionsTableColumnOption
                                display={strings.modifiedDate.label}
                                value={sortKeyEnum.VEHICLE_UPDATED_DATE_UTC}
                                sortOptions={[
                                    {
                                        display: strings.modifiedDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.modifiedDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={strings.vehicle.label}
                            columnKey="vehicle"
                            width={266}
                        />
                        <TableColumn
                            display={strings.identifiers.label}
                            columnKey="identifiers"
                            width={266}
                        />
                        <TableColumn display={strings.owner.label} columnKey="owner" width={266} />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <ImageCell columnKey="profilePhoto" />
                    <VehicleCell columnKey="vehicle" />
                    <IdentifiersCell columnKey="identifiers" />
                    <OwnerCell columnKey="owner" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    query: advancedSearchVehicles.selectors.currentQuerySelector,
    results: advancedSearchVehicles.selectors.currentResultsViewModelsSelector,
    totalCount: advancedSearchVehicles.selectors.totalCountSelector,
    tableLoading: advancedSearchVehicles.selectors.tableLoadingSelector,
    searchFilters: advancedSearchVehicles.selectors.filtersSelector,
    selectedRows: advancedSearchVehicles.selectors.selectedRowsSelector,
    savedSearchErrorMessage: advancedSearchVehicles.selectors.loadSavedSearchErrorMessageSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleRowClick(getCurrentScrollPosition) {
        return (elasticVehicle, rowIndex) => {
            dispatch(
                advancedSearchVehicles.actionCreators.openSearchResult(
                    elasticVehicle,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleRowSelect(newSelectedRows) {
        dispatch(advancedSearchVehicles.actionCreators.selectRows(newSelectedRows));
    },
    handleSortByOptionClick({ value, sortOptions }) {
        dispatch(
            advancedSearchVehicles.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleSortBySortTypeClick(sortType) {
        dispatch(advancedSearchVehicles.actionCreators.search({ sortType }));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            advancedSearchVehicles.actionCreators.search({ from: nextPage * size - size, size })
        );
    },
    handleSizeChange(size) {
        dispatch(advancedSearchVehicles.actionCreators.search({ from: 0, size }));
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearchVehiclesResults);

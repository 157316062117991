import { AgencyProfile, FormEnum } from '@mark43/rms-api';
import { PreferenceLink } from '@mark43/cad-api';
import {
    applyTimeZoneToIsoDate,
    isoTimeGetFirstMomentOfMonthRegardlessTimezone,
} from '~/client-common/core/dates/utils/dateHelpers';
import {
    AGENCY_PROFILE_AGENCY_CODE,
    AGENCY_PROFILE_AGENCY_NAME,
    AGENCY_PROFILE_AGENCY_ORI,
    AGENCY_PROFILE_NIBRS_START_DATE_UTC,
    AGENCY_PROFILE_COUNTY_INDICATOR,
} from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

interface AgencyPreferences {
    dispositionRequired: boolean;
    eventBasedMultiRenGen?: PreferenceLink;
    unitBasedMultiRenGen?: PreferenceLink;
}

interface MultiRenGenSettings {
    callForServiceIds: (number | string)[];
    unitStatusIds: (number | string)[];
}

interface AgencyProfileFormModel {
    agencyProfile?: Partial<AgencyProfile>;
    agencyPreferences?: Partial<AgencyPreferences>;
    multiRenGenSettings?: Partial<MultiRenGenSettings>;
}

const agencyProfileAdminFormFieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'agencyProfile.agencyName',
        fieldName: AGENCY_PROFILE_AGENCY_NAME,
    },
    {
        key: 'agencyProfile.agencyCode',
        fieldName: AGENCY_PROFILE_AGENCY_CODE,
    },
    {
        key: 'agencyProfile.agencyOri',
        fieldName: AGENCY_PROFILE_AGENCY_ORI,
    },
    {
        key: 'agencyProfile.countyIndicator',
        fieldName: AGENCY_PROFILE_COUNTY_INDICATOR,
    },
    {
        key: 'agencyProfile.nibrsStartDateUtc',
        fieldName: AGENCY_PROFILE_NIBRS_START_DATE_UTC,
    },
    {
        key: 'agencyProfile.agencyTypeAttrId',
    },
    {
        key: 'agencyProfile.agencyAbbreviation',
    },
    {
        key: 'agencyProfile.rmsDepartmentId',
    },
    {
        key: 'agencyProfile.rmsAgencyId',
    },
    {
        key: 'agencyProfile.isExternal',
    },
    {
        key: 'agencyPreferences.dispositionRequired',
    },
    {
        key: 'agencyPreferences.eventBasedMultiRenGen',
    },
    {
        key: 'agencyPreferences.unitBasedMultiRenGen',
    },
    {
        key: 'multiRenGenSettings.callForServiceIds',
    },
    {
        key: 'multiRenGenSettings.unitStatusIds',
    },
    {
        key: 'agencyProfile.agencyProfileLogoId',
    },
    {
        key: 'agencyProfile.removedAgencyProfileLogoId',
    },
    {
        key: 'agencyProfile.reportingDistrict',
    },
]);

function convertToFormModel(
    agencyProfile: AgencyProfile,
    agencyPreferences: AgencyPreferences,
    multiRenGenSettings: MultiRenGenSettings
) {
    const nibrsStartDateUtc = agencyProfile.nibrsStartDateUtc
        ? isoTimeGetFirstMomentOfMonthRegardlessTimezone(agencyProfile.nibrsStartDateUtc)
        : undefined;

    return {
        agencyProfile: {
            ...agencyProfile,
            nibrsStartDateUtc,
        },
        agencyPreferences,
        multiRenGenSettings,
    };
}

function convertFromFormModel(formModel: AgencyProfileFormModel, timeZone: string) {
    const { agencyProfile, agencyPreferences, multiRenGenSettings } = formModel;

    const nibrsStartDateUtc = agencyProfile?.nibrsStartDateUtc
        ? applyTimeZoneToIsoDate(agencyProfile.nibrsStartDateUtc, timeZone)
        : undefined;

    return {
        agencyProfile: {
            ...agencyProfile,
            nibrsStartDateUtc,
        },

        agencyPreferences,
        multiRenGenSettings,
    };
}

/**
 * Module of the agency profiles admin form.
 * @type {Object}
 */
// @ts-expect-error client-common to client RND-7529
export default createFormModule({
    formName: FormEnum.ADMIN_AGENCY_PROFILE.name,
    fieldViewModels: agencyProfileAdminFormFieldViewModels,
    convertFromFormModel,
    convertToFormModel,
});

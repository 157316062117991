import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';

import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import StaffRemarksCell from '../../../../legacy-redux/components/core/tables/elasticEvidenceTable/StaffRemarksCell';

// table cell that contains WideTable, 560px is the sum of the 2 column widths
const WideCell = styled.td`
    display: inline-block;
    padding: 0;
    width: 700px;
`;

// nested table inside WideCell containing the cells at the right within an evidence item search
// result along with a special wide cell for staff remarks below them (the staff remarks are what
// necessitate this nested table)
const WideTable = styled.table`
    width: 100%;
`;

function EvidenceDashboardSearchResultRow({
    currentUserHasAbility,
    row,
    children,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
}) {
    const rowProps = { className, onClick, onMouseEnter, onMouseLeave };

    if (
        _.size(row.staffRemarks) > 0 &&
        currentUserHasAbility(abilitiesEnum.EVIDENCE.VIEW_STAFF_REMARKS)
    ) {
        const [checkboxCell, infoCell, descriptionCell, locationCell] = React.Children.toArray(
            children
        );

        return (
            <ElasticResultRow row={row} {...rowProps}>
                {checkboxCell}
                {infoCell}
                <WideCell colSpan={2}>
                    <WideTable>
                        <tbody>
                            <tr>
                                {descriptionCell}
                                {locationCell}
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <StaffRemarksCell {...row} />
                                </td>
                            </tr>
                        </tbody>
                    </WideTable>
                </WideCell>
            </ElasticResultRow>
        );
    } else {
        return (
            <ElasticResultRow row={row} {...rowProps}>
                {children}
            </ElasticResultRow>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    currentUserHasAbility: currentUserHasAbilitySelector,
});

/**
 * A single row for an evidence item search result in the evidence dashboard. If the evidence item
 *.  has staff remarks, then the layout of this row becomes different to show the staff remarks
 *.  inside a nested table.
 */
export default connect(mapStateToProps)(EvidenceDashboardSearchResultRow);

import React from 'react';
import styled from 'styled-components';
import { AttributeTypeEnum } from '@mark43/rms-api';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { chain, get, filter } from 'lodash';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatTitleForElasticVehicleSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';

import { VehicleEnhancedCautions } from '../../../../../modules/core/cautions/components/VehicleEnhancedCautions';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';
import { VehicleLabels } from '../../../../../modules/core/components/tags/VehicleLabels';

const AgencyContainer = styled.div`
    margin-top: 10px;
`;

const AgencyText = styled.span`
    color: ${(props) => props.theme.colors.lightGrey};
    text-transform: uppercase;
    font-size: var(--arc-fontSizes-md);
`;

function VehicleCell({
    formatTitleForElasticVehicle,
    elasticVehicle,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) {
    const { departmentId, updatedDateUtc, itemAttributes, isStolen, cautions } = elasticVehicle;
    const vehicleViewModel = getViewModelProperties(elasticVehicle);
    const { primaryColorAttrId } = vehicleViewModel;

    const mostRecentPropertyStatus = chain(elasticVehicle.propertyStatuses)
        .sortBy('statusDateUtc')
        .last()
        .value();

    const statusDateUtc = get(mostRecentPropertyStatus, 'statusDateUtc');
    const { propertyStatusAttrId } = getViewModelProperties(mostRecentPropertyStatus);

    const isExternalAgency = departmentId !== currentUserDepartmentId;
    const departmentName = isExternalAgency
        ? departmentNameFromConsortiumLinksByDepartmentId(departmentId)
        : undefined;

    return (
        <div className="elastic-vehicle-info">
            <div className="elastic-vehicle-info-content">
                <div className="elastic-vehicle-make-and-model">
                    {formatTitleForElasticVehicle(elasticVehicle)}
                </div>
                <div className="elastic-vehicle-primary-color">{primaryColorAttrId}</div>
                <div className="elastic-vehicle-property-status">
                    {propertyStatusAttrId && (
                        <div className="elastic-property-status-info">
                            {propertyStatusAttrId}
                            {statusDateUtc ? ' on ' : ''}
                            <FormattedDate
                                date={statusDateUtc}
                                format={FormattedDate.FORMATS.FORM_DATE}
                            />
                        </div>
                    )}
                </div>
                <div className="elastic-vehicle-modified-date-container">
                    <span className="elastic-vehicle-modified-text">Modified: </span>
                    <span className="elastic-vehicle-modified-date">
                        <FormattedDate
                            date={updatedDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    </span>
                </div>

                <FeatureFlagged
                    flag="RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <FeatureFlagged flag="RMS_VEHICLE_CAUTIONS_ENABLED">
                            <VehicleLabels
                                elasticItemAttributes={filter(itemAttributes, {
                                    itemAttrDetail: {
                                        type: AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
                                    },
                                })}
                                isStolen={isStolen}
                                size="md"
                            />
                        </FeatureFlagged>
                    }
                >
                    <VehicleEnhancedCautions cautions={cautions} isStolen={isStolen} size="sm" />
                </FeatureFlagged>
                {departmentName && (
                    <AgencyContainer>
                        <AgencyText>Agency: </AgencyText>
                        <span>{departmentName}</span>
                    </AgencyContainer>
                )}
            </div>
        </div>
    );
}

export default compose(
    mapProps((ownerProps) => ({ elasticVehicle: ownerProps })),
    connect(
        createStructuredSelector({
            formatTitleForElasticVehicle: formatTitleForElasticVehicleSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(VehicleCell);

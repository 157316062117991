import React from 'react';
import styled from 'styled-components';
import { SavedSearch } from '@mark43/rms-api';
import { Text } from 'arc';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

const AdvancedSearchSection = styled.div`
    padding: 0 20px;
`;

const DashboardSection = styled.div`
    margin-bottom: 10px;
`;

interface SearchNameProps {
    currentSavedSearch: SavedSearch | null;
    isAdvancedSearch: boolean;
}

export const SearchName = ({ currentSavedSearch, isAdvancedSearch }: SearchNameProps) => {
    if (currentSavedSearch) {
        const Wrapper = isAdvancedSearch ? AdvancedSearchSection : DashboardSection;
        return (
            <FeatureFlagged flag="RMS_SAVED_SEARCH_SHARING_ENABLED">
                <Wrapper>
                    <Text variant="headingMd">{currentSavedSearch.name}</Text>
                </Wrapper>
            </FeatureFlagged>
        );
    }
    return null;
};

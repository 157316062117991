import React from 'react';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';

const context = {
    name: boxEnum.LOADING_MODAL,
};

export default function LoadingModal() {
    return (
        <Modal context={context} hideFooter={true}>
            <div className="mark43-loading-modal-content">
                <SimpleLoading />
                <div>{componentStrings.core.LoadingModal.loadingMessage}</div>
            </div>
        </Modal>
    );
}

import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { map, reduce } from 'lodash';

import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { loadFullWarrant } from '~/client-common/core/domain/warrants/state/data';
import { warrantViewModelByIdSelector } from '~/client-common/core/domain/warrants/state/ui';
import { dateSortedWarrantDexReturnsWhereSelector } from '~/client-common/core/domain/warrant-dex-returns/state/ui';
import { getWarrantDexReturns } from '~/client-common/core/domain/warrant-dex-returns/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { Button, ButtonGroup } from '../../../core/components/Button';
import { IconButton } from '../../../core/components/IconButton';

import Subheader from '../../../core/components/Subheader';
import _RecordsHeader from '../../../records/core/components/header/RecordsHeader';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import _Subsection, { Header } from '../../../core/components/Subsection';
import NoDataBlock from '../../../core/components/NoDataBlock';

const strings = componentStrings.warrants.warrant.WarrantDexReturns;

const Returns = styled.div`
    max-width: 650px;
    padding: 20px;
    white-space: pre-wrap;
`;

const ReturnText = styled.div`
    padding: 15px 0 15px 10px;
    word-wrap: break-word;
`;

const Subsection = styled(_Subsection)`
    ${/* sc-sel */ Header} {
        border-bottom: 1px solid ${(props) => props.theme.colors.mediumLightGrey};
        color: ${(props) => props.theme.colors.mediumLightGrey};
        font-style: italic;
    }
`;

const RecordsHeader = styled(_RecordsHeader)`
    width: auto;
`;

function printContent(content) {
    const html = `
        <html>
            <style type="text/css">
                html { white-space: pre-wrap; }
            </style>
            ${content}
        </html>`;

    const printWin = window.open();
    printWin.document.write(html);
    printWin.document.close();
    printWin.focus();
    printWin.print();
    printWin.close();
}

class WarrantDexReturns extends React.Component {
    componentDidMount() {
        this.props.loadWarrantDexReturns();
    }

    render() {
        const {
            warrantViewModelById,
            dateSortedWarrantDexReturnsWhere,
            loadWarrantDexReturns,
            reloadFullWarrant,
            params,
        } = this.props;

        const { warrantId: warrantIdFromRoute } = params;
        const warrantId = parseInt(warrantIdFromRoute);
        const warrantViewModel = warrantViewModelById(warrantId);

        if (!warrantViewModel) {
            return <div />;
        }
        const warrantDexReturns = dateSortedWarrantDexReturnsWhere({ warrantId });

        const { warrantShortTitle } = getViewModelProperties(warrantViewModel);
        return (
            <>
                <Subheader
                    title={warrantShortTitle}
                    backButtonTo={`/warrants/${warrantId}`}
                    onBackButtonClick={reloadFullWarrant}
                    content={
                        <RecordsHeader
                            subtitleParts={[{ name: '', value: strings.dataExchangeReturns }]}
                            departmentId={warrantViewModel.departmentId}
                        />
                    }
                >
                    <ButtonGroup>
                        <Button
                            isTextTransformNone
                            leadingVisual="Export"
                            onClick={() => {
                                const warrantDexReturnsString = reduce(
                                    warrantDexReturns,
                                    (result, dexReturn) => {
                                        return `${result}\n\n\n${dexReturn.returnText}`;
                                    },
                                    ''
                                );

                                printContent(warrantDexReturnsString);
                            }}
                        >
                            {strings.exportAll}
                        </Button>
                        <Button
                            isTextTransformNone
                            leadingVisual="Refresh"
                            onClick={loadWarrantDexReturns}
                        >
                            {strings.refresh}
                        </Button>
                    </ButtonGroup>
                </Subheader>
                <ScrollableUnderSubheader>
                    <Returns>
                        {warrantDexReturns.length > 0 ? (
                            map(warrantDexReturns, (dexReturn, i) => {
                                return (
                                    <Subsection
                                        key={i}
                                        title={
                                            <FormattedDate
                                                date={dexReturn.returnDateUtc}
                                                format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                                            />
                                        }
                                        actionButton={
                                            <IconButton
                                                icon="Export"
                                                variant="ghost"
                                                size="sm"
                                                onClick={() => {
                                                    printContent(dexReturn.returnText);
                                                }}
                                            />
                                        }
                                    >
                                        <ReturnText>{dexReturn.returnText}</ReturnText>
                                    </Subsection>
                                );
                            })
                        ) : (
                            <NoDataBlock>{strings.noReturns}</NoDataBlock>
                        )}
                    </Returns>
                </ScrollableUnderSubheader>
            </>
        );
    }
}
const mapStateToProps = createStructuredSelector({
    warrantViewModelById: warrantViewModelByIdSelector,
    dateSortedWarrantDexReturnsWhere: dateSortedWarrantDexReturnsWhereSelector,
});

const mapDispatchToProps = (dispatch, props) => {
    const warrantId = parseInt(props.params.warrantId);
    return {
        loadWarrantDexReturns: () => dispatch(getWarrantDexReturns(warrantId)),
        reloadFullWarrant: () => dispatch(loadFullWarrant(warrantId)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['warrantId', 'warrantViewModelById', 'currentUserHasAbility'],
        ({ warrantId, warrantViewModelById, currentUserHasAbility }) => ({
            canEdit:
                getViewModelProperties(warrantViewModelById(warrantId)).canWrite &&
                currentUserHasAbility(abilitiesEnum.WARRANTS.EDIT_GENERAL),
        })
    )
)(WarrantDexReturns);

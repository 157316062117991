import { useRef, useEffect, useReducer, useMemo, useCallback } from 'react';
import Konva from 'konva';
import { KonvaNodeEvents } from 'react-konva';
import { Box } from 'konva/lib/shapes/Transformer';
import { debounce } from 'lodash';
import { useFilePathForFileId } from '../../../../../../attachments/files/hooks/useFilePathForFileId';
import { CrashDiagramPosition, KonvaWidget } from '../types';
import {
    fetchSVGImage,
    getKonvaShapePosition,
    isSVGBase64DataUrl,
    formatFileName,
    calculateConstrainedPosition,
} from '../helpers';
import { useAssetCache, CRASH_DIAGRAM_ASSET_KEY } from '../context/AssetCacheContext';
import { STAGE_HEIGHT, STAGE_WIDTH_LARGE, DEFAULT_WIDGET_POSITION } from '../config';
import { fetchSVGFromUrlReducer } from './fetch-svg-from-url-reducer';
import { SearchState, searchReducer } from './search-assets-reducer';

const isKonvaImage = (
    target: Konva.KonvaEventObject<DragEvent | Event>['target']
): target is Konva.Image => {
    return target.getClassName() === 'Image';
};

const isKonvaText = (
    target: Konva.KonvaEventObject<DragEvent | Event>['target']
): target is Konva.Text => {
    return target.getClassName() === 'Text';
};

const isSupportedKonvaWidget = (
    target: Konva.KonvaEventObject<DragEvent | Event>['target']
): target is KonvaWidget => {
    return isKonvaText(target) || isKonvaImage(target);
};

const isOutOfBounds = (newBox: Box) => {
    const { x, y, width, height } = newBox;
    const rad = newBox.rotation;

    const point1 = getCorner(x, y, 0, 0, rad);
    const point2 = getCorner(x, y, width, 0, rad);
    const point3 = getCorner(x, y, width, height, rad);
    const point4 = getCorner(x, y, 0, height, rad);

    const minX = Math.min(point1.x, point2.x, point3.x, point4.x);
    const minY = Math.min(point1.y, point2.y, point3.y, point4.y);
    const maxX = Math.max(point1.x, point2.x, point3.x, point4.x);
    const maxY = Math.max(point1.y, point2.y, point3.y, point4.y);

    const isWithinHorizontalBounds = minX >= 0 && maxX <= STAGE_WIDTH_LARGE;
    const isWithinVerticalBounds = minY >= 0 && maxY <= STAGE_HEIGHT;

    return !isWithinHorizontalBounds || !isWithinVerticalBounds;
};

const getCorner = (pivotX: number, pivotY: number, diffX: number, diffY: number, angle: number) => {
    const distance = Math.sqrt(diffX * diffX + diffY * diffY);

    // Find angle from pivot to corner
    angle += Math.atan2(diffY, diffX);

    // Get new x and y and round it off to integer
    const x = pivotX + distance * Math.cos(angle);
    const y = pivotY + distance * Math.sin(angle);

    return { x, y };
};

type KonvaShapeEventsArgs = {
    onMoveWidgetEnd: (widgetId: string, position: CrashDiagramPosition) => void;
};

export const useKonvaShapeEvents = (args: KonvaShapeEventsArgs) => {
    const initalWidgetPosition = useRef<CrashDiagramPosition>();
    const { onMoveWidgetEnd } = args;

    const boundBoxFunc = (oldBox: Box, newBox: Box) => {
        const isOut = isOutOfBounds(newBox);

        // If new bounding box is out of visible viewport, then skip transforming
        if (isOut) {
            return oldBox;
        }
        return newBox;
    };

    const updateWidgetPositionInBounds = (konvaWidget: KonvaWidget) => {
        const position = getKonvaShapePosition(konvaWidget);

        const {
            x,
            y,
            width = DEFAULT_WIDGET_POSITION.width,
            height = DEFAULT_WIDGET_POSITION.height,
            scaleX = DEFAULT_WIDGET_POSITION.scaleX,
            scaleY = DEFAULT_WIDGET_POSITION.scaleY,
            rotation = DEFAULT_WIDGET_POSITION.rotation,
        } = position;

        const { x: newX, y: newY } = calculateConstrainedPosition(
            {
                width,
                height,
                scaleX,
                scaleY,
                rotation,
                x,
                y,
            },
            STAGE_WIDTH_LARGE,
            STAGE_HEIGHT
        );

        const updatedPosition = {
            ...position,
            x: newX,
            y: newY,
        };

        // Need to explicitly update the widget position to prevent it from being dragged out of bounds
        konvaWidget.setPosition(updatedPosition);

        updateInitialWidgetPosition(konvaWidget);
    };

    const updateInitialWidgetPosition = (konvaWidget: KonvaWidget) => {
        const position = getKonvaShapePosition(konvaWidget);
        initalWidgetPosition.current = position;
        return position;
    };

    const onDragMove: KonvaNodeEvents['onDragMove'] = (event) => {
        const shape = event.target;
        if (!isSupportedKonvaWidget(shape)) {
            return;
        }
        updateWidgetPositionInBounds(shape);
    };

    const onDragEnd: KonvaNodeEvents['onDragEnd'] = (event) => {
        const shape = event.target;
        if (!isSupportedKonvaWidget(shape)) {
            return;
        }
        const widgetId = shape.getAttr('id').toString();
        const currentPosition = initalWidgetPosition.current;
        const updatedPosition = updateInitialWidgetPosition(shape);
        if (widgetId && updatedPosition && currentPosition) {
            onMoveWidgetEnd(widgetId, updatedPosition);
        }
    };

    const onTransformStart: KonvaNodeEvents['onTransformStart'] = (event) => {
        const shape = event.target;
        if (!isSupportedKonvaWidget(shape)) {
            return;
        }
        updateInitialWidgetPosition(shape);
    };

    const onTransformEnd: KonvaNodeEvents['onTransformEnd'] = (event) => {
        const shape = event.target;
        if (!isSupportedKonvaWidget(shape)) {
            return;
        }
        const widgetId = shape.getAttr('id').toString();
        const currentPosition = initalWidgetPosition.current;
        const updatedPosition = updateInitialWidgetPosition(shape);
        if (widgetId && updatedPosition && currentPosition) {
            onMoveWidgetEnd(widgetId, updatedPosition);
        }
    };

    return {
        onDragMove,
        onDragEnd,
        onTransformStart,
        onTransformEnd,
        boundBoxFunc,
    };
};

type Args = {
    value?: string;
    assetKey?: string;
    onSuccess?: (svgDataUrl: string) => void;
};

export const useFetchSVGAsset = ({ value, assetKey, onSuccess }: Args) => {
    const { addAsset, getAsset } = useAssetCache();
    const svgAsset = assetKey && getAsset({ key: assetKey, type: 'SVG' });
    const svgDataUrl = assetKey && getAsset({ key: assetKey, type: 'SVG_DATA_URL' });
    const [state, dispatch] = useReducer(fetchSVGFromUrlReducer, {
        isLoading: false,
        svg: undefined,
        svgDataUrl: undefined,
        error: undefined,
    });

    useEffect(() => {
        const fetchAssetFromUrl = async (url: string) => {
            dispatch({ type: 'LOADING_START' });
            const svgImageResponse = await fetchSVGImage(url);
            if (!svgImageResponse) {
                dispatch({
                    type: 'LOADING_FAILURE',
                    payload: {
                        error: `Failed to fetch and convert image from url ${url}`,
                    },
                });
                return;
            }

            const { svgDataUrl, svgContents } = svgImageResponse;

            if (assetKey) {
                addAsset({
                    key: assetKey,
                    value: svgDataUrl,
                    type: 'SVG_DATA_URL',
                    replace: true,
                });
                addAsset({
                    key: assetKey,
                    value: svgContents,
                    type: 'SVG',
                    replace: true,
                });
            }

            dispatch({
                type: 'LOADING_SUCCESS',
                payload: {
                    svgDataUrl,
                    svg: svgContents,
                },
            });

            if (typeof onSuccess === 'function') {
                onSuccess(svgDataUrl);
            }
        };

        if (!value || !!svgAsset || !!svgDataUrl) {
            return;
        }

        // We only support two types of values for crash diagram assets;
        // A base64 string AND a url/path to the file. Currently the url/path
        // points to the assets being hosted on the web server, but this can
        // point to any remote service. This hook will be re-usable when it comes
        // time to move the assets to a remote service (ie: s3, rms-api) which is yet tbd.
        if (isSVGBase64DataUrl(value)) {
            dispatch({
                type: 'SET_SVG_DATA_URL',
                payload: {
                    svgDataUrl: value,
                },
            });
        } else {
            fetchAssetFromUrl(value);
        }
    }, [value, assetKey, addAsset, svgAsset, onSuccess, svgDataUrl]);

    return {
        ...state,
        svg: !!svgAsset ? svgAsset : state.svg,
        svgDataUrl: !!svgDataUrl ? svgDataUrl : state.svgDataUrl,
    };
};

export const useFetchCrashDiagramImageFile = (fileId?: number) => {
    const { fileWebServerPath } = useFilePathForFileId(fileId);
    return useFetchSVGAsset({
        value: fileWebServerPath,
        assetKey: CRASH_DIAGRAM_ASSET_KEY,
    });
};

export const useSearchAssets = (initialState: SearchState, ASSET_SEARCH_DELAY: number) => {
    const [state, dispatch] = useReducer(searchReducer, initialState);

    const debouncedSearch = useMemo(
        () =>
            debounce((term: string) => {
                const filtered = !!term
                    ? initialState.filteredAssets.filter(
                          (src) =>
                              formatFileName(src)
                                  .toLowerCase()
                                  .indexOf(term.trim().toLowerCase()) !== -1
                      )
                    : initialState.filteredAssets;

                dispatch({ type: 'SEARCH_SUCCESS', payload: { filteredAssets: filtered } });
            }, ASSET_SEARCH_DELAY),
        [initialState.filteredAssets, ASSET_SEARCH_DELAY]
    );

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        dispatch({ type: 'SEARCH_START', payload: { searchTerm: value } });
    }, []);

    useEffect(() => {
        if (state.searchTerm !== '') {
            debouncedSearch(state.searchTerm);
        } else {
            dispatch({
                type: 'SEARCH_SUCCESS',
                payload: { filteredAssets: initialState.filteredAssets },
            });
        }
        return () => {
            debouncedSearch.cancel();
        };
    }, [state.searchTerm, debouncedSearch, initialState.filteredAssets]);

    return {
        filteredAssets: state.filteredAssets,
        searchTerm: state.searchTerm,
        isLoading: state.isLoading,
        handleChange,
    };
};

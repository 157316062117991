import React, { useEffect, useState } from 'react';
import { compact } from 'lodash';
import { FilterMenuRenderProps, FilterMenuList } from 'arc';
import { SearchResultElasticStorageLocation } from '@mark43/evidence-api';
import { useFetchStorageLocations } from '../../../core/hooks/useFetchStorageLocations';

export const StorageLocationFilter: React.FC<FilterMenuRenderProps> = ({
    appliedOptions,
    setAppliedFilters,
}) => {
    const [storageLocations, setStorageLocations] = useState<
        SearchResultElasticStorageLocation['items']
    >([]);
    const { searchStorageLocations } = useFetchStorageLocations();
    useEffect(() => {
        searchStorageLocations({
            query: '',
            // @ts-expect-error TODO: type the resource method
        }).then((result: SearchResultElasticStorageLocation) => {
            if (result.items && result.items.length > 0) {
                setStorageLocations(result.items);
            }
        });
    });
    return (
        <FilterMenuList
            options={compact(
                storageLocations.map((item) => {
                    if (item.fullPath) {
                        return {
                            id: item.id,
                            value: String(item.id),
                            label: item.fullPath,
                        };
                    }
                    return undefined;
                })
            )}
            onSelect={setAppliedFilters}
            appliedOptions={appliedOptions}
            selectionType="multiple"
        />
    );
};

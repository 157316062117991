import React from 'react';
import { isUndefined, map, noop } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    compose,
    defaultProps,
    getContext,
    setPropTypes,
    withHandlers,
    withPropsOnChange,
} from 'recompose';
import classNames from 'classnames';
import { simpleControl } from 'markformythree';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';

import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { Button as _ArcButton } from '../../components/Button';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import testIds from '../../../../core/testIds';

const ArcButton = styled(_ArcButton)`
    margin-right: 10px;
`;

const { connectRRFInput } = reactReduxFormHelpers;

const ButtonRadioButtonComponent = ({ children, onClick, selected, style, value }) => {
    return (
        <FeatureFlagged
            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
            fallback={
                <Button
                    children={children}
                    className={classNames(buttonTypes.SECONDARY, { selected })}
                    onClick={onClick}
                    style={style}
                    testId={testIds.BUTTON_RADIO_BUTTON}
                    value={value}
                />
            }
        >
            <ArcButton
                children={children}
                onClick={onClick}
                variant={selected ? 'solid' : 'outline'}
                style={style}
                value={value}
                testId={testIds.BUTTON_RADIO_BUTTON}
            />
        </FeatureFlagged>
    );
};

// a single button in the radio group
const ButtonRadioButton = withHandlers({
    onClick({ onChange, value, selected, deselectable = true }) {
        return () => onChange(selected && deselectable ? null : value);
    },
})(function ButtonRadioButton({ children, onClick, selected, display, style }) {
    return children({
        children: display,
        onClick,
        selected,
        className: classNames(buttonTypes.SECONDARY, { selected }),
        style,
        testId: testIds.BUTTON_RADIO_BUTTON,
    });
});

const ButtonRadio = compose(
    setPropTypes({
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        gutterWidth: PropTypes.number,
    }),
    getContext({
        onInputChange: PropTypes.func,
        onInputFocus: PropTypes.func,
        onInputBlur: PropTypes.func,
    }),
    defaultProps({
        onChange: noop,
        onFocus: noop,
        onBlur: noop,
        onInputChange: noop,
        onInputFocus: noop,
        onInputBlur: noop,
    }),
    withPropsOnChange(
        [
            'onChange',
            'onInputChange',
            'onFocus',
            'onInputFocus',
            'onBlur',
            'onInputBlur',
            'gutterWidth',
        ],
        ({ onChange, onInputChange, onFocus, onInputFocus, onBlur, onInputBlur, gutterWidth }) => ({
            onChange(value) {
                // call the focus and blur handlers immediately since the radio
                // button does not have any real focus state
                onFocus(value);
                onInputFocus(value);
                onChange(value);
                onInputChange(value);
                onBlur(value);
                onInputBlur(value);
            },
            style: !isUndefined(gutterWidth) ? { marginRight: gutterWidth } : {},
        })
    )
)(function ButtonRadio({
    options,
    value,
    onChange,
    style,
    children = (props) => <ButtonRadioButtonComponent {...props} />,
    testId,
    deselectable,
    className,
}) {
    return (
        <div className={classNames(className, 'button-radio')} data-test-id={testId}>
            {map(options, (option) => (
                <ButtonRadioButton
                    key={option.value}
                    value={option.value}
                    selected={value === option.value}
                    onChange={onChange}
                    display={option.display}
                    style={style}
                    deselectable={deselectable}
                >
                    {children}
                </ButtonRadioButton>
            ))}
        </div>
    );
});

/**
 * A button radio group is a group of buttons where only zero or one may be
 *   selected.
 * @param {Object[]} options    Objects with properties `value` and `display`.
 * @param {number}   [gutterWidth] Margin spacing between buttons.
 * @param {function} [children] A function with signature `buttonProps =>
 *   ReactElement`, which will be called and rendered for each option in the
 *   radio group. Use this when you need to apply custom props on each button.
 */
export default ButtonRadio;

export const RRFButtonRadio = connectRRFInput(ButtonRadio);
export const MFTButtonRadio = simpleControl(ButtonRadio);

import { NameReportLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';
import getNameReportLinksResource from '../../resources/nameReportLinksResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'nameReportLinks';

const nameReportLinksModule = createLinkModule<NameReportLink>({
    type: NEXUS_STATE_PROP,
    keys: ['nameId', 'reportId', 'contextId', 'linkType'],
});

const DELETE_NAME_REPORT_LINK_START = 'name-report-links/DELETE_NAME_REPORT_LINK_START';
export const DELETE_NAME_REPORT_LINK_SUCCESS = 'name-report-links/DELETE_NAME_REPORT_LINK_SUCCESS';
const DELETE_NAME_REPORT_LINK_FAILURE = 'name-report-links/DELETE_NAME_REPORT_LINK_FAILURE';

function deleteNameReportLinkStart(nameReportLink: NameReportLink) {
    return {
        type: DELETE_NAME_REPORT_LINK_START,
        payload: nameReportLink,
    };
}

function deleteNameReportLinkSuccess(nameReportLink: NameReportLink) {
    return {
        type: DELETE_NAME_REPORT_LINK_SUCCESS,
        payload: nameReportLink,
    };
}

function deleteNameReportLinkFailure(message: string) {
    return {
        type: DELETE_NAME_REPORT_LINK_FAILURE,
        payload: message,
    };
}

export function deleteNameReportLink(
    nameReportLink: NameReportLink,
    customNameReportLinkDeletionCallback?: (nameReportLink: NameReportLink) => Promise<unknown>
): ClientCommonAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(deleteNameReportLinkStart(nameReportLink));
        const deletionPromise = customNameReportLinkDeletionCallback
            ? customNameReportLinkDeletionCallback(nameReportLink)
            : getNameReportLinksResource().deleteNameReportLink(nameReportLink);

        return deletionPromise
            .then(() => {
                dispatch(
                    nexus.withRemove(
                        NEXUS_STATE_PROP,
                        {
                            entityType: nameReportLink.entityType,
                            nameId: nameReportLink.nameId,
                            contextType: nameReportLink.contextType,
                            contextId: nameReportLink.contextId,
                            linkType: nameReportLink.linkType,
                        },
                        deleteNameReportLinkSuccess(nameReportLink)
                    )
                );
                dispatch(
                    nexus.withRemove(
                        'nameNameLinks',
                        {
                            nameFromId: nameReportLink.nameId,
                        },
                        { type: 'REMOVE_CAD_RMS_NAME_NAME_LINK' }
                    )
                );
            })
            .catch((err: Error) => {
                dispatch(deleteNameReportLinkFailure(err.message));
                throw err;
            });
    };
}

export function addNameReportLink(
    nameReportLink: NameReportLink
): ClientCommonAction<Promise<NameReportLink>> {
    const resource = getNameReportLinksResource();

    return (dispatch) => {
        return resource
            .addNameToReport(nameReportLink)
            .then((updatedNameReportLink: NameReportLink) => {
                // transient property
                updatedNameReportLink.wasStatementTaken = !!nameReportLink.wasStatementTaken;
                dispatch(
                    replaceNameReportLinksWhere(
                        {
                            entityType: updatedNameReportLink.entityType,
                            nameId: updatedNameReportLink.nameId,
                            contextType: updatedNameReportLink.contextType,
                            contextId: updatedNameReportLink.contextId,
                            linkType: updatedNameReportLink.linkType,
                        },
                        updatedNameReportLink
                    )
                );
                return updatedNameReportLink;
            });
    };
}

// ACTIONS

const replaceNameReportLinksWhere = nameReportLinksModule.actionCreators.replaceLinksWhere;

// SELECTORS
export const nameReportLinksSelector = nameReportLinksModule.selectors.linksSelector;
export const nameReportLinksWhereSelector = nameReportLinksModule.selectors.linksWhereSelector;

// REDUCER
export default nameReportLinksModule.reducerConfig;

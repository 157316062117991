import { AttributeTypeEnum } from '@mark43/rms-api';
import { size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { lifecycleOptions, Fields, Observer } from 'markformythree';
import {
    EVENT_DETAIL_RESPONDING_OFFICER_ID,
    EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID,
    DISPLAY_ONLY_MY_UNIT_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { reportByIdSelector } from '~/client-common/core/domain/reports/state/data';
import { agencyProfileOptionsWhereSelector } from '~/client-common/core/domain/agency-profiles/state/ui';
import { mostRecentActiveUnitForUserSelector } from '~/client-common/core/domain/user-assignments/state/ui';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    currentUserIdSelector,
    currentUserDepartmentIdSelector,
} from '../../../../core/current-user/state/ui';

import {
    formName,
    prefillRespondingOfficerId,
    prefillPersonnelUnitAttrId,
    SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH,
    RESPONDING_OFFICER_ID_PATH,
    PERSONNEL_UNIT_ATTR_ID_PATH,
} from '../../state/forms/supplementInfoForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import { ArbiterMFTAgencyProfileSelect } from '../../../../core/forms/components/selects/AgencyProfileSelect';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTUserSelect } from '../../../../core/forms/components/selects/UserSelect';
import Button, { buttonTypes } from '../../../../../legacy-redux/components/core/Button';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.SupplementInfoCard;

const AssignSpecificButton = styled(Button)`
    margin-top: 19px;
`;

const computeRespondingOfficerEffectiveDateFilter = ({ isLegacyReport, formEventStartUtc }) => {
    if (isLegacyReport) {
        return undefined;
    }
    return isUndefinedOrNull(formEventStartUtc) ? nowUtc() : formEventStartUtc;
};

const mapStateToProps = createStructuredSelector({
    agencyProfileOptionsWhere: agencyProfileOptionsWhereSelector,
    mostRecentActiveUnitForUser: mostRecentActiveUnitForUserSelector,
    currentUserId: currentUserIdSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
    reportById: reportByIdSelector,
});

const SupplementInfoCardForm = connect(mapStateToProps)(function _SupplementInfoCardForm({
    reportId,
    agencyProfileOptionsWhere,
    mostRecentActiveUnitForUser,
    currentUserId,
    currentUserDepartmentId,
    fieldConfigurationContextByContextAndFieldName,
    reportById,
}) {
    const agencyProfileOptionsPredicate = { departmentId: currentUserDepartmentId };
    const isAgencyProfileShown = size(agencyProfileOptionsWhere(agencyProfileOptionsPredicate)) > 1;
    const myUnitLabel = useFields(DISPLAY_ONLY_MY_UNIT_LABEL)[DISPLAY_ONLY_MY_UNIT_LABEL];
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={() => {
                return (
                    <CardSection>
                        <Row>
                            <ArbiterMFTText path="description" length="lg" autoFocus={true} />
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker path="eventStartUtc" includeTime={true} />
                        </Row>
                        <Row>
                            <ArbiterMFTDatePicker path="eventEndUtc" includeTime={true} />
                        </Row>
                        {isAgencyProfileShown && (
                            <Row>
                                <ArbiterMFTAgencyProfileSelect
                                    path="agencyId"
                                    length="lg"
                                    clearable={false}
                                    predicate={agencyProfileOptionsPredicate}
                                />
                            </Row>
                        )}
                        <Observer
                            subscriptions={{
                                formEventStartUtc: SUPPLEMENT_INFO_CARD_EVENT_START_UTC_PATH,
                                isRespondingOfficerIdDynamicallyHidden: [
                                    RESPONDING_OFFICER_ID_PATH,
                                    Fields.HIDDEN,
                                ],
                            }}
                            render={({
                                formEventStartUtc,
                                isRespondingOfficerIdDynamicallyHidden,
                            }) => {
                                const respondingOfficerIdFieldConfigurationContext = fieldConfigurationContextByContextAndFieldName(
                                    formName,
                                    EVENT_DETAIL_RESPONDING_OFFICER_ID
                                );
                                const isRespondingOfficerIdStaticallyHidden =
                                    respondingOfficerIdFieldConfigurationContext.isStaticallyHidden;

                                const isRespondingOfficerShown = !(
                                    isRespondingOfficerIdDynamicallyHidden ||
                                    isRespondingOfficerIdStaticallyHidden
                                );

                                if (isRespondingOfficerShown) {
                                    const report = reportById(reportId) || {};
                                    const { isLegacyReport } = report;
                                    const effectiveDateFilter = computeRespondingOfficerEffectiveDateFilter(
                                        {
                                            isLegacyReport,
                                            formEventStartUtc,
                                        }
                                    );

                                    return (
                                        <>
                                            <Row>
                                                <ArbiterMFTUserSelect
                                                    path="respondingOfficerId"
                                                    effectiveDate={effectiveDateFilter}
                                                    length="lg"
                                                    additionalIds={[currentUserId]}
                                                />
                                                <AssignSpecificButton
                                                    className={buttonTypes.SECONDARY}
                                                    onClick={() => {
                                                        prefillRespondingOfficerId({
                                                            respondingOfficerId: currentUserId,
                                                        });
                                                    }}
                                                    testId={testIds.ASSIGN_TO_ME_BUTTON}
                                                >
                                                    {strings.respondingOfficerAssignToMe}
                                                </AssignSpecificButton>
                                            </Row>
                                        </>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Observer
                            subscriptions={{
                                isPersonnelUnitAttrIdDynamicallyHidden: [
                                    PERSONNEL_UNIT_ATTR_ID_PATH,
                                    Fields.HIDDEN,
                                ],
                            }}
                            render={({ isPersonnelUnitAttrIdDynamicallyHidden }) => {
                                const personnelUnitAttrIdFieldConfigurationContext = fieldConfigurationContextByContextAndFieldName(
                                    formName,
                                    EVENT_DETAIL_PERSONNEL_UNIT_ATTR_ID
                                );
                                const isPersonnelUnitAttrIdStaticallyHidden =
                                    personnelUnitAttrIdFieldConfigurationContext.isStaticallyHidden;

                                const isPersonnelUnitShown = !(
                                    isPersonnelUnitAttrIdDynamicallyHidden ||
                                    isPersonnelUnitAttrIdStaticallyHidden
                                );

                                if (isPersonnelUnitShown) {
                                    const currentUserMostRecentActiveUnit =
                                        mostRecentActiveUnitForUser(currentUserId) || {};
                                    const {
                                        personnelUnitAttrId: currentUserMostRecentPersonnelUnitAttrId,
                                    } = currentUserMostRecentActiveUnit;
                                    const isCurrentUserActivelyInUnit = !isUndefinedOrNull(
                                        currentUserMostRecentPersonnelUnitAttrId
                                    );

                                    return (
                                        <Row>
                                            <ArbiterMFTAttributeSelect
                                                path="personnelUnitAttrId"
                                                attributeType={
                                                    AttributeTypeEnum.PERSONNEL_UNIT.name
                                                }
                                                length="lg"
                                            />
                                            {isCurrentUserActivelyInUnit && (
                                                <AssignSpecificButton
                                                    className={buttonTypes.SECONDARY}
                                                    onClick={() => {
                                                        prefillPersonnelUnitAttrId({
                                                            personnelUnitAttrId: currentUserMostRecentPersonnelUnitAttrId,
                                                        });
                                                    }}
                                                >
                                                    {myUnitLabel}
                                                </AssignSpecificButton>
                                            )}
                                        </Row>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Row>
                            <ArbiterMFTAttributeSelect
                                path="supplementTypeAttrIds"
                                length="lg"
                                attributeType={AttributeTypeEnum.SUPPLEMENT_TYPE.name}
                                multiple={true}
                            />
                        </Row>
                        <Row>
                            <IndentedFields>
                                <ArbiterMFTText path="supplementTypeDescription" length="lg" />
                            </IndentedFields>
                        </Row>
                    </CardSection>
                );
            }}
        />
    );
});

export default SupplementInfoCardForm;

import { intersection, map } from 'lodash';
import { RefContextEnum, ComplianceGroupEnum } from '@mark43/rms-api';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

export const getSuspectIsOffender = (getState: () => RootState) => (offenseId?: number) => {
    const state = getState();

    if (
        currentUserDepartmentProfileSelector(state)?.complianceGroup !==
        ComplianceGroupEnum.UNITED_KINGDOM.name
    ) {
        return false;
    }

    const { getForm } = useFormGetter();

    const form = getForm(RefContextEnum.FORM_OFFENSE.name, offenseId);

    if (typeof form === 'undefined') {
        return false;
    }

    // @ts-expect-error remove this when offenseForm.js becomes typed
    const { suspects, offenders } = form.getState().model.links || {};
    return intersection(map(suspects, 'nameId'), map(offenders, 'nameId')).length > 0;
};

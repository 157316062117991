import React from 'react';
import styled from 'styled-components';
import { head, map, omit } from 'lodash';
import { EmailTypeEnum, PhoneTypeEnum, AttributeTypeEnum } from '@mark43/rms-api';
import { Observer } from 'markformythree';

import Row from '../../components/Row';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTSelect } from '../../forms/components/selects/Select';
import { ArbiterMFTText } from '../../forms/components/Text';
import { ArbiterMFTTextArea } from '../../forms/components/TextArea';
import { ArbiterMFTDatePicker } from '../../forms/components/DatePicker';
import { AddButton, RemoveButton } from '../../forms/components/NItems';
import FormRow from '../../forms/components/FormRow';
import Icon, { iconTypes, iconSizes } from '../../components/Icon';
import LocationSummary from '../../../records/core/components/summaries/LocationSummary';
import { isOtherEmailType, isOtherPhoneType } from '../util/nameFormHelpers';

const StyledRemoveButton = styled(RemoveButton)`
    float: none;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
`;

const StyledAddButton = styled(AddButton)`
    margin-top: 18px;
    margin-bottom: 20px;
    display: inline-flex;
`;

const FlexRow = styled.div`
    display: flex;
    gap: var(--arc-space-3);
    width: 100%;
`;

export const phoneOptions = map(PhoneTypeEnum, (phoneType) => {
    return {
        value: phoneType.value,
        display: phoneType.value,
    };
});

const emailOptions = map(EmailTypeEnum, (emailType) => {
    return {
        value: emailType.value,
        display: emailType.value,
    };
});

// remove and add buttons need to be consistent across our form

export function renderRemoveButton({ removeItem, iconSize = iconSizes.MEDIUM }) {
    return (
        <StyledRemoveButton
            icon={<Icon color="cobaltBlue" type={iconTypes.TRASH_CAN} size={iconSize} />}
            onClick={removeItem}
            marginTop="30px"
        />
    );
}

export function renderRemoveButtonWithLabelOnFirstItemOnly({ removeItem, index }) {
    return (
        <StyledRemoveButton
            icon={<Icon color="cobaltBlue" type={iconTypes.TRASH_CAN} size={iconSizes.MEDIUM} />}
            onClick={removeItem}
            marginTop={index === 0 ? '30px' : '6px'}
        />
    );
}

export function renderRemoveButtonAfterFirstItem(props) {
    // Don't show trash can on first item if it's the only item
    return (props.index > 0 || props.items.length > 1) && renderRemoveButton(props);
}

export function renderAddButton({ addText, addItem, id, testId, setRef }) {
    return (
        <Row className="mark43-n-items-add-row">
            <StyledAddButton
                icon={<Icon color="cobaltBlue" type={iconTypes.ADD} size={iconSizes.MEDIUM} />}
                id={id}
                onClick={addItem}
                testId={testId}
                setRef={setRef}
            >
                {addText}
            </StyledAddButton>
        </Row>
    );
}

export function renderOrganizationPhoneNumbersNItems(props) {
    const path = `phoneNumbers[${props.index}].phoneType`;
    return (
        <FlexRow>
            <FormRow>
                <ArbiterMFTSelect path="phoneType" length="md" options={phoneOptions} />
                <ArbiterMFTText path="displayNumber" length="md" />
                <Observer
                    subscriptions={{
                        phoneType: path,
                    }}
                    render={({ phoneType }) =>
                        isOtherPhoneType(phoneType) ? (
                            <ArbiterMFTText path="phoneTypeOther" length="md" />
                        ) : null
                    }
                />
            </FormRow>
            {renderRemoveButtonWithLabelOnFirstItemOnly(props)}
        </FlexRow>
    );
}

export function renderOrganizationEmailsNItems(props) {
    const path = `emails[${props.index}]`;
    return (
        <FlexRow>
            <FormRow>
                <ArbiterMFTSelect path="emailType" length="md" options={emailOptions} />
                <ArbiterMFTText path="emailAddress" length="md" />
                <Observer
                    subscriptions={{
                        emailType: `${path}.emailType`,
                    }}
                    render={({ emailType }) =>
                        isOtherEmailType(emailType) ? (
                            <ArbiterMFTText path="emailTypeOther" length="md" />
                        ) : null
                    }
                />
            </FormRow>
            {renderRemoveButtonWithLabelOnFirstItemOnly(props)}
        </FlexRow>
    );
}

export function renderIdentifiersNItems(props) {
    return (
        <FlexRow>
            <FormRow>
                <ArbiterMFTAttributeSelect
                    path="nameIdentifierTypeAttrId"
                    attributeType={AttributeTypeEnum.NAME_IDENTIFIER_TYPE.name}
                    length="md"
                />
                <ArbiterMFTText path="identifier" length="md" />
                <ArbiterMFTText path="nameIdentifierTypeOther" length="md" />
            </FormRow>
            {renderRemoveButton(props)}
        </FlexRow>
    );
}

export function renderLocationNItems({ locationBundleWithUnknownLocationId, renderDescription, renderEndDate }) {
    return (
        <div>
            <LocationSummary
                locationBundle={{
                    location: locationBundleWithUnknownLocationId.location,
                    unknownLocationId: locationBundleWithUnknownLocationId.unknownLocationId,
                    // omit the fields we are showing in the form so that they don't show up twice
                    locationEntityLink: omit(
                        head(locationBundleWithUnknownLocationId.entityLinks) || {},
                        ['description', 'startDateUtc']
                    ),
                }}
            />
            <ArbiterMFTAttributeSelect
                path="positionAttrId"
                attributeType={AttributeTypeEnum.LOCATION_POSITION.name}
                length="md"
            />
            {renderDescription && <ArbiterMFTTextArea path="description" />}
            <ArbiterMFTDatePicker path="startDateUtc" />
            {renderEndDate && <ArbiterMFTDatePicker path="endDateUtc" />}
        </div>
    );
}

import { createSelector } from 'reselect';
import { Report } from '@mark43/rms-api';
import { get, mapValues } from 'lodash';

import { buildViewModel, ViewModel } from '../../../../../helpers/viewModelHelpers';
import {
    reportDefinitionsSelector,
    formatReportDefinitionByIdSelector,
    reportDefinitionByReportIdSelector,
} from '../../../report-definitions/state/data';
import { joinTruthyValues } from '../../../../../helpers/stringHelpers';
import { formatFieldByNameSelector } from '../../../../fields/state/config';
import { REPORT_REPORTING_EVENT_NUMBER } from '../../../../enums/universal/fields';
import { reportsSelector } from '../data';
import { reportDefinitionHasArrestCard } from '../../../../../helpers/reportDefinitionsHelpers';
import globalStrings from '../../../../strings/globalStrings';
import { arrestForReportIdSelector } from '../../../arrests/state/data';

export * from './index-old';

type ReportViewModelProps = {
    reportDefinition: string;
};

type ReportViewModel = ViewModel<Report, ReportViewModelProps>;

const reportViewModelsSelector = createSelector(
    reportsSelector,
    reportDefinitionsSelector,
    (reports, reportDefinitions) =>
        mapValues(
            reports,
            buildViewModel<ReportViewModel>({
                mappers: [
                    ({ reportDefinitionId }) => ({
                        reportDefinition: get(reportDefinitions[reportDefinitionId], 'name'),
                    }),
                ],
                helpers: {},
            })
        )
);

export const reportViewModelByIdSelector = createSelector(
    reportViewModelsSelector,
    (reportViewModels) => (id: number) => reportViewModels[id] || {}
);

export const formatReportTitleForReportIdSelector = createSelector(
    reportsSelector,
    formatFieldByNameSelector,
    formatReportDefinitionByIdSelector,
    reportDefinitionByReportIdSelector,
    arrestForReportIdSelector,
    (
            reports,
            formatFieldByName,
            formatReportDefinitionById,
            reportDefinitionByReportId,
            arrestForReportId
        ) =>
        (reportId: number, { includeRenDisplayName = true, includeRen = true } = {}) => {
            const report = reports[reportId];

            if (!report) {
                return '';
            }

            const reportDefinition = reportDefinitionByReportId(reportId);
            const isArrestReport = reportDefinitionHasArrestCard(reportDefinition);
            const arrest = isArrestReport ? arrestForReportId(reportId) : undefined;
            const arrestIsDetentionDisplayName = !!arrest?.isDetention
                ? globalStrings.detention
                : undefined;

            const renDisplayName =
                includeRen && includeRenDisplayName
                    ? formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                    : '';
            const ren = includeRen ? report.reportingEventNumber : '';
            return joinTruthyValues(
                [
                    renDisplayName,
                    ren,
                    arrestIsDetentionDisplayName ||
                        formatReportDefinitionById(report.reportDefinitionId),
                ],
                ' '
            );
        }
);

import { reduce } from 'lodash';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';

import {
    LOAD_POINT_LOCATIONS_START,
    LOAD_POINT_LOCATIONS_SUCCESS,
    LOAD_POINT_LOCATIONS_FAILURE,
    LOAD_POINT_LOCATION_START,
    LOAD_POINT_LOCATION_SUCCESS,
    LOAD_POINT_LOCATION_FAILURE,
    PURGE_POINT_LOCATIONS_START,
    PURGE_POINT_LOCATIONS_SUCCESS,
    PURGE_POINT_LOCATIONS_FAILURE,
} from '../data';

const initialUiState = {
    loadingList: false,
    loadingEntity: false,
    listIds: [],
    listError: undefined,
    formError: undefined,
};

const RESET_POINT_LOCATIONS_ADMIN = 'point-locations/RESET_POINT_LOCATIONS_ADMIN';

export default makeResettable(
    RESET_POINT_LOCATIONS_ADMIN,
    function pointLocationsAdminUiReducer(state = initialUiState, action) {
        switch (action.type) {
            case LOAD_POINT_LOCATIONS_START:
                return {
                    ...state,
                    listIds: [],
                    listError: undefined,
                    loadingList: true,
                };
            case LOAD_POINT_LOCATIONS_SUCCESS:
                return {
                    ...state,
                    listIds: reduce(
                        action.payload,
                        (acc, pointLocationView) => {
                            acc.push(pointLocationView.pointLocation.id);
                            return acc;
                        },
                        []
                    ),
                    loadingList: false,
                };
            case LOAD_POINT_LOCATIONS_FAILURE:
                return {
                    ...state,
                    listIds: [],
                    loadingList: false,
                    listError: action.payload,
                };
            case LOAD_POINT_LOCATION_START:
                return {
                    ...state,
                    formError: undefined,
                    loadingEntity: true,
                };
            case LOAD_POINT_LOCATION_SUCCESS:
                return {
                    ...state,
                    loadingEntity: false,
                };
            case LOAD_POINT_LOCATION_FAILURE:
                return {
                    ...state,
                    loadingEntity: false,
                    formError: action.payload,
                };
            case PURGE_POINT_LOCATIONS_START:
                return {
                    ...state,
                    formError: undefined,
                    loadingEntity: true,
                };
            case PURGE_POINT_LOCATIONS_SUCCESS:
                return {
                    ...state,
                    loadingEntity: false,
                };
            case PURGE_POINT_LOCATIONS_FAILURE:
                return {
                    ...state,
                    loadingEntity: false,
                    formError: action.payload,
                };
            default:
                return state;
        }
    }
);

export const uiSelectorBase = (state) => state.ui.pointLocationAdmin;

export function resetAdminState() {
    return {
        type: RESET_POINT_LOCATIONS_ADMIN,
    };
}

import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { createSelector, createStructuredSelector } from 'reselect';
import { map } from 'lodash';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import { elasticAttributeDetailsSelector } from '~/client-common/core/domain/elastic-attribute-details/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { nibrsOffenseCodeByCodeSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import { sortSavedSearches } from '~/client-common/helpers/advancedSearchHelpers';
import { savedSearchErrorMessages } from '~/client-common/configs/advancedSearchConfig';
import dragonRmsIntegrationResource from '~/client-common/core/domain/dragon-rms-integration/resources/dragonRmsIntegrationResource';
import {
    protectedEntityOptionDisplayViewByIdSelector,
    protectedEntityOptionDisplayViewWhereSelector,
    NEXUS_STATE_PROP_ENTITY_OPTION_DISPLAYS,
} from '~/client-common/core/domain/dragon-rms-integration/state/data/protected-option-display-views';
import {
    NEXUS_STATE_PROP_PROPERTY_OPTION_VIEWS,
    searchableConfiguredPropertyOptionViewByConfiguredEntityKeyNameSelector,
} from '~/client-common/core/domain/dragon-rms-integration/state/data/searchable-configured-property-option-views';
import { offenseCodesForFlagsSelector } from '../../../../../legacy-redux/selectors/offenseCodesSelectors';
import createSearchModule, {
    convertSavedSearchQueryShapesToLegacyQueryShape,
} from '../../../core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import searchResource from '../../../../../legacy-redux/resources/searchResource';
import advancedSearchReportsForm, {
    convertAdvancedSearchReportsFormModelToFilterGroups,
    convertAdvancedSearchReportsElasticQueryToFormModel,
} from '../forms/advancedSearchReportsForm';
import createReportsResultsSelector from '../../../core/utils/createReportsResultsSelector';
import { transformElasticQueryIdsToDisplayValuesSelector } from '../../../core/state/ui';
import recursivelyConvertElasticLocationQueries from '../../../core/utils/recursivelyConvertElasticLocationQueries';
import getRmsSavedSearchResource from '../../../core/resources/rmsSavedSearchResource';
import storeRmsSavedSearchesView from '../../../core/utils/storeRmsSavedSearchesView';
import { getSavedSearchConfiguredEntityRequestsForElasticReportQueries } from '../../utils/dragon/getSavedSearchConfiguredEntityRequestsForElasticReportQueries';
import { retry } from '../../../../core/utils/promiseHelpers';
import { getReportDefinitionQueriesWithConfiguredEntityQueriesFromElasticReportQueries } from '../../utils/dragon/getReportDefinitionQueriesWithConfiguredEntityQueriesFromElasticReportQueries';
import { getConfiguredEntityPropertiesFromElasticReportDefinitionQueries } from '../../utils/dragon/getConfiguredEntityPropertiesFromElasticReportDefinitionQueries';
import { createKeyForSearchableConfiguredPropertyOptionView } from '../../utils/dragon/createKeyForSearchableConfiguredPropertyOptionView';
import { formatDragonReportFieldsFilterGroups } from '../../utils/dragon/formatDragonReportFieldsFilterGroups';

/**
 * Based on the given search query model, compute filter groups to be displayed
 *   in the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}  boundSelectors extra selectors needed by search module
 * @return {Object}   Array of filter group view models.
 */
function convertAdvancedSearchReportsElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    return convertAdvancedSearchReportsFormModelToFilterGroups(
        // removed access to store so that we now use bound selectors instead of state (2nd arg)
        convertAdvancedSearchReportsElasticQueryToFormModel(elasticQuery, null, boundSelectors),
        (value, config) => {
            // The core code for managing transformation of filter groups does not support nested n-items. Instead it provides
            // the nested array to the `formatFieldValue` function. This is where we are hooking in to format our nested dragon
            // search data.
            if (config.key === 'reportConfiguredEntityProperties') {
                return formatDragonReportFieldsFilterGroups(value, {
                    formatFieldValue,
                    ...boundSelectors,
                });
            }
            return formatFieldValue(value, config);
        },
        boundSelectors
    );
}

export const transformElasticQueryBeforeSearchSelector = createSelector(
    transformElasticQueryIdsToDisplayValuesSelector,
    (transformIds) => (elasticQuery) => {
        const queryWithTransformedIds = transformIds(elasticQuery);
        const trimmedRoutingLabelsDetails = queryWithTransformedIds.routingLabelAttrDetail
            ?.displayValues
            ? map(queryWithTransformedIds.routingLabelAttrDetail.displayValues, (displayValue) =>
                  displayValue.trim()
              )
            : [];
        const trimmedQueryWithTransformedIds = {
            ...queryWithTransformedIds,
            routingLabelAttrDetail: {
                ...queryWithTransformedIds.routingLabelAttrDetail,
                displayValues: trimmedRoutingLabelsDetails,
            },
        };
        return recursivelyConvertElasticLocationQueries(trimmedQueryWithTransformedIds, {
            legacyQueryProp: 'involvedLocations',
            newQueryProp: 'involvedElasticLocations',
        });
    }
);

function buildLoadSavedSearchesActionCreator(actionCreators, elasticSearchType) {
    return () => {
        return async (dispatch, _, { nexus }) => {
            dispatch(actionCreators.loadSavedSearchesStart());

            try {
                const rmsSavedSearchesView =
                    await getRmsSavedSearchResource().getNonAutoSavedRmsSavedSearchesViewForElasticSearchType(
                        elasticSearchType
                    );

                const { savedSearches } = rmsSavedSearchesView;

                // we transform saved searches which could contain query detail objects back to ids,
                // so that our form pre-filling works, since the FE always expects ids and not query detail objects
                convertSavedSearchQueryShapesToLegacyQueryShape(savedSearches);

                const parsedSearchQueries = savedSearches.map(({ query }) => JSON.parse(query));

                // In order to display dragon field labels correctly in the filter groupings we have to load the required
                // data for all saved searches.
                const savedSearchConfiguredEntityRequests =
                    getSavedSearchConfiguredEntityRequestsForElasticReportQueries(
                        parsedSearchQueries
                    );

                const reportDefinitions =
                    getReportDefinitionQueriesWithConfiguredEntityQueriesFromElasticReportQueries(
                        parsedSearchQueries
                    );
                const propertyOptionViewPromise = reportDefinitions.length
                    ? retry(() =>
                          dragonRmsIntegrationResource.getReportSearchableConfiguredPropertyOptionViewsForReportDefinitionRequest(
                              {
                                  reportDefinitionIds: reportDefinitions
                                      .map((query) => query.reportDefinitionId)
                                      .filter((x) => !!x),
                              }
                          )
                      )
                    : undefined;
                const savedSearchContextPromise = savedSearchConfiguredEntityRequests.requests
                    .length
                    ? retry(() =>
                          dragonRmsIntegrationResource.getSavedSearchContext(
                              savedSearchConfiguredEntityRequests
                          )
                      )
                    : undefined;

                const entitiesToStore = {};
                if (propertyOptionViewPromise) {
                    const reportSearchableConfiguredPropertyOptionViews =
                        await propertyOptionViewPromise;
                    const configuredEntityPropertiesSearchedFor =
                        getConfiguredEntityPropertiesFromElasticReportDefinitionQueries(
                            reportDefinitions
                        );

                    const propertyOptionViewsToStore =
                        reportSearchableConfiguredPropertyOptionViews.flatMap(
                            (searchableOptionsView) =>
                                searchableOptionsView.searchableConfiguredPropertyOptionHierarchyViews.flatMap(
                                    (optionHierarchyView) =>
                                        optionHierarchyView.options.filter(
                                            (propertyOptionView) =>
                                                configuredEntityPropertiesSearchedFor[
                                                    createKeyForSearchableConfiguredPropertyOptionView(
                                                        propertyOptionView
                                                    )
                                                ]
                                        )
                                )
                        );

                    if (propertyOptionViewsToStore.length) {
                        entitiesToStore[NEXUS_STATE_PROP_PROPERTY_OPTION_VIEWS] =
                            propertyOptionViewsToStore;
                    }
                }

                if (savedSearchContextPromise) {
                    const { cetKeyNameToInstances } = await savedSearchContextPromise;
                    const instancesToStore = Object.values(cetKeyNameToInstances).flat();
                    if (instancesToStore.length) {
                        entitiesToStore[NEXUS_STATE_PROP_ENTITY_OPTION_DISPLAYS] = instancesToStore;
                    }
                }

                // eslint-disable-next-line no-restricted-syntax
                dispatch(
                    storeRmsSavedSearchesView({
                        rmsSavedSearchesView,
                        action: nexus.withEntityItems(
                            entitiesToStore,
                            actionCreators.loadSavedSearchesSuccess(
                                sortSavedSearches(savedSearches)
                            )
                        ),
                    })
                );
            } catch (error) {
                const errorMessage =
                    (error && error.message) ||
                    savedSearchErrorMessages.onGetSavedSearchesErrorMessage;
                dispatch(actionCreators.loadSavedSearchesFailure(errorMessage));
            }
        };
    };
}

/**
 * Redux module for advanced search reports. Unlike the other search modules,
 *   this one has functionality for exporting search results.
 * @type {Object}
 */
export default createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.REPORT.name,
    baseUiSelector: (state) => state.ui.advancedSearch.reports,
    form: advancedSearchReportsForm,
    defaultTableState: {
        activeColumnKeys: {
            date: 'relevance',
            entity: 'locations',
        },
    },
    elasticQueryToFilterGroups: convertAdvancedSearchReportsElasticQueryToFilterGroups,
    searchResultToRoutePath: ({ id }) => `/reports/${id}`,
    resourceMethod: elasticSearchResource.searchReports,
    exportResourceMethod: searchResource.exportReports,
    loadSearchExportPrintablesResourceMethod: searchResource.getReportSearchExportPrintables,
    resultsContainerClassName: 'reports-results-container',
    createResultsViewModelsSelector: createReportsResultsSelector,
    selectorToBind: createStructuredSelector({
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
        globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
        offenseCodesForFlags: offenseCodesForFlagsSelector,
        applicationSettings: applicationSettingsSelector,
        nibrsOffenseCodeByCode: nibrsOffenseCodeByCodeSelector,
        elasticAttributeDetails: elasticAttributeDetailsSelector,
        protectedEntityOptionDisplayViewById: protectedEntityOptionDisplayViewByIdSelector,
        protectedEntityOptionDisplayViewWhere: protectedEntityOptionDisplayViewWhereSelector,
        searchableConfiguredPropertyOptionViewByConfiguredEntityKeyName:
            searchableConfiguredPropertyOptionViewByConfiguredEntityKeyNameSelector,
    }),
    transformElasticQueryBeforeSearchSelector,
    buildLoadSavedSearchesActionCreator,
});

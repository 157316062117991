import { NEXUS_STATE_PROP } from '~/client-common/core/domain/looker-dashboards/state/data';
import { selectLookerDashboard } from '../../../dashboard/state/ui';
import getLookerResource from '../../../dashboard/resources/lookerResource';

export const createAnalysisDashboard = (folderType) => (dispatch, getState, { nexus }) => {
    return getLookerResource.createNewDashboard(folderType).then((newDashboard) => {
        dispatch(
            nexus.withEntityItems(
                {
                    [NEXUS_STATE_PROP]: [newDashboard],
                },
                { type: 'CREATE_ANALYSIS_DASHBOARD' }
            )
        );
        dispatch(selectLookerDashboard(newDashboard.lookerId));
    });
};

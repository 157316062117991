/**
 * Must match server enum {@link
 *   cobalt-rms/core/core-common/src/main/java/mark43/core/model/LinkTypes.java}.
 */
export default {
    TIED_TO: 1,
    EMPLOYEE: 2,
    STEP_PARENT_OF: 3,
    VICTIM_IN_OFFENSE: 4,
    SUSPECT_IN_OFFENSE: 5,
    OFFENDER_IN_OFFENSE: 301,
    PERSON_HOME: 6,
    LOCATION_OF_EVENT: 7,
    SUSPECT_IN_REPORT: 8,
    PERSON_WORK: 9,
    PERSON_SCHOOL: 10,
    OFFENSE_PROPERTY: 11,
    PERSON_IN_VEHICLE: 12,
    MISSING_PERSON_IN_REPORT: 13,
    SUBJECT_IN_OFFENSE: 14,
    WITNESS_IN_OFFENSE: 15,
    OTHERWISE_KNOWN: 16,
    SIBLING_OF: 18,
    CHILD_OF: 20,
    EMPLOYEE_OF: 22,
    ENGAGED_TO: 24,
    FRIEND_OF: 25,
    DEFENDANT_IN_ARREST: 28,
    RELATED_TO: 29,
    SUBJECT_OF_WARRANT: 30,
    EX_SIGNIFICANT_OTHER_OF: 32,
    SPOUSE_OF: 33,
    ACQUAINTANCE_OF: 37,
    SIGNIFICANT_OTHER_OF: 39,
    MUGSHOT: 41,
    OTHER_LOCATION_IN_REPORT: 42,
    FINGERPRINT: 43,
    LOCATION_OFFENSE: 45,
    CASE_NOTE_ATTACHMENT: 49,
    OTHER_NAME_IN_REPORT: 50,
    OFFENSE_IN_ARREST: 52,
    CO_DEFENDANT: 53,
    REPORTING_PARTY_IN_REPORT: 56,
    ATTORNEY_PHONE: 57,
    MOBILE_PHONE: 58,
    FAX: 59,
    WORK_PHONE: 60,
    HOME_PHONE: 61,
    OTHER_PHONE: 62,
    LAST_KNOWN_LOCATION: 63,
    OTHER_NAME_IN_OFFENSE: 71,
    CHILD_OF_SIGNIFICANT_OTHER: 98,
    STRANGER_OF: 99,
    COMMON_LAW_SPOUSE: 100,
    GRANDCHILD_OF: 101,
    STEP_SIBLING_OF: 102,
    NEIGHBOR_OF: 103,
    EX_SPOUSE_OF: 105,
    BABYSITTER_OF: 104,
    IN_LAW_OF: 106,
    NON_MOTORIST: 107,
    WITNESS_IN_REPORT: 108,
    BUSINESS_LOCATION: 109,
    BUSINESS_ADDRESS: 110,
    BUSINESS_BUSINESS: 111,
    OWNED_BY: 112,
    CLAIMED_BY: 113,
    SUBJECT_IN_FIELD_CONTACT: 114,
    LOCATION_OF_FIELD_CONTACT: 116,
    PROPERTY_RECOVERED_LOCATION: 117,
    OFFENSE_LOCATION: 123,
    ARREST_LOCATION: 124,
    VICTIM_IN_REPORT: 125,
    INCIDENT_PROPERTY: 120,
    FIELD_CONTACT_PROPERTY: 121,
    REPORT_ATTACHMENT: 126,
    IDENTIFYING_MARK: 127,
    CASE_NOTE_LOCATION: 129,
    LOCATION_WHERE_LOCATED: 130,
    KNOWN_HANGOUT: 131,
    SUBJECT_IN_USE_OF_FORCE: 132,
    ORGANIZATION_IN_FIELD_CONTACT: 133,
    SUBJECT_IN_COMMUNITY_INFORMATION: 134,
    ALLEGED_MEMBER_OF_GANG: 135,
    CONFIRMED_MEMBER_OF_GANG: 136,
    FACTION_SUBSET_OF_GANG: 137,
    LOCATION_OF_CAD_TICKET: 138,
    ATTACHMENT_TO_BULLETIN: 160,
    EVENT_LOCATION: 161,
    REPORTING_PARTY_LOCATION: 162,
    REPORTING_PARTY_IN_AGENCY_EVENT: 164,
    VEHICLE_IN_AGENCY_EVENT: 166,
    MEMBER_OF_UNIT: 167,
    LAST_KNOWN_CONTACT_IN_REPORT: 169,
    TRANSPORT_LOCATION: 173,
    TOW_VEHICLE_FROM_LOCATION: 175, // for CAD
    TOW_VEHICLE_TO_LOCATION: 176, // for CAD
    EVIDENCE_FACILITY_LOCATION: 178,
    PATROL_LOCATION: 179,
    CHAIN_EVENT_SIGNATURE: 180, // evidence
    CHAIN_EVENT_ID_SCAN: 181, // evidence
    SPECIAL_ASSIGNMENT_LOCATION: 195,
    TASK_ATTACHMENT: 182,
    LOCATION_OF_USE_OF_FORCE: 183,
    USER_HOME_ADDRESS: 184,
    USER_PHOTO: 185,
    TOW_VEHICLE_VEHICLE: 186,
    TOW_VEHICLE_TOWED_FROM_LOCATION: 187, // for RMS
    TOW_VEHICLE_REPORTED_STOLEN_LOCATION: 188,
    TOW_VEHICLE_RECOVERY_LOCATION: 189,
    TOW_VEHICLE_STORAGE_LOCATION: 190,
    TOW_VEHICLE_RELEASE_TO: 191,
    TOW_VEHICLE_RELEASE_AUTHORIZED_BY: 192,
    RELATIONSHIP_UNKNOWN: 193,
    USER_PROFILE_ATTACHMENT: 196,
    PERSON_PROFILE_ATTACHMENT: 199,
    ORGANIZATION_PROFILE_ATTACHMENT: 200,
    UNIT_LOCATION_INFO_LOCATION: 201,
    WARRANT_ATTACHMENT: 202,
    LOCATION_OF_WARRANT: 203,
    COMPLAINANT_IN_REPORT: 205,
    LOCATION_OF_TRAFFIC_CRASH: 206,
    SUBJECT_IN_TRAFFIC_CRASH: 207,
    LOCATION_OF_CITATION: 208,
    SUBJECT_IN_CITATION: 209,
    EXPORT_RELEASE_ATTACHMENT: 210,
    DEPARTMENT_PROFILE_LOGO: 211,
    DEPARTMENT_PROFILE_ADDRESS: 212,
    FIELD_NOTE_ATTACHMENT: 213,
    INVOLVED_PERSON_IN_REPORT: 215,
    INVOLVED_ORG_IN_REPORT: 216,
    COURT_ORDER_ATTACHMENT: 217,
    ITEM_PROFILE_PHOTO: 218,
    ITEM_PROFILE_ATTACHMENT: 219,
    CHAIN_EVENT_ATTACHMENT: 220, // evidence
    COPIED_CALL: 222,
    SUBJECT_IN_BEHAVIORAL_CRISIS: 223,
    DISPATCH_AREA_OWNER_PRIMARY: 224,
    DISPATCH_AREA_MONITOR: 225,
    STATION_LOCATION: 226,
    DEX_MESSAGE_SENT_FOR: 227,
    FIELD_NOTE_LINKED_PERSON: 229,
    FIELD_NOTE_LINKED_LOCATION: 230,
    THREATENED: 231,
    OTHER_NAME_IN_BEHAVIORAL_CRISIS: 232,
    OTHER_NAME_IN_FIELD_CONTACT: 233,
    LOCATION_OF_BEHAVIORAL_CRISIS: 234,
    ORGANIZATION_PROFILE_PHOTO: 235,
    LOCATION_OF_STOP: 236,
    SUBJECT_IN_STOP: 237,
    OTHER_NAME_IN_STOP: 238,
    FOSTER_CHILD_OF: 239,
    ESTRANGED_SPOUSE_OF: 241,
    NON_MARRIED_LIVE_IN: 242,
    THROWAWAY: 999,
    LOCATION_OF_PERSON_STOP: 240,
    DEFENDANT: 252,
    VEHICLE_LICENSE_PLATE_PHOTO: 254,
    DRIVER_LICENSE_FRONT_PHOTO: 255,
    DRIVER_LICENSE_BACK_PHOTO: 256,
    TRAFFIC_CRASH_ATTACHMENT: 257,
    TRAFFIC_CRASH_EXPORT_ATTACHMENT: 258,
    ROADWAY_OF_TRAFFIC_CRASH: 259,
    INTERSECTING_ROADWAY_OF_TRAFFIC_CRASH: 260,
    USER_SIGNATURE: 262,
    CASE_ATTACHMENT: 268,
    CONFIGURED_ENTITY_NAME_IN_REPORT: 272,
    CONFIGURED_ENTITY_LOCATION: 285,
} as const;

import { RotationTypeSuspensionForm } from '@mark43/cad-api';

export type AddServiceSuspensionFormAction = {
    type: string;
    error?: boolean;
    payload?: string;
};

export type AddOrEditSuspensionFormFields = RotationTypeSuspensionForm & {
    isIndefinitely?: boolean;
};

export enum SuspensionFormActions {
    ADD = 'add',
    EDIT = 'edit',
}

export enum SuspensionsHistoryRequestStatuses {
    SUCCESS = 'success',
    FAILURE = 'failure',
    LOADING = 'loading',
}

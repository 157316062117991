import { EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { FormattedDate } from '~/client-common/core/dates/components';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { sortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import {
    DISPLAY_ONLY_ARREST_ID_NUMBER,
    COURT_ORDER_COURT_CASE_DATE_UTC,
    COURT_ORDER_COURT_CASE_NUMBER,
    COURT_ORDER_COURT_CASE_STATUTE,
    COURT_ORDER_DATE_TO_PURGE_UTC,
    COURT_ORDER_INVOLVED_PERSON_FULL_NAME,
    COURT_ORDER_IS_JUVENILE,
    COURT_ORDER_REMARKS,
    COURT_ORDER_STATE_ID_NUMBER,
    DISPLAY_ONLY_MODIFIED_ON_DATE_UTC,
    COURT_ORDER_STATUTE_CODE_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import _ExpandCollapseButton from '../../../../../legacy-redux/components/core/ExpandCollapseButton';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { FileGrid as _FileGrid } from '../../../../../legacy-redux/components/core/Files';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.ReportSidebar.CourtOrderSection;

const ContentWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 6px;
    display: inline-block;
    width: 90%;
    border-right: ${(props) => `1px solid ${props.theme.colors.lowContrastGrey}`};
`;

const FileGrid = styled(_FileGrid)`
    margin-left: 10px;
    margin-right: 10px;
`;

const ExpandCollapseButton = styled(_ExpandCollapseButton)`
    margin-top: 10px;
    display: inline-block;
    float: none;
    vertical-align: top;
    width: 8%;

    && .react-icon-left {
        margin: 0;
    }
`;

const Title = styled.div`
    display: inline-block;
    font-size: var(--arc-fontSizes-sm);
    line-height: 15px;
    color: ${(props) => props.theme.colors.darkGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const FieldLabel = styled.div`
    flex: 1 1 auto;
    font-size: var(--arc-fontSizes-sm);
    line-height: 15px;
    color: ${(props) => props.theme.colors.lowContrastGrey};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
`;

const mapStateToProps = createStructuredSelector({
    formatMiniUserById: formatMiniUserByIdSelector,
    formatFieldByName: formatFieldByNameSelector,
    sortedAugmentedAttachmentViewModelsWhere: sortedAugmentedAttachmentViewModelsWhereSelector,
    currentReport: currentReportSelector,
});

class CourtOrder extends React.Component {
    state = { showAllFields: false };

    toggleShowAllFields = () => this.setState((state) => ({ showAllFields: !state.showAllFields }));

    render() {
        const { showAllFields } = this.state;
        const {
            courtOrder,
            formatMiniUserById,
            formatFieldByName,
            sortedAugmentedAttachmentViewModelsWhere,
            currentReport,
        } = this.props;

        const isStatuteSealing = !!courtOrder.statuteCodeAttrId;
        const title = isStatuteSealing
            ? strings.statuteSealing
            : `${formatFieldByName(COURT_ORDER_COURT_CASE_NUMBER)}: ${courtOrder.courtCaseNumber}`;

        const entityIdsForAttachments = isStatuteSealing
            ? courtOrder.allCourtOrderIds
            : [courtOrder.id];
        const attachmentViewModels = entityIdsForAttachments.reduce((acc, courtOrderId) => {
            acc = [
                ...acc,
                ...sortedAugmentedAttachmentViewModelsWhere({
                    entityId: courtOrderId,
                    entityType: EntityTypeEnum.COURT_ORDER.name,
                    linkType: LinkTypesEnum.COURT_ORDER_ATTACHMENT,
                }),
            ];
            return acc;
        }, []);

        const arrestIdNumber = currentReport.recordNumber;

        return (
            <div>
                <ContentWrapper>
                    <Title>{title}</Title>
                    <SummaryList
                        labelWidth={120}
                        contentWidth={150}
                        testId={testIds.COURT_ORDER_INFORMATION}
                    >
                        <SummaryRow
                            label={formatFieldByName(COURT_ORDER_DATE_TO_PURGE_UTC)}
                            hidden={!courtOrder.dateToPurgeUtc}
                        >
                            <FormattedDate
                                date={courtOrder.dateToPurgeUtc}
                                format={FormattedDate.FORMATS.FORM_DATE}
                            />
                        </SummaryRow>
                        {showAllFields && (
                            <div>
                                <SummaryRow
                                    label={formatFieldByName(COURT_ORDER_COURT_CASE_STATUTE)}
                                >
                                    {courtOrder.courtCaseStatute}
                                </SummaryRow>
                                <SummaryRow
                                    label={formatFieldByName(COURT_ORDER_COURT_CASE_DATE_UTC)}
                                    hidden={!courtOrder.courtCaseDateUtc}
                                >
                                    <FormattedDate
                                        date={courtOrder.courtCaseDateUtc}
                                        format={FormattedDate.FORMATS.FORM_DATE}
                                    />
                                </SummaryRow>
                                <SummaryRow
                                    label={formatFieldByName(COURT_ORDER_STATUTE_CODE_ATTR_ID)}
                                >
                                    {courtOrder.statuteCodeAttrId}
                                </SummaryRow>
                                {!isStatuteSealing && (
                                    <SummaryRow
                                        label={formatFieldByName(
                                            COURT_ORDER_INVOLVED_PERSON_FULL_NAME
                                        )}
                                    >
                                        {courtOrder.involvedPersonFullName}
                                    </SummaryRow>
                                )}
                                {isStatuteSealing && (
                                    <SummaryRow label={strings.statutePersons}>
                                        {courtOrder.allInvolvedPeople.join(',')}
                                    </SummaryRow>
                                )}
                                {!courtOrder.isVacate && !isStatuteSealing && (
                                    <SummaryRow label={formatFieldByName(COURT_ORDER_IS_JUVENILE)}>
                                        {booleanToYesNo(courtOrder.isJuvenile)}
                                    </SummaryRow>
                                )}
                                <SummaryRow
                                    label={
                                        courtOrder.isVacate ? strings.vacatedBy : strings.sealedBy
                                    }
                                >
                                    {formatMiniUserById(courtOrder.createdBy)}
                                </SummaryRow>
                                <SummaryRow
                                    label={
                                        courtOrder.isVacate ? strings.vacateDate : strings.sealDate
                                    }
                                    hidden={!courtOrder.createdDateUtc}
                                >
                                    <FormattedDate
                                        date={courtOrder.createdDateUtc}
                                        format={FormattedDate.FORMATS.FORM_DATE_TIME}
                                    />
                                </SummaryRow>
                                <SummaryRow label={formatFieldByName(COURT_ORDER_REMARKS)}>
                                    {courtOrder.remarks}
                                </SummaryRow>
                                <SummaryRow
                                    fieldName={DISPLAY_ONLY_MODIFIED_ON_DATE_UTC}
                                    hidden={
                                        !courtOrder.updatedDateUtc ||
                                        courtOrder.updatedDateUtc === courtOrder.createdDateUtc
                                    }
                                >
                                    <FormattedDate
                                        date={courtOrder.updatedDateUtc}
                                        format={FormattedDate.FORMATS.FORM_DATE_TIME}
                                    />
                                </SummaryRow>
                                <SummaryRow fieldName={COURT_ORDER_STATE_ID_NUMBER}>
                                    {courtOrder.stateIdNumber}
                                </SummaryRow>
                                {!isStatuteSealing && (
                                    <SummaryRow fieldName={DISPLAY_ONLY_ARREST_ID_NUMBER}>
                                        {arrestIdNumber}
                                    </SummaryRow>
                                )}
                                {!!attachmentViewModels.length && (
                                    <div className="summary-row">
                                        <FieldLabel>{strings.attachments}:</FieldLabel>
                                        <FileGrid
                                            fileLinks={attachmentViewModels}
                                            numOfColumns={1}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </SummaryList>
                </ContentWrapper>
                <ExpandCollapseButton
                    collapsed={!showAllFields}
                    setCollapsed={this.toggleShowAllFields}
                />
            </div>
        );
    }
}

export default connect(mapStateToProps)(CourtOrder);

import _, { mapValues } from 'lodash';
import { createSelector } from 'reselect';
import { caseDefinitionsSelector } from '../data';
import { getAdminListStatusFromStartEnd } from '../../../../dates/utils/dateHelpers';
import { attributeStatuses } from '../../../attributes/configuration';

const { ACTIVE, EXPIRED } = attributeStatuses;

/**
 * Format the given case definition as a dropdown/checkbox option.
 * @param  {number} options.id   id of case definition
 * @param  {String} options.name name of case definition
 * @return {Object}              [description]
 */
function mapCaseDefinitionToOption({ id, name, status }) {
    return {
        value: id,
        display: name,
        ...(status === EXPIRED
            ? {
                  status,
                  noteDisplay: '(expired)',
              }
            : {}),
    };
}

/**
 * Format the given case definitions as sorted dropdown/checkbox options. If
 *   there are expired case definition view models, they get sorted after active
 *   definitions.
 * @param  {Object} definitions
 * @return {Object[]}
 */
export function mapCaseDefinitionsToOptions(definitions) {
    return _(definitions)
        .sortBy([({ status }) => (status === EXPIRED ? 2 : 1), 'name'])
        .map(mapCaseDefinitionToOption)
        .value();
}

/**
 * Add status
 * @param  {[type]} definitions [description]
 * @return {[type]}             [description]
 */
function buildCaseDefinitionsViewModels(definitions) {
    return mapValues(definitions, (definition) => {
        const now = new Date();
        return {
            ...definition,
            status: getAdminListStatusFromStartEnd(
                definition.startDateUtc,
                definition.endDateUtc,
                now
            ),
        };
    });
}

export const caseDefinitionViewModelsSelector = createSelector(
    caseDefinitionsSelector,
    (caseDefinitions) => buildCaseDefinitionsViewModels(caseDefinitions)
);

export const activeCaseDefinitionViewModelsSelector = createSelector(
    caseDefinitionViewModelsSelector,
    (caseDefinitionViewModels) => {
        return _(caseDefinitionViewModels).filter({ status: ACTIVE }).mapKeys('id').value();
    }
);

export const expiredCaseDefinitionViewModelsSelector = createSelector(
    caseDefinitionViewModelsSelector,
    (caseDefinitionViewModels) => {
        return _(caseDefinitionViewModels).filter({ status: EXPIRED }).mapKeys('id').value();
    }
);

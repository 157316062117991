import { every, includes } from 'lodash';

import { Printable, PrintingDataTypeEnum } from '@mark43/rms-api';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';

const PARENT_FOLDER = PrintingDataTypeEnum.PARENT_FOLDER.name;
const FOLDER = PrintingDataTypeEnum.FOLDER.name;
const CASE_NOTE = PrintingDataTypeEnum.CASE_NOTE.name;

/**
 * When converting a parent Printable to a parent WorkOrder, this helper is used to determine the WorkOrder's zipTitle.
 * This helper should not be called with a child Printable because the parent represents the zip which will contain all
 * the children.
 *
 * zipTitle is an optional property of the WorkOrder. A return value of undefined means either the output is not a zip
 * file, or we're leaving it to the back end to determine the title of the zip based on the workOrder.title.
 *
 * We have to set the zipTitle when it is different from the WorkOrder's title, like when the user exports different
 * entities together. For example, when the user exports 9 case notes, the 'parent' entity is 1 of the case notes, and
 * its title (`Case Note - ${title}`) would not make sense as the zipTitle (`Case # ${caseNumber} - Notes`).
 */
export default function computeZipTitle(
    printable: Printable,
    { folderingEnabled }: { folderingEnabled: boolean }
): string | undefined {
    if (!folderingEnabled) {
        return undefined;
    }

    if (printable.childPrintables.length === 0) {
        if (includes([PARENT_FOLDER, FOLDER], printable.dataType)) {
            // exporting only 1 folder
            return joinTruthyValues([printable.groupTitle, printable.title], ' - ');
        }
        return undefined;
    }

    if (
        printable.dataType === CASE_NOTE &&
        every(printable.childPrintables, (childPrintable) =>
            includes([CASE_NOTE, PARENT_FOLDER, FOLDER], childPrintable.dataType)
        )
    ) {
        // exporting only case notes and folders
        return 'Case Notes';
    }

    if (printable.fileName === '') {
        /**
         * This is written as ad hoc logic to apply when the user exports a case and non-report entities (i.e. case
         * notes) together. But it does make sense generally for all types of exports.
         * See `mapGivenPacketsToPrintables` for how the Printable data was updated before reaching this function.
         */
        return printable.groupTitle;
    }

    return joinTruthyValues([printable.groupTitle, printable.title], ' - ');
}

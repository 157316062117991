import { SearchableConfiguredPropertyOptionView } from '@mark43/rms-api';

import createGroupedModule from '../../../../utils/createGroupedModule';

export const NEXUS_STATE_PROP_PROPERTY_OPTION_VIEWS =
    'dragonSearchableConfiguredPropertyOptionViews';

const dragonSearchableConfiguredPropertyOptionViewModule =
    createGroupedModule<SearchableConfiguredPropertyOptionView>({
        type: NEXUS_STATE_PROP_PROPERTY_OPTION_VIEWS,
        key: 'configuredEntityKeyName',
        indexKeys: ['configuredEntityKeyName', 'configuredPropertyKeyName'],
    });

// ACTIONS
export const storeSearchableConfiguredPropertyOptionViews =
    dragonSearchableConfiguredPropertyOptionViewModule.actionCreators.storeEntities;

// SELECTORS
export const searchableConfiguredPropertyOptionViewByConfiguredEntityKeyNameSelector =
    dragonSearchableConfiguredPropertyOptionViewModule.selectors.entitiesByKeySelector;

// REDUCER
export default dragonSearchableConfiguredPropertyOptionViewModule.reducerConfig;

import React from 'react';
import { every, includes, isEqual, map, startCase, xorWith } from 'lodash';
import styled from 'styled-components';

import { Attachment } from '@mark43/rms-api';
import { Avatar, Checkbox, InlineBanner, Tile, VStack } from 'arc';
import { useResource } from '~/client-common/core/hooks/useResource';
import {
    getAttachmentFile,
    getThumbnailPath,
} from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { arcIconForFileCategory } from '../../../../../attachments/files/helpers';
import { eFileResource } from '../../../../resources';
import { SkeletonList } from '../../../../../core/components/Skeleton';
import NoDataBlock from '../../../../../core/components/NoDataBlock';

const strings = componentStrings.eFiles.core.sidePanel;

interface ImportableAttachmentsContextT {
    selectedAttachmentIds: number[];
    setSelectedAttachmentIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const ImportableAttachmentsContext = React.createContext<ImportableAttachmentsContextT | undefined>(
    undefined
);

/**
 * Wrap this Provider component around any feature that needs to import E-File attachments.
 */
export const ImportableAttachmentsProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [selectedAttachmentIds, setSelectedAttachmentIds] = React.useState<number[]>([]);
    return (
        <ImportableAttachmentsContext.Provider
            value={{
                selectedAttachmentIds,
                setSelectedAttachmentIds,
            }}
        >
            {children}
        </ImportableAttachmentsContext.Provider>
    );
};

/**
 * The state of attachments currently selected for import by the user.
 * This state has no ties to the other state in `EFileContext`.
 */
export function useImportableAttachments(): ImportableAttachmentsContextT {
    const context = React.useContext(ImportableAttachmentsContext);
    if (context === undefined) {
        throw new Error('Must be inside ImportableAttachmentsProvider');
    }
    return context;
}

export const ImportableAttachmentsContent = ({ eFileId }: { eFileId: number }): JSX.Element => {
    const [attachments, setAttachments] = React.useState<Attachment[]>([]);

    const resource = React.useCallback(() => {
        return eFileResource.getAttachmentsForImport(eFileId);
    }, [eFileId]);

    const { isLoading, errorMessage } = useResource(resource, setAttachments);

    if (errorMessage) {
        return <InlineBanner status="error">{errorMessage}</InlineBanner>;
    } else if (isLoading) {
        return <SkeletonList />;
    }
    return (
        <>
            <AttachmentsSection attachments={attachments} />
        </>
    );
};

const AttachmentTile = styled(Tile)`
    margin-top: var(--arc-space-3);
`;

const AttachmentRow = ({
    attachment,
    isSelected,
    setSelectedAttachmentIds,
}: {
    attachment: Attachment;
    isSelected: boolean;
    setSelectedAttachmentIds: React.Dispatch<React.SetStateAction<number[]>>;
}): JSX.Element => {
    const file = getAttachmentFile(attachment);
    return (
        <AttachmentTile
            title={file.originalFileName}
            description={startCase(file.fileCategory)}
            media={
                <Avatar
                    src={getThumbnailPath(attachment, true)}
                    icon={arcIconForFileCategory(file.fileCategory)}
                    size="md"
                />
            }
            onClick={() => {
                setSelectedAttachmentIds((ids) => xorWith(ids, [attachment.attachmentId], isEqual));
            }}
            isMultiple={true}
            isSelected={isSelected}
        />
    );
};

const AttachmentRowMemo = React.memo(AttachmentRow);

const AttachmentsSection = ({ attachments }: { attachments: Attachment[] }): JSX.Element => {
    const { selectedAttachmentIds, setSelectedAttachmentIds } = useImportableAttachments();

    const onToggleAll = React.useCallback(
        (event) => {
            setSelectedAttachmentIds(event.target.checked ? map(attachments, 'attachmentId') : []);
        },
        [attachments, setSelectedAttachmentIds]
    );

    const isChecked =
        attachments.length > 0 &&
        every(attachments, (attachment) =>
            includes(selectedAttachmentIds, attachment.attachmentId)
        );

    return (
        <VStack align="left" height="auto">
            <Checkbox
                isDisabled={attachments.length === 0}
                isChecked={isChecked}
                onChange={onToggleAll}
            >
                {strings.selectAll}
            </Checkbox>
            {attachments.length > 0 ? (
                map(attachments, (attachment) => {
                    return (
                        <AttachmentRowMemo
                            key={attachment.attachmentId}
                            attachment={attachment}
                            isSelected={includes(selectedAttachmentIds, attachment.attachmentId)}
                            setSelectedAttachmentIds={setSelectedAttachmentIds}
                        />
                    );
                })
            ) : (
                <NoDataBlock>{strings.noResultsFound}</NoDataBlock>
            )}
        </VStack>
    );
};

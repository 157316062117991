import { UsageSourceModuleEnum } from '@mark43/rms-api';
/* global SENTRY_URL RELEASE_VERSION */

import * as Sentry from '@sentry/browser';

import { forEach, get, has, some } from 'lodash';

import {
    ConflictError,
    GoneError,
    InsufficientPermissionsError,
    InternalServerError,
    NetworkError,
    NotFoundError,
    RequestTimeoutError,
    UnauthorizedError,
    UnprocessableEntityError,
} from '../lib/errors';
import { LogRocket } from './logRocket';
import constants from './constants';

const excludeFromSentryErrors = [
    ConflictError,
    GoneError,
    InsufficientPermissionsError,
    InternalServerError,
    NetworkError,
    NotFoundError,
    RequestTimeoutError,
    UnauthorizedError,
    UnprocessableEntityError,
];

export function initSentry() {
    if (SENTRY_URL === constants.noSentryUrl) {
        return;
    }

    Sentry.init({
        dsn: SENTRY_URL,
        release: typeof RELEASE_VERSION === 'undefined' ? 'none' : RELEASE_VERSION,
        environment: typeof MARK43_ENV === 'undefined' ? 'none' : MARK43_ENV,
        beforeSend(event, hint) {
            const { data, originalException } = hint;
            const excludeError =
                originalException &&
                some(
                    excludeFromSentryErrors,
                    (excludeFromSentryError) => originalException instanceof excludeFromSentryError
                );

            const unhandledRejectionMftValidation =
                has(originalException, 'validationResult') &&
                get(data, 'stack.mechanism') === 'onunhandledrejection';

            if (excludeError || unhandledRejectionMftValidation) {
                return null;
            }

            return event;
        },
        ignoreErrors: [
            // https://bugs.chromium.org/p/chromium/issues/detail?id=809574
            'ResizeObserver',
            // Content Security Policy errors from the third party services we use
            `Blocked 'connect' from 'bam.nr-data.net'`,
            `Blocked 'connect' from 'api.segment.io'`,
            // Console error from using styled() from ARC on the Button
            `Function components cannot be given refs.`,
        ],
    });

    Sentry.configureScope((scope) => {
        scope.setTag('version', typeof RELEASE_VERSION === 'undefined' ? 'none' : RELEASE_VERSION);
        // initialize Sentry with default module tag
        scope.setTag('module', UsageSourceModuleEnum.RMS_GENERAL.name);
    });

    // Seperate block here because we may not have logrocket enabled,
    // in which case the callback will never get executed
    LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
            // Tag FE sentry errors with the current log rocket session
            scope.setExtra('logRocketSessionUrl', sessionURL);
        });
    });
}

export function sentrySetExtra(state) {
    Sentry.configureScope((scope) => {
        scope.setExtra('screenResolution', `${window.screen.height}x${window.screen.width}`);

        forEach(state.sessionHistory, (val, key) => {
            scope.setExtra(key, val);
        });
    });
}

import { createSelector } from 'reselect';
import { reject } from 'lodash';

import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';

import { sortedCurrentCaseTasksSelector } from '../../../task-list/state/ui';

const { taskStatusGlobal } = globalAttributes;

export const currentCaseSummaryTasksSelector = createSelector(
    sortedCurrentCaseTasksSelector,
    parentAttributeIdByAttributeIdSelector,
    (sortedCurrentCaseTasks, parentAttributeIdByAttributeId) =>
        reject(
            sortedCurrentCaseTasks,
            ({ statusAttrId }) =>
                parentAttributeIdByAttributeId(statusAttrId) === taskStatusGlobal.notApplicable
        )
);

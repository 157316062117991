import { filter, includes } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import { chainEventTypeOptionsSelector } from '~/client-common/core/domain/chain-event-types/state/ui';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const ChainEventTypeSelect = compose(
    connect(
        createStructuredSelector({
            options: chainEventTypeOptionsSelector,
        })
    ),
    withPropsOnChange(
        ['idFilter', 'options', 'includeExpired'],
        ({ idFilter, options, includeExpired }) => {
            return {
                options: filter(options, ({ value, isExpired }) => {
                    const idFilterResult = idFilter ? includes(idFilter, value) : true;
                    const includeExpiredResult = includeExpired ? true : !isExpired;

                    return idFilterResult && includeExpiredResult;
                }),
            };
        }
    )
)(Select);

/**
 * Select dropdown for chain event types. Values returned are chain event type
 *   id and not the attribute id.
 * @param {Object}   props
 * @param {Number[]} [props.idFilter] Chain event type ids to filter by. If
 *   provided, only these ids will be options.
 */
export default ChainEventTypeSelect;
export const RRFChainEventTypeSelect = connectRRFInput(ChainEventTypeSelect);

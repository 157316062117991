import React, { useCallback, useEffect, useState } from 'react';
import { TaskView } from '@mark43/rms-api';
import { useSelector } from 'react-redux';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { allRoleFormatsByRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import taskResource from '../resources/taskResource';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import { SidePanelSection } from '../../../../legacy-redux/components/core/SidePanel';
import { formatTaskHistories } from '../utils/taskHistoryHelpers';
import testIds from '../../../../core/testIds';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';
import { HistoryName, StyledRow } from './TaskFormComment';

const strings = componentStrings.tasks.core.TaskSidePanel;

const TaskFormHistory = ({ task }: { task: TaskView }) => {
    const [formattedTaskHistories, setFormattedTaskHistories] = useState<string[]>();
    const formatMiniUserById = useSelector(formatMiniUserByIdSelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const allRoleFormatsByRoleId = useSelector(allRoleFormatsByRoleIdSelector);
    const fieldFormatter = useSelector(formatFieldByNameSelector);
    const dateTimeFormatter = useDateTimeFormatter();

    const loadTaskHistory = useCallback(() => taskResource.getTaskHistory(task.id), [task]);
    const onResourceSuccess = useCallback(
        (result) => {
            setFormattedTaskHistories(
                formatTaskHistories(
                    result,
                    formatMiniUserById,
                    formatAttributeById,
                    allRoleFormatsByRoleId,
                    fieldFormatter,
                    dateTimeFormatter
                )
            );
        },
        [
            allRoleFormatsByRoleId,
            formatAttributeById,
            formatMiniUserById,
            fieldFormatter,
            dateTimeFormatter,
        ]
    );

    const { callResource, loading } = useResourceDeferred(loadTaskHistory, onResourceSuccess);

    useEffect(() => {
        callResource();
    }, [callResource]);

    return (
        <div data-test-id={testIds.TASK_HISTORY_SECTION}>
            <SidePanelSection title={strings.history} />
            <div>
                {loading.isLoading ? (
                    <SimpleLoading />
                ) : (
                    formattedTaskHistories?.map((formattedHistoryEvent, index) => {
                        return (
                            <StyledRow key={index}>
                                <HistoryName>{formattedHistoryEvent}</HistoryName>
                            </StyledRow>
                        );
                    })
                )}
            </div>
        </div>
    );
};

export default TaskFormHistory;

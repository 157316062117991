import { NameReportLinkUiOptions } from 'dragon-react';
import { isEqual } from 'lodash';
import { NameReportLink as NameReportLinkT } from '@mark43/rms-api';
import { Fieldset } from 'markformythree';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';

import { reportsSelector } from '~/client-common/core/domain/reports/state/data';
import NameSummaryViewWrapper from '../../../../core/components/NameSummaryViewWrapper';
import { useMemoEquals } from '../../../hooks/use-memo-equals';
import { useResolvedNameReportLinkQueryConfigurationValues } from '../../../hooks/use-resolved-name-report-link-query-configuration-values';
import {
    DragonInlineFormReferenceState,
    RMSDragonConfigurationExtensions,
} from '../../../rms-types';
import { assertNotNullOrUndefined } from '../../../../core/utils/assert-not-null-or-undefined';
import { useMFTFormContext } from '../../../context/mft-form';
import { useDragonCurrentFormNestingPathContext } from '../../../context/dragon-current-form';
import { getConfiguredFormPath } from '../../../utils/get-configured-form-path';
import { InlineFormReferenceSummaryWrapper } from '../inline-form-reference-summary-wrapper';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { syncCoreInstancesAction } from '../state';
import { getCoreModelInstanceId } from '../../../utils/dragon-instance-ids';
import { getDataAttributes } from '../utils/get-data-attributes';
import { deleteInlineFormReference } from '../../../state';
import { useSummaryValueForPath } from '../../../hooks/use-summary-value-for-path';
import { useSyncInlineFormValuesForLinks } from '../../../hooks/use-sync-inline-form-values-for-links';
import { InlineFormReferenceListEntry, RMSDragonFieldsetHelper } from './shared';

type NameReportLinkUiOptionsWithExtensions = NameReportLinkUiOptions<RMSDragonConfigurationExtensions>;

export function NameReportLinkSummary(props: NameReportLinkUiOptionsWithExtensions): JSX.Element {
    const { props: dragonProps, configuration, renderChildren, fullyQualifiedPath } = props;
    const value = useSummaryValueForPath(fullyQualifiedPath);
    if (!Array.isArray(value)) {
        throw new Error(`Unexpectedly did not find array value for path ${fullyQualifiedPath}`);
    }

    // The parent entity type, for now, is always `REPORT`, given that we have a `NameReportLink`.
    const parentEntityType = 'REPORT';
    const {
        contextId,
        contextType,
        linkType,
        reportId,
        entityType,
    } = useResolvedNameReportLinkQueryConfigurationValues(
        configuration.configuredFormQueryConfigurationViews
    );

    return (
        <RMSDragonFieldsetHelper
            key={dragonProps.key}
            label={dragonProps.label}
            {...getDataAttributes({
                label: configuration.ui.label,
                fullyQualifiedPath,
            })}
            render={() => (
                <NameSummaryViewWrapper
                    key={dragonProps.key}
                    // Summary mode is always `true` because we use dedicated components for summary and form modes in dragon
                    summaryMode={true}
                    reportId={reportId}
                    contextType={contextType}
                    contextId={contextId}
                    parentEntityType={parentEntityType}
                    parentId={reportId}
                    linkType={linkType}
                    entityType={entityType}
                    renderAdditionalItem={({ nameLink }: { nameLink: NameReportLinkT }) => {
                        // We have to match the index of the NRL to the correct index in our form state, in case the NRL order is different
                        // than what we have in form state.
                        const index = (value as DragonInlineFormReferenceState[]).findIndex(
                            (state) => getCoreModelInstanceId(state) === nameLink.id
                        );

                        if (index === -1) {
                            // TODO alert here?
                            return null;
                        }

                        return (
                            <InlineFormReferenceSummaryWrapper
                                options={{
                                    ...props,
                                    fullyQualifiedPath: `${fullyQualifiedPath}[${index}]`,
                                }}
                            >
                                {renderChildren({ index, key: nameLink.id })}
                            </InlineFormReferenceSummaryWrapper>
                        );
                    }}
                />
            )}
        />
    );
}

function compareNameReportLinksById(
    oldLinks: NameReportLinkT[] | undefined,
    newLinks: NameReportLinkT[]
): boolean {
    return isEqual(
        oldLinks?.map((link) => link.id),
        newLinks.map((link) => link.id)
    );
}

function getLinkId(link: NameReportLinkT): number {
    return link.id;
}
/**
 * Wrapper around `NameSummaryViewWrapper`, which enables usage of dragon forms for `NameReportLink`s.
 * The component reads name report link state based on dragon query configurations. The RMS's name report link state
 * dictates link ordering and this component will resolve the correct dragon state for a given link when rendering nested forms.
 *
 * This component will detect changes in the number of name report links reported for a given configuration and if links are added
 * or removed, it will update dragon form state accordingly.
 */
export function NameReportLink(props: NameReportLinkUiOptionsWithExtensions): JSX.Element {
    const { props: dragonProps, fullyQualifiedPath, configuration } = props;
    const [isFetchingInlineFormValues, setIsFetchingInlineFormValues] = React.useState(false);
    const form = useMFTFormContext();
    assertNotNullOrUndefined(form, 'Unexpectedly did not find form');
    const currentFormNestingPath = useDragonCurrentFormNestingPathContext();
    // The parent entity type, for now, is always `REPORT`, given that we have a `NameReportLink`.
    const parentEntityType = 'REPORT';
    const {
        contextId,
        contextType,
        entityType,
        linkType,
        reportId,
    } = useResolvedNameReportLinkQueryConfigurationValues(
        configuration.configuredFormQueryConfigurationViews
    );

    const dispatch: RmsDispatch = useDispatch();
    // The RMS is in charge of rendering name report links via `NameSummaryViewWrapper`.
    // We pull out the current state manually so we can use it to detect changes to the set of links for a given link configuration.
    const nameReportLinksWhere = useSelector(nameReportLinksWhereSelector);
    const nameReportLinks = nameReportLinksWhere({
        contextId,
        contextType,
        reportId,
        linkType,
        entityType,
    });
    const reports = useSelector(reportsSelector);
    const reportingEventNumber = reports[reportId]?.reportingEventNumber;

    // The name report links array is not stable by virtue of being a filtered list, so we need
    // ensure equality by comparing the ids of all links. The reason for this is that we need this
    // as input to our `useEffect` below, which would execute too often without a stable reference.
    const stableNameReportLinks = useMemoEquals(nameReportLinks, compareNameReportLinksById);
    useSyncInlineFormValuesForLinks({
        configuredFormId: configuration.formConfigurationId,
        currentFormNestingPath,
        form,
        fullyQualifiedPath,
        linkEntities: stableNameReportLinks,
        getLinkId,
        uiConfigurationId: configuration.id,
        setIsLoading: setIsFetchingInlineFormValues,
    });

    return (
        <RMSDragonFieldsetHelper
            key={dragonProps.key}
            label={dragonProps.label}
            render={() => (
                // Nitems handle the path nesting internally, but we have to ensure that we add the current path as a fieldset, else
                // our form state nesting will be off
                <Fieldset key={dragonProps.key} path={`[${dragonProps.path}]`}>
                    <NameSummaryViewWrapper
                        key={dragonProps.key}
                        deleteNameReportLink={(nameReportLink: NameReportLinkT) => {
                            return deleteInlineFormReference({
                                instanceIds: [nameReportLink.id],
                                parentReferencingUiConfigurationId: configuration.id,
                                parentFormPathInstance: getConfiguredFormPath(
                                    currentFormNestingPath
                                ),
                                configuredFormId: configuration.formConfigurationId,
                            })
                                .then((deletionResponse) => {
                                    if (deletionResponse) {
                                        dispatch(
                                            syncCoreInstancesAction(
                                                deletionResponse.deletedCoreInstances,
                                                deletionResponse.updatedCoreInstances
                                            )
                                        );
                                    }
                                })
                                .catch(() => {
                                    // noop for failed deletes. This causes dangling data but we
                                    // can't do anything on the frontend about it
                                });
                        }}
                        // TODO This ideally would be configurable from the admin UI
                        showQuickAdd={true}
                        // Summary mode is always `false` because we use dedicated components for summary and form modes in dragon
                        summaryMode={false}
                        reportId={reportId}
                        renForRecents={reportingEventNumber}
                        contextType={contextType}
                        contextId={contextId}
                        parentEntityType={parentEntityType}
                        parentId={reportId}
                        entityType={entityType}
                        linkType={linkType}
                        // TODO In the spirit of dragon, this text should be configurable via the admin application
                        addNameButtonText="Person/Org"
                        show={{
                            people: entityType === 'PERSON_PROFILE',
                            organizations: entityType === 'ORGANIZATION_PROFILE',
                            societies: entityType === 'ORGANIZATION_PROFILE',
                        }}
                        personOverlayIdPrefix={`${dragonProps.key}-person`}
                        organizationOverlayIdPrefix={`${dragonProps.key}-org`}
                        renderAdditionalItem={({ nameLink }: { nameLink: NameReportLinkT }) => {
                            return (
                                <InlineFormReferenceListEntry
                                    key={nameLink.id}
                                    entityId={nameLink.id}
                                    form={form}
                                    isLoading={isFetchingInlineFormValues}
                                    setIsLoading={setIsFetchingInlineFormValues}
                                    options={props}
                                    currentFormNestingPath={currentFormNestingPath}
                                />
                            );
                        }}
                    />
                </Fieldset>
            )}
        />
    );
}

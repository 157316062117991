import { EntityTypeEnum, FileCategoryEnum, FormEnum, LinkTypesEnum } from '@mark43/evidence-api';

import { map, omit } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';

import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'eventTypeId',
        fieldName: fields.CHAIN_EVENT_EVENT_TYPE_ID,
    },
    {
        key: 'facilityId',
        fieldName: fields.CHAIN_EVENT_FACILITY_ID,
    },
    {
        key: 'storageLocationId',
        fieldName: fields.CHAIN_EVENT_STORAGE_LOCATION_ID,
    },
    'receivedByEntityType',
    {
        key: 'receivedByEntityId',
        fieldName: fields.CHAIN_EVENT_RECEIVED_BY_ENTITY_ID,
    },
    {
        // this field has a dynamic label depending on eventTypeId
        key: 'receivedByName',
        fieldName: fields.CHAIN_EVENT_RECEIVED_BY_NAME,
    },
    {
        key: 'description',
        fieldName: fields.CHAIN_EVENT_DESCRIPTION,
    },
    {
        key: 'signatureFileId',
        fieldName: fields.ATTACHMENT_ENTITY_ID,
    },
    'signatureFileCategory',
    {
        key: 'idScanFileId',
        fieldName: fields.ATTACHMENT_ENTITY_ID,
    },
    {
        key: 'attachmentFileIds',
        fieldName: fields.ATTACHMENT_ENTITY_ID,
    },
]);

const signatureFileCategoryToEntityType = {
    [FileCategoryEnum.IMAGE.name]: EntityTypeEnum.IMAGE.name,
    [FileCategoryEnum.PDF.name]: EntityTypeEnum.FILE.name,
};
function convertFromFormModel(formModel = {}, chainOfCustodyIds = []) {
    // if there is a signature and/or an ID photo attachment, its attachment
    // model is the same for every selected item/chain of custody; these are
    // arrays and not single objects so that we can easily support multiple
    // attachments per type in the future, for say front and back photos
    const signatures = !!formModel.signatureFileId
        ? [
              {
                  // entityId on this attachment model is left blank since it must be
                  // the new chainEventId
                  attachmentId: formModel.signatureFileId,
                  attachmentType:
                      signatureFileCategoryToEntityType[formModel.signatureFileCategory] ||
                      EntityTypeEnum.FILE.name,
                  entityType: EntityTypeEnum.CHAIN_EVENT.name,
                  linkType: LinkTypesEnum.CHAIN_EVENT_SIGNATURE,
              },
          ]
        : [];
    const idScans = !!formModel.idScanFileId
        ? [
              {
                  // entityId on this attachment model is left blank since it must be
                  // the new chainEventId
                  attachmentId: formModel.idScanFileId,
                  attachmentType: EntityTypeEnum.FILE.name,
                  entityType: EntityTypeEnum.CHAIN_EVENT.name,
                  linkType: LinkTypesEnum.CHAIN_EVENT_ID_SCAN,
              },
          ]
        : [];
    const attachments = map(formModel.attachmentFileIds, (attachmentFileId) => ({
        // entityId on this attachment model is left blank since it must be
        // the new chainEventId
        attachmentId: attachmentFileId,
        attachmentType: EntityTypeEnum.FILE.name,
        entityType: EntityTypeEnum.CHAIN_EVENT.name,
        linkType: LinkTypesEnum.CHAIN_EVENT_ATTACHMENT,
    }));
    const allAttachments = [...signatures, ...idScans, ...attachments];

    return map(chainOfCustodyIds, (chainOfCustodyId) => ({
        chainEvent: {
            ...omit(formModel, ['signatureFileId', 'idScanFileId', 'attachmentFileIds']),
            chainOfCustodyId,
        },
        attachments: allAttachments,
    }));
}

const createChainEventsForm = createFormModule({
    formName: FormEnum.EVIDENCE_CREATE_CHAIN_EVENTS.name,
    fieldViewModels,
    convertFromFormModel,
});

/**
 * Module of the facility admin form, for creating/editing a single facility.
 * @type {Object}
 */
export default createChainEventsForm;

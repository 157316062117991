import { EntityTypeEnumType, FolderView } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { isEmpty, values, chain } from 'lodash';

import createNormalizedModule from '../../../../utils/createNormalizedModule';

export interface Folder extends FolderView {
    id: number;
}

export const NEXUS_STATE_PROP = 'folders';

const foldersModule = createNormalizedModule<Folder>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// SELECTORS
export const foldersSelector = foldersModule.selectors.entitiesSelector;
export const folderByIdSelector = foldersModule.selectors.entityByIdSelector;

export const foldersByOwnerIdSelector = createSelector(
    foldersSelector,
    (folders) => (ownerId: number, entityTypeId: EntityTypeEnumType) => {
        if (isEmpty(values(folders))) {
            return;
        }

        return chain(folders)
            .values()
            .filter(
                ({ folder }) => folder?.ownerId === ownerId && folder.entityTypeId === entityTypeId
            )
            .sortBy([(folderContentView) => folderContentView.folder?.name.toLowerCase()])
            .value();
    }
);

export const topLevelFoldersByOwnerIdSelector = createSelector(
    foldersByOwnerIdSelector,
    (foldersByOwnerId) => (ownerId: number, entityTypeId: EntityTypeEnumType) => {
        return foldersByOwnerId(ownerId, entityTypeId)?.map(({ folder }) => folder);
    }
);

export const rootFolderByFolderIdSelector = createSelector(
    foldersSelector,
    (folders) => (targetFolderId: string, entityTypeId: EntityTypeEnumType) => {
        let topFolderId: number | undefined;

        chain(folders)
            .pickBy((topFolder) => topFolder.folder?.entityTypeId === entityTypeId)
            .forEach((topLevelFolder) => {
                if (
                    topLevelFolder !== undefined &&
                    findSubFolder(topLevelFolder, parseInt(targetFolderId))
                ) {
                    topFolderId = topLevelFolder.id;
                }
            });

        return topFolderId;
    }
);

const findSubFolder = (rootFolder: FolderView, folderId: number): Folder | undefined => {
    if (isEmpty(rootFolder.subFolderViews)) {
        return undefined;
    }

    for (const subFolder of rootFolder.subFolderViews) {
        const foundSubFolder = findSubFolder(subFolder, folderId);
        if (foundSubFolder !== undefined) {
            return foundSubFolder;
        }
    }

    return undefined;
};

// REDUCER
export default foldersModule.reducerConfig;

import { Component } from 'react';
import { isFunction, last, indexOf, filter } from 'lodash';

import { blurActiveElement } from '~/client-common/core/keyboardFocus/helpers';
import { createOverlayCustomProperties } from '../../utils/createOverlayCustomProperties';

export function pushScreenOntoStack(stack, { screen, screenState }) {
    return {
        screenStack: [
            ...(stack || []),
            {
                screen,
                screenState: {
                    ...screenState,
                    currentScreen: screen,
                },
            },
        ],
    };
}

export function popScreenFromStack(stack) {
    return {
        screenStack: stack.slice(0, -1),
    };
}

export function updateCurrentScreenStateInStack(stack, updatedScreenState) {
    const current = last(stack);
    return {
        screenStack: [
            ...stack.slice(0, -1),
            {
                screen: current.screen,
                screenState: {
                    ...current.screenState,
                    ...(isFunction(updatedScreenState)
                        ? updatedScreenState(current.screenState)
                        : updatedScreenState),
                },
            },
        ],
    };
}

/**
 * Provides an API abstraction on top of `overlay-manager` custom properties
 * which allows easy and "type-safe" setting of custom properties for a given
 * overlay.
 */
export class ScreenManager extends Component {
    getScreenStack = () => this.props.overlayState.customProperties.screenStack;

    setCustomOverlayProperties(customProperties) {
        return this.props.overlayStore.setCustomProperties(
            this.props.overlayId,
            createOverlayCustomProperties(customProperties, this.props.overlayStateType, {
                setDefaults: true,
            })
        );
    }

    getCurrentScreen = () => last(this.getScreenStack());

    setCurrentScreenState = (updatedScreenState) =>
        this.setCustomOverlayProperties(
            updateCurrentScreenStateInStack(this.getScreenStack(), updatedScreenState)
        );

    goToNextScreen = (screen, screenState = {}) => {
        blurActiveElement();
        this.setCustomOverlayProperties(
            pushScreenOntoStack(this.getScreenStack(), { screen, screenState })
        );
    };

    goToPreviousScreen = () => {
        blurActiveElement();
        this.setCustomOverlayProperties(popScreenFromStack(this.getScreenStack()));
    };

    getScreenStackSize = () => this.getScreenStack().length;

    removePanelErrorMessageForCurrentScreen = (errorMessage) => {
        const { errorMessages = [] } = this.getCurrentScreen().screenState;
        if (indexOf(errorMessages, errorMessage) === -1) {
            return;
        }
        this.setCurrentScreenState({
            errorMessages: filter(errorMessages, (message) => message !== errorMessage),
        });
    };

    resetPanelErrorMessagesForCurrentScreen = () =>
        this.setCurrentScreenState({
            errorMessages: [],
        });

    setSaveButtonDisabledForCurrentScreen = (saveDisabled) =>
        this.setCurrentScreenState({
            saveDisabled,
        });

    appendPanelErrorMessageToCurrentScreenIfNotExists = (errorMessage) => {
        const { errorMessages = [] } = this.getCurrentScreen().screenState;
        const messages = Array.isArray(errorMessage) ? errorMessage : [errorMessage];
        const newMessages = filter(messages, (message) => indexOf(errorMessages, message) === -1);
        if (newMessages.length === 0) {
            return;
        }
        this.setCurrentScreenState({
            errorMessages: [...errorMessages, ...newMessages],
        });
    };

    render() {
        return this.props.children({
            goToPreviousScreen: this.goToPreviousScreen,
            goToNextScreen: this.goToNextScreen,
            setCurrentScreenState: this.setCurrentScreenState,
            getCurrentScreen: this.getCurrentScreen,
            getScreenStackSize: this.getScreenStackSize,
            removePanelErrorMessageForCurrentScreen: this.removePanelErrorMessageForCurrentScreen,
            resetPanelErrorMessagesForCurrentScreen: this.resetPanelErrorMessagesForCurrentScreen,
            setSaveButtonDisabledForCurrentScreen: this.setSaveButtonDisabledForCurrentScreen,
            appendPanelErrorMessageToCurrentScreenIfNotExists: this
                .appendPanelErrorMessageToCurrentScreenIfNotExists,
        });
    }
}

import { identity } from 'lodash';

import { isNotPrefixedWithHasRegex, joinRegex, wrapInCaptureGroup } from './regexPatterns';

import recursivelyConvertIdsToDisplayValueObjects from './recursivelyConvertIdsToDisplayValueObjects';

const OFFENSECODE_DETAIL_KEY_REGEX = /offenseCodeDetails?$/;
const OFFENSECODE_ID_KEY_REGEX = /Detail(s)?$/;
const ATTRIBUTE_ID_KEY_REGEX = /AttrDetail(s)?$/;
const ATTRIBUTE_DETAIL_KEY_REGEX = /AttrDetails?$/;

/**
 * Recursively traverses an object, looking for keys that match `offenseCodeDetail(s)` and `*AttrDetail(s)`,
 * transforms their values into id arrays and stores the result on a new key,
 * replacing the `Detail(s)`/ `AttrDetail(s)` suffix with `AttrId(s)`. The original keys will be removed.
 *
 * For params see return value of `recursivelyConvertIdsToDisplayValueObjects`
 */
export const recursivelyConvertQueryDetailObjectsToIds = recursivelyConvertIdsToDisplayValueObjects(
    {
        sourceKeyRegex: [OFFENSECODE_DETAIL_KEY_REGEX, ATTRIBUTE_DETAIL_KEY_REGEX],
        targetKeyRegex: [OFFENSECODE_ID_KEY_REGEX, ATTRIBUTE_ID_KEY_REGEX],
        getTargetKey: (key, targetKeyRegex, sourceKeyRegexIndex) =>
            sourceKeyRegexIndex === 0
                ? key.replace(targetKeyRegex, 'Id$1')
                : key.replace(targetKeyRegex, 'AttrId$1'),
        createTargetValue: (convertedValue, sourceValue) => {
            if (sourceValue && sourceValue.ids) {
                return sourceValue.ids;
            }
            // If our `sourceValue` doesn't contain an `ids` field
            // then just return an empty array
            return [];
        },
    }
);

/**
 * This function is only here to account for a singular/plural mismatch we have
 * between the `*AttrIds` and `*AttrDetail` as well as `offenseCodeIds` and `offenseCodeDetail`
 * fields on the FE and on elastic query objects.
 * When attribute detail queries were introduced the decision was made that all of them
 * be named `*AttrDetail`, even if the source field on the FE was a plural `*AttrIds` field.
 * This now comes back to haunt us because when we convert shapes back from `*AttrDetail`
 * into `*AttrId(s)` we cannot infer whether or not the name should be singular or plural
 * That's why this function exists and its sole purpose is to copy `*AttrId` fields over
 * to `*AttrIds` so that our forms can pick the correct value for pre-filling and just
 * discard the rest.
 */
export const convertAttributeAndOffenseCodeIdsSingularToPlural = recursivelyConvertIdsToDisplayValueObjects(
    {
        sourceKeyRegex: [/offenseCodeId$/, joinRegex([isNotPrefixedWithHasRegex, /AttrId$/])],
        targetKeyRegex: [
            /(offenseCodeId)$/,
            wrapInCaptureGroup(joinRegex([isNotPrefixedWithHasRegex, /AttrId$/])),
        ],
        getTargetKey: (key, targetKeyRegex) => key.replace(targetKeyRegex, '$1s'),
        createTargetValue: identity,
        preserveOldKeys: true,
    }
);

import { EntityPermission } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

// TODO PRINTING-REFACTOR need to switch RMS to use this file...
// This file was used by printing before, but is no longer needed in printing,
// but leaving it here so we can eventually move RMS to use it.

const entityPermissionsModule = createLinkModule<EntityPermission>({
    type: 'entityPermissions',
    keys: ['entityType', 'entityId', 'roleId'],
});

export const NEXUS_STATE_PROP = 'entityPermissions';

// ACTIONS
export const storeEntityPermissions = entityPermissionsModule.actionCreators.storeLinks;

// SELECTORS
export const entityPermissionsSelector = entityPermissionsModule.selectors.linksSelector;

// REDUCER
export default entityPermissionsModule.reducerConfig;

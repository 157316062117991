import React from 'react';
import { filter, includes } from 'lodash';

import { simpleControl } from 'markformythree';
import { getDropdownOptionsFromObject } from '~/client-common/helpers/dropdownOptionHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { arbiterMFTInput } from '../../../arbiter';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const { label, options } = componentStrings.forms.select.OperationTypeSelect;
const operationTypeOptions = getDropdownOptionsFromObject(options);

const getOptionsFromValues = (dropdownOptionValues) => {
    if (!dropdownOptionValues) {
        return operationTypeOptions;
    }

    return filter(operationTypeOptions, ({ value }) => includes(dropdownOptionValues, value));
};

/**
 * `Select` dropdown for operation types (permissions).
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 * @param {OperationTypeEnumType} [props.dropdownOptionValues] Set this prop to determine which permission dropdown values should appear in the modal.
 *      If undefined, all possible dropdown options will appear
 * @param {string} [props.fieldName] Provide a name for the field. This will allow for easy testing
 */
export default function OperationTypeSelect({ dropdownOptionValues, ...rest }) {
    const dropdownOptions = getOptionsFromValues(dropdownOptionValues);
    return <Select label={label} options={dropdownOptions} {...rest} />;
}

OperationTypeSelect.displayName = 'OperationTypeSelect';

export const RRFOperationTypeSelect = connectRRFInput(OperationTypeSelect);
export const MFTOperationTypeSelect = simpleControl(OperationTypeSelect);
export const ArbiterMFTOperationTypeSelect = arbiterMFTInput(OperationTypeSelect);

import styled from 'styled-components';
import { mediaQueries, cssVar } from 'arc';

import { Button } from '../../core/components/Button';

export const Actions = styled.div`
    display: flex;
    gap: ${cssVar('arc.space.2')};

    @media (max-width: ${mediaQueries.md}) {
        align-self: flex-end;
    }
`;

export const ActionButton = styled(Button)`
    flex-shrink: 0;
`;

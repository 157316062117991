import { createSelector } from 'reselect';
import { filter, intersection, map } from 'lodash';
import { ElasticSearchTypeEnum, EntityTypeEnum } from '@mark43/rms-api';
import Promise from 'bluebird';
import {
    STORE_ELASTIC_ATTRIBUTE_DETAILS,
    NEXUS_STATE_PROP as ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/elastic-attribute-details/state/data';
import { offenseCodesSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';
import { rejectUnknownEnumValue } from '~/client-common/helpers/enumHelpers';

import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults from '../../../core/utils/getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults';
import {
    QUICK_SEARCH_QUERY_SIZE,
    quickSearchSearchTypes,
    quickSearchResultTypeToPropMap,
} from '../../config';
import { bookingsResource } from '../../../core/resources';
import { logError } from '../../../../../core/logging';

const QUICK_SEARCH_UPDATE_QUERY = 'quick-search/UPDATE_QUERY';
const QUICK_SEARCH_RESET_STATE = 'quick-search/RESET_STATE';

const QUICK_SEARCH_START = 'quick-search/SEARCH_START';
const QUICK_SEARCH_SUCCEESS = 'quick-search/SEARCH_SUCCEESS';
const QUICK_SEARCH_FAILURE = 'quick-search/SEARCH_FAILURE';

const QUICK_SEARCH_LOAD_MORE_START = 'quick-search/LOAD_MORE_START';
const QUICK_SEARCH_LOAD_MORE_SUCCESS = 'quick-search/LOAD_MORE_SUCCESS';
const QUICK_SEARCH_LOAD_MORE_FAILURE = 'quick-search/LOAD_MORE_FAILURE';

export function stashAttributesForSearchResults(result) {
    return (dispatch, getState) => {
        const state = getState();
        const offenseCodes = offenseCodesSelector(state);
        const {
            elasticAttributeDetails,
        } = getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults(result, {
            offenseCodes,
        });

        if (elasticAttributeDetails.length) {
            dispatch(
                withEntityItems(
                    { [ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP]: elasticAttributeDetails },
                    { type: STORE_ELASTIC_ATTRIBUTE_DETAILS }
                )
            );
        }
    };
}

export const currentUserSearchPermissionSelector = createSelector(
    isProductModuleActiveSelector,
    currentUserHasAbilitySelector,
    applicationSettingsSelector,
    (isProductModuleActive, currentUserHasAbility, applicationSettings) => (searchType) => {
        return (
            (!searchType.productModule || isProductModuleActive(searchType.productModule)) &&
            (!searchType.ability || currentUserHasAbility(searchType.ability)) &&
            (!searchType.featureFlag || applicationSettings[searchType.featureFlag] === true)
        );
    }
);

// quickSearchSearchTypes config object specifies allowed types based on product module/feature flag/ability
export const allowedQuickSearchTypesSelector = createSelector(
    currentUserSearchPermissionSelector,
    (checkSearchPermission) => {
        const allowedQuickSearchTypes = filter(quickSearchSearchTypes, checkSearchPermission);

        return map(allowedQuickSearchTypes, 'type');
    }
);

export const allEntityTypes = map(rejectUnknownEnumValue(ElasticSearchTypeEnum), 'name');

export const triggerQuickSearch = ({
    allowedEntityTypes = allEntityTypes,
    query,
    excludeExternalAgencyResults,
}) => (dispatch, getState) => {
    const searchTypes = allowedQuickSearchTypesSelector(getState());
    const finalSearchTypes = intersection(searchTypes, allowedEntityTypes);
    dispatch(quickSearchStart());
    const isBookingEnabled = searchTypes.includes(EntityTypeEnum.BOOKING.name);
    return Promise.all([
        isBookingEnabled
            ? bookingsResource
                  .searchBookings({ query, limit: QUICK_SEARCH_QUERY_SIZE })
                  .catch(logError)
            : Promise.resolve(undefined),
        elasticSearchResource.searchAll({
            size: QUICK_SEARCH_QUERY_SIZE,
            query,
            searchTypes: finalSearchTypes,
            excludeExternalAgencyResults,
        }),
    ])
        .then((response) => {
            // if we have no result, then we have to assume that
            // the promise was cancelled (since our ajax implementation
            // swallows cancellation errors)
            // doing this check here since `promise.reflect()` leads
            // to an infinite loop here
            const [bookingResponse, mainResponse] = response;
            const result = bookingResponse
                ? {
                      ...mainResponse,
                      bookings: {
                          ...bookingResponse,
                          query: { from: 0, size: QUICK_SEARCH_QUERY_SIZE },
                      },
                  }
                : mainResponse;
            if (result) {
                dispatch(stashAttributesForSearchResults(result));
                dispatch(quickSearchSuccess(result));
            }
        })
        .catch((err) => dispatch(quickSearchFailure(err.message)));
};

export const triggerQuickSearchLoadMoreForType = ({
    type,
    query,
    excludeExternalAgencyResults,
}) => (dispatch, getState) => {
    dispatch(quickSearchLoadMoreStart(type));
    const { size } = quickSearchSectionQuerySelector(getState(), { type });
    const newSize = size + QUICK_SEARCH_QUERY_SIZE;
    if (type === EntityTypeEnum.BOOKING.name) {
        return bookingsResource
            .searchBookings({ query, limit: newSize })
            .then((result) => {
                dispatch(
                    quickSearchLoadMoreSuccess(type, {
                        bookings: { ...result, query: { from: 0, size: newSize } },
                    })
                );
            })
            .catch((err) => dispatch(quickSearchLoadMoreFailure(type, err.message)));
    }
    return elasticSearchResource
        .searchAll({
            from: 0,
            query,
            searchTypes: [type],
            size: newSize,
            excludeExternalAgencyResults,
        })
        .then((result) => {
            if (result) {
                dispatch(stashAttributesForSearchResults(result));
            }
            dispatch(quickSearchLoadMoreSuccess(type, result));
        })
        .catch((err) => dispatch(quickSearchLoadMoreFailure(type, err.message)));
};

export const updateQuickSearchQuery = (query) => {
    return { type: QUICK_SEARCH_UPDATE_QUERY, payload: { query } };
};

const quickSearchStart = () => ({ type: QUICK_SEARCH_START });

const quickSearchSuccess = (data = {}) => ({ type: QUICK_SEARCH_SUCCEESS, payload: { data } });

const quickSearchFailure = (error) => ({ type: QUICK_SEARCH_FAILURE, payload: { error } });

const quickSearchLoadMoreStart = (type) => ({
    type: QUICK_SEARCH_LOAD_MORE_START,
    payload: { type },
});

const quickSearchLoadMoreSuccess = (type, data) => ({
    type: QUICK_SEARCH_LOAD_MORE_SUCCESS,
    payload: { data, type },
});

const quickSearchLoadMoreFailure = (type, error) => ({
    type: QUICK_SEARCH_LOAD_MORE_FAILURE,
    payload: { type, error },
});

export const resetQuickSearchState = () => {
    return { type: QUICK_SEARCH_RESET_STATE };
};

const initialState = {
    isLoading: false,
    error: undefined,
    data: {},
    query: '',
};

const subSectionReducer = (state, action) => {
    const { type, data, error } = action.payload;
    const subSectionPropKey = quickSearchResultTypeToPropMap[type];

    switch (action.type) {
        case QUICK_SEARCH_LOAD_MORE_START:
            return {
                ...state,
                data: {
                    ...state.data,
                    [subSectionPropKey]: {
                        ...state.data[subSectionPropKey],
                        isLoading: true,
                        error: undefined,
                    },
                },
            };
        case QUICK_SEARCH_LOAD_MORE_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    [subSectionPropKey]: {
                        ...state.data[subSectionPropKey],
                        ...data[subSectionPropKey],
                        isLoading: false,
                        error: undefined,
                        items: [...data[subSectionPropKey].items],
                    },
                },
            };
        case QUICK_SEARCH_LOAD_MORE_FAILURE:
            return {
                ...state,
                data: {
                    ...state.data,
                    [type]: {
                        ...state.data[type],
                        isLoading: false,
                        error,
                    },
                },
            };
        default:
            return state;
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case QUICK_SEARCH_UPDATE_QUERY:
            return {
                ...state,
                query: action.payload.query,
                // set `error` upon query change
                // to prevent ui flickering
                error: undefined,
            };
        case QUICK_SEARCH_START:
            return { ...state, isLoading: true, error: undefined, data: {} };

        case QUICK_SEARCH_SUCCEESS:
            return { ...state, isLoading: false, data: action.payload.data };

        case QUICK_SEARCH_FAILURE:
            return { ...state, isLoading: false, error: action.payload.error };
        case QUICK_SEARCH_LOAD_MORE_START:
        case QUICK_SEARCH_LOAD_MORE_SUCCESS:
        case QUICK_SEARCH_LOAD_MORE_FAILURE:
            return subSectionReducer(state, action);
        default:
            return state;
    }
};

const baseStateSelector = (state) => state.ui.quickSearch;

export const quickSearchResultsSelector = (state) => baseStateSelector(state).data;

export const quickSearchisLoadingSelector = (state) => baseStateSelector(state).isLoading;

export const quickSearchErrorSelector = (state) => baseStateSelector(state).error;

export const quickSearchQuerySelector = (state) => baseStateSelector(state).query;

const quickSearchSectionDataSelector = (state, props) => {
    const data = quickSearchResultsSelector(state);

    return data[quickSearchResultTypeToPropMap[props.type]];
};

export const quickSearchHasResultsSelector = (state) => {
    const data = quickSearchResultsSelector(state);
    return allowedQuickSearchTypesSelector(state).some((type) => {
        const sectionData = data[quickSearchResultTypeToPropMap[type]];
        return sectionData && sectionData.items.length;
    });
};

const quickSearchSectionQuerySelector = (state, props) => {
    const data = quickSearchSectionDataSelector(state, props);
    if (!data) {
        return { from: 0, size: QUICK_SEARCH_QUERY_SIZE, elasticQuery: '' };
    }
    return data.query;
};

export const quickSearchIsSectionLoadingForTypeSelector = createSelector(
    quickSearchResultsSelector,
    (quickSearchResults) => (type) => {
        const sectionState = quickSearchResults[quickSearchResultTypeToPropMap[type]];

        return sectionState?.isLoading;
    }
);

export const quickSearchDefaultResultTypesSelector = (state) =>
    allowedQuickSearchTypesSelector(state);

export default makeResettable(QUICK_SEARCH_RESET_STATE, reducer, initialState);

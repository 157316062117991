import _, {
    chain,
    get,
    includes,
    map,
    omit,
    pick,
    reduce,
    reject,
    first,
    mapKeys,
    concat,
    compact,
} from 'lodash';
import { createSelector } from 'reselect';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import {
    formatTimeOnly,
    START_OF_DAY_TIME,
    END_OF_DAY_TIME,
} from '~/client-common/core/dates/utils/dateHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { elasticAttributeDetailsSelector } from '~/client-common/core/domain/elastic-attribute-details/state/data';
import { nibrsOffenseCodeByCodeSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/data';
import combineNibrsOffenseCodeIds from '~/client-common/core/domain/nibrs-offense-codes/utils/combineNibrsOffenseCodeIds';
import {
    allSubdivisionAttrIdNames,
    includeLocationSubdivisions,
    getSubdivisionsFromLocation,
    getElasticQuerySubdivisionAttrIds,
} from '~/client-common/core/domain/locations/utils/subdivisionHelpers';
import { DISPLAY_ONLY_ORGANIZATION_LABEL } from '~/client-common/core/enums/universal/fields';
import { createFormModule } from '../../../../core/forms';
import {
    parseOffenseCaseStatusFromReportDefinitions,
    parseHasOffenseCaseStatusFromReportDefinitions,
} from '../../../../core/elastic-search/util/parseOffenseCaseStatusFromReportDefinitions';
import {
    extractDateTimeModelFromQuery,
    dateFormFieldsToElasticQueryFields,
} from '../../../../core/elastic-search/util/extractDateTimeModelFromQuery';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import {
    identifiersFieldsetViewModel,
    locationsFieldsetViewModel,
    organizationsFieldsetViewModel,
    personDetailsFieldsetViewModel,
    personnelFieldsetViewModel,
    personsFieldsetViewModel,
    physicalAttributesFieldsetViewModel,
    propertiesFieldsetViewModel,
    reportDetailsFieldsetViewModel,
    reportDefinitionOffenseFieldList,
    reportDefinitionArrestFieldList,
    reportDefinitionChargeFieldList,
    reportDefinitionUseOfForceFieldList,
    reportDefinitionUseOfForceSubjectFieldList,
    reportDefinitionSupplementFieldList,
    reportDefinitionTowVehicleFieldList,
    reportDefinitionMissingPersonsFieldList,
    vehiclesFieldsetViewModel,
    vehicleCautionsFieldsetViewModel,
    behaviorsFieldsetViewModel,
} from '../../../../../legacy-redux/configs/fieldsetsConfig';
import {
    convertFormModelToNameAttributes,
    convertElasticPersonQueryToBehaviorsFormModel,
    convertPersonCautionsFormModelToFilterGroups,
    convertFormModelToPersonCautionsElasticQuery,
} from '../../../persons/state/forms/advancedSearchPersonsForm';
import {
    convertVehicleCautionsFormModelToFilterGroups,
    convertFormModelToVehicleCautionsElasticQuery,
} from '../../../vehicles/state/forms/advancedSearchVehiclesForm';
import { convertDragonReportFieldsToElasticConfiguredEntityQuery } from '../../utils/dragon/convertDragonReportFieldsToElasticConfiguredEntityQuery';
import { convertElasticConfiguredEntityQueryToDragonReportFields } from '../../utils/dragon/convertElasticConfiguredEntityQueryToDragonReportFields';

const strings = componentStrings.search.AdvancedSearchReportsForm;

const advancedSearchReportsFormFieldViewModels = {
    ...buildFlatFormFieldViewModels([
        // fields in the form that are not nested
        'fuzzyMatchingEnabled',
    ]),
    reportDetails: reportDetailsFieldsetViewModel,
    personnel: personnelFieldsetViewModel,
    involvedPersons: {
        ...personsFieldsetViewModel,
        key: 'involvedPersons',
        title: strings.fieldsetTitles.involvedPersons,
    },
    involvedProperty: {
        ...propertiesFieldsetViewModel,
        key: 'involvedProperty',
        title: strings.fieldsetTitles.involvedProperty,
    },
    involvedVehicles: {
        ...vehiclesFieldsetViewModel,
        key: 'involvedVehicles',
        title: strings.fieldsetTitles.involvedVehicles,
    },
    involvedLocations: {
        ...locationsFieldsetViewModel,
        key: 'involvedLocations',
        title: strings.fieldsetTitles.involvedLocations,
    },
    involvedOrganizations: {
        ...organizationsFieldsetViewModel,
        key: 'involvedOrganizations',
    },
};

const convertStringToBoolean = (value) => {
    if (value === 'false') {
        return false;
    }
    if (value === 'true') {
        return true;
    }
    return undefined;
};

/**
 * Convert the given search query model to form state. While the query is flat,
 *   the form state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @param  {Object} state redux state from form module
 * @param  {Object} boundSelectors extra selectors from search module
 * @return {Object} Form state.
 */
export function convertAdvancedSearchReportsElasticQueryToFormModel(
    elasticQuery,
    state,
    boundSelectors
) {
    const hasUcrCodeVal = get(elasticQuery, 'hasUcrCode');
    const hasCustomReportClassificationAttrIdVal = get(
        elasticQuery,
        'hasCustomReportClassificationAttrId'
    );
    const hasRoutingLabel = get(elasticQuery, 'hasRoutingLabel');
    const hasOffenseCaseStatusVal = parseHasOffenseCaseStatusFromReportDefinitions(elasticQuery);

    const clientApprovalStatuses = get(elasticQuery, 'clientApprovalStatuses');

    const elasticAttributeDetails = state
        ? elasticAttributeDetailsSelector(state)
        : get(boundSelectors, 'elasticAttributeDetails', {});

    const subdivisions = getElasticQuerySubdivisionAttrIds(elasticQuery, 'involvedLocations');
    const dateTime = extractDateTimeModelFromQuery(elasticQuery);

    return buildFormModel(
        {
            fuzzyMatchingEnabled: get(elasticQuery, 'fuzzyMatchingEnabled'),
            reportDetails: {
                ...pick(elasticQuery, map(reportDetailsFieldsetViewModel.fields, 'key')),
                dateType: get(dateTime, 'dateType'),
                withinLastPeriod: get(dateTime, 'withinLastPeriod'),
                startTime: formatTimeOnly(get(elasticQuery, 'startTime')),
                endTime: formatTimeOnly(get(elasticQuery, 'endTime')),
                clientApprovalStatuses,
                ucrGroup: get(elasticQuery, 'ucrGroups'),
                hasRoutingLabel: [hasRoutingLabel],
                offenseCaseStatusAttrId: parseOffenseCaseStatusFromReportDefinitions(elasticQuery),
                reportDefinitions: map(
                    get(elasticQuery, 'reportDefinitions'),
                    ({
                        offensesAndIncidents,
                        charges,
                        arrest,
                        useOfForce,
                        towVehicle,
                        supplement,
                        missingPerson,
                        configuredEntities,
                        ...reportDefinition
                    }) => {
                        const useOfForceFields = omit(useOfForce, 'subjects');
                        const useOfForceSubjectFields = useOfForce
                            ? reduce(
                                  filterFormData(useOfForce.subjects),
                                  (acc, val) => ({ ...acc, ...val }),
                                  {}
                              )
                            : {};

                        return {
                            ...reportDefinition,
                            ...towVehicle,
                            ...supplement,
                            ...(offensesAndIncidents
                                ? _(offensesAndIncidents[0])
                                      .omit(['offenseCodeIds, offenseCaseStatusAttrId'])
                                      .mapValues((value, key) => {
                                          // offense-level hasUcrCode field
                                          if (
                                              key === 'hasUcrCode' ||
                                              key === 'hasCustomReportClassificationAttrId'
                                          ) {
                                              // see below
                                              if (value === false) {
                                                  return ['false'];
                                              }
                                              if (value === true) {
                                                  return ['true'];
                                              }
                                          }
                                          return value;
                                      })
                                      .mapKeys((field, key) =>
                                          key.replace(
                                              /^([a-z])/,
                                              (k, firstLetter) =>
                                                  `offense${firstLetter.toUpperCase()}`
                                          )
                                      )
                                      .thru((elasticOffense) => {
                                          return {
                                              ...omit(elasticOffense, 'offenseNibrsCodes'),
                                              offenseNibrsCodeIds: combineNibrsOffenseCodeIds({
                                                  ids: elasticOffense.offenseNibrsCodeIds,
                                                  codes: elasticOffense.offenseNibrsCodes,
                                                  codeByCode: state
                                                      ? nibrsOffenseCodeByCodeSelector(state)
                                                      : get(
                                                            boundSelectors,
                                                            'nibrsOffenseCodeByCode'
                                                        ),
                                              }),
                                              offenseIncidentOffenseCodeIds: get(
                                                  offensesAndIncidents[0],
                                                  'offenseCodeIds'
                                              ),
                                          };
                                      })
                                      .value()
                                : {}),
                            ...(charges
                                ? _(charges[0])
                                      .mapKeys((field, key) =>
                                          key.replace(
                                              /^([a-z])/,
                                              (k, firstLetter) =>
                                                  `arrest${firstLetter.toUpperCase()}`
                                          )
                                      )
                                      .value()
                                : {}),
                            ...(arrest
                                ? mapKeys(arrest, (field, key) => {
                                      if (key === 'recordNumber') {
                                          return 'arrestLocalId';
                                      } else {
                                          return key;
                                      }
                                  })
                                : {}),
                            ...useOfForceFields,
                            ...useOfForceSubjectFields,
                            ...missingPerson,
                            ...convertElasticConfiguredEntityQueryToDragonReportFields(
                                configuredEntities
                            ),
                        };
                    }
                ),
                // report-level hasUcrCode field
                // ad hoc conversion to make the UCR Code dropdown work because it
                // supports one field that is an array (ucrCodes) and one field that
                // is a boolean (hasUcrCode), but the value is still always an
                // array... 'false' is a string because a multiselect value is
                // always a string
                ...(hasUcrCodeVal !== undefined ? { hasUcrCode: [hasUcrCodeVal.toString()] } : {}),
                ...(hasCustomReportClassificationAttrIdVal !== undefined
                    ? {
                          hasCustomReportClassificationAttrId: [
                              hasCustomReportClassificationAttrIdVal.toString(),
                          ],
                      }
                    : {}),
                ...(hasOffenseCaseStatusVal !== undefined
                    ? {
                          hasOffenseCaseStatus: hasOffenseCaseStatusVal.toString(),
                      }
                    : {}),
                ...subdivisions, // move from locations
                // convert the array to a single value because the form input is a
                // single-value dropdown
                caseStatusAttrId: get(elasticQuery, 'caseStatusAttrIds[0]'),
            },
            personnel: {
                ..._(elasticQuery)
                    .pick(map(personnelFieldsetViewModel.fields, 'key'))
                    .omit('caseStatusAttrIds')
                    .value(),
            },
            involvedPersons: map(
                get(elasticQuery, 'involvedPersons'),
                ({
                    person,
                    involvement,
                    wasFrisked,
                    injuries,
                    ageAtTimeOfReportRangeStart,
                    ageAtTimeOfReportRangeEnd,
                }) => ({
                    personDetails: {
                        ...pick(person, map(personDetailsFieldsetViewModel.fields, 'key')),
                        involvement,
                        wasFrisked,
                        ageRangeStart: ageAtTimeOfReportRangeStart,
                        ageRangeEnd: ageAtTimeOfReportRangeEnd,
                    },
                    physicalAttributes: pick(
                        person,
                        map(physicalAttributesFieldsetViewModel.fields, 'key')
                    ),
                    injuries,
                    identifyingMarks: get(person, 'identifyingMarks'),
                    clothing: get(person, 'clothing'),
                    behaviors: convertElasticPersonQueryToBehaviorsFormModel(
                        person,
                        elasticAttributeDetails
                    ),
                    identifiers: pick(person, map(identifiersFieldsetViewModel.fields, 'key')),
                })
            ),
            involvedProperty: map(
                get(elasticQuery, 'involvedProperty'),
                ({ property, propertyStatusAttrIds, itemIdentifiers }) => ({
                    ...pick(property, map(propertiesFieldsetViewModel.fields, 'key')),
                    propertyStatusAttrIds,
                    itemIdentifiers: itemIdentifiers
                        ? {
                              itemIdentifiers,
                          }
                        : undefined,
                })
            ),
            involvedVehicles: map(
                get(elasticQuery, 'involvedVehicles'),
                ({ vehicle, propertyStatusAttrIds, itemIdentifiers, cautions = [] }) => ({
                    ...pick(vehicle, map(vehiclesFieldsetViewModel.fields, 'key')),
                    propertyStatusAttrIds,
                    itemIdentifiers: itemIdentifiers
                        ? {
                              itemIdentifiers,
                          }
                        : undefined,
                    cautions: pick(
                        cautions[0],
                        map(vehicleCautionsFieldsetViewModel.fields, 'key')
                    ),
                })
            ),
            involvedLocations: chain(elasticQuery)
                .get('involvedLocations')
                // move the subdivisions-only location to `reportDetails`
                .reject(includeLocationSubdivisions)
                .value(),
            involvedOrganizations: map(
                get(elasticQuery, 'involvedOrganizations'),
                ({ org, involvement }) => ({
                    ...pick(org, map(organizationsFieldsetViewModel.fields, 'key')),
                    involvement,
                })
            ),
        },
        advancedSearchReportsFormFieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertAdvancedSearchReportsFormModelToElasticQuery(formModel = {}) {
    const hasRoutingLabel = first(get(formModel, 'reportDetails.hasRoutingLabel'));
    const offenseCaseStatusAttrId = get(formModel.reportDetails, 'offenseCaseStatusAttrId');
    const hasOffenseCaseStatusVal = get(formModel.reportDetails, 'hasOffenseCaseStatus');
    const dateFields = dateFormFieldsToElasticQueryFields(formModel.reportDetails);

    return filterFormData(
        {
            ...dateFields,
            fuzzyMatchingEnabled: formModel.fuzzyMatchingEnabled,
            ...omit(formModel.reportDetails, [
                'reportDefinitions', // set below
                'hasUcrCode', // set below
                'hasCustomReportClassificationAttrId', // set below
                'ucrGroup',
                'secondaryApprovalStatuses', // set below
                ...allSubdivisionAttrIdNames, // move to locations
                'caseStatusAttrId', // expanded into array
                'offenseCaseStatusAttrId', // part of reportDefinitions
                'hasOffenseCaseStatus', // part of reportDefinitions
                'dateType',
                'withinLastPeriod',
                'toDatePeriod',
                'startDateUtc',
                'endDateUtc',
                'legacyInformationMetadataQuery', // set below
            ]),
            hasRoutingLabel,
            reportDefinitions: map(
                compact(
                    concat(
                        get(formModel.reportDetails, 'reportDefinitions'),
                        offenseCaseStatusAttrId && { offenseCaseStatusAttrId },
                        hasOffenseCaseStatusVal && {
                            hasOffenseCaseStatus: convertStringToBoolean(hasOffenseCaseStatusVal),
                        }
                    )
                ),
                (reportDefinition) => ({
                    ...omit(reportDefinition, [
                        ...reportDefinitionOffenseFieldList,
                        ...reportDefinitionChargeFieldList,
                        ...reportDefinitionArrestFieldList,
                        ...reportDefinitionUseOfForceFieldList,
                        ...reportDefinitionUseOfForceSubjectFieldList,
                        ...reportDefinitionTowVehicleFieldList,
                        ...reportDefinitionSupplementFieldList,
                        ...reportDefinitionMissingPersonsFieldList,
                        'hasUcrCode',
                        'hasCustomReportClassificationAttrId',
                        'ucrGroup',
                        // dragon search properties
                        'reportConfiguredEntityProperties',
                        'keywords',
                    ]),
                    configuredEntities: convertDragonReportFieldsToElasticConfiguredEntityQuery(
                        filterFormData(reportDefinition)
                    ),
                    towVehicle: pick(reportDefinition, reportDefinitionTowVehicleFieldList),
                    useOfForce: {
                        ...pick(reportDefinition, reportDefinitionUseOfForceFieldList),
                        // We submit these in their own set so we can search by OR
                        // rather than AND, ie, we match if "any subject matches any criteria"
                        // rather than "subject matches all criteria".
                        // This means each property needs to be its own object,
                        // [
                        //    { key: value },
                        //    { key: value }
                        // ]
                        // instead of
                        // {
                        //     key: value,
                        //     key: value
                        // }
                        subjects: _(reportDefinition)
                            .pick(reportDefinitionUseOfForceSubjectFieldList)
                            .map((value, key) => {
                                return {
                                    [key]: value,
                                };
                            })
                            .value(),
                    },
                    // two elastic query arrays that are flat fields on the report
                    // definition
                    offensesAndIncidents: [
                        _(reportDefinition)
                            .pick(reportDefinitionOffenseFieldList)
                            .omit(['offenseIncidentOffenseCodeIds'])
                            .mapKeys((field, key) => {
                                if (key !== 'offenseCaseStatusAttrId') {
                                    return key.replace(/^offense([A-Z])/, (k, firstLetter) =>
                                        firstLetter.toLowerCase()
                                    );
                                }
                                return key;
                            })
                            .mapValues((value, key) => {
                                // offense-level hasUcrCode field, see above
                                if (
                                    key === 'hasUcrCode' ||
                                    key === 'hasCustomReportClassificationAttrId'
                                ) {
                                    if (includes(value, 'false')) {
                                        return false;
                                    }
                                    if (includes(value, 'true')) {
                                        return true;
                                    }
                                }
                                return value;
                            })
                            .thru((elasticOffense) => {
                                const completeElasticOffense = {
                                    ...elasticOffense,
                                    offenseCodeIds:
                                        reportDefinition.offenseIncidentOffenseCodeIds || [],
                                };

                                if (elasticOffense.hasUcrCode === false) {
                                    return {
                                        ...completeElasticOffense,
                                        isOffense: true,
                                    };
                                }
                                return completeElasticOffense;
                            })
                            .value(),
                    ],
                    charges: [
                        _(reportDefinition)
                            .pick(reportDefinitionChargeFieldList)
                            .mapKeys((field, key) =>
                                key.replace(/^arrest([A-Z])/, (k, firstLetter) =>
                                    firstLetter.toLowerCase()
                                )
                            )
                            .value(),
                    ],
                    arrest: mapKeys(
                        pick(reportDefinition, reportDefinitionArrestFieldList),
                        (values, key) => {
                            if (key === 'arrestLocalId') {
                                return 'recordNumber';
                            } else {
                                return key;
                            }
                        }
                    ),
                    supplement: pick(reportDefinition, reportDefinitionSupplementFieldList),
                    missingPerson: pick(reportDefinition, reportDefinitionMissingPersonsFieldList),
                })
            ),
            ...(chain(formModel.reportDetails) // report-level hasUcrCode field
                .get('hasUcrCode')
                .includes('false') // see above
                .value()
                ? { hasUcrCode: false }
                : {}),
            ...(chain(formModel.reportDetails).get('hasUcrCode').includes('true').value()
                ? { hasUcrCode: true }
                : {}),
            ...(chain(formModel.reportDetails)
                .get('hasCustomReportClassificationAttrId')
                .includes('false')
                .value()
                ? { hasCustomReportClassificationAttrId: false }
                : {}),
            ...(chain(formModel.reportDetails)
                .get('hasCustomReportClassificationAttrId')
                .includes('true')
                .value()
                ? { hasCustomReportClassificationAttrId: true }
                : {}),
            ...omit(formModel.personnel, 'caseStatusAttrId'),
            ucrGroups: get(formModel.reportDetails, 'ucrGroup'),
            // convert value to array
            caseStatusAttrIds: [get(formModel.reportDetails, 'caseStatusAttrId')],
            involvedPersons: map(formModel.involvedPersons, (person) => ({
                person: {
                    // the person model is nested alongside other fields
                    ...omit(person.personDetails, [
                        'involvement',
                        'wasFrisked',
                        'ageRangeStart',
                        'ageRangeEnd',
                    ]),
                    ...person.physicalAttributes,
                    identifyingMarks: person.identifyingMarks,
                    clothing: person.clothing,
                    ...person.identifiers,
                    ...convertFormModelToNameAttributes(person),
                    ...convertFormModelToPersonCautionsElasticQuery(person),
                },
                involvement: get(person.personDetails, 'involvement'),
                wasFrisked: get(person.personDetails, 'wasFrisked'),
                injuries: person.injuries,
                ageAtTimeOfReportRangeStart: get(person.personDetails, 'ageRangeStart'),
                ageAtTimeOfReportRangeEnd: get(person.personDetails, 'ageRangeEnd'),
            })),
            involvedProperty: map(formModel.involvedProperty, (property) => ({
                property: omit(property, ['propertyStatusAttrIds', 'itemIdentifiers']),
                propertyStatusAttrIds: property.propertyStatusAttrIds,
                itemIdentifiers: get(property, 'itemIdentifiers.itemIdentifiers'),
            })),
            involvedVehicles: map(formModel.involvedVehicles, (vehicle) => ({
                vehicle: omit(vehicle, ['propertyStatusAttrIds', 'itemIdentifiers', 'cautions']),
                propertyStatusAttrIds: vehicle.propertyStatusAttrIds,
                itemIdentifiers: get(vehicle, 'itemIdentifiers.itemIdentifiers'),
                ...convertFormModelToVehicleCautionsElasticQuery({ vehicle }),
            })),
            involvedLocations: [
                ...(formModel.involvedLocations || []),
                ...(includeLocationSubdivisions(formModel.reportDetails)
                    ? [getSubdivisionsFromLocation(formModel.reportDetails)]
                    : []),
            ],
            involvedOrganizations: map(formModel.involvedOrganizations, (org) => ({
                org: omit(org, ['involvement']),
                involvement: org.involvement,
            })),
            legacyInformationMetadataQuery: {
                legacySystemAttrId: get(
                    formModel.reportDetails,
                    'reportDefinitions[0].legacySystemAttrId'
                ),
                legacyReportTypeAttrId: get(
                    formModel.reportDetails,
                    'reportDefinitions[0].legacyReportTypeAttrId'
                ),
                legacyReportSubTypeAttrId: get(
                    formModel.reportDetails,
                    'reportDefinitions[0].legacyReportSubTypeAttrId'
                ),
                customReportClassificationAttrId: get(
                    formModel.reportDetails,
                    'reportDefinitions[0].customReportClassificationAttrId'
                ),
                eventStatisticAttrIds: get(
                    formModel.reportDetails,
                    'reportDefinitions[0].eventStatisticAttrIds'
                ),
            },
        },
        advancedSearchReportsFormFieldViewModels
    );
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @return {Object}   Array of filter group view models.
 */
export function convertAdvancedSearchReportsFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    const combinedSubdivisionsLabel = boundSelectors.combinedSubdivisionsLabel;
    const formatFieldByName = boundSelectors.formatFieldByName;
    const globalSequenceNumberLabel = boundSelectors.globalSequenceNumberLabel;

    const organizationLabel = formatFieldByName(DISPLAY_ONLY_ORGANIZATION_LABEL);

    const fieldViewModels = {
        ...advancedSearchReportsFormFieldViewModels,
        reportDetails: {
            ...advancedSearchReportsFormFieldViewModels.reportDetails,
            fields: {
                ...advancedSearchReportsFormFieldViewModels.reportDetails.fields,
                subdivisionAttrIds: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .subdivisionAttrIds,
                    label: combinedSubdivisionsLabel,
                },
                subdivision1AttrIds: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .subdivision1AttrIds,
                    label: formatFieldByName(
                        advancedSearchReportsFormFieldViewModels.reportDetails.fields
                            .subdivision1AttrIds.fieldName
                    ),
                },
                subdivision2AttrIds: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .subdivision2AttrIds,
                    label: formatFieldByName(
                        advancedSearchReportsFormFieldViewModels.reportDetails.fields
                            .subdivision2AttrIds.fieldName
                    ),
                },
                subdivision3AttrIds: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .subdivision3AttrIds,
                    label: formatFieldByName(
                        advancedSearchReportsFormFieldViewModels.reportDetails.fields
                            .subdivision3AttrIds.fieldName
                    ),
                },
                subdivision4AttrIds: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .subdivision4AttrIds,
                    label: formatFieldByName(
                        advancedSearchReportsFormFieldViewModels.reportDetails.fields
                            .subdivision4AttrIds.fieldName
                    ),
                },
                subdivision5AttrIds: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .subdivision5AttrIds,
                    label: formatFieldByName(
                        advancedSearchReportsFormFieldViewModels.reportDetails.fields
                            .subdivision5AttrIds.fieldName
                    ),
                },
                eventStatisticAttrIds: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .eventStatisticAttrIds,
                    label: formatFieldByName(
                        advancedSearchReportsFormFieldViewModels.reportDetails.fields
                            .eventStatisticAttrIds.fieldName
                    ),
                },
                globalSequenceNumber: {
                    ...advancedSearchReportsFormFieldViewModels.reportDetails.fields
                        .globalSequenceNumber,
                    label: globalSequenceNumberLabel,
                },
            },
        },
        involvedOrganizations: {
            ...organizationsFieldsetViewModel,
            key: 'involvedOrganizations',
            title: (index) =>
                strings.fieldsetTitles.involvedOrganizations(organizationLabel, index),
            fields: {
                ...organizationsFieldsetViewModel.fields,
                name: {
                    ...organizationsFieldsetViewModel.fields.name,
                    label: organizationsFieldsetViewModel.fields.name.label(organizationLabel),
                },
            },
        },
        involvedPersons: {
            ...advancedSearchReportsFormFieldViewModels.involvedPersons,
            fields: {
                ...advancedSearchReportsFormFieldViewModels.involvedPersons.fields,
                behaviors: {
                    ...personsFieldsetViewModel.fields.behaviors,
                    fields: {
                        ...behaviorsFieldsetViewModel.fields,
                        ...convertPersonCautionsFormModelToFilterGroups(formatFieldByName),
                    },
                },
            },
        },
        involvedVehicles: {
            ...advancedSearchReportsFormFieldViewModels.involvedVehicles,
            fields: {
                ...vehiclesFieldsetViewModel.fields,
                ...convertVehicleCautionsFormModelToFilterGroups(formatFieldByName),
            },
        },
    };

    const filterGroups = convertFormModelToFilterGroups(
        formModel,
        fieldViewModels,
        formatFieldValue,
        formatFieldByName
    );

    /**
     * OUTDATED: This is a temporary fix to hide the field `hasSecondaryApprovalStatus` until
     * it has its own dropdown.
     *
     * See:
     * - https://mark43.atlassian.net/browse/RMS-3265 (bug)
     * - https://mark43.atlassian.net/browse/RMS-3296 (proposed solution)
     *
     * Once 3296 is implemented this should be removed.
     *
     * UPDATE: instead of creating a new dropdown for this field, we have opted to add a
     * "None" option to ClientSecondaryApprovalStatusSelect, so keeping this here
     * (see comments in https://mark43.atlassian.net/browse/RMS-3296)
     */
    const cleanedFilterGroups = map(filterGroups, (filterGroup) => {
        return {
            ...filterGroup,
            fields: reject(filterGroup.fields, { key: 'hasSecondaryApprovalStatus' }),
        };
    });

    return cleanedFilterGroups;
}

const advancedSearchReportsForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_REPORTS,
    fieldViewModels: advancedSearchReportsFormFieldViewModels,
    convertToFormModel: convertAdvancedSearchReportsElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchReportsFormModelToElasticQuery,
});

advancedSearchReportsForm.selectors.formIsEmptySelector = createSelector(
    advancedSearchReportsForm.selectors.formModelSelector,
    (formModel) =>
        formDataIsEmpty(omit(formModel, 'fuzzyMatchingEnabled', 'reportDetails.dateType'))
);

// If either startTime or endTime is missing we prefill the missing one
// because a time range doesn't make sense without a start and end
advancedSearchReportsForm.actionCreators.updateShiftTimeRange = () => {
    return (dispatch, getState) => {
        const formModel = advancedSearchReportsForm.selectors.formModelSelector(getState());
        let { startTime, endTime } = formModel.reportDetails || {};
        if (startTime && !endTime) {
            endTime = END_OF_DAY_TIME;
        } else if (endTime && !startTime) {
            startTime = START_OF_DAY_TIME;
        }
        dispatch(
            advancedSearchReportsForm.actionCreators.changePath(
                'reportDetails.startTime',
                startTime
            )
        );
        dispatch(
            advancedSearchReportsForm.actionCreators.changePath('reportDetails.endTime', endTime)
        );
    };
};

/**
 * Module of the advanced search reports form.
 * @type {Object}
 */
export default advancedSearchReportsForm;

import { filter, first, includes } from 'lodash';
import { createSelector } from 'reselect';
import Promise from 'bluebird';
import {
    EntityTypeEnum,
    TasksView,
    RefContextEnum,
    TaskEntityLinkView,
    TaskView,
} from '@mark43/rms-api';
import { userHasAbilitySelector } from '~/client-common/core/domain/abilities/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import {
    deleteTaskSuccess,
    NEXUS_STATE_PROP as TASK_VIEWS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/taskViews/state/data';
import {
    NEXUS_STATE_PROP as TASK_ENTITY_LINKS_NEXUS_STATE_PROP,
    taskEntityLinksSelector,
} from '~/client-common/core/domain/task-entity-links/state/data';
import { NEXUS_STATE_PROP as TASK_COMMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/task-comments/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { abilitiesEnum } from '../../../../core/abilities';
import {
    convertFromFormModel,
    convertToFormModel,
    FormTaskConfiguration,
} from '../../forms/taskForm';
import {
    defaultTaskPriorityAttributeIdSelector,
    todoTaskStatusAttributeIdSelector,
    defaultTaskTypeAttributeIdSelector,
    unpersistedTaskAttachmentsSelector,
    withTasksView,
    withComment,
} from '../../data';
import taskResource from '../../resources/taskResource';
import { getTaskEntityLinkTitle } from '../../utils/getTaskEntityLinkTitle';
import { RmsAction } from '../../../../../core/typings/redux';
import { allowedTaskLinkEntityTypes } from '../../config';

const strings = componentStrings.tasks.core;

export enum TaskOverlayMode {
    'CREATE',
    'EDIT',
    'VIEW',
}

export type TaskOverlayCustomProperties = {
    task?: TaskView;
    taskEntityLinks?: Partial<TaskEntityLinkView>[];
    mode: TaskOverlayMode;
};

export interface PendingComment {
    comment: string;
    createdBy: number;
    createdDateUtc: string;
}

/**
 * Open the task side panel for creating a new task without any linked entities.
 */
export function openTaskSidePanel(): RmsAction<void> {
    return function (dispatch, getState, { overlayStore }) {
        dispatch(prefillTaskFormOnTaskCreation());
        overlayStore.open(overlayIdEnum.TASK_OVERLAY);
    };
}

/**
 * Open the task side panel for creating a new task with one or more linked entities.
 */
export function openTaskSidePanelForEntity(
    taskEntityLinks: Partial<TaskEntityLinkView>[]
): RmsAction<void> {
    return function (dispatch, getState, { overlayStore }) {
        dispatch(prefillTaskFormOnTaskCreation(taskEntityLinks));

        const ownerTaskEntityLink = first(taskEntityLinks);
        const task = ownerTaskEntityLink
            ? {
                  ownerTitle: getTaskEntityLinkTitle(ownerTaskEntityLink),
                  ownerId: ownerTaskEntityLink?.entityId,
                  ownerType: ownerTaskEntityLink?.entityType,
              }
            : undefined;

        overlayStore.open(overlayIdEnum.TASK_OVERLAY, {
            task,
            taskEntityLinks,
            mode: TaskOverlayMode.CREATE,
        });
    };
}

/**
 * Open the task side panel for editing an existing task.
 */
export function openTaskSidePanelForTask(task: TaskView, mode: TaskOverlayMode): RmsAction<void> {
    return function (dispatch, getState, { formsRegistry, overlayStore }) {
        const state = getState();
        const taskEntityLinksEnabled = applicationSettingsSelector(state)
            .RMS_TASK_ENTITY_LINKS_ENABLED as boolean;
        const taskEntityLinks = filter(taskEntityLinksSelector(state), { taskId: task.id });
        formsRegistry.maybeDeferredOperation<FormTaskConfiguration>(
            RefContextEnum.FORM_TASK.name,
            undefined,
            (form) => form.set(convertToFormModel(task, taskEntityLinksEnabled, taskEntityLinks))
        );

        overlayStore.open(overlayIdEnum.TASK_OVERLAY, { task, mode });
    };
}

export function deleteTask(taskId: number): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus, overlayStore }) => {
        return taskResource
            .deleteTask(taskId)
            .then((success) => {
                if (!success) {
                    throw new Error(strings.errorDeletingTask);
                }

                dispatch(
                    nexus.withRemove(
                        TASK_VIEWS_NEXUS_STATE_PROP,
                        { id: taskId },
                        nexus.withRemove(
                            TASK_ENTITY_LINKS_NEXUS_STATE_PROP,
                            { taskId },
                            nexus.withRemove(
                                TASK_COMMENTS_NEXUS_STATE_PROP,
                                { taskId },
                                deleteTaskSuccess(taskId)
                            )
                        )
                    )
                );
            })
            .catch((error) => {
                overlayStore.setError(overlayIdEnum.TASK_ERROR_OVERLAY, error.message);
                overlayStore.open(overlayIdEnum.TASK_ERROR_OVERLAY);
            });
    };
}

export function updateTask(
    task: Partial<TaskView>,
    taskEntityLinks: Partial<TaskEntityLinkView>[],
    updates: PendingComment[] = []
): RmsAction<Promise<TasksView | void>> {
    return function () {
        // filter out links with disallowed entityTypes
        // these may exist in the task form state since we display them to the user in the same way as normal taskEntityLinks
        const filteredTaskEntityLinks = filter(taskEntityLinks, (taskEntityLink) =>
            includes(allowedTaskLinkEntityTypes, taskEntityLink.entityType)
        );

        return taskResource
            .upsertTask(task, filteredTaskEntityLinks, [], updates)
            .then((upsertedTasksView) => {
                return upsertedTasksView;
            });
    };
}

export function saveTaskForm(
    comments: PendingComment[] = []
): RmsAction<Promise<TasksView | void>> {
    return function (dispatch, getState, { overlayStore, formsRegistry }) {
        const overlayId = overlayIdEnum.TASK_OVERLAY;

        const taskForm = formsRegistry.get(RefContextEnum.FORM_TASK.name);
        if (!taskForm) {
            throw new Error(strings.errorSavingTask);
        }

        return taskForm.submit().then(({ form }) => {
            const taskFormModel = form.get();
            const attachments =
                typeof taskFormModel.id === 'undefined'
                    ? // @ts-expect-error uploadedFilesSelector in attachmentsSidePanel.js has to be typed in order for
                      // this selector to have its state be typed with RootState, but importing RootState to
                      // attachmentsSidePanel.js causes a circular dependency.
                      // TODO is to resolve all RootState circular dependencies.
                      unpersistedTaskAttachmentsSelector(getState())
                    : [];
            const { task, taskEntityLinks } = convertFromFormModel(taskFormModel);

            return taskResource
                .upsertTask(task, taskEntityLinks, attachments, comments)
                .then((upsertedTasksView) => {
                    dispatch(withTasksView(upsertedTasksView));
                    dispatch(unregisterTaskForm());
                    overlayStore.close(overlayId);
                    return upsertedTasksView;
                })
                .catch((error) => {
                    overlayStore.setError(overlayId, error.message);
                    throw error;
                });
        });
    };
}

export const saveTaskComment = (taskId: number, comment: PendingComment): RmsAction<void> => (
    dispatch,
    getState,
    { overlayStore }
) => {
    taskResource
        .createTaskComment(taskId, comment)
        .then((createdComment) => {
            dispatch(withComment(createdComment));
        })
        .catch((error) => {
            overlayStore.setError(overlayIdEnum.TASK_OVERLAY, error.message);
            throw error;
        });
};

export const deleteTaskComment = (id: number): RmsAction<void> => (
    dispatch,
    getState,
    { nexus, overlayStore }
) => {
    taskResource
        .removeTaskComment(id)
        .then(() => {
            dispatch(
                nexus.withRemove(TASK_COMMENTS_NEXUS_STATE_PROP, { id }, deleteTaskSuccess(id))
            );
        })
        .catch((error) => {
            overlayStore.setError(overlayIdEnum.TASK_OVERLAY, error.message);
            throw error;
        });
};

function prefillTaskFormOnTaskCreation(
    taskEntityLinks?: Partial<TaskEntityLinkView>[]
): RmsAction<void> {
    return function (dispatch, getState, { formsRegistry }) {
        const state = getState();
        const todoTaskStatusAttributeId = todoTaskStatusAttributeIdSelector(state);
        const defaultPriorityAttributeId = defaultTaskPriorityAttributeIdSelector(state);
        const defaultTaskTypeAttributeId = defaultTaskTypeAttributeIdSelector(state);
        const taskEntityLinksEnabled = applicationSettingsSelector(state)
            .RMS_TASK_ENTITY_LINKS_ENABLED as boolean;

        // Assign first entity link as owner, set as Task type if none
        const ownerTaskEntityLink = first(taskEntityLinks) || {
            entityType: EntityTypeEnum.TASK.name,
        };

        formsRegistry.maybeDeferredOperation<FormTaskConfiguration>(
            RefContextEnum.FORM_TASK.name,
            undefined,
            (form) => {
                form.resetModel();

                form.set(
                    convertToFormModel(
                        {
                            ownerId: ownerTaskEntityLink?.entityId,
                            ownerType: ownerTaskEntityLink?.entityType,
                            statusAttrId: todoTaskStatusAttributeId,
                            priorityAttrId: defaultPriorityAttributeId,
                            typeAttrId: defaultTaskTypeAttributeId,
                        },
                        taskEntityLinksEnabled,
                        taskEntityLinks
                    )
                );
            }
        );
    };
}

export function unregisterTaskForm(): RmsAction<void> {
    return function (dispatch, getState, { formsRegistry }) {
        formsRegistry.unregister(RefContextEnum.FORM_TASK.name);
    };
}

export const canEditTaskSelector = createSelector(
    currentUserIdSelector,
    userHasAbilitySelector,
    (userId, userHasAbility) => userHasAbility(userId, abilitiesEnum.CORE.EDIT_TASKS)
);

export const canDeleteTaskSelector = createSelector(
    currentUserIdSelector,
    userHasAbilitySelector,
    (userId, userHasAbility) => userHasAbility(userId, abilitiesEnum.CORE.MASTER_DELETE_TASKS)
);

import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';

import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
    EntityProfileSubdetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';
import { convertIdentifiersToComponentArray } from '../../core/helpers/identifierHelpers';

const strings = componentStrings.entityProfiles.vehicle.IdentificationDetails;

function IdentificationDetails({ vehicleViewModel }) {
    const display = getViewModelProperties(vehicleViewModel);
    const identifiers = convertIdentifiersToComponentArray(
        display.identifiers,
        'itemIdentifierType'
    );

    return (
        <EntityProfileSection title={strings.title}>
            <EntityProfileSummaryColumn className="one-column">
                <EntityProfileDetailSummaryList>
                    <SummaryRow label={strings.labels.tag}>{vehicleViewModel.tag}</SummaryRow>
                    <SummaryRow label={strings.labels.vinHin}>
                        {vehicleViewModel.vinNumber}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.regState}>
                        {display.registrationState}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.regYear}>
                        {vehicleViewModel.registrationYear}
                    </SummaryRow>
                    <SummaryRow label={strings.labels.regType}>
                        {vehicleViewModel.registrationType}
                    </SummaryRow>
                    <SummaryRow
                        label={strings.labels.insuranceCompany}
                        hidden={
                            !(
                                vehicleViewModel.insuranceProviderName ||
                                vehicleViewModel.insurancePolicyNumber
                            )
                        }
                    >
                        {vehicleViewModel.insuranceProviderName}
                        <EntityProfileSubdetailSummaryList>
                            <SummaryRow label={strings.labels.policyNumber}>
                                {vehicleViewModel.insurancePolicyNumber}
                            </SummaryRow>
                        </EntityProfileSubdetailSummaryList>
                    </SummaryRow>
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList labelWidth={105}>
                    {identifiers}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

const RenderNoInformation = () => (
    <EntityProfileSection title={strings.title}>
        <EntityProfileSectionNoData />
    </EntityProfileSection>
);

export default renderOnlyIf(({ vehicleViewModel }) => {
    const display = getViewModelProperties(vehicleViewModel);
    return (
        vehicleViewModel.tag ||
        vehicleViewModel.vinNumber ||
        display.registrationState ||
        vehicleViewModel.registrationYear ||
        vehicleViewModel.registrationType ||
        vehicleViewModel.insuranceProviderName ||
        vehicleViewModel.insurancePolicyNumber ||
        (display.identifiers && display.identifiers.length)
    );
}, RenderNoInformation)(IdentificationDetails);

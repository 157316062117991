import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { compact, head, includes, map } from 'lodash';
import { createSelector } from 'reselect';

import getReportCardBundleResource from '../../resources/reportCardBundleResource';
import {
    locationEntityLinksWhereSelector,
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
} from '../../../location-entity-links/state/data';
import {
    NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
    reportAttributesWhereSelector,
} from '../../../report-attributes/state/data';
import {
    NEXUS_STATE_PROP as BEHAVIORAL_CRISIS_NEXUS_STATE_PROP,
    behavioralCrisisByReportIdSelector,
} from '../../../behavioral-crises/state/data';
import {
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
    nameReportLinksWhereSelector,
} from '../../../name-report-links/state/data';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const BEHAVIORAL_CRISIS_CARD_REPORT_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.BEHAVIORAL_CRISIS_DISPOSITION.name,
    AttributeTypeEnum.EXHIBITING_BEHAVIOR.name,
    AttributeTypeEnum.BEHAVIORAL_CRISIS_TECHNIQUES_USED.name,
    AttributeTypeEnum.WEAPON_INVOLVED.name,
];

/**
 * `LinkTypes` that are valid for the `NameReportLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const BEHAVIORAL_CRISIS_CARD_NAME_REPORT_LINK_TYPES = [
    LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS,
    LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS,
];

/**
 * `LinkTypes` that are valid for the `LocationEntityLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const BEHAVIORAL_CRISIS_CARD_LOCATION_LINK_TYPES = [LinkTypesEnum.LOCATION_OF_BEHAVIORAL_CRISIS];

const reportAttributesFilter = (reportId) => {
    return (reportAttribute) =>
        reportAttribute.reportId === reportId &&
        includes(BEHAVIORAL_CRISIS_CARD_REPORT_ATTRIBUTE_TYPES, reportAttribute.attributeType);
};

export const hydratedBehavioralCrisisByReportIdSelector = createSelector(
    behavioralCrisisByReportIdSelector,
    reportAttributesWhereSelector,
    nameReportLinksWhereSelector,
    locationEntityLinksWhereSelector,
    (
        behavioralCrisisByReportId,
        reportAttributesWhere,
        nameReportLinksWhere,
        locationEntityLinksWhere
    ) => (reportId) => {
        const behavioralCrisis = behavioralCrisisByReportId(reportId) || {};
        const reportAttributes = reportAttributesWhere(reportAttributesFilter(reportId));
        const behavioralCrisisSubject = head(
            nameReportLinksWhere({
                reportId,
                contextType: EntityTypeEnum.REPORT.name,
                contextId: reportId,
                linkType: LinkTypesEnum.SUBJECT_IN_BEHAVIORAL_CRISIS,
            })
        );
        const otherNamesInBehavioralCrisis = nameReportLinksWhere({
            reportId,
            contextType: EntityTypeEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.OTHER_NAME_IN_BEHAVIORAL_CRISIS,
        });
        const behavioralCrisisLocation = head(
            locationEntityLinksWhere({
                entityType: EntityTypeEnum.BEHAVIORAL_CRISIS.name,
                linkType: LinkTypesEnum.LOCATION_OF_BEHAVIORAL_CRISIS,
                entityId: behavioralCrisis.id,
            })
        );

        return {
            behavioralCrisis,
            reportAttributes,
            behavioralCrisisSubject,
            otherNamesInBehavioralCrisis,
            behavioralCrisisLocation,
        };
    }
);

export function replaceBehavioralCrisisCardBundle({ reportId, behavioralCrisisCardBundle }) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemove } }) => {
        const resource = getReportCardBundleResource();
        return resource
            .upsertBehavioralCrisisCard(reportId, behavioralCrisisCardBundle)
            .then((updatedBehavioralCrisisCardBundle) => {
                const {
                    behavioralCrisis,
                    reportAttributes,
                    nameReportLinks,
                    locationEntityLink: behavioralCrisisLocationLocationEntityLink,
                } = updatedBehavioralCrisisCardBundle;

                const reportAttributeReplacementPredicates = map(
                    BEHAVIORAL_CRISIS_CARD_REPORT_ATTRIBUTE_TYPES,
                    (attributeType) => ({
                        reportId,
                        attributeType,
                    })
                );
                const withRemoveReportAttributes = withRemove(
                    REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
                    reportAttributeReplacementPredicates,
                    { type: 'UPSERT_BEHAVIORAL_CRISIS_CARD_BUNDLE' }
                );
                const withRemoveNameReportLinks = withRemove(
                    NAME_REPORT_LINKS_NEXUS_STATE_PROP,
                    (nameReportLink) =>
                        includes(
                            BEHAVIORAL_CRISIS_CARD_NAME_REPORT_LINK_TYPES,
                            nameReportLink.linkType
                        ) && reportId === nameReportLink.reportId,
                    withRemoveReportAttributes
                );
                const withRemoveLocationEntityLinks = withRemove(
                    LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
                    (locationEntityLink) =>
                        includes(
                            BEHAVIORAL_CRISIS_CARD_LOCATION_LINK_TYPES,
                            locationEntityLink.linkType
                        ) &&
                        locationEntityLink.entityId === behavioralCrisis.id &&
                        locationEntityLink.entityType === EntityTypeEnum.BEHAVIORAL_CRISIS.name,
                    withRemoveNameReportLinks
                );
                const replaceAction = withEntityItems(
                    {
                        [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                        [BEHAVIORAL_CRISIS_NEXUS_STATE_PROP]: [behavioralCrisis],
                        [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: compact([
                            behavioralCrisisLocationLocationEntityLink,
                        ]),
                    },
                    withRemoveLocationEntityLinks
                );

                dispatch(replaceAction);
                return updatedBehavioralCrisisCardBundle;
            });
    };
}

import React, { createContext } from 'react';

export enum DragStatuses {
    COMPLETE = 'COMPLETE',
    IN_PROGRESS = 'IN_PROGRESS',
    HAS_NOT_STARTED = 'HAS_NOT_STARTED',
}

type DraggingIdContextContextProps = {
    draggingId?: number;
    setDraggingId?: React.Dispatch<React.SetStateAction<number>>;
    dragStatus?: DragStatuses;
    setDragStatus?: React.Dispatch<React.SetStateAction<DragStatuses>>;
};

export const DraggingIdContext = createContext<DraggingIdContextContextProps>({});

import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { WithRouterProps } from 'react-router';
import truncate from 'lodash/truncate';
import {
    cssVar,
    Flex,
    Tag,
    Table,
    TableContainer as _TableContainer,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatMiniUserByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import { cautionsByEntitySelector } from '~/client-common/core/domain/cautions/state/data';
import { isBefore, isDateInFuture } from '~/client-common/core/dates/utils/dateHelpers';
import useFields, { useFieldName } from '~/client-common/core/fields/hooks/useFields';
import {
    CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION,
    CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_PROVENANCE,
    DISPLAY_ONLY_CAUTION_INACTIVE,
    DISPLAY_ONLY_CAUTION_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';

import testIds from '../../../../core/testIds';
import { useDateTimeFormatter } from '../../../core/current-user/hooks/dateTimeFormats';
import { EnhancedCaution } from '../../../core/cautions/components/EnhancedCaution';
import {
    CautionEntityProfileTypes,
    entityProfileTypeToEntityType,
    entityTypeToCautionAttributeTypes,
} from '../../../core/cautions/configuration';
import { prepareEnhancedCautions } from '../../../core/cautions/helpers';
import { ConditionalTooltip } from '../../../core/components/tooltip';

interface ProfileCautionsRouteParams {
    entityId: string;
    entityType: CautionEntityProfileTypes;
}
type EntityProfileCautionsProps = WithRouterProps<ProfileCautionsRouteParams>;
enum CautionDateType {
    EffectiveFrom,
    EffectiveTo,
}

const labels = componentStrings.core.cautions.table;

const TableContainer = styled(_TableContainer)`
    white-space: normal;
`;

const WrappedCaution = styled(EnhancedCaution)`
    max-width: 160px;
`;

const WrappedTag = styled(Tag)`
    max-width: 75px;
`;

const DateCell: React.FC<{ date?: string; type?: CautionDateType }> = ({ date, type }) => {
    const dateFormatter = useDateTimeFormatter();
    const { singular: inactiveLabel } = useFieldName(DISPLAY_ONLY_CAUTION_INACTIVE);

    const formattedDate = dateFormatter.formatDate(date);
    const tag =
        date &&
        ((type === CautionDateType.EffectiveFrom && isDateInFuture(date)) ||
            (type === CautionDateType.EffectiveTo && isBefore(new Date(), date))) ? (
            <WrappedTag size="sm">{inactiveLabel.toUpperCase()}</WrappedTag>
        ) : null;

    return (
        <Flex direction="column" alignItems="start" gap={cssVar('arc.space.1')}>
            {formattedDate}
            {tag}
        </Flex>
    );
};

const TruncatedText: React.FC<{ text?: string; length?: number }> = ({ text, length = 100 }) => {
    const descriptionIsLong = Boolean(text && text.length > length);
    const displayText = descriptionIsLong ? truncate(text, { separator: /,? +/, length }) : text;
    return (
        <ConditionalTooltip condition={descriptionIsLong} content={text} maxWidth="400px">
            <span>{displayText}</span>
        </ConditionalTooltip>
    );
};

const EntityProfileCautions: React.FC<EntityProfileCautionsProps> = ({
    params: { entityId, entityType: entityProfileType },
}) => {
    const entityType = entityProfileTypeToEntityType[entityProfileType];
    const { valueAttrType, categoryAttrType } = entityTypeToCautionAttributeTypes[entityType];
    const { isLoading } = usePreloadAttributes([valueAttrType, categoryAttrType]);

    const cautionsByEntity = useSelector(cautionsByEntitySelector);
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);
    const formatUserById = useSelector(formatMiniUserByIdSelector);

    const { plural: cautionsLabel } = useFieldName(DISPLAY_ONLY_CAUTION_LABEL);
    const {
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION: descriptionLabel,
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_PROVENANCE: provenanceLabel,
    } = useFields([
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_DESCRIPTION,
        CAUTION_ATTRIBUTE_TYPE_PERSON_LABEL_ATTRIBUTES_PROVENANCE,
    ]);

    if (isLoading) {
        return null;
    }

    const cautions = cautionsByEntity(entityType, +entityId);
    const { enhancedCautions } = prepareEnhancedCautions({
        cautions,
        includeInactive: true,
        formatAttributeById,
        parentAttributeIdByAttributeId,
    });

    const tableLabel = `Entity Profile ${cautionsLabel} Table`;

    return (
        <TableContainer>
            <Table
                aria-label={tableLabel}
                variant="accented"
                data-test-id={testIds.ENTITY_PROFILE_CAUTIONS_TABLE}
            >
                <Thead>
                    <Tr>
                        <Th>{labels.type}</Th>
                        <Th>{labels.effectiveFrom}</Th>
                        <Th>{labels.effectiveTo}</Th>
                        <Th>{descriptionLabel}</Th>
                        <Th>{provenanceLabel}</Th>
                        <Th>{labels.dateCreated}</Th>
                        <Th>{labels.createdBy}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {enhancedCautions.map(({ caution, label, priority }) => (
                        <Tr key={caution.id}>
                            <Td>
                                <WrappedCaution
                                    caution={caution}
                                    label={label}
                                    priority={priority}
                                />
                            </Td>
                            <Td>
                                <DateCell
                                    date={caution.dateEffectiveFrom}
                                    type={CautionDateType.EffectiveFrom}
                                />
                            </Td>
                            <Td>
                                <DateCell
                                    date={caution.dateEffectiveTo}
                                    type={CautionDateType.EffectiveTo}
                                />
                            </Td>
                            <Td>
                                <TruncatedText text={caution.description} />
                            </Td>
                            <Td>
                                <TruncatedText text={caution.provenance} />
                            </Td>
                            <Td>
                                <DateCell date={caution.createdDateUtc} />
                            </Td>
                            <Td>{formatUserById(caution.createdBy)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export default EntityProfileCautions;

import React, { useEffect } from 'react';
import { createOverlayManager } from 'overlay-manager';
import { IOverlayBaseProps } from 'overlay-manager/lib/OverlayBase';

interface OverlayProvider {
    children: React.ReactNode;
}

// we're using this as a singleton, not creating 1 manager per overlay
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const OverlayManager = createOverlayManager<any>({ customPropertiesDefaultState: {} });

const OverlayBaseBase = OverlayManager.OverlayBase;
export const OverlayStoreContext = OverlayManager.OverlayStoreContext;
export const overlayStore = OverlayManager.overlayStore;

const { Provider } = OverlayStoreContext;

export const OverlayProvider: React.FC<OverlayProvider> = ({ children }) => (
    <Provider mountNode={document.body} store={overlayStore} children={children} />
);

export const mountedOverlayBases: Record<string, boolean> = {};

// This wrapper component is for tracking multiple renders of OverlayBase.
// In anticipation of upgrading to overlay-manager 2.0.0 because it only supports
// one overlayBase per overlayId. Can revert after all warnings on Sentry are fixed.
export function OverlayBase<T extends Record<string, unknown>>(
    props: IOverlayBaseProps<T>
): React.ReactElement | null {
    const { id } = props;

    useEffect(() => {
        // Multiple OverlayBases should not have an identical id. We don't log warnings here to avoid spamming the
        // console and Sentry. TODO: Fix all duplicate ids in https://mark43.atlassian.net/browse/RMS-11266
        if (!mountedOverlayBases[id]) {
            mountedOverlayBases[id] = true;
        }

        return function cleanup() {
            delete mountedOverlayBases[id];
        };
    }, [id]);

    return <OverlayBaseBase {...props} />;
}

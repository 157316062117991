import React from 'react';
import classNames from 'classnames';

/**
 * Column that takes up the full height of the browser window below the header
 *   and subheader. The column can be scrolled if needed, but the whole page
 *   will not have its own scrollbar.
 */
const FullHeightColumn: React.FC<{
    direction?: 'column' | 'row';
    className?: string;
    width?: number | string;
}> = ({ className, width, direction = 'column', children }) => {
    return (
        <div
            className={classNames('mark43-column-full-height', className)}
            style={{ width, flexDirection: `${direction}` }}
        >
            {children}
        </div>
    );
};

export default FullHeightColumn;

import { EntityTypeEnumType, FutureEntityPermission } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { get, filter, sortBy, last } from 'lodash';
import createLinkModule from '../../../../utils/createLinkModule';

const futureEntityPermissionsModule = createLinkModule<FutureEntityPermission>({
    type: 'futureEntityPermissions',
    keys: ['entityType', 'roleId'],
});

// ACTIONS
export const storeFutureEntityPermissions = futureEntityPermissionsModule.actionCreators.storeLinks;

// SELECTORS
const futureEntityPermissionsSelector = futureEntityPermissionsModule.selectors.linksSelector;

/**
 *
 * @param {string} Entity type (full list here - client-common/core/enums/universal/entityTypeEnum.js)
 * @returns the most recent entity profile for the provided entity type
 */
export const latestFutureEntityPermissionForEntityTypeSelector = createSelector(
    futureEntityPermissionsSelector,
    (entities) => (entityType: EntityTypeEnumType) => {
        const entitiesForType = filter(
            entities,
            (entity) => get(entity, 'entityType') === entityType
        );

        const lastModifiedEntity = last(
            sortBy(entitiesForType, (entity) => get(entity, 'updatedDateUtc'))
        );

        return lastModifiedEntity;
    }
);

// REDUCER
export default futureEntityPermissionsModule.reducerConfig;

/**
 * This is a mutually exclusive list of input styles. The FormElement component and each input component (Text, Select,
 * etc) may support their own styles outside of these ones.
 */
export const InputStyles = {
    /**
     * When the input has an error message to display. In the UI, this is represented by a red border and a red error
     * message.
     */
    ERROR: 'ERROR',
    /**
     * When the field is required and the input has no error message. This style should apply when the value is empty
     * and the input is untouched.
     */
    REQUIRED_EMPTY_UNTOUCHED: 'REQUIRED_EMPTY_UNTOUCHED',
    /**
     * When the input has no error, and the field is not required.
     */
    NONE: 'NONE',
} as const;

type InputStyle = keyof typeof InputStyles;

export function computeInputStyle({
    isRequired,
    isEmpty,
    isTouched,
    hasError,
    forceShowError,
}: {
    isRequired?: boolean;
    isEmpty?: boolean;
    isTouched?: boolean;
    hasError?: boolean;
    forceShowError?: boolean;
}): InputStyle {
    if (isRequired && isEmpty && !isTouched) {
        return InputStyles.REQUIRED_EMPTY_UNTOUCHED;
    } else if (hasError && (isTouched || forceShowError)) {
        return InputStyles.ERROR;
    } else {
        return InputStyles.NONE;
    }
}

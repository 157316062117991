import { dark } from 'arc';
import { hexToRgb } from '../utils/colorHelpers';

const colors = dark.colors;
const text = colors.text;
const surface = colors.surface;
const brand = colors.brand;
const positive = colors.positive;
const raw = colors.raw;

const baseColors = {
    black: raw.black,
    darkGrey: raw.whiteAlpha[900],
    mediumGrey: text.secondary,
    mediumLightGrey: text.tertiary,
    lightGrey: raw.gray[500],
    extraLightGrey: surface.foreground,
    white: surface.foreground,
    red: raw.red[500],
    darkGreen: positive.default,
    lightGreen: raw.green[700],
    darkGreen2: raw.cyan[500], // Used only in Label and TaskStatusLabel
    lightGreen2: raw.cyan[900], // Used only in Label and TaskStatusLabel
    brightYellow: raw.yellow[200],
    lightYellow: raw.blue[900],
    navyBlue: raw.blue[800],
    cobaltBlue: brand.default,
    brightBlue: brand.default,
    mediumBlue: brand.default,
    lightBlue: surface.accent,
};

const brightBlueRgbValues = hexToRgb(baseColors.brightBlue);

export default {
    colors: {
        ...baseColors,

        brightBlueAlpha: `rgba(${brightBlueRgbValues.r},${brightBlueRgbValues.g},${brightBlueRgbValues.b}, .25)`,

        // Deprecated, remove these two when RMS_ARC_NAVIGATION_ENABLED is torn down
        navbarText: '#EDEDED',
        navbarBorder: '#333333',

        text: text.primary,
        placeholder: text.placeholder,
        shadow: baseColors.black,
        fieldBg: raw.whiteAlpha[50],
        disabled: baseColors.mediumGrey,

        highContrastGreen: baseColors.darkGreen,
        lowContrastGreen: baseColors.lightGreen,
        highContrastGrey: baseColors.mediumGrey,
        lowContrastGrey: baseColors.mediumLightGrey,
    },
    fontWeights: {
        thin: 100,
        extraLight: 200,
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    fontFamilies: {
        facit: 'jaf-facitweb',
        proximaNova: 'proxima-nova',
    },
    widths: {
        container: 970,
        navSidebar: 166,
        label: 85,
        sectionHeader: {
            card: 574,
            sidePanel: 428,
        },
        caseContent: 804,
    },
    heights: {
        header: 60,
        subheader: 70,
        actionBar: 62,
        alertBanner: 80,
    },
    fontSizes: {
        subheader: '24px',
    },
};

import { useSelector } from 'react-redux';
import { coreModelConfigurationsSelector } from '~/client-common/core/domain/core-model-configurations/state/data';
import { fieldConfigurationContextsSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';
import { fieldConfigurationsSelector } from '~/client-common/core/domain/field-configurations/state/data';
import { fieldDetailsSelector } from '~/client-common/core/domain/field-details/state/data';

export const useAdditionalFormConfigurationData = () => {
    const fieldDetails = useSelector(fieldDetailsSelector);
    const fieldConfigurations = useSelector(fieldConfigurationsSelector);
    const fieldConfigurationContexts = useSelector(fieldConfigurationContextsSelector);
    const coreModelConfigurations = Object.values(useSelector(coreModelConfigurationsSelector)).map(
        (coreModelConfiguration) => ({
            ...coreModelConfiguration,
            properties: Object.values(coreModelConfiguration.properties || {}),
        })
    );
    return {
        fieldDetails,
        fieldConfigurations,
        fieldConfigurationContexts,
        coreModelConfigurations,
    };
};

import { AttributeCode } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

const attributeCodesModule = createLinkModule<AttributeCode>({
    type: 'attributeCodes',
    keys: ['attributeId', 'codeId'],
});

// SELECTORS

export const attributeCodesWhereSelector = attributeCodesModule.selectors.linksWhereSelector;

// REDUCER
export default attributeCodesModule.reducerConfig;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { evidencePrintablesSelector } from '~/client-common/core/domain/evidence-printables/state/data';
import { buildEvidenceDocumentPrintableOptions } from '~/client-common/core/domain/evidence-printables/utils/evidencePrintableHelpers';

import ReactReduxForm from '../../../../core/forms/components/ReactReduxForm';
import Row from '../../../../core/components/Row';
import { RRFSelect } from '../../../../core/forms/components/selects/Select';
import documentExportingForm from '../../state/forms/documentExportingForm';

function DocumentExportingForm({ evidencePrintables }) {
    const options = buildEvidenceDocumentPrintableOptions(evidencePrintables);

    return (
        <ReactReduxForm {...documentExportingForm}>
            <Row>
                <RRFSelect path="printableHash" options={options} />
            </Row>
        </ReactReduxForm>
    );
}

const mapStateToProps = createStructuredSelector({
    evidencePrintables: evidencePrintablesSelector,
});

/**
 * Form for selecting a type of evidence document to export.
 */
export default connect(mapStateToProps)(DocumentExportingForm);

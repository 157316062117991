import { _Form, createFormConfiguration, formEvents } from 'markformythree';
import * as yup from 'yup';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formsRegistry from '../../../../../core/formsRegistry';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../../core/validation/yupMftValidation';

export const CONSORTIUM_ADMIN_ADD_DEPARTMENT_FORM = 'consortiumAdminAddDepartmentForm';

const strings = componentStrings.admin.consortium.ConsortiumAdminAddDepartmentForm;

const validationSchema = yup.object().shape({
    departmentId: yup.number().required(strings.requiredError).nullable(true),
});

const consortiumAdminAddDepartmentForm = new _Form({
    name: CONSORTIUM_ADMIN_ADD_DEPARTMENT_FORM,
    configuration: createFormConfiguration({
        departmentId: {},
        deparmentTimeZones: {},
        allDepartmentProfiles: {},
    }),
    onValidate: ({ formState }) =>
        validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((validationResult) =>
                convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form)
            )
            .catch((validationErrors) =>
                convertYupErrorShapeToMFTErrorShape(validationErrors, formState.ui.$form)
            ),
    validationEvents: [
        {
            eventType: formEvents.FORM_SUBMIT,
        },
        {
            eventType: formEvents.INPUT_CHANGE,
        },
        {
            eventType: formEvents.INPUT_BLUR,
        },
    ],
});

formsRegistry.register(consortiumAdminAddDepartmentForm);

export default consortiumAdminAddDepartmentForm;

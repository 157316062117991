import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { iconTypes, iconSizes } from '../../../core/components/Icon';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { Button } from '../../../core/components/Button';
import { openBox } from '../../../../legacy-redux/actions/boxActions';
import { OnlyWithAbility, abilitiesEnum } from '../../../core/abilities';
import { useViewport } from '../../../core/utils/useViewport';

import type { RmsDispatch } from '../../../../core/typings/redux';
import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import Subheader from '../../../core/components/Subheader';
import Page from '../../../core/components/Page';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';
import {
    reportModuleSearch,
    searchInitialReports,
    setReportDefinitionIds,
    submitReportModuleSearch,
} from '../state/ui/reportModuleSearch';

import testIds from '../../../../core/testIds';
import { useDashboardScroll } from '../../../search/core/hooks/useDashboardScroll';
import { openCreateReportModal } from '../../core/state/ui/createReportModal';
import {
    SearchAndFilterBar,
    SearchWrapper,
    SearchBox,
    ToggleIcon,
    FilterButton,
} from './ReportsDashboardContainer';
import ReportModuleSearchResults from './ReportModuleSearchResults';
import ReportModuleSearchFilters from './ReportModuleSearchFilters';
import ReportModuleSearchForm from './ReportModuleSearchForm';

const strings = componentStrings.reports.dashboard;

function CreateReportButton(): JSX.Element {
    const dispatch = useDispatch<RmsDispatch>();
    const applicationSettings = useSelector(applicationSettingsSelector);
    const recordsWithoutRenEnabled = applicationSettings.RMS_REPORT_RECORDS_WITHOUT_REN_ENABLED;
    const onClick = React.useCallback(() => {
        if (recordsWithoutRenEnabled) {
            dispatch(openCreateReportModal());
        } else {
            dispatch(openBox({ name: boxEnum.CREATE_REPORT_MODAL }));
        }
    }, [recordsWithoutRenEnabled, dispatch]);

    return (
        <OnlyWithAbility has={abilitiesEnum.REPORTING.CREATE_REPORT_FROM_DASHBOARD}>
            <Button
                isTextTransformNone
                variant="solid"
                leadingVisual="Add"
                testId={testIds.NEW_REPORT_BUTTON}
                onClick={onClick}
            >
                {strings.ReportsDashboardContainer.newReport}
            </Button>
        </OnlyWithAbility>
    );
}

/**
 * This component:
 * - is based on ReportsDashboardContainer.js
 * - is used to render a search dashboard for a Report Module, whereas ReportsDashboardContainer is used to render the
 *       default reports dashboard
 * - uses newer code patterns with TypeScript, React hooks, etc.
 * - relies on its own search module
 * - renders untyped copies of the SearchForm, SearchFilters, and SearchResults components which use that search module
 *       because it is safer than updating those components to handle a Report Module differently, and the next step is
 *       to convert all these components to TypeScript RND-9718
 * - does not support saved search or auto-saved search
 * - shares the same form module and form state as ReportsDashboardContainer, with the form state updating as the user
 *       navigates between the two routes
 */
export const ReportModuleDashboardContainer = withRouter<{
    title: string;
    reportDefinitionIds: number[];
}>(({ title, reportDefinitionIds, router }) => {
    const dispatch = useDispatch<RmsDispatch>();
    React.useEffect(() => {
        dispatch(setReportDefinitionIds(reportDefinitionIds));
        dispatch(searchInitialReports());
    }, [reportDefinitionIds, dispatch]);

    const [searchIsOpen, setSearchIsOpen] = React.useState(false);

    const formatFieldByName = useSelector(formatFieldByNameSelector);

    const onSearchChange = React.useCallback(() => {
        dispatch(submitReportModuleSearch());
    }, [dispatch]);
    const onSearchToggleClick = React.useCallback(() => {
        // @ts-expect-error RND-9718 type the search module
        dispatch(reportModuleSearch.actionCreators.setScrollPosition(0));
        // @ts-expect-error RND-9718 type the search module
        dispatch(reportModuleSearch.actionCreators.setTriggerScroll(true));
        setSearchIsOpen((isOpen) => !isOpen);
        setScrollToElement(false);
    }, [dispatch]);

    const [scrollToElement, setScrollToElement] = React.useState(true);
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: reportModuleSearch,
        isScrollToElement: scrollToElement,
    });
    useViewport();

    return (
        <Page>
            <Subheader title={title}>
                <CreateReportButton />
            </Subheader>
            <ScrollableUnderSubheader>
                <SearchAndFilterBar>
                    <SearchWrapper>
                        {/* @ts-expect-error RND-9718 type the search module */}
                        <SearchBox {...reportModuleSearch.form}>
                            <RRFAsyncText
                                path="quickSearchQuery"
                                textInputOnly={true}
                                placeholder={formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)}
                                asyncAction={onSearchChange}
                            />
                        </SearchBox>
                        <FilterButton
                            className={buttonTypes.SECONDARY}
                            active={searchIsOpen}
                            inactiveIcon={
                                <ToggleIcon size={iconSizes.MEDIUM} type={iconTypes.FILTER} />
                            }
                            onClick={onSearchToggleClick}
                            testId={testIds.REPORTS_DASHBOARD_FILTERS_TOGGLE_BUTTON}
                        >
                            Filters
                        </FilterButton>
                    </SearchWrapper>
                </SearchAndFilterBar>

                {searchIsOpen && <ReportModuleSearchForm />}
                {/* @ts-expect-error RND-9718 type this component */}
                <ReportModuleSearchFilters />
                <ReportModuleSearchResults
                    getCurrentScrollPosition={getCurrentScrollPosition}
                    setScrollToElement={setScrollToElement}
                />
            </ScrollableUnderSubheader>
        </Page>
    );
});

import { identity, omit } from 'lodash';
import styled from 'styled-components';
import { branch, renderNothing, renderComponent, withProps, mapProps } from 'recompose';

/**
 * Return a higher-order component that renders the component only if the
 *   predicate returns true. Render nothing if the predicate returns false. The
 *   predicate receives the component's props.
 * @param  {function} predicate
 * @return {function} errorComponent Component to render when predicate fails
 * @return {function} Higher-order component.
 */
export function renderOnlyIf(predicate, errorComponent) {
    return branch(
        predicate,
        identity,
        errorComponent ? renderComponent(errorComponent) : renderNothing
    );
}

export function styledWithProps(Component, props) {
    return styled(withProps(() => props)(Component));
}

export const omitProps = (keys) => mapProps((props) => omit(props, keys));

export const getChildTypes = (children) => {
    if (children.$$typeof) {
        return [children.type.displayName || children.type];
    } else if (Array.isArray(children)) {
        return children.reduce((carry, child) => carry.concat(getChildTypes(child)), []);
    }
    return [typeof children];
};

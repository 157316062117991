import { _Form, createFormConfiguration, formEvents, createNItems } from 'markformythree';
import { map, get, reject, filter, omit } from 'lodash';
import * as yup from 'yup';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { consortiumDepartmentLinksWhereSelector } from '~/client-common/core/domain/consortium-link-view/state/data';

import formsRegistry from '../../../../../core/formsRegistry';
import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../../../core/validation/yupMftValidation';

export const CONSORTIUM_ADMIN_EDIT_DEPARTMENT_FORM = 'consortiumAdminEditDepartmentForm';

const strings = componentStrings.admin.consortium.ConsortiumAdminEditDepartmentForm;

// https://github.com/jquense/yup/issues/79
// For mutual dependency, don't use '.when',
// just use a custom test
const validationSchema = yup.object().shape({
    permissions: yup.array().of(
        yup.object().shape({
            departmentId: yup.number().required(),
            operationType: yup.mixed().test({
                name: 'has-no-access',
                test(value) {
                    return !this.parent.noAccess ? !!value : true;
                },
                message: strings.accessLevelRequiredError,
            }),
            noAccess: yup.boolean(),
        })
    ),
});

const consortiumAdminEditDepartmentForm = new _Form({
    name: CONSORTIUM_ADMIN_EDIT_DEPARTMENT_FORM,
    configuration: createFormConfiguration({
        currentDepartmentId: {},
        permissions: createNItems({
            fields: {
                departmentId: {},
                operationType: {},
                noAccess: {},
            },
        }),
    }),
    onValidate: ({ formState }) =>
        validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((validationResult) =>
                convertYupSuccessShapeToMFTSuccessShape(validationResult, formState.ui.$form)
            )
            .catch((validationErrors) =>
                convertYupErrorShapeToMFTErrorShape(validationErrors, formState.ui.$form)
            ),
    validationEvents: [
        {
            eventType: formEvents.FORM_SUBMIT,
        },
        {
            eventType: formEvents.INPUT_CHANGE,
        },
        {
            eventType: formEvents.INPUT_BLUR,
        },
    ],
});

formsRegistry.register(consortiumAdminEditDepartmentForm);

export function fillAdminConsortiumEditDepartmentForm(consortiumId, deptId) {
    return (dispatch, getState, { formsRegistry }) =>
        formsRegistry.maybeDeferredOperation(
            CONSORTIUM_ADMIN_EDIT_DEPARTMENT_FORM,
            undefined,
            (form) => {
                clearAdminConsortiumForm();
                form.set('currentDepartmentId', deptId);
                form.set(
                    'permissions',
                    map(
                        reject(
                            consortiumDepartmentLinksWhereSelector(getState())({ consortiumId }),
                            { deptId }
                        ),
                        (consortiumDepartmentLink) => ({
                            departmentId: get(consortiumDepartmentLink, 'deptId'),
                            noAccess: false,
                            operationType: null,
                        })
                    )
                );
            }
        );
}

export function convertFromFormModel({ permissions, currentDepartmentId }) {
    return {
        departmentId: currentDepartmentId,
        // Filter out all entries without an operation type
        permissions: map(filter(permissions, 'operationType'), (permission) =>
            omit(permission, 'noAccess')
        ),
    };
}

function clearAdminConsortiumForm() {
    const form = formsRegistry.get(CONSORTIUM_ADMIN_EDIT_DEPARTMENT_FORM);
    if (form) {
        form.resetModel();
    }
}

export default consortiumAdminEditDepartmentForm;

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Batch Operations Resource',
    methods: {
        getBatchOperationStatusesForUser(extendCompletedThreshold = false) {
            return req({
                method: 'GET',
                url: `batch_operations/status`,
                hideLoadingBar: true,
                params: {
                    'extended_completed_threshold': extendCompletedThreshold,
                },
            });
        },
        batchGrantEntityPermissions(batchEntityPermissionGrantRequests) {
            return req({
                method: 'POST',
                url: 'batch_operations/entity_permissions',
                data: batchEntityPermissionGrantRequests,
            });
        },
    },
});

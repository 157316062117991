import React, { DragEvent } from 'react';
import { Skeleton } from '@arc/skeleton';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useFetchSVGAsset } from '../hooks';
import { widgetTypes } from '../types';
import { formatFileName } from '../helpers';
import { NoImagePlaceHolder } from './shared';

type WidgetPropsT = {
    src: string;
};

const WidgetWrapper = styled.div`
    width: 100%;
    height: fit-content;
    display: inline-block;
`;

const DiagramAsset = styled(LazyLoadImage)`
    max-width: 85px;
    width: 85px;
    max-height: 70px;
    height: 70px;
`;

export const Widget = ({ src }: WidgetPropsT) => {
    const { svgDataUrl, isLoading } = useFetchSVGAsset({
        value: src,
        assetKey: src,
    });

    const handleDragStart = (e: DragEvent) => {
        if (e.dataTransfer && svgDataUrl) {
            e.dataTransfer.setData('svgDataUrl', svgDataUrl);
            e.dataTransfer.setData('widgetType', widgetTypes.IMAGE);
            e.dataTransfer.setData('widgetLabel', formatFileName(src));
        }
    };
    let children: JSX.Element | null = null;

    if (!svgDataUrl && !isLoading) {
        children = <NoImagePlaceHolder />;
    } else {
        children = (
            <DiagramAsset
                src={svgDataUrl}
                draggable
                onDragStart={handleDragStart}
                effect="opacity"
            />
        );
    }

    return (
        <Skeleton isLoaded={!isLoading}>
            <WidgetWrapper>{children}</WidgetWrapper>
        </Skeleton>
    );
};

import { EntityTypeEnum } from '@mark43/rms-api';
import React from 'react';

import styled from 'styled-components';
import { TrafficCrashVehicleViewModel } from '~/client-common/core/domain/traffic-crash-vehicles/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import _SummaryList from '../../../../legacy-redux/components/summaries/SummaryList';
import { TrafficCrashTrailerSummary } from './TrafficCrashTrailerSummary';
import { TrafficCrashEntityDetailSummarySection } from './TrafficCrashEntityDetailSummarySection';

const SummaryList = styled(_SummaryList)`
    margin: 10px;
`;

const strings = componentStrings.trafficCrashVehicleSummary;

const TrafficCrashVehicleSummary = ({ viewModel }: { viewModel: TrafficCrashVehicleViewModel }) => {
    if (!viewModel) {
        return null;
    }

    const viewModelProperties = getViewModelProperties(viewModel);

    return (
        <SummaryList labelWidth={250} contentWidth={250}>
            <SummaryRow label={strings.unitTypeAttrId}>
                {viewModelProperties.unitTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.unitNumber}>{viewModel.unitNumber}</SummaryRow>
            <SummaryRow label={strings.registrationStateOrCountry}>
                {viewModel.registrationStateOrCountry}
            </SummaryRow>
            <SummaryRow label={strings.bodyTypeCategoryAttrId}>
                {viewModelProperties.bodyTypeCategoryAttrId}
            </SummaryRow>
            <SummaryRow label={strings.numTrailingUnitsAttrId}>
                {viewModelProperties.numTrailingUnitsAttrId}
            </SummaryRow>
            <SummaryRow label={strings.vehicleSizeAttrId}>
                {viewModelProperties.vehicleSizeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.hasHmPlacardAttrId}>
                {viewModelProperties.hasHmPlacardAttrId}
            </SummaryRow>
            <SummaryRow label={strings.totalOccupantsInMotorVehicle}>
                {viewModel.totalOccupantsInMotorVehicle}
            </SummaryRow>
            <SummaryRow label={strings.specialFunctionAttrId}>
                {viewModelProperties.specialFunctionAttrId}
            </SummaryRow>
            <SummaryRow label={strings.emergencyMotorVehicleUseAttrId}>
                {viewModelProperties.emergencyMotorVehicleUseAttrId}
            </SummaryRow>
            <SummaryRow label={strings.postedSpeedLimit}>{viewModel.postedSpeedLimit}</SummaryRow>
            <SummaryRow label={strings.directionOfTravelBeforeCrashAttrId}>
                {viewModelProperties.directionOfTravelBeforeCrashAttrId}
            </SummaryRow>
            <SummaryRow label={strings.trafficwayTravelDirectionsAttrId}>
                {viewModelProperties.trafficwayTravelDirectionsAttrId}
            </SummaryRow>
            <SummaryRow label={strings.trafficwayDividedAttrId}>
                {viewModelProperties.trafficwayDividedAttrId}
            </SummaryRow>
            <SummaryRow label={strings.trafficwayBarrierTypeAttrId}>
                {viewModelProperties.trafficwayBarrierTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.trafficwayHovHotLanesAttrId}>
                {viewModelProperties.trafficwayHovHotLanesAttrId}
            </SummaryRow>
            <SummaryRow label={strings.crashRelatedToHovHotLanesAttrId}>
                {viewModelProperties.crashRelatedToHovHotLanesAttrId}
            </SummaryRow>
            <SummaryRow label={strings.totalThroughLanes}>{viewModel.totalThroughLanes}</SummaryRow>
            <SummaryRow label={strings.totalAuxLanes}>{viewModel.totalAuxLanes}</SummaryRow>
            <SummaryRow label={strings.trafficControlsType}>
                {viewModelProperties.trafficControlsType}
            </SummaryRow>
            <SummaryRow label={strings.trafficControlsInoperativeMissing}>
                {viewModelProperties.trafficControlsInoperativeMissing}
            </SummaryRow>
            <SummaryRow label={strings.roadwayHorizontalAlignmentAttrId}>
                {viewModelProperties.roadwayHorizontalAlignmentAttrId}
            </SummaryRow>
            <SummaryRow label={strings.roadwayGradeAttrId}>
                {viewModelProperties.roadwayGradeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.maneuverAttrId}>
                {viewModelProperties.maneuverAttrId}
            </SummaryRow>
            <SummaryRow label={strings.initialPointOfContactAttrId}>
                {viewModelProperties.initialPointOfContactAttrId}
            </SummaryRow>
            <SummaryRow label={strings.locationOfDamagedAreas}>
                {viewModelProperties.locationOfDamagedAreas}
            </SummaryRow>
            <SummaryRow label={strings.sequenceOfEvents}>
                {viewModelProperties.sequenceOfEvents}
            </SummaryRow>
            <SummaryRow label={strings.resultingExtentOfDamageAttrId}>
                {viewModelProperties.resultingExtentOfDamageAttrId}
            </SummaryRow>
            <SummaryRow label={strings.mostHarmfulEventAttrId}>
                {viewModelProperties.mostHarmfulEventAttrId}
            </SummaryRow>
            <SummaryRow label={strings.hitAndRunAttrId}>
                {viewModelProperties.hitAndRunAttrId}
            </SummaryRow>
            <SummaryRow label={strings.towedDueToDisablingDamageAttrId}>
                {viewModelProperties.towedDueToDisablingDamageAttrId}
            </SummaryRow>
            <SummaryRow label={strings.contributingCircumstancesAttrId}>
                {viewModelProperties.contributingCircumstancesAttrId}
            </SummaryRow>

            <TrafficCrashTrailerSummary
                title={strings.firstTrailerTitle}
                trailerName={viewModelProperties.firstTrailerTitle}
                licensePlateNum={viewModel.licensePlateNumFirstTrailer}
                vin={viewModel.vinFirstTrailer}
                totalNumAxels={viewModel.totalNumOfAxlesFirstTrailer}
            />

            <TrafficCrashTrailerSummary
                title={strings.secondTrailerTitle}
                trailerName={viewModelProperties.secondTrailerTitle}
                licensePlateNum={viewModel.licensePlateNumSecondTrailer}
                vin={viewModel.vinSecondTrailer}
                totalNumAxels={viewModel.totalNumOfAxlesSecondTrailer}
            />

            <TrafficCrashTrailerSummary
                title={strings.thirdTrailerTitle}
                trailerName={viewModelProperties.thirdTrailerTitle}
                licensePlateNum={viewModel.licensePlateNumThirdTrailer}
                vin={viewModel.vinThirdTrailer}
                totalNumAxels={viewModel.totalNumOfAxlesThirdTrailer}
                showBottomDivider={true}
            />

            <SummaryRow label={strings.motorCarrierIdentificationTypeAttrId}>
                {viewModelProperties.motorCarrierIdentificationTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.motorCarrierIdentificationCountryOrStateCode}>
                {viewModel.motorCarrierIdentificationCountryOrStateCode}
            </SummaryRow>
            <SummaryRow label={strings.motorCarrierIdentificationNumber}>
                {viewModel.motorCarrierIdentificationNumber}
            </SummaryRow>
            <SummaryRow label={strings.motorCarrierIdentificationName}>
                {viewModel.motorCarrierIdentificationName}
            </SummaryRow>
            <SummaryRow label={strings.motorCarrierAddressLocationId}>
                {viewModel.motorCarrierAddressLocationId}
            </SummaryRow>
            <SummaryRow label={strings.motorCarrierIdentificationCarrierTypeAttrId}>
                {viewModelProperties.motorCarrierIdentificationCarrierTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.vehicleConfigurationAttrId}>
                {viewModelProperties.vehicleConfigurationAttrId}
            </SummaryRow>
            <SummaryRow label={strings.vehicleConfigSpecialSizing}>
                {viewModelProperties.vehicleConfigSpecialSizing}
            </SummaryRow>
            <SummaryRow label={strings.vehicleConfigurationPermittedAttrId}>
                {viewModelProperties.vehicleConfigurationPermittedAttrId}
            </SummaryRow>
            <SummaryRow label={strings.cargoBodyTypeAttrId}>
                {viewModelProperties.cargoBodyTypeAttrId}
            </SummaryRow>
            <SummaryRow label={strings.hazardousMaterialsId}>
                {viewModel.hazardousMaterialsId}
            </SummaryRow>
            <SummaryRow label={strings.hazardousMaterialsClass}>
                {viewModelProperties.hazardousMaterialsClassAttrId}
            </SummaryRow>
            <SummaryRow label={strings.hazardousMaterialsReleasedAttrId}>
                {viewModelProperties.hazardousMaterialsReleasedAttrId}
            </SummaryRow>
            <SummaryRow label={strings.totalNumOfAxlesTruckTractor}>
                {viewModel.totalNumOfAxlesTruckTractor}
            </SummaryRow>
            <SummaryRow label={strings.motorVehicleAutomatedDrivingSystemsAttrId}>
                {viewModelProperties.motorVehicleAutomatedDrivingSystemsAttrId}
            </SummaryRow>
            <SummaryRow label={strings.automatedDrivingSystemsLevel}>
                {viewModelProperties.automatedDrivingSystemsLevel}
            </SummaryRow>
            <SummaryRow label={strings.automatedDrivingSystemsEngaged}>
                {viewModelProperties.automatedDrivingSystemsEngaged}
            </SummaryRow>
            <SummaryRow label={strings.roadwayName}>{viewModel.roadwayName}</SummaryRow>
            <SummaryRow label={strings.hazardousMaterialsInvolved}>
                {viewModelProperties.hazardousMaterialsInvolved}
            </SummaryRow>
            <TrafficCrashEntityDetailSummarySection
                entityId={viewModel.vehicleId}
                entityType={EntityTypeEnum.VEHICLE.name}
            />
        </SummaryList>
    );
};

export default TrafficCrashVehicleSummary;

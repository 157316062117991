import { useSelector } from 'react-redux';
import { map } from 'lodash';

import { formatFieldByNameSelector } from '../../fields/state/config';
import { interpolateErrorMessage } from '../templateRunner';

export const useErrorInterpolator = (rawPanelErrors: string[]) => {
    const formatFieldByName = useSelector(formatFieldByNameSelector);
    return map(rawPanelErrors, (rawPanelError) =>
        interpolateErrorMessage(rawPanelError, formatFieldByName)
    );
};

import { EntityTypeEnum } from '@mark43/rms-api';
import { map, compact, parseInt } from 'lodash';

import { createSelector } from 'reselect';
import { organizationProfilesSelector } from '~/client-common/core/domain/organization-profiles/state/data';
import { personProfilesSelector } from '~/client-common/core/domain/person-profiles/state/data';
import { vehiclesSelector } from '~/client-common/core/domain/vehicles/state/data';
import { personGangTrackingMergeDataForPersonIdSelector } from '~/client-common/core/domain/person-gang-trackings/state/data';

const { PERSON_PROFILE, ORGANIZATION_PROFILE, VEHICLE } = EntityTypeEnum;

const baseStateSelector = (state) => state.ui.mergeEntitiesDashboard;
export const isLoadingSelector = (state) => baseStateSelector(state).isLoading;
export const errorSelector = (state) => baseStateSelector(state).error;
export const profilesLoadedSelector = (state) => baseStateSelector(state).profilesLoaded;
export const entityTypeToMergeSelector = (state) => baseStateSelector(state).entityTypeToMerge;
export const entityIdsToMergeSelector = (state) => baseStateSelector(state).entityIdsToMerge;
export const selectedValuesSelector = (state) => baseStateSelector(state).selectedValues;

export const profilesToMergeSelector = createSelector(
    entityTypeToMergeSelector,
    entityIdsToMergeSelector,
    organizationProfilesSelector,
    personProfilesSelector,
    vehiclesSelector,
    personGangTrackingMergeDataForPersonIdSelector,
    (
        entityTypeToMerge,
        entityIdsToMerge,
        organizationProfiles,
        personProfiles,
        vehicleProfiles,
        personGangTrackingMergeDataForPersonId
    ) => {
        if (!entityTypeToMerge || !entityIdsToMerge) {
            return undefined;
        }
        switch (entityTypeToMerge) {
            case PERSON_PROFILE.name:
                return compact(
                    map(entityIdsToMerge, (id) => {
                        return {
                            ...personProfiles[id],
                            ...personGangTrackingMergeDataForPersonId(parseInt(id)),
                        };
                    })
                );
            case ORGANIZATION_PROFILE.name:
                return compact(map(entityIdsToMerge, (id) => organizationProfiles[id]));
            case VEHICLE.name:
                return compact(map(entityIdsToMerge, (id) => vehicleProfiles[id]));
            default:
                throw new Error(
                    'Unsupported merging type, not of type Persons, Organizations, or Vehicles'
                );
        }
    }
);

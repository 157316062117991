import { EntityTypeEnum, RefContextEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';

import { get, findIndex, head, chain } from 'lodash';
import getOrganizationProfileResource from '~/client-common/core/domain/organization-profiles/resources/organizationProfileResource';

import {
    augmentActionWithNexusHydratedOrganization,
    SAVE_ORGANIZATION_PROFILE_SUCCESS,
    organizationProfilesWhereSelector,
} from '~/client-common/core/domain/organization-profiles/state/data/';
import { locationsSelector } from '~/client-common/core/domain/locations/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { nameIdentifiersSelector } from '~/client-common/core/domain/name-identifiers/state/data';
import { nameEmailsSelector } from '~/client-common/core/domain/name-emails/state/data';
import { namePhonesSelector } from '~/client-common/core/domain/name-phones/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { elasticOrganizationsSelector } from '~/client-common/core/domain/elastic-organizations/state/data';
import { societyProfilesSelector } from '~/client-common/core/domain/society-profiles/state/data';

import { uploadedFilesSelector } from '../../../../attachments/core/state/ui/inlineAttachmentsUploader';
import { createOverlayCustomProperties } from '../../../utils/createOverlayCustomProperties';
import { convertFromFormModel } from '../forms/organizationProfileForm';
import { createOrganizationProfileFormConfiguration } from '../forms/createOrganizationProfileFormConfiguration';
import { ORGANIZATION_SIDE_PANEL_SCREENS } from '../ui';
import { quickAddName } from '../../../names/state/data';
import relationshipsCard from '../../../../reports/core/state/ui/relationshipsCard';
import { mapQuickAddEntitiesToSameOwnerId } from '../../../../reports/core/helpers/quickAddHelpers';
import {
    REN_IDENTIFIER,
    recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
} from '../../../recent-entities/state/ui';

import {
    handleNameProfileFormError,
    augmentHydratedNameWithAttachmentsAndReportLinks,
    createOnAddSuccessNameReportLink,
    shouldUseStubLink,
} from '../../../names/util/nameSaveFormHelpers';

const quickAddStrings = componentStrings.core.OrganizationQuickAdd;

function organizationSidePanelSaveSuccess(hydratedOrganization) {
    return {
        type: SAVE_ORGANIZATION_PROFILE_SUCCESS,
        payload: hydratedOrganization,
    };
}

function createFullHydratedOrganization({
    formModel,
    getState,
    contextId,
    contextType,
    linkType,
    entityId,
    ownerId,
    ownerType,
    isEditingMasterProfile,
}) {
    const state = getState();
    const lookupEntityId = entityId || formModel.masterOrganizationId;
    const hydratedOrganization = convertFromFormModel(
        formModel,
        {
            identifiers: nameIdentifiersSelector(state)[lookupEntityId],
            phoneNumbers: namePhonesSelector(state)[lookupEntityId],
            emails: nameEmailsSelector(state)[lookupEntityId],
            locations: locationsSelector(state),
            locationEntityLinks: locationEntityLinksWhereSelector(state)({
                entityId: lookupEntityId,
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            }),
        },
        {
            entityId,
            lookupEntityId,
            ownerId,
            ownerType,
            isEditingMasterProfile,
        }
    );

    // we need to send down an empty array if there are no locations present
    // because the backend is going to replace location links
    if (!hydratedOrganization.locations) {
        hydratedOrganization.locations = [];
    }

    // attachments are uploaded using the `InlineAttachmentsUploader`
    // which manages its own state. Because of this the attachments do not
    // live in MFT directly and have to be augmented here.
    const fullHydratedOrganization = augmentHydratedNameWithAttachmentsAndReportLinks({
        hydratedName: hydratedOrganization,
        attachments: uploadedFilesSelector(getState()),
        entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
        entityId,
        linkType,
        ownerId,
        contextId,
        contextType,
        isEditingMasterProfile,
    });

    if (shouldUseStubLink({ contextType, contextId })) {
        fullHydratedOrganization.reportLinks = [];
    }

    return fullHydratedOrganization;
}

function saveHydratedOrganization({
    formModel,
    getState,
    contextId,
    contextType,
    linkType,
    entityId,
    ownerId,
    ownerType,
    isEditingMasterProfile,
}) {
    const fullHydratedOrganization = createFullHydratedOrganization({
        formModel,
        getState,
        isEditingMasterProfile: !!isEditingMasterProfile,
        contextId,
        contextType,
        linkType,
        entityId,
        ownerId,
        ownerType,
    });

    return Promise.resolve(
        getOrganizationProfileResource().upsertHydratedOrganization({
            hydratedOrganization: fullHydratedOrganization,
            removeLocationsIfEmpty: true,
        })
    );
}

export function saveOrganizationProfileForm({
    overlayId,
    appendPanelErrorMessageIfNotExists,
    resetPanelErrorMessages,
    isEditingMasterProfile,
    isEditingExistingOrganization,
    onAddSuccess,
    contextId,
    contextType,
    linkType,
    entityId,
    ownerId,
    ownerType,
    savePanel,
}) {
    return (dispatch, getState, dependencies) => {
        resetPanelErrorMessages();
        const form = dependencies.formsRegistry.get(
            RefContextEnum.FORM_ORGANIZATION_SIDE_PANEL.name
        );
        return form
            .submit()
            .then(({ form }) => {
                dependencies.overlayStore.setCustomProperties(
                    overlayId,
                    createOverlayCustomProperties({ isSaving: true })
                );

                const { model } = form.getState();

                return saveHydratedOrganization({
                    formModel: model,
                    getState,
                    contextId,
                    contextType,
                    linkType,
                    entityId,
                    ownerId,
                    ownerType,
                    isEditingMasterProfile: !!isEditingMasterProfile,
                });
            })
            .then((hydratedOrganization) => {
                dispatch(
                    augmentActionWithNexusHydratedOrganization(
                        hydratedOrganization,
                        organizationSidePanelSaveSuccess(hydratedOrganization)
                    )
                );

                // this callback is only passed in for adding, but not editing.
                if (onAddSuccess) {
                    onAddSuccess(
                        createOnAddSuccessNameReportLink({
                            contextId,
                            contextType,
                            linkType,
                            ownerId,
                            hydratedName: hydratedOrganization,
                            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                        })
                    );
                }

                // If we are adding a new organization
                // then put the involved name card
                // into edit mode
                if (!isEditingExistingOrganization) {
                    dispatch(relationshipsCard.actionCreators.edit());
                }
                savePanel();
            })
            .catch((error) => {
                handleNameProfileFormError({
                    error,
                    appendPanelErrorMessageIfNotExists,
                    overlayStore: dependencies.overlayStore,
                    overlayId,
                });
            });
    };
}

export function quickAddOrganization({
    formModel,
    contextType,
    contextId,
    ownerType,
    ownerId,
    linkType,
    entityId,
    onAddSuccess,
    onComplete,
    arbiterInstance,
    organizationOverlayId,
    organizationLabel,
}) {
    return (dispatch, getState) => {
        const formConfiguration = createOrganizationProfileFormConfiguration();

        const onSubmitSuccess = () =>
            saveHydratedOrganization({
                formModel,
                getState,
                contextId,
                contextType,
                linkType,
                entityId,
                ownerId,
                ownerType,
                isEditingMasterProfile: false,
            }).then((hydratedOrganization) => {
                dispatch(
                    augmentActionWithNexusHydratedOrganization(
                        hydratedOrganization,
                        organizationSidePanelSaveSuccess(hydratedOrganization)
                    )
                );

                // this callback is only passed in for adding, but not editing.
                if (onAddSuccess) {
                    onAddSuccess(
                        createOnAddSuccessNameReportLink({
                            contextId,
                            contextType,
                            linkType,
                            ownerId,
                            hydratedName: hydratedOrganization,
                            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
                        })
                    );
                }
            });

        const getScreenStackWithErrorMessages = (errorMessages) => {
            return [
                {
                    screen: ORGANIZATION_SIDE_PANEL_SCREENS.PROFILE_EDIT,
                    screenState: {
                        errorMessages,
                        selectedId: entityId,
                    },
                },
            ];
        };

        return dispatch(
            quickAddName({
                formModel,
                entityId,
                onComplete,
                arbiterInstance,
                nameOverlayId: organizationOverlayId,
                formContext: RefContextEnum.FORM_ORGANIZATION_SIDE_PANEL.name,
                formConfiguration,
                onSubmitSuccess,
                genericSaveError: quickAddStrings.genericSaveError(organizationLabel),
                getScreenStackWithErrorMessages,
            })
        );
    };
}

export const sortedRecentElasticOrgsForContextSelector = createSelector(
    recentEntitiesForOwnerTypeOwnerIdAndEntityTypeSelector,
    elasticOrganizationsSelector,
    nameReportLinksWhereSelector,
    organizationProfilesWhereSelector,
    societyProfilesSelector,
    (
        recentEntitiesForOwnerTypeOwnerIdAndEntityType,
        elasticOrganizations,
        nameReportLinksWhere,
        organizationProfilesWhere,
        societyProfiles
    ) => ({
        renForRecents,
        ownerType,
        ownerId,
        linkType,
        contextId,
        showSocieties,
        stubbedLinks,
    }) => {
        const { recentEntityIdsByOwner = {} } =
            recentEntitiesForOwnerTypeOwnerIdAndEntityType({
                ownerType: renForRecents ? REN_IDENTIFIER : ownerType,
                ownerId: renForRecents || ownerId,
                entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            }) || {};

        const recentEntityIds = recentEntityIdsByOwner[ownerId] || [];

        const existingNameReportLinks = nameReportLinksWhere({
            linkType,
            reportId: ownerId,
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            contextId,
        });

        const orgIdsAlreadyLinked = chain(existingNameReportLinks)
            .concat(stubbedLinks)
            .flatMap((nrl) => {
                const orgId = nrl.nameId;
                const org =
                    head(organizationProfilesWhere({ id: orgId })) || elasticOrganizations[orgId];
                const masterOrganizationId = get(org, 'masterOrganizationId');
                return [orgId, masterOrganizationId];
            })
            .compact()
            .uniq()
            .value();

        const organizations = chain([])
            .concat(
                chain(recentEntityIds)
                    .uniq()
                    .map((recentEntityId) => elasticOrganizations[recentEntityId])
                    .map((elasticOrganization) => ({
                        id: get(elasticOrganization, 'id'),
                        sortGroup: 0,
                        sortKey: get(elasticOrganization, 'name', '').toLowerCase(),
                        isSocietyProfile: false,
                        name: get(elasticOrganization, 'name'),
                        organizationTypeAttrId: get(elasticOrganization, 'organizationTypeAttrId'),
                        involvedLocations: get(elasticOrganization, 'involvedLocations'),
                        masterOrganizationId: get(elasticOrganization, 'masterOrganizationId'),
                        ownerId: get(elasticOrganization, 'ownerId'),
                    }))
                    .value()
            )
            .concat(
                showSocieties
                    ? chain(societyProfiles)
                          .map((societyProfile) => ({
                              id: societyProfile.id,
                              sortGroup: 1,
                              sortKey: get(societyProfile, 'name', '').toLowerCase(),
                              isSocietyProfile: true,
                              name: get(societyProfile, 'name'),
                              organizationTypeAttrId: get(societyProfile, 'organizationTypeAttrId'),
                              involvedLocations: [],
                              masterOrganizationId: get(societyProfile, 'masterOrganizationId'),
                              ownerId: get(societyProfile, 'ownerId'),
                          }))
                          .value()
                    : []
            )
            .differenceWith(orgIdsAlreadyLinked, (organizationData, orgIdAlreadyLinked) => {
                if (organizationData.id === orgIdAlreadyLinked) {
                    return true;
                } else if (organizationData.isSocietyProfile) {
                    const organizationProfiles = organizationProfilesWhere({
                        masterOrganizationId: organizationData.id,
                        ownerType,
                        ownerId,
                    });
                    if (
                        organizationProfiles &&
                        findIndex(organizationProfiles, { id: orgIdAlreadyLinked }) >= 0
                    ) {
                        return true;
                    }
                }
                return false;
            })
            .compact()
            .value();

        const organizationsMappedToSameOwnerId = mapQuickAddEntitiesToSameOwnerId(
            organizations,
            ownerId,
            'masterOrganizationId'
        );

        return chain(organizationsMappedToSameOwnerId).sortBy(['sortGroup', 'sortKey']).value();
    }
);

export const sortedRecentElasticSocietyOrgsForContextSelector = createSelector(
    elasticOrganizationsSelector,
    nameReportLinksWhereSelector,
    organizationProfilesWhereSelector,
    societyProfilesSelector,
    (elasticOrganizations, nameReportLinksWhere, organizationProfilesWhere, societyProfiles) => ({
        ownerType,
        ownerId,
        linkType,
        contextId,
        showSocieties,
        stubbedLinks,
    }) => {
        const existingNameReportLinks = nameReportLinksWhere({
            linkType,
            reportId: ownerId,
            entityType: EntityTypeEnum.ORGANIZATION_PROFILE.name,
            contextId,
        });

        const orgIdsAlreadyLinked = chain(existingNameReportLinks)
            .concat(stubbedLinks)
            .flatMap((nrl) => {
                const orgId = nrl.nameId;
                const org =
                    head(organizationProfilesWhere({ id: orgId })) || elasticOrganizations[orgId];
                const masterOrganizationId = get(org, 'masterOrganizationId');
                return [orgId, masterOrganizationId];
            })
            .compact()
            .uniq()
            .value();

        const societyOrganizations = showSocieties
            ? chain(societyProfiles)
                  .map((societyProfile) => ({
                      id: societyProfile.id,
                      sortGroup: 1,
                      sortKey: get(societyProfile, 'name', '').toLowerCase(),
                      isSocietyProfile: true,
                      name: get(societyProfile, 'name'),
                      organizationTypeAttrId: get(societyProfile, 'organizationTypeAttrId'),
                      involvedLocations: [],
                      masterOrganizationId: get(societyProfile, 'masterOrganizationId'),
                      ownerId: get(societyProfile, 'ownerId'),
                  }))
                  .differenceWith(orgIdsAlreadyLinked, (organizationData, orgIdAlreadyLinked) => {
                      if (organizationData.id === orgIdAlreadyLinked) {
                          return true;
                      } else if (organizationData.isSocietyProfile) {
                          const organizationProfiles = organizationProfilesWhere({
                              masterOrganizationId: organizationData.id,
                              ownerType,
                              ownerId,
                          });
                          if (
                              organizationProfiles &&
                              findIndex(organizationProfiles, { id: orgIdAlreadyLinked }) >= 0
                          ) {
                              return true;
                          }
                      }
                      return false;
                  })
                  .compact()
                  .value()
            : [];

        const organizationsMappedToSameOwnerId = mapQuickAddEntitiesToSameOwnerId(
            societyOrganizations,
            ownerId,
            'masterOrganizationId'
        );

        return chain(organizationsMappedToSameOwnerId).sortBy(['sortGroup', 'sortKey']).value();
    }
);

import React from 'react';
import { noop } from 'lodash';
import { Observer, lifecycleOptions } from 'markformythree';
import { AttributeTypeEnum, ComplianceGroupEnum, RefContextEnum } from '@mark43/rms-api';

import {
    STOP_ANONYMOUS_SUBJECTS_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH,
    STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH,
    STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH,
    STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH,
    STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH,
    STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH,
    STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH,
    STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH,
    STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH,
    STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH,
    STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH,
    STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH,
    STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH,
    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH,
    STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH,
    STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH,
    STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH,
    STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH,
    STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH,
    STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH,
} from '~/client-common/core/domain/stop-anonymous-subjects/utils/stopAnonymousSubjectHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_REPORT_ANONYMOUS_SUBJECT_LABEL } from '~/client-common/core/enums/universal/fields';

import { VisibilityObserver } from '../../../../core/forms/markformythree-arbiter/mftArbiterObservers';
import { MFTNItems, RemoveButton } from '../../../../core/forms/components/NItems';
import { formName } from '../../state/forms/stopForm';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { CardSection } from '../../../../../legacy-redux/components/core/Card';
import WithComplianceGroup from '../../../../core/components/WithComplianceGroup';
import { ArbiterMFTAttributeCheckboxes } from '../../../../core/forms/components/checkboxes/AttributeCheckboxes';
import { ArbiterMFTAttributeSelect } from '../../../../core/forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../../../core/forms/components/selects/BooleanSelect';
import { ArbiterMFTRipaOffenseCodeSelect } from '../../../../core/forms/components/selects/RipaOffenseCodeSelect';
import { ArbiterMFTCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import { ArbiterMFTDatePicker } from '../../../../core/forms/components/DatePicker';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTTextArea } from '../../../../core/forms/components/TextArea';
import { ArbiterMFTBooleanButtonRadio } from '../../../../core/forms/components/button-radios/BooleanButtonRadio';
import { ArbiterMFTValidByDateAttributeSelect } from '../../../../core/forms/components/selects/ValidByDateAttributeSelect';
import IndentedFields from '../../../../core/components/IndentedFields';
import Row from '../../../../core/components/Row';
import { ArbiterMFTAttributeButtonRadio } from '../../../../core/forms/components/button-radios/AttributeButtonRadio';
import { useRipaCutoverStartDateUtcChangeHandler } from '../../hooks/useRipaCutoverStartDateUtcChangeHandler';
import StopCardLocationSection from './StopCardLocationSection';

const strings = componentStrings.reports.core.StopCard;

const StopCardForm = ({ stop }) => {
    const { ripaCutoverStartDateUtcChangeHandler } = useRipaCutoverStartDateUtcChangeHandler();
    const anonymousSubjectLabel = useFields(DISPLAY_ONLY_REPORT_ANONYMOUS_SUBJECT_LABEL)[
        DISPLAY_ONLY_REPORT_ANONYMOUS_SUBJECT_LABEL
    ];
    return (
        <ArbiterForm
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formName}
            context={formName}
            render={() => (
                <Observer
                    formName={RefContextEnum.FORM_STOP.name}
                    subscriptions={{ startDateUtc: 'stop.startDateUtc' }}
                    render={({ startDateUtc }) => (
                        <>
                            <CardSection title={strings.stopIncidentSectionTitle}>
                                <Row>
                                    <ArbiterMFTCheckbox path="stop.wasVehicleStopWithArrest" />
                                </Row>
                                <Row>
                                    <ArbiterMFTCheckbox path="stop.completingTcoleReport" />
                                </Row>
                                <Row>
                                    <ArbiterMFTCheckbox path="stop.atlQ1" />
                                </Row>
                                <Row>
                                    <ArbiterMFTCheckbox path="stop.atlQ2" />
                                </Row>
                                <VisibilityObserver
                                    path="atlWrapper"
                                    formName={formName}
                                    render={({ hidden }) =>
                                        !hidden && (
                                            <>
                                                <Row>
                                                    <WithComplianceGroup
                                                        complianceGroup={
                                                            ComplianceGroupEnum.CALIFORNIA.name
                                                        }
                                                        fallback={
                                                            <ArbiterMFTDatePicker
                                                                path="stop.startDateUtc"
                                                                length="lg"
                                                                includeTime={true}
                                                            />
                                                        }
                                                    >
                                                        <ArbiterMFTDatePicker
                                                            path="stop.startDateUtc"
                                                            length="lg"
                                                            includeTime={true}
                                                            onChange={
                                                                ripaCutoverStartDateUtcChangeHandler
                                                            }
                                                        />
                                                    </WithComplianceGroup>
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTDatePicker
                                                        path="stop.endDateUtc"
                                                        length="lg"
                                                        includeTime={true}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path="stop.wasResponseToCallForService"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <WithComplianceGroup
                                                    complianceGroup={
                                                        ComplianceGroupEnum.CALIFORNIA.name
                                                    }
                                                >
                                                    <Row>
                                                        <ArbiterMFTAttributeButtonRadio
                                                            path="stop.typeOfStopAttrId"
                                                            attributeType={
                                                                AttributeTypeEnum.TYPE_OF_STOP.name
                                                            }
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTBooleanButtonRadio
                                                            path="stop.wasWelfareWellnessCheckInvolved"
                                                            length="lg"
                                                        />
                                                    </Row>
                                                </WithComplianceGroup>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="stop.contactTypeAttrId"
                                                        length="lg"
                                                        attributeType={
                                                            AttributeTypeEnum.FIELD_CONTACT_TYPE
                                                                .name
                                                        }
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="stop.reasonForStopAttrId"
                                                        length="lg"
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .FIELD_CONTACT_REASON_FOR_STOP.name
                                                        }
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTText
                                                            path="stop.reasonForStopOther"
                                                            length="lg"
                                                        />
                                                    </Row>
                                                    <WithComplianceGroup
                                                        complianceGroup={
                                                            ComplianceGroupEnum.CALIFORNIA.name
                                                        }
                                                        fallback={
                                                            <Row>
                                                                <ArbiterMFTTextArea
                                                                    path="stop.narrative1"
                                                                    rows={4}
                                                                />
                                                            </Row>
                                                        }
                                                    />
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="stop.wasSubjectScreenedAttrId"
                                                        length="lg"
                                                        attributeType={
                                                            AttributeTypeEnum.WAS_SUBJECT_SCREENED
                                                                .name
                                                        }
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path="stop.wasSubjectSearched"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path="stop.reasonForSearchAttrId"
                                                            length="lg"
                                                            attributeType={
                                                                AttributeTypeEnum.REASON_FOR_SEARCH
                                                                    .name
                                                            }
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTBooleanSelect
                                                            path="stop.wasSearchConsented"
                                                            length="lg"
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path="stop.forceResultedInInjury"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path="stop.wasContrabandDiscovered"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path="stop.contrabandTypeAttrId"
                                                            length="lg"
                                                            attributeType={
                                                                AttributeTypeEnum.WEAPON_INVOLVED
                                                                    .name
                                                            }
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <IndentedFields>
                                                            <ArbiterMFTText
                                                                path="stop.contrabandTypeOther"
                                                                length="lg"
                                                            />
                                                        </IndentedFields>
                                                    </Row>
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path="stop.resultOfStopAttrId"
                                                        attributeType={
                                                            AttributeTypeEnum.RESULT_OF_STOP.name
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path="stop.arrestBasedOnAttrId"
                                                            attributeType={
                                                                AttributeTypeEnum.ARREST_BASED_ON
                                                                    .name
                                                            }
                                                            length="lg"
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path="stop.wasRaceKnown"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTText
                                                        path="stop.violationNumber"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTTextArea
                                                        path="stop.narrative2"
                                                        rows={4}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTTextArea
                                                        path="stop.narrative3"
                                                        rows={4}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTTextArea
                                                        path="stop.narrative4"
                                                        rows={4}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTTextArea
                                                        path="stop.narrative5"
                                                        rows={4}
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTBooleanSelect path="stop.wasPropertySearchConsented" />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path="reportAttributes.contrabandTypeAttrIds"
                                                            attributeType={
                                                                AttributeTypeEnum.WEAPON_INVOLVED
                                                                    .name
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTAttributeCheckboxes
                                                        path="reportAttributes.fieldContactDispositionAttrIds"
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .FIELD_CONTACT_DISPOSITION.name
                                                        }
                                                        columns={2}
                                                        columnWidth="48%"
                                                        gutterWidth="4%"
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTText
                                                            path="reportAttributes.fieldContactDispositionDescription"
                                                            length="lg"
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path="stop.wasInformationCollectedInOffenseReport"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path="stop.wasStopInvolved"
                                                        length="lg"
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path="reportAttributes.reasonForStopAttrIds"
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .STOP_REASON_FOR_STOP.name
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <IndentedFields>
                                                        <Row>
                                                            <ArbiterMFTText path="reportAttributes.reasonForStopDescription" />
                                                        </Row>
                                                    </IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTBooleanSelect
                                                            path="stop.wasArrestInvolved"
                                                            length="lg"
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                            </>
                                        )
                                    }
                                />
                            </CardSection>
                            <WithComplianceGroup
                                complianceGroup={ComplianceGroupEnum.CALIFORNIA.name}
                            >
                                <StopCardLocationSection
                                    summaryMode={false}
                                    stopId={stop.id}
                                    stop={stop}
                                />
                                <CardSection title={strings.stopOfficerSectionTitle}>
                                    <VisibilityObserver
                                        path="atlWrapper"
                                        formName={formName}
                                        render={({ hidden }) =>
                                            !hidden && (
                                                <>
                                                    <Row>
                                                        <ArbiterMFTText
                                                            path="stop.userYearsOfExperience"
                                                            length="lg"
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <WithComplianceGroup
                                                            complianceGroup={
                                                                ComplianceGroupEnum.CALIFORNIA.name
                                                            }
                                                            fallback={
                                                                <ArbiterMFTAttributeSelect
                                                                    path="stop.userAssignmentTypeAttrId"
                                                                    attributeType={
                                                                        AttributeTypeEnum
                                                                            .STOP_USER_ASSIGNMENT_TYPE
                                                                            .name
                                                                    }
                                                                />
                                                            }
                                                        >
                                                            <ArbiterMFTValidByDateAttributeSelect
                                                                path="stop.userAssignmentTypeAttrId"
                                                                attributeType={
                                                                    AttributeTypeEnum
                                                                        .STOP_USER_ASSIGNMENT_TYPE
                                                                        .name
                                                                }
                                                                timeWindowDate={startDateUtc}
                                                            />
                                                        </WithComplianceGroup>
                                                    </Row>
                                                    <IndentedFields>
                                                        <Row>
                                                            <ArbiterMFTText
                                                                path="stop.userAssignmentTypeOther"
                                                                length="lg"
                                                            />
                                                        </Row>
                                                    </IndentedFields>
                                                    <Row>
                                                        <WithComplianceGroup
                                                            complianceGroup={
                                                                ComplianceGroupEnum.CALIFORNIA.name
                                                            }
                                                        >
                                                            <ArbiterMFTValidByDateAttributeSelect
                                                                path={
                                                                    'stop.officerRaceOrEthnicityAttrIds'
                                                                }
                                                                attributeType={
                                                                    AttributeTypeEnum
                                                                        .STOP_DATA_OFFICER_RACE.name
                                                                }
                                                                timeWindowDate={startDateUtc}
                                                                multiple={true}
                                                            />
                                                            <ArbiterMFTValidByDateAttributeSelect
                                                                path={'stop.officerGender'}
                                                                attributeType={
                                                                    AttributeTypeEnum
                                                                        .STOP_DATA_OFFICER_GENDER
                                                                        .name
                                                                }
                                                                timeWindowDate={startDateUtc}
                                                                multiple={false}
                                                            />
                                                            <Row>
                                                                <ArbiterMFTBooleanButtonRadio
                                                                    path="stop.isNonbinaryOfficer"
                                                                    length="lg"
                                                                />
                                                            </Row>
                                                            <Row>
                                                                <ArbiterMFTBooleanButtonRadio
                                                                    path="stop.isNonPrimaryAgencyInConjunctionNonReportingAgency"
                                                                    length="lg"
                                                                />
                                                            </Row>
                                                        </WithComplianceGroup>
                                                    </Row>
                                                </>
                                            )
                                        }
                                    />
                                </CardSection>
                                <CardSection title={anonymousSubjectLabel}>
                                    <MFTNItems
                                        path={STOP_ANONYMOUS_SUBJECTS_PATH}
                                        addItemOnEmpty={true}
                                        addText={anonymousSubjectLabel}
                                        hideAddButtonOnEmptyItem={true}
                                        autoFocusFirstInputOnAdd={true}
                                        renderRemoveButton={noop}
                                        render={({ items, index, removeItem }) => () => (
                                            <div>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_LGBT_PATH
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_IS_PERCEIVED_DISABILITY_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .SUBJECT_DATA_DISABILITY.name
                                                        }
                                                        length="lg"
                                                        multiple={true}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_LIMITED_ENGLISH_FLUENCY_PATH
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTValidByDateAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_RACE_ATTRIBUTE_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum.SUBJECT_DATA_RACE.name
                                                        }
                                                        timeWindowDate={startDateUtc}
                                                        length="lg"
                                                        multiple={true}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTValidByDateAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_GENDER_ATTR_ID_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum.SUBJECT_DATA_GENDER
                                                                .name
                                                        }
                                                        timeWindowDate={startDateUtc}
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanButtonRadio
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_IS_NONBINARY_PATH
                                                        }
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanButtonRadio
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_PERSON_PERCEIVED_TO_BE_UNHOUSED_PATH
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanButtonRadio
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_PERSON_PASSENGER_IN_A_VEHICLE_PATH
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanButtonRadio
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_IS_IN_RESIDENCE_PATH
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTText
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_PERCEIVED_AGE_PATH
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTBooleanSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_IS_STUDENT_PATH
                                                        }
                                                        length="lg"
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTValidByDateAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_ATTR_ID_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .SUBJECT_DATA_REASON_FOR_STOP.name
                                                        }
                                                        timeWindowDate={startDateUtc}
                                                        length="lg"
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTValidByDateAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_TYPE_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .SUBJECT_DATA_PROBABLE_CAUSE
                                                                    .name
                                                            }
                                                            timeWindowDate={startDateUtc}
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_PROBABLE_CAUSE_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={false}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTTextArea
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_REASON_FOR_STOP_NARRATIVE_PATH
                                                            }
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTValidByDateAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_REASON_GIVEN_TO_STOPPED_PERSON_ATTR_ID_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .SUBJECT_DATA_REASON_GIVEN_TO_STOPPED_PERSON
                                                                    .name
                                                            }
                                                            timeWindowDate={startDateUtc}
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_ATTR_ID_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .STOP_TYPE_OF_TRAFFIC_VIOLATION
                                                                    .name
                                                            }
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_TRAFFIC_VIOLATION_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SECTION_ATTR_ID_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .STOP_EDUCATION_CODE_SECTION
                                                                    .name
                                                            }
                                                        />
                                                    </Row>
                                                    <IndentedFields>
                                                        <Row>
                                                            <ArbiterMFTAttributeSelect
                                                                path={
                                                                    STOP_ANONYMOUS_SUBJECT_EDUCATION_CODE_SUBDIVISION_ATTR_ID_PATH
                                                                }
                                                                attributeType={
                                                                    AttributeTypeEnum
                                                                        .STOP_EDUCATION_CODE_SUBDIVISION
                                                                        .name
                                                                }
                                                            />
                                                        </Row>
                                                    </IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_SUSPICION_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={false}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTValidByDateAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_SUSPICION_SUB_TYPE_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .STOP_SUSPICION_TYPE.name
                                                            }
                                                            timeWindowDate={startDateUtc}
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_ACTIONS_TAKEN_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum.STOP_ACTION_TAKEN.name
                                                        }
                                                        length="lg"
                                                        multiple={true}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_NON_FORCIBLE_ACTION_TAKEN_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .STOP_NON_FORCIBLE_ACTION_TAKEN.name
                                                        }
                                                        length="lg"
                                                        multiple={true}
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTBooleanSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_WAS_SEARCH_CONSENTED_PATH
                                                            }
                                                            length="lg"
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTBooleanSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_WAS_PROPERTY_SEARCH_CONSENTED_PATH
                                                            }
                                                            length="lg"
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_REASON_FOR_SEARCH_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum.REASON_FOR_SEARCH
                                                                    .name
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <IndentedFields>
                                                        <Row>
                                                            <ArbiterMFTAttributeSelect
                                                                path={
                                                                    STOP_ANONYMOUS_SUBJECT_CONSENT_TYPE_ATTR_ID_PATH
                                                                }
                                                                attributeType={
                                                                    AttributeTypeEnum.CONSENT_TYPE
                                                                        .name
                                                                }
                                                            />
                                                        </Row>
                                                        <Row>
                                                            <ArbiterMFTTextArea
                                                                path={
                                                                    STOP_ANONYMOUS_SUBJECT_BASIS_FOR_SEARCH_NARRATIVE_PATH
                                                                }
                                                            />
                                                        </Row>
                                                    </IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_CONTRABAND_OR_EVIDENCE_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .SUBJECT_DATA_CONTRABAND_OR_EVIDENCE
                                                                    .name
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_BASIS_FOR_PROPERTY_SEIZURE_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .STOP_BASIS_FOR_PROPERTY_SEIZURE
                                                                    .name
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTAttributeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_TYPE_OF_PROPERTY_SEIZED_PATH
                                                            }
                                                            attributeType={
                                                                AttributeTypeEnum
                                                                    .STOP_TYPE_OF_PROPERTY_SEIZED
                                                                    .name
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                                <Row>
                                                    <ArbiterMFTAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_FORCIBLE_ACTION_TAKEN_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .STOP_FORCIBLE_ACTION_TAKEN.name
                                                        }
                                                        length="lg"
                                                        multiple={true}
                                                    />
                                                </Row>
                                                <Row>
                                                    <ArbiterMFTValidByDateAttributeSelect
                                                        path={
                                                            STOP_ANONYMOUS_SUBJECT_RESULT_OF_STOP_PATH
                                                        }
                                                        attributeType={
                                                            AttributeTypeEnum
                                                                .SUBJECT_DATA_RESULT_OF_STOP.name
                                                        }
                                                        timeWindowDate={startDateUtc}
                                                        length="lg"
                                                        multiple={true}
                                                    />
                                                </Row>
                                                <IndentedFields>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_WARNING_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_VERBAL_WARNING_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_WRITTEN_WARNING_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_CITATION_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_IN_FIELD_CITE_RELEASE_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                    <Row>
                                                        <ArbiterMFTRipaOffenseCodeSelect
                                                            path={
                                                                STOP_ANONYMOUS_SUBJECT_CUSTODIAL_ARREST_CJIS_OFFENSE_CODE_PATH
                                                            }
                                                            multiple={true}
                                                        />
                                                    </Row>
                                                </IndentedFields>
                                                {(index > 0 || items.length > 1) && (
                                                    <RemoveButton onClick={removeItem} />
                                                )}
                                            </div>
                                        )}
                                    />
                                </CardSection>
                            </WithComplianceGroup>
                        </>
                    )}
                />
            )}
        />
    );
};

export default StopCardForm;

import React from 'react';
import _, { uniqBy, map } from 'lodash';
import { nameReportLinkTypePriority } from '~/client-common/helpers/nameReportLinksHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import constants from '../../../../../core/constants';

const linkTypeLetters = constants.linkTypeLetters;
const MAX_ORGANIZATIONS_LENGTH = 6;

export default function OrganizationsCell({ involvedOrganizations = [], permissionSet = [] }) {
    if (involvedOrganizations.length === 0) {
        const fallbackText = canRead(permissionSet) ? 'No Involved Organizations' : undefined;
        return <div>{fallbackText}</div>;
    }

    const displayedOrganizations = _(involvedOrganizations)
        // Implementation of sort logic described in RMS-2053
        .sortBy('linkTypeSequenceNumber', ({ involvement }) =>
            nameReportLinkTypePriority(involvement)
        )
        .take(MAX_ORGANIZATIONS_LENGTH)
        .value();

    const displayedOrganizationsUniqueCount = uniqBy(displayedOrganizations, 'org.id').length;
    const totalOrganizationsUniqueCount = uniqBy(involvedOrganizations, 'org.id').length;
    const organizationsNotDisplayedCount =
        totalOrganizationsUniqueCount - displayedOrganizationsUniqueCount;

    const mappedOrganizations = map(
        displayedOrganizations,
        ({ involvement, linkTypeSequenceNumber, org: { name } }) => (
            <div key={`${involvement}~${linkTypeSequenceNumber}`}>
                <div className="link-type">
                    <span>{`${linkTypeLetters[involvement]}${linkTypeSequenceNumber || ''}`}</span>
                    <span className="link-type-dash">-</span>
                </div>
                <div className="name">{name}</div>
            </div>
        )
    );

    // This shows the number of unique organizations not displayed in the cell
    // which is different from the number of organization report links which are
    // not displayed in the cell
    const showMore =
        organizationsNotDisplayedCount > 0 ? (
            <div className="elastic-row-show-more">
                {`+ ${organizationsNotDisplayedCount} other organizations`}
            </div>
        ) : undefined;

    return (
        <div className="elastic-report-name">
            {mappedOrganizations}
            {showMore}
        </div>
    );
}

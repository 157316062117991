import {
    DispositionEventTypeEnum,
    RetentionPolicyTypeEnum,
    DispositionStatusEnum,
    ChainEventCategoryEnum,
} from '@mark43/evidence-api';
import _, { chain, findIndex, get, includes, slice } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { compose, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import { chainEventViewModelsForChainOfCustodyIdSelector } from '~/client-common/core/domain/chain-events/state/ui';
import { dispositionEventsByChainOfCustodyIdSelector } from '~/client-common/core/domain/disposition-events/state/data';
import { dispositionEventViewModelByIdSelector } from '~/client-common/core/domain/disposition-events/state/ui';
import { chainOfCustodiesSelector } from '~/client-common/core/domain/chain-of-custodies/state/data';
import { retentionPoliciesSelector } from '~/client-common/core/domain/retention-policies/state/data';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { timeAgo } from '~/client-common/core/dates/utils/dateHelpers';
import { parsePeriod } from '~/client-common/core/dates/utils/dateRangeHelpers';
import { FormattedDate } from '~/client-common/core/dates/components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import DispositionHistoryText from './DispositionHistoryText';

const strings = componentStrings.reports.custodialPropertySummary.disposition.DispositionHistoryRow;
const dispositionApprovalLevelSelectStrings =
    componentStrings.forms.select.DispositionApprovalLevelSelect.options;

const {
    RETENTION_PERIOD_STARTED,
    RETENTION_PERIOD_EXPIRED,
    MANUAL_REQUEST,
    PRIMARY_RELEASE_APPROVED,
    PRIMARY_DISPOSITION_APPROVED,
    SECONDARY_REJECT,
    RELEASE_APPROVED,
    DISPOSITION_APPROVED,
    REJECTED_HOLD,
    RELEASE_EXPIRED,
    COMPLETED,
} = DispositionEventTypeEnum;

const { CONFIGURED, ONE_OFF } = RetentionPolicyTypeEnum;

const {
    WAITING_RETENTION_PERIOD,
    INELIGIBLE_FOR_DISPOSITION,
    APPROVED_FOR_RELEASE,
    APPROVED_FOR_DISPOSITION,
    PENDING_SECONDARY_REVIEW_FOR_RELEASE,
    PENDING_SECONDARY_REVIEW_FOR_DISPOSITION,
    PENDING_PRIMARY_REVIEW,
    DISPOSITION_COMPLETED,
} = DispositionStatusEnum;

const { DISPOSITIONED, RELEASED, TRANSFERRED } = ChainEventCategoryEnum;

function _DispositionHistoryRow({
    chainEventCategoryByChainOfCustodyId,
    className,
    dispositionEventViewModel,
    previousDispositionEvents,
    retentionPolicies,
}) {
    const {
        chainOfCustodyId,
        comment,
        dispositionEventType,
        eventDateUtc,
    } = dispositionEventViewModel;

    const { eventUserId } = getViewModelProperties(dispositionEventViewModel);

    const prevDispositionStatus = chain(previousDispositionEvents)
        .find(({ dispositionState = {} }) =>
            includes(
                [
                    WAITING_RETENTION_PERIOD.name,
                    PENDING_PRIMARY_REVIEW.name,
                    PENDING_SECONDARY_REVIEW_FOR_RELEASE.name,
                    PENDING_SECONDARY_REVIEW_FOR_DISPOSITION.name,
                ],
                dispositionState.dispositionStatus
            )
        )
        .get('dispositionState.dispositionStatus')
        .value();

    const commentContent = comment ? <p>"{comment}"</p> : null;

    const chainEventCategoryType = chainEventCategoryByChainOfCustodyId(chainOfCustodyId);
    const eventUser = get(eventUserId, 'fullNameWithFirstInitialAndIdNumber');

    const {
        dispositionApprovalLevel,
        dispositionStatus,
        expirationDateUtc,
        retentionPolicyId,
    } = dispositionEventViewModel.dispositionState;

    const { code, retentionPolicyType } = retentionPolicies[retentionPolicyId] || {};
    const daysOnHold = chain(dispositionEventViewModel)
        .get('manualRetentionPeriod')
        .thru(parsePeriod)
        .get('amount')
        .value();

    // (NEW) Retention period is reset
    if (
        dispositionEventType === RETENTION_PERIOD_STARTED.name &&
        dispositionStatus === WAITING_RETENTION_PERIOD.name &&
        retentionPolicyType === ONE_OFF.name
    ) {
        return (
            <div className={className}>
                {commentContent}
                {strings.retentionPeriodIsReset(eventUser)}
                <DispositionHistoryText>
                    {timeAgo(eventDateUtc)}
                    <br />
                    {'Expires '}
                    <FormattedDate
                        date={expirationDateUtc}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                    {`, ${dispositionApprovalLevelSelectStrings[dispositionApprovalLevel]} required`}
                </DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RETENTION_PERIOD_STARTED.name &&
        dispositionStatus === WAITING_RETENTION_PERIOD.name &&
        retentionPolicyType === CONFIGURED.name
    ) {
        // Retention period starts for item eligible for dispo
        return (
            <div className={className}>
                {strings.retentionPeriodStartForItemEligibleForDisposition}
                <DispositionHistoryText>
                    {timeAgo(eventDateUtc)}
                    <br />
                    {'Expires '}
                    <FormattedDate
                        date={expirationDateUtc}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                    {`. Retention policy code ${code}`}
                </DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RETENTION_PERIOD_STARTED.name &&
        dispositionStatus === INELIGIBLE_FOR_DISPOSITION.name &&
        retentionPolicyType === CONFIGURED.name
    ) {
        // Retention period starts for item ineligible for dispo
        return (
            <div className={className}>
                {strings.retentionPeriodStartsForItemIneligibleForDisposition}
                <DispositionHistoryText>
                    {timeAgo(eventDateUtc)}
                    <br />
                    {strings.retentionPeriodStartsForItemIneligibleForDispositionRententionPolicyCode(
                        code
                    )}
                </DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RETENTION_PERIOD_EXPIRED.name &&
        dispositionStatus === PENDING_PRIMARY_REVIEW.name
    ) {
        // (UPDATED) Retention period ends (initial approval required)
        return (
            <div className={className}>
                {strings.retentionPeriodCompletedDispositionAutoRequested}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RETENTION_PERIOD_EXPIRED.name &&
        dispositionStatus === APPROVED_FOR_DISPOSITION.name
    ) {
        // (UPDATED) Retention period ends and dispo approved (no initial approval required)
        return (
            <div className={className}>
                {strings.retentionPeriodCompletedDispositionAutoApproved}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RETENTION_PERIOD_EXPIRED.name &&
        dispositionStatus === APPROVED_FOR_RELEASE.name
    ) {
        // (UPDATED) Retention period ends and release approved (no initial approval required)
        return (
            <div className={className}>
                {strings.retentionPeriodCompletedReleaseToOwnerAutoApproved}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === MANUAL_REQUEST.name &&
        dispositionStatus === PENDING_PRIMARY_REVIEW.name
    ) {
        // (UPDATED) Manual disposition request (initial approval required)
        return (
            <div className={className}>
                {commentContent}
                {strings.dispositionRequestedByUser(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === MANUAL_REQUEST.name &&
        dispositionStatus === APPROVED_FOR_DISPOSITION.name
    ) {
        // (UPDATED) Manual disposition request and dispo approved (no initial approval required)
        return (
            <div className={className}>
                {commentContent}
                {strings.dispositionRequestedByUserDispositionAutoApproved(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === MANUAL_REQUEST.name &&
        dispositionStatus === APPROVED_FOR_RELEASE.name
    ) {
        // (UPDATED) Manual disposition request and release approved ((no initial approval required)
        return (
            <div className={className}>
                {commentContent}
                {strings.dispositionRequestedByUserReleaseToOwnerAutoApproved(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === REJECTED_HOLD.name &&
        dispositionStatus === WAITING_RETENTION_PERIOD.name
    ) {
        // Initial approver holds item
        return (
            <div className={className}>
                {commentContent}
                {strings.initialApproverHolderItem(daysOnHold, eventUser)}
                <DispositionHistoryText>
                    {timeAgo(eventDateUtc)}
                    <br />
                    {'Expires '}
                    <FormattedDate
                        date={expirationDateUtc}
                        format={FormattedDate.FORMATS.SUMMARY_DATE}
                    />
                </DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RELEASE_APPROVED.name &&
        dispositionStatus === APPROVED_FOR_RELEASE.name &&
        prevDispositionStatus === PENDING_PRIMARY_REVIEW.name
    ) {
        // Initial approver approves for release (no secondary approval required)
        return (
            <div className={className}>
                {commentContent}
                {strings.initialApproverApprovesForReleaseNoSecondaryApprovalRequired(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === DISPOSITION_APPROVED.name &&
        dispositionStatus === APPROVED_FOR_DISPOSITION.name &&
        prevDispositionStatus === PENDING_PRIMARY_REVIEW.name
    ) {
        // Initial approver approves for dispo (no secondary approval required)
        return (
            <div className={className}>
                {commentContent}
                {strings.initialApproverApprovesForDispositionNoSecondaryApprovalRequired(
                    eventUser
                )}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === PRIMARY_RELEASE_APPROVED.name &&
        dispositionStatus === PENDING_SECONDARY_REVIEW_FOR_RELEASE.name
    ) {
        // Initial approver approves for release (secondary approval required)
        return (
            <div className={className}>
                {commentContent}
                {strings.initialApproverApprovesForReleaseSecondaryApprovalRequired(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === PRIMARY_DISPOSITION_APPROVED.name &&
        dispositionStatus === PENDING_SECONDARY_REVIEW_FOR_DISPOSITION.name
    ) {
        // Initial approver approves for dispo (secondary approval required)
        return (
            <div className={className}>
                {commentContent}
                {strings.initialApproverApprovesForDispositionSecondaryApprovalRequired(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === SECONDARY_REJECT.name &&
        dispositionStatus === PENDING_PRIMARY_REVIEW.name &&
        prevDispositionStatus === PENDING_SECONDARY_REVIEW_FOR_RELEASE.name
    ) {
        // Secondary approver rejects initial approver decision to release
        return (
            <div className={className}>
                {commentContent}
                {strings.secondaryApproverRejectsInitialApproverDecisionToRelease(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === SECONDARY_REJECT.name &&
        dispositionStatus === PENDING_PRIMARY_REVIEW.name &&
        prevDispositionStatus === PENDING_SECONDARY_REVIEW_FOR_DISPOSITION.name
    ) {
        // Secondary approver rejects initial approver decision to dispo
        return (
            <div className={className}>
                {commentContent}
                {strings.secondaryApproverRejectsInitialApproverDecisionToDisposition(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RELEASE_APPROVED.name &&
        dispositionStatus === APPROVED_FOR_RELEASE.name &&
        prevDispositionStatus === PENDING_SECONDARY_REVIEW_FOR_RELEASE.name
    ) {
        // Secondary approver approves initial approver decision to release
        return (
            <div className={className}>
                {commentContent}
                {strings.secondaryApproverApprovesInitialApproverDecisionToRelease(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === DISPOSITION_APPROVED.name &&
        dispositionStatus === APPROVED_FOR_DISPOSITION.name &&
        prevDispositionStatus === PENDING_SECONDARY_REVIEW_FOR_DISPOSITION.name
    ) {
        // Secondary approver approves initial approver decision to dispo
        return (
            <div className={className}>
                {commentContent}
                {strings.secondaryApproverApprovesInitialApproverDecisionToDisposition(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === RELEASE_EXPIRED.name &&
        dispositionStatus === APPROVED_FOR_DISPOSITION.name
    ) {
        // (UPDATED) Release timer expires and dispo approved
        return (
            <div className={className}>
                {strings.releaseTimerExpiredDispositionAutoApproved}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === COMPLETED.name &&
        dispositionStatus === DISPOSITION_COMPLETED.name &&
        chainEventCategoryType === RELEASED.name
    ) {
        // Item is released	COMPLETED
        return (
            <div className={className}>
                {strings.itemIsReleasedCompleted(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === COMPLETED.name &&
        dispositionStatus === DISPOSITION_COMPLETED.name &&
        chainEventCategoryType === DISPOSITIONED.name
    ) {
        // Item is dispo-ed	COMPLETED
        return (
            <div className={className}>
                {strings.itemIsDispositionCompleted(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    } else if (
        dispositionEventType === COMPLETED.name &&
        dispositionStatus === DISPOSITION_COMPLETED.name &&
        chainEventCategoryType === TRANSFERRED.name
    ) {
        // Item is transferred to another agency
        return (
            <div className={className}>
                {strings.itemIsTransferredToAnotherAgency(eventUser)}
                <DispositionHistoryText>{timeAgo(eventDateUtc)}</DispositionHistoryText>
            </div>
        );
    }

    return null;
}

const DispositionHistoryRow = styled(_DispositionHistoryRow)`
    margin-top: 15px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: ${(props) => `1px dashed ${props.theme.colors.lightGrey}`};
`;

const mapStateToProps = createStructuredSelector({
    chainEventCategoryByChainOfCustodyId: createSelector(
        chainEventViewModelsForChainOfCustodyIdSelector,
        (chainEventViewModelsForChainOfCustodyId) => (chainOfCustodyId) =>
            chain(chainOfCustodyId)
                .thru(chainEventViewModelsForChainOfCustodyId)
                .head()
                .thru(getViewModelProperties)
                .get('eventType.chainEventCategory')
                .value()
    ),
    chainOfCustodies: chainOfCustodiesSelector,
    dispositionEventsByChainOfCustodyId: dispositionEventsByChainOfCustodyIdSelector,
    dispositionEventViewModelById: dispositionEventViewModelByIdSelector,
    retentionPolicies: retentionPoliciesSelector,
});

/**
 * A row in the disposition history of an item - arbitrarily many of these rows
 *   comprise the full disposition history. The "main" disposition event is
 *   either a rejection or release expiration. The approval events that preceded
 *   the "main" disposition event are displayed below it.
 * @param {number} [props.dispositionEventId]
 */
export default compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            'dispositionEventId',
            'dispositionEventsByChainOfCustodyId',
            'dispositionEventViewModelById',
        ],
        ({
            dispositionEventId,
            dispositionEventsByChainOfCustodyId,
            dispositionEventViewModelById,
        }) => {
            const dispositionEventViewModel = dispositionEventViewModelById(dispositionEventId);
            const { id, chainOfCustodyId } = dispositionEventViewModel;
            const dispositionEvents = dispositionEventsByChainOfCustodyId(chainOfCustodyId);

            // calculate a range of indexes of the approval events that happened
            // before this event and after the previous rejection/expiration event
            const index = findIndex(dispositionEvents, { id });
            const nextIndex = _(dispositionEvents)
                .drop(index + 1)
                .findIndex(({ dispositionEventType }) => {
                    return includes(
                        [REJECTED_HOLD.name, SECONDARY_REJECT.name, RELEASE_EXPIRED.name],
                        dispositionEventType
                    );
                });
            const lastIndex = nextIndex === -1 ? undefined : nextIndex + index + 1;
            const previousDispositionEvents = slice(dispositionEvents, index + 1, lastIndex);
            return {
                dispositionEventViewModel,
                previousDispositionEvents,
            };
        }
    )
)(DispositionHistoryRow);

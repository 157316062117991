import {
    FolderUploadRequest,
    FoldersResource,
    EntityTypeEnumType,
    FolderUpdateRequest,
    FolderDeletionRequest,
    MoveFolderRequest,
    SearchFoldersAndAttachmentsRequest,
} from '@mark43/rms-api';

import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Folder Resource',
    methods: {
        createTopLevelFolder(data: FolderUploadRequest) {
            return req<FoldersResource.CreateTopLevelFolderOnOwner>({
                method: 'POST',
                url: 'folders/top-level',
                data,
            });
        },
        createSubFolder(data: FolderUploadRequest, parentFolderId: number) {
            return req<FoldersResource.CreateSubFolder>({
                method: 'POST',
                url: `folders/subfolders/${parentFolderId}/`,
                data,
            });
        },
        getFolderHierarchyForFolder(
            ownerId: number,
            entityType: EntityTypeEnumType,
            folderId: number
        ) {
            return req<FoldersResource.GetFolderHierarchyForFolder>({
                method: 'GET',
                url: `folders/${ownerId}/${entityType}/${folderId}/folder_hierarchy`,
            });
        },
        getTopLevelFolders(ownerId: number, entityType: EntityTypeEnumType) {
            return req<FoldersResource.GetTopLevelFoldersForOwner>({
                method: 'GET',
                url: `folders/${ownerId}/${entityType}/top-level`,
            });
        },
        getTopLevelFoldersForOwner(ownerId: number) {
            return req<FoldersResource.GetTopLevelFolders>({
                method: 'GET',
                url: `folders/${ownerId}/top-level`,
            });
        },
        getDirectSubFolders(parentFolderId: number) {
            return req<FoldersResource.GetDirectSubFoldersForParent>({
                method: 'GET',
                url: `folders/subfolders/${parentFolderId}/direct_children`,
            });
        },
        updateFolder(data: FolderUpdateRequest) {
            return req<FoldersResource.UpdateFolder>({
                method: 'PUT',
                url: 'folders',
                data,
            });
        },
        deleteFolders(data: FolderDeletionRequest) {
            return req<FoldersResource.DeleteFolders>({
                method: 'POST',
                url: 'folders/delete/bulk',
                data,
            });
        },
        moveFoldersIntoFolders(data: MoveFolderRequest) {
            return req<FoldersResource.MoveFoldersIntoFolder>({
                method: 'PUT',
                url: 'folders/move',
                data,
            });
        },
        searchFoldersAndAttachments(data: SearchFoldersAndAttachmentsRequest) {
            return req<FoldersResource.SearchFoldersAndAttachments>({
                method: 'POST',
                url: 'folders/search',
                data,
            });
        },
    },
});

import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { Tooltip } from '../../../core/components/tooltip';
import { SearchFilterContext } from '../contexts';
import testIds from '../../../../core/testIds';

export const CurrentFolderStyle = styled.span`
    color: ${(props) => props.theme.colors.darkGrey};
    white-space: nowrap;
`;

const OverlayContainer = styled.div`
    text-align: center;
`;

const TooltipWrap = styled.div`
    display: flex;
    align-items: center;
    min-width: 2em;
    & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        white-space: nowrap;
    }
`;
interface BreadcrumbProps {
    path: string;
    name: string;
    lastBreadcrumb?: boolean;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ path, name, lastBreadcrumb }) => {
    const { setFilter, setCurrentPage } = useContext(SearchFilterContext);

    return (
        <>
            <Icon color="cobaltBlue" size={12} type={iconTypes.OPEN_RIGHT} />
            <TooltipWrap data-test-id={testIds.BREADCRUMB_LIST}>
                <Tooltip
                    side="top"
                    align="start"
                    content={<OverlayContainer>{name}</OverlayContainer>}
                >
                    {lastBreadcrumb ? (
                        <CurrentFolderStyle>{name}</CurrentFolderStyle>
                    ) : (
                        <>
                            <Link
                                to={path}
                                onClick={() => {
                                    if (setFilter) {
                                        setFilter('');
                                    }
                                    if (setCurrentPage) {
                                        setCurrentPage(1);
                                    }
                                }}
                                data-test-id={testIds.BREADCRUMB_LIST_LINK}
                            >
                                {name}
                            </Link>
                        </>
                    )}
                </Tooltip>
            </TooltipWrap>
        </>
    );
};

export default Breadcrumb;

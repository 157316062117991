import { partition } from 'lodash';
import { createSelector } from 'reselect';

import { Folder, foldersSelector } from '~/client-common/core/domain/folders/state/data';
import { ModuleShape } from '~/client-common/core/utils/createNormalizedModule';

/**
 * Split the given ids into two arrays, one for attachments and the other for attachment folders.
 */
function partitionAttachmentAndFolderIds(
    ids: number[],
    folders: ModuleShape<Folder>
): {
    attachmentIds: number[];
    folderIds: number[];
    folders: ModuleShape<Folder>;
} {
    const [folderIds, attachmentIds] = partition(ids, (id) => !!folders[id]);
    return { attachmentIds, folderIds, folders };
}

export const partitionAttachmentAndFolderIdsSelector = createSelector(
    foldersSelector,
    (folders) => (ids: number[]) => partitionAttachmentAndFolderIds(ids, folders)
);

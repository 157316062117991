import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Caution, ElasticCaution } from '@mark43/rms-api';
import { CautionGroup, CautionProps, cssVar } from 'arc';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CAUTION_INACTIVE } from '~/client-common/core/enums/universal/fields';
import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';

import testIds from '../../../../core/testIds';
import Link from '../../components/links/Link';

import {
    type CautionEntityTypes,
    type CautionComputedData,
    entityTypeToCautionAttributeTypes,
    entityTypeToEntityProfileType,
} from '../configuration';
import { prepareEnhancedCautions, sortCustomAndEnhancedCautions } from '../helpers';
import { EnhancedCaution } from './EnhancedCaution';

interface EnhancedCautionsPropsBase {
    entityType: CautionEntityTypes;
    /** "real" cautions that are manually created in Person/Vehicle Profile forms */
    cautions?: Caution[] | ElasticCaution[];
    /** "fake" cautions that are created based on model properties like isJuvenile or isStolen */
    customCautions?: CautionComputedData[];
    /**
     * Enables logic that treats a caution as effective (visible) if it was effective at the time of creation.
     * This logic is used in Report cards and Involved Profiles sidebar, where we want to show a "snapshot" of cautions.
     * When we create a contexted profile in a report, we recreate all cautions, so we can use creationDateUtc as the "snapshot" time.
     */
    evaluateByCreationDates?: boolean;
    includePriority1Only?: boolean;
    size?: CautionProps['size'];
    showTooltips?: boolean;
    renderAddButton?: React.ReactNode;
    className?: string;
}

export type EnhancedCautionsProps =
    | (EnhancedCautionsPropsBase & { showInactiveIndicator?: false; entityId?: number })
    | (EnhancedCautionsPropsBase & { showInactiveIndicator: true; entityId: number });

const StyledCautionGroup = styled(CautionGroup)`
    align-items: center;
`;
const StyledLink = styled(Link)`
    padding-bottom: 2px;
    // we need a pseudo-class to surpass the default a:visited selector specificity
    &: any-link {
        color: ${cssVar('arc.colors.text.secondary')};
    }
`;

export const EnhancedCautions: React.FC<EnhancedCautionsProps> = ({
    cautions = [],
    customCautions = [],
    entityId,
    entityType,
    evaluateByCreationDates,
    includePriority1Only,
    size,
    showInactiveIndicator,
    showTooltips,
    renderAddButton,
    className,
}) => {
    const { valueAttrType, categoryAttrType } = entityTypeToCautionAttributeTypes[entityType];
    const { isLoading } = usePreloadAttributes([valueAttrType, categoryAttrType]);

    const { DISPLAY_ONLY_CAUTION_INACTIVE: inactiveDisplayValue } = useFields(
        DISPLAY_ONLY_CAUTION_INACTIVE
    );

    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);

    if (isLoading) {
        return null;
    }

    const { enhancedCautions, inactiveCautionsCounter } = prepareEnhancedCautions({
        cautions,
        includePriority1Only,
        evaluateByCreationDates,
        formatAttributeById,
        parentAttributeIdByAttributeId,
    });

    const sortedCautions = sortCustomAndEnhancedCautions({ customCautions, enhancedCautions });

    const inactiveLabel = `${
        enhancedCautions.length ? '+' : ''
    }${inactiveCautionsCounter} ${inactiveDisplayValue}`;

    const showInactiveCautionsIndicator = showInactiveIndicator && entityId && inactiveCautionsCounter > 0;

    return (
        <StyledCautionGroup size={size} className={className}>
            {sortedCautions.map((enhancedCaution, index) => (
                <EnhancedCaution key={index} showTooltip={showTooltips} {...enhancedCaution} />
            ))}
            {showInactiveCautionsIndicator && (
                <StyledLink
                    to={`/profiles/${entityTypeToEntityProfileType[entityType]}/${entityId}/cautions`}
                    hoverOnUnderline
                    fontWeight="400"
                    testId={testIds.ENHANCED_CAUTIONS_INACTIVE_INDICATOR}
                >
                    {inactiveLabel}
                </StyledLink>
            )}
            {renderAddButton}
        </StyledCautionGroup>
    );
};

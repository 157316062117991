import { createSelector } from 'reselect';

const SET_EVIDENCE_DEPARTMENT_CONFIG = 'evidence-department-config/SET_EVIDENCE_DEPARTMENT_CONFIG';

// ACTIONS
export function setEvidenceDepartmentConfig(evidenceDepartmentConfig = {}) {
    return {
        type: SET_EVIDENCE_DEPARTMENT_CONFIG,
        payload: evidenceDepartmentConfig,
    };
}

// SELECTORS
/**
 * @type {object}
 */
export const evidenceDepartmentConfigSelector = (state) => state.data.evidenceDepartmentConfig;

/**
 * Whether vehicles in police custody can become evidence in this tenant.
 * @type {boolean}
 */
export const vehiclesEnabledSelector = createSelector(
    evidenceDepartmentConfigSelector,
    ({ vehiclesEnabled }) => vehiclesEnabled
);

// REDUCER
/**
 * EvidenceDepartmentConfig contains evidence settings for the current tenant.
 *   Only one model will ever be loaded.
 */
export default function evidenceDepartmentConfigReducer(state = {}, action) {
    switch (action.type) {
        case SET_EVIDENCE_DEPARTMENT_CONFIG:
            return action.payload;
        default:
            return state;
    }
}

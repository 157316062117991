import { EntityTypeEnum } from '@mark43/rms-api';
import { size } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withPropsOnChange } from 'recompose';
import { createSelector, createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { staffRemarksByEntitySelector } from '~/client-common/core/domain/staff-remarks/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { RemarksAccordion } from '../../../../core/components/RemarksAccordion';
import _StaffRemarks from '../../../../evidence/core/components/StaffRemarks';
import { renderOnlyIf } from '../../../../../legacy-redux/helpers/reactHelpers';

const { ITEM_PROFILE } = EntityTypeEnum;
const staffRemarks =
    componentStrings.reports.custodialPropertySummary.custodialPropertyCardItem
        .CustodialPropertyCardItemStaffRemarks;

const StaffRemarks = styled(_StaffRemarks)`
    margin-top: 8px;
`;

const CustodialPropertyCardItemStaffRemarks = ({ collapsed, itemProfileId, staffRemarksCount }) => (
    <RemarksAccordion header={staffRemarks.title(staffRemarksCount)} isCollapsed={collapsed}>
        <StaffRemarks entityId={itemProfileId} entityType={ITEM_PROFILE.name} deletable={true} />
    </RemarksAccordion>
);

const mapStateToProps = createStructuredSelector({
    staffRemarksByEntity: staffRemarksByEntitySelector,
});

export default compose(
    connect(mapStateToProps),
    withPropsOnChange(['staffRemarksByEntity'], ({ staffRemarksByEntity, itemProfileId }) => ({
        staffRemarksCount: size(staffRemarksByEntity(ITEM_PROFILE.name, itemProfileId)),
        staffRemarksSelector: createSelector(staffRemarksByEntitySelector, (staffRemarksByEntity) =>
            staffRemarksByEntity(ITEM_PROFILE.name, itemProfileId)
        ),
    })),
    renderOnlyIf(({ staffRemarksCount }) => staffRemarksCount)
)(CustodialPropertyCardItemStaffRemarks);

import { get, map } from 'lodash';

import {
    UsageSourceModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    EntityTypeEnum,
    RmsHistoryEvent,
    RmsHistoryEventView,
} from '@mark43/rms-api';
import { NEXUS_STATE_PROP as HISTORY_EVENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/history-events/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { reportShortTitleViewModelByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { withEntityItems } from '~/client-common/core/utils/nexusHelpers';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import { augmentHistoryEvents } from '~/client-common/core/domain/history-events/utils/historyEventHelpers';
import getEvidenceReportResource from '~/client-common/core/domain/evidence/resources/evidenceReportResource';
import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import { currentReportSelector } from '../../../../../legacy-redux/selectors/reportSelectors';
import { currentUserHasAbilitySelector } from '../../../../core/current-user/state/ui';
import { createUsageLog } from '../../../../admin/usage-logs/state/data';
import { custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector } from '../../../../core/report-definitions/state/ui';
import { RmsAction } from '../../../../../core/typings/redux';

export const LOAD_REPORT_HISTORY_START = 'report-history/LOAD_REPORT_HISTORY_START';
export const LOAD_REPORT_HISTORY_SUCCESS = 'report-history/LOAD_REPORT_HISTORY_SUCCESS';
export const LOAD_REPORT_HISTORY_FAILURE = 'report-history/LOAD_REPORT_HISTORY_FAILURE';

function loadReportHistoryStart() {
    return {
        type: LOAD_REPORT_HISTORY_START,
    } as const;
}

function loadReportHistorySuccess(historyEvents: RmsHistoryEvent[]) {
    return {
        type: LOAD_REPORT_HISTORY_SUCCESS,
        payload: { historyEvents },
    } as const;
}

function loadReportHistoryFailure(errorMessage: string) {
    return {
        type: LOAD_REPORT_HISTORY_FAILURE,
        payload: errorMessage,
    } as const;
}

function determineRmsHistoryEventView(
    obj: RmsHistoryEventView | RmsHistoryEvent[]
): obj is RmsHistoryEventView {
    return (obj as RmsHistoryEventView).historyEvents !== undefined;
}

export function loadReportHistory(
    reportId: number
): RmsAction<Promise<RmsHistoryEventView | RmsHistoryEvent[]>> {
    return (dispatch, getState) => {
        const state = getState();
        // check user has ability to view report history
        const currentUserHasAbility = currentUserHasAbilitySelector(state)(
            abilitiesEnum.REPORTING.VIEW_HISTORY
        );
        const custodialPropertySummaryReportDefinition = custodialPropertySummaryReportDefinitionForCurrentDepartmentSelector(
            getState()
        );
        dispatch(loadReportHistoryStart());
        // get report short title for usage logs
        const reportShortTitleViewModel = reportShortTitleViewModelByReportIdSelector(state)(
            reportId
        );
        const { shortTitleWithRen } = getViewModelProperties(reportShortTitleViewModel);
        const currentReport = currentReportSelector(state);
        const isCPSReport =
            currentReport?.reportDefinitionId ===
            get(custodialPropertySummaryReportDefinition, 'id');
        const resourceMethod = isCPSReport
            ? getEvidenceReportResource().getEvidenceReportHistory
            : getReportResource().getReportHistory;
        return resourceMethod(reportId)
            .then((payload: RmsHistoryEventView | RmsHistoryEvent[]) => {
                const action = withEntityItems(
                    {
                        [HISTORY_EVENTS_NEXUS_STATE_PROP]: augmentHistoryEvents(
                            determineRmsHistoryEventView(payload) ? payload.historyEvents : payload,
                            reportId
                        ),
                        ...(determineRmsHistoryEventView(payload)
                            ? {
                                  [ATTRIBUTES_NEXUS_STATE_PROP]: map(
                                      payload.attributes,
                                      convertAttributeToAttributeView
                                  ),
                              }
                            : {}),
                    },

                    determineRmsHistoryEventView(payload)
                        ? loadReportHistorySuccess(payload.historyEvents)
                        : loadReportHistorySuccess(payload)
                );
                dispatch(action);
                dispatch(
                    createUsageLog({
                        primaryEntityType: EntityTypeEnum.REPORT.name,
                        action: UsageActionEnum.VIEWED_REPORT_HISTORY.name,
                        primaryEntityTitle: shortTitleWithRen,
                        primaryEntityId: reportId,
                        primaryEntityDepartmentId: get(currentReport, 'departmentId'),
                        completion: UsageCompletionEnum.SUCCEEDED.name,
                        sourceModule: UsageSourceModuleEnum.REPORTS.name,
                    })
                );
                return payload;
            })
            .catch((err: Error) => {
                dispatch(loadReportHistoryFailure(err.message));
                dispatch(
                    createUsageLog({
                        primaryEntityType: EntityTypeEnum.REPORT.name,
                        action: UsageActionEnum.VIEWED_REPORT_HISTORY.name,
                        primaryEntityTitle: shortTitleWithRen,
                        primaryEntityId: reportId,
                        completion: currentUserHasAbility
                            ? UsageCompletionEnum.SERVER_ERROR.name
                            : UsageCompletionEnum.INSUFFICIENT_PERMISSIONS.name,
                        sourceModule: UsageSourceModuleEnum.REPORTS.name,
                    })
                );
                throw err;
            });
    };
}

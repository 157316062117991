import { createSelector } from 'reselect';

import {
    MARK_ALL_USER_NOTIFICATIONS_START,
    MARK_ALL_USER_NOTIFICATIONS_SUCCESS,
    MARK_ALL_USER_NOTIFICATIONS_FAILURE,
    POLL_FOR_USER_NOTIFICATIONS_SUCCESS,
} from '~/client-common/core/domain/user-notifications/state/data';
import { SET_PREVIOUS_POLL_DATE } from '~/client-common/core/domain/user-notifications/state/persistent';

const SET_NOTIFICATION_NAVIGATION_LINK_POSITION =
    'notification-popover/SET_NOTIFICATION_NAVIGATION_LINK_POSITION';

// Actions
export function setNotificationNavigationLinkPosition(position) {
    return {
        type: SET_NOTIFICATION_NAVIGATION_LINK_POSITION,
        payload: position,
    };
}

// Selectors
const notificationsNavigationLinkUiSelector = (state) => state.ui.notifications.navigationLink;

/**
 * Storing the Notification Navigation Link left position in state because we
 * want the Notification Popover to be lined up with the Navigation Link.
 * @type {number}
 */
export const navigationLinkLeftPositionSelector = createSelector(
    notificationsNavigationLinkUiSelector,
    ({ leftPosition }) => leftPosition
);

/**
 * @returns {number}
 */
export const notificationsUnreadCountSelector = createSelector(
    notificationsNavigationLinkUiSelector,
    ({ unreadCount }) => unreadCount
);

/**
 * The last time polling for notifications succeeded.
 * @returns {string}
 */
export const previousPollDateUtcSelector = createSelector(
    notificationsNavigationLinkUiSelector,
    ({ previousPollDateUtc }) => previousPollDateUtc
);

/**
 * Whether an attempt to mark notifications as read is currently being made.
 * @returns {boolean}
 */
export const markingUserNotificationsSelector = createSelector(
    notificationsNavigationLinkUiSelector,
    ({ markingUserNotifications }) => markingUserNotifications
);

// Reducer
export default function notificationsNavigationLinkUiReducer(
    state = {
        leftPosition: null,
        unreadCount: 0,
        previousUnreadCount: 0,
        previousPollDateUtc: null,
        markingUserNotifications: false,
    },
    action
) {
    switch (action.type) {
        case SET_NOTIFICATION_NAVIGATION_LINK_POSITION:
            return {
                ...state,
                leftPosition: action.payload,
            };
        case POLL_FOR_USER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                unreadCount:
                    typeof action.payload.unreadCount === 'number'
                        ? action.payload.unreadCount
                        : state.unreadCount,
                totalCount:
                    typeof action.payload.totalCount === 'number'
                        ? action.payload.totalCount
                        : state.totalCount,
            };
        case SET_PREVIOUS_POLL_DATE:
            return {
                ...state,
                previousPollDateUtc: action.payload,
            };
        // before the API request to mark notifications as read even starts,
        // update the unread count in ui state to be 0 to indicate an immediate
        // change to the user; if the request ends up failing, bump the unread
        // count back up
        case MARK_ALL_USER_NOTIFICATIONS_START:
            return {
                ...state,
                unreadCount: 0,
                previousUnreadCount: state.unreadCount,
                markingUserNotifications: true,
            };
        case MARK_ALL_USER_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                unreadCount: 0,
                previousUnreadCount: 0,
                markingUserNotifications: false,
            };
        case MARK_ALL_USER_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                unreadCount: state.previousUnreadCount,
                markingUserNotifications: false,
            };
        default:
            return state;
    }
}

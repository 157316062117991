import { ReportNotification } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getResource from '../../resources/reportNotificationsResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'reportNotifications';

const reportNotificationsModule = createNormalizedModule<ReportNotification>({
    type: NEXUS_STATE_PROP,
});

const SET_REPORT_NOTIFICATIONS_START = 'report-notifications/SET_REPORT_NOTIFICATIONS_START';
const SET_REPORT_NOTIFICATIONS_SUCCESS = 'report-notifications/SET_REPORT_NOTIFICATIONS_SUCCESS';
const SET_REPORT_NOTIFICATIONS_FAILURE = 'report-notifications/SET_REPORT_NOTIFICATIONS_FAILURE';

function setReportNotificationsStart(reportNotifications: ReportNotification[]) {
    return {
        type: SET_REPORT_NOTIFICATIONS_START,
        payload: reportNotifications,
    };
}

function setReportNotificationsSuccess(reportNotifications: ReportNotification[]) {
    return {
        type: SET_REPORT_NOTIFICATIONS_SUCCESS,
        payload: reportNotifications,
    };
}

function setReportNotificationsFailure(message: string) {
    return {
        type: SET_REPORT_NOTIFICATIONS_FAILURE,
        payload: message,
    };
}

export function setReportNotifications(
    reportId: number,
    reportNotifications: ReportNotification[]
): ClientCommonAction<Promise<ReportNotification[]>> {
    return (dispatch) => {
        const resource = getResource();
        dispatch(setReportNotificationsStart(reportNotifications));
        return resource
            .setReportNotifications(reportId, reportNotifications)
            .then((reportNotifications: ReportNotification[]) => {
                dispatch(replaceReportNotificationsWhere({ reportId }, reportNotifications));
                dispatch(setReportNotificationsSuccess(reportNotifications));
                return reportNotifications;
            })
            .catch((err: Error) => {
                dispatch(setReportNotificationsFailure(err.message));
                throw err;
            });
    };
}

// ACTIONS

const replaceReportNotificationsWhere =
    reportNotificationsModule.actionCreators.replaceEntitiesWhere;

// SELECTORS
export const reportNotificationsSelector = reportNotificationsModule.selectors.entitiesSelector;
export const reportNotificationsWhereSelector =
    reportNotificationsModule.selectors.entitiesWhereSelector;

// REDUCER
export default reportNotificationsModule.reducerConfig;

import React, { useContext, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { Icon, Tile as _Pill, Text as _Text } from 'arc';
import { EntityTypeEnumType } from '@mark43/rms-api';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import {
    getRelationshipSuggestions,
    RelationshipSuggestion,
} from '~/client-common/helpers/relationshipHelpers';
import { convertNameNameLinkTypeToOptions } from '~/client-common/helpers/linkTypesHelpers';
import { RelationshipsPrefillContext } from '../../../../core/context/RelationshipsPrefillContext';
import {
    formatLinkTypeByIdSelector,
    linkTypeByLinkTypeIdSelector,
} from '../../../../../legacy-redux/selectors/linkTypesSelectors';
import { prefillRelationshipForm } from '../../state/forms/relationshipsForm';

const Pill = styled(_Pill)`
    margin-bottom: 12px;
    border-radius: 4px;
    padding: 10px 5px;
    width: 220px;
`;

const PillDescription = styled(_Text)`
    padding: 2px 0;
`;

const PillTitle = styled(_Text)`
    padding: 2px 0;
`;

const NameRelationshipSuggestionPillInfo = ({
    suggestion,
    otherNameEntityType,
    relationshipLinkTypesPath,
    isInverted,
    nameEntityType,
    linkOptionIds,
    relationshipIndex,
}: {
    suggestion: RelationshipSuggestion;
    relationshipLinkTypesPath: string;
    isInverted: boolean;
    nameEntityType: EntityTypeEnumType;
    otherNameEntityType: EntityTypeEnumType;
    linkOptionIds: number[];
    relationshipIndex: number;
}) => {
    const [showPill, setShowPill] = useState(false);
    const dispatch = useDispatch();
    const formatLinkTypeById = useSelector(formatLinkTypeByIdSelector);
    const linkTypeByLinkTypeId = useSelector(linkTypeByLinkTypeIdSelector);

    const { dateEffectiveFrom, dateEffectiveTo, linkTypeId, useLinkNameTwo } = suggestion;
    const linkType = linkTypeByLinkTypeId(linkTypeId);

    useEffect(() => {
        const isLinkTypeExist = find(linkOptionIds, (id) => id === linkTypeId);
        setShowPill(!isLinkTypeExist);
    }, [linkOptionIds, linkTypeId]);

    const onClickHandler = useCallback(() => {
        const option = convertNameNameLinkTypeToOptions(
            linkType,
            isInverted ? otherNameEntityType : nameEntityType,
            isInverted
        );
        let displayLinkOptionIds;
        if (!option[1]) {
            displayLinkOptionIds = option[0].value;
        } else if (isInverted) {
            displayLinkOptionIds = useLinkNameTwo ? option[0].value : option[1].value;
        } else {
            displayLinkOptionIds = useLinkNameTwo ? option[1].value : option[0].value;
        }
        dispatch(
            prefillRelationshipForm(
                {
                    effectiveFrom: dateEffectiveFrom,
                    effectiveTo: dateEffectiveTo,
                    displayLinkOptionIds,
                    linkOptionIds: linkTypeId,
                },
                relationshipLinkTypesPath,
                relationshipIndex
            )
        );
        setShowPill(false);
    }, [
        linkType,
        nameEntityType,
        isInverted,
        dispatch,
        relationshipLinkTypesPath,
        dateEffectiveFrom,
        dateEffectiveTo,
        linkTypeId,
        useLinkNameTwo,
        otherNameEntityType,
        relationshipIndex,
    ]);

    if (!showPill || !linkTypeId) {
        return null;
    }

    return (
        <Pill
            title={
                <PillTitle color="brand.default" fontSize="sm" fontWeight="semibold">
                    {formatLinkTypeById(linkTypeId, useLinkNameTwo)}
                </PillTitle>
            }
            media={<Icon color="brand.default" icon="Add" size="md" />}
            description={
                <PillDescription color="text.secondary" fontSize="xs">
                    {joinTruthyValues([dateEffectiveFrom, dateEffectiveTo], ' - ')}
                </PillDescription>
            }
            onClick={onClickHandler}
        />
    );
};

export const NameRelationshipSuggestionPills = ({
    nameFromId,
    nameToId,
    linkOptionIds,
    relationshipLinkTypesPath,
    isInverted,
    nameEntityType,
    otherNameEntityType,
    relationshipIndex,
}: {
    nameFromId: number;
    nameToId: number;
    linkOptionIds: number[];
    relationshipLinkTypesPath: string;
    isInverted: boolean;
    nameEntityType: EntityTypeEnumType;
    otherNameEntityType: EntityTypeEnumType;
    relationshipIndex: number;
}) => {
    const { relationships } = useContext(RelationshipsPrefillContext);

    if (!relationships || isEmpty(relationships)) {
        return null;
    }

    const suggestions = getRelationshipSuggestions(relationships, nameFromId, nameToId);

    return (
        <>
            {suggestions.map((suggestion, index) => {
                return (
                    <NameRelationshipSuggestionPillInfo
                        suggestion={suggestion}
                        key={index}
                        relationshipLinkTypesPath={relationshipLinkTypesPath}
                        isInverted={isInverted}
                        nameEntityType={nameEntityType}
                        otherNameEntityType={otherNameEntityType}
                        linkOptionIds={linkOptionIds}
                        relationshipIndex={relationshipIndex}
                    />
                );
            })}
        </>
    );
};

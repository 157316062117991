import getReportResource from '~/client-common/core/domain/reports/resources/reportResource';
import { storeReports } from '../../../../../legacy-redux/actions/reportsActions';

const LOAD_REPORTS_IN_REPORTING_EVENT_START =
    'reports-submission/LOAD_REPORTS_IN_REPORTING_EVENT_START';
const LOAD_REPORTS_IN_REPORTING_EVENT_SUCCESS =
    'reports-submission/LOAD_REPORTS_IN_REPORTING_EVENT_SUCCESS';
const LOAD_REPORTS_IN_REPORTING_EVENT_FAILURE =
    'reports-submission/LOAD_REPORTS_IN_REPORTING_EVENT_FAILURE';

function loadReportsInReportingEventStart() {
    return {
        type: LOAD_REPORTS_IN_REPORTING_EVENT_START,
    };
}
function loadReportsInReportingEventSuccess() {
    return {
        type: LOAD_REPORTS_IN_REPORTING_EVENT_SUCCESS,
    };
}
function loadReportsInReportingEventFailure(errorMessage) {
    return {
        type: LOAD_REPORTS_IN_REPORTING_EVENT_FAILURE,
        payload: errorMessage,
    };
}

/**
 * TODO: Move this into core/domain when reports state is moved there.
 * @param  {number | undefined} reportingEventId
 * @return {RmsAction<Promise<Report[]>>}
 */
export function loadReportsInReportingEvent(reportingEventId) {
    return function (dispatch) {
        dispatch(loadReportsInReportingEventStart());
        return getReportResource()
            .getReportsInReportingEvent(reportingEventId)
            .then((reports) => {
                dispatch(loadReportsInReportingEventSuccess());
                dispatch(storeReports(reports));
                return reports;
            })
            .catch((err) => {
                dispatch(loadReportsInReportingEventFailure(err.message));
                throw err;
            });
    };
}

import React from 'react';
import { RefContextEnum, AttributeTypeEnum } from '@mark43/rms-api';
import { useSelector } from 'react-redux';

import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { statusIsClosedSelector } from '~/client-common/core/domain/case-statuses/state/ui';
import { reportShortTitleByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import type { ArrayElement } from '~/client-common/types';

import { OnlyWithAbility } from '../../abilities';
import { useFormGetter } from '../../forms/hooks/useFormGetter';
import { currentUserHasAbilitySelector } from '../../current-user/state/ui';

import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTDatePicker } from '../../forms/components/DatePicker';
import Fieldset from '../../forms/components/Fieldset';
import { MFTNItems } from '../../forms/components/NItems';
import FormRow from '../../forms/components/FormRow';
import type { ReportCaseStatusesFormDataShape } from '../../../reports/core/state/forms/reportCaseStatusesForm';

const strings = componentStrings.core.OffenseCaseStatusSummary;

// Context can be one of two right now
type AcceptedContexts =
    | typeof RefContextEnum.FORM_CREATE_MANAGE_CASE.name
    | typeof RefContextEnum.FORM_REPORT_CASE_STATUSES_SIDE_PANEL.name;

export const OffenseCaseStatusFieldset: React.FC<{
    context: AcceptedContexts;
    className?: string;
    title?: string;
}> = ({ context, className }) => {
    const { getForm } = useFormGetter();

    const form = getForm(context);
    const currentUserHasAbility = useSelector(currentUserHasAbilitySelector);
    const statusIsClosed = useSelector(statusIsClosedSelector);
    const userHasEditOffenseCaseStatusAbility = currentUserHasAbility(
        abilitiesEnum.REPORTING.EDIT_CASE_STATUS
    );
    const reportShortTitleByReportId = useSelector(reportShortTitleByReportIdSelector);

    return (
        <OnlyWithAbility has={abilitiesEnum.REPORTING.VIEW_CASE_STATUS}>
            <MFTNItems<ArrayElement<ReportCaseStatusesFormDataShape['offenseCaseStatuses']>>
                className={className}
                renderAddButton={undefined}
                renderRemoveButton={undefined}
                path="offenseCaseStatuses"
                render={({ item, index }) => {
                    const reportShortTitle = reportShortTitleByReportId(item.reportId) || {
                        reportingEventNumber: '',
                    };
                    const itemTitle = strings.itemTitle({
                        ren: reportShortTitle.reportingEventNumber,
                        offenseIncidentTitle: item.title,
                    });
                    return (
                        <Fieldset>
                            <div className="report-title">{itemTitle}</div>
                            <ArbiterMFTAttributeSelect
                                fieldName={fields.OFFENSE_CASE_STATUS_CASE_STATUS_ATTR_ID}
                                attributeType={AttributeTypeEnum.OFFENSE_CASE_STATUS.name}
                                disabled={!userHasEditOffenseCaseStatusAbility}
                                length="lg"
                                path="caseStatusAttrId"
                                onChange={(caseStatusAttrId: number) => {
                                    if (
                                        form &&
                                        !item.statusDateUtc &&
                                        statusIsClosed(caseStatusAttrId)
                                    ) {
                                        form.set(
                                            `offenseCaseStatuses[${index}].statusDateUtc`,
                                            nowUtc()
                                        );
                                    }
                                }}
                            />
                            <FormRow>
                                <ArbiterMFTAttributeSelect
                                    fieldName={
                                        fields.OFFENSE_CASE_STATUS_CLOSED_BY_DIVISION_ATTR_ID
                                    }
                                    attributeType={AttributeTypeEnum.DIVISION.name}
                                    disabled={!userHasEditOffenseCaseStatusAbility}
                                    length="md"
                                    path="closedByDivisionAttrId"
                                />
                                <ArbiterMFTAttributeSelect
                                    fieldName={fields.OFFENSE_CASE_STATUS_CLOSED_BY_UNIT_ATTR_ID}
                                    attributeType={AttributeTypeEnum.PERSONNEL_UNIT.name}
                                    disabled={!userHasEditOffenseCaseStatusAbility}
                                    length="md"
                                    path="closedByUnitAttrId"
                                />
                            </FormRow>
                            <ArbiterMFTDatePicker
                                fieldName={fields.OFFENSE_CASE_STATUS_STATUS_DATE_UTC}
                                disabled={!userHasEditOffenseCaseStatusAbility}
                                includeTime={true}
                                path="statusDateUtc"
                            />
                        </Fieldset>
                    );
                }}
            />
        </OnlyWithAbility>
    );
};

import { AttributeTypeEnum } from '@mark43/rms-api';

import React from 'react';

import FormRow from '../../forms/components/FormRow';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTText } from '../../forms/components/Text';

export function PersonProfileFormResidencyFields() {
    return (
        <>
            <FormRow>
                <ArbiterMFTText path="placeOfBirth" length="md" />
                <ArbiterMFTAttributeSelect
                    path="birthStateAttrId"
                    attributeType={AttributeTypeEnum.STATE.name}
                    length="md"
                />
            </FormRow>
            <ArbiterMFTAttributeSelect
                path="citizenshipAttrId"
                attributeType={AttributeTypeEnum.CITIZENSHIP.name}
                length="lg"
            />
        </>
    );
}

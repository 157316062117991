import { DispositionEventTypeEnum } from '@mark43/evidence-api';
import { chain } from 'lodash';

/**
 * For a given disposition event type enum value, return a display string. Some
 *   types are never displayed to the user, and the empty string is returned for
 *   them.
 * @param  {string} dispositionEventType
 * @return {string}
 */
export function formatDispositionEventType(dispositionEventType) {
    switch (dispositionEventType) {
        case DispositionEventTypeEnum.PRIMARY_RELEASE_APPROVED.name:
            return 'Submitted';
        case DispositionEventTypeEnum.PRIMARY_DISPOSITION_APPROVED.name:
            return 'Submitted';
        case DispositionEventTypeEnum.SECONDARY_REJECT.name:
            return 'Item Rejected';
        case DispositionEventTypeEnum.RELEASE_APPROVED.name:
            return 'Approved for Release to Owner';
        case DispositionEventTypeEnum.DISPOSITION_APPROVED.name:
            return 'Approved for Disposition';
        case DispositionEventTypeEnum.REJECTED_HOLD.name:
            return 'Held';
        case DispositionEventTypeEnum.RELEASE_EXPIRED.name:
            return 'Release Timer Expired';
        default:
            return '';
    }
}

/**
 * Merges disposition state into disposition event based on their correspond disposition event id
 * @param dispositionEvents
 * @param dispositionState
 * @returns {*}
 */
export const mergeDispositionEventAndState = (dispositionEvents, dispositionState) =>
    chain(dispositionEvents)
        .map((dispositionEvent) => ({
            ...dispositionEvent,
            dispositionState: chain(dispositionState)
                .find(({ dispositionEventId }) => dispositionEventId === dispositionEvent.id)
                .pick([
                    'dispositionApprovalLevel',
                    'dispositionStatus',
                    'expirationDateUtc',
                    'retentionPolicyId',
                ])
                .value(),
        }))
        .compact()
        .value();

import factory from './actionTypesFactory';

export default factory('box', [
    'OPEN_BOX',
    'CLOSE_BOX',
    'SAVE_BOX_START',
    'SAVE_BOX_HALT', // for stopping saving gif
    'SAVE_BOX_FAILURE',
    'SAVE_BOX_SUCCESS',
    'STORE_BOX_ERROR_MESSAGES',
    'LOAD_BOX_START',
    'STORE_PAYLOAD',
]);

import _ from 'lodash';
import { getAdminListStatusFromStartEnd } from '~/client-common/core/dates/utils/dateHelpers';
import { setFormValues, clearFormValues } from '../helpers/formReducerHelpers';
import {
    attributesAdminFormFields,
    attributesAdminCodeLinkFormFields,
} from '../configs/adminConfig';
import actionTypes from '../actions/types/attributesAdminActionTypes';

const initialDataState = {
    attributeTypes: {},
    attributes: {},
    codeMappings: [],
    linkTypes: [],
};

const initialUiState = {
    attributes: {}, // for storing computed properties not in the data state
    filterQuery: null,
    pageLoadError: false,
    attributeLoadError: null,
    saving: false,
    savingError: null,
    histories: {},
    attributeTypeIsLoading: false,
    showExpiredAttributes: false,
};

export function attributesAdminDataReducer(state = initialDataState, action) {
    let attribute;
    let attributeType;
    let codeMappings;
    let linkTypes;

    switch (action.type) {
        case actionTypes.PAGE_LOAD_SUCCESS:
            return {
                ...state,
                attributeTypes: action.payload.attributeTypes,
                attributes: action.payload.attributes,
            };
        case actionTypes.SELECT_ATTRIBUTE_TYPE_SUCCESS:
        case actionTypes.SELECT_ATTRIBUTE_TYPE_OPTION_SUCCESS:
            attributeType = action.payload.attributeType;
            // code mappings are requested and stored by attribute type
            codeMappings = action.payload.codeMappings;

            return {
                ...state,
                codeMappings: {
                    ...state.codeMappings,
                    [attributeType]: codeMappings,
                },
            };
        case actionTypes.SAVE_ATTRIBUTE_TYPE_LINK_TYPES:
            attributeType = action.payload.attributeType;
            linkTypes = action.payload.linkTypes;

            return {
                ...state,
                linkTypes: {
                    ...state.linkTypes,
                    [attributeType]: linkTypes,
                },
            };
        case actionTypes.SAVE_ATTRIBUTE_SUCCESS:
            attribute = action.payload.attribute;
            const updatedCodeLinks = action.payload.codeLinks;

            attributeType = attribute.attributeType;

            // all code links for the attribute's type, to be mutated
            const codeLinks = _.clone(state.codeMappings[attributeType].links);

            // merge each updated code link into the array of all code links
            _.forEach(updatedCodeLinks, (updatedCodeLink) => {
                const index = _.findIndex(
                    codeLinks,
                    (codeLink) =>
                        codeLink.codeTypeId === updatedCodeLink.codeTypeId &&
                        codeLink.attributeId === updatedCodeLink.attributeId
                );

                if (index !== -1) {
                    // existing code link has been updated
                    codeLinks[index] = updatedCodeLink;
                } else {
                    // new code link
                    codeLinks.push(updatedCodeLink);
                }
            });

            return {
                ...state,
                attributes: {
                    ...state.attributes,
                    [attribute.id]: attribute,
                },
                codeMappings: {
                    ...state.codeMappings,
                    [attributeType]: {
                        ...state.codeMappings[attributeType],
                        links: codeLinks,
                    },
                },
            };
        default:
            return state;
    }
}

export function attributesAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case actionTypes.PAGE_LOAD_START:
            return {
                ...state,
                attributeTypeIsLoading: true,
            };
        case actionTypes.PAGE_LOAD_SUCCESS:
            // we need to set the status of each attribute, not tracked on the
            // server as it's just a computed field. Could do this in the selector
            // but it's actually pretty expensive, so better to cache it
            const now = new Date();
            const attributes = _.mapValues(action.payload.attributes, (attribute) => ({
                id: attribute.id,
                status: getAdminListStatusFromStartEnd(
                    attribute.startDateUtc,
                    attribute.endDateUtc,
                    now
                ),
            }));
            return {
                ...state,
                attributeTypeIsLoading: false,
                attributes,
            };
        case actionTypes.PAGE_LOAD_ERROR:
            return {
                ...state,
                attributeTypeIsLoading: false,
                pageLoadError: true,
            };
        case actionTypes.SELECT_ATTRIBUTE_TYPE_START:
            return {
                ...state,
                savingError: null,
            };
        case actionTypes.SELECT_ATTRIBUTE_START:
            return {
                ...state,
                attributeLoadError: null,
                savingError: null,
            };
        case actionTypes.SELECT_ATTRIBUTE_FAILURE:
            return {
                ...state,
                attributeLoadError: action.payload.message,
            };
        case actionTypes.OPEN_NEW_ATTRIBUTE_FORM:
            return {
                ...state,
                savingError: null,
            };
        case actionTypes.SAVE_ATTRIBUTE_START:
            return {
                ...state,
                saving: true,
                savingError: null,
            };
        case actionTypes.SAVE_ATTRIBUTE_SUCCESS:
            // update the attribute's status
            const attribute = action.payload.attribute;

            return {
                ...state,
                attributes: {
                    ...state.attributes,
                    [attribute.id]: {
                        ...attribute,
                        status: getAdminListStatusFromStartEnd(
                            attribute.startDateUtc,
                            attribute.endDateUtc
                        ),
                    },
                },
                saving: false,
            };
        case actionTypes.SAVE_ATTRIBUTE_FAILURE:
            return {
                ...state,
                saving: false,
                savingError: action.payload,
            };
        case actionTypes.FETCH_ATTRIBUTE_TYPE_HISTORY_SUCCESS:
            return {
                ...state,
                histories: {
                    ...state.histories,
                    [action.payload.attributeType]: action.payload.histories,
                },
            };
        case actionTypes.TOGGLE_EXPIRED_ATTRIBUTES:
            return {
                ...state,
                showExpiredAttributes: !state.showExpiredAttributes,
            };
        default:
            return state;
    }
}

export function attributesAdminFormReducer(state, action) {
    switch (action.type) {
        case actionTypes.SELECT_ATTRIBUTE_SUCCESS:
            const attribute = action.payload.attribute;
            const linkType = action.payload.linkType;

            if (attribute) {
                return setFormValues(
                    state,
                    {
                        ...attribute,
                        linkType,
                    },
                    attributesAdminFormFields
                );
            }

            return clearFormValues(state);
        case actionTypes.OPEN_NEW_ATTRIBUTE_FORM:
            return clearFormValues(state);
        default:
            return state;
    }
}

export function attributesAdminCodeLinkFormReducer(state, action) {
    // the state is form data and can be cleared by returning an empty object
    switch (action.type) {
        case actionTypes.SELECT_ATTRIBUTE_SUCCESS:
        case actionTypes.SELECT_ATTRIBUTE_TYPE_OPTION_SUCCESS:
            if (action.payload.attribute || action.payload.attributeType) {
                return _(action.payload.codeLinks)
                    .mapKeys((codeLink) => codeLink.codeTypeId.toString())
                    .mapValues((codeLink) =>
                        setFormValues(
                            state[codeLink.codeTypeId.toString()],
                            codeLink,
                            attributesAdminCodeLinkFormFields
                        )
                    )
                    .value();
            }

            return {};
        case actionTypes.SELECT_ATTRIBUTE_FAILURE:
        case actionTypes.OPEN_NEW_ATTRIBUTE_FORM:
            return {};
        default:
            return state;
    }
}

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

import { requiredString } from '../../../../../legacy-redux/validation/helpers/reactReduxFormFieldValidators';

const fieldViewModels = buildFlatFormFieldViewModels([
    {
        key: 'ownerId',
        validators: {
            requiredError: requiredString,
        },
    },
]);

const reportChangeOwnerForm = createFormModule({
    formName: formClientEnum.REPORT_CHANGE_OWNER,
    fieldViewModels,
});

/**
 * Module of the change owner report form
 * @type {Object}
 */
export default reportChangeOwnerForm;

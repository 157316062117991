import React, { FC } from 'react';
import { Box, Text, cssVar, Icon } from 'arc';
import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router';

import { elasticCasesViewModelForElasticCasesSelector } from '~/client-common/core/domain/elastic-cases/state/data';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';
import { formatElasticAttributes } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

import { getCaseTitle } from '../../../../records/core/helpers/caseStringFormatters';

const StyledReactRouterLink = styled(ReactRouterLink)`
    display: flex;
    gap: ${cssVar('arc.space.half')};
    align-items: center;

    &:hover {
        text-decoration: underline;
    }
`;

type EntityProfileActiveTargetItemProps = {
    caseItem: ReturnType<ReturnType<typeof elasticCasesViewModelForElasticCasesSelector>>[0];
};

export const EntityProfileActiveTargetItem: FC<EntityProfileActiveTargetItemProps> = ({
    caseItem,
}) => {
    const { caseNumber, title, targetProfileCategoryAttrDetails, id } = caseItem;

    const { singularCaseFieldName: caseFieldName } = useCaseFieldName();

    const linkText = getCaseTitle({
        localId: caseNumber,
        caseDefinition: title,
        caseFieldName,
    });
    const infoText = formatElasticAttributes(targetProfileCategoryAttrDetails);

    return (
        <Box display="flex" gap="6px" alignItems="center">
            <StyledReactRouterLink to={`/cases/${id}/summary`} target="_blank">
                <Text
                    color="inherit"
                    fontSize={cssVar('arc.fontSizes.sm')}
                    lineHeight={cssVar('arc.lineHeights.normal')}
                    fontWeight={cssVar('arc.fontWeights.bold') as 'bold'}
                >
                    {linkText}
                </Text>
                <Icon icon="OpenNewWindow" />
            </StyledReactRouterLink>
            <Text
                fontSize={cssVar('arc.fontSizes.sm')}
                lineHeight={cssVar('arc.lineHeights.normal')}
                isItalic
            >
                {infoText}
            </Text>
        </Box>
    );
};

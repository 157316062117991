import $ from 'jquery';
import keyCodeEnum from '~/client-common/core/enums/client/keyCodeEnum';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { openLightbox, closeLightbox } from './lightboxActions';
import {
    setImages,
    nextImage,
    previousImage,
    setCurrentIndex,
    setEmailsEnabled,
} from './imageGalleryActions';

const context = {
    name: boxEnum.IMAGE_GALLERY_LIGHTBOX,
};

/**
 * Dispatches actions to set images and current index in the imageGallery and
 * attaches keydown events for navigating left and right and opens the
 * lightbox
 * @param  {Object[]} images  Array of images to display
 * @param  {Number} index   Number to set as current index
 * @param  {Object} [usageLogParamsForAttachments] - params for usageLog - (title, source)
 * @param  {Boolean} emailsEnabled - enable direct emails
 */
export function openImageGalleryLightbox(
    images,
    index = 0,
    usageLogParamsForAttachments,
    emailsEnabled = false
) {
    return (dispatch) => {
        $(document).on('keydown.imageGalleryLightbox', (e) => {
            if (e.keyCode === keyCodeEnum.RIGHT_ARROW) {
                e.preventDefault();
                dispatch(nextImage());
            } else if (e.keyCode === keyCodeEnum.LEFT_ARROW) {
                e.preventDefault();
                dispatch(previousImage());
            }
        });
        const boxPayload = { usageLogParamsForAttachments };
        dispatch(setImages(images));
        dispatch(setCurrentIndex(index));
        dispatch(openLightbox(context, boxPayload));
        dispatch(setEmailsEnabled(emailsEnabled));
    };
}

/**
 * Dispatches actions to reset images and current index in the imageGallery and
 * detaches keydown events for navigating left and right and closes the
 * lightbox
 */
export function closeImageGalleryLightbox() {
    return (dispatch) => {
        $(document).off('keydown.imageGalleryLightbox');
        dispatch(closeLightbox(context));
        dispatch(setCurrentIndex(0));
        dispatch(setImages([]));
        dispatch(setEmailsEnabled(false));
    };
}

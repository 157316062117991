import React, { createContext, useState, useEffect, useCallback } from 'react';
import { NameNameLink } from '@mark43/rms-api';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import personProfileResource from '~/client-common/core/domain/person-profiles/resources/personProfileResource';
import { RelationshipSuggestion } from '~/client-common/helpers/relationshipHelpers';

type RelationshipsPrefillContextProps = {
    relationships?: Partial<NameNameLink>[];
    setRelationships?: React.Dispatch<React.SetStateAction<Partial<NameNameLink>[]>>;
    relationshipSuggestions?: RelationshipSuggestion[];
    setRelationshipSuggestions?: React.Dispatch<React.SetStateAction<RelationshipSuggestion[]>>;
};

export const RelationshipsPrefillContext = createContext<RelationshipsPrefillContextProps>({});

export const RelationshipsPrefillContextProvider = ({
    children,
    personProfileIds,
}: {
    children: React.ReactNode;
    personProfileIds: number[];
}) => {
    const [relationships, setRelationships] = useState<Partial<NameNameLink>[]>([]);
    const [relationshipSuggestions, setRelationshipSuggestions] = useState<
        RelationshipSuggestion[]
    >([]);

    const { callResource } = useResourceDeferred(
        useCallback((ids: number[]) => personProfileResource().getRelationshipData(ids), []),
        useCallback((response: Partial<NameNameLink>[]) => {
            setRelationships(response);
        }, [])
    );

    useEffect(() => {
        callResource(personProfileIds);
    }, [personProfileIds, callResource]);

    return (
        <RelationshipsPrefillContext.Provider
            value={{
                relationships,
                setRelationships,
                relationshipSuggestions,
                setRelationshipSuggestions,
            }}
        >
            {children}
        </RelationshipsPrefillContext.Provider>
    );
};

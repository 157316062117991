import { AttributeTypeEnum, ReportAttribute } from '@mark43/rms-api';
import { includes } from 'lodash';
import { createSelector } from 'reselect';
import { reportLegacyMetadataByReportIdSelector } from '../../../report-legacy-metadatas/state/data';
import { agencyProfileByIdSelector } from '../../../agency-profiles/state/data';
import { reportAttributesWhereSelector } from '../../../report-attributes/state/data';
import { reportByIdSelector } from '../data';

const REPORT_LEGACY_METADATA_CARD_REPORT_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.LEGACY_CLASSIFICATION_CODE.name,
    AttributeTypeEnum.LEGACY_MIGRATION_STATISTIC.name,
];

const reportAttributesFilter = (reportId: number) => {
    return (reportAttribute: ReportAttribute) =>
        reportAttribute.reportId === reportId &&
        includes(REPORT_LEGACY_METADATA_CARD_REPORT_ATTRIBUTE_TYPES, reportAttribute.attributeType);
};

export const hydratedReportLegacyMetadataByReportIdSelector = createSelector(
    reportByIdSelector,
    reportAttributesWhereSelector,
    reportLegacyMetadataByReportIdSelector,
    agencyProfileByIdSelector,
    (reportById, reportAttributesWhere, reportLegacyMetadataByReportId, agencyProfileById) => (
        reportId: number
    ) => {
        const report = reportById(reportId);
        const reportAttributes = reportAttributesWhere(reportAttributesFilter(reportId));
        const reportLegacyMetadatas = reportLegacyMetadataByReportId(reportId);
        const agencyProfile = agencyProfileById(report?.agencyId);

        return {
            report,
            reportAttributes,
            reportLegacyMetadatas,
            agencyProfile,
        };
    }
);

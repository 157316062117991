import React from 'react';
import _, { map, zip } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { useSubPremiseFormatters } from '../../../../modules/core/locations/hooks/useSubPremiseFormatters';

function getLocationsToDisplay(locations, limit = 2) {
    return _(locations)
        .groupBy('type')
        .values()
        .thru((locations) => zip(...locations))
        .flatten()
        .take(limit)
        .value();
}

function LocationsCell({ moreLocationsCount, locationsToDisplay }) {
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    return (
        <div className="elastic-locations-cell">
            {map(locationsToDisplay, (elasticLocation, index) => {
                const { type, subdivisionAttrIds, categoryAttrId, propertyTypeAttrId } =
                    getViewModelProperties(elasticLocation);
                const subdivisionsLine = subdivisionAttrIds ? (
                    <div className="subdivisions-line">
                        <span className="subdivisions-info">{subdivisionAttrIds}</span>
                    </div>
                ) : undefined;
                return (
                    <div className="elastic-location" key={index}>
                        <div className="elastic-location-type">{type}:</div>
                        <div className="elastic-location-info">
                            {map(buildElasticLocationLines(elasticLocation), (line) => (
                                <div key={line}>{line}</div>
                            ))}
                            {subdivisionsLine}
                            {categoryAttrId && (
                                <div className="category-line">{categoryAttrId}</div>
                            )}
                            {propertyTypeAttrId && (
                                <div className="property-type-line">{propertyTypeAttrId}</div>
                            )}
                        </div>
                    </div>
                );
            })}
            {moreLocationsCount > 0 && (
                <div className="elastic-row-show-more">{`+ ${moreLocationsCount} more`}</div>
            )}
        </div>
    );
}

export default function LocationsCellWrapper({ involvedLocations }) {
    const locationsToDisplay = getLocationsToDisplay(involvedLocations);
    const moreLocationsCount = involvedLocations.length - 2;

    if (involvedLocations.length === 0) {
        return <div className="elastic-locations-cell">No Location Data</div>;
    }

    return (
        <LocationsCell
            locationsToDisplay={locationsToDisplay}
            moreLocationsCount={moreLocationsCount}
        />
    );
}

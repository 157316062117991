import _, { set, map, flatten, parseInt } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import {
    storeCaseDefinitions,
    NEXUS_STATE_PROP as CASE_DEFINITIONS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/case-definitions/state/data';
import {
    storeCaseDefaultRoleLinks,
    replaceCaseDefaultRoleLinksWhere,
    NEXUS_STATE_PROP as CASE_DEFAULT_ROLE_LINKS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/case-default-role-links/state/data';
import {
    replaceCaseDefaultTasksWhere,
    storeCaseDefaultTasks,
    NEXUS_STATE_PROP as CASE_DEFAULT_TASKS_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/case-default-tasks/state/data';
import { NEXUS_STATE_PROP as CASE_PRINTING_TEMPLATES_NEXUS_STATE_PROP } from '~/client-common/core/domain/case-printing-templates/state/data';
import {
    storeDefaultFolders,
    replaceDefaultFoldersWhere,
} from '~/client-common/core/domain/default-folders/state/data';
import { convertToDefaultFolderView } from '~/client-common/core/domain/default-folders/utils/defaultFoldersHelpers';

import caseDefinitionResource from '../../resources/caseDefinitionResource';

export const LOAD_CASE_DEFINITIONS_START = 'case-definitions/LOAD_CASE_DEFINITIONS_START';
export const LOAD_CASE_DEFINITIONS_SUCCESS = 'case-definitions/LOAD_CASE_DEFINITIONS_SUCCESS';
export const LOAD_CASE_DEFINITIONS_FAILURE = 'case-definitions/LOAD_CASE_DEFINITIONS_FAILURE';
export const REMOVE_CASE_DEFINITION_START = 'REMOVE_CASE_DEFINITION_START';
export const REMOVE_CASE_DEFINITION_SUCCESS = 'REMOVE_CASE_DEFINITION_SUCCESS';
export const REMOVE_CASE_DEFINITION_FAILURE = 'REMOVE_CASE_DEFINITION_FAILURE';
export const SAVE_CASE_DEFINITION_START = 'case-definitions/SAVE_CASE_DEFINITION_START';
export const SAVE_CASE_DEFINITION_SUCCESS = 'case-definitions/SAVE_CASE_DEFINITION_SUCCESS';
export const SAVE_CASE_DEFINITION_FAILURE = 'case-definitions/SAVE_CASE_DEFINITION_FAILURE';

function loadCaseDefinitionsStart() {
    return {
        type: LOAD_CASE_DEFINITIONS_START,
    };
}

function loadCaseDefinitionsSuccess() {
    return {
        type: LOAD_CASE_DEFINITIONS_SUCCESS,
    };
}

function loadCaseDefinitionsFailure(errMsg) {
    return {
        type: LOAD_CASE_DEFINITIONS_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function loadCaseDefinitions() {
    return function (dispatch) {
        dispatch(loadCaseDefinitionsStart());

        return caseDefinitionResource
            .getCaseDefinitions()
            .then((definitions) => {
                dispatch(
                    storeCaseDefaultRoleLinks(flatten(map(definitions, 'caseDefaultRoleLinks')))
                );
                dispatch(storeCaseDefaultTasks(flatten(map(definitions, 'defaultTasks'))));
                dispatch(storeCaseDefinitions(map(definitions, 'caseDefinition')));
                const defaultFolders = _(definitions)
                    .map(({ defaultFolders }) => defaultFolders?.folderViews)
                    .flatten()
                    .map((folderViews) => {
                        if (!folderViews) {
                            return;
                        }
                        return {
                            ...folderViews,
                            id: folderViews.folder.id,
                        };
                    })
                    .value();

                dispatch(storeDefaultFolders(defaultFolders));
                dispatch(loadCaseDefinitionsSuccess());
            })
            .catch((err) => dispatch(loadCaseDefinitionsFailure(err.message)));
    };
}

function saveCaseDefinitionStart() {
    return {
        type: SAVE_CASE_DEFINITION_START,
    };
}

function removeCaseDefinitionStart() {
    return {
        type: REMOVE_CASE_DEFINITION_START,
    };
}

function removeCaseDefinitionSuccess() {
    return {
        type: REMOVE_CASE_DEFINITION_SUCCESS,
    };
}

function removeCaseDefinitionFailure() {
    return {
        type: REMOVE_CASE_DEFINITION_FAILURE,
    };
}

function saveCaseDefinitionSuccess(caseDefinition) {
    return {
        type: SAVE_CASE_DEFINITION_SUCCESS,
        payload: caseDefinition,
    };
}

function saveCaseDefinitionFailure(errMsg) {
    return {
        type: SAVE_CASE_DEFINITION_FAILURE,
        error: true,
        payload: errMsg,
    };
}

export function saveCaseDefinition({
    caseDefinition,
    caseDefaultRoleLinks,
    defaultTaskList,
    defaultAttachmentFolders,
    defaultNotesFolders,
}) {
    return function (dispatch) {
        dispatch(saveCaseDefinitionStart());

        const isNewDefinition = !caseDefinition.id;
        const method = isNewDefinition ? 'createCaseDefinition' : 'updateCaseDefinition';

        return caseDefinitionResource[method](caseDefinition)
            .then((definition) => {
                dispatch(storeCaseDefinitions([definition]));
                const setRoleLinks = caseDefinitionResource.setCaseDefaultRoleLinks(
                    definition.id,
                    map(caseDefaultRoleLinks, (link) =>
                        set(link, 'caseDefinitionId', definition.id)
                    )
                );
                const upsertTasks = caseDefinitionResource.upsertTasks(
                    definition.id,
                    defaultTaskList
                );

                const upsertDefaultFolders = caseDefinitionResource
                    .replaceDefaultFolders(EntityTypeEnum.CASE_DEFINITION.name, definition.id, {
                        folderViews: [
                            ...convertToDefaultFolderView({
                                formModel: defaultAttachmentFolders,
                                entityTypeId: EntityTypeEnum.ATTACHMENT.name,
                                ownerTypeId: EntityTypeEnum.CASE_DEFINITION.name,
                                ownerId: definition.id,
                            }),
                            ...convertToDefaultFolderView({
                                formModel: defaultNotesFolders,
                                entityTypeId: EntityTypeEnum.CASE_NOTE.name,
                                ownerTypeId: EntityTypeEnum.CASE_DEFINITION.name,
                                ownerId: definition.id,
                            }),
                        ],
                    })
                    .then(({ folderViews }) => {
                        return map(folderViews, (folderView) => {
                            if (!folderView) {
                                return;
                            }
                            return {
                                ...folderView,
                                id: folderView.folder.id,
                            };
                        });
                    });

                return [definition, Promise.all([setRoleLinks, upsertTasks, upsertDefaultFolders])];
            })
            .spread((definition, [newCaseDefaultRoleLinks, newTasks, newDefaultFolders]) => {
                const replaceForDefinition = ({ caseDefinitionId }) =>
                    caseDefinitionId === definition.id;
                const replaceForOwnerId = ({ ownerId }) => ownerId === definition.id;
                const replaceForDefaultFolder = ({ folder }) => folder.ownerId === definition.id;
                dispatch(
                    replaceCaseDefaultRoleLinksWhere(replaceForDefinition, newCaseDefaultRoleLinks)
                );
                dispatch(replaceCaseDefaultTasksWhere(replaceForOwnerId, newTasks));
                dispatch(replaceDefaultFoldersWhere(replaceForDefaultFolder, newDefaultFolders));
                return dispatch(saveCaseDefinitionSuccess(definition));
            })
            .catch((err) => dispatch(saveCaseDefinitionFailure(err.message)));
    };
}

export function removeCaseDefinition(givenCaseDefinitionId) {
    return function (dispatch, getState, { nexus }) {
        dispatch(removeCaseDefinitionStart());
        const id = parseInt(givenCaseDefinitionId);
        return caseDefinitionResource['deleteCaseDefinition'](id)
            .then(() =>
                dispatch(
                    nexus.withRemoveMultiple(
                        {
                            [CASE_DEFINITIONS_NEXUS_STATE_PROP]: [{ id }],
                            [CASE_DEFAULT_ROLE_LINKS_NEXUS_STATE_PROP]: [{ caseDefinitionId: id }],
                            [CASE_DEFAULT_TASKS_NEXUS_STATE_PROP]: [{ caseDefinitionId: id }],
                            [CASE_PRINTING_TEMPLATES_NEXUS_STATE_PROP]: [{ caseDefinitionId: id }],
                        },
                        removeCaseDefinitionSuccess()
                    )
                )
            )
            .catch((err) => dispatch(removeCaseDefinitionFailure(err.message)));
    };
}

import { findIndex, first, filter } from 'lodash';
import { EntityTypeEnum } from '@mark43/rms-api';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import {
    attachmentsWhereSelector,
    removeAttachment,
} from '~/client-common/core/domain/attachments/state/data';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';

import { updateOrphanContents } from '../../../../cases/core/state/data/orphanedContents';
import { uploadedFilesSelector, updateUploads } from './attachmentsSidePanel';

const ATTACHMENT_SIDEPANEL_FORM = 'ATTACHMENT_SIDEPANEL_FORM';

function removeFormFileDescriptionForAttachment(formsRegistry, attachment) {
    const form = formsRegistry.get(ATTACHMENT_SIDEPANEL_FORM);
    const formFileDescriptions = form.get('fileDescriptions');
    const file = getAttachmentFile(attachment);
    const idx = findIndex(formFileDescriptions, { id: file.id });
    form.remove('fileDescriptions', idx);
}

export function openDeleteAttachmentConfirmationModal(attachmentId, entityType) {
    return (dispatch, getState, { overlayStore }) => {
        overlayStore.open(overlayIdEnum.DELETE_ATTACHMENTS_CONFIRMATION_MODAL, {
            attachmentId,
            entityType,
        });
    };
}

export function saveDeleteAttachmentConfirmationModal() {
    return function (dispatch, getState, { formsRegistry, overlayStore }) {
        const applicationSettings = applicationSettingsSelector(getState());
        const {
            customProperties: { attachmentId, entityType },
        } = overlayStore.getStateForId(overlayIdEnum.DELETE_ATTACHMENTS_CONFIRMATION_MODAL);
        const attachment = first(attachmentsWhereSelector(getState())({ id: attachmentId }));

        return dispatch(removeAttachment(attachment))
            .then(() => {
                removeFormFileDescriptionForAttachment(formsRegistry, attachment);

                const newUploadingFiles = filter(
                    uploadedFilesSelector(getState()),
                    ({ file, image, video }) => {
                        const specificFileTypeId = image?.id || video?.id || file?.id;

                        return specificFileTypeId !== attachment.attachmentId;
                    }
                );
                dispatch(updateUploads(newUploadingFiles));

                // remove from the ui.attachments
                // TODO: CHI-1221 + Only dispatching this for Case Note Entity
                if (
                    applicationSettings.RMS_CASE_FOLDERING_ENABLED &&
                    entityType === EntityTypeEnum.CASE.name
                ) {
                    dispatch(updateOrphanContents());
                }

                overlayStore.close(overlayIdEnum.DELETE_ATTACHMENTS_CONFIRMATION_MODAL);
            })
            .catch((err) => {
                overlayStore.setError(
                    overlayIdEnum.DELETE_ATTACHMENTS_CONFIRMATION_MODAL,
                    err.message
                );
            });
    };
}

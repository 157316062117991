import React from 'react';
import styled from 'styled-components';

import { omitProps } from '~/client-common/helpers/reactHelpers';
import Link from '../../../core/components/links/Link';
import testIds from '../../../../core/testIds';

const Wrapper = styled.div`
    font-size: var(--arc-fontSizes-md);
    position: relative;
    border-bottom: ${({ isOnly, isLast, theme }) =>
        isOnly || isLast ? 'none' : `1px solid ${theme.colors.lightGrey}`};
    background: ${({ theme, canRead }) => (!canRead ? theme.colors.extraLightGrey : 'inherit')};
`;

const StyledLink = styled(omitProps(['canRead'])(Link))`
    position: relative;
    display: block;

    &:hover {
        background: ${({ theme, canRead }) =>
            canRead ? theme.colors.lightBlue : theme.colors.extraLightGrey};
    }
`;

const QuickSearchResultSectionItemLink = ({
    isOnly,
    isLast,
    canRead,
    children,
    forcePreventLink,
    ...rest
}) => (
    <Wrapper isOnly={isOnly} isLast={isLast} canRead={canRead}>
        {canRead && !forcePreventLink && (
            <StyledLink canRead={canRead} {...rest} testId={testIds.QUICK_SEARCH_RESULT_LINK}>
                {children}
            </StyledLink>
        )}
        {(!canRead || forcePreventLink) && children}
    </Wrapper>
);

export default QuickSearchResultSectionItemLink;

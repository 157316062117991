import { chain, isEmpty, filter, reduce, flatMap } from 'lodash';

import {
    EntityTypeEnum,
    PersonProfile,
    LinkTypesEnum,
    NameNameLink,
    NameReportLink,
    OrganizationProfile,
    LinkTypesEnumType,
    EntityTypeEnumType,
} from '@mark43/rms-api';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { useFormGetter } from '../forms/hooks/useFormGetter';
import { relationshipsDataSelector } from '../../reports/core/state/ui/relationships';

import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { currentReportIdSelector } from '../../../legacy-redux/selectors/reportSelectors';

import { FieldDescriptorType } from './helpers/types';

type RelationshipDataType = {
    nameNameLinks: NameNameLink[];
    nameReportLinks: NameReportLink[];
    personProfiles: PersonProfile[];
    organizationProfiles: OrganizationProfile[];
};

type RelationshipLinkTypeT = {
    displayLinkOptionIds: string;
    linkOptionIds: number;
};

type RelationshipType = {
    otherNameId: number;
    otherNameEntityType: EntityTypeEnumType;
    linkOptionIds: LinkTypesEnumType[];
    nameId: number;
    nameEntityType: EntityTypeEnumType;
    displayLinkOptionIds: string[];
    relationshipLinkTypes?: RelationshipLinkTypeT[];
};

const isValidRelationship = (
    {
        displayLinkOptionIds,
        nameId,
        otherNameId,
        nameEntityType,
        otherNameEntityType,
        relationshipLinkTypes,
    }: RelationshipType,
    vulnerablePersonId: number,
    useRelationshipLinkTypes: boolean
): boolean => {
    const displayLinkOptionIdsNItems = flatMap(
        relationshipLinkTypes,
        (item) => item.displayLinkOptionIds
    );

    const links = useRelationshipLinkTypes ? displayLinkOptionIdsNItems : displayLinkOptionIds;

    const allLinkOptionIds = chain(links).compact().uniq().value();

    const validDisplayLinkOption = chain(allLinkOptionIds)
        .find((displayLink) =>
            isValidRelationshipInCorrectOrder(displayLink, nameId, otherNameId, vulnerablePersonId)
        )
        .value();

    return (
        !!validDisplayLinkOption &&
        nameEntityType === EntityTypeEnum.PERSON_PROFILE.name &&
        otherNameEntityType === EntityTypeEnum.PERSON_PROFILE.name
    );
};

/**
 * A valid relationship in the context of this RFG is considered to be any of the following relationships:
        1. Guardian Of
        2. Parent Of
        3. Step Parent Of
        4. Foster Parent Of
        5. Grandparent Of
        6. Caretaker Of
 */
const isValidRelationshipInCorrectOrder = (
    displayLink: string,
    nameId: number,
    otherNameId: number,
    vulnerablePersonId: number
): boolean => {
    if (!displayLink) {
        return false;
    }

    const displayLinkType = displayLink.split('-')[0];
    if (
        displayLinkType === LinkTypesEnum.GUARDIAN_OF.toString() ||
        displayLinkType === LinkTypesEnum.STEP_PARENT_OF.toString() ||
        displayLinkType === LinkTypesEnum.CARETAKER_OF.toString()
    ) {
        /**
         * If link option ends with "One", then otherNameId is under guardianship/taken care of by/stepchild of nameId
         * If link option ends with "Two", then nameId is under guardianship/taken care of by/stepchild of otherNameId
         * Vulnerable person must be under guardianship/the profile being taken care of/stepchild
         */

        return displayLink.endsWith('One')
            ? otherNameId === vulnerablePersonId
            : nameId === vulnerablePersonId;
    } else if (
        displayLinkType === LinkTypesEnum.CHILD_OF.toString() ||
        displayLinkType === LinkTypesEnum.FOSTER_CHILD_OF.toString() ||
        displayLinkType === LinkTypesEnum.GRANDCHILD_OF.toString()
    ) {
        /**
         * If link option ends with "One", then nameId is the child of/foster child of/grandchild of otherNameId
         * If link option ends with "Two", then otherNameId is the child of/foster child of/grandchild of nameId
         * Vulnerable person must be the child/foster child/grandchild
         */

        return displayLink.endsWith('One')
            ? nameId === vulnerablePersonId
            : otherNameId === vulnerablePersonId;
    }

    return false;
};

export const getVulnerablePersonProfileRequiresParentOrGuardianRelationship = (
    getState: () => RootState
) => (_fieldDescriptor: FieldDescriptorType, args: object, { context }: { context: string }) => {
    const state = getState();
    const { getForm } = useFormGetter();

    const currentReportId = currentReportIdSelector(state);
    if (!currentReportId) {
        return true;
    }

    const relationshipData: RelationshipDataType = relationshipsDataSelector(state);
    const isRelationshipPreFillEnabled = Boolean(
        applicationSettingsSelector(state).RMS_RELATIONSHIP_PREFILL_ENABLED
    );

    const { personProfiles = [] } = relationshipData;

    // Relationship card is empty
    if (isEmpty(personProfiles)) {
        return true;
    }

    const vulnerablePersonProfiles = chain(personProfiles)
        .filter((item) => item.isVulnerable ?? false)
        .value();
    // There are no unborn person profiles on the report.
    // No need to check any further.
    if (isEmpty(vulnerablePersonProfiles)) {
        return true;
    }

    const relationshipsForm = getForm(context);

    // Form is not in the forms registry. No need to check any further
    if (!relationshipsForm) {
        return true;
    }

    const relationships = relationshipsForm.getState().model.relationships as RelationshipType[];

    const invalidPersonProfileIds: number[] = [];

    const result = reduce(
        vulnerablePersonProfiles,
        (acc, { id }) => {
            const vulnerablePersonRelationships = filter(
                relationships,
                (relationship) => relationship.nameId === id || relationship.otherNameId === id
            );

            const hasValidRelationship = chain(vulnerablePersonRelationships)
                .filter((item) => item.nameId === id || item.otherNameId === id)
                .some((item) => isValidRelationship(item, id, isRelationshipPreFillEnabled))
                .value();

            if (!hasValidRelationship) {
                acc = [...acc, id];
            }
            return acc;
        },
        invalidPersonProfileIds
    );

    return isEmpty(result);
};

import { EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { head, map } from 'lodash';

import { createFormConfiguration, _Form } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';

import { towVehicleReleaseByReportIdSelector } from '~/client-common/core/domain/tow-vehicle-releases/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

const TOW_VEHICLE_RELEASE_TO_PATH = 'towVehicleReleaseTo';
const TOW_VEHICLE_RELEASE_AUTHORIZED_BY_PATH = 'towVehicleReleaseAuthorizedBy';

export const formName = RefContextEnum.FORM_TOW_VEHICLE_RELEASE.name;
export const RELEASE_BY_PATH = 'releaseBy';
export const getTowVehicleReleaseForm = () => formsRegistry.get(formName);

export const createTowVehicleReleaseForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            releaseDateUtc: {
                fieldName: fields.TOW_VEHICLE_RELEASE_RELEASE_DATE_UTC,
            },
            releaseTypeAttrId: {
                fieldName: fields.TOW_VEHICLE_RELEASE_RELEASE_TYPE_ATTR_ID,
            },
            releaseTypeOther: {
                fieldName: fields.TOW_VEHICLE_RELEASE_RELEASE_TYPE_OTHER,
            },
            [RELEASE_BY_PATH]: {
                fieldName: fields.TOW_VEHICLE_RELEASE_RELEASE_BY,
            },
            additionalNotes: {
                fieldName: fields.TOW_VEHICLE_RELEASE_ADDITIONAL_NOTES,
            },
            [TOW_VEHICLE_RELEASE_TO_PATH]: {
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_TOW_VEHICLE_RELEASE_TO_LINK_TYPE,
            },
            [TOW_VEHICLE_RELEASE_AUTHORIZED_BY_PATH]: {
                fieldName:
                    fields.NAME_REPORT_LINK_LINK_TYPE_TOW_VEHICLE_RELEASE_AUTHORIZED_BY_LINK_TYPE,
            },
        }),
    });
};

const buildTowVehicleReleaseNameReportLinks = ({ reportId, nameReportLinksWhere }) => {
    const towVehicleReleaseToNameReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.TOW_VEHICLE_RELEASE_TO,
        contextType: EntityTypeEnum.REPORT.name,
        contextId: reportId,
    });
    const towVehicleReleaseAuthorizedByNameReportLinks = nameReportLinksWhere({
        reportId,
        linkType: LinkTypesEnum.TOW_VEHICLE_RELEASE_AUTHORIZED_BY,
        contextType: EntityTypeEnum.REPORT.name,
        contextId: reportId,
    });
    return {
        towVehicleReleaseToNameReportLinks,
        towVehicleReleaseAuthorizedByNameReportLinks,
    };
};

export const buildTowVehicleReleaseCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const towVehicleReleaseByReportId = towVehicleReleaseByReportIdSelector(state);
    const nameReportLinksWhere = nameReportLinksWhereSelector(state);

    const {
        id: towVehicleReleaseId,
        releaseDateUtc,
        releaseTypeAttrId,
        releaseTypeOther,
        releaseBy,
        additionalNotes,
    } = towVehicleReleaseByReportId(reportId);
    const {
        towVehicleReleaseToNameReportLinks,
        towVehicleReleaseAuthorizedByNameReportLinks,
    } = buildTowVehicleReleaseNameReportLinks({ reportId, nameReportLinksWhere });
    const towVehicleReleaseToLinkType = head(map(towVehicleReleaseToNameReportLinks, 'linkType'));
    const towVehicleReleaseAuthorizedByLinkType = head(
        map(towVehicleReleaseAuthorizedByNameReportLinks, 'linkType')
    );

    return {
        id: towVehicleReleaseId,
        releaseDateUtc,
        releaseTypeAttrId,
        releaseTypeOther,
        [RELEASE_BY_PATH]: releaseBy,
        additionalNotes,
        [TOW_VEHICLE_RELEASE_TO_PATH]: towVehicleReleaseToLinkType,
        [TOW_VEHICLE_RELEASE_AUTHORIZED_BY_PATH]: towVehicleReleaseAuthorizedByLinkType,
    };
};

export const buildTowVehicleReleaseCardBundle = ({ reportId, form }) => {
    const { model } = form.getState();
    const {
        id: towVehicleReleaseId,
        releaseDateUtc,
        releaseTypeAttrId,
        releaseTypeOther,
        [RELEASE_BY_PATH]: releaseBy,
        additionalNotes,
    } = model;

    const towVehicleReleaseForBundle = {
        id: towVehicleReleaseId,
        reportId,
        releaseDateUtc,
        releaseTypeAttrId,
        releaseTypeOther,
        releaseBy,
        additionalNotes,
    };

    return {
        towVehicleRelease: towVehicleReleaseForBundle,
    };
};

export const refreshTowVehicleReleaseForm = ({ reportId }) => (dispatch) => {
    const form = getTowVehicleReleaseForm();
    const formModel = dispatch(buildTowVehicleReleaseCardFormModel({ reportId }));
    form.set('', formModel);
};

export const refreshTowVehicleReleaseFormNameReportLinks = ({ reportId }) => (dispatch) => {
    const form = getTowVehicleReleaseForm();
    const {
        [TOW_VEHICLE_RELEASE_TO_PATH]: towVehicleReleaseToLinkType,
        [TOW_VEHICLE_RELEASE_AUTHORIZED_BY_PATH]: towVehicleReleaseAuthorizedByLinkType,
    } = dispatch(buildTowVehicleReleaseCardFormModel({ reportId }));

    form.transaction(() => {
        form.set(TOW_VEHICLE_RELEASE_TO_PATH, towVehicleReleaseToLinkType);
        form.set(TOW_VEHICLE_RELEASE_AUTHORIZED_BY_PATH, towVehicleReleaseAuthorizedByLinkType);
    });
};

export const prefillReleaseBy = ({ releaseBy }) => {
    const form = getTowVehicleReleaseForm();
    form.set(RELEASE_BY_PATH, releaseBy);
};

export function formSetReleaseTo(value) {
    const form = getTowVehicleReleaseForm();
    form.set(TOW_VEHICLE_RELEASE_TO_PATH, value);
}

export function formSetAuthorizedBy(value) {
    const form = getTowVehicleReleaseForm();
    form.set(TOW_VEHICLE_RELEASE_AUTHORIZED_BY_PATH, value);
}

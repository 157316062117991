import React from 'react';
import styled from 'styled-components';
import { at, keys, max } from 'lodash';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
// configs
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { searchSizes } from '~/client-common/configs/advancedSearchConfig';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import {
    WARRANT_SUBJECT_PERSON_PROFILE_ID,
    WARRANT_WARRANT_ISSUED_DATE_UTC,
} from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import Pagination from '../../../../legacy-redux/components/core/Pagination';

import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';

import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';

import ScrollableUnderSubheader from '../../../core/components/ScrollableUnderSubheader';
import zIndexes from '../../../core/styles/zIndexes';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import { warrantsDashboardSearch } from '../state/ui';
import WarrantChargesCell from './results/WarrantChargesCell';
import StatusCell from './results/StatusCell';
import WarrantsDashboardSearchResultsActionBar from './WarrantsDashboardSearchResultsActionBar';
import SubjectCell from './results/SubjectCell';
import WarrantCell from './results/WarrantCell';
import WarrantLocationCell from './results/WarrantLocation';
import WarrantsDashboardSearchForm from './WarrantsDashboardSearchForm';
import WarrantsDashboardSubheader from './WarrantsDashboardSubheader';

const WarrantIssuedDateUtcCell = dateCellFactory('warrantIssuedDateUtc');

const strings = componentStrings.warrants.dashboard.results;

const WarrantsDashboardSearchResultsContainer = styled.div`
    position: relative;
    background: ${(props) => props.theme.colors.white};
`;

const ResultsHeader = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    padding: var(--arc-space-3) var(--arc-space-2) var(--arc-space-1);
    background: var(--arc-colors-surface-foreground);
    z-index: ${zIndexes.stickyHeader};
    border-bottom: solid 1px var(--arc-colors-border-default);
`;

const ResultsBody = styled.div`
    padding: 0 20px 0;
`;

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    display: inline;
    margin-right: 10px;
    padding-top: 7px;
`;

const ResultsFooter = styled.div`
    background: ${(props) => props.theme.colors.white};
    padding: 50px 25px 64px 0;
    text-align: right;
`;

const WarrantsDashboardSearchResultTable = styled(Table)`
    border-top: 4px solid ${(props) => props.theme.colors.cobaltBlue};
    font-size: var(--arc-fontSizes-sm);
`;

const WarrantIssuedDateCell = styled(OptionsTableColumn)`
    margin-left: 5px;
`;

function WarrantsDashboardSearchResults({
    results,
    formatFieldByName,
    isSearchFormOpen,
    onSearchFormToggleClick,

    // elastic query
    selectedRows,
    allResultsSelected,
    query: { from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount,
    activeColumnKeys,

    // handlers
    handlePaginationClick,
    handleRowClick,
    handleSelectRows,
    handleSelectAllRows,
    handleSortTypeClick,
    handleSubjectOptionClick,
    handleClearAllSelectedRows,
    handleEntityOptionClick,
    handleSizeChange,
}) {
    const subjectSorts = {
        LAST_NAME_ASCENDING: 'Last Name Z to A',
        LAST_NAME_DESCENDING: 'Last Name A to Z',
        FIRST_NAME_ASCENDING: 'First Name Z to A',
        FIRST_NAME_DESCENDING: 'First Name A to Z',
    };

    const currentPage = max([1, fromSizeToPage(from + 1, size)]);

    function handleNameSort(nameSort) {
        if (nameSort === subjectSorts.LAST_NAME_ASCENDING) {
            handleSortTypeClick(sortTypeEnum.ALPHABETICAL_Z_TO_A, {
                value: sortKeyEnum.SUBJECT_PERSON_LAST_NAME,
            });
        } else if (nameSort === subjectSorts.LAST_NAME_DESCENDING) {
            handleSortTypeClick(sortTypeEnum.ALPHABETICAL_A_TO_Z, {
                value: sortKeyEnum.SUBJECT_PERSON_LAST_NAME,
            });
        } else if (nameSort === subjectSorts.FIRST_NAME_DESCENDING) {
            handleSortTypeClick(sortTypeEnum.ALPHABETICAL_A_TO_Z, {
                value: sortKeyEnum.SUBJECT_PERSON_FIRST_NAME,
            });
        } else if (nameSort === subjectSorts.FIRST_NAME_ASCENDING) {
            handleSortTypeClick(sortTypeEnum.ALPHABETICAL_Z_TO_A, {
                value: sortKeyEnum.SUBJECT_PERSON_FIRST_NAME,
            });
        }
        handleSubjectOptionClick(nameSort);
    }

    return (
        <>
            <ScrollableUnderSubheader>
                <WarrantsDashboardSearchResultsContainer>
                    <ResultsHeader>
                        <WarrantsDashboardSubheader
                            isSearchFormOpen={isSearchFormOpen}
                            onSearchFormToggleClick={onSearchFormToggleClick}
                        />
                        <TableResultsSummaryContainer
                            from={from}
                            to={from + keys(results).length}
                            totalResults={totalCount}
                            caption=""
                        />
                        <SearchSizeDropdownMenu sizes={searchSizes} onChange={handleSizeChange} />
                    </ResultsHeader>
                    <ResultsBody>
                        {isSearchFormOpen && <WarrantsDashboardSearchForm />}
                        <WarrantsDashboardSearchResultTable
                            data={results}
                            selectableRows={true}
                            selectedRows={selectedRows}
                            onSelectRow={handleSelectRows}
                            onRowClick={handleRowClick}
                            sortKey={currentSortKey}
                            sortType={currentSortType}
                            noRowsText={strings.noResults}
                            rowComponent={ElasticResultRow}
                            className="warrants-dashboard-search-results"
                        >
                            <TableHeader>
                                <TableColumns>
                                    <FeatureFlagged
                                        columnKey="warrantIssuedDateUtc"
                                        width={160}
                                        flag="RMS_WARRANT_SORTING_ENABLED"
                                        fallback={
                                            <TableColumn
                                                display={formatFieldByName(
                                                    WARRANT_WARRANT_ISSUED_DATE_UTC
                                                )}
                                                columnKey="warrantIssuedDateUtc"
                                                width={130}
                                            />
                                        }
                                    >
                                        <WarrantIssuedDateCell
                                            columnKey="warrantIssuedDateUtc"
                                            width={125}
                                            display={({ display }) => display}
                                            activeValue={sortKeyEnum.WARRANT_DATE_ISSUED_UTC}
                                            activeSortType={
                                                currentSortKey ===
                                                sortKeyEnum.WARRANT_DATE_ISSUED_UTC
                                                    ? currentSortType
                                                    : undefined
                                            }
                                            onSortTypeClick={handleSortTypeClick}
                                            popoutWidth={160}
                                        >
                                            <OptionsTableColumnOption
                                                display={formatFieldByName(
                                                    WARRANT_WARRANT_ISSUED_DATE_UTC
                                                )}
                                                value={sortKeyEnum.WARRANT_DATE_ISSUED_UTC}
                                                columnKey="warrantIssuedDateUtc"
                                                sortOptions={[
                                                    {
                                                        display:
                                                            strings.columns.issuedDate
                                                                .ascendingSort,
                                                        sortType:
                                                            sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                                    },
                                                    {
                                                        display:
                                                            strings.columns.issuedDate
                                                                .descendingSort,
                                                        sortType:
                                                            sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                                    },
                                                ]}
                                            />
                                        </WarrantIssuedDateCell>
                                    </FeatureFlagged>
                                    <FeatureFlagged
                                        columnKey="subject"
                                        width={290}
                                        flag="RMS_WARRANT_SORTING_ENABLED"
                                        fallback={
                                            <TableColumn
                                                width={290}
                                                columnKey="subject"
                                                display={formatFieldByName(
                                                    WARRANT_SUBJECT_PERSON_PROFILE_ID
                                                )}
                                            />
                                        }
                                    >
                                        <OptionsTableColumn
                                            columnKey="subject"
                                            width={290}
                                            display={({ display }) => display}
                                            activeValue={activeColumnKeys.subject}
                                            activeSortType={
                                                currentSortKey ===
                                                    sortKeyEnum.SUBJECT_PERSON_LAST_NAME ||
                                                currentSortKey ===
                                                    sortKeyEnum.SUBJECT_PERSON_FIRST_NAME
                                                    ? activeColumnKeys.subject
                                                    : undefined
                                            }
                                            onSortTypeClick={handleNameSort}
                                            popoutWidth={160}
                                        >
                                            <OptionsTableColumnOption
                                                display={formatFieldByName(
                                                    WARRANT_SUBJECT_PERSON_PROFILE_ID
                                                )}
                                                value={activeColumnKeys.subject}
                                                columnKey="subject"
                                                sortOptions={[
                                                    {
                                                        display:
                                                            strings.columns.subject
                                                                .lastNameDescendingSort,
                                                        sortType: subjectSorts.LAST_NAME_DESCENDING,
                                                    },
                                                    {
                                                        display:
                                                            strings.columns.subject
                                                                .lastNameAscendingSort,
                                                        sortType: subjectSorts.LAST_NAME_ASCENDING,
                                                    },
                                                    {
                                                        display:
                                                            strings.columns.subject
                                                                .firstNameDescendingSort,
                                                        sortType:
                                                            subjectSorts.FIRST_NAME_DESCENDING,
                                                    },
                                                    {
                                                        display:
                                                            strings.columns.subject
                                                                .firstNameAscendingSort,
                                                        sortType: subjectSorts.FIRST_NAME_ASCENDING,
                                                    },
                                                ]}
                                            />
                                        </OptionsTableColumn>
                                    </FeatureFlagged>

                                    <OptionsTableColumn
                                        display={({ display }) => display}
                                        columnKey={activeColumnKeys.warrant}
                                        activeValue={activeColumnKeys.warrant}
                                        onOptionClick={handleEntityOptionClick}
                                        width={195}
                                        popoutWidth={160}
                                    >
                                        <OptionsTableColumnOption
                                            display={strings.columns.warrantInformation}
                                            value="warrantInformation"
                                        />
                                        <OptionsTableColumnOption
                                            display={strings.columns.warrantLocation}
                                            value="warrantLocation"
                                        />
                                    </OptionsTableColumn>
                                    <TableColumn
                                        width={185}
                                        display={strings.columns.charges}
                                        columnKey="charges"
                                    />
                                    <FeatureFlagged
                                        columnKey="status"
                                        width={100}
                                        flag="RMS_WARRANT_SORTING_ENABLED"
                                        fallback={
                                            <TableColumn
                                                width={100}
                                                columnKey="status"
                                                display={strings.columns.status.label}
                                            />
                                        }
                                    >
                                        <OptionsTableColumn
                                            columnKey="status"
                                            width={100}
                                            display={({ display }) => display}
                                            activeValue={sortKeyEnum.WARRANT_STATUS}
                                            activeSortType={
                                                currentSortKey === sortKeyEnum.WARRANT_STATUS
                                                    ? currentSortType
                                                    : undefined
                                            }
                                            onSortTypeClick={handleSortTypeClick}
                                            popoutWidth={160}
                                        >
                                            <OptionsTableColumnOption
                                                display={strings.columns.status.label}
                                                value={sortKeyEnum.WARRANT_STATUS}
                                                columnKey="status"
                                                sortOptions={[
                                                    {
                                                        display:
                                                            strings.columns.status.descendingSort,
                                                        sortType: sortTypeEnum.ALPHABETICAL_A_TO_Z,
                                                    },
                                                    {
                                                        display:
                                                            strings.columns.status.ascendingSort,
                                                        sortType: sortTypeEnum.ALPHABETICAL_Z_TO_A,
                                                    },
                                                ]}
                                            />
                                        </OptionsTableColumn>
                                    </FeatureFlagged>
                                </TableColumns>
                            </TableHeader>

                            <TableBody>
                                <WarrantIssuedDateUtcCell columnKey="warrantIssuedDateUtc" />
                                <SubjectCell columnKey="subject" />
                                <WarrantCell columnKey="warrantInformation" />
                                <WarrantLocationCell columnKey="warrantLocation" />
                                <WarrantChargesCell columnKey="charges" />
                                <StatusCell columnKey="status" />
                            </TableBody>
                        </WarrantsDashboardSearchResultTable>
                    </ResultsBody>
                    {totalCount > 0 && (
                        <ResultsFooter>
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={size}
                                itemCount={totalCount}
                                onClick={(lastPage, nextPage) =>
                                    handlePaginationClick(nextPage, size)
                                }
                                maxEdgeItems={1}
                            />
                        </ResultsFooter>
                    )}
                </WarrantsDashboardSearchResultsContainer>
            </ScrollableUnderSubheader>

            {selectedRows.length > 0 && (
                <WarrantsDashboardSearchResultsActionBar
                    selectedRows={selectedRows}
                    selectedResults={at(results, selectedRows)}
                    allResultsSelected={
                        // allResultsSelected only true when select all
                        // action fired. therefore also check ui selectedRows
                        allResultsSelected || selectedRows.length === totalCount
                    }
                    allResultsOnPageSelected={selectedRows.length === size}
                    totalResults={totalCount}
                    handleSelectAllRows={handleSelectAllRows}
                    handleClearAllSelectedRows={handleClearAllSelectedRows}
                />
            )}
        </>
    );
}

const mapStateToProps = createStructuredSelector({
    activeColumnKeys: warrantsDashboardSearch.selectors.activeColumnKeysSelector,
    query: warrantsDashboardSearch.selectors.currentQuerySelector,
    results: warrantsDashboardSearch.selectors.currentResultsViewModelsSelector,
    totalCount: warrantsDashboardSearch.selectors.totalCountSelector,
    selectedRows: warrantsDashboardSearch.selectors.selectedRowsSelector,
    allResultsSelected: warrantsDashboardSearch.selectors.allResultsSelectedSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handlePaginationClick(nextPage, size) {
        dispatch(
            warrantsDashboardSearch.actionCreators.search(
                { from: nextPage * size - size, size },
                { cacheBust: true }
            )
        );
    },
    handleRowClick(elasticReport, rowIndex) {
        dispatch(
            warrantsDashboardSearch.actionCreators.openSearchResult(elasticReport, rowIndex, router)
        );
    },
    handleSizeChange(size) {
        dispatch(warrantsDashboardSearch.actionCreators.search({ from: 0, size }));
    },
    handleSelectRows(newSelectedRows) {
        dispatch(warrantsDashboardSearch.actionCreators.selectRows(newSelectedRows));
    },
    handleSelectAllRows() {
        dispatch(warrantsDashboardSearch.actionCreators.selectAllResults());
    },
    handleClearAllSelectedRows() {
        dispatch(warrantsDashboardSearch.actionCreators.selectRows([]));
    },
    handleEntityOptionClick({ value }) {
        dispatch(warrantsDashboardSearch.actionCreators.setActiveColumnKey('warrant', value));
    },
    handleSubjectOptionClick(value) {
        dispatch(warrantsDashboardSearch.actionCreators.setActiveColumnKey('subject', value));
    },
    handleSortTypeClick(sortType, activeOption) {
        dispatch(
            warrantsDashboardSearch.actionCreators.search({
                sortKey: activeOption.value,
                sortType,
            })
        );
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(WarrantsDashboardSearchResults);

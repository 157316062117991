import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Form,
    Observer,
    createField,
    createFormConfiguration,
    lifecycleOptions,
    formEvents,
} from 'markformythree';
import styled from 'styled-components';
import * as yup from 'yup';
import { RegionalGroupEnum } from '@mark43/rms-api';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import formsRegistry from '../../../core/formsRegistry';
import Row from '../../core/components/Row';
import { currentUserDepartmentProfileSelector } from '../../core/current-user/state/ui';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import testIds from '../../../core/testIds';
import { MFTText } from '../../core/forms/components/Text';

import {
    convertYupSuccessShapeToMFTSuccessShape,
    convertYupErrorShapeToMFTErrorShape,
} from '../../core/validation/yupMftValidation';
import { BodyMediumText } from '../../core/components/typography';

const strings = componentStrings.compliance.ComplianceExportAuthenticationForm;
const labels = strings.labels;
const validationStrings = componentStrings.validation;

const complianceExportAuthenticationFormConfiguration = createFormConfiguration({
    username: createField<string>({}),
    password: createField<string>({}),
});

export type FormComplianceExportAuthenticationFormConfiguration = typeof complianceExportAuthenticationFormConfiguration;

const validationSchema = yup.object().shape({
    username: yup.string().required(validationStrings.requiredError),
    password: yup.string().required(validationStrings.requiredError),
});

const HideShowPasswordButton = styled(Button)`
    width: 100px;
    align-self: flex-start;
    margin-top: 18px;
`;

const FormFieldsWrapper = styled.div`
    padding-top: 10px;
    display: flex;
    align-items: center;
`;

type ComplianceExportAuthenticationFormPropsT = {
    complianceExportTypeDisplayValue: string;
};

const InputWrapper = styled.div`
    height: 78px;
`;

const ComplianceExportAuthenticationForm: React.FC<ComplianceExportAuthenticationFormPropsT> = ({
    complianceExportTypeDisplayValue,
}) => {
    const [isPassword, setIsPassword] = useState(true);
    const currentUserDepartmentProfile = useSelector(currentUserDepartmentProfileSelector);
    const { isExternalSubmission = false } = currentUserDepartmentProfile
        ? RegionalGroupEnum[currentUserDepartmentProfile.nibrsRegionalGroup]
        : {};

    useEffect(() => {
        formsRegistry.maybeDeferredOperation(
            formClientEnum.COMPLIANCE_EXPORT_AUTHENTICATION_FORM,
            undefined,
            (form) => {
                form.resetModel();
                form.resetUi();
            }
        );
    }, []);

    const handleValidation = useCallback(({ formState, eventType }) => {
        const $form = formState.ui.$form;

        return validationSchema
            .validate(formState.model, { abortEarly: false })
            .then((result) => convertYupSuccessShapeToMFTSuccessShape(result, $form))
            .catch((error) => convertYupErrorShapeToMFTErrorShape(error, $form, eventType));
    }, []);

    if (!isExternalSubmission) {
        return null;
    }

    return (
        <Form
            configuration={complianceExportAuthenticationFormConfiguration}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            name={formClientEnum.COMPLIANCE_EXPORT_AUTHENTICATION_FORM}
            onValidate={handleValidation}
            validationEvents={[
                { eventType: formEvents.FORM_SUBMIT },
                { eventType: formEvents.INPUT_BLUR },
                { eventType: formEvents.INPUT_CHANGE },
            ]}
            render={() => (
                <>
                    <Row>
                        <BodyMediumText fontWeight="semibold">
                            {strings.content(complianceExportTypeDisplayValue)}
                        </BodyMediumText>
                    </Row>
                    <Row>
                        <FormFieldsWrapper>
                            <InputWrapper
                                data-test-id={
                                    testIds.COMPLIANCE_DASHBOARD_EXTERNAL_SUBMISSION_USERNAME_PROMPT
                                }
                            >
                                <MFTText width={185} path="username" label={labels.username} />
                            </InputWrapper>
                            <InputWrapper
                                data-test-id={
                                    testIds.COMPLIANCE_DASHBOARD_EXTERNAL_SUBMISSION_PASSWORD_PROMPT
                                }
                            >
                                <MFTText
                                    width={185}
                                    path="password"
                                    label={labels.password}
                                    isPassword={isPassword}
                                />
                            </InputWrapper>
                            <Observer<
                                { password?: string },
                                FormComplianceExportAuthenticationFormConfiguration
                            >
                                subscriptions={{ password: 'password' }}
                                render={({ password }) => (
                                    <HideShowPasswordButton
                                        className={buttonTypes.PRIMARY}
                                        disabled={!password}
                                        onClick={() => {
                                            setIsPassword((isPassword) => !isPassword);
                                        }}
                                    >
                                        {isPassword ? labels.showPassword : labels.hidePassword}
                                    </HideShowPasswordButton>
                                )}
                            />
                        </FormFieldsWrapper>
                    </Row>
                </>
            )}
        />
    );
};

export default ComplianceExportAuthenticationForm;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName } from 'recompose';
import { simpleControl } from 'markformythree';

import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_REPORT_TYPE_LABEL } from '~/client-common/core/enums/universal/fields';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { reportDefinitionOptionsForCurrentDepartmentSelector } from '../../../report-definitions/state/ui';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

/**
 * Select dropdown containing all report definitions (report types).
 * @param {Object} props Same props as `Select`.
 */
const ReportDefinitionSelect = compose(
    setDisplayName('ReportDefinitionSelect'),
    connect(
        createStructuredSelector({
            options: reportDefinitionOptionsForCurrentDepartmentSelector,
        })
    )
)(function ReportDefinitionSelect({ options, filterOptions, ...otherProps }) {
    const reportTypeLabel = useFields(DISPLAY_ONLY_REPORT_TYPE_LABEL)[
        DISPLAY_ONLY_REPORT_TYPE_LABEL
    ];
    return (
        <Select
            label={reportTypeLabel}
            options={filterOptions ? filterOptions(options) : options}
            {...otherProps}
        />
    );
});

export const RRFReportDefinitionSelect = connectRRFInput(ReportDefinitionSelect);
export const MFTReportDefinitionSelect = simpleControl(ReportDefinitionSelect);

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'admin/role';

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'User Roles Resource',
        methods: {
            loadUserRolesForRole(roleId) {
                return req({
                    method: 'GET',
                    url: `${BASE_PATH}/${roleId}/user`,
                });
            },
            updateUserRole(userRole) {
                return req({
                    method: 'PUT',
                    url: `${BASE_PATH}/${userRole.roleId}/user`,
                    data: userRole,
                });
            },
            deleteUserFromRole(roleId, userId) {
                return req({
                    method: 'DELETE',
                    url: `${BASE_PATH}/${roleId}/user/${userId}`,
                });
            },
        },
    });
}

/**
 * Resources for UserRoles. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

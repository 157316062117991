import type { ReportDefinitionCard } from '@mark43/rms-api';
import formClientEnum from '~/client-common/core/enums/client/formClientEnum';

import { RmsAction } from '../../../../../core/typings/redux';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';
import {
    saveReportDefinitionCardLinks,
    saveReportDefinitionCardLinksFailure,
} from '../../../../../legacy-redux/actions/customReportsAdminActions';
import { currentReportDefinitionCardsSelector } from '../../../../../legacy-redux/selectors/customReportsAdminSelectors';
import reportCardConfigurationResource from '../../../../reports/core/resources/reportCardConfigurationResource';
import { convertFromFormModel } from './reportCardLinksForm';

/**
 * Make 2 API requests to save 2 types of links between a report type and its cards:
 * `ReportDefinitionCard`s and `ReportDefinitionOrderedCardViewModel`s.
 */
export function saveReportDefinitionCardLinksAndOrder(
    reportDefinitionId: number,
    isNewReportDefinition: boolean
): RmsAction<Promise<ReportDefinitionCard[]>> {
    return function (dispatch, getState, { formsRegistry }) {
        const departmentId = currentUserDepartmentIdSelector(getState());
        const form = formsRegistry.get(formClientEnum.REPORT_CARD_LINKS);

        if (!departmentId || !form) {
            return Promise.resolve([]);
        }

        return form.submit().then(({ form }) => {
            const existingReportDefinitionCards = isNewReportDefinition
                ? []
                : currentReportDefinitionCardsSelector(getState());

            const formModel = form.getState().model;
            const result = convertFromFormModel(
                formModel,
                reportDefinitionId,
                existingReportDefinitionCards,
                departmentId
            );

            if (!result) {
                return Promise.resolve([]);
            }

            const { reportDefinitionCards, reportDefinitionOrderedCardViewModel } = result;

            return dispatch(
                saveReportDefinitionCardLinks(reportDefinitionId, reportDefinitionCards)
            ).then((savedReportDefinitionCards: ReportDefinitionCard[]) => {
                return reportCardConfigurationResource
                    .replaceReportDefinitionOrderedCards(
                        reportDefinitionId,
                        reportDefinitionOrderedCardViewModel
                    )
                    .catch((err) => {
                        dispatch(saveReportDefinitionCardLinksFailure(err.message));
                        // if the API response is a failure, we intentionally don't re-throw the error here in order to
                        // not block the subsequent promise chain from making any additional API requests, because it's
                        // acceptable for only this card ordering to fail to persist when the user is trying to save
                        // other configurations at the same time
                    })
                    .then(() => savedReportDefinitionCards);
            });
        });
    };
}

import { get, head, map, omit, pick } from 'lodash';
import { createSelector } from 'reselect';

import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import createFormModule from '../../../../core/forms/lib/createFormModule';
import {
    formDataIsEmpty,
    filterFormData,
    buildFormModel,
    buildFlatFormFieldViewModels,
} from '../../../../../legacy-redux/helpers/formHelpers';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import {
    propertyFieldsetViewModel,
    personDetailsFieldsetViewModel,
    identifiersFieldsetViewModel,
} from '../../../../../legacy-redux/configs/fieldsetsConfig';

const advancedSearchPropertyFormFieldViewModels = {
    ...buildFlatFormFieldViewModels([
        // fields in the form that are not nested
        'fuzzyMatchingEnabled',
    ]),
    property: propertyFieldsetViewModel,
    personDetails: personDetailsFieldsetViewModel,
    identifiers: identifiersFieldsetViewModel,
};

/**
 * Convert the given search query model to form model state. While the query is
 *   flat, the form model state has a nested structure.
 * @param  {Object} [elasticQuery] Search query model.
 * @return {Object} Form state.
 */
export function convertAdvancedSearchPropertyElasticQueryToFormModel(elasticQuery = {}) {
    const person = get(elasticQuery.propertyPersons, '[0].person');

    return buildFormModel(
        {
            fuzzyMatchingEnabled: elasticQuery.fuzzyMatchingEnabled,
            property: {
                ...pick(
                    {
                        ...elasticQuery.property,
                        ...head(elasticQuery.propertyStatuses), // date time range fields
                    },
                    map(propertyFieldsetViewModel.fields, 'key')
                ),
                itemIdentifiers: {
                    itemIdentifiers: elasticQuery.itemIdentifiers,
                },
            },
            personDetails: pick(person, map(personDetailsFieldsetViewModel.fields, 'key')),
            identifiers: pick(person, map(identifiersFieldsetViewModel.fields, 'key')),
        },
        advancedSearchPropertyFormFieldViewModels
    );
}

/**
 * Flatten the given form model state into a search query model which can be
 *   sent to the server for searching.
 * @param  {Object} [formModel]
 * @return {Object} Search query model.
 */
export function convertAdvancedSearchPropertyFormModelToElasticQuery(
    formModel = {},
    { ownerNameItemAttributeIds } = {}
) {
    const person = {
        ...formModel.personDetails,
        ...formModel.identifiers,
    };

    return filterFormData({
        fuzzyMatchingEnabled: formModel.fuzzyMatchingEnabled,
        property: omit(formModel.property, [
            'statusDateRangeQuery',
            'itemIdentifiers',
            'propertyStatusAttrIds',
        ]),
        itemIdentifiers: get(formModel.property, 'itemIdentifiers.itemIdentifiers'),
        propertyStatuses: [
            {
                statusDateRangeQuery: get(formModel.property, 'statusDateRangeQuery'),
                propertyStatusAttrIds: get(formModel.property, 'propertyStatusAttrIds'),
            },
        ],
        propertyPersons: [
            {
                person,
                nameItemLink: formDataIsEmpty(person)
                    ? {}
                    : {
                          nameItemAssociationAttrDetail: {
                              ids: ownerNameItemAttributeIds,
                          },
                      },
            },
        ],
    });
}

/**
 * Based on the given form model state, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   formModel
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}   boundSelectors
 * @return {Object}   Array of filter group view models.
 */
export function convertAdvancedSearchPropertyFormModelToFilterGroups(
    formModel,
    formatFieldValue,
    boundSelectors
) {
    return convertFormModelToFilterGroups(
        formModel,
        advancedSearchPropertyFormFieldViewModels,
        formatFieldValue,
        boundSelectors.formatFieldByName
    );
}

const advancedSearchPropertyForm = createFormModule({
    formName: formClientEnum.ADVANCED_SEARCH_PROPERTY,
    fieldViewModels: advancedSearchPropertyFormFieldViewModels,
    convertToFormModel: convertAdvancedSearchPropertyElasticQueryToFormModel,
    convertFromFormModel: convertAdvancedSearchPropertyFormModelToElasticQuery,
});

advancedSearchPropertyForm.selectors.formIsEmptySelector = createSelector(
    advancedSearchPropertyForm.selectors.formModelSelector,
    (formModel) => formDataIsEmpty(omit(formModel, 'fuzzyMatchingEnabled'))
);

/**
 * Module of the advanced search property form.
 * @type {Object}
 */
export default advancedSearchPropertyForm;

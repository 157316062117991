import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListItem, Popover, PopoverContent, PopoverTrigger, UnorderedList, VStack } from 'arc';
import { map, noop } from 'lodash';

// configs
import {
    BARCODE_VALUE,
    DISPLAY_EVIDENCE_LABEL_ITEM_ID,
    ITEM_PROFILE_DESCRIPTION,
    ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import useFields, { useEvidenceModuleName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import testIds from '../../../../core/testIds';

// Redux
import { evidenceDashboardSearch, submitEvidenceDashboardSearchForm } from '../state/ui';

// components
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFText } from '../../../core/forms/components/Text';
import Row from '../../../core/components/Row';
import { Button } from '../../../core/components/Button';
import LegacyButton, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import SavedSearchModal from '../../../search/saved-search/components/SavedSearchModal';
import _ToggleButton from '../../../../legacy-redux/components/core/ToggleButton';
import EvidenceDashboardAppliedFilterSummary from './EvidenceDashboardAppliedFilterSummary';
import FilterForm from './filters/EvidenceDashboardFilterForm';

const strings = componentStrings.evidence.dashboard.searchForm;

const DashboardSearchWell = styled.div`
    padding: 19px;
    margin-top: 20px;
    background-color: ${(props) => props.theme.colors.extraLightGrey};
    border-radius: 4px;
`;
const DashboardSearchWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const EvidenceDashboardFilterButtonsWrapper = styled.div`
    display: flex;
`;

const EvidenceDashboardFilterButton = styled(_ToggleButton)`
    height: 30.5px;
    margin-top: 0;
`;

const EvidenceDashboardSearchButton = styled(LegacyButton)`
    margin: 0 0 0 -13px;
    position: relative;
    z-index: 1;
    padding-left: 20px;
    padding-right: 20px;
`;

const FormVisibilityWrapper = styled.div`
    display: ${(props) => (props.visibility ? 'block' : 'none')};
`;

const SearchPopoverButton = styled(Button)`
    border: none;
    margin-left: 0;
    padding-left: 0;

    &:hover {
        background: none;
        text-decoration: underline;
    }

    &:active,
    &:focus {
        background: none;
        border: none;
        box-shadow: none;
    }
`;

const PopOverContentWrapper = styled(UnorderedList)`
    width: 350px;
`;

const QuickSearchPopoverContent = () => {
    const {
        BARCODE_VALUE: barcodeIdDisplay,
        DISPLAY_EVIDENCE_LABEL_ITEM_ID: itemIdDisplay,
        ITEM_PROFILE_DESCRIPTION: itemDesciptionDisplay,
        ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER: serialIdDisplay,
        REPORT_REPORTING_EVENT_NUMBER: renDisplay,
    } = useFields([
        BARCODE_VALUE,
        DISPLAY_EVIDENCE_LABEL_ITEM_ID,
        ITEM_PROFILE_DESCRIPTION,
        ITEM_PROFILE_ITEM_TYPE_ITEM_SERIAL_NUMBER,
        REPORT_REPORTING_EVENT_NUMBER,
    ]);

    const searchables = [
        strings.searchOptionPopover.itemIdentifiers(
            renDisplay,
            barcodeIdDisplay,
            serialIdDisplay,
            itemIdDisplay
        ),
        strings.searchOptionPopover.propertyOwners,
        strings.searchOptionPopover.itemNotes,
        strings.searchOptionPopover.itemDescription(itemDesciptionDisplay),
    ];

    return (
        <PopOverContentWrapper>
            {map(searchables, (text) => (
                <ListItem>{text}</ListItem>
            ))}
        </PopOverContentWrapper>
    );
};

// This component is highly possible to be replaced by
// ResultsHeader used in Inventories/Cases dashboard in the future, once design is done.
function EvidenceDashboardSearchForm() {
    const dispatch = useDispatch();
    const applicationSettings = useSelector(applicationSettingsSelector);

    const fieldDisplayNames = useFields([REPORT_REPORTING_EVENT_NUMBER]);
    const evidenceModuleName = useEvidenceModuleName();
    const [filterIsOpen, setFilterIsOpen] = useState(false);

    const onSubmit = useCallback(() => dispatch(submitEvidenceDashboardSearchForm()), [dispatch]);

    const isEvidenceFilterSortUpdatesEnabled = !!applicationSettings.EVIDENCE_FILTER_SORT_UPDATES;

    const placeholder = useMemo(() => {
        return isEvidenceFilterSortUpdatesEnabled
            ? strings.placeholders.basicPlaceholder
            : strings.placeholders.quickSearchQuery(
                  fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER
              );
    }, [fieldDisplayNames.REPORT_REPORTING_EVENT_NUMBER, isEvidenceFilterSortUpdatesEnabled]);

    return (
        <div className="evidence-dashboard__search-form">
            <ReactReduxForm {...evidenceDashboardSearch.form}>
                <Row>
                    <DashboardSearchWell className="clearfix evidence-dashboard__search-well">
                        <DashboardSearchWrapper>
                            <VStack align="start" spacing="-2px">
                                <div>
                                    <RRFText
                                        textInputOnly
                                        path="query"
                                        width={250}
                                        placeholder={placeholder}
                                        isClearable={isEvidenceFilterSortUpdatesEnabled}
                                        onPressEnter={onSubmit}
                                        testId={testIds.EVIDENCE_DASHBOARD_SEARCH_TEXT_INPUT}
                                    />
                                    <EvidenceDashboardSearchButton
                                        className={buttonTypes.SMALL_GO}
                                        onClick={onSubmit}
                                        testId={testIds.EVIDENCE_DASHBOARD_SEARCH_BUTTON}
                                    >
                                        {strings.search}
                                    </EvidenceDashboardSearchButton>
                                </div>
                                <FeatureFlagged flag="EVIDENCE_FILTER_SORT_UPDATES">
                                    <Popover hasArrow={false}>
                                        <PopoverTrigger>
                                            <SearchPopoverButton onClick={noop} isTextTransformNone>
                                                {strings.searchByButton}
                                            </SearchPopoverButton>
                                        </PopoverTrigger>
                                        <PopoverContent align="start">
                                            <QuickSearchPopoverContent />
                                        </PopoverContent>
                                    </Popover>
                                </FeatureFlagged>
                            </VStack>

                            <EvidenceDashboardFilterButtonsWrapper>
                                <EvidenceDashboardFilterButton
                                    className={buttonTypes.SECONDARY}
                                    testId={testIds.EVIDENCE_DASHBOARD_FILTER_BUTTON}
                                    onClick={() => {
                                        setFilterIsOpen(
                                            (previousFilterIsOpen) => !previousFilterIsOpen
                                        );
                                    }}
                                    active={filterIsOpen}
                                >
                                    {strings.filterEvidence(evidenceModuleName)}
                                </EvidenceDashboardFilterButton>
                                <SavedSearchModal searchModule={evidenceDashboardSearch} />
                            </EvidenceDashboardFilterButtonsWrapper>
                        </DashboardSearchWrapper>

                        <EvidenceDashboardAppliedFilterSummary />
                    </DashboardSearchWell>
                </Row>
            </ReactReduxForm>
            <FormVisibilityWrapper visibility={filterIsOpen}>
                <FilterForm />
            </FormVisibilityWrapper>
        </div>
    );
}

/**
 * Search form on the evidence dashboard, which includes both the free text
 *   search input and the filters below it. The filter form fields only appear
 *   in an overlay when the FILTERS button is clicked.
 * @type {Object}
 */
export default EvidenceDashboardSearchForm;

import { RmsHistoryEvent } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import { augmentHistoryEvents, AugmentedRmsHistoryEvent } from '../../utils/historyEventHelpers';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'historyEvents';
const historyEventsModule = createNormalizedModule<AugmentedRmsHistoryEvent>({
    type: NEXUS_STATE_PROP,
});

// The history event does not have any information about the entity used in the
// request to get those history events
// So in order to later on get all the history events for a particular
// entity, we have to add the entityId onto the model
export const storeHistoryEventsWithEntityId = (
    historyEvents: RmsHistoryEvent[],
    entityId: number
): ClientCommonAction<void> => (dispatch) => {
    const augmentedHistoryEvents = augmentHistoryEvents(historyEvents, entityId);
    dispatch(historyEventsModule.actionCreators.storeEntities(augmentedHistoryEvents));
};

export const historyEventsSelector = historyEventsModule.selectors.entitiesSelector;

export default historyEventsModule.reducerConfig;

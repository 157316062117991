import { chain, filter, get, map } from 'lodash';

import { createSelector } from 'reselect';
import { allMiniUserFormatsByIdSelector } from '../../../mini-users/state/data';
import { storageLocationViewModelByIdSelector } from '../../../storage-locations/state/ui';
import { itemTypeSpecificViewModelByIdSelector } from '../../../item-profiles/state/ui';
import {
    buildAllMiniUserFormatsMapper,
    buildViewModel,
} from '../../../../../helpers/viewModelHelpers';

import { itemReportingEventLinksSelector } from '../../../item-reporting-event-links/state/data';
import formatRenSequenceNumber from '../../../item-reporting-event-links/utils/formatRenSequenceNumber';
import { inventoryItemsSelector } from '../data';

/**
 * A selector that creates and populates a view model for all inventory items
 */
const inventoryItemViewModelsSelector = createSelector(
    allMiniUserFormatsByIdSelector,
    inventoryItemsSelector,
    itemReportingEventLinksSelector,
    itemTypeSpecificViewModelByIdSelector,
    storageLocationViewModelByIdSelector,
    (
        allMiniUserFormatsById,
        inventoryItems,
        itemReportingEventLinks,
        itemTypeSpecificViewModelById,
        storageLocationViewModelById
    ) =>
        map(
            inventoryItems,
            buildViewModel({
                helpers: { allMiniUserFormatsById },
                mappers: [
                    buildAllMiniUserFormatsMapper(),
                    ({ inventoryId, inventoryItemStatus, masterItemId, storageLocationId }) => {
                        const { reportingEventNumber, sequenceNumber } = chain(
                            itemReportingEventLinks
                        )
                            .find({ masterItemProfileId: masterItemId })
                            .pick('reportingEventNumber', 'sequenceNumber')
                            .value();

                        const storageLocationViewModel = storageLocationViewModelById(
                            storageLocationId
                        );

                        return {
                            ...itemTypeSpecificViewModelById(masterItemId),
                            inventoryId,
                            itemId: formatRenSequenceNumber(reportingEventNumber, sequenceNumber),
                            status: inventoryItemStatus,
                            storageLocationName: get(storageLocationViewModel, 'displayValue', ''),
                            storageLocationFullDisplayPath: get(
                                storageLocationViewModel,
                                'fullDisplayPath',
                                ''
                            ),
                        };
                    },
                ],
                recursive: false,
            })
        )
);

/**
 * A selector that creates and populates a inventory items view model based on a specific inventory id
 */
export const inventoryItemViewModelsByIdSelector = createSelector(
    inventoryItemViewModelsSelector,
    (inventoryItemsViewModel) => (inventoryId) =>
        filter(inventoryItemsViewModel, (item) => item.inventoryId === inventoryId)
);

import { includes, some, filter } from 'lodash';
import {
    RefContextEnum,
    LinkTypesEnum,
    ComplianceGroupEnumType,
    ComplianceGroupEnum,
    RegionalGroupEnumType,
} from '@mark43/rms-api';
import { organizationProfileByIdSelector } from '~/client-common/core/domain/organization-profiles/state/data';
import { MFTFormsRegistry } from '../../../../core/formsRegistry';
import {
    RelationshipDataType,
    getIdsForLinkTypeFromRelationshipData,
    RelationshipType,
    isSupportedComplianceGroup,
    isSupportedRegionalGroup,
} from '../getVictimHasValidDomesticViolenceRelationship';
import { DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP } from './domesticViolenceRelationshipsByComplianceGroup';
import { DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP } from './domesticViolenceRelationshipsByRegionalGroup';

/**
 * Given a `victimId` and an active offense report,
 * if the victim has a DV relationship with any/all (based off of `quantifier`)
 * of the suspects on the same offense, then this function will return `true`
 *
 * Note that testing of this method is done through this RFG:
 * https://github.com/mark43/rms/blob/d60108d74809b2118a12efec290b630e9e632fb4/client/src/scripts/modules/core/arbiter/__tests__/getRelevantRelationshipExistsInEachDomesticViolenceOffense.js#L732
 *
 * because that RFG makes heavy usage of this method
 */
export const victimHasValidDomesticViolenceRelationship = ({
    complianceGroup,
    nibrsRegionalGroup,
    relationshipData,
    victimId,
    formsRegistry,
    organizationProfileById,
    quantifier,
}: {
    relationshipData: RelationshipDataType;
    victimId: number;
    formsRegistry: MFTFormsRegistry;
    organizationProfileById: ReturnType<typeof organizationProfileByIdSelector>;
    /**
     * If there are multiple suspects:
     *      - `ALL` means that every suspect must have a DV relationship with the victim
     *      - `ANY` means that at least one suspect must have a DV relationship with the victim
     *
     * By default, we'll assume `ANY` because most often, we only care for at least one DV relationship
     * to exist between the victim and all suspects.
     *
     * However, there are some (while few) use-cases where we need to know if all victim-suspect pairings
     * have DV relationships
     */
    quantifier?: 'ALL' | 'ANY';
} & (
    | {
          complianceGroup: ComplianceGroupEnumType;
          nibrsRegionalGroup?: never;
      }
    | {
          complianceGroup?: never;
          nibrsRegionalGroup: RegionalGroupEnumType;
      }
)) => {
    const victimIsSociety = organizationProfileById(victimId)?.isSociety;

    // Specifically for UK, if a victim is "society", then it doesn't need
    // to have relationship to be considered valid
    if (complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name && victimIsSociety) {
        return true;
    }

    const offendersFromRelationships = getIdsForLinkTypeFromRelationshipData(
        relationshipData,
        LinkTypesEnum.OFFENDER_IN_OFFENSE
    );

    const suspectsFromRelationships = getIdsForLinkTypeFromRelationshipData(
        relationshipData,
        LinkTypesEnum.SUSPECT_IN_OFFENSE
    );

    // In the US, we typically care for victim <-> suspect
    // However in the UK, we look for victim <-> offender
    const victimCounterparts =
        complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name
            ? offendersFromRelationships
            : suspectsFromRelationships;

    const victimsFromRelationships = getIdsForLinkTypeFromRelationshipData(
        relationshipData,
        LinkTypesEnum.VICTIM_IN_OFFENSE
    );

    const relationshipsForm = formsRegistry.get(RefContextEnum.FORM_RELATIONSHIPS.name);
    // type this better if relationshipForm is typed
    const relationships = relationshipsForm?.getState().model.relationships as RelationshipType[];

    // How do we decide if we should use the regional group vs the compliance group?
    // Let's just say that the compliance group will trump the regional group
    // (this is because if the compliance group is UK, we should use those relationships
    // regardless of the regional group)
    const domesticViolenceRelationships =
        (isSupportedComplianceGroup(complianceGroup)
            ? DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_COMPLIANCE_GROUP[complianceGroup]
            : undefined) ||
        (isSupportedRegionalGroup(nibrsRegionalGroup)
            ? DOMESTIC_VIOLENCE_RELATIONSHIPS_BY_REGIONAL_GROUP[nibrsRegionalGroup]
            : undefined);

    // If there are no DV relationships defined for the compliance/regional group,
    // then we'll say all relationships are valid
    if (!domesticViolenceRelationships) {
        return true;
    }

    const relevantRelationships = filter(relationships, ({ linkOptionIds }) => {
        return some(linkOptionIds, (linkOptionId) =>
            includes(domesticViolenceRelationships, linkOptionId)
        );
    });

    const victim = victimsFromRelationships.find((victim) => victim.nameId === victimId);

    // If a relationship doesn't exist for the victim, then we are in an invalid state
    if (!victim) {
        return false;
    }

    const offenseId = victim.offenseId;

    const victimCounterpartIds = victimCounterparts
        .filter((victimCounterpart) => victimCounterpart.offenseId === offenseId)
        .map((victimCounterpart) => victimCounterpart.nameId);

    const relationshipIncludesVicimAndVictimCounterpart = (
        relationship: RelationshipType,
        victimId: number,
        victimCounterpartId: number
    ) => {
        return (
            (relationship.nameId === victimId &&
                relationship.otherNameId === victimCounterpartId) ||
            (relationship.otherNameId === victimId && relationship.nameId === victimCounterpartId)
        );
    };

    const iterator = (quantifier === 'ALL'
        ? victimCounterpartIds.every
        : victimCounterpartIds.some
    ).bind(victimCounterpartIds);

    return iterator((victimCounterpartId) => {
        return relevantRelationships.find((relationship) => {
            return relationshipIncludesVicimAndVictimCounterpart(
                relationship,
                victim.nameId,
                victimCounterpartId
            );
        });
    });
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName, mapProps } from 'recompose';
import { includes, keyBy, map, reject } from 'lodash';
import { simpleControl } from 'markformythree';

import {
    roleOptionsByTypesSelector,
    allRoleFormatsByRoleIdSelector,
} from '~/client-common/core/domain/roles/state/data';
import { nowUtc } from '~/client-common/core/dates/utils/dateHelpers';
import { searchUserRoles } from '../../../../../legacy-redux/actions/rolesActions';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';
import { arbiterMFTInput } from '../../../arbiter';
import AsyncSelect from './AsyncSelect';

const { connectRRFInput } = reactReduxFormHelpers;

// If you are using a RoleSelect in order to get User-Roles try to use UserSelect instead
const RoleSelect = compose(
    setDisplayName('RoleSelect'),
    connect(
        createStructuredSelector({
            roleOptionsByTypes: roleOptionsByTypesSelector,
            allRoleFormatsByRoleId: allRoleFormatsByRoleIdSelector,
            currentUserDepartmentId: currentUserDepartmentIdSelector,
        }),
        (dispatch) => ({
            searchUserRoles: (query, { effectiveDate }) =>
                dispatch(searchUserRoles(query, { effectiveDate })),
        })
    ),
    mapProps((props) => {
        const { useExternalRoles = false, currentUserDepartmentId } = props;
        let options = reject(
            props.roleOptionsByTypes(
                props.roleTypes,
                useExternalRoles
                    ? ({ departmentId }) => departmentId !== currentUserDepartmentId
                    : { departmentId: currentUserDepartmentId },
                // whenever we use external roles, we want them to be prefixed
                // with their department name, so we just pass `useExternalRoles`
                // through as the value for `prefixWithDepartmentName`
                useExternalRoles
            ),
            (roleOption) => includes(props.excludeRoleIds, roleOption.value)
        );
        const indexedOptions = keyBy(options, 'value');
        // if the currently selected value is not in our options, we have
        // to add an option representing this value
        if (props.value && !indexedOptions[props.value]) {
            const values = props.multiple ? props.value : [props.value];
            options = [
                ...map(values, (value) => ({
                    display: props.allRoleFormatsByRoleId(value).default,
                    value,
                })),
                ...options,
            ];
        }
        return {
            options,
            ...props,
        };
    })
)(function RoleSelect({
    searchUserRoles,
    options,
    effectiveDate = nowUtc(),
    noAsyncAction = false,
    ...otherProps
}) {
    return (
        <AsyncSelect
            {...otherProps}
            options={options}
            asyncAction={
                noAsyncAction
                    ? () => undefined
                    : (query) => searchUserRoles(query, { effectiveDate })
            }
        />
    );
});

/**
 * Typeahead for selecting role(s).
 * @param {Object} props Same props as `Select`.
 * @param {boolean} [props.useExternalRoles=false]  Whether to show external or internal roles
 * @param {boolean} [props.noAsyncAction=false]  Whether or not to use the `searchUserRoles` async action
 * @param {string[]} props.roleTypes
 */
export default RoleSelect;

export const RRFRoleSelect = connectRRFInput(RoleSelect);
export const ArbiterMFTRoleSelect = arbiterMFTInput(RoleSelect);
export const MFTRoleSelect = simpleControl(RoleSelect);

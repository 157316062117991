import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';

import { pageSizeToFrom } from '~/client-common/helpers/searchHelpers';
import { REPORT_REPORTING_EVENT_NUMBER, DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL } from '~/client-common/core/enums/universal/fields';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    sortedProfileReportsViewModelsSelector,
    uiReportsPaginationSelector,
} from '../../core/state/ui';
import { fetchAndStoreRelatedReports } from '../../core/state/data';
import { InlineBanner } from '../../../core/components/InlineBanner';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import InfoCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/InfoCell';
import ReportTypeCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/ReportTypeCell';
import PrimaryLocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/PrimaryLocationCell';
import PropertyStatusCell from '../../../../legacy-redux/components/core/tables/elasticReportTable/PropertyStatusCell';
import EntityProfileReportsWithFilterTableHeader from '../../core/components/EntityProfileReportsWithFilterTableHeader';
import entityProfileReportsFilterForm from '../../core/state/forms/entityProfileReportsFilterForm';

const strings = componentStrings.entityProfiles.EntityProfileVehicleReports;

const EventStartUtcCell = dateCellFactory('eventStartUtc');

function EntityProfileVehicleReports({
    uiReportsPagination,
    profileReports,
    formatFieldByName,
    handleRowClick,
    handlePaginationClick,
}) {
    const { loading, from, size, totalCount, fetchErrorMessage } = uiReportsPagination;
    const error = fetchErrorMessage ? (
        <th colSpan={5}>
            <InlineBanner status="error">{fetchErrorMessage}</InlineBanner>
        </th>
    ) : null;

    return (
        <Table
            data={profileReports}
            onRowClick={handleRowClick}
            noRowsText="No Report Results"
            simpleStyles={true}
            rowComponent={ElasticResultRow}
            disableBody={loading}
        >
            <TableHeader>
                {error}
                <EntityProfileReportsWithFilterTableHeader
                    from={from}
                    size={size}
                    totalCount={totalCount}
                    onPaginationClick={(lastPage, nextPage) =>
                        handlePaginationClick(nextPage, size)
                    }
                />
                <TableColumns>
                    <TableColumn
                        display={strings.columns.eventDate.label}
                        columnKey="eventStartUtc"
                        width={124}
                    />
                    <TableColumn
                        display={strings.columns.renOwner.label(
                            formatFieldByName(REPORT_REPORTING_EVENT_NUMBER), formatFieldByName(DISPLAY_ONLY_PERSONNEL_REPORT_OWNER_LABEL)
                        )}
                        columnKey="info"
                        width={127}
                    />
                    <TableColumn
                        display={strings.columns.reportType.label}
                        columnKey="reportType"
                        width={156}
                    />
                    <TableColumn
                        display={strings.columns.locations.label}
                        columnKey="locations"
                        width={214}
                    />
                    <TableColumn
                        display={strings.columns.propertyStatuses.label}
                        columnKey="propertyStatuses"
                        width={124}
                    />
                </TableColumns>
            </TableHeader>
            <TableBody>
                <EventStartUtcCell columnKey="eventStartUtc" />
                <InfoCell columnKey="info" />
                <ReportTypeCell columnKey="reportType" />
                <PrimaryLocationCell columnKey="locations" />
                <PropertyStatusCell columnKey="propertyStatuses" />
            </TableBody>
        </Table>
    );
}

const mapStateToProps = createStructuredSelector({
    uiReportsPagination: uiReportsPaginationSelector,
    profileReports: sortedProfileReportsViewModelsSelector,
    formatFieldByName: formatFieldByNameSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    handleRowClick({ id }) {
        ownProps.router.push(`/reports/${id}`);
    },
    handlePaginationClick(nextPage, size) {
        const from = pageSizeToFrom(nextPage, size);
        dispatch(
            entityProfileReportsFilterForm.actionCreators.submit((formData) => {
                dispatch(
                    fetchAndStoreRelatedReports(
                        ownProps.params.entityType,
                        ownProps.params.entityId,
                        {
                            from,
                            size,
                        },
                        formData
                    )
                );
            })
        );
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(EntityProfileVehicleReports);

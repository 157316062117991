import { RefContextEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { createFormConfiguration, createNItems, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { WARRANT_CHARGE_CHARGE_NAME } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import { submitWarrantChargesForm, WARRANT_CHARGES_BOX_CONTEXT } from '../state/ui';
import SidePanel from '../../../../legacy-redux/components/core/SidePanel';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTText } from '../../../core/forms/components/Text';
import { ArbiterMFTDatePicker } from '../../../core/forms/components/DatePicker';
import { ArbiterMFTAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { MFTNItems, RemoveButton as _RemoveButton } from '../../../core/forms/components/NItems';
import Row from '../../../core/components/Row';
import IndentedFields from '../../../core/components/IndentedFields';
import _Fieldset from '../../../core/forms/components/Fieldset';
import { currentUserIdSelector } from '../../../core/current-user/state/ui';
import { closeBox } from '../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../core/box/state/ui';
import formsRegistry from '../../../../core/formsRegistry';

const strings = componentStrings.warrants.warrant.WarrantChargesSidePanel;

const RemoveButton = styled(_RemoveButton)`
    position: absolute;
    right: -3px;
    margin-top: 0;
`;

const Fieldset = styled(_Fieldset)`
    position: relative;
`;

function WarrantChargesSidePanel({ onSubmit, close, boxPayload }) {
    const { warrantId, createNew } = boxPayload || {};
    const chargeLabel = useFields(WARRANT_CHARGE_CHARGE_NAME)[WARRANT_CHARGE_CHARGE_NAME];
    return (
        <SidePanel
            title={createNew ? strings.addCharges(chargeLabel) : strings.editCharge(chargeLabel)}
            onSave={() => onSubmit({ warrantId })}
            onCancel={close}
            context={WARRANT_CHARGES_BOX_CONTEXT}
            width={400}
        >
            <ArbiterForm
                lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                name={RefContextEnum.FORM_WARRANT_CHARGES.name}
                context={RefContextEnum.FORM_WARRANT_CHARGES.name}
                configuration={createFormConfiguration({
                    charges: createNItems({
                        fields: {
                            chargeName: {
                                fieldName: fields.WARRANT_CHARGE_CHARGE_NAME,
                            },
                            chargeCount: {
                                fieldName: fields.WARRANT_CHARGE_CHARGE_COUNT,
                            },
                            offenseClassificationAttrId: {
                                fieldName: fields.WARRANT_CHARGE_OFFENSE_CLASSIFICATION_ATTR_ID,
                            },
                            offenseClassificationOther: {
                                fieldName: fields.WARRANT_CHARGE_OFFENSE_CLASSIFICATION_OTHER,
                            },
                            offenseDateUtc: {
                                fieldName: fields.WARRANT_CHARGE_OFFENSE_DATE_UTC,
                            },
                        },
                    }),
                })}
                render={() => (
                    <MFTNItems
                        path="charges"
                        addText={chargeLabel}
                        addItemOnEmpty={true}
                        renderRemoveButton={undefined}
                        {...(createNew ? {} : { renderAddButton: undefined })}
                        render={(form) => {
                            const { removeItem } = form;
                            return (
                                <Fieldset
                                    title={chargeLabel}
                                    deleteButton={
                                        createNew ? <RemoveButton onClick={removeItem} /> : null
                                    }
                                >
                                    <Row>
                                        <ArbiterMFTText width={270} path="chargeName" />
                                        <ArbiterMFTText width={60} path="chargeCount" />
                                    </Row>
                                    <Row>
                                        <ArbiterMFTAttributeSelect
                                            attributeType="OFFENSE_CLASSIFICATION"
                                            width={340}
                                            path="offenseClassificationAttrId"
                                        />
                                    </Row>
                                    <IndentedFields>
                                        <Row>
                                            <ArbiterMFTText
                                                path="offenseClassificationOther"
                                                width={225}
                                            />
                                        </Row>
                                    </IndentedFields>
                                    <Row>
                                        <ArbiterMFTDatePicker
                                            path="offenseDateUtc"
                                            includeTime={false}
                                            width={225}
                                        />
                                    </Row>
                                </Fieldset>
                            );
                        }}
                    />
                )}
            />
        </SidePanel>
    );
}

const mapStateToProps = createStructuredSelector({
    currentUserId: currentUserIdSelector,
    boxPayload: createModalSelector(WARRANT_CHARGES_BOX_CONTEXT),
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: ({ warrantId }) => dispatch(submitWarrantChargesForm({ warrantId })),
    close: () => {
        dispatch(closeBox(WARRANT_CHARGES_BOX_CONTEXT));
        const form = formsRegistry.get(RefContextEnum.FORM_WARRANT_CHARGES.name);
        form.resetModel();
    },
});

/**
 * Side panel for adding/editing an charge
 */
export default connect(mapStateToProps, mapDispatchToProps)(WarrantChargesSidePanel);

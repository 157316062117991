import React from 'react';
import { useSelector } from 'react-redux';
import { InjectedRouter, withRouter } from 'react-router';

import RecordsHeaderHistoryButton from '../../../records/core/components/header/RecordsHeaderHistoryButton';
import { routeNameSelector } from '../../../../routing/routerModule';
import routesConfig from '../../../../routing/routesConfig';

const WarrantHeaderHistoryButton: React.FC<{
    router: InjectedRouter;
    warrantId: number;
}> = ({ router, warrantId }) => {
    const routeName = useSelector(routeNameSelector);

    const onClick = () => {
        const isHistoryView = routesConfig.WARRANT_HISTORY.name === routeName;
        if (isHistoryView) {
            router.push(`/warrants/${warrantId}`);
        } else {
            router.push(`/warrants/${warrantId}/history`);
        }
    };
    return (
        <RecordsHeaderHistoryButton
            className="warrant_header_history"
            onClick={onClick}
            active={routesConfig.WARRANT_HISTORY.name === routeName}
        />
    );
};

export default withRouter(WarrantHeaderHistoryButton);

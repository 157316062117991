import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formatStaticMapUrl } from '~/client-common/core/domain/elastic-involved-locations/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { Map } from '~/client-common/core/maps';
import createElement from '../../../core/utils/recompose.createElement';

import LocationCell from '../../../../legacy-redux/components/core/tables/elasticLocationTable/LocationCell';
import CaseLinkedProfile from './CaseLinkedProfile';
import CaseSourceIndicator from './CaseSourceIndicator';

function CaseRelatedLocation({ elasticLocation, applicationSettings }) {
    let staticMap = null;
    const hasLocation = elasticLocation && elasticLocation.locationAddress;
    const hasLatLng =
        hasLocation &&
        typeof elasticLocation.locationAddress?.latitude === 'number' &&
        typeof elasticLocation.locationAddress?.longitude === 'number';
    if (hasLocation) {
        if (applicationSettings.ESRI_MAPS_ENABLED && hasLatLng) {
            staticMap = (
                <Map
                    defaultCenter={{
                        lat: elasticLocation.locationAddress.latitude,
                        lng: elasticLocation.locationAddress.longitude,
                    }}
                    selectedLocation={elasticLocation.locationAddress}
                    width={199}
                    height={199}
                />
            );
        } else {
            // use of `createElement` is due to the fact that all `Symbol` properties
            // of an object are lost when it's "copied" (via spread syntax). This is an intended
            // effect of spread syntax / for in
            staticMap = <img src={formatStaticMapUrl(elasticLocation.locationAddress)} />;
        }
    } else {
        staticMap = <img className="map-stub-image" src="images/entity-profiles/locations.png" />;
    }
    return (
        <CaseLinkedProfile clickable={false}>
            <div className="case-related-entity-cell map">{staticMap}</div>
            <div className="case-related-entity-cell related-entity-location-info-cell">
                {createElement(LocationCell, { ...elasticLocation })}
                <CaseSourceIndicator sources={elasticLocation.sources} />
            </div>
        </CaseLinkedProfile>
    );
}
export default connect(
    createStructuredSelector({
        applicationSettings: applicationSettingsSelector,
    })
)(CaseRelatedLocation);

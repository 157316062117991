import { flatMap, map } from 'lodash';

import { NEXUS_STATE_PROP as ITEM_REPORT_LINKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/item-report-links/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { NEXUS_STATE_PROP as STAFF_REMARKS_NEXUS_STATE_PROP } from '~/client-common/core/domain/staff-remarks/state/data';
import { NEXUS_STATE_PROP as ATTRIBUTES_NEXUS_STATE_PROP } from '~/client-common/core/domain/attributes/state/data';
import { convertStorageLocationsToElasticAndStore } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { convertAttributeToAttributeView } from '~/client-common/core/domain/attributes/utils/attributesHelpers';
import getEvidenceReportResource from '~/client-common/core/domain/evidence/resources/evidenceReportResource';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { withEvidenceHydratedItems } from '../../utils/evidenceItemsHelpers';

/**
 * Load evidence-related data for a custodial report.
 * @param  {number} itemId
 * @return {RmsAction<Promise<CompleteEvidenceReportView>>}
 */
export function loadCustodialReportBundle(custodialReportId) {
    return function (dispatch, getState, dependencies) {
        const state = getState();

        const isPaginationEnabled = applicationSettingsSelector(state)
            .EVIDENCE_PAGINATE_HYDRATED_ITEMS_ENABLED;

        return getEvidenceReportResource()
            .getCustodialReportBundle(custodialReportId, isPaginationEnabled)
            .then((bundle) => {
                const {
                    evidenceHydratedItems,
                    reportShortTitles,
                    itemReportLinks,
                    staffRemarks = [],
                    attributes,
                } = bundle;

                const hydratedEvidenceItemStaffRemarks = flatMap(
                    evidenceHydratedItems,
                    'staffRemarks'
                );

                const storageLocationViews = flatMap(evidenceHydratedItems, 'storageLocationViews');

                const allStaffRemarks =
                    hydratedEvidenceItemStaffRemarks.length === 0
                        ? staffRemarks
                        : [
                              ...(staffRemarks || []), // report-level remarks
                              ...hydratedEvidenceItemStaffRemarks, // item-level remarks
                          ];

                const entitiesToStore = {
                    [ITEM_REPORT_LINKS_NEXUS_STATE_PROP]: itemReportLinks,
                    [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: reportShortTitles,
                    [STAFF_REMARKS_NEXUS_STATE_PROP]: allStaffRemarks,
                    [ATTRIBUTES_NEXUS_STATE_PROP]: map(attributes, (attribute) =>
                        convertAttributeToAttributeView(attribute)
                    ),
                };

                dispatch(
                    dependencies.nexus.withEntityItems(
                        entitiesToStore,
                        withEvidenceHydratedItems(
                            evidenceHydratedItems,
                            dependencies.nexus.withEntityItems
                        )
                    )
                );

                if (storageLocationViews.length > 0) {
                    dispatch(convertStorageLocationsToElasticAndStore(storageLocationViews));
                }

                return bundle;
            });
    };
}

import { ProfileReportTypeDto } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const NEXUS_STATE_PROP = 'profileReportTypes';
const profileReportTypes = createNormalizedModule<ProfileReportTypeDto>({
    type: NEXUS_STATE_PROP,
});

// ACTIONS
export const profileReportTypesStore = profileReportTypes.actionCreators.storeEntities;
export const profileReportTypesDeleteWhere = profileReportTypes.actionCreators.deleteEntitiesWhere;

// SELECTORS
export const profileReportTypesSelector = profileReportTypes.selectors.entitiesSelector;

// REDUCER
export default profileReportTypes.reducerConfig;

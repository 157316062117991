import React from 'react';

import { Caution, CautionGroup, CautionProps } from '@arc/tag';
import { useSelector } from 'react-redux';
import { AttributeTypeEnum, ElasticItemAttribute, ItemAttribute } from '@mark43/rms-api';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { usePreloadAttributes } from '~/client-common/core/hooks/usePreloadAttributes';
import testIds, { TestId } from '../../../../core/testIds';
import { getPrioritizedVehicleLabelAttributes } from './helpers';

type VehicleLabelsPropsT = {
    itemAttributes?: ItemAttribute[];
    elasticItemAttributes?: ElasticItemAttribute[];
    isStolen?: boolean;
    isActiveTarget?: boolean;
    className?: string;
    priority1Only?: boolean;
    size: CautionProps['size'];
    testId: TestId;
};

export const VehicleLabels: React.FC<VehicleLabelsPropsT> = ({
    itemAttributes,
    elasticItemAttributes,
    isStolen,
    isActiveTarget,
    priority1Only,
    className,
    size,
    testId,
}) => {
    const formatAttributeById = useSelector(formatAttributeByIdSelector);
    const parentAttributeIdByAttributeId = useSelector(parentAttributeIdByAttributeIdSelector);

    // 1. For non-search features, in RmsHydratedPerson the gathered attributes are
    //    VEHICLE_LABEL_ATTRIBUTES and don't include their parent attributes of type
    //    VEHICLE_LABEL_CATEGORY.
    // 2. For search features, in ElasticPerson the VEHICLE_LABEL_ATTRIBUTES do not have
    //    parent ids since they are AttrDetails.
    // We need VEHICLE_LABEL_CATEGORY in order to determine each label's priority. Until
    // both models include this info, this component has to preload both attribute types.
    usePreloadAttributes([
        AttributeTypeEnum.VEHICLE_LABEL_CATEGORY.name,
        AttributeTypeEnum.VEHICLE_LABEL_ATTRIBUTES.name,
    ]);

    return (
        <CautionGroup wrap="wrap" className={className} data-test-id={testId}>
            {getPrioritizedVehicleLabelAttributes({
                itemAttributes,
                elasticItemAttributes,
                isStolen,
                isActiveTarget,
                priority1Only,
                formatAttributeById,
                parentAttributeIdByAttributeId,
            }).map(({ priority, formattedCategoryAttribute }) => {
                return formattedCategoryAttribute ? (
                    <Caution data-test-id={testIds.LABEL} size={size} priority={priority}>
                        {formattedCategoryAttribute}
                    </Caution>
                ) : undefined;
            })}
        </CautionGroup>
    );
};

import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';

import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';

type OverlayIdType = keyof typeof overlayIdEnum;

export default function useOverlayStoreCustomPropertiesGetter<T extends Record<string, unknown>>(
    overlayId: OverlayIdType
) {
    const overlayStore = useOverlayStore<T>();
    return (): T => {
        return overlayStore.getStateForId(overlayId)?.customProperties;
    };
}

import { EntityTypeEnum } from '@mark43/rms-api';

import _ from 'lodash';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { createStaffRemarks } from '~/client-common/core/domain/staff-remarks/state/data';
import { reportShortTitleByReportIdSelector } from '~/client-common/core/domain/report-short-titles/state/data';

import {
    openBox,
    closeBox,
    saveBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
} from '../../../../../legacy-redux/actions/boxActions';
import { createModalSelector } from '../../../../core/box/state/ui';
import staffRemarkForm from '../forms/staffRemarkForm';

const context = {
    name: boxEnum.STAFF_REMARK_SIDE_PANEL,
};

/**
 * Open a side panel to create staff remark(s) for either 1 report or arbitrarily many item
 *   profiles.
 * @param  {string}   entityType 'REPORT' or 'ITEM_PROFILE'
 * @param  {number[]} entityIds  If entityType is 'REPORT', only the report id should be provided.
 */
export function openStaffRemarkSidePanel(entityType, entityIds) {
    return function (dispatch, getState) {
        const state = getState();
        const reportShortTitleByReportId = reportShortTitleByReportIdSelector(state);
        dispatch(staffRemarkForm.actionCreators.reset());
        dispatch(
            openBox(context, {
                entityType,
                entityIds,
                reportingEventNumber:
                    entityType === EntityTypeEnum.REPORT.name
                        ? _.get(
                              reportShortTitleByReportId(_.head(entityIds)),
                              'reportingEventNumber'
                          )
                        : undefined,
            })
        );
    };
}

export function closeStaffRemarkSidePanel() {
    return function (dispatch) {
        dispatch(closeBox(context));
        dispatch(staffRemarkForm.actionCreators.reset());
    };
}

export function submitStaffRemarkForm() {
    return function (dispatch, getState) {
        dispatch(saveBoxStart(context));

        return dispatch(
            staffRemarkForm.actionCreators.submit((formModel) => {
                const state = getState();
                const entityType = entityTypeSelector(state);
                const entityIds = entityIdsSelector(state);
                const staffRemarks = staffRemarkForm.convertFromFormModel(
                    formModel,
                    entityType,
                    entityIds
                );

                return dispatch(createStaffRemarks(staffRemarks))
                    .then(() => {
                        dispatch(saveBoxSuccess(context));
                    })
                    .catch((err) => {
                        dispatch(saveBoxFailure(context, err.message));
                    });
            })
        ).catch((panelErrors) => {
            dispatch(saveBoxFailure(context, _.head(panelErrors)));
        });
    };
}

/**
 * The entity type that the staff remark side panel was opened for.
 * @type {string|undefined}
 */
export const entityTypeSelector = createModalSelector(context, 'entityType');

/**
 * The entity ids that the staff remark side panel was opened for.
 * @type {number[]|undefined}
 */
export const entityIdsSelector = createModalSelector(context, 'entityIds');

/**
 * The REN of the report that the staff remark side panel was opened for.
 * @type {string|undefined}
 */
export const reportingEventNumberSelector = createModalSelector(context, 'reportingEventNumber');

import React from 'react';
import { JmsBooking } from '@mark43/rms-api';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { searchSizes } from '~/client-common/configs/advancedSearchConfig';
import { InlineBanner } from '../../../core/components/InlineBanner';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import { TableRow } from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import BookingDetailsCell from '../../../../legacy-redux/components/core/tables/jmsBookingsTable/BookingDetailsCell';
import ArrestingOfficerCell from '../../../../legacy-redux/components/core/tables/jmsBookingsTable/ArrestingOfficerCell';
import EventNumberCell from '../../../../legacy-redux/components/core/tables/jmsBookingsTable/EventNumberCell';
import { BookingCellProps } from '../../../entity-profiles/persons/components/EntityProfilePersonJmsBookings';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import SearchSizeDropdownMenu from '../../../core/components/SearchSizeDropdownMenu';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';

type AdvancedSearchBookingResultsProps = {
    bookings: JmsBooking[];
    totalCount: number;
    from: number;
    pageSize: searchSizes;
    currentPage: number;
    handlePagination: (nextPage: number, size: number) => void;
    errorMessage?: string;
    loading: boolean;
    handleRowClick: (bookingItem: BookingCellProps) => void;
    handlePageSizeChange: (size: searchSizes) => void;
};

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    float: left;
    margin-right: 10px;
    padding-top: 7px;
`;
// 109 is a header height
const ClippableSearchTable = clippable({ bottomBuffer: 109 })(Table);
const BookingDateTimeCell = dateCellFactory('beginDate');
const strings = componentStrings.entityProfiles.EntityProfilePersonBookings;
export const AdvancedSearchBookingResults: React.FC<AdvancedSearchBookingResultsProps> = ({
    bookings,
    totalCount,
    from,
    pageSize,
    handlePagination,
    errorMessage,
    loading,
    handleRowClick,
    handlePageSizeChange,
    currentPage,
}) => {
    const error = errorMessage ? (
        <th colSpan={5}>
            <InlineBanner status="error">{errorMessage}</InlineBanner>
        </th>
    ) : null;
    const size = currentPage * pageSize;
    return (
        <div>
            <ClippableSearchTable
                data={bookings}
                noRowsText={strings.noResults}
                rowComponent={TableRow}
                disableBody={loading}
                onRowClick={handleRowClick}
                containerClassName="search-results-table"
                sortKey=""
                sortType=""
            >
                <TableHeader>
                    {error ||
                        (totalCount !== undefined && (
                            <th colSpan={4} className="advanced-search-table-header">
                                <div className="header-top">
                                    <div className="size-select-container">
                                        <TableResultsSummaryContainer
                                            from={from}
                                            to={size}
                                            totalResults={totalCount}
                                            caption=""
                                            className="advanced-search-booking-summary"
                                        />
                                        <SearchSizeDropdownMenu
                                            sizes={searchSizes}
                                            onChange={handlePageSizeChange}
                                        />
                                    </div>
                                    <div className="advanced-search-table-header-pagination">
                                        {totalCount > 0 ? (
                                            <Pagination
                                                currentPage={currentPage}
                                                itemsPerPage={pageSize}
                                                itemCount={totalCount}
                                                onClick={handlePagination}
                                                maxEdgeItems={1}
                                                className={'pagination'}
                                            />
                                        ) : undefined}
                                    </div>
                                </div>
                            </th>
                        ))}
                    <TableColumns>
                        <TableColumn
                            display={strings.columns.bookingDateTime.label}
                            columnKey="bookingDateTime"
                            width={200}
                        />
                        <TableColumn
                            display={strings.columns.bookingDetails.label}
                            columnKey="details"
                            width={300}
                        />
                        <TableColumn
                            display={strings.columns.arrestingOfficer.label}
                            columnKey="arrestingOfficer"
                            width={300}
                        />
                        <TableColumn
                            display={strings.columns.eventNumber.label}
                            columnKey="eventNumber"
                            width={200}
                        />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <BookingDateTimeCell columnKey="bookingDateTime" />
                    <BookingDetailsCell showDetaineeName={true} columnKey="details" />
                    <ArrestingOfficerCell columnKey="arrestingOfficer" />
                    <EventNumberCell columnKey="eventNumber" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
};

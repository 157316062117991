import { compact, filter, find, flatMap, map, sortBy, sortedUniqBy } from 'lodash';
import { ExportPresetReportTypeView as ExportPresetReportType } from '@mark43/rms-api';
import {
    createField,
    createNItems,
    InferFormDataShape,
    createFormConfiguration,
} from 'markformythree';
import { PickByValue as Pick } from 'utility-types';
import { ReportTypePacket } from '../state/data';

export const reportTypeExportConfigsPath = 'reportTypeExportConfigs';

const reportTypeExportConfigFormConfiguration = createFormConfiguration({
    id: createField<number>({}),
    exportPresetReportTypeId: createField<number>({}),
    exportOptionDisplay: createField<string>({}),
    exportOptionName: createField<string>({}),
    isEnabled: createField<boolean>({}),
});

const reportPrintableFormConfiguration = createFormConfiguration({
    id: createField<number>({}),
    displayName: createField<string>({}),
    reportPrintingTemplateId: createField<number>({}),
    isEnabled: createField<boolean>({}),
    [reportTypeExportConfigsPath]: createNItems({
        fields: {
            ...reportTypeExportConfigFormConfiguration,
        },
    }),
});

export const exportPresetReportTypeFormConfiguration = createFormConfiguration({
    exportPresetId: createField<number>({}),
    reportDefinitionId: createField<number>({}),
    reportPrintables: createNItems({
        fields: {
            ...reportPrintableFormConfiguration,
        },
    }),
});

export type ExportPresetReportTypeFormConfiguration = typeof exportPresetReportTypeFormConfiguration;
export type ExportPresetReportTypeFormModel = InferFormDataShape<ExportPresetReportTypeFormConfiguration>;
export type ReportPrintableFormModel = InferFormDataShape<typeof reportPrintableFormConfiguration>;
export type ReportTypeExportConfigFormModel = InferFormDataShape<
    typeof reportTypeExportConfigFormConfiguration
>;

export function convertExportPresetReportTypesToFormModel(
    exportPresetId: number,
    exportPresetReportTypes: ExportPresetReportType[],
    defaultReportTypePackets: ReportTypePacket[]
): Pick<
    ExportPresetReportTypeFormModel,
    'reportDefinitionId' | 'exportPresetId' | 'reportPrintables'
>[] {
    const sortedReportTypes = sortedUniqBy(
        sortBy(exportPresetReportTypes, 'exportPosition'),
        'reportDefinitionId'
    );

    const exportPresetReportTypeFormModels = map(sortedReportTypes, ({ reportDefinitionId }) => {
        if (!reportDefinitionId) {
            return;
        }

        const defaultPrintablesForReportTypes = reportPacketsToReportPrintableFormModels(
            defaultReportTypePackets,
            reportDefinitionId,
            false
        );
        const enabledReportPrintablesForReportType = filter(exportPresetReportTypes, {
            reportDefinitionId,
        });
        const reportPrintables = reportPrintablesToFormModel(
            defaultPrintablesForReportTypes,
            enabledReportPrintablesForReportType
        );
        return {
            reportDefinitionId,
            exportPresetId,
            reportPrintables,
        };
    });

    return compact(exportPresetReportTypeFormModels);
}

export function convertExportPresetReportTypesFromFormModel(
    exportPresetId: number | undefined,
    isDefaultExportPreset: boolean,
    exportPresetReportTypes: ExportPresetReportTypeFormModel[]
): Pick<
    ExportPresetReportType,
    | 'id'
    | 'exportPresetId'
    | 'reportDefinitionId'
    | 'reportPrintingTemplateId'
    | 'reportTypeExportConfigs'
>[] {
    const selectedReportTypes = filter(
        exportPresetReportTypes,
        (exportPresetReportType) =>
            isDefaultExportPreset || !!exportPresetReportType.reportDefinitionId
    );

    const reportTypes = flatMap(selectedReportTypes, (exportPresetReportType) => {
        const reportDefinitionId = exportPresetReportType.reportDefinitionId as number;
        const reportPrintables = exportPresetReportType.reportPrintables as ReportPrintableFormModel[];
        return reportPrintablesFromFormModel(reportPrintables, exportPresetId, reportDefinitionId);
    });

    return map(reportTypes, (reportType, index) => ({
        ...reportType,
        // avoid using 0 index as exportPosition because it is falsy in javascript
        exportPosition: index + 1,
    }));
}

function reportPrintablesToFormModel(
    defaultPrintablesForReportDef: ReportPrintableFormModel[],
    enabledReportPrintablesForReportDef: ExportPresetReportType[]
): ReportPrintableFormModel[] {
    return map(defaultPrintablesForReportDef, (defaultPrintable) => {
        const enabledPrintable = find(
            enabledReportPrintablesForReportDef,
            (enabledPrintable) =>
                enabledPrintable.reportPrintingTemplateId ===
                defaultPrintable.reportPrintingTemplateId
        );

        if (!!enabledPrintable) {
            const reportTypeExportConfigs = reportTypeExportConfigsToFormModel(
                enabledPrintable,
                defaultPrintable
            );
            return {
                id: enabledPrintable.id,
                displayName: defaultPrintable.displayName,
                reportPrintingTemplateId: enabledPrintable.reportPrintingTemplateId,
                isEnabled: true,
                reportTypeExportConfigs,
            };
        } else {
            return defaultPrintable;
        }
    });
}

function reportTypeExportConfigsToFormModel(
    enabledPrintable: ExportPresetReportType,
    defaultPrintable: ReportPrintableFormModel
): ReportTypeExportConfigFormModel[] {
    return map(defaultPrintable.reportTypeExportConfigs, (exportConfig) => {
        const enabledConfig = find(
            enabledPrintable.reportTypeExportConfigs,
            (rtec) => rtec.exportOptionName === exportConfig.exportOptionName
        );
        if (!!enabledConfig) {
            return {
                id: enabledConfig.id,
                exportPresetReportTypeId: enabledConfig.exportPresetReportTypeId,
                exportOptionName: enabledConfig.exportOptionName,
                exportOptionDisplay: exportConfig.exportOptionDisplay,
                isEnabled: true,
            };
        } else {
            return exportConfig;
        }
    });
}

function reportPrintablesFromFormModel(
    reportPrintables: ReportPrintableFormModel[],
    exportPresetId: number | undefined,
    reportDefinitionId: number
): Pick<
    ExportPresetReportType,
    | 'id'
    | 'exportPresetId'
    | 'reportDefinitionId'
    | 'reportPrintingTemplateId'
    | 'reportTypeExportConfigs'
>[] {
    const enabledReportPrintables = filter(
        reportPrintables,
        (reportPrintable) => reportPrintable.isEnabled
    ) as ReportPrintableFormModel[];
    return map(enabledReportPrintables, (reportPrintable) => {
        const exportPresetReportTypeId = reportPrintable.id;
        const reportTypeExportConfigs = reportPrintable.reportTypeExportConfigs;
        const enabledConfigs = filter(
            reportTypeExportConfigs,
            (exportConfig) => exportConfig.isEnabled
        ) as ReportTypeExportConfigFormModel[];
        const enabledExportConfigs = map(enabledConfigs, (enabledConfig) => {
            return {
                id: enabledConfig.id,
                exportPresetReportTypeId,
                exportOptionName: enabledConfig.exportOptionName,
            };
        });
        return {
            id: exportPresetReportTypeId,
            exportPresetId,
            reportDefinitionId,
            reportPrintingTemplateId: reportPrintable.reportPrintingTemplateId,
            reportTypeExportConfigs: enabledExportConfigs,
        };
    });
}

export const reportPacketsToReportPrintableFormModels = (
    reportTypePackets: ReportTypePacket[],
    reportDefinitionId: number,
    enableAllOptions: boolean
): ReportPrintableFormModel[] => {
    const reportPrintablesForReportDefinition = filter(reportTypePackets, {
        reportDefinitionId,
    });
    return map(reportPrintablesForReportDefinition, (reportPrintable) => {
        const exportConfigs = map(reportPrintable.exportConfigs, (exportConfig) => {
            return {
                isEnabled: enableAllOptions,
                exportOptionName: exportConfig.exportOptionName,
                exportOptionDisplay: exportConfig.exportOptionDisplay,
            };
        });
        return {
            displayName: reportPrintable.display,
            reportPrintingTemplateId: reportPrintable.reportPrintingTemplateId,
            isEnabled: enableAllOptions,
            reportTypeExportConfigs: exportConfigs,
        };
    });
};

import { InventoryItem, InventoryItemsView } from '@mark43/evidence-api';
import { omit } from 'lodash';
import { createWithEntityItems } from 'nexus-mark43';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getInventoryItemsResource from '../../resources/inventoryItemsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const withEntityItems = createWithEntityItems({ key: 'items' });

const inventoryItems = createNormalizedModule<InventoryItem>({
    type: 'inventoryItems',
});

// ACTION TYPES
const LOAD_INVENTORY_ITEMS_START = 'inventory-items/LOAD_INVENTORY_ITEMS_START';
const LOAD_INVENTORY_ITEMS_SUCCESS = 'inventory-items/LOAD_INVENTORY_ITEMS_SUCCESS';
const LOAD_INVENTORY_ITEMS_FAILURE = 'inventory-items/LOAD_INVENTORY_ITEMS_FAILURE';
const LOAD_INVENTORY_ITEMS_BUNDLE = 'inventory-items/LOAD_INVENTORY_ITEMS_BUNDLE';

// ACTIONS
const loadInventoryItemsStart = () => ({
    type: LOAD_INVENTORY_ITEMS_START,
});

const loadInventoryItemsSuccess = () => ({
    type: LOAD_INVENTORY_ITEMS_SUCCESS,
});

const loadInventoryItemsFailure = (errorMessage: string) => ({
    type: LOAD_INVENTORY_ITEMS_FAILURE,
    payload: errorMessage,
});

const loadInventoryItemsBundle = (payload: Parameters<typeof withEntityItems>[0]) =>
    withEntityItems(payload, { type: LOAD_INVENTORY_ITEMS_BUNDLE });

/**
 * Load an inventory as well as all the items in it.
 */
export const loadInventoryItems = (
    inventoryId: number
): ClientCommonAction<Promise<InventoryItemsView>> => (dispatch) => {
    const inventoryItemsResource = getInventoryItemsResource();
    dispatch(loadInventoryItemsStart());

    return inventoryItemsResource
        .loadInventoryItems(inventoryId)
        .then((response: InventoryItemsView) => {
            // the API does not follow this rule
            // https://readme.mark43.io/guides/general-engineering/backend/api-responses/#rule-4-almost-always-arrays
            // Due to the backend API returning inventory information as an object stored as 'inventory'
            // we need to massage the data such that it will store correctly in our Redux store

            dispatch(
                loadInventoryItemsBundle({
                    ...omit(response, 'inventory'),
                    inventories: [response.inventory],
                })
            );
            dispatch(loadInventoryItemsSuccess());
            return response;
        })
        .catch((err: Error) => {
            dispatch(loadInventoryItemsFailure(err.message));
            throw err;
        });
};

// SELECTORS
export const inventoryItemsSelector = inventoryItems.selectors.entitiesSelector;

// REDUCER
export default inventoryItems.reducerConfig;

import { orderBy, get } from 'lodash';

const sortLocationEntityLinks = ({ locationEntityLinks }) => {
    return orderBy(
        locationEntityLinks,
        (locationEntityLink) => {
            // Default to empty string because when these values are null,
            // they appear at the top of the list instead of the bottom.
            // But we want them at the bottom
            return [
                get(locationEntityLink, 'startDateUtc') || '',
                get(locationEntityLink, 'enteredDateUtc') || '',
                get(locationEntityLink, 'createdDateUtc') || '',
                get(locationEntityLink, 'rmsEventId') || '',
            ];
        },
        ['desc', 'desc', 'desc']
    );
};

export default sortLocationEntityLinks;

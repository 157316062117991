import { get, isUndefined } from 'lodash';
import { ComplianceGroupEnum } from '@mark43/rms-api';
import { offenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { ukOffenseCodeExtensionByOffenseCodeIdSelector } from '~/client-common/core/domain/uk-offense-code-extensions/state/data';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';
import { currentUserDepartmentProfileSelector } from '../current-user/state/ui';
import { logWarning } from '../../../core/logging';

const warningPrefix = 'Rule Field Getter getOffenseCodeFlag:';

export const getOffenseCodeFlag = (getState: () => RootState) => (
    offenseCodeId: number,
    { flag }: { flag?: string } = {}
) => {
    if (isUndefined(flag)) {
        logWarning(`${warningPrefix} missing rule condition argument for flag`);
        return false;
    }

    const state = getState();
    const offenseCode = offenseCodeByIdSelector(state)(offenseCodeId);

    if (isUndefined(offenseCode)) {
        return false;
    }

    const departmentProfile = currentUserDepartmentProfileSelector(state);
    if (departmentProfile?.complianceGroup === ComplianceGroupEnum.UNITED_KINGDOM.name) {
        const offenseCodeExtension = ukOffenseCodeExtensionByOffenseCodeIdSelector(state)(
            offenseCode.id
        );

        return !!get({ ...offenseCodeExtension, ...offenseCode }, flag);
    }

    return !!get(offenseCode, flag);
};

import { OrganizationProfile } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'societyProfiles';

// not a typo
const societyProfilesModule = createNormalizedModule<OrganizationProfile>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
export const societyProfilesSelector = societyProfilesModule.selectors.entitiesSelector;
export const societyProfilesWhereSelector = societyProfilesModule.selectors.entitiesWhereSelector;

// REDUCER
export default societyProfilesModule.reducerConfig;

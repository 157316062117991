import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

const BASE_URL = '/cad/api';
const BASE_PATH = 'rotation';

export default createResource({
    name: 'Cad ServiceType Resource',
    methods: {
        getCadServiceTypes() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_types/true`,
            });
        },
        createCadServiceType(cadServiceType) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_type`,
                data: cadServiceType,
            });
        },
        updateCadServiceType(cadServiceType) {
            return req({
                method: 'POST',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/service_type`,
                data: cadServiceType,
            });
        },
        getCadServiceTypeHistory(serviceTypeId) {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/${serviceTypeId}/histories`,
            });
        },
        getAllCadServiceTypesHistory() {
            return req({
                method: 'GET',
                baseUrl: BASE_URL,
                url: `${BASE_PATH}/histories`,
            });
        },

        /**
         * Export all serviceTypes into a CSV.
         * @return {Promise}
         */
        exportServiceTypes() {
            return req({
                baseUrl: BASE_URL,
                method: 'GET',
                url: `${BASE_PATH}/export`,
            });
        },

        /**
         * Validate that a file is valid for importing new serviceTypes.
         * @return {Promise}
         */
        validateServiceTypeImport(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/validate/${fileId}`,
            });
        },

        /**
         * Import serviceTypes from an uploaded file.
         * @return {Promise}
         */
        importServiceTypes(fileId) {
            return req({
                baseUrl: BASE_URL,
                method: 'POST',
                url: `${BASE_PATH}/import/${fileId}`,
            });
        },
    },
});

import { Store } from 'redux';
import { ElasticVehicle } from '@mark43/rms-api';
import { iconPaths } from '@arc/icon';
import {
    formatAttributeByIdSelector,
    parentAttributeIdByAttributeIdSelector,
} from '~/client-common/core/domain/attributes/state/data';
import { formatTitleForElasticVehicleSelector } from '~/client-common/core/domain/elastic-vehicles/state/ui';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

import { RootState } from '../../../../../../../legacy-redux/reducers/rootReducer';
import {
    prepareEnhancedCautions,
    sortCustomAndEnhancedCautions,
} from '../../../../../cautions/helpers';
import { prepareVehicleCustomCautions } from '../../../../../cautions/helpers/customCautions';
import { getPrioritizedVehicleLabelAttributes } from '../../../../../components/tags/helpers';
import { FormatMentionItemMapper, Mention, MentionCautions } from '../types';
import {
    getDefaultAvatarIcon,
    getMentionCautions,
    getMentionEntityLinkUrl,
    getTruthyValues,
} from '../utils';

const prepareMentionedItem = (item: ElasticVehicle, store: Store<RootState>): Mention => {
    const formatTitleForElasticVehicle = formatTitleForElasticVehicleSelector(store.getState());
    const formatAttributeById = formatAttributeByIdSelector(store.getState());
    const parentAttributeIdByAttributeId = parentAttributeIdByAttributeIdSelector(store.getState());
    const {
        RMS_VEHICLE_CAUTIONS_ENABLED,
        RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED,
    } = applicationSettingsSelector(store.getState());

    const name = formatTitleForElasticVehicle(item);
    const descriptions = getTruthyValues([
        item.tag,
        item.vehicle?.primaryColorAttrDetail?.displayValue,
    ]);

    let cautions: MentionCautions | undefined;

    if (RMS_VEHICLE_CAUTIONS_ENABLED) {
        const cautionLabels = getPrioritizedVehicleLabelAttributes({
            elasticItemAttributes: item.itemAttributes,
            isStolen: item.isStolen,
            formatAttributeById,
            parentAttributeIdByAttributeId,
        });

        cautions = getMentionCautions(cautionLabels);
    }

    if (RMS_VEHICLE_CAUTIONS_ENHANCEMENTS_ENABLED) {
        const customCautions = prepareVehicleCustomCautions({
            isStolen: item.isStolen,
        });
        const { enhancedCautions } = prepareEnhancedCautions({
            cautions: item.cautions,
            formatAttributeById,
            parentAttributeIdByAttributeId,
        });
        const sortedCautions = sortCustomAndEnhancedCautions({
            customCautions,
            enhancedCautions,
        });
        cautions = getMentionCautions(sortedCautions);
    }

    return {
        id: item.id.toString(),
        name,
        text: name,
        descriptions,
        category: 'VEHICLE',
        photo: item.primaryProfilePhotoPath,
        defaultIcon: getDefaultAvatarIcon(iconPaths.Vehicle),
        cautions,
        url: getMentionEntityLinkUrl({ itemType: 'VEHICLE', itemId: item.id }),
    };
};

const formatMapper: FormatMentionItemMapper<ElasticVehicle> = {
    resourceMethod: 'searchVehicles',
    prepareMentionedItem,
};

export default formatMapper;

import React from 'react';
import { connect } from 'react-redux';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import ItemPopover from '../../../reports/core/components/ItemPopover';
import { itemSplitItemPopoverSubmit } from '../state/ui';

const context = {
    name: boxEnum.ITEM_SPLIT_ITEM_POPOVER,
};

const mapDispatchToProps = { onSubmit: itemSplitItemPopoverSubmit };

export default connect(
    null,
    mapDispatchToProps
)(({ onSubmit }) => <ItemPopover context={context} onSubmit={onSubmit} />);

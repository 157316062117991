import { DeepPartial } from 'utility-types';
import { map, sortBy } from 'lodash';
import { ExportPresetView } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getExportPresetsResource from '../../resources/exportPresetsResource';
import { ClientCommonAction } from '../../../../../redux/types';

const NEXUS_STATE_PROP = 'exportPresets';
const exportPresetsModule = createNormalizedModule<ExportPresetView>({
    type: NEXUS_STATE_PROP,
});

export const LOAD_EXPORT_PRESETS_START = 'export-presets/LOAD_EXPORT_PRESETS_START' as const;
export const LOAD_EXPORT_PRESETS_SUCCESS = 'export-presets/LOAD_EXPORT_PRESETS_SUCCESS' as const;
export const LOAD_EXPORT_PRESETS_FAILURE = 'export-presets/LOAD_EXPORT_PRESETS_FAILURE' as const;
export const SAVE_EXPORT_PRESET_START = 'export-presets/SAVE_EXPORT_PRESET_START' as const;
export const SAVE_EXPORT_PRESET_SUCCESS = 'export-presets/SAVE_EXPORT_PRESET_SUCCESS' as const;
export const SAVE_EXPORT_PRESET_FAILURE = 'export-presets/SAVE_EXPORT_PRESET_FAILURE' as const;
export const REMOVE_EXPORT_PRESET_START = 'export-presets/REMOVE_EXPORT_PRESET_START' as const;
export const REMOVE_EXPORT_PRESET_SUCCESS = 'export-presets/REMOVE_EXPORT_PRESET_SUCCESS' as const;
export const REMOVE_EXPORT_PRESET_FAILURE = 'export-presets/REMOVE_EXPORT_PRESET_FAILURE' as const;

function loadExportPresetsStart() {
    return {
        type: LOAD_EXPORT_PRESETS_START,
    };
}

function loadExportPresetsSuccess(exportPresets: ExportPresetView[]) {
    return {
        type: LOAD_EXPORT_PRESETS_SUCCESS,
        payload: exportPresets,
    };
}

function loadExportPresetsFailure(errMsg: string) {
    return { type: LOAD_EXPORT_PRESETS_FAILURE, payload: errMsg };
}

function saveExportPresetStart() {
    return { type: SAVE_EXPORT_PRESET_START };
}

function saveExportPresetSuccess() {
    return { type: SAVE_EXPORT_PRESET_SUCCESS };
}

export function saveExportPresetFailure(errMsg: string) {
    return { type: SAVE_EXPORT_PRESET_FAILURE, payload: errMsg };
}

function removeExportPresetStart() {
    return { type: REMOVE_EXPORT_PRESET_START };
}

function removeExportPresetSuccess() {
    return { type: REMOVE_EXPORT_PRESET_SUCCESS };
}

function removeExportPresetFailure(errMsg: string) {
    return { type: REMOVE_EXPORT_PRESET_FAILURE, payload: errMsg };
}

export type ExportPresetAction =
    | ReturnType<typeof loadExportPresetsStart>
    | ReturnType<typeof loadExportPresetsSuccess>
    | ReturnType<typeof loadExportPresetsFailure>
    | ReturnType<typeof saveExportPresetStart>
    | ReturnType<typeof saveExportPresetSuccess>
    | ReturnType<typeof saveExportPresetFailure>
    | ReturnType<typeof removeExportPresetStart>
    | ReturnType<typeof removeExportPresetSuccess>
    | ReturnType<typeof removeExportPresetFailure>;

export function loadExportPresets(): ClientCommonAction<Promise<void>> {
    const exportPresetsResource = getExportPresetsResource();
    return function (dispatch, getState, { nexus }) {
        dispatch(loadExportPresetsStart());
        return exportPresetsResource
            .getAllExportPresets()
            .then((exportPresets: ExportPresetView[]) => {
                const sortedReportTypes = map(exportPresets, (preset) => {
                    const exportPresetReportTypes = sortBy(
                        preset.exportPresetReportTypes,
                        'exportPosition'
                    );
                    return { ...preset, exportPresetReportTypes };
                });

                dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: sortedReportTypes,
                        },
                        loadExportPresetsSuccess(exportPresets)
                    )
                );
            })
            .catch((err: Error) => dispatch(loadExportPresetsFailure(err.message)));
    };
}

export function saveExportPreset(
    exportPreset: DeepPartial<ExportPresetView>
): ClientCommonAction<Promise<ExportPresetView>> {
    return function (dispatch, getState, { nexus }) {
        dispatch(saveExportPresetStart());
        const resource = getExportPresetsResource();
        return resource.upsertExportPreset(exportPreset).then((exportPreset: ExportPresetView) => {
            dispatch(
                nexus.withEntityItems(
                    {
                        [NEXUS_STATE_PROP]: [exportPreset],
                    },
                    saveExportPresetSuccess()
                )
            );
            return exportPreset;
        });
    };
}

export function removeExportPreset(exportPresetId: number): ClientCommonAction<Promise<void>> {
    return function (dispatch, getState, { nexus }) {
        dispatch(removeExportPresetStart());
        const resource = getExportPresetsResource();
        return resource
            .deleteExportPresets([exportPresetId])
            .then(() =>
                dispatch(
                    nexus.withRemove(
                        NEXUS_STATE_PROP,
                        { id: exportPresetId },
                        removeExportPresetSuccess()
                    )
                )
            )
            .catch((err: Error) => dispatch(removeExportPresetFailure(err.message)));
    };
}

export const exportPresetsSelector = exportPresetsModule.selectors.entitiesSelector;
export const exportPresetsByIdSelector = exportPresetsModule.selectors.entityByIdSelector;

export default exportPresetsModule.reducerConfig;

import { includes } from 'lodash';
import thunk from 'redux-thunk';
import createLogger from 'redux-logger';
import { bootstrapMiddleware } from './bootstrapMiddleware';

import { createSideEffectsMiddleware } from './side-effects/createSideEffectsMiddleware';
import { analyticsMiddleware } from './analyticsMiddleware';

export function getMiddlewares({ dependencies } = {}) {
    const middlewares = [
        analyticsMiddleware(dependencies),
        bootstrapMiddleware(dependencies),
        createSideEffectsMiddleware(dependencies),
        thunk.withExtraArgument(dependencies),
    ];

    // set this to true to turn off the logs
    const turnLogsOff = process.env.MARK43_DISABLE_DEV_REDUX_LOGGER === 'true';

    const logLevel = {
        log: 'log',
        console: 'console',
        warn: 'warn',
        error: 'error',
        info: 'info',
    };

    if (process.env.NODE_ENV === 'development') {
        // ignore these actions types which fire a lot; this list is similar to
        // sessionHistoryReducer.js
        const ignoredActionTypes = [
            'global_SHOW_HIDE_LOADING_BAR',
            'core/STORE_userNotificationsS',
            'user-notifications/POLL_FOR_USER_NOTIFICATIONS_SUCCESS',
            'cobalt-notifications/SET_PREVIOUS_POLL_DATE',
            'current-viewers/LOAD_CURRENT_VIEWERS_SUCCESS',
        ];

        const logger = createLogger({
            level: process.env.MARK43_DEV_REDUX_LOGGER_LEVEL || logLevel.info,
            collapsed: true,
            predicate: (getState, action) =>
                !turnLogsOff && !includes(ignoredActionTypes, action.type),
        });
        middlewares.push(logger);
    }

    return middlewares;
}

import { OperationTypeEnum } from '@mark43/rms-api';
import { includes } from 'lodash';

export function canFindOnly(permissionSet) {
    return (
        permissionSet &&
        permissionSet.length === 1 &&
        permissionSet[0] === OperationTypeEnum.SEARCH.name
    );
}

export function canRead(permissionSet) {
    return includes(permissionSet, OperationTypeEnum.READ.name);
}

export function canWrite(permissionSet) {
    return includes(permissionSet, OperationTypeEnum.WRITE.name);
}

export function canEdit(permissionSet) {
    return includes(permissionSet, OperationTypeEnum.DELETE.name);
}

export function canManage(permissionSet) {
    return includes(permissionSet, OperationTypeEnum.MANAGE.name);
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal from '../../../../../legacy-redux/components/core/Modal';
import { closeBox } from '../../../../../legacy-redux/actions/boxActions';

import { createModalSelector } from '../../../../core/box/state/ui';

import { sendReportToBooking } from '../../state/ui';

const strings = componentStrings.reports.core.ReportSendArrestToBookingModal;

const context = {
    name: boxEnum.REPORT_SEND_ARREST_TO_BOOKING_MODAL,
};

function ReportSendArrestToBookingModal({ closeBox, reportId, sendReportToBooking }) {
    return (
        <Modal
            context={context}
            onClose={closeBox}
            okText={strings.okText}
            title={strings.title}
            onSave={() => sendReportToBooking(reportId)}
        >
            {strings.message}
        </Modal>
    );
}

const mapDispatchToProps = (dispatch) => ({
    closeBox: () => dispatch(closeBox(context)),
    sendReportToBooking: (reportId) => dispatch(sendReportToBooking(reportId)),
});
const mapStateToProps = createStructuredSelector({
    reportId: createModalSelector(context, 'reportId'),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportSendArrestToBookingModal);

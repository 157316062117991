import React from 'react';
import { map } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import { DashedDivider } from '../../../../legacy-redux/components/core/Divider';

/**
 * Converts identifiers to an array of `SummaryRow`s.
 *
 * NOTE This is a helper and not a component as React currently does not
 * allow returning of fragments
 *
 * FIXME when React 16 is released
 *
 * @param  {array} identifiers An array of identifiers
 * @return {array}             An array of `SummaryRow`s
 */
export const convertIdentifiersToComponentArray = (identifiers, identifierTypeFieldName) =>
    map(identifiers, (identifierObj) => (
        <SummaryRow
            key={identifierObj.id}
            label={getViewModelProperties(identifierObj)[identifierTypeFieldName]}
        >
            {identifierObj.identifier}
        </SummaryRow>
    ));

export const convertPassportsToComponentArray = (passports) =>
    map(passports, (passport, index) => (
        <div key={passport.id}>
            {index !== 0 && <DashedDivider />}
            <SummaryRow fieldName={fields.PASSPORT_NUMBER}>{passport.number}</SummaryRow>
            <SummaryRow fieldName={fields.PASSPORT_DATE_OF_EXPIRY}>
                {passport.dateOfExpiry}
            </SummaryRow>
            <SummaryRow fieldName={fields.PASSPORT_DATE_OF_ISSUE}>
                {passport.dateOfIssue}
            </SummaryRow>
            <SummaryRow fieldName={fields.PASSPORT_COUNTRY}>{passport.country}</SummaryRow>
        </div>
    ));

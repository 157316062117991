import React from 'react';

export default function createNFieldsets(NItems) {
    return function NFieldsets({
        title,
        children,
        renderDeleteButton,
        className,
        removeItem,
        ...props
    }) {
        return (
            <NItems
                className={className}
                hideAddButtonOnEmptyItem={true}
                removeItem={removeItem}
                {...props}
            >
                {(fields, index) =>
                    children({
                        fields,
                        index,
                        deleteButton: renderDeleteButton(removeItem, index),
                        title: index > 0 && title && title(index),
                    })
                }
            </NItems>
        );
    };
}

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, defaultProps, setPropTypes } from 'recompose';
import keyMirror from 'keymirror';
import { AttributeTypeEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useOffenseFieldName } from '~/client-common/core/fields/hooks/useFields';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { attributeOptionsByTypeForCurrentDepartmentSelector } from '../../../attributes/state/ui';
import { SelectOption } from '../../helpers/selectHelpers';
import MultiFieldSelect from './MultiFieldSelect';
import type { SelectProps } from './Select';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.select.OffenseCaseStatusAttrSelect;

/**
 * Select dropdown containing all Offense Case Status codes as well as "No Offense Case Status",
 * and "Any Offense Case Status" options
 * (modeled by `hasOffenseCaseStatus = false/true`).
 * @param {Object} props Same props as `Select`.
 */
const OffenseCaseStatusAttrSelectInner: React.FC<
    SelectProps<SelectOption, true> & {
        fields: unknown;
        attributeOptionsByTypeForCurrentDepartment: unknown;
        label: string;
    }
> = (props) => {
    const { fields, attributeOptionsByTypeForCurrentDepartment, label, ...otherProps } = props;

    // @ts-expect-error TODO: type
    const opts = attributeOptionsByTypeForCurrentDepartment({
        type: AttributeTypeEnum.OFFENSE_CASE_STATUS.name,
        includeExpired: true,
        additionalIds: [],
        includeAbbr: true,
    });

    const offenseDisplayName = useOffenseFieldName();

    return (
        // @ts-expect-error TODO: type
        <MultiFieldSelect
            label={label || strings.label(offenseDisplayName)}
            {...otherProps}
            multiple={false}
            fields={{
                hasOffenseCaseStatus: {
                    options: [
                        {
                            value: false,
                            display: strings.options.hasOffenseCaseStatus.false(offenseDisplayName),
                            none: true,
                        },
                        {
                            value: true,
                            display: strings.options.hasOffenseCaseStatus.true(offenseDisplayName),
                            none: true,
                        },
                    ],
                    // @ts-expect-error TODO: type
                    ...fields?.hasOffenseCaseStatus,
                },
                offenseCaseStatusAttrId: {
                    options: opts,
                    // @ts-expect-error TODO: type
                    ...fields?.offenseCaseStatusAttrId,
                },
            }}
        />
    );
};

const OffenseCaseStatusAttrSelect = connect(
    createStructuredSelector({
        attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
    })
)(OffenseCaseStatusAttrSelectInner);

export const RRFOffenseCaseStatusAttrSelect = compose(
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            hasOffenseCaseStatus: null,
            offenseCaseStatusAttrId: null,
        }),
    }),
    connectRRFMultiFieldInput
    // @ts-expect-error TODO: type
)(OffenseCaseStatusAttrSelect);

import groupBy from 'lodash/groupBy';
import { AttributeView } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatAttributeValue } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

import { sanitizeTinyMCEValue } from '../../core/editor/utils/tinyEditor';
import { withTinyEditor } from '../../core/editor/utils/withTinyEditor';

import { SUPPORTED_SUBDIVISION_TYPES } from '../configuration';

const strings = componentStrings.briefings.core;

export const getEditorIdByBriefingId = (briefingId: number) => `briefing-${briefingId}`;

export const getEditorContentByBriefingId = (briefingId: number) => {
    const editorId = getEditorIdByBriefingId(briefingId);
    const editor = withTinyEditor(editorId, 'SYNCHRONOUS_OR_THROW');
    return sanitizeTinyMCEValue(editor.getContent() || '');
};

/**
 * Returns briefing location based on subdivision attribute values
 * If there are multiple levels of subdivision, returns the highest level
 * If there are multiple attributes of the same subdivision, returns 1 value +X
 */
export const getBriefingLocationBySubdivisions = (attributes: AttributeView[]) => {
    const subdivisions = attributes.filter(({ type }) =>
        SUPPORTED_SUBDIVISION_TYPES.some((val) => val === type)
    );
    if (!subdivisions.length) {
        return null;
    }
    const levelsMap = groupBy(subdivisions, 'type');

    // SUBDIVISION_DEPTH_1 should be considered higher than SUBDIVISION_DEPTH_2
    const sortedLevels = Object.keys(levelsMap).sort();
    const highestLevel = sortedLevels[0];

    const highestSubs = levelsMap[highestLevel];
    if (highestSubs.length === 1) {
        return formatAttributeValue(highestSubs[0]);
    } else {
        return `${formatAttributeValue(highestSubs[0])} +${highestSubs.length - 1}`;
    }
};

export const getDuplicateBriefingTitle = (originalTitle: string) =>
    `${strings.duplicateBriefingPrefix} ${originalTitle}`;

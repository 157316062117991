import { ReportLegacyMetadata } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { head } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'reportLegacyMetadatas';

const reportLegacyMetadataModule = createNormalizedModule<ReportLegacyMetadata>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
const reportLegacyMetadataWhereSelector =
    reportLegacyMetadataModule.selectors.entitiesWhereSelector;

export const reportLegacyMetadataByReportIdSelector = createSelector(
    reportLegacyMetadataWhereSelector,
    (reportLegacyMetadataWhere) => (reportId: number) =>
        head(reportLegacyMetadataWhere({ reportId }))
);

// REDUCER
export default reportLegacyMetadataModule.reducerConfig;

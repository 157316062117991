import React, { FC } from 'react';
import styled from 'styled-components';
import { Accordion, AccordionHeader, AccordionContent, AccordionItem } from 'arc';
import { ElasticCase } from '@mark43/rms-api';

import { DISPLAY_ONLY_TARGET_PROFILE } from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import CaseTargetProfileDetails from '../../../../modules/cases/case-summary/components/CaseTargetProfileDetails';

const StyledRow = styled.div`
    padding-left: 170px;
`;

const StyledAccordionItem = styled(AccordionItem)`
    border-bottom: 0;
`;

const accordionValue = 'Target profile details';

type TargetProfilesDetailsRowProps = {
    row: ElasticCase;
    className?: string;
};

export const TargetProfilesDetailsRow: FC<TargetProfilesDetailsRowProps> = ({ row, className }) => {
    const caseAttributes = [
        ...row.targetProfileAreaAttrDetails,
        ...row.targetProfileCategoryAttrDetails,
        row.targetProfilePriorityAttrDetail,
    ];

    const fields = useFields([DISPLAY_ONLY_TARGET_PROFILE]);

    return (
        <StyledRow className={className} onClick={(ev) => ev.stopPropagation()}>
            <Accordion>
                <StyledAccordionItem value={accordionValue}>
                    <AccordionHeader>{fields.DISPLAY_ONLY_TARGET_PROFILE}</AccordionHeader>
                    <AccordionContent>
                        <CaseTargetProfileDetails
                            caseAttributes={caseAttributes}
                            reviewDate={row.targetProfileReviewDateUtc}
                            narrative={row.targetProfileNarrative}
                        />
                    </AccordionContent>
                </StyledAccordionItem>
            </Accordion>
        </StyledRow>
    );
};

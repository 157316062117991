import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { debounce } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
} from '~/client-common/core/fields/state/config';
import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import {
    WARRANT_SUBJECT_PERSON_PROFILE_ID,
    PERSON_PROFILE_FIRST_NAME,
    PERSON_PROFILE_LAST_NAME,
    PERSON_PROFILE_MIDDLE_NAME,
    PERSON_PROFILE_DATE_OF_BIRTH,
    WARRANT_WARRANT_ISSUED_DATE_UTC,
    WARRANT_WARRANT_NUMBER,
    WARRANT_COURT_CASE_NUMBER,
    WARRANT_STATUS_WARRANT_STATUS_ATTR_ID,
    WARRANT_WARRANT_TYPE_ATTR_ID,
    WARRANT_ISSUING_AGENCY_NAME_ATTR_ID,
    WARRANT_ATTRIBUTE_ATTRIBUTE_TYPE_WARRANT_STATISTIC_ATTRIBUTE_ID,
    WARRANT_CHARGE_OFFENSE_CLASSIFICATION_ATTR_ID,
    WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER,
    WARRANT_DEX_SUBMISSION_NCIC_NUMBER,
    WARRANT_DEX_SUBMISSION_INTERNAL_WARRANT_STATUS_ATTR_ID,
    WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID,
    WARRANT_DEX_SUBMISSION_DEX_WARRANT_TYPE_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';

// Redux
import {
    warrantsDashboardSearch,
    submitWarrantsDashboardSearchForm,
    clearAllFilters,
} from '../state/ui';

// components
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import FormSection from '../../../core/forms/components/FormSection';
import Row from '../../../core/components/Row';
import Fieldset from '../../../core/forms/components/Fieldset';
import { RRFNItems } from '../../../core/forms/components/NItems';
import { RRFBooleanSelect } from '../../../core/forms/components/selects/BooleanSelect';
import { RRFDatePicker } from '../../../core/forms/components/DatePicker';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { RRFAsyncText } from '../../../core/forms/components/AsyncText';
import { RRFAttributeSelect } from '../../../core/forms/components/selects/AttributeSelect';
import { RRFWarrantStatusAttributeSelect } from '../../../core/forms/components/selects/WarrantStatusAttributeSelect';
import { RRFConsortiumDepartmentSelect } from '../../../core/forms/components/selects/ConsortiumDepartmentSelect';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import { SearchName } from '../../../search/core/components/SearchName';
import useOnSaveSearchToggleHandler from '../../../search/core/hooks/useOnSaveSearchToggleHandler';
import useSaveAsNewSearch from '../../../search/core/hooks/useSaveAsNewSearch';
import CreateSavedSearchModal from '../../../search/saved-search/components/CreateSavedSearchModal';
import { DeleteSavedSearchConfirmationModal } from '../../../search/saved-search/components/SavedSearchConfirmationModals';
import { SavedSearchDropdownButton } from '../../../search/saved-search/components/SavedSearchDropdownButton';
import { SubdivisionsSearch } from '../../../search/core/components/SubdivisionsSearch';

const strings = componentStrings.warrants.dashboard.searchForm;

const WarrantsDashboardSearchFormContainer = styled(ReactReduxForm)`
    background: ${(props) => props.theme.colors.white};
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const WarrantsDashboardSearchFormSection = styled(FormSection)`
    && {
        padding-top: var(--arc-space-2);
        padding-bottom: 22px;
    }
`;

const FooterLeft = styled.div`
    margin: 15px 0 0;
    display: flex;
    align-items: end;
`;

const ClearFiltersButton = styled(Button)`
    margin-left: 8px;
    color: ${(props) => props.theme.colors.highContrastGrey};
    padding: 6px 0;
`;

const SaveSearchButton = styled(Button)`
    display: inline-block;
    padding: 6px 0;
`;

function WarrantsDashboardSearchForm({
    onSubmit,
    onClearFilters,
    formatFieldByName,
    formIsEmpty,
    query,
    combinedSubdivisionsLabel,
    consortiumDepartmentLinksAvailable,
    currentSavedSearch,
}) {
    const isSavedSearch = query.savedSearchId && !query.isAutoSave && !query.isShared;
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(warrantsDashboardSearch);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const savedSearchSharingEnabled = applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED;
    const saveAsNewSearchHandler = useSaveAsNewSearch(warrantsDashboardSearch);

    return (
        <WarrantsDashboardSearchFormContainer {...warrantsDashboardSearch.form}>
            <WarrantsDashboardSearchFormSection>
                <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={false} />
                <Fieldset
                    title={strings.subjectFieldset(
                        formatFieldByName(WARRANT_SUBJECT_PERSON_PROFILE_ID)
                    )}
                >
                    <Row>
                        <RRFAsyncText
                            label={formatFieldByName(PERSON_PROFILE_FIRST_NAME)}
                            width={220}
                            path="subjectFirstName"
                            asyncAction={onSubmit}
                            typeaheadThrottle={600}
                        />
                        <RRFAsyncText
                            label={formatFieldByName(PERSON_PROFILE_LAST_NAME)}
                            width={220}
                            path="subjectLastName"
                            asyncAction={onSubmit}
                            typeaheadThrottle={600}
                        />
                        <RRFAsyncText
                            label={formatFieldByName(PERSON_PROFILE_MIDDLE_NAME)}
                            width={220}
                            path="subjectMiddleName"
                            asyncAction={onSubmit}
                            typeaheadThrottle={600}
                        />
                        <RRFDatePicker
                            label={formatFieldByName(PERSON_PROFILE_DATE_OF_BIRTH)}
                            dateWidth={220}
                            path="subjectDateOfBirth"
                            variant={RRFDatePicker.variants.LOCAL_DATE}
                            onChange={onSubmit}
                        />
                    </Row>
                    <Row>
                        <RRFNItems
                            path="subjectIdentifiers"
                            addItemOnDirty={true}
                            addItemOnEmpty={true}
                            onRemoveItem={onSubmit}
                        >
                            {(identifier, index, deleteButton) => (
                                <Row>
                                    <RRFAttributeSelect
                                        path="nameIdentifierTypeAttrId"
                                        label={strings.labels.nameIdentifierTypeAttrId}
                                        attributeType={AttributeTypeEnum.NAME_IDENTIFIER_TYPE.name}
                                        width={220}
                                        onChange={onSubmit}
                                        includeExpired={true}
                                    />
                                    <RRFAsyncText
                                        label={strings.labels.idValue}
                                        width={220}
                                        path="idValue"
                                        asyncAction={onSubmit}
                                        typeaheadThrottle={600}
                                    />
                                    {deleteButton}
                                </Row>
                            )}
                        </RRFNItems>
                    </Row>
                </Fieldset>
                <Fieldset title={strings.warrantDetailsFieldset}>
                    <Row>
                        <RRFDateRangePicker
                            includeTime={false}
                            width={220}
                            granularity="day"
                            withinLastPeriodOptions={['PT12H', 'PT24H', 'P7D', 'P14D', 'P28D']}
                            toDatePeriodOptions={['P1M', 'P1Y']}
                            paths={{
                                withinLastPeriod: 'warrantIssuedWithinLastPeriod',
                                toDatePeriod: 'warrantIssuedToDatePeriod',
                                startDateUtc: 'warrantIssuedStartDateUtc',
                                endDateUtc: 'warrantIssuedEndDateUtc',
                            }}
                            label={formatFieldByName(WARRANT_WARRANT_ISSUED_DATE_UTC)}
                            onChange={onSubmit}
                        />
                        <RRFAsyncText
                            label={formatFieldByName(WARRANT_WARRANT_NUMBER)}
                            width={220}
                            path="warrantNum"
                            asyncAction={onSubmit}
                            typeaheadThrottle={600}
                        />
                        <RRFAsyncText
                            label={formatFieldByName(WARRANT_COURT_CASE_NUMBER)}
                            width={220}
                            path="courtCaseNumber"
                            asyncAction={onSubmit}
                            typeaheadThrottle={600}
                        />
                        <RRFWarrantStatusAttributeSelect
                            path="warrantStatusAttrIds"
                            label={formatFieldByName(WARRANT_STATUS_WARRANT_STATUS_ATTR_ID)}
                            width={220}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                    </Row>
                    <Row>
                        <RRFAttributeSelect
                            path="warrantTypeAttrIds"
                            label={formatFieldByName(WARRANT_WARRANT_TYPE_ATTR_ID)}
                            attributeType={AttributeTypeEnum.WARRANT_TYPE.name}
                            width={220}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                        <FeatureFlagged
                            flag="RMS_SUBDIVISION_SEARCH_ENHANCEMENTS_ENABLED"
                            fallback={
                                <RRFAttributeSelect
                                    path="warrantLocationSubdivisionAttrIds"
                                    label={combinedSubdivisionsLabel}
                                    attributeType={[
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_1.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_2.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_3.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_4.name,
                                        AttributeTypeEnum.SUBDIVISION_DEPTH_5.name,
                                    ]}
                                    width={220}
                                    multiple={true}
                                    includeExpired={true}
                                    onChange={onSubmit}
                                />
                            }
                        />
                        <RRFAttributeSelect
                            path="issuingAgencyNameAttrIds"
                            label={formatFieldByName(WARRANT_ISSUING_AGENCY_NAME_ATTR_ID)}
                            width={220}
                            attributeType={AttributeTypeEnum.ISSUING_AGENCY_NAME.name}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                        <RRFAttributeSelect
                            path="warrantStatisticAttrIds"
                            label={formatFieldByName(
                                WARRANT_ATTRIBUTE_ATTRIBUTE_TYPE_WARRANT_STATISTIC_ATTRIBUTE_ID
                            )}
                            attributeType={AttributeTypeEnum.WARRANT_STATISTIC.name}
                            width={220}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                    </Row>
                    <Row>
                        <RRFBooleanSelect
                            path="isLinkedToArrest"
                            label={strings.labels.isLinkedToArrest}
                            width={220}
                            onChange={onSubmit}
                        />
                        <RRFAsyncText
                            label={formatFieldByName(
                                WARRANT_DEX_SUBMISSION_REGIONAL_MESSAGE_SWITCH_NUMBER
                            )}
                            width={220}
                            path="dexRegionalMessageSwitchNumber"
                            asyncAction={onSubmit}
                            typeaheadThrottle={600}
                        />
                        <RRFAsyncText
                            label={formatFieldByName(WARRANT_DEX_SUBMISSION_NCIC_NUMBER)}
                            width={220}
                            path="dexNcicNumber"
                            asyncAction={onSubmit}
                            typeaheadThrottle={600}
                        />
                        <RRFAttributeSelect
                            path="offenseClassificationAttrIds"
                            label={formatFieldByName(WARRANT_CHARGE_OFFENSE_CLASSIFICATION_ATTR_ID)}
                            attributeType={AttributeTypeEnum.OFFENSE_CLASSIFICATION.name}
                            width={220}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                        {consortiumDepartmentLinksAvailable && (
                            <RRFConsortiumDepartmentSelect
                                path="departmentIds"
                                label={strings.labels.agency}
                                multiple={true}
                                width={230}
                                onChange={onSubmit}
                            />
                        )}
                    </Row>
                    <Row>
                        <SubdivisionsSearch width={220} onChange={onSubmit} />
                    </Row>
                </Fieldset>
                <Fieldset title={strings.recordsFieldset}>
                    <Row>
                        <RRFAttributeSelect
                            path="dexInternalWarrantStatusAttrIds"
                            label={formatFieldByName(
                                WARRANT_DEX_SUBMISSION_INTERNAL_WARRANT_STATUS_ATTR_ID
                            )}
                            attributeType={AttributeTypeEnum.INTERNAL_WARRANT_STATUS.name}
                            width={220}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                        <RRFAttributeSelect
                            path="dexWarrantEntryLevelCodeAttrIds"
                            label={formatFieldByName(
                                WARRANT_DEX_SUBMISSION_WARRANT_ENTRY_LEVEL_CODE_ATTR_ID
                            )}
                            attributeType={AttributeTypeEnum.WARRANT_ENTRY_LEVEL_CODE.name}
                            width={220}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                        <RRFAttributeSelect
                            path="dexWarrantTypeAttrIds"
                            label={formatFieldByName(
                                WARRANT_DEX_SUBMISSION_DEX_WARRANT_TYPE_ATTR_ID
                            )}
                            attributeType={AttributeTypeEnum.DEX_WARRANT_TYPE.name}
                            width={220}
                            multiple={true}
                            includeExpired={true}
                            onChange={onSubmit}
                        />
                    </Row>
                </Fieldset>
                <FooterLeft>
                    <SavedSearchDropdownButton searchModule={warrantsDashboardSearch} />
                    <FeatureFlagged
                        flag="RMS_SAVED_SEARCH_SHARING_ENABLED"
                        fallback={
                            <SaveSearchButton
                                className={buttonTypes.ICON_LINK}
                                iconLeft={
                                    <Icon
                                        type={
                                            isSavedSearch
                                                ? iconTypes.SAVED_SEARCH_ACTIVE
                                                : iconTypes.SAVED_SEARCH_INACTIVE
                                        }
                                    />
                                }
                                hoveredIconLeft={
                                    <Icon
                                        type={
                                            isSavedSearch
                                                ? iconTypes.SAVED_SEARCH_INACTIVE
                                                : iconTypes.SAVED_SEARCH_ACTIVE
                                        }
                                    />
                                }
                                onClick={onSaveSearchToggle}
                                disabled={formIsEmpty}
                            >
                                {isSavedSearch
                                    ? strings.footer.unsaveSearch
                                    : strings.footer.saveSearch}
                            </SaveSearchButton>
                        }
                    />
                    <CreateSavedSearchModal
                        onSave={
                            savedSearchSharingEnabled ? saveAsNewSearchHandler : onSaveSearchToggle
                        }
                    />
                    <DeleteSavedSearchConfirmationModal searchModule={warrantsDashboardSearch} />
                    <ClearFiltersButton
                        className={buttonTypes.ICON_LINK}
                        iconLeft={<Icon type={iconTypes.CLOSE_X} size={12} color="mediumGrey" />}
                        onClick={onClearFilters}
                    >
                        {strings.footer.clearFilters}
                    </ClearFiltersButton>
                </FooterLeft>
            </WarrantsDashboardSearchFormSection>
        </WarrantsDashboardSearchFormContainer>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onSubmit: debounce(() => {
        dispatch(submitWarrantsDashboardSearchForm(false));
    }, 400),
    onClearFilters: () => {
        dispatch(clearAllFilters());
        dispatch(warrantsDashboardSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(warrantsDashboardSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
});

const mapStateToProps = createStructuredSelector({
    formatFieldByName: formatFieldByNameSelector,
    formIsEmpty: warrantsDashboardSearch.form.selectors.formIsEmptySelector,
    query: warrantsDashboardSearch.selectors.currentQuerySelector,
    combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    currentSavedSearch: warrantsDashboardSearch.selectors.currentSavedSearchSelector,
});

/**
 * Search form on the warrants dashboard, which includes both the free text
 *   search input and the filters below it. The filter form fields only appear
 *   in an overlay when the FILTERS button is clicked.
 * @type {Object}
 */
export default connect(mapStateToProps, mapDispatchToProps)(WarrantsDashboardSearchForm);

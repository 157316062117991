import { ReportDefinitionCard } from '@mark43/rms-api';
import {
    _Form,
    InferFormDataShape,
    createField,
    createFormConfiguration,
    createNItems,
} from 'markformythree';

import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';

import { getInformationCardIdFromCardLinks } from '../../../../../legacy-redux/helpers/customReportsAdminHelpers';
import { isInformationCard } from '../../configuration/informationCards';
import type {
    PartialReportDefinitionCard,
    PartialReportDefinitionOrderedCardViewModel,
} from '../../types';

const nItemsFields = createFormConfiguration({
    // field on ReportDefinitionCard
    isHidden: createField<boolean>({}),
    // field on ReportDefinitionCard
    title: createField<string>({}),
    // optional read-only field on ReportDefinitionCard and ReportDefinitionOrderedCard
    cardId: createField<number>({}),
    // optional read-only field on ReportDefinitionOrderedCard
    externalCardId: createField<number>({}),
    // optional read-only field on ReportDefinitionOrderedCard
    externalCardTitle: createField<string>({}),
    // ReportDefinitionOrderedCard.ordinal is not a field in this form, it is derived from each index in this NItems
    // see convertFromFormModel
});

export const formConfiguration = createFormConfiguration({
    informationCardId: createField<number>({}),
    cardLinks: createNItems({
        fields: nItemsFields,
    }),
});

export type ReportCardLinksFormConfiguration = typeof formConfiguration;
export type ReportCardLinksMFTForm = _Form<ReportCardLinksFormConfiguration>;
type ReportCardLinksFormModel = InferFormDataShape<typeof formConfiguration>;
export type ReportCardLinkFormModel = InferFormDataShape<typeof nItemsFields>;

export function convertToFormModel(
    reportDefinitionOrderedCardViewModel?: PartialReportDefinitionOrderedCardViewModel,
    reportDefinitionCards?: PartialReportDefinitionCard[]
): ReportCardLinksFormModel {
    if (
        !reportDefinitionOrderedCardViewModel ||
        !reportDefinitionOrderedCardViewModel.orderedCardLinks ||
        !reportDefinitionCards
    ) {
        return { cardLinks: [] };
    }

    const informationCardId =
        reportDefinitionOrderedCardViewModel.reportDefinitionId > 0
            ? getInformationCardIdFromCardLinks(reportDefinitionCards)
            : undefined;

    const orderedCardLinks = reportDefinitionOrderedCardViewModel.orderedCardLinks.map(
        (cardLink) => {
            const cardId = cardLink.cardId;
            const reportDefinitionCard = reportDefinitionCards.find(
                (link) => link.cardId === cardId
            );
            return {
                isHidden: reportDefinitionCard?.isHidden,
                title: reportDefinitionCard?.title,
                cardId,
                externalCardId: cardLink.externalCardId,
                externalCardTitle: cardLink.externalCardTitle,
            };
        }
    );

    const orderedCardIds = orderedCardLinks.map((link) => link.cardId);
    const remainingCoreCardLinks = reportDefinitionCards
        .filter(
            (link) =>
                !!link.cardId &&
                !orderedCardIds.includes(link.cardId) &&
                // hide all Information Cards besides the selected one
                !(isInformationCard(link.cardId) && link.cardId !== informationCardId) &&
                link.cardId !== reportCardEnum.APPROVALS.id
        )
        .sort((link1, link2) => `${link1.title}`.localeCompare(`${link2.title}`))
        .map((link) => ({
            isHidden: link.isHidden,
            title: link.title,
            cardId: link.cardId,
            externalCardId: undefined,
            externalCardTitle: undefined,
        }));

    return {
        informationCardId,
        cardLinks: [...orderedCardLinks, ...remainingCoreCardLinks],
    };
}

interface ReportCardLinks {
    reportDefinitionOrderedCardViewModel: PartialReportDefinitionOrderedCardViewModel;
    reportDefinitionCards: Partial<ReportDefinitionCard>[];
}

export function convertFromFormModel(
    formModel: ReportCardLinksFormModel,
    reportDefinitionId: number,
    existingReportDefinitionCards: PartialReportDefinitionCard[],
    departmentId: number
): ReportCardLinks | undefined {
    if (!formModel.cardLinks) {
        return;
    }

    const reportDefinitionCards = formModel.cardLinks
        .filter((link) => !!link.cardId)
        .map((formLink) => ({
            ...existingReportDefinitionCards.find(
                (existingLink) => existingLink.cardId === formLink.cardId
            ),
            cardId: formLink.cardId,
            isHidden: formLink.isHidden,
            title: formLink.title,
        }));

    const orderedCardLinks = formModel.cardLinks
        .filter((link) => !link.isHidden)
        .map((link, index) => ({
            cardId: link.cardId,
            externalCardId: link.externalCardId,
            externalCardTitle: link.externalCardTitle,
            ordinal: index + 1,
            departmentId,
        }));

    return {
        reportDefinitionCards,
        reportDefinitionOrderedCardViewModel: {
            reportDefinitionId,
            isDefaultOrder: false,
            orderedCardLinks,
        },
    };
}

import { AttributeTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { includes, map } from 'lodash';

import { createSelector } from 'reselect';
import entityTypeObjectEnum from '../../../../enums/universal/entityTypeObjectEnum';

import sortNameReportLinks from '../../../name-report-links/utils/sortNameReportLinks';
import sortLocationEntityLinks from '../../../location-entity-links/utils/sortLocationEntityLinks';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';
import {
    locationEntityLinksWhereSelector,
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
} from '../../../location-entity-links/state/data';
import {
    fieldContactByReportIdSelector,
    NEXUS_STATE_PROP as FIELD_CONTACTS_NEXUS_STATE_PROP,
} from '../../../field-contacts/state/data';
import {
    reportAttributesWhereSelector,
    NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
} from '../../../report-attributes/state/data';
import {
    nameReportLinksWhereSelector,
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
} from '../../../name-report-links/state/data';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in a Field Contact Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const FIELD_CONTACT_CARD_REPORT_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name,
    AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name,
    AttributeTypeEnum.WEAPON_INVOLVED.name,
];

/**
 * `LinkTypes` that are valid for the `NameReportLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const FIELD_CONTACT_CARD_NAME_REPORT_LINK_TYPES = [
    LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT,
    LinkTypesEnum.OTHER_NAME_IN_FIELD_CONTACT,
];

/**
 * `LinkTypes` that are valid for the `LocationEntityLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const FIELD_CONTACT_CARD_LOCATION_LINK_TYPES = [LinkTypesEnum.LOCATION_OF_FIELD_CONTACT];

const reportAttributesFilter = (reportId) => {
    return (reportAttribute) =>
        reportAttribute.reportId === reportId &&
        includes(FIELD_CONTACT_CARD_REPORT_ATTRIBUTE_TYPES, reportAttribute.attributeType);
};

export const hydratedFieldContactByReportIdSelector = createSelector(
    fieldContactByReportIdSelector,
    reportAttributesWhereSelector,
    nameReportLinksWhereSelector,
    locationEntityLinksWhereSelector,
    (
        fieldContactByReportId,
        reportAttributesWhere,
        nameReportLinksWhere,
        locationEntityLinksWhere
    ) => (reportId) => {
        const fieldContact = fieldContactByReportId(reportId) || {};
        const { id: fieldContactId } = fieldContact;
        const reportAttributes = reportAttributesWhere(reportAttributesFilter(reportId));
        const fieldContactSubjects = nameReportLinksWhere({
            reportId,
            contextType: entityTypeObjectEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.SUBJECT_IN_FIELD_CONTACT,
        });
        const fieldContactOrganizations = nameReportLinksWhere({
            reportId,
            contextType: entityTypeObjectEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.ORGANIZATION_IN_FIELD_CONTACT,
        });
        const otherNamesInFieldContact = nameReportLinksWhere({
            reportId,
            contextType: entityTypeObjectEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.OTHER_NAME_IN_FIELD_CONTACT,
        });
        const fieldContactLocations = locationEntityLinksWhere({
            entityType: entityTypeObjectEnum.FIELD_CONTACT.name,
            linkType: LinkTypesEnum.LOCATION_OF_FIELD_CONTACT,
            entityId: fieldContactId,
        });

        const sortedFieldContactSubjects = sortNameReportLinks({
            nameReportLinks: fieldContactSubjects,
        });
        const sortedFieldContactOrganizations = sortNameReportLinks({
            nameReportLinks: fieldContactOrganizations,
        });
        const sortedOtherNamesInFieldContact = sortNameReportLinks({
            nameReportLinks: otherNamesInFieldContact,
        });
        const sortedFieldContactLocations = sortLocationEntityLinks({
            locationEntityLinks: fieldContactLocations,
        });

        return {
            fieldContact,
            reportAttributes,
            fieldContactSubjects: sortedFieldContactSubjects,
            fieldContactOrganizations: sortedFieldContactOrganizations,
            otherNamesInFieldContact: sortedOtherNamesInFieldContact,
            fieldContactLocations: sortedFieldContactLocations,
        };
    }
);

export function replaceFieldContactCardBundle({ reportId, fieldContactCardBundle }) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) => {
        const resource = getReportCardBundleResource();
        return resource
            .upsertFieldContactCard(reportId, fieldContactCardBundle)
            .then((updatedFieldContactCardBundle) => {
                const {
                    fieldContact,
                    reportAttributes,
                    nameReportLinks,
                    locationEntityLinks,
                } = updatedFieldContactCardBundle;
                const { id: fieldContactId } = fieldContact;
                const predicateReportAttributeObjectsToRemoveBy = map(
                    FIELD_CONTACT_CARD_REPORT_ATTRIBUTE_TYPES,
                    (attributeType) => ({
                        reportId,
                        attributeType,
                    })
                );
                const predicateNameReportLinksToRemoveBy = map(
                    FIELD_CONTACT_CARD_NAME_REPORT_LINK_TYPES,
                    (linkType) => ({
                        reportId,
                        linkType,
                    })
                );
                const predicateLocationEntityLinksToRemoveBy = map(
                    FIELD_CONTACT_CARD_LOCATION_LINK_TYPES,
                    (linkType) => ({
                        linkType,
                        entityType: entityTypeObjectEnum.FIELD_CONTACT.name,
                        entityId: fieldContactId,
                    })
                );

                dispatch(
                    withEntityItems(
                        {
                            [FIELD_CONTACTS_NEXUS_STATE_PROP]: [fieldContact],
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                            [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                        },
                        withRemoveMultiple(
                            {
                                [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: predicateReportAttributeObjectsToRemoveBy,
                                [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: predicateNameReportLinksToRemoveBy,
                                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: predicateLocationEntityLinksToRemoveBy,
                            },
                            {
                                type: 'UPSERT_FIELD_CONTACT_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedFieldContactCardBundle;
            });
    };
}

import { useSelector } from 'react-redux';

import { Offense } from '@mark43/rms-api';

import { offenseURNEnabledSelector } from '../state/ui/offenseURN';

/**
 * If this department is configured to display the offense URN, then return that string.
 * Otherwise, return undefined.
 */
export function useOffenseURN(offense: Offense): string | undefined {
    const enabled = useSelector(offenseURNEnabledSelector);
    return enabled ? offense.offenseURN : undefined;
}

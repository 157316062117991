import { chain, compact, concat, filter, get, head, map, some, sortBy, uniqBy } from 'lodash';

import { createSelector } from 'reselect';
import { isUndefinedOrNull } from '../../../../../helpers/logicHelpers';

import {
    buildViewModel,
    allSingleAttributeValuesMapper,
    getViewModelProperties,
} from '../../../../../helpers/viewModelHelpers';
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { arrestByIdSelector, arrestForReportIdSelector } from '../../../arrests/state/data';
import { chargesWhereSelector } from '../data';
import { offenseByIdSelector, offensesWhereSelector } from '../../../offenses/state/data';
import { offenseCodeByIdSelector } from '../../../offense-codes/state/data';
import { reportsWhereSelector } from '../../../reports/state/data';
import { warrantsSelector, warrantByIdSelector } from '../../../warrants/state/data';
import { offenseReportDefinitionForDepartmentSelector } from '../../../report-definitions/state/ui';
import { offenseCodeViewModelsSelector } from '../../../offense-codes/state/ui';
import { offenseViewModelByIdSelector } from '../../../offenses/state/ui';
import { warrantViewModelByIdSelector } from '../../../warrants/state/ui';
import { mostRecentWarrantStatusViewModelByWarrantIdSelector } from '../../../warrant-statuses/state/ui';
import { reportRenByIdSelector } from '../../../reports/state/ui';
import sortCharges from '../../utils/sortCharges';
import { detentionsForReportIdSelector } from '../../../detentions/state/data';

const buildChargeViewModelSelector = createSelector(
    offenseCodeViewModelsSelector,
    offenseByIdSelector,
    reportRenByIdSelector,
    formatAttributeByIdSelector,
    (offenseCodeViewModels, offenseById, reportRenById, formatAttributeById) => {
        const viewModel = buildViewModel({
            recursive: true,
            mappers: [
                allSingleAttributeValuesMapper,
                ({ chargeOffenseCodeId, offenseId }) => {
                    const offenseCodeViewModel = offenseCodeViewModels[chargeOffenseCodeId] || {};
                    const { displayName } = getViewModelProperties(offenseCodeViewModel);
                    const offense = offenseById(offenseId) || {};
                    const { reportId } = offense;
                    const reportRen = reportRenById(reportId);

                    return {
                        chargeOffenseCode: displayName,
                        originalRen: reportRen,
                    };
                },
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return viewModel;
    }
);

export const chargeViewModelsWhereSelector = createSelector(
    chargesWhereSelector,
    buildChargeViewModelSelector,
    (chargesWhere, buildChargeViewModel) => (predicate) => {
        return map(chargesWhere(predicate), buildChargeViewModel);
    }
);

export const hydratedChargeViewModelsForChargesWhereSelector = createSelector(
    chargeViewModelsWhereSelector,
    offenseViewModelByIdSelector,
    warrantViewModelByIdSelector,
    mostRecentWarrantStatusViewModelByWarrantIdSelector,
    (
        chargeViewModelsWhere,
        offenseViewModelById,
        warrantViewModelById,
        mostRecentWarrantStatusViewModelByWarrantId
    ) =>
        (chargeViewModelsPredicate) => {
            const chargeViewModels = chargeViewModelsWhere(chargeViewModelsPredicate);
            return map(chargeViewModels, (chargeViewModel) => {
                const { offenseId, warrantId } = chargeViewModel;
                return {
                    chargeViewModel,
                    offenseViewModel: offenseViewModelById(offenseId) || {},
                    warrantViewModel: warrantViewModelById(warrantId) || {},
                    warrantStatusViewModel:
                        mostRecentWarrantStatusViewModelByWarrantId(warrantId) || {},
                };
            });
        }
);

export const sortHydratedChargeViewModels = ({ hydratedChargeViewModels }) =>
    sortBy(hydratedChargeViewModels, 'chargeViewModel.offenseOrder');

export const hydratedChargesForChargesWhereSelector = createSelector(
    chargesWhereSelector,
    offenseByIdSelector,
    warrantByIdSelector,
    (chargesWhere, offenseById, warrantById) => (chargesWherePredicate) => {
        const charges = chargesWhere(chargesWherePredicate);
        return map(charges, (charge) => {
            const { offenseId, warrantId } = charge;
            return {
                charge,
                offense: offenseById(offenseId) || {},
                warrant: warrantById(warrantId) || {},
            };
        });
    }
);

export const sortHydratedCharges = ({ hydratedCharges }) =>
    sortBy(hydratedCharges, 'charge.offenseOrder');

/**
 * Gets all related Offenses to an Arrest.
 * Logic:
 * 1) Get all `isOffenseType` Offenses under the arrest report's REN.
 * 2) Get all Offenses for all Charges currently tied to the arrest.
 */
export const arrestRelatedOffensesSelector = createSelector(
    arrestByIdSelector,
    reportRenByIdSelector,
    reportsWhereSelector,
    offenseReportDefinitionForDepartmentSelector,
    offensesWhereSelector,
    offenseByIdSelector,
    offenseCodeByIdSelector,
    chargesWhereSelector,
    (
        arrestById,
        reportRenById,
        reportsWhere,
        offenseReportDefinitionForDepartment,
        offensesWhere,
        offenseById,
        offenseCodeById,
        chargesWhere
    ) =>
        ({ arrestId }) => {
            const arrest = arrestById(arrestId) || {};
            const arrestReportRen = reportRenById(arrest.reportId);
            const offenseReportDefinitionId = get(
                offenseReportDefinitionForDepartment({ departmentId: arrest.departmentId }),
                'id'
            );
            const offenseReportForRen =
                head(
                    reportsWhere({
                        reportingEventNumber: arrestReportRen,
                        reportDefinitionId: offenseReportDefinitionId,
                    })
                ) || {};
            const offenseTypeOffensesForRen = filter(
                offensesWhere({ reportId: offenseReportForRen.id }),
                (offense) => {
                    const { offenseCodeId } = offense;
                    const offenseCode = offenseCodeById(offenseCodeId);
                    return !isUndefinedOrNull(offenseCode) && offenseCode.isOffenseType;
                }
            );

            const arrestCharges = chargesWhere({ arrestId });
            const offenseIdsForArrestCharges = map(arrestCharges, 'offenseId');
            const offensesForArrestCharges = compact(
                map(offenseIdsForArrestCharges, (offenseId) => offenseById(offenseId))
            );

            return uniqBy(concat(offenseTypeOffensesForRen, offensesForArrestCharges), 'id');
        }
);

/**
 * Gets all related Warrants to an Arrest.
 * Logic:
 * 1) Get warrants under arrest report's REN
 * 2) Get warrants for current report arrest id
 * 3) Get warrants for all linked charges
 */
export const arrestRelatedWarrantsSelector = createSelector(
    arrestByIdSelector,
    reportRenByIdSelector,
    warrantsSelector,
    chargesWhereSelector,
    (arrestById, reportRenById, warrants, chargesWhere) =>
        ({ arrestId }) => {
            const arrest = arrestById(arrestId) || {};
            const arrestReportRen = reportRenById(arrest.reportId);
            const chargeWarrantIds = chain(chargesWhere({ arrestId }))
                .map('warrantId')
                .compact()
                .value();

            return chain(warrants)
                .filter(
                    (warrant) =>
                        warrant.reportingEventNumber === arrestReportRen ||
                        warrant.arrestId === arrestId ||
                        some(chargeWarrantIds, (chargeWarrantId) => chargeWarrantId === warrant.id)
                )
                .uniqBy('id')
                .value();
        }
);

/**
 * Return a list of sorted charges, but only if they have detentions
 */
export const sortedChargesWithDetentionsForReportIdSelector = createSelector(
    arrestForReportIdSelector,
    chargesWhereSelector,
    detentionsForReportIdSelector,
    (arrestForReportId, chargesWhere, detentionsForReportId) => (reportId) => {
        const arrest = arrestForReportId(reportId);

        const sortedChargesForArrestId = sortCharges({
            charges: chargesWhere({
                arrestId: arrest?.id,
            }),
        });

        const detentions = detentionsForReportId(reportId);

        return sortedChargesForArrestId.flatMap((charge) => {
            const detentionForCharge = detentions.find(
                (detention) => detention.chargeId === charge.id
            );
            if (detentionForCharge) {
                return {
                    charge,
                    detention: detentionForCharge,
                };
            }
            return [];
        });
    }
);

/**
 * Returns detention associated with charge if exists
 */
export const detentionForReportIdAndChargeIdSelector = createSelector(
    detentionsForReportIdSelector,
    (detentionsForReportId) => (reportId, chargeId) => {
        const detentions = detentionsForReportId(reportId);

        return detentions.find((detention) => detention.chargeId === chargeId);
    }
);

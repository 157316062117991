import { searchElasticReports } from './priorOffenseSearch';

export const performPriorOffenseSearchMoreResults = ({ elasticQuery, from, size }) => (
    dispatch
) => {
    const newFrom = from + size;
    return dispatch(
        searchElasticReports({
            elasticQuery,
            from: newFrom,
            size,
            actionType: { type: 'PRIOR_OFFENSE_SEARCH_ELASTIC_REPORTS_MORE_RESULTS' },
        })
    );
};

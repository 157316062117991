import React from 'react';

import { RegionalGroupEnum } from '@mark43/rms-api';
import { getDropdownOptionsFromEnum } from '~/client-common/helpers/dropdownOptionHelpers';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

export const regionalGroupOptions = getDropdownOptionsFromEnum(RegionalGroupEnum);

/**
 * Select dropdown for UCR/NIBRS regional groups
 * @param {Object} props
 * @param {string} [props.label] Set this prop to use a custom label different
 *   from the default label.
 */
function RegionalGroupSelect(props) {
    return <Select options={regionalGroupOptions} {...props} />;
}

export const RRFRegionalGroupSelect = connectRRFInput(RegionalGroupSelect);

import createCreateFormModule from '~/client-common/redux/modules/core/forms/lib/createCreateFormModule';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import {
    formModelsSelector,
    formUiSelector as allFormUiSelector,
} from '../../../../legacy-redux/selectors/formSelectors';
import { getArbiter } from '../../arbiter';

import { runValidationRRF } from '../../validation';

const fieldsetTypes = [
    fieldTypeClientEnum.N_FIELDSETS,
    fieldTypeClientEnum.FIELDSET,
    fieldTypeClientEnum.N_ITEMS_FIELDSET,
];
const multiTypes = [fieldTypeClientEnum.N_FIELDSETS, fieldTypeClientEnum.N_ITEMS_FIELDSET];
const validationStrings = componentStrings.validation;

const { joinFormModelPath } = reactReduxFormHelpers;
export default createCreateFormModule(
    fieldsetTypes,
    multiTypes,
    validationStrings,
    formModelsSelector,
    allFormUiSelector,
    joinFormModelPath,
    runValidationRRF,
    getArbiter
);

import { AttributeTypeEnum } from '@mark43/rms-api';
import _, { get, uniq, map, some, values, noop, isArray } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    compose,
    setDisplayName,
    setPropTypes,
    withState,
    withHandlers,
    withPropsOnChange,
    lifecycle,
    getContext,
} from 'recompose';

import { simpleControl } from 'markformythree';
import strings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { arbiterMFTInput } from '../../../arbiter';
import { OnlyWithAbility, abilitiesEnum } from '../../../abilities';
import Link from '../../../components/links/Link';
import {
    attributeLoadingStateSelectorFactory,
    attributeOptionsByTypeForCurrentDepartmentSelector,
} from '../../../attributes/state/ui';
import { loadAttributesForType } from '../../../attributes/state/ui/loadAttributesForType';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import fieldStrings from '../../../../../legacy-redux/configs/fieldStrings';
import { computeAllowedAttributeTypesToLoad } from '../../../attributes/utils/computeAllowedAttributeTypesToLoad';
import { computeLoadableAttributeTypes } from '../../../attributes/utils/computeLoadableAttributeTypes';
import { shouldShowFieldNamesSelector } from '../../../../../legacy-redux/selectors/globalSelectors';
import { logWarning } from '../../../../../core/logging';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

const createLink = (attributeType, showLinks) => {
    const displayName = get(AttributeTypeEnum[attributeType], 'displayName');
    return showLinks ? (
        <Link to={`/admin/attributes/${attributeType}`} openInNewTab={true}>
            {displayName}
        </Link>
    ) : (
        <span>{displayName}</span>
    );
};

const AttributeSelect = compose(
    setDisplayName('AttributeSelect'),
    setPropTypes({
        attributeType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
            .isRequired,
        includeExpired: PropTypes.bool,
        filterOptions: PropTypes.func,
    }),
    withState('attributeIds', 'setAttributeIds', ({ value }) => (value ? [].concat(value) : [])),
    connect(
        () => {
            const attributeLoadingStateSelector = attributeLoadingStateSelectorFactory();
            return createStructuredSelector({
                attributeOptionsByTypeForCurrentDepartment:
                    attributeOptionsByTypeForCurrentDepartmentSelector,
                attributeLoadingState: attributeLoadingStateSelector,
                shouldShowFieldNames: shouldShowFieldNamesSelector,
            });
        },
        {
            loadAttributesForType,
        }
    ),
    getContext({ router: PropTypes.object }),
    withHandlers({
        onInputFocus(props) {
            const { attributeLoadingState, onFocus, loadAttributesForType } = props;
            return () => {
                // grab all types which aren't yet loaded so that we only load the required subset
                const attributeTypesToLoad = computeLoadableAttributeTypes(attributeLoadingState);

                // for now we load all attributes for the specified types, including expired ones
                if (attributeTypesToLoad.length) {
                    // the empty `catch` is intentional. Due to having to support legacy KO/BB
                    // we have to return a promise which can potentially be rejected
                    loadAttributesForType({ attributeType: attributeTypesToLoad }).catch(noop);
                }

                if (onFocus) {
                    onFocus();
                }
            };
        },
        onAttributeChange({ setAttributeIds, onChange }) {
            return (attributeId) => {
                setAttributeIds((attributeIds) => {
                    return _(attributeIds).concat(attributeId).uniq().value();
                });
                onChange(attributeId);
            };
        },
    }),
    lifecycle({
        componentDidMount() {
            const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                this.props.attributeLoadingState
            );
            // for now we load all attributes for the specified types, including expired ones
            if (attributeTypesToLoad.length) {
                this.props
                    .loadAttributesForType({ attributeType: attributeTypesToLoad })
                    .then((attributes) => {
                        if (this.props.onAttributeLoadSuccess) {
                            this.props.onAttributeLoadSuccess(attributes);
                        }
                    })
                    .catch(noop);
            }
        },
    }),
    withPropsOnChange(
        [
            'attributeType',
            'includeExpired',
            'attributeIds',
            'includeAbbr',
            'attributeOptionsByTypeForCurrentDepartment',
            'predicate',
        ],
        ({
            attributeType,
            includeAbbr = false,
            includeExpired = false,
            attributeIds,
            attributeOptionsByTypeForCurrentDepartment,
            predicate,
        }) => {
            const options = attributeOptionsByTypeForCurrentDepartment({
                type: attributeType,
                includeExpired,
                additionalIds: attributeIds,
                includeAbbr,
                predicate,
            });
            const departmentIds = map(options, 'departmentId');
            const unique = uniq(departmentIds);
            if (unique.length > 1) {
                logWarning('Attributes from multiple departments found in attribute select', {
                    attributeType,
                    departmentIds: unique,
                    attributeIds: map(options, 'value'),
                });
            }
            return {
                options,
            };
        }
    )
)(function AttributeSelect({
    options,
    attributeType,
    filterOptions,
    shouldShowFieldNames,
    onAttributeChange,
    onInputFocus,
    router,
    attributeLoadingState,
    externalIsLoading,
    error,
    fieldUi,
    ui,
    disabled,
    /**
     * false or undefined: only shows loading spinner when select is focused
     * if set to true loading spinner will show whenever isLoading is true
     */
    showLoadingWhenUnfocused,
    ...otherProps
}) {
    const attributeLoadingValues = values(attributeLoadingState);
    const isLoading =
        !!externalIsLoading || some(attributeLoadingValues, (loadingState) => loadingState.loading);
    // need to check both fields to stay compatible between RRF and MFT
    const uiState = fieldUi || ui;
    // if a field has't been focussed at all, it doesn't seem
    // to have a ui state in RRF. so we have to account for that here
    const isFocused = !!uiState && uiState.focus;
    const finalOptions = filterOptions ? filterOptions(options) : options;
    const isDisabled = !!disabled || !!externalIsLoading;
    const hasAsyncError =
        isFocused &&
        !isLoading &&
        some(attributeLoadingValues, (loadingState) => loadingState.error);
    // If the router is not in context, links cannot be rendered here. This is possible because not
    // every Backbone view that renders a React component passes in the router as a prop, and not
    // every component rendered that way puts the router on its context.
    const showLinks = !!router;
    const rawLabel = otherProps.label || fieldStrings.attributeTypes[attributeType];
    const label = shouldShowFieldNames ? (
        <div>
            {rawLabel}
            <FeatureFlagged flag="RMS_TOGGLE_FIELD_LABELS_ENABLED">
                <OnlyWithAbility has={abilitiesEnum.ADMIN.EDIT_GLOBAL_ATTRIBUTE_CONFIGURATION}>
                    <div>
                        Attribute Type:{' '}
                        {isArray(attributeType)
                            ? map(attributeType, (attribute) => (
                                  <div key={attribute}>{createLink(attribute, showLinks)} </div>
                              ))
                            : createLink(attributeType, showLinks)}
                    </div>
                </OnlyWithAbility>
            </FeatureFlagged>
        </div>
    ) : (
        rawLabel
    );
    return (
        <Select
            disabled={isDisabled}
            options={finalOptions}
            loading={(showLoadingWhenUnfocused || isFocused) && isLoading}
            {...otherProps}
            label={label}
            fieldUi={fieldUi}
            ui={ui}
            error={
                error ||
                (hasAsyncError
                    ? strings.core.attributeComponents.attributeTypeLoadingError
                    : undefined)
            }
            onChange={onAttributeChange}
            onFocus={onInputFocus}
            forceShowError={hasAsyncError}
        />
    );
});

/**
 * Select dropdown containing all attributes of the specified attribute type(s).
 *   Whenever an attribute is selected (including initially), its id gets stored
 *   in component state so that any expired or scheduled attributes that get
 *   selected are always shown in this dropdown (even when `includeExpired` is
 *   `false`), which is useful mainly in old reports where the initial attribute
 *   is expired.
 * @param {string|string[]} props.attributeType
 * @param {boolean}  [props.includeAbbr=false] Whether to show each attribute's
 *   code before its display name.
 * @param {boolean}  [props.includeExpired=false] Whether to include expired
 *   attributes. If `true`, the expired attributes are listed after the active
 *   ones, both sorted alphabetically.
 * @param {function} [props.filterOptions] If provided, this function is called
 *   on the original array of attribute options and its return value becomes the
 *   new options. For example, use this to filter the dropdown options to only
 *   attributes with a particular parent attribute.
 * @param {string}   [props.label] A default label from `fieldStrings` gets set
 *   based on the attribute type. Set this prop to use a custom label different
 *   from the default label.
 */
export default AttributeSelect;

export const RRFAttributeSelect = connectRRFInput(AttributeSelect);
export const ArbiterMFTAttributeSelect = arbiterMFTInput(AttributeSelect);
export const MFTAttributeSelect = simpleControl(AttributeSelect);

import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Fieldset } from 'markformythree';

import { InlineBanner } from 'arc';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields, { useFieldName } from '~/client-common/core/fields/hooks/useFields';
import {
    DISPLAY_ONLY_PERSON_PROFILE_IDENTIFYING_MARKS_LABEL,
    DISPLAY_ONLY_PERSON_PROFILE_OTHER_NAMES_USED_LABEL,
    PASSPORT_N_ITEMS_WRAPPER,
    USER_PHONE_PHONE_NUMBER,
    DISPLAY_ONLY_PERSON_PROFILE_EMAIL_LABEL,
    EMPLOYMENT_HISTORY_MILITARY_BRANCH_ATTR_ID,
    DISPLAY_ONLY_PERSON_PROFILE_ATTACHMENT_LABEL,
    DISPLAY_ONLY_CAUTION_LABEL,
} from '~/client-common/core/enums/universal/fields';

import Row from '../../components/Row';
import { ArbiterMFTCheckbox } from '../../forms/components/checkboxes/Checkbox';
import { ArbiterMFTAttributeSelect } from '../../forms/components/selects/AttributeSelect';
import { ArbiterMFTBooleanSelect } from '../../forms/components/selects/BooleanSelect';
import { ArbiterMFTText } from '../../forms/components/Text';
import { ArbiterMFTTextArea } from '../../forms/components/TextArea';
import Divider from '../../components/Divider';
import FormRow from '../../forms/components/FormRow';
import { MFTNItems, ArbiterMFTNItems } from '../../forms/components/NItems';
import { DashedDivider } from '../../../../legacy-redux/components/core/Divider';
import { renderAddButton } from '../../names/components/nameFormNItemsRenderers';
import InlineAttachmentsUploader from '../../../attachments/core/components/InlineAttachmentsUploader';
import { InlineAttachmentsEntityProfileUploader } from '../../names/components/InlineAttachmentsEntityProfileUploader';
import { CollapsibleSectionWithStickyHeader } from '../../components/CollapsibleSectionWithStickyHeader';
import testIds from '../../../../core/testIds';
import {
    CAUTIONS,
    CORE_DETAILS,
    CONTACT_INFO,
    EVENT_SPECIFIC_INFO,
    MISC_INFO,
} from '../config/personProfileFormSections';
import { SomeFieldsHaveTruthyValue } from '../../components/SomeFieldsHaveValue';
import { passportFields } from '../../names/util/nameFieldHelpers';
import { responsiveStack } from '../../styles/mixins';
import { PERSON_PROFILE_FORM_ADD_CAUTION_BUTTON_ID } from '../../cautions/configuration';
import { useAbilitySelector } from '../../current-user/hooks/useAbilitySelector';
import { abilitiesEnum } from '../../abilities';
import {
    ATTRIBUTE_FIELDSET_DISPLAY_AS,
    PersonProfileFormAttributeFieldset,
} from './PersonProfileFormAttributeFieldset';
import { PersonProfileFormNames } from './PersonProfileFormNames';
import { PersonProfileEmploymentSchoolHistories } from './PersonProfileEmploymentSchoolHistories';
import { PersonProfileFormBodyRelatedFields } from './PersonProfileFormBodyRelatedFields';
import { PersonProfileFormClothingFields } from './PersonProfileFormClothingFields';
import { PersonProfileFormInjuryFields } from './PersonProfileFormInjuryFields';
import { PersonProfileFormGeneralPersonRelatedFields } from './PersonProfileFormGeneralPersonRelatedFields';
import { PersonProfileFormDriversLicenseFields } from './PersonProfileFormDriversLicenseFields';
import { PersonProfileFormResidencyFields } from './PersonProfileFormResidencyFields';
import { PersonProfileFormIdentifierFields } from './PersonProfileFormIdentifierFields';
import { PersonProfileGangTrackingFields } from './PersonProfileGangTrackingFields';
import { PersonProfileFormAddressFields } from './PersonProfileFormAddressFields';
import { PersonProfileFormEmergencyContactFields } from './PersonProfileFormEmergencyContactFields';
import { PersonProfileNextOfKinFields } from './PersonProfileNextOfKinFields';
import {
    renderMonikersNItems,
    renderIdentifyingMarksNItems,
    renderMilitaryHistoriesNItems,
    renderPersonPhoneNumbersNItems,
    renderProbationsNItems,
    renderPersonEmailsNItems,
    renderPassportsNItems,
    renderCautionsNItems,
} from './nameFormNItemsRenderers';
import { FormSection } from './FormSection';
import { NItemsRow } from './NItemsRow';

const { PersonProfileFormComponent: strings } = componentStrings.core.PersonProfileForm;

const PersonProfileFormContainer = styled.div`
    padding-bottom: 100px;
`;

const ProfileContainer = styled.div`
    gap: var(--arc-space-3);
    ${responsiveStack()}
`;

const StyledInlineAttachmentsUploader = styled(InlineAttachmentsUploader)`
    align-items: flex-start;
    flex-direction: column;
`;

const CollapsibleSectionContainer = styled.div`
    margin-top: 12px;
`;

const MILITARY_HISTORIES_PATH = 'militaryHistories';

const PersonProfileCoreDetailsSection = React.memo(({ linkType, form, entityId, cadProfileId }) => {
    const labels = useFields([
        DISPLAY_ONLY_PERSON_PROFILE_IDENTIFYING_MARKS_LABEL,
        DISPLAY_ONLY_PERSON_PROFILE_OTHER_NAMES_USED_LABEL,
        PASSPORT_N_ITEMS_WRAPPER,
    ]);
    return (
        <CollapsibleSectionContainer>
            <ProfileContainer>
                <StyledInlineAttachmentsUploader
                    entityId={cadProfileId ?? entityId}
                    entityType={EntityTypeEnum.PERSON_PROFILE.name}
                    linkType={LinkTypesEnum.MUGSHOT}
                    // We do not utilize any callbacks here because they will fire before we
                    // we have received updated props. This leads to an issue with the detection
                    // of currently running uploads because we are always lagging behing the component.
                    // Instead we solely rely on `this.props.uploadingFiles` to determine the current state
                >
                    {(inlineAttachmentsRenderProps) => (
                        <InlineAttachmentsEntityProfileUploader {...inlineAttachmentsRenderProps} />
                    )}
                </StyledInlineAttachmentsUploader>
                <PersonProfileFormNames form={form} />
            </ProfileContainer>
            <DashedDivider />
            <PersonProfileFormGeneralPersonRelatedFields form={form} />
            <Divider />
            <SomeFieldsHaveTruthyValue formPathsToObserve={['isUnborn']}>
                {(someFieldsHaveTruthyValue) =>
                    !someFieldsHaveTruthyValue && (
                        <>
                            <PersonProfileFormDriversLicenseFields />
                            <Divider />
                            <FormRow>
                                <ArbiterMFTText path="ssn" length="md" />
                                <ArbiterMFTText path="nationalIdNumber" length="md" />
                                <ArbiterMFTText path="stateIdNumber" length="md" />
                                <ArbiterMFTText path="fbiUcn" length="md" />
                            </FormRow>
                            <Divider />
                            <FormRow>
                                <ArbiterMFTText path="croId" length="md" />
                                <ArbiterMFTText path="pncId" length="md" />
                            </FormRow>
                            <PersonProfileFormIdentifierFields />
                            <DashedDivider />
                            <PersonProfileFormBodyRelatedFields form={form} linkType={linkType} />
                            <DashedDivider />
                            <MFTNItems
                                path="identifyingMarks"
                                addItemOnEmpty={false}
                                addText={
                                    labels[DISPLAY_ONLY_PERSON_PROFILE_IDENTIFYING_MARKS_LABEL]
                                }
                                hideAddButtonOnEmptyItem={true}
                                defaultItemValue={{
                                    imageId: {},
                                }}
                                childFieldKeys={[
                                    'description',
                                    'bodyPartAttrId',
                                    'identifyingMarkTypeAttrId',
                                    'imageId',
                                ]}
                                renderRowContainer={({ itemElement, index, item }) => (
                                    <NItemsRow
                                        key={index}
                                        index={index}
                                        item={item}
                                        children={itemElement}
                                    />
                                )}
                                render={({ item, removeItem }) => (
                                    <FormSection removeItem={removeItem}>
                                        {renderIdentifyingMarksNItems(item)}
                                    </FormSection>
                                )}
                                renderAddButton={(props) =>
                                    renderAddButton({
                                        ...props,
                                        testId: testIds.PERSON_PROFILE_IDENTIFYING_MARKS,
                                    })
                                }
                                renderRemoveButton={undefined}
                            />
                            <DashedDivider />
                            {/*
                            class added so the E2E tests work properly
                            can be removed once the old person side panel
                            is gone and the E2E tests can use a different selector
                         */}
                            <div className="person-side-panel-monikers">
                                <MFTNItems
                                    path="monikers"
                                    addItemOnEmpty={false}
                                    addText={
                                        labels[DISPLAY_ONLY_PERSON_PROFILE_OTHER_NAMES_USED_LABEL]
                                    }
                                    hideAddButtonOnEmptyItem={true}
                                    showLabelForFirstItemOnly={true}
                                    renderRemoveButton={undefined}
                                    renderAddButton={renderAddButton}
                                    childFieldKeys={['moniker', 'dateOfBirthOther']}
                                    render={renderMonikersNItems}
                                />
                            </div>
                            <DashedDivider />
                            <ArbiterMFTNItems
                                path="passports"
                                addText={labels[PASSPORT_N_ITEMS_WRAPPER]}
                                addItemOnEmpty={false}
                                hideAddButtonOnEmptyItem={false}
                                showLabelForFirstItemOnly={false}
                                renderAddButton={renderAddButton}
                                childFieldKeys={passportFields}
                                render={renderPassportsNItems}
                                renderRemoveButton={undefined}
                                formName={form.name}
                            />
                        </>
                    )
                }
            </SomeFieldsHaveTruthyValue>
        </CollapsibleSectionContainer>
    );
});

const PersonProfileCautionsSection = React.memo(() => {
    const { singular: addCautionLabel } = useFieldName(DISPLAY_ONLY_CAUTION_LABEL);
    const hasDeleteCautionsAbility = useAbilitySelector(abilitiesEnum.CORE.DELETE_CAUTIONS);

    return (
        <CollapsibleSectionContainer data-test-id={testIds.PERSON_PROFILE_CAUTIONS_CONTAINER}>
            <MFTNItems
                path="cautions"
                addText={addCautionLabel}
                addItemOnEmpty={false}
                render={({ item, removeItem }) =>
                    renderCautionsNItems({ item, removeItem, hasDeleteCautionsAbility })
                }
                renderAddButton={(props) =>
                    renderAddButton({
                        ...props,
                        id: PERSON_PROFILE_FORM_ADD_CAUTION_BUTTON_ID,
                        testId: testIds.PERSON_PROFILE_ADD_CAUTION,
                    })
                }
                renderRemoveButton={undefined}
            />
        </CollapsibleSectionContainer>
    );
});

const PersonProfileContactInfoSection = React.memo(({ form, entityId }) => {
    const labels = useFields([USER_PHONE_PHONE_NUMBER, DISPLAY_ONLY_PERSON_PROFILE_EMAIL_LABEL]);
    return (
        <CollapsibleSectionContainer>
            <Row>
                <ArbiterMFTCheckbox path="isPhoneNumberNotGiven" />
            </Row>
            <div className="person-org-side-panel-phones">
                <MFTNItems
                    path="phoneNumbers"
                    addItemOnEmpty={false}
                    addText={labels[USER_PHONE_PHONE_NUMBER]}
                    hideAddButtonOnEmptyItem={true}
                    childFieldKeys={[
                        'phoneType',
                        'displayNumber',
                        'isPrimary',
                        'dateEffectiveFrom',
                        'dateEffectiveTo',
                        'provenance',
                        'isSafeContact',
                        'safeContactDetails',
                    ]}
                    render={({ index, removeItem }) =>
                        renderPersonPhoneNumbersNItems({ removeItem, index, form })
                    }
                    renderRemoveButton={undefined}
                    renderAddButton={({ items, addText, addItem }) => (
                        <SomeFieldsHaveTruthyValue
                            formPathsToObserve={items.map(
                                (item, idx) => `phoneNumbers[${idx}].isPrimary`
                            )}
                        >
                            {(hasPrimaryPhoneNumber) =>
                                renderAddButton({
                                    items,
                                    addText,
                                    addItem: () =>
                                        addItem({
                                            isPrimary: !hasPrimaryPhoneNumber,
                                        }),
                                    testId: testIds.PERSON_PROFILE_ADD_PHONE,
                                })
                            }
                        </SomeFieldsHaveTruthyValue>
                    )}
                />
            </div>
            <DashedDivider />
            <div className="person-org-side-panel-emails">
                <MFTNItems
                    path="emails"
                    addItemOnEmpty={false}
                    addText={labels[DISPLAY_ONLY_PERSON_PROFILE_EMAIL_LABEL]}
                    hideAddButtonOnEmptyItem={true}
                    childFieldKeys={[
                        'emailType',
                        'emailAddress',
                        'dateEffectiveFrom',
                        'dateEffectiveTo',
                        'provenance',
                        'isPrimary',
                        'isSafeContact',
                        'safeContactDetails',
                    ]}
                    render={(props) => renderPersonEmailsNItems({ ...props, form })}
                    renderRemoveButton={undefined}
                    renderAddButton={({ items, addText, addItem }) => (
                        <SomeFieldsHaveTruthyValue
                            formPathsToObserve={items.map(
                                (item, idx) => `emails[${idx}].isPrimary`
                            )}
                        >
                            {(hasPrimaryEmailAddress) =>
                                renderAddButton({
                                    items,
                                    addText,
                                    addItem: () =>
                                        addItem({
                                            isPrimary: !hasPrimaryEmailAddress,
                                        }),
                                    testId: testIds.PERSON_PROFILE_ADD_EMAIL,
                                })
                            }
                        </SomeFieldsHaveTruthyValue>
                    )}
                />
            </div>
            <DashedDivider />
            <PersonProfileFormAddressFields form={form} entityId={entityId} />
            <DashedDivider />
            <PersonProfileEmploymentSchoolHistories form={form} />
            <DashedDivider />
            <PersonProfileFormEmergencyContactFields form={form} />
        </CollapsibleSectionContainer>
    );
});

const PersonProfileEventInfoSection = React.memo(({ form }) => (
    <CollapsibleSectionContainer data-test-id={testIds.PERSON_PROFILE_EVENT_INFO_CONTAINER}>
        <PersonProfileFormInjuryFields form={form} />
        <PersonProfileFormClothingFields />
        <Fieldset path="attributes">
            <PersonProfileFormAttributeFieldset
                attributeType={AttributeTypeEnum.BEHAVIORAL_CHARACTERISTIC.name}
                path={AttributeTypeEnum.BEHAVIORAL_CHARACTERISTIC.name}
                grouped={true}
            />
            <PersonProfileFormAttributeFieldset
                attributeType={AttributeTypeEnum.MOOD.name}
                path={AttributeTypeEnum.MOOD.name}
            />
        </Fieldset>
        <FeatureFlagged
            flag="RMS_LUNA_RELEASE_2020_2_ENABLED"
            fallback={<ArbiterMFTBooleanSelect path="isNonDisclosureRequest" length="md" />}
        />
        <PersonProfileGangTrackingFields />
        <ArbiterMFTTextArea path="details" />
    </CollapsibleSectionContainer>
));

const PersonProfileMiscInfoSection = React.memo(({ entityId }) => {
    const labels = useFields([
        EMPLOYMENT_HISTORY_MILITARY_BRANCH_ATTR_ID,
        DISPLAY_ONLY_PERSON_PROFILE_EMAIL_LABEL,
        DISPLAY_ONLY_PERSON_PROFILE_ATTACHMENT_LABEL,
    ]);
    return (
        <CollapsibleSectionContainer>
            <Fieldset path="attributes">
                <FeatureFlagged
                    flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED"
                    fallback={
                        <PersonProfileFormAttributeFieldset
                            attributeType={AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name}
                            path={AttributeTypeEnum.PERSON_LABEL_ATTRIBUTES.name}
                        />
                    }
                />
                <PersonProfileFormAttributeFieldset
                    attributeType={AttributeTypeEnum.PERSON_SKILL.name}
                    path={AttributeTypeEnum.PERSON_SKILL.name}
                />
            </Fieldset>
            <PersonProfileFormResidencyFields />
            <PersonProfileNextOfKinFields />
            <ArbiterMFTCheckbox path="needsInterpreter" />
            <Fieldset path="attributes">
                <PersonProfileFormAttributeFieldset
                    attributeType={AttributeTypeEnum.LANGUAGE.name}
                    path={AttributeTypeEnum.LANGUAGE.name}
                    grouped={true}
                />
            </Fieldset>
            <ArbiterMFTAttributeSelect
                path="maritalStatusAttrId"
                attributeType={AttributeTypeEnum.MARITAL_STATUS.name}
                length="lg"
            />
            <ArbiterMFTAttributeSelect
                path="sexualOrientationAttrId"
                attributeType={AttributeTypeEnum.SEXUAL_ORIENTATION.name}
                length="md"
            />
            <FormRow hasIndent>
                <ArbiterMFTText path="sexualOrientationOther" length="md" />
            </FormRow>
            <ArbiterMFTAttributeSelect
                path="religionAttrId"
                attributeType={AttributeTypeEnum.RELIGION.name}
                length="lg"
            />
            <FormRow hasIndent>
                <ArbiterMFTText path="religionOther" length="lg" />
            </FormRow>
            <Fieldset path="attributes">
                <PersonProfileFormAttributeFieldset
                    path={AttributeTypeEnum.VICTIM_DISABILITY_TYPE.name}
                    attributeType={AttributeTypeEnum.VICTIM_DISABILITY_TYPE.name}
                    displayAs={ATTRIBUTE_FIELDSET_DISPLAY_AS.BUTTON_RADIO}
                    multiple={true}
                />
            </Fieldset>
            <MFTNItems
                path="personProbations"
                addItemOnEmpty={true}
                addText={labels[DISPLAY_ONLY_PERSON_PROFILE_EMAIL_LABEL]}
                hideAddButtonOnEmptyItem={true}
                showLabelForFirstItemOnly={true}
                childFieldKeys={['probationTypeAttrId', 'probationOfficer', 'miscDescription']}
                render={renderProbationsNItems}
                renderRemoveButton={undefined}
                renderAddButton={undefined}
            />
            <DashedDivider />
            <MFTNItems
                path={MILITARY_HISTORIES_PATH}
                addItemOnEmpty={false}
                addText={labels[EMPLOYMENT_HISTORY_MILITARY_BRANCH_ATTR_ID]}
                hideAddButtonOnEmptyItem={true}
                childFieldKeys={['militaryBranchAttrId', 'dateStart', 'dateEnd']}
                renderRowContainer={({ itemElement, index, item }) => (
                    <NItemsRow key={index} index={index} item={item} children={itemElement} />
                )}
                render={({ removeItem }) => (
                    <FormSection removeItem={removeItem}>
                        {renderMilitaryHistoriesNItems()}
                    </FormSection>
                )}
                renderRemoveButton={undefined}
                renderAddButton={renderAddButton}
            />
            <DashedDivider />
            <InlineAttachmentsUploader
                entityId={entityId}
                entityType={EntityTypeEnum.PERSON_PROFILE.name}
                linkType={LinkTypesEnum.PERSON_PROFILE_ATTACHMENT}
                buttonText={labels[DISPLAY_ONLY_PERSON_PROFILE_ATTACHMENT_LABEL]}
                // We do not utilize any callbacks here because they will fire before we
                // we have received updated props. This leads to an issue with the detection
                // of currently running uploads because we are always lagging behing the component.
                // Instead we solely rely on `this.props.uploadingFiles` to determine the current state
            />
        </CollapsibleSectionContainer>
    );
});

export const PersonProfileFormComponent = ({
    form,
    id,
    linkType,
    updateFormSectionState,
    formSectionState,
    isEditingMasterProfile,
    cadProfileId,
    dexPerson,
}) => {
    const { plural: cautionsSectionTitle } = useFieldName(DISPLAY_ONLY_CAUTION_LABEL);
    const [showDexWarningBanner, setShowDexWarningBanner] = useState(dexPerson && dexPerson.name);

    return (
        <PersonProfileFormContainer data-test-id={testIds.NAME_SIDE_PANEL_FORM_WRAPPER}>
            <CollapsibleSectionWithStickyHeader
                isOpen={formSectionState[CORE_DETAILS]}
                title={strings.sections.coreDetails.header}
                description={strings.sections.coreDetails.description}
                toggleOpen={(open) => updateFormSectionState({ [CORE_DETAILS]: open })}
            >
                {showDexWarningBanner && (
                    <InlineBanner
                        description={strings.dexWarningBannerText}
                        onClose={() => setShowDexWarningBanner(false)}
                        status="attention"
                        title={strings.dexWarningBannerTitle}
                    />
                )}
                <PersonProfileCoreDetailsSection
                    entityId={id}
                    linkType={linkType}
                    form={form}
                    cadProfileId={cadProfileId}
                />
            </CollapsibleSectionWithStickyHeader>
            <SomeFieldsHaveTruthyValue formPathsToObserve={['isUnborn']}>
                {(someFieldsHaveTruthyValue) =>
                    !someFieldsHaveTruthyValue && (
                        <>
                            <FeatureFlagged flag="RMS_PERSON_CAUTIONS_ENHANCEMENTS_ENABLED">
                                <CollapsibleSectionWithStickyHeader
                                    isOpen={formSectionState[CAUTIONS]}
                                    title={cautionsSectionTitle}
                                    toggleOpen={(open) =>
                                        updateFormSectionState({ [CAUTIONS]: open })
                                    }
                                >
                                    <PersonProfileCautionsSection />
                                </CollapsibleSectionWithStickyHeader>
                            </FeatureFlagged>
                            <CollapsibleSectionWithStickyHeader
                                isOpen={formSectionState[CONTACT_INFO]}
                                title={strings.sections.contactInfo.header}
                                description={strings.sections.contactInfo.description}
                                toggleOpen={(open) =>
                                    updateFormSectionState({ [CONTACT_INFO]: open })
                                }
                            >
                                <PersonProfileContactInfoSection form={form} entityId={id} />
                            </CollapsibleSectionWithStickyHeader>
                            {!isEditingMasterProfile && (
                                <CollapsibleSectionWithStickyHeader
                                    isOpen={formSectionState[EVENT_SPECIFIC_INFO]}
                                    title={strings.sections.eventSpecificInfo.header}
                                    description={strings.sections.eventSpecificInfo.description}
                                    toggleOpen={(open) =>
                                        updateFormSectionState({ [EVENT_SPECIFIC_INFO]: open })
                                    }
                                >
                                    <PersonProfileEventInfoSection form={form} />
                                </CollapsibleSectionWithStickyHeader>
                            )}
                            <CollapsibleSectionWithStickyHeader
                                isOpen={formSectionState[MISC_INFO]}
                                title={strings.sections.miscellaneousInfo.header}
                                description={strings.sections.miscellaneousInfo.description}
                                toggleOpen={(open) => updateFormSectionState({ [MISC_INFO]: open })}
                            >
                                <PersonProfileMiscInfoSection entityId={id} />
                            </CollapsibleSectionWithStickyHeader>
                        </>
                    )
                }
            </SomeFieldsHaveTruthyValue>
        </PersonProfileFormContainer>
    );
};

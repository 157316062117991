import {
    LinkTypesEnumType,
    ConfiguredFormQueryConfigurationView,
    EntityTypeEnumType,
} from '@mark43/rms-api';
import { assertNotNullOrUndefined } from '../../core/utils/assert-not-null-or-undefined';
import { assertValidLinkTypeEnumValue } from '../utils/assert-valid-link-type-enum-value';
import { reverseLookupEntityTypeEnum } from '../utils/reverse-lookup-enums';
import { useResolvedQueryConfigurationValues } from './use-resolved-query-configuration-values';

export function useResolvedLocationEntityLinkQueryConfigurationValues(
    configuredFormQueryConfigurationViews: ConfiguredFormQueryConfigurationView[]
): {
    entityId: number;
    entityType: EntityTypeEnumType;
    linkType: LinkTypesEnumType;
} {
    // Looking up query configurations in order to map them to properties for `LocationSummaryViewWrapper` has to rely on string naming
    // of known properties for location entity links. We do not have another way of looking these up and this means
    // that we will hard fail, if a location entity link form is not properly configured with all the require query configurations.
    return useResolvedQueryConfigurationValues(configuredFormQueryConfigurationViews, {
        entityType: (data) => {
            const rawEntityType = data['entityType'];
            assertNotNullOrUndefined(rawEntityType, 'Entity type configuration missing');

            const entityType = reverseLookupEntityTypeEnum[rawEntityType];
            assertNotNullOrUndefined(
                entityType,
                'Unexpectedly unable to resolve configured entity type for location entity link'
            );

            return entityType;
        },
        entityId: (data) => {
            const contextId = Number(data['entityId']);
            if (isNaN(contextId)) {
                throw new Error(
                    'Unexpectedly unable to resolve entity id for location entity link'
                );
            }
            return contextId;
        },
        linkType: (data) => {
            const linkType = Number(data['linkType']);
            assertValidLinkTypeEnumValue(linkType);
            return linkType;
        },
    });
}

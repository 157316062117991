import _, { map, get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { attributeStatuses } from '~/client-common/core/domain/attributes/configuration';
import { reportDefinitionsForCurrentDepartmentSelector } from '../../modules/core/report-definitions/state/ui';
import { isTitleValid } from '../validation/helpers/customReportsAdminCardLinksFormValidatorHelper';
import { getFormsListFromStateByFormKey } from './formSelectorHelpers';

export const uiSelector = (state) => {
    return state.ui.customReportsAdmin;
};

// This is placed into the state only when a specific custom report definition is selected
export const currentReportDefinitionCardsSelector = createSelector(
    uiSelector,
    (ui) => ui.cardLinks
);

export const currentReportCardIdSelector = createSelector(uiSelector, (ui) => ui.reportCardId);

export const cardLinkFormsValidSelector = (state) => {
    const cardLinks = currentReportDefinitionCardsSelector(state);
    const cardIds = _(cardLinks).map('cardId').value();
    const formState = getFormsListFromStateByFormKey(state, 'customReportsAdminCardLinks', cardIds);

    const isInvalid = _.some(formState, (formStateLink) => !isTitleValid(formStateLink));

    return !isInvalid;
};

export const cardLinkFormsSelector = (state) => (reportName) => {
    const cardLinks = currentReportDefinitionCardsSelector(state);
    const cardIds = _(cardLinks).map('cardId').value();
    const formState = getFormsListFromStateByFormKey(state, 'customReportsAdminCardLinks', cardIds);

    const updatedLinks = _(cardLinks)
        .map((link) => {
            const linkFormState = formState[link.cardId];
            const isHidden = !isEmpty(linkFormState) ? get(linkFormState, 'isHidden') : false;
            const title = !isEmpty(linkFormState) ? get(linkFormState, 'title') : reportName;
            return {
                ...link,
                isHidden,
                title,
            };
        })
        .value();

    return updatedLinks;
};

export const currentReportDefinitionIdSelector = createSelector(
    uiSelector,
    (ui) => ui.selectedReportDefinitionId
);

export const selectedReportDefinitionSelector = createSelector(
    [uiSelector, reportDefinitionsForCurrentDepartmentSelector],
    (ui, definitions) => {
        if (!ui.selectedReportDefinitionId) {
            return;
        }
        return definitions[ui.selectedReportDefinitionId];
    }
);

export const getCustomReportsAdminListSelector = createSelector(
    [reportDefinitionsForCurrentDepartmentSelector, selectedReportDefinitionSelector],
    (reportDefinitions, selectedReportDefinition) => (showDisabled) => {
        return (
            _(reportDefinitions)
                .filter((definition) => {
                    return showDisabled ? true : definition.isEnabled || definition.isActive;
                })
                // map definition to object to pass as `items` for AdminList in CustomReportsAdmin
                .map((definition) => {
                    let status = attributeStatuses.ACTIVE;
                    if (!definition.isEnabled) {
                        status = attributeStatuses.DISABLED;
                    } else if (!definition.isActive) {
                        status = attributeStatuses.EXPIRED;
                    }
                    return {
                        title: definition.name,
                        key: definition.id,
                        selected: definition.id === _.get(selectedReportDefinition, 'id'),
                        status,
                    };
                })
                .sortBy('title')
                .value()
        );
    }
);

export const getFilledDownloadablesForms = (state) => {
    return getFormsListFromStateByFormKey(
        state,
        'customReportsAdminDownloadables',
        state.ui.customReportsAdmin.editingDownloadableIds
    );
};

// This selector is more or less a hack to aid in admin page validatoin. We should be able to deprecate this when we
// updgrade redux-form or move to react-redux-form
export const downloadablesDisplayNamesSelector = (state) =>
    map(state.form.customReportsAdminDownloadables, (form) => get(form, 'displayName.value'));

import {
    find,
    identity,
    includes,
    map,
    reduce,
    reject,
    sortBy,
    uniqBy,
    filter,
    pickBy,
} from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import type { DeepPartial } from 'utility-types';
import moment from 'moment';

import {
    ElasticEntityPermissionQuery,
    ElasticStorageLocation,
    ElasticStorageLocationQuery,
    Facility,
    OperationTypeEnum,
    SearchResultElasticStorageLocation,
} from '@mark43/evidence-api';
import type { ModuleShape } from '~/client-common/redux/state';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import {
    formatStorageLocationNames,
    appendExpiredLabelIfExpired,
} from '~/client-common/core/domain/storage-locations/utils/storageLocationHelpers';
import type { ClientElasticStorageLocation } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { elasticStorageLocationsSelector } from '~/client-common/core/domain/elastic-storage-locations/state/data';
import { facilitiesSelector } from '~/client-common/core/domain/facilities/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';

import evidenceDashboardSearchForm from '../../../../evidence/dashboard/state/forms/evidenceDashboardSearchForm';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import type { GroupedOption, SelectOption } from '../../helpers/selectHelpers';
import {
    convertFieldsToOptionValues,
    convertOptionValuesToFieldValues,
    mapFieldValueToOptionValue,
} from './MultiFieldSelect';
import AsyncSelect from './AsyncSelect';
import type { SelectProps } from './Select';

const strings = componentStrings.evidence.core.FacilityStorageLocationSelect;

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;

type Options =
    | [GroupedOption<SelectOption>]
    | [GroupedOption<SelectOption>, GroupedOption<SelectOption>];

function convertFacilityToOption(
    facility: Facility,
    isEvidenceFiltersEnabled: boolean
): SelectOption {
    return {
        value: mapFieldValueToOptionValue('facilityId', `${facility.id}`),
        label: isEvidenceFiltersEnabled
            ? appendExpiredLabelIfExpired(facility.locationName, facility.expiredDateUtc)
            : facility.locationName,
    };
}

function buildFacilityGroup(
    facilities: ModuleShape<Facility>,
    isEvidenceFiltersEnabled: boolean
): GroupedOption<SelectOption> {
    return {
        label: strings.facilities,
        options: sortBy(
            map(facilities, (facility) =>
                convertFacilityToOption(facility, isEvidenceFiltersEnabled)
            ),
            'label'
        ),
    };
}

function convertStorageLocationToOption(
    elasticStorageLocation: ClientElasticStorageLocation,
    isEvidenceFiltersEnabled: boolean
): SelectOption {
    return {
        value: mapFieldValueToOptionValue('storageLocationId', `${elasticStorageLocation.id}`),
        label: isEvidenceFiltersEnabled
            ? appendExpiredLabelIfExpired(
                  formatStorageLocationNames(elasticStorageLocation.parentLocationNames),
                  elasticStorageLocation.expiredDateUtc
              )
            : formatStorageLocationNames(elasticStorageLocation.parentLocationNames),
        subtitle: elasticStorageLocation.barcodeValue,
    };
}

function buildStorageLocationGroup(
    selectedOptions: SelectOption[],
    unselectedOptions: SelectOption[]
): GroupedOption<SelectOption> {
    return {
        label: strings.storageLocations,
        options: uniqBy([...selectedOptions, ...unselectedOptions], 'value'),
    };
}

function useOptions(
    selectedStorageLocationIds: string[] | undefined
): {
    // facility options always exist as they are all loaded on bootstrap
    // storage location options may not exist sincce they are searched from the server
    options: Options;
    cacheSelectedValues: (values: string[]) => void;
    replaceStorageLocationOptions: (elasticStorageLocations: ElasticStorageLocation[]) => void;
    clearStorageLocationOptions: () => void;
} {
    // the first cache represents the currently selected options, which must persist when the search results change
    // the second cache represents search results from the server, which change independently of the currently selected options
    // these 2 array may have duplicate values, we combine and de-duplicate them in buildStorageLocationGroup
    const [selectedStorageLocationOptions, setSelectedStorageLocationOptions] = React.useState<
        SelectOption[]
    >([]);
    const [searchedStorageLocationOptions, setSearchedStorageLocationOptions] = React.useState<
        SelectOption[]
    >([]);

    const facilities = useSelector(facilitiesSelector);
    const elasticStorageLocations = useSelector(elasticStorageLocationsSelector);
    // @ts-expect-error client-common to client RND-7529
    const formModel = useSelector(evidenceDashboardSearchForm.selectors.formModelSelector);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const isEvidenceFiltersEnabled = !!applicationSettings.EVIDENCE_FILTER_SORT_UPDATES;
    const [filteredFacilities, setFilteredFacilities] = React.useState(facilities);

    React.useEffect(() => {
        // @ts-expect-error selector is untyped
        if (formModel?.excludeExpiredStorageLocations) {
            setFilteredFacilities(
                pickBy(
                    facilities,
                    (facility) =>
                        moment(facility.expiredDateUtc).isAfter(moment()) ||
                        facility.expiredDateUtc == null
                )
            );
        } else {
            setFilteredFacilities(facilities);
        }
    }, [
        facilities,
        // @ts-expect-error selector is untyped
        formModel?.excludeExpiredStorageLocations,
    ]);

    React.useEffect(() => {
        const cachedValues = map(selectedStorageLocationOptions, 'value');
        // find any values that are not yet cached, and add them to the options cache using data from Redux state
        const newIds = reject(selectedStorageLocationIds, (id) =>
            includes(cachedValues, mapFieldValueToOptionValue('storageLocationId', id))
        );
        // filter out any expired locations, locations that are expired will be undefined
        const newLocations = reject(
            map(newIds, (id) => elasticStorageLocations[id]),
            (loc) => loc === undefined
        );
        if (newLocations.length > 0) {
            setSelectedStorageLocationOptions((cache) => [
                ...cache,
                ...map(newLocations, (location) =>
                    convertStorageLocationToOption(location, isEvidenceFiltersEnabled)
                ),
            ]);
        }
    }, [
        selectedStorageLocationIds,
        selectedStorageLocationOptions,
        elasticStorageLocations,
        isEvidenceFiltersEnabled,
    ]);

    // build the dropdown options in 1 or 2 groups
    const options: Options = React.useMemo(() => {
        const facilityGroup = buildFacilityGroup(filteredFacilities, isEvidenceFiltersEnabled);

        if (selectedStorageLocationOptions.length + searchedStorageLocationOptions.length > 0) {
            const storageLocationGroup = buildStorageLocationGroup(
                selectedStorageLocationOptions,
                searchedStorageLocationOptions
            );
            return [facilityGroup, storageLocationGroup];
        } else {
            return [facilityGroup];
        }
    }, [
        filteredFacilities,
        selectedStorageLocationOptions,
        searchedStorageLocationOptions,
        isEvidenceFiltersEnabled,
    ]);

    // update the cache whenever a facility or storage location is added or removed from the selected values
    const cacheSelectedValues = React.useCallback(
        (values: string[]) => {
            const newSelectedOptions = reduce<string, SelectOption[]>(
                values,
                (acc, value) => {
                    const storageLocationOptions = options[1];
                    const storageLocationOption = find(storageLocationOptions?.options, {
                        value,
                    });
                    if (storageLocationOption) {
                        return [...acc, storageLocationOption];
                    }
                    return acc;
                },
                []
            );
            setSelectedStorageLocationOptions(newSelectedOptions);
        },
        [options]
    );

    // update the cache with new search results
    const replaceStorageLocationOptions = React.useCallback(
        (storageLocations: ElasticStorageLocation[]) => {
            setSearchedStorageLocationOptions(
                map(storageLocations, (location) =>
                    convertStorageLocationToOption(location, isEvidenceFiltersEnabled)
                )
            );
        },
        [isEvidenceFiltersEnabled]
    );

    const clearStorageLocationOptions = React.useCallback(() => {
        setSearchedStorageLocationOptions([]);
    }, []);

    return {
        options,
        cacheSelectedValues,
        replaceStorageLocationOptions,
        clearStorageLocationOptions,
    };
}

const storageLocationPermissionsQuery: Partial<ElasticEntityPermissionQuery>[] = [
    {
        operationTypes: [OperationTypeEnum.READ.name, OperationTypeEnum.MANAGE.name],
    },
];

const FacilityStorageLocationSelect: React.FC<
    SelectProps<SelectOption, true> & {
        fields: unknown;
    }
> = (props) => {
    const { onChange, fields } = props;
    const values = convertFieldsToOptionValues(fields);
    const {
        options,
        cacheSelectedValues,
        replaceStorageLocationOptions,
        clearStorageLocationOptions,
    } = useOptions(
        // @ts-expect-error TODO: type connectRRFMultiFieldInput
        fields?.storageLocationId?.value as string[] | undefined
    );

    const applicationSettings = useSelector(applicationSettingsSelector);
    // @ts-expect-error client-common to client RND-7529
    const formModel = useSelector(evidenceDashboardSearchForm.selectors.formModelSelector);
    const asyncAction = React.useCallback(
        (query: string) => {
            const elasticQuery: DeepPartial<ElasticStorageLocationQuery> = {
                query,
                isFacility: false,
                ...(applicationSettings.RMS_USE_EVD_LOCATION_PERMS_ENABLED
                    ? {
                          storageLocationPermissionsQuery,
                      }
                    : {}),
            };

            return (
                elasticSearchResource
                    .searchStorageLocations(
                        elasticQuery,
                        0,
                        100,
                        undefined,
                        undefined,
                        undefined,
                        // hide loading bar
                        true
                    )
                    // @ts-expect-error TODO: type the resource method
                    .then((result: SearchResultElasticStorageLocation) => {
                        if (result.items) {
                            const excludeExpiredStorageLocations =
                                // @ts-expect-error formModelSelector is untyped
                                formModel?.excludeExpiredStorageLocations;
                            const resultLocations = excludeExpiredStorageLocations
                                ? filter(
                                      result.items,
                                      (location) =>
                                          moment(location.expiredDateUtc).isAfter(moment()) ||
                                          !location.expiredDateUtc
                                  )
                                : result.items;

                            replaceStorageLocationOptions(resultLocations);
                        }
                    })
            );
        },
        [
            applicationSettings,
            replaceStorageLocationOptions,
            // @ts-expect-error formModelSelector is untyped
            formModel?.excludeExpiredStorageLocations,
        ]
    );

    const handleChange = React.useCallback(
        (values: string[]) => {
            cacheSelectedValues(values);

            if (onChange) {
                const fieldValues = convertOptionValuesToFieldValues(values);
                // @ts-expect-error TODO: type connectRRFMultiFieldInput
                onChange(fieldValues);
            }
        },
        [cacheSelectedValues, onChange]
    );

    return (
        <AsyncSelect
            {...props}
            multiple={true}
            value={values}
            options={options}
            onChange={handleChange}
            // clear the cache on blur, otherwise the cached dropdown options would quickly appear and disappear the
            // next time the dropdown is opened, since the search results will be different
            onBlur={clearStorageLocationOptions}
            asyncAction={asyncAction}
            showSelectedGroup={false}
            // ignore default sorting function in Select
            sortAttributes={identity}
            typeaheadThrottle={600}
        />
    );
};

/**
 * Typeahead for searching both facilities and storage locations. The dropdown options are separated into 2 groups.
 *
 * The first group is facilities.
 * - Unlike FacilitySelect, this component does not use facilityOptionsSelector to get options.
 * - Facilities are filtered synchronously since they are all loaded on app bootstrap.
 *
 * The second group is storage locations.
 * - Storage locations are searched asynchronously.
 * - The sort order of search results from the server must be preserved. This component ignores the default front end
 *   sort logic for selects, otherwise it would show irrelevant results at the top.
 * - Unlike FacilitySelect and DeprecatedStorageLocationSelect, this component does not store new search results in
 *   global Redux state. Instead, it uses local state and directly converts new search results into dropdown options. It
 *   reads from Redux state using elasticStorageLocationsSelector only to show options for the initially selected
 *   values.
 * - The currently selected values are also cached in local state in order to persist as options, otherwise those values
 *   would disappear from the select input when the search results are cleared.
 *
 * If either group has no search results that match the user's query, then the group does not appear in the dropdown.
 */
export default FacilityStorageLocationSelect;

export const RRFFacilityStorageLocationSelect = connectRRFMultiFieldInput(
    FacilityStorageLocationSelect
);

const logicalStructures = {
    HIDE_ATTRIBUTE_OTHER: {
        name: 'HIDE_ATTRIBUTE_OTHER',
        title: 'Hide other attribute description',
    },
    HIDE_ATTRIBUTE_OTHER_2: {
        name: 'HIDE_ATTRIBUTE_OTHER_2',
        title: 'Hide other attribute description - rules that do not follow the common pattern',
    },
    HIDE_COMPARE_2: {
        name: 'HIDE_COMPARE_2',
        title: 'Show/hide fields depending on a comparison between 2 other fields',
    },
    HIDE_FIELDS_1: {
        name: 'HIDE_FIELDS_1',
        title: 'Show/hide fields depending on the value of another field',
    },
    NIBRS_PROPERTY: {
        name: 'NIBRS_PROPERTY',
        title: 'NIBRS property validation',
        subtitle: 'CONDITIONAL rules',
    },
    OTHER: {
        name: 'OTHER',
        title: 'Unknown RuleType',
    },
    OTHER_CONDITIONAL: {
        name: 'OTHER_CONDITIONAL',
        title: 'Misc conditional rules',
        subtitle: 'CONDITIONAL rules that do not fit into the other categories',
    },
    OTHER_HIDE: {
        name: 'OTHER_HIDE',
        title: 'Misc hide rules',
        subtitle: 'Hide rules that do not fit into the other categories',
    },
    OTHER_VALIDATION: {
        name: 'OTHER_VALIDATION',
        title: 'Misc validation rules',
        subtitle: 'Validation rules that do not fit into the other categories',
    },
    VALIDATION_ATTRIBUTE_OTHER: {
        name: 'VALIDATION_ATTRIBUTE_OTHER',
        title: 'Require other attribute description',
    },
    VALIDATION_IS_NOT_EMPTY_1: {
        name: 'VALIDATION_IS_NOT_EMPTY_1',
        title: 'Require 1 field',
    },
    VALIDATION_IS_NOT_EMPTY_2: {
        name: 'VALIDATION_IS_NOT_EMPTY_2',
        title: 'Require 1 field without RuleActionedField',
    },
    VALIDATION_NO_RAF: {
        name: 'VALIDATION_NO_RAF',
        title: 'Validation rules with no actioned fields',
    },
    VALIDATION_COMPARE_2: {
        name: 'VALIDATION_COMPARE_2',
        title: 'Compare 2 fields',
    },
    VALIDATION_LONG: {
        name: 'VALIDATION_LONG',
        title: 'Validation rules with many conditions',
    },
    VALIDATION_SUBDIVISION: {
        name: 'VALIDATION_SUBDIVISION',
        title: 'Require subdivision',
    },
    OR_2_N_ITEMS: {
        name: 'OR_2_N_ITEMS',
        title: 'Require a field based on either of 2 fields',
        subtitle: 'Some of these are NItems',
    },
} as const;

/**
 * Categorization of Rules by the logical structure of their RuleConditions. This is used by
 *   engineers to find and read existing Rules.
 *
 * These structures don't have to be unique, meaning multiple structures can apply to the same Rule.
 */
export default logicalStructures;

import { EntityTypeEnum, UsageSourceModuleEnum, UsageActionEnum } from '@mark43/rms-api';
import React from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withHandlers, pure, withPropsOnChange } from 'recompose';
import { chain, compact, findIndex, first, isFunction, map, size } from 'lodash';
import { HStack } from 'arc';

import {
    augmentedAttachmentViewModelsWhereSelector,
    convertAttachmentViewModelsForLightboxGallery,
} from '~/client-common/core/domain/attachments/state/ui';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { sortByNaturalOrder } from '~/client-common/helpers/arrayHelpers';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import { currentDepartmentDateFormatterSelector } from '~/client-common/core/domain/current-user/state/ui';
import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import Card, { CardSection } from '../../../../legacy-redux/components/core/Card';
import withCard from '../../../reports/core/utils/withCard';
import attachmentsCard from '../../../warrants/warrant/state/cards/attachmentsCard';
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import AttachmentViewToggleButton, {
    attachmentViews,
} from '../../../attachments/core/components/AttachmentViewToggleButton';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import { openImageGalleryLightbox } from '../../../../legacy-redux/actions/imageGalleryLightboxActions';
import { exportFiles } from '../../../../legacy-redux/actions/exportsActions';
import _PaddedNoDataBlock from '../PaddedNoDataBlock';
import Icon, { iconTypes } from '../Icon';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import TableHeaderButton from '../../../../legacy-redux/components/core/tables/TableHeaderButton';
import _AddEntityLink from '../AddEntityLink';
import DebouncedText from '../../forms/components/DebouncedText';
import _Text from '../../forms/components/Text';
import AttachmentRow from '../../../attachments/core/components/AttachmentRow';
import AttachmentGridItem from '../../../attachments/core/components/AttachmentGridItem';
import { computeTotalFileSize, downloadSelectFile } from '../../../attachments/files/helpers';

import { responsiveStack } from '../../styles/mixins';
import _OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import { initializeAttachmentsSidePanelForm } from '../../../attachments/core/state/ui/attachmentsSidePanel';
import { createUsageLog } from '../../../admin/usage-logs/state/data';
import testIds from '../../../../core/testIds';
import { overlayStore } from '../../../../core/overlayManager';
import AttachmentsSidePanel from '../../../attachments/core/components/AttachmentsSidePanel';
import { AttachmentsMenu } from '../../../attachments/core/components/AttachmentsMenu';
import { registerCard } from '../../../reports/core/utils/cardsRegistry';
import { currentUserHasAbilitySelector } from '../../current-user/state/ui';
import { currentReportCardUITitleByTypeSelector } from '../../../../legacy-redux/selectors/reportSelectors';
import { getEmailButtonState, emailAttachments } from '../../emails/helpers';
import SendEmailModal from '../../emails/components/SendEmailModal';

const strings = componentStrings.core.cards.AttachmentsCard;

const NUM_OF_RESULTS_ON_PAGE = 30;

const tableWidths = {
    displayName: '40%',
    createdDateUtc: '40%',
    actions: '20%',
};

const sortTypes = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING',
};

const AddEntityLink = styled(_AddEntityLink)`
    padding: 18px 0 18px 0;
`;

const FilterText = styled(_Text)`
    margin: 0;
`;

const TableHeader = styled.div`
    ${responsiveStack()}
    margin-bottom: 10px;
    justify-content: space-between;
    gap: var(--arc-space-3);
    align-items: end;
`;

const AttachmentsTableHeaders = styled.table`
    width: 100%;
    padding-top: 40px;
`;

const AttachmentHeader = styled.tr`
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey};
`;

const AttachmentHeaderCell = styled.td`
    text-align: left;

    &:last-child {
        text-align: right;
        padding-right: 7px;
    }
`;

const DirectionIcon = styled(Icon)`
    margin-left: 5px;
`;

const OptionsTableColumn = styled(_OptionsTableColumn)`
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    font-family: ${(props) => props.theme.fontFamilies.proximaNova}, sans-serif;
    font-size: var(--arc-fontSizes-md);
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.cobaltBlue};
    text-transform: uppercase;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;

    &:hover {
        background-color: ${(props) => props.theme.colors.lightBlue};
    }
`;

const OptionTableColumnDisplayContainer = styled.div`
    display: flex;
    align-items: center;
`;

const AttachmentsCardSection = styled(CardSection)`
    padding: 0 12px 0 12px;
`;

const CenteredCardSection = styled(CardSection)`
    text-align: center;
    padding: 0 10px 0 10px;
`;

const PaddedNoDataBlock = styled(_PaddedNoDataBlock)`
    padding: 10px 20px;
`;

const Footer = styled.div`
    text-align: right;
    clear: both;
    padding: 16px 0;
`;

function getPagination(items, page, pageSize = NUM_OF_RESULTS_ON_PAGE) {
    const zeroBasedPage = page - 1;
    const sliceOffset = zeroBasedPage * pageSize;
    const results = items.slice(sliceOffset, sliceOffset + pageSize);

    const paginationItemsTo = sliceOffset + results.length;

    return {
        paginationItemsFrom: sliceOffset,
        paginationItemsTo,
        numTotalResults: items.length,
        results,
    };
}

function AttachmentCardContent({
    index,
    attachmentRows,
    summaryMode,
    sortKey,
    sortType,
    fileGridAttachments,
    paginationItemsFrom,
    paginationItemsTo,
    totalAttachments,
    currentView,
    filter,
    currentPage,
    handleSearchChange,
    setSort,
    toggleView,
    handlePaginationClick,
    entityLinkDetails,
    initializeAttachmentsSidePanelForm,
    attachmentMenu,
}) {
    return (
        <div>
            {size(fileGridAttachments) > 0 && (
                <AttachmentsCardSection>
                    <TableHeader>
                        {fileGridAttachments.length > NUM_OF_RESULTS_ON_PAGE ? (
                            <DebouncedText
                                value={filter}
                                onChange={handleSearchChange}
                                placeholder={strings.searchTextPlaceholder}
                                TextComponent={FilterText}
                            />
                        ) : (
                            <FilterText
                                value={filter}
                                onChange={handleSearchChange}
                                placeholder={strings.searchTextPlaceholder}
                            />
                        )}
                        <HStack>
                            <TableResultsSummary
                                from={paginationItemsFrom}
                                to={paginationItemsTo}
                                totalResults={totalAttachments.length}
                                caption=""
                            />
                            {attachmentMenu}
                        </HStack>
                    </TableHeader>
                    <AttachmentsTableHeaders>
                        <thead>
                            {currentView === attachmentViews.LIST ? (
                                <AttachmentHeader>
                                    <AttachmentHeaderCell width={tableWidths.displayName}>
                                        <TableHeaderButton
                                            activeSortKey={sortKey}
                                            activeSortType={sortType}
                                            sortKey="displayName"
                                            onClick={setSort}
                                        >
                                            {strings.name}
                                        </TableHeaderButton>
                                    </AttachmentHeaderCell>
                                    <AttachmentHeaderCell width={tableWidths.createdDateUtc}>
                                        <TableHeaderButton
                                            activeSortKey={sortKey}
                                            activeSortType={sortType}
                                            sortKey="createdDateUtc"
                                            onClick={setSort}
                                        >
                                            {strings.uploadDate}
                                        </TableHeaderButton>
                                    </AttachmentHeaderCell>
                                    <AttachmentHeaderCell width={tableWidths.actions}>
                                        <AttachmentViewToggleButton
                                            currentView={currentView}
                                            onClick={toggleView}
                                        />
                                    </AttachmentHeaderCell>
                                </AttachmentHeader>
                            ) : (
                                <AttachmentHeader>
                                    <AttachmentHeaderCell>
                                        <OptionsTableColumn
                                            display={(data) => (
                                                <OptionTableColumnDisplayContainer>
                                                    {data.display}
                                                    <DirectionIcon
                                                        color="cobaltBlue"
                                                        type={
                                                            sortType === sortTypes.DESCENDING
                                                                ? iconTypes.ARROW_UP
                                                                : iconTypes.ARROW_DOWN
                                                        }
                                                        size={10}
                                                    />
                                                </OptionTableColumnDisplayContainer>
                                            )}
                                            activeValue={sortKey}
                                            activeSortType={sortType}
                                            onOptionClick={(data) => setSort(data.value)}
                                            onSortTypeClick={() => setSort(sortKey)}
                                            popoutWidth={160}
                                        >
                                            <OptionsTableColumnOption
                                                display={strings.name}
                                                value={'displayName'}
                                                sortOptions={[
                                                    {
                                                        display: `${strings.name} A-Z`,
                                                        sortType: sortTypes.ASCENDING,
                                                    },
                                                    {
                                                        display: `${strings.name} Z-A`,
                                                        sortType: sortTypes.DESCENDING,
                                                    },
                                                ]}
                                            />
                                            <OptionsTableColumnOption
                                                display={strings.uploadDate}
                                                value={'createdDateUtc'}
                                                sortOptions={[
                                                    {
                                                        display: strings.sortDateAsc,
                                                        sortType: sortTypes.ASCENDING,
                                                    },
                                                    {
                                                        display: strings.sortDateDesc,
                                                        sortType: sortTypes.DESCENDING,
                                                    },
                                                ]}
                                            />
                                        </OptionsTableColumn>
                                    </AttachmentHeaderCell>
                                    <AttachmentHeaderCell width={tableWidths.createdDateUtc} />
                                    <AttachmentHeaderCell width={tableWidths.actions}>
                                        <AttachmentViewToggleButton
                                            currentView={currentView}
                                            onClick={toggleView}
                                        />
                                    </AttachmentHeaderCell>
                                </AttachmentHeader>
                            )}
                        </thead>
                    </AttachmentsTableHeaders>
                    {attachmentRows}
                    {totalAttachments.length > NUM_OF_RESULTS_ON_PAGE && (
                        <Footer>
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={NUM_OF_RESULTS_ON_PAGE}
                                itemCount={totalAttachments.length}
                                onClick={handlePaginationClick}
                                maxEdgeItems={1}
                            />
                        </Footer>
                    )}
                </AttachmentsCardSection>
            )}
            <CenteredCardSection>
                {totalAttachments.length < 1 && summaryMode && (
                    <PaddedNoDataBlock>{strings.noData}</PaddedNoDataBlock>
                )}
                {!summaryMode && (
                    <AttachmentsSidePanel
                        getInitialCustomPropertyState={() => entityLinkDetails}
                        overlayId={`${overlayIdEnum.ATTACHMENTS_CARD_ATTACHMENTS_SIDE_PANEL}${
                            index ? `.${index}` : ''
                        }`}
                        renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => {
                            function handleAddClick() {
                                initializeAttachmentsSidePanelForm(entityLinkDetails);
                                open();
                            }

                            return (
                                <AddEntityLink
                                    className={buttonTypes.ICON_LINK}
                                    iconLeft={<Icon type={iconTypes.ADD} />}
                                    onClick={handleAddClick}
                                    testId={testIds.ATTACHMENTS_CARD_MANAGE_ATTACHMENTS_BUTTON}
                                    setRef={setCloseFocusRefs}
                                >
                                    {strings.addEdit}
                                </AddEntityLink>
                            );
                        }}
                    />
                )}
            </CenteredCardSection>
        </div>
    );
}

class AttachmentsCard extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            filter: '',
            currentView: attachmentViews.LIST,
            sortType: sortTypes.ASCENDING,
            sortKey: 'displayName',
            currentPage: 1,
        };

        this.onEdit = this.onEdit.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePaginationClick = this.handlePaginationClick.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.setSort = this.setSort.bind(this);

        this.unregisterCard = registerCard({
            cardModule: attachmentsCard,
            onSave: this.onSave,
            index: this.props.index,
        });
    }

    componentWillUnmount() {
        this.unregisterCard();
    }

    onEdit() {
        // Attachments card on reports uses `editMediator`, which is unused for warrants.
        if (isFunction(this.props.editCallback) && !this.props.canMasterEditAttachments) {
            this.props.editCallback(() => this.props.onEdit({ index: this.props.index }));
        } else {
            this.props.onEdit({ index: this.props.index });
        }
    }

    onSave() {
        return this.props.onSave({ index: this.props.index });
    }

    handlePaginationClick(currentPage, nextPage) {
        this.setState({ currentPage: nextPage });
    }

    handleSearchChange(filterText = '') {
        this.setState({
            filter: filterText,
            currentPage: 1,
        });
    }

    toggleView() {
        this.setState({
            currentView:
                this.state.currentView === attachmentViews.LIST
                    ? attachmentViews.GRID
                    : attachmentViews.LIST,
        });
    }

    setSort(sortKey) {
        this.setState({
            sortKey,
            sortType:
                this.state.sortKey === sortKey && this.state.sortType === sortTypes.ASCENDING
                    ? sortTypes.DESCENDING
                    : sortTypes.ASCENDING,
        });
    }

    render() {
        const {
            card = {},
            index,
            fileGridAttachments,
            initializeAttachmentsSidePanelForm,
            generateExportAttachmentUsageLog,
            download,
            openLightbox,
            openEmailModal,
            canEdit,
            canEditErrorMessage,
            currentReportCardUITitleByType,
            emailsEnabled = false,
        } = this.props;

        const { currentView, sortKey, sortType, filter, currentPage } = this.state;
        const lowercaseFilter = this.state.filter.toLowerCase();
        const totalAttachments = chain(fileGridAttachments)
            .filter(
                (attachment) =>
                    `${attachment.displayName}`.toLowerCase().indexOf(lowercaseFilter) !== -1
            )
            .thru((arr) =>
                sortByNaturalOrder(arr, [sortKey], [sortType === 'ASCENDING' ? 'asc' : 'desc'])
            )
            .value();

        const cardTitle = currentReportCardUITitleByType(reportCardEnum.ATTACHMENTS.id);

        const {
            paginationItemsFrom,
            paginationItemsTo,
            results, // this gets the sliced results
        } = getPagination(totalAttachments, this.state.currentPage);

        const { entityId, entityType, linkType } = this.props;
        const entityLinkDetails = { entityId, entityType, attachmentLinkType: linkType };

        const attachmentRows =
            currentView === attachmentViews.LIST ? (
                <div>
                    {map(results, (attachment) => (
                        <AttachmentRow
                            tableWidths={tableWidths}
                            onClickForLightBox={openLightbox(totalAttachments, attachment.id)}
                            onDownload={() => generateExportAttachmentUsageLog(attachment)}
                            onEmail={() => openEmailModal([attachment])}
                            key={attachment.id}
                            attachment={attachment}
                            columns={['displayName', 'createdDateUtc', 'actions']}
                            emailsEnabled={emailsEnabled}
                        />
                    ))}
                </div>
            ) : (
                map(results, (attachment) => (
                    <AttachmentGridItem
                        onClickForLightBox={openLightbox(totalAttachments, attachment.id)}
                        onDownload={() => generateExportAttachmentUsageLog(attachment)}
                        onEmail={() => openEmailModal([attachment])}
                        key={attachment.id}
                        attachment={attachment}
                    />
                ))
            );

        const size = computeTotalFileSize(totalAttachments);
        const emailButtonState = getEmailButtonState(totalAttachments.length, true, size);

        const attachmentMenuItems = [
            {
                title: strings.downloadResults,
                disabled: totalAttachments.length === 0,
                onClick: () => download(totalAttachments),
            },
        ];

        return (
            <>
                <Card
                    className={card.anchor}
                    anchor={card.anchorForIndex(index)}
                    title={`${cardTitle} (${fileGridAttachments.length})`}
                    renderContent={(summaryMode) => (
                        <AttachmentCardContent
                            index={index}
                            summaryMode={summaryMode}
                            attachmentRows={attachmentRows}
                            paginationItemsTo={paginationItemsTo}
                            paginationItemsFrom={paginationItemsFrom}
                            initializeAttachmentsSidePanelForm={initializeAttachmentsSidePanelForm}
                            entityLinkDetails={entityLinkDetails}
                            sortKey={sortKey}
                            sortType={sortType}
                            fileGridAttachments={fileGridAttachments}
                            totalAttachments={totalAttachments}
                            currentView={currentView}
                            filter={filter}
                            currentPage={currentPage}
                            handleSearchChange={this.handleSearchChange}
                            setSort={this.setSort}
                            toggleView={this.toggleView}
                            handlePaginationClick={this.handlePaginationClick}
                            attachmentMenu={
                                <FeatureFlagged
                                    flag="RMS_DIRECT_EMAILS_ENABLED"
                                    fallback={<AttachmentsMenu items={attachmentMenuItems} />}
                                >
                                    <AttachmentsMenu
                                        items={[
                                            ...attachmentMenuItems,
                                            {
                                                title: strings.emailResults,
                                                disabled: emailButtonState.isDisabled,
                                                tooltip: emailButtonState?.tooltip,
                                                onClick: () => openEmailModal(totalAttachments),
                                            },
                                        ]}
                                    />
                                </FeatureFlagged>
                            }
                        />
                    )}
                    summaryMode={card.summaryMode}
                    canEdit={canEdit}
                    canEditErrorMessage={canEditErrorMessage}
                    onEdit={this.onEdit}
                    errors={card.errorMessages}
                    saving={card.saving}
                    onSave={this.onSave}
                    testId={testIds.ATTACHMENTS_CARD}
                />
                <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                    {emailsEnabled && <SendEmailModal />}
                </FeatureFlagged>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    augmentedAttachmentViewModelsWhere: augmentedAttachmentViewModelsWhereSelector,
    currentUserHasAbility: currentUserHasAbilitySelector,
    dateTimeFormatter: currentDepartmentDateFormatterSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    initializeAttachmentsSidePanelForm: (entityLinkDetails) =>
        dispatch(initializeAttachmentsSidePanelForm(entityLinkDetails)),
    download: (attachments) => {
        const { entityType, entityId, entityTitle } = ownProps;
        const files = map(attachments, getAttachmentFile);
        const fileIds = compact(map(files, 'id'));
        const bulkDownloadTitle = componentStrings.attachments.core.bulkDownloadTitle(entityTitle);

        const usageLog = {
            primaryEntityType: entityType,
            primaryEntityId: entityId,
            sourceModule:
                entityType === EntityTypeEnum.REPORT.name
                    ? UsageSourceModuleEnum.REPORTS.name
                    : UsageSourceModuleEnum.WARRANTS.name,
        };

        if (size(fileIds) === 1) {
            const firstFile = first(files);
            downloadSelectFile(firstFile.fileWebServerPath, firstFile.originalFileName);
            dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityTitle: `${entityTitle}: ${firstFile.id} - ${firstFile.originalFileName}`,
                    action: UsageActionEnum.DOWNLOADED_ATTACHMENT.name,
                })
            );
        } else if (size(fileIds) > 1) {
            dispatch(exportFiles(fileIds, bulkDownloadTitle));
            dispatch(
                createUsageLog({
                    ...usageLog,
                    primaryEntityTitle: `${entityTitle} (${fileIds.length} Attachments)`,
                    action: UsageActionEnum.DOWNLOADED_BULK_ATTACHMENTS.name,
                })
            );
        }
    },
    generateExportAttachmentUsageLog: (attachment) => {
        const { entityType, entityId, entityTitle } = ownProps;
        const file = getAttachmentFile(attachment);
        const usageLog = {
            primaryEntityType: entityType,
            primaryEntityId: entityId,
            primaryEntityTitle: `${entityTitle}: ${file.id} - ${file.originalFileName}`,
            action: UsageActionEnum.DOWNLOADED_ATTACHMENT.name,
            sourceModule:
                entityType === EntityTypeEnum.REPORT.name
                    ? UsageSourceModuleEnum.REPORTS.name
                    : UsageSourceModuleEnum.WARRANTS.name,
        };
        dispatch(createUsageLog(usageLog));
    },
    openImageGalleryLightbox: (images, index) => {
        const { entityType, entityTitle, emailsEnabled } = ownProps;
        const source =
            entityType === EntityTypeEnum.REPORT.name
                ? UsageSourceModuleEnum.REPORTS.name
                : UsageSourceModuleEnum.WARRANTS.name;
        const usageLogParamsForAttachments = {
            primaryEntityTitle: entityTitle,
            sourceModule: source,
        };
        dispatch(
            openImageGalleryLightbox(images, index, usageLogParamsForAttachments, emailsEnabled)
        );
    },
    openEmailModal: (attachments) => {
        const { entityType, entityId } = ownProps;
        const handleEmailSave = (emailData) => {
            const attachmentFileIds = attachments
                .map((item) => getAttachmentFile(item).id)
                .filter(Boolean);

            return emailAttachments({
                emailData,
                attachmentFileIds,
                entityType,
                entityId,
            });
        };

        overlayStore.open(overlayIdEnum.SEND_EMAIL_MODAL, {
            onSave: handleEmailSave,
        });
    },
});

export default compose(
    withCard(attachmentsCard),
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true }),
    withPropsOnChange(
        [
            'entityType',
            'entityId',
            'linkType',
            'augmentedAttachmentViewModelsWhere',
            'currentUserHasAbility',
        ],
        ({
            entityType,
            entityId,
            linkType,
            augmentedAttachmentViewModelsWhere,
            currentUserHasAbility,
        }) => {
            const fileGridAttachments = augmentedAttachmentViewModelsWhere({
                entityType,
                entityId,
                linkType,
            });
            const canMasterEditAttachments = currentUserHasAbility(
                abilitiesEnum.REPORTING.MASTER_ATTACHMENT_EDIT
            );
            return {
                fileGridAttachments,
                canMasterEditAttachments,
            };
        }
    ),
    withHandlers({
        openLightbox: ({ openImageGalleryLightbox, dateTimeFormatter }) => (
            attachments,
            attachmentId
        ) => () => {
            const index = findIndex(attachments, { id: attachmentId });
            openImageGalleryLightbox(
                convertAttachmentViewModelsForLightboxGallery(attachments, dateTimeFormatter),
                index
            );
        },
    }),
    pure
)(AttachmentsCard);

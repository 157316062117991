import { Reducer, combineReducers } from 'redux';

import { DataState as ClientCommonDataState } from '~/client-common/redux/state';
import settingsReducer from '~/client-common/core/domain/settings/state/data';
import evidenceDepartmentConfigReducer from '~/client-common/core/domain/evidence-department-config/state/data';
import productModulesReducer from '~/client-common/core/domain/product-modules/state/data';
import notificationTypesReducer from '~/client-common/core/domain/notification-types/state/data';

import { dragonDataReducer } from '../../modules/dragon/dragonRedux';

import { userRolesDataReducer } from './rolesReducers';
import { linkTypesDataReducer } from './linkTypesReducers';
import { exportsDataReducer } from './exportsReducers';
import { armadaDataReducer } from './armadaReducers';
import { entityPermissionsDataReducer } from './entityPermissionsReducers';
import { recentEntitiesDataReducer } from './recentEntitiesReducers';
import { userProfileAdminDataReducer } from './userProfileAdminReducers';
import { attributesAdminDataReducer } from './attributesAdminReducers';
import { departmentIPsAdminDataReducer } from './departmentIPsAdminReducers';
import { departmentProfileDataReducer } from './departmentProfileReducers';
import { shapefilesAdminDataReducer } from './shapefilesAdminReducers';
import { locationsDataReducer } from './locationsReducers';
import { locationAliasesAdminDataReducer } from './locationAliasesAdminReducers';
import { reportDataReducer } from './reportReducers';
import { nameAttributesDataReducer } from './nameAttributesReducers';
import { nameNameLinksDataReducer } from './nameNameLinksReducers';
import { userDataReducer } from './userReducers';

const dataReducer = combineReducers({
    // dragon
    dragon: dragonDataReducer,

    // user data
    user: userDataReducer,
    exports: exportsDataReducer,
    recentEntities: recentEntitiesDataReducer, // entities relevant to the session

    // mark43 config (sent from the server)
    linkTypes: linkTypesDataReducer,

    // department config (sent from the server)
    userRolesTODO: userRolesDataReducer,
    entityPermissions: entityPermissionsDataReducer,
    departmentProfile: departmentProfileDataReducer,

    /**
     * Application settings configured by mark43.
     */
    settings: settingsReducer,

    // admin data
    // TODO rename / refactor to descope data from the ui its
    // viewed in... its likely that most of these things should just be
    // under "department data"
    attributesAdmin: attributesAdminDataReducer,
    departmentIPsAdmin: departmentIPsAdminDataReducer,
    userProfileAdmin: userProfileAdminDataReducer,
    shapefilesAdmin: shapefilesAdminDataReducer,
    locationAliasesAdmin: locationAliasesAdminDataReducer,
    armada: armadaDataReducer,
    productModules: productModulesReducer,

    // entities
    reports: reportDataReducer, // legacy version
    locations: locationsDataReducer,

    // links (between two entities)
    nameNameLinks: nameNameLinksDataReducer,

    // attribute links (between an entity and an attribute)
    nameAttributes: nameAttributesDataReducer,

    // evidence
    evidenceDepartmentConfig: evidenceDepartmentConfigReducer,

    // notifications
    notificationTypes: notificationTypesReducer,
});

/**
 * For data that is stored in Nexus modules, use `dataNexusReducer`.
 * This reducer is for all other data which is not stored in Nexus modules.
 */
export default dataReducer as Reducer<
    // most of the properties in data state are typed, but some are imported from untyped .js files
    ReturnType<typeof dataReducer> &
        // small subset of typed properties from the reducers imported from client-common
        ClientCommonDataState
>;

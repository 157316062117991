import React, { useCallback } from 'react';
import { cssVar } from 'arc';
import styled from 'styled-components';
import { IOverlayBaseRenderProps } from 'overlay-manager/lib/OverlayBase';
import { useDispatch } from 'react-redux';
import { RefContextEnum } from '@mark43/rms-api';

import { withSecurityClassification } from '~/client-common/core/domain/security-classifications/state/data';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import { useFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { OverlayBaseHelper } from '../../core/components/OverlayBaseHelper';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import Modal from '../../core/overlays/components/Modal';
import { RmsDispatch } from '../../../core/typings/redux';
import securityClassificationsResource from '../resources/securityClassificationsResource';
import { convertFromFormModel } from '../state/forms/securityClassificationForm';
import { SecurityClassificationOwner } from '../types';
import { EditSecurityClassificationForm } from './EditSecurityClassificationForm';

const strings = componentStrings.securityClassification.editSecurityClassificationModal;

const FormWrapper = styled.div`
    & .mark43-n-items-add-row {
        margin-top: ${cssVar('arc.space.3')};
    }
`;

type EditSecurityClassificationModalContentProps = SecurityClassificationOwner;

type EditSecurityClassificationModalRenderProps = {
    overlayBase: IOverlayBaseRenderProps<EditSecurityClassificationModalContentProps>;
};

const EditSecurityClassificationModalContent = (
    ownerProps: EditSecurityClassificationModalContentProps
) => {
    const dispatch: RmsDispatch = useDispatch();
    const { singular: securityClassificationFieldName } = useFieldName(
        SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID
    );
    const { getForm } = useFormGetter();

    const handleSave = useCallback(async () => {
        const form = getForm(RefContextEnum.FORM_SECURITY_CLASSIFICATION_MODAL.name);
        if (!form) {
            return;
        }

        const result = await form.submit();
        if (result && result.validationResult) {
            if (result.validationResult.success) {
                const formModel = result.form.getState().model;
                const payload = convertFromFormModel(formModel);
                try {
                    const securityClassification = await securityClassificationsResource.replaceSecurityClassification(
                        ownerProps,
                        payload
                    );
                    dispatch(withSecurityClassification(ownerProps, securityClassification));
                } catch (error) {
                    throw error;
                }
                return;
            }

            return result.validationResult.formErrors;
        }

        return;
    }, [dispatch, getForm, ownerProps]);

    return (
        <Modal
            id={overlayIdEnum.EDIT_SECURITY_CLASSIFICATION_MODAL}
            title={strings.title(securityClassificationFieldName)}
            okText={strings.saveText}
            cancelText={strings.cancelText}
            autoClose={false}
            onSave={handleSave}
        >
            <FormWrapper>
                <EditSecurityClassificationForm {...ownerProps} />
            </FormWrapper>
        </Modal>
    );
};

export const EditSecurityClassificationModal = () => {
    return (
        <OverlayBaseHelper id={overlayIdEnum.EDIT_SECURITY_CLASSIFICATION_MODAL}>
            {({ overlayBase }: EditSecurityClassificationModalRenderProps) => {
                const { customProperties } = overlayBase.overlayState;

                return <EditSecurityClassificationModalContent {...customProperties} />;
            }}
        </OverlayBaseHelper>
    );
};

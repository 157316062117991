import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ItemProfile } from '@mark43/rms-api';
import { isUndefined } from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

import { MFTText } from '../../core/forms/components/Text';
import Icon, { iconTypes } from '../../core/components/Icon';
import { clearfix } from '../../core/styles/mixins';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import { MFTCheckbox } from '../../core/forms/components/checkboxes/Checkbox';
import _ItemInfo from '../../evidence/core/components/ItemInfo';
import ItemDetails from '../../reports/core/components/items/ItemCard/Details';
import { PersonProfileSummary } from '../../records/core/components/summaries/PersonProfileSummary';
import { clearErrorMessage } from '../state/ui';
import testIds from '../../../core/testIds';

interface DetailsProps {
    show: boolean;
    testId?: string;
}

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    min-width: 0;
    flex: 1;
`;

const StyledEntryNameFlexRow = styled(FlexRow)`
    padding-top: 15px;
`;

const Textbox = styled(MFTText)`
    display: inline-block;
`;

const InlineBlockDiv = styled.div`
    margin-top: 1.8em;
    display: inline-block;
    color: ${(props) => props.theme.colors.disabled};
`;

const StyledClipsReportCardItem = styled(FlexRow)`
    font-size: var(--arc-fontSizes-md);
    ${clearfix};
    min-width: 0;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1;
`;

const FlexContent = styled.div`
    flex: 1;
    min-width: 0;
`;

const Details = styled.div<DetailsProps>`
    display: ${({ show }) => (show ? 'flex' : 'none')};
    flex-direction: column;
    margin-left: 25px;
    width: 100%;

    & > div {
        margin-top: 16px;

        &:empty {
            margin-top: 0;
        }
    }
`;

const Checkbox = styled(MFTCheckbox)`
    flex: 0 0 25px;
    margin-top: -2px;
`;

const ItemInfo = styled(_ItemInfo)`
    flex: 0 0 ${({ width }) => width}px;
`;

const ToggleButtonContainer = styled.div`
    border-left: 1px solid ${(props) => props.theme.colors.lightGrey};
    flex: 0 0 auto;
    padding: 0 8px 0 12px;
`;

const strings = componentStrings.clips.ClipsReportCardItems;

const ClipsReportCardItem: React.FC<{
    reportId: number;
    personProfileId?: number;
    personFlag?: boolean;
    itemProfileId?: number;
    itemSummaryViewModel?: ItemProfile;
    itemFlag?: boolean;
    ren: string;
}> = ({ reportId, personProfileId, personFlag, itemSummaryViewModel, itemFlag, ren }) => {
    const dispatch = useDispatch();
    const [itemDetailsCollapsed, setItemDetailsCollapsed] = useState(true);
    const [selected, setSelected] = useState(false);
    const onSelectionToggle = () => {
        dispatch(clearErrorMessage());
        setSelected((selected) => !selected);
    };
    const onCollapseToggle = () =>
        setItemDetailsCollapsed((itemDetailsCollapsed) => !itemDetailsCollapsed);
    const iconType = itemDetailsCollapsed ? iconTypes.OPEN : iconTypes.CLOSE;
    const lastFourOfRen = ren.slice(-4);
    const fieldDisplayName = useFields(fields.REPORT_REPORTING_EVENT_NUMBER);
    const renSuffix = `${fieldDisplayName.REPORT_REPORTING_EVENT_NUMBER} ${lastFourOfRen}`;
    return (
        <StyledClipsReportCardItem>
            <FlexRow>
                <Checkbox path="isSelected" onChange={onSelectionToggle} />
                <FlexColumn>
                    <FlexRow>
                        <FlexContent data-test-id={testIds.CLIPS_CONTENT}>
                            <FlexRow>
                                {itemFlag && (
                                    <ItemInfo
                                        viewModel={itemSummaryViewModel}
                                        reportId={reportId}
                                        openInNewTab={true}
                                    />
                                )}
                                {personFlag && !isUndefined(personProfileId) && (
                                    <PersonProfileSummary personProfileId={personProfileId} />
                                )}
                            </FlexRow>
                            <FlexRow>
                                {itemFlag && (
                                    <Details show={!itemDetailsCollapsed}>
                                        <ItemDetails data={itemSummaryViewModel} />
                                    </Details>
                                )}
                            </FlexRow>
                            {selected && (
                                <StyledEntryNameFlexRow>
                                    <Textbox
                                        path="entryName"
                                        label={strings.entryNameLabel}
                                        helpText={strings.entryNameHelpText}
                                        maxLength={50}
                                        disabled={false}
                                    />
                                    <InlineBlockDiv data-test-id={testIds.CLIPS_REN_SUFFIX}>
                                        {renSuffix}
                                    </InlineBlockDiv>
                                </StyledEntryNameFlexRow>
                            )}
                        </FlexContent>
                        {itemFlag && (
                            <ToggleButtonContainer>
                                <Button
                                    testId={testIds.GENERIC_EXPAND_COLLAPSE_BUTTON}
                                    className={buttonTypes.ICON_LINK}
                                    iconLeft={<Icon type={iconType} size={16} color="cobaltBlue" />}
                                    onClick={onCollapseToggle}
                                />
                            </ToggleButtonContainer>
                        )}
                    </FlexRow>
                </FlexColumn>
            </FlexRow>
        </StyledClipsReportCardItem>
    );
};

export default ClipsReportCardItem;

import React from 'react';
import { map } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { renderOnlyIf } from '~/client-common/helpers/reactHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    FIREARM_ALTERATION_INDICATED,
    FIREARM_BARREL_LENGTH,
    FIREARM_CALIBER,
    FIREARM_FINISH_ATTR_ID,
    FIREARM_GRIP_ATTR_ID,
    FIREARM_NUMBER_OF_SHOTS,
    FIREARM_REGISTRATION_NUMBER,
    FIREARM_STOCK_ATTR_ID,
    ITEM_PROFILE_BIOHAZARD_DESCRIPTION,
    ITEM_PROFILE_IS_BIOHAZARD,
    ITEM_PROFILE_ITEM_TYPE_FIREARM_DESCRIPTION,
    ITEM_PROFILE_ITEM_TYPE_FIREARM_ITEM_MODEL,
    ITEM_PROFILE_ITEM_TYPE_FIREARM_SERIAL_NUMBER,
    FIREARM_NCIC_FIREARM_CALIBER_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';
import EntityProfileSectionNoData from '../../core/components/EntityProfileSectionNoData';
import { convertIdentifiersToComponentArray } from '../../core/helpers/identifierHelpers';

const { title } = componentStrings.entityProfiles.property.IdentificationDetails;

const propertyDetailsLeftColumn /* keyof typeof firearmDetails & keyof typeof display */ = {
    description: ITEM_PROFILE_ITEM_TYPE_FIREARM_DESCRIPTION,
    serialNumber: ITEM_PROFILE_ITEM_TYPE_FIREARM_SERIAL_NUMBER,
    itemModel: ITEM_PROFILE_ITEM_TYPE_FIREARM_ITEM_MODEL,
    registrationNumber: FIREARM_REGISTRATION_NUMBER,
    finishAttrId: FIREARM_FINISH_ATTR_ID,
    stockAttrId: FIREARM_STOCK_ATTR_ID,
    gripAttrId: FIREARM_GRIP_ATTR_ID,
    caliber: FIREARM_CALIBER,
    barrelLength: FIREARM_BARREL_LENGTH,
    ncicFirearmCaliberAttrId: FIREARM_NCIC_FIREARM_CALIBER_ATTR_ID,
};
const propertyDetailsRightColum /* keyof typeof firearmDetails & keyof typeof display */ = {
    numberOfShots: FIREARM_NUMBER_OF_SHOTS,
    alterationIndicated: FIREARM_ALTERATION_INDICATED,
    isBiohazard: ITEM_PROFILE_IS_BIOHAZARD,
};

function EntityProfileFirearmIdentificationDetails({ firearmDetails }) {
    const display = getViewModelProperties(firearmDetails);
    const identifiers = convertIdentifiersToComponentArray(
        display.identifiers,
        'itemIdentifierType'
    );

    return (
        <EntityProfileSection title={title} noDivider>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    {map(propertyDetailsLeftColumn, (fieldName, key) => (
                        <SummaryRow key={key} fieldName={fieldName}>
                            {display[key] || firearmDetails[key]}
                        </SummaryRow>
                    ))}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList labelWidth={105}>
                    {map(propertyDetailsRightColum, (fieldName, key) => (
                        <SummaryRow key={key} fieldName={fieldName}>
                            {display[key] || firearmDetails[key]}
                        </SummaryRow>
                    ))}
                    {firearmDetails.isBiohazard && (
                        <SummaryRow fieldName={ITEM_PROFILE_BIOHAZARD_DESCRIPTION}>
                            {firearmDetails.biohazardDescription}
                        </SummaryRow>
                    )}
                    {identifiers}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

const RenderNoInformation = () => (
    <EntityProfileSection title={title} noDivider>
        <EntityProfileSectionNoData />
    </EntityProfileSection>
);

export default renderOnlyIf(({ firearmDetails }) => {
    const display = getViewModelProperties(firearmDetails);
    return (
        firearmDetails.description ||
        firearmDetails.registrationNumber ||
        display.finishAttrId ||
        display.stockAttrId ||
        display.gripAttrId ||
        display.ncicFirearmCaliberAttrId ||
        firearmDetails.caliber ||
        firearmDetails.barrelLength ||
        firearmDetails.numberOfShots ||
        display.alterationIndicated ||
        display.isBiohazard ||
        (display.identifiers && display.identifiers.length)
    );
}, RenderNoInformation)(EntityProfileFirearmIdentificationDetails);

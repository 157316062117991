import pluralize from 'pluralize';

type DisplayFields = {
    offenseDisplayName: string;
};

type Labels = {
    pluralCap: string;
    singularCap: string;
    plural: string;
    singular: string;
};

const DEFAULT_OFFENSE_DISPLAY_NAME = 'Offense';

export const withOffenseDisplayName = <Params extends object>(
    stringFunc: (labels: Labels, params: Params & DisplayFields) => string
) => (params: Params & DisplayFields) => {
    const { offenseDisplayName: singularCap = DEFAULT_OFFENSE_DISPLAY_NAME } = params || {
        offenseDisplayName: DEFAULT_OFFENSE_DISPLAY_NAME,
    };
    const pluralCap = pluralize(singularCap);

    return stringFunc(
        {
            pluralCap,
            singularCap,
            plural: pluralCap.toLowerCase(),
            singular: singularCap.toLowerCase(),
        },
        params
    );
};

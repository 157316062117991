import { AttributeTypeEnum } from '@mark43/rms-api';
import _, { parseInt, first, get } from 'lodash';
import { createSelector } from 'reselect';
import {
    warrantActivityStatusLinksSelector,
    warrantActivityStatusLinksWhereSelector,
} from '~/client-common/core/domain/warrant-activity-status-links/state/data';
import { activeAttributesByTypeSelector } from '~/client-common/core/domain/attributes/state/data';
import {
    SAVE_WARRANT_ACTIVITY_STATUS_LINK_START,
    SAVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS,
    SAVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE,
    REMOVE_WARRANT_ACTIVITY_STATUS_LINK_START,
    REMOVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS,
    REMOVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE,
    saveWarrantActivityStatusLink,
    removeWarrantActivityStatusLink,
} from '../data';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

const SELECT_WARRANT_ACTIVITY_STATUS_LINK_START =
    'warrant-activity-status-links/SELECT_WARRANT_ACTIVITY_STATUS_LINK_START';
const SELECT_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS =
    'warrant-activity-status-links/SELECT_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS';

const OPEN_NEW_WARRANT_ACTIVITY_STATUS_LINK_FORM =
    'warrant-activity-status-links/OPEN_NEW_WARRANT_ACTIVITY_STATUS_LINK_FORM';

function selectWarrantActivityStatusStart() {
    return {
        type: SELECT_WARRANT_ACTIVITY_STATUS_LINK_START,
    };
}

export function selectWarrantActivityStatusSuccess(id) {
    return {
        type: SELECT_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS,
        payload: id,
    };
}

/**
 * Dispatches the actions required to display a warrant activity status link on the page
 *
 * @param {number} id id of the warrant activity status link
 */
export function selectWarrantActivityStatus(activityTypeAttrId) {
    return (dispatch, getState, { formsRegistry }) => {
        dispatch(selectWarrantActivityStatusStart());
        const state = getState();
        const warrantActivityStatusLink = warrantActivityStatusLinksSelector(state)[
            activityTypeAttrId
        ];
        formsRegistry.maybeDeferredOperation('WARRANT_ACTIVITY_STATUS_LINK', undefined, (form) => {
            form.set('warrantStatusAttrId', get(warrantActivityStatusLink, 'warrantStatusAttrId'));
            form.resetUi();
        });
        dispatch(selectWarrantActivityStatusSuccess(activityTypeAttrId));
    };
}

const initialUiState = {
    selectedWarrantActivityAttributeId: null,
    loadingList: false,
    listError: null,
    submittingForm: false,
    formError: null,
    profileLoadError: null,
};

/**
 * warrant activity status link Redux Reducer
 *
 * @param {Object} [state=initialUiState]
 * @param {Object} action
 * @returns
 */
export default function warrantActivityStatusLinksAdminUiReducer(state = initialUiState, action) {
    switch (action.type) {
        case SAVE_WARRANT_ACTIVITY_STATUS_LINK_START:
        case REMOVE_WARRANT_ACTIVITY_STATUS_LINK_START:
            return {
                ...state,
                submittingForm: true,
            };
        case SAVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS:
        case REMOVE_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS:
            return {
                ...state,
                submittingForm: false,
            };
        case SAVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE:
        case REMOVE_WARRANT_ACTIVITY_STATUS_LINK_FAILURE:
            return {
                ...state,
                submittingForm: false,
                formError: action.payload,
            };
        case SELECT_WARRANT_ACTIVITY_STATUS_LINK_SUCCESS:
            return {
                ...state,
                profileLoadError: null,
                selectedWarrantActivityAttributeId: parseInt(action.payload),
                listError: null,
                formError: null,
            };
        case OPEN_NEW_WARRANT_ACTIVITY_STATUS_LINK_FORM:
            return {
                ...state,
                formError: null,
                profileLoadError: null,
                selectedWarrantActivityAttributeId: null,
            };
        default:
            return state;
    }
}

export const uiSelector = (state) => state.ui.warrantActivityStatusLinksAdmin;

/**
 * Builds a list of warrant activity status links scoped by current department id with
 * {path, title, key, code, selected} structure that will be used in the AdminList UI.
 *
 * @param {Function} activeAttributesByType
 * @param {Object} ui
 * @param {Number} currentUserDepartmentId
 * @returns a list of warrant activity status links with
 * {path, title, key, selected} structure that will be used in the AdminList UI.
 */
export const warrantActivityStatusListItemsSelector = createSelector(
    activeAttributesByTypeSelector,
    uiSelector,
    currentUserDepartmentIdSelector,
    (activeAttributesByType, ui, currentUserDepartmentId) =>
        _(activeAttributesByType(AttributeTypeEnum.WARRANT_ACTIVITY.name))
            .filter({ deptId: currentUserDepartmentId })
            .map((warrantActivityAttribute) => {
                return {
                    path: `/admin/warrant-activity-mappings/${warrantActivityAttribute.id}`,
                    title: warrantActivityAttribute.val,
                    key: warrantActivityAttribute.id,
                    selected: warrantActivityAttribute.id === ui.selectedWarrantActivityAttributeId,
                };
            })
            .sortBy('title')
            .value()
);

/**
 * Submit method that is used in WarrantActivityStatusAdminForm. Will delete the link if the warrant status is null
 * @param {Object} dispatch
 * @param {function} getState
 * @return result of the main dispatch
 */
export const submitWarrantActivityStatusLink = function (activityTypeAttrId) {
    return (dispatch, getState, { formsRegistry }) => {
        const form = formsRegistry.get('WARRANT_ACTIVITY_STATUS_LINK');
        const existingWarrantActivityStatusLink = warrantActivityStatusLinksWhereSelector(
            getState()
        )({ activityTypeAttrId });
        return form.submit().then((result) => {
            const formModel = result.form.getState().model;
            if (!formModel.warrantStatusAttrId) {
                // if warrantStatusAttrId is empty, that is equivalent to deleting the link because there is no mapping
                dispatch(removeWarrantActivityStatusLink(activityTypeAttrId));
            } else {
                // otherwise, update it
                let id = null;
                if (first(existingWarrantActivityStatusLink)) {
                    id = first(existingWarrantActivityStatusLink).id;
                }
                dispatch(
                    saveWarrantActivityStatusLink({
                        warrantStatusAttrId: formModel.warrantStatusAttrId,
                        activityTypeAttrId,
                        id,
                    })
                ).then((warrantActivityStatus) =>
                    dispatch(selectWarrantActivityStatus(warrantActivityStatus.activityTypeAttrId))
                );
            }
        });
    };
};

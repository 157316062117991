import { Court, CourtCreationRequest, CourtUpdateRequest } from '@mark43/rms-api';
import { NEXUS_STATE_PROP as COURTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/courts/state/data';
import courtsResource from '../../resources/courtsResource';
import { RmsAction } from '../../../../../core/typings/redux';

export const LOAD_COURTS_START = 'courts-admin/LOAD_COURTS_START';
export const LOAD_COURTS_SUCCESS = 'courts-admin/LOAD_COURTS_SUCCESS';
export const LOAD_COURTS_FAIL = 'courts-admin/LOAD_COURTS_FAIL';
export const CREATE_COURT_START = 'courts-admin/CREATE_COURT_START';
export const CREATE_COURT_SUCCESS = 'courts-admin/CREATE_COURT_SUCCESS';
export const CREATE_COURT_FAIL = 'courts-admin/CREATE_COURT_FAIL';
export const UPDATE_COURT_START = 'courts-admin/UPDATE_COURT_START';
export const UPDATE_COURT_SUCCESS = 'courts-admin/UPDATE_COURT_SUCCESS';
export const UPDATE_COURT_FAIL = 'courts-admin/UPDATE_COURT_FAIL';

export type CourtActionType = {
    type: string;
    payload?: {
        errorMessage: string;
    };
};

function loadCourtsStart() {
    return { type: LOAD_COURTS_START };
}

function loadCourtsSuccess() {
    return { type: LOAD_COURTS_SUCCESS };
}

function loadCourtsFail(errorMessage: string) {
    return {
        type: LOAD_COURTS_FAIL,
        payload: {
            errorMessage,
        },
    };
}

function createCourtStart() {
    return { type: CREATE_COURT_START };
}

function createCourtSuccess() {
    return { type: CREATE_COURT_SUCCESS };
}

function createCourtFail(errorMessage: string) {
    return {
        type: CREATE_COURT_FAIL,
        payload: {
            errorMessage,
        },
    };
}

function updateCourtStart() {
    return { type: UPDATE_COURT_START };
}

function updateCourtSuccess() {
    return { type: UPDATE_COURT_SUCCESS };
}

function updateCourtFail(errorMessage: string) {
    return {
        type: UPDATE_COURT_FAIL,
        payload: {
            errorMessage,
        },
    };
}

export function getCourts(): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(loadCourtsStart());

        return courtsResource
            .getCourts()
            .then((results: Court[]) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [COURTS_NEXUS_STATE_PROP]: results,
                        },
                        loadCourtsSuccess()
                    )
                );
            })
            .catch((error: Error) => {
                dispatch(loadCourtsFail(error.message));
                throw error;
            });
    };
}

export function createCourt(court: CourtCreationRequest): RmsAction<Promise<Court>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(createCourtStart());

        return courtsResource
            .createCourt(court)
            .then((result: Court) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [COURTS_NEXUS_STATE_PROP]: [result],
                        },
                        createCourtSuccess()
                    )
                );
                return result;
            })
            .catch((error: Error) => {
                dispatch(createCourtFail(error.message));
                throw error;
            });
    };
}

export function updateCourt(court: CourtUpdateRequest): RmsAction<Promise<Court>> {
    return (dispatch, getState, { nexus }) => {
        dispatch(updateCourtStart());

        return courtsResource
            .updateCourt(court)
            .then((result: Court) => {
                dispatch(
                    nexus.withEntityItems(
                        {
                            [COURTS_NEXUS_STATE_PROP]: [result],
                        },
                        updateCourtSuccess()
                    )
                );
                return result;
            })
            .catch((error: Error) => {
                dispatch(updateCourtFail(error.message));
                throw error;
            });
    };
}

import { AttributeTypeEnum, EntityTypeEnum, RefContextEnum, LinkTypesEnum } from '@mark43/rms-api';
import { get, head, map, size } from 'lodash';
import { createFormConfiguration, createNItems, createFieldset, _Form } from 'markformythree';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import * as fields from '~/client-common/core/enums/universal/fields';

import { attributesSelector } from '~/client-common/core/domain/attributes/state/data';
import { citationByReportIdSelector } from '~/client-common/core/domain/citations/state/data';
import { citationChargesByCitationIdSelector } from '~/client-common/core/domain/citation-charges/state/data';
import { locationEntityLinksWhereSelector } from '~/client-common/core/domain/location-entity-links/state/data';
import { nameReportLinksWhereSelector } from '~/client-common/core/domain/name-report-links/state/data';
import { reportAttributesWhereSelector } from '~/client-common/core/domain/report-attributes/state/data';
import { getDescriptionForAttributeLinks } from '~/client-common/core/domain/attributes/state/ui';
import { assignDescriptionToReportAttributes } from '~/client-common/core/domain/report-attributes/utils/reportAttributesHelpers';
import sortCitationCharges from '~/client-common/core/domain/citation-charges/utils/sortCitationCharges';

import formsRegistry from '../../../../../core/formsRegistry';
import createArbiterMFTValidationHandler from '../../../../core/markformythree-arbiter/createArbiterMFTValidationHandler';
import mftArbiterValidationEvents from '../../../../core/markformythree-arbiter/mftArbiterValidationEvents';

export const formName = RefContextEnum.FORM_CITATION.name;
export const CITATION_LOCATION_PATH = 'citationLocation';
const CITATION_LOCATION_DESCRIPTION_PATH = 'description';
const CITATION_LOCATION_POSITION_ATTR_ID_PATH = 'positionAttrId';
export const CITATION_LOCATION_DESCRIPTION_FULL_PATH = `${CITATION_LOCATION_PATH}.${CITATION_LOCATION_DESCRIPTION_PATH}`;
export const CITATION_LOCATION_POSITION_ATTR_ID_FULL_PATH = `${CITATION_LOCATION_PATH}.${CITATION_LOCATION_POSITION_ATTR_ID_PATH}`;
export const CITATION_RECIPIENT_PATH = 'citationRecipient';

export const getCitationForm = () => formsRegistry.get(formName);

export const createCitationForm = (options = {}) => {
    const { initialState, arbiter, formatFieldByName } = options;
    return new _Form({
        name: formName,
        onValidate: createArbiterMFTValidationHandler(arbiter, formName, formatFieldByName),
        initialState,
        validationEvents: mftArbiterValidationEvents,
        configuration: createFormConfiguration({
            id: {},
            citationNumber: {
                fieldName: fields.CITATION_CITATION_NUMBER,
            },
            issuedDateUtc: {
                fieldName: fields.CITATION_ISSUED_DATE_UTC,
            },
            citationTypeAttrId: {
                fieldName: fields.CITATION_CITATION_TYPE_ATTR_ID,
            },
            citationTypeOther: {
                fieldName: fields.CITATION_CITATION_TYPE_OTHER,
            },
            citationPostedSpeed: {
                fieldName: fields.CITATION_CITATION_POSTED_SPEED,
            },
            citationActualSpeed: {
                fieldName: fields.CITATION_CITATION_ACTUAL_SPEED,
            },
            citationStatisticsAttrIds: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CITATION_STATISTICS_ATTRIBUTE_ID,
            },
            citationStatisticsDescription: {
                fieldName: fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_CITATION_STATISTICS_DESCRIPTION,
            },
            [CITATION_RECIPIENT_PATH]: {
                fieldName: fields.NAME_REPORT_LINK_LINK_TYPE_SUBJECT_IN_CITATION_LINK_TYPE,
            },
            [CITATION_LOCATION_PATH]: createFieldset({
                fields: {
                    locationId: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_CITATION_LOCATION_ID,
                    },
                    [CITATION_LOCATION_POSITION_ATTR_ID_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_CITATION_POSITION_ATTR_ID,
                    },
                    [CITATION_LOCATION_DESCRIPTION_PATH]: {
                        fieldName:
                            fields.LOCATION_ENTITY_LINK_LINK_TYPE_LOCATION_OF_CITATION_DESCRIPTION,
                    },
                },
            }),
            citationCharges: createNItems({
                fields: {
                    id: {},
                    offenseCodeId: {
                        fieldName: fields.CITATION_CHARGE_OFFENSE_CODE_ID,
                    },
                    chargeResultAttrId: {
                        fieldName: fields.CITATION_CHARGE_CHARGE_RESULT_ATTR_ID,
                    },
                    chargeOrder: {},
                },
            }),
        }),
    });
};

export const buildCitationCardFormModel = ({ reportId }) => (dispatch, getState) => {
    const state = getState();
    const citationByReportId = citationByReportIdSelector(state);
    const citationChargesByCitationId = citationChargesByCitationIdSelector(state);
    const reportAttributesWhere = reportAttributesWhereSelector(state);
    const locationEntityLinksWhere = locationEntityLinksWhereSelector(state);
    const nameReportLinksWhere = nameReportLinksWhereSelector(state);

    const citation = citationByReportId(reportId);
    const {
        id: citationId,
        citationNumber,
        issuedDateUtc,
        citationTypeAttrId,
        citationTypeOther,
        citationPostedSpeed,
        citationActualSpeed,
    } = citation;
    const citationCharges = citationChargesByCitationId(citationId);
    // Initialize the form model with an empty, initial `CitationCharge`,
    // if none exist.
    const initialCitationCharges =
        size(citationCharges) > 0
            ? citationCharges
            : [buildNewCitationChargeFormModel({ existingCitationChargeFormModels: [] })];
    const sortedCitationCharges = map(
        sortCitationCharges({ citationCharges: initialCitationCharges }),
        (citationCharge) => {
            const {
                id: citationChargeId,
                offenseCodeId,
                chargeOrder,
                chargeResultAttrId,
            } = citationCharge;
            return {
                id: citationChargeId,
                offenseCodeId,
                chargeOrder,
                chargeResultAttrId,
            };
        }
    );

    const citationStatisticsAttributes = reportAttributesWhere({
        reportId,
        attributeType: AttributeTypeEnum.CITATION_STATISTICS.name,
    });
    const citationStatisticsAttrIds = map(citationStatisticsAttributes, 'attributeId');
    const citationStatisticsDescription = getDescriptionForAttributeLinks(
        citationStatisticsAttributes
    );

    const citationLocation = head(
        locationEntityLinksWhere({
            linkType: LinkTypesEnum.LOCATION_OF_CITATION,
            entityType: EntityTypeEnum.CITATION.name,
            entityId: citationId,
        })
    );

    const citationRecipientLinkType = head(
        map(
            nameReportLinksWhere({
                reportId,
                linkType: LinkTypesEnum.SUBJECT_IN_CITATION,
                contextType: EntityTypeEnum.CITATION.name,
                contextId: citationId,
            }),
            'linkType'
        )
    );

    return {
        id: citationId,
        citationNumber,
        issuedDateUtc,
        citationTypeAttrId,
        citationTypeOther,
        citationPostedSpeed,
        citationActualSpeed,
        citationStatisticsAttrIds,
        citationStatisticsDescription,
        [CITATION_RECIPIENT_PATH]: citationRecipientLinkType,
        [CITATION_LOCATION_PATH]: citationLocation,
        citationCharges: sortedCitationCharges,
    };
};

export const buildCitationCardBundle = ({ reportId, form }) => (dispatch, getState) => {
    const state = getState();
    const attributes = attributesSelector(state);
    const { model } = form.getState();
    const {
        id: citationId,
        citationNumber,
        issuedDateUtc,
        citationTypeAttrId,
        citationTypeOther,
        citationPostedSpeed,
        citationActualSpeed,
        citationStatisticsAttrIds,
        citationStatisticsDescription,
        [CITATION_LOCATION_PATH]: citationLocation,
        citationCharges,
    } = model;

    const citationForBundle = {
        id: citationId,
        reportId,
        citationNumber,
        issuedDateUtc,
        citationTypeAttrId,
        citationTypeOther,
        citationPostedSpeed,
        citationActualSpeed,
    };

    const citationChargesForBundle = map(
        sortCitationCharges({ citationCharges }),
        (citationCharge, idx) => {
            const { id: citationChargeId, offenseCodeId, chargeResultAttrId } = citationCharge;
            // Just in case we somehow end up with multiple `CitationCharge`s with
            // the same offense order, after sorting the `CitationCharge`s, we can
            // de-dupe by using the mapped index.
            const computedChargeOrder = idx + 1;

            return {
                id: citationChargeId,
                reportId,
                citationId,
                offenseCodeId,
                chargeResultAttrId,
                chargeOrder: computedChargeOrder,
            };
        }
    );

    const reportAttributes = assignDescriptionToReportAttributes(
        map(citationStatisticsAttrIds, (citationStatisticsAttrId) => {
            return {
                reportId,
                attributeType: AttributeTypeEnum.CITATION_STATISTICS.name,
                attributeId: citationStatisticsAttrId,
            };
        }),
        attributes,
        citationStatisticsDescription
    );

    // Only preserve the `LocationEntityLink` if the form has a valid
    // `LocationEntityLink`; use `locationId` to validate.
    // This is because both initializing and adding-then-removing a
    // location will cause the form model state to correctly reset with
    // `undefined` values for `locationId` & `description`, but the BE
    // is expecting a `NULL` `locationEntityLink` for processing,
    // not an "empty" one.
    const filteredCitationLocation = isUndefinedOrNull(get(citationLocation, 'locationId'))
        ? undefined
        : citationLocation;

    return {
        citation: citationForBundle,
        citationCharges: citationChargesForBundle,
        reportAttributes,
        locationEntityLink: filteredCitationLocation,
    };
};

export const refreshCitationForm = ({ reportId }) => (dispatch) => {
    const form = getCitationForm();
    const formModel = dispatch(buildCitationCardFormModel({ reportId }));
    form.set('', formModel);
};

export const buildNewCitationChargeFormModel = ({ existingCitationChargeFormModels = [] }) => {
    const computedChargeOrder = size(existingCitationChargeFormModels) + 1;
    return {
        id: undefined,
        offenseCodeId: undefined,
        chargeResultAttrId: undefined,
        chargeOrder: computedChargeOrder,
    };
};

export const resequenceChargeOrders = () => {
    const form = getCitationForm();
    const {
        model: { citationCharges },
    } = form.getState();
    const resequencedCitationCharges = map(
        sortCitationCharges({ citationCharges }),
        (citationCharge, idx) => {
            const computedChargeOrder = idx + 1;
            return {
                ...citationCharge,
                chargeOrder: computedChargeOrder,
            };
        }
    );
    form.set('citationCharges', resequencedCitationCharges);
};

import { Integration } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

const integrationModule = createNormalizedModule<Integration>({
    type: 'integrations',
});

// ACTIONS
export const storeIntegrations = integrationModule.actionCreators.storeEntities;
export const deleteIntegration = integrationModule.actionCreators.deleteEntity;

// SELECTORS
export const integrationsSelector = integrationModule.selectors.entitiesSelector;
export const integrationByIdSelector = integrationModule.selectors.entityByIdSelector;

// REDUCER
export default integrationModule.reducerConfig;

import React, { FC } from 'react';
import { ElasticCase } from '@mark43/rms-api';
import { ReactTable } from '@arc/data-table';
import { Box, BoxProps, cssVar } from 'arc';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useCaseFieldName } from '~/client-common/core/fields/hooks/useFields';

import testIds from '../../../../../core/testIds';
import CaseNumberNameCell from '../CaseNumberNameCell';
import { columnKeys } from './constants';
import { CaseCreatedDateCell } from './cells/CaseCreatedDateCell';
import { InvolvementCell } from './cells/InvolvementCell';
import { ApprovalStatusCell } from './cells/ApprovalStatusCell';

const strings = componentStrings.cases.core.CasesTable;

export const CellWrap: FC<BoxProps> = ({ children, ...props }) => (
    <Box
        whiteSpace="normal"
        lineHeight={cssVar('arc.lineHeights.base')}
        data-test-id={testIds.TABLE_CELL}
        {...props}
    >
        {children}
    </Box>
);

const CaseNubmerHeader = () => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    return strings.columns.caseNumber(caseDisplayName);
};

const CaseStatusHeader = () => {
    const { singularCaseFieldName: caseDisplayName } = useCaseFieldName();

    return strings.columns.caseStatus(caseDisplayName);
};

const columnHelper = ReactTable.createColumnHelper<ElasticCase>();

export type CasesTableColumnOverrides = Partial<
    Record<typeof columnKeys.involvement, Partial<ReactTable.ColumnDef<ElasticCase>>>
>;

export const useColumnDefinitions = (overrides: CasesTableColumnOverrides = {}) => [
    columnHelper.accessor(columnKeys.createdDateUtc, {
        id: columnKeys.createdDateUtc,
        header: strings.columns.createdDateUtc,
        cell: ({ getValue }) => (
            <CellWrap>
                <CaseCreatedDateCell date={getValue()} />
            </CellWrap>
        ),
        enableSorting: true,
        maxSize: 112,
    }),
    columnHelper.display({
        id: 'caseNumber',
        cell: ({ row }) => (
            <CellWrap>
                <CaseNumberNameCell {...row.original} />
            </CellWrap>
        ),
        header: CaseNubmerHeader,
        enableSorting: false,
        maxSize: 156,
    }),
    columnHelper.display({
        id: columnKeys.involvement,
        header: strings.columns.involvement,
        cell: ({ row }) => (
            <CellWrap>
                <InvolvementCell assigneeRoleId={row.original.assigneeRoleId || -1} />
            </CellWrap>
        ),
        enableSorting: false,
        maxSize: 96,
        ...(overrides[columnKeys.involvement] || {}),
    }),
    columnHelper.accessor(columnKeys.currentStatusAttrDetail, {
        id: columnKeys.currentStatusAttrDetail,
        header: CaseStatusHeader,
        cell: ({ getValue }) => <CellWrap>{getValue().displayValue}</CellWrap>,
        enableSorting: false,
        maxSize: 134,
    }),
    columnHelper.accessor(columnKeys.approvalStatus, {
        id: columnKeys.approvalStatus,
        header: strings.columns.approvalStatus,
        cell: ({ getValue }) => (
            <CellWrap>
                <ApprovalStatusCell approvalStatus={getValue()} />
            </CellWrap>
        ),
        enableSorting: false,
        maxSize: 76,
    }),
];

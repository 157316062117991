import React from 'react';
import { rateLimitTypeahead } from '~/client-common/helpers/searchHelpers';

/**
 * @param  {boolean} withRef Determines whether or not the enhanced Component
 *   returned from `withAsyncHandler` will maintain/expose a ref to the wrapped
 *   component instance.
 * @return {React.Component}
 */
export default function withAsyncHandler({ withRef } = {}) {
    return (Component) => {
        const WithAsyncHandler = class extends React.Component {
            constructor(props) {
                super(props);
                this.state = {
                    currentAction: undefined,
                    loading: false,
                };
                this.getWrappedInstance = this.getWrappedInstance.bind(this);
                this.setWrappedInstance = this.setWrappedInstance.bind(this);
                this.asyncHandler = rateLimitTypeahead(
                    this.asyncHandler.bind(this),
                    this.props.typeaheadThrottle
                );
            }
            getWrappedInstance() {
                return this.wrappedInstance;
            }
            setWrappedInstance(ref) {
                this.wrappedInstance = ref;
            }
            asyncHandler(query) {
                const asyncAction = this.props.asyncAction(query);
                const isPromise = asyncAction && !!asyncAction.finally;
                if (isPromise) {
                    asyncAction.finally(() => {
                        this.setState({
                            loading: false,
                        });
                    });
                }
                if (asyncAction) {
                    this.setState((prevState) => {
                        const previousAction = prevState.currentAction;
                        if (previousAction && previousAction.isCancellable()) {
                            previousAction.cancel();
                        }
                        return {
                            loading: isPromise,
                            currentAction: asyncAction,
                        };
                    });
                }
            }
            render() {
                return React.createElement(Component, {
                    ...this.props,
                    asyncHandler: this.asyncHandler,
                    asyncHandlerIsLoading: this.state.loading,
                    ...(withRef ? { ref: this.setWrappedInstance } : {}),
                });
            }
        };

        WithAsyncHandler.displayName = `WithAsyncHandler(${
            Component.displayName || Component.name || Component
        })`;
        return WithAsyncHandler;
    };
}

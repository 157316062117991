// selectors
import { impoundByReportIdSelector } from '~/client-common/core/domain/impounds/state/data';

// forms
import impoundForm from '../forms/impoundForm';

/**
 * Populate the Tow Vehicle Impound Form based on current data state
 */
export function refreshImpoundForm(reportId) {
    return (dispatch, getState) => {
        const state = getState();
        const impound = impoundByReportIdSelector(state)(reportId);
        dispatch(impoundForm.actionCreators.change(impound));
    };
}

import React from 'react';
import { cssVar, DataItem } from 'arc';

type BookingDetailsCardProps = {
    label: string;
    key: string;
    value?: string | null;
};

export const BookingDetailsInfo: React.FC<BookingDetailsCardProps> = ({
    key,
    value = '',
    label,
}) => {
    return (
        <DataItem
            key={key}
            style={{
                fontSize: cssVar('arc.fontSizes.sm'),
                alignItems: 'center',
                overflowWrap: 'anywhere',
                wordBreak: 'normal',
            }}
            dataKey={label}
        >
            <p style={{ overflowWrap: 'normal' }}>{value}</p>
        </DataItem>
    );
};

/**
 * Padding of <ItemInfo>, which appears in both <ItemQueueTable> and
 *   <ItemQueuePopover>.
 * @type {number}
 */
export const ITEM_INFO_PADDING = 10;

/**
 * Icon width in <ItemInfo> for the item type.
 * @type {number}
 */
export const ITEM_INFO_ICON_WIDTH = 25;

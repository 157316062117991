import formClientEnum from '~/client-common/core/enums/client/formClientEnum';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

import { createFormModule } from '../../../../core/forms';

const fieldViewModels = buildFlatFormFieldViewModels([
    'id',
    {
        key: 'releaseTypeName',
    },
    {
        key: 'description',
    },
]);
const exportReleaseAdminForm = createFormModule({
    formName: formClientEnum.EXPORT_RELEASE_SETTINGS_ADMIN,
    fieldViewModels,
});

/**
 * Form module of the export release admin settings
 * @type {Object}
 */
export default exportReleaseAdminForm;

import { createSelector } from 'reselect';
import { chain, map } from 'lodash';

import {
    loadAllItemProfilesPropertiesForReportingEventNumber,
    loadAllItemProfilesVehiclesForReportingEventNumber,
} from '~/client-common/core/domain/item-profiles/state/data';
import {
    itemProfileViewModelsInReportSelector,
    itemTypeSpecificViewModelByIdSelector,
    setRecentHydratedItemProfileIds,
} from '~/client-common/core/domain/item-profiles/state/ui';
import { propertyStatusesByItemProfileIdSelector } from '~/client-common/core/domain/property-statuses/state/data';
import {
    buildPropertyStatusViewModelSelector,
    propertyStatusViewModelByIdSelector,
} from '~/client-common/core/domain/property-statuses/state/ui';
import {
    mergePropertyStatuses,
    deduplicatePropertyStatuses,
} from '~/client-common/core/domain/property-statuses/utils/propertyStatusHelpers';
import { itemFacilityLinksSelector } from '~/client-common/core/domain/item-facility-links/state/data';
import { getRenSequenceNumberSelector } from '~/client-common/core/domain/item-reporting-event-links/state/data';
import { nameItemLinksWhereSelector } from '~/client-common/core/domain/name-item-links/state/data/';
import { legacyEntityDetailsWhereSelector } from '~/client-common/core/domain/legacy-entity-details/state/data';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { itemAttributesWhereSelector } from '~/client-common/core/domain/item-attributes/state/data';

import {
    currentReportIdSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';

/**
 * Get all linked hydrated items for a report and update the recent hydrated item profile ids Nexus state.
 * @param isVehicle
 * @param ownerId
 * @param ownerType
 * @param reportingEventNumber
 */
export const getRecentHydratedItems = ({ isVehicle, ownerId, ownerType, reportingEventNumber }) => (
    dispatch
) => {
    const loadAllItemProfiles = isVehicle
        ? loadAllItemProfilesVehiclesForReportingEventNumber
        : loadAllItemProfilesPropertiesForReportingEventNumber;

    return dispatch(loadAllItemProfiles({ ownerId, ownerType, reportingEventNumber })).then(
        (hydratedItems) => {
            dispatch(setRecentHydratedItemProfileIds(map(hydratedItems, 'id')));
            return hydratedItems;
        }
    );
};

const getReportId = (offenseId, reportId, currentReportId, supplementReportId) => {
    // we use empty offenseId and non-empty supplementReportId as a sign of OMS report,
    // in this case we will use supplementReportId to retrieve a list of items
    if (isUndefinedOrNull(offenseId) && !isUndefinedOrNull(supplementReportId)) {
        return supplementReportId;
    }
    return (
        reportId || (isUndefinedOrNull(supplementReportId) ? currentReportId : supplementReportId)
    );
};

/**
 * Selector for Item Profile View Models, with property status view models and item facility links
 *   for the current report id.
 *
 * When you update this selector, you must also update CustodialPropertyCardItem.js.
 * @param  {boolean}  isVehicle   Item lists in item entry v2 are sectioned into vehicle and
 *   non-vehicle.
 * @param  {number}   [offenseId] Determines whether the propertyStatuses are filtered.
 * @param  {number}   [reportId] ownnerId of Vehicle Card in streamlined report,
 * this can be different with the value we get from currentReportIdSelector
 * @return {Object[]}
 */
export const itemSummaryViewModelsForCurrentReportSelector = createSelector(
    itemProfileViewModelsInReportSelector,
    itemTypeSpecificViewModelByIdSelector,
    propertyStatusesByItemProfileIdSelector,
    buildPropertyStatusViewModelSelector,
    propertyStatusViewModelByIdSelector,
    itemFacilityLinksSelector,
    getRenSequenceNumberSelector,
    nameItemLinksWhereSelector,
    legacyEntityDetailsWhereSelector,
    currentReportIdSelector,
    offenseModifyingSupplementRenOffenseReportIdSelector,
    itemAttributesWhereSelector,
    (
        itemProfileViewModelsInReport,
        itemTypeSpecificViewModelById,
        propertyStatusesByItemProfileId,
        buildPropertyStatusViewModel,
        propertyStatusViewModelById,
        itemFacilityLinks,
        getRenSequenceNumber,
        nameItemLinksWhere,
        legacyEntityDetailsWhere,
        currentReportId,
        offenseModifyingSupplementRenOffenseReportId,
        itemAttributesWhere
    ) => (isVehicle, offenseId, reportId) => {
        const actualReportId = getReportId(
            offenseId,
            reportId,
            currentReportId,
            offenseModifyingSupplementRenOffenseReportId
        );
        return chain(actualReportId)
            .thru(itemProfileViewModelsInReport)
            .filter(
                ({ itemTypeAttrId }) =>
                    (isVehicle && itemTypeAttrId === globalAttributes.itemType.vehicle) ||
                    (!isVehicle && itemTypeAttrId !== globalAttributes.itemType.vehicle)
            )
            .map((item) => {
                const viewModelProperties = getViewModelProperties(item);
                const propertyStatuses = chain(item.id)
                    .thru(propertyStatusesByItemProfileId)
                    .filter(!!offenseId ? { offenseId } : undefined)
                    .thru((propertyStatuses) => deduplicatePropertyStatuses(propertyStatuses))
                    .value();

                return {
                    ...itemTypeSpecificViewModelById(item.id),
                    // array of all unique property statuses belonging to this item (on this offense, if
                    // offenseId is provided)
                    // tech debt: rename this to be plural
                    propertyStatus: map(propertyStatuses, ({ id }) =>
                        propertyStatusViewModelById(id)
                    ),
                    // a single merged property status that is 'representative' for the item, used to
                    // display values in summary mode
                    mergedPropertyStatus: buildPropertyStatusViewModel(
                        mergePropertyStatuses(propertyStatuses)
                    ),
                    storageLocationId: chain(itemFacilityLinks)
                        .find({ itemProfileId: item.id })
                        .get('storageLocationId')
                        .value(),
                    nameItemLinks: nameItemLinksWhere({ itemProfileId: item.id }),
                    legacyEntityDetails: legacyEntityDetailsWhere({ entityId: item.id }),
                    trafficCrashVehicle: viewModelProperties.trafficCrashVehicle,
                    attachmentViewModels: viewModelProperties.attachmentViewModels,
                    cautions: viewModelProperties.cautions,
                    itemAttributes: itemAttributesWhere({ itemProfileId: item.id }),
                };
            })
            .reject(({ propertyStatus }) => !!offenseId && propertyStatus.length === 0)
            .sortBy(
                ({ masterItemId }) =>
                    getRenSequenceNumber(masterItemId, actualReportId)?.sequenceNumber || 0
            )
            .value();
    }
);

import { RefContextEnum } from '@mark43/rms-api';
import { concat, get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Observer, lifecycleOptions } from 'markformythree';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { buildStubOffenseIncidentCardAnchor } from '~/client-common/core/domain/offenses/utils/offensesHelpers';
import {
    applyStatuteCodeSetFilterToFlags,
    convertOffenseCodeToStatuteCodeSetFilter,
} from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    OFFENSE_TYPE_OFFENSE_CODE_FLAGS,
    INCIDENT_TYPE_OFFENSE_CODE_FLAGS,
} from '~/client-common/core/domain/offense-codes/constants';
import * as fields from '~/client-common/core/enums/universal/fields';
import { fieldConfigurationContextByContextAndFieldNameSelector } from '~/client-common/core/domain/field-configuration-contexts/state/data';

import Card, { CardSection } from '../../../../legacy-redux/components/core/Card';
import testIds from '../../../../core/testIds';
import Row from '../../../core/components/Row';
import { ArbiterMFTOffenseCodeSelect } from '../../../core/forms/components/selects/OffenseCodeSelect';
import { createOffenseIncidentForm } from '../state/forms/offenseIncidentForm';
import { registerForm } from '../state/ui';
import ArbiterForm from '../../../core/markformythree-arbiter/ArbiterForm';
import { currentUserDepartmentProfileSelector } from '../../../core/current-user/state/ui';
import { ArbiterMFTStatuteCodeSetRadio } from './StatuteCodeSetRadio';

const strings = componentStrings.reports.core.StubOffenseIncidentCard;

class StubOffenseIncidentCard extends React.Component {
    constructor(...args) {
        super(...args);
        const {
            currentUserDepartmentProfile,
            fieldConfigurationContextByContextAndFieldName,
            formatFieldByName,
            arbiter,
            index,
        } = this.props;
        const departmentSubDomain = currentUserDepartmentProfile.department.subDomain;
        const statuteCodeSetFilterFieldContext = fieldConfigurationContextByContextAndFieldName(
            RefContextEnum.FORM_STUB_OFFENSE_INCIDENT.name,
            fields.DISPLAY_ONLY_OFFENSE_CODE_STATUTE_CODE_SET_FILTER
        );
        const statuteCodeSetFilter = convertOffenseCodeToStatuteCodeSetFilter(
            undefined,
            [],
            departmentSubDomain,
            !!get(statuteCodeSetFilterFieldContext, 'isStaticallyHidden')
        );
        const initialState = {
            offenseIncident: this.props.offenseIncident,
            statuteCodeSetFilter,
        };
        const form = createOffenseIncidentForm({
            initialState,
            formatFieldByName,
            arbiter,
        });
        this.form = form;
        registerForm({ form, index });
    }

    render() {
        const {
            offenseIncident,
            onChangeOffenseIncidentCode,
            formatFieldByName,
            summaryMode,
            onEdit,
            canEdit,
            autoFocus,
            index,
            title,
        } = this.props;
        const flags = concat([], OFFENSE_TYPE_OFFENSE_CODE_FLAGS, INCIDENT_TYPE_OFFENSE_CODE_FLAGS);
        const offenseDisplayName = formatFieldByName(fields.DISPLAY_ONLY_OFFENSE);

        return (
            <Card
                title={title}
                testId={testIds.STUB_OFFENSE_INCIDENT_CARD}
                anchor={buildStubOffenseIncidentCardAnchor({ index: offenseIncident.id })}
                summaryMode={summaryMode}
                canEdit={canEdit}
                saveDisabled={true}
                onEdit={onEdit}
                renderContent={(summaryMode) => {
                    return (
                        !summaryMode && (
                            <CardSection>
                                <ArbiterForm
                                    name={RefContextEnum.FORM_STUB_OFFENSE_INCIDENT.name}
                                    context={RefContextEnum.FORM_STUB_OFFENSE_INCIDENT.name}
                                    index={index}
                                    lifecycle={lifecycleOptions.REGISTER_AND_UNREGISTER}
                                    render={() => (
                                        <>
                                            <Row>
                                                <ArbiterMFTStatuteCodeSetRadio
                                                    form={this.form}
                                                    offenseCodeIdPath="offenseIncident.offenseCodeId"
                                                />
                                            </Row>
                                            <Row>
                                                <Observer
                                                    subscriptions={{
                                                        statuteCodeSetFilter:
                                                            'statuteCodeSetFilter',
                                                    }}
                                                    render={({ statuteCodeSetFilter }) => (
                                                        <Row>
                                                            <ArbiterMFTOffenseCodeSelect
                                                                path={
                                                                    'offenseIncident.offenseCodeId'
                                                                }
                                                                flags={applyStatuteCodeSetFilterToFlags(
                                                                    statuteCodeSetFilter,
                                                                    flags
                                                                )}
                                                                statuteCodeSetFilter={
                                                                    statuteCodeSetFilter
                                                                }
                                                                clearable={false}
                                                                onChange={
                                                                    onChangeOffenseIncidentCode
                                                                }
                                                                autoFocus={autoFocus}
                                                                label={strings.offenseCodeSelectLabel(
                                                                    offenseDisplayName
                                                                )}
                                                            />
                                                        </Row>
                                                    )}
                                                />
                                            </Row>
                                        </>
                                    )}
                                />
                            </CardSection>
                        )
                    );
                }}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    fieldConfigurationContextByContextAndFieldName: fieldConfigurationContextByContextAndFieldNameSelector,
    formatFieldByName: formatFieldByNameSelector,
    currentUserDepartmentProfile: currentUserDepartmentProfileSelector,
});

export default connect(mapStateToProps)(StubOffenseIncidentCard);

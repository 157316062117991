import { isFinite, isNaN, parseInt, reduce, slice } from 'lodash';
import { joinTruthyValues } from '../../../../helpers/stringHelpers';

/**
 * Ignore all initial strings in the array that are parsed as numeric.
 * Numeric strings appearing after non-numeric strings are not ignored.
 */
function ignoreLeadingNumbers(words: string[]): string {
    let keepCheckingForNumbers = true;
    return reduce(
        words,
        (acc, word) => {
            if (keepCheckingForNumbers && (!word || isFinite(parseInt(word)))) {
                return acc;
            } else {
                keepCheckingForNumbers = false;
                return joinTruthyValues([acc, word], ' ');
            }
        },
        ''
    );
}

/**
 * Round the given street number to its nearest 100th block.
 * This logic is based on requirements from Washington DC MPD.
 */
export function roundStreetAddressToNearestBlock(streetAddress = ''): string {
    const words = typeof streetAddress === 'string' ? streetAddress.trim().split(' ') : '';
    if (!words[0]) {
        return '';
    }

    const number = parseInt(words[0]);
    if (isNaN(number)) {
        return '';
    }

    const restOfAddress = ignoreLeadingNumbers(slice(words, 1));
    if (number < 0) {
        return '';
    }

    const block = Math.round(number / 100) * 100;
    return joinTruthyValues([`${block} BLK`, restOfAddress], ' ');
}

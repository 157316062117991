import { map } from 'lodash';
import { Radio as ArcRadio, RadioGroup, RadioGroupProps, RadioProps } from 'arc';
import React from 'react';
import styled from 'styled-components';

import classNames from 'classnames';
import { simpleControl } from 'markformythree';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import testIds from '../../../../core/testIds';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { arbiterMFTInput } from '../../arbiter';
import { RadioOption } from '../types';

const { connectRRFInput } = reactReduxFormHelpers;

const RadioButtonContainer = styled.label<{ disabled?: boolean }>`
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const RadioButtonInput = styled.input`
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export default class Radio extends React.Component<{
    // TODO: After removing ARC_RELEASE_CYCLE_THREE_COMPONENTS,
    // remove the `newlines` prop
    newlines?: boolean;
    options?: RadioOption[];
    name?: string;
    value?: string | number;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    label?: string;
    className?: string;
    orientation?: RadioGroupProps['direction'];
    size?: RadioProps['size'];
}> {
    static displayName = 'Radio';

    render() {
        const {
            newlines,
            options,
            name,
            value,
            onChange,
            disabled,
            label,
            className: radioClassName,
            orientation,
            size,
        } = this.props;

        const radioButtonContainerClassName = classNames('radio-option', { newline: newlines });
        const radioInputs = map(options, (option) => {
            const { value: optionValue, label: optionDisplay, isOptionDisabled = false } = option;
            const optionValueAsString = String(optionValue);
            return (
                <FeatureFlagged
                    key={optionValueAsString}
                    flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                    fallback={
                        <RadioButtonContainer
                            className={radioButtonContainerClassName}
                            disabled={disabled}
                        >
                            <div className="form-label radio-label">
                                <RadioButtonInput
                                    type="radio"
                                    name={name}
                                    value={optionValueAsString}
                                    checked={
                                        String(value) === optionValueAsString
                                    } /* booleans dont work otherwise */
                                    onChange={onChange}
                                    disabled={disabled || isOptionDisabled}
                                    data-test-id={testIds.RADIO_INPUT}
                                />
                                {optionDisplay}
                            </div>
                        </RadioButtonContainer>
                    }
                >
                    <ArcRadio
                        type="radio"
                        name={name}
                        value={optionValueAsString}
                        checked={
                            String(value) === optionValueAsString
                        } /* booleans dont work otherwise */
                        onChange={onChange}
                        disabled={disabled || isOptionDisabled}
                        data-test-id={testIds.RADIO_INPUT}
                    >
                        {optionDisplay}
                    </ArcRadio>
                </FeatureFlagged>
            );
        });

        return (
            <div className={classNames('mark43-form-field', radioClassName)}>
                {label && <label>{label}</label>}
                <FeatureFlagged
                    flag="ARC_RELEASE_CYCLE_THREE_COMPONENTS"
                    fallback={<div className="radio-group">{radioInputs}</div>}
                >
                    <RadioGroup direction={orientation} size={size}>
                        {radioInputs}
                    </RadioGroup>
                </FeatureFlagged>
            </div>
        );
    }
}

// @ts-expect-error client-common to client RND-7529
export const RRFRadio = connectRRFInput(Radio);
export const ArbiterMFTRadio = arbiterMFTInput(Radio);
export const MFTRadio = simpleControl(Radio);

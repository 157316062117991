import React from 'react';
import classNames from 'classnames';
import Link from '../../../core/components/links/Link';
import testIds from '../../../../core/testIds';

export default function EntityProfileSummaryWrapper({
    hasReadAccess,
    linkUrl,
    children,
    isOwnerDetail,
}) {
    const summaryClassName = classNames('entity-profile-entity-summary', {
        clickable: hasReadAccess,
        'viewable-owner-details-rows': isOwnerDetail && hasReadAccess,
        'findable-owner-details-rows': isOwnerDetail && !hasReadAccess,
    });
    return (
        <div className={summaryClassName} data-test-id={testIds.MASTER_PROFILE_CONTENT_LINK}>
            {hasReadAccess ? (
                <Link to={linkUrl} className="entity-profile-link">
                    {children}
                </Link>
            ) : (
                children
            )}
        </div>
    );
}

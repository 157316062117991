import React from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../core/testIds';
import ActionBar from '../../core/components/ActionBar';
import Button, { buttonTypes } from '../../../legacy-redux/components/core/Button';
import Link from '../../core/components/links/Link';

import OverlayButton from '../../core/overlays/components/OverlayButton';
import { saveMergedProfiles } from '../state/ui';
import MergeEntitiesConfirmationModal from './MergeEntitiesConfirmationModal';

const { mergeEntities } = componentStrings;

const MergeActionBar = ({ onMergeClick, backUrl, router }) => {
    const dispatch = useDispatch();

    return (
        <ActionBar visible position="static">
            <OverlayButton
                id={overlayIdEnum.MERGE_ENTITIES_CONFIRMATION_MODAL}
                className={buttonTypes.PRIMARY}
                float="none"
                display="inline-block"
                testId={testIds.CONFIRM_MERGE_ENTITIES_BUTTON}
            >
                {mergeEntities.MergeActionBar.mergeProfiles}
            </OverlayButton>
            <Link to={backUrl}>
                <Button className={buttonTypes.SECONDARY} float="none" display="inline-block">
                    {mergeEntities.MergeActionBar.cancelMerge}
                </Button>
            </Link>
            <MergeEntitiesConfirmationModal
                onAfterOpen={() => onMergeClick()}
                onSave={() =>
                    dispatch(saveMergedProfiles())
                        .then(({ pathname }) => {
                            router.push({ pathname });
                        })
                        .catch((err) => {
                            throw err;
                        })
                }
            />
        </ActionBar>
    );
};

export default withRouter(MergeActionBar);

import { PrintingDataTypeEnum, FileTypeEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import _ from 'lodash';

import {
    loadEvidencePrintables,
    removeEvidencePrintablesWhere,
    evidencePrintablesWhereSelector,
    LOAD_EVIDENCE_PRINTABLES_FAILURE,
} from '~/client-common/core/domain/evidence-printables/state/data';
import {
    loadCsvExportColumns,
    removeCsvExportColumnsWhere,
    LOAD_CSV_EXPORT_COLUMNS_FAILURE,
} from '~/client-common/core/domain/csv-export-columns/state/data';
import { evidencePrintablesOptionsByPrintingDataTypeSelector } from '~/client-common/core/domain/evidence-printables/state/ui';
import { parseEvidenceDocumentPrintableHash } from '~/client-common/core/domain/evidence-printables/utils/evidencePrintableHelpers';
import { makeResettable } from '~/client-common/helpers/reducerHelpers';

import { getPrintRequestObject } from '../../../../../legacy-redux/actions/exportsActions';
import { ENTER_NEW_ROUTE } from '../../../../../routing/routerModule';
import evidenceDashboardExportForm from '../forms/evidenceDashboardExportForm';
import {
    exportSearchResults,
    EVIDENCE_CSV_EXPORT_START,
    EVIDENCE_CSV_EXPORT_FAILURE,
} from '../../../core/state/data';
import { evidenceDashboardSearch } from './searchModule';

const { MULTI_REN_ITEM_LIST, EVIDENCE_ITEM_SEARCH } = PrintingDataTypeEnum;

export const printingDataTypes = [MULTI_REN_ITEM_LIST.name, EVIDENCE_ITEM_SEARCH.name];
const searchSelectors = evidenceDashboardSearch.selectors;

/**
 * On transition to the evidence dashboard exports page, load printables and csv columns
 */
export function getExportOptions() {
    return (dispatch, getState) => {
        const state = getState();

        const selectedRows = searchSelectors.selectedRowsSelector(state);
        const currentResults = searchSelectors.currentResultsSelector(state);
        const itemProfileIds = _(currentResults).at(selectedRows).map('id').value();

        // must clear evidence printables from data state because the
        // dropdown in the form will show all of them as options
        dispatch(removeEvidencePrintablesWhere({}));
        dispatch(loadEvidencePrintables({ itemIds: itemProfileIds, printingDataTypes }))
            .then((printables) => {
                const { printingTemplateId } = _.find(printables, {
                    dataType: EVIDENCE_ITEM_SEARCH.name,
                });

                // must clear from data state because the checkbox tree
                // will show all of them as options
                dispatch(removeCsvExportColumnsWhere({}));
                dispatch(loadCsvExportColumns(printingTemplateId)).then((columns) =>
                    dispatch(
                        evidenceDashboardExportForm.actionCreators.changePath(
                            'csvExportColumnIds',
                            _.map(columns, 'id')
                        )
                    )
                );
            })
            // errors handled in loadEvidencePrintables and loadCsvExportColumns
            .catch(_.noop);
    };
}

/**
 * Action to submit either PDF or CSV export.
 */
export function submitForm(exportType) {
    return (dispatch, getState) =>
        dispatch(
            evidenceDashboardExportForm.actionCreators.submit((formModel) => {
                const state = getState();

                const allResultsSelected = searchSelectors.allResultsSelectedSelector(state);
                const currentQuery = searchSelectors.currentQuerySelector(state);

                // we will convert each Printable to a WorkOrder to include in the API request
                let evidenceWorkOrders;

                // this is the potential value of each WorkOrder.search, which is optional and is an
                // elasticQuery
                const elasticQuery = allResultsSelected
                    ? currentQuery.elasticQuery
                    : {
                          ids: _(searchSelectors.currentResultsSelector(state))
                              .pick(searchSelectors.selectedRowsSelector(state))
                              .map('id')
                              .value(),
                      };

                const search = searchSelectors.transformElasticQueryBeforeSearchSelector(state)(
                    elasticQuery
                );

                if (exportType === FileTypeEnum.PDF.name) {
                    const printables = _(formModel.printableHash)
                        .thru(parseEvidenceDocumentPrintableHash)
                        .thru(evidencePrintablesWhereSelector(state))
                        .value();

                    // we don't call exportPDF directly here because we need to hijack the part that
                    // converts each Printable to a WorkOrder in order to make both 'export selected
                    // items' and 'export all search results' work
                    evidenceWorkOrders = getPrintRequestObject({}, printables);

                    if (allResultsSelected) {
                        evidenceWorkOrders = _.map(evidenceWorkOrders, (workOrder) => ({
                            ...workOrder,
                            // search is only needed when exporting all items because
                            // exportConfigs.evidenceItemProfileIds always exists
                            search,
                            // since we still always get printables using the current page's
                            // selected ids even when all results are selected, the selected
                            // evidenceItemProfileIds are still incorrectly included after
                            // converting from Printable to WorkOrder, and they must be
                            // cleared here
                            exportConfigs: _.omit(
                                workOrder.exportConfigs,
                                'evidenceItemProfileIds'
                            ),
                        }));
                    }
                } else if (exportType === FileTypeEnum.CSV.name) {
                    const printables = evidencePrintablesWhereSelector(state)({
                        dataType: EVIDENCE_ITEM_SEARCH.name,
                    });

                    // unlike for PDFs, we don't need to call getPrintRequestObject for CSVs because
                    // exportConfigs doesn't exist on these resulting workOrders
                    evidenceWorkOrders = _.map(printables, (printable) => ({
                        ..._.pick(printable, ['printingTemplateId', 'title', 'dataType']),
                        // tech debt: from and size are not needed here and aren't used on BE
                        ..._.pick(currentQuery, ['from', 'size']),
                        csvExportColumnIds: formModel.csvExportColumnIds,
                        search,
                    }));
                }

                return dispatch(exportSearchResults(evidenceWorkOrders));
            })
        );
}

/**
 * Printable Options for MULTI_REN_ITEM_LIST
 * @type {Object[]}
 */
export const multiRenItemListPrintableOptionsSelector = createSelector(
    evidencePrintablesOptionsByPrintingDataTypeSelector,
    (evidencePrintablesOptionsByPrintingDataType) =>
        evidencePrintablesOptionsByPrintingDataType(MULTI_REN_ITEM_LIST.name)
);

/**
 * Check if printableHash in the formModel has a value. Used to disable download button.
 * @type {boolean}
 */
export const disablePdfDownloadSelector = createSelector(
    evidenceDashboardExportForm.selectors.formModelByPathSelector,
    (formModelByPath) => !formModelByPath('printableHash')
);

const exportsUiSelector = (state) => state.ui.evidence.dashboard.exports;

/**
 * Error message to display in evidence dashboard exports.
 * @type {string}
 */
export const exportsErrorMessageSelector = createSelector(
    exportsUiSelector,
    (exportsUi) => exportsUi.errorMessage
);

export const exportsUiReducer = makeResettable(
    ENTER_NEW_ROUTE,
    function exportsUiReducer(state = { errorMessage: null }, action) {
        switch (action.type) {
            case EVIDENCE_CSV_EXPORT_START:
                return {
                    ...state,
                    errorMessage: null,
                };
            case LOAD_EVIDENCE_PRINTABLES_FAILURE:
            case LOAD_CSV_EXPORT_COLUMNS_FAILURE:
            case EVIDENCE_CSV_EXPORT_FAILURE:
                return {
                    ...state,
                    errorMessage: action.payload,
                };
            default:
                return state;
        }
    }
);

import _ from 'lodash';
import { ReportDefinition, ReportDefinitionCard } from '@mark43/rms-api';
import reportCardEnum from '../core/enums/universal/reportCardEnum';

/**
 * Format the display string for a report definition.
 */
export function formatReportDefinition(reportDefinition: ReportDefinition) {
    return _.get(reportDefinition, 'name');
}

/**
 * Format the given report definition as a dropdown/checkbox option.
 */
function mapReportDefinitionToOption({ id, name, isActive }: ReportDefinition) {
    return {
        value: id,
        display: name,
        noteDisplay: isActive ? null : ' (inactive)',
    };
}

/**
 * Format the given report definition as a dropdown/checkbox option.
 */
function mapReportDefinitionToVerboseOption({ id, name, isActive, isEnabled }: ReportDefinition) {
    let noteDisplay = '';
    if (isActive && !isEnabled) {
        noteDisplay = ' (disabled)';
    } else if (!isActive && isEnabled) {
        noteDisplay = ' (inactive)';
    } else if (!isActive && !isEnabled) {
        noteDisplay = ' (disabled and inactive)';
    }
    return {
        value: id,
        display: name,
        isEnabled,
        noteDisplay,
    };
}

/**
 * Format the given report definitions as dropdown/checkbox options, sorted by
 *   display string.
 */
export function mapReportDefinitionsToOptions(reportDefinitions: ReportDefinition[]) {
    return _(reportDefinitions).map(mapReportDefinitionToOption).sortBy('display').value();
}

/**
 * Format the given report definitions as dropdown/checkbox options, sorted by
 *   display string.
 */
export function mapReportDefinitionsToVerboseOptions(reportDefinitions: ReportDefinition[]) {
    return _(reportDefinitions).map(mapReportDefinitionToVerboseOption).sortBy('display').value();
}

/**
 * Return whether the given report definition has an event info card.
 */
export function reportDefinitionHasEventInfoCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.EVENT_INFO.id,
        })
    );
}

/**
 * Return whether the given report definition has a field contact card.
 */
export function reportDefinitionHasFieldContactCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.FIELD_CONTACT.id,
        })
    );
}

/**
 * Return whether the given report definition has a Legacy Migration Info card.
 */
export function reportDefinitionHasLegacyMigrationInfoCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.LEGACY_INFO.id,
        })
    );
}

/**
 * Return whether the given report definition has a missing persons card.
 */
export function reportDefinitionHasMissingPersonsCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.MISSING_PERSONS.id,
        })
    );
}

/**
 * Return whether the given report definition has an offense card.
 */
export function reportDefinitionHasOffenseCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.OFFENSE.id,
        })
    );
}

/**
 * Return whether the given report definition has an incident card.
 */
export function reportDefinitionHasIncidentCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.INCIDENT.id,
        })
    );
}

/**
 * Return whether the given report definition has an arrest card.
 */
export function reportDefinitionHasArrestCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.ARREST.id,
        })
    );
}

/**
 * Return whether the given report definition has a stop card.
 */
export function reportDefinitionHasStopCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.STOP.id,
        })
    );
}

/**
 * Return whether the given report definition has a custodial property summary
 *   card.
 */
export function reportDefinitionHasCustodialPropertySummaryCard(
    reportDefinition: ReportDefinition
) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.CUSTODIAL.id,
        })
    );
}

/**
 * Return whether the given report definition can have use of force card.
 */
export function reportDefinitionHasUseOfForceCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.USE_OF_FORCE.id,
        })
    );
}

/**
 * Return whether the given report definition can have use of force subject card.
 */
export function reportDefinitionHasUseOfForceSubjectCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.USE_OF_FORCE_SUBJECT.id,
        })
    );
}

/**
 * Return whether the given report definition can have tow vehicle card.
 */
export function reportDefinitionHasTowVehicle(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.TOW_VEHICLE.id,
        })
    );
}

/**
 * Return whether the given report definition can have a community information card.
 */
export function reportDefinitionHasCommunityInformation(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.COMMUNITY_INFORMATION.id,
        })
    );
}

/**
 * Return whether the given report definition can have a Supplement Offense card
 */
export function reportDefinitionHasSupplementOffenseCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.SUPPLEMENT_OFFENSE.id,
        })
    );
}

/**
 * Return whether the given report definition can have a Supplement Incident card
 */
export function reportDefinitionHasSupplementIncidentCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.SUPPLEMENT_INCIDENT.id,
        })
    );
}

/**
 * Return whether the given report definition can have a Supplement Info card
 */
export function reportDefinitionHasSupplementInfoCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.SUPPLEMENT_INFO.id,
        })
    );
}

/**
 * Return whether the given report definition can have a Citation Card
 * @param {object}  reportDefinition
 * @return {boolean}
 */
export function reportDefinitionHasCitationCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.CITATION.id,
        })
    );
}

/**
 * Return whether the given report definition can have a Impound Card
 * @param {object}  reportDefinition
 * @return {boolean}
 */
export function reportDefinitionHasImpoundCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.IMPOUND.id,
        })
    );
}

/**
 * Return whether the given report definition can have a Behavioral Crisis Card
 * @param {object}  reportDefinition
 * @return {boolean}
 */
export function reportDefinitionHasBehavioralCrisisCard(reportDefinition: ReportDefinition) {
    return !!(
        reportDefinition &&
        _.some(reportDefinition.cards, {
            id: reportCardEnum.BEHAVIORAL_CRISIS.id,
        })
    );
}

/**
 * When the Event Info and (Supplement Info OR Crash Event Info) cards are both not required, this means the report definition was a custom
 *   creation for this tenant, and the admin user may choose between the two cards.
 */
export function isCustomReportDefinition({
    cardLinks,
}: {
    cardLinks: Pick<ReportDefinitionCard, 'cardId' | 'isRequired'>[];
}): boolean {
    return (
        _.some(cardLinks, {
            cardId: reportCardEnum.EVENT_INFO.id,
            isRequired: false,
        }) &&
        (_.some(cardLinks, {
            cardId: reportCardEnum.SUPPLEMENT_INFO.id,
            isRequired: false,
        }) ||
            _.some(cardLinks, {
                cardId: reportCardEnum.CRASH_EVENT_INFO.id,
                isRequired: false,
            }))
    );
}

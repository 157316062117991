import { noop } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, withProps } from 'recompose';
import styled from 'styled-components';

import { AttributeTypeEnum } from '@mark43/rms-api';
import { _Form, createField, createFormConfiguration } from 'markformythree';
import { StatuteCodeSetFilterEnum } from '~/client-common/core/domain/offense-codes/utils/offenseCodeStatutesHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { attributeLoadingStateSelectorFactory } from '../../../core/attributes/state/ui';
import { loadAttributesForType } from '../../../core/attributes/state/ui/loadAttributesForType';
import { computeAllowedAttributeTypesToLoad } from '../../../core/attributes/utils/computeAllowedAttributeTypesToLoad';
import { ArbiterMFTRadio } from '../../../core/forms/components/Radio';

const strings = componentStrings.reports.core.StatuteCodeSetRadio;

const options = [
    {
        value: StatuteCodeSetFilterEnum.LOCAL_OFFENSE,
        label: strings.localOffense,
    },
    {
        value: StatuteCodeSetFilterEnum.FEDERAL_OFFENSE,
        label: strings.federalOffense,
    },
    {
        value: StatuteCodeSetFilterEnum.INCIDENT,
        label: strings.incident,
    },
];

// align the label and options on the same row
const ArbiterMFTRadioWithLeftLabel = styled(ArbiterMFTRadio)`
    & label {
        float: left;
        margin-right: 24px;
        font-size: var(--arc-fontSizes-sm);
    }

    & .radio-group {
        width: auto;
    }
`;

// tech debt: replace this type after offenseForm.js is typed
const PartialOffenseForm = createFormConfiguration({
    statuteCodeSetFilter: createField<keyof typeof StatuteCodeSetFilterEnum>({}),
});

type Props = {
    form: _Form<typeof PartialOffenseForm>;
    offenseCodeIdPath: string;
};

const BaseRadio: React.FC<Props> = ({ form, offenseCodeIdPath, ...otherProps }) => {
    const onChange = React.useCallback(() => {
        form.transaction(() => {
            form.set(offenseCodeIdPath, undefined);
            form.resetUi(offenseCodeIdPath);
        });
    }, [form, offenseCodeIdPath]);

    return (
        <ArbiterMFTRadioWithLeftLabel
            path="statuteCodeSetFilter"
            options={options}
            onChange={onChange}
            {...otherProps}
        />
    );
};

/**
 * Radio toggle to filter the options available in the offense code dropdown, based on the Statute
 *   Code Set attributes on the offense codes. This is meant to be used in all variants of the
 *   offense/incident cards, including their stub version.
 *
 * Tech debt: In LUNA-191, move this async attribute loading pattern into hooks.
 */
export const ArbiterMFTStatuteCodeSetRadio = compose<Props, Props>(
    withProps(() => ({ attributeType: AttributeTypeEnum.OFFENSE_CODE_STATUTE_CODE_SET.name })),
    connect(
        createStructuredSelector({
            attributeLoadingState: attributeLoadingStateSelectorFactory(),
        }),
        { loadAttributesForType }
    ),
    lifecycle({
        componentDidMount() {
            const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                // @ts-expect-error rewrite as functional component with hooks
                this.props.attributeLoadingState
            );
            if (attributeTypesToLoad.length) {
                // @ts-expect-error rewrite as functional component with hooks
                this.props
                    .loadAttributesForType({ attributeType: attributeTypesToLoad })
                    .catch(noop);
            }
        },
    })
)(BaseRadio);

import React from 'react';
import styled from 'styled-components';
import { map } from 'lodash';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import strings from '~/client-common/core/strings/componentStrings';
import { PopoutLink } from '../../../core/components/links/Link';

const Summary = styled.div`
    font-size: var(--arc-fontSizes-md);
    padding: 0 0 10px 22px;
`;

const SummarySubdetails = styled.div`
    color: ${(props) => props.theme.colors.mediumLightGrey};
`;

function WarrantSummary({ warrantTitleViewModel }) {
    const { warrantId, warrantTitle, warrantIssuedDateUtc } = warrantTitleViewModel;
    const { obtainingOfficerUserDisplay, canRead } = getViewModelProperties(warrantTitleViewModel);
    return (
        <Summary>
            <PopoutLink to={`warrants/${warrantId}`} disabled={!canRead}>
                {warrantTitle}
            </PopoutLink>
            <SummarySubdetails>
                <FormattedDate date={warrantIssuedDateUtc} format={FormattedDate.FORMATS.FORM_DATE}>
                    {(formattedDate) => (
                        <span>
                            {joinTruthyValues([
                                obtainingOfficerUserDisplay &&
                                    `${strings.warrants.generic.obtainedBy}: ${obtainingOfficerUserDisplay}`,
                                formattedDate,
                            ])}
                        </span>
                    )}
                </FormattedDate>
            </SummarySubdetails>
        </Summary>
    );
}

export default function ExistingWarrantTitles({ warrantTitleViewModels }) {
    return (
        <div>
            {map(warrantTitleViewModels, (warrantTitleViewModel) => (
                <WarrantSummary
                    key={warrantTitleViewModel.warrantId}
                    warrantTitleViewModel={warrantTitleViewModel}
                />
            ))}
        </div>
    );
}

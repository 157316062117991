import { AttributeTypeEnum, EntityTypeEnum, LinkTypesEnum } from '@mark43/rms-api';
import { map } from 'lodash';

import { NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP } from '../../../report-attributes/state/data';
import { NEXUS_STATE_PROP as MISSING_PERSONS_NEXUS_STATE_PROP } from '../../../missing-persons/state/data';
import { NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP } from '../../../location-entity-links/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in a Missing Persons Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const MISSING_PERSONS_CARD_REPORT_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.MISSING_PERSON_ADDITIONAL_INFO.name,
];

/**
 * `LinkTypes` that are valid for the `LocationEntityLinks`s in an Missing Persons Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const MISSING_PERSONS_CARD_LOCATION_LINK_TYPES = [
    LinkTypesEnum.LAST_KNOWN_LOCATION,
    LinkTypesEnum.LOCATION_WHERE_LOCATED,
];

export function replaceMissingPersonsCardBundle({ reportId, missingPersonsCardBundle }) {
    return function (dispatch, getState, dependencies) {
        const resource = getReportCardBundleResource();
        const { withEntityItems, withRemoveMultiple } = dependencies.nexus;

        return resource
            .upsertMissingPersonsCard(reportId, missingPersonsCardBundle)
            .then((updatedMissingPersonsCardBundle) => {
                const {
                    missingPerson,
                    reportAttributes,
                    locationEntityLinks,
                } = updatedMissingPersonsCardBundle;
                const predicateReportAttributeObjectsToRemoveBy = map(
                    MISSING_PERSONS_CARD_REPORT_ATTRIBUTE_TYPES,
                    (attributeType) => ({
                        reportId,
                        attributeType,
                    })
                );

                const predicateLocationEntityLinksToRemoveBy = map(
                    MISSING_PERSONS_CARD_LOCATION_LINK_TYPES,
                    (linkType) => ({
                        linkType,
                        entityType: EntityTypeEnum.REPORT.name,
                        entityId: reportId,
                    })
                );

                dispatch(
                    withEntityItems(
                        {
                            [MISSING_PERSONS_NEXUS_STATE_PROP]: [missingPerson],
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                        },
                        // On the BE, we perform a full replacement of all attributes
                        // by our target `MISSING_PERSONS_CARD_REPORT_ATTRIBUTE_TYPES`,
                        // so, remove all `ReportAttribute` objects from state, by our
                        // `reportId, attributeType` key.
                        //
                        // We also do a full replacement of `locationEntitylinks`
                        // so we need to remove these before re-adding them in as well
                        withRemoveMultiple(
                            {
                                [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: predicateReportAttributeObjectsToRemoveBy,
                                [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: predicateLocationEntityLinksToRemoveBy,
                            },
                            {
                                type: 'UPSERT_MISSING_PERSONS_CARD_BUNDLE',
                            }
                        )
                    )
                );

                return updatedMissingPersonsCardBundle;
            });
    };
}

export const COLUMN_WIDTH_12 = '37.5%';
export const COLUMN_WIDTH_24 = '75%';
export const COLUMN_WIDTH_8 = '25%';
export const LEFT_VALUE = 0;
export const RIGHT_VALUE = 1;

export const NO_VALUE_SENTINEL = '-';

export const HYDRATED_PERSON_PROFILE_PROP = 'personProfile';
export const HYDRATED_ORGANIZATION_PROFILE_PROP = 'organizationProfile';
export const PERSON_GANG_TRACKINGS_PROP = 'personGangTrackings';
export const HYDRATED_VEHICLE_PROP = 'vehicle';

import {
    ApprovalStatusEnum,
    BulkReportApprovalStatusResponse,
    Offense,
    ProductModuleEnum,
    RefContextEnum,
    ReportApprovalStatusRequest,
    ReportApprovalStatusResponse,
    ReportApprovalStatusView,
} from '@mark43/rms-api';
import { find, get, includes, map, noop, isEmpty } from 'lodash';

import {
    NEXUS_STATE_PROP as REPORT_STATUS_HISTORIES_NEXUS_STATE_PROP,
    orderedReportStatusHistoriesByReportIdSelector,
} from '~/client-common/core/domain/report-status-histories/state/data';
import { NEXUS_STATE_PROP as REPORT_SUBMISSION_AUTHORS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-submission-authors/state/data';
import { NEXUS_STATE_PROP as REPORTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/reports/state/data';
import { NEXUS_STATE_PROP as REPORT_COMMENTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-comments/state/data';
import { NEXUS_STATE_PROP as REPORT_SHORT_TITLES_NEXUS_STATE_PROP } from '~/client-common/core/domain/report-short-titles/state/data';
import { NEXUS_STATE_PROP as OFFENSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/offenses/state/data';
import { isOffenseReportSelector } from '~/client-common/core/domain/report-definitions/state/data';
import getOffensesResource from '~/client-common/core/domain/offenses/resources/offensesResource';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import { isProductModuleActiveSelector } from '~/client-common/core/domain/product-modules/state/data';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import getReportsResource from '~/client-common/core/domain/reports/resources/reportResource';

import reportsResource from '../../resources/reportsResource';
import {
    currentUserHasAbilitySelector,
    currentUserIdSelector,
} from '../../../../core/current-user/state/ui';
import { pollForCustodialPropertySummaryReport } from '../data/submission';
import {
    storeReports,
    storeLinkedReportIds,
    setReportIsPackaged,
    setReportIsUcrValidated,
    setCanSubmit,
    setReportStatusView,
    reportSubmissionChange,
} from '../../../../../legacy-redux/actions/reportsActions';
import {
    openBox,
    openErrorModal,
    openLoadingModal,
    closeLoadingModal,
} from '../../../../../legacy-redux/actions/boxActions';
import {
    currentReportSelector,
    currentReportIdSelector,
    currentReportEventIdSelector,
    reportStatusViewSelector,
    reportSubmissionAuthorsSelector,
} from '../../../../../legacy-redux/selectors/reportSelectors';
import routingLabelsRequiredValidationSelector from '../../../../../legacy-redux/validation/selectors/routingLabelsRequiredValidationSelector';
import errors from '../../../../../lib/errors';
import { DependenciesArg, RmsAction, RmsDispatch } from '../../../../../core/typings/redux';
import { RootState } from '../../../../../legacy-redux/reducers/rootReducer';
import { embeddedReportShortTitlesSelector } from './arrestBlock';
import { offenseURNEnabledSelector } from './offenseURN';

const strings = componentStrings.reports.core.ReportStatusCommentsCard.submissions;

function saveReportApprovalStatusView(
    nexus: DependenciesArg['nexus'],
    reportApprovalStatusView: ReportApprovalStatusView
): RmsAction<void> {
    const reportStatusView = reportApprovalStatusView.reportStatusView;
    return (dispatch) => {
        // this is an unfortunate requirement until we get rid of legacy reports data state,
        // eventually we should be able to get away with only firing a single action
        dispatch(storeReports([reportApprovalStatusView.reportStatusView.report]));
        dispatch(setReportStatusView(reportStatusView));
        dispatch(setCanSubmit(reportStatusView.canSubmit));
        const baseAction = reportSubmissionChange(reportApprovalStatusView);
        dispatch(
            nexus.withEntityItems(
                {
                    [REPORT_SUBMISSION_AUTHORS_NEXUS_STATE_PROP]:
                        reportApprovalStatusView.reportSubmissionAuthors,
                    [REPORT_STATUS_HISTORIES_NEXUS_STATE_PROP]:
                        reportApprovalStatusView.reportStatusHistories,
                    [REPORTS_NEXUS_STATE_PROP]: reportApprovalStatusView.reportStatusView.report,
                    [REPORT_COMMENTS_NEXUS_STATE_PROP]: reportApprovalStatusView.reportComments,
                },
                baseAction
            )
        );
    };
}

function transitionReportApprovalStatusSuccessHandler(
    dispatch: RmsDispatch,
    getState: () => RootState,
    dependencies: DependenciesArg
) {
    return (
        reportApprovalStatusResponse:
            | ReportApprovalStatusResponse
            | BulkReportApprovalStatusResponse
    ) => {
        if (reportApprovalStatusResponse.transitionSucceeded) {
            if (
                'reportApprovalStatusView' in reportApprovalStatusResponse &&
                reportApprovalStatusResponse.reportApprovalStatusView
            ) {
                // handle response for single report
                dispatch(
                    saveReportApprovalStatusView(
                        dependencies.nexus,
                        reportApprovalStatusResponse.reportApprovalStatusView
                    )
                );
            } else if (
                'reportApprovalStatusViews' in reportApprovalStatusResponse &&
                reportApprovalStatusResponse.reportApprovalStatusViews
            ) {
                // handle response for bulk reports
                const state = getState();
                const currentReportId = currentReportIdSelector(state);
                const viewForCurrentReport = find(
                    reportApprovalStatusResponse.reportApprovalStatusViews,
                    (view) => view.reportStatusView.report.id === currentReportId
                );
                if (viewForCurrentReport) {
                    dispatch(
                        saveReportApprovalStatusView(dependencies.nexus, viewForCurrentReport)
                    );
                }
                // since linked reports are displayed using their ReportShortTitles, it is essential for this part of
                // state to be updated after the current report triggers updates to the approval statuses of linked
                // reports
                dispatch(
                    dependencies.nexus.withEntityItems(
                        {
                            [REPORT_SHORT_TITLES_NEXUS_STATE_PROP]: map(
                                reportApprovalStatusResponse.reportApprovalStatusViews,
                                'reportShortTitle'
                            ),
                        },
                        { type: 'UPDATE_REPORT_SHORT_TITLES' }
                    )
                );
            }
        }
        return reportApprovalStatusResponse;
    };
}

function transitionReportApprovalStatusFailureHandler(
    dispatch: RmsDispatch,
    errorModalContents?: { title: string; paragraphs?: string[]; list?: string[] }
) {
    return (err: Error) => {
        if (err instanceof errors.ConflictError) {
            dispatch(
                openErrorModal({
                    title: strings.errors.conflict.title,
                    list: [err.message],
                })
            );
        } else if (!!errorModalContents) {
            dispatch(openErrorModal(errorModalContents));
        } else {
            dispatch(
                openErrorModal({
                    title: strings.errors.generic.title,
                    paragraphs: [err.message],
                })
            );
        }
    };
}

export function editMediator(editCallback: () => void): RmsAction<void> {
    return (dispatch, getState) => {
        const state = getState();
        const reportStatusView = reportStatusViewSelector(state);
        const canMasterEdit = get(reportStatusView, 'canMasterEdit');
        const currentReport = currentReportSelector(state);
        const approvalStatus = get(currentReport, 'approvalStatus');
        const secondaryApprovalStatus = get(currentReport, 'secondaryApprovalStatus');

        dispatch(setReportIsPackaged(false));
        // RMS-4211 -- Editing any card _always_ resets the Secondary Approver's
        // report validation status.
        dispatch(setReportIsUcrValidated(false));

        // NOTE: the legacy editMediator included this but I don't see any usages of
        // showUcrApprove besides setting it in the legacy submissions so nuking
        // self.showUcrApprove(false);

        if (
            approvalStatus === ApprovalStatusEnum.COMPLETED.name &&
            secondaryApprovalStatus === ApprovalStatusEnum.COMPLETED.name &&
            canMasterEdit
        ) {
            dispatch(rescindStaffReview(editCallback));
        } else if (approvalStatus === ApprovalStatusEnum.DRAFT.name || canMasterEdit) {
            return dispatch(addSubmissionAuthor(editCallback));
        } else if (approvalStatus === ApprovalStatusEnum.REJECTED.name) {
            return dispatch(
                addSubmissionAuthor(() => {
                    dispatch(actuallyRedraftReport(editCallback));
                })
            );
        } else {
            return dispatch(redraftReport(editCallback));
        }
    };
}

function rescindStaffReview(callback: () => void): RmsAction<void> {
    return (dispatch, getState, { overlayStore }) => {
        overlayStore.open(overlayIdEnum.RESCIND_STAFF_REVIEW_MODAL, { callback });
    };
}

export function actuallyRescindStaffReview(callback: () => void): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        return getReportsResource()
            .transitionReportApprovalStatus(currentReportId, {
                suggestedApprovalStatus: ApprovalStatusEnum.COMPLETED.name,
                suggestedSecondaryApprovalStatus: ApprovalStatusEnum.DRAFT.name,
            })
            .then(transitionReportApprovalStatusSuccessHandler(dispatch, getState, dependencies))
            .then(() => {
                if (callback) {
                    callback();
                }
                dependencies.overlayStore.close(overlayIdEnum.RESCIND_STAFF_REVIEW_MODAL);
            })
            .catch(transitionReportApprovalStatusFailureHandler(dispatch));
    };
}

function redraftReport(callback: () => void): RmsAction<void> {
    return (dispatch) => {
        dispatch(
            openBox(
                {
                    name: boxEnum.RETURN_REPORT_TO_DRAFT_MODAL,
                },
                { callback }
            )
        );
    };
}

export function actuallyRedraftReport(callback: () => void): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        return getReportsResource()
            .transitionReportApprovalStatus(currentReportId, {
                suggestedApprovalStatus: ApprovalStatusEnum.DRAFT.name,
            })
            .then(transitionReportApprovalStatusSuccessHandler(dispatch, getState, dependencies))
            .then(() => {
                dispatch(setReportIsPackaged(false));
                if (callback) {
                    callback();
                }
            })
            .catch(transitionReportApprovalStatusFailureHandler(dispatch), {
                title: strings.errors.returnToDraft.title,
                paragraphs: [strings.errors.returnToDraft.message],
            });
    };
}

export function submitReport(): RmsAction<void> {
    return (dispatch, getState) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        const reportStatusHistories = currentReportId
            ? orderedReportStatusHistoriesByReportIdSelector(state)(currentReportId)
            : [];
        if (reportStatusHistories.length > 0) {
            dispatch(openBox({ name: boxEnum.SUBMISSION_COMMENTS_MODAL }));
        } else {
            dispatch(actuallySubmitReport());
        }
    };
}

/**
 * Submit all reports currently shown on the page.
 */
export function actuallySubmitReport(submissionComments = ''): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state) || undefined;
        const embeddedReportIds = map(embeddedReportShortTitlesSelector(state), 'reportId');
        const reportSubmissionForm = dependencies.formsRegistry.get(
            RefContextEnum.FORM_REPORT_SUBMISSION.name
        );
        const roleIds = reportSubmissionForm?.get('roleIds') as number[] | undefined;
        const userRoleIds = reportSubmissionForm?.get('userIds') as number[] | undefined;
        dispatch(openLoadingModal());

        const reportApprovalStatusRequest = {
            suggestedApprovalStatus: ApprovalStatusEnum.SUBMITTED.name,
            comment: submissionComments,
            notifyIds: [...(roleIds || []), ...(userRoleIds || [])],
        };
        const promise =
            embeddedReportIds.length > 0
                ? reportsResource.bulkTransitionReportApprovalStatus(
                      map([currentReportId, ...embeddedReportIds], (reportId) => ({
                          reportId,
                          ...reportApprovalStatusRequest,
                      }))
                  )
                : getReportsResource().transitionReportApprovalStatus(
                      currentReportId,
                      reportApprovalStatusRequest
                  );

        return promise
            .then(transitionReportApprovalStatusSuccessHandler(dispatch, getState, dependencies))
            .then(() => {
                /**
                 * If
                 *     only 1 report was submitted,
                 *     it is an offense report, and
                 *     the department is configured to display offense URN,
                 * then
                 *     load the updated offense data and
                 *     store them into Redux state,
                 *     which causes the offense card components to re-render with the URNs in their titles.
                 */
                const isOffenseReport = isOffenseReportSelector(state);
                if (!!currentReportId && isOffenseReport(currentReportId)) {
                    const offenseURNEnabled = offenseURNEnabledSelector(state);
                    if (offenseURNEnabled) {
                        const currentReportEventId = currentReportEventIdSelector(state);
                        const offensesResource = getOffensesResource();
                        dispatch(() =>
                            offensesResource.getOffensesForEventId(currentReportEventId)
                        ).then((offenses: Offense[]) => {
                            if (offenses && offenses.length > 0) {
                                dispatch(
                                    dependencies.nexus.withEntityItems(
                                        {
                                            [OFFENSES_NEXUS_STATE_PROP]: offenses,
                                        },
                                        {
                                            type: 'RELOAD_OFFENSES_FOR_URN',
                                        }
                                    )
                                );
                            }
                        });
                    }
                }
            })
            .then(() => {
                // if evidence is enabled and the user has the basic evidence
                // ability, then try to poll for a linked Custodial Property Summary
                // report, which may be generated async by the Import Event
                const evidenceEnabled = isProductModuleActiveSelector(state)(
                    ProductModuleEnum.EVIDENCE.name
                );
                const hasViewEvidenceAbility = currentUserHasAbilitySelector(state)(
                    abilitiesEnum.EVIDENCE.VIEW_GENERAL
                );
                if (evidenceEnabled && hasViewEvidenceAbility && currentReportId) {
                    // this action creator decides whether polling is actually needed
                    dispatch(pollForCustodialPropertySummaryReport(currentReportId)).then(
                        (custodialReport) => {
                            // if polling succeeded, show the new Custodial report in this report's sidebar
                            if (custodialReport && typeof custodialReport !== 'boolean') {
                                dispatch(storeLinkedReportIds([custodialReport.id]));
                            }
                        }
                    );
                }
            })
            .catch(
                transitionReportApprovalStatusFailureHandler(dispatch, {
                    title: strings.errors.submit.title,
                    paragraphs: [strings.errors.submit.message],
                })
            )
            .then(() => {
                dispatch(closeLoadingModal());
                reportSubmissionForm?.resetModel();
            });
    };
}

function validateReportApproval(getState: () => RootState, dependencies: DependenciesArg) {
    const state = getState();
    const validationErrorMessage = routingLabelsRequiredValidationSelector(state);
    if (!isEmpty(validationErrorMessage)) {
        dependencies.overlayStore.open(overlayIdEnum.SUBMISSION_ERROR_MODAL, {
            errors: [{ message: validationErrorMessage }],
        });
    }
    return validationErrorMessage;
}

export function approveReport(): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        const currentReportId = currentReportIdSelector(getState());
        const reportApprovalValidationErrors = validateReportApproval(getState, dependencies);
        if (isEmpty(reportApprovalValidationErrors)) {
            return getReportsResource()
                .transitionReportApprovalStatus(currentReportId, {
                    suggestedApprovalStatus: ApprovalStatusEnum.APPROVED.name,
                })
                .then(
                    transitionReportApprovalStatusSuccessHandler(dispatch, getState, dependencies)
                )
                .catch((err: Error) =>
                    transitionReportApprovalStatusFailureHandler(dispatch, {
                        title: strings.errors.review.title,
                        paragraphs: [err.message],
                    })
                );
        }
        return Promise.resolve();
    };
}

export function secondaryApproveReport(): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        const currentReportId = currentReportIdSelector(getState());
        return getReportsResource()
            .transitionReportApprovalStatus(currentReportId, {
                suggestedSecondaryApprovalStatus: ApprovalStatusEnum.APPROVED.name,
            })
            .then(transitionReportApprovalStatusSuccessHandler(dispatch, getState, dependencies))
            .then(() => dispatch(setReportIsUcrValidated(true)))
            .catch((err: Error) => {
                dispatch(setReportIsUcrValidated(false));
                transitionReportApprovalStatusFailureHandler(dispatch, {
                    title: strings.errors.secondaryReview.title,
                    paragraphs: [err.message],
                })(err);
            });
    };
}

export function actuallyRejectReport(
    isStaffReview: boolean,
    reasonForRejection?: string,
    callback: () => void = noop
): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const currentReportId = currentReportIdSelector(state);
        const rejectionRequest: Partial<ReportApprovalStatusRequest> = {
            comment: reasonForRejection,
        };
        if (isStaffReview) {
            rejectionRequest.suggestedSecondaryApprovalStatus = ApprovalStatusEnum.REJECTED.name;
        } else {
            rejectionRequest.suggestedApprovalStatus = ApprovalStatusEnum.REJECTED.name;
        }
        // Send the rejection request
        return getReportsResource()
            .transitionReportApprovalStatus(currentReportId, rejectionRequest)
            .then(transitionReportApprovalStatusSuccessHandler(dispatch, getState, dependencies))
            .then(callback)
            .catch(
                transitionReportApprovalStatusFailureHandler(dispatch, {
                    title: strings.errors.review.title,
                    paragraphs: [strings.errors.review.message],
                })
            );
    };
}

export function addSubmissionAuthor(callback: () => void): RmsAction<Promise<void>> {
    return (dispatch, getState) => {
        const state = getState();
        const reportStatusView = reportStatusViewSelector(state);
        const activeSubmission = get(reportStatusView, 'activeSubmission');
        const reportSubmissionAuthors = reportSubmissionAuthorsSelector(state);
        const currentUserId = currentUserIdSelector(state);

        // if the current user is one of this submissions authors
        // also we have to send stuff to the server if the owner is null so only let this pass
        // if ownerId is truthy
        if (
            !!get(activeSubmission, 'ownerId') &&
            includes(map(reportSubmissionAuthors, 'officerId'), currentUserId)
        ) {
            if (callback) {
                callback();
            }
            return;
        }

        const author = {
            officerId: currentUserId,
            submissionId: activeSubmission.id,
        };

        // TODO: right now the legacy code relies on a boolean sent back from the
        // BE called `shouldRefresh`. This is only true when a report submission exists
        // in the DB but doesn't have an ownerId. As of 7/9/19 there are no examples of
        // report submissions or histories where ownerId is null so in practice, `shouldRefresh`
        // should always be false. As a result, we're not implementing any refresh logic here.
        // However, it would be nice to be able to automatically add report authors to the report
        // header display when adding a submission author so the user doesn't have to refresh the page.
        // We should refactor this call to either refetch the most updated report submission authors
        // after adding or to have the BE endpoint just send the updated authors. We will then need
        // to update the ReportHeader component to pull authors from redux state rather than
        // state.ui.report.reportSubmissionAuthors from which it is currently pulling them
        return getReportsResource()
            .addReportSubmissionAuthor(author)
            .then(() => !!callback && callback())
            .catch((err: Error) => {
                dispatch(
                    openErrorModal({
                        title: strings.errors.edit.title,
                        paragraphs: [err.message],
                    })
                );
            });
    };
}

export function refreshReportApprovalStatusView(reportId: number): RmsAction<Promise<void>> {
    return (dispatch, getState, dependencies) => {
        return getReportsResource()
            .getReportApprovalStatusView(reportId)
            .then((reportApprovalStatusView: ReportApprovalStatusView) =>
                dispatch(saveReportApprovalStatusView(dependencies.nexus, reportApprovalStatusView))
            );
    };
}

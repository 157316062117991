import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { mediaQueries, InlineBannerProps } from 'arc';

import { InlineBanner } from '../../components/InlineBanner';
import testIds from '../../../../core/testIds';
import { confirmationBarSelector, closeConfirmationBar } from '../state/ui';
import { RmsDispatch } from '../../../../core/typings/redux';

// By default this will be located just below the headers
// Could/Should be customized later to be located wherever
const StyledInlineBanner = styled(InlineBanner)`
    z-index: 10000;
    position: absolute;
    top: var(--arc-space-2);
    left: var(--arc-space-2);
    right: var(--arc-space-2);
    box-shadow: var(--arc-shadows-lg);

    @media (min-width: ${mediaQueries.sm}) {
        top: var(--arc-space-8);
        left: var(--arc-space-6);
        right: var(--arc-space-6);
    }
`;

type ConfirmationBarTypes = InlineBannerProps & {
    delay: number;
};

/**
 * Component for fading out after a certain amount of delay
 * The timer will reset when the mouse hovers over
 * and restart when the mouse leaves
 * NOTE: Some or all of these might be able to be replaced eventually with the ARC Toast.
 */
const ConfirmationBar = ({ delay = 10000 }: ConfirmationBarTypes) => {
    const ui = useSelector(confirmationBarSelector);
    const dispatch = useDispatch<RmsDispatch>();
    const closeBar = React.useCallback(() => {
        dispatch(closeConfirmationBar());
    }, [dispatch]);

    const timer = React.useRef<number | null>();

    const stopTimer = () => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    };

    const startTimer = React.useCallback(() => {
        stopTimer();
        timer.current = setTimeout(() => {
            closeBar();
        }, delay);
    }, [closeBar, delay]);

    const close = () => {
        closeBar();
        timer.current = null;
    };

    React.useEffect(() => {
        if (ui.isOpen) {
            startTimer();
        } else {
            stopTimer();
        }
    }, [ui.isOpen, startTimer, ui.message]);

    if (!ui.isOpen) {
        return null;
    }

    return (
        <StyledInlineBanner
            data-test-id={testIds.CONFIRMATION_BAR}
            onMouseEnter={stopTimer}
            onMouseLeave={startTimer}
            status={ui.isError ? 'error' : ui.isSuccess ? 'success' : 'attention'}
            description={ui.message}
            onClose={close}
        />
    );
};

export default ConfirmationBar;

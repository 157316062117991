import { compact, concat, flatMap, includes, map } from 'lodash';
import { createSelector } from 'reselect';
import { LinkTypesEnum, AttributeTypeEnum, EntityTypeEnum } from '@mark43/rms-api';

import sortNameReportLinks from '../../../name-report-links/utils/sortNameReportLinks';
import sortLocationEntityLinks from '../../../location-entity-links/utils/sortLocationEntityLinks';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';
import {
    locationEntityLinksWhereSelector,
    NEXUS_STATE_PROP as LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP,
} from '../../../location-entity-links/state/data';
import {
    stopByReportIdSelector,
    NEXUS_STATE_PROP as STOPS_NEXUS_STATE_PROP,
} from '../../../stops/state/data';
import {
    reportAttributesWhereSelector,
    NEXUS_STATE_PROP as REPORT_ATTRIBUTES_NEXUS_STATE_PROP,
} from '../../../report-attributes/state/data';
import {
    stopAnonymousSubjectsWhereSelector,
    NEXUS_STATE_PROP as STOP_ANONYMOUS_SUBJECTS_NEXUS_STATE_PROP,
} from '../../../stop-anonymous-subjects/state/data';
import {
    nameReportLinksWhereSelector,
    NEXUS_STATE_PROP as NAME_REPORT_LINKS_NEXUS_STATE_PROP,
} from '../../../name-report-links/state/data';
import {
    stopEntityAttributesWhereSelector,
    NEXUS_STATE_PROP as STOP_ENTITY_ATTRIBUTES_ATTRIBUTES_NEXUS_STATE_PROP,
} from '../../../stop-entity-attributes/state/data';
import {
    ripaSubjectOffenseCodesWhereSelector,
    NEXUS_STATE_PROP as RIPA_SUBJECT_OFFENSE_CODES_NEXUS_STATE_PROP,
} from '../../../ripa-subject-offense-codes/state/data';
import {
    dojSchoolsSelector,
    NEXUS_STATE_PROP as DOJ_SCHOOLS_NEXUS_STATE_PROP,
} from '../../../doj-schools/state/data';

/**
 * `AttributeType`s that are valid for the `ReportAttribute`s in a Stop Card data bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const STOP_CARD_REPORT_ATTRIBUTE_TYPES = [
    AttributeTypeEnum.FIELD_CONTACT_DISPOSITION.name,
    AttributeTypeEnum.FIELD_CONTACT_REASON_FOR_STOP.name,
    AttributeTypeEnum.REASON_FOR_SEARCH.name,
    AttributeTypeEnum.RESULT_OF_STOP.name,
    AttributeTypeEnum.STOP_ACTION_TAKEN.name,
    AttributeTypeEnum.STOP_BASIS_FOR_PROPERTY_SEIZURE.name,
    AttributeTypeEnum.STOP_SUSPICION_TYPE.name,
    AttributeTypeEnum.SUBJECT_DATA_PROBABLE_CAUSE.name,
    AttributeTypeEnum.STOP_TYPE_OF_PROPERTY_SEIZED.name,
    AttributeTypeEnum.STOP_REASON_FOR_STOP.name,
    AttributeTypeEnum.WEAPON_INVOLVED.name,
];

/**
 * `LinkTypes` that are valid for the `NameReportLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const STOP_CARD_NAME_REPORT_LINK_TYPES = [
    LinkTypesEnum.SUBJECT_IN_STOP,
    LinkTypesEnum.OTHER_NAME_IN_STOP,
];

/**
 * `LinkTypes` that are valid for the `LocationEntityLink`s in the bundle.
 * Must be kept in sync with BE constant sharing same variable name.
 */
const STOP_CARD_LOCATION_LINK_TYPES = [
    LinkTypesEnum.LOCATION_OF_STOP,
    LinkTypesEnum.LOCATION_OF_PERSON_STOP,
];

export const hydratedStopByReportIdSelector = createSelector(
    stopByReportIdSelector,
    reportAttributesWhereSelector,
    nameReportLinksWhereSelector,
    stopEntityAttributesWhereSelector,
    locationEntityLinksWhereSelector,
    stopAnonymousSubjectsWhereSelector,
    ripaSubjectOffenseCodesWhereSelector,
    dojSchoolsSelector,
    (
        stopByReportId,
        reportAttributesWhere,
        nameReportLinksWhere,
        stopEntityAttributesWhere,
        locationEntityLinksWhere,
        stopAnonymousSubjectsWhere,
        ripaSubjectOffenseCodesWhere,
        dojSchools
    ) => (reportId) => {
        const stop = stopByReportId(reportId);
        if (!stop) {
            return {};
        }

        const { id: stopId } = stop;
        const reportAttributes = reportAttributesWhere((reportId) => {
            return (reportAttribute) =>
                reportAttribute.reportId === reportId &&
                includes(STOP_CARD_REPORT_ATTRIBUTE_TYPES, reportAttribute.attributeType);
        });
        const subjects = nameReportLinksWhere({
            reportId,
            contextType: EntityTypeEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.SUBJECT_IN_STOP,
        });
        const otherNames = nameReportLinksWhere({
            reportId,
            contextType: EntityTypeEnum.REPORT.name,
            contextId: reportId,
            linkType: LinkTypesEnum.OTHER_NAME_IN_STOP,
        });
        const stopEntityAttributes = stopEntityAttributesWhere({
            stopId,
        });
        const stopLocation = locationEntityLinksWhere({
            entityType: EntityTypeEnum.STOP.name,
            linkType: LinkTypesEnum.LOCATION_OF_STOP,
            entityId: stopId,
        });
        const subjectLocations = compact(
            flatMap(subjects, (subject) =>
                locationEntityLinksWhere({
                    entityType: EntityTypeEnum.PERSON_PROFILE.name,
                    linkType: LinkTypesEnum.LOCATION_OF_PERSON_STOP,
                    entityId: subject.nameId,
                })
            )
        );

        const sortedSubjects = sortNameReportLinks({
            nameReportLinks: subjects,
        });
        const sortedOtherNames = sortNameReportLinks({
            nameReportLinks: otherNames,
        });
        const sortedLocationEntityLinks = sortLocationEntityLinks({
            locationEntityLinks: concat(stopLocation, subjectLocations),
        });
        const stopAnonymousSubjects = stopAnonymousSubjectsWhere({
            stopId,
        });
        const ripaSubjectOffenseCodes = ripaSubjectOffenseCodesWhere({ stopId });

        return {
            stop,
            reportAttributes,
            subjects: sortedSubjects,
            stopEntityAttributes,
            otherNames: sortedOtherNames,
            locationEntityLinks: sortedLocationEntityLinks,
            stopAnonymousSubjects,
            ripaSubjectOffenseCodes,
            dojSchools,
        };
    }
);

export function replaceStopCardBundle({ reportId, stopCardBundle }) {
    return (dispatch, getState, { nexus: { withEntityItems, withRemoveMultiple } }) => {
        const resource = getReportCardBundleResource();
        return resource.upsertStopCard(reportId, stopCardBundle).then((updatedStopCardBundle) => {
            const {
                stop,
                reportAttributes,
                nameReportLinks,
                stopEntityAttributes,
                locationEntityLinks,
                stopAnonymousSubjects,
                ripaSubjectOffenseCodes,
                dojSchool,
            } = updatedStopCardBundle;
            const { id: stopId } = stop;
            const predicateReportAttributeObjectsToRemoveBy = map(
                STOP_CARD_REPORT_ATTRIBUTE_TYPES,
                (attributeType) => ({
                    reportId,
                    attributeType,
                })
            );
            const predicateNameReportLinksToRemoveBy = map(
                STOP_CARD_NAME_REPORT_LINK_TYPES,
                (linkType) => ({
                    reportId,
                    linkType,
                })
            );
            const predicateLocationEntityLinksToRemoveBy = map(
                STOP_CARD_LOCATION_LINK_TYPES,
                (linkType) => ({
                    linkType,
                    entityType: EntityTypeEnum.STOP.name,
                    entityId: stopId,
                })
            );

            dispatch(
                withEntityItems(
                    {
                        [STOPS_NEXUS_STATE_PROP]: [stop],
                        [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: reportAttributes,
                        [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: nameReportLinks,
                        [STOP_ENTITY_ATTRIBUTES_ATTRIBUTES_NEXUS_STATE_PROP]: stopEntityAttributes,
                        [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: locationEntityLinks,
                        [STOP_ANONYMOUS_SUBJECTS_NEXUS_STATE_PROP]: stopAnonymousSubjects,
                        [RIPA_SUBJECT_OFFENSE_CODES_NEXUS_STATE_PROP]: ripaSubjectOffenseCodes,
                        [DOJ_SCHOOLS_NEXUS_STATE_PROP]: [dojSchool],
                    },
                    withRemoveMultiple(
                        {
                            [REPORT_ATTRIBUTES_NEXUS_STATE_PROP]: predicateReportAttributeObjectsToRemoveBy,
                            [NAME_REPORT_LINKS_NEXUS_STATE_PROP]: predicateNameReportLinksToRemoveBy,
                            [STOP_ENTITY_ATTRIBUTES_ATTRIBUTES_NEXUS_STATE_PROP]: { stopId },
                            [LOCATION_ENTITY_LINKS_NEXUS_STATE_PROP]: predicateLocationEntityLinksToRemoveBy,
                            [STOP_ANONYMOUS_SUBJECTS_NEXUS_STATE_PROP]: { stopId },
                            [RIPA_SUBJECT_OFFENSE_CODES_NEXUS_STATE_PROP]: { stopId },
                        },
                        {
                            type: 'UPSERT_STOP_CARD_BUNDLE',
                        }
                    )
                )
            );

            return updatedStopCardBundle;
        });
    };
}

import React from 'react';
import styled from 'styled-components';

import { ReportShortTitle } from '@mark43/rms-api';
import Icon, { iconTypes } from '../../../../../legacy-redux/components/core/Icon';
import Link from '../../../../core/components/links/Link';

const StyledIcon = styled(Icon)`
    display: inline-block;
    vertical-align: top;
    margin-left: 8px;
    margin-top: -6px;
`;

const ComplianceReportTitleCell: React.FC<{ reportShortTitle: ReportShortTitle }> = ({
    reportShortTitle,
}) => (
    <Link to={`/reports/${reportShortTitle.reportId}`} openInNewTab={true}>
        {reportShortTitle.reportingEventNumber} {reportShortTitle.shortTitle}
        <StyledIcon type={iconTypes.POPOUT} fontSize="20px" />
    </Link>
);

export default ComplianceReportTitleCell;

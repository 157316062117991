import { createResource } from '../../../../lib/resources/Resource';
import { req } from '../../../../lib/ajax';

export default createResource({
    name: 'Permissions Resource',
    methods: {
        /**
         * Get the permissions for the given entity or entities.
         * @param  {string}              entityType
         * @param  {number|number[]}     entityIds
         * @param  {boolean | undefined} overrideRoleRestrictions
         * @return {Promise<EntityPermissionView[]>} Set of EntityPermission models.
         */
        getPermissions(entityType, entityIds, overrideRoleRestrictions) {
            return req({
                method: 'GET',
                url: 'security/permissions/entity',
                params: {
                    entity_type: entityType,
                    entity_ids: entityIds,
                    override_role_restrictions: overrideRoleRestrictions,
                },
            });
        },

        /**
         * Update the permissions for the given entity, by REPLACING THEM.
         * @param  {string}   entityType
         * @param  {number}   entityId
         * @param  {EntityPermissionView[]} permissions
         * @return {Promise<EntityPermissionView[]>} Set of EntityPermission models.
         */
        updatePermissions(entityType, entityId, permissions) {
            return req({
                method: 'PUT',
                url: 'security/permissions/entity',
                params: {
                    entity_type: entityType,
                    entity_id: entityId,
                },
                data: permissions,
            });
        },
    },
});

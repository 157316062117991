import keyMirror from 'keymirror';

/**
 * Item side panel operation
 * @type {Object}
 */
export default keyMirror({
    CREATE: null,
    DUPLICATE: null,
    EDIT: null,
});

import React, { useCallback } from 'react';
import { EntityTypeEnumType } from '@mark43/rms-api';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import { useFormGetter } from '../../core/forms/hooks/useFormGetter';
import Modal from '../../core/overlays/components/Modal';
import {
    GENERIC_SUBMISSION_CREATION_FORM_NAME,
    convertFromFormModel,
} from '../state/forms/createGenericSubmissionsForm';
import submissionMessageResource from '../resources/submissionMessageResource';
import { useSubmissionMessages } from '../hooks';

import { CreateSubmissionsForm } from './CreateSubmissionsForm';

const overlayId = overlayIdEnum.CREATE_GENERIC_SUBMISSIONS_MODAL;
const strings = componentStrings.submissions.createSubmissionsModal;

type PropsT = {
    entityId: number;
    entityType: EntityTypeEnumType;
};

export const CreateSubmissionsModal: React.FC<PropsT> = ({ entityId, entityType }) => {
    const { getForm } = useFormGetter();
    const { actions } = useSubmissionMessages();

    const onSave = useCallback(async () => {
        const form = getForm(GENERIC_SUBMISSION_CREATION_FORM_NAME);

        if (!form) {
            return;
        }

        const result = await form.submit();
        if (result && result.validationResult) {
            if (result.validationResult.success) {
                const { form } = result;
                const formModel = form.get();
                const requestPayload = convertFromFormModel({ formModel, entityId, entityType });
                try {
                    const submissionMessageView = await submissionMessageResource.sendMessage(
                        requestPayload
                    );
                    actions.setSubmissionViews([submissionMessageView]);

                    return;
                } catch (err) {
                    throw err;
                }
            }
        }
        return;
    }, [getForm, entityId, entityType, actions]);

    return (
        <Modal
            id={overlayId}
            title={strings.title}
            okText={strings.saveText}
            contentStyle={{ minHeight: 300 }}
            autoClose={false}
            onSave={onSave}
        >
            <CreateSubmissionsForm entityId={entityId} entityType={entityType} />
        </Modal>
    );
};

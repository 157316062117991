import { SubmissionMessagetypeResource, EntityTypeEnumType } from '@mark43/rms-api';
import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';

export default createResource({
    name: 'Submission Message Type Resource',
    methods: {
        getMessageTypesForEntity(entityId: number, entityType: EntityTypeEnumType) {
            return req<SubmissionMessagetypeResource.GetMessageTypesForEntity>({
                method: 'GET',
                url: `submission_message/type/${entityType}/${entityId}/`,
            });
        },
    },
});

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AttributeTypeEnum, RefContextEnum } from '@mark43/rms-api';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { useFieldName } from '~/client-common/core/fields/hooks/useFields';
import { SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID } from '~/client-common/core/enums/universal/fields';
import { securityClassificationsByOwnerSelector } from '~/client-common/core/domain/security-classifications/state/data';

import formsRegistry from '../../../core/formsRegistry';
import Icon, { iconSizes, iconTypes } from '../../core/components/Icon';
import FormGroup from '../../core/forms/components/FormGroup';
import { ArbiterMFTAttributeSelect } from '../../core/forms/components/selects/AttributeSelect';
import { MFTNItems, RemoveButton } from '../../core/forms/components/NItems';
import { ArbiterMFTTextArea } from '../../core/forms/components/TextArea';
import NItemsItemSection from '../../core/forms/components/NItemsItemSection';
import ArbiterForm from '../../core/markformythree-arbiter/ArbiterForm';
import {
    convertToFormModel,
    editSecurityClassificationsCreateForm,
    SecurityClassificationFormDataShape,
} from '../state/forms/securityClassificationForm';
import { SecurityClassificationOwner } from '../types';

const strings = componentStrings.securityClassification.editSecurityClassificationModal;

type EditSecurityClassificationFormProps = SecurityClassificationOwner;

export const EditSecurityClassificationForm = ({
    ownerType,
    ownerId,
}: EditSecurityClassificationFormProps) => {
    const securityClassificationsByOwner = useSelector(securityClassificationsByOwnerSelector);
    const securityClassifications = securityClassificationsByOwner(ownerType, ownerId);

    const { singular: securityClassificationFieldName } = useFieldName(
        SECURITY_CLASSIFICATION_SECURITY_CLASSIFICATION_ATTR_ID
    );

    useEffect(() => {
        if (securityClassifications.length) {
            formsRegistry.maybeDeferredOperation(
                RefContextEnum.FORM_SECURITY_CLASSIFICATION_MODAL.name,
                undefined,
                (form) => {
                    const formModel = convertToFormModel(securityClassifications);
                    form.set('', formModel);
                }
            );
        }
    }, [securityClassifications]);

    return (
        <ArbiterForm
            {...editSecurityClassificationsCreateForm}
            render={() => (
                <FormGroup>
                    <>
                        <MFTNItems<SecurityClassificationFormDataShape>
                            path="securityClassifications"
                            addText={strings.form.addSecurityClassification(
                                securityClassificationFieldName
                            )}
                            maxItems={1}
                            addItemOnEmpty={false}
                            renderRemoveButton={undefined}
                            render={({ removeItem }) => (
                                <NItemsItemSection
                                    deleteButton={
                                        <RemoveButton
                                            onClick={removeItem}
                                            icon={
                                                <Icon
                                                    size={iconSizes.MEDIUM}
                                                    color="cobaltBlue"
                                                    type={iconTypes.TRASH_CAN}
                                                />
                                            }
                                        />
                                    }
                                >
                                    <ArbiterMFTAttributeSelect
                                        path="securityClassificationAttrId"
                                        attributeType={
                                            AttributeTypeEnum.SECURITY_CLASSIFICATION.name
                                        }
                                        length="md"
                                    />
                                    <ArbiterMFTTextArea rows={3} path="handlingInstructions" />
                                </NItemsItemSection>
                            )}
                        />
                    </>
                </FormGroup>
            )}
        />
    );
};

import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttributeTypeEnumType } from '@mark43/rms-api';
import { attributeLoadingStateByAttributeTypeSelector } from '../../../core/attributes/state/ui';
import { loadAttributesForType } from '../../../core/attributes/state/ui/loadAttributesForType';
import { computeLoadableAttributeTypes } from '../../../core/attributes/utils/computeLoadableAttributeTypes';

export const useLoadAttributes = (
    attributeType: AttributeTypeEnumType | AttributeTypeEnumType[]
) => {
    const dispatch = useDispatch();
    const attrLoadingStateByAttributeTypeSelector = useSelector(
        attributeLoadingStateByAttributeTypeSelector
    );

    useEffect(() => {
        // Load attrs if they haven't been loaded yet. Only do this when the component mounts.
        const attrLoadingState = attrLoadingStateByAttributeTypeSelector(attributeType);

        if (!isEmpty(computeLoadableAttributeTypes(attrLoadingState))) {
            dispatch(loadAttributesForType({ attributeType }));
        }
    }, [attributeType, attrLoadingStateByAttributeTypeSelector, dispatch]);
};

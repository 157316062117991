import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { ripaOffenseCodeOptionsSelector } from '~/client-common/core/domain/ripa-offense-codes/state/ui';
import { searchForRipaOffenseCodes } from '~/client-common/core/domain/ripa-offense-codes/state/data';
import { RmsDispatch } from '../../../../../core/typings/redux';
import { arbiterMFTInput } from '../../../arbiter';
import { SelectProps } from './Select';
import AsyncSelect from './AsyncSelect';

const RipaOffenseCodeSelect: React.FC<
    SelectProps<{
        value: string;
        display: string;
    }>
> = (props) => {
    const ripaOffenseCodeOptions = useSelector(ripaOffenseCodeOptionsSelector);

    const dispatch: RmsDispatch = useDispatch();
    const asyncAction = React.useCallback(
        (q: string) => {
            return dispatch(
                searchForRipaOffenseCodes({
                    q,
                    from: 0,
                    size: 100,
                })
            );
        },
        [dispatch]
    );

    return (
        <AsyncSelect
            asyncAction={asyncAction}
            sortOnRelevance={true}
            options={ripaOffenseCodeOptions}
            {...props}
        />
    );
};

export const ArbiterMFTRipaOffenseCodeSelect = arbiterMFTInput(RipaOffenseCodeSelect);

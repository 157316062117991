import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { simpleControl } from 'markformythree';
import { consortiumDepartmentLinksOptionsWhereSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { currentUserDepartmentIdSelector } from '../../../current-user/state/ui';

import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;

// we're filtering out departments that are the cad department. This is because
// consortiums that have CAD should never be searching for / looking for the cad department
// when in the RMS as the CAD dept is more of a "placeholder dept" than a dept
// that is holding any valuable RMS data, just CAD data. However if the current user
// is in the CAD department, unhelpful to filter it as we often default to current department
// in these dropdowns
// https://mark43.atlassian.net/browse/CAD-3532
const mapStateToProps = (state) => {
    const currentUserDepartmentId = currentUserDepartmentIdSelector(state);
    return {
        options: consortiumDepartmentLinksOptionsWhereSelector(state)((link) => {
            return link.deptId === currentUserDepartmentId || link.cadDeptId !== link.deptId;
        }),
    };
};

/**
 * Select for selecting consortium department(s).
 * @param {Object} props Same props as `Select`.

 */
const ConsortiumDepartmentSelect = compose(
    setDisplayName('ConsortiumDepartmentSelect'),
    connect(mapStateToProps)
)(Select);

export const RRFConsortiumDepartmentSelect = connectRRFInput(ConsortiumDepartmentSelect);
export const MFTConsortiumDepartmentSelect = simpleControl(ConsortiumDepartmentSelect);

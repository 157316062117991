let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Charge Resource',
        methods: {
            getChargeOffenseViewForOffenseId({ offenseId }) {
                return req({
                    method: 'GET',
                    url: `reporting/charges/offense/${offenseId}`,
                });
            },
            getChargeWarrantViewForWarrantId({ warrantId }) {
                return req({
                    method: 'GET',
                    url: `reporting/charges/warrant/${warrantId}`,
                });
            },
            getChargeEntityQuickAddViewForArrestId({ arrestId }) {
                return req({
                    method: 'GET',
                    url: `reporting/charges/quick_add/${arrestId}`,
                });
            },
            replaceChargesForArrestId({ arrestId, charges }) {
                return req({
                    method: 'PUT',
                    url: `reporting/charges/arrest/${arrestId}`,
                    data: charges,
                });
            },
            getOffenseCodeWordingTemplateBundleByChargeIds(chargeIds) {
                return req({
                    method: 'POST',
                    url: 'reporting/charges/offense_code_wording_bundle',
                    data: chargeIds,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import { chain, map, mapValues, omit } from 'lodash';

import { normalize, removeTransientKeys, TRANSIENT_KEY } from '~/client-common/helpers/dataHelpers';
import { entityPermissionFieldList } from '../configs/entityPermissionsConfig';
import { setFormValues } from './formReducerHelpers';

import { isExternalDepartment } from './permissionHelpers';

/**
 * Compute view models for the given permissions. Whether each permission is
 *   deletable depends only on its initial state and doesn't update when the
 *   user changes its fields.
 * @param  {Object} permissions
 * @return {Object} View models keyed by the transient key.
 */
export function buildEntityPermissionViewModels(permissions) {
    return mapValues(normalize(permissions, TRANSIENT_KEY), ({ roleId, operationType }) => ({
        initialRoleId: roleId,
        initialOperationType: operationType,
    }));
}

/**
 * @param  {Object[]} permissions
 * @return {Object}
 */
export const removeExternalDepartmentFlags = (permissions) =>
    map(permissions, (permission) => omit(permission, 'isExternalDepartment'));

/**
 * @param  {Object[]} permissions
 * @return {Object}
 */
export function convertEntityPermissionsDataStateToFormState(permissions) {
    const mapper = (permission) => setFormValues({}, permission, entityPermissionFieldList);
    return {
        permissions: chain(permissions).reject(isExternalDepartment).map(mapper).value(),
        externalDepartmentPermissions: chain(permissions)
            .filter(isExternalDepartment)
            .map(mapper)
            .value(),
    };
}

/**
 * @param  {string} entityType
 * @param  {number} entityId
 * @param  {Object} formData
 * @return {Object[]}
 */
export function convertEntityPermissionsFormDataToDataState(
    entityType,
    entityId,
    { permissions, externalDepartmentPermissions }
) {
    // fill the data models with required properties
    return map(
        [
            ...removeTransientKeys(permissions),
            ...removeTransientKeys(externalDepartmentPermissions),
        ],
        (permission) => ({
            ...permission,
            entityType,
            entityId,
        })
    );
}

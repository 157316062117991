import React, { createContext, useReducer, useMemo } from 'react';
import { EFileContextT, EFileReducerType } from '../types';
import { useEFileActions } from '../hooks/useEFileActions';
import { useEFileGetters } from '../hooks/useEFileGetters';
import { useEFileHandlers } from '../hooks/useEFileHandlers';
import { initialState, eFileReducer } from './state';

export const EFileContext = createContext<EFileContextT | undefined>(undefined);
const EFileStateContext = createContext<EFileReducerType | undefined>(undefined);

export const EFileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(eFileReducer, initialState);
    const eFileActions = useEFileActions(dispatch);
    const eFileGetters = useEFileGetters(state);
    const eFileHandlers = useEFileHandlers(state, eFileActions);

    const value = useMemo(() => {
        return {
            actions: eFileActions,
            getters: eFileGetters,
            handlers: eFileHandlers,
        };
    }, [eFileActions, eFileHandlers, eFileGetters]);
    return (
        <EFileStateContext.Provider value={state}>
            <EFileContext.Provider value={value}>{children}</EFileContext.Provider>
        </EFileStateContext.Provider>
    );
};

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { formatDispositionStatusByChainOfCustodyIdSelector } from '~/client-common/core/domain/item-evidence-states/state/ui';
import { BodyMediumText } from '../../../core/components/typography';

function DispositionStatus({
    chainOfCustodyId,
    formatDispositionStatusByChainOfCustodyId,
    className,
}) {
    return (
        <div className={className}>
            <BodyMediumText color="positive">
                {formatDispositionStatusByChainOfCustodyId(chainOfCustodyId)}
            </BodyMediumText>
        </div>
    );
}

/**
 * Display the current disposition status for a given chain of custody.
 * @param {number} chainOfCustodyId
 */
export default connect(
    createStructuredSelector({
        formatDispositionStatusByChainOfCustodyId: formatDispositionStatusByChainOfCustodyIdSelector,
    })
)(DispositionStatus);

import React from 'react';

import GenericContainer from './layout/GenericContainer';

const ExternalDepartmentName = ({ departmentName, theme }) =>
    !departmentName ? null : (
        <GenericContainer color={theme.colors.mediumLightGrey}>({departmentName})</GenericContainer>
    );

export default ExternalDepartmentName;

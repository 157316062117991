import { SideNav, SideNavItem } from '@arc/side-nav';
import React from 'react';
import { InjectedRouter, withRouter } from 'react-router';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import { BaseLinkWithAnalytics } from '../../../core/components/links/Link';

const strings = componentStrings.eFiles.core.sidebar;

const EFileSidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 180px;
    padding: var(--arc-space-4);
    background-color: var(--arc-colors-surface-background);
    border-right: 1px solid var(--arc-colors-border-default);
`;

type EFileSidebarT = {
    eFileId: number;
    router: InjectedRouter;
};

export const EFileSidebar = withRouter(({ eFileId, router }: EFileSidebarT) => {
    return (
        <EFileSidebarWrapper data-test-id={testIds.E_FILE_SIDEBAR}>
            <SideNav>
                <SideNavItem
                    as={BaseLinkWithAnalytics}
                    to={`/e-file/${eFileId}/summary`}
                    isActive={router.isActive(`/e-file/${eFileId}/summary`)}
                    label={strings.summaryLink}
                    testId={testIds.E_FILE_SIDEBAR_SUMMARY}
                />
                <SideNavItem
                    as={BaseLinkWithAnalytics}
                    to={`/e-file/${eFileId}/defendants`}
                    isActive={router.isActive(`/e-file/${eFileId}/defendants`)}
                    label={strings.defendantsLink}
                    testId={testIds.E_FILE_SIDEBAR_DEFENDANTS}
                />
                <SideNavItem
                    as={BaseLinkWithAnalytics}
                    to={`/e-file/${eFileId}/material`}
                    isActive={router.isActive(`/e-file/${eFileId}/material`)}
                    label={strings.materialLink}
                    testId={testIds.E_FILE_SIDEBAR_MATERIAL}
                />
                <SideNavItem
                    as={BaseLinkWithAnalytics}
                    to={`/e-file/${eFileId}/tasks`}
                    isActive={router.isActive(`/e-file/${eFileId}/tasks`)}
                    label={strings.tasksLink}
                    testId={testIds.E_FILE_SIDEBAR_TASKS}
                />
            </SideNav>
        </EFileSidebarWrapper>
    );
});

import { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';
import Modal from '../../../../legacy-redux/components/core/Modal';

import { createModalSelector } from '../state/ui';

const context = {
    name: boxEnum.ERROR_MODAL,
};

/**
 * Error modal that displays optional paragraphs followed by an optional list of
 *   error messages. The content of this modal is not fully customizable.
 * @param {string[]} [props.paragraphs]
 * @param {string[]} [props.list]
 * @param {string}   [props.title='Error']
 */
export default connect(
    createStructuredSelector({
        title: createModalSelector(context, 'title'),
        paragraphs: createModalSelector(context, 'paragraphs'),
        list: createModalSelector(context, 'list'),
    })
)(function ErrorModal({ title = 'Error', paragraphs = [], list = [], ...otherProps }) {
    return (
        <Modal
            context={context}
            title={title}
            cancelText={null}
            errorHeader={true}
            contentStyle={{ fontSize: 12 }}
            {...otherProps}
        >
            {map(paragraphs, (paragraph, i) => (
                <p key={i} className="error-summary">
                    {paragraph}
                </p>
            ))}
            {list.length > 0 && (
                <ul>
                    {map(list, (item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            )}
        </Modal>
    );
});

import React from 'react';
import { CaseApprovalStatusIcon } from '../../../records/core/components/ApprovalStatusIcon';

export default function CaseApprovalStatusCell(elasticCase) {
    const { approvalStatus } = elasticCase;
    return (
        <div className="case-approval-status-cell">
            <CaseApprovalStatusIcon approvalStatus={approvalStatus} />
        </div>
    );
}

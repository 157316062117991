import { createSelector } from 'reselect';
import {
    EntityTypeEnumType,
    FolderContentView,
    FolderContentUploadRequest,
    CaseNote,
    Attachment,
    Printable,
} from '@mark43/rms-api';
import { includes, chain, values, compact, first } from 'lodash';
import {
    folderContentViewsSelector,
    folderContentViewWhereSelector,
    NEXUS_STATE_PROP as FOLDER_CONTENT_VIEW_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/folder-content-views/state/data';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { DISPLAY_ONLY_CASE } from '~/client-common/core/enums/universal/fields';
import { mapGivenPacketsToPrintables } from '~/client-common/helpers/exportHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { caseAttachmentsByCaseIdAndUserIdSelector } from '~/client-common/core/domain/case-attachments/state/ui';
import buildPrintablesResource from '~/client-common/core/domain/printables/resources/printablesResource';
import folderContentsResource from '../../resources/folderContentsResource';
import { RmsAction } from '../../../../../core/typings/redux';
import { exportPDF } from '../../../../../legacy-redux/actions/exportsActions';
import { currentCaseSelector, currentCaseNotesByFolderIdSelector, expandFolders } from '../ui';
import { currentUserIdSelector } from '../../../../core/current-user/state/ui';
import { updateOrphanContents } from './orphanedContents';

export function getFolderContents(
    folderId: number,
    entityType: EntityTypeEnumType
): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        return folderContentsResource
            .getFolderContents(folderId)
            .then((folderContentView) => {
                dispatch(
                    expandFolders(
                        entityType,
                        folderContentView.folderBreadcrumbs
                            .filter((x) => x.folderId !== folderId)
                            .map((x) => x.folderId)
                    )
                );

                dispatch(
                    nexus.withRemove(
                        FOLDER_CONTENT_VIEW_NEXUS_STATE_PROP,
                        { folderId: folderContentView.folderId },
                        nexus.withEntityItems(
                            {
                                [FOLDER_CONTENT_VIEW_NEXUS_STATE_PROP]: [
                                    {
                                        ...folderContentView,
                                    },
                                ],
                            },
                            { type: 'STORE_FOLDER_CONTENT_VIEW' }
                        )
                    )
                );
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function createFolderContents({
    folderContent,
    sourceFolderId,
}: {
    folderContent: FolderContentUploadRequest;
    sourceFolderId?: number; // the content may be orphaned, not belonging to any folder
}): RmsAction<Promise<void>> {
    return (dispatch, getState) => {
        const state = getState();
        const currentCase = currentCaseSelector(state);

        return folderContentsResource
            .createFolderContents(folderContent)
            .then(() => {
                if (sourceFolderId && currentCase) {
                    dispatch(getFolderContents(sourceFolderId, folderContent.entityTypeId));
                } else {
                    dispatch(updateOrphanContents());
                }
            })
            .catch((err) => {
                throw err;
            });
    };
}

// TODO: We haven't have an api to move folderContents from a source folder to a destination folder yet.
// That api will be done in https://mark43.atlassian.net/browse/CHI-528
// In the mean time, this action will use 2 apis to move folderContents, the 1st api is to remove
// folderContents from source folder, and the 2nd api is to add folderContents to a destination folder

export function moveFolderContents({
    contentIds,
    entityType,
    destinationFolderId,
    sourceFolderId,
}: {
    contentIds: number[];
    entityType: EntityTypeEnumType;
    destinationFolderId: number;
    sourceFolderId: number;
}): RmsAction<Promise<void>> {
    return (dispatch) => {
        return folderContentsResource
            .removeFolderContents({ contentIds, folderId: sourceFolderId })
            .then(() => {
                return dispatch(
                    createFolderContents({
                        folderContent: {
                            contentIds,
                            entityTypeId: entityType,
                            folderId: destinationFolderId,
                        },
                        sourceFolderId,
                    })
                );
            });
    };
}

export function downloadContent(
    currentFolderId: number | undefined,
    selectedContent: {
        entityType: EntityTypeEnumType;
        isEditable: boolean;
        isFolder: boolean;
        rowId: number;
    }[]
): RmsAction<void> {
    return async (dispatch, getState) => {
        const isDownloadAll = !selectedContent?.length;
        const currentCase = currentCaseSelector(getState());
        const formatFieldDisplayName = formatFieldByNameSelector(getState());
        const currentCaseNotes: CaseNote[] = currentCaseNotesByFolderIdSelector(
            getState() as never
        )(currentFolderId);

        const resource = buildPrintablesResource();
        const allPrintables: Printable[] = await resource.getCasePrintables(currentCase.id, {
            includeNestedFolders: true,
        });
        const strings = componentStrings.cases;

        const exportType = includes(window.location.href, 'attachments')
            ? 'Attachments'
            : 'Case Note';
        currentFolderId = includes(window.location.href, '/folder') ? currentFolderId : undefined;

        const parentPrintableId = currentFolderId ?? currentCase.id;
        const parentPrintableDataType = currentFolderId ? 'PARENT_FOLDER' : 'CASE';
        const parentPrintable = allPrintables.filter(
            (x) => x.dataType === parentPrintableDataType && x.entityId === parentPrintableId
        )[0];

        let folderIds: number[];
        let attachmentIds: number[];
        let caseNoteIds: number[];

        if (!currentFolderId && isDownloadAll) {
            const currentUserId = currentUserIdSelector(getState());
            const attachments: Attachment[] = caseAttachmentsByCaseIdAndUserIdSelector(getState())(
                currentCase.id,
                currentUserId
            );
            folderIds = allPrintables
                .filter(
                    (x) =>
                        x.dataType !== parentPrintableDataType &&
                        x.groupTitle === exportType &&
                        x.entityId
                )
                .map((x) => x.entityId) as number[];
            attachmentIds =
                exportType === 'Attachments' ? attachments.map((x) => x.attachmentId) : [];
            caseNoteIds = currentCaseNotes.map((x) => x.id);
        } else {
            folderIds = selectedContent.filter((x) => x.isFolder).map((x) => x.rowId);
            const nonFolderSelectedIds = selectedContent
                .filter((x) => !x.isFolder)
                .map((x) => x.rowId);
            attachmentIds = exportType === 'Attachments' ? nonFolderSelectedIds : [];
            caseNoteIds = exportType === 'Case Note' ? nonFolderSelectedIds : [];
        }

        const selectedPrintables = allPrintables.filter((x) => {
            return (
                (x.dataType === 'PARENT_FOLDER' && x.entityId && folderIds.includes(x.entityId)) ||
                (exportType === 'Case Note' &&
                    x.dataType === 'CASE_NOTE' &&
                    x.entityId &&
                    caseNoteIds.includes(x.entityId))
            );
        });

        const caseFieldName = formatFieldDisplayName(DISPLAY_ONLY_CASE);
        const downloadZipFileTitle =
            exportType === 'Attachments'
                ? strings.caseAttachments.CaseAttachments.downloadZipFileTitle(
                      currentCase.localId,
                      caseFieldName
                  )
                : strings.caseNotes.CaseNotes.downloadZipFileTitle(
                      currentCase.localId,
                      caseFieldName
                  );

        const packets = mapGivenPacketsToPrintables(
            [parentPrintable, ...selectedPrintables],
            currentFolderId ? undefined : downloadZipFileTitle,
            undefined
        );

        // when exporting a subset of a folder, we have to attach the selected items to the parent printable
        // but we don't want the BE to grab the whole parent printable so we switch it to FOLDER
        if (currentFolderId && !isDownloadAll) {
            packets[0].dataType = 'FOLDER';
        }

        // need to key attachments by the folder it's in - if it's an orphan we need to key by the case id
        const formData = {
            packets: [],
            includeFiles: true,
            selectedAttachmentsToInclude: { [parentPrintableId]: attachmentIds },
            includeHistoryEvents: false,
            onlyIncludeFieldsWithData: true,
            includeWarrantActivities: false,
            includeConfidentialInformation: false,
            includeNameAddendums: false,
            redactFields: false,
            mergeAttachments: false,
        };

        return dispatch(exportPDF(formData, packets, null, undefined));
    };
}

const getFolderContentViewBySubFolderId = (
    folderContentViews: FolderContentView[],
    subFolderId: number
) => {
    const folderContentView = folderContentViews.filter((item) => {
        return item.directSubFolders?.filter((item) => item.id === subFolderId).length === 1;
    })[0];

    return folderContentView;
};

export const removeSubFolderByIdsFromFolderContentView = (
    folderContentView: FolderContentView,
    subFolderIds: number[]
) => {
    return folderContentView.directSubFolders.filter((item) => !includes(subFolderIds, item.id));
};

export const retrieveFolderContentViewsBySubFolderIdSelector = createSelector(
    folderContentViewsSelector,
    (folderContentViews) => (subFolderIds: number[]) => {
        return chain(subFolderIds)
            .map((folderId) => {
                return getFolderContentViewBySubFolderId(values(folderContentViews), folderId);
            })
            .compact()
            .uniqBy('folderId')
            .compact()
            .value();
    }
);

export function updateCaseNoteByIdInFolderContentView({
    caseNote,
    folderId,
}: {
    caseNote: CaseNote;
    folderId: number;
}): RmsAction<Promise<void>> {
    return (dispatch, getState, { nexus }) => {
        const folderContentView = first(folderContentViewWhereSelector(getState())({ folderId }));

        const newCaseNotes = folderContentView
            ? [
                  ...compact(
                      folderContentView.hydratedFolderContent?.caseNotes.filter(
                          (item) => item.id !== caseNote.id
                      )
                  ),
                  caseNote,
              ]
            : [];

        const folderContentViewToUpdate = {
            ...folderContentView,
            hydratedFolderContent: {
                ...folderContentView?.hydratedFolderContent,
                caseNotes: newCaseNotes,
            },
        };

        return dispatch(
            nexus.withEntityItems(
                {
                    [FOLDER_CONTENT_VIEW_NEXUS_STATE_PROP]: [
                        {
                            ...folderContentViewToUpdate,
                        },
                    ],
                },
                { type: 'UPDATE_CASE_NOTE_BY_ID_IN_FOLDER_CONTENT_VIEW_SUCCESS' }
            )
        );
    };
}

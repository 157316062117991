import { ComplianceResubmissionStatus, ResubmissionStatusEnum } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import createNormalizedModule from '~/client-common/core/utils/createNormalizedModule';
import { ClientCommonAction } from '~/client-common/redux/types';
import complianceResubmissionsResource from '../../resources/complianceResubmissionsResource';

const NEXUS_STATE_PROP = 'complianceResubmissions';

const complianceResubmissionModule = createNormalizedModule<ComplianceResubmissionStatus>({
    type: NEXUS_STATE_PROP,
    key: 'reportId',
});

const complianceResubmissionsSelector = complianceResubmissionModule.selectors.entitiesSelector;
export const complianceResubmissionsByReportIdSelector = createSelector(
    complianceResubmissionsSelector,
    (complianceResubmissions) => (reportId: number) => {
        if (!complianceResubmissions) {
            return null;
        }
        return complianceResubmissions[reportId];
    }
);

// ACTIONS
const storeComplianceResubmissions = complianceResubmissionModule.actionCreators.storeEntities;
const replaceComplianceResubmissions =
    complianceResubmissionModule.actionCreators.replaceEntitiesWhere;

export function loadComplianceResubmissions(reportId: number): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        return complianceResubmissionsResource
            .getQueuedResubmissions(reportId)
            .then((queuedResubmissions: ComplianceResubmissionStatus[]) => {
                dispatch(storeComplianceResubmissions(queuedResubmissions));
            })
            .catch((err: Error) => {
                throw err;
            });
    };
}

export function queueComplianceResubmission(
    reportId: number,
    agencyId: number,
    departmentId: number
): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        const resubmission = {
            agencyId,
            departmentId,
            reportId,
            resubmissionStatus: ResubmissionStatusEnum.QUEUED.name,
        };
        return complianceResubmissionsResource
            .putResubmissionToQueue([resubmission])
            .then((queuedResubmissions: ComplianceResubmissionStatus[]) => {
                dispatch(
                    replaceComplianceResubmissions(
                        { reportId: resubmission.reportId },
                        queuedResubmissions
                    )
                );
            })
            .catch((err: Error) => {
                throw err;
            });
    };
}

export function removeComplianceResubmission(
    reportId: number,
    resubmissionId: number
): ClientCommonAction<Promise<void>> {
    return (dispatch) => {
        return complianceResubmissionsResource
            .removeResubmissionFromQueue(resubmissionId)
            .then((result: boolean) => {
                if (result) {
                    dispatch(replaceComplianceResubmissions({ reportId }, []));
                }
            })
            .catch((err: Error) => {
                throw err;
            });
    };
}

export default complianceResubmissionModule.reducerConfig;

import { PersonInjury } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { filter } from 'lodash';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'personInjuries';
const personInjuriesModule = createNormalizedModule<PersonInjury>({
    type: NEXUS_STATE_PROP,
});

// SELECTORS
const personInjuriesSelector = personInjuriesModule.selectors.entitiesSelector;
export const personInjuriesByPersonProfileIdSelector = createSelector(
    personInjuriesSelector,
    (personInjuries) => (personProfileId: number) => filter(personInjuries, { personProfileId })
);

// REDUCER
export default personInjuriesModule.reducerConfig;

import { createSelector } from 'reselect';
import { first, get, map, sortBy } from 'lodash';
import { ItemInvolvementTypeEnum } from '@mark43/rms-api';

// constants
import componentStrings from '../../../../strings/componentStrings';

// helpers
import {
    formatOffenseCode,
    formatNibrsCode,
    formatUcrCode,
} from '../../../../../helpers/offenseCodesHelpers';
import {
    boolToCustomTrueFalseDisplay,
    buildViewModel,
    allSingleAttributeValuesMapper,
} from '../../../../../helpers/viewModelHelpers';
import { dateTimeRangeFormatter } from '../../../../dates/utils/dateHelpers';

// selectors
import { formatAttributeByIdSelector } from '../../../attributes/state/data';
import { offenseCodesSelector } from '../../../offense-codes/state/data';
import { offenseCaseStatusByOffenseIdSelector } from '../../../offense-case-statuses/state/data';
import { offensesWhereSelector } from '../data';
import { ucrSummaryOffenseCodesSelector } from '../../../ucr-summary-offense-codes/state/data';
import { formatOffenseCodeByIdSelector } from '../../../offense-codes/state/ui';
import { offenseInvolvedChildrenWhereSelector } from '../../../offense-involved-children/state/data';
import { formatFieldByNameSelector } from '../../../../fields/state/config';
import {
    DISPLAY_ONLY_OFFENSE,
    OFFENSE_INVOLVED_CHILD_CHILD_AGE,
} from '../../../../enums/universal/fields';
import {
    currentDepartmentDateFormatterSelector,
    currentUserDepartmentProfileSelector,
} from '../../../current-user/state/ui';

const strings = componentStrings.reports.core.OffenseCard;

const buildIncidentViewModelSelector = createSelector(offenseCodesSelector, (offenseCodes) => {
    const viewModel = buildViewModel({
        mappers: [
            ({ offenseCodeId }) => {
                const offenseCodeModel = offenseCodes[offenseCodeId];
                return {
                    offenseCode: formatOffenseCode(offenseCodeModel),
                };
            },
        ],
    });

    return viewModel;
});

const buildOffenseViewModelSelector = createSelector(
    formatAttributeByIdSelector,
    offenseCodesSelector,
    ucrSummaryOffenseCodesSelector,
    formatOffenseCodeByIdSelector,
    offenseInvolvedChildrenWhereSelector,
    offenseCaseStatusByOffenseIdSelector,
    formatFieldByNameSelector,
    currentDepartmentDateFormatterSelector,
    currentUserDepartmentProfileSelector,
    (
        formatAttributeById,
        offenseCodes,
        ucrSummaryOffenseCodes,
        formatOffenseCodeById,
        offenseInvolvedChildrenWhere,
        offenseCaseStatusByOffenseId,
        formatFieldByName,
        dateTimeFormatter,
        currentUserDepartmentProfile
    ) => {
        const offenseDisplayName = formatFieldByName(DISPLAY_ONLY_OFFENSE);
        /**
         * @typedef {import('@mark43/rms-api').Offense} Offense
         * @type {ReturnType<typeof buildViewModel<Offense, {
         *      schoolWeaponInvolved?: boolean,
         *      secondaryLocationCategoryAttrId?: string
         * } >>}
         */
        const viewModel = buildViewModel({
            mappers: [
                allSingleAttributeValuesMapper,
                ({ offenseCodeId, nibrsCode }) => {
                    const offenseCodeModel = offenseCodes[offenseCodeId];
                    const offenseCode = formatOffenseCode(
                        offenseCodeModel,
                        currentUserDepartmentProfile
                    );

                    const nibrsOffenseCode = formatNibrsCode(nibrsCode);

                    const offenseUcrCode =
                        ucrSummaryOffenseCodes[get(offenseCodeModel, 'ucrSummaryCodeCode')];
                    const ucrSummaryOffenseCode = formatUcrCode(offenseUcrCode);
                    const offenseCodeWithCode = formatOffenseCodeById({
                        id: offenseCodeId,
                        includeCode: true,
                    });

                    return {
                        offenseCode,
                        offenseCodeModel,
                        nibrsOffenseCode,
                        ucrSummaryOffenseCode,
                        offenseCodeWithCode,
                        // TODO-ARREST-V3-INCIDENT-TYPE for now we treat "no code" as
                        // an incident, but eventually that should be an error
                        isIncident:
                            !offenseCode || get(offenseCodes[offenseCodeId], 'isIncidentType'),
                    };
                },
                ({ id }) => {
                    const offenseInvolvedChildren = sortBy(
                        offenseInvolvedChildrenWhere({ offenseId: id }),
                        ['childName']
                    );
                    const formattedChildren = map(
                        offenseInvolvedChildren,
                        (child) =>
                            `${child.childName || ''}${
                                child.childAge
                                    ? `, ${formatFieldByName(OFFENSE_INVOLVED_CHILD_CHILD_AGE)}: ${
                                          child.childAge
                                      }`
                                    : ''
                            }`
                    );
                    return {
                        offenseInvolvedChildren: formattedChildren,
                    };
                },
                ({ id }) => ({
                    offenseCaseStatus: offenseCaseStatusByOffenseId[id],
                }),
                ({ isOffenseDateUnknown, offenseDateUtc, offenseEndDateUtc }) => {
                    const formattedDate =
                        dateTimeRangeFormatter(
                            offenseDateUtc,
                            offenseEndDateUtc,
                            dateTimeFormatter
                        ) || '';
                    const formattedOffenseDateUnknown = isOffenseDateUnknown
                        ? ` ${strings.offenseDateUnknown(offenseDisplayName)}`
                        : '';
                    return {
                        offenseDate: `${formattedDate}${formattedOffenseDateUnknown}`,
                    };
                },
                ({ wasCompleted }) =>
                    boolToCustomTrueFalseDisplay({
                        propName: 'wasCompleted',
                        propVal: wasCompleted,
                        trueDisplay: strings.wasCompleted.trueDisplay,
                        falseDisplay: strings.wasCompleted.falseDisplay,
                    }),
                ({ itemInvolvementType }) => {
                    if (typeof itemInvolvementType !== 'undefined') {
                        return enumToStringsDisplay({
                            propName: 'itemInvolvementType',
                            propVal: itemInvolvementType,
                            typeEnum: ItemInvolvementTypeEnum,
                            strings: componentStrings.forms.select.ItemInvolvementSelect.options,
                        });
                    }
                },
                ({ arsonBuildingInhabited }) =>
                    boolToCustomTrueFalseDisplay({
                        propName: 'arsonBuildingInhabited',
                        propVal: arsonBuildingInhabited,
                        trueDisplay: strings.arsonBuildingInhabited.trueDisplay,
                        falseDisplay: strings.arsonBuildingInhabited.falseDisplay,
                    }),
            ],
            helpers: {
                formatAttributeById,
            },
        });
        return viewModel;
    }
);

const enumToStringsDisplay = ({ propName, propVal, typeEnum, strings }) => {
    // strings object must have a string value for each possible enum value
    return { [propName]: strings[typeEnum[propVal]?.name] };
};

const incidentViewModelsWhereSelector = createSelector(
    offensesWhereSelector,
    buildIncidentViewModelSelector,
    (offensesWhere, buildIncidentViewModel) => (predicate) =>
        map(offensesWhere(predicate), buildIncidentViewModel)
);

const offenseViewModelsWhereSelector = createSelector(
    offensesWhereSelector,
    buildOffenseViewModelSelector,
    (offensesWhere, buildOffenseViewModel) => (predicate) =>
        map(offensesWhere(predicate), buildOffenseViewModel)
);

export const offenseViewModelsForReportIdSelector = createSelector(
    offenseViewModelsWhereSelector,
    (offenseViewModelsWhere) => (reportId) => offenseViewModelsWhere({ reportId })
);

export const incidentViewModelByIdSelector = createSelector(
    incidentViewModelsWhereSelector,
    // return empty object if the offense unexpectedly does not exist, for safety
    (incidentViewModelsWhere) => (id) => first(incidentViewModelsWhere({ id })) || {}
);

export const offenseViewModelByIdSelector = createSelector(
    offenseViewModelsWhereSelector,
    // return empty object if the offense unexpectedly does not exist, for safety
    (offenseViewModelsWhere) => (id) =>
    first(offenseViewModelsWhere({ id })) ||
    /**
     * @type ReturnType<typeof offenseViewModelsWhere>[number]
     */
    ({})
);

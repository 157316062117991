import { WarrantActivityStatusLink } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';

export const NEXUS_STATE_PROP = 'warrantActivityStatusLinks';

const warrantActivityStatusLinksModule = createNormalizedModule<WarrantActivityStatusLink>({
    type: NEXUS_STATE_PROP,
    key: 'activityTypeAttrId',
});

// ACTIONS
export const storeWarrantActivityStatusLinks =
    warrantActivityStatusLinksModule.actionCreators.storeEntities;

export const deleteWarrantActivityStatusLinksWhere =
    warrantActivityStatusLinksModule.actionCreators.deleteEntitiesWhere;

// SELECTORS
export const warrantActivityStatusLinksSelector =
    warrantActivityStatusLinksModule.selectors.entitiesSelector;
export const warrantActivityStatusLinksWhereSelector =
    warrantActivityStatusLinksModule.selectors.entitiesWhereSelector;

// REDUCER
export default warrantActivityStatusLinksModule.reducerConfig;

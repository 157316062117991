import React from 'react';
import { map } from 'lodash';

import getDisplayValueForValue from '../../utils/getDisplayValueForValue';
import MergeEntitiesText from '../core/MergeEntitiesText';

const GenericGroupedCell = ({ model, fieldDisplayNames, fieldConfig, dependencies }) => {
    return (
        <div>
            {map(fieldConfig, (config) => (
                <div key={config.prop}>
                    <MergeEntitiesText display="inline-block" color="mediumGrey" width="auto">
                        {fieldDisplayNames[config.field]}:
                    </MergeEntitiesText>{' '}
                    <MergeEntitiesText display="inline-block" width="auto">
                        {getDisplayValueForValue(model, config, dependencies)}
                    </MergeEntitiesText>
                </div>
            ))}
        </div>
    );
};

export default GenericGroupedCell;

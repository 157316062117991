import { find, filter, forEach, kebabCase, snakeCase, sortBy } from 'lodash';
import { ComponentEnum, UiTypeEnum } from '@mark43/rms-api';
import { FormValue, combineUiConfigurationArrays } from 'dragon-react';
import { ClientFormView } from '../../rms-types';
import type { ReportSidebarEntry } from '../../../reports/core/types';

export function createDragonFormAnchor(
    displayName: string,
    instanceId: number | string | undefined
): string {
    return kebabCase(`${displayName}-${instanceId}`);
}

function formValueToSidebarEntry(
    formFetchApiResponse: ClientFormView,
    formValue: FormValue
): ReportSidebarEntry {
    if (typeof formValue.formConfigurationId === 'number') {
        const formConfigurationId = formValue.formConfigurationId;
        const formConfiguration = formFetchApiResponse.forms[formConfigurationId];
        if (formConfiguration === undefined) {
            throw new Error(`No FormConfiguration found with id ${formConfigurationId}`);
        }
        return {
            cardId: formConfigurationId,
            display: formConfiguration.displayName,
            anchor: createDragonFormAnchor(formConfiguration.displayName, formValue.instanceId),
            cardName: snakeCase(formConfiguration.displayName).toUpperCase(),
        };
    } else {
        throw new Error('formValue must include a formConfigurationId');
    }
}

function notNullish<TValue>(
    value: TValue | null | undefined
): value is Exclude<TValue, null | undefined> {
    return value !== null && value !== undefined;
}

export function mapDragonFormsForReportSidebar(
    formFetchResponse: ClientFormView
): ReportSidebarEntry[] {
    const forms = Object.values(formFetchResponse.forms);
    // really not sure why I have to check for the form not being defined here
    const reportForm = find(
        forms,
        (form) => form !== undefined && form.component === ComponentEnum.ROOT.name
    );
    if (typeof reportForm != null && typeof reportForm === 'object') {
        // This algorithm looks up "CARD forms" through the "ROOT form" root. A simpler algorithm
        // which just filters for forms of type CARD could work as well. Said algorithm wouldn't handle
        // certain edge cases, like forms coming down which don't actually end up rendering as cards.
        const cardForms = sortBy(
            combineUiConfigurationArrays(reportForm)
                .map((childConfiguration) => {
                    if (childConfiguration.uiType === UiTypeEnum.FORM_REFERENCE.name) {
                        return {
                            ordinal: childConfiguration.ui.ordinal,
                            typeId: childConfiguration.formConfigurationId,
                        };
                    } else {
                        // some forms are irrelevant, and for those we return null which will be filtered out later
                        return null;
                    }
                })
                .filter(notNullish),
            'ordinal'
        );
        const sidebarEntries: ReportSidebarEntry[] = [];
        forEach(cardForms, (cardForm) => {
            const matchedFormValues = sortBy(
                filter(
                    formFetchResponse.formValues,
                    (formValue) => formValue.formConfigurationId === cardForm.typeId
                ),
                // AFAIK `instanceId` is the closest thing we have to a proxy for "ordinal" of a form value at the moment
                'instanceId'
            );
            sidebarEntries.push(
                ...matchedFormValues.map((formValue) =>
                    formValueToSidebarEntry(formFetchResponse, formValue)
                )
            );
        });
        return sidebarEntries;
    } else {
        throw new Error('FormFetchResponse did not include a root form of type `ROOT`.');
    }
}

import React, { useCallback } from 'react';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Modal, { OverrideModalPropsFunction } from '../../../core/overlays/components/Modal';
import { useOverlayStore } from '../../../core/overlays/hooks/useOverlayStore';

const strings = componentStrings.tasks.core.TaskErrorModal;

type TaskErrorModalProps = {
    titleString: string;
    message: string;
};

export const useOpenTaskErrorModal = () => {
    const overlayStore = useOverlayStore<TaskErrorModalProps>();
    return useCallback(
        (customProperties: TaskErrorModalProps) => {
            overlayStore.open(overlayIdEnum.TASK_ERROR_OVERLAY, customProperties);
        },
        [overlayStore]
    );
};

const TaskErrorModal: React.FC = () => {
    const overrideModalProps: OverrideModalPropsFunction<TaskErrorModalProps> = (overlayProps) => {
        const {
            overlayState: {
                customProperties: { titleString, message },
            },
        } = overlayProps;

        const title = titleString || strings.title;
        const children = message || strings.error;

        return {
            children,
            title,
        };
    };

    return (
        <Modal
            cancelText={null}
            id={overlayIdEnum.TASK_ERROR_OVERLAY}
            okText={strings.confirmButton}
            overrideModalProps={overrideModalProps}
        />
    );
};

export default TaskErrorModal;

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, defaultProps, setPropTypes } from 'recompose';
import keyMirror from 'keymirror';

import { ucrSummaryOffenseCodeOptionsSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import { arbiterMFTInput } from '../../../arbiter';
import MultiFieldSelect from './MultiFieldSelect';
import Select from './Select';

const { connectRRFMultiFieldInput, connectRRFInput } = reactReduxFormHelpers;
const strings = componentStrings.forms.select.UcrCodeSelect;

/**
 * Select dropdown containing all UCR summary offense codes as well as "No UCR
 *   Code" and "Any UCR Code" options (modeled by `hasUcrCode = false/true`).
 * @param {Object} props Same props as `Select`.
 */
const UcrCodeSelect = connect(
    createStructuredSelector({
        ucrCodeOptions: ucrSummaryOffenseCodeOptionsSelector,
    })
)(function UcrCodeSelect({
    fields: { hasUcrCode, ucrCodes },
    ucrCodeOptions,
    label,
    ...otherProps
}) {
    return (
        <MultiFieldSelect
            label={label || strings.label}
            {...otherProps}
            fields={{
                hasUcrCode: {
                    options: [
                        {
                            value: false,
                            display: strings.options.hasUcrCode.false,
                            none: true,
                        },
                        {
                            value: true,
                            display: strings.options.hasUcrCode.true,
                            none: true,
                        },
                    ],
                    ...hasUcrCode,
                },
                ucrCodes: {
                    options: ucrCodeOptions,
                    ...ucrCodes,
                },
            }}
        />
    );
});

const SingleUcrCodeSelect = connect(
    createStructuredSelector({
        ucrCodeOptions: ucrSummaryOffenseCodeOptionsSelector,
    })
)(function UcrCodeSelect({ ucrCodeOptions, ...otherProps }) {
    return <Select options={ucrCodeOptions} {...otherProps} />;
});

export const RRFUcrCodeSelect = compose(
    setPropTypes({
        paths: PropTypes.object,
    }),
    defaultProps({
        paths: keyMirror({
            hasUcrCode: null,
            ucrCodes: null,
        }),
    }),
    connectRRFMultiFieldInput
)(UcrCodeSelect);

export const RRFSingleUcrCodeSelect = connectRRFInput(SingleUcrCodeSelect);
export const ArbiterMFTSingleUcrCodeSelect = arbiterMFTInput(SingleUcrCodeSelect);

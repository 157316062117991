import { map } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import * as fields from '~/client-common/core/enums/universal/fields';

import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import sortCharges from '~/client-common/core/domain/charges/utils/sortCharges';

import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import SealedStamp from '../../../../core/components/SealedStamp';
import SemiBoldSummaryTitle from '../../../../core/components/SemiBoldSummaryTitle';
import testIds from '../../../../../core/testIds';
import ChargeTitle from './ChargeTitle';

const strings = componentStrings.reports.core.BookingCard;

const SummaryText = styled.span`
    font-size: var(--arc-fontSizes-md);
    color: ${(props) => props.theme.colors.darkGrey};
`;

const ChargeTitleWrapper = styled.div`
    margin-bottom: 20px;
`;

const SealedChargeWrapper = styled.div`
    display: flex;
    margin-right: 20px;
`;
const SealedStampStyled = styled(SealedStamp)`
    margin-left: auto;
`;

const BookingCardSummary = connect(
    createStructuredSelector({
        formatAttributeById: formatAttributeByIdSelector,
    })
)(function _BookingCardSummary({ formatAttributeById, arrest, charges }) {
    const orderedCharges = sortCharges({ charges });
    const { lockupNumber, afisNumber, lockupLocation, lockupDateUtc } = arrest;
    const isLockupSummaryDataVisible = !!(
        lockupNumber ||
        afisNumber ||
        lockupLocation ||
        lockupDateUtc
    );

    return (
        <>
            {map(orderedCharges, (orderedCharge) => {
                const {
                    id,
                    dispositionAttrId,
                    isSealed,
                    isVacated,
                    dispositionDateUtc,
                    juvenileDispositionAttrId,
                    juvenileDispositionDateUtc,
                    collateralBondReceiptNum,
                    collateralBondAmountPaid,
                } = orderedCharge;
                const isChargeSummaryDataVisible = !!(
                    (dispositionAttrId && !isVacated) ||
                    dispositionDateUtc ||
                    juvenileDispositionAttrId ||
                    juvenileDispositionDateUtc ||
                    collateralBondReceiptNum ||
                    collateralBondAmountPaid
                );

                const chargeSummary = (
                    <SummaryList labelWidth={200} contentWidth={340}>
                        {!isVacated && (
                            <SummaryRow fieldName={fields.CHARGE_DISPOSITION_ATTR_ID}>
                                {formatAttributeById(dispositionAttrId)}
                            </SummaryRow>
                        )}
                        <SummaryRowDate
                            date={dispositionDateUtc}
                            fieldName={fields.CHARGE_DISPOSITION_DATE_UTC}
                        />
                        <SummaryRow fieldName={fields.CHARGE_JUVENILE_DISPOSITION_ATTR_ID}>
                            {formatAttributeById(juvenileDispositionAttrId)}
                        </SummaryRow>
                        <SummaryRowDate
                            date={juvenileDispositionDateUtc}
                            fieldName={fields.CHARGE_JUVENILE_DISPOSITION_DATE_UTC}
                        />
                        <SummaryRow fieldName={fields.CHARGE_COLLATERAL_BOND_RECEIPT_NUM}>
                            {collateralBondReceiptNum}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.CHARGE_COLLATERAL_BOND_AMOUNT_PAID}>
                            {collateralBondAmountPaid}
                        </SummaryRow>
                    </SummaryList>
                );

                return (
                    <>
                        {isSealed ? (
                            <ChargeTitleWrapper
                                key={id}
                                data-test-id={testIds.BOOKING_CARD_CHARGE_SUMMARY}
                            >
                                <SealedChargeWrapper>
                                    <SemiBoldSummaryTitle>
                                        {strings.sealedCharge}
                                    </SemiBoldSummaryTitle>
                                    <SealedStampStyled>
                                        <SealedStamp />
                                    </SealedStampStyled>
                                </SealedChargeWrapper>
                            </ChargeTitleWrapper>
                        ) : (
                            <ChargeTitleWrapper
                                key={id}
                                data-test-id={testIds.BOOKING_CARD_CHARGE_SUMMARY}
                            >
                                <ChargeTitle charge={orderedCharge} />
                                {isChargeSummaryDataVisible && chargeSummary}
                            </ChargeTitleWrapper>
                        )}
                    </>
                );
            })}
            <>
                {isLockupSummaryDataVisible && <SummaryText>{strings.lockupDetail}</SummaryText>}
                <SummaryList labelWidth={160} contentWidth={380}>
                    <SummaryRow fieldName={fields.ARREST_LOCKUP_NUMBER}>{lockupNumber}</SummaryRow>
                    <SummaryRow fieldName={fields.ARREST_AFIS_NUMBER}>{afisNumber}</SummaryRow>
                    <SummaryRow fieldName={fields.ARREST_LOCKUP_LOCATION}>
                        {lockupLocation}
                    </SummaryRow>
                    <SummaryRowDate
                        fieldName={fields.ARREST_LOCKUP_DATE_UTC}
                        date={lockupDateUtc}
                    />
                </SummaryList>
            </>
        </>
    );
});

export default BookingCardSummary;

import { useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { FilterT, VisibleFilter } from 'arc';
import { filtersActionCreators, useFiltersState } from './useFiltersState';

type UseFiltersProps<TFilterOption = string> = {
    filterIds: FilterT['id'][];
    initialAppliedFilters: VisibleFilter<TFilterOption>[];
    onFiltersChange?: (activeFilters: VisibleFilter<TFilterOption>[], reset?: boolean) => void;
    wait?: number;
};

export const useFilters = <TFilterOption = string>({
    filterIds,
    initialAppliedFilters,
    onFiltersChange,
    wait = 800,
}: UseFiltersProps<TFilterOption>) => {
    const [{ appliedFilters, isDirty, shouldFetchNextRequest }, dispatch] = useFiltersState({
        initialAppliedFilters,
    });

    const applyFilters = useCallback(
        (filters: VisibleFilter<TFilterOption>[], withFetchForNextRequest = false) => {
            dispatch(filtersActionCreators.applyFilters(filters, withFetchForNextRequest));
        },
        [dispatch]
    );

    const handleDebouncedFiltersChange = useMemo(() => {
        return debounce((visibleFilters: VisibleFilter<TFilterOption>[], reset?: boolean) => {
            onFiltersChange?.(visibleFilters, reset);
        }, wait);
    }, [onFiltersChange, wait]);

    const handleFiltersChange = (returnedFilters: VisibleFilter<TFilterOption>[]) => {
        if (!isDirty && isEqual(appliedFilters, returnedFilters)) {
            return;
        }

        dispatch(filtersActionCreators.saveFilters(returnedFilters));

        if (!shouldFetchNextRequest) {
            return;
        }

        const visibleFilters = filterIds.map((originalFilterId) => {
            const returnedFilter = returnedFilters.find((item) => item.id === originalFilterId);

            return (
                returnedFilter || {
                    id: originalFilterId,
                    appliedOptions: [],
                }
            );
        });

        handleDebouncedFiltersChange(visibleFilters, returnedFilters.length === 0);
    };

    return {
        appliedFilters,
        applyFilters,
        handleDebouncedFiltersChange,
        handleFiltersChange,
    };
};

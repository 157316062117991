import reportCardEnum from '~/client-common/core/enums/universal/reportCardEnum';
import { reportDefinitionHasCardSelector } from '~/client-common/core/domain/report-definitions/state/data';
import { currentReportSelector } from '../../../legacy-redux/selectors/reportSelectors';
import { RootState } from '../../../legacy-redux/reducers/rootReducer';

/**
 * Determine if the current report is an arrest report.
 * ie: Since there is no concept of "reports" in RMS,
 *     we will check if the current report definition has
 *     the arrest card. If it does then return `true`,
 *     `false` otherwise.
 */
export function getReportHasArrestCard(getState: () => RootState): () => boolean {
    return () => {
        const state = getState();
        const currentReport = currentReportSelector(state);
        const reportDefinitionId = currentReport?.reportDefinitionId;
        return reportDefinitionId
            ? reportDefinitionHasCardSelector(state)(reportDefinitionId, reportCardEnum.ARREST.id)
            : false;
    };
}

import { ElasticSearchTypeEnum } from '@mark43/rms-api';
import { compact, get } from 'lodash';

import { createStructuredSelector, createSelector } from 'reselect';
import {
    DISPLAY_ONLY_CASE,
    DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL,
} from '~/client-common/core/enums/universal/fields';
import { createElasticCasesResultsSelector } from '~/client-common/core/domain/elastic-cases/state/data';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import { currentUserUserRoleIdSelector } from '~/client-common/core/domain/roles/state/data';
import {
    combinedSubdivisionsLabelSelector,
    formatFieldByNameSelector,
    globalSequenceNumberLabelSelector,
} from '~/client-common/core/fields/state/config';
import componentStrings from '~/client-common/core/strings/componentStrings';
import createSearchModule from '../../../../search/core/utils/createSearchModule';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import { casesSearchQueryParamDefaults } from '../../../core/configuration';
import myCasesSearchForm, {
    myCasesSearchFormFieldViewModels,
    convertMyCasesSearchElasticQueryToFormModel,
} from '../forms/myCasesSearchForm';
import { convertFormModelToFilterGroups } from '../../../../../legacy-redux/helpers/formFilterHelpers';
import searchResource from '../../../../../legacy-redux/resources/searchResource';

const strings = componentStrings.cases.allCases.AllCasesSearchCaseInformationFieldset;

const assigneeQuerySelector = createSelector(
    currentUserUserRoleIdSelector,
    (currentUserUserRoleId) => ({
        assigneeRoleIds: [currentUserUserRoleId],
        includeAssistingInvestigators: true,
    })
);

// SEARCH MODULE
export const myCasesSearch = createSearchModule({
    elasticSearchType: ElasticSearchTypeEnum.CASE_ASSIGNED_TO_ME.name,
    baseUiSelector: (state) => state.ui.cases.myCases,
    form: myCasesSearchForm,
    elasticQueryToFilterGroups: convertMyCasesSearchElasticQueryToFilterGroups,
    loadSearchExportPrintablesResourceMethod: searchResource.getCasesPrintables,
    baseQuery: assigneeQuerySelector,
    defaultTableState: {
        from: casesSearchQueryParamDefaults.FROM,
        size: casesSearchQueryParamDefaults.SIZE,
        // specify the default sort because it's not relevance sort
        sortKey: sortKeyEnum.CASE_ASSIGNED_DATE_UTC,
        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
        activeColumnKeys: {
            date: 'assignedDateUtc',
            caseInfo: 'caseNumberName',
            personnel: 'yourInvolvement',
            location: 'primaryLocation',
        },
    },
    searchResultToRoutePath: ({ id }) => `/cases/${id}/summary`,
    resourceMethod: elasticSearchResource.searchCases,
    resultsContainerClassName: 'my-cases-results-container',
    createResultsViewModelsSelector: createElasticCasesResultsSelector,
    selectorToBind: createStructuredSelector({
        globalSequenceNumberLabel: globalSequenceNumberLabelSelector,
        combinedSubdivisionsLabel: combinedSubdivisionsLabelSelector,
        formatFieldByName: formatFieldByNameSelector,
    }),
});

export function resetMyCasesSearchForm() {
    return (dispatch) => {
        dispatch(myCasesSearchForm.actionCreators.change({}));
    };
}

export function submitMyCasesSearch(options = {}) {
    return function (dispatch, getState) {
        const state = getState();
        const assigneeRoleQuery = assigneeQuerySelector(state);

        dispatch(
            myCasesSearch.form.actionCreators.submit((formData) => {
                dispatch(
                    myCasesSearch.actionCreators.search(
                        {
                            formData: {
                                ...formData,
                                ...assigneeRoleQuery,
                            },
                            from: 0,
                        },
                        { cacheBust: true, autoSave: options.autoSave }
                    )
                );
            })
        );
    };
}

/**
 * Make the default search for my cases. This action is meant to be
 *   dispatched when the my cases route is entered.
 */
export function searchInitialMyCases() {
    return function (dispatch, getState) {
        dispatch(myCasesSearch.actionCreators.loadAndExecuteLatestAutoSavedSearch()).then(
            (result) => {
                if (!result) {
                    // this is the first enter, so update the form accordingly
                    dispatch(
                        myCasesSearchForm.actionCreators.change(
                            get(
                                myCasesSearch.selectors.currentQuerySelector(getState()),
                                'elasticQuery',
                                {}
                            )
                        )
                    );
                    // do not auto-save the default search so that
                    // a faulty request cannot cause your latest saved-search
                    // to be overwritten by the default search
                    return dispatch(submitMyCasesSearch({ autoSave: false }));
                }
            }
        );
    };
}

/**
 * Based on the given elastic query, compute filter groups to be displayed in
 *   the UI.
 * @param  {Object}   elasticQuery
 * @param  {function} formatFieldValue Display string function passed in because
 *   it depends on state.
 * @param  {Object}   state
 * @return {Object}   Array of filter group view models.
 */
function convertMyCasesSearchElasticQueryToFilterGroups(
    elasticQuery,
    formatFieldValue,
    boundSelectors
) {
    const combinedSubdivisionsLabel = boundSelectors.combinedSubdivisionsLabel;
    const globalSequenceNumberLabel = boundSelectors.globalSequenceNumberLabel;
    const formatFieldByName = boundSelectors.formatFieldByName;

    const caseLabel = formatFieldByName(DISPLAY_ONLY_CASE);

    const formModel = convertMyCasesSearchElasticQueryToFormModel(elasticQuery);

    const fieldViewModels = {
        ...myCasesSearchFormFieldViewModels,
        caseInformation: {
            ...myCasesSearchFormFieldViewModels.caseInformation,
            title: strings.title(caseLabel),
            fields: {
                ...myCasesSearchFormFieldViewModels.caseInformation.fields,
                startDateUtc: {
                    ...myCasesSearchFormFieldViewModels.caseInformation.fields.startDateUtc,
                    label: formatFieldByName(DISPLAY_ONLY_CASE_SEARCH_DATE_TIME_RANGE_LABEL),
                },
                reportIdentifier: {
                    ...myCasesSearchFormFieldViewModels.caseInformation.fields.reportIdentifier,
                    label: globalSequenceNumberLabel,
                },
                subdivisionAttrIds: {
                    ...myCasesSearchFormFieldViewModels.caseInformation.fields.subdivisionAttrIds,
                    label: combinedSubdivisionsLabel,
                },
            },
        },
    };

    return compact(
        convertFormModelToFilterGroups(
            formModel,
            fieldViewModels,
            formatFieldValue,
            formatFieldByName
        )
    );
}

export default myCasesSearch.reducers.uiReducer;

import { createSelector } from 'reselect';
import { partition, get, sortBy, find, map } from 'lodash';
import { formatFullName } from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';
import {
    personProfilesWithLinksForReportIdSelector,
    organizationProfilesWithLinksForReportIdSelector,
} from '~/client-common/core/domain/reports/state/ui/names';
import { locationViewModelsWithLinksForReportIdSelector } from '~/client-common/core/domain/locations/state/ui';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { itemProfileIsVehicle } from '~/client-common/core/domain/item-profiles/utils/itemProfileHelpers';
import {
    propertyTitleForItemProfileSelector,
    itemTypeSpecificViewModelsForReportIdSelector,
} from '~/client-common/core/domain/item-profiles/state/ui';
import { entitySidebarConfigEnum } from '../../constants/entitySidebarConfigEnum';

export const entitySidebarDataForReportIdSelector = createSelector(
    personProfilesWithLinksForReportIdSelector,
    organizationProfilesWithLinksForReportIdSelector,
    locationViewModelsWithLinksForReportIdSelector,
    itemTypeSpecificViewModelsForReportIdSelector,
    propertyTitleForItemProfileSelector,
    (
        personProfilesWithLinksForReportId,
        organizationProfilesWithLinksForReportId,
        locationViewModelsWithLinksForReportId,
        itemTypeSpecificViewModelsForReportId,
        propertyTitleForItemProfile
    ) => (reportId) => {
        const [vehicles, property] = partition(
            itemTypeSpecificViewModelsForReportId(reportId),
            itemProfileIsVehicle
        );
        return {
            [entitySidebarConfigEnum.PEOPLE]: sortBy(
                personProfilesWithLinksForReportId(reportId),
                ({ entity }) => formatFullName(entity)
            ),
            [entitySidebarConfigEnum.ORGANIZATIONS]: sortBy(
                organizationProfilesWithLinksForReportId(reportId),
                'entity.name'
            ),
            [entitySidebarConfigEnum.LOCATIONS]: sortBy(
                locationViewModelsWithLinksForReportId(reportId),
                ({ entity, links }) => {
                    // Sort by either the placename, or the full address
                    // For placename, just grab the first placename on any
                    // entityLink
                    const { names } = getViewModelProperties(entity);
                    const placeName = get(find(links, 'placeName'), 'placeName');
                    return placeName || names.full;
                }
            ),
            [entitySidebarConfigEnum.VEHICLES]: sortBy(
                map(vehicles, (vehicle) => ({ entity: vehicle, links: [] })),
                ({ entity }) => {
                    return propertyTitleForItemProfile(entity);
                }
            ),
            [entitySidebarConfigEnum.PROPERTY]: sortBy(
                map(property, (item) => {
                    return { entity: item, links: [] };
                }),
                ({ entity }) => {
                    return propertyTitleForItemProfile(entity);
                }
            ),
        };
    }
);

import { CaseRoleLink } from '@mark43/rms-api';
import createLinkModule from '../../../../utils/createLinkModule';

export const NEXUS_STATE_PROP = 'caseRoleLinks';
const caseRoleLinksModule = createLinkModule<CaseRoleLink>({
    type: NEXUS_STATE_PROP,
    keys: ['id'],
});

// ACTIONS

export const replaceCaseRoleLinksWhere = caseRoleLinksModule.actionCreators.replaceLinksWhere;

// SELECTORS
export const caseRoleLinksSelector = caseRoleLinksModule.selectors.linksSelector;

// REDUCER
export default caseRoleLinksModule.reducerConfig;

import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../../core/components/Icon';
import useOnSaveSearchToggleHandler from '../../../search/core/hooks/useOnSaveSearchToggleHandler';
import useSaveAsNewSearch from '../../../search/core/hooks/useSaveAsNewSearch';
import CreateSavedSearchModal from '../../../search/saved-search/components/CreateSavedSearchModal';
import { DeleteSavedSearchConfirmationModal } from '../../../search/saved-search/components/SavedSearchConfirmationModals';
import { SavedSearchDropdownButton } from '../../../search/saved-search/components/SavedSearchDropdownButton';
import testIds from '../../../../core/testIds';

const strings = componentStrings.cases.core.CasesSearchFormFooter;

/**
 * Footer in cases search forms containing submit and reset buttons.
 * @param {function} props.onSubmit
 * @param {function} props.onReset
 */
export default function CasesSearchFormFooter({
    onSubmit,
    onReset,
    query,
    renderSearchButton,
    searchModule,
}) {
    const isSavedSearch = query.savedSearchId && !query.isAutoSave;
    const submitButtonClassNames = classNames(buttonTypes.PRIMARY, 'submit-button');
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(searchModule);
    const applicationSettings = useSelector(applicationSettingsSelector);
    const savedSearchSharingEnabled = applicationSettings.RMS_SAVED_SEARCH_SHARING_ENABLED;
    const saveAsNewSearchHandler = useSaveAsNewSearch(searchModule);

    return (
        <div className="cases-search-form-footer">
            {renderSearchButton ? (
                renderSearchButton({
                    onClick: onSubmit,
                    submitText: strings.search,
                    className: submitButtonClassNames,
                })
            ) : (
                <Button
                    testId={testIds.CASE_DASHBOARD_APPLY_FILTER_BUTTON}
                    className={submitButtonClassNames}
                    onClick={onSubmit}
                >
                    {strings.search}
                </Button>
            )}
            <SavedSearchDropdownButton searchModule={searchModule} />
            <Button
                testId={testIds.CASE_DASHBOARD_CLEAR_FILTER_BUTTON}
                className={classNames(buttonTypes.ICON_LINK, 'reset-button')}
                iconLeft={<Icon type={iconTypes.CLOSE_X} />}
                onClick={onReset}
            >
                {strings.clear}
            </Button>
            <FeatureFlagged
                flag="RMS_SAVED_SEARCH_SHARING_ENABLED"
                fallback={
                    <Button
                        className={classNames(buttonTypes.ICON_LINK, 'reset-button')}
                        iconLeft={
                            <Icon
                                type={
                                    isSavedSearch
                                        ? iconTypes.SAVED_SEARCH_ACTIVE
                                        : iconTypes.SAVED_SEARCH_INACTIVE
                                }
                            />
                        }
                        hoveredIconLeft={
                            <Icon
                                type={
                                    isSavedSearch
                                        ? iconTypes.SAVED_SEARCH_INACTIVE
                                        : iconTypes.SAVED_SEARCH_ACTIVE
                                }
                            />
                        }
                        onClick={onSaveSearchToggle}
                    >
                        {isSavedSearch ? strings.unsaveSearch : strings.saveSearch}
                    </Button>
                }
            />
            <CreateSavedSearchModal
                onSave={savedSearchSharingEnabled ? saveAsNewSearchHandler : onSaveSearchToggle}
            />
            <DeleteSavedSearchConfirmationModal searchModule={searchModule} />
        </div>
    );
}

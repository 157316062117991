import React from 'react';
import _, { map, uniqBy } from 'lodash';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import { attributeIsOtherSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatTitleForPropertyRecord } from '../../../../helpers/propertyRecordHelpers';
import { getIconTypeForItemTypeAttrId } from '../../../../../modules/core/item-profiles/utils/itemIcons';
import Icon from '../../Icon';

const MAX_PROPERTY_LENGTH = 4;

function PropertyCell({ attributeIsOther, involvedProperty, permissionSet = [] }) {
    if (involvedProperty.length === 0) {
        const fallbackText = canRead(permissionSet) ? 'No Involved Property' : undefined;
        return <div>{fallbackText}</div>;
    }

    const displayedProperty = _(involvedProperty)
        .groupBy('property.id')
        .map((propertyGroup) => {
            const property = propertyGroup[0].property;
            const vehicleMakeIsOther = attributeIsOther(property.vehicleMakeAttrId);
            const vehicleModelIsOther = attributeIsOther(property.vehicleModelAttrId);

            return {
                title: formatTitleForPropertyRecord(
                    property,
                    false,
                    vehicleMakeIsOther,
                    vehicleModelIsOther
                ),
                itemTypeAttrId: property.itemTypeAttrId,
                propertyStatuses: _(propertyGroup)
                    .map((property) => getViewModelProperties(property).propertyStatusAttrId)
                    .sortBy()
                    .join(', '),
            };
        })
        .sortBy('sequenceNumber')
        .take(MAX_PROPERTY_LENGTH)
        .value();

    // displayed property are effectively unique by default because of the "groupBy"
    // we perform above
    const displayedPropertyUniqueCount = displayedProperty.length;
    const totalPropertyUniqueCount = uniqBy(involvedProperty, 'property.id').length;
    const propertyNotDisplayedCount = totalPropertyUniqueCount - displayedPropertyUniqueCount;

    // This shows the number of unique persons not displayed in the cell
    // which is different from the number of person report links which are
    // not displayed in the cell
    const showMore =
        propertyNotDisplayedCount > 0 ? (
            <div className="elastic-row-show-more">
                {`+ ${propertyNotDisplayedCount} other property`}
            </div>
        ) : undefined;

    return (
        <div className="elastic-report-property">
            {map(displayedProperty, ({ title, itemTypeAttrId, propertyStatuses }, index) => (
                <div key={index}>
                    <div className="link-type">
                        <Icon type={getIconTypeForItemTypeAttrId(itemTypeAttrId)} />
                        <span className="link-type-dash">-</span>
                    </div>
                    <div className="property">
                        <div>{title}</div>
                        <div className="property-statuses">{propertyStatuses}</div>
                    </div>
                </div>
            ))}
            {showMore}
        </div>
    );
}

export default connect(
    createStructuredSelector({
        attributeIsOther: attributeIsOtherSelector,
    })
)(PropertyCell);

import React from 'react';
import _ from 'lodash';
import componentStrings from '~/client-common/core/strings/componentStrings';
import EntityProfileSection, {
    EntityProfileSummaryColumn,
    EntityProfileDetailSummaryList,
    EntityProfileSubdetailSummaryList,
} from '../../core/components/EntityProfileSection';
import SummaryRow from '../../../../legacy-redux/components/summaries/SummaryRow';

const strings = componentStrings.entityProfiles.person.EducationDetails;

function mapSchoolHistoryRows(schoolHistories) {
    return _.map(schoolHistories, (history) => {
        return (
            <SummaryRow key={history.id} label={strings.labels.school}>
                <div>{history.schoolName}</div>
                <EntityProfileSubdetailSummaryList>
                    <SummaryRow label={strings.labels.address}>{history.schoolAddress}</SummaryRow>
                    <SummaryRow label={strings.labels.grade}>{history.grade}</SummaryRow>
                    <SummaryRow label={strings.labels.status}>{history.status}</SummaryRow>
                    <SummaryRow label={strings.labels.phoneNumber}>
                        {history.displayNumber}
                    </SummaryRow>
                </EntityProfileSubdetailSummaryList>
            </SummaryRow>
        );
    });
}

export default function EducationDetails({ schoolHistories }) {
    const partitionedSchoolHistories = _.partition(schoolHistories, (history, index) => index % 2);
    const leftSchoolHistoryRows = mapSchoolHistoryRows(partitionedSchoolHistories[1]);
    const rightSchoolHistoryRows = mapSchoolHistoryRows(partitionedSchoolHistories[0]);

    return (
        <EntityProfileSection title={strings.title}>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    {leftSchoolHistoryRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
            <EntityProfileSummaryColumn>
                <EntityProfileDetailSummaryList>
                    {rightSchoolHistoryRows}
                </EntityProfileDetailSummaryList>
            </EntityProfileSummaryColumn>
        </EntityProfileSection>
    );
}

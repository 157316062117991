import { DOJSchool } from '@mark43/rms-api';

import { ClientCommonAction } from '../../../../../redux/types';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getDojSchoolsResource from '../../resources/dojSchoolsResource';

export const NEXUS_STATE_PROP = 'dojSchools';

const dojSchoolsModule = createNormalizedModule<DOJSchool>({
    type: NEXUS_STATE_PROP,
});

export const dojSchoolsSelector = dojSchoolsModule.selectors.entitiesSelector;

const SEARCH_FOR_DOJ_SCHOOLS_START = 'SEARCH_FOR_DOJ_SCHOOLS_START';
const SEARCH_FOR_DOJ_SCHOOLS_SUCCESS = 'SEARCH_FOR_DOJ_SCHOOLS_SUCCESS';
const SEARCH_FOR_DOJ_SCHOOLS_FAIL = 'SEARCH_FOR_DOJ_SCHOOLS_FAIL';

function searchForDojSchoolsStart() {
    return { type: SEARCH_FOR_DOJ_SCHOOLS_START };
}
function searchForDojSchoolsSuccess(dojSchools: DOJSchool[]) {
    return { type: SEARCH_FOR_DOJ_SCHOOLS_SUCCESS, payload: dojSchools };
}
function searchForDojSchoolsFail(message: string) {
    return { type: SEARCH_FOR_DOJ_SCHOOLS_FAIL, payload: message };
}

export function searchForDojSchools({
    q,
    from = 0,
    size,
}: {
    q?: string;
    from?: number;
    size?: number;
}): ClientCommonAction<Promise<void>> {
    const dojSchoolsResource = getDojSchoolsResource();

    return (dispatch, getState, { nexus }) => {
        dispatch(searchForDojSchoolsStart());
        return dojSchoolsResource
            .searchForDojSchools({
                q,
                from,
                size,
            })
            .then((dojSchools: DOJSchool[]) => {
                return dispatch(
                    nexus.withEntityItems(
                        {
                            [NEXUS_STATE_PROP]: dojSchools,
                        },
                        searchForDojSchoolsSuccess(dojSchools)
                    )
                );
            })
            .catch((err: Error) => {
                dispatch(searchForDojSchoolsFail(err.message));
                throw err;
            });
    };
}

export default dojSchoolsModule.reducerConfig;

import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';

import { BlueActionBar } from '../../../core/components/ActionBar';

const strings = componentStrings.notifications.dashboard.NotificationsActionBar;

const ActionBar = styled(BlueActionBar)`
    line-height: 50px;
    padding-left: 185px;
`;

const ClickActionText = styled.div`
    display: inline-block;
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
`;

function NotificationsActionBar({
    allResultsSelected,
    allResultsOnPageSelected,
    totalResults,
    selectedResults,
    handleClearAllSelectedRows,
    handleSelectAllRows,
}) {
    const clickText = allResultsSelected ? strings.clearSelection : strings.selectAll(totalResults);
    let statusText;
    if (allResultsSelected) {
        statusText = strings.allSelected(totalResults);
    } else if (allResultsOnPageSelected) {
        statusText = strings.allOnPageSelected(selectedResults.length);
    } else {
        statusText = strings.someOnPageSelected(selectedResults.length);
    }
    return (
        <ActionBar visible={true}>
            {`${statusText} `}
            <ClickActionText
                onClick={allResultsSelected ? handleClearAllSelectedRows : handleSelectAllRows}
            >
                {clickText}
            </ClickActionText>
        </ActionBar>
    );
}

/**
 * Action bar that appears at the bottom of the page when notifications are
 *   selected, allowing the user to select all or to clear their selection.
 */
export default NotificationsActionBar;

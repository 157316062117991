import {
    NotificationStatusEnum,
    UsageSourceModuleEnum,
    UsageActionEnum,
    UsageCompletionEnum,
    EntityTypeEnum,
    DeliveryMethodEnum,
} from '@mark43/rms-api';

import { get, noop } from 'lodash';
import { markAllUserNotifications } from '~/client-common/core/domain/user-notifications/state/data';
import notificationTypeEnum from '~/client-common/core/enums/universal/notificationTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { createUsageLog } from '../../admin/usage-logs/state/data';
import { openConfirmationBar } from '../../core/confirmation-bar/state/ui';
import {
    notificationsUnreadCountSelector,
    markingUserNotificationsSelector,
    previousPollDateUtcSelector,
} from '../navigation-link/state/ui';
import {
    getNotificationsAlertsAndAttachments,
    notificationsInbox,
    notificationsArchive,
    notificationsAlerts,
    unsubscribeNotificationTypeForCurrentUser,
} from './state/ui';

const strings = componentStrings.notifications.dashboard;

const { READ, UNREAD } = NotificationStatusEnum;

const createNotificationUsageLog = ({ action, completion }) => (dispatch) =>
    dispatch(
        createUsageLog({
            action,
            completion,
            primaryEntityType: EntityTypeEnum.NOTIFICATION.name,
            sourceModule: UsageSourceModuleEnum.NOTIFICATIONS.name,
        })
    );

export function notificationsDashboardInboxOnEnter(nextState) {
    const dispatch = this.dispatch;

    const unsubscribeType = get(nextState, 'location.query.unsubscribe_type');
    if (unsubscribeType) {
        dispatch(
            unsubscribeNotificationTypeForCurrentUser(
                unsubscribeType,
                DeliveryMethodEnum.EMAIL.name
            )
        ).then(() => {
            const notificationTypeDisplay = get(
                notificationTypeEnum,
                `[${unsubscribeType}].display`
            );
            const message = strings.unsubscribeConfirmation(notificationTypeDisplay);

            dispatch(openConfirmationBar({ message }));
        });
    }

    this.dispatch(notificationsInbox.actionCreators.search({}, { cacheBust: true })).then(
        (result) => {
            dispatch(
                createNotificationUsageLog({
                    action: UsageActionEnum.VIEWED_NOTIFICATION_INBOX.name,
                    completion:
                        result.type === notificationsInbox.actionTypes.searchSuccess
                            ? UsageCompletionEnum.SUCCEEDED.name
                            : UsageCompletionEnum.SERVER_ERROR.name,
                })
            );
            return result;
        }
    );

    // mark all unread notifications as read asynchronously in the background,
    // and silently ignore any API error
    const state = this.getState();
    const unreadCount = notificationsUnreadCountSelector(state);
    const markingUserNotifications = markingUserNotificationsSelector(state);
    if (unreadCount > 0 && !markingUserNotifications) {
        const previousPollDateUtc = previousPollDateUtcSelector(state);
        this.dispatch(
            markAllUserNotifications({
                beforeStatuses: [UNREAD.name],
                afterStatus: READ.name,
                endDateUtc: previousPollDateUtc,
            })
        ).catch(noop);
    }
}

export function notificationsDashboardAlertsOnEnter() {
    const dispatch = this.dispatch;
    dispatch(getNotificationsAlertsAndAttachments()).then((result) => {
        dispatch(
            createNotificationUsageLog({
                action: UsageActionEnum.VIEWED_NOTIFICATION_ALERTS.name,
                completion:
                    result.type === notificationsAlerts.actionTypes.searchSuccess
                        ? UsageCompletionEnum.SUCCEEDED.name
                        : UsageCompletionEnum.SERVER_ERROR.name,
            })
        );
        return result;
    });
}

export function notificationsDashboardArchiveOnEnter() {
    const dispatch = this.dispatch;
    dispatch(notificationsArchive.actionCreators.search({}, { cacheBust: true })).then((result) => {
        dispatch(
            createNotificationUsageLog({
                action: UsageActionEnum.VIEWED_NOTIFICATION_ARCHIVE.name,
                completion:
                    result.type === notificationsArchive.actionTypes.searchSuccess
                        ? UsageCompletionEnum.SUCCEEDED.name
                        : UsageCompletionEnum.SERVER_ERROR.name,
            })
        );
        return result;
    });
}

import React from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { chain, get } from 'lodash';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { departmentNameFromConsortiumLinksByDepartmentIdSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { currentUserDepartmentIdSelector } from '../../../../../modules/core/current-user/state/ui';

const AgencyContainer = styled.div`
    margin-top: 10px;
`;
const AgencyText = styled.span`
    color: ${(props) => props.theme.colors.lightGrey};
    text-transform: uppercase;
`;

function PropertyCell({
    elasticProperty,
    currentUserDepartmentId,
    departmentNameFromConsortiumLinksByDepartmentId,
}) {
    const { description, itemTypeAttrId, departmentId, updatedDateUtc, property } = elasticProperty;
    const { firearmMakeAttrId } = getViewModelProperties(elasticProperty);

    const mostRecentPropertyStatus = chain(elasticProperty.propertyStatuses)
        .sortBy('statusDateUtc')
        .last()
        .value();

    const statusDateUtc = get(mostRecentPropertyStatus, 'statusDateUtc');
    const { propertyStatusAttrId } = getViewModelProperties(mostRecentPropertyStatus);

    const isFirearm = itemTypeAttrId === globalAttributes.itemType.firearm;

    const propertyTitle =
        (isFirearm && (property.atfManufacturerName || firearmMakeAttrId)) || description;

    const isExternalAgency = departmentId !== currentUserDepartmentId;
    const departmentName = isExternalAgency
        ? departmentNameFromConsortiumLinksByDepartmentId(departmentId)
        : undefined;

    return (
        <div className="elastic-property-info">
            <div className="elastic-property-info-content">
                {propertyTitle && (
                    <div className="elastic-property-description">{propertyTitle}</div>
                )}
                {propertyStatusAttrId && (
                    <div className="elastic-property-status-info">
                        {propertyStatusAttrId}
                        {statusDateUtc ? ' on ' : ''}
                        <FormattedDate
                            date={statusDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    </div>
                )}
                <div className="elastic-property-modified-date-container">
                    <span className="elastic-property-modified-text">Modified: </span>
                    <span className="elastic-property-modified-date">
                        <FormattedDate
                            date={updatedDateUtc}
                            format={FormattedDate.FORMATS.FORM_DATE}
                        />
                    </span>
                </div>
                {departmentName && (
                    <AgencyContainer>
                        <AgencyText>Agency: </AgencyText>
                        <span>{departmentName}</span>
                    </AgencyContainer>
                )}
            </div>
        </div>
    );
}

export default compose(
    mapProps((ownerProps) => ({ elasticProperty: ownerProps })),
    connect(
        createStructuredSelector({
            currentUserDepartmentId: currentUserDepartmentIdSelector,
            departmentNameFromConsortiumLinksByDepartmentId: departmentNameFromConsortiumLinksByDepartmentIdSelector,
        })
    )
)(PropertyCell);

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here

let resource;

export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report Comments Resource',
        methods: {
            createReportComment(reportId, comment) {
                return req({
                    method: 'POST',
                    url: `reports/${reportId}/comments`,
                    data: {
                        data: comment,
                    },
                });
            },
            deleteReportComment(id) {
                return req({
                    method: 'DELETE',
                    url: `reports/comments/${id}`,
                });
            },
        },
    });
}

const resourceGetter = () => resource;
export default resourceGetter;

import React from 'react';
import { flatMap, map } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import withFields from '~/client-common/core/fields/components/withFields';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import personProfileMergeableFields from '../configuration/personProfileMergeableFields';

import GenericPropertyList from './GenericPropertyList';

const PersonProfilePropertyList = (props) => (
    <GenericPropertyList {...props} propertyConfiguration={personProfileMergeableFields} />
);

const PersonProfilePropertyListWithFields = withFields(() =>
    flatMap(personProfileMergeableFields, ({ field, relatedFields }) => {
        const fields = [field];
        if (relatedFields) {
            fields.push(...map(relatedFields, 'field'));
        }

        return fields;
    })
)(
    connect(
        createStructuredSelector({
            formatAttributeById: formatAttributeByIdSelector,
        })
    )(PersonProfilePropertyList)
);

export default PersonProfilePropertyListWithFields;

import React from 'react';
import { FederatedSearchPerson } from 'mark43-federated-search';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Modal from '../../overlays/components/Modal';
import { OverlayBaseHelper } from '../../components/OverlayBaseHelper';

type PersonOverrideDialogModal = {
    selectedId: number;
};

const strings = componentStrings.core.PersonSidePanel;

export const OverrideProfileModal = ({
    onOverride,
    onKeepExisting,
}: {
    onOverride: ({
        dexPerson,
        selectedId,
    }: {
        dexPerson?: FederatedSearchPerson;
        selectedId?: number;
    }) => void;
    onKeepExisting: ({ selectedId }: { selectedId?: number }) => void;
}) => {
    return (
        <OverlayBaseHelper<PersonOverrideDialogModal> id={overlayIdEnum.OVERRIDE_PROFILE_MODAL}>
            {({ overlayBase }) => {
                const { selectedId } = overlayBase.overlayState.customProperties;
                const { close } = overlayBase;

                return (
                    <Modal
                        id={overlayIdEnum.OVERRIDE_PROFILE_MODAL}
                        title={strings.overridePersonModalTitle}
                        okText={strings.overrideBtnLabel}
                        onSave={onOverride}
                        cancelText={null}
                        additionalFooterContent={
                            <Button
                                style={{ height: '30px', marginTop: '12px' }}
                                className={buttonTypes.SECONDARY}
                                onClick={() => {
                                    close();
                                    onKeepExisting({ selectedId });
                                }}
                            >
                                {strings.overrideCancelBtnLabel}
                            </Button>
                        }
                    >
                        {strings.overridePersonModalBody}
                    </Modal>
                );
            }}
        </OverlayBaseHelper>
    );
};

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { REPORT_REPORTING_EVENT_NUMBER } from '~/client-common/core/enums/universal/fields';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RmsDispatch } from '../../../../core/typings/redux';
import Modal, { OverrideModalPropsFunction } from '../../../core/overlays/components/Modal';
import { submitPrefillModal } from '../state/data/reportPrefillModals';

type ReportPrefillModalCustomProps = {
    cardName?: string;
    reportId?: number;
    prefillFromReportId?: number;
    isEventInfoCardPrefill?: boolean;
};

const strings = componentStrings.reports.core.ReportPrefillModal;

const ReportPrefillModal: React.FC = () => {
    const dispatch = useDispatch<RmsDispatch>();
    const formatFieldByName = useSelector(formatFieldByNameSelector);

    const handleSave = useCallback(
        (customProperties: ReportPrefillModalCustomProps) => {
            const { reportId, prefillFromReportId, isEventInfoCardPrefill } = customProperties;
            return dispatch(
                submitPrefillModal(reportId, prefillFromReportId, isEventInfoCardPrefill)
            );
        },
        [dispatch]
    );

    const overrideModalProps: OverrideModalPropsFunction<ReportPrefillModalCustomProps> = (
        overlayProps
    ) => {
        const { cardName } = overlayProps.overlayState.customProperties;
        const renDisplayName = formatFieldByName(REPORT_REPORTING_EVENT_NUMBER);

        const children = <>{strings.message.initial(renDisplayName, cardName)}</>;

        return { children };
    };

    return (
        <Modal
            // no button because Modal is opened by route change
            cancelText={strings.buttonText.cancelButton.initial}
            id={overlayIdEnum.REPORT_PREFILL_MODAL}
            okText={strings.buttonText.okButton}
            onSave={handleSave}
            overrideModalProps={overrideModalProps}
            title={strings.title.initial}
        />
    );
};

export default ReportPrefillModal;

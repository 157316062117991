import { filter } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, withHandlers } from 'recompose';

import globalAttributes from '~/client-common/core/legacy-constants/globalAttributes';
import { parentAttributeIdByAttributeIdSelector } from '~/client-common/core/domain/attributes/state/data';

/**
 * Return a higher-order component that provides a prop called `itemCategoryFilterOptions` that
 *   filters depending on item type, to be passed into <AttributeSelect> as `filterOptions`.
 * @param  {number} itemTypeAttrId
 * @return {function}
 */
export default function withItemCategoryFilterOptions(itemTypeAttrId) {
    return compose(
        connect(
            createStructuredSelector({
                parentAttributeIdByAttributeId: parentAttributeIdByAttributeIdSelector,
            })
        ),
        withHandlers({
            itemCategoryFilterOptions({ parentAttributeIdByAttributeId }) {
                if (itemTypeAttrId === globalAttributes.itemType.vehicle) {
                    // vehicle is the one special case where some ITEM_CATEGORY attributes can have
                    // child ITEM_CATEGORY attributes, see CHD-2850
                    return (options) =>
                        filter(options, ({ value }) => {
                            const parentAttrId = parentAttributeIdByAttributeId(value);
                            return (
                                parentAttrId === itemTypeAttrId ||
                                parentAttributeIdByAttributeId(parentAttrId) === itemTypeAttrId
                            );
                        });
                }

                return (options) =>
                    filter(
                        options,
                        ({ value }) => parentAttributeIdByAttributeId(value) === itemTypeAttrId
                    );
            },
        })
    );
}

import React, { createContext } from 'react';
import { EntityTypeEnum } from '@mark43/rms-api';

export type SelectedContentItem = {
    id: number;
    rowEntityType: typeof EntityTypeEnum.ATTACHMENT.name | typeof EntityTypeEnum.CASE_NOTE.name;
};

type CurrentFolderIdContextProps = {
    currentFolderId?: number;
    setCurrentFolderId?: React.Dispatch<React.SetStateAction<number | undefined>>;
    folderIdsToDelete?: number[];
    setFolderIdsToDelete?: React.Dispatch<React.SetStateAction<number[]>>;
    selectedContentItemsToDelete?: SelectedContentItem[];
    setSelectedContentItemsToDelete?: React.Dispatch<React.SetStateAction<SelectedContentItem[]>>;
    parentFolderIdOfCurrentFolder?: number;
    setParentFolderIdOfCurrentFolder?: React.Dispatch<React.SetStateAction<number | undefined>>;
};

export const CurrentFolderIdContext = createContext<CurrentFolderIdContextProps>({});

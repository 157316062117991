import { RefContextEnum } from '@mark43/rms-api';
import { noop } from 'lodash';

import { useState, useEffect } from 'react';
import formsRegistry from '../../../../core/formsRegistry';

/**
 * Effect that will set the `eventDateUtc` by reading the value from either
 * the Event Info card or Supplement Info card, depending on the report type.
 */
const useEventDateUtc = ({
    reportHasEventInfoCard,
    reportHasSupplementInfoCard,
    eventInfoFormDatePath,
    supplementInfoFormDatePath,
}) => {
    const [eventDateUtc, setEventDateUtc] = useState(undefined);

    useEffect(() => {
        if (reportHasEventInfoCard || reportHasSupplementInfoCard) {
            let eventDateUtcObserveDisposer = noop;
            let mftCardFormDisposer = noop;

            if (reportHasEventInfoCard) {
                mftCardFormDisposer = formsRegistry.maybeDeferredOperation(
                    RefContextEnum.FORM_EVENT_INFO.name,
                    undefined,
                    (eventInfoForm) => {
                        setEventDateUtc(eventInfoForm.get(eventInfoFormDatePath));
                        eventDateUtcObserveDisposer = eventInfoForm.observe({
                            subscriptions: {
                                [eventInfoFormDatePath]: eventInfoFormDatePath,
                            },
                            callback: ({ [eventInfoFormDatePath]: eventDateUtc }) =>
                                setEventDateUtc(eventDateUtc),
                        });
                    }
                );
            } else {
                mftCardFormDisposer = formsRegistry.maybeDeferredOperation(
                    RefContextEnum.FORM_SUPPLEMENT_INFO.name,
                    undefined,
                    (supplementInfoForm) => {
                        setEventDateUtc(supplementInfoForm.get(supplementInfoFormDatePath));
                        eventDateUtcObserveDisposer = supplementInfoForm.observe({
                            subscriptions: {
                                [supplementInfoFormDatePath]: supplementInfoFormDatePath,
                            },
                            callback: ({ [supplementInfoFormDatePath]: eventDateUtc }) =>
                                setEventDateUtc(eventDateUtc),
                        });
                    }
                );
            }

            return () => {
                eventDateUtcObserveDisposer();
                mftCardFormDisposer();
            };
        }
    }, [
        reportHasEventInfoCard,
        reportHasSupplementInfoCard,
        eventInfoFormDatePath,
        supplementInfoFormDatePath,
    ]);

    return eventDateUtc;
};

export default useEventDateUtc;

import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import {
    PERSON_PROFILE_SSN,
    PERSON_PROFILE_FBI_UCN,
    PERSON_PROFILE_STATE_ID_NUMBER,
} from '~/client-common/core/enums/universal/fields';
import useFields from '~/client-common/core/fields/hooks/useFields';

// copied from ElasticRowStyles.scss, to allow sidePanels to remove any padding/margining that search uses

const InfoGroup = styled.div`
    margin-top: ${(props) => (props.inSidePanel ? '0' : '10px')};
    word-wrap: break-word;

    &:first-child {
        margin-top: 0;
    }
`;

const InfoLabel = styled.div`
    vertical-align: top;
    display: inline-block;
    width: ${(props) => props.theme.widths.label}px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    padding-right: ${(props) => (props.inSidePanel ? '0' : '10px')};
`;

function IdentifiersCell(props) {
    /*
    const {
        inSidePanel = true,
        ...elasticPerson
    } = props
    *
    * This is what I wanted to do, but Symbol does not get carried over in object spreading
    * https://github.com/facebook/react/issues/7552
    *
    * Additionally, lodash's omit doesn't copy symbols until 4.6.1, and we're on 3.10.0 (8/24/17)
    * https://github.com/lodash/lodash/issues/2081
    */
    const fieldDisplayNames = useFields([
        PERSON_PROFILE_SSN,
        PERSON_PROFILE_FBI_UCN,
        PERSON_PROFILE_STATE_ID_NUMBER,
    ]);
    const { inSidePanel } = props;
    const elasticPerson = props;

    const {
        phoneNumbers = [],
        fbiUcn,
        ssn,
        stateIdNumber,
        dateOfBirth,
        masterPersonId,
    } = elasticPerson;

    const { age } = getViewModelProperties(elasticPerson);
    const dateOfBirthInfo = dateOfBirth && (
        <InfoGroup inSidePanel={inSidePanel}>
            <div>
                <InfoLabel inSidePanel={inSidePanel}>Age:</InfoLabel>
                <div className="info-text">{age}</div>
            </div>
            <div>
                <InfoLabel inSidePanel={inSidePanel}>DOB:</InfoLabel>
                <div className="info-text">
                    <FormattedDate date={dateOfBirth} format={FormattedDate.FORMATS.FORM_DATE} />
                </div>
            </div>
        </InfoGroup>
    );
    const phoneNumberComponents = _(phoneNumbers)
        .map((phoneNumber) => <div key={phoneNumber}>{phoneNumber}</div>)
        .take(3)
        .value();
    const morePhoneNumbersCount = phoneNumbers.length - 3;
    const phoneNumbersInfo = phoneNumbers.length ? (
        <InfoGroup inSidePanel={inSidePanel}>
            <InfoLabel inSidePanel={inSidePanel}>Phone #:</InfoLabel>
            <div className="info-text">
                {phoneNumberComponents}
                {morePhoneNumbersCount > 0 && (
                    <div className="elastic-row-show-more">{`+ ${morePhoneNumbersCount} more`}</div>
                )}
            </div>
        </InfoGroup>
    ) : undefined;
    const fbiInfo = fbiUcn && (
        <div>
            <InfoLabel inSidePanel={inSidePanel}>
                {fieldDisplayNames[PERSON_PROFILE_FBI_UCN]}:
            </InfoLabel>
            <div className="info-text">{fbiUcn}</div>
        </div>
    );
    const ssnInfo = ssn && (
        <div>
            <InfoLabel inSidePanel={inSidePanel}>
                {fieldDisplayNames[PERSON_PROFILE_SSN]}:
            </InfoLabel>
            <div className="info-text">{ssn}</div>
        </div>
    );
    const mniInfo = masterPersonId && (
        <div>
            <InfoLabel inSidePanel={inSidePanel}>MNI #:</InfoLabel>
            <div className="info-text">{masterPersonId}</div>
        </div>
    );
    const stateIdNumberInfo = stateIdNumber && (
        <div>
            <InfoLabel inSidePanel={inSidePanel}>
                {fieldDisplayNames[PERSON_PROFILE_STATE_ID_NUMBER]}:
            </InfoLabel>
            <div className="info-text">{stateIdNumber}</div>
        </div>
    );
    const identifiers = (fbiUcn || ssn || stateIdNumber || masterPersonId) && (
        <InfoGroup inSidePanel={inSidePanel}>
            {fbiInfo}
            {ssnInfo}
            {mniInfo}
            {stateIdNumberInfo}
        </InfoGroup>
    );
    return (
        <div className="elastic-person-identifiers">
            {dateOfBirthInfo}
            {phoneNumbersInfo}
            {identifiers}
        </div>
    );
}

export default connect()(IdentifiersCell);

import { combineReducers } from 'redux';

import { evidenceDashboardSearch } from './searchModule';
import { exportsUiReducer } from './exports';

export * from './exports';
export * from './searchModule';

export const executeSavedSearchFromFilter = (savedSearchId) => {
    return function (dispatch) {
        dispatch(evidenceDashboardSearch.actionCreators.executeSavedSearch(savedSearchId));
    };
};

/**
 * Submit the evidence dashboard search form.
 */
export function submitEvidenceDashboardSearchForm() {
    return function (dispatch) {
        dispatch(
            evidenceDashboardSearch.form.actionCreators.submit((formData) => {
                dispatch(
                    evidenceDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                );
            })
        );
    };
}

/**
 * Remove all currently applied filters
 * Also do another search with no filters used.
 */
export function clearAllFilters() {
    return function (dispatch) {
        dispatch(evidenceDashboardSearch.form.actionCreators.reset());
        dispatch(evidenceDashboardSearch.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(evidenceDashboardSearch.actionCreators.setExecutedSavedSearchToUpdate(null));
        dispatch(
            evidenceDashboardSearch.form.actionCreators.submit((formData) => {
                dispatch(
                    evidenceDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                );
            })
        );
    };
}

export const applyFiltersAndUpdateSearch = (currentSavedSearch) => {
    return function (dispatch) {
        dispatch(
            evidenceDashboardSearch.form.actionCreators.submit((formData) => {
                dispatch(
                    evidenceDashboardSearch.actionCreators.search(
                        {
                            formData,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                ).then((response) => {
                    // Hack: revert executedSavedSearchToUpdate back to currentSavedSearch before updating
                    dispatch(
                        evidenceDashboardSearch.actionCreators.setExecutedSavedSearchToUpdate(
                            currentSavedSearch
                        )
                    );
                    dispatch(
                        evidenceDashboardSearch.actionCreators.updateSavedSearch(
                            response.payload.query
                        )
                    );
                });
            })
        );
    };
};

export default combineReducers({
    search: evidenceDashboardSearch.reducers.uiReducer,
    exports: exportsUiReducer,
});

import React from 'react';

export default function AdvancedSearchErrorBar({
    onActionTextClick,
    message: { readOnlyText = '', actionText = '' },
}) {
    return (
        <div className="advanced-search-error-bar">
            <span>{`${readOnlyText} `}</span>
            {actionText && (
                <span onClick={onActionTextClick} className="search-error-action-text">
                    {actionText}
                </span>
            )}
        </div>
    );
}

import React from 'react';
import keymirror from 'keymirror';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { includes } from 'lodash';

import * as fields from '~/client-common/core/enums/universal/fields';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatSubdivsionAttributesForLocationEntityLinkSelector } from '~/client-common/core/domain/locations/state/ui';
import { formatCombinedSubdivisionsLabel } from '~/client-common/helpers/subdivisionHelpers';
import { agencyProfileByIdSelector } from '~/client-common/core/domain/agency-profiles/state/data';

import SummaryList from '../../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../../legacy-redux/components/summaries/SummaryRowDate';

export const locationEntityLinkSummaryVariants = keymirror({
    WITHOUT_DESCRIPTION: null,
    WITHOUT_DESCRIPTION_AND_START_DATE_UTC: null,
});

export const LocationEntityLinkSummary = connect(
    createStructuredSelector({
        formatFieldByName: formatFieldByNameSelector,
        formatAttributeById: formatAttributeByIdSelector,
        agencyProfileById: agencyProfileByIdSelector,
        formatSubdivsionAttributesForLocationEntityLink: formatSubdivsionAttributesForLocationEntityLinkSelector,
    })
)(
    ({
        locationEntityLink,
        contentWidth,
        labelWidth,
        formatFieldByName,
        formatAttributeById,
        agencyProfileById,
        formatSubdivsionAttributesForLocationEntityLink,
        variant,
    }) => {
        const combinedSubdivisionsLabel = formatCombinedSubdivisionsLabel(formatFieldByName);
        const reportingAgency = agencyProfileById(locationEntityLink.agencyId);
        return (
            <SummaryList labelWidth={labelWidth} contentWidth={contentWidth}>
                <FeatureFlagged flag="MULTI_AGENCY_SUBDIVISIONS_ENABLED">
                    {!!reportingAgency && (
                        <SummaryRow fieldName={fields.LOCATION_ENTITY_LINK_AGENCY_ID}>
                            {reportingAgency.agencyName}
                        </SummaryRow>
                    )}
                </FeatureFlagged>
                <SummaryRow label={combinedSubdivisionsLabel}>
                    {formatSubdivsionAttributesForLocationEntityLink(
                        locationEntityLink,
                        formatAttributeById
                    )}
                </SummaryRow>
                <SummaryRow fieldName={fields.LOCATION_ENTITY_LINK_COUNTY_CODE_ATTRIBUTE_ID}>
                    {formatAttributeById(locationEntityLink.countyCodeAttrId)}
                </SummaryRow>
                <SummaryRow fieldName={fields.LOCATION_ENTITY_LINK_TYPE_ATTR_ID}>
                    {formatAttributeById(locationEntityLink.typeAttrId)}
                </SummaryRow>
                <SummaryRow fieldName={fields.LOCATION_ENTITY_LINK_PROPERTY_TYPE_ATTR_ID}>
                    {formatAttributeById(locationEntityLink.propertyTypeAttrId)}
                </SummaryRow>
                <SummaryRow fieldName={fields.LOCATION_ENTITY_LINK_POSITION_ATTR_ID}>
                    {formatAttributeById(locationEntityLink.positionAttrId)}
                </SummaryRow>
                {includes(
                    [
                        locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION,
                        locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION_AND_START_DATE_UTC,
                    ],
                    variant
                ) ? null : (
                    <SummaryRow fieldName={fields.LOCATION_ENTITY_LINK_DESCRIPTION}>
                        {locationEntityLink.description}
                    </SummaryRow>
                )}
                <SummaryRowDate
                    fieldName={fields.LOCATION_ENTITY_LINK_ENTERED_DATE_UTC}
                    date={locationEntityLink.enteredDateUtc}
                    format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                />
                {variant !==
                    locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION_AND_START_DATE_UTC && (
                    <SummaryRowDate
                        fieldName={fields.LOCATION_ENTITY_LINK_START_DATE_UTC}
                        date={locationEntityLink.startDateUtc}
                        format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                    />
                )}
                {variant !==
                    locationEntityLinkSummaryVariants.WITHOUT_DESCRIPTION_AND_START_DATE_UTC && (
                    <SummaryRowDate
                        fieldName={fields.LOCATION_ENTITY_LINK_LINK_TYPE_LIVES_AT_END_DATE_UTC}
                        date={locationEntityLink.endDateUtc}
                        format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                    />
                )}
            </SummaryList>
        );
    }
);

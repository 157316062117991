import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { max, isEmpty } from 'lodash';
import { formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import abilitiesEnum from '~/client-common/enums/universal/abilitiesEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import {
    REPORT_REPORTING_EVENT_NUMBER,
    CAD_TICKET_CAD_AGENCY_EVENT_NUMBER,
    DISPLAY_ONLY_CAD_LABEL,
} from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { applicationSettingsSelector } from '~/client-common/core/domain/settings/state/data';
import { currentUserHasAbilitySelector } from '../../../core/current-user/state/ui';
import advancedSearchCadTickets from '../state/ui';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import StaleQueryNotificationBar from '../../core/components/StaleQueryNotificationBar';
import dateCellFactory from '../../../../legacy-redux/components/core/tables/elasticReportTable/dateCellFactory';
import InfoCell from '../../../../legacy-redux/components/core/tables/elasticCadTicketTable/InfoCell';
import SubdivisionCell from '../../../../legacy-redux/components/core/tables/elasticCadTicketTable/SubdivisionCell';
import LocationCell from '../../../../legacy-redux/components/core/tables/elasticCadTicketTable/LocationCell';
import PrimaryUnitCell from '../../../../legacy-redux/components/core/tables/elasticCadTicketTable/PrimaryUnitCell';
import InvolvedUnitsCell from '../../../../legacy-redux/components/core/tables/elasticCadTicketTable/InvolvedUnitsCell';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import { savedSearchIsStale } from '../../core/utils/savedSearchIsStale';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import { useDashboardScroll } from '../../core/hooks/useDashboardScroll';
import useOnSaveSearchToggleHandler from '../../core/hooks/useOnSaveSearchToggleHandler';

const StartDateUtcCell = dateCellFactory('startDateUtc');

const strings = componentStrings.search.AdvancedSearchCadTicketsResults;

// 107 is the height of the table header
const ClippableSearchTable = clippable({ bottomBuffer: 107 })(Table);

function AdvancedSearchCadTicketsResults({
    // Current Query and Search Results
    results = [],
    query: { savedSearchId, from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,

    // Table UI State
    tableLoading,
    searchFilters,
    highlightedRows,

    // Event Handlers
    handleDateOptionClick,
    handleDateSortTypeClick,
    handlePaginationClick,
    handleSizeChange,
    handleRowClick,

    // Saved Search
    savedSearchErrorMessage,
    savedSearches,

    // field names
    formatFieldByName,
    router,

    applicationSettings,
}) {
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: advancedSearchCadTickets,
        isScrollToElement: true,
    });
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(advancedSearchCadTickets);

    let transformedResults = [];
    if (applicationSettings.CAD_MULTI_REN_GENERATION_ENABLED) {
        transformedResults = results.flatMap((result) => {
            if (isEmpty(result.rens)) {
                return result;
            }
            return result.rens.map((ren) => {
                return { ...result, reportingEventNumber: ren };
            });
        });
    }

    const cadDisplayName = useFields(DISPLAY_ONLY_CAD_LABEL)[DISPLAY_ONLY_CAD_LABEL];
    return (
        <div>
            {savedSearchIsStale(savedSearches, savedSearchId) && <StaleQueryNotificationBar />}
            <ClippableSearchTable
                data={
                    applicationSettings.CAD_MULTI_REN_GENERATION_ENABLED
                        ? transformedResults
                        : results
                }
                onRowClick={handleRowClick(getCurrentScrollPosition)}
                sortKey={currentSortKey}
                sortType={currentSortType}
                disableBody={tableLoading}
                highlightedRows={highlightedRows}
                noRowsText={strings.noResults}
                containerClassName="search-results-table"
                rowComponent={ElasticResultRow}
                groupingData={
                    applicationSettings.CAD_MULTI_REN_GENERATION_ENABLED
                        ? {
                              field: 'agencyEventNumber',
                              showLoadMore: true,
                              showCollapseButton: false,
                              displayMessage: (groupedRow) =>
                                  `${formatFieldByName(CAD_TICKET_CAD_AGENCY_EVENT_NUMBER)} ${
                                      groupedRow[0]['agencyEventNumber'] ?? ''
                                  }`,
                          }
                        : undefined
                }
            >
                <TableHeader>
                    <AdvancedSearchResultsTableHeader
                        from={from}
                        to={from + results.length}
                        searchSize={size}
                        onSizeChange={handleSizeChange}
                        totalResults={totalResults}
                        currentPage={currentPage}
                        searchFilters={searchFilters}
                        onPaginationClick={(lastPage, nextPage) =>
                            handlePaginationClick(nextPage, size)
                        }
                        isSearchSavable={true}
                        onSaveSearchToggleClick={onSaveSearchToggle}
                        savedSearchId={savedSearchId}
                        savedSearchErrorMessage={savedSearchErrorMessage}
                        searchModule={advancedSearchCadTickets}
                        savedSearches={savedSearches}
                        onSaveSearch={onSaveSearchToggle}
                    />
                    <TableColumns>
                        <OptionsTableColumn
                            display={({ display }) => display}
                            columnKey="startDateUtc"
                            activeValue={currentSortKey}
                            activeSortType={currentSortType}
                            onOptionClick={handleDateOptionClick}
                            onSortTypeClick={handleDateSortTypeClick}
                            width={126}
                            popoutWidth={159}
                        >
                            <OptionsTableColumnOption
                                display={strings.relevance.label}
                                value={sortKeyEnum.RELEVANCE}
                                columnKey="startDateUtc"
                            />
                            <OptionsTableColumnOption
                                display={strings.eventDate.label}
                                value={sortKeyEnum.CAD_TICKET_START_DATE_UTC}
                                columnKey="startDateUtc"
                                sortOptions={[
                                    {
                                        display: strings.eventDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.eventDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={strings.cadRen.label(
                                cadDisplayName,
                                formatFieldByName(REPORT_REPORTING_EVENT_NUMBER)
                            )}
                            columnKey="info"
                            width={126}
                        />
                        <TableColumn
                            display={strings.subdivision.label}
                            columnKey="subdivision"
                            width={126}
                        />
                        <TableColumn
                            display={strings.location.label}
                            columnKey="location"
                            width={177}
                        />
                        <TableColumn
                            display={strings.primaryUnit.label}
                            columnKey="primaryUnit"
                            width={177}
                        />
                        <TableColumn
                            display={strings.involvedUnits.label}
                            columnKey="involvedUnits"
                            width={196}
                        />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <StartDateUtcCell columnKey="startDateUtc" />
                    <InfoCell columnKey="info" />
                    <SubdivisionCell columnKey="subdivision" />
                    <LocationCell columnKey="location" cadDisplayName={cadDisplayName} />
                    <PrimaryUnitCell columnKey="primaryUnit" />
                    <InvolvedUnitsCell columnKey="involvedUnits" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    query: advancedSearchCadTickets.selectors.currentQuerySelector,
    highlightedRows: advancedSearchCadTickets.selectors.highlightedRowsSelector,
    results: advancedSearchCadTickets.selectors.currentResultsViewModelsSelector,
    totalCount: advancedSearchCadTickets.selectors.totalCountSelector,
    tableLoading: advancedSearchCadTickets.selectors.tableLoadingSelector,
    searchFilters: advancedSearchCadTickets.selectors.filtersSelector,
    activeColumnKeys: advancedSearchCadTickets.selectors.activeColumnKeysSelector,
    savedSearchErrorMessage: advancedSearchCadTickets.selectors.loadSavedSearchErrorMessageSelector,
    formatFieldByName: formatFieldByNameSelector,
    applicationSettings: applicationSettingsSelector,
});

const mapDispatchToProps = (dispatch) => ({
    handleRowClick(getCurrentScrollPosition) {
        return dispatch((dispatch, getState) => {
            const state = getState();
            const canCreateReports = currentUserHasAbilitySelector(state)(
                abilitiesEnum.REPORTING.CREATE_REPORTS
            );
            // Purposefully do the 'can create reports' check here, because we want to
            // pass in undefined if the user does not have the ability to create reports.
            // This is because the underlying Table component will handle everything we
            // want (such as, not changing the mouse cursor, not highlighting the results
            // row, and not allowing the row to be clickable) all OOTB for us, if we do
            // not pass it an `onRowClick` prop.
            if (!canCreateReports) {
                return;
            }

            return (elasticCadTicket, rowIndex) => {
                dispatch(
                    advancedSearchCadTickets.actionCreators.openSearchResult(
                        elasticCadTicket,
                        rowIndex,
                        getCurrentScrollPosition()
                    )
                );
            };
        });
    },
    handleDateOptionClick({ columnKey, value, sortOptions }) {
        dispatch(advancedSearchCadTickets.actionCreators.setActiveColumnKey('date', columnKey));
        dispatch(
            advancedSearchCadTickets.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleDateSortTypeClick(sortType) {
        dispatch(advancedSearchCadTickets.actionCreators.search({ sortType }));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            advancedSearchCadTickets.actionCreators.search({
                from: nextPage * size - size,
                size,
            })
        );
    },
    handleSizeChange(size) {
        dispatch(advancedSearchCadTickets.actionCreators.search({ from: 0, size }));
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearchCadTicketsResults);

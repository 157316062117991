import PropTypes from 'prop-types';
import React from 'react';
import ReactSlider from 'react-slider';
import {
    compose,
    defaultProps,
    getContext,
    setDisplayName,
    setPropTypes,
    withState,
} from 'recompose';
import classNames from 'classnames';
import { get, noop, throttle } from 'lodash';
import styled from 'styled-components';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import { formatSimpleRange } from '~/client-common/helpers/rangeHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { Tooltip } from '../../components/tooltip';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Icon, { iconTypes } from '../../components/Icon';

import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import {
    formatAgeRange,
    formatHeightRange,
    formatWeightRange,
    formatVehicleYearRange,
} from '../../../../legacy-redux/helpers/rangeHelpers';
import {
    SLIDER_THROTTLE,
    AGE_RANGE_SLIDER_MAX,
    HEIGHT_RANGE_SLIDER_MIN,
    HEIGHT_RANGE_SLIDER_MAX,
    WEIGHT_RANGE_SLIDER_MIN,
    WEIGHT_RANGE_SLIDER_MAX,
    WEIGHT_RANGE_SLIDER_STEP,
    VEHICLE_YEAR_RANGE_SLIDER_MIN,
    VEHICLE_YEAR_RANGE_SLIDER_MAX,
} from '../../../../legacy-redux/configs/formsConfig';
import testIds from '../../../../core/testIds';
import FormElement from './FormElement';

const { connectRRFMultiFieldInput } = reactReduxFormHelpers;
const strings = componentStrings.forms;

const ClearButton = styled(Button)`
    margin-left: 8px;
`;
const ExtraLabelContent = styled.span`
    float: right;
    font-size: var(--arc-fontSizes-sm);
`;

/**
 * Compute the default display value for a slider. If a default value isn't
 *   provided, determine one using the slider's other props.
 * @param  {number|number[]} [defaultValue]
 * @param  {boolean}         isRange
 * @param  {number}          [min]          Required for a range slider.
 * @param  {number}          [max]          Required for a range slider.
 * @return {number|number[]}
 */
function computeDefaultDisplayValue(defaultValue, isRange, min, max) {
    if (!isUndefinedOrNull(defaultValue)) {
        return defaultValue;
    }
    return isRange ? [min, max] : min;
}

/**
 * Compute the current display value of a slider. The display value can be
 *   different from the actual value for a range slider that is unbounded; for
 *   example, to represent 70-80+ years old in the age range slider, the display
 *   value would be `[70, 80]` .
 * @param  {number|number[]} [value]
 * @param  {boolean}         isRange
 * @param  {Object}          [rangeStart] Required for a range slider.
 * @param  {Object}          [rangeEnd]   Required for a range slider.
 * @param  {number|number[]} defaultValue Result of
 *   `computeDefaultDisplayValue()`.
 * @return {number|number[]}
 */
function computeDisplayValue(value, isRange, rangeStart, rangeEnd, defaultValue) {
    if (!isRange) {
        return !isUndefinedOrNull(value) ? value : defaultValue;
    } else {
        return [
            !isUndefinedOrNull(get(rangeStart, 'value')) ? rangeStart.value : defaultValue[0],
            !isUndefinedOrNull(get(rangeEnd, 'value')) ? rangeEnd.value : defaultValue[1],
        ];
    }
}

/**
 * Return whether two display values are equal.
 * @param  {number|number[]} value1
 * @param  {number|number[]} value2
 * @param  {boolean}         isRange
 * @return {boolean}
 */
function displayValuesAreEqual(value1, value2, isRange) {
    return !isRange ? value1 === value2 : value1[0] === value2[0] && value1[1] === value2[1];
}

/**
 * Default function for computing the value label for a slider.
 * @param  {number|number[]} options.value        Result of
 *   `computeDisplayValue()`.
 * @param  {boolean}         options.isRange
 * @param  {number}          [options.rangeStart] Result of
 *   `computeDisplayValue()`. Required for a range slider.
 * @param  {number}          [options.rangeEnd]   Result of
 *   `computeDisplayValue()`. Required for a range slider.
 * @return {string}
 */
function defaultValueLabel({ value, isRange, rangeStart, rangeEnd }) {
    return isRange ? `${rangeStart}-${rangeEnd}` : `${value}`;
}

/**
 * Handle a change to the slider value(s). For an unbounded range slider, the
 *   handlers receive a value of `undefined` at each unbounded end. This
 *   function is throttled to reduce lag in the UI.
 * @param  {number|number[]} value Number for a single value, array for a range.
 * @param  {Object}          props
 */
const handleChange = throttle(
    (
        value,
        { isRange, min, minBounded, max, maxBounded, rangeStart, rangeEnd, onChange, onInputChange }
    ) => {
        if (isRange) {
            // update the range values if needed
            if (!value || (value[0] === min && value[1] === max)) {
                // if the range values become the entire [min, max] range, reset
                // them to undefined so the values don't pollute form state or get
                // included in server requests
                value = [undefined, undefined];
            } else if (value[0] === min && !minBounded) {
                // range interval is unbounded at the left
                value = [undefined, value[1]];
            } else if (value[1] === max && !maxBounded) {
                // range interval is unbounded at the right
                value = [value[0], undefined];
            }

            // call field handlers
            const [rangeStartValue, rangeEndValue] = value;
            rangeStart.onChange(rangeStartValue);
            rangeEnd.onChange(rangeEndValue);
        }

        // call custom handler
        const handlerValue = isRange
            ? {
                  rangeStart: get(value, 0),
                  rangeEnd: get(value, 1),
              }
            : value;
        onChange(handlerValue);
        // call context handler
        onInputChange(handlerValue);
    },
    SLIDER_THROTTLE
);

// a slider knob that shows a tooltip of its value on hover
function SliderKnob(props) {
    const { setActive, ...otherProps } = props;

    return (
        <Tooltip side="top" {...otherProps}>
            <div
                data-test-id={testIds.ADVANCED_SEARCH_PERSON_PROFILE_AGE_RANGE_SLIDER_KNOB}
                className="mark43-slider-knob"
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
            />
        </Tooltip>
    );
}

// eslint-disable-next-line import/no-mutable-exports
let Slider = function ({
    isRange = false,
    min,
    minBounded = true,
    max,
    maxBounded = true,
    step = 1,
    valueLabel,
    defaultValue,
    value,
    fields: { rangeStart, rangeEnd } = {},
    disabled = false,
    clearable = true,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    onInputChange = noop,
    onInputFocus = noop,
    onInputBlur = noop,
    // state
    setTouched,
    active,
    setActive,
    testId,
    ...otherProps
}) {
    // compute the default display value and the current display value
    defaultValue = computeDefaultDisplayValue(defaultValue, isRange, min, max);
    value = computeDisplayValue(value, isRange, rangeStart, rangeEnd, defaultValue);

    const hasDefaultValue = displayValuesAreEqual(value, defaultValue, isRange);
    valueLabel = valueLabel || defaultValueLabel; // function

    const [rangeStartValue, rangeEndValue] = isRange ? value : [undefined, undefined];

    const clearButton = clearable && !disabled && !hasDefaultValue && (
        <Tooltip key="clear" side="top" content="Clear">
            <ClearButton
                className={buttonTypes.ICON_LINK}
                onClick={() =>
                    handleChange(undefined, {
                        isRange,
                        rangeStart,
                        rangeEnd,
                        onChange,
                        onInputChange,
                    })
                }
                iconLeft={<Icon size={11} color="lightGrey" type={iconTypes.CLOSE_X} />}
                hoveredIconLeft={<Icon size={11} color="cobaltBlue" type={iconTypes.CLOSE_X} />}
            />
        </Tooltip>
    );

    const extraLabelContent = (
        <ExtraLabelContent className="mark43-slider-extra-label">
            {valueLabel({
                isRange,
                min,
                max,
                value,
                rangeStart: rangeStartValue,
                rangeEnd: rangeEndValue,
            })}
            {clearButton}
        </ExtraLabelContent>
    );

    const knob = isRange ? (
        [
            <SliderKnob key="rangeStart" content={rangeStartValue} setActive={setActive} />,
            <SliderKnob key="rangeEnd" content={rangeEndValue} setActive={setActive} />,
        ]
    ) : (
        <SliderKnob content={value} setActive={setActive} />
    );

    return (
        <FormElement
            className={classNames('mark43-slider-field', {
                range: isRange,
                active,
                'has-value': !hasDefaultValue,
                disabled,
            })}
            labelClassName=""
            extraLabelContent={extraLabelContent}
            testId={testId}
            value={value}
            {...otherProps}
        >
            <ReactSlider
                min={min}
                max={max}
                step={step}
                value={value}
                className="mark43-slider"
                handleClassName="mark43-slider-knob-tooltip"
                handleActiveClassName="mark43-slider-active-knob-tooltip"
                withBars={true}
                barClassName="mark43-slider-bar"
                pearling={true}
                disabled={disabled}
                onChange={(value) =>
                    handleChange(value, {
                        isRange,
                        min,
                        minBounded,
                        max,
                        maxBounded,
                        rangeStart,
                        rangeEnd,
                        onChange,
                        onInputChange,
                    })
                }
                onBeforeChange={() => {
                    setActive(true);
                    onFocus(value);
                    onInputFocus(value);
                }}
                onAfterChange={() => {
                    setTouched(true);
                    setActive(false);
                    onBlur(value);
                    onInputBlur(value);
                }}
            >
                {knob}
            </ReactSlider>
        </FormElement>
    );
};

Slider = compose(
    setDisplayName('Slider'),
    setPropTypes({
        isRange: PropTypes.bool,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        step: PropTypes.number,
        valueLabel: PropTypes.func,
        defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        rangeStart: PropTypes.object,
        rangeEnd: PropTypes.object,
        disabled: PropTypes.bool,
        clearable: PropTypes.bool,
        onChange: PropTypes.func,
    }),
    withState('touched', 'setTouched', false),
    withState('active', 'setActive', false),
    getContext({
        onInputChange: PropTypes.func,
        onInputFocus: PropTypes.func,
        onInputBlur: PropTypes.func,
    })
)(Slider);

/**
 * This component uses the third party library `react-slider` to create a
 *   slider, either a single value slider or a range slider of a range between
 *   two values. A range may be unbounded at either end.
 * @param {object}          [props]                 Other props not listed below
 *   get spread into the `FormElement` component.
 * @param {boolean}         [props.isRange=false]   Set to `true` to make this a
 *   range slider.
 * @param {number}          [props.min]             The minimum value at the
 *   left edge of the slider.
 * @param {boolean}         [props.minBounded=true] Whether the range interval
 *   is bounded to the left at `min`. If `false`, the interval effectively
 *   becomes `[Infinity, max]` so that when `min` is selected, the range start
 *   value is `undefined` rather than `min`.
 * @param {number}          [props.max]             The maximum value at the
 *   right edge of the slider.
 * @param {boolean}         [props.maxBounded=true] Whether the range interval
 *   is bounded to the right at `max`. If `false`, the interval effectively
 *   becomes `[min, Infinity]` so that when `max` is selected, the range end
 *   value is `undefined` rather than `max`.
 * @param {number}          [props.step=1]          The difference between
 *   adjacent values.
 * @param {function}        [props.valueLabel]      Display string for the value
 *   label that appears at the top right.
 * @param {number|number[]} [props.defaultValue]    The value(s) to set when the
 *   slider is cleared or initialized without a value. If not provided, the
 *   default value is `[min, max]` for a range and `min` for a single value.
 * @param {number|number[]} [props.value]           The current value, required
 *   only when this is a single value slider.
 * @param {Object}          [props.fields]          `rangeStart` and `rangeEnd`,
 *   required only when this is a range slider.
 * @param {boolean}         [props.disabled=false]  Whether the slider is
 *   disabled.
 * @param {boolean}         [props.clearable=true]  Whether to show an icon for
 *   resetting the value(s) to default.
 * @param {function}        [props.onChange]        Handler that gets run
 *   whenever the value changes, only when this is a single value slider.
 * @param {function}        [props.onFocus]         Handler that gets run
 *   whenever the slider (knobs or bars) is clicked or active.
 * @param {function}        [props.onBlur]          Handler that gets run
 *   whenever after the value(s) is changed.
 */
export default Slider;

/**
 * Range slider for a person's age.
 * @param {Object} props Same props as `Slider`.
 */
export const AgeRangeSlider = compose(
    setDisplayName('AgeRangeSlider'),
    defaultProps({
        isRange: true,
        label: strings.AgeRangeSlider.label,
        valueLabel: formatAgeRange,
        min: 0,
        max: AGE_RANGE_SLIDER_MAX,
        maxBounded: false,
    })
)(Slider);

export const RRFAgeRangeSlider = compose(
    setDisplayName('RRFAgeRangeSlider'),
    defaultProps({
        paths: {
            rangeStart: 'ageRangeStart',
            rangeEnd: 'ageRangeEnd',
        },
    }),
    connectRRFMultiFieldInput
)(AgeRangeSlider);

/**
 * Range slider for a person's height.
 * @param {Object} props Same props as `Slider`.
 */
export const HeightRangeSlider = compose(
    setDisplayName('HeightRangeSlider'),
    defaultProps({
        isRange: true,
        label: strings.HeightRangeSlider.label,
        valueLabel: formatHeightRange,
        min: HEIGHT_RANGE_SLIDER_MIN,
        minBounded: false,
        max: HEIGHT_RANGE_SLIDER_MAX,
        maxBounded: false,
    })
)(Slider);

export const RRFHeightRangeSlider = compose(
    setDisplayName('RRFHeightRangeSlider'),
    defaultProps({
        paths: {
            rangeStart: 'heightRangeMin',
            rangeEnd: 'heightRangeMax',
        },
    }),
    connectRRFMultiFieldInput
)(HeightRangeSlider);

/**
 * Range slider for a person's weight.
 * @param {Object} props Same props as `Slider`.
 */
export const WeightRangeSlider = compose(
    setDisplayName('WeightRangeSlider'),
    defaultProps({
        isRange: true,
        label: strings.WeightRangeSlider.label,
        valueLabel: formatWeightRange,
        min: WEIGHT_RANGE_SLIDER_MIN,
        minBounded: false,
        max: WEIGHT_RANGE_SLIDER_MAX,
        maxBounded: false,
        step: WEIGHT_RANGE_SLIDER_STEP,
    })
)(Slider);

export const RRFWeightRangeSlider = compose(
    setDisplayName('RRFWeightRangeSlider'),
    defaultProps({
        paths: {
            rangeStart: 'weightRangeMin',
            rangeEnd: 'weightRangeMax',
        },
    }),
    connectRRFMultiFieldInput
)(WeightRangeSlider);

/**
 * Range slider for a vehicle's year.
 * @param {Object} props Same props as `Slider`.
 */
export const VehicleYearRangeSlider = compose(
    setDisplayName('VehicleYearRangeSlider'),
    defaultProps({
        isRange: true,
        label: strings.VehicleYearRangeSlider.label,
        valueLabel: formatVehicleYearRange,
        min: VEHICLE_YEAR_RANGE_SLIDER_MIN,
        minBounded: false,
        max: VEHICLE_YEAR_RANGE_SLIDER_MAX,
        maxBounded: false,
    })
)(Slider);

export const RRFVehicleYearRangeSlider = compose(
    setDisplayName('RRFVehicleYearRangeSlider'),
    defaultProps({
        paths: {
            rangeStart: 'minYearOfManufacture',
            rangeEnd: 'maxYearOfManufacture',
        },
    }),
    connectRRFMultiFieldInput
)(VehicleYearRangeSlider);

/**
 * Range slider for an officer's years of service.
 * @param {Object} props Same props as `Slider`.
 */
const YearsOfServiceSlider = compose(
    setDisplayName('YearsOfServiceSlider'),
    defaultProps({
        isRange: true,
        label: strings.YearsOfServiceSlider.label,
        valueLabel: formatSimpleRange,
        min: 0,
        minBounded: true,
        max: 50,
        maxBounded: true,
    })
)(Slider);

export const RRFYearsOfServiceSlider = compose(
    setDisplayName('RRFYearsOfServiceSlider'),
    defaultProps({
        paths: {
            rangeStart: 'officerYearsOfServiceMin',
            rangeEnd: 'officerYearsOfServiceMax',
        },
    }),
    connectRRFMultiFieldInput
)(YearsOfServiceSlider);

import {
    NEXUS_STATE_PROP as ARRESTS_NEXUS_STATE_PROP,
    arrestByIdSelector,
} from '../../../arrests/state/data';
import { storeChargeDispositionInfoFields } from '../../../charges/state/data';
import getReportCardBundleResource from '../../resources/reportCardBundleResource';

export function replaceBookingCardBundle({ reportId, bookingCardBundle }) {
    return function (dispatch, getState, { nexus: { withEntityItems } }) {
        const resource = getReportCardBundleResource();

        return resource
            .upsertBookingCard(reportId, bookingCardBundle)
            .then((updatedBookingCardBundle) => {
                const state = getState();
                const {
                    arrestId,
                    lockupNumber,
                    afisNumber,
                    lockupLocation,
                    lockupDateUtc,
                    charges,
                } = updatedBookingCardBundle;
                const existingArrest = arrestByIdSelector(state)(arrestId);
                const mergedUpdatedArrest = {
                    ...existingArrest,
                    lockupNumber,
                    afisNumber,
                    lockupLocation,
                    lockupDateUtc,
                };

                dispatch(
                    withEntityItems(
                        {
                            [ARRESTS_NEXUS_STATE_PROP]: [mergedUpdatedArrest],
                        },
                        { type: 'UPSERT_BOOKING_CARD_BUNDLE' }
                    )
                );
                dispatch(storeChargeDispositionInfoFields(charges));

                return updatedBookingCardBundle;
            });
    };
}

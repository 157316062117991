import {
    ReplaceSecurityClassificationRequest,
    SecurityClassificationsResource,
} from '@mark43/rms-api';
import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';
import { SecurityClassificationOwner } from '../types';

export default createResource({
    name: 'Security Classification Resource',
    methods: {
        replaceSecurityClassification(
            ownerProps: SecurityClassificationOwner,
            data?: ReplaceSecurityClassificationRequest
        ) {
            const { ownerType, ownerId } = ownerProps;
            return req<SecurityClassificationsResource.ReplaceSecurityClassification>({
                method: 'PUT',
                url: `security_classifications/${ownerType}/${ownerId}/`,
                data: data ? { data } : undefined,
            });
        },
    },
});

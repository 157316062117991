import { storeUserSearchResults } from '~/client-common/core/domain/user-search-results/state/data';
import { searchUsers as searchUsersBase } from './searchActions';

/**
 * Search for users and store the search results in data state.
 * @param  {string}     query
 * @param  {Object}     options
 *         {string}     effectiveDate   Moment string that specifies the duty
 *                                      status effective date that will be used
 *                                      to query for `userSearchResult`s.
 *                                      Only *active* users for the specified
 *                                      `effectiveDate` will be queried for.
 * @return {Promise<Object[]>} Roles.
 */
export function searchUsers(query, { effectiveDate }) {
    return function (dispatch) {
        return dispatch(searchUsersBase(query, { effectiveDate })).then((users) => {
            if (users.length > 0) {
                dispatch(storeUserSearchResults(users));
            }
            return users;
        });
    };
}

import { Folder } from '@mark43/rms-api';
import componentStrings from '../../../strings/componentStrings';

const attachmentTableColumnStrings = componentStrings.cases.caseAttachments.AttachmentTableColumns;

export const translateToFolderRowView = (folder: Folder, caseFieldName: string) => ({
    ...folder,
    displayName: folder.name,
    type: attachmentTableColumnStrings.folderType,
    description: caseFieldName,
});

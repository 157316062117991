import createNormalizedModule from '../../../../utils/createNormalizedModule';

// this model is not in mark43-resources
const cadDesktopConfigsModule = createNormalizedModule<unknown>({
    type: 'cadDesktopConfigs',
});

// ACTIONS
export const storeCadDesktopConfigs = cadDesktopConfigsModule.actionCreators.storeEntities;

export const deleteCadDesktopConfig = cadDesktopConfigsModule.actionCreators.deleteEntity;

// SELECTORS
export const cadDesktopConfigsSelector = cadDesktopConfigsModule.selectors.entitiesSelector;

// REDUCER
export default cadDesktopConfigsModule.reducerConfig;

import { EntityTypeEnum, ProductModuleEnum } from '@mark43/rms-api';
import React, { Component } from 'react';
import styled from 'styled-components';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import ProductModuled from '~/client-common/core/domain/product-modules/components/ProductModuled';

import RecentEntities from '../../recent-entities/components/RecentEntities';
import { NotepadPill } from '../../../mobile/notepad/components/NotepadPill';
import { abilitiesEnum, OnlyWithAbility } from '../../abilities';
import Pill from '../../components/Pill';
import { BodyMediumText } from '../../components/typography';
import _ElasticPersonPill from './ElasticPersonPill';
import { PersonSearchToAddForm } from './PersonSearchToAddForm';

const SearchFromDexPill = styled(Pill)`
    margin-top: 10px;
    justify-content: space-between;
`;

const StyledNotepadPill = styled(NotepadPill)`
    margin-top: 10px;
`;

const ElasticPersonPill = styled(_ElasticPersonPill)`
    margin-top: 10px;
`;

class PersonSearchToAdd extends Component {
    renderRecentEntity = ({ recentEntityId }) => (
        <ElasticPersonPill
            onClick={this.props.onClickRecentEntity}
            id={recentEntityId}
            key={recentEntityId}
            onIdentifyClick={this.props.onClickIdentify}
        />
    );
    render() {
        const {
            onNotepadEntityClick,
            onClickSearch,
            loading,
            onClickRecentEntity,
            ownerType,
            ownerId,
            renForRecents,
            contextId,
            linkType,
            hideRecentEntities,
            onClickSearchFromDex,
            searchFromDexButtonTestId,
            searchFromDexString,
        } = this.props;

        return (
            <div>
                <PersonSearchToAddForm onClickSearch={onClickSearch} loading={loading} />
                {!hideRecentEntities && (
                    <RecentEntities
                        renForRecents={renForRecents}
                        linkType={linkType}
                        ownerType={ownerType}
                        ownerId={ownerId}
                        contextId={contextId}
                        entityType={EntityTypeEnum.PERSON_PROFILE.name}
                        onClickRecentEntity={onClickRecentEntity}
                        renderRecentEntity={this.renderRecentEntity}
                    />
                )}
                <StyledNotepadPill
                    onEntityClick={onNotepadEntityClick}
                    entityType={EntityTypeEnum.PERSON_PROFILE.name}
                    id={overlayIdEnum.NOTEPAD_LINKS_OVERLAY_PERSON_OVERLAY}
                />
                {onClickSearchFromDex && (
                    <ProductModuled productModule={ProductModuleEnum.RMS_DEX.name}>
                        <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_MAKE_INQUIRIES}>
                            <OnlyWithAbility has={abilitiesEnum.RMS_DEX.RMS_DEX_VIEW_RESULTS}>
                                <SearchFromDexPill
                                    justifyContent="normal"
                                    hoverable={true}
                                    showChevron={true}
                                    onClick={onClickSearchFromDex}
                                    testId={searchFromDexButtonTestId}
                                >
                                    <BodyMediumText fontWeight="semibold">
                                        {searchFromDexString}
                                    </BodyMediumText>
                                </SearchFromDexPill>
                            </OnlyWithAbility>
                        </OnlyWithAbility>
                    </ProductModuled>
                )}
            </div>
        );
    }
}

export default PersonSearchToAdd;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, setDisplayName } from 'recompose';

import { nibrsOffenseCodeIdOptionsSelector } from '~/client-common/core/domain/nibrs-offense-codes/state/ui';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { arbiterMFTInput } from '../../../arbiter';
import reactReduxFormHelpers from '../../../../../legacy-redux/helpers/reactReduxFormHelpers';
import Select from './Select';

const { connectRRFInput } = reactReduxFormHelpers;
const { label } = componentStrings.forms.select.NibrsCodeSelect;

const NibrsOffenseCodeIdSelect = compose(
    setDisplayName('NibrsOffenseCodeIdSelect'),
    connect(
        createStructuredSelector({
            options: nibrsOffenseCodeIdOptionsSelector,
        })
    )
)(function NibrsOffenseCodeIdSelect({ options, ...otherProps }) {
    return <Select label={label} options={options} {...otherProps} />;
});

export const RRFNibrsOffenseCodeIdSelect = connectRRFInput(NibrsOffenseCodeIdSelect);
export const ArbiterMFTNibrsOffenseCodeIdSelect = arbiterMFTInput(NibrsOffenseCodeIdSelect);

import { RefContextEnum } from '@mark43/rms-api';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_CUSTODY_LABEL } from '~/client-common/core/enums/universal/fields';
import formsRegistry from '../../../../core/formsRegistry';
import { RmsDispatch } from '../../../../core/typings/redux';
import { iconTypes } from '../../../core/components/Icon';
import Modal from '../../../core/overlays/components/Modal';
import OverlayButton, { buttonTypes } from '../../../core/overlays/components/OverlayButton';
import { deleteChainOfCustodyEvent } from '../state/data/chainOfCustodyEvent';
import DeleteChainEventForm from './DeleteChainEventForm';

const strings = componentStrings.evidence.chainOfCustody.DeleteChainEventModal;

const DeleteChainEventModal: React.FC<{ chainEventId: number }> = ({ chainEventId }) => {
    const dispatch = useDispatch<RmsDispatch>();
    const overlayId = overlayIdEnum.DELETE_CHAIN_OF_CUSTODY_EVENT_OVERLAY;

    const handleSave = useCallback(() => {
        const form = formsRegistry.get(RefContextEnum.FORM_DELETE_CHAIN_EVENT.name);

        return form
            ?.submit()
            .then(() => {
                const reasonForDeletion = form.getState().model.itemRequestReasonForDeletion;
                return dispatch(deleteChainOfCustodyEvent(chainEventId, reasonForDeletion));
            })
            .then(() => form.resetModel());
    }, [chainEventId, dispatch]);

    const custodyLabel = useFields(DISPLAY_ONLY_CUSTODY_LABEL)[DISPLAY_ONLY_CUSTODY_LABEL];

    return (
        <Modal
            buttonElement={
                <OverlayButton
                    className={buttonTypes.ICON_LINK}
                    iconLeft={iconTypes.TRASH_CAN}
                    id={overlayIdEnum.DELETE_CHAIN_OF_CUSTODY_EVENT_OVERLAY}
                />
            }
            id={overlayId}
            title={strings.title(custodyLabel)}
            okText={strings.confirmButton}
            onSave={handleSave}
        >
            <DeleteChainEventForm />
        </Modal>
    );
};

export default DeleteChainEventModal;

import {
    ComplianceExportQuery,
    NibrsExportQuery,
    ReportPrintingTemplate,
    PrintreportResource,
} from '@mark43/rms-api';

import { req } from '../../../../lib/ajax';
import { createResource } from '../../../../lib/resources/Resource';

// TODO PRINTING-REFACTOR evalute the "DI" pattern used here
const BASE_PATH = 'print/report';

export default createResource({
    name: 'Report Printing Resource',
    methods: {
        // upserts the given arrays, does not do a "replace all"
        upsertReportPrintingTemplates(reportPrintingTemplates: ReportPrintingTemplate[]) {
            return req<PrintreportResource.UpsertReportPrintingTemplates>({
                method: 'PUT',
                url: `${BASE_PATH}/templates`,
                data: reportPrintingTemplates,
            });
        },

        getReportPrintingTemplates() {
            return req<PrintreportResource.GetReportPrintingTemplates>({
                method: 'GET',
                url: `${BASE_PATH}/templates`,
            });
        },

        deleteReportPrintingTemplate(id: number) {
            return req<PrintreportResource.DeleteReportPrintingTemplate>({
                method: 'DELETE',
                url: `${BASE_PATH}/template/${id}/`,
            });
        },

        generateComplianceExportFinal(data: ComplianceExportQuery) {
            return req<PrintreportResource.GenerateComplianceExportFinal>({
                method: 'POST',
                url: `${BASE_PATH}/compliance/final`,
                data,
            });
        },

        generateComplianceExportDraft(data: ComplianceExportQuery) {
            return req<PrintreportResource.GenerateComplianceExportPreview>({
                method: 'POST',
                url: `${BASE_PATH}/compliance/draft`,
                data,
            });
        },

        generateFinalNibrsExport(data: NibrsExportQuery) {
            return req<PrintreportResource.GenerateNibrsExport>({
                method: 'POST',
                url: `${BASE_PATH}/nibrs/final`,
                data,
            });
        },

        generateNibrsTestExport(data: NibrsExportQuery, { async = false } = {}) {
            return req<PrintreportResource.GenerateNibrsErrors>({
                method: 'POST',
                url: `${BASE_PATH}/nibrs/test`,
                data,
                ...(async
                    ? {
                          params: {
                              async: true,
                          },
                      }
                    : {}),
            });
        },
    },
});

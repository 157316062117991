import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { compose, withPropsOnChange, withHandlers } from 'recompose';

import { createSelector, createStructuredSelector } from 'reselect';

import styled from 'styled-components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { inventoryViewModelByIdSelector } from '~/client-common/core/domain/inventories/state/ui';
import UnderSubheader from '../../../core/components/UnderSubheader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import InventoryItemIdentifierCell from '../../search-results/components/InventoryItemIdentifierCell';
import InventoryItemIdCell from '../../search-results/components/InventoryItemIdCell';
import StorageLocationCell from '../../search-results/components/StorageLocationCell';
import _SearchResults from '../../search-results/containers/SearchResults';
import _InventoryDetails from '../components/InventoryDetails';
import _InventoryNavigation from '../components/InventoryNavigation';
import SearchResultsTableHeader from '../components/SearchResultsTableHeader';

import {
    sortInventoryBySortKey,
    inventorySortKeySelector,
    inventorySortTypeSelector,
    inventoryItemViewModelsByInventoryIdSelector,
} from '../state/ui';

const InventoryNavigation = styled(_InventoryNavigation)`
    background-color: ${(props) => props.theme.colors.white};
    left: 0;

    .wrapper {
        width: 970px;
    }
`;

const InventoryDetails = styled(_InventoryDetails)`
    background-color: ${(props) => props.theme.colors.white};
    margin: 0;
    padding: 20px;
    width: 100%;
`;

const SearchResults = styled(_SearchResults)`
    background-color: ${(props) => props.theme.colors.white};
    padding: 0 0px 77px 0px;
    width: 100%;

    tr.cobalt-table-row {
        display: table-row !important;
    }

    th.cobalt-table-column,
    td.cobalt-table-cell {
        display: table-cell !important;
    }
`;

const Status = styled.div`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const Inventory = ({
    handleSortKeyClickAction,
    inventoryViewModel,
    queryResultsSelector,
    router,
    type,
}) => {
    /**
     * TODO we need to update the SearchResults API to be less restrictive in such that multiple properties can
     * be passed into a column function.  Currently we are limited to just the view model object.
     * Hence the below implementation as, the 'type' property cannot be passed in.
     */
    const InventoryItemStatusCell = (inventoryItem) => {
        const {
            missing,
            none,
            scanned,
            unexpected,
            unexpectedForAutoUpdate,
            autoUpdatedLocation,
        } = componentStrings.evidence.inventory.Inventory[type].labels;

        const viewModel = getViewModelProperties(inventoryItem);

        const status = {
            SCANNED: scanned,
            MISSING: missing,
            NONE: none,
            UNEXPECTED: unexpected,
            UNEXPECTED_FOR_AUTO_UPDATE: unexpectedForAutoUpdate,
            AUTO_UPDATED_LOCATION: autoUpdatedLocation,
        };

        return <Status>{status[viewModel.status] || status.none}</Status>;
    };

    /**
     * TODO we need to update the SearchResults API to be less restrictive in such that multiple properties can
     * be passed into a column function.  Currently we are limited to just the view model object.
     * Hence the below implementation as, the 'type' property cannot be passed in.
     */
    const SearchResultsTableBody = () => (
        <TableBody>
            <InventoryItemIdCell columnKey="itemId" />

            <InventoryItemStatusCell columnKey="status" />

            <InventoryItemIdentifierCell columnKey="itemIdentifier" />

            <StorageLocationCell columnKey="storageLocation" />
        </TableBody>
    );

    return (
        <>
            <InventoryNavigation
                className="mark43-subheader-container"
                returnToUrl={'/evidence/inventories'}
                viewModel={inventoryViewModel}
            />

            <UnderSubheader>
                <InventoryDetails type={type} viewModel={inventoryViewModel} />

                <SearchResults
                    TableHeader={SearchResultsTableHeader}
                    TableBody={SearchResultsTableBody}
                    handleRowClick={({ masterItemId }) =>
                        router.push(`/profiles/property/${masterItemId}/details`)
                    }
                    handleSortKeyClickAction={handleSortKeyClickAction}
                    queryResultsSelector={queryResultsSelector}
                    querySortKeySelector={inventorySortKeySelector}
                    querySortTypeSelector={inventorySortTypeSelector}
                    showTableHeader={false}
                />
            </UnderSubheader>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    inventoryViewModelById: inventoryViewModelByIdSelector,
});

const mapPropsOnChange = ({ inventoryViewModelById, params: { id: inventoryIdStr } }) => {
    const inventoryId = parseInt(inventoryIdStr);
    const inventoryViewModel = inventoryViewModelById(inventoryId);
    return {
        inventoryId,
        inventoryViewModel,
        queryResultsSelector: createSelector(
            inventoryItemViewModelsByInventoryIdSelector,
            (inventoryItemViewModelsByInventoryId) =>
                inventoryItemViewModelsByInventoryId(inventoryId)
        ),
        type: inventoryViewModel.inventoryType,
    };
};

export default compose(
    connect(mapStateToProps),
    withHandlers({
        handleSortKeyClickAction: () => sortInventoryBySortKey,
    }),
    withPropsOnChange(['inventoryViewModelById', 'params'], mapPropsOnChange),
    withRouter
)(Inventory);

import { pick, omit, merge, first, get } from 'lodash';
import keyMirror from 'keymirror';

import { NEXUS_STATE_PROP as ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP } from '~/client-common/core/domain/elastic-attribute-details/state/data';
import {
    offenseCodesSelector,
    NEXUS_STATE_PROP as OFFENSE_CODES_NEXUS_STATE_PROP,
} from '~/client-common/core/domain/offense-codes/state/data';
import { createWarrantsResultsSelector } from '~/client-common/core/domain/elastic-warrants/state/ui';
import getWarrantsResource from '~/client-common/core/domain/warrants/resources/warrantsResource';
import { NEXUS_STATE_PROP as WARRANTS_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrants/state/data';
import { NEXUS_STATE_PROP as WARRANT_STATUSES_NEXUS_STATE_PROP } from '~/client-common/core/domain/warrant-statuses/state/data';
import boxEnum from '~/client-common/core/enums/client/boxEnum';
import elasticSearchResource from '../../../../../legacy-redux/resources/elasticSearchResource';
import connectWarrantsSidePanelForm from '../forms/connectWarrantsSidePanelForm';

import getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults from '../../../../search/core/utils/getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults';
import { currentUserDepartmentIdSelector } from '../../../../core/current-user/state/ui';

import {
    loadBoxStart,
    saveBoxSuccess,
    saveBoxFailure,
    saveBoxStart,
    saveBoxHalt,
} from '../../../../../legacy-redux/actions/boxActions';

export const connectWarrantsContexts = keyMirror({
    ARREST: null,
});

const context = { name: boxEnum.CONNECT_WARRANTS_SIDE_PANEL };

const ADD_TO_CONNECT_WARRANT_RESULTS = 'connectWarrants/ADD_TO_CONNECT_WARRANT_RESULTS';
const CLEAR_CONNECT_WARRANT_RESULTS = 'connectWarrants/CLEAR_CONNECT_WARRANT_RESULTS';
const SHOW_CONNECT_WARRANTS_RESULTS = 'connectWarrants/SHOW_CONNECT_WARRANTS_RESULTS';
const SHOW_CONNECT_WARRANTS_SEARCH = 'connectWarrants/SHOW_CONNECT_WARRANTS_SEARCH';

const CONNECT_WARRANTS_SEARCH_SUCCESS = 'connectWarrants/CONNECT_WARRANTS_SEARCH_SUCCESS';
const CONNECT_WARRANTS_SEARCH_START = 'connectWarrants/CONNECT_WARRANTS_SEARCH_START';
const CONNECT_WARRANTS_SEARCH_CONTINUE = 'connectWarrants/CONNECT_WARRANTS_SEARCH_CONTINUE';

// selectors
export const connectWarrantsUiSelector = (state) => state.ui.warrants.connectWarrants;
const connectWarrantsElasticWarrantsSelector = (state) =>
    state.ui.warrants.connectWarrants.elasticWarrants;
export const connectWarrantsElasticWarrantsViewModelsSelector = createWarrantsResultsSelector(
    connectWarrantsElasticWarrantsSelector
);

export function addToWarrantResults(result) {
    return {
        type: ADD_TO_CONNECT_WARRANT_RESULTS,
        payload: result,
    };
}

export function clearWarrantResults() {
    return {
        type: CLEAR_CONNECT_WARRANT_RESULTS,
    };
}

export function showConnectWarrantsSearch() {
    return {
        type: SHOW_CONNECT_WARRANTS_SEARCH,
    };
}

function showConnectWarrantsResults() {
    return {
        type: SHOW_CONNECT_WARRANTS_RESULTS,
    };
}

function connectWarrantsSearchStart(query) {
    return {
        type: CONNECT_WARRANTS_SEARCH_START,
        payload: query,
    };
}

function connectWarrantsSearchContinue() {
    return {
        type: CONNECT_WARRANTS_SEARCH_CONTINUE,
    };
}

function connectWarrantsSearchSuccess(elasticWarrants, numResults, totalCount) {
    return {
        type: CONNECT_WARRANTS_SEARCH_SUCCESS,
        payload: { elasticWarrants, numResults, totalCount },
    };
}

export function submitConnectWarrantsSearch({
    isQuickSearch,
    searchOnlyCurrentDepartment = false,
}) {
    return (dispatch, getState, dependencies) => {
        const currentUserDepartmentId = currentUserDepartmentIdSelector(getState());
        dispatch(loadBoxStart(context));
        dispatch(
            connectWarrantsSidePanelForm.actionCreators.submit((formModel) => {
                const filteredFormModel = isQuickSearch
                    ? pick(formModel, 'warrantNumber')
                    : omit(formModel, 'warrantNumber');
                return connectWarrantsSidePanelForm.convertFromFormModel(filteredFormModel);
            })
        )
            .then((elasticWarrantQuery) => {
                if (searchOnlyCurrentDepartment) {
                    elasticWarrantQuery.departmentIds = [currentUserDepartmentId];
                }
                dispatch(connectWarrantsSearchStart(elasticWarrantQuery));
                return elasticSearchResource.searchWarrants(elasticWarrantQuery);
            })
            .then((data) => {
                dispatch(saveBoxHalt(context));
                const state = getState();
                const {
                    elasticAttributeDetails,
                    offenseCodeViews,
                } = getElasticAttributeDetailsAndOffenseCodeViewsFromSearchResults(
                    { data },
                    {
                        offenseCodes: offenseCodesSelector(state),
                    }
                );
                const entityData = {};
                if (elasticAttributeDetails.length) {
                    entityData[
                        ELASTIC_ATTRIBUTE_DETAILS_NEXUS_STATE_PROP
                    ] = elasticAttributeDetails;
                }

                if (offenseCodeViews.length) {
                    entityData[OFFENSE_CODES_NEXUS_STATE_PROP] = offenseCodeViews;
                }

                const { items, query, totalCount } = data;
                dispatch(
                    dependencies.nexus.withEntityItems(
                        entityData,
                        connectWarrantsSearchSuccess(items, query.size, totalCount)
                    )
                );
                dispatch(showConnectWarrantsResults());
            })
            .catch(() => dispatch(saveBoxFailure(context, 'Warrant Search Failed')));
    };
}

export function continueConnectWarrantsSearch() {
    return (dispatch, getState) => {
        const ui = connectWarrantsUiSelector(getState());
        dispatch(connectWarrantsSearchContinue());
        elasticSearchResource
            .searchWarrants(ui.query, ui.warrantSearchPosition)
            .then(({ items, query, totalCount }) => {
                dispatch(connectWarrantsSearchSuccess(items, query.size, totalCount));
            })
            .catch(() => dispatch(saveBoxFailure(context, 'Warrant Search Failed')));
    };
}

// TODO - this is bad, but I don't see another option really
export function selectWarrantFromConnectWarrantsSidePanel(warrantId) {
    return (dispatch, getState, dependencies) => {
        const state = getState();
        const uiState = connectWarrantsUiSelector(state);
        const connectWarrantsContext = uiState.connectWarrantsContext;
        const { metadata } = uiState;
        dispatch(saveBoxStart(context));
        switch (connectWarrantsContext) {
            case connectWarrantsContexts.ARREST:
                const arrestId = metadata.arrestId;
                const chargeOffenseOrder = metadata.chargeOffenseOrder;
                getWarrantsResource()
                    .getFullWarrantsByIds([warrantId])
                    .then((fullWarrants) => {
                        const fullWarrant = first(fullWarrants);
                        const warrant = first(get(fullWarrant, 'warrants'));
                        if (fullWarrant === undefined || warrant === undefined) {
                            return dispatch(saveBoxFailure(context, 'Could not select warrant.'));
                        }

                        dispatch(
                            dependencies.nexus.withEntityItems(
                                {
                                    [WARRANTS_NEXUS_STATE_PROP]: [warrant],
                                    [WARRANT_STATUSES_NEXUS_STATE_PROP]:
                                        fullWarrant.warrantStatuses || [],
                                },
                                addToWarrantResults({
                                    [connectWarrantsContexts.ARREST]: {
                                        [arrestId]: {
                                            [chargeOffenseOrder]: warrant,
                                        },
                                    },
                                })
                            )
                        );
                        return dispatch(saveBoxSuccess(context));
                    })
                    .catch(() =>
                        dispatch(saveBoxFailure(context, 'Could not add warrant to charge.'))
                    );
                break;
            default:
                dispatch(saveBoxFailure(context));
        }
    };
}

export default function connectWarrantsUiReducer(
    state = {
        connectWarrantsContext: null,
        metadata: null,
        showingResults: false,
        elasticWarrants: [],
        warrantSearchPosition: 0,
        totalCount: null,
        query: null,
        continuingSearch: false,
        warrantResults: {},
    },
    action
) {
    switch (action.type) {
        case ADD_TO_CONNECT_WARRANT_RESULTS:
            return {
                ...state,
                warrantResults: merge({}, state.warrantResults, action.payload),
            };
        case CLEAR_CONNECT_WARRANT_RESULTS:
            return {
                ...state,
                warrantResults: {},
            };
        case SHOW_CONNECT_WARRANTS_SEARCH:
            return {
                ...state,
                showingResults: false,
            };
        case SHOW_CONNECT_WARRANTS_RESULTS:
            return {
                ...state,
                showingResults: true,
            };
        case CONNECT_WARRANTS_SEARCH_SUCCESS:
            return {
                ...state,
                elasticWarrants: [...state.elasticWarrants, ...action.payload.elasticWarrants],
                warrantSearchPosition: state.warrantSearchPosition + action.payload.numResults,
                totalCount: action.payload.totalCount,
                continuingSearch: false,
            };
        case CONNECT_WARRANTS_SEARCH_START:
            return {
                ...state,
                elasticWarrants: [],
                query: action.payload,
                warrantSearchPosition: 0,
                totalCount: null,
            };
        case CONNECT_WARRANTS_SEARCH_CONTINUE:
            return {
                ...state,
                continuingSearch: true,
            };
        default:
            return state;
    }
}

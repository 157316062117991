import { max, min } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'arc';

// helpers
import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';

// components
import Pagination from '../../../../legacy-redux/components/core/Pagination';
import TableResultsSummary from '../../../../legacy-redux/components/core/tables/TableResultsSummary';
import testIds from '../../../../core/testIds';
import { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import ToggleButton from '../../../../legacy-redux/components/core/ToggleButton';
import EntityProfileReportsFilterForm from './filter/EntityProfileReportsFilterForm';

const EntityProfileReportsTableHeaderContainer = styled.div`
    float: right;
    margin: 5px;
`;

const TableResultsSummaryContainer = styled(TableResultsSummary)`
    float: left;
    margin-right: 10px;
    padding-top: 7px;
`;

const ToggleButtonContainer = styled(ToggleButton)`
    margin-right: 5px;
`;

export default function EntityProfileReportsWithFilterTableHeader({
    from,
    size,
    totalCount,
    onPaginationClick,
}) {
    const [filterIsOpen, setFilterIsOpen] = useState(false);
    const onFilterToggleClick = () => setFilterIsOpen((filterIsOpen) => !filterIsOpen);

    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const to = min([from + size, totalCount]);
    return (
        <th colSpan={5} height={'auto'}>
            <EntityProfileReportsTableHeaderContainer>
                {totalCount > 0 && (
                    <TableResultsSummaryContainer from={from} to={to} totalResults={totalCount} />
                )}
                <ToggleButtonContainer
                    testId={testIds.FILTER_BUTTON}
                    className={buttonTypes.ICON}
                    active={filterIsOpen}
                    inactiveIcon={<Icon icon="Filter" />}
                    onClick={onFilterToggleClick}
                />
                {totalCount > 0 && (
                    <Pagination
                        currentPage={currentPage}
                        itemsPerPage={size}
                        itemCount={totalCount}
                        onClick={onPaginationClick}
                        maxEdgeItems={1}
                    />
                )}
            </EntityProfileReportsTableHeaderContainer>

            {filterIsOpen && (
                <EntityProfileReportsFilterForm onChange={() => onPaginationClick(null, 1)} />
            )}
        </th>
    );
}

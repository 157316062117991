import { ElasticReportDefinitionQuery } from '@mark43/rms-api';
import { keyBy } from 'lodash';
import { createKeyForSearchableConfiguredPropertyOptionView } from './createKeyForSearchableConfiguredPropertyOptionView';

export function getConfiguredEntityPropertiesFromElasticReportDefinitionQueries(
    reportDefinitionQueries: ElasticReportDefinitionQuery[]
): Record<string, { configuredEntityKeyName: string; configuredPropertyKeyName: string }> {
    return keyBy(
        reportDefinitionQueries
            .flatMap((query) =>
                query.configuredEntities.flatMap(
                    ({
                        configuredBigIntQuery = [],
                        configuredBooleanQuery = [],
                        configuredDateTimeQuery = [],
                        configuredDecimalQuery = [],
                        configuredIdQuery = [],
                        configuredStringQuery = [],
                        key,
                    }) => {
                        if (!key) {
                            return;
                        }

                        return [
                            ...configuredBigIntQuery,
                            ...configuredBooleanQuery,
                            ...configuredDateTimeQuery,
                            ...configuredDecimalQuery,
                            ...configuredIdQuery,
                            ...configuredStringQuery,
                        ].map(({ key: propertyKeyName }) => {
                            if (!propertyKeyName) {
                                return;
                            }
                            return {
                                configuredEntityKeyName: key,
                                configuredPropertyKeyName: propertyKeyName,
                            };
                        });
                    }
                )
            )
            .filter((value): value is NonNullable<typeof value> => !!value),
        createKeyForSearchableConfiguredPropertyOptionView
    );
}

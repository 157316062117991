import React from 'react';
import styled from 'styled-components';
import { compose, mapProps } from 'recompose';
import { map, slice } from 'lodash';

import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import _Icon, { iconTypes } from '../../../../core/components/Icon';

import { Tooltip } from '../../../../core/components/tooltip';

const NUMBER_TO_DISPLAY = 5;

const MoreChargeText = styled.div`
    padding-left: 16px;
    color: ${(props) => props.theme.colors.mediumLightGrey};
    font-size: var(--arc-fontSizes-sm);
    cursor: default;
`;

const Icon = styled(_Icon)`
    vertical-align: text-bottom;
`;

function WarrantChargeDisplay({ warrantCharge: { chargeName } }) {
    return (
        <div>
            <Icon type={iconTypes.CHARGE} />
            {chargeName}
        </div>
    );
}

function WarrantCell({ elasticWarrant }) {
    const viewModelProps = getViewModelProperties(elasticWarrant);
    const warrantCharges = viewModelProps.warrantCharges || [];
    const displayWarrantCharges = slice(warrantCharges, 0, NUMBER_TO_DISPLAY);
    const additionalWarrantCharges = slice(warrantCharges, NUMBER_TO_DISPLAY);

    return (
        <div>
            {map(displayWarrantCharges, (warrantCharge) => {
                return (
                    <WarrantChargeDisplay key={warrantCharge.id} warrantCharge={warrantCharge} />
                );
            })}
            {additionalWarrantCharges && additionalWarrantCharges.length > 0 && (
                <div>
                    <Tooltip
                        side="right"
                        content={map(additionalWarrantCharges, (warrantCharge) => (
                            <WarrantChargeDisplay
                                key={warrantCharge.id}
                                warrantCharge={warrantCharge}
                            />
                        ))}
                    >
                        <MoreChargeText>+{additionalWarrantCharges.length}</MoreChargeText>
                    </Tooltip>
                </div>
            )}
        </div>
    );
}

export default compose(mapProps((ownerProps) => ({ elasticWarrant: ownerProps })))(WarrantCell);

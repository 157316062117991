import { RefContextEnum } from '@mark43/rms-api';

import { omit } from 'lodash';
import * as fields from '~/client-common/core/enums/universal/fields';

import baseCourtOrderFormFieldViewModels from '../../../core/state/forms/baseCourtOrderFormFieldViewModels';
import { createFormModule } from '../../../../core/forms';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const fieldViewModels = {
    ...buildFlatFormFieldViewModels([
        {
            key: 'chargeIdsToVacate',
            fieldName: fields.COURT_ORDER_ID,
        },
        'courtOrderId',
    ]),
    ...omit(baseCourtOrderFormFieldViewModels, [
        'userToContactId',
        'isSealNarrative',
        'isSealReport',
    ]),
};

export default createFormModule({
    context: RefContextEnum.FORM_CHARGE_VACATING.name,
    fieldViewModels,
});

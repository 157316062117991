import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';
import * as fields from '~/client-common/core/enums/universal/fields';
import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { ATTRIBUTE, N_ITEMS_FIELDSET } = fieldTypeClientEnum;

/**
 * View model for the `ItemIdentifierNItems` component.
 * @type {Object}
 */
export default {
    type: N_ITEMS_FIELDSET,
    key: 'itemIdentifiers',
    fields: buildFlatFormFieldViewModels([
        {
            key: 'itemIdentifierTypeAttrId',
            type: ATTRIBUTE,
            fieldName: fields.ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_ATTR_ID,
        },
        {
            key: 'identifier',
            fieldName: fields.ITEM_IDENTIFIER_IDENTIFIER,
        },
        {
            key: 'itemIdentifierTypeOtherDesc',
            fieldName: fields.ITEM_IDENTIFIER_ITEM_IDENTIFIER_TYPE_OTHER_DESC,
        },
    ]),
};

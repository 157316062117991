import { SystemRuleEnum, UcrCategoryEnum } from '@mark43/rms-api';
import _, { map, every, filter, some, get, includes, uniqBy, parseInt, isNaN } from 'lodash';

import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import ucrCodes from '~/client-common/core/legacy-constants/ucrCodes';

import { ucrSummaryOffenseCodeByCodeSelector } from '~/client-common/core/domain/ucr-summary-offense-codes/state/data';
import { codesSelector } from '~/client-common/core/domain/codes/state/data';
import { currentUcrSourceSelector } from '../ui/selectors';
import addRuleId from '../../../../../legacy-redux/validation/helpers/addRuleId';
import {
    showDomesticViolenceOnUcrEvent,
    showDomesticViolenceOnUcrOffense,
    showRelationshipCodeId,
    showHomicideFields,
    showArsonLocation,
    showNegligentManslaughter,
    showBuildingInhabited,
    showWeaponOrForceInvolved,
    showSuffocationOrStrangulation,
    showNumberOfSeniorCitizenVictims,
    showRobbberyLocation,
    showBurglaryFields,
    showLarcenyTheft,
} from '../../utils/ucrClassificationHelper';

const validateOffenses = ({ ucrOffenses, requiredFieldName, isRequired, state }) => {
    const currentUcrSource = currentUcrSourceSelector(state);
    const ucrSummaryOffenseCodeByCode = ucrSummaryOffenseCodeByCodeSelector(state);
    return (
        ucrOffenses.length === 0 ||
        some(ucrOffenses, (ucrOffense) => {
            const ucrCode = ucrSummaryOffenseCodeByCode(get(ucrOffense, 'ucrSummaryCodeCode'));
            const ucrRuleBundle = {
                currentUcrSource,
                ucrCodeCategory: get(ucrCode, 'category'),
                ucrCodeCode: get(ucrCode, 'code'),
                ucrOffense,
            };
            return (
                !isRequired(ucrRuleBundle) || !isUndefinedOrNull(get(ucrOffense, requiredFieldName))
            );
        })
    );
};

export const ucrEventWeaponOrForceInvolvedRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_EVENT_WEAPON_OR_FORCE_INVOLVED_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        const ucrOffenseCodes = map(
            get(formModel, 'ucrOffenses'),
            (ucrOffense) => ucrOffense.ucrSummaryCodeCode
        );
        const ucrRuleBundle = {
            ucrOffenseSummaryCodes: ucrOffenseCodes,
            currentUcrSource: currentUcrSourceSelector(state),
            ucrEvent: get(formModel, 'ucrEvent'),
        };
        return (
            !showWeaponOrForceInvolved(ucrRuleBundle) ||
            !isUndefinedOrNull(get(formModel, 'ucrEvent.weaponOrForceInvolvedCodeId'))
        );
    }
);

export const ucrSuffocationOrStrangulationRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_SUFFOCATION_OR_STRANGULATION_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        const ucrRuleBundle = {
            currentUcrSource: currentUcrSourceSelector(state),
            ucrEvent: get(formModel, 'ucrEvent'),
        };
        return (
            !showSuffocationOrStrangulation(ucrRuleBundle) ||
            !isUndefinedOrNull(get(formModel, 'ucrEvent.suffocationOrStrangulationInvolvedCodeId'))
        );
    }
);

export const ucrEventNumberOfSeniorCitizenVictimsRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_EVENT_NUMBER_OF_SENIOR_CITIZEN_VICTIMS_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        const ucrRuleBundle = {
            currentUcrSource: currentUcrSourceSelector(state),
            ucrEvent: get(formModel, 'ucrEvent'),
        };
        const numberOfSeniorCitizenVictims = get(
            formModel,
            'ucrEvent.numberOfSeniorCitizenVictims'
        );
        const numberOfSeniorCitizensIsValid =
            !isNaN(parseInt(numberOfSeniorCitizenVictims)) && numberOfSeniorCitizenVictims > 0;
        return !showNumberOfSeniorCitizenVictims(ucrRuleBundle) || numberOfSeniorCitizensIsValid;
    }
);

export const ucrOffenseRobberyLocationTypeRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_ROBBERY_LOCATION_TYPE_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'robberyLocationCodeId',
            isRequired: showRobbberyLocation,
            state,
        });
    }
);

export const ucrOffenseBuildingInhabitedRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_BUILDING_INHABITED_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'arsonBuildingInhabited',
            isRequired: showBuildingInhabited,
            state,
        });
    }
);

export const ucrOffenseBurglaryResidenceTypeRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_BURGLARY_RESIDENCE_TYPE_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'burglaryResidenceTypeCodeId',
            isRequired: showBurglaryFields,
            state,
        });
    }
);

export const ucrOffenseBurglaryTimeRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_BURGLARY_TIME_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'burglaryTimeOfDayCodeId',
            isRequired: showBurglaryFields,
            state,
        });
    }
);

export const ucrOffenseLarcenyTheftCategoryRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_LARCENY_THEFT_CATEGORY_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'larcenyTheftCategoryCodeId',
            isRequired: showLarcenyTheft,
            state,
        });
    }
);

export const ucrOffenseOrEventClassificationRequired = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_OR_EVENT_CLASSIFICATION_REQUIRED.name,
    (formModel) => {
        const hasEventClassification =
            formModel.ucrEvent && !!formModel.ucrEvent.customReportClassificationAttrId;
        const hasOffenseClassification =
            formModel.ucrOffenses &&
            some(formModel.ucrOffenses, (ucrOffense) => !!ucrOffense.ucrSummaryCodeCode);
        return hasEventClassification || hasOffenseClassification;
    }
);

export const ucrOffenseOnlyOneLarceny = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_ONLY_ONE_LARCENY.name,
    (formModel, value, state) => {
        if (!formModel.ucrOffenses) {
            return true;
        }
        const allCodes = map(formModel.ucrOffenses, ({ ucrSummaryCodeCode }) =>
            ucrSummaryOffenseCodeByCodeSelector(state)(ucrSummaryCodeCode)
        );
        return filter(allCodes, { category: UcrCategoryEnum.VI.name }).length <= 1;
    }
);

export const ucrOffenseBurglaryTimeAndPlace = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_BURGLARY_TIME_AND_PLACE.name,
    (formModel, value, state) => {
        return (
            _(formModel.ucrOffenses)
                .filter(
                    ({ ucrSummaryCodeCode }) =>
                        get(
                            ucrSummaryOffenseCodeByCodeSelector(state)(ucrSummaryCodeCode),
                            'category'
                        ) === UcrCategoryEnum.V.name
                )
                .uniqBy(
                    ({ burglaryResidenceTypeCodeId, burglaryTimeOfDayCodeId }) =>
                        `${burglaryResidenceTypeCodeId}-${burglaryTimeOfDayCodeId}`
                )
                .value().length <= 1
        );
    }
);

export const ucrOffenseRobberyPlace = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_ROBBERY_PLACE.name,
    (formModel, value, state) => {
        return (
            _(formModel.ucrOffenses)
                .filter(
                    ({ ucrSummaryCodeCode }) =>
                        get(
                            ucrSummaryOffenseCodeByCodeSelector(state)(ucrSummaryCodeCode),
                            'category'
                        ) === UcrCategoryEnum.III.name
                )
                .uniqBy('robberyLocationCodeId')
                .value().length <= 1
        );
    }
);

export const ucrOffenseNoDuplicateCodes = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_NO_DUPLICATE_CODES.name,
    (formModel) => {
        return (
            formModel.ucrOffenses &&
            uniqBy(formModel.ucrOffenses, 'ucrSummaryCodeCode').length ===
                formModel.ucrOffenses.length
        );
    }
);

function isVehicle(ucrProperty, state) {
    const propertyCategoryCode = codesSelector(state)[ucrProperty.propertyCategoryCodeId];
    // this code is hard coded because it comes from the UCR spec and should therefore not change
    return propertyCategoryCode && propertyCategoryCode.code === '24';
}

function isRecoveredProperty(ucrProperty, state) {
    const propertyStatusCode = codesSelector(state)[ucrProperty.propertyStatusCodeId];
    // this code is hard coded because it comes from the UCR spec and should therefore not change
    return propertyStatusCode && propertyStatusCode.code === 'R';
}

export const ucrPropertyRecoveryTypeRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_PROPERTY_RECOVERY_TYPE_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return every(formModel.ucrProperty, (ucrProperty) => {
            if (isVehicle(ucrProperty, state) && isRecoveredProperty(ucrProperty, state)) {
                return !isUndefinedOrNull(ucrProperty.vehicleRecoveryTypeCodeId);
            }
            return true;
        });
    }
);

export const ucrPropertyRecoveryDateRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_PROPERTY_RECOVERY_DATE_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return every(formModel.ucrProperty, (ucrProperty) => {
            if (isRecoveredProperty(ucrProperty, state)) {
                return !isUndefinedOrNull(ucrProperty.vehicleRecoveryDateUtc);
            }
            return true;
        });
    }
);

export const ucrPropertyBurnedRequireOneUcrOffenseArson = addRuleId(
    SystemRuleEnum.UCR_PROPERTY_BURNED_REQUIRE_ONE_UCR_OFFENSE_ARSON.name,
    (formModel, value, state) => {
        const allCodes =
            formModel.ucrOffenses &&
            map(formModel.ucrOffenses, ({ ucrSummaryCodeCode }) =>
                ucrSummaryOffenseCodeByCodeSelector(state)(ucrSummaryCodeCode)
            );
        const hasArson = some(allCodes, { category: UcrCategoryEnum.VIII.name });
        const hasBurned =
            !!formModel.ucrProperty &&
            _(formModel.ucrProperty).some((ucrProperty) => {
                const code = codesSelector(state)[ucrProperty.propertyStatusCodeId];
                // this code is hard coded because it comes from the UCR spec and should therefore not change
                return get(code, 'code') === 'B';
            });
        return !hasBurned || hasArson;
    }
);

export const ucrOffenseAdditionalUcrCodesMustBeArsonHumanTraffickingOrHomicide = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_ADDITIONAL_UCR_CODES_MUST_BE_ARSON_HUMAN_TRAFFICKING_OR_HOMICIDE
        .name,
    (formModel, value, state) => {
        if (!formModel.ucrOffenses) {
            return false;
        }
        return (
            _(formModel.ucrOffenses)
                .reject(
                    (ucrOffense) =>
                        ucrOffense.ucrSummaryCodeCode === ucrCodes.potentialJustifiableHomicideCode
                )
                .map((ucrOffense) =>
                    get(
                        ucrSummaryOffenseCodeByCodeSelector(state)(ucrOffense.ucrSummaryCodeCode),
                        'category'
                    )
                )
                .reject((category) =>
                    includes(
                        [
                            UcrCategoryEnum.VIII.name,
                            UcrCategoryEnum.XXX.name,
                            UcrCategoryEnum.XXXI.name,
                        ],
                        category
                    )
                )
                .uniq()
                .value().length <= 1
        );
    }
);

export const ucrOffenseAdditionalUcrCodeHomicideMustHaveUcrStatusUnfounded = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_ADDITIONAL_UCR_CODE_HOMICIDE_MUST_HAVE_UCR_STATUS_UNFOUNDED.name,
    (formModel, value, state) => {
        if (!formModel.ucrOffenses) {
            return false;
        }

        const hasConflictingOffenses =
            _(formModel.ucrOffenses)
                .reject((ucrOffense) => {
                    return (
                        ucrOffense.ucrSummaryCodeCode === ucrCodes.potentialJustifiableHomicideCode
                    );
                })
                .map((ucrOffense) =>
                    get(
                        ucrSummaryOffenseCodeByCodeSelector(state)(ucrOffense.ucrSummaryCodeCode),
                        'category'
                    )
                )
                .reject((category) =>
                    includes(
                        [
                            UcrCategoryEnum.VIII.name,
                            UcrCategoryEnum.XXX.name,
                            UcrCategoryEnum.XXXI.name,
                        ],
                        category
                    )
                )
                .value().length > 0;

        return (
            !hasConflictingOffenses ||
            !some(formModel.ucrOffenses, (ucrOffense) => {
                const code = codesSelector(state)[ucrOffense.offenseStatusCodeId];
                return (
                    ucrOffense.ucrSummaryCodeCode === ucrCodes.potentialJustifiableHomicideCode &&
                    // this code is hard coded because it comes from the UCR spec and should therefore not change
                    get(code, 'code') !== 'U'
                );
            })
        );
    }
);

export const ucrOffenseLarcenyAmountMatchesStolenProperty = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_LARCENY_AMOUNT_MATCHES_STOLEN_PROPERTY.name,
    (formModel, value, state) => {
        const stolenPropertyValue =
            !!formModel.ucrProperty &&
            _(formModel.ucrProperty)
                .filter((ucrProperty) => {
                    const code = codesSelector(state)[ucrProperty.propertyStatusCodeId];
                    // this code is hard coded because it comes from the UCR spec and should therefore not change
                    return get(code, 'code') === 'S';
                })
                .map((ucrProperty) => parseInt(ucrProperty.nearestDollarValue) || 0)
                .reduce((val1, val2) => val1 + val2, 0);

        return _(formModel.ucrOffenses).every((ucrOffense) => {
            const category = get(
                ucrSummaryOffenseCodeByCodeSelector(state)(ucrOffense.ucrSummaryCodeCode),
                'category'
            );
            if (category !== UcrCategoryEnum.VI.name) {
                return true;
            }
            const code = get(codesSelector(state)[ucrOffense.larcenyTheftCategoryCodeId], 'code');
            switch (code) {
                case '4':
                    return stolenPropertyValue > 400;
                case '3':
                    return stolenPropertyValue >= 200 && stolenPropertyValue <= 400;
                case '2':
                    return stolenPropertyValue >= 50 && stolenPropertyValue <= 199;
                case '1':
                    return stolenPropertyValue >= 0 && stolenPropertyValue < 50;
                default:
                    // if the field isn't filled the user will get a required field error instead
                    return true;
            }
        });
    }
);

export const ucrPropertyNoStolenPropertyIfOffenseIsAssault = addRuleId(
    SystemRuleEnum.UCR_PROPERTY_NO_STOLEN_PROPERTY_IF_OFFENSE_IS_ASSAULT.name,
    (formModel, value, state) => {
        const allCodes =
            formModel.ucrOffenses &&
            map(formModel.ucrOffenses, ({ ucrSummaryCodeCode }) =>
                ucrSummaryOffenseCodeByCodeSelector(state)(ucrSummaryCodeCode)
            );
        const hasAssault = some(allCodes, { category: UcrCategoryEnum.IV.name });
        if (!hasAssault) {
            return true;
        }
        return (
            !!formModel.ucrProperty &&
            !_(formModel.ucrProperty).some((ucrProperty) => {
                const code = codesSelector(state)[ucrProperty.propertyStatusCodeId];
                // this code is hard coded because it comes from the UCR spec and should therefore not change
                return get(code, 'code') === 'S';
            })
        );
    }
);

export const ucrOffenseOnlyOneArsonOffensePerReport = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_ONLY_ONE_ARSON_OFFENSE_PER_REPORT.name,
    (formModel, value, state) => {
        if (!formModel.ucrOffenses) {
            return true;
        }
        const allCodes = map(formModel.ucrOffenses, ({ ucrSummaryCodeCode }) =>
            ucrSummaryOffenseCodeByCodeSelector(state)(ucrSummaryCodeCode)
        );
        return filter(allCodes, { category: UcrCategoryEnum.VIII.name }).length <= 1;
    }
);

export const ucrIsDomesticViolenceRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_IS_DOMESTIC_VIOLENCE_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        const ucrRuleBundle = {
            currentUcrSource: currentUcrSourceSelector(state),
        };
        if (showDomesticViolenceOnUcrEvent(ucrRuleBundle)) {
            return !isUndefinedOrNull(get(formModel, 'ucrEvent.isDomesticViolence'));
        } else {
            return validateOffenses({
                ucrOffenses: get(formModel, 'ucrOffenses'),
                requiredFieldName: 'isDomesticViolence',
                isRequired: showDomesticViolenceOnUcrOffense,
                state,
            });
        }
    }
);

export const ucrOffenseRelationshipCodeIdRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_RELATIONSHIP_CODE_ID_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'relationshipCodeId',
            isRequired: showRelationshipCodeId,
            state,
        });
    }
);

export const ucrOffenseHomicideWeaponRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_HOMICIDE_WEAPON_OR_FORCE_INVOLVED_CODE_ID_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'homicideWeaponOrForceInvolvedCodeId',
            isRequired: showHomicideFields,
            state,
        });
    }
);

export const ucrOffenseHomicideRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_HOMICIDE_CODE_ID_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'homicideCodeId',
            isRequired: showHomicideFields,
            state,
        });
    }
);

export const ucrOffenseArsonLocationRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_ARSON_LOCATION_CODE_ID_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'arsonLocationCodeId',
            isRequired: showArsonLocation,
            state,
        });
    }
);

export const ucrOffenseNegligentManslaughterRequiredWhenShown = addRuleId(
    SystemRuleEnum.UCR_OFFENSE_NEGLIGENT_MANSLAUGHTER_CODE_ID_REQUIRED_WHEN_SHOWN.name,
    (formModel, value, state) => {
        return validateOffenses({
            ucrOffenses: get(formModel, 'ucrOffenses'),
            requiredFieldName: 'negligentManslaughterCodeId',
            isRequired: showNegligentManslaughter,
            state,
        });
    }
);

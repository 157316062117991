import { Table as _Table } from 'components-mark43';
import { get, isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
    compose,
    defaultProps,
    setPropTypes,
    withProps,
    withState,
    withHandlers,
    withPropsOnChange,
} from 'recompose';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Box, cssVar } from 'arc';
import { deleteHydratedItem } from '~/client-common/core/domain/item-profiles/state/data';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Card, {
    CardSection as _CardSection,
} from '../../../../../../legacy-redux/components/core/Card';
import { itemSummaryViewModelsForCurrentReportSelector } from '../../../state/ui';
import testIds from '../../../../../../core/testIds';
import {
    currentReportCardUITitleByTypeSelector,
    currentReportSelector,
} from '../../../../../../legacy-redux/selectors/reportSelectors';
import { useEvidence } from '../ManageItemsSidePanel';
import { BlueActionBar } from '../../../../../core/components/ActionBar';
import Icon, { iconTypes } from '../../../../../core/components/Icon';
import Button, { buttonTypes } from '../../../../../../legacy-redux/components/core/Button';
import {
    openLoadingModal,
    closeLoadingModal,
} from '../../../../../../legacy-redux/actions/boxActions';
import Navigation from './Navigation';
import { TableHeaderRow, TableBodyRow } from './Row';

const Table = styled(_Table)`
    margin-bottom: 20px;
    min-width: 24rem;
`;

const TableWrapper = styled.div`
    overflow-x: auto;
`;

const CardSection = styled(_CardSection)`
    float: none;
    padding: 0 20px;
`;

const FixedBottom = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 50;
`;

const mapStateToProps = createStructuredSelector({
    itemSummaryViewModelsForCurrentReport: itemSummaryViewModelsForCurrentReportSelector,
    currentReportCardUITitleByType: currentReportCardUITitleByTypeSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onDeleteItem: (itemProfileId) => dispatch(deleteHydratedItem(itemProfileId)),
});

const ItemCard = compose(
    defaultProps({
        currentReportId: -1,
        isVehicle: false,
        onAdd() {},
    }),
    setPropTypes({
        currentReportId: PropTypes.number,
        isVehicle: PropTypes.bool,
        onAdd: PropTypes.func,
    }),
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        ['itemSummaryViewModelsForCurrentReport', 'isVehicle', 'index'],
        ({ itemSummaryViewModelsForCurrentReport, isVehicle, index }) => ({
            itemProfiles: itemSummaryViewModelsForCurrentReport(isVehicle, undefined, index),
        })
    ),
    withState('detailsRowShowing', 'setDetailsRowShowing', {}),
    withHandlers({
        setDetailsRowShowing: ({ detailsRowShowing, setDetailsRowShowing }) => (rowIndex) =>
            setDetailsRowShowing({
                ...detailsRowShowing,
                [rowIndex]: true,
            }),
        unsetDetailsRowShowing: ({ detailsRowShowing, setDetailsRowShowing }) => (rowIndex) =>
            setDetailsRowShowing({
                ...detailsRowShowing,
                [rowIndex]: false,
            }),
    }),
    withProps(
        ({
            card: { summaryMode },
            detailsRowShowing,
            isVehicle,
            itemProfiles,
            limitToOne = false,
            onAdd,
            onDeleteItem,
            setDetailsRowShowing,
            unsetDetailsRowShowing,
            quickSearchSection,
        }) => {
            const currentReport = useSelector(currentReportSelector);
            const dispatch = useDispatch();
            const [isSaving, setIsSaving] = useState(false);
            const {
                evidenceEnabled,
                selectableItemProfileIds,
                selectedItemProfileIds,
                setSelectedItemProfileIds,
                onPrintLabels,
                saveProgressDisabled,
            } = useEvidence({
                isVehicle,
                itemProfiles,
                ownerId: currentReport.id,
                closeSidePanel: () => {},
            });

            const renderHeaderRow = () => (
                <TableHeaderRow
                    isVehicle={isVehicle}
                    summaryMode={summaryMode}
                    showCheckboxes={evidenceEnabled}
                    selectableItemProfileIds={selectableItemProfileIds}
                    selectedItemProfileIds={selectedItemProfileIds}
                    setSelectedItemProfileIds={setSelectedItemProfileIds}
                />
            );

            const selectedMasterIds = itemProfiles
                .filter((x) => selectedItemProfileIds.includes(x.id))
                .map((x) => x.masterItemId);

            if (evidenceEnabled && isSaving !== saveProgressDisabled) {
                if (saveProgressDisabled) {
                    dispatch(openLoadingModal());
                    setIsSaving(true);
                } else {
                    dispatch(closeLoadingModal());
                    setIsSaving(false);
                }
            }

            const renderBodyRow = ({ data, index, key }) => (
                <TableBodyRow
                    key={key}
                    data={data}
                    detailsShowing={detailsRowShowing[index]}
                    hideDetails={() => unsetDetailsRowShowing(index)}
                    isVehicle={isVehicle}
                    onRemoveItem={() => onDeleteItem(data.id)}
                    showDetails={() => setDetailsRowShowing(index)}
                    summaryMode={summaryMode}
                    reportingEventNumber={currentReport.reportingEventNumber}
                    isChecked={selectedItemProfileIds.indexOf(data.id) >= 0}
                    disabled={selectableItemProfileIds.indexOf(data.id) === -1}
                    showCheckboxes={evidenceEnabled}
                    selectableItemProfileIds={selectableItemProfileIds}
                    selectedItemProfileIds={selectedItemProfileIds}
                    setSelectedItemProfileIds={setSelectedItemProfileIds}
                />
            );

            return {
                renderContent: () => (
                    <CardSection testId={summaryMode ? testIds.CARD_SUMMARY : undefined}>
                        <Navigation
                            isVehicle={isVehicle}
                            limitToOne={limitToOne}
                            onAddClickHandler={onAdd}
                            summaryMode={summaryMode}
                        />
                        {!summaryMode && quickSearchSection && (
                            <Box marginBottom={cssVar('arc.space.3')}>{quickSearchSection}</Box>
                        )}
                        <TableWrapper>
                            <Table
                                data={itemProfiles}
                                renderHeaderRow={renderHeaderRow}
                                renderBodyRow={renderBodyRow}
                            />
                        </TableWrapper>
                        <FixedBottom>
                            <BlueActionBar visible={selectedMasterIds.length > 0}>
                                <Button
                                    className={buttonTypes.SECONDARY}
                                    iconLeft={iconTypes.LABEL}
                                    onClick={onPrintLabels}
                                >
                                    <Icon icon="Label" size="md" />
                                    {componentStrings.reports.core.VehicleCard.printLabels}
                                </Button>
                            </BlueActionBar>
                        </FixedBottom>
                    </CardSection>
                ),
            };
        }
    )
)(
    ({
        card: { anchorForIndex, canEditReportCardStatus, errorMessages, saving, summaryMode },
        className,
        onEdit,
        onSaveProgress,
        renderContent,
        cardId,
        testId,
        registerCard,
        index,
        currentReportCardUITitleByType,
    }) => {
        useEffect(() => {
            if (isFunction(registerCard)) {
                registerCard();
            }
        }, [registerCard]);

        const cardTitle = currentReportCardUITitleByType(cardId);

        const onSave = () => {
            return onSaveProgress(undefined, { index });
        };

        return (
            <Card
                anchor={anchorForIndex(index)}
                canEdit={get(canEditReportCardStatus, 'canEditReportCard')}
                canEditErrorMessage={get(canEditReportCardStatus, 'errorMessage')}
                className={className}
                errors={errorMessages}
                onEdit={onEdit}
                onSave={onSave}
                renderContent={renderContent}
                saving={saving}
                summaryMode={summaryMode}
                title={cardTitle}
                testId={testId}
            />
        );
    }
);

export default ItemCard;

import { WarrantDexSubmission } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getWarrantResource from '../../../warrants/resources/warrantsResource';
import { ClientCommonAction } from '../../../../../redux/types';

export const NEXUS_STATE_PROP = 'warrantDexSubmissions';

const warrantDexSubmissionsModule = createNormalizedModule<WarrantDexSubmission>({
    type: NEXUS_STATE_PROP,
    key: 'id',
});

// ACTIONS

export const replaceWarrantDexSubmissionsWhere =
    warrantDexSubmissionsModule.actionCreators.replaceEntitiesWhere;

// SELECTORS

export const warrantDexSubmissionsWhereSelector =
    warrantDexSubmissionsModule.selectors.entitiesWhereSelector;

export function updateWarrantDexSubmissionsForWarrantId(
    warrantId: number,
    warrantDexSubmissions: WarrantDexSubmission[]
): ClientCommonAction<Promise<WarrantDexSubmission>> {
    const warrantResource = getWarrantResource();
    return function (dispatch) {
        return warrantResource
            .replaceWarrantDexSubmissionsForWarrantId(warrantId, warrantDexSubmissions)
            .then((updatedWarrantDexSubmissions: WarrantDexSubmission) => {
                dispatch(
                    replaceWarrantDexSubmissionsWhere({ warrantId }, updatedWarrantDexSubmissions)
                );
                return updatedWarrantDexSubmissions;
            });
    };
}

// REDUCER
export default warrantDexSubmissionsModule.reducerConfig;

import { mapValues, get } from 'lodash';
import { Ability } from '@mark43/rms-api';
import { createSelector } from 'reselect';
import { abilitiesSelector } from '../data';
import { modulesSelector } from '../../../modules/state/data';
import { buildViewModel } from '../../../../../helpers/viewModelHelpers';

export const abilityViewModelsSelector = createSelector(
    abilitiesSelector,
    modulesSelector,
    (abilities, moduleById) => {
        const viewModel = buildViewModel<Ability>({
            recursive: true,
            mappers: [
                ({ moduleId }) => ({
                    moduleName: get(moduleById[moduleId], 'name'),
                }),
            ],
        });

        return mapValues(abilities, viewModel);
    }
);

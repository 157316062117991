import React, { useState } from 'react';
import { HStack, cssVar } from 'arc';

import { useTargetProfileFieldName } from '~/client-common/core/fields/hooks/useFields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import testIds from '../../../core/testIds';
import Link from '../../core/components/links/Link';
import { Button } from '../../core/components/Button';
import { DashboardCard, DashboardCardProps } from './DashboardSection';
import TargetProfilesTable from './TargetProfilesTable';
import TargetProfilesFilters, { TargetProfilesFilterQuery } from './TargetProfilesFilters';

const strings = componentStrings.personalDashboard.TargetProfiles;

type TargetProfilesDashboardCardProps = DashboardCardProps;

const TargetProfilesDashboardCard = React.forwardRef<
    HTMLDivElement,
    TargetProfilesDashboardCardProps
>(({ dragHandleProps, isDragging, ...props }, forwardedRef) => {
    const { pluralTargetProfileFieldName: targetProfilesSectionTitle } =
        useTargetProfileFieldName();

    const [filtersQueryObj, setFiltersQueryObj] = useState<TargetProfilesFilterQuery>({});

    return (
        <DashboardCard
            ref={forwardedRef}
            {...props}
            dragHandleProps={dragHandleProps}
            isDragging={isDragging}
            data-test-id={testIds.PERSONAL_DASHBOARD_TARGET_PROFILES_SECTION}
            title={targetProfilesSectionTitle}
            actions={
                <HStack spacing={cssVar('arc.space.2')}>
                    <TargetProfilesFilters onApplyFilters={setFiltersQueryObj} />
                    <Link to="/cases/all/open?isTargetProfile=true">
                        <Button variant="ghost" isTextTransformNone>
                            {strings.viewAll}
                        </Button>
                    </Link>
                </HStack>
            }
        >
            <TargetProfilesTable filtersQueryObj={filtersQueryObj} />
        </DashboardCard>
    );
});

/**
 * Target Profiles section on the Personal Dashboard.
 */
export default TargetProfilesDashboardCard;

import React from 'react';

import componentStrings from '~/client-common/core/strings/componentStrings';

import ActionBar from '../../../core/components/ActionBar';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import Link from '../../../core/components/links/Link';

const { recordPrivacy } = componentStrings;

const CourtOrderEditActionBar = ({ saveButtonDisabled, onSaveClick, backUrl }) => {
    return (
        <ActionBar visible position="static">
            <Button
                onClick={onSaveClick}
                className={buttonTypes.PRIMARY}
                float="none"
                display="inline-block"
                disabled={saveButtonDisabled}
            >
                {recordPrivacy.courtOrders.CourtOrderEditActionBar.save}
            </Button>
            <Link to={backUrl}>
                <Button className={buttonTypes.SECONDARY} float="none" display="inline-block">
                    {recordPrivacy.courtOrders.CourtOrderEditActionBar.cancel}
                </Button>
            </Link>
        </ActionBar>
    );
};

export default CourtOrderEditActionBar;

import React from 'react';
import styled from 'styled-components';
import { EntityTypeEnum } from '@mark43/rms-api';
import { Flex } from 'arc';
import { FormattedDate } from '~/client-common/core/dates/components';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import ImgWithExifOrientation from '~/client-common/core/images/components/ImgWithExifOrientation';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { hexColorWithOpacity } from '../../../core/utils/colorHelpers';
import Icon from '../../../core/components/Icon';
import { fileIconTypeForFileCategory } from '../../files/helpers';
import CaseRowCheckbox from '../../../cases/core/components/CaseRowCheckbox';
import testIds from '../../../../core/testIds';
import { AttachmentDownloadButton, AttachmentEmailButton } from './AttachmentActionButtons';

export const AttachmentGridItemContainerInlineBlock = styled.div`
    display: inline-block;
`;

export const HoverContainer = styled.div`
    padding: 6px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    visibility: hidden;
    position: relative;
    background-color: ${(props) => hexColorWithOpacity(props.theme.colors.white, 0.7)};
`;

export const AttachmentGridItemContainer = styled.div`
    width: 176px;
    height: 176px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 3px;
    border: 1px solid ${(props) => props.theme.colors.extraLightGrey};
    cursor: pointer;
    font-size: var(--arc-fontSizes-md);
    position: relative;
    z-index: 1;

    & i {
        margin-right: 0;
    }

    &:hover {
        border: 1px solid ${(props) => props.theme.colors.cobaltBlue};

        ${/* sc-selector */ HoverContainer} {
            visibility: visible;
        }
    }
`;

export const AttachmentGridItemFooter = styled.div`
    background-color: ${(props) => props.theme.colors.cobaltBlue};
    color: ${(props) => props.theme.colors.white};
    width: 100%;
    padding: 4px;
    border: 1px solid ${(props) => props.theme.colors.cobaltBlue};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    border-radius: 0 0 2px 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export const GridStyledCheckbox = styled(CaseRowCheckbox)`
    display: flex;
    position: absolute !important;
    right: 0;
    top: 1em;
`;

export const AttachmentGridItemTitle = styled.div`
    font-size: var(--arc-fontSizes-md);
`;

export const AttachmentGridItemSubtitle = styled.div`
    font-style: italic;
    font-size: var(--arc-fontSizes-md);
    flex-grow: 1;
`;

const AttachmentGridItemBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

class AttachmentGridItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            attachment,
            onClickForLightBox,
            onDownload,
            onEmail,
            handleGridSelect,
            isGridSelected,
            showCheckbox = false,
        } = this.props;
        const { displayName, source, sourceDescription, createdDateUtc, createdBy } = attachment;
        const { thumbnailPath, originalFile } = getViewModelProperties(attachment);
        return (
            <AttachmentGridItemContainerInlineBlock>
                <AttachmentGridItemContainer
                    backgroundImage={thumbnailPath}
                    onClick={onClickForLightBox}
                    data-test-id={testIds.ATTACHMENT_GRID_ITEM}
                >
                    {thumbnailPath ? (
                        <ImgWithExifOrientation src={thumbnailPath} isBackgroundImage={true} />
                    ) : (
                        <AttachmentGridItemBackground>
                            <Icon
                                size={40}
                                color="lowContrastGrey"
                                type={fileIconTypeForFileCategory(originalFile.fileCategory)}
                            />
                        </AttachmentGridItemBackground>
                    )}
                    <HoverContainer>
                        {(source || sourceDescription) && (
                            <AttachmentGridItemTitle>
                                {source}: {sourceDescription}
                            </AttachmentGridItemTitle>
                        )}
                        <AttachmentGridItemSubtitle>
                            <FormattedDate
                                date={createdDateUtc}
                                format={FormattedDate.FORMATS.SUMMARY_DATE_TIME}
                            />
                            <div>by {createdBy}</div>
                        </AttachmentGridItemSubtitle>

                        <Flex gap={1} alignItems="center">
                            <AttachmentDownloadButton
                                href={attachment.path}
                                onDownload={(e) => {
                                    e.stopPropagation();
                                    onDownload();
                                }}
                            />

                            <FeatureFlagged flag="RMS_DIRECT_EMAILS_ENABLED">
                                <AttachmentEmailButton
                                    attachment={attachment}
                                    onEmail={(e) => {
                                        e.stopPropagation();
                                        onEmail(e);
                                    }}
                                />
                            </FeatureFlagged>
                        </Flex>
                    </HoverContainer>
                    {showCheckbox && (
                        <GridStyledCheckbox
                            value={isGridSelected}
                            onChange={() =>
                                handleGridSelect(
                                    attachment.attachmentId,
                                    isGridSelected,
                                    false,
                                    true,
                                    EntityTypeEnum.ATTACHMENT.name
                                )
                            }
                            entityType={EntityTypeEnum.ATTACHMENT.name}
                        />
                    )}

                    <AttachmentGridItemFooter>{displayName}</AttachmentGridItemFooter>
                </AttachmentGridItemContainer>
            </AttachmentGridItemContainerInlineBlock>
        );
    }
}

export default AttachmentGridItem;

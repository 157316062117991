import { noop, reject, map } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { InlineBanner } from 'arc';
import {
    AttributeTypeEnum,
    EFileMaterialSearchRequest,
    EntityTypeEnum,
    SearchResultEFileItemSearchView,
} from '@mark43/rms-api';
import { useResourceDeferred } from '~/client-common/core/hooks/useResource';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { RmsDispatch } from '../../../../../core/typings/redux';
import { attributeLoadingStateByAttributeTypeSelector } from '../../../../core/attributes/state/ui';
import { loadAttributesForType } from '../../../../core/attributes/state/ui/loadAttributesForType';
import { computeLoadableAttributeTypes } from '../../../../core/attributes/utils/computeLoadableAttributeTypes';

import { SkeletonTable } from '../../../../core/components/Skeleton';
import RMSTableComposite from '../../../../core/components/RMSTableComposite';
import Pagination from '../../../../../legacy-redux/components/core/Pagination';
import TableResultsSummary from '../../../../../legacy-redux/components/core/tables/TableResultsSummary';
import { ITEMS_PAGE_SIZE } from '../../../constants';
import { useEFileContext, usePagination } from '../../../hooks';
import { eFileResource } from '../../../resources/eFileResource';
import { ColumnT, EFileItemGridRowT, EFileSearchRequest } from '../../../types';
import { EFileMaterialItemsHeaderRow } from '../table/items/EFileMaterialItemsHeaderRow';
import { EFileMaterialItemsGridRow } from '../table/items/EFileMaterialItemsGridRow';
import { useMaterialItems } from './EFileMaterialItemsProvider';

const strings = componentStrings.eFiles.materials.items.grid;

const PaginationSummaryComponentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 0.5em;
`;

const PaginationComponentWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
`;

const columns: ColumnT[] = [
    {
        key: 'avatar',
        width: 40,
        title: '',
        sortable: false,
        filterable: false,
    },
    {
        key: 'description',
        width: 253,
        title: strings.columns.description,
        sortable: false,
        filterable: false,
    },
    {
        key: 'source',
        width: 196,
        title: strings.columns.source,
        sortable: false,
        filterable: false,
    },
    {
        key: 'createdDate',
        width: 176,
        title: strings.columns.createdDate,
        sortable: false,
        filterable: false,
    },
    {
        key: 'actions',
        width: 45,
        title: '',
        sortable: false,
        filterable: false,
    },
];

/**
 * Load attributes in order to show item profile data in this grid.
 * EFileItemSearchView includes only itemProfiles without attributes. If RMS-17994 hydrates attributes, then delete this
 * function.
 */
function useLoadAttributes(): void {
    const dispatch: RmsDispatch = useDispatch();
    const attributeLoadingStateByAttributeType = useSelector(
        attributeLoadingStateByAttributeTypeSelector
    );
    const attributeLoadingState = attributeLoadingStateByAttributeType([
        AttributeTypeEnum.ITEM_CATEGORY.name,
        // uncomment these 2 lines after RMS-17994 in order to show Firearm Make in <PropertyTitle> and show item identifiers
        // AttributeTypeEnum.FIREARM_MAKE.name,
        // AttributeTypeEnum.ITEM_IDENTIFIER_TYPE.name,
    ]);
    const attributeTypesToLoad = React.useMemo(
        () => computeLoadableAttributeTypes(attributeLoadingState),
        [attributeLoadingState]
    );
    React.useEffect(() => {
        if (attributeTypesToLoad.length > 0) {
            dispatch(loadAttributesForType({ attributeType: attributeTypesToLoad })).catch(noop);
        }
    }, [attributeTypesToLoad, dispatch]);
}

export const EFileMaterialItemsGrid = ({
    eFileId,
    setOnImport,
}: {
    eFileId: number;
    setOnImport: React.Dispatch<React.SetStateAction<() => void>>;
}): JSX.Element => {
    const initialQuery: EFileSearchRequest<EFileMaterialSearchRequest> = React.useMemo(
        () => ({
            query: {
                efileId: eFileId,
                entityTypes: [EntityTypeEnum.ITEM_PROFILE.name, EntityTypeEnum.VEHICLE.name],
            },
            from: 0,
            size: ITEMS_PAGE_SIZE,
            sorts: [],
        }),
        [eFileId]
    );

    const [searchView, setSearchView] = React.useState<SearchResultEFileItemSearchView | undefined>(
        undefined
    );

    const {
        getters: {
            efile: { getEFileViewModel },
        },
    } = useEFileContext();

    const eFileViewModel = getEFileViewModel();

    const { setSelectedMaterialIds } = useMaterialItems();

    const resource = React.useCallback(
        (searchRequest: EFileSearchRequest<EFileMaterialSearchRequest>, eFileId?: number) => {
            setSelectedMaterialIds([]);
            return eFileId
                ? eFileResource.searchEFileItems({
                      ...searchRequest,
                      query: {
                          ...searchRequest.query,
                          efileId: eFileId,
                      },
                  })
                : Promise.resolve(undefined);
        },
        [setSelectedMaterialIds]
    );
    const onSuccess = React.useCallback((view: SearchResultEFileItemSearchView | undefined) => {
        if (view) {
            setSearchView(view);
        }
    }, []);
    const { callResource, loading } = useResourceDeferred(resource, onSuccess);

    // load initial results when the items tab is opened
    React.useEffect(() => {
        callResource(initialQuery, eFileId);
    }, [callResource, initialQuery, eFileId]);

    // load results when the user clicks to a different page
    const {
        loading: onPaginationLoad,
        page,
        from,
        to,
        onPaginationClick,
        reloadCurrentPage,
    } = usePagination<EFileMaterialSearchRequest, SearchResultEFileItemSearchView>({
        eFileId,
        total: searchView?.totalCount || 0,
        initialSearchQuery: initialQuery,
        pageSize: ITEMS_PAGE_SIZE,
        resourceCall: resource,
        onSuccess,
        onError: noop,
    });

    React.useEffect(() => {
        setOnImport(() => reloadCurrentPage);
    }, [setOnImport, reloadCurrentPage]);

    useLoadAttributes();

    if (loading.errorMessage || onPaginationLoad.errorMessage) {
        return (
            <InlineBanner status="error">
                {loading.errorMessage || onPaginationLoad.errorMessage}
            </InlineBanner>
        );
    } else if (loading.isLoading || onPaginationLoad.isLoading || !searchView) {
        return <SkeletonTable />;
    }

    const items: EFileItemGridRowT[] = map(searchView.items, (item) => {
        return {
            itemProfile: item.itemProfile,
            materialId: item.material.id,
            sourceCaseTitle: item.sourceCaseTitle,
            sourceReportTitle: item.sourceReportTitle,
        };
    });

    return (
        <RMSTableComposite
            columns={columns}
            items={items}
            noDataText={strings.noDataText}
            includeFilter={false}
            paginationOptions={{
                pageSize: ITEMS_PAGE_SIZE,
            }}
            renderHeaderRow={({ columns }: { columns: ColumnT[] }) => (
                <EFileMaterialItemsHeaderRow
                    canEdit={!!eFileViewModel?.canEdit}
                    columns={columns}
                    materialIds={map(items, (row) => row.materialId)}
                />
            )}
            renderBodyRow={({ item }: { item: EFileItemGridRowT }) => {
                const materialId = item.materialId;
                return (
                    <EFileMaterialItemsGridRow
                        key={materialId}
                        itemRow={item}
                        onRemove={() => {
                            setSearchView((searchView) =>
                                searchView
                                    ? {
                                          ...searchView,
                                          totalCount: searchView.totalCount - 1,
                                          items: reject(
                                              searchView.items,
                                              (item) => item.material.id === materialId
                                          ),
                                      }
                                    : undefined
                            );
                        }}
                    />
                );
            }}
            renderLayout={({ tableComponent }: { tableComponent: JSX.Element }) => {
                return (
                    <>
                        <PaginationSummaryComponentWrapper>
                            <TableResultsSummary
                                from={from}
                                to={to}
                                totalResults={searchView.totalCount}
                                className={null}
                            />
                        </PaginationSummaryComponentWrapper>
                        {tableComponent}
                        {searchView.totalCount > 0 && (
                            <PaginationComponentWrapper>
                                <Pagination
                                    currentPage={page}
                                    itemsPerPage={ITEMS_PAGE_SIZE}
                                    itemCount={searchView.totalCount}
                                    onClick={onPaginationClick}
                                    maxEdgeItems={1}
                                    className={null}
                                    gapText={null}
                                />
                            </PaginationComponentWrapper>
                        )}
                    </>
                );
            }}
        />
    );
};

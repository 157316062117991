import React from 'react';
import styled from 'styled-components';
import { Avatar } from 'arc';
import Icon, { iconSizes } from '../../../../core/components/Icon';
import { PopoutLink } from '../../../../core/components/links/Link';
import testIds from '../../../../../core/testIds';

const PreTitle = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    color: ${(props) => props.theme.colors.mediumLightGrey};
    margin-right: 6px;
`;

const Title = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

const PostTitle = styled.span`
    font-weight: ${(props) => props.theme.fontWeights.normal};
    font-style: italic;
    color: ${(props) => props.theme.colors.mediumGrey};
    margin-left: 3px;
`;

const SubTitle = styled.span`
    color: ${(props) => props.theme.colors.highContrastGrey};
    margin-left: var(--arc-space-2);
`;

const HeaderContent = styled.div`
    font-size: var(--arc-fontSizes-sm);
    padding-left: 10px;
    flex: 1;
    min-width: 0;
`;

const EntitySummaryHeaderWrapper = styled.div`
    display: flex;
`;

export default function EntitySummaryHeader({
    imageUrl,
    iconType,
    preTitle,
    name,
    postTitle,
    subTitle,
    linkTo,
    tags,
    children,
    headerContentStyles,
}) {
    return (
        <EntitySummaryHeaderWrapper>
            {imageUrl ? (
                <Avatar src={imageUrl} size="md" />
            ) : (
                <Icon
                    color="var(--arc-colors-icon-default)"
                    size={iconSizes.MEDIUM}
                    type={iconType}
                />
            )}
            <HeaderContent style={headerContentStyles}>
                {preTitle && <PreTitle>{preTitle}</PreTitle>}
                {linkTo ? (
                    <span>
                        <PopoutLink to={linkTo} testId={testIds.ENTITY_SUMMARY_LINK}>
                            <Title>{name}</Title>
                            <PostTitle>{postTitle}</PostTitle>
                        </PopoutLink>
                    </span>
                ) : (
                    <span>
                        <Title>{name}</Title>
                        {postTitle && <PostTitle>{postTitle}</PostTitle>}
                    </span>
                )}
                {subTitle && <SubTitle>{subTitle}</SubTitle>}
                <div>{tags}</div>
                {children}
            </HeaderContent>
        </EntitySummaryHeaderWrapper>
    );
}

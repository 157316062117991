import React from 'react';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import { blurActiveElement } from '~/client-common/core/keyboardFocus/helpers';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import ReactHotKeysWrapper from '../../../core/hotkeys/components/ReactHotKeysWrapper';
import { SearchCardFooter } from './SearchCard';
import AdvancedSearchFormFooter from './AdvancedSearchFormFooter';

/**
 * Advanced search form. The search card footer clips at the bottom when
 *   scrolling.
 * @param {Object}   props.clippable
 * @param {boolean}  [props.includeFuzzyMatchingCheckbox=false]
 * @param {boolean}  [props.fuzzyMatchingEnabled=false]
 * @param {function} props.onSubmit
 * @param {function} props.onReset
 * @param {string}   props.searchText
 * @param {boolean}  props.formIsEmpty
 */
export default clippable()(function AdvancedSearchForm({
    clippable: { isClippedBottom = false } = {},
    includeFuzzyMatchingCheckbox = false,
    fuzzyMatchingEnabled = false,
    onSubmit,
    onReset,
    searchText,
    formIsEmpty,
    children,
    includePersonsFilterCheckboxes,
    ...otherProps
}) {
    const { SEARCH_FORM } = hotkeysActionEnum;

    const hotKeysConfig = {
        [SEARCH_FORM.name]: {
            handler: () => {
                blurActiveElement();
                if (isFunction(onSubmit)) {
                    onSubmit();
                }
            },
        },
    };

    return (
        <ReactHotKeysWrapper hotKeysConfig={hotKeysConfig}>
            <ReactReduxForm
                className={classNames('advanced-search-form', {
                    'clipped-bottom': isClippedBottom,
                })}
                {...otherProps}
            >
                {children}

                <SearchCardFooter
                    className={classNames({
                        'clipped-bottom': isClippedBottom,
                    })}
                >
                    <AdvancedSearchFormFooter
                        onSubmit={onSubmit}
                        onReset={onReset}
                        searchText={searchText}
                        searchDisabled={formIsEmpty}
                        includeFuzzyMatchingCheckbox={includeFuzzyMatchingCheckbox}
                        fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                        includePersonsFilterCheckboxes={includePersonsFilterCheckboxes}
                    />
                </SearchCardFooter>
            </ReactReduxForm>
        </ReactHotKeysWrapper>
    );
});

let resource;

// TODO: evaluate this pattern plz
export function buildResource(Resource, req) {
    resource = new Resource({
        name: 'Report History Resource',
        methods: {
            getFullReportSnapshot(reportId, rmsEventId) {
                return req({
                    method: 'GET',
                    params: {
                        rms_event_id: rmsEventId,
                    },
                    url: `reports/${reportId}/full/snapshot`,
                });
            },
            getReportShortTitleForReportSnapshot(reportId, rmsEventId) {
                return req({
                    method: 'GET',
                    params: {
                        rms_event_id: rmsEventId,
                    },
                    url: `reports/${reportId}/snapshot/related_entities`,
                });
            },
        },
    });
}

/**
 * Resources for Reports. This is temporarily a function to avoid dependency
 *   problems between /client-common/ and /client/.
 * @type {function}
 */
const resourceGetter = () => resource;
export default resourceGetter;

import { SqlSort } from '@mark43/rms-api';
import { findKey, head } from 'lodash';
import { ColumnSort, SortDirection } from '@tanstack/table-core';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
type TEntity = Record<string, any>;

type TColumn<
    TColumnName extends string,
    TAscOrder extends SqlSort['sortType'] = SqlSort['sortType'],
    TDescOrder extends SqlSort['sortType'] = SqlSort['sortType']
> = {
    id: TColumnName;
    sortKey: SqlSort['sortKey'];
    sortDirections: {
        asc: TAscOrder;
        desc: TDescOrder;
    };
};

type TConfig<
    TData extends TEntity,
    TColumns extends Partial<Record<keyof TData & string, TColumn<keyof TData & string>>> = Partial<
        Record<keyof TData & string, TColumn<keyof TData & string>>
    >
> = {
    columns: TColumns;
    defaultSorting?: {
        id: keyof TColumns;
        sortDirection: SortDirection;
    };
};
const createDataTableSortColumns = <TData extends TEntity>() => {
    return (config: TConfig<TData>) => {
        const { columns, defaultSorting } = config;

        const isSortDesc = (
            sortColumn: typeof columns[keyof TData & string],
            sortType: SqlSort['sortType']
        ) => {
            const item = findKey(sortColumn?.sortDirections, (item) => item === sortType);
            return item === 'desc';
        };

        const getDefaultSorting = (): [ColumnSort | undefined, SqlSort | undefined] => {
            if (!defaultSorting || !(defaultSorting.id in columns) || !columns[defaultSorting.id]) {
                return [undefined, undefined];
            }

            const column = columns[defaultSorting.id];

            if (!column) {
                return [undefined, undefined];
            }

            const columnSort: ColumnSort = {
                id: column.id,
                desc: defaultSorting.sortDirection === 'desc',
            };

            const sqlSort: SqlSort = {
                sortKey: column.sortKey,
                sortType: column.sortDirections[defaultSorting.sortDirection],
            };

            return [columnSort, sqlSort];
        };

        return {
            getColumnSort: (sorts: SqlSort[]): ColumnSort | undefined => {
                const sort = head(sorts);

                if (!sort) {
                    const [columnSort] = getDefaultSorting();
                    return columnSort;
                }

                const columnId = findKey<TConfig<TData>['columns']>(
                    columns,
                    (column) => column?.sortKey === sort.sortKey
                );
                if (!columnId || !columns[columnId]) {
                    const [columnSort] = getDefaultSorting();
                    return columnSort;
                }

                const column = columns[columnId];
                if (!column || !column.id) {
                    const [columnSort] = getDefaultSorting();
                    return columnSort;
                }

                return {
                    id: column.id,
                    desc: isSortDesc(column, sort.sortType),
                };
            },

            getSqlSort: (columnSort: ColumnSort): SqlSort | undefined => {
                const columnId = columnSort.id as keyof TData & string;

                if (!(columnId in columns)) {
                    return;
                }

                const column = columns[columnId];
                if (!column || !column.sortDirections) {
                    return;
                }

                return {
                    sortKey: column.sortKey,
                    sortType: column.sortDirections[columnSort.desc ? 'desc' : 'asc'],
                };
            },
        };
    };
};

export default createDataTableSortColumns;

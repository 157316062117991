import { DashboardExternalLink } from '@mark43/rms-api';
import createNormalizedModule from '../../../../utils/createNormalizedModule';
import getExternalLinksResource from '../../resources/externalLinksResource';
import { ClientCommonAction } from '../../../../../redux/types';

const externalLinksModule = createNormalizedModule<DashboardExternalLink>({
    type: 'externalLinks',
});

export const LOAD_EXTERNAL_LINKS_START = 'external-links/LOAD_EXTERNAL_LINKS_START';
export const LOAD_EXTERNAL_LINKS_SUCCESS = 'external-links/LOAD_EXTERNAL_LINKS_SUCCESS';
export const LOAD_EXTERNAL_LINKS_FAILURE = 'external-links/LOAD_EXTERNAL_LINKS_FAILURE';
export const SAVE_EXTERNAL_LINK_START = 'external-links/SAVE_EXTERNAL_LINK_START';
export const SAVE_EXTERNAL_LINK_SUCCESS = 'external-links/SAVE_EXTERNAL_LINK_SUCCESS';
export const SAVE_EXTERNAL_LINK_FAILURE = 'external-links/SAVE_EXTERNAL_LINK_FAILURE';
export const REMOVE_EXTERNAL_LINK_START = 'external-links/REMOVE_EXTERNAL_LINK_START';
export const REMOVE_EXTERNAL_LINK_SUCCESS = 'external-links/REMOVE_EXTERNAL_LINK_SUCCESS';
export const REMOVE_EXTERNAL_LINK_FAILURE = 'external-links/REMOVE_EXTERNAL_LINK_FAILURE';

// ACTIONS
const storeExternalLinks = externalLinksModule.actionCreators.storeEntities;
const deleteExternalLinks = externalLinksModule.actionCreators.deleteEntity;

function loadExternalLinksStart() {
    return { type: LOAD_EXTERNAL_LINKS_START };
}

function loadExternalLinksSuccess() {
    return { type: LOAD_EXTERNAL_LINKS_SUCCESS };
}

function loadExternalLinksFailure(errMsg: string) {
    return { type: LOAD_EXTERNAL_LINKS_FAILURE, payload: errMsg };
}

function saveExternalLinkStart() {
    return { type: SAVE_EXTERNAL_LINK_START };
}

function saveExternalLinkSuccess() {
    return { type: SAVE_EXTERNAL_LINK_SUCCESS };
}

export function saveExternalLinkFailure(errMsg: string) {
    return { type: SAVE_EXTERNAL_LINK_FAILURE, error: true, payload: errMsg };
}

function removeExternalLinkStart() {
    return { type: REMOVE_EXTERNAL_LINK_START };
}

function removeExternalLinkSuccess() {
    return { type: REMOVE_EXTERNAL_LINK_SUCCESS };
}

function removeExternalLinkFailure(errMsg: string) {
    return { type: REMOVE_EXTERNAL_LINK_FAILURE, error: true, payload: errMsg };
}

export function loadExternalLinks(): ClientCommonAction<Promise<void>> {
    const externalLinksResource = getExternalLinksResource();
    return function (dispatch) {
        dispatch(loadExternalLinksStart());
        const inner = () => {
            return externalLinksResource
                .getExternalLinks()
                .then((resp: DashboardExternalLink[]) => {
                    dispatch(storeExternalLinks(resp));
                    dispatch(loadExternalLinksSuccess());
                })
                .catch((err: Error) => dispatch(loadExternalLinksFailure(err.message)));
        };
        return inner();
    };
}

export function saveExternalLink(
    externalLink: DashboardExternalLink
): ClientCommonAction<Promise<DashboardExternalLink>> {
    return function (dispatch) {
        dispatch(saveExternalLinkStart());
        const resource = getExternalLinksResource();
        return resource
            .upsertExternalLink(externalLink)
            .then((resp: DashboardExternalLink) => {
                dispatch(storeExternalLinks(resp));
                dispatch(saveExternalLinkSuccess());
                return resp;
            })
            .catch((error: Error) => {
                dispatch(saveExternalLinkFailure(error.message));
                throw error;
            });
    };
}

export function removeExternalLink(externalLinkId: string): ClientCommonAction<Promise<void>> {
    return function (dispatch) {
        dispatch(removeExternalLinkStart());
        const resource = getExternalLinksResource();
        const id = parseInt(externalLinkId);
        return resource
            .deleteExternalLink(id)
            .then(() => {
                dispatch(deleteExternalLinks(id));
                dispatch(removeExternalLinkSuccess());
            })
            .catch((err: Error) => dispatch(removeExternalLinkFailure(err.message)));
    };
}

// SELECTORS
export const externalLinksSelector = externalLinksModule.selectors.entitiesSelector;
export const externalLinksByIdSelector = externalLinksModule.selectors.entityByIdSelector;

// REDUCER
export default externalLinksModule.reducerConfig;

import * as fields from '~/client-common/core/enums/universal/fields';
import fieldTypeClientEnum from '~/client-common/core/enums/client/fieldTypeClientEnum';

import { buildFlatFormFieldViewModels } from '../../../../../legacy-redux/helpers/formHelpers';

const { ATTRIBUTE } = fieldTypeClientEnum;

export const itemSplitPropertyStatusFieldsetFields = buildFlatFormFieldViewModels([
    {
        key: 'declaredValue',
        fieldName: fields.PROPERTY_STATUS_DECLARED_VALUE,
    },
    {
        key: 'quantity',
        fieldName: fields.PROPERTY_STATUS_QUANTITY,
    },
    {
        key: 'measurementUnitsAttrId',
        type: ATTRIBUTE,
        fieldName: fields.PROPERTY_STATUS_MEASUREMENT_UNITS_ATTR_ID,
    },
]);

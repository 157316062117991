import Color from '@arcgis/core/Color';
import { UniqueValueRenderer } from '@arcgis/core/renderers';
import { SimpleFillSymbol } from "@arcgis/core/symbols";
import UniqueValueInfo from "@arcgis/core/renderers/support/UniqueValueInfo";

import type { SelectedShapefileT } from "../types";
import { stringToColor } from "./avlHelpers";


const outline = {
    color: new Color('white'),
};

export const getRendererForJson = (geoJSON: SelectedShapefileT): UniqueValueRenderer | undefined => {
    if (!geoJSON.propertyName || !geoJSON.properties) {
        return undefined;
    }

    const renderer = new UniqueValueRenderer({
        field: geoJSON.propertyName,
        defaultSymbol: new SimpleFillSymbol({
            color: new Color('orange'),
            outline,
        }),
        uniqueValueInfos:
            geoJSON.properties[geoJSON.propertyName]?.map((property) => new UniqueValueInfo({
                value: property,
                symbol: new SimpleFillSymbol({
                    color: new Color(stringToColor(property.toString(), 0.6)),
                    style: 'solid',
                    outline,
                }),
            })) ?? [],
    });
    
    return renderer;
};

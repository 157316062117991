import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, iconSizes } from 'components-mark43';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Button from '../Button';
import { iconTypes } from '../../../../modules/core/components/Icon';
import { ResultsDivider } from './ResultsDivider';

const strings = componentStrings.quickSearch.QuickSearchResults;

const ShowMoreWrapper = styled.div`
    width: 100%;
    height: 28px;
    margin-bottom: 10px;
`;

const ShowMoreButton = styled(Button)`
    border: 0;
    width: 100%;
    padding: 0;

    &:active,
    &:hover {
        border: 0;
    }
`;

interface ResultsGroupingProps {
    showDivider: boolean;
    showLoadMore: boolean;
    showCollapseButton: boolean;
    rightElement: React.ReactNode;
    children: React.ReactNode;
    groupingDisplay: string;
    customStyles?: React.CSSProperties;
    maxResults?: number;
}

export const ResultsGrouping = (props: ResultsGroupingProps) => {
    const maxResults = props.maxResults ? props.maxResults : 5;
    const childrenLength = React.Children.count(props.children);
    const [showChildren, setShowChildren] = useState(true);
    const [resultLimit, setResultLimit] = useState(maxResults);

    useEffect(() => {
        setResultLimit(
            props.showDivider && props.showCollapseButton
                ? Math.min(maxResults, childrenLength)
                : childrenLength
        );
    }, [props.showDivider, maxResults, childrenLength, props.showCollapseButton]);

    return (
        <>
            {props.showDivider && (
                <ResultsDivider
                    showCollapseButton={props.showCollapseButton}
                    groupingDisplay={props.groupingDisplay}
                    onClick={() => setShowChildren(!showChildren)}
                    customStyles={props.customStyles}
                    rightElement={props.rightElement}
                />
            )}

            {showChildren && React.Children.toArray(props.children).slice(0, resultLimit)}
            {props.showDivider &&
                props.showLoadMore &&
                showChildren &&
                resultLimit !== childrenLength && (
                    <ShowMoreWrapper>
                        <ShowMoreButton
                            iconRight={
                                <Icon
                                    type={iconTypes.OPEN}
                                    size={iconSizes.SMALL}
                                    color="cobaltBlue"
                                />
                            }
                            onClick={(event) => {
                                setResultLimit(Math.min(resultLimit + maxResults, childrenLength));
                                event?.stopPropagation();
                            }}
                        >
                            {strings.showMore}
                        </ShowMoreButton>
                    </ShowMoreWrapper>
                )}
        </>
    );
};

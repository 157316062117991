import React from 'react';
import styled from 'styled-components';

import componentStrings from '~/client-common/core/strings/componentStrings';
import testIds from '../../../../core/testIds';
import { quickSearchResultTypeToPropMap } from '../config';
import QuickSearchResultsSection from './QuickSearchResultsSection';

const { quickSearch: quickSearchStrings } = componentStrings;

const QuickSearchResultsWrapper = styled.div`
    max-height: 100%;
    overflow: auto;
`;

const QuickSearchNotification = styled.div`
    font: ${(props) =>
        `${props.theme.fontWeights.semiBold} var(--arc-fontSizes-md) ${props.theme.fontFamilies.proximaNova}`};
    color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.darkGrey)};
    padding: 20px 10px;
`;

const QuickSearchResults = ({
    results,
    resultTypes,
    isLoading,
    isSectionLoadingForType,
    query,
    hasResults,
    error,
    onResultClick,
    excludeExternalAgencyResults,
    minQueryLength,
    maxQueryLength,
    triggerQuickSearchLoadMoreForType,
}) => {
    const hasQuery = query.length >= (minQueryLength || 1);
    const queryTooLong = query.length > (maxQueryLength || 200);
    const showNoResultsMessage = !isLoading && hasQuery && !queryTooLong && !hasResults && !error;
    const showQueryTooLongMessage = !isLoading && hasQuery && queryTooLong && !hasResults && !error;
    const showErrorMessage = hasQuery && !queryTooLong && error;
    const showSearchingMessage = isLoading;
    const showResults = !isLoading && hasQuery && !queryTooLong && hasResults;

    return (
        <QuickSearchResultsWrapper
            data-test-state={showNoResultsMessage || showResults ? 'SEARCH_DONE' : undefined}
        >
            {showQueryTooLongMessage && (
                <QuickSearchNotification
                    error={false}
                    data-test-id={testIds.QUICK_SEARCH_NOTIFICATION_QUERY_TOO_LONG}
                >
                    {quickSearchStrings.QuickSearchResults.queryTooLong}
                </QuickSearchNotification>
            )}
            {showNoResultsMessage && (
                <QuickSearchNotification
                    error={false}
                    data-test-id={testIds.QUICK_SEARCH_NOTIFICATION_NO_RESULTS}
                >
                    {quickSearchStrings.QuickSearchResults.noResults}
                </QuickSearchNotification>
            )}
            {showSearchingMessage && (
                <QuickSearchNotification
                    error={false}
                    data-test-id={testIds.QUICK_SEARCH_NOTIFICATION_SEARCHING}
                >
                    {quickSearchStrings.QuickSearchResults.searching}
                </QuickSearchNotification>
            )}
            {showErrorMessage && (
                <QuickSearchNotification
                    error={true}
                    data-test-id={testIds.QUICK_SEARCH_NOTIFICATION_ERROR}
                >
                    {quickSearchStrings.QuickSearchResults.error}
                    <br />
                    {error}
                </QuickSearchNotification>
            )}
            {showResults &&
                resultTypes.map((type) => (
                    <QuickSearchResultsSection
                        key={type}
                        isSectionLoading={isSectionLoadingForType(type)}
                        triggerQuickSearchLoadMoreForType={triggerQuickSearchLoadMoreForType}
                        type={type}
                        query={query}
                        results={results[quickSearchResultTypeToPropMap[type]]}
                        onResultClick={onResultClick}
                        excludeExternalAgencyResults={excludeExternalAgencyResults}
                    />
                ))}
        </QuickSearchResultsWrapper>
    );
};

export default QuickSearchResults;

import React from 'react';
import { mapValues } from 'lodash';

import dateTypeEnum from '~/client-common/core/enums/client/dateTypeEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';
import Row from '../../../core/components/Row';
import { RRFFieldset } from '../../../core/forms/components/Fieldset';
import { RRFSelect } from '../../../core/forms/components/selects/Select';
import RRFTimeRangeElement from '../../../core/forms/components/RRFTimeRangeElement';
import { RRFDateRangePicker } from '../../../core/forms/components/DateRangePicker';
import { dateTimeFieldsetViewModel } from '../state/forms/reportsDashboardSearchForm';

const labels = mapValues(dateTimeFieldsetViewModel.fields, 'label');

const strings = componentStrings.reports.dashboard;

const dateTypeOptions = [
    { display: 'Event', value: dateTypeEnum.EVENT },
    { display: 'Modified', value: dateTypeEnum.MODIFIED },
    { display: 'Created', value: dateTypeEnum.CREATED },
];

export default function DateTimeFieldset({ onSubmit }) {
    return (
        <RRFFieldset path="dateTime" title={strings.DateTimeFieldset.title}>
            <Row>
                <RRFSelect
                    path="dateType"
                    label={labels.dateType}
                    options={dateTypeOptions}
                    width={225}
                    clearable={false}
                    onChange={onSubmit}
                />
                <RRFDateRangePicker
                    label={labels.startDateUtc}
                    width={225}
                    includeTime={true}
                    withinLastPeriodOptions={['PT12H', 'PT24H', 'PT48H', 'P7D', 'P28D']}
                    toDatePeriodOptions={['P1M', 'P1Y']}
                    onChange={onSubmit}
                />
                <RRFTimeRangeElement onChange={onSubmit} />
            </Row>
        </RRFFieldset>
    );
}

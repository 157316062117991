import { NotificationStatusEnum } from '@mark43/rms-api';
import { noop } from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import componentStrings from '~/client-common/core/strings/componentStrings';

import {
    markAllUserNotifications as _markAllUserNotifications,
    MarkAllUserNotificationProps,
} from '~/client-common/core/domain/user-notifications/state/data';
import testIds from '../../../../../core/testIds';
import { NavItemToggle } from '../../../../core/components/Navigation/components';

import {
    notificationsUnreadCountSelector,
    markingUserNotificationsSelector,
    previousPollDateUtcSelector,
} from '../../../../notifications/navigation-link/state/ui';
import UpdatedNotificationsPopoverContent from '../../../../notifications/popover/components/UpdatedNotificationsPopover';
import { RmsDispatch } from '../../../../../core/typings/redux';

const { READ, UNREAD } = NotificationStatusEnum;

function NotificationsNavigationLink() {
    const unreadCount: number = useSelector(notificationsUnreadCountSelector);
    const markingUserNotifications = useSelector(markingUserNotificationsSelector);
    const previousPollDateUtc = useSelector(previousPollDateUtcSelector);

    const dispatch = useDispatch<RmsDispatch>();
    const markAllUserNotifications = (query: MarkAllUserNotificationProps) =>
        dispatch(_markAllUserNotifications(query));

    const handleOnClick = () => {
        return () => {
            // mark notifications as read asynchronously in the background,
            // and silently ignore any API error
            if (unreadCount > 0 && !markingUserNotifications) {
                markAllUserNotifications({
                    beforeStatuses: [UNREAD.name],
                    afterStatus: READ.name,
                    endDateUtc: previousPollDateUtc,
                }).catch(noop);
            }
        };
    };

    return (
        <UpdatedNotificationsPopoverContent
            renderButton={(props) => {
                return (
                    // @ts-expect-error union too complex
                    <NavItemToggle
                        label={componentStrings.core.navigation.notifications}
                        icon="Notification"
                        count={unreadCount}
                        data-test-id={testIds.MODULE_NOTIFICATIONS}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            handleOnClick();
                            return props.onClick ? props.onClick(e) : undefined;
                        }}
                        ref={props.ref}
                        isActive={props.isActive}
                    />
                );
            }}
        />
    );
}

export default NotificationsNavigationLink;

import React from 'react';

import styled from 'styled-components';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import testIds from '../../../../../core/testIds';

const BannerContainer = styled.div`
    background: ${(props) => props.theme.colors.red};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ContentContainer = styled.div`
    width: ${(props) => props.theme.widths.container}px;
    display: flex;
    gap: var(--arc-space-2);
    padding: var(--arc-space-2);
`;

const BannerText = styled.div`
    display: inline-block;
    line-height: 15px;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
`;

export default function RestrictedReportBanner({ className, bannerText, getRef }) {
    return (
        <BannerContainer className={className} ref={(elem) => getRef(elem)}>
            <ContentContainer data-test-id={testIds.RESTRICTED_BANNER_TEXT}>
                <Icon color="white" type={iconTypes.PERMISSIONS} size={15} />
                <BannerText>{bannerText}</BannerText>
            </ContentContainer>
        </BannerContainer>
    );
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, lifecycle, pure, withHandlers } from 'recompose';

import { consortiumDepartmentLinksAvailableSelector } from '~/client-common/core/domain/consortium-link-view/state/ui';
import { ownerNameItemAttributeIdsSelector } from '~/client-common/core/domain/name-item-links/state/data/';
import componentStrings from '~/client-common/core/strings/componentStrings';

import advancedSearchProperty from '../state/ui';
import { currentUserDepartmentIdSelector } from '../../../core/current-user/state/ui';
import FormSection from '../../../core/forms/components/FormSection';
import AdvancedSearchForm from '../../core/components/AdvancedSearchForm';
import IdentifiersFieldset from '../../core/components/IdentifiersFieldset';
import PersonDetailsFieldset from '../../core/components/PersonDetailsFieldset';
import { PropertyFieldset } from '../../core/components/PropertiesFieldset';
import { SearchName } from '../../core/components/SearchName';

const strings = componentStrings.search.AdvancedSearchPropertyForm;

const PropertySection = pure(function PropertySection(props) {
    return (
        <FormSection>
            <PropertyFieldset highlightOnFocus={false} {...props} />
        </FormSection>
    );
});

const OwnerSection = pure(function OwnerSection({ fuzzyMatchingEnabled }) {
    return (
        <FormSection title={strings.sectionTitles.owner}>
            <PersonDetailsFieldset fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
            <IdentifiersFieldset fuzzyMatchingEnabled={fuzzyMatchingEnabled} showHeader={false} />
        </FormSection>
    );
});

function AdvancedSearchPropertyForm({
    fuzzyMatchingEnabled,
    formIsEmpty,
    consortiumDepartmentLinksAvailable,
    onSubmit,
    onReset,
    currentSavedSearch,
}) {
    return (
        <AdvancedSearchForm
            {...advancedSearchProperty.form}
            includeFuzzyMatchingCheckbox={true}
            fuzzyMatchingEnabled={fuzzyMatchingEnabled}
            onSubmit={onSubmit}
            onReset={onReset}
            formIsEmpty={formIsEmpty}
            searchText={strings.search}
        >
            <SearchName currentSavedSearch={currentSavedSearch} isAdvancedSearch={true} />
            <PropertySection
                fuzzyMatchingEnabled={fuzzyMatchingEnabled}
                includeDepartmentIds={consortiumDepartmentLinksAvailable}
            />
            <hr />
            <OwnerSection fuzzyMatchingEnabled={fuzzyMatchingEnabled} />
        </AdvancedSearchForm>
    );
}

const mapStateToProps = createStructuredSelector({
    fuzzyMatchingEnabled: advancedSearchProperty.form.selectors.buildFormModelSelectorByPath(
        'fuzzyMatchingEnabled'
    ),
    formIsEmpty: advancedSearchProperty.form.selectors.formIsEmptySelector,
    consortiumDepartmentLinksAvailable: consortiumDepartmentLinksAvailableSelector,
    currentUserDepartmentId: currentUserDepartmentIdSelector,
    ownerNameItemAttributeIds: ownerNameItemAttributeIdsSelector,
    currentSavedSearch: advancedSearchProperty.selectors.currentSavedSearchSelector,
});

const mapDispatchToProps = (dispatch) => ({
    onSubmit: (additionalDataForConvertFromFormModel) =>
        dispatch(
            advancedSearchProperty.form.actionCreators.submit((formData) =>
                dispatch(
                    advancedSearchProperty.actionCreators.search(
                        {
                            formData,
                            additionalDataForConvertFromFormModel,
                            from: 0,
                        },
                        { cacheBust: true }
                    )
                )
            )
        ),
    onReset: () => {
        dispatch(advancedSearchProperty.form.actionCreators.reset());
        dispatch(advancedSearchProperty.actionCreators.resetState());
        dispatch(advancedSearchProperty.actionCreators.setIsSavedSearchUpdatable(false));
        dispatch(advancedSearchProperty.actionCreators.setExecutedSavedSearchToUpdate(null));
    },
    initializeFormFields: (props) => {
        if (props.consortiumDepartmentLinksAvailable) {
            dispatch(
                advancedSearchProperty.form.actionCreators.change({
                    departmentIds: [props.currentUserDepartmentId],
                })
            );
        }
    },
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withHandlers({
        onSubmit: ({ onSubmit, ownerNameItemAttributeIds }) => () =>
            onSubmit({ ownerNameItemAttributeIds }),
    }),
    lifecycle({
        UNSAFE_componentWillMount() {
            // if the form is empty, this means that we didn't execute a saved search.
            // In this case we have to prefill the department select once
            // on component mount so that user has a sensible default value
            if (this.props.formIsEmpty) {
                this.props.initializeFormFields(this.props);
            }
        },
    })
)(AdvancedSearchPropertyForm);

import React from 'react';
import PropTypes from 'prop-types';
import { filter, map, uniq, find, truncate, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, getContext } from 'recompose';
import styled from 'styled-components';

import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { fieldConfigurationByFieldNameSelector } from '~/client-common/core/domain/field-configurations/state/data';
import { fieldDetailByIdSelector } from '~/client-common/core/domain/field-details/state/data';
import { shouldShowFieldNamesSelector } from '../../../../legacy-redux/selectors/globalSelectors';

import { OnlyWithAbility, abilitiesEnum } from '../../abilities';
import { Tooltip } from '../tooltip';
import Icon, { iconTypes } from '../Icon';
import Link, { PopoutLink, CustomLink } from './Link';

const FieldName = styled.div`
    clear: left;
`;

const TooltipRow = styled.div`
    display: flex;
    align-items: center;
`;

const TooltipText = styled.span`
    margin-left: 10px;
`;

function getRulesForFieldName({ dataGetters, fieldConfiguration }) {
    const ruleConditions = filter(
        dataGetters.ruleConditions(),
        (rc) => fieldConfiguration.id === rc.fieldConfigurationId
    );
    const rules = uniq(map(ruleConditions, (rc) => dataGetters.rules()[rc.ruleId]));
    const ruleViews = map(rules, (rule) => ({
        rule,
        ruleConfigurationContext: find(dataGetters.ruleConfigurationContexts(), (rcc) => {
            return rcc.ruleId === rule.id;
        }),
    }));
    // it's possible, at this point, for there to not be a ruleConfigurationContext on the ruleView
    // in a case where a field from the current context is also used in a rule in another context
    // if that happens, we simply omit that ruleView
    return filter(ruleViews, (ruleView) => ruleView.ruleConfigurationContext);
}

const RulesOverlay = ({ ruleViews }) => (
    <div>
        {ruleViews.length === 0
            ? 'No rules for this field'
            : map(
                  sortBy(ruleViews, (rv) => rv.isDisabled),
                  (ruleView) => (
                      <TooltipRow>
                          <Icon
                              type={
                                  ruleView.ruleConfigurationContext.isDisabled
                                      ? iconTypes.REJECTED
                                      : iconTypes.SUPERVISOR_APPROVED
                              }
                          />
                          <TooltipText>
                              {truncate(
                                  `[${ruleView.rule.ruleType}] ${ruleView.rule.displayName}`,
                                  {
                                      length: 64,
                                  }
                              )}
                          </TooltipText>{' '}
                          <PopoutLink to={`/admin/rules/${ruleView.rule.id}`} />
                      </TooltipRow>
                  )
              )}
    </div>
);

function FieldLink({
    fieldName,
    fieldConfigurationByFieldName,
    shouldShowFieldNames,
    className,
    style,
    router,
    dataGetters,
}) {
    if (!shouldShowFieldNames) {
        return null;
    }
    const fieldConfiguration = fieldConfigurationByFieldName[fieldName];

    if (!fieldName || !fieldConfiguration) {
        // maybe error when fieldConfiguration doesn't exist
        return null;
    }

    const ruleViews = dataGetters
        ? getRulesForFieldName({
              dataGetters,
              fieldName,
              fieldConfiguration,
          })
        : undefined;

    // If the router is not in context, links cannot be rendered here. This is possible because not
    // every Backbone view that renders a React component passes in the router as a prop, and not
    // every component rendered that way puts the router on its context.
    const showLinks = !!router;

    return (
        <FeatureFlagged flag="RMS_TOGGLE_FIELD_LABELS_ENABLED">
            <FieldName className={className} style={style}>
                <OnlyWithAbility has={abilitiesEnum.ADMIN.VIEW_VALIDATION}>
                    <div>
                        Field:{' '}
                        {showLinks ? (
                            <Link to={`/admin/fields/${fieldConfiguration.id}`} openInNewTab={true}>
                                {fieldConfiguration.originalDisplayName}
                            </Link>
                        ) : (
                            <span>{fieldConfiguration.originalDisplayName}</span>
                        )}
                        {ruleViews ? (
                            <div>
                                <Tooltip
                                    side="right"
                                    content={<RulesOverlay ruleViews={ruleViews} />}
                                >
                                    <CustomLink>
                                        {`${ruleViews.length} Rule(s) `}
                                        <Icon
                                            size={12}
                                            type={iconTypes.OPEN_NEW_WINDOW}
                                            color="cobaltBlue"
                                        />
                                    </CustomLink>
                                </Tooltip>
                            </div>
                        ) : null}
                    </div>
                </OnlyWithAbility>
            </FieldName>
        </FeatureFlagged>
    );
}

const mapStateToProps = createStructuredSelector({
    fieldConfigurationByFieldName: fieldConfigurationByFieldNameSelector,
    fieldDetailById: fieldDetailByIdSelector,
    shouldShowFieldNames: shouldShowFieldNamesSelector,
});

/**
 * A link to the field admin page.
 *
 * If the field is an attribute, link to the attribute type's admin page too.
 * @param {string} props.fieldName
 */
export default compose(
    connect(mapStateToProps),
    getContext({ router: PropTypes.object, dataGetters: PropTypes.object })
)(FieldLink);

import { AttributeTypeEnum } from '@mark43/rms-api';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash';
import pluralize from 'pluralize';

import styled from 'styled-components';
import { Observer, lifecycleOptions } from 'markformythree';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { formatWarrantShortTitle } from '~/client-common/helpers/warrantHelpers';
import { booleanToYesNo } from '~/client-common/helpers/stringHelpers';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import useFields from '~/client-common/core/fields/hooks/useFields';
import { DISPLAY_ONLY_WARRANT_LABEL } from '~/client-common/core/enums/universal/fields';

import { CardSection, CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import Row from '../../../../core/components/Row';
import ArbiterForm from '../../../../core/markformythree-arbiter/ArbiterForm';
import { ArbiterMFTText } from '../../../../core/forms/components/Text';
import { ArbiterMFTCheckbox } from '../../../../core/forms/components/checkboxes/Checkbox';
import { ArbiterMFTNItems } from '../../../../core/forms/components/NItems';
import IndentedFields from '../../../../core/components/IndentedFields';
import { ArbiterMFTAttributeTextFields } from '../../../../core/forms/components/AttributeTextFields';
import PaddedNoDataBlock from '../../../../core/components/PaddedNoDataBlock';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SemiBoldSummaryTitle from '../../../../core/components/SemiBoldSummaryTitle';
import Icon, { iconTypes } from '../../../../../legacy-redux/components/core/Icon';
import { formName } from '../../state/forms/arrestForm';

const strings = componentStrings.reports.core.WarrantSection;

function WarrantChecksSummary({ warrantCheckTypeAttributes, formatAttributeById }) {
    return (
        <div>
            {map(warrantCheckTypeAttributes, (attribute) => {
                return (
                    <SummaryRow
                        key={attribute.attributeId}
                        label={formatAttributeById(attribute.attributeId)}
                    >
                        {attribute.description}
                    </SummaryRow>
                );
            })}
        </div>
    );
}

const FlexRow = styled.div`
    display: flex;
`;

const WarrantTitle = styled(SemiBoldSummaryTitle)`
    display: inline-block;
    width: 415px;
`;

const WarrantIcon = styled(Icon)`
    &::before {
        width: 25px;
    }
`;

const WarrantSummaryList = styled(SummaryList)`
    padding-left: 25px;
`;

const TicketNumberSummary = styled(SummaryList)`
    padding-top: 10px;
`;

function WarrantsSummary({ warrants, formatAttributeById }) {
    const warrantLabel = useFields(DISPLAY_ONLY_WARRANT_LABEL)[DISPLAY_ONLY_WARRANT_LABEL];
    return warrants.length > 0 ? (
        <div>
            {map(warrants, (warrant) => (
                <div>
                    <div>
                        <WarrantIcon type={iconTypes.CHARGE} fontSize="22px" />
                        <WarrantTitle>
                            {formatWarrantShortTitle({
                                warrantNumber: warrant.warrantNumber,
                                warrantType: formatAttributeById(warrant.warrantTypeAttrId),
                            })}
                        </WarrantTitle>
                    </div>
                    <WarrantSummaryList labelWidth={160} contentWidth={350}>
                        <SummaryRow fieldName={fields.WARRANT_DESCRIPTION}>
                            {warrant.description}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.WARRANT_IS_OTHER_JURISDICTION}>
                            {booleanToYesNo(warrant.isOtherJurisdiction)}
                        </SummaryRow>
                    </WarrantSummaryList>
                </div>
            ))}
        </div>
    ) : (
        <PaddedNoDataBlock>{strings.noWarrants(warrantLabel)}</PaddedNoDataBlock>
    );
}

const WarrantSectionSummary = connect(
    createStructuredSelector({
        formatAttributeById: formatAttributeByIdSelector,
    })
)(function WarrantSectionSummary({
    warrantCheckTypeAttributes,
    warrants,
    arrest,
    formatAttributeById,
}) {
    const warrantLabel = useFields(DISPLAY_ONLY_WARRANT_LABEL)[DISPLAY_ONLY_WARRANT_LABEL];
    return (
        <CardSection title={`${pluralize(warrantLabel)} (${warrants.length})`}>
            {warrantCheckTypeAttributes.length > 0 && (
                <CardSubsection title={strings.checks}>
                    <SummaryList labelWidth={160} contentWidth={350}>
                        <WarrantChecksSummary
                            warrantCheckTypeAttributes={warrantCheckTypeAttributes}
                            formatAttributeById={formatAttributeById}
                        />
                    </SummaryList>
                </CardSubsection>
            )}
            <CardSubsection title={pluralize(warrantLabel)}>
                <WarrantsSummary warrants={warrants} formatAttributeById={formatAttributeById} />
            </CardSubsection>
            <TicketNumberSummary labelWidth={160} contentWidth={350}>
                <SummaryRow fieldName={fields.ARREST_TICKET_NUMBERS}>
                    {arrest.ticketNumbers}
                </SummaryRow>
            </TicketNumberSummary>
        </CardSection>
    );
});

function WarrantFormSection({ index, children }) {
    const warrantLabel = useFields(DISPLAY_ONLY_WARRANT_LABEL)[DISPLAY_ONLY_WARRANT_LABEL];
    return (
        <Observer
            formName={formName}
            formIndex={index}
            subscriptions={{ warrants: 'warrants' }}
            render={({ warrants }) => (
                <CardSection title={`${pluralize(warrantLabel)} (${warrants.length})`}>
                    {children}
                </CardSection>
            )}
        />
    );
}

/**
 * Warrant section only shows for Arrest V2
 */
export default function WarrantSection({
    summaryMode,
    warrants,
    arrest,
    warrantCheckTypeAttributes,
}) {
    const warrantLabel = useFields(DISPLAY_ONLY_WARRANT_LABEL)[DISPLAY_ONLY_WARRANT_LABEL];
    return summaryMode ? (
        <WarrantSectionSummary
            warrants={warrants}
            arrest={arrest}
            warrantCheckTypeAttributes={warrantCheckTypeAttributes}
        />
    ) : (
        <ArbiterForm
            name={formName}
            context={formName}
            index={arrest.reportId}
            lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
            render={() => (
                <WarrantFormSection index={arrest.reportId}>
                    <CardSubsection title={strings.checks}>
                        <ArbiterMFTAttributeTextFields
                            path="arrestAttributes.WARRANT_CHECK_TYPES"
                            attributeType={AttributeTypeEnum.WARRANT_CHECK_TYPES.name}
                        />
                    </CardSubsection>
                    <CardSubsection title={pluralize(warrantLabel)}>
                        <ArbiterMFTNItems
                            path="warrants"
                            addText={warrantLabel}
                            automaticallyIncludeDeleteButton={false}
                            minimumNumberOfItems={0}
                        >
                            {(item, index, deleteButton) => (
                                <div>
                                    <FlexRow>
                                        <ArbiterMFTText path="warrantNumber" />
                                        <ArbiterMFTText path="description" />
                                        {deleteButton}
                                    </FlexRow>
                                    <IndentedFields>
                                        <Row>
                                            <ArbiterMFTCheckbox path="isOtherJurisdiction" />
                                        </Row>
                                    </IndentedFields>
                                </div>
                            )}
                        </ArbiterMFTNItems>
                    </CardSubsection>
                    <Row>
                        <ArbiterMFTText length="lg" path="arrest.ticketNumbers" />
                    </Row>
                </WarrantFormSection>
            )}
        />
    );
}

import { LinkTypesEnum } from '@mark43/rms-api';
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withFields from '~/client-common/core/fields/components/withFields';
import { formatAttributeByIdSelector } from '~/client-common/core/domain/attributes/state/data';
import { joinTruthyValues } from '~/client-common/helpers/stringHelpers';
import { formatMiniUserShortHandByIdSelector } from '~/client-common/core/domain/mini-users/state/data';
import {
    CAD_TICKET_CAD_AGENCY_EVENT_NUMBER,
    REPORT_REPORTING_EVENT_NUMBER,
} from '~/client-common/core/enums/universal/fields';

import { FormattedDate } from '~/client-common/core/dates/components';
import GenericContainer from '../layout/GenericContainer';
import { useSubPremiseFormatters } from '../../../../core/locations/hooks/useSubPremiseFormatters';
import Icon, { iconTypes } from '../../../../core/components/Icon';
import WrappedFormattedDate from '../WrappedFormattedDate';
import QuickSearchResultSectionItemTitle from '../QuickSearchResultSectionItemTitle';
import Highlighter from '../QuickSearchHighlighter';
import LocationWrapper from '../layout/LocationWrapper';
import ExternalDepartmentName from '../ExternalDepartmentName';
import EntityTypeIconContainer from '../layout/EntityTypeIconContainer';

const Wrapper = styled.div`
    padding: 10px 10px 10px 42px;
    position: relative;
`;

const QuickSearchResultSectionOrganizationItem = ({
    query,
    item,
    fieldDisplayNames,
    theme,
    formatMiniUserShortHandById,
    formatAttributeById,
    externalDepartmentName,
    testId,
    testType,
}) => {
    const searchWords = [query];
    const cadEventNumberLabel = fieldDisplayNames[CAD_TICKET_CAD_AGENCY_EVENT_NUMBER];
    const renLabel = fieldDisplayNames[REPORT_REPORTING_EVENT_NUMBER];
    const cadAEN = `${cadEventNumberLabel}: ${item.agencyEventNumber ?? ''}`;
    const cadREN = item.ren ? ` ${renLabel}: ${item.ren}` : '';
    const title = `${cadAEN}${cadREN}`;
    const primaryCallSigns = joinTruthyValues([...item.primaryUnitCallSigns].sort(), ', ');
    const primaryOfficers = joinTruthyValues(
        item.primaryOfficerIds.map(formatMiniUserShortHandById).sort(),
        ', '
    );
    const separator = primaryOfficers ? ': ' : '';
    const officerInfo = `${primaryCallSigns}${separator}${primaryOfficers}`;
    const callType = formatAttributeById(item.callTypeAttrId);
    const callEventType = formatAttributeById(item.callEventTypeAttrId);
    const location = item.involvedLocations.find(
        ({ type }) => type === LinkTypesEnum.LOCATION_OF_CAD_TICKET
    );
    const { buildElasticLocationLines } = useSubPremiseFormatters();

    return (
        <Wrapper data-test-id={testId} data-test-type={testType}>
            <EntityTypeIconContainer>
                <Icon color="cobaltBlue" type={iconTypes.CAD} size={24} />
            </EntityTypeIconContainer>
            <QuickSearchResultSectionItemTitle width="calc(100% - 106px)">
                <Highlighter searchWords={searchWords} textToHighlight={title} />{' '}
                <ExternalDepartmentName theme={theme} departmentName={externalDepartmentName} />
            </QuickSearchResultSectionItemTitle>
            <WrappedFormattedDate
                date={item.startDateUtc}
                format={FormattedDate.FORMATS.TABLE_DATE_TIME}
                width="106px"
            />
            {!externalDepartmentName && (
                <GenericContainer block={true} color={theme.colors.lightGrey}>
                    <Highlighter searchWords={searchWords} textToHighlight={`${officerInfo}`} />
                </GenericContainer>
            )}
            <GenericContainer block={true} color={theme.colors.lightGrey}>
                <Highlighter
                    searchWords={searchWords}
                    textToHighlight={`${callType}: ${callEventType}`}
                />
            </GenericContainer>
            {location && (
                <LocationWrapper>
                    <Highlighter
                        searchWords={searchWords}
                        textToHighlight={buildElasticLocationLines(location).join(', ')}
                    />
                </LocationWrapper>
            )}
        </Wrapper>
    );
};

export default compose(
    withFields([CAD_TICKET_CAD_AGENCY_EVENT_NUMBER, REPORT_REPORTING_EVENT_NUMBER]),
    withTheme,
    connect((state) => ({
        formatAttributeById: formatAttributeByIdSelector(state),
        formatMiniUserShortHandById: formatMiniUserShortHandByIdSelector(state),
    }))
)(QuickSearchResultSectionOrganizationItem);

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { max } from 'lodash';

import { fromSizeToPage } from '~/client-common/helpers/searchHelpers';
import { canRead } from '~/client-common/core/domain/entity-permissions/state/ui';
import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sortKeyEnum from '~/client-common/core/enums/universal/sortKeyEnum';
import componentStrings from '~/client-common/core/strings/componentStrings';

import advancedSearchProperty from '../state/ui';
import StaleQueryNotificationBar from '../../core/components/StaleQueryNotificationBar';
import AdvancedSearchResultsTableHeader from '../../core/components/AdvancedSearchResultsTableHeader';
import Table from '../../../../legacy-redux/components/core/tables/Table';
import TableColumn from '../../../../legacy-redux/components/core/tables/TableColumn';
import OptionsTableColumn, {
    OptionsTableColumnOption,
} from '../../../../legacy-redux/components/core/tables/OptionsTableColumn';
import TableHeader from '../../../../legacy-redux/components/core/tables/TableHeader';
import TableBody from '../../../../legacy-redux/components/core/tables/TableBody';
import ElasticResultRow from '../../../../legacy-redux/components/core/tables/ElasticResultRow';
import TableColumns from '../../../../legacy-redux/components/core/tables/TableColumns';
import ImageCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/ImageCell';
import PropertyCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/PropertyCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/IdentifiersCell';
import OwnerCell from '../../../../legacy-redux/components/core/tables/elasticPropertyTable/OwnerCell';
import { clippable } from '../../../../legacy-redux/helpers/reactHelpers';
import { savedSearchIsStale } from '../../core/utils/savedSearchIsStale';
import { useDashboardScroll } from '../../core/hooks/useDashboardScroll';
import useOnSaveSearchToggleHandler from '../../core/hooks/useOnSaveSearchToggleHandler';

const strings = componentStrings.search.AdvancedSearchPropertyResults;

// 107 is the height of the table header
// todo refactor to genericize
const ClippableSearchTable = clippable({ bottomBuffer: 107 })(Table);

function AdvancedSearchPropertyResults({
    // Current Query and Search Results
    results = [],
    query: { savedSearchId, from, size, sortKey: currentSortKey, sortType: currentSortType },
    totalCount: totalResults,

    // Table UI State
    tableLoading,
    searchFilters,
    selectedRows,
    highlightedRows,

    // Event Handlers
    handleRowSelect,
    handleRowClick,
    handleSortByOptionClick,
    handleSortBySortTypeClick,
    handlePaginationClick,
    handleSizeChange,

    // Saved Search
    savedSearchErrorMessage,
    savedSearches,
    router,
}) {
    const [getCurrentScrollPosition] = useDashboardScroll({
        router,
        searchModule: advancedSearchProperty,
        isScrollToElement: true,
    });
    const currentPage = max([1, fromSizeToPage(from + 1, size)]);
    const onSaveSearchToggle = useOnSaveSearchToggleHandler(advancedSearchProperty);

    return (
        <div>
            {savedSearchIsStale(savedSearches, savedSearchId) && <StaleQueryNotificationBar />}
            <ClippableSearchTable
                data={results}
                onRowClick={handleRowClick(getCurrentScrollPosition)}
                sortKey={currentSortKey}
                sortType={currentSortType}
                noRowsText={strings.noResults}
                disableBody={tableLoading}
                selectableRows={true}
                selectedRows={selectedRows}
                highlightedRows={highlightedRows}
                onSelectRow={handleRowSelect}
                containerClassName="search-results-table"
                rowComponent={ElasticResultRow}
                disableRowSelectWhen={(row) => row && !canRead(row.permissionSet)}
                tableCheckboxMargin="10px"
            >
                <TableHeader>
                    <AdvancedSearchResultsTableHeader
                        from={from}
                        to={from + results.length}
                        searchSize={size}
                        onSizeChange={handleSizeChange}
                        totalResults={totalResults}
                        currentPage={currentPage}
                        searchFilters={searchFilters}
                        onPaginationClick={(lastPage, nextPage) =>
                            handlePaginationClick(nextPage, size)
                        }
                        isSearchSavable={true}
                        onSaveSearchToggleClick={onSaveSearchToggle}
                        savedSearchId={savedSearchId}
                        savedSearchErrorMessage={savedSearchErrorMessage}
                        savedSearches={savedSearches}
                        onSaveSearch={onSaveSearchToggle}
                        searchModule={advancedSearchProperty}
                    />
                    <TableColumns>
                        <OptionsTableColumn
                            display={strings.sortBy.label}
                            columnKey="profilePhoto"
                            activeValue={currentSortKey}
                            activeSortType={currentSortType}
                            onOptionClick={handleSortByOptionClick}
                            onSortTypeClick={handleSortBySortTypeClick}
                            width={130}
                            popoutWidth={160}
                        >
                            <OptionsTableColumnOption
                                display={strings.relevance.label}
                                value={sortKeyEnum.RELEVANCE}
                            />
                            <OptionsTableColumnOption
                                display={strings.modifiedDate.label}
                                value={sortKeyEnum.PROPERTY_UPDATED_DATE_UTC}
                                sortOptions={[
                                    {
                                        display: strings.modifiedDate.descendingSort,
                                        sortType: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
                                    },
                                    {
                                        display: strings.modifiedDate.ascendingSort,
                                        sortType: sortTypeEnum.DATE_LEAST_RECENT_TO_MOST_RECENT,
                                    },
                                ]}
                            />
                        </OptionsTableColumn>
                        <TableColumn
                            display={strings.property.label}
                            columnKey="property"
                            width={266}
                        />
                        <TableColumn
                            display={strings.identifiers.label}
                            columnKey="identifiers"
                            width={261}
                        />
                        <TableColumn display={strings.owner.label} columnKey="owner" width={261} />
                    </TableColumns>
                </TableHeader>
                <TableBody>
                    <ImageCell columnKey="profilePhoto" />
                    <PropertyCell columnKey="property" />
                    <IdentifiersCell columnKey="identifiers" />
                    <OwnerCell columnKey="owner" />
                </TableBody>
            </ClippableSearchTable>
        </div>
    );
}

const mapStateToProps = createStructuredSelector({
    query: advancedSearchProperty.selectors.currentQuerySelector,
    results: advancedSearchProperty.selectors.currentResultsViewModelsSelector,
    totalCount: advancedSearchProperty.selectors.totalCountSelector,
    tableLoading: advancedSearchProperty.selectors.tableLoadingSelector,
    searchFilters: advancedSearchProperty.selectors.filtersSelector,
    selectedRows: advancedSearchProperty.selectors.selectedRowsSelector,
    highlightedRows: advancedSearchProperty.selectors.highlightedRowsSelector,
    savedSearchErrorMessage: advancedSearchProperty.selectors.loadSavedSearchErrorMessageSelector,
});

const mapDispatchToProps = (dispatch, { router }) => ({
    handleRowSelect(newSelectedRows) {
        dispatch(advancedSearchProperty.actionCreators.selectRows(newSelectedRows));
    },
    handleRowClick(getCurrentScrollPosition) {
        return (elasticProperty, rowIndex) => {
            dispatch(
                advancedSearchProperty.actionCreators.openSearchResult(
                    elasticProperty,
                    rowIndex,
                    router,
                    getCurrentScrollPosition()
                )
            );
        };
    },
    handleSortByOptionClick({ value, sortOptions }) {
        dispatch(
            advancedSearchProperty.actionCreators.search({
                sortKey: value,
                sortType: sortOptions ? sortOptions[0].sortType : undefined,
            })
        );
    },
    handleSortBySortTypeClick(sortType) {
        dispatch(advancedSearchProperty.actionCreators.search({ sortType }));
    },
    handlePaginationClick(nextPage, size) {
        dispatch(
            advancedSearchProperty.actionCreators.search({ from: nextPage * size - size, size })
        );
    },
    handleSizeChange(size) {
        dispatch(advancedSearchProperty.actionCreators.search({ from: 0, size }));
    },
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(AdvancedSearchPropertyResults);

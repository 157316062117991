import { SaveGpsRetentionPolicyRequest, RetentionPolicyResource } from '@mark43/gps-api';
import { createResource } from '../../../lib/resources/Resource';
import { req } from '../../../lib/ajax';

export const gpsRetentionResource = createResource({
    name: 'GPS Retention Admin Resource',
    methods: {
        getDepartmentInfo() {
            return req<RetentionPolicyResource.GetDepartmentGpsRetentionPolicy>({
                method: 'GET',
                url: 'retention_policy/',
                baseUrl: '/gps/api',
            });
        },
        saveGpsRetentionPolicy(data?: SaveGpsRetentionPolicyRequest) {
            return req<RetentionPolicyResource.SaveGpsRetentionPolicy>({
                method: 'PUT',
                url: 'retention_policy/',
                baseUrl: '/gps/api',
                data,
            });
        },
    },
});

import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import styled from 'styled-components';
import componentStrings from '~/client-common/core/strings/componentStrings';
import { prettify } from '~/client-common/helpers/stringHelpers';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import FormattedDate from '~/client-common/core/dates/components/FormattedDate';

import { InventoriesViewModel } from '../../types';

const strings = componentStrings.evidence.inventories.SearchResultsTableHeader.columns;

const inventoriesDashboardColumnHelper = createColumnHelper<InventoriesViewModel>();

const StorageLocationNameCell = styled.div`
    white-space: normal;
`;

export const columnDefinitions = [
    inventoriesDashboardColumnHelper.accessor('completedDateUtc', {
        id: 'completedDateUtc',
        header: strings.dateCompleted.label,
        maxSize: 150,
        enableSorting: true,
        cell: ({ getValue }) => {
            return <FormattedDate date={getValue()} format={FormattedDate.FORMATS.FORM_DATE} />;
        },
    }),
    inventoriesDashboardColumnHelper.accessor('storageLocationName', {
        id: 'storageLocationName',
        header: strings.storageLocation.label,
        minSize: 250,
        enableSorting: false,
        cell: ({ row }) => {
            const { storageLocationFullDisplayPath } = getViewModelProperties(row.original);
            return (
                <StorageLocationNameCell>{storageLocationFullDisplayPath}</StorageLocationNameCell>
            );
        },
    }),

    inventoriesDashboardColumnHelper.accessor('responsiblePersonnel', {
        id: 'responsiblePersonnel',
        header: strings.responsiblePersonnel.label,
        maxSize: 200,
        enableSorting: false,
        cell: ({ row }) => {
            const {
                ownerUserId: { fullNameWithFirstInitialAndIdNumber },
            } = getViewModelProperties(row.original);
            return fullNameWithFirstInitialAndIdNumber;
        },
    }),

    inventoriesDashboardColumnHelper.accessor('inventoryType', {
        id: 'inventoryType',
        header: strings.inventoryType.label,
        maxSize: 120,
        enableSorting: false,
        cell: ({ row }) => {
            const { inventoryType } = row.original;
            return prettify(inventoryType);
        },
    }),

    inventoriesDashboardColumnHelper.accessor('missingItemsCount', {
        id: 'missingItemsCount',
        header: strings.missingItemsCount.label,
        maxSize: 120,
        enableSorting: true,
        cell: ({ row }) => {
            const { missingItemsCount } = row.original;
            return missingItemsCount > 0 ? missingItemsCount : '-';
        },
    }),
];

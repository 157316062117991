import {
    ElasticConfiguredEntityBooleanQuery,
    ElasticConfiguredEntityIdQuery,
    ElasticConfiguredEntityQuery,
    ElasticConfiguredEntityStringQuery,
    ValueTypeEnum,
    ValueTypeEnumType,
} from '@mark43/rms-api';
import { partition } from 'lodash';

import { ReportConfiguredEntityProperty } from '../../components/dragon/types';
import { createConfigurdEntityPropertyOptionValue } from './optionValue';

function convertSingleValueQueriesToReportConfiguredEntityPropertyFactory(
    valueType: ValueTypeEnumType,
    configuredEntityTypeKeyName: string
): (
    query:
        | ElasticConfiguredEntityStringQuery
        | ElasticConfiguredEntityIdQuery
        | ElasticConfiguredEntityBooleanQuery
) => Required<ReportConfiguredEntityProperty> | undefined {
    return (query) => {
        if (!query.key) {
            return;
        }

        // `ElasticConfiguredEntityIdQuery`'s `value` is a string array, but we only ever support
        // singular values
        const value = Array.isArray(query.value) ? query.value[0] : query.value;
        return {
            configuredEntityPropertyDescriptor: createConfigurdEntityPropertyOptionValue({
                configuredEntityKeyName: configuredEntityTypeKeyName,
                configuredPropertyKeyName: query.key,
                valueType,
            }),
            configuredEntityPropertyInputValue: String(value),
        };
    };
}

export function convertElasticConfiguredEntityQueryToDragonReportFields(
    input?: ElasticConfiguredEntityQuery[]
): {
    keywords?: string;
    reportConfiguredEntityProperties: Required<ReportConfiguredEntityProperty>[];
} {
    const [keywordQuery, otherQueries] = partition(input, (input) => !input.key);

    const keywords = keywordQuery[0]?.configuredStringQuery?.[0]?.value;
    return {
        keywords,
        reportConfiguredEntityProperties: otherQueries
            .flatMap(
                ({
                    configuredBigIntQuery = [],
                    configuredBooleanQuery = [],
                    configuredDateTimeQuery = [],
                    configuredDecimalQuery = [],
                    configuredIdQuery = [],
                    configuredStringQuery = [],
                    key: configuredEntityTypeKeyName,
                }): (Required<ReportConfiguredEntityProperty> | undefined)[] | undefined => {
                    // if we do not have a key, we cannot reconstruct our property value, so we gracefully skip it
                    if (!configuredEntityTypeKeyName) {
                        return;
                    }

                    return configuredStringQuery
                        .map(
                            convertSingleValueQueriesToReportConfiguredEntityPropertyFactory(
                                ValueTypeEnum.STRING.name,
                                configuredEntityTypeKeyName
                            )
                        )
                        .concat(
                            configuredBooleanQuery.map(
                                convertSingleValueQueriesToReportConfiguredEntityPropertyFactory(
                                    ValueTypeEnum.BOOLEAN.name,
                                    configuredEntityTypeKeyName
                                )
                            )
                        )
                        .concat(
                            configuredIdQuery.map(
                                convertSingleValueQueriesToReportConfiguredEntityPropertyFactory(
                                    ValueTypeEnum.ID.name,
                                    configuredEntityTypeKeyName
                                )
                            )
                        )
                        .concat(
                            configuredBigIntQuery.map((query) => {
                                return {
                                    configuredEntityPropertyDescriptor:
                                        createConfigurdEntityPropertyOptionValue({
                                            configuredEntityKeyName: configuredEntityTypeKeyName,
                                            configuredPropertyKeyName: query.key,
                                            valueType: ValueTypeEnum.BIGINT.name,
                                        }),
                                    configuredEntityPropertyInputValue:
                                        query.exactBigIntValue !== undefined
                                            ? query.exactBigIntValue
                                            : {
                                                  min:
                                                      query.startBigIntRange !== undefined
                                                          ? String(query.startBigIntRange)
                                                          : undefined,
                                                  max:
                                                      query.endBigIntRange !== undefined
                                                          ? String(query.endBigIntRange)
                                                          : undefined,
                                              },
                                };
                            })
                        )
                        .concat(
                            configuredDecimalQuery.map((query) => {
                                return {
                                    configuredEntityPropertyDescriptor:
                                        createConfigurdEntityPropertyOptionValue({
                                            configuredEntityKeyName: configuredEntityTypeKeyName,
                                            configuredPropertyKeyName: query.key,
                                            valueType: ValueTypeEnum.DECIMAL.name,
                                        }),
                                    configuredEntityPropertyInputValue:
                                        query.exactDecimalValue !== undefined
                                            ? query.exactDecimalValue
                                            : {
                                                  min:
                                                      query.startDecimalRange !== undefined
                                                          ? String(query.startDecimalRange)
                                                          : undefined,
                                                  max:
                                                      query.endDecimalRange !== undefined
                                                          ? String(query.endDecimalRange)
                                                          : undefined,
                                              },
                                };
                            })
                        )
                        .concat(
                            configuredDateTimeQuery.map((query) => {
                                return {
                                    configuredEntityPropertyDescriptor:
                                        createConfigurdEntityPropertyOptionValue({
                                            configuredEntityKeyName: configuredEntityTypeKeyName,
                                            configuredPropertyKeyName: query.key,
                                            valueType: ValueTypeEnum.DATETIME.name,
                                        }),
                                    configuredEntityPropertyInputValue: query.value,
                                };
                            })
                        );
                }
            )
            .filter((formValue): formValue is NonNullable<typeof formValue> => !!formValue)
            .sort((a, b) => {
                if (
                    a?.configuredEntityPropertyDescriptor === b?.configuredEntityPropertyDescriptor
                ) {
                    return 0;
                }

                return a?.configuredEntityPropertyDescriptor > b?.configuredEntityPropertyDescriptor
                    ? 1
                    : -1;
            }),
    };
}

import { Button, Flex, Popover, PopoverContent, PopoverTrigger, cssVar, useDisclosure } from 'arc';
import { AttributeTypeEnum, ElasticAttributeDetailQuery } from '@mark43/rms-api';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Form, lifecycleOptions, _Form, MFTFormConfiguration } from 'markformythree';

import componentStrings from '~/client-common/core/strings/componentStrings';
import useFields from '~/client-common/core/fields/hooks/useFields';
import {
    CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
    CASE_ATTRIBUTE_ATTRIBUTE_ID,
} from '~/client-common/core/enums/universal/fields';
import { getAttributesByIdsSelector } from '~/client-common/core/domain/attributes/state/data';
import { formatAttributeValue } from '~/client-common/core/domain/attributes/utils/attributesHelpers';

import { MFTAttributeSelect } from '../../core/forms/components/selects/AttributeSelect';
import testIds from '../../../core/testIds';

const FORM_NAME = 'DASHBOARD_TARGET_PROFILES_FILTERS_FORM';

const strings = componentStrings.personalDashboard.filters;

const FormContent = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
`;

type TargetProfilesFilterFormModel = {
    targetProfileCategoryAttrIds?: number[];
    targetProfileAreaAttrIds?: number[];
};

export type TargetProfilesFilterQuery = {
    targetProfileCategoryAttrDetail?: ElasticAttributeDetailQuery;
    targetProfileAreaAttrDetail?: ElasticAttributeDetailQuery;
};

const getElasticAttrDetailQuery = (
    formModel: TargetProfilesFilterFormModel,
    getAttributesByIds: ReturnType<typeof getAttributesByIdsSelector>,
    key: keyof TargetProfilesFilterFormModel
): ElasticAttributeDetailQuery => {
    const ids = formModel[key] || [];

    return {
        ids,
        displayValues: getAttributesByIds(ids).map((attr) => formatAttributeValue(attr) || ''),
    };
};

const convertFormModelToElasticQueryObj = (
    formModel: TargetProfilesFilterFormModel,
    getAttributesByIds: ReturnType<typeof getAttributesByIdsSelector>
) => {
    return {
        targetProfileCategoryAttrDetail: getElasticAttrDetailQuery(
            formModel,
            getAttributesByIds,
            'targetProfileCategoryAttrIds'
        ),
        targetProfileAreaAttrDetail: getElasticAttrDetailQuery(
            formModel,
            getAttributesByIds,
            'targetProfileAreaAttrIds'
        ),
    };
};

type TargetProfilesFiltersProps = {
    onApplyFilters: (data: TargetProfilesFilterQuery) => void;
};

const TargetProfilesFilters: FC<TargetProfilesFiltersProps> = ({ onApplyFilters }) => {
    const getAttributesByIds = useSelector(getAttributesByIdsSelector);
    const fields = useFields([
        CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID,
        CASE_ATTRIBUTE_ATTRIBUTE_ID,
    ]);

    const { isOpen, onClose, onToggle } = useDisclosure();

    const handleFormSubmit = (form: _Form<MFTFormConfiguration>) => () => {
        form.submit().then(({ form }) => {
            const formModel: TargetProfilesFilterFormModel = form.getState().model;

            const elasticQueryObj = convertFormModelToElasticQueryObj(
                formModel,
                getAttributesByIds
            );

            onApplyFilters(elasticQueryObj);
            onClose();
        });
    };

    return (
        <Popover data-test-id={testIds.PERSONAL_DASHBOARD_TARGET_PROFILES_FILTERS} isOpen={isOpen}>
            <PopoverTrigger asChild>
                <Button
                    onClick={onToggle}
                    leadingVisual="Filter"
                    aria-label={strings.button}
                    variant="outline"
                >
                    {strings.button}
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <Form
                    lifecycle={lifecycleOptions.REGISTER_AND_RETAIN}
                    name={FORM_NAME}
                    render={(form) => (
                        <FormContent>
                            <MFTAttributeSelect
                                attributeType={AttributeTypeEnum.TARGET_PROFILE_CATEGORY.name}
                                multiple
                                fullWidth
                                label={
                                    fields.CASE_ATTRIBUTE_ATTRIBUTE_TYPE_TARGET_PROFILE_CATEGORY_ATTRIBUTE_ID
                                }
                                testId={testIds.TARGET_PROFILE_CATEGORY_INPUT}
                                path="targetProfileCategoryAttrIds"
                            />

                            <MFTAttributeSelect
                                attributeType={[
                                    AttributeTypeEnum.SUBDIVISION_DEPTH_1.name,
                                    AttributeTypeEnum.SUBDIVISION_DEPTH_2.name,
                                    AttributeTypeEnum.SUBDIVISION_DEPTH_3.name,
                                    AttributeTypeEnum.SUBDIVISION_DEPTH_4.name,
                                    AttributeTypeEnum.SUBDIVISION_DEPTH_5.name,
                                ]}
                                multiple
                                fullWidth
                                label={fields.CASE_ATTRIBUTE_ATTRIBUTE_ID}
                                testId={testIds.TARGET_PROFILE_AREA_INPUT}
                                path="targetProfileAreaAttrIds"
                            />

                            <Flex justifyContent="flex-end" gap={cssVar('arc.space.2')}>
                                <Button variant="ghost" onClick={onClose}>
                                    {strings.cancel}
                                </Button>
                                <Button
                                    variant="solid"
                                    onClick={handleFormSubmit(form)}
                                    data-test-id={testIds.TARGET_PROFILE_FILTER_SUBMIT_BUTTON}
                                >
                                    {strings.submit}
                                </Button>
                            </Flex>
                        </FormContent>
                    )}
                />
            </PopoverContent>
        </Popover>
    );
};

export default TargetProfilesFilters;

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import React, { useEffect, useState } from 'react';
import { pure, withPropsOnChange, withHandlers, branch, renderComponent } from 'recompose';
import { map, get, isFunction, values, isEmpty } from 'lodash';
import { Control } from 'markformythree';
import styled from 'styled-components';
import { dateSortedAugmentedAttachmentViewModelsWhereSelector } from '~/client-common/core/domain/attachments/state/ui';
import { getAttachmentFile } from '~/client-common/core/domain/attachments/utils/attachmentsHelper';
import componentStrings from '~/client-common/core/strings/componentStrings';
import overlayIdEnum from '~/client-common/core/enums/universal/overlayIdEnum';
import Upload from '../../../core/forms/components/Upload';
import testIds from '../../../../core/testIds';
import Row from '../../../core/components/Row';
import AddEntityLink from '../../../core/components/AddEntityLink';
import {
    initializeAttachmentsSidePanelForm,
    uploadedFilesSelector,
    clearUploads as clearAttachmentSidePanelUploads,
} from '../state/ui/attachmentsSidePanel';
import reactReduxFormHelpers from '../../../../legacy-redux/helpers/reactReduxFormHelpers';
import {
    saveUploadedFiles,
    startFileUpload,
    uploadedFilesWhereSelector,
    uploadingFilesWhereSelector,
    deleteUpload,
    openConfirmationModal,
    saveConfirmationModal,
    deleteUploadingFile,
    onUploadFailure,
} from '../state/ui/inlineAttachmentsUploader';
import FormElement from '../../../core/forms/components/FormElement';

import AttachmentsSidePanel from './AttachmentsSidePanel';

import InlineFileRow from './InlineFileRow';

const { connectRRFInput } = reactReduxFormHelpers;

const strings = componentStrings.reports.core.InlineAttachmentsUploader;

const ButtonCopyWrapper = styled.span`
    vertical-align: top;
`;

function InlineAttachmentsUploaderWithAttachmentsSidePanel({
    attachments,
    attachmentsSidePanelUploadedFiles,
    clearAttachmentSidePanelUploads,
    initializeAttachmentsSidePanelForm,
    entityId,
    entityType,
    imageLinkType,
    attachmentLinkType,
    hideDetails,
    disabled,
}) {
    useEffect(() => {
        return () => {
            clearAttachmentSidePanelUploads();
        };
    }, [clearAttachmentSidePanelUploads]);

    const entityLinkDetails = {
        entityId,
        entityType,
        imageLinkType,
        attachmentLinkType,
    };

    const allAttachments = [...attachments, ...attachmentsSidePanelUploadedFiles];

    const attachmentRows = map(allAttachments, (attachment) => {
        const originalFile = getAttachmentFile(attachment);
        const attachmentName = get(originalFile, 'originalFileName');
        const fileCategory = get(originalFile, 'fileCategory');
        // fall back to file id because not every attachment has a unique id (ie. UNPERSISTED_ENTITY_ID)
        const key = attachment.id || originalFile.id;

        return (
            <InlineFileRow
                url={attachment.path}
                key={key}
                fileName={attachmentName}
                createdDateUtc={attachment.createdDateUtc}
                createdBy={attachment.createdBy}
                fileCategory={fileCategory}
                disabled={true}
                hideDetails={hideDetails}
            />
        );
    });

    return (
        <>
            {attachmentRows}
            {!disabled && (
                <AttachmentsSidePanel
                    getInitialCustomPropertyState={() => entityLinkDetails}
                    overlayId={overlayIdEnum.INLINE_ATTACHMENTS_ATTACHMENTS_SIDE_PANEL}
                    renderButton={({ overlayBase: { open }, setCloseFocusRefs }) => {
                        function handleAddClick() {
                            initializeAttachmentsSidePanelForm(entityLinkDetails);
                            open();
                        }

                        return (
                            <AddEntityLink
                                onClick={handleAddClick}
                                setRef={setCloseFocusRefs}
                                testId={testIds.MANAGE_ATTACHMENTS}
                            >
                                <ButtonCopyWrapper>{strings.addEditAttachments}</ButtonCopyWrapper>
                            </AddEntityLink>
                        );
                    }}
                />
            )}
        </>
    );
}

function _InlineAttachmentsUploader({
    onUploadSuccess,
    onUploadStart,
    onUploadFailure,
    uploadingFiles,
    uploadedFiles,
    attachments,
    label,
    hideDetails,
    onClickDeleteUpload,
    onClickDeleteAttachment,
    onClickDeleteUploadingFile,
    disabled,
    children,
    entityId,
    linkType,
    multiple = true,
    accept,
    testId,
    onFileInfected,
    buttonText,
}) {
    if (isFunction(children)) {
        return children({
            attachments,
            uploadingFiles,
            uploadedFiles,
            onClickDeleteUpload,
            onClickDeleteUploadingFile,
            onClickDeleteAttachment,
            onUploadSuccess,
            onUploadStart,
            onUploadFailure,
            entityId,
            linkType,
        });
    }
    const attachmentRows = map(attachments, (attachment) => {
        const originalFile = getAttachmentFile(attachment);
        const url = get(originalFile, 'fileWebServerPath');
        const attachmentName = get(originalFile, 'originalFileName');
        const fileCategory = get(originalFile, 'fileCategory');
        return (
            <InlineFileRow
                key={attachment.id}
                url={url}
                fileName={attachmentName}
                createdDateUtc={attachment.createdDateUtc}
                createdBy={attachment.createdBy}
                deleteFile={onClickDeleteAttachment(attachment.id)}
                fileCategory={fileCategory}
                disabled={disabled}
                hideDetails={hideDetails}
            />
        );
    });
    const uploadingFileRows = map(uploadingFiles, (upload) => {
        if (!upload.file.isDeleted) {
            return (
                <InlineFileRow
                    key={upload.file.id}
                    url={upload.file.fileWebServerPath}
                    fileName={upload.file.originalFileName}
                    deleteFile={onClickDeleteUploadingFile(upload.file.id)}
                    isLoading={upload.file.isLoading}
                    uploadFailed={upload.file.uploadFailed}
                    errorMessage={upload.file.errorMessage}
                    disabled={disabled}
                    hideDetails={hideDetails}
                />
            );
        }
    });

    const uploadedFileRows = map(uploadedFiles, (upload) => (
        <InlineFileRow
            key={upload.file.id}
            url={upload.file.fileWebServerPath}
            fileName={upload.file.originalFileName}
            deleteFile={onClickDeleteUpload(upload.file.id)}
            isLoading={upload.file.isLoading}
            fileCategory={upload.file.fileCategory}
            disabled={disabled}
            hideDetails={hideDetails}
        />
    ));

    const hideUploadButton = disabled || (!multiple && uploadedFileRows.length === 1);

    return (
        <Row>
            {label && <div className="mark43-form-label">{label}</div>}
            {attachmentRows}
            {uploadedFileRows}
            {uploadingFileRows}
            {!hideUploadButton && (
                <Upload
                    accept={accept}
                    multiple={multiple}
                    onSuccess={onUploadSuccess}
                    onStart={onUploadStart}
                    onError={onUploadFailure}
                    testId={testId}
                    onFileInfected={onFileInfected}
                >
                    <AddEntityLink>
                        <ButtonCopyWrapper>
                            {buttonText || strings.addAttachments}
                        </ButtonCopyWrapper>
                    </AddEntityLink>
                </Upload>
            )}
        </Row>
    );
}

const mapStateToProps = createStructuredSelector({
    sortedAugmentedAttachmentViewModelsWhere: dateSortedAugmentedAttachmentViewModelsWhereSelector,
    uploadedFilesWhere: uploadedFilesWhereSelector,
    uploadingFilesWhere: uploadingFilesWhereSelector,
    attachmentsSidePanelUploadedFiles: uploadedFilesSelector,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    clearAttachmentSidePanelUploads: () => {
        dispatch(clearAttachmentSidePanelUploads());
    },
    onUploadSuccess: (uploadedFiles) => {
        dispatch(saveUploadedFiles(uploadedFiles, ownProps.entityId, ownProps.linkType));
        if (isFunction(ownProps.onFileUploadFinish)) {
            ownProps.onFileUploadFinish(uploadedFiles);
        }
    },
    onUploadStart: (files) => {
        dispatch(startFileUpload(files, ownProps.entityId, ownProps.linkType));
        if (isFunction(ownProps.onFileUploadStart)) {
            ownProps.onFileUploadStart();
        }
    },
    onUploadFailure: (error, files) => {
        dispatch(onUploadFailure(error, files));
        if (isFunction(ownProps.onFileUploadFailure)) {
            ownProps.onFileUploadFailure();
        }
    },
    onDeleteUpload: (uploadedFiles, uploadId) => {
        dispatch(
            deleteUpload(uploadedFiles, uploadId, {
                entityId: ownProps.entityId,
                linkType: ownProps.linkType,
            })
        );
        if (isFunction(ownProps.onDeleteUpload)) {
            ownProps.onDeleteUpload(uploadId);
        }
    },
    onDeleteUploadingFile: (uploadingFiles, uploadingFileId) => {
        dispatch(
            deleteUploadingFile(uploadingFiles, uploadingFileId, {
                entityId: ownProps.entityId,
                linkType: ownProps.linkType,
            })
        );
        if (isFunction(ownProps.onDeleteUploadFinish)) {
            ownProps.onDeleteUploadFinish();
        }
    },
    confirmationModalCallback: () => {
        dispatch(saveConfirmationModal()).then((attachment) => {
            if (isFunction(ownProps.onAttachmentDelete)) {
                ownProps.onAttachmentDelete(attachment);
            }
        });
    },
    onDeleteAttachment: (attachmentId, saveCallback) => {
        dispatch(openConfirmationModal(attachmentId, saveCallback));
    },
    initializeAttachmentsSidePanelForm: (entityLinkDetails) => {
        dispatch(initializeAttachmentsSidePanelForm(entityLinkDetails));
    },
});

/*
    The boolean prop openAttachmentsSidePanel when set to true will
    open the Attachments Side Panel as oppose to the native browser Upload dialog box.
    As we move forward with attachments in RMS we want to have the Attachments Side Panel
    as the only place to manage entity attachments.

    We can remove this boolean prop once all usages of InlineAttachmentUploader is passing
    true for this prop. We can also remove all other logic/code around when this boolean is false.
*/
const InlineAttachmentsUploader = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPropsOnChange(
        [
            'entityType',
            'entityId',
            'linkType',
            'sortedAugmentedAttachmentViewModelsWhere',
            'uploadingFilesWhere',
            'uploadedFilesWhere',
        ],
        ({
            entityType,
            entityId,
            linkType,
            sortedAugmentedAttachmentViewModelsWhere,
            uploadingFilesWhere,
            uploadedFilesWhere,
        }) => {
            const attachments = sortedAugmentedAttachmentViewModelsWhere({
                entityType,
                entityId,
                linkType,
            });
            const uploadingFiles = uploadingFilesWhere({ entityId, linkType });
            const uploadedFiles = uploadedFilesWhere({ entityId, linkType });

            return {
                attachments,
                uploadingFiles,
                uploadedFiles,
            };
        }
    ),
    withHandlers({
        onClickDeleteUpload: ({ uploadedFiles, onDeleteUpload }) => (uploadId) => () =>
            onDeleteUpload(uploadedFiles, uploadId),
        onClickDeleteAttachment: ({ onDeleteAttachment, confirmationModalCallback }) => (
            attachmentId
        ) => () => onDeleteAttachment(attachmentId, confirmationModalCallback),
        onClickDeleteUploadingFile: ({ uploadingFiles, onDeleteUploadingFile }) => (
            uploadingFileId
        ) => () => onDeleteUploadingFile(uploadingFiles, uploadingFileId),
    }),
    pure,
    branch(
        ({ withAttachmentsSidePanel }) => withAttachmentsSidePanel,
        renderComponent(InlineAttachmentsUploaderWithAttachmentsSidePanel),
        renderComponent(_InlineAttachmentsUploader)
    )
)(_InlineAttachmentsUploader);

export const MFTInlineAttachmentsUploader = ({ path, ...rest }) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <Control
            path={path}
            render={({ onChange, ui }) => {
                const { errors, touched } = ui;
                let errorMessage = null;

                if (!isEmpty(errors)) {
                    errorMessage = values(errors)[0];
                }

                return (
                    <div
                        onFocus={() => {
                            setIsFocused(true);
                        }}
                    >
                        <InlineAttachmentsUploader
                            {...rest}
                            onFileUploadFinish={(result) => {
                                const { file } = result[0];
                                onChange(file);
                            }}
                            onDeleteUpload={() => {
                                onChange(undefined);
                            }}
                        />
                        {errorMessage && (isFocused || touched) && (
                            <div className="mark43-form-error-message">{errorMessage}</div>
                        )}
                    </div>
                );
            }}
        />
    );
};

const _RRFInlineAttachmentsUploader = ({
    error,
    forceShowError,
    label,
    touched,
    width,
    ...rest
}) => {
    return (
        <FormElement
            error={error}
            touched={touched}
            forceShowError={forceShowError}
            width={width}
            label={label}
            {...rest}
        >
            <InlineAttachmentsUploader {...rest} />
        </FormElement>
    );
};

export default InlineAttachmentsUploader;
export const RRFInlineAttachmentsUploader = connectRRFInput(_RRFInlineAttachmentsUploader);

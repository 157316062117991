import sortTypeEnum from '~/client-common/core/enums/universal/sortTypeEnum';
import sqlSortKeyEnum from '~/client-common/core/enums/universal/sqlSortKeyEnum';

/**
 * Default search query params on the inventory dashboard.
 * @type {Object}
 */
export const queryParamDefaults = {
    FROM: 0,
    SIZE: 50,
    SORT_KEY: sqlSortKeyEnum.INVENTORY_COMPLETED_DATE_UTC,
    SORT_TYPE: sortTypeEnum.DATE_MOST_RECENT_TO_LEAST_RECENT,
};

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import boxEnum from '~/client-common/core/enums/client/boxEnum';

import componentStrings from '~/client-common/core/strings/componentStrings';
import { submitEditRoleNameModal } from '../state/ui';

import editRoleNameForm from '../state/forms/editRoleNameForm';
import ReactReduxForm from '../../../core/forms/components/ReactReduxForm';
import { RRFText } from '../../../core/forms/components/Text';
import { RRFMultiTextInput } from '../../../core/forms/components/RRFMultiTextInput';
import Row from '../../../core/components/Row';
import Modal from '../../../../legacy-redux/components/core/Modal';
import { InfoMessage } from '../../../core/components/InfoMessage';
import { BodyMediumText } from '../../../core/components/typography';

const strings = componentStrings.admin.roles.EditRole.EditRoleName;

const modalContext = {
    name: boxEnum.EDIT_ROLE_NAME_MODAL,
};

const globalPathConfig = [
    {
        path: 'sessionTimeoutLength.hours',
        suffix: 'H',
        width: 50,
    },
    {
        path: 'sessionTimeoutLength.mins',
        suffix: 'm',
        width: 50,
    },
];

export const CjisComplianceMessage = () => {
    return (
        <InfoMessage>
            <BodyMediumText>
                {strings.cjisComplianceMessage}
            </BodyMediumText>
        </InfoMessage>
    );
}

export default compose(
    connect(null, (dispatch) => {
        return {
            submitEditRoleNameModal: () => dispatch(submitEditRoleNameModal()),
        };
    })
)(function EditRoleNameModal({ submitEditRoleNameModal }) {
    return (
        <Modal
            title={strings.title}
            context={modalContext}
            onSave={() => submitEditRoleNameModal()}
        >
            <ReactReduxForm {...editRoleNameForm}>
                <CjisComplianceMessage/>
                <Row>
                    <RRFText label={strings.name} width={257} path="name" />
                </Row>
                <Row>
                    <RRFMultiTextInput
                        label={strings.sessionTimeoutLength}
                        pathConfig={globalPathConfig}
                        width={150}
                        path="sessionTimeoutLength"
                        justifyContent="start"
                    />
                </Row>
            </ReactReduxForm>
        </Modal>
    );
});

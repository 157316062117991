import { RefContextEnum } from '@mark43/rms-api';
import { filter, first, get, isEmpty, keys, map, pick, size, some, sortBy, values } from 'lodash';

import { createFormConfiguration, createNItems, formEvents } from 'markformythree';
import { isUndefinedOrNull } from '~/client-common/helpers/logicHelpers';
import {
    CHARGE_CHARGE_OFFENSE_CODE_ID,
    CHARGE_CHARGE_COUNT,
    CHARGE_OFFENSE_ID,
    CHARGE_WARRANT_ID,
    CHARGE_LEGACY_CHARGE,
    CHARGE_LEGACY_EVENT_NUMBER,
    CHARGE_LEGACY_CHARGE_SOURCE_ATTR_ID,
    CHARGE_CHARGE_STATUS_ATTR_ID,
    DISPLAY_ONLY_CHARGE_OFFENSE_OFFENSE_CODE_ID,
    DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE,
    DISPLAY_ONLY_NEW_OFFENSE_BUTTON,
    CHARGE_DUI_CAUSE_ATTR_ID,
} from '~/client-common/core/enums/universal/fields';
import { CHARGE_TYPE_OFFENSE_CODE_FLAGS } from '~/client-common/core/domain/offense-codes/constants';
import {
    CHARGES_DISPOSITION_INFO_FIELDS,
    chargeByIdSelector,
} from '~/client-common/core/domain/charges/state/data';
import { offenseByIdSelector } from '~/client-common/core/domain/offenses/state/data';
import { offenseCodeByIdSelector } from '~/client-common/core/domain/offense-codes/state/data';
import { sortHydratedCharges } from '~/client-common/core/domain/charges/state/ui';

import formsRegistry from '../../../../../../core/formsRegistry';

export const formName = RefContextEnum.FORM_ARREST_CHARGES_N_CHARGES_SIDE_PANEL.name;

const chargeFormModelFields = {
    id: {},
    offenseId: {
        fieldName: CHARGE_OFFENSE_ID,
    },
    newOffenseButton: {
        fieldName: DISPLAY_ONLY_NEW_OFFENSE_BUTTON,
    },
    offenseCodeId: {
        fieldName: DISPLAY_ONLY_CHARGE_OFFENSE_OFFENSE_CODE_ID,
    },
    warrantId: {
        fieldName: CHARGE_WARRANT_ID,
    },
    offenseOrder: {},
    legacyCharge: {
        fieldName: CHARGE_LEGACY_CHARGE,
    },
    legacyEventNumber: {
        fieldName: CHARGE_LEGACY_EVENT_NUMBER,
    },
    legacyChargeSourceAttrId: {
        fieldName: CHARGE_LEGACY_CHARGE_SOURCE_ATTR_ID,
    },
    chargeStatusAttrId: {
        fieldName: CHARGE_CHARGE_STATUS_ATTR_ID,
    },
    chargeOffenseCodeId: {
        fieldName: CHARGE_CHARGE_OFFENSE_CODE_ID,
    },
    chargeCount: {
        fieldName: CHARGE_CHARGE_COUNT,
    },
    duiCauseAttrId: {
        fieldName: CHARGE_DUI_CAUSE_ATTR_ID,
    },
    nibrsCodeCode: { fieldName: DISPLAY_ONLY_OFFENSE_NIBRS_CODE_CODE },
};

const sortChargeFormModels = ({ chargeFormModels }) => sortBy(chargeFormModels, 'offenseOrder');

export const getFormConfiguration = () =>
    createFormConfiguration({
        charges: createNItems({ fields: chargeFormModelFields }),
    });

export const newChargeFormModel = ({ chargeFormModels = [] }) => {
    const chargeFormModel = {};
    // initialize all fields on the new item as `undefined`
    map(keys(chargeFormModelFields), (field) => (chargeFormModel[field] = undefined));
    chargeFormModel['offenseOrder'] = size(chargeFormModels) + 1;
    // purposely a string, because the UI input component is an `MFTText` component.
    chargeFormModel['chargeCount'] = '1';
    return chargeFormModel;
};

export const convertToFormModel =
    ({ hydratedCharges = [] }) =>
    (dispatch, getState) => {
        const charges = isEmpty(hydratedCharges)
            ? [newChargeFormModel({ chargeFormModels: [] })]
            : map(sortHydratedCharges({ hydratedCharges }), (hydratedCharge) => {
                  const {
                      charge: {
                          id,
                          offenseId,
                          warrantId,
                          offenseOrder,
                          legacyCharge,
                          legacyEventNumber,
                          legacyChargeSourceAttrId,
                          chargeOffenseCodeId,
                          chargeCount,
                          chargeStatusAttrId,
                          duiCauseAttrId,
                      },
                  } = hydratedCharge;
                  const offenseCode = offenseByIdSelector(getState())(offenseId);
                  const offenseCodeId = get(offenseCode, 'offenseCodeId');
                  const nibrsCodeCode = get(offenseCode, 'nibrsCodeCode');

                  return {
                      id,
                      offenseId,
                      offenseCodeId,
                      warrantId,
                      offenseOrder,
                      legacyCharge,
                      legacyEventNumber,
                      legacyChargeSourceAttrId,
                      chargeOffenseCodeId,
                      chargeCount,
                      chargeStatusAttrId,
                      duiCauseAttrId,
                      nibrsCodeCode,
                  };
              });
        return { charges };
    };

export const convertFromFormModel =
    ({ form, arrestId }) =>
    (dispatch, getState) => {
        const state = getState();
        const chargeById = chargeByIdSelector(state);
        const formModelCharges = form.get('charges');

        const charges = map(formModelCharges, (formModelCharge) => {
            const {
                id,
                offenseId,
                warrantId,
                offenseOrder,
                legacyCharge,
                legacyEventNumber,
                legacyChargeSourceAttrId,
                chargeOffenseCodeId,
                chargeCount,
                chargeStatusAttrId,
                duiCauseAttrId,
            } = formModelCharge;
            const existingCharge = chargeById(id) || {};
            const dispositionInfo = pick(existingCharge, CHARGES_DISPOSITION_INFO_FIELDS);
            const offenseCodeId = get(offenseByIdSelector(state)(offenseId), 'offenseCodeId');

            return {
                id,
                offenseId,
                offenseCodeId,
                warrantId,
                offenseOrder,
                legacyCharge,
                legacyEventNumber,
                legacyChargeSourceAttrId,
                chargeOffenseCodeId,
                chargeCount,
                chargeStatusAttrId,
                arrestId,
                duiCauseAttrId,
                ...dispositionInfo,
            };
        });
        return charges;
    };

const sortThenResequenceOffenseOrders = ({ form, chargeFormModels }) => {
    const sortedFormModelCharges = sortChargeFormModels({ chargeFormModels });
    map(sortedFormModelCharges, (sortedFormModelCharge, idx) => {
        sortedFormModelCharges[idx].offenseOrder = idx + 1;
    });
    form.set('charges', sortedFormModelCharges);
    form.validate({ eventType: formEvents.MODEL_CHANGE });
};

export const resequenceOffenseOrders = ({ form }) => {
    const chargeFormModels = form.get('charges');
    sortThenResequenceOffenseOrders({ form, chargeFormModels });
};

const findChargeFormModelIndexesForOffenseOrder = ({ chargeFormModels, offenseOrder }) => {
    return filter(
        map(chargeFormModels, (chargeFormModel, idx) => {
            if (chargeFormModel.offenseOrder === offenseOrder) {
                return idx;
            }
            return undefined;
        }),
        (idx) => !isUndefinedOrNull(idx)
    );
};

export const swapOffenseOrders = ({ form, offenseOrder1, offenseOrder2 }) => {
    const chargeFormModels = form.get('charges');
    const charge1Indexes = findChargeFormModelIndexesForOffenseOrder({
        chargeFormModels,
        offenseOrder: offenseOrder1,
    });
    const charge2Indexes = findChargeFormModelIndexesForOffenseOrder({
        chargeFormModels,
        offenseOrder: offenseOrder2,
    });

    if (size(charge1Indexes) === 1 && size(charge2Indexes) === 1) {
        const charge1Index = first(charge1Indexes);
        const charge2Index = first(charge2Indexes);
        chargeFormModels[charge1Index].offenseOrder = offenseOrder2;
        chargeFormModels[charge2Index].offenseOrder = offenseOrder1;
        sortThenResequenceOffenseOrders({ form, chargeFormModels });
    }
};

export const moveChargeFormModelToTopCharge = ({ form, offenseOrder }) => {
    const chargeFormModels = form.get('charges');
    const chargeIndexes = findChargeFormModelIndexesForOffenseOrder({
        chargeFormModels,
        offenseOrder,
    });

    if (size(chargeIndexes) === 1) {
        const chargeIndex = first(chargeIndexes);
        map(chargeFormModels, (chargeFormModel, idx) => {
            if (idx < chargeIndex) {
                chargeFormModels[idx].offenseOrder = chargeFormModels[idx].offenseOrder + 1;
            }
        });
        chargeFormModels[chargeIndex].offenseOrder = 1;
        sortThenResequenceOffenseOrders({ form, chargeFormModels });
    }
};

export const findIndexForChargeOffenseOrder = ({ chargeOffenseOrder }) => {
    const form = formsRegistry.get(formName);
    const chargeFormModels = !!form ? form.get('charges') : [];
    return first(
        findChargeFormModelIndexesForOffenseOrder({
            chargeFormModels,
            offenseOrder: chargeOffenseOrder,
        })
    );
};

const computeMaybePrefilledChargeOffenseCodeId =
    ({ offenseId, chargeFormModel }) =>
    (dispatch, getState) => {
        const { chargeOffenseCodeId } = chargeFormModel;
        if (!isUndefinedOrNull(chargeOffenseCodeId)) {
            return chargeOffenseCodeId;
        }

        const state = getState();
        const offense = offenseByIdSelector(state)(offenseId) || {};
        const { offenseCodeId } = offense;
        const offenseCode = offenseCodeByIdSelector(state)(offenseCodeId) || {};
        const isChargeTypeOffenseCode = some(
            values(pick(offenseCode, CHARGE_TYPE_OFFENSE_CODE_FLAGS))
        );

        if (isChargeTypeOffenseCode) {
            return offenseCode.id;
        }
        return undefined;
    };

export const setOffenseOnCharge =
    ({ chargeOffenseOrder, offenseId }) =>
    (dispatch, getState) => {
        const form = formsRegistry.get(formName);
        const chargeIndex = findIndexForChargeOffenseOrder({ chargeOffenseOrder });

        if (!isUndefinedOrNull(chargeIndex)) {
            const chargeFormModel = form.get(`charges[${chargeIndex}]`);
            const maybePrefilledChargeOffenseCodeId = dispatch(
                computeMaybePrefilledChargeOffenseCodeId({ offenseId, chargeFormModel })
            );
            const offense = offenseByIdSelector(getState())(offenseId);
            const offenseCodeId = get(offense, 'offenseCodeId');
            const nibrsCodeCode = get(offense, 'nibrsCodeCode');

            form.transaction(() => {
                form.set(`charges[${chargeIndex}]`, {
                    ...chargeFormModel,
                });
                form.set(`charges[${chargeIndex}].offenseId`, offenseId);
                form.set(`charges[${chargeIndex}].offenseCodeId`, offenseCodeId);
                form.set(
                    `charges[${chargeIndex}].chargeOffenseCodeId`,
                    maybePrefilledChargeOffenseCodeId
                );
                form.set(`charges[${chargeIndex}].legacyCharge`, undefined);
                form.set(`charges[${chargeIndex}].legacyEventNumber`, undefined);
                form.set(`charges[${chargeIndex}].legacyChargeSourceAttrId`, undefined);
                form.set(`charges[${chargeIndex}].nibrsCodeCode`, nibrsCodeCode);
            });
        }
    };

export const getLegacyOffenseForCharge = ({ chargeOffenseOrder }) => {
    const form = formsRegistry.get(formName);
    const chargeIndex = findIndexForChargeOffenseOrder({ chargeOffenseOrder });
    const chargeFormModel = form.get(`charges[${chargeIndex}]`) || {};
    const { legacyCharge, legacyEventNumber, legacyChargeSourceAttrId } = chargeFormModel;

    return {
        legacyCharge,
        legacyEventNumber,
        legacyChargeSourceAttrId,
    };
};

export const setLegacyOffenseOnCharge = ({
    chargeOffenseOrder,
    legacyCharge,
    legacyEventNumber,
    legacyChargeSourceAttrId,
}) => {
    const form = formsRegistry.get(formName);
    const chargeIndex = findIndexForChargeOffenseOrder({ chargeOffenseOrder });

    if (!isUndefinedOrNull(chargeIndex)) {
        const chargeFormModel = form.get(`charges[${chargeIndex}]`);
        form.set(`charges[${chargeIndex}]`, {
            ...chargeFormModel,
            offenseId: undefined,
            legacyCharge,
            legacyEventNumber,
            legacyChargeSourceAttrId,
        });
    }
};

export const getWarrantIdForCharge = ({ chargeOffenseOrder }) => {
    const form = formsRegistry.get(formName);
    const chargeIndex = findIndexForChargeOffenseOrder({ chargeOffenseOrder });

    if (!isUndefinedOrNull(chargeIndex)) {
        const chargeFormModel = form.get(`charges[${chargeIndex}]`);
        return chargeFormModel.warrantId;
    }
    return null;
};

export const setWarrantOnCharge = ({ chargeOffenseOrder, warrantId }) => {
    const form = formsRegistry.get(formName);
    const chargeIndex = findIndexForChargeOffenseOrder({ chargeOffenseOrder });

    if (!isUndefinedOrNull(chargeIndex)) {
        const chargeFormModel = form.get(`charges[${chargeIndex}]`);
        form.set(`charges[${chargeIndex}]`, {
            ...chargeFormModel,
            warrantId,
        });
    }
};

import { AttributeTypeEnum } from '@mark43/rms-api';
import { map } from 'lodash';
import { connect } from 'react-redux';
import React from 'react';
import { compose, withPropsOnChange } from 'recompose';

import { createStructuredSelector } from 'reselect';
import { getViewModelProperties } from '~/client-common/helpers/viewModelHelpers';
import { reportAttributeViewModelsWhereSelector } from '~/client-common/core/domain/report-attributes/state/ui';
import { towVehicleViewModelByReportIdSelector } from '~/client-common/core/domain/tow-vehicles/state/ui';
import * as fields from '~/client-common/core/enums/universal/fields';
import componentStrings from '~/client-common/core/strings/componentStrings';

import { CardSection, CardSubsection } from '../../../../../legacy-redux/components/core/Card';
import SummaryList from '../../../../../legacy-redux/components/summaries/SummaryList';
import SummaryRow from '../../../../../legacy-redux/components/summaries/SummaryRow';
import SummaryRowDate from '../../../../../legacy-redux/components/summaries/SummaryRowDate';
import { reasonForTowIsStolenSelector } from '../../state/ui';
import testIds from '../../../../../core/testIds';

const strings = componentStrings.reports.core.TowVehicleCardSummary;

const mapStateToProps = createStructuredSelector({
    towVehicleViewModelByReportId: towVehicleViewModelByReportIdSelector,
    reasonForTowIsStolen: reasonForTowIsStolenSelector,
    reportAttributeViewModelsWhere: reportAttributeViewModelsWhereSelector,
});

const mapPropsOnChange = ({
    reportId,
    towVehicleViewModelByReportId,
    reasonForTowIsStolen,
    reportAttributeViewModelsWhere,
}) => {
    const towVehicleViewModel = towVehicleViewModelByReportId(reportId) || {};
    const reasonsForTow = map(
        reportAttributeViewModelsWhere({
            reportId,
            attributeType: AttributeTypeEnum.TOW_VEHICLE_REASON_FOR_TOW.name,
        }),
        (reportAttribute) => getViewModelProperties(reportAttribute).attributeId
    ).join(', ');
    return {
        towVehicleViewModel,
        reasonForTowIsStolen,
        reasonsForTow,
    };
};

const TowVehicleCardSummary = compose(
    connect(mapStateToProps),
    withPropsOnChange(
        [
            'reportId',
            'towVehicleViewModelByReportId',
            'reasonForTowIsStolen',
            'reportAttributeViewModelsWhere',
        ],
        mapPropsOnChange
    )
)(function _TowVehicleCardSummary({
    towedFromLocation,
    reportedStolenLocation,
    outsideRecoveryLocation,
    reasonForTowIsStolen,
    towVehicleViewModel,
    reasonsForTow,
}) {
    const { dateOfTheft } = towVehicleViewModel;
    const {
        towVehicleStatusAttrId,
        towCompanyCalledDisplay,
        wasLocateSent,
        wereImpoundsChecked,
        isImpounded,
        wasOutsideRecovery,
        wasOwnerContactAttempted,
    } = getViewModelProperties(towVehicleViewModel);

    return (
        <div>
            <SummaryList labelWidth={180} contentWidth={360}>
                <CardSection testId={testIds.CARD_SUMMARY}>
                    <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_TOW_VEHICLE_STATUS_ATTR_ID}>
                        {towVehicleStatusAttrId}
                    </SummaryRow>
                </CardSection>
                <CardSection
                    title={strings.sections.vehicle}
                    testId={testIds.TOW_VEHICLE_VEHICLE_SECTION}
                >
                    <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_REMARKS_AND_CONDITIONS}>
                        {towVehicleViewModel.remarksAndConditions}
                    </SummaryRow>
                </CardSection>

                <CardSection
                    title={strings.sections.towInformation}
                    testId={testIds.TOW_VEHICLE_TOW_INFORMATION_SECTION}
                >
                    <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_TOW_COMPANY_CALLED_ATTR_ID}>
                        {towCompanyCalledDisplay}
                    </SummaryRow>
                    <SummaryRowDate
                        fieldName={fields.RMS_TOW_VEHICLE_TOW_COMPANY_CALLED_DATE_UTC}
                        date={towVehicleViewModel.towCompanyCalledDateUtc}
                    />
                    <SummaryRowDate
                        fieldName={fields.RMS_TOW_VEHICLE_VEHICLE_WAS_TOWED_DATE_UTC}
                        date={towVehicleViewModel.vehicleWasTowedDateUtc}
                    />
                    {towedFromLocation()}
                    <SummaryRow
                        fieldName={
                            fields.REPORT_ATTRIBUTE_ATTRIBUTE_TYPE_TOW_VEHICLE_REASON_FOR_TOW_ATTRIBUTE_ID
                        }
                    >
                        {reasonsForTow}
                    </SummaryRow>
                    {reasonForTowIsStolen && (
                        <CardSubsection title={strings.sections.stolen}>
                            {reportedStolenLocation()}
                            <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_ORIGINAL_REN}>
                                {towVehicleViewModel.originalRen}
                            </SummaryRow>
                            <SummaryRowDate
                                fieldName={fields.RMS_TOW_VEHICLE_DATE_OF_THEFT}
                                date={dateOfTheft}
                                format={SummaryRowDate.FORMATS.SUMMARY_DATE}
                            />
                            <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_WAS_LOCATE_SENT}>
                                {wasLocateSent}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_WERE_IMPOUNDS_CHECKED}>
                                {wereImpoundsChecked}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_IS_IMPOUNDED}>
                                {isImpounded}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_WAS_OUTSIDE_RECOVERY}>
                                {wasOutsideRecovery}
                            </SummaryRow>
                            <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_OUTSIDE_RECOVERY_AGENCY}>
                                {towVehicleViewModel.outsideRecoveryAgency}
                            </SummaryRow>
                            <SummaryRow
                                fieldName={fields.RMS_TOW_VEHICLE_OUTSIDE_RECOVERY_AGENCY_REN}
                            >
                                {towVehicleViewModel.outsideRecoveryAgencyRen}
                            </SummaryRow>
                            {outsideRecoveryLocation()}
                        </CardSubsection>
                    )}
                    <CardSubsection title={strings.sections.other}>
                        <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_WAS_OWNER_CONTACT_ATTEMPTED}>
                            {wasOwnerContactAttempted}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_MESSAGE_LEFT_WITH}>
                            {towVehicleViewModel.messageLeftWith}
                        </SummaryRow>
                        <SummaryRow fieldName={fields.RMS_TOW_VEHICLE_ADDITIONAL_NOTES}>
                            {towVehicleViewModel.additionalNotes}
                        </SummaryRow>
                    </CardSubsection>
                </CardSection>
            </SummaryList>
        </div>
    );
});

export default TowVehicleCardSummary;

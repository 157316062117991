import keyMirror from 'keymirror';

// these directly map to classes on the admin list
// stylesheet, so modifications should check on AdminListStyles.scss
export const attributeStatuses = keyMirror({
    ACTIVE: undefined,
    SCHEDULED: undefined,
    EXPIRED: undefined,
    DISABLED: undefined,
});

export type AttributeStatus = keyof typeof attributeStatuses;

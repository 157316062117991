import { filter, keys } from 'lodash';

/**
 * @returns {AttributeTypeEnumType[]}
 */
export const computeLoadableAttributeTypes = (attributeLoadingState) =>
    filter(keys(attributeLoadingState), (key) => {
        const state = attributeLoadingState[key];
        return !state.loaded && !state.loading;
    });

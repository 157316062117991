import React, { Ref } from 'react';
import { ButtonGroup, cssVar } from 'arc';
import { useSelector, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { RefContextEnumType } from '@mark43/rms-api';
import classNames from 'classnames';
import _, { isArray, isEmpty, isFunction } from 'lodash';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import invariant from 'invariant';
import featureFlagged from '~/client-common/core/domain/settings/utils/featureFlagged';

import componentStrings from '~/client-common/core/strings/componentStrings';
import hotkeysActionEnum from '~/client-common/core/enums/client/hotkeysActionEnum';
import { blurActiveElement } from '~/client-common/core/keyboardFocus/helpers';
import { Field, formatFieldByNameSelector } from '~/client-common/core/fields/state/config';
import FeatureFlagged from '~/client-common/core/domain/settings/components/FeatureFlagged';
import { useErrorInterpolator } from '~/client-common/core/arbiter-utils/hooks/useErrorInterpolator';
import { fieldConfigurationByFieldNameSelector } from '~/client-common/core/domain/field-configurations/state/data';
import { BodyMediumText } from '../../../modules/core/components/typography';
import { Button as ArcButton } from '../../../modules/core/components/Button';
import { IconButton } from '../../../modules/core/components/IconButton';
import { useStaticFieldRequired } from '../../../modules/core/forms/hooks/useStaticFieldRequired';

import { AnalyticsPropertyEnum } from '../../../modules/analytics/constants/analyticsEnum';
import ReactHotKeysWrapper from '../../../modules/core/hotkeys/components/ReactHotKeysWrapper';
import { iconTypes } from '../../../modules/core/components/Icon';
import testIds from '../../../core/testIds';
import { clearfix } from '../../../modules/core/styles/mixins';
import { useIsE2ETestEnvironment } from '../../../modules/core/context/E2ETestingContext';
import zIndexes from '../../../modules/core/styles/zIndexes';
import theme from '../../../modules/core/styles/theme';
import { AnalyticsContextProviderWithAdditionalData } from '../../../modules/core/context/AnalyticsContext';
import { InteractionType } from '../../../modules/core/utils/buttonHelpers';
import { RootState } from '../../reducers/rootReducer';
import { cardScrollHotKeyConfigForAnchor } from '../../../modules/reports/core/state/ui/cards';
import { Tooltip } from '../../../modules/core/components/tooltip';
import Button, { buttonTypes } from './Button';
import { ContentSection, ContentSubsection } from './Content';
import { FloatLoadingGray } from './Loading';
import { CardHeaderErrorsSection } from './CardHeaderErrorsSection';

const strings = componentStrings.core.Card;

const defaultSummaryModeValue = true;
const SummaryModeContext = React.createContext(defaultSummaryModeValue);

export interface CardProps {
    className?: string;
    anchor?: string;
    /**
     * The title appears at the top of the card, in the card header.
     */
    title?: string;
    /**
     * Tooltip which appears beside the title.
     */
    helpText?: string;
    errors?: string[];
    /**
     * The card is either in summary mode to display data to the user (true, default), or edit mode to allow the user to
     * edit the data (false).
     */
    summaryMode?: boolean;
    summaryModeContent?: React.ReactNode;
    editModeContent?: React.ReactNode;
    /**
     * Whether the user can edit this card.
     */
    canEdit?: boolean;
    /**
     * User-facing error message describing why the user cannot perform an edit action.
     */
    canEditErrorMessage?: string;
    /**
     * Click handler for the edit button.
     */
    onEdit?: (e?: InteractionType) => void;
    /**
     * Icon type that is to be shown for the onEdit button.
     */
    onEditIconType?: string;
    /**
     * Text that is to be shown for the onEdit button card header in summary mode.
     * If not provided, the edit button is never shown.
     */
    onEditLabel?: string;
    /**
     * Click handler for the remove button in the card header in edit mode.
     * If not provided, the remove button is never shown.
     */
    onRemove?: (e?: InteractionType) => void;
    /**
     * Click handler for the save button in the card footer in edit mode. Required for edit mode.
     */
    onSave?: (e?: InteractionType) => void;
    onDuplicate?: (e?: InteractionType) => void;
    saving?: boolean;
    saveDisabled?: boolean;
    saveText?: string;
    children?: React.ReactNode;
    hideHeader?: boolean;
    hideFooter?: boolean;
    testId?: string;
    /**
     * Provide either renderContent OR children, but not both.
     */
    renderContent?: (summaryMode: boolean) => React.ReactNode;
    customSummaryModeColor?: keyof typeof theme.colors;
    customSummaryModeBorderColor?: keyof typeof theme.colors;
    /**
     * Set to `true` for the header to have a `sticky` position, `false` for a `relative` position.
     * For E2E tests, `stickyHeader` is always `false`, regardless of prop value passed in.
     */
    stickyHeader?: CardHeaderProps['stickyHeader'];
    cardHeaderRef?: Ref<HTMLDivElement>;
    /**
     * Function for rendering an expand/collapse button in the Card Header.
     */
    renderFullScreenToggleHeaderButton?: (summaryMode: boolean) => React.ReactNode;
    additionalFooterContent?: ({
        saving,
        saveDisabled,
    }: {
        saving?: boolean;
        saveDisabled?: boolean;
    }) => React.ReactNode;
}

export const CardComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    border-radius: 4px;
    width: 100%;
    overflow: visible;
    background-color: ${(props) => props.theme.colors.white};
    background-clip: padding-box;
`;

export const CardWithNoPadding = styled(Card)`
    && .mark43-react-card-content {
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
    }

    &&.edit-mode .mark43-react-card-content {
        overflow: visible;
    }
`;

interface CardHeaderTitleSectionProps
    extends Pick<
        CardProps,
        'summaryMode' | 'errors' | 'customSummaryModeColor' | 'customSummaryModeBorderColor'
    > {
    theme: typeof theme;
}

const getCardHeaderBorderColor = ({
    summaryMode,
    errors,
    theme,
    customSummaryModeBorderColor,
}: CardHeaderTitleSectionProps) => {
    if (!isEmpty(errors)) {
        return 'var(--arc-colors-negative-default)';
    } else if (!summaryMode) {
        return theme.colors.cobaltBlue;
    } else if (customSummaryModeBorderColor) {
        return theme.colors[customSummaryModeBorderColor];
    } else {
        return theme.colors.lightGrey;
    }
};

const getCardHeaderBackgroundColor = ({
    summaryMode,
    errors,
    theme,
    customSummaryModeColor,
}: CardHeaderTitleSectionProps) => {
    if (!isEmpty(errors)) {
        return 'var(--arc-colors-negative-default)';
    } else if (!summaryMode) {
        return theme.colors.brightBlue;
    } else if (customSummaryModeColor) {
        return theme.colors[customSummaryModeColor];
    } else {
        return 'var(--arc-colors-neutral-default)';
    }
};

const ButtonWrapper = styled.div`
    padding-left: 7px;
`;

const HeaderButton = styled.div`
    position: static;
    display: flex;
    justify-content: flex-end;
`;

const DuplicateHeaderButton = styled(HeaderButton)`
    min-width: 50px;
    margin-right: 6px;
`;

const DeleteHeaderButton = styled(HeaderButton)<{ minWidth: string }>`
    margin-right: 13px;
    min-width: ${(props) => props.minWidth};
`;

// we may need to keep the original if the sticky errors feature will be based on App Settings
const StyledCardHeader = styled.div<{
    sticky: CardHeaderProps['stickyHeader'];
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${clearfix};
    position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
    z-index: ${zIndexes.stickyHeader};
    top: 0;
    background-color: var(--arc-colors-surface-foreground);
    border-radius: 4px 4px 0 0;
`;

const StyledCardHeaderTitleSection = styled.div<CardHeaderTitleSectionProps>`
    display: flex;
    padding: 6px 0 4px 28px;
    border-radius: 4px 4px 0 0;
    background-color: ${getCardHeaderBackgroundColor};
    color: ${(props) =>
        props.summaryMode ? props.theme.colors.darkGrey : props.theme.colors.white};
    border: 1px solid ${getCardHeaderBorderColor};
    font-size: var(--arc-fontSizes-md);
    align-items: center;

    .mark43-react-icon-text-link {
        color: ${(props) => !props.summaryMode && props.theme.colors.white};
    }
`;

interface CardHeaderProps {
    title?: string;
    helpText?: string;
    summaryMode: boolean;
    customSummaryModeColor?: keyof typeof theme.colors;
    customSummaryModeBorderColor?: keyof typeof theme.colors;
    onEdit?: (e?: InteractionType) => void;
    onEditIconType?: string;
    onEditLabel?: string;
    onRemove?: (e?: InteractionType) => void;
    canEdit?: boolean;
    canEditErrorMessage?: string;
    errors: string[];
    onDuplicate?: (e?: InteractionType) => void;
    stickyHeader?: boolean;
    renderFullScreenToggleHeaderButton?: (summaryMode: boolean) => React.ReactNode;
}

const CardHeader = React.forwardRef(function CardHeader(
    {
        title,
        summaryMode,
        customSummaryModeColor,
        customSummaryModeBorderColor,
        onEdit,
        onEditIconType = iconTypes.EDIT,
        onEditLabel = strings.edit,
        onRemove,
        canEdit,
        canEditErrorMessage,
        errors,
        onDuplicate,
        stickyHeader,
        renderFullScreenToggleHeaderButton,
    }: CardHeaderProps,
    ref: Ref<HTMLDivElement>
) {
    let editButton;
    let deleteButton;
    let duplicateButton;

    if (summaryMode) {
        // the edit button is only shown in summary mode
        if (canEdit && onEdit) {
            editButton = (
                <FeatureFlagged
                    flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                    fallback={
                        <Button
                            onClick={onEdit}
                            testId={testIds.CARD_EDIT}
                            className={buttonTypes.ICON_LINK}
                            iconLeft={onEditIconType}
                        >
                            {onEditLabel}
                        </Button>
                    }
                >
                    <ArcButton
                        onClick={onEdit}
                        testId={testIds.CARD_EDIT}
                        // NOTE: There are only two icons that get used
                        // for the card right now
                        leftIcon={onEditIconType === iconTypes.ADD ? 'Add' : 'Edit'}
                        variant="ghost"
                    >
                        {onEditLabel}
                    </ArcButton>
                </FeatureFlagged>
            );
        } else if (!canEdit && onEdit) {
            const disabledButton = (
                <FeatureFlagged
                    flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
                    fallback={
                        <Button
                            disabled={true}
                            testId={testIds.CARD_EDIT}
                            className={buttonTypes.ICON_LINK}
                            iconLeft={onEditIconType}
                        >
                            {onEditLabel}
                        </Button>
                    }
                >
                    <ArcButton
                        isDisabled={true}
                        onClick={onEdit}
                        testId={testIds.CARD_EDIT}
                        // NOTE: There are only two icons that get used
                        // for the card right now
                        leftIcon={onEditIconType === iconTypes.ADD ? 'Add' : 'Edit'}
                        variant="ghost"
                    >
                        {onEditLabel}
                    </ArcButton>
                </FeatureFlagged>
            );

            editButton = isEmpty(canEditErrorMessage) ? (
                disabledButton
            ) : (
                <Tooltip side="bottom" content={canEditErrorMessage}>
                    <span>{disabledButton}</span>
                </Tooltip>
            );
        }
    } else if (canEdit && onRemove && !summaryMode) {
        // the remove button is only shown in edit mode
        deleteButton = (
            <Tooltip side="top" content={strings.removeCard}>
                <div>
                    <IconButton
                        style={
                            // TODO: This is a hack to make the button white in edit mode
                            {
                                color: 'currentColor',
                            }
                        }
                        size="sm"
                        onClick={onRemove}
                        icon="TrashCan"
                        testId={testIds.TRASH_BUTTON}
                        variant="ghost"
                        aria-label={strings.removeCard}
                    />
                </div>
            </Tooltip>
        );
    }

    // the duplicate button is only shown in edit mode
    if (canEdit && onDuplicate && !summaryMode) {
        duplicateButton = (
            <Tooltip side="top" content={strings.duplicateCard}>
                <ButtonWrapper>
                    <IconButton
                        style={
                            // TODO: This is a hack to make the button white in edit mode
                            {
                                color: 'currentColor',
                            }
                        }
                        size="sm"
                        onClick={onDuplicate}
                        icon="Duplicate"
                        testId={testIds.DUPLICATE_CARD_BUTTON}
                        variant="ghost"
                        aria-label={strings.duplicateCard}
                    />
                </ButtonWrapper>
            </Tooltip>
        );
    }

    return (
        <StyledCardHeader
            ref={ref}
            sticky={!useIsE2ETestEnvironment() && stickyHeader}
            data-test-id={testIds.CARD_HEADER}
        >
            <StyledCardHeaderTitleSection
                summaryMode={summaryMode}
                errors={errors}
                customSummaryModeColor={customSummaryModeColor}
                customSummaryModeBorderColor={customSummaryModeBorderColor}
            >
                <div className="mark43-react-card-header-title" data-test-id={testIds.CARD_TITLE}>
                    {title}
                </div>

                {!!renderFullScreenToggleHeaderButton && (
                    <div className="mark43-react-card-header-button">
                        {renderFullScreenToggleHeaderButton(summaryMode)}
                    </div>
                )}
                {editButton && <div className="mark43-react-card-header-button">{editButton}</div>}
                {duplicateButton && (
                    <DuplicateHeaderButton>{duplicateButton}</DuplicateHeaderButton>
                )}
                {deleteButton && (
                    <DeleteHeaderButton minWidth={!!duplicateButton ? '0px' : '85px'}>
                        {deleteButton}
                    </DeleteHeaderButton>
                )}
            </StyledCardHeaderTitleSection>
            <FeatureFlagged flag="RMS_REPORT_WRITING_ERROR_ENHANCEMENTS_ENABLED">
                <CardHeaderErrorsSection summaryMode={summaryMode} errors={errors} />
            </FeatureFlagged>
        </StyledCardHeader>
    );
});

const StyledCardContent = styled.div`
    background-color: ${(props) => props.theme.colors.white};
`;

interface CardContentProps {
    summaryMode?: boolean;
    children: React.ReactNode;
}

function CardContent({ children }: CardContentProps) {
    return (
        <StyledCardContent
            className="mark43-react-card-content"
            data-test-id={testIds.CARD_CONTENT}
        >
            {children}
        </StyledCardContent>
    );
}

const CardSectionError = styled.div`
    margin-left: 10px;
    display: inline-block;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.red};
    font-family: ${(props) => props.theme.fontFamilies.proximaNova};
    text-transform: none;
    font-weight: normal;
`;

const mapStateToProps = (state: RootState, ownProps: CardSectionOuterProps) => {
    return {
        title:
            ownProps.title ||
            (ownProps.fieldName ? formatFieldByNameSelector(state)(ownProps.fieldName) : ''),
    };
};

const RequiredText = styled(BodyMediumText)`
    color: ${cssVar('arc.colors.text.tertiary')};
    text-transform: none;
`;

interface WithAugmentTitleWithRequiredLabelProps {
    title?: React.ReactNode;
    isRequired?: boolean;
}

const withAugmentTitleWithRequiredLabel = (
    Component: React.ComponentClass<WithAugmentTitleWithRequiredLabelProps>
) => (props: WithAugmentTitleWithRequiredLabelProps) => {
    const summaryMode = React.useContext(SummaryModeContext);
    const title =
        props.isRequired && !summaryMode ? (
            <>
                {props.title} <RequiredText>{strings.required}</RequiredText>
            </>
        ) : (
            props.title
        );

    return <Component {...props} title={title} />;
};

interface WithStaticRequiredProps {
    fieldName?: Field;
    isRequired?: boolean;
}

function withStaticRequired(Component: React.ComponentClass<WithStaticRequiredProps>) {
    const StaticRequired = (
        props: WithStaticRequiredProps,
        context: {
            context: RefContextEnumType;
        }
    ) => {
        const fieldConfigurationByFieldName = useSelector(fieldConfigurationByFieldNameSelector);
        const fieldConfiguration = props.fieldName
            ? fieldConfigurationByFieldName[props.fieldName]
            : undefined;

        const isStaticFieldRequired = useStaticFieldRequired({
            disabled: false,
            value: undefined,
            context: context.context,
            fieldConfigurationId: fieldConfiguration?.id ?? 0,
        });

        return <Component {...props} isRequired={isStaticFieldRequired} />;
    };

    StaticRequired.contextTypes = {
        context: PropTypes.string,
    };

    return featureFlagged(
        'RMS_REPORT_WRITING_SHOW_REQUIRED_FIELDS_ENABLED',
        Component
    )(StaticRequired);
}

interface CardSectionInnerProps {
    title?: string;
    helpText?: string;
    error?: string;
    children?: React.ReactNode;
    className?: string;
    theme?: DefaultTheme;
    testId?: string;
    fieldName?: Field;
}

interface CardSectionOuterProps extends CardSectionInnerProps {
    isRequired?: boolean;
}

const CardSection = compose<CardSectionInnerProps, CardSectionOuterProps>(
    withTheme,
    connect(mapStateToProps),
    withStaticRequired,
    withAugmentTitleWithRequiredLabel
)(function CardSection({ title, helpText, error, children, className, testId, fieldName }) {
    return (
        <AnalyticsContextProviderWithAdditionalData
            analyticsKeyToAdd={AnalyticsPropertyEnum.CARD_SECTION}
            analyticsValueToAdd={testId}
        >
            <ContentSection
                title={title}
                helpText={helpText}
                error={error && <CardSectionError>{error}</CardSectionError>}
                className={className}
                headerStyle={{
                    width: 'calc(100% + 23px)',
                    marginLeft: '-23px',
                    marginBottom: '12px',
                }}
                fieldName={fieldName}
                testId={testId}
            >
                {children}
            </ContentSection>
        </AnalyticsContextProviderWithAdditionalData>
    );
});

const PanelError = styled.div`
    margin: 10px 28px 10px 28px;
    font-size: var(--arc-fontSizes-sm);
    color: ${(props) => props.theme.colors.red};
    border-radius: 4px;
    padding: 12px;
    display: block;
    background-color: var(--arc-colors-negative-accent);
    border: 1px solid var(--arc-colors-negative-default);

    ul {
        padding-right: 40px;
    }
`;

function CardErrors({ children }: { children: React.ReactNode }) {
    const errorRows = isArray(children) ? (
        _(children)
            .uniq()
            .map((child, i) => <li key={i}>{child}</li>)
            .value()
    ) : (
        <li>{children}</li>
    );
    return (
        <PanelError data-test-id={testIds.CARD_PANEL_ERRORS}>
            <ul>{errorRows}</ul>
        </PanelError>
    );
}

const cardSubsectionHeaderStyle = {
    marginTop: '15px',
    marginBottom: '15px',
    width: '98%',
    float: 'left',
};

interface CardSubsectionProps extends Record<`data-${string}`, string> {
    title?: string;
    children?: React.ReactNode;
    className?: string;
    testId?: string;
}

export function CardSubsection({
    title,
    children,
    className,
    testId,
    ...props
}: CardSubsectionProps) {
    return (
        <ContentSubsection
            className={className}
            title={title}
            headerStyle={cardSubsectionHeaderStyle}
            testId={testId}
            {...props}
        >
            {children}
        </ContentSubsection>
    );
}

interface CardMiniSectionHeaderProps {
    text?: string;
}

export function CardMiniSectionHeader({ text }: CardMiniSectionHeaderProps) {
    return <div className="mark43-react-card-content-mini-header">{text}</div>;
}

CardMiniSectionHeader.propTypes = {
    text: PropTypes.string,
};

const CardFooterWrapper = styled.div<{
    noPadding?: boolean;
}>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${(props) => (props.noPadding ? '' : 'padding: 12px 28px;')}

    .loading-small {
        margin-top: 0;
    }
`;

interface CardFooterProps
    extends Pick<CardProps, 'onSave' | 'saving' | 'saveDisabled' | 'saveText'> {
    additionalContent: CardProps['additionalFooterContent'];
}

function CardFooter({
    onSave,
    saving,
    saveDisabled,
    saveText,
    additionalContent,
}: CardFooterProps): JSX.Element {
    return (
        <FeatureFlagged
            flag="ARC_RELEASE_CYCLE_ONE_COMPONENTS"
            fallback={
                <CardFooterWrapper noPadding={true} className="mark43-react-card-footer">
                    <Button
                        testId={testIds.CARD_SAVE}
                        onClick={onSave}
                        className={buttonTypes.PRIMARY}
                        disabled={saving || saveDisabled}
                    >
                        {saveText}
                    </Button>
                    {!!additionalContent && additionalContent({ saving, saveDisabled })}
                    {saving && <FloatLoadingGray />}
                </CardFooterWrapper>
            }
        >
            <CardFooterWrapper className="mark43-react-card-footer">
                <ButtonGroup>
                    <ArcButton
                        testId={testIds.CARD_SAVE}
                        onClick={onSave}
                        isDisabled={saving || saveDisabled}
                        isLoading={saving}
                        variant="solid"
                    >
                        {saveText}
                    </ArcButton>
                    {
                        // additionalContent may not always be a Button, but ButtonGroup still
                        // provides reasonable layout styles
                        !!additionalContent && additionalContent({ saving, saveDisabled })
                    }
                    {saving && <FloatLoadingGray />}
                </ButtonGroup>
            </CardFooterWrapper>
        </FeatureFlagged>
    );
}

function Card({
    className,
    anchor,
    title,
    helpText,
    errors = [],
    summaryMode = defaultSummaryModeValue,
    canEdit = false,
    canEditErrorMessage,
    onEdit,
    onEditIconType,
    onEditLabel,
    onRemove,
    onSave,
    onDuplicate,
    saving = false,
    saveDisabled = false,
    saveText = strings.save,
    children,
    hideHeader = false,
    hideFooter = false,
    testId,
    renderContent,
    customSummaryModeColor,
    customSummaryModeBorderColor,
    stickyHeader = true,
    cardHeaderRef,
    renderFullScreenToggleHeaderButton,
    additionalFooterContent,
}: CardProps) {
    invariant(
        !(children && renderContent),
        'Card component can only take in either children OR renderContent, not both.'
    );

    const { SAVE_AND_CLOSE } = hotkeysActionEnum;
    const interpolatedErrors = useErrorInterpolator(errors);

    const hotKeysConfig = {
        [SAVE_AND_CLOSE.name]: {
            handler: () => {
                blurActiveElement();
                if (isFunction(onSave)) {
                    onSave();
                }
            },
        },
        ...cardScrollHotKeyConfigForAnchor(anchor),
    };

    return (
        <AnalyticsContextProviderWithAdditionalData
            analyticsKeyToAdd={AnalyticsPropertyEnum.CARD}
            analyticsValueToAdd={testId}
        >
            <ReactHotKeysWrapper hotKeysConfig={hotKeysConfig}>
                <SummaryModeContext.Provider value={summaryMode}>
                    <CardComponent
                        className={classNames('mark43-react-card', className, {
                            'edit-mode': !summaryMode,
                            'hide-header': hideHeader,
                            'hide-footer': hideFooter,
                        })}
                        data-test-id={testId}
                        data-anchor={anchor}
                    >
                        {!hideHeader && (
                            <CardHeader
                                title={title}
                                helpText={helpText}
                                summaryMode={summaryMode}
                                onEdit={onEdit}
                                onEditIconType={onEditIconType}
                                onEditLabel={onEditLabel}
                                onRemove={onRemove}
                                canEdit={canEdit}
                                canEditErrorMessage={canEditErrorMessage}
                                errors={errors}
                                customSummaryModeColor={customSummaryModeColor}
                                customSummaryModeBorderColor={customSummaryModeBorderColor}
                                onDuplicate={onDuplicate}
                                stickyHeader={stickyHeader}
                                renderFullScreenToggleHeaderButton={
                                    renderFullScreenToggleHeaderButton
                                }
                                ref={cardHeaderRef}
                            />
                        )}
                        <CardContent summaryMode={summaryMode}>
                            <>
                                <FeatureFlagged
                                    flag="RMS_REPORT_WRITING_ERROR_ENHANCEMENTS_ENABLED"
                                    fallback={
                                        !isEmpty(interpolatedErrors) && (
                                            <CardErrors>{interpolatedErrors}</CardErrors>
                                        )
                                    }
                                />
                                {renderContent ? renderContent(summaryMode) : children}
                            </>
                        </CardContent>
                        {!summaryMode && !hideFooter && (
                            <CardFooter
                                onSave={onSave}
                                saving={saving}
                                saveDisabled={saveDisabled}
                                saveText={saveText}
                                additionalContent={additionalFooterContent}
                            />
                        )}
                    </CardComponent>
                </SummaryModeContext.Provider>
            </ReactHotKeysWrapper>
        </AnalyticsContextProviderWithAdditionalData>
    );
}

/**
 * Card, which is also called panel in legacy code.
 *
 * To support summary mode and edit mode, provide the prop
 *   renderContent, which will provided the boolean summaryMode. If only summary mode is needed,
 *   you can also just set the component's children.
 *
 * This component doesn't have the dropdown option from the legacy card.
 */
export default Card;

/**
 * Section inside a `Card` component with an optional header if a title is
 *   provided.
 */
export { CardSection };

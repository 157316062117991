import React, { useRef } from 'react';
import { compact, join, size, isFunction } from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { Text, Flex } from 'arc';
import componentStrings from '~/client-common/core/strings/componentStrings';
import {
    formatFullName,
    isUnknown,
} from '~/client-common/core/domain/person-profiles/utils/personProfilesHelpers';

import { iconSizes } from '../../components/Icon';
import { Tile } from '../../components/Tile';
import { PersonProfileImage, profileImageSizes } from '../../components/ProfileImage';
import { assignToRefs } from '../../utils/eventHelpers';
import { showIdentifyUnknownForPersonSelector } from '../state/ui';
import testIds from '../../../../core/testIds';
import { useDateTimeFormatter } from '../../current-user/hooks/dateTimeFormats';

const strings = componentStrings.core.PersonPillSmall;
export const NO_INFO_KNOWN_ID = -1;

const StyledPersonProfileImage = styled(PersonProfileImage)`
    flex-shrink: 0;
`;

const TileWithIdWrapper = styled(Flex)`
    height: 100%;
    overflow: hidden;
`;
const StyledIdButton = styled(Tile)`
    height: 100%;
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
`;

// This is there to support the side-by-side Tiles when there is an ID button displayed.
const StyledTile = styled(Tile)`
    ${(props) =>
        props.$hasId === true &&
        `
        flex: 1;
        border-start-end-radius: 0px ;
        border-end-end-radius: 0px ;
        `}
`;

const PersonPillSmall = React.forwardRef(
    (
        {
            className,
            disabled,
            elasticPerson,
            onClick,
            testId,
            showIdentifyUnknownForPerson,
            onClickIdentify,
            formatFullName: formatFullNameProp = formatFullName,
        },
        forwardedRef
    ) => {
        const dateTimeFormatter = useDateTimeFormatter();
        const pillRef = useRef(null);
        const idRef = useRef(null);
        const {
            primaryMugshotPath,
            nicknames,
            dateOfBirth,
            sexAttrDetail,
            raceAttrDetail,
            id,
        } = elasticPerson;
        const fullName = formatFullNameProp(elasticPerson);
        const details = compact([
            dateOfBirth ? dateTimeFormatter.formatDate(dateOfBirth) : null,
            sexAttrDetail ? sexAttrDetail.displayValue : null,
            raceAttrDetail ? raceAttrDetail.displayValue : null,
        ]);

        const handleClick = () => {
            if (isFunction(onClick)) {
                onClick(pillRef);
            }
        };

        const handleIdentifyClick = () => {
            if (isFunction(onClickIdentify)) {
                onClickIdentify(idRef);
            }
        };

        const isUnknownPerson = isUnknown(elasticPerson);
        const showIdentify = showIdentifyUnknownForPerson(elasticPerson);
        const noInfoKnown = id === NO_INFO_KNOWN_ID;

        const personTile = () => {
            return (
                <StyledTile
                    $hasId={showIdentify}
                    className={className}
                    testId={testId}
                    isDisabled={disabled}
                    onClick={disabled ? undefined : handleClick}
                    ref={assignToRefs([pillRef, forwardedRef])}
                    title={
                        <>
                            {fullName}
                            {!!size(nicknames) && (
                                <Text isItalic color="tertiary" as="span">
                                    {` (${strings.nicknamesPrefix} ${join(nicknames, ', ')})`}
                                </Text>
                            )}
                        </>
                    }
                    media={
                        <StyledPersonProfileImage
                            iconSize={iconSizes.LARGE}
                            size={profileImageSizes.PERSON_PILL_SMALL}
                            isUnknown={isUnknownPerson}
                            url={primaryMugshotPath}
                            zIndex={0}
                        />
                    }
                    description={!!size(details) && join(details, ', ')}
                />
            );
        };

        const personTileWithId = () => {
            return (
                <TileWithIdWrapper>
                    {personTile()}
                    {isFunction(onClickIdentify) && showIdentify && (
                        <StyledIdButton
                            testId={testIds.UNKNOWN_PERSON_ID_BUTTON}
                            tabIndex={0}
                            onClick={handleIdentifyClick}
                            ref={idRef}
                            isDisabled={disabled}
                            title={<Text color="brand">{strings.id}</Text>}
                        />
                    )}
                </TileWithIdWrapper>
            );
        };

        function renderContent() {
            if (noInfoKnown) {
                return (
                    <Tile
                        icon="Add"
                        title={strings.noInfoKnown}
                        className={className}
                        testId={testId}
                        isDisabled={disabled}
                        onClick={disabled ? undefined : handleClick}
                        ref={assignToRefs([pillRef, forwardedRef])}
                    />
                );
            } else if (showIdentify) {
                return personTileWithId();
            } else {
                return personTile();
            }
        }
        return renderContent();
    }
);

const mapStateToProps = createStructuredSelector({
    showIdentifyUnknownForPerson: showIdentifyUnknownForPersonSelector,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(PersonPillSmall);

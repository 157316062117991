import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import boxEnum from '~/client-common/core/enums/client/boxEnum';

import { closeImageGalleryLightbox } from '../../actions/imageGalleryLightboxActions';
import { createModalSelector } from '../../../modules/core/box/state/ui';
import Lightbox from './Lightbox';
import ImageGallery from './ImageGallery';

const context = {
    name: boxEnum.IMAGE_GALLERY_LIGHTBOX,
};

/**
 * This component puts an image gallery inside of a lightbox
 * @param {function} options.closeBox function that dispatches action to close the component
 * @param {Object[]} usageLogParams usage log params (title, source)
 */
function ImageGalleryLightbox({ closeBox, usageLogParams }) {
    return (
        <Lightbox context={context} onClose={closeBox}>
            <ImageGallery usageLogParams={usageLogParams} />
        </Lightbox>
    );
}

const mapDispatchToProps = (dispatch) => ({
    closeBox: () => {
        dispatch(closeImageGalleryLightbox());
    },
});

const mapStateToProps = createStructuredSelector({
    usageLogParams: createModalSelector(context, 'usageLogParamsForAttachments'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageGalleryLightbox);

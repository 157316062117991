import { storeStations } from '~/client-common/core/domain/stations/state/data';
import getStationsResource from '~/client-common/core/domain/stations/resources/stationsResource';

import { departmentProfileSelector } from '../../../../../legacy-redux/selectors/departmentProfileSelectors';

export const LOAD_STATIONS_START = 'stations/LOAD_STATIONS_START';
export const LOAD_STATIONS_SUCCESS = 'stations/LOAD_STATIONS_SUCCESS';
export const LOAD_STATIONS_FAILURE = 'stations/LOAD_STATIONS_FAILURE';

const SAVE_STATION_FORM_START = 'stations/SAVE_STATION_FORM_START';
export const SAVE_STATION_FORM_SUCCESS = 'stations/SAVE_STATION_FORM_SUCCESS';
export const SAVE_STATION_FORM_FAILURE = 'stations/SAVE_STATION_FORM_FAILURE';

export const REMOVE_STATION_FAILURE = 'stations/REMOVE_STATION_FAILURE';

export function saveStationForm(stationModel, stationLocation) {
    const stationsResource = getStationsResource();

    return (dispatch, getState) => {
        dispatch(saveStationFormStart());
        const state = getState();
        const toSave = {
            station: {
                ...stationModel,
                departmentId: departmentProfileSelector(state).departmentId,
            },
        };

        if (stationLocation) {
            toSave.location = stationLocation;
        }

        return stationsResource
            .upsertStation(toSave)
            .then((resp) => {
                dispatch(storeStations(resp));
                dispatch(saveStationFormSuccess());
                return resp;
            })
            .catch((err) => {
                dispatch(saveStationFormFailure(err));
            });
    };
}

function saveStationFormStart() {
    return {
        type: SAVE_STATION_FORM_START,
    };
}

function saveStationFormSuccess() {
    return {
        type: SAVE_STATION_FORM_SUCCESS,
    };
}

function saveStationFormFailure(err) {
    return {
        type: SAVE_STATION_FORM_FAILURE,
        payload: err,
    };
}

function loadStationsStart() {
    return {
        type: LOAD_STATIONS_START,
    };
}

function loadStationsSuccess(stations) {
    return {
        type: LOAD_STATIONS_SUCCESS,
        payload: stations,
    };
}

function loadStationsFailure(err) {
    return {
        type: LOAD_STATIONS_FAILURE,
        payload: err,
    };
}

export function loadStationsAdmin() {
    const stationsResource = getStationsResource();

    return function (dispatch) {
        dispatch(loadStationsStart());
        const inner = () => {
            return stationsResource
                .getStations()
                .then((resp) => {
                    dispatch(storeStations(resp));
                    dispatch(loadStationsSuccess(resp));
                })
                .catch((err) => {
                    dispatch(loadStationsFailure(err));
                });
        };

        return inner();
    };
}

export function createNewStation(id = -1) {
    const location = { entityLinks: [] };
    return {
        id,
        station: {
            id,
            location,
        },
        location,
    };
}

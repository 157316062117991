import React from 'react';
import { useSelector } from 'react-redux';
import { ElasticVehicle } from '@mark43/rms-api';
import { getTruthyValuesByKey } from '~/client-common/helpers/logicHelpers';
import { itemCaseLinksWhereSelector } from '~/client-common/core/domain/item-case-links/state/data';
import ImageCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/ImageCell';
import VehicleCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/VehicleCell';
import IdentifiersCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/IdentifiersCell';
import OwnerCell from '../../../../legacy-redux/components/core/tables/elasticVehicleTable/OwnerCell';
import testIds from '../../../../core/testIds';
import Link from '../../../core/components/links/Link';
import createElement from '../../../core/utils/recompose.createElement';
import { currentCaseIdSelector } from '../../core/state/ui';
import CaseLinkedProfile from './CaseLinkedProfile';
import CaseSourceIndicator from './CaseSourceIndicator';
import CaseTargetLevelIndicator from './CaseTargetLevelIndicator';

export default function CaseLinkedVehicle({ vehicle }: { vehicle: ElasticVehicle }) {
    const currentCaseId = useSelector(currentCaseIdSelector);
    const itemCaseLinksWhere = useSelector(itemCaseLinksWhereSelector);
    const predicate = {
        itemId: vehicle.id,
        caseId: currentCaseId,
    };
    const itemCaseLinks = itemCaseLinksWhere(predicate);
    const targetProfileLevelAttrIds = getTruthyValuesByKey(itemCaseLinks, 'targetProfileLevelAttrId');
    // use of `createElement` is due to the fact that all `Symbol` properties
    // of an object are lost when it's "copied" (via spread syntax). This is an intended
    // effect of spread syntax / for in
    return (
        <Link to={`/profiles/vehicles/${vehicle.id}`} target="_blank">
            <CaseLinkedProfile>
                <div className="case-related-entity-cell">
                    {createElement(ImageCell, { ...vehicle })}
                </div>
                <div
                    className="case-related-entity-cell related-entity-vehicle-cell"
                    data-test-id={testIds.CASE_RELATED_VEHICLES}
                >
                    {createElement(VehicleCell, { ...vehicle })}
                    <CaseSourceIndicator sources={vehicle.sources} />
                    <CaseTargetLevelIndicator attributeIds={targetProfileLevelAttrIds} />
                </div>
                <div className="case-related-entity-cell related-entity-vehicle-identifiers-cell">
                    {createElement(IdentifiersCell, { ...vehicle })}
                </div>
                <div className="case-related-entity-cell related-entity-vehicle-owner-cell">
                    {createElement(OwnerCell, { ...vehicle })}
                </div>
            </CaseLinkedProfile>
        </Link>
    );
}

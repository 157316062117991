import { AttributeTypeEnumType, AttributeTypeEnum } from '@mark43/rms-api';
import { FederatedSearchPerson, PersonFields } from 'mark43-federated-search';
import { isNil, keys, omitBy, pick } from 'lodash';
import {
    createPersonProfileFormConfiguration,
    PersonProfileFormDataShape,
} from './createPersonProfileFormConfiguration';
import { TaskStatusOption } from 'src/scripts/modules/tasks/core/types';

const convertDexPersonToPersonProfile = (
    dexPerson: FederatedSearchPerson,
    getAttributesByType: ({ type }: { type: AttributeTypeEnumType }) => TaskStatusOption[]
) => {
    const getAttributeId = (value: string | undefined, formPath: AttributeTypeEnumType) =>
        getAttributeIdByDisplayValue(value, formPath, getAttributesByType);

    const attributeProperties = {
        sexAttrId: getAttributeId(dexPerson.sex, AttributeTypeEnum.SEX.name),
        raceAttrId: getAttributeId(dexPerson.race, AttributeTypeEnum.RACE.name),
        ethnicityAttrId: getAttributeId(dexPerson.ethnicity, AttributeTypeEnum.ETHNICITY.name),
        citizenshipAttrId: getAttributeId(
            dexPerson.citizenship,
            AttributeTypeEnum.CITIZENSHIP.name
        ),
        eyeColorAttrId: getAttributeId(dexPerson.eyeColor, AttributeTypeEnum.EYE_COLOR.name),
        skinToneAttrId: getAttributeId(dexPerson.skinTone, AttributeTypeEnum.SKIN_TONE.name),
        hairColorAttrId: getAttributeId(dexPerson.hairColor, AttributeTypeEnum.HAIR_COLOR.name),
        hairStyleAttrId: getAttributeId(dexPerson.hairStyle, AttributeTypeEnum.HAIR_STYLE.name),
        hairLengthAttrId: getAttributeId(dexPerson.hairLength, AttributeTypeEnum.HAIR_LENGTH.name),
        facialHairTypeAttrId: getAttributeId(
            dexPerson.facialHairType,
            AttributeTypeEnum.FACIAL_HAIR_TYPE.name
        ),
        visionAttrId: getAttributeId(dexPerson.vision, AttributeTypeEnum.VISION.name),
        maritalStatusAttrId: getAttributeId(
            dexPerson.maritalStatus,
            AttributeTypeEnum.MARITAL_STATUS.name
        ),
        birthStateAttrId: getAttributeId(dexPerson.birthState, AttributeTypeEnum.STATE.name),
        isResidentOfJurisdictionAttrId: getAttributeId(
            dexPerson.isResidentOfJurisdiction,
            AttributeTypeEnum.RESIDENT_OF_JURISDICTION.name
        ),
    };

    const personProfile: Partial<PersonProfileFormDataShape> = {
        ...pick(dexPerson, [
            PersonFields.firstName,
            PersonFields.middleName,
            PersonFields.suffix,
            PersonFields.dateOfBirth,
        ]),
        lastName: dexPerson.lastName || dexPerson.name,
        heightFeet: dexPerson.height ? +dexPerson.height : undefined,
        weight: dexPerson.weight ? +dexPerson.weight : undefined,
        ...attributeProperties,
        emergencyContacts: dexPerson.personEmergencyContacts || [],
        phoneNumbers: dexPerson.namePhoneNumbers || [],
        emails: dexPerson.nameEmails || [],
        monikers: dexPerson.nameMonikers || [],
        employmentHistories: dexPerson.employmentHistories || [],
    };

    return personProfile;
};

export const getPersonProfileFromDexPerson = (
    dexPerson: FederatedSearchPerson,
    getAttributesByType: ({ type }: { type: AttributeTypeEnumType }) => TaskStatusOption[]
) => {
    const personProfile = convertDexPersonToPersonProfile(dexPerson, getAttributesByType);

    const formConfiguration = createPersonProfileFormConfiguration();
    return pick(personProfile, keys(formConfiguration));
};

const getAttributeIdByDisplayValue = (
    value: string | undefined,
    formPath: AttributeTypeEnumType,
    getAttributesByType: ({ type }: { type: AttributeTypeEnumType }) => TaskStatusOption[]
) => {
    const attributesByType = getAttributesByType({ type: formPath });

    const currentAttribute = attributesByType.find((attribute) => attribute.display === value);
    return currentAttribute?.value;
};

export const mergeDexFormModelWithPersonFormModel = (
    dexFormModel: PersonProfileFormDataShape,
    personFormModel: PersonProfileFormDataShape
) => {
    return {
        ...personFormModel,
        ...omitBy(dexFormModel, isNil),
        personProbations: [
            ...(dexFormModel.personProbations || []),
            ...(personFormModel.personProbations || []),
        ],
        emergencyContacts: [
            ...(dexFormModel.emergencyContacts || []),
            ...(personFormModel.emergencyContacts || []),
        ],
        phoneNumbers: [
            ...(dexFormModel.phoneNumbers || []),
            ...(personFormModel.phoneNumbers || []),
        ],
        emails: [...(dexFormModel.emails || []), ...(personFormModel.emails || [])],
        monikers: [...(dexFormModel.monikers || []), ...(personFormModel.monikers || [])],
        employmentHistories: [
            ...(dexFormModel.employmentHistories || []),
            ...(personFormModel.employmentHistories || []),
        ],
        identifiers: [...(dexFormModel.identifiers || []), ...(personFormModel.identifiers || [])],
        identifyingMarks: [
            ...(dexFormModel.identifyingMarks || []),
            ...(personFormModel.identifyingMarks || []),
        ],
        injuries: [...(dexFormModel.injuries || []), ...(personFormModel.injuries || [])],
    };
};

export const attributesToPrefetch = [
    AttributeTypeEnum.VERIFICATION_OF_DEATH.name,
    AttributeTypeEnum.INFANT_AGE.name,
    AttributeTypeEnum.YES_NO_UNKNOWN.name,
    AttributeTypeEnum.RESIDENT_OF_JURISDICTION.name,
    AttributeTypeEnum.STATE.name,
    AttributeTypeEnum.NAME_IDENTIFIER_TYPE.name,
    AttributeTypeEnum.EYE_COLOR.name,
    AttributeTypeEnum.HAIR_COLOR.name,
    AttributeTypeEnum.SUBJECT_DATA_DISABILITY.name,
    AttributeTypeEnum.IDENTIFYING_MARK_TYPE.name,
    AttributeTypeEnum.BODY_PART.name,
    AttributeTypeEnum.CLOTHING_TYPE.name,
    AttributeTypeEnum.BEHAVIORAL_CHARACTERISTIC.name,
    AttributeTypeEnum.MOOD.name,
    AttributeTypeEnum.PERSON_SKILL.name,
    AttributeTypeEnum.CITIZENSHIP.name,
    AttributeTypeEnum.LANGUAGE.name,
    AttributeTypeEnum.MARITAL_STATUS.name,
    AttributeTypeEnum.SEXUAL_ORIENTATION.name,
    AttributeTypeEnum.RELIGION.name,
    AttributeTypeEnum.PROBATION_TYPE.name,
    AttributeTypeEnum.VICTIM_DISABILITY_TYPE.name,
    AttributeTypeEnum.INJURY_CATEGORY.name,
    AttributeTypeEnum.WEAPON_OR_FORCE_INVOLVED.name,
    AttributeTypeEnum.SEX.name,
];

import { last, sortBy } from 'lodash';

// helpers
import { valuesToArray } from '../../../../helpers/logicHelpers';

const sortCadTickets = (cadTickets) => {
    const cadTicketsArray = valuesToArray(cadTickets);
    return sortBy(cadTicketsArray, ['createdDateUtc', 'id']);
};

export const latestCadTicket = (cadTickets) => {
    const sortedCadTickets = sortCadTickets(cadTickets);
    return last(sortedCadTickets);
};

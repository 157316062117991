import { AttributeTypeEnum } from '@mark43/rms-api';
import * as React from 'react';
import { noop, values, some, map, identity, pick } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withHandlers, lifecycle, withPropsOnChange } from 'recompose';
import { Fieldset } from 'markformythree';
import strings from '~/client-common/core/strings/componentStrings';
import {
    attributeLoadingStateSelectorFactory,
    attributeOptionsByTypeForCurrentDepartmentSelector,
} from '../../attributes/state/ui';
import { loadAttributesForType } from '../../attributes/state/ui/loadAttributesForType';
import { computeAllowedAttributeTypesToLoad } from '../../attributes/utils/computeAllowedAttributeTypesToLoad';
import { MFTText } from '../../forms/components/Text';
import { InlineBanner } from '../../components/InlineBanner';
import Button, { buttonTypes } from '../../../../legacy-redux/components/core/Button';
import { SimpleLoading } from '../../../../legacy-redux/components/core/Loading';
import FormRow from '../../forms/components/FormRow';

function _AttributesForTypeRenderer({
    attributeLoadingState,
    triggerAttributeLoad,
    options,
    render,
}) {
    const attributeLoadingValues = values(attributeLoadingState);
    const isLoading = some(attributeLoadingValues, (loadingState) => loadingState.loading);
    const showError =
        !isLoading && some(attributeLoadingValues, (loadingState) => loadingState.error);

    return (
        <div>
            {isLoading && <SimpleLoading />}
            {showError && (
                <div>
                    <InlineBanner status="error">
                        {strings.core.attributeComponents.attributeTypeLoadingError}
                    </InlineBanner>
                    <Button className={buttonTypes.SECONDARY} onClick={triggerAttributeLoad}>
                        {strings.core.attributeComponents.retryLoading}
                    </Button>
                </div>
            )}
            {!isLoading && !showError && options.length ? render(options) : null}
        </div>
    );
}

const ATTRIBUTE_FILTER_PROPS = [
    'attributeOptionsByTypeForCurrentDepartment',
    'attributeType',
    'includeExpired',
    'attributeIds',
    'filterOptions',
];

const AttributesForTypeRenderer = compose(
    connect(
        () => {
            const attributeLoadingStateSelector = attributeLoadingStateSelectorFactory();
            return createStructuredSelector({
                attributeOptionsByTypeForCurrentDepartment: attributeOptionsByTypeForCurrentDepartmentSelector,
                attributeLoadingState: attributeLoadingStateSelector,
            });
        },
        {
            loadAttributesForType,
        }
    ),
    withHandlers({
        triggerAttributeLoad({ attributeLoadingState, loadAttributesForType }) {
            return () => {
                const attributeTypesToLoad = computeAllowedAttributeTypesToLoad(
                    attributeLoadingState
                );

                // for now we load all attributes for the specified types, including expired ones
                if (attributeTypesToLoad.length) {
                    // the empty `catch` is intentional. Due to having to support legacy KO/BB
                    // we have to return a promise which can potentially be rejected
                    loadAttributesForType({ attributeType: attributeTypesToLoad }).catch(noop);
                }
            };
        },
    }),
    lifecycle({
        componentDidMount() {
            this.props.triggerAttributeLoad();
        },
    }),
    withPropsOnChange(
        ATTRIBUTE_FILTER_PROPS,
        ({
            attributeOptionsByTypeForCurrentDepartment,
            attributeType,
            includeExpired,
            attributeIds,
            filterOptions = identity,
        }) => ({
            options: filterOptions(
                attributeOptionsByTypeForCurrentDepartment({
                    type: attributeType,
                    includeExpired,
                    additionalIds: attributeIds,
                })
            ),
        })
    )
)(_AttributesForTypeRenderer);

export function PersonProfileFormClothingFields(props) {
    const attributeFilterProps = pick(props, ATTRIBUTE_FILTER_PROPS);
    return (
        <Fieldset path="clothing">
            <AttributesForTypeRenderer
                {...attributeFilterProps}
                attributeType={AttributeTypeEnum.CLOTHING_TYPE.name}
                render={(options) => (
                    <FormRow>
                        {map(options, (option) => (
                            <MFTText
                                key={option.value}
                                label={option.display}
                                path={`${option.value}`}
                                length="md"
                            />
                        ))}
                    </FormRow>
                )}
            />
        </Fieldset>
    );
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RefContextEnum } from '@mark43/rms-api';
import { get, first } from 'lodash';

import strings from '~/client-common/core/strings/componentStrings';
import { isArrestReportSelector } from '~/client-common/core/domain/arrests/state/ui';
import { courtOrderSelector } from '~/client-common/core/domain/court-orders/state/data';
import { courtOrderEditSubmissionErrorSelector } from '../state/ui';
import courtOrderEditForm from '../state/forms/courtOrderEditForm';
import { InlineBanner } from '../../../core/components/InlineBanner';
import { ColumnLeft, ColumnRight, ColumnFull } from '../../core/components/RecordPrivacyColumn';
import { FlexContainer, InnerFlexContainer } from '../../core/components/RecordPrivacyContainers';
import { Note, RightColumnNote } from '../../core/components/RecordPrivacyNote';
import { Form } from '../../core/components/Form';
import SubSectionTitle from '../../core/components/SubSectionTitle';
import CourtOrderFormFields from '../../core/components/CourtOrderFormFields';
import ReportSealingFormFields from '../../core/components/ReportSealingFormFields';
import SealingChargesSection from '../../sealing/components/SealingChargesSection';

class CourtOrderEditForm extends React.Component {
    handleReportSealClick = (isReportSealed) => {
        if (isReportSealed && !this.props.report.isNarrativeSealed) {
            this.props.changePath('isSealNarrative', true);
        }
    };

    handleNarrativeSealClick = (isNarrativeSealed) => {
        if (!isNarrativeSealed) {
            this.props.changePath('isSealReport', false);
            this.props.changePath('userToContactId', undefined);
        }
    };

    render() {
        const {
            report,
            submissionError,
            formModelByPath,
            isArrestReport,
            courtOrdersByReportId,
            courtOrderId,
        } = this.props;
        const showArrestIdField = isArrestReport(report.id);
        const isStatuteSealing = !!get(
            first(get(courtOrdersByReportId, report.id), ['id', courtOrderId]),
            'statuteCodeAttrId'
        );

        return (
            <Form {...courtOrderEditForm}>
                <FlexContainer>
                    {submissionError && (
                        <ColumnFull flexGrow={0}>
                            <InlineBanner status="error">{submissionError}</InlineBanner>
                        </ColumnFull>
                    )}
                    <InnerFlexContainer>
                        <ColumnLeft>
                            <Note>
                                {
                                    strings.recordPrivacy.courtOrders.CourtOrderEditForm
                                        .editInformation
                                }
                            </Note>
                            <CourtOrderFormFields
                                showArrestIdField={showArrestIdField}
                                showStateIdField={true}
                                changePath={this.props.changePath}
                                formModelByPath={formModelByPath}
                                courtOrderId={this.props.courtOrderId}
                                disableIsJuvenile={true}
                                currentContext={RefContextEnum.FORM_COURT_ORDER_EDIT.name}
                                isStatuteSealing={isStatuteSealing}
                                isEditing={true}
                            />
                        </ColumnLeft>
                        <ColumnRight>
                            <SubSectionTitle>
                                {strings.recordPrivacy.shared.sections.involvedPersons.title}
                            </SubSectionTitle>
                            <RightColumnNote>
                                {
                                    strings.recordPrivacy.courtOrders.CourtOrderEditForm
                                        .involvedPersons.note
                                }
                            </RightColumnNote>
                            <SealingChargesSection reportId={report.id} edit={true} />
                            <SubSectionTitle>
                                {strings.recordPrivacy.shared.sections.narrative.title}
                            </SubSectionTitle>
                            <RightColumnNote>
                                {strings.recordPrivacy.shared.sections.narrative.note}
                            </RightColumnNote>
                            <ReportSealingFormFields
                                formModelByPath={formModelByPath}
                                onReportSealClick={this.handleReportSealClick}
                                onNarrativeSealClick={this.handleNarrativeSealClick}
                                report={report}
                                forceEnabledNarrativeSealing={true}
                            />
                        </ColumnRight>
                    </InnerFlexContainer>
                </FlexContainer>
            </Form>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    submissionError: courtOrderEditSubmissionErrorSelector,
    formModelByPath: courtOrderEditForm.selectors.formModelByPathSelector,
    isArrestReport: isArrestReportSelector,
    courtOrdersByReportId: courtOrderSelector,
});

export default connect(mapStateToProps, {
    changePath: courtOrderEditForm.actionCreators.changePath,
    validateForm: courtOrderEditForm.actionCreators.validate,
})(CourtOrderEditForm);
